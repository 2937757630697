import {Component, OnInit} from '@angular/core';
import {
    LocalStorageService
} from '../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {WarenkorbService} from './warenkorb/warenkorb.service';

@Component({
    selector: 'kiene-wa-wi',
    templateUrl: './wa-wi.component.html',
    styleUrls: ['./wa-wi.component.scss']
})
export class WaWiComponent implements OnInit {

    constructor(private localStorageService: LocalStorageService, private warenkorbService: WarenkorbService) {
        this.localStorageService.setCurrentClientVisible(false);
        this.localStorageService.setShoppingCartVisible(true);
    }

    ngOnInit() {
        this.warenkorbService.getWarenkorbContent().subscribe(response => {
            const len: number = response.records.length;
            this.localStorageService.setNumberOfShoppingCartItems(len);
        });
    }

    isTabMenuVisible(path: string) {
        return this.localStorageService.currentUserCanSeeTab(path);
    }

}
