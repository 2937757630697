export class ConfirmDialogInput {
    headline = '';
    text = '';
    okButton = 'OK';
    cancelButton = 'Abbrechen';
    showCancel = true;
    buttonColor = 'accent';
    isWarning = false;
}

export class ConfirmDialogResult {
    ok = false; // false = Abbruch
    confirmed = false;
}
