export class Inventur {
    inventur_id: number;
    datum: Date;
    name: string;
    erstellt: Date;
    editiert: Date;
    geloescht: boolean;
    mandant_id: number;
    erstellt_von: number;
    editiert_von: number;
    aktiv: number;
}
