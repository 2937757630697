<h1 mat-dialog-title>Rechnungen
    erzeugen {{selectedCompanyGroup ? 'für ' + selectedCompanyGroup.bezeichnung : selectedClient ? 'für ' + selectedClient.bezeichnung_final : ''}}</h1>
<div mat-dialog-content>
    <mat-form-field style="width: 40%; margin-right: 10px;">
        <input matInput [matDatepicker]="pickerFrom" placeholder="Zeitraum von" [formControl]="pickerFromCtrl">
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom startView="month" [startAt]="startDate"></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 40%;">
        <input matInput [matDatepicker]="pickerTo" placeholder="Zeitraum bis" [formControl]="pickerToCtrl">
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo startView="month"></mat-datepicker>
    </mat-form-field>
    <mat-divider style="margin-top: 24px;"></mat-divider>
    <h4>Kundendaten</h4>
    <mat-radio-group [formControl]="clientRadioCtrl" style="width: 100%;">
        <mat-radio-button [value]="'client'" style="width: 100%;">
            <kiene-client-autocomplete [panelWidth]="'50%'"
                                       (selectedClient)="setClient($event)"></kiene-client-autocomplete>
        </mat-radio-button>
        <mat-radio-button [value]="'companyGroup'" style="width: 100%;">
            <kiene-company-group-autocomplete
                (selectedCompanyGroup)="setCompanyGroup($event)"></kiene-company-group-autocomplete>
        </mat-radio-button>
        <mat-radio-button [value]="'all'" style="width: 100%;">
            Alle Betriebe
        </mat-radio-button>
    </mat-radio-group>
    <mat-divider style="margin-top: 24px;"></mat-divider>
    <h4>Belegtypen</h4>
    <p>Bitte ausw&auml;hlen f&uuml;r welche Belegtypen Rechnungen generiert werden sollen.</p>
    <mat-checkbox [formControl]="abgabebelegeCtrl">Abgabebelege</mat-checkbox>
    <br>
    <mat-checkbox [formControl]="befundeCtrl">Befundmitteilungen</mat-checkbox>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-raised-button color="primary" (click)="ok()">Erzeugen</button>
</div>
