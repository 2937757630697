import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InventurEintrag} from '../entities/InventurEintrag';
import {UntypedFormControl, Validators} from '@angular/forms';

@Component({
  selector: 'kiene-inventur-edit-dialog',
  templateUrl: './inventur-edit-dialog.component.html',
  styleUrls: ['./inventur-edit-dialog.component.scss']
})
export class InventurEditDialogComponent implements OnInit {

  inventurEintrag: InventurEintrag;
  mengeCtrl: UntypedFormControl = new UntypedFormControl(this.data.inventurEintrag.menge, [Validators.required, Validators.min(0)]);

  constructor(public dialogRef: MatDialogRef<InventurEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data) {
    this.inventurEintrag = this.data.inventurEintrag;
  }

  ngOnInit(): void {
    this.mengeCtrl.valueChanges.subscribe(val => {
      if (this.mengeCtrl.valid) {
        this.inventurEintrag.menge = val;
      }
      this.mengeCtrl.markAsTouched();
    });
  }

  close() {
    this.inventurEintrag.menge = this.data.inventurEintrag.menge;
    this.dialogRef.close(null);
  }

  save() {
    this.dialogRef.close(this.inventurEintrag);
  }

}
