<div class="one-row" style="margin-top: 16px;">
    <span class="spacer"></span>
    <button *ngIf="!currentClient" mat-stroked-button color="accent" (click)="selectCurrentClient()">Betrieb ausw&auml;hlen</button>
    <div *ngIf="currentClient" class="one-row" style="width: 80%;">
        <span class="spacer"></span>
        <div>
            <span style="font-weight: bold; font-size: 1em;">{{currentClient?.bezeichnung_final}}</span><br>
            <span style="color: #7d7d7d; font-size: .8em;">Aktueller Betrieb</span>
        </div>
        <button mat-icon-button (click)="clear()">
            <mat-icon>cancel</mat-icon>
        </button>
        <span class="spacer"></span>
    </div>
    <span class="spacer"></span>
</div>
