import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArticleScanSelectedEvent } from '../../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import { Material } from '../../material/material';
import { Charge } from '../charges.component';

@Component({
  selector: 'kiene-create-charge-dialog',
  templateUrl: './create-charge-dialog.component.html',
  styleUrls: ['./create-charge-dialog.component.scss']
})
export class CreateChargeDialogComponent implements OnInit {

  chargeCtrl = new UntypedFormControl();
  mhdCtrl = new UntypedFormControl();

  material!: Material;

  constructor(public dialogRef: MatDialogRef<CreateChargeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) { }

  ngOnInit(): void {
    this.chargeCtrl.setValidators([Validators.required]);
    this.mhdCtrl.setValidators([Validators.required]);
  }

  setSelectedMaterial(event: ArticleScanSelectedEvent) {
    this.material = event.article;
    if (event.charge) {
      this.mhdCtrl.setValue(event.charge);
    }
    if (event.mhd) {
      this.mhdCtrl.setValue(event.mhd);
    }
  }

  close() {
    this.dialogRef.close();
  }


  save() {
    const c = new Charge();
    c.artikel_id = this.material.artikel_id;
    c.artikel = this.material.bezeichnung;
    c.charge = this.chargeCtrl.value;
    c.mhd = this.mhdCtrl.value;

    this.dialogRef.close(c);
  }

}
