import {
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from "@angular/core";
import { Gruppe } from "../../classes/gruppe";
import { GruppeTier } from "../../../gruppe-tier/classes/gruppe-tier";
import { MatDialog } from "@angular/material/dialog";
import { MessageService } from "../../../../services/message-service/message.service";
import { GruppenService } from "../../gruppen.service";
import { EinstallenService } from "../einstallen.service";
import { EinstallenMeldenDialogComponent } from "../einstallen-melden-dialog/einstallen-melden-dialog.component";
import { HttpParams } from "@angular/common/http";
import { Subscription } from "rxjs";
import { EinstallStatus } from "../../classes/einstall-status";
import {
    EinstallenMeldenDialogInput,
    EinstallenMeldenDialogResult,
} from "../../classes/einstallen-melden";
import { AppService } from "../../../../services/app/app.service";

@Component({
    selector: "kiene-einstallen-tiere-melden",
    templateUrl: "./einstallen-tiere-melden.component.html",
    styleUrls: ["./einstallen-tiere-melden.component.scss"],
})
export class EinstallenTiereMeldenComponent
    implements OnInit, OnDestroy {
    tierSubscription: Subscription;

    currentGruppe: Gruppe;
    eingestallteTiere: GruppeTier[] = [];

    Einstallstatus = EinstallStatus;

    filterParams: HttpParams;

    constructor(
        private einstallService: EinstallenService,
        private dialog: MatDialog,
        private messageService: MessageService,
        private gruppenService: GruppenService,
        private appService: AppService
    ) { }

    ngOnDestroy(): void {
        this.tierSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.currentGruppe = this.einstallService.getCurrentGruppe();
        if (this.currentGruppe) {
            this.loadTiere();
        }

        this.einstallService.watchCurrentGruppe().subscribe({
            next: (val) => {
                this.currentGruppe = val;
                this.loadTiere();
            },
        });

        this.einstallService.watchEingestallteTiere().subscribe({
            next: () => this.loadTiere(),
        });
    }

    private loadTiere(orderBy = "erstellt desc") {
        this.appService.setBlockOnLoading(true);
        this.eingestallteTiere = [];
        let params = new HttpParams().append("orderby", orderBy);
        if (this.filterParams) {
            this.filterParams.keys().forEach((key) => {
                params = params.append(key, this.filterParams.get(key));
            });
        }
        this.tierSubscription?.unsubscribe();
        this.tierSubscription = this.gruppenService
            .ladeGruppenTiere(this.currentGruppe?.gruppe_id, params)
            .subscribe({
                next: (resp) => {
                    this.eingestallteTiere = resp.records;
                    this.appService.setBlockOnLoading(false);
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        "Fehler beim Laden der Tiere!",
                        err
                    );
                    this.appService.setBlockOnLoading(false);
                }
            });
    }

    melden() {
        const input = new EinstallenMeldenDialogInput();
        input.gruppe_id = this.currentGruppe?.gruppe_id;

        const dialogRef = this.dialog.open(EinstallenMeldenDialogComponent, {
            width: "100%",
            maxWidth: "400px",
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: EinstallenMeldenDialogResult) => {
                if (result?.ok) {
                    this.loadTiere();
                } else if (result?.tiereOhneEinstalldatumAnzeigen) {
                    this.filtern("einstalldatum_notnull", "null");
                }
            },
        });
    }

    filtern(filterName: string, filterValue: string) {
        if (filterName && filterValue) {
            if (!this.filterParams) {
                this.filterParams = new HttpParams();
            }
            if (this.filterParams.has(filterName)) {
                this.filterParams = this.filterParams.delete(filterName);
                this.loadTiere();
            } else {
                this.filterParams = this.filterParams.set(
                    filterName,
                    filterValue
                );
                this.loadTiere();
            }
        }
    }

    filterZuruecksetzen() {
        this.filterParams = undefined;
        this.loadTiere();
    }

    isFilterActive(filterName: string) {
        if (this.filterParams && this.filterParams.has(filterName)) {
            return true;
        }
        return false;
    }
}
