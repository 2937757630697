import { Component, OnInit, Inject, ViewChild, ElementRef, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Tierart } from '../../../administration/tierarten/tierart';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { StepperSelectionEvent, CdkStep } from '@angular/cdk/stepper';
import { HttpParams } from '@angular/common/http';
import { Untersuchungsmaterial } from '../../untersuchungsmaterialien/untersuchungsmaterial';
import {
    Artikelstrukturelement,
    Probenbegleitscheinposition,
    ArtikelstrukturelementFlatNode,
    ProbenbegleitscheinArtikel,
    ArtikelKommentar
} from '../probenbegleitschein';
import { Material } from '../../../warehouse/material/material';
import { MatStepper } from '@angular/material/stepper';
import { MatSelect } from '@angular/material/select';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { MessageService } from '../../../services/message.service';

@Component({
    selector: 'kiene-add-probenbegleitschein-position-dialog',
    templateUrl: './add-probenbegleitschein-position-dialog.component.html',
    styleUrls: ['./add-probenbegleitschein-position-dialog.component.scss']
})
export class AddProbenbegleitscheinPositionDialogComponent implements OnInit, AfterViewInit {

    readyToSave = false;
    currentArtikelstrukturelement: Artikelstrukturelement;

    selectedTierart: Tierart;

    selectedNodes: ArtikelstrukturelementFlatNode[] = [];

    currentStepIndex = 0;

    availableMaterials: Material[] = [];

    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('tieranzahlInput') tieranzahlInput: MatInput;
    @ViewChild('tiergewichtInput') tiergewichtInput: MatInput;
    @ViewChild('kennzeichnungInput') kennzeichnungInput: MatInput;
    @ViewChild('untersuchungsmaterialSelect') untersuchungsmaterialSelect: MatSelect;
    @ViewChild('saveButton') saveButton: ElementRef;
    @ViewChildren('untersuchung') untersuchung: QueryList<MatSelect>;

    tieranzahlCtrl = new UntypedFormControl('', Validators.required);
    tiergewichtCtrl = new UntypedFormControl('', Validators.required);
    kennzeichnungCtrl = new UntypedFormControl('', Validators.required);

    untersuchungsmaterial: Untersuchungsmaterial;


    untersuchungsmaterialien: Untersuchungsmaterial[] = [];

    artikelstrukturelemente: Artikelstrukturelement[] = [];

    selectedProbenbegleitscheinArtikel: ProbenbegleitscheinArtikel[] = [];

    artikelKommentarListe: ArtikelKommentar[] = [];

    position: Probenbegleitscheinposition;

    kunde_id: number;

    constructor(public dialogRef: MatDialogRef<AddProbenbegleitscheinPositionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private messageService: MessageService) {
        if (this.data.position) {
            this.position = this.data.position;
        }
        this.kunde_id = this.data.kunde_id;
    }

    ngOnInit() {

        if (this.position) {
            const tierart = { tierart_id: this.position.tierart_id, tierart: this.position.tierart };
            this.selectedTierart = <Tierart>tierart;
            this.loadUntersuchungsmaterialien();
            this.tieranzahlCtrl.setValue(this.position.anzahl);
            this.tiergewichtCtrl.setValue(this.position.gewicht);
            this.kennzeichnungCtrl.setValue(this.position.kennzeichnung);
            this.untersuchungsmaterial = {
                untersuchungsmaterial_id: this.position.untersuchungsmaterial_id,
                bezeichnung: this.position.untersuchungsmaterial,
                tierart: this.position.tierart,
                tierart_id: this.position.tierart_id,
                bezeichnung_tierart: this.position.tierart

            };
            this.ladeArtikelFuerUntersuchungsmaterial(this.untersuchungsmaterial);

            this.checkReadyToSave();
        }

        this.tieranzahlCtrl.valueChanges.subscribe(value => this.checkReadyToSave());
        this.tiergewichtCtrl.valueChanges.subscribe(value => this.checkReadyToSave());
        this.kennzeichnungCtrl.valueChanges.subscribe(value => this.checkReadyToSave());


    }

    ngAfterViewInit() {
    }

    untersuchungsmaterialMapper(um1: Untersuchungsmaterial, um2: Untersuchungsmaterial) {
        if (um1 && um2) {
            return um1.untersuchungsmaterial_id === um2.untersuchungsmaterial_id;
        }
        return false;
    }

    setTierart(tierart: Tierart) {
        this.selectedTierart = tierart;
        this.loadUntersuchungsmaterialien();
        setTimeout(() => this.tieranzahlInput?.focus(), 300);
    }

    loadUntersuchungsmaterialien() {
        let params = new HttpParams();
        params = params.append('tierart_id', this.selectedTierart.tierart_id.toString());
        this.api.getAll(this.apiBaseUrl + 'untersuchungsmaterial/read.php', params).subscribe(response => {
            this.untersuchungsmaterialien = response.records;
        });
    }

    flattenTree() {

    }

    stepSelectionChanged(event: StepperSelectionEvent) {
        this.currentStepIndex = event.selectedIndex;
    }

    untersuchungsmaterialSelected(u: Untersuchungsmaterial) {
        this.untersuchungsmaterial = u;


        this.ladeArtikelFuerUntersuchungsmaterial(u);
        this.checkReadyToSave();
        this.stepper.next();
        // setTimeout(()=>{
        //   this.untersuchung.toArray()[0].focus();
        // }, 200);
    }

    // artikelstrukturAlsListe(as: Artikelstrukturelement[]): Material[] {
    //     const materialListe: Material[] = [];
    //
    //     for (const ase of as) {
    //         if (ase.artikel_id) {
    //             const m = new Material();
    //             m.artikel_id = ase.artikel_id;
    //             m.bezeichnung = ase.artikel;
    //             m.kuerzel = ase.kuerzel;
    //             m.lieferant = ase.lieferant;
    //             if (!materialListe.find(material => material.artikel_id === m.artikel_id)) {
    //                 materialListe.push(m);
    //             }
    //         } else {
    //             const tmpListe = this.artikelstrukturAlsListe(ase.kinder);
    //             for (const m of tmpListe) {
    //                 if (!materialListe.find(material => material.artikel_id === m.artikel_id)) {
    //                     materialListe.push(m);
    //                 }
    //             }
    //         }
    //     }
    //
    //     return materialListe;
    //
    // }

    filterMaterial(materialName: string) {
        const filterValue = materialName.toLowerCase();
        return this.availableMaterials.filter(material => material.bezeichnung.toLowerCase().indexOf(filterValue) >= 0);
    }

    materialMapper(m: Material) {
        return m ? m.bezeichnung : '';
    }


    listContainsMaterial(artikel_id: number, material: Material) {
        return artikel_id === material.artikel_id;
    }

    checkReadyToSave() {
        const partOne = this.selectedTierart != null && this.tieranzahlCtrl.valid && this.tiergewichtCtrl.valid && this.kennzeichnungCtrl.valid;
        let partTwo = false;
        if (this.selectedNodes && this.selectedNodes.length > 0) {
            partTwo = true;
        }
        this.readyToSave = partOne; // && partTwo;
    }

    cancel() {
        this.dialogRef.close(false);
    }

    setSelectedArticle(nodes: ArtikelstrukturelementFlatNode[]) {
        this.selectedNodes = nodes;
    }

    save() {
        if (!this.position) {
            this.position = new Probenbegleitscheinposition();
        }

        this.position.tierart = this.selectedTierart.tierart;
        this.position.tierart_id = this.selectedTierart.tierart_id;
        this.position.anzahl = this.tieranzahlCtrl.value;
        this.position.gewicht = this.tiergewichtCtrl.value;
        this.position.kennzeichnung = this.kennzeichnungCtrl.value;
        this.position.untersuchungsmaterial = this.untersuchungsmaterial.bezeichnung;
        this.position.untersuchungsmaterial_id = this.untersuchungsmaterial.untersuchungsmaterial_id;
        this.position.positionen = this.createPositionen();
        this.dialogRef.close(this.position);
    }

    createPositionen() {
        const m: ProbenbegleitscheinArtikel[] = [];

        for (const n of this.artikelKommentarListe) {
            if (n.checkboxCtrl.value) {
                const mat = new ProbenbegleitscheinArtikel();
                mat.artikel_id = n.artikel.artikel_id;
                mat.bezeichnung = n.artikel.bezeichnung;
                mat.kommentar = n.kommentarCtrl.value;
                m.push(mat);
            }
        }
        return m;
    }

    private ladeArtikelFuerUntersuchungsmaterial(u: Untersuchungsmaterial) {
        this.availableMaterials = [];
        const params = new HttpParams().set('untersuchungsmaterial_id', u.untersuchungsmaterial_id.toString()).set('kunde_id', this.kunde_id?.toString());
        this.api.getAll(this.apiBaseUrl + 'untersuchungsmaterial/read_artikel.php', params).subscribe({
            next: response => {
                this.availableMaterials = response.records;
                this.artikelKommentarListe = [];
                for (const am of this.availableMaterials) {
                    let positionIndex = -1;
                    if (this.position && this.position.positionen?.length > 0) {
                        positionIndex = this.position.positionen.findIndex((p) => {
                            return p.artikel_id === am.artikel_id;
                        });
                    } else {
                        positionIndex = -1;
                    }
                    const ak = new ArtikelKommentar(am, new FormControl(positionIndex > -1 ? this.position.positionen[positionIndex].kommentar : undefined), new FormControl(positionIndex > -1 ? true : false));
                    this.artikelKommentarListe.push(ak);
                }
            },
            error: err => {
                this.messageService.errorMessage('Fehler', err);
            }
        });
    }
}
