import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kiene-check-behandlung-abgabe-dialog',
  templateUrl: './check-behandlung-abgabe-dialog.component.html',
  styleUrls: ['./check-behandlung-abgabe-dialog.component.scss']
})
export class CheckBehandlungAbgabeDialogComponent implements OnInit {

  yesNoCtrl = new UntypedFormControl();

  constructor(public dialogRef: MatDialogRef<CheckBehandlungAbgabeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  cancel() {
    return this.dialogRef.close(null);
  }

  save() {
    return this.dialogRef.close(this.yesNoCtrl.value);
  }

}
