import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Supplier} from '../../../warehouse/supplier/supplier';
import {SupplierService} from '../../../warehouse/supplier/supplier.service';
import {BestellungExtern, BestellungExternDokument, BestellungExternPosition} from '../bestellung-extern';
import {MatDialog} from '@angular/material/dialog';
import {BestellungExternService} from '../bestellung-extern.service';
import {FileUploadService} from '../../../dialogs/file-upload-dialog/file-upload.service';
import {ConfirmDeleteDialogComponent} from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {MessageService} from '../../../services/message.service';
import {UntypedFormControl, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import {Material} from '../../../warehouse/material/material';
import {MaterialService} from '../../../warehouse/material/material.service';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {HttpParams} from '@angular/common/http';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {KieneSession} from '../../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';

@Component({
    selector: 'kiene-bestellung-extern-details',
    templateUrl: './bestellung-extern-details.component.html',
    styleUrls: ['./bestellung-extern-details.component.scss'],
})
export class BestellungExternDetailsComponent implements OnInit {
    isLoading = false;

    editAllowed = true;

    bestellungExternId: string;
    kieneSession: KieneSession;
    supplier: Supplier = new Supplier();

    materials: Observable<Material[]>;

    materialCtrl: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
    positionAmountCtrl: UntypedFormControl = new UntypedFormControl('', [
        Validators.required,
        Validators.min(1),
    ]);

    deliveryNotes: BestellungExternDokument[] = [];
    invoices: BestellungExternDokument[] = [];

    bestellungExtern: BestellungExtern = new BestellungExtern();

    canUserReleaseBestellungExtern = false;

    @ViewChild('materialInput')
    materialInput: ElementRef;

    @ViewChild('amountInput')
    amountInput: ElementRef;

    constructor(
        private pdfViewerService: PdfViewerDesktopService,
        private messageService: MessageService,
        private dialog: MatDialog,
        private fileUploadService: FileUploadService,
        private service: BestellungExternService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private activatedRoute: ActivatedRoute,
        private materialService: MaterialService,
        private supplierService: SupplierService,
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        this.bestellungExternId =
            this.activatedRoute.snapshot.paramMap.get('id');
    }

    ngOnInit() {
        this.autocompleteMaterial();
        this.kieneSession = this.localStorageService.getCurrentUser();

        if (this.bestellungExternId) {
            if (this.bestellungExternId !== 'neu') {
                const id: number = Number.parseInt(this.bestellungExternId, 10);
                this.loadBestellungExtern(id);
            } else {
                this.editAllowed = true;
            }
        }
    }

    isUserAllowedToReleaseBestellungExtern(): void {
        if (
            this.kieneSession.benutzerrecht_ids.includes(1766) &&
            this.bestellungExtern.status_id === 22
        ) {
            this.canUserReleaseBestellungExtern = true;
        } else {
            this.canUserReleaseBestellungExtern = false;
        }
    }

    goBack() {
        this.router.navigate(['/wawi/bestellung-extern/']);
    }

    loadBestellungExtern(id: number) {
        this.isLoading = true;
        const params = new HttpParams().set('bestellung_extern_id', id);
        this.api.get(this.apiBaseUrl + 'bestellung_extern/read_one.php', params).subscribe({
            next: (response) => {
                this.bestellungExtern = response;

                if (this.bestellungExtern.status_id <= 22) {
                    this.editAllowed = true;
                } else {
                    this.editAllowed = false;
                }
                this.isLoading = false;
                this.loadSupplier(this.bestellungExtern.lieferant_id);
                this.isUserAllowedToReleaseBestellungExtern();
                this.loadDeliveryNotes();
                this.loadInvoices();
            },
            error: (error) => {
                this.isLoading = false;
            }
        });
    }

    loadSupplier(id: number) {
        this.isLoading = true;
        this.supplierService.getSupplierById(id).subscribe(
            (response) => {
                this.supplier = response;
                this.isLoading = false;
            },
            (error) => {
                this.isLoading = false;
            }
        );
    }

    autocompleteMaterial() {
        this.materials = this.materialCtrl.valueChanges.pipe(
            debounceTime(100),
            startWith(''),
            switchMap((value) => {
                return this.searchMaterial(value);
            })
        );
    }

    searchMaterial(value: string) {
        if (this.supplier !== undefined) {
            return this.materialService
                .searchSupplierMaterial(value, this.supplier)
                .pipe(
                    debounceTime(300),
                    distinctUntilChanged(),
                    map((response) => response.records)
                );
        } else {
            return this.materialService.searchSupplierMaterial(value).pipe(
                debounceTime(300),
                distinctUntilChanged(),
                map((response) => response.records)
            );
        }
    }

    materialMapper(artikel: Material) {
        if (artikel) {
            return artikel.artikel_me;
        }
    }

    materialSelected() {
        this.amountInput.nativeElement?.focus();
    }

    removePosition(p: BestellungExternPosition) {
        const index = this.bestellungExtern.positionen.indexOf(p);
        this.bestellungExtern.positionen.splice(index, 1);
    }

    loadDeliveryNotes() {
        if (!this.localStorageService.currentUserHasPermission(1741)) {
            return;
        }
        this.service
            .getDeliveryNotes(this.bestellungExtern)
            .subscribe((response) => {
                this.deliveryNotes = response.records;
            });
    }

    loadInvoices() {
        if (!this.localStorageService.currentUserHasPermission(1751)) {
            return;
        }
        this.service
            .getInvoices(this.bestellungExtern)
            .subscribe((response) => {
                this.invoices = response.records;
            });
    }

    saveBestellungExtern() {
        if (this.bestellungExtern != null) {
            if (this.bestellungExtern.status_id_neu === undefined) {
                this.bestellungExtern.status_id_neu = 21;
            }

            this.bestellungExtern.lieferant_id = this.supplier.lieferant_id;

            this.service
                .updateBestellungExtern(this.bestellungExtern)
                .subscribe(
                    (response) => {
                        this.bestellungExtern = response;
                        // this.loadBestellungExternPositionen(this.bestellungExtern.bestellung_extern_id);
                        this.messageService.infoMessage(
                            'Die Bestellung ' +
                            this.bestellungExtern.bestellung_extern_id +
                            ' wurde erfolgreich gespeichert!'
                        );
                        this.router.navigate([
                            '/wawi/bestellung-extern',
                            this.bestellungExtern.bestellung_extern_id,
                        ]);
                        this.bestellungExternId =
                            this.bestellungExtern.bestellung_extern_id.toString();
                        this.loadBestellungExtern(
                            this.bestellungExtern.bestellung_extern_id
                        );
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Fehler! Die Bestellung konnte nicht gespeichert werden! ',
                            error
                        );
                        this.loadBestellungExtern(
                            Number.parseInt(this.bestellungExternId, 10)
                        );
                    }
                );
        }
    }

    release() {
        this.bestellungExtern.status_id_neu = 22;

        this.service.updateBestellungExtern(this.bestellungExtern).subscribe(
            (response) => {
                this.bestellungExtern = response;
                this.isUserAllowedToReleaseBestellungExtern();
                this.messageService.infoMessage(
                    'Die Bestellung ' +
                    this.bestellungExtern.bestellung_extern_id +
                    ' wurde freigegeben!'
                );
            },
            (error) => {
                this.messageService.errorMessage(
                    'Fehler! Die Bestellung konnte nicht freigegeben werden! ',
                    error
                );
                this.loadBestellungExtern(
                    Number.parseInt(this.bestellungExternId, 10)
                );
            }
        );
    }

    order() {
        if (this.bestellungExtern != null) {
            this.bestellungExtern.status_id_neu = 23;

            this.service
                .updateBestellungExtern(this.bestellungExtern)
                .subscribe(
                    (response) => {
                        this.bestellungExtern = response;
                        this.editAllowed = false;
                        this.messageService.infoMessage(
                            'Die Bestellung ' +
                            this.bestellungExtern.bestellung_extern_id +
                            ' wurde erfolgreich aufgegeben!'
                        );
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Fehler! Die Bestellung konnte nicht aufgegeben werden! ',
                            error
                        );
                        this.loadBestellungExtern(
                            Number.parseInt(this.bestellungExternId, 10)
                        );
                    }
                );
        }
    }

    markAsBooked() {
        if (this.bestellungExtern != null) {
            this.bestellungExtern.status_id_neu = 29;

            this.service
                .updateBestellungExtern(this.bestellungExtern)
                .subscribe({
                    next: (response) => {
                        this.bestellungExtern = response;
                        this.editAllowed = false;
                        this.messageService.infoMessage(
                            'Die Bestellung ' +
                            this.bestellungExtern.bestellung_extern_id +
                            ' wurde erfolgreich als bestellt markiert!'
                        );
                    },
                    error: (error) => {
                        this.messageService.errorMessage(
                            'Fehler! Die Bestellung konnte nicht als bestellt markiert werden! ',
                            error
                        );
                        this.loadBestellungExtern(
                            Number.parseInt(this.bestellungExternId, 10)
                        );
                    }
                });
        }
    }

    close() {
        if (this.bestellungExtern != null) {
            this.bestellungExtern.status_id_neu = 30; // geliefert manuell

            this.service
                .updateBestellungExtern(this.bestellungExtern)
                .subscribe(
                    (response) => {
                        this.bestellungExtern = response;
                        this.editAllowed = false;
                        this.messageService.infoMessage(
                            'Die Bestellung ' +
                            this.bestellungExtern.bestellung_extern_id +
                            ' wurde erfolgreich geschlossen!'
                        );
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Fehler! Die Bestellung konnte nicht geschlossen werden! ',
                            error
                        );
                        this.loadBestellungExtern(
                            Number.parseInt(this.bestellungExternId, 10)
                        );
                    }
                );
        }
    }

    openAddDeliveryNoteDialog() {
        const ids: number[] = [];
        ids.push(this.bestellungExtern.bestellung_extern_id);
        this.fileUploadService.openAddFilesDialogIds(
            ids,
            'bestellung_extern_ids',
            'bestellung_extern_dokument/create_lieferschein.php',
            () => {
                this.service
                    .getDeliveryNotes(this.bestellungExtern)
                    .subscribe((response) => {
                        this.deliveryNotes = response.records;
                    });
            },
            () => {
            }
        );
    }

    openAddInvoiceDialog() {
        const ids: number[] = [];
        ids.push(this.bestellungExtern.bestellung_extern_id);
        this.fileUploadService.openAddFilesDialogIds(
            ids,
            'bestellung_extern_ids',
            'bestellung_extern_dokument/create_rechnung.php',
            () => {
                this.service
                    .getInvoices(this.bestellungExtern)
                    .subscribe((response) => {
                        this.invoices = response.records;
                    });
            },
            () => {
            }
        );
    }

    showDeliveryNotePdf(bed: BestellungExternDokument) {
        this.pdfViewerService.openPdfViewer(
            new PdfViewerDialogInput(
                'bestellung_extern_dokument/read_pdf_lieferschein.php',
                new HttpParams().set('bed_id', bed?.bed_id?.toString()),
                bed.dateiname
            )
        );
    }

    showInvoicePdf(bed: BestellungExternDokument) {
        this.pdfViewerService.openPdfViewer(
            new PdfViewerDialogInput(
                'bestellung_extern_dokument/read_pdf_rechnung.php',
                new HttpParams().set('bed_id', bed?.bed_id?.toString()),
                bed.dateiname
            )
        );
    }

    openRemoveDokument(bed: BestellungExternDokument) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '450px',
            data: {
                title: 'Löschen',
                text: 'Wollen Sie ' + bed.dateiname + ' wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.service.deleteBestellungExternDokument(bed).subscribe(
                    (response) => {
                        this.messageService.infoMessage(
                            'Datei ' +
                            bed.dateiname +
                            ' wurde erfolgreich entfernt!'
                        );
                        this.loadBestellungExtern(
                            Number.parseInt(this.bestellungExternId, 10)
                        );
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Die Datei ' +
                            bed.dateiname +
                            ' konnte nicht entfernt werden! Fehler: ',
                            error
                        );
                    }
                );
            }
        });
    }

    invoiceOption(option: string, iv: BestellungExternDokument) {
        if (option === 'loeschen') {
            this.openRemoveDokument(iv);
        }
    }


}
