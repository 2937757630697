import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Version } from './versioninfo';

@Component({
  selector: 'kiene-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss']
})
export class VersionInfoComponent implements OnInit {

  versioninfo: Version[] = [];

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.loadVersioninfo();
  }

  loadVersioninfo() {
    this.http.get<Version[]>('./assets/versioninfo.json').subscribe(data => {
      this.versioninfo = data;
    });
  }

}
