import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Client } from '../../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {
    PdfViewerDesktopService
} from '../../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {
    Benutzerprofil
} from '../../../../../../projects/kiene-core/src/lib/components/stammdaten/mein-profil/mein-profil';
import { Signature } from '../../../../../../projects/kiene-core/src/lib/components/unterschriften/signature';
import {
    UnterschriftenService
} from '../../../../../../projects/kiene-core/src/lib/components/unterschriften/unterschriften.service';
import { API_BASE_URL_SERVICE } from '../../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {
    LocalStorageService
} from '../../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { Textbaustein } from '../../../../administration/text-baustein/text-baustein';
import { Vet } from '../../../../administration/vet/vet';
import { KieneTableService } from '../../../../components/kiene-table/kiene-table.service';
import { TextbausteinConfig } from '../../../../directives/text-baustein.directive';
import { MessageService } from '../../../../services/message.service';
import { MassnahmenplanSchwein } from '../massnahmenplan-schwein';
import { MassnahmenplanSchweinService } from '../massnahmenplan-schwein.service';

@Component({
    selector: 'kiene-massnahmenplan-schwein-details',
    templateUrl: './massnahmenplan-schwein-details.component.html',
    styleUrls: ['./massnahmenplan-schwein-details.component.scss'],
})
export class MassnahmenplanSchweinDetailsComponent
    implements OnInit, OnDestroy {
    permissionAbschliessen = 1185;

    tierartCtrl = new FormControl();
    _0_1_2_halbjahr = new UntypedFormControl();
    _0_1_3_jahr = new UntypedFormControl();
    _0_1_4_haeuffigkeit = new UntypedFormControl();

    // _1_1_1_stallbezeichnung = new UntypedFormControl();
    // _1_1_2_anzahl_staelle = new UntypedFormControl();
    _1_1_3_sauenhalter = new UntypedFormControl();

    _2_1_1_betreuungsvertrag = new UntypedFormControl();

    _2_2_1_weitere_aerzte = new UntypedFormControl();
    _2_2_1_weitere_aerzte_anschrift = new UntypedFormControl();

    _2_3_1_weitere_berater = new UntypedFormControl();
    _2_3_1_weitere_berater_anschrift = new UntypedFormControl();

    // _3_1_1 = new UntypedFormControl();
    _3_1_2 = new UntypedFormControl();
    _3_1_3 = new UntypedFormControl();
    _3_1_4 = new UntypedFormControl();
    _3_1_5 = new UntypedFormControl();
    _3_1_6 = new UntypedFormControl();
    _3_1_7_qs_system_teilnehmer = new UntypedFormControl();
    _3_1_7_qs_system_teilnehmer_text = new UntypedFormControl();
    _3_1_8_bio_siegel = new UntypedFormControl();
    _3_1_8_bio_siegel_text = new UntypedFormControl();
    _3_1_9_tierwohl = new UntypedFormControl();

    _3_2_1_geschlossenes_system = new UntypedFormControl();
    _3_2_2_zukauf = new UntypedFormControl();
    _3_2_3_herkuenfte = new UntypedFormControl();

    _3_3_1_alter_jungsau = new UntypedFormControl();
    _3_3_2_mastdauer = new UntypedFormControl();

    _3_4_1_besatzdichte = new UntypedFormControl();
    _3_4_2tiere_per_bucht = new UntypedFormControl();

    _3_5_1_leerstand_zeit = new UntypedFormControl();

    _3_6_1_vollspalten = new UntypedFormControl();
    _3_6_2_teilspalten = new UntypedFormControl();
    _3_6_3_beton = new UntypedFormControl();
    _3_6_4_kunststoff = new UntypedFormControl();
    _3_6_5_stroh = new UntypedFormControl();
    _3_6_6_sonstiges = new UntypedFormControl();
    _3_6_6_sonstiges_text = new UntypedFormControl();
    _3_6_5_planbefestigt = new UntypedFormControl();

    _3_7_1_kette = new UntypedFormControl();
    _3_7_2_holz = new UntypedFormControl();
    _3_7_3_seil = new UntypedFormControl();
    _3_7_4_heu = new UntypedFormControl();
    _3_7_5_sonstiges = new UntypedFormControl();
    _3_7_6_anzahl_bucht = new UntypedFormControl();

    _4_1_1_zwangslueftung = new UntypedFormControl();
    _4_1_2_deckenlueftung = new UntypedFormControl();
    _4_1_3_tuerganglueftung = new UntypedFormControl();
    _4_1_4_freie_lueftung = new UntypedFormControl();
    _4_1_5_unterflurabsaugung = new UntypedFormControl();
    _4_1_6_sonstiges_1 = new UntypedFormControl();
    _4_1_7_sonstiges_2 = new UntypedFormControl();
    _4_1_8_heizkanone = new UntypedFormControl();
    _4_1_9_kenvektor = new UntypedFormControl();
    _4_1_10_deltarohre = new UntypedFormControl();
    _4_1_11_sonstiges_3 = new UntypedFormControl();
    _4_1_12_waermelampe = new UntypedFormControl();
    _4_1_12_beheitzte_bodenlampe = new UntypedFormControl();
    _4_1_12_sonstiges_4 = new UntypedFormControl();
    _4_1_13_regelmaessig = new UntypedFormControl();
    _4_1_14_bei_bedarf = new UntypedFormControl();

    _5_1_1_breiautomat = new UntypedFormControl();
    _5_1_2_trockenautomat = new UntypedFormControl();
    _5_1_3_fluessigfutter = new UntypedFormControl();
    _5_1_4_mehlfoermig = new UntypedFormControl();
    _5_1_5_garuliert = new UntypedFormControl();
    _5_1_6_pelletiert = new UntypedFormControl();
    _5_1_7_sonstiges = new UntypedFormControl();
    _5_1_8_anzahl_tiere = new UntypedFormControl();
    _5_1_9_trockenfuetterung = new UntypedFormControl();
    _5_1_10_abruffuetterung = new UntypedFormControl();
    _5_1_11_raufutter_sau = new UntypedFormControl();

    _5_2_1_brunnenwasser = new UntypedFormControl();
    _5_2_2_stadtwasser = new UntypedFormControl();
    _5_2_3_letzte_wasseruntersuchung = new UntypedFormControl();
    _5_2_4_letzte_wasseruntersuchung_text = new UntypedFormControl();
    _5_2_5_anzahl_tiere = new UntypedFormControl();
    _5_2_6_art_traenke = new UntypedFormControl();

    _6_1_1 = new UntypedFormControl();
    _6_1_2 = new UntypedFormControl();
    _6_1_3 = new UntypedFormControl();
    _6_1_4 = new UntypedFormControl();
    _6_1_5 = new UntypedFormControl();
    _6_1_6 = new UntypedFormControl();
    _6_1_7 = new UntypedFormControl();

    _6_2_1 = new UntypedFormControl();

    _6_3_1 = new UntypedFormControl();

    _7_1_1_art_schutzkleidung = new UntypedFormControl();
    _7_1_2_art_hygieneschleuse = new UntypedFormControl();

    _7_2_1_reinigung_stall = new UntypedFormControl();
    _7_2_2_desinfektion_stall = new UntypedFormControl();
    // _7_2_3_reinigung_stall = new UntypedFormControl();
    // _7_2_4_reinigung_stall_text = new UntypedFormControl();
    _7_2_5_reingung_selbst = new UntypedFormControl();
    // _7_2_6_desinfektion = new UntypedFormControl();
    _7_2_6_reinigung_desinfektion = new UntypedFormControl();
    _7_2_6_nach_antibiotika = new UntypedFormControl();
    _7_2_7_reinigung_futterleitungen = new UntypedFormControl();
    _7_2_8_reinigung_futtertroege = new UntypedFormControl();
    _7_2_9_reinigung_futtersilo = new UntypedFormControl();
    _7_2_10_nach_antibiotika = new UntypedFormControl();
    // _7_2_11 = new UntypedFormControl();
    _7_2_12_durch_fremdfirma = new UntypedFormControl();
    _7_2_13_selbst = new UntypedFormControl();

    _7_3_1_eigenes_ammenabteil = new UntypedFormControl();
    _7_5_y_n = new UntypedFormControl();
    _7_5_y_n_anzahl = new UntypedFormControl();
    _7_3_4_mit_gummimatte = new UntypedFormControl();
    _7_3_5_mit_stroh = new UntypedFormControl();
    _7_3_6_sonstiges = new UntypedFormControl();
    _7_3_7_sonst_absonderung = new UntypedFormControl();
    _7_3_8_sonst_absonderung_text = new UntypedFormControl();

    _8_1_1 = new UntypedFormControl();
    _8_1_2 = new UntypedFormControl();
    _8_1_3 = new UntypedFormControl();
    _8_1_4 = new UntypedFormControl();
    _8_1_5 = new UntypedFormControl();
    _8_1_6 = new UntypedFormControl();
    // _8_1_7 = new UntypedFormControl();
    _8_1_8 = new UntypedFormControl();

    _9_1_1_atemwege = new UntypedFormControl();
    _9_1_2_verdauung = new UntypedFormControl();
    _9_1_3_bewegung = new UntypedFormControl();
    // _9_1_4_sonstiges = new UntypedFormControl();
    _9_1_5_sonstiges_text = new UntypedFormControl();

    _9_2_1_anzahl_verendet = new UntypedFormControl();
    _9_2_2_verlust_halbjahr = new UntypedFormControl();

    _9_3_1 = new UntypedFormControl();

    _9_4_erregernachweis_y_n_ctrl = new UntypedFormControl();
    _erregernachweis_text = new UntypedFormControl();
    _9_4_sektion_y_n_ctrl = new UntypedFormControl();
    _9_4_4 = new UntypedFormControl();
    _9_4_5 = new UntypedFormControl();
    _9_4_6 = new UntypedFormControl();

    _10_1_1 = new UntypedFormControl();

    _10_2_1 = new UntypedFormControl();

    _10_3_1 = new UntypedFormControl();

    _11_1_1 = new UntypedFormControl();

    _11_2_1 = new UntypedFormControl();

    _11_3_1 = new UntypedFormControl();
    _11_3_2 = new UntypedFormControl();
    _11_3_3 = new UntypedFormControl();

    bbpAnhaengenCtrl = new UntypedFormControl();
    befundeAnhaengenCtrl = new UntypedFormControl();

    _10_focus = false;
    _11_focus = false;

    mps = new MassnahmenplanSchwein();
    // currentClient = new Client();
    mpsId: string;

    zeitraum_von: string = null;
    zeitraum_bis: string = null;
    anzahl_befunde: number = null;
    anzahl_antibiogramme: number = null;
    anzahl_abgabebelege: number = null;
    antibiogrammeSubscription: Subscription;
    befundeSubscription: Subscription;
    abgabebelegeSubscription: Subscription;

    routerSub: Subscription;

    kundeHatSchweine = true;

    showTierarztSelector = false;
    abrechnenCtrl = new FormControl(true);

    benutzerprofil: Benutzerprofil;


    //TODO NEUE FPRMCONTROLS
    _1_1_3_anzahl_Sauen = new UntypedFormControl();
    _1_1_3_anzahl_JungSauen = new UntypedFormControl();
    _1_1_3_stallnummer_1 = new UntypedFormControl();
    _1_1_3_stallnummer_2 = new UntypedFormControl();
    _1_1_3_stallnummer_3 = new UntypedFormControl();
    _1_1_3_stallnummer_4 = new UntypedFormControl();
    _1_1_3_stallplatze_1 = new UntypedFormControl();
    _1_1_3_stallplatze_2 = new UntypedFormControl();
    _1_1_3_stallplatze_3 = new UntypedFormControl();
    _1_1_3_stallplatze_4 = new UntypedFormControl();
    _1_1_3_isolations_stall = new UntypedFormControl();
    _2_3_1_integration = new UntypedFormControl();
    _3_1_9_tierwohl_text = new UntypedFormControl();
    _3_8_1_konventionelle_buchten = new UntypedFormControl();
    _3_8_2_teilspalten = new UntypedFormControl();
    _3_8_3_betonspalten = new UntypedFormControl();
    _3_8_3_bewegungsbuchten = new UntypedFormControl();
    _3_8_3_freie_abferkelung = new UntypedFormControl();
    _3_8_3_freie_abferkelbuchten = new UntypedFormControl();
    _3_8_3_freie_abferkelplaetze = new UntypedFormControl();
    _3_8_4_kunststoffspalten = new UntypedFormControl();
    _3_8_5_strohspalten = new UntypedFormControl();
    _3_8_6_planbefestigt = new UntypedFormControl();
    _3_8_7_sonstiges = new UntypedFormControl();
    _3_8_8_rhytmus_1 = new UntypedFormControl();
    _3_8_8_rhytmus_2 = new UntypedFormControl();
    _3_8_8_rhytmus_3 = new UntypedFormControl();
    _3_8_8_rhytmus_4 = new UntypedFormControl();
    _3_8_8_rhytmus_5 = new UntypedFormControl();
    _3_8_8_rhytmus_6 = new UntypedFormControl();
    _3_8_8_rhytmus_7 = new UntypedFormControl();
    _3_8_rhythmus_sonstiger = new UntypedFormControl();
    _3_9_1_absetzalter = new UntypedFormControl();
    _3_9_2_absetzgewicht = new UntypedFormControl();
    _3_9_3_waschung = new UntypedFormControl();
    _3_9_4_umsetzung = new UntypedFormControl();
    _3_9_5_kastrieren = new UntypedFormControl();
    _3_9_6_schwaenze_kuerzen = new UntypedFormControl();
    _3_9_7_zaehne_schleiffen = new UntypedFormControl();
    _3_9_8_remontierungsrate = new UntypedFormControl();
    _3_9_9_abgesetzte_ferkel = new UntypedFormControl();
    _3_9_10_traechtigkeitsdauer = new UntypedFormControl();
    _3_9_11_wuerfe = new UntypedFormControl();
    _3_9_12_abferkelrate = new UntypedFormControl();
    _3_9_13_lebendgeboren = new UntypedFormControl();
    _3_9_14_erstbelegungsalter = new UntypedFormControl();
    _5_3_1_zufuetterung_text = new UntypedFormControl();
    _5_3_2_kuenstliche_amme = new UntypedFormControl();
    _5_3_4_zusaetliche_milch = new UntypedFormControl();
    _5_3_3_natuerliche_amme = new UntypedFormControl();
    _7_1_2_matten_vorhanden = new UntypedFormControl();
    _7_1_2_matten_vorhanden_text = new UntypedFormControl();
    _7_1_4_reinigung_stall_text = new UntypedFormControl();
    _7_1_5_reinigung_stall_selbst = new UntypedFormControl();
    _7_1_6_reinigung_stall_fremdfirma = new UntypedFormControl(); R
    _7_1_6_desinfektion_stall_text = new UntypedFormControl();
    _7_1_6_desinfektion_stall_selbst = new UntypedFormControl();
    _7_1_6_desinfektion_stall_fremdfirma = new UntypedFormControl();
    _7_1_14_doku_vorhanden = new UntypedFormControl();
    _7_2_7_routine = new UntypedFormControl();
    _7_2_5_reingung_desinfektion_text = new UntypedFormControl();
    _9_1_3_1_zns = new UntypedFormControl();
    _9_1_3_2_gesaeuge = new UntypedFormControl();
    _9_1_3_3_geschlecht = new UntypedFormControl();

    _9_verlustrate = new UntypedFormControl();
    _8_impfschema_zuchtschweine = new UntypedFormControl();
    _8_impfschema_saugferkel = new UntypedFormControl();

    constructor(
        private localStorageService: LocalStorageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private activatedRoute: ActivatedRoute,
        private pdf: PdfViewerDesktopService,
        private router: Router,
        private locationService: Location,
        private mnpSchweinService: MassnahmenplanSchweinService,
        private unterschriftService: UnterschriftenService
    ) {
        this.mpsId = this.activatedRoute.snapshot.paramMap.get('id');
    }


    istFerkelOderMastschwein(): boolean {
        return this.tierartCtrl?.value === 19 || this.tierartCtrl?.value === 15;
    }

    istSaugferkelOderZuchtschwein(): boolean {
        return this.tierartCtrl?.value === 17 || this.tierartCtrl?.value === 2;
    }

    pruefeKundeTierart(kundeId: number) {
        const params = new HttpParams().set('kunde_id', kundeId.toString()).set('mnp_schwein', '1');
        this.api.get(this.apiBaseUrl + 'kunde_tierart/read.php', params).subscribe(
            (response) => {
                if (response.count <= 0) {
                    this.kundeHatSchweine = false;
                } else {
                    this.kundeHatSchweine = true;
                }
            },
            (error) => {
                this.messageService.errorMessage(
                    'Tierarten des Kunden konnten nicht abgefragt werden!',
                    null
                );
            }
        );
    }

    ngOnInit() {
        this.ladeBenutzerprofil();

        if (!this.mps.tierarzt_id) {
            this.mps.tierarzt_id =
                this.localStorageService.getCurrentUser().tierarzt_id;
        }

        if (this.localStorageService.isCurrentUserVerwaltung()) {
            this.showTierarztSelector = true;
        }

        if (this.mpsId !== 'neu') {
            this.loadMps();
        } else {
            this._11_3_2.setValue(new Date());
            this._10_2_1.setValue(new Date());

            if (this.localStorageService.getCurrentClient() !== null) {
                if (!this.mps.kunde_id) {
                    this.setKunde(this.localStorageService.getCurrentClient());
                }
            }
        }

        this.initForm();

        this.routerSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (this.mps.status_id !== 62) {
                    this.save(false);
                }
            }
        });

        this._0_1_2_halbjahr.valueChanges.subscribe((value) => {
            this.mps._0_halbjahr = value;
            this.calculateZeitraum();
        });

        this._0_1_3_jahr.valueChanges.subscribe((value) => {
            this.mps._0_jahr = value;
            this.calculateZeitraum();
        });

        this.localStorageService.watchCurrentClient().subscribe((client) => {
            if (client && !this.mps.kunde_id) {
                this.setKunde(client);
                this.calculateZeitraum();
            }
        });

        this._9_4_erregernachweis_y_n_ctrl.valueChanges.subscribe((value) => {
            if (value === true) {
                this.befundeAnhaengenCtrl.setValue(true);
            }
        });

        this._9_4_sektion_y_n_ctrl.valueChanges.subscribe((value) => {
            if (value === true) {
                this.befundeAnhaengenCtrl.setValue(true);
            }
        });
    }

    ladeBenutzerprofil() {
        this.api.get(this.apiBaseUrl + 'benutzerprofil/read_one_kundenportal.php').subscribe({
            next: response => {
                this.benutzerprofil = <Benutzerprofil>response;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    setKunde(client: Client) {
        this.mps.kunde_id = client.kunde_id;
        this.mps.kunde = client.bezeichnung_final;
        this.mps.kunde_ort = client.ort_final;
        this.mps.kunde_plz = client.plz_final;
        this.mps.kunde_strasse = client.strasse_final;
        this.mps.kunde_telefon_1 = client.telefon_1;
        this.mps.strasse_lieferung = client.strasse_lieferung;
        this.mps.plz_lieferung = client.plz_lieferung;
        this.mps.ort_lieferung = client.ort_lieferung;
        this.mps.vvvo = client.vvvo;

        this.mps._11_2_ort = client.ort_final;
        this._11_3_1.setValue(client.ort_final);

        this.pruefeKundeTierart(this.mps.kunde_id);
    }

    ngOnDestroy() {
        this.routerSub.unsubscribe();
    }

    goBack() {
        this.locationService.back();
    }

    loadMps() {
        const params = new HttpParams().set('mnp_schwein_id', this.mpsId);
        this.api.get(
            this.apiBaseUrl + 'massnahmenplan_schwein/read_one.php',
            params
        ).subscribe((mps) => {
            this.mps = mps;

            if (!this.mps.tierarzt_id) {
                this.mps.tierarzt_id =
                    this.localStorageService.getCurrentUser().tierarzt_id;
            }

            this.initForm();
            if (this.mps.status_id !== 62) {
                // this.prepareSigning();
            }
        });
    }

    calculateHalbjahr() {
        const now = new Date();
        now.setMonth(now.getMonth() - 6);
        const year = now.getFullYear();
        const halbjahr = now.getMonth() <= 5 ? 1 : 2;
        this._0_1_2_halbjahr.setValue(halbjahr);
        this._0_1_3_jahr.setValue(year);
        this.mps._0_halbjahr = halbjahr;
        this.mps._0_jahr = year;
    }

    getTrueFalse(value: number): boolean {
        return value === 1 ? true : false;
    }

    initForm() {

        if (this.mps.abrechnen === 0) {
            this.abrechnenCtrl.setValue(false);
        } else if (this.mps.abrechnen === 1) {
            this.abrechnenCtrl.setValue(true);
        }
        if (this.mps.abgabebeleg_id) {
            this.abrechnenCtrl.disable();
        }

        // 0
        this.tierartCtrl.setValue(
            this.mps.tierart_id
        );
        if (this.mpsId === 'neu') {
            this.calculateHalbjahr();
        }
        this.calculateZeitraum();
        this._0_1_4_haeuffigkeit.setValue(this.mps._0_therapiehaeufigkeit);
        this._0_1_2_halbjahr.setValue(this.mps._0_halbjahr);
        this._0_1_3_jahr.setValue(this.mps._0_jahr);

        // 1
        // this._1_1_1_stallbezeichnung.setValue(this.mps._1_anzahl_staelle);
        // this._1_1_2_anzahl_staelle.setValue(this.mps._1_anzahl_stallplaetze_gesamt);
        this._1_1_3_sauenhalter.setValue(this.mps._1_sauenhalter);
        this._1_1_3_anzahl_Sauen.setValue(this.mps._1_anzahl_sauen);
        this._1_1_3_anzahl_JungSauen.setValue(this.mps._1_anzahl_jungsauen);
        this._1_1_3_stallnummer_1.setValue(this.mps._1_stallnummer_1);
        this._1_1_3_stallnummer_2.setValue(this.mps._1_stallnummer_2);
        this._1_1_3_stallnummer_3.setValue(this.mps._1_stallnummer_3);
        this._1_1_3_stallnummer_4.setValue(this.mps._1_stallnummer_4);
        this._1_1_3_stallplatze_1.setValue(this.mps._1_stallplatze_1);
        this._1_1_3_stallplatze_2.setValue(this.mps._1_stallplatze_2);
        this._1_1_3_stallplatze_3.setValue(this.mps._1_stallplatze_3);
        this._1_1_3_stallplatze_4.setValue(this.mps._1_stallplatze_4);
        this._1_1_3_isolations_stall.setValue(this.mps._1_isolations_stall);

        // 2
        this._2_1_1_betreuungsvertrag.setValue(
            this.mps._2_ta_betreuungsvertrag
        );
        this._2_2_1_weitere_aerzte.setValue(
            this.mps._2_weitere_ta_yes_no
        );
        this._2_2_1_weitere_aerzte_anschrift.setValue(this.mps._2_weitere_ta_name_anschrift);
        this._2_3_1_weitere_berater.setValue(
            this.mps._2_weitere_berater_yes_no
        );
        this._2_3_1_weitere_berater_anschrift.setValue(this.mps._2_weitere_berater_name_anschrift);
        this._2_3_1_integration.setValue(this.mps._2_integration);

        // 3
        // this._3_1_1.setValue(this.getTrueFalse(this.mps._3_1_rein_raus));
        this._3_1_2.setValue(this.getTrueFalse(this.mps._3_1_abteilweise));
        this._3_1_3.setValue(this.getTrueFalse(this.mps._3_1_stallweise));
        this._3_1_4.setValue(this.getTrueFalse(this.mps._3_1_kontinuierlich));
        this._3_1_5.setValue(this.getTrueFalse(this.mps._3_1_mit_auslauf));
        this._3_1_6.setValue(this.getTrueFalse(this.mps._3_1_freilandhaltung));

        this._3_1_7_qs_system_teilnehmer.setValue(this.mps._3_2_qs_teilnehmer);
        this._3_1_7_qs_system_teilnehmer_text.setValue(this.mps._3_2_qs_teilnehmer_text);
        this._3_1_8_bio_siegel.setValue(this.mps._3_2_bio_siegel_y_n);
        this._3_1_8_bio_siegel_text.setValue(this.mps._3_2_bio_siegel);
        this._3_1_9_tierwohl.setValue(this.mps._3_2_tierwohl);
        this._3_1_9_tierwohl_text.setValue(this.mps._3_2_sonstiges_text);

        this._3_2_1_geschlossenes_system.setValue(
            this.getTrueFalse(this.mps._3_3_geschlossenes_system)
        );
        this._3_2_2_zukauf.setValue(this.getTrueFalse(this.mps._3_3_zukauf));
        this._3_2_3_herkuenfte.setValue(this.mps._3_3_anzahl_herkuenfte);

        this._3_3_1_alter_jungsau.setValue(this.mps._3_4_alter_bei_einstallung);
        this._3_3_2_mastdauer.setValue(this.mps._3_4_mastdauer);

        this._3_4_1_besatzdichte.setValue(this.mps._3_5_besatzdichte);
        this._3_4_2tiere_per_bucht.setValue(this.mps._3_5_tiere_pro_bucht);

        this._3_5_1_leerstand_zeit.setValue(this.mps._3_6_leerstandszeit);

        this._3_6_1_vollspalten.setValue(this.getTrueFalse(this.mps._3_7_vollspalten));
        this._3_6_2_teilspalten.setValue(this.getTrueFalse(this.mps._3_7_teilspalten));
        this._3_6_3_beton.setValue(this.getTrueFalse(this.mps._3_7_betonspalten));
        this._3_6_4_kunststoff.setValue(
            this.getTrueFalse(this.mps._3_7_kunststoffspalten)
        );
        this._3_6_5_stroh.setValue(this.getTrueFalse(this.mps._3_7_stroh));
        this._3_6_5_planbefestigt.setValue(this.getTrueFalse(this.mps._3_7_planbefestigt));
        this._3_6_6_sonstiges.setValue(this.getTrueFalse(this.mps._3_6_sonstiges));
        this._3_6_6_sonstiges_text.setValue(this.mps._3_6_sonstiges_text);

        this._3_7_1_kette.setValue(this.getTrueFalse(this.mps._3_7_kette));
        this._3_7_2_holz.setValue(this.getTrueFalse(this.mps._3_7_kette_holz));
        this._3_7_3_seil.setValue(this.getTrueFalse(this.mps._3_7_seil));
        this._3_7_4_heu.setValue(this.getTrueFalse(this.mps._3_7_heu_stroh));
        this._3_7_5_sonstiges.setValue(this.mps._3_7_sonstiges_material_text);
        this._3_7_6_anzahl_bucht.setValue(this.mps._3_7_anzahl_bucht);

        this._3_8_1_konventionelle_buchten.setValue(this.getTrueFalse(this.mps._3_8_konventionelle_buchten))
        this._3_8_2_teilspalten.setValue(this.getTrueFalse(this.mps._3_8_teilspalten))
        this._3_8_3_betonspalten.setValue(this.getTrueFalse(this.mps._3_8_betonspalten))
        this._3_8_3_bewegungsbuchten.setValue(this.getTrueFalse(this.mps._3_8_bewegungsbuchten))
        this._3_8_3_freie_abferkelung.setValue(this.getTrueFalse(this.mps._3_8_freie_abferkelung))
        this._3_8_3_freie_abferkelbuchten.setValue(this.mps._3_8_anzahl_abferkelbuchten)
        this._3_8_3_freie_abferkelplaetze.setValue(this.mps._3_8_anzahl_abferkelplatze)
        this._3_8_4_kunststoffspalten.setValue(this.getTrueFalse(this.mps._3_8_kunststoffspalten))
        this._3_8_5_strohspalten.setValue(this.getTrueFalse(this.mps._3_8_strohspalten))
        this._3_8_6_planbefestigt.setValue(this.getTrueFalse(this.mps._3_8_planbefestigt))
        this._3_8_7_sonstiges.setValue(this.mps._3_8_sonstiges);
        this._3_8_8_rhytmus_1.setValue(this.getTrueFalse(this.mps._3_8_rhythmus_1));
        this._3_8_8_rhytmus_2.setValue(this.getTrueFalse(this.mps._3_8_rhythmus_2));
        this._3_8_8_rhytmus_3.setValue(this.getTrueFalse(this.mps._3_8_rhythmus_3));
        this._3_8_8_rhytmus_4.setValue(this.getTrueFalse(this.mps._3_8_rhythmus_4));
        this._3_8_8_rhytmus_5.setValue(this.getTrueFalse(this.mps._3_8_rhythmus_5));
        this._3_8_8_rhytmus_6.setValue(this.getTrueFalse(this.mps._3_8_rhythmus_6));
        this._3_8_8_rhytmus_7.setValue(this.getTrueFalse(this.mps._3_8_rhythmus_7));
        this._3_8_rhythmus_sonstiger.setValue(this.mps._3_8_rhythmus_sonstiger);

        this._3_9_1_absetzalter.setValue(this.mps._3_9_absetzalter_tage);
        this._3_9_2_absetzgewicht.setValue(this.mps._3_9_absetzgewicht);
        this._3_9_3_waschung.setValue(this.mps._3_9_waschung);
        this._3_9_4_umsetzung.setValue(this.mps._3_9_umsetzung);
        this._3_9_5_kastrieren.setValue(this.getTrueFalse(this.mps._3_9_kastrieren));
        this._3_9_6_schwaenze_kuerzen.setValue(this.getTrueFalse(this.mps._3_9_schwaenze_kuerzen));
        this._3_9_7_zaehne_schleiffen.setValue(this.getTrueFalse(this.mps._3_9_zaehne_schleifen));
        this._3_9_8_remontierungsrate.setValue(this.mps._3_9_remontierungsrate);
        this._3_9_9_abgesetzte_ferkel.setValue(this.mps._3_9_abgesetzte_ferkel);
        this._3_9_10_traechtigkeitsdauer.setValue(this.mps._3_9_traechtigkeitsdauer);
        this._3_9_11_wuerfe.setValue(this.mps._3_9_wuerfe);
        this._3_9_12_abferkelrate.setValue(this.mps._3_9_abferkelrate);
        this._3_9_13_lebendgeboren.setValue(this.mps._3_9_lebendgeboren);
        this._3_9_14_erstbelegungsalter.setValue(this.mps._3_9_erstbelegungsalter);

        // 4
        this._4_1_1_zwangslueftung.setValue(this.getTrueFalse(this.mps._4_zwangslueftung));
        this._4_1_2_deckenlueftung.setValue(this.getTrueFalse(this.mps._4_deckenlueftung));
        this._4_1_3_tuerganglueftung.setValue(this.getTrueFalse(this.mps._4_tuerganglueftung));
        this._4_1_4_freie_lueftung.setValue(this.getTrueFalse(this.mps._4_freie_lueftung));
        this._4_1_5_unterflurabsaugung.setValue(this.getTrueFalse(this.mps._4_unterflurabsaugung));
        this._4_1_6_sonstiges_1.setValue(this.mps._4_individuell_1);
        this._4_1_7_sonstiges_2.setValue(this.mps._4_individuell_2);
        this._4_1_8_heizkanone.setValue(this.getTrueFalse(this.mps._4_heizkanone));
        this._4_1_9_kenvektor.setValue(this.getTrueFalse(this.mps._4_konvektor));
        this._4_1_10_deltarohre.setValue(this.getTrueFalse(this.mps._4_deltarohre));
        this._4_1_11_sonstiges_3.setValue(this.mps._4_individuell_3);
        this._4_1_12_beheitzte_bodenlampe.setValue(this.getTrueFalse(this.mps._4_beheizte_bodenlampe));
        this._4_1_12_waermelampe.setValue(this.getTrueFalse(this.mps._4_waermelampe));
        this._4_1_12_sonstiges_4.setValue(this.mps._4_individuell_4);
        this._4_1_13_regelmaessig.setValue(this.getTrueFalse(this.mps._4_klimacheck_regelmaessig));
        this._4_1_14_bei_bedarf.setValue(this.getTrueFalse(this.mps._4_klimacheck_bei_bedarf));

        // 5
        this._5_1_1_breiautomat.setValue(this.getTrueFalse(this.mps._5_breiautomat));
        this._5_1_2_trockenautomat.setValue(this.getTrueFalse(this.mps._5_trockenautomat));
        this._5_1_3_fluessigfutter.setValue(this.getTrueFalse(this.mps._5_fluessigfuetterung));
        this._5_1_4_mehlfoermig.setValue(this.getTrueFalse(this.mps._5_mehlfoermig));
        this._5_1_5_garuliert.setValue(this.getTrueFalse(this.mps._5_granuliert));
        this._5_1_6_pelletiert.setValue(this.getTrueFalse(this.mps._5_pelletiert));
        this._5_1_7_sonstiges.setValue(this.mps._5_individuell_1);
        this._5_1_8_anzahl_tiere.setValue(this.mps._5_tiere_futterplatz);
        this._5_1_9_trockenfuetterung.setValue(this.getTrueFalse(this.mps._5_trockenfuetterung));
        this._5_1_10_abruffuetterung.setValue(this.getTrueFalse(this.mps._5_abruffuetterung));
        this._5_1_11_raufutter_sau.setValue(this.getTrueFalse(this.mps._5_raufutter));

        this._5_2_1_brunnenwasser.setValue(this.getTrueFalse(this.mps._5_brunnenwasser));
        this._5_2_2_stadtwasser.setValue(this.getTrueFalse(this.mps._5_stadtwasser));
        this._5_2_3_letzte_wasseruntersuchung.setValue(this.getTrueFalse(this.mps._5_wasseruntersuchung));
        this._5_2_4_letzte_wasseruntersuchung_text.setValue(this.mps._5_wasseruntersuchung_text);
        this._5_2_5_anzahl_tiere.setValue(this.mps._5_tiere_traenke);
        this._5_2_6_art_traenke.setValue(this.mps._5_art_traenke);

        this._5_3_1_zufuetterung_text.setValue(this.mps._5_zufuetterung_mit_text);
        this._5_3_2_kuenstliche_amme.setValue(this.getTrueFalse(this.mps._5_kuenstliche_amme));
        this._5_3_3_natuerliche_amme.setValue(this.getTrueFalse(this.mps._5_natuerliche_amme));
        this._5_3_4_zusaetliche_milch.setValue(this.getTrueFalse(this.mps._5_zusaetzlich_milch))

        // 6
        this._6_1_1.setValue(this.getTrueFalse(this.mps._6_injektion));
        this._6_1_2.setValue(this.getTrueFalse(this.mps._6_futter));
        this._6_1_3.setValue(this.getTrueFalse(this.mps._6_wasser));
        this._6_1_4.setValue(
            this.getTrueFalse(this.mps._6_mischer_vorbehaelter)
        );
        this._6_1_5.setValue(this.getTrueFalse(this.mps._6_dosierer));
        this._6_1_6.setValue(this.getTrueFalse(this.mps._6_tiernah));
        this._6_1_7.setValue(this.getTrueFalse(this.mps._6_lokal));

        this._6_2_1.setValue(this.mps._6_leitfaden);
        this._6_3_1.setValue(this.mps._6_risikomanagement);

        // 7
        this._7_1_1_art_schutzkleidung.setValue(this.mps._7_1_art_schutzkleidung);
        this._7_1_2_art_hygieneschleuse.setValue(this.mps._7_1_art_hygieneschleuse);
        this._7_2_1_reinigung_stall.setValue(this.mps._7_2_reinigung_stall)
        this._7_2_2_desinfektion_stall.setValue(this.mps._7_2_desinfektion_stall);
        this._7_1_2_matten_vorhanden.setValue(this.mps._7_1_matten_vorhanden);
        this._7_1_2_matten_vorhanden_text.setValue(this.mps._7_1_matten_vorhanden_text);
        this._7_1_4_reinigung_stall_text.setValue(this.mps._7_2_reinigung_stall_text);
        this._7_1_5_reinigung_stall_selbst.setValue(this.getTrueFalse(this.mps._7_2_reinigung_stall_selbst));
        this._7_2_5_reingung_desinfektion_text.setValue(this.mps._7_3_wl_reinigung_text)
        this._7_1_6_reinigung_stall_fremdfirma.setValue(this.getTrueFalse(this.mps._7_2_reinigung_stall_fremdfirma));
        this._7_1_6_desinfektion_stall_text.setValue(this.mps._7_2_desinfektion_text);
        this._7_1_6_desinfektion_stall_selbst.setValue(this.getTrueFalse(this.mps._7_2_desinfektion_stall_selbst));
        this._7_1_6_desinfektion_stall_fremdfirma.setValue(this.getTrueFalse(this.mps._7_2_desinfektion_stall_fremdfirma));
        this._7_1_14_doku_vorhanden.setValue(this.mps._7_2_doku_vorhanden);
        // this._7_2_3_reinigung_stall.setValue(this.getTrueFalse(this.mps._7_2_reinigung_stall));
        // this._7_2_4_reinigung_stall_text.setValue(this.getTrueFalse(this.mps._7_3_wl_desinfektion));
        this._7_2_5_reingung_selbst.setValue(
            this.getTrueFalse(this.mps._7_3_wl_reinigung)
        );
        this._7_2_6_reinigung_desinfektion.setValue(
            this.mps._7_3_wl_desinfektion
        );
        this._7_2_6_nach_antibiotika.setValue(this.getTrueFalse(this.mps._7_3_wl_nach_antibiotika))
        // this._7_2_6_desinfektion.setValue(this.getTrueFalse(this.mps._7_3_wl_desinfektion));
        this._7_2_7_routine.setValue(this.getTrueFalse(this.mps._7_3_wl_routinemaessig))
        this._7_2_7_reinigung_futterleitungen.setValue(this.getTrueFalse(this.mps._7_3_fe_futterleitung));
        this._7_2_8_reinigung_futtertroege.setValue(this.getTrueFalse(this.mps._7_3_fe_futtertroege));
        this._7_2_9_reinigung_futtersilo.setValue(this.getTrueFalse(this.mps._7_3_fe_futtersilo));
        this._7_2_10_nach_antibiotika.setValue(
            this.getTrueFalse(this.mps._7_3_fe_nach_antibiotika)
        );
        // this._7_2_11.setValue( this.getTrueFalse( this.mps._7_3_fe_futtersilo ) );
        this._7_2_12_durch_fremdfirma.setValue(
            this.getTrueFalse(this.mps._7_4_durch_fremdfirma)
        );
        this._7_2_13_selbst.setValue(this.getTrueFalse(this.mps._7_4_selbst));

        this._7_3_1_eigenes_ammenabteil.setValue(this.mps._7_5_ammenabteil);
        this._7_5_y_n.setValue(this.mps._7_5_y_n);
        this._7_5_y_n_anzahl.setValue(this.mps._7_5_y_n_anzahl);
        this._7_3_4_mit_gummimatte.setValue(this.getTrueFalse(this.mps._7_5_mit_gummimatte));
        this._7_3_5_mit_stroh.setValue(this.getTrueFalse(this.mps._7_5_mit_stroh));
        this._7_3_6_sonstiges.setValue(this.mps._7_5_sonstiges_y_n_text);

        this._7_3_7_sonst_absonderung.setValue(
            this.getTrueFalse(this.mps._7_6_sonstige_absonder)
        );
        this._7_3_8_sonst_absonderung_text.setValue(this.mps._7_6_sonstige_absonder_text);

        // 8
        this._8_1_1.setValue(this.getTrueFalse(this.mps._8_1_vorgeimpft_y_n));
        this._8_1_2.setValue(this.mps._8_1_vorgeimpft_y_n_text);

        this._8_1_3.setValue(
            this.getTrueFalse(this.mps._8_1_im_bestand_geimpft_y_n)
        );
        this._8_1_4.setValue(this.mps._8_1_im_bestand_geimpft_y_n_text);

        this._8_1_5.setValue(this.mps._8_2_endoparasiten_y_n);
        this._8_1_6.setValue(this.mps._8_2_ektoparasiten_y_n);

        //this._8_1_7.setValue(this.getTrueFalse(this.mps._8_2_sonstiges_y_n));
        this._8_1_8.setValue(this.mps._8_2_sonstiges_y_n_text);

        // 9
        this._9_1_1_atemwege.setValue(this.getTrueFalse(this.mps._9_1_atemwege));
        this._9_1_2_verdauung.setValue(
            this.getTrueFalse(this.mps._9_1_verdauungsapparat)
        );
        this._9_1_3_bewegung.setValue(
            this.getTrueFalse(this.mps._9_1_bewegungssapparat)
        );
        this._9_1_3_1_zns.setValue(this.getTrueFalse(this.mps._9_1_zns));
        this._9_1_3_2_gesaeuge.setValue(this.getTrueFalse(this.mps._9_1_gesaeuge))
        this._9_1_3_3_geschlecht.setValue(this.getTrueFalse(this.mps._9_1_geschlechtsapparat))
        // this._9_1_4_sonstiges.setValue(this.getTrueFalse(this.mps._9_1_sonstiges_y_n));
        this._9_1_5_sonstiges_text.setValue(this.mps._9_1_sonstiges_y_n_text);

        this._9_2_1_anzahl_verendet.setValue(this.mps._9_2_anzahl_kalenderhalbjahr);
        this._9_2_2_verlust_halbjahr.setValue(this.mps._9_2_anzahl_vorheriges_halbjahr);

        this._9_3_1.setValue(this.mps._9_3_angaben_zu_krankheit);

        this._9_4_erregernachweis_y_n_ctrl.setValue(
            this.getTrueFalse(this.mps._9_4_erregernachweis_y_n),
            { emitEvent: false }
        );
        this._erregernachweis_text.setValue(this.mps._9_4_erregernachweis_y_n_text);

        this._9_4_sektion_y_n_ctrl.setValue(
            this.getTrueFalse(this.mps._9_4_sektion_y_n),
            { emitEvent: false }
        );
        this._9_4_4.setValue(this.mps._9_4_sektion_y_n_text);

        this._9_4_5.setValue(this.getTrueFalse(this.mps._9_4_sonstiges_y_n));
        this._9_4_6.setValue(this.mps._9_4_sonstiges_y_n_text);

        // 10
        this._10_1_1.setValue(this.mps._10_1_ergebnis_beratung);
        if (!this.isEditable() && this.mps._10_1_datum) {
            this._10_2_1.setValue(this.mps._10_1_datum);
        }
        this._10_3_1.setValue(
            this.getTrueFalse(this.mps._10_1_text_streichen_y_n)
        );

        // 11
        this._11_1_1.setValue(this.mps._11_1_massnahmen);
        this._11_2_1.setValue(this.mps._11_1_zeitraum_umsetzung);

        this._9_verlustrate.setValue(this.mps._9_verlustrate);
        this._8_impfschema_zuchtschweine.setValue(this.mps._8_impfschema_zuchtschweine);
        this._8_impfschema_saugferkel.setValue(this.mps._8_impfschema_saugferkel);

        // this.mps._11_2_ort = this._11_3_1.value;
        // this.mps._11_2_datum = this._11_3_2.value;
        // this.mps._11_2_verantwortlicher = this._11_3_3.value;

        /*if (!this.mps._11_2_ort) {
      if (this.currentClient && this.currentClient.kunde_id === this.mps.kunde_id) {
        this.mps._11_2_ort = this.currentClient.ort;
      }
    }*/

        this._11_3_1.setValue(this.mps._11_2_ort);
        if (!this.isEditable() && this.mps._11_2_datum) {
            this._11_3_2.setValue(this.mps._11_2_datum);
        }
        this._11_3_3.setValue(this.mps._11_2_verantwortlicher);

        this.bbpAnhaengenCtrl.setValue(this.mps.bbp_anhaengen > 0);
        this.befundeAnhaengenCtrl.setValue(this.mps.befunde_anhaengen > 0);
    }

    save(abschliessen: boolean) {
        // 0
        this.mps.tierart_id = this.tierartCtrl.value;
        this.mps._0_halbjahr = this._0_1_2_halbjahr.value;
        this.mps._0_jahr = this._0_1_3_jahr.value;
        this.mps._0_therapiehaeufigkeit = this._0_1_4_haeuffigkeit.value;

        // 1
        // this.mps._1_anzahl_staelle = this._1_1_1_stallbezeichnung.value;
        // this.mps._1_stallListe = this._1_1_1_stallListe.value;
        // this.mps._1_anzahl_stallplaetze_gesamt = this._1_1_2_anzahl_staelle.value;
        // TODO: Wert 0 = nein, 1 = JA, 2 = nicht zutreffend
        this.mps._1_sauenhalter = this._1_1_3_sauenhalter.value;
        this.mps._1_anzahl_sauen = this._1_1_3_anzahl_Sauen.value;
        this.mps._1_anzahl_jungsauen = this._1_1_3_anzahl_JungSauen.value;
        this.mps._1_stallnummer_1 = this._1_1_3_stallnummer_1.value;
        this.mps._1_stallnummer_2 = this._1_1_3_stallnummer_2.value;
        this.mps._1_stallnummer_3 = this._1_1_3_stallnummer_3.value;
        this.mps._1_stallnummer_4 = this._1_1_3_stallnummer_4.value;
        this.mps._1_stallplatze_1 = this._1_1_3_stallplatze_1.value;
        this.mps._1_stallplatze_2 = this._1_1_3_stallplatze_2.value;
        this.mps._1_stallplatze_3 = this._1_1_3_stallplatze_3.value;
        this.mps._1_stallplatze_4 = this._1_1_3_stallplatze_4.value;
        this.mps._1_isolations_stall = this._1_1_3_isolations_stall.value;

        // 2
        this.mps._2_ta_betreuungsvertrag = this._2_1_1_betreuungsvertrag.value;
        this.mps._2_weitere_ta_yes_no = this._2_2_1_weitere_aerzte.value;
        this.mps._2_weitere_ta_name_anschrift = this._2_2_1_weitere_aerzte_anschrift.value;
        this.mps._2_weitere_berater_yes_no =
            this._2_3_1_weitere_berater.value;
        this.mps._2_weitere_berater_name_anschrift = this._2_3_1_weitere_berater_anschrift.value;
        this.mps._2_integration = this._2_3_1_integration.value;


        // 3
        // this.mps._3_1_rein_raus = this._3_1_1.value === true ? 1 : 0;
        this.mps._3_1_abteilweise = this._3_1_2.value === true ? 1 : 0;
        this.mps._3_1_stallweise = this._3_1_3.value === true ? 1 : 0;
        this.mps._3_1_kontinuierlich = this._3_1_4.value === true ? 1 : 0;
        this.mps._3_1_mit_auslauf = this._3_1_5.value === true ? 1 : 0;
        this.mps._3_1_freilandhaltung = this._3_1_6.value === true ? 1 : 0;

        this.mps._3_2_qs_teilnehmer = this._3_1_7_qs_system_teilnehmer.value;
        this.mps._3_2_qs_teilnehmer_text = this._3_1_7_qs_system_teilnehmer_text.value;
        this.mps._3_2_bio_siegel_y_n = this._3_1_8_bio_siegel.value;
        this.mps._3_2_bio_siegel = this._3_1_8_bio_siegel_text.value;
        this.mps._3_2_tierwohl = this._3_1_9_tierwohl.value;
        this.mps._3_2_sonstiges_text = this._3_1_9_tierwohl_text.value;

        this.mps._3_3_geschlossenes_system = this._3_2_1_geschlossenes_system.value === true ? 1 : 0;
        this.mps._3_3_zukauf = this._3_2_2_zukauf.value === true ? 1 : 0;
        this.mps._3_3_anzahl_herkuenfte = this._3_2_3_herkuenfte.value;

        this.mps._3_4_alter_bei_einstallung = this._3_3_1_alter_jungsau.value;
        this.mps._3_4_mastdauer = this._3_3_2_mastdauer.value;

        this.mps._3_5_besatzdichte = this._3_4_1_besatzdichte.value;
        this.mps._3_5_tiere_pro_bucht = this._3_4_2tiere_per_bucht.value;

        this.mps._3_6_leerstandszeit = this._3_5_1_leerstand_zeit.value;
        this.mps._3_6_sonstiges = this._3_6_6_sonstiges.value === true ? 1 : 0;
        this.mps._3_6_sonstiges_text = this._3_6_6_sonstiges_text.value;
        this.mps._3_7_vollspalten = this._3_6_1_vollspalten.value === true ? 1 : 0;
        this.mps._3_7_teilspalten = this._3_6_2_teilspalten.value === true ? 1 : 0;
        this.mps._3_7_betonspalten = this._3_6_3_beton.value === true ? 1 : 0;
        this.mps._3_7_kunststoffspalten = this._3_6_4_kunststoff.value === true ? 1 : 0;
        this.mps._3_7_stroh = this._3_6_5_stroh.value === true ? 1 : 0;
        this.mps._3_7_planbefestigt = this._3_6_5_planbefestigt.value === true ? 1 : 0;

        this.mps._3_7_kette = this._3_7_1_kette.value === true ? 1 : 0;
        this.mps._3_7_kette_holz = this._3_7_2_holz.value === true ? 1 : 0;
        this.mps._3_7_seil = this._3_7_3_seil.value === true ? 1 : 0;
        this.mps._3_7_heu_stroh = this._3_7_4_heu.value === true ? 1 : 0;
        this.mps._3_7_sonstiges_material_text = this._3_7_5_sonstiges.value;
        this.mps._3_7_anzahl_bucht = this._3_7_6_anzahl_bucht.value;

        this.mps._3_8_konventionelle_buchten = this._3_8_1_konventionelle_buchten.value === true ? 1 : 0;
        this.mps._3_8_teilspalten = this._3_8_2_teilspalten.value === true ? 1 : 0;
        this.mps._3_8_betonspalten = this._3_8_3_betonspalten.value === true ? 1 : 0;
        this.mps._3_8_kunststoffspalten = this._3_8_4_kunststoffspalten.value === true ? 1 : 0;
        this.mps._3_8_strohspalten = this._3_8_5_strohspalten.value === true ? 1 : 0;
        this.mps._3_8_planbefestigt = this._3_8_6_planbefestigt.value === true ? 1 : 0;
        this.mps._3_8_bewegungsbuchten = this._3_8_3_bewegungsbuchten.value === true ? 1 : 0;
        this.mps._3_8_freie_abferkelung = this._3_8_3_freie_abferkelung.value === true ? 1 : 0;
        this.mps._3_8_anzahl_abferkelbuchten = this._3_8_3_freie_abferkelbuchten.value;
        this.mps._3_8_anzahl_abferkelplatze = this._3_8_3_freie_abferkelplaetze.value;
        this.mps._3_8_sonstiges = this._3_8_7_sonstiges.value;
        this.mps._3_8_rhythmus_1 = this._3_8_8_rhytmus_1.value === true ? 1 : 0;
        this.mps._3_8_rhythmus_2 = this._3_8_8_rhytmus_2.value === true ? 1 : 0;
        this.mps._3_8_rhythmus_3 = this._3_8_8_rhytmus_3.value === true ? 1 : 0;
        this.mps._3_8_rhythmus_4 = this._3_8_8_rhytmus_4.value === true ? 1 : 0;
        this.mps._3_8_rhythmus_5 = this._3_8_8_rhytmus_5.value === true ? 1 : 0;
        this.mps._3_8_rhythmus_6 = this._3_8_8_rhytmus_6.value === true ? 1 : 0;
        this.mps._3_8_rhythmus_7 = this._3_8_8_rhytmus_7.value === true ? 1 : 0;
        this.mps._3_8_rhythmus_sonstiger = this._3_8_rhythmus_sonstiger.value;

        this.mps._3_9_absetzalter_tage = this._3_9_1_absetzalter.value;
        this.mps._3_9_absetzgewicht = this._3_9_2_absetzgewicht.value;
        this.mps._3_9_waschung = this._3_9_3_waschung.value;
        this.mps._3_9_umsetzung = this._3_9_4_umsetzung.value;
        this.mps._3_9_kastrieren = this._3_9_5_kastrieren.value === true ? 1 : 0;
        this.mps._3_9_schwaenze_kuerzen = this._3_9_6_schwaenze_kuerzen.value === true ? 1 : 0;
        this.mps._3_9_zaehne_schleifen = this._3_9_7_zaehne_schleiffen.value === true ? 1 : 0;
        this.mps._3_9_remontierungsrate = this._3_9_8_remontierungsrate.value;
        this.mps._3_9_abgesetzte_ferkel = this._3_9_9_abgesetzte_ferkel.value;
        this.mps._3_9_traechtigkeitsdauer = this._3_9_10_traechtigkeitsdauer.value;
        this.mps._3_9_wuerfe = this._3_9_11_wuerfe.value;
        this.mps._3_9_abferkelrate = this._3_9_12_abferkelrate.value;
        this.mps._3_9_lebendgeboren = this._3_9_13_lebendgeboren.value;
        this.mps._3_9_erstbelegungsalter = this._3_9_14_erstbelegungsalter.value;
        // 4
        this.mps._4_zwangslueftung = this._4_1_1_zwangslueftung.value === true ? 1 : 0;
        this.mps._4_deckenlueftung = this._4_1_2_deckenlueftung.value === true ? 1 : 0;
        this.mps._4_tuerganglueftung = this._4_1_3_tuerganglueftung.value === true ? 1 : 0;
        this.mps._4_freie_lueftung = this._4_1_4_freie_lueftung.value === true ? 1 : 0;
        this.mps._4_unterflurabsaugung = this._4_1_5_unterflurabsaugung.value === true ? 1 : 0;
        this.mps._4_heizkanone = this._4_1_8_heizkanone.value === true ? 1 : 0;
        this.mps._4_konvektor = this._4_1_9_kenvektor.value === true ? 1 : 0;
        this.mps._4_deltarohre = this._4_1_10_deltarohre.value === true ? 1 : 0;
        this.mps._4_individuell_1 = this._4_1_6_sonstiges_1.value;
        this.mps._4_individuell_2 = this._4_1_7_sonstiges_2.value;
        this.mps._4_individuell_3 = this._4_1_11_sonstiges_3.value;
        this.mps._4_waermelampe = this._4_1_12_waermelampe.value === true ? 1 : 0;
        this.mps._4_beheizte_bodenlampe = this._4_1_12_beheitzte_bodenlampe.value === true ? 1 : 0;
        this.mps._4_individuell_4 = this._4_1_12_sonstiges_4.value;
        // this.mps._4_ueberpruefung_am = this._4_1_13_regelmaessig.value;
        this.mps._4_klimacheck_regelmaessig = this._4_1_13_regelmaessig.value === true ? 1 : 0;
        this.mps._4_klimacheck_bei_bedarf = this._4_1_14_bei_bedarf.value === true ? 1 : 0;

        // 5
        this.mps._5_breiautomat = this._5_1_1_breiautomat.value === true ? 1 : 0;
        this.mps._5_trockenautomat = this._5_1_2_trockenautomat.value === true ? 1 : 0;
        this.mps._5_fluessigfuetterung = this._5_1_3_fluessigfutter.value === true ? 1 : 0;
        this.mps._5_mehlfoermig = this._5_1_4_mehlfoermig.value === true ? 1 : 0;
        this.mps._5_granuliert = this._5_1_5_garuliert.value === true ? 1 : 0;
        this.mps._5_pelletiert = this._5_1_6_pelletiert.value === true ? 1 : 0;
        this.mps._5_individuell_1 = this._5_1_7_sonstiges.value;
        this.mps._5_tiere_futterplatz = this._5_1_8_anzahl_tiere.value;
        this.mps._5_trockenfuetterung = this._5_1_9_trockenfuetterung.value === true ? 1 : 0;
        this.mps._5_abruffuetterung = this._5_1_10_abruffuetterung.value === true ? 1 : 0;
        this.mps._5_raufutter = this._5_1_11_raufutter_sau.value === true ? 1 : 0;

        this.mps._5_brunnenwasser = this._5_2_1_brunnenwasser.value === true ? 1 : 0;
        this.mps._5_stadtwasser = this._5_2_2_stadtwasser.value === true ? 1 : 0;
        this.mps._5_wasseruntersuchung = this._5_2_3_letzte_wasseruntersuchung.value === true ? 1 : 0;
        this.mps._5_wasseruntersuchung_text = this._5_2_4_letzte_wasseruntersuchung_text.value;
        this.mps._5_tiere_traenke = this._5_2_5_anzahl_tiere.value;
        this.mps._5_art_traenke = this._5_2_6_art_traenke.value;

        this.mps._5_zufuetterung_mit_text = this._5_3_1_zufuetterung_text.value;
        this.mps._5_kuenstliche_amme = this._5_3_2_kuenstliche_amme.value === true ? 1 : 0;
        this.mps._5_natuerliche_amme = this._5_3_3_natuerliche_amme.value === true ? 1 : 0;
        this.mps._5_zusaetzlich_milch = this._5_3_4_zusaetliche_milch.value === true ? 1 : 0;

        // 6
        this.mps._6_injektion = this._6_1_1.value === true ? 1 : 0;
        this.mps._6_futter = this._6_1_2.value === true ? 1 : 0;
        this.mps._6_wasser = this._6_1_3.value === true ? 1 : 0;
        this.mps._6_mischer_vorbehaelter = this._6_1_4.value === true ? 1 : 0;
        this.mps._6_dosierer = this._6_1_5.value === true ? 1 : 0;
        this.mps._6_tiernah = this._6_1_6.value === true ? 1 : 0;
        this.mps._6_lokal = this._6_1_7.value === true ? 1 : 0;

        this.mps._6_leitfaden = this._6_2_1.value;
        this.mps._6_risikomanagement = this._6_3_1.value;

        // 7
        this.mps._7_1_art_schutzkleidung = this._7_1_1_art_schutzkleidung.value;
        this.mps._7_1_art_hygieneschleuse = this._7_1_2_art_hygieneschleuse.value;
        this.mps._7_1_matten_vorhanden = this._7_1_2_matten_vorhanden.value;
        this.mps._7_1_matten_vorhanden_text = this._7_1_2_matten_vorhanden_text.value;
        // this.mps._7_2_reinigung_stall = this._7_1_3_reinigung_stall.value  === true ? 1 : 0;
        this.mps._7_2_reinigung_stall = this._7_2_1_reinigung_stall.value;
        this.mps._7_2_reinigung_stall_text = this._7_1_4_reinigung_stall_text.value;
        this.mps._7_2_reinigung_stall_selbst = this._7_1_5_reinigung_stall_selbst.value === true ? 1 : 0;
        this.mps._7_2_reinigung_stall_fremdfirma = this._7_1_6_reinigung_stall_fremdfirma.value === true ? 1 : 0;

        this.mps._7_2_desinfektion_stall = this._7_2_2_desinfektion_stall.value;
        this.mps._7_2_desinfektion_text = this._7_1_6_desinfektion_stall_text.value;
        this.mps._7_2_desinfektion_stall_selbst = this._7_1_6_desinfektion_stall_selbst.value === true ? 1 : 0;
        this.mps._7_2_desinfektion_stall_fremdfirma = this._7_1_6_desinfektion_stall_fremdfirma.value === true ? 1 : 0;

        this.mps._7_3_wl_reinigung = this._7_2_5_reingung_selbst.value === true ? 1 : 0;
        this.mps._7_3_wl_desinfektion = this._7_2_6_reinigung_desinfektion.value === true ? 1 : 0;
        this.mps._7_2_doku_vorhanden = this._7_1_14_doku_vorhanden.value;
        this.mps._7_3_wl_routinemaessig = this._7_2_7_routine.value === true ? 1 : 0;
        this.mps._7_3_wl_reinigung_text = this._7_2_5_reingung_desinfektion_text.value;
        this.mps._7_3_wl_nach_antibiotika = this._7_2_6_nach_antibiotika.value === true ? 1 : 0;
        this.mps._7_3_fe_futterleitung = this._7_2_7_reinigung_futterleitungen.value === true ? 1 : 0;
        this.mps._7_3_fe_futtertroege = this._7_2_8_reinigung_futtertroege.value === true ? 1 : 0;
        this.mps._7_3_fe_futtersilo = this._7_2_9_reinigung_futtersilo.value === true ? 1 : 0;
        this.mps._7_3_fe_nach_antibiotika = this._7_2_10_nach_antibiotika.value === true ? 1 : 0;
        // this.mps._7_3_wl_reinigung_text = this._7_2_4_reinigung_stall_text.value;
        this.mps._7_3_fe_futtertroege = this._7_2_8_reinigung_futtertroege.value === true ? 1 : 0;
        // this.mps._7_4 = this._7_2_10_nach_antibiotika.value === true ? 1 : 0;
        this.mps._7_4_durch_fremdfirma = this._7_2_12_durch_fremdfirma.value === true ? 1 : 0;
        this.mps._7_4_selbst = this._7_2_13_selbst.value === true ? 1 : 0;

        this.mps._7_5_ammenabteil = this._7_3_1_eigenes_ammenabteil.value;
        this.mps._7_5_y_n = this._7_5_y_n.value === true ? 1 : 0,
            this.mps._7_5_y_n_anzahl = this._7_5_y_n_anzahl.value;
        this.mps._7_5_mit_gummimatte = this._7_3_4_mit_gummimatte.value === true ? 1 : 0;
        this.mps._7_5_mit_stroh = this._7_3_5_mit_stroh.value === true ? 1 : 0;
        this.mps._7_5_sonstiges_y_n_text = this._7_3_6_sonstiges.value;

        this.mps._7_6_sonstige_absonder = this._7_3_7_sonst_absonderung.value === true ? 1 : 0;
        this.mps._7_6_sonstige_absonder_text = this._7_3_8_sonst_absonderung_text.value;

        // 8
        this.mps._8_1_vorgeimpft_y_n = this._8_1_1.value === true ? 1 : 0;
        this.mps._8_1_vorgeimpft_y_n_text = this._8_1_2.value;

        this.mps._8_1_im_bestand_geimpft_y_n =
            this._8_1_3.value === true ? 1 : 0;
        this.mps._8_1_im_bestand_geimpft_y_n_text = this._8_1_4.value;

        this.mps._8_2_endoparasiten_y_n = this._8_1_5.value;
        this.mps._8_2_ektoparasiten_y_n = this._8_1_6.value;

        // this.mps._8_2_sonstiges_y_n = this._8_1_7.value === true ? 1 : 0;
        this.mps._8_2_sonstiges_y_n_text = this._8_1_8.value;

        // 9
        this.mps._9_1_atemwege = this._9_1_1_atemwege.value === true ? 1 : 0;
        this.mps._9_1_verdauungsapparat = this._9_1_2_verdauung.value === true ? 1 : 0;
        this.mps._9_1_bewegungssapparat = this._9_1_3_bewegung.value === true ? 1 : 0;
        // this.mps._9_1_sonstiges_y_n = this._9_1_4_sonstiges.value === true ? 1 : 0;
        this.mps._9_1_sonstiges_y_n_text = this._9_1_5_sonstiges_text.value;
        this.mps._9_1_zns = this._9_1_3_1_zns.value === true ? 1 : 0;
        this.mps._9_1_gesaeuge = this._9_1_3_2_gesaeuge.value === true ? 1 : 0;
        this.mps._9_1_geschlechtsapparat = this._9_1_3_3_geschlecht.value === true ? 1 : 0;
        this.mps._9_2_anzahl_kalenderhalbjahr = this._9_2_1_anzahl_verendet.value;
        this.mps._9_2_anzahl_vorheriges_halbjahr = this._9_2_2_verlust_halbjahr.value;

        this.mps._9_3_angaben_zu_krankheit = this._9_3_1.value;

        this.mps._9_4_erregernachweis_y_n =
            this._9_4_erregernachweis_y_n_ctrl.value === true ? 1 : 0;
        this.mps._9_4_erregernachweis_y_n_text = this._erregernachweis_text.value;

        this.mps._9_4_sektion_y_n =
            this._9_4_sektion_y_n_ctrl.value === true ? 1 : 0;
        this.mps._9_4_sektion_y_n_text = this._9_4_4.value;

        this.mps._9_4_sonstiges_y_n = this._9_4_5.value === true ? 1 : 0;
        this.mps._9_4_sonstiges_y_n_text = this._9_4_6.value;

        // 10
        this.mps._10_1_ergebnis_beratung = this._10_1_1.value;
        // Datum:
        if (!this._10_2_1.value) {
            this._10_2_1.setValue(new Date());
        }
        this.mps._10_1_datum = this._10_2_1.value;
        this.mps._10_1_text_streichen_y_n = this._10_3_1.value === true ? 1 : 0;

        // 11
        this.mps._11_1_massnahmen = this._11_1_1.value;
        this.mps._11_1_zeitraum_umsetzung = this._11_2_1.value;

        this.mps._11_2_ort = this._11_3_1.value;
        // Datum:
        if (!this._11_3_2.value) {
            this._11_3_2.setValue(new Date());
        }
        this.mps._11_2_datum = this._11_3_2.value;
        this.mps._11_2_verantwortlicher = this._11_3_3.value;

        this.mps._9_verlustrate = this._9_verlustrate.value;
        this.mps._8_impfschema_zuchtschweine = this._8_impfschema_zuchtschweine.value;
        this.mps._8_impfschema_saugferkel = this._8_impfschema_saugferkel.value;


        if (abschliessen) {
            this.mps.status_id = 62;
        } else {
            this.mps.status_id = 61;
        }

        this.mps.befunde_anhaengen = this.befundeAnhaengenCtrl.value ? 1 : 0;
        this.mps.bbp_anhaengen = this.bbpAnhaengenCtrl.value ? 1 : 0;

        this.mps.abrechnen = this.abrechnenCtrl.value ? 1 : 0;

        this.api.updateGeneric(this.apiBaseUrl + 'massnahmenplan_schwein/update.php', this.mps).subscribe({
            next: (response) => {
                // this.notification.infoMessage('Maßnahmenplan erfolgreich gespeichert!');
                this.mps = response;
                this.initForm();
                if (abschliessen) {
                    this.mnpSchweinService.showPdf(this.mps);
                }
            },
            error: (error) => {
                this.messageService.errorMessage(
                    'Der Maßnahmenplan konnte nicht gespeichert werden!',
                    error
                );
                this.mps.status_id = 61;
            }
        });
    }

    setValue10and11(textbaustein: Textbaustein) {
        if (textbaustein) {
            this._10_1_1.setValue(textbaustein.textbaustein1);
            this._11_1_1.setValue(textbaustein.textbaustein2);
            this._11_2_1.setValue(textbaustein.textbaustein3);
        }
    }

    setTierarzt(vet: Vet) {
        if (!vet) {
            this.mps.tierarzt_id = null;
        } else {
            this.mps.tierarzt_id = vet.tierarzt_id;
        }
    }

    getTextbaustein10und11() {
        const tc = new TextbausteinConfig();
        tc.count = 3;
        tc.titel1 = 'Ergebnis der Beratung';
        tc.titel2 = 'Maßnahmen';
        tc.titel3 = 'Zeitraum der Umsetzung';
        tc.text1 = this._10_1_1.value;
        tc.text2 = this._11_1_1.value;
        tc.text3 = this._11_2_1.value;
        tc.typ = 'mnpschwein';
        return tc;
    }

    getTextbaustein9(): TextbausteinConfig {
        const t = new TextbausteinConfig();
        t.typ = 'mnpschwein';
        return t;
    }

    isEditable() {
        if (this.mps.status_id === 61) {
            return true;
        }
        return false;
    }

    calculateZeitraum() {
        if (this.mps._0_halbjahr === 1) {
            this.zeitraum_von = this.mps._0_jahr + '-01-01';
            this.zeitraum_bis = this.mps._0_jahr + '-06-30';
        } else {
            this.zeitraum_von = this.mps._0_jahr + '-07-01';
            this.zeitraum_bis = this.mps._0_jahr + '-12-31';
        }
        if (this.mps?.kunde_id > 0) {
            this.loadAnzahlBefunde();
            this.loadAnzahlAntibiogramme();
            this.loadAnzahlAbgabebelege();
        }
    }

    showDokumente() {
        this.pdf.openPdfGlobal(
            this.mps.kunde_id,
            new Date(this.zeitraum_von),
            new Date(this.zeitraum_bis),
            'datum',
            { abgabebelege: true, befunde: true, antibiogramme: true }
        );
    }

    loadAnzahlAntibiogramme() {
        this.anzahl_antibiogramme = null;
        let params = new HttpParams();
        params = params.set('zeit_von', this.zeitraum_von);
        params = params.set('zeit_bis', this.zeitraum_bis);
        params = params.set('kunde_id', this.mps.kunde_id.toString());
        if (this.antibiogrammeSubscription) {
            this.antibiogrammeSubscription.unsubscribe();
        }
        this.antibiogrammeSubscription = this.api.get(this.apiBaseUrl + 'antibiogramm/read.php', params).subscribe({
            next: (response) => {
                this.anzahl_antibiogramme = response.count;
            },
        });
    }

    loadAnzahlBefunde() {
        this.anzahl_befunde = null;
        let params = new HttpParams();
        params = params.set('zeit_von', this.zeitraum_von);
        params = params.set('zeit_bis', this.zeitraum_bis);
        params = params.set('kunde_id', this.mps.kunde_id.toString());
        if (this.befundeSubscription) {
            this.befundeSubscription.unsubscribe();
        }
        this.befundeSubscription = this.api.get(this.apiBaseUrl + 'befund/read.php', params).subscribe({
            next: (response) => {
                this.anzahl_befunde = response.count;
            },
        });
    }

    loadAnzahlAbgabebelege() {
        this.anzahl_abgabebelege = null;
        let params = new HttpParams();
        params = params.set('datum_von', this.zeitraum_von);
        params = params.set('datum_bis', this.zeitraum_bis);
        params = params.set('kunde_id', this.mps.kunde_id.toString());
        params = params.set('status_id[]', '42');
        params = params.set('status_id[]', '49');
        if (this.abgabebelegeSubscription) {
            this.abgabebelegeSubscription.unsubscribe();
        }
        this.abgabebelegeSubscription = this.api.get(this.apiBaseUrl + 'abgabebeleg/read.php', params).subscribe({
            next: (response) => {
                this.anzahl_abgabebelege = response.count;
            },
        });
    }

    downloadBBP() {
        this.mnpSchweinService.openAnlageA(
            this.mps.kunde_id,
            this._0_1_3_jahr.value,
            this._0_1_2_halbjahr.value,
            this.tierartCtrl.value,
            false,
            false,
            true
        );
    }

    downloadBefunde() {
        this.mnpSchweinService.openAnlageA(
            this.mps.kunde_id,
            this._0_1_3_jahr.value,
            this._0_1_2_halbjahr.value,
            this.tierartCtrl.value,
            false,
            true,
            false
        );
    }

    downloadAnlageA() {
        this.mnpSchweinService.openAnlageA(
            this.mps.kunde_id,
            this._0_1_3_jahr.value,
            this._0_1_2_halbjahr.value,
            this.tierartCtrl.value,
            true,
            false,
            false
        );
    }

    setAngabenKrankheitsgeschehen(textbaustein: Textbaustein) {
        if (textbaustein) {
            this._9_3_1.setValue(textbaustein.textbaustein1);
        }
    }

    setErregernachweis(textbaustein: Textbaustein) {
        if (textbaustein) {
            this._erregernachweis_text.setValue(textbaustein.textbaustein1);
        }
    }

    setSektion(textbaustein: Textbaustein) {
        if (textbaustein) {
            this._9_4_4.setValue(textbaustein.textbaustein1);
        }
    }

    setSonstiges(textbaustein: Textbaustein) {
        if (textbaustein) {
            this._9_4_6.setValue(textbaustein.textbaustein1);
        }
    }

    signMnp() {

        if (this.benutzerprofil?.unterschrift_png) {
            this.dokumentUnterschreiben(this.mps);
        } else {
            const signature = new Signature();
            signature.benutzer = 1;
            this.unterschriftService.unterschreiben(signature).subscribe({
                next: result => {
                    if (result) {
                        this.dokumentUnterschreiben(this.mps);
                    }
                }
            });
        }

    }

    isKunde() {
        return this.localStorageService.isCurrentUserKundeAdmin();
    }

    private dokumentUnterschreiben(mnp: MassnahmenplanSchwein) {
        this.api.post(this.apiBaseUrl + 'massnahmenplan_schwein/update_unterschrift.php', mnp).subscribe({
            next: response => {
                this.messageService.infoMessage('Ihre Unterschrift wurde erfolgreich im Dokument eingefügt!');
                this.loadMps()
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    setImpfschemaZuchtschweine(textbaustein: Textbaustein) {
        if (textbaustein) {
            this._8_impfschema_zuchtschweine.setValue(textbaustein.textbaustein1);
        }
    }

    setImpfschemaSaugferkel(textbaustein: Textbaustein) {
        if (textbaustein) {
            this._8_impfschema_saugferkel.setValue(textbaustein.textbaustein1)
        }
    }

}
