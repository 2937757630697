import {
    AfterViewInit,
    Component,
    HostListener,
    Inject,
    OnInit,
    ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { EnterValueInput, EnterValueResponse } from './enter-value-response';

@Component({
    selector: 'kiene-enter-value-dialog',
    templateUrl: './enter-value-dialog.component.html',
    styleUrls: ['./enter-value-dialog.component.scss'],
})
export class EnterValueDialogComponent implements OnInit, AfterViewInit {
    @ViewChildren('valueInput') valueInput: MatInput[];

    valueCtrls: FormControl[] = [];

    constructor(
        public dialogRef: MatDialogRef<EnterValueDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EnterValueInput
    ) {
        for (let i = 0; i < this.data?.values?.length; i++) {
            const fc = new FormControl();
            if (this.data?.values[i]?.value) {
                fc.setValue(this.data.values[i].value);
            }
            this.valueCtrls.push(fc);
        }
    }

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.valueInput[0]?.focus();
    }

    @HostListener('window:keyup.Enter', ['$event'])
    checkEnterPress(event: KeyboardEvent): void {
        this.save();
    }

    close() {
        const response = new EnterValueResponse();
        response.result = false;
        this.dialogRef.close(response);
    }

    save() {
        const response = new EnterValueResponse();
        response.result = true;
        for (let i = 0; i < this.data?.values?.length; i++) {
            response.addValue(this.valueCtrls[i].value);
        }
        this.dialogRef.close(response);
    }

    toggleShowHidePassword(index: number) {
        if (this.data?.values[index]?.type === 'password') {
            this.data.values[index].type = 'text';
        } else {
            this.data.values[index].type = 'password';
        }
    }
}
