<mat-form-field *ngIf="!hideOnEmptyList || this.transpondernummernFuerGruppe.length > 0"
                class="abgang-form-input no-padding-margin-mat-form-field" style="margin: 6px 0 0 0; width: 100%">
    <mat-icon style="padding-right: 6px" matPrefix>search</mat-icon>
    <mat-label>{{label}}</mat-label>
    <mat-select [compareWith]="compareTranspondernummer" [formControl]="selectedTranspondernummerCtrl">
        <mat-option *ngFor="let tnr of transpondernummernFuerGruppe" [value]="tnr">{{tnr.name}}</mat-option>
    </mat-select>
    <button *ngIf="selectedTranspondernummerCtrl.value && transpondernummernFuerGruppe.length > 0" matSuffix mat-icon-button aria-label="Clear"
            (click)="resetTranspondernummernCtrl($event)">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
