<div *ngIf="zeitslot_buchung">
    <h1>Anfrage: {{zeitslot_buchung.datum_uhrzeit_von | date: "dd.MM.YYYY"}} - <span *ngIf="landwirt">{{landwirt.bezeichnung}}</span></h1>
    
    <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedIndex">
        <mat-tab label="Buchungsdaten">
            <div class="container">
                <kiene-details [zeitslot_buchung]="zeitslot_buchung" [landwirt]="false" [haendler]="haendler" [schlachthof]="schlachthof" [spediteur]="spediteur" [fahrerData]="fahrer" [offen]="showButtons" [vonHaendlerBeauftragt]="vonHaendlerBeauftragt" [chooseTransporteur]="chooseTransporteur" [lieferscheinNichtAusgefuellt]="lieferscheinNichtAusgefuellt" (acceptCallback)="acceptBuchungCallback($event)" (beauftragenCallback)="beauftragenCallback($event)"></kiene-details>
            </div>
        </mat-tab>

        <mat-tab label="Lieferschein" *ngIf="zeitslot_buchung.status_schlachthof == 1">
            <div class="container">
                <kiene-lieferschein [form]="'landwirt'" [lieferschein_id]="lieferschein_id" *ngIf="lieferscheinNichtAusgefuellt; else elseBlock"></kiene-lieferschein>
                <ng-template #elseBlock>
                    <b>Der Lieferschein wurde bereits ausgefüllt</b>
                </ng-template>
            </div>
        </mat-tab>
        
        <mat-tab label="Standort" *ngIf="zeitslot_buchung.standort || zeitslot_buchung.status_transport == 1 || zeitslot_buchung.status_transport == 2">
            <div class="container">
                <kiene-standort [standort_daten]="zeitslot_buchung.standort" [schlachthof_daten]="schlachthofStandort"></kiene-standort>
            </div>
        </mat-tab>

        <mat-tab label="Zusatzinformationen" *ngIf="zeitslot_buchung.status_schlachthof == 1">
            <div class="container">
                <kiene-pdf-upload [buchung_dateien]="buchung_dateien"></kiene-pdf-upload>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>


