import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {
    ArticleScanSelectedEvent
} from '../../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import {Ruecklieferung} from '../ruecklieferung';
import {Supplier} from '../../supplier/supplier';
import {ChargeService} from '../../charges/charge.service';
import {MatDialogRef} from '@angular/material/dialog';
import {Material} from '../../material/material';
import {Charge} from '../../charges/charges.component';
import {MessageService} from '../../../services/message.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';

@Component({
    selector: 'kiene-create-ruecklieferung-dialog',
    templateUrl: './create-ruecklieferung-dialog.component.html',
    styleUrls: ['./create-ruecklieferung-dialog.component.scss']
})
export class CreateRuecklieferungDialogComponent implements OnInit {

    ruecklieferung = new Ruecklieferung();

    datumCtrl = new FormControl(new Date(), [Validators.required]);
    chargeCtrl = new FormControl(undefined, [Validators.required]);
    mengeCtrl = new FormControl(undefined, [Validators.required]);
    bestellnummerCtrl = new FormControl(undefined);

    material: Material;
    supplier: Supplier;

    constructor(private chargeService: ChargeService,
                public dialogRef: MatDialogRef<CreateRuecklieferungDialogComponent>,
                private messageService: MessageService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService) {
    }

    ngOnInit(): void {
    }

    setMaterial(asse: ArticleScanSelectedEvent) {
        if (asse) {
            this.material = asse.article;
        }
    }

    setLieferant(lieferant: Supplier) {
        this.supplier = lieferant;
    }

    save() {
        if (this.material && this.chargeCtrl.value) {
            const charge = new Charge();
            charge.artikel_id = this.material.artikel_id;
            charge.charge = this.chargeCtrl.value;
            charge.mhd = '01.01.2099';
            this.chargeService.saveCharge(charge).subscribe({
                next: (response: Charge) => {
                    if (response) {
                        const ruecklieferungObj = {
                            menge: this.mengeCtrl.value,
                            lieferant_id: this.supplier.lieferant_id,
                            charge_id: response.charge_id,
                            bestell_nr: this.bestellnummerCtrl.value
                        };

                        this.api.post(this.apiBaseUrl + 'wareneingang/rueckliefern.php', ruecklieferungObj).subscribe({
                            next: responseRueck => {
                                this.messageService.infoMessage('Die Ruecklieferung wurde erfolgreich erzeugt!');
                                this.dialogRef.close(true);
                            },
                            error: err => {
                                this.messageService.errorMessage('Die Rücklieferung konnte nicht erzeugt werden!', err);
                            }
                        });
                    }
                },
                error: err => {
                    this.messageService.errorMessage('Fehler beim Erzeugen der Charge!', err);
                }
            });
        }
    }
}
