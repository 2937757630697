import {LieferantAnlegenDialogComponent} from "../lieferant-anlegen-dialog/lieferant-anlegen-dialog.component";
import {KieneAutocompleteComponent} from "../../../kiene-autocomplete/kiene-autocomplete.component";
import {StammdatenService} from "../../../stammdaten/stammdaten.service";
import {API_BASE_URL_SERVICE} from "../../../../kiene-core.config";
import {MessageService} from "../../../../services/message-service/message.service";
import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {Futtermittellieferant} from '../lieferant';

@Component({
    selector: 'kiene-lieferant-details',
    templateUrl: './lieferant-details.component.html',
    styleUrls: ['./lieferant-details.component.scss']
})
export class LieferantDetailsComponent implements OnInit, OnDestroy {
    lieferantSubscription: Subscription;

    lieferantPath = `${this.apiBaseUrl}futtermittellieferant/read_all.php`;
    isLoading = false;

    @ViewChild('nameAutocomplete') nameAutocomplete: KieneAutocompleteComponent;

    @Input('futtermittellieferant') futtermittellieferant: Futtermittellieferant;
    buttonText = 'Anlegen';
    headline = 'Lieferant';
    

    @Output('saved')
    saved: EventEmitter<Futtermittellieferant> = new EventEmitter<Futtermittellieferant>();

    @Output('deleted')
    deleted: EventEmitter<Futtermittellieferant> = new EventEmitter<Futtermittellieferant>();

    constructor(
        private stammdatenService: StammdatenService,
        private messageService: MessageService,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {

    }

    ngOnDestroy(): void {
        this.lieferantSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.loadLieferant();

    }


    createOrUpdateFuttermittellieferant() {
        this.isLoading = true;
        this.stammdatenService
            .createOrUpdateFuttermittellieferant(this.futtermittellieferant)
            .subscribe({
                next: (futtermittellieferant) => {
                    this.futtermittellieferant = futtermittellieferant;
                    this.messageService.infoMessage(
                        'Der Lieferant wurde erfolgreich aktualisiert.'
                    );
                    this.isLoading = false;
                    this.emit();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Beim Aktualisieren des Lieferanten ist ein Fehler aufgetreten.',
                        err
                    );
                    this.isLoading = false;
                },
            });
    }

    emit(): void {
        this.saved.emit(this.futtermittellieferant);
        this.stammdatenService.speichereLetzenAngelegtenFuttermittellieferant(this.futtermittellieferant);
    }

    selectedLieferant(futtermittellieferant: Futtermittellieferant) {
        if (!futtermittellieferant) {
            return;
        }
        this.futtermittellieferant = futtermittellieferant;
    }


    createNewFuttermittellieferant(lieferantName?: string) {
        this.dialog.open(LieferantAnlegenDialogComponent, {
            data: lieferantName
        }).afterClosed().subscribe({
            next: (lieferant: Futtermittellieferant) => {
                if (lieferant) {
                    this.selectedLieferant(lieferant);
                }
            }
        });
    }


    private loadLieferant() {
        const futtermittellieferantId = this.route.snapshot.paramMap.get('id');
        if (futtermittellieferantId) {
            this.stammdatenService.getFuttermittellieferant(Number.parseInt(futtermittellieferantId, 10)).subscribe({
                next: lieferant => {
                    this.futtermittellieferant = lieferant;
                }
            });
        }
    }
}
