export class Untersuchungsmaterial {
    untersuchungsmaterial_id: number;
    bezeichnung: string;
    bezeichnung_tierart: string;
    artikelstruktur?: any[];
    tierart_id: number;
    tierart: string;
    erstellt?: Date;
    editiert?: Date;

}
