import {NgModule} from '@angular/core';

import {WaWiRoutingModule} from './wa-wi-routing.module';
import {WaWiComponent} from './wa-wi.component';
import {BestellungExternComponent} from './bestellung-extern/bestellung-extern.component';
import {
    BestellungExternDetailsComponent
} from './bestellung-extern/bestellung-extern-details/bestellung-extern-details.component';
import {
    BestellungExternListComponent
} from './bestellung-extern-list/bestellung-extern-list/bestellung-extern-list.component';
import {
    InvoiceDeliverNoteDialogComponent
} from './bestellung-extern/invoice-deliver-note-dialog/invoice-deliver-note-dialog.component';
import {AddCommentDialogComponent} from './bestellung-extern-list/add-comment-dialog/add-comment-dialog.component';
import {
    UploadInvoiceDialogComponent
} from './bestellung-extern-list/upload-invoice-dialog/upload-invoice-dialog.component';
import {PhitoDropFileDirective} from './bestellung-extern-list/phito-drop-file.directive';
import {BestellungInternComponent} from './bestellung-intern/bestellung-intern.component';
import {
    BestellungInternDetailsComponent
} from './bestellung-intern/bestellung-intern-details/bestellung-intern-details.component';
import {
    AddArticleToWarenkorbDialog,
    BestellvorschlaegeComponent
} from './bestellvorschlaege/bestellvorschlaege.component';
import {
    ChangeBestellmengenDialogComponent
} from './bestellvorschlaege/change-bestellmengen-dialog/change-bestellmengen-dialog.component';
import {GutschriftenComponent} from './gutschriften/gutschriften.component';
import {AddGutschriftDialogComponent} from './gutschriften/add-gutschrift-dialog/add-gutschrift-dialog.component';
import {InformationenComponent} from './informationen/informationen.component';
import {ReleaseWarenkorbConfirmDialog, WarenkorbComponent} from './warenkorb/warenkorb.component';
import {BestellungExternService} from './bestellung-extern/bestellung-extern.service';
import {BestellungInternServiceService} from './bestellung-intern/bestellung-intern-service.service';
import {BestellvorschlagService} from './bestellvorschlaege/bestellvorschlag.service';
import {WarenkorbService} from './warenkorb/warenkorb.service';
import {KieneTableModule} from '../components/kiene-table/kiene-table.module';
import {
    ShowGutschriftenDialogComponent
} from './bestellung-extern-list/show-gutschriften-dialog/show-gutschriften-dialog.component';
import {BestellvorschlagInternComponent} from './bestellvorschlag-intern/bestellvorschlag-intern.component';
import {
    BestellvorschlagInternDialogComponent
} from './bestellvorschlag-intern/bestellvorschlag-intern-dialog/bestellvorschlag-intern-dialog.component';
import {GemeinkostenComponent} from './gemeinkosten/gemeinkosten.component';
import {
    GemeinkostenDetailsDialogComponent
} from './gemeinkosten/gemeinkosten-details-dialog/gemeinkosten-details-dialog.component';
import {WarehouseModule} from '../warehouse/warehouse.module';
import {ArticleScanAutocompleteModule} from '../components/article-scan-autocomplete/article-scan-autocomplete.module';
import {HonorareUndGehaelterComponent} from './honorare-und-gehaelter/honorare-und-gehaelter.component';
import {
    RechnungBestellpositionCheckDialogComponent
} from './bestellung-extern-list/rechnung-bestellposition-check-dialog/rechnung-bestellposition-check-dialog.component';
import {HomeModule} from '../home/home.module';
import {CommonModule} from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatChipsModule} from '@angular/material/chips';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {KieneAutocompleteModule} from '../components/kiene-autocomplete/kiene-autocomplete.module';
import {ComponentsModule} from '../components/components.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatListModule} from '@angular/material/list';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTooltipModule} from '@angular/material/tooltip';
import {KieneDirectivesModule} from '../directives/kiene-directives.module';
import {DirectivesModule} from '../../../projects/kiene-core/src/lib/directives/directives.module';
import {
    ChargeAuswahlDialogComponent
} from './bestellung-intern/bestellung-intern-details/charge-auswahl-dialog/charge-auswahl-dialog.component';
import {A11yModule} from '@angular/cdk/a11y';

@NgModule({
    declarations: [
        WaWiComponent,
        BestellungExternComponent,
        BestellungExternDetailsComponent,
        BestellungExternListComponent,
        InvoiceDeliverNoteDialogComponent,
        AddCommentDialogComponent,
        UploadInvoiceDialogComponent,
        PhitoDropFileDirective,
        BestellungInternComponent,
        BestellungInternDetailsComponent,
        BestellvorschlaegeComponent,
        ChangeBestellmengenDialogComponent,
        GutschriftenComponent,
        AddGutschriftDialogComponent,
        InformationenComponent,
        WarenkorbComponent,
        AddArticleToWarenkorbDialog,
        ReleaseWarenkorbConfirmDialog,
        ShowGutschriftenDialogComponent,
        BestellvorschlagInternComponent,
        BestellvorschlagInternDialogComponent,
        GemeinkostenComponent,
        GemeinkostenDetailsDialogComponent,
        HonorareUndGehaelterComponent,
        RechnungBestellpositionCheckDialogComponent,
        ChargeAuswahlDialogComponent
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatCardModule,
        MatMenuModule,
        MatChipsModule,
        MatToolbarModule,
        MatExpansionModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatListModule,
        MatBadgeModule,
        MatTooltipModule,
        MatDialogModule,
        MatPaginatorModule,
        MatRadioModule,
        MatDividerModule,
        KieneAutocompleteModule,
        KieneTableModule,
        WaWiRoutingModule,
        WarehouseModule,
        ArticleScanAutocompleteModule,
        HomeModule,
        ComponentsModule,
        KieneDirectivesModule,
        DirectivesModule, A11yModule
    ],
    providers: [
        BestellungExternService,
        BestellungInternServiceService,
        BestellvorschlagService,
        WarenkorbService
    ]
})
export class WaWiModule {
}
