import {formatDate} from "@angular/common";
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
    selector: 'kiene-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
    @Input() date: Date;
    @Input() showSeconds = false;
    @Input() label = 'Uhrzeit';
    @Output() dateChange = new EventEmitter<Date>();

    timeCtrl = new FormControl();

    constructor() {
    }

    ngOnInit(): void {
        this.initDateText();

        this.timeCtrl.valueChanges.subscribe(value => {
            if (this.date && value) {
                const [hours, minutes, seconds] = value.split(':');
                this.date.setHours(+hours);
                this.date.setMinutes(+minutes);
                if (this.showSeconds && seconds) {
                    this.date.setSeconds(+seconds);
                }
                console.log("TimePickerComponent - Emitting DateChange", this.date);
                this.dateChange.emit(new Date(this.date));
            }
        });
    }


    private initDateText() {
        if (!this.date) {
            this.date = new Date();
        }
        let timeTxt = '';
        if (this.showSeconds) {
            timeTxt = formatDate(this.date, 'HH:mm:ss', 'de');
        } else {
            timeTxt = formatDate(this.date, 'HH:mm', 'de');
        }
        this.timeCtrl.setValue(timeTxt);
    }
}
