<h1 mat-dialog-title>Geb&uuml;hren Meldungen</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Jahr</mat-label>
        <input matInput type="number" [formControl]="jahrCtrl">
    </mat-form-field>
    <br>
    <mat-form-field>
        <mat-label>Halbjahr</mat-label>
        <mat-select [formControl]="halbjahrCtrl">
            <mat-option *ngFor="let h of halbjahre" [value]="h">{{h}}</mat-option>
        </mat-select>
    </mat-form-field>
    <br>
    <kiene-article-scan-autocomplete [placeholder]="'Artikel'"
                                     (selectedMaterial)="setMaterial($event)"></kiene-article-scan-autocomplete>
    <br>
    <mat-form-field appearance="fill">
        <mat-label>Belegdatum</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="belegDatumCtrl">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Generieren</button>
</div>
