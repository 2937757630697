export class Anwendung {
    anwendung_id: number;
    anwendung: string;
    artikel_id: number;
    artikel: string;
    wartezeit_fleisch: number;
    wartezeit_milch: number;
    wartezeit_eier: number;
    kommentar: string;
}

export interface AnwendungApi {
    records: Anwendung[];
    count: number;
}

export class AnwendungEvent {
    anwendung: Anwendung;
    ladePosition = false;

    constructor(anwendung: Anwendung, options?: { ladePosition?: boolean }) {
        this.anwendung = anwendung;
        if (options?.ladePosition === true) {
            this.ladePosition = true;
        }
    }
}
