export class AssignVetToTourData {
    title = 'Tierarzt der Tour zuweisen';
    buttonOk = 'Zuweisen';
    showUnbekannt = false;
    tierarzt_id: number;
}

export class AssignVetToTourResult {
    confirmed: boolean = false;
    tierarzt_id: number;
}