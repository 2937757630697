import { HttpParams } from '@angular/common/http';

export class AbgangDownloadRequestPojo {
    confirmed = false;
    tierlisten_ids: number[] = [];
    show_barcode: number;
    show_gruppendetails: number;
    show_tiergruppe: number;
    add_scans: number;
    pdf = false;
    csv = false;
    excel = false;

    constructor(
        tierlisten_ids: number[],
        show_barcode: number,
        show_gruppendetails: number,
        show_tiergruppe: number,
        add_scans: number
    ) {
        this.confirmed = true;
        this.tierlisten_ids = tierlisten_ids;
        this.show_barcode = show_barcode;
        this.show_gruppendetails = show_gruppendetails;
        this.show_tiergruppe = show_tiergruppe;
        this.add_scans = add_scans;
    }

    getHttpParams(): HttpParams {
        let params = new HttpParams();
        params = params.append('show_tiergruppe', this.show_tiergruppe?.toString());
        params = params.append('show_barcode', this.show_barcode?.toString());
        params = params.append('show_gruppendetails', this.show_gruppendetails?.toString());
        params = params.append('add_scans', this.add_scans?.toString());
        for (const tierliste_id of this.tierlisten_ids) {
            params = params.append('tierliste_id[]', tierliste_id?.toString());
        }
        params = params.append('csv', this.csv ? '1' : '0');
        params = params.append('excel', this.excel ? '1' : '0');
        params = params.append('pdf', this.pdf ? '1' : '0');
        return params;
    }

    pdfDownloadVerfuegbar() {
        if (this.tierlisten_ids?.length == 1 && this.pdf === true && this.csv === false && this.excel === false) {
            return true;
        }
        return false;
    }

    csvDownloadVerfuegbar() {
        if (this.tierlisten_ids?.length == 1 && this.csv === true && this.pdf === false && this.excel === false) {
            return true;
        }
        return false;
    }

    excelDownloadVerfuegbar() {
        if (this.tierlisten_ids?.length == 1 && this.excel === true && this.pdf === false && this.csv === false) {
            return true;
        }
        return false;
    }
}