<h1 mat-dialog-title>{{ data?.title }}</h1>
<div mat-dialog-content>
    <p *ngIf="data?.text">{{ data?.text }}</p>
    <ng-container *ngFor="let v of data?.values; let i = index">
        <mat-form-field *ngIf="v.type !== 'date'" style="width: 100%">
            <mat-label>{{ v?.label ? v.label : 'Eingabe' }}</mat-label>
            <input #valueInput="matInput" [attr.inputmode]="v?.inputmode" [formControl]="valueCtrls[i]" [type]="v?.type"
                   cdkFocusInitial matInput/>
            <mat-icon *ngIf="data?.suffixIcon" matSuffix>{{ data.suffixIcon }}</mat-icon>
            <button (click)="toggleShowHidePassword(i)" *ngIf="v?.showHidePassword" mat-icon-button matSuffix>
                <mat-icon>{{
                        v?.type === "password" ? "visibility" : "visibility_off"
                    }}
                </mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field *ngIf="v.type === 'date'">
            <mat-label>{{ v?.label ? v.label : 'Eingabe' }}</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="valueCtrls[i]" [readonly]="true">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </ng-container>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button (click)="close()" mat-button>Abbrechen</button>
    <button (click)="save()" (keyup.enter)="save()" [color]="data?.buttonColor" mat-flat-button>
        {{ data?.okButton }}
    </button>
</div>
