import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';

@Injectable({
  providedIn: 'root'
})
export class MeldungService {

  constructor(private api: KieneTableService) { }

  public jetztAlleMeldungenSenden(){
    return this.api.post(environment.BASE_URI + 'meldung/anstossen.php', {},null);
  }
}
