<h1 mat-dialog-title>Standort{{gruppeStandort?.gs_id ? ' bearbeiten' : ' wechseln'}}</h1>
<div mat-dialog-content>
    <p>Gruppe: {{gruppe?.bezeichnung}}</p>
    <p *ngIf="!gruppeStandort?.gs_id">Aktueller Standort: {{gruppe?.stall}}</p>
    <kiene-autocomplete [genericApiUrl]="standortApiUrl" [emitFreeText]="true" [params]="params"
        [placeholder]="gruppeStandort?.gs_id ? 'Standort' : 'Neuer Standort'" [displayFields]="['stall','sgs_nummer']"
        separator=", " [kieneObject]="stall" (elementSelected)="setStandort($event)">
    </kiene-autocomplete>

    <mat-form-field *ngIf="sgsNummerEinblenden" style="width: 100%">
        <mat-label>SGS Nummer</mat-label>
        <input [formControl]="sgsNummerCtrl" matInput type="text" />
    </mat-form-field>

    <mat-form-field style="width: 100%;">
        <mat-label>{{gruppeStandort?.gs_id ? '' : 'Neuer '}}Produktionsabschnitt</mat-label>
        <mat-select [formControl]="produktionsabschnittCtrl" [compareWith]="produktionsabschnittComparator">
            <mat-option *ngFor="let p of produktionsabschnitte" [value]="p">{{p.produktionsabschnitt}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 100%;">
        <mat-label>Datum</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="datumVonCtrl" [readonly]="true">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field *ngIf="gruppeStandort?.gs_id" style="width: 100%;">
        <mat-label>Bis</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="datumBisCtrl" [readonly]="true">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <p>Anzahl Tiere zu diesem Datum: <strong>{{anzahlTiereAnDatum}}</strong></p>

    <ng-container *kienePermission="[9161]">
        <ng-container *ngIf="showGewicht">

            <mat-hint>Das eingetragene Gewicht wird bei diesem Standort als Einstallgewicht gespeichert und als
                Abgangsgewicht beim vorherigen Standort.
            </mat-hint>

            <mat-form-field style="width: 100%;">
                <mat-label>Gesamtgewicht</mat-label>
                <input matInput type="number" inputmode="decimal" [formControl]="gesamtgewichtCtrl">
            </mat-form-field>

            <mat-form-field style="width: 100%;">
                <mat-label>&Oslash; Durchschnittsgewicht</mat-label>
                <input matInput type="number" inputmode="decimal" [formControl]="durchschnittsgewichtCtrl">
            </mat-form-field>
        </ng-container>
    </ng-container>

</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">{{gruppeStandort?.gs_id ? 'Speichern' :
        'Wechseln'}}</button>
</div>