import {AfterViewInit, Component, Inject, OnInit, QueryList, ViewChildren,} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatInput} from '@angular/material/input';
import {AbgabebelegCharge, AbgabebelegPosition,} from '../../documents/abgabebeleg/abgabebeleg';
import {AbgabebelegService} from '../../documents/abgabebeleg/abgabebeleg.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MessageService} from '../../services/message.service';
import {Stock} from '../../warehouse/stock/stock';
import {StockService} from '../../warehouse/stock/stock.service';

@Component({
    selector: 'kiene-abgabebeleg-charge-dialog',
    templateUrl: './abgabebeleg-charge-dialog.component.html',
    styleUrls: ['./abgabebeleg-charge-dialog.component.scss'],
})
export class AbgabebelegChargeDialogComponent implements OnInit, AfterViewInit {
    abgabebelegPosition: AbgabebelegPosition;
    // artikelId: number;
    // setpoint: number;
    // artikelName: string;
    tierarztId: number;

    isLoading = false;

    chargen: AbgabebelegCharge[] = [];

    istMengeCtrls: UntypedFormControl[] = [];

    chargeCtrl = new UntypedFormControl();
    mengeCtrl = new UntypedFormControl(0);
    mhdCtrl = new UntypedFormControl();

    @ViewChildren('istMengeInput', {read: MatInput})
    istMengeInput: QueryList<MatInput>;

    constructor(
        private api: AbgabebelegService,
        public dialogRef: MatDialogRef<AbgabebelegChargeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private stockService: StockService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService
    ) {
        this.abgabebelegPosition = data.abgabebelegPosition;
        // this.artikelId = data.artikelId;
        // this.setpoint = data.setpoint;
        // this.artikelName = data.artikelName;
        this.tierarztId = data.tierarztId;
    }

    ngOnInit() {
        this.loadChargen();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.istMengeInput.changes.subscribe((ism) => {
                const elems = ism.toArray();
                if (elems && elems.length > 0) {
                    elems[0]?.focus();
                }
            });
        }, 10);
    }

    loadChargen() {
        this.isLoading = true;
        this.api
            .getChargenForMaterial(this.abgabebelegPosition, this.tierarztId)
            .subscribe(
                (response) => {
                    this.chargen = response.records;

                    for (let i = 0; i < this.chargen.length; i++) {
                        // 4 Nachkommastellen
                        this.chargen[i].menge_auto =
                            Math.round(this.chargen[i].menge_auto * 10000) /
                            10000;
                        this.chargen[i].menge_beleg =
                            Math.round(this.chargen[i].menge_beleg * 10000) /
                            10000;
                        this.istMengeCtrls[i] = new UntypedFormControl(
                            this.chargen[i].menge_beleg
                                ? this.chargen[i].menge_beleg
                                : 0
                        );
                    }

                    const kieneSession =
                        this.localStorageService.getCurrentUser();
                    if (kieneSession?.automatische_chargenauswahl > 0) {
                        if (this.abgabebelegPosition.artikelmenge > 0) {
                            // Sollmenge automatisch den ältestes MHDs zuordnen
                            let istmengen = 0;
                            for (const c of this.chargen) {
                                istmengen += c.menge_beleg;
                            }

                            let sollmenge =
                                this.abgabebelegPosition.artikelmenge;
                            if (istmengen != sollmenge) {
                                // nur automatisch zuweisen, wenn noch keine Istmenge eingetragen wurde
                                let i = 0;
                                while (
                                    sollmenge > 0 &&
                                    i < this.chargen.length
                                    ) {
                                    const charge: AbgabebelegCharge =
                                        this.chargen[i];
                                    let ma: number = null;
                                    if (charge.menge_auto < 1) {
                                        if (
                                            sollmenge <= charge.menge_auto &&
                                            this.abgabebelegPosition
                                                .behandlung > 0
                                        ) {
                                            ma = sollmenge;
                                        } else {
                                            ma = 0;
                                        }
                                    } else if (sollmenge > charge.menge_auto) {
                                        if (
                                            this.abgabebelegPosition
                                                .behandlung === 0
                                        ) {
                                            // Abgabeartikel -> nur ganze Flaschen abgeben
                                            ma = Math.trunc(charge.menge_auto);
                                        } else {
                                            ma = charge.menge_auto;
                                        }
                                    } else {
                                        // sollmenge <= charge.menge_auto
                                        if (
                                            this.abgabebelegPosition
                                                .behandlung === 0
                                        ) {
                                            // Abgabeartikel -> nur ganze Flaschen abgeben
                                            ma = Math.trunc(sollmenge);
                                        } else {
                                            ma = sollmenge;
                                        }
                                    }
                                    charge.menge_beleg = ma;
                                    sollmenge -= ma;
                                    this.istMengeCtrls[i].setValue(
                                        charge.menge_beleg
                                    );
                                    i++;
                                }
                            }
                        }
                    }
                    this.isLoading = false;
                },
                (error) => {
                    this.isLoading = false;
                }
            );
    }

    createCharge() {
        const stock = new Stock();
        stock.artikel_id = this.abgabebelegPosition.artikel_id;
        stock.charge = this.chargeCtrl.value;
        stock.menge = this.mengeCtrl.value;
        stock.mhd = this.mhdCtrl.value;
        stock.tierarzt_id = this.tierarztId;

        this.stockService.createTierarztBestand(stock).subscribe(
            (response) => {
                this.chargeCtrl.setValue(null);
                this.mengeCtrl.setValue(0);
                this.mhdCtrl.setValue(null);
                this.messageService.infoMessage(
                    'Der Bestand wurde erfolgreich angelegt!'
                );
                this.loadChargen();
            },
            (error) => {
                this.messageService.errorMessage(
                    'Der Bestand konnte nicht angelegt werden!',
                    error
                );
                this.loadChargen();
            }
        );
    }

    save() {
        for (let i = 0; i < this.chargen.length; i++) {
            this.chargen[i].menge_beleg = this.istMengeCtrls[i].value; // Nachkommastellen weg
        }
        this.dialogRef.close(this.chargen);
    }

    cancel() {
        this.dialogRef.close(false);
    }

    gibArtikelname() {
        if (this.abgabebelegPosition) {
            return this.abgabebelegPosition.artikel_me;
        }
        return '';
    }

    gibArtikelmenge() {
        if (this.abgabebelegPosition) {
            return this.abgabebelegPosition.artikelmenge;
        }
        return '';
    }
}
