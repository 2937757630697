import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {Signature} from '../signature';
import {MessageService} from '../../../services/message-service/message.service';
import {SignaturePanelComponent} from '../../signature-panel/signature-panel/signature-panel.component';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {API_BASE_URL_SERVICE} from '../../../kiene-core.config';
import {KieneBackendApiService} from '../../../services/backend-api/kiene-backend-api.service';

@Component({
    selector: 'kiene-sign-panel',
    templateUrl: './sign-panel.component.html',
    styleUrls: ['./sign-panel.component.scss'],
})
export class SignPanelComponent implements OnInit {

    @ViewChild('panelVet') panelVet: SignaturePanelComponent;
    @ViewChild('panelCustomer') panelCustomer: SignaturePanelComponent;
    @ViewChild('panelUser') panelUser: SignaturePanelComponent;

    signatureId: string;

    signature: Signature = new Signature();

    signatureVetSet = false;
    signatureCustomerSet = false;
    signatureUserSet = false;

    constructor(private route: ActivatedRoute,
                private api: KieneBackendApiService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private notification: MessageService) {
        this.signatureId = this.route.snapshot.paramMap.get('id');
    }

    ngOnInit() {
        this.loadSignatureObject();
    }

    loadSignatureObject() {
        if (this.signatureId) {
            const params = new HttpParams().append('qrcode', this.signatureId);
            this.api.get(this.apiBaseUrl + 'unterschrift/read_by_qrcode.php', params).subscribe(response => {
                this.signature = response;
                if (this.signature.tierarzt_png) {
                    this.signatureVetSet = true;
                }
                if (this.signature.kunde_png) {
                    this.signatureCustomerSet = true;
                }
                if (this.signature.benutzer_png) {
                    // this.signatureUserSet = true; erneuern
                }
            }, error => {
                this.notification.errorMessage('Fehler! ', error);
            });
        }
    }

    save() {
        this.api.post(this.apiBaseUrl + 'unterschrift/update.php', this.signature, undefined).subscribe(response => {
            this.signature = response;
            if (this.signature.tierarzt_png) {
                this.signatureVetSet = true;
            }
            if (this.signature.kunde_png) {
                this.signatureCustomerSet = true;
            }
            if (this.signature.benutzer_png) {
                this.signatureUserSet = true;
            }
            this.notification.infoMessage('Sie haben erfolgreich unterschrieben! Sie können das Browserfenster jetzt schließen!');
        }, error => {
            this.notification.errorMessage('Es ist ein Fehler aufgetreten. Das Dokument wurde nicht unterschrieben. Bitte wenden Sie sich an vetvet.de!', error);
        });
    }


    saveVetSignature(signature: string) {
        if (this.signature) {
            this.signature.tierarzt_png = signature;
            this.save();
        }
    }

    saveCustomerSignature(signature: string) {
        if (this.signature) {
            this.signature.kunde_png = signature;
            this.save();
        }
    }

    saveUserSignature(signature: string) {
        if (this.signature) {
            this.signature.benutzer_png = signature;
            this.save();
        }
    }

    tabChanged(event: MatTabChangeEvent) {
        /*console.log('P' + event.tab.position)
        const currentTabIndex = event.index;
        console.log('current tab index: %d', currentTabIndex);
        if (currentTabIndex === 0 && !this.signatureVetSet) {
            this.panelVet.resizeCanvas();
        } else if (currentTabIndex === 1 && !this.signatureCustomerSet) {
            this.panelCustomer.resizeCanvas();
        } else if (currentTabIndex === 2 && !this.signatureUserSet) {
            this.panelUser.resizeCanvas();
        }*/
        this.panelVet?.resizeCanvas();
        this.panelCustomer?.resizeCanvas();
        this.panelUser?.resizeCanvas();
    }

    close() {
        window.close();
    }
}
