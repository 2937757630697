<h1 mat-dialog-title> PDF Dokument als E-Mail versenden</h1>

<div mat-dialog-content>

    <mat-form-field style="width: 100%; margin-bottom: 6px" class="no-padding-margin-mat-form-field">
        <mat-label>Betreff</mat-label>
        <input matInput type="text" required [(ngModel)]="betreff">
    </mat-form-field>

    <h4 style="margin-block-end: 6px; margin-block-start: 6px">Empf&auml;nger</h4>

    <!--    <div>-->
    <!--        <div class="one-row" style="padding-bottom: 12px">-->
    <!--            <mat-form-field style="width: 90%; margin-bottom: 6px" class="no-padding-margin-mat-form-field">-->
    <!--                <mat-label>E-Mail hinzuf&uuml;gen</mat-label>-->
    <!--                <input matInput type="text" [formControl]="neuerEmpfaengerCtrl">-->
    <!--                <mat-error style="padding-top: 18px; font-size: 0.8em" *ngIf="neuerEmpfaengerCtrl.hasError('email')">-->
    <!--                    Bitte eine g&uuml;ltige Email im Format "meine@email.de" eingeben.-->
    <!--                </mat-error>-->
    <!--            </mat-form-field>-->
    <!--            <button mat-icon-button color="accent" [disabled]="!neuerEmpfaengerCtrl.value" (click)="addToList()">-->
    <!--                <mat-icon>add</mat-icon>-->
    <!--            </button>-->
    <!--        </div>-->
    <!--    </div>-->
    <div>
        <kiene-autocomplete [genericApiUrl]="apiBaseUrl + 'benutzer_kontakte/read.php'"
                            [placeholder]="'E-Mail eingeben'"
                            [inputmode]="'email'"
                            [displayField]="'email'" [focus]="false" [clearAfterSelect]="true" [showClear]="false"
                            [emitButton]="'add'" [emitFreeText]="true"
                            (elementSelected)="addToList($event)"></kiene-autocomplete>
    </div>


    <table aria-label="" style="width: 100%">
        <thead style="display: none">
        <th></th>
        <th></th>
        <th></th>
        </thead>
        <tbody>
        <tr *ngFor="let e of empfaenger" (click)="setSelected($event, e)">
            <td style="padding-right: 6px">
                <mat-checkbox [(ngModel)]="e.selected"></mat-checkbox>
            </td>
            <td>
                <div>{{e.name}}</div>
                <div style="font-size: 0.8em; color: #777">({{e.email}})</div>
            </td>
        </tr>
        </tbody>
    </table>
</div>

<div mat-dialog-actions>
    <button [disabled]="!isValid() || isSending" (click)="send()" mat-flat-button color="accent">
        <mat-icon style="transform: scale(0.8); margin-right: 3px">send</mat-icon>
        <span>Senden</span>
    </button>
    <button mat-flat-button (click)="dialogRef.close()">Schlie&szlig;en</button>
</div>
