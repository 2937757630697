<h1 mat-dialog-title>Tierliste importieren</h1>
<div mat-dialog-content>
    <p><strong>Tierliste:</strong></p>
    <table>
        <tr>
            <td>Datum</td>
            <td>{{tierliste?.datum_abgang | date: 'dd.MM.yyyy'}}</td>
        </tr>
        <tr>
            <td>Kommentar</td>
            <td>{{tierliste?.kommentar}}</td>
        </tr>
        <tr>
            <td>Anzahl Tiere</td>
            <td>{{tierliste?.anzahl_tiere}}</td>
        </tr>
        <tr>
            <td>Kunde Einstallen</td>
            <td>{{tierliste?.kunde_einstallen}}</td>
        </tr>
    </table>
    <p>Bitte w&auml;hlen Sie, ob die Tiere in eine neue Gruppe oder in eine vorhandene Gruppe hinzugef&uuml;gt werden
        sollen!</p>
    <mat-radio-group [formControl]="radioCtrl" class="radio-group">
        <mat-radio-button [value]="0" class="radio-button">In eine neue Gruppe importieren</mat-radio-button>
        <mat-radio-button [value]="1" class="radio-button">In eine vorhandene Gruppe importieren</mat-radio-button>
    </mat-radio-group>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button (click)="dialogRef.close()" mat-button>Abbrechen</button>
    <button (click)="importieren()" [disabled]="!radioCtrl.valid" color="accent" mat-flat-button>Importieren</button>
</div>
