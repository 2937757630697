<h1 mat-dialog-title>Wartezeit Sonderfall</h1>
<div mat-dialog-content>
    <p>
        Medikament: <span>{{ material?.artikel_me }}</span>
    </p>

    <kiene-tierart-selector
        #tierartSelektor
        [materialId]="material.artikel_id"
        (tierartSelectionChanged)="setArtikelTierart($event)"
    ></kiene-tierart-selector>

    <kiene-anwendungs-selector
        #anwendungSelektor
        (change)="setAnwendung($event)"
    ></kiene-anwendungs-selector>

    <kiene-diagnose-selector
        #diagnoseSelektor
        (change)="setDiagnose($event)"
    ></kiene-diagnose-selector>
    <div class="one-row">
        <mat-form-field style="width: 30%">
            <mat-label>Wartezeit Fleisch</mat-label>
            <input
                matInput
                type="number"
                inputmode="numeric"
                [formControl]="fleischCtrl"
            />
        </mat-form-field>
        <span class="spacer"></span>
        <mat-form-field style="width: 30%">
            <mat-label>Wartezeit Milch</mat-label>
            <input
                matInput
                type="number"
                inputmode="numeric"
                [formControl]="milchCtrl"
            />
        </mat-form-field>
        <span class="spacer"></span>
        <mat-form-field style="width: 30%">
            <mat-label>Wartezeit Eier</mat-label>
            <input
                matInput
                type="number"
                inputmode="numeric"
                [formControl]="eierCtrl"
            />
        </mat-form-field>
    </div>

    <mat-form-field style="width: 100%">
        <mat-label>Kommentar</mat-label>
        <input matInput type="text" [formControl]="kommentarCtrl" />
    </mat-form-field>

    <mat-form-field>
        <mat-label>Menge</mat-label>
        <input matInput type="number" [formControl]="mengeCtrl" />
    </mat-form-field>

    <mat-form-field>
        <mat-label>Einheit</mat-label>
        <mat-select
            [formControl]="einheitCtrl"
            [compareWith]="einheitComparator"
        >
            <mat-option *ngFor="let u of units" [value]="u">{{
                u.bezeichnung
            }}</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="one-row">
        <mat-form-field style="width: 22%">
            <mat-label>Behandlung in Tagen</mat-label>
            <input matInput type="number" [formControl]="behandlungTagenCtrl" />
        </mat-form-field>

        <span class="spacer"></span>
        <mat-form-field style="width: 22%">
            <mat-label>Intervall in Stunden</mat-label>
            <input matInput type="number" [formControl]="intervallStdCtrl" />
        </mat-form-field>

        <span class="spacer"></span>
        <mat-form-field style="width: 22%">
            <mat-label>Faktor</mat-label>
            <input matInput type="number" [formControl]="faktorCtrl" />
        </mat-form-field>

        <span class="spacer"></span>
        <mat-form-field style="width: 22%">
            <mat-label>K&ouml;rpergewicht kg</mat-label>
            <input matInput type="number" [formControl]="koerpergewichtCtrl" />
        </mat-form-field>

        <span class="spacer"></span>
        <mat-form-field style="width: 22%">
            <mat-label>Dosierungstext</mat-label>
            <input matInput type="text" [formControl]="dosierungTxtCtrl" />
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</div>
