<h1 mat-dialog-title>Tierreklamation {{action}}</h1>

<div mat-dialog-content>
    <div style="padding-top: 24px; display: flex; flex-direction: column; align-items: center; justify-content: center">
        <mat-form-field style="width: 100%">
            <mat-label>Tierreklamation</mat-label>
            <input data-cy="tierreklamation-edit-dialog-reklamation" matInput [formControl]="reklamationCtrl" name="text" required>
            <mat-error *ngIf="reklamationCtrl.hasError('required')">
                Es muss ein reklamation angegeben werden.
            </mat-error>
        </mat-form-field>
    </div>
</div>

<div mat-dialog-actions>
    <button [disabled]="!reklamationCtrl.value || reklamationCtrl.value === '' || isSaving" (click)="updateTierreklamation()"
            mat-flat-button data-cy="tierreklamation-edit-dialog-save" color="accent">Speichern</button>
    <button mat-flat-button data-cy="tierreklamation-edit-dialog-abort" (click)="dialogRef.close()">Abbrechen</button>
</div>
