export class EnterValue {
    label = 'Wert';
    type = 'text';
    value: any = null;
    inputmode = 'text';
    showHidePassword = false;
}

export class EnterValueInput {
    title = 'Bitte Wert eingeben';
    text = 'Bitte geben Sie einen Wert ein.';
    suffixIcon: string;
    okButton = 'Speichern';
    buttonColor = 'accent';

    values: EnterValue[] = [];

    getValues() {
        return this.values;
    }

    // getValuesLength(): number {
    //     if (!this.values) {
    //         return 0;
    //     }
    //     return this.values?.length;
    // }
    //
    // getType(index: number): string {
    //     if (!this.values || index >= this.values.length) {
    //         return null;
    //     }
    //     return this.values[index].type;
    // }
    //
    // getValue(index: number): string {
    //     if (!this.values || index >= this.values.length) {
    //         return null;
    //     }
    //     return this.values[index].value;
    // }
    //
    // setType(index: number, type: string) {
    //     if (!this.values || index >= this.values.length) {
    //         return;
    //     }
    //     this.values[index].type = type;
    // }

    addInputValue(label: string, inputmode: string, value?: any, suffixIcon?: string) {
        const ev = new EnterValue();
        ev.label = label;
        ev.inputmode = inputmode;
        if (value) {
            ev.value = value;
        }
        if (inputmode === 'text') {
            ev.type = 'text';
        } else if (inputmode === 'decimal' || inputmode === 'numeric') {
            ev.type = 'number';
        } else if (inputmode === 'password') {
            ev.type = 'password';
            ev.showHidePassword = true;
        } else if (inputmode === 'date') {
            ev.type = 'date';
        }
        if (suffixIcon) {
            this.suffixIcon = suffixIcon;
        }
        this.values.push(ev);
    }
}

export class EnterValueResponse {
    values: any[] = [];
    result: boolean;

    getValue(index: number) {
        if (!this.values || index >= this.values.length) {
            return null;
        }
        return this.values[index];
    }

    addValue(value: any) {
        this.values.push(value);
    }
}
