import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Mandant } from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';
import { KieneBackendApiService } from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { LocalStorageService } from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';

@Component({
  selector: 'kiene-tamaus-hitdialog',
  templateUrl: './tamaus-hitdialog.component.html',
  styleUrls: ['./tamaus-hitdialog.component.scss']
})
export class TAMAusHITDialogComponent implements OnInit {

  meldung: any;
  mandant: Mandant;
  jsn: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data,
    private api: KieneBackendApiService,
    private messageService: MessageService,
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService) {
    this.meldung = this.data.meldung;
  }

  ngOnInit(): void {
    this.mandant = this.localStorageService.getCurrentMandant();
    if (this.meldung) {

      this.ladeTAMausHIT();
    }
  }

  ladeTAMausHIT() {
    const params = new HttpParams().set('mandantid', this.mandant.mandant_id.toString());
    this.httpClient.get('http://vr10152.webspaceconfig.de:8081/kienehitqsapi/resources/hit/prescription/tam/' + this.meldung.meldung_id, { params: params }).subscribe({
      next: response => {
        this.jsn = JSON.stringify(response, null, 4);
      },
      error: err => {
        this.messageService.errorMessage('Fehler!', err);
      }
    });
  }

}
