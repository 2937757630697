import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StammdatenService } from '../../stammdaten/stammdaten.service';
import { KieneAutocompleteComponent } from '../../kiene-autocomplete/kiene-autocomplete.component';
import { Client } from '../../client/classes/client';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../services/message-service/message.service';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { Subscription } from 'rxjs';
import { Gruppe } from '../classes/gruppe';
import { ConfirmDialogInput, ConfirmDialogResult } from '../../dialogs/confirm-dialog/confirm-dialog';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { Betriebsart, Produktionsabschnitt } from '../../stammdaten/vvvo/classes/betriebsart';

@Component({
    selector: 'kiene-gruppe-anlegen',
    templateUrl: './gruppe-anlegen.component.html',
    styleUrls: ['./gruppe-anlegen.component.scss'],
})
export class GruppeAnlegenComponent implements OnInit, OnDestroy {
    @Input() alsDialog = false;
    @Input() nameVorschlag: string;
    @Input() kunde: Client;
    @Input() einstalldatumAusblenden: boolean;
    @Input() einstalldatum: Date;
    @Output('gruppeAngelegt') gruppeAngelegt = new EventEmitter<Gruppe>();

    @ViewChild('standortAutocomplete')
    standortAutocomplete: KieneAutocompleteComponent;

    readStallKundenportalUrl =
        this.apiBaseUrl + 'gruppe/read_stall_kundenportal.php';

    gruppe = new Gruppe();
    isLoading = false;

    produktionsabschnitte: Produktionsabschnitt[] = [];

    standortCtrl = new UntypedFormControl();
    standortParams = new HttpParams();
    standort: string = null;

    nameCtrl = new UntypedFormControl();
    sgsNummerCtrl = new UntypedFormControl();
    aufstalleinheitJahrCtrl = new UntypedFormControl();
    aufstalleinheitNummerCtrl = new UntypedFormControl();
    produktionsabschnittCtrl = new FormControl<Produktionsabschnitt>(undefined);

    // Schwein
    einstalldatumVonCtrl = new UntypedFormControl();
    einstalldatumBisCtrl = new UntypedFormControl();
    anzahlTiereCtrl = new UntypedFormControl();
    alterEinstallungCtrl = new UntypedFormControl();

    produktionsabschnittSub: Subscription;
    namensVorschlagSubscription: Subscription;
    sgsMeldenCtrl = new FormControl(false);
    sgsEinblenden = false;
    sgsNummerEinblenden = false;

    constructor(
        private api: KieneBackendApiService,
        private messageService: MessageService,
        public localStorageService: LocalStorageService,
        private stammdatenService: StammdatenService,
        private dialog: MatDialog,
        private router: Router,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnDestroy(): void {
        this.namensVorschlagSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        if (!this.kunde) {
            this.kunde = this.stammdatenService.getAusgewaehlteVvvo();
        }

        this.sgsMeldenCtrl.disable();
        if (this.nameVorschlag) {
            this.nameCtrl.setValue(this.nameVorschlag);
        }

        this.standortParams = new HttpParams().set(
            'kunde_id',
            this.kunde?.kunde_id?.toString()
        );

        // intern umstallen
        if (this.einstalldatum) {
            this.einstalldatumVonCtrl.setValue(this.einstalldatum);
            this.einstalldatumBisCtrl.setValue(this.einstalldatum);
        }

        if (this.kunde?.schwein === 1) {
            this.alterEinstallungCtrl = new UntypedFormControl(14);
        }

        this.checkSgsMelden();
        this.loadGruppennamenUndAufstalleinheitVorschlag();
        this.ladeProduktionsabschnitte();
    }

    ladeProduktionsabschnitte() {
        if (this.kunde?.betriebsart_id) {
            this.produktionsabschnittSub?.unsubscribe();
            this.produktionsabschnittSub = this.api.getByIdAlternative(this.apiBaseUrl + 'betriebsart/read_one.php', 'betriebsart_id', this.kunde?.betriebsart_id?.toString()).subscribe({
                next: (betriebsart: Betriebsart) => {
                    this.produktionsabschnitte = betriebsart.produktionsabschnitte;
                    if (this.produktionsabschnitte?.length === 1) {
                        this.produktionsabschnittCtrl.setValue(this.produktionsabschnitte[0]);
                    }
                },
                error: err => {
                    this.messageService.errorMessage('Produktionsabschnitte konnten nicht geladen werden!', err);
                }
            });
        }
    }

    setStandort(standort) {
        if (typeof standort === 'object' && standort?.stall) {
            this.standort = standort.stall;
            if (standort.sgs_nummer) {
                this.sgsNummerCtrl.setValue(standort.sgs_nummer);
            }
        }
        if (typeof standort === 'string' && standort) {
            this.standort = standort;
        }
    }

    deleteStall(gruppe: Gruppe) {
        this.api
            .deleteGeneric(
                this.apiBaseUrl + 'gruppe/delete_stall_kundenportal.php',
                gruppe
            )
            .subscribe({
                next: () => {
                    this.messageService.infoMessage(
                        'Der Standort wurde erfolgreich gelöscht!'
                    );
                    this.standortAutocomplete.clearAndFocus();
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Der Standort konnte nicht gelöscht werden',
                        error
                    );
                },
            });
    }

    loadGruppennamenUndAufstalleinheitVorschlag() {
        const params = new HttpParams().set(
            'kunde_id',
            this.kunde?.kunde_id?.toString()
        );
        this.namensVorschlagSubscription?.unsubscribe();
        this.namensVorschlagSubscription = this.api
            .get(
                this.apiBaseUrl + 'gruppe/read_vorschlag_kundenportal.php',
                params
            )
            .subscribe((response) => {
                if (response) {
                    if (!this.nameVorschlag) {
                        this.nameCtrl.setValue(response.bezeichnung);
                    }
                    this.aufstalleinheitJahrCtrl.setValue(
                        response.aufstalleinheit_jahr
                    );
                    this.aufstalleinheitNummerCtrl.setValue(
                        response.aufstalleinheit_nummer
                    );
                }
            });
    }

    clearCtrls() {
        this.nameCtrl.setValue('');
        this.aufstalleinheitJahrCtrl.setValue('');
        this.aufstalleinheitNummerCtrl.setValue('');
    }

    anlegen() {
        this.isLoading = true;
        this.gruppe.sgs = this.sgsMeldenCtrl.value ? 1 : 0;
        this.gruppe.kunde_id = this.kunde?.kunde_id;
        this.gruppe.produktionsabschnitt_id = this.produktionsabschnittCtrl.value?.produktionsabschnitt_id;
        this.gruppe.stall = this.standort;
        this.gruppe.sgs_nummer = this.sgsNummerCtrl.value;
        this.gruppe.bezeichnung = this.nameCtrl.value;
        this.gruppe.aufstalleinheit_jahr = this.aufstalleinheitJahrCtrl.value;
        this.gruppe.aufstalleinheit_nummer =
            this.aufstalleinheitNummerCtrl.value;

        this.gruppe.einstalldatum_von = this.einstalldatumVonCtrl.value;
        this.gruppe.einstalldatum_bis = this.einstalldatumBisCtrl.value;
        if (this.kunde?.schwein === 1) {
            this.gruppe.alter_bei_einstallung = this.alterEinstallungCtrl.value;
            this.gruppe.anzahl_tiere = this.anzahlTiereCtrl.value;
        }

        this.api
            .post(
                this.apiBaseUrl + 'gruppe/create_kundenportal.php',
                this.gruppe,
                undefined
            )
            .subscribe({
                next: (gruppe: Gruppe) => {
                    this.messageService.infoMessage(
                        'Die Gruppe wurde erfolgreich angelegt!'
                    );

                    this.isLoading = false;
                    if (this.alsDialog) {
                        this.gruppeAngelegt.emit(gruppe);
                    } else {
                        if (this.kunde?.rind > 0) {
                            this.openTierHinzufuegenFrageDialog(
                                gruppe.gruppe_id
                            );
                        } else {
                            this.router
                                .navigate([
                                    '/gruppen/gruppe/',
                                    gruppe.gruppe_id,
                                ])
                                .then();
                        }
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fehler! Die Gruppe wurde nicht angelegt!',
                        err
                    );
                    this.isLoading = false;
                },
            });
    }

    openTierHinzufuegenFrageDialog(gruppeId: number) {
        const input = new ConfirmDialogInput();
        input.headline = 'Tiere hinzufügen';
        input.text = 'Wollen Sie mit dem Einstallen beginnen?';
        input.okButton = 'Einstallen';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (confirm: ConfirmDialogResult) => {
                if (confirm?.confirmed) {
                    this.router
                        .navigate(['/gruppen/tierehinzufuegen', gruppeId])
                        .then();
                } else {
                    this.router.navigate(['/gruppen/gruppe/', gruppeId]).then();
                }
            },
        });
    }

    produktionsabschnittComparator(a: Produktionsabschnitt, b: Produktionsabschnitt): boolean {
        return a?.produktionsabschnitt_id === b?.produktionsabschnitt_id;
    }

    private checkSgsMelden() {
        this.sgsMeldenCtrl.disable();
        this.sgsEinblenden = false;
        this.sgsNummerEinblenden = false;
        if (this.kunde) {
            if (this.kunde.kaelbermast) {
                this.sgsMeldenCtrl.setValue(true);
                this.sgsNummerEinblenden = true;
            } else {
                if (this.kunde.sgs_nummer) {
                    this.sgsMeldenCtrl.setValue(true);
                    this.sgsMeldenCtrl.enable();
                    this.sgsEinblenden = true;
                    this.sgsNummerEinblenden = true;
                } else {
                    this.sgsMeldenCtrl.setValue(false);
                }
            }
        }
    }
}
