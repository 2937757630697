<h1 mat-dialog-title>Tierliste zu Gruppe {{gruppe?.bezeichnung}} hochladen</h1>
<div mat-dialog-content>
    <div style="margin-bottom: 12px;">

        <div class="one-row">
            <div *ngFor="let file of filesToUpload" class="one-row" style="width: 80%;">
                <mat-icon style="color: gray;">description</mat-icon>
                <span style="margin-left: 12px;">{{file?.name}}</span>
                <span class="spacer"></span>
            </div>
            <span class="spacer"></span>
            <button mat-stroked-button color="accent" (click)="openFileSelector()">
                <mat-icon>attach_file</mat-icon>
                Dateien ausw&auml;hlen
            </button>
            <input type="file" style="display: none" #fileSelector (change)="handleFile($event)"
                   accept=".csv, .xls, .xlsx">
        </div>


        <mat-form-field style="width: 100%; margin-top: 12px;">
            <mat-label>Einstalldatum</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="einstalldatumCtrl" required [readonly]="true">

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field style="width: 100%;">
            <mat-label>Viehh&auml;ndler</mat-label>
            <mat-select [formControl]="viehhaendlerCtrl">
                <mat-option *ngFor="let v of viehhaendler" [value]="v">{{v.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field style="width: 100%;">
            <mat-label>Abteil</mat-label>
            <input matInput type="text" [formControl]="abteilCtrl">
        </mat-form-field>

        <div class="one-row">
            <span>Nach dem Hochladen direkt an HIT melden?: *</span>
            <span class="spacer"></span>
            <mat-radio-group [formControl]="hitMeldenCtrl" required>
                <mat-radio-button [value]="1">ja</mat-radio-button>
                <mat-radio-button [value]="0">nein</mat-radio-button>
            </mat-radio-group>
        </div>


    </div>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="uploadFilesToServer()"
            [disabled]="isLoading || filesToUpload?.length === 0 || (!einstalldatumCtrl.value || hitMeldenCtrl.value === undefined)">
        Hochladen
    </button>

</div>
