<h1 mat-dialog-title>Kundennummer nachtragen</h1>
<div mat-dialog-content>

    <div style="padding-bottom: 12px">
        <strong>Entsorger: </strong>
        <span>{{entsorger.name}}</span>
    </div>

    <div style="padding-bottom: 12px">
        <strong>F&uuml;r VVVO: </strong>
        <span>{{vvvo}}</span>
    </div>

    <div style="margin-top: 12px">
        <mat-form-field style="width: 100%">
            <mat-label>Kundennummer</mat-label>
            <input matInput required [formControl]="kundenNrVvvoCtrl">
        </mat-form-field>
    </div>


</div>
<div mat-dialog-actions>
    <button mat-flat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button (click)="speichern()" color="accent"
            [disabled]="!this.kundenNrVvvoCtrl.value && kundenNrVvvoCtrl?.value?.length <= 0">
        Speichern
    </button>
</div>
