<h1 mat-dialog-title>Spalten anzeigen</h1>
<div mat-dialog-content>
    <ul style="list-style-type: none; padding: 0;">
        <ng-container *ngFor="let m of data?.cols;">
            <li *ngIf="m?.aktiv">
                <mat-checkbox [checked]="m?.sichtbar > 0" (change)="change(m, $event)">{{ m?.titel }}</mat-checkbox>
            </li>
        </ng-container>
    </ul>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="onCancelClick()">Abbrechen</button>
    <button mat-flat-button [disabled]="checkDisable()" color="accent" (click)="save()"
            [disabled]="isSaving">Anwenden
    </button>
</div>
