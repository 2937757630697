import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {VetGroup} from './vet-group';
import {MessageService} from '../../services/message.service';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {environment} from '../../../environments/environment';
import {ColorTableColumn, TableColumn} from '../../components/kiene-table/table-column';
import {TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {UpdateVetGroupDialogComponent} from './update-vet-group-dialog/update-vet-group-dialog.component';
import {AddVetGroupDialogComponent} from './add-vet-group-dialog/add-vet-group-dialog.component';
import {VetGroupDetailsDialogComponent} from './vet-group-details-dialog/vet-group-details-dialog.component';

@Component({
    selector: 'kiene-vet-group',
    templateUrl: './vet-group.component.html',
    styleUrls: ['./vet-group.component.scss']
})
export class VetGroupComponent implements OnInit {

    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(public dialog: MatDialog,
                private messageService: MessageService,
                private api: KieneTableService) {
    }

    ngOnInit() {

        this.tableDescriptor.headline = 'Tierarztgruppen';
        this.tableDescriptor.uniqueIdentifier = 'Tierarztgruppen';
        this.tableDescriptor.apiUrl = environment.BASE_URI + 'tierarztgruppe/';
        this.tableDescriptor.searchPlaceholder = 'Tierarztgruppen durchsuchen';
        this.tableDescriptor.columns.push(new TableColumn('bezeichnung', 'Tierarztgruppe'));
        this.tableDescriptor.columns.push(new ColorTableColumn('hintergrundfarbe', 'Farbe'));

        // this.tableDescriptor.columns.push(new TableColumn('options', ''));
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.showDetailsAsOption = true;

        this.tableDescriptor.permissionCreate = 1262;
        this.tableDescriptor.permissionView = 1261;
        this.tableDescriptor.permissionUpdate = 1263;
        this.tableDescriptor.permissionDelete = 1264;

        this.tableDescriptor.options.push(new TableOption('delete', 'löschen', 'delete', 1264));

        this.tableDescriptor.initialSortColumn = 'bezeichnung';
        this.tableDescriptor.initialSortDirection = 'asc';

    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            this.openDetailsDialog();
        } else if (event.name === 'details') {
            this.openDetailsDialog(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteConfirmDialog(event.value);
        }
    }


    openAddVetGroupDialog() {
        const dialogRef = this.dialog.open(AddVetGroupDialogComponent, {
            width: '700px'
        });
        dialogRef.afterClosed().subscribe(response => {
            const vetGroup: VetGroup = response;
            this.api.post(environment.BASE_URI + 'tierarztgruppe/create.php', vetGroup).subscribe(createdVetGroup => {
                this.messageService.infoMessage('Tierarztgruppe ' + createdVetGroup.bezeichnung + ' wurde erfolgreich angelegt!');
                this.api.reload();
            }, error => {
                this.messageService.errorMessage('Die Tierarztgruppe ' + vetGroup.bezeichnung + ' konnte nicht angelegt werden! Fehler: ', error);
            });

        }, error => {

        });
    }

    openEditVetGroupDialog(vetGroup: VetGroup) {
        const dialogRef = this.dialog.open(UpdateVetGroupDialogComponent, {
            width: '700px',
            data: {vetGroup: vetGroup}
        });
        dialogRef.afterClosed().subscribe(vetGroupEdit => {
            if (vetGroupEdit) {
                this.api.updateEntity(environment.BASE_URI + 'tierarztgruppe/update.php', vetGroupEdit).subscribe(response => {
                    this.messageService.infoMessage('Tierarztgruppe ' + response.bezeichnung + ' wurde erfolgreich editiert!');
                    this.api.reload();
                }, error => {
                    this.messageService.errorMessage('Tierarztgruppe konnte nicht editiert werden! Fehler: ', error);
                });


            }
        });
    }

    openDeleteConfirmDialog(vetGroup: VetGroup) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '350px',
            data: {
                title: 'Tierarztgruppe löschen',
                text: 'Wollen Sie die Tierarztgruppe ' + vetGroup.bezeichnung + ' wirklich löschen?',
                textButton: 'Löschen'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.deleteVetGroup(vetGroup);
            }
        });
    }

    deleteVetGroup(vetGroup: VetGroup) {
        this.api.deleteEntity(environment.BASE_URI + 'tierarztgruppe/delete.php', vetGroup).subscribe(response => {
            this.messageService.infoMessage('Tierarztgruppe ' + vetGroup.bezeichnung + ' erfolgreich entfernt!');
            this.api.reload();
        }, error => {
            console.error(error);
            this.messageService.errorMessage('Löschen von Tierarztgruppe ' + vetGroup.bezeichnung + ' ist fehlgeschlagen!', error);
        });
    }

    private openDetailsDialog(vetgroup?: VetGroup) {
        if (!vetgroup) {
            vetgroup = new VetGroup();
        }
        const dialogRef = this.dialog.open(VetGroupDetailsDialogComponent, {
            data: vetgroup
        });
        dialogRef.afterClosed().subscribe({
            next: result => {
                if (result) {
                    this.api.reload();
                }
            }
        });
    }
}

