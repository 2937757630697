import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";
import { UntypedFormControl } from "@angular/forms";
import { KundenSettingsService } from "../../profile-settings/kunden-settings/kunden-settings.service";
import { MessageService } from "../../services/message.service";
import { EditHaendlerDialogComponent } from "../../profile-settings/kunden-settings/edit-haendler-dialog/edit-haendler-dialog.component";
import { KieneAutocompleteComponent } from "../../components/kiene-autocomplete/kiene-autocomplete.component";
import { GruppeTierService } from "../../../../projects/kiene-core/src/lib/components/gruppe-tier/gruppe-tier.service";
import { GruppeTier } from "../../../../projects/kiene-core/src/lib/components/gruppe-tier/classes/gruppe-tier";
import { Haendler } from '../../../../projects/kiene-core/src/lib/components/stammdaten/haendler/haendler';
import { Tierbemerkung } from '../../../../projects/kiene-core/src/lib/components/gruppe-tier/classes/tierbemerkung';

@Component({
    selector: "kiene-tier-editieren-dialog",
    templateUrl: "./tier-editieren-dialog.component.html",
    styleUrls: ["./tier-editieren-dialog.component.scss"],
})
export class TierEditierenDialogComponent implements OnInit {
    tier: GruppeTier;
    emptyListHaendler = false;
    haendlerFavoriten: Haendler[] = [];
    bemerkungen: Tierbemerkung[] = [];

    @ViewChild("bemerkungenAutocomplete")
    bemerkungenAutocomplete: KieneAutocompleteComponent;
    abteilCtrl: UntypedFormControl = new UntypedFormControl();
    haendlerCtrl: UntypedFormControl = new UntypedFormControl();

    constructor(
        public dialogRef: MatDialogRef<TierEditierenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private gruppeTierService: GruppeTierService,
        private kundenSettingsService: KundenSettingsService,
        private messageService: MessageService,
        private dialog: MatDialog
    ) {
        if (data.tier) {
            this.tier = data.tier;
            this.abteilCtrl.setValue(this.tier?.abteil);
            this.ladeHaendlerFavoriten();
        }
    }

    ngOnInit(): void { }

    compareHaendler(h1: Haendler, h2: Haendler) {
        return h1 && h2 && h1.viehhaendler_id === h2.viehhaendler_id;
    }

    updateGruppeTier() {
        this.tier.viehhaendler_id = this.haendlerCtrl.value?.viehhaendler_id;
        this.tier.abteil = this.abteilCtrl.value;
        this.gruppeTierService.update(this.tier).subscribe({
            next: (resp) => {
                this.tier = resp;
                this.messageService.infoMessage(
                    "Tierdaten wurden erfolgreich aktualisiert."
                );
                this.dialogRef.close(this.tier);
            },
            error: (err) => {
                this.messageService.errorMessage(
                    "Fehler beim Speichern des Tieres!",
                    err
                );
            },
        });
    }

    openHaendlerVerwaltung() {
        const dialogRef = this.dialog.open(EditHaendlerDialogComponent, {
            width: "50vw",
        });
        dialogRef.afterClosed().subscribe({
            next: (resp) => {
                if (resp) {
                    this.kundenSettingsService
                        .addOrUpdateHaendler(resp)
                        .subscribe({
                            next: () => {
                                this.ladeHaendlerFavoriten();
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    "Fehler beim Speichern des Händlers !",
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    ladeHaendlerFavoriten() {
        this.kundenSettingsService
            .loadHaendlerFavoriten()
            .subscribe((haendlerFavoriten) => {
                this.haendlerFavoriten = haendlerFavoriten.records;

                if (this.tier.viehhaendler_id) {
                    this.haendlerCtrl.setValue(
                        this.haendlerFavoriten.find(
                            (h) =>
                                h.viehhaendler_id === this.tier.viehhaendler_id
                        )
                    );
                }

                if (this.haendlerFavoriten.length === 0) {
                    this.emptyListHaendler = true;
                }
            });
    }

    addBemerkung(bemerkung: Tierbemerkung | string): void {
        let toAdd: Tierbemerkung;
        if (typeof bemerkung === "string") {
            toAdd = new Tierbemerkung();
            toAdd.bemerkung = bemerkung;
        } else {
            toAdd = bemerkung;
        }
        if (!this.bemerkungen.find((b) => b.bemerkung === toAdd.bemerkung)) {
            this.bemerkungen.push(toAdd);
        }
    }

    add() {
        this.bemerkungenAutocomplete.emitCurrentValue();
    }

    deleteBemerkung(bemerkung: Tierbemerkung) {
        let index;
        if (bemerkung.tierbemerkung_id) {
            index = this.bemerkungen.findIndex(
                (b) => b?.tierbemerkung_id === bemerkung?.tierbemerkung_id
            );
        } else {
            index = this.bemerkungen.findIndex(
                (b) => b?.bemerkung === bemerkung?.bemerkung
            );
        }
        if (index) {
            this.bemerkungen.splice(index, 1);
        }
    }
}
