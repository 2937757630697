<h1 mat-dialog-title> Inventur erstellen </h1>
<div mat-dialog-content>
    <mat-form-field style=" width: 100%;">
        <mat-label>Name</mat-label>
        <input [formControl]="nameCtrl" matInput aria-label="Name" required>
    </mat-form-field>
    <mat-form-field style=" width: 100%">

    </mat-form-field>
    <mat-slide-toggle (change)="onInputChange($event)"> Sofort auf aktiv setzen</mat-slide-toggle>
    <div *ngIf="this.createdInventur.aktiv" style="color: #7d7d7d; padding: 5%">
        Hinweis: Sollte bereits eine andere Inventur aktiv sein, wird diese deaktiviert.
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="close()">Abbrechen</button>
    <button [disabled]="this.yearCtrl.invalid" mat-flat-button (click)="save()" color="accent">Speichern</button>
</div>
