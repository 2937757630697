import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Gruppe} from '../../gruppen/classes/gruppe';

@Component({
  selector: 'kiene-gruppe-tier-pass-scan-verwaltung-dialog',
  templateUrl: './gruppe-tier-pass-scan-verwaltung-dialog.component.html',
  styleUrls: ['./gruppe-tier-pass-scan-verwaltung-dialog.component.scss']
})
export class GruppeTierPassScanVerwaltungDialogComponent implements OnInit {

    gruppe: Gruppe;

    constructor(
        public dialogRef: MatDialogRef<GruppeTierPassScanVerwaltungDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Gruppe) {
        this.gruppe = data;

    }

  ngOnInit(): void {
  }

}
