import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Material } from './material';
import { Router } from '@angular/router';
import { TableDescriptor, ToolbarButton, } from '../../components/kiene-table/table-descriptor';
import { MessageService } from '../../services/message.service';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { environment } from '../../../environments/environment';
import { TableColumn } from '../../components/kiene-table/table-column';
import { Condition, Operator, TableOption, TableOptionEvent, } from '../../components/kiene-table/table-option';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { HttpParams } from '@angular/common/http';
import { SonderpreisDialogComponent } from '../../warehouse/material/sonderpreis-dialog/sonderpreis-dialog.component';
import { KieneFileService } from '../../../../projects/kiene-core/src/lib/services/files/kiene-file.service';
import {
    FilterItem,
    FilterItemType
} from '../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';

@Component({
    selector: 'app-material',
    templateUrl: './material.component.html',
    styleUrls: ['./material.component.scss'],
})
export class MaterialComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private messageService: MessageService,
        private api: KieneTableService,
        private router: Router,
        private fileService: KieneFileService
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Artikel';
        this.tableDescriptor.uniqueIdentifier = 'Artikel';
        this.tableDescriptor.apiUrl = environment.BASE_URI + 'artikel/';
        this.tableDescriptor.route = '/lager/artikel/';
        this.tableDescriptor.nameOfIdField = 'artikel_id';
        this.tableDescriptor.searchPlaceholder = 'Artikel durchsuchen';

        this.tableDescriptor.columns.push(new TableColumn('artikel_id', 'ID'));
        this.tableDescriptor.columns.push(
            new TableColumn('artikel_nr_intern', 'Artikel Nr.')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('bezeichnung', 'Bezeichnung')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('einheit', 'Einheit')
        );
        this.tableDescriptor.columns.push(new TableColumn('ean', 'EAN'));
        this.tableDescriptor.columns.push(new TableColumn('kuerzel', 'Kürzel'));
        this.tableDescriptor.columns.push(new TableColumn('lieferant', 'Lieferant'));
        this.tableDescriptor.columns.push(
            new TableColumn('warengruppe', 'Warengruppe')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('editiert', 'Editiert', 'datetime')
        );
        this.tableDescriptor.standardCreate = false;

        this.tableDescriptor.disableConditions.push(
            new Condition('gesperrt', Operator.GROESSER, 0)
        );

        this.tableDescriptor.permissionCreate = 1002;
        this.tableDescriptor.permissionView = 1001;
        this.tableDescriptor.permissionUpdate = 1003;
        this.tableDescriptor.permissionDelete = 1004;

        this.tableDescriptor.options.push(
            new TableOption('edit', 'bearbeiten', 'edit', 1003)
        );
        this.tableDescriptor.options.push(
            new TableOption('delete', 'löschen', 'delete', 1004)
        );
        this.tableDescriptor.options.push(
            new TableOption('duplicate', 'duplizieren', 'content_copy', 1002)
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'sonderpreise_anzeigen',
                'Sonderpreise anzeigen',
                'local_offer',
                1002
            )
        );

        this.tableDescriptor.toolbarButtons.push(
            new ToolbarButton(
                'preislisteDownload',
                'euro',
                1009,
                'Preisliste herunterladen'
            )
        );

        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Antibiotikum',
                1,
                FilterItemType.CHECKBOX,
                'antibiotikum',
                false
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Behandlungsartikel',
                1,
                FilterItemType.CHECKBOX,
                'behandlung',
                false
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Abgabeartikel',
                1,
                FilterItemType.CHECKBOX,
                'abgabe',
                false
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Impfstoff',
                1,
                FilterItemType.CHECKBOX,
                'impfung',
                false
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Mengenartikel',
                1,
                FilterItemType.CHECKBOX,
                'mengenartikel',
                false
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Betäubungsartikel',
                1,
                FilterItemType.CHECKBOX,
                'betaeubungsmittel',
                false
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Gesperrte Artikel',
                1,
                FilterItemType.CHECKBOX,
                'gesperrt',
                false
            )
        );

        this.tableDescriptor.initialSortColumn = 'bezeichnung';
        this.tableDescriptor.initialSortDirection = 'asc';
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            this.router.navigate(['lager', 'artikel', 'neu']);
        } else if (event.name === 'edit') {
            this.router.navigate(['lager', 'artikel', event.value.artikel_id]);
        } else if (event.name === 'delete') {
            const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
                width: '500px',
                data: {
                    text: 'Wollen Sie den Artikel wirklich löschen?',
                    title: 'Artikel löschen?',
                },
            });

            dialogRef.afterClosed().subscribe((response) => {
                if (response) {
                    this.deleteArtikel(event.value);
                }
            });
        } else if (event.name === 'duplicate') {
            this.duplicate(event.value);
        } else if (event.name === 'preislisteDownload') {
            this.preislisteDownload();
        } else if (event.name === 'sonderpreise_anzeigen') {
            this.openSondpreisDialog(event.value);
        }
    }

    preislisteDownload() {
        const params = new HttpParams();
        this.fileService.downloadCSV(
            environment.BASE_URI + 'artikel/read_preisliste_csv.php',
            params
        );
    }

    duplicate(artikel: Material) {
        this.api
            .duplicateEntity(environment.BASE_URI + 'artikel/duplicate.php', artikel)
            .subscribe(
                (response) => {
                    this.messageService.infoMessage(
                        'Der Artikel wurde erfolgreich dupliziert!'
                    );
                    this.router.navigate([
                        'lager',
                        'artikel',
                        response.artikel_id,
                    ]);
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Der Artikel konnte nicht dupliziert werden!',
                        error
                    );
                }
            );
    }

    deleteArtikel(artikel: Material) {
        this.api.deleteEntity(environment.BASE_URI + 'artikel/delete.php', artikel).subscribe(
            (response) => {
                this.messageService.infoMessage(
                    'Der Artikel wurde erfolgreich gelöscht!'
                );
                this.api.reload();
            },
            (error) => {
                this.messageService.errorMessage(
                    'Es ist ein Fehler beim Löschen aufgetreten! ',
                    error
                );
            }
        );
    }

    private openSondpreisDialog(artikel: Material) {
        const dialogRef = this.dialog.open(SonderpreisDialogComponent, {
            data: artikel,
        });
    }
}
