import {Component, Inject, OnInit} from '@angular/core';
import {ClientService} from '../../client/client.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {UntypedFormControl} from '@angular/forms';
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-add-client-group-to-company-group-dialog',
    templateUrl: './add-client-group-to-company-group-dialog.component.html',
    styleUrls: ['./add-client-group-to-company-group-dialog.component.scss']
})
export class AddClientGroupToCompanyGroupDialogComponent implements OnInit {

    companyGroupId: number;
    clients: Client[] = [];
    clientSelectCtrl: UntypedFormControl = new UntypedFormControl();

    constructor(private api: ClientService,
                public dialogRef: MatDialogRef<AddClientGroupToCompanyGroupDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private router: Router) {
        this.companyGroupId = data.companyGroupId;
    }

    ngOnInit() {
        this.loadClients();
    }

    loadClients() {
        this.api.getClientsByCompanyGroup(this.companyGroupId).subscribe(response => {
            this.clients = response.records;
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    choose() {
        this.router.navigate(['/stammdaten/gruppen/neu'], {
            queryParams: {
                kunde_id: this.clientSelectCtrl.value.kunde_id
            }
        });
        this.dialogRef.close();
    }

}
