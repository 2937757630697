<h1 *ngIf="articleList.artikelliste_id" mat-dialog-title>
    Artikelliste "{{ this.articleList.listenname }}"
</h1>
<h1 *ngIf="!articleList.artikelliste_id" mat-dialog-title>
    Neue Artikelliste erstellen
</h1>

<div mat-dialog-content>
    <form *ngIf="artikellistenForm" [formGroup]="artikellistenForm">
        <div class="one-row header">

            <mat-form-field style="width:20%">
                <mat-label>Listentitel</mat-label>
                <input formControlName="listenTitelCtrl" matInput/>
            </mat-form-field>

            <span class="spacer"></span>

            <button (click)="savePosition()" [disabled]="artikellistenForm.invalid" class="add-button" color="accent"
                    mat-stroked-button>
                <mat-icon>add</mat-icon>
                <span *ngIf="listenArtikel?.aa_id">Änderungen übernehmen</span>
                <span *ngIf="!listenArtikel?.aa_id">Position hinzufügen</span>
            </button>

        </div>

        <div class="selektion-reihe">

            <kiene-article-scan-autocomplete #articleAutocomplete (cleared)="materialInputCleared()"
                                             (selectedMaterial)="setSelectedMaterial($event)"
                                             [gesperrtAusblenden]="true"
                                             [placeholder]="'Artikel eingeben'" [required]="true" [stuecklisten]="false"
                                             class="autocomplete selektoren15">
            </kiene-article-scan-autocomplete>

            <span class="spacer"></span>
            <kiene-tierart-selector #tierartSelector (tierartSelectionChanged)="setSelectedTierart($event)"
                                    [disabled]="istMengenArtikel()" [kundeMandantId]="mandant.mandant_id"
                                    [required]="true"
                                    [zeigeKeine]="false" class="selektoren15">
            </kiene-tierart-selector>

            <span class="spacer"></span>
            <kiene-anwendungs-selector #anwendungSelector (change)="setSelectedAnwendung($event)"
                                       [disabled]="istMengenArtikel()" [kundeMandantId]="mandant.mandant_id"
                                       [required]="true"
                                       class="selektoren15">
            </kiene-anwendungs-selector>

            <span class="spacer"></span>
            <kiene-diagnose-selector #diagnoseSelector (change)="setSelectedDiagnose($event)"
                                     [disabled]="istMengenArtikel()" [kundeMandantId]="mandant.mandant_id"
                                     [required]="true"
                                     class="selektoren15">
            </kiene-diagnose-selector>

            <span class="spacer"></span>

            <mat-form-field class="inputs10">
                <mat-label>Behandlung:</mat-label>
                <mat-select #behandlungJaNein (blur)="blurBehandlung()"
                            formControlName="behandlungJaNeinCtrl">
                    <mat-option [value]="1">Ja</mat-option>
                    <mat-option [value]="0">Nein</mat-option>
                </mat-select>
            </mat-form-field>

            <span class="spacer"></span>

            <mat-form-field class="inputs10">
                <mat-label>Anwendungsdauer</mat-label>
                <input (change)="updateAnwendungsdauer()" formControlName="anwendungsdauerCtrl" matInput
                       type="number"/>
            </mat-form-field>

            <span class="spacer"></span>

            <mat-form-field class="inputs10">
                <mat-label>{{
                        listenArtikel?.dosierung_txt ? listenArtikel.dosierung_txt : "Dosierung"
                    }}
                </mat-label>
                <input (change)="updateDosierung()" formControlName="dosierungCtrl" matInput type="number"
                    (blur)="savePosition()" />
                <mat-hint *ngIf="listenArtikel?.koerpergewicht_tierart">pro {{ listenArtikel.koerpergewicht_tierart }}kg
                    Körpergewicht
                </mat-hint>
            </mat-form-field>

        </div>

        <div>

            <table class="phito-table">
                <thead>
                <tr>
                    <th>Position</th>
                    <th>Artikel</th>
                    <th>Tierart</th>
                    <th>Anwendung</th>
                    <th>Diagnose</th>
                    <th>Behandlung</th>
                    <th>Anwendungsdauer</th>
                    <th>Dosierung</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let a of articleList?.artikel; let i=index;" (click)="editSelectedElement(a)"
                    style="cursor: pointer;">
                    <td>{{ (i + 1) }}</td>
                    <td>{{ a?.artikel_me }}</td>
                    <td>{{ a?.tierart }}</td>
                    <td>{{ a?.anwendung }}</td>
                    <td>{{ a?.diagnose }}</td>
                    <td>{{ a?.behandlung === 1 ? 'ja' : 'nein' }}</td>
                    <td>{{ a?.anwendung }}</td>
                    <td>{{ a?.dosierung }}</td>
                    <td>
                        <button mat-icon-button [matMenuTriggerFor]="positionMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #positionMenu>
                            <button mat-menu-item (click)="$event.stopPropagation();editSelectedElement(a)">
                                <mat-icon>edit</mat-icon>
                                <span>bearbeiten</span>
                            </button>
                            <button mat-menu-item (click)="$event.stopPropagation();deleteListenartikel(a)">
                                <mat-icon>delete</mat-icon>
                                <span>l&ouml;schen</span>
                            </button>
                        </mat-menu>
                    </td>
                </tr>
                </tbody>
            </table>

            <!--            <kiene-kiene-mini-table (deleteItem)="deleteListenartikel($event)"-->
            <!--                                    (selectedElement)="editSelectedElement($event)"-->
            <!--                                    [initMiniTable]="initMiniTable"></kiene-kiene-mini-table>-->
        </div>
    </form>
</div>
<div align="end" class="footer" mat-dialog-actions>
    <button (click)="dialogRef.close()" mat-button>Abbrechen</button>
    <button (click)="save(true)" color="accent" mat-flat-button>Speichern
    </button>
</div>
