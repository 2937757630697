import { HttpParams } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { Client, ClientApi } from './classes/client';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL_SERVICE } from '../../kiene-core.config';
import { KieneBackendApiService } from '../../services/backend-api/kiene-backend-api.service';

@Injectable()
export class ClientService {
    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    getAllClients(
        sortColumn: string,
        sortDirection: string,
        offset: number,
        limit: number,
        searchString: string,
        showNewClients: boolean
    ): Observable<ClientApi> {
        let params = new HttpParams();

        if (sortColumn) {
            let columnAndDirection = sortColumn + ' asc';
            if (sortDirection) {
                columnAndDirection = sortColumn + ' ' + sortDirection;
            }

            params = params.append('orderby', columnAndDirection);
        }

        if (offset) {
            params = params.append('offset', offset.toString());
        }

        if (limit) {
            params = params.append('limit', limit.toString());
        }

        if (searchString) {
            params = params.append('search', searchString);
        }

        if (showNewClients) {
            params = params.append('neukunde', '1');
        }

        return this.api.get(
            this.apiBaseUrl + 'kunde/read.php',
            params
        );
    }

    pruefeZugriff(kunde_id_a: number, kunde_id_b: number): Observable<boolean> {
        if (!kunde_id_a || !kunde_id_b) {
            return of(false);
        }

        const sub = new Subject<boolean>();
        const params = new HttpParams().set('kunde_id_a', kunde_id_a).set('kunde_id_b', kunde_id_b);
        this.api.get(this.apiBaseUrl + 'benutzer_kunde/pruefe_zugriff.php', params).subscribe({
            next: (response) => {
                if (response.zugriff > 0) {
                    sub.next(true);
                } else {
                    sub.next(false);
                }
            },
            error: err => {
                sub.error(err);
            }
        });

        return sub;
    }

    getClientById(id: number): Observable<Client> {
        return this.api.get(
            this.apiBaseUrl + 'kunde/read_one.php?kunde_id=' + id
        );
    }

}
