import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GruppenuebersichtComponent } from './gruppenuebersicht/gruppenuebersicht.component';
import { MatTabsModule } from '@angular/material/tabs';
import { GruppenAktuelleComponent } from './gruppenuebersicht/gruppen-aktuelle/gruppen-aktuelle.component';
import { GruppenHistorieComponent } from './gruppenuebersicht/gruppen-historie/gruppen-historie.component';
import { KieneTableModule } from '../../components/kiene-table/kiene-table.module';
import { GruppeDialogComponent } from './gruppenuebersicht/gruppe-dialog/gruppe-dialog.component';
import { KieneAutocompleteModule } from '../../components/kiene-autocomplete/kiene-autocomplete.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { GruppeDetailsDialogComponent } from './gruppe-details-dialog/gruppe-details-dialog.component';
import { GruppeDetailsComponent } from './gruppe-details/gruppe-details.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GruppeEditDialogComponent } from './gruppenuebersicht/gruppe-edit-dialog/gruppe-edit-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { SelectorModule } from '../../components/selector/selector.module';
import { OhrmarkeModule } from '../../../../projects/kiene-core/src/lib/ohrmarke/ohrmarke.module';

@NgModule({
  declarations: [GruppenuebersichtComponent, GruppenAktuelleComponent, GruppenHistorieComponent, GruppeDialogComponent, GruppeDetailsDialogComponent, GruppeDetailsComponent, GruppeEditDialogComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    KieneTableModule,
    KieneAutocompleteModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatRadioModule,
    MatDialogModule,
    MatPaginatorModule,
    MatMenuModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatSelectModule,
    MatBadgeModule,
    SelectorModule,
    OhrmarkeModule
  ],
  exports: [
    GruppeDialogComponent,
    GruppeDetailsComponent,
    GruppeDetailsDialogComponent,
    GruppenuebersichtComponent,
    GruppenAktuelleComponent
  ]
})
export class GruppenverwaltungModule { }
