import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Inventur} from '../../../warehouse/inventur/entities/Inventur';
import {UntypedFormControl, Validators} from '@angular/forms';

@Component({
    selector: 'kiene-inventur-edit-dialog',
    templateUrl: './inventur-edit-dialog.component.html',
    styleUrls: ['./inventur-edit-dialog.component.scss']
})
export class InventurEditDialogComponent implements OnInit {

    yearMin = new Date().getFullYear() - 10;
    inventur: Inventur;
    nameCtrl: UntypedFormControl = new UntypedFormControl(this.data.inventur.name, [Validators.required]);

    constructor(public dialogRef: MatDialogRef<InventurEditDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data) {
        this.inventur = this.data.inventur;
    }

    ngOnInit(): void {
        this.nameCtrl.valueChanges.subscribe(value => this.inventur.name = value);
    }

    close() {
        this.dialogRef.close(null);
    }

    save() {
        this.dialogRef.close(this.inventur);
    }

}
