import {Component, Inject, OnInit} from '@angular/core';
import {TableDescriptor, ToolbarButton} from '../../components/kiene-table/table-descriptor';
import {MatDialog} from '@angular/material/dialog';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {MessageService} from '../../services/message.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {FileUploadService} from '../../dialogs/file-upload-dialog/file-upload.service';
import {KieneBooleanTableColumn, NumberTableColumn, TableColumn,} from '../../components/kiene-table/table-column';
import {Condition, Operator, TableOption, TableOptionEvent,} from '../../components/kiene-table/table-option';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {Gutschrift} from './gutschrift';
import {AddGutschriftDialogComponent} from './add-gutschrift-dialog/add-gutschrift-dialog.component';
import {HttpClient, HttpParams} from '@angular/common/http';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {
    ConfirmDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    FilterItem,
    FilterItemType
} from '../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import {Router} from '@angular/router';

@Component({
    selector: 'kiene-gutschriften',
    templateUrl: './gutschriften.component.html',
    styleUrls: ['./gutschriften.component.scss'],
})
export class GutschriftenComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private api: KieneTableService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private pdfService: PdfViewerDesktopService,
        private fileUploadService: FileUploadService,
        private http: HttpClient,
        private router: Router,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Gutschriften';
        this.tableDescriptor.uniqueIdentifier = 'Gutschriften';
        // this.tableDescriptor.apiUrl = environment.BASE_URI + 'gutschrift/';
        this.tableDescriptor.alternativeApiUrl =
            this.apiBaseUrl + 'gutschrift/read_lieferanten.php';
        this.tableDescriptor.route = '/wawi/gutschrift/';
        this.tableDescriptor.nameOfIdField = 'gutschrift_id';
        this.tableDescriptor.searchPlaceholder = 'Gutschriften durchsuchen';
        this.tableDescriptor.columns.push(
            new TableColumn('gutschrift_id', 'ID')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('lieferant', 'Lieferant')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('artikel', 'Artikel')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('gutschrift_nr', 'Gutschrift Nr.')
        );
        this.tableDescriptor.columns.push(
            new NumberTableColumn('betrag', 'Betrag (netto)', '1.2-2')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('mwstsatz', 'MWST (%)', 'number')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('datum', 'Datum Gutschrift', 'date')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('erstellt', 'Erstellt Am', 'datetime')
        );

        this.tableDescriptor.columns.push(
            new KieneBooleanTableColumn('primanota_id', 'Primanota', {tooltipFieldname: 'primanota_id'})
        );
        this.tableDescriptor.columns.push(
            new KieneBooleanTableColumn('gutschrift_id_storno', 'Storniert')
        );
        this.tableDescriptor.columns.push(
            new KieneBooleanTableColumn('gutschrift_id_storniert', 'Stornobuchung')
        );

        if (this.localStorageService.currentUserHasPermissions([4105])) {
            this.tableDescriptor.columns.push(
                new TableColumn('pdf_id', '', 'pdf')
            );
        }

        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;
        this.tableDescriptor.showDetailsAsOption = true;

        this.tableDescriptor.permissionView = 4101;
        this.tableDescriptor.permissionCreate = 4102;
        this.tableDescriptor.permissionUpdate = 4102;
        this.tableDescriptor.permissionDelete = 4104;

        this.tableDescriptor.toolbarButtons.push(new ToolbarButton('generatePrimanota', 'account_balance', 1832, 'Primanota erstellen'));

        this.tableDescriptor.options.push(
            new TableOption('edit', 'editieren', 'edit', 4102, {
                conditions: [
                    new Condition('primanota_id', Operator.EXISTIERT_NICHT, null),
                    new Condition('gutschrift_id_storno', Operator.EXISTIERT_NICHT, null),
                    new Condition('gutschrift_id_storniert', Operator.EXISTIERT_NICHT, null)
                ]
            })
        );
        this.tableDescriptor.options.push(
            new TableOption('storno', 'stornieren', 'cancel', 4106, {conditions: [new Condition('primanota_id', Operator.EXISTIERT, null), new Condition('gutschrift_id_storno', Operator.EXISTIERT_NICHT, null)]})
        );
        this.tableDescriptor.options.push(
            new TableOption('delete', 'löschen', 'delete', 4104, {
                conditions: [
                    new Condition('primanota_id', Operator.EXISTIERT_NICHT, null),
                ]
            })
        );

        this.tableDescriptor.filterItems.push(new FilterItem('Ohne Primanota', null, FilterItemType.CHECKBOX, 'primanota_id'));

        this.tableDescriptor.initialSortColumn = 'erstellt';
        this.tableDescriptor.initialSortDirection = 'desc';

        this.tableDescriptor.useFileDropEvent = true;

        this.fileUploadService.watchFileUploaded().subscribe((result) => {
            if (result) {
                this.api.reload();
            }
        });
    }

    catchOptionEvent(event: TableOptionEvent) {
        const g: Gutschrift = event.value;
        if (event.name === 'details') {
            this.openAddGutschriftDialog(event.value);
        } else if (event.name === 'showPdf') {
            const input = new PdfViewerDialogInput(
                'gutschrift/read_pdf.php',
                new HttpParams().set(
                    'gutschrift_id',
                    g?.gutschrift_id?.toString()
                ),
                'Gutschrift von ' + g.lieferant
            );
            this.pdfService.openPdfViewer(input);
        } else if (event.name === 'add') {
            this.openAddGutschriftDialog();
        } else if (event.name === 'edit') {
            this.openAddGutschriftDialog(g);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        } else if (event.name === 'storno') {
            this.stornieren(g);
        } else if (event.name === 'generatePrimanota') {
            this.generatePrimanota();
        }
    }

    generatePrimanota() {
        const input = new ConfirmDialogInput();
        input.headline = 'Primanota erstellen';
        input.text = 'Soll die Primanota für alle offenen Positionen im Reiter "Gutschriften" erstellt werden?';
        input.okButton = 'Erstellen';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.http
                        .post(this.apiBaseUrl + 'primanota/generieren_lieferanten.php', {gutschriften: 1})
                        .subscribe({
                            next: response => {
                                this.messageService.infoMessage(
                                    'Die Primanota wurde erfolgreich generiert!'
                                );
                                this.router.navigate(['/finanzen', 'primanota']);
                            },
                            error: err => {
                                this.messageService.errorMessage(
                                    'Es ist ein Fehler beim Generieren der Primanota aufgetreten!',
                                    err
                                );
                            }
                        });
                }
            }
        });
    }

    openAddGutschriftDialog(g?: Gutschrift) {
        if (!g?.primanota_id && !g?.gutschrift_id_storniert && !g?.gutschrift_id_storno) {
            const dialogRef = this.dialog.open(AddGutschriftDialogComponent, {
                width: '500px',
                data: {
                    gutschrift: g,
                    apiUrl: 'gutschrift/update.php',
                },
            });

            dialogRef.afterClosed().subscribe((result) => {
                this.api.reload();
            });
        }
    }

    stornieren(g: Gutschrift) {
        const input = new ConfirmDialogInput();
        input.text = 'Soll diese Gutschrift wirklich storniert werden?';
        input.headline = 'Stornieren';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: input
        });

        dialogRef.afterClosed().subscribe({
            next: (response: ConfirmDialogResult) => {
                if (response?.ok && response?.confirmed) {
                    this.api.post(this.apiBaseUrl + 'gutschrift/stornieren.php', g).subscribe({
                        next: response => {
                            this.messageService.infoMessage('Gutschrift erfolgreich storniert!');
                            this.api.reload();
                        },
                        error: err => {
                            this.messageService.errorMessage('Gutschrift konnte nicht storniert werden!', err);
                        }
                    });
                }
            }
        });
    }

    openDeleteDialog(g: Gutschrift) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Gutschrift wirklich löschen?',
                text: 'Gutschrift von ' + g.lieferant + ' wirklich löschen?',
                textButton: 'löschen',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.api
                    .deleteEntity(this.apiBaseUrl + 'gutschrift/delete.php', g)
                    .subscribe(
                        (result) => {
                            this.messageService.infoMessage(
                                'Die Gutschrift wurde erfolgreich gelöscht!'
                            );
                            this.api.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Die Gutschrift konnte nicht gelöscht werden! ',
                                error
                            );
                        }
                    );
            }
        });
    }
}
