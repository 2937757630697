import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Mandant} from '../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'kiene-select-mandant-dialog',
    templateUrl: './select-mandant-dialog.component.html',
    styleUrls: ['./select-mandant-dialog.component.scss'],
    host: {'(window:keydown)': 'checkForNumberKey($event)'},
})
export class SelectMandantDialogComponent implements OnInit {
    mandant: Mandant;
    mandanten: Mandant[] = [];
    mandantCtrl = new FormControl();
    title = 'Bitte Mandanten auswählen';

    constructor(
        public dialogRef: MatDialogRef<SelectMandantDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.mandanten = data.mandanten;
        if (this.data.title) {
            this.title = this.data.title;
        }
    }

    ngOnInit() {
    }

    checkForNumberKey(event) {
        const numberOfAvailableMandanten = this.mandanten.length;

        const numberPressed = String.fromCharCode(event.which);

        for (let i = 0; i < numberOfAvailableMandanten; i++) {
            if (i + 1 === parseInt(numberPressed, 10)) {
                this.dialogRef.close(this.mandanten[i]);
            }
        }
    }

    setMandant(m: Mandant) {
        this.dialogRef.close(m);
    }

    closeAndSafe() {
        if (this.mandantCtrl.value != null) {
            this.dialogRef.close(this.mandantCtrl.value);
        } else {
            this.dialogRef.close(null);
        }
    }

    onCancelClick(): void {
        this.dialogRef.close(null);
    }

}
