import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TableDescriptor } from '../../../components/kiene-table/table-descriptor';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '../../../services/message.service';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { TabinfoService } from '../../../components/tabinfo/tabinfo.service';
import { environment } from '../../../../environments/environment';
import { EmailGesendetTableColumn, GedrucktTableColumn, KieneBooleanTableColumn, TableColumn } from '../../../components/kiene-table/table-column';
import { Condition, Operator, TableOption, TableOptionEvent, } from '../../../components/kiene-table/table-option';
import { TierwohlprotokollSchwein } from './classes/tierwohlprotokoll-schwein';
import { ConfirmDeleteDialogComponent } from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {
    TierwohlprotokollSchweinDetailsComponent
} from './tierwohlprotokoll-schwein-details/tierwohlprotokoll-schwein-details.component';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import { HttpParams } from '@angular/common/http';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { Client } from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-tierwohlprotokoll-schwein',
    templateUrl: './tierwohlprotokoll-schwein.component.html',
    styleUrls: ['./tierwohlprotokoll-schwein.component.scss'],
})
export class TierwohlprotokollSchweinComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();
    currentClient: Client;
    watchClientSubscription: Subscription;

    constructor(
        public dialog: MatDialog,
        private messageService: MessageService,
        private pdfService: PdfViewerDesktopService,
        private api: KieneTableService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private tabinfoService: TabinfoService
    ) {
    }

    ngOnInit() {
        this.api.watchDataLoadedCompleted().subscribe({
            next: () => {
                this.tabinfoService.setReload('bestandsbesuchsprotokoll/tabinfo.php');
            },
        });

        this.tableDescriptor.headline = 'Tierwohlprotokolle Schwein';
        this.tableDescriptor.uniqueIdentifier = 'Tierwohlprotokolle Schwein';
        this.tableDescriptor.apiUrl =
            environment.BASE_URI + 'tierwohlprotokoll_schwein/';
        this.tableDescriptor.route = '/belege/tierwohlprotokoll_schwein/';
        this.tableDescriptor.nameOfIdField = 'twp_schwein_id';
        this.tableDescriptor.searchPlaceholder =
            'Tierwohlprotokolle Schwein durchsuchen';

        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.showDetailsAsOption = true;

        this.tableDescriptor.columns.push(
            new TableColumn('twp_schwein_id', 'ID')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('datum', 'Datum', 'date')
        );
        this.tableDescriptor.columns.push(new TableColumn('kunde', 'Kunde'));
        this.tableDescriptor.columns.push(
            new TableColumn(
                'tierarzt_nachname',
                'Tierarzt',
                'string',
                true,
                null,
                null,
                null,
                null,
                null,
                ['tierarzt_vorname', 'tierarzt_nachname']
            )
        );
        this.tableDescriptor.columns.push(new TableColumn('status', 'Status'));
        this.tableDescriptor.columns.push(new KieneBooleanTableColumn('abgabebeleg_id', 'Abgerechnet'));
        this.tableDescriptor.columns.push(new EmailGesendetTableColumn());
        this.tableDescriptor.columns.push(new GedrucktTableColumn());

        this.tableDescriptor.options.push(
            new TableOption('delete', 'löschen', 'delete', 9313)
        );
        this.tableDescriptor.options.push(
            new TableOption('close', 'Abschließen', 'done', 9312, {
                conditions: [
                    new Condition('status_id', Operator.GLEICH, 153),
                ]
            })
        );
        this.tableDescriptor.options.push(new TableOption(null, 'Duplizieren', 'file_copy', 9316, { void: this.duplicate.bind(this) }));
        this.tableDescriptor.options.push(
            new TableOption(null, 'Drucken', 'print', 9315, { void: this.gedruckt.bind(this), conditions: [new Condition('pdf_id', Operator.EXISTIERT, null)] })
        );

        this.tableDescriptor.permissionView = 9311;
        this.tableDescriptor.permissionCreate = 9312;
        this.tableDescriptor.permissionUpdate = 9312;
        this.tableDescriptor.permissionDelete = 9313;

        this.tableDescriptor.initialSortColumn = 'twp_schwein_id';
        this.tableDescriptor.initialSortDirection = 'desc';
    }

    gedruckt(twps: TierwohlprotokollSchwein) {
        this.api.get(this.apiBaseUrl + 'tierwohlprotokoll_schwein/gedruckt.php', new HttpParams().set('twp_schwein_id', twps.twp_schwein_id)).subscribe({
            next: () => {
                this.api.reload('gedruckt');
                this.openPdf(twps);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    duplicate(twps: TierwohlprotokollSchwein) {
        this.api.post(this.apiBaseUrl + 'tierwohlprotokoll_schwein/duplicate.php', twps).subscribe({
            next: (response: TierwohlprotokollSchwein) => {
                this.api.reload('duplicate');
                this.openDetailsDialog(response);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    catchOptionEvent(event: TableOptionEvent) {
        const twps = <TierwohlprotokollSchwein>event.value;
        if (event.name === 'details') {
            if (twps.status_id === 154) {
                this.openPdf(twps);
            } else {
                this.openDetailsDialog(twps);
            }
        } else if (event.name === 'add') {
            this.openDetailsDialog();
        } else if (event.name === 'delete') {
            this.openDeleteDialog(twps);
        } else if (event.name === 'close') {
            this.openConfirmDialog(twps);
        }
    }

    openDeleteDialog(twps: TierwohlprotokollSchwein) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Tierwohlprotokoll löschen?',
                text: 'Soll das Tierwohlprotokoll wirklich gelöscht werden?',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.api
                    .deleteEntity(
                        environment.BASE_URI + 'tierwohlprotokoll_schwein/delete.php',
                        twps
                    )
                    .subscribe({
                        next: () => {
                            this.messageService.infoMessage(
                                'Das Tierwohlprotokoll wurde erfolgreich gelöscht!'
                            );
                            this.api.reload();
                        },
                        error: (error) => {
                            this.messageService.errorMessage(
                                'Das Tierwohlprotokoll konnte nicht gelöscht werden! ',
                                error
                            );
                        },
                    });
            }
        });
    }

    private openDetailsDialog(twps?: TierwohlprotokollSchwein) {
        const dialogRef = this.dialog.open(
            TierwohlprotokollSchweinDetailsComponent,
            {
                width: '90vw',
                data: {
                    tierwohlprotokollSchwein: twps,
                },
            }
        );

        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                this.api.reload();
            }
        });
    }

    private openPdf(twps: TierwohlprotokollSchwein) {
        const input = new PdfViewerDialogInput(
            'tierwohlprotokoll_schwein/read_or_mail_pdf.php',
            new HttpParams().set(
                'twp_schwein_id',
                twps?.twp_schwein_id?.toString()
            ),
            'Tierwohlprotokoll Schwein ' + twps?.twp_schwein_id?.toString(),
            {
                email: {
                    sende_mail: true,
                    kunde_id: twps?.kunde_id,
                }
            }
        );
        this.pdfService.openPdfViewer(input).subscribe({
            next: () => {
                this.api.reload();
            }
        });
    }

    private openConfirmDialog(twps: TierwohlprotokollSchwein) {
        const input = new ConfirmDialogInput();
        input.headline = 'Tierwohlprotokoll Schwein abschließen';
        input.text =
            'Soll das Tierwohlprotokoll Schwein wirklich abgeschlossen werden? Durch das Abschließen wird eine PDF zu diesem Tierwohlprotokoll erzeugt.';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (resp: ConfirmDialogResult) => {
                if (resp?.confirmed) {
                    twps.status_id = 154;
                    this.api
                        .updateEntity(
                            this.apiBaseUrl + 'tierwohlprotokoll_schwein/update.php',
                            twps
                        )
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Tierwohlprotokoll wurde erfolgreich abgeschlossen.'
                                );
                                this.api.reload();
                                this.openPdf(twps);
                            },
                            error: (error) => {
                                this.messageService.errorMessage(
                                    'Fehler beim Aktualisieren des Tierwohlprotokolls!',
                                    error
                                );
                            },
                        });
                }
            },
        });
    }
}
