<h1 mat-dialog-title>{{ nurHonorar ? 'Honorar ' : 'Gemeinkosten ' }} {{ (gemeinkosten && gemeinkosten.kosten_id) ? 'editieren' : 'hinzufügen' }}</h1>
<div mat-dialog-content style="min-width: 800px;">

    <div class="one-row">
        <mat-radio-group [formControl]="gemeinkostenTypCtrl" style="margin-bottom: 24px;align-self: flex-start;">
            <mat-radio-button *ngIf="!nurHonorar" [value]="0">Allgemein</mat-radio-button>
            <mat-radio-button [value]="1">Labor</mat-radio-button>
            <mat-radio-button [value]="2">Tierarzt</mat-radio-button>
        </mat-radio-group>
        <span class="spacer"></span>
    </div>

    <div *ngIf="gemeinkostenTypCtrl.value === 2">
        <kiene-autocomplete (elementSelected)="setTierarzt($event)" [apiUrl]="'tierarzt/'"
                            [displayFields]="['titel', 'vorname', 'nachname']"
                            [kieneObject]="tierarzt" [placeholder]="'Tierarzt'">
        </kiene-autocomplete>
    </div>

    <div>
        <kiene-autocomplete (elementSelected)="setMaterial($event)" [apiUrl]="'artikel_autocomplete/'"
                            [displayField]="'artikel_me'" [focus]="false"
                            [kieneObject]="material" [params]="artikelParams" [placeholder]="'Artikel'"
                            [required]="true">
        </kiene-autocomplete>
    </div>

    <div *ngIf="!nurHonorar">
        <kiene-autocomplete (elementSelected)="setLieferant($event)" [apiUrl]="'lieferant/'"
                            [displayField]="'bezeichnung'" [focus]="false"
                            [kieneObject]="supplier" [params]="lieferantParams"
                            [placeholder]="'Lieferant'">
        </kiene-autocomplete>
    </div>

    <div>
        <mat-form-field style="width: 100%;">
            <mat-label>Kommentar</mat-label>
            <input [formControl]="kommentarCtrl" matInput>
        </mat-form-field>
    </div>
    <div class="one-row">
        <mat-form-field style="width: 45%;">
            <mat-label>Rechnungsdatum</mat-label>
            <input (dateChange)="setDates()" [formControl]="datumCtrl" [matDatepicker]="picker" matInput required>
            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <span class="spacer"></span>
        <mat-form-field style="width: 45%;">
            <mat-label>Rechnungsnummer</mat-label>
            <input [formControl]="rechnungNummerCtrl" matInput required>
        </mat-form-field>
    </div>
    <div class="one-row">
        <mat-form-field style="width: 45%;">
            <mat-label>Leistungsdatum von</mat-label>
            <input [formControl]="leistungsdatumVonCtrl" [matDatepicker]="lstgdatumvon" matInput required>
            <mat-datepicker-toggle [for]="lstgdatumvon" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #lstgdatumvon></mat-datepicker>
        </mat-form-field>
        <span class="spacer"></span>
        <mat-form-field style="width: 45%;">
            <mat-label>Leistungsdatum bis</mat-label>
            <input [formControl]="leistungsdatumBisCtrl" [matDatepicker]="lstgdatumbis" matInput>
            <mat-datepicker-toggle [for]="lstgdatumbis" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #lstgdatumbis></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="one-row">
        <h3>Preise angeben</h3>
        <span class="spacer"></span>
        <button (click)="neuenPreisHinzufuegen()" color="accent" mat-icon-button>
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <ul style="list-style-type: none;">
        <li *ngFor="let preis of gemeinkosten.preise; let i = index;">
            <div class="one-row">
                <mat-form-field style="width: 33%;">
                    <mat-label>Preis netto</mat-label>
                    <input [(ngModel)]="preis.kosten_netto" matInput type="number">
                    <span matSuffix>&euro;</span>
                </mat-form-field>
                <span class="spacer"></span>
                <mat-form-field style="width: 33%;">
                    <mat-label>MwSt. in %</mat-label>
                    <input [(ngModel)]="preis.mwstsatz" matInput placeholder="z.B. 19%" type="number">
                    <span matSuffix>%</span>
                </mat-form-field>
                <span class="spacer"></span>
                <button (click)="preisAusListeEntfernen(i)" mat-icon-button>
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </li>
    </ul>
    <!--    <div class="one-row">-->
    <!--        <mat-form-field style="width: 45%;">-->
    <!--            <mat-label>Preis netto</mat-label>-->
    <!--            <input [formControl]="preisCtrl" matInput type="number">-->
    <!--            <span matSuffix>&euro;</span>-->
    <!--        </mat-form-field>-->
    <!--        <span class="spacer"></span>-->
    <!--        <mat-form-field style="width: 45%;">-->
    <!--            <mat-label>MwSt. in %</mat-label>-->
    <!--            <input [formControl]="mwstCtrl" matInput placeholder="z.B. 19%" type="number">-->
    <!--            <span matSuffix>%</span>-->
    <!--        </mat-form-field>-->
    <!--    </div>-->
    <button (click)="openFileSelector()" color="accent" mat-flat-button>Rechnung als PDF hinzuf&uuml;gen</button>
    <input #fileSelector (change)="handleFiles($event)" accept="application/pdf" style="display: none" type="file">

    <div *ngIf="filesToUpload.length > 0">
        <mat-list>
            <h4 mat-subheader>Dateien</h4>
            <div *ngFor="let file of filesToUpload; let i = index" style="width: 100%;">
                <div class="one-row" style="margin-bottom: 12px;">
                    <mat-icon mat-list-icon style="width: 70px;">insert_drive_file</mat-icon>
                    <div style="min-width: 40%;">{{ file?.name }}</div>
                    <mat-icon *ngIf="filesUploadStatus[i]==1" color="primary">check</mat-icon>
                    <mat-icon *ngIf="filesUploadStatus[i]==2" color="warn">warning</mat-icon>
                    <span class="spacer"></span>
                    <mat-progress-spinner [diameter]="30" [value]="filesProgressValues[i]" mode="determinate">
                    </mat-progress-spinner>
                </div>
            </div>
        </mat-list>
    </div>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button (click)="close()" mat-button>Abbrechen</button>
    <button (click)="save()" [disabled]="!isValid()" color="accent"
            mat-flat-button>{{
            (gemeinkosten && gemeinkosten.kosten_id) ? 'editieren' :
                'hinzufügen'
        }}
    </button>
</div>
