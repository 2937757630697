import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {
    BestellungExtern,
    BestellungExternApi,
    BestellungExternDokument,
    BestellungExternDokumentApi
} from './bestellung-extern';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';

@Injectable({
    providedIn: 'root'
})
export class BestellungExternService {

    constructor(private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService) {
    }

    getAllBestellungenExtern(sortColumn: string, sortDirection: string, offset: number, limit: number, searchString: string): Observable<BestellungExternApi> {
        return this.http.get<BestellungExternApi>(environment.BASE_URI + 'bestellung_extern/read.php?orderby=' + sortColumn + ' ' + sortDirection + '&limit=' + limit + '&offset=' + offset + '&search=' + searchString);
    }

    getBestellungExternById(id: number): Observable<BestellungExtern> {
        return this.api.getByIdAlternative(this.apiBaseUrl + 'bestellung_extern', 'bestellung_extern_id', id?.toString(10));
    }

    getBestellungExternPdf(id: number): Observable<Blob> {
        return this.http.get(environment.BASE_URI + 'bestellung_extern/read_pdf.php?bestellung_extern_id=' + id, {
            responseType: 'blob'
        });
    }

    updateBestellungExtern(bestellungExtern: BestellungExtern): Observable<BestellungExtern> {
        return this.http.post<BestellungExtern>(environment.BASE_URI + 'bestellung_extern/update.php', bestellungExtern);
    }

    getDeliveryNotes(bestellungExtern: BestellungExtern): Observable<BestellungExternDokumentApi> {
        return this.http.get<BestellungExternDokumentApi>(environment.BASE_URI + 'bestellung_extern_dokument/read_lieferschein.php?bestellung_extern_id=' + bestellungExtern.bestellung_extern_id);
    }

    getInvoices(bestellungExtern: BestellungExtern): Observable<BestellungExternDokumentApi> {
        return this.http.get<BestellungExternDokumentApi>(environment.BASE_URI + 'bestellung_extern_dokument/read_rechnung.php?bestellung_extern_id=' + bestellungExtern.bestellung_extern_id);
    }

    deleteBestellungExtern(bestellungExtern: BestellungExtern) {
        return this.http.post(environment.BASE_URI + 'bestellung_extern/delete.php', bestellungExtern);
    }

    deleteBestellungExternDokument(bestellExternDokument: BestellungExternDokument) {
        return this.http.post(environment.BASE_URI + 'bestellung_extern_dokument/delete.php', bestellExternDokument);
    }

    getBestellungenByMaterialAndStatus(materialId: number, status?: number[]): Observable<BestellungExternApi> {
        let params = new HttpParams();
        params = params.append('artikel_id', materialId.toString());
        if (status != undefined && status.length > 0) {
            for (const s of status) {
                params = params.append('status_id[]', s.toString());
            }
        }
        return this.http.get<BestellungExternApi>(environment.BASE_URI + 'artikel_bestellung_extern/read.php', {params: params});
    }
}
