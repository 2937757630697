<mat-form-field *ngIf="!hideOnEmptyList || this.rassenFuerGruppe.length > 0"
    class="abgang-form-input no-padding-margin-mat-form-field" style="margin: 6px 0 0 0; width: 100%">
    <mat-icon style="padding-right: 6px" matPrefix>search</mat-icon>
    <mat-label>{{label}}</mat-label>
    <mat-select multiple [compareWith]="compareRasse" [formControl]="selectedRasseCtrl">
        <mat-option *ngFor="let rasse of rassenFuerGruppe" [value]="rasse">{{rasse.name}} ({{rasse.anzahl}}
            Tiere)</mat-option>
    </mat-select>
    <button *ngIf="selectedRasseCtrl.value && rassenFuerGruppe.length > 0" matSuffix mat-icon-button aria-label="Clear"
        (click)="resetRassenCtrl($event)">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>