import { GruppeStandort } from '../../../../../../projects/kiene-core/src/lib/components/gruppen/gruppe-standort-verwalten/gruppe-standort';

export class WertAnzahl {
    wert: number;
    anzahl: number;
}

export class GruppeStandortAuswertung {
    gruppeStandort: GruppeStandort;
    gruppe: GewichteUndPreise;
    betrieb: GewichteUndPreise;
    unternehmensgruppe: GewichteUndPreise;
    letzterDatensatz = false;
    ersterDatensatz = false;
    isLoading = false;
    erfolgreich = false;
}

export class GewichteUndPreise {
    erloesdifferenz_avg: WertAnzahl;
    schlachtgewicht_avg_ueber12: WertAnzahl;
    schlachtgewicht_avg_unter12: WertAnzahl;
    nettozunahmen_avg_ueber12: WertAnzahl;
    nettozunahmen_avg_unter12: WertAnzahl;
    einkaufspreis_avg: WertAnzahl;
    verkaufspreis_avg: WertAnzahl;
    einstallgewicht_avg: WertAnzahl;
    abgangsgewicht_avg: WertAnzahl;
    tageszunahmen_avg: WertAnzahl;
}