import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {TimePickerComponent} from "./time-picker.component";
import {Input, NgModule} from '@angular/core';
import {CommonModule, formatDate} from '@angular/common';



@NgModule({
  declarations: [TimePickerComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule
  ],
    exports: [TimePickerComponent]
})
export class TimePickerModule {

}
