<div style="padding: 12px; padding-bottom: 64px;">
    <div class="one-row">
        <button *ngIf="showBackButton" mat-icon-button color="accent" (click)="back()">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <h2>Mein Profil</h2>
        <span class="spacer"></span>
    </div>
    <div>
        <kiene-key-value-list>
            <kiene-key-value-list-item *ngIf="kieneSession?.kostenpflichtig_ab" key="Kostenpflichtig ab"
                [value]="kieneSession?.kostenpflichtig_ab | date: 'dd.MM.yyyy'"></kiene-key-value-list-item>
            <kiene-key-value-list-item key="E-Mail Adresse" [value]="benutzerprofil?.email"></kiene-key-value-list-item>
        </kiene-key-value-list>
    </div>
    <div>
        <mat-form-field style="width: 100%">
            <mat-label>Vorname</mat-label>
            <input matInput type="text" [(ngModel)]="benutzerprofil.vorname" />
        </mat-form-field>
    </div>
    <div>
        <mat-form-field style="width: 100%">
            <mat-label>Nachname</mat-label>
            <input matInput type="text" [(ngModel)]="benutzerprofil.nachname" />
        </mat-form-field>
    </div>
    <div>
        <mat-form-field style="width: 100%">
            <mat-label>Elemente pro Seite</mat-label>
            <input matInput type="number" [(ngModel)]="benutzerprofil.elemente_pro_seite" />
        </mat-form-field>
    </div>

    <button mat-flat-button color="accent" (click)="updateBenutzerprofil()"
        style="margin-top: 24px; margin-bottom: 24px;">
        &Auml;nderungen speichern
    </button>

    <mat-divider></mat-divider>

    <div style="margin-top: 20px" *kienePermission="[61, 63]">
        <h3>Mein Passwort &auml;ndern</h3>

        <kiene-new-password (changePassword)="passwortSpeichern($event)"></kiene-new-password>
        <br>
    </div>
    <mat-divider></mat-divider>

    <div id="unterschrift" style="width: 100%; margin-top: 24px;">
        <h3>Unterschrift</h3>
        <img *ngIf="benutzerprofil?.unterschrift_png" [src]="benutzerprofil.unterschrift_png" />
        <kiene-sign-button [buttonText]="'Unterschrift hinterlegen'" [istBenutzer]="true"></kiene-sign-button>
    </div>
</div>