// calendar-week.ts

export class CalendarWeek {
    weekNumber: number;
    year: number;
    monday: Date;
    sunday: Date;

    // Kein Konstruktor erforderlich

    /**
     * Gibt das Datum für einen bestimmten Tag der Woche zurück.
     * @param index Der Tag der Woche (0 = Montag, ..., 6 = Sonntag).
     * @returns Das entsprechende Datum.
     */
    getDateForDayIndex(index: number): Date {
        const date = new Date(this.monday);
        date.setDate(this.monday.getDate() + index);
        return date;
    }
}


export class CalenderWeekUtils {
    /**
     * Berechnet die ISO-Wochennummer für ein gegebenes Datum.
     * @param date Das Datum, für das die Wochennummer berechnet werden soll.
     * @returns Die ISO-Wochennummer.
     */
    static getISOWeekNumber(date: Date): number {
        const tmpDate = new Date(date.valueOf());
        const dayNumber = (date.getDay() + 6) % 7; // Montag = 0, Sonntag = 6
        tmpDate.setDate(tmpDate.getDate() - dayNumber + 3); // Donnerstag der aktuellen Woche
        const firstThursday = tmpDate.valueOf();
        tmpDate.setMonth(0, 1);
        if (tmpDate.getDay() !== 4) {
            tmpDate.setMonth(0, 1 + ((4 - tmpDate.getDay()) + 7) % 7);
        }
        return 1 + Math.round((firstThursday - tmpDate.getTime()) / (7 * 24 * 3600 * 1000));
    }

    /**
     * Ermittelt das Montag-Datum einer gegebenen ISO-Woche und eines Jahres.
     * @param weekNumber Die ISO-Wochennummer.
     * @param year Das Jahr.
     * @returns Das Montag-Datum der angegebenen Woche.
     */
    static getMondayOfWeek(weekNumber: number, year: number): Date {
        const simple = new Date(year, 0, 1 + (weekNumber - 1) * 7);
        const dow = simple.getDay();
        const ISOweekStart = new Date(simple);
        if (dow <= 4) {
            ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        } else {
            ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
        }
        return ISOweekStart;
    }

    /**
     * Ermittelt das Sonntag-Datum basierend auf dem Montag-Datum.
     * @param monday Das Montag-Datum.
     * @returns Das Sonntag-Datum der Woche.
     */
    static getSundayOfWeek(monday: Date): Date {
        const sunday = new Date(monday);
        sunday.setDate(monday.getDate() + 6);
        return sunday;
    }

    /**
     * Wandelt ein Datum in das Format YYYYMMDD um.
     * @param date Das Datum.
     * @returns Ein String im Format YYYYMMDD.
     */
    static dateToYYYYMMDD(date: Date): string {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}${month}${day}`;
    }

    // Weitere Methoden...
}

