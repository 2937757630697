import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {TierRasse} from "../../../../projects/kiene-core/src/lib/components/gruppe-tier/classes/tier-rasse";
import {KieneTableService} from "../../components/kiene-table/kiene-table.service";
import {NumberTableColumn, TableColumn} from "../../components/kiene-table/table-column";
import {TableDescriptor} from "../../components/kiene-table/table-descriptor";
import {TableOption, TableOptionEvent} from "../../components/kiene-table/table-option";
import {RasseDialogComponent} from "./rasse-dialog/rasse-dialog.component";
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-rassen',
    templateUrl: './rassen.component.html',
    styleUrls: ['./rassen.component.scss']
})
export class RassenComponent implements OnInit {

    tableDescriptor = new TableDescriptor();

    constructor(public dialog: MatDialog,
                private api: KieneTableService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    ngOnInit(): void {
        this.tableDescriptor.headline = 'Tierrassen';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'rasse/';
        this.tableDescriptor.uniqueIdentifier = 'tierrassen';
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.initialSortColumn = 'name';
        this.tableDescriptor.initialSortDirection = 'asc';

        const columns: TableColumn[] = [];

        columns.push(new TableColumn('kuerzel', 'Kürzel'));
        columns.push(new TableColumn('name', 'Rasse'));
        columns.push(new NumberTableColumn('geburtsgewicht', 'Geburtsgewicht', '1.0-2', 'kg'));
        columns.push(new NumberTableColumn('tageszunahme', 'Tageszunahme', '1.0-2', 'g'));

        this.tableDescriptor.columns = columns;

        this.tableDescriptor.permissionView = 2061;
        this.tableDescriptor.permissionCreate = 2062;
        this.tableDescriptor.permissionUpdate = 2062;

        this.tableDescriptor.showDetails = true;

        this.tableDescriptor.options.push(new TableOption('edit', 'Bearbeiten', 'edit', 2062));

    }

    optionClicked(event: TableOptionEvent) {
        console.debug('Table Option: ' + event.name);
        if (event.name === 'add') {
            this.openRasseDialog(null);
        } else if (event.name === 'edit') {
            this.openRasseDialog(event.value);
        }
    }

    openRasseDialog(rasse: TierRasse) {
        console.log(rasse)
        const dialogRef = this.dialog.open(RasseDialogComponent, {
            width: '500px',
            data: {
                rasse: rasse
            }
        });

        dialogRef.afterClosed().subscribe(response => {
            console.log(response)
            this.api.reload();
        });
    }
}
