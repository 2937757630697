import {VetGroup} from "../vet-group/vet-group";

export class Vet {
    tierarzt_id: number;
    tierarztgruppen: VetGroup[] = [];
    tierarztgruppe_id: number;
    tierarztgruppe: string;
    tierarztgruppe_farbe: string;
    tierarztgruppe_hintergrundfarbe: string;
    benutzer_id: number;
    vorname: string;
    nachname: string;
    lagerplatz_id: number;
    lagerplatz: string;
    tierarztnummer: number;
    qs_alias: string;
    abgabebeleg_nr: number;
    editiert: Date;
    titel: string;
    unterschrift: string;
}

export class VetGroupVet {
    tt_id: number;
    tierarztgruppe_id: number;
    tierarztgruppe: string;
    tierarzt_id: number;
    vorname: string;
    nachname: string;
}

export interface VetApi {
    records: Vet[];
    count: number;
}
