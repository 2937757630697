<div style="margin-top: 64px; padding: 12px; padding-bottom: 84px; font-size: 0.8em">
    <div class="one-row">
        <button
            style="margin-right: 12px"
            mat-icon-button
            color="accent"
            (click)="goBack()"
        >
            <mat-icon>chevron_left</mat-icon>
        </button>
        <h1>
            {{ isKunde ? "Behandlungsanfrage" : "Tourenplan"
            }}{{ !behandlungsanfrageId ? " erstellen" : "" }}
        </h1>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <button
            style="height: 46px; margin-right: 5px; margin-bottom: 16px"
            mat-flat-button
            color="accent"
            (click)="openKWDialog()"
            matTooltip="Kalenderwoche auswählen"
        >
            KW
        </button>
        <mat-form-field style="width: 130px; margin-right: 12px">
            <input
                matInput
                [matDatepicker]="pickerFrom"
                placeholder="Zieldatum von"
                [formControl]="zieldatumFromCtrl"
                autofocus
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="pickerFrom"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="width: 130px">
            <input
                matInput
                [matDatepicker]="pickerTo"
                placeholder="Zieldatum bis"
                [formControl]="zieldatumToCtrl"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="pickerTo"
            ></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
        <span class="spacer"></span>
    </div>

    <mat-checkbox *kienePermission="[1168]" [formControl]="apothekeCtrl"
    >Über Apotheke
    </mat-checkbox
    >

    <div>
        <div *ngIf="!isKunde" style="width: 100%">
            <!-- <kiene-company-group-autocomplete [companyGroup]="currentCompanyGroup"
        (selectedCompanyGroup)="setUnternehmensgruppe($event)"></kiene-company-group-autocomplete> -->
            <kiene-autocomplete
                [focus]="false"
                [apiUrl]="'unternehmensgruppe/'"
                [placeholder]="'Unternehmensgruppe'"
                [kieneObject]="currentCompanyGroup"
                [panelWidth]="'100%'"
                (elementSelected)="setUnternehmensgruppe($event)"
            >
            </kiene-autocomplete>
        </div>
        <div
            *ngIf="
                !isKunde || (isKunde && betriebe.length > 1) || currentClient
            "
            style="width: 100%"
        >
            <kiene-autocomplete
                [focus]="false"
                [apiUrl]="'kunde/'"
                [kieneObject]="currentClient"
                [placeholder]="isKunde ? 'Kunde' : 'oder Kunde'"
                [panelWidth]="'100%'"
                [displayFields]="['kunde_nr_intern', 'bezeichnung_final', 'zusatz_final']"
                (elementSelected)="setClient($event)"
                [required]="isKunde"
                [params]="paramsActive"
            >
            </kiene-autocomplete>
        </div>
        <div *ngIf="isKunde && betriebe.length === 1">
            {{ betriebe[0].bezeichnung_final }}
        </div>
    </div>

    <mat-form-field style="width: 100%">
        <textarea
            matInput
            [formControl]="notizCtrl"
            rows="3"
            placeholder="Notiz hinzufügen..."
        ></textarea>
    </mat-form-field>
    <div class="one-row">
        <h4>Artikel</h4>
        <button
            *kienePermission="[1162]"
            mat-icon-button
            color="accent"
            (click)="openAddPositionDialog()"
        >
            <mat-icon>add</mat-icon>
        </button>
        <span class="spacer"></span>
    </div>

    <mat-card *ngFor="let am of artikelMenge" style="margin-bottom: 12px">
        <div class="one-row">
            <h3>{{ am.article?.artikel_me }}</h3>
            <span class="spacer"></span>
            <button mat-icon-button [matMenuTriggerFor]="artikelMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #artikelMenu="matMenu">
                <button mat-menu-item (click)="editArtikel(am)">
                    <mat-icon>edit</mat-icon>
                    <span>Bearbeiten</span>
                </button>
                <button mat-menu-item (click)="removeArtikel(am)">
                    <mat-icon>delete</mat-icon>
                    <span>L&ouml;schen</span>
                </button>

            </mat-menu>
        </div>

        <div class="one-row">
            <div>Menge:</div>
            <span class="spacer"></span>
            <button
                mat-icon-button
                color="accent"
                (click)="am.menge = am.menge - 1"
            >
                <mat-icon>remove</mat-icon>
            </button>
            <input
                matInput
                type="number"
                style="width: 32px; font-size: 14px"
                [(ngModel)]="am.menge"
            />
            <button
                mat-icon-button
                color="accent"
                (click)="am.menge = am.menge + 1"
            >
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="one-row">
            <div>Anzahl Tiere:</div>
            <span class="spacer"></span>
            <button
                mat-icon-button
                color="accent"
                (click)="am.anzahl_tiere = am.anzahl_tiere - 1"
            >
                <mat-icon>remove</mat-icon>
            </button>
            <input
                matInput
                type="number"
                style="width: 32px; font-size: 14px"
                [(ngModel)]="am.anzahl_tiere"
            />
            <button
                mat-icon-button
                color="accent"
                (click)="am.anzahl_tiere = am.anzahl_tiere + 1"
            >
                <mat-icon>add</mat-icon>
            </button>
        </div>
        Identifikation: {{ am.identifikation }}
    </mat-card>
    <div>
        <button
            mat-flat-button
            color="accent"
            (click)="openSaveConfirmDialog()"
        >
            {{ behandlungsanfrageId ? "Speichern" : "Bestellen" }}
        </button>
    </div>
</div>
