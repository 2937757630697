import { Inject, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanyGroup, CompanyGroupApi } from './company-group';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import { KieneBackendApiService } from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { FileDownloadService } from '../../services/file-download.service';

@Injectable({
    providedIn: 'root',
})
export class CompanyGroupService {
    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        private pdfService: PdfViewerDesktopService,
        private api: KieneBackendApiService,
        private fileDownloadService: FileDownloadService
    ) {}

    openPreislistePdf(unternehmensgruppe_id: number, verkaufspreis_id: number) {
        this.pdfService.openPdfViewer(
            new PdfViewerDialogInput(
                'unternehmensgruppe/read_pdf_preisliste.php',
                new HttpParams()
                    .set(
                        'unternehmensgruppe_id',
                        unternehmensgruppe_id?.toString()
                    )
                    .set('verkaufspreis_id', verkaufspreis_id?.toString()),
                'Preisliste'
            )
        );
    }

    downloadPreislisteExcel(
        unternehmensgruppe_id: number,
        verkaufspreis_id: number
    ) {
        const params = new HttpParams()
            .set('unternehmensgruppe_id', unternehmensgruppe_id)
            .set('verkaufspreis_id', verkaufspreis_id);
        const url = 'unternehmensgruppe/read_excel_preisliste.php';
        this.fileDownloadService.downloadExcel(url, params);
    }

    getVerkaufspreise() {
        return this.api.getAll(
            this.apiBaseUrl + 'verkaufspreis/read.php',
            null
        );
    }

    getAllCompanyGroups(
        sortColumn: string,
        sortDirection: string,
        offset: number,
        limit: number,
        searchString: string
    ): Observable<CompanyGroupApi> {
        let params = new HttpParams()
            .append('orderby', sortColumn + ' ' + sortDirection)
            .append('limit', limit)
            .append('offset', offset);
        if (searchString) {
            params = params.append('search', searchString);
        }
        return this.api.getAll(
            this.apiBaseUrl + 'unternehmensgruppe/read.php',
            params
        );
    }

    searchCompanyGroups(searchString: string): Observable<CompanyGroupApi> {
        let params = new HttpParams()
            .append('limit', '25')
            .append('offset', '0');
        if (searchString) {
            params = params.append('search', searchString);
        }
        return this.api.getAll(
            this.apiBaseUrl + 'unternehmensgruppe/read.php',
            params
        );
    }

    getCompanyGroup(id: string): Observable<CompanyGroup> {
        return this.api.getByIdAlternative(
            this.apiBaseUrl + 'unternehmensgruppe/read_one.php',
            'unternehmensgruppe_id',
            id
        );
    }

    saveCompanyGroup(companyGroup: CompanyGroup): Observable<CompanyGroup> {
        return this.api.updateGeneric(
            this.apiBaseUrl + 'unternehmensgruppe/update.php',
            companyGroup
        );
    }

    deleteCompanyGroup(companyGroup: CompanyGroup): Observable<CompanyGroup> {
        return this.api.deleteGeneric(
            this.apiBaseUrl + 'unternehmensgruppe/delete.php',
            companyGroup
        );
    }

    getClientGroupsHistory(
        ugId: number,
        sortColumn: string,
        sortDirection: string,
        offset: number,
        limit: number,
        searchString: string
    ) {
        let params = new HttpParams()
            .append('unternehmensgruppe_id', ugId)
            .append('orderby', sortColumn + ' ' + sortDirection)
            .append('limit', limit)
            .append('offset', offset);
        if (searchString) {
            params = params.append('search', searchString);
        }
        return this.api.getAll(
            this.apiBaseUrl + 'unternehmensgruppe/read_hist.php',
            params
        );
    }

    getClientGroupsCurrent(
        ugId: number,
        sortColumn: string,
        sortDirection: string,
        offset: number,
        limit: number,
        searchString: string
    ) {
        let params = new HttpParams()
            .append('unternehmensgruppe_id', ugId)
            .append('orderby', sortColumn + ' ' + sortDirection)
            .append('limit', limit)
            .append('offset', offset);
        if (searchString) {
            params = params.append('search', searchString);
        }
        return this.api.getAll(
            this.apiBaseUrl + 'unternehmensgruppe/read_akt.php',
            params
        );
    }
}
