import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {MessageService} from '../message-service/message.service';
import {KieneException} from './classes/kiene-exception';
import {API_BASE_URL_SERVICE} from '../../kiene-core.config';

export class KieneApi {
    public records: any[] = [];
    public count: number;
}

@Injectable({
    providedIn: 'root',
})
export class KieneBackendApiService {
    dataLoadedCompletedSubject = new Subject<boolean>();
    filter = new Subject<HttpParams>();
    currentData: KieneApi;

    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    public getAll(
        url: string,
        params?: HttpParams
    ): Observable<KieneApi> {
        return this.http.get<KieneApi>(url, {params: params});
    }

    public getAllAlternativeBlob(
        url: string,
        params?: HttpParams
    ): Observable<any> {
        return this.http.get(url, {responseType: 'blob', params: params});
    }

    public getBlob(
        url: string,
        params?: HttpParams
    ): Observable<any> {
        return this.http.get(url, {responseType: 'blob', params: params});
    }

    public getPdfBase64(url: string, params: HttpParams) {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('base64', 'true');
        return this.http.get(
            url,
            {params: params, responseType: 'text'});
    }

    public get(url: string, params?: HttpParams): Observable<any> {
        return this.http.get<any>(url, {params: params});
    }

    public getByIdAlternative(
        url: string,
        idFieldName: string,
        id: string,
        params?: HttpParams
    ): Observable<any> {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set(idFieldName, id);
        return this.http.get<any>(url, {params: params});
    }

    public createGeneric(url: string, entity: any): Observable<any> {
        return this.http.post(url, entity);
    }

    public updateGeneric(url: string, entity: any): Observable<any> {
        return this.http.post(url, entity);
    }

    public deleteGeneric(url: string, entity: any): Observable<any> {
        return this.http.post(url, entity);
    }

    public duplicateEntity(url: string, entity: any): Observable<any> {
        return this.http.post(url, entity);
    }

    public postFormData(
        url: string,
        formData: FormData,
        options?: any
    ): Observable<any> {
        return this.http.post(url, formData, options);
    }

    public post(url: string, entity: any, params?: HttpParams): Observable<any> {
        return this.http.post(url, entity, {params: params});
    }

    public deleteEntity(url: string, entity: any): Observable<any> {
        return this.http.post(url, entity);
    }

    public setSpeicher(key: string, value: any): Observable<boolean> {
        const subject = new Subject<boolean>();
        const params = new HttpParams().set('name', key);
        const url = this.apiBaseUrl + 'speicher/update.php';
        this.post(url, value, params).subscribe({
            next: () => {
                subject.next(true);
            },
            error: () => {
                console.error('Key Value konnte nicht gespeichert werden!');
                subject.next(false);
            },
        });
        return subject.asObservable();
    }

    public getSpeicher(name: string): any {
        const url = this.apiBaseUrl + 'speicher/read_value.php';
        return this.getByIdAlternative(url, 'name', name);
    }

    setCurrentData(currentData: KieneApi) {
        this.currentData = currentData;
    }

    getCurrentData(): KieneApi {
        return this.currentData;
    }

    public dataLoadedCompleted() {
        this.dataLoadedCompletedSubject.next(true);
    }

    public watchDataLoadedCompleted() {
        return this.dataLoadedCompletedSubject.asObservable();
    }

    public handleException(production: boolean, error: Error) {
        console.error(error);
        if (error && this.apiBaseUrl) {
            if (
                error?.message?.indexOf(
                    'ExpressionChangedAfterItHasBeenCheckedError'
                ) < 0 &&
                error?.message?.indexOf('0 Unknown Error') < 0
            ) {
                if (production === true) {
                    const ex = new KieneException();
                    ex.messagetext = error.name + ': ' + error.message;
                    ex.stack = error.stack;
                    try {
                        this.post(
                            this.apiBaseUrl + 'exceptions/create.php',
                            ex,
                            null
                        ).subscribe();
                    } catch (ex) {
                        console.error(
                            'Exception konnte nicht ans Backend verschickt werden',
                            ex
                        );
                    }
                } else {
                    this.messageService.errorMessage(
                        error.name + ': ' + error.message,
                        null
                    );
                }
            }
        }
    }

    createOrUpdate(url: string, entity: any, nameOfIdField: string) {
        if (!entity) {
            throw new Error("Object ist nicht vorhanden und kann somit nicht gespeichert werden!");
        }
        url = this.apiBaseUrl + url;
        if (entity[nameOfIdField]) {
            url = url + '/update.php';
            return this.updateGeneric(url, entity);
        } else {
            url = url + '/create.php';
            return this.createGeneric(url, entity);
        }

    }
}
