import { Component, OnInit } from '@angular/core';
import { TableDescriptor } from '../../components/kiene-table/table-descriptor';
import { MatDialog } from '@angular/material/dialog';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../services/message.service';
import { environment } from '../../../environments/environment';
import { TableColumn } from '../../components/kiene-table/table-column';
import { TableOption, TableOptionEvent } from '../../components/kiene-table/table-option';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { Labor } from './labor';
import { LaborDialogComponent } from './labor-dialog/labor-dialog.component';

@Component({
  selector: 'kiene-labore',
  templateUrl: './labore.component.html',
  styleUrls: ['./labore.component.scss']
})
export class LaboreComponent implements OnInit {

  tableDescriptor: TableDescriptor = new TableDescriptor();

  constructor(public dialog: MatDialog,
    private api: KieneTableService,
    private notification: MessageService) { }

  ngOnInit() {
    this.tableDescriptor.headline = "Labore";
    this.tableDescriptor.uniqueIdentifier = "Labore";
    this.tableDescriptor.apiUrl = environment.BASE_URI + 'labor/';
    this.tableDescriptor.route = '/labor/labore/';
    this.tableDescriptor.nameOfIdField = 'labor_id';
    this.tableDescriptor.createAllowed = true;
    this.tableDescriptor.standardCreate = false;

    this.tableDescriptor.columns.push(new TableColumn('bezeichnung', 'Labor'));
    this.tableDescriptor.columns.push(new TableColumn('strasse', 'Straße'));
    this.tableDescriptor.columns.push(new TableColumn('plz', 'PLZ'));
    this.tableDescriptor.columns.push(new TableColumn('ort', 'Ort'));

    this.tableDescriptor.options.push(new TableOption('edit', 'bearbeiten', 'edit', 1));
    this.tableDescriptor.options.push(new TableOption('delete', 'löschen', 'delete', 1));


    this.tableDescriptor.permissionCreate = 1233;
    this.tableDescriptor.permissionUpdate = 1233;
    this.tableDescriptor.permissionView = 1231;
    this.tableDescriptor.permissionDelete = 1234;

    this.tableDescriptor.initialSortColumn = 'bezeichnung';
    this.tableDescriptor.initialSortDirection = 'asc';
  }

  catchOptionEvent(event: TableOptionEvent) {
    if (event.name === 'add') {
      this.openAddDialog();
    } else if (event.name === 'edit') {
      this.openEditDialog(event.value);
    } else if (event.name === 'delete') {
      this.openDeleteDialog(event.value);
    }
  }

  openDeleteDialog(labor: Labor) {
    let dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '50%',
      minWidth: '300px',
      data: {
        title: labor.bezeichnung + ' löschen',
        text: 'Wollen Sie ' + labor.bezeichnung + ' wirklich löschen?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.api.deleteEntity(environment.BASE_URI + 'labor/delete.php', labor).subscribe(response => {
          this.notification.infoMessage('Labor erfolgreich gelöscht!');
          this.api.reload();
        }, error => {
          this.notification.errorMessage('Labor konnte nicht gelöscht werden!', error);
          this.api.reload();
        });
      }
    });
  }

  openAddDialog() {
    let dialogRef = this.dialog.open(LaborDialogComponent, {
      width: '50%',
      minWidth: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const lab = <Labor>result;
        this.api.updateEntity(environment.BASE_URI + 'labor/update.php', lab).subscribe(response => {
          this.notification.infoMessage('Labor erfolgreich gespeichert!');
          this.api.reload();
        }, error => {
          this.notification.errorMessage('Labor konnte nicht gespeichert werden!', error);
          this.api.reload();
        });
      }
    });
  }
  openEditDialog(labor: Labor) {
    let dialogRef = this.dialog.open(LaborDialogComponent, {
      width: '50%',
      minWidth: '300px',
      data: {
        labor: labor
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const lab = <Labor>result;
        this.api.updateEntity(environment.BASE_URI + 'labor/update.php', lab).subscribe(response => {
          this.notification.infoMessage('Labor erfolgreich gespeichert!');
          this.api.reload();
        }, error => {
          this.notification.errorMessage('Labor konnte nicht gespeichert werden!', error);
          this.api.reload();
        });
      }
    });
  }

}
