import { Component, Inject, OnInit } from '@angular/core';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Umbuchung } from '../umbuchung';
import { Observable } from 'rxjs';
import { BestellungExtern } from '../../../wa-wi/bestellung-extern/bestellung-extern';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { FormControl, Validators } from '@angular/forms';
import { MessageService } from '../../../services/message.service';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'kiene-return-delivery-dialog',
    templateUrl: './return-delivery-dialog.component.html',
    styleUrls: ['./return-delivery-dialog.component.scss']
})
export class ReturnDeliveryDialogComponent implements OnInit {

    bestellungExtern: BestellungExtern;
    bestellteMenge = 0;

    mengeCtrl = new FormControl();

    constructor(@Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        @Inject(MAT_DIALOG_DATA) public umbuchung: Umbuchung,
        public dialogRef: MatDialogRef<ReturnDeliveryDialogComponent>,
        private messageService: MessageService,
        private pdfViewerService: PdfViewerDesktopService) {
    }

    ngOnInit(): void {
        this.ladeBestellung();
        this.mengeCtrl.setValidators([Validators.required, Validators.min(1), Validators.max(this.umbuchung.menge)]);
    }

    getBestellungExternById(id: number): Observable<BestellungExtern> {
        return this.api.getByIdAlternative(this.apiBaseUrl + 'bestellung_extern/read_one.php', 'bestellung_extern_id', id?.toString(10));
    }

    save() {
        const obj = {
            'umbuchung_id': this.umbuchung.umbuchung_id,
            'menge': this.mengeCtrl.value
        };
        this.api.post(this.apiBaseUrl + 'wareneingang/rueckliefern.php', obj).subscribe({
            next: response => {
                this.messageService.infoMessage('Die Rücklieferung wurde erfolgreich gebucht!');
                const params = new HttpParams().set('umbuchung_id', response.umbuchung_id);
                const input = new PdfViewerDialogInput('wareneingang/read_pdf.php', params, 'Lieferschein');
                this.pdfViewerService.openPdfViewer(input);
                this.dialogRef.close();
            },
            error: err => {
                this.messageService.errorMessageSimple(err);
            }
        });

    }

    private ladeBestellung() {
        if (this.umbuchung) {
            this.getBestellungExternById(this.umbuchung.bestellung_extern_id).subscribe({
                next: (bestellungExtern: BestellungExtern) => {
                    this.bestellungExtern = bestellungExtern;
                    this.ermittleBestellteMenge();
                },
                error: err => {
                    this.messageService.errorMessageSimple(err);
                }
            });
        }
    }

    private ermittleBestellteMenge() {
        for (const position of this.bestellungExtern.positionen) {
            if (position.artikel_id === this.umbuchung.artikel_id) {
                this.bestellteMenge += position.menge_soll;
            }
        }
    }
}
