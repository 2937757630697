import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kiene-select-date-dialog',
  templateUrl: './select-date-dialog.component.html',
  styleUrls: ['./select-date-dialog.component.scss']
})
export class SelectDateDialogComponent implements OnInit {

  dateCtrl = new UntypedFormControl();
  headline = 'Datum eingeben';
  text = 'Bitte geben Sie ein Datum ein';
  label = 'Datum';
  okButton = 'Speichern';

  constructor(public dialogRef: MatDialogRef<SelectDateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) { }

  ngOnInit(): void {
    if (this.data) {
      this.headline = this.data.headline;
      this.text = this.data.text;
      this.label = this.data.label;
      this.okButton = this.data.okButton;
    }
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.dateCtrl.value);
  }

}
