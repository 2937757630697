
<div class="padMe">
    <h1>
    Zeitslot buchen und Viehändler beauftragen
    </h1>
    <h2 *ngIf="gruppe">{{gruppe.bezeichnung}} : {{gruppe.anzahl_tiere}} Tiere</h2>
    <div class="timeSlotBooking">        
        <h2>Zeitpunkt der Schlachtung</h2>
        <h3 *ngIf="standard_schlachthof">Schlachthof: {{standard_schlachthof.bezeichnung}}</h3>

        <div *ngIf="zeitslot_buchung" class="current-booking">
            <h4>Aktueller Zeitslot</h4>
            <div>von {{zeitslot_buchung.datum_uhrzeit_von}}</div> 
            <div>bis {{zeitslot_buchung.datum_uhrzeit_bis}}</div>
        </div>

        <div class="slotSelection">
            <h3>Diese Woche</h3>
            <div *ngIf="noSlotsAvailable" class="no-slots">
                Es gibt keine Zeitslots für die nächsten Tage!
            </div>
            <div class="weekSchedule">
                <mat-list *ngFor="let e of days" style="margin-bottom: 6px" class="daySlots">
                    <div matTooltip="120 frei"
                        class="dayHeader">
                        <div>{{e.day | date: "fullDate"}}</div>
                    </div>
                    <mat-list-item *ngFor="let slot of e.slots" class="timeSlot" [ngClass]="selectedSlot == slot.zeitslot_id? 'selected' : ''"
                        [ngClass]="slot.anzahl_tiere <= 0 ? 'emptySlot' : ''"
                        (click)="selectTimeSlot(slot)">
                        <span matLine>{{slot.datum_uhrzeit_von | date: "HH:mm"}} - {{slot.datum_uhrzeit_bis | date: "HH:mm"}} Uhr</span>
                        <span matLine>{{slot.anzahl_tiere}} Tiere</span>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>

        <hr/>

        <h2>Tierzahl zur Schlachtung</h2>
        <div *ngIf="zeitslot_buchung" class="current-booking">
            <h4>Aktuelle Buchung</h4>
            <div>{{zeitslot_buchung.anzahl_tiere}} Tiere</div> 
        </div>
        <div>
            <mat-form-field>
                <mat-label>Tierzahl</mat-label>
                <input matInput [formControl]="countCtrl" placeholder="Tierzahl" (change)="checkCapacityAssignment()"/>
                <span matSuffix>/ {{maxCapacity}}</span>
            </mat-form-field>
        </div>

        <hr/>

        <h2>Auswahl Viehhändler</h2>
        <div *ngIf="zeitslot_buchung" class="current-booking">
            <h4>Aktuelle Buchung</h4>
            <div>{{zeitslot_buchung.haendler_id}}</div> 
        </div>
        <div class="haendler-selection">
            <mat-form-field>
                <mat-label>Viehändler</mat-label>
                <mat-select matInput [formControl]="haendlerCtrl" placeholder="Viehändler" (selectionChange)="checkTraderAssignment()">
                    <mat-option *ngFor="let item of favoritenHaendler" [value]="item.viehhaendler_id">{{item.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <hr/>

        <button (click)="buchungAbschliessen()" mat-flat-button color="accent" [disabled]="!bookingValid">
            <span>Änderungen speichern</span>
        </button>
    </div>
</div>