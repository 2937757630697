<h1 mat-dialog-title>Dateien generieren</h1>
<mat-dialog-content>
    <p>für Kunde: {{currentClient ? currentClient.bezeichnung_final : ''}}</p>
    <mat-radio-group class="radio-group" [formControl]="documentTypeCtrl">
        <mat-radio-button class="radio-button" [value]="1">Tierärztlicher Betreuungsvertrag</mat-radio-button>
        <mat-radio-button class="radio-button" [value]="2">Ergänzung zum Betreuungsvertrag</mat-radio-button>
        <mat-radio-button class="radio-button" [value]="3">Anwendung von Tierarzneimitteln (Futter)</mat-radio-button>
        <mat-radio-button class="radio-button" [value]="4">Anwendung von Tierarzneimitteln (Wasser)</mat-radio-button>
        <mat-radio-button class="radio-button" [value]="5">Meldevereinbarung QS</mat-radio-button>
        <mat-radio-button class="radio-button" [value]="6">Risikomanagement zur oralen Medikation</mat-radio-button>
    </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="accent" (click)="cancel()">Abbrechen</button>
    <button mat-raised-button color="accent" (click)="save()">Erzeugen</button>
</mat-dialog-actions>
