import { KieneKeyValue } from "../../../../projects/kiene-core/src/lib/services/backend-api/classes/kiene-key-value";

export class ConfirmWithOptionDialogInput {
    headline: string;
    text: string;
    label: string;
    okButton: string;
    options: KieneKeyValue[] = [];

    addOption(kkv: KieneKeyValue) {
        this.options.push(kkv);
    }
}

export class ConfirmWithOptionDialogResult {
    ok: boolean = false; // false = Abbruch
    confirmed: boolean = false;
    option: KieneKeyValue;
}
