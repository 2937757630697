<div *kienePermission="[4001]" style="padding: 12px">
    <div class="one-row">
        <h1>Tourenplan</h1>
        <span class="spacer"></span>
        <div>
            <button
                *kienePermission="[1162]"
                color="accent"
                mat-icon-button
                (click)="openAddTourDialog()"
            >
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>

    <div *ngIf="!isMobileScreen" class="one-row">
        <h4>f&uuml;r {{ kieneSession.vorname }} {{ kieneSession.nachname }}</h4>
        <span class="spacer"></span>
        <mat-form-field *ngIf="!isKunde">
            <mat-select
                placeholder="Tierarztgruppe"
                [formControl]="tierarztgruppeCtrl"
                [compareWith]="vetGroupComparator"
            >
                <mat-option>--</mat-option>
                <mat-option *ngFor="let t of tierarztgruppen" [value]="t">{{
                        t.bezeichnung
                    }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <span class="spacer"></span>
        <div *ngIf="!isKunde" class="one-row" style="width: 25%">
            <mat-slide-toggle
                color="accent"
                style="margin-right: 5px"
                [formControl]="onlyMineCtrl"
            >Nur meine
            </mat-slide-toggle>

            <span class="spacer"></span>
            <span
                style="color: #555; font-weight: bold"
                [matBadgeHidden]="kwInfo < 1 || selectedOption != 'Heute'"
                [matBadge]="kwInfo"
                [matTooltip]="
                    selectedOption === 'Heute' && kwInfo > 0
                        ? 'Diese KW noch ' + kwInfo + ' Einträge'
                        : ''
                "
            >{{ selectedOption }}</span
            >
            <span class="spacer"></span>

            <button mat-icon-button color="accent" (click)="reload()" matTooltip="Seite neu laden">
                <mat-icon>refresh</mat-icon>
            </button>

            <button
                mat-icon-button
                color="accent"
                [matMenuTriggerFor]="menu"
                aria-label="filter menu button"
                matTooltip="Tourenplan filtern"
            >
                <mat-icon>filter_list</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="setzeOptions(1, true)">
                    <mat-icon>filter_list_off</mat-icon>
                    <span>Alle</span>
                </button>
                <button mat-menu-item (click)="setzeOptions(2, true)">
                    <mat-icon>today</mat-icon>
                    <span>Heute</span>
                </button>
                <button mat-menu-item (click)="setzeOptions(4, true)">
                    <mat-icon>view_week</mat-icon>
                    <span>Kalenderwoche</span>
                </button>
                <button mat-menu-item (click)="setzeOptions(3, true)">
                    <mat-icon>history</mat-icon>
                    <span>Historie</span>
                </button>
            </mat-menu>

            <button
                color="accent"
                mat-icon-button
                (click)="downloadTourenplan()"
                matTooltip="Tourenplan herunterladen"
            >
                <mat-icon>cloud_download</mat-icon>
            </button>
            <button
                *ngIf="isVet"
                color="accent"
                mat-icon-button
                (click)="route()"
                matTooltip="Heutige Route in Google Maps öffnen"
            >
                <mat-icon>directions</mat-icon>
            </button>
        </div>
    </div>

    <div *ngIf="isMobileScreen">
        <h4 style="width: 100%; margin-top: 5px; margin-bottom: 5px">
            f&uuml;r {{ kieneSession.vorname }} {{ kieneSession.nachname }}
        </h4>
        <div class="one-row">
            <mat-form-field *ngIf="!isKunde" style="width: 66%">
                <mat-select
                    placeholder="Tierarztgruppe"
                    [formControl]="tierarztgruppeCtrl"
                    [compareWith]="vetGroupComparator"
                >
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let t of tierarztgruppen" [value]="t">{{
                            t.bezeichnung
                        }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <span class="spacer"></span>
            <button
                color="accent"
                mat-icon-button
                (click)="downloadTourenplan()"
            >
                <mat-icon>cloud_download</mat-icon>
            </button>
            <span class="spacer"></span>
            <button
                *ngIf="isVet"
                color="accent"
                mat-icon-button
                (click)="route()"
            >
                <mat-icon>directions</mat-icon>
            </button>
        </div>

        <div
            *ngIf="!isKunde"
            style="width: 100%; margin-bottom: 6px"
            class="one-row"
        >
            <mat-slide-toggle
                color="accent"
                style="margin-right: 5px"
                [formControl]="onlyMineCtrl"
            >Nur meine
            </mat-slide-toggle>

            <span class="spacer"></span>
            <span
                style="color: #555; font-weight: bold"
                [matBadgeHidden]="kwInfo < 1 || selectedOption != 'Heute'"
                [matBadge]="kwInfo"
                [matTooltip]="
                    selectedOption === 'Heute' && kwInfo > 0
                        ? 'Diese KW noch ' + kwInfo + ' Einträge'
                        : ''
                "
            >{{ selectedOption }}</span
            >
            <span class="spacer"></span>

            <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="filter menu button"
            >
                <mat-icon>filter_list</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="setzeOptions(1, true)">
                    <mat-icon>filter_list_off</mat-icon>
                    <span>Alle</span>
                </button>
                <button mat-menu-item (click)="setzeOptions(2, true)">
                    <mat-icon>today</mat-icon>
                    <span>Heute</span>
                </button>
                <button mat-menu-item (click)="setzeOptions(4, true)">
                    <mat-icon>view_week</mat-icon>
                    <span>Kalenderwoche</span>
                </button>
                <button mat-menu-item (click)="setzeOptions(3, true)">
                    <mat-icon>history</mat-icon>
                    <span>Historie</span>
                </button>
            </mat-menu>
        </div>
    </div>

    <div *ngIf="isMobileScreen">
        <mat-card
            style="margin-bottom: 15px"
            *ngFor="let t of tourenplans"
            [ngClass]="t.status_id === 2 ? 'background-red' : ''"
        >
            <div class="one-row">
                <p style="font-weight: bold">
                    {{ t.kunde }} {{ t.zusatz }}
                    {{ !t.kunde ? t.unternehmensgruppe : "" }}
                </p>
                <button
                    *kienePermission="[4006]"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <a
                        class="phone-button"
                        href="{{ 'tel:' + t.telefon_1 }}"
                        [disabled]="t.telefon_1 === null"
                        mat-menu-item
                    >
                        <mat-icon>phone</mat-icon>
                        <span>Festnetz anrufen</span>
                    </a>
                    <a
                        class="phone-button"
                        href="{{ 'tel:' + t.mobil_1 }}"
                        [disabled]="t.mobil_1 === null"
                        mat-menu-item
                    >
                        <mat-icon>phone_iphone
                        </mat-icon
                        >
                        <span>Mobil anrufen</span>
                    </a>
                    <button mat-menu-item (click)="goToBehandlungsanfrage(t)">
                        <mat-icon>insert_drive_file
                        </mat-icon
                        >
                        <span>Behandlungsanfr. anzeigen</span>
                    </button>
                    <button mat-menu-item (click)="editTour(t)">
                        <mat-icon>edit</mat-icon>
                        <span>Bearbeiten</span>
                    </button>
                    <button mat-menu-item (click)="deleteTour(t)">
                        <mat-icon>delete</mat-icon>
                        <span>Löschen</span>
                    </button>
                    <button
                        *ngIf="t.status_id === 2"
                        mat-menu-item
                        (click)="release(t)"
                    >
                        <mat-icon>thumb_up</mat-icon>
                        <span>Freigeben</span>
                    </button>
                </mat-menu>
            </div>
            <div class="one-row">
                <mat-icon color="accent">place</mat-icon>
                <kiene-google-maps-route
                    style="font-size: 0.8em; text-align: left; color: #aaa"
                    [strasse]="getStrasse(t)"
                    [plz]="getPlz(t)"
                    [ort]="getOrt(t)"
                ></kiene-google-maps-route>
                <span class="spacer"></span>
            </div>

            <div class="one-row" style="font-size: 0.8em; font-weight: bold">
                <p *ngIf="t.zieldatum">
                    Ziel: {{ t.zieldatum | date: "mediumDate" }}
                </p>
                <p *ngIf="t.zieldatum_bis">
                    - {{ t.zieldatum_bis | date: "mediumDate" }}
                </p>
                <span class="spacer"></span>
            </div>

            <div class="one-row">
                <div style="width: 100px">
                    <mat-icon
                        matTooltip="Artikel müssen in der Apotheke abgeholt werden"
                        color="primary"
                        *ngIf="t.apotheke && !isKunde"
                    >
                        local_pharmacy
                    </mat-icon
                    >
                </div>
                <div
                    *ngIf="t.apotheke && t.status_id === 3 && !isKunde"
                    class="red"
                    style="width: 100px"
                >
                    <mat-icon matTooltip="Bestellung noch nicht kommissioniert"
                    >local_shipping
                    </mat-icon
                    >
                </div>
                <button
                    mat-icon-button
                    *ngIf="t.apotheke && t.status_id === 4 && !isKunde"
                    class="orange"
                    (click)="bestellungAbgeholt(t)"
                    style="width: 100px"
                >
                    <mat-icon matTooltip="Bestellung kann abgeholt werden"
                    >local_shipping
                    </mat-icon
                    >
                </button>
                <div
                    *ngIf="t.apotheke && t.status_id === 5 && !isKunde"
                    class="green"
                    style="width: 100px"
                >
                    <mat-icon matTooltip="Bestellung wurde abgeholt"
                    >local_shipping
                    </mat-icon
                    >
                </div>
                <span class="spacer"></span>
            </div>

            <p *ngIf="t.bezeichnung">{{ t.bezeichnung }}</p>
            <ul style="padding: 0; margin: 0; list-style-type: none">
                <li *ngFor="let p of t.positionen">
                    <p
                        style="
                            font-size: 0.9em;
                            font-weight: bold;
                            padding-bottom: 0px;
                            margin-bottom: 0px;
                        "
                    >
                        {{ p.artikelmenge | number: "1.0-1" }}x
                        {{ p.artikel_me }}
                    </p>
                    <p
                        *ngIf="p.anzahl_tiere && p.anzahl_tiere > 0"
                        style="
                            margin: 0px;
                            padding: 0px;
                            width: 280px;
                            white-space: nowrap;
                            overflow: hidden;
                            font-size: 0.9em;
                            color: #555;
                            text-overflow: ellipsis;
                        "
                    >
                        Tiere: {{ p.anzahl_tiere }}
                    </p>
                    <p
                        style="
                            margin: 0px;
                            padding: 0px;
                            width: 280px;
                            white-space: nowrap;
                            overflow: hidden;
                            font-size: 0.9em;
                            color: #555;
                            text-overflow: ellipsis;
                        "
                    >
                        {{ p.identifikation }}
                    </p>
                </li>
            </ul>

            <div
                class="one-row"
                style="
                    border-top: 1px solid gray;
                    padding-top: 12px;
                    margin-top: 12px;
                "
            >
                <button
                    *ngIf="t.tourenplan == 1"
                    mat-icon-button
                    color="accent"
                    (click)="done(t)"
                    style="margin-right: 12px"
                >
                    <mat-icon>check</mat-icon>
                </button>
                <button
                    *ngIf="t.tourenplan == 2"
                    mat-icon-button
                    color="accent"
                    (click)="undone(t)"
                    style="margin-right: 12px"
                >
                    <mat-icon>clear</mat-icon>
                </button>
                <button
                    mat-icon-button
                    color="accent"
                    (click)="transformAbgabebeleg(t)"
                    matTooltip="In Abgabebeleg umwandeln"
                >
                    <mat-icon>transform</mat-icon>
                </button>
                <span class="spacer"></span>

                <button
                    *ngIf="!t.tierarzt"
                    mat-icon-button
                    (click)="assignToVet(t)"
                >
                    <mat-icon>person_add</mat-icon>
                </button>
                <div *ngIf="t.tierarzt">
                    <button mat-button (click)="releaseVet(t)">
                        {{ t.tierarzt }}
                    </button>
                </div>
            </div>
        </mat-card>

        <mat-paginator
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent($event)"
        >
        </mat-paginator>
    </div>

    <div *ngIf="!isMobileScreen">
        <!-- cards -->
        <mat-card
            style="margin-bottom: 15px"
            *ngFor="let t of tourenplans"
            [ngClass]="t.status_id === 2 ? 'background-red' : ''"
        >
            <mat-card-header class="card-header">
                <mat-icon mat-card-avatar>place</mat-icon>
                <mat-card-title style="font-weight: bold"
                >{{ t.kunde }} {{ t.zusatz }}
                    {{ !t.kunde ? t.unternehmensgruppe : "" }}<br/>
                    <kiene-google-maps-route
                        style="font-size: 0.6em; text-align: left; color: #aaa"
                        [strasse]="getStrasse(t)"
                        [plz]="getPlz(t)"
                        [ort]="getOrt(t)"
                    ></kiene-google-maps-route>
                </mat-card-title>
                <button
                    *kienePermission="[4006]"
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <a
                        class="phone-button"
                        href="{{ 'tel:' + t.telefon_1 }}"
                        [disabled]="t.telefon_1 === null"
                        mat-menu-item
                    >
                        <mat-icon>phone</mat-icon>
                        <span>Festnetz anrufen</span>
                    </a>
                    <a
                        class="phone-button"
                        href="{{ 'tel:' + t.mobil_1 }}"
                        [disabled]="t.mobil_1 === null"
                        mat-menu-item
                    >
                        <mat-icon>phone_iphone
                        </mat-icon
                        >
                        <span>Mobil anrufen</span>
                    </a>
                    <button mat-menu-item (click)="goToBehandlungsanfrage(t)">
                        <mat-icon>insert_drive_file
                        </mat-icon
                        >
                        <span>Behandlungsanfr. anzeigen</span>
                    </button>
                    <button mat-menu-item (click)="editTour(t)">
                        <mat-icon>edit</mat-icon>
                        <span>Bearbeiten</span>
                    </button>
                    <button mat-menu-item (click)="deleteTour(t)">
                        <mat-icon>delete</mat-icon>
                        <span>Löschen</span>
                    </button>
                    <button
                        *ngIf="t.status_id === 2"
                        mat-menu-item
                        (click)="release(t)"
                    >
                        <mat-icon>thumb_up</mat-icon>
                        <span>Freigeben</span>
                    </button>
                </mat-menu>
            </mat-card-header>
            <mat-card-content>
                <div class="one-row">
                    <mat-icon
                        matTooltip="Artikel müssen in der Apotheke abgeholt werden"
                        color="primary"
                        *ngIf="t.apotheke && !isKunde"
                    >
                        local_pharmacy
                    </mat-icon
                    >
                    <div
                        *ngIf="t.apotheke && t.status_id === 3 && !isKunde"
                        class="red"
                    >
                        <mat-icon
                            matTooltip="Bestellung noch nicht kommissioniert"
                        >local_shipping
                        </mat-icon
                        >
                    </div>
                    <button
                        mat-icon-button
                        *ngIf="t.apotheke && t.status_id === 4 && !isKunde"
                        class="orange"
                        (click)="bestellungAbgeholt(t)"
                    >
                        <mat-icon matTooltip="Bestellung kann abgeholt werden"
                        >local_shipping
                        </mat-icon
                        >
                    </button>
                    <div
                        *ngIf="t.apotheke && t.status_id === 5 && !isKunde"
                        class="green"
                    >
                        <mat-icon matTooltip="Bestellung wurde abgeholt"
                        >local_shipping
                        </mat-icon
                        >
                    </div>
                    <span class="spacer"></span>
                    <div>
                        <p *ngIf="t.zieldatum">
                            Ziel: {{ t.zieldatum | date: "mediumDate" }}
                        </p>
                        <p *ngIf="t.zieldatum_bis">
                            Bis: {{ t.zieldatum_bis | date: "mediumDate" }}
                        </p>
                    </div>
                </div>
                <p *ngIf="t.bezeichnung">{{ t.bezeichnung }}</p>
                <div>
                    <table *ngIf="t.positionen.length > 0" class="phito-table">
                        <thead>
                        <tr>
                            <th>Pos.</th>
                            <th>Artikel</th>
                            <th>Menge</th>
                            <th>Tiere</th>
                            <th>Identifikation</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let p of t.positionen">
                            <td>{{ p.position }}</td>
                            <td>{{ p.artikel_me }}</td>
                            <td>{{ p.artikelmenge | number: "1.0-1" }}</td>
                            <td>{{ p.anzahl_tiere | number: "1.0-0" }}</td>
                            <td>{{ p.identifikation }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </mat-card-content>
            <mat-card-footer class="footer" *ngIf="!isKunde">
                <div class="one-row">
                    <button
                        *ngIf="t.tourenplan == 1"
                        mat-mini-fab
                        color="accent"
                        (click)="done(t)"
                        style="margin-right: 12px"
                        matTooltip="Als erledigt markieren"
                    >
                        <mat-icon>check</mat-icon>
                    </button>
                    <button
                        *ngIf="t.tourenplan == 2"
                        mat-mini-fab
                        color="accent"
                        (click)="undone(t)"
                        style="margin-right: 12px"
                    >
                        <mat-icon>clear</mat-icon>
                    </button>
                    <button
                        mat-mini-fab
                        color="accent"
                        (click)="transformAbgabebeleg(t)"
                        matTooltip="In Abgabebeleg umwandeln"
                    >
                        <mat-icon>transform</mat-icon>
                    </button>
                    <span class="spacer"></span>

                    <button
                        *ngIf="!t.tierarzt"
                        mat-icon-button
                        (click)="assignToVet(t)"
                    >
                        <mat-icon>person_add</mat-icon>
                    </button>
                    <div *ngIf="t.tierarzt">
                        <button mat-button (click)="releaseVet(t)">
                            {{ t.tierarzt }}
                        </button>
                    </div>
                </div>
            </mat-card-footer>
        </mat-card>
        <mat-paginator
            [length]="length"
            [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions"
            (page)="pageEvent($event)"
        >
        </mat-paginator>
    </div>
</div>
