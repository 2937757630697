export class Supplier {
    lieferant_id: number;
    bezeichnung: string;
    strasse: string;
    plz: string;
    ort: string;
    bezeichnung_1: string;
    bezeichnung_2: string;
    bezeichnung_3: string;
    bezeichnung_kurz: string;
    email: string;
    fax: string;
    fax_bevorzugen: number;
    mobil: string;
    telefon: string;
    skonto: number;
    // rabatt: number;
    zahlungsziel: number;
    zahlungsart: number;
    buchungskonto: string;
    buchungsgegenkonto: string;
    lieferant_nr_intern: number;
    mandanten_ids: number[] = [];
    nebenkosten: number;
  }

  export interface SupplierApi {
    records: Supplier[];
    count: number;
  }
