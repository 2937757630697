import {HttpParams} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {BestellungExtern} from '../../bestellung-extern/bestellung-extern';
import {Gutschrift} from '../../gutschriften/gutschrift';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';

@Component({
    selector: 'kiene-show-gutschriften-dialog',
    templateUrl: './show-gutschriften-dialog.component.html',
    styleUrls: ['./show-gutschriften-dialog.component.css']
})
export class ShowGutschriftenDialogComponent implements OnInit {

    bestellungExtern: BestellungExtern = new BestellungExtern();
    gutschriften: Gutschrift[] = [];

    constructor(public dialogRef: MatDialogRef<ShowGutschriftenDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data,
                private api: KieneTableService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private pdfService: PdfViewerDesktopService) {
        this.bestellungExtern = data.bestellungExtern;
    }

    ngOnInit(): void {
        this.loadGutschriften();
    }

    loadGutschriften() {
        let params = new HttpParams();
        params = params.append('bestellung_extern_id', this.bestellungExtern.bestellung_extern_id.toString());
        this.api.get(this.apiBaseUrl + 'gutschrift/read_lieferanten.php', params).subscribe(response => {
            this.gutschriften = response.records;
        });
    }

    close() {
        this.dialogRef.close();
    }

    openPdf(g: Gutschrift) {
        let params = new HttpParams();
        params = params.set('pdf_id', g.pdf_id);
        params = params.set('gutschrift_id', g.gutschrift_id);
        const input = new PdfViewerDialogInput('gutschrift/read_pdf.php', params, 'Gutschrift ' + this.bestellungExtern.lieferant);
        this.pdfService.openPdfViewer(input);
    }
}
