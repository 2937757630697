<div *ngIf="!hasHitLoggedIn" style="
        height: 90vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    ">
    <h1>VETVET Registrierung</h1>
    <h2>HI-Tier Anmeldung </h2>

    <p class="hit-form-text">
        Damit Sie den vollen Funktionsumfang von
        <strong>vetvet.de</strong> nutzen können, ist eine einmalige Anmeldung
        bei HI-Tier notwendig.
    </p>

    <mat-form-field class="hit-form-field">
        <mat-label>VVVO-Nummer</mat-label>
        <input #VvvoInput="matInput" matInput type="text" placeholder="VVVO-Nummer" [formControl]="hitVvvoCtrl"
            maxlength="15" autocomplete="username" />
        <span matSuffix style="font-size: 0.8em">{{ hitVvvoCtrl.value.length }}/15</span>
        <mat-error class="input-error" *ngIf="hitVvvoCtrl.hasError('required')">Die VVVO-Nummer ist ein
            Pflichtfeld.
        </mat-error>
        <mat-error class="input-error" *ngIf="hitVvvoCtrl.hasError('pattern')">Die VVVO-Nummer muss 15 Zeichen lang
            sein.
        </mat-error>
    </mat-form-field>

    <mat-form-field class="hit-form-field">
        <mat-label>Passwort</mat-label>
        <input #hitPinInput="matInput" matInput placeholder="Passwort" [type]="hidePassword ? 'password' : 'text'"
            [formControl]="hitPinCtrl" autocomplete="current-password" />
        <mat-error class="input-error" *ngIf="hitPinCtrl.hasError('required')">Das Passwort ist ein
            Pflichtfeld.
        </mat-error>
        <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{ hidePassword ? "visibility" : "visibility_off" }}
        </mat-icon>
    </mat-form-field>

    <button class="hit-login-button" mat-flat-button type="button" color="accent" (click)="hitLogin()"
        [disabled]="isLoading">
        Anmelden
    </button>
    <br>
    <button class="hit-login-button" mat-stroked-button type="button" color="accent" (click)="backToLogin()"
        [disabled]="isLoading">
        zur&uuml;ck zum Login
    </button>
</div>

<div *ngIf="hasHitLoggedIn && hitLoginResponse">
    <h1>VetVet Benutzer anlegen</h1>

    <div style="width: 100%; margin-bottom: 12px;">
        <p style="width: 100%;">Ich registriere mich als:</p>
        <mat-radio-group [formControl]="landwirtHaendlerCtrl" style="width: 100%;" required>
            <mat-radio-button [value]="0">Landwirt</mat-radio-button>
            <mat-radio-button [value]="1">Viehh&auml;ndler</mat-radio-button>
        </mat-radio-group>

    </div>

    <div>
        <h3>Benutzerinformationen</h3>

        <div style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
            ">
            <mat-form-field>
                <mat-label>VVVO</mat-label>
                <input required matInput type="text" disabled placeholder="VVVO" [(ngModel)]="hitLoginResponse.vvvo"
                    autocomplete="vvvo" />
            </mat-form-field>
        </div>


        <div style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
            ">
            <mat-form-field>
                <mat-label>Firmenname</mat-label>
                <input #firmenname="ngModel" required matInput type="text" placeholder="Firmenname"
                    [(ngModel)]="hitLoginResponse.bezeichnung" autocomplete="company" />
                <mat-error class="input-error" *ngIf="firmenname.errors">Der Firmenname ist ein Pflichtfeld.</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Betriebsart</mat-label>
                <mat-select [formControl]="betriebsartCtrl" [compareWith]="compareBetriebsarten">
                    <mat-option *ngFor="let betriebsart of betriebsarten" [value]="betriebsart">
                        {{ betriebsart.bezeichnung }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Adresszusatz</mat-label>
                <input matInput type="text" placeholder="Adresszusatz" [(ngModel)]="hitLoginResponse.zusatz"
                    autocomplete="company" />
            </mat-form-field>
        </div>

        <div style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
            ">
            <mat-form-field>
                <mat-label>Strasse</mat-label>
                <input #strasse="ngModel" required matInput type="text" placeholder="Strasse Hausnr."
                    [(ngModel)]="hitLoginResponse.strasse" autocomplete="street" />
                <mat-error class="input-error" *ngIf="strasse.errors && strasse.touched">Die Strasse ist ein
                    Pflichtfeld.
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Postleitzahl</mat-label>
                <input #plz="ngModel" required matInput type="text" placeholder="Postleitzahl"
                    [(ngModel)]="hitLoginResponse.plz" autocomplete="zipcode" />
                <mat-error class="input-error" *ngIf="plz.errors">Die Postleitzahl ist ein Pflichtfeld.</mat-error>
            </mat-form-field>
        </div>

        <div style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
            ">
            <mat-form-field>
                <mat-label>Ort</mat-label>
                <input #ort="ngModel" required matInput type="text" placeholder="Ort" [(ngModel)]="hitLoginResponse.ort"
                    autocomplete="city" />
                <mat-error class="input-error" *ngIf="ort.errors">Der Ort ist ein Pflichtfeld.</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Ortsteil</mat-label>
                <input matInput type="text" placeholder="Ortsteil" [(ngModel)]="hitLoginResponse.ortsteil"
                    autocomplete="city" />
            </mat-form-field>
        </div>

        <div style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
            ">
            <mat-form-field>
                <mat-label>E-Mail Adresse</mat-label>
                <input required matInput type="email" placeholder="E-Mail" [formControl]="hitEmailCtrl"
                    autocomplete="email" />
                <mat-error class="input-error" *ngIf="hitEmailCtrl.errors">Die E-Mail Adresse ist ein
                    Pflichtfeld.
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Telefon</mat-label>
                <input matInput type="tel" placeholder="Telefon" [(ngModel)]="hitLoginResponse.telefon_1"
                    autocomplete="phone" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Telefon alternativ</mat-label>
                <input matInput type="tel" placeholder="Telefon alternativ" [(ngModel)]="hitLoginResponse.telefon_2"
                    autocomplete="phone" />
            </mat-form-field>
        </div>

        <div style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
            ">
            <mat-form-field>
                <mat-label>Mobiltelefon</mat-label>
                <input matInput type="tel" placeholder="Mobiltelefon" [(ngModel)]="hitLoginResponse.mobil_1"
                    autocomplete="mobilephone" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Mobiltelefon alternativ</mat-label>
                <input matInput type="tel" placeholder="Mobiltelefon alternativ" [(ngModel)]="hitLoginResponse.mobil_2"
                    autocomplete="mobilephone" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Fax</mat-label>
                <input matInput type="tel" placeholder="Fax" [(ngModel)]="hitLoginResponse.fax" autocomplete="fax" />
            </mat-form-field>
        </div>

        <div style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
            ">
            <mat-form-field>
                <mat-label>SGS Nummer</mat-label>
                <input matInput type="text" placeholder="SGS Nummer" [(ngModel)]="hitLoginResponse.sgs_nummer"
                    autocomplete="sgs" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>TSK Nummer</mat-label>
                <input matInput type="text" placeholder="TSK Nummer" [(ngModel)]="hitLoginResponse.tsk_nummer"
                    autocomplete="tsk" />
            </mat-form-field>
        </div>

        <!--Tierarten-->

        <div style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
            ">
            <mat-form-field>
                <mat-label>Tierzahl Sauen</mat-label>
                <input matInput type="number" placeholder="Tierzahl Sauen" [(ngModel)]="hitLoginResponse.tierzahl_sauen"
                    autocomplete="sauen" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Tierzahl Saugferkel</mat-label>
                <input matInput type="number" placeholder="Tierzahl Saugferkel"
                    [(ngModel)]="hitLoginResponse.tierzahl_saugferkel" autocomplete="saugferkel" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Tierzahl Absatzferkel</mat-label>
                <input matInput type="number" placeholder="Tierzahl Absatzferkel"
                    [(ngModel)]="hitLoginResponse.tierzahl_absatzferkel" autocomplete="absatzferkel" />
            </mat-form-field>
        </div>

        <div style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
            ">
            <mat-form-field>
                <mat-label>Tierzahl Mastschweine</mat-label>
                <input matInput type="number" placeholder="Tierzahl Mastschweine"
                    [(ngModel)]="hitLoginResponse.tierzahl_mastschweine" autocomplete="mastschweine" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Tierzahl K&uuml;he</mat-label>
                <input matInput type="number" placeholder="Tierzahl Kühe" [(ngModel)]="hitLoginResponse.tierzahl_kuehe"
                    autocomplete="kuehe" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Tierzahl K&auml;lber</mat-label>
                <input matInput type="number" placeholder="Tierzahl Kälber"
                    [(ngModel)]="hitLoginResponse.tierzahl_kaelber" autocomplete="kaelber" />
            </mat-form-field>
        </div>

        <div style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
            ">
            <mat-form-field>
                <mat-label>Tierzahl Fresser</mat-label>
                <input matInput type="number" placeholder="Tierzahl Fresser"
                    [(ngModel)]="hitLoginResponse.tierzahl_fresser" autocomplete="fresser" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Tierzahl Bullen</mat-label>
                <input matInput type="number" placeholder="Tierzahl Bullen"
                    [(ngModel)]="hitLoginResponse.tierzahl_bullen" autocomplete="bullen" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Tierzahl Mastrinder</mat-label>
                <input matInput type="number" placeholder="Tierzahl Mastrinder"
                    [(ngModel)]="hitLoginResponse.tierzahl_mastrinder" autocomplete="mastrinder" />
            </mat-form-field>
        </div>
    </div>

    <div>
        <h3>Benutzerkonto</h3>

        <div>

            <mat-form-field>
                <mat-label>Vorname</mat-label>
                <input #benutzerVorname="ngModel" required matInput placeholder="Passwort" type="text"
                    [(ngModel)]="hitLoginResponse.benutzer_vorname" autocomplete="current-password" />
                <mat-error class="input-error" *ngIf="benutzerVorname.errors">Der Benutzervorname ist ein
                    Pflichtfeld.
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Nachname</mat-label>
                <input #benutzerNachname="ngModel" required matInput placeholder="Passwort" type="text"
                    [(ngModel)]="hitLoginResponse.benutzer_nachname" autocomplete="current-password" />
                <mat-error class="input-error" *ngIf="benutzerNachname.errors">Der Benutzernachname ist ein
                    Pflichtfeld.
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>E-Mail Adresse</mat-label>
                <input required matInput type="email" placeholder="E-Mail" [formControl]="benutzerEmailCtrl"
                    autocomplete="email" />
                <mat-error class="input-error" *ngIf="benutzerEmailCtrl.errors">Die E-Mail Adresse ist ein
                    Pflichtfeld.
                </mat-error>
            </mat-form-field>
        </div>

        <div style="
                display: flex;
                align-items: center;
                justify-content: flex-start;
            ">
            <mat-form-field>
                <mat-label>Passwort</mat-label>
                <input required matInput placeholder="Passwort" [type]="hidePassword ? 'password' : 'text'"
                    [formControl]="passwortCtrl" autocomplete="current-password" />
                <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{ hidePassword ? "visibility" :
                    "visibility_off" }}
                </mat-icon>
                <mat-error class="input-error" *ngIf="passwortCtrl.hasError('pattern')">Das Passwort muss mindestens 6
                    Zeichen lang
                    sein.
                </mat-error>
                <mat-error class="input-error" *ngIf="passwortCtrl.hasError('mismatch')">Die eingegebenen Passwörter
                    stimmen nicht
                    überein!
                </mat-error>
                <mat-error class="input-error" *ngIf="passwortCtrl.hasError('required')">Das Passwort ist ein
                    Pflichtfeld.
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Passwort wiederholen</mat-label>
                <input required matInput placeholder="Passwort" [type]="hidePassword ? 'password' : 'text'"
                    [formControl]="passwortWdhCtrl" autocomplete="current-password" />
                <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{ hidePassword ? "visibility" :
                    "visibility_off" }}
                </mat-icon>
                <mat-error class="input-error" *ngIf="passwortWdhCtrl.hasError('pattern')">Das Passwort muss mindestens
                    6 Zeichen lang
                    sein.
                </mat-error>
                <mat-error class="input-error" *ngIf="passwortWdhCtrl.hasError('mismatch')">Die eingegebenen Passwörter
                    stimmen nicht
                    überein!
                </mat-error>
                <mat-error class="input-error" *ngIf="passwortWdhCtrl.hasError('required')">Das Passwort ist ein
                    Pflichtfeld.
                </mat-error>
            </mat-form-field>

            <div class="one-row" style="margin: 24px; justify-content: flex-start; width: 25%">
                <div class="mat-form-field registration-form-input agb-field">
                    <mat-checkbox [formControl]="agbCtrl"></mat-checkbox>
                    AGBs
                    akzeptieren (einsehbar unter vetvet.de)
                    <mat-error class="input-error" *ngIf="agbCtrl.hasError('notAccepted')">Die AGBs müssen akzeptiert
                        werden.
                    </mat-error>
                </div>
            </div>
        </div>
    </div>

    <div style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        ">
        <p>Der kostenlose Probezeitraum läuft nach der Registrierung für 90 Tage. Danach wird ihnen bei der ersten
            HIT-Meldung je Tier einmalig 30 Cent in Rechnung gestellt. Wiederholte Zahlungen pro Tier sind
            ausgeschlossen.</p>
        <button style="margin: 12px 0 12px 0; width: 33%" mat-flat-button type="button" color="accent" class="button"
            (click)="register()" [disabled]="isLoading || !agbCtrl.valid || !landwirtHaendlerCtrl.valid">
            Registrieren
        </button>
    </div>
</div>