import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { HttpParams } from '@angular/common/http';
import { MessageService } from '../../../services/message-service/message.service';
import { GruppeStandortGewichtePreise, GruppeTierGewichtPreisDialogInput } from './gruppe-tier-gewicht-preis-dialog';

@Component({
    selector: 'kiene-gruppe-tier-gewicht-preis-dialog',
    templateUrl: './gruppe-tier-gewicht-preis-dialog.component.html',
    styleUrls: ['./gruppe-tier-gewicht-preis-dialog.component.scss']
})
export class GruppeTierGewichtPreisDialogComponent implements OnInit {

    isLoading = false;

    anzahlTiere: number;

    gesamtGewichtCtrl = new FormControl(undefined, [Validators.min(1)]);
    einzeltierGewichtCtrl = new FormControl(undefined, [Validators.min(1)]);
    gesamtpreisCtrl = new FormControl(undefined, [Validators.min(1)]);
    einzelpreisCtrl = new FormControl(undefined, [Validators.min(1)]);
    mwstCtrl = new FormControl(undefined, [Validators.required]);

    constructor(@Inject(MAT_DIALOG_DATA) public input: GruppeTierGewichtPreisDialogInput,
        public dialogRef: MatDialogRef<GruppeTierGewichtPreisDialogComponent>,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.ladeTiere();

        this.gesamtGewichtCtrl.valueChanges.subscribe({
            next: value => {
                if (value > 0 && this.anzahlTiere > 0) {
                    const v = Math.round((value / this.anzahlTiere) * 100) / 100.0;
                    this.einzeltierGewichtCtrl.setValue(v, { emitEvent: false });
                }
            }
        });

        this.einzeltierGewichtCtrl.valueChanges.subscribe({
            next: value => {
                if (value > 0 && this.anzahlTiere > 0) {
                    const v = Math.round((value * this.anzahlTiere) * 100) / 100.0;
                    this.gesamtGewichtCtrl.setValue(v, { emitEvent: false });
                }
            }
        });

        this.gesamtpreisCtrl.valueChanges.subscribe({
            next: value => {
                if (value > 0 && this.anzahlTiere > 0) {
                    const v = Math.round((value / this.anzahlTiere) * 100) / 100.0;
                    this.einzelpreisCtrl.setValue(v, { emitEvent: false });
                }
            }
        });

        this.einzelpreisCtrl.valueChanges.subscribe({
            next: value => {
                if (value > 0 && this.anzahlTiere > 0) {
                    const v = Math.round((value * this.anzahlTiere) * 100) / 100.0;
                    this.gesamtpreisCtrl.setValue(v, { emitEvent: false });
                }
            }
        });
    }

    save() {
        const output = new GruppeStandortGewichtePreise();
        let url = undefined;

        if (this.input?.ausstallen === true) {
            output.gs_id = this.input?.gruppeStandort?.gs_id;
            output.tierliste_id = this.input?.tierliste?.tierliste_id;
            output.abgangsgewicht = this.einzeltierGewichtCtrl.value;
            output.kp_abgangspreis = this.einzelpreisCtrl.value;
            output.kp_abgangsmwstsatz = this.mwstCtrl.value;

            url = this.apiBaseUrl + 'gruppe_tier/update_ausstalldaten.php';
        } else if (this.input?.einstallen === true) {
            output.gs_id = this.input?.gruppeStandort?.gs_id;
            output.viehhaendler_id = this.input?.viehhaendler?.viehhaendler_id;
            output.einstalldatums = this.input?.einstalldatums;
            output.einstallgewicht = this.einzeltierGewichtCtrl.value;
            output.kp_einstallpreis = this.einzelpreisCtrl.value;
            output.kp_einstallmwstsatz = this.mwstCtrl.value;

            url = this.apiBaseUrl + 'gruppe_tier/update_einstalldaten.php';
        }

        if (url != undefined) {
            this.api.post(url, output).subscribe({
                next: () => {
                    this.messageService.infoMessage('Die Eingaben wurden erfolgreich gespeichert');
                    this.dialogRef.close(true);
                },
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        }
    }

    private ladeTiere() {
        let params = undefined;
        if (this.input?.einstallen === true) {
            params = new HttpParams().set('gruppe_id', this.input?.gruppeStandort?.gruppe_id?.toString());
            params = params.set('viehhaendler_id[]', this.input?.viehhaendler?.viehhaendler_id?.toString());
            for (const ed of this.input?.einstalldatums) {
                params = params.append('einstalldatum[]', formatDate(ed, 'yyyy-MM-dd', 'de', 'Europe/Berlin'));
            }
        } else if (this.input?.ausstallen === true) {
            params = new HttpParams().set('tierliste_id', this.input?.tierliste?.tierliste_id?.toString());
            params = params.set('gruppe_id', this.input?.gruppeStandort?.gruppe_id?.toString());
        }
        if (params != undefined) {
            this.isLoading = true;
            this.api.getAll(this.apiBaseUrl + 'gruppe_tier/read_kundenportal.php', params).subscribe({
                next: response => {
                    this.anzahlTiere = response.records.length;
                    this.isLoading = false;
                },
                error: err => {
                    this.messageService.errorMessage('Fehler', err);
                    this.isLoading = false;
                }
            });
        }
    }
}
