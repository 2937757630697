import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Clipboard} from '@angular/cdk/clipboard';

@Component({
    selector: 'kiene-hit-pin-anzeigen-dialog',
    templateUrl: './hit-pin-anzeigen-dialog.component.html',
    styleUrls: ['./hit-pin-anzeigen-dialog.component.scss']
})
export class HitPinAnzeigenDialogComponent implements OnInit {

    vvvo: string;
    pin: string;

    constructor(@Inject(MAT_DIALOG_DATA) private data,
                public dialogRef: MatDialogRef<HitPinAnzeigenDialogComponent>,
                private clipboard: Clipboard) {
    }

    ngOnInit(): void {
        this.vvvo = this.data?.vvvo;
        this.pin = this.data?.pin;
    }

    copyVVVO() {
        this.clipboard.copy(this.vvvo);
    }

    copyPin() {
        this.clipboard.copy(this.pin);
    }

}
