import {NgModule} from '@angular/core';

import {AdministrationRoutingModule} from './administration-routing.module';
import {UserComponent} from './user/user.component';
import {UserDetailsComponent} from './user/user-details/user-details.component';
import {UserService} from './user/user.service';
import {UserroleComponent} from './userrole/userrole.component';
import {UserroleService} from './userrole/userrole.service';
import {VetComponent} from './vet/vet.component';
import {VetDetailsDialogComponent} from './vet/vet-details-dialog/vet-details-dialog.component';
import {
    VetVetGroupDetailsDialogComponent
} from './vet/vet-vet-group-details-dialog/vet-vet-group-details-dialog.component';
import {VetService} from './vet/vet.service';
import {VetGroupComponent} from './vet-group/vet-group.component';
import {VetGroupService} from './vet-group/vet-group.service';
import {AdministrationComponent} from './administration.component';
import {ClientService} from './client/client.service';
import {CompanyGroupService} from './company-group/company-group.service';
import {PermissionOverviewService} from './permission-overview/permission-overview.service';
import {ChangeCurrentClientService} from './client/change-current-client/change-current-client.service';
import {KieneTableModule} from '../components/kiene-table/kiene-table.module';
import {GruppenverwaltungModule} from '../documents/gruppenverwaltung/gruppenverwaltung.module';
import {InventurVerwaltungComponent} from './inventur-verwaltung/inventur-verwaltung.component';
import {
    InventurCreateDialogComponent
} from './inventur-verwaltung/inventur-create-dialog/inventur-create-dialog.component';
import {InventurEditDialogComponent} from './inventur-verwaltung/inventur-edit-dialog/inventur-edit-dialog.component';
import {
    InventurDeleteDialogComponent
} from './inventur-verwaltung/inventur-delete-dialog/inventur-delete-dialog.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {
    InventurActiceNoticeDialogComponent
} from './inventur-verwaltung/inventur-actice-notice-dialog/inventur-actice-notice-dialog.component';
import {DeleteButtonModule} from '../components/delete-button/delete-button.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';
import {BetriebstypenComponent} from './betriebstypen/betriebstypen.component';
import {SelectorModule} from '../components/selector/selector.module';
import {
    BetriebstypDetailsDialogComponent
} from './betriebstypen/betriebstyp-details-dialog/betriebstyp-details-dialog.component';
import {
    PreislistePdfDownloadDialogComponent
} from './company-group/preisliste-pdf-download-dialog/preisliste-pdf-download-dialog.component';
import {
    CompanyGroupArticleDialogComponent
} from './company-group/company-group-article-dialog/company-group-article-dialog.component';
import {ArticleScanAutocompleteModule} from '../components/article-scan-autocomplete/article-scan-autocomplete.module';
import {HitPinAnzeigenDialogComponent} from '../components/hit-pin-anzeigen-dialog/hit-pin-anzeigen-dialog.component';
import {CommonModule} from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatChipsModule} from '@angular/material/chips';
import {MatToolbarModule} from '@angular/material/toolbar';
import {KieneAutocompleteModule} from '../components/kiene-autocomplete/kiene-autocomplete.module';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {KieneClientAutocompleteModule} from '../components/client-autocomplete/client-autocomlete.module';
import {VerkaufspreisSelectorModule} from '../components/verkaufspreis-selector/verkaufspreis-selector.module';
import {ComponentsModule} from '../components/components.module';
import {UpdateVetGroupDialogComponent} from './vet-group/update-vet-group-dialog/update-vet-group-dialog.component';
import {AddVetGroupDialogComponent} from './vet-group/add-vet-group-dialog/add-vet-group-dialog.component';
import {PermissionOverviewComponent} from './permission-overview/permission-overview.component';
import {MatTableModule} from '@angular/material/table';
import {ProduktionsabschnitteComponent} from './betriebsarten/produktionsabschnitte/produktionsabschnitte.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {NewPasswordModule} from '../../../projects/kiene-core/src/lib/components/new-password/new-password.module';
import {BenutzerModule} from '../../../projects/kiene-core/src/lib/components/stammdaten/benutzer/benutzer.module';
import {DirectivesModule} from '../../../projects/kiene-core/src/lib/directives/directives.module';
import {
    BetriebsartPreiseUndRabattDialogComponent
} from './betriebsarten/betriebsart-preise-und-rabatt-dialog/betriebsart-preise-und-rabatt-dialog.component';
import {
    BetriebsartenTierartenDialogComponent
} from './betriebsarten/betriebsarten-tierarten-dialog/betriebsarten-tierarten-dialog.component';
import {VetGroupDetailsDialogComponent} from './vet-group/vet-group-details-dialog/vet-group-details-dialog.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {KieneDirectivesModule} from '../directives/kiene-directives.module';
import {
    TextbausteinGeneralDialogComponent
} from './text-baustein/textbaustein-general-dialog/textbaustein-general-dialog.component';
import {TextBausteinComponent} from './text-baustein/text-baustein.component';

@NgModule({
    declarations: [
        AdministrationComponent,
        PermissionOverviewComponent,
        UserComponent,
        UserDetailsComponent,
        UserroleComponent,
        VetComponent,
        VetDetailsDialogComponent,
        VetVetGroupDetailsDialogComponent,
        VetGroupComponent,
        InventurVerwaltungComponent,
        InventurCreateDialogComponent,
        InventurEditDialogComponent,
        InventurDeleteDialogComponent,
        InventurActiceNoticeDialogComponent,
        BetriebstypenComponent,
        BetriebstypDetailsDialogComponent,
        PreislistePdfDownloadDialogComponent,
        CompanyGroupArticleDialogComponent,
        HitPinAnzeigenDialogComponent,
        UpdateVetGroupDialogComponent,
        AddVetGroupDialogComponent,
        ProduktionsabschnitteComponent,
        BetriebsartPreiseUndRabattDialogComponent,
        BetriebsartenTierartenDialogComponent,
        VetGroupDetailsDialogComponent,
        TextBausteinComponent,
        TextbausteinGeneralDialogComponent,
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatCardModule,
        MatMenuModule,
        MatChipsModule,
        MatToolbarModule,
        MatExpansionModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatPaginatorModule,
        KieneAutocompleteModule,
        KieneTableModule,
        AdministrationRoutingModule,
        GruppenverwaltungModule,
        DeleteButtonModule,
        SelectorModule,
        ArticleScanAutocompleteModule,
        KieneClientAutocompleteModule,
        VerkaufspreisSelectorModule,
        ComponentsModule,
        MatTableModule,
        DragDropModule,
        NewPasswordModule,
        BenutzerModule,
        DirectivesModule,
        MatGridListModule,
        KieneDirectivesModule
    ],
    providers: [
        UserService,
        UserroleService,
        VetService,
        VetGroupService,
        ClientService,
        CompanyGroupService,
        PermissionOverviewService,
        ChangeCurrentClientService,
    ],
})
export class AdministrationModule {
}
