import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../services/message.service';
import { InvoicePosition } from '../rechnung';

@Component({
  selector: 'kiene-delete-rechnung-position-dialog',
  templateUrl: './delete-rechnung-position-dialog.component.html',
  styleUrls: ['./delete-rechnung-position-dialog.component.css']
})
export class DeleteRechnungPositionDialogComponent implements OnInit {

  position: InvoicePosition;
  gutschriftCtrl = new UntypedFormControl(false);

  constructor(public dialogRef: MatDialogRef<DeleteRechnungPositionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private api: KieneTableService,
    private messageService: MessageService) { 
      this.position = this.data.position;
    }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  delete(){
    this.position.gutschrift = this.gutschriftCtrl.value ? 1 : 0;
    this.api.deleteGeneric(environment.BASE_URI + 'rechnung/delete_artikel.php', this.position).subscribe(response => {
      this.messageService.infoMessage('Die Position wurde erfolgreich gelöscht!');
      this.close();
    }, error => {
      this.messageService.errorMessage('Die Position konnte nicht gelöscht werden!', error);
    });
  }

}
