import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../services/message.service';
import { MeldungService } from '../../meldungen/meldung.service';

@Component({
  selector: 'kiene-meldungen-generieren-dialog',
  templateUrl: './meldungen-generieren-dialog.component.html',
  styleUrls: ['./meldungen-generieren-dialog.component.css']
})
export class MeldungenGenerierenDialogComponent implements OnInit {

  radioCtrl = new UntypedFormControl(1);
  datumCtrl = new UntypedFormControl(new Date(), [Validators.required]);
  isSaving = false;

  constructor(public dialogRef: MatDialogRef<MeldungenGenerierenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private api: KieneTableService,
    private messageService: MessageService,
    private meldungService: MeldungService) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.isSaving = true;
    const choice = this.radioCtrl.value;
    let obj = {
      bis: null
    }

    if (choice === 2) {
      obj.bis = this.datumCtrl.value;
    }

    this.api.post(environment.BASE_URI + 'meldung/generieren.php', obj, null).subscribe(response => {
      this.meldungService.jetztAlleMeldungenSenden().subscribe(responseSenden => {
        this.messageService.infoMessage('Meldungen wurden erfolgreich generiert und werden jetzt nacheinander an HIT/QS versendet. Dies kann einige Minuten dauern...');
        this.isSaving = false;
        this.close();
      }, error => {
        this.messageService.errorMessage('Meldungen wurden erfolgreich generiert aber konnten nicht verschickt werden!', error);
        this.isSaving = false;
        this.close();
      });
    }, error => {
      this.messageService.errorMessage('Es ist ein Fehler beim Generieren der Meldungen aufgetreten!', error);
      this.isSaving = false;
    });

  }

}
