<div>

    <div class="one-row">
        <p>f&uuml;r <span style="font-weight:bold; font-size: 1.2em">{{kundengutschrift.kunde}}</span></p>
        <span class="spacer"></span>
        <mat-form-field>
            <mat-label>Datum der Gutschrift</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="gutschriftDatumCtrl">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>


    <fieldset *ngIf="!kundengutschrift.primanota_id" style="border-radius: 6px;">
        <legend>Position hinzuf&uuml;gen</legend>

        <div class="one-row">

            <kiene-article-scan-autocomplete #artikelInput style="width: 40%; margin-right: 12px;"
                [kundeId]="kundengutschrift.kunde_id" (selectedMaterial)="setSelectedMaterial($event)">
            </kiene-article-scan-autocomplete>

            <mat-form-field style="width: 10%; margin-right: 12px;">
                <mat-label>Menge</mat-label>
                <input #mengeInput="matInput" matInput type="number" [formControl]="mengeCtrl">
            </mat-form-field>

            <mat-form-field style="width: 15%; margin-right: 12px;">
                <mat-label>Einzelpreis</mat-label>
                <input #einzelpreisInput="matInput" matInput type="number" [formControl]="einzelpreisCtrl">
                <span matSuffix>&euro;</span>
            </mat-form-field>

            <mat-form-field style="width: 15%; margin-right: 12px;">
                <mat-label>Gesamtpreis</mat-label>
                <input #preisInput="matInput" matInput type="number" [formControl]="preisCtrl">
                <span matSuffix>&euro;</span>
            </mat-form-field>

            <button mat-flat-button color="accent" (click)="savePosition()">{{currentEditPosition >= 0 ? 'Ändern' :
                'Hinzufügen'}}</button>

        </div>

    </fieldset>

    <table class="phito-table" style="margin-top: 24px;">
        <thead>
            <tr>
                <th>Pos.</th>
                <th>Artikel</th>
                <th style="text-align: right;">Menge</th>
                <th style="text-align: right;">Einzelpreis</th>
                <th style="text-align: right;">Gesamtpreis</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr style="cursor: pointer;" *ngFor="let pos of kundengutschrift.positionen; let i = index"
                (click)="editPosition(i)" [style.background-color]="currentEditPosition === i ? '#CCC' : '#FFF'">
                <td>{{(i+1)}}</td>
                <td>{{pos.artikel_me}}</td>
                <td style="text-align: right;">{{pos.artikelmenge}}</td>
                <td style="text-align: right;">{{pos.einzelpreis | number: '1.2-2'}} &euro;</td>
                <td style="text-align: right;">{{pos.gesamtpreis | number: '1.2-2'}} &euro;</td>
                <td>
                    <kiene-delete-button *ngIf="!kundengutschrift.primanota_id" (delete)="deletePosition(i)">
                    </kiene-delete-button>
                </td>
            </tr>
            <tr>
                <td></td>
                <td colspan="3" style="text-align: right;">Preis Gutschrift:</td>
                <td style="font-weight: bold; text-align: right;">{{gesamtpreisGutschrift | number: '1.2-2'}} &euro;
                </td>
                <td></td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="!kundengutschrift.primanota_id" class="one-row" style="margin-top: 24px;">
        <span class="spacer"></span>
        <button mat-flat-button color="accent" (click)="gutschriftSpeichern()">Gutschrift speichern</button>
    </div>

</div>