import { HeimtierBelege } from "./heimtier-belege";
import { API_BASE_URL_SERVICE } from "../../../../projects/kiene-core/src/lib/kiene-core.config";
import { KieneTableService } from "../../components/kiene-table/kiene-table.service";
import {
    ConditionSetting,
    ConditionTableColumn,
    DateTableColumn,
    TableColumn
} from "../../components/kiene-table/table-column";
import { TableDescriptor, ToolbarButton } from "../../components/kiene-table/table-descriptor";
import { Condition, Operator, TableOption, TableOptionEvent } from "../../components/kiene-table/table-option";
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {
    ChangeCurrentClientService
} from '../../administration/client/change-current-client/change-current-client.service';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';

@Component({
    selector: 'kiene-heimtier-belege-table',
    templateUrl: './heimtier-belege-table.component.html',
    styleUrls: ['./heimtier-belege-table.component.scss']
})
// erzeugt mit KieneTableTemplateTS.ts.template
export class HeimtierBelegeTableComponent implements OnInit {

    tableDescriptor = new TableDescriptor();

    @Input() kunde_id: number;

    constructor(
        private dialog: MatDialog,
        protected router: Router,
        private tableService: KieneTableService,
        private api: KieneBackendApiService,
        private lss: LocalStorageService,
        private changeCurrentClientService: ChangeCurrentClientService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit(): void {
        this.configureTableDescriptor();
    }

    getColumns(): TableColumn[] {
        const columns: TableColumn[] = [];

        columns.push(new TableColumn('ht_beleg_id', 'Beleg ID'));
        columns.push(new TableColumn('beleg_nr', 'BelegNr'));
        columns.push(new TableColumn('kunde', 'kunde'));
        columns.push(new TableColumn('tierarzt', 'Tierarzt'));
        columns.push(new DateTableColumn('datum', 'Datum', 'dd.MM.YYYY'));
        columns.push(new DateTableColumn('behandlung_seit', 'In Behandlung seit'));
        columns.push(new TableColumn('pdf_id', 'PDF', 'pdf'));
        columns.push(
            new ConditionTableColumn(
                'rechnung_id', 'Rechn.',
                [
                    new ConditionSetting(
                        [new Condition('rechnung_id', Operator.EXISTIERT, null)],
                        { icon: 'attach_money' }
                    ),
                ],
                {
                    sortFields: ['rechnung_id'],
                }
            )
        );
        columns.push(new TableColumn('status', 'Status'));

        return columns;
    }

    getOptions(): TableOption[] {
        const options: TableOption[] = [];
        options.push(
            new TableOption('delete', 'Löschen', 'delete', 7034,
                { conditions: [new Condition('status_id', Operator.GLEICH, 221)] })
        );
        return options;
    }

    createOrEditHeimtierBelege(input?: HeimtierBelege) {
        if (!this.lss.getCurrentClient()) {
            this.changeCurrentClientService.openChangeDialog('createOrEditHeimtierBelege').subscribe({
                next: result => {
                    this.createNewHeimtierBelege();
                }
            });
        } else {
            this.createNewHeimtierBelege();
        }
    }

    catchOptionEvent(event: TableOptionEvent) {
        const beleg: HeimtierBelege = event.value;
        if (event.name === 'add') {
            this.createOrEditHeimtierBelege();
        } else if (event.name === 'details') {
            this.router.navigate(['heimtiere', 'belege', beleg?.ht_beleg_id]);
        }
    }

    private configureTableDescriptor(): void {
        this.tableDescriptor.headline = 'Heimtier Belege';
        this.tableDescriptor.initialSortDirection = 'desc';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'heimtier_beleg/';
        this.tableDescriptor.initialSortColumn = 'beleg_nr';
        this.tableDescriptor.nameOfIdField = 'ht_beleg_id';
        this.tableDescriptor.searchPlaceholder = 'Heimtier Belege durchsuchen';
        this.tableDescriptor.uniqueIdentifier = 'heimtierbelege';
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDownloads = false;
        this.tableDescriptor.sendCurrentClient = true;
        this.tableDescriptor.showDetailsAsOption = true;

        this.tableDescriptor.columns = this.getColumns();
        this.tableDescriptor.options = this.getOptions();
        this.tableDescriptor.toolbarButtons = this.getToolbarButtons();

        this.tableDescriptor.tooltips['add_button'] = 'Neuen Heimtier Beleg anlegen';

        this.tableDescriptor.permissionCreate = 7032;
        this.tableDescriptor.permissionUpdate = 7033;
    }

    private getToolbarButtons(): ToolbarButton[] {
        const toolbarButtons = [];
        return toolbarButtons;
    }


    private createNewHeimtierBelege() {
        const beleg = new HeimtierBelege();
        beleg.kunde_id = this.lss.getCurrentClient().kunde_id;
        this.api.post(this.apiBaseUrl + 'heimtier_beleg/create.php', beleg).subscribe({
            next: response => {
                this.router.navigate(['heimtiere', 'belege', response.ht_beleg_id]).then();
            }
        });
    }
}





