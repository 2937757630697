<li style="margin-bottom: 6px;">
    <div class="info-item">
        <div class="one-row">
            <p class="info-item-title">{{ key }}</p>
        </div>
        <div class="one-row info-item-value" [ngClass]="{'bold': bold}">
            <mat-icon *ngIf="icon" style="margin-right: 12px;">{{ icon }}</mat-icon>
            <span>{{ value }}</span>
            <span class="spacer"></span>
            <button *ngIf="actionButtonIcon" mat-icon-button color="accent" (click)="actionClicked($event)"
                [disabled]="actionButtonDisabled">
                <mat-icon>{{ actionButtonIcon }}</mat-icon>
            </button>
        </div>
    </div>
</li>