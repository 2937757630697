import {AfterViewInit, Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ArtikelMenge, Tourenplan} from '../tourenplan';
import {Material} from '../../../warehouse/material/material';
import {UntypedFormControl} from '@angular/forms';
import {
    ArticleScanAutocompleteComponent,
    ArticleScanSelectedEvent,
} from '../../article-scan-autocomplete/article-scan-autocomplete.component';
import {CompanyGroup} from '../../../administration/company-group/company-group';
import {KieneTableService} from '../../kiene-table/kiene-table.service';
import {
    Behandlungsanfrage,
    BehandlungsanfragePosition,
} from '../../../documents/behandlungsanfrage/behandlungsanfrage';
import {MessageService} from '../../../services/message.service';
import {environment} from '../../../../environments/environment';
import {BehandlungsanfrageService} from '../../../documents/behandlungsanfrage/behandlungsanfrage.service';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {CompanyGroupAutocompleteComponent} from '../../company-group-autocomplete/company-group-autocomplete.component';
import {MatInput} from '@angular/material/input';
import {BestellungInternPosition} from '../../../wa-wi/bestellung-intern/bestellung-intern-position';
import {CalendarWeek, KWDialogComponent} from '../kwdialog/kwdialog.component';
import {KieneUtils} from '../../../../../projects/kiene-core/src/lib/utils/KieneUtils';
import {AddArticlePositionDialogComponent} from '../add-article-position-dialog/add-article-position-dialog.component';
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-add-tour-dialog',
    templateUrl: './add-tour-dialog.component.html',
    styleUrls: ['./add-tour-dialog.component.scss'],
})
export class AddTourDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('articleAuto') articleAuto: ArticleScanAutocompleteComponent;
    @ViewChild('identifikation') identifikation: MatInput;
    @ViewChild('menge') menge: MatInput;
    @ViewChild('anzahlTiere') anzahlTiere: MatInput;
    @ViewChild('companyGroupAuto')
    companyGroupAuto: CompanyGroupAutocompleteComponent;

    @Input('tour') tour: Tourenplan;

    artikelMenge: ArtikelMenge[] = [];
    currentArtikelMenge = new ArtikelMenge();
    mengeCtrl = new UntypedFormControl();
    zieldatumFromCtrl = new UntypedFormControl(new Date());
    zieldatumToCtrl = new UntypedFormControl();
    // zieldatumCtrl = new FormControl(new Date());
    currentCompanyGroup: CompanyGroup = new CompanyGroup();
    currentClient: Client = new Client();
    notizCtrl = new UntypedFormControl();
    apothekeCtrl = new UntypedFormControl(false);
    identifikationCtrl = new UntypedFormControl();
    anzahlTiereCtrl = new UntypedFormControl();

    showKunde = true;

    isKunde = true;

    constructor(
        public dialogRef: MatDialogRef<AddTourDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private api: KieneTableService,
        private notification: MessageService,
        private behandlungsanfrageService: BehandlungsanfrageService,
        private session: LocalStorageService,
        public dialog: MatDialog
    ) {
        if (this.data) {
            this.tour = this.data.tour;
            if (this.data.positionen) {
                const positionen: BestellungInternPosition[] =
                    this.data.positionen;
                for (const p of positionen) {
                    const am = new ArtikelMenge();
                    const m = new Material();
                    m.artikel_id = p.artikel_id;
                    m.artikel_me = p.artikel_me;
                    m.bezeichnung = p.artikel;
                    am.article = m;
                    am.anzahl_tiere = p.anzahl_tiere;
                    am.identifikation = p.identifikation;
                    am.menge = p.artikelmenge;
                    this.artikelMenge.push(am);

                    const d = new Date();
                    d.setDate(d.getDate() + p.tourenplan_tage);
                    this.zieldatumFromCtrl.setValue(d);
                }
            }
            if (this.data.client) {
                this.currentClient = this.data.client;
                this.showKunde = false;
            }
        }
    }

    ngOnInit() {
        this.isKunde = this.session.isCurrentUserKunde();

        if (this.tour) {
            this.setArtikelMenge(this.tour);

            this.zieldatumFromCtrl.setValue(this.tour.zieldatum);
            this.zieldatumToCtrl.setValue(this.tour.zieldatum_bis);

            this.apothekeCtrl.setValue(this.tour.apotheke === 1 ? true : false);

            this.notizCtrl.setValue(this.tour.bezeichnung);
        }
    }

    ngAfterViewInit() {
        if (this.tour) {
            this.currentCompanyGroup = new CompanyGroup();
            this.currentCompanyGroup.bezeichnung = this.tour.unternehmensgruppe;
            this.currentCompanyGroup.unternehmensgruppe_id =
                this.tour.unternehmensgruppe_id;

            this.currentClient = new Client();
            this.currentClient.kunde_id = this.tour.kunde_id;
            this.currentClient.kunde_nr_intern = this.tour.kunde_nr_intern;
            this.currentClient.bezeichnung_final = this.tour.kunde;
            this.currentClient.zusatz_final = this.tour.zusatz;
        }
        setTimeout(() => {
            this.companyGroupAuto.setFocus();
        }, 10);
    }

    setUnternehmensgruppe(companyGroup: CompanyGroup) {
        this.currentCompanyGroup = companyGroup;
    }

    setClient(c: Client) {
        this.currentClient = c;
    }

    setSelectedMaterial(event: ArticleScanSelectedEvent) {
        this.currentArtikelMenge.article = event.article;
        this.menge?.focus();
    }

    setFocusToAnzahlTiere() {
        this.anzahlTiere?.focus();
    }

    setFocusToIdentification() {
        this.identifikation?.focus();
    }

    addPositionToList() {
        if (this.currentArtikelMenge.article) {
            this.currentArtikelMenge.menge = this.mengeCtrl.value;
            this.currentArtikelMenge.anzahl_tiere = this.anzahlTiereCtrl.value;
            this.currentArtikelMenge.identifikation =
                this.identifikationCtrl.value;
            this.artikelMenge.push(this.currentArtikelMenge);
            this.currentArtikelMenge = new ArtikelMenge();
            this.mengeCtrl.setValue(null);
            this.anzahlTiereCtrl.setValue(null);
            this.identifikationCtrl.setValue(null);
            this.articleAuto.clear();
            this.articleAuto.setFocus();
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    save() {
        const b = new Behandlungsanfrage();
        b.status_id = this.isKunde ? 2 : 3;

        if (this.tour && this.tour.bestellung_id) {
            b.bestellung_id = this.tour.bestellung_id;
        }

        // kunde hat priorität gegenüber unternehmensgruppe
        if (this.currentClient && this.currentClient.kunde_id) {
            b.kunde_id = this.currentClient.kunde_id;
        } else {
            if (
                this.currentCompanyGroup &&
                this.currentCompanyGroup.unternehmensgruppe_id
            ) {
                b.unternehmensgruppe_id =
                    this.currentCompanyGroup.unternehmensgruppe_id;
            }
        }

        b.bezeichnung = this.notizCtrl.value;
        b.zieldatum = this.zieldatumFromCtrl.value;
        b.zieldatum_bis = this.zieldatumToCtrl.value;
        b.positionen = this.getPositionen();

        b.apotheke = this.apothekeCtrl.value ? 1 : 0;

        this.api
            .updateEntity(environment.BASE_URI + 'behandlungsanfrage/update.php', b)
            .subscribe(
                (response) => {
                    this.notification.infoMessage(
                        'Die Anfrage wurde erfolgreich gespeichert!'
                    );
                    this.dialogRef.close();
                },
                (error) => {
                    this.notification.errorMessage(
                        'Die Tour konnte nicht gespeichert werden!',
                        error
                    );
                }
            );
    }

    getPositionen(): BehandlungsanfragePosition[] {
        const positionen: BehandlungsanfragePosition[] = [];

        for (const a of this.artikelMenge) {
            const b = new BehandlungsanfragePosition();
            b.artikel_id = a.article.artikel_id;
            b.artikelmenge = a.menge;
            b.identifikation = a.identifikation;
            b.anzahl_tiere = a.anzahl_tiere;
            b.ba_id = a.ba_id;
            positionen.push(b);
        }

        return positionen;
    }

    setArtikelMenge(b: Behandlungsanfrage) {
        if (b.positionen) {
            this.artikelMenge = [];
            for (const p of b.positionen) {
                const am = new ArtikelMenge();
                const m = new Material();
                m.artikel_me = p.artikel_me;
                m.artikel_id = p.artikel_id;
                am.article = m;
                am.menge = p.artikelmenge;
                am.anzahl_tiere = p.anzahl_tiere;
                am.identifikation = p.identifikation;
                am.ba_id = p.ba_id;
                this.artikelMenge.push(am);
            }
        }
    }

    removeArtikel(a: ArtikelMenge) {
        if (!a) {
            return;
        }
        const index = this.artikelMenge.indexOf(a);
        this.artikelMenge.splice(index, 1);
    }

    editArtikel(am: ArtikelMenge) {
        const dialogRef = this.dialog.open(AddArticlePositionDialogComponent, {
                data: am
            }
        );
        dialogRef.afterClosed().subscribe({
            next: result => {
                if (result) {
                    am = result;
                }
            }
        });
    }

    openKWDialog() {
        const dialogRef = this.dialog.open(KWDialogComponent);
        dialogRef.afterClosed().subscribe({
            next: (kw: CalendarWeek) => {
                const monday = KieneUtils.getDateOfMonday(
                    kw.weekNumber,
                    kw.year
                );
                const sunday = KieneUtils.addDays(monday, 6);

                this.zieldatumFromCtrl.setValue(monday);
                this.zieldatumToCtrl.setValue(sunday);
            },
        });
    }
}
