import { ConfirmDialogComponent } from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AcceptBookingWithDriverDialogInput, AcceptBookingWithDriverDialogResult } from './accep-booking-with-driver-dialog-data';
import { FormControl, Validators } from '@angular/forms';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import { MessageService } from '../../services/message.service';
import { KieneBackendApiService } from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'kiene-accept-booking-with-driver-dialog',
  templateUrl: './accept-booking-with-driver-dialog.component.html',
  styleUrls: ['./accept-booking-with-driver-dialog.component.scss']
})
export class AcceptBookingWithDriverDialogComponent implements OnInit {

  driverFormControl = new FormControl<number>(0, Validators.required);
  acceptButtonEnabled = false;
  drivers: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AcceptBookingWithDriverDialogInput,
    private api: KieneBackendApiService,
    @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.acceptButtonEnabled = false;
    this.driverFormControl.valueChanges.subscribe((value) => {
      this.updateState(value);
    });
    this.readMyDrivers();
  }

  updateState(value){
    if(value != 0){
      this.acceptButtonEnabled = true;
    } else {
      this.acceptButtonEnabled = false;
    }
  }

  cancel() {
      const result = new AcceptBookingWithDriverDialogResult();
      result.ok = false;
      this.dialogRef.close(result);
  }

  ok() {
    if(this.driverFormControl.valid){
      const result = new AcceptBookingWithDriverDialogResult();
      result.ok = true;
      result.confirmed = true;
      result.driver_id = this.driverFormControl.value;
      this.dialogRef.close(result);
    } else {
      this.messageService.infoMessage('Kein Gültiger Fahrer gewählt!');
    }
  }

  readMyDrivers(){
    this.api
      .get(
          `${this.apiBaseUrl}benutzer/read_meine_benutzer.php`)
      .subscribe({
          next: (response: any) => {
            console.log(response);
            //TODO update drivers
            response.records.forEach(benutzer => {
              if(benutzer.benutzerrollen.filter(rolle => rolle.benutzerrolle_id == 21).length > 0){
                this.drivers.push(benutzer);
              }
            });
          },
          error: err => {
              this.messageService.errorMessage('Buchung konnte nicht geladen werden!', err);
          }
      });
  }
}
