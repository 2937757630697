import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {Gruppe} from '../../../gruppen/classes/gruppe';
import {UntypedFormControl} from '@angular/forms';
import {MessageService} from '../../../../services/message-service/message.service';
import {StammdatenService} from '../../../stammdaten/stammdaten.service';
import {Haendler} from '../../../stammdaten/haendler/haendler';
import {TierFilterSettings} from '../gruppe-tier-filter-dialog/classes/tier-filter-settings';
import {HttpParams} from '@angular/common/http';

@Component({
    selector: 'kiene-gruppe-tier-viehhaendler-filter',
    templateUrl: './gruppe-tier-viehhaendler-filter.component.html',
    styleUrls: ['./gruppe-tier-viehhaendler-filter.component.scss']
})
export class GruppeTierViehhaendlerFilterComponent implements OnInit, OnDestroy, OnChanges {

    haendlerGruppeSubscription: Subscription;
    isLoading = true;
    label = 'Lädt...';

    @Input('gruppe') selectedGruppe: Gruppe;
    @Input('filter') filter: TierFilterSettings;
    @Input('haendler') haendler: Haendler[] = [];
    @Input('hideOnEmptyList') hideOnEmptyList = false;
    @Output('selectedHaendler') selectedHaendler: EventEmitter<Haendler[]> = new EventEmitter<Haendler[]>();
    selectedHaendlerCtrl: UntypedFormControl = new UntypedFormControl(null);
    haendlerFuerGruppe: Haendler[] = [];

    constructor(private messageService: MessageService, private stammdatenService: StammdatenService) {
    }

    ngOnDestroy(): void {
        this.haendlerGruppeSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.loadTierHaendlerFuerGruppe();

        this.selectedHaendlerCtrl.setValue(this.haendler);

        // this.selectedHaendlerCtrl.valueChanges.subscribe(data => {
        //     this.selectedHaendler.emit(data);
        // });
    }

    ngOnChanges() {
        if (this.selectedGruppe) {
            this.loadTierHaendlerFuerGruppe();
        }
    }

    resetHaendlerCtrl($event: MouseEvent) {
        $event.stopPropagation();
        this.selectedHaendlerCtrl.setValue(undefined);
    }

    compareHaendler(a: Haendler, b: Haendler) {
        return a && b && a.viehhaendler_id === b.viehhaendler_id;
    }

    selectionChanged() {
        this.selectedHaendler.emit(this.selectedHaendlerCtrl.value);
    }

    public update() {
        this.loadTierHaendlerFuerGruppe();
    }

    private loadTierHaendlerFuerGruppe() {
        this.isLoading = true;
        if (this.selectedGruppe) {
            let params = new HttpParams();
            if (this.filter && this.filter.einstalldatum && this.filter.einstalldatum.length > 0) {
                for (const e of this.filter.einstalldatum) {
                    params = params.append('einstalldatum[]', e.einstalldatum.toString());
                }
            }
            this.haendlerGruppeSubscription?.unsubscribe();
            this.haendlerGruppeSubscription = this.stammdatenService
                .ladeViehhaendler(this.selectedGruppe.gruppe_id, params)
                .subscribe({
                    next: (result) => {
                        this.selectedHaendlerCtrl.enable();
                        this.isLoading = false;
                        this.haendlerFuerGruppe = result.records;
                        this.haendlerFuerGruppe.sort((a, b) => {
                            return a.name < b.name ? -1 : 1;
                        });

                        if (this.haendlerFuerGruppe.length === 0) {
                            this.label = 'keine Händler';
                            this.selectedHaendlerCtrl.disable();
                        } else {
                            this.label = 'Händler';
                        }
                    },
                    error: (err) => {
                        this.isLoading = false;
                        this.messageService.errorMessage(
                            'Fehler beim Laden der Händler',
                            err
                        );
                    },
                });
        }
    }
}
