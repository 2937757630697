<h1 mat-dialog-title>Kalenderwochen</h1>
<div mat-dialog-content>
  <mat-form-field style="margin-top: 12px">
    <mat-label>Jahr</mat-label>
    <mat-select [formControl]="yearCtrl">
      <mat-option *ngFor="let y of years" [value]="y">{{ y }}</mat-option>
    </mat-select>
  </mat-form-field>
  <div>
    <mat-grid-list [cols]="isMobile ? 4 : 12" rowHeight="1:1">
      <mat-grid-tile
        (click)="select(w)"
        *ngFor="let w of weeks"
        [ngClass]="{
          'week-tile': true,
          now: w.year === currentYear && w.weekNumber === currentWeek,
          selected: w.year === selectedWeek.year && w.weekNumber === selectedWeek.weekNumber
        }"
        style="cursor: pointer"
      >
        <div style="text-align: center; width: 56px">
          <p class="accent-color" style="padding: 0px; margin: 0px; font-weight: bold">{{ w.weekNumber }}</p>
          <p style="padding: 0px; margin: 0px; font-size: 0.9em; color: gray">{{ w.monday | date : 'dd.MM.' }}</p>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button (click)="close()" mat-button>Abbrechen</button>
</div>
