export class Tierbemerkung {
    constructor(options?: { bemerkung: string; gt_id: number }) {
        if (options) {
            this.gt_id = options?.gt_id;
            this.bemerkung = options?.bemerkung;
        }
    }

    tierbemerkung_id: number;
    gtb_id: number;
    gt_id: number;
    bemerkung: string;
    tierarzt = 0;
    erstellt: Date;
    vorname: string;
    nachname: string;
}
