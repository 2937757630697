<h1 mat-dialog-title>Tierdetails</h1>

<div mat-dialog-content>
    <kiene-gruppe-tier-details [dialog]="true" [tier]="tier" (changed)="changedDetails = $event">
    </kiene-gruppe-tier-details>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="accent" (click)="dialogRef.close(changedDetails)">Schlie&szlig;en</button>
</div>
