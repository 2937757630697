import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { GruppenService } from '../gruppen.service';
import { Gruppe } from '../classes/gruppe';
import { MessageService } from '../../../services/message-service/message.service';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'kiene-gruppe-kommentar-dialog',
    templateUrl: './gruppe-informationen-dialog.component.html',
    styleUrls: ['./gruppe-informationen-dialog.component.scss']
})
export class GruppeInformationenDialogComponent implements OnInit, OnDestroy {

    gruppe: Gruppe;
    informationenCtrl: UntypedFormControl = new UntypedFormControl();
    gruppeSubscription: Subscription;
    speicherbar = false;

    constructor(public dialogRef: MatDialogRef<GruppeInformationenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private gruppenService: GruppenService,
        private messageService: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl) {
        this.informationenCtrl.disable({ emitEvent: false });
        this.loadGruppe(data.gruppe_id);
    }

    ngOnDestroy(): void {
        this.gruppeSubscription?.unsubscribe();
    }

    ngOnInit(): void { }

    loadGruppe(gruppe_id: number) {
        this.gruppeSubscription?.unsubscribe();
        this.gruppeSubscription = this.gruppenService.getGruppeKundenportal(gruppe_id).subscribe({
            next: response => {
                this.gruppe = response;
                this.informationenCtrl.setValue(this.gruppe.informationen);
                this.speicherbar = true;
                this.informationenCtrl.enable({ emitEvent: false });
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Laden der Gruppe!', err);
            }
        });
    }

    updateKommentar() {
        this.gruppe.informationen = this.informationenCtrl.value;
        this.gruppenService.updateGruppeKundenportal(this.gruppe).subscribe({
            next: response => {
                this.dialogRef.close(response);
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Speichern der Gruppeninformationen', err);
            }
        });

    }

}
