import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Gruppe } from '../../gruppen/classes/gruppe';
import { AusstallenFrageDialogResult } from './ausstallen-frage-dialog';

@Component({
    selector: 'kiene-ausstallen-frage-dialog',
    templateUrl: './ausstallen-frage-dialog.component.html',
    styleUrls: ['./ausstallen-frage-dialog.component.scss'],
})
export class AusstallenFrageDialogComponent implements OnInit {
    radioCtrl = new FormControl(null);

    gruppe: Gruppe;
    anzahl_tiere: number;

    outstalledVieh: number;

    constructor(
        public dialogRef: MatDialogRef<AusstallenFrageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data
    ) { }

    ngOnInit(): void {
        if (this.data?.gruppe) {
            this.gruppe = this.data.gruppe;
        }
        if (this.data?.anzahl_tiere) {
            this.anzahl_tiere = this.data.anzahl_tiere;
            if (this.data?.anzahl_tiere > 0) {
                this.outstalledVieh = this.data.tiere.filter(
                    (tier) =>
                        tier.tierliste_id === null &&
                        tier.hit_todesdatum === null &&
                        tier.kp_todesdatum === null &&
                        tier.hit_abgangsdatum === null
                ).length;
            }
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    save(option: number) {
        const erg = new AusstallenFrageDialogResult();
        erg.ok = true;
        erg.intern = option === 0;
        erg.extern = option === 1;
        erg.tierliste = option === 2;
        this.dialogRef.close(erg);
    }

    public saveIntern(): void {
        this.save(0);
    }

    public saveExtern(): void {
        this.save(1);
    }

    public saveTierliste(): void {
        this.save(2);
    }
}
