<h1 mat-dialog-title>Identifikation</h1>
<div style="height: 100%; display: flex; flex-direction: column">
    <div class="one-row" style="margin-bottom: 4px">
        <mat-radio-group *ngIf="tiereAnzeigen" [formControl]="radioCtrl" style="margin-bottom: 24px">
            <mat-radio-button [value]="0">Freitext</mat-radio-button>
            <mat-radio-button [value]="1">Tierauswahl</mat-radio-button>
        </mat-radio-group>
        <span class="spacer"></span>
        <mat-checkbox [formControl]="prophylaxeCtrl" style="margin-right: 24px">Prophylaxe</mat-checkbox>
        <mat-checkbox [formControl]="metaphylaxeCtrl">Metaphylaxe</mat-checkbox>
    </div>
    <div mat-dialog-content style="padding-left: 24px">
        <div *ngIf="radioCtrl.value === 0" style="width: 100%; margin-top: 24px">
            <mat-form-field style="width: 100%" matAutocompleteOrigin #originIdentity="matAutocompleteOrigin">
                <mat-label>Identifikation</mat-label>
                <textarea #identityCtrlRef matInput [formControl]="identityCtrl" rows="4"
                    [matAutocomplete]="autoIdentity" [matAutocompleteConnectedTo]="originIdentity"></textarea>
            </mat-form-field>
        </div>

        <mat-autocomplete #autoIdentity="matAutocomplete" [displayWith]="identityMapper">
            <mat-option *ngFor="let i of identifikationen | async" [value]="i">
                <div class="one-row">
                    <button mat-icon-button (click)="deleteIdentifikation(i, $event)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <span>{{ i.bezeichnung }}</span>
                    <span class="spacer"></span>
                </div>
            </mat-option>
        </mat-autocomplete>

        <div *ngIf="radioCtrl.value === 1" style="width: 100%">
            <kiene-gruppe-details style="margin: 24px" [gruppe]="gruppe" [auswahl]="true" [zeigeMedikation]="true"
                [anzahlTiereSollwert]="anzahlTiereSollwert" [selectedTiere]="tierauswahl"
                (selectionChange)="setTiere($event)"></kiene-gruppe-details>
        </div>
    </div>
    <div style="flex-grow: 1"></div>
    <div mat-dialog-actions>
        <span class="spacer"></span>
        <button mat-button (click)="close()">Abbrechen</button>
        <button mat-flat-button color="accent" (click)="save()" [disabled]="!showSaveButton">
            &Uuml;bernehmen
        </button>
    </div>
</div>