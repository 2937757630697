<h1 mat-dialog-title>Ausstallen</h1>

<div mat-dialog-content>

    <h5>Händler</h5>
    <div class="one-row">
        <button (click)="openContactDialog('Haendler')" *ngIf="!zwischenhaendler" color="accent"
            mat-stroked-button>Händler auswählen
        </button>
        <ng-container *ngIf="zwischenhaendler">
            <mat-card class="kontakt-card" (click)="openContactDialog('Haendler')">
                <h3>{{ zwischenhaendler.bezeichnung }}</h3>
                <p *ngIf="zwischenhaendler.zusatz"><b>{{ zwischenhaendler.zusatz }}</b></p>
                VVVO: {{ zwischenhaendler.vvvo ? zwischenhaendler.vvvo : '---' }}<br />
                {{ zwischenhaendler.strasse }}<br />
                {{ zwischenhaendler.plz }} {{ zwischenhaendler.ort }}
            </mat-card>
            <span class="spacer"></span>
            <button (click)="deleteHaendler()" mat-icon-button>
                <mat-icon>delete</mat-icon>
            </button>
            <button (click)="openContactDialog('Haendler')" mat-icon-button>
                <mat-icon>edit</mat-icon>
            </button>
        </ng-container>
    </div>

    <mat-divider style="margin-top: 5px"></mat-divider>

    <h5>Zielbetrieb</h5>
    <div class="one-row">
        <button (click)="openContactDialog('Empfaenger')" *ngIf="!empfaenger" color="accent" mat-stroked-button>
            Zielbetrieb auswählen
        </button>
        <ng-container *ngIf="empfaenger">
            <mat-card class="kontakt-card" (click)="openContactDialog('Empfaenger')">
                <h3>{{ empfaenger.bezeichnung }}</h3>
                <p *ngIf="empfaenger.zusatz"><b>{{ empfaenger.zusatz }}</b></p>
                VVVO: {{ empfaenger.vvvo ? empfaenger.vvvo : '---' }}<br />
                {{ empfaenger.strasse }}<br />
                {{ empfaenger.plz }} {{ empfaenger.ort }}
            </mat-card>
            <span class="spacer"></span>
            <button (click)="deleteEmpfaenger()" mat-icon-button>
                <mat-icon>delete</mat-icon>
            </button>
            <button (click)="openContactDialog('Empfaenger')" mat-icon-button>
                <mat-icon>edit</mat-icon>
            </button>
        </ng-container>
    </div>

    <mat-divider style="margin-top: 5px"></mat-divider>

    <h5>Schlachthof</h5>
    <div class="one-row">
        <button (click)="openContactDialog('Schlachthof')" *ngIf="!schlachthof" color="accent"
            mat-stroked-button>Schlachthof auswählen
        </button>
        <ng-container *ngIf="schlachthof">
            <mat-card class="kontakt-card" (click)="openContactDialog('Schlachthof')">
                <h3>{{ schlachthof.bezeichnung }}</h3>
                <p *ngIf="schlachthof.zusatz"><b>{{ schlachthof.zusatz }}</b></p>
                VVVO: {{ schlachthof.vvvo ? schlachthof.vvvo : '---' }}<br />
                {{ schlachthof.strasse }}<br />
                {{ schlachthof.plz }} {{ schlachthof.ort }}
            </mat-card>
            <span class="spacer"></span>
            <button (click)="deleteSchlachthof()" mat-icon-button>
                <mat-icon>delete</mat-icon>
            </button>
            <button (click)="openContactDialog('Schlachthof')" mat-icon-button>
                <mat-icon>edit</mat-icon>
            </button>
        </ng-container>
    </div>

    <mat-divider style="margin-top: 5px"></mat-divider>

    <h5>Einstellungen</h5>
    <mat-form-field style="width: 100%">
        <mat-label>Kommentar</mat-label>
        <textarea [formControl]="kommentarCtrl" matInput rows="1" type="text"></textarea>
    </mat-form-field>

    <mat-form-field style="width: 100%;">
        <mat-label>(geplantes) Ausstalldatum</mat-label>
        <input [formControl]="datumCtrl" [matDatepicker]="picker" readonly="true" matInput />
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <button (click)="datumCtrl.setValue(undefined)" mat-icon-button matSuffix>
            <mat-icon>clear</mat-icon>
        </button>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <p>H&auml;ndlerinformationen der Tiere an Empf&auml;nger weitergeben?</p>
    <mat-radio-group [formControl]="haendlerInfoCtrl"
        aria-label="H&auml;ndlerinformationen der Tiere an Empf&auml;nger weitergeben?">
        <mat-radio-button [value]="0">nein</mat-radio-button>
        <mat-radio-button [value]="1">ja</mat-radio-button>
    </mat-radio-group>
</div>


<div mat-dialog-actions>
    <span class="spacer"></span>
    <button (click)="close()" mat-button>Abbrechen</button>
    <button (click)="save()" *kienePermission="[9322]" [disabled]="isSaving" color="accent" mat-flat-button>
        {{ tierliste.tierliste_id ? "Speichern" : "Erstellen" }}
    </button>
</div>