import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TableDescriptor } from '../../components/kiene-table/table-descriptor';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '../../services/message.service';
import { EmailGesendetTableColumn, GedrucktTableColumn, TableColumn } from '../../components/kiene-table/table-column';
import { Condition, Operator, TableOption, TableOptionEvent } from '../../components/kiene-table/table-option';
import { HttpParams } from '@angular/common/http';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Untersuchungsauftrag } from './untersuchungsauftrag';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { Client } from '../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-untersuchungsauftrag',
    templateUrl: './untersuchungsauftrag.component.html',
    styleUrls: ['./untersuchungsauftrag.component.scss'],
})
export class UntersuchungsauftragComponent implements OnInit, OnDestroy {
    tableDescriptor: TableDescriptor = new TableDescriptor();
    currentClient: Client;

    clearSearch = false;

    watchClientSubscription: Subscription;

    constructor(
        public dialog: MatDialog,
        private messageService: MessageService,
        private pdfService: PdfViewerDesktopService,
        private tableService: KieneTableService,
        private router: Router,
        private api: KieneTableService,
        private route: ActivatedRoute,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        const befund_id = this.route.snapshot.queryParamMap.get('befund_id');
        const cs = this.route.snapshot.queryParamMap.get('clearsearch');
        if (befund_id) {
            if (this.tableDescriptor.params) {
                this.tableDescriptor.params =
                    this.tableDescriptor.params.append('befund_id', befund_id);
            } else {
                this.tableDescriptor.params = new HttpParams().append(
                    'befund_id',
                    befund_id
                );
            }
        }

        if (cs) {
            this.clearSearch = cs === 'true';
            if (this.clearSearch) {
                if (this.tableDescriptor.params) {
                    this.tableDescriptor.params =
                        this.tableDescriptor.params.set('search', '');
                } else {
                    this.tableDescriptor.params = new HttpParams().set(
                        'search',
                        ''
                    );
                }
            }
        }
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Externe Untersuchungsaufträge';
        this.tableDescriptor.uniqueIdentifier = 'Externe Untersuchungsaufträge';
        this.tableDescriptor.apiUrl =
            this.apiBaseUrl + 'untersuchungsauftrag/';
        this.tableDescriptor.route = '/labor/untersuchungsauftrag/';
        this.tableDescriptor.nameOfIdField = 'untersuchungsauftrag_id';
        this.tableDescriptor.searchPlaceholder =
            'Untersuchungsaufträge durchsuchen';
        this.tableDescriptor.columns.push(
            new TableColumn('untersuchungsauftrag_id', 'Nr.')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('tagebuch_nr', 'Tagebuch-Nr.')
        );
        this.tableDescriptor.columns.push(new TableColumn('kunde', 'Kunde'));
        this.tableDescriptor.columns.push(new TableColumn('gruppe', 'Gruppe'));
        this.tableDescriptor.columns.push(
            new TableColumn('lieferant', 'Labor')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('editiert', 'Editiert', 'datetime')
        );
        this.tableDescriptor.columns.push(new TableColumn('status', 'Status'));
        this.tableDescriptor.columns.push(new TableColumn('pdf_id', '', 'pdf'));
        this.tableDescriptor.columns.push(new EmailGesendetTableColumn());
        this.tableDescriptor.columns.push(new GedrucktTableColumn());

        this.tableDescriptor.permissionCreate = 1233;
        this.tableDescriptor.permissionUpdate = 1233;
        this.tableDescriptor.permissionView = 1231;
        this.tableDescriptor.permissionDelete = 1234;

        this.tableDescriptor.options.push(
            new TableOption('delete', 'löschen', 'delete', 1234)
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'createBefund',
                'Befundmitteilung erstellen',
                'fast_forward',
                1235
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'createUntersuchung',
                'Ext. Unters.-Auftrag erstellen',
                'fast_forward',
                1235
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'gotoBefund',
                'Zu Befundmitteilung gehen',
                'fast_forward',
                1311,
                { conditions: [new Condition('befund_id', Operator.GROESSER, 0)] }
            )
        );
        this.tableDescriptor.options.push(new TableOption(null, 'Drucken', 'print', 2519, { void: this.gedruckt.bind(this), conditions: [new Condition('pdf_id', Operator.EXISTIERT, null)] }));

        this.tableDescriptor.initialSortColumn = 'erstellt';
        this.tableDescriptor.initialSortDirection = 'desc';

        this.tableDescriptor.fileUploadApi =
            'untersuchungsauftrag_dokument/create_rechnung.php';
    }

    gedruckt(usa: Untersuchungsauftrag) {
        this.api.get(this.apiBaseUrl + 'untersuchungsauftrag/gedruckt.php', new HttpParams().set('untersuchungsauftrag_id', usa.untersuchungsauftrag_id)).subscribe({
            next: () => {
                this.api.reload('gedruckt');
                this.showPdf(usa);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    ngOnDestroy() {
        console.debug('Destroy ProbenbegleitscheinComponent');
        this.tableService = null;
    }

    showPdf(usa: Untersuchungsauftrag) {
        const input = new PdfViewerDialogInput(
            'untersuchungsauftrag/read_or_mail_pdf.php',
            new HttpParams().set('untersuchungsauftrag_id',
                usa?.untersuchungsauftrag_id?.toString()),
            'Untersuchungsauftrag',
            {
                email: {
                    sende_mail: true,
                    kunde_id: usa?.kunde_id
                }
            }
        );
        this.pdfService.openPdfViewer(input).subscribe({
            next: () => {
                this.api.reload();
            }
        });
    }

    catchOptionEvent(event: TableOptionEvent) {
        const usa = <Untersuchungsauftrag>event.value;
        if (event.name === 'showPdf') {
            this.showPdf(usa);
        } else if (event.name === 'createBefund') {
            this.api
                .post(
                    this.apiBaseUrl + 'befund/transform.php',
                    event.value,
                    null
                )
                .subscribe(
                    (response) => {
                        const b: Untersuchungsauftrag = response;
                        this.messageService.infoMessage(
                            'Die Befundmitteilung wurde erfolgreich erstellt!'
                        );
                        this.router.navigate([
                            'labor',
                            'befundmitteilungen',
                            b.untersuchungsauftrag_id,
                        ]);
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Befundmitteilung konnte nicht erstellt werden!',
                            error
                        );
                    }
                );
        } else if (event.name === 'createUntersuchung') {
            this.api
                .post(
                    this.apiBaseUrl + 'untersuchungsauftrag/transform.php',
                    event.value,
                    null
                )
                .subscribe(
                    (response) => {
                        const b: any = response;
                        this.messageService.infoMessage(
                            'Der Untersuchungsauftrag wurde erfolgreich erstellt!'
                        );
                        this.router.navigate([
                            'labor',
                            'untersuchungsauftrag',
                            b.untersuchungsauftrag_id,
                        ]);
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Untersuchungsauftrag konnte nicht erstellt werden!',
                            error
                        );
                    }
                );
        } else if (event.name === 'gotoBefund') {
            this.goToBefund(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        }
    }

    openDeleteDialog(u: Untersuchungsauftrag) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Untersuchungsauftrag wirklich löschen?',
                text: 'Untersuchungsauftrag wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.api
                    .deleteEntity(this.apiBaseUrl + 'untersuchungsauftrag/delete.php', u)
                    .subscribe(
                        (result) => {
                            this.messageService.infoMessage(
                                'Untersuchungsauftrag wurde erfolgreich gelöscht!'
                            );
                            this.api.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Untersuchungsauftrag konnte nicht gelöscht werden!',
                                error
                            );
                        }
                    );
            }
        });
    }

    goToBefund(u: Untersuchungsauftrag) {
        this.router.navigate(['labor', 'befundmitteilungen', u.befund_id]).then();
    }
}
