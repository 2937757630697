import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {TextbausteinConfig} from "../../../directives/text-baustein.directive";
import {HeimtierBeleg, HeimtierBelegStatus} from '../../heimtier-beleg';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {MessageService} from '../../../services/message.service';

@Component({
    selector: 'kiene-besuchs-detail-beleg',
    templateUrl: './besuchs-detail-beleg.component.html',
    styleUrls: ['./besuchs-detail-beleg.component.scss']
})
export class BesuchsDetailBelegComponent implements OnInit, OnChanges {
    @Input() heimtierBeleg: HeimtierBeleg;
    @Output() validationChange = new EventEmitter<boolean>();

    public berichtCtrl: FormControl = new FormControl(undefined, Validators.required);
    protected zusammenfassungCtrl: FormControl = new FormControl();
    protected anmerkungCtrl: FormControl = new FormControl();

    constructor(private api: KieneBackendApiService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private messageService: MessageService) {
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        this.initFormFields();
    }

    public getTextBausteinZusammenfassung(): TextbausteinConfig {
        const textbaustein = new TextbausteinConfig();
        textbaustein.count = 1;
        textbaustein.titel1 = "Besuchszusammenfassung";
        textbaustein.text1 = this.zusammenfassungCtrl.value;
        textbaustein.typ = 'besuchszusammenfassung';

        return textbaustein;
    }

    public getTextBausteinBericht(): TextbausteinConfig {
        const textbaustein = new TextbausteinConfig();
        textbaustein.count = 1;
        textbaustein.titel1 = "Bericht";
        textbaustein.text1 = this.berichtCtrl.value;
        textbaustein.typ = 'bericht';

        return textbaustein;
    }

    public getTextBausteinAnmerkung(): TextbausteinConfig {
        const textbaustein = new TextbausteinConfig();
        textbaustein.count = 1;
        textbaustein.titel1 = "Anmerkung";
        textbaustein.text1 = this.anmerkungCtrl.value;
        textbaustein.typ = 'besuch';

        return textbaustein;
    }

    updateBeleg() {
        if (this.heimtierBeleg.status_id === HeimtierBelegStatus.ABGESCHLOSSEN) {
            return;
        }
        this.heimtierBeleg.bericht = this.berichtCtrl.value;
        this.heimtierBeleg.besuchszusammenfassung = this.zusammenfassungCtrl.value;
        this.heimtierBeleg.anmerkung = this.anmerkungCtrl.value;
        this.api.updateGeneric(this.apiBaseUrl + 'heimtier_beleg/update.php', this.heimtierBeleg).subscribe({
            next: response => {
                this.heimtierBeleg = response;
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Speichern!', err);
            }
        });
    }


    private initFormFields() {
        this.berichtCtrl.setValue(this.heimtierBeleg?.bericht);
        this.zusammenfassungCtrl.setValue(this.heimtierBeleg?.besuchszusammenfassung)
        this.anmerkungCtrl.setValue(this.heimtierBeleg?.anmerkung)
    }


}
