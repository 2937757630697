<div style="padding: 12px; padding-bottom: 64px;">
    <div class="one-row" style="margin: 12px 0;">
        <button *ngIf="showBackButton" mat-icon-button color="accent" (click)="back()">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>
        <h3>Meine Benutzer</h3>
        <span class="spacer"></span>
        <button mat-icon-button color="accent" (click)="benutzerHinzufuegen()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <div style="margin-top: 20px">

        <ul style="margin: 0; padding: 0; list-style-type: none">
            <li *ngFor="let b of benutzerList"
                style="border-bottom: 1px solid #ccc; margin-bottom: 24px;">
                <div class="one-row" style="margin-bottom: 12px;">
                    <mat-icon style="transform: scale(1.6)">account_circle</mat-icon>
                    <span style="margin-left: 24px;">
                                <strong>{{ b.vorname }} {{ b.nachname }}</strong>
                        <br><span style="font-size: .9em; color: #7d7d7d;">{{ b.email }}</span>
                            </span>
                    <span class="spacer"></span>
                    <button mat-icon-button color="accent" (click)="benutzerEntfernen(b)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <div class="one-row">
                    <span style="width: 20px;"></span>
                    <mat-icon>business</mat-icon>
                    <span style="margin-left: 6px; font-size: .8em;"><strong>Betriebe</strong></span>
                    <span class="spacer"></span>
                    <button mat-icon-button color="accent" (click)="betriebeEditieren(b)">
                        <mat-icon>{{ b.kunden?.length < 1 ? 'add' : 'edit' }}</mat-icon>
                    </button>
                </div>
                <ul style="list-style-type: none">
                    <li *ngFor="let k of b?.kunden" style="padding-bottom: 12px;">{{ k.bezeichnung_final }}
                        - {{ k.zusatz_final }}
                    </li>
                </ul>

                <div class="one-row">
                    <span style="width: 20px;"></span>
                    <mat-icon>people</mat-icon>
                    <span style="margin-left: 6px; font-size: .8em;"><strong>Benutzerrollen</strong></span>
                    <span class="spacer"></span>
                    <button mat-icon-button color="accent" (click)="rollenEditieren(b)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
                <ul style="list-style-type: none">
                    <li *ngFor="let r of b?.benutzerrollen" style="padding-bottom: 12px;">{{ r.bezeichnung }}</li>
                </ul>
                <div style="height: 12px;"></div>
            </li>
        </ul>
    </div>
</div>
