<nav mat-tab-nav-bar style="margin-top: 63px;">
    <a *ngIf="isTabMenuVisible('/administration/benutzer')" mat-tab-link [routerLink]="['/administration/benutzer']"
       routerLinkActive #ula="routerLinkActive" [active]="ula.isActive">Benutzer</a>
    <!-- <a *ngIf="isTabMenuVisible('/administration/kunden')" mat-tab-link [routerLink]="['/administration/kunden']"  routerLinkActive #ttbla="routerLinkActive" [active]="ttbla.isActive">Kunden</a> -->
    <!-- <a *ngIf="isTabMenuVisible('/administration/unternehmensgruppen')" mat-tab-link [routerLink]="['/administration/unternehmensgruppen']"  routerLinkActive #ugla="routerLinkActive" [active]="ugla.isActive">Unternehmensgruppen</a> -->
    <a *ngIf="isTabMenuVisible('/administration/benutzerrollen')" mat-tab-link
       [routerLink]="['/administration/benutzerrollen']" routerLinkActive #urbla="routerLinkActive"
       [active]="urbla.isActive">Benutzerrollen</a>
    <a *ngIf="isTabMenuVisible('/administration/tieraerzte')" mat-tab-link [routerLink]="['/administration/tieraerzte']"
       routerLinkActive #tla="routerLinkActive" [active]="tla.isActive">Tier&auml;rzte</a>
    <a *ngIf="isTabMenuVisible('/administration/tierarztgruppen')" mat-tab-link
       [routerLink]="['/administration/tierarztgruppen']" routerLinkActive #tgla="routerLinkActive"
       [active]="tgla.isActive">Tierarztgruppen</a>
    <!-- <a *ngIf="isTabMenuVisible('/administration/betriebsarten')" mat-tab-link [routerLink]="['/administration/betriebsarten']"  routerLinkActive #bala="routerLinkActive" [active]="bala.isActive">Betriebsarten</a> -->
    <!-- <a *ngIf="isTabMenuVisible('/administration/tierarten')" mat-tab-link [routerLink]="['/administration/tierarten']"  routerLinkActive #tala="routerLinkActive" [active]="tala.isActive">Tierarten</a> -->
    <!-- <a *ngIf="isTabMenuVisible('/administration/landkreise')" mat-tab-link [routerLink]="['/administration/landkreise']"  routerLinkActive #lla="routerLinkActive" [active]="lla.isActive">Landkreise</a> -->
    <a *ngIf="isTabMenuVisible('/administration/inventur-verwaltung')" mat-tab-link
       [routerLink]="['/administration/inventur-verwaltung']" routerLinkActive #ivla="routerLinkActive"
       [active]="ivla.isActive">Inventur</a>
    <!--   <a  mat-tab-link [routerLink]="['/administration/textbaustein']"  routerLinkActive #ivla="routerLinkActive" [active]="ivla.isActive">Textbausteine</a>-->
    <a *ngIf="isTabMenuVisible('/administration/textbausteine')" mat-tab-link
       [routerLink]="['/administration/textbaustein']" routerLinkActive #txt="routerLinkActive"
       [active]="txt.isActive">Textbausteine</a>
    <a *ngIf="isTabMenuVisible('/administration/mandanten')" mat-tab-link
       [routerLink]="['/administration/mandanten']" routerLinkActive #mandantActive="routerLinkActive"
       [active]="mandantActive.isActive">Mandanten</a>
</nav>
<router-outlet></router-outlet>
