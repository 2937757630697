<h1 mat-dialog-title>Kontakt anlegen</h1>
<div mat-dialog-content>

    <mat-form-field style="width: 100%;">
        <mat-label>VVVO</mat-label>
        <input matInput type="string" inputmode="numeric" [formControl]="vvvoCtrl" minlength="15" maxlength="15"
               required>
        <span style="font-size: .8em; color: gray;" matSuffix>{{vvvoCtrl.value?.length}}/15</span>
    </mat-form-field>

    <ul *ngIf="kundenvorschlaege.length > 1" style="padding: 0; margin: 0; list-style-type: none;">
        <li *ngFor="let k of kundenvorschlaege" style="border-bottom: 1px solid gray; padding-bottom: 12px;">
            <div>
                <p>{{k.bezeichnung_final}}</p>
                <p>{{k.zusatz_final}}</p>
                <p>{{k.strasse_final}}</p>
                <p>{{k.plz_final}}&nbsp;{{k.ort_final}}</p>
                <div *ngIf="k.abweichende_lieferadresse">
                    <h5>Abweichende Lieferadresse</h5>
                    <p>{{k.strasse_lieferung}}</p>
                    <p>{{k.plz_lieferung}}&nbsp;{{k.ort_lieferung}}</p>
                </div>
            </div>
            <button mat-flat-button color="accent" (click)="save(k)">Speichern und
                ausw&auml;hlen
            </button>
        </li>
    </ul>

    <div *ngIf="kunde_b?.kunde_id && kundenvorschlaege.length < 2">
        <p>{{kunde_b.bezeichnung_final}}</p>
        <p>{{kunde_b.zusatz_final}}</p>
        <p>{{kunde_b.strasse_final}}</p>
        <p>{{kunde_b.plz_final}}&nbsp;{{kunde_b.ort_final}}</p>
        <div *ngIf="kunde_b.abweichende_lieferadresse">
            <h5>Abweichende Lieferadresse</h5>
            <p>{{kunde_b.strasse_lieferung}}</p>
            <p>{{kunde_b.plz_lieferung}}&nbsp;{{kunde_b.ort_lieferung}}</p>
        </div>
    </div>

    <div *ngIf="formularAnzeigen && !kunde_b?.kunde_id">
        <mat-form-field style="width: 100%;">
            <mat-label>Name des Kunden</mat-label>
            <input matInput type="text" [(ngModel)]="kunde_b.bezeichnung" [disabled]="eingabenDisabled">
        </mat-form-field>
        <mat-form-field style="width: 100%;">
            <mat-label>Kunden Zusatz</mat-label>
            <input matInput type="text" [(ngModel)]="kunde_b.zusatz" [disabled]="eingabenDisabled">
        </mat-form-field>
        <mat-form-field style="width: 100%;">
            <mat-label>Stra&szlig;e</mat-label>
            <input matInput type="text" [(ngModel)]="kunde_b.strasse" [disabled]="eingabenDisabled">
        </mat-form-field>
        <mat-form-field style="width: 100%;">
            <mat-label>PLZ</mat-label>
            <input matInput type="text" [(ngModel)]="kunde_b.plz" [disabled]="eingabenDisabled">
        </mat-form-field>
        <mat-form-field style="width: 100%;">
            <mat-label>Ort</mat-label>
            <input matInput type="text" [(ngModel)]="kunde_b.ort" [disabled]="eingabenDisabled">
        </mat-form-field>
        <mat-checkbox [(ngModel)]="abweichendeLieferadresseProxy" [disabled]="eingabenDisabled">Abweichende
            Lieferadresse
        </mat-checkbox>
        <div *ngIf="abweichendeLieferadresseProxy">
            <h5>Lieferadresse</h5>
            <mat-form-field style="width: 100%;">
                <mat-label>Stra&szlig;e</mat-label>
                <input matInput type="text" [(ngModel)]="kunde_b.strasse_lieferung" [disabled]="eingabenDisabled">
            </mat-form-field>
            <mat-form-field style="width: 100%;">
                <mat-label>PLZ</mat-label>
                <input matInput type="text" [(ngModel)]="kunde_b.plz_lieferung" [disabled]="eingabenDisabled">
            </mat-form-field>
            <mat-form-field style="width: 100%;">
                <mat-label>Ort</mat-label>
                <input matInput type="text" [(ngModel)]="kunde_b.ort_lieferung" [disabled]="eingabenDisabled">
            </mat-form-field>
        </div>
    </div>

    <button *ngIf="vvvoCtrl.value?.length === 15 && kundenvorschlaege.length < 2" mat-flat-button color="accent"
            (click)="save()">Speichern und
        ausw&auml;hlen
    </button>

</div>
<div mat-dialog-actions>
    <button mat-button (click)="close()">Schlie&szlig;en</button>
</div>
