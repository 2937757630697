<h1 mat-dialog-title>Neue R&uuml;cklieferung erstellen</h1>
<div mat-dialog-content>

    <kiene-autocomplete [apiUrl]="'lieferant/'" [placeholder]="'Lieferant'"
                        (elementSelected)="setLieferant($event)"></kiene-autocomplete>

    <mat-form-field style="width: 100%;">
        <mat-label>Datum der R&uuml;cklieferung</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="datumCtrl">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <kiene-article-scan-autocomplete [placeholder]="'Artikel'" [required]="true"
                                     (selectedMaterial)="setMaterial($event)"></kiene-article-scan-autocomplete>

    <mat-form-field style="width: 100%;">
        <mat-label>Chargennummer</mat-label>
        <input matInput type="text" [formControl]="chargeCtrl">
    </mat-form-field>

    <mat-form-field style="width: 100%;">
        <mat-label>Menge</mat-label>
        <input matInput type="number" inputmode="decimal" [formControl]="mengeCtrl">
    </mat-form-field>

    <mat-form-field style="width: 100%;">
        <mat-label>Bestellnummer / Referenz des Lieferanten</mat-label>
        <input matInput type="text" inputmode="decimal" [formControl]="bestellnummerCtrl">
    </mat-form-field>


</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()"
            [disabled]="!datumCtrl.valid || !chargeCtrl.valid || !mengeCtrl.valid || !material || !supplier">Erstellen
    </button>
</div>
