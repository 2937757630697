<div class="one-row" style="margin: 0 24px; width: auto">
    <button (click)="zoomFactor = zoomFactor + 0.05" [disabled]="isDownloading" color="accent" mat-icon-button>
        <mat-icon>zoom_in</mat-icon>
    </button>
    <button (click)="zoomFactor = zoomFactor - 0.05" [disabled]="isDownloading" color="accent" mat-icon-button>
        <mat-icon>zoom_out</mat-icon>
    </button>
    <button (click)="zoomFactor = 1" [disabled]="isDownloading" color="accent" mat-icon-button>
        <mat-icon>restart_alt</mat-icon>
    </button>
    <kiene-share-button [disabled]="isDownloading" [files]="[pdfFile]" [title]="'VETVET PDF'"></kiene-share-button>
    <button (click)="download()" [disabled]="isDownloading" color="accent" mat-icon-button>
        <mat-icon>{{pdfViewerInput.asEmail ? 'mail' : 'file_download'}}</mat-icon>
    </button>
</div>

<mat-divider style="width: 100%; margin-top: 6px"></mat-divider>

<div *ngIf="!pdfSource"
     style="width: 100%; display: flex; align-items: center; justify-content: center; text-align: center; margin: 20% 0">
    <mat-hint class="hinweis-text">Lade PDF-Datei...</mat-hint>
</div>

<div>
    <div *ngIf="currPage && totalPages" class="one-row">
        <mat-hint class="hinweis-text" style=" padding: 6px; font-size: 0.8em">
            Gesamt: {{totalPages}} {{totalPages === 1 ? 'Seite' : 'Seiten'}}</mat-hint>
    </div>
    <pdf-viewer (after-load-complete)="setPages($event)" *ngIf="pdfSource"
                [autoresize]="false"
                [original-size]="false"
                [render-text]="true"
                [show-all]="true"
                [src]="pdfSource"
                [zoom]="zoomFactor * 0.95"
                style="height: 50vh; overflow: scroll"
    ></pdf-viewer>
</div>

<mat-slider (valueChange)="zoomFactor = $event" [max]="2" [min]="0" [step]="0.05" [value]="zoomFactor"
            style="width: 100%;"></mat-slider>

