import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArtikelstrukturelementFlatNode } from '../../../probenbegleitschein/probenbegleitschein';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'kiene-edit-untersuchungsmaterial-dialog',
  templateUrl: './edit-untersuchungsmaterial-dialog.component.html',
  styleUrls: ['./edit-untersuchungsmaterial-dialog.component.scss']
})
export class EditUntersuchungsmaterialDialogComponent implements OnInit {

  node: ArtikelstrukturelementFlatNode;
  valueCtrl = new UntypedFormControl();

  constructor(public dialogRef: MatDialogRef<EditUntersuchungsmaterialDialogComponent>, @Inject(MAT_DIALOG_DATA) private data) { 
    this.node = data.node;
  }

  ngOnInit() {
    this.valueCtrl.setValue(this.node.untersuchung);
  }

  cancel(){
    this.dialogRef.close(null);
  }

  save(){
    this.dialogRef.close(this.valueCtrl.value);
  }

}
