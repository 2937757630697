import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import SignaturePad from 'signature_pad';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';

@Component({
    selector: 'kiene-signature-panel',
    templateUrl: './signature-panel.component.html',
    styleUrls: ['./signature-panel.component.scss']
})
export class SignaturePanelComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('signatureCanvas', {static: false}) signatureCanvas: ElementRef;

    @Input('imgBase64') imgBase64: string;

    @Output('saved') saved = new EventEmitter<string>();


    signaturePad: SignaturePad;

    isMobileDevice = false;
    isPortraitModeQuery = window.matchMedia('(orientation: portrait)');
    isPortraitMode = true;
    base64Png: string;
    @Input('hinweisText') hinweisText = '';

    constructor(private breakpointObserver: BreakpointObserver) {
        this.isPortraitModeQuery.addEventListener('change', event => {
            console.log('orientation portrait: %s', this.isPortraitModeQuery.matches);
            this.isPortraitMode = this.isPortraitModeQuery.matches;
        });
    }

    ngOnInit(): void {
        this.isPortraitMode = this.isPortraitModeQuery.matches;
        console.log('init signature pad');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.imgBase64 && changes.imgBase64.currentValue) {
            const options = {
                width: this.signatureCanvas?.nativeElement.offsetWidth,
                height: this.signatureCanvas?.nativeElement.offsetHeight
            };
            this.signaturePad?.fromDataURL(changes.imgBase64.currentValue, options);
        }
    }

    ngAfterViewInit() {
        this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement);
        this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe({
            next: (state: BreakpointState) => {
                if (state.matches) {
                    this.isMobileDevice = true;
                    this.resizeCanvas();
                } else {
                    this.isMobileDevice = false;
                    this.resizeCanvas();
                }
                console.log('MobileDevice: %s, Portrait: %s', this.isMobileDevice, this.isPortraitMode);
            }
        });
        setTimeout(() => {
            if (this.imgBase64) {
                const options = {
                    width: this.signatureCanvas?.nativeElement.offsetWidth,
                    height: this.signatureCanvas?.nativeElement.offsetHeight
                };
                this.signaturePad.fromDataURL(this.imgBase64, options);
            }
            this.resizeCanvas();
        }, 200);
    }

    public clear() {
        this.signaturePad?.clear();
        this.imgBase64 = undefined;
        this.signaturePad?.on();
    }

    save() {
        this.base64Png = this.signaturePad.toDataURL('image/png');
        this.saved.emit(this.base64Png);
    }

    public resizeCanvas() {
        const ratio = 1; //Math.max(window.devicePixelRatio || 1, 1);
        this.signatureCanvas.nativeElement.width = this.signatureCanvas?.nativeElement.offsetWidth * ratio;
        this.signatureCanvas.nativeElement.height = this.signatureCanvas?.nativeElement.offsetHeight * ratio;
        this.signatureCanvas?.nativeElement?.getContext('2d')?.scale(ratio, ratio);
        this.signaturePad?.clear();
    }
}
