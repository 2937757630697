import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Gruppe} from '../classes/gruppe';
import {GruppenService} from '../gruppen.service';
import {MessageService} from '../../../services/message-service/message.service';

@Component({
    selector: 'kiene-gruppe-auswaehlen-selektor',
    templateUrl: './gruppe-auswaehlen-selektor.component.html',
    styleUrls: ['./gruppe-auswaehlen-selektor.component.scss']
})
export class GruppeAuswaehlenSelektorComponent implements OnInit, OnChanges {
    selectCtrl = new FormControl();

    gruppen: Gruppe[] = [];

    @Input('kundeId') kundeId: number;
    @Input('disabled') disabled = false;

    @Output('ausgewaehlt') ausgewaehltEmitter = new EventEmitter<Gruppe>();

    constructor(private gruppenService: GruppenService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.selectCtrl.valueChanges.subscribe({next: gruppe => this.ausgewaehltEmitter.emit(gruppe)});
        this.checkDisabled();
        this.ladeGruppen();
    }

    ngOnChanges() {
        this.checkDisabled();
        this.ladeGruppen();
    }

    ladeGruppen() {
        if (this.kundeId) {
            this.gruppenService.ladeGruppenFuerKunde(this.kundeId).subscribe({
                next: response => {
                    this.gruppen = response.records;
                    if (this.gruppen?.length > 0) {
                        this.selectCtrl.setValue(this.gruppen[0]);
                    }
                    this.checkDisabled();
                }
            });
        }
    }

    compareGruppen(g1: Gruppe, g2: Gruppe) {
        return g1?.gruppe_id === g2?.gruppe_id;
    }

    public setzeGruppe(gruppeId: number) {
        this.gruppenService.ladeGruppe(gruppeId).subscribe({
            next: gruppe => {
                this.selectCtrl.setValue(gruppe);
            },
            error: err => {
                this.messageService.errorMessage('Fehler', err);
            }
        });
    }

    private checkDisabled() {
        if (this.disabled) {
            this.selectCtrl.disable();
        } else {
            this.selectCtrl.enable();
        }
    }
}
