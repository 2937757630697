<h1 mat-dialog-title>{{artikelliste?.artikelliste_id ? 'Vorlage bearbeiten' : 'Neue Vorlage erstellen'}}</h1>
<div mat-dialog-content>
    <div>
        <kiene-article-scan-autocomplete style="min-width: 300px; width: 15%" [disabled]="disabled" #articleAutocomplete

                                         (selectedMaterial)="setSelectedMaterial($event)"
                                         [placeholder]="'Artikel eingeben'" [gesperrtAusblenden]="true"

                                         [required]="true" [focus]="true"
                                         (cleared)="materialInputCleared()"
        >
        </kiene-article-scan-autocomplete>
        <br>
        <kiene-tierart-selector #tierartSelector [zeigeKeine]="false" [required]="true"
                                [disabled]="disabled"
                                (tierartSelectionChanged)="setSelectedTierart($event)" style="width: 10%">
        </kiene-tierart-selector>
        <br>
        <kiene-anwendungs-selector #anwendungSelector style="width: 10%" [required]="true"

                                   [disabled]="disabled"
                                   (change)="setSelectedAnwendung($event)">
        </kiene-anwendungs-selector>
        <br>
        <kiene-diagnose-selector #diagnoseSelector style="width: 15%"
                                 [disabled]="disabled"
                                 [required]="true" (change)="setSelectedDiagnose($event)">
        </kiene-diagnose-selector>
        <br>
        <mat-form-field style="width: 5%">
            <mat-label>Behandlung:</mat-label>
            <mat-select #behandlungJaNein [formControl]="behandlungJaNeinCtrl" [disabled]="disabled">
                <mat-option [value]="true">Ja</mat-option>
                <mat-option [value]="false">Nein</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
