<mat-card (click)="selected()" [style.background]="transparentBackground ? 'transparent' : ''"
    [style.padding]="padding ? padding : '18px'" style="margin-bottom: 16px;">
    <div class="one-row">
        <h3>#{{ tierliste?.tierliste_id }}</h3>
        <span class="spacer"></span>
        <ng-container *kienePermission="[9323,9324,1971]">
            <button *ngIf="loeschenAnzeigen || downloadAnzeigen || (tierliste?.status_id == 162 && !historie)"
                mat-icon-button [matMenuTriggerFor]="listMenu" (click)="$event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
            </button>
        </ng-container>
        <mat-menu #listMenu="matMenu">
            <ng-container *ngIf="loeschenAnzeigen">
                <button *kienePermission="[9324]" mat-menu-item (click)="listeLoeschen($event)">
                    <mat-icon>delete</mat-icon>
                    <span>l&ouml;schen</span>
                </button>
            </ng-container>
            <ng-container *ngIf="downloadAnzeigen">
                <button *kienePermission="[1971]" mat-menu-item (click)="downloadListe()">
                    <mat-icon>download</mat-icon>
                    <span>Download</span>
                </button>
            </ng-container>
            <ng-container *ngIf="tierliste?.status_id == 162 && !historie">
                <button *kienePermission="[9112]" (click)="zuNeuerGruppeHinzufuegen($event)" mat-menu-item>
                    <mat-icon>add</mat-icon>
                    <span>In neue Gruppe importieren</span>
                </button>
                <button *kienePermission="[9112]" (click)="zuGruppeHinzufuegen($event)" mat-menu-item>
                    <mat-icon>library_add</mat-icon>
                    <span>In vorhandene Gruppe importieren</span>
                </button>
                <button *kienePermission="[9324]" (click)="nichtImportieren($event)" mat-menu-item>
                    <mat-icon>delete</mat-icon>
                    <span>L&ouml;schen / Nicht importieren</span>
                </button>
            </ng-container>
        </mat-menu>
    </div>
    <div class="one-row">
        <mat-icon *ngIf="tierliste?.status_id > 161 && showLock" color="accent">lock
        </mat-icon>
        <mat-icon *ngIf="tierliste?.mehrere_gruppen > 0" color="accent">public
        </mat-icon>
        <span class="spacer"></span>
    </div>
    <div class="list-client-row">
        <span class="list-client-row-title">Status:</span><br>
        <span class="list-client-row-value">{{ tierliste?.status }}</span>
    </div>
    <div class="list-client-row">
        <span class="list-client-row-title">Anzahl Tiere:</span><br>
        <span class="list-client-row-value">{{ tierliste?.anzahl_tiere }}</span>
    </div>
    <div class="list-client-row">
        <span class="list-client-row-title">Abgangsdatum:</span><br>
        <span class="list-client-row-value">{{ tierliste?.datum_abgang | date: 'dd.MM.yyyy' }}</span>
    </div>
    <div class="list-client-row">
        <span class="list-client-row-title">Von:</span><br>
        <span class="list-client-row-value">{{ tierliste?.kunden_abgang }}</span>
    </div>
    <div class="list-client-row">
        <span class="list-client-row-title">{{ tierliste?.kunde_id_zielbetrieb ? 'Über:' : 'Nach:' }}</span><br>
        <span class="list-client-row-value">{{ tierliste?.kunde_einstallen }}{{ tierliste?.kunde_zusatz_einstallen ? (',
            ' + tierliste.kunde_zusatz_einstallen) : '' }}</span>
    </div>
    <div *ngIf="tierliste?.kunde_id_zielbetrieb" class="list-client-row">
        <span class="list-client-row-title">Nach:</span><br>
        <span class="list-client-row-value">{{ tierliste?.kunde_zielbetrieb }}{{ tierliste?.kunde_zusatz_zielbetrieb ?
            (', ' + tierliste.kunde_zusatz_zielbetrieb) : '' }}</span>
    </div>
    <div *ngIf="tierliste?.kommentar" class="list-client-row">
        <span class="list-client-row-title">Kommentar:</span><br>
        <span class="list-client-row-value">{{ tierliste?.kommentar }}</span>
    </div>

</mat-card>