import { EnterValueDialogComponent } from "../../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-dialog.component";
import {
    EnterValueInput,
    EnterValueResponse,
} from "../../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-response";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from "../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog";
import { ConfirmDialogComponent } from "../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component";
import { environment } from "../../../../environments/environment";
import { AppService } from "../../../app.service";
import { KieneTableService } from "../../../components/kiene-table/kiene-table.service";
import {
    Abgabebeleg,
    AbgabebelegPosition,
    AbgabebelegStatus,
} from "../../../documents/abgabebeleg/abgabebeleg";
import { Befundmitteilung } from "../../../laboratory/befundmitteilung/befundmitteilung";
import { MessageService } from "../../../services/message.service";
import { Invoice } from "../rechnung";

export class StornoResult {
    abgabebelege: Abgabebeleg[] = [];
    befunde: Befundmitteilung[] = [];
}

@Component({
    selector: "kiene-rechnung-belege-dialog",
    templateUrl: "./rechnung-belege-dialog.component.html",
    styleUrls: ["./rechnung-belege-dialog.component.scss"],
})
export class RechnungBelegeDialogComponent implements OnInit {
    invoice: Invoice;
    stornoResult: StornoResult;

    formControlsArtikelmenge: UntypedFormControl[] = [];
    formControlsArtikelpreise: UntypedFormControl[] = [];

    constructor(
        public dialogRef: MatDialogRef<RechnungBelegeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private api: KieneTableService,
        private router: Router,
        private messageService: MessageService,
        private appService: AppService,
        private dialog: MatDialog
    ) {
        if (this.data) {
            this.invoice = this.data.invoice;
        }
    }

    ngOnInit(): void {
        this.loadBelege();
    }

    refresh() {
        this.loadBelege();
    }

    loadBelege() {
        this.appService.setDataLoading(true);
        this.api
            .getOne(environment.BASE_URI + "rechnung/read_storno.php", "rechnung_id",
                this.invoice?.rechnung_id?.toString())
            .subscribe({
                next: (response) => {
                    this.stornoResult = <StornoResult>response;
                    this.prepareFormControls();
                    this.appService.setDataLoading(false);
                },
                error: (err) => {
                    this.appService.setDataLoading(false);
                    this.messageService.errorMessage(
                        `Die Belege für die Rechnung ${this.invoice.rechnung_nr} konnten nicht geladen werden`,
                        err
                    );
                },
            });
    }

    prepareFormControls() {
        for (let ab of this.stornoResult.abgabebelege) {
            for (let p of ab.positionen) {
                this.formControlsArtikelmenge[p.aa_id] = new UntypedFormControl(
                    p.artikelmenge
                );
                this.formControlsArtikelpreise[p.aa_id] =
                    new UntypedFormControl(p.einzelpreis);
            }
        }
    }

    reopenAbgabebeleg(a: Abgabebeleg) {
        this.api
            .post(environment.BASE_URI + "abgabebeleg/korrigieren.php", a, null)
            .subscribe({
                next: (response) => {
                    // this.openAbgabebelegInNewTab(response);
                    this.loadBelege();
                },
                error: (err) => {
                    this.loadBelege();
                    this.messageService.errorMessage(
                        "Der Abgabebeleg " +
                        a.abgabebeleg_nr +
                        " konnte nicht wieder geöffnet werden!",
                        err
                    );
                },
            });
    }

    updateEinzelpreisAbgabebelegposition(p: AbgabebelegPosition) {
        const neuerPreis = this.formControlsArtikelpreise[p.aa_id].value;
        if (neuerPreis === p.einzelpreis) {
            return;
        }
        p.einzelpreis = neuerPreis;
        this.api
            .updateGeneric(
                environment.BASE_URI + "abgabebeleg/update_einzelpreis.php",
                p
            )
            .subscribe({
                next: (response) => {
                    this.messageService.infoMessage(
                        "Der Einzelpreis wurde erfolgreich aktualisiert!"
                    );
                    this.loadBelege();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        "Der Einzelpreis konnte nicht aktualisiert werden!",
                        err
                    );
                },
            });
    }

    updateArtikelmengeAbgabebelegposition(p: AbgabebelegPosition) {
        const neueMenge = this.formControlsArtikelmenge[p.aa_id].value;
        if (neueMenge === p.artikelmenge) {
            return;
        }
        p.artikelmenge = neueMenge;
        this.api
            .updateGeneric(
                environment.BASE_URI + "abgabebeleg/update_artikelmenge.php",
                p
            )
            .subscribe({
                next: (response) => {
                    this.messageService.infoMessage(
                        "Die Artikelmenge wurde erfolgreich aktualisiert!"
                    );
                    this.loadBelege();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        "Die Artikelmenge konnte nicht aktualisiert werden!",
                        err
                    );
                },
            });
    }

    deletePosition(a: Abgabebeleg, index: number) {
        a.positionen.splice(index, 1);
        this.api.updateEntity(environment.BASE_URI + "abgabebeleg/update.php", a).subscribe({
            next: (response) => {
                this.messageService.infoMessage(
                    "Die Position wurde erfolgreich entfernt!"
                );
                this.loadBelege();
            },
            error: (err) => {
                this.messageService.errorMessage(
                    "Die Position konnte nicht entfernt werden!",
                    err
                );
            },
        });
    }

    lockAbgabebeleg(a: Abgabebeleg) {
        a.status_id = AbgabebelegStatus.ABGESCHLOSSEN;
        this.api.updateEntity(environment.BASE_URI + "abgabebeleg/update.php", a).subscribe({
            next: (response) => {
                this.messageService.infoMessage(
                    "Der Beleg wurde erfolgreich abgeschlossen!"
                );
                this.loadBelege();
            },
            error: (err) => {
                this.loadBelege();
                this.messageService.errorMessage(
                    "Der Abgabebeleg " +
                    a.abgabebeleg_nr +
                    " konnte nicht abgeschlossen werden!",
                    err
                );
            },
        });
    }

    openAbgabebelegInNewTab(a: Abgabebeleg) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([
                "/belege/abgabebelege",
                a.abgabebeleg_id,
            ])
        );
        window.open(url, "_blank");
    }

    reopenBefund(b: Befundmitteilung) {
        this.api
            .post(environment.BASE_URI + "befund/korrigieren.php", b, null)
            .subscribe({
                next: (response) => {
                    this.openBefundInNewTab(b);
                    this.loadBelege();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        "Der Befund " +
                        b.tagebuch_nr +
                        " konnte nicht wieder geöffnet werden!",
                        err
                    );
                },
            });
    }

    openBefundInNewTab(b: Befundmitteilung) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([
                "/labor/befundmitteilungen",
                b.befund_id,
            ])
        );
        window.open(url, "_blank");
    }

    close() {
        this.dialogRef.close();
    }

    openCreateNewInvoiceDialog() {
        const input = new EnterValueInput();
        input.title = "Rechnung erstellen";
        input.text =
            "Bitte geben Sie noch das Rechnungsdatum ein, welches die neue Rechnung haben soll.";
        input.okButton = "Rechnung erstellen";
        input.buttonColor = "accent";
        input.addInputValue(
            "Rechnungsdatum",
            "date",
            this.invoice.rechnungsdatum
        );

        const dialogRef = this.dialog.open(EnterValueDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: EnterValueResponse) => {
                if (result?.result && result?.getValue(0)) {
                    const obj = {
                        rechnung_id: this.invoice.rechnung_id,
                        rechnungsdatum: result.getValue(0),
                    };

                    this.api
                        .post(
                            environment.BASE_URI +
                            "rechnung/generieren_neu.php",
                            obj,
                            null
                        )
                        .subscribe({
                            next: (response) => {
                                this.messageService.infoMessage(
                                    "Es wurde eine neue Rechnung erstellt"
                                );
                                this.router.navigate([
                                    "/finanzen",
                                    "rechnungen",
                                    response.rechnung_id,
                                ]);
                                this.dialogRef.close();
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    "Beim Erstellen der neuen Rechnung ist ein Fehler aufgetreten!",
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    openRemoveAbgabebelegDialog(a: Abgabebeleg) {
        const input = new ConfirmDialogInput();
        input.headline = "Abgabebeleg aus Rechnung entfernen";
        input.text =
            "Soll der Abgabebeleg aus der Rechnung entfernt werden? Dabei wird dieser Abgabebelg zuerst dupliziert und anschließend storniert. Beim Duplizieren werden die HIT/QS Status der einzelnen Positionen übernommen.";
        input.okButton = "Aus Rechnung entfernen";

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    // erst duplizieren und danach den orginalen stornieren
                    this.duplicateAbgabebeleg(a, 1).subscribe({
                        next: (response) => {
                            this.cancelAbgabebeleg(a);
                        },
                        error: (err) => {
                            this.messageService.errorMessage(
                                "Fehler beim Duplizieren des Abgabebelegs " +
                                a.abgabebeleg_nr,
                                err
                            );
                        },
                    });
                }
            },
        });
    }

    duplicateAbgabebeleg(
        abgabebeleg: Abgabebeleg,
        meldung_uebernehmen?: number
    ) {
        return this.api.post(
            environment.BASE_URI + "abgabebeleg/duplicate.php",
            abgabebeleg,
            undefined
        );
    }

    cancelAbgabebeleg(a: Abgabebeleg) {
        this.api
            .post(environment.BASE_URI + "abgabebeleg/stornieren.php", a, null)
            .subscribe({
                next: (response) => {
                    this.messageService.infoMessage(
                        `Der Abgabebeleg ${a.abgabebeleg_nr} wurde erfolgreich storniert!`
                    );
                    this.loadBelege();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        `Der Abgabebeleg ${a.abgabebeleg_nr} konnte nicht storniert werden!`,
                        err
                    );
                },
            });
    }

    cancelBefund(b: Befundmitteilung) {
        this.api
            .post(environment.BASE_URI + "befund/stornieren.php", b, null)
            .subscribe({
                next: (response) => {
                    this.messageService.infoMessage(
                        `Der Befund ${b.tagebuch_nr} wurde erfolgreich storniert!`
                    );
                    this.loadBelege();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        `Der Befund ${b.tagebuch_nr} konnte nicht storniert werden!`,
                        err
                    );
                },
            });
    }

    duplicateBefund(b: Befundmitteilung) {
        return this.api.post(
            environment.BASE_URI + "befund/duplizieren.php",
            b,
            null
        );
    }

    openRemoveBefundDialog(b: Befundmitteilung) {
        const input = new ConfirmDialogInput();
        input.headline = "Befundmitteilung aus Rechnung entfernen";
        input.text =
            "Soll die Befundmitteilung aus der Rechnung entfernt werden? Dabei wird diese Befundmitteilung zuerst dupliziert und anschließend storniert.";
        input.okButton = "Aus Rechnung entfernen";

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    // erst duplizieren und danach den orginalen stornieren
                    this.duplicateBefund(b).subscribe({
                        next: (response) => {
                            this.cancelBefund(b);
                        },
                        error: (err) => {
                            this.messageService.errorMessage(
                                "Fehler beim Duplizieren der Befundmitteilung " +
                                b.tagebuch_nr,
                                err
                            );
                        },
                    });
                }
            },
        });
    }
}
