<div>
  <p>Den QR Code bitte mit dem Smartphone scannen, um dieses Dokument zu unterschreiben.</p>
  <qr-code [value]="qrcodeUrl" [size]="250"></qr-code>
  <p>Oder klicken Sie <a [href]="qrcodeUrl" target="_blank">hier</a></p>
  <div *ngIf="tierarzt?.unterschrift" class="one-row">
    <mat-icon color="accent">info</mat-icon>
    <p style="font-size: .8em; font-weight:bold;">Unterschrift vom Tierarzt schon vorhanden!</p>
    <span class="spacer"></span>
  </div>
</div>
