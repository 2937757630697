import {Gruppe} from '../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import {Client} from '../../../../projects/kiene-core/src/lib/components/client/classes/client';

export class BefundmitteilungPosition {
    ba_id = 0;
    befund_id = 0;
    position = 1;
    artikel_id = 0;
    artikel = '';
    artikelmenge = 0;
    auflisten = 0;
    freitext = '';
    freitext_extern = '';
    fusszeile = '';
    idexx_testlaufkommentar = '';
    idexx_spezies_tierart_id: number;
    idexx_probenart = '';
    idexx_tierart = '';
    idexx_spezies = '';
    idexx: number;
    idexx_testlauf_id: number;
    abgeschlossen: number;
    einzelpreis: number;
}

export class Befundmitteilung {
    recposition: number;
    befund_id: number;
    untersuchungsmaterial: string;
    tagebuch_nr: string;
    eingang: Date;
    ausgang: Date;
    probenentnahme;
    Date;
    kunde_id: number;
    kunde: string;
    gruppe: string;
    gruppe_id: number;
    rechnung_id: number;
    erstellt: Date;
    erstellt_von: number;
    editiert: Date;
    editiert_von: number;
    status_id: number;
    status: string;
    pdf_id: number;
    positionen: BefundmitteilungPosition[] = [];
    pruefer_id: number;
    pruefer: string;
    befunde_extern: number;
    tierarztgruppe: string;
    antibiogramm_id: number;
    antibiogramme: number;
    untersuchungsauftraege: number;
    tierarzt: string;
    kunde_nr_intern: number;
    gruppe_nr_intern: string;
    kunde_ort: string;
    idexx: number;
}

export interface BefundmitteilungApi {
    records: Befundmitteilung[];
    count: number;
}

export class BefundmitteilungExtern {
    be_id: number;
    pdf_id: number;
    dateiname: string;
}

export interface BefundmitteilungExternApi {
    records: BefundmitteilungExtern[];
    count: number;
}

export class BefundmitteilungAllDownload {
    gruppe: Gruppe;
    client: Client;
    zeit_von: Date;
    zeit_bis: Date;
}

export interface BefundmitteilungPositionApi {
    records: BefundmitteilungPosition[];
    count: number;
}

export class BefundStatus {
    public static ERSTELLT = 11;
    public static ABGESCHLOSSEN = 12;
    public static FREIGEGEBEN = 13;
    public static STORNIERT = 14;
    public static WIRD_KORRIGIERT = 15;
}
