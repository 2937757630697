import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kiene-json-dialog',
  templateUrl: './json-dialog.component.html',
  styleUrls: ['./json-dialog.component.css']
})
export class JsonDialogComponent implements OnInit {

  json: string;
  text: string;

  constructor(public dialogRef: MatDialogRef<JsonDialogComponent>, @Inject(MAT_DIALOG_DATA) private data) { 
    if(this.data.json){
      this.json = JSON.stringify(this.data.json, null, 2);
    }
    if(this.data.text){
      this.text = this.data.text;
    }
  }

  ngOnInit(): void {
  }

}
