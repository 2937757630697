import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GruppeTierFilterDialogComponent } from '../gruppe-tier-filter-dialog/gruppe-tier-filter-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Gruppe } from '../../../gruppen/classes/gruppe';
import { TierFilterSettings } from '../gruppe-tier-filter-dialog/classes/tier-filter-settings';
import { TierFilter } from '../gruppe-tier-filter-dialog/classes/tier-filter';
import { Observable, Subject } from 'rxjs';
import { GruppeTierService } from '../../gruppe-tier.service';

@Component({
    selector: 'kiene-gruppe-tier-filter-button',
    templateUrl: './gruppe-tier-filter-button.component.html',
    styleUrls: ['./gruppe-tier-filter-button.component.scss']
})
export class GruppeTierFilterButtonComponent {


    tierFilter = TierFilter;
    @Input('gruppe') gruppe: Gruppe;
    @Input('tierFilterSettings') filterSettings: TierFilterSettings = new TierFilterSettings();
    @Output('filterSelected') filterSelected: EventEmitter<TierFilterSettings> = new EventEmitter<TierFilterSettings>();

    constructor(
        private dialog: MatDialog
    ) {
    }

    public openFilterDialog(options?: { filterSettings?: TierFilterSettings, info?: string }): Observable<boolean> {
        const sub = new Subject<boolean>();

        let tfs: TierFilterSettings;
        if (options?.filterSettings) {
            tfs = options.filterSettings;
        } else {
            tfs = this.filterSettings.clone();
            tfs.showAbgangslisten = true;
            tfs.showAbteile = true;
            tfs.showEinstalldatum = true;
            tfs.showFilter = true;
            tfs.showGeschlechter = true;
            tfs.showRassen = true;
            tfs.showReklamationen = true;
            tfs.showViehhaendler = true;
        }

        const dialogRef = this.dialog.open(GruppeTierFilterDialogComponent, {
            width: '90vw',
            data: {
                gruppe: this.gruppe,
                filterSettings: tfs,
                info: options?.info ? options?.info : null
            }
        });
        dialogRef.afterClosed().subscribe({
            next: (res: TierFilterSettings) => {
                if (res) {
                    this.filterSettings = res;
                    this.filterSelected.emit(this.filterSettings);
                }
                sub.next(true);
            }
        });
        return sub;
    }

}
