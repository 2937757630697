<h1 mat-dialog-title>Tierrasse {{ data?.tierrasse_id ? 'bearbeiten' : 'anlegen' }}</h1>
<div mat-dialog-content>
    <p>F&uuml;r Tierart: {{ data?.tierart }}</p>
    <mat-form-field style="width: 100%;">
        <mat-label>Name der Rasse</mat-label>
        <input type="text" matInput [disabled]="disabled()" [formControl]="rasse_name_ctrl">
    </mat-form-field>
</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-raised-button color="primary" (click)="save()"
            [disabled]="!rasse_name_ctrl.valid || !tierartCtrl.valid">Speichern
    </button>
</div>
