<div style="padding: 20px">
    <div class="one-row">
        <h1>
            Externer Untersuchungsauftrag
            {{
                untersuchungsauftragId === "neu"
                    ? "erstellen"
                    : untersuchungsauftragId
            }}
        </h1>
        <button
            *ngIf="untersuchungsauftrag.status_id === 91"
            mat-icon-button
            color="accent"
            matTooltip="Untersuchungsauftrag zwischenspeichern"
            (click)="save(untersuchungsauftrag.status_id)"
        >
            <mat-icon>save_alt</mat-icon>
        </button>
    </div>
    <mat-card>
        <mat-card-content>
            <h3>Allgemein</h3>
            <div class="one-row">
                <div style="width: 30%; min-width: 250px">
                    <kiene-client-group-autocomplete
                        [disabled]="untersuchungsauftrag.status_id >= 92"
                        [gruppe]="gruppe"
                        (selectedGroup)="this.gruppe = $event"
                        [focus]="true"
                    >
                    </kiene-client-group-autocomplete>
                </div>

                <mat-form-field style="width: 15%; min-width: 200px">
                    <input
                        type="text"
                        placeholder="Tagebuch-Nr"
                        matInput
                        [formControl]="tagebuchCtrl"
                    />
                </mat-form-field>
                <mat-form-field style="width: 30%; min-width: 200px">
                    <input
                        type="text"
                        placeholder="Kennzeichnung"
                        matInput
                        [formControl]="kennzeichnungCtrl"
                    />
                </mat-form-field>
                <span class="spacer"></span>
                <div style="width: 15%; min-width: 200px; text-align: right">
                    <span class="phito-status-badge">{{
                        untersuchungsauftrag.status
                            ? untersuchungsauftrag.status
                            : "Neu"
                    }}</span>
                </div>
            </div>
            <div class="one-row">
                <div style="width: 30%; min-width: 200px">
                    <kiene-autocomplete
                        [disabled]="untersuchungsauftrag.status_id >= 92"
                        [apiUrl]="'lieferant/'"
                        [placeholder]="'Labor'"
                        [displayField]="'bezeichnung'"
                        (elementSelected)="setLabor($event)"
                        [kieneObject]="labor"
                    ></kiene-autocomplete>
                </div>
                <mat-form-field style="width: 30%; min-width: 200px">
                    <input
                        matInput
                        [matDatepicker]="picker"
                        placeholder="Probenentnahme"
                        [formControl]="probenentnahmeCtrl"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <div style="width: 30%; min-width: 200px">
                    <span>Tierarzt: </span
                    ><span>{{
                        untersuchungsauftrag.tierarzt_id
                            ? untersuchungsauftrag.tierarzt
                            : kieneSession.vorname + " " + kieneSession.nachname
                    }}</span>
                </div>
                <span class="spacer"></span>
            </div>
        </mat-card-content>
    </mat-card>

    <div style="margin-top: 10px">
        <mat-chip-list>
            <mat-chip
                style="cursor: pointer"
                *ngFor="let ud of untersuchungsauftragDokumente"
                [removable]="true"
                [selectable]="true"
                (removed)="removeUntersuchungsauftragDokument(ud)"
                (click)="untersuchungsauftragDokumentSelected(ud)"
                >{{ ud.dateiname }}<mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </div>

    <mat-card
        *ngIf="
            untersuchungsauftrag.status_id < 92 &&
            labor !== undefined &&
            labor !== null
        "
        style="margin-top: 20px"
    >
        <mat-card-content>
            <h3>Position hinzufügen</h3>

            <form>
                <div class="one-row" style="width: fit-content">
                    <div style="width: 300px; margin-right: 15px">
                        <kiene-autocomplete
                            [apiUrl]="'artikel_autocomplete/'"
                            [params]="params"
                            [placeholder]="'Artikel'"
                            [displayField]="'artikel_me'"
                            (elementSelected)="setArtikel($event)"
                            [kieneObject]="selectedArtikel"
                        ></kiene-autocomplete>
                    </div>
                    <div style="width: 100px; margin-right: 15px">
                        <mat-form-field style="width: 100%">
                            <input
                                matInput
                                type="number"
                                placeholder="Anzahl"
                                [formControl]="anzahlCtrl"
                            />
                        </mat-form-field>
                    </div>
                    <div style="width: 400px; margin-right: 15px">
                        <mat-form-field style="width: 100%">
                            <input
                                matInput
                                type="string"
                                placeholder="Notiz"
                                [formControl]="notizCtrl"
                                (keydown)="checkEnterKey($event)"
                            />
                        </mat-form-field>
                    </div>

                    <div>
                        <button
                            mat-mini-fab
                            type="submit"
                            (click)="addCurrentPosition()"
                        >
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>

                    <span class="spacer"></span>
                </div>
            </form>
        </mat-card-content>
    </mat-card>

    <h4 style="padding-left: 20px">Positionen</h4>
    <table class="phito-table" style="margin-top: 20px; margin-left: 20px">
        <thead>
            <tr>
                <th>Pos.</th>
                <th>Artikel</th>
                <th>Anzahl</th>
                <th>Notiz</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let p of positionen; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ p.artikel }}</td>
                <td>{{ p.anzahl }}</td>
                <td>{{ p.notiz }}</td>
                <td>
                    <button
                        *ngIf="untersuchungsauftrag.status_id < 92"
                        class="delete-button"
                        mat-icon-button
                        (click)="removePosition(p)"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <div
        *ngIf="untersuchungsauftrag.status_id === 91"
        style="width: 100%; text-align: right; margin-top: 20px"
    >
        <button mat-flat-button (click)="close()" color="accent">
            Abschließen
        </button>
    </div>
</div>
