import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HitAnmeldungComponent } from './hit-anmeldung/hit-anmeldung.component';
import { HitRegistrierungComponent } from './hit-registrierung/hit-registrierung.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { PageLayoutModule } from '../components/page-layout/page-layout.module';
import { MatCardModule } from '@angular/material/card';
import {MatIconModule} from "@angular/material/icon";



@NgModule({
  declarations: [
    HitAnmeldungComponent,
    HitRegistrierungComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        PageLayoutModule,
        MatCardModule,
        MatIconModule
    ],
  exports: [
    HitAnmeldungComponent,
    HitRegistrierungComponent
  ]
})
export class HitModule { }
