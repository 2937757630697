<mat-form-field style="width: 100%;">
    <mat-label>{{selectorInput?.label}}</mat-label>
    <mat-select #select [formControl]="selectCtrl" (selectionChange)="setSelectedOption($event)"
        [compareWith]="selectorInput?.comparator">
        <mat-option *ngFor="let o of options" [value]="o">{{formatOptionText(o)}}</mat-option>
    </mat-select>
    <div matSuffix>
        <mat-spinner *ngIf="isLoading" [diameter]="25"></mat-spinner>
        <mat-icon *ngIf="!isLoading" (click)="clear($event)">clear</mat-icon>
    </div>
</mat-form-field>