import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CalendarWeek} from "../calender-week";
import {DateUtils} from "../date-utils";

@Component({
  selector: 'kiene-calendar-week-selector-dialog',
  templateUrl: './calendar-week-selector-dialog.component.html',
  styleUrls: ['./calendar-week-selector-dialog.component.scss'],
})
export class CalendarWeekSelectorDialogComponent implements OnInit {
  yearCtrl = new FormControl(new Date().getFullYear());
  years: number[] = [];
  numberOfWeeks = 0;
  weeks: CalendarWeek[] = [];
  currentWeek = 0;
  currentYear = 0;
  selectedWeek: CalendarWeek = new CalendarWeek();

  isMobile = false;

  constructor(
    public dialogRef: MatDialogRef<CalendarWeekSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private calendarWeek: CalendarWeek,
  ) {
  }

  ngOnInit(): void {
    const now = new Date();
    this.currentWeek = DateUtils.getCalendarWeekNumber(now);
    this.currentYear = now.getFullYear();

    if (this.calendarWeek) {
      this.selectedWeek = this.calendarWeek;
    }

    this.initYears();

    this.yearCtrl.valueChanges.subscribe({
      next: () => {
        this.initWeeks();
      },
    });
  }

  close() {
    this.dialogRef.close();
  }

  select(w: CalendarWeek) {
    this.dialogRef.close(w);
  }

  initWeeks() {
    this.weeks = [];

    this.numberOfWeeks = DateUtils.getNumberOfWeeks(this.yearCtrl.value);
    for (let i = 1; i <= this.numberOfWeeks; i++) {
      let w = DateUtils.getCalendarWeek(DateUtils.getDateOfMonday(i, this.yearCtrl.value));
      this.weeks.push(w);
    }
  }

  initYears() {
    for (let i = 1950; i <= 2100; i++) {
      this.years.push(i);
    }
    this.initWeeks();
  }

}
