import {API_BASE_URL_SERVICE} from "../../../../kiene-core.config";
import {KieneBackendApiService} from "../../../../services/backend-api/kiene-backend-api.service";
import {MessageService} from "../../../../services/message-service/message.service";
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Futtermittellieferant} from '../lieferant';

@Component({
    selector: 'kiene-lieferant-anlegen-dialog',
    templateUrl: './lieferant-anlegen-dialog.component.html',
    styleUrls: ['./lieferant-anlegen-dialog.component.scss']
})
export class LieferantAnlegenDialogComponent implements OnInit {
    nameCtrl = new FormControl(undefined, [Validators.required]);
    strasseCtrl = new FormControl();
    plzCtrl = new FormControl();
    ortCtrl = new FormControl();
    emailCtrl = new FormControl(undefined, [Validators.email, Validators.required]);
    telefonCtrl = new FormControl();
    mobilCtrl = new FormControl();

    constructor(public dialogRef: MatDialogRef<LieferantAnlegenDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private lieferantName: string,
                private api: KieneBackendApiService,
                private messageService: MessageService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    ngOnInit(): void {
        if (this.lieferantName) {
            this.nameCtrl.setValue(this.lieferantName);
        }
    }

    anlegen() {
        const lieferant = new Futtermittellieferant();
        lieferant.bezeichnung = this.nameCtrl.value;
        lieferant.strasse = this.strasseCtrl.value;
        lieferant.plz = this.plzCtrl.value;
        lieferant.ort = this.ortCtrl.value;
        lieferant.email = this.emailCtrl.value;
        lieferant.telefon = this.telefonCtrl.value;
        lieferant.mobil = this.mobilCtrl.value;

        this.api.post(this.apiBaseUrl + 'futtermittellieferant/update.php', lieferant).subscribe({
            next: response => {
                this.dialogRef.close(response);
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Anlegen eines Lieferanten!', err);
            }
        });
    }

}
