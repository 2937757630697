import {Betriebsart} from '../../stammdaten/vvvo/classes/betriebsart';

export class Client {

    kunde_id: number;
    kunde_nr_intern: number;
    bezeichnung_final: string;
    zusatz_final: string;
    kommentar: string;
    strasse_final: string;
    plz_final: string;
    ort_final: string;
    ortsteil_final: string;

    bezeichnung: string;
    zusatz: string;
    strasse: string;
    plz: string;
    ort: string;
    ortsteil: string;

    betrieb_id: number;

    abweichende_lieferadresse: number;
    firmenname_lieferung: string;
    zusatz_lieferung: string;
    strasse_lieferung: string;
    plz_lieferung: string;
    ort_lieferung: string;
    ortsteil_lieferung: string;

    abweichende_rechnungsadresse: number;
    firmenname_rechnung: string;
    zusatz_rechnung: string;
    strasse_rechnung: string;
    plz_rechnung: string;
    ort_rechnung: string;
    ortsteil_rechnung: string;

    belege_email: string;

    vvvo: string;
    email: string;
    email_ug: string;
    unternehmensgruppe: string;
    unternehmensgruppe_id: number;
    // benutze_ug_anschrift: number;
    tierarztgruppe: string;
    tierarztgruppe_id: number;
    benutzer_id: number;
    erstellt: Date;
    editiert: Date;
    tierart: string;
    tierart_id: number;
    telefon_1: string;
    telefon_2: string;
    mobil_1: string;
    mobil_2: string;
    fax: string;
    fax_bevorzugen: number;
    behandlungsanfrage: number;
    hit_qs_teilnahme: number;
    nicht_qs_zertifiziert: number;
    betriebsarten: Betriebsart[] = [];
    betriebsart: string;
    betriebsart_id: number;
    rechnung_mail: number;
    rechnung_brief: number;
    selbstimpfer: number;
    sgs_nummer: string;
    // lieferant_id_veterinaeramt: number;
    // lieferant_id_ueberwachungsbehoerde: number;
    email_veterinaeramt: string;
    email_ueberwachungsbehoerde: string;
    aktiv: number;
    buchungskonto: string;
    buchungsgegenkonto: string;
    tierbewegung_melden: number;
    kaelbermast: number;
    ohrmarke: number;

    kp_abw_email: string;
    kp_abw_rechnungsadresse: number;
    kp_abw_rechnungsadresse_nachname: string;
    kp_abw_rechnungsadresse_vorname: string;
    kp_abw_rechnungsadresse_strasse: string;
    kp_abw_rechnungsadresse_ort: string;
    kp_abw_rechnungsadresse_plz: string;
    kp_iban: string;
    kp_kontoinhaber: string;
    kp_kreditinstitut: string;
    kp_lastschrift: number;
    kp_rechnung_email: number;
    kp_rechnung_post: number;
    bestandsbuch_digital: number;
    bestandsbuch_digital_historie: number;

    pin: string;

    schwein: number;
    rind: number;
    eu_ausland: number;
    ust_id: string;

    tierzahl: string;
    tierzahl_sauen: number;
    tierzahl_saugferkel: number;
    tierzahl_absatzferkel: number;
    tierzahl_mastschweine: number;
    tierzahl_kuehe: number;
    tierzahl_kaelber: number;
    tierzahl_fresser: number;
    tierzahl_bullen: number;
    tierzahl_mastrinder: number;
    kp_abw_rechnungsadresse_ortsteil: string;
    tsk_nummer: string;

    betriebstyp_id: number;
    betriebstyp: string;

    rechnung_gruppe: number;

    futtermittellieferant: string;
    futtermittellieferant_id: number;
}


export interface ClientApi {
    records: Client[];
    count: number;
}

export class KundeArtikel {
    ka_id: number;
    kunde_id: number;
    kunde: string;
    zusatz: string;
    artikel_id: number;
    artikel: string;
    artikel_me: string;
    einzelpreis: number;
}
