<h1 mat-dialog-title> Inventur bearbeiten </h1>
<div mat-dialog-content>
    <mat-form-field style=" width: 100%;">
        <mat-label>Name</mat-label>
        <input [formControl]="nameCtrl" matInput aria-label="Name" required>
    </mat-form-field>

</div>
<div mat-dialog-actions>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button (click)="save()" color="accent">Speichern</button>
</div>
