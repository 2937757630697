import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatButtonToggleChange } from "@angular/material/button-toggle";
import { ZeitauswahlChange } from "./zeitauswahl-change";
import { ZeitauswahlService } from "./zeitauswahl.service";

@Component({
    selector: "kiene-zeitauswahl",
    templateUrl: "./zeitauswahl.component.html",
    styleUrls: ["./zeitauswahl.component.css"],
})
export class ZeitauswahlComponent implements OnInit {
    @Output("change") change = new EventEmitter<ZeitauswahlChange>();

    @Input("hideAggregate") hideAggregate = false;

    startDateCtrl = new UntypedFormControl();
    endDateCtrl = new UntypedFormControl();
    aggregierenCtrl = new UntypedFormControl("woche");

    aktuellerZeitintervalInStunden = 24;
    aktuellerZeitinterval = "monat";
    individuellInterval = 0;
    aktuellVon: Date;
    aktuellBis: Date;

    constructor(private service: ZeitauswahlService) { }

    ngOnInit(): void {
        let z = this.service.getZeitauswahl();

        this.aktuellVon = z.von;
        this.aktuellBis = z.bis;
        this.aggregierenCtrl.setValue(z.aggregieren);
        this.aktuellerZeitinterval = z.aktuellerZeitinterval;

        this.berechneStartUndEnde();

        this.aggregierenCtrl.valueChanges.subscribe(() =>
            this.fireEvent("ngOnInit")
        );
    }

    fireEvent(caller?: string) {
        let event = new ZeitauswahlChange();
        event.von = this.aktuellVon;
        event.bis = this.aktuellBis;
        event.aggregieren = this.aggregierenCtrl.value;
        event.aktuellerZeitinterval = this.aktuellerZeitinterval;
        if (event.von && event.bis) {
            this.service.setZeitauswahl(event);
            this.change.emit(event);
        }
    }

    rewind() {
        if (this.aktuellerZeitinterval === "tag") {
            this.aktuellVon = new Date(
                this.aktuellVon.setDate(this.aktuellVon.getDate() - 1)
            );
            this.aktuellBis = new Date(
                this.aktuellBis.setDate(this.aktuellBis.getDate() - 1)
            );
        } else if (this.aktuellerZeitinterval === "woche") {
            this.aktuellVon = new Date(
                this.aktuellVon.setDate(this.aktuellVon.getDate() - 7)
            );
            this.aktuellBis = new Date(
                this.aktuellBis.setDate(this.aktuellBis.getDate() - 7)
            );
        } else if (this.aktuellerZeitinterval === "monat") {
            this.aktuellVon = new Date(
                this.aktuellVon.setMonth(this.aktuellVon.getMonth() - 1)
            );
            this.aktuellBis = new Date(
                this.aktuellVon.getFullYear(),
                this.aktuellVon.getMonth() + 1,
                0
            );
        } else if (this.aktuellerZeitinterval === "quartal") {
            this.aktuellVon = new Date(
                this.aktuellVon.setMonth(this.aktuellVon.getMonth() - 3)
            );
            this.aktuellBis = new Date(
                this.aktuellVon.getFullYear(),
                this.aktuellVon.getMonth() + 3,
                0
            );
        } else if (this.aktuellerZeitinterval === "halbjahr") {
            this.aktuellVon = new Date(
                this.aktuellVon.setMonth(this.aktuellVon.getMonth() - 6)
            );
            this.aktuellBis = new Date(
                this.aktuellVon.getFullYear(),
                this.aktuellVon.getMonth() + 6,
                0
            );
        } else if (this.aktuellerZeitinterval === "jahr") {
            this.aktuellVon = new Date(
                this.aktuellVon.setMonth(this.aktuellVon.getMonth() - 12)
            );
            this.aktuellBis = new Date(
                this.aktuellVon.getFullYear(),
                this.aktuellVon.getMonth() + 12,
                0
            );
        } else if (this.aktuellerZeitinterval === "individuell") {
            this.aktuellVon = new Date(
                this.aktuellVon.setDate(
                    this.aktuellVon.getDate() - this.individuellInterval
                )
            );
            this.aktuellBis = new Date(
                this.aktuellBis.setDate(
                    this.aktuellBis.getDate() - this.individuellInterval
                )
            );
        }

        this.startDateCtrl.setValue(this.aktuellVon);
        this.endDateCtrl.setValue(this.aktuellBis);

        this.fireEvent("rewind");
    }

    forward() {
        if (this.aktuellerZeitinterval === "tag") {
            this.aktuellVon = new Date(
                this.aktuellVon.setDate(this.aktuellVon.getDate() + 1)
            );
            this.aktuellBis = new Date(
                this.aktuellBis.setDate(this.aktuellBis.getDate() + 1)
            );
        } else if (this.aktuellerZeitinterval === "woche") {
            this.aktuellVon = new Date(
                this.aktuellVon.setDate(this.aktuellVon.getDate() + 7)
            );
            this.aktuellBis = new Date(
                this.aktuellBis.setDate(this.aktuellBis.getDate() + 7)
            );
        } else if (this.aktuellerZeitinterval === "monat") {
            this.aktuellVon = new Date(
                this.aktuellVon.setMonth(this.aktuellVon.getMonth() + 1)
            );
            this.aktuellBis = new Date(
                this.aktuellVon.getFullYear(),
                this.aktuellVon.getMonth() + 1,
                0
            );
        } else if (this.aktuellerZeitinterval === "quartal") {
            this.aktuellVon = new Date(
                this.aktuellVon.setMonth(this.aktuellVon.getMonth() + 3)
            );
            this.aktuellBis = new Date(
                this.aktuellVon.getFullYear(),
                this.aktuellVon.getMonth() + 3,
                0
            );
        } else if (this.aktuellerZeitinterval === "halbjahr") {
            this.aktuellVon = new Date(
                this.aktuellVon.setMonth(this.aktuellVon.getMonth() + 6)
            );
            this.aktuellBis = new Date(
                this.aktuellVon.getFullYear(),
                this.aktuellVon.getMonth() + 6,
                0
            );
        } else if (this.aktuellerZeitinterval === "jahr") {
            this.aktuellVon = new Date(
                this.aktuellVon.setMonth(this.aktuellVon.getMonth() + 12)
            );
            this.aktuellBis = new Date(
                this.aktuellVon.getFullYear(),
                this.aktuellVon.getMonth() + 12,
                0
            );
        } else if (this.aktuellerZeitinterval === "individuell") {
            this.aktuellVon = new Date(
                this.aktuellVon.setDate(
                    this.aktuellVon.getDate() + this.individuellInterval
                )
            );
            this.aktuellBis = new Date(
                this.aktuellBis.setDate(
                    this.aktuellBis.getDate() + this.individuellInterval
                )
            );
        }

        this.startDateCtrl.setValue(this.aktuellVon);
        this.endDateCtrl.setValue(this.aktuellBis);

        this.fireEvent("forward");
    }

    morgens(d: Date) {
        let date = new Date(d);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        return date;
    }

    abends(d: Date) {
        let date = new Date(d);
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        return date;
    }

    montag() {
        let d = this.aktuellBis;
        let dayOfWeek = d.getDay();
        let monday = d.getDate() - dayOfWeek + (dayOfWeek == 0 ? -6 : 1);
        return new Date(d.setDate(monday));
    }

    erster(d: Date) {
        d.setDate(1);
        return d;
    }

    closedDatepicker() {
        this.aktuellerZeitinterval = "individuell";
        this.aktuellVon = this.startDateCtrl.value;
        this.aktuellBis = this.endDateCtrl.value;
        this.individuellInterval = this.differenzInTagen(
            this.aktuellVon,
            this.aktuellBis
        );
        this.fireEvent("closeedDatepicker");
    }

    berechneStartUndEnde() {
        if (this.aktuellerZeitinterval === "tag") {
            this.startDateCtrl.setValue(this.morgens(this.aktuellBis));
            this.endDateCtrl.setValue(this.abends(this.aktuellBis));
        } else if (this.aktuellerZeitinterval === "woche") {
            let montag = this.montag();
            this.startDateCtrl.setValue(this.morgens(montag));
            let ende = this.abends(montag);
            ende.setDate(ende.getDate() + 6);
            this.endDateCtrl.setValue(ende);
        } else if (this.aktuellerZeitinterval === "monat") {
            let erster = this.erster(this.aktuellBis);
            this.startDateCtrl.setValue(erster);
            this.endDateCtrl.setValue(
                new Date(erster.getFullYear(), erster.getMonth() + 1, 0)
            );
        } else if (this.aktuellerZeitinterval === "quartal") {
            let quartal = this.getQuartal(this.aktuellBis);
            this.startDateCtrl.setValue(quartal.von);
            this.endDateCtrl.setValue(quartal.bis);
        } else if (this.aktuellerZeitinterval === "halbjahr") {
            let halbjahr = this.getHalbjahr(this.aktuellBis);
            this.startDateCtrl.setValue(halbjahr.von);
            this.endDateCtrl.setValue(halbjahr.bis);
        } else if (this.aktuellerZeitinterval === "jahr") {
            let von = new Date(this.aktuellBis.getFullYear(), 0, 1);
            let bis = new Date(this.aktuellBis.getFullYear(), 11, 31);
            this.startDateCtrl.setValue(von);
            this.endDateCtrl.setValue(bis);
        } else if (this.aktuellerZeitinterval === "individuell") {
            this.aktuellVon = this.startDateCtrl.value;
            this.aktuellBis = this.endDateCtrl.value;
            this.individuellInterval = this.differenzInTagen(
                this.aktuellVon,
                this.aktuellBis
            );
        }

        this.aktuellVon = this.startDateCtrl.value;
        this.aktuellBis = this.endDateCtrl.value;

        this.fireEvent("berechneStartUndEnde");
    }

    differenzInTagen(von: Date, bis: Date): number {
        let diffInMilli = bis?.getTime() - von?.getTime();
        return Math.ceil(diffInMilli / (1000 * 60 * 60 * 24));
    }
    /**
     * Gibt die Quartalsnummer des übergebenen Datums zurück.
     *
     * @param {Date} Datum
     * @return {number} Quartalsnummer
     * @memberof ZeitauswahlComponent
     */
    getQuartal(d: Date): { von: Date; bis: Date } {
        // Januar = 0, Dezember = 11
        let month = d.getMonth();
        if (month < 3) {
            let von = new Date(d.getFullYear(), 0, 1);
            let bis = new Date(d.getFullYear(), 3, 0);
            return { von: von, bis: bis };
        }
        if (month < 6) {
            let von = new Date(d.getFullYear(), 3, 1);
            let bis = new Date(d.getFullYear(), 6, 0);
            return { von: von, bis: bis };
        }
        if (month < 9) {
            let von = new Date(d.getFullYear(), 6, 1);
            let bis = new Date(d.getFullYear(), 9, 0);
            return { von: von, bis: bis };
        }
        let von = new Date(d.getFullYear(), 9, 1);
        let bis = new Date(d.getFullYear(), 11, 31);
        return { von: von, bis: bis };
    }

    getHalbjahr(d: Date): { von: Date; bis: Date } {
        let month = d.getMonth();
        if (month < 6) {
            let von = new Date(d.getFullYear(), 0, 1);
            let bis = new Date(d.getFullYear(), 5, 30);
            return { von: von, bis: bis };
        }
        let von = new Date(d.getFullYear(), 6, 1);
        let bis = new Date(d.getFullYear(), 11, 31);
        return { von: von, bis: bis };
    }

    selectZeitinterval(event: MatButtonToggleChange) {
        if (event.value === "tag") {
            this.aktuellerZeitintervalInStunden = 24;
        } else if (event.value === "woche") {
            this.aktuellerZeitintervalInStunden = 24 * 7;
        } else if (event.value === "monat") {
            this.aktuellerZeitintervalInStunden = 24 * 30;
        } else if (event.value === "quartal") {
            this.aktuellerZeitintervalInStunden = 24 * 30 * 3;
        } else if (event.value === "halbjahr") {
            this.aktuellerZeitintervalInStunden = 24 * 30 * 6;
        } else if (event.value === "jahr") {
            this.aktuellerZeitintervalInStunden = 24 * 30 * 12;
        }
        this.aktuellerZeitinterval = event.value;

        this.berechneStartUndEnde();
    }
}
