<div>

    <div *ngIf="futtermittellieferant">
        <span>{{ futtermittellieferant.bezeichnung }}</span><br>
        <span>{{ futtermittellieferant.strasse }}</span><br>
        <span>{{ futtermittellieferant.plz }}&nbsp;{{ futtermittellieferant.ort }}</span>
        <br>
        <span>{{ futtermittellieferant.telefon }}</span>
    </div>


    <div style="width: 100%;">

        <kiene-autocomplete #nameAutocomplete (elementSelected)="selectedLieferant($event)"
                            *ngIf="!futtermittellieferant?.futtermittellieferant_id"
                            [displayFields]="['bezeichnung', 'ort']"
                            [separator]="', '" [emitFreeText]="false" [createNewAllowed]="true"
                            [createNewText]="'Lieferant anlegen'"
                            (createNew)="createNewFuttermittellieferant($event)" [focus]="false"
                            [genericApiUrl]="lieferantPath"
                            [panelWidth]="'100%'" [placeholder]="'Name'" [required]="true" [showClear]="false"
                            [showLoadingIndicator]="true" style=" width: 100%">
        </kiene-autocomplete>
    </div>


    <div style="position: sticky; bottom: 0; background-color: white; padding: 12px 0;"
         class="one-row">
        <button (click)="createOrUpdateFuttermittellieferant()" [disabled]="isLoading" color="accent" mat-flat-button>
            Speichern
        </button>
        <span class="spacer"></span>
        <ng-content select="[additional-actions]"></ng-content>
    </div>


</div>
