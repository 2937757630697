<div mat-dialog-title>
    Ohrmarkenscan löschen
</div>
<div mat-dialog-content>
    <p>
        Möchten Sie den Scan {{ohrmarke}} wirklich löschen?
    </p>
    </div>
<div mat-dialog-actions>
    <button mat-button (click)="ablehnen()">Abbrechen</button>
    <button mat-button (click)="akzeptieren()">Löschen</button>
</div>
