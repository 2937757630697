import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'kiene-share-button',
    templateUrl: './share-button.component.html',
    styleUrls: ['./share-button.component.scss']
})
export class ShareButtonComponent implements OnInit {

    @Input('text') text: string;
    @Input('title') title: string;
    @Input('files') files: File[] = [];
    @Input('url') url: string;
    @Input('disabled') disabled = false;

    isIos = false;

    constructor() {
    }

    ngOnInit(): void {
        this.checkBrowser();
    }

    share() {
        const shareData = {
            url: this.url,
            text: this.text,
            title: this.title,
            files: this.files
        };
        window.navigator.share(shareData).then(() => {
            console.log('fertig mit geshared :-)');
        })
            .catch(error => {
                console.error(error);
            });
    }

    private checkBrowser() {
        this.isIos = /iPad|iPhone|iPod/.test(window.navigator.userAgent);
    }
}
