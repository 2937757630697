export class  Ersteinrichtung {
    vvvo = 0;
    entsorger = 0;
    haendler = 0;


    constructor(vvvo?: number, entsorger?: number, haendler?: number) {
        this.vvvo = vvvo;
        this.entsorger = entsorger;
        this.haendler = haendler;
    }
}
