<h1 mat-dialog-title>Gruppe erstellen</h1>
<div mat-dialog-content>
    <p style="width: 100%">
        {{ input?.kunde?.bezeichnung_final }} {{ input?.kunde?.zusatz_final }}
    </p>

    <mat-checkbox *ngIf="sgsEinblenden" [formControl]="sgsMeldenCtrl">An SGS melden</mat-checkbox>

    <mat-form-field style="width: 100%;">
        <mat-label>Produktionsabschnitt</mat-label>
        <mat-select [formControl]="produktionsabschnittCtrl" [compareWith]="produktionsabschnittComparator">
            <mat-option *ngFor="let p of produktionsabschnitte" [value]="p">{{ p.produktionsabschnitt }}</mat-option>
        </mat-select>
    </mat-form-field>

    <kiene-autocomplete *ngIf="input?.kunde" #standortAutocomplete (deleteSelected)="deleteStall($event)"
                        (elementSelected)="setStandort($event)" [displayFields]="['stall','sgs_nummer']" separator=", "
                        [emitFreeText]="true" [focus]="true" [genericApiUrl]="'gruppe/read_stall.php'"
                        [kieneObject]="standort"
                        [params]="standortParams" [placeholder]="'Stall'" [showClear]="false" [showDeleteButton]="true">
    </kiene-autocomplete>

    <mat-form-field *ngIf="sgsNummerEinblenden" style="width: 100%">
        <mat-label>SGS Nummer</mat-label>
        <input [formControl]="sgsNummerCtrl" matInput type="text"/>
    </mat-form-field>

    <mat-form-field style="width: 100%">
        <mat-label>Gruppenname</mat-label>
        <input [formControl]="gruppennameCtrl" matInput type="text"/>
    </mat-form-field>

    <mat-form-field *ngIf="input?.kunde?.kaelbermast" style="width: 100%">
        <mat-label>Aufstalleinheit</mat-label>
        <div class="one-row">
            <input [formControl]="aufstalleinheitJahrCtrl" matInput max="99" maxlength="2" min="1" style="
                        width: 40px;
                        margin-right: 12px;
                        border: 1px solid lightgray;
                        padding: 4px;
                        border-radius: 4px;
                    " type="number"/>
            <span>- AE -</span>
            <input [formControl]="aufstalleinheitNummerCtrl" matInput style="
                        width: 60px;
                        margin-right: 12px;
                        border: 1px solid lightgray;
                        padding: 4px;
                        border-radius: 4px;
                    " type="number"/>
        </div>
    </mat-form-field>

    <div *ngIf="!lss.kostenpflichtigAktiv() && input?.einstalldatumAusblenden === false" class="one-row">
        <mat-form-field style="width: 45%">
            <mat-label>Einstalldatum von</mat-label>
            <input [formControl]="einstalldatumVonCtrl" [matDatepicker]="einstalldatum_von" [readonly]="true" matInput
            />
            <mat-datepicker-toggle [for]="einstalldatum_von" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #einstalldatum_von></mat-datepicker>
        </mat-form-field>

        <mat-form-field style="width: 45%">
            <mat-label>Einstalldatum bis</mat-label>
            <input [formControl]="einstalldatumBisCtrl" [matDatepicker]="einstalldatum_bis" [readonly]="true" matInput
            />
            <mat-datepicker-toggle [for]="einstalldatum_bis" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #einstalldatum_bis></mat-datepicker>
        </mat-form-field>
    </div>

    <ng-container *ngIf="input?.kunde?.schwein === 1">
        <div *ngIf="!input?.kunde?.tierbewegung_melden">
            <mat-form-field style="margin-right: 12px">
                <mat-label>Anzahl Tiere</mat-label>
                <input [formControl]="anzahlTiereCtrl" matInput maxlength="15" type="number"/>
            </mat-form-field>

            <mat-form-field style="margin-right: 12px">
                <mat-label>Alter bei Einstallung (Tage)</mat-label>
                <input [formControl]="alterEinstallungCtrl" matInput maxlength="15" type="number"/>
            </mat-form-field>
        </div>
    </ng-container>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button (click)="close()" mat-button>Abbrechen</button>
    <button (click)="save()" color="accent" mat-flat-button [disabled]="!isFormValid()">Erstellen</button>
</div>
