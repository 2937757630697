import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpParams } from '@angular/common/http';
import { Client } from '../../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { LocalStorageService } from '../../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-change-current-client-dialog',
    templateUrl: './change-current-client-dialog.component.html',
    styleUrls: ['./change-current-client-dialog.component.scss'],
})
export class ChangeCurrentClientDialogComponent implements OnInit {
    params: HttpParams;
    currentClient: Client;

    constructor(
        public dialogRef: MatDialogRef<ChangeCurrentClientDialogComponent>,
        private localStorageService: LocalStorageService,
        @Inject(MAT_DIALOG_DATA) private data
    ) {
        // dialogRef.disableClose = true;
        this.currentClient = data.client;
    }

    ngOnInit() {
        this.params = new HttpParams().set('limit', '25');
        // this.params = this.params.set('orderby', 'bezeichnung asc'); kein Order by mitschicken!
        this.params = this.params.set('aktiv', '1');
    }

    setCurrentClient(client: Client) {
        const result = {
            save: true,
            client: client,
        };

        this.dialogRef.close(result);
    }

    quit() {
        const result = {
            save: false,
        };

        this.dialogRef.close(result);
    }

    getDisplayFields(): string[] {
        let df = [
            'vvvo',
            'bezeichnung_final',
            'zusatz_final',
            'kunde_nr_intern',
            'ort_final',
            'strasse_final',
            'unternehmensgruppe'
        ];
        if (this.localStorageService.isCurrentUserPhitoUser()) {
            df.push('kunde_id');
            df.push('betrieb_id');
        }
        return df;
    }

    getClient() {
        if (this.currentClient) {
            return '(' + this.currentClient.bezeichnung_final + ')';
        }
        return '';
    }
}
