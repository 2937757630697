<h1 mat-dialog-title>Datei {{dateiname}}</h1>
<div mat-dialog-content>
    <div *ngIf="isImage">
        <img [src]="safeUrl" width="100%;">
    </div>
    <div *ngIf="isPdf">
        <pdf-viewer
            [autoresize]="false"
            [original-size]="false"
            [render-text]="true"
            [show-all]="true"
            [src]="pdfBase64"
            [zoom]="2 * 0.95"
            style="height: 50vh; width: 100%; overflow: scroll"
        ></pdf-viewer>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()">Schlie&szlig;en</button>
</div>
