import {Component, Inject, OnInit} from '@angular/core';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {TextProposal} from './textkuerzel';
import {MessageService} from '../../services/message.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {
    PdfViewerDesktopService
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {ClientService} from '../../administration/client/client.service';
import {Router} from '@angular/router';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {TextTableColumn} from '../../components/kiene-table/table-column';
import {TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {TextProposalService} from './text-proposal.service';

@Component({
    selector: 'kiene-text-autocomplete',
    templateUrl: './text-autocomplete.component.html',
    styleUrls: ['./text-autocomplete.component.scss'],
})
export class TextAutocompleteComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private pdfService: PdfViewerDesktopService,
        private clientService: ClientService,
        private router: Router,
        private api: KieneTableService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private service: TextProposalService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Text Autovervollständigung';
        this.tableDescriptor.uniqueIdentifier = 'Text Autovervollständigung';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'textkuerzel/';
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;

        this.tableDescriptor.columns = this.getTableColumns();
        this.tableDescriptor.options = this.getTableOptions();

        this.tableDescriptor.initialSortColumn = 'textkuerzel';

        this.tableDescriptor.permissionView = 1411;
        this.tableDescriptor.permissionCreate = 1412;
        this.tableDescriptor.permissionUpdate = 1413;
        this.tableDescriptor.permissionDelete = 1414;

    }

    getTableColumns() {
        const columns = [];
        columns.push(new TextTableColumn('textkuerzel', 'Kürzel'));
        columns.push(new TextTableColumn('wortgruppe', 'Text'));
        return columns;
    }

    getTableOptions() {
        const options = [];
        options.push(new TableOption('edit', 'bearbeiten', 'edit', 1413));
        options.push(new TableOption('delete', 'löschen', 'delete', 1414));
        return options;
    }


    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'edit') {
            this.openEditTextkuerzelDialog(event.value);
        } else if (event.name === 'delete') {
            this.deleteTextProposal(event.value);
        } else if (event.name === 'add') {
            this.openAddTextProposalDialog();
        }
    }

    // loadTextProposals() {
    //     this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    //
    //     merge(this.sort.sortChange, this.paginator.page)
    //         .pipe(
    //             startWith({}),
    //             switchMap(() => {
    //                 this.isLoading = true;
    //                 this.offset =
    //                     this.paginator.pageIndex != null &&
    //                     this.paginator.pageSize != null
    //                         ? this.paginator.pageIndex * this.paginator.pageSize
    //                         : 0;
    //                 this.limit =
    //                     this.paginator.pageSize != null
    //                         ? this.paginator.pageSize
    //                         : this.limit;
    //                 this.sortColumn =
    //                     this.sort.active != null
    //                         ? this.sort.active
    //                         : this.sortColumn;
    //                 return this.service!.loadAllTextProposals(
    //                     this.sortColumn,
    //                     this.sort.direction,
    //                     this.offset,
    //                     this.limit,
    //                     this.searchInput.nativeElement.value
    //                 );
    //             }),
    //             map((data) => {
    //                 this.isLoading = false;
    //                 this.totalCount = data.count;
    //                 return data.records;
    //             }),
    //             catchError(() => {
    //                 this.isLoading = false;
    //                 return observableOf([]);
    //             })
    //         )
    //         .subscribe((data) => (this.textAutocompleteDataSource.data = data));
    // }

    openAddTextProposalDialog() {
        const dialogRef = this.dialog.open(AddTextkuerzelDialog, {
            width: '700px',
        });

        dialogRef.afterClosed().subscribe((result) => {
            const textProposal: TextProposal = result;

            if (textProposal) {
                this.service.createTextProposal(textProposal).subscribe(
                    (response) => {
                        const updatedTextProposal: TextProposal = response;
                        this.messageService.infoMessage(
                            'Textkuerzel erfolgreich gespeichert!'
                        );
                        this.api.reload('openAddTextProposalDialog');
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Fehler: Textkuerzel konnte nicht gespeichert werden!',
                            error
                        );
                    }
                );
            }
        });
    }

    openEditTextkuerzelDialog(row: TextProposal) {
        const dialogRef = this.dialog.open(EditTextkuerzelDialog, {
            width: '700px',
            data: {textProposal: row},
        });

        dialogRef.afterClosed().subscribe((result) => {
            const textProposal: TextProposal = result;

            if (textProposal) {
                this.service.updateTextProposal(textProposal).subscribe(
                    (response) => {
                        const updatedTextProposal: TextProposal = response;
                        this.messageService.infoMessage(
                            'Textkuerzel erfolgreich gespeichert!'
                        );
                        this.api.reload('openEditTextkuerzelDialog');
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Fehler: Textkuerzel konnte nicht gespeichert werden!',
                            error
                        );
                    }
                );
            }
        });
    }

    openDeleteConfirmDialog(textProposal: TextProposal) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '350px',
            data: {
                title: 'Textkürzeleintrag löschen',
                text:
                    'Wollen Sie den Textkürzeleintrag ' +
                    textProposal.textkuerzel +
                    ' wirklich löschen?',
                textButton: 'Löschen',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.deleteTextProposal(textProposal);
            }
        });
    }

    deleteTextProposal(textProposal: TextProposal) {
        this.service.deleteTextProposal(textProposal).subscribe(
            (response) => {
                this.messageService.infoMessage(
                    'Tierarztgruppe ' +
                    textProposal.textkuerzel +
                    ' erfolgreich entfernt!'
                );
                this.api.reload('deleteTextProposal');
            },
            (error) => {
                console.error(error);
                this.messageService.errorMessage(
                    'Löschen von Tierarztgruppe ' +
                    textProposal.textkuerzel +
                    ' ist fehlgeschlagen!',
                    error
                );
            }
        );
    }
}

@Component({
    selector: 'edit-textkuerzel-dialog',
    template: `<h3 mat-dialog-title>
        Text f&uuml;r K&uuml;rzel {{ textkuerzel }} &auml;ndern
    </h3>
    <mat-dialog-content>
        <mat-form-field style="width: 100%;">
            <input
                type="text"
                matInput
                placeholder="Text"
                [(ngModel)]="wortgruppe"
            />
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button color="primary" (click)="onCancelClick()">
            Abbrechen
        </button>
        <button mat-button color="primary" (click)="closeAndSafe()">
            Speichern
        </button>
    </mat-dialog-actions>`,
})
export class EditTextkuerzelDialog {
    textkuerzel = '';
    wortgruppe = '';

    constructor(
        public dialogRef: MatDialogRef<EditTextkuerzelDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.textkuerzel = data.textProposal.textkuerzel;
        this.wortgruppe = data.textProposal.wortgruppe;
    }

    closeAndSafe() {
        const textProp: TextProposal = new TextProposal();
        textProp.textkuerzel = this.textkuerzel;
        textProp.wortgruppe = this.wortgruppe;
        this.dialogRef.close(textProp);
    }

    onCancelClick(): void {
        this.dialogRef.close(null);
    }
}

@Component({
    selector: 'add-textkuerzel-dialog',
    template: `<h3 mat-dialog-title>Textk&uuml;rzel hinzuf&uuml;gen</h3>
    <mat-dialog-content>
        <mat-form-field style="width: 100%;">
            <mat-label>K&uuml;rzel</mat-label>
            <input
                type="text"
                matInput
                placeholder="Kürzel"
                [(ngModel)]="textkuerzel"
            />
        </mat-form-field>
        <mat-form-field style="width: 100%;">
            <mat-label>Text</mat-label>
            <input
                type="text"
                matInput
                placeholder="Text"
                [(ngModel)]="wortgruppe"
            />
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button (click)="onCancelClick()">
            Abbrechen
        </button>
        <button mat-flat-button color="accent" (click)="closeAndSafe()">
            Speichern
        </button>
    </mat-dialog-actions>`,
})
export class AddTextkuerzelDialog {
    textkuerzel = '';
    wortgruppe = '';

    constructor(
        public dialogRef: MatDialogRef<AddTextkuerzelDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    closeAndSafe() {
        const textProp: TextProposal = new TextProposal();
        textProp.textkuerzel = this.textkuerzel;
        textProp.wortgruppe = this.wortgruppe;
        this.dialogRef.close(textProp);
    }

    onCancelClick(): void {
        this.dialogRef.close(null);
    }
}
