import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StammdatenService} from '../../../../../projects/kiene-core/src/lib/components/stammdaten/stammdaten.service';
import {Haendler} from '../../../../../projects/kiene-core/src/lib/components/stammdaten/haendler/haendler';
import {MessageService} from '../../../services/message.service';

@Component({
    selector: 'kiene-edit-viehhaendler-dialog',
    templateUrl: './edit-viehhaendler-dialog.component.html',
    styleUrls: ['./edit-viehhaendler-dialog.component.scss']
})
export class EditViehhaendlerDialogComponent implements OnInit {


    constructor(public dialogRef: MatDialogRef<EditViehhaendlerDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public viehhaendler: Haendler, private stammdatenService: StammdatenService, private messageService: MessageService) {
        if (!this.viehhaendler) {
            this.viehhaendler = new Haendler();
        }
    }

    ngOnInit(): void {
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        if (!this.viehhaendler?.viehhaendler_id) {
            this.stammdatenService.createHaendler(this.viehhaendler).subscribe({
                next: response => {
                    this.dialogRef.close(response);
                },
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        } else if (this.viehhaendler.viehhaendler_id) {
            this.stammdatenService.updateHaendler(this.viehhaendler).subscribe({
                next: response => {
                    this.dialogRef.close(response);
                },
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        }
    }

}
