import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ClientRoutingModule} from './client-routing.module';
import {ClientSelectButtonComponent} from './client-select-button/client-select-button.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [
        ClientSelectButtonComponent
    ],
    imports: [
        CommonModule,
        ClientRoutingModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        ClientSelectButtonComponent
    ]
})
export class ClientModule {
}
