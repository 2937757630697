import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { DateTableColumn, NumberTableColumn, TableColumn, } from '../../components/kiene-table/table-column';
import { TableDescriptor, ToolbarButton, } from '../../components/kiene-table/table-descriptor';
import { TableOption, TableOptionEvent, } from '../../components/kiene-table/table-option';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { Gruppe } from '../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import { ConfirmDialogInput } from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import { GruppenService } from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import { MessageService } from '../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-historie-gruppen',
    templateUrl: './historie-gruppen.component.html',
    styleUrls: ['./historie-gruppen.component.scss'],
})
export class HistorieGruppenComponent implements OnInit, OnDestroy {
    tableDescriptor = new TableDescriptor();
    watchClientSubscription: Subscription;
    kundeColumn: TableColumn;
    kundeZusatzColumn: TableColumn;

    constructor(
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private dialog: MatDialog,
        private gruppenService: GruppenService,
        private messageService: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
    ) {
    }

    ngOnDestroy(): void {
        this.watchClientSubscription.unsubscribe();
        this.tableService = null;
    }

    ngOnInit(): void {
        this.watchClientSubscription = this.localStorageService
            .watchCurrentClient()
            .subscribe((response) => {
                if (response) {
                    if (this.kundeColumn && this.kundeZusatzColumn) {
                        this.kundeColumn.isActive = false;
                        this.kundeZusatzColumn.isActive = false;
                        this.tableService.updateColumns();
                    }
                } else {
                    if (this.kundeColumn && this.kundeZusatzColumn) {
                        this.kundeColumn.isActive = true;
                        this.kundeZusatzColumn.isActive = true;
                        this.tableService.updateColumns();
                    }
                }
            });

        this.tableDescriptor.headline = 'Historie Gruppen';
        this.tableDescriptor.uniqueIdentifier = 'Historie Gruppen';
        this.tableDescriptor.alternativeApiUrl =
            this.apiBaseUrl + 'gruppe/read_hist_kundenportal.php';
        this.tableDescriptor.initialSortDirection = 'desc';
        this.tableDescriptor.initialSortColumn = 'einstalldatum_von';
        this.tableDescriptor.nameOfIdField = 'gruppe_id';
        this.tableDescriptor.route = '/gruppen/historie/';
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.showDownloads = true;
        this.tableDescriptor.sendCurrentClient = true;

        this.tableDescriptor.columns = this.getColumns();
        this.tableDescriptor.options = this.getOptions();
        this.tableDescriptor.toolbarButtons = this.getToolbarButtons();

        this.tableDescriptor.totalRow = true;
        this.tableDescriptor.totalRowApiUrl = this.apiBaseUrl + 'gruppe/read_hist_kundenportal_summen.php';
    }

    getColumns(): TableColumn[] {
        const columns = [];
        columns.push(new TableColumn('bezeichnung', 'Gruppenname'));
        columns.push(new TableColumn('produktionsabschnitt', 'Abschnitt'));
        columns.push(new TableColumn('herdenname', 'Herdenname'));
        columns.push(
            new TableColumn('anzahl_tiere_gesamt', 'Eingestallte Tiere')
        );
        columns.push(new TableColumn('stall', 'Standort'));

        this.kundeColumn = new TableColumn('kunde', 'Kunde');
        this.kundeZusatzColumn = new TableColumn('zusatz', 'Kunde Zusatz');
        if (this.localStorageService.getCurrentClient()) {
            this.kundeColumn.isActive = false;
            this.kundeZusatzColumn.isActive = false;
        }
        columns.push(this.kundeColumn);
        columns.push(this.kundeZusatzColumn);

        columns.push(
            new DateTableColumn(
                'einstalldatum_von',
                'Einstalldatum von',
                'dd.MM.yy'
            )
        );
        columns.push(
            new DateTableColumn(
                'einstalldatum_bis',
                'Einstalldatum bis',
                'dd.MM.yy'
            )
        );
        columns.push(
            new DateTableColumn(
                'abgangsdatum_von',
                'Abgangsdatum von',
                'dd.MM.yy'
            )
        );
        columns.push(
            new DateTableColumn(
                'abgangsdatum_bis',
                'Abgangsdatum bis',
                'dd.MM.yy'
            )
        );
        // columns.push(new TableColumn("anzahl_abgaenge", "Abgänge"));
        columns.push(
            new NumberTableColumn('verluste', 'Verluste', '1.1-1', '%')
        );
        columns.push(new TableColumn('anzahl_tote', 'Tote'));
        if (this.localStorageService.hasPermission(9161)) {
            columns.push(
                new NumberTableColumn('schlachtgewicht_avg_unter12.wert', 'Schlachtgew. <12M', '1.2-2', 'kg')
            );
            columns.push(
                new NumberTableColumn('schlachtgewicht_avg_ueber12.wert', 'Schlachtgew. >12M', '1.2-2', 'kg')
            );
            columns.push(
                new NumberTableColumn('nettozunahmen_avg_unter12.wert', 'Nettozunahmen <12M', '1.2-2', 'kg')
            );
            columns.push(
                new NumberTableColumn('nettozunahmen_avg_ueber12.wert', 'Nettozunahmen >12M', '1.2-2', 'kg')
            );
        }
        if (this.localStorageService.hasPermission(9162)) {
            columns.push(
                new NumberTableColumn('erloesdifferenz_avg.wert', ' Erlösdifferenz', '1.2-2', '€')
            );
        }
        columns.push(
            new DateTableColumn(
                'synchronisiert',
                'HIT Sync.',
                'dd.MM.yy HH:mm',
                { alternativeField: 'hit_fehler' }
            )
        );
        return columns;
    }

    getOptions() {
        const options: TableOption[] = [];
        options.push(new TableOption(null, 'Aus Historie holen', 'undo', 9114, { void: this.ausHistorieHolen.bind(this) }));
        return options;
    }

    getToolbarButtons(): ToolbarButton[] {
        const buttons: ToolbarButton[] = [];
        return buttons;
    }

    catchOptionEvent(event: TableOptionEvent) {
        console.debug('event: %s', event.name);
    }

    ausHistorieHolen(gruppe: Gruppe) {
        const input = new ConfirmDialogInput();
        input.headline = 'Gruppe aus Historie holen?';
        input.text = 'Gruppe aus Historie holen und wieder in "aktuelle Gruppen" anzeigen?';
        input.okButton = 'Verschieben';

        this.dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: input
        }).afterClosed().subscribe((response) => {
            if (response) {
                this.gruppenService.historieAendern(gruppe, false)
                    .subscribe(
                        {
                            next: () => {
                                this.messageService.infoMessage(
                                    'Gruppe erfolgreich verschoben!'
                                );
                                this.tableService?.reload();
                            },
                            error: err => {
                                this.messageService.errorMessage(
                                    'Gruppe konnte nicht verschoben werden!',
                                    err
                                );
                            }
                        }
                    );
            }
        });
    }

}
