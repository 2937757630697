import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Gruppe } from '../../../gruppen/classes/gruppe';
import { UntypedFormControl } from '@angular/forms';
import { MessageService } from '../../../../services/message-service/message.service';
import { StammdatenService } from '../../../stammdaten/stammdaten.service';
import { TierRasse } from '../../classes/tier-rasse';
import { OnChanges } from '@angular/core';

@Component({
    selector: 'kiene-gruppe-tier-rasse-filter',
    templateUrl: './gruppe-tier-rasse-filter.component.html',
    styleUrls: ['./gruppe-tier-rasse-filter.component.scss']
})
export class GruppeTierRasseFilterComponent implements OnInit, OnDestroy, OnChanges {

    rassenGruppeSubscription: Subscription;
    isLoading = true;
    label = 'Lädt...';

    @Input('gruppe') selectedGruppe: Gruppe;
    @Input('rassen') rassen: TierRasse[] = [];
    @Input('hideOnEmptyList') hideOnEmptyList = false;
    @Output('selectedRassen') selectedRassen: EventEmitter<TierRasse[]> = new EventEmitter<TierRasse[]>();
    selectedRasseCtrl: UntypedFormControl = new UntypedFormControl(null);
    rassenFuerGruppe: TierRasse[] = [];

    constructor(private messageService: MessageService, private stammdatenService: StammdatenService) { }

    ngOnDestroy(): void {
        this.rassenGruppeSubscription?.unsubscribe();
    }
    ngOnInit(): void {
        this.loadTierRasseFuerGruppe();

        this.selectedRasseCtrl.setValue(this.rassen);

        this.selectedRasseCtrl.valueChanges.subscribe(data => {
            this.selectedRassen.emit(data);
        });
    }

    ngOnChanges() {
        if (this.selectedGruppe) { this.loadTierRasseFuerGruppe(); }
    }

    private loadTierRasseFuerGruppe() {
        this.isLoading = true;
        if (this.selectedGruppe) {
            this.rassenGruppeSubscription?.unsubscribe();
            this.rassenGruppeSubscription = this.stammdatenService
                .ladeRassen(this.selectedGruppe.gruppe_id)
                .subscribe({
                    next: (result) => {
                        this.selectedRasseCtrl.enable();
                        this.isLoading = false;
                        this.rassenFuerGruppe = result.records;
                        this.rassenFuerGruppe.sort((a, b) => {
                            return a.name < b.name ? -1 : 1;
                        });

                        if (this.rassenFuerGruppe.length === 0) {
                            this.label = 'keine Rassen';
                            this.selectedRasseCtrl.disable();
                        } else {
                            this.label = 'Rasse';
                        }
                    },
                    error: (err) => {
                        this.isLoading = false;
                        this.messageService.errorMessage(
                            'Fehler beim Laden der Tierrassen',
                            err
                        );
                    },
                });
        }
    }

    resetRassenCtrl($event: MouseEvent) {
        $event.stopPropagation();
        this.selectedRasseCtrl.setValue(undefined);
    }

    compareRasse(a: TierRasse, b: TierRasse) {
        return a && b && a.rasse_id === b.rasse_id;
    }

}
