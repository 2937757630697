import { FormControl } from '@angular/forms';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { KieneBackendApiService } from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogHtmlComponent } from '../../dialog-html/dialog-html.component';
import { Router } from '@angular/router';

@Component({
  selector: 'kiene-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  @Input('landwirt') landwirt;
  @Input('haendler') haendler;
  @Input('schlachthof') schlachthof;
  @Input('spediteur') spediteur;
  @Input('fahrerData') fahrerData;
  @Input('isSpediteur') isSpediteur;
  @Input('zeitslot_buchung') zeitslot_buchung;
  @Input('offen') offen;
  @Input('chooseTransporteur') chooseTransporteur: boolean = false;
  @Input('chooseFahrer') chooseFahrer: boolean = false;
  @Input('lieferscheinNichtAusgefuellt') lieferscheinNichtAusgefuellt: boolean = false;
  @Input('vonHaendlerBeauftragt') vonHaendlerBeauftragt: boolean = false;
  @Output('acceptCallback') acceptCallback: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('beauftragenCallback') beauftragenCallbackCallback: EventEmitter<BeauftragenResponse> = new EventEmitter<BeauftragenResponse>();
  spediteure = [{_bezeichnung: 'Selber', kunde_id: -1}];
  fahrer = [];
  fahrerFormControl: FormControl<number> = new FormControl<number>(0);
  spediteurFormControl: FormControl<number> = new FormControl<number>(0);
  beauftragenEnabled: boolean = false;
  pdfBase64;
  canReportVerzoegerung: boolean = false;
  beauftragenButton: string;
  
  
  constructor(
    private api: KieneBackendApiService,
    private messageService: MessageService,
    private dialog: MatDialog,
    protected _router: Router,
    @Inject(API_BASE_URL_SERVICE) private apiBaseUrl
  ) { }

  ngOnInit(): void {
    if(this.chooseTransporteur || this.chooseFahrer){
      this.fahrerFormControl.disable();
      this.spediteurFormControl.valueChanges.subscribe((x ) => this.SpediteurChange(x));
      this.fahrerFormControl.valueChanges.subscribe((x ) => this.FahrerChange(x)); 
      //TODO get all spediteure
      this.getSpediteure();

      this.getFahrer();
    }

    this.updateVerzoegerungStatus();
    if((this.landwirt != undefined && !this.landwirt) || this.isSpediteur) {
      this.beauftragenButton = 'Übergeben';
    } else if(this.schlachthof != undefined && !this.schlachthof) {
      this.beauftragenButton = 'Fertig';
    }
  }

  updateVerzoegerungStatus(){
    this.canReportVerzoegerung = this.zeitslot_buchung.verzoegert == 0;
    //TODO check if user can report verzoegerung
  }

  SpediteurChange(event){
    if(event === -1){
      this.fahrerFormControl.enable();
    } else {
      this.fahrerFormControl.disable();
    }
    this.updateBeauftragenEnabled();
  }

  FahrerChange(event){
    this.updateBeauftragenEnabled();
  }

  updateBeauftragenEnabled(){
    if(this.spediteurFormControl.value === -1){
      this.beauftragenEnabled = this.fahrerFormControl.value > 0
      return;
    }
    
    if(this.spediteurFormControl.value > 0){
      this.beauftragenEnabled = true;
      return;
    }
  }

  getSpediteure() {
    
    let params = new HttpParams()

    this.api
    .get(
        this.apiBaseUrl +
        'spediteure/read.php',
        null
    )
    .subscribe({
        next: (response) => {
          this.spediteure = [{_bezeichnung: 'Selber', kunde_id: -1}];
          this.spediteure = this.spediteure.concat(response.records);
          console.log(this.spediteure);
        },
        error: (err) => {

            this.messageService.errorMessage(
                'Fehler!',
                err
            );
        },
    });
  }

  getFahrer() {
    this.api
      .get(
          `${this.apiBaseUrl}benutzer/read_meine_benutzer.php`)
      .subscribe({
          next: (response: any) => {
            response.records.forEach(benutzer => {
              if(benutzer.benutzerrollen.filter(rolle => rolle.benutzerrolle_id == 21).length > 0){
                this.fahrer.push(benutzer);
              }
            });
          },
          error: err => {
              this.messageService.errorMessage('Fahrer konnten nicht geladen werden!', err);
          }
      });
  }

  acceptBuchung(){
    this.acceptCallback.emit(true);
  }

  denyBuchung(){
    this.acceptCallback.emit(false);
  }


  beauftragen(){
    var response = new BeauftragenResponse();
    if(!this.haendler) {
      response.spediteur_id = this.spediteurFormControl.value;
      if(this.spediteurFormControl.value === -1){
        response.fahrer_id = this.fahrerFormControl.value;
      }
    }
    this.beauftragenCallbackCallback.emit(response);
  }

  reportVerzoegerung(){
    this.zeitslot_buchung.verzoegert = 1;

    this.api.post(this.apiBaseUrl + 'zeitslot_buchung/melde_verzoegerung.php', this.zeitslot_buchung, undefined).subscribe({
      next: response => {
        this.messageService.infoMessage('Die Verzögerung wurde erfolgreich gemeldet.');
        //this._router.navigateByUrl(this._router.url);
      },
      error: error => {
        this.messageService.errorMessage('Fehler beim Melden der Verzögerung! ', error);
      }
    });
  }

  leseLieferschein() {
    const params = new HttpParams().append('buchungs_id', this.zeitslot_buchung.zeitslot_buchung_id);
    this.api.get(this.apiBaseUrl + 'lieferschein/create_pdf.php', params).subscribe({
      next: response => {
        if(response != null) {
          this.pdfBase64 = 'data:application/pdf;base64,' + response;
          
          const dialogRef = this.dialog.open(DialogHtmlComponent, {
            data: {
              headline: 'Lieferschein',
              okButton: 'PDF Herunterladen',
              cancelButton: 'Abbrechen',
              buttonColor: 'accent',
            }
          });
          dialogRef.afterClosed().subscribe({
              next: result => {
                if(result?.ok) {
                    this.getPDF(response);
                  }
              }
          });
          let instance = dialogRef.componentInstance;
          instance.base64 = this.pdfBase64;
        }
      }, 
      error: error => {
        this.messageService.errorMessage('Fehler beim Ausgeben des PDF\'s! ', error);
      }
    });
  }

  _base64ToArrayBuffer(base64) {
	  var binary_string = base64.replace(/\\n/g, '');
    binary_string =  window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array( len );
    for (var i = 0; i < len; i++)        {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  
  getPDF(base64data) {
    var blob = this.b64toBlob(base64data, "application/pdf");
    let a = document.createElement("a");
    document.body.appendChild(a);
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = String("Lieferschein.pdf");
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  
  public b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}

export class BeauftragenResponse {
  spediteur_id: number;
  fahrer_id: number;
}
