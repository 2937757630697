import {StammdatenRoutingModule} from './stammdaten-routing.module';
import {CommonModule} from '@angular/common';

import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {EntsorgerDetailsComponent} from './entsorger/entsorger-details/entsorger-details.component';
import {HaendlerDetailsComponent} from './haendler/haendler-details/haendler-details.component';
import {HaendlerVerwaltungComponent} from './haendler/haendler-verwaltung/haendler-verwaltung.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {EntsorgerUebersichtComponent} from './entsorger/entsorger-uebersicht/entsorger-uebersicht.component';
import {NgModule} from '@angular/core';
import {HaendlerUebersichtComponent} from './haendler/haendler-uebersicht/haendler-uebersicht.component';
import {HaendlerNamePipe} from './haendler/haendler-verwaltung/haendler-name.pipe';
import {VvvoUebersichtComponent} from './vvvo/vvvo-uebersicht/vvvo-uebersicht.component';
import {MatRadioModule} from '@angular/material/radio';
import {VvvoBasisInfosFormComponent} from './vvvo/vvvo-basis-infos-form/vvvo-basis-infos-form.component';
import {VvvoDetailsActionsComponent} from './vvvo/vvvo-details-actions/vvvo-details-actions.component';
import {
    VvvoDetailsActionsHitPinComponent
} from './vvvo/vvvo-details-actions-hit-pin/vvvo-details-actions-hit-pin.component';
import {
    TierReklamationenUebersichtComponent
} from './tierreklamationen/tierreklamationen-uebersicht/tier-reklamationen-uebersicht.component';
import {MatMenuModule} from '@angular/material/menu';
import {
    TierReklamationenEditierenComponent
} from './tierreklamationen/tierreklamationen-editieren/tier-reklamationen-editieren.component';
import {MatSelectModule} from '@angular/material/select';
import {KieneAutocompleteModule} from '../kiene-autocomplete/kiene-autocomplete.module';
import {DirectivesModule} from '../../directives/directives.module';
import {UnterschriftComponent} from './unterschrift/unterschrift.component';
import {UnterschriftenModule} from '../unterschriften/unterschriften.module';
import {SignaturePadModule} from 'angular2-signaturepad';
import {
    EntsorgerKundenNrVerwaltenComponent
} from './entsorger/entsorger-kunden-nr-verwalten/entsorger-kunden-nr-verwalten.component';
import {PageLayoutModule} from '../page-layout/page-layout.module';
import {MatDialogModule} from '@angular/material/dialog';
import {HaendlerDetailsDialogComponent} from './haendler/haendler-details-dialog/haendler-details-dialog.component';
import {EntsorgerDetailsDialogComponent} from './entsorger/entsorger-details-dialog/entsorger-details-dialog.component';
import {VvvoDetailsComponent} from './vvvo/vvvo-details/vvvo-details.component';
import {VvvoHinzufuegenHitComponent} from './vvvo/vvvo-hinzufuegen-hit/vvvo-hinzufuegen-hit.component';
import {HitModule} from '../../hit/hit.module';
import {
    EntsorgerKundenNrVerwaltenDialogComponent
} from './entsorger/entsorger-kunden-nr-verwalten-dialog/entsorger-kunden-nr-verwalten-dialog.component';
import {
    HitPinAktualisierenDialogComponent
} from './vvvo/hit-pin-aktualisieren-dialog/hit-pin-aktualisieren-dialog.component';
import {MeinProfilComponent} from './mein-profil/mein-profil.component';
import {NewPasswordModule} from '../new-password/new-password.module';

import {BenutzerModule} from './benutzer/benutzer.module';
import {KeyValueListModule} from '../key-value-list/key-value-list.module';
import {EntsorgerAnlegenDialogComponent} from './entsorger/entsorger-anlegen-dialog/entsorger-anlegen-dialog.component';
import {SignaturePanelModule} from '../signature-panel/signature-panel.module';
import {DialogsModule} from '../dialogs/dialogs.module';
import {HaendlerSelectorComponent} from './haendler/haendler-selector/haendler-selector.component';
import {FreigabenComponent} from './freigaben/freigaben.component';
import {
    FreigabeErstellenDialogComponent
} from './freigaben/freigabe-erstellen-dialog/freigabe-erstellen-dialog.component';
import {MatStepperModule} from '@angular/material/stepper';
import {
    VvvoHinzufuegenHitDialogComponent
} from './vvvo/vvvo-hinzufuegen-hit-dialog/vvvo-hinzufuegen-hit-dialog.component';
import {VVVODetailsDialogComponent} from './vvvo/vvvodetails-dialog/vvvodetails-dialog.component';
import {LieferantUebersichtComponent} from './lieferant/lieferant-uebersicht/lieferant-uebersicht.component';
import {
    LieferantKundenNrVerwaltenComponent
} from './lieferant/lieferant-kunden-nr-verwalten/lieferant-kunden-nr-verwalten.component';
import {
    LieferantKundenNrVerwaltenDialogComponent
} from './lieferant/lieferant-kunden-nr-verwalten-dialog/lieferant-kunden-nr-verwalten-dialog.component';
import {LieferantAnlegenDialogComponent} from './lieferant/lieferant-anlegen-dialog/lieferant-anlegen-dialog.component';
import {LieferantDetailsComponent} from './lieferant/lieferant-details/lieferant-details.component';
import {LieferantDetailsDialogComponent} from './lieferant/lieferant-details-dialog/lieferant-details-dialog.component';
import {
    HaendlerUebersichtFavoritenAltComponent
} from './haendler/haendler-uebersicht-favoriten-alt/haendler-uebersicht-favoriten-alt.component';
import {KontakteComponent} from './kontakte/kontakte.component';


@NgModule({
    declarations: [
        EntsorgerDetailsComponent,
        EntsorgerUebersichtComponent,
        EntsorgerKundenNrVerwaltenComponent,
        EntsorgerDetailsDialogComponent,
        EntsorgerKundenNrVerwaltenDialogComponent,
        HaendlerDetailsComponent,
        HaendlerVerwaltungComponent,
        HaendlerUebersichtComponent,
        HaendlerNamePipe,
        VvvoUebersichtComponent,
        VvvoDetailsComponent,
        VvvoBasisInfosFormComponent,
        VvvoDetailsActionsComponent,
        VvvoDetailsActionsHitPinComponent,
        TierReklamationenUebersichtComponent,
        TierReklamationenEditierenComponent,
        UnterschriftComponent,
        HaendlerDetailsDialogComponent,
        VvvoHinzufuegenHitComponent,
        HitPinAktualisierenDialogComponent,
        MeinProfilComponent,
        EntsorgerAnlegenDialogComponent,
        HaendlerSelectorComponent,
        FreigabenComponent,
        FreigabeErstellenDialogComponent,
        VvvoHinzufuegenHitDialogComponent,
        VVVODetailsDialogComponent,
        LieferantDetailsComponent,
        LieferantDetailsDialogComponent,
        LieferantAnlegenDialogComponent,
        LieferantUebersichtComponent,
        LieferantKundenNrVerwaltenComponent,
        LieferantKundenNrVerwaltenDialogComponent,
        HaendlerUebersichtFavoritenAltComponent,
        KontakteComponent,
    ],

    exports: [
        EntsorgerDetailsComponent,
        EntsorgerUebersichtComponent,
        EntsorgerKundenNrVerwaltenComponent,
        EntsorgerDetailsDialogComponent,
        EntsorgerKundenNrVerwaltenDialogComponent,
        HaendlerDetailsComponent,
        HaendlerVerwaltungComponent,
        HaendlerUebersichtComponent,
        VvvoHinzufuegenHitComponent,
        HaendlerNamePipe,
        VvvoDetailsComponent,
        VvvoUebersichtComponent,
        VvvoBasisInfosFormComponent,
        VvvoDetailsActionsComponent,
        VvvoDetailsActionsHitPinComponent,
        TierReklamationenUebersichtComponent,
        TierReklamationenEditierenComponent,
        UnterschriftComponent,
        HaendlerDetailsDialogComponent,
        MeinProfilComponent,
        HaendlerSelectorComponent,
        FreigabenComponent,
        LieferantUebersichtComponent,
        LieferantKundenNrVerwaltenComponent,
        LieferantDetailsComponent, HaendlerUebersichtFavoritenAltComponent, KontakteComponent
    ],
    imports: [
        CommonModule,
        StammdatenRoutingModule,
        MatIconModule,
        MatCheckboxModule,
        MatCardModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        KieneAutocompleteModule,
        MatDividerModule,
        MatButtonModule,
        MatInputModule,
        DirectivesModule,
        MatExpansionModule,
        MatRadioModule,
        MatMenuModule,
        MatSelectModule,
        UnterschriftenModule,
        SignaturePadModule,
        FormsModule,
        PageLayoutModule,
        MatDialogModule,
        HitModule,
        NewPasswordModule,
        BenutzerModule,
        KeyValueListModule,
        SignaturePanelModule,
        DialogsModule,
        MatStepperModule,
    ],
})
export class StammdatenModule {
}
