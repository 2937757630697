import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../services/message.service';
import { Entsorger } from '../../../../../projects/kiene-core/src/lib/components/stammdaten/entsorger/entsorger';

@Component({
    selector: 'kiene-entsorger-details-dialog',
    templateUrl: './entsorger-details-dialog.component.html',
    styleUrls: ['./entsorger-details-dialog.component.scss']
})
export class EntsorgerDetailsDialogComponent implements OnInit {

    entsorger: Entsorger;

    constructor(@Inject(MAT_DIALOG_DATA) private data,
        public dialogRef: MatDialogRef<EntsorgerDetailsDialogComponent>,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private messageService: MessageService) {
        if (this.data?.entsorger) {
            this.entsorger = this.data.entsorger;
        }
    }

    ngOnInit(): void {
        if (!this.entsorger) {
            this.entsorger = new Entsorger();
        }
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        const datei = this.entsorger.entsorger_id ? 'update_kundenportal.php' : 'create_kundenportal.php';
        this.api.post(this.apiBaseUrl + 'entsorger/' + datei, this.entsorger).subscribe({
            next: response => {
                this.messageService.infoMessage('Der Entsorger wurde erfolgreich gespeichert!');
                this.dialogRef.close(true);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

}
