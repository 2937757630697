import { HttpParams } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { environment } from "../../../environments/environment";
import { GruppeTier } from "../../administration/client-group/gruppe-tier";
import { KieneTableService } from "../../components/kiene-table/kiene-table.service";
import { Tierreklamation } from '../../../../projects/kiene-core/src/lib/components/gruppe-tier/classes/tierreklamation';

@Component({
    selector: "kiene-add-gruppe-tier-reklamation-dialog",
    templateUrl: "./add-gruppe-tier-reklamation-dialog.component.html",
    styleUrls: ["./add-gruppe-tier-reklamation-dialog.component.scss"],
})
export class AddGruppeTierReklamationDialogComponent implements OnInit {
    tierreklamationen: Tierreklamation[] = [];
    selectedReklamationen: Tierreklamation[] = [];
    gruppeTier: GruppeTier;
    loadReklamationenSub: Subscription;

    constructor(
        public dialogRef: MatDialogRef<AddGruppeTierReklamationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private api: KieneTableService
    ) {
        if (this.data.gruppeTier) {
            this.gruppeTier = this.data.gruppeTier;
            console.debug("GruppeTier: %s", JSON.stringify(this.gruppeTier));
            this.selectedReklamationen = this.gruppeTier.reklamationen;
        } else if (this.data.gt_id) {
            this.api
                .getOne(
                    environment.BASE_URI +
                    "gruppe_tier/read_one_kundenportal.php",
                    "gt_id", this.data?.gt_id?.toString()
                )
                .subscribe({
                    next: (gt: GruppeTier) => {
                        this.gruppeTier = gt;
                        this.selectedReklamationen =
                            this.gruppeTier.reklamationen;
                    },
                });
        }
    }

    ngOnInit(): void {
        this.loadReklamationen();
    }

    addRemoveReklamation(
        event: MatCheckboxChange,
        tierreklamation: Tierreklamation
    ) {
        if (event.checked) {
            this.selectedReklamationen.push(tierreklamation);
        } else {
            const index = this.selectedReklamationen.indexOf(tierreklamation);
            this.selectedReklamationen.splice(index, 1);
        }
    }

    isChecked(tierreklamation: Tierreklamation) {
        for (const st of this.selectedReklamationen) {
            if (st.tierreklamation_id === tierreklamation.tierreklamation_id) {
                return true;
            }
        }
        return false;
    }

    loadReklamationen() {
        this.loadReklamationenSub?.unsubscribe();
        const params = new HttpParams().set("freigegeben", "1");
        this.loadReklamationenSub = this.api
            .get(
                `${environment.BASE_URI}tierreklamation/read_kundenportal.php`,
                params
            )
            .subscribe({
                next: (response) => {
                    this.tierreklamationen = response.records;
                },
            });
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        this.gruppeTier.reklamationen = this.selectedReklamationen;
        this.dialogRef.close(this.gruppeTier);
    }
}
