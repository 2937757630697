import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { API_BASE_URL_SERVICE } from "../../../../../../projects/kiene-core/src/lib/kiene-core.config";
import {
    KieneBackendApiService
} from "../../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service";
import { MessageService } from "../../../../services/message.service";
import { Heimtier } from "../../../kalender/heimtier-kalender-eintrag";
import { HeimtierDaten, HeimtierDatenOutput } from "./heimtier-daten";

@Component({
    selector: 'kiene-heimtier-daten-dialog',
    templateUrl: './heimtier-daten-dialog.component.html',
    styleUrls: ['./heimtier-daten-dialog.component.scss']
})
// erzeugt mit KieneTableTemplateTS.ts.template
export class HeimtierDatenDialogComponent implements OnInit {

    gewichtCtrl: FormControl = new FormControl(undefined, []);
    pulsCtrl: FormControl = new FormControl(undefined, []);
    atmungCtrl: FormControl = new FormControl(undefined, []);
    temperaturCtrl: FormControl = new FormControl(undefined, []);
    isSaving = false;

    constructor(@Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        @Inject(MAT_DIALOG_DATA) private input: HeimtierDaten,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        public dialogRef: MatDialogRef<HeimtierDatenDialogComponent>) {
        if (!this.input) {
            this.input = new HeimtierDaten();
        }
    }

    ngOnInit(): void {
        this.initFormControls();
    }

    initFormControls() {
        this.gewichtCtrl.setValue(this.input.gewicht);
        this.pulsCtrl.setValue(this.input.puls);
        this.atmungCtrl.setValue(this.input.atmung);
        this.temperaturCtrl.setValue(this.input.temperatur);
    }

    close() {
        this.dialogRef.close(null);
    }

    save() {
        this.isSaving = true;
        this.input.gewicht = this.gewichtCtrl.value;
        this.input.puls = this.pulsCtrl.value;
        this.input.atmung = this.atmungCtrl.value;
        this.input.temperatur = this.temperaturCtrl.value;

        this.api.post(this.apiBaseUrl + 'heimtier_daten/update.php', this.input).subscribe({
            next: response => {
                this.messageService.infoMessage('Heimtier Daten wurde erfolgreich gespeichert!');
                const output = new HeimtierDatenOutput();
                output.heimtierDaten = response;
                output.confirm = true;
                this.isSaving = false;
                this.dialogRef.close(output);
            },
            error: err => {
                this.isSaving = false;
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

}


