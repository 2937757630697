<h1 mat-dialog-title>Termin {{ kalenderEintrag?.hk_id ? 'editieren' : 'anlegen' }}</h1>
<div mat-dialog-content>
    <kiene-autocomplete *kienePermission="[7011]"
                        (elementSelected)="setKunde($event)"
                        apiUrl="heimtier_kunde/"
                        [clearAfterSelect]="false"
                        [displayFields]="['bezeichnung', 'betriebsart']"
                        [placeholder]="'Kunde suchen und hinzufügen'"
                        [focus]="false"
                        [createNewEvent]="autocompleCreateKundeEvent"
                        (createNewEvent)="createKunde()"
                        [kieneObject]="kunde" style="width: 80%;">
    </kiene-autocomplete>

    <mat-form-field style="width: 100%">
        <mat-label>Tiere</mat-label>
        <mat-select
            [formControl]="tiereCtrl"
            multiple
            [compareWith]="compareTiere"
            (selectionChange)="onTiereSelectionChange($event)">
            <mat-option (click)="createTier()"
                        style="position: sticky; bottom: 0; background-color: #efefef; border-top: 1px solid #ccc">
                <mat-icon>add</mat-icon>
                <span>Tier anlegen</span>
            </mat-option>
            <mat-option *ngFor="let tier of tiere" [value]="tier">
                {{ tier?.name }} - {{ tier?.tierart }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <p><strong>Terminierung:</strong></p>

    <div class="one-row">
        <mat-form-field style="width: 49%">
            <mat-label>Am:</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="dateCtrl">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Von</mat-label>
            <input matInput type="time" [formControl]="vonCtrl">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Bis</mat-label>
            <input matInput type="time" [formControl]="bisCtrl">
        </mat-form-field>

    </div>

    <mat-form-field style="width: 100%;">
        <mat-label>Beschreibung</mat-label>
        <textarea #beschreibungInput matInput [formControl]="beschreibungCtrl" rows="7"></textarea>
    </mat-form-field>
</div>

<div align="end" mat-dialog-actions>
    <button (click)="dialogRef.close()" mat-button>Abbrechen</button>
    <button mat-flat-button *kienePermission="[7002]" [disabled]="!isValid() || isSaving" (click)="save()"
            color="accent">
        {{ kalenderEintrag?.hk_id ? 'Speichern' : 'Anlegen' }}
    </button>
</div>
