<div>
  <div class="one-row">
    <span class="spacer"></span>
    <button style="margin-right: 24px;" mat-flat-button color="accent" (click)="rewind()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <div>
      <mat-form-field style="width: fit-content;">
        <mat-label>Zeitspanne eingeben</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Startdatum" (dateInput)="closedDatepicker()" [formControl]="startDateCtrl">
          <input matEndDate placeholder="Enddatum"  (dateInput)="closedDatepicker()" [formControl]="endDateCtrl">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker (closed)="closedDatepicker()"></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div style="width: 24px;"></div>
    <mat-button-toggle-group (change)="selectZeitinterval($event)" [value]="aktuellerZeitinterval">
      <mat-button-toggle value="individuell">Individuell</mat-button-toggle>
      <mat-button-toggle value="tag">Tag</mat-button-toggle>
      <mat-button-toggle value="woche">Woche</mat-button-toggle>
      <mat-button-toggle value="monat">Monat</mat-button-toggle>
      <mat-button-toggle value="quartal">Quartal</mat-button-toggle>
      <mat-button-toggle value="halbjahr">Halbjahr</mat-button-toggle>
      <mat-button-toggle value="jahr">Jahr</mat-button-toggle>
    </mat-button-toggle-group>
    <div style="width: 24px;"></div>
    <div *ngIf="!hideAggregate">
      <mat-form-field style="width: fit-content;">
        <mat-label>Aggregieren nach</mat-label>
        <mat-select [formControl]="aggregierenCtrl">
          <mat-option value="tag">Tag</mat-option>
          <mat-option value="woche">Woche</mat-option>
          <mat-option value="monat">Monat</mat-option>
          <mat-option value="quartal">Quartal</mat-option>
          <mat-option value="jahr">Jahr</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <button style="margin-left: 24px;" mat-flat-button color="accent" (click)="forward()">
      <mat-icon>chevron_right</mat-icon>
    </button>
    <span class="spacer"></span>
  </div>
</div>
