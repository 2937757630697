import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[mouseOverClass]'
})
export class MouseOverClassDirective {

    @Input('mouseOverClass') mouseOverClass: string;

    constructor(public elementRef: ElementRef) {
    }

    @HostListener('mouseenter') onMouseEnter() {
        this.elementRef.nativeElement.classList.add(this.mouseOverClass);
    }

    @HostListener('mouseleave') onMouseLeave() {
        this.elementRef.nativeElement.classList.remove(this.mouseOverClass);
    }

}
