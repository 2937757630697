import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {
    CalendarWeekSelectorDialogComponent
} from "./calendar-week-selector-dialog/calendar-week-selector-dialog.component";
import {CalendarWeek} from "./calender-week";
import {DateUtils} from "./date-utils";

@Component({
  selector: 'kiene-calendar-week-picker',
  templateUrl: './calendar-week-picker.component.html',
  styleUrls: ['./calendar-week-picker.component.scss'],
})
export class CalendarWeekPickerComponent implements OnInit {
  @Input() calendarWeek: CalendarWeek;
  @Output() calendarWeekChange = new EventEmitter<CalendarWeek>();

  currentKW: CalendarWeek;

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.currentKW = DateUtils.getCalendarWeek(new Date());
    if (!this.calendarWeek) {
      this.calendarWeek = this.currentKW;
      this.calendarWeekChange.emit(this.calendarWeek);
    }
  }

  openCalenderWeekSelector() {
    this.dialog
      .open(CalendarWeekSelectorDialogComponent, {
        minWidth: '100%',
        data: this.calendarWeek,
      })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (result) {
            this.calendarWeek = result;
            this.calendarWeekChange.emit(this.calendarWeek);
          }
        },
      });
  }

  previousWeek() {
    if (this.calendarWeek?.monday) {
      let lastMonday = new Date(this.calendarWeek.monday);
      lastMonday = new Date(lastMonday.setDate(lastMonday.getDate() - 7));
      this.calendarWeek = DateUtils.getCalendarWeek(lastMonday);
      this.calendarWeekChange.emit(this.calendarWeek);
    }
  }

  nextWeek() {
    if (this.calendarWeek?.monday) {
      let nextMonday = new Date(this.calendarWeek.monday);
      nextMonday = new Date(nextMonday.setDate(nextMonday.getDate() + 7));
      this.calendarWeek = DateUtils.getCalendarWeek(nextMonday);
      this.calendarWeekChange.emit(this.calendarWeek);
    }
  }

  setCurrentWeek() {
    this.calendarWeek = this.currentKW;
    this.calendarWeekChange.emit(this.calendarWeek);
  }
}
