import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageHeaderComponent} from './page-header/page-header.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {VvvoOptionSelectComponent} from './vvvo-option-select/vvvo-option-select.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {VVVOInlineSelectComponent} from './vvvoinline-select/vvvoinline-select.component';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule({
    declarations: [
        PageHeaderComponent,
        VvvoOptionSelectComponent,
        VVVOInlineSelectComponent
    ],
    exports: [
        PageHeaderComponent,
        VvvoOptionSelectComponent,
        VVVOInlineSelectComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatDialogModule
    ]
})
export class PageLayoutModule {
}
