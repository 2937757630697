import { DatePipe, formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger, } from '@angular/animations';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChartType } from 'angular-google-charts';
import { environment } from '../../../../environments/environment';
import { Vet } from '../../../administration/vet/vet';
import { AppService } from '../../../app.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { ZeitauswahlChange } from '../../zeitauswahl/zeitauswahl-change';
import { CsvColumn } from '../../../csv-export/csv-export/csv-column';
import { WarengruppenAusgang } from './warengruppen-ausgang';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { Subscription } from 'rxjs';
import { KieneBackendApiService } from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { ReportUtils } from '../../report-utils';

@Component({
    selector: 'kiene-warengruppen-ausgang-report',
    templateUrl: './warengruppen-ausgang-report.component.html',
    styleUrls: ['./warengruppen-ausgang-report.component.scss'],
    animations: [
        trigger('detailExpanded', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
    ],
})
export class WarengruppenAusgangReportComponent
    implements OnInit, AfterViewInit {
    displayedColumns = [
        'warengruppe',
        'umsatz_prozent',
        'umsatz',
        'wareneinsatz',
        'wareneinsatz_prozent',
        'rohertrag',
        'rohertrag_prozent',
    ];
    dataSource = new MatTableDataSource<WarengruppenAusgang>();
    dataSourceVorjahr = new MatTableDataSource<WarengruppenAusgang>();
    @ViewChild(MatSort) sort: MatSort;
    expandedElement: WarengruppenAusgang | null;

    tieraerzte: Vet[] = [];
    aktuellerTierarzt: Vet = new Vet();
    tierarztCtrl = new UntypedFormControl();

    rabattCtrl = new FormControl();
    ergaenzerCtrl = new FormControl();
    zeiteintrag: ZeitauswahlChange;
    vorjahreszeitraum: ZeitauswahlChange;

    chartType = ChartType.PieChart;
    chartColumns = ['Warengruppe', 'Umsatz'];
    chartData = [['', 0]];

    csvColumns: CsvColumn[] = [];
    csvColumnsDetails: CsvColumn[] = [];
    csvFilename = 'WarengruppenAusgang.csv';

    gesamtWareneinsatz = 0;
    gesamtUmsatz = 0;
    gesamtRohertrag = 0;

    gesamtWareneinsatzProzent = '0';
    gesamtRohertragProzent = '0 ';

    gesamtWareneinsatzVorjahr = 0;
    gesamtUmsatzVorjahr = 0;
    gesamtRohertragVorjahr = 0;

    gesamtWareneinsatzProzentVorjahr = '0';
    gesamtRohertragProzentVorjahr = '0 ';

    isLoading: boolean[] = [];
    aktuellSubscription: Subscription;
    vorjahrSubscription: Subscription;

    constructor(
        private api: KieneTableService,
        private appService: AppService,
        private backendService: KieneBackendApiService,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.ergaenzerCtrl.valueChanges.subscribe({
            next: value => {
                this.backendService.setSpeicher('report.mitergaenzern', value);
                this.loadData();
            }
        });

        this.backendService.getSpeicher('report.mitergaenzern').subscribe({
            next: (value: boolean) => {
                if (value !== null) {
                    this.ergaenzerCtrl.setValue(value);
                }
            }
        });

        this.backendService.getSpeicher('report.bereinigterumsatz').subscribe({
            next: (value: boolean) => {
                this.ergaenzerCtrl.setValue(value);
            }
        });

        this.csvColumns.push(new CsvColumn('warengruppe', 'Warengruppe'));
        this.csvColumns.push(
            new CsvColumn('umsatz_prozent', 'Umsatz von Gesamt (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('umsatz', 'Umsatz'));
        this.csvColumns.push(new CsvColumn('wareneinsatz', 'Wareneinsatz'));
        this.csvColumns.push(
            new CsvColumn('wareneinsatz_prozent', 'Wareneinsatz (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('rohertrag', 'Rohertrag'));
        this.csvColumns.push(
            new CsvColumn('rohertrag_prozent', 'Rohertrag (%)', '%')
        );

        this.csvColumnsDetails = this.csvColumns.map((column) => ({
            ...column,
        }));
        this.csvColumnsDetails.splice(
            0,
            1,
            new CsvColumn('zeitraum', 'Zeitraum')
        );
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    loadData() {
        this.loadDataAktuell();
        this.loadDataVorjahr();
    }

    loadDataAktuell() {
        this.appService.setDataLoading(true);

        let params = new HttpParams();
        params = params.set('von', formatDate(this.zeiteintrag.von, 'yyyy-MM-dd', 'de'));
        params = params.set('bis', formatDate(this.zeiteintrag.bis, 'yyyy-MM-dd', 'de'));
        params = params.set('rabatt', this.rabattCtrl.value ? '1' : '0');
        params = params.set('ergaenzer', this.ergaenzerCtrl.value ? '1' : '0');

        this.aktuellSubscription?.unsubscribe();
        this.aktuellSubscription = this.api
            .get(
                environment.BASE_URI + 'report/read_waren_ausgang_gruppe.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSource.data = response.records;
                    this.resetChartData();
                    this.berechneGesamtwerte();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    loadDataVorjahr() {
        this.appService.setDataLoading(true);

        let params = new HttpParams();
        params = params.set('von', formatDate(this.vorjahreszeitraum.von, 'yyyy-MM-dd', 'de'));
        params = params.set('bis', formatDate(this.vorjahreszeitraum.bis, 'yyyy-MM-dd', 'de'));
        params = params.set('rabatt', this.rabattCtrl.value ? '1' : '0');
        params = params.set('ergaenzer', this.ergaenzerCtrl.value ? '1' : '0');

        this.vorjahrSubscription?.unsubscribe();
        this.vorjahrSubscription = this.api
            .get(
                environment.BASE_URI + 'report/read_waren_ausgang_gruppe.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSourceVorjahr.data = response.records;
                    this.berechneGesamtwerteVorjahr();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    zeitintervalChanged(event: ZeitauswahlChange) {
        this.zeiteintrag = event;
        this.vorjahreszeitraum = ReportUtils.getVorjahreszeitraum(this.zeiteintrag);
        this.loadData();
    }

    berechneGesamtwerte() {
        this.gesamtWareneinsatz = 0;
        this.gesamtUmsatz = 0;
        this.gesamtRohertrag = 0;
        const data = this.dataSource.data;
        for (const r of data) {
            this.chartData.push([r.warengruppe, r.umsatz ? r.umsatz : 0]);
            this.gesamtWareneinsatz += r.wareneinsatz ? r.wareneinsatz : 0;
            this.gesamtUmsatz += r.umsatz ? r.umsatz : 0;
            this.gesamtRohertrag += r.rohertrag ? r.rohertrag : 0;
        }

        if (this.gesamtUmsatz > 0) {
            this.gesamtWareneinsatzProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtWareneinsatz * 100
                ) / 100
            ).toString();
            this.gesamtRohertragProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtRohertrag * 100
                ) / 100
            ).toString();
        }
    }

    berechneGesamtwerteVorjahr() {
        this.gesamtWareneinsatzVorjahr = 0;
        this.gesamtUmsatzVorjahr = 0;
        this.gesamtRohertragVorjahr = 0;
        const data = this.dataSourceVorjahr.data;
        for (const r of data) {
            this.gesamtWareneinsatzVorjahr += r.wareneinsatz ? r.wareneinsatz : 0;
            this.gesamtUmsatzVorjahr += r.umsatz ? r.umsatz : 0;
            this.gesamtRohertragVorjahr += r.rohertrag ? r.rohertrag : 0;
        }

        if (this.gesamtUmsatzVorjahr > 0) {
            this.gesamtWareneinsatzProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtWareneinsatzVorjahr * 100
                ) / 100
            ).toString();
            this.gesamtRohertragProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtRohertragVorjahr * 100
                ) / 100
            ).toString();
        }
    }

    expandVetGroupRow(element: WarengruppenAusgang) {
        this.expandedElement =
            this.expandedElement === element ? null : element;
        if (this.expandedElement) {
            this.isLoading[element.warengruppe_id] = true;

            let params = new HttpParams();
            params = params.set('von', formatDate(this.zeiteintrag.von, 'yyyy-MM-dd', 'de'));
            params = params.set('bis', formatDate(this.zeiteintrag.bis, 'yyyy-MM-dd', 'de'));
            params = params.set(
                'warengruppe_id',
                element.warengruppe_id.toString()
            );
            params = params.set('rabatt', this.rabattCtrl.value ? '1' : '0');
            params = params.set('ergaenzer', this.ergaenzerCtrl.value ? '1' : '0');
            this.api
                .get(
                    environment.BASE_URI +
                    'report/read_waren_ausgang_gruppe.php',
                    params
                )
                .subscribe(
                    (response) => {
                        element.details = response.records;
                        this.isLoading[element.warengruppe_id] = false;
                    },
                    (error) => {
                        this.messageService.errorMessage('Fehler!', error);
                        this.isLoading[element.warengruppe_id] = false;
                    }
                );
        }
    }

    resetChartData() {
        this.chartData = [['', 0]];
    }

}
