import { KieneColor } from "../../administration/vet-group/vet-group-details-dialog/vet-group-details-dialog.component";

export class HeimtierKalenderEintrag {
    titel: string;
    beschreibung: string;
    hk_id?: number; // Optional
    kunde: string;
    kunde_id: number;
    von: Date;
    bis: Date;
    // Neue Eigenschaften für die Darstellung
    layer?: number;    // Schicht/Lage des Eintrags
    left?: number;     // Linke Position in Prozent
    width?: number;    // Breite in Prozent
    zIndex?: number;   // Z-Index für die Überdeckungslogik
    column?: number;   // Spalte, in der der Eintrag angezeigt wird
    totalColumns?: number; // Gesamtanzahl der Spalten
    tiere: Heimtier[] = [];
    farbe: KieneColor;
    behandlung_seit: Date;
    notiz: string;
    wartezimmer_seit: Date;
    ht_beleg_id: number;
    rechnung_id: number; // aus beleg
    pdf_id_rechnung: number;
    beleg_nr: number;
}

export class HeimtierKalenderEintragOutput {
    heimtierKalenderEintrag: HeimtierKalenderEintrag;
    confirm: boolean = false;
}

export class ProcessedSlots {
    timeSlot: Date;
    entry: HeimtierKalenderEintrag;
    rowspan: number | null;
    assignedColumn: number;
    entryCount: number;
    // Neue Eigenschaften für die Darstellung
    layer?: number;    // Schicht/Lage des Eintrags
    left?: number;     // Linke Position in Prozent
    width?: number;    // Breite in Prozent
    zIndex?: number;   // Z-Index für die Überdeckungslogik
    column?: number;   // Spalte, in der der Eintrag angezeigt wird
    totalColumns?: number; // Gesamtanzahl der Spalten
}


export class Heimtier {
    kunde_id: number;
    heimtier_id: number;
    name: string;
    tierart: string
    tierart_id: number;
    geschlecht_id: number;
    geschlecht: string;

    verstorben: number;
    fellfarbe: string;
    identifizierung: string;
    chipnummer: string;
    zuchtbuchnummer: string;
    eu_tierpass: string;
    tierrasse: string;
    tierrasse_id: number;
    geburtsdatum: Date;

    aktuelles_gewicht: number;
}

export class HeimtierOutput {
    heimtier: Heimtier;
    confirm: boolean = false;
}
