import { DatePipe, formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChartType } from 'angular-google-charts';
import { environment } from '../../../../environments/environment';
import { AppService } from '../../../app.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { ZeitauswahlChange } from '../../zeitauswahl/zeitauswahl-change';
import { LieferantGesamt } from './lieferanten-gesamt';
import { CsvColumn } from '../../../csv-export/csv-export/csv-column';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { Subscription } from 'rxjs';
import { ReportUtils } from '../../report-utils';

@Component({
    selector: 'kiene-lieferanten-gesamt-report',
    templateUrl: './lieferanten-gesamt-report.component.html',
    styleUrls: ['./lieferanten-gesamt-report.component.scss'],
    animations: [
        trigger('detailExpanded', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
    ],
})
export class LieferantenGesamtReportComponent implements OnInit, AfterViewInit {
    displayedColumns = [
        'lieferant',
        'umsatz_prozent',
        'umsatz',
        'gutschriften',
        'gutschriften_prozent',
        'rabatte',
        'rabatte_prozent',
        'endpreis',
        'bestellungen',
    ];
    dataSource = new MatTableDataSource<LieferantGesamt>();
    dataSourceVorjahr = new MatTableDataSource<LieferantGesamt>();
    @ViewChild(MatSort) sort: MatSort;
    expandedElement: LieferantGesamt | null;

    reportParams = new HttpParams();
    zeiteintrag: ZeitauswahlChange;
    vorjahreszeitraum: ZeitauswahlChange;

    chartType = ChartType.PieChart;
    chartColumns = ['Lieferant', 'Umsatz'];
    chartData = [['', 0]];

    csvColumns: CsvColumn[] = [];
    csvColumnsDetails: CsvColumn[] = [];
    csvFilename = 'Lieferanten_Gesamt.csv';

    gesamtUmsatz = 0;
    gesamtUmsatzProzent = '0';
    gesamtUmsatzII = 0;
    gesamtUmsatzIIProzent = '0';
    gesamtGutschriften = 0;
    gesamtGutschriftenProzent = '0';
    gesamtRabatte = 0;
    gesamtRabatteProzent = '0';
    gesamtBestellungen = 0;

    gesamtUmsatzVorjahr = 0;
    gesamtUmsatzProzentVorjahr = '0';
    gesamtUmsatzIIVorjahr = 0;
    gesamtUmsatzIIProzentVorjahr = '0';
    gesamtGutschriftenVorjahr = 0;
    gesamtGutschriftenProzentVorjahr = '0';
    gesamtRabatteVorjahr = 0;
    gesamtRabatteProzentVorjahr = '0';
    gesamtBestellungenVorjahr = 0;

    isLoading: boolean[] = [];
    aktuellSubscription: Subscription;
    vorjahrSubscription: Subscription;

    constructor(
        private api: KieneTableService,
        private appService: AppService,
        private datePipe: DatePipe,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.reportParams = this.reportParams.set(
            'von',
            this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        );
        this.reportParams = this.reportParams.set(
            'bis',
            this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        );

        this.csvColumns.push(new CsvColumn('lieferant', 'Lieferant'));
        this.csvColumns.push(
            new CsvColumn('umsatz_prozent', 'Umsatz von Gesamt (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('umsatz', 'Umsatz'));
        this.csvColumns.push(new CsvColumn('gutschriften', 'Gutschriften'));
        this.csvColumns.push(
            new CsvColumn('gutschriften_prozent', 'Gutschriften (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('rabatte', 'Rabatte'));
        this.csvColumns.push(
            new CsvColumn('rabatte_prozent', 'Rabatte (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('endpreis', 'Umsatz II.'));
        this.csvColumns.push(new CsvColumn('bestellungen', 'Bestellungen'));

        this.csvColumnsDetails = this.csvColumns.map((column) => ({
            ...column,
        }));
        this.csvColumnsDetails.splice(
            0,
            1,
            new CsvColumn('zeitraum', 'Zeitraum')
        );
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    loadData() {
        this.loadDataAktuell();
        this.loadDataVorjahr();
    }

    loadDataAktuell() {
        this.appService.setDataLoading(true);

        let params = new HttpParams();
        params = params.set('von', formatDate(this.zeiteintrag.von, 'yyyy-MM-dd', 'de'));
        params = params.set('bis', formatDate(this.zeiteintrag.bis, 'yyyy-MM-dd', 'de'));

        this.aktuellSubscription?.unsubscribe();
        this.aktuellSubscription = this.api
            .get(
                environment.BASE_URI + 'report/read_lieferant_gesamt.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSource.data = response.records;
                    this.resetChartData();
                    this.berechneGesamtwerte();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    loadDataVorjahr() {
        this.appService.setDataLoading(true);

        let params = new HttpParams();
        params = params.set('von', formatDate(this.vorjahreszeitraum.von, 'yyyy-MM-dd', 'de'));
        params = params.set('bis', formatDate(this.vorjahreszeitraum.bis, 'yyyy-MM-dd', 'de'));

        this.vorjahrSubscription?.unsubscribe();
        this.vorjahrSubscription = this.api
            .get(
                environment.BASE_URI + 'report/read_lieferant_gesamt.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSourceVorjahr.data = response.records;
                    this.berechneGesamtwerteVorjahr();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    zeitintervalChanged(event: ZeitauswahlChange) {
        this.zeiteintrag = event;
        this.vorjahreszeitraum = ReportUtils.getVorjahreszeitraum(this.zeiteintrag);
        this.loadData();
    }

    berechneGesamtwerte() {
        this.gesamtUmsatz = 0;
        this.gesamtUmsatzII = 0;
        this.gesamtGutschriften = 0;
        this.gesamtRabatte = 0;
        this.gesamtBestellungen = 0;
        const data = this.dataSource.data;
        for (const r of data) {
            this.chartData.push([r.lieferant, r.umsatz ? r.umsatz : 0]);
            this.gesamtUmsatz += r.umsatz ? r.umsatz : 0;
            this.gesamtUmsatzII += r.endpreis ? r.endpreis : 0;
            this.gesamtGutschriften += r.gutschriften ? r.gutschriften : 0;
            this.gesamtRabatte += r.rabatte ? r.rabatte : 0;
            this.gesamtBestellungen += r.bestellungen ? r.bestellungen : 0;
        }
        if (this.gesamtUmsatz > 0) {
            this.gesamtGutschriftenProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtGutschriften * 100
                ) / 100
            ).toString();
            this.gesamtRabatteProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtRabatte * 100
                ) / 100
            ).toString();
            this.gesamtUmsatzIIProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtUmsatzII * 100
                ) / 100
            ).toString();
        }
    }

    berechneGesamtwerteVorjahr() {
        this.gesamtUmsatzVorjahr = 0;
        this.gesamtUmsatzIIVorjahr = 0;
        this.gesamtGutschriftenVorjahr = 0;
        this.gesamtRabatteVorjahr = 0;
        this.gesamtBestellungenVorjahr = 0;
        const data = this.dataSourceVorjahr.data;
        for (const r of data) {
            this.gesamtUmsatzVorjahr += r.umsatz ? r.umsatz : 0;
            this.gesamtUmsatzIIVorjahr += r.endpreis ? r.endpreis : 0;
            this.gesamtGutschriftenVorjahr += r.gutschriften ? r.gutschriften : 0;
            this.gesamtRabatteVorjahr += r.rabatte ? r.rabatte : 0;
            this.gesamtBestellungenVorjahr += r.bestellungen ? r.bestellungen : 0;
        }
        if (this.gesamtUmsatzVorjahr > 0) {
            this.gesamtGutschriftenProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtGutschriftenVorjahr * 100
                ) / 100
            ).toString();
            this.gesamtRabatteProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtRabatteVorjahr * 100
                ) / 100
            ).toString();
            this.gesamtUmsatzIIProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtUmsatzIIVorjahr * 100
                ) / 100
            ).toString();
        }
    }

    expandVetGroupRow(element: LieferantGesamt) {
        this.expandedElement =
            this.expandedElement === element ? null : element;
        if (this.expandedElement) {
            this.isLoading[element.lieferant_id] = true;

            let params = new HttpParams();
            params = params.set('von', formatDate(this.zeiteintrag.von, 'yyyy-MM-dd', 'de'));
            params = params.set('bis', formatDate(this.zeiteintrag.bis, 'yyyy-MM-dd', 'de'));
            params = params.set(
                'aggregieren',
                this.zeiteintrag.aggregieren
            );
            params = params.set(
                'lieferant_id',
                element.lieferant_id.toString()
            );
            this.api
                .get(
                    environment.BASE_URI + 'report/read_lieferant_gesamt.php',
                    params
                )
                .subscribe(
                    (response) => {
                        element.details = response.records;
                        this.isLoading[element.lieferant_id] = false;
                    },
                    (error) => {
                        this.messageService.errorMessage('Fehler!', error);
                        this.isLoading[element.lieferant_id] = false;
                    }
                );
        }
    }

    resetChartData() {
        this.chartData = [['', 0]];
    }

}
