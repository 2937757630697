import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';

@Component({
  selector: 'kiene-rechnung-email-dialog',
  templateUrl: './rechnung-email-dialog.component.html',
  styleUrls: ['./rechnung-email-dialog.component.scss']
})
export class RechnungEmailDialogComponent implements OnInit {

  bisCtrl = new UntypedFormControl(new Date(), [Validators.required]);
  vonCtrl = new UntypedFormControl([Validators.required]);
  anzahlBetroffenerRechnungen = 0;

  constructor(public dialogRef: MatDialogRef<RechnungEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private api: KieneTableService
  ) { }

  ngOnInit(): void {
    this.vonCtrl.valueChanges.subscribe({
      next: value => {
        this.gibAnzahlBetroffenerRechnungen();
      }
    });

    this.bisCtrl.valueChanges.subscribe({
      next: value => {
        this.gibAnzahlBetroffenerRechnungen();
      }
    });
  }

  gibAnzahlBetroffenerRechnungen() {
    if (!this.bisCtrl.valid || !this.vonCtrl.valid) {
      this.anzahlBetroffenerRechnungen = 0;
      return;
    }
    let params = new HttpParams();
    params = params.set('mail', 'null');
    params = params.set('artikel', '0');
    params = params.set('status_id[]', '53');
    params = params.append('status_id[]', '54');
    params = params.set('datum_von', (<Date>this.vonCtrl.value).toISOString());
    params = params.set('datum_bis', (<Date>this.bisCtrl.value).toISOString());
    this.api.get(environment.BASE_URI + 'rechnung/read.php', params).subscribe({
      next: response => {
        this.anzahlBetroffenerRechnungen = response.count;
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const obj = {
      datum_von: this.vonCtrl.value,
      datum_bis: this.bisCtrl.value
    };

    this.dialogRef.close(obj);
  }

}
