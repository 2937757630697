<h2 mat-dialog-title>Betriebsart editieren</h2>
<mat-dialog-content>
  <mat-form-field style="width: 100%;">
    <input type="number" matInput placeholder="Sortierung" [formControl]="sortierungCtrl">
  </mat-form-field>
  <mat-form-field style="width: 100%;">
    <input type="text" matInput placeholder="Bezeichnung" [formControl]="betriebsartCtrl">
  </mat-form-field>
  <br>
  <mat-checkbox style="margin-right: 24px;" [formControl]="kaelbermastCtrl">K&auml;lbermast</mat-checkbox>
  <mat-checkbox [formControl]="ohrmarkeCtrl">Ohrmarke</mat-checkbox>
  <br>
  <kiene-verkaufspreis-selector [selectedVerkaufspreisId]="betriebsart.verkaufspreis_id" (verkaufspreisSelectionChanged)="setVerkaufspreis($event)"></kiene-verkaufspreis-selector>
  <br>
  <mat-form-field>
    <input type="number" matInput placeholder="Rabatt" [formControl]="rabattCtrl">
    <span matSuffix>%</span>
  </mat-form-field>
  <br>
  <mat-checkbox [formControl]="qsCtrl">QS</mat-checkbox><br>
  <mat-checkbox [formControl]="hitCtrl">HIT</mat-checkbox><br>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
  <button mat-raised-button color="primary" (click)="save()">Speichern</button>
</mat-dialog-actions>