import { HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { environment } from '../../../../environments/environment';
import {
    ArticleScanAutocompleteComponent,
    ArticleScanSelectedEvent
} from '../../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { MessageService } from '../../../services/message.service';
import { Material } from '../../../warehouse/material/material';
import { Abgabebeleg, AbgabebelegWarenkorb } from '../abgabebeleg';
import { Client } from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-abgabebeleg-warenkorb-dialog',
    templateUrl: './abgabebeleg-warenkorb-dialog.component.html',
    styleUrls: ['./abgabebeleg-warenkorb-dialog.component.scss']
})
export class AbgabebelegWarenkorbDialogComponent implements OnInit {

    @ViewChild('mengeInput') mengeInput: MatInput;
    @ViewChild('articleScan') articleScan: ArticleScanAutocompleteComponent;

    currentClient: Client;
    mengeCtrl = new UntypedFormControl();
    warenkorbEintraege: AbgabebelegWarenkorb[] = [];
    currentWarenkorbArtikel: Material;
    isAdding = false;

    constructor(public dialogRef: MatDialogRef<AbgabebelegWarenkorbDialogComponent>,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.currentClient = this.localStorageService.getCurrentClient();
        this.loadAbgabebelegWarenkorb();
    }

    loadAbgabebelegWarenkorb() {
        let params = new HttpParams();
        if (this.currentClient.unternehmensgruppe_id) {
            params = params.set('unternehmensgruppe_id', this.currentClient.unternehmensgruppe_id.toString());
        } else {
            params = params.set('kunde_id', this.currentClient.kunde_id.toString());
        }
        this.tableService.get(environment.BASE_URI + 'abgabebeleg_warenkorb/read.php', params).subscribe({
            next: response => {
                this.warenkorbEintraege = response.records;
                this.articleScan?.setFocus();
            },
            error: err => {
                this.messageService.errorMessage('Warenkorb konnte nicht geladen werden!', err);
            }
        });
    }

    setWarenkorbArtikel(event: ArticleScanSelectedEvent) {
        this.currentWarenkorbArtikel = event.article;
        this.mengeInput?.focus();
    }

    addArtikelWarenkorb() {
        if (!this.currentWarenkorbArtikel.artikel_id || !this.mengeCtrl.value) {
            return;
        }
        this.isAdding = true;
        const aw = new AbgabebelegWarenkorb();
        aw.artikel_id = this.currentWarenkorbArtikel.artikel_id;
        aw.artikelmenge = this.mengeCtrl.value;
        aw.kunde_id = this.currentClient.kunde_id;

        this.tableService.updateEntity(environment.BASE_URI + 'abgabebeleg_warenkorb/update.php', aw).subscribe({
            next: () => {
                this.articleScan.clearAndFocus();
                this.mengeCtrl.setValue(null);
                this.loadAbgabebelegWarenkorb();
                this.messageService.infoMessage('Die Position wurde erfolgreich hinzugefügt!');
                this.isAdding = false;
            },
            error: err => {
                this.messageService.errorMessage('Eintrag konnte nicht hinzugefügt werden!', err);
                this.isAdding = false;
            }
        });
    }

    deleteItem(we: AbgabebelegWarenkorb) {
        const index = this.warenkorbEintraege.indexOf(we);
        this.warenkorbEintraege.splice(index, 1);
        this.tableService.deleteEntity(environment.BASE_URI + 'abgabebeleg_warenkorb/delete.php', we).subscribe({
            next: () => {
                this.loadAbgabebelegWarenkorb();
                this.messageService.infoMessage('Das Element wurde erfolgreich gelöscht!');
            },
            error: err => {
                this.messageService.errorMessage('Das Element konnte nicht gelöscht werden!', err);
                this.loadAbgabebelegWarenkorb();
            }
        });
    }

    close() {
        this.dialogRef.close();
    }

    abgabebelegErzeugen() {
        this.tableService.post(environment.BASE_URI + 'abgabebeleg/generieren_warenkorb.php', { kunde_id: this.currentClient.kunde_id }, undefined).subscribe({
            next: (response: Abgabebeleg) => {
                this.messageService.infoMessage('Der Abgabebeleg wurde erfolgreich erstellt!');
                this.dialogRef.close(response);
            },
            error: err => {
                this.messageService.errorMessage('Der Abgabebeleg konnte nicht erstellt werden!', err);
            }
        });
    }

    blurMenge() {
        this.addArtikelWarenkorb();
    }

}
