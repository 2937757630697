import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'kiene-zeitslot-editing',
  templateUrl: './zeitslot-editing.component.html',
  styleUrls: ['./zeitslot-editing.component.scss']
})
export class ZeitslotEditingComponent implements OnInit {
  @Input() zeitfenster: Zeitfenster[];
  @Input() wiederholungen: { value: number; name: string; }[];
  @Input() zeitslot: Zeitslot;
  @Output() zeitslotChange = new EventEmitter<Zeitslot>();

  animalCount: number = 0;
  selectedZeitfenster: number = -1;
  selectedWiederholungen: number = -1; 
  selectedDate: Date = null;

  zeitfensterCtrl: FormControl<number> = new FormControl(this.selectedZeitfenster,[Validators.required]);
  wiederholungenCtrl: FormControl<number> = new FormControl(this.selectedWiederholungen,[Validators.required]);
  countCtrl: FormControl<number> = new FormControl(this.animalCount,[Validators.required, Validators.min(1)]);
  dateCtrl: FormControl<Date> = new FormControl(this.selectedDate, [Validators.required, this.dateValidator()]);

  constructor() {
  }

  dateValidator() : ValidatorFn{
    return (control: AbstractControl) : ValidationErrors | null => {
      
      let isValid = control.value > new Date();
      return !isValid ? {dateError: true} : null;
    }
  }

  ngOnInit(): void {
    this.countCtrl.valueChanges.subscribe((x) => {this.countChanged()});
    this.zeitfensterCtrl.valueChanges.subscribe((x)=> {this.zeitfensterChanged()});
    this.dateCtrl.valueChanges.subscribe((x) => {this.dateChanged()});
    this.wiederholungenCtrl.valueChanges.subscribe((x) => {this.wiederholungenChanged()});
  }

  ngOnChanges(changes){
    let keys = Object.keys(changes);
    console.log(keys);

    keys.forEach(x => {
      switch(x){
        case 'zeitfenster':
          //TODO implement
          console.log(changes[x]);
          break;
        case 'wiederholungen':
          //TODO implement
          break;
        case 'zeitslot':
          //TODO implement
          this.countCtrl.setValue(changes[x].kapazitaet);
          break;
        default:
          break;
      }
    })
  }

  countChanged(){
    if(this.countCtrl.valid){
      this.zeitslot.kapazitaet = this.countCtrl.value;
    }
  }

  zeitfensterChanged(){
    if(this.zeitfensterCtrl.valid){
      this.zeitslot.zeitfenster = this.zeitfensterCtrl.value;
    }
  }

  wiederholungenChanged(){
    if(this.wiederholungenCtrl.valid){
      this.zeitslot.wiederholungen = this.wiederholungenCtrl.value;
    }
  }

  dateChanged(){
    if(this.dateCtrl.valid){
      this.zeitslot.tag = this.dateCtrl.value;
    }
  }

  public isValid(){
    return this.wiederholungenCtrl.valid &&
    this.zeitfensterCtrl.valid && 
    this.countCtrl.valid &&
    this.dateCtrl.valid;
  }
}

export class Zeitfenster{
  index: number;
  text: string;
  start: Date;
  end: Date;
  constructor(index: number, start: Date, end: Date){
    this.index = index;
    this.start = start;
    this.end = end;

    let startFormat = moment(start).format('HH:mm');
    let endFormat = moment(end).format('HH:mm');
    this.text = startFormat+" - "+endFormat;
  }
}

export class Zeitslot{
  tag: Date;
  zeitfenster: number;
  kapazitaet: number;
  wiederholungen: number;
  anFeiertagen: boolean = false;
}