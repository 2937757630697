import { Component, OnInit, ViewChild, ElementRef, Inject, AfterViewInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
    ArticleScanSelectedEvent,
    ArticleScanAutocompleteComponent
} from '../../article-scan-autocomplete/article-scan-autocomplete.component';
import { ArtikelMenge } from '../tourenplan';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';

@Component({
    selector: 'kiene-add-article-position-dialog',
    templateUrl: './add-article-position-dialog.component.html',
    styleUrls: ['./add-article-position-dialog.component.scss']
})
export class AddArticlePositionDialogComponent implements OnInit, AfterViewInit {

    @ViewChild('articleAuto') articleAuto: ArticleScanAutocompleteComponent;
    @ViewChild('identifikation') identifikation: MatInput;
    @ViewChild('menge') menge: MatInput;
    @ViewChild('anzahlTiere') anzahlTiere: MatInput;

    currentArtikelMenge = new ArtikelMenge();

    mengeCtrl = new UntypedFormControl();
    anzahlTiereCtrl = new UntypedFormControl();

    identifikationCtrl = new UntypedFormControl();


    constructor(public dialogRef: MatDialogRef<AddArticlePositionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any) {
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        if (this.data) {
            this.currentArtikelMenge = this.data;
            this.initFormControls();
        }
    }

    setSelectedMaterial(event: ArticleScanSelectedEvent) {
        this.currentArtikelMenge.article = event.article;
        this.menge?.focus();
    }

    save() {
        this.currentArtikelMenge.menge = this.mengeCtrl.value;
        this.currentArtikelMenge.anzahl_tiere = this.anzahlTiereCtrl.value;
        this.currentArtikelMenge.identifikation = this.identifikationCtrl.value;
        return this.dialogRef.close(this.currentArtikelMenge);
    }

    cancel() {
        return this.dialogRef.close(null);
    }

    private initFormControls() {
        this.articleAuto.setMaterial(this.currentArtikelMenge.article, false);
        this.mengeCtrl.setValue(this.currentArtikelMenge.menge);
        this.anzahlTiereCtrl.setValue(this.currentArtikelMenge.anzahl_tiere);
        this.identifikationCtrl.setValue(this.currentArtikelMenge.identifikation);
    }
}
