<h1 mat-dialog-title>Transpondernummer eingeben</h1>

<div mat-dialog-content>
    <mat-form-field style="width: 100%">
        <mat-label>Transpondernummer</mat-label>
        <input data-cy="transpondernummer-eingabe" matInput type="text" required [formControl]="transponderNrCtrl" placeholder="Transpondernummer"/>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button data-cy="transpondernummer-abort" (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button data-cy="transpondernummer-save" color="accent" [disabled]="!isValidInput()" (click)="dialogRef.close(transponderNrCtrl.value)">Speichern</button>
</div>
