import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import { KieneTableService } from '../../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../../services/message.service';
import {
    Befundmitteilung,
    BefundmitteilungExtern,
} from '../../befundmitteilung';
import {
    PdfViewerDesktopService, PdfViewerDialogInput
} from '../../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';

@Component({
    selector: 'kiene-externe-befunde-dialog',
    templateUrl: './externe-befunde-dialog.component.html',
    styleUrls: ['./externe-befunde-dialog.component.css'],
})
export class ExterneBefundeDialogComponent implements OnInit {
    befundmitteilung: Befundmitteilung;
    externeBefunde: BefundmitteilungExtern[] = [];

    constructor(
        public dialogRef: MatDialogRef<ExterneBefundeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private pdfViewerService: PdfViewerDesktopService,
        private api: KieneTableService,
        private messageService: MessageService
    ) {
        if (this.data) {
            (this.befundmitteilung = data.befundmitteilung),
                (this.externeBefunde = data.externeBefunde);
        }
    }

    ngOnInit(): void {}

    delete(externerBefund: BefundmitteilungExtern, index: number) {
        if (externerBefund.be_id) {
            this.api
                .post(
                    environment.BASE_URI + 'befund_dokument/delete.php',
                    externerBefund,
                    null
                )
                .subscribe(
                    (response) => {
                        this.externeBefunde.splice(index, 1);
                        this.messageService.infoMessage(
                            'Die Datei wurde erfolgreich gelöscht!'
                        );
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Die Datei konnte nicht gelöscht werden!',
                            error
                        );
                    }
                );
        }
    }

    openFile(externerBefund: BefundmitteilungExtern) {
        if (externerBefund.pdf_id) {
            const input = new PdfViewerDialogInput(
                'befund_dokument/read_pdf.php',
                new HttpParams().set('be_id', externerBefund?.be_id?.toString()),
                'Befund Extern'
            );
            this.pdfViewerService.openPdfViewer(input);
        }
    }

    close() {
        this.dialogRef.close();
    }
}
