import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Vet} from '../../../../administration/vet/vet';
import {
    LocalStorageService
} from '../../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MassnahmenplanRind} from '../massnahmenplan-rind';
import {Client} from '../../../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-massnahmenplan-rind-kurz-dialog',
    templateUrl: './massnahmenplan-rind-kurz-dialog.component.html',
    styleUrls: ['./massnahmenplan-rind-kurz-dialog.component.scss']
})
export class MassnahmenplanRindKurzDialogComponent implements OnInit {

    mpr: MassnahmenplanRind = new MassnahmenplanRind();
    currentClient: Client;

    jahrCtrl = new UntypedFormControl();
    halbjahrCtrl = new UntypedFormControl();
    therapieCtrl = new UntypedFormControl();
    datumCtrl = new FormControl(new Date());
    ortCtrl = new UntypedFormControl();
    betreuungsVetragCtrl = new UntypedFormControl();
    showTierarztSelector = false;
    abrechnenCtrl = new FormControl(true);


    constructor(public dialogRef: MatDialogRef<MassnahmenplanRindKurzDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data,
                private localStorageService: LocalStorageService) {
        if (this.data && this.data.mpr) {
            this.mpr = this.data.mpr;
        }
    }

    ngOnInit(): void {
        this.currentClient = this.localStorageService.getCurrentClient();
        this.initJahr();
        this.ortCtrl.setValue(this.currentClient.ort_final);
        this.mpr._11_2_ort = this.currentClient.ort_final;

        if (!this.localStorageService.getCurrentUser().tierarzt_id) {
            this.showTierarztSelector = true;
        }

        // if (this.mpr.mnp_rind_id) {
        this.jahrCtrl.setValue(this.mpr._0_jahr);
        this.halbjahrCtrl.setValue(this.mpr._0_halbjahr);
        this.therapieCtrl.setValue(this.mpr._0_therapiehaeufigkeit);
        this.ortCtrl.setValue(this.mpr._11_2_ort);

        if (this.mpr.mnp_rind_id) {
            this.datumCtrl.setValue(this.mpr._11_2_datum);
        }

        this.betreuungsVetragCtrl.setValue(this.mpr._2_ta_betreuungsvertrag ? true : false);
        if (!this.mpr.tierarzt_id) {
            if (this.localStorageService.getCurrentUser().tierarzt_id) {

                this.mpr.tierarzt_id =
                    this.localStorageService.getCurrentUser().tierarzt_id;
            }
        }
        // }
    }

    hasPermission(permissionId: number): boolean {
        return this.localStorageService.currentUserHasPermission(permissionId);
    }

    setTierarzt(vet: Vet) {
        if (!vet) {
            this.mpr.tierarzt_id = null;
        } else {
            this.mpr.tierarzt_id = vet.tierarzt_id;
        }
    }

    initJahr() {
        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth();
        if (currentMonth >= 0 && currentMonth <= 5) {
            this.jahrCtrl.setValue((currentYear - 1));
            this.halbjahrCtrl.setValue(2);
            this.mpr._0_halbjahr = 2;
            this.mpr._0_jahr = (currentYear - 1);
        } else {
            this.jahrCtrl.setValue(currentYear);
            this.halbjahrCtrl.setValue(1);
            this.mpr._0_halbjahr = 1;
            this.mpr._0_jahr = (currentYear);
        }
    }

    close() {
        this.dialogRef.close();
    }

    save() {

        this.mpr.abrechnen = this.abrechnenCtrl.value ? 1 : 0;

        this.mpr._0_jahr = this.jahrCtrl.value;
        this.mpr._0_halbjahr = this.halbjahrCtrl.value;
        this.mpr._0_therapiehaeufigkeit = this.therapieCtrl.value;
        this.mpr._11_2_ort = this.ortCtrl.value;
        this.mpr._11_2_datum = this.datumCtrl.value;
        this.mpr.mnp_kurz = 1;
        this.mpr.status_id = 62;
        this.mpr.kunde_id = this.currentClient.kunde_id;
        if (!this.mpr.tierarzt_id) {
            this.mpr.tierarzt_id =
                this.localStorageService.getCurrentUser().tierarzt_id;
        }
        this.mpr._2_ta_betreuungsvertrag = this.betreuungsVetragCtrl.value ? 1 : 0;


        this.dialogRef.close(this.mpr);
    }

}
