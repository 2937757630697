import {HttpParams} from "@angular/common/http";
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {Tierarzt} from "../../../../projects/kiene-core/src/lib/components/tieraerzte/classes/tierarzt";
import {API_BASE_URL_SERVICE} from "../../../../projects/kiene-core/src/lib/kiene-core.config";
import {
    KieneBackendApiService
} from "../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service";
import { MessageService } from "../../services/message.service";
import { HeimtierBeleg, HeimtierBelegStatus } from '../heimtier-beleg';
import { Client } from '../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { Heimtier } from '../kalender/heimtier-kalender-eintrag';

@Component({
    selector: 'kiene-besuchs-detail-seite',
    templateUrl: './besuchs-detail-seite.component.html',
    styleUrls: ['./besuchs-detail-seite.component.scss']
})
export class BesuchsDetailSeiteComponent implements OnInit {
    public heimtierBeleg: HeimtierBeleg;
    kunde: Client;
    besuchsdatumCtrl = new FormControl(undefined, [Validators.required]);
    behandlungStartCtrl = new FormControl();
    behandlungEndeCtrl = new FormControl();
    arztCtrl = new FormControl(undefined, Validators.required);
    aerzte: Tierarzt[] = [];
    heimtier: Heimtier;

    belegValid = false;
    rechnungenValid = false;
    letzteBelegeValid = false;
    informationenValid = false;
    isSaving = false;

    selectedTabIndex: number = 0; // Standard-Tab

    constructor(
        private route: ActivatedRoute,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private router: Router,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
    ) {
    }

    ngOnInit(): void {
        const ht_beleg_id = this.route.snapshot.paramMap.get('id');
        if (!ht_beleg_id) {
            return;
        }
        const savedIndex = localStorage.getItem('selectedTabIndex');
        if (savedIndex !== null) {
            this.selectedTabIndex = +savedIndex;
        }
        this.loadHeimtierBeleg(Number.parseInt(ht_beleg_id));
        this.loadAerzte();

        this.arztCtrl.valueChanges.subscribe({
            next: arzt => {
                this.heimtierBeleg.tierarzt_id = arzt.tierarzt_id;
                this.updateBeleg();
            }
        });

        this.besuchsdatumCtrl.valueChanges.subscribe({
            next: datum => {
                this.heimtierBeleg.datum = datum;
                this.updateBeleg();
            }
        });

        this.behandlungStartCtrl.valueChanges.subscribe({
            next: start => {
                if (start) {
                    if (start.includes(':')) {
                        const time = start.split(':');
                        const seit = new Date(this.heimtierBeleg.datum);
                        seit.setHours(+time[0], +time[1]);
                        this.heimtierBeleg.behandlung_seit = seit;
                        this.updateBeleg();
                    }
                }

            }
        });

        this.behandlungEndeCtrl.valueChanges.subscribe({
            next: ende => {
                if (ende) {
                    if (ende.includes(':')) {
                        const time = ende.split(':');
                        const bis = new Date(this.heimtierBeleg.datum);
                        bis.setHours(+time[0], +time[1]);
                        this.heimtierBeleg.behandlung_bis = bis;
                        this.updateBeleg();
                    }
                }

            }
        });

    }

    public abschliessen(): void {
        this.isSaving = true;
        this.api.post(this.apiBaseUrl + 'heimtier_beleg/abschliessen.php', this.heimtierBeleg).subscribe({
            next: () => {
                this.isSaving = false;
                this.router.navigate(['heimtiere', 'startseite']);
            },
            error: err => {
                this.isSaving = false;
                this.messageService.errorMessage('Fehler beim Abschließen!', err);
            }
        });
    }

    public isDisabled(): boolean {
        return this.isSaving;
    }

    onBelegValidationChange(isValid: boolean) {
        this.belegValid = isValid;
    }

    onTabChange(event: any) {
        this.selectedTabIndex = event.index;
        localStorage.setItem('selectedTabIndex', this.selectedTabIndex.toString());
    }

    setCurrentHeimtier(heimtier: Heimtier) {
        this.heimtier = heimtier;
    }

    private loadHeimtierBeleg(ht_beleg_id: number): void {
        let param = new HttpParams().set('ht_beleg_id', ht_beleg_id);
        this.api.get(this.apiBaseUrl + 'heimtier_beleg/read_one.php', param).subscribe({
            next: (response) => {
                this.heimtierBeleg = response;
                this.initFormFields();
                this.loadKunde();
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Laden des Belegs', err);
            }
        });
    }

    private loadAerzte(): void {
        this.api.getAll(this.apiBaseUrl + 'tierarzt/read.php').subscribe({
            next: (data) => {
                this.aerzte = data.records;
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Laden der Ärzte', err);
            }
        });
    }

    private loadKunde(): void {
        const params: HttpParams = new HttpParams().set('kunde_id', this.heimtierBeleg?.kunde_id);
        this.api.get(this.apiBaseUrl + 'heimtier_kunde/read_one.php', params).subscribe({
            next: (data) => {
                this.kunde = data;
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Laden des Kunden', err);
            }
        });
    }

    private setCurrentTierarzt() {
        if (this.heimtierBeleg?.tierarzt_id) {
            this.arztCtrl.setValue(this.aerzte.find((arzt) => arzt.tierarzt_id === this.heimtierBeleg.tierarzt_id), {emitEvent: false});
        }
    }

    private updateBeleg() {
        if (this.heimtierBeleg.status_id === HeimtierBelegStatus.ABGESCHLOSSEN || this.heimtierBeleg.status_id === HeimtierBelegStatus.ERSTELLT) {
            this.api.post(this.apiBaseUrl + 'heimtier_beleg/update.php', this.heimtierBeleg).subscribe({
                next: (response) => {
                    this.heimtierBeleg = response;
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler beim Speichern des Belegs', err);
                }
            });
        } else {
            this.messageService.alertMessage('Speichern in diesem Status nicht möglich!');
        }
    }

    protected readonly HeimtierBelegStatus = HeimtierBelegStatus;

    private initFormFields() {
        this.besuchsdatumCtrl.setValue(this.heimtierBeleg?.datum, {emitEvent: false});
        this.behandlungStartCtrl.setValue(this.formatTime(this.heimtierBeleg?.behandlung_seit));
        if (this.heimtierBeleg?.behandlung_bis) {
            this.behandlungEndeCtrl.setValue(this.formatTime(this.heimtierBeleg?.behandlung_bis));
        }
        this.setCurrentTierarzt();
    }

    private formatTime(date: Date): string {
        date = new Date(date)
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }
}
