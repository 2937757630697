import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Tierarzt} from "../../../../../projects/kiene-core/src/lib/components/tieraerzte/classes/tierarzt";
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {Vet} from '../../../administration/vet/vet';
import {FormControl} from '@angular/forms';
import {MessageService} from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import {AssignVetToTourData, AssignVetToTourResult} from './assign-vet-to-tour';

@Component({
    selector: 'kiene-assign-vet-to-tour-dialog',
    templateUrl: './assign-vet-to-tour-dialog.component.html',
    styleUrls: ['./assign-vet-to-tour-dialog.component.scss']
})
export class AssignVetToTourDialogComponent implements OnInit {
    vets: Vet[] = [];
    vetSelectCtrl = new FormControl();
    tierarzt = new Tierarzt();

    constructor(public dialogRef: MatDialogRef<AssignVetToTourDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: AssignVetToTourData,
                private lls: LocalStorageService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.ladeTieraerzte();
    }

    compareVet(v1: Vet, v2: Vet) {
        if ( v1?.tierarzt_id > 0 && v2?.tierarzt_id > 0) {
            return v1?.tierarzt_id === v2?.tierarzt_id;
        }
        return false;
    }

    unknown() {
        const result = new AssignVetToTourResult();
        result.confirmed = true;
        result.tierarzt_id = null;
        this.dialogRef.close(result);
    }

    save() {
        if (!this.vetSelectCtrl.value) {
            this.messageService.alertMessage('Bitte einen Tierarzt auswählen!');
            return;
        }
        const result = new AssignVetToTourResult();
        result.confirmed = true;
        // if ( this.vetSelectCtrl.value.tierarzt_id === 0 ) {
        //     this.vetSelectCtrl.value.tierarzt_id = null;
        // }
        result.tierarzt_id = this.vetSelectCtrl.value?.tierarzt_id;
        this.dialogRef.close(result);
    }

    private ladeTieraerzte() {
        if (this.lls.getCurrentUser().tierarzt_id) {
            this.ladeTieraerzteVonMeinerGruppe();
        } else {
            this.ladeAlleTieraerzte();
        }
    }

    private ladeTieraerzteVonMeinerGruppe() {
        this.api.get(this.apiBaseUrl + 'tierarzt/read_gleiche_tierarztgruppe.php', undefined).subscribe({
            next: response => {
                this.vets = response.records;
                for (const v of this.vets) {
                    if (v.tierarzt_id === this.data?.tierarzt_id) {
                        this.vetSelectCtrl.setValue(v);
                        break;
                    }
                }
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Laden!', err);
            }
        });
    }

    private ladeAlleTieraerzte() {
        this.api.get(this.apiBaseUrl + 'tierarzt/read_autocomplete.php', undefined).subscribe({
            next: response => {
                this.vets = response.records;
                for (const v of this.vets) {
                    if (v.tierarzt_id === this.data?.tierarzt_id) {
                        this.vetSelectCtrl.setValue(v);
                        break;
                    }
                }
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Laden!', err);
            }
        });
    }
}
