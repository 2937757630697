import {Component, Inject, OnInit} from '@angular/core';
import {Client} from '../../../components/client/classes/client';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MessageService} from '../../../services/message-service/message.service';
import {StammdatenService} from '../../../components/stammdaten/stammdaten.service';
import {Subscription} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {FormControl} from '@angular/forms';
import {LocalStorageService} from '../../../services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-betrieb-auswaehlen-dialog',
    templateUrl: './betrieb-auswaehlen-dialog.component.html',
    styleUrls: ['./betrieb-auswaehlen-dialog.component.scss']
})
export class BetriebAuswaehlenDialogComponent implements OnInit {

    selectedVvvo: Client;
    vvvos: Client[] = [];
    isLoading = true;

    headline = 'Betrieb auswählen';

    alleAuswaehlenButtonZeigen = true;
    searchCtrl = new FormControl();
    private vvvoSub: Subscription;

    constructor(public dialogRef: MatDialogRef<BetriebAuswaehlenDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data,
                private messageService: MessageService,
                private localStorageService: LocalStorageService,
                private stammdatenService: StammdatenService) {
        if (this.data) {
            if (this.data.alleAuswahlenButtonZeigen) {
                this.alleAuswaehlenButtonZeigen = this.data.alleAuswaehlenButtonZeigen;
            }
            if (this.data.headline) {
                this.headline = this.data.headline;
            }
        }

    }


    ngOnInit(): void {

        this.searchCtrl.valueChanges.subscribe({
            next: () => {
                this.loadVvvos();
            }
        });

        this.selectedVvvo = this.localStorageService.getCurrentClient();
        this.localStorageService.watchCurrentClient().subscribe({
            next: (client: Client) => {
                this.selectedVvvo = client;
            }
        });
        this.loadVvvos();
    }

    loadVvvos() {
        this.isLoading = true;
        this.vvvoSub?.unsubscribe();
        let params;
        if (this.searchCtrl.value) {
            params = new HttpParams().set('search', this.searchCtrl.value);
        }
        this.vvvoSub = this.stammdatenService.ladeVvvos(params).subscribe({
            next: resp => {
                this.isLoading = false;
                this.vvvos = resp.records;
                if (params === undefined && this.vvvos?.length === 1) {
                    this.messageService.infoMessage('Einzigen Betrieb automatisch ausgewählt!');
                    this.betriebAuswaehlen(this.vvvos[0]);
                }
            },
            error: err => {
                this.isLoading = false;
                this.messageService.errorMessage('Fehler beim Laden der Betriebe!', err);
            }
        });
    }


    betriebAuswaehlen(vvvo: Client) {
        this.dialogRef.close(vvvo);
    }

    betriebAbwaehlen() {
        this.localStorageService.setCurrentClient(undefined);
    }
}
