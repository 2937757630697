import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Inventur} from '../../../warehouse/inventur/entities/Inventur';

@Component({
  selector: 'kiene-inventur-delete-dialog',
  templateUrl: './inventur-delete-dialog.component.html',
  styleUrls: ['./inventur-delete-dialog.component.scss']
})
export class InventurDeleteDialogComponent implements OnInit {

  inventur: Inventur;
  toDelete: boolean;

  constructor(public dialogRef: MatDialogRef<InventurDeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    this.inventur = this.data.inventur;
  }
  close() {
    this.dialogRef.close(false);
  }

  save() {
    this.dialogRef.close(true);
  }

}
