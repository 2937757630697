import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";

@Injectable()
export class KieneMatPaginatorIntl extends MatPaginatorIntl {

  constructor() {
    super();
    this.getAndInitTranslations();
  }

  getAndInitTranslations() {

    this.itemsPerPageLabel = "Einträge pro Seite";
    this.nextPageLabel = "Nächste Seite";
    this.previousPageLabel = "Vorherige Seite";
    this.firstPageLabel = 'Erste Seite';
    this.lastPageLabel = 'Letzte Seite';
    this.changes.next();

  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} / ${length}`;
  }
}