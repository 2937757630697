import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../services/message.service';
import { AbgabebelegPosition } from '../abgabebeleg';

@Component({
  selector: 'kiene-change-artikelmenge-dialog',
  templateUrl: './change-artikelmenge-dialog.component.html',
  styleUrls: ['./change-artikelmenge-dialog.component.css']
})
export class ChangeArtikelmengeDialogComponent implements OnInit {

  abgabebelegPosition: AbgabebelegPosition;
  radioCtrl = new UntypedFormControl();
  chargenmengeCtrls: UntypedFormControl[] = [];
  datumCtrl = new UntypedFormControl(new Date());

  constructor(public dialogRef: MatDialogRef<ChangeArtikelmengeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private tableService: KieneTableService,
    private messageService: MessageService) { 
      this.abgabebelegPosition = this.data.abgabebelegPosition;
      this.initChargenmengen();
    }

  ngOnInit(): void {
    
  }

  private initChargenmengen(){
    for(let c of this.abgabebelegPosition.chargen){
      this.chargenmengeCtrls[c.charge] = new UntypedFormControl(c.chargenmenge);
    }
  }

  public close(){
    this.dialogRef.close(null);
  }

  public save(){

    let chargen: any[] = [];

    for(let c of this.abgabebelegPosition.chargen){

      let diff = c.chargenmenge - this.chargenmengeCtrls[c.charge].value;

      let nc = {
        charge_id: c.charge_id,
        chargenmenge_diff: diff
      };

      chargen.push(nc);
    }

    let obj = {
      aa_id: this.abgabebelegPosition.aa_id,
      chargen: chargen,
      datum_gutschrift: this.datumCtrl.value 
    };

    this.tableService.updateGeneric(environment.BASE_URI + 'abgabebeleg/update_menge.php', obj).subscribe(response => {
      this.messageService.infoMessage('Die Artikelmenge wurde erfolgreich korrigiert!');
      this.close();
    }, error => {
      this.messageService.errorMessage('Die Artikelmenge konnte nicht korrigiert werden!', error);
    });
  }

}
