import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FortbildungenModule} from './fortbildungen/fortbildungen.module';
import {TourenplanModule} from '../components/tourenplan/tourenplan.module';
import {TierarztwagenBestandComponent} from './tierarztwagen-bestand/tierarztwagen-bestand.component';
import {UrlaubskalenderModule} from './urlaubskalender/urlaubskalender.module';
import {InformationBoardComponent} from './information-board/information-board.component';
import {MatTabsModule} from '@angular/material/tabs';
import {
    AddInformationDialogComponent
} from './information-board/add-information-dialog/add-information-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {DirectivesModule} from '../../../projects/kiene-core/src/lib/directives/directives.module';
import {MatDialogModule} from '@angular/material/dialog';
import {HomeComponent} from './home.component';
import {WarehouseModule} from '../warehouse/warehouse.module';
import {TabinfoModule} from '../components/tabinfo/tabinfo.module';
import {
    WochenendeUndFeiertagPlanungComponent
} from './wochenende-und-feiertag-planung/wochenende-und-feiertag-planung.component';
import {MatSelectModule} from '@angular/material/select';
import {KieneDirectivesModule} from '../directives/kiene-directives.module';
import {HomeRoutingModule} from './home-routing.module';
import {
    DienstgruppeDetailsDialogComponent
} from './wochenende-und-feiertag-planung/dienstgruppe-details-dialog/dienstgruppe-details-dialog.component';
import {KieneAutocompleteModule} from '../components/kiene-autocomplete/kiene-autocomplete.module';
import {MatChipsModule} from '@angular/material/chips';
import {MatGridListModule} from '@angular/material/grid-list';


@NgModule({
    declarations: [
        HomeComponent,
        TierarztwagenBestandComponent,
        InformationBoardComponent,
        AddInformationDialogComponent,
        WochenendeUndFeiertagPlanungComponent,
        DienstgruppeDetailsDialogComponent
    ],
    imports: [
        CommonModule,
        HomeRoutingModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatCardModule,
        MatMenuModule,
        DirectivesModule,
        MatDialogModule,
        FortbildungenModule,
        TourenplanModule,
        UrlaubskalenderModule,
        WarehouseModule,
        TabinfoModule,
        MatSelectModule,
        KieneDirectivesModule,
        KieneAutocompleteModule,
        MatChipsModule,
        MatGridListModule,
    ],
    exports: [
        HomeComponent,
        InformationBoardComponent
    ]
})
export class HomeModule {
}
