import { Client } from '../../client/classes/client';

export class GruppeAnlegenDialogInput {
    kunde: Client;
    einstalldatumAusblenden: boolean;
    nameVorschlag: string;
    einstalldatum: Date;

    constructor(kunde: Client, einstalldaumAusblenden: boolean, nameVorschlag: string, einstalldatum: Date) {
        this.kunde = kunde;
        this.einstalldatumAusblenden = einstalldaumAusblenden;
        this.nameVorschlag = nameVorschlag;
        this.einstalldatum = einstalldatum;
    }
}