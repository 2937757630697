export class TierRasse {
    rasse_id: number;
    kuerzel: string;
    name: string;
    geburtsgewicht: number;
    tageszunahme: number;
    anzahl: number; // nur für Filter
}

export class TierRasseOutput {
    rasse: TierRasse;
    confirm: boolean = false;
}

export class TierRasseSonstige {
    rasse: string;
    name: string;
    tierart_id: number;
}

export class TierRasseSonstigeOutput {
    rasse: TierRasseSonstige;
    confirm: boolean = false;
}

export class Tierrasse2 {
    tierrasse_id: number;
    name: string;
    tierart_id: number;
    tierart: string;
}
