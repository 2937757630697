import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {Entsorger, KundenNrVvvo} from '../entsorger';
import {MessageService} from '../../../../services/message-service/message.service';
import {StammdatenService} from '../../stammdaten.service';
import {ActivatedRoute, Router} from '@angular/router';
import {KieneAutocompleteComponent} from '../../../kiene-autocomplete/kiene-autocomplete.component';
import {ConfirmDialogComponent} from '../../../dialogs/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {ConfirmDialogInput, ConfirmDialogResult} from '../../../dialogs/confirm-dialog/confirm-dialog';
import {API_BASE_URL_SERVICE} from '../../../../kiene-core.config';
import {Client} from '../../../client/classes/client';
import {EntsorgerAnlegenDialogComponent} from '../entsorger-anlegen-dialog/entsorger-anlegen-dialog.component';

@Component({
    selector: 'kiene-entsorger-details',
    templateUrl: './entsorger-details.component.html',
    styleUrls: ['./entsorger-details.component.scss'],
})
export class EntsorgerDetailsComponent implements OnInit, OnDestroy {
    entsorgerSubscription: Subscription;

    entsorgerPath = `${this.apiBaseUrl}entsorger/read_kundenportal.php?favorit=0`;
    isLoading = false;

    @ViewChild('nameAutocomplete') nameAutocomplete: KieneAutocompleteComponent;
    @Input('dialog') showAsDialog = false;

    @Input('entsorger') entsorger: Entsorger;
    buttonText = 'Anlegen';
    redirectTo: string;
    headline = 'Entsorger';
    backRoute = '../';

    @Input('redirectBack')
    redirectBack = true;
    ersteinrichtung = false;

    @Output('saved')
    saved: EventEmitter<Entsorger> = new EventEmitter<Entsorger>();

    @Output('deleted')
    deleted: EventEmitter<Entsorger> = new EventEmitter<Entsorger>();

    kunde_nrCtrl = new UntypedFormControl(null, [Validators.required]);
    faxCtrl = new UntypedFormControl(null);

    private vvvoSubscription: Subscription;

    constructor(
        private stammdatenService: StammdatenService,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {

    }

    ngOnDestroy(): void {
        this.entsorgerSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.loadEntsorger();

    }


    redirect(entsorgerCreated?: Entsorger) {
        let destinationPath: string[];
        const queryParams = {};

        if (this.redirectBack) {
            // ersteinrichtung - Kundennr fehlen noch - weiterleiten
            if (this.ersteinrichtung && entsorgerCreated) {
                this.redirectTo = 'registrierung-ersteinrichtung';
                queryParams['ersteinrichtung'] = 1;
                destinationPath = [
                    entsorgerCreated.entsorger_id.toString(),
                    'kunden-nr',
                ];

                // neu anlage - Kundennr fehlen noch - weiterleiten
            } else if (entsorgerCreated) {
                if (!this.redirectTo) {
                    destinationPath = [
                        entsorgerCreated.entsorger_id.toString(),
                        'kunden-nr',
                    ];
                    queryParams['redirectTo'] = this.redirectTo;
                    this.redirectTo = `einstellungen-stammdaten-details-${entsorgerCreated.entsorger_id}`;
                }
            }

            // kein Entsorger uebergeben update - zurueckleiten
            if (!destinationPath) {
                window.history.back();
            } else {
                console.log('NAVIGATE: ', destinationPath, queryParams);
                this.router
                    .navigate(destinationPath, {
                        relativeTo: this.route,
                        queryParams,
                    })
                    .then();
            }
        }
    }

    addOrUpdateEntsorger() {
        this.isLoading = true;
        this.stammdatenService
            .updateEntsorger(this.entsorger)
            .subscribe({
                next: (entsorger) => {
                    this.entsorger = entsorger;
                    this.messageService.infoMessage(
                        'Der Entsorger wurde erfolgreich aktualisiert.'
                    );
                    this.isLoading = false;
                    this.emit();
                    if (!this.showAsDialog) {
                        this.router.navigate(['../'], {relativeTo: this.route});
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Beim Aktualisieren des Entsorger ist ein Fehler aufgetreten.',
                        err
                    );
                    this.isLoading = false;
                },
            });
    }

    emit(): void {
        this.saved.emit(this.entsorger);
        this.stammdatenService.speichereLetzenAngelegtenEntsorger(this.entsorger);
    }

    selectedEntsorger(entsorger: Entsorger) {
        if (!entsorger) {
            return;
        }
        this.entsorger = entsorger;
        this.initVvvos();
    }

    deleteEntsorgerFavorit(event: MouseEvent) {
        event.stopPropagation();

        const input = new ConfirmDialogInput();
        input.headline = 'Entsorger löschen';
        input.text = `Möchten Sie den Entsorger ${this.entsorger.name} wirklich löschen?`;
        input.okButton = 'Löschen';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.stammdatenService
                        .deleteEntsorgerFavorit(this.entsorger)
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Der Entsorger wurde erfolgreich gelöscht.'
                                );

                                this.deleted.emit(this.entsorger);
                                if (!this.showAsDialog) {
                                    this.redirect();
                                }
                            },
                            error: (err: any) => {
                                this.messageService.errorMessage(
                                    'Fehler beim Löschen des Entsorgerfavoriten',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    createNewEntsorger(entsorgerName?: string) {
        const dialogRef = this.dialog.open(EntsorgerAnlegenDialogComponent, {
            data: entsorgerName
        });
        dialogRef.afterClosed().subscribe({
            next: (entsorger: Entsorger) => {
                if (entsorger) {
                    this.selectedEntsorger(entsorger);
                }
            }
        });
    }

    private initVvvos() {
        this.isLoading = true;
        this.vvvoSubscription?.unsubscribe();
        this.vvvoSubscription = this.stammdatenService.ladeVvvos().subscribe({
            next: (vvvos) => {
                this.isLoading = false;
                const kunden = <Client[]>vvvos.records;
                // add all
                if (this.entsorger.kundenNrVvvos.length === 0) {
                    kunden.forEach(kunde => {
                        this.entsorger.kundenNrVvvos.push(new KundenNrVvvo(kunde.kunde_id, null, kunde.vvvo,
                            kunde.bezeichnung_final, kunde.zusatz_final));
                    });

                    // only add new
                } else {
                    kunden.forEach(kunde => {
                        const found = this.entsorger.kundenNrVvvos.find(entry => entry.kunde_id === kunde.kunde_id);
                        if (!found) {
                            this.entsorger.kundenNrVvvos.push(new KundenNrVvvo(kunde.kunde_id, null,
                                kunde.vvvo, kunde.bezeichnung_final, kunde.zusatz_final));
                        } else {
                            found.betrieb = kunde.bezeichnung_final;
                            found.betrieb_zusatz = kunde.zusatz_final;
                        }
                    });
                }
                this.entsorger.kundenNrVvvos.sort((obj1, obj2) => {
                    if (obj1.kunde_id > obj2.kunde_id) {
                        return 1;
                    }

                    if (obj1.kunde_id < obj2.kunde_id) {
                        return -1;
                    }

                    return 0;
                });
            },
            error: err => {
                this.isLoading = false;
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private loadEntsorger() {
        const entsorgerId = this.route.snapshot.paramMap.get('id');
        if (entsorgerId) {
            this.stammdatenService.getEntsorger(entsorgerId).subscribe({
                next: entsorger => {
                    this.entsorger = entsorger;
                }
            });
        }
    }
}
