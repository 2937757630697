import {
    LieferantDetailsDialogComponent
} from "../../lieferant/lieferant-details-dialog/lieferant-details-dialog.component";
import {StammdatenService} from "../../stammdaten.service";
import {API_BASE_URL_SERVICE} from "../../../../kiene-core.config";
import {AppService} from "../../../../services/app/app.service";
import {KieneBackendApiService} from "../../../../services/backend-api/kiene-backend-api.service";
import {MessageService} from "../../../../services/message-service/message.service";
import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {Futtermittellieferant} from '../lieferant';
import {Client} from '../../../client/classes/client';

@Component({
    selector: 'kiene-lieferant-uebersicht',
    templateUrl: './lieferant-uebersicht.component.html',
    styleUrls: ['./lieferant-uebersicht.component.scss']
})
export class LieferantUebersichtComponent implements OnInit, OnDestroy {
    @Input('showReturnButton') showReturnButton = true;
    favoritenLieferant: Futtermittellieferant[];
    lieferantFehlt = this.stammdatenService.getLieferantFehlt();
    watchLieferantFehltSub: Subscription;
    lieferantFavoritenSubscription: Subscription;

    betriebe: Client[] = [];

    constructor(
        private stammdatenService: StammdatenService,
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute,
        private appService: AppService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.ladeBetriebe();
        this.ladeFavoriten();

        this.watchLieferantFehltSub = this.stammdatenService
            .watchEntsorgerFehlt()
            .subscribe((fehlt) => {
                this.lieferantFehlt = fehlt;
            });
    }

    ngOnDestroy(): void {
        this.watchLieferantFehltSub?.unsubscribe();
        this.lieferantFavoritenSubscription?.unsubscribe();
    }

    ladeFavoriten(): void {
        this.lieferantFavoritenSubscription?.unsubscribe();
        this.lieferantFavoritenSubscription = this.stammdatenService.ladeFuttermittellieferantenFavoriten().subscribe({
            next: (data) => {
                this.favoritenLieferant = [];
                this.favoritenLieferant = data.records;
            },
            error: (err: any) => {
                this.messageService.errorMessage(
                    'Fehler beim Laden aller Lieferanten',
                    err
                );
            },
        });
    }

    editiereLieferant(futtermittellieferant: Futtermittellieferant): void {
        if (!this.appService.isMobileScreen) {
            const dialogRef = this.dialog.open(LieferantDetailsDialogComponent, {
                data: {
                    futtermittellieferant: futtermittellieferant
                }
            });
            dialogRef.afterClosed().subscribe({
                next: () => {
                    this.ladeFavoriten();
                }
            });

        } else {
            this.router
                .navigate(['../details', futtermittellieferant.futtermittellieferant_id], {
                    relativeTo: this.route,
                })
                .then();
        }
    }


    lieferantHinzufuegen() {
        if (!this.appService.isMobileScreen) {
            const dialogRef = this.dialog.open(LieferantDetailsDialogComponent);
            dialogRef.afterClosed().subscribe({
                next: () => {
                    this.ladeFavoriten();
                }
            });

        } else {
            this.router.navigate(['details'], {relativeTo: this.route}).then();
        }
    }

    sendMail(futtermittellieferant: Futtermittellieferant) {
        if (futtermittellieferant.email) {
            const htmlAnchorElement = document.createElement('a');
            htmlAnchorElement.style.display = 'none';
            htmlAnchorElement.href = 'mailto:' + futtermittellieferant.email;
            document.body.appendChild(htmlAnchorElement);
            htmlAnchorElement.click();
            // document.body.removeChild(htmlAnchorElement);
        }
    }

    lieferantLoeschen(futtermittellieferant: Futtermittellieferant) {
        if (futtermittellieferant) {
            this.api.post(this.apiBaseUrl + 'futtermittellieferant/favorit.php', futtermittellieferant).subscribe({
                next: response => {
                    this.messageService.infoMessage('Der Lieferant wurde erfolgreich entfernt!');
                    this.ladeFavoriten();
                },
                error: err => {
                    this.messageService.errorMessage('Fehler', err);
                }
            });
        }
    }

    changeFuttermittellieferant(betrieb: Client) {
        const isMobile = this.appService.isMobileScreen;
        if (betrieb?.futtermittellieferant_id) {
            this.updateBetriebFuttermittellieferant(betrieb, undefined);
        } else {

            this.dialog.open(LieferantDetailsDialogComponent, {}).afterClosed().subscribe({
                next: result => {
                    if (result) {
                        this.updateBetriebFuttermittellieferant(betrieb, result);
                    }
                }
            });
        }
    }

    private ladeBetriebe() {
        this.stammdatenService.ladeVvvos().subscribe({
            next: (response) => {
                this.betriebe = response.records;
            },
            error: err => {
                this.messageService.errorMessage('Fehler', err);
            }
        });
    }

    private updateBetriebFuttermittellieferant(betrieb: Client, futtermittellieferant: Futtermittellieferant) {
        const obj = {
            kunde_id: betrieb?.kunde_id,
            futtermittellieferant_id: futtermittellieferant?.futtermittellieferant_id
        };
        this.api.post(this.apiBaseUrl + 'futtermittellieferant/favorit.php', obj).subscribe({
            next: result => {
                this.ladeBetriebe();
            },
            error: err => {
                this.messageService.errorMessage('Fehler', err);
            }
        });
    }
}


