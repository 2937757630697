import { Component, OnInit, Inject } from '@angular/core';
import { StorageService } from '../../storage/storage.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { StoragePlace } from '../storage-place';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Storage } from '../../storage/storage';

@Component({
  selector: 'kiene-edit-storage-place-dialog',
  templateUrl: './edit-storage-place-dialog.component.html',
  styleUrls: ['./edit-storage-place-dialog.component.scss']
})
export class EditStoragePlaceDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EditStoragePlaceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storageService: StorageService) {
      this.storagePlace = data.storagePlace;
    }

  storagePlace = new StoragePlace();
  nameCtrl = new UntypedFormControl('', [Validators.required]);
  storageCtrl = new UntypedFormControl('', [Validators.required]);
  storages: Storage[] = [];

  ngOnInit() {
    this.loadStorages();
    this.nameCtrl.setValue(this.storagePlace.bezeichnung);
    this.storageCtrl.setValue(this.storagePlace.lager_id);
  }

  loadStorages() {
    this.storageService.getAll().subscribe(storages => {
      this.storages = storages.records;
    });
  }

  create() {
    this.storagePlace.bezeichnung = this.nameCtrl.value;
    this.storagePlace.lager_id = this.storageCtrl.value;
    this.dialogRef.close(this.storagePlace);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
