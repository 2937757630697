<h2 mat-dialog-title>E-Mail Konto für {{user.vorname}} {{user.nachname}}</h2>
<mat-dialog-content>
  <mat-form-field style="width: 100%;">
    <input type="text" matInput placeholder="E-Mail Adresse" [(ngModel)]="userAdmin.email_adresse">
  </mat-form-field>
  <mat-form-field style="width: 100%;">
    <input type="text" matInput placeholder="SMTP Server Adresse" [(ngModel)]="userAdmin.email_smtp">
  </mat-form-field>
  <mat-form-field style="width: 100%;">
    <input type="number" matInput placeholder="SMTP Server Port" [(ngModel)]="userAdmin.email_port">
  </mat-form-field>
  <mat-form-field style="width: 100%;">
    <input type="text" matInput placeholder="Benutzername" [(ngModel)]="userAdmin.email_nutzer">
  </mat-form-field>
  <mat-form-field style="width: 100%;">
    <input type="password" matInput placeholder="Passwort" [(ngModel)]="userAdmin.email_passwort">
  </mat-form-field>
  <mat-checkbox [(ngModel)]="emailKopie">Kopie an {{userAdmin.email_adresse}} senden?</mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
  <button mat-raised-button color="primary" (click)="save()">Speichern</button>
</mat-dialog-actions>