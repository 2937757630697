import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {KieneTableService} from '../kiene-table/kiene-table.service';
import {TabinfoService} from './tabinfo.service';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-tabinfo',
    templateUrl: './tabinfo.component.html',
    styleUrls: ['./tabinfo.component.css'],
})
export class TabinfoComponent implements OnInit, OnDestroy {
    @Input('icon') icon = '';

    @Input('urlpfad') urlpfad: string;

    @Input('sendeKundeId') sendeKundeId = false;
    @Input('sendeKundeIdOptional') sendeKundeIdOptional = false;

    @Input('ladeWennKunde') ladeWennKunde = false;

    info = '';

    reloadSubscription: Subscription;

    currentClientSubscription: Subscription;

    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneTableService,
        private tabinfoService: TabinfoService,
        private localStorageService: LocalStorageService
    ) {
    }

    ngOnDestroy(): void {
        if (this.reloadSubscription) {
            this.reloadSubscription.unsubscribe();
        }
        if (this.currentClientSubscription) {
            this.currentClientSubscription.unsubscribe();
        }
    }

    ngOnInit(): void {
        this.reloadSubscription = this.tabinfoService.watchReload(this.urlpfad).subscribe({
            next: (response) => {
                if (response) {
                    this.load();
                }
            },
        });
        this.currentClientSubscription = this.localStorageService
            .watchCurrentClient()
            .subscribe({
                next: (client) => {
                    this.load();
                },
            });

        this.load();
    }

    public reload() {
        this.load();
    }

    load(): void {
        if (!this.localStorageService.isCurrentUserKunde() || this.ladeWennKunde) {
            if (this.urlpfad) {
                let idFieldName = undefined;
                let id = undefined;
                if (this.sendeKundeId || this.sendeKundeIdOptional) {
                    if (
                        !this.localStorageService.getCurrentClient()?.kunde_id && !this.sendeKundeIdOptional
                    ) {
                        this.info = '';
                        return; // wenn kunde Id wichtig, muss diese auch gesetzt sein
                    }
                    if (this.localStorageService.getCurrentClient()?.kunde_id) {
                        idFieldName = 'kunde_id';
                        id = this.localStorageService.getCurrentClient()?.kunde_id?.toString()
                    }

                }

                this.api
                    .getOne(this.apiBaseUrl + this.urlpfad, idFieldName, id)
                    .subscribe({
                        next: (response) => {
                            this.info = response.info;
                        },
                        error: () => {
                            this.info = 'F';
                        },
                    });
            }
        }
    }
}
