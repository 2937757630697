import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TourenplanComponent} from '../components/tourenplan/tourenplan.component';
import {InformationBoardComponent} from './information-board/information-board.component';
import {FortbildungComponent} from './fortbildungen/fortbildung/fortbildung.component';
import {UrlaubskalenderComponent} from './urlaubskalender/urlaubskalender.component';
import {
    WochenendeUndFeiertagPlanungComponent
} from './wochenende-und-feiertag-planung/wochenende-und-feiertag-planung.component';
import {HomeComponent} from './home.component';
import {TierarztBestandComponent} from '../warehouse/tierarzt-bestand/tierarzt-bestand.component';

const routes: Routes = [
    {
        path: '', component: HomeComponent, children: [
            {path: 'tourenplan', component: TourenplanComponent},
            {path: 'infos-tourenplan', component: InformationBoardComponent},
            {path: 'mein-bestand', component: TierarztBestandComponent},
            {path: 'fortbildungen', component: FortbildungComponent},
            {path: 'urlaubskalender', component: UrlaubskalenderComponent},
            {path: 'wochenendplaner', component: WochenendeUndFeiertagPlanungComponent},
            {path: '**', redirectTo: 'tourenplan'},
        ]
    }

];

@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HomeRoutingModule {
}
