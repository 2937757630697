<script src="entsorger-kunden-nr-verwalten.component.ts"></script>
<div [style.padding]="dialog ? '0' : '0 24px 64px 24px'">

    <kiene-page-header *ngIf="!dialog" [ueberschrift]="'Entsorger Kundennr. '"></kiene-page-header>

    <div [style.padding-top]="dialog ? '12px' : '128px'" style=" width: 100%; text-align: center" *ngIf="isLoading">
        Lade Entsorger...
    </div>

    <div *ngIf="!isLoading && entsorger">

        <h3>{{entsorger.name}}</h3>

        <div *ngIf="entsorger.kundenNrVvvos?.length > 0">
            <h3>Kundennummern pro VVVO</h3>
            <div style="margin: 6px 0 12px 0">
                <mat-hint class="hinweis-text">
            <span>
                <strong>Hinweis:&nbsp;</strong>
                Bitte pro VVVO die Kundennr. für den Entsorger angegeben, sonst kann später keine Todmeldung getätigt werden.
            </span>
                </mat-hint>
            </div>

            <mat-card style="margin-bottom: 6px; padding: 12px" *ngFor="let zuordnung of entsorger.kundenNrVvvos">
                <h3 style="margin-block: 0">{{zuordnung.vvvo}}</h3>
                <div>
                    <mat-hint class="hinweis-text">{{zuordnung.betrieb}}</mat-hint>
                    <span *ngIf="zuordnung.betrieb_zusatz"> - </span>
                    <mat-hint class="hinweis-text">{{zuordnung.betrieb_zusatz}}</mat-hint>
                </div>
                <mat-form-field style="width: 100%" class="no-padding-margin-mat-form-field">
                    <input placeholder="Kundennummer" matInput [(ngModel)]="zuordnung.kunden_nr">
                </mat-form-field>
            </mat-card>
        </div>

        <div [style]="dialog ? 'position: sticky; bottom: 0; background-color: white; padding: 12px 0;' : ''"
             class="one-row">
            <button mat-flat-button color="accent" (click)="speichern()" style="width: 66%; text-align: center" [disabled]="isLoading">Speichern</button>
            <span class="spacer"></span>
            <ng-content select="[additional-actions]"></ng-content>
        </div>

    </div>

</div>
