import { HttpParams } from '@angular/common/http';
import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { Gruppe } from '../classes/gruppe';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../services/message-service/message.service';
import { AbgangListeComponent } from './abgang-liste/abgang-liste.component';
import { GruppeAbgangService } from './gruppe-abgang.service';
import { Tierliste } from '../../gruppe-tier/classes/tierliste';
import { GruppenService } from '../gruppen.service';
import { ConfirmDialogInput, ConfirmDialogResult } from '../../dialogs/confirm-dialog/confirm-dialog';
import { TierlistenauswahlDialogComponent } from './tierlistenauswahl-dialog/tierlistenauswahl-dialog.component';
import { EnterValueInput, EnterValueResponse } from '../../dialogs/enter-value-dialog/enter-value-response';
import { EnterValueDialogComponent } from '../../dialogs/enter-value-dialog/enter-value-dialog.component';
import { AusstallenService } from '../../../components/ausstallen/ausstallen.service';
import { GruppeTier } from '../../../components/gruppe-tier/classes/gruppe-tier';
import { AusstallenDialogComponent } from '../../ausstallen/ausstallen-dialog/ausstallen-dialog.component';
import { AusstallenDialogInput, AusstallenDialogResult } from '../../ausstallen/ausstallen-dialog/ausstallen-dialog';
import { TierlistenauswahlDialogInput } from './tierlistenauswahl-dialog/tierlistenauswahl-dialog';

@Component({
    selector: 'kiene-gruppe-abgang',
    templateUrl: './gruppe-abgang.component.html',
    styleUrls: ['./gruppe-abgang.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GruppeAbgangComponent implements OnInit, OnDestroy {
    @ViewChild('abgangListe') abgangListe?: AbgangListeComponent;
    @ViewChild('tierlisteSelect') tierlisteSelect: MatSelect;
    @ViewChild('tabgroup') tabGroup: MatTabGroup;
    @Input() gruppe: Gruppe;
    @Input() alsDialog = false;
    @Input() tierliste: Tierliste;
    @Output() geschlossen = new EventEmitter<Tierliste>();

    gruppe_id: number;
    tiere: GruppeTier[] = [];
    tierlisteId: string;
    tierlisten: Tierliste[] = [];
    ladeAbgaengeSub: Subscription;
    ladeTierlistenSub: Subscription;
    aktuelleTierlisteSubscription: Subscription;
    gruppeSubscription: Subscription;

    ladeTiereFuerGruppeSub: Subscription;

    isLoading = false;

    currentTabIndex = 0;

    constructor(
        private route: ActivatedRoute,
        private abgangService: GruppeAbgangService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        private gruppenService: GruppenService,
        private api: KieneBackendApiService,
        private dialog: MatDialog,
        private messageService: MessageService,
        private ausstallenService: AusstallenService
    ) {
        if (this.gruppe) {
            this.gruppe_id = this.gruppe.gruppe_id;
        } else {
            const id = this.route.snapshot.paramMap.get('gruppe_id');
            if (id) {
                this.gruppe_id = Number(id);
            }
        }
        console.log('gruppeAbgang: gruppe_id: ', this.gruppe_id);
        this.tierlisteId =
            this.route.snapshot.queryParamMap.get('tierliste_id');
    }

    ngOnDestroy(): void {
        this.aktuelleTierlisteSubscription?.unsubscribe();
        this.ladeAbgaengeSub?.unsubscribe();
        this.gruppeSubscription?.unsubscribe();
        this.ladeTierlistenSub?.unsubscribe();
    }

    ngOnInit(): void {
        if (this.tierliste) {
            if (this.tierliste.gruppe_id_abgang) {
                this.gruppe_id = this.tierliste.gruppe_id_abgang;
            }
            this.abgangService.setAbgangsliste(
                this.tierliste,
                true,
                true
            );
        }

        if (this.tierlisteId) {
            this.api
                .getByIdAlternative(
                    this.apiBaseUrl + 'tierliste/read_one_kundenportal.php',
                    'tierliste_id',
                    this.tierlisteId
                )
                .subscribe({
                    next: (response) => {
                        this.tierliste = response;
                        this.abgangService.setAbgangsliste(
                            this.tierliste,
                            false,
                            true
                        );
                    },
                    error: (err) => {
                        this.messageService.errorMessage('Fehler!', err);
                    },
                });
        }

        if (this.gruppe_id) {
            this.gruppeSubscription?.unsubscribe();
            this.gruppeSubscription = this.ladeGruppe(this.gruppe_id).subscribe(
                {
                    next: (gruppe) => {
                        if (gruppe) {
                            this.gruppe = gruppe;
                        }
                    },
                    error: (err) => {
                        this.messageService.errorMessage('Fehler!', err);
                    },
                }
            );
        }

        this.ladeTierlisten();

        this.abgangService.watchAbgangsliste().subscribe({
            next: (liste) => {
                this.tierliste = liste.tierliste;
                if (liste.reloadGruppenTiere) {
                    this.ladeTiere();
                }
                if (liste.reloadAbgangsliste) {
                    this.ladeAktuelleTierliste();
                }
            },
        });


        this.abgangService.watchAlleTiereHinzugefuegt().subscribe({
            next: (alleHinzugefuegt) => {
                if (alleHinzugefuegt) {
                    // Letzten Tab zum abschließen anzeigen
                    this.tabGroup.selectedIndex =
                        this.tabGroup._tabs.length - 1;
                }
            },
        });

        this.ladeTiere();
    }

    tabChanged(event: MatTabChangeEvent) {
        this.currentTabIndex = event.index;
    }

    ladeAktuelleTierliste() {
        if (!this.tierliste) {
            return;
        }
        const params = new HttpParams().set(
            'tierliste_id',
            this.tierliste?.tierliste_id.toString()
        );
        this.aktuelleTierlisteSubscription?.unsubscribe();
        this.aktuelleTierlisteSubscription = this.api
            .get(
                this.apiBaseUrl + 'tierliste/read_one_kundenportal.php',
                params
            )
            .subscribe({
                next: (response) => {
                    this.tierliste = response;
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }

    ladeGruppe(gruppeId: number): Observable<Gruppe> {
        if (!gruppeId) {
            return EMPTY;
        }
        const params = new HttpParams().set('gruppe_id', gruppeId?.toString());
        return this.api.get(
            this.apiBaseUrl + 'gruppe/read_one_kundenportal.php',
            params
        );
    }

    tierlistenComparator(l1: Tierliste, l2: Tierliste) {
        return l1?.tierliste_id === l2?.tierliste_id;
    }

    ladeTierlisten(liste?: Tierliste) {
        this.ladeTierlistenSub?.unsubscribe();
        this.ladeTierlistenSub = this.gruppenService
            .ladeTierlistenHistorie(this.gruppe_id)
            .subscribe({
                next: (response) => {
                    this.tierlisten = response.records;
                    if (this.tierlisteId || this.tierliste) {
                        return;
                    }
                    if (this.tierlisten.length === 1) {
                        this.tierliste = this.tierlisten[0];
                        this.abgangService.setAbgangsliste(
                            this.tierlisten[0],
                            true,
                            true
                        );
                    } else if (this.tierlisten.length < 1) {
                        this.abgangService.setAbgangsliste(
                            undefined,
                            true,
                            true
                        );
                    } else if (this.tierlisten.length > 1 && !liste) {
                        let alleListenGeschlossen = true;
                        for (const tl of this.tierlisten) {
                            if (tl.status_id === 161) {
                                alleListenGeschlossen = false;
                                break;
                            }
                        }
                        if (!alleListenGeschlossen) {
                            this.openTierlistenauswahlDialog();
                        }
                    } else if (liste) {
                        this.tierliste = this.tierlisten.find((tl) => {
                            return tl.tierliste_id === liste.tierliste_id;
                        });
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }

    neueTierlisteErstellen() {
        this.ausstallenService.ausstallen(this.gruppe, false, undefined);
    }

    aktuelleTierlisteEditieren() {
        const extern = !!(this.tierliste?.tierliste_extern > 0)
        const input = new AusstallenDialogInput(!extern, extern, { tierliste: this.tierliste });
        input.tierliste = this.tierliste;
        const dialogRef = this.dialog.open<AusstallenDialogComponent, AusstallenDialogInput, AusstallenDialogResult>(AusstallenDialogComponent, {
            data: input
        });
        dialogRef.afterClosed().subscribe({
            next: (result: AusstallenDialogResult) => {
                if (result?.tierliste) {
                    this.tierliste = result.tierliste;
                    this.ladeTierlisten(result.tierliste);
                    this.abgangService.setAbgangsliste(result.tierliste, true, true);
                }
            },
        });
    }

    aktuelleTierlisteLoeschen() {
        const input = new ConfirmDialogInput();
        input.headline = 'Liste löschen?';
        input.text = 'Wollen Sie die aktuelle Ausstallliste wirklich löschen?';
        input.okButton = 'Löschen';
        input.buttonColor = 'warn';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.api
                        .post(
                            this.apiBaseUrl +
                            'tierliste/delete_kundenportal.php',
                            this.tierliste,
                            undefined
                        )
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Die Ausstallliste wurde erfolgreich gelöscht!'
                                );
                                this.ladeTierlisten();
                                this.abgangService.setAbgangsliste(
                                    undefined,
                                    true,
                                    true
                                );
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    openTierlistenauswahlDialog() {
        if (this.tierlisten?.length === 0) {
            return;
        }
        const input = new TierlistenauswahlDialogInput();
        input.gruppe = this.gruppe;
        input.tierlisten = this.tierlisten;
        this.dialog.open<TierlistenauswahlDialogComponent, TierlistenauswahlDialogInput, Tierliste>(TierlistenauswahlDialogComponent, {
            maxWidth: '100vw',
            maxHeight: '80vh',
            width: '100%',
            data: input
        }).afterClosed().subscribe({
            next: (tierliste) => {
                if (tierliste) {
                    this.ladeTierlisten(tierliste);
                    this.tierliste = tierliste;
                    this.abgangService.setAbgangsliste(tierliste, true, true);
                }
            },
        });
    }

    aktuelleTierlisteWiederOeffnen() {
        if (this.tierliste) {
            this.tierliste.status_id = 161;
            this.api
                .post(
                    this.apiBaseUrl + 'tierliste/update_kundenportal.php',
                    this.tierliste,
                    null
                )
                .subscribe({
                    next: (response: Tierliste) => {
                        this.messageService.infoMessage(
                            'Liste erfolgreich geöffnet!'
                        );
                        this.ladeTierlisten(response);
                        this.abgangService.setAbgangsliste(response, false, false);
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Fehler beim Öffnen.',
                            err
                        );
                        this.ladeTierlisten(this.tierliste);
                    },
                });
        }
    }

    aktuelleTierlisteTeilen() {
        if (this.tierliste) {
            const input = new EnterValueInput();
            input.title = 'Tierliste freigeben?';
            input.text =
                'Die Email-Adresse des Benutzers angeben, für den der Zugriff auf diese Liste erlaubt werden soll.';
            input.okButton = 'freigeben';
            input.buttonColor = 'accent';
            input.addInputValue('Email Adresse', 'text');

            const dialogRef = this.dialog.open(EnterValueDialogComponent, {
                data: input,
                position: { top: '10px' },
            });
            dialogRef.afterClosed().subscribe({
                next: (res: EnterValueResponse) => {
                    if (res?.result === true) {
                        this.tierlisteTeilen(this.tierliste, res?.getValue(0));
                    }
                },
            });
        }
    }

    tierlisteTeilen(liste: Tierliste, email: string) {
        this.api
            .post(
                this.apiBaseUrl + 'tierliste/teilen.php',
                { tierliste_id: liste?.tierliste_id, email: email },
                null
            )
            .subscribe({
                next: () => {
                    this.messageService.infoMessage(
                        'Die Liste wurde erfolgreich freigegeben!'
                    );
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fehler bei Freigabe!',
                        err
                    );
                },
            });
    }

    ladeTiere() {
        if (this.gruppe_id) {
            let params = new HttpParams().set('abgaenge', '0');
            params = params.set('tot', '0');
            this.ladeTiereFuerGruppeSub?.unsubscribe();
            this.ladeTiereFuerGruppeSub = this.gruppenService
                .ladeGruppenTiere(this.gruppe_id, params)
                .subscribe({
                    next: (tiere) => {
                        this.isLoading = false;
                        this.tiere = tiere.records;
                    },
                    error: (err) => {
                        this.isLoading = false;
                        this.messageService.errorMessage('Fehler!', err);
                    },
                });
        }
    }

    alleTiereZuAbgangslisteHinzufuegen() {
        if (!this.tierliste || this.tiere.length === 0) {
            return;
        }
        this.isLoading = true;
        const obj = {
            tierliste_id: this.tierliste.tierliste_id,
            gruppen_tiere: this.tiere,
        };
        this.api
            .post(
                this.apiBaseUrl + 'tierliste/abgang_kundenportal.php',
                obj,
                undefined
            )
            .subscribe({
                next: (tierlisteAntwort: Tierliste) => {
                    this.messageService.infoMessage(
                        this.tiere.length +
                        ' Tiere wurden zur aktuellen Ausstallliste hinzugefügt.'
                    );
                    this.isLoading = false;
                    this.abgangService.setAbgangsliste(
                        tierlisteAntwort,
                        true,
                        true
                    );
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                    this.isLoading = false;
                },
            });
    }

    abgangslisteGeschlossen(event: Tierliste) {
        if (event) {
            this.geschlossen.emit(event);
            console.log(event)
        }
    }

    public close(): void {

    }
}
