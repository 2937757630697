import { Component, OnInit, Output, EventEmitter, Input, OnChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { CompanyGroup } from '../../administration/company-group/company-group';
import { startWith, switchMap, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CompanyGroupService } from '../../administration/company-group/company-group.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'kiene-company-group-autocomplete',
  templateUrl: './company-group-autocomplete.component.html',
  styleUrls: ['./company-group-autocomplete.component.scss']
})
export class CompanyGroupAutocompleteComponent implements OnInit, OnChanges {

  @ViewChild('companyGroupInput') companyGroupInput: MatInput;

  companyGroupCtrl = new UntypedFormControl();
  companyGroups: Observable<CompanyGroup[]>;

  @Output('selectedCompanyGroup') selectedCompanyGroup: EventEmitter<CompanyGroup> = new EventEmitter<CompanyGroup>();

  @Input('companyGroup') companyGroup = new CompanyGroup();

  constructor(private service: CompanyGroupService) { }

  ngOnInit() {

    this.autocompleteCompanyGroup()
  }

  ngOnChanges() {
    if (this.companyGroupCtrl && this.companyGroup.unternehmensgruppe_id) {
      this.companyGroupCtrl.setValue(this.companyGroup);
    }
  }

  autocompleteCompanyGroup() {

    this.companyGroups = this.companyGroupCtrl.valueChanges.pipe(
      startWith(''),
      switchMap(value => { return this.searchCompanyGroups(value) })
    );
  }

  searchCompanyGroups(value: string) {
    console.debug("Mat-Autocomplete Search for: " + value);
    return this.service.searchCompanyGroups(value).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(response => response.records)
    )
  }

  companyGroupMapper(cg: CompanyGroup) {
    if (cg) {
      return cg.bezeichnung;
    }
    return '';
  }

  companyGroupSelected(event: MatAutocompleteSelectedEvent) {
    console.debug("CompanyGroup selected: " + event.option.value.bezeichnung);
    let companyGroup = event.option.value;
    this.selectedCompanyGroup.emit(companyGroup);
    //this.clientFormCtrl.setValue("");
  }

  public setFocus() {
    this.companyGroupInput?.focus();
  }
}
