<h1 mat-dialog-title>{{ kunde?.bezeichnung }} {{ kunde?.zusatz }}</h1>
<div mat-dialog-content>
    <p><strong>abgegebene Artikel im Zeitraum von {{ data?.von | date: 'dd.MM.yyyy' }}
        bis {{ data?.bis | date: 'dd.MM.yyyy' }}</strong></p>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-progress-spinner *ngIf="isLoading" mode="indeterminate" diameter="48"></mat-progress-spinner>
        <span class="spacer"></span>
    </div>
    <div *ngIf="!isLoading" class="one-row">
        <mat-checkbox [formControl]="ergaenzerCtrl" style="margin-right: 24px;">Mit Erg&auml;nzern</mat-checkbox>
        <mat-checkbox [formControl]="rabattCtrl">Bereinigter Umsatz</mat-checkbox>
        <span class="spacer"></span>
        <button mat-icon-button color="accent" (click)="rueckverguetung()"
                matTooltip="R&uuml;ckverg&uuml;tung f&uuml;r den Kunden erstellen">
            <mat-icon>assignment_return</mat-icon>
        </button>
    </div>
    <table *ngIf="!isLoading" class="phito-table artikel-table">
        <thead>
        <tr>
            <th></th>
            <th>Artikel</th>
            <th>Menge</th>
            <th>Einzelpreis &bemptyv;</th>
            <th>Umsatz von Gesamt %</th>
            <th>Umsatz</th>
            <th>Wareneinsatz</th>
            <th>Wareneinsatz<br>Einzelpreis &bemptyv;</th>
            <th>Wareneinsatz %</th>
            <th>Rohertrag</th>
            <th>Rohertrag<br>Einzelpreis &bemptyv;</th>
            <th>Rohertrag %</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let ad of artikelData">
            <td></td>
            <td>{{ ad.artikel }}</td>
            <td>{{ ad.artikelmenge | number: '1.0-2' }}</td>
            <td>{{ ad.umsatz / ad.artikelmenge | number: '1.2-2' }}&euro;</td>
            <td>{{ ad.umsatz_prozent | number: '1.0-2' }}%</td>
            <td>{{ ad.umsatz | number: '1.2-2' }}&euro;</td>
            <td>{{ ad.wareneinsatz | number: '1.2-2' }}&euro;</td>
            <td>{{ ad.wareneinsatz / ad.artikelmenge | number: '1.2-2' }}&euro;</td>
            <td>{{ ad.wareneinsatz_prozent | number: '1.0-2' }}%</td>
            <td>{{ ad.rohertrag | number: '1.2-2' }}&euro;</td>
            <td>{{ ad.rohertrag / ad.artikelmenge | number: '1.2-2' }}&euro;</td>
            <td>{{ ad.rohertrag_prozent | number: '1.0-2' }}%</td>
        </tr>
        </tbody>
    </table>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close()">Schlie&szlig;en</button>
</div>
