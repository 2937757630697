import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

import {KieneSession} from './kiene-session';
import {SearchFilterValue} from './search-filter-value';
import {Client} from '../../components/client/classes/client';
import {Mandant} from '../../components/mandanten/classes/mandant';
import {KieneUtils} from '../../utils/KieneUtils';

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    currentClient: Client;
    currentClientVisible = false;
    currentKieneSession: KieneSession = null;
    private showShoppingCartSubject = new Subject<boolean>();
    private changeCurrentMandantSubject = new Subject<Mandant>();
    private numberOfShoppingCartItemsSubject = new Subject<number>();
    private currentUserSubject = new Subject<KieneSession>();
    private currentClientSubject = new Subject<Client>();
    private loggedInSubject = new Subject<boolean>();
    private showCurrentClientSubject = new Subject<boolean>();

    constructor() {
    }

    watchShowShoppingCart(): Observable<boolean> {
        return this.showShoppingCartSubject.asObservable();
    }

    watchChangeCurrentMandant(): Observable<Mandant> {
        return this.changeCurrentMandantSubject.asObservable();
    }

    setShoppingCartVisible(visible: boolean) {
        this.showShoppingCartSubject.next(visible);
    }

    setNumberOfShoppingCartItems(items: number) {
        this.numberOfShoppingCartItemsSubject.next(items);
    }

    watchNumberOfShoppingCartItems(): Observable<number> {
        return this.numberOfShoppingCartItemsSubject.asObservable();
    }

    watchCurrentUser(): Observable<KieneSession> {
        return this.currentUserSubject.asObservable();
    }

    setCurrentClient(client: Client) {
        this.currentClient = client;
        localStorage.setItem(
            'kieneCurrentClient',
            client ? JSON.stringify(client) : null
        );
        this.currentClientSubject.next(client);
    }

    watchCurrentClient(): Observable<Client> {
        return this.currentClientSubject.asObservable();
    }

    getCurrentClient(): Client {
        const str = localStorage.getItem('kieneCurrentClient');
        if (str && str !== 'undefined') {
            return JSON.parse(str);
        } else {
            return null;
        }
    }

    setCurrentMandant(mandant: Mandant, sender: string) {
        console.log(sender + ': setCurrentMandant: ', mandant.mandant_id);
        localStorage.setItem('currentMandant', JSON.stringify(mandant));
        this.changeCurrentMandantSubject.next(mandant);
    }

    getCurrentMandant(): Mandant {
        return <Mandant>JSON.parse(localStorage.getItem('currentMandant'));
    }

    removeKieneSession() {
        localStorage.removeItem('kieneSession');
        this.currentKieneSession = null;
    }

    setCurrentUser(kieneSession: KieneSession) {
        this.removeKieneSession();
        localStorage.setItem('kieneSession', JSON.stringify(kieneSession));
        this.currentKieneSession = kieneSession;
        this.currentUserSubject.next(kieneSession);
    }

    isLoggedIn(): boolean {
        return !!this.getCurrentUser();
    }

    watchLoggedIn(): Observable<boolean> {
        return this.loggedInSubject.asObservable();
    }

    setLoggedIn(loggedIn: boolean) {
        this.loggedInSubject.next(loggedIn);
    }

    getCurrentUser(): KieneSession {
        if (this.currentKieneSession) {
            return this.currentKieneSession;
        }
        this.currentKieneSession = JSON.parse(
            localStorage.getItem('kieneSession')
        );

        return this.currentKieneSession;
    }

    getSession(): KieneSession {
        return this.getCurrentUser();
    }

    currentUserCanSeeMenu(menu: string): boolean {
        const kieneSession = this.getCurrentUser();

        if (kieneSession == null) {
            return false;
        }

        if (kieneSession.admin === 1) {
            return true;
        }
        if (kieneSession.menue == null) {
            return false;
        }
        const splittedMenu: string[] = menu.split('/');

        return kieneSession.menue[splittedMenu[1]] != null;
    }

    public hasPermission(permissionId: number): boolean {
        return this.currentUserHasPermission(permissionId);
    }

    currentUserIsAdmin(): boolean {
        const kieneSession = this.getCurrentUser();

        if (kieneSession == null) {
            return false;
        }

        return kieneSession.admin > 0;
    }

    currentUserHasPermission(permissionId: number): boolean {
        const kieneSession = this.getCurrentUser();

        if (kieneSession == null) {
            return false;
        }

        if (kieneSession.admin === 1) {
            return true;
        }

        return kieneSession.benutzerrecht_ids.includes(permissionId);
    }

    currentUserHasPermissions(ids: number[]): boolean {
        // console.debug('Length of ids array: ' + ids.length);
        // console.debug('ids content: ' + ids.toString());

        if (!ids) {
            return false;
        }

        for (const id of ids) {
            if (!(typeof id === 'number')) {
                return false;
            }
        }

        if (ids.length === 0) {
            return false;
        }
        const kieneSession = this.getCurrentUser();
        if (!kieneSession) {
            return false;
        }

        if (kieneSession.admin === 1) {
            return true;
        }


        for (const id of ids) {
            if (kieneSession.benutzerrecht_ids?.includes(id)) {
                return true;
            }
        }

        return false;
    }

    isCurrentUserPhitoUser(): boolean {
        const kieneSession = this.getCurrentUser();
        if (kieneSession == null) {
            return false;
        }
        return kieneSession.email?.endsWith('@phito.de');
    }

    getSearchFilterValue(componentName: string): string {
        const searchFilterValues: SearchFilterValue[] = JSON.parse(
            localStorage.getItem('searchFilterValues')
        );

        if (searchFilterValues == null) {
            return '';
        }
        for (const v of searchFilterValues) {
            if (v.componentName === componentName) {
                return v.filterValue;
            }
        }

        return '';
    }

    setSearchFilterValue(componentName: string, filterValue: string) {
        const sfv = new SearchFilterValue(componentName, filterValue);

        let searchFilterValues: SearchFilterValue[] = JSON.parse(
            localStorage.getItem('searchFilterValues')
        );
        if (searchFilterValues == null) {
            searchFilterValues = [];
        }

        let found = false;
        for (const v of searchFilterValues) {
            if (v.componentName === componentName) {
                v.filterValue = filterValue;
                found = true;
            }
        }

        if (!found) {
            searchFilterValues.push(sfv);
        }

        localStorage.setItem(
            'searchFilterValues',
            JSON.stringify(searchFilterValues)
        );
    }

    setDebugToken(token: string) {
        if (this.getCurrentUser().admin) {
            this.removeDebugToken();
            localStorage.setItem('kieneDebugToken', token);
        }
    }

    getDebugToken() {
        if (this.getCurrentUser().admin) {
            return localStorage.getItem('kieneDebugToken');
        }
        return '';
    }

    removeDebugToken() {
        localStorage.removeItem('kieneDebugToken');
    }

    deleteBrowserSession(sender: string) {
        console.log('deleteBrowserSession: ' + sender);
        this.removeKieneSession();
        localStorage.removeItem('kieneCurrentClient');
        localStorage.removeItem('currentMandant');
        localStorage.removeItem('searchFilterValues');
        this.removeDebugToken();
    }

    isCurrentUserViehhaendler(): boolean {
        const u = this.getCurrentUser();
        if (!u || !u.benutzerrollen) {
            return false;
        }
        for (const rolle of u.benutzerrollen) {
            if (rolle?.viehhaendler > 0) {
                return true;
            }
        }
        return false;
    }

    isCurrentUserViehhaendlerAdmin(): boolean {
        const u = this.getCurrentUser();
        if (!u || !u.benutzerrollen) {
            return false;
        }
        for (const rolle of u.benutzerrollen) {
            if (rolle?.viehhaendler > 0 && rolle?.admin_der_rolle > 0) {
                return true;
            }
        }
        return false;
    }

    isCurrentUserSpediteur(): boolean {
        const u = this.getCurrentUser();
        if (!u || !u.benutzerrollen) {
            return false;
        }
        for (const rolle of u.benutzerrollen) {
            if (rolle?.spediteur > 0) {
                return true;
            }
        }
        return false;
    }

    isCurrentUserSchlachthof(): boolean {
        const u = this.getCurrentUser();
        if (!u || !u.benutzerrollen) {
            return false;
        }
        for (const rolle of u.benutzerrollen) {
            if (rolle?.schlachthof > 0) {
                return true;
            }
        }
        return false;
    }

    isCurrentUserKunde(): boolean {
        const u = this.getCurrentUser();
        if (!u || !u.benutzerrollen) {
            return false;
        }
        for (const rolle of u.benutzerrollen) {
            if (rolle?.kunde > 0) {
                return true;
            }
        }
        return false;
    }


    isCurrentUserKundeAdmin(): boolean {
        const u = this.getCurrentUser();
        if (!u || !u.benutzerrollen) {
            return false;
        }
        for (const rolle of u.benutzerrollen) {
            if (rolle?.kunde > 0 && rolle?.admin_der_rolle > 0) {
                return true;
            }
        }
        return false;
    }

    isCurrentUserSgs(): boolean {
        const u = this.getCurrentUser();
        if (!u || !u.benutzerrollen) {
            return false;
        }
        for (const rolle of u.benutzerrollen) {
            if (rolle?.sgs > 0) {
                return true;
            }
        }
        return false;
    }

    isCurrentUserVerwaltung(): boolean {
        const u = this.getCurrentUser();
        if (!u || !u.benutzerrollen) {
            return false;
        }
        if (u.benutzerrollen) {
            for (const rolle of u.benutzerrollen) {
                if (rolle?.verwaltung > 0) {
                    return true;
                }
            }
        }
        return false;
    }


    watchShowCurrentClient(): Observable<boolean> {
        return this.showCurrentClientSubject.asObservable();
    }


    setCurrentClientVisible(visible: boolean, log?: string) {
        if (log) {
            console.log('setCurrentClientVisible: ' + log);
        }
        this.currentClientVisible = visible;
        this.showCurrentClientSubject.next(visible);
    }

    isCurrentClientVisible() {
        return this.currentClientVisible;
    }


    currentUserIsVet(): boolean {
        return (
            this.getCurrentUser().tierarzt_id &&
            this.getCurrentUser().tierarzt_id >= 0
        );
    }

    currentUserCanSeeTab(menuTab: string): boolean {
        const kieneSession = this.getCurrentUser();

        if (kieneSession == null) {
            return false;
        }
        if (kieneSession.admin === 1) {
            return true;
        }

        const splittedMenu: string[] = menuTab.split('/');
        const tabName = splittedMenu[2];

        if (kieneSession?.menue && kieneSession?.menue[splittedMenu[1]]) {
            const tabList: string[] = kieneSession?.menue[splittedMenu[1]];
            return tabList && tabList.includes(tabName);

        }
        return false;
    }

    public hasAtLeastOnePermission(permissionIds: number[]): boolean {
        for (const p of permissionIds) {
            if (this.hasPermission(p)) {
                return true;
            }
        }
        return false;
    }

    public hasAllPermissions(permissionIds: number[]): boolean {
        for (const p of permissionIds) {
            if (!this.hasPermission(p)) {
                return false;
            }
        }
        return true;
    }

    public getStartseite(): string {
        if (this.isCurrentUserSchlachthof()) {
            return '/schlachthoefe';
        }
        if (this.isCurrentUserSpediteur()) {
            return '/spediteur';
        }
        // Kunde
        if (this.isCurrentUserKunde()) {
            return '/gruppen';
        }
        if (this.isCurrentUserSgs()) {
            return '/gruppen';
        }
        if (this.isCurrentUserViehhaendler()) {
            return '/gruppen';
        }
        if (this.getSession().menue.startseite) {
            return '/startseite';
        }
        if (this.getSession().menue.belege) {
            return '/belege';
        }
        if (this.getSession().menue.labor) {
            return '/labor';
        }
        if (this.getSession().menue.lager) {
            return '/lager';
        }
        return '';
    }

    public kostenpflichtigAktiv(): boolean {

        const heute = new Date();

        if (!this.isCurrentUserKunde() && !this.isCurrentUserViehhaendler()) {
            return false;
        } else {
            if (this.getCurrentUser().kostenpflichtig_ab) {
                const kostenpflichtig_ab = new Date(this.getCurrentUser().kostenpflichtig_ab);
                if (kostenpflichtig_ab.getTime() > heute.getTime()) {
                    return false;
                }
            } else {
                return false;
            }
        }
        return true;
    }

    isCurrentUserVeterinarian() {
        return KieneUtils.isNNoU(this.getCurrentUser().tierarzt_id);
    }
}
