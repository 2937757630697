import {Component, OnInit} from '@angular/core';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {Router} from '@angular/router';

@Component({
    selector: 'kiene-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

    constructor(private localStorageService: LocalStorageService,
                public router: Router) {
    }

    ngOnInit(): void {
    }

    isTabMenuVisible(path: string) {

        return this.localStorageService.currentUserCanSeeTab(path);
    }

}
