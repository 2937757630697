import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {KieneApi} from '../../components/kiene-table/kiene-api';
import {Client, ClientApi} from '../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {ClientAdmin} from '../../../../projects/kiene-core/src/lib/components/client/classes/client-admin';

@Injectable()
export class ClientService {
    constructor(
        private http: HttpClient,
        public dialog: MatDialog,
        private localStorageService: LocalStorageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    getAllClients(
        sortColumn: string,
        sortDirection: string,
        offset: number,
        limit: number,
        searchString: string,
        showNewClients: boolean
    ): Observable<ClientApi> {
        let params = new HttpParams();

        if (sortColumn) {
            let columnAndDirection = sortColumn + ' asc';
            if (sortDirection) {
                columnAndDirection = sortColumn + ' ' + sortDirection;
            }

            params = params.append('orderby', columnAndDirection);
        }

        if (offset) {
            params = params.append('offset', offset.toString());
        }

        if (limit) {
            params = params.append('limit', limit.toString());
        }

        if (searchString) {
            params = params.append('search', searchString);
        }

        if (showNewClients) {
            params = params.append('neukunde', '1');
        }

        return this.http.get<ClientApi>(
            this.apiBaseUrl + 'kunde/read.php',
            {params: params}
        );
    }

    getClientAdmin(id: string): Observable<ClientAdmin> {
        return this.http.get<ClientAdmin>(
            this.apiBaseUrl + 'kunde_mandant_admin/read_one.php?kunde_id=' + id
        );
    }

    getClient(id: string): Observable<Client> {
        return this.http.get<Client>(
            this.apiBaseUrl + 'kunde/read_one.php?kunde_id=' + id
        );
    }

    getClientById(id: number): Observable<Client> {
        return this.http.get<Client>(
            this.apiBaseUrl + 'kunde/read_one.php?kunde_id=' + id
        );
    }

    getClientsByCompanyGroup(companyGroupId: number): Observable<ClientApi> {
        return this.http.get<ClientApi>(
            this.apiBaseUrl +
            'kunde/read.php?unternehmensgruppe_id=' +
            companyGroupId
        );
    }

    getClientFromHIT(vvvo: string) {
        const params = new HttpParams().append('vvvo', vvvo);
        return this.http.get<Client>(
            this.apiBaseUrl + '_qs_hit/hit_kunden.php',
            {params: params}
        );
    }

    searchClients(
        searchString: string,
        options?: { onlyActiveClients?: boolean }
    ): Observable<ClientApi> {
        let param = '';
        if (options?.onlyActiveClients === true) {
                param = '&aktiv=1';
            }        
        return this.http.get<ClientApi>(
            this.apiBaseUrl +
            'kunde/read.php?limit=25&offset=0&search=' +
            searchString +
            param
        );
    }

    saveClient(client: Client): Observable<Client> {
        return this.http.post<Client>(
            this.apiBaseUrl + 'kunde/create.php',
            client
        );
    }

    updateClientAdmin(clientAdmin: ClientAdmin): Observable<ClientAdmin> {
        return this.http.post<ClientAdmin>(
            this.apiBaseUrl + 'kunde_mandant_admin/update.php',
            clientAdmin
        );
    }

    updateClient(client: Client): Observable<Client> {
        return this.http.post<Client>(
            this.apiBaseUrl + 'kunde/update.php',
            client
        );
    }

    deleteClient(client: Client) {
        return this.http.post(
            this.apiBaseUrl + 'kunde/delete.php',
            client
        );
    }

    getMandantenForClient(client: Client) {
        if (!client) {
            return undefined;
        }
        const params = new HttpParams().set('kunde_id', client.kunde_id);
        return this.http.get<KieneApi>(this.apiBaseUrl + 'kunde_mandant/read.php', {params: params});
    }
}
