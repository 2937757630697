<h1 mat-dialog-title>Zugänge an HIT melden</h1>
<div mat-dialog-content style="padding-bottom: 12px">
    <div>
        <span style="text-align: justify"
        >Welche Tiere sollen an HIT gemeldet werden?</span
        >
    </div>

    <div style="margin: 12px 0">
        <mat-hint class="hinweis-text" style="font-size: 0.8em">
            <strong>Hinweis:</strong> Zehnstellige Ohrmarken werden mit
            &quot;DE&quot; bzw. &quot;27600&quot; ergänzt.
        </mat-hint>
    </div>

    <div>
        <mat-checkbox
            [disabled]="anzahl_pass_ohrmarke <= 0 || isLoading"
            [(ngModel)]="pass_und_ohrmarke"
        >Ohrmarke und Pass gescannt ({{
            anzahl_pass_ohrmarke
            }}
            Stk.)
        </mat-checkbox
        >
    </div>

    <div>
        <mat-checkbox
            [disabled]="anzahl_pass <= 0 || isLoading"
            [(ngModel)]="nur_pass_gescannt"
        >nur Pass gescannt ({{ anzahl_pass }} Stk.)
        </mat-checkbox
        >
    </div>

    <div>
        <mat-checkbox
            [disabled]="anzahl_ohrmarke <= 0 || isLoading"
            [(ngModel)]="nur_ohrmarke_gescannt"
        >nur Ohrmarke gescannt ({{ anzahl_ohrmarke }} Stk.)
        </mat-checkbox
        >
    </div>

    <div>
        <mat-checkbox
            [disabled]="anzahl_csv <= 0 || isLoading"
            [(ngModel)]="weder_pass_noch_ohrmarke">nur CSV-Import ({{ anzahl_csv }} Stk.)
        </mat-checkbox>
    </div>

    <div *ngIf="nicht_meldbar" class="one-row" style="margin-top: 12px; font-size: .9em;">
        <mat-icon color="warn">warning</mat-icon>
        <span>Tiere ohne Einstalldatum: {{ nicht_meldbar }}</span>
        <span class="spacer"></span>
        <button mat-stroked-button color="warn" (click)="tiereOhneEinstalldatumAnzeigen()">anzeigen</button>
        <span class="spacer"></span>
    </div>
</div>
<div mat-dialog-actions>
    <button
        mat-flat-button
        color="accent"
        [disabled]="isLoading"
        (click)="save()"
    >
        Tier(e) melden
    </button>
    <button mat-button (click)="close()">Abbrechen</button>
</div>
