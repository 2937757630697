<h1 mat-dialog-title>Datei Upload</h1>
<div mat-dialog-content>
    <p>Datei Upload f&uuml;r folgende Bestellung(en):</p>
    <ul style="list-style-type: none;">
        <li *ngFor="let id of ids">#{{id}}</li>
    </ul>

    <div style="margin-bottom: 12px;">
        <div *ngFor="let f of filesToUpload">
            <p>{{f.name}}: {{filesProgressValues[f.name]}}%</p>
            <mat-progress-bar mode="determinate" [value]="filesProgressValues[f.name]"></mat-progress-bar>
        </div>
    </div>

    <div style="width: 100%; text-align: center;">
        <mat-form-field>
            <mat-label>Rechnungsdatum</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="datumCtrl">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <button style="padding-left: 24px; padding-right: 24px; margin-right: 12px;" mat-raised-button color="primary"
                (click)="openFileSelector()">Dateien ausw&auml;hlen
        </button>
        <button style="padding-left: 24px; padding-right: 24px;" mat-raised-button color="primary"
                (click)="uploadFiles()"
                [disabled]="filesToUpload.length < 1">
            <mat-icon>cloud_upload</mat-icon>
            Dateien hochladen
        </button>
        <input type="file" style="display: none" #fileSelector multiple (change)="handleFiles($event)"
               accept="application/pdf">
    </div>

</div>
<div mat-dialog-actions>
    <button mat-flat-button color="accent" (click)="close()">Schlie&szlig;en</button>
</div>
