export class Material {
    artikel_id: number;
    bezeichnung: string;
    kuerzel: string;
    artikel_nr_intern: string;
    artikel_me: string;
    ean: string;
    produktkennzeichnungen: string;
    bga: string;
    menge: number;
    gebindegroesse: number;
    einheit: string;
    einheit_id: number;
    einheit_alternativ: string;
    einzelpreis: number;
    preiseinheit: number;
    impfung: number;
    behandlung: number;
    abgabe: number;
    abgabeundbehandlung: number;
    anwendungsdauer_fix: number;
    dosierung_fix: number;
    bestandsbesuchsprotokoll: number;
    massnahmenplan_schwein: number;
    massnahmenplan_rind: number;
    massnahmenplan_rind_kurz: number;
    tierwohlprotokoll_schwein: number;
    tierwohlprotokoll_rind: number;
    verpackungsmenge: number;
    antibiotikum: number;
    mindestbestand: number;
    sicherheitsbestand: number;
    sicherheitsbestand_variabel: number;
    lieferant_id: number;
    lieferant: string;
    apotheke: number;
    lager: number;
    wagen: number;
    ausgang: number;
    summe: number;
    bezeichnung_befund: string;
    bestellmenge: number;
    bestellmenge_konstant: number;
    bestellvorschlag: number;
    umrechnungsfaktor: number;
    ermittlung_rohertrag: string;
    laborartikel: number;
    mengenartikel: number;
    charge: number;
    sonstige_abgabe: number;
    betaeubungsmittel: number;
    nicht_abgebbar: number;
    provisionsfaehig: number;
    provision: number;
    rabattfaehig: number;
    bonusfaehig: number;
    skontofaehig: number;
    bestellwesen: number;
    kein_negativbestand: number;
    lagerbestaende_verbuchen: number;
    kein_liefervorschlag: number;
    keine_barcodeerfassung: number;
    sammelartikel: number;
    interne_verrechnung: number;
    vet_sort: any;
    tsk_abkuerzung: string;
    tsk_preis: number;
    tsk: number;
    impftext: string;
    // impfgebuehr_0_24: number;
    // impfgebuehr_25_49: number;
    // impfgebuehr_50: number;
    // wartezeit: number;
    warengruppe_id: number;
    warengruppe: string;
    gesperrt: number;
    lieferant_id_labor: number;
    lieferant_labor: string;
    untersuchungsgruppe_id: number;
    untersuchungsgruppe: string;
    untersuchungen_anzahl: number;
    ust: number;
    mandanten_ids: number[] = [];
    warengruppe_labor: number;
    warengruppe_tierarzt: number;
    stueckliste: number;
    wirkstoff_id: number;
    wirkstoff: string;
    artikelmenge_ausblenden: number;
    in_abgangsliste: number;
    honorar: number;
    primanota_ignorieren: number;

    // impfgebuehr_0_24: number;
    // impfgebuehr_25_49: number;
    // impfgebuehr_50: number;
    wartezeit: number;
    idexx: number;
    idexx_messgroesse: string;
    idexx_pdf_beschreibung: string;
    idexx_pdf_tabellen_text: string;
    tourenplan: number;
    tourenplan_tage: number;
    packungs_id: number;
    eingangsnummer: number;
    upd: string;
}

export class Wirkstoff {
    wirkstoff_id: number;
    wirkstoff: string;
}

export interface MaterialApi {
    records: Material[];
    count: number;
}

export class Einzelpreis {
    einzelpreis: number;
}

export class ArtikelAdmin {
    artikel_id: number;
    ust: number;
    buchungskonto: string;
    buchungsgegenkonto: string;
    mandanten_ids: number[] = [];
    stueckliste_einkaufspreis: number;
}

export class ArtikelStueckliste {
    as_id: number;
    artikel_id_liste: number;
    artikel_liste: string;
    artikel_id_pos: number;
    artikel_pos: string;
    behandlung_artikel: number;
    abgabe_artikel: number;
    anwendung_id: number;
    anwendung: string;
    anwendungsdauer: number;
    behandlung: number;
    indikation_id: number;
    diagnose: string;
    artikelmenge: number;
    mengenartikel: number;
    tierart_id: number;
    tierart: string;
    lieferant: string;
    einzelpreis: number;
    wartezeit: number;
    dosierung: number;
    rabatt: number;
    erstellt: Date;
    erstellt_von: number;
    editiert: Date;
    editiert_von: number;
    mandanten_ids: number[] = [];
}

export class ArtikelMedikation {
    am_id: number;
    artikel_id: number;
    artikel_me: string;
    tierart_id: number;
    tierart: string;
    anwendung_id: number;
    anwendung: string;
    indikation_id: number;
    diagnose: string;
    wartezeit_fleisch: number;
    wartezeit_milch: number;
    wartezeit_eier: number;
    kommentar: string;
    menge: number;
    einheit_id: number;
    einheit: string;
    behandlung_tage: number;
    faktor: number;
    intervall_std: number;
    koerpergewicht: number;
    dosierung_txt: string;
    editiert: Date;
}
