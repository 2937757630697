import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kiene-google-maps-route',
  templateUrl: './google-maps-route.component.html',
  styleUrls: ['./google-maps-route.component.scss']
})
export class GoogleMapsRouteComponent implements OnInit {

  @Input('strasse') strasse: string;
  @Input('plz') plz: string;
  @Input('ort') ort: string;

  link = '';

  constructor() { }

  ngOnInit() {
    this.createLink();
  }

  createLink() {
    const prefix = 'https://www.google.de/maps/dir//';
    this.link = prefix + this.strasse + ',' + this.plz + ',' + this.ort;
  }


}
