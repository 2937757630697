<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
    <div class="one-row">
        <span class="spacer"></span>
        <!--        <mat-card-->
        <!--            *ngFor="let m of mandanten; let i = index"-->
        <!--            style="text-align: center; margin: 12px; min-width: 150px; min-height: 150px; width: fit-content; max-width: 150px; cursor: pointer;"-->
        <!--            (click)="setMandant(m)"-->
        <!--        >-->
        <!--            <div style="width: 100%;">-->
        <!--                <mat-icon color="accent" style="transform: scale(2);"-->
        <!--                >business-->
        <!--                </mat-icon>-->
        <!--            </div>-->
        <!--            <p style="font-weight: bold;">-->
        <!--                {{ m.bezeichnung }} ({{ i + 1 }})-->
        <!--            </p>-->
        <!--        </mat-card>-->
        <ng-container *ngFor="let m of mandanten; let i = index;">
            <div (click)="setMandant(m)"
                 style="" class="mandant-button">

                <mat-icon color="accent" style="transform: scale(2.4)">house</mat-icon>

                <p style="margin-top: 36px;">{{ m.bezeichnung }} ({{ i + 1 }})</p>
            </div>
            <span class="spacer"></span>
        </ng-container>
    </div>
</div>
