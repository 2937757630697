<mat-form-field *ngIf="!hideOnEmptyList || this.einstalldaten.length > 0 || this.isLoading"
                style="width: 100%; margin: 6px 0 0 0">
    <mat-icon matPrefix style="padding-right: 6px">search</mat-icon>
    <mat-label>{{label}}</mat-label>
    <mat-select [compareWith]="compareGruppeTierEinstalldatum" [formControl]="selectedEinstalldatumCtrl" multiple>
        <mat-option *ngFor="let datum of einstalldaten"
                    [value]="datum">{{datum.einstalldatum | date: 'dd.MM.yyyy'}} ({{datum.anzahl}}
            Tiere)
        </mat-option>
    </mat-select>
    <button (click)="resetCtrl($event)" *ngIf="selectedEinstalldatumCtrl.value && einstalldaten.length > 0"
            aria-label="Clear"
            mat-icon-button
            matSuffix>
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
