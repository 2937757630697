import { Gruppe, GruppenStatistik } from '../../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import { GruppenVerlauf } from '../../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppen-verlauf';
import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GruppeInformationenDialogComponent } from '../../../../../../src/app/gruppen/gruppe-informationen-dialog/gruppe-informationen-dialog.component';

@Component({
  selector: 'kiene-details-verlauf-statisiken',
  templateUrl: './details-verlauf-statisiken.component.html',
  styleUrls: ['./details-verlauf-statisiken.component.scss']
})
export class DetailsVerlaufStatisikenComponent implements OnInit {

  gruppenVerlauf: GruppenVerlauf[];
  gruppenVerlaufSubscription: Subscription;
  gruppenVerlaufReloadSubscription: Subscription;

  gruppenStatistik: GruppenStatistik;
  gruppenStatistikSubscription: Subscription;
  verlaufIsLoading = true;

  @Input() gruppe: Gruppe;

  constructor(
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    console.log("gruppe",this.gruppe);
  }

  onPropertyChanged(changes: any) {
    console.log("something changed",changes);
  }

  openGruppeInformationenDialog() {
    const dialofRef = this.dialog.open(GruppeInformationenDialogComponent, {
        width: '50vw',
        data: {
            gruppe_id: this.gruppe.gruppe_id,
        },
    });

    dialofRef.afterClosed().subscribe({
        next: () => {
            //this.loadGruppe();
            //TODO fix this updating the gruppe
        }
    });
  }

  statistikOpenable() {
    return (
        this.gruppenStatistik &&
        (this.gruppenStatistik?.rassen.length > 0 ||
            this.gruppenStatistik?.geschlechter.length > 0 ||
            this.gruppenStatistik?.abteile.length > 0 ||
            this.gruppenStatistik?.reklamationen.length > 0)
    );
  }

  /**
   * s1 - s2 ==> (+/- {result})
   * @param s1
   * @param s2
   * @param options - asPercentage: boolean
   */
  getDifferenceString(
    s1: number | undefined,
    s2: number | undefined,
    options?: { asPercentage: boolean }
  ): string {
    if (s1 && s2) {
        let erg: number;
        if (options?.asPercentage) {
            erg = Number(((s1 / s2) * 100).toFixed(1));
        } else {
            erg = s1 - s2;
        }
        if (erg > 0) {
            return `(${options?.asPercentage ? '' : '+'}${erg.toString()}${options?.asPercentage ? '%' : ''
                })`;
        } else if (erg < 0) {
            return `(${erg.toString()}${options?.asPercentage ? '%' : ''})`;
        } else {
            return '';
        }
    }
    return '';
  }

  verlaufOpenable() {
      return this.gruppenVerlauf && this.gruppenVerlauf?.length > 0;
  }

  gibAnzahlStatistiken() {
    const l1 = this.gruppenStatistik?.geschlechter?.length;
    const l2 = this.gruppenStatistik?.reklamationen?.length;
    const l3 = this.gruppenStatistik?.rassen?.length;
    const l4 = this.gruppenStatistik?.abteile?.length;
    return (l1 ? l1 : 0) + (l2 ? l2 : 0) + (l3 ? l3 : 0) + (l4 ? l4 : 0);
  }
}
