import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kiene-text-input-dialog',
  templateUrl: './text-input-dialog.component.html',
  styleUrls: ['./text-input-dialog.component.css']
})
export class TextInputDialogComponent implements OnInit {

  title: string;
  text: string;
  textLabel: string;
  textButton: string = 'Speichern';
  inputCtrl = new UntypedFormControl();

  constructor(public dialogRef: MatDialogRef<TextInputDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.title = data.title;
    this.text = data.text;
    this.textLabel = data.textLabel;
    if(data.textButton){
      this.textButton = data.textButton;
    }
  }

  ngOnInit() {
  }

  save() {
    this.dialogRef.close(this.inputCtrl.value);
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

}
