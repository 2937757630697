import { ErrorHandler, Inject, Injectable } from '@angular/core';
import {
    KieneBackendApiService
} from '../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { environment } from '../../environments/environment';

@Injectable()
export class KieneErrorsHandler implements ErrorHandler {
    constructor(
        private api: KieneBackendApiService) {
    }

    handleError(error: Error): void {
        this.api.handleException(
            environment.production,
            error
        );
    }
}
