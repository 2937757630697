import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ArtikelAdmin, Einzelpreis, Material, MaterialApi } from './material';
import { Observable } from 'rxjs';
import { Supplier } from '../supplier/supplier';
import { Unit, UnitApi } from './unit';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable()
export class MaterialService {
    constructor(private http: HttpClient,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    public getOne(id: number): Observable<Material> {
        return this.http.get<Material>(
            this.apiBaseUrl + 'artikel/read_one.php?artikel_id=' + id
        );
    }

    public getAllTreatmentMaterials(
        sortColumn: string,
        sortDirection: string,
        offset: number,
        limit: number,
        searchString: string
    ): Observable<MaterialApi> {
        return this.http.get<MaterialApi>(
            this.apiBaseUrl +
            'behandlungsartikel/read.php?&orderby=' +
            sortColumn +
            ' ' +
            sortDirection +
            '&limit=' +
            limit +
            '&offset=' +
            offset +
            '&search=' +
            searchString
        );
    }

    public updateTreatmentMaterial(material: Material): Observable<Material> {
        return this.http.post<Material>(
            this.apiBaseUrl + 'behandlungsartikel/update.php',
            material
        );
    }

    public saveMaterial(material: Material): Observable<Material> {
        return this.http.post<Material>(
            this.apiBaseUrl + 'artikel/update_allgemein.php',
            material
        );
    }

    public updateMaterial(material: Material): Observable<Material> {
        return this.http.post<Material>(
            this.apiBaseUrl + 'artikel/update.php',
            material
        );
    }

    public searchMaterial(searchString: string): Observable<MaterialApi> {
        return this.http.get<MaterialApi>(
            this.apiBaseUrl +
            'artikel/read.php?search=' +
            searchString +
            '&limit=10'
        );
    }

    public searchSupplierMaterial(
        searchString: string,
        supplier?: Supplier
    ): Observable<MaterialApi> {
        let params: HttpParams = new HttpParams()
            .set('search', searchString)
            .set('limit', '10');

        if (supplier) {
            params = params.append(
                'lieferant_id',
                supplier.lieferant_id.toString()
            );
        }

        return this.http.get<MaterialApi>(
            this.apiBaseUrl + 'artikel/read_mit_lieferant.php',
            { params: params }
        );
    }

    public searchAutocompleteMaterial(
        searchString: string,
        gesperrtAusblenden: boolean,
        kundeId: number,
        labor: boolean,
        params: HttpParams,
        kundeMandantId: number,
        ladePreise: boolean,
        nurAbgegebeneArtikel: boolean,
        stuecklisten: boolean
    ): Observable<MaterialApi> {
        if (!params) {
            params = new HttpParams();
        }
        if (gesperrtAusblenden) {
            params = params.append('gesperrt', '0'); // Zeige alle, wo gesperrt 0 ist.
        }
        params = params.append('search', searchString);
        params = params.append('limit', '20');
        params = params.append('orderby', 'artikel_me asc');
        if (kundeId) {
            params = params.append('kunde_id', kundeId.toString());
        }
        if (stuecklisten === false) {
            params = params.append('stueckliste', '0');
        }

        params = params.set('preise', ladePreise ? '1' : '0');
        params = params.set('abgegeben', nurAbgegebeneArtikel ? '1' : '0');

        if (labor) {
            params = params.set('laborartikel', '1');
        }

        let headers;
        if (kundeMandantId !== undefined) {
            headers = { 'MANDANTID-KUNDE': kundeMandantId.toString() };
        }

        return this.http.get<MaterialApi>(
            this.apiBaseUrl + 'artikel_autocomplete/read.php',
            { params: params, headers: headers }
        );
    }

    public getMaterialByEAN(ean: string): Observable<MaterialApi> {
        return this.http.get<MaterialApi>(
            this.apiBaseUrl + 'artikel_autocomplete/read.php?ean=' + ean
        );
    }

    public getUnits(): Observable<UnitApi> {
        return this.http.get<UnitApi>(
            this.apiBaseUrl + 'einheit/read.php'
        );
    }

    public getUnitForMaterial(material: Material): Observable<Unit> {
        return this.http.get<Unit>(
            this.apiBaseUrl +
            'einheit/read_one.php?einheit_id' +
            material.einheit_id
        );
    }

    public loadAdminMaterial(id: number): Observable<ArtikelAdmin> {
        return this.http.get<ArtikelAdmin>(
            this.apiBaseUrl +
            'artikel_admin/read_one.php?artikel_id=' +
            id
        );
    }

    public saveAdminMaterial(aa: ArtikelAdmin): Observable<ArtikelAdmin> {
        return this.http.post<ArtikelAdmin>(
            this.apiBaseUrl + 'artikel_admin/update.php',
            aa
        );
    }

    public getPriceForClient(
        materialId: number,
        menge: number,
        clientId: number
    ): Observable<Einzelpreis> {
        return this.http.get<Einzelpreis>(
            this.apiBaseUrl +
            'artikel/read_preis.php?artikel_id=' +
            materialId +
            '&artikelmenge=' +
            menge +
            '&kunde_id=' +
            clientId
        );
    }
}
