import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {StammdatenService} from '../../stammdaten.service';
import {MessageService} from '../../../../services/message-service/message.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AppService} from '../../../../services/app/app.service';
import {MatDialog} from '@angular/material/dialog';
import {KieneBackendApiService} from '../../../../services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../kiene-core.config';
import {Client} from '../../../client/classes/client';
import {Benutzerprofil} from '../../mein-profil/mein-profil';

@Component({
    selector: 'kiene-haendler-favoriten',
    templateUrl: './haendler-uebersicht.component.html',
    styleUrls: ['./haendler-uebersicht.component.scss'],
})
export class HaendlerUebersichtComponent implements OnInit, OnDestroy {
    favoritenHaendler: Client[];
    haendlerFehlt = this.stammdatenService.getHaendlerFehlt();
    watchHaendlerFehltSub: Subscription;
    haendlerFavoritenSubscription: Subscription;
    @Input('showReturnButton') showReturnButton = true;

    benutzerprofil = new Benutzerprofil();

    constructor(
        private stammdatenService: StammdatenService,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private appService: AppService,
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
    ) {
    }

    ngOnInit(): void {
        this.ladeBenutzerprofil();
        this.ladeFavoriten();

        this.watchHaendlerFehltSub = this.stammdatenService
            .watchHaendlerFehlt()
            .subscribe((fehlt) => {
                this.haendlerFehlt = fehlt;
            });
    }

    ngOnDestroy(): void {
        this.watchHaendlerFehltSub?.unsubscribe();
        this.haendlerFavoritenSubscription?.unsubscribe();
    }

    ladeFavoriten(): void {
        this.haendlerFavoritenSubscription?.unsubscribe();
        this.haendlerFavoritenSubscription = this.api.get(this.apiBaseUrl + 'kunde/read_viehhaendler.php').subscribe({
            next: (data) => {
                this.favoritenHaendler = [];
                this.favoritenHaendler = data.records;
            },
            error: (err: any) => {
                this.messageService.errorMessage(
                    'Fehler beim Laden aller Händler',
                    err
                );
            },
        });
    }

    // public updateFavoriten(updatedHaendler: Client) {
    //     this.messageService.infoMessage('Funktion inaktiv!');
    //     /*
    //     if (updatedHaendler.favorit === 1) {
    //         this.favoritenHaendler.push(updatedHaendler);
    //     } else {
    //         this.favoritenHaendler = this.favoritenHaendler.filter(
    //             (entsorger) =>
    //                 entsorger.viehhaendler_id !==
    //                 updatedHaendler.viehhaendler_id
    //         );
    //     }
    //         */
    // }

    // editiereHaendler(haendler: Client): void {
    //     this.messageService.infoMessage('Funktion inaktiv!');
    //     /*
    //     if (!this.appService.isMobileScreen) {
    //         const dialogRef = this.dialog.open(HaendlerDetailsDialogComponent, {
    //             minWidth: '600px',
    //             data: {
    //                 haendler: haendler
    //             }
    //         });
    //         dialogRef.afterClosed().subscribe({
    //             next: () => {
    //                 this.ladeFavoriten();
    //             }
    //         });
    //     } else {
    //         this.router
    //             .navigate(['../details', haendler.viehhaendler_id], {
    //                 relativeTo: this.route,
    //             })
    //             .then();
    //
    //     }
    //     */
    // }

    // anlegenHaendler(): void {
    //     this.messageService.infoMessage('Funktion inaktiv!');
    //     /*
    //     if (this.appService.isMobileScreen) {
    //         this.router.navigate(['details'], { relativeTo: this.route }).then();
    //     } else {
    //         const dialogRef = this.dialog.open(HaendlerDetailsDialogComponent, {
    //             minWidth: '600px'
    //         });
    //         dialogRef.afterClosed().subscribe({
    //             next: () => {
    //                 this.ladeFavoriten();
    //             }
    //         });
    //     }
    //         */
    // }

    setHaendlerAsDefault(haendler: Client): void {
        console.log(haendler.bezeichnung + "is now default");
        this.benutzerprofil.default_haendler_id = haendler.betrieb_id;
        this.updateBenutzerprofil();
    }

    ladeBenutzerprofil() {
        this.api
            .get(
                `${this.apiBaseUrl}benutzerprofil/read_one_kundenportal.php`)
            .subscribe({
                next: (response: Benutzerprofil) => {
                    this.benutzerprofil = response;
                },
                error: err => {
                    this.messageService.errorMessage('Profil konnte nicht geladen werden!', err);
                }
            });
    }

    updateBenutzerprofil() {
        this.api
            .updateGeneric(
                `${this.apiBaseUrl}benutzerprofil/update_kundenportal.php`,
                this.benutzerprofil
            )
            .subscribe({
                next: (response: Benutzerprofil) => {
                    this.messageService.infoMessage(
                        'Standardhändler erfolgreich gespeichert!'
                    );
                    this.benutzerprofil = response;
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler! ', err);
                },
            });
    }

    sendMail(haendler: Client) {
        if (haendler.email) {
            const htmlAnchorElement = document.createElement('a');
            htmlAnchorElement.style.display = 'none';
            htmlAnchorElement.href = 'mailto:' + haendler.email;
            document.body.appendChild(htmlAnchorElement);
            htmlAnchorElement.click();
            // document.body.removeChild(htmlAnchorElement);
        }
    }

    // deleteHaendler(h: Client) {
    //     this.messageService.infoMessage('Funktion inaktiv!');
    //     /*
    //     const input = new ConfirmDialogInput();
    //     input.headline = 'Händler löschen';
    //     input.text = 'Wollen Sie den Händler ' + h.name + ' wirklich löschen?';
    //     input.okButton = 'Löschen';
    //     input.buttonColor = 'warn';
    //     const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    //         data: input
    //     });
    //     dialogRef.afterClosed().subscribe({
    //         next: (result: ConfirmDialogResult) => {
    //             if (result.ok && result.confirmed) {
    //                 this.stammdatenService.deleteHaendlerFavorit(h).subscribe({
    //                     next: response => {
    //                         this.messageService.infoMessage('Der Händler wurde erfolgreich entfernt!');
    //                         this.ladeFavoriten();
    //                     },
    //                     error: err => {
    //                         this.messageService.errorMessage('Fehler!', err);
    //                     }
    //                 });
    //             }
    //         }
    //     });
    //     */
    // }
}
