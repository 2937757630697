<div *kienePermission="[permissionRead]" style="padding: 24px;">
    <div class="one-row">
        <h1 *ngIf="headline">{{ headline }}</h1>
        <mat-checkbox [formControl]="gueltigCtrl">alle Anzeigen</mat-checkbox>
        <button *kienePermission="[permissionWrite]" color="primary" mat-icon-button (click)="add()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <mat-card style="margin: 12px;" *ngFor="let i of informationen">
        <mat-card-header class="card-header">
            <div mat-card-avatar>
                <mat-icon>information</mat-icon>
            </div>
            <mat-card-title style="font-weight: bold;">{{ i.titel }}</mat-card-title>
            <button *kienePermission="[permissionWrite, permissionDelete]" mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button *kienePermission="[permissionWrite]" mat-menu-item (click)="edit(i)">
                    <mat-icon>edit</mat-icon>
                    <span>Editieren</span>
                </button>
                <button *kienePermission="[permissionDelete]" mat-menu-item (click)="delete(i)">
                    <mat-icon>delete</mat-icon>
                    <span>Löschen</span>
                </button>
            </mat-menu>
            <mat-card-subtitle>{{ i.vorname }} {{ i.nachname }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            {{ i.txt }}
        </mat-card-content>
    </mat-card>
</div>
