<h1 mat-dialog-title>Bestand hinzuf&uuml;gen</h1>
<div mat-dialog-content>
    <kiene-article-scan-autocomplete [placeholder]="'Artikel'" [required]="true" (selectedMaterial)="setMaterial($event)"></kiene-article-scan-autocomplete>
    <br>
    <mat-form-field>
        <mat-label>Menge</mat-label>
        <input matInput type="number" [formControl]="mengeCtrl" required>
    </mat-form-field>
    <br>
    <mat-form-field>
        <mat-label>Charge</mat-label>
        <input matInput type="text" [formControl]="chargeCtrl">
    </mat-form-field>
    <br>
    <mat-form-field>
        <mat-label>MHD</mat-label>
        <input matInput [matDatepicker]="mhdPicker" [formControl]="mhdCtrl">
        <mat-datepicker-toggle matSuffix [for]="mhdPicker"></mat-datepicker-toggle>
        <mat-datepicker #mhdPicker></mat-datepicker>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()" [disabled]="isSaveButtonDisabled()">Hinzuf&uuml;gen</button>
</div>