import { VetGroup } from './tierarzt-gruppe';

export class Tierarzt {
    tierarzt_id: number;
    tierarztgruppen: VetGroup[] = [];
    benutzer_id: number;
    vorname: string;
    nachname: string;
    lagerplatz_id: number;
    lagerplatz: string;
    tierarztnummer: number;
    qs_alias: string;
    abgabebeleg_nr: number;
    editiert: Date;
    titel: string;
    unterschrift: string;
}

export class VetGroupVet {
    tt_id: number;
    tierarztgruppe_id: number;
    tierarztgruppe: string;
    tierarzt_id: number;
    vorname: string;
    nachname: string;
}

export interface VetApi {
    records: Tierarzt[];
    count: number;
}
