import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../services/message.service';
import { Wirkstoff } from '../../../warehouse/material/material';
import { Betriebstyp } from '../betriebstyp';

@Component({
  selector: 'kiene-betriebstyp-details-dialog',
  templateUrl: './betriebstyp-details-dialog.component.html',
  styleUrls: ['./betriebstyp-details-dialog.component.scss']
})
export class BetriebstypDetailsDialogComponent implements OnInit {

  betriebstyp = new Betriebstyp();
  betriebstypCtrl = new UntypedFormControl();
  impfstoffZusatzCtrl = new UntypedFormControl();
  antibiotikaZusatzCtrl = new UntypedFormControl();
  wirkstoffCtrl = new UntypedFormControl();

  wirkstoffe: Wirkstoff[] = [];

  constructor(public dialogRef: MatDialogRef<BetriebstypDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private api: KieneTableService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    if (this.data && this.data.betriebstyp) {
      this.betriebstyp = this.data.betriebstyp;
      this.initFormFields();
    }
    this.loadWirkstoffe();
  }

  initFormFields() {
    this.betriebstypCtrl.setValue(this.betriebstyp.betriebstyp);
    this.impfstoffZusatzCtrl.setValue(this.betriebstyp.impfstoff_zusatz);
    this.antibiotikaZusatzCtrl.setValue(this.betriebstyp.antibiotika_zusatz);
    this.wirkstoffCtrl.setValue(this.betriebstyp.wirkstoffe);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.betriebstyp.betriebstyp = this.betriebstypCtrl.value;
    this.betriebstyp.impfstoff_zusatz = this.impfstoffZusatzCtrl.value;
    this.betriebstyp.antibiotika_zusatz = this.antibiotikaZusatzCtrl.value;
    this.betriebstyp.wirkstoffe = this.wirkstoffCtrl.value;
    this.api.updateEntity(environment.BASE_URI + 'betriebstyp/update.php', this.betriebstyp).subscribe({
      next: response => {
        this.close();
      },
      error: err => {
        this.messageService.errorMessage('Der Betriebstyp konnte nicht gespeichert werden!', err);
      }
    });
  }

  wirkstoffComparator(w1: Wirkstoff, w2: Wirkstoff) {
    return w1.wirkstoff_id === w2.wirkstoff_id;
  }

  loadWirkstoffe() {
    this.api.get(environment.BASE_URI + 'wirkstoff/read.php').subscribe({
      next: response => {
        this.wirkstoffe = response.records;
      }
    });
  }

}
