import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, } from '@angular/core';
import { TableDescriptor } from '../../../components/kiene-table/table-descriptor';
import { ArtikelMedikation, Material } from '../material';
import { MatDialog } from '@angular/material/dialog';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../services/message.service';
import { HttpParams } from '@angular/common/http';
import { NumberTableColumn, TableColumn, } from '../../../components/kiene-table/table-column';
import { TableOption, TableOptionEvent, } from '../../../components/kiene-table/table-option';
import { ConfirmDeleteDialogComponent } from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';

import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    WartezeitSonderfallDialogComponent
} from '../../../warehouse/material/material-wartezeit/wartezeit-sonderfall-dialog/wartezeit-sonderfall-dialog.component';
import { Mandant } from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'kiene-material-wartezeit',
    templateUrl: './material-wartezeit.component.html',
    styleUrls: ['./material-wartezeit.component.scss'],
})
export class MaterialWartezeitComponent implements OnInit, OnChanges {
    tableDescriptor = new TableDescriptor();

    @Input('material') material: Material;
    @Input('mandanten') mandanten: Mandant[] = [];

    constructor(
        public dialog: MatDialog,
        private api: KieneTableService,
        private messageService: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Sonderfälle Wartezeiten';
        this.tableDescriptor.uniqueIdentifier = 'Sonderfälle Wartezeiten';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'artikel_medikation/';
        this.tableDescriptor.standardCreate = false;

        this.tableDescriptor.permissionView = 1301;
        this.tableDescriptor.permissionCreate = 1303;
        this.tableDescriptor.permissionUpdate = 1303;
        this.tableDescriptor.permissionDelete = 1304;

        if (this.material?.artikel_id) {
            this.tableDescriptor.params = new HttpParams().set(
                'artikel_id',
                this.material.artikel_id.toString()
            );
        } else {
            this.tableDescriptor.waitToLoad = true;
        }

        const columns: TableColumn[] = [];

        columns.push(new TableColumn('tierart', 'Tierart'));
        columns.push(new TableColumn('anwendung', 'Anwendung'));
        columns.push(new TableColumn('diagnose', 'Diagnose'));
        columns.push(new TableColumn('wartezeit_fleisch', 'Wartezeit Fleisch'));
        columns.push(new TableColumn('wartezeit_milch', 'Wartezeit Milch'));
        columns.push(new TableColumn('wartezeit_eier', 'Wartezeit Eier'));
        columns.push(new TableColumn('kommentar', 'Kommentar'));
        columns.push(new NumberTableColumn('menge', 'Menge', '1.2-2'));
        columns.push(new TableColumn('einheit', 'Einheit'));
        columns.push(new TableColumn('behandlung_tage', 'Behandlung Tage'));
        columns.push(new TableColumn('intervall_std', 'Intervall Stunden'));
        columns.push(new TableColumn('faktor', 'Faktor'));
        columns.push(new TableColumn('wirktage_zb', 'Wirktage (Bsp.)'));
        columns.push(
            new NumberTableColumn(
                'koerpergewicht',
                'Körpergewicht',
                '1.0-2',
                'kg'
            )
        );
        columns.push(new TableColumn('dosierung_txt', 'Dosierungtext'));

        this.tableDescriptor.columns = columns;

        this.tableDescriptor.initialSortColumn = 'tierart';
        this.tableDescriptor.initialSortDirection = 'asc';
        this.tableDescriptor.defaultPageSize = 10;

        this.tableDescriptor.options.push(
            new TableOption('edit', 'Bearbeiten', 'edit', 1303)
        );
        this.tableDescriptor.options.push(
            new TableOption('delete', 'Löschen', 'delete', 1304)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.material) {
            // Nur wenn sich das Material geändert hat
            if (this.material?.artikel_id && this.tableDescriptor.waitToLoad) {
                this.tableDescriptor.waitToLoad = false;
                this.tableDescriptor.params = new HttpParams().set(
                    'artikel_id',
                    this.material.artikel_id.toString()
                );
                this.api.reload('ngOnChanges Tierart');
            }
        }
    }

    optionClicked(event: TableOptionEvent) {
        if (event.name === 'add') {
            this.openWartezeitSonderfallHinzufuegenDialog();
        } else if (event.name === 'edit') {
            this.openWartezeitSonderfallHinzufuegenDialog(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        }
    }

    openDeleteDialog(artikelMedikation: ArtikelMedikation) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Sonderfall löschen?',
                text: 'Wollen Sie den Sonderfall wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe({
            next: (result) => {
                if (result) {
                    this.api
                        .deleteEntity(
                            this.apiBaseUrl + 'artikel_medikation/delete.php',
                            artikelMedikation
                        )
                        .subscribe({
                            next: (response) => {
                                this.messageService.infoMessage(
                                    'Der Sonderfall wurde erfolgreich gelöscht!'
                                );
                                this.api.reload();
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    private openWartezeitSonderfallHinzufuegenDialog(am?: ArtikelMedikation) {
        const dialogRef = this.dialog.open(WartezeitSonderfallDialogComponent, {
            data: {
                material: this.material,
                artikelMedikation: am,
            },
        });
        dialogRef.afterClosed().subscribe({
            next: (result) => {
                if (result) {
                    this.api.reload('openWartezeitSonderfallHinzufuegenDialog');
                }
            },
        });
    }
}
