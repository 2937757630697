<div *kienePermission="[1725]">
    <h1>Positionen &uuml;berpr&uuml;fen</h1>
    <div class="one-row" style="height: 800px; max-height: 800px;">
        <div
            style="width: 50%; align-self: flex-start; height: 100%;">
            <object *ngIf="pdfAvailable"
                    [data]="pdfBase64"
                    style="height: 100%; width: 100%; min-height: 75vh;"
                    type="application/pdf"
            ></object>
            <mat-spinner *ngIf="!pdfAvailable && isLoading" style="align-self: center;"></mat-spinner>
        </div>
        <div
            style="width: 50%; align-self: flex-start; padding-left: 12px;height: 800px; max-height: 800px; overflow-y: scroll;">

            <div class="one-row">
                <mat-form-field>
                    <mat-label>Rechnungsnummer</mat-label>
                    <input [formControl]="rechnungsNummerCtrl" matInput required type="text"/>
                </mat-form-field>
                <span class="spacer"></span>
                <mat-form-field>
                    <mat-label>Rechnungsdatum</mat-label>
                    <input [formControl]="rechnungsDatumCtrl" [matDatepicker]="picker" matInput required>
                    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>

            <table class="phito-table bestellposition-table" style="width: 100%; position: relative;">
                <thead>
                <tr>
                    <th>Position</th>
                    <th>In Rechnung</th>
                    <th colspan="7">Artikel</th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th>Einzelpreis</th>
                    <th>Rabatt</th>
                    <th>Rabattpreis</th>
                    <th>Menge Bestellt</th>
                    <th>Menge Geliefert</th>
                    <th style="width: 70px;">F&uuml;r Primanota freigeben</th>
                    <th style="width: 70px;">In Primanota</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td></td>
                    <td colspan="9">
                        <mat-checkbox (change)="alleAnhakenInRechnung($event)">Alle Positionen in Rechnung vorhanden
                        </mat-checkbox>
                    </td>
                </tr>
                <ng-container *ngFor="let p of bestellungExternDokumentPruefung?.positionen; let i = index;">
                    <tr
                        [ngClass]="{'position-disabled': (p.primanota_id > 0) }">
                        <td style="border-bottom: none;">{{ (i + 1) }}</td>
                        <td style="border-bottom: none;">
                            <mat-checkbox [formControl]="inRechnungCtrls[i]"></mat-checkbox>
                        </td>
                        <td colspan="7" style="border-bottom: none;">{{ p.artikel_me }}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td (click)="editEinzelpreis(p)"
                            style="cursor: pointer;"><span
                            style="background-color: #cfcfcf; padding: 3px; font-style: italic;">{{ p.einzelpreis | number: '1.5-5' }}&euro;</span>
                        </td>
                        <td>{{ p.rabatt | number: '1.0-1' }}%</td>
                        <td>{{
                                (p.rabatt ? (p.einzelpreis * (100 - p.rabatt) / 100) : p.einzelpreis) |
                                    number: '1.5-5'
                            }}&euro;
                        </td>
                        <td>{{ p.menge_soll }}</td>
                        <td>{{ p.menge_ist }}</td>
                        <td>
                            <mat-checkbox [formControl]="geprueftCtrls[i]"></mat-checkbox>
                        </td>
                        <td>
                            <mat-icon>{{ p.primanota_id > 0 ? 'check' : '' }}</mat-icon>
                        </td>
                    </tr>
                </ng-container>


                </tbody>
            </table>
            <div class="one-row" style="margin-top: 12px;">
                <span class="spacer"></span>
                <button (click)="save()" [disabled]="!isValid()" color="accent" mat-flat-button>Speichern</button>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button (click)="dialogRef.close()" mat-button>Abbrechen</button>
</div>
