import { Component, OnInit } from '@angular/core';
import {
    LocalStorageService
} from "../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service";

@Component({
  selector: 'kiene-heimtiere',
  templateUrl: './heimtiere.component.html',
  styleUrls: ['./heimtiere.component.scss']
})
export class HeimtiereComponent implements OnInit {

    constructor(private localStorageService: LocalStorageService) {
    }

    ngOnInit(): void {
        this.localStorageService.setShoppingCartVisible(false);
    }

    isTabMenuVisible(path: string) {
        return this.localStorageService.currentUserCanSeeTab(path);
    }
}
