import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileViewerDialogComponent} from './file-viewer-dialog/file-viewer-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {PdfViewerModule} from 'ng2-pdf-viewer';


@NgModule({
    declarations: [
        FileViewerDialogComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        PdfViewerModule
    ],
    exports: [
        FileViewerDialogComponent
    ]
})
export class FileViewerModule {
}
