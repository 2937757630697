import {HttpParams} from '@angular/common/http';
import {Component, Inject, Input, OnChanges, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {ConfirmDialogComponent} from '../../../dialogs/confirm-dialog/confirm-dialog.component';
import {EnterValueDialogComponent} from '../../../dialogs/enter-value-dialog/enter-value-dialog.component';
import {EnterValueInput, EnterValueResponse} from '../../../dialogs/enter-value-dialog/enter-value-response';
import {GruppeTier} from '../../../gruppe-tier/classes/gruppe-tier';
import {API_BASE_URL_SERVICE} from '../../../../kiene-core.config';
import {KieneBackendApiService} from '../../../../services/backend-api/kiene-backend-api.service';
import {MessageService} from '../../../../services/message-service/message.service';
import {KieneUtils} from '../../../../utils/KieneUtils';
import {GruppenService} from '../../gruppen.service';
import {
    AbgangslisteSchliessenDialogComponent
} from '../abgangsliste-schliessen-dialog/abgangsliste-schliessen-dialog.component';
import {GruppeAbgangService} from '../gruppe-abgang.service';
import {Tierliste} from '../../../gruppe-tier/classes/tierliste';
import {AppService} from '../../../../services/app/app.service';
import {ConfirmDialogInput, ConfirmDialogResult} from '../../../dialogs/confirm-dialog/confirm-dialog';
import {LocalStorageService} from '../../../../services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-abgang-liste',
    templateUrl: './abgang-liste.component.html',
    styleUrls: ['./abgang-liste.component.scss'],
})
export class AbgangListeComponent implements OnInit, OnChanges {
    tiere: GruppeTier[] = [];
    tierliste: Tierliste;
    ladeTiereFuerAbgangslisteSub: Subscription;
    isLoading = false;

    @Input('alsDialog') alsDialog = false;

    constructor(
        private abgangService: GruppeAbgangService,
        private api: KieneBackendApiService,
        private dialog: MatDialog,
        private appService: AppService,
        private messageService: MessageService,
        private gruppenService: GruppenService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        public lss: LocalStorageService
    ) {
    }

    ngOnInit(): void {
        if (!this.tierliste) {
            this.tierliste = this.abgangService.tierliste;
            if (this.tierliste) {
                this.ladeTiereFuerAbgangsliste('ngOnInit AbgangListeComponent');
            }
        }

        this.abgangService.watchAbgangsliste().subscribe({
            next: (liste) => {
                if (liste.tierliste) {
                    this.tierliste = liste.tierliste;
                    if (liste.reloadGruppenTiere) {
                        this.ladeTiereFuerAbgangsliste('watchAbgangsliste() next');
                    }
                } else {
                    this.tierliste = undefined;
                    this.tiere = [];
                }
            },
        });
    }

    ngOnChanges() {
    }

    ausListeEntfernen(tier: GruppeTier) {
        const input = new EnterValueInput();
        input.title = 'Tier aus Liste entfernen?';
        input.text =
            'Es kann optional ein Grund für das Entfernen eingetragen werden.';
        input.okButton = 'entfernen';
        input.buttonColor = 'accent';
        input.addInputValue('Grund (optional)', 'text');

        const dialogRef = this.dialog.open(EnterValueDialogComponent, {
            data: input,
            position: {top: '10px'}, disableClose: true
        });
        dialogRef.afterClosed().subscribe({
            next: (res: EnterValueResponse) => {
                if (res?.result === true) {
                    if (KieneUtils.isNNoU(res.getValue(0))) {
                        tier.grund_entfernung = res.getValue(0);
                    }
                    this.api
                        .post(
                            this.apiBaseUrl +
                            'gruppe_tier/delete_tierliste.php',
                            tier,
                            undefined
                        )
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Das Tier wurde erfolgreich entfernt!'
                                );
                                this.abgangService.setAbgangsliste(
                                    this.tierliste,
                                    true,
                                    true
                                );
                                this.ladeTiereFuerAbgangsliste('ausListeEntfernen() afterClosed');
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    abgangslisteComparator(l1: Tierliste, l2: Tierliste) {
        return l1?.tierliste_id === l2?.tierliste_id;
    }

    abgangslisteLeeren() {
        const input = new ConfirmDialogInput();
        input.headline = 'Liste leeren?';
        input.text = 'Wollen Sie die aktuelle Ausstallliste wirklich leeren?';
        input.okButton = 'Leeren';
        input.buttonColor = 'warn';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input, disableClose: true
        });
        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.appService.setBlockOnLoading(true);
                    this.api
                        .post(
                            this.apiBaseUrl +
                            'gruppe_tier/delete_tierliste.php',
                            this.tierliste,
                            undefined
                        )
                        .subscribe({
                            next: () => {
                                this.appService.setBlockOnLoading(false);
                                this.messageService.infoMessage(
                                    'Die Ausstallliste wurde erfolgreich geleert!'
                                );
                                this.abgangService.setAbgangsliste(
                                    this.tierliste,
                                    true,
                                    true
                                );
                            },
                            error: (err) => {
                                this.appService.setBlockOnLoading(false);
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    abgangslisteSchliessen() {
        const dialogRef = this.dialog.open(
            AbgangslisteSchliessenDialogComponent,
            {
                maxWidth: '600px',
                data: {
                    tierliste: this.tierliste,
                    alsDialog: this.alsDialog
                }, disableClose: true
            }
        );
        dialogRef.afterClosed().subscribe({
            next: (result) => {
                if (result) {
                    this.abgangService.setAbgangsliste(
                        this.tierliste,
                        false,
                        true
                    );
                    this.ladeTiereFuerAbgangsliste('abgangslisteSchliessen() afterClosed');
                    this.abgangService.tierListeAbgeschlossen(this.tierliste, 'abgangslisteSchliessen');
                }
            },
        });
    }

    public ladeTiereFuerAbgangsliste(sender: string) {
        console.log('ladeTiereFuerAbgangsliste(): ', sender);
        if (this.tierliste) {
            this.isLoading = true;
            let params = new HttpParams().set(
                'tierliste_id',
                this.tierliste.tierliste_id
            );
            params = params.set('orderby', 'editiert desc');

            this.ladeTiereFuerAbgangslisteSub?.unsubscribe();
            this.ladeTiereFuerAbgangslisteSub = this.api
                .getAll(
                    this.apiBaseUrl + 'gruppe_tier/read_kundenportal.php',
                    params
                )
                .subscribe({
                    next: (response) => {
                        this.tiere = response.records;
                        this.isLoading = false;
                    },
                    error: (err) => {
                        this.messageService.errorMessage('Fehler!', err);
                        this.isLoading = false;
                    },
                });
        }
    }

    getTierreklamationFormatted(t: GruppeTier) {
        return t.reklamationen.map((x) => x.reklamation).join(', ');
    }

    hasReklamation(tier: GruppeTier) {
        return tier.reklamationen && tier.reklamationen.length > 0;
    }

    istMeldenMoeglich(): boolean {
        const meldenMoeglich = false;
        for (const t of this.tiere) {
            if (t.status_id_abgang === 140) {
                return true;
            }
        }
        return meldenMoeglich;
    }

    downloadAbgangsliste() {
        this.gruppenService.openGruppeAbgangDownloadDialog({
            tierliste: this.tierliste,
        });
    }
}
