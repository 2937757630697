<div style="margin-top: 24px;">
    <ng-container *ngIf="gruppe?.standard === 0">
        <button *kienePermission="[1912]" mat-flat-button color="accent"
            (click)="openGruppeInformationenDialog()">
            <mat-icon>edit_note</mat-icon>
            <span>&nbsp;Informationen hinzufügen</span>
        </button>
    </ng-container>
</div>

<mat-expansion-panel style="margin-top: 24px;" [disabled]="!statistikOpenable()" [expanded]="true">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <div style="display: inline-flex">
                <mat-icon>info</mat-icon>
                <span style="margin-left: 6px; padding-top: 4px">Gruppendetails</span>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <table aria-label="gruppen-infos">
        <tr>
            <td class="tdlabel">
                Herdenname:
            </td>
            <td class="tdvalue">
                {{ gruppe?.herdenname || "---" }}
            </td>
            <td class="tdlabel">Anzahl Tiere:
            </td>
            <td class="tdvalue">{{
                gruppe?.anzahl_tiere_gesamt || "---"
                }}
            </td>
            <td class="tdlabel">Tiere gescannt:
            </td>
            <td class="tdvalue">{{
                gruppe?.anzahl_tiere_ohrmarken || "---"
                }} {{
                getDifferenceString(
                gruppe?.anzahl_tiere_ohrmarken,
                gruppe?.anzahl_tiere_gesamt
                )
                }}
            </td>
            <td class="tdlabel">Abgänge:
            </td>
            <td class="tdvalue">{{ gruppe?.anzahl_abgaenge || "---" }}
                {{
                getDifferenceString(
                gruppe?.anzahl_abgaenge,
                gruppe?.anzahl_tiere_gesamt,
                {asPercentage: true}
                )
                }}
            </td>
        </tr>

        <tr>
            <td class="tdlabel">Standort:
            </td>
            <td class="tdvalue">{{
                gruppe?.stall || "---"
                }}
            </td>
            <td class="tdlabel">- laut HIT:
            </td>
            <td class="tdvalue">{{ gruppe?.anzahl_tiere_hit || "---" }} {{
                getDifferenceString(
                gruppe?.anzahl_tiere_hit,
                gruppe?.anzahl_tiere_gesamt
                )
                }}
            </td>
            <td class="tdlabel">- davon Tiere ohne Pass:
            </td>
            <td class="tdvalue">{{ gruppe?.tiere_ohne_pass || "---" }}
            </td>
            <td class="tdlabel">Tote:
            </td>
            <td class="tdvalue">{{ gruppe?.anzahl_tote || "---" }} {{
                getDifferenceString(
                gruppe?.anzahl_tote,
                gruppe?.anzahl_tiere_gesamt,
                {asPercentage: true}
                )
                }}
            </td>
        </tr>

        <tr>
            <td class="tdlabel">Einstallzeitraum:
            </td>
            <td class="tdvalue">
                {{
                (gruppe?.einstalldatum_von | date : "dd.MM.yy") ||
                "---"
                }}
                -
                {{
                (gruppe?.einstalldatum_bis | date : "dd.MM.yy") ||
                "---"
                }}
            </td>
            <td class="tdlabel">Alter:
            </td>
            <td class="tdvalue">
                {{ gruppe?.alter_wochen || "---" }} Wochen
            </td>
            <td class="tdlabel">P&auml;sse ohne Tier:
            </td>
            <td class="tdvalue">{{ gruppe?.paesse_ohne_tier || "---" }}
            </td>
            <td class="tdlabel">Anzahl Tiere aktuell:
            </td>
            <td class="tdvalue">{{
                gruppe?.anzahl_tiere_aktuell || "---"
                }}
            </td>
        </tr>

        <tr>
            <td class="tdlabel">Akt. Produktionsabschnitt:</td>
            <td class="tdvalue">{{gruppe?.produktionsabschnitt || '---' }} seit {{ (gruppe?.datum_von |
                date:"dd.MM.yy") || '---'}}</td>
        </tr>

        <tr>
            <td class="tdlabel">Informationen:</td>
            <td class="tdvalue" colspan="7">{{gruppe?.informationen || '---' }}</td>
        </tr>
    </table>
</mat-expansion-panel>

<mat-expansion-panel [disabled]="verlaufIsLoading || !verlaufOpenable()" [expanded]="true">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <div class="one-row">
                <mat-icon>history</mat-icon>
                <span style="margin-left: 6px">{{
                    gruppenVerlauf?.length === 0
                    ? "Verlauf - keine Ereignisse vorhanden"
                    : "Verlauf"
                    }}</span>
                &nbsp;
                <span>{{
                    gruppenVerlauf && gruppenVerlauf.length > 0
                    ? "(" + gruppenVerlauf?.length + ")"
                    : ""
                    }}</span>
                <span class="spacer"></span>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <div>
        <div *ngFor="let verlaufEintrag of gruppenVerlauf" class="verlauf one-row">
            <mat-icon>{{ verlaufEintrag.icon }}</mat-icon>
            <span>{{ verlaufEintrag.datum | date : "dd.MM.YY" }}</span>
            <span>{{ verlaufEintrag.txt }}</span>
            <span class="spacer"></span>
        </div>
    </div>
</mat-expansion-panel>

<mat-expansion-panel [disabled]="!statistikOpenable()" [expanded]="true">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <div style="display: inline-flex">
                <mat-icon>assessment</mat-icon>
                <span style="margin-left: 6px; padding-top: 4px">Statistiken ({{
                    gibAnzahlStatistiken()
                    }})</span>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <div>
        <table class="info-table">
            <tr *ngIf="gruppenStatistik?.rassen?.length > 0">
                <td><strong>Rassen</strong></td>
                <td></td>
            </tr>
            <tr *ngFor="let r of gruppenStatistik?.rassen">
                <td>{{ r.rasse }}</td>
                <td>{{ r.anzahl }}x</td>
            </tr>
            <tr *ngIf="gruppenStatistik?.geschlechter?.length > 0">
                <td style="padding-top: 12px">
                    <strong>Anzahl Geschlechter</strong>
                </td>
                <td></td>
            </tr>
            <tr *ngFor="let g of gruppenStatistik?.geschlechter">
                <td>{{ g.geschlecht }}</td>
                <td>{{ g.anzahl }}x</td>
            </tr>
            <tr *ngIf="gruppenStatistik?.reklamationen?.length > 0">
                <td style="padding-top: 12px">
                    <strong>Anzahl Reklamationen</strong>
                </td>
                <td></td>
            </tr>
            <tr *ngFor="let g of gruppenStatistik?.reklamationen">
                <td>{{ g.reklamation }}</td>
                <td>{{ g.anzahl }}x</td>
            </tr>
            <tr *ngIf="gruppenStatistik?.abteile?.length > 0">
                <td style="padding-top: 12px">
                    <strong>Tiere je Abteil</strong>
                </td>
                <td></td>
            </tr>
            <tr *ngFor="let a of gruppenStatistik?.abteile">
                <td>{{ a?.abteil }}</td>
                <td>{{ a?.anzahl }}x</td>
            </tr>
        </table>
    </div>
</mat-expansion-panel>