<h1 mat-dialog-title>Entsorger {{entsorger?.entsorger_id ? 'editieren' : 'anlegen'}}</h1>
<div mat-dialog-content>


    <div class="one-row">


        <mat-form-field style="width: 49%;">
            <mat-label>Name</mat-label>
            <input [(ngModel)]="entsorger.name" matInput required type="text">
        </mat-form-field>

        <mat-form-field style="width: 49%;">
            <mat-label>VVVO</mat-label>
            <input [(ngModel)]="entsorger.vvvo" matInput maxlength="15" minlength="12" type="text">
        </mat-form-field>

    </div>

    <div class="one-row">
        <mat-form-field style="width: 32%;">
            <mat-label>Stra&szlig;e</mat-label>
            <input [(ngModel)]="entsorger.strasse" matInput type="text">
        </mat-form-field>
        <mat-form-field style="width: 32%;">
            <mat-label>PLZ</mat-label>
            <input [(ngModel)]="entsorger.plz" matInput type="text">
        </mat-form-field>
        <mat-form-field style="width: 32%;">
            <mat-label>Ort</mat-label>
            <input [(ngModel)]="entsorger.ort" matInput type="text">
        </mat-form-field>
    </div>
    <div class="one-row">
        <mat-form-field style="width: 24%;">
            <mat-label>Telefon</mat-label>
            <input [(ngModel)]="entsorger.telefon" matInput type="tel">
        </mat-form-field>
        <mat-form-field style="width: 24%;">
            <mat-label>Mobil</mat-label>
            <input [(ngModel)]="entsorger.mobil" matInput type="tel">
        </mat-form-field>
        <mat-form-field style="width: 24%;">
            <mat-label>Fax</mat-label>
            <input [(ngModel)]="entsorger.fax" matInput type="tel">
        </mat-form-field>
        <mat-form-field style="width: 24%;">
            <mat-label>E-Mail</mat-label>
            <input [(ngModel)]="entsorger.email" matInput type="email">
        </mat-form-field>
    </div>

</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button (click)="close()" mat-button>Abbrechen</button>
    <button (click)="save()" color="accent" mat-flat-button>Speichern</button>
</div>
