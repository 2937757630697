<h1 mat-dialog-title>Belegvorlagen</h1>
<div mat-dialog-content>

    <div class="one-row">
        <span class="spacer"></span>
        <button mat-icon-button color="accent" (click)="neueVorlageAnlegen()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <table class="phito-table">
        <thead>
        <tr>
            <th>Position</th>
            <th>Vorlage</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let v of allArticleLists; let i = index;">
            <td>{{ (i + 1) }}.</td>
            <td (click)="openDetailsDialog(v)" style="cursor: pointer;">{{ v.listenname }}</td>
            <td>
                <button mat-icon-button [matMenuTriggerFor]="listMenu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #listMenu="matMenu">
                    <button mat-menu-item (click)="openDetailsDialog(v);">
                        <mat-icon>edit</mat-icon>
                        <span>bearbeiten</span>
                    </button>
                    <button mat-menu-item (click)="deleteArticleList(v)">
                        <mat-icon>delete</mat-icon>
                        <span>l&ouml;schen</span>
                    </button>
                </mat-menu>
            </td>
        </tr>
        </tbody>
    </table>


    <!--    <kiene-kiene-mini-table (addButton)="openDetailsDialog()" (closeDialog)="closeDialog()"-->
    <!--                            (deleteItem)="deleteArticleList($event)" (selectedElement)="getElement($event)"-->
    <!--                            [initMiniTable]="initMiniTable"></kiene-kiene-mini-table>-->
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close()">Schlie&szlig;en</button>
</div>
