import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {Subscription} from 'rxjs';
import {
    DateTableColumn,
    KieneBooleanTableColumn,
    TableColumn,
    TextTableColumn
} from '../../components/kiene-table/table-column';
import {KieneSession} from '../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import {MatDialog} from '@angular/material/dialog';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {MessageService} from '../../services/message.service';
import {AppService} from '../../app.service';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {TableOptionEvent} from '../../components/kiene-table/table-option';
import {
    FilterItem,
    FilterItemType
} from '../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import {Router} from '@angular/router';

@Component({
    selector: 'kiene-hitmeldungen',
    templateUrl: './hitmeldungen.component.html',
    styleUrls: ['./hitmeldungen.component.scss']
})
export class HITMeldungenComponent implements OnInit, OnDestroy {
    tableDescriptor = new TableDescriptor();
    watchClientSubscription: Subscription;
    kundeColumn: TableColumn;
    kundeZusatzColumn: TableColumn;

    currentUser: KieneSession;

    constructor(
        private dialog: MatDialog,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private messageService: MessageService,
        private appService: AppService,
        private router: Router,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService
    ) {
    }

    ngOnDestroy() {
        this.watchClientSubscription.unsubscribe();
        this.tableService = null;
    }

    ngOnInit(): void {
        this.currentUser = this.localStorageService.getSession();

        this.watchClientSubscription = this.localStorageService
            .watchCurrentClient()
            .subscribe((response) => {
                if (response) {
                    if (this.kundeColumn && this.kundeZusatzColumn) {
                        this.kundeColumn.isActive = false;
                        this.kundeZusatzColumn.isActive = false;
                        this.tableService.updateColumns();
                    }
                } else {
                    if (this.kundeColumn && this.kundeZusatzColumn) {
                        this.kundeColumn.isActive = true;
                        this.kundeZusatzColumn.isActive = true;
                        this.tableService.updateColumns();
                    }
                }
            });

        this.tableDescriptor.headline = 'HIT Meldungen';
        this.tableDescriptor.uniqueIdentifier = 'HIT Meldungen';
        this.tableDescriptor.alternativeApiUrl =
            this.apiBaseUrl + 'hit_bewegung/read_kundenportal.php';
        this.tableDescriptor.initialSortDirection = 'desc';
        this.tableDescriptor.initialSortColumn = 'bewegung_id';
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.showDetailsAsOption = true;
        this.tableDescriptor.sendCurrentClient = true;

        this.tableDescriptor.columns = this.getColumns();

        this.tableDescriptor.permissionView = 9221;

        this.tableDescriptor.filterItems.push(new FilterItem('noch nicht melden', 140, FilterItemType.CHECKBOX, 'status_id', false, {permissionId: 9221}));
        this.tableDescriptor.filterItems.push(new FilterItem('bereit zum Melden', 141, FilterItemType.CHECKBOX, 'status_id', false, {permissionId: 9221}));
        this.tableDescriptor.filterItems.push(new FilterItem('gemeldet', 142, FilterItemType.CHECKBOX, 'status_id', false, {permissionId: 9221}));
        this.tableDescriptor.filterItems.push(new FilterItem('fehlerhaft', 143, FilterItemType.CHECKBOX, 'status_id', true, {permissionId: 9221}));
        this.tableDescriptor.filterItems.push(new FilterItem('selektiert', 144, FilterItemType.CHECKBOX, 'status_id', false, {permissionId: 9221}));
        this.tableDescriptor.filterItems.push(new FilterItem('bereits gemeldet', 145, FilterItemType.CHECKBOX, 'status_id', false, {permissionId: 9221}));

    }

    getColumns(): TableColumn[] {
        const columns = [];

        columns.push(new TextTableColumn('bewegung_id', 'ID'));
        columns.push(new TextTableColumn('bewegung', 'Bewegung'));
        columns.push(new KieneBooleanTableColumn('stornomeldung', 'Stornomeldung'));
        columns.push(new TextTableColumn('status', 'Status'));
        columns.push(new TextTableColumn('fehler', 'Fehler'));
        columns.push(new TextTableColumn('ohrmarke_notnull', 'Ohrmarke'));
        columns.push(new TextTableColumn('gruppe', 'Gruppe'));
        columns.push(new TextTableColumn('abhaengig_von', 'Abhängig von'));
        columns.push(new DateTableColumn('einstalldatum', 'Einstalldatum', 'dd.MM.yyyy'));
        columns.push(new DateTableColumn('abgangsdatum', 'Abgangsdatum', 'dd.MM.yyyy'));
        columns.push(new DateTableColumn('todesdatum', 'Todesdatum', 'dd.MM.yyyy'));

        this.kundeColumn = new TableColumn('kunde', 'Kunde');
        this.kundeZusatzColumn = new TableColumn('zusatz', 'Kunde Zusatz');
        if (this.localStorageService.getCurrentClient()) {
            this.kundeColumn.isActive = false;
            this.kundeZusatzColumn.isActive = false;
        }
        columns.push(this.kundeColumn);
        columns.push(this.kundeZusatzColumn);
        columns.push(new DateTableColumn('editiert', 'Meldedatum', 'dd.MM.yyyy'));

        return columns;
    }


    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'details') {
            const historie = event.value?.historie;
            if (historie) {
                this.router.navigate(['/gruppen/historie/' + event.value.gruppe_id]);

            } else {
                this.router.navigate(['/gruppen/aktuelle/' + event.value.gruppe_id]);

            }
        }
    }


}
