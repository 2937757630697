import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EnterValueDialogComponent} from './enter-value-dialog/enter-value-dialog.component';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {BetriebAuswaehlenDialogComponent} from './betrieb-auswaehlen-dialog/betrieb-auswaehlen-dialog.component';
import {
    BetriebBenutzerZuweisenDialogComponent
} from './betrieb-benutzer-zuweisen-dialog/betrieb-benutzer-zuweisen-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
    declarations: [ConfirmDialogComponent, EnterValueDialogComponent, BetriebAuswaehlenDialogComponent, BetriebBenutzerZuweisenDialogComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDatepickerModule,
    ],
    providers: [ConfirmDialogComponent],
})
export class DialogsModule {
}
