import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Heimtier } from '../../kalender/heimtier-kalender-eintrag';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { MessageService } from '../../../services/message.service';

@Component({
    selector: 'kiene-heimtier-info',
    templateUrl: './heimtier-info.component.html',
    styleUrls: ['./heimtier-info.component.scss']
})
export class HeimtierInfoComponent {

    @Input() heimtier: Heimtier;

}
