import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../benutzer';
import { MessageService } from '../../../../services/message-service/message.service';
import { BenutzerService } from '../benutzer.service';
import { UserRole } from '../../../../../../../../src/app/administration/user/user';


@Component({
    selector: 'kiene-benutzerrolle-aendern-dialog',
    templateUrl: './benutzerrolle-aendern-dialog.component.html',
    styleUrls: ['./benutzerrolle-aendern-dialog.component.scss']
})
export class BenutzerrolleAendernDialogComponent implements OnInit {
    userroles: UserRole[] = [];

    constructor(
        public dialogRef: MatDialogRef<BenutzerrolleAendernDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private userService: BenutzerService,
        private messageService: MessageService
    ) {
    }

    ngOnInit() {
        this.loadUserRoles();
    }

    loadUserRoles() {
        this.userService.getAllUserRoles().subscribe(
            (response) => {
                this.userroles = response.records;
            },
            (error) => {
            }
        );
    }

    safeAndCloseDialog() {
        const currentUser: User = this.data.user;
        if (currentUser.benutzerrollen?.length >= 1) {
            this.userService.updateUser(currentUser).subscribe(
                (response) => {
                    this.closeDialog(response);
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Fehler! Benutzerrolle konnte nicht geändert werden!',
                        error
                    );
                }
            );
        } else {
            this.messageService.alertMessage('Mindestens eine Benutzerrolle auswählen!');
        }
    }

    closeDialog(user: User) {
        this.dialogRef.close(user);
    }

    onCancelClick(): void {
        this.dialogRef.close(null);
    }

    compareRoles(a: UserRole, b: UserRole): boolean {
        return a && b && a.benutzerrolle_id === b.benutzerrolle_id;
    }

}
