<div class="kiene-content-page" *kienePermission="[1161]">

    <mat-toolbar>
        <mat-toolbar-row>
            <button mat-icon-button color="accent" matTooltip="Zwischenspeichern"
                [disabled]="!canEditBehandlungsanfrage()" (click)="save(behandlungsanfrage.status_id)">
                <mat-icon>save</mat-icon>
            </button>
            <button *kienePermission="[1165]" mat-icon-button color="accent" matTooltip="Duplizieren"
                (click)="duplicate()">
                <mat-icon>file_copy</mat-icon>
            </button>
            <button *kienePermission="[1236]" mat-icon-button color="accent" matTooltip="In Abgabebeleg umwandeln"
                (click)="transformToAbgabebeleg()">
                <mat-icon>swap_horiz</mat-icon>
            </button>
            <button *ngIf="kieneSession.tierarzt_id" mat-icon-button color="accent" matTooltip="Mir zuweisen"
                (click)="assignToMe()">
                <mat-icon>person_add</mat-icon>
            </button>
            <button mat-icon-button color="accent" matTooltip="Neu laden" (click)="reload()">
                <mat-icon>refresh</mat-icon>
            </button>
            <span class="kiene-spacer"></span>
            <mat-checkbox *kienePermission="[1168]" color="accent" [formControl]="apothekeCtrl"
                (change)="save(behandlungsanfrage.status_id)">&uuml;ber Apotheke
            </mat-checkbox>

            <span class="kiene-spacer"></span>

            <div *ngIf="behandlungsanfrageId !== 'neu'">
                <span style="
                        display: inline-block;
                        margin-right: 10px;
                        font-size: 14px;
                    ">Status:
                </span>
                <mat-chip-list style="display: inline-block">
                    <mat-chip color="primary" selected>{{
                        behandlungsanfrage.status
                        }}</mat-chip>
                </mat-chip-list>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="kiene-page-header">
        <div>
            <button style="display: inline-block" (click)="goBack()" color="primary" mat-icon-button
                matTooltip="zurück">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <h2 style="display: inline-block; margin-left: 20px">
                {{
                behandlungsanfrage?.bestellung_id
                ? "Behandlungsanfrage " + behandlungsanfrage?.bestellung_id + " an " + mandant?.bezeichnung
                : "Neue Behandlungsanfrage stellen an " + mandant?.bezeichnung}}
            </h2>
            <button *ngIf="!behandlungsanfrage?.kunde_id" style="display: inline-block"
                (click)="takeoverCurrentClient()" color="primary" mat-icon-button
                matTooltip="Ausgewählten Betrieb für Behandlungsanfrage übernehmen">
                <mat-icon>compare_arrows</mat-icon>
            </button>
        </div>
    </div>

    <div class="position-editing">
        <div class="one-row">

            <div>
                <span style="font-weight: bold;">Von: {{behandlungsanfrage?.kunde}}
                    - {{behandlungsanfrage?.zusatz}}</span>
            </div>

            <mat-form-field style="min-width: 200px; width: 25%; margin-right: 2%">
                <mat-label>Kommentar</mat-label>
                <input matInput type="text" placeholder="Kommentar" [formControl]="descriptionCtrl" />
            </mat-form-field>

            <mat-form-field>
                <mat-label>Zieldatum</mat-label>
                <input matInput [matDatepicker]="picker" [formControl]="zielDatumCtrl" placeholder="Zieldatum" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <kiene-gruppe-auswaehlen-selektor #gruppeSelektor style="width: 300px;"
                [kundeId]="behandlungsanfrage?.kunde_id" [disabled]="disabled"
                (ausgewaehlt)="setzeGruppe($event)"></kiene-gruppe-auswaehlen-selektor>

        </div>

        <kiene-beleg-position-inline #positionInline [disabled]="disabled" [kundeMandantId]="mandantid"
            [kundeId]="behandlungsanfrage?.kunde_id" [gruppe]="aktuelleGruppe" [keineChargen]="true"
            [tiereAnzeigen]="false" (speichern)="positionenSpeichern($event)"></kiene-beleg-position-inline>

        <!--        <mat-card>-->
        <!--            <h4>Position hinzufügen</h4>-->
        <!--            <div style="width: 100%; font-size: 12px" class="one-row">-->
        <!--                <form (ngSubmit)="addPosition()">-->
        <!--                    &lt;!&ndash; Artikel &ndash;&gt;-->
        <!--                    <mat-form-field style="width: 20%; margin-right: 1%">-->
        <!--                        <input matInput #materialInput [matAutocomplete]="autoMaterial" (blur)="materialBlur()"-->
        <!--                               [formControl]="materialCtrl" placeholder="Artikel auswählen" autofocus/>-->
        <!--                        <mat-autocomplete panelWidth="500px" #autoMaterial="matAutocomplete"-->
        <!--                                          [displayWith]="materialMapper" (optionSelected)="materialSelected($event)">-->
        <!--                            <mat-option *ngFor="let m of materials | async" [value]="m">-->
        <!--                                <span>{{ m.artikel_me }}</span>-->
        <!--                            </mat-option>-->
        <!--                        </mat-autocomplete>-->
        <!--                    </mat-form-field>-->

        <!--                    &lt;!&ndash; Tierart &ndash;&gt;-->
        <!--                    <mat-form-field style="width: 7%; margin-right: 1%">-->
        <!--                        <mat-select placeholder="Tierart" [formControl]="tierartCtrl"-->
        <!--                                    (selectionChange)="tierartSelected($event)" [compareWith]="tierartComparator">-->
        <!--                            <mat-option *ngFor="let t of tierarten" [value]="t">{{ t.tierart }}</mat-option>-->
        <!--                        </mat-select>-->
        <!--                    </mat-form-field>-->

        <!--                    &lt;!&ndash; Anwendung &ndash;&gt;-->
        <!--                    <mat-form-field style="width: 7%; margin-right: 1%">-->
        <!--                        <mat-select placeholder="Anwendung" [formControl]="anwendungCtrl"-->
        <!--                                    [compareWith]="anwendungComparator">-->
        <!--                            <mat-option *ngFor="let a of anwendungen" [value]="a">{{ a.anwendung }}</mat-option>-->
        <!--                        </mat-select>-->
        <!--                    </mat-form-field>-->

        <!--                    &lt;!&ndash; Diagnose &ndash;&gt;-->
        <!--                    <mat-form-field style="width: 7%; margin-right: 1%">-->
        <!--                        <input matInput #materialInput [matAutocomplete]="autoIndication" [formControl]="indicationCtrl"-->
        <!--                               placeholder="Diagnose"/>-->
        <!--                        <mat-autocomplete panelWidth="250px" #autoIndication="matAutocomplete"-->
        <!--                                          [displayWith]="indicationMapper">-->
        <!--                            <mat-option *ngFor="let ind of indications | async" [value]="ind">-->
        <!--                                <span>{{ ind.diagnose }}</span>-->
        <!--                            </mat-option>-->
        <!--                        </mat-autocomplete>-->
        <!--                    </mat-form-field>-->

        <!--                    &lt;!&ndash; Menge &ndash;&gt;-->
        <!--                    <mat-form-field style="width: 7%; margin-right: 1%">-->
        <!--                        <input type="number" #amountInput matInput [formControl]="positionAmountCtrl"/>-->
        <!--                        <mat-placeholder>-->
        <!--                            {{ positionAmountPlaceholder }}-->
        <!--                        </mat-placeholder>-->
        <!--                    </mat-form-field>-->

        <!--                    &lt;!&ndash; Anzahl Tiere &ndash;&gt;-->
        <!--                    <mat-form-field style="width: 4%; margin-right: 1%">-->
        <!--                        <input matInput type="number" placeholder="Anz. Tiere" [formControl]="numberAnimalCtrl"/>-->
        <!--                    </mat-form-field>-->

        <!--                    &lt;!&ndash; Dosierung &ndash;&gt;-->
        <!--                    <mat-form-field style="width: 7%; margin-right: 1%">-->
        <!--                        <input matInput #dosingInput type="number" [formControl]="dosingCtrl"/>-->
        <!--                        <mat-placeholder>-->
        <!--                            {{ dosingInputPlaceholder }}-->
        <!--                        </mat-placeholder>-->
        <!--                    </mat-form-field>-->

        <!--                    &lt;!&ndash; Anwendungsdauer &ndash;&gt;-->
        <!--                    <mat-form-field style="width: 4%; margin-right: 1%">-->
        <!--                        <input matInput type="number" placeholder="Anw.-dauer" [formControl]="treatmentDurationCtrl"/>-->
        <!--                    </mat-form-field>-->

        <!--                    &lt;!&ndash; Tiergewicht &ndash;&gt;-->
        <!--                    <mat-form-field style="width: 4%; margin-right: 1%">-->
        <!--                        <input matInput type="text" placeholder="Tiergewicht" [formControl]="animalWeightCtrl"/>-->
        <!--                    </mat-form-field>-->

        <!--                    &lt;!&ndash; Identifikation &ndash;&gt;-->
        <!--                    <mat-form-field style="width: 7%; margin-right: 1%">-->
        <!--                        <input matInput #materialInput [matAutocomplete]="autoIdentity" [formControl]="identityCtrl"-->
        <!--                               placeholder="Identif."/>-->
        <!--                        <mat-autocomplete panelWidth="200px" #autoIdentity="matAutocomplete"-->
        <!--                                          [displayWith]="identityMapper">-->
        <!--                            <mat-option *ngFor="let i of identifikationen | async" [value]="i">-->
        <!--                                <span>{{ i.bezeichnung }}</span>-->
        <!--                            </mat-option>-->
        <!--                        </mat-autocomplete>-->
        <!--                    </mat-form-field>-->

        <!--                    &lt;!&ndash; Wartezeit &ndash;&gt;-->
        <!--                    <mat-form-field style="width: 6%; margin-right: 1%">-->
        <!--                        <input matInput type="text" placeholder="Wartezeit Fl." [formControl]="wartezeitCtrl"/>-->
        <!--                    </mat-form-field>-->

        <!--                    &lt;!&ndash; Selbstimpfer &ndash;&gt;-->
        <!--                    &lt;!&ndash;-->
        <!--          <mat-checkbox *ngIf="materialCtrl.value !== null ? materialCtrl.value.impfung : null" color="primary" style="margin-right: 1%;" [formControl]="impfungCtrl">Selbstimpfer</mat-checkbox>-->
        <!--          &ndash;&gt;-->

        <!--                    <button [disabled]="-->
        <!--                            !positionAmountCtrl.valid ||-->
        <!--                            !materialCtrl.valid ||-->
        <!--                            !tierartCtrl.valid ||-->
        <!--                            !numberAnimalCtrl.valid ||-->
        <!--                            (!animalWeightCtrl.valid &&-->
        <!--                                animalWeightCtrl.enabled) ||-->
        <!--                            !treatmentDurationCtrl.valid-->
        <!--                        " mat-flat-button color="accent">-->
        <!--                        speichern-->
        <!--                    </button>-->
        <!--                </form>-->
        <!--            </div>-->
        <!--        </mat-card>-->

        <h4>Positionen</h4>
        <mat-card>
            <div style="width: 100%; font-size: 12px" *ngIf="behandlungsanfrage.positionen.length > 0">
                <div class="flex-grid position">
                    <div class="pos-head">Pos.</div>
                    <div class="artikel-head">Artikel</div>
                    <div class="ean-head">Tierart</div>
                    <div class="amount-head">Anwendung</div>
                    <div class="amount-head">Diagnose</div>
                    <div class="amount-head">Menge</div>
                    <div class="amount-head">Anzahl Tiere</div>
                    <div class="amount-head">Dosierung</div>
                    <div class="amount-head">Anwendungsdauer</div>
                    <div class="amount-head">Tiergewicht</div>
                    <div class="amount-head">Identifikation</div>
                    <div class="amount-head">Wartezeit Fl.</div>
                    <div class="remove-head"></div>
                </div>
            </div>
            <div style="width: 100%; font-size: 12px" *ngFor="let p of behandlungsanfrage.positionen; let i = index">
                <div class="flex-grid position" (click)="aktuellePositionEditieren(p)">
                    <div class="pos-col">{{ i + 1 }}.</div>
                    <div class="artikel-col">{{ p.artikel_me }}</div>
                    <div class="ean-col">{{ p.tierart }}</div>
                    <div class="amount-col">{{ p.anwendung }}</div>
                    <div class="amount-col">{{ p.diagnose }}</div>
                    <div class="amount-col">{{ p.artikelmenge }}</div>
                    <div class="amount-col">{{ p.anzahl_tiere }}</div>
                    <div class="amount-col">{{ p.dosierung }}</div>
                    <div class="amount-col">{{ p.anwendungsdauer }}</div>
                    <div class="amount-col">{{ p.tiergewicht }}</div>
                    <div class="amount-col">{{ p.identifikation }}</div>
                    <div class="amount-col">{{ p.wartezeit }}</div>
                    <div class="remove-col">
                        <button *ngIf="canEditBehandlungsanfrage()" mat-icon-button (click)="removePosition(p)">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <mat-icon *ngIf="p.artikelmenge_ist >= p.artikelmenge">check</mat-icon>
                    </div>
                </div>
                <div><span>Gruppe: {{p.gruppe}}</span></div>
            </div>
        </mat-card>
        <div *ngIf="
                behandlungsanfrage?.positionen?.length > 0 &&
                behandlungsanfrage?.status_id == 1
            " style="width: 100%; text-align: right">
            <button mat-raised-button color="primary" style="margin-top: 20px" (click)="save(2)">
                bestellen
            </button>
        </div>
        <div *kienePermission="[1167]">
            <div *ngIf="
                    behandlungsanfrage?.positionen?.length > 0 &&
                    behandlungsanfrage?.status_id == 2
                " style="width: 100%; text-align: right">
                <button [disabled]="!releaseAllowed" mat-flat-button color="accent" style="margin-top: 20px"
                    (click)="save(3)">
                    freigeben
                </button>
            </div>
        </div>
    </div>
</div>