import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AbhollistenComponent } from "./abhollisten/abhollisten.component";
import { AktuelleGruppenComponent } from './aktuelle-gruppen/aktuelle-gruppen.component';
import { GruppeDetailsComponent } from './gruppe-details/gruppe-details.component';
import { GruppenComponent } from './gruppen/gruppen.component';
import { HistorieGruppenComponent } from './historie-gruppen/historie-gruppen.component';
import { GlobaleTiersucheComponent } from './gruppen/globale-tiersuche/globale-tiersuche.component';
import { GruppenDashboardComponent } from './gruppen-dashboard/gruppen-dashboard/gruppen-dashboard.component';
import { HITMeldungenComponent } from './hitmeldungen/hitmeldungen.component';
import { AktuelleGruppenSchweineComponent } from './schweine/aktuelle-gruppen-schweine/aktuelle-gruppen-schweine.component';
import { GruppeSchweineDetailsComponent } from './schweine/gruppe-schweine-details/gruppe-schweine-details.component';
import { AnfrageDetailsComponent } from './schweine/anfrage-details/anfrage-details.component';
import { ZeitslotHistorieComponent } from './schweine/zeitslot-historie/zeitslot-historie.component';
import { ZeitslotBuchenComponent } from './schweine/zeitslot-buchen/zeitslot-buchen.component';
import { ZeitslotBuchungAendernComponent } from './schweine/zeitslot-buchung-aendern/zeitslot-buchung-aendern.component';


const routes: Routes = [
    {
        path: '', component: GruppenComponent, children: [
            { path: 'dashboard', component: GruppenDashboardComponent },
            { path: 'aktuelle', component: AktuelleGruppenComponent },
            { path: 'aktuelle/:id', component: GruppeDetailsComponent },
            { path: 'historie', component: HistorieGruppenComponent },
            { path: 'historie/:id', component: GruppeDetailsComponent },
            { path: 'abhollisten', component: AbhollistenComponent },
            { path: 'globale-suche', component: GlobaleTiersucheComponent },
            { path: 'hitmeldungen', component: HITMeldungenComponent },
            {
                path: 'tierlisten',
                loadChildren: () => import('./tierlisten/tierlisten.module').then(m => m.TierlistenModule)
            },
            //schweine pages
            { path: 'schweine-gruppen', component: AktuelleGruppenSchweineComponent },
            { path: 'schweine-gruppen/:id', component: GruppeSchweineDetailsComponent },
            { path: 'anfrage-details/:id', component: AnfrageDetailsComponent },
            { path: 'zeitslot-historie', component: ZeitslotHistorieComponent },
            { path: 'zeitslot-buchen/:gruppen_id', component: ZeitslotBuchenComponent },
            { path: 'zeitslot-buchung-aendern/:zeitslot_buchung_id', component: ZeitslotBuchungAendernComponent },
            { path: '**', redirectTo: 'aktuelle' }

        ]
    },
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class GruppenRoutingModule {
}
