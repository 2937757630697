<div style="padding: 0 24px 64px 24px">
    <div class="one-row" style="margin: 12px 0;">
        <kiene-page-header [showReturnButton]="showReturnButton" [ueberschrift]="'Meine Kontakte'"
                           [routeRelativeAlternative]="'../../'"></kiene-page-header>
        <span class="spacer"></span>
        <!--        <button (click)="kontaktAnlegen()" color="accent" mat-icon-button>-->
        <!--            <mat-icon>add</mat-icon>-->
        <!--        </button>-->
    </div>


    <ng-container *ngFor="let k of kontakte">

        <div class="one-row">
            <h3>{{ k?.bezeichnung }}</h3>
            <span class="spacer"></span>
            <!--            <button mat-icon-button color="accent" [matMenuTriggerFor]="kontakteMenu">-->
            <!--                <mat-icon>more_vert</mat-icon>-->
            <!--            </button>-->
            <!--            <mat-menu #kontakteMenu>-->
            <!--                <button mat-menu-item (click)="kontaktEditieren(k)">-->
            <!--                    <mat-icon>edit</mat-icon>-->
            <!--                    <span>bearbeiten</span>-->
            <!--                </button>-->
            <!--                <button mat-menu-item (click)="kontaktEntfernen(k)">-->
            <!--                    <mat-icon>delete</mat-icon>-->
            <!--                    <span>entfernen</span>-->
            <!--                </button>-->
            <!--            </mat-menu>-->
        </div>

        <div style="margin-bottom: 48px;">
            <kiene-key-value-list>
                <kiene-key-value-list-item key="VVVO" [value]="k.vvvo"></kiene-key-value-list-item>
                <kiene-key-value-list-item key="Stra&szlig;e Nr." [value]="k.strasse"></kiene-key-value-list-item>
                <kiene-key-value-list-item key="PLZ Ort" [value]="k.plz + ' ' + k.ort"></kiene-key-value-list-item>
                <kiene-key-value-list-item key="E-Mail" [value]="k.email"
                                           [actionButtonIcon]="k.email ? 'mail' : undefined"
                                           (actionClicked)="sendMail(k)"></kiene-key-value-list-item>
            </kiene-key-value-list>
        </div>
    </ng-container>

</div>
