<h1 mat-dialog-title>HIT/QS Meldungen generieren</h1>
<div mat-dialog-content>
    <p>Bitte geben Sie an, aus welchen Abgabebelegen die Meldungen generiert werden sollen.</p>
    <mat-radio-group class="example-radio-group" [formControl]="radioCtrl">
        <mat-radio-button [value]="1">Alle</mat-radio-button>
        <mat-radio-button [value]="2">Alle bis:
            <mat-form-field appearance="fill">
                <mat-label>Datum</mat-label>
                <input matInput [matDatepicker]="picker" [formControl]="datumCtrl">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </mat-radio-button>

    </mat-radio-group>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()" [disabled]="isSaving">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()"
        [disabled]="(radioCtrl.value === 2 && !datumCtrl.valid) || isSaving">Generieren</button>
</div>