import { NgModule } from '@angular/core';
import { KieneCoreComponent } from './kiene-core.component';
import { ReplaceNullWithTextPipe } from './utils/pipes/replace-null-with-text.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { PdfViewerModule as ng2PdfViewerModule } from 'ng2-pdf-viewer';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ClientService } from './components/client/client.service';
import { PdfViewerDesktopService } from './components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';

@NgModule({
    declarations: [KieneCoreComponent, ReplaceNullWithTextPipe],
    imports: [
        MatDialogModule,
        MatButtonModule,
        ng2PdfViewerModule,
        CommonModule,
        MatIconModule,
        MatCardModule,
        MatDividerModule,
        MatSliderModule,
        MatCheckboxModule,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
    ],

    providers: [ClientService, PdfViewerDesktopService],

    exports: [KieneCoreComponent, ReplaceNullWithTextPipe],
})
export class KieneCoreModule {}
