import {Component, Inject, OnInit} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {formatDate} from '@angular/common';
import {KundeArtikelReportInput} from '../unternehmensgruppe-einzel-report.component';
import {
    KieneBackendApiService
} from '../../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {Subscription} from 'rxjs';
import {MessageService} from '../../../../services/message.service';
import {FormControl} from '@angular/forms';
import {Client} from '../../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {ReportService} from '../../../report.service';

export class KundeArtikel {
    abgabebelege: number;
    artikel: string;
    artikel_id: number;
    artikelmenge: number;
    rohertrag: number;
    rohertrag_prozent: number;
    umsatz: number;
    umsatz_prozent: number;
    wareneinsatz: number;
    wareneinsatz_prozent: number;
}

@Component({
    selector: 'kiene-kunde-artikel-report-dialog',
    templateUrl: './kunde-artikel-report-dialog.component.html',
    styleUrls: ['./kunde-artikel-report-dialog.component.scss']
})
export class KundeArtikelReportDialogComponent implements OnInit {
    rabattCtrl = new FormControl();
    ergaenzerCtrl = new FormControl();
    kunde: Client;
    artikelData: KundeArtikel[] = [];
    isLoading = false;
    private sub: Subscription;

    constructor(@Inject(MAT_DIALOG_DATA) public data: KundeArtikelReportInput,
                public dialogRef: MatDialogRef<KundeArtikelReportDialogComponent>,
                private api: KieneBackendApiService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private messageService: MessageService,
                private reportService: ReportService) {
    }

    ngOnInit(): void {
        this.ladeKunde();
        this.loadData();

        this.ergaenzerCtrl.valueChanges.subscribe({
            next: () => {
                this.loadData();
            }
        });

        this.rabattCtrl.valueChanges.subscribe({
            next: () => {
                this.loadData();
            }
        });
    }

    rueckverguetung() {
        this.reportService.rueckverguetung(
            this.data.kunde_id,
            this.data.von,
            this.data.bis
        );
    }

    private loadData() {
        this.isLoading = true;
        let params = new HttpParams();
        params = params.set('rabatt', this.rabattCtrl.value ? '1' : '0');
        params = params.set('ergaenzer', this.ergaenzerCtrl.value ? '1' : '0');
        params = params.set(
            'kunde_id',
            this.data.kunde_id
        );
        params = params.set(
            'von', formatDate(this.data.von, 'yyyy-MM-dd', 'de')
        );
        params = params.set(
            'bis', formatDate(this.data.bis, 'yyyy-MM-dd', 'de')
        );

        this.sub?.unsubscribe();
        this.sub = this.api
            .get(
                this.apiBaseUrl + 'report/read_kunde_kunde.php',
                params
            )
            .subscribe({
                next: (response) => {
                    this.artikelData = response.records;
                    this.artikelData = this.artikelData.sort((a: KundeArtikel, b: KundeArtikel) => {
                        if (a.umsatz > b.umsatz) {
                            return -1;
                        } else if (a.umsatz < b.umsatz) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    this.isLoading = false;
                },
                error: (error) => {
                    this.isLoading = false;
                    this.messageService.errorMessage('Fehler!', error);
                }
            });
    }

    private ladeKunde() {
        const params = new HttpParams().set('kunde_id', this.data.kunde_id);
        this.api.get(this.apiBaseUrl + 'kunde/read_one.php', params).subscribe({
            next: response => {
                this.kunde = response;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}
