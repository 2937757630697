import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import { StoragePlace } from '../../warehouse/storage-place/storage-place';
import { StoragePlaceService } from '../../warehouse/storage-place/storage-place.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'kiene-storage-place-autocomplete',
  templateUrl: './storage-place-autocomplete.component.html',
  styleUrls: ['./storage-place-autocomplete.component.scss']
})
export class StoragePlaceAutocompleteComponent implements OnInit {

  storagePlaceFormCtrl: UntypedFormControl = new UntypedFormControl();
  storagePlaces: Observable<StoragePlace[]>;

  @Input('defaultValue') defaultValue = '';
  @Output() selectedStoragePlace = new EventEmitter<StoragePlace>();

  constructor(private storagePlaceService: StoragePlaceService) { }

  ngOnInit() {
    this.autocompleteStoragePlace();
  }

  autocompleteStoragePlace() {
    this.storagePlaceFormCtrl = new UntypedFormControl();
    this.storagePlaces = this.storagePlaceFormCtrl.valueChanges.pipe(
      startWith(''),
      switchMap(value => this.searchStoragePlace(value))
    );
  }

  searchStoragePlace(value: string) {
    console.debug('Mat-Autocomplete Search for: '+ value);
    return this.storagePlaceService.searchStoragePlaces(value).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(response => response.records)
    );
  }

  storagePlaceMapper(storagePlace) {
    if (storagePlace) {
      return storagePlace.bezeichnung;
    }
    return this.defaultValue;
  }

  autocompleteStoragePlaceSelected(event: MatAutocompleteSelectedEvent) {
    console.debug('StoragePlace selected: ' + event.option.value.bezeichnung);
    const storagePlace = event.option.value;
    this.selectedStoragePlace.emit(storagePlace);
  }

}
