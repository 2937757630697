import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'kiene-report-overview-value-card',
    templateUrl: './report-overview-value-card.component.html',
    styleUrls: ['./report-overview-value-card.component.css']
})
export class ReportOverviewValueCardComponent implements OnInit {

    @Input('label') label: string;
    @Input('numberValue') numberValue = 0;
    @Input('numberValueVorjahr') numberValueVorjahr = 0;
    @Input('numberFormat') numberFormat = '1.0-2';
    @Input('unit') unit: string;
    @Input('prefixIcon') prefixIcon: string;
    @Input('secondRow') secondRow: string;
    @Input('secondRowVorjahr') secondRowVorjahr: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
