import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Information} from '../information';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {MessageService} from '../../../services/message.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'kiene-add-information-dialog',
    templateUrl: './add-information-dialog.component.html',
    styleUrls: ['./add-information-dialog.component.scss']
})
export class AddInformationDialogComponent implements OnInit {


    information = new Information();

    constructor(private dialogRef: MatDialogRef<AddInformationDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
                private api: KieneTableService,
                private messageService: MessageService) {
        if (data && data.information) {
            this.information = data.information;
        } else {
            this.information.gueltig_von = new Date();
        }
        if (data && data.informationstyp) {
            this.information.informationstyp = data.informationstyp;
        }
    }

    ngOnInit() {
    }

    save() {
        this.api.updateEntity(environment.BASE_URI + 'information/update.php', this.information).subscribe(response => {
            this.messageService.infoMessage('Neue Information erfolgreich gespeichert!');
            this.dialogRef.close(response);
        }, error => {
            this.messageService.errorMessage('Die Information konnte nicht gespeichert werden!', error);
        });
    }

    cancel() {
        this.dialogRef.close(false);
    }

}
