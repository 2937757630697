<mat-form-field *ngIf="!hideOnEmptyList || this.haendlerFuerGruppe.length > 0"
                class="abgang-form-input no-padding-margin-mat-form-field" style="margin: 6px 0 0 0; width: 100%">
    <mat-icon matPrefix style="padding-right: 6px">search</mat-icon>
    <mat-label>{{label}}</mat-label>
    <mat-select (selectionChange)="selectionChanged()" [compareWith]="compareHaendler" [formControl]="selectedHaendlerCtrl"
                multiple>
        <mat-option *ngFor="let hndl of haendlerFuerGruppe" [value]="hndl">{{hndl.name}} ({{hndl.anzahl
            }} Tiere)
        </mat-option>
    </mat-select>
    <button (click)="resetHaendlerCtrl($event)" *ngIf="selectedHaendlerCtrl.value && haendlerFuerGruppe.length > 0" aria-label="Clear"
            mat-icon-button matSuffix>
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
