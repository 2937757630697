import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Entsorger} from '../entsorger';

@Component({
    selector: 'kiene-entsorger-details-dialog',
    templateUrl: './entsorger-details-dialog.component.html',
    styleUrls: ['./entsorger-details-dialog.component.scss']
})
export class EntsorgerDetailsDialogComponent implements OnInit {

    entsorger: Entsorger;

    constructor(
        public dialogRef: MatDialogRef<EntsorgerDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        if (this.data?.entsorger) {
            this.entsorger = this.data.entsorger;
        }
    }

    ngOnInit(): void {
    }
}
