import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatInput} from '@angular/material/input';
import {FormControl} from '@angular/forms';
import {Gruppe} from '../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import {Observable} from 'rxjs';
import {GruppenService} from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import {debounceTime, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

@Component({
    selector: 'kiene-client-group-autocomplete',
    templateUrl: './client-group-autocomplete.component.html',
    styleUrls: ['./client-group-autocomplete.component.scss']
})
export class ClientGroupAutocompleteComponent implements OnInit, AfterViewInit, OnChanges {

    @ViewChild('inputGruppeAutocomplete') inputGruppeAutocomplete: MatInput;

    @Input('formControl') formControl = new FormControl();

    @Input('gruppe') gruppe: Gruppe;

    @Input('disabled') disabled = false;

    @Input('focus') focus = false;

    @Output('selectedGroup') selectedGroup = new EventEmitter();

    gruppen: Observable<Gruppe[]>;

    constructor(private gruppenService: GruppenService) {
    }

    ngOnInit() {
        this.autocompleteGruppen();
        if (this.gruppe) {
            this.formControl.setValue(this.gruppe);
        }
    }

    ngOnChanges() {
        if (this.gruppe) {
            this.formControl.setValue(this.gruppe);
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.focus) {
                this.inputGruppeAutocomplete?.focus();
            }
            if (this.gruppe) {
                this.formControl.setValue(this.gruppe);
            } else {
            }

            if (this.disabled) {
                this.formControl.disable();
            }
        }, 500);
    }

    autocompleteGruppen() {
        this.gruppen = this.formControl.valueChanges.pipe(
            startWith(''),
            switchMap((value) => this.searchGruppen(value))
        );
    }

    searchGruppen(value: string) {
        return this.gruppenService.searchGruppenPraxissoftware(value).pipe(
            debounceTime(300),
            distinctUntilChanged(),
            map((response) => response.records)
        );
    }

    gruppeMapper(gruppe?: Gruppe) {
        if (!gruppe || gruppe.kunde === undefined) {
            return '';
        }
        const text =
            gruppe.kunde +
            ' - ' +
            (String(gruppe.gruppe_nr_intern) === ''
                ? gruppe.kunde_nr_intern
                : gruppe.gruppe_nr_intern) +
            ' - ' +
            gruppe.bezeichnung +
            ' - ' +
            gruppe.zusatz +
            ' - ' +
            gruppe.unternehmensgruppe;
        return text ? text : undefined;
    }

    emitSelectedGroup(event: MatAutocompleteSelectedEvent) {
        const gruppe = event.option.value;
        this.selectedGroup.emit(gruppe);
    }
}
