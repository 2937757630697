<div>
    <button (click)="ladeGruppenStandorte()" mat-flat-button color="accent" style="margin-top: 24px;"
        [disabled]="gruppeStandortLoading">
        <mat-icon>refresh</mat-icon>
        <span>&nbsp;Aktualisieren</span>
    </button>
    <ng-container *ngFor="let a of auswertungen">
        <mat-expansion-panel style="margin-top: 24px;" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div style="display: inline-flex" class="one-row">
                        <mat-spinner *ngIf="a.isLoading || a.isLoadingGefiltert" [diameter]="20"></mat-spinner>
                        <mat-icon *ngIf="!a.isLoading && a.erfolgreich"
                            [matTooltip]="'#' + a.gruppeStandort?.gs_id?.toString()">info</mat-icon>
                        <mat-icon *ngIf="!a.isLoading && !a.erfolgreich">disabled_by_default</mat-icon>
                        <span style="margin-left: 6px; padding-top: 4px">
                            {{a.gruppeStandort?.produktionsabschnitt}}
                            {{(a.gruppeStandort?.datum_von | date: 'dd.MM.yy') || '---'}} {{a.gruppeStandort?.datum_bis
                            ? (' - ' +
                            (a.gruppeStandort?.datum_bis | date: 'dd.MM.yy')) : ''}}
                        </span>
                        <span class="spacer"></span>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <table>
                <tr>
                    <td>
                    </td>
                    <td style="text-align: center; font-weight: bold;">{{userIstLandwirt ? 'Meine Betriebe' :
                        'Unternehmensgruppe' }}</td>
                    <td style="text-align: center">vs.</td>
                    <td style="text-align: center; font-weight: bold;">Betrieb</td>
                    <td style="text-align: center">vs.</td>
                    <td style="text-align: center; font-weight: bold;">akt. Gruppe</td>
                    <td style="text-align: center">vs.</td>
                    <td style="text-align: center; font-weight: bold;">Gefiltert</td>
                </tr>
                <ng-container *kienePermission="[9161]">
                    <tr>
                        <td class="tdlabel">&Oslash; Einstallgewicht:</td>

                        <td class="tdvergleich">
                            <sub>({{a.unternehmensgruppe?.einstallgewicht_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{
                                (a.unternehmensgruppe?.einstallgewicht_avg?.wert |
                                number:'1.2-2':'de') || "---" }} kg</span>
                        </td>
                        <td class="tdicon">
                            <mat-icon
                                [ngClass]="getClass(a.unternehmensgruppe?.einstallgewicht_avg,a.betrieb?.einstallgewicht_avg,2)">arrow_circle_right</mat-icon>
                        </td>

                        <td class="tdvergleich">
                            <sub>({{a.betrieb?.einstallgewicht_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{
                                (a.betrieb?.einstallgewicht_avg?.wert |
                                number:'1.2-2':'de') || "---" }} kg</span>
                        </td>
                        <td class="tdicon">
                            <mat-icon
                                [ngClass]="getClass(a.betrieb?.einstallgewicht_avg,a.gruppe?.einstallgewicht_avg,2)">arrow_circle_right</mat-icon>
                        </td>
                        <td class="tdvergleich">
                            <sub>({{a.gruppe?.einstallgewicht_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{ (a.gruppe?.einstallgewicht_avg?.wert |
                                number:'1.2-2':'de') || "---" }} kg</span>
                        </td>
                        <td class="tdicon">
                            <mat-icon
                                [ngClass]="getClass(a.gruppe?.einstallgewicht_avg,a.gefiltert?.einstallgewicht_avg,2)">arrow_circle_right</mat-icon>
                        </td>
                        <td class="tdvergleich">
                            <sub>({{a.gefiltert?.einstallgewicht_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{ (a.gefiltert?.einstallgewicht_avg?.wert |
                                number:'1.2-2':'de') || "---" }} kg</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="tdlabel">&Oslash; Abgangsgewicht:</td>
                        <td class="tdvergleich">
                            <sub>({{a.unternehmensgruppe?.abgangsgewicht_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{
                                (a.unternehmensgruppe?.abgangsgewicht_avg?.wert |
                                number:'1.2-2':'de') || "---" }} kg</span>
                        </td>
                        <td class="tdicon">
                            <mat-icon
                                [ngClass]="getClass(a.unternehmensgruppe?.abgangsgewicht_avg,a.betrieb?.abgangsgewicht_avg,2)">arrow_circle_right</mat-icon>
                        </td>
                        <td class="tdvergleich">
                            <sub>({{a.betrieb?.abgangsgewicht_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{
                                (a.betrieb?.abgangsgewicht_avg?.wert |
                                number:'1.2-2':'de') || "---" }} kg</span>
                        </td>
                        <td class="tdicon">
                            <mat-icon
                                [ngClass]="getClass(a.betrieb?.abgangsgewicht_avg,a.gruppe?.abgangsgewicht_avg,2)">arrow_circle_right</mat-icon>
                        </td>
                        <td class="tdvergleich">
                            <sub>({{a.gruppe?.abgangsgewicht_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{ (a.gruppe?.abgangsgewicht_avg?.wert |
                                number:'1.2-2':'de') || "---" }} kg</span>
                        </td>
                        <td class="tdicon">
                            <mat-icon
                                [ngClass]="getClass(a.gruppe?.abgangsgewicht_avg,a.gefiltert?.abgangsgewicht_avg,2)">arrow_circle_right</mat-icon>
                        </td>
                        <td class="tdvergleich">
                            <sub>({{a.gefiltert?.abgangsgewicht_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{ (a.gefiltert?.abgangsgewicht_avg?.wert |
                                number:'1.2-2':'de') || "---" }} kg</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="tdlabel">&Oslash; Tageszunahmen:</td>
                        <td class="tdvergleich">
                            <sub>({{a.unternehmensgruppe?.tageszunahmen_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{
                                (a.unternehmensgruppe?.tageszunahmen_avg?.wert |
                                number:'1.2-2':'de') || "---" }} kg</span>
                        </td>
                        <td class="tdicon">
                            <mat-icon
                                [ngClass]="getClass(a.unternehmensgruppe?.tageszunahmen_avg,a.betrieb?.tageszunahmen_avg,2)">arrow_circle_right</mat-icon>
                        </td>
                        <td class="tdvergleich">
                            <sub>({{a.betrieb?.tageszunahmen_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{ (a.betrieb?.tageszunahmen_avg?.wert
                                |
                                number:'1.2-2':'de') || "---" }} kg</span>
                        </td>
                        <td class="tdicon">
                            <mat-icon
                                [ngClass]="getClass(a.betrieb?.tageszunahmen_avg,a.gruppe?.tageszunahmen_avg,2)">arrow_circle_right</mat-icon>
                        </td>
                        <td class="tdvergleich">
                            <sub>({{a.gruppe?.tageszunahmen_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{ (a.gruppe?.tageszunahmen_avg?.wert |
                                number:'1.2-2':'de') || "---" }} kg</span>
                        </td>
                        <td class="tdicon">
                            <mat-icon
                                [ngClass]="getClass(a.gruppe?.tageszunahmen_avg,a.gefiltert?.tageszunahmen_avg,2)">arrow_circle_right</mat-icon>
                        </td>
                        <td class="tdvergleich">
                            <sub>({{a.gefiltert?.tageszunahmen_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{ (a.gefiltert?.tageszunahmen_avg?.wert |
                                number:'1.2-2':'de') || "---" }} kg</span>
                        </td>
                    </tr>
                    <ng-container *ngIf="a.letzterDatensatz">
                        <tr>
                            <td class="tdlabel">&Oslash; Schlachtgewicht &lt;12M:</td>
                            <td class="tdvergleich">
                                <sub>({{a.unternehmensgruppe?.schlachtgewicht_avg_unter12?.anzahl ||
                                    0}})</sub>
                                <span class="vergleichwert">{{
                                    (a.unternehmensgruppe?.schlachtgewicht_avg_unter12?.wert |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.unternehmensgruppe?.schlachtgewicht_avg_unter12,a.betrieb?.schlachtgewicht_avg_unter12,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.betrieb?.schlachtgewicht_avg_unter12?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{
                                    (a.betrieb?.schlachtgewicht_avg_unter12?.wert
                                    |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.betrieb?.schlachtgewicht_avg_unter12,a.gruppe?.schlachtgewicht_avg_unter12,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.gruppe?.schlachtgewicht_avg_unter12?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.gruppe?.schlachtgewicht_avg_unter12?.wert |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.gruppe?.schlachtgewicht_avg_unter12,a.gefiltert?.schlachtgewicht_avg_unter12,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.gefiltert?.schlachtgewicht_avg_unter12?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.gefiltert?.schlachtgewicht_avg_unter12?.wert |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="tdlabel">&Oslash; Schlachtgewicht &gt;12M:</td>
                            <td class="tdvergleich">
                                <sub>({{a.unternehmensgruppe?.schlachtgewicht_avg_ueber12?.anzahl ||
                                    0}})</sub>
                                <span class="vergleichwert">{{
                                    (a.unternehmensgruppe?.schlachtgewicht_avg_ueber12?.wert |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.unternehmensgruppe?.schlachtgewicht_avg_ueber12,a.betrieb?.schlachtgewicht_avg_ueber12,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.betrieb?.schlachtgewicht_avg_ueber12?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{
                                    (a.betrieb?.schlachtgewicht_avg_ueber12?.wert
                                    |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.betrieb?.schlachtgewicht_avg_ueber12,a.gruppe?.schlachtgewicht_avg_ueber12,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.gruppe?.schlachtgewicht_avg_ueber12?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.gruppe?.schlachtgewicht_avg_ueber12?.wert |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.gruppe?.schlachtgewicht_avg_ueber12,a.gefiltert?.schlachtgewicht_avg_ueber12,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.gefiltert?.schlachtgewicht_avg_ueber12?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.gefiltert?.schlachtgewicht_avg_ueber12?.wert |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="tdlabel">&Oslash; Nettozunahmen &lt;12M:</td>
                            <td class="tdvergleich">
                                <sub>({{a.unternehmensgruppe?.nettozunahmen_avg_unter12?.anzahl ||
                                    0}})</sub>
                                <span class="vergleichwert">{{
                                    (a.unternehmensgruppe?.nettozunahmen_avg_unter12?.wert |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.unternehmensgruppe?.nettozunahmen_avg_unter12,a.betrieb?.nettozunahmen_avg_unter12,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.betrieb?.nettozunahmen_avg_unter12?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.betrieb?.nettozunahmen_avg_unter12?.wert
                                    |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.betrieb?.nettozunahmen_avg_unter12,a.gruppe?.nettozunahmen_avg_unter12,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.gruppe?.nettozunahmen_avg_unter12?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.gruppe?.nettozunahmen_avg_unter12?.wert |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.gruppe?.nettozunahmen_avg_unter12,a.gefiltert?.nettozunahmen_avg_unter12,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.gefiltert?.nettozunahmen_avg_unter12?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.gefiltert?.nettozunahmen_avg_unter12?.wert |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="tdlabel">&Oslash; Nettozunahmen &gt;12M:</td>
                            <td class="tdvergleich">
                                <sub>({{a.unternehmensgruppe?.nettozunahmen_avg_ueber12?.anzahl ||
                                    0}})</sub>
                                <span class="vergleichwert">{{
                                    (a.unternehmensgruppe?.nettozunahmen_avg_ueber12?.wert |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.unternehmensgruppe?.nettozunahmen_avg_ueber12,a.betrieb?.nettozunahmen_avg_ueber12,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.betrieb?.nettozunahmen_avg_ueber12?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.betrieb?.nettozunahmen_avg_ueber12?.wert
                                    |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.betrieb?.nettozunahmen_avg_ueber12,a.gruppe?.nettozunahmen_avg_ueber12,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.gruppe?.nettozunahmen_avg_ueber12?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.gruppe?.nettozunahmen_avg_ueber12?.wert |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.gruppe?.nettozunahmen_avg_ueber12,a.gefiltert?.nettozunahmen_avg_ueber12,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.gefiltert?.nettozunahmen_avg_ueber12?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.gefiltert?.nettozunahmen_avg_ueber12?.wert |
                                    number:'1.2-2':'de') || "---" }} kg</span>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                <ng-container *kienePermission="[9162]">
                    <ng-container *ngIf="a.ersterDatensatz">
                        <tr>
                            <td class="tdlabel">&Oslash; Einkaufspreise:</td>
                            <td class="tdvergleich">
                                <sub>({{a.unternehmensgruppe?.einkaufspreis_avg?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{
                                    (a.unternehmensgruppe?.einkaufspreis_avg?.wert |
                                    number:'1.2-2':'de') || "---" }} €</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.unternehmensgruppe?.einkaufspreis_avg,a.betrieb?.einkaufspreis_avg,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.betrieb?.einkaufspreis_avg?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{
                                    (a.betrieb?.einkaufspreis_avg?.wert |
                                    number:'1.2-2':'de') || "---" }} €</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.betrieb?.einkaufspreis_avg,a.gruppe?.einkaufspreis_avg,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.gruppe?.einkaufspreis_avg?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.gruppe?.einkaufspreis_avg?.wert |
                                    number:'1.2-2':'de') || "---" }} €</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.gruppe?.einkaufspreis_avg,a.gefiltert?.einkaufspreis_avg,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.gefiltert?.einkaufspreis_avg?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.gefiltert?.einkaufspreis_avg?.wert |
                                    number:'1.2-2':'de') || "---" }} €</span>
                            </td>
                        </tr>
                    </ng-container>
                    <ng-container *ngIf="a.letzterDatensatz">
                        <tr>
                            <td class="tdlabel">&Oslash; Verkaufspreise:</td>
                            <td class="tdvergleich">
                                <sub>({{a.unternehmensgruppe?.verkaufspreis_avg?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{
                                    (a.unternehmensgruppe?.verkaufspreis_avg?.wert |
                                    number:'1.2-2':'de') || "---" }} €</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.unternehmensgruppe?.verkaufspreis_avg,a.betrieb?.verkaufspreis_avg,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.betrieb?.verkaufspreis_avg?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{
                                    (a.betrieb?.verkaufspreis_avg?.wert |
                                    number:'1.2-2':'de') || "---" }} €</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.betrieb?.verkaufspreis_avg,a.gruppe?.verkaufspreis_avg,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.gruppe?.verkaufspreis_avg?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.gruppe?.verkaufspreis_avg?.wert |
                                    number:'1.2-2':'de') || "---" }} €</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.gruppe?.verkaufspreis_avg,a.gefiltert?.verkaufspreis_avg,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.gefiltert?.verkaufspreis_avg?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.gefiltert?.verkaufspreis_avg?.wert |
                                    number:'1.2-2':'de') || "---" }} €</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="tdlabel">&Oslash; Erlösdifferenz:</td>
                            <td class="tdvergleich">
                                <sub>({{a.unternehmensgruppe?.erloesdifferenz_avg?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{
                                    (a.unternehmensgruppe?.erloesdifferenz_avg?.wert |
                                    number:'1.2-2':'de') || "---" }} €</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.unternehmensgruppe?.erloesdifferenz_avg,a.betrieb?.erloesdifferenz_avg,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.betrieb?.erloesdifferenz_avg?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{
                                    (a.betrieb?.erloesdifferenz_avg?.wert |
                                    number:'1.2-2':'de') || "---" }} €</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.betrieb?.erloesdifferenz_avg,a.gruppe?.erloesdifferenz_avg,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.gruppe?.erloesdifferenz_avg?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.gruppe?.erloesdifferenz_avg?.wert |
                                    number:'1.2-2':'de') || "---" }} €</span>
                            </td>
                            <td class="tdicon">
                                <mat-icon
                                    [ngClass]="getClass(a.gruppe?.erloesdifferenz_avg,a.gefiltert?.erloesdifferenz_avg,2)">arrow_circle_right</mat-icon>
                            </td>
                            <td class="tdvergleich">
                                <sub>({{a.gefiltert?.erloesdifferenz_avg?.anzahl || 0}})</sub>
                                <span class="vergleichwert">{{ (a.gefiltert?.erloesdifferenz_avg?.wert |
                                    number:'1.2-2':'de') || "---" }} €</span>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                <ng-container *kienePermission="[9163]">
                    <tr>
                        <td class="tdlabel">&Oslash; Tierarztkosten:</td>
                        <td class="tdvergleich">
                            <sub>({{a.unternehmensgruppe?.tierarztkosten_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{
                                (a.unternehmensgruppe?.tierarztkosten_avg?.wert |
                                number:'1.2-2':'de') || "---" }} €</span>
                        </td>
                        <td class="tdicon">
                            <mat-icon
                                [ngClass]="getClass(a.unternehmensgruppe?.tierarztkosten_avg,a.betrieb?.tierarztkosten_avg,2)">arrow_circle_right</mat-icon>
                        </td>
                        <td class="tdvergleich">
                            <sub>({{a.betrieb?.tierarztkosten_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{
                                (a.betrieb?.tierarztkosten_avg?.wert |
                                number:'1.2-2':'de') || "---" }} €</span>
                        </td>
                        <td class="tdicon">
                            <mat-icon
                                [ngClass]="getClass(a.betrieb?.tierarztkosten_avg,a.gruppe?.tierarztkosten_avg,2)">arrow_circle_right</mat-icon>
                        </td>
                        <td class="tdvergleich">
                            <sub>({{a.gruppe?.tierarztkosten_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{ (a.gruppe?.tierarztkosten_avg?.wert |
                                number:'1.2-2':'de') || "---" }} €</span>
                        </td>
                        <!--
                        <td class="tdicon">
                            <mat-icon
                                [ngClass]="getClass(a.gruppe?.tierarztkosten_avg,a.gefiltert?.tierarztkosten_avg,2)">arrow_circle_right</mat-icon>
                        </td>
                        <td class="tdvergleich">
                            <sub>({{a.gefiltert?.tierarztkosten_avg?.anzahl || 0}})</sub>
                            <span class="vergleichwert">{{ (a.gefiltert?.tierarztkosten_avg?.wert |
                                number:'1.2-2':'de') || "---" }} €</span>
                        </td>
                        -->
                    </tr>
                </ng-container>
            </table>
        </mat-expansion-panel>
    </ng-container>
</div>