<div>
    <mat-tab-group [selectedIndex]="selectedTab" (selectedTabChange)="setSelectedTab($event)">
        <mat-tab>
            <ng-template matTabLabel>
                <kiene-tabinfo (click)="$event.stopPropagation(); alleAbschliessenRind();"
                               #rindTabInfo
                               [urlpfad]="'massnahmenplan_rind/tabinfo.php'" [sendeKundeIdOptional]="true"
                               [sendeKundeId]="true"></kiene-tabinfo>
                <span>&nbsp;Rind</span>
            </ng-template>
            <ng-template matTabContent>
                <kiene-massnahmenplan-rind></kiene-massnahmenplan-rind>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template matTabLabel>
                <kiene-tabinfo (click)="$event.stopPropagation(); alleAbschliessenSchwein();"
                               [urlpfad]="'massnahmenplan_schwein/tabinfo.php'" [sendeKundeId]="true"
                               [sendeKundeIdOptional]="true"></kiene-tabinfo>
                <span>&nbsp;Schwein</span>
            </ng-template>
            <ng-template matTabContent>
                <kiene-massnahmenplan-schwein></kiene-massnahmenplan-schwein>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
