import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { Tierart } from '../../administration/tierarten/tierart';
import { KieneTableService } from '../kiene-table/kiene-table.service';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { MatSelectChange, MatSelect } from '@angular/material/select';
import { UntypedFormControl } from '@angular/forms';
import { MessageService } from '../../services/message.service';

@Component({
    selector: 'kiene-animal-type-selector',
    templateUrl: './animal-type-selector.component.html',
    styleUrls: ['./animal-type-selector.component.scss']
})
export class AnimalTypeSelectorComponent implements OnInit, AfterViewInit {

    tierarten: Tierart[] = [];
    tierartCtrl = new UntypedFormControl();

    @ViewChild('selector') selector: MatSelect;

    @Input('materialId') materialId: number;

    @Input('selectedTierartId') selectedTierartId: number;

    @Input('focused') focused = true;

    @Input('probenbegleitschein') probenbegleitschein = false;

    @Output('tierartSelectionChanged')
    tierartSelectionChanged = new EventEmitter<Tierart>();

    constructor(
        private api: KieneTableService,
        private messageService: MessageService
    ) {
        //
    }

    ngOnInit() {
        this.loadTierartenForMaterial();
    }

    ngAfterViewInit() {
        if (this.selectedTierartId) {
            const tierart = new Tierart();
            tierart.tierart_id = this.selectedTierartId;
            this.tierartCtrl.setValue(tierart);
        }
        setTimeout(() => {
            if (this.focused) {
                this.selector?.focus();
            }
        }, 700);
    }

    loadTierartenForMaterial() {
        if (this.probenbegleitschein) {
            this.api.get(environment.BASE_URI + 'tierart/read_probenbegleitschein.php').subscribe(
                response => {
                    this.tierarten = response.records;
                },
                error => {
                    this.messageService.errorMessage('Tierarten konnten nicht geladen werden!', error);
                }
            )
        } else if (this.materialId != null) {
            let params = new HttpParams().append('artikel_id', this.materialId.toString());
            this.api.get(environment.BASE_URI + 'artikel_tierart/read.php', params).subscribe(
                response => {
                    this.tierarten = response.records;
                },
                error => {
                    this.messageService.errorMessage('Tierarten konnten nicht geladen werden!', error);
                }
            );
        } else {
            this.api.get(environment.BASE_URI + 'tierart/read.php').subscribe(
                response => {
                    this.tierarten = response.records;
                },
                error => {
                    this.messageService.errorMessage('Tierarten konnten nicht geladen werden!', error);
                }
            );
        }
    }

    tierartChanged(event: MatSelectChange) {
        console.debug(event.value);
        this.tierartSelectionChanged.emit(event.value);
    }

    tierartComparator(t1: Tierart, t2: Tierart): boolean {
        if (t1 && t2) {
            return t1.tierart_id === t2.tierart_id;
        }
        return false;
    }

}
