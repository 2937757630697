<h1 mat-dialog-title>{{position ? 'Position editieren' : 'Neue Position erstellen'}}</h1>
<div mat-dialog-content>
    <mat-stepper #stepper [linear]="true" (selectionChange)="stepSelectionChanged($event)">
        <mat-step>
            <ng-template matStepLabel>Tierinformationen {{selectedTierart ? selectedTierart.tierart : ''}}</ng-template>
            <div class="one-row">

                <kiene-animal-type-selector [probenbegleitschein]="true" [focused]="true"
                    [selectedTierartId]="position?.tierart_id"
                    (tierartSelectionChanged)="setTierart($event); tieranzahlInput?.focus();"
                    style="width: 250px;"></kiene-animal-type-selector>
                <span class="spacer"></span>

                <mat-form-field>
                    <mat-label>Anzahl der Tiere</mat-label>
                    <input matInput type="number" placeholder="Tieranzahl" [formControl]="tieranzahlCtrl"
                        (keyup.enter)="tiergewichtInput?.focus()" #tieranzahlInput="matInput">
                </mat-form-field>

                <span class="spacer"></span>

                <mat-form-field>
                    <mat-label>Tiergewicht</mat-label>
                    <input matInput type="text" placeholder="Tiergewicht" [formControl]="tiergewichtCtrl"
                        (keyup.enter)="kennzeichnungInput?.focus()" #tiergewichtInput>
                </mat-form-field>

                <span class="spacer"></span>
                <mat-form-field>
                    <mat-label>Kennzeichnung</mat-label>
                    <input matInput type="text" [formControl]="kennzeichnungCtrl" (keyup.enter)="stepper.next()"
                        #kennzeichnungInput="matInput">
                </mat-form-field>
            </div>
            <div>
                <button mat-button color="accent" matStepperNext>Weiter</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Untersuchungsmaterial
                {{untersuchungsmaterial ? untersuchungsmaterial.bezeichnung : ''}}</ng-template>
            <h4>Untersuchungsmaterialien:</h4>
            <ul style="list-style-type: none; padding: 0; margin: 0">
                <li *ngFor="let u of untersuchungsmaterialien"
                    style="margin-bottom: 6px; padding: 6px; border-bottom: 1px solid #dddddd;">
                    <div class="one-row">
                        <span
                            [style.font-weight]="u.untersuchungsmaterial_id === position?.untersuchungsmaterial_id ? 'bold' : 'normal'">{{u.bezeichnung}}</span>
                        <span class="spacer"></span>
                        <button mat-stroked-button color="accent"
                            (click)="untersuchungsmaterialSelected(u)">ausw&auml;hlen</button>
                    </div>
                </li>
            </ul>

            <div>
                <button mat-button color="accent" matStepperPrevious>Zur&uuml;ck</button>
                <button mat-button color="accent" matStepperNext>Weiter</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Verf&uuml;gbare Artikel</ng-template>

            <div *ngFor="let ak of this.artikelKommentarListe">
                <div class="one-row" style="border-bottom: 1px solid #cccccc; padding-bottom: 6px; padding-top: 6px;">
                    <mat-checkbox style="min-width: 70px;" [formControl]="ak.checkboxCtrl"></mat-checkbox>
                    <div style="width: calc(100% - 70px)">
                        <div class="one-row">
                            <span style="font-weight: bold;">{{ak.artikel?.bezeichnung}}</span>
                            <span class="spacer"></span>
                            <span style="color: #555555;" *ngIf="ak.artikel?.einzelpreis">Preis:&nbsp;</span>
                            <span style="color: #555555;" *ngIf="ak.artikel?.einzelpreis">{{ak.artikel.einzelpreis |
                                number: '1.2-2'}}
                                &euro;</span>
                        </div>
                        <div>
                            <mat-form-field style="min-width: 450px;">
                                <mat-label>Kommentar</mat-label>
                                <input matInput type="text" [formControl]="ak.kommentarCtrl" />
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button mat-button color="accent" matStepperPrevious>Zur&uuml;ck</button>
            </div>

        </mat-step>
    </mat-stepper>
</div>
<mat-dialog-actions>
    <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="!readyToSave" #saveButton>Speichern</button>
</mat-dialog-actions>