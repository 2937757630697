import {NgModule} from '@angular/core';

import {LaboratoryRoutingModule} from './laboratory-routing.module';
import {AntibiogrammComponent, DownloadAllAntibiogrammeDialog,} from './antibiogramm/antibiogramm.component';
import {AntibiogrammDetailsComponent} from './antibiogramm/antibiogramm-details/antibiogramm-details.component';
import {
    BefundmitteilungComponent,
    BefundmitteilungNewNoteDialog,
    BefundmitteilungNewSampleSheetDialog,
    DownloadAllBefundmitteilungDialog,
    DownloadFileInfoDialog,
} from './befundmitteilung/befundmitteilung.component';
import {AntibiogrammService} from './antibiogramm/antibiogramm.service';
import {BefundmitteilungService} from './befundmitteilung/befundmitteilung.service';
import {BehandlungsArtikelComponent} from './behandlungs-artikel/behandlungs-artikel.component';
import {LaboreComponent} from './labore/labore.component';
import {LaborDialogComponent} from './labore/labor-dialog/labor-dialog.component';
import {ProbenbegleitscheinComponent} from './probenbegleitschein/probenbegleitschein.component';
import {
    AddProbenbegleitscheinPositionDialogComponent
} from './probenbegleitschein/add-probenbegleitschein-position-dialog/add-probenbegleitschein-position-dialog.component';
import {
    ProbenbegleitscheinDetailsComponent
} from './probenbegleitschein/probenbegleitschein-details/probenbegleitschein-details.component';
import {
    AddTextkuerzelDialog,
    EditTextkuerzelDialog,
    TextAutocompleteComponent,
} from './text-autocomplete/text-autocomplete.component';
import {UntersuchungsauftragComponent} from './untersuchungsauftrag/untersuchungsauftrag.component';
import {
    UntersuchungsauftragDetailsComponent
} from './untersuchungsauftrag/untersuchungsauftrag-details/untersuchungsauftrag-details.component';
import {UntersuchungsgruppeComponent} from './untersuchungsgruppe/untersuchungsgruppe.component';
import {
    UntersuchungsgruppeDialogComponent
} from './untersuchungsgruppe/untersuchungsgruppe-dialog/untersuchungsgruppe-dialog.component';
import {UntersuchungsmaterialienComponent} from './untersuchungsmaterialien/untersuchungsmaterialien.component';
import {
    AddUntersuchungsmaterialDialogComponent
} from './untersuchungsmaterialien/add-untersuchungsmaterial-dialog/add-untersuchungsmaterial-dialog.component';
import {
    ArtikelstrukturDatabase,
    UntersuchungsmaterialKonfiguratorComponent,
} from './untersuchungsmaterialien/untersuchungsmaterial-konfigurator/untersuchungsmaterial-konfigurator.component';
import {MatTreeModule} from '@angular/material/tree';
import {
    UntersuchungTreeComponent,
    UntersuchungTreeDatabase
} from './probenbegleitschein/add-probenbegleitschein-position-dialog/untersuchung-tree/untersuchung-tree.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatBadgeModule} from '@angular/material/badge';
import {LaboratoryComponent} from './laboratory.component';
import {KieneTableModule} from '../components/kiene-table/kiene-table.module';
import {DetailsseiteComponent} from './befundmitteilung/detailsseite/detailsseite.component';
import {ArticleScanAutocompleteModule} from '../components/article-scan-autocomplete/article-scan-autocomplete.module';
import {DeleteButtonModule} from '../components/delete-button/delete-button.module';
import {
    ExterneBefundeDialogComponent
} from './befundmitteilung/detailsseite/externe-befunde-dialog/externe-befunde-dialog.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TabinfoModule} from '../components/tabinfo/tabinfo.module';
import {KieneAutocompleteModule} from '../components/kiene-autocomplete/kiene-autocomplete.module';
import {IdexxResultDialogComponent} from './befundmitteilung/idexx-result-dialog/idexx-result-dialog.component';
import {CommonModule} from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatChipsModule} from '@angular/material/chips';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {ComponentsModule} from '../components/components.module';
import {MatTableModule} from '@angular/material/table';
import {MatListModule} from '@angular/material/list';
import {
    EditUntersuchungsmaterialDialogComponent
} from './untersuchungsmaterialien/untersuchungsmaterial-konfigurator/edit-untersuchungsmaterial-dialog/edit-untersuchungsmaterial-dialog.component';
import {MatStepperModule} from '@angular/material/stepper';
import {KieneClientAutocompleteModule} from '../components/client-autocomplete/client-autocomlete.module';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from '@angular/material/tooltip';
import {KieneDirectivesModule} from '../directives/kiene-directives.module';
import {DirectivesModule} from '../../../projects/kiene-core/src/lib/directives/directives.module';

@NgModule({
    declarations: [
        LaboratoryComponent,
        AntibiogrammComponent,
        AntibiogrammDetailsComponent,
        BefundmitteilungComponent,
        BehandlungsArtikelComponent,
        LaboreComponent,
        LaborDialogComponent,
        ProbenbegleitscheinComponent,
        AddProbenbegleitscheinPositionDialogComponent,
        ProbenbegleitscheinDetailsComponent,
        TextAutocompleteComponent,
        UntersuchungsauftragComponent,
        UntersuchungsauftragDetailsComponent,
        UntersuchungsgruppeComponent,
        UntersuchungsgruppeDialogComponent,
        UntersuchungsmaterialienComponent,
        AddUntersuchungsmaterialDialogComponent,
        UntersuchungsmaterialKonfiguratorComponent,
        AddTextkuerzelDialog,
        EditTextkuerzelDialog,
        UntersuchungTreeComponent,
        DownloadAllAntibiogrammeDialog,
        DownloadAllBefundmitteilungDialog,
        DownloadFileInfoDialog,
        BefundmitteilungNewSampleSheetDialog,
        BefundmitteilungNewNoteDialog,
        DetailsseiteComponent,
        ExterneBefundeDialogComponent,
        IdexxResultDialogComponent,
        EditUntersuchungsmaterialDialogComponent
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatCardModule,
        MatMenuModule,
        MatChipsModule,
        MatToolbarModule,
        MatExpansionModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatPaginatorModule,
        MatRadioModule,
        MatDividerModule,
        MatBadgeModule,
        MatTreeModule,
        MatTableModule,
        MatListModule,
        KieneAutocompleteModule,
        KieneTableModule,
        LaboratoryRoutingModule,
        ArticleScanAutocompleteModule,
        DeleteButtonModule,
        DragDropModule,
        TabinfoModule,
        ComponentsModule,
        MatStepperModule,
        KieneClientAutocompleteModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatTooltipModule,
        KieneDirectivesModule,
        DirectivesModule
    ],

    providers: [
        AntibiogrammService,
        BefundmitteilungService,
        UntersuchungTreeDatabase,
        ArtikelstrukturDatabase,
    ],

    exports: [
        ProbenbegleitscheinComponent,
        BefundmitteilungComponent,
        AntibiogrammComponent,
    ],
})
export class LaboratoryModule {
}
