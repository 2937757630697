<h1 mat-dialog-title>Dateien hinzuf&uuml;gen</h1>
<div mat-dialog-content>
    <button mat-raised-button color="primary" (click)="openFileSelector()">Dateien ausw&auml;hlen</button>
    <input type="file" style="display: none" #fileSelector multiple (change)="handleFiles($event)"
           [accept]="mimeTypeAccepted">
    <div>
        <mat-list>
            <h4 mat-subheader>Dateien</h4>
            <mat-list-item *ngFor="let file of filesToUpload; let i = index">
                <mat-icon mat-list-icon>insert_drive_file</mat-icon>
                <p mat-line>{{file.name}}
                    <mat-icon color="primary" *ngIf="filesProgressValues[i]">check</mat-icon>
                </p>
            </mat-list-item>
        </mat-list>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button color="primary" (click)="onCancelClick()">Abbrechen</button>
    <button *ngIf="!showCloseButton" mat-button color="primary" (click)="uploadFiles()" [disabled]="isUploading">
        Hochladen
    </button>
    <button *ngIf="showCloseButton" mat-button color="primary" (click)="closeDialog()">Schlie&szlig;en</button>
</div>
