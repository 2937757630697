import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {InventurRoutingModule} from './inventur-routing.module';
import {InventurComponent} from './inventur.component';
import {InventurUebersichtComponent} from './inventur-uebersicht/inventur-uebersicht.component';
import {KieneTableModule} from '../../components/kiene-table/kiene-table.module';
import {
    ArticleScanAutocompleteModule
} from '../../components/article-scan-autocomplete/article-scan-autocomplete.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {KieneAutocompleteModule} from '../../components/kiene-autocomplete/kiene-autocomplete.module';
import {InventurDetailsDialogComponent} from './inventur-details-dialog/inventur-details-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {InventurEditDialogComponent} from './inventur-edit-dialog/inventur-edit-dialog.component';
import {InventurDeleteDialogComponent} from './inventur-delete-dialog/inventur-delete-dialog.component';
import {InventurAuswertungComponent} from './inventur-auswertung/inventur-auswertung.component';
import {AbgelaufeneChargenComponent} from './abgelaufene-chargen/abgelaufene-chargen.component';
import {MatTabsModule} from '@angular/material/tabs';
import {DirectivesModule} from '../../../../projects/kiene-core/src/lib/directives/directives.module';

@NgModule({
    declarations: [
        InventurComponent,
        InventurUebersichtComponent,
        InventurDetailsDialogComponent,
        InventurDeleteDialogComponent,
        InventurEditDialogComponent,
        InventurAuswertungComponent,
        AbgelaufeneChargenComponent,
    ],
    imports: [
        CommonModule,
        InventurRoutingModule,
        KieneTableModule,
        ArticleScanAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        KieneAutocompleteModule,
        MatDialogModule,
        MatDatepickerModule,
        MatSelectModule,
        MatCheckboxModule,
        MatTabsModule,
        DirectivesModule
    ],
    exports: [InventurComponent],
})
export class InventurModule {
}
