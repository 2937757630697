import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {DateTableColumn, TableColumn} from '../../components/kiene-table/table-column';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MessageService} from '../../services/message.service';
import {EditViehhaendlerDialogComponent} from './edit-viehhaendler-dialog/edit-viehhaendler-dialog.component';
import {Haendler} from '../../../../projects/kiene-core/src/lib/components/stammdaten/haendler/haendler';

@Component({
    selector: 'kiene-viehhaendler',
    templateUrl: './viehhaendler.component.html',
    styleUrls: ['./viehhaendler.component.scss']
})
export class ViehhaendlerComponent implements OnInit {

    tableDescriptor: TableDescriptor = new TableDescriptor();


    constructor(public dialog: MatDialog,
                private router: Router,
                private messageService: MessageService,
                private localStorageService: LocalStorageService,
                private tableService: KieneTableService,
                private api: KieneTableService) {
    }

    ngOnInit() {

        this.tableDescriptor.initialSortColumn = 'bezeichnung';
        this.tableDescriptor.initialSortDirection = 'asc';

        this.tableDescriptor.headline = 'Viehhändler';
        this.tableDescriptor.uniqueIdentifier = 'Viehhändler';
        this.tableDescriptor.apiUrl = environment.BASE_URI + 'viehhaendler/';
        this.tableDescriptor.route = '/stammdaten/viehhaendler/';
        this.tableDescriptor.nameOfIdField = 'viehhaendler_id';
        this.tableDescriptor.searchPlaceholder = 'Viehhändler durchsuchen';

        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;


        this.tableDescriptor.columns.push(new TableColumn('viehhaendler_id', 'ID'));
        this.tableDescriptor.columns.push(new TableColumn('name', 'Name'));
        this.tableDescriptor.columns.push(new TableColumn('vvvo', 'VVVO'));
        this.tableDescriptor.columns.push(new TableColumn('strasse', 'Strasse'));
        this.tableDescriptor.columns.push(new TableColumn('plz', 'PLZ'));
        this.tableDescriptor.columns.push(new TableColumn('ort', 'Ort'));
        this.tableDescriptor.columns.push(new TableColumn('telefon', 'Festnetz'));
        this.tableDescriptor.columns.push(new TableColumn('mobil', 'Mobil'));
        this.tableDescriptor.columns.push(new TableColumn('email', 'E-Mail'));
        this.tableDescriptor.columns.push(new TableColumn('fax', 'Fax'));
        this.tableDescriptor.columns.push(new TableColumn('favorit', 'Mein Favorit'));
        this.tableDescriptor.columns.push(new DateTableColumn('editiert', 'Editiert', 'dd.MM.yyyy HH:mm:ss'));


        this.tableDescriptor.permissionView = 9139;
        this.tableDescriptor.permissionCreate = 9132;
        this.tableDescriptor.permissionUpdate = 9133;

        this.tableDescriptor.options.push(new TableOption('edit', 'editieren', 'edit', 9133));


        this.tableDescriptor.initialSortColumn = 'viehhaendler_id';
        this.tableDescriptor.initialSortDirection = 'asc';

    }


    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            this.openEditDialog();
        } else if (event.name === 'edit') {
            this.openEditDialog(event.value);
        }
    }

    openEditDialog(haendler?: Haendler) {
        if (!haendler) {
            haendler = new Haendler();
        }
        const dialogRef = this.dialog.open(EditViehhaendlerDialogComponent, {
            data: haendler
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.tableService.reload();
            }
        });
    }

}
