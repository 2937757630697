import { animate, state, style, transition, trigger, } from '@angular/animations';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../../../services/message-service/message.service';
import { GruppenService } from '../../gruppen.service';
import { SoundType } from '../../../../classes/sound-type';
import { Haendler } from '../../../stammdaten/haendler/haendler';
import { StammdatenService } from '../../../stammdaten/stammdaten.service';
import { KieneBackendApiService } from '../../../../services/backend-api/kiene-backend-api.service';
import { GruppeTier } from '../../../gruppe-tier/classes/gruppe-tier';
import { ConfirmDialogComponent } from '../../../dialogs/confirm-dialog/confirm-dialog.component';
import { EinstallenService } from '../einstallen.service';
import { Gruppe } from '../../classes/gruppe';
import { AppService } from '../../../../services/app/app.service';
import {
    GewichtEingebenNumberpadDialogComponent
} from './gewicht-eingeben-numberpad-dialog/gewicht-eingeben-numberpad-dialog.component';
import { Subscription } from 'rxjs';
import {
    TransponderNummerEingebenDialogComponent
} from '../transponder-nummer-eingeben-dialog/transponder-nummer-eingeben-dialog.component';
import {
    DetachedRouteHandlesService
} from '../../../../configuration/route-caching-strategy/detached-route-handles.service';
import { ConfirmDialogInput, ConfirmDialogResult, } from '../../../dialogs/confirm-dialog/confirm-dialog';
import { ScanErgebnis } from '../../classes/scan-ergebnis';
import {
    HaendlerDetailsDialogComponent
} from '../../../stammdaten/haendler/haendler-details-dialog/haendler-details-dialog.component';
import {
    GruppeTierReklamierenDialogComponent
} from '../../../gruppe-tier/gruppe-tier-reklamieren-dialog/gruppe-tier-reklamieren-dialog.component';
import { API_BASE_URL_SERVICE } from '../../../../kiene-core.config';

@Component({
    selector: 'kiene-einstallen-tiere-hinzufuegen',
    templateUrl: './einstallen-tiere-hinzufuegen.component.html',
    styleUrls: ['./einstallen-tiere-hinzufuegen.component.scss'],
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    backgroundColor: 'rgba(55, 126, 34, 0.8)',
                })
            ),
            state(
                'closed',
                style({
                    backgroundColor: 'rgba(55, 126, 34, 0)',
                })
            ),
            transition('open => closed', [animate('1s')]),
            transition('closed => open', [animate('0.5s')]),

            state(
                'openDuplicate',
                style({
                    backgroundColor: 'rgba(255, 150, 45, 0.8)',
                })
            ),
            state(
                'closeDuplicate',
                style({
                    backgroundColor: 'rgba(255, 150, 45, 0)',
                })
            ),
            transition('openDuplicate => closeDuplicate', [animate('1s')]),
            transition('closeDuplicate => openDuplicate', [animate('0.5s')]),

            state(
                'openError',
                style({
                    backgroundColor: 'rgba(155, 0, 36, 0.8)',
                })
            ),
            state(
                'closedError',
                style({
                    backgroundColor: 'rgba(155, 0, 36, 0)',
                })
            ),
            transition('openError => closedError', [animate('1s')]),
            transition('closedError => openError', [animate('0.5s')]),
        ]),
    ],
})
export class EinstallenTiereHinzufuegenComponent implements OnInit, OnDestroy {
    haendlerFavoritenSubscription: Subscription;
    gruppeTierSubscription: Subscription;
    gruppeSubscription: Subscription;
    gruppeId: string;
    currentScanErgebnis: ScanErgebnis;
    datumCtrl = new UntypedFormControl();
    ohrenmarkeCtrl = new UntypedFormControl();
    gescannteOhrmarken: ScanErgebnis[] = [];
    maxLengthOhrmarke = 10;
    openCloseState = 'closed';
    timeMs = null;
    ohrmarkePassCtrl = new UntypedFormControl(0);
    haendlerFavoriten: Haendler[] = [];
    haendlerCtrl: UntypedFormControl = new UntypedFormControl('');
    abteilCtrl: UntypedFormControl = new UntypedFormControl(null);
    mitGewichtToggleCtrl = new UntypedFormControl();
    mitTransponderNummerToggleCtrl = new UntypedFormControl();
    isLoading = true;

    @Input() gruppe: Gruppe;
    @Input() showPassReiter = false;

    @ViewChild('ohrenmarkeInput') ohrenmarkeInput: MatInput;

    constructor(
        private route: ActivatedRoute,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private einstallService: EinstallenService,
        private dialog: MatDialog,
        private stammdatenService: StammdatenService,
        private gruppenService: GruppenService,
        private appService: AppService,
        private detachedRouteHandlesService: DetachedRouteHandlesService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        this.gruppeId = this.route.snapshot.paramMap.get('gruppeid');
    }

    ngOnDestroy(): void {
        this.gruppeSubscription?.unsubscribe();
        this.gruppeTierSubscription?.unsubscribe();
        this.haendlerFavoritenSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.detachedRouteHandlesService.watchChanges().subscribe(() => {
            setTimeout(() => {
                this.ohrenmarkeInput?.focus();
            }, 0);
        });

        this.loadGruppe();

        this.einstallService.watchCurrentAbteil().subscribe({
            next: (val) => this.abteilCtrl.setValue(val, { emitEvent: false }),
        });
        this.abteilCtrl.valueChanges.subscribe((abteil) => {
            this.einstallService.setCurrentAbteil(abteil);
        });

        this.haendlerCtrl.valueChanges.subscribe((value) => {
            this.einstallService.setCurrentHaendler(value);
            if (this.currentScanErgebnis) {
                this.currentScanErgebnis.anzahl_viehhaendler = null;
            }
        });

        this.ohrmarkePassCtrl.valueChanges.subscribe((value) => {
            if (value === 1) {
                this.ladeHaendlerFavoriten();
                this.maxLengthOhrmarke = 15;
            } else {
                this.maxLengthOhrmarke = 10;
            }
            this.ohrenmarkeCtrl.setValue(undefined, { emitEvent: false });
        });

        this.einstallService.watchScanErgebnisSubject().subscribe({
            next: (scanErgebnis) => {
                this.currentScanErgebnis = scanErgebnis;
            },
        });

        if (this.showPassReiter === true) {
            this.ohrmarkePassCtrl.setValue(1);
        }
    }

    ladeHaendlerFavoriten() {
        this.haendlerFavoritenSubscription?.unsubscribe();
        this.haendlerFavoritenSubscription = this.stammdatenService
            .loadHaendlerFavoriten()
            .subscribe((haendlerFavoriten) => {
                this.haendlerFavoriten = haendlerFavoriten.records;
            });
    }

    loadGruppe() {
        this.isLoading = true;
        this.gruppeSubscription?.unsubscribe();
        this.gruppeSubscription = this.einstallService
            .loadGruppe(
                this.gruppe ? this.gruppe.gruppe_id : Number(this.gruppeId)
            )
            .subscribe({
                next: (gruppe: Gruppe) => {
                    this.isLoading = false;
                    this.gruppe = gruppe;
                    if (!this.currentScanErgebnis) {
                        this.currentScanErgebnis = new ScanErgebnis();
                    }
                    this.currentScanErgebnis.anzahl_tiere_datensaetze =
                        this.gruppe.anzahl_tiere_datensaetze;
                    this.currentScanErgebnis.anzahl_tiere =
                        this.gruppe.anzahl_tiere_ohrmarken;
                    this.currentScanErgebnis.anzahl_tiere_match =
                        this.gruppe.anzahl_tiere_match;
                    this.einstallService.setScanErgebnisSubject(
                        this.currentScanErgebnis
                    );
                    this.einstallService.setCurrentGruppe(this.gruppe);
                },
                error: (err) => {
                    this.isLoading = false;
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }

    compareHaendler(haendler1: Haendler, haendler2: Haendler) {
        return haendler1 && haendler2
            ? haendler1.viehhaendler_id === haendler2.viehhaendler_id
            : haendler1 === haendler2;
    }

    addOhrmarke() {
        const startTime = performance.now();

        // this.ohrenmarkeCtrl.disable();

        const ohrmarke: string = this.ohrenmarkeCtrl.value;
        this.ohrenmarkeCtrl.setValue('');
        this.ohrenmarkeInput?.focus();
        const abteil = this.abteilCtrl.value;

        if (ohrmarke.length !== this.maxLengthOhrmarke) {
            this.messageService.errorMessage(
                'Fehler! Bitte eine Ohrmarke mit ' +
                this.maxLengthOhrmarke +
                ' Zeichen eingeben oder ggf. langsamer einscannen.',
                null
            );
            this.gruppenService.playScanSound(SoundType.ERROR);
            return;
        }

        if (this.ohrmarkePassCtrl.value == 1) {
            // Pass
            if (ohrmarke?.startsWith('276')) {
                if (!ohrmarke?.startsWith('27600')) {
                    this.messageService.errorMessage(
                        'Dies ist keine gültige Ohrmarke! (27600...)',
                        null
                    );
                    this.gruppenService.playScanSound(SoundType.ERROR);
                    return;
                }
            }
        }

        const gruppeTier: GruppeTier = new GruppeTier();

        gruppeTier.gruppe_id = this.gruppe?.gruppe_id
            ? this.gruppe?.gruppe_id
            : Number(this.gruppeId);
        gruppeTier.ohrmarke = ohrmarke;
        gruppeTier.abteil = abteil;

        if (this.ohrmarkePassCtrl.value === 1) {
            gruppeTier.kp_einstalldatum = this.datumCtrl.value;
            gruppeTier.viehhaendler_id =
                this.haendlerCtrl?.value?.viehhaendler_id;
            gruppeTier.abteil = undefined;
        }

        if (this.ohrmarkePassCtrl.value === 0) {
            this.openGewichtEingeben(gruppeTier, startTime);
        } else {
            this.postOhrmarkeOderPass(gruppeTier, startTime);
        }
    }

    openGewichtEingeben(gruppeTier: GruppeTier, startTime: number) {
        if (this.mitGewichtToggleCtrl.value) {
            this.dialog
                .open(GewichtEingebenNumberpadDialogComponent)
                .afterClosed()
                .subscribe((gewicht) => {
                    if (gewicht) {
                        gruppeTier.einstallgewicht = gewicht;
                    }
                    this.openTranspondernummer(gruppeTier, null);
                });
        } else {
            this.openTranspondernummer(gruppeTier, startTime);
        }
    }

    openTranspondernummer(gruppeTier: GruppeTier, startTime: number) {
        if (this.mitTransponderNummerToggleCtrl.value) {
            this.dialog
                .open(TransponderNummerEingebenDialogComponent)
                .afterClosed()
                .subscribe((transponderNummer) => {
                    if (transponderNummer) {
                        gruppeTier.transpondernummer = transponderNummer;
                    }
                    this.postOhrmarkeOderPass(gruppeTier, null);
                });
        } else {
            this.postOhrmarkeOderPass(gruppeTier, startTime);
        }
    }

    openHaendlerVerwaltung() {
        const dialogRef = this.dialog.open(HaendlerDetailsDialogComponent, {
            minWidth: '90vw',
        });
        dialogRef.afterClosed().subscribe({
            next: (result) => {
                if (result) {
                    this.ladeHaendlerFavoriten();
                    this.haendlerCtrl.setValue(result, { emitEvent: true });
                }
            },
        });
    }

    scanLoeschen(ergebnis: ScanErgebnis) {
        if (ergebnis.success) {
            const input = new ConfirmDialogInput();
            input.headline = 'Tier löschen';
            input.text =
                'Soll das Tier "' +
                ergebnis.ohrmarke_notnull +
                '" wirklich gelöscht werden?';
            input.okButton = 'Löschen';

            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: input,
            });

            dialogRef.afterClosed().subscribe({
                next: (accepted: ConfirmDialogResult) => {
                    if (accepted?.confirmed) {
                        this.gruppenService.scanLoeschen(ergebnis).subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Das Tier wurde gelöscht'
                                );

                                // loesche alle aus gescannten ohrmarken
                                this.gescannteOhrmarken =
                                    this.gescannteOhrmarken.filter((go) => {
                                        return go.gt_id != ergebnis.gt_id;
                                    });

                                // lade Anzahl der Tiere neu
                                this.loadGruppe();
                                // aktualisiere Anzahl fuer aktuelles Abteil
                                if (
                                    this.abteilCtrl.value &&
                                    this.currentScanErgebnis
                                ) {
                                    this.currentScanErgebnis.anzahl_abteil -= 1;
                                }
                                if (
                                    this.haendlerCtrl.value &&
                                    this.currentScanErgebnis
                                ) {
                                    this.currentScanErgebnis.anzahl_viehhaendler -= 1;
                                }
                                this.ohrenmarkeInput?.focus();
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Tier konnte nicht gelöscht werden!',
                                    err
                                );
                            },
                        });
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
        }
    }

    reklamationFuerScanHinzufuegen(scanResult: ScanErgebnis) {
        const tier = new GruppeTier();
        tier.gt_id = scanResult.gt_id;
        tier.ohrmarke_notnull = scanResult.ohrmarke_notnull;

        const dialogRef = this.dialog.open(
            GruppeTierReklamierenDialogComponent,
            {
                minWidth: '90vw',
                data: {
                    tier: tier,
                },
            }
        );

        dialogRef.afterClosed().subscribe({
            next: () => {
                // this.einstallService.ladeEingestallteTiere();
            },
        });
    }

    hasAbteilungsChange(currentOhrmarke: ScanErgebnis, i: number) {
        if (this.gescannteOhrmarken?.length > 1) {
            const previousOhrmarke = this.gescannteOhrmarken[i - 1];
            return currentOhrmarke.abteil !== previousOhrmarke.abteil;
        } else {
            return false;
        }
    }

    changeTransponderNrToggle(event: MouseEvent) {
        event.stopPropagation();
        this.mitTransponderNummerToggleCtrl.setValue(
            !this.mitTransponderNummerToggleCtrl.value
        );
    }

    changeGewichtToggle(event: MouseEvent) {
        event.stopPropagation();
        this.mitGewichtToggleCtrl.setValue(!this.mitGewichtToggleCtrl.value);
    }

    private postOhrmarkeOderPass(gt: GruppeTier, startTime: number) {
        this.appService.setBlockOnLoading(true);
        console.log('a:' + Math.round(performance.now() - startTime) + 'ms');
        this.api
            .post(
                this.apiBaseUrl + 'gruppe_tier/create_kundenportal.php',
                gt,
                undefined
            )
            .subscribe({
                next: (result: ScanErgebnis) => {
                    // this.ohrenmarkeCtrl.enable();

                    const scanResult = result;
                    scanResult.success = true;

                    this.gescannteOhrmarken.unshift(scanResult);
                    if (this.gescannteOhrmarken?.length > 10) {
                        this.gescannteOhrmarken.splice(10, this.gescannteOhrmarken?.length);
                    }
                    this.currentScanErgebnis = result;

                    if (
                        (this.ohrmarkePassCtrl.value === 0 &&
                            result.ohrmarke_gescannt > 1) ||
                        (this.ohrmarkePassCtrl.value === 1 &&
                            result.pass_gescannt > 1)
                    ) {
                        this.gruppenService.playScanSound(SoundType.WARNING);
                        this.openCloseState = 'closeDuplicate'; // das muss so
                        this.openCloseState = 'openDuplicate';
                        setTimeout(() => {
                            this.openCloseState = 'closeDuplicate';
                        }, 400);
                        this.appService.setBlockOnLoading(false);
                        return;
                    }

                    this.gruppenService.playScanSound(SoundType.OK);
                    this.openCloseState = 'closed'; // das muss so
                    this.openCloseState = 'open';

                    this.einstallService.setScanErgebnisSubject(
                        this.currentScanErgebnis
                    );

                    setTimeout(() => {
                        this.openCloseState = 'closed';
                    }, 400);

                    if (startTime) {
                        this.timeMs = Math.round(performance.now() - startTime);
                    } else {
                        this.timeMs = null;
                    }
                    this.appService.setBlockOnLoading(false);
                },
                error: (err) => {
                    // this.ohrenmarkeCtrl.enable();
                    this.gruppenService.playScanSound(SoundType.ERROR);
                    this.messageService.errorMessage('Fehler!', err);
                    const scanResult = new ScanErgebnis();
                    scanResult.ohrmarke_notnull = gt?.ohrmarke;
                    scanResult.success = false;
                    this.gescannteOhrmarken.unshift(scanResult);
                    if (this.gescannteOhrmarken?.length > 10) {
                        this.gescannteOhrmarken.splice(10, this.gescannteOhrmarken?.length);
                    }
                    this.openCloseState = 'openError';
                    this.timeMs = null;
                    setTimeout(() => {
                        this.openCloseState = 'closedError';
                    }, 400);
                    this.appService.setBlockOnLoading(false);
                },
            });
    }
}
