import { Component, Inject, OnInit } from '@angular/core';
import { Gruppe } from "../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe";
import { UntypedFormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MessageService } from "../../../../projects/kiene-core/src/lib/services/message-service/message.service";
import { API_BASE_URL_SERVICE } from "../../../../projects/kiene-core/src/lib/kiene-core.config";
import { GruppenService } from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';

@Component({
    selector: 'kiene-gruppe-informationen-dialog',
    templateUrl: './gruppe-informationen-dialog.component.html',
    styleUrls: ['./gruppe-informationen-dialog.component.scss']
})
export class GruppeInformationenDialogComponent implements OnInit {

    gruppe: Gruppe;
    informationenCtrl: UntypedFormControl = new UntypedFormControl();

    constructor(public dialogRef: MatDialogRef<GruppeInformationenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private gruppenService: GruppenService,
        private messageService: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl) {
        this.loadGruppe(data.gruppe_id);
    }

    ngOnInit(): void { }

    loadGruppe(gruppe_id: number) {
        this.gruppenService.getGruppeKundenportal(gruppe_id).subscribe({
            next: response => {
                this.gruppe = response;
                this.informationenCtrl.setValue(this.gruppe.informationen);
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Laden der Gruppe!', err);
            }
        });
    }

    updateKommentar() {
        this.gruppe.informationen = this.informationenCtrl.value;
        this.gruppenService.updateGruppeKundenportal(this.gruppe).subscribe({
            next: response => {
                this.dialogRef.close(response);
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Speichern der Gruppeninformationen', err);
            }
        });

    }

}
