import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Gruppe } from '../../../gruppen/classes/gruppe';
import { StammdatenService } from '../../../stammdaten/stammdaten.service';
import { MessageService } from '../../../../services/message-service/message.service';
import { Subscription } from 'rxjs';
import { Abteil } from './gruppe-tier-abteil-filter';

@Component({
    selector: 'kiene-gruppe-tier-abteil-filter',
    templateUrl: './gruppe-tier-abteil-filter.component.html',
    styleUrls: ['./gruppe-tier-abteil-filter.component.scss'],
})
export class GruppeTierAbteilFilterComponent
    implements OnInit, OnDestroy, OnChanges {
    abteileSubscription: Subscription;
    isLoading = true;
    label = 'Lädt...';

    @Input('gruppeId') selectedGruppeId: number;
    @Input('abteile') abteile: Abteil[] = [];
    @Input('hideOnEmptyList') hideOnEmptyList = false;

    @Output('selectedAbteile') selectedAbteile: EventEmitter<Abteil[]> =
        new EventEmitter<Abteil[]>();
    selectedAbteilCtrl: UntypedFormControl = new UntypedFormControl(null);
    abteileFuerGruppe: Abteil[] = [];

    constructor(
        private messageService: MessageService,
        private stammdatenService: StammdatenService
    ) { }

    ngOnDestroy(): void {
        this.abteileSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.selectedAbteilCtrl.disable();
        this.loadAbteileFuerGruppe();

        this.selectedAbteilCtrl.setValue(this.abteile);

        this.selectedAbteilCtrl.valueChanges.subscribe((value: Abteil[]) => {
            this.selectedAbteile.emit(value);
        });
    }

    ngOnChanges() {
        this.loadAbteileFuerGruppe();
    }

    resetAbteilCtrl($event: MouseEvent) {
        $event.stopPropagation();
        this.selectedAbteilCtrl.setValue(undefined);
    }

    loadAbteileFuerGruppe() {
        if (this.selectedGruppeId) {
            this.isLoading = true;
            this.abteileSubscription?.unsubscribe();
            this.abteileSubscription = this.stammdatenService
                .ladeAbteile(this.selectedGruppeId)
                .subscribe({
                    next: (result) => {
                        this.selectedAbteilCtrl.enable();
                        this.isLoading = false;
                        this.abteileFuerGruppe = result.records;

                        if (this.abteileFuerGruppe.length === 0) {
                            this.label = 'keine Abteile';
                            this.selectedAbteilCtrl.disable();
                        } else {
                            this.label = 'Abteil';
                        }
                    },
                    error: (err) => {
                        this.isLoading = false;
                        this.messageService.errorMessage(
                            'Fehler beim Laden der Abteile',
                            err
                        );
                    },
                });
        }
    }

    compareAbteil(a: Abteil, b: Abteil) {
        return a && b && a.abteil === b.abteil;
    }
}
