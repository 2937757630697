export class WarenkorbPosition{
    warenkorb_id: number;
    artikel_id: number;
    artikel: string;
    lieferant_id: number;
    lieferant: string;
    einheit: string;
    vorname: string;
    nachname: string;
    menge_soll: number;
    status_id: number;
    status: string;
    erstellt: Date;
    erstellt_von: number;
    editiert: Date;
    editiert_von: number;
    kommentar: string;
}

export interface WarenkorbPositionApi{
    records: WarenkorbPosition[];
    count: number;
}