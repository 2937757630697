import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BelegeVorlageDialogInput} from './belege-vorlage-dialog-input';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {Artikelliste} from '../../beleg-position/artikelliste-auswahl-dialog/artikelliste-auswahl-dialog';
import {
    BelegVorlageDetailsDialogComponent
} from './beleg-vorlage-details-dialog/beleg-vorlage-details-dialog.component';

@Component({
    selector: 'kiene-belege-vorlage-dialog',
    templateUrl: './belege-vorlage-dialog.component.html',
    styleUrls: ['./belege-vorlage-dialog.component.scss']
})
export class BelegeVorlageDialogComponent implements OnInit {

    artikellisten: Artikelliste[] = [];
    ausgewaehlteArtikelliste: Artikelliste;

    constructor(@Inject(MAT_DIALOG_DATA) private input: BelegeVorlageDialogInput,
                public dialogRef: MatDialogRef<BelegeVorlageDialogComponent>,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.ladeVorlagen();
    }

    artikellisteAuswaehlen(al: Artikelliste) {
        this.ausgewaehlteArtikelliste = al;
    }

    neueVorlageErstellen() {
        const dialogRef = this.dialog.open(BelegVorlageDetailsDialogComponent);
        dialogRef.afterClosed().subscribe({
            next: result => {
                if (result) {
                    this.ladeVorlagen(result);
                }
            }
        });
    }

    private ladeVorlagen(artikelliste?: Artikelliste) {
        this.api.get(this.apiBaseUrl + 'artikelliste/read.php').subscribe({
            next: result => {
                this.artikellisten = result.records;
                if (artikelliste) {
                    this.ausgewaehlteArtikelliste = this.artikellisten.find((v) => v.artikelliste_id === artikelliste.artikelliste_id);
                } else {
                    this.ausgewaehlteArtikelliste = this.artikellisten[0];
                }
            }
        });
    }
}
