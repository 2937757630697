import {
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import {
    Probenbegleitschein,
    Probenbegleitscheinposition,
} from '../probenbegleitschein';
import { HttpParams } from '@angular/common/http';
import { UntypedFormControl } from '@angular/forms';
import { TierartSelectorService } from '../../../components/tierart-selector/tierart-selector.service';
import { MessageService } from '../../../services/message.service';
import { LocalStorageService } from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { AddProbenbegleitscheinPositionDialogComponent } from '../add-probenbegleitschein-position-dialog/add-probenbegleitschein-position-dialog.component';
import { MatSelect } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent } from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { KieneAutocompleteComponent } from '../../../components/kiene-autocomplete/kiene-autocomplete.component';
import { ChangeCurrentClientService } from '../../../administration/client/change-current-client/change-current-client.service';
import { Gruppe } from '../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import { GruppenService } from '../../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { AppService } from '../../../app.service';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { KieneSession } from '../../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';

@Component({
    selector: 'kiene-probenbegleitschein-details',
    templateUrl: './probenbegleitschein-details.component.html',
    styleUrls: ['./probenbegleitschein-details.component.scss'],
    host: { '(window:keydown)': 'checkForKeyShortcut($event)' },
})
export class ProbenbegleitscheinDetailsComponent implements OnInit {
    @ViewChild('tierartInput') tierartInput: MatSelect;
    @ViewChild('gruppeAutocomplete')
    gruppeAutocomplete: KieneAutocompleteComponent;

    probenbegleitscheinId = 'neu';
    gruppe: Gruppe;
    gruppeParams = new HttpParams();

    kieneSession: KieneSession;

    probenentnahmeCtrl = new UntypedFormControl(new Date());

    probenbegleitschein: Probenbegleitschein = new Probenbegleitschein();

    positionen: Probenbegleitscheinposition[] = [];

    constructor(
        private appService: AppService,
        private route: ActivatedRoute,
        private router: Router,
        private api: KieneTableService,
        private gruppenService: GruppenService,
        private tierartService: TierartSelectorService,
        private notification: MessageService,
        private localStorageService: LocalStorageService,
        public dialog: MatDialog,
        private cd: ChangeDetectorRef,
        private pdfViewer: PdfViewerDesktopService,
        private changeCurrentClientService: ChangeCurrentClientService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl
    ) {
        this.probenbegleitscheinId = this.route.snapshot.paramMap.get('id');
        this.kieneSession = localStorageService.getCurrentUser();
    }

    ngOnInit() {
        if (this.probenbegleitscheinId !== 'neu') {
            this.loadProbenbegleitschein();
        } else if (this.probenbegleitscheinId === 'neu') {
            this.probenbegleitschein.status_id = 81;
            if (this.kieneSession.tierarzt_id) {
                this.probenbegleitschein.tierarzt_id =
                    this.kieneSession.tierarzt_id;
                this.probenbegleitschein.tierarzt =
                    this.kieneSession.vorname +
                    ' ' +
                    this.kieneSession.nachname;
            } else {
                this.probenbegleitschein.tierarzt_id = 1;
                this.probenbegleitschein.tierarzt = 'Felix Kiene (zum Testen)';
            }
            this.probenbegleitschein.kunde_id =
                this.localStorageService.getCurrentClient()?.kunde_id;
            if (!this.probenbegleitschein.gruppe_id) {
                if (this.probenbegleitschein.kunde_id) {
                    this.gruppenService
                        .getStandardGruppePraxissoftware(
                            this.probenbegleitschein.kunde_id
                        )
                        .subscribe({
                            next: (response) => {
                                if (response.records?.length > 0) {
                                    this.setSelectedClientGroup(
                                        <Gruppe>(<unknown>response.records[0])
                                    );
                                }
                            },
                        });
                }
            }

            if (this.localStorageService.getCurrentClient()) {
                this.probenbegleitschein.kunde =
                    this.localStorageService.getCurrentClient().bezeichnung_final;
                this.probenbegleitschein.kunde_id =
                    this.localStorageService.getCurrentClient().kunde_id;
                this.gruppeParams = this.gruppeParams.set(
                    'kunde_id',
                    this.probenbegleitschein.kunde_id.toString()
                );
            } else {
                this.changeCurrentClientService.openChangeDialog(
                    'CCC: Probenbegleitschein'
                );
            }
        }

        this.localStorageService.watchCurrentClient().subscribe({
            next: (cc) => {
                if (this.probenbegleitscheinId === 'neu') {
                    if (cc) {
                        this.probenbegleitschein.kunde_id = cc.kunde_id;
                        this.probenbegleitschein.kunde = cc.bezeichnung_final;
                        this.gruppeParams = this.gruppeParams.set(
                            'kunde_id',
                            cc.kunde_id.toString()
                        );
                        this.gruppeAutocomplete.clearAndFocus();
                    }
                }
            },
        });
    }

    checkForKeyShortcut(event) {
        if (event.key === 'F9') {
            this.openAddPositionDialog();
        }
    }

    editPosition(p: Probenbegleitscheinposition) {
        this.openAddPositionDialog(p);
    }

    openAddPositionDialog(position?: Probenbegleitscheinposition) {
        const dialogRef = this.dialog.open(
            AddProbenbegleitscheinPositionDialogComponent,
            {
                data: {
                    position: position,
                    kunde_id: this.probenbegleitschein.kunde_id,
                },
            }
        );

        dialogRef.afterClosed().subscribe((positionReturn) => {
            if (positionReturn && !position) {
                this.positionen.push(positionReturn);
                this.save(81);
            } else if (positionReturn && position) {
                const index = this.positionen.indexOf(position);
                this.positionen.splice(index, 1, positionReturn);
                this.save(81);
            }
        });
    }

    loadProbenbegleitschein() {
        this.api
            .getOne(
                this.apiBaseUrl + 'probenbegleitschein/read_one.php',
                'probenbegleitschein_id',
                this.probenbegleitscheinId
            )
            .subscribe((response) => {
                this.probenbegleitschein = response;
                this.gruppeParams = this.gruppeParams.set(
                    'kunde_id',
                    this.probenbegleitschein.kunde_id.toString()
                );

                this.loadPositionen();
                this.initFields();
            });
    }

    loadPositionen() {
        this.appService.setDataLoading(true);
        let params = new HttpParams();
        params = params.append(
            'probenbegleitschein_id',
            this.probenbegleitschein.probenbegleitschein_id.toString()
        );
        this.api
            .get(
                this.apiBaseUrl +
                'probenbegleitschein_untersuchungsmaterial/read.php',
                params
            )
            .subscribe({
                next: (response) => {
                    this.positionen = response.records;
                    this.appService.setDataLoading(false);
                },
                error: (error) => {
                    this.appService.setDataLoading(false);
                    this.notification.errorMessage(
                        'Positionen konnten nicht geladen werden!',
                        error
                    );
                },
            });
    }

    loadClientGroup(gruppe_id: number) {
        this.gruppenService
            .getGruppePraxissoftware(gruppe_id)
            .subscribe((cg) => {
                this.gruppe = <Gruppe>(<unknown>cg);
            });
    }

    initFields() {
        this.loadClientGroup(this.probenbegleitschein.gruppe_id);
        this.probenentnahmeCtrl.setValue(
            this.probenbegleitschein.probenentnahme
        );
        if (this.probenbegleitschein) {
            if (this.probenbegleitschein.status_id >= 82) {
                this.probenentnahmeCtrl.disable();
            }
        }
    }

    setSelectedClientGroup(clientGroup: Gruppe) {
        this.gruppe = clientGroup;
    }

    close() {
        this.save(82);
    }

    save(status_id?: number) {
        this.appService.setDataLoading(true);
        this.probenbegleitschein.materialien = this.positionen;
        this.probenbegleitschein.gruppe_id = this.gruppe.gruppe_id;
        this.probenbegleitschein.probenentnahme =
            this.probenentnahmeCtrl?.value;
        if (status_id) {
            this.probenbegleitschein.status_id = status_id;
        }
        this.api
            .updateEntity(
                this.apiBaseUrl + 'probenbegleitschein/update.php',
                this.probenbegleitschein
            )
            .subscribe({
                next: (response) => {
                    this.notification.infoMessage(
                        'Der Probenbegleitschein wurde erfolgreich gespeichert!'
                    );
                    this.probenbegleitschein = response;
                    this.probenbegleitscheinId =
                        this.probenbegleitschein.probenbegleitschein_id.toString();
                    this.router
                        .navigate(
                            [
                                '../',
                                this.probenbegleitschein.probenbegleitschein_id,
                            ],
                            {
                                relativeTo: this.route,
                                replaceUrl: true,
                            }
                        )
                        .then();
                    this.appService.setDataLoading(false);
                    this.loadPositionen();
                    if (this.probenbegleitschein.status_id === 82) {
                        const input = new PdfViewerDialogInput(
                            'probenbegleitschein/read_or_mail_pdf.php',
                            new HttpParams().set(
                                'probenbegleitschein_id',
                                this.probenbegleitschein?.probenbegleitschein_id?.toString()
                            ),
                            'Probenbegleitschein',
                            {
                                targetRoute: ['/labor', 'probenbegleitscheine'],
                                email: {
                                    sende_mail: true,
                                    kunde_id: this.probenbegleitschein?.kunde_id
                                }
                            }
                        );
                        this.pdfViewer.openPdfViewer(input);
                    }
                },
                error: (error) => {
                    this.appService.setDataLoading(false);
                    this.notification.errorMessage(
                        'Der Probenbegleitschein konnte nicht gespeichert werden!',
                        error
                    );
                },
            });
    }

    removePosition(p: Probenbegleitscheinposition) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            data: {
                title: 'Position löschen',
                text: 'Wollen Sie die Position wirklich löschen?',
                textButton: 'Löschen',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const index = this.positionen.indexOf(p);
                this.positionen.splice(index, 1);
                this.save();
            }
        });
    }

    createArticleString(p: Probenbegleitscheinposition) {
        let t = '';
        for (const m of p.positionen) {
            t += m.bezeichnung + ', ';
        }
        t = t.substr(0, t.length - 2);
        return t;
    }
}
