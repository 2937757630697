export class Benutzerprofil {
    benutzer_id: number;
    email: string;
    nachname: string;
    recpositon: number;
    vorname: string;
    elemente_pro_seite: number;
    unterschrift_png: string;
    default_haendler_id: number;
    default_schlachthof_id: number;
    default_spediteur_id: number;
}
