import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {AppService} from '../../app.service';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {SaveForAllDialogComponent} from '../../dialogs/save-for-all-dialog/save-for-all-dialog.component';
import {MessageService} from '../../services/message.service';
import {Mandant} from '../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Injectable({
    providedIn: 'root'
})
export class SupplierMaterialService {

    constructor(private api: KieneTableService,
                private notification: MessageService,
                public dialog: MatDialog,
                private appService: AppService) {
    }

    public saveSupplierMaterial(sm: any): void {
        this.loadAvailableMandanten(sm);
    }

    private loadAvailableMandanten(sm: any) {
        if (sm) {
            this.appService.setDataLoading(true);
            let params = new HttpParams();
            params = params.append('artikel_id', sm.artikel_id.toString());
            params = params.append('lieferant_id', sm.lieferant_id.toString());
            this.api.get(environment.BASE_URI + 'artikel_lieferant/read_mandanten.php', params).subscribe({
                next: response => {
                    const availableMandanten: Mandant[] = response.records;
                    this.appService.setDataLoading(false);

                    if (availableMandanten.length > 1) {
                        this.openSaveForAllDialog(availableMandanten, sm);
                    } else {
                        if (availableMandanten.length === 1) {
                            sm.mandanten_ids = [availableMandanten[0].mandant_id];
                        }
                        this.saveArtikelLieferant(sm);
                    }


                }, error: error => {
                    this.notification.errorMessage('Fehler beim Laden der Mandanten', error);
                    this.appService.setDataLoading(false);
                }
            });
        }
    }

    private openSaveForAllDialog(availableMandanten: Mandant[], sm: any) {
        const dialogRef = this.dialog.open(SaveForAllDialogComponent, {
            data: {
                availableMandanten: availableMandanten
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                sm.mandanten_ids = result;
                this.saveArtikelLieferant(sm);
            }
        });
    }

    private saveArtikelLieferant(sm: any) {
        this.appService.setDataLoading(true);
        this.api.updateEntity(environment.BASE_URI + 'artikel_lieferant/update.php', sm).subscribe({
            next: response => {
                this.appService.setDataLoading(false);
                this.notification.infoMessage('Artikel-Lieferant erfolgreich editiert!');
            }, error: error => {
                this.appService.setDataLoading(false);
                this.notification.errorMessage('Fehler beim Arikel-Lieferant editieren aufgetreten!', error);
            }
        });
    }
}
