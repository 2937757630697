import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Tierliste} from '../../gruppe-tier/classes/tierliste';
import {Haendler} from '../../stammdaten/haendler/haendler';

@Component({
    selector: 'kiene-import-optionen-dialog',
    templateUrl: './import-optionen-dialog.component.html',
    styleUrls: ['./import-optionen-dialog.component.scss']
})
export class ImportOptionenDialogComponent implements OnInit {

    einstalldatumCtrl = new UntypedFormControl(undefined, [Validators.required]);
    gewichtCtrl = new UntypedFormControl();
    hitMeldenCtrl = new UntypedFormControl(1);
    kfzCtrl = new FormControl();

    tierliste: Tierliste;

    constructor(public dialogRef: MatDialogRef<ImportOptionenDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data) {
        this.tierliste = this.data.tierliste;
    }

    ngOnInit(): void {
        this.einstalldatumCtrl.setValue(this.tierliste.datum_abgang);
        this.kfzCtrl.setValue(this.tierliste.kfz);
    }

    close() {
        this.dialogRef.close(this.tierliste);
    }

    istDerselbeKunde() {
        return this.tierliste?.tierliste_extern === 0;
    }

    save() {
        this.tierliste.datum_einstallen = this.einstalldatumCtrl.value;
        this.tierliste.gewicht_gesamt_einstallen = this.gewichtCtrl.value;
        this.tierliste.kfz = this.kfzCtrl.value;
        if (this.istDerselbeKunde()) {
            this.tierliste.einstallen_melden = 0;
        } else {
            this.tierliste.einstallen_melden = this.hitMeldenCtrl.value;
        }
        this.dialogRef.close(this.tierliste);
    }

    setHaendler(haendler: Haendler) {
        if (haendler) {
            this.tierliste.viehhaendler_id = haendler.viehhaendler_id;
        } else {
            this.tierliste.viehhaendler_id = undefined;
        }
    }

}
