<div>
    <mat-tab-group>
        <mat-tab label="Probenbegleitscheine">
            <ng-template matTabContent>
                <kiene-probenbegleitschein></kiene-probenbegleitschein>
            </ng-template>
        </mat-tab>
        <mat-tab label="Befunde">
            <ng-template matTabContent>
                <kiene-befundmitteilung></kiene-befundmitteilung>
            </ng-template>
        </mat-tab>
        <mat-tab label="Antibiogramme">
            <ng-template matTabContent>
                <kiene-antibiogramm></kiene-antibiogramm>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>