import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';
import {CompanyGroup} from '../../administration/company-group/company-group';
import {RechnungGeneration} from '../../finanzen/rechnungen/rechnung-generation';
import {MessageService} from '../../services/message.service';
import {Client} from '../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-invoice-generation-dialog',
    templateUrl: './invoice-generation-dialog.component.html',
    styleUrls: ['./invoice-generation-dialog.component.scss']
})
export class InvoiceGenerationDialogComponent implements OnInit {

    pickerFromCtrl = new UntypedFormControl();
    pickerToCtrl = new UntypedFormControl();
    clientRadioCtrl = new UntypedFormControl();
    abgabebelegeCtrl = new UntypedFormControl(true);
    befundeCtrl = new UntypedFormControl(false);

    startDate: Date;

    selectedClient: Client;
    selectedCompanyGroup: CompanyGroup;

    constructor(
        public dialogRef: MatDialogRef<InvoiceGenerationDialogComponent>,
        private messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        const now = new Date();
        this.startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    }

    ngOnInit() {
        this.clientRadioCtrl.setValue('client');
    }

    setClient(c: Client) {
        this.selectedClient = c;
    }

    setCompanyGroup(cg: CompanyGroup) {
        this.selectedCompanyGroup = cg;
    }

    ok() {
        const rg = new RechnungGeneration();
        const choice = this.clientRadioCtrl.value;
        rg.kunde_id = null;
        rg.unternehmensgruppe_id = null;
        if (choice === 'client') {
            if (!this.selectedClient) {
                this.messageService.errorMessage('Bitte "Betrieb" auswählen', null);
                return;
            }
            rg.kunde_id = this.selectedClient.kunde_id;
        } else if (choice === 'companyGroup') {
            if (!this.selectedCompanyGroup) {
                this.messageService.errorMessage('Bitte "Unternehmensgruppe" auswählen', null);
                return;
            }
            rg.unternehmensgruppe_id = this.selectedCompanyGroup.unternehmensgruppe_id;
        } else {
            // all
        }
        rg.von = this.pickerFromCtrl.value;
        rg.bis = this.pickerToCtrl.value;
        rg.abgabebelege = this.abgabebelegeCtrl.value === true ? 1 : 0;
        rg.befunde = this.befundeCtrl.value === true ? 1 : 0;

        if (!rg.von) {
            this.messageService.errorMessage('Bitte "Zeitraum von" auswählen', null);
            return;
        }

        if (!rg.bis) {
            this.messageService.errorMessage('Bitte "Zeitraum bis" auswählen', null);
            return;
        }

        this.dialogRef.close(rg);
    }

    cancel() {
        this.dialogRef.close(null);
    }

}
