import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {MessageService} from '../../../services/message.service';
import {Observable} from 'rxjs';
import {
    KieneColor
} from '../../../administration/vet-group/vet-group-details-dialog/vet-group-details-dialog.component';
import {Dienstgruppe, DienstgruppeTierarzt} from '../dienstgruppe';
import {Vet} from '../../../administration/vet/vet';

@Component({
    selector: 'kiene-dienstgruppe-details-dialog',
    templateUrl: './dienstgruppe-details-dialog.component.html',
    styleUrls: ['./dienstgruppe-details-dialog.component.scss']
})
export class DienstgruppeDetailsDialogComponent implements OnInit {

    dienstgruppeNameCtrl = new FormControl();
    farbeCtrl = new FormControl();
    tieraerzte: DienstgruppeTierarzt[] = [];

    farben: KieneColor[] = [];

    constructor(public dialogRef: MatDialogRef<DienstgruppeDetailsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public dienstgruppe: Dienstgruppe,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private messageService: MessageService) {
        if (!this.dienstgruppe) {
            this.dienstgruppe = new Dienstgruppe();
        }
    }

    ngOnInit(): void {
        this.initFarben();
        this.dienstgruppeNameCtrl.setValue(this.dienstgruppe.dienstgruppe);
    }

    saveDienstgruppe(): Observable<Dienstgruppe> {
        this.dienstgruppe.dienstgruppe = this.dienstgruppeNameCtrl.value;
        if (!this.dienstgruppe.farbe) {
            this.dienstgruppe.farbe = this.farben[0].farbe;
            this.dienstgruppe.hintergrundfarbe = this.farben[0].hintergrundfarbe;
        }
        return this.api.post(this.apiBaseUrl + 'dienstgruppe/update.php', this.dienstgruppe);
    }

    save() {
        this.saveDienstgruppe().subscribe({
            next: response => {
                this.dialogRef.close(this.dienstgruppe);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });

    }

    isColorSelected(farbe: KieneColor) {
        return this.dienstgruppe.farbe === farbe?.farbe && this.dienstgruppe.hintergrundfarbe === farbe?.hintergrundfarbe;
    }

    selectFarbe(farbe: KieneColor) {
        this.dienstgruppe.farbe = farbe?.farbe;
        this.dienstgruppe.hintergrundfarbe = farbe?.hintergrundfarbe;
    }

    tierarztEntfernen(tierarzt: DienstgruppeTierarzt) {

        const indexOf = this.dienstgruppe.tieraerzte.indexOf(tierarzt);
        if (indexOf > -1) {
            this.dienstgruppe.tieraerzte.splice(indexOf, 1);
            this.api.updateGeneric(this.apiBaseUrl + 'dienstgruppe/update.php', this.dienstgruppe).subscribe({
                next: response => {
                    this.dienstgruppe = response;
                },
                error: error => {
                    this.messageService.errorMessage('Fehler!', error);
                }
            });
        }
    }

    tierarztHinzufuegen(tierarzt: Vet) {
        if (!tierarzt) {
            return;
        }

        const dt = new DienstgruppeTierarzt();
        dt.tierarzt_id = tierarzt.tierarzt_id;
        dt.vorname = tierarzt.vorname;
        dt.nachname = tierarzt.nachname;
        if (!this.dienstgruppe?.tieraerzte) {
            this.dienstgruppe.tieraerzte = [];
        }
        this.dienstgruppe?.tieraerzte?.push(dt);

        this.saveDienstgruppe().subscribe({
            next: response => {
                if (response) {
                    this.dienstgruppe = response;
                }
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }


    private initFarben() {
        this.farben.push(new KieneColor('#000000', '#FFFFFF'));
        this.farben.push(new KieneColor('#FFFFFF', '#333333'));
        this.farben.push(new KieneColor('#FFFFFF', '#5FAD56'));
        this.farben.push(new KieneColor('#FFFFFF', '#F2C14E'));
        this.farben.push(new KieneColor('#FFFFFF', '#F78154'));
        this.farben.push(new KieneColor('#FFFFFF', '#B4436C'));
        this.farben.push(new KieneColor('#FFFFFF', '#AF1B3F'));
        this.farben.push(new KieneColor('#FFFFFF', '#4F359B'));
        this.farben.push(new KieneColor('#FFFFFF', '#246EB9'));
        this.farben.push(new KieneColor('#FFFFFF', '#8DAA9D'));
        this.farben.push(new KieneColor('#FFFFFF', '#EB7BC0'));
        this.farben.push(new KieneColor('#000000', '#F9E784'));
    }
}
