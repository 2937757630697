<div style="padding: 20px">
    <div class="one-row">
        <h1>
            Probenbegleitschein
            {{
                probenbegleitscheinId === "neu"
                    ? "erstellen"
                    : probenbegleitscheinId
            }}
        </h1>

        <span class="spacer"></span>
        <div class="one-row" style="width: 250px">
            <div
                    *ngIf="
                    probenbegleitschein.status_id === 81 &&
                    probenbegleitschein.tierarzt_id
                "
                    style="width: 100%; text-align: right; margin-top: 20px"
            >
                <button (click)="close()" color="accent" mat-flat-button>
                    Abschließen
                </button>
            </div>
        </div>
    </div>
    <mat-card>
        <mat-card-content>
            <h3>Allgemein</h3>
            <div class="one-row">
                <p>Kunde: {{ probenbegleitschein.kunde }}</p>

                <div style="width: 30%; min-width: 300px">
                    <!-- <kiene-client-group-autocomplete [disabled]="probenbegleitschein.status_id >= 82" [clientGroup]="clientGroup" (selectedGroup)="setSelectedClientGroup($event)"
            [focus]="true"></kiene-client-group-autocomplete> -->
                    <kiene-autocomplete
                        #gruppeAutocomplete
                        (elementSelected)="setSelectedClientGroup($event)"
                        [displayFields]="['bezeichnung', 'herdenname', 'stall']"
                        [genericApiUrl]="'gruppe/read_akt.php'"
                        [kieneObject]="gruppe"
                        [panelOpen]="false"
                        [params]="gruppeParams"
                        [placeholder]="'Gruppe (Name - Herdenname - Stall)'"
                        [separator]="' - '"
                    >
                    </kiene-autocomplete>
                </div>
                <div>
                    <mat-form-field>
                        <input
                                [formControl]="probenentnahmeCtrl"
                                [matDatepicker]="picker"
                                matInput
                                placeholder="Probenentnahme"
                        />
                        <mat-datepicker-toggle
                                [for]="picker"
                                matSuffix
                        ></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div>
                    <span>Tierarzt: </span
                    ><span>{{
                        probenbegleitschein.tierarzt_id
                            ? probenbegleitschein.tierarzt
                            : "Bitte als Tierarzt anmelden!"
                    }}</span>
                </div>
                <div>
                    <span class="phito-status-badge">{{
                            probenbegleitschein.status
                                ? probenbegleitschein.status
                                : "Neu"
                        }}</span>
                </div>
                <button
                        (click)="save(probenbegleitschein.status_id)"
                        *ngIf="
                        probenbegleitschein.status_id === 81 &&
                        probenbegleitschein.tierarzt_id
                    "
                        color="accent"
                        mat-icon-button
                        matTooltip="Probenbegleitschein zwischenspeichern"
                >
                    <mat-icon>save</mat-icon>
                </button>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="one-row">
        <h4 style="padding-left: 20px">Positionen</h4>
        <span class="spacer"></span>
        <div
                *ngIf="
                probenbegleitschein.status_id < 82 &&
                probenbegleitschein.tierarzt_id
            "
        >
            <button
                    (click)="openAddPositionDialog()"
                    color="accent"
                    mat-stroked-button
            >
                Position hinzufügen
            </button>
        </div>
    </div>
    <table class="phito-table" style="margin-top: 20px; margin-left: 20px">
        <thead>
        <tr>
            <th>Pos.</th>
            <th>Tierart</th>
            <th>Tieranzahl</th>
            <th>Tiergewicht</th>
            <th>Kennzeichnung</th>
            <th>Untersuchungsmaterial</th>
            <th style="width: 33%;">Artikel</th>
            <th></th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let p of positionen; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ p.tierart }}</td>
            <td>{{ p.anzahl }}</td>
            <td>{{ p.gewicht }}</td>
            <td>{{ p.kennzeichnung }}</td>
            <td>{{ p.untersuchungsmaterial }}</td>
            <td style="width: 33%;">{{ createArticleString(p) }}</td>
            <td>
                <button
                        (click)="editPosition(p)"
                        *ngIf="
                            probenbegleitschein.status_id < 82 &&
                            probenbegleitschein.tierarzt_id
                        "
                        mat-icon-button
                >
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
            <td>
                <button
                        (click)="removePosition(p)"
                        *ngIf="
                            probenbegleitschein.status_id < 82 &&
                            probenbegleitschein.tierarzt_id
                        "
                        mat-icon-button
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>
