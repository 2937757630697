<h1 mat-dialog-title>{{ data.headline }}</h1>
<div mat-dialog-content>
    <p style="white-space: pre-wrap;">{{ data.text }}</p>
</div>

<div mat-dialog-content *ngIf="dropdown">
    <mat-select></mat-select>
</div>

<div mat-dialog-actions>
    <span class="spacer"></span>
    <button data-cy="confirm-dialog-abort" mat-button (click)="cancel()">{{data?.cancelButton}}</button>
    <button data-cy="confirm-dialog-confirm" mat-flat-button [color]="data?.buttonColor" (click)="ok()">
        {{ data.okButton }}
    </button>
</div>
