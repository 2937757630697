import { Component, OnInit, Input, AfterViewInit, OnChanges } from '@angular/core';
import { Material } from '../material';
import { RabattStaffelService } from './rabatt-staffel.service';
import { MessageService } from '../../../services/message.service';
import { UntypedFormControl } from '@angular/forms';

export class ArtikelRabattstaffel {
  ar_id: number;
  artikel_id: number;
  artikel_me: string;
  artikelmenge_ab: number;
  rabatt: number;
}

export interface ArtikelRabattstaffelApi {
  records: ArtikelRabattstaffel[];
  count: number;
}

@Component({
  selector: 'kiene-rabatt-staffel',
  templateUrl: './rabatt-staffel.component.html',
  styleUrls: ['./rabatt-staffel.component.scss']
})
export class RabattStaffelComponent implements OnInit, OnChanges {

  @Input('material') material: Material;

  rabattstaffeln: ArtikelRabattstaffel[] = [];

  rabattCtrl = new UntypedFormControl();
  amountCtrl = new UntypedFormControl();

  constructor(private api: RabattStaffelService, private message: MessageService) { }

  ngOnInit() {
    if(this.material.artikel_id){
      this.loadRabattstaffeln();
    }
  }

  ngOnChanges(){
    if(this.material.artikel_id){
      this.loadRabattstaffeln();
    }
  }

  loadRabattstaffeln() {
    this.api.getRabattStaffeln(this.material.artikel_id.toString()).subscribe(response => {
      this.rabattstaffeln = response.records;
    });
  }

  remove(rs: ArtikelRabattstaffel) {
    this.api.remove(rs).subscribe(response => {
      this.loadRabattstaffeln();
    }, error => {
      this.message.errorMessage('Die Rabattstaffel konnte nicht entfernt werden: ', error);
    });
  }

  save() {
    const rs = new ArtikelRabattstaffel();
    rs.artikelmenge_ab = this.amountCtrl.value;
    rs.rabatt = this.rabattCtrl.value;
    rs.artikel_id = this.material.artikel_id;
    this.api.save(rs).subscribe(response => {
      this.loadRabattstaffeln();
    }, error => {
      this.message.errorMessage('Rabattstaffel konnte nicht hinzugefügt werden! ', error);
    });
  }

}
