<nav mat-tab-nav-bar>
    <a mat-tab-link [routerLink]="['eingang']"
    routerLinkActive #eingang="routerLinkActive" [active]="eingang.isActive">Eingang</a>
    <a mat-tab-link [routerLink]="['ausgang']"
    routerLinkActive #ausgang="routerLinkActive" [active]="ausgang.isActive">Ausgang</a>
    <a mat-tab-link [routerLink]="['kombiniert']"
    routerLinkActive #kombiniert="routerLinkActive" [active]="kombiniert.isActive">Kombiniert</a>
    <a mat-tab-link [routerLink]="['warengruppeneingang']"
    routerLinkActive #warengruppenein="routerLinkActive" [active]="warengruppenein.isActive">Warengruppen Eingang</a>
    <a mat-tab-link [routerLink]="['warengruppenausgang']"
    routerLinkActive #warengruppenaus="routerLinkActive" [active]="warengruppenaus.isActive">Warengruppen Ausgang</a>
</nav>
<router-outlet></router-outlet>