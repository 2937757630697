import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../services/message.service';
import { UntypedFormControl } from '@angular/forms';
import { Invoice, InvoicePosition } from '../rechnung';
import { MatDialog } from '@angular/material/dialog';
import { EditRechnungPositionDialogComponent } from '../edit-rechnung-position-dialog/edit-rechnung-position-dialog.component';
import { DeleteRechnungPositionDialogComponent } from '../delete-rechnung-position-dialog/delete-rechnung-position-dialog.component';
import { RechnungStornoDialogComponent } from '../rechnung-storno-dialog/rechnung-storno-dialog.component';
import { RechnungBelegeDialogComponent } from '../rechnung-belege-dialog/rechnung-belege-dialog.component';

@Component({
  selector: 'kiene-rechnung-details',
  templateUrl: './rechnung-details.component.html',
  styleUrls: ['./rechnung-details.component.scss']
})
export class RechnungDetailsComponent implements OnInit {

  rechnungId: string;
  rechnung: Invoice = new Invoice();
  rechnungsdatumCtrl = new UntypedFormControl();

  constructor(
    private route: ActivatedRoute,
    private api: KieneTableService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.rechnungId = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    this.loadRechnung();
  }

  loadRechnung() {
    this.api.getOne(environment.BASE_URI + 'rechnung/read_one.php', 'rechnung_id', this.rechnungId).subscribe(response => {
      this.rechnung = response;
      this.setRechnungsdatum();
    });
  }

  setRechnungsdatum() {
    const rd = new Date(this.rechnung.rechnungsdatum);
    this.rechnungsdatumCtrl.setValue(rd);
  }

  goBack() {
    window.history.go(-1);
  }

  getRechnungssumme() {
    let gesamtpreis = 0;
    for (const p of this.rechnung.positionen) {
      gesamtpreis += (p.einzelpreis * p.artikelmenge);
    }
    return gesamtpreis;
  }

  getMwst() {
    let mwst = 0;
    for (const p of this.rechnung.positionen) {
      const preis = p.einzelpreis * p.artikelmenge;
      mwst += preis * p.mwstsatz / 100;
    }
    return mwst;
  }

  getMwstSaetze() {
    const mwstSaetze = [];
    for (const p of this.rechnung.positionen) {
      let found = false;
      for (const m of mwstSaetze) {
        if (m.mwst === p.mwstsatz) {
          m.betrag += p.einzelpreis * p.artikelmenge * p.mwstsatz / 100;
          found = true;
        }
      }
      if (!found) {
        const mwstSatz = {
          mwst: p.mwstsatz,
          betrag: p.einzelpreis * p.artikelmenge * p.mwstsatz / 100
        };
        mwstSaetze.push(mwstSatz);
      }
    }
    return mwstSaetze;
  }

  isInvoiceEditable() {
    return this.rechnung.status_id === 51;
  }

  getRechungsbetrag() {
    return this.getRechnungssumme() + this.getMwst();
  }

  deletePosition(p: InvoicePosition) {
    const dialogRef = this.dialog.open(DeleteRechnungPositionDialogComponent, {
      data: {
        position: p
      }
    });

    dialogRef.afterClosed().subscribe(() => this.loadRechnung());
  }

  editPosition(p: InvoicePosition) {
    const dialogRef = this.dialog.open(EditRechnungPositionDialogComponent, {
      data: {
        position: p
      }
    });
    dialogRef.afterClosed().subscribe(() => this.loadRechnung());
  }

  showDocument(p: InvoicePosition) {
    if (p.abgabebeleg_id) {
      this.router.navigate(['/belege', 'abgabebelege', p.abgabebeleg_id]);
    } else if (p.befund_id) {
      this.router.navigate(['/labor', 'befundmitteilungen', p.befund_id]);
    }
  }

  // save(status_id: number) {
  //   this.rechnung.status_id = status_id;
  //   this.rechnung.rechnungsdatum = this.rechnungsdatumCtrl.value;
  //   this.api.update(environment.BASE_URI + 'rechnung/', this.rechnung).subscribe(response => {
  //     this.rechnung = response;
  //     this.messageService.infoMessage('Die Rechnung ' + this.rechnung.rechnung_id + ' wurde erfolgreich gespeichert!');
  //   }, error => {
  //     this.messageService.errorMessage('Die Rechnung ' + this.rechnung.rechnung_id + ' konnte nicht gespeichert werden! ', error);
  //     this.loadRechnung();
  //   });
  // }

  /*abgabeChanged(p: InvoicePosition, event: MatCheckboxChange) {
    const checked = event.checked;
    if (checked) {
      p.behandlung = 0;
      p.abgabe = 1;
    } else {
      p.behandlung = 1;
      p.abgabe = 0;
    }
  }

  behandlungChanged(p: InvoicePosition, event: MatCheckboxChange) {
    const checked = event.checked;
    if (checked) {
      p.abgabe = 0;
      p.behandlung = 1;
    } else {
      p.abgabe = 1;
      p.behandlung = 0;
    }
  }*/

  getTyp(p: InvoicePosition) {
    if (p.abgabe > 0) {
      return 'Abgabe';
    }
    if (p.behandlung > 0) {
      return 'Beh.';
    }
    return '/';
  }

  einzelnFreigeben() {
    this.api.post(environment.BASE_URI + 'rechnung/freigeben_einzeln.php', this.rechnung, null).subscribe(
      response => {
        this.messageService.infoMessage('Rechnung erfolgreich freigegeben!');
        this.loadRechnung();
      },
      error => {
        this.messageService.errorMessage('Rechnung konnte nicht freigegeben werden!', error);
      }
    );
  }

  rechnungLoeschen() {
    this.api.deleteEntity(environment.BASE_URI + 'rechnung/delete.php', this.rechnung).subscribe(result => {
      this.messageService.infoMessage('Rechnung wurde erfolgreich gelöscht!');
      this.router.navigate(['/finanzen', 'rechnungen']);
    }, error => {
      this.messageService.errorMessage('Rechnung konnte nicht gelöscht werden!', error);
    });
  }

  openStornoDialog() {
    const dialogRef = this.dialog.open(RechnungStornoDialogComponent, {
      data: {
        invoice: this.rechnung
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.api.updateGeneric(environment.BASE_URI + 'rechnung/stornieren.php', result).subscribe(
          response => {
            this.messageService.infoMessage('Rechnung wurde storniert!');
            this.loadRechnung();
            this.openRechnungBelegeDialog(this.rechnung);
          },
          error => {
            this.messageService.errorMessage('Rechnung konnte nicht storniert werden!', error);
            this.loadRechnung();
          }
        );
      }
    });
  }

  openRechnungBelegeDialog(invoice: Invoice) {
    const dialogRef = this.dialog.open(RechnungBelegeDialogComponent, {
      width: '80%',
      data: {
        invoice: invoice
      }
    });
  }

}
