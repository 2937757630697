import {Component, Inject, Input, OnChanges, OnInit} from '@angular/core';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {HttpParams} from '@angular/common/http';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-go-to-hitbutton',
    templateUrl: './go-to-hitbutton.component.html',
    styleUrls: ['./go-to-hitbutton.component.scss']
})
export class GoToHITButtonComponent implements OnInit, OnChanges {

    @Input('currentClient') currentClient: Client;
    vvvo: string;
    pin: string;

    constructor(private lss: LocalStorageService,
                private clipboard: Clipboard,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService) {
    }

    ngOnInit(): void {
        if (!this.currentClient) {
            this.currentClient = this.lss.getCurrentClient();
        }
        if (this.currentClient) {
            this.ladeHitPin();
        }

        this.lss.watchCurrentClient().subscribe({
            next: client => {
                this.currentClient = client;
                this.ladeHitPin();
            }
        });
    }

    ngOnChanges() {
        if (this.currentClient) {
            this.ladeHitPin();
        }
    }

    ladeHitPin() {
        if (!this.lss.isCurrentUserPhitoUser()) {
            return;
        }
        this.vvvo = undefined;
        this.pin = undefined;
        if (this.currentClient?.kunde_id) {
            const params = new HttpParams().set(
                'kunde_id',
                this.currentClient?.kunde_id?.toString()
            );
            this.api
                .get(this.apiBaseUrl + 'kunde/read_one_pin.php', params)
                .subscribe({
                    next: (response) => {
                        this.vvvo = response.v;
                        this.pin = response.p;
                    },
                    error: err => {
                        console.error('error lade hit pin');
                    }
                });
        }
    }

    goToHit() {
        const url = 'https://www4.hi-tier.de/HitCom/login.asp?txtLogonBnr12=' + this.vvvo;
        this.clipboard.copy(this.pin);
        window.open(url, '_blank').focus();
    }
}
