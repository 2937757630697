import { Pipe, PipeTransform } from '@angular/core';
import { Haendler } from '../haendler';

@Pipe({
  name: 'haendlerName'
})
export class HaendlerNamePipe implements PipeTransform {

    transform(haendler: Haendler[], nameSubstring: string): Haendler[] {
        if (nameSubstring) {
            return haendler.filter(e => e.name.toLowerCase().includes(nameSubstring.toLowerCase()));
        } else {
            return haendler;
        }
    }

}
