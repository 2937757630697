<div style="padding: 24px; height: 100%;">
    <div class="one-row" style="font-size: 0.8em; padding: 6px 0 6px 0">
        <span>HIT Meldung: </span>
        <span style="display: flex; align-items: center; justify-content: center">
            <svg color="accent" height="1em" viewBox="0 0 1 1" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path class="triangle-green" d="m 0 0 l 0 1 l 1 -1" />
            </svg>
            <span style="padding-left: 3px">erfolgreich</span>
        </span>
        <span style="display: flex; align-items: center; justify-content: center">
            <svg color="accent" height="1em" viewBox="0 0 1 1" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path class="triangle-red" d="m 0 0 l 0 1 l 1 -1" />
            </svg>
            <span style="padding-left: 3px">fehlgeschlagen</span>
        </span>
    </div>
    <div *ngIf="tierliste?.mehrere_gruppen === 0" class="one-row" style="margin: 12px 0">
        <ng-container *ngIf="tierliste?.status_id === 161">
            <span class="spacer"></span>
            <button (click)="abgangslisteSchliessen()" [disabled]="tiere.length < 1" color="accent" mat-flat-button>
                Liste schlie&szlig;en
            </button>
        </ng-container>
        <ng-container *ngIf="tierliste?.status_id === 161">
            <span class="spacer"></span>
            <button (click)="abgangslisteLeeren()" [disabled]="tiere.length <= 0" color="accent" mat-stroked-button>
                Liste leeren
            </button>
        </ng-container>
        <span class="spacer"></span>
        <button (click)="downloadAbgangsliste()" color="accent" mat-icon-button>
            <mat-icon>download</mat-icon>
        </button>
        <span class="spacer"></span>
        <button (click)="ladeTiereFuerAbgangsliste('reload buton')" color="accent" mat-icon-button>
            <mat-icon>refresh</mat-icon>
        </button>
        <span class="spacer"></span>

    </div>
    <div *ngIf="tierliste?.mehrere_gruppen > 0" class="one-row" style="margin: 12px 0">
        <ng-container
            *ngIf="tierliste?.status_id === 161 && tierliste?.erstellt_von === lss.getCurrentUser().benutzer_id">
            <span class="spacer"></span>
            <button (click)="abgangslisteSchliessen()" [disabled]="tiere.length < 1" color="accent" mat-flat-button>
                Ausstallliste schlie&szlig;en
            </button>
        </ng-container>
        <ng-container
            *ngIf="tierliste?.status_id === 161 && tierliste?.erstellt_von === lss.getCurrentUser().benutzer_id">
            <span class="spacer"></span>
            <button (click)="abgangslisteLeeren()" [disabled]="tiere.length <= 0" color="accent" mat-stroked-button>
                Liste leeren
            </button>
        </ng-container>
        <div *ngIf="tierliste?.status_id === 161 && tierliste?.erstellt_von !== lss.getCurrentUser().benutzer_id"
            class="one-row">
            <span class="spacer"></span>
            <mat-icon>info</mat-icon>
            <span class="spacer"></span>
            <span style="width: 220px; font-size: .8em;">Abschlie&szlig;en oder leeren der Liste nicht m&ouml;glich. Sie
                sind nicht Besitzer dieser Liste.</span>
            <span class="spacer"></span>
        </div>
        <span class="spacer"></span>
        <button (click)="downloadAbgangsliste()" color="accent" mat-icon-button>
            <mat-icon>download</mat-icon>
        </button>
        <span class="spacer"></span>
        <button (click)="ladeTiereFuerAbgangsliste('reload buton')" color="accent" mat-icon-button>
            <mat-icon>refresh</mat-icon>
        </button>
        <span class="spacer"></span>
    </div>
    <div style="margin-bottom: 80px">
        <p *ngIf="isLoading">Tiere werden geladen...</p>
        <ng-container *ngFor="let tier of tiere; let index = index">
            <div style="padding-bottom: 6px">
                <kiene-gruppe-tier-card [disableCardClickDetails]="true" [hideAllButtons]="false" [hideBemerkung]="true"
                    [hideKunde]="tierliste?.mehrere_gruppen === 0" [hideReklamationen]="true" [tier]="tier">
                    <ng-container additional-rows>
                        <div class="one-row">
                            <span class="spacer"></span>
                            <button (click)="ausListeEntfernen(tier)" *ngIf="tierliste?.status_id === 161"
                                color="accent" mat-stroked-button>
                                <span>Tier aus Liste entfernen</span>
                            </button>
                        </div>
                    </ng-container>
                </kiene-gruppe-tier-card>
            </div>
        </ng-container>
    </div>
</div>
