import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SupplierService} from '../supplier.service';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MessageService} from '../../../services/message.service';
import {Supplier} from '../supplier';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {environment} from '../../../../environments/environment';
import {HttpParams} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {Location} from '@angular/common';
import {SaveForAllDialogComponent} from '../../../dialogs/save-for-all-dialog/save-for-all-dialog.component';
import {Mandant} from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'kiene-supplier-details',
    templateUrl: './supplier-details.component.html',
    styleUrls: ['./supplier-details.component.scss']
})
export class SupplierDetailsComponent implements OnInit {

    currentSupplierId: string;
    supplier: Supplier = new Supplier();
    selectedTab = 0;
    isCreationForm = false;

    emailCtrl = new UntypedFormControl('', [Validators.email]);
    faxBevorzugenCtrl = new UntypedFormControl();
    nebenkostenCtrl = new UntypedFormControl();

    proxyFaxBevorzugen = true;

    availableMandanten: Mandant[] = [];

    constructor(private route: ActivatedRoute,
                private router: Router,
                private location: Location,
                private service: SupplierService,
                private messageService: MessageService,
                private api: KieneTableService,
                public dialog: MatDialog,
                private localStorageService: LocalStorageService) {
        this.currentSupplierId = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            this.selectedTab = params['tab'];
        });
    }

    ngOnInit() {
        if (this.currentSupplierId !== 'neu') {
            this.loadSupplier();

        } else {
            this.isCreationForm = true;
            this.loadMandanten();
        }
    }

    goBack() {
        window.history.go(-1);
    }

    loadMandanten() {
        // (this.localStorageService.getCurrentMandant().mandant_id === 1) &&
        if (this.localStorageService.currentUserHasPermission(1075)) {
            let params = new HttpParams();
            if (!this.isCreationForm) {
                params = params.append('lieferant_id', this.supplier.lieferant_id.toString());
            }
            this.api.get(environment.BASE_URI + 'lieferant/read_mandanten.php', params).subscribe(
                response => {
                    this.availableMandanten = response.records;
                },
                error => {
                    this.messageService.errorMessage('Fehler beim Laden der verfügbaren Mandanten', error);
                }
            );
        }
    }

    loadSupplier() {
        this.service.getSupplierById(Number.parseInt(this.currentSupplierId, 10)).subscribe(
            response => {
                this.supplier = response;
                this.faxBevorzugenCtrl.setValue(this.supplier.fax_bevorzugen === 1 ? true : false);
                this.emailCtrl.setValue(this.supplier.email);
                this.nebenkostenCtrl.setValue(this.supplier.nebenkosten === 1 ? true : false);
                this.loadMandanten();
            },
            error => {
                this.messageService.errorMessage('Fehler beim Laden des Lieferanten!', error);
            }
        );
    }

    updateAllgemein() {
        this.saveSupplier('lieferant/update_allgemein.php');
    }

    updateFibu() {
        this.saveSupplier('lieferant/update_fibu.php');
    }

    updateZahlung() {
        this.saveSupplier('lieferant/update_zahlung.php');
    }

    saveSupplier(apiUrl: string) {
        this.supplier.fax_bevorzugen = this.faxBevorzugenCtrl.value === true ? 1 : 0;
        this.supplier.nebenkosten = this.nebenkostenCtrl.value ? 1 : 0;
        this.supplier.email = this.emailCtrl.value;

        if (this.availableMandanten.length > 1) { // Array enthält nur Daten mit entspr. Berechtigung und bei Kiene
            let dialogRef = this.dialog.open(SaveForAllDialogComponent, {
                data: {
                    availableMandanten: this.availableMandanten
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.supplier.mandanten_ids = result;
                    this.save(apiUrl);
                }
            });
        } else {
            this.save(apiUrl);
        }
    }

    save(apiUrl: string) {
        this.api.updateGeneric(environment.BASE_URI + apiUrl, this.supplier).subscribe(
            response => {
                this.supplier = response;
                if (this.supplier.lieferant_id) {
                    this.isCreationForm = false;
                    this.currentSupplierId = this.supplier.lieferant_id.toString();
                    this.resetUrl();
                }
                this.messageService.infoMessage('Lieferant ' + this.supplier.bezeichnung + ' wurde erfolgreich gespeichert!');
            },
            error => {
                this.messageService.errorMessage('Lieferant nicht gespeichert!', error);
            }
        );
    }

    clearEmail() {
        this.emailCtrl.setValue('');
    }

    resetUrl() {
        const url = this.router.createUrlTree([], {relativeTo: this.route}).toString();
        let urlParts = url.split('/');
        let newUrl = '';
        for (let i = 0; i < urlParts.length - 1; i++) {
            newUrl += urlParts[i] + '/';
        }

        newUrl += this.supplier.lieferant_id;

        console.debug('reset url: %s', newUrl);
        this.location.go(newUrl);
    }

}
