<h1 mat-dialog-title>Rinderpassscan-PDF importieren</h1>

<div mat-dialog-content>

    <h5>PDF Datei mit den Rinderp&auml;ssen ausw&auml;hlen (Größe max. 100MB)</h5>
    <div class="one-row">
        <button mat-flat-button color="accent" (click)="this.fileSelector.click();">
            <mat-icon>file_upload</mat-icon>
            Datei auswählen
        </button>
        <span class="spacer"></span>
        <mat-progress-spinner *ngIf="uploadStatus && uploadStatus?.state !== 'DONE'" [color]="'accent'"
            [mode]="'determinate'" [value]="uploadStatus?.progress" [diameter]="25">
        </mat-progress-spinner>
    </div>
    <input type="file" style="display: none" #fileSelector multiple (change)="handleFiles($event)"
        accept="application/pdf">
    <p *ngIf="pdfFile">Datei: {{ pdfFile?.name }}</p>

    <h5>Es kann ein Viehh&auml;ndler ausgew&auml;hlt werden, der dann bei allen Tieren hinterlegt wird. (optional)</h5>
    <div class="one-row">
        <kiene-autocomplete #viehhaendlerAutoComplete class="no-padding-margin-mat-form-field" [showClear]="true"
            [showDeleteButton]="false" [required]="false" [apiUrl]="apiBaseUrl + 'viehhaendler/'"
            [displayFields]="['name']" [placeholder]="'Viehhändler'" [focus]="false"
            (elementSelected)="viehhaendlerCtrl.setValue($event)">
        </kiene-autocomplete>
        <span class="spacer"></span>
        <button mat-icon-button color="accent" (click)="addViehhaendler()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <ng-container *ngIf="!lss.kostenpflichtigAktiv()">

        <mat-form-field style="margin-top: 24px; width: 100%;">
            <mat-label>Einstalldatum</mat-label>
            <input required matInput [matDatepicker]="picker" [formControl]="kpEinstalldatumCtrl" [readonly]="true">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </ng-container>


</div>

<div mat-dialog-actions align="end">
    <button mat-flat-button [disabled]="isLoading" (click)="dialogRef.close()">Schlie&szlig;en</button>
    <button mat-flat-button color="accent" [disabled]="!isValid() || isLoading" (click)="progressScan()">Hochladen
    </button>
</div>
