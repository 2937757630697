<mat-form-field
    *ngIf="!hideOnEmptyList || this.abgangslistenFuerGruppe.length > 0"
    class="abgang-form-input no-padding-margin-mat-form-field"
    style="margin: 6px 0 0 0; width: 100%"
>
    <mat-icon style="padding-right: 6px" matPrefix>search</mat-icon>
    <mat-label>{{ label }}</mat-label>
    <mat-select
        multiple
        [compareWith]="compareAbgangsliste"
        [formControl]="selectedAbgangslisteCtrl"
    >
        <mat-option
            *ngFor="let liste of abgangslistenFuerGruppe"
            [value]="liste"
        >
            {{ liste.datum_abgang | date : "dd.MM.YY" }}
            {{ liste.kunde_einstallen || liste.kommentar || "" }}
            ({{ liste.anzahl_tiere }} Tiere)
        </mat-option>
    </mat-select>
    <button
        *ngIf="
            selectedAbgangslisteCtrl.value && abgangslistenFuerGruppe.length > 0
        "
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="resetAbgangslisteCtrl($event)"
    >
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
