import {Inject, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Signature} from './signature';
import {QRCodeDialogComponent} from './qrcode-dialog/qrcode-dialog.component';
import {KieneBackendApiService} from '../../services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../kiene-core.config';
import {MessageService} from '../../services/message-service/message.service';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UnterschriftenService {

    constructor(private dialog: MatDialog,
                private api: KieneBackendApiService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private messageService: MessageService) {
    }

    unterschreiben(signature: Signature): Observable<boolean> {
        const subject = new Subject<boolean>();
        if (signature?.kuid) {
            this.dialog.open(QRCodeDialogComponent, {
                width: '300px',
                minWidth: '300px',
                data: {
                    signature: signature
                }
            }).afterClosed().subscribe({
                next: () => {
                    subject.next(true);
                }
            });
        } else {
            // erzeugt eine KUID für Unterschrift auf Handy
            this.api.createGeneric(this.apiBaseUrl + 'unterschrift/create.php', signature).subscribe({
                next: (response: Signature) => {
                    signature = response;
                    this.dialog.open(QRCodeDialogComponent, {
                        width: '100%',
                        minWidth: '300px',
                        maxWidth: '600px',
                        data: {
                            signature: signature
                        }
                    }).afterClosed().subscribe({
                        next: (result) => {
                            subject.next(result);
                        }
                    });
                },
                error: error => {
                    if (error.status === 400) {
                        this.messageService.alertMessage(error.error.errortext);
                    } else {
                        this.messageService.errorMessage('Es ist ein Fehler aufgetreten: ', error);
                    }
                    subject.next(true);
                }
            });
        }
        return subject.asObservable();
    }
}
