import { HttpParams } from "@angular/common/http";
import { Empfaenger } from "./empfaenger";

export class PdfViewerInput {
    asEmail = false;
    url: string;
    params = new HttpParams();
    empfaenger: Empfaenger[] = [];
    betreff: string;
    dateiname: string;

    an_kunde = false;
    an_tierarztgruppe = false;
    an_veterinaeramt = false;
    an_ueberwachungsbehoerde = false;
    sende_mail = true;

    constructor(
        url?: string,
        params?: HttpParams,
        options?: { betreff?: string; dateiname?: string }
    ) {
        this.url = url;
        this.params = params;
        if (options) {
            // this.empfaenger = options?.empfaenger ? options.empfaenger : [];
            this.betreff = options?.betreff ? options.betreff : "VETVET";
            this.dateiname = options.dateiname ? options.dateiname : "VETVET";
            this.asEmail = true;
        }
    }
}
