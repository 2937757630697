<h1 mat-dialog-title>Untersuchungsmaterial {{edit ? 'bearbeiten' : 'hinzufügen'}}</h1>
<div mat-dialog-content>
  <mat-form-field style="width: 100%;">
    <input matInput type="text" placeholder="Name" [formControl]="nameCtrl">
  </mat-form-field>
  <kiene-animal-type-selector (tierartSelectionChanged)="setTierart($event)" [selectedTierartId]="untersuchungsmaterial ? untersuchungsmaterial.tierart_id : null"></kiene-animal-type-selector>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Abbrechen</button>
  <button mat-flat-button color="primary" (click)="save()" cdkFocusInitial>Speichern</button>
</div>