import {Form, UntypedFormControl} from '@angular/forms';
import {Material} from '../../warehouse/material/material';

export class ProbenbegleitscheinArtikel {
    artikel_id: number;
    bezeichnung: string;
    kommentar: string;
}

export class Probenbegleitschein {
    probenbegleitschein_id: number;
    gruppe: string;
    gruppe_id: number;
    kunde: string;
    kunde_id: number;
    probenentnahme: Date;
    status: string;
    status_id: number;
    tierarzt: string;
    tierarzt_id: number;
    materialien: Probenbegleitscheinposition[] = [];
}

export class ProbenbegleitscheinUntersuchung {
    pu_id: number;
    pum_id: number;
    untersuchung_id: number;
    bezeichnung: string;
}

export class Probenbegleitscheinposition {
    pum_id: number;
    probenbegleitschein_id: number;
    tierart_id: number;
    tierart: string;
    anzahl: number;
    gewicht: number;
    kennzeichnung: string;
    untersuchungsmaterial_id: number;
    untersuchungsmaterial: string;
    positionen: ProbenbegleitscheinArtikel[] = [];
}

export class Artikelstrukturelement {
    untersuchung: string;
    artikel_id: number;
    artikel: string;
    kuerzel: string;
    lieferant: string;
    lieferant_id: number;
    kommentar: string;
    kinder: Artikelstrukturelement[] = [];
}

export class ArtikelstrukturelementFlatNode {
    untersuchung: string;
    level: number;
    expandable: boolean;
    artikel: string;
    artikel_id: number;
    lieferant: string;
    kommentar: string;
    lieferant_id: number;
}

export class ArtikelKommentar {
    artikel: Material;
    kommentarCtrl: UntypedFormControl;
    checkboxCtrl: UntypedFormControl;

    constructor(m: Material, fc: UntypedFormControl, cc: UntypedFormControl) {
        this.artikel = m;
        this.kommentarCtrl = fc;
        this.checkboxCtrl = cc;
    }
}
