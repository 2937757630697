<div mat-dialog-content>
    <div class="one-row" style="margin-bottom: 24px;">
        <span [ngClass]="{'active-step': step === 1}" (click)="step = 1">1. Tier suchen</span>
        <span class="spacer"></span>
        <span [ngClass]="{'active-step': step === 2}" (click)="step = 2">2. Gewicht eingeben</span>
    </div>
    <kiene-numberpad *ngIf="step === 1" [gruppe]="gruppe" (tierGefunden)="tierGefunden($event)"></kiene-numberpad>
    <div *ngIf="step === 2">
        <p>F&uuml;r Tier:</p>
        <p>
            <kiene-ohrmarke [ohrmarke]="gruppeTier?.ohrmarke"></kiene-ohrmarke>
        </p>
        <mat-radio-group style="width: 100%;" [formControl]="radioCtrl">
            <mat-radio-button [value]="0">Einstallgewicht</mat-radio-button>
            <mat-radio-button [value]="1">Ausstallgewicht</mat-radio-button>
        </mat-radio-group>

        <mat-form-field style="width: 100%; margin-top: 24px;">
            <mat-label>Gewicht eingeben</mat-label>
            <input #gewichtInput="matInput" matInput type="number" inputmode="decimal" [formControl]="gewichtCtrl">
        </mat-form-field>

        <button style="margin-top: 24px;" mat-flat-button color="accent"
            [disabled]="!gewichtCtrl.valid || !radioCtrl.valid" (click)="gewichtSpeichern()">
            Gewicht speichern
        </button>
    </div>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
</div>