<div class="kiene-content-page">
    <div class="one-row">
        <button style="margin-bottom: 1.25em;" (click)="goBack()" mat-icon-button matTooltip="zurück">
            <mat-icon color="primary">arrow_back</mat-icon>
        </button>
        <h1>Antibiogramm {{ tbnHeadline }} </h1>
        <span class="spacer"></span>
        <div *kienePermission="[1819]">
            <button *ngIf="antibiogramm?.status === 1" (click)="resendAntibiogrammToMCN6()" mat-icon-button
                    matTooltip="Erneut an MCN6 übertragen">
                <mat-icon color="primary">cached</mat-icon>
            </button>
        </div>
        <div *kienePermission="[1817]">
            <button *ngIf="antibiogramm?.status === 3" (click)="openPdf(antibiogramm)" mat-icon-button
                    matTooltip="Download Ergebnis Resistenztest als PDF">
                <mat-icon color="primary">cloud_download</mat-icon>
            </button>
        </div>
    </div>
    <table style="max-width: 600px;">
        <tr>
            <td>Kunde (Nr.):</td>
            <td><strong>{{ antibiogramm?.kunde }} ({{ antibiogramm?.kunde_nr_intern }})</strong></td>
        </tr>
        <tr>
            <td>Kunde Zusatz:</td>
            <td><strong>{{ currentGruppe?.zusatz }}</strong></td>
        </tr>
        <tr>
            <td>Gruppe:</td>
            <td><strong>{{ currentGruppe?.bezeichnung }}</strong></td>
        </tr>
    </table>
    <div *ngIf="hasPermission(1313) && isNewAntibiogramm || antibiogramm.status === -1">
        <div class="kiene-table-header">
            <mat-card style="width: 100%;">

                <div class="one-row">
                    <mat-form-field style="min-width: 400px; width: 30%;">
                        <mat-label>Tagebuchnummer</mat-label>
                        <input matInput type="text" placeholder="Tagebuchnummer" [(ngModel)]="antibiogramm.tagebuch_nr"
                               autofocus>
                        <span matSuffix>/{{ presetYear.toString().substr(-2) }}</span>
                        <button matSuffix mat-icon-button (click)="addToPresetYear(1)">
                            <mat-icon>arrow_drop_up</mat-icon>
                        </button>
                        <button matSuffix mat-icon-button (click)="addToPresetYear(-1)">
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                    </mat-form-field>

                    <span class="spacer"></span>
                    <mat-form-field>
                        <mat-label>Probenentnahmedatum</mat-label>
                        <input matInput [matDatepicker]="probenentnahmePicker" placeholder="Probenentnahmedatum"
                               [(ngModel)]="antibiogramm.probenentnahme">
                        <mat-datepicker-toggle matSuffix [for]="probenentnahmePicker"></mat-datepicker-toggle>
                        <mat-datepicker #probenentnahmePicker></mat-datepicker>
                    </mat-form-field>
                    <span class="spacer"></span>
                    <mat-form-field>
                        <mat-label>Untersuchungsbeginn</mat-label>
                        <input matInput [matDatepicker]="eingangPicker" placeholder="Untersuchungsbeginn"
                               [(ngModel)]="antibiogramm.eingang" (dateChange)="changedEingangDate()">
                        <mat-datepicker-toggle matSuffix [for]="eingangPicker"></mat-datepicker-toggle>
                        <mat-datepicker #eingangPicker></mat-datepicker>
                    </mat-form-field>
                    <span class="spacer"></span>
                    <!--                    <kiene-autocomplete #kundeAutocomplete style="min-width: 400px; width: 30%;"-->
                    <!--                                        [genericApiUrl]="'kunde/read.php'"-->
                    <!--                                        [placeholder]="'Kunde'"-->
                    <!--                                        [kieneObject]="currentClient"-->
                    <!--                                        [displayFields]="['kunde_nr_intern', 'bezeichnung', 'zusatz', 'unternehmensgruppe']"-->
                    <!--                                        [mapperDisplayFields]="['kunde_nr_intern', 'bezeichnung', 'zusatz', 'unternehmensgruppe']"-->
                    <!--                                        [separator]="' - '"-->
                    <!--                                        (elementSelected)="setSelectedClient($event)">-->
                    <!--                    </kiene-autocomplete>-->
                    <!--                    <span class="spacer"></span>-->
                    <!--                    <kiene-autocomplete #gruppeAutocomplete style="min-width: 400px; width: 30%;"-->
                    <!--                                        [genericApiUrl]="'gruppe/read_akt.php'"-->
                    <!--                                        [disabled]="!currentClient"-->
                    <!--                                        [params]="currentClientParams"-->
                    <!--                                        [kieneObject]="currentGruppe"-->
                    <!--                                        [placeholder]="'Gruppe'"-->
                    <!--                                        [displayFields]="['bezeichnung', 'stall']"-->
                    <!--                                        [mapperDisplayFields]="['bezeichnung', 'stall']" [separator]="' - '"-->
                    <!--                                        (elementSelected)="setSelectedGruppe($event)">-->
                    <!--                    </kiene-autocomplete>-->
                </div>
                <!--                <div class="one-row">-->
                <!--                    -->
                <!--                    <div style="width: 30%;"></div>-->
                <!--                </div>-->

                <h3>Test hinzuf&uuml;gen</h3>
                <div class="one-row">
                    <mat-form-field style="min-width: 200px; width: 30%;">
                        <mat-label>Testart</mat-label>
                        <mat-select placeholder="Testart" [(value)]="newTest.testart">
                            <mat-option value="R1">R1</mat-option>
                            <mat-option value="R2">R2</mat-option>
                            <mat-option value="H1">H1</mat-option>
                            <mat-option value="H2">H2</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span class="spacer"></span>
                    <mat-form-field style="min-width: 500px; width: 30%;">
                        <mat-label>Verdacht</mat-label>
                        <input type="text" matInput placeholder="Verdacht" [formControl]="verdachtCtrl"
                               [matAutocomplete]="verdachtAuto">
                    </mat-form-field>
                    <mat-autocomplete #verdachtAuto="matAutocomplete" [displayWith]="displayVerdacht">
                        <mat-option *ngFor="let v of verdachte | async" [value]="v">{{ v?.verdacht }}
                            - {{ v?.kuerzel }}
                        </mat-option>
                    </mat-autocomplete>
                    <span class="spacer"></span>
                    <mat-form-field style="min-width: 400px; width: 30%;">
                        <mat-label>Tierart</mat-label>
                        <input type="text" matInput placeholder="Tierart" [formControl]="tierartCtrl"
                               [matAutocomplete]="tierartAuto">
                    </mat-form-field>
                    <mat-autocomplete #tierartAuto="matAutocomplete" [displayWith]="displayTierart">
                        <mat-option *ngFor="let t of tierarten | async" [value]="t">{{ t?.tierart }}
                            - {{ t?.kuerzel }}
                        </mat-option>
                    </mat-autocomplete>
                </div>
                <div class="one-row">
                    <mat-form-field style="min-width: 300px; width: 30%;">
                        <mat-label>Infektionsart</mat-label>
                        <input type="text" matInput placeholder="Infektionsart" [formControl]="infektionCtrl"
                               [matAutocomplete]="infektionAuto">
                    </mat-form-field>
                    <mat-autocomplete #infektionAuto="matAutocomplete" [displayWith]="displayInfektionsart">
                        <mat-option *ngFor="let i of filteredInfektionsarten | async" [value]="i">{{ i?.kuerzel }}
                            - {{ i?.bezeichnung }}
                        </mat-option>
                    </mat-autocomplete>
                    <span class="spacer"></span>
                    <mat-form-field style="min-width: 500px; width: 30%;">
                        <mat-label>Kommentar</mat-label>
                        <input type="text" matInput placeholder="Kommentar" [(ngModel)]="newTest.kommentar">
                    </mat-form-field>
                    <span class="spacer"></span>
                    <mat-form-field style="min-width: 300px; width: 30%;">
                        <mat-label>Kennzeichnung</mat-label>
                        <input type="text" matInput placeholder="Kennzeichnung" [(ngModel)]="newTest.kennzeichnung">
                    </mat-form-field>
                </div>
                <div class="one-row">
                    <span class="spacer"></span>
                    <button *kienePermission="[1812]" [disabled]="newTest?.testart == null" mat-flat-button
                            color="accent"
                            (click)="addTest()">Hinzuf&uuml;gen
                    </button>
                </div>

            </mat-card>
        </div>
        <h3 *ngIf="resTestList?.length > 0">Tests</h3>
        <mat-card *ngIf="resTestList?.length > 0" style="margin-top: 10px;">
            <table style="width: 100%;">
                <thead>
                <tr>
                    <th style="text-align: left; font-size: 12px; color: #777">#</th>
                    <th style="text-align: left; font-size: 12px; color: #777">Testart</th>
                    <th style="text-align: left; font-size: 12px; color: #777">Verdacht</th>
                    <th style="text-align: left; font-size: 12px; color: #777">Tierart</th>
                    <th style="text-align: left; font-size: 12px; color: #777">Infektionsart</th>
                    <th style="text-align: left; font-size: 12px; color: #777">Kommentar</th>
                    <th style="text-align: left; font-size: 12px; color: #777">Kennzeichnung</th>
                </tr>
                </thead>
                <tr *ngFor="let t of resTestList; let i = index">
                    <td style="width: 10%">{{ i + 1 }}.</td>
                    <td style="width: 15%">{{ t.testart }}</td>
                    <td style="width: 15%">{{ t.verdacht }}</td>
                    <td style="width: 15%">{{ t.tierart }}</td>
                    <td style="width: 15%">{{ getInfektionsartName(t.infektionsart) }}</td>
                    <td style="width: 15%">{{ t.kommentar }}</td>
                    <td style="width: 15%">{{ t.kennzeichnung }}</td>
                </tr>
            </table>
        </mat-card>
        <div *kienePermission="[1812]" class="one-row">
            <span class="spacer"></span>
            <button (click)="saveAntibiogrammOrder()" style="margin-top: 10px;" *ngIf="resTestList?.length > 0"
                    [disabled]="antibiogramm?.tagebuch_nr === null || antibiogramm?.tagebuch_nr === 'neu' || !currentGruppe"
                    mat-flat-button color="accent">Speichern
            </button>
        </div>
    </div>
    <div *ngIf="!isNewAntibiogramm">
        <div *ngIf="testResult == null && testDescription != null">
            <mat-card style="margin-top: 10px;">
                <table style="width: 100%;">
                    <thead>
                    <tr>
                        <th style="text-align: left; font-size: 12px; color: #777">#</th>
                        <th style="text-align: left; font-size: 12px; color: #777">Testart</th>
                        <th style="text-align: left; font-size: 12px; color: #777">Verdacht</th>
                        <th style="text-align: left; font-size: 12px; color: #777">Tierart</th>
                        <th style="text-align: left; font-size: 12px; color: #777">Infektionsart</th>
                        <th style="text-align: left; font-size: 12px; color: #777">Kommentar</th>
                        <th style="text-align: left; font-size: 12px; color: #777">Kennzeichnung</th>
                    </tr>
                    </thead>
                    <tr *ngFor="let t of testDescription?.testOrderResultRecords; let i = index">
                        <td style="width: 10%">{{ t.testOrderRecord?.isolatenNummer }}.</td>
                        <td style="width: 15%">{{ t.resultRecord?.testart }}</td>
                        <td style="width: 15%">{{ t.resultRecord?.verdacht }}</td>
                        <td style="width: 15%">{{ t.testOrderRecord?.einsenderKuerzel }}</td>
                        <td style="width: 15%">{{ getInfektionsartName(t.testOrderRecord?.materialartenKuerzel) }}</td>
                        <td style="width: 15%">{{ t.testOrderRecord?.isoKommentar }}</td>
                        <td style="width: 15%">{{ t.testOrderRecord?.kennzeichnung }}</td>
                    </tr>
                </table>
            </mat-card>
        </div>
        <div *ngIf="testResult != null" style="margin-top: 48px;">
            <h2>Ergebnisse</h2>
            <mat-card *ngFor="let torer of testResult?.testOrderResultExportRecords; let i = index;"
                      style="margin-bottom: 10px;">
                <h3 class="kiene-color">Isolat: {{ torer.testOrderRecord?.isolatenNummer }}</h3>
                <h4 style="color:#777;">Verdacht: {{ getVerdachtString(torer.resultRecords[0].measuredValue) }} |
                    Kennzeichnung:
                    {{ torer.testOrderRecord?.kennzeichnung }}
                    | Testart: {{ testDescription.testOrderResultRecords[i]?.resultRecord?.testart }} | Tierart:
                    {{ testDescription.testOrderResultRecords[i]?.testOrderRecord?.einsenderKuerzel }}
                    | Kommentar: {{ testDescription.testOrderResultRecords[i]?.testOrderRecord?.isoKommentar }}</h4>
                <table style="width: 100%; border-spacing: 0px;" class="test-result-table">
                    <tr>
                        <th style="text-align: left; padding: 10px;">Antibiotika</th>
                        <th style="text-align: left; padding: 10px;">Ergebnis</th>
                        <th style="text-align: left; padding: 10px;">Messwert</th>
                    </tr>
                    <tr *ngFor="let rr of torer?.resultRecords; let i = index;">
                        <td *ngIf="i>0"
                            style="width: 20%; padding: 10px; border-bottom: 1px solid #CCC;">{{ rr?.antibiotikaName }}
                        </td>
                        <td *ngIf="i>0"
                            style="width: 20%; padding: 10px; border-bottom: 1px solid #CCC;">{{ rr?.result }}
                        </td>
                        <td *ngIf="i>0"
                            style="width: 20%; padding: 10px; border-bottom: 1px solid #CCC;">{{ rr?.measuredValue }}
                        </td>
                    </tr>
                </table>
            </mat-card>
        </div>
    </div>
</div>
