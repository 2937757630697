import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {StammdatenService} from '../../stammdaten.service';
import {MessageService} from '../../../../services/message-service/message.service';
import {Haendler} from '../haendler';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AppService} from '../../../../services/app/app.service';
import {MatDialog} from '@angular/material/dialog';
import {HaendlerDetailsDialogComponent} from '../haendler-details-dialog/haendler-details-dialog.component';
import {ConfirmDialogComponent} from '../../../dialogs/confirm-dialog/confirm-dialog.component';
import {ConfirmDialogInput, ConfirmDialogResult} from '../../../dialogs/confirm-dialog/confirm-dialog';
import {Benutzerprofil} from '../../../stammdaten/mein-profil/mein-profil';
import {KieneBackendApiService} from '../../../../services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../kiene-core.config';

@Component({
    selector: 'kiene-haendler-uebersicht-favoriten-alt',
    templateUrl: './haendler-uebersicht-favoriten-alt.component.html',
    styleUrls: ['./haendler-uebersicht-favoriten-alt.component.scss']
})
export class HaendlerUebersichtFavoritenAltComponent implements OnInit, OnDestroy {

    benutzerprofil = new Benutzerprofil();
    favoritenHaendler: Haendler[];
    haendlerFehlt = this.stammdatenService.getHaendlerFehlt();
    watchHaendlerFehltSub: Subscription;
    haendlerFavoritenSubscription: Subscription;
    @Input('showReturnButton') showReturnButton = true;

    constructor(
        private stammdatenService: StammdatenService,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private appService: AppService,
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
    ) {
    }

    ngOnInit(): void {
        this.ladeBenutzerprofil();
        this.ladeFavoriten();

        this.watchHaendlerFehltSub = this.stammdatenService
            .watchHaendlerFehlt()
            .subscribe((fehlt) => {
                this.haendlerFehlt = fehlt;
            });
    }

    ngOnDestroy(): void {
        this.watchHaendlerFehltSub?.unsubscribe();
        this.haendlerFavoritenSubscription?.unsubscribe();
    }

    ladeFavoriten(): void {
        this.haendlerFavoritenSubscription?.unsubscribe();
        this.haendlerFavoritenSubscription = this.stammdatenService.loadHaendlerFavoriten().subscribe({
            next: (data) => {
                this.favoritenHaendler = [];
                this.favoritenHaendler = data.records;
            },
            error: (err: any) => {
                this.messageService.errorMessage(
                    'Fehler beim Laden aller Händler',
                    err
                );
            },
        });
    }

    setHaendlerAsDefault(haendler: Haendler): void {
        this.benutzerprofil.default_haendler_id = haendler.viehhaendler_id;
        this.updateBenutzerprofil();
    }

    ladeBenutzerprofil() {
        this.api
            .get(
                `${this.apiBaseUrl}benutzerprofil/read_one_kundenportal.php`)
            .subscribe({
                next: (response: Benutzerprofil) => {
                    this.benutzerprofil = response;
                },
                error: err => {
                    this.messageService.errorMessage('Profil konnte nicht geladen werden!', err);
                }
            });
    }

    updateBenutzerprofil() {
        this.api
            .updateGeneric(
                `${this.apiBaseUrl}benutzerprofil/update_kundenportal.php`,
                this.benutzerprofil
            )
            .subscribe({
                next: (response: Benutzerprofil) => {
                    this.messageService.infoMessage(
                        'Standardhändler erfolgreich gespeichert!'
                    );
                    this.benutzerprofil = response;
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler! ', err);
                },
            });
    }

    public updateFavoriten(updatedHaendler: Haendler) {
        if (updatedHaendler.favorit === 1) {
            this.favoritenHaendler.push(updatedHaendler);
        } else {
            this.favoritenHaendler = this.favoritenHaendler.filter(
                (entsorger) =>
                    entsorger.viehhaendler_id !==
                    updatedHaendler.viehhaendler_id
            );
        }
    }

    editiereHaendler(haendler: Haendler): void {
        if (!this.appService.isMobileScreen) {
            const dialogRef = this.dialog.open(HaendlerDetailsDialogComponent, {
                minWidth: '600px',
                data: {
                    haendler: haendler
                }
            });
            dialogRef.afterClosed().subscribe({
                next: () => {
                    this.ladeFavoriten();
                }
            });
        } else {
            this.router
                .navigate(['../details', haendler.viehhaendler_id], {
                    relativeTo: this.route,
                })
                .then();

        }
    }

    anlegenHaendler(): void {
        if (this.appService.isMobileScreen) {
            this.router.navigate(['details'], {relativeTo: this.route}).then();
        } else {
            const dialogRef = this.dialog.open(HaendlerDetailsDialogComponent, {
                minWidth: '600px'
            });
            dialogRef.afterClosed().subscribe({
                next: () => {
                    this.ladeFavoriten();
                }
            });
        }
    }

    sendMail(haendler: Haendler) {
        if (haendler.email) {
            const htmlAnchorElement = document.createElement('a');
            htmlAnchorElement.style.display = 'none';
            htmlAnchorElement.href = 'mailto:' + haendler.email;
            document.body.appendChild(htmlAnchorElement);
            htmlAnchorElement.click();
            // document.body.removeChild(htmlAnchorElement);
        }
    }

    deleteHaendler(h: Haendler) {
        const input = new ConfirmDialogInput();
        input.headline = 'Händler löschen';
        input.text = 'Wollen Sie den Händler ' + h.name + ' wirklich löschen?';
        input.okButton = 'Löschen';
        input.buttonColor = 'warn';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input
        });
        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result.ok && result.confirmed) {
                    this.stammdatenService.deleteHaendlerFavorit(h).subscribe({
                        next: response => {
                            this.messageService.infoMessage('Der Händler wurde erfolgreich entfernt!');
                            this.ladeFavoriten();
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler!', err);
                        }
                    });
                }
            }
        });
    }

}
