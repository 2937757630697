import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { HeimtierBeleg, HeimtierBelegArtikel, HeimtierBelegStatus } from '../../heimtier-beleg';
import {
    ArticleScanAutocompleteComponent,
    ArticleScanSelectedEvent
} from '../../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import { Anwendung, AnwendungEvent } from '../../../documents/behandlungsanfrage/anwendung';
import { Indikation, IndikationEvent } from '../../../warehouse/material/artikel-indikation/indikation';
import { FormControl, Validators } from '@angular/forms';
import { AnwendungsSelectorComponent } from '../../../components/anwendungs-selector/anwendungs-selector.component';
import { KieneUtils } from '../../../../../projects/kiene-core/src/lib/utils/KieneUtils';
import {
    DiagnoseSelectorComponent
} from '../../../components/diagnose-selector/diagnose-selector/diagnose-selector.component';
import { MessageService } from '../../../services/message.service';
import { Heimtier } from '../../kalender/heimtier-kalender-eintrag';
import { HttpParams } from '@angular/common/http';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { HeimtierDaten } from '../besuchs-detail-beleg/heimtier-daten-dialog/heimtier-daten';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { Material } from '../../../warehouse/material/material';

@Component({
    selector: 'kiene-leistungen',
    templateUrl: './leistungen.component.html',
    styleUrls: ['./leistungen.component.scss']
})
export class LeistungenComponent implements OnInit, OnChanges {

    disabled = false;
    focusOnInit = true;
    isSaving = false;

    tiere: Heimtier[] = [];
    tierCtrl = new FormControl();
    latestHeimtierDaten: HeimtierDaten;

    mengeExakt = '';

    @Input() heimtierBeleg: HeimtierBeleg;

    @Output() tierChange = new EventEmitter<Heimtier>();

    @ViewChild('articleAutocomplete') articleAutocomplete: ArticleScanAutocompleteComponent;
    @ViewChild('anwendungSelector') anwendungSelector: AnwendungsSelectorComponent;
    @ViewChild('diagnoseSelector') diagnoseSelector: DiagnoseSelectorComponent;
    @ViewChild('behandlungJaNein') behandlungJaNein: MatSelect;
    @ViewChild('anwendungsdauer') anwendungsdauer: MatInput;

    behandlungJaNeinCtrl = new FormControl();
    anwendungsdauerCtrl = new FormControl();
    mengeCtrl = new FormControl();
    tiergewichtCtrl = new FormControl();
    dosierungCtrl = new FormControl();
    faktorCtrl = new FormControl(1, [Validators.required]);
    position = new HeimtierBelegArtikel();
    private numberInputFocused: boolean;

    constructor(private messageService: MessageService, private api: KieneBackendApiService, @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    ngOnInit(): void {

        this.tierCtrl.valueChanges.subscribe({
            next: tier => {
                this.felderLeeren(true);
                this.position.tierart_id = tier?.tierart_id;
                this.position.heimtier_id = this.tiere[0].heimtier_id;
                this.ladeHeimtierDaten();
                this.anwendungSelector?.loadAnwendungen(this.position?.artikel_id, this.position?.tierart_id, false, false);
                this.tierChange.emit(tier);
            }
        });

        this.behandlungJaNeinCtrl.valueChanges.subscribe({
            next: value => {
                if (this.position) {
                    this.position.behandlung = value ? 1 : 0;
                }
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.ladeHeimtiere();
    }

    setSelectedMaterial(asse: ArticleScanSelectedEvent) {

        // this.felderLeeren(false);
        // if (!this.position) {
        //     this.position = new HeimtierBelegArtikel();
        // }

        // this.position.chargeJN = asse.article.charge;
        // this.position.impfung = asse.article.impfung;
        this.position.behandlung = asse.article.behandlung;
        // this.position.mengenartikel = asse.article.mengenartikel;
        this.position.abgabeundbehandlung = asse.article.abgabeundbehandlung;
        this.position.verpackungsmenge = asse.article.verpackungsmenge;
        this.position.artikel_id = asse.article.artikel_id;
        // this.position.artikel_me = asse.article.bezeichnung;
        this.position.artikel_me = asse.article.artikel_me;
        this.position.tierart_id = this.tierCtrl.value?.tierart_id;
        // this.position.stueckliste = asse.article.stueckliste;
        // this.position.anwendungsdauer_fix = asse.article.anwendungsdauer_fix;
        this.position.dosierung_fix = asse.article.dosierung_fix;

        this.ladeEinzelpreis();

        this.checkInputsDisabled(false);

        console.log('Position:', this.position);

        this.ladeArtikelTierart();

        this.anwendungSelector?.loadAnwendungen(this.position?.artikel_id, this.position?.tierart_id, true, true);

    }


    setSelectedAnwendung(event: AnwendungEvent) {
        if (KieneUtils.isNoU(event?.anwendung)) {
            return;
        }

        this.position.anwendung_id = event.anwendung.anwendung_id;
        this.position.anwendung = event.anwendung.anwendung;

        if (this.diagnoseSelector) {
            if (event.ladePosition) {
                this.diagnoseSelector.loadDiagnosen(
                    event.anwendung.artikel_id,
                    this.position?.tierart_id,
                    this.position?.anwendung_id,
                    false,
                    true
                );
            } else {
                this.diagnoseSelector.loadDiagnosen(
                    event.anwendung.artikel_id,
                    this.position?.tierart_id,
                    this.position?.anwendung_id,
                    true,
                    false
                );
            }
        }
    }

    setSelectedDiagnose(event: IndikationEvent) {
        if (KieneUtils.isNoU(event?.indikation)) {
            return;
        }

        this.position.indikation_id = event.indikation.indikation_id;
        this.position.diagnose = event.indikation.diagnose;

        if (this.istAbgabeUndBehandlung()) {
            this.behandlungJaNein.focus();
        } else {
            this.anwendungsdauer.focus();
        }

    }

    public felderLeeren(clearArticle: boolean) {
        this.anwendungSelector.clear();
        this.diagnoseSelector.clear();
        this.behandlungJaNeinCtrl.setValue(null, { emitEvent: false });
        this.anwendungsdauerCtrl.setValue(null, { emitEvent: false });
        this.mengeCtrl.setValue(null, { emitEvent: false });
        this.tiergewichtCtrl.setValue(null, { emitEvent: false });
        this.dosierungCtrl.setValue(null, { emitEvent: false });
        this.position = new HeimtierBelegArtikel();
        this.position.tierart_id - this.tierCtrl.value?.tierart_id;
        this.position.heimtier_id = this.tierCtrl.value?.heimtier_id;
        this.faktorCtrl.setValue(1, { emitEvent: false });

        this.anwendungsdauerCtrl.markAsUntouched();
        this.mengeCtrl.markAsUntouched();
        this.tiergewichtCtrl.markAsUntouched();
        this.dosierungCtrl.markAsUntouched();


        if (clearArticle) {
            this.articleAutocomplete.clearAndFocus();
        }
    }

    checkInputsDisabled(markAsTouched: boolean) {
        if (this.istMengenArtikel()) {
            this.behandlungJaNeinCtrl.disable({ emitEvent: false });
            this.tiergewichtCtrl.disable({ emitEvent: false });
            this.anwendungsdauerCtrl.disable({ emitEvent: false });
        } else {
            this.behandlungJaNeinCtrl.enable({ emitEvent: false });
            this.tiergewichtCtrl.enable({ emitEvent: false });
            this.anwendungsdauerCtrl.enable({ emitEvent: false });

            if (markAsTouched) {
                this.tiergewichtCtrl.markAsTouched();
            }
        }

        if (this.istMengenArtikel()) {

        } else {

            if (markAsTouched) {
                this.anwendungsdauerCtrl.markAsTouched();
            }
        }

        if (this.istMengenArtikel()) {
            this.dosierungCtrl.disable({ emitEvent: false });
        } else {
            this.dosierungCtrl.enable({ emitEvent: false });
            if (markAsTouched) {
                this.dosierungCtrl.markAsTouched();
            }
        }

        //        if (this.istDosierungFix()) {
        //          this.mengeCtrl.disable({ emitEvent: false });
        //    } else {
        this.mengeCtrl.enable({ emitEvent: false });
        if (markAsTouched) {
            this.mengeCtrl.markAsTouched();
        }
        //  }
    }

    // berechneTiergewicht(sender: string) {
    //     if (!this.istDosierungFix()) {
    //         return;
    //     }
    //
    //     if (!this.position?.verpackungsmenge) {
    //         this.messageService.alertMessage(
    //             'Inhaltsmenge des Medikaments in den Stammdaten nicht eingepflegt!'
    //         );
    //         return;
    //     }
    //     if (!this.position?.koerpergewicht_tierart) {
    //         // wenn Körpergewicht nicht angegeben (null / 0), wird davon ausgegangen,
    //         // dass die Dosierung pro Tier gilt, unabhängig vom Körpergewicht
    //         return;
    //     }
    //     if (!this.position?.dosierung_tierart) {
    //         this.messageService.alertMessage(
    //             'Dosierung je Körpergewicht in den Stammdaten nicht eingepflegt!'
    //         );
    //         return;
    //     }
    //     const anzahlTiere: number = this.anzahlTiereCtrl.value;
    //     const anwendungsdauer: number = this.anwendungsdauerCtrl.value;
    //     const menge: number = this.mengeCtrl.value;
    //
    //     const nenner =
    //         anwendungsdauer * anzahlTiere * this.position?.dosierung_tierart;
    //     if (!nenner) {
    //         return;
    //     }
    //
    //     const tiergewicht = Math.round(
    //         (menge *
    //             this.position?.verpackungsmenge *
    //             this.position?.koerpergewicht_tierart) /
    //         nenner
    //     );
    //     this.tiergewichtCtrl.setValue(tiergewicht, {emitEvent: false});
    //     this.berechneDosiermengeAnhandTiergewicht(tiergewicht);
    // }

    /**
     * nur aufrufen, wenn Dosierung Fix ist
     * @param tiergewicht
     * @returns
     */
    berechneDosiermengeAnhandTiergewicht(tiergewicht: number) {
        // if (!this.istDosierungFix()) {
        //     return;
        // }
        // z.B. 1ml / 10kg Gewicht
        if (!this.position?.dosierung) {
            this.messageService.alertMessage(
                'Dosierung je Körpergewicht in den Stammdaten nicht eingepflegt!'
            );
            return;
        }
        const dos = this.berechneDosierungFix(this.position?.koerpergewicht, this.position?.dosierung, tiergewicht);
        this.dosierungCtrl.setValue(dos, { emitEvent: false });
    }

    berechneDosierungFix(koerpergewicht_tierart: number, dosierung_: number, tiergewicht: number) {
        if (!koerpergewicht_tierart) {
            // wenn Körpergewicht nicht angegeben (null / 0), wird davon ausgegangen,
            // dass die Dosierung pro Tier gilt, unabhängig vom Körpergewicht
            return Math.round(dosierung_ * 100) / 100;
        }
        if (!tiergewicht) {
            return null;
        }
        let dos = (dosierung_ / koerpergewicht_tierart) * tiergewicht;
        return Math.round(dos * 100) / 100;
    }

    berechneDosiermenge(mindestmengeEins: boolean, sender: string) {
        if (this.istDosierungFix()) {
            console.log('Dosierung fix, keine Berechnung');
            return;
        }

        if (!this.mengeCtrl.value) {
            if (mindestmengeEins) {
                this.mengeCtrl.setValue(1, { emitEvent: false });
            } else {
                return;
            }
        }

        if (!this.position?.verpackungsmenge) {
            console.log('Inhaltsmenge des Medikaments in den Stammdaten nicht eingepflegt!');
            return;
        }

        const menge = this.mengeCtrl.value;
        const anzahlTiere = 1;
        const anwendungsdauer = this.anwendungsdauerCtrl.value;
        // Verpackungsmenge

        const dosiermenge =
            (Number.parseFloat(menge) * this.position?.verpackungsmenge) /
            (Number.parseFloat(anwendungsdauer));
        console.log('Dosiermenge:', dosiermenge);

        if (Number.isNaN(dosiermenge)) {
            return;
        }
        let mengeRound = Math.round(dosiermenge * 100) / 100;
        mengeRound = !isNaN(mengeRound) ? mengeRound : null;

        this.dosierungCtrl.setValue(mengeRound, {
            emitEvent: false,
        });
        this.position.dosierung = this.dosierungCtrl.value;
    }

    istDosierungFix() {
        return this.position?.dosierung_fix === 1;
    }

    berechneArtikelmenge(tiergewichtBerechnen: boolean) {
        if (!this.position?.verpackungsmenge) {
            this.messageService.alertMessage(
                'Inhaltsmenge des Medikaments in den Stammdaten nicht eingepflegt!'
            );
            return;
        }
        const anzahlTiere = 1;
        const dosierung: number = this.dosierungCtrl.value;
        const anwendungsdauer: number = this.anwendungsdauerCtrl.value;

        let menge =
            (dosierung * anzahlTiere * anwendungsdauer) /
            this.position.verpackungsmenge;
        menge = Math.round(menge * 1000) / 1000;
        if (!this.istPositionBehandlung()) {
            // wenn abgabe, nur in ganzen verpackungseinheiten
            this.mengeExakt = '' + menge;
            menge = Math.ceil(menge);
        } else {
            this.mengeExakt = '';
        }

        this.mengeCtrl.setValue(menge, { emitEvent: false });
        this.position.artikelmenge = this.mengeCtrl.value;

        // if (tiergewichtBerechnen) {
        //     this.berechneTiergewicht('berechneArtikelmenge');
        // }
    }

    getPlaceholderArtikel() {
        return 'Artikel auswählen';
    }

    materialInputCleared() {

    }

    istPositionBehandlung() {
        return this.position?.behandlung === 1;
    }

    istMengenArtikel() {
        return undefined;
    }

    istAbgabeUndBehandlung() {
        return this.position?.abgabeundbehandlung === 1;
    }

    onFocusNumberInput() {
        this.numberInputFocused = true;
    }

    anwendungsdauerChange() {
        this.position.anwendungsdauer = this.anwendungsdauerCtrl.value;
        this.berechneArtikelmenge(false);
        this.berechneDosiermenge(false, 'anwendungsdauerChanged');
    }

    tiergewichtChange() {
        console.log('Tiergewicht changed');
        this.position.tiergewicht = this.tiergewichtCtrl.value;
        this.berechneDosiermenge(false, 'tiergewichtChanged');
    }

    mengeChange() {
        this.numberInputFocused = false;
        this.position.artikelmenge = this.mengeCtrl.value;
        this.berechneDosiermenge(true, 'mengeChanged');
    }

    onFocusTiergewicht() {

    }

    onFocusDosierung() {

    }

    onBlurDosierung() {
        // this.savePosition();
    }

    savePosition() {
        if (!this.isSaving) {
            if (!this.position.faktor) {
                this.position.faktor = this.faktorCtrl.value;
            }
            if (this.position.hba_id) {
                const index = this.heimtierBeleg.positionen.indexOf(this.position);
                this.heimtierBeleg.positionen[index] = this.position;
            } else {
                this.heimtierBeleg.positionen.push(this.position);
            }
            this.updateBeleg();
        }
    }

    faktorChange() {
        this.position.faktor = this.faktorCtrl.value;
    }

    editPosition(pos: HeimtierBelegArtikel) {
        if (!pos.aus_stueckliste) {

            this.position = pos;

            /**
             * Artikel
             */
            const material = new Material();
            material.artikel_id = this.position?.artikel_id;
            material.artikel_me = this.position?.artikel_me;
            material.stueckliste = this.position?.aus_stueckliste;
            if (this.articleAutocomplete) {
                this.articleAutocomplete.setMaterial(material, false);
            }

            this.setzeTier(pos);
            this.setzeAnwendung(pos, true);
            this.setzeDiagnose(pos, true);
            this.setzeInputFelder(pos);
            this.setzeBehandlung();

            /**
             * Flags
             */


            this.checkInputsDisabled(true);

            this.articleAutocomplete?.setFocus();
        }
    }

    setzeDiagnose(pos: HeimtierBelegArtikel, ladePosition: boolean) {
        if (this.position.indikation_id) {
            const indikation = new Indikation();
            indikation.indikation_id = this.position.indikation_id;
            indikation.diagnose = this.position.diagnose;
            indikation.artikel_id = this.position.artikel_id;
            indikation.tierart_id = this.position.tierart_id;
            if (this.diagnoseSelector) {
                this.diagnoseSelector.setDiagnose(
                    indikation,
                    this.position.anwendung_id,
                    false,
                    ladePosition
                );
            }
        } else {
            if (
                this.diagnoseSelector &&
                this.position.artikel_id &&
                this.position.tierart_id
            ) {
                this.diagnoseSelector.loadDiagnosen(
                    this.position.artikel_id,
                    this.position.tierart_id,
                    this.position.anwendung_id,
                    false,
                    ladePosition
                );
            }
        }
    }

    setzeBehandlung() {
        if (KieneUtils.isNoU(this.position?.behandlung)) {
            this.position.behandlung = 0;
        }
        if (this.istAbgabeUndBehandlung()) {
            this.behandlungJaNeinCtrl.setValue(this.istPositionBehandlung(), {
                emitEvent: false,
            });
        }
    }

    setzeInputFelder(pos: HeimtierBelegArtikel) {
        /**
         * Anwendungsdauer
         */
        this.anwendungsdauerCtrl.setValue(pos.anwendungsdauer, {
            emitEvent: false,
        });

        /**
         * Menge
         */
        this.mengeCtrl.setValue(pos.artikelmenge, { emitEvent: false });

        /**
         * Tiergewicht
         */
        this.tiergewichtCtrl.setValue(pos.tiergewicht, {
            emitEvent: false,
        });

        /**
         * Dosierung
         */
        this.dosierungCtrl.setValue(pos.dosierung, { emitEvent: false });
        this.faktorCtrl.setValue(pos.faktor, { emitEvent: false });

    }

    removePosition(pos: HeimtierBelegArtikel) {
        const index = this.heimtierBeleg.positionen.indexOf(pos);
        if (index > -1) {
            this.heimtierBeleg.positionen.splice(index, 1);
            this.updateBeleg();
        }
    }

    private ladeEinzelpreis() {

    }

    private ladeHeimtiere() {
        if (this.heimtierBeleg?.kunde_id) {
            const params = new HttpParams().set('kunde_id', this.heimtierBeleg.kunde_id);
            this.api.getAll(this.apiBaseUrl + 'heimtier/read.php', params).subscribe({
                next: response => {
                    this.tiere = response.records;
                    if (this.tiere.length > 0) {
                        this.tierCtrl.setValue(this.tiere[0], { emitEvent: false });
                        this.position.tierart_id = this.tiere[0].tierart_id;
                        this.position.heimtier_id = this.tiere[0].heimtier_id;
                        this.ladeHeimtierDaten();
                    }
                },
                error: err => {
                    this.messageService.errorMessage('Fehler beim Laden der Heimtiere', err);
                }
            });
        }
    }

    private ladeHeimtierDaten() {
        if (this.tierCtrl.value) {
            const heimtier: Heimtier = this.tierCtrl.value;
            let params = new HttpParams();
            params = params.set('heimtier_id', heimtier.heimtier_id);
            params = params.set('orderby', 'erstellt desc');
            params = params.set('limit', '1');
            this.api.get(this.apiBaseUrl + 'heimtier_daten/read.php', params).subscribe({
                next: response => {
                    if (response.records.length > 0) {
                        this.latestHeimtierDaten = <HeimtierDaten>response.records[0];
                        this.tiergewichtCtrl.setValue(this.latestHeimtierDaten.gewicht);
                    } else {
                        this.latestHeimtierDaten = undefined;
                    }
                },
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        }
    }

    private ladeArtikelTierart() {
        let params = new HttpParams();
        params = params.set('artikel_id', this.position.artikel_id);
        params = params.set('tierart_id', this.position.tierart_id);
        this.api.get(this.apiBaseUrl + 'artikel_tierart/read.php', params).subscribe({
            next: response => {
                if (response.records?.length > 0) {
                    const artikelTierart = response.records[0];
                    this.position.tierart_id = artikelTierart.tierart_id;
                    this.position.tierart = artikelTierart.tierart;
                    this.position.dosierung_txt = artikelTierart.dosierung_txt;
                    this.position.menge = artikelTierart.menge;
                    this.position.koerpergewicht =
                        artikelTierart.koerpergewicht;

                    if (!this.anwendungsdauerCtrl.value) {
                        this.anwendungsdauerCtrl.setValue(
                            artikelTierart.behandlung_tage,
                            { emitEvent: false }
                        );
                        this.position.anwendungsdauer = artikelTierart.behandlung_tage;
                    }
                    if (!this.dosierungCtrl.value) {
                        this.dosierungCtrl.setValue(artikelTierart.menge, {
                            emitEvent: false,
                        });
                        this.position.dosierung = artikelTierart.menge;
                    }

                }
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private updateBeleg() {
        if (this.heimtierBeleg.status_id === HeimtierBelegStatus.ABGESCHLOSSEN || this.heimtierBeleg.status_id === HeimtierBelegStatus.ERSTELLT) {
            this.isSaving = true;this.api.post(this.apiBaseUrl + 'heimtier_beleg/update.php', this.heimtierBeleg).subscribe({
                next: response => {
                    this.heimtierBeleg = response;
                    this.felderLeeren(true);
                this.isSaving = false;},
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);this.isSaving = false;
                }
            });
        } else {
            this.messageService.alertMessage('Speichern in diesem Status nicht möglich!');
        }
    }

    private setzeTier(pos: HeimtierBelegArtikel) {
        const heimtier = new Heimtier();
        heimtier.heimtier_id = pos.heimtier_id;
        heimtier.name = pos.name;
        heimtier.tierart_id = pos.tierart_id;
        heimtier.tierart = pos.tierart;
        this.tierCtrl.setValue(heimtier, { emitEvent: false });
    }

    tierComparator(t1: Heimtier, t2: Heimtier) {
        return t1?.heimtier_id === t2?.heimtier_id;
    }


    private setzeAnwendung(pos: HeimtierBelegArtikel, ladePosition: boolean) {
        if (pos.anwendung_id) {
            const anwendung = new Anwendung();
            anwendung.anwendung_id = pos.anwendung_id;
            anwendung.anwendung = pos.anwendung;
            anwendung.artikel_id = pos.artikel_id;
            if (this.anwendungSelector) {
                this.anwendungSelector.setAnwendung(
                    anwendung,
                    pos.tierart_id,
                    false,
                    ladePosition
                );
            }
        } else {
            if (this.anwendungSelector && pos.artikel_id && pos.tierart_id) {
                this.anwendungSelector.loadAnwendungen(
                    pos.artikel_id,
                    pos.tierart_id,
                    false,
                    ladePosition
                );
            }
        }
    }
}
