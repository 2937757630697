import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {KieneAutocompleteComponent} from "./kiene-autocomplete.component";
import {DirectivesModule} from '../../../../projects/kiene-core/src/lib/directives/directives.module';

@NgModule({
    declarations: [
        KieneAutocompleteComponent
    ],
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatInputModule,
        MatIconModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        DirectivesModule
    ],
    exports: [
        KieneAutocompleteComponent
    ]
})
export class KieneAutocompleteModule {
}

