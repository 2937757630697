import {Client} from '../../../components/client/classes/client';
import {Betriebsart} from '../../../components/stammdaten/vvvo/classes/betriebsart';

export class HitLoginResponse {
    pin: string;
    abweichende_lieferadresse: number;
    benutzer_email: string;
    benutzer_nachname: string;
    benutzer_vorname: string;
    bezeichnung: string;
    email: string;
    fax: string;
    kunde_id: number;
    mobil_1: string;
    mobil_2: string;
    ort: string;
    ort_lieferung: string;
    ortsteil: string;
    ortsteil_lieferung: string;
    plz: string;
    plz_lieferung: string;
    sgs_nummer: string;
    tsk_nummer: string;
    strasse: string;
    strasse_lieferung: string;
    telefon_1: string;
    telefon_2: string;
    vvvo: string;
    zusatz: string;
    betriebsarten: Betriebsart[] = [];
    betriebsart: string;
    betriebsart_id: number;
    tierzahl_sauen: number;
    tierzahl_saugferkel: number;
    tierzahl_absatzferkel: number;
    tierzahl_mastschweine: number;
    tierzahl_kuehe: number;
    tierzahl_kaelber: number;
    tierzahl_fresser: number;
    tierzahl_bullen: number;
    tierzahl_mastrinder: number;
    landwirt: number;
    viehhaendler: number;

    constructor(options?: { hitLoginResponse: HitLoginResponse }) {
        if (options?.hitLoginResponse) {
            this.pin = options.hitLoginResponse.pin;
            this.abweichende_lieferadresse =
                options.hitLoginResponse.abweichende_lieferadresse;
            this.benutzer_email =
                options.hitLoginResponse.benutzer_email;
            this.benutzer_nachname =
                options.hitLoginResponse.benutzer_nachname;
            this.benutzer_vorname =
                options.hitLoginResponse.benutzer_vorname;
            this.bezeichnung =
                options.hitLoginResponse.bezeichnung;
            this.email = options.hitLoginResponse.email;
            this.fax = options.hitLoginResponse.fax;
            this.kunde_id = options.hitLoginResponse.kunde_id;
            this.mobil_1 = options.hitLoginResponse.mobil_1;
            this.mobil_2 = options.hitLoginResponse.mobil_2;
            this.ort = options.hitLoginResponse.ort;
            this.ort_lieferung =
                options.hitLoginResponse.ort_lieferung;
            this.ortsteil = options.hitLoginResponse.ortsteil;
            this.ortsteil_lieferung =
                options.hitLoginResponse.ortsteil_lieferung;
            this.plz = options.hitLoginResponse.plz;
            this.plz_lieferung =
                options.hitLoginResponse.plz_lieferung;
            this.sgs_nummer = options.hitLoginResponse.sgs_nummer;
            this.tsk_nummer = options.hitLoginResponse.tsk_nummer;
            this.strasse = options.hitLoginResponse.strasse;
            this.strasse_lieferung =
                options.hitLoginResponse.strasse_lieferung;
            this.telefon_1 = options.hitLoginResponse.telefon_1;
            this.telefon_2 = options.hitLoginResponse.telefon_2;
            this.vvvo = options.hitLoginResponse.vvvo;
            this.zusatz = options.hitLoginResponse.zusatz;
            this.betriebsart =
                options.hitLoginResponse.betriebsart;
            this.betriebsart_id =
                options.hitLoginResponse.betriebsart_id;

            this.tierzahl_sauen = options.hitLoginResponse.tierzahl_sauen;
            this.tierzahl_saugferkel = options.hitLoginResponse.tierzahl_saugferkel;
            this.tierzahl_absatzferkel = options.hitLoginResponse.tierzahl_absatzferkel;
            this.tierzahl_mastschweine = options.hitLoginResponse.tierzahl_mastschweine;
            this.tierzahl_kuehe = options.hitLoginResponse.tierzahl_kuehe;
            this.tierzahl_kaelber = options.hitLoginResponse.tierzahl_kaelber;
            this.tierzahl_fresser = options.hitLoginResponse.tierzahl_fresser;
            this.tierzahl_bullen = options.hitLoginResponse.tierzahl_bullen;
            this.tierzahl_mastrinder = options.hitLoginResponse.tierzahl_mastrinder;
        }
    }

    static generateClient(hitLoginResponse: HitLoginResponse): Client {
        const client = new Client();

        client.kunde_id = hitLoginResponse.kunde_id;

        client.email = hitLoginResponse.benutzer_email;
        client.telefon_1 = hitLoginResponse.telefon_1;
        client.telefon_2 = hitLoginResponse.telefon_2;
        client.mobil_1 = hitLoginResponse.mobil_1;
        client.mobil_2 = hitLoginResponse.mobil_2;
        client.fax = hitLoginResponse.fax;

        client.strasse = hitLoginResponse.strasse;
        client.plz = hitLoginResponse.plz;
        client.ort = hitLoginResponse.ort;
        client.ortsteil = hitLoginResponse.ortsteil;

        client.abweichende_lieferadresse =
            hitLoginResponse.abweichende_lieferadresse;
        client.ort_lieferung = hitLoginResponse.ort_lieferung;
        client.ortsteil_lieferung = hitLoginResponse.ortsteil_lieferung;
        client.plz_lieferung = hitLoginResponse.plz_lieferung;
        client.strasse_lieferung = hitLoginResponse.strasse_lieferung;

        client.bezeichnung = hitLoginResponse.bezeichnung;
        client.zusatz = hitLoginResponse.zusatz;
        client.vvvo = hitLoginResponse.vvvo;
        client.sgs_nummer = hitLoginResponse.sgs_nummer;
        client.tsk_nummer = hitLoginResponse.tsk_nummer;
        client.betriebsart = hitLoginResponse.betriebsart;
        client.betriebsart_id = hitLoginResponse.betriebsart_id;

        client.tierzahl_sauen = hitLoginResponse.tierzahl_sauen;
        client.tierzahl_saugferkel = hitLoginResponse.tierzahl_saugferkel;
        client.tierzahl_absatzferkel = hitLoginResponse.tierzahl_absatzferkel;
        client.tierzahl_mastschweine = hitLoginResponse.tierzahl_mastschweine;
        client.tierzahl_kuehe = hitLoginResponse.tierzahl_kuehe;
        client.tierzahl_kaelber = hitLoginResponse.tierzahl_kaelber;
        client.tierzahl_fresser = hitLoginResponse.tierzahl_fresser;
        client.tierzahl_bullen = hitLoginResponse.tierzahl_bullen;
        client.tierzahl_mastrinder = hitLoginResponse.tierzahl_mastrinder;

        return client;
    }
}
