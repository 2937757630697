import { Component, OnDestroy, OnInit } from '@angular/core';
import { TableDescriptor, ToolbarButton } from '../../components/kiene-table/table-descriptor';
import { environment } from '../../../environments/environment';
import { TableColumn } from '../../components/kiene-table/table-column';
import { TableOption, TableOptionEvent } from '../../components/kiene-table/table-option';
import { AddPreisgruppeComponent } from './add-preisgruppe/add-preisgruppe.component';
import { MatDialog } from '@angular/material/dialog';
import { Preisgruppe } from './preisgruppen';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'kiene-preisgruppen',
  templateUrl: './preisgruppen.component.html',
  styleUrls: ['./preisgruppen.component.scss']
})
export class PreisgruppenComponent implements OnInit, OnDestroy {

  tableDescriptor: TableDescriptor = new TableDescriptor();

  constructor(
    public dialog: MatDialog,
    private tableService: KieneTableService,
    private messageService: MessageService
  ) {

  }

  ngOnDestroy(): void {
    this.tableService = null;
  }

  ngOnInit() {
    this.tableDescriptor.headline = 'Preisgruppen';
    this.tableDescriptor.uniqueIdentifier = 'Preisgruppen';
    this.tableDescriptor.apiUrl = environment.BASE_URI + 'preisgruppe/';
    this.tableDescriptor.searchPlaceholder = 'Preisgruppen durchsuchen';
    this.tableDescriptor.createAllowed = false;
    this.tableDescriptor.columns.push(new TableColumn('preisgruppe_id', 'ID'));
    this.tableDescriptor.columns.push(new TableColumn('bezeichnung', 'Bezeichnung'));

    this.tableDescriptor.showDetails = true;
    this.tableDescriptor.route = '/stammdaten/preisgruppen/';
    this.tableDescriptor.nameOfIdField = 'preisgruppe_id';

    this.tableDescriptor.permissionCreate = 2992;
    this.tableDescriptor.permissionDelete = 2994;
    this.tableDescriptor.permissionUpdate = 2992;
    this.tableDescriptor.permissionView = 2991;

    this.tableDescriptor.initialSortColumn = 'preisgruppe_id';
    this.tableDescriptor.initialSortDirection = 'desc';

    this.tableDescriptor.toolbarButtons.push(new ToolbarButton('add', 'add', 2992));

    this.tableDescriptor.options.push(new TableOption('edit', 'Editieren', 'edit', 2992));
    this.tableDescriptor.options.push(new TableOption('delete', 'Löschen', 'delete', 2994));
  }

  catchOptionEvent(event: TableOptionEvent) {
    if (event.name === 'add') {
      this.openAddPreisgruppeDialog(null);
    } else if (event.name === 'edit') {
      this.openAddPreisgruppeDialog(event.value);
    } else if (event.name === 'delete') {
      this.openDeleteDialog(event.value);
    }
  }

  openAddPreisgruppeDialog(preisgruppe: Preisgruppe) {
    let dialogRef = this.dialog.open(AddPreisgruppeComponent, {
      width: '50%',
      minWidth: '300px',
      data: {
        preisgruppe: preisgruppe
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      this.tableService.reload();
    });
  }

  openDeleteDialog(preisgruppe: Preisgruppe) {
    let dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '500px',
      data: {
        title: 'Preisgruppe wirklich löschen?',
        text: 'Preisgruppe wirklich löschen?'
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.tableService.deleteEntity(environment.BASE_URI + 'preisgruppe/delete.php', preisgruppe).subscribe(result => {
          this.messageService.infoMessage('Die Preisgruppe wurde erfolgreich gelöscht!');
          this.tableService.reload();
        }, error => {
          this.messageService.errorMessage('Die Preisgruppe konnte nicht gelöscht werden! ', error);
        });
      }
    });
  }

}
