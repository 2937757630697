import { Client } from '../../client/classes/client';

export class MeineKontakteDialogInput {
    intern: boolean;
    extern: boolean;
    kunde_id_a: number;

    constructor(intern: boolean, extern: boolean, kunde_id: number) {
        this.intern = intern;
        this.extern = extern;
        this.kunde_id_a = kunde_id;
    }
}

export class MeineKontakteDialogResult {
    kunde: Client;

    constructor(kunde: Client) {
        this.kunde = kunde;
    }
}