<h1 mat-dialog-title>
    {{
    tour && tour.bestellung_id
    ? "Tour editieren"
    : "Eine neue Tour hinzufügen"
    }}
</h1>
<div mat-dialog-content>
    <div class="one-row">
        <button style="height: 46px; margin-right: 5px; margin-bottom: 16px" mat-flat-button color="accent"
            (click)="openKWDialog()" matTooltip="Kalenderwoche auswählen">
            KW
        </button>
        <mat-form-field style="margin-right: 12px">
            <input matInput [matDatepicker]="pickerFrom" placeholder="Zieldatum von" [formControl]="zieldatumFromCtrl"
                autofocus />
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <input matInput [matDatepicker]="pickerTo" placeholder="Zieldatum bis" [formControl]="zieldatumToCtrl" />
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div *ngIf="showKunde">
        <mat-checkbox *kienePermission="[1168]" [formControl]="apothekeCtrl">Über Apotheke
        </mat-checkbox>
        <p style="font-size: 14px; font-weight: bold; color: #aaa">
            Entweder eine Unternehmensgruppe oder einen Kunden eingeben
        </p>
        <div class="one-row">
            <div style="width: 40%">
                <kiene-company-group-autocomplete #companyGroupAuto [companyGroup]="currentCompanyGroup"
                    (selectedCompanyGroup)="setUnternehmensgruppe($event)"></kiene-company-group-autocomplete>
            </div>
            <div>oder</div>
            <div style="width: 40%">
                <kiene-client-autocomplete [panelWidth]="'50%'" [client]="currentClient" [placeholder]="'Kunde'"
                    [showVVVO]="true" (selectedClient)="setClient($event)" [onlyActiveClients]="true">
                </kiene-client-autocomplete>
            </div>
        </div>

        <mat-divider></mat-divider>
        <mat-form-field style="width: 100%">
            <textarea matInput [formControl]="notizCtrl" rows="3" placeholder="Notiz hinzufügen..."></textarea>
        </mat-form-field>
    </div>
    <h4>Artikel</h4>
    <table class="phito-table">
        <thead>
            <tr>
                <th></th>
                <th>Artikel</th>
                <th>Menge</th>
                <th>Anzahl Tiere</th>
                <th>Identifikation</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let am of artikelMenge">
                <td></td>
                <td style="width: 50%">{{ am.article.artikel_me }}</td>
                <td style="width: 10%">{{ am.menge | number: "1.0-1" }}</td>
                <td style="width: 10%">
                    {{ am.anzahl_tiere | number: "1.0-0" }}
                </td>
                <td style="width: 20%">{{ am.identifikation }}</td>
                <td style="width: 10%">
                    <button mat-icon-button [matMenuTriggerFor]="artikelMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #artikelMenu="matMenu">
                        <button mat-menu-item (click)="editArtikel(am)">
                            <mat-icon>edit</mat-icon>
                            <span>Bearbeiten</span>
                        </button>
                        <button mat-menu-item (click)="removeArtikel(am)">
                            <mat-icon>delete</mat-icon>
                            <span>L&ouml;schen</span>
                        </button>

                    </mat-menu>
                </td>
            </tr>
        </tbody>
    </table>

    <table style="width: 100%">
        <tbody>
            <tr>
                <td></td>
                <td style="width: 50%">
                    <kiene-article-scan-autocomplete #articleAuto [focus]="false" [placeholder]="'Artikel'"
                        (selectedMaterial)="setSelectedMaterial($event)" [gesperrtAusblenden]="true">
                    </kiene-article-scan-autocomplete>
                </td>
                <td style="width: 5%">
                    <mat-form-field style="width: 100px">
                        <mat-label>Menge</mat-label>
                        <input #menge="matInput" matInput type="number" [formControl]="mengeCtrl"
                            (blur)="setFocusToAnzahlTiere()" (keyup.enter)="setFocusToAnzahlTiere()" />
                    </mat-form-field>
                </td>
                <td style="width: 5%">
                    <mat-form-field style="width: 120px">
                        <mat-label>Anzahl Tiere</mat-label>
                        <input #anzahlTiere="matInput" matInput type="number" [formControl]="anzahlTiereCtrl"
                            (blur)="setFocusToIdentification()" (keyup.enter)="setFocusToIdentification()" />
                    </mat-form-field>
                </td>
                <td style="width: 30%">
                    <mat-form-field>
                        <mat-label>Identifikation</mat-label>
                        <input #identifikation="matInput" matInput type="text" [formControl]="identifikationCtrl"
                            (keyup.enter)="addPositionToList()" (blur)="addPositionToList()" />
                    </mat-form-field>
                </td>
                <td style="width: 10%">
                    <button mat-icon-button color="accent" (click)="addPositionToList()">
                        <mat-icon>add_circle</mat-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">
        {{ tour && tour.bestellung_id ? "Speichern" : "Hinzufügen" }}
    </button>
</div>