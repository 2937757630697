import {Artikelliste} from '../../beleg-position/artikelliste-auswahl-dialog/artikelliste-auswahl-dialog';

export class BelegeVorlageDialogInput {
    artikelliste: Artikelliste;
}

export class BelegeVorlageDialogResult {
    confirm = false;
    artikelliste: Artikelliste;
}
