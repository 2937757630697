import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TextBausteinDirective} from './text-baustein.directive';
import {TextProposalDirective} from './text-proposal.directive';
import {ClickStopPropagationDirective} from './click-stop-propagation.directive';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MouseOverClassDirective} from './mouse-over-class.directive';


@NgModule({
    declarations: [TextBausteinDirective, TextProposalDirective, ClickStopPropagationDirective, ClickStopPropagationDirective, MouseOverClassDirective],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule
    ],
    exports: [
        TextBausteinDirective,
        TextProposalDirective,
        ClickStopPropagationDirective,
        MouseOverClassDirective
    ]
})
export class KieneDirectivesModule {
}
