import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {Material} from '../material/material';
import {
    ArticleScanAutocompleteComponent,
    ArticleScanSelectedEvent,
} from '../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {DateTableColumn, TableColumn} from '../../components/kiene-table/table-column';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {InventurEintrag} from './entities/InventurEintrag';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {Vet} from '../../administration/vet/vet';
import {MessageService} from '../../services/message.service';
import {StoragePlace} from '../storage-place/storage-place';
import {Condition, Operator, TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {MatDialog} from '@angular/material/dialog';
import {InventurEditDialogComponent} from './inventur-edit-dialog/inventur-edit-dialog.component';
import {InventurDeleteDialogComponent} from './inventur-delete-dialog/inventur-delete-dialog.component';
import {KieneAutocompleteComponent} from '../../components/kiene-autocomplete/kiene-autocomplete.component';
import {VetService} from '../../administration/vet/vet.service';
import {Inventur} from './entities/Inventur';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {KieneSession} from '../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import {Charge} from '../charges/charges.component';
import {MatInput} from '@angular/material/input';

@Component({
    selector: 'kiene-inventur',
    templateUrl: './inventur.component.html',
    styleUrls: ['./inventur.component.scss'],
})
export class InventurComponent implements OnInit, AfterViewInit {
    mengeCtrl: UntypedFormControl = new UntypedFormControl(null, [
        Validators.required,
        Validators.min(0),
    ]);
    tableDescriptor: TableDescriptor = new TableDescriptor();
    lagerplatzPlaceholder = 'Lagerplatz';

    tierarzt: Vet;
    kieneSession: KieneSession;
    currentLagerplatz: StoragePlace;
    selectedArtikel: Material;
    selectedCharge: Charge;
    selectedChargeString: string;
    chargeDisabled = true;
    chargeParams: HttpParams;
    mhdCtrl = new FormControl();
    disabledTierarzt = true;
    inventur: Inventur;

    inventurId: number;

    @ViewChild('artikelAutocomplete')
    artikelAutocomplete: ArticleScanAutocompleteComponent;

    @ViewChild('lagerplatzAutocomplete')
    lagerplatzAutocomplete: KieneAutocompleteComponent;

    @ViewChild('chargeAutocomplete') chargeAutocomplete: KieneAutocompleteComponent;

    @ViewChild('mhdInput') mhdInput: MatInput;
    @ViewChild('mengeInput') mengeInput: MatInput;

    constructor(
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private vetService: VetService,
        private messageService: MessageService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService
    ) {
        const id = this.route.snapshot.paramMap.get('id');
        if (id) {
            this.inventurId = Number.parseInt(id, 10);
        }
    }

    ngOnInit(): void {
        this.mengeCtrl.disable();
        if (isNaN(this.inventurId)) {
            this.messageService.alertMessage('Die Inventur ID ist ungültig!');
            return;
        } else {
            this.ladeInventur();
        }

        this.kieneSession = this.localStorageService.getCurrentUser();

        if (this.kieneSession.tierarzt_id) {
            this.loadTierarzt();
        }

        this.disabledTierarzt = !!this.kieneSession.tierarzt_id;
        if (this.disabledTierarzt) {
            this.lagerplatzPlaceholder = 'Lager: Tierarztwagen';
        }

        this.mengeCtrl.valueChanges.subscribe({
            next: () => {
                this.mengeCtrl.markAsTouched();
            },
        });

        this.mhdCtrl.disable();

        this.initTable();

    }

    initTable() {
        this.tableDescriptor.headline = this.inventur?.name;
        this.tableDescriptor.uniqueIdentifier = this.inventur?.name;
        this.tableDescriptor.apiUrl =
            this.apiBaseUrl + 'inventur_eintrag/';
        this.tableDescriptor.route = '/lager/inventur/';
        this.tableDescriptor.nameOfIdField = 'inventur_eintrag_id';
        this.tableDescriptor.searchPlaceholder = 'Artikel durchsuchen';

        this.tableDescriptor.params = new HttpParams().set('inventur_id', this.inventurId.toString());

        this.tableDescriptor.columns.push(
            new TableColumn('lagerplatz', 'Lagerplatz Nr.')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('artikel_me', 'Artikel')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('charge', 'Charge')
        );
        this.tableDescriptor.columns.push(new DateTableColumn('mhd', 'MHD', 'dd.MM.yyyy'));
        this.tableDescriptor.columns.push(new TableColumn('menge', 'Menge'));
        this.tableDescriptor.columns.push(
            new DateTableColumn('erstellt', 'Erfasst am', 'dd.MM.yy')
        );

        this.tableDescriptor.options = this.getOptions();

        this.tableDescriptor.initialSortColumn = 'erstellt';
        this.tableDescriptor.initialSortDirection = 'desc';
        this.tableDescriptor.showDetails = false;
    }

    loadTierarzt() {
        this.vetService.getVetForId(this.kieneSession.tierarzt_id).subscribe({
            next: (vet) => {
                this.tierarzt = vet;
                if (!this.tierarzt.lagerplatz_id) {
                    this.disabledTierarzt = false;
                }
            },
        });
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'bearbeiten') {
            this.openEditDialog(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.artikelAutocomplete.setFocus();
        }, 1100);
    }

    openDeleteDialog(val: InventurEintrag) {
        const dialogRef = this.dialog.open(InventurDeleteDialogComponent, {
            data: {
                inventurEintrag: val,
            },
        });
        dialogRef.afterClosed().subscribe({
            next: (toDelete: boolean) => {
                if (toDelete) {
                    this.deleteInventurEintrag(val);
                }
            },
        });
    }

    deleteInventurEintrag(inventurEintrag: InventurEintrag) {
        this.tableService
            .deleteEntity(`${this.apiBaseUrl}inventur_eintrag/delete.php`, inventurEintrag)
            .subscribe({
                next: () => {
                    this.tableService.reload();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Der Inventureintrag konnte nicht gelöscht werden!',
                        err
                    );
                },
            });
    }

    resetInputFields() {
        this.mengeCtrl.reset();
        this.mhdCtrl.reset();
        this.chargeAutocomplete.clear();
        this.selectedCharge = undefined;
        this.selectedChargeString = undefined;
        this.mengeCtrl.markAsUntouched();
        this.artikelAutocomplete.clear();
        this.tableService.reload();
        this.artikelAutocomplete.setFocus();
        if (!this.disabledTierarzt) {
            this.lagerplatzAutocomplete.clear();
            this.lagerplatzAutocomplete.markAsUntouched();
        }
    }

    openEditDialog(value: InventurEintrag) {
        const dialogRef = this.dialog.open(InventurEditDialogComponent, {
            data: {
                inventurEintrag: value,
            },
        });
        dialogRef.afterClosed().subscribe({
            next: (inventurEintrag: InventurEintrag) => {
                if (inventurEintrag) {
                    this.updateInventurEintrag(inventurEintrag);
                }
            },
        });
    }

    updateInventurEintrag(inventurEintrag: InventurEintrag) {
        this.tableService
            .updateEntity(this.apiBaseUrl + 'inventur_eintrag/update.php', inventurEintrag)
            .subscribe({
                next: () => {
                    this.tableService.reload();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Der Inventureintrag konnte nicht gespeichert werden!',
                        err
                    );
                },
            });
    }

    saveNewInventurEintrag() {
        if (this.mengeCtrl.valid && this.selectedArtikel) {
            const entity = {
                menge: this.mengeCtrl.value,
                artikelid: this.selectedArtikel.artikel_id,
                lagerplatzid: this.currentLagerplatz?.lagerplatz_id,
                charge_id: this.selectedCharge ? this.selectedCharge.charge_id : undefined,
                charge: this.selectedChargeString ? this.selectedChargeString : undefined,
                mhd: this.mhdCtrl.value
            };
            this.tableService
                .post(this.apiBaseUrl + 'inventur_eintrag/create.php', entity)
                .subscribe({
                    next: () => {
                        this.resetInputFields();
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Der Inventureintrag konnte nicht angelegt werden!',
                            err
                        );
                        this.lagerplatzAutocomplete.clear();
                        this.currentLagerplatz = undefined;
                        this.artikelAutocomplete.setFocus();
                        // this.resetInputFields();
                    },
                });
        }
        return;
    }

    artikelSelected(event: ArticleScanSelectedEvent) {
        console.log('artikelSelected: ' + event);
        this.selectedArtikel = event.article;
        this.chargeParams = new HttpParams();
        this.chargeParams = this.chargeParams.set('artikel_id', this.selectedArtikel.artikel_id);
        this.chargeDisabled = false;
        if (event.charge) {
            this.selectedChargeString = event.charge;
        }
        if (event.mhd) {
            this.mhdCtrl.setValue(event.mhd);
        }
    }

    chargeSelected(charge: any) {
        if (typeof charge === 'object') {
            this.selectedCharge = charge;
            this.mhdCtrl.setValue(new Date(this.selectedCharge?.mhd), {emitEvent: false});
            this.mengeInput?.focus();
        } else {
            this.selectedChargeString = charge;
            this.mhdCtrl.enable();
            this.mhdInput?.focus();
        }
    }

    setCurrentLagerplatz(value: StoragePlace) {
        this.currentLagerplatz = value;
        if (this.currentLagerplatz) {
            this.saveNewInventurEintrag();
        }
    }

    skipOrSave() {
        if (this.disabledTierarzt && this.mengeCtrl.valid) {
            this.saveNewInventurEintrag();
        }
    }

    artikelCleared(cleared: boolean) {
        if (cleared) {
            if (this.selectedCharge) {
                this.selectedCharge = undefined;
                this.chargeAutocomplete.clear();
                this.mhdCtrl.setValue(undefined, {emitEvent: false});
            }
        }
    }

    private ladeInventur() {
        this.api.getByIdAlternative(this.apiBaseUrl + 'inventur/read_one.php', 'inventur_id', this.inventurId.toString(10)).subscribe({
            next: response => {
                this.inventur = response;
                this.tableDescriptor.headline = this.inventur.name;
                // this.initTable();
                if (this.inventur.aktiv) {
                    this.mengeCtrl.enable();
                }
                this.tableService.reload('ladeInventur');
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private getOptions() {
        const options: TableOption[] = [];

        options.push(
            new TableOption('bearbeiten', 'Bearbeiten', 'edit', 4432, {
                conditions: [
                    new Condition('aktiv', Operator.GLEICH, 1)
                ]
            })
        );
        options.push(
            new TableOption('delete', 'Löschen', 'delete', 4434, {
                conditions: [
                    new Condition('aktiv', Operator.GLEICH, 1)
                ]
            })
        );

        return options;
    }
}
