import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Heimtier } from '../../kalender/heimtier-kalender-eintrag';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { MatDialog } from '@angular/material/dialog';
import { HeimtierBeleg } from '../../heimtier-beleg';
import { HeimtierDaten } from '../besuchs-detail-beleg/heimtier-daten-dialog/heimtier-daten';
import { HttpParams } from '@angular/common/http';
import { KieneBackendApiService } from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { HeimtierDatenDialogComponent } from '../besuchs-detail-beleg/heimtier-daten-dialog/heimtier-daten-dialog.component';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { ConfirmDialogInput, ConfirmDialogResult } from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import { ConfirmDialogComponent } from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'kiene-besuchs-detail-untersuchung',
  templateUrl: './besuchs-detail-untersuchung.component.html',
  styleUrls: ['./besuchs-detail-untersuchung.component.scss']
})
export class BesuchsDetailUntersuchungComponent implements OnInit, OnChanges {

  @Output() heimtierChange = new EventEmitter<Heimtier>();
  @Input() heimtierBeleg: HeimtierBeleg;

  tierCtrl = new FormControl();
  tiere: Heimtier[] = [];
  heimtierDaten: HeimtierDaten[] = [];

  constructor(
    @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string, private dialog: MatDialog, private api: KieneBackendApiService, private messageService: MessageService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.ladeTiere();
  }

  ngOnInit(): void {
    this.tierCtrl.valueChanges.subscribe({
      next: (heimtier: Heimtier) => {
        this.ladeHeimtierDaten();
        this.heimtierChange.emit(heimtier);
      }
    });
  }

  private ladeTiere() {
    if (this.heimtierBeleg?.kunde_id) {
      const params = new HttpParams().set('kunde_id', this.heimtierBeleg.kunde_id);
      this.api.getAll(this.apiBaseUrl + 'heimtier/read.php', params).subscribe({
        next: response => {
          this.tiere = response.records;
          if (this.tiere.length > 0) {
            this.tierCtrl.setValue(this.tiere[0], { emitEvent: true });
          }
        },
        error: err => {
          this.messageService.errorMessage('Fehler beim Laden!', err);
        }
      });
    }
  }

  createHeimtierDaten() {
    const tier = this.tierCtrl.value;
    if (tier) {
      const daten = new HeimtierDaten();
      daten.heimtier_id = tier.heimtier_id;
      this.dialog.open(HeimtierDatenDialogComponent, {
        data: daten
      }).afterClosed().subscribe({
        next: result => {
          if (result?.confirm) {
            this.ladeHeimtierDaten();
            this.reloadTier(); // Gewicht hat sich geändert
          }
        }
      });
    }
  }

  reloadTier() {
    if (this.tierCtrl.value?.heimtier_id) {
      const params = new HttpParams().set('heimtier_id', this.tierCtrl.value?.heimtier_id);
      this.api.get(this.apiBaseUrl + 'heimtier/read_one.php', params).subscribe({
        next: (result: Heimtier) => {
          this.heimtierChange.emit(result);
        },
        error: err => {
          this.messageService.errorMessage('Fehler!', err);
        }
      });
    }
  }

  ladeHeimtierDaten() {
    let params = new HttpParams();
    params = params.append('heimtier_id', this.tierCtrl.value?.heimtier_id);
    params = params.append('orderby', 'erstellt desc');
    this.api.getAll(this.apiBaseUrl + 'heimtier_daten/read.php', params).subscribe({
      next: response => {
        this.heimtierDaten = response.records;
      }, error: err => {
        this.messageService.errorMessage('Fehler beim Laden der Heimtierdaten', err);
      }
    })
  }

  editDaten(daten: HeimtierDaten) {
    this.dialog.open(HeimtierDatenDialogComponent, {
      data: daten
    }).afterClosed().subscribe({
      next: result => {
        if (result?.confirm) {
          this.ladeHeimtierDaten();
          this.reloadTier(); // Gewicht hat sich geändert
        }
      }
    });
  }

  deleteDaten(daten: HeimtierDaten) {
    const input = new ConfirmDialogInput();
    input.headline = 'Daten löschen?';
    input.text =
      'Sollen die Daten unwiderruflich gelöscht werden?';
    input.okButton = 'Löschen';

    this.dialog.open(ConfirmDialogComponent, {
      data: input,
    }).afterClosed().subscribe({
      next: (result: ConfirmDialogResult) => {
        if (result?.confirmed) {
          this.api.deleteEntity(this.apiBaseUrl + 'heimtier_daten/delete.php', daten).subscribe({
            next: () => {
              this.ladeHeimtierDaten();
              this.reloadTier();
            },
            error: err => {
              this.messageService.errorMessage('Fehler beim Löschen!', err);
            }
          });
        }
      }
    });
  }

}
