<script src="lieferant-kunden-nr-verwalten-dialog.component.ts"></script><h1 mat-dialog-title>Lieferant Kundennr.</h1>

<div mat-dialog-content>
    <kiene-lieferant-kunden-nr-verwalten (saved)="dialogRef.close($event)" [dialog]="true"
                                         [futtermittellieferant]="futtermittellieferant">
        <ng-container additional-actions>
            <button type="button" mat-button (click)="dialogRef.close()">Abbrechen</button>
        </ng-container>
    </kiene-lieferant-kunden-nr-verwalten>
</div>
