<div mat-dialog-title>
    <h3>Urlaubsantrag stellen</h3>
</div>
<div mat-dialog-content>

    <div style="font-size: 0.8em; padding: 6px 0 24px 0">
        <mat-hint >
            <strong>Hinweis:</strong>
            Der Urlaubsantrag wird nach dem Anlegen bereits im Kalender angezeigt.
            Der Urlaub muss jedoch noch genehmigt werden. Bei einer Genehmigung wird automatisch eine E-Mail an Sie gesendet.
        </mat-hint>
    </div>

    <div>
        <kiene-autocomplete *ngIf="hasPermission()" style=" width: 100%" [showClear]="false" [showDeleteButton]="false"
                            [required]="true" [genericApiUrl]="userPath" [displayFields]="['vorname', 'nachname']" [params]="params"
                            [placeholder]="'Antragssteller'" [kieneObject]="currentUser" [emitFreeText]="true" [focus]="false" (elementSelected)="selectedAntragssteller($event)">
        </kiene-autocomplete>
    </div>


    <div style="margin-bottom: 12px">
        <mat-form-field style="width: 100%" class="no-padding-margin-mat-form-field">
            <mat-label>Zeitraum wählen</mat-label>
            <mat-date-range-input [formGroup]="datumCtrl" [rangePicker]="picker">
                <input matStartDate [formControl]="datumVonCtrl" placeholder="Startdatum">
                <input matEndDate [formControl]="datumBisCtrl" placeholder="Enddatum">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="datumCtrl.controls.datumVonCtrl.hasError('matStartDateInvalid')">Ungültiges Startdatum.</mat-error>
            <mat-error *ngIf="datumCtrl.controls.datumBisCtrl.hasError('matEndDateInvalid')">Ungültiges Startdatum.</mat-error>
            <mat-error *ngIf="datumCtrl.hasError('required')">Der Zeitraum ist ein Pflichtfeld.</mat-error>
        </mat-form-field>

        <div *ngIf="datumVonCtrl.value && datumBisCtrl.value">

            <div *ngIf="sameDates(datumVonCtrl.value, datumBisCtrl.value)">
                <mat-checkbox (change)="setHalbtags({halbtags_start: $event.checked ? 1 : 0, halbtags_end: $event.checked ? 1 : 0})">halbtags</mat-checkbox>
            </div>

            <div *ngIf="!sameDates(datumVonCtrl.value, datumBisCtrl.value)" class="one-row" style="justify-content: space-between">
                <mat-checkbox (change)="setHalbtags({halbtags_start: $event.checked ? 1 : 0})">halbtags {{datumVonCtrl.value | date: 'dd.MM.YYYY'}}</mat-checkbox>
                <mat-checkbox (change)="setHalbtags({halbtags_end: $event.checked ? 1 : 0})">halbtags {{datumBisCtrl.value | date: 'dd.MM.YYYY'}}</mat-checkbox>
            </div>

        </div>
    </div>

    <div class="one-row">
        <mat-form-field style="width: 100%">
            <mat-label>Anmerkungen zum Urlaub</mat-label>
            <input matInput [formControl]="beschreibungCtrl">
        </mat-form-field>
    </div>

</div>
<div mat-dialog-actions>
    <button mat-flat-button (click)="onNoClick()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="antragStellen()">Stellen</button>
</div>
