import {Component, Inject, OnInit} from '@angular/core';
import {TableOptionEvent} from '../../components/kiene-table/table-option';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {MatDialog} from '@angular/material/dialog';
import {
    PdfViewerDesktopService
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {ClientService} from '../../administration/client/client.service';
import {Router} from '@angular/router';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {MessageService} from '../../services/message.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {UserService} from '../../administration/user/user.service';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {TextTableColumn} from '../../components/kiene-table/table-column';

@Component({
    selector: 'kiene-storage',
    templateUrl: './storage.component.html',
    styleUrls: ['./storage.component.scss'],
})
export class StorageComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private pdfService: PdfViewerDesktopService,
        private clientService: ClientService,
        private router: Router,
        private api: KieneTableService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private userService: UserService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Lager';
        this.tableDescriptor.uniqueIdentifier = 'Lager';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'lager/';
        this.tableDescriptor.createAllowed = false;
        this.tableDescriptor.showDetails = false;

        this.tableDescriptor.columns = this.getTableColumns();
        this.tableDescriptor.initialSortColumn = 'bezeichnung';
        this.tableDescriptor.initialSortDirection = 'asc';

        this.tableDescriptor.permissionView = 1681;

    }

    getTableColumns() {
        const columns = [];
        columns.push(new TextTableColumn('bezeichnung', 'Name'));
        return columns;
    }


    catchOptionEvent(event: TableOptionEvent) {

    }
}
