<h1 mat-dialog-title>Vertr&auml;ge erstellen</h1>
<div mat-dialog-content>
  <h4>Bitte die zu erstellenden Vertr&auml;ge ausw&auml;hlen.</h4>
  <ul>
    <li style="list-style-type: none;">
      <mat-checkbox [formControl]="ctrl1">Tier&auml;rztlicher Betreuungsvertrag</mat-checkbox>
    </li>
    <li style="list-style-type: none;">
      <mat-checkbox [formControl]="ctrl2">Ergänzung zum Betreuungsvertrag</mat-checkbox>
    </li>
    <li style="list-style-type: none;">
      <mat-checkbox [formControl]="ctrl3">Anwendung von Tierarzneimitteln (Futter)</mat-checkbox>
    </li>
    <li style="list-style-type: none;">
      <mat-checkbox [formControl]="ctrl4">Anwendung von Tierarzneimitteln (Wasser)</mat-checkbox>
    </li>
    <li style="list-style-type: none;">
      <mat-checkbox [formControl]="ctrl5">Meldevereinbarung QS</mat-checkbox>
    </li>
    <li style="list-style-type: none;">
      <mat-checkbox [formControl]="ctrl6">Risikomanagement zur oralen Medikation</mat-checkbox>
    </li>
  </ul>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Abbrechen</button>
  <button mat-flat-button color="accent" (click)="create()">Erstellen</button>
</div>
