export class BestellungInternPosition {
    ba_id: number;
    position: number;
    artikel_id: number;
    artikel: string;
    artikel_me: string;
    tierart_id: number;
    tierart: string;
    anzahl_tiere: number;
    tiergewicht: number;
    anwendungsdauer: number;
    dosierung: number;
    artikelmenge: number;
    artikelmenge_ist: number;
    chargen: string;
    indikation_id: number;
    indikation: string;
    identifikation_id: string;
    identifikation: string;
    anwendung_id: number;
    anwendung: string;
    ean: string;
    tourenplan_tage: number;
    gebindegroesse: number;
}

export interface BestellungInternPositionApi {
    records: BestellungInternPosition[];
    count: number;
}
