import {
  Directive,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Textbaustein } from "../administration/text-baustein/text-baustein";
import { TextBausteinDialogComponent } from "../dialogs/text-baustein-dialog/text-baustein-dialog.component";

@Directive({
  selector: "[kieneTextBaustein]",
})
export class TextBausteinDirective {
  @Output("textChanged") textChanged = new EventEmitter<Textbaustein>();

  @Input("kieneTextBaustein") textbausteinConfig: TextbausteinConfig;

  constructor(private el: ElementRef, public dialog: MatDialog) { }

  @HostListener("keyup", ["$event"]) checkForProposal(event) {
    if (event.key === "F4") {
      console.debug("F4 pressed");
      if (!this.textbausteinConfig) {
        this.textbausteinConfig = new TextbausteinConfig();
        this.textbausteinConfig.text1 = this.el.nativeElement.value;
        this.textbausteinConfig.count = 1;
      }
      this.openDialog();
    }
  }

  openDialog() {
    let dialogRef = this.dialog.open(TextBausteinDialogComponent, {
      width: "50%",
      minWidth: "300px",
      data: {
        textbausteinConfig: this.textbausteinConfig
      },
    });

    dialogRef.afterClosed().subscribe((response) => {
      console.debug("Textbausteindialog closed");
      if (response) {
        this.el.nativeElement.value = response.textbaustein1;
        this.textChanged.emit(response);
      }
    });
  }
}

export class TextbausteinConfig {
  count: number = 1;
  titel1: string = "Text";
  titel2: string;
  titel3: string;
  text1: string;
  text2: string;
  text3: string;
  typ: string;
}
