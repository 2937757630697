<h1 mat-dialog-title>Tourenplan Artikel</h1>
<div mat-dialog-content>
    <kiene-article-scan-autocomplete #articleAuto [placeholder]="'Artikel'"
        (selectedMaterial)="setSelectedMaterial($event)" [gesperrtAusblenden]="true"></kiene-article-scan-autocomplete>

    <mat-form-field>
        <mat-label>Menge</mat-label>
        <!--    <input #menge="matInput" matInput type="number" [formControl]="mengeCtrl" (blur)="addArtikelMenge()"-->
        <!--      (keyup.enter)="addArtikelMenge()">-->
        <input #menge="matInput" matInput type="number" [formControl]="mengeCtrl" (keyup.enter)="anzahlTiere?.focus()">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Anzahl Tiere</mat-label>
        <input #anzahlTiere="matInput" matInput type="number" [formControl]="anzahlTiereCtrl"
            (keyup.enter)="identifikation?.focus()">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Identifikation</mat-label>
        <input #identifikation="matInput" matInput type="text" [formControl]="identifikationCtrl"
            (keyup.enter)="save()">
    </mat-form-field>

</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()" [disabled]="!currentArtikelMenge.article">&Uuml;bernehmen
    </button>
</div>