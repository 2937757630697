<h1 mat-dialog-title>Debug Settings</h1>
<div mat-dialog-content>
    <section *ngIf="isAdmin" style="margin-bottom: 24px;">
        <h4>Als Benutzer XY fortfahren</h4>
        <mat-form-field style="width: 100%;">
            <mat-label>Session Token</mat-label>
            <textarea matInput rows="4" [formControl]="sessionTokenCtrl"></textarea>
        </mat-form-field>
        <br>
        <button mat-flat-button color="accent" (click)="replaceSession()">Session ersetzen</button>
    </section>
    <mat-divider></mat-divider>
    <section style="margin-bottom: 24px;">
        <h4>Trockener Logout</h4>
        <p>Es wird nur die Session im Browser gel&ouml;scht, nicht in der Datenbank!</p>
        <button mat-flat-button color="accent" (click)="dryLogout()">
            <mat-icon>settings_power</mat-icon> Logout
        </button>
    </section>
    <mat-divider></mat-divider>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="close()">Schlie&szlig;en</button>
</div>