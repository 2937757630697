import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { API_BASE_URL_SERVICE, SNACKBAR_POSITION } from '../../kiene-core.config';
import { KieneSession } from '../local-storage/kiene-session';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { log } from 'console';

export enum SnackbarPosition {
    TOP = 'top',
    BOTTOM = 'bottom',
}

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    position: SnackbarPosition = SnackbarPosition.BOTTOM;

    constructor(
        @Optional() @Inject(SNACKBAR_POSITION) position: SnackbarPosition,
        private snackbar: MatSnackBar,
        private localStorageService: LocalStorageService,
        private http: HttpClient,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        if (position) {
            this.position = position;
        }
    }

    openSnackBar(message: string) {
        return this.snackbar.open(message);
    }

    infoMessageAction(text: string, action: string) {
        return this.snackbar
            .open(text, action, {
                duration: 8000,
                verticalPosition: this.position,
                panelClass: ['snackbar-ok'],
            })
            .onAction();
    }

    infoMessage(text: string): MatSnackBarRef<TextOnlySnackBar> {
        return this.snackbar.open(text, '', {
            duration: 4000,
            verticalPosition: this.position,
            panelClass: ['snackbar-ok'],
        });
    }

    alertMessage(text: string) {
        this.snackbar.open(text, '', {
            duration: 4000,
            verticalPosition: this.position,
            panelClass: ['snackbar-alert'],
        });
    }

    hintMessage(text: string) {
        this.snackbar.open(text, '', {
            duration: 4000,
            verticalPosition: this.position,
            panelClass: ['snackbar-hint'],
        });
    }

    updateMessage(version: string) {
        return this.snackbar
            .open(
                'Es ist eine neue Version (' + version + ') verfügbar.',
                'Jetzt aktualisieren',
                {
                    duration: 10000,
                    panelClass: ['snackbar-hint'],
                    horizontalPosition: 'start',
                    verticalPosition: this.position,
                }
            )
            .onAction()
            .subscribe(() => {
                this.http
                    .get(this.apiBaseUrl + 'session/refresh.php')
                    .subscribe({
                        next: (response: KieneSession) => {
                            this.localStorageService.setCurrentUser(response);
                            location.reload();
                        },
                        error: () => {
                            location.reload();
                        },
                    });
            });
    }

    hintMessageRight(text: string) {
        this.snackbar.open(text, '', {
            duration: 4000,
            verticalPosition: this.position,
            horizontalPosition: 'right',
            panelClass: ['snackbar-hint'],
        });
    }

    errorMessage(text: string, errorObject: HttpErrorResponse) {
        // const error = errorObject.error;
        // const errorText = JSON.parse(error);
        let fehler = '';
        if (errorObject) {
            if (errorObject.status === 0) {
                text = '';
                fehler = 'Keine Internetverbindung!';
            } else if (errorObject.error) {
                if (errorObject.error.errortext) {
                    fehler = '\n' + errorObject.error.errortext;
                } else if (errorObject.error.fehler) {
                    fehler = '\n' + errorObject.error.fehler;
                } else {
                    if (typeof errorObject.error === 'object') {
                        fehler = '\n' + errorObject.error;
                    } else if (typeof errorObject.error === 'string') {
                        const err = JSON.parse(errorObject.error);
                        fehler = '\n' + err.errortext;
                    } else {
                        fehler = '\n';
                    }
                }
            }
        }
        // console.trace();
        this.snackbar.open(text + fehler, '', {
            duration: 6000,
            verticalPosition: this.position,
            panelClass: ['snackbar-alert'],
        });
        // this.snackbar.open(text + '\n' + errorText.errortext, '', {duration: 4000, panelClass: ['snackbar-alert']});
    }
}
