<mat-card (click)="showDetails(tier, $event)" style="padding: 12px; min-width: 300px;">
    <div class="one-row">
        <kiene-ohrmarke [ohrmarke]="tier?.ohrmarke_notnull" style="font-size: 0.85em"></kiene-ohrmarke>
        <span class="spacer"></span>
        <button (click)="$event.stopPropagation()" *ngIf="!hideAllButtons" [matMenuTriggerFor]="menu" mat-icon-button
                style="text-align: end">
            <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
            <ng-container>
                <button (click)="showDetails(tier)" *ngIf="!hideDetailsButton" mat-menu-item>
                    <mat-icon>info</mat-icon>
                    <span>Details anzeigen</span>
                </button>

                <button (click)="todmeldung(tier)" *ngIf="!hideTodmeldungButton" [disabled]="!!tier?.todesdatum_notnull"
                        mat-menu-item>
                    <mat-icon>local_hospital</mat-icon>
                    <span>Todmeldung</span>
                </button>

                <button (click)="loeschen(tier)" *ngIf="!hideLoeschenButton" mat-menu-item>
                    <mat-icon>delete</mat-icon>
                    <span>Tier aus Gruppe L&ouml;schen</span>
                </button>

                <button (click)="reklamationHinzufuegen(tier)" *ngIf="!hideReklamationButton" mat-menu-item>
                    <mat-icon style="transform: rotateY(180deg)">feedback
                    </mat-icon>
                    <span>Reklamieren</span>
                </button>

                <button (click)="bemerkungHinzufuegen(tier)" *ngIf="!hideReklamationButton" mat-menu-item>
                    <mat-icon>comment</mat-icon>
                    <span>Bemerkung hinzuf&uuml;gen</span>
                </button>

                <button (click)="tierBearbeiten(tier)" *ngIf="!hideBearbeitenButton" mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>Bearbeiten</span>
                </button>

                <button (click)="addOhrmarkeGescannt()" *ngIf="tier.ohrmarke_gescannt === 0" mat-menu-item>
                    <mat-icon>label</mat-icon>
                    <span>Ohrmarke vorhanden</span>
                </button>

                <button (click)="gruppeAendern()" [disabled]="tier.tierliste_id > 0" mat-menu-item>
                    <mat-icon>sync_alt</mat-icon>
                    <span>Gruppe ändern</span>
                </button>

                <button (click)="openHelp()" *ngIf="!hideHilfeButton" mat-menu-item>
                    <mat-icon>help</mat-icon>
                    <span>Hilfe anzeigen</span>
                </button>

                <ng-content select="[mat-menu-items]"></ng-content>
            </ng-container>
        </mat-menu>
    </div>
    <div style="font-size: 0.8em">
        <div *ngIf="!hideStatusInfos" class="one-row">
            <mat-icon [ngClass]="getIconStatusClass(tier?.status_id_gesamt)">
                cloud_sync
            </mat-icon>
            <mat-icon [ngClass]="
                    tier?.ohrmarke_gescannt > 0
                        ? 'icon-status--checked'
                        : 'icon-status--unchecked'
                ">label
            </mat-icon>
            <mat-icon [ngClass]="
                    tier?.pass_gescannt > 0
                        ? 'icon-status--checked'
                        : 'icon-status--unchecked'
                ">badge
            </mat-icon>
            <mat-icon [ngClass]="
                    tier?.csv_import > 0
                        ? 'icon-status--checked'
                        : 'icon-status--unchecked'
                ">download
            </mat-icon>
            <mat-icon [ngClass]="
                    tier?.sgs_id > 0
                        ? 'icon-status--checked'
                        : 'icon-status--unchecked'
                ">biotech
            </mat-icon>
            <mat-icon [ngClass]="
                    tier?.tierliste_id > 0
                        ? 'icon-status--checked'
                        : 'icon-status--unchecked'
                ">list
            </mat-icon>
        </div>

        <div style="padding: 6px 0">
            <mat-divider style="width: 100%"></mat-divider>
        </div>

        <div *ngIf="tier?.hit_fehler_text" class="one-row">
            <mat-icon class="icon-status--error" style="width: 10%">error
            </mat-icon>
            <span style="width: 90%">{{
                tier?.hit_fehler_text | replaceNullWithText
                }}</span>
        </div>

        <div *ngIf="!hideTranspondernr && tier?.transpondernummer" class="one-row">
            <mat-icon class="icon-status--checked" style="width: 10%">confirmation_number
            </mat-icon>
            <span style="width: 90%">Transpondernr.:
                {{ tier?.transpondernummer | replaceNullWithText }}</span>
        </div>

        <div *ngIf="!hideGeschlechtRasse && (tier?.geschlecht_id || tier?.rasse)" class="one-row">
            <mat-icon class="icon-status--checked" style="width: 10%">
                {{
                tier?.geschlecht_id && tier?.geschlecht_id === 1
                    ? "male"
                    : "female" || "transgender"
                }}
            </mat-icon>
            <span style="width: 90%">{{
                tier?.rasse | replaceNullWithText
                }}</span>
        </div>

        <div *ngIf="
                (!hideGruppe && tier?.gruppe) || (!hideAbteil && tier?.abteil)
            " class="one-row">
            <mat-icon class="icon-status--checked" style="width: 10%">{{
                !hideGruppe && tier?.gruppe ? "groups" : "place"
                }}</mat-icon>
            <span style="width: 90%">{{ !hideGruppe && tier?.gruppe ? tier.gruppe : "" }}
                {{
                !hideGruppe && tier?.gruppe && !hideAbteil && tier?.abteil
                    ? " - Abteil: "
                    : ""
                }}
                {{ !hideAbteil && tier?.abteil ? tier?.abteil : "" }}</span>
        </div>

        <div *ngIf="!hideKunde && tier?.kunde" class="one-row">
            <mat-icon class="icon-status--checked" style="width: 10%">business
            </mat-icon>
            <span style="width: 90%">{{
                tier.kunde + " " + tier.kunde_zusatz
                }}</span>
        </div>

        <div *ngIf="!hideGrundEntfernung && tier?.grund_entfernung" class="one-row">
            <mat-icon class="icon-status--checked" style="width: 10%">warning_amber
            </mat-icon>
            <span style="width: 90%">{{ tier?.grund_entfernung }}</span>
        </div>

        <div *ngIf="
                !hideReklamationen &&
                tier?.reklamationen &&
                tier?.reklamationen?.length > 0
            " class="one-row">
            <mat-icon class="icon-status--checked" style="transform: rotateY(180deg)">feedback
            </mat-icon>
            <span style="width: 90%">
                <span *ngFor="
                        let reklamation of tier.reklamationen;
                        let index = index
                    ">
                    {{ reklamation.reklamation
                    }}{{ index !== tier.reklamationen.length - 1 ? "," : "" }}
                </span>
            </span>
        </div>

        <div *ngIf="
                !hideBemerkung &&
                tier?.bemerkungen &&
                tier?.bemerkungen?.length > 0
            " class="one-row">
            <mat-icon class="icon-status--checked" style="width: 10%">comment
            </mat-icon>
            <span style="width: 90%">
                <span *ngFor="
                        let bemerkung of tier.bemerkungen;
                        let index = index
                    ">
                    {{ bemerkung.bemerkung
                    }}{{ index !== tier.bemerkungen.length - 1 ? "," : "" }}
                </span>
            </span>
        </div>

        <div *ngIf="
                !hideAlter &&
                (tier?.geburtsdatum || tier?.alter_tage || tier?.alter_wochen)
            " class="one-row">
            <div class="one-row" style="width: 40%">
                <mat-icon class="icon-status--checked" style="transform: scale(0.85)">cake
                </mat-icon>
                <span style="margin-left: 6px">{{
                    tier?.geburtsdatum
                        | date : "dd.MM.YY"
                        | replaceNullWithText : "---"
                    }}</span>
                <span class="spacer"></span>
            </div>
            <span style="width: 60%">
                Alter: {{ tier?.alter_tage ? tier?.alter_tage + "T" : "---" }}
                {{ tier?.alter_wochen ? "(" + tier?.alter_wochen + "W)" : "" }}
            </span>
        </div>

        <div *ngIf="
                !hideEinstallung &&
                (tier?.einstalldatum_notnull ||
                    tier?.viehhaendler ||
                    tier?.status_id_einstallen)
            " class="one-row">
            <div class="one-row" style="width: 40%">
                <mat-icon [ngClass]="getIconStatusClass(tier?.status_id_einstallen)"
                          style="transform: scale(0.85)">login
                </mat-icon>
                <span style="margin-left: 6px; margin-right: 6px">{{
                    tier?.einstalldatum_notnull
                        | date : "dd.MM.YY"
                        | replaceNullWithText : "---"
                    }}</span>
                <span class="spacer"></span>
            </div>
            <span style="width: 60%">{{
                tier?.viehhaendler | replaceNullWithText
                }}</span>
        </div>

        <div *ngIf="
                !hideAbgang &&
                (tier?.abgangsdatum_notnull ||
                    tier?.kunde_einstallen ||
                    tier?.status_id_abgang ||
                    tier?.abteil_abgang)
            " class="one-row">
            <div class="one-row" style="width: 40%">
                <mat-icon [ngClass]="getIconStatusClass(tier?.status_id_abgang)" style="transform: scale(0.85)">logout
                </mat-icon>
                <span style="margin-left: 6px">{{
                    tier?.abgangsdatum_notnull
                        | date : "dd.MM.YY"
                        | replaceNullWithText : "---"
                    }}</span>
                <span class="spacer"></span>
            </div>
            <span style="width: 60%">{{ tier?.kunde_einstallen | replaceNullWithText }} |
                {{ tier?.abteil_abgang }}</span>
        </div>

        <div *ngIf="
                !hideTodesdatum &&
                (tier?.todesdatum_notnull ||
                    tier?.entsorger ||
                    tier?.status_id_tod)
            " class="one-row">
            <div class="one-row" style="width: 40%">
                <mat-icon [ngClass]="getIconStatusClass(tier?.status_id_tod)"
                          style="transform: scale(0.85)">local_hospital
                </mat-icon>
                <span style="margin-left: 6px">{{
                    tier?.todesdatum_notnull
                        | date : "dd.MM.YY"
                        | replaceNullWithText : "---"
                    }}</span>
                <span class="spacer"></span>
            </div>
            <span style="width: 60%">{{
                tier?.entsorger | replaceNullWithText
                }}</span>
        </div>
        <ng-content select="[additional-rows]"></ng-content>
    </div>
</mat-card>
