import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    ChangeUserEmailAccountDialogComponent
} from '../../dialogs/change-user-email-account-dialog/change-user-email-account-dialog.component';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {MessageService} from '../../services/message.service';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {ArrayTableColumn, DateTableColumn, TextTableColumn} from '../../components/kiene-table/table-column';
import {
    BenutzerrolleAendernDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/stammdaten/benutzer/benutzerrolle-aendern-dialog/benutzerrolle-aendern-dialog.component';
import {
    BenutzerHinzufuegenDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/stammdaten/benutzer/benutzer-hinzufuegen-dialog/benutzer-hinzufuegen-dialog.component';
import {BenutzerService} from '../../../../projects/kiene-core/src/lib/components/stammdaten/benutzer/benutzer.service';
import {User} from '../../../../projects/kiene-core/src/lib/components/stammdaten/benutzer/benutzer';

@Component({
    selector: 'kiene-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
    displayedColumns = [
        'titel',
        'nachname',
        'vorname',
        'email',
        'benutzerrolle',
        'erstellt',
        'options',
    ];
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private api: KieneTableService,
        private messageService: MessageService,
        private userService: BenutzerService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Benutzer';
        this.tableDescriptor.uniqueIdentifier = 'Benutzer';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'benutzer/';
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.detailsRoute = '/administration/benutzer/';
        this.tableDescriptor.nameOfIdField = 'benutzer_id';

        this.tableDescriptor.columns = this.getTableColumns();
        this.tableDescriptor.options = this.getTableOptions();

        this.tableDescriptor.permissionView = 51;
        this.tableDescriptor.permissionCreate = 52;
        this.tableDescriptor.permissionUpdate = 53;
        this.tableDescriptor.permissionDelete = 54;

        this.tableDescriptor.initialSortColumn = 'nachname';
        this.tableDescriptor.initialSortDirection = 'asc';
    }

    getTableColumns() {
        const columns = [];
        columns.push(new TextTableColumn('titel', 'Titel'));
        columns.push(new TextTableColumn('vorname', 'Vorname'));
        columns.push(new TextTableColumn('nachname', 'Nachname'));
        columns.push(new TextTableColumn('email', 'E-Mail'));
        columns.push(new ArrayTableColumn('benutzerrollen', 'Benutzerrollen', ['bezeichnung']));
        columns.push(new DateTableColumn('erstellt', 'Erstellt'));
        return columns;
    }

    openEmailAccountDialog(user: User) {
        const dialogRef = this.dialog.open(
            ChangeUserEmailAccountDialogComponent,
            {
                width: '800px',
                data: {
                    user: user,
                },
            }
        );

        dialogRef.afterClosed().subscribe((response) => {
        });
    }

    sendTestMail(user: User) {
        this.userService.sendTestMail(user).subscribe(
            (response) => {
                this.messageService.infoMessage(
                    'Test E-Mail erfolgreich gesendet! Bitte auch im Spam Ordner nachschauen!'
                );
            },
            (error) => {
                this.messageService.errorMessage(
                    'Test E-Mail konnte nicht gesendet werden!',
                    error
                );
            }
        );
    }

    openChangeUserRoleDialog(user: User) {
        const dialogRef = this.dialog.open(BenutzerrolleAendernDialogComponent, {
            width: '700px',
            data: {user: user},
        });
        dialogRef.afterClosed().subscribe((response) => {
            if (response != null) {
                this.messageService.infoMessage(
                    'Benutzer ' +
                    response.vorname +
                    ' ' +
                    response.nachname +
                    ' wurde erfolgreich geändert!'
                );
            }
            this.api.reload('openChangeUserRoleDialog');
        });
    }

    openAddUserDialog() {
        const dialogRef = this.dialog.open(BenutzerHinzufuegenDialogComponent, {
            width: '700px',
        });
        dialogRef.afterClosed().subscribe((response) => {
            if (response != null) {
                this.messageService.infoMessage(
                    'Benutzer ' +
                    response.vorname +
                    ' ' +
                    response.nachname +
                    ' wurde erfolgreich angelegt!'
                );
                this.api.reload('openAddUserDialog');
            }
        });
    }

    openDeleteConfirmDialog(user: User) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '350px',
            data: {
                title: 'Benutzer löschen',
                text:
                    'Wollen Sie den Benutzer ' +
                    user.vorname +
                    ' ' +
                    user.nachname +
                    ' wirklich löschen?',
                textButton: 'Löschen',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.deleteUser(user);
            } else {
            }
        });
    }

    deleteUser(user: User) {
        this.userService.deleteUser(user).subscribe(
            (response) => {
                // this.snackBar.open('Benutzer ' + user.vorname + ' ' + user.nachname + ' erfolgreich entfernt!', '', { duration: 3000 })
                this.messageService.infoMessage(
                    'Benutzer ' +
                    user.vorname +
                    ' ' +
                    user.nachname +
                    ' erfolgreich entfernt!'
                );
                this.api.reload('deleteUser');
            },
            (error) => {
                // this.snackBar.open('Löschen von Benutzer ' + user.vorname + ' ' + user.nachname + ' ist fehlgeschlagen!', '', { duration: 3000, panelClass: ['snackbar-alert'] });
                this.messageService.errorMessage(
                    'Löschen von Benutzer ' +
                    user.vorname +
                    ' ' +
                    user.nachname +
                    ' ist fehlgeschlagen!',
                    error
                );
            }
        );
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'benutzerrolle_aendern') {
            this.openChangeUserRoleDialog(event.value);
        } else if (event.name === 'delete') {
            this.deleteUser(event.value);
        } else if (event.name === 'email_account') {
            this.openEmailAccountDialog(event.value);
        } else if (event.name === 'send_testmail') {
            this.sendTestMail(event.value);
        } else if (event.name === 'add') {
            this.openAddUserDialog();
        }

    }

    private getTableOptions() {
        const options = [];
        options.push(new TableOption('benutzerrolle_aendern', 'Benutzerrolle ändern', 'forward', 53));
        options.push(new TableOption('delete', 'Benutzer löschen', 'delete', 54));
        options.push(new TableOption('email_account', 'E-Mail Konto einrichten', 'email', 73));
        options.push(new TableOption('send_testmail', 'Test E-Mail an mich senden', 'forward_to_inbox', 59));
        return options;
    }
}
