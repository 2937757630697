<h3 mat-dialog-title>Monat auswählen</h3>
<mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>Monat und Jahr</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="dateCtrl" />
        <mat-hint>MM/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp startView="multi-year" (monthSelected)="setMonthAndYear($event, dp)"
            panelClass="example-month-picker">
        </mat-datepicker>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="primary" (click)="onCancelClick()">
        Abbrechen
    </button>
</mat-dialog-actions>