<div [style.padding]="dialog ? '0' : '24px'">

    <kiene-page-header *ngIf="!dialog" [ueberschrift]="'Tiertod melden'"></kiene-page-header>

    <kiene-autocomplete #tierAutoComplete (elementSelected)="setSelectedTier($event)" [disabled]="dialog"
                        [displayFields]="['ohrmarke_notnull', 'gruppe']" [focus]="false"
                        [genericApiUrl]="readOhrenmarke"
                        [inputmode]="'numeric'" [kieneObject]="tier" [limit]="25" [panelWidth]="'85%'" [params]="params"
                        [placeholder]="'Ohrmarke auswählen'" [required]="true" [showClear]="!dialog"
                        [showLoadingIndicator]="true"
                        [type]="'text'" class="tod-meldungen-form-input">
    </kiene-autocomplete>

    <p>Betrieb: {{ tier?.kunde ? tier.kunde : '/' }}</p>

    <mat-form-field style="width: 100%;">
        <mat-label>Todesdatum wählen</mat-label>
        <input [formControl]="todesDatumCtrl" [matDatepicker]="picker" [readonly]="true" matInput [readonly]="true">
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="todesDatumCtrl.hasError('required')" class="input-error">Das Todesdatum ist ein
            Pflichtfeld.
        </mat-error>
    </mat-form-field>
    <div [style.padding-bottom]="dialog && entsorgerCtrl.hasError('keine-kundennr-fuer-entsorger')
                 ? '24px' : '12px' " class="one-row" style="width: 100%">
        <mat-form-field appearance="outline" style="width: 90%">
            <mat-label>{{ getLabel() }}</mat-label>
            <mat-select [compareWith]="compareEntsorger" [formControl]="entsorgerCtrl">
                <mat-option>Kein Entsorger</mat-option>
                <mat-option *ngFor="let entsorger of entsorgerFavoriten" [value]="entsorger">{{ entsorger.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="entsorgerCtrl.hasError('keine-kundennr-fuer-entsorger')">
                Es ist keine Kundennr. beim Entsorger für die VVVO {{ vvvoOfTierGruppe }} hinterlegt.
                Bitte nachtragen, sonst ist keine Meldung möglich.
            </mat-error>
        </mat-form-field>
        <div *kienePermission="[9142]" style="margin-bottom: 6%; transform:scale(1.1)">
            <button (click)="entsorgerHinzufuegen()" *ngIf="!isLoading" color="accent" mat-icon-button>
                <mat-icon>add</mat-icon>
            </button>
            <mat-progress-spinner *ngIf="isLoading" [diameter]="24" color="accent" mode="indeterminate">
            </mat-progress-spinner>

        </div>
    </div>

    <div *ngIf="entsorgerCtrl.hasError('keine-kundennr-fuer-entsorger')"
         style="width: 100%; display: flex; align-items: center; justify-content: center; padding: 32px 0 6px 0">
        <button *kienePermission="[9143]" (click)="kundenNrNachtragen()" color="accent" mat-flat-button
                style="width: 85%">
            Kundennummer nachtragen
        </button>
    </div>

    <div class="one-row" style="padding-bottom: 24px">
        <span class="spacer"></span>
        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>Todesart w&auml;hlen</mat-label>
            <mat-select [formControl]="selectedTodesartCtrl">
                <mat-option *ngFor="let todesart of todesarten" [value]="todesart">{{ todesart.bezeichnung }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="selectedTodesartCtrl.hasError('required')">Es muss eine Todesart ausgew&auml;hlt
                werden.
            </mat-error>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div [style.justify-content]="dialog ? 'space-between': 'center'" class="one-row">
        <ng-content select="[additional-actions]"></ng-content>
        <span *ngIf="dialog" class="spacer"></span>
        <button (click)="melden()" [disabled]="!isValid() || isLoading" [style.width]="dialog ? '' : '66%'"
                class="button" color="accent" mat-flat-button type="button">
            Melden
        </button>
    </div>
</div>
