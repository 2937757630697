import {Component, OnInit} from '@angular/core';
import {
    LocalStorageService
} from '../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-administration',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {

    constructor(private localStorageService: LocalStorageService) {
        this.localStorageService.setCurrentClientVisible(false, 'AdministrationComponent');
        this.localStorageService.setShoppingCartVisible(false);
    }

    ngOnInit() {
    }

    isTabMenuVisible(path: string) {
        return this.localStorageService.currentUserCanSeeTab(path);
    }

}
