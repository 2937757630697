import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {Mandant} from '../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'kiene-save-for-all-dialog',
    templateUrl: './save-for-all-dialog.component.html',
    styleUrls: ['./save-for-all-dialog.component.css'],
    host: {'(window:keyup)': 'keyListener($event)'}

})
export class SaveForAllDialogComponent implements OnInit {

    mandanten: Mandant[] = [];
    selectedMandantenIds: number[] = [];

    mandantCtrl: UntypedFormControl[] = [];

    buttonText = 'Speichern';

    constructor(public dialogRef: MatDialogRef<SaveForAllDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data,
                private localStorageService: LocalStorageService) {
        this.mandanten = this.data.availableMandanten;
        if (this.data?.buttonText) {
            this.buttonText = this.data.buttonText;
        }
    }

    ngOnInit(): void {

        const currentMandant = this.localStorageService.getCurrentMandant();


        for (const m of this.mandanten) {
            this.mandantCtrl[m.mandant_id] = new UntypedFormControl(true);
            if (m.mandant_id === currentMandant.mandant_id) {
                this.mandantCtrl[m.mandant_id].disable();
            }
        }
        this.updateSelectedMandanten();

    }

    updateSelectedMandanten() {
        this.selectedMandantenIds = [];
        for (const m of this.mandanten) {
            if (this.mandantCtrl[m.mandant_id].value) {
                this.selectedMandantenIds.push(m.mandant_id);
            }
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    keyListener(event) {
        if (event.code === 'Enter') {
            this.saveForSelected();
        }
    }

    saveForSelected() {

        this.dialogRef.close(this.selectedMandantenIds);
    }

}
