import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { UntypedFormControl, Validators } from '@angular/forms';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../services/message-service/message.service';
import { KieneUtils } from '../../../utils/KieneUtils';

@Component({
    selector: 'kiene-selector',
    templateUrl: './kiene-selector.component.html',
    styleUrls: ['./kiene-selector.component.scss']
})
export class KieneSelectorComponent implements OnInit, OnChanges {

    @Input('label') label = 'Bitte Label definieren';
    @Input('multiLine') multiLine = false;
    @Input('disableTillLoaded') disableTillLoaded = false;
    @Input('required') required = false;
    @Input('kieneObject') kieneObject: any;
    @Input('apiUrl') apiUrl: string;
    @Input('params') params: HttpParams;
    @Input('displayColumns') displayColumns: string[] = [];
    @Input('delimiter') delimiter = ' - ';
    @Input('comparator') comparator: (o1: any, o2: any) => boolean;

    @Output('optionSelected') optionSelected = new EventEmitter<any>();

    @ViewChild('select') select: MatSelect;

    public selectCtrl = new UntypedFormControl();

    options: any[] = [];
    isLoading = false;

    constructor(private api: KieneBackendApiService,
        private messageService: MessageService) { }

    ngOnInit(): void {

        this.isLoading = this.disableTillLoaded;

        if (this.required) {
            this.selectCtrl.setValidators(Validators.required);
        }

        if (this.kieneObject) {
            this.selectCtrl.setValue(this.kieneObject);
        }

        if (!this.comparator) {
            this.comparator = function (o1: any, o2: any) {
                return o1 === o2;
            };
        }

        this.loadOptions();
    }

    ngOnChanges() {
        this.reload();
    }

    public reload(): void {
        if (this.kieneObject) {
            this.selectCtrl.setValue(this.kieneObject);
        }
        this.loadOptions();
    }


    loadOptions() {
        this.isLoading = this.disableTillLoaded;
        this.api.get(this.apiUrl, this.params).subscribe({
            next: response => {
                this.isLoading = false;
                if (response) {
                    this.options = response.records;
                }
            },
            error: error => {
                this.isLoading = false;
                this.messageService.errorMessage('Die Optionen für ' + this.label + ' konnten nicht geladen werden!', error);
            }
        });
    }

    setFocus() {
        this.select?.focus();
    }

    formatOptionText(o: any) {
        let text = '';
        if (o) {
            for (const d of this.displayColumns) {
                text += o[d] + this.delimiter;
            }
            text = text.substr(0, text.length - this.delimiter.length);
        }
        return text;
    }

    setSelectedOption(option: MatSelectChange) {
        console.log('setSelectedOption', option);
        this.optionSelected.emit(option.value);
    }

    isValid(): boolean {
        this.selectCtrl.markAsTouched();
        return this.selectCtrl.valid && KieneUtils.isNNoU(this.selectCtrl.value);
    }

    resetCtrl() {
        this.selectCtrl.reset(null);
    }

    clearOptions() {
        this.options = [];
    }
}
