<h1 mat-dialog-title>Reklamationen hinzuf&uuml;gen</h1>
<div mat-dialog-content>
    <div *ngIf="tierreklamationen.length > 0">
        <ul style="list-style-type: none;">
            <li *ngFor="let t of tierreklamationen; let i = index">
                <mat-checkbox (change)="addRemoveReklamation($event, t)" [checked]="isChecked(t)">{{t.reklamation}}
                </mat-checkbox>
            </li>
        </ul>
    </div>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</div>
