<h1 mat-dialog-title>Neuen Benutzer anlegen</h1>
<form [formGroup]="newUserFormGroup" (ngSubmit)="safeAndCloseDialog()">
    <mat-dialog-content>
        <h5>Allgemeine Angaben</h5>
        <mat-form-field style="width: 100%">
            <mat-label>Titel</mat-label>
            <input
                placeholder="Titel"
                matInput
                formControlName="titel"
            />
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <mat-label>Vorname</mat-label>
            <input
                placeholder="Vorname"
                matInput
                formControlName="vorname"
                required
            />
            <mat-error *ngIf="newUserFormGroup.get('vorname').invalid"
            >Bitte Vornamen eintragen!
            </mat-error
            >
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <mat-label>Nachname</mat-label>
            <input
                placeholder="Nachname"
                matInput
                formControlName="nachname"
            />
            <mat-error *ngIf="newUserFormGroup.get('nachname').invalid"
            >Bitte Nachnamen eintragen!
            </mat-error
            >
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <mat-label>E-Mail Adresse</mat-label>
            <input
                placeholder="E-Mail Adresse"
                matInput
                formControlName="email"
                required
            />
            <mat-error *ngIf="newUserFormGroup.get('email').invalid"
            >Ungültige E-Mail Adresse
            </mat-error
            >
        </mat-form-field>
        <h5>Benutzerrolle ausw&auml;hlen</h5>
        <mat-form-field style="width: 100%">
            <mat-label>Benutzerrolle</mat-label>
            <mat-select multiple formControlName="benutzerrollen" required [compareWith]="compareBenutzerrolle">
                <mat-option
                    *ngFor="let ur of userroles"
                    [value]="ur"
                >{{ ur.bezeichnung }}</mat-option
                >
            </mat-select>
            <mat-error
                *ngIf="newUserFormGroup.get('benutzerrollen').invalid"
            >Bitte Benutzerrolle eintragen!
            </mat-error
            >
        </mat-form-field>
        <h5>Passwort vergeben</h5>
        <mat-form-field style="width: 100%; margin-bottom: 12px;">
            <mat-label>Passwort</mat-label>
            <input matInput type="text" formControlName="password">
            <mat-hint>Passwort muss mindestens 8 Zeichen lang sein und mindestens ein Gro&szlig;buchstaben, ein
                Kleinbuchstaben und eine Ziffer beinhalten!
            </mat-hint>
        </mat-form-field>
        <div style="width: 100%; margin-top: 36px;">
            <mat-checkbox [formControl]="sendePasswortAnEmailCtrl"><span style="white-space: normal; width: 80%;">Soll das Passwort an die oben genannte E-Mail
                Adresse <strong>{{newUserFormGroup.get('email').value}}</strong> gesendet werden?</span>
            </mat-checkbox>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button (click)="onCancelClick()">
            Abbrechen
        </button>
        <button mat-flat-button color="accent" type="submit">
            Anlegen
        </button>
    </mat-dialog-actions>
</form>`
