import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {TourenplanService} from './tourenplan.service';
import {BehandlungsanfrageService} from '../../documents/behandlungsanfrage/behandlungsanfrage.service';
import {HttpParams} from '@angular/common/http';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {Tourenplan} from './tourenplan';
import {MessageService} from '../../services/message.service';
import {UntypedFormControl} from '@angular/forms';
import {Router} from '@angular/router';
import {AddTourDialogComponent} from './add-tour-dialog/add-tour-dialog.component';
import {VetGroup} from '../../administration/vet-group/vet-group';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {Abgabebeleg} from '../../documents/abgabebeleg/abgabebeleg';
import {ClientService} from '../../administration/client/client.service';
import {AppService} from '../../app.service';
import {KWDialogComponent} from './kwdialog/kwdialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {
    ConfirmDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {KieneSession} from '../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import {AssignVetToTourDialogComponent} from './assign-vet-to-tour-dialog/assign-vet-to-tour-dialog.component';
import {Vet} from '../../administration/vet/vet';
import {AssignVetToTourData, AssignVetToTourResult} from './assign-vet-to-tour-dialog/assign-vet-to-tour';

@Component({
    selector: 'kiene-tourenplan',
    templateUrl: './tourenplan.component.html',
    styleUrls: ['./tourenplan.component.scss'],
})
export class TourenplanComponent implements OnInit, OnDestroy {
    isVet = false;
    isMobileScreen = false;
    isKunde = true;

    tierarztgruppen: VetGroup[] = [];
    tierarztgruppeCtrl = new UntypedFormControl();
    currentTierarztgruppe: VetGroup;

    tierarztgruppeSub: Subscription;

    apiUrl = environment.BASE_URI;
    tourenplans: Tourenplan[] = [];
    kieneSession: KieneSession;

    onlyMineCtrl = new UntypedFormControl();
    tourenplanOptionsCtrl = new UntypedFormControl();

    length = 0;
    pageSize = 20;
    pageSizeOptions = [5, 10, 20, 50, 100];
    offset = 0;

    showOnlyMine = false;

    onlyToday = false;
    kw = false;
    kwInfo = 0;
    history = false;

    selectedOption = 'Alle';

    tourenplanSubscription: Subscription;
    kwInfoSubscription: Subscription;
    private currentMandantSubscription: Subscription;

    constructor(
        private api: KieneBackendApiService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private service: TourenplanService,
        private messageService: MessageService,
        private behandlungsanfrageService: BehandlungsanfrageService,
        private clientService: ClientService,
        public dialog: MatDialog,
        private pdfViewer: PdfViewerDesktopService,
        private breakpointObserver: BreakpointObserver,
        private appService: AppService
    ) {
        this.kieneSession = this.localStorageService.getCurrentUser();
        this.isVet = this.kieneSession.tierarzt_id ? true : false;
        this.isKunde = this.localStorageService.isCurrentUserKunde();

        this.breakpointObserver
            .observe(Breakpoints.Handset)
            .subscribe((response) => {
                this.isMobileScreen = response.matches;
            });
    }

    ngOnInit() {
        this.pageSize =
            this.localStorageService.getSession().elemente_pro_seite;

        this.currentMandantSubscription = this.localStorageService
            .watchChangeCurrentMandant()
            .subscribe(() => {
                this.loadBestellungen('localStorageService');
            });

        this.loadTierarztgruppen();

        this.api.getSpeicher('tourenplan_options').subscribe({
            next: (values) => {
                this.tourenplanOptionsCtrl.setValue(values, {
                    emitEvent: false,
                });
                this.setzeOptions(values, false);
                this.loadBestellungen('getSpeicher');
            },
            error: () => {
                this.loadBestellungen('getSpeicher');
            },
        });

        this.tierarztgruppeSub = this.tierarztgruppeCtrl.valueChanges.subscribe(
            (tierarztgruppe) => {
                this.currentTierarztgruppe = tierarztgruppe;
                this.loadBestellungen('tierarztgruppeCtrl');
            }
        );

        this.onlyMineCtrl.valueChanges.subscribe({
            next: (onlyMine: boolean) => {
                this.showOnlyMine = onlyMine;
                this.api.setSpeicher('tourenplan_onlyMine', this.showOnlyMine);
                this.loadBestellungen('this.onlyMineCtrl');
            },
        });
    }

    setzeOptions(v: number, save: boolean) {
        // 1=Nur meine, 2=Nur von heute, 3=Historie, 4=KW
        // this.showOnlyMine = false;
        this.onlyToday = false;
        this.history = false;
        this.kw = false;

        if (v === 1) {
            // this.showOnlyMine = true;
            this.selectedOption = 'Alle';
        } else if (v === 2) {
            this.onlyToday = true;
            this.selectedOption = 'Heute';
        } else if (v === 3) {
            this.history = true;
            this.selectedOption = 'Historie';
        } else if (v === 4) {
            this.kw = true;
            this.selectedOption = 'KW';
        }
        if (save) {
            this.api.setSpeicher('tourenplan_options', v);
        }
        this.loadBestellungen('setze Options');
    }

    ngOnDestroy() {
        this.tierarztgruppeSub?.unsubscribe();
        this.tourenplanSubscription?.unsubscribe();
        this.currentMandantSubscription?.unsubscribe();
    }

    getStrasse(t: Tourenplan) {
        if (t.abweichende_lieferadresse === 1) {
            return t.strasse_lieferung;
        } else {
            return t.strasse ? t.strasse : t.strasse_ug;
        }
    }

    getPlz(t: Tourenplan) {
        if (t.abweichende_lieferadresse === 1) {
            return t.plz_lieferung;
        } else {
            return t.plz ? t.plz : t.plz_ug;
        }
    }

    getOrt(t: Tourenplan) {
        if (t.abweichende_lieferadresse === 1) {
            return t.ort_lieferung;
        } else {
            return t.ort ? t.ort : t.ort_ug;
        }
    }

    getOrtsteil(t: Tourenplan) {
        if (t.abweichende_lieferadresse === 1) {
            return t.ortsteil_lieferung;
        } else {
            return t.ortsteil ? t.ortsteil : t.ortsteil_ug;
        }
    }

    vetGroupComparator(vg1: VetGroup, vg2: VetGroup): boolean {
        if (!vg1 || !vg2) {
            return false;
        }

        return vg1.tierarztgruppe_id === vg2.tierarztgruppe_id;
    }

    loadTierarztgruppen() {
        if (!this.isKunde && this.localStorageService.hasPermission(4007)) {
            this.api
                .get(
                    environment.BASE_URI + 'tierarztgruppe/read_tourenplan.php',
                    null
                )
                .subscribe((response) => {
                    this.tierarztgruppen = response.records;

                    for (const tg of this.tierarztgruppen) {
                        if (tg.meine_gruppe > 0) {
                            this.tierarztgruppeCtrl.setValue(tg);
                            break;
                        }
                    }
                });
        }
    }

    route() {
        if (this.kieneSession?.tierarzt_id) {
            let params = new HttpParams();
            params = params.append(
                'tierarzt_id',
                this.kieneSession.tierarzt_id.toString()
            );
            params = params.append('heute', '1');
            this.api.getAll(this.apiUrl + 'tourenplan_tierarzt/read.php', params).subscribe({
                next: (response) => {
                    if (response.count <= 0) {
                        this.messageService.infoMessage(
                            'Für Sie sind keine Touren für heute hinterlegt!'
                        );
                    } else {
                        let dist = '';
                        for (const t of response.records) {
                            const str = this.getStrasse(t);
                            const plz = this.getPlz(t);
                            const ort = this.getOrt(t);
                            // Ort mindestens
                            if (ort) {
                                if (str) {
                                    dist += str + ',';
                                }
                                if (plz) {
                                    dist += plz + ' ';
                                }
                                dist += ort + '/';
                            }
                        }
                        window.open('https://www.google.de/maps/dir/' + dist);
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Einträge konnten nicht gelesen werden!',
                        err
                    );
                },
            });
        }
    }

    loadBestellungen(sender: string) {

        if (this.tourenplanSubscription) {
            this.tourenplanSubscription.unsubscribe();
        }

        this.appService.setDataLoading(true);

        let params = new HttpParams();
        if (this.showOnlyMine) {
            if (this.kieneSession.tierarzt_id) {
                params = params.append(
                    'tierarzt_id',
                    this.kieneSession.tierarzt_id.toString()
                );
            }
        }

        if (this.onlyToday) {
            params = params.append('heute', '1');
        }

        if (this.history) {
            params = params.append('historie', '1');
        }

        if (this.kw) {
            params = params.append('kw', '1');
        }

        if (this.currentTierarztgruppe) {
            params = params.append(
                'tierarztgruppe_id',
                this.currentTierarztgruppe.tierarztgruppe_id.toString()
            );
        }

        params = params.append('offset', this.offset.toString());
        params = params.append('limit', this.pageSize.toString());
        this.tourenplanSubscription = this.api
            .getAll(this.apiUrl + 'tourenplan_tierarzt/read.php', params)
            .subscribe(
                (response) => {
                    this.tourenplans = response.records;
                    this.length = response.count;
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.appService.setDataLoading(false);
                    this.messageService.errorMessage(
                        'Tourenplan konnte nicht geladen werden!',
                        error
                    );
                }
            );

        // KW Info abfragen:
        // Einträge für diese Woche, aber nicht heute
        if (this.kwInfoSubscription) {
            this.kwInfoSubscription.unsubscribe();
        }

        if (!this.history && this.onlyToday) {
            params = params.delete('heute');
            params = params.set('kw', '1');
            params = params.append('nichtheute', '1');
            this.kwInfoSubscription = this.api
                .getAll(this.apiUrl + 'tourenplan_tierarzt/read.php', params)
                .subscribe({
                    next: (response) => {
                        this.kwInfo = response.count;
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Einträge der KW konnten nicht abgefragt werden',
                            err
                        );
                        this.kwInfo = 0;
                    },
                });
        } else {
            this.kwInfo = 0;
        }
    }

    formatDate(date: Date) {
        const year = date.getFullYear();
        const month = date.getMonth();
        const day = date.getDate();

        let formatedDay = day.toString();
        let fomatedMonth = (month + 1).toString();
        if (day < 10) {
            formatedDay = '0' + day;
        }

        if (month + 1 < 10) {
            fomatedMonth = '0' + (month + 1);
        }

        return year + '-' + fomatedMonth + '-' + formatedDay;
    }

    assignToMe(t: Tourenplan) {
        t.tierarzt_id = this.kieneSession.tierarzt_id;
        this.service.assignToVet(t).subscribe(
            () => {
                this.messageService.infoMessage(
                    'Behandlungsanfrage wurde Ihnen erfolgreich zugewiesen!'
                );
                this.loadBestellungen('assignToMe');
            },
            (error) => {
                this.messageService.errorMessage(
                    'Die Behandlungsanfrage konnte Ihnen nicht zugewiesen werden! Fehler: ',
                    error
                );
            }
        );
    }

    release(t: Tourenplan) {
        t.status_id = 3;
        this.api.updateGeneric(environment.BASE_URI + 'tourenplan_tierarzt/update.php', t).subscribe(
            () => {
                this.messageService.infoMessage('Erfolgreich freigegeben!');
                this.loadBestellungen('release');
            },
            (error) => {
                this.messageService.errorMessage(
                    'Die Behandlungsanfrage konnte nicht freigegeben werden!',
                    error
                );
                this.loadBestellungen('release');
            }
        );
    }

    goToBehandlungsanfrage(t: Tourenplan) {
        this.router.navigate([
            'belege',
            'behandlungsanfragen',
            t.bestellung_id,
        ]).then();
    }

    editTour(t: Tourenplan) {
        if (this.isMobileScreen) {
            this.router.navigate(['edittourenplan', t.bestellung_id]).then();
        } else {
            const dialogRef = this.dialog.open(AddTourDialogComponent, {
                width: '50%',
                data: {
                    tour: t,
                },
            });

            dialogRef.afterClosed().subscribe(() => {
                this.loadBestellungen('editTour');
            });
        }
    }

    deleteTour(t: Tourenplan) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: {
                title: 'Tour löschen',
                text: 'Soll diese Tour wirklich gelöscht werden?',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                t.status_id = 6;
                this.api
                    .updateGeneric(environment.BASE_URI + 'behandlungsanfrage/update.php', t)
                    .subscribe(
                        () => {
                            this.messageService.infoMessage(
                                'Die Behandlungsanfrage wurde erfolgreich storniert!'
                            );
                            this.loadBestellungen('deleteTour');
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Die Behandlungsanfrage konnte nicht storniert werden! ',
                                error
                            );
                        }
                    );
            }
        });
    }

    pageEvent(event: PageEvent) {
        this.offset = this.pageSize * event.pageIndex;
        this.pageSize = event.pageSize;
        this.loadBestellungen('pageEvent');
    }

    @HostListener('document:keydown', ['$event'])
    createNewTour(event: KeyboardEvent) {
        if (event.code === 'KeyN' && !this.isKeyEventFromInput(event)) {
            event.stopPropagation();
            setTimeout(() => {
                this.openAddTourDialog();
            }, 10);
        }
    }

    openAddTourDialog() {
        if (this.isMobileScreen) {
            this.router.navigate(['edittourenplan', 'neu']);
        } else {
            const dialogRef = this.dialog.open(AddTourDialogComponent, {
                width: '50%',
            });
            dialogRef.afterClosed().subscribe((result) => {
                this.loadBestellungen('openAddTourDialog');
            });
        }
    }

    downloadTourenplan() {
        const id = this.currentTierarztgruppe
            ? this.currentTierarztgruppe.tierarztgruppe_id?.toString()
            : '';
        const bezeichnung = this.currentTierarztgruppe
            ? this.currentTierarztgruppe.bezeichnung
            : 'Alle';

        const url = 'tourenplan_tierarzt/create_or_mail_pdf.php';

        let params = new HttpParams();
        params = params.append('informationstyp', '1');

        if (id) {
            params = params.append('tierarztgruppe_id', id);
        }

        if (this.showOnlyMine) {
            params = params.append(
                'tierarzt_id',
                this.kieneSession.tierarzt_id?.toString()
            );
        }

        if (this.onlyToday) {
            params = params.append('heute', '1');
        }

        if (this.kw) {
            params = params.append('kw', '1');
        }

        if (this.isMobileScreen) {
            this.pdfViewer.downloadPdf(environment.BASE_URI + url);
        } else {
            const input = new PdfViewerDialogInput(
                url,
                params,
                'Tourenplan von ' + bezeichnung,
                {
                    email: {
                        sende_mail: true,
                        kunde_id: null,
                    },
                    forceSend: true
                }
            );
            this.pdfViewer.openPdfViewer(input);
        }
    }

    undone(t: Tourenplan) {
        t.tourenplan = 1;
        this.api.updateGeneric(environment.BASE_URI + 'tourenplan_tierarzt/update.php', t).subscribe({
            next: (response) => {
                this.messageService.infoMessage(
                    'Anfrage wurde wieder als nicht erledigt markiert!'
                );
                this.loadBestellungen('undone');
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Anfrage konnte nicht als nicht erledigt markiert werden!',
                    err
                );
            },
        });
    }

    done(t: Tourenplan) {
        const input = new ConfirmDialogInput();
        input.headline = 'Erledigt';
        input.text =
            'Soll die Anfrage für ' +
            (t.kunde ? t.kunde : t.unternehmensgruppe) +
            ' als erledigt markiert werden?';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    t.tourenplan = 2; // 2 ist status done
                    this.api
                        .updateGeneric(environment.BASE_URI + 'tourenplan_tierarzt/update.php', t)
                        .subscribe(
                            (response) => {
                                this.messageService.infoMessage(
                                    'Die Anfrage wurde erfolgreich als erledigt markiert!'
                                );
                                this.loadBestellungen('done');
                            },
                            (error) => {
                                this.messageService.errorMessage(
                                    'Fehler! Die Anfrage konnte nicht als erledigt markiert werden!',
                                    error
                                );
                            }
                        );
                }
            },
        });
    }

    transformAbgabebeleg(t: Tourenplan) {
        const input = new ConfirmDialogInput();
        input.headline = 'In Abgabebeleg umwandeln';
        input.text =
            'Soll die Anfrage für ' +
            (t.kunde ? t.kunde : t.unternehmensgruppe) +
            ' in ein Abgabebeleg umgewandelt werden?';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.behandlungsanfrageService
                        .transformToAbgabebeleg(t)
                        .subscribe(
                            (response) => {
                                const abgabebeleg: Abgabebeleg = response;
                                this.setAsCurrentClient(abgabebeleg);
                                this.messageService.infoMessage(
                                    'Die Behandlungsanfrage wurde erfolgreich in einen Abgabebeleg umgewandelt!'
                                );
                                this.router.navigate([
                                    'belege',
                                    'abgabebelege',
                                    abgabebeleg.abgabebeleg_id,
                                ]);
                            },
                            (error) => {
                                this.messageService.errorMessage(
                                    'Die Behandlungsanfrage konnte nicht in ein Abgabebeleg umgewandelt werden! ',
                                    error
                                );
                            }
                        );
                }
            },
        });
    }

    setAsCurrentClient(abgabebeleg: Abgabebeleg) {
        if (abgabebeleg) {
            const kunde_id = abgabebeleg.kunde_id;
            this.clientService.getClientById(kunde_id).subscribe(
                (client) => {
                    if (client) {
                        this.localStorageService.setCurrentClient(client);
                    }
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Der Kunde mit der ID ' +
                        kunde_id +
                        ' konnte nicht geladen werden!',
                        error
                    );
                }
            );
        }
    }

    releaseVet(t: Tourenplan) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: {
                title: 'Tierarzt entfernen',
                text: 'Soll der Tierarzt von der Tour entfernt werden?',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                t.tierarzt = null;
                t.tierarzt_id = null;
                this.api.updateGeneric(environment.BASE_URI + 'tourenplan_tierarzt/update.php', t).subscribe(
                    (response) => {
                        this.loadBestellungen('releaseVet');
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Aufheben der Zuweisung war nicht möglich!',
                            error
                        );
                    }
                );
            }
        });
    }

    bestellungAbgeholt(t: Tourenplan) {
        if (t.status_id !== 4) {
            this.messageService.infoMessage(
                'Achtung: Bestellung ist noch nicht fertig bearbeitet!'
            );
            return;
        }

        const input = new ConfirmDialogInput();
        input.headline = 'Bestellung umbuchen';
        input.text = 'Wurde die Bestellung abgeholt?';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (response: ConfirmDialogResult) => {
                if (response?.confirmed) {
                    if (t.status_id === 4) {
                        t.status_id = 5;
                        t.tierarzt_id =
                            this.localStorageService.getCurrentUser().tierarzt_id;
                        this.api
                            .updateGeneric(environment.BASE_URI + 'bestellung/update.php', t)
                            .subscribe(
                                (result) => {
                                    this.loadBestellungen('bestellungAbgeholt');
                                    this.messageService.infoMessage(
                                        'Die Bestellung ' +
                                        result.bestellung_id +
                                        ' wurde erfolgreich umgebucht!'
                                    );
                                },
                                (error) => {
                                    this.messageService.errorMessage(
                                        'Die Bestellung ' +
                                        t.bestellung_id +
                                        ' konnte nicht umgebucht weden!',
                                        error
                                    );
                                    this.loadBestellungen('bestellungAbgeholt');
                                }
                            );
                    }
                }
            },
        });
    }

    openKWDialog() {
        const dialogRef = this.dialog.open(KWDialogComponent);
    }

    assignToVet(t: Tourenplan) {
        const vetData = new AssignVetToTourData();
        vetData.tierarzt_id = t.tierarzt_id;
        if (!vetData.tierarzt_id) {
            vetData.tierarzt_id = this.localStorageService.getCurrentUser()?.tierarzt_id;
        }
        const dialogRef = this.dialog.open(AssignVetToTourDialogComponent, {data: vetData});
        dialogRef.afterClosed().subscribe({
            next: (result: AssignVetToTourResult) => {
                if (result?.confirmed) {
                    t.tierarzt_id = result.tierarzt_id;
                    this.service.assignToVet(t).subscribe({
                        next: response => {
                            this.messageService.infoMessage(
                                'Behandlungsanfrage wurde Ihnen erfolgreich zugewiesen!'
                            );
                            this.loadBestellungen('assignToMe');
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler beim Zuweisen!', err);
                        }
                    });
                }
            }
        });
    }

    reload() {
        this.loadBestellungen('releod');
    }

    private isKeyEventFromInput(event: KeyboardEvent): boolean {
        if (event.target instanceof Node) {
            switch (event.target.nodeName) {
                case 'INPUT':
                case 'SELECT':
                case 'TEXTAREA':
                    return true;
                default:
                    return false;
            }
        }
        return false;
    }
}
