import {Tierart} from './tierart';

export class Betriebsart {
    betriebsart_id: number;
    sortierung: number;
    bezeichnung: string;
    kaelbermast: number;
    verkaufspreis_id: number;
    verkaufspreis: string;
    rabatt: number;
    ohrmarke: number;
    qs: number;
    hit: number;
    qs_schluessel: number;
    hit_schluessel: number;
    produktionsabschnitte: Produktionsabschnitt[] = [];
    tierarten: Tierart[] = [];
}

export class Produktionsabschnitt {
    produktionsabschnitt_id: number;
    betriebsart_id: number;
    produktionsabschnitt: string;
    sortierung: number;
}

export class BetriebsartMandant {
    betriebsart_id: number;
    verkaufspreis_id: number;
    verkaufspreis: string;
    rabatt: number;
}
