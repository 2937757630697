import { Component, OnInit } from '@angular/core';
import { TableDescriptor } from '../../components/kiene-table/table-descriptor';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { TableColumn } from '../../components/kiene-table/table-column';
import {
    TableOptionEvent,
    TableOption,
} from '../../components/kiene-table/table-option';
import { UntersuchungsgruppeDialogComponent } from './untersuchungsgruppe-dialog/untersuchungsgruppe-dialog.component';
import { Untersuchungsgruppe } from './untersuchungsgruppe';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../services/message.service';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';

@Component({
    selector: 'kiene-untersuchungsgruppe',
    templateUrl: './untersuchungsgruppe.component.html',
    styleUrls: ['./untersuchungsgruppe.component.scss'],
})
export class UntersuchungsgruppeComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private api: KieneTableService,
        private notification: MessageService
    ) { }

    ngOnInit() {
        this.tableDescriptor.headline = 'Untersuchungsgruppen';
        this.tableDescriptor.uniqueIdentifier = 'Untersuchungsgruppen';
        this.tableDescriptor.apiUrl =
            environment.BASE_URI + 'untersuchungsgruppe/';
        this.tableDescriptor.route = '/labor/unteruchungsgruppen/';
        this.tableDescriptor.nameOfIdField = 'untersuchungsgruppe_id';
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;

        this.tableDescriptor.columns.push(
            new TableColumn('bezeichnung', 'Untersuchungsgruppe')
        );

        this.tableDescriptor.options.push(
            new TableOption('edit', 'bearbeiten', 'edit', 1)
        );
        this.tableDescriptor.options.push(
            new TableOption('delete', 'löschen', 'delete', 1)
        );

        this.tableDescriptor.permissionCreate = 1233;
        this.tableDescriptor.permissionUpdate = 1233;
        this.tableDescriptor.permissionView = 1231;
        this.tableDescriptor.permissionDelete = 1234;

        this.tableDescriptor.initialSortColumn = 'bezeichnung';
        this.tableDescriptor.initialSortDirection = 'asc';
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            this.openAddDialog();
        } else if (event.name === 'edit') {
            this.openEditDialog(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        }
    }

    openDeleteDialog(untersuchungsgruppe: Untersuchungsgruppe) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: {
                title: untersuchungsgruppe.bezeichnung + ' löschen',
                text:
                    'Wollen Sie ' +
                    untersuchungsgruppe.bezeichnung +
                    ' wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.api
                    .deleteEntity(
                        environment.BASE_URI + 'untersuchungsgruppe/delete.php',
                        untersuchungsgruppe
                    )
                    .subscribe(
                        (response) => {
                            this.notification.infoMessage(
                                'Untersuchungsgruppe erfolgreich gelöscht!'
                            );
                            this.api.reload();
                        },
                        (error) => {
                            this.notification.errorMessage(
                                'Untersuchungsgruppe konnte nicht gelöscht werden!',
                                error
                            );
                        }
                    );
            }
        });
    }

    openAddDialog() {
        const dialogRef = this.dialog.open(UntersuchungsgruppeDialogComponent, {
            width: '50%',
            minWidth: '300px',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const ug = <Untersuchungsgruppe>result;
                this.api
                    .updateEntity(environment.BASE_URI + 'untersuchungsgruppe/update.php', ug)
                    .subscribe(
                        (response) => {
                            this.notification.infoMessage(
                                'Untersuchungsgruppe erfolgreich gespeichert!'
                            );
                            this.api.reload();
                        },
                        (error) => {
                            this.notification.errorMessage(
                                'Untersuchungsgruppe konnte nicht gespeichert werden!',
                                error
                            );
                        }
                    );
            }
        });
    }
    openEditDialog(untersuchungsgruppe: Untersuchungsgruppe) {
        const dialogRef = this.dialog.open(UntersuchungsgruppeDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: {
                untersuchungsgruppe: untersuchungsgruppe,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const ug = <Untersuchungsgruppe>result;
                this.api
                    .updateEntity(environment.BASE_URI + 'untersuchungsgruppe/update.php', ug)
                    .subscribe(
                        (response) => {
                            this.notification.infoMessage(
                                'Untersuchungsgruppe erfolgreich gespeichert!'
                            );
                            this.api.reload();
                        },
                        (error) => {
                            this.notification.errorMessage(
                                'Untersuchungsgruppe konnte nicht gespeichert werden!',
                                error
                            );
                        }
                    );
            }
        });
    }
}
