import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AusstallenFrageDialogComponent } from './ausstallen-frage-dialog/ausstallen-frage-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { DialogsModule } from '../dialogs/dialogs.module';
import { TierlisteImportierenFrageDialogComponent } from './tierliste-importieren-frage-dialog/tierliste-importieren-frage-dialog.component';
import { AusstallenDialogComponent } from './ausstallen-dialog/ausstallen-dialog.component';
import { MeineKontakteModule } from '../meine-kontakte/meine-kontakte.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { DirectivesModule } from '../../directives/directives.module';

@NgModule({
    declarations: [
        AusstallenFrageDialogComponent,
        TierlisteImportierenFrageDialogComponent,
        AusstallenDialogComponent,
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatRadioModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        DialogsModule,
        MeineKontakteModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatCardModule,
        MatDividerModule,
        DirectivesModule
    ],
    exports: [AusstallenFrageDialogComponent, AusstallenDialogComponent],
})
export class AusstallenModule { }
