<h1 mat-dialog-title>Inventur anlegen</h1>
<div mat-dialog-content>

    <mat-form-field style="width: 100%;">
        <mat-label>Name der Inventur</mat-label>
        <input matInput type="text" [formControl]="nameCtrl">
    </mat-form-field>
    <mat-form-field>
        <mat-label>Datum der Inventur</mat-label>
        <input matInput [formControl]="datumCtrl" [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <br>
    <mat-checkbox [formControl]="aktivCtrl">Aktiv</mat-checkbox>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">{{inventur?.inventur_id ? 'Speichern' : 'Anlegen'}}</button>
</div>
