<form class="vvvo-form">
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="hit-login-form-input">
            <mat-label>VVVO-Nummer</mat-label>
            <input #VvvoInput="matInput" matInput type="text" placeholder="VVVO-Nummer" [formControl]="hitVvvoCtrl"
                   autocomplete="username">
            <span matSuffix style="font-size: .8em;">{{hitVvvoCtrl.value.length}}/15</span>
            <mat-error class="input-error" *ngIf="hitVvvoCtrl.hasError('required')">Die VVVO-Nummer ist ein
                Pflichtfeld.
            </mat-error>
            <mat-error class="input-error" *ngIf="hitVvvoCtrl.hasError('pattern')">Die VVVO-Nummer muss 15 Zeichen lang
                sein.
            </mat-error>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div data-cy="hit-pwd-button" class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="hit-login-form-input">
            <mat-label>Passwort</mat-label>
            <input matInput placeholder="Passwort" [type]="hidePassword ? 'password' : 'text'"
                   [formControl]="hitPinCtrl" autocomplete="current-password">
            <mat-error class="input-error" *ngIf="hitPinCtrl.hasError('required')">Das Passwort ist ein Pflichtfeld.
            </mat-error>
            <mat-icon matSuffix
                      (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility' : 'visibility_off'}}
            </mat-icon>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <button mat-flat-button type="button" color="accent" class="button"
            [disabled]="!this.hitFormGroup.valid || isLoading" (click)="anmelden()">Anmelden
    </button>
</form>

