import { Component, Inject, OnInit } from '@angular/core';
import { TableDescriptor, ToolbarButton, } from '../../components/kiene-table/table-descriptor';
import { StatusTableColumn, TableColumn, } from '../../components/kiene-table/table-column';
import { environment } from '../../../environments/environment';
import { Condition, Operator, TableOption, TableOptionEvent, } from '../../components/kiene-table/table-option';
import { BestellungIntern } from './bestellung-intern';
import { MatDialog } from '@angular/material/dialog';
import { BestellungInternServiceService } from './bestellung-intern-service.service';
import { MessageService } from '../../services/message.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {
    FilterItem,
    FilterItemType,
} from '../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectDateDialogComponent } from '../../dialogs/select-date-dialog/select-date-dialog.component';
import {
    ConfirmDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import { HttpParams } from '@angular/common/http';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {
    AssignVetToTourDialogComponent
} from '../../components/tourenplan/assign-vet-to-tour-dialog/assign-vet-to-tour-dialog.component';
import {
    AssignVetToTourData,
    AssignVetToTourResult
} from '../../components/tourenplan/assign-vet-to-tour-dialog/assign-vet-to-tour';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-bestellung-intern',
    templateUrl: './bestellung-intern.component.html',
    styleUrls: ['./bestellung-intern.component.scss'],
})
export class BestellungInternComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private bestellungInternService: BestellungInternServiceService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private pdfService: PdfViewerDesktopService
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Bestellungen Intern';
        this.tableDescriptor.uniqueIdentifier = 'Bestellungen Intern';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'bestellung/';
        this.tableDescriptor.route = '/wawi/bestellung-intern/';
        this.tableDescriptor.nameOfIdField = 'bestellung_id';
        this.tableDescriptor.searchPlaceholder = 'Bestellungen durchsuchen';
        this.tableDescriptor.columns.push(
            new TableColumn('bestellung_id', 'Nr.')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('bezeichnung', 'Bezeichnung')
        );
        this.tableDescriptor.columns.push(
            new TableColumn(
                'behandlungsanfrage',
                'Behandlungsanfrage',
                'boolean'
            )
        );
        this.tableDescriptor.columns.push(
            new TableColumn(
                'abgabebeleg',
                'Abgabebeleg',
                'boolean'
            )
        );

        const valueColorMap = new Map<number, string>();
        valueColorMap.set(6, '#a30000');
        valueColorMap.set(5, '#004777');
        valueColorMap.set(4, '#ff7700');
        valueColorMap.set(3, '#8cb369');
        valueColorMap.set(2, '#efd28d');
        valueColorMap.set(1, '#8cb369');

        this.tableDescriptor.columns.push(
            new StatusTableColumn('status', 'Status', valueColorMap)
        );

        this.tableDescriptor.columns.push(
            new TableColumn('zieldatum', 'Zieldatum', 'date')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('tierarzt', 'Tierarzt')
        );

        this.tableDescriptor.columns.push(new TableColumn('kunde', 'Kunde'));
        this.tableDescriptor.columns.push(
            new TableColumn('erstellt', 'Erstellt am', 'datetime')
        );

        this.tableDescriptor.permissionCreate = 1033;
        this.tableDescriptor.permissionDelete = 1034;
        this.tableDescriptor.permissionUpdate = 1033;
        this.tableDescriptor.permissionView = 1031;

        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Anfrage aufgegeben',
                2,
                FilterItemType.CHECKBOX,
                'status_id'
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Anfrage freigegeben',
                3,
                FilterItemType.CHECKBOX,
                'status_id',
                true
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Anfrage kommissioniert',
                4,
                FilterItemType.CHECKBOX,
                'status_id',
                true
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Anfrage abgeholt',
                5,
                FilterItemType.CHECKBOX,
                'status_id',
                true
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Anfrage abgeholt und umgewandelt',
                1,
                FilterItemType.CHECKBOX,
                'abgeholtundumgewandelt',
                false
            )
        );
        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Anfrage storniert',
                6,
                FilterItemType.CHECKBOX,
                'status_id',
                false
            )
        );

        this.tableDescriptor.options.push(
            new TableOption(
                'bookToMe',
                'abgeholt (mir zuweisen)',
                'forward',
                1037, {
                conditions: [new Condition('status_id', Operator.GLEICH, 4)]
            }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'bookToVet',
                'wurde abgeholt',
                'forward',
                1038, {
                conditions: [new Condition('status_id', Operator.GLEICH, 4)]
            }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption('delete', 'löschen', 'delete', 1034)
        );
        this.tableDescriptor.options.push(
            new TableOption('cancel', 'stornieren', 'cancel', 1033)
        );
        this.tableDescriptor.options.push(
            new TableOption('pdf', 'PDF anzeigen', 'description', 1031)
        );

        this.tableDescriptor.toolbarButtons.push(
            new ToolbarButton(
                'order',
                'post_add',
                1391,
                'Bestellung aus Bestellvorschlägen generieren'
            )
        );
        this.tableDescriptor.toolbarButtons.push(
            new ToolbarButton(
                'order_abgabebelege',
                'add_circle_outline',
                1391,
                'Bestellung aus Abgabebelegen generieren'
            )
        );

        this.tableDescriptor.initialSortColumn = 'erstellt';
        this.tableDescriptor.initialSortDirection = 'desc';
    }

    catchOptionEvent(event: TableOptionEvent) {
        const bestellung = <BestellungIntern>event.value;
        if (event.name === 'bookToMe') {
            this.openBookConfirmDialog(bestellung);
        } else if (event.name === 'bookToVet') {
            this.bookToVet(bestellung)
        } else if (event.name === 'delete') {
            this.openDeleteDialog(bestellung);
        } else if (event.name === 'cancel') {
            this.openCancelDialog(bestellung);
        } else if (event.name === 'pdf') {
            this.showPdf(bestellung);
        } else if (event.name === 'order') {
            this.openOrderConfirmDialog();
        } else if (event.name === 'order_abgabebelege') {
            this.generateOrderFromAbgabebelege();
        }
    }

    showPdf(bestellungIntern: BestellungIntern) {
        this.pdfService.openPdfViewer(
            new PdfViewerDialogInput(
                'bestellung/pdf.php',
                new HttpParams().set(
                    'bestellung_id',
                    bestellungIntern?.bestellung_id?.toString()
                ),
                'Bestellung Intern'
            )
        );
    }

    public openOrderConfirmDialog() {
        const input = new ConfirmDialogInput();
        input.headline = 'Bestellung generieren?';
        input.text =
            'Wollen Sie für alle benötigten Artikel eine Bestellung generieren?';
        input.okButton = 'Bestellung generieren';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.tableService
                        .post(
                            environment.BASE_URI + 'bestellung/generieren.php',
                            {},
                            null
                        )
                        .subscribe(
                            (response) => {
                                this.messageService.infoMessage(
                                    'Die Bestellung wurde erfolgreich generiert'
                                );
                                const bi: BestellungIntern = response;
                                this.router.navigate(
                                    ['../bestellung-intern', bi.bestellung_id],
                                    { relativeTo: this.route }
                                );
                            },
                            (error) => {
                                this.messageService.errorMessage(
                                    'Die Bestellung konnte nicht generiert werden!',
                                    error
                                );
                                this.tableService.reload();
                            }
                        );
                }
            },
        });
    }

    bookToVet(bestellungIntern: BestellungIntern) {
        if (bestellungIntern.status_id !== 4) {
            this.messageService.infoMessage(
                'Achtung: Bestellung ist noch nicht fertig bearbeitet!'
            );
            return;
        }

        const vetData = new AssignVetToTourData();
        vetData.title = 'Tierarzt auswählen';
        vetData.buttonOk = 'Auswählen';
        vetData.tierarzt_id = bestellungIntern.tierarzt_id;
        if (!vetData.tierarzt_id) {
            vetData.showUnbekannt = true;
        }
        const dialogRef = this.dialog.open(AssignVetToTourDialogComponent, { data: vetData });
        dialogRef.afterClosed().subscribe({
            next: (result: AssignVetToTourResult) => {
                if (result?.confirmed) {
                    this.abgeholt(bestellungIntern, result.tierarzt_id);
                }
            }
        });
    }

    abgeholt(bestellungIntern: BestellungIntern, tierarzt_id: number) {
        if (bestellungIntern.status_id == 4) {
            bestellungIntern.status_id = 5;
            bestellungIntern.tierarzt_id = tierarzt_id;
            this.bestellungInternService.save(bestellungIntern).subscribe(
                (result) => {
                    this.tableService.reload();
                    this.messageService.infoMessage(
                        'Die Bestellung ' +
                        result.bestellung_id +
                        ' wurde erfolgreich umgebucht!'
                    );
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Die Bestellung ' +
                        bestellungIntern.bestellung_id +
                        ' konnte nicht umgebucht werden!',
                        error
                    );
                }
            );
        }
    }

    openBookConfirmDialog(bestellungIntern: BestellungIntern) {
        if (bestellungIntern.status_id !== 4) {
            this.messageService.infoMessage(
                'Achtung: Bestellung ist noch nicht fertig bearbeitet!'
            );
            return;
        }

        const input = new ConfirmDialogInput();
        input.headline = 'Bestellung umbuchen';
        input.text = 'Wurde die Bestellung abgeholt?';
        input.okButton = 'Abgeholt';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '30%',
            data: input,
        });

        dialogRef.afterClosed().subscribe((response: ConfirmDialogResult) => {
            if (response?.confirmed) {
                this.abgeholt(bestellungIntern, this.localStorageService.getCurrentUser().tierarzt_id);
            }
        });
    }

    openDeleteDialog(bi: BestellungIntern) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Bestellung ' + bi.bezeichnung + ' wirklich löschen?',
                text: 'Bestellung ' + bi.bezeichnung + ' wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.tableService
                    .deleteEntity(environment.BASE_URI + 'bestellung/delete.php', bi)
                    .subscribe(
                        (result) => {
                            this.messageService.infoMessage(
                                'Die Bestellung wurde erfolgreich gelöscht!'
                            );
                            this.tableService.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Die Bestellung konnte nicht gelöscht werden! ',
                                error
                            );
                        }
                    );
            }
        });
    }

    openCancelDialog(bi: BestellungIntern) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Bestellung ' + bi.bezeichnung + ' wirklich stornieren?',
                text: 'Bestellung ' + bi.bezeichnung + ' wirklich stornieren?',
                textButton: 'stornieren',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                bi.status_id = 6;
                this.tableService
                    .updateEntity(environment.BASE_URI + 'bestellung/update.php', bi)
                    .subscribe(
                        (result) => {
                            this.messageService.infoMessage(
                                'Die Bestellung wurde erfolgreich storniert!'
                            );
                            this.tableService.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Die Bestellung konnte nicht storniert werden! ',
                                error
                            );
                        }
                    );
            }
        });
    }

    generateOrderFromAbgabebelege() {
        const dialogRef = this.dialog.open(SelectDateDialogComponent, {
            data: {
                headline: 'Bestellung aus Abgabebelege erzeugen',
                text: 'Abgabebelege berücksichtigen bis einschließlich:',
                label: 'Datum',
                okButton: 'Erzeugen',
            },
        });

        dialogRef.afterClosed().subscribe({
            next: (d: Date) => {
                if (d) {
                    this.tableService
                        .post(
                            environment.BASE_URI +
                            'bestellung/generieren_datum.php',
                            { datum: d },
                            undefined
                        )
                        .subscribe({
                            next: (bestellung: BestellungIntern) => {
                                this.messageService.infoMessage(
                                    'Die Bestellung wurden erfolgreich erstellt!'
                                );
                                this.router.navigate([
                                    '/wawi',
                                    'bestellung-intern',
                                    bestellung.bestellung_id,
                                ]);
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Die Bestellung konnte nicht erzeugt werden!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }
}
