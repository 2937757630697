import { HttpParams } from "@angular/common/http";
import {
    Component,
    HostListener,
    Inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { EnterValueDialogComponent } from "../../../dialogs/enter-value-dialog/enter-value-dialog.component";
import {
    EnterValueInput,
    EnterValueResponse,
} from "../../../dialogs/enter-value-dialog/enter-value-response";
import { GruppeTier } from "../../../gruppe-tier/classes/gruppe-tier";
import { API_BASE_URL_SERVICE } from "../../../../kiene-core.config";
import { KieneBackendApiService } from "../../../../services/backend-api/kiene-backend-api.service";
import { MessageService } from "../../../../services/message-service/message.service";
import { KieneUtils } from "../../../../utils/KieneUtils";
import { Haendler } from "../../../stammdaten/haendler/haendler";
import { GruppeAbgangService } from "../gruppe-abgang.service";
import { Tierliste } from "../../../gruppe-tier/classes/tierliste";
import { Subscription } from "rxjs";
import { AbgangScanResult } from "../abgang-scannen/abgang-scannen.component";

@Component({
    selector: "kiene-abgang-number-pad",
    templateUrl: "./abgang-number-pad.component.html",
    styleUrls: ["./abgang-number-pad.component.scss"],
})
export class AbgangNumberPadComponent implements OnInit, OnChanges {
    @Input("abgangsliste") abgangsliste: Tierliste;

    @Input("isVisible") isVisible = false;

    numbers = "";
    fontSize = "3em";
    checkBackgroundColor = "lightgray";
    checkColor = "gray";
    checkBorderColor = "transparent";
    checkIcon = "";
    ohrmarke = "276031234567890";
    ohrmarkeGefunden = false;

    currentAbnehmer: Haendler;

    ohrmarken: GruppeTier[] = [];

    keinErgebnis: boolean;
    selectedTier: GruppeTier;
    ladeOhrmarkeSub: Subscription;
    gescannteOhrmarken: AbgangScanResult[] = [];

    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        private abgangService: GruppeAbgangService,
        private messageService: MessageService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.abgangService.watchAbnehmer().subscribe({
            next: (val) => {
                this.currentAbnehmer = val;
            },
        });

        this.abgangService.watchAbgangsliste().subscribe({
            next: () => {
                this.clear();
            },
        });
    }

    ngOnChanges(changes: SimpleChanges): void {}

    @HostListener("window:keyup", ["$event"])
    catchKeyboardEvent(event: KeyboardEvent) {
        if (!this.isVisible) {
            return;
        }
        console.log(event.code);
        if (
            (event.code === "Enter" || event.code === "NumpadEnter") &&
            !this.isKeyEventFromInput(event)
        ) {
            if (this.ohrmarkeGefunden && this.numbers.length > 4) {
                this.check();
            }
        } else if (
            (event.code.startsWith("Numpad") ||
                event.code.startsWith("Digit")) &&
            !this.isKeyEventFromInput(event)
        ) {
            const splitted = event.code.split(/Numpad|Digit/);
            if (splitted && splitted[1]) {
                this.addNumber(Number.parseInt(splitted[1], 10));
            }
        } else if (
            event.code === "Backspace" &&
            !this.isKeyEventFromInput(event)
        ) {
            this.backspace();
        }
    }

    addNumber(number: number) {
        this.calcFontSize();
        this.numbers += `${number}`;
        this.ladeOhrmarke();
        this.calcFontSize();
    }

    ladeOhrmarke() {
        let params = new HttpParams();
        if (this.abgangsliste?.gruppe_id_abgang) {
            params = params.set(
                "gruppe_id",
                this.abgangsliste.gruppe_id_abgang.toString()
            );
        } else {
            params = params.set("alle_gruppen", "1");
        }
        params = params.set("ohrmarke5", this.numbers);
        params = params.set("tot", "0");
        params = params.set(
            "tierliste_id_or_null",
            this.abgangsliste.tierliste_id.toString()
        );

        this.ladeOhrmarkeSub?.unsubscribe();
        this.ladeOhrmarkeSub = this.api
            .getAll(
                this.apiBaseUrl + "gruppe_tier/read_kundenportal.php",
                params
            )
            .subscribe({
                next: (response) => {
                    this.ohrmarken = response.records;
                    this.checkOhrmarken();
                },
                error: (err) => {
                    this.messageService.errorMessage("Fehler!", err);
                },
            });
    }

    backspace() {
        if (this.numbers.length >= 1) {
            this.numbers = this.numbers.substring(0, this.numbers.length - 1);
            this.ladeOhrmarke();
        }

        this.calcFontSize();
    }

    checkOhrmarken() {
        if (this.ohrmarken.length === 1) {
            this.selectedTier = this.ohrmarken[0];
            this.ohrmarke = this.selectedTier.ohrmarke_notnull;
            this.ohrmarkeGefunden = true;
            this.keinErgebnis = false;
            if (this.selectedTier.tierliste_id) {
                this.checkBackgroundColor = "red";
                this.checkColor = "white";
                this.checkBorderColor =
                    this.numbers.length > 4 ? "red" : "transparent";
                this.checkIcon = "delete";
            } else {
                this.checkBackgroundColor = "green";
                this.checkColor = "white";
                this.checkBorderColor =
                    this.numbers.length > 4 ? "green" : "transparent";
                this.checkIcon = "check";
            }
        } else if (this.ohrmarken.length === 0) {
            this.selectedTier = undefined;
            this.ohrmarke = "nicht vorhanden";
            this.ohrmarkeGefunden = false;
            this.keinErgebnis = this.numbers.length > 4;
            this.checkBackgroundColor = "lightgray";
            this.checkColor = "gray";
            this.checkBorderColor =
                this.numbers.length > 4 ? "red" : "transparent";
        } else if (this.ohrmarken.length > 1) {
            this.selectedTier = undefined;
            this.ohrmarke = "nicht vorhanden";
            this.ohrmarkeGefunden = false;
            this.keinErgebnis = false;
            this.checkBackgroundColor = "lightgray";
            this.checkColor = "gray";
            this.checkBorderColor = "transparent";
        }
    }

    clear() {
        this.ohrmarken = [];
        this.ohrmarke = "nicht vorhanden";
        this.ohrmarkeGefunden = false;
        this.keinErgebnis = false;
        this.checkBackgroundColor = "lightgray";
        this.checkColor = "gray";
        this.checkBorderColor = "transparent";
        this.numbers = "";
    }

    check(weight?: number, abteil?: string) {
        if (this.selectedTier.tierliste_id) {
            this.ausListeEntfernen();
        } else {
            const obj = {
                tierliste_id: this.abgangsliste.tierliste_id,
                gruppen_tiere: [
                    {
                        gt_id: this.selectedTier.gt_id,
                        abgangsgewicht: weight,
                        abteil_abgang: abteil,
                    },
                ],
            };
            this.api
                .post(
                    this.apiBaseUrl + "tierliste/abgang_kundenportal.php",
                    obj
                )
                .subscribe({
                    next: (tierlisteAntwort: Tierliste) => {
                        const scanResult = new AbgangScanResult();
                        scanResult.ohrmarke =
                            this.selectedTier.ohrmarke_notnull;
                        scanResult.success = true;
                        this.gescannteOhrmarken.unshift(scanResult);
                        this.gescannteOhrmarken.splice(20, 1);

                        this.abgangService.setAbgangsliste(
                            tierlisteAntwort,
                            true,
                            true
                        );
                        this.numbers = "";
                        this.checkBackgroundColor = "lightgray";
                        this.checkColor = "gray";
                        this.checkBorderColor = "transparent";
                        this.ohrmarkeGefunden = false;
                        this.ohrmarke = "";
                        this.ohrmarken = [];
                    },
                    error: (err) => {
                        const scanResult = new AbgangScanResult();
                        scanResult.ohrmarke =
                            this.selectedTier.ohrmarke_notnull;
                        scanResult.success = false;
                        this.gescannteOhrmarken.unshift(scanResult);
                        this.gescannteOhrmarken.splice(20, 1);

                        this.messageService.errorMessage("Fehler!", err);
                        this.numbers = "";
                        this.checkBackgroundColor = "lightgray";
                        this.checkColor = "gray";
                        this.checkBorderColor = "transparent";
                        this.ohrmarkeGefunden = false;
                        this.ohrmarke = "";
                        this.ohrmarken = [];
                    },
                });
        }
    }

    ausListeEntfernen() {
        const input = new EnterValueInput();
        input.title = "Tier aus Liste entfernen?";
        input.text =
            "Es kann optional ein Grund für das Entfernen eingetragen werden.";
        input.okButton = "entfernen";
        input.buttonColor = "accent";
        input.addInputValue("Grund (optional)", "text");

        const dialogRef = this.dialog.open(EnterValueDialogComponent, {
            data: input,
            position: { top: "10px" },
        });
        dialogRef.afterClosed().subscribe({
            next: (res: EnterValueResponse) => {
                if (res?.result === true) {
                    if (KieneUtils.isNNoU(res.getValue(0))) {
                        this.selectedTier.grund_entfernung = res.getValue(0);
                    }
                    this.api
                        .post(
                            this.apiBaseUrl +
                                "gruppe_tier/delete_tierliste.php",
                            this.selectedTier,
                            undefined
                        )
                        .subscribe({
                            next: () => {
                                this.selectedTier = undefined;
                                this.abgangService.setAbgangsliste(
                                    this.abgangsliste,
                                    true,
                                    true
                                );
                                this.numbers = "";
                                this.checkBackgroundColor = "lightgray";
                                this.checkColor = "gray";
                                this.checkBorderColor = "transparent";
                                this.ohrmarkeGefunden = false;
                                this.ohrmarke = "";
                                this.ohrmarken = [];
                                this.messageService.infoMessage(
                                    "Das Tier wurde erfolgreich aus der Liste entfernt!"
                                );
                            },
                            error: (err) => {
                                this.numbers = "";
                                this.checkBackgroundColor = "lightgray";
                                this.checkColor = "gray";
                                this.checkBorderColor = "transparent";
                                this.ohrmarkeGefunden = false;
                                this.ohrmarke = "";
                                this.ohrmarken = [];
                                this.messageService.errorMessage(
                                    "Fehler!",
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    calcFontSize() {
        if (this.numbers.length > 12) {
            this.fontSize = "1.4em";
        } else if (this.numbers.length > 8) {
            this.fontSize = "2em";
        } else {
            this.fontSize = "3em";
        }
    }

    getFirstTenDigits(ohrmarke: string) {
        return ohrmarke.substring(0, ohrmarke.length - 5);
    }

    getLastFiveDigits(ohrmarke: string) {
        return ohrmarke.substring(ohrmarke.length - 5, ohrmarke.length);
    }

    openWeightDailog() {
        const input = new EnterValueInput();
        input.title = "Gewicht oder Abteil eingeben";
        input.text =
            "Es kann optional ein Gewicht oder Abteil angegeben werden";
        input.okButton = "Speichern";
        input.buttonColor = "accent";
        input.addInputValue("Gewicht in kg (optional)", "decimal");
        input.addInputValue(
            "Abteil (optional)",
            "text",
            this.abgangService.abteilAbgang
        );

        const dialogRef = this.dialog.open(EnterValueDialogComponent, {
            data: input,
            position: { top: "10px" },
        });
        dialogRef.afterClosed().subscribe({
            next: (response: EnterValueResponse) => {
                if (response) {
                    if (response.result) {
                        const gewicht = response.getValue(0);
                        const abteil = response.getValue(1);
                        this.abgangService.abteilAbgang = abteil;
                        this.check(gewicht, abteil);
                    }
                }
            },
        });
    }

    private isKeyEventFromInput(event: KeyboardEvent): boolean {
        if (event.target instanceof Node) {
            switch (event.target.nodeName) {
                case "INPUT":
                case "SELECT":
                case "TEXTAREA":
                    return true;
                default:
                    return false;
            }
        }
        return false;
    }
}
