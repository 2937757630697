import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Charge, ChargeApi } from './charges.component';
import { Observable } from 'rxjs';
import { Material } from '../material/material';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { environment } from '../../../environments/environment';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable()
export class ChargeService {

    constructor(private http: HttpClient, private api: KieneTableService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    getAllCharges(sortColumn: string, sortDirection: string, offset: number, limit: number, searchString: string): Observable<ChargeApi> {
        return this.http.get<ChargeApi>(this.apiBaseUrl + 'charge/read.php?orderby=' + sortColumn + ' ' + sortDirection + '&limit=' + limit + '&offset=' + offset + '&search=' + searchString);
    }

    saveCharge(charge: Charge): Observable<Charge> {
        return this.http.post<Charge>(this.apiBaseUrl + 'charge/create.php', charge);
    }

    searchCharges(searchString: string): Observable<ChargeApi> {
        return this.http.get<ChargeApi>(this.apiBaseUrl + 'charge/read.php?search=' + searchString);
    }

    getChargesForMaterial(material: Material): Observable<ChargeApi> {
        const params = new HttpParams().set('artikel_id', material.artikel_id.toString());
        return this.http.get<ChargeApi>(this.apiBaseUrl + 'charge/read.php', { params: params });
    }

    getCharge(chargeNumber: string, material: Material) {
        if (!chargeNumber || !material) {
            return null;
        }
        let params = new HttpParams();
        params = params.append('charge', chargeNumber);
        params = params.append('artikel_id', material.artikel_id.toString());
        return this.api.get(environment.BASE_URI + 'charge/read.php', params);
    }

}
