import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Material} from '../../warehouse/material/material';
import {UntypedFormControl} from '@angular/forms';
import {KundeArtikel} from '../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-add-client-artikel-dialog',
    templateUrl: './add-client-artikel-dialog.component.html',
    styleUrls: ['./add-client-artikel-dialog.component.scss'],
})
export class AddClientArtikelDialogComponent implements OnInit {
    clientId: number;
    ka: KundeArtikel = new KundeArtikel();
    einzelpreisCtrl = new UntypedFormControl();

    constructor(
        public dialogRef: MatDialogRef<AddClientArtikelDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.clientId = data.clientId;
    }

    ngOnInit() {
    }

    cancel() {
        this.dialogRef.close(false);
    }

    save() {
        this.ka.kunde_id = this.clientId;
        this.ka.einzelpreis = this.einzelpreisCtrl.value;
        this.dialogRef.close(this.ka);
    }

    setSelectedMaterial(event: Material) {
        this.ka.artikel_id = event.artikel_id;
    }
}
