import { KieneFileService } from "../../../../projects/kiene-core/src/lib/services/files/kiene-file.service";
import { HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { environment } from "../../../environments/environment";
import {
    NumberTableColumn,
    TableColumn,
} from "../../components/kiene-table/table-column";
import { TableDescriptor } from "../../components/kiene-table/table-descriptor";
import {
    TableOption,
    TableOptionEvent,
} from "../../components/kiene-table/table-option";
import { Primanota } from "./primanota";

@Component({
    selector: "kiene-primanota",
    templateUrl: "./primanota.component.html",
    styleUrls: ["./primanota.component.css"],
})
export class PrimanotaComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(private fileService: KieneFileService) { }

    ngOnInit(): void {
        this.tableDescriptor.headline = "Primanotas";
        this.tableDescriptor.uniqueIdentifier = "Primanotas";
        this.tableDescriptor.apiUrl = environment.BASE_URI + "primanota/";
        this.tableDescriptor.route = "/finanzen/primanota/";
        this.tableDescriptor.nameOfIdField = "primanota_id";
        this.tableDescriptor.searchPlaceholder = "Primanotas durchsuchen";
        this.tableDescriptor.createAllowed = false;
        this.tableDescriptor.initialSortColumn = "primanota_id";
        this.tableDescriptor.initialSortDirection = "desc";
        this.tableDescriptor.showDetails = false;

        // this.tableDescriptor.permissionView = 2971;

        this.tableDescriptor.columns.push(
            new TableColumn("primanota_id", "ID")
        );
        this.tableDescriptor.columns.push(
            new TableColumn("dateiname", "Dateiname")
        );
        this.tableDescriptor.columns.push(new TableColumn("status", "Status"));
        this.tableDescriptor.columns.push(new TableColumn("jahr", "Jahr"));
        this.tableDescriptor.columns.push(new TableColumn("monat", "Monat"));
        this.tableDescriptor.columns.push(
            new NumberTableColumn("anzahl", "Positionen", "1.0-0")
        );
        this.tableDescriptor.columns.push(
            new NumberTableColumn("umsatz_brutto", "Umsatz Brutto (€)")
        );
        this.tableDescriptor.columns.push(
            new TableColumn("erstellt", "erstellt", "date")
        );
        this.tableDescriptor.columns.push(
            new TableColumn("nachname", "erstellt von")
        );

        this.tableDescriptor.options.push(
            new TableOption(
                "downloadPrimanota",
                "Primanota Download",
                "cloud_download",
                2951
            )
        );
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === "downloadPrimanota") {
            this.downloadPrimanota(event.value);
        }
    }

    downloadPrimanota(primanota: Primanota) {
        const params = new HttpParams().append(
            "primanota_id",
            primanota?.primanota_id?.toString()
        );
        this.fileService.downloadCSV(
            environment.BASE_URI + "primanota/read_csv.php",
            params
        );
    }
}
