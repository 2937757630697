import { Pipe, PipeTransform } from '@angular/core';
import {KieneUtils} from "../KieneUtils";

@Pipe({
  name: 'replaceNullWithText'
})
export class ReplaceNullWithTextPipe implements PipeTransform {

  transform(value: any, replaceText: string = 'k.A.'): any {
      if (KieneUtils.isNoU(value)) {
          return replaceText;
      }
      return value;
  }

}
