import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {HttpParams} from '@angular/common/http';
import {BestandsbuchArtikelTier} from '../bestandsbuch/classes/bestandsbuch-artikel-tier';
import {environment} from '../../../../environments/environment';
import {KieneAutocompleteComponent} from '../../../components/kiene-autocomplete/kiene-autocomplete.component';
import {GruppeTier} from '../../../administration/client-group/gruppe-tier';

@Component({
  selector: 'kiene-bestandsbuch-artikel-tier-nachtragen-dialog',
  templateUrl: './bestandsbuch-artikel-tier-nachtragen-dialog.component.html',
  styleUrls: ['./bestandsbuch-artikel-tier-nachtragen-dialog.component.scss']
})
export class BestandsbuchArtikelTierNachtragenDialogComponent implements OnInit {

    params: HttpParams = new HttpParams();
    gruppeId: number;
    tier: BestandsbuchArtikelTier = new BestandsbuchArtikelTier();
    readTiere = `gruppe_tier/read_kundenportal.php`;

    @ViewChild('autocomplete') autocomplete: KieneAutocompleteComponent;

    constructor(public dialogRef: MatDialogRef<BestandsbuchArtikelTierNachtragenDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any) {
        this.gruppeId = data.gruppeId;
        if (data.tier)  {
            this.tier = data.tier;
        }
        this.params = this.params.set('gruppe_id', this.gruppeId.toString());
  }

  updateTier(tier: GruppeTier) {
        this.tier.editiert = new Date();
        this.tier.tier_id = tier.tier_id;
        this.tier.ohrmarke_notnull = tier.ohrmarke_notnull;
  }

  ngOnInit(): void {
  }

    onNoClick() {
        this.dialogRef.close();
    }

    save() {
        if (this.autocomplete.isValid()) {
            this.dialogRef.close(this.tier);
        }
    }
}
