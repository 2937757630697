import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {KalenderEintrag, KalenderTyp} from '../classes/kalender-eintrag';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {User} from '../../../administration/user/user';
import {HttpParams} from '@angular/common/http';
import {UrlaubskalenderService} from '../urlaubskalender.service';

@Component({
    selector: 'kiene-urlaubsantrag-stellen-dialog',
    templateUrl: './urlaubsantrag-stellen-dialog.component.html',
    styleUrls: ['./urlaubsantrag-stellen-dialog.component.scss']
})
export class UrlaubsantragStellenDialogComponent implements OnInit {

    antrag: KalenderEintrag = new KalenderEintrag();

    meineUrlaubstage: KalenderEintrag[] = [];

    currentUser: User;
    userPath = 'benutzer/read.php';
    params = new HttpParams().append('praxis', '1');

    datumVonCtrl: UntypedFormControl = new UntypedFormControl(null, Validators.required);
    datumBisCtrl: UntypedFormControl = new UntypedFormControl(null, Validators.required);
    beschreibungCtrl: UntypedFormControl = new UntypedFormControl();
    datumCtrl: UntypedFormGroup = new UntypedFormGroup({
        datumVonCtrl: this.datumVonCtrl,
        datumBisCtrl: this.datumBisCtrl
    });

    constructor(@Inject(MAT_DIALOG_DATA) data,
                public dialogRef: MatDialogRef<UrlaubsantragStellenDialogComponent>,
                private localStorageService: LocalStorageService,
                private urlaubsKalenderService: UrlaubskalenderService) {
        this.currentUser = <User><unknown>this.localStorageService.getCurrentUser();
    }

    ngOnInit(): void {
        this.meineUrlaubstageDiesesJahr();
    }

    onNoClick() {
        this.dialogRef.close();
    }

    antragStellen() {
        if (this.isValid()) {
            this.antrag.startdatum = this.datumVonCtrl.value;
            this.antrag.enddatum = this.datumBisCtrl.value;
            this.antrag.bemerkung = this.beschreibungCtrl.value;
            this.antrag.eintragtyp = KalenderTyp.Urlaub;
            this.antrag.eintragtyp_id = 1;
            this.antrag.benutzer_id = this.currentUser.benutzer_id;
            this.antrag.benutzername = this.currentUser.vorname + ' ' + this.currentUser.nachname;
            this.dialogRef.close(this.antrag);
        }
    }

    isValid(): boolean {
        this.datumCtrl.markAsTouched();
        this.datumVonCtrl.markAsTouched();
        this.datumBisCtrl.markAsTouched();
        return this.datumVonCtrl.valid && this.datumBisCtrl.valid;
    }

    selectedAntragssteller(selectedUser: User) {
        this.currentUser = selectedUser;
    }

    hasPermission() {
        return this.localStorageService.hasPermission(this.urlaubsKalenderService.kalenderRechte.antragAndererBenutzer);
    }

    setHalbtags(options: { halbtags_start?: number, halbtags_end?: number }) {
        if (options.halbtags_start) {
            this.antrag.startdatum_halbtags = options.halbtags_start;
        }
        if (options.halbtags_end) {
            this.antrag.enddatum_halbtags = options.halbtags_end;
        }
    }

    sameDates(d1: Date, d2: Date) {
        return this.urlaubsKalenderService.sameDates(d1, d2);
    }

    private meineUrlaubstageDiesesJahr() {
        this.urlaubsKalenderService.ladeMeineUrlaubstageDiesesJahr().subscribe({
            next: result => {
                this.meineUrlaubstage = result.records;
            }
        });
    }
}
