<div class="file-attachments">
    <div *ngIf="schlachtdaten || istSchlachthof">
        <h2>Schlachtdaten</h2>
        
        <div class="buchungs-datei" (click)="downloadDatei(schlachtdaten)" *ngIf="schlachtdaten">
            {{schlachtdaten.dateiname}}
        </div>

        <div *ngIf="istSchlachthof && !schlachtdaten">
            <button class="icon-btn pdf-btn" mat-flat-button color="primary" (click)="this.fileSelectorSchlachtdaten.click();">
                <img class="icon-btn-pdf" src="../../../../assets/pdf.svg">
                Schlachtdaten hochladen
            </button>
            <input type="file" style="display: none" #fileSelectorSchlachtdaten (change)="abrechnungAttached($event)"
                accept=".csv">
        </div>
    </div>

    <div *ngIf="abrechnung || istSchlachthof || istViehhandel">
        <h2>Abrechnung</h2>
        
        <div class="buchungs-datei" (click)="downloadDatei(abrechnung)" *ngIf="abrechnung">
            {{abrechnung.dateiname}}
        </div>

        <div *ngIf="istSchlachthof && !abrechnung">
            <button class="icon-btn pdf-btn" mat-flat-button color="primary" (click)="this.fileSelectorAbrechnung.click();" >
                <img class="icon-btn-pdf" src="../../../../assets/pdf.svg">
                Abrechnung hochladen
            </button>
            <input type="file" style="display: none" #fileSelectorAbrechnung (change)="abrechnungAttached($event)"
                accept="application/pdf">
        </div>
    </div>

    <div class="buchung-dateien">
        <h2>Angehängte-Dateien</h2>
        <div *ngFor="let datei of buchung_dateien">
            <div class="buchungs-datei" (click)="downloadDatei(datei)">
                {{datei.dateiname}}
            </div>
        </div>
    <button class="icon-btn pdf-btn" mat-flat-button color="accent" (click)="this.fileSelector.click();">
        <img class="icon-btn-pdf" src="../../../../assets/pdf.svg">
        Upload
    </button>
        <input type="file" style="display: none" #fileSelector multiple (change)="filesAttachend($event)"
        accept="application/pdf, image/*, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">    
   
    </div>
</div>