<h1 mat-dialog-title>Viehh&auml;ndler {{ viehhaendler?.viehhaendler_id ? 'editieren' : 'anlegen' }}</h1>
<div mat-dialog-content>


    <div class="one-row">


        <mat-form-field style="width: 49%;">
            <mat-label>Name</mat-label>
            <input matInput required type="text" [(ngModel)]="viehhaendler.name">
        </mat-form-field>

        <mat-form-field style="width: 49%;">
            <mat-label>VVVO</mat-label>
            <input matInput type="text" maxlength="15" minlength="12" [(ngModel)]="viehhaendler.vvvo">
            <span matSuffix>{{ viehhaendler?.vvvo?.length }}/15</span>
        </mat-form-field>

    </div>

    <div class="one-row">
        <mat-form-field style="width: 32%;">
            <mat-label>Stra&szlig;e</mat-label>
            <input matInput type="text" [(ngModel)]="viehhaendler.strasse">
        </mat-form-field>
        <mat-form-field style="width: 32%;">
            <mat-label>PLZ</mat-label>
            <input matInput type="text" [(ngModel)]="viehhaendler.plz">
        </mat-form-field>
        <mat-form-field style="width: 32%;">
            <mat-label>Ort</mat-label>
            <input matInput type="text" [(ngModel)]="viehhaendler.ort">
        </mat-form-field>
    </div>
    <div class="one-row">
        <mat-form-field style="width: 24%;">
            <mat-label>Telefon</mat-label>
            <input matInput type="tel" [(ngModel)]="viehhaendler.telefon">
        </mat-form-field>
        <mat-form-field style="width: 24%;">
            <mat-label>Mobil</mat-label>
            <input matInput type="tel" [(ngModel)]="viehhaendler.mobil">
        </mat-form-field>
        <mat-form-field style="width: 24%;">
            <mat-label>Fax</mat-label>
            <input matInput type="tel" [(ngModel)]="viehhaendler.fax">
        </mat-form-field>
        <mat-form-field style="width: 24%;">
            <mat-label>E-Mail</mat-label>
            <input matInput type="email" [(ngModel)]="viehhaendler.email">
        </mat-form-field>
    </div>

</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()" [disabled]="!viehhaendler?.name">Speichern</button>
</div>
