import { Material } from "../../warehouse/material/material";
import { Behandlungsanfrage } from "../../documents/behandlungsanfrage/behandlungsanfrage";

export class Tourenplan extends Behandlungsanfrage {
    bestellung_id: number;
    bezeichnung: string;
    zieldatum: Date;
    zieldatum_bis: Date;
    status_id: number;
    status: string;
    apotheke: number;
    kunde_id: number;
    kunde_nr_intern: number;
    kunde: string;
    unternehmensgruppe_id: number;
    unternehmensgruppe: string;
    abweichende_lieferadresse: number;
    zusatz: string;
    strasse: string;
    strasse_lieferung: string;
    strasse_ug: string;
    plz: string;
    plz_lieferung: string;
    plz_ug: string;
    ort: string;
    ort_lieferung: string;
    ort_ug: string;
    ortsteil: string;
    ortsteil_lieferung: string;
    ortsteil_ug: string;
    telefon_1: string;
    mobil_1: string;
    tierarzt: string;
    tierarzt_id: number;
    tourenplan: number;
}

export interface TourenplanApi {
    records: Tourenplan[];
    count: number;
}

export class ArtikelMenge {
    article: Material;
    menge: number;
    identifikation: string;
    anzahl_tiere: number;
    ba_id: number;
}
