<h1 mat-dialog-title>{{ bestandsbuch?.typ === 1 ? 'Bestandsbuch' : 'Impfkontrollbuch' }}</h1>
<div mat-dialog-content>
    <div>
        <p>
            Nr. {{ bestandsbuch?.abgabebeleg_nr }} vom
            {{ bestandsbuch?.datum | date : "dd.MM.yyyy" }}
        </p>

        <table *ngIf="bestandsbuch?.artikel?.length > 0" style="width: 100%">
            <ng-container *ngFor="let artikelPos of bestandsbuch?.artikel">
                <tr (click)="openArtikel(artikelPos)" style="width: 100%; cursor: pointer">
                    <td style="width: 5%">
                        <mat-icon [color]="
                                artikelPos?.fertig === 1 ? 'accent' : 'warn'
                            " style="margin-right: 3px">
                            {{
                                artikelPos?.fertig === 1
                                    ? "done"
                                    : "priority_high"
                            }}
                        </mat-icon>
                    </td>
                    <td>
                        <div>
                            <strong [ngClass]="
                                    !(artikelPos.behandlung === 0)
                                        ? 'greyed'
                                        : 'greened'
                                ">{{ artikelPos.artikel }}</strong>
                        </div>
                        <div *ngIf="!(artikelPos.behandlung === 0)" style="color: #777; font-size: 0.8em">
                            (Tierarzt)
                        </div>
                    </td>
                    <td style="text-align: right">
                        <mat-icon>chevron_right</mat-icon>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <mat-divider style="width: 100%"></mat-divider>
                    </td>
                </tr>
            </ng-container>
        </table>

        <div style="
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 0.85em;
                margin: 24px 0 12px 0;
            ">
            <div class="one-row">
                <mat-icon [color]="unterschriftVorhanden ? 'accent' : 'warn'">{{
                        unterschriftVorhanden ? "check" : "error"
                    }}
                </mat-icon>
                <span>
                    Unterschrift
                    <span>{{
                            unterschriftVorhanden ? "vorhanden" : "nicht vorhanden"
                        }}</span>
                </span>
                <button (click)="setUnterschrift()" color="accent" mat-icon-button>
                    <mat-icon>border_color</mat-icon>
                </button>
            </div>
            <mat-error *ngIf="hasNotUnterschriftTrigger" class="hinweis-text"
                       style="color: red; text-align: start; margin-bottom: 12px">
                Es wurde noch keine Unterschrift konfiguriert. Bitte in den
                Einstellungen oder über das Unterschriftensymbol ergänzen.
            </mat-error>
            <button (click)="alleProtokollieren()" *ngIf="bestandsbuch?.typ === 1" [disabled]="
                    isLoading ||
                    bestandsbuch?.status_id === BestandsbuchStatus.FERTIG ||
                    bestandsbuch?.abschliessbar === 1
                " color="accent" mat-flat-button style="width: 66%; margin: 12px">
                alle Artikel protokollieren
            </button>
            <button (click)="deleteBestandsbuch()" *kienePermission="[4424]" [disabled]="isLoading" color="accent"
                    mat-flat-button style="width: 66%; margin: 12px">
                <mat-icon>delete</mat-icon>
                Löschen
            </button>
            <button (click)="bestandsbuchAbschliessen(false)" *ngIf="bestandsbuch?.typ === 1" [disabled]="
                    isLoading ||
                    bestandsbuch?.status_id === BestandsbuchStatus.FERTIG ||
                    bestandsbuch?.abschliessbar === 0
                " color="accent" mat-flat-button style="width: 66%; margin: 12px">
                {{
                    this.bestandsbuch?.status_id === BestandsbuchStatus.FERTIG
                        ? "Abgeschlossen"
                        : "Abschließen"
                }}
            </button>
            <ng-container *ngIf="bestandsbuch?.typ === 2">

                <button (click)="impfkontrollbuchAbschliessenFrageDialog()" *kienePermission="[4426]"
                        [disabled]="isLoading || bestandsbuch?.status_id === BestandsbuchStatus.FERTIG || bestandsbuch?.abschliessbar === 0"
                        color="accent" mat-flat-button style="width: 66%; margin: 12px">
                    {{
                        this.bestandsbuch?.status_id === BestandsbuchStatus.FERTIG
                            ? "Abgeschlossen"
                            : "Abschließen"
                    }}
                </button>

            </ng-container>
            <div *ngIf="bestandsbuch?.abschliessbar === 0" style="text-align: justify">
                <mat-hint class="hinweis-text" style="font-size: 0.9em">
                    <strong>Hinweis: </strong>
                    <span>Das {{ bestandsbuch?.typ === 1 ? 'Bestandsbuch' : 'Impfkontrollbuch' }} kann noch nicht
                        abgeschlossen werden,
                        da nicht alle Artikel abgeschlossen wurden.
                    </span>
                </mat-hint>

                <div class="one-row"
                     *ngIf="bestandsbuch?.typ === 1">
                    <span class="spacer"></span>
                    <button (click)="bestandsbuchAbschliessen(true)"
                            [disabled]="isLoading"
                            color="accent" mat-flat-button style="width: 66%; margin: 12px">
                        Trotzdem Abschlie&szlig;en
                    </button>
                    <span class="spacer"></span>
                </div>
                <div class="one-row"
                     *ngIf="(bestandsbuch?.typ === 2 && localStorageService.isCurrentUserVeterinarian())">
                    <span class="spacer"></span>
                    <button (click)="impfkontrollbuchAbschliessenFrageDialog(true)"
                            [disabled]="isLoading"
                            color="accent" mat-flat-button style="width: 66%; margin: 12px">
                        Trotzdem Abschlie&szlig;en
                    </button>
                    <span class="spacer"></span>
                </div>
            </div>
            <div *ngIf="bestandsbuch?.typ === 2" class="one-row">
                <span class="spacer"></span>
                <mat-checkbox [formControl]="trotzdemAbschliessenCtrl">Tierarzt soll trotzdem abschlie&szlig;en!
                </mat-checkbox>
                <span class="spacer"></span>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button (click)="dialogRef.close(false)" mat-button>Schlie&szlig;en</button>
</div>
