import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {BenachrichtigungenRoutingModule} from './benachrichtigungen-routing.module';
import {BenachrichtigungUebersichtComponent} from './benachrichtigung-uebersicht/benachrichtigung-uebersicht.component';
import {BenachrichtigungButtonComponent} from './benachrichtigung-button/benachrichtigung-button.component';
import {MatButtonModule} from '@angular/material/button';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatBadgeModule} from '@angular/material/badge';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatFormFieldModule} from '@angular/material/form-field';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {BenachrichtigungenService} from './benachrichtigungen.service';


@NgModule({
    declarations: [
        BenachrichtigungUebersichtComponent,
        BenachrichtigungButtonComponent
    ],
    imports: [
        CommonModule,
        BenachrichtigungenRoutingModule,
        MatButtonModule,
        OverlayModule,
        MatBadgeModule,
        MatIconModule,
        MatSlideToggleModule,
        MatTooltipModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatListModule,
        InfiniteScrollModule
    ],
    exports: [
        BenachrichtigungUebersichtComponent,
        BenachrichtigungButtonComponent
    ],
    providers: [
        BenachrichtigungenService
    ]
})
export class BenachrichtigungenModule {
}
