<h1 mat-dialog-title>Gruppe bearbeiten</h1>
<div mat-dialog-content>
    <h3>{{ gruppe.kunde }}</h3>

    <mat-form-field>
        <mat-label>Gruppenname</mat-label>
        <input matInput type="text" [formControl]="nameCtrl">
    </mat-form-field>

    <div class="one-row">
        <kiene-autocomplete style="width: 244px; margin-right: 12px;" [kieneObject]="standort"
            [genericApiUrl]="'gruppe/read_stall.php'" [params]="standortParams" [placeholder]="'Standort'"
            [focus]="false" [displayFields]="['stall','sgs_nummer']" separator=", " [showClear]="false"
            [emitFreeText]="true" (elementSelected)="setStandort($event)">
        </kiene-autocomplete>

        <mat-form-field *ngIf="client?.kaelbermast" style="width: 244px;">
            <mat-label>Aufstalleinheit</mat-label>
            <div class="one-row">
                <input
                    style="width: 40px; margin-right: 12px; border: 1px solid lightgray; padding: 4px; border-radius: 4px;"
                    matInput type="number" min="1" max="99" maxlength="2" [formControl]="aufstalleinheitJahrCtrl">
                <span>- AE -</span>
                <input
                    style="width: 60px; margin-right: 12px; border: 1px solid lightgray; padding: 4px; border-radius: 4px;"
                    matInput type="number" [formControl]="aufstalleinheitNummerCtrl">
            </div>
        </mat-form-field>

        <!-- <fieldset *ngIf="client?.kaelbermast"
            style="max-width: 200px; border: 1px solid lightgray; border-radius: 5px;">
            <legend style="color: gray;">Aufstalleinheit</legend>
            <div class="one-row">
                <input matInput type="number" min="1" max="99" maxlength="2" [formControl]="aufstalleinheitJahrCtrl">

                <span>- AE -</span>
                <input matInput type="number" [formControl]="aufstalleinheitNummerCtrl">

            </div>
        </fieldset> -->

        <span class="spacer"></span>
    </div>

    <br>
    <!--<mat-form-field style="width: 200px;">
        <mat-label>Lieferanten VVVO</mat-label>
        <input matInput type="number" maxlength="15" [formControl]="lieferantenVVVOCtrl">
    </mat-form-field>
    <br>-->
    <div *ngIf="!lss?.kostenpflichtigAktiv()" class="one-row">

        <mat-form-field style="margin-right: 12px;">
            <mat-label>Einstalldatum von</mat-label>
            <input matInput [matDatepicker]="einstalldatum_von" [formControl]="einstalldatumVonCtrl" [readonly]="true">
            <mat-datepicker-toggle matSuffix [for]="einstalldatum_von"></mat-datepicker-toggle>
            <mat-datepicker #einstalldatum_von></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Einstalldatum bis</mat-label>
            <input matInput [matDatepicker]="einstalldatum_bis" [formControl]="einstalldatumBisCtrl" [readonly]="true">
            <mat-datepicker-toggle matSuffix [for]="einstalldatum_bis"></mat-datepicker-toggle>
            <mat-datepicker #einstalldatum_bis></mat-datepicker>
        </mat-form-field>
        <span class="spacer"></span>
    </div>

    <div *ngIf="!client?.tierbewegung_melden || ( client?.tierbewegung_melden === 1 && ( !client?.ohrmarke || (client?.ohrmarke && anzahlTiereOhrmarke < 1) ) )"
        class="one-row">
        <mat-form-field style="width: 244px; margin-right: 12px;">
            <mat-label>Anzahl Tiere</mat-label>
            <input matInput type="number" maxlength="15" [formControl]="anzahlTiereCtrl">
        </mat-form-field>

        <mat-form-field style="width: 244px;">
            <mat-label>Alter bei Einstallung (Tage)</mat-label>
            <input matInput type="number" maxlength="15" [formControl]="alterEinstallungCtrl">
        </mat-form-field>
        <span class="spacer"></span>
    </div>


</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</div>