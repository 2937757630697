<mat-form-field style="min-width: 30%; width: 100%;">
    <mat-label>Kunde / Gruppe</mat-label>
    <input #inputGruppeAutocomplete="matInput" matInput [matAutocomplete]="autoGruppe" [formControl]="formControl"
           placeholder="Kunde/Gruppe">
    <mat-autocomplete #autoGruppe="matAutocomplete" [displayWith]="gruppeMapper"
                      (optionSelected)="emitSelectedGroup($event)" panelWidth="400px">
        <mat-option *ngFor="let g of gruppen | async" [value]="g" style="height: 100px; border-bottom: 1px solid #CCC;">
            <div style="width: 100%; border-bottom: 1px solid #CCC; padding: 5px;">
                <span style="padding: 5px; font-weight: bold;">{{g.kunde ? g.kunde : ''}}</span>
                <span style="font-size: 14px;padding: 5px;">{{g.gruppe_nr_intern ? g.kunde_nr_intern :
                    g.gruppe_nr_intern}}</span>
                <br>
                <span style="font-size: 14px;padding: 5px;">{{g.bezeichnung}} - {{g.zusatz}} -
                    {{g.unternehmensgruppe}}</span>
            </div>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
