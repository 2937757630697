import {HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';
import {environment} from '../../../../environments/environment';
import {AppService} from '../../../app.service';
import {AnwendungsSelectorComponent} from '../../../components/anwendungs-selector/anwendungs-selector.component';
import {
    ArticleScanAutocompleteComponent,
    ArticleScanSelectedEvent,
} from '../../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import {
    DiagnoseSelectorComponent
} from '../../../components/diagnose-selector/diagnose-selector/diagnose-selector.component';
import {EntityViewerService} from '../../../components/entity-viewer/entity-viewer.service';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {ArtikelTierart, ArtikelTierartEvent,} from '../../../components/tierart-selector/artikel-tierart';
import {TierartSelectorComponent,} from '../../../components/tierart-selector/tierart-selector.component';
import {ConfirmDeleteDialogComponent} from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {SaveForAllDialogComponent} from '../../../dialogs/save-for-all-dialog/save-for-all-dialog.component';
import {Anwendung, AnwendungEvent,} from '../../../documents/behandlungsanfrage/anwendung';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MessageService} from '../../../services/message.service';
import {Indikation, IndikationEvent} from '../artikel-indikation/indikation';
import {ArtikelStueckliste, Material} from '../material';
import {Mandant} from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'kiene-stueckliste',
    templateUrl: './stueckliste.component.html',
    styleUrls: ['./stueckliste.component.css'],
})
export class StuecklisteComponent implements OnInit {
    @ViewChild('articleAutocomplete')
    articleAutocomplete: ArticleScanAutocompleteComponent;
    @ViewChild('behandlungSelector') behandlungSelector: MatSelect;
    @ViewChild('tierartSelector') tierartSelector: TierartSelectorComponent;
    @ViewChild('anwendungSelector')
    anwendungSelector: AnwendungsSelectorComponent;
    @ViewChild('diagnoseSelector') diagnoseSelector: DiagnoseSelectorComponent;

    @Input('material') material: Material;
    @Output('reload') reload = new EventEmitter<number>();
    // availableMandanten: Mandant[] = [];

    artikelliste: ArtikelStueckliste[] = [];
    currentPosition: ArtikelStueckliste;
    selectedMaterial: Material;
    selectedArtikelTierart: ArtikelTierart;
    selectedAnwendung: Anwendung;
    selectedDiagnose: Indikation;

    einkaufspreis = 0;

    anwendungsdauerCtrl = new UntypedFormControl();
    mengeCtrl = new UntypedFormControl();
    dosierungCtrl = new UntypedFormControl();
    behandlungJaNeinCtrl = new UntypedFormControl();
    s;

    constructor(
        private api: KieneTableService,
        private notification: MessageService,
        private appService: AppService,
        public dialog: MatDialog,
        private localStorageService: LocalStorageService,
        private entityViewerService: EntityViewerService
    ) {
    }

    ngOnInit(): void {
        this.loadArtikeliste();
    }

    loadArtikeliste() {
        this.appService.setDataLoading(true);
        const params = new HttpParams().append(
            'artikel_id_liste',
            this.material?.artikel_id.toString()
        );
        this.api
            .get(environment.BASE_URI + 'artikel_stueckliste/read.php', params)
            .subscribe(
                (response) => {
                    this.artikelliste = response.records;
                    this.entityViewerService.setEntity(this.artikelliste);
                    this.berechneEinkaufspreis();
                    this.appService.setDataLoading(false);
                    this.reload.emit(this.material.artikel_id);
                },
                (error) => {
                    this.appService.setDataLoading(false);
                }
            );
    }

    berechneEinkaufspreis() {
        this.einkaufspreis = 0;
        for (const as of this.artikelliste) {
            this.einkaufspreis +=
                as.einzelpreis * as.artikelmenge * (1 - as.rabatt / 100);
        }
    }

    setSelectedMaterial(asse: ArticleScanSelectedEvent) {
        this.selectedMaterial = asse.article;
        if (!this.selectedMaterial) {
            return;
        }
        this.tierartSelector.ladeTierartenFuerMaterialUndKunde(
            'stueckliste.component.ts setSelectedMaterial',
            this.selectedMaterial.artikel_id,
            true,
            false
        );
    }

    setSelectedTierart(event: ArtikelTierartEvent) {
        this.selectedArtikelTierart = event.artikelTierart;
        if (!this.selectedArtikelTierart) {
            return;
        }
        this.anwendungsdauerCtrl.setValue(
            this.selectedArtikelTierart.behandlung_tage
        );
        this.dosierungCtrl.setValue(this.selectedArtikelTierart.menge);
        this.anwendungSelector.loadAnwendungen(
            this.selectedArtikelTierart.artikel_id,
            this.selectedArtikelTierart.tierart_id,
            true,
            false
        );
    }

    setAnwendung(anwendungEvent: AnwendungEvent) {
        this.selectedAnwendung = anwendungEvent.anwendung;
        if (!this.selectedAnwendung) {
            return;
        }
        this.diagnoseSelector.loadDiagnosen(
            this.selectedAnwendung.artikel_id,
            this.selectedArtikelTierart.tierart_id,
            this.selectedAnwendung.anwendung_id,
            true,
            false
        );
    }

    setDiagnose(event: IndikationEvent) {
        this.selectedDiagnose = event.indikation;
    }

    clear() {
        this.currentPosition = null;
        this.articleAutocomplete.clear();
        this.tierartSelector.clear();
        this.anwendungSelector.clear();
        this.diagnoseSelector.clear();
        this.anwendungsdauerCtrl.setValue(null, {emitEvent: false});
        this.dosierungCtrl.setValue(null, {emitEvent: false});
        this.mengeCtrl.setValue(null, {emitEvent: false});
        this.behandlungJaNeinCtrl.setValue(null, {emitEvent: false});
        this.articleAutocomplete.setFocus();
    }

    addPosition() {
        if (!this.currentPosition) {
            this.currentPosition = new ArtikelStueckliste();
        }
        this.currentPosition.artikel_id_liste = this.material.artikel_id;
        this.currentPosition.artikel_id_pos = this.selectedMaterial.artikel_id;
        this.currentPosition.dosierung = this.dosierungCtrl.value; // trotz mengenartikel
        this.currentPosition.anwendungsdauer = this.anwendungsdauerCtrl.value;
        if (this.selectedMaterial.mengenartikel === 0) {
            this.currentPosition.tierart_id =
                this.selectedArtikelTierart.tierart_id;
            this.currentPosition.anwendung_id =
                this.selectedAnwendung.anwendung_id;
            this.currentPosition.indikation_id =
                this.selectedDiagnose.indikation_id;
            this.currentPosition.wartezeit =
                this.selectedAnwendung.wartezeit_fleisch;
            this.currentPosition.behandlung = this.behandlungJaNeinCtrl.value;
        }
        this.currentPosition.artikelmenge = this.mengeCtrl.value;

        // (this.localStorageService.getCurrentMandant().mandant_id === 1) &&
        if (this.localStorageService.currentUserHasPermission(4405)) {
            const params = new HttpParams()
                .append(
                    'artikel_id_liste',
                    this.currentPosition.artikel_id_liste.toString()
                )
                .append(
                    'artikel_id_pos',
                    this.currentPosition.artikel_id_pos.toString()
                );
            this.api
                .get(
                    environment.BASE_URI +
                    'artikel_stueckliste/read_mandanten.php',
                    params
                )
                .subscribe((responseMandanten) => {
                    const availableMandanten: Mandant[] =
                        responseMandanten.records;

                    if (availableMandanten.length > 1) {
                        const dialogRef = this.dialog.open(
                            SaveForAllDialogComponent,
                            {
                                data: {
                                    availableMandanten: availableMandanten,
                                    buttonText: 'Speichern',
                                },
                            }
                        );

                        dialogRef.afterClosed().subscribe((result) => {
                            if (result) {
                                this.currentPosition.mandanten_ids = result;
                                this.createOrUpdateArtikelStueckliste(
                                    this.currentPosition
                                );
                            }
                        });
                    } else {
                        this.createOrUpdateArtikelStueckliste(
                            this.currentPosition
                        );
                    }
                });
        } else {
            this.createOrUpdateArtikelStueckliste(this.currentPosition);
        }
    }

    removePosition(as: ArtikelStueckliste) {
        const confirmDialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            data: {
                title: 'Wirklich löschen?',
                text:
                    'Wollen Sie die Position ' +
                    as.artikel_pos +
                    ' wirklich löschen?',
            },
        });

        confirmDialogRef.afterClosed().subscribe((resultConfirm) => {
            if (resultConfirm) {
                // (this.localStorageService.getCurrentMandant().mandant_id === 1) &&
                if (this.localStorageService.currentUserHasPermission(4405)) {
                    const params = new HttpParams().append(
                        'as_id',
                        as.as_id.toString()
                    );
                    this.api
                        .get(
                            environment.BASE_URI +
                            'artikel_stueckliste/read_mandanten_delete.php',
                            params
                        )
                        .subscribe((responseMandanten) => {
                            const availableMandanten: Mandant[] =
                                responseMandanten.records;

                            if (availableMandanten.length > 1) {
                                const dialogRef = this.dialog.open(
                                    SaveForAllDialogComponent,
                                    {
                                        data: {
                                            availableMandanten:
                                            availableMandanten,
                                            buttonText: 'Löschen',
                                        },
                                    }
                                );

                                dialogRef.afterClosed().subscribe((result) => {
                                    if (result) {
                                        as.mandanten_ids = result;
                                        this.removeArtikelStueckliste(as);
                                    }
                                });
                            } else {
                                this.removeArtikelStueckliste(as);
                            }
                        });
                } else {
                    this.removeArtikelStueckliste(as);
                }
            }
        });
    }

    editPosition(as: ArtikelStueckliste) {
        this.currentPosition = as;
        this.loadCurrentPositionForEditing();
    }

    loadCurrentPositionForEditing() {
        const m = new Material();
        m.artikel_id = this.currentPosition.artikel_id_pos;
        m.artikel_me = this.currentPosition.artikel_pos;
        m.bezeichnung = this.currentPosition.artikel_pos;
        m.abgabe = this.currentPosition.abgabe_artikel;
        m.behandlung = this.currentPosition.behandlung_artikel;
        m.mengenartikel = this.currentPosition.mengenartikel;

        this.selectedMaterial = m;

        this.articleAutocomplete.setMaterial(m, false);

        const at = new ArtikelTierart();
        at.tierart_id = this.currentPosition.tierart_id;
        at.tierart = this.currentPosition.tierart;
        at.artikel_id = this.currentPosition.artikel_id_pos;
        at.artikel = this.currentPosition.artikel_pos;

        this.selectedArtikelTierart = at;

        this.tierartSelector.setTierart(at, false, false);

        const a = new Anwendung();
        a.anwendung = this.currentPosition.anwendung;
        a.anwendung_id = this.currentPosition.anwendung_id;
        a.artikel_id = this.currentPosition.artikel_id_pos;
        a.artikel = this.currentPosition.artikel_pos;
        a.wartezeit_fleisch = this.currentPosition.wartezeit;

        this.selectedAnwendung = a;

        this.anwendungSelector.setAnwendung(a, at.tierart_id, false, false);

        const d = new Indikation();
        d.indikation_id = this.currentPosition.indikation_id;
        d.diagnose = this.currentPosition.diagnose;
        d.artikel_id = this.currentPosition.artikel_id_pos;
        d.tierart_id = this.currentPosition.tierart_id;

        this.selectedDiagnose = d;

        this.diagnoseSelector.setDiagnose(d, a.anwendung_id, false, false);

        this.behandlungJaNeinCtrl.setValue(this.currentPosition.behandlung);

        this.anwendungsdauerCtrl.setValue(this.currentPosition.anwendungsdauer);

        this.dosierungCtrl.setValue(this.currentPosition.dosierung);

        this.mengeCtrl.setValue(this.currentPosition.artikelmenge);

        this.articleAutocomplete.setFocus();
    }

    createOrUpdateArtikelStueckliste(as: ArtikelStueckliste) {
        this.api
            .updateEntity(environment.BASE_URI + 'artikel_stueckliste/update.php', as)
            .subscribe(
                (response) => {
                    this.notification.infoMessage(
                        'Die Position wurde erfolgreich gespeichert!'
                    );
                    this.loadArtikeliste();
                    this.clear();
                },
                (error) => {
                    this.notification.errorMessage(
                        'Die Position konnte nicht gespeichert werden!',
                        error
                    );
                }
            );
    }

    removeArtikelStueckliste(a: ArtikelStueckliste) {
        this.api
            .deleteEntity(environment.BASE_URI + 'artikel_stueckliste/delete.php', a)
            .subscribe(
                (response) => {
                    this.loadArtikeliste();
                },
                (error) => {
                    this.notification.errorMessage(
                        'Die Position konnte nicht entfernt werden!',
                        error
                    );
                }
            );
    }

    hasPermissionForPrices() {
        return this.localStorageService.currentUserHasPermissions([3201]);
    }
}
