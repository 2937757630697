import { HttpParams } from '@angular/common/http';
import { Component, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AppService } from '../../../../app.service';
import {
    FilterItem,
    FilterItemType
} from '../../../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import { KieneTableService } from '../../../../components/kiene-table/kiene-table.service';
import { DateTableColumn, NumberTableColumn, TableColumn } from '../../../../components/kiene-table/table-column';
import { TableDescriptor } from '../../../../components/kiene-table/table-descriptor';
import { Condition, Operator, TableOption, TableOptionEvent } from '../../../../components/kiene-table/table-option';
import { ConfirmDeleteDialogComponent } from '../../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {
    LocalStorageService
} from '../../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { MessageService } from '../../../../services/message.service';
import { GruppeDetailsDialogComponent } from '../../gruppe-details-dialog/gruppe-details-dialog.component';
import { GruppeDialogComponent } from '../gruppe-dialog/gruppe-dialog.component';
import { GruppeEditDialogComponent } from '../gruppe-edit-dialog/gruppe-edit-dialog.component';
import { GruppenService } from '../../../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import { Gruppe } from '../../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import { Client } from '../../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { GruppeDialogInput } from '../gruppe-dialog/gruppe-dialog';
import {
    ChangeCurrentClientDialogComponent
} from '../../../../administration/client/change-current-client/change-current-client-dialog/change-current-client-dialog.component';
import {
    ConfirmDialogComponent
} from '../../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput
} from '../../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';

@Component({
    selector: 'kiene-gruppen-aktuelle',
    templateUrl: './gruppen-aktuelle.component.html',
    styleUrls: ['./gruppen-aktuelle.component.css'],
})
export class GruppenAktuelleComponent implements OnInit, OnChanges {
    tableDescriptor: TableDescriptor = new TableDescriptor();
    currentClient: Client;
    watchClientSubscription: Subscription;

    clientCtrl = new UntypedFormControl();
    companyGroupClients: Client[] = [];

    constructor(
        public dialog: MatDialog,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private api: KieneTableService,
        private appService: AppService,
        private gruppenService: GruppenService
    ) {
    }

    ngOnInit() {
        this.currentClient = this.localStorageService.getCurrentClient();
        this.clientCtrl.disable();
        this.ladeKundenZuUnternehmensgruppe();

        this.watchClientSubscription = this.localStorageService
            .watchCurrentClient()
            .subscribe((response) => {
                if (response) {
                    this.currentClient = response;
                    this.ladeKundenZuUnternehmensgruppe();
                    // this.tableDescriptor.params = new HttpParams().set('kunde_id', kundeId);
                    this.tableService.reload();
                } else {
                    this.currentClient = null;
                    this.tableDescriptor.params = null;
                    this.tableService.reload();
                    this.ladeKundenZuUnternehmensgruppe();
                }
            });

        this.tableDescriptor.headline = 'Gruppen';
        this.tableDescriptor.uniqueIdentifier = 'Gruppen';
        this.tableDescriptor.alternativeApiUrl =
            environment.BASE_URI + 'gruppe/read_akt.php';
        this.tableDescriptor.route = '/belege/gruppe/';
        this.tableDescriptor.nameOfIdField = 'gruppe_id';
        this.tableDescriptor.searchPlaceholder = 'Gruppen durchsuchen';

        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.createAllowed = true;

        this.tableDescriptor.showDetails = false;
        this.tableDescriptor.showDetailsAsOption = false;

        this.tableDescriptor.columns.push(new TableColumn('gruppe_id', 'ID'));
        this.tableDescriptor.columns.push(new TableColumn('kunde', 'Betrieb'));
        this.tableDescriptor.columns.push(new TableColumn('zusatz', 'Zusatz'));

        this.tableDescriptor.columns.push(
            new TableColumn('bezeichnung', 'Bezeichnung')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('produktionsabschnitt', 'Abschnitt')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('herdenname', 'Herdenname')
        );
        this.tableDescriptor.columns.push(new TableColumn('stall', 'Standort'));
        this.tableDescriptor.columns.push(
            new DateTableColumn(
                'einstalldatum_von',
                'Einstalldatum von',
                'dd.MM.yy'
            )
        );
        this.tableDescriptor.columns.push(
            new DateTableColumn(
                'einstalldatum_bis',
                'Einstalldatum bis',
                'dd.MM.yy'
            )
        );
        this.tableDescriptor.columns.push(
            new TableColumn('alter_wochen', 'Tieralter in Wochen')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('anzahl_tiere_gesamt', 'Eingestallte Tiere')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('anzahl_abgaenge', 'Abgänge')
        );
        this.tableDescriptor.columns.push(
            new NumberTableColumn('verluste', 'Verlust %', '1.1-1', '%')
        );
        this.tableDescriptor.columns.push(
            new NumberTableColumn('anzahl_tote', 'Tote', '1.0-0')
        );
        // this.tableDescriptor.columns.push(
        //     new TableColumn("verkaeufe", "Verkauft")
        // );
        this.tableDescriptor.columns.push(
            new TableColumn('anzahl_tiere_aktuell', 'Anzahl Tiere')
        );
        this.tableDescriptor.columns.push(
            new DateTableColumn(
                'synchronisiert',
                'HIT Sync.',
                'dd.MM.yy HH:mm',
                { alternativeField: 'hit_fehler' }
            )
        );

        this.tableDescriptor.options.push(
            new TableOption('details', 'Tiere verwalten', 'settings', 1912, {
                conditions: [
                    new Condition('ohrmarke', Operator.EXISTIERT, null),
                ]
            })
        );

        this.tableDescriptor.options.push(
            new TableOption('edit', 'Gruppe editieren', 'edit', 1902)
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'hit',
                'Tiere mit HIT synchronisieren',
                'cloud_sync',
                1902
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'historie',
                'Gruppe in Historie verschieben',
                'history_edu',
                9114,
                { conditions: [new Condition('standard', Operator.GLEICH, '0')] }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption('loeschen', 'Gruppe löschen', 'delete', 1904)
        );

        this.tableDescriptor.permissionView = 1901;
        this.tableDescriptor.permissionCreate = 1902;
        this.tableDescriptor.permissionUpdate = 1902;
        this.tableDescriptor.permissionDelete = 1904;

        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Gruppe \'Standard\' ausblenden',
                0,
                FilterItemType.CHECKBOX,
                'standardgruppe',
                false
            )
        );

        this.tableDescriptor.initialSortColumn = 'einstalldatum_von';
        this.tableDescriptor.initialSortDirection = 'desc';
    }

    ngOnChanges() {
        if (this.currentClient && this.currentClient.kunde_id) {
            this.clientCtrl.enable();
            this.tableDescriptor.params = new HttpParams();
            this.tableDescriptor.params = this.tableDescriptor.params.append(
                'kunde_id[]',
                this.currentClient.kunde_id.toString()
            );
            this.tableService.reload();
            this.ladeKundenZuUnternehmensgruppe();
        }
    }

    reload() {
        this.api.reload();
    }

    ladeKundenZuUnternehmensgruppe() {
        this.companyGroupClients = [];
        if (!this.currentClient) {
            this.clientCtrl.setValue([]);
            this.clientCtrl.disable();
            return;
        } else {
            this.clientCtrl.enable();
        }
        const ugId = this.currentClient.unternehmensgruppe_id;
        if (!ugId) {
            return;
        }
        const params = new HttpParams().set(
            'unternehmensgruppe_id',
            ugId.toString()
        );
        this.api.get(environment.BASE_URI + 'kunde/read.php', params).subscribe({
            next: (response) => {
                this.companyGroupClients = response.records;
                const index = this.companyGroupClients
                    .map((e) => {
                        return e.kunde_id;
                    })
                    .indexOf(this.currentClient.kunde_id);
                this.companyGroupClients.splice(index, 1);
                this.clientCtrl.enable();
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Es konnten keine Kunden zur Unternehmensgruppe geladen werden!',
                    err
                );
            },
        });
    }

    clientComparator(c1: Client, c2: Client): boolean {
        if (c1 && c2) {
            return c1.kunde_id === c2.kunde_id;
        }
        return false;
    }

    setSelectedClients(event: MatSelectChange) {
        const clients = <Client[]>event.value;
        if (this.currentClient) {
            this.tableDescriptor.params = new HttpParams();
            this.tableDescriptor.params = this.tableDescriptor.params.append(
                'kunde_id[]',
                this.currentClient.kunde_id.toString()
            );
            for (const c of clients) {
                if (c.kunde_id !== this.currentClient.kunde_id) {
                    this.tableDescriptor.params =
                        this.tableDescriptor.params.append(
                            'kunde_id[]',
                            c.kunde_id.toString()
                        );
                }
            }
            this.tableService.reload();
        }
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            this.openCreateGruppeDialog();
        } else if (event.name === 'details') {
            this.openGruppeDetailsDialog(event.value);
        } else if (event.name === 'edit') {
            this.openEditGruppeDialog(event.value);
        } else if (event.name === 'hit') {
            this.tiereAusHitNachladen(event.value);
        } else if (event.name === 'historie') {
            this.inHistorieVerschieben(event.value);
        } else if (event.name === 'loeschen') {
            this.loescheGruppe(event.value);
        }
    }

    loescheGruppe(gruppe: Gruppe) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Gruppe wirklich löschen?',
                text: 'Gruppe wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.api
                    .deleteEntity(environment.BASE_URI + 'gruppe/delete.php', gruppe)
                    .subscribe(
                        () => {
                            this.messageService.infoMessage(
                                'Gruppe erfolgreich gelöscht!'
                            );
                            this.api.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Gruppe konnte nicht gelöscht werden!',
                                error
                            );
                        }
                    );
            }
        });
    }

    inHistorieVerschieben(gruppe: Gruppe) {
        const input = new ConfirmDialogInput();
        input.headline = 'Gruppe in die Historie verschieben?';
        input.text = `Möchten Sie die Gruppe ${gruppe?.bezeichnung} wirklich in die Historie schieben?`;
        input.okButton = 'Verschieben';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: input
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.gruppenService.historieAendern(gruppe, true).subscribe({
                    next: () => {
                        this.messageService.infoMessage(
                            'Gruppe erfolgreich in Historie verschoben!'
                        );
                        this.api.reload();
                    },
                    error: err => {
                        this.messageService.errorMessage(
                            'Gruppe konnte nicht in die Historie verschoben werden!',
                            err
                        );
                    }
                });
            }
        });
    }

    tiereAusHitNachladen(gruppe: Gruppe) {
        this.appService.setDataLoading(true);
        this.messageService.infoMessage('Tiere werden aus HIT geladen!');
        this.gruppenService.tiereAusHitNachladen(gruppe).subscribe({
            next: () => {
                this.messageService.infoMessage(
                    'Tiere erfolgreich aus HIT geladen!'
                );
                this.appService.setDataLoading(false);
                this.api.reload();
            },
            error: (error) => {
                this.messageService.errorMessage(
                    'Tiere konnten nicht aus HIT nachgeladen werden!',
                    error
                );
                this.appService.setDataLoading(false);
            },
        });
    }

    openEditGruppeDialog(gruppe: Gruppe) {
        const dialogRef = this.dialog.open(GruppeEditDialogComponent, {
            minWidth: '500px',
            width: '50%',
            data: {
                gruppe: gruppe,
            },
        });
        dialogRef.afterClosed().subscribe((gruppe1) => {
            if (gruppe1) {
                this.tableService.reload();
            }
        });
    }

    openCreateGruppeDialog() {
        if (this.localStorageService.getCurrentClient()) {
            const input = new GruppeDialogInput(this.localStorageService.getCurrentClient(), false, undefined);
            this.dialog.open<GruppeDialogComponent, GruppeDialogInput, Gruppe>(GruppeDialogComponent, {
                minWidth: '500px',
                width: '50%',
                data: input
            }).afterClosed().subscribe(() => {
                this.tableService.reload();
            });
        } else {
            const dialogRef = this.dialog.open(
                ChangeCurrentClientDialogComponent,
                {
                    width: '50%',
                    data: {
                        closeOnSelect: true,
                    },
                }
            );
            dialogRef.afterClosed().subscribe((result) => {
                if (result?.save && result?.client) {
                    this.localStorageService.setCurrentClient(result.client);
                    this.openCreateGruppeDialog();
                }
            });
        }
    }

    openGruppeDetailsDialog(gruppe: Gruppe) {
        const dialogRef = this.dialog.open(GruppeDetailsDialogComponent, {
            width: '90%',
            data: {
                gruppe: gruppe,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.tableService.reload();
        });
    }

    hello(text: String) {
        this.tableDescriptor.headline += text;
    }
}
