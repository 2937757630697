import {Component, Inject, OnInit} from '@angular/core';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MessageService} from '../../services/message.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {TableColumn} from '../../components/kiene-table/table-column';
import {TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {EntsorgerDetailsDialogComponent} from './entsorger-details-dialog/entsorger-details-dialog.component';

@Component({
    selector: 'kiene-entsorger',
    templateUrl: './entsorger.component.html',
    styleUrls: ['./entsorger.component.scss']
})
export class EntsorgerComponent implements OnInit {

    tableDescriptor: TableDescriptor = new TableDescriptor();


    constructor(public dialog: MatDialog,
                private router: Router,
                private messageService: MessageService,
                private localStorageService: LocalStorageService,
                private tableService: KieneTableService,
                private api: KieneTableService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    ngOnInit() {

        this.tableDescriptor.initialSortColumn = 'bezeichnung';
        this.tableDescriptor.initialSortDirection = 'asc';

        this.tableDescriptor.headline = 'Entsorger';
        this.tableDescriptor.uniqueIdentifier = 'Entsorger';
        this.tableDescriptor.alternativeApiUrl = this.apiBaseUrl + 'entsorger/read_kundenportal.php';
        this.tableDescriptor.route = '/stammdaten/entsorger/';
        this.tableDescriptor.nameOfIdField = 'entsorger_id';
        this.tableDescriptor.searchPlaceholder = 'Entsorger durchsuchen';

        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.showDetailsAsOption = true;


        this.tableDescriptor.columns.push(new TableColumn('entsorger_id', 'ID'));
        this.tableDescriptor.columns.push(new TableColumn('name', 'Name'));
        this.tableDescriptor.columns.push(new TableColumn('vvvo', 'VVVO'));
        this.tableDescriptor.columns.push(new TableColumn('strasse', 'Strasse'));
        this.tableDescriptor.columns.push(new TableColumn('plz', 'PLZ'));
        this.tableDescriptor.columns.push(new TableColumn('ort', 'Ort'));
        this.tableDescriptor.columns.push(new TableColumn('telefon', 'Festnetz'));
        this.tableDescriptor.columns.push(new TableColumn('mobil', 'Mobil'));
        this.tableDescriptor.columns.push(new TableColumn('email', 'E-Mail'));
        this.tableDescriptor.columns.push(new TableColumn('fax', 'Fax'));
        this.tableDescriptor.columns.push(new TableColumn('editiert', 'Editiert', 'datetime'));


        this.tableDescriptor.permissionView = 9149;
        this.tableDescriptor.permissionCreate = 9142;
        this.tableDescriptor.permissionUpdate = 9143;
        this.tableDescriptor.permissionUpdate = 9144;

        this.tableDescriptor.options.push(new TableOption('edit', 'editieren', 'edit', 9143));


        this.tableDescriptor.initialSortColumn = 'entsorger_id';
        this.tableDescriptor.initialSortDirection = 'asc';

    }


    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'edit' || event.name === 'details') {
            this.openEditDialog(event);
        } else if (event.name === 'add') {
            this.openEditDialog();
        }
    }

    openEditDialog(event?: TableOptionEvent) {
        const dialogRef = this.dialog.open(EntsorgerDetailsDialogComponent, {
            data: {
                entsorger: event?.value
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.tableService.reload();
        });
    }

}
