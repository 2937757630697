import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogInput, ConfirmDialogResult } from './confirm-dialog';

@Component({
    selector: 'kiene-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogInput
    ) {}

    ngOnInit() {}

    cancel() {
        const result = new ConfirmDialogResult();
        result.ok = false;
        this.dialogRef.close(result);
    }

    ok() {
        const result = new ConfirmDialogResult();
        result.ok = true;
        result.confirmed = true;
        this.dialogRef.close(result);
    }
}
