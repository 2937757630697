<h1 mat-dialog-title>Gruppeninformationen</h1>


<div mat-dialog-content>

    <div style="display: flex; flex-direction: column; margin-bottom: 6px">
        <strong>{{gruppe?.kunde}}</strong>
        <mat-hint class="one-row">
            <span>{{gruppe?.bezeichnung}}</span>
            <span class="spacer"></span>
            <span>{{gruppe?.stall}}</span>
        </mat-hint>
    </div>

    <mat-form-field
        style="width: 100%">
        <mat-label>Gruppeninformationen</mat-label>
        <textarea
            [formControl]="informationenCtrl"
            matInput
            rows="8"
        ></textarea>
    </mat-form-field>
</div>

<div mat-dialog-actions>
    <button (click)="updateKommentar()" [disabled]="!speicherbar" color="accent" mat-flat-button>Speichern</button>
    <button (click)="dialogRef.close()" mat-flat-button>Abbrechen</button>
</div>
