<div>
    <mat-form-field *ngIf="!hideSuche" class="no-padding-margin-mat-form-field" style="width: 100%">
        <mat-label>Suchen</mat-label>
        <mat-icon matPrefix style="padding-right: 6px">search</mat-icon>
        <input [formControl]="searchCtrl" matInput />
    </mat-form-field>

    <mat-divider style="margin-bottom: 12px"></mat-divider>

    <div class="one-row">
        <span>Tiere: <strong>{{ gruppeTiere?.length }}</strong></span>
        <span class="spacer"></span>

        <button (click)="reloadEvent()" *ngIf="!hideReloadButton" color="accent" mat-icon-button>
            <mat-icon>refresh</mat-icon>
        </button>

        <button (click)="openHilfe()" *ngIf="!hideHilfeButton" color="accent" mat-icon-button>
            <mat-icon>help</mat-icon>
        </button>

        <kiene-gruppe-tier-sortierung-button (selectedFilter)="sortiereTiere($event)"
            *ngIf="!hideSortierung"></kiene-gruppe-tier-sortierung-button>

        <kiene-gruppe-tier-filter-button #filterButton (filterSelected)="setFilterSettings($event)" *ngIf="!hideFilter"
            [gruppe]="gruppe" [tierFilterSettings]="filterSettings">
        </kiene-gruppe-tier-filter-button>

        <ng-content select="[additional-actions]"></ng-content>
    </div>


    <div *ngIf="!gruppeTiere || gruppeTiere?.length === 0">

        <div class="hinweis-text" style="padding: 24px 0 64px 0; text-align: center">

            <span *ngIf="isLoading">Lade Tiere...</span>

            <div *ngIf="!isLoading && gruppeTiere.length === 0">

                <span *ngIf="selectedTierFilter === tierFilter.ALLE_TIERE &&
                             !searchCtrl.value && selectedAbteil">
                    In der Gruppe sind keine Tiere vorhanden.
                </span>

                <span *ngIf="searchCtrl.value || validFilterSettings(filterSettings)">
                    Zu den angewandten Suchkriterien sind keine Tiere vorhanden.
                </span>

            </div>
        </div>
    </div>

    <div *ngIf="gruppeTiere && gruppeTiere?.length > 0">
        <ng-container *ngFor="let tier of gruppeTiere">
            <div style="margin-bottom: 12px;">
                <kiene-gruppe-tier-card (reload)="reloadEvent()" [hideGruppe]="true" [tier]="tier">
                </kiene-gruppe-tier-card>
            </div>
        </ng-container>
    </div>
</div>