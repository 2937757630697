import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { BestellungExtern, BestellungExternDokument, } from '../../bestellung-extern/bestellung-extern';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { ConfirmDeleteDialogComponent } from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { MessageService } from '../../../services/message.service';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UploadInvoiceDialogComponent } from '../upload-invoice-dialog/upload-invoice-dialog.component';
import {
    InvoiceDeliverNoteDialogComponent
} from '../../bestellung-extern/invoice-deliver-note-dialog/invoice-deliver-note-dialog.component';
import { FileUploadService } from '../../../dialogs/file-upload-dialog/file-upload.service';
import { DatePipe } from '@angular/common';
import { AddCommentDialogComponent } from '../add-comment-dialog/add-comment-dialog.component';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { AddGutschriftDialogComponent } from '../../gutschriften/add-gutschrift-dialog/add-gutschrift-dialog.component';
import { Gutschrift } from '../../gutschriften/gutschrift';
import { ShowGutschriftenDialogComponent } from '../show-gutschriften-dialog/show-gutschriften-dialog.component';
import { SupplierMaterialService } from '../../../warehouse/supplier-material/supplier-material.service';
import { AppService } from '../../../app.service';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    RechnungBestellpositionCheckDialogComponent
} from '../rechnung-bestellposition-check-dialog/rechnung-bestellposition-check-dialog.component';
import { BestellungExternService } from '../../bestellung-extern/bestellung-extern.service';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'kiene-bestellung-extern-list',
    templateUrl: './bestellung-extern-list.component.html',
    styleUrls: ['./bestellung-extern-list.component.scss'],
})
export class BestellungExternListComponent implements OnInit {
    @ViewChild('topPaginator') topPaginator: MatPaginator;
    @ViewChild('bottomPaginator') bottomPaginator: MatPaginator;

    offset = 0;
    limit = 10;
    totalLength = 100;

    currentView = 'single';

    bestellungenExtern: BestellungExtern[] = [];

    bestellungRechnungArray: number[] = [];

    searchValue = '';
    searchCtrl = new UntypedFormControl();

    withoutInvoiceCtrl = new UntypedFormControl();
    invoiceModeActive = false;
    selectedLieferantId = -1;
    teilweiseGeliefertFilter = false;

    searchCtrlSubscription: Subscription;
    loadBestellungSub: Subscription;

    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private session: LocalStorageService,
        private pdf: PdfViewerDesktopService,
        public dialog: MatDialog,
        private messageService: MessageService,
        private uploadService: FileUploadService,
        private http: HttpClient,
        private datePipe: DatePipe,
        private router: Router,
        private appService: AppService,
        private bestellungExternService: BestellungExternService
    ) {
    }

    ngOnInit() {
        this.limit = this.session.getCurrentUser().elemente_pro_seite;

        this.searchCtrlSubscription = this.searchCtrl.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe((value) => {
                this.searchValue = value;
                this.loadBestellungenExtern();
            });

        this.withoutInvoiceCtrl.valueChanges.subscribe((value) => {
            if (value) {
                this.invoiceModeActive = true;
            } else {
                this.invoiceModeActive = false;
            }
            this.loadBestellungenExtern();
        });

        this.loadBestellungenExtern();
    }

    getFormattedDate(d: Date): string {
        let formattedDate = '';

        if (!d) {
            return formattedDate;
        }

        const now = new Date();
        d = new Date(d);

        const hours =
            d.getHours() < 10 ? '0' + d.getHours() : d.getHours().toString();
        const minutes =
            d.getMinutes() < 10
                ? '0' + d.getMinutes()
                : d.getMinutes().toString();

        // heute?
        if (
            d.getDate() === now.getDate() &&
            d.getMonth() === now.getMonth() &&
            d.getFullYear() === now.getFullYear()
        ) {
            formattedDate = 'heute um ' + hours + ':' + minutes;
        } else if (
            d.getDate() === now.getDate() - 1 &&
            d.getMonth() === now.getMonth() &&
            d.getFullYear() === now.getFullYear()
        ) {
            formattedDate = 'gestern um ' + hours + ':' + minutes;
        } else {
            formattedDate = this.datePipe.transform(d, 'dd.MM.yyyy HH:mm');
        }

        return formattedDate;
    }

    topPageChanged(event: PageEvent) {
        this.offset = event.pageIndex * event.pageSize;
        this.limit = event.pageSize;
        this.bottomPaginator.pageSize = event.pageSize;
        this.bottomPaginator.pageIndex = event.pageIndex;
        this.loadBestellungenExtern();
    }

    bottomPageChanged(event: PageEvent) {
        this.offset = event.pageIndex * event.pageSize;
        this.limit = event.pageSize;
        this.topPaginator.pageSize = event.pageSize;
        this.topPaginator.pageIndex = event.pageIndex;
        this.loadBestellungenExtern();
    }

    loadBestellungenExtern() {
        this.appService.setDataLoading(true);
        let params = new HttpParams();
        params = params.append('offset', this.offset.toString());
        params = params.append('limit', this.limit.toString());
        params = params.append('orderby', 'erstellt desc');
        if (this.searchValue) {
            params = params.append('search', this.searchValue);
        }
        if (this.invoiceModeActive) {
            params = params.append('rechnungen', '0');
        }
        if (this.teilweiseGeliefertFilter) {
            params = params.set('vollstaendig', '0');
        }
        this.loadBestellungSub?.unsubscribe();
        this.loadBestellungSub = this.api
            .get(this.apiBaseUrl + 'bestellung_extern/read.php', params)
            .subscribe({
                next: (response) => {
                    this.bestellungenExtern = response.records;
                    this.totalLength = response.count;
                    this.appService.setDataLoading(false);
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Fehler beim Laden der Bestellungen aufgetreten!',
                        error
                    );
                    this.appService.setDataLoading(false);
                }
            });
    }

    addToBestellungRechnungArray(
        event: MatCheckboxChange,
        be: BestellungExtern
    ) {
        if (event.checked) {
            this.selectedLieferantId = be.lieferant_id;
            this.bestellungRechnungArray.push(be.bestellung_extern_id);
        } else {
            const index = this.bestellungRechnungArray.indexOf(
                be.bestellung_extern_id
            );
            this.bestellungRechnungArray.splice(index, 1);

            if (this.bestellungRechnungArray.length === 0) {
                this.selectedLieferantId = -1;
            }
        }
    }

    reload() {
        this.loadBestellungenExtern();
    }

    mergeBestellungen() {
        const input = new ConfirmDialogInput();
        input.headline = 'Bestellungen zusammenführen';
        input.text =
            'Wollen Sie die markierten Bestellungen zu einer zusammenführen?';
        input.okButton = 'Zusammenführen';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '33%',
            minWidth: '300px',
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    const obj = {
                        bestellung_extern_ids: this.bestellungRechnungArray,
                    };
                    this.api
                        .post(
                            this.apiBaseUrl + 'bestellung_extern/merge.php',
                            obj,
                            null
                        )
                        .subscribe(
                            (response) => {
                                this.messageService.infoMessage(
                                    'Bestellungen erfolgreich zusammengeführt!'
                                );
                                this.reload();
                            },
                            (error) => {
                                this.messageService.errorMessage(
                                    'Fehler beim Zusammenführen aufgetreten!',
                                    error
                                );
                                this.reload();
                            }
                        );
                }
            },
        });
    }

    openAddInvoiceToCollectionDialog() {
        const dialogRef = this.dialog.open(UploadInvoiceDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: {
                ids: this.bestellungRechnungArray,
                paramName: 'bestellung_extern_ids',
                url: 'bestellung_extern_dokument/create_rechnung.php',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.loadBestellungenExtern();
        });
    }

    openInvoiceDeliveryNoteDialog(files: File[], be: BestellungExtern) {
        const dialogRef = this.dialog.open(InvoiceDeliverNoteDialogComponent, {
            width: '50%',
            minWidth: '300px',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'invoice') {
                this.uploadService.uploadFilesToServerForMultipleEntities(
                    files,
                    'bestellung_extern_ids',
                    [be.bestellung_extern_id],
                    'bestellung_extern_dokument/create_rechnung.php',
                    undefined,
                    (fileName: string, progress: number) => {
                    },
                    (response) => {
                        this.openRechnungBestellpositonenCheckDialog(be, response);
                    },
                    () => {
                        this.loadBestellungenExtern();
                    }
                );
            } else if (result === 'deliveryNote') {
                this.uploadService.uploadFilesToServerForMultipleEntities(
                    files,
                    'bestellung_extern_ids',
                    [be.bestellung_extern_id],
                    'bestellung_extern_dokument/create_lieferschein.php',
                    new Date(),
                    (fileName: string, progress: number) => {
                    },
                    (response) => {
                    },
                    () => {
                        this.loadBestellungenExtern();
                    }
                );
            } else if (result === 'credit') {
                this.openAddGutschriftDialog(files, be);
            }
        });
    }

    openAddGutschriftDialog(files: File[], be: BestellungExtern) {
        const gutschrift = new Gutschrift();
        gutschrift.lieferant = be.lieferant;
        gutschrift.lieferant_id = be.lieferant_id;
        gutschrift.bestellung_extern_id = be.bestellung_extern_id;

        const dialogRef = this.dialog.open(AddGutschriftDialogComponent, {
            data: {
                filesToUpload: files,
                gutschrift: gutschrift,
                apiUrl: 'gutschrift/update.php',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            this.reload();
        });
    }

    generatePrimanota() {
        const input = new ConfirmDialogInput();
        input.headline = 'Primanota erstellen';
        input.text = 'Soll die Primanota für alle offenen Positionen im Reiter "Bestellungen Arzneimittel" erstellt werden?';
        input.okButton = 'Erstellen';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.http
                        .post(this.apiBaseUrl + 'primanota/generieren_lieferanten.php', { arzneimittel: 1 })
                        .subscribe({
                            next: response => {
                                this.messageService.infoMessage(
                                    'Die Primanota wurde erfolgreich generiert!'
                                );
                                this.router.navigate(['/finanzen', 'primanota']);
                            },
                            error: err => {
                                this.messageService.errorMessage(
                                    'Es ist ein Fehler beim Generieren der Primanota aufgetreten!',
                                    err
                                );
                            }
                        });
                }
            }
        });
    }

    isChecked(be: BestellungExtern) {
        const index = this.bestellungRechnungArray.indexOf(
            be.bestellung_extern_id
        );

        if (index > -1) {
            return true;
        }
        return false;
    }

    hasPermission(id: number) {
        return this.session.currentUserHasPermission(id);
    }

    getColorRechung(id: number) {
        switch (id) {
            case 101:
                return 'red';
            case 102:
                return 'orange';
            case 103:
                return 'green';
            case 104:
                return 'blue';
        }
    }

    saveBestellungExtern(be: BestellungExtern) {
        this.api.updateGeneric(this.apiBaseUrl + 'bestellung_extern/update.php', be).subscribe({
            next: (response) => {
                this.messageService.infoMessage(
                    'Die Änderungen wurden erfolgreich gespeichert!'
                );
                this.loadBestellungenExtern();
            },
            error: (error) => {
                this.messageService.errorMessage(
                    'Fehler beim Speichern!',
                    error
                );
            }
        });
    }

    download(be: BestellungExtern) {
        this.pdf.openPdfViewer(
            new PdfViewerDialogInput(
                'bestellung_extern/pdf_merged.php',
                new HttpParams().set(
                    'bestellung_extern_id',
                    be?.bestellung_extern_id?.toString()
                ),
                'Bestellungen Extern'
            )
        );
    }

    downloadGutschriften(be: BestellungExtern) {
        this.pdf.openPdfViewer(
            new PdfViewerDialogInput(
                'gutschrift/read_pdfs.php',
                new HttpParams().set(
                    'bestellung_extern_id',
                    be?.bestellung_extern_id?.toString()
                ),
                'Gutschriften'
            )
        );
    }

    downloadSingle(bestellungExternId: number, be: BestellungExtern) {
        this.pdf.openPdfViewer(
            new PdfViewerDialogInput(
                'bestellung_extern/pdf_merged.php',
                new HttpParams().set(
                    'bestellung_extern_id',
                    bestellungExternId?.toString()
                ),
                'Bestellung Extern an ' + be.lieferant
            )
        );
    }

    cancel(be: BestellungExtern) {
        this.openCancelDialog(be);
    }

    delete(be: BestellungExtern) {
        this.openDeleteDialog(be);
    }

    openCancelDialog(be: BestellungExtern) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title:
                    'Bestellung ' +
                    be.bestellung_extern_id +
                    ' wirklich stornieren?',
                text:
                    'Bestellung ' +
                    be.bestellung_extern_id +
                    ' wirklich stornieren?',
                textButton: 'stornieren',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                be.status_id_neu = 28;
                this.api
                    .updateGeneric(this.apiBaseUrl + 'bestellung_extern/update.php', be)
                    .subscribe({
                        next: (result) => {
                            this.messageService.infoMessage(
                                'Die Bestellung wurde erfolgreich storniert!'
                            );
                            this.reload();
                        },
                        error: (error) => {
                            this.messageService.errorMessage(
                                'Die Bestellung konnte nicht storniert werden! ',
                                error
                            );
                        }
                    });
            }
        });
    }

    openDeleteDialog(be: BestellungExtern) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title:
                    'Bestellung ' +
                    be.bestell_nr +
                    ' wirklich löschen?',
                text:
                    'Bestellung ' +
                    be.bestell_nr +
                    ' wirklich löschen?',
                textButton: 'löschen',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.api
                    .deleteEntity(this.apiBaseUrl + 'bestellung_extern/delete.php', be)
                    .subscribe({
                        next: (result) => {
                            this.messageService.infoMessage(
                                'Die Bestellung wurde erfolgreich gelöscht!'
                            );
                            this.reload();
                        },
                        error: (error) => {
                            this.messageService.errorMessage(
                                'Die Bestellung konnte nicht gelöscht werden! ',
                                error
                            );
                        }
                    });
            }
        });
    }

    openOrderAllDialog() {
        const input = new ConfirmDialogInput();
        input.headline = 'Offene Bestellungen';
        input.text = 'Wollen Sie alle offenen Bestellungen jetzt bestellen?';
        input.okButton = 'Alle Bestellen';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.api
                        .post(
                            this.apiBaseUrl +
                            'bestellung_extern/alle_bestellen.php',
                            null,
                            null
                        )
                        .subscribe(
                            (response) => {
                                this.messageService.infoMessage(
                                    'Es werden alle offenen Bestellungen (' +
                                    response.bestellungen +
                                    ') jetzt bestellt!'
                                );
                            },
                            (error) => {
                                this.messageService.errorMessage(
                                    'Es ist ein Fehler beim Bestellen aufgetreten!',
                                    error
                                );
                            }
                        );
                }
            },
        });
    }

    changeView(event: MatButtonToggleChange) {
        this.currentView = event.value;
    }

    openCommentDialog(be: BestellungExtern) {
        const dialogRef = this.dialog.open(AddCommentDialogComponent, {
            width: '70%',
            minWidth: '300px',
            data: {
                bestellungExtern: be,
            },
        });
    }

    goToDetails(id: number) {
        this.router.navigate(['wawi', 'bestellung-extern', id]);
    }

    summeAllerEinzelpreise(be: BestellungExtern): number {
        let summe = 0.0;

        for (const p of be.positionen) {
            summe += Math.round(p.einzelpreis * p.menge_ist * 100) / 100;
        }

        return summe;
    }

    summeAllerRabattpreise(be: BestellungExtern): number {
        let summe = 0.0;

        for (const p of be.positionen) {
            summe +=
                (Math.round(
                    (p.rabatt
                        ? (p.einzelpreis * (100 - p.rabatt)) / 100
                        : p.einzelpreis) * 100
                ) /
                    100) *
                p.menge_ist;
        }

        return summe;
    }

    openShowGutschriftenDialog(be: BestellungExtern) {
        const dialogRef = this.dialog.open(ShowGutschriftenDialogComponent, {
            minWidth: '33%',
            data: {
                bestellungExtern: be,
            },
        });
    }

    uploadInvoice(be: BestellungExtern) {
    }

    public openRechnungBestellpositonenCheckDialog(be: BestellungExtern, bestellungExternDokument: BestellungExternDokument) {
        const dialogRef = this.dialog.open(RechnungBestellpositionCheckDialogComponent, {
            width: '100%',
            height: '90%',
            data: {
                bestellungExtern: be,
                bestellungExternDokument: bestellungExternDokument
            }
        });
        dialogRef.afterClosed().subscribe({
            next: result => {
                this.reload();
            }
        });
    }

    downloadLieferschein(l: BestellungExternDokument) {
        this.pdf.openPdfViewer(
            new PdfViewerDialogInput(
                'bestellung_extern_dokument/read_pdf_lieferschein.php',
                new HttpParams().set(
                    'bed_id',
                    l?.bed_id?.toString()
                ),
                l.dateiname
            )
        );
    }

    markAsClosed(be: BestellungExtern) {
        const input = new ConfirmDialogInput();
        input.headline = 'Bestellung als geliefert markieren';
        input.text = 'Hiermit wird die Bestellung als vollständig geliefert markiert!';
        input.okButton = 'Vollständig geliefert';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input
        });
        dialogRef.afterClosed().subscribe({
            next: result => {
                if (result?.ok) {
                    if (be) {
                        be.status_id_neu = 30;

                        this.bestellungExternService
                            .updateBestellungExtern(be)
                            .subscribe({
                                next: (response) => {
                                    be = response;
                                    this.messageService.infoMessage(
                                        'Die Bestellung ' +
                                        be.bestell_nr +
                                        ' wurde erfolgreich als geliefert markiert!'
                                    );
                                },
                                error: (error) => {
                                    this.messageService.errorMessage(
                                        'Fehler! Die Bestellung konnte nicht als geliefert markiert werden! ',
                                        error
                                    );
                                }
                            });
                    }
                }
            }
        });

    }

    setzeTeilweiseGeliefertFilter() {
        this.teilweiseGeliefertFilter = !this.teilweiseGeliefertFilter;
        this.reload();
    }

    alleTeilweiseGeliefertAlsGeliefertMarkieren() {
        const input = new ConfirmDialogInput();
        input.headline = 'Alle als geliefert markieren!';
        input.text = 'Hiermit werden alle Bestellungen, die im Status "teilweise geliefert" und älter als 4 Wochen sind, als geliefert markiert!';
        input.okButton = 'als geliefert markieren';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: input });
        dialogRef.afterClosed().subscribe({
            next: result => {
                if (result?.ok) {
                    this.api.post(this.apiBaseUrl + 'bestellung_extern/geliefert.php', {}, undefined).subscribe({
                        next: response => {
                            this.messageService.infoMessage('Die entsprechenden Bestellungen wurden erfolgreich als geliefert markiert!');
                            this.reload();
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler!', err);
                        }
                    });
                }
            }
        });
    }
}
