import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kiene-labor',
  templateUrl: './labor.component.html',
  styleUrls: ['./labor.component.css']
})
export class LaborComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
