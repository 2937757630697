import {Material} from '../../warehouse/material/material';

export class BestellungExtern {
    bestellung_extern_id: number;
    bestell_nr: number;
    bestellt_nachname: string;
    kommentar: string;
    kommentar_per_mail: number;
    lieferant_id: number;
    lieferant: string;
    status_id: number;
    status_id_neu: number;
    status: string;
    erstellt: Date;
    editiert: Date;
    bestellt: Date;
    vorname: string;
    nachname: string;
    pdf_id: number;
    positionen: BestellungExternPosition[];
    geliefert: Date;
    dokumente: {
        gutschriften: BestellungExternDokument[];
        lieferscheine: BestellungExternDokument[];
        rechnungen: BestellungExternDokument[];
    };

}

export class BestellungExternPosition {
    bea_id: number;
    bestellung_extern_id: number;
    bestell_nr: number;
    in_rechnung: number;
    position: number;
    artikel_id: number;
    artikel: string;
    artikel_me: string;
    einheit: string;
    chargen: string;
    menge_soll: number;
    menge_ist: number;
    erstellt: Date;
    geprueft: any;
    einzelpreis: number;
    rabatt: number;
    kommentar: string;
    status: string;
    bestell_datum: Date;
    primanota_id: number;
    rechnungen: BestellungExternDokument[] = [];

    constructor(artikel: Material, menge: number) {
        if (artikel != null) {
            this.artikel_id = artikel.artikel_id;
            this.artikel = artikel.bezeichnung;
            if (menge != null) {
                this.menge_soll = menge;
            }
        }
    }
}

export class BestellungExternDokumentPruefung {
    bed_id: number;
    datum: Date;
    nummer: string;
    positionen: BestellungExternPosition[] = [];
}

export interface BestellungExternApi {
    records: BestellungExtern[];
    count: number;
}

export interface BestellungExternPositionApi {
    records: BestellungExternPosition[];
    count: number;
}

export class BestellungExternDokument {
    bed_id: number;
    pdf_id: number;
    dateiname: string;
    datum: Date;
    nummer: string;
    erstellt: Date;
    editiert: Date;
}

export interface BestellungExternDokumentApi {
    records: BestellungExternDokument[];
    count: number;
}
