import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'kiene-generate-nullmeldung-dialog',
  templateUrl: './generate-nullmeldung-dialog.component.html',
  styleUrls: ['./generate-nullmeldung-dialog.component.css']
})
export class GenerateNullmeldungDialogComponent implements OnInit {

  jahrCtrl = new UntypedFormControl((new Date()).getFullYear());
  halbjahrCtrl = new UntypedFormControl();

  years: number[] = [];

  constructor(public dialogRef: MatDialogRef<GenerateNullmeldungDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private api: KieneTableService,
    private messageService: MessageService) { }

  ngOnInit(): void {

    for (let i = 2000; i < 2099; i++) {
      this.years.push(i);
    }

    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    if (month < 6) {
      this.jahrCtrl.setValue(year - 1);
      this.halbjahrCtrl.setValue(2);
    } else {
      this.jahrCtrl.setValue(year);
      this.halbjahrCtrl.setValue(1);
    }

  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const obj = {
      jahr: this.jahrCtrl.value,
      halbjahr: this.halbjahrCtrl.value
    };
    this.api.post(environment.BASE_URI + 'meldung/generieren_nullmeldung.php', obj, null).subscribe(response => {
      this.messageService.infoMessage('Die Nullmeldungen wurden erfolgreich generiert!');
      this.close();
    }, error => {
      this.messageService.errorMessage('Beim Generieren der Nullmeldungen ist ein Fehler aufgetreten!', error);
    });
  }

}
