import {HttpParams} from "@angular/common/http";
import {KieneBackendApiService} from "../../../services/backend-api/kiene-backend-api.service";
import {TierRasse} from "../../gruppe-tier/classes/tier-rasse";
import {MessageService} from "../../../services/message-service/message.service";
import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {API_BASE_URL_SERVICE} from '../../../kiene-core.config';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {GeburtTier, GruppeTier} from "../../gruppe-tier/classes/gruppe-tier";
import {Gruppe} from '../classes/gruppe';
import {KieneAutocompleteComponent} from '../../kiene-autocomplete/kiene-autocomplete.component';

@Component({
    selector: 'kiene-gruppe-geburt',
    templateUrl: './gruppe-geburt.component.html',
    styleUrls: ['./gruppe-geburt.component.scss']
})
export class GruppeGeburtComponent implements OnInit, AfterViewInit {

    @ViewChild('rasseAutocomplete') rasseAutocomplete: KieneAutocompleteComponent;
    @ViewChild('ohrmarkeAutocomplete') ohrmarkeAutocomplete: KieneAutocompleteComponent;

    tier: GeburtTier;
    public url: string;
    public geburtsdatumCtrl = new FormControl(undefined, [Validators.required, this.dateInPastValidator]);
    public ohrmarkeCtrl = new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
    ]);
    public geschlechtCtrl = new FormControl(undefined, [Validators.required]);
    public gewichtCtrl = new FormControl();
    public params: HttpParams;
    private isRasse = false;
    private isOhrmarkeMutter = false;

    constructor(
        public dialogRef: MatDialogRef<GruppeGeburtComponent>,
        private dialog: MatDialog,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public input: Gruppe,
        @Inject(API_BASE_URL_SERVICE) public apiBaseUrl: string
    ) {
    }

    ngOnInit(): void {
        this.url = this.apiBaseUrl;
        this.params = new HttpParams().append('geschlecht_id', 2);
        this.tier = new GeburtTier();
        this.tier.gruppe_id = this.input.gruppe_id;

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.rasseAutocomplete?.closePanel();
            this.ohrmarkeAutocomplete?.closePanel();

        }, 20);
    }

    public save(): void {
        if (this.geburtsdatumCtrl.valid && this.ohrmarkeCtrl.valid && this.geschlechtCtrl.valid) {
            this.tier.geburtsdatum = this.geburtsdatumCtrl.value;
            this.tier.ohrmarke_kurz = this.ohrmarkeCtrl.value;
            this.tier.geschlecht_id = this.geschlechtCtrl.value;
            this.tier.geburtsgewicht = this.gewichtCtrl.value;
            this.postTier();
        } else {
            console.log(this.ohrmarkeCtrl.errors)
            if (this.ohrmarkeCtrl.hasError('required')) {
                this.messageService.hintMessage("Ohrmarke ist erforderlich." + this.ohrmarkeCtrl.errors);
            }
            if (this.ohrmarkeCtrl.hasError('minlength') || this.ohrmarkeCtrl.hasError('maxlength')) {
                this.messageService.hintMessage("Die Ohrmarke muss aus 'DE' und 10 weiteren Zeichen bestehen." + +this.ohrmarkeCtrl.errors);
            }
            if (this.ohrmarkeCtrl.hasError('pattern')) {
                this.messageService.hintMessage("Die Ohrmarke darf nur Zahlen enthalten.");
            }
            if (this.geschlechtCtrl.hasError('required')) {
                this.messageService.hintMessage("Geschlecht ist erforderlich.");
            }
            if (this.geburtsdatumCtrl.hasError('dateInFuture')) {
                this.messageService.hintMessage("Das Geburtsdatum darf nicht in der Zukunft liegen.");
            }
        }
    }

    dateInPastValidator(control: FormControl) {
        const today = new Date();
        if (control.value && control.value > today) {
            return {'dateInFuture': true};
        }
        return null;
    }

    public setSelectedOhrmarkeMutter(event: GruppeTier): void {
        this.tier.tier_id_mutter = event.tier_id;
        this.isOhrmarkeMutter = true;
    }

    public setSelectedRasse(event: TierRasse): void {
        this.tier.rasse_id = event.rasse_id;
        this.isRasse = true;
    }

    public disableSave(): boolean {
        return !(this.geburtsdatumCtrl.valid && this.ohrmarkeCtrl.valid && this.geschlechtCtrl.valid && this.isRasse && this.isOhrmarkeMutter);
    }

    private postTier(): void {
        this.api.post(this.apiBaseUrl + 'gruppe_tier/create_geburt.php', this.tier).subscribe({
            next: value => {
                this.messageService.infoMessage('Geburt wurde erfolgreich gemeldet.')
                this.dialogRef.close(true);
            }, error: err => {
                this.messageService.errorMessage('Fehler beim Melden', err);
            }
        })
    }
}
