import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnimalTypeSelectorComponent} from './animal-type-selector/animal-type-selector.component';
import {BetriebsartSelectorComponent} from './betriebsart-selector/betriebsart-selector.component';
import {ClientSelectorComponent} from './client-selector/client-selector.component';
import {CompanyGroupAutocompleteComponent} from './company-group-autocomplete/company-group-autocomplete.component';
import {EinheitSelectorComponent} from './einheit-selector/einheit-selector.component';
import {KieneButtonComponent} from './kiene-button/kiene-button.component';
import {KieneIconButtonComponent} from './kiene-icon-button/kiene-icon-button.component';
import {KieneMenuButtonComponent} from './kiene-menu-button/kiene-menu-button.component';
import {MaterialAutocompleteComponent} from './material-autocomplete/material-autocomplete.component';
import {OptionButtonComponent} from './option-button/option-button.component';
import {StoragePlaceAutocompleteComponent} from './storage-place-autocomplete/storage-place-autocomplete.component';
import {VerkaufspreisSelectorComponent} from './verkaufspreis-selector/verkaufspreis-selector.component';
import {
    ChangeCurrentClientComponent
} from '../administration/client/change-current-client/change-current-client.component';
import {
    ChangeCurrentClientDialogComponent
} from '../administration/client/change-current-client/change-current-client-dialog/change-current-client-dialog.component';
import {DropFileDirective} from './drop-file.directive';
import {MatTabsModule} from '@angular/material/tabs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatChipsModule} from '@angular/material/chips';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {KieneAutocompleteModule} from './kiene-autocomplete/kiene-autocomplete.module';
import {VerkaufspreisSelectorModule} from './verkaufspreis-selector/verkaufspreis-selector.module';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ClientGroupAutocompleteComponent} from './client-group-autocomplete/client-group-autocomplete.component';
import { ZeitslotEditingComponent } from './schlachthoefe/zeitslot-editing/zeitslot-editing.component';
import { DialogDropdownComponent } from './dialog-dropdown/dialog-dropdown.component';
import { AcceptBookingWithDriverDialogComponent } from './accept-booking-with-driver-dialog/accept-booking-with-driver-dialog.component';
import { DialogHtmlComponent } from './dialog-html/dialog-html.component';
import { DialogSanitizeHtmlPipe } from './dialog-html/dialog-sanitize-html.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {DirectivesModule} from '../../../projects/kiene-core/src/lib/directives/directives.module';
import {KieneMiniTableComponent} from './kiene-mini-table/kiene-mini-table.component';


@NgModule({
    declarations: [
        AnimalTypeSelectorComponent,
        BetriebsartSelectorComponent,
        ClientSelectorComponent,
        CompanyGroupAutocompleteComponent,
        EinheitSelectorComponent,
        KieneButtonComponent,
        KieneIconButtonComponent,
        KieneMenuButtonComponent,
        MaterialAutocompleteComponent,
        OptionButtonComponent,
        StoragePlaceAutocompleteComponent,
        ChangeCurrentClientComponent,
        ChangeCurrentClientDialogComponent,
        DropFileDirective,
        ClientGroupAutocompleteComponent,
        KieneMiniTableComponent,
        ClientGroupAutocompleteComponent,
        ZeitslotEditingComponent,
        DialogDropdownComponent,
        AcceptBookingWithDriverDialogComponent,
        DialogHtmlComponent,
        DialogSanitizeHtmlPipe,
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatCardModule,
        MatMenuModule,
        MatChipsModule,
        MatToolbarModule,
        MatExpansionModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatPaginatorModule,
        MatRadioModule,
        MatDividerModule,
        KieneAutocompleteModule,
        VerkaufspreisSelectorModule,
        MatAutocompleteModule,
        DirectivesModule,
        MatAutocompleteModule,
        PdfViewerModule,
    ],
    exports: [
        AnimalTypeSelectorComponent,
        BetriebsartSelectorComponent,
        ClientSelectorComponent,
        CompanyGroupAutocompleteComponent,
        EinheitSelectorComponent,
        KieneButtonComponent,
        KieneIconButtonComponent,
        KieneMenuButtonComponent,
        MaterialAutocompleteComponent,
        OptionButtonComponent,
        StoragePlaceAutocompleteComponent,
        VerkaufspreisSelectorComponent,
        ChangeCurrentClientComponent,
        ChangeCurrentClientDialogComponent,
        DropFileDirective,
        ClientGroupAutocompleteComponent,
        KieneMiniTableComponent,
        ZeitslotEditingComponent
    ]
})
export class ComponentsModule {
}
