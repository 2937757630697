import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { StoragePlace } from '../storage-place/storage-place';
import { StoragePlaceService } from '../storage-place/storage-place.service';
import { StockService } from '../stock/stock.service';
import { BestellungExtern } from '../../wa-wi/bestellung-extern/bestellung-extern';
import { BestellungExternService } from '../../wa-wi/bestellung-extern/bestellung-extern.service';
import { Material } from '../material/material';
import { MaterialService } from '../material/material.service';
import { GoodReceipt } from './good-receipt';
import { MessageService } from '../../services/message.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
    ArticleScanAutocompleteComponent,
    ArticleScanSelectedEvent,
} from '../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import { MatDialog } from '@angular/material/dialog';
import { GoodsReceiptAddArticleComponent } from './goods-receipt-add-article/goods-receipt-add-article.component';

@Component({
    selector: 'kiene-goods-receipt',
    templateUrl: './goods-receipt.component.html',
    styleUrls: ['./goods-receipt.component.scss'],
})
export class GoodsReceiptComponent implements AfterViewInit {
    materialFormCtrl: UntypedFormControl;
    storagePlaceFormCtrl: UntypedFormControl;
    materialien: Observable<Material[]>;
    storagePlaces: Observable<StoragePlace[]>;
    possibleBestellungen: BestellungExtern[] = [];

    newMaterial: Material = new Material();
    goodReceipt: GoodReceipt = new GoodReceipt();
    storagePlaceSuggestions: StoragePlace[] = [];
    selectedStoragePlace: StoragePlace = new StoragePlace();
    showStoragePlaceAutocomplete = false;

    selectedOrder: BestellungExtern = new BestellungExtern();

    saveButtonDisabled = false;
    produktkennzeichnung: string;

    @ViewChild('materialAutocomplete', { static: false })
    materialAutocomplete: ArticleScanAutocompleteComponent;

    constructor(
        private bestellungExternService: BestellungExternService,
        private storagePlaceService: StoragePlaceService,
        private stockService: StockService,
        private notification: MessageService,
        private dialog: MatDialog
    ) {
        this.autocompleteStoragePlace();
    }

    ngAfterViewInit() {
    }

    isArticleInDatabase(event: ArticleScanSelectedEvent) {
        if (event.article) {
            this.materialSelected(event);
        } else {
            this.openAddArticleDialog();
        }
    }

    materialSelected(event: ArticleScanSelectedEvent) {
        this.newMaterial = event.article;
        // this.newMaterial.artikel_id = event.article.artikel_id;
        // this.newMaterial.bezeichnung = event.option.value.bezeichnung;
        this.goodReceipt.artikel_id = event.article.artikel_id;
        if (event.mhd) {
            this.goodReceipt.mhd = event.mhd;
        }
        if (event.charge) {
            this.goodReceipt.charge = event.charge;
        }
        this.getStoragePlaceSuggestions(event.article.artikel_id);
        this.loadPossibleOrders(this.newMaterial.artikel_id);
    }

    clearMaterial() {
        this.materialAutocomplete.clear();
        this.materialAutocomplete.setFocus();
        this.newMaterial = null;
    }

    autocompleteStoragePlace() {
        this.storagePlaceFormCtrl = new UntypedFormControl();
        this.storagePlaces = this.storagePlaceFormCtrl.valueChanges.pipe(
            startWith(''),
            switchMap((value) => this.searchStoragePlaces(value))
        );
    }

    searchStoragePlaces(value: string) {
        return this.storagePlaceService
            .searchStoragePlaces(value)
            .pipe(map((response) => response.records));
    }

    getStoragePlaceSuggestions(artikelId: number) {
        if (artikelId) {
            this.storagePlaceService
                .getStoragePlacesForMaterial(artikelId)
                .subscribe({
                    next: (response) => {
                        this.storagePlaceSuggestions = response.records;
                    },
                    error: (error) => {
                        this.notification.errorMessage('Fehler!', error);
                    }
                }
                );
        }
    }

    setSelectedStoragePlace(storagePlace: StoragePlace) {
        if (this.selectedStoragePlace.bezeichnung === storagePlace.bezeichnung) {
            this.selectedStoragePlace = new StoragePlace();
        } else {
            this.selectedStoragePlace = storagePlace;
        }
    }

    isStoragePlaceSelected(storagePlace: StoragePlace) {
        if (this.selectedStoragePlace.bezeichnung === storagePlace.bezeichnung) {
            return true;
        }
        return false;
    }

    isOrderSelected(po: BestellungExtern) {
        if (
            this.selectedOrder.bestellung_extern_id === po.bestellung_extern_id
        ) {
            return true;
        }
        return false;
    }

    setSelectedOrder(po: BestellungExtern) {
        this.selectedOrder = po;
    }

    autocompleteMaterialSelected(event: MatAutocompleteSelectedEvent) {
        this.newMaterial.artikel_id = event.option.value.artikel_id;
        this.newMaterial.bezeichnung = event.option.value.bezeichnung;
        this.goodReceipt.artikel_id = event.option.value.artikel_id;
        this.getStoragePlaceSuggestions(event.option.value.artikel_id);
        this.loadPossibleOrders(this.newMaterial.artikel_id);
    }

    loadPossibleOrders(materialId: number) {
        const status: number[] = [23, 24, 25, 27, 29];
        this.bestellungExternService
            .getBestellungenByMaterialAndStatus(materialId, status)
            .subscribe((response) => {
                this.possibleBestellungen = response.records;
            });
    }

    saveGoodReceipt() {
        this.saveButtonDisabled = true;
        this.goodReceipt.lagerplatz_id_ziel =
            this.selectedStoragePlace.lagerplatz_id;
        this.goodReceipt.bestellung_extern_id =
            this.selectedOrder.bestellung_extern_id;
        this.stockService.createStock(this.goodReceipt).subscribe(
            () => {
                this.notification.infoMessage(
                    'Der neue Bestand wurde erfolgreich gespeichert!'
                );
                this.clearInputs();
                this.saveButtonDisabled = false;
            },
            (error) => {
                this.notification.errorMessage(
                    'Ein Fehler ist aufgetreten! Der neue Bestand konnte nicht gespeichert werden!',
                    error
                );
                this.saveButtonDisabled = false;
            }
        );
    }

    clearInputs() {
        this.newMaterial = undefined;
        this.storagePlaceFormCtrl.setValue(undefined);
        this.storagePlaceSuggestions = [];
        this.selectedOrder = new BestellungExtern();
        this.goodReceipt = new GoodReceipt();
        this.selectedStoragePlace = new StoragePlace();
    }

    setProduktkennzeichnung(pk: string) {
        this.produktkennzeichnung = pk;
    }

    openAddArticleDialog() {
        const pk = this.produktkennzeichnung || this.materialAutocomplete.getValue();
        if (!pk) {
            this.notification.infoMessage('Keine alternative Produktbezeichnung eingetragen!');
        } else {
            this.dialog.open(GoodsReceiptAddArticleComponent, {
                data: pk, minWidth: '400px'
            }).afterClosed().subscribe({
                next: (artikel: Material) => {
                    if (artikel) {
                        const event = new ArticleScanSelectedEvent();
                        event.article = artikel;
                        this.materialSelected(event);
                    }
                }
            });
        }
    }

    reload() {
        this.clearInputs();
    }
}
