<h1 mat-dialog-title>
    Tier bearbeiten
</h1>
<div mat-dialog-content>

    <div style="padding: 12px 0 24px 0">
        <p>
            <strong>Ohrmarke:</strong>
            <kiene-ohrmarke [ohrmarke]="this.tier.ohrmarke_notnull"></kiene-ohrmarke>
        </p>
    </div>

    <mat-form-field style="width: 100%; padding-bottom: 24px" class="no-padding-margin-mat-form-field">
        <mat-label>Abteil</mat-label>
        <input matInput [placeholder]="'Abteil'" [formControl]="abteilCtrl"/>
    </mat-form-field>

    <div
        style="display: flex; justify-content: space-around; align-items: center; width: 100%;">
        <mat-form-field class="no-padding-margin-mat-form-field" style="width: 90% ;display:flex; flex-direction: column ; justify-content: space-around;">
            <mat-label>H&auml;ndler ausw&auml;hlen</mat-label>
            <mat-select [compareWith]="compareHaendler" [formControl]="haendlerCtrl">
                <mat-option *ngFor="let haendler of haendlerFavoriten" [value]="haendler">{{haendler.name}}</mat-option>
            </mat-select>
            <mat-hint  style="padding-top: 24px" *ngIf="emptyListHaendler">
                Es ist kein H&auml;ndler gespeichert. Ggf. über das + Symbol einen H&auml;ndler hinzufügen.
            </mat-hint>
        </mat-form-field>
        <button (click)="openHaendlerVerwaltung()" mat-icon-button color="accent" >
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>
<div mat-dialog-actions>

    <button (click)="dialogRef.close()" mat-flat-button>Abbrechen</button>
    <button (click)="updateGruppeTier()" mat-flat-button color="accent">Speichern</button>
</div>
