<div class="mat-typography column">
    <div class="column-spacer"></div>
    <div *ngIf="!showMandantSelector">
        <form (ngSubmit)="login()">
            <div class="one-row" style="padding-bottom: 40px;">

                <mat-icon color="primary" style="transform: scale(1.6)">lock</mat-icon>
                <span class="spacer"></span>
                <!--
                      <img src="assets/Logo.png" style="padding-left: 14px;" />
                    -->
                <div class="vetvet-logo">VETVET<span class="primary-color">.</span></div>
            </div>
            <div>
                <mat-form-field style="width: 100%;">
                    <mat-label>E-Mail</mat-label>
                    <span matPrefix style="padding: 0; font-size: .6em;">
                                <mat-icon>email</mat-icon>
                                &nbsp;
                            </span>
                    <input [formControl]="emailCtrl" autocomplete="username" matInput type="email"/>
                </mat-form-field>
                <mat-form-field style="width: 100%;">
                    <mat-label>Passwort</mat-label>
                    <span matPrefix style="font-size: .6em;">
                                <mat-icon>more_horiz</mat-icon>
                                &nbsp;
                            </span>
                    <input [formControl]="passwordCtrl" [type]="hidePassword ? 'password' : 'text'"
                           autocomplete="current-password" matInput type="password"/>
                    <mat-icon (click)="hidePassword = !hidePassword" matSuffix>{{
                            hidePassword ? 'visibility' :
                                'visibility_off'
                        }}
                    </mat-icon>
                </mat-form-field>

                <div>
                    <div style="text-align: center">
                        <button [disabled]="isLoading" color="primary" mat-flat-button style="width: 66%" type="submit">
                            Anmelden
                        </button>
                    </div>

                    <div style="text-align: center">
                        <button (click)="register()" mat-flat-button style="width: 66%; background-color: transparent"
                                type="button">
                            <span class="kiene-color">Registrieren</span>
                        </button>
                    </div>

                    <div style="text-align: center">
                        <button (click)="resetPassword()" mat-flat-button
                                style="width: 66%; background-color: transparent">
                            <span class="kiene-color">Passwort vergessen</span>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div *ngIf="showMandantSelector" class="one-row">
        <span class="spacer"></span>
        <div>
            <h3>Bitte Mandanten auswählen.</h3>
            <mat-card (click)="setMandant(m)" *ngFor="let m of kieneSession?.mandanten"
                      style="text-align: center; margin-bottom: 5px; width: 90%; max-width: 300px; cursor: pointer;">
                <div style="width: 100%;">
                    <mat-icon color="accent" style="transform: scale(2)">business</mat-icon>
                </div>
                <p>{{ m.bezeichnung }}</p>
            </mat-card>
        </div>
        <span class="spacer"></span>
    </div>
    <div class="column-spacer"></div>
</div>
<!--<div *ngIf="!showMandantSelector" class="login one-row">-->
<!--    <span class="spacer"></span>-->
<!--    <div class="form-area">-->
<!--        <form (ngSubmit)="login()">-->
<!--            <div class="one-row" style="padding-bottom: 40px;">-->

<!--                <mat-icon color="primary" style="transform: scale(1.6)">lock</mat-icon>-->
<!--                <span class="spacer"></span>-->
<!--                &lt;!&ndash;-->
<!--                      <img src="assets/Logo.png" style="padding-left: 14px;" />-->
<!--                    &ndash;&gt;-->
<!--                <div class="vetvet-logo">VETVET<span class="primary-color">.</span></div>-->
<!--            </div>-->
<!--            <div>-->
<!--                <mat-form-field style="width: 100%;">-->
<!--                    <mat-label>E-Mail</mat-label>-->
<!--                    <span matPrefix style="padding: 0; font-size: .6em;">-->
<!--                        <mat-icon>email</mat-icon>-->
<!--                        &nbsp;-->
<!--                    </span>-->
<!--                    <input [formControl]="emailCtrl" autocomplete="username" matInput type="email"/>-->
<!--                </mat-form-field>-->
<!--                <mat-form-field style="width: 100%;">-->
<!--                    <mat-label>Passwort</mat-label>-->
<!--                    <span matPrefix style="font-size: .6em;">-->
<!--                        <mat-icon>more_horiz</mat-icon>-->
<!--                        &nbsp;-->
<!--                    </span>-->
<!--                    <input [formControl]="passwordCtrl" [type]="hidePassword ? 'password' : 'text'"-->
<!--                           autocomplete="current-password" matInput type="password"/>-->
<!--                    <mat-icon (click)="hidePassword = !hidePassword" matSuffix>{{hidePassword ? 'visibility' :-->
<!--                        'visibility_off'}}-->
<!--                    </mat-icon>-->
<!--                </mat-form-field>-->

<!--                <div>-->
<!--                    <div style="text-align: center">-->
<!--                        <button [disabled]="isLoading" color="primary" mat-flat-button style="width: 66%" type="submit">-->
<!--                            Anmelden-->
<!--                        </button>-->
<!--                    </div>-->

<!--                    <div style="text-align: center">-->
<!--                        <button (click)="register()" mat-flat-button style="width: 66%; background-color: transparent"-->
<!--                                type="button">-->
<!--                            <span class="kiene-color">Registrieren</span>-->
<!--                        </button>-->
<!--                    </div>-->

<!--                    <div style="text-align: center">-->
<!--                        <button (click)="resetPassword()" mat-flat-button-->
<!--                                style="width: 66%; background-color: transparent">-->
<!--                            <span class="kiene-color">Passwort vergessen</span>-->
<!--                        </button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </form>-->
<!--    </div>-->
<!--    <span class="spacer"></span>-->
<!--</div>-->


