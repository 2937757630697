import { Injectable, Inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { EnterValueDialogComponent } from "../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-dialog.component";
import {
    EnterValueInput,
    EnterValueResponse,
} from "../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-response";
import {
    Kundengutschrift,
    KundengutschriftRueckverguetung,
} from "../../../src/app/finanzen/gutschriften/gutschrift";
import { MessageService } from "../../../projects/kiene-core/src/lib/services/message-service/message.service";
import { Router } from "@angular/router";
import { KieneTableService } from "../components/kiene-table/kiene-table.service";
import { API_BASE_URL_SERVICE } from "../../../projects/kiene-core/src/lib/kiene-core.config";

@Injectable({
    providedIn: "root",
})
export class ReportService {
    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private dialog: MatDialog,
        private messageService: MessageService,
        private router: Router,
        private api: KieneTableService
    ) { }

    rueckverguetung(kunde_id: number, von: Date, bis: Date) {
        if (!kunde_id) {
            this.messageService.alertMessage(
                "Bitte erst einen Kunden auswählen!"
            );
            return;
        }

        const input = new EnterValueInput();
        input.title = "Rückvergütung erstellen";
        input.text =
            "Bitte geben Sie den Prozentsatz für die Rückvergütung ein.";
        input.okButton = "erstellen";
        input.buttonColor = "accent";
        input.addInputValue("Prozentsatz", "decimal");

        const dialogRef = this.dialog.open(EnterValueDialogComponent, {
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (res: EnterValueResponse) => {
                if (res?.result === true) {
                    const rv = new KundengutschriftRueckverguetung();
                    rv.kunde_id = kunde_id;
                    rv.von = von;
                    rv.bis = bis;
                    rv.prozentsatz = res.getValue(0);
                    this.api
                        .updateGeneric(
                            this.apiBaseUrl +
                            "kundengutschrift/generieren_rueckverguetung.php",
                            rv
                        )
                        .subscribe({
                            next: () => {
                                this.messageService
                                    .infoMessageAction(
                                        "Rückvergütung wurde erfolgreich erstellt",
                                        "Rückvergütungen anzeigen"
                                    )
                                    .subscribe(() => {
                                        this.router
                                            .navigate([
                                                "finanzen",
                                                "gutschriften",
                                            ])
                                            .then();
                                    });
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    "Rückvergütung konnte nicht erstellt werden!",
                                    err
                                );
                            },
                        });
                }
            },
        });
    }
}
