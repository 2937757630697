import {HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {environment} from '../../../../environments/environment';
import {
    ArticleScanAutocompleteComponent,
    ArticleScanSelectedEvent
} from '../../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {MessageService} from '../../../services/message.service';
import {Material} from '../../../warehouse/material/material';
import {Kundengutschrift, KundengutschriftPosition} from '../gutschrift';
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-gutschrift-details',
    templateUrl: './gutschrift-details.component.html',
    styleUrls: ['./gutschrift-details.component.css']
})
export class GutschriftDetailsComponent implements OnInit {

    @Input('kundengutschrift') kundengutschrift: Kundengutschrift;

    @ViewChild('artikelInput') artikelInput: ArticleScanAutocompleteComponent;
    @ViewChild('mengeInput') mengeInput: MatInput;
    @ViewChild('preisInput') preisInput: MatInput;
    @ViewChild('einzelpreisInput') einzelpreisInput: MatInput;

    @Output('saved') saved = new EventEmitter<boolean>();

    kunde: Client = new Client();

    mengeCtrl = new UntypedFormControl(1);
    preisCtrl = new UntypedFormControl();
    einzelpreisCtrl = new UntypedFormControl();
    gutschriftDatumCtrl = new UntypedFormControl();
    materialSelected: Material;
    gesamtpreisGutschrift = 0;

    currentEditPosition = -1;

    constructor(private api: KieneTableService, private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.initGutschrift();

        this.mengeCtrl.valueChanges.subscribe(value => {
            const price = value * this.einzelpreisCtrl.value;
            this.preisCtrl.setValue(price, {emitEvent: false});
        });

        this.einzelpreisCtrl.valueChanges.subscribe(einzelpreis => {
            const price = einzelpreis * this.mengeCtrl.value;
            this.preisCtrl.setValue(price, {emitEvent: false});
        });

        this.preisCtrl.valueChanges.subscribe(gesamtpreis => {
            if (this.mengeCtrl.value && this.mengeCtrl.value !== 0) {
                const einzelpreis = gesamtpreis / this.mengeCtrl.value;
                this.einzelpreisCtrl.setValue(einzelpreis, {emitEvent: false});
            }
        });

        if (this.kundengutschrift.primanota_id) {
            this.gutschriftDatumCtrl.disable();
        }
    }

    initGutschrift() {
        if (this.kundengutschrift.kunde_id) {
            this.kunde.kunde_id = this.kundengutschrift.kunde_id;
            this.kunde.bezeichnung_final = this.kundengutschrift.kunde;
        }
        if (!this.kundengutschrift.datum) {
            const now = new Date();
            this.kundengutschrift.datum = now;
            this.gutschriftDatumCtrl.setValue(now);
        } else {
            this.gutschriftDatumCtrl.setValue(this.kundengutschrift.datum);
        }

        this.calculateGutschriftpreis();
    }

    setSelectedMaterial(asse: ArticleScanSelectedEvent) {
        if (asse) {
            this.materialSelected = asse.article;
            this.mengeInput?.focus();
            this.loadArticlePrice();
        }

    }

    savePosition() {
        if (this.currentEditPosition >= 0) {
            this.kundengutschrift.positionen[this.currentEditPosition].artikel_id = this.materialSelected.artikel_id;
            this.kundengutschrift.positionen[this.currentEditPosition].artikel_me = this.materialSelected.artikel_me;
            this.kundengutschrift.positionen[this.currentEditPosition].artikelmenge = this.mengeCtrl.value;
            this.kundengutschrift.positionen[this.currentEditPosition].einzelpreis = this.einzelpreisCtrl.value;
            this.kundengutschrift.positionen[this.currentEditPosition].gesamtpreis = this.mengeCtrl.value * this.einzelpreisCtrl.value;
            this.clearInputFields();
        } else {
            this.addPosition();
        }
        this.calculateGutschriftpreis();
    }

    addPosition(): void {
        if (!this.materialSelected) {
            return;
        }
        const position = new KundengutschriftPosition();
        position.artikel_id = this.materialSelected.artikel_id;
        position.artikel_me = this.materialSelected.artikel_me;
        position.artikelmenge = this.mengeCtrl.value;
        position.einzelpreis = this.einzelpreisCtrl.value;
        position.gesamtpreis = this.mengeCtrl.value * this.einzelpreisCtrl.value;
        this.kundengutschrift.positionen.push(position);
        this.calculateGutschriftpreis();
        this.clearInputFields();
    }

    deletePosition(index: number) {
        this.kundengutschrift.positionen.splice(index, 1);
        this.calculateGutschriftpreis();
    }

    editPosition(index: number) {
        this.currentEditPosition = index;
        const pos: KundengutschriftPosition = this.kundengutschrift.positionen[index];
        const material = new Material();
        material.artikel_id = pos.artikel_id;
        material.artikel_me = pos.artikel_me;
        this.artikelInput.setMaterial(material, false);
        this.materialSelected = material;
        this.einzelpreisCtrl.setValue(pos.einzelpreis);
        this.mengeCtrl.setValue(pos.artikelmenge);
        this.preisCtrl.setValue(pos.gesamtpreis);
    }

    calculateGutschriftpreis() {
        let preis = 0;
        for (const pos of this.kundengutschrift.positionen) {
            preis += pos.gesamtpreis;
        }
        this.gesamtpreisGutschrift = preis;
    }

    clearInputFields() {
        this.materialSelected = null;
        this.mengeCtrl.setValue(1, {emitEvent: false});
        this.preisCtrl.setValue(0, {emitEvent: false});
        this.artikelInput.clearAndFocus();
        this.currentEditPosition = -1;
    }

    loadArticlePrice() {
        if (this.materialSelected) {
            let menge = this.mengeCtrl.value;
            if (!menge || menge < 1) {
                menge = 1;
            }
            let params = new HttpParams();
            params = params.set('artikel_id', this.materialSelected.artikel_id.toString());
            params = params.set('artikelmenge', menge);
            params = params.set('kunde_id', this.kundengutschrift.kunde_id.toString());

            this.api.getOneByParams(environment.BASE_URI + 'artikel/read_preis.php', params).subscribe(response => {
                this.einzelpreisCtrl.setValue(response.einzelpreis);
                this.preisCtrl.setValue(this.mengeCtrl.value * response.einzelpreis);
            }, error => {
            });
        }
    }

    gutschriftSpeichern() {
        if (this.kundengutschrift.primanota_id) {
            return;
        }
        this.kundengutschrift.datum = this.gutschriftDatumCtrl.value;
        this.api.updateEntity(environment.BASE_URI + 'kundengutschrift/update.php', this.kundengutschrift).subscribe({
            next: response => {
                this.messageService.infoMessage('Die Gutschrift wurde erfolgreich gespeichert');
                this.saved.emit(true);
            }, error: error => {
                this.messageService.errorMessage('Die Gutschrift konnte nicht gespeichert werden!', error);
            }
        });
    }

}
