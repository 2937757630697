<div style="padding: 24px;">
    <div class="one-row">
        <span class="spacer"></span>
        <kiene-zeitauswahl (change)="zeitintervalChanged($event)"></kiene-zeitauswahl>
        <span class="spacer"></span>
    </div>

    <table class="report-ergebnis-table">
        <thead>
        <tr>
            <th></th>
            <th>Wareneingang Gesamt</th>
            <th>Warenausgang Gesamt</th>

        </tr>
        </thead>
        <tbody>
        <tr style="font-size: 1.6em; font-weight: bold; color: green;">
            <td style="font-size: 1em; font-weight: bold; color: gray;"></td>
            <td>{{gesamtWareneingang | number: '1.2-2'}} &euro;</td>
            <td>{{gesamtWarenausgang | number: '1.2-2'}} &euro;</td>
        </tr>
        <tr style="font-size: 1em; font-weight: bold; color: gray;">
            <td>{{ vorjahreszeitraum?.von | date: 'dd.MM.yyyy' }}
                bis {{vorjahreszeitraum?.bis | date: 'dd.MM.yyyy'}}</td>
            <td>{{gesamtWareneingangVorjahr | number: '1.2-2'}} &euro;</td>
            <td>{{gesamtWarenausgangVorjahr | number: '1.2-2'}} &euro;</td>
        </tr>
        </tbody>
    </table>

    <div class="one-row">
        <kiene-autocomplete style="width: 300px;" [displayField]="'artikel_me'"
                            (elementSelected)="setCurrentMaterial($event)" [placeholder]="'Artikel'"
                            [apiUrl]="'artikel_autocomplete/'"></kiene-autocomplete>
        <!--            <kiene-report-overview-value-card [label]="'Wareneingang Gesamt'" [numberValue]="gesamtWareneingang" [numberFormat]="'1.0-2'" [unit]="'Stk.'"></kiene-report-overview-value-card>-->
        <!--            <kiene-report-overview-value-card [label]="'Warenausgang Gesamt'" [numberValue]="gesamtWarenausgang" [numberFormat]="'1.0-2'" [unit]="'Stk.'"></kiene-report-overview-value-card>-->
    </div>

    <mat-card>
        <div class="one-row">
            <span class="spacer"></span>
            <kiene-csv-export [columns]="csvColumns" [data]="dataSource.data"
                              [filename]="'Warenein-und-ausgang_' + (currentMaterial ? currentMaterial.artikel_me : '') + '.csv'"></kiene-csv-export>
        </div>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

            <!-- Name Column -->
            <ng-container matColumnDef="zeitraum">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Zeitraum</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.zeitraum}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="wareneingang">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Wareneingang</th>
                <td mat-cell *matCellDef="let element"
                    class="number"> {{element.wareneingang ? element.wareneingang : 0 | number: '1.0-2'}} Stk.
                </td>
            </ng-container>
            <ng-container matColumnDef="warenausgang">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Warenausgang</th>
                <td mat-cell *matCellDef="let element"
                    class="number"> {{element.warenausgang ? element.warenausgang : 0 | number: '1.0-2'}} Stk.
                </td>
            </ng-container>
            <ng-container matColumnDef="differenz">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Differenz</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.differenz | number: '1.0-2'}} Stk.</td>
            </ng-container>


            <ng-container matColumnDef="aggregatedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="aggregated-vetgroup-detail"
                         [@detailExpanded]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div *ngIf="isLoading[element.artikel_id]" class="one-row">
                            <span class="spacer"></span>
                            <mat-spinner [diameter]="32"></mat-spinner>
                            <span class="spacer"></span>
                        </div>
                        <table *ngIf="!isLoading[element.artikel_id]" class="details-table"
                               style="table-layout: fixed; padding-bottom: 24px;">
                            <tr *ngFor="let d of element.details">
                                <td style="padding-left: 24px;">{{d.zeitraum}}</td>
                                <td class="number">{{d.umsatz | number: '1.2-2'}} &euro;</td>
                                <td class="number">{{d.umsatz_prozent | number: '1.0-2'}} %</td>
                                <td class="number">{{d.gutschriften | number: '1.2-2'}} &euro;</td>
                                <td class="number">{{d.gutschriften_prozent | number: '1.0-2'}} %</td>
                                <td class="number">{{d.rabatte | number: '1.2-2'}} &euro;</td>
                                <td class="number">{{d.rabatte_prozent | number: '1.0-2'}} %</td>
                                <td class="number">{{d.bestellungen | number: '1.0-0'}} Stk.</td>
                            </tr>
                        </table>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
        </table>
    </mat-card>

    <mat-card style="margin-bottom: 24px; margin-top: 24px; height: 300px;">
        <mat-form-field style="width: 250px;">
            <mat-label>Bitte Diagrammtyp ausw&auml;hlen</mat-label>
            <mat-select [(value)]="chartType">
                <mat-option value="Line">Linie</mat-option>
                <mat-option value="Bar">Balken</mat-option>
            </mat-select>
        </mat-form-field>
        <google-chart style="width: 100%;"
                      [options]="{chart: {title: currentMaterial ? 'Für Artikel: ' + currentMaterial.artikel_me : ''}}"
                      [type]="chartType" [columns]="chartColumns" [data]="chartData">
        </google-chart>
    </mat-card>


</div>
