<h1 mat-dialog-title>Neuen Entsorger anlegen</h1>
<div mat-dialog-content>
    <mat-form-field style="width: 100%;">
        <mat-label>Name des Entsorgers</mat-label>
        <input matInput type="text" [formControl]="nameCtrl">
    </mat-form-field>

    <mat-form-field style="width: 100%;">
        <mat-label>VVVO des Entsorgers</mat-label>
        <input matInput type="text" [formControl]="vvvoCtrl" maxlength="15" minlength="12">
        <span matSuffix>{{(vvvoCtrl?.value ? vvvoCtrl.value.length : 0) + '/15'}}</span>
    </mat-form-field>

    <mat-form-field style="width: 100%;">
        <mat-label>Stra&szlig;e und Nr.</mat-label>
        <input matInput type="text" [formControl]="strasseCtrl">
    </mat-form-field>

    <div class="one-row">
        <mat-form-field style="width: 30%;">
            <mat-label>PLZ</mat-label>
            <input matInput type="text" [formControl]="plzCtrl">
        </mat-form-field>
        <span class="spacer"></span>
        <mat-form-field style="width: 65%;">
            <mat-label>Ort</mat-label>
            <input matInput type="text" [formControl]="ortCtrl">
        </mat-form-field>

        <mat-form-field style="width: 100%;">
            <mat-label>E-Mail Adresse</mat-label>
            <input matInput type="email" inputmode="email" [formControl]="emailCtrl">
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="anlegen()" [disabled]="!nameCtrl.valid || !emailCtrl.valid">
        Anlegen
    </button>
</div>
