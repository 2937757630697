import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {MessageService} from '../../../../services/message-service/message.service';
import {StammdatenService} from '../../../../components/stammdaten/stammdaten.service';
import {Gruppe} from '../../../../components/gruppen/classes/gruppe';
import {
    GruppeTierEinstalldatum
} from '../../../../components/gruppe-tier/filter/gruppe-tier-einstalldatum-filter/gruppe-tier-einstalldatum';
import {
    TierFilterSettings
} from '../../../../components/gruppe-tier/filter/gruppe-tier-filter-dialog/classes/tier-filter-settings';
import {HttpParams} from '@angular/common/http';

@Component({
    selector: 'kiene-gruppe-tier-einstalldatum-filter',
    templateUrl: './gruppe-tier-einstalldatum-filter.component.html',
    styleUrls: ['./gruppe-tier-einstalldatum-filter.component.scss']
})
export class GruppeTierEinstalldatumFilterComponent implements OnInit, OnChanges, OnDestroy {
    sub: Subscription;
    isLoading = true;
    label = 'Lädt...';

    @Input('gruppe') gruppe: Gruppe;
    @Input('filter') filter: TierFilterSettings;
    @Input('einstalldaten') selectedEinstalldaten: GruppeTierEinstalldatum[] = [];
    @Input('hideOnEmptyList') hideOnEmptyList = false;

    @Output('change') change =
        new EventEmitter<GruppeTierEinstalldatum[]>();
    selectedEinstalldatumCtrl: FormControl = new FormControl<GruppeTierEinstalldatum[]>(undefined);

    einstalldaten: GruppeTierEinstalldatum[] = [];

    constructor(
        private messageService: MessageService,
        private stammdatenService: StammdatenService
    ) {
    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    ngOnInit(): void {
        this.selectedEinstalldatumCtrl.disable();
        this.ladeEinstalldatumFuerGruppe();

        this.selectedEinstalldatumCtrl.setValue(this.selectedEinstalldaten, {emitEvent: false});

        this.selectedEinstalldatumCtrl.valueChanges.subscribe((value: GruppeTierEinstalldatum[]) => {
            this.change.emit(value);
        });
    }

    ngOnChanges() {
    }

    compareGruppeTierEinstalldatum(o1: GruppeTierEinstalldatum, o2: GruppeTierEinstalldatum) {
        return o1?.einstalldatum === o2?.einstalldatum;
    }

    resetCtrl($event: MouseEvent) {
        $event.stopPropagation();
        this.selectedEinstalldatumCtrl.setValue(undefined);
    }

    public update() {
        this.ladeEinstalldatumFuerGruppe();
    }

    ladeEinstalldatumFuerGruppe() {
        if (this.gruppe) {
            this.isLoading = true;
            let params = new HttpParams();
            if (this.filter && this.filter.viehhaendler && this.filter.viehhaendler.length > 0) {
                for (const h of this.filter.viehhaendler) {
                    params = params.append('viehhaendler_id[]', h.viehhaendler_id);
                }
            }

            this.sub?.unsubscribe();
            this.sub = this.stammdatenService
                .ladeEinstalldaten(this.gruppe.gruppe_id, params)
                .subscribe({
                    next: (result) => {
                        this.selectedEinstalldatumCtrl.enable();
                        this.isLoading = false;
                        this.einstalldaten = result.records;

                        if (this.einstalldaten.length === 0) {
                            this.label = 'keine Einstalldaten';
                            this.selectedEinstalldatumCtrl.disable();
                        } else {
                            this.label = 'Einstalldatum';
                        }
                    },
                    error: (err) => {
                        this.isLoading = false;
                        this.messageService.errorMessage(
                            'Fehler beim Laden der Einstalldaten',
                            err
                        );
                    },
                });
        }
    }
}
