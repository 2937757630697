<button  class="icon-badge" matBadge="check" matBadgeSize="small"
        [matBadgeHidden]="!selectedSortierFilter" color="accent" mat-icon-button [matMenuTriggerFor]="sortierMenu">
    <mat-icon>sort</mat-icon>
</button>
<mat-menu style="z-index: 9995" [class]="'sortier-gruppen-menu'" #sortierMenu="matMenu">
    <span style="padding-left: 6px; color: #777">Sortierung:</span>
    <mat-divider style="width: 100%; color: #777; margin-top: 6px"></mat-divider>
    <button *ngFor="let sortierung of tiersortierfilter" mat-menu-item (click)="selectedFilter.emit(sortierung)">
        <span>{{ sortierung.displayName }}</span>
    </button>
</mat-menu>
