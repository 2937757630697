import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { MatSelectChange } from '@angular/material/select';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { ChangeCurrentClientService } from './change-current-client.service';
import { Client } from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-change-current-client',
    templateUrl: './change-current-client.component.html',
    styleUrls: ['./change-current-client.component.scss'],
})
export class ChangeCurrentClientComponent implements OnInit {

    currentClient: Client;
    clientSelectCtrl = new UntypedFormControl();
    clients: Client[] = [];

    constructor(
        private localStorageService: LocalStorageService,
        private api: KieneTableService,
        public dialog: MatDialog,
        private messageService: MessageService,
        private changeCurrentClientService: ChangeCurrentClientService
    ) {
    }

    ngOnInit() {
        this.currentClient = this.localStorageService.getCurrentClient();
        if (this.currentClient) {
            this.clientSelectCtrl.setValue(this.currentClient);
        }
        this.loadClientsForUnternehmensgruppe();

        this.localStorageService.watchCurrentClient().subscribe({
            next: (c: Client) => {
                this.currentClient = c;
                this.loadClientsForUnternehmensgruppe();
            },
        });
    }

    loadClientsForUnternehmensgruppe() {
        if (!this.currentClient) {
            this.clients = [];
            return;
        }
        this.clients = [];
        this.clients.push(this.currentClient);

        this.clientSelectCtrl.setValue(this.currentClient);
        if (this.currentClient.unternehmensgruppe_id && this.localStorageService.hasPermission(4501)) {
            const ugId = this.currentClient.unternehmensgruppe_id;
            let params = new HttpParams().set(
                'unternehmensgruppe_id',
                ugId.toString()
            );
            params = params.append('aktiv', '1');
            this.api.get(environment.BASE_URI + 'kunde/read.php', params).subscribe({
                next: (response) => {
                    this.clients = response.records;
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fehler beim Laden!',
                        err
                    );
                },
            });
        }
    }

    clickedSelect() {
        if (!this.currentClient) {
            this.openDialog();
        }
    }

    openDialog() {
        this.changeCurrentClientService.openChangeDialog('ChangeCurrentClient');
    }

    setCurrentClient(event: MatSelectChange) {
        this.localStorageService.setCurrentClient(event.value);
    }

    public deselectClient() {
        this.localStorageService.setCurrentClient(null);
    }

    clientComparator(c1: Client, c2: Client) {
        if (c1 && c2) {
            return c1.kunde_id === c2.kunde_id;
        }
        return false;
    }

    formatClientString(c: Client) {
        if (!c) {
            return 'kein Kunde';
        }
        let info = c.bezeichnung_final;
        if (c.kunde_nr_intern) {
            info += ' | ' + c.kunde_nr_intern;
        }
        if (c.zusatz_final) {
            info += ' | ' + c.zusatz_final;
        }
        if (c.vvvo) {
            info += ' | ' + c.vvvo;
        }
        if (this.localStorageService.isCurrentUserPhitoUser()) {
            info += ' (ID:K.' + c.kunde_id + '/B.' + c.betrieb_id + ')';
        }
        return info;
    }
}
