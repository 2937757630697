export class KundengutschriftPosition {
    ka_id: number;
    kundengutschrift_id: number;
    position: number;
    artikel_me: string;
    artikel_id: number;
    artikelmenge: number;
    einzelpreis: number;
    gesamtpreis: number;
    mwstsatz: number;
}

export class Kundengutschrift {
    artikel: string;
    artikel_id: number;
    betrag: number;
    datum: Date;
    editiert: Date;
    erstellt: Date;
    kundengutschrift_id: number;
    kunde: string;
    kunde_id: number;
    mwstsatz: number;
    pdf_id: number;
    primanota_id: number;
    rechnung_nr: number;
    rechnung_id: number;
    recposition: number;
    positionen: KundengutschriftPosition[] = [];
}

export class KundengutschriftRueckverguetung {
    kunde_id: number;
    von: Date;
    bis: Date;
    prozentsatz: number;
}
