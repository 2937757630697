import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {StammdatenService} from '../../stammdaten.service';
import {MessageService} from '../../../../services/message-service/message.service';
import {Tierreklamation} from '../../../gruppe-tier/classes/tierreklamation';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../../../dialogs/confirm-dialog/confirm-dialog.component';
import {Subscription} from 'rxjs';
import {ConfirmDialogInput, ConfirmDialogResult,} from '../../../dialogs/confirm-dialog/confirm-dialog';
import {EnterValueDialogComponent} from '../../../dialogs/enter-value-dialog/enter-value-dialog.component';
import {EnterValueInput, EnterValueResponse} from '../../../dialogs/enter-value-dialog/enter-value-response';
import {LocalStorageService} from '../../../../services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-tierreklamationen-uebersicht',
    templateUrl: './tier-reklamationen-uebersicht.component.html',
    styleUrls: ['./tier-reklamationen-uebersicht.component.scss'],
})
export class TierReklamationenUebersichtComponent implements OnInit {

    @Input('showReturnButton') showReturnButton = true;
    reklamationCtrl: UntypedFormControl = new UntypedFormControl(null);
    tierreklamationen: Tierreklamation[] = [];
    isLoading = true;
    isSaving = false;
    loadTierreklamationenSub: Subscription;

    constructor(
        private stammdatenService: StammdatenService,
        private messageService: MessageService,
        private dialog: MatDialog,
        public localStorageService: LocalStorageService
    ) {
    }

    ngOnInit(): void {
        this.loadTierreklamationen();
    }

    loadTierreklamationen(): void {
        this.isLoading = true;
        this.loadTierreklamationenSub?.unsubscribe();
        this.loadTierreklamationenSub = this.stammdatenService
            .getTierreklamationen(false)
            .subscribe({
                next: (tierreklamationen) => {
                    this.isLoading = false;
                    this.tierreklamationen = tierreklamationen.records;
                },
                error: (err) => {
                    this.isLoading = false;
                    this.messageService.errorMessage(
                        'Fehler beim Laden der Tierreklamationen',
                        err
                    );
                },
            });
    }

    deleteTierreklamation(event: Event, tierreklamation: Tierreklamation) {
        if (!this.localStorageService.hasPermission(9234)) {
            return;
        }
        event.stopPropagation();
        const input = new ConfirmDialogInput();
        input.headline = 'Tierreklamation löschen';
        input.text = 'Soll der Tierreklamation wirklich gelöscht werden?';
        input.okButton = 'Löschen';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '100%',
            maxWidth: '400px',
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.stammdatenService
                        .deleteTierreklamation(tierreklamation)
                        .subscribe({
                            next: () => {
                                this.loadTierreklamationen();
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler beim Löschen des Tierreklamations',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    editTierreklamation(tierreklamation: Tierreklamation) {
        this.reklamationAnlegen(tierreklamation);
    }


    zugriffFreigeben() {
        this.stammdatenService.reklamationenTeilen();
    }

    reklamationAnlegen(tierreklamation?: Tierreklamation) {
        if (!this.localStorageService.hasPermission(9232)) {
            return;
        }
        const inp = new EnterValueInput();
        inp.title = 'Tierreklamation ' + (tierreklamation ? 'bearbeiten' : 'anlegen');
        inp.addInputValue('Bezeichnung', 'text', tierreklamation?.reklamation);
        this.dialog.open(EnterValueDialogComponent, {data: inp}).afterClosed().subscribe({
            next: (result: EnterValueResponse) => {
                if (result) {
                    if (tierreklamation) {
                        tierreklamation.reklamation = result.getValue(0);
                        this.stammdatenService.updateTierreklamation(tierreklamation).subscribe({
                            next: response => {
                                this.loadTierreklamationen();
                            },
                            error: err => {
                                this.messageService.errorMessage('Fehler!', err);
                            }
                        });
                    } else {
                        this.stammdatenService
                            .addTierreklamation(result.getValue(0).trim())
                            .subscribe({
                                next: (tierreklamation) => {
                                    this.loadTierreklamationen();
                                },
                                error: (err) => {
                                    this.messageService.errorMessage(
                                        'Fehler beim Hinzufügen des Tierreklamations', err);
                                }
                            });
                    }
                }
            }
        });
    }
}
