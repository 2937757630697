<h1 mat-dialot-title>Artikelmenge korrigieren</h1>
<div mat-dialog-content>

    <h4>Chargen</h4>
    <div *ngFor="let c of abgabebelegPosition.chargen">
        <div class="one-row" style="min-width: 250px;">
            <span>{{c.charge}}</span>
            <span class="spacer"></span>
            <mat-form-field style="width: 100px;">
                <mat-label>Neue Istmenge</mat-label>
                <input matInput type="number" [max]="c.chargenmenge" [formControl]="chargenmengeCtrls[c.charge]">
            </mat-form-field>
        </div>
    </div>
    

    <br>

    <mat-form-field appearance="fill">
        <mat-label>Datum Gutschrift</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="datumCtrl">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      

</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Korrigieren</button>
</div>