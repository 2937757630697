<h1 mat-dialog-title>Ausstallen <span *ngIf="anzahl_tiere">({{ anzahl_tiere }} Tiere)</span></h1>
<div *ngIf="outstalledVieh<anzahl_tiere" style="border: 1px solid red; padding: 5px">
    <p>Von den {{ anzahl_tiere }} ausgewählten Tieren können nur {{ outstalledVieh }} Tiere der Liste hinzugefügt
        werden ({{anzahl_tiere - outstalledVieh}} Tiere sind bereits abgegangen, verendet oder in einer anderen Liste).
    </p>
</div>
<div mat-dialog-content>
    <p style="align-self: flex-start">Wohin sollen die Tiere gehen?</p>
    <div class="one-column">
        <button mat-stroked-button color="accent" (click)="saveIntern()" style="margin-top: 12px; width: 100%">Umstallen
            innerhalb meiner
            Betriebe</button>
        <button mat-stroked-button color="accent" (click)="saveExtern()" style="margin-top: 12px; width: 100%">Verkauf
            an einen
            externen
            Betrieb</button>
        <button mat-stroked-button color="accent" (click)="saveTierliste()" style="margin-top: 12px; width: 100%">In
            existierende
            Tierliste</button>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button (click)="cancel()" mat-button>Abbrechen</button>
</div>