<button mat-icon-button (click)="triggerNotificationOverlay($event)" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <mat-icon [matBadge]="ungelesen" [matBadgeHidden]="ungelesen <= 0" matBadgeColor="warn">notifications</mat-icon>
</button>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="opened">
    <div class="container-overlay">
        <h1 class="headline">Benachrichtigungen</h1>
        <kiene-benachrichtigung-uebersicht></kiene-benachrichtigung-uebersicht>
    </div>
</ng-template>
