<h2 mat-dialog-title>Betriebsart hinzufügen</h2>
<mat-dialog-content>
  <mat-form-field style="width: 100%;">
    <input type="text" matInput placeholder="Bezeichnung" [formControl]="betriebsartCtrl">
  </mat-form-field>
  <br>
  <kiene-verkaufspreis-selector (verkaufspreisSelectionChanged)="setVerkaufspreis($event)"></kiene-verkaufspreis-selector>
  <br>
  <mat-form-field>
    <input type="number" matInput placeholder="Rabatt" [formControl]="rabattCtrl">
    <span matSuffix>%</span>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
  <button mat-raised-button color="primary" (click)="save()">Speichern</button>
</mat-dialog-actions>