import { Component, OnInit, Inject } from '@angular/core';
import { ChangeBestellmengenDialogComponent } from '../../../wa-wi/bestellvorschlaege/change-bestellmengen-dialog/change-bestellmengen-dialog.component';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../services/message.service';
import { GoodsReceiptHistory } from '../goods-receipt-history.component';
import { UntypedFormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kiene-correct-amount-dialog',
  templateUrl: './correct-amount-dialog.component.html',
  styleUrls: ['./correct-amount-dialog.component.scss']
})
export class CorrectAmountDialogComponent implements OnInit {

  wareneingangshistorie: GoodsReceiptHistory;
  amountCtrl = new UntypedFormControl();

  constructor(
    public dialogRef:MatDialogRef<ChangeBestellmengenDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) private data,
    private api: KieneTableService,
    private messageService: MessageService) 
  { 
    this.wareneingangshistorie = data.grh;
  }

  ngOnInit() {
    this.amountCtrl.setValue(this.wareneingangshistorie.menge);
  }

  cancel(){
    this.dialogRef.close(false);
  }

  save(){
    const updateObject = {
      umbuchung_id: this.wareneingangshistorie.umbuchung_id,
      menge_neu: this.amountCtrl.value
    };

    this.api.updateGeneric(environment.BASE_URI + 'wareneingang/update.php', updateObject).subscribe(response => {
      this.messageService.infoMessage('Die Menge wurde erfolgreich korrigiert!');
      this.dialogRef.close(true);
    }, error => {
      this.messageService.errorMessage('Fehler beim Korrigieren aufgetreten!', error);
      this.dialogRef.close(false);
    });

  }

}
