import { Component, Inject, Input, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MessageService } from '../../../services/message-service/message.service';
import { DomSanitizer } from '@angular/platform-browser';
import { KieneFileService } from '../../../services/files/kiene-file.service';
import { PdfViewerInput } from './classes/pdf-viewer-input';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { MatDialog } from '@angular/material/dialog';
import {
    PdfViewerSendEmailDialogComponent
} from '../pdf-viewer-send-email-dialog/pdf-viewer-send-email-dialog.component';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';


@Component({
    selector: 'kiene-pdf-viewer-component',
    templateUrl: './kiene-pdf-viewer.component.html',
    styleUrls: ['./kiene-pdf-viewer.component.scss'],
})
export class KienePdfViewerComponent implements OnInit {
    pdfBase64: any;
    pdfSource: Uint8Array;
    zoomFactor = 1;
    currPage: number;
    totalPages: number;

    @Input('pdfViewerInput') pdfViewerInput: PdfViewerInput;
    pdfFile: File;

    isDownloading = false;

    constructor(
        private http: HttpClient,
        @Inject(API_BASE_URL_SERVICE) private apiUrl: string,
        private messageService: MessageService,
        private sanatizer: DomSanitizer,
        private fileService: KieneFileService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        // this.downloadPdfBase64();
        this.downloadPdfBlob();
    }

    // downloadPdfBase64() {
    //     const httpParams: HttpParams = (
    //         this.pdfViewerInput.params
    //             ? this.pdfViewerInput.params
    //             : new HttpParams()
    //     ).set('base64', 'true');
    //     this.http
    //         .get(this.apiUrl + this.pdfViewerInput.url, {
    //             responseType: 'text',
    //             params: httpParams,
    //         })
    //         .subscribe({
    //             next: (response) => {
    //                 this.pdfBase64 =
    //                     this.sanatizer.bypassSecurityTrustResourceUrl(response);
    //                 // this.convertBase64ToFile();
    //                 this.pdfSource = this.convertBase64ToBinary(
    //                     this.pdfBase64.changingThisBreaksApplicationSecurity
    //                 );
    //             },
    //             error: (error) => {
    //                 this.messageService.errorMessage('Fehler:', error);
    //             },
    //         });
    // }

    // convertBase64ToBinary(dataURI) {
    //     console.log(dataURI);
    //     const BASE64_MARKER = ';base64,';
    //     const base64Index = BASE64_MARKER.length;
    //     const base64 = dataURI.substring(base64Index);
    //     const raw = window.atob(base64);
    //     const rawLength = raw.length;
    //     const array = new Uint8Array(new ArrayBuffer(rawLength));
    //
    //     for (let i = 0; i < rawLength; i++) {
    //         array[i] = raw.charCodeAt(i);
    //     }
    //     return array;
    // }

    download() {
        if (this.pdfViewerInput.asEmail) {
            this.dialog.open(PdfViewerSendEmailDialogComponent, {
                minWidth: '95vw',
                data: this.pdfViewerInput,
            });
        } else {
            this.fileService.downloadPdfFile(
                this.pdfBase64.changingThisBreaksApplicationSecurity
            );
        }
    }

    setPages(event: PDFDocumentProxy) {
        this.totalPages = event.numPages;
        this.currPage = 1;
    }

    // private convertBase64ToFile() {
    //     const BASE64_MARKER = ';base64,';
    //     const base64Index = BASE64_MARKER.length;
    //     const base64 = this.pdfBase64.changingThisBreaksApplicationSecurity.substring(base64Index);
    //     const sliceSize = 512;
    //     const byteCharacters = atob(base64);
    //     const byteArrays = [];
    //
    //     for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    //         const slice = byteCharacters.slice(offset, offset + sliceSize);
    //
    //         const byteNumbers = new Array(slice.length);
    //         for (let i = 0; i < slice.length; i++) {
    //             byteNumbers[i] = slice.charCodeAt(i);
    //         }
    //
    //         const byteArray = new Uint8Array(byteNumbers);
    //         byteArrays.push(byteArray);
    //     }
    //
    //     const blob = new Blob(byteArrays, {type: 'application/pdf'});
    // }

    private downloadPdfBlob() {
        this.isDownloading = true;
        let httpParams: HttpParams = (
            this.pdfViewerInput.params
                ? this.pdfViewerInput.params
                : new HttpParams()
        );
        this.http
            .get(this.apiUrl + this.pdfViewerInput.url, {
                responseType: 'blob',
                params: httpParams
            })
            .subscribe({
                next: (response) => {
                    this.isDownloading = false;
                    const filename = this.pdfViewerInput.dateiname ? this.pdfViewerInput.dateiname : 'vetvet.pdf';
                    this.pdfFile = new File([response], filename, { type: response.type });
                    new Response(response).arrayBuffer().then(value => {
                        this.pdfSource = new Uint8Array(value);
                    });
                },
                error: (error) => {
                    this.isDownloading = false;
                    this.messageService.errorMessage('Fehler:', error);
                },
            });
    }
}
