<h2 mat-dialog-title>Bitte Kunden auswählen</h2>
<mat-dialog-content>
    <mat-select placeholder="Kunde" [formControl]="clientSelectCtrl">
        <mat-option *ngFor="let c of clients" [value]="c">{{c.bezeichnung_final}} - {{c.zusatz_final}}</mat-option>
    </mat-select>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
    <button mat-raised-button color="primary" (click)="choose()">Auswählen</button>
</mat-dialog-actions>
