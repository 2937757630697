<nav mat-tab-nav-bar style="margin-top: 63px">
    <a
        #betriebela="routerLinkActive"
        *ngIf="isTabMenuVisible('/stammdaten/betriebe')"
        [active]="betriebela.isActive"
        [routerLink]="['/stammdaten/betriebe']"
        mat-tab-link
        routerLinkActive
        >Betriebe</a
    >
    <!--<a #ttbla="routerLinkActive" *ngIf="isTabMenuVisible('/stammdaten/kunden')" [active]="ttbla.isActive"
       [routerLink]="['/stammdaten/kunden']"
       mat-tab-link routerLinkActive>Kunden</a>-->
    <a
        #ugla="routerLinkActive"
        *ngIf="isTabMenuVisible('/stammdaten/unternehmensgruppen')"
        [active]="ugla.isActive"
        [routerLink]="['/stammdaten/unternehmensgruppen']"
        mat-tab-link
        routerLinkActive
        >Unternehmensgruppen</a
    >
    <a
        #bala="routerLinkActive"
        *ngIf="isTabMenuVisible('/stammdaten/betriebsarten')"
        [active]="bala.isActive"
        [routerLink]="['/stammdaten/betriebsarten']"
        mat-tab-link
        routerLinkActive
        >Betriebsarten</a
    >
    <a
        #btla="routerLinkActive"
        *ngIf="isTabMenuVisible('/stammdaten/betriebstypen')"
        [active]="btla.isActive"
        [routerLink]="['/stammdaten/betriebstypen']"
        mat-tab-link
        routerLinkActive
        >Betriebstypen</a
    >
    <a
        #tala="routerLinkActive"
        *ngIf="isTabMenuVisible('/stammdaten/tierarten_rassen')"
        [active]="tala.isActive"
        [routerLink]="['/stammdaten/tierarten_rassen']"
        mat-tab-link
        routerLinkActive
        >Tierarten & Rassen</a
    >
<!--    <a-->
<!--        #tala="routerLinkActive"-->
<!--        *ngIf="isTabMenuVisible('/stammdaten/tierarten')"-->
<!--        [active]="tala.isActive"-->
<!--        [routerLink]="['/stammdaten/tierarten']"-->
<!--        mat-tab-link-->
<!--        routerLinkActive-->
<!--        >Tierarten</a-->
<!--    >-->
<!--    <a-->
<!--        #ras="routerLinkActive"-->
<!--        *ngIf="isTabMenuVisible('/stammdaten/rassen')"-->
<!--        [active]="ras.isActive"-->
<!--        [routerLink]="['/stammdaten/rassen']"-->
<!--        mat-tab-link-->
<!--        routerLinkActive-->
<!--        >Rassen</a-->
<!--    >-->
    <a
        #lla="routerLinkActive"
        *ngIf="isTabMenuVisible('/stammdaten/landkreise')"
        [active]="lla.isActive"
        [routerLink]="['/stammdaten/landkreise']"
        mat-tab-link
        routerLinkActive
        >Landkreise</a
    >
    <a
        #pla="routerLinkActive"
        *ngIf="isTabMenuVisible('/stammdaten/preisgruppen')"
        [active]="pla.isActive"
        [routerLink]="['/stammdaten/preisgruppen']"
        mat-tab-link
        routerLinkActive
        >Preisgruppen</a
    >
    <a
        #vhla="routerLinkActive"
        *ngIf="isTabMenuVisible('/stammdaten/viehhaendler')"
        [active]="vhla.isActive"
        [routerLink]="['/stammdaten/viehhaendler']"
        mat-tab-link
        routerLinkActive
        >Viehh&auml;ndler</a
    >
    <a
        #ela="routerLinkActive"
        *ngIf="isTabMenuVisible('/stammdaten/entsorger')"
        [active]="ela.isActive"
        [routerLink]="['/stammdaten/entsorger']"
        mat-tab-link
        routerLinkActive
        >Entsorger</a
    >
</nav>
<router-outlet></router-outlet>
