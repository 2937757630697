import { DatePipe, formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChartType } from 'angular-google-charts';
import { environment } from '../../../../environments/environment';
import { Vet } from '../../../administration/vet/vet';
import { AppService } from '../../../app.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { ZeitauswahlChange } from '../../zeitauswahl/zeitauswahl-change';
import { LaborMitarbeiter } from './labor-mitarbeiter';
import { CsvColumn } from '../../../csv-export/csv-export/csv-column';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { Subscription } from 'rxjs';
import { ReportUtils } from '../../report-utils';

@Component({
    selector: 'kiene-labor-mitarbeiter-report',
    templateUrl: './labor-mitarbeiter-report.component.html',
    styleUrls: ['./labor-mitarbeiter-report.component.scss'],
    animations: [
        trigger('detailExpanded', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
    ],
})
export class LaborMitarbeiterReportComponent implements OnInit, AfterViewInit {
    displayedColumns = [
        'benutzer',
        'befunde',
        'umsatz_prozent',
        'umsatz',
        'wareneinsatz',
        'wareneinsatz_prozent',
        'rohertrag',
        'rohertrag_prozent',
    ];
    dataSource = new MatTableDataSource<LaborMitarbeiter>();
    dataSourceVorjahr = new MatTableDataSource<LaborMitarbeiter>();
    @ViewChild(MatSort) sort: MatSort;
    expandedElement: LaborMitarbeiter | null;

    tieraerzte: Vet[] = [];
    aktuellerTierarzt: Vet = new Vet();
    tierarztCtrl = new UntypedFormControl();

    zeitauswahl: ZeitauswahlChange;
    vorjahreszeitraum: ZeitauswahlChange;

    rabattCtrl = new FormControl();
    ergaenzerCtrl = new FormControl();
    aktuellSubscription: Subscription;
    vorjahrSubscription: Subscription;

    chartType = ChartType.Line;
    chartColumns = [
        'Zeitraum',
        'Umsatz',
        'Wareneinsatz',
        'Rohertrag',
        'Befunde',
    ];
    chartData = [['', 0, 0, 0, 0]];

    csvColumns: CsvColumn[] = [];
    csvColumnsDetails: CsvColumn[] = [];
    csvFilename = 'Labor_Mitarbeiter.csv';

    gesamtUmsatz = 0;
    gesamtUmsatzProzent = '0';
    gesamtWareneinsatz = 0;
    gesamtWareneinsatzProzent = '0';
    gesamtRohertrag = 0;
    gesamtRohertragProzent = '0';
    gesamtBefunde = 0;

    gesamtUmsatzVorjahr = 0;
    gesamtUmsatzProzentVorjahr = '0';
    gesamtWareneinsatzVorjahr = 0;
    gesamtWareneinsatzProzentVorjahr = '0';
    gesamtRohertragVorjahr = 0;
    gesamtRohertragProzentVorjahr = '0';
    gesamtBefundeVorjahr = 0;

    isLoading: boolean[] = [];

    constructor(
        private api: KieneTableService,
        private appService: AppService,
        private datePipe: DatePipe,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.csvColumns.push(new CsvColumn('befunde', 'Befunde'));
        this.csvColumns.push(new CsvColumn('umsatz', 'Umsatz'));
        this.csvColumns.push(
            new CsvColumn('umsatz_prozent', 'Umsatz (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('wareneinsatz', 'Wareneinsatz'));
        this.csvColumns.push(
            new CsvColumn('wareneinsatz_prozent', 'Wareneinsatz (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('rohertrag', 'Rohertrag'));
        this.csvColumns.push(
            new CsvColumn('rohertrag_prozent', 'Rohertrag (%)', '%')
        );

        this.csvColumnsDetails = this.csvColumns.map((column) => ({
            ...column,
        }));
        this.csvColumnsDetails.unshift(new CsvColumn('zeitraum', 'Zeitraum'));
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    loadData() {
        this.loadDataAktuelle();
        this.loadDataVorjahr();
    }

    loadDataAktuelle() {
        this.appService.setDataLoading(true);

        let params = new HttpParams();
        params = params.set('rabatt', this.rabattCtrl.value ? '1' : '0');
        params = params.set('ergaenzer', this.ergaenzerCtrl.value ? '1' : '0');
        params = params.set('von', formatDate(this.zeitauswahl.von, 'yyyy-MM-dd', 'de'));
        params = params.set('bis', formatDate(this.zeitauswahl.bis, 'yyyy-MM-dd', 'de'));

        this.aktuellSubscription?.unsubscribe();
        this.aktuellSubscription = this.api
            .get(
                environment.BASE_URI + 'report/read_labor_mitarbeiter.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSource.data = response.records;
                    this.resetChartData();
                    this.berechneGesamtwerte();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    loadDataVorjahr() {
        this.appService.setDataLoading(true);

        let params = new HttpParams();
        params = params.set('rabatt', this.rabattCtrl.value ? '1' : '0');
        params = params.set('ergaenzer', this.ergaenzerCtrl.value ? '1' : '0');
        params = params.set('von', formatDate(this.vorjahreszeitraum.von, 'yyyy-MM-dd', 'de'));
        params = params.set('bis', formatDate(this.vorjahreszeitraum.bis, 'yyyy-MM-dd', 'de'));

        this.vorjahrSubscription?.unsubscribe();
        this.vorjahrSubscription = this.api
            .get(
                environment.BASE_URI + 'report/read_labor_mitarbeiter.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSourceVorjahr.data = response.records;
                    this.berechneGesamtwerteVorjahr();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    zeitintervalChanged(event: ZeitauswahlChange) {
        this.zeitauswahl = event;
        this.vorjahreszeitraum = ReportUtils.getVorjahreszeitraum(this.zeitauswahl);

        this.loadData();
    }

    berechneGesamtwerte() {
        this.gesamtUmsatz = 0;
        this.gesamtWareneinsatz = 0;
        this.gesamtRohertrag = 0;
        const data = this.dataSource.data;
        for (const r of data) {
            this.gesamtUmsatz += r.umsatz ? r.umsatz : 0;
            this.gesamtWareneinsatz += r.wareneinsatz ? r.wareneinsatz : 0;
            this.gesamtRohertrag += r.rohertrag ? r.rohertrag : 0;
            this.gesamtBefunde += r.befunde ? r.befunde : 0;
        }

        if (this.gesamtUmsatz > 0) {
            this.gesamtWareneinsatzProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtWareneinsatz * 100
                ) / 100
            ).toString();
            this.gesamtRohertragProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtRohertrag * 100
                ) / 100
            ).toString();
        }
    }

    berechneGesamtwerteVorjahr() {
        this.gesamtUmsatzVorjahr = 0;
        this.gesamtWareneinsatzVorjahr = 0;
        this.gesamtRohertragVorjahr = 0;
        const data = this.dataSourceVorjahr.data;
        for (const r of data) {
            this.gesamtUmsatzVorjahr += r.umsatz ? r.umsatz : 0;
            this.gesamtWareneinsatzVorjahr += r.wareneinsatz ? r.wareneinsatz : 0;
            this.gesamtRohertragVorjahr += r.rohertrag ? r.rohertrag : 0;
            this.gesamtBefundeVorjahr += r.befunde ? r.befunde : 0;
        }

        if (this.gesamtUmsatzVorjahr > 0) {
            this.gesamtWareneinsatzProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtWareneinsatzVorjahr * 100
                ) / 100
            ).toString();
            this.gesamtRohertragProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtRohertragVorjahr * 100
                ) / 100
            ).toString();
        }
    }

    expandVetGroupRow(element: LaborMitarbeiter) {
        this.expandedElement =
            this.expandedElement === element ? null : element;
        if (this.expandedElement) {
            this.isLoading[element.benutzer_id] = true;

            let params = new HttpParams();
            params = params.set('von', formatDate(this.zeitauswahl.von, 'yyyy-MM-dd', 'de'));
            params = params.set('bis', formatDate(this.zeitauswahl.bis, 'yyyy-MM-dd', 'de'));
            params = params.set(
                'aggregieren',
                this.zeitauswahl.aggregieren);
            params = params.set('benutzer_id', element.benutzer_id.toString());
            this.api
                .get(
                    environment.BASE_URI + 'report/read_labor_mitarbeiter.php',
                    params
                )
                .subscribe(
                    (response) => {
                        element.details = response.records;
                        this.isLoading[element.benutzer_id] = false;
                        this.chartData = [];
                        for (const d of element.details) {
                            this.chartData.push([
                                d.zeitraum,
                                d.umsatz ? d.umsatz : 0,
                                d.wareneinsatz ? d.wareneinsatz : 0,
                                d.rohertrag ? d.rohertrag : 0,
                                d.befunde ? d.befunde : 0,
                            ]);
                        }
                    },
                    (error) => {
                        this.messageService.errorMessage('Fehler!', error);
                        this.isLoading[element.benutzer_id] = false;
                    }
                );
        }
    }

    resetChartData() {
        this.chartData = [['', 0, 0, 0, 0]];
    }

}
