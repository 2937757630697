<h1 mat-dialog-title>Kommentar f&uuml;r Bestellung {{ be.lieferant }}</h1>
<div mat-dialog-content>
    <!--<p style="white-space: pre-wrap; margin-bottom: 12px; line-height: 1.5em;">{{be.kommentar}}</p>-->
    <div class="one-row">
        <mat-form-field style="width: 100%">
            <mat-label>Kommentar</mat-label>
            <textarea matInput [formControl]="commentCtrl" rows="10">
            </textarea>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()">Schlie&szlig;en</button>
    <button mat-flat-button (click)="saveComment(false)" color="accent">
        Speichern
    </button>
    <button mat-flat-button (click)="saveComment(true)" color="accent">
        Speichern + Mail an Verwaltung
    </button>
</div>
