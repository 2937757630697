<div class="kiene-content-page" *kienePermission="[1711]">

  <mat-toolbar>
    <mat-toolbar-row>

      <!-- <button [disabled]="!isInvoiceEditable()" mat-icon-button color="primary" matTooltip="Zwischenspeichern"
        (click)="save(51)">
        <mat-icon>save</mat-icon>
      </button> -->
      <button [disabled]="!isInvoiceEditable()" mat-icon-button color="primary" matTooltip="Rechnung freigeben"
        (click)="einzelnFreigeben()">
        <mat-icon>check_circle_outline</mat-icon>
      </button>
      <button [disabled]="!(rechnung.status_id === 53 || rechnung.status_id === 54)" mat-icon-button color="primary"
        matTooltip="Rechnung stornieren" (click)="openStornoDialog()">
        <mat-icon>cancel</mat-icon>
      </button>
      <kiene-delete-button [color]="'accent'" [disabled]="!(rechnung.status_id === 51)" (delete)="rechnungLoeschen()">
      </kiene-delete-button>
      <!-- <button [disabled]="!(rechnung.status_id === 51)" mat-icon-button color="primary" matTooltip="Rechnung l&ouml;schen"
        (click)="openDeleteDialog()">
        <mat-icon>delete</mat-icon>
      </button> -->

      <span class="kiene-spacer"></span>

      <div *ngIf="rechnungId !== 'neu'">
        <span style="display: inline-block; margin-right: 10px; font-size: 14px;">Status: </span>
        <mat-chip-list style="display: inline-block">
          <mat-chip color="primary" selected>{{rechnung.status}}</mat-chip>
        </mat-chip-list>
      </div>

    </mat-toolbar-row>
  </mat-toolbar>

  <div class="kiene-page-header">
    <div>
      <button style="display: inline-block;" (click)="goBack()" color="primary" mat-icon-button matTooltip="zurück">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <h2 style="display: inline-block; margin-left: 20px;">Rechnung
        {{rechnung.rechnung_nr ? rechnung.rechnung_nr : 'Nummer noch nicht vergeben'}}</h2>
    </div>
  </div>

  <div class="content">

    <div class="one-row">

      <div class="adressfeld">
        <p>{{rechnung.kunde}}</p>
        <p>{{rechnung.gruppe}}</p>
        <p>{{rechnung.strasse}}</p>
        <p>{{rechnung.plz}} {{rechnung.ort}}</p>
        <p>{{rechnung.zusatz}}</p>
      </div>

      <div>
        <p>Kundennummer: {{rechnung.kunde_nr_intern}}</p>
        <p>VVVO: {{rechnung.vvvo}}</p>
        <p>Rechnungsdatum: <mat-form-field>
            <input matInput [matDatepicker]="picker" [formControl]="rechnungsdatumCtrl"
              [disabled]="!isInvoiceEditable()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </p>
      </div>

    </div>

    <mat-card style="width: 99%;">
      <h3>Positionen</h3>
      <table style="width: 100%;" class="phito-table">
        <thead>
          <tr>
            <th>Pos.</th>
            <th>Datum</th>
            <th>Beleg</th>
            <th>Artikel</th>
            <th>Menge</th>
            <th>Einzelpreis</th>
            <th>Gesamtpreis</th>
            <th>Mwst. %</th>
            <!--<th>Abgabe</th>
          <th>Behandlung</th>-->
            <th>Typ</th>
            <!-- <th></th> -->
            <th *ngIf="isInvoiceEditable()"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let p of rechnung.positionen">
            <td>{{p.position}}</td>
            <td>{{p.datum | date: 'dd.MM.yyyy'}}</td>
            <td>{{p.abgabebeleg_nr ? p.abgabebeleg_nr : p.tagebuch_nr}}</td>
            <td>{{p.artikel}}</td>
            <td>{{p.artikelmenge | number: '1.2-2'}}</td>
            <td>{{p.einzelpreis | number: '1.2-2'}} &euro;</td>
            <td>{{p.artikelmenge * p.einzelpreis | number: '1.2-2'}} &euro;</td>
            <td>{{p.mwstsatz}}</td>
            <td>{{getTyp(p)}}</td>
            <!-- <td><mat-icon>{{p.aa_id ? 'description' : (p.ba_id ? 'biotech' : '')}}</mat-icon></td> -->
            <!--<td><mat-checkbox [(ngModel)]="p.abgabe" (change)="abgabeChanged(p, $event)"></mat-checkbox></td>
          <td><mat-checkbox [(ngModel)]="p.behandlung" (change)="behandlungChanged(p, $event)"></mat-checkbox></td>-->
            <td *ngIf="isInvoiceEditable()">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <!-- <button mat-menu-item (click)="editPosition(p)">
                  <mat-icon>edit</mat-icon>
                  <span>Bearbeiten</span>
                </button>
                <button mat-menu-item (click)="deletePosition(p)">
                  <mat-icon>delete</mat-icon>
                  <span>L&ouml;schen</span>
                </button> -->
                <button *ngIf="p.abgabebeleg_id || p.befund_id" mat-menu-item (click)="showDocument(p)">
                  <mat-icon>{{p.abgabebeleg_id ? 'description' : (p.befund_id ? 'biotech' : '')}}</mat-icon>
                  <span>{{p.abgabebeleg_id ? 'Abgabebeleg anzeigen' : (p.befund_id ? 'Befundmitteilung anzeigen' :
                    '')}}</span>
                </button>

              </mat-menu>
            </td>
          </tr>
          <tr>
            <td colspan="4" style="border-bottom: 0px;"></td>
            <td colspan="2" style="border-bottom: 0px; text-align: right">Gesamt:
              {{getRechnungssumme() | number: '1.2-2'}} &euro;</td>
            <td colspan="2" style="border-bottom: 0px;"></td>
          </tr>
          <tr *ngFor="let m of getMwstSaetze()">
            <td colspan="4" style="border-bottom: 0px;"></td>
            <td colspan="2" style="border-bottom: 0px; text-align: right">MwSt {{m.mwst}}%: {{m.betrag | number:
              '1.2-2'}} &euro;
            </td>
            <td colspan="2" style="border-bottom: 0px;"></td>
          </tr>
          <tr>
            <td colspan="4" style="border-bottom: 0px;"></td>
            <td colspan="2" style=" text-align: right">Rechnungsbetrag:
              {{getRechungsbetrag() | number: '1.2-2'}} &euro;</td>
            <td colspan="2" style="border-bottom: 0px;"></td>
          </tr>
        </tbody>
      </table>
    </mat-card>
    <div class="one-row" style="margin-top: 24px;">
      <span class="spacer"></span>
      <button *ngIf="rechnung.status_id === 51" mat-raised-button (click)="einzelnFreigeben()" color="primary">
        <mat-icon>check_circle_outline</mat-icon> Rechnung
        freigeben
      </button>
      <!-- <button *ngIf="rechnung.status_id === 51" mat-raised-button (click)="save(51)" color="primary"><mat-icon>save</mat-icon> Rechnung
        speichern</button> -->
    </div>
  </div>

</div>