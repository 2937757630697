import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Gruppe } from '../../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import { ActivatedRoute, Router } from '@angular/router';
import {
    GruppeEinstallenDialogComponent
} from '../../../gruppe-details/gruppe-einstallen-dialog/gruppe-einstallen-dialog.component';
import {
    GruppeEinstallenDialogInsert
} from '../../../gruppe-details/gruppe-einstallen-dialog/gruppe-einstallen-dialog-insert';
import { GruppenService } from '../../../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import { MessageService } from '../../../../services/message.service';


@Component({
    selector: 'kiene-gruppen-dashboard-select-action-dialog',
    templateUrl: './gruppen-dashboard-select-action-dialog.component.html',
    styleUrls: ['./gruppen-dashboard-select-action-dialog.component.scss']
})
export class GruppenDashboardSelectActionDialogComponent implements OnInit {

    gruppe: Gruppe;
    isLoading = true;
    constructor(public dialogRef: MatDialogRef<GruppenDashboardSelectActionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: Gruppe, private router: Router, private route: ActivatedRoute,
        private dialog: MatDialog, private gruppenService: GruppenService, private messageService: MessageService) {
        this.gruppe = data;
        this.loadGruppe(this.gruppe.gruppe_id);
        console.log('GROUP: ', this.gruppe);
    }
    ngOnInit(): void {
    }

    navigateTo(type: string) {
        if (type === 'gruppe') {
            console.log(this.gruppe);
            this.router.navigate(
                ['gruppen/aktuelle', this.gruppe.gruppe_id]).then(() => this.dialogRef.close());
        }
        if (type === 'einstallen') {
            this.dialog.open(GruppeEinstallenDialogComponent, {
                minWidth: '25vw',
                data: new GruppeEinstallenDialogInsert(this.gruppe, true)
            });
            this.dialogRef.close();
        }
    }

    private loadGruppe(gruppe_id: number) {
        this.gruppenService.getGruppeKundenportal(gruppe_id).subscribe({
            next: (gruppe: Gruppe) => {
                this.isLoading = false;
                this.gruppe = gruppe;
                console.log('IS NOW:', this.gruppe);
            },
            error: (error) => {
                this.isLoading = false;
                this.messageService.errorMessage('Fehler beim Laden der Gruppe!', error);
            }
        });
    }
}
