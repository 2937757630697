import { HttpParams } from "@angular/common/http";

export class TierSortierFilter {
    displayName: string;
    param: HttpParams;

    constructor(displayName: string, param: HttpParams) {
        this.displayName = displayName;
        this.param = param;
    }

    static getAvailableFilter() {
        return [
            new TierSortierFilter(
                "letzte 3 Ohrmarkenziffern aufsteigend",
                new HttpParams().set(
                    "orderby",
                    "Right(ohrmarke15,3),ohrmarke15 asc"
                )
            ),
            new TierSortierFilter(
                "letzte 3 Ohrmarkenziffern absteigend",
                new HttpParams().set(
                    "orderby",
                    "Right(ohrmarke15,3),ohrmarke15 desc"
                )
            ),
            new TierSortierFilter(
                "letzte 5 Ohrmarkenziffern aufsteigend",
                new HttpParams().set(
                    "orderby",
                    "Right(ohrmarke15,5),ohrmarke15 asc"
                )
            ),
            new TierSortierFilter(
                "letzte 5 Ohrmarkenziffern absteigend",
                new HttpParams().set(
                    "orderby",
                    "Right(ohrmarke15,5),ohrmarke15 desc"
                )
            ),
            new TierSortierFilter(
                "Rasse aufsteigend",
                new HttpParams().set("orderby", "rasse asc")
            ),
            new TierSortierFilter(
                "Rasse absteigend",
                new HttpParams().set("orderby", "rasse desc")
            ),
            new TierSortierFilter(
                "Geschlecht aufsteigend",
                new HttpParams().set("orderby", "geschlecht_id asc")
            ),
            new TierSortierFilter(
                "Geschlecht absteigend",
                new HttpParams().set("orderby", "geschlecht_id desc")
            ),
            new TierSortierFilter(
                "Abteil aufsteigend",
                new HttpParams().set(
                    "orderby",
                    "abteil_string asc,abteil_number asc"
                )
            ),
            new TierSortierFilter(
                "Abteil absteigend",
                new HttpParams().set(
                    "orderby",
                    "abteil_string desc,abteil_number desc"
                )
            ),
            new TierSortierFilter(
                "Alter aufsteigend",
                new HttpParams().set("orderby", "alter_tage asc")
            ),
            new TierSortierFilter(
                "Alter absteigend",
                new HttpParams().set("orderby", "alter_tage desc")
            ),
            new TierSortierFilter(
                "Transpondernr. aufsteigend",
                new HttpParams().set("orderby", "transpondernummer asc")
            ),
            new TierSortierFilter(
                "Transpondernr. absteigend",
                new HttpParams().set("orderby", "transpondernummer desc")
            ),
        ];
    }
}
