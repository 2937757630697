<div class="kiene-content-page" *kienePermission="[1711]">

  <div class="kiene-table-header">
    <div>
      <button style="display: inline-block;" (click)="goBack()" mat-icon-button matTooltip="zurück">
        <mat-icon color="primary">arrow_back</mat-icon>
      </button>
      <h2 style="display: inline-block; margin-left: 20px;">Lieferant {{supplier.bezeichnung}}</h2>
    </div>
  </div>
  <div>
    <div class="kiene-table-header">
      <mat-card style="width: 100%;">
        <div style="padding-bottom: 30px;">
          <mat-tab-group [selectedIndex]="selectedTab">

            <mat-tab label="Allgemein">

              <h4>Allgemein</h4>
              <mat-form-field style="width: 40%; margin-right: 1%;">
                <input type="text" placeholder="Name des Lieferanten" matInput [(ngModel)]="supplier.bezeichnung">
              </mat-form-field>
              <mat-form-field style="width: 40%; margin-right: 1%;">
                <input type="text" placeholder="Interne Lieferantennummer" matInput
                  [(ngModel)]="supplier.lieferant_nr_intern">
              </mat-form-field>
              <br>
              <mat-form-field style="width: 40%; margin-right: 1%;">
                <input type="text" placeholder="Bezeichnung 1" matInput [(ngModel)]="supplier.bezeichnung_1">
              </mat-form-field>
              <mat-form-field style="width: 40%; margin-right: 1%;">
                <input type="text" placeholder="Bezeichnung 2" matInput [(ngModel)]="supplier.bezeichnung_2">
              </mat-form-field>
              <mat-form-field style="width: 40%; margin-right: 1%;">
                <input type="text" placeholder="Bezeichnung 3" matInput [(ngModel)]="supplier.bezeichnung_3">
              </mat-form-field>
              <mat-form-field style="width: 40%; margin-right: 1%;">
                <input type="text" placeholder="Bezeichnung Kurz" matInput [(ngModel)]="supplier.bezeichnung_kurz">
              </mat-form-field>
              <br>
              <mat-checkbox [formControl]="nebenkostenCtrl">Nebenkostenlieferant</mat-checkbox>

              <h4>Anschrift</h4>
              <mat-form-field style="width: 81%; margin-right: 1%;">
                <input type="text" placeholder="Straße" matInput [(ngModel)]="supplier.strasse">
              </mat-form-field>

              <mat-form-field style="width: 40%; margin-right: 1%;">
                <input type="text" placeholder="PLZ" matInput [(ngModel)]="supplier.plz">
              </mat-form-field>

              <mat-form-field style="width: 40%; margin-right: 1%;">
                <input type="text" placeholder="Ort" matInput [(ngModel)]="supplier.ort">
              </mat-form-field>

              <mat-form-field style="width: 27%; margin-right: 1%;">
                <input type="email" placeholder="E-Mail Adresse" matInput [formControl]="emailCtrl">
                <span matPrefix>
                  <mat-icon>email</mat-icon>&nbsp;&nbsp;
                </span>
                <button mat-button *ngIf="emailCtrl.value" matSuffix mat-icon-button aria-label="Clear"
                  (click)="clearEmail()">
                  <mat-icon>close</mat-icon>
                </button>
                <mat-error *ngIf="emailCtrl.invalid">E-Mail Adresse ungültig</mat-error>
              </mat-form-field>

              <mat-form-field style="width: 27%; margin-right: 1%;">
                <span matPrefix>
                  <mat-icon>local_phone</mat-icon>&nbsp;&nbsp;
                </span>
                <input type="text" placeholder="Telefon" matInput [(ngModel)]="supplier.telefon">
              </mat-form-field>

              <mat-form-field style="width: 25%; margin-right: 1%;">
                <span matPrefix>
                  <mat-icon>phone_iphone</mat-icon>&nbsp;&nbsp;
                </span>
                <input type="text" placeholder="Mobil" matInput [(ngModel)]="supplier.mobil">
              </mat-form-field>

              <mat-form-field style="width: 40%; margin-right: 1%;">
                <span matPrefix>
                  <mat-icon>print</mat-icon>&nbsp;&nbsp;
                </span>
                <input type="text" placeholder="Fax" matInput [(ngModel)]="supplier.fax">
              </mat-form-field>

              <mat-checkbox style="padding-top: 10px;" [formControl]="faxBevorzugenCtrl">Fax gegen&uuml;ber E-Mail
                bevorzugen?</mat-checkbox>

              <br>
              <div *kienePermission="[1713]">
                <button *ngIf="isCreationForm" style="margin-top: 40px;" mat-flat-button color="accent"
                  (click)="updateAllgemein()">Anlegen</button>

                <button *ngIf="!isCreationForm" style="margin-top: 40px;" mat-flat-button color="accent"
                  (click)="updateAllgemein()">Speichern</button>
              </div>
            </mat-tab>

            <mat-tab label="Zahlungsbedingungen" [disabled]="isCreationForm">
              <h4>Zahlungsbedingungen</h4>
              <mat-form-field>
                <mat-label>Skonto (%)</mat-label>
                <input matInput type="number" [(ngModel)]="supplier.skonto">
              </mat-form-field><br>
              <!--<mat-form-field>
                  <mat-label>Rabatt in %</mat-label>
                  <input matInput type="number" [(ngModel)]="supplier.rabatt">
                </mat-form-field><br>-->
              <mat-form-field>
                <mat-label>Zahlungsziel (Tage)</mat-label>
                <input matInput type="number" [(ngModel)]="supplier.zahlungsziel">
              </mat-form-field>
              <br>

              <mat-radio-group [(ngModel)]="supplier.zahlungsart" aria-label="Zahlungsart auswählen">
                <mat-label>Zahlungsart: </mat-label>
                <mat-radio-button [value]="1">Lastschrift</mat-radio-button>
                <mat-radio-button [value]="2">&Uuml;berweisung</mat-radio-button>
              </mat-radio-group>

              <br>

              <div *kienePermission="[1713]" style="margin-top: 24px;">
                <button mat-flat-button color="accent" (click)="updateZahlung()">Speichern</button>
              </div>

            </mat-tab>

            <mat-tab label="FiBu" [disabled]="isCreationForm">
              <h4 style="margin-top: 24px;">FiBu-Konten</h4>
              <mat-form-field style="margin-right: 12px; min-width: 300px;">
                <mat-label>Buchungskonto (Kreditor)</mat-label>
                <input matInput [(ngModel)]="supplier.buchungskonto">
              </mat-form-field>
              <br>
              <mat-form-field style="min-width: 300px;">
                <mat-label>Gegenkonto</mat-label>
                <input matInput [(ngModel)]="supplier.buchungsgegenkonto">
              </mat-form-field>
              <br>
              <div *kienePermission="[1713]" style="margin-top: 24px;">
                <button mat-flat-button color="accent" (click)="updateFibu()">Speichern</button>
              </div>
            </mat-tab>

          </mat-tab-group>
        </div>
      </mat-card>
    </div>
  </div>
</div>