<kiene-kiene-table (optionClicked)="catchOptionEvent($event)" [tableDescriptor]="tableDescriptor">
    <div toolbarElementCenter>
        <ng-container
            *ngIf="typCtrl?.value === 1 || (typCtrl?.value === 2 && localStorageService.isCurrentUserVeterinarian() && offeneImpfkontrollbuecher > 0)">
            <button mat-stroked-button color="accent" (click)="alleAbschliessen()">
                Alle {{ typCtrl?.value === 1 ? 'Bestandsbücher' : 'Impfkontrollbücher' }} abschlie&szlig;en
            </button>
        </ng-container>
    </div>
    <div toolbarElementTop>
        <mat-radio-group [formControl]="typCtrl" class="radio-group">
            <mat-radio-button [value]="0">Alle</mat-radio-button>
            <mat-radio-button [value]="1">Bestandsb&uuml;cher</mat-radio-button>
            <mat-radio-button [value]="2">Impfkontrollb&uuml;cher</mat-radio-button>
        </mat-radio-group>
    </div>
</kiene-kiene-table>
