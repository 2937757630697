import { Client } from '../../../../projects/kiene-core/src/lib/components/client/classes/client';

export class User {
    benutzer_id: number;
    titel = '';
    vorname = '';
    nachname;
    string = '';
    email = '';
    mobil: string;
    telefon: string;
    passwort: string;

    strasse = '';
    plz = '';

    benutzerrollen: UserRole[] = [];

    erstellt: Date;
    admin: number;

    elemente_pro_seite: number;
    info_bestandsbesuchsprotokoll: number;
    info_antibiogramme: number;
    abgabebeleg_duplikat: number;
    identifikation_aus_historie: number;
    abgabebeleg_artikeleingabe_aus_historie: number;
    automatische_chargenauswahl: number;
    nur_chargen_aus_wagen: number;
    immer_unterschreiben: number;
    tierarzt_id: number;
    tierarztgruppe_id: number;

    unterschrift_png: string;

    kunden: Client[] = []; // nur für Anzeige im Benutzerprofil
    sende_email: number;
}

export class UserAdmin {
    benutzer_id: number;
    email_adresse: string;
    email_smtp: string;
    email_passwort: string;
    email_nutzer: string;
    email_port: number;
    email_kopie: number;
}

export interface UserApi {
    records: User[];
    count: number;
}

export interface UserAdminApi {
    records: UserAdmin[];
    count: number;
}

export class UserClient {
    bk_id: number;
    benutzer_id: number;
    nachname: string;
    vorname: string;
    kunde_id: number;
    kunde: string;
    zusatz: string;
    kommentar: string;
    erstellt: Date;
    erstellt_von: number;
    editiert: Date;
    editiert_von: number;
}

export interface UserClientApi {
    records: UserClient[];
    count: number;
}

export class UserRole {
    apotheke: number;
    benutzerrolle_id: number;
    bezeichnung: string;
    erstellt: Date;
    kunde: number;
    labor: number;
    praxis: number;
    tierarzt: number;
    verwaltung: number;
    viehhaendler: number;
    schlachthof: number;
    spediteur: number;
    sgs: number;
    admin_der_rolle: number;
}

export interface UserRoleApi {
    records: UserRole[];
    count: number;
}

export class UserMandant {
    bm_id: number;
    benutzer_id: number;
    nachname: string;
    vorname: string;
    mandant_id: number;
    bezeichnung: string;
    erstellt: Date;
    editiert: Date;
}

export interface UserMandantApi {
    records: UserMandant[];
    count: number;
}
