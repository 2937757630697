import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionInfoComponent } from './version-info.component';
import { MatCardModule } from '@angular/material/card';



@NgModule({
  declarations: [
    VersionInfoComponent
  ],
  imports: [
    CommonModule,
    MatCardModule
  ]
})
export class VersionInfoModule { }
