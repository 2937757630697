export class UntersuchungsauftragPosition {
    ua_id: number;
    artikel_id: number;
    artikel: string;
    anzahl: number;
    notiz: string;
    erstellt: Date;
    editiert: Date;
}

export class Untersuchungsauftrag {
    untersuchungsauftrag_id: number;
    untersuchungsmaterial_id: number;
    material: number;
    lieferant_id: number;
    lieferant: string;
    labor: string;
    status_id: number;
    gruppe_id: number;
    gruppe: string;
    kunde_id: number;
    status: string;
    pdf_id: number;
    kennzeichnung: string;
    datum: Date;
    erstellt: Date;
    editiert: Date;
    positionen: UntersuchungsauftragPosition[] = [];
    tagebuch_nr: string;
    tierarzt_id: number;
    tierarzt: string;
    befund_id: number;
}

export class UntersuchungsauftragDokument {
    ud_id: number;
    untersuchungsauftrag_id: number;
    pdf_id: number;
    dateiname: string;
    erstellt: Date;
    editiert: number;
}
