import {Benachrichtigung} from './benachrichtigung';

export class BenachrichtigungClickEvent {
    datum: Date;
    benachrichtigung: Benachrichtigung;

    constructor(benachrichtigung: Benachrichtigung) {
        this.datum = new Date();
        this.benachrichtigung = benachrichtigung;
    }
}
