import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {finalize, Observable, of} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap,} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {
    MatAutocompleteActivatedEvent,
    MatAutocompleteSelectedEvent,
    MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import {MatInput} from '@angular/material/input';
import {KieneBackendApiService} from '../../services/backend-api/kiene-backend-api.service';
import {MessageService} from '../../services/message-service/message.service';

@Component({
    selector: 'kiene-autocomplete',
    templateUrl: './kiene-autocomplete.component.html',
    styleUrls: ['./kiene-autocomplete.component.scss'],
    host: {'(window:keydown)': 'checkForKeyShortcut($event)'}
})
export class KieneAutocompleteComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('inputAutocomplete') inputAutocomplete: MatInput;
    @ViewChild(MatAutocompleteTrigger) auto: MatAutocompleteTrigger;

    formControl = new UntypedFormControl();
    isLoading = false;

    @Input('kieneObject') kieneObject: any;
    @Input('showLoadingIndicator') showLoadingIndicator = false;

    @Input('displayField') displayField = 'bezeichnung';

    @Input('displayFields') displayFields: string[] = [];

    @Input('mapperDisplayFields') mapperDisplayFields: string[] = [];

    @Input('separator') separator = ' ';

    @Input('apiUrl') apiUrl: string;

    @Input('genericApiUrl') genericApiUrl: string;

    @Input('params') params: HttpParams;

    @Input('placeholder') placeholder = 'Bezeichnung';

    @Input('panelWidth') panelWidth = '50%';

    @Input('focus') focus = true;

    @Input('presetSelected') presetSelected = true;

    @Input('disabled') disabled = false;

    @Input('required') required = false;

    @Input('clearAfterSelect') clearAfterSelect = false;

    @Input('resetValue') resetValue: string;

    @Input('showClear') showClear = true;

    @Input('limit') limit = 50;

    @Input('emitFreeText') emitFreeText = false;

    @Input('multiLine') multiLine = false;

    @Input('type') type = 'text';

    @Input('inputmode') inputmode = '';

    @Input('showDeleteButton') showDeleteButton = false;

    @Input('noPaddingNoMargin') noPaddingNoMargin = false;

    @Input('emitButton') emitButton: string;

    @Input('createNewAllowed') createNewAllowed = false;

    @Input('createNewText') createNewText = 'Neuen Antrag anlegen';

    @Output('elementSelected') elementSelected = new EventEmitter();

    @Output('deleteSelected') deleteSelected = new EventEmitter();

    @Output('optionActivated') optionActivated = new EventEmitter();

    @Output('createNew') createNewEmitter = new EventEmitter<string>();

    options: Observable<any[]>;

    optionSelected = false;

    activatedObject: any;
    activatedObjectSelected = false;

    createObj: any;

    constructor(
        private api: KieneBackendApiService,
        private messageService: MessageService
    ) {

    }

    ngOnInit() {

    }

    ngOnChanges() {

        if (this.kieneObject) {

            this.formControl.setValue(this.kieneObject, {emitEvent: false}); // neu
        }

        if (this.disabled) {
            this.formControl.disable({emitEvent: false}); // neu
        } else {
            this.formControl.enable({emitEvent: false}); // neu
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.autocomplete('ngAfterViewInit');
            if (this.focus) {
                this.inputAutocomplete?.focus();
            }
            if (this.kieneObject) {

                this.formControl.setValue(this.kieneObject);
                if (this.focus) {
                    this.inputAutocomplete?.focus();
                    if (this.presetSelected) {
                        // this.inputAutocomplete.nativ
                    }
                }

            }

            if (this.required) {
                this.formControl.setValidators(Validators.required);
            }

            if (this.disabled) {
                this.formControl.disable();
            } else {
                this.formControl.enable();
            }
        }, 1);
    }

    clear() {
        this.formControl.setValue('');
        this.activatedObjectSelected = false;
        this.elementSelected.emit(null);
        this.autocomplete('clear');
        this.formControl.markAsUntouched();
    }

    deleteElement(event, option?: any) {
        event.stopPropagation();
        this.deleteSelected.emit(option);
    }

    public clearAndFocus() {
        this.formControl.setValue('');
        this.activatedObjectSelected = false;
        this.elementSelected.emit(null);
        this.autocomplete('clearAndFocus');
        this.inputAutocomplete?.focus();
        this.formControl.markAsUntouched();

    }

    public setValue(value: any, emit: boolean) {
        this.formControl.setValue(value, {emitEvent: emit});
        this.activatedObjectSelected = false;
    }

    autocomplete(sender: string) {
        console.log('autocomplete ' + sender);
        this.options = this.formControl.valueChanges.pipe(
            startWith(''),
            switchMap((value) => {
                if (this.isObject(value)) {
                    value = '';
                }
                this.activatedObjectSelected = false;
                return this.search(value);
            })
        );
    }

    search(value: string): Observable<any> {
        // if (value === null || value === undefined || value === '') {
        //   return of();
        // }
        let params: HttpParams;
        if (this.params) {
            params = this.params;
        } else {
            params = new HttpParams();
        }
        params = params.append('search', value ? value : '');

        if (this.limit) {
            params = params.append('limit', this.limit.toString());
        }

        let url;
        if (this.genericApiUrl) {
            url = this.genericApiUrl;
        } else {
            url = this.apiUrl + 'read.php';
        }

        this.isLoading = true;
        return this.api.get(url, params)
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                map((response) => response.records),
                finalize(() => this.isLoading = false),
                catchError((error) => {
                    this.isLoading = false;
                    this.messageService.errorMessage('Es ist ein Fehler aufgetreten!', error);
                    return of();
                })
            );
    }

    mapper(ko?: any) {
        if (!ko) {
            return '';
        }

        if (typeof ko === 'string') {
            return ko;
        }

        if (this.mapperDisplayFields.length > 0) {
            let field = '';
            for (const d of this.mapperDisplayFields) {
                if (ko[d]) {
                    field += ko[d] + ' ';
                }
            }
            return field;
        } else if (this.displayFields.length > 0) {
            let field = '';
            for (const d of this.displayFields) {
                if (ko[d]) {
                    field += ko[d] + ' ';
                }
            }
            return field;
        }

        return ko[this.displayField];
    }

    emitSelectedElement(event: MatAutocompleteSelectedEvent) {
        console.log('selected', event.option.value);
        const elem = event.option.value;
        if (elem && elem !== '') {
            this.optionSelected = true;
        }
        this.elementSelected.emit(elem);
        if (this.clearAfterSelect && elem !== '') {
            this.formControl.setValue(null, {emitEvent: false});
        }
    }

    setActivatedObject(event: MatAutocompleteActivatedEvent) {
        this.activatedObject = event.option.value;
        this.activatedObjectSelected = true;
    }

    onBlur() {
        if (this.emitFreeText && !this.optionSelected) {
            this.emitTypedText();

        }
        if (this.activatedObjectSelected) {
            this.elementSelected.emit(this.activatedObject);
            this.activatedObjectSelected = false;
        }
    }

    isObject(obj: any): boolean {
        const type = typeof obj;
        return type === 'function' || (type === 'object' && !!obj);
    }

    getValue(obj: any): string {
        if (this.displayFields.length > 0) {
            let s = '';
            let i = 0;
            for (const df of this.displayFields) {
                const txt = obj[df];
                if (txt) {
                    if (i > 0) {
                        s += this.separator;
                    }
                    s += txt;
                    i++;
                }
            }
            return s;
        } else {
            return obj[this.displayField];
        }
    }

    markAsUntouched() {
        this.formControl.markAsUntouched();
    }

    setError(error: string) {
        this.formControl.markAsTouched();
        this.formControl.setErrors({
            [error]: true,
        });
    }

    public reload() {
        this.autocomplete('reload');
    }

    isValid() {
        this.formControl.markAsTouched();
        return this.formControl.valid;
    }

    public emitCurrentValue() {
        const typedValue = this.formControl.value;
        if (typedValue) {
            this.elementSelected.emit(typedValue);
            this.optionSelected = false;
            if (this.clearAfterSelect) {
                this.formControl.setValue(null);
            }
        }
    }

    public emitTypedText() {
        const typedValue = this.formControl.value;
        if (typedValue !== undefined && typedValue !== null) {
            this.elementSelected.emit(typedValue);
            this.optionSelected = false;
            if (this.clearAfterSelect) {
                this.clear();
            }
        }
    }

    checkKeys(event: KeyboardEvent) {
        if (event.altKey && event.key === 'Enter' && this.showCreateNewOption()) {
            this.emitCreateNewObj();
        }
    }

    showCreateNewOption(): boolean {
        return this.createNewAllowed && !this.isLoading;
    }

    emitCreateNewObj() {
        // this.setCreateObj();
        this.auto.closePanel();
        this.createNewEmitter.emit(this.createObj);
    }

    setCreateObj() {
        this.createObj = this.inputAutocomplete.value + '';
    }

    checkForKeyShortcut(event: KeyboardEvent) {
        if (event.altKey && event.code === 'Enter') {
            this.emitCreateNewObj();
        }
    }

    closePanel() {
        this.auto.closePanel();
    }
}
