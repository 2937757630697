import { Component, OnInit } from '@angular/core';
import { TableDescriptor } from '../../components/kiene-table/table-descriptor';
import { environment } from '../../../environments/environment';
import { KieneBooleanTableColumn, TableColumn } from '../../components/kiene-table/table-column';
import { TableOption, TableOptionEvent } from '../../components/kiene-table/table-option';
import { Tierart } from './tierart';
import { MatDialog } from '@angular/material/dialog';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../services/message.service';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { TierartDialogComponent } from './tierart-dialog/tierart-dialog.component';

@Component({
  selector: 'kiene-tierarten',
  templateUrl: './tierarten.component.html',
  styleUrls: ['./tierarten.component.scss']
})
export class TierartenComponent implements OnInit {

  tableDescriptor = new TableDescriptor();

  constructor(public dialog: MatDialog,
    private api: KieneTableService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.tableDescriptor.headline = 'Tierarten';
    this.tableDescriptor.uniqueIdentifier = 'Tierarten';
    this.tableDescriptor.apiUrl = environment.BASE_URI + 'tierart/';
    this.tableDescriptor.standardCreate = false; // nur phito legt Tierarten an

    const columns: TableColumn[] = [];

    columns.push(new TableColumn('bezeichnung', 'Tierart'));
    columns.push(new TableColumn('kuerzel', 'Kürzel'));
    columns.push(new KieneBooleanTableColumn('mnp_rind', 'MNP Rind'));
    columns.push(new KieneBooleanTableColumn('ueber_8_monate', '> 8 Monate'));
    columns.push(new KieneBooleanTableColumn('mnp_schwein', 'MNP Schwein'));
    columns.push(new KieneBooleanTableColumn('ueber_30_kg', '> 30 KG'));
    columns.push(new KieneBooleanTableColumn('hit', 'HIT'));
    columns.push(new KieneBooleanTableColumn('qs', 'QS'));

    this.tableDescriptor.columns = columns;

    this.tableDescriptor.initialSortColumn = 'tierart';
    this.tableDescriptor.initialSortDirection = 'asc';
    this.tableDescriptor.defaultPageSize = 10;

    this.tableDescriptor.showDetails = false;

    this.tableDescriptor.options.push(new TableOption('edit', 'Bearbeiten', 'edit', 2023));
    this.tableDescriptor.options.push(new TableOption('delete', 'Löschen', 'delete', 2024));
  }

  optionClicked(event: TableOptionEvent) {
    console.debug('Table Option: ' + event.name);
    if (event.name === 'add') {
      this.openTierartDialog(null);
    } else if (event.name === 'edit') {
      this.openTierartDialog(event.value);
    } else if (event.name === 'delete') {
      this.openDeleteDialog(event.value);
    }
  }

  openTierartDialog(tierart: Tierart) {
    const dialogRef = this.dialog.open(TierartDialogComponent, {
      width: '500px',
      data: {
        tierart: tierart
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      this.api.reload();
    });
  }

  openDeleteDialog(tierart: Tierart) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '500px',
      data: {
        title: tierart.tierart + ' löschen?',
        text: 'Wollen Sie die Tierart ' + tierart.bezeichnung + ' wirklich löschen?'
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.api.deleteEntity(environment.BASE_URI + 'tierart/delete.php', tierart).subscribe(result => {
          this.messageService.infoMessage('Die Tierart wurde erfolgreich gelöscht!');
          this.api.reload();
        }, error => {
          this.messageService.errorMessage('Fehler! Es ein Problem beim Löschen der Tierart aufgetreten! ', error);
        });
      }
    }, error => { });
  }

}
