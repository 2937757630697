import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Haendler} from '../../stammdaten/haendler/haendler';
import {Tierliste} from '../../gruppe-tier/classes/tierliste';

export class AbgangslisteReload {
    tierliste: Tierliste;
    reloadAbgangsliste: boolean; // tab 3
    reloadGruppenTiere: boolean; // tab 1

    constructor(tierliste: Tierliste, reloadAbgangsliste: boolean, reloadGruppenTiere: boolean) {
        this.tierliste = tierliste;
        this.reloadAbgangsliste = reloadAbgangsliste;
        this.reloadGruppenTiere = reloadGruppenTiere;
    }
}

@Injectable({
    providedIn: 'root',
})
export class GruppeAbgangService {
    abgangSubject = new Subject<number>();
    abgaenge = 0;

    abgangdatum: Date;
    ausstallgewicht: number;

    abnehmerSubject: Subject<Haendler> = new Subject<Haendler>();
    abnehmer: Haendler;
    abgangslisteSubject = new Subject<AbgangslisteReload>();
    alleTiereHinzugefuegtSubject = new Subject<boolean>();

    tierListeAbgeschlossenSubject = new Subject<Tierliste>();

    constructor() {
    }

    private _tierliste: Tierliste;

    get tierliste(): Tierliste {
        return this._tierliste;
    }

    set tierliste(value: Tierliste) {
        this._tierliste = value;
    }

    private _abteilAbgang: string;

    get abteilAbgang(): string {
        return this._abteilAbgang;
    }

    set abteilAbgang(value: string) {
        this._abteilAbgang = value;
    }

    addAbgang(n: number) {
        this.abgaenge += n;
        this.abgangSubject.next(this.abgaenge);
    }

    watchAlleTiereHinzugefuegt(): Observable<boolean> {
        return this.alleTiereHinzugefuegtSubject.asObservable();
    }

    alleTiereHinzugefuegt() {
        this.alleTiereHinzugefuegtSubject.next(true);
    }

    setAbgaenge(n: number) {
        this.abgaenge = n;
        this.abgangSubject.next(this.abgaenge);
    }

    watchAbgaenge(): Observable<number> {
        return this.abgangSubject.asObservable();
    }

    setAbgangsliste(tierliste: Tierliste, reloadAbgangsliste: boolean, reloadGruppenTiere: boolean) {
        this.tierliste = tierliste;
        const obj = new AbgangslisteReload(tierliste, reloadAbgangsliste, reloadGruppenTiere);
        this.abgangslisteSubject.next(obj);
    }

    watchAbgangsliste(): Observable<AbgangslisteReload> {
        return this.abgangslisteSubject.asObservable();
    }

    public setAbgangdatum(abgangDatum: Date): void {
        this.abgangdatum = abgangDatum;
    }

    public getAbgangdatum(): Date {
        return this.abgangdatum;
    }

    public setAusstallgewicht(ausstallgewicht: number): void {
        this.ausstallgewicht = ausstallgewicht;
    }

    public getAusstallgewicht(): number {
        return this.ausstallgewicht;
    }

    public watchAbnehmer() {
        return this.abnehmerSubject.asObservable();
    }

    public setAbnehmer(abnehmer: Haendler) {
        this.abnehmer = abnehmer;
        this.abnehmerSubject.next(this.abnehmer);
    }

    public tierListeAbgeschlossen(tierListe: Tierliste, sender: string) {
        console.log('tierListeAbgeschlossen: ', sender);
        this.tierListeAbgeschlossenSubject.next(tierListe);
    }

    public watchTierListeAbgeschlossen() {
        return this.tierListeAbgeschlossenSubject.asObservable();
    }
}
