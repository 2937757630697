import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Tierreklamation } from '../classes/tierreklamation';
import { MessageService } from '../../../services/message-service/message.service';
import { GruppeTierService } from '../gruppe-tier.service';

@Component({
    selector: 'kiene-gruppe-tier-reklamation-neu-dialog',
    templateUrl: './gruppe-tier-reklamation-edit-dialog.component.html',
    styleUrls: ['./gruppe-tier-reklamation-edit-dialog.component.scss']
})
export class GruppeTierReklamationEditDialogComponent implements OnInit {

    tierreklamation: Tierreklamation = new Tierreklamation();
    reklamationCtrl: UntypedFormControl = new UntypedFormControl(null, Validators.required);
    action = 'anlegen';
    isSaving = false;

    constructor(
        public dialogRef: MatDialogRef<GruppeTierReklamationEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private messageService: MessageService,
        private gruppeTierService: GruppeTierService) {
        if (data?.tierreklamation) {
            this.action = 'bearbeiten';
            this.tierreklamation = data.tierreklamation;
            this.reklamationCtrl.setValue(this.tierreklamation.reklamation);
        }
    }

    ngOnInit(): void {
    }

    updateTierreklamation() {
        this.isSaving = true;
        this.tierreklamation.reklamation = this.reklamationCtrl.value;
        this.gruppeTierService.updateTierreklamation(this.tierreklamation).subscribe({
            next: resp => {
                this.messageService.infoMessage('Tierreklamation wurde erfolgreich aktualisiert');
                this.isSaving = false;
                this.dialogRef.close(resp);
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Aktualisieren des Tierreklamations', err);
                this.isSaving = false;
            }
        });
    }
}
