<div style="padding-top: 24px; padding-bottom: 24px;">

    <mat-card>
        <div class="kiene-table-header">
            <h1 *ngIf="tableDescriptor.headline?.length > 0">
                {{ tableDescriptor.headline }} <small>({{ this.totalCount }})</small>
            </h1>
            <mat-chip-list *ngIf="selection?.selected?.length > 0" style="margin-left: 24px;">
                <mat-chip (removed)="clearSelection()">
                    <span>{{ selection?.selected?.length }} Elemente selektiert</span>
                    <button matChipRemove matTooltip="Selektion löschen">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip>
            </mat-chip-list>
            <span class="spacer"></span>

            <ng-content select="[toolbarElementCenter]"></ng-content>
            <span class="spacer"></span>

            <div class="one-row" style="width: fit-content">

                <!-- Custom elements -->
                <ng-content select="[toolbarElementTop]"></ng-content>

                <ng-container *ngIf="tableDescriptor.filterItems?.length > 0">
                    <button [matMenuTriggerFor]="filterMenu" color="accent" mat-icon-button matTooltip="Filtern"
                            style="display: inline-block;">
                        <mat-icon [matBadge]=" checkedFilterCount">filter_list</mat-icon>
                    </button>
                    <mat-menu #filterMenu="matMenu" [class]="'filter_menu'">
                        <kiene-filter (checkedFilterCount)="setCheckedFilterCount($event)"
                                      (updatedFilterItems)="updatedFilterItems($event)"
                                      [filterItems]="tableDescriptor.filterItems" [tablename]="tableDescriptor.headline"
                                      clickStopPropagation>
                        </kiene-filter>
                    </mat-menu>
                </ng-container>
                <ng-container *kienePermission="[tableDescriptor.permissionCreate]">
                    <div *ngIf="
                        tableDescriptor.standardCreate &&
                        tableDescriptor.createAllowed
                    ">
                        <button (click)="createNew()"
                                [matTooltip]="tableDescriptor?.tooltips['add_button'] ? tableDescriptor?.tooltips['add_button'] : 'Neuen Datensatz erstellen'"
                                color="accent" mat-icon-button>
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </ng-container>
                <ng-container *kienePermission="[tableDescriptor.permissionCreate]">
                    <div *ngIf="
                        !tableDescriptor.standardCreate &&
                        tableDescriptor.createAllowed
                    ">
                        <button (click)="clickedOption('add', null)"
                                [matTooltip]="tableDescriptor?.tooltips['add_button'] ? tableDescriptor?.tooltips['add_button'] : 'Neuen Datensatz erstellen'"
                                color="accent" mat-icon-button>
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </ng-container>
                <div *ngFor="let tb of tableDescriptor.selectionToolbarButtons">
                    <button (click)="clickedSelectionToolbarButton(tb.action)" *kienePermission="[tb.permission]"
                            [disabled]="
                            tb.disableOnEmptySelection &&
                            (selection?.selected?.length < 1 ||
                                !tb.checkConditions(selection?.selected))
                        " [matTooltip]="
                            tb.tooltip +
                            ' Selektiert: (' +
                            selection?.selected?.length +
                            ')'
                        " color="accent" mat-icon-button style="display: inline-block">
                        <mat-icon>{{ tb.icon }}</mat-icon>
                    </button>
                </div>
                <div *ngFor="let tb of tableDescriptor.toolbarButtons">
                    <button (click)="clickedOption(tb.action, null)" *kienePermission="[tb.permission]"
                            [matTooltip]="tb.tooltip" color="accent" mat-icon-button style="display: inline-block">
                        <mat-icon>{{ tb.icon }}</mat-icon>
                    </button>
                </div>
                <div *ngIf="showColumnFilter && tableDescriptor.uniqueIdentifier">
                    <button (click)="openFilterDialog()" color="accent" mat-icon-button matTooltip="Spalten auswählen"
                            [disabled]="isLoading" style="display: inline-block">
                        <mat-icon>view_week</mat-icon>
                    </button>
                </div>
                <div *ngIf="tableDescriptor.showRefresh">
                    <button (click)="reload('button')" color="accent" mat-icon-button matTooltip="Daten aktualisieren"
                            style="display: inline-block">
                        <mat-icon *ngIf="!isLoading">refresh</mat-icon>
                        <mat-spinner *ngIf="isLoading" [diameter]="20" style="margin-left: 9px;"></mat-spinner>
                    </button>
                </div>
                <div *ngIf="showDownloads()">
                    <button [matMenuTriggerFor]="downloadMenu" color="accent" mat-icon-button
                            style="display: inline-block">
                        <mat-icon>download</mat-icon>
                    </button>
                    <mat-menu #downloadMenu="matMenu">
                        <button (click)="download_csv()" mat-menu-item>
                            <mat-icon color="accent">download</mat-icon>
                            <span>Ansicht als CSV herunterladen</span>
                        </button>
                        <button (click)="download_excel()" mat-menu-item>
                            <mat-icon color="accent">download</mat-icon>
                            <span>Ansicht als Excel herunterladen</span>
                        </button>
                    </mat-menu>
                </div>

                <!-- <div *ngIf="tableDescriptor.params" style="width: 50px;">
            <button style="display: inline-block;" mat-icon-button color="accent" (click)="clearExternalParams()">
              <mat-icon>clear</mat-icon>
            </button>
        </div> -->
            </div>
        </div>

        <div *ngIf="tableDescriptor.showSearchBar">
            <mat-form-field style="width: 100%">
                <mat-label>{{ tableDescriptor.searchPlaceholder }}</mat-label>
                <input #searchInput="matInput" [formControl]="searchCtrl" matInput/>
            </mat-form-field>
        </div>

        <!-- Custom elements -->
        <ng-content select="[toolbarElementMiddle]"></ng-content>

        <table #table [dataSource]="tableDataSource" [matSortActive]="tableDescriptor.initialSortColumn"
               [matSortDirection]="initialSortDirection" class="kiene-table" mat-table matSort matSortDisableClear
               multiTemplateDataRows>
            <ng-container matColumnDef="select">
                <th *matHeaderCellDef mat-header-cell style="min-width: 50px">
                    <mat-checkbox (change)="masterSelectionToggle($event)"
                                  [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="
                            selection.hasValue() && !isAllSelected()
                        ">
                    </mat-checkbox>
                </th>
                <td *matCellDef="let row" mat-cell>
                    <mat-checkbox (change)="changeSelection($event, row)" [checked]="isRowSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container *ngFor="let c of getColumns()" [matColumnDef]="c.name">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>
                    {{ c.title }}
                    <ng-container *ngIf="tableDescriptor?.totalRow && tableDataSource?.data?.length>1">
                        <br>{{ getSumValue(c.name) }}
                    </ng-container>
                </th>
                <td *matCellDef="let row" [ngStyle]="{
                        cursor: tableDescriptor.showDetails ? 'pointer' : '',
                        color: getColor(row)
                    }" mat-cell style="white-space: pre-wrap">
                    <div (click)="showDetails(row)" *ngIf="c.type === 'date'">
                        <span *ngIf="getValue(row,c.name)">{{
                                getValue(row, c.name) | date : c.format
                            }}</span>
                        <span *ngIf="!getValue(row,c.name) && getValue(row,c.alternativeField)">{{
                                getValue(row, c.alternativeField)
                            }}</span>
                    </div>
                    <div (click)="showDetails(row)" *ngIf="c.type === 'datetime'">
                        {{ getValue(row, c.name) | date : "dd.MM.yy HH:mm:ss" }}
                    </div>
                    <div (click)="showDetails(row)" *ngIf="c.type === 'rawLocalDateTime'">
                        {{
                            getDateFromRawLocalDateTime(row, c.name)
                                | date : "dd.MM.yy"
                        }}
                    </div>
                    <div *ngIf="c.type === 'boolean'">
                        <ng-container *ngIf="!(c.clickable === true)">
                            <mat-icon (click)="showDetails(row)" *ngIf="getValue(row,c.name) > 0"
                                      [matTooltip]="getValue(row, c.tooltipFieldname)" color="primary">check_circle
                            </mat-icon>
                            <mat-icon (click)="showDetails(row)"
                                      *ngIf="getValue(row,c.name) === 0 || !getValue(row,c.name)"
                                      color="primary">radio_button_unchecked
                            </mat-icon>
                        </ng-container>
                        <ng-container *ngIf="c.clickable === true">
                            <mat-checkbox (change)="checkboxChanged($event, c, row)"
                                          [checked]="getValue(row,c.name) > 0"
                                          [disabled]="!c.checkConditions(row) || !hasPermission(c.permissionId)"></mat-checkbox>
                        </ng-container>
                    </div>
                    <div *ngIf="c.type === 'slideToggle'">
                        <mat-slide-toggle (change)="slideToggled(c, row, $event)" [checked]="!!getValue(row,c.name)">
                        </mat-slide-toggle>
                    </div>
                    <div (click)="showDetails(row)" *ngIf="c.type === 'string'" [matTooltip]="getValue(row, c.hint)"
                         [style.max-width]="c.maxWidth" class="text-column" style="
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;
                        ">
                        {{ getValue(row, c.name) }}
                    </div>
                    <div (click)="showDetails(row)" *ngIf="c.type === 'number'">
                        {{
                            ((getValue(row, c.name) !== undefined && getValue(row, c.name) !== null) ? getValue(row, c.name)
                                : '') | number : c.format
                        }}{{ (c.unit && getValue(row, c.name) !== null) ? (" " + c.unit) : "" }}
                    </div>
                    <div (click)="showDetails(row)" *ngIf="c.type === 'text'">
                        <span [ngStyle]="{
                                'font-family': c.fontFamily,
                                'font-size': c.fontSize,
                                'letter-spacing': c.letterSpacing
                            }"><span *ngIf="c.prefix">{{ c.prefix }}</span>
                            {{ getValue(row, c.name) }}<span *ngIf="c.suffix">{{ c.suffix }}</span>
                        </span>
                    </div>
                    <div (click)="showDetails(row)" *ngIf="c.type === 'ohrmarke'" style="font-size: 0.8em">
                        <kiene-ohrmarke [ohrmarke]="getValue(row,c.name)"></kiene-ohrmarke>
                    </div>
                    <div *ngIf="c.type === 'pdf'">
                        <button (click)="clickedPdf(row)" *ngIf="getValue(row,c.name)" color="accent" mat-icon-button
                                matTooltip="PDF anzeigen">
                            <mat-icon>cloud_download</mat-icon>
                        </button>
                    </div>
                    <div (click)="showDetails(row)" *ngIf="c.type === 'status'">
                        <div [style.border-color]="
                                getStatusBackgroundColor(c, row)
                            " [style.color]="getStatusBackgroundColor(c, row)" class="status-field">
                            {{ getValue(row, c.name) }}
                        </div>
                    </div>
                    <div (click)="showDetails(row)" *ngIf="c.type === 'color'">
                        <div [ngStyle]="{'background-color': getValue(row, c.name)}"
                             style="width: 50px; min-height: 12px;">
                        </div>
                    </div>
                    <div (click)="showDetails(row)" *ngIf="c.type === 'ConditionTableColumn'">
                        <mat-icon [matTooltip]="getTooltip(c, row)" [ngStyle]="{ color: getIconColor(c, row) }">
                            {{ getIconName(c, row) }}
                        </mat-icon>
                    </div>
                    <div (click)="showDetails(row)" *ngIf="c.type === 'function'">
                        {{ executeCallback(row, c) }}
                    </div>
                    <div (click)="showDetails(row)" *ngIf="c.type === 'ifelse'">
                        {{ ifElseValue(row, c) }}
                    </div>
                    <div *ngIf="c.type === 'json'" style="max-width: 400px">
                        <button (click)="openJsonDialog(getValue(row,c.name))" color="accent" mat-icon-button>
                            <mat-icon>star</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="c.type === 'array'">
                        <div *ngFor="let elem of getValue(row, c.name)" style="padding: 6px">
                            <div *ngIf="c?.displayFields?.length > 0">
                                <span *ngFor="let displayField of c.displayFields">{{ elem[displayField] }}&nbsp;</span>
                            </div>
                            <div *ngIf="c?.displayFields?.length < 1">
                                <span>{{ elem }}&nbsp;</span>
                            </div>
                        </div>
                        <br/>
                    </div>
                    <div (click)="showDetails(row)" *ngIf="c.type === 'url'" [matTooltip]="getValue(row, c.hint)"
                         class="text-column">
                        <div [innerHTML]="
                                parseStringForUrl(getValue(row, c.name))
                            "></div>
                    </div>
                    <div *ngIf="c.type === 'link'" class="text-column">
                        <a [queryParams]="createQueryParams(c, row)"
                           [routerLink]="[c.path, getValue(row,c.nameOfIdField)]" class="kiene-link">{{
                                getValue(row,
                                    c.name)
                            }}</a>
                    </div>
                    <div *ngIf="c.type === 'button'" class="text-column">
                        <button (click)="emitButtonEvent(row, c)" *ngIf="getValue(row, c.name)" [matTooltip]="c.tooltip"
                                color="accent" mat-icon-button>
                            <mat-icon>{{ c.iconName }}</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="c.type === 'input'" class="text-column">
                        <div class="one-row">
                            <mat-form-field appearance="standard">
                                <input [(ngModel)]="row[c.name]" matInput/>
                            </mat-form-field>
                            <button (click)="saveInput(row, c)" mat-icon-button>
                                <mat-icon color="accent">save</mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="dragHandle">
                <th *matHeaderCellDef mat-header-cell></th>
                <td *matCellDef="let row" cdkDragHandle mat-cell style="cursor: move">
                    <mat-icon>menu</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="options">
                <th *matHeaderCellDef mat-header-cell></th>
                <td *matCellDef="let row" mat-cell>
                    <mat-menu #optionsMenu>
                        <ng-template matMenuContent>
                            <div *ngFor="let o of tableDescriptor.options">
                                <button (click)="clickedTableOption(o, row)" *kienePermission="[o.permissionId]"
                                        [disabled]="!o.checkConditions(row)" mat-menu-item>
                                    <mat-icon>{{ o.icon }}</mat-icon>
                                    <span>{{ o.title }}</span>
                                </button>
                            </div>
                        </ng-template>
                    </mat-menu>
                    <button *ngIf="hasAtLeastOneOptionPermission()" [matMenuTriggerFor]="optionsMenu" mat-icon-button
                            matTooltip="Menü öffnen">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="entityViewerButton">
                <th *matHeaderCellDef mat-header-cell></th>
                <td *matCellDef="let row" mat-cell>
                    <button (click)="ladeEntityInEntityViewer(row)" mat-icon-button
                            matTooltip="Lade Entity in EntityViewer">
                        <mat-icon>developer_mode</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="detailsButton">
                <th *matHeaderCellDef mat-header-cell></th>
                <td *matCellDef="let row" mat-cell>
                    <button (click)="expand(row)" mat-icon-button matTooltip="Details öffnen">
                        <mat-icon>{{
                                isExpanded(row) ? "expand_less" : "expand_more"
                            }}
                        </mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td *matCellDef="let row" [attr.colspan]="getAllColumnsLength()" mat-cell>
                    <div *ngIf="tableDescriptor?.expandable"
                         [@detailExpand]="isExpanded(row) === true ? 'expanded' : 'collapsed'"
                         class="example-element-detail">
                        <div [ngStyle]="{color: getColor(row)}" style="width: 100%">
                            <table class="details-table">
                                <thead>
                                <tr>
                                    <th *ngIf="
                                                tableDescriptor?.expandableOptions?.length >
                                                0
                                            " style="
                                                text-align: left;
                                                font-size: 0.9em;
                                                color: gray;
                                                font-weight: 400;
                                            "></th>
                                    <th *ngFor="
                                                let tc of tableDescriptor?.expandableColumns
                                            " style="
                                                text-align: left;
                                                font-size: 0.9em;
                                                color: gray;
                                                font-weight: 400;
                                            ">
                                        {{ tc.title }}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr (click)="clickedOnExpandedTable(row)"
                                    *ngFor="let r of getValue(row,tableDescriptor?.expandableFieldName)"
                                    [style.cursor]="
                                            tableDescriptor?.showDetailsAsOptionExpandable
                                                ? 'pointer'
                                                : 'auto'
                                        ">
                                    <td *ngIf="
                                                tableDescriptor?.expandableOptions?.length >
                                                0
                                            ">
                                        <mat-menu #expandableOptionsMenu>
                                            <ng-template matMenuContent>
                                                <div *ngFor="
                                                            let eo of tableDescriptor?.expandableOptions
                                                        ">
                                                    <button (click)="
                                                                clickedOption(
                                                                    eo.name,
                                                                    r
                                                                )
                                                            " *kienePermission="[
                                                                eo.permissionId
                                                            ]" [disabled]="
                                                                !eo.checkConditions(
                                                                    r
                                                                )
                                                            " mat-menu-item>
                                                        <mat-icon>{{
                                                                eo.icon
                                                            }}
                                                        </mat-icon>
                                                        <span>{{
                                                                eo.title
                                                            }}</span>
                                                    </button>
                                                </div>
                                            </ng-template>
                                        </mat-menu>
                                        <button [matMenuTriggerFor]="
                                                    expandableOptionsMenu
                                                " mat-icon-button matTooltip="Menü öffnen">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                    </td>
                                    <td *ngFor="
                                                let tc of tableDescriptor.expandableColumns
                                            " style="
                                                padding-top: 12px;
                                                padding-bottom: 12px;
                                                border-bottom: 1px solid #ddd;
                                                white-space: pre-wrap;
                                            ">
                                        <div *ngIf="tc.type === 'function'">
                                            {{ executeCallback(r, tc) }}
                                        </div>
                                        <div *ngIf="tc.type === 'number'">
                                            {{
                                                r[tc.name]
                                                    | number : tc.format
                                            }}
                                        </div>
                                        <div *ngIf="tc.type === 'date'">
                                            {{
                                                r[tc.name]
                                                    | date : tc.format
                                            }}
                                        </div>
                                        <div *ngIf="tc.type === 'string'">
                                            {{ r[tc.name] }}
                                        </div>
                                        <div *ngIf="tc.type === 'boolean'">
                                            <ng-container *ngIf="!(tc.clickable === true)">
                                                <mat-icon (click)="showDetails(r)" *ngIf="getValue(r,tc.name) > 0"
                                                          [matTooltip]="getValue(r, tc.tooltipFieldname)"
                                                          color="primary">check_circle
                                                </mat-icon>
                                                <mat-icon (click)="showDetails(r)"
                                                          *ngIf="getValue(r,tc.name) === 0 || !getValue(r,tc.name)"
                                                          color="primary">radio_button_unchecked
                                                </mat-icon>
                                            </ng-container>
                                            <ng-container *ngIf="tc.clickable === true">
                                                <mat-checkbox (change)="checkboxChanged($event, tc, r)"
                                                              [checked]="getValue(r,tc.name) > 0"
                                                              [disabled]="!tc.checkConditions(r) || !hasPermission(tc.permissionId)"></mat-checkbox>
                                            </ng-container>
                                        </div>
                                        <div (click)="showDetails(r)" *ngIf="tc.type === 'ifelse'">
                                            {{ ifElseValue(r, tc) }}
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr (click)="setSelectedRow(row, i)" (onFileDropped)="
                    tableDescriptor.useFileDropEvent
                        ? filesDropped($event, row)
                        : uploadFiles($event, row)
                " *matRowDef="
                    let row;
                    let i = dataIndex;
                    columns: displayedColumns
                " [class.example-expanded-row]="isExpanded(row)" class="example-element-row" kieneDropFile mat-row>
            </tr>
            <tr *matRowDef="let row; columns: tableDescriptor.expandable ? ['expandedDetail'] : ['expandedDetail']"
                class="example-detail-row" mat-row></tr>
        </table>
        <mat-paginator [length]="totalCount" [pageSizeOptions]="tableDescriptor.pageSizeOptions"
                       [pageSize]="tableDescriptor.defaultPageSize" showFirstLastButtons></mat-paginator>
    </mat-card>
</div>
