<h1 mat-dialog-title>
    <div class="one-row" style="justify-content: start">
        <mat-icon style="margin-right: 6px" color="accent">help</mat-icon>
        <strong>Hilfe</strong>
    </div>
</h1>

<div mat-dialog-content>
    <div>
        <div>
            <strong> Status:</strong>
        </div>

        <div style="padding-bottom: 6px">
            <mat-hint class="hinweis-text" style="font-size: 0.8em">
                Ist ein Status erf&uuml;llt hat er eine grüne Symbolfarbe,
                ansonsten ist er grau.
            </mat-hint>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">cloud_sync</mat-icon>
            <span class="spacer"></span>
            <span>HIT Gesamtstatus</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">label</mat-icon>
            <span class="spacer"></span>
            <span>Ohrmarke gescannt</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">badge</mat-icon>
            <span class="spacer"></span>
            <span>Pass gescannt</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">download</mat-icon>
            <span class="spacer"></span>
            <span>CSV Import</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">biotech</mat-icon>
            <span class="spacer"></span>
            <span>SGS informiert</span>
        </div>
        <div class="help-entry">
            <mat-icon color="accent">list</mat-icon>
            <span class="spacer"></span>
            <span>Tier in Ausstallliste vorhanden</span>
        </div>

        <!--        <div class="help-entry">-->
        <!--            <mat-icon color="accent">scanner</mat-icon>-->
        <!--            <span class="spacer"></span>-->
        <!--            <span>Pass als PDF hinterlegt</span>-->
        <!--        </div>-->
    </div>

    <div style="margin-top: 12px">
        <div>
            <strong> Informationen:</strong>
        </div>

        <div style="padding-bottom: 6px">
            <mat-hint class="hinweis-text" style="font-size: 0.8em">
                Es werden nur Informationen angezeigt, zu denen Informationen
                hinterlegt sind.
            </mat-hint>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">error</mat-icon>
            <span class="spacer"></span>
            <span>HIT Fehler</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">confirmation_number</mat-icon>
            <span class="spacer"></span>
            <span>Transpondernummer</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">male</mat-icon>
            <mat-icon color="accent">female</mat-icon>
            <span class="spacer"></span>
            <span>Geschlecht</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">transgender</mat-icon>
            <span class="spacer"></span>
            <span>Geschlecht unbekannt</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">groups</mat-icon>
            <span class="spacer"></span>
            <span>Gruppe und/oder Abteil</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">warning_amber</mat-icon>
            <span class="spacer"></span>
            <span>Grund Entfernung aus Abgangsliste</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent" style="transform: rotateY(180deg)">feedback</mat-icon>
            <span class="spacer"></span>
            <span>Reklamationen</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">comment</mat-icon>
            <span class="spacer"></span>
            <span>Bemerkungen</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">scale</mat-icon>
            <span class="spacer"></span>
            <span>Einstall- und/oder Austallgewicht</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">cake</mat-icon>
            <span class="spacer"></span>
            <span>Geburtstag und/oder Alter</span>
        </div>
    </div>

    <div style="margin-top: 12px">
        <div>
            <strong> Daten und HIT Status:</strong>
        </div>

        <div style="padding-bottom: 6px">
            <mat-hint class="hinweis-text" style="font-size: 0.8em">
                Iconfarben:
            </mat-hint>
            <mat-hint class="hinweis-text" style="padding: 6px">
                <br><span style="margin-left: 12px">grün: Meldung erfolgreich</span>
                <br><span style="margin-left: 12px">rot: Meldung fehlerhaft</span>
                <br><span style="margin-left: 12px">gelb: wird gemeldet</span>
                <br><span style="margin-left: 12px">grau: nicht melden</span>
            </mat-hint>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">login</mat-icon>
            <span class="spacer"></span>
            <span>Einstalldatum und/oder Abgeber</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">logout</mat-icon>
            <span class="spacer"></span>
            <span>Austalldatum und/oder Abnehmer</span>
        </div>

        <div class="help-entry">
            <mat-icon color="accent">local_hospital</mat-icon>
            <span class="spacer"></span>
            <span>Todesdatum und/oder Entsorger</span>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="accent" (click)="dialogRef.close()">
        Schlie&szlig;en
    </button>
</div>
