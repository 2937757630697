import { Component, OnInit } from '@angular/core';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { TableDescriptor } from '../../components/kiene-table/table-descriptor';
import { Router } from '@angular/router';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../services/message.service';
import { environment } from '../../../environments/environment';
import { KieneBooleanTableColumn, TableColumn } from '../../components/kiene-table/table-column';
import { TableOption, TableOptionEvent } from '../../components/kiene-table/table-option';
import { CorrectAmountDialogComponent } from './correct-amount-dialog/correct-amount-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CorrectOrderDialogComponent } from './correct-order-dialog/correct-order-dialog.component';
import { Umbuchung } from './umbuchung';
import { ReturnDeliveryDialogComponent } from './return-delivery-dialog/return-delivery-dialog.component';

@Component({
    selector: 'kiene-goods-receipt-history',
    templateUrl: './goods-receipt-history.component.html',
    styleUrls: ['./goods-receipt-history.component.scss']
})
export class GoodsReceiptHistoryComponent implements OnInit {

    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(public dialog: MatDialog,
        private tableService: KieneTableService) {
    }

    ngOnInit() {

        this.tableDescriptor.headline = 'Wareneingangshistorie';
        this.tableDescriptor.uniqueIdentifier = 'Wareneingangshistorie';
        this.tableDescriptor.apiUrl = environment.BASE_URI + 'wareneingang/';

        this.tableDescriptor.searchPlaceholder = 'Wareneingangshistorie durchsuchen';
        this.tableDescriptor.columns.push(new TableColumn('lagerplatz', 'Lagerplatz Ziel'));
        this.tableDescriptor.columns.push(new TableColumn('artikel_me', 'Artikel'));
        this.tableDescriptor.columns.push(new TableColumn('lieferant', 'Lieferant'));
        this.tableDescriptor.columns.push(new TableColumn('bestell_nr', 'Bestellung'));
        this.tableDescriptor.columns.push(new TableColumn('charge', 'Charge'));
        this.tableDescriptor.columns.push(new TableColumn('menge', 'Menge', 'number'));
        this.tableDescriptor.columns.push(new TableColumn('mhd', 'MHD', 'date'));
        this.tableDescriptor.columns.push(new TableColumn('lagerplatz_quelle', 'Lagerplatz Quelle'));
        this.tableDescriptor.columns.push(new KieneBooleanTableColumn('ruecklieferung_vorhanden', 'Rücklieferung vorhanden'));
        this.tableDescriptor.columns.push(new TableColumn('erstellt', 'Eingang', 'datetime'));

        this.tableDescriptor.permissionView = 1651;

        this.tableDescriptor.options.push(new TableOption('correct', 'Menge korrigieren', 'edit', 1653));
        this.tableDescriptor.options.push(new TableOption('edit_bestellung', 'Bestell Nr. korrigieren', 'edit', 1653));
        this.tableDescriptor.options.push(new TableOption('ruecklieferung', 'Rücklieferung', 'assignment_return', 1653));

        this.tableDescriptor.initialSortColumn = 'erstellt';
        this.tableDescriptor.initialSortDirection = 'desc';
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'correct') {
            this.openCorrectAmountDialog(event.value);
        } else if (event.name === 'edit_bestellung') {
            this.openCorrectOrderDialog(event.value);
        } else if (event.name === 'ruecklieferung') {
            this.openReturnDeliveryDialog(event);
        }
    }

    openCorrectAmountDialog(grh: GoodsReceiptHistory) {
        const dialogRef = this.dialog.open(CorrectAmountDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: {
                grh: grh
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.tableService.reload();
            }
        });
    }

    openCorrectOrderDialog(grh: GoodsReceiptHistory) {
        const dialogRef = this.dialog.open(CorrectOrderDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: {
                grh: grh
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.tableService.reload();
            }
        });
    }


    private openReturnDeliveryDialog(event: TableOptionEvent) {
        const value = <Umbuchung>event.value;
        const dialogRef = this.dialog.open(ReturnDeliveryDialogComponent, {
            data: value
        });
        dialogRef.afterClosed().subscribe(result => {
            this.tableService.reload();
        });
    }
}

export class GoodsReceiptHistory {
    lagerplatz: string;
    artikel: string;
    artikel_me: string;
    bestell_nr: string;
    bestellung_extern_id: number;
    charge: string;
    mhd: Date;
    menge: number;
    erstellt: Date;
    umbuchung_id: number;
}

export interface GoodsReceiptHistoryApi {
    records: GoodsReceiptHistory[];
    count: number;
}

