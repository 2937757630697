import { Artikelliste } from '../artikelliste-auswahl-dialog/artikelliste-auswahl-dialog';

export class ArtikellisteAusfuellenDialogInput {
    kunde_id: number;
    artikelliste: Artikelliste;
}

export class ArtikellisteAusfuellenDialogResult {
    confirm = false;
    artikelliste: Artikelliste;
    anzahl_tiere: number;
    tiergewicht: number;
    identifikation: string;
}