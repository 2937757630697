import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { AppService } from '../app.service';
import { MessageService } from './message.service';
import { Observable, Subject } from 'rxjs';
import { API_BASE_URL_SERVICE } from '../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable({
    providedIn: 'root',
})
export class FileDownloadService {
    constructor(
        private appService: AppService,
        private http: HttpClient,
        private messageService: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    downloadCSV(
        url: string,
        params: HttpParams
    ): Observable<number> {
        return this.download(url, 'text/csv', params);
    }

    downloadExcel(
        url: string,
        params: HttpParams
    ): Observable<number> {
        return this.download(
            url,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            params
        );
    }

    downloadZip(
        url: string,
        params: HttpParams
    ): Observable<number> {
        return this.download(
            url,
            'application/zip',
            params
        );
    }

    download(
        url: string,
        mimeType: string,
        params: HttpParams
    ): Observable<number> {
        this.appService.setDataLoading(true);
        const subject = new Subject<number>();

        if (!params) {
            params = new HttpParams();
        }
        //
        // const uri = this.apiBaseUrl + url;
        // const requestType = 'GET';
        // const request = new HttpRequest(requestType, uri, {params: params,
        //     responseType: 'blob',
        //     observe: 'response',
        //     reportProgress: true
        // });

        this.http
            .get(this.apiBaseUrl + url, {
                params: params,
                responseType: 'blob',
                reportProgress: true,
                observe: 'events',
            }).subscribe({
                next: (resp) => {
                    if (resp.type === HttpEventType.DownloadProgress) {
                        const percentDone = Math.round(100 * resp.loaded / resp.total);
                        console.log(percentDone);
                    }
                    if (resp.type === HttpEventType.Response) {
                        const header = resp.headers;
                        const blob = new Blob([resp.body], { type: mimeType });
                        subject.next(blob.size);
                        if (blob.size > 0) {
                            const urlDownload =
                                window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = urlDownload;
                            a.target = '_blank';

                            const regex = /filename="(?<filename>.*)"/;
                            const erg = header?.get('Content-Disposition')?.match(regex);
                            if (erg && erg.groups['filename']) {
                                a.download = erg.groups['filename'];
                            } else {
                                a.download = 'Fehler.vetvet';
                            }

                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(urlDownload);
                        }
                        this.appService.setDataLoading(false);
                    }
                },
                error: (err) => {
                    this.appService.setDataLoading(false);
                    this.messageService.errorMessage(
                        `${mimeType}  konnte nicht generiert werden!`,
                        err
                    );
                },
            });
        return subject.asObservable();
    }
}
