import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Tierart, TierartenApi} from './tierart-selector.component';
import {ArtikelTierartApi} from './artikel-tierart';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable()
export class TierartSelectorService {

    constructor(private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    getTierartenForMaterialAndClient(materialId: number, kundeId: number): Observable<ArtikelTierartApi> {
        let params = new HttpParams();
        if (materialId) {
            params = params.append('artikel_id', materialId.toString());
        }
        if ((kundeId) && (kundeId !== undefined)) {
            params = params.append('kunde_id', kundeId.toString());
        }

        return this.http.get<ArtikelTierartApi>(this.apiBaseUrl + 'artikel_tierart/read_kunde.php', {params: params});
    }

    getTierartenForMaterial(materialId: number): Observable<ArtikelTierartApi> {
        let params = new HttpParams().append('artikel_id', materialId.toString());
        return this.http.get<ArtikelTierartApi>(this.apiBaseUrl + 'artikel_tierart/read.php', {params: params});
    }

    getTierartForId(tierartId: number): Observable<Tierart> {
        return this.http.get<Tierart>(this.apiBaseUrl + 'tierart/read_one.php?tierart_id=' + tierartId);
    }

    getTierarten(): Observable<TierartenApi> {
        return this.http.get<TierartenApi>(this.apiBaseUrl + 'tierart/read.php');
    }
}
