import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { Client } from '../../client/classes/client';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { Tierliste } from '../../gruppe-tier/classes/tierliste';
import { MeineKontakteDialogComponent } from '../../meine-kontakte/meine-kontakte-dialog/meine-kontakte-dialog.component';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { MessageService } from '../../../services/message-service/message.service';
import { Observable } from 'rxjs';
import { AusstallenDialogInput, AusstallenDialogResult } from './ausstallen-dialog';
import { MeineKontakteDialogInput, MeineKontakteDialogResult } from '../../meine-kontakte/meine-kontakte-dialog/meine-kontakte-dialog';
import { HttpParams } from '@angular/common/http';

type ContactSelector = 'Haendler' | 'Empfaenger' | 'Schlachthof';

@Component({
    selector: 'kiene-ausstallen-dialog',
    templateUrl: './ausstallen-dialog.component.html',
    styleUrls: ['./ausstallen-dialog.component.scss'],
})
export class AusstallenDialogComponent implements OnInit {
    tierliste = new Tierliste();

    zwischenhaendler: Client;
    empfaenger: Client;
    schlachthof: Client;

    kommentarCtrl = new UntypedFormControl();
    datumCtrl = new UntypedFormControl();
    haendlerInfoCtrl = new FormControl();
    isSaving = false;

    constructor(
        public dialogRef: MatDialogRef<AusstallenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AusstallenDialogInput,
        private dialog: MatDialog,
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        private messageService: MessageService
    ) { }

    ngOnInit(): void {
        this.tierliste.mehrere_gruppen = 0;
        if (this.data?.tierliste) {
            this.tierliste = this.data.tierliste;
            this.kommentarCtrl.setValue(this.tierliste.kommentar);
            this.datumCtrl.setValue(this.tierliste.datum_abgang);
        } else {
            // neu
            if (this.data?.gruppe_abgang) {
                this.tierliste.gruppe_id_abgang = this.data.gruppe_abgang.gruppe_id;
            } else {
                this.tierliste.mehrere_gruppen = 1;
            }
            this.empfaenger = this.data?.zielbetrieb;
        }
        this.haendlerInfoCtrl.setValue(this.tierliste.haendler_uebernehmen > 0 ? 1 : 0);

        if (this.tierliste?.kunde_id_einstallen && this.tierliste?.kunde_id_zielbetrieb) {
            // kunde_id_einstallen ist händler & kunde_id_zielbetrieb ist zielbetrieb/empfaenger
            this.zwischenhaendler = new Client();
            this.zwischenhaendler.kunde_id = this.tierliste.kunde_id_einstallen;
            this.zwischenhaendler.bezeichnung = this.tierliste.kunde_einstallen;
            this.zwischenhaendler.zusatz = this.tierliste.kunde_zusatz_einstallen;
            this.zwischenhaendler.vvvo = this.tierliste.kunde_vvvo_einstallen;
            this.zwischenhaendler.strasse = this.tierliste.kunde_strasse_einstallen;
            this.zwischenhaendler.plz = this.tierliste.kunde_plz_einstallen;
            this.zwischenhaendler.ort = this.tierliste.kunde_ort_einstallen;

            this.empfaenger = new Client();
            this.empfaenger.kunde_id = this.tierliste.kunde_id_zielbetrieb;
            this.empfaenger.bezeichnung = this.tierliste.kunde_zielbetrieb;
            this.empfaenger.zusatz = this.tierliste.kunde_zusatz_zielbetrieb;
            this.empfaenger.vvvo = this.tierliste.kunde_vvvo_zielbetrieb;
            this.empfaenger.strasse = this.tierliste.kunde_strasse_zielbetrieb;
            this.empfaenger.plz = this.tierliste.kunde_plz_zielbetrieb;
            this.empfaenger.ort = this.tierliste.kunde_ort_zielbetrieb;
        } else if (this.tierliste?.kunde_id_einstallen) {
            // kunde_id_einstallen ist zielbetrieb/empfaenger
            this.empfaenger = new Client();
            this.empfaenger.kunde_id = this.tierliste.kunde_id_einstallen;
            this.empfaenger.bezeichnung = this.tierliste.kunde_einstallen;
            this.empfaenger.zusatz = this.tierliste.kunde_zusatz_einstallen;
            this.empfaenger.vvvo = this.tierliste.kunde_vvvo_einstallen;
            this.empfaenger.strasse = this.tierliste.kunde_strasse_einstallen;
            this.empfaenger.plz = this.tierliste.kunde_plz_einstallen;
            this.empfaenger.ort = this.tierliste.kunde_ort_einstallen;
        }

        if (this.tierliste?.kunde_id_schlachthof) {
            this.schlachthof = new Client();
            this.schlachthof.kunde_id = this.tierliste.kunde_id_schlachthof
            this.schlachthof.bezeichnung = this.tierliste.kunde_schlachthof;
            this.schlachthof.zusatz = this.tierliste.kunde_zusatz_schlachthof;
            this.schlachthof.vvvo = this.tierliste.kunde_vvvo_schlachthof;
            this.schlachthof.strasse = this.tierliste.kunde_strasse_schlachthof;
            this.schlachthof.plz = this.tierliste.kunde_plz_schlachthof;
            this.schlachthof.ort = this.tierliste.kunde_ort_schlachthof;
        }

        if (this.data?.gruppe_abgang?.kunde_id && !this.tierliste.tierliste_id) {
            if (!this.zwischenhaendler) {
                const params = new HttpParams().set('kunde_id', this.data.gruppe_abgang.kunde_id);
                this.api.get(this.apiBaseUrl + 'tierliste/read_last_haendler.php', params).subscribe({
                    next: (kunde: Client) => {
                        if (kunde) {
                            this.zwischenhaendler = kunde;
                        }
                    },
                    error: err => {
                        this.messageService.errorMessage('Fehler!', err);
                    }
                });
            }
            if (!this.empfaenger) {
                const params = new HttpParams().set('kunde_id', this.data.gruppe_abgang.kunde_id);
                this.api.get(this.apiBaseUrl + 'tierliste/read_last_schlachthof.php', params).subscribe({
                    next: (kunde: Client) => {
                        if (kunde) {
                            this.schlachthof = kunde;
                        }
                    },
                    error: err => {
                        this.messageService.errorMessage('Fehler!', err);
                    }
                });
            }
        }
    }

    save() {
        this.isSaving = true;
        this.tierliste.kommentar = this.kommentarCtrl.value;
        this.tierliste.datum_abgang = this.datumCtrl.value;
        this.tierliste.haendler_uebernehmen = this.haendlerInfoCtrl.value;

        if (this.zwischenhaendler && this.empfaenger) {
            // zwischenhaendler ist kunde einstallen & empfänger ist kunde zielbetrieb
            this.tierliste.kunde_id_einstallen = this.zwischenhaendler.kunde_id;
            this.tierliste.kunde_id_zielbetrieb = this.empfaenger.kunde_id;
        } else if (this.empfaenger) {
            // empfaenger ist kunde einstallen
            this.tierliste.kunde_id_einstallen = this.empfaenger.kunde_id;
        } else if (this.zwischenhaendler) {
            // zwischenhaendler ist kunde einstallen
            this.tierliste.kunde_id_einstallen = this.zwischenhaendler.kunde_id;
        }
        this.tierliste.kunde_id_schlachthof = this.schlachthof?.kunde_id;

        // update
        let obs: Observable<Tierliste>;
        if (this.tierliste.tierliste_id) {
            obs = this.api
                .post(
                    this.apiBaseUrl + 'tierliste/update_kundenportal.php',
                    this.tierliste,
                    undefined
                );
        } else {
            obs = this.api
                .post(
                    this.apiBaseUrl + 'tierliste/create_kundenportal.php',
                    this.tierliste,
                    undefined
                );
        }
        obs.subscribe({
            next: (liste: Tierliste) => {
                this.messageService.infoMessage(
                    'Die Ausstallliste wurde erfolgreich angelegt!'
                );
                this.isSaving = false;
                this.dialogRef.close(new AusstallenDialogResult(liste));
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler!', err);
                this.isSaving = false;
            },
        });
    }

    close() {
        this.dialogRef.close();
    }

    deleteEmpfaenger() {
        this.empfaenger = null;
    }

    deleteHaendler() {
        this.zwischenhaendler = null;
    }

    deleteSchlachthof() {
        this.schlachthof = null;
    }

    openContactDialog(contactSelector: ContactSelector) {
        const input = new MeineKontakteDialogInput(this.data?.intern, this.data?.extern, this.data?.gruppe_abgang?.kunde_id)
        this.dialog.open<MeineKontakteDialogComponent, MeineKontakteDialogInput, MeineKontakteDialogResult>(MeineKontakteDialogComponent, {
            maxWidth: '100vh',
            maxHeight: '90vh',
            width: '100%',
            data: input,
            disableClose: false,
        }).afterClosed().subscribe((result) => {
            if (result?.kunde) {
                if (contactSelector == 'Haendler') {
                    this.zwischenhaendler = result.kunde;
                } else if (contactSelector == 'Empfaenger') {
                    this.empfaenger = result.kunde;
                } else if (contactSelector == 'Schlachthof') {
                    this.schlachthof = result.kunde;
                }
            }
        });
    }

}
