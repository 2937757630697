import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
    ConfirmDialogInput
} from "../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog";
import {
    ConfirmDialogComponent
} from "../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component";
import {GruppeTier} from '../../administration/client-group/gruppe-tier';
import {UntypedFormControl, Validators} from '@angular/forms';
import {KundenSettingsService} from '../../profile-settings/kunden-settings/kunden-settings.service';
import {MessageService} from '../../services/message.service';
import {
    EntsorgerKundenNrNachtragenComponent
} from './entsorger-kunden-nr-nachtragen/entsorger-kunden-nr-nachtragen.component';
import {Client} from '../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {KieneUtils} from '../../../../projects/kiene-core/src/lib/utils/KieneUtils';
import {GruppenService} from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import {
    EntsorgerDetailsDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/stammdaten/entsorger/entsorger-details-dialog/entsorger-details-dialog.component';
import {Entsorger} from '../../../../projects/kiene-core/src/lib/components/stammdaten/entsorger/entsorger';

export class TodesArt {
    todesart_id: number;
    bezeichnung: string;
    editiert: Date;
}

@Component({
    selector: 'kiene-gruppe-tier-tod-melden',
    templateUrl: './gruppe-tier-tod-melden.component.html',
    styleUrls: ['./gruppe-tier-tod-melden.component.scss']
})
export class GruppeTierTodMeldenComponent implements OnInit {

    tier: GruppeTier;
    entsorgerFavoriten: Entsorger[] = [];
    todesarten: TodesArt[] = [];

    todesDatumCtrl = new UntypedFormControl(undefined, [Validators.required]);
    entsorgerCtrl: UntypedFormControl = new UntypedFormControl(null);
    selectedTodesartCtrl: UntypedFormControl = new UntypedFormControl('', Validators.required);
    vvvoOfTierGruppe: string;
    kundeIdOfTierGruppe: number;

    constructor(public dialogRef: MatDialogRef<GruppeTierTodMeldenComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private kundenSettingsService: KundenSettingsService,
                private messageService: MessageService,
                private dialog: MatDialog,
                private gruppenService: GruppenService) {
    }

    ngOnInit(): void {
        if (this.data.gruppeTier) {
            this.tier = this.data.gruppeTier;
            this.ladeEntsorgerFavoriten();
            this.ladeTodesarten();
        }
        this.entsorgerCtrl.valueChanges.subscribe(data => {
            console.log('CHANGED;', data);
            this.checkEntsorgerKundenNr();
        });
    }

    todMelden() {
        if (!this.isValid()) {
            return;
        }
        if (!this.entsorgerCtrl.value) {
            const input = new ConfirmDialogInput();
            input.headline = 'Todmeldung ohne Entsorger';
            input.text = 'Soll die Todmeldung ohne Angabe eines Entsorgers erfolgen?';
            input.okButton = 'Melden';

            this.dialog.open(ConfirmDialogComponent, {data: input}).afterClosed().subscribe({
                next: value => {
                    console.log(value)
                    if (value.confirmed) {
                        this.speicherTodmeldung();
                    }
                }
            })
        } else {
            this.speicherTodmeldung();
        }
    }

    compareEntsorger(c1: any, c2: any): boolean {
        return c1 && c2 ? c1.entsorger_id === c2.entsorger_id : c1 === c2;
    }

    entsorgerHinzufuegen(_$event: MouseEvent) {
        const dialogRef = this.dialog.open(EntsorgerDetailsDialogComponent);
        dialogRef.afterClosed().subscribe({
            next: (entsorger) => {
                this.entsorgerCtrl.setValue(entsorger, {emitEvent: false});
                this.ladeEntsorgerFavoriten(entsorger);
            }
        });
    }

    ladeTodesarten(): void {
        this.kundenSettingsService.loadTodesarten().subscribe({
            next: (todesarten) => {
                this.todesarten = todesarten.records;
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Laden der Todesarten', err);
            }
        });
    }

    ladeEntsorgerFavoriten(entsorger?: Entsorger) {
        this.kundenSettingsService.loadEntsorgerFavoriten().subscribe({
            next: (entsorgerFavoriten) => {
                this.entsorgerFavoriten = entsorgerFavoriten.records;

                // letzten benutzten Entsorger vorauswaehlen
                if (this.entsorgerFavoriten.length > 0) {
                    if (entsorger) {
                        this.entsorgerCtrl.setValue(entsorger);
                    } else {
                        this.entsorgerCtrl.setValue(this.entsorgerFavoriten[0]);

                    }
                } else {
                    this.entsorgerCtrl.setErrors({'empty-list': true});
                    this.entsorgerCtrl.markAsTouched();
                }
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Laden der Entsorgerfavoriten', err);
            }
        });
    }

    isValid(): boolean {
        let valid = true;

        this.todesDatumCtrl.markAsTouched();
        valid = valid && this.todesDatumCtrl.valid && this.todesDatumCtrl.value;

        this.entsorgerCtrl.markAsTouched();
        // valid = valid && this.entsorgerCtrl.valid && this.entsorgerCtrl.value;

        this.selectedTodesartCtrl.markAsTouched();
        valid = valid && this.selectedTodesartCtrl.valid && this.selectedTodesartCtrl.value;

        return valid;
    }

    kundenNrNachtragen() {
        const dialogRef = this.dialog.open(EntsorgerKundenNrNachtragenComponent, {
            width: '25vw',
            data: {
                vvvo: this.vvvoOfTierGruppe,
                kundeId: this.kundeIdOfTierGruppe,
                entsorger: this.entsorgerCtrl?.value
            }
        });

        dialogRef.afterClosed().subscribe({
            next: resp => {
                if (resp) {
                    this.entsorgerCtrl.setValue(resp, {emitEvent: false});
                    if (this.entsorgerCtrl?.value.kundenNrVvvos?.some(entry => entry.vvvo === this.vvvoOfTierGruppe)) {
                        delete this.entsorgerCtrl.errors['keine-kundennr-fuer-entsorger'];
                        this.entsorgerCtrl.updateValueAndValidity();
                    }
                }
            }
        });
    }

    public getLabel(): string {
        if (!this.entsorgerCtrl.value) {
            return 'Ohne Entsorger';
        }
        return "Entsorger auswählen";
    }

    private speicherTodmeldung(): void {
        this.gruppenService.todMeldenKundenportal(this.tier.gt_id, this.todesDatumCtrl.value,
            this.entsorgerCtrl.value?.entsorger_id, this.selectedTodesartCtrl.value?.todesart_id).subscribe({
                next: result => {
                    this.messageService.infoMessage('Tier erfolgreich gemeldet');
                    this.selectedTodesartCtrl.setValue(null);
                    this.selectedTodesartCtrl.markAsUntouched();
                    this.dialogRef.close(result);
                },
                error: (err) => {
                    this.messageService.errorMessage('Tiermeldung fehlgeschlagen: ', err);
                }
            }
        );
    }

    private checkEntsorgerKundenNr() {
        if (this.tier && this.entsorgerCtrl?.value) {
            this.gruppenService.getGruppeKundenportal(this.tier.gruppe_id).subscribe({
                next: resp => {
                    this.kundenSettingsService.getKunde(resp.kunde_id).subscribe({
                        next: kd => {
                            const kunde = <Client>kd;
                            this.vvvoOfTierGruppe = kunde.vvvo;
                            this.kundeIdOfTierGruppe = kunde.kunde_id;
                            if (this.entsorgerCtrl?.value.kundenNrVvvos?.some(entry => entry.kunde_id === kunde.kunde_id && KieneUtils.isNNoU(entry.kunden_nr))) {
                                if (this.entsorgerCtrl.errors) {
                                    delete this.entsorgerCtrl.errors['keine-kundennr-fuer-entsorger'];
                                }
                            } else {
                                this.entsorgerCtrl.setErrors({'keine-kundennr-fuer-entsorger': true});
                            }
                            this.entsorgerCtrl.markAsTouched();
                        }
                    });
                },
                error: err => {
                    this.messageService.errorMessage('Fehler beim Prüfen der Entsorger-Kundennr!', err);
                }
            });
        }
    }
}
