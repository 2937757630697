import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Tierart} from "../../../../../administration/tierarten/tierart";
import {KieneTableService} from "../../../../../components/kiene-table/kiene-table.service";
import {MessageService} from "../../../../../services/message.service";

@Component({
    selector: 'kiene-tierart-sonstige-dialog',
    templateUrl: './tierart-sonstige-dialog.component.html',
    styleUrls: ['./tierart-sonstige-dialog.component.scss']
})
export class TierartSonstigeDialogComponent implements OnInit {
    isSaving = false;
    tierartCtrl: FormControl = new FormControl();
    rasseNameCtrl: FormControl;

    // private rasse: TierRasse;

    constructor(public dialogRef: MatDialogRef<TierartSonstigeDialogComponent>,
                private api: KieneTableService,
                private messageService: MessageService,
                @Inject(MAT_DIALOG_DATA) public tierart: Tierart
    ) {

    }

    ngOnInit(): void {
        this.tierartCtrl.setValue(this.tierart);
        this.tierartCtrl.markAsPristine();
    }

    cancel() {
        this.dialogRef.close(null);
    }

    save() {
        this.isSaving = true;
        // this.rasse = new TierRasse()
        // this.rasse.name = this.rasseNameCtrl.value;
        //
        // this.api.updateEntity(environment.BASE_URI + 'tierrasse/update.php', this.rasse).subscribe({
        //     next: (saved: TierRasse) => {
        //
        //         this.isSaving = false;
        //         this.messageService.infoMessage('Die Tierart ' + saved.name + ' wurde erfolgreich gespeichert!');
        //         this.dialogRef.close(saved);
        //     },
        //     error: error => {
        //         this.isSaving = false;
        //         this.messageService.errorMessage('Fehler! Die Tierart ' + this.rasse.name + ' konnte nicht gespeichert werden! ', error);
        //     }
        // });
    }

    public isDisabled(): boolean {
        return !this.tierart.bezeichnung || !this.tierart.kuerzel;
    }
}
