import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {Inventur} from '../../../warehouse/inventur/entities/Inventur';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {MessageService} from '../../../services/message.service';

@Component({
    selector: 'kiene-inventur-details-dialog',
    templateUrl: './inventur-details-dialog.component.html',
    styleUrls: ['./inventur-details-dialog.component.scss']
})
export class InventurDetailsDialogComponent implements OnInit {

    inventur: Inventur;
    nameCtrl = new FormControl(undefined, [Validators.required]);
    aktivCtrl = new UntypedFormControl(true);
    datumCtrl = new FormControl(new Date(), [Validators.required]);

    constructor(public dialogRef: MatDialogRef<InventurDetailsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data: Inventur,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private messageService: MessageService) {
        if (this.data) {
            this.inventur = data;
        }
    }

    ngOnInit(): void {

        if (this.inventur) {
            this.nameCtrl.setValue(this.inventur.name, {emitEvent: false});
            this.aktivCtrl.setValue(this.inventur.aktiv === 1, {emitEvent: false});
            this.datumCtrl.setValue(this.inventur.datum, {emitEvent: false});
        }

    }

    save() {
        if (!this.inventur) {
            this.inventur = new Inventur();
        }
        this.inventur.name = this.nameCtrl.value;
        this.inventur.datum = this.datumCtrl.value;
        this.inventur.aktiv = this.aktivCtrl.value ? 1 : 0;

        this.api.updateGeneric(this.apiBaseUrl + 'inventur/update.php', this.inventur).subscribe({
            next: response => {
                this.messageService.infoMessage('Die Inventur wurde erfolgreich gespeichert!');
                this.dialogRef.close(true);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

}
