import {Component, Inject, OnInit} from '@angular/core';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {Material} from '../../warehouse/material/material';
import {MatDialog} from '@angular/material/dialog';
import {TextInputDialogComponent} from '../../dialogs/text-input-dialog/text-input-dialog.component';
import {MessageService} from '../../services/message.service';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {
    PdfViewerDesktopService
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {ClientService} from '../../administration/client/client.service';
import {Router} from '@angular/router';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {TextProposalService} from '../text-autocomplete/text-proposal.service';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {TextTableColumn} from '../../components/kiene-table/table-column';
import {TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';

@Component({
    selector: 'kiene-behandlungs-artikel',
    templateUrl: './behandlungs-artikel.component.html',
    styleUrls: ['./behandlungs-artikel.component.scss'],
})
export class BehandlungsArtikelComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private pdfService: PdfViewerDesktopService,
        private clientService: ClientService,
        private router: Router,
        private api: KieneTableService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private service: TextProposalService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Laborartikel';
        this.tableDescriptor.uniqueIdentifier = 'Laborartikel';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'behandlungsartikel/';
        this.tableDescriptor.createAllowed = false;
        this.tableDescriptor.showDetails = false;

        this.tableDescriptor.columns = this.getTableColumns();
        this.tableDescriptor.options = this.getTableOptions();

        this.tableDescriptor.initialSortColumn = 'bezeichnung';
        this.tableDescriptor.initialSortDirection = 'asc';

        this.tableDescriptor.permissionView = 1021;
        this.tableDescriptor.permissionUpdate = 1023;

    }

    getTableColumns() {
        const columns = [];
        columns.push(new TextTableColumn('artikel_id', 'Nr.'));
        columns.push(new TextTableColumn('bezeichnung', 'Name'));
        columns.push(new TextTableColumn('bezeichnung_befund', 'Name auf Befund'));
        return columns;
    }

    getTableOptions() {
        const options = [];
        options.push(new TableOption('edit', 'bearbeiten', 'edit', 1023));
        return options;
    }


    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'edit') {
            this.openEditBefundArtikelDialog(event.value);
        }
    }


    openEditBefundArtikelDialog(article: Material) {
        const dialogRef = this.dialog.open(TextInputDialogComponent, {
            width: '700px',
            data: {
                title: 'Artikelnamen ändern',
                text: 'Bitte geben Sie den alternativen Artikelnamen für Befunde an',
                textLabel: 'Alternativer Name',
                textButton: 'Ändern',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                article.bezeichnung_befund = result;
                this.api.post(this.apiBaseUrl + 'behandlungsartikel/update.php', article).subscribe({
                    next: (response) => {
                        const updatedArticle: Material = response;
                        this.messageService.infoMessage(
                            'Artikelname auf Befund geändert in: ' +
                            updatedArticle.bezeichnung_befund
                        );
                    },
                    error: (error) => {
                        this.messageService.errorMessage(
                            'Fehler: Artikelname auf Befund konnte nicht geändert werden!',
                            error
                        );
                    }
                });
            }
        });
    }
}
