import { FormControl, FormGroup } from '@angular/forms';

export class FormGroupHelper {
    private form: FormGroup;

    constructor(form: FormGroup) {
        this.form = form;
    }

    getControl(name: string): FormControl {
        return this.form.get(name) as FormControl;
    }

    getValue(name: string): any {
        return this.getControl(name).value;
    }

    setValue(name: string, value: any) {
        this.getControl(name).setValue(value);
    }

    setControlValue(name: string, value: any, options?: any) {
        this.getControl(name).setValue(value, options);
    }

    enableControl(name: string, options?: any) {
        this.getControl(name).enable(options);
    }

    disableControl(name: string, options?: any) {
        this.getControl(name).disable(options);
    }

    markAsTouched(name: string) {
        this.getControl(name).markAsTouched();
    }

    markAsUntouched(name: string) {
        this.getControl(name).markAsUntouched();
    }

    patchValue(name: string, value: any, options?: any) {
        this.getControl(name).patchValue(value, options);
    }

    reset(name: string, value?: any, options?: any) {
        this.getControl(name).reset(value, options);
    }

    markAsDirty(name: string) {
        this.getControl(name).markAsDirty();
    }

    markAsPristine(name: string) {
        this.getControl(name).markAsPristine();
    }

    markAsPending(name: string) {
        this.getControl(name).markAsPending({ onlySelf: true });
    }
}

export class FormControlHelper extends FormGroupHelper {
    private readonly controlName: string;

    constructor(form: FormGroup, controlName: string) {
        super(form);
        this.controlName = controlName;
    }

    get value(): any {
        return super.getValue(this.controlName);
    }

    /**
     * Wenn Options übergeben werden sollen, muss setControlValue() genutzt werden.
     * @param value
     */
    set value(value: any) {
        super.setValue(this.controlName, value);
    }

    getControl(): FormControl {
        return super.getControl(this.controlName);
    }

    getValue(): any {
        return super.getValue(this.controlName);
    }

    /**
     * setValue und setControlValue sind beide notwendig, weil es sonst zu einem Konflikt kommt,
     * der bewirkt das bei möglichen Options ein Value aus der Control über eine getter-Methode
     * nicht zurückgegeben wird. Stattdessen kann nur die FormControl und der value dann über .value abgerufen
     * werden. Das widerspricht der Idee hinter diesem Helper.
     */

    setValue(value: any) {
        super.setValue(this.controlName, value);
    }

    setControlValue(value: any, options?: any) {
        super.setControlValue(this.controlName, value, options);
    }

    enableControl(options?: any) {
        super.enableControl(this.controlName, options);
    }

    disableControl(options?: any) {
        super.disableControl(this.controlName, options);
    }

    markAsTouched() {
        super.markAsTouched(this.controlName);
    }

    markAsUntouched() {
        super.markAsUntouched(this.controlName);
    }

    patchValue(value: any, options?: any) {
        super.patchValue(this.controlName, value, options);
    }

    reset(value?: any, options?: any) {
        super.reset(this.controlName, value, options);
    }

    markAsDirty() {
        super.markAsDirty(this.controlName);
    }

    markAsPristine() {
        super.markAsPristine(this.controlName);
    }

    markAsPending() {
        super.markAsPending(this.controlName);
    }
}
