import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MessageService} from '../../../services/message.service';
import {BestandsbuchService} from '../bestandsbuch.service';
import {BestandsbuchArtikel} from '../bestandsbuch/classes/bestandsbuch-artikel';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {UntypedFormControl} from '@angular/forms';
import {
    BestandsbuchArtikelTierNachtragenDialogComponent
} from '../bestandsbuch-artikel-tier-nachtragen-dialog/bestandsbuch-artikel-tier-nachtragen-dialog.component';
import {BestandsbuchArtikelTier} from '../bestandsbuch/classes/bestandsbuch-artikel-tier';
import {BestandsbuchArtikelAnwendung} from '../bestandsbuch/classes/bestandsbuch-artikel-anwendung';

@Component({
    selector: 'kiene-bestandsbuch-artikel-dialog',
    templateUrl: './bestandsbuch-artikel-dialog.component.html',
    styleUrls: ['./bestandsbuch-artikel-dialog.component.scss']
})
export class BestandsbuchArtikelDialogComponent implements OnInit {

    tiersucheCtrl: UntypedFormControl = new UntypedFormControl();
    bestandsbuchArtikel: BestandsbuchArtikel;
    openTiere: any[];
    private isTierarzt: boolean;

    constructor(public dialogRef: MatDialogRef<BestandsbuchArtikelDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data,
                private dialog: MatDialog,
                private messageService: MessageService,
                private localStorageService: LocalStorageService,
                private bestandsbuchService: BestandsbuchService) {

        this.isTierarzt = this.localStorageService.currentUserIsVet();
        if (data.bestandsbuchArtikel) {
            this.bestandsbuchArtikel = data.bestandsbuchArtikel;
            this.loadBestandsbuch();
        }
    }

    ngOnInit(): void {
    }

    loadBestandsbuch() {
        this.bestandsbuchService.getBestandsbuchArtikel(this.bestandsbuchArtikel.bestandsbuch_artikel_id).subscribe({
            next: bba => {
                this.bestandsbuchArtikel = bba;
                console.log('NACHTRAGEN: ', this.bestandsbuchArtikel.anzahl_tiere - this.bestandsbuchArtikel.tiere.length);
                console.log('ZIEL: ', this.bestandsbuchArtikel.anzahl_tiere, ' IST: ', this.bestandsbuchArtikel.tiere.length);
                this.openTiere = new Array(this.bestandsbuchArtikel.anzahl_tiere - this.bestandsbuchArtikel.tiere.length);
                console.log(this.openTiere);
            },
            error: error => this.messageService.errorMessage('Fehler beim Laden des Bestandsbuchartikels!', error)
        });
    }

    isInFuture(datum: Date) {
        const now = new Date();
        const d = new Date(datum);
        now.setHours(0, 0, 0, 0);
        d.setHours(0, 0, 0, 0);
        return d > now;
    }

    hasValidPhoneNumber() {
        return this.bestandsbuchArtikel.telefon || this.bestandsbuchArtikel.mobil;
    }

    isUserAllowed() {
        return this.bestandsbuchArtikel.behandlung === 0 || this.isTierarzt;
    }

    addTier(tier?: any) {
        if (this.isUserAllowed()) {
            console.log(this.bestandsbuchArtikel);
            const dialogRef = this.dialog.open(BestandsbuchArtikelTierNachtragenDialogComponent, {
                width: '500px',
                data: {
                    tier: tier,
                    gruppeId: this.bestandsbuchArtikel.gruppe_id,
                }
            });

            dialogRef.afterClosed().subscribe((result: BestandsbuchArtikelTier) => {
                if (result) {
                    if (tier) {
                        // replace
                        const index = this.bestandsbuchArtikel.tiere.findIndex(t => t.bat_id === tier.bat_id);
                        this.bestandsbuchArtikel.tiere[index] = result;
                    } else {
                        // add
                        this.bestandsbuchArtikel.tiere.push(result);
                    }
                    this.updateBestandsbuchArtikel();
                }
            });
        }


    }

    updateBestandsbuchArtikel() {
        this.bestandsbuchService.updateBestandsbuchArtikel(this.bestandsbuchArtikel).subscribe({
            next: (bestandsbuchArtikel) => {
                this.bestandsbuchArtikel = <BestandsbuchArtikel>bestandsbuchArtikel;
                this.openTiere = new Array(this.bestandsbuchArtikel.anzahl_tiere - this.bestandsbuchArtikel.tiere.length);
                this.messageService.infoMessage('Änderungen gespeichert.');
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    protokollieren($event: MatCheckboxChange, anwendung: BestandsbuchArtikelAnwendung) {
        if (this.isUserAllowed()) {
            anwendung.angewendet = $event.checked ? 1 : 0;
            this.updateBestandsbuchArtikel();
        }
    }

    showTierarztHint() {
        this.messageService.infoMessage('Die Aktion kann bei diesem Artikel nur durch einen Tierarzt durchgeführt werden.');
    }
}
