<div style="padding: 0 24px 64px 24px">

    <div class="one-row" style="margin: 24px 0px;">

        <kiene-page-header [showReturnButton]="showReturnButton" [ueberschrift]="'Meine Entsorger'"
                           [routeRelativeAlternative]="'../../'"></kiene-page-header>

        <button *kienePermission="[9142]" (click)="entsorgerHinzufuegen()" color="accent" mat-icon-button>
            <mat-icon>add</mat-icon>
        </button>
    </div>


    <div *ngIf="entsorgerFehlt" style="margin-bottom: 12px">
        <mat-hint class="hinweis-text" style="display: flex; align-items: flex-start; justify-content: flex-start">
            <div>
                <span class="dot-warn"></span>
            </div>
            <div style="text-align: justify; margin-left: 6px">
                <span> Es muss mind. ein Entsorger hinterlegt werden, sonst ist keine Todmeldung m&ouml;glich.</span>
            </div>
        </mat-hint>
    </div>

    <ng-container *ngFor="let entsorger of favoritenEntsorger">
        <div class="one-row">
            <h3>{{entsorger.name}}</h3>
            <span class="spacer"></span>
            <button mat-icon-button color="accent" [matMenuTriggerFor]="entsorgerMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #entsorgerMenu>
                <button mat-menu-item (click)="editiereEntsorger(entsorger)">
                    <mat-icon>edit</mat-icon>
                    <span>bearbeiten</span>
                </button>
                <!--                <button mat-menu-item (click)="entsorgerLoeschen(entsorger)">-->
                <!--                    <mat-icon>delete</mat-icon>-->
                <!--                    <span>l&ouml;schen</span>-->
                <!--                </button>-->
            </mat-menu>
        </div>
        <kiene-key-value-list>
            <kiene-key-value-list-item key="VVVO" [value]="entsorger.vvvo"></kiene-key-value-list-item>
            <kiene-key-value-list-item key="Strasse Nr." [value]="entsorger.strasse"></kiene-key-value-list-item>
            <kiene-key-value-list-item key="PLZ Ort"
                                       [value]="entsorger.plz + ' ' + entsorger.ort"></kiene-key-value-list-item>
            <kiene-key-value-list-item key="E-Mail" [value]="entsorger.email"
                                       [actionButtonIcon]="entsorger.email ? 'mail' : undefined"
                                       (actionClicked)="sendMail(entsorger)"></kiene-key-value-list-item>
        </kiene-key-value-list>

    </ng-container>


</div>
