<kiene-kiene-table [tableDescriptor]="tableDescriptor" (optionClicked)="catchOptionEvent($event)">
    <div toolbarElementTop>
        <mat-chip-list #chipList>
            <mat-chip (removed)="removeMinAlter()" (click)="openAbhollisteFilterDialog()"
                *ngIf="filterObject?.minAlter">
                {{ getMindestalter() }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <mat-chip (removed)="removeDate()" (click)="openAbhollisteFilterDialog()" *ngIf="filterObject?.datum">
                {{ getDate() }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <mat-chip (removed)="removeMaennlich()" (click)="openAbhollisteFilterDialog()" *ngIf="getMaennlich()">
                {{ getMaennlich() }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <mat-chip (removed)="removeWeiblich()" (click)="openAbhollisteFilterDialog()" *ngIf="getWeiblich()">
                {{ getWeiblich() }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <mat-chip (removed)="removeMaxAlter()" (click)="openAbhollisteFilterDialog()"
                *ngIf="filterObject?.maxAlter">
                {{ getMaxAlter() }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <mat-chip (removed)="removeRassen()" (click)="openAbhollisteFilterDialog()" *ngIf="isRassenVisible()">
                {{ getRassen() }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
            <mat-chip (removed)="removeTiereInAbholliste()" (click)="openAbhollisteFilterDialog()"
                *ngIf="filterObject?.nichtInAbholliste">
                {{ getInAbholliste() }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
        </mat-chip-list>
    </div>
</kiene-kiene-table>