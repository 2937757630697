import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-menu-button',
    templateUrl: './kiene-menu-button.component.html',
    styleUrls: ['./kiene-menu-button.component.scss']
})
export class KieneMenuButtonComponent implements OnInit {

    @Input('iconName') iconName = 'icon-name';
    @Input('buttonText') buttonText = 'button-text';
    @Input('permissionId') permissionId: number = 0;
    @Output() buttonClicked = new EventEmitter<boolean>();

    buttonVisible: boolean = false;

    constructor(private localStorageService: LocalStorageService) {

    }

    ngOnInit() {
        if (this.localStorageService.currentUserHasPermission(this.permissionId)) {
            this.buttonVisible = true;
        } else {
            this.buttonVisible = false;
        }
    }

    execute() {
        this.buttonClicked.emit(true);
    }

}
