import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants } from '../../app.constants';
import { Observable } from 'rxjs';
import { BestellvorschlagApi } from './bestellvorschlag';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable({
  providedIn: 'root'
})
export class BestellvorschlagService {

  constructor(private http: HttpClient,
              @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) { }

  getAllBestellvorschlaege(sortColumn: string, sortDirection: string, offset: number, limit: number, searchString: string): Observable<BestellvorschlagApi> {
    return this.http.get<BestellvorschlagApi>(this.apiBaseUrl + 'bestellvorschlag/read.php?orderby=' + sortColumn + ' ' + sortDirection + '&limit=' + limit + '&offset=' + offset + '&search=' + searchString);
  }
}
