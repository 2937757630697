<kiene-kiene-table [tableDescriptor]="tableDescriptor" (optionClicked)="catchOptionEvent($event)">
    <div toolbarElementCenter>
        <button
            *ngIf="anzahlUnterschreibbarePlaene > 0 && (localStorageService.isCurrentUserKunde() || localStorageService.isCurrentUserViehhaendler())"
            mat-stroked-button color="accent" (click)="alleUnterschreiben()">
            Alle unterschreiben ({{ anzahlUnterschreibbarePlaene }})
        </button>
        <button
            *ngIf="anzahlOffenePlaene > 0 && (localStorageService.isCurrentUserVeterinarian() || localStorageService.isCurrentUserVerwaltung())"
            mat-stroked-button color="accent" (click)="alleAbschliessen()">
            Alle abschlie&szlig;en ({{ anzahlOffenePlaene }})
        </button>
    </div>
</kiene-kiene-table>
