import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
import {VerkaufspreisSelectorService} from './verkaufspreis-selector.service';

export class Verkaufspreis {
    verkaufspreis_id: number;
    bezeichnung: string;
    erstellt: Date;
    erstellt_von: number;
    editiert: Date;
    editiert_von: number;
}

export interface VerkaufspreisApi {
    records: Verkaufspreis[];
    count: number;
}

@Component({
    selector: 'kiene-verkaufspreis-selector',
    templateUrl: './verkaufspreis-selector.component.html',
    styleUrls: ['./verkaufspreis-selector.component.scss']
})
export class VerkaufspreisSelectorComponent implements OnInit, OnChanges {

    verkaufspreise: Verkaufspreis[] = [];
    vkCtrl = new UntypedFormControl();

    @Input('selectedVerkaufspreisId') selectedVerkaufspreisId: number;
    @Input('allowEmptySelection') allowEmptySelection = true;
    @Input('disabled') disabled = false;

    @Output('verkaufspreisSelectionChanged')
    verkaufspreisSelectionChanged = new EventEmitter<Verkaufspreis>();

    constructor(private _service: VerkaufspreisSelectorService) {
    }

    ngOnInit() {
        this.loadVerkaufspreise();

    }

    ngOnChanges() {
        const obj = {
            verkaufspreis_id: this.selectedVerkaufspreisId
        }
        this.vkCtrl.setValue(obj);
        if (this.disabled) {
            this.vkCtrl.disable();
        } else {
            this.vkCtrl.enable();
        }
    }

    comparator(v1: Verkaufspreis, v2: Verkaufspreis) {
        return v1.verkaufspreis_id === v2.verkaufspreis_id;
    }

    loadVerkaufspreise() {
        this._service.loadVerkaufspreise().subscribe(response => {
            this.verkaufspreise = response.records;
        });
    }

    verkaufspreisChanged(event: MatSelectChange) {
        this.verkaufspreisSelectionChanged.emit(event.value);
    }

}
