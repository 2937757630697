import {Component, Inject, OnInit} from '@angular/core';
import {KalenderEintrag, KalenderTyp} from '../classes/kalender-eintrag';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {UrlaubskalenderService} from '../urlaubskalender.service';
import {KieneSession} from '../../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import {HttpParams} from '@angular/common/http';

@Component({
    selector: 'kiene-krank-meldung-dialog',
    templateUrl: './krank-meldung-dialog.component.html',
    styleUrls: ['./krank-meldung-dialog.component.scss']
})
export class KrankMeldungDialogComponent implements OnInit {

    antrag: KalenderEintrag = new KalenderEintrag();

    currentUser: KieneSession;
    userPath = 'benutzer/read.php';
    params = new HttpParams().append('praxis', '1');

    datumVonCtrl: UntypedFormControl = new UntypedFormControl(null, Validators.required);
    datumBisCtrl: UntypedFormControl = new UntypedFormControl(null, Validators.required);
    beschreibungCtrl: UntypedFormControl = new UntypedFormControl();
    datumCtrl: UntypedFormGroup = new UntypedFormGroup({
        datumVonCtrl: this.datumVonCtrl,
        datumBisCtrl: this.datumBisCtrl
    });

    constructor(@Inject(MAT_DIALOG_DATA) data,
                private kalenderService: UrlaubskalenderService,
                public dialogRef: MatDialogRef<KrankMeldungDialogComponent>,
                private localStorageService: LocalStorageService) {
    }

    ngOnInit(): void {
        this.currentUser = this.localStorageService.getCurrentUser();
    }

    onNoClick() {
        this.dialogRef.close();
    }

    hasPermission() {
        return this.localStorageService.hasPermission(this.kalenderService.kalenderRechte.antragAndererBenutzer);
    }

    selectedAntragssteller(user) {
        this.currentUser = user;
    }

    antragStellen() {
        if (this.isValid()) {
            this.antrag.startdatum = this.datumVonCtrl.value;
            this.antrag.enddatum = this.datumBisCtrl.value;
            this.antrag.bemerkung = this.beschreibungCtrl.value;
            this.antrag.eintragtyp = KalenderTyp.Krankheit;
            this.antrag.eintragtyp_id = 2;
            this.antrag.genehmigt = 1;
            this.antrag.benutzer_id = this.currentUser.benutzer_id;
            this.antrag.benutzername = this.currentUser.vorname + ' ' + this.currentUser.nachname;
            this.dialogRef.close(this.antrag);
        }

    }

    isValid(): boolean {
        this.datumCtrl.markAsTouched();
        this.datumVonCtrl.markAsTouched();
        this.datumBisCtrl.markAsTouched();
        return this.datumVonCtrl.valid && this.datumBisCtrl.valid;
    }
}
