<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
    <mat-horizontal-stepper #stepper [linear]="true">
        <mat-step>
            <ng-template matStepLabel>Dokumentenfelder ausfüllen</ng-template>
            <div>
                <div *ngFor="let e of elements" class="form-element" [ngStyle]="
                        e.type === 'textarea' ||
                        e.type === 'description' ||
                        e.type === 'headline' ||
                        e.type === 'nl'
                            ? { width: '100%' }
                            : {width: 'auto'}
                    ">
                    <mat-form-field *ngIf="e.type === 'number'">
                        <mat-label>{{ e.title }}</mat-label>
                        <input matInput type="number" [formControl]="formControls[e.name]" />
                    </mat-form-field>

                    <mat-form-field *ngIf="e.type === 'text'">
                        <mat-label>{{ e.title }}</mat-label>
                        <input matInput type="text" [formControl]="formControls[e.name]" />
                    </mat-form-field>

                    <mat-form-field *ngIf="e.type === 'disabledText'" style="min-width: 350px">
                        <mat-label>{{ e.title }}</mat-label>
                        <input matInput type="text" [formControl]="formControls[e.name]" />
                    </mat-form-field>

                    <mat-form-field *ngIf="e.type === 'textarea'" style="width: 100%">
                        <mat-label>{{ e.title }}</mat-label>
                        <textarea matInput [formControl]="formControls[e.name]" rows="3"></textarea>
                    </mat-form-field>

                    <mat-form-field *ngIf="e.type === 'date'">
                        <mat-label>{{ e.title }}</mat-label>
                        <input matInput [matDatepicker]="picker" [formControl]="formControls[e.name]" />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field *ngIf="e.type === 'selection'" style="min-width: 350px">
                        <mat-label>{{ e.title }}</mat-label>
                        <mat-select [formControl]="formControls[e.name]">
                            <mat-option *ngFor="let o of e.defaultValue" [value]="o.value">{{ o.key }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-checkbox *ngIf="e.type === 'checkbox'" [formControl]="formControls[e.name]">{{ e.title }}
                    </mat-checkbox>

                    <!-- </div> -->
                    <h4 *ngIf="e.type === 'headline'">{{ e.title }}</h4>
                    <p *ngIf="e.type === 'description'">{{ e.title }}</p>
                    <div style="width: 100%" *ngIf="e.type === 'nl'"></div>
                </div>
                <div>
                    <button mat-button matStepperNext>Weiter</button>
                </div>
            </div>
        </mat-step>

        <mat-step *ngIf="!tierarztGesetzt">
            <ng-template matStepLabel>Tierarzt ausw&auml;hlen</ng-template>
            <kiene-autocomplete [focus]="false" [genericApiUrl]="'tierarzt/read_autocomplete.php'"
                [placeholder]="'Tierarzt'" [displayFields]="['vorname', 'nachname']"
                (elementSelected)="setSelectedTierarzt($event)"></kiene-autocomplete>
            <div>
                <button mat-button matStepperPrevious>Zurück</button>
                <button mat-button matStepperNext>Weiter</button>
            </div>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Dokument unterschreiben</ng-template>
            <div>
                <kiene-qr-code-sign-field [signature]="signature"></kiene-qr-code-sign-field>
                <button mat-button matStepperPrevious>Zurück</button>
                <button mat-flat-button color="accent" (click)="createPdf()">
                    Abschließen
                </button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>