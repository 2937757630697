import { GruppeTier } from "../../administration/client-group/gruppe-tier";

export class Gruppe {
    aufstalleinheit_jahr: number;
    aufstalleinheit_nummer: number;
    gruppe_id: number;
    bezeichnung: string;
    produktionsabschnitt_id: number;
    produktionsabschnitt: string;
    herdenname: string;
    stall: string;
    einstalldatum_von: Date;
    einstalldatum_bis: Date;
    alter_bei_einstallung: number;
    anzahl_tiere: number;
    alter_wochen: number;
    alter_tage: number;
    gruppe_id_quelle: number;
    kunde_quelle: string;
    kunde_quelle_zusatz: string;
    gruppe_id_ziel: number;
    kunde_ziel: string;
    kunde_ziel_zusatz: string;
    gruppe_nr_intern: number;
    kunde_nr_intern: number;
    kunde_id: number;
    kunde: string;
    k_strasse: string;
    k_plz: string;
    k_ort: string;
    // ortsteil: string;
    vvvo: number;
    zusatz: string;
    abgaenge: number;
    verkaeufe: number;
    verluste: number;
    tote: number;
    ohrmarke: number;
    lieferant_vvvo: number;
    historie: number;
    tiere_ohne_pass: number;
    paesse_ohne_tier: number;
    anzahl_abgaenge: number;
    anzahl_tiere_aktuell: number;
    anzahl_tiere_csv: number;
    anzahl_tiere_datensaetze: number;
    anzahl_tiere_gesamt: number;
    anzahl_tiere_hit: number;
    anzahl_tiere_match: number;
    anzahl_tiere_ohrmarken: number;
    anzahl_tote: number;
    hit_status_id: number;
}

export class TierauswahlEvent {
    tiere: GruppeTier[] = [];
    alleSelektiert: boolean;
}
