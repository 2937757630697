import { Client } from '../../../../../../projects/kiene-core/src/lib/components/client/classes/client';

export class GruppeDialogInput {

    kunde: Client;
    einstalldatumAusblenden: boolean;
    nameVorschlag: string;

    constructor(kunde: Client, einstalldatumAusblenden: boolean, nameVorschlag: string) {
        this.kunde = kunde;
        this.einstalldatumAusblenden = einstalldatumAusblenden;
        this.nameVorschlag = nameVorschlag;
    }
}
