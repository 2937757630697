import { UserRole } from '../user/user';

export interface UserroleApi {
    records: UserRole[];
    count: number;
}

export class UserroleRight {
    bb_id: number;
    benutzerrolle_id: number;
    benutzerrolle: string;
    benutzerrecht_id: number;
    benutzerrecht: string;
}

export interface UserroleRightApi {
    records: UserroleRight[];
    count: number;
}

export class Userright {
    benutzerrecht_id: number;
    bezeichnung: string;
}

export interface UserrightApi {
    records: Userright[];
    count: number;
}