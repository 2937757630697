import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {WarenkorbPosition, WarenkorbPositionApi} from './warenkorb';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {Observable, throwError} from 'rxjs';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable({
    providedIn: 'root',
})
export class WarenkorbService {
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    getWarenkorbContent(): Observable<WarenkorbPositionApi> {
        if (!this.localStorageService.hasPermission(1771)) {
            return throwError(null);
        }
        return this.http.get<WarenkorbPositionApi>(
            this.apiBaseUrl + 'warenkorb/read.php'
        );
    }

    updateWarenkorbPosition(
        w: WarenkorbPosition
    ): Observable<WarenkorbPosition> {
        return this.http.post<WarenkorbPosition>(
            this.apiBaseUrl + 'warenkorb/update.php',
            w
        );
    }

    deleteWarenkorbPosition(w: WarenkorbPosition) {
        return this.http.post(
            this.apiBaseUrl + 'warenkorb/delete.php',
            w
        );
    }

    addArticleToWarenkorb(
        articleId: number,
        amount: number,
        kommentar: string
    ): Observable<WarenkorbPosition> {
        const w = new WarenkorbPosition();
        w.artikel_id = articleId;
        w.menge_soll = amount;
        w.kommentar = kommentar;
        w.status_id = 31;
        return this.http.post<WarenkorbPosition>(
            this.apiBaseUrl + 'warenkorb/update.php',
            w
        );
    }

    addWarenkorbPositionToWarenkorb(
        wkp: WarenkorbPosition
    ): Observable<WarenkorbPosition> {
        return this.addArticleToWarenkorb(
            wkp.artikel_id,
            wkp.menge_soll,
            wkp.kommentar
        );
    }

    releaseWarenkorb() {
        return this.http.post(
            this.apiBaseUrl + 'warenkorb/freigeben.php',
            '{}'
        );
    }

    updateShoppingCartBadge() {
        this.getWarenkorbContent().subscribe((response) => {
            const len = response.count;
            this.localStorageService.setNumberOfShoppingCartItems(len);
        });
    }
}
