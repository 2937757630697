export class SupplierMaterial {
    al_id: number;
    lieferant: string;
    lieferant_id: number;
    artikel_id: number;
    artikel: string;
    einzelpreis: number;
    skonto: number;
    zahlungsziel: number;
    rabatt: number;
    rabatt_lieferant: number;
    standard_lieferant: number;
    mandanten_ids: number[] = [];
}