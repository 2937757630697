<h1 mat-dialog-title>Ma&szlig;nahmenplan Kurz
    f&uuml;r {{currentClient?.bezeichnung_final}} {{currentClient?.zusatz_final}}</h1>
<div mat-dialog-content>
    <div class="info-box">
        <mat-checkbox [formControl]="abrechnenCtrl">Ma&szlig;nahmenplan abrechnen?</mat-checkbox>
        <span class="spacer"></span>
    </div>
    <mat-form-field style="width: 100%;">
        <mat-label>Jahr</mat-label>
        <input matInput type="number" maxlength="4" min="2018" max="2099" [formControl]="jahrCtrl">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Halbjahr</mat-label>
        <input matInput type="number" maxlength="1" min="1" max="2" [formControl]="halbjahrCtrl">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Therapieh&auml;ufigkeit</mat-label>
        <input matInput type="number" maxlength="4" [formControl]="therapieCtrl">
    </mat-form-field>
    <kiene-autocomplete *ngIf="showTierarztSelector" (elementSelected)="setTierarzt($event)" [placeholder]="'Tierarzt'"
                        [displayFields]="['vorname', 'nachname']" [genericApiUrl]="'tierarzt/read_autocomplete.php'"
                        [disabled]="!hasPermission(1153)"></kiene-autocomplete>
    <mat-checkbox [formControl]="betreuungsVetragCtrl">Betreuungsvertrag j/n</mat-checkbox>
    <mat-form-field style="width: 100%;">
        <mat-label>Ort</mat-label>
        <input matInput type="text" [formControl]="ortCtrl">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Datum</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="datumCtrl">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Abschlie&szlig;en</button>
</div>
