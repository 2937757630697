import { Injectable } from '@angular/core';
import { ZeitauswahlChange } from './zeitauswahl-change';

@Injectable({
  providedIn: 'root'
})
export class ZeitauswahlService {

  private start: Date;
  private end: Date;
  private aggregieren: string;
  private zeitauswahl: ZeitauswahlChange;

  constructor() { }

  public getZeitauswahl(): ZeitauswahlChange {
    if(!this.zeitauswahl){
      this.zeitauswahl = new ZeitauswahlChange();
      this.zeitauswahl.von = this.morgens(new Date());
      this.zeitauswahl.bis = this.abends(new Date());
      this.zeitauswahl.aggregieren = 'woche';
      this.zeitauswahl.aktuellerZeitinterval = 'monat';
    }
    return this.zeitauswahl;
  }

  public setZeitauswahl(zeitauswahl: ZeitauswahlChange) : void {
    this.zeitauswahl = zeitauswahl;
  }

  public getStart(): Date {
    if(!this.start){
      this.start = new Date();
    }
    return this.start;
  }

  public setStart(start: Date) : void {
    this.start = start;
  }
  
  public getEnd(): Date {
    if(!this.end){
      this.end = new Date();
    }
    return this.end;
  }

  public setEnd(end: Date) : void {
    this.end = end;
  }

  public getAggregieren(): string {
    return this.aggregieren;
  }

  public setAggregieren(aggregieren: string) : void {
    this.aggregieren = aggregieren;
  }

  morgens(d: Date){
    let date = new Date(d);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }

  abends(d: Date){
    let date = new Date(d);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }
}
