import * as moment from 'moment';

export class KieneUtils {
    public static getCalendarWeek(d: Date) {
        const weekNumber = moment(d).isoWeek();
        console.debug(
            'Week number of date %s is %d',
            d.toISOString(),
            weekNumber
        );
        return weekNumber;
    }

    public static getDateOfMonday(weekNumber: number, year: number) {
        const d = moment()
            .isoWeekYear(year)
            .isoWeek(weekNumber)
            .startOf('week')
            .toDate();
        d.setDate(d.getDate() + 1);
        return d;
    }

    public static getNumberOfWeeks(year: number) {
        return moment(new Date(year, 0, 1)).isoWeeksInYear();
    }

    public static addDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    public static isNullOrUndefined(s: any) {
        return s === null || s === undefined;
    }

    public static isNoU(s: any) {
        return this.isNullOrUndefined(s);
    }

    public static isNotNullOrUndefined(s: any) {
        return !this.isNoU(s);
    }

    public static isNNoU(s: any) {
        return this.isNotNullOrUndefined(s);
    }

    public static isNullOrEmpty(s: string) {
        return this.isNullOrUndefined(s) || s == '';
    }

    // public static isMobilePhone(): boolean {
    //     return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // }

    static beautifyDate(
        createdAt: Date,
        options?: { showSeconds?: boolean; showYear?: boolean }
    ) {
        const beautifiedDate = new Date(createdAt);
        const showSeconds = options?.showSeconds ?? false;
        const showYear = options?.showYear ?? false;
        const today = new Date();

        const time = `${
            beautifiedDate.getHours() <= 9
                ? '0' + beautifiedDate.getHours()
                : beautifiedDate.getHours()
        }:${
            beautifiedDate.getMinutes() <= 9
                ? '0' + beautifiedDate.getMinutes()
                : beautifiedDate.getMinutes()
        }${showSeconds ? ':' + beautifiedDate.getSeconds() : ''}`;

        // if tommorrow
        if (
            beautifiedDate.getDate() ===
            moment(today).clone().add(1, 'day').toDate().getDate()
        ) {
            return `Morgen ${time}`;
        }
        // if today
        if (
            beautifiedDate.getFullYear() === today.getFullYear() &&
            beautifiedDate.getMonth() === today.getMonth() &&
            beautifiedDate.getDate() === today.getDate()
        ) {
            return `Heute ${time}`;
        }
        // if yesterday
        if (
            beautifiedDate.getFullYear() === today.getFullYear() &&
            beautifiedDate.getMonth() === today.getMonth() &&
            beautifiedDate.getDate() ===
            moment(today).clone().subtract(1, 'day').toDate().getDate()
        ) {
            moment(today).get('month');
            return `Gestern ${time}`;
        }
        // if this year
        if (beautifiedDate.getFullYear() === today.getFullYear()) {
            return `${beautifiedDate.getDate()}.${
                moment(beautifiedDate).get('month') + 1
            }${showYear ? ':' + beautifiedDate.getFullYear() : ''} ${time}`;
        }

        return `${beautifiedDate.getDate()}.${
            moment(beautifiedDate).get('month') + 1
        }.${beautifiedDate.getFullYear()} ${time}`;
    }
}
