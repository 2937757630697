import {NgModule} from '@angular/core';
import {FinanzenComponent} from './finanzen.component';
import {AddPreisgruppeComponent} from './preisgruppen/add-preisgruppe/add-preisgruppe.component';
import {
    AddPreisgruppeArtikelComponent
} from './preisgruppen/preisgruppe-artikel/add-preisgruppe-artikel/add-preisgruppe-artikel.component';
import {PreisgruppeArtikelComponent} from './preisgruppen/preisgruppe-artikel/preisgruppe-artikel.component';
import {PreisgruppenComponent} from './preisgruppen/preisgruppen.component';
import {RechnungDetailsComponent} from './rechnungen/rechnung-details/rechnung-details.component';
import {RechnungenComponent} from './rechnungen/rechnungen.component';

import {FinanzenRoutingModule} from './finanzen-routing.module';
import {KieneTableModule} from '../components/kiene-table/kiene-table.module';
import {PrimanotaComponent} from './primanota/primanota.component';
import {GutschriftenComponent} from './gutschriften/gutschriften.component';
import {RechnungStornoDialogComponent} from './rechnungen/rechnung-storno-dialog/rechnung-storno-dialog.component';
import {
    EditRechnungPositionDialogComponent
} from './rechnungen/edit-rechnung-position-dialog/edit-rechnung-position-dialog.component';
import {
    DeleteRechnungPositionDialogComponent
} from './rechnungen/delete-rechnung-position-dialog/delete-rechnung-position-dialog.component';
import {GutschriftDetailsComponent} from './gutschriften/gutschrift-details/gutschrift-details.component';
import {
    GutschriftDetailsDialogComponent
} from './gutschriften/gutschrift-details-dialog/gutschrift-details-dialog.component';
import {ArticleScanAutocompleteModule} from '../components/article-scan-autocomplete/article-scan-autocomplete.module';
import {DeleteButtonModule} from '../components/delete-button/delete-button.module';
import {RechnungBelegeDialogComponent} from './rechnungen/rechnung-belege-dialog/rechnung-belege-dialog.component';
import {RechnungEmailDialogComponent} from './rechnungen/rechnung-email-dialog/rechnung-email-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {DirectivesModule} from '../../../projects/kiene-core/src/lib/directives/directives.module';
import {MatChipsModule} from '@angular/material/chips';
import {MatToolbarModule} from '@angular/material/toolbar';
import {KieneAutocompleteModule} from '../components/kiene-autocomplete/kiene-autocomplete.module';


@NgModule({
    declarations: [
        FinanzenComponent,
        PreisgruppenComponent,
        AddPreisgruppeComponent,
        PreisgruppeArtikelComponent,
        AddPreisgruppeArtikelComponent,
        RechnungenComponent,
        RechnungDetailsComponent,
        PrimanotaComponent,
        GutschriftenComponent,
        RechnungStornoDialogComponent,
        EditRechnungPositionDialogComponent,
        DeleteRechnungPositionDialogComponent,
        GutschriftDetailsComponent,
        GutschriftDetailsDialogComponent,
        RechnungBelegeDialogComponent,
        RechnungEmailDialogComponent,
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatCardModule,
        MatMenuModule,
        MatChipsModule,
        MatToolbarModule,
        KieneAutocompleteModule,
        DirectivesModule,
        MatDialogModule,
        KieneTableModule,
        FinanzenRoutingModule,
        ArticleScanAutocompleteModule,
        DeleteButtonModule
    ],
    exports: []
})
export class FinanzenModule {
}
