import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpParams } from '@angular/common/http';

import {
    KontaktAnlegenDialogComponent
} from '../../gruppen/gruppe-abgang/ausstallen-empfaenger-dialog/kontakt-anlegen-dialog/kontakt-anlegen-dialog.component';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../services/message-service/message.service';
import { MatDialog } from '@angular/material/dialog';
import { Gruppe } from '../../gruppen/classes/gruppe';
import { Client } from '../../client/classes/client';

@Component({
    selector: 'kiene-meine-kontakte',
    templateUrl: './meine-kontakte.component.html',
    styleUrls: ['./meine-kontakte.component.scss']
})
export class MeineKontakteComponent implements OnInit {

    searchCtrl = new FormControl();
    kontakte: Client[] = [];
    ladeKontakteSub: Subscription;

    @Input() kunde_id: number;
    @Input('neuenKontaktErstellen') neuenKontaktErstellen = true;
    @Input('intern') intern = false;
    @Input('extern') extern = true;
    @Output('kontaktAusgewaehlt') kontaktAusgewaehlt = new EventEmitter<Client>();

    constructor(@Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.searchCtrl.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(300))
            .subscribe({
                next: (value) => {
                    this.ladeKontakte(value);
                },
            });
        this.ladeKontakte();
    }

    ladeKontakte(search?: string) {
        let params = new HttpParams().set('offset', '0');
        params = params.set('limit', '20');
        params = params.set('intern', this.intern ? '1' : '0');
        params = params.set('extern', this.extern ? '1' : '0');
        if (search) {
            params = params.set('search', search);
        }

        this.ladeKontakteSub?.unsubscribe();
        this.ladeKontakteSub = this.api
            .getAll(
                this.apiBaseUrl + 'kunde_kunde/read_kundenportal.php',
                params
            )
            .subscribe({
                next: (response) => {
                    this.kontakte = response.records;
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }

    kontaktAuswaehlen(kontakt: Client) {
        this.kontaktAusgewaehlt.emit(kontakt);
    }

    neuenKontakt() {
        const dialogRef = this.dialog.open(KontaktAnlegenDialogComponent, {
            data: this.kunde_id
        });
        dialogRef.afterClosed().subscribe({
            next: (kunde: Client) => {
                if (kunde) {
                    this.searchCtrl.setValue(undefined, { emitEvent: false });
                    this.ladeKontakte();
                    this.kontaktAuswaehlen(kunde);
                }
            },
        });
    }

}
