import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {API_BASE_URL_SERVICE} from "../../../../../../projects/kiene-core/src/lib/kiene-core.config";
import {AbhollisteNumberInputDialogResult} from './abholliste-number-input-dialog';

@Component({
    selector: 'kiene-abhol-liste-number-input-dialog',
    templateUrl: './abholliste-number-input-dialog.component.html',
    styleUrls: ['./abholliste-number-input-dialog.component.scss']
})
export class AbhollisteNumberInputDialogComponent implements OnInit {

    public anzahlTiereCtrl: FormControl = new FormControl<number>(undefined);
    public inAbhollisteCtrl = new FormControl<boolean>(false);

    constructor(
        public dialogRef: MatDialogRef<AbhollisteNumberInputDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public nichtInAbholliste: boolean,
        @Inject(API_BASE_URL_SERVICE) public apiBaseUrl: string
    ) {
    }

    ngOnInit(): void {
    }

    public save(): void {
        const result = new AbhollisteNumberInputDialogResult();
        result.confirmed = true;
        result.anzahl_tiere = this.anzahlTiereCtrl.value;
        result.in_abholliste_einbeziehen = this.inAbhollisteCtrl.value;
        this.dialogRef.close(result)
    }
}
