<kiene-kiene-table [tableDescriptor]="tableDescriptor" (optionClicked)="catchOptionEvent($event)"></kiene-kiene-table>
<!--<div class="kiene-content-page">-->
<!--  <mat-card>-->
<!--    <div *ngIf="isLoading" class="kiene-loading-spinner">-->
<!--      <mat-spinner *ngIf="isLoading"></mat-spinner>-->
<!--    </div>-->
<!--    <div class="kiene-table-header">-->
<!--      <h3>Text Autovervollständigung</h3>-->
<!--      <button *kienePermission="[1412]" mat-icon-button color="primary" (click)="openAddTextProposalDialog()"><mat-icon>add</mat-icon></button>-->
<!--    </div>-->

<!--    <div>-->
<!--      <mat-form-field style="width: 100%;">-->
<!--        <input matInput placeholder="Suchen" #searchInput>-->
<!--      </mat-form-field>-->
<!--    </div>-->

<!--    <mat-table class="kiene-table" #table [dataSource]="textAutocompleteDataSource" matSort matSortActive="textkuerzel" matSortDisableClear-->
<!--      matSortDirection="asc">-->

<!--      <ng-container matColumnDef="textkuerzel">-->
<!--          <mat-header-cell *matHeaderCellDef mat-sort-header>Kürzel</mat-header-cell>-->
<!--          <mat-cell *matCellDef="let row">{{row.textkuerzel}}</mat-cell>-->
<!--        </ng-container>-->
<!--  -->
<!--        <ng-container matColumnDef="wortgruppe">-->
<!--          <mat-header-cell *matHeaderCellDef mat-sort-header>Text</mat-header-cell>-->
<!--          <mat-cell *matCellDef="let row">{{row.wortgruppe}}</mat-cell>-->
<!--        </ng-container>-->

<!--        <ng-container matColumnDef="options">-->
<!--            <mat-header-cell *matHeaderCellDef></mat-header-cell>-->
<!--            <mat-cell *matCellDef="let row">-->
<!--              <mat-menu #optionsMenue>-->
<!--                <ng-template matMenuContent>-->
<!--                  <button *kienePermission="[1413]" mat-menu-item (click)="openEditTextkuerzelDialog(row)">-->
<!--                    <mat-icon>edit</mat-icon>-->
<!--                    <span>editieren</span>-->
<!--                  </button>-->
<!--                  <button *kienePermission="[1414]" mat-menu-item (click)="openDeleteConfirmDialog(row)">-->
<!--                    <mat-icon>delete</mat-icon>-->
<!--                    <span>löschen</span>-->
<!--                  </button>-->
<!--                </ng-template>-->
<!--              </mat-menu>-->
<!--              <button *kienePermission="[1413]" mat-icon-button [matMenuTriggerFor]="optionsMenue">-->
<!--                <mat-icon>more_vert</mat-icon>-->
<!--              </button>-->
<!--            </mat-cell>-->
<!--          </ng-container>-->

<!--      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>-->
<!--      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>-->

<!--    </mat-table>-->
<!--    <mat-paginator [length]="totalCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>-->
<!--  </mat-card>-->
<!--</div>-->

