import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { slideInLeft, slideInRight } from './animations';

@Component({
  selector: 'kiene-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.css'],
  animations: [slideInLeft, slideInRight]
})
export class DeleteButtonComponent implements OnInit {

  @Input('disabled') disabled = false;
  @Input('color') color = '';

  @Output('delete') delete = new EventEmitter<boolean>();

  showIcon = true;

  constructor() { }

  ngOnInit(): void {
  }

  clickedIcon(event){
    event.stopPropagation();
    this.showIcon = false;
    setTimeout(() => this.showIcon = true, 3000);
  }

  clickedButton(event){
    event.stopPropagation();
    this.delete.emit(true);
  }

}

