<div style="padding: 24px">
    <h1>Gruppen&uuml;bersicht</h1>

    <div *ngIf="!chartData"
         style="width:100%;height: 50vh; display: flex; align-items: center; justify-content: center; text-align: center;">
        <mat-hint>Lade Gruppen&uuml;bersicht...</mat-hint>
    </div>

    <div *ngIf="chartData?.length === 0"
         style="width:100%;height: 50vh; display: flex; align-items: center; justify-content: center; text-align: center;">
        <mat-hint>keine Einträge vorhanden</mat-hint>
    </div>

    <div #chartParent style="width: calc(100vw - 50px); height: 70vh; display: block;">
        <google-chart [style.visibility]="chartData && chartData.length > 0 ? 'visible' : 'hidden'"
                      [type]="timelineType" [data]="chartData" [columns]="chartColumns" [height]="600"
                      [options]="options" (select)="select($event)"></google-chart>
    </div>
</div>
