export class Tierreklamation {
    tierreklamation_id: number;
    reklamation: string;
    anzahl: number; // nur für Filter

    constructor(tk_id?: number, text?: string) {
        this.tierreklamation_id = tk_id;
        this.reklamation = text;
    }
}
