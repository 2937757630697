import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


export class KieneNetworkEvent{
  status: number;
  method: string;
  url: string;
  body: string;
}
export class KieneNetworkLogEvent{
  timestamp: Date;
  network: KieneNetworkEvent;
  log: string;

  constructor(status: number, url: string, method: string, body: string, log: string){
    if(status){
      let n = new KieneNetworkEvent();
      n.status = status;
      n.url = url;
      n.body = body;
      n.method = method;
      this.network = n;
    }
    if(log){
      this.log = log;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  subject = new Subject<KieneNetworkLogEvent>();

  constructor() { }

  addEvent(event: KieneNetworkLogEvent){
    this.subject.next(event);
  }

  watchEvent(){
    return this.subject.asObservable();
  }
}
