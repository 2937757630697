<h1 mat-dialog-title>Produktkennzeichnung "{{produktkennzeichnung}}" zu Artikel hinzufügen</h1>
<div mat-dialog-content>
    <p>Es wurde kein Artikel zu den eingegeben Werten gefunden. Wollen Sie einen neuen Artikel anlegen?</p>

    <kiene-material-autocomplete (selectedMaterial)="selectMaterial($event)"></kiene-material-autocomplete>

    <mat-form-field style="width: 100%;">
        <mat-label>Produktkennzeichnungen</mat-label>
        <input matInput [formControl]="produktkennzeichnungCtrl">
    </mat-form-field>

</div>
<div align="end" mat-dialog-actions>
    <button (click)="close()" mat-button type="button">
        Abbrechen
    </button>
    <button (click)="confirm()" [disabled]="!artikel" color="accent" mat-flat-button type="submit">
        Speichern
    </button>
</div>