import {Component, Inject, OnInit} from '@angular/core';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {MatDialog} from '@angular/material/dialog';
import {MessageService} from '../../services/message.service';
import {
    ArrayTableColumn,
    KieneBooleanTableColumn,
    TableColumn,
    TextTableColumn
} from '../../components/kiene-table/table-column';
import {TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {environment} from '../../../environments/environment';
import {AddBetriebsartDialogComponent} from '../../dialogs/add-betriebsart-dialog/add-betriebsart-dialog.component';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {EditBetriebsartDialogComponent} from '../../dialogs/edit-betriebsart-dialog/edit-betriebsart-dialog.component';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {ProduktionsabschnitteComponent} from './produktionsabschnitte/produktionsabschnitte.component';
import {Betriebsart} from '../../../../projects/kiene-core/src/lib/components/stammdaten/vvvo/classes/betriebsart';
import {
    BetriebsartPreiseUndRabattDialogComponent
} from './betriebsart-preise-und-rabatt-dialog/betriebsart-preise-und-rabatt-dialog.component';
import {
    BetriebsartenTierartenDialogComponent
} from './betriebsarten-tierarten-dialog/betriebsarten-tierarten-dialog.component';


@Component({
    selector: 'kiene-betriebsarten',
    templateUrl: './betriebsarten.component.html',
    styleUrls: ['./betriebsarten.component.scss']
})
export class BetriebsartenComponent implements OnInit {

    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(public dialog: MatDialog,
                private messageService: MessageService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneTableService) {
    }

    ngOnInit() {

        this.tableDescriptor.headline = 'Betriebsarten';
        this.tableDescriptor.uniqueIdentifier = 'Betriebsarten';
        this.tableDescriptor.apiUrl = environment.BASE_URI + 'betriebsart/';
        this.tableDescriptor.route = '/administration/betriebsarten/';
        this.tableDescriptor.nameOfIdField = 'betriebsart_id';
        this.tableDescriptor.searchPlaceholder = 'Betriebsarten durchsuchen';
        this.tableDescriptor.columns.push(new TableColumn('betriebsart_id', 'ID'));
        this.tableDescriptor.columns.push(new TextTableColumn('bezeichnung', 'Bezeichnung'));
        this.tableDescriptor.columns.push(new KieneBooleanTableColumn('kaelbermast', 'Kälbermast'));
        this.tableDescriptor.columns.push(new KieneBooleanTableColumn('ohrmarke', 'Ohrmarke'));
        this.tableDescriptor.columns.push(new TableColumn('verkaufspreis', 'Verkaufspreis'));
        this.tableDescriptor.columns.push(new TableColumn('rabatt', 'Rabatt %'));
        this.tableDescriptor.columns.push(new KieneBooleanTableColumn('qs', 'QS Meldung'));
        this.tableDescriptor.columns.push(new TableColumn('qs_schluessel', 'QS Schlüssel'));
        this.tableDescriptor.columns.push(new KieneBooleanTableColumn('hit', 'HIT Meldung'));
        this.tableDescriptor.columns.push(new ArrayTableColumn('produktionsabschnitte', 'Produktionsabschnitte', ['produktionsabschnitt']));
        this.tableDescriptor.columns.push(new ArrayTableColumn('tierarten', 'Tierarten', ['bezeichnung']));
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;

        this.tableDescriptor.options.push(new TableOption('edit', 'bearbeiten', 'edit', 3103));
        this.tableDescriptor.options.push(new TableOption('produktionsabschnitte', 'Produktionsabschnitte', 'segment', 3113));
        this.tableDescriptor.options.push(new TableOption('tierarten', 'Tierarten', 'cruelty_free', 3113));
        this.tableDescriptor.options.push(new TableOption('preise_rabatt', 'Preise und Rabatt', 'euro', 3113));

        this.tableDescriptor.initialSortColumn = 'betriebsart_id';
        this.tableDescriptor.initialSortDirection = 'asc';
    }

    catchOptionEvent(event: TableOptionEvent) {
        const betriebsart: Betriebsart = event.value;
        if (event.name === 'add') {
            this.openAddDialog();
        } else if (event.name === 'edit') {
            this.openEditDialog(betriebsart);
        } else if (event.name === 'produktionsabschnitte') {
            this.produktionsabschnitteBearbeiten(betriebsart);
        } else if (event.name === 'tierarten') {
            this.tierartenBearbeiten(betriebsart);
        } else if (event.name === 'preise_rabatt') {
            this.openPreiseRabattDialog(betriebsart);
        }
    }

    produktionsabschnitteBearbeiten(betriebsart: Betriebsart) {
        const dialog = this.dialog.open(ProduktionsabschnitteComponent, {
            data: betriebsart,
            disableClose: true
        });

        dialog.afterClosed().subscribe(response => {
            if (response) {
                this.api.reload();
            }
        });
    }

    tierartenBearbeiten(betriebsart: Betriebsart) {
        const dialog = this.dialog.open(BetriebsartenTierartenDialogComponent, {
            data: betriebsart,
            disableClose: true
        });

        dialog.afterClosed().subscribe(response => {
            if (response) {
                this.api.reload();
            }
        });
    }

    openAddDialog() {
        const dialog = this.dialog.open(AddBetriebsartDialogComponent, {
            width: '40%',
            minWidth: '400px'
        });

        dialog.afterClosed().subscribe(response => {
            if (response) {
                this.saveBetriebsart(response);
            }
        });
    }

    openEditDialog(betriebsart: Betriebsart) {
        const dialog = this.dialog.open(EditBetriebsartDialogComponent, {
            width: '40%',
            minWidth: '400px',
            data: {
                betriebsart: betriebsart
            }
        });

        dialog.afterClosed().subscribe(response => {
            if (response) {
                this.saveBetriebsart(response);
            }
        });
    }

    saveBetriebsart(betriebsart: Betriebsart) {
        this.api.updateEntity(this.apiBaseUrl + 'betriebsart/update.php', betriebsart).subscribe(response => {
            this.messageService.infoMessage('Die Betriebsart ' + betriebsart.bezeichnung + ' wurde erfolgreich gespeichert!');
            this.api.reload();
        }, error => {
            this.messageService.errorMessage('Die Betriebsart konnte nicht gespeichert werden! ', error);
        });
    }

    private openPreiseRabattDialog(betriebsart: Betriebsart) {
        const dialogRef = this.dialog.open(BetriebsartPreiseUndRabattDialogComponent, {
            data: betriebsart
        });
        dialogRef.afterClosed().subscribe(response => {
            if (response) {
                this.api.reload();
            }
        });
    }
}
