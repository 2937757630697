<!-- <kiene-kiene-table [tableDescriptor]="tableDescriptor" (optionClicked)="catchOptionEvent($event)"></kiene-kiene-table> -->
<nav mat-tab-nav-bar>
    <a #belief="routerLinkActive" *kienePermission="[1081]" [active]="belief.isActive"
       [routerLink]="['/wawi/bestellung-extern/lieferanten']"
       mat-tab-link
       routerLinkActive>
        <mat-icon>medical_services</mat-icon>&nbsp;Arzneimittel</a>
    <a #begut="routerLinkActive" *kienePermission="[4101]" [active]="begut.isActive"
       [routerLink]="['/wawi/bestellung-extern/gutschriften']"
       mat-tab-link
       routerLinkActive>
        <mat-icon>money</mat-icon>
        &nbsp;Gutschriften</a>
    <a #begem="routerLinkActive" *kienePermission="[4201]" [active]="begem.isActive"
       [routerLink]="['/wawi/bestellung-extern/gemeinkosten']"
       mat-tab-link
       routerLinkActive>
        <mat-icon>people</mat-icon>
        &nbsp;Betriebl. Kosten</a>
    <a #behug="routerLinkActive" *kienePermission="[4211]" [active]="behug.isActive"
       [routerLink]="['/wawi/bestellung-extern/honorareundgehaelter']"
       mat-tab-link
       routerLinkActive>
        <mat-icon>euro</mat-icon>
        &nbsp;Honorare u. Geh&auml;lter</a>

</nav>
<router-outlet></router-outlet>
