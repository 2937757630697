import { Component, Input, OnInit } from '@angular/core';
import { EntityViewerService } from '../entity-viewer.service';

@Component({
  selector: 'kiene-entity-viewer',
  templateUrl: './entity-viewer.component.html',
  styleUrls: ['./entity-viewer.component.scss']
})
export class EntityViewerComponent implements OnInit {

  jsn: string;
  visible = false;
  opacity = 1;

  entity: any;

  constructor(private service: EntityViewerService) { }

  ngOnInit(): void {
    

    this.service.watchVisible().subscribe(v => this.visible = v);
    this.service.watchEntity().subscribe(e => {
      this.jsn = JSON.stringify(e, null, 4);
    });
  }

  changeOpacity(){
    console.debug('change opacity');
    if(this.opacity > 0.75){
      this.opacity = 0.75;
    }
    else if(this.opacity > 0.5){
      this.opacity = 0.5;
    }
    else if(this.opacity > 0.25){
      this.opacity = 0.25;
    }
    else if(this.opacity > 0.1){
      this.opacity = 0.1;
    }
    else if(this.opacity > 0){
      this.opacity = 1;
    }
  }

}
