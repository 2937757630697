import {Component, Inject, OnInit} from '@angular/core';
import {TableDescriptor} from '../../../components/kiene-table/table-descriptor';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {MessageService} from '../../../services/message.service';
import {AppService} from '../../../app.service';
import {GruppenService} from '../../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {TableOption, TableOptionEvent} from '../../../components/kiene-table/table-option';
import {
    NumberTableColumn,
    TableColumn,
    TextTableColumn
} from '../../../components/kiene-table/table-column';

@Component({
    selector: 'kiene-abgelaufene-chargen',
    templateUrl: './abgelaufene-chargen.component.html',
    styleUrls: ['./abgelaufene-chargen.component.scss']
})
export class AbgelaufeneChargenComponent implements OnInit {

    tableDescriptor = new TableDescriptor();

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private messageService: MessageService,
        private appService: AppService,
        private gruppenService: GruppenService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService
    ) {
    }

    ngOnInit(): void {
        this.initTable();
    }

    catchOptionEvent(event: TableOptionEvent) {

    }

    private initTable() {
        this.tableDescriptor.headline = 'Abgelaufene Chargen';
        this.tableDescriptor.uniqueIdentifier = 'Abgelaufene Chargen';
        this.tableDescriptor.alternativeApiUrl = this.apiBaseUrl + 'charge/read_abgelaufen.php';
        this.tableDescriptor.initialSortColumn = 'artikel';
        this.tableDescriptor.initialSortDirection = 'asc';
        this.tableDescriptor.showDetails = false;

        this.tableDescriptor.permissionView = 4441;
        this.tableDescriptor.permissionCreate = 4442;
        this.tableDescriptor.permissionUpdate = 4442;
        this.tableDescriptor.permissionDelete = 4444;

        this.tableDescriptor.createAllowed = false;

        this.tableDescriptor.columns = this.getTableColumns();

        this.tableDescriptor.options = this.getTableOptions();
    }

    private getTableColumns() {
        const columns: TableColumn[] = [];

        columns.push(new TextTableColumn('artikel_me', 'Artikel'));
        columns.push(new TextTableColumn('charge', 'Charge'));
        columns.push(new TextTableColumn('mhd', 'MHD'));
        columns.push(new NumberTableColumn('menge', 'Menge', '1.0-2'));
        columns.push(new TextTableColumn('lagerplatz', 'Lagerplatz'));


        return columns;
    }


    private getTableOptions() {
        const options: TableOption[] = [];

        return options;
    }


}
