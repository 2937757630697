<div>
    <mat-tab-group [selectedIndex]="selectedTab" (selectedTabChange)="setSelectedTab($event)">

        <mat-tab *kienePermission="[3851]" label=Bestandsbesuchsprotokoll>
            <ng-template matTabContent>
                <kiene-bestandsbesuchsprotokoll></kiene-bestandsbesuchsprotokoll>
            </ng-template>
        </mat-tab>

        <mat-tab *kienePermission="[9311]" [label]="'Tierwohlprotokoll Rind'">
            <ng-template matTabContent>
                <kiene-tierwohlprotokoll-rind></kiene-tierwohlprotokoll-rind>
            </ng-template>
        </mat-tab>

        <mat-tab *kienePermission="[9311]" [label]="'Tierwohlprotokoll Schwein'">
            <ng-template matTabContent>
                <kiene-tierwohlprotokoll-schwein></kiene-tierwohlprotokoll-schwein>
            </ng-template>
        </mat-tab>

    </mat-tab-group>
</div>