<div  [style.padding]="dialog ? '0' : '0 24px 64px 24px'">

    <kiene-page-header *ngIf="!dialog" [ueberschrift]="ueberschrift"></kiene-page-header>

    <div *ngIf="isLoading" style="padding: 64px 0; display: flex; align-items: center; justify-content: center">
        <span>Lade Tier...</span>
    </div>

    <div *ngIf="!isLoading">

        <h3 style="margin-block-end: 12px; margin-block-start: 0">Ohrmarke {{this.tier?.ohrmarke_notnull}}</h3>

        <div style="padding-bottom: 48px;">
            <h4>Bemerkungen</h4>

            <div class="one-row">
                <kiene-autocomplete style="width: 90%" #bemerkungenAutocomplete (elementSelected)="addBemerkung($event)" [clearAfterSelect]="true"
                                    [genericApiUrl]="bemerkungenAutocompleteUrl" [placeholder]="'Bemerkung hinzufügen'"
                                    [displayFields]="['bemerkung']" [showClear]="false" [focus]="false"
                                    [noPaddingNoMargin]="true">
                </kiene-autocomplete>
                <button mat-icon-button color="accent" (click)="add()"><mat-icon>add</mat-icon></button>
            </div>

            <div style="padding-top: 24px; width: 100%; display: flex; align-items: center; justify-content: center; text-align: center"
                 *ngIf="tier?.bemerkungen?.length === 0">
                <mat-hint style="font-size: 0.9em">- keine Bemerkungen hinterlegt -</mat-hint>
            </div>

            <table aria-label="" *ngIf="tier?.bemerkungen?.length > 0" style="width: 100%;">
                <thead>
                <th></th>
                <th></th>
                <th></th>
                </thead>
                <tbody>
                <tr *ngFor="let bemerkung of tier?.bemerkungen">
                    <td>{{bemerkung?.bemerkung}}</td>
                    <td>{{bemerkung?.vorname?.substring(0,1)}}. {{bemerkung?.nachname}}</td>
                    <td>{{bemerkung?.erstellt | date: 'dd.MM.YY HH:mm'}}</td>
                    <td>
                        <button mat-icon-button color="accent" (click)="deleteBemerkung(bemerkung)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div [style]="dialog ? 'position: sticky; bottom: 0; background-color: white; padding: 12px 0 0 0;' : 'padding-top: 48px'"
             class="one-row" [style.justify-content]="dialog ? 'flex-start !important' : 'center !important'">
            <button color="accent" mat-flat-button
                    data-cy="bemerkung-save"
                    (click)="bemerkungenHinzufuegen()" [disabled]="isSaving">
                Speichern
            </button>
            <span style="margin-right: 12px"></span>
            <ng-content select="[additional-actions]"></ng-content>
        </div>
    </div>
</div>
