<h1 mat-dialog-title>Produktionsabschnitte {{betriebsart.bezeichnung}}</h1>
<div mat-dialog-content>
    <div class="info-box">
        Bitte die Produktionsabschnitte in der entsprechenden Reihenfolge eintragen
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <button style="margin-bottom: 12px;" mat-icon-button color="accent"
                (click)="addOrEdit()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <p *ngIf="!betriebsart?.produktionsabschnitte || betriebsart?.produktionsabschnitte?.length === 0">Keine
        Produktionsabschnitte hinterlegt</p>
    <ul *ngIf="betriebsart?.produktionsabschnitte?.length>0" cdkDropList class="list"
        (cdkDropListDropped)="drop($event)">
        <li class="one-row box" *ngFor="let abschnitt of betriebsart?.produktionsabschnitte; let i = index" cdkDrag>
            <mat-icon>drag_handle</mat-icon>
            <span>{{i + 1}}. {{abschnitt.produktionsabschnitt}}</span>
            <span class="spacer"></span>
            <button mat-icon-button color="accent" [matMenuTriggerFor]="abschnittMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #abschnittMenu>
                <button mat-menu-item (click)="addOrEdit(abschnitt)">
                    <mat-icon>edit</mat-icon>
                    <span>bearbeiten</span>
                </button>
                <!--                <button mat-menu-item (click)="addOrEdit(abschnitt)">-->
                <!--                    <mat-icon>delete</mat-icon>-->
                <!--                    <span>l&ouml;schen</span>-->
                <!--                </button>-->
            </mat-menu>
        </li>
    </ul>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</div>
