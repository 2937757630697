<kiene-kiene-table [tableDescriptor]="tableDescriptor" (optionClicked)="catchOptionEvent($event)"></kiene-kiene-table>

<!--<div class="kiene-content-page">-->
<!--  <mat-card>-->
<!--    <div *ngIf="isLoading" class="kiene-loading-spinner">-->
<!--      <mat-spinner *ngIf="isLoading"></mat-spinner>-->
<!--    </div>-->
<!--    <div class="kiene-table-header">-->
<!--      <h3>Antibiogramme</h3>-->
<!--      <mat-checkbox *ngIf="showTierarztGruppeCheckbox" [(ngModel)]="queryOnlyTierarztgruppe" (change)="filterTierarztgruppe()">Nur meine Tierarztgruppen</mat-checkbox>-->
<!--      <div>-->
<!--        <button *kienePermission="[1812]" mat-icon-button color="primary" (click)="openNewAntibiogramm()">-->
<!--          <mat-icon>add</mat-icon>-->
<!--        </button>-->
<!--        <button *kienePermission="[1818]" mat-icon-button color="primary" (click)="openDownloadAllDialog()">-->
<!--          <mat-icon>save_alt</mat-icon>-->
<!--        </button>-->
<!--        <button *ngIf="params.keys().length > 0" mat-icon-button color="primary" (click)="clearParams()">-->
<!--          <mat-icon>clear</mat-icon>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div>-->
<!--      <mat-form-field style="width: 100%;">-->
<!--        <input matInput placeholder="Suchen" #searchInput>-->
<!--      </mat-form-field>-->
<!--    </div>-->

<!--    <mat-table class="kiene-table" #table [dataSource]="antibiogrammDataSource" matSort matSortActive="editiert"-->
<!--      matSortDisableClear matSortDirection="desc">-->

<!--      <ng-container matColumnDef="tagebuch_nr">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header>Tagebuchnummer</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.antibiogramm_id)">{{row.tagebuch_nr}}</mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="kunde">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Kunde</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.antibiogramm_id)">{{row.kunde}}</mat-cell>-->
<!--      </ng-container>-->
<!--      -->
<!--      <ng-container matColumnDef="tierarztgruppe">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Tierarzt-Grp.</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.antibiogramm_id)">{{row.tierarztgruppe}}</mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="gruppe">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Gruppe</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.antibiogramm_id)">{{row.gruppe}}</mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="eingang">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Untersuchungsbeginn</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.antibiogramm_id)">{{row.eingang | date: 'shortDate':-->
<!--          '+0200': 'de'}}</mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="erstellt">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Erstellt</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.antibiogramm_id)">{{row.erstellt | date: 'shortDate':-->
<!--          '+0200': 'de'}}</mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="editiert">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Aktualisiert</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.antibiogramm_id)">{{row.editiert | date: 'shortDate':-->
<!--          '+0200': 'de'}}</mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="status">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Status</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.antibiogramm_id)">{{getStatusText(row.status)}}</mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="pdf">-->
<!--        <mat-header-cell *matHeaderCellDef disableClear="true">PDF</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" style="cursor: pointer;">-->
<!--          <div *kienePermission="[1817]">-->
<!--            <button *ngIf="row.pdf_id" mat-icon-button (click)="openPdf(row)">-->
<!--              <mat-icon color="primary">cloud_download</mat-icon>-->
<!--            </button>-->
<!--          </div>-->
<!--        </mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="options">-->
<!--        <mat-header-cell *matHeaderCellDef></mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row">-->
<!--          <mat-menu #antibiogrammOptionsMenu>-->
<!--            <ng-template matMenuContent>-->
<!--              <button *kienePermission="[1814]" mat-menu-item (click)="openConfirmDeleteAntibiogrammDialog(row)">-->
<!--                <mat-icon>delete</mat-icon>-->
<!--                <span>Antibiogramm l&ouml;schen</span>-->
<!--              </button>-->
<!--              <div *ngIf="row.befund_id">-->
<!--                <button *kienePermission="[1311]" mat-menu-item (click)="goToBefund(row)">-->
<!--                  <mat-icon>play_arrow</mat-icon>-->
<!--                  <span>zu Befundmitteilung gehen</span>-->
<!--                </button>-->
<!--              </div>-->
<!--              -->
<!--              <button *kienePermission="[1816]" mat-menu-item (click)="recreateAntibiogramm(row)">-->
<!--                <mat-icon>edit</mat-icon>-->
<!--                <span>Antibiogramm PDF neu erstellen</span>-->
<!--              </button>-->

<!--            </ng-template>-->
<!--          </mat-menu>-->
<!--          <button *kienePermission="[1814]" mat-icon-button [matMenuTriggerFor]="antibiogrammOptionsMenu">-->
<!--            <mat-icon>more_vert</mat-icon>-->
<!--          </button>-->
<!--        </mat-cell>-->
<!--      </ng-container>-->

<!--      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>-->
<!--      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>-->

<!--    </mat-table>-->
<!--    <mat-paginator [length]="totalCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>-->
<!--  </mat-card>-->
<!--</div>-->
