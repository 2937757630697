<h1 mat-dialog-title>Neue Charge erstellen</h1>
<div mat-dialog-content>
    <kiene-article-scan-autocomplete [required]="true" (selectedMaterial)="setSelectedMaterial($event)"
        [placeholder]="'Artikel scannen oder eingeben'">
    </kiene-article-scan-autocomplete>

    <mat-form-field style="margin-right: 12px;">
        <mat-label>Charge</mat-label>
        <input matInput type="text" [formControl]="chargeCtrl" required>
    </mat-form-field>

    <mat-form-field>
        <mat-label>MHD</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="mhdCtrl" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

</div>

<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()"
        [disabled]="!(material && chargeCtrl.valid && mhdCtrl.valid)">Erstellen</button>
</div>