import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TierlistenelementComponent} from './tierlistenelement/tierlistenelement.component';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {DirectivesModule} from '../../directives/directives.module';


@NgModule({
    declarations: [
        TierlistenelementComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        DirectivesModule
    ],
    exports: [
        TierlistenelementComponent
    ]
})
export class TierlistenelementModule {
}
