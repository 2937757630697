import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Material } from '../../../../warehouse/material/material';
import {
    KieneBackendApiService
} from '../../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { API_BASE_URL_SERVICE } from '../../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { HttpParams } from '@angular/common/http';
import { BestandElementCharge } from '../../../../warehouse/bestand/bestand-element';


@Component({
    selector: 'kiene-charge-auswahl-dialog',
    templateUrl: './charge-auswahl-dialog.component.html',
    styleUrls: ['./charge-auswahl-dialog.component.scss']
})
export class ChargeAuswahlDialogComponent implements OnInit {
    bestandChargen: BestandElementCharge[] = [];
    currentIndex = 0;
    rowHandler = Array<boolean>();
    listeLaenge: number;

    constructor(@Inject(MAT_DIALOG_DATA) public article: Material,
        @Inject(API_BASE_URL_SERVICE) private apiBaseURL: string,
        private kieneBackendService: KieneBackendApiService,
        public dialogRef: MatDialogRef<ChargeAuswahlDialogComponent>) {
    }

    ngOnInit(): void {
        this.ladeChargen();
        this.rowHandler[0] = true;
    }


    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        switch (event.key) {
            case 'ArrowDown':
                if (this.currentIndex < this.bestandChargen.length) {
                    this.nextRow();
                } else {
                    this.firstRow();
                }
                break;
            case 'ArrowUp':
                if (this.currentIndex > 0) {
                    this.lastRow();
                } else {
                    this.endRow();
                }
                break;
            case 'Tab':
                event.stopPropagation();
                if (this.currentIndex < this.bestandChargen.length) {
                    this.nextRow();
                } else {
                    this.firstRow();
                }
                break;
            case 'ArrowRight':
                event.stopPropagation();
                this.confirm();
                break;
            case 'Escape':
                this.dialogRef.close(undefined);
            /*case 'Enter':
                event.stopPropagation();
                this.confirm();
                break;*/
            //     if (this.listeLaenge) {
            //         event.stopPropagation();
            //         
            //     }
            //     break;

        }
    }

    nextRow() {
        this.rowHandler[this.currentIndex] = false;
        this.currentIndex++;
        this.rowHandler[this.currentIndex] = true;
    }

    lastRow() {
        this.rowHandler[this.currentIndex] = false;
        this.currentIndex--;
        this.rowHandler[this.currentIndex] = true;
    }

    firstRow() {
        this.rowHandler[this.currentIndex] = false;
        this.currentIndex = 0;
        this.rowHandler[this.currentIndex] = true;
    }

    endRow() {
        this.rowHandler[this.currentIndex] = false;
        this.currentIndex = this.bestandChargen.length;
        this.rowHandler[this.currentIndex] = true;
    }


    ladeChargen() {
        let params = new HttpParams().set('artikel_id', this.article.artikel_id);
        params = params.append('lager_id[]', '1');
        params = params.append('lager_id[]', '2');
        this.kieneBackendService.get(this.apiBaseURL + 'bestand_charge/read.php', params).subscribe({
            next: ((res) => {
                this.bestandChargen = res.records;
                this.listeLaenge = this.bestandChargen.length;
            })
        });
    }


    confirm(index?: number) {
        console.log(index);
        if (index > -1 && index < this.bestandChargen?.length) {
            this.currentIndex = index;
        }
        this.dialogRef.close(this.bestandChargen[this.currentIndex]);
    }

}
