import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Gruppe} from '../../../gruppen/classes/gruppe';
import {TierFilterSettings} from './classes/tier-filter-settings';
import {TierFilter} from './classes/tier-filter';
import {UntypedFormControl} from '@angular/forms';
import {Haendler} from '../../../stammdaten/haendler/haendler';
import {
    GruppeTierEinstalldatumFilterComponent
} from '../../../../components/gruppe-tier/filter/gruppe-tier-einstalldatum-filter/gruppe-tier-einstalldatum-filter.component';
import {
    GruppeTierEinstalldatum
} from '../../../../components/gruppe-tier/filter/gruppe-tier-einstalldatum-filter/gruppe-tier-einstalldatum';
import {
    GruppeTierViehhaendlerFilterComponent
} from '../../../../components/gruppe-tier/filter/gruppe-tier-viehhaendler-filter/gruppe-tier-viehhaendler-filter.component';


@Component({
    selector: 'kiene-gruppe-tier-filter-dialog',
    templateUrl: './gruppe-tier-filter-dialog.component.html',
    styleUrls: ['./gruppe-tier-filter-dialog.component.scss']
})
export class GruppeTierFilterDialogComponent implements OnInit {

    @ViewChild('filterEinstalldatum') filterEinstalldatum: GruppeTierEinstalldatumFilterComponent;
    @ViewChild('filterViehhaendler') filterViehhaendler: GruppeTierViehhaendlerFilterComponent;

    gruppe: Gruppe;
    filterSettings: TierFilterSettings = new TierFilterSettings();
    selectedTierFilterCtrl: UntypedFormControl = new UntypedFormControl();
    info: string;

    constructor(
        public dialogRef: MatDialogRef<GruppeTierFilterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
    ) {
        if (data.filterSettings) {
            this.filterSettings = data.filterSettings.clone();
            console.log(this.filterSettings);
        }
        this.selectedTierFilterCtrl.setValue(this.filterSettings?.filter);
        this.gruppe = this.data?.gruppe;
        if (data?.info) {
            this.info = data?.info;
        }
    }


    ngOnInit(): void {
        this.selectedTierFilterCtrl.valueChanges.subscribe(tierFilter => {
            this.filterSettings.filter = tierFilter;
        });
    }

    setFilter(selected: TierFilter) {
        this.filterSettings.filter = selected;
    }

    delete() {
        this.dialogRef.close(new TierFilterSettings());
    }

    viehhaendlerSelected(haendler: Haendler[]) {
        if (haendler) {
            for (const h of haendler) {
                console.log('haendler: ' + h.name);
            }
            this.filterSettings.viehhaendler = haendler;
            this.filterEinstalldatum.update();
        }
    }

    einstalldatumSelected(einstalldaten: GruppeTierEinstalldatum[]) {
        if (einstalldaten) {
            for (const e of einstalldaten) {
                console.log('einstalldatum: ' + e.einstalldatum);
            }
            this.filterSettings.einstalldatum = einstalldaten;
            this.filterViehhaendler.update();
        }
    }
}
