<div *kienePermission="[4531]" style="padding: 24px;">
    <h1>Wochenend- und Feiertagsplanung</h1>
    <div>
        <div class="one-row" *kienePermission="[4530]">
            <div style="width: 33%;"></div>
            <span class="spacer"></span>
            <div class="one-row" style="width: 33%;">
                <span class="spacer"></span>
                <mat-form-field style="min-width: 300px;">
                    <mat-label>Dienstgruppe</mat-label>
                    <mat-select [formControl]="dienstgruppeCtrl" [compareWith]="dienstgruppeComparator">
                        <mat-option>Keine Gruppe</mat-option>
                        <mat-option *ngFor="let dg of dienstgruppen" [value]="dg">
                            <div [ngStyle]="{'color': dg.farbe, 'background-color': dg.hintergrundfarbe}"
                                 style="padding: 6px; border-radius: 6px; font-weight: bold;">
                                {{ dg.dienstgruppe }}
                            </div>
                        </mat-option>
                    </mat-select>
                    <button (click)="$event.stopPropagation(); dienstgruppeHinzufuegen()"
                            *ngIf="dienstgruppeHinzufuegenSichtbar()" color="accent"
                            mat-icon-button matSuffix>
                        <mat-icon>add</mat-icon>
                    </button>
                    <button (click)="$event.stopPropagation(); dienstgruppeEditieren()"
                            *ngIf="dienstgruppeEditierenSichtbar()"
                            color="accent"
                            mat-icon-button matSuffix>
                        <mat-icon>edit</mat-icon>
                    </button>
                </mat-form-field>
                <div style="margin: 0.25em 12px; align-self: flex-start;">
                    <div
                        [ngStyle]="{'background-color': dienstgruppeCtrl.value?.hintergrundfarbe, 'color': dienstgruppeCtrl.value?.farbe}"
                        style="width: 55px; height: 55px; border-radius: 12px; display: flex; flex-direction: column; justify-content: center;">
                        <span
                            style="width: 100%; font-weight: bold; text-align: center; font-size: 2em;">{{ dienstgruppeCtrl.value?.dienstgruppe?.slice(-1) }}</span>
                    </div>
                </div>
                <span class="spacer"></span>
            </div>
            <div class="one-row" style="width: 33%;">

                <div class="one-row">
                    <span class="tierarzt"
                          [ngStyle]="{'background-color': dienstgruppeCtrl.value?.hintergrundfarbe, 'color': dienstgruppeCtrl.value?.farbe}"
                          *ngFor="let t of dienstgruppeCtrl.value?.tieraerzte">{{ t.vorname }} {{ t.nachname }}</span>
                    <span class="spacer"></span>
                </div>
                <span class="spacer"></span>
            </div>
            <span class="spacer"></span>
        </div>

        <div class="one-row">
            <span class="spacer"></span>
            <button (click)="previousYear()" color="accent" mat-icon-button>
                <mat-icon>chevron_left</mat-icon>
            </button>
            <span>{{ currentYear }}</span>
            <button (click)="nextYear()" color="accent" mat-icon-button>
                <mat-icon>chevron_right</mat-icon>
            </button>
            <span class="spacer"></span>
        </div>

        <div class="one-row jahreskalender">
            <div *ngFor="let month of months; let monthIndex = index;" class="month-column">
                <p class="monat-titel">{{ month }}</p>
                <div (click)="createKalendereintragForDay(monthIndex, d)" *ngFor="let d of getDaysInMonth(monthIndex)"
                     [ngClass]="{'week-day': isWeekDay(monthIndex, d) }" class="one-row day-row"
                     mouseOverClass="day-mouse-over">
                    <div class="day-number">{{ d }}.</div>
                    <div class="day-content one-row">
                        <span class="day-name">{{ monthList[monthIndex][d - 1] | date: 'EE' }}</span>
                        <span class="feiertag-cell">{{ getFeiertag(monthIndex, d) }}</span>
                        <span
                            [ngStyle]="{'background-color': getBackgroundColor(monthIndex, d), 'color': getColor(monthIndex, d)}"
                            class="tierarztgruppe-cell">{{ getKalendereintragTierarztgruppe(monthIndex, d) }}</span>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>
