import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MatSelectChange} from '@angular/material/select';
import {BetriebsartSelectorService} from './betriebsart-selector.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {Betriebsart} from '../../../../projects/kiene-core/src/lib/components/stammdaten/vvvo/classes/betriebsart';

export interface BetriebsartApi {
    records: Betriebsart[];
    count: number;
}

export class BetriebsartSelectionChangeEvent {
    selectedBetriebsartId: number;
    betriebsart: Betriebsart;
}

@Component({
    selector: 'kiene-betriebsart-selector',
    templateUrl: './betriebsart-selector.component.html',
    styleUrls: ['./betriebsart-selector.component.scss']
})
export class BetriebsartSelectorComponent implements OnInit, OnChanges {

    betriebsarten: Betriebsart[] = [];

    betriebsartCtrl = new UntypedFormControl();

    @Input('required') required = false;

    @Input('selectedBetriebsartId') selectedBetriebsartId: number;

    @Output('betriebsartSelectionChanged')
    betriebsartSelectionChanged = new EventEmitter<BetriebsartSelectionChangeEvent>();

    constructor(private _service: BetriebsartSelectorService,
                private localStorageService: LocalStorageService) {
    }

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.init();
    }

    init() {
        this.loadBetriebsarten();
        if (this.required) {
            this.betriebsartCtrl.setValidators([Validators.required]);
        }
    }

    loadBetriebsarten() {
        if (!this.localStorageService.currentUserHasPermission(3101) && !this.localStorageService.currentUserHasPermission(3105)) {
            return;
        }
        this._service.loadBetriebsarten().subscribe(response => {
            this.betriebsarten = response.records;
            this.setSelected();
        });
    }

    setSelected() {
        if (this.selectedBetriebsartId) {
            let b = {betriebsart_id: this.selectedBetriebsartId};
            this.betriebsartCtrl.setValue(b);
        }
    }

    betriebsartChanged(event: MatSelectChange) {
        const b = new BetriebsartSelectionChangeEvent();
        const betriebsart: Betriebsart = event.value;
        b.selectedBetriebsartId = betriebsart.betriebsart_id;
        b.betriebsart = betriebsart;
        this.betriebsartSelectionChanged.emit(b);
    }

    betriebsartComparator(b1: Betriebsart, b2: Betriebsart) {
        if (b1 && b2) {
            return b1.betriebsart_id === b2.betriebsart_id;
        }
        return false;
    }

}


