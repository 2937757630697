<div style="padding: 6px 24px 64px 24px">
    <kiene-page-header
        [small]="true"
        [ueberschrift]="'Einstallen'"
    ></kiene-page-header>

    <div
        style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 12px 0;
        "
    >
        <button
            style="width: 66%"
            mat-flat-button
            color="accent"
            (click)="melden()"
            data-cy="einstallen-melden"
            [disabled]="eingestallteTiere?.length === 0"
        >
            <mat-icon>login</mat-icon>
            <span style="margin-left: 6px">Jetzt an HIT melden</span>
        </button>
    </div>

    <div class="one-row">
        <span class="spacer"></span>
        <button
            mat-icon-button
            color="accent"
            [matMenuTriggerFor]="filterMenu"
            [matBadgeHidden]="
                !filterParams || filterParams?.keys().length === 0
            "
            [matBadge]="filterParams?.keys().length"
        >
            <mat-icon>filter_alt</mat-icon>
        </button>
        <mat-menu #filterMenu="matMenu">
            <button
                mat-menu-item
                (click)="filtern('einstalldatum_notnull', 'null')"
            >
                <mat-icon>event_busy</mat-icon>
                <span
                    [style.font-weight]="
                        isFilterActive('einstalldatum_notnull')
                            ? 'bold'
                            : 'normal'
                    "
                    >Alle ohne Einstalldatum</span
                >
            </button>
            <button
                mat-menu-item
                [disabled]="!filterParams"
                (click)="filterZuruecksetzen()"
            >
                <mat-icon>filter_alt_off</mat-icon>
                <span>Filter zur&uuml;cksetzen</span>
            </button>
        </mat-menu>
    </div>

    <div>
        <div *ngFor="let t of eingestallteTiere" style="padding-bottom: 6px">
            <kiene-gruppe-tier-card
                [disableCardClickDetails]="true"
                [hideAllButtons]="false"
                [hideGruppe]="true"
                [tier]="t"
            >
            </kiene-gruppe-tier-card>
        </div>
    </div>
</div>
