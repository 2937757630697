import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Gruppe } from '../classes/gruppe';
import { Tierliste } from '../../gruppe-tier/classes/tierliste';
import { GruppeAnlegenDialogInput } from './gruppe-anlegen-dialog';

@Component({
    selector: 'kiene-gruppe-anlegen-dialog',
    templateUrl: './gruppe-anlegen-dialog.component.html',
    styleUrls: ['./gruppe-anlegen-dialog.component.scss']
})
export class GruppeAnlegenDialogComponent {

    constructor(public dialogRef: MatDialogRef<GruppeAnlegenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public input: GruppeAnlegenDialogInput) {
    }

    setGruppe(gruppe: Gruppe) {
        this.dialogRef.close(gruppe);
    }

    close() {
        this.dialogRef.close();
    }

}
