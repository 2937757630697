<h1 mat-dialog-title>Tiere filtern</h1>

<p *ngIf="info">{{info}}</p>

<div mat-dialog-content>
    <kiene-gruppe-tier-abteil-filter (selectedAbteile)="filterSettings.abteile = $event"
                                     *ngIf="!!filterSettings?.showAbteile"
                                     [abteile]="filterSettings.abteile"
                                     [gruppeId]="this.gruppe?.gruppe_id">
    </kiene-gruppe-tier-abteil-filter>

    <kiene-gruppe-tier-reklamation-filter (selectedReklamationen)="filterSettings.reklamationen = $event"
                                          *ngIf="!!filterSettings?.showReklamationen"
                                          [gruppeId]="this.gruppe?.gruppe_id"
                                          [reklamationen]="filterSettings.reklamationen">
    </kiene-gruppe-tier-reklamation-filter>

    <kiene-gruppe-tier-geschlecht-filter (selectedGeschlechter)="filterSettings.geschlechter = $event"
                                         *ngIf="!!filterSettings?.showGeschlechter"
                                         [geschlechter]="filterSettings.geschlechter"
                                         [gruppe]="this.gruppe">
    </kiene-gruppe-tier-geschlecht-filter>

    <kiene-gruppe-tier-rasse-filter (selectedRassen)="filterSettings.rassen = $event"
                                    *ngIf="!!filterSettings?.showRassen" [gruppe]="this.gruppe"
                                    [rassen]="filterSettings.rassen">
    </kiene-gruppe-tier-rasse-filter>

    <kiene-gruppe-tier-abgangsliste-filter (selectedAbgangslisten)=" filterSettings.abgangslisten=$event"
                                           *ngIf="!!filterSettings?.showAbgangslisten"
                                           [abgangslisten]="filterSettings.abgangslisten"
                                           [gruppe]="this.gruppe">
    </kiene-gruppe-tier-abgangsliste-filter>

    <kiene-gruppe-tier-viehhaendler-filter #filterViehhaendler (selectedHaendler)="viehhaendlerSelected($event)"
                                           *ngIf="!!filterSettings?.showViehhaendler"
                                           [filter]="filterSettings"
                                           [gruppe]="this.gruppe"
                                           [haendler]="filterSettings.viehhaendler">
    </kiene-gruppe-tier-viehhaendler-filter>

    <kiene-gruppe-tier-einstalldatum-filter #filterEinstalldatum (change)="einstalldatumSelected($event)"
                                            *ngIf="!!filterSettings?.showEinstalldatum"
                                            [einstalldaten]="filterSettings.einstalldatum"
                                            [filter]="filterSettings"
                                            [gruppe]="gruppe"></kiene-gruppe-tier-einstalldatum-filter>

    <kiene-gruppe-tier-filter (selectedFilter)=" setFilter($event)" *ngIf="!!filterSettings?.showFilter"
                              [filter]="this.filterSettings.filter">
    </kiene-gruppe-tier-filter>
</div>

<div mat-dialog-actions>
    <button (click)="dialogRef.close(filterSettings)" color="accent" mat-flat-button>
        Anwenden
    </button>
    <button (click)="delete()" color="accent" mat-flat-button>Filter löschen</button>
    <button (click)="this.dialogRef.close()" mat-button>Abbrechen</button>
</div>
