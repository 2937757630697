<h1 mat-dialog-title>Reklamationen exportieren</h1>

<div mat-dialog-content>

    <div *ngIf="isLoading" style="display: flex; align-items: center; justify-content: center; text-align: center; width: 100%; margin: 10vh 0">
        <mat-hint class="hinweis-text">Lade H&auml;ndler...</mat-hint>
    </div>

    <div *ngIf="!isLoading">

        <h4>Gruppe: {{gruppe.bezeichnung}}{{gruppe.zusatz ? ' - ' + gruppe.zusatz : ''}}</h4>

        <div  style="width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center; margin: 6px 0 24px 0">
            <button [disabled]="!isEmailVersandMoeglich()" (click)="sendePdfAnAlleHaendler()" color="accent" mat-flat-button>
                An alle H&auml;ndler per E-Mail senden
            </button>
            <div>
                <mat-hint
                    style="text-align: justify; font-size: 0.7em; align-items: flex-start; justify-content: flex-start; align-self: flex-start"
                    *ngIf="!isEmailVersandMoeglich()">
                    E-Mail Versand nicht m&ouml;glich. Kein H&auml;ndler hat eine E-Mail hinterlegt. Ggf. in den
                    Stammdaten nachtragen.
                </mat-hint>
            </div>

        </div>

        <div style="width: 100%; margin-bottom: 6px">
            <mat-divider style="width: 100%"></mat-divider>
        </div>

        <div>
            <mat-form-field class="no-padding-margin-mat-form-field" style="width: 100%;">
                <mat-label>H&auml;ndler</mat-label>
                <mat-select [compareWith]="compareHaendler" [formControl]="haendlerCtrl">
                    <mat-option [value]="alleHaendler">Alle H&auml;ndler ({{haendler.length}} Stk.)</mat-option>
                    <mat-option *ngFor="let h of haendler" [value]="h" class="multiline-mat-option">
                        <div>{{h.name}}</div>
                        <div style="color: #777; font-size: 0.7em">&nbsp;({{h.email ? h.email : 'keine E-Mail hinterlegt' }})</div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>

</div>

<div mat-dialog-actions>
    <button mat-flat-button color="accent" (click)="close()">PDF anzeigen</button>
    <button mat-flat-button (click)="dialogRef.close(null)">Schlie&szlig;en</button>
</div>
