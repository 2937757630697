<mat-drawer-container class="example-container">
    <mat-drawer mode="side" opened style="height: calc(100vh - 154px); background-color: transparent; border: none;">
        <div *ngIf="istKunde && bereitZumUnterschreiben?.length > 0" class="one-row" style="margin-top: 12px;">
            <span class="spacer"></span>
            <button mat-stroked-button color="accent" (click)="alleProtokollieren()">Alle protokollieren</button>
            <span class="spacer"></span>
        </div>
        <ul class="vertraege-menu">
            <li (click)="setDocumentType(1, 'Tierärztlicher Betreuungsvertrag')">
                <div class="one-row">
                    <mat-icon color="primary">description</mat-icon>
                    <span>Tierärztlicher Betreuungsvertrag</span>
                    <span class="spacer"></span>
                </div>
            </li>
            <li (click)="setDocumentType(2, 'Ergänzung zum Betreuungsvertrag')">
                <div class="one-row">
                    <mat-icon color="primary">description</mat-icon>
                    <span>Ergänzung zum Betreuungsvertrag</span>
                    <span class="spacer"></span>
                </div>
            </li>
            <li (click)="setDocumentType(3, 'Anwendung von Tierarzneimitteln (Futter)')">
                <div class="one-row">
                    <mat-icon color="primary">description</mat-icon>
                    <span>Anwendung von Tierarzneimitteln (Futter)</span>
                    <span class="spacer"></span>
                </div>
            </li>
            <li (click)="setDocumentType(4, 'Anwendung von Tierarzneimitteln (Wasser)')">
                <div class="one-row">
                    <mat-icon color="primary">description</mat-icon>
                    <span>Anwendung von Tierarzneimitteln (Wasser)</span>
                    <span class="spacer"></span>
                </div>
            </li>
            <li (click)="setDocumentType(5, 'Meldevereinbarung QS')">
                <div class="one-row">
                    <mat-icon color="primary">description</mat-icon>
                    <span>Meldevereinbarung QS</span>
                    <span class="spacer"></span>
                </div>
            </li>
            <li (click)="setDocumentType(6, 'Risikomanagement zur oralen Medikation')">
                <div class="one-row">
                    <mat-icon color="primary">description</mat-icon>
                    <span>Risikomanagement zur oralen Medikation</span>
                    <span class="spacer"></span>
                </div>
            </li>
        </ul>
    </mat-drawer>
    <mat-drawer-content>
        <kiene-dokumente [dokumentType]="[documentType]" [headline]="documentHeadline"></kiene-dokumente>
    </mat-drawer-content>
</mat-drawer-container>
