<h1 mat-dialog-title>Fehlerhafte Scans: Ohrmarken manuell nachtragen</h1>
<div mat-dialog-content style="width: 100%;">
    <div class="one-row">
        <h3>Fehlerhafte Seiten:</h3>
        <span class="spacer"></span>
        <ng-container *ngFor="let seite of fehlerhafteSeiten; let i = index;">
            <span (click)="ladeSeite(i)" class="seiten-link">{{seite.seitenzahl}}</span>
            <span class="spacer"></span>
        </ng-container>
    </div>
    <div class="one-row">
        <div
            style="min-width: 800px; width: 60%; align-self: flex-start; height: 100%;">
            <object *ngIf="pdfAvailable"
                    [data]="pdfBase64"
                    style="height: 100%; width: 100%; min-height: 75vh;"
                    type="application/pdf"
            ></object>
            <mat-spinner *ngIf="!pdfAvailable" style="align-self: center;"></mat-spinner>
        </div>
        <div style="min-width: 400px; width: 40%; align-self: flex-start;">
            <div style="margin-top: 110px; width: 100%; margin-left: 6px;">
                <p>Bitte die Ohrmarke eingeben:</p>
                <div class="one-row">
                    <span class="ohrmarke-ziffer">D</span>
                    <span class="ohrmarke-ziffer">E</span>
                    <input #zifferInput class="ohrmarke-ziffer-input" maxlength="1" [formControl]="zifferCtrl[0]">
                    <input #zifferInput class="ohrmarke-ziffer-input" maxlength="1" [formControl]="zifferCtrl[1]">
                    <input #zifferInput class="ohrmarke-ziffer-input" maxlength="1" [formControl]="zifferCtrl[2]">
                    <input #zifferInput class="ohrmarke-ziffer-input" maxlength="1" [formControl]="zifferCtrl[3]">
                    <input #zifferInput class="ohrmarke-ziffer-input" maxlength="1" [formControl]="zifferCtrl[4]">
                    <input #zifferInput class="ohrmarke-ziffer-input" maxlength="1" [formControl]="zifferCtrl[5]">
                    <input #zifferInput class="ohrmarke-ziffer-input" maxlength="1" [formControl]="zifferCtrl[6]">
                    <input #zifferInput class="ohrmarke-ziffer-input" maxlength="1" [formControl]="zifferCtrl[7]">
                    <input #zifferInput class="ohrmarke-ziffer-input" maxlength="1" [formControl]="zifferCtrl[8]">
                    <input #zifferInput class="ohrmarke-ziffer-input" maxlength="1" [formControl]="zifferCtrl[9]">
                </div>
                <div class="one-row" style="margin-top: 12px;">
                    <span class="spacer"></span>
                    <button mat-flat-button color="accent" [disabled]="!allesKorrekt" (click)="ohrmarkeNachtragen()">
                        Ohrmarke nachtragen
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-flat-button color="accent" (click)="dialogRef.close()">Schlie&szlig;en</button>
</div>
