import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BetriebsartApi} from './betriebsart-selector.component';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';

@Injectable({
    providedIn: 'root'
})
export class BetriebsartSelectorService {

    constructor(private api: KieneBackendApiService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    loadBetriebsarten(): Observable<BetriebsartApi> {
        return this.api.get(this.apiBaseUrl + 'betriebsart/read_kundenportal.php');
    }
}
