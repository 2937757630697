import { HttpParams } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AppService } from '../../../../app.service';
import {
    FilterItem,
    FilterItemType,
} from '../../../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import { KieneTableService } from '../../../../components/kiene-table/kiene-table.service';
import { DateTableColumn, NumberTableColumn, TableColumn, } from '../../../../components/kiene-table/table-column';
import { TableDescriptor } from '../../../../components/kiene-table/table-descriptor';
import { Condition, Operator, TableOption, TableOptionEvent, } from '../../../../components/kiene-table/table-option';
import {
    LocalStorageService
} from '../../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { MessageService } from '../../../../services/message.service';
import { GruppeDetailsDialogComponent } from '../../gruppe-details-dialog/gruppe-details-dialog.component';
import { GruppenService } from '../../../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import { Gruppe } from '../../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import { Client } from '../../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { ConfirmDialogInput } from '../../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import { ConfirmDialogComponent } from '../../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'kiene-gruppen-historie',
    templateUrl: './gruppen-historie.component.html',
    styleUrls: ['./gruppen-historie.component.css'],
})
export class GruppenHistorieComponent implements OnInit, OnChanges {
    tableDescriptor: TableDescriptor = new TableDescriptor();
    currentClient: Client;
    watchClientSubscription: Subscription;

    constructor(
        public dialog: MatDialog,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private api: KieneTableService,
        private appService: AppService,
        private gruppenService: GruppenService
    ) {
    }

    ngOnInit() {
        this.currentClient = this.localStorageService.getCurrentClient();

        this.watchClientSubscription = this.localStorageService
            .watchCurrentClient()
            .subscribe((response) => {
                if (response) {
                    this.currentClient = response;
                    const kundeId: string =
                        this.currentClient.kunde_id.toString();
                    this.tableDescriptor.params = new HttpParams().set(
                        'kunde_id',
                        kundeId
                    );
                    this.tableService.reload();
                } else {
                    this.tableDescriptor.params = null;
                    this.tableService.reload();
                }
            });

        if (this.currentClient) {
            const kundeId: string = this.currentClient.kunde_id.toString();
            this.tableDescriptor.params = new HttpParams().set(
                'kunde_id',
                kundeId
            );
        }

        this.tableDescriptor.headline = 'Gruppen';
        this.tableDescriptor.uniqueIdentifier = 'Gruppen';
        this.tableDescriptor.alternativeApiUrl =
            environment.BASE_URI + 'gruppe/read_hist.php';
        this.tableDescriptor.route = '/belege/gruppe/';
        this.tableDescriptor.nameOfIdField = 'gruppe_id';
        this.tableDescriptor.searchPlaceholder = 'Gruppen durchsuchen';

        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.createAllowed = false;

        this.tableDescriptor.showDetails = false;
        this.tableDescriptor.showDetailsAsOption = false;

        this.tableDescriptor.columns.push(new TableColumn('gruppe_id', 'ID'));
        this.tableDescriptor.columns.push(new TableColumn('kunde', 'Betrieb'));
        this.tableDescriptor.columns.push(new TableColumn('zusatz', 'Zusatz'));

        this.tableDescriptor.columns.push(
            new TableColumn('bezeichnung', 'Bezeichnung')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('produktionsabschnitt', 'Abschnitt')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('herdenname', 'Herdenname')
        );
        this.tableDescriptor.columns.push(new TableColumn('stall', 'Standort'));
        this.tableDescriptor.columns.push(
            new DateTableColumn(
                'einstalldatum_von',
                'Einstalldatum von',
                'dd.MM.yy'
            )
        );
        this.tableDescriptor.columns.push(
            new DateTableColumn(
                'einstalldatum_bis',
                'Einstalldatum bis',
                'dd.MM.yy'
            )
        );
        this.tableDescriptor.columns.push(
            new TableColumn('alter_wochen', 'Tieralter in Wochen')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('anzahl_tiere_gesamt', 'Eingestallte Tiere')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('anzahl_abgaenge', 'Abgänge')
        );
        this.tableDescriptor.columns.push(
            new NumberTableColumn('verluste', 'Verlust %', '1.1-1', '%')
        );
        this.tableDescriptor.columns.push(
            new NumberTableColumn('anzahl_tote', 'Tote', '1.0-0')
        );
        // this.tableDescriptor.columns.push(
        //     new TableColumn("verkaeufe", "Verkauft")
        // );
        this.tableDescriptor.columns.push(
            new TableColumn('anzahl_tiere_aktuell', 'Anzahl Tiere')
        );
        this.tableDescriptor.columns.push(
            new DateTableColumn(
                'synchronisiert',
                'HIT Sync.',
                'dd.MM.yy HH:mm',
                { alternativeField: 'hit_fehler' }
            )
        );

        this.tableDescriptor.options.push(
            new TableOption('details', 'Tiere verwalten', 'settings', 0, {
                conditions: [
                    new Condition('ohrmarke', Operator.EXISTIERT, null),
                ]
            })
        );
        this.tableDescriptor.options.push(
            new TableOption('hit', 'Tiere mit HIT synchronisieren', 'pets', 1902)
        );
        this.tableDescriptor.options.push(new TableOption(null, 'Gruppe aus Historie holen', 'undo', 1908, { conditions: [new Condition('standard', Operator.GLEICH, '0')], void: this.ausHistorieHolen.bind(this) }));

        this.tableDescriptor.permissionView = 1901;
        this.tableDescriptor.permissionCreate = 1902;
        this.tableDescriptor.permissionUpdate = 1902;
        this.tableDescriptor.permissionDelete = 1904;

        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Gruppe \'Standard\' ausblenden',
                0,
                FilterItemType.CHECKBOX,
                'standardgruppe',
                false
            )
        );

        this.tableDescriptor.initialSortColumn = 'einstalldatum_von';
        this.tableDescriptor.initialSortDirection = 'desc';
    }

    ngOnChanges() {
        if (this.currentClient) {
            const kundeId: string = this.currentClient.kunde_id.toString();
            this.tableDescriptor.params = new HttpParams().set(
                'kunde_id',
                kundeId
            );
            this.tableService.reload();
        }
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'details') {
            this.openGruppeDetailsDialog(event.value);
        } else if (event.name === 'hit') {
            this.tiereAusHitNachladen(event.value);
        }
    }

    tiereAusHitNachladen(gruppe: Gruppe) {
        this.appService.setDataLoading(true);
        this.gruppenService.tiereAusHitNachladen(gruppe).subscribe(
            () => {
                this.messageService.infoMessage(
                    'Tiere erfolgreich aus HIT geladen!'
                );
                this.appService.setDataLoading(false);
                this.api.reload();
            },
            (error) => {
                this.messageService.errorMessage(
                    'Tiere konnten nicht aus HIT nachgeladen werden!',
                    error
                );
                this.appService.setDataLoading(false);
            }
        );
    }

    openGruppeDetailsDialog(gruppe: Gruppe) {
        const dialogRef = this.dialog.open(GruppeDetailsDialogComponent, {
            width: '90%',
            data: {
                gruppe: gruppe,
            },
        });

        dialogRef.afterClosed().subscribe(() => {
            this.tableService.reload();
        });
    }

    ngOnDestroy() {
        console.debug('Destroy MeldungenComponent');
        this.watchClientSubscription.unsubscribe();
        this.tableService = null;
    }

    ausHistorieHolen(gruppe: Gruppe) {
        const input = new ConfirmDialogInput();
        input.headline = 'Gruppe aus der Historie holen?';
        input.text = `Möchten Sie die Gruppe wirklich wieder nach "aktuelle Gruppen" verschieben?`;
        input.okButton = 'Verschieben';

        this.dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: input
        }).afterClosed().subscribe((response) => {
            if (response) {
                this.gruppenService.historieAendern(gruppe, false).
                    subscribe({
                        next: () => {
                            this.messageService.infoMessage(
                                'Gruppe erfolgreich in Historie verschoben!'
                            );
                            this.api.reload();
                        },
                        error: err => {
                            this.messageService.errorMessage(
                                'Gruppe konnte nicht in die Historie verschoben werden!',
                                err
                            );
                        }
                    });
            }
        });
    }

}
