<h1 mat-dialog-title>{{bestandsbuchArtikel?.artikelmenge}}x {{bestandsbuchArtikel?.artikel_me}}</h1>
<div mat-dialog-content>

    <kiene-back-to-top-button></kiene-back-to-top-button>

    <div style="padding-bottom: 64px">

        <div class="one-row" style="margin-bottom: 12px">
            <h3>Details</h3>
            <div *ngIf="hasValidPhoneNumber()">
                <button (click)="tel.click()" color="accent" mat-flat-button>
                    <mat-icon>phone</mat-icon>
                </button>
                <a #tel
                   [href]="'tel:' + (bestandsbuchArtikel.mobil ? bestandsbuchArtikel.mobil : bestandsbuchArtikel.telefon)"
                   style="display: none"></a>
            </div>

        </div>
        <div>
            <div *ngIf="bestandsbuchArtikel?.tierart" class=" detail-row">
                <strong>{{bestandsbuchArtikel?.tierart}}:</strong>
                <span>{{bestandsbuchArtikel?.anzahl_tiere || '---'}} Stk.</span>
            </div>
            <div *ngIf="bestandsbuchArtikel?.tierart" class=" detail-row">
                <span>davon behandelt:</span>
                <span>{{summeAnzahlTiereAnwendungen() || '---'}} Stk.</span>
            </div>
            <div class="detail-row detail-row__new-line">
                <strong>Diagnose:</strong>
                <div>{{bestandsbuchArtikel?.diagnose || '---'}} </div>
            </div>
            <div class="detail-row detail-row__new-line">
                <strong>Dosierung:</strong>
                <div>{{bestandsbuchArtikel?.dosierung_txt || '---'}} </div>
            </div>
            <div class=" detail-row">
                <strong>Wartezeit Fleisch:</strong>
                <span>{{bestandsbuchArtikel?.wartezeit || '---'}} Tag(e)</span>
            </div>
            <div class=" detail-row">
                <strong>Beleg-Nr.:</strong>
                <span>{{bestandsbuchArtikel?.abgabebeleg_nr}}</span>
            </div>
            <div class=" detail-row">
                <strong>Gruppe:</strong>
                <span>{{bestandsbuchArtikel?.gruppe}}</span>
            </div>
        </div>

        <div *ngIf="bestandsbuchTyp === 1">
            <h3>Tiere/Ohrmarken</h3>
            <div>
                <div *ngIf="!bestandsbuchArtikel?.tiere?.length && !bestandsbuchArtikel?.identifikation">
                    <mat-hint class="hinweis-text">Es wurden weder Tiere noch Identifikationen dokumentiert.</mat-hint>
                </div>

                <div class="one-row">
                    <mat-icon>info</mat-icon>
                    <span class="spacer"></span>
                    <span style="width: calc(100% - 78px); font-size: .8em;">Einzelne Tiere f&uuml;r dieses Medikament pflegen. Sobald eins von den {{bestandsbuchArtikel?.anzahl_tiere}}
                        Tieren eingepflegt wurde, m&uuml;ssen alle anderen Tiere auch gepflegt werden!</span>
                    <span class="spacer"></span>
                    <mat-slide-toggle [formControl]="zeigeEinzelneTiereCtrl"></mat-slide-toggle>
                </div>
                <div *ngIf="zeigeEinzelneTiereCtrl.value">
                    <div (click)="addTier(tier)" *ngFor="let tier of bestandsbuchArtikel?.tiere"
                         class="one-row"
                         style="height:5vh">
                        <div class="detail-row" style="font-size: 0.8em">
                            <kiene-ohrmarke [ohrmarke]="tier.ohrmarke_notnull" style="margin: 6px;"></kiene-ohrmarke>
                        </div>
                        <mat-icon color="accent">edit</mat-icon>

                    </div>
                    <ng-container>
                        <div (click)="addTier()" *ngFor="let i of openTiere; let index = index" class="one-row"
                             style="height:5vh">
                            <mat-hint class="empty-animal">
                                <mat-icon style="padding-right: 6px; transform: scale(0.8)">warning</mat-icon>
                                <span>{{index + bestandsbuchArtikel.tiere.length + 1}}. Tier nachtragen</span>
                            </mat-hint>
                            <mat-icon color="accent">edit</mat-icon>
                        </div>
                    </ng-container>

                </div>

                <div *ngIf="!bestandsbuchArtikel?.tiere?.length && bestandsbuchArtikel?.identifikation">
                    <div class="detail-row detail-row__new-line">
                        <strong>Identifikation:</strong>
                        <div class="detail-row__data">{{bestandsbuchArtikel?.identifikation}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="bestandsbuchTyp === 1 && bestandsbuchArtikel?.anwendungen?.length > 0">
            <h3>Protokollierung</h3>
            <mat-hint *ngIf="isUserAllowed()" class="hinweis-text"><strong>Hinweis:</strong> Es können keine Tage im
                voraus protokolliert werden.
            </mat-hint>
            <mat-hint *ngIf="!isUserAllowed()" class="hinweis-text">
                <strong>Hinweis:</strong>
                Der aktuelle Artikel wird durch einen Tierarzt protokolliert.
            </mat-hint>
            <div *ngFor="let anwendung of bestandsbuchArtikel.anwendungen">
                <mat-checkbox (change)="protokollieren($event, anwendung)" [checked]="anwendung.angewendet > 0"
                              [disabled]="(isInFuture(anwendung.datum) || anwendung.angewendet > 0) || !isUserAllowed()"
                              style="margin: 6px 0 6px 0">{{anwendung.datum | date: 'dd.MM.yyyy'}}</mat-checkbox>
            </div>
        </div>
        <div *ngIf="bestandsbuchTyp === 2">
            <h3>Protokollierung</h3>
            <div *ngFor="let anwendung of bestandsbuchArtikel.anwendungen; let i= index;" class="one-row">
                <mat-form-field style="width: 30%;">
                    <mat-label>Anzahl Tiere</mat-label>
                    <input matInput type="number" (change)="updateBestandsbuchArtikel(i)" inputmode="numeric"
                           [(ngModel)]="anwendung.anzahl_tiere">
                </mat-form-field>
                <span class="spacer"></span>
                <mat-form-field style="width: 50%;">
                    <mat-label>Datum der {{(i + 1)}} Impfung</mat-label>
                    <input (dateChange)="impfungEingetragen(anwendung)" [(ngModel)]="anwendung.datum"
                           [matDatepicker]="datePicker"
                           [placeholder]="'TT.MM.JJJJ'" matInput="matInput">
                    <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                </mat-form-field>
                <span class="spacer"></span>
                <button mat-icon-button [disabled]="i === bestandsbuchArtikel.anwendungen.length - 1"
                        (click)="bestandsbuchArtikel?.anwendungen?.splice(i,1); updateBestandsbuchArtikel();">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <div class="one-row">
                <span class="spacer"></span>
                <button mat-stroked-button color="accent" (click)="anwendungHinzufuegen()"
                        [disabled]="summeAnzahlTiereAnwendungen() >= bestandsbuchArtikel?.anzahl_tiere">
                    <mat-icon>add</mat-icon>
                    <span>hinzuf&uuml;gen</span>
                </button>
            </div>
        </div>
    </div>

</div>
<div mat-dialog-actions>
    <button (click)="dialogRef.close()" mat-button>Schlie&szlig;en</button>
</div>
