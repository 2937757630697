import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginService} from './login.service';
import {UserCredentials} from './user.credentials';
import {LocalStorageService} from '../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {UntypedFormControl} from '@angular/forms';
import {MessageService} from '../services/message.service';
import {KieneSession} from '../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import {HttpParams} from '@angular/common/http';
import {API_BASE_URL_SERVICE} from '../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {Mandant} from '../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    host: {'(window:keydown)': 'checkForF8($event)'},
})
export class LoginComponent implements OnInit {
    hidePassword = true;
    isLoading = false;

    showMandantSelector = false;
    kieneSession: KieneSession = null;

    emailCtrl = new UntypedFormControl();
    passwordCtrl = new UntypedFormControl();

    constructor(
        private loginService: LoginService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private messageService: MessageService,
        private route: ActivatedRoute,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService
    ) {
    }

    ngOnInit() {
        if (this.localStorageService.isLoggedIn()) {
            this.logout();
        }

        const email = this.route.snapshot.queryParamMap.get('email');
        if (email) {
            this.emailCtrl.setValue(email);
        }
    }

    checkForF8(event: any) {
        if (event.code === 'F8') {
            this.hidePassword = !this.hidePassword;
        }
    }

    mandantAuswaehlen() {
        if (this.kieneSession.mandanten?.length > 1) {
            this.showMandantSelector = true;
        } else if (this.kieneSession.mandanten?.length === 1) {
            this.setMandant(this.kieneSession.mandanten[0]);
        } else {
            this.setMandant(undefined);
        }
    }

    setSession(session: KieneSession) {
        this.kieneSession = session;
        localStorage.removeItem('kieneCurrentClient');

        this.localStorageService.setCurrentUser(this.kieneSession);

        this.mandantAuswaehlen();
    }

    login() {
        this.isLoading = true;
        this.localStorageService.deleteBrowserSession('login');
        const userCredentials: UserCredentials = new UserCredentials();

        userCredentials.email = this.emailCtrl.value;
        userCredentials.passwort = this.passwordCtrl.value;

        this.loginService.login(userCredentials).subscribe({
            next: (response: KieneSession) => {
                this.isLoading = false;
                this.setSession(response);
            },
            error: (err) => {
                this.messageService.errorMessage('Login fehlgeschlagen!', err);
                this.isLoading = false;
            },
        });
    }

    setMandant(m: Mandant) {
        const startseite = this.localStorageService.getStartseite();
        this.localStorageService.setCurrentMandant(m, 'LoginComponent setMandant()');
        this.loadClientsAndSelect();
        console.log('startseite: ', startseite);
        this.router.navigate([startseite]).then();
    }

    loadClientsAndSelect() {
        if (this.localStorageService.isCurrentUserKunde() || this.localStorageService.isCurrentUserViehhaendler()) {
            this.api.getAll(this.apiBaseUrl + 'kunde/search.php', new HttpParams().set('limit', '2')).subscribe({
                next: (response) => {
                    if (response.count === 1) {
                        this.localStorageService.setCurrentClient(response.records[0]);
                    }
                },
                error: () => {
                    // messageservice zirkulär fehler
                }
            });
        }
    }

    logout() {
        this.localStorageService.deleteBrowserSession('logout');
    }

    register() {
        this.logout();
        this.router.navigate(['/register']).then();
    }

    resetPassword() {
        this.logout();
        this.router.navigate(['/password/reset']).then();
    }

}
