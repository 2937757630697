<h1 mat-dialog-title>Bestellvorschlag Intern</h1>
<div mat-dialog-content>

    <kiene-article-scan-autocomplete [presetMaterial]="presetMaterial" [placeholder]="'Artikel'" (selectedMaterial)="setMaterial($event)"></kiene-article-scan-autocomplete>
    <br>
    <mat-form-field style="width:100%">
        <mat-label>Sollbestand</mat-label>
        <input matInput type="number" [formControl]="mindestbestandCtrl">
    </mat-form-field>
    <br>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()" [disabled]="!formIsValid()">Speichern</button>
</div>