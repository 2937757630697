import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    PdfViewerInput
} from '../kiene-pdf-viewer/classes/pdf-viewer-input';

@Component({
    selector: 'kiene-pdf-viewer-dialog',
    templateUrl: './pdf-viewer-dialog.component.html',
    styleUrls: ['./pdf-viewer-dialog.component.scss']
})
export class PdfViewerDialogComponent implements OnInit {

    pdfViewerInput: PdfViewerInput;

    constructor(
        public dialogRef: MatDialogRef<PdfViewerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PdfViewerInput,
    ) {
        this.pdfViewerInput = data;
    }

    ngOnInit(): void { }

}
