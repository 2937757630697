<h1 mat-dialog-title>Charge f&uuml;r {{ article?.artikel_me }} auswählen</h1>
<form (ngSubmit)="confirm()">
    <div mat-dialog-content>
        <table class="phito-table">
            <thead>
                <tr>
                    <th></th>
                    <th>Charge</th>
                    <th>Lager</th>
                    <th>Lagerplatz</th>
                    <th>MHD</th>
                    <th>Bestand</th>
                </tr>
            </thead>
            <tbody>
                <tr (keyup.enter)="confirm(i)" class="charge-row" *ngFor="let charge of bestandChargen; index as i"
                    (click)="confirm(i)" [ngClass]="rowHandler[i] ? 'highlight' : 'nothing'" tabindex="{{i}}"
                    style="cursor: pointer;">
                    <td>{{ (i + 1) }}.</td>
                    <td>{{ charge.charge }}</td>
                    <td>{{ charge.lager }}</td>
                    <td>{{ charge.bezeichnung }}</td>
                    <td>{{ charge.mhd }}</td>
                    <td>{{ charge.menge | number: '1.2-2' }} {{ article?.einheit }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div align="end" id="chargenButtons" mat-dialog-actions>
        <button mat-flat-button color="accent" type="submit"
            (click)="confirm()"><mat-icon>start</mat-icon>Ausw&auml;hlen</button>
    </div>
</form>