import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { MessageService } from '../../../../services/message-service/message.service';
import { PdfViewerDesktopDialogComponent } from './pdf-viewer-desktop-dialog';
import { Empfaenger } from '../classes/empfaenger';

export class PdfViewerDialogInput {
    apiUrl: string;
    params: HttpParams;
    object: any;
    title: string;
    sende_mail = false;
    kunde_id: number;
    isInvoice = false;
    targetRoute?: string[];
    pdfBase64: SafeResourceUrl;
    forceSend = false;
    empfaenger: Empfaenger[] = [];

    constructor(
        apiUrl: string,
        params: HttpParams,
        titel: string,
        options?: {
            email?: { sende_mail: boolean; kunde_id: number };
            forceSend?: boolean;
            empfaenger?: Empfaenger[];
            targetRoute?: string[];
        }
    ) {
        this.apiUrl = apiUrl;
        this.params = params;
        this.title = titel;
        if (options?.email) {
            this.sende_mail = options?.email?.sende_mail === true;
            this.kunde_id = options?.email?.kunde_id;
        }
        this.forceSend = options?.forceSend === true;
        this.empfaenger = options?.empfaenger ? options?.empfaenger : [];
        this.targetRoute = options?.targetRoute;
    }
}

@Injectable({
    providedIn: 'root',
})
export class PdfViewerDesktopService {
    constructor(
        public dialog: MatDialog,
        private http: HttpClient,
        private messageService: MessageService,
        private router: Router
    ) { }

    downloadPdf(url: string, params?: HttpParams) {
        this.http
            .get(url, {
                responseType: 'blob',
                params: params,
            })
            .subscribe({
                next: (response) => {
                    const pdfBlob = new Blob([response], {
                        type: 'application/pdf',
                    });
                    if (pdfBlob.size > 0) {
                        const openUrl =
                            window.URL.createObjectURL(pdfBlob) + '#view=FitH';
                        window.open(openUrl);
                    } else {
                        this.messageService.hintMessage(
                            'Es wurden keine passende PDFs gefunden!'
                        );
                    }
                },
                error: (error) => {
                    console.error(error);
                    this.messageService.errorMessage(
                        'Es ist ein Fehler beim Laden der PDF aufgetreten! ',
                        error
                    );
                },
            });
    }

    openPdfViewer(input: PdfViewerDialogInput): Observable<boolean> {
        const closeSubject = new Subject<boolean>();
        this.dialog.open<PdfViewerDesktopDialogComponent, PdfViewerDialogInput>(PdfViewerDesktopDialogComponent, {
            data: input,
            panelClass: ['pdf-dialog'],
        }).afterClosed().subscribe(() => {
            closeSubject.next(true);
            if (input?.targetRoute) {
                this.router.navigate(input?.targetRoute).then();
            }
        });

        return closeSubject.asObservable();
    }

    openPdfGlobal(
        kunde_id: number,
        von: Date,
        bis: Date,
        orderby: string,
        options?: {
            abgabebelege?: boolean;
            bestandsbuecher?: boolean;
            impfkontrollbuecher?: boolean;
            mnp_rind?: boolean;
            mnp_schwein?: boolean;
            bbp?: boolean;
            befunde?: boolean;
            antibiogramme?: boolean;
            dok1?: boolean;
            dok2?: boolean;
            dok3?: boolean;
            dok4?: boolean;
            dok5?: boolean;
            dok6?: boolean;
        }
    ) {
        let params = new HttpParams();
        params = params.append('kunde_id', kunde_id?.toString());
        params = params.append('von', von.toISOString());
        params = params.append('bis', bis.toISOString());
        params = params.append('orderby', orderby);
        params = params.append(
            'abgabebelege',
            options?.abgabebelege === true ? '1' : '0'
        );
        params = params.append(
            'bestandsbuecher',
            options?.bestandsbuecher === true ? '1' : '0'
        );
        params = params.append(
            'impfkontrollbuecher',
            options?.impfkontrollbuecher === true ? '1' : '0'
        );
        params = params.append(
            'mnp_rind',
            options?.mnp_rind === true ? '1' : '0'
        );
        params = params.append(
            'mnp_schwein',
            options?.mnp_schwein === true ? '1' : '0'
        );
        params = params.append('bbp', options?.bbp === true ? '1' : '0');
        params = params.append(
            'befunde',
            options?.befunde === true ? '1' : '0'
        );
        params = params.append(
            'antibiogramme',
            options?.antibiogramme === true ? '1' : '0'
        );
        params = params.append('dok1', options?.dok1 === true ? '1' : '0');
        params = params.append('dok2', options?.dok2 === true ? '1' : '0');
        params = params.append('dok3', options?.dok3 === true ? '1' : '0');
        params = params.append('dok4', options?.dok4 === true ? '1' : '0');
        params = params.append('dok5', options?.dok5 === true ? '1' : '0');
        params = params.append('dok6', options?.dok6 === true ? '1' : '0');

        const input = new PdfViewerDialogInput(
            'abgabebeleg/read_pdf_global.php',
            params,
            'Alle Dokumente'
        );
        this.openPdfViewer(input);
    }
}
