<mat-card *kienePermission="[3201]" style="margin-bottom: 20px;">
  <div style="width:75%;">
    <h5>Verkauf</h5>
    <table class="phito-table">
      <thead>
        <tr>
          <th>VK</th>
          <th>Verkaufspreis Netto</th>
          <th>Handelsspanne %</th>
          <th>Rohertrag</th>
          <th>Kalkulationszuschlag %</th>
          <th>Brutto VK {{vat}}%</th>
        </tr>
      </thead>
      <tbody>

        <tr *ngFor="let mp of materialPrices">
          <td>{{mp.verkaufspreis}}</td>
          <td>
            <mat-form-field>
              <mat-label>Einzelpreis</mat-label>
              <input matInput type="number" [formControl]="vkCtrls[mp.verkaufspreis_id+'_'+mp.artikel_id]">
              <span matSuffix>&euro;</span>
            </mat-form-field>
          </td>
          <td>{{gibHandelsspanne(vkCtrls[mp.verkaufspreis_id+'_'+mp.artikel_id]?.value) | number: '2.2-2'}}%</td>
          <td>{{gibRohertrag(vkCtrls[mp.verkaufspreis_id+'_'+mp.artikel_id]?.value) | number: '2.2-2'}}€</td>
          <td>{{gibKalkulationszuschlag(vkCtrls[mp.verkaufspreis_id+'_'+mp.artikel_id]?.value) | number: '2.2-2'}}%</td>
          <td>{{gibBruttoVK(vkCtrls[mp.verkaufspreis_id+'_'+mp.artikel_id]?.value) | number: '2.2-2'}}€</td>
        </tr>
        <!-- <tr>
              <td>2</td>
              <td><mat-form-field>
                <input matInput type="number" [formControl]="vk2Ctrl" placeholder="Verkaufspreis 2">
                <span matSuffix>&euro;</span>
              </mat-form-field></td>
              <td>{{gibHandelsspanne(vk2Ctrl.value) | number: '2.2-2'}}%</td>
              <td>{{gibRohertrag(vk2Ctrl.value) | number: '2.2-2'}}€</td>
              <td>{{gibKalkulationszuschlag(vk2Ctrl.value) | number: '2.2-2'}}%</td>
              <td>{{gibBruttoVK(vk2Ctrl.value) | number: '2.2-2'}}€</td>
            </tr>
            <tr>
              <td>3</td>
              <td><mat-form-field>
                <input matInput type="number" [formControl]="vk3Ctrl" placeholder="Verkaufspreis 3">
                <span matSuffix>&euro;</span>
              </mat-form-field></td>
              <td>{{gibHandelsspanne(vk3Ctrl.value) | number: '2.2-2'}}%</td>
              <td>{{gibRohertrag(vk3Ctrl.value) | number: '2.2-2'}}€</td>
              <td>{{gibKalkulationszuschlag(vk3Ctrl.value) | number: '2.2-2'}}%</td>
              <td>{{gibBruttoVK(vk3Ctrl.value) | number: '2.2-2'}}€</td>
            </tr>
            <tr>
              <td>4</td>
              <td><mat-form-field>
                <input matInput type="number" [formControl]="vk4Ctrl" placeholder="Verkaufspreis 4">
                <span matSuffix>&euro;</span>
              </mat-form-field></td>
              <td>{{gibHandelsspanne(vk4Ctrl.value) | number: '2.2-2'}}%</td>
              <td>{{gibRohertrag(vk4Ctrl.value) | number: '2.2-2'}}€</td>
              <td>{{gibKalkulationszuschlag(vk4Ctrl.value) | number: '2.2-2'}}%</td>
              <td>{{gibBruttoVK(vk4Ctrl.value) | number: '2.2-2'}}€</td>
            </tr>
            <tr>
              <td>5</td>
              <td><mat-form-field>
                <input matInput type="number" [formControl]="vk5Ctrl" placeholder="Verkaufspreis 5">
                <span matSuffix>&euro;</span>
              </mat-form-field></td>
              <td>{{gibHandelsspanne(vk5Ctrl.value) | number: '2.2-2'}}%</td>
              <td>{{gibRohertrag(vk5Ctrl.value) | number: '2.2-2'}}€</td>
              <td>{{gibKalkulationszuschlag(vk5Ctrl.value) | number: '2.2-2'}}%</td>
              <td>{{gibBruttoVK(vk5Ctrl.value) | number: '2.2-2'}}€</td>
            </tr> -->
      </tbody>
    </table>

  </div>
  <mat-card-actions style="text-align: right;">
    <button mat-raised-button color="primary" (click)="savePrices()"
      [disabled]="material.artikel_id === null || material.artikel_id === undefined">Speichern</button>
  </mat-card-actions>
</mat-card>