<div style="width: 100%">
    <mat-form-field
        [ngClass]="noPaddingNoMargin ? 'no-padding-margin-mat-form-field' : ''"
        style="width: 100%"
    >
        <mat-label>{{ placeholder }}</mat-label>
        <input
            #inputAutocomplete="matInput"
            matInput
            #trigger="matAutocompleteTrigger"
            [matAutocomplete]="auto"
            [formControl]="formControl"
            [required]="required"
            (blur)="onBlur()"
            [placeholder]="placeholder"
        />
        <button
            *ngIf="showClear"
            [disabled]="disabled"
            matSuffix
            mat-icon-button
            type="button"
            color="accent"
            (click)="clear()"
        >
            <mat-icon>cancel</mat-icon>
        </button>
        <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="mapper.bind(this)"
            (optionSelected)="emitSelectedElement($event)"
            (optionActivated)="setActivatedObject($event)"
        >
            <ng-container *ngIf="createNewEvent">

                <mat-option *kienePermission="createNewEvent?.permission" (click)="emitCreateNewEvent()"
                            style="position: sticky; bottom: 0; background-color: #efefef; border-top: 1px solid #ccc">
                    <mat-icon>add</mat-icon>
                    <span>{{ createNewEvent?.buttonText }}</span>
                </mat-option>
            </ng-container>
            <mat-option *ngIf="resetValue">{{ resetValue }}</mat-option>
            <mat-option *ngFor="let o of options | async" [value]="o">
                <div class="one-row">
                    {{ getValue(o) }}
                    <span class="spacer"></span>
                    <div *ngIf="showDeleteButton">
                        <button
                            mat-icon-button
                            (click)="deleteElement($event, o)"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
