<h1 mat-dialog-title>Meldung {{meldung?.meldung_id}} von {{meldung?.artikel}}</h1>
<div mat-dialog-content>
    <table>
        <tr class="zeile">
            <td>An:</td>
            <td>{{meldung?.meldung_an}}</td>
        </tr>
        <tr class="zeile">
            <td>Status:</td>
            <td>
                <mat-icon [style.color]="getStatusColor()">{{getStatusIcon()}}</mat-icon>
            </td>
        </tr>
        <tr class="zeile">
            <td>Kunde:</td>
            <td>{{meldung?.kunde}}</td>
        </tr>
        <tr *ngIf="meldung?.fehler?.length > 0">
            <td style="font-weight: bold; padding: 12px;">Fehler</td>
            <td style="border: 3px solid red; padding: 12px;">
                <pre style="white-space: normal; font-size: 1.4em;"><span *ngFor="let f of fehlerArray">{{f}}<br></span></pre>
            </td>
        </tr>


        <tr class="zeile">
            <td>Gruppe:</td>
            <td>{{meldung.gruppe}}</td>
        </tr>
        <tr class="zeile">
            <td>Zusatz:</td>
            <td>{{meldung.zusatz}}</td>
        </tr>
        <tr *ngIf="meldung.fehler?.length < 1" class="zeile">
            <td>Gemeldet am:</td>
            <td>{{meldung?.gemeldet | date: 'medium'}}</td>
        </tr>
        <tr class="zeile">
            <td>Tierarzt:</td>
            <td>{{meldung?.meldung?.tierarzt}}</td>
        </tr>
        <tr class="zeile">
            <td>Artikel:</td>
            <td>{{meldung?.artikel}}</td>
        </tr>
        <tr class="zeile">
            <td>Chargen:</td>
            <td>
                <div *ngFor="let c of meldung?.meldung?.chargen">
                    <span>{{c.charge_nr}}: {{c.chargenmenge}} Stk.</span>
                </div>
            </td>
        </tr>
    </table>
</div>
