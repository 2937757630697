<nav mat-tab-nav-bar>
    <a mat-tab-link [routerLink]="['gesamt']" routerLinkActive #kundenGesamt="routerLinkActive"
        [active]="kundenGesamt.isActive">Gesamt</a>
    <a mat-tab-link [routerLink]="['gesamteinzel']" routerLinkActive #kundenGesamtEinzel="routerLinkActive"
        [active]="kundenGesamtEinzel.isActive">Gesamt Einzel</a>
    <a mat-tab-link [routerLink]="['gruppe']" routerLinkActive #unternehmensGruppen="routerLinkActive"
        [active]="unternehmensGruppen.isActive">Unternehmensgruppen</a>
    <a mat-tab-link [routerLink]="['einzel']" routerLinkActive #kundeEinzel="routerLinkActive"
        [active]="kundeEinzel.isActive">Kunden Einzel</a>
</nav>
<router-outlet></router-outlet>