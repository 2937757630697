import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { GruppeTier } from "../classes/gruppe-tier";
import { Tierreklamation } from "../classes/tierreklamation";
import { TierreklamationCheckbox } from "../classes/tierreklamation-checkbox";
import { Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { GruppenService } from "../../gruppen/gruppen.service";
import { MessageService } from "../../../services/message-service/message.service";
import { StammdatenService } from "../../stammdaten/stammdaten.service";
import { DetachedRouteHandlesService } from "../../../configuration/route-caching-strategy/detached-route-handles.service";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { GruppeTierReklamationEditDialogComponent } from "../gruppe-tier-reklamation-neu-dialog/gruppe-tier-reklamation-edit-dialog.component";
import { GruppeTierService } from "../gruppe-tier.service";

@Component({
    selector: "kiene-gruppe-tier-reklamieren",
    templateUrl: "./gruppe-tier-reklamieren.component.html",
    styleUrls: ["./gruppe-tier-reklamieren.component.scss"],
})
export class GruppeTierReklamierenComponent implements OnInit, OnDestroy {
    @Input("dialog") dialog = false;
    @Input("tier") tier: GruppeTier;

    @Output("saved")
    saved: EventEmitter<boolean> = new EventEmitter<boolean>();

    isLoading = true;
    isSaving = false;
    tierId: number;
    ueberschrift = "Tierreklamation hinzufügen";
    tierreklamationen: Tierreklamation[] = [];
    tierreklamationCheckboxes: TierreklamationCheckbox[] = [];

    loadTierreklamationenSub: Subscription;
    gruppeTierSubscription: Subscription;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private gruppenService: GruppenService,
        private messageService: MessageService,
        private stammdatenService: StammdatenService,
        private detachedRouteHandlesService: DetachedRouteHandlesService,
        private matDialog: MatDialog,
        private gruppeTierService: GruppeTierService
    ) {}

    ngOnDestroy(): void {
        this.loadTierreklamationenSub?.unsubscribe();
        this.gruppeTierSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.tierId = this.tier
            ? this.tier.gt_id
            : this.route.snapshot.params["gt_id"];
        this.loadTier();

        this.detachedRouteHandlesService.watchChanges().subscribe({
            next: () => {
                this.loadTierreklamationen();
            },
        });
    }

    loadTierreklamationen(): void {
        this.isLoading = true;
        this.loadTierreklamationenSub?.unsubscribe();
        this.loadTierreklamationenSub = this.stammdatenService
            .getTierreklamationen(true)
            .subscribe({
                next: (tierreklamationen) => {
                    this.isLoading = false;
                    this.tierreklamationen = [];
                    this.tierreklamationCheckboxes = [];
                    this.tierreklamationen = tierreklamationen.records;
                    this.tierreklamationen.forEach(
                        (tierreklamation: Tierreklamation) => {
                            // preselect all existing tierreklamationen
                            const reklamationFound =
                                !!this.tier?.reklamationen?.find(
                                    (x) =>
                                        x.tierreklamation_id ===
                                        tierreklamation.tierreklamation_id
                                );
                            if (reklamationFound) {
                                this.tierreklamationCheckboxes.push(
                                    new TierreklamationCheckbox(
                                        tierreklamation,
                                        reklamationFound
                                    )
                                );
                            } else {
                                this.tierreklamationCheckboxes.push(
                                    new TierreklamationCheckbox(
                                        tierreklamation,
                                        false
                                    )
                                );
                            }
                        }
                    );
                },
                error: (err) => {
                    this.isLoading = false;
                    this.messageService.errorMessage(
                        "Fehler beim Laden der Tierreklamationen",
                        err
                    );
                },
            });
    }

    loadTier() {
        this.gruppeTierSubscription?.unsubscribe();
        this.gruppeTierSubscription = this.gruppenService
            .getGruppeTier(this.tierId)
            .subscribe({
                next: (tier) => {
                    this.tier = tier;
                    this.loadTierreklamationen();
                    this.isLoading = false;
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        "Fehler beim Laden des Tiers",
                        err
                    );
                    this.isLoading = false;
                },
            });
    }

    editTierreklamation(
        event: MouseEvent,
        tierreklamation?: Tierreklamation
    ): void {
        event.stopPropagation();

        this.matDialog
            .open(GruppeTierReklamationEditDialogComponent, {
                width: "90vw",
                minWidth: "90vw",
                data: {
                    tierreklamation: tierreklamation,
                },
            })
            .afterClosed()
            .subscribe((result: Tierreklamation) => {
                if (result) {
                    this.tierreklamationCheckboxes.push(
                        new TierreklamationCheckbox(result, true)
                    );
                    this.tier.reklamationen.push(result);
                }
            });
    }

    reklamationHinzufuegen() {
        this.isSaving = true;
        this.gruppeTierService.update(this.tier).subscribe({
            next: () => {
                this.isSaving = false;
                if (this.dialog) {
                    this.saved.emit();
                } else {
                    this.messageService.infoMessage(
                        "Die Reklamation wurde hinzugefügt"
                    );
                    this.router
                        .navigate(["../../"], { relativeTo: this.route })
                        .then();
                }
            },
            error: (err) => {
                this.isSaving = false;
                this.messageService.errorMessage("Fehler!", err);
            },
        });
    }

    addSelected(
        change: MatCheckboxChange | { checked },
        tierreklamationCheckbox: TierreklamationCheckbox,
        click?: MouseEvent
    ) {
        if (click) {
            click.preventDefault();
            click.stopPropagation();
        }
        if (change.checked) {
            tierreklamationCheckbox.checked = true;
            this.tier.reklamationen.push(
                tierreklamationCheckbox.tierreklamation
            );
        } else {
            tierreklamationCheckbox.checked = false;
            this.tier.reklamationen = this.tier.reklamationen.filter(
                (x) =>
                    x.tierreklamation_id !==
                    tierreklamationCheckbox.tierreklamation.tierreklamation_id
            );
        }
    }
}
