import { Component, OnInit, Inject } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Anwendung, AnwendungTierart } from "../anwendung";
import { Material } from "../../material";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Tierart } from "../../../../administration/tierarten/tierart";
import { HttpParams } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { KieneTableService } from "../../../../components/kiene-table/kiene-table.service";
import { MessageService } from "../../../../services/message.service";

@Component({
    selector: "kiene-artikel-anwendung-dialog",
    templateUrl: "./artikel-anwendung-dialog.component.html",
    styleUrls: ["./artikel-anwendung-dialog.component.scss"],
})
export class ArtikelAnwendungDialogComponent implements OnInit {
    anwendungCtrl = new UntypedFormControl();
    anwendung: Anwendung;
    material = new Material();
    tierarten: Tierart[] = [];
    anwendungTierarten: AnwendungTierart[] = [];

    selectedTierarten: boolean[] = [];

    qsKeyCtrl = new UntypedFormControl();
    hitKeyCtrl = new UntypedFormControl();
    tierartCtrl = new UntypedFormControl();

    constructor(
        public dialogRef: MatDialogRef<ArtikelAnwendungDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private api: KieneTableService,
        private messageService: MessageService
    ) {
        if (data.anwendung) {
            this.anwendung = data.anwendung;
            this.anwendungCtrl.setValue(this.anwendung.anwendung);
            this.qsKeyCtrl.setValue(this.anwendung.qs_schluessel);
            this.hitKeyCtrl.setValue(this.anwendung.hit_schluessel);
        }
        this.material = data.material;
    }

    ngOnInit() {
        this.loadTierarten();
    }

    /**
     * Lade Tierarten fuer diesen Artikel.
     *
     * @memberof AddIndikationToMaterialDialogComponent
     */
    loadTierarten() {
        let params = new HttpParams().append(
            "artikel_id",
            this.material.artikel_id.toString()
        );
        this.api
            .get(environment.BASE_URI + "artikel_tierart/read.php", params)
            .subscribe(
                (response) => {
                    this.tierarten = response.records;
                    this.initAnwendungTierarten();
                },
                (error) => {
                    this.messageService.errorMessage(
                        "Tierarten konnten nicht geladen werden!",
                        error
                    );
                }
            );
    }

    initAnwendungTierarten() {
        for (let t of this.tierarten) {
            const at = new AnwendungTierart();
            at.tierart = t.tierart;
            at.tierart_id = t.tierart_id;
            this.anwendungTierarten.push(at);
        }
        for (let at of this.anwendung?.tierarten) {
            this.selectedTierarten[at.tierart_id] = true;
            for (let o of this.anwendungTierarten) {
                if (o.tierart_id === at.tierart_id) {
                    o.kommentar = at.kommentar;
                    o.wartezeit_fleisch = at.wartezeit_fleisch;
                    o.wartezeit_milch = at.wartezeit_milch;
                    o.wartezeit_eier = at.wartezeit_eier;
                }
            }
        }
    }
    isTierartInAnwendung(t: Tierart): boolean {
        for (let at of this.anwendung?.tierarten) {
            if (at.tierart_id === t.tierart_id) {
                return true;
            }
        }
        return false;
    }

    /**
     * Vergleich zwei Tierarten anhand der ID
     *
     * @param {Tierart} t1
     * @param {Tierart} t2
     * @return {*}  {boolean}
     * @memberof AddIndikationToMaterialDialogComponent
     */
    tierartComparator(t1: Tierart, t2: Tierart): boolean {
        if (!t1 || !t2) {
            return false;
        }

        return t1.tierart_id === t2.tierart_id;
    }

    getSelectedAnwendungTierarten(): AnwendungTierart[] {
        let at: AnwendungTierart[] = [];
        for (let a of this.anwendungTierarten) {
            if (this.selectedTierarten[a.tierart_id]) {
                at.push(a);
            }
        }
        return at;
    }

    cancel() {
        this.dialogRef.close(null);
    }

    save() {
        if (!this.anwendung) {
            this.anwendung = new Anwendung();
        }
        this.anwendung.anwendung = this.anwendungCtrl.value;
        this.anwendung.artikel_id = this.material.artikel_id;
        this.anwendung.qs_schluessel = this.qsKeyCtrl.value;
        this.anwendung.hit_schluessel = this.hitKeyCtrl.value;
        // this.anwendung.tierarten = this.tierartCtrl.value;
        this.anwendung.tierarten = this.getSelectedAnwendungTierarten();
        this.dialogRef.close(this.anwendung);
    }
}
