<nav mat-tab-nav-bar style="margin-top: 63px;">
  <a *ngIf="isTabMenuVisible('/wawi/informationen')" mat-tab-link [routerLink]="['/wawi/informationen']"
    routerLinkActive #infla="routerLinkActive" [active]="infla.isActive">Informationen</a>
  <a *ngIf="isTabMenuVisible('/wawi/bestellung-extern')" mat-tab-link [routerLink]="['/wawi/bestellung-extern']"
    routerLinkActive #bela="routerLinkActive" [active]="bela.isActive">Bestellungen Extern</a>
  <a *ngIf="isTabMenuVisible('/wawi/bestellvorschlaege')" mat-tab-link [routerLink]="['/wawi/bestellvorschlaege']"
    routerLinkActive #bvla="routerLinkActive" [active]="bvla.isActive">Bestellvorschl&auml;ge Extern</a>
  <a *ngIf="isTabMenuVisible('/wawi/bestellung-intern')" mat-tab-link [routerLink]="['/wawi/bestellung-intern']"
    routerLinkActive #bila="routerLinkActive" [active]="bila.isActive">Bestellung Intern</a>
  <a *ngIf="isTabMenuVisible('/wawi/bestellvorschlaege-intern')" mat-tab-link
    [routerLink]="['/wawi/bestellvorschlaege-intern']" routerLinkActive #bvila="routerLinkActive"
    [active]="bvila.isActive">Bestellungvorschl&auml;ge Intern</a>
</nav>
<router-outlet></router-outlet>