<div style="padding: 12px;">
    <button (click)="loadStandorte()" mat-flat-button color="accent" style="margin-top: 24px;"
        [disabled]="loadingOrSaving">
        <mat-icon>refresh</mat-icon>
        <span>&nbsp;Aktualisieren</span>
    </button>

    <ng-container *ngIf="!appService.isMobileScreen">

        <div class="one-row" style="margin-top: 24px;">
            <div *ngFor="let s of standorte; let i = index;"
                style="display: flex; flex-direction: column; align-self: flex-start; justify-content: space-between; width: 300px;">


                <div class="one-row">
                    <span style="font-weight: bold; padding-left: 12px;">{{(i + 1)}}
                        .)&nbsp;&nbsp;{{(s.datum_von | date: 'dd.MM.yy') || '---'}} {{s.datum_bis ? (' - ' +
                        (s.datum_bis | date: 'dd.MM.yy')) : ''}}</span>
                    <button mat-icon-button color="accent" *kienePermission="[9352,9378]"
                        [matMenuTriggerFor]="editMenu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <span class="spacer"></span>
                    <mat-menu #editMenu>
                        <button *kienePermission="[9352]" mat-menu-item (click)="editStandort(s, i)"
                            [disabled]="loadingOrSaving">
                            <mat-icon>edit</mat-icon>
                            <span>Bearbeiten</span>
                        </button>
                        <ng-container *ngIf="i > 0">
                            <button *kienePermission="[9378]" mat-menu-item (click)="deleteStandort(s)"
                                [disabled]="loadingOrSaving">
                                <mat-icon>delete</mat-icon>
                                <span>Löschen</span></button>
                        </ng-container>
                        <ng-container *ngIf="i === 0">
                            <button *kienePermission="[9378]" mat-menu-item
                                (click)="gewichtePreiseEingebenEinstallen(s)" [disabled]="loadingOrSaving">
                                <mat-icon>scale</mat-icon>
                                <span>&nbsp;Einstallgewichte &
                                    Preise</span></button>
                        </ng-container>
                        <ng-container *ngIf="i > -1">
                            <button *kienePermission="[9378]" mat-menu-item (click)="einzeltiergewichtEintragen(s)">
                                <mat-icon>scale</mat-icon>
                                <span>&nbsp;Einzeltiergewicht eintragen</span></button>
                        </ng-container>
                        <ng-container *ngIf="i >= standorte?.length-1">
                            <button mat-menu-item (click)="gewichtePreiseEingebenAusstallen(s)"
                                *kienePermission="[9378]" [disabled]="loadingOrSaving">
                                <mat-icon>scale</mat-icon>
                                <span>&nbsp;Abgangsgewichte & Preise</span>
                            </button>
                        </ng-container>
                    </mat-menu>
                </div>
                <svg width="300px" height="12px">
                    <circle *ngIf="i === 0" r="6" cx="6" cy="6" style="fill: gray" />
                    <line x1="0%" y1="6px" x2="290" y2="6px" style="stroke: gray; stroke-width: 2px;"></line>
                    <polygon points="290,0 290,12 300,6" style="fill: gray; stroke: gray;" />
                </svg>
                <div style="padding-right: 24px; margin-top: 12px;">
                    <kiene-key-value-list>
                        <kiene-key-value-list-item key="Standort" [value]="s.stall"></kiene-key-value-list-item>

                        <kiene-key-value-list-item key="Produktionsabschnitt"
                            [value]="s.produktionsabschnitt"></kiene-key-value-list-item>
                        <kiene-key-value-list-item *ngIf="s.sgs_nummer" key="SGS Nummer"
                            [value]="s.sgs_nummer"></kiene-key-value-list-item>
                        <ng-container *kienePermission="[9161]">
                            <kiene-key-value-list-item
                                key="&Oslash; Einstallgewicht / {{s.einstallgewicht_avg?.anzahl || 0}} Tier(e)"
                                value="{{(s.einstallgewicht_avg?.wert | number:'1.1-1')}} kg"></kiene-key-value-list-item>

                            <kiene-key-value-list-item
                                key="&Oslash; Abgangsgewicht / {{s.abgangsgewicht_avg?.anzahl || 0}} Tier(e)"
                                value="{{(s.abgangsgewicht_avg?.wert | number:'1.1-1')}} kg"></kiene-key-value-list-item>

                            <kiene-key-value-list-item
                                key="&Oslash; Tageszunahmen / {{s.tageszunahmen_avg?.anzahl || 0}} Tier(e)"
                                value="{{(s.tageszunahmen_avg?.wert | number:'1.1-1')}} kg"></kiene-key-value-list-item>
                        </ng-container>
                        <ng-container *kienePermission="[9162]">
                            <kiene-key-value-list-item *ngIf="i === 0"
                                key="&Oslash; Einkaufspreise / {{s.einkaufspreis_avg?.anzahl || 0}} Tier(e)"
                                value="{{(s.einkaufspreis_avg?.wert | number:'1.2-2')}} &euro;"></kiene-key-value-list-item>

                            <kiene-key-value-list-item *ngIf="i >= standorte?.length -1"
                                key="&Oslash; Verkaufspreise/ {{s.verkaufspreis_avg?.anzahl || 0}} Tier(e)"
                                value="{{(s.verkaufspreis_avg?.wert | number:'1.2-2')}} &euro;"></kiene-key-value-list-item>

                            <kiene-key-value-list-item *ngIf="i >= standorte?.length -1"
                                key="&Oslash; Erl&ouml;sdifferenz/ {{s.erloesdifferenz_avg?.anzahl || 0}} Tier(e)"
                                value="{{(s.erloesdifferenz_avg?.wert | number:'1.2-2')}} &euro;"></kiene-key-value-list-item>

                        </ng-container>
                    </kiene-key-value-list>
                </div>
            </div>
            <div *kienePermission="[9352]">
                <button mat-flat-button color="accent" (click)="standortwechsel()" [disabled]="loadingOrSaving">
                    <mat-icon>swap_horiz</mat-icon>
                    <span>&nbsp;Standort wechseln</span>
                </button>
            </div>
            <span class="spacer"></span>
        </div>


    </ng-container>

    <ng-container *ngIf="appService.isMobileScreen">
        <div class="one-col" style="margin-top: 24px; padding-left: 12px;">
            <div class="one-row" *ngFor="let s of standorte; let i = index;">
                <div style="width: 20px; display: flex; flex-direction: column; align-self: stretch;">
                    <svg style="width: 100%; height: 100%;">
                        <circle cx="10" cy="8" r="8" style="fill: gray;"></circle>
                        <!--                            <rect *ngIf="i === tour?.elemente.length -1" width="16" height="16" x="2"-->
                        <!--                                  y="100%"-->
                        <!--                                  style="fill: #008000; transform: translateY(-16px)"></rect>-->
                        <svg viewBox="0 0 10 10" width="20px">
                            <polygon points="2,0 5,10 8,0" style="fill: gray;"></polygon>
                        </svg>
                        <line x1="10" y1="0%" x2="10" y2="100%" style="stroke: gray; stroke-width: 3;"></line>
                        <rect *ngIf="i === standorte?.length -1" width="16" height="16" x="2" y="100%"
                            style="fill: gray; transform: translateY(-16px)"></rect>
                    </svg>
                </div>
                <div
                    style="display: flex; flex-direction: column; align-self: flex-start; justify-content: space-between; width: 300px;">


                    <div class="one-row">
                        <span style="font-weight: bold; padding-left: 12px;">{{(i + 1)}}
                            .)&nbsp;&nbsp;{{(s.datum_von | date: 'dd.MM.yy') || '---'}} {{s.datum_bis ? (' - ' +
                            (s.datum_bis | date: 'dd.MM.yy')) : ''}}</span>
                        <button mat-icon-button color="accent" *kienePermission="[9352,9378]"
                            [matMenuTriggerFor]="editMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <span class="spacer"></span>
                        <mat-menu #editMenu>
                            <button *kienePermission="[9352]" mat-menu-item (click)="editStandort(s, i)"
                                [disabled]="loadingOrSaving">
                                <mat-icon>edit</mat-icon>
                                <span>Bearbeiten</span>
                            </button>
                            <ng-container *ngIf="i > 0">
                                <button *kienePermission="[9378]" mat-menu-item (click)="deleteStandort(s)"
                                    [disabled]="loadingOrSaving">
                                    <mat-icon>delete</mat-icon>
                                    <span>Löschen</span></button>
                            </ng-container>
                            <ng-container *ngIf="i === 0">
                                <button *kienePermission="[9378]" mat-menu-item
                                    (click)="gewichtePreiseEingebenEinstallen(s)" [disabled]="loadingOrSaving">
                                    <mat-icon>scale</mat-icon>
                                    <span>&nbsp;Einstallgewichte &
                                        Preise</span></button>
                            </ng-container>
                            <ng-container *ngIf="i > -1">
                                <button *kienePermission="[9378]" mat-menu-item (click)="einzeltiergewichtEintragen(s)">
                                    <mat-icon>scale</mat-icon>
                                    <span>&nbsp;Einzeltiergewicht eintragen</span></button>
                            </ng-container>
                            <ng-container *ngIf="i >= standorte?.length-1">
                                <button mat-menu-item (click)="gewichtePreiseEingebenAusstallen(s)"
                                    *kienePermission="[9378]" [disabled]="loadingOrSaving">
                                    <mat-icon>scale</mat-icon>
                                    <span>&nbsp;Abgangsgewichte & Preise</span>
                                </button>
                            </ng-container>
                        </mat-menu>
                    </div>

                    <div style="padding-right: 24px; margin-top: 12px;">
                        <kiene-key-value-list>
                            <kiene-key-value-list-item key="Standort" [value]="s.stall"></kiene-key-value-list-item>

                            <kiene-key-value-list-item key="Produktionsabschnitt"
                                [value]="s.produktionsabschnitt"></kiene-key-value-list-item>
                            <ng-container *kienePermission="[9161]">
                                <kiene-key-value-list-item
                                    key="&Oslash; Einstallgewicht / {{s.einstallgewicht_avg?.anzahl || 0}} Tier(e)"
                                    value="{{(s.einstallgewicht_avg?.wert | number:'1.1-1')}} kg"></kiene-key-value-list-item>

                                <kiene-key-value-list-item
                                    key="&Oslash; Abgangsgewicht / {{s.abgangsgewicht_avg?.anzahl || 0}} Tier(e)"
                                    value="{{(s.abgangsgewicht_avg?.wert | number:'1.1-1')}} kg"></kiene-key-value-list-item>

                                <kiene-key-value-list-item
                                    key="&Oslash; Tageszunahmen / {{s.tageszunahmen_avg?.anzahl || 0}} Tier(e)"
                                    value="{{(s.tageszunahmen_avg?.wert | number:'1.1-1')}} kg"></kiene-key-value-list-item>
                            </ng-container>
                            <ng-container *kienePermission="[9162]">
                                <kiene-key-value-list-item *ngIf="i === 0"
                                    key="&Oslash; Einkaufspreise / {{s.einkaufspreis_avg?.anzahl || 0}} Tier(e)"
                                    value="{{(s.einkaufspreis_avg?.wert | number:'1.2-2')}} &euro;"></kiene-key-value-list-item>

                                <kiene-key-value-list-item *ngIf="i >= standorte?.length -1"
                                    key="&Oslash; Verkaufspreise/ {{s.verkaufspreis_avg?.anzahl || 0}} Tier(e)"
                                    value="{{(s.verkaufspreis_avg?.wert | number:'1.2-2')}} &euro;"></kiene-key-value-list-item>

                                <kiene-key-value-list-item *ngIf="i >= standorte?.length -1"
                                    key="&Oslash; Erl&ouml;sdifferenz/ {{s.erloesdifferenz_avg?.anzahl || 0}} Tier(e)"
                                    value="{{(s.erloesdifferenz_avg?.wert | number:'1.2-2')}} &euro;"></kiene-key-value-list-item>

                            </ng-container>
                        </kiene-key-value-list>
                    </div>
                </div>
            </div>
            <div *kienePermission="[9352]" style="margin-top: 24px;">
                <button mat-flat-button color="accent" (click)="standortwechsel()" [disabled]="loadingOrSaving">
                    <mat-icon>swap_horiz</mat-icon>
                    <span>&nbsp;Standort wechseln</span>
                </button>
            </div>
            <span class="spacer"></span>
        </div>
    </ng-container>

    <!--    <ng-container *ngIf="false || appService.isMobileScreen">-->


    <!--        <mat-stepper [orientation]="stepperOrientation" style="margin-top: 24px; width: 100%;" [linear]="false"-->
    <!--                     #stepper>-->
    <!--            <mat-step *ngFor="let s of standorte; let i = index" [completed]="true">-->
    <!--                <ng-template matStepLabel>-->
    <!--                    <div class="one-row">-->
    <!--                <span style="font-weight: bold;">{{(s.datum_von | date: 'dd.MM.yy') || '-&#45;&#45;'}} {{s.datum_bis ? (' - ' +-->
    <!--                    (s.datum_bis | date: 'dd.MM.yy')) : ''}}</span>-->
    <!--                        <button mat-icon-button color="accent" *kienePermission="[9352,9378]"-->
    <!--                                [matMenuTriggerFor]="editMenu">-->
    <!--                            <mat-icon>more_vert</mat-icon>-->
    <!--                        </button>-->
    <!--                        <mat-menu #editMenu>-->
    <!--                            <button *kienePermission="[9352]" mat-menu-item (click)="editStandort(s, i)"-->
    <!--                                    [disabled]="loadingOrSaving">-->
    <!--                                <mat-icon>edit</mat-icon>-->
    <!--                                <span>Bearbeiten</span>-->
    <!--                            </button>-->
    <!--                            <ng-container *ngIf="i > 0">-->
    <!--                                <button *kienePermission="[9378]" mat-menu-item (click)="deleteStandort(s)"-->
    <!--                                        [disabled]="loadingOrSaving">-->
    <!--                                    <mat-icon>delete</mat-icon>-->
    <!--                                    <span>Löschen</span></button>-->
    <!--                            </ng-container>-->
    <!--                            <ng-container *ngIf="i === 0">-->
    <!--                                <button *kienePermission="[9378]" mat-menu-item-->
    <!--                                        (click)="gewichtePreiseEingebenEinstallen(s)"-->
    <!--                                        [disabled]="loadingOrSaving">-->
    <!--                                    <mat-icon>scale</mat-icon>-->
    <!--                                    <span>&nbsp;Einstallgewichte &-->
    <!--                                Preise</span></button>-->
    <!--                            </ng-container>-->
    <!--                            <ng-container *ngIf="i > -1">-->
    <!--                                <button *kienePermission="[9378]" mat-menu-item (click)="einzeltiergewichtEintragen(s)">-->
    <!--                                    <mat-icon>scale</mat-icon>-->
    <!--                                    <span>&nbsp;Einzeltiergewicht eintragen</span></button>-->
    <!--                            </ng-container>-->
    <!--                            <ng-container *ngIf="i >= standorte?.length-1">-->
    <!--                                <button mat-menu-item (click)="gewichtePreiseEingebenAusstallen(s)"-->
    <!--                                        *kienePermission="[9378]"-->
    <!--                                        [disabled]="loadingOrSaving">-->
    <!--                                    <mat-icon>scale</mat-icon>-->
    <!--                                    <span>&nbsp;Abgangsgewichte & Preise</span>-->
    <!--                                </button>-->
    <!--                            </ng-container>-->
    <!--                        </mat-menu>-->
    <!--                    </div>-->
    <!--                </ng-template>-->
    <!--                <kiene-key-value-list>-->
    <!--                    <kiene-key-value-list-item key="Standort" [value]="s.stall"></kiene-key-value-list-item>-->

    <!--                    <kiene-key-value-list-item key="Produktionsabschnitt"-->
    <!--                                               [value]="s.produktionsabschnitt"></kiene-key-value-list-item>-->
    <!--                    <ng-container *kienePermission="[9161]">-->
    <!--                        <kiene-key-value-list-item-->
    <!--                            key="&Oslash; Einstallgewicht / {{s.einstallgewicht_avg?.anzahl || 0}} Tier(e)"-->
    <!--                            value="{{(s.einstallgewicht_avg?.wert | number:'1.1-1')}} kg"></kiene-key-value-list-item>-->

    <!--                        <kiene-key-value-list-item-->
    <!--                            key="&Oslash; Abgangsgewicht / {{s.abgangsgewicht_avg?.anzahl || 0}} Tier(e)"-->
    <!--                            value="{{(s.abgangsgewicht_avg?.wert | number:'1.1-1')}} kg"></kiene-key-value-list-item>-->

    <!--                        <kiene-key-value-list-item-->
    <!--                            key="&Oslash; Tageszunahmen / {{s.tageszunahmen_avg?.anzahl || 0}} Tier(e)"-->
    <!--                            value="{{(s.tageszunahmen_avg?.wert | number:'1.1-1')}} kg"></kiene-key-value-list-item>-->
    <!--                    </ng-container>-->
    <!--                    <ng-container *kienePermission="[9162]">-->
    <!--                        <kiene-key-value-list-item *ngIf="i === 0"-->
    <!--                                                   key="&Oslash; Einkaufspreise / {{s.einkaufspreis_avg?.anzahl || 0}} Tier(e)"-->
    <!--                                                   value="{{(s.einkaufspreis_avg?.wert | number:'1.2-2')}} &euro;"></kiene-key-value-list-item>-->

    <!--                        <kiene-key-value-list-item *ngIf="i >= standorte?.length -1"-->
    <!--                                                   key="&Oslash; Verkaufspreise/ {{s.verkaufspreis_avg?.anzahl || 0}} Tier(e)"-->
    <!--                                                   value="{{(s.verkaufspreis_avg?.wert | number:'1.2-2')}} &euro;"></kiene-key-value-list-item>-->

    <!--                        <kiene-key-value-list-item *ngIf="i >= standorte?.length -1"-->
    <!--                                                   key="&Oslash; Erl&ouml;sdifferenz/ {{s.erloesdifferenz_avg?.anzahl || 0}} Tier(e)"-->
    <!--                                                   value="{{(s.erloesdifferenz_avg?.wert | number:'1.2-2')}} &euro;"></kiene-key-value-list-item>-->

    <!--                    </ng-container>-->
    <!--                </kiene-key-value-list>-->

    <!--            </mat-step>-->
    <!--            <mat-step *kienePermission="[9352]">-->
    <!--                <ng-template matStepLabel>Standort wechseln</ng-template>-->
    <!--                <button mat-flat-button color="accent" (click)="standortwechsel()" [disabled]="loadingOrSaving">-->
    <!--                    <mat-icon>swap_horiz</mat-icon>-->
    <!--                    <span>&nbsp;Wechseln</span>-->
    <!--                </button>-->
    <!--            </mat-step>-->
    <!--        </mat-stepper>-->
    <!--    </ng-container>-->
</div>