export class Empfaenger {
    email: string;
    name: string;
    selected: boolean;


    constructor(email?: string, name?: string, selected?: boolean) {
        this.email = email;
        this.name = name;
        this.selected = selected;
    }
}
