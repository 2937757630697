import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { Preisgruppe } from '../preisgruppen';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'kiene-add-preisgruppe',
  templateUrl: './add-preisgruppe.component.html',
  styleUrls: ['./add-preisgruppe.component.scss']
})
export class AddPreisgruppeComponent implements OnInit {

  preisgruppe: Preisgruppe;

  constructor(
    public dialogRef: MatDialogRef<AddPreisgruppeComponent>,
    public api: KieneTableService,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.preisgruppe !== null && data.preisgruppe !== undefined) {
      this.preisgruppe = data.preisgruppe;
    } else {
      this.preisgruppe = new Preisgruppe();
    }
  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    console.debug(JSON.stringify(this.preisgruppe));
    this.api.updateEntity(environment.BASE_URI + 'preisgruppe/update.php', this.preisgruppe).subscribe(
      response => {
        this.cancel();
      },
      error => {
        this.messageService.errorMessage('Preisgruppe konnte nicht gespeichert werden!', error);
      }
    )
  }

}
