<h3 mat-dialog-title>Preisgruppe Artikel anlegen</h3>
<mat-dialog-content>
  <kiene-autocomplete [apiUrl]="'artikel_autocomplete/'" [placeholder]="'Artikel'" [displayField]="'artikel_me'"
    (elementSelected)="setArtikel($event)" [kieneObject]="artikel" [focus]="false"></kiene-autocomplete>
  <kiene-autocomplete [apiUrl]="'verkaufspreis/'" [placeholder]="'Verkaufspreis'" [displayField]="'bezeichnung'"
    (elementSelected)="setVerkaufspreis($event)" [kieneObject]="verkaufspreis" [focus]="false"></kiene-autocomplete>
    <mat-form-field style="width: 100%;">
      <input matInput type="number" placeholder="Einzelpreis" [(ngModel)]="preisgruppeArtikel.einzelpreis">
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
  <button mat-raised-button color="primary" (click)="save()">Speichern</button>
</mat-dialog-actions>