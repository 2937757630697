export class Freigabe {
    kf_id: number;
    kunde_id_freigabe: number;
    kunde_id: number;
    zusatz_freigabe: string;
    betrieb_freigabe: string;
    vvvo_freigabe: number;
    zusatz_freigeber: string;
    betrieb_freigeber: string;
    vvvo_freigeber: number;
    erstellt: Date;
}
