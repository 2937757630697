<h1 mat-dialog-title>Position</h1>
<div mat-dialog-content>
    <div class="one-row">
        <span class="spacer"></span>
        <button *ngIf="!p?.aus_stueckliste" mat-stroked-button color="warn" (click)="removePosition()"
                [disabled]="isLoading">
            <mat-icon>delete</mat-icon>
            L&ouml;schen
        </button>
        <span class="spacer"></span>
    </div>
    <kiene-key-value-list>
        <kiene-key-value-list-item key="Artikel" [value]="p?.artikel_me"></kiene-key-value-list-item>
        <kiene-key-value-list-item key="Menge" [value]="p?.artikelmenge"></kiene-key-value-list-item>
        <kiene-key-value-list-item key="Gruppe" [value]="p?.gruppe"></kiene-key-value-list-item>
        <kiene-key-value-list-item key="Tierart" [value]="p?.tierart"></kiene-key-value-list-item>
        <kiene-key-value-list-item key="Anwendung" [value]="p?.anwendung"></kiene-key-value-list-item>
        <kiene-key-value-list-item key="Diagnose" [value]="p?.diagnose"></kiene-key-value-list-item>
        <kiene-key-value-list-item key="Art"
                                   [value]="p?.behandlung === 1 ? 'Behandlung' : 'Abgabe'"></kiene-key-value-list-item>
        <kiene-key-value-list-item key="Anzahl Tiere" [value]="p?.anzahl_tiere"></kiene-key-value-list-item>
        <kiene-key-value-list-item key="Anwendungsdauer" [value]="p?.anwendungsdauer"></kiene-key-value-list-item>
        <kiene-key-value-list-item key="Gewicht" [value]="p?.tiergewicht"></kiene-key-value-list-item>
        <kiene-key-value-list-item key="Dosierung" [value]="p?.dosierung"></kiene-key-value-list-item>
        <kiene-key-value-list-item key="Identifikation" [value]="p?.identifikation"></kiene-key-value-list-item>
        <kiene-key-value-list-item key="Chargen" [value]="chargenText(p)"></kiene-key-value-list-item>
        <kiene-key-value-list-item *kienePermission="[1229]" key="Einzelpreis" [actionButtonIcon]="'edit'"
                                   value="{{p?.einzelpreis | number: '1.2-2'}} &euro;"
                                   (actionClicked)="einzelpreisEditieren()"></kiene-key-value-list-item>
        <ng-container *ngIf="currentUserIsAdmin && p?.einzelpreis_editor">
            <kiene-key-value-list-item *kienePermission="[1229]" key="Einzelpreis ge&auml;ndert von"
                                       [value]="p?.einzelpreis_editor"></kiene-key-value-list-item>

        </ng-container>
    </kiene-key-value-list>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button color="accent" (click)="dialogRef.close(false)">Schlie&szlig;en</button>
</div>
