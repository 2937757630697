import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Benachrichtigung } from '../classes/benachrichtigung';
import { BenachrichtigungenService } from '../benachrichtigungen.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BenachrichtigungTyp } from '../classes/benachrichtigung-typ';
import { KieneUtils } from '../../utils/KieneUtils';
import { Subscription } from 'rxjs';
import { MessageService } from '../../services/message-service/message.service';
import {
    BenachrichtigungClickEvent
} from '../classes/benachrichtigung-click-event';

@Component({
    selector: 'kiene-benachrichtigung-uebersicht',
    templateUrl: './benachrichtigung-uebersicht.component.html',
    styleUrls: ['./benachrichtigung-uebersicht.component.scss']
})
export class BenachrichtigungUebersichtComponent implements OnInit, OnDestroy {
    benachrichtigungen: Benachrichtigung[] = [];
    isLoading = true;
    isSaving = false;

    onlyUnreadBenachrichtigungen = true;
    searchCtrl: UntypedFormControl = new UntypedFormControl();
    @Output('benachrichtigungRead') benachrichtigungRead: EventEmitter<Benachrichtigung> = new EventEmitter<Benachrichtigung>();
    private offset = 0;
    private limit = 10;
    benachrichtigungsTyp = BenachrichtigungTyp;
    scrollDisabled = false;
    getBenachrichtigungenSub: Subscription;
    reloadSub: Subscription;
    interval: any;

    constructor(
        private benachrichtigungenService: BenachrichtigungenService,
        private messageService: MessageService
    ) {
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
        this.reloadSub?.unsubscribe();
    }

    ngOnInit(): void {

        this.leseBenachrichtigungen();

        this.interval = setInterval(() => {
            this.leseBenachrichtigungen();
        }, 1000 * 60);

        this.searchCtrl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(() => {
            this.offset = 0;
            this.benachrichtigungen = [];
            this.search();
        });

        this.reloadSub = this.benachrichtigungenService.watchReload().subscribe({
            next: (reload: boolean) => {
                if (reload) {
                    console.log('reload');
                    this.leseBenachrichtigungen();
                }
            }
        });
    }

    leseBenachrichtigungen() {
        if (this.onlyUnreadBenachrichtigungen) {
            this.filterUnread(true);
        } else {
            this.loadAllNotifications();
        }
    }

    loadAllNotifications() {
        this.isLoading = true;
        const filter = { offset: 0, limit: this.limit };
        if (this.searchCtrl.value) {
            filter['search'] = this.searchCtrl.value;
        }
        this.getBenachrichtigungenSub?.unsubscribe();
        this.getBenachrichtigungenSub = this.benachrichtigungenService.getBenachrichtigungen(filter).subscribe({
            next: (notifications) => {
                this.benachrichtigungenService.setBenachrichtigungen(this.benachrichtigungen);
                this.isLoading = false;
                // add or replace
                if (this.benachrichtigungen.length === 0) {
                    this.benachrichtigungen = <Benachrichtigung[]>notifications.records;
                } else {
                    notifications.records.forEach((notification: Benachrichtigung) => {
                        const i = this.benachrichtigungen.findIndex(b => b.bb_id === notification.bb_id);
                        if (i >= 0) {
                            this.benachrichtigungen[i] = notification;
                        } else {
                            this.benachrichtigungen.push(notification);
                        }

                    });
                    this.benachrichtigungen.sort((a, b) => new Date(b.erstellt).getTime() - new Date(a.erstellt).getTime());
                }
            },
        });
    }

    markAsRead(benachrichtigung: Benachrichtigung) {
        console.log('markAsRead');
        if (benachrichtigung.gelesen > 0) {
            // schon gelesen
            this.benachrichtigungenService.setBenachrichtigungSelected(benachrichtigung);
        } else {
            this.benachrichtigungenService.markBenachrichtigungAsRead(benachrichtigung).subscribe({
                next: () => {
                    this.benachrichtigungenService.setBenachrichtigungSelected(benachrichtigung);
                    this.benachrichtigungenService.setBenachrichtigungen(this.benachrichtigungen);
                },
                error: (err) => {
                    console.log('READ NOTIFICATION ERROR', err);
                },
            });
        }
        this.benachrichtigungenService.fireBenachrichtiungClickEvent(new BenachrichtigungClickEvent(benachrichtigung));
    }

    formatDate(createdAt: Date) {
        return KieneUtils.beautifyDate(createdAt);
    }

    filterUnread(checked: boolean) {
        this.offset = 0;
        this.isLoading = true;
        this.onlyUnreadBenachrichtigungen = checked;
        this.getBenachrichtigungenSub?.unsubscribe();
        if (checked) {
            this.getBenachrichtigungenSub = this.benachrichtigungenService.getBenachrichtigungen({
                gelesen: 0,
                offset: this.offset,
                limit: this.limit
            }).subscribe({
                next: (response) => {
                    this.isLoading = false;
                    this.benachrichtigungen = <Benachrichtigung[]>response.records;
                },
            });
        } else {
            this.getBenachrichtigungenSub = this.benachrichtigungenService.getBenachrichtigungen({
                offset: this.offset,
                limit: this.limit
            }).subscribe({
                next: (response) => {
                    this.isLoading = false;
                    this.benachrichtigungen = <Benachrichtigung[]>response.records;
                },
            });
        }
    }

    allRead() {
        return this.benachrichtigungen?.every((n) => n.gelesen === 1);
    }

    search() {
        this.isLoading = true;
        const searchTxt = this.searchCtrl.value;
        console.log('search: ', searchTxt);
        const filter = { offset: this.offset, limit: this.limit };
        if (this.onlyUnreadBenachrichtigungen) {
            filter['gelesen'] = 0;
        }
        if (this.searchCtrl.value) {
            filter['search'] = this.searchCtrl.value;
        }
        this.getBenachrichtigungenSub?.unsubscribe();
        this.getBenachrichtigungenSub = this.benachrichtigungenService
            .getBenachrichtigungen(filter)
            .subscribe({
                next: (response) => {
                    this.scrollDisabled = false;
                    this.isLoading = false;
                    this.benachrichtigungen = [...this.benachrichtigungen, ...<Benachrichtigung[]>response.records];
                },
            });
    }

    scrolled() {
        console.log('scrolled...');
        this.offset += this.limit;
        this.search();
    }

    markAllAsRead() {
        this.isSaving = true;
        this.benachrichtigungenService.markAllBenachrichtigungAsRead().subscribe({
            next: () => {
                this.benachrichtigungenService.setUngelesen(0);
                this.leseBenachrichtigungen();
                this.isSaving = false;
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Speichern!', err);
                this.isSaving = false;
            }
        });
    }

    getDateString(benachrichtigung: Benachrichtigung) {
        return new Date(benachrichtigung.erstellt).toISOString();
    }

    isLink(benachrichtigung: Benachrichtigung): boolean {
        return (benachrichtigung?.gruppe_id > 0) || (benachrichtigung?.tierliste_id > 0) || (benachrichtigung?.gt_id > 0) || (benachrichtigung?.buchungs_id > 0);
    }

}
