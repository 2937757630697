export class GemeinkostenPreise {
    kosten_netto: number;
    mwstsatz: number;

}

export class Gemeinkosten {
    kosten_id: number;
    kommentar: string;
    rechnung_nr: string;
    datum: Date;
    artikel_id: number;
    artikel_me: string;
    lieferant: string;
    lieferant_id: number;
    preise: GemeinkostenPreise[] = [];
    tierarzt_id: number;
    tierarzt: string;
    labor: number;
    allgemein: number;
    leistungsdatum_von: Date;
    leistungsdatum_bis: Date;
    erstellt: Date;
    erstellt_von: number;
    editiert: Date;
    editiert_von: number;
    pdf_id: number;
    primanota_id: number;
    freigegeben: number;
}
