import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatDialogRef } from "@angular/material/dialog";
import { MonthSelectorResult } from "./month-selector";

@Component({
    selector: "kiene-month-selector",
    templateUrl: "./month-selector.component.html",
    styleUrls: ["./month-selector.component.scss"],
})
export class MonthSelectorComponent implements OnInit {
    constructor(public dialogRef: MatDialogRef<MonthSelectorComponent>) {}

    dateCtrl = new FormControl();

    ngOnInit(): void {}

    onCancelClick() {
        this.dialogRef.close(new MonthSelectorResult());
    }

    setMonthAndYear(
        normalizedMonthAndYear: Date,
        datepicker: MatDatepicker<Date>
    ) {
        const result = new MonthSelectorResult();
        result.monat = normalizedMonthAndYear?.getMonth() + 1;
        result.jahr = normalizedMonthAndYear?.getFullYear();
        result.confirmed = true;

        datepicker.close();

        this.dialogRef.close(result);
    }
}
