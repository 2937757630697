import {Component, Inject, OnInit} from '@angular/core';
import {GruppeTier} from "../../classes/gruppe-tier";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'kiene-gruppe-tier-viehhaendler-zuordnen-dialog',
  templateUrl: './gruppe-tier-viehhaendler-zuordnen-dialog.component.html',
  styleUrls: ['./gruppe-tier-viehhaendler-zuordnen-dialog.component.scss']
})
export class GruppeTierViehhaendlerZuordnenDialogComponent implements OnInit {

    tier: GruppeTier;
    constructor(
        public dialogRef: MatDialogRef<GruppeTierViehhaendlerZuordnenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.tier = data.tier;
    }

    ngOnInit(): void {
    }
}
