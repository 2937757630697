import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BestellungExtern } from '../../bestellung-extern/bestellung-extern';
import { UntypedFormControl } from '@angular/forms';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../services/message.service';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-add-comment-dialog',
    templateUrl: './add-comment-dialog.component.html',
    styleUrls: ['./add-comment-dialog.component.scss'],
})
export class AddCommentDialogComponent implements OnInit {
    be: BestellungExtern;

    commentCtrl = new UntypedFormControl();

    constructor(
        public dialogRef: MatDialogRef<AddCommentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneTableService,
        private messageService: MessageService
    ) {
        this.be = data.bestellungExtern;
    }

    ngOnInit() {
        this.commentCtrl.setValue(this.be.kommentar);
    }

    cancel() {
        this.dialogRef.close();
    }

    saveComment(mail: boolean) {
        this.be.kommentar = this.commentCtrl.value;
        this.be.kommentar_per_mail = mail ? 1 : 0;
        this.api
            .updateEntity(this.apiBaseUrl + 'bestellung_extern/update.php', this.be)
            .subscribe(
                (response) => {
                    this.messageService.infoMessage(
                        'Der Kommentar wurde gespeichert'
                    );
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Fehler beim Speichern des Kommentars aufgetreten',
                        error
                    );
                }
            );
    }
}
