import {Component, Inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {HeimtierKalenderEintrag} from "../../kalender/heimtier-kalender-eintrag";
import {HeimtierBeleg} from '../../heimtier-beleg';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {HttpParams} from '@angular/common/http';

@Component({
    selector: 'kiene-besuchs-detail-informationen',
    templateUrl: './besuchs-detail-informationen.component.html',
    styleUrls: ['./besuchs-detail-informationen.component.scss']
})
export class BesuchsDetailInformationenComponent implements OnInit, OnChanges {
    @Input() heimtierBeleg: HeimtierBeleg;
    selectedAnimalIndex = 0; // Index des aktuellen Tieres

    kunde: Client;

    constructor(private api: KieneBackendApiService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    ngOnInit(): void {
        
    }

    ngOnChanges(changes: SimpleChanges) {
        this.ladeKunde();
    }


    public getTermin(kalenderEintrag: HeimtierKalenderEintrag): string {
        if (!kalenderEintrag?.von || !kalenderEintrag?.bis) {
            return '';
        }

        const vonDate = new Date(kalenderEintrag.von);
        const bisDate = new Date(kalenderEintrag.bis);

        // Datum im Format dd.MM.yyyy
        const datum = vonDate.getDate().toString().padStart(2, '0') + '.' +
            (vonDate.getMonth() + 1).toString().padStart(2, '0') + '.' +
            vonDate.getFullYear();

        // Zeit im Format HH:mm
        const vonZeit = vonDate.getHours().toString().padStart(2, '0') + ':' +
            vonDate.getMinutes().toString().padStart(2, '0');

        const bisZeit = bisDate.getHours().toString().padStart(2, '0') + ':' +
            bisDate.getMinutes().toString().padStart(2, '0');

        return `${datum} ${vonZeit} - ${bisZeit}`;
    }

    private ladeKunde() {
        if (this.heimtierBeleg) {
            const params = new HttpParams().set('kunde_id', this.heimtierBeleg.kunde_id);
            this.api.get(this.apiBaseUrl + 'heimtier_kunde/read_one.php', params).subscribe({
                next: response => {
                    this.kunde = response;
                }
            });
        }
    }
}
