<div mat-dialog-content>
    <kiene-gruppe-abgang [alsDialog]="data?.alsDialog" [gruppe]="data?.gruppe"
         [tierliste]="data?.tierliste" (geschlossen)="abgangGeschlossen($event)">
    </kiene-gruppe-abgang>
</div>

<div mat-dialog-actions *ngIf="data?.alsDialog">
    <span class="spacer"></span>
    <button (click)="close()" mat-button>Schließen</button>
</div>
