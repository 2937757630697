import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TableColumn } from '../../components/kiene-table/table-column';
import { TableDescriptor } from '../../components/kiene-table/table-descriptor';
import { TableOptionEvent } from '../../components/kiene-table/table-option';

@Component({
  selector: 'kiene-gruppe',
  templateUrl: './gruppe.component.html',
  styleUrls: ['./gruppe.component.scss']
})
export class GruppeComponent implements OnInit {

  tableDescriptorAllgemein: TableDescriptor = new TableDescriptor();
  tableDescriptorHistorie: TableDescriptor = new TableDescriptor();

  constructor(public dialog: MatDialog) {



  }

  ngOnInit(): void {
    this.initAllgemein();
    this.initHistorie();
  }

  initAllgemein() {
    this.tableDescriptorAllgemein.headline = 'Gruppen';
    this.tableDescriptorAllgemein.uniqueIdentifier = 'Gruppen_allgemein';

    const columns: TableColumn[] = [];

    columns.push(new TableColumn('betrieb', 'Betrieb'));

    this.tableDescriptorAllgemein.columns = columns;

  }

  initHistorie() {

  }

  catchOptionEvent(event: TableOptionEvent) {

  }


}
