import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpParams } from '@angular/common/http';
import { StoragePlace } from '../../../warehouse/storage-place/storage-place';
import { VetGroup } from '../../vet-group/vet-group';
import { Vet } from '../vet';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { environment } from '../../../../environments/environment';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'kiene-vet-details-dialog',
  templateUrl: './vet-details-dialog.component.html',
  styleUrls: ['./vet-details-dialog.component.scss']
})
export class VetDetailsDialogComponent implements OnInit {

  params = new HttpParams().set('lager_id', '3');

  vetParams = new HttpParams().append('tierarzt', '1');

  vetNumberCtrl = new UntypedFormControl();
  qsAliasCtrl = new UntypedFormControl();
  abgabebelegNrCtrl = new UntypedFormControl();

  lagerplatz: StoragePlace;

  tierarzt: Vet = new Vet();

  edit = false;

  constructor(public dialogRef: MatDialogRef<VetDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private api: KieneTableService) {
    if (data) {

      if (data.tierarzt) {
        this.edit = true;
        this.tierarzt = data.tierarzt;
        if (this.tierarzt.lagerplatz_id) {
          this.loadLagerplatz();
        }

        this.vetNumberCtrl.setValue(this.tierarzt.tierarztnummer);
        this.qsAliasCtrl.setValue(this.tierarzt.qs_alias);
        this.abgabebelegNrCtrl.setValue(this.tierarzt.abgabebeleg_nr);
      }
    }
  }

  ngOnInit() {

  }

  loadLagerplatz() {
    this.api.getOne(environment.BASE_URI + 'lagerplatz/read_one.php', 'lagerplatz_id', this.tierarzt.lagerplatz_id.toString()).subscribe(response => {
      this.lagerplatz = response;
    });
  }

  setTierarzt(t: Vet) {
    this.tierarzt = t;
  }

  setLagerplatz(sl: StoragePlace) {
    this.lagerplatz = sl;
    this.tierarzt.lagerplatz_id = sl.lagerplatz_id;
  }

  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    this.tierarzt.tierarztnummer = this.vetNumberCtrl.value;
    this.tierarzt.qs_alias = this.qsAliasCtrl.value;
    this.tierarzt.abgabebeleg_nr = this.abgabebelegNrCtrl.value;
    this.dialogRef.close(this.tierarzt);
  }

}
