<h1 mat-dialog-title>Tierarzt {{tierarzt && tierarzt.tierarzt_id ? tierarzt.vorname + ' ' + tierarzt.nachname : ''}}</h1>
<div mat-dialog-content>

  <kiene-autocomplete *ngIf="!edit" [panelWidth]="'50%'" [apiUrl]="'benutzer/'" [params]="vetParams" [placeholder]="'Tierarzt'" [displayFields]="['vorname', 'nachname']" (elementSelected)="setTierarzt($event)"></kiene-autocomplete>

  <kiene-autocomplete [focus]="false" [displayField]="'bezeichnung'" [panelWidth]="'50%'" [apiUrl]="'lagerplatz/'" [kieneObject]="lagerplatz" [params]="params" [placeholder]="'Lagerplatz'" (elementSelected)="setLagerplatz($event)"></kiene-autocomplete>

  <mat-form-field style="margin-right: 12px;">
    <mat-label>Tierarztnummer</mat-label>
    <input matInput type="number" [formControl]="vetNumberCtrl">
  </mat-form-field>
  <mat-form-field style="margin-right: 12px;">
    <mat-label>QS Alias</mat-label>
    <input matInput type="text" [formControl]="qsAliasCtrl">
  </mat-form-field>
  <mat-form-field style="margin-right: 12px;">
    <mat-label>N&auml;chste Abgabebelegnr</mat-label>
    <input matInput type="number" [formControl]="abgabebelegNrCtrl">
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Abbrechen</button>
  <button mat-flat-button color="accent" cdkFocusInitial (click)="save()">Speichern</button>
</div>