import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AusstallenFrageDialogComponent } from './ausstallen-frage-dialog/ausstallen-frage-dialog.component';
import { AusstallenFrageDialogInput, AusstallenFrageDialogResult } from './ausstallen-frage-dialog/ausstallen-frage-dialog';
import { Gruppe } from '../gruppen/classes/gruppe';
import { Router } from '@angular/router';
import { GruppeAbgangDialogComponent } from '../gruppen/gruppe-abgang-dialog/gruppe-abgang-dialog.component';
import { GruppeAbgangService } from '../gruppen/gruppe-abgang/gruppe-abgang.service';
import { MessageService } from '../../services/message-service/message.service';
import { GruppeAuswaehlenDialogComponent } from '../gruppen/gruppe-auswaehlen-dialog/gruppe-auswaehlen-dialog.component';
import { Tierliste } from '../gruppe-tier/classes/tierliste';
import { BetriebAuswaehlenDialogComponent } from '../dialogs/betrieb-auswaehlen-dialog/betrieb-auswaehlen-dialog.component';
import { TierlistenauswahlDialogComponent } from '../gruppen/gruppe-abgang/tierlistenauswahl-dialog/tierlistenauswahl-dialog.component';
import { GruppeTier } from '../gruppe-tier/classes/gruppe-tier';
import { TierlisteImportierenFrageDialogComponent } from './tierliste-importieren-frage-dialog/tierliste-importieren-frage-dialog.component';
import { StammdatenService } from '../stammdaten/stammdaten.service';
import { GruppeAbgangDialogInput } from '../gruppen/gruppe-abgang-dialog/gruppe-abgang-dialog';
import { API_BASE_URL_SERVICE } from '../../kiene-core.config';
import { KieneBackendApiService } from '../../services/backend-api/kiene-backend-api.service';
import { AppService } from '../../services/app/app.service';
import { AusstallenDialogComponent } from './ausstallen-dialog/ausstallen-dialog.component';
import { AusstallenDialogInput, AusstallenDialogResult } from './ausstallen-dialog/ausstallen-dialog';
import { GruppeAuswaehlenDialogInput, GruppeAuswaehlenDialogResult } from '../gruppen/gruppe-auswaehlen-dialog/gruppe-auswaehlen-dialog';
import { TierlistenauswahlDialogInput } from '../gruppen/gruppe-abgang/tierlistenauswahl-dialog/tierlistenauswahl-dialog';

@Injectable({
    providedIn: 'root',
})
export class AusstallenService {
    constructor(
        private dialog: MatDialog,
        private router: Router,
        private appService: AppService,
        private abgangService: GruppeAbgangService,
        private messageService: MessageService,
        private stammdatenService: StammdatenService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        private api: KieneBackendApiService
    ) {
        this.checkImport();
    }

    public ausstallen(
        aktuelleGruppe: Gruppe,
        gruppeAbgangAlsDialog: boolean,
        tiere: GruppeTier[]
    ) {
        const input = new AusstallenFrageDialogInput();
        input.gruppe = aktuelleGruppe;
        input.anzahl_tiere = tiere?.length;
        input.tiere = tiere;
        const dialogRef = this.dialog.open<AusstallenFrageDialogComponent, AusstallenFrageDialogInput, AusstallenFrageDialogResult>(AusstallenFrageDialogComponent, {
            data: input,
            disableClose: true,
            minWidth: this.appService.isMobileScreen ? '95vw' : undefined
        });
        dialogRef.afterClosed().subscribe({
            next: (result1: AusstallenFrageDialogResult) => {
                if (result1?.ok) {
                    if (result1.tierliste) {
                        this.openTierlisteAuswahl(
                            aktuelleGruppe,
                            gruppeAbgangAlsDialog,
                            tiere
                        );
                    } else {
                        if (!aktuelleGruppe) {
                            const input = new GruppeAuswaehlenDialogInput();
                            input.mehrereGruppen = true;
                            this.dialog.open<GruppeAuswaehlenDialogComponent, GruppeAuswaehlenDialogInput, GruppeAuswaehlenDialogResult>(
                                GruppeAuswaehlenDialogComponent,
                                {
                                    data: input,
                                    disableClose: true,
                                }
                            ).afterClosed().subscribe({
                                next: (result2: GruppeAuswaehlenDialogResult) => {
                                    if (result2?.confirmed) {
                                        this.neueTierlisteErstellen(
                                            result2.gruppe,
                                            result1.intern,
                                            result1.extern,
                                            gruppeAbgangAlsDialog,
                                            tiere
                                        );
                                    }
                                },
                            });
                        } else {
                            this.neueTierlisteErstellen(
                                aktuelleGruppe,
                                result1.intern,
                                result1.extern,
                                gruppeAbgangAlsDialog,
                                tiere
                            );
                        }
                    }
                }
            },
        });
    }

    private neueTierlisteErstellen(
        gruppe: Gruppe,
        intern: boolean,
        extern: boolean,
        gruppeAbgangAlsDialog: boolean,
        tiere: GruppeTier[]
    ) {
        if (intern) {
            this.dialog.open(
                BetriebAuswaehlenDialogComponent,
                {
                    maxWidth: this.appService.isMobileScreen ? '95vw' : '50vw',
                    maxHeight: '90vh',
                    width: this.appService.isMobileScreen ? '95vw' : '50vw',
                    position: { top: '10px' },
                    data: {
                        headline: 'Zielbetrieb auswählen',
                    },
                    disableClose: true,
                }
            ).afterClosed().subscribe({
                next: (betrieb) => {
                    if (betrieb) {
                        const input = new AusstallenDialogInput(intern, extern, { neu: { gruppe_abgang: gruppe, zielbetrieb: betrieb } });
                        this.dialog.open<AusstallenDialogComponent, AusstallenDialogInput, AusstallenDialogResult>(
                            AusstallenDialogComponent,
                            {
                                maxWidth: this.appService.isMobileScreen ? '95vw' : '50vw',
                                maxHeight: '90vh',
                                width: this.appService.isMobileScreen
                                    ? '95vw'
                                    : '50vw',
                                data: input,
                                disableClose: true,
                            }
                        ).afterClosed().subscribe({
                            next: (result: AusstallenDialogResult) => {
                                if (result?.tierliste) {
                                    this.gruppeAbgangOeffnen(
                                        gruppeAbgangAlsDialog,
                                        gruppe,
                                        result.tierliste,
                                        tiere
                                    );
                                    this.abgangService.setAbgangsliste(
                                        result.tierliste,
                                        true,
                                        true
                                    );
                                }
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                            },
                        });
                    }
                },
            });
        } else {
            const input = new AusstallenDialogInput(intern, extern, { neu: { gruppe_abgang: gruppe, zielbetrieb: null } });
            this.dialog.open<AusstallenDialogComponent, AusstallenDialogInput, AusstallenDialogResult>(AusstallenDialogComponent, {
                maxWidth: this.appService.isMobileScreen ? '95vw' : '50vw',
                maxHeight: '90vh',
                width: this.appService.isMobileScreen ? '95vw' : '50vw',
                data: input,
                disableClose: true,
            }).afterClosed().subscribe({
                next: (result: AusstallenDialogResult) => {
                    if (result?.tierliste) {
                        this.gruppeAbgangOeffnen(
                            gruppeAbgangAlsDialog,
                            gruppe,
                            result.tierliste,
                            tiere
                        );
                        this.abgangService.setAbgangsliste(result.tierliste, true, true);
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
        }
    }

    private tiereImportZuAbgangslisteHinzufuegen(
        gruppe: Gruppe,
        tierliste: Tierliste,
        tiere: GruppeTier[]
    ) {
        const obj = {
            tierliste_id: tierliste?.tierliste_id,
            gruppen_tiere: tiere,
        };
        const snackbarRef = this.messageService.openSnackBar(
            'Tiere werden zur Ausstallliste hinzugefügt. Dieses kann einen Moment dauern'
        );
        this.api
            .post(
                this.apiBaseUrl + 'tierliste/abgang_kundenportal.php',
                obj,
                undefined
            )
            .subscribe({
                next: (tierlisteAntwort: Tierliste) => {
                    snackbarRef.dismiss();
                    this.messageService.infoMessage(
                        tiere?.length +
                        ' Tiere wurden zur aktuellen Ausstallliste hinzugefügt.'
                    );
                    this.gruppeAbgangDialogOeffnen(
                        gruppe,
                        tierlisteAntwort,
                        undefined
                    );
                },
                error: (err) => {
                    snackbarRef.dismiss();
                    this.messageService.errorMessage(
                        'Fehler beim Hinzufügen der Tiere!',
                        err
                    );
                    this.gruppeAbgangDialogOeffnen(
                        gruppe,
                        tierliste,
                        undefined
                    );
                },
            });
    }

    private gruppeAbgangDialogOeffnen(
        gruppe: Gruppe,
        liste: Tierliste,
        tiere: GruppeTier[]
    ) {
        if (liste && tiere?.length > 0) {
            this.tiereImportZuAbgangslisteHinzufuegen(gruppe, liste, tiere);
        } else {
            const input = new GruppeAbgangDialogInput(liste, true, gruppe);
            const dialogRefAbgang = this.dialog.open<GruppeAbgangDialogComponent, GruppeAbgangDialogInput, Tierliste>(
                GruppeAbgangDialogComponent,
                {
                    data: input,
                    disableClose: true,
                    maxHeight: '95vh'
                }
            );
            dialogRefAbgang.afterClosed().subscribe({
                next: (tierliste) => {
                    if (tierliste) {
                        this.abgangService.tierListeAbgeschlossen(
                            tierliste,
                            'gruppeAbgangDialogOeffnen'
                        );
                    }
                },
            });
        }
    }

    private gruppeAbgangOeffnen(
        gruppeAbgangAlsDialog: boolean,
        gruppe: Gruppe,
        liste: Tierliste,
        tiere: GruppeTier[]
    ) {
        if (gruppeAbgangAlsDialog) {
            this.gruppeAbgangDialogOeffnen(gruppe, liste, tiere);
        } else {
            if (gruppe) {
                this.router
                    .navigate(['/gruppen/abgang', gruppe.gruppe_id], {
                        queryParams: {
                            tierliste_id: liste.tierliste_id,
                        },
                    })
                    .then();
            } else {
                this.router
                    .navigate(['/gruppen/abgang'], {
                        queryParams: {
                            tierliste_id: liste.tierliste_id,
                        },
                    })
                    .then();
            }
        }
    }

    private openTierlisteAuswahl(
        aktuelleGruppe: Gruppe,
        gruppeAbgangAlsDialog: boolean,
        tiere: GruppeTier[]
    ) {
        const input = new TierlistenauswahlDialogInput();
        input.gruppe = aktuelleGruppe;
        input.onlyActive = true;
        this.dialog.open<TierlistenauswahlDialogComponent, TierlistenauswahlDialogInput, Tierliste>(TierlistenauswahlDialogComponent, {
            data: input,
            disableClose: true,
        }).afterClosed().subscribe({
            next: (result: Tierliste) => {
                if (result) {
                    this.gruppeAbgangOeffnen(
                        gruppeAbgangAlsDialog,
                        aktuelleGruppe,
                        result,
                        tiere
                    );
                }
            },
        });
    }

    private checkImport() {
        this.abgangService.watchTierListeAbgeschlossen().subscribe({
            next: (tierliste) => {
                if (tierliste && tierliste.kunde_id_einstallen) {
                    this.stammdatenService.ladeVvvos().subscribe({
                        next: (response) => {
                            const meineVVVOs = response.records;
                            const meinBetrieb = meineVVVOs.find(
                                (vvvo) =>
                                    vvvo.kunde_id ===
                                    tierliste.kunde_id_einstallen
                            );
                            if (meinBetrieb) {
                                // tierliste.kunde_id_einstallen
                                this.dialog.open(
                                    TierlisteImportierenFrageDialogComponent,
                                    {
                                        data: tierliste,
                                        disableClose: true,
                                    }
                                );
                            }
                        },
                        error: (err) => {
                            this.messageService.errorMessage('Fehler!', err);
                        },
                    });
                }
            },
        });
    }
}
