import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { GruppeStandort } from './gruppe-standort';
import { HttpParams } from '@angular/common/http';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { MessageService } from '../../../services/message-service/message.service';
import { GruppenService } from '../gruppen.service';
import { Gruppe } from '../classes/gruppe';
import { MatDialog } from '@angular/material/dialog';
import {
    GruppeStandortWechselnDialogComponent
} from '../gruppe-standort-wechseln-dialog/gruppe-standort-wechseln-dialog.component';
import {
    GruppeStandortWechselnDialogResult
} from '../gruppe-standort-wechseln-dialog/gruppe-standort-wechseln-dialog-result';
import { TierFilterSettings } from '../../gruppe-tier/filter/gruppe-tier-filter-dialog/classes/tier-filter-settings';
import {
    GruppeTierGewichtPreisDialogInput
} from '../../gruppe-tier/gruppe-tier-gewicht-preis-dialog/gruppe-tier-gewicht-preis-dialog';
import {
    GruppeTierGewichtPreisDialogComponent
} from '../../gruppe-tier/gruppe-tier-gewicht-preis-dialog/gruppe-tier-gewicht-preis-dialog.component';
import {
    GruppeTierFilterDialogComponent
} from '../../gruppe-tier/filter/gruppe-tier-filter-dialog/gruppe-tier-filter-dialog.component';
import { EinzeltiergewichtDialogComponent } from './einzeltiergewicht-dialog/einzeltiergewicht-dialog.component';
import { ConfirmDialogInput } from '../../dialogs/confirm-dialog/confirm-dialog';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { AppService } from '../../../services/app/app.service';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { StepperOrientation } from '@angular/cdk/stepper';

@Component({
    selector: 'kiene-gruppe-standort-verwalten',
    templateUrl: './gruppe-standort-verwalten.component.html',
    styleUrls: ['./gruppe-standort-verwalten.component.scss']
})
export class GruppeStandortVerwaltenComponent implements OnInit, OnDestroy, OnChanges {

    standorte: GruppeStandort[] = [];
    standorteSubscription: Subscription;
    reloadStandorteSubscription: Subscription;

    loadingOrSaving = false;

    stepperOrientation: StepperOrientation = 'vertical';

    @Input('gruppe') gruppe: Gruppe;
    @Input('filterSettings') filterSettings: TierFilterSettings;

    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private gruppenService: GruppenService,
        private dialog: MatDialog,
        public appService: AppService,
        private localStorageService: LocalStorageService
    ) {
    }

    loadStandorte() {
        this.loadingOrSaving = true;
        this.standorteSubscription?.unsubscribe();
        let params = new HttpParams().set('gruppe_id', this.gruppe?.gruppe_id?.toString());
        params = params.set('orderby', 'datum_von');
        if (this.localStorageService.hasPermission(9161)) {
            params = params.set('gewichte', '1');
        }
        if (this.localStorageService.hasPermission(9162)) {
            params = params.set('preise', '1');
        }
        this.standorteSubscription = this.api.getAll(this.apiBaseUrl + 'gruppe_standort/read.php', params).subscribe({
            next: (response) => {
                this.standorte = response.records;
                this.loadingOrSaving = false;
            },
            error: err => {
                this.messageService.errorMessage('Standorte konnten nicht geladen werden!', err);
                this.loadingOrSaving = false;
            }
        });
    }


    editStandort(standort: GruppeStandort, index: number) {
        const dialogRefStandortBearbeiten = this.dialog.open(
            GruppeStandortWechselnDialogComponent,
            {
                data: {
                    gruppe: this.gruppe,
                    gruppeStandort: standort,
                    showGewicht: index !== 0
                },
            }
        );
        dialogRefStandortBearbeiten.afterClosed().subscribe({
            next: () => {
                this.ladeAlles();
            }
        });
    }

    deleteStandort(gruppeStandort: GruppeStandort) {
        const confirmInputData = new ConfirmDialogInput();
        confirmInputData.headline = 'Standortwechsel wirklich löschen?';
        confirmInputData.text = 'Wollen Sie den Standortwechsel wirklich löschen?';
        confirmInputData.okButton = 'Löschen';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: confirmInputData
        });
        dialogRef.afterClosed().subscribe({
            next: result => {
                if (result?.ok && result?.confirmed) {
                    this.loadingOrSaving = true;
                    this.api.post(this.apiBaseUrl + 'gruppe_standort/delete.php', gruppeStandort).subscribe({
                        next: () => {
                            this.messageService.infoMessage('Der Standortwechsel wurde erfolgreich gelöscht!');
                            this.ladeAlles(); // loadingOrSaving
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler!', err);
                            this.loadingOrSaving = false;
                        }
                    });
                }
            }
        });
    }

    standortwechsel() {
        const dialogRefStandortWechsel = this.dialog.open(
            GruppeStandortWechselnDialogComponent,
            {
                data: {
                    gruppe: this.gruppe,
                    showGewicht: true
                },
            }
        );
        dialogRefStandortWechsel.afterClosed().subscribe({
            next: (result: GruppeStandortWechselnDialogResult) => {
                if (result && result.confirm) {
                    this.ladeAlles();
                }
            },
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.gruppe) {
            this.loadStandorte();
        }
    }

    ngOnDestroy(): void {
        this.standorteSubscription?.unsubscribe();
        this.reloadStandorteSubscription?.unsubscribe();
    }


    ngOnInit(): void {
        if (!this.appService.isMobileScreen) {
            this.stepperOrientation = 'horizontal';
        }
        this.reloadStandorteSubscription = this.gruppenService.watchReloadStandorte().subscribe({
            next: () => {
                this.loadStandorte();
            }
        });
    }

    public ladeAlles() {
        this.gruppenService.reloadGruppe();
        this.gruppenService.reloadVerlauf();
        this.gruppenService.reloadGewichteUndPreise();
        this.loadStandorte();
    }


    gewichtePreiseEingebenEinstallen(gruppeStandort: GruppeStandort, tfs?: TierFilterSettings) {
        if (!tfs) {
            tfs = this.filterSettings;
        }

        if (tfs?.viehhaendler?.length === 1 && tfs?.einstalldatum?.length >= 1) {
            const input = new GruppeTierGewichtPreisDialogInput();
            input.einstallen = true;
            input.gruppeStandort = gruppeStandort;
            input.viehhaendler = tfs.viehhaendler[0];
            for (const ed of tfs.einstalldatum) {
                input.einstalldatums.push(ed.einstalldatum);
            }
            const dialogRef = this.dialog.open(GruppeTierGewichtPreisDialogComponent, {
                data: input
            });
            dialogRef.afterClosed().subscribe({
                next: (result: boolean) => {
                    if (result === true) {
                        this.filterSettings = null;
                        this.ladeAlles();
                    }
                }
            });
        } else {
            const tfsNeu = new TierFilterSettings();
            tfsNeu.showAbgangslisten = false;
            tfsNeu.showAbteile = false;
            tfsNeu.showFilter = false;
            tfsNeu.showGeschlechter = false;
            tfsNeu.showRassen = false;
            tfsNeu.showReklamationen = false;

            const dialogRef = this.dialog.open(GruppeTierFilterDialogComponent, {
                width: '90vw',
                data: {
                    gruppe: this.gruppe,
                    filterSettings: tfsNeu,
                    info: 'Bitte einen Viehhändler und ein Einstalldatum wählen!'
                }
            });
            dialogRef.afterClosed().subscribe({
                next: (res: TierFilterSettings) => {
                    if (res) {
                        this.gewichtePreiseEingebenEinstallen(gruppeStandort, res);
                    }
                }
            });
        }
    }

    gewichtePreiseEingebenAusstallen(gruppeStandort: GruppeStandort, tfs?: TierFilterSettings) {
        if (!tfs) {
            tfs = this.filterSettings;
        }

        if (tfs?.abgangslisten?.length === 1) {
            const input = new GruppeTierGewichtPreisDialogInput();
            input.ausstallen = true;
            input.gruppeStandort = gruppeStandort;
            input.tierliste = tfs.abgangslisten[0];
            const dialogRef = this.dialog.open(GruppeTierGewichtPreisDialogComponent, {
                data: input
            });
            dialogRef.afterClosed().subscribe({
                next: (result: boolean) => {
                    if (result === true) {
                        this.filterSettings = undefined;
                        this.ladeAlles();
                    }
                }
            });
        } else {
            const tfsNeu = new TierFilterSettings();
            tfsNeu.showAbteile = false;
            tfsNeu.showFilter = false;
            tfsNeu.showGeschlechter = false;
            tfsNeu.showRassen = false;
            tfsNeu.showReklamationen = false;
            tfsNeu.showEinstalldatum = false;
            tfsNeu.showViehhaendler = false;

            const dialogRef = this.dialog.open(GruppeTierFilterDialogComponent, {
                width: '90vw',
                data: {
                    gruppe: this.gruppe,
                    filterSettings: tfsNeu,
                    info: 'Bitte eine Abgangsliste auswählen!'
                }
            });
            dialogRef.afterClosed().subscribe({
                next: (res: TierFilterSettings) => {
                    if (res) {
                        this.gewichtePreiseEingebenAusstallen(gruppeStandort, res);
                    }
                }
            });
        }
    }

    einzeltiergewichtEintragen(s: GruppeStandort) {
        const dialogRef = this.dialog.open(EinzeltiergewichtDialogComponent, {
            data: {
                gruppe: this.gruppe,
                gruppeStandort: s
            },
            minWidth: this.appService.isMobileScreen ? '100vw' : '400px',
            width: '100%',
            minHeight: this.appService.isMobileScreen ? '90vh' : ''
        });
        dialogRef.afterClosed().subscribe({
            next: result => {
                this.ladeAlles();
            }
        });
    }
}
