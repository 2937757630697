import {Inject, Injectable} from '@angular/core';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {KalenderEintrag} from './classes/kalender-eintrag';
import {HttpParams} from '@angular/common/http';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {Subject} from 'rxjs';
import * as moment from 'moment';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {TierarztGruppe} from '../../report/tierarzt/tierarzt-gruppe-report/tierarzt-gruppe';
import {KalenderRechte} from './classes/KalenderRechte';

@Injectable({
    providedIn: 'root',
})
export class UrlaubskalenderService {

    filterPermissions = [1261, 51, 11];
    kalenderRechte = KalenderRechte;

    reloadSubject = new Subject<void>();

    constructor(
        private api: KieneTableService,
        private localStorageService: LocalStorageService,
        @Inject(API_BASE_URL_SERVICE) private baseUrl: string
    ) {
    }

    createOrUpdate(eintrag: KalenderEintrag) {
        return this.api.updateEntity(
            this.baseUrl + 'kalendereintrag/update.php',
            eintrag
        );
    }

    getOffeneUrlaubsantraege() {
        const params = new HttpParams()
            .set('genehmigt', '0')
            .set('eintragtyp_id', '1');
        return this.api.get(
            this.baseUrl + 'kalendereintrag/read.php',
            params
        );
    }

    getKalenderEintraege(queryParams: HttpParams) {
        return this.api.get(
            this.baseUrl + 'kalendereintrag/read.php',
            queryParams
        );
    }

    getKalenderEintrag(id: number) {
        return this.api.getOne(
            this.baseUrl + 'kalendereintrag/read_one.php',
            'kalendereintrag_id',
            id.toString()
        );
    }

    delete(urlaubsantrag: KalenderEintrag) {
        return this.api.deleteEntity(
            this.baseUrl + 'kalendereintrag/delete.php',
            urlaubsantrag
        );
    }

    hasUrlaubGenehmigenPermission() {
        return this.localStorageService.hasPermission(this.kalenderRechte.urlaubGenehmigen);
    }

    isOwnKalenderEintrag(eintrag: KalenderEintrag) {
        return (
            eintrag.benutzer_id ===
            this.localStorageService.getCurrentUser().benutzer_id
        );
    }

    watchReload() {
        return this.reloadSubject.asObservable();
    }

    triggerReload() {
        this.reloadSubject.next();
    }

    hasKrankMeldenPermission() {
        return this.localStorageService.hasPermission(this.kalenderRechte.krankheitMelden);
    }

    sameDates(d1: Date, d2: Date) {
        return moment(d1).isSame(d2);
    }

    loadTierarztGruppen() {
        const params = new HttpParams().set('grp-s', '1'); // inkl. grp. s
        return this.api
            .get(this.baseUrl + 'tierarztgruppe/read.php', params);
    }

    loadWochenendeFeiertage(tierarztGruppe: TierarztGruppe, monat: Date) {
        const params = new HttpParams()
            .set('tierarztgruppe_id', tierarztGruppe.tierarztgruppe_id.toString())
            .set('datum', moment(monat).format('YYYY-MM-DD'));
        return this.api
            .get(this.baseUrl + 'kalendereintrag/read_sonn_und_feiertage.php', params);
    }

    loadTieraerzte(tierarztGruppe: TierarztGruppe) {
        const params = new HttpParams().set('tierarztgruppe_id', tierarztGruppe.tierarztgruppe_id.toString());
        return this.api
            .get(this.baseUrl + 'tierarztgruppe_tierarzt/read.php', params);
    }

    isWeekend(date = new Date()) {
        const weekdays = 6;
        return date.getDay() % weekdays === 0;
    }

    loadFeiertage(year = new Date()) {
        const params = new HttpParams().set('datum', moment(year).format('YYYY-MM-DD'));
        return this.api
            .get(this.baseUrl + 'kalendereintrag/read_feiertage.php', params);
    }

    ladeMeineUrlaubstageDiesesJahr() {
        return this.api
            .get(this.baseUrl + 'kalendereintrag/read_meine_urlaubstage_dieses_jahr.php', undefined);
    }

    gibAnzahlUrlaubstage(ke: KalenderEintrag) {
        const start = new Date(ke.startdatum);
        const ende = new Date(ke.enddatum);

        const tmpDate = new Date(start);
        let anzahlTage = 0;
        while (tmpDate <= ende) {
            const dayOfWeek = tmpDate.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                anzahlTage++;
            }
            tmpDate.setDate(tmpDate.getDate() + 1);
        }

        if (ke.startdatum_halbtags === 1) {
            anzahlTage -= 0.5;
        }
        if (ke.enddatum_halbtags === 1) {
            anzahlTage -= 0.5;
        }

        return anzahlTage;
    }
}
