import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KieneSelectorComponent } from './kiene-selector/kiene-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    KieneSelectorComponent
  ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule
    ],
    exports: [
        KieneSelectorComponent
    ]
})
export class KieneSelectorModule { }
