<h1 mat-dialog-title>
    Neuen Text f&uuml; K&uuml;rzel {{ data.kuerzel }} anlegen
</h1>
<div mat-dialog-content>
    <mat-form-field>
        <input
            matInput
            [(ngModel)]="kuerzelText"
            (keyup.enter)="closeAndSafe()"
        />
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onCancelClick()">
        Abbrechen
    </button>
    <button mat-flat-button color="accent" (click)="dialogRef.close(kuerzelText)">
        Anlegen
    </button>
</div>
