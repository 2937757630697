<div class="table-header">
    <h3>{{ initMiniTable.title }}</h3>
    <button (click)="addClick()" *ngIf="!initMiniTable.hideAddButton" color="accent" mat-icon-button
            tabindex="-1">
        <mat-icon>add</mat-icon>
    </button>
</div>
<div *ngIf="initMiniTable && initMiniTable.disableKeyControl" mat-dialog-content>
    <table class="phito-table" id="chargenTable">
        <thead>
        <tr>
            <th>Position</th>
            <th *ngFor="let column of initMiniTable.columnNames">{{ column }}</th>
            <th *ngIf="initMiniTable.addActionButtons">Aktionen</th>
        </tr>
        </thead>
        <tbody>
        <tr
            *ngFor="let element of initMiniTable.dataList; index as i"
            [ngClass]="rowHandler[i] ? 'highlight' : 'nothing'" tabindex="{{i}}">
            <td>{{ i + 1 }}</td>
            <td (click)="confirm(element)" *ngFor="let attribute of initMiniTable.attributes">
                <span *ngIf="initMiniTable.modifiedAttributes">
                    <span *ngFor="let modifiedAttribute of initMiniTable.modifiedAttributes">
                        <span *ngIf="attribute === modifiedAttribute.attribute">{{ modifiedAttribute.values[i] }}</span>
                        <span *ngIf="attribute !== modifiedAttribute.attribute">{{ element[attribute] }}</span>
                    </span>
                </span>
                <span *ngIf="!initMiniTable.modifiedAttributes">{{ element[attribute] }}</span>
            </td>
            <td (click)="$event.stopPropagation()" *ngIf="initMiniTable.addActionButtons">
                <button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu>
                    <button (click)="confirm(element)" mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        <span>Editieren</span>
                    </button>
                    <button (click)="deleteClick(element[initMiniTable.attributeID])"
                            *kienePermission="[initMiniTable.kienePermissionDeleteItem]"
                            mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>Löschen</span>
                    </button>
                </mat-menu>
            </td>
        </tr>
        </tbody>
    </table>
</div>
<!--<div *ngIf=!initMiniTable.hideCloseButton align="end" id="chargenButtons" mat-dialog-actions>-->
<!--    <button (click)="abort()" [ngClass]="rowHandler[this.initMiniTable.listeLaenge] ? 'highlight' : 'nothing'"-->
<!--            mat-button-->
<!--            type="button"-->
<!--    >Abbrechen-->
<!--    </button>-->
<!--</div>-->
