<h1 mat-dialog-title>Ausstallliste abschlie&szlig;en</h1>
<div mat-dialog-content>
    <div class="info-box">
        Beim Abschlie&szlig;en der Ausstallliste wird der Empf&auml;nger
        {{ abgangsliste.kunde_einstallen }} informiert, dass die Tiere zum
        eingetragenem Datum transportiert werden sollen.
    </div>
    <mat-form-field style="width: 100%;">
        <mat-label>Abgangsdatum</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="datumCtrl" [readonly]="true"/>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>KFZ-Kennzeichen</mat-label>
        <input matInput [formControl]="kfzCtrl" type="text"/>
        <mat-hint>KFZ Kennzeichen des Transportfahrzeugs</mat-hint>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <div *ngIf="
            gleicheUnternehmensgruppe === false &&
            abgangsliste.kunde_id_einstallen
        " style="width: 100%">
        <mat-checkbox [(ngModel)]="abgangsliste.import_einstallen"><span style="white-space: normal">
                Empf&auml;nger darf die aktuelle Liste importieren</span></mat-checkbox>
    </div>
    <div *ngIf="
            gleicheUnternehmensgruppe === false &&
            abgangsliste.kunde_id_einstallen
        " style="width: 100%">
        <mat-checkbox [disabled]="!abgangsliste.import_einstallen" [(ngModel)]="abgangsliste.reklamationen_weitergeben">
            <span style="white-space: normal">Tierreklamationen an Empf&auml;nger weitergegeben</span>
        </mat-checkbox>
    </div>
    <p>H&auml;ndlerinformationen der Tiere an Empf&auml;nger weitergeben?</p>
    <mat-radio-group aria-label="H&auml;ndlerinformationen der Tiere an Empf&auml;nger weitergeben?"
                     [formControl]="haendlerInfoCtrl">
        <mat-radio-button [value]="0">nein</mat-radio-button>
        <mat-radio-button [value]="1">ja</mat-radio-button>
    </mat-radio-group>
    <div *ngIf="!istDerselbeKunde()" style="margin-top: 12px;">
        <p style="font-size: 0.9em">
            Sollen die Tiere mit dem Datum
            {{ datumCtrl.value | date: "dd.MM.yyyy" }} in HIT als Abgang
            gemeldet werden? Die Abgänge werden erst nach diesem Datum gemeldet.
        </p>
        <mat-radio-group aria-label="Abgang an HIT melden ja oder nein" [formControl]="hitMeldenCtrl">
            <mat-radio-button [value]="0">nein</mat-radio-button>
            <mat-radio-button [value]="1">ja</mat-radio-button>
        </mat-radio-group>
    </div>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()" [disabled]="isSaving">
        Abschlie&szlig;en
    </button>
</div>
