import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { TableDescriptor, ToolbarButton } from '../../../components/kiene-table/table-descriptor';
import { MessageService } from '../../../services/message.service';
import { environment } from '../../../../environments/environment';
import { TableColumn } from '../../../components/kiene-table/table-column';
import { TableOption, TableOptionEvent } from '../../../components/kiene-table/table-option';
import { ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Preisgruppe, PreisgruppeArtikel } from '../preisgruppen';
import { ConfirmDeleteDialogComponent } from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { AddPreisgruppeArtikelComponent } from './add-preisgruppe-artikel/add-preisgruppe-artikel.component';

@Component({
  selector: 'kiene-preisgruppe-artikel',
  templateUrl: './preisgruppe-artikel.component.html',
  styleUrls: ['./preisgruppe-artikel.component.scss']
})
export class PreisgruppeArtikelComponent implements OnInit, OnDestroy {

  tableDescriptor: TableDescriptor = new TableDescriptor();

  preisgruppe_id: string = null;

  constructor(
    public dialog: MatDialog,
    private tableService: KieneTableService,
    private messageService: MessageService,
    private route: ActivatedRoute
  ) {

  }

  ngOnDestroy(): void {
    this.tableService = null;
  }

  ngOnInit() {
    this.preisgruppe_id = this.route.snapshot.paramMap.get('id');
    this.loadPreisgruppe(this.preisgruppe_id);
    this.tableDescriptor.params = new HttpParams().set('preisgruppe_id', this.preisgruppe_id);

    this.tableDescriptor.headline = 'Preisgruppen Artikel';
    this.tableDescriptor.uniqueIdentifier = 'Preisgruppen Artikel';
    this.tableDescriptor.apiUrl = environment.BASE_URI + 'preisgruppe_artikel/';
    this.tableDescriptor.searchPlaceholder = 'Preisgruppen Artikel durchsuchen';
    this.tableDescriptor.createAllowed = false;
    this.tableDescriptor.columns.push(new TableColumn('preisgruppe', 'Preisgruppe'));
    this.tableDescriptor.columns.push(new TableColumn('artikel', 'Artikel'));
    this.tableDescriptor.columns.push(new TableColumn('vk', 'VK'));
    this.tableDescriptor.columns.push(new TableColumn('vkpreis', 'VK-Preis (€)'));
    this.tableDescriptor.columns.push(new TableColumn('einzelpreis', 'Verkaufspreis (€)'));
    this.tableDescriptor.columns.push(new TableColumn('einkaufspreis', 'Rab. Einkaufspreis (€)'));
    this.tableDescriptor.columns.push(new TableColumn('handelsspanne', 'Handelsspanne (%)'));
    this.tableDescriptor.columns.push(new TableColumn('rohertrag', 'Rohertrag (€)'));
    this.tableDescriptor.columns.push(new TableColumn('kalkulationszuschlag', 'Kalkulationszuschlag (%)'));
    this.tableDescriptor.columns.push(new TableColumn('editiert', 'Editiert', 'datetime'));

    this.tableDescriptor.showDetails = false;

    this.tableDescriptor.permissionCreate = 2982;
    this.tableDescriptor.permissionDelete = 2984;
    this.tableDescriptor.permissionUpdate = 2982;
    this.tableDescriptor.permissionView = 2981;

    this.tableDescriptor.initialSortColumn = 'artikel';
    this.tableDescriptor.initialSortDirection = 'asc';

    this.tableDescriptor.toolbarButtons.push(new ToolbarButton('add', 'add', 2982));

    this.tableDescriptor.options.push(new TableOption('edit', 'Editieren', 'edit', 2982));
    this.tableDescriptor.options.push(new TableOption('delete', 'Löschen', 'delete', 2984));
  }

  catchOptionEvent(event: TableOptionEvent) {
    if (event.name === 'add') {
      this.openAddPreisgruppeArtikelDialog(null);
    } else if (event.name === 'edit') {
      this.openAddPreisgruppeArtikelDialog(event.value);
    } else if (event.name === 'delete') {
      this.openDeleteDialog(event.value);
    }
  }

  openAddPreisgruppeArtikelDialog(preisgruppeArtikel: PreisgruppeArtikel) {
    if (this.preisgruppe_id === null || this.preisgruppe_id === undefined) {
      this.messageService.errorMessage('Ungültige Preisgruppe', null);
    } else {
      let dialogRef = this.dialog.open(AddPreisgruppeArtikelComponent, {
        width: '50%',
        minWidth: '300px',
        data: {
          preisgruppeArtikel: preisgruppeArtikel,
          preisgruppe_id: this.preisgruppe_id
        }
      });

      dialogRef.afterClosed().subscribe(response => {
        this.tableService.reload();
      });
    }
  }

  openDeleteDialog(preisgruppeArtikel: PreisgruppeArtikel) {
    let dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '500px',
      data: {
        title: 'Preisgruppenartikel wirklich löschen?',
        text: 'Preisgruppenartikel wirklich löschen?'
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.tableService.deleteEntity(environment.BASE_URI + 'preisgruppe_artikel/delete.php', preisgruppeArtikel).subscribe(result => {
          this.messageService.infoMessage('Der Preisgruppeartikel wurde erfolgreich gelöscht!');
          this.tableService.reload();
        }, error => {
          this.messageService.errorMessage('Der Preisgruppenartikel konnte nicht gelöscht werden! ', error);
        });
      }
    });
  }

  loadPreisgruppe(preisgruppe_id: string) {
    if (preisgruppe_id !== null && preisgruppe_id !== undefined) {
      this.tableService.getOne(environment.BASE_URI + 'preisgruppe/read_one.php', 'preisgruppe_id', preisgruppe_id).subscribe(
        response => {
          let preisgruppe: Preisgruppe = response;
          this.tableDescriptor.headline = 'Preisgruppen Artikel (' + preisgruppe.bezeichnung + ')';
        }
      );
    }
  }

}
