import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';


@Injectable()
export class BehandlungsanfrageDetailService {

    constructor(private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl) {
    }

    getPdf(behandlungsanfrageId: string): Observable<Blob> {
        return this.http.get(this.apiBaseUrl + 'behandlungsanfrage/pdf.php', {
            responseType: 'blob'
        });
    }

}
