<script src="entsorger-kunden-nr-verwalten.component.ts"></script>
<div [style.padding]="dialog ? '0' : '0 24px 64px 24px'">

    <kiene-page-header *ngIf="!dialog" [ueberschrift]="'Lieferant Kundennr. '"></kiene-page-header>

    <div [style.padding-top]="dialog ? '12px' : '128px'" style=" width: 100%; text-align: center" *ngIf="isLoading">
        Lade Lieferant...
    </div>

    <div *ngIf="!isLoading && futtermittellieferant">

        <h3>{{ futtermittellieferant.bezeichnung }}</h3>

        <

        <div [style]="dialog ? 'position: sticky; bottom: 0; background-color: white; padding: 12px 0;' : ''"
             class="one-row">
            <button mat-flat-button color="accent" (click)="speichern()" style="width: 66%; text-align: center"
                    [disabled]="isLoading">Speichern
            </button>
            <span class="spacer"></span>
            <ng-content select="[additional-actions]"></ng-content>
        </div>

    </div>

</div>
