<div style="height: 100%">
    <div class="container">
        <div class="one-row kiene-color-background">
            <mat-form-field *ngIf="input?.sende_mail" style="width: 50%; color: white;"
                class="mat-form-field--white no-padding-margin-mat-form-field">
                <mat-label>Betreff</mat-label>
                <input style="font-size: 1.5em;" matInput type="text" required [(ngModel)]="input.title">
            </mat-form-field>

            <h2 *ngIf="!input?.sende_mail" style="color: white; padding-left: 12px">{{input?.title}}</h2>

            <span class="kiene-spacer"></span>
            <div style="margin: 0 12px">
                <button mat-icon-button (click)="pdfCloud.click()" [disabled]="!input?.pdfBase64">
                    <mat-icon [style.color]="input?.pdfBase64 ? 'white': '#777'">cloud_download</mat-icon>
                </button>
                <button mat-icon-button (click)="dialogRef.close()">
                    <mat-icon style="color: white">close</mat-icon>
                </button>
                <a #pdfCloud [href]="input?.pdfBase64" download="{{input?.title}}.pdf"
                    style="display: none; color: white">
                </a>
            </div>
        </div>
        <div *ngIf="input?.sende_mail" class="one-row" style="padding-left: 12px;">
            <div style="width: 70%">
                <strong>Senden an:</strong>
                <ng-container *ngIf="istNichtKunde()">
                    <mat-checkbox *kienePermission="[4501]" style="margin-left: 10px" [(ngModel)]="sendToCustomer"
                        [disabled]="!sendToCustomerAvailable">
                        Kunde{{customerEmails ? (' (' + customerEmails + ')') : ''
                        }}</mat-checkbox>
                    <mat-checkbox style="margin-left: 10px" [(ngModel)]="sendToVetGroup"
                        [disabled]="!sendToVetGroupAvailable">
                        Tierarztgruppe
                    </mat-checkbox>
                    <mat-checkbox style="margin-left: 10px" [(ngModel)]="sendToVetAmt"
                        [disabled]="!sendToVetAmtAvailable">
                        Veterinäramt{{vetAmtEmail ? (' (' + vetAmtEmail + ')') :
                        ''}}</mat-checkbox>
                    <mat-checkbox style="margin-left: 10px" [(ngModel)]="sendToUeberwachungsbehoerde"
                        [disabled]="!sendToUeberwachungsbehoerdeAvailable">Überwachungsbehörde{{ueberwachungsbehoerdeEmail
                        ? (' ('
                        + ueberwachungsbehoerdeEmail + ')') : ''}}</mat-checkbox>
                </ng-container>
                <mat-checkbox *ngFor="let e of zusaetzlicheEmpfaenger" style="margin-left: 10px"
                    [(ngModel)]="e.selected">
                    <span>{{e.name}}</span>
                    <span style="font-size: 0.8em; color: #777">&nbsp;({{e.email}})</span>
                </mat-checkbox>
            </div>
            <span class="kiene-spacer"></span>
            <kiene-autocomplete [genericApiUrl]="apiBaseUrl + 'benutzer_kontakte/read.php'"
                [placeholder]="'E-Mail eingeben'" [inputmode]="'email'" [emitButton]="'add'" [showClear]="false"
                [displayField]="'email'" [focus]="false" [clearAfterSelect]="true" [emitFreeText]="true"
                (elementSelected)="addToList($event)"></kiene-autocomplete>
            <!--            <div style="margin-top: 12px; width:20%; margin-right: 12px">-->
            <!--                <div class="one-row" style="padding-bottom: 12px">-->
            <!--                    <mat-form-field style="width: 80%; margin-bottom: 6px" class="no-padding-margin-mat-form-field">-->
            <!--                        <mat-label>E-Mail hinzuf&uuml;gen</mat-label>-->
            <!--                        <input matInput type="text" [formControl]="neuerEmpfaengerCtrl">-->
            <!--                        <mat-error style="padding-top: 18px; font-size: 0.8em"-->
            <!--                                   *ngIf="neuerEmpfaengerCtrl.hasError('email')">-->
            <!--                            Bitte eine g&uuml;ltige Email im Format "meine@email.de" eingeben.-->
            <!--                        </mat-error>-->
            <!--                    </mat-form-field>-->
            <!--                    <button mat-icon-button color="accent" [disabled]="!neuerEmpfaengerCtrl.value"-->
            <!--                            (click)="addToList()">-->
            <!--                        <mat-icon>add</mat-icon>-->
            <!--                    </button>-->
            <!--                </div>-->
            <!--            </div>-->
            <button mat-icon-button color="accent" [disabled]="!isSendable()" (click)="sendEmail()">
                <mat-icon>send</mat-icon>
            </button>
            <span class="spacer"></span>
        </div>
    </div>

    <div style="height: 90%; width: 100%">
        <div style="width: 100%; margin: 10vh 0 12px 0; display:flex; align-items: center; justify-content: center; text-align: center"
            *ngIf="isLoading">
            <mat-spinner *ngIf="isLoading"></mat-spinner>
        </div>

        <object *ngIf="pdfAvailable" style="height: 80vh; width: 100%" type="application/pdf"
            [data]="input?.pdfBase64"></object>
        <div *ngIf="!pdfAvailable && !isLoading"
            style="min-height: 50vh; display: flex; align-items: center; justify-content: center; text-align: center;">
            <h4>{{noPdfText}}</h4>
        </div>
        <div style="min-height: 25vh; display: flex; align-items: center; justify-content: center; text-align: center;"
            *ngIf="!pdfAvailable && isLoading">
            <h4>PDF wird geladen, bitte warten.</h4>
        </div>
    </div>
</div>
