import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface Option{
  action: string;
  icon: string;
  text: string;
  permission: number;
}

@Component({
  selector: 'kiene-option-button',
  templateUrl: './option-button.component.html',
  styleUrls: ['./option-button.component.scss']
})
export class OptionButtonComponent implements OnInit {

  @Input('text') text: string;

  @Input('icon') icon = '';

  @Input('options') options: Option[] = [];

  @Output('onClick') onClick = new EventEmitter<boolean>();

  @Output('optionClicked') optionClicked = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  clickedText(){
    this.onClick.emit(true);
  }

  clickedOption(o: Option){
    this.optionClicked.emit(o.action);
  }

}
