import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../services/message.service';
import { InvoicePosition } from '../rechnung';

@Component({
  selector: 'kiene-edit-rechnung-position-dialog',
  templateUrl: './edit-rechnung-position-dialog.component.html',
  styleUrls: ['./edit-rechnung-position-dialog.component.css']
})
export class EditRechnungPositionDialogComponent implements OnInit {

  position: InvoicePosition;

  mengeCtrl = new UntypedFormControl();
  einzelpreisCtrl = new UntypedFormControl();
  gutschriftCtrl = new UntypedFormControl(false);

  constructor(public dialogRef: MatDialogRef<EditRechnungPositionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private api: KieneTableService,
    private messageService: MessageService) { 
      this.position = this.data.position;
    }

  ngOnInit(): void {
    this.mengeCtrl.setValue(this.position.artikelmenge);
    this.einzelpreisCtrl.setValue(this.position.einzelpreis);
  }

  close(){
    this.dialogRef.close(null);
  }

  save(){
    this.position.artikelmenge = this.mengeCtrl.value;
    this.position.einzelpreis = this.einzelpreisCtrl.value;
    this.position.gutschrift = this.gutschriftCtrl.value ? 1 : 0;

    this.api.updateGeneric(environment.BASE_URI + 'rechnung/update_artikel.php', this.position).subscribe(response => {
      this.messageService.infoMessage('Die Änderung wurde erfolgreich gespeichert!');
      this.close();
    }, error => {
      this.messageService.errorMessage('Die Änderung konnte nicht gespeichert werden!', error);
    });
  }

}
