import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {API_BASE_URL_SERVICE} from '../../../kiene-core.config';
import {KieneBackendApiService} from '../../../services/backend-api/kiene-backend-api.service';
import {KieneFileService} from '../../../services/files/kiene-file.service';
import {HttpParams} from '@angular/common/http';
import {DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

@Component({
    selector: 'kiene-file-viewer-dialog',
    templateUrl: './file-viewer-dialog.component.html',
    styleUrls: ['./file-viewer-dialog.component.scss']
})
export class FileViewerDialogComponent implements OnInit {
    dateiname: string;
    url: string;
    params: HttpParams;

    safeUrl: SafeUrl;
    blob: Blob;
    pdfBase64: SafeResourceUrl;
    isImage = false;
    isPdf = false;

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                public dialogRef: MatDialogRef<FileViewerDialogComponent>,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private fileService: KieneFileService,
                private sanitizer: DomSanitizer
    ) {
    }

    ngOnInit(): void {
        this.url = this.data.url;
        this.dateiname = this.data.dateiname;
        this.params = this.data.params;

        this.fileService.download(this.url, this.params).subscribe({
            next: blob => {
                this.blob = blob;
                this.checkFileType();
            }
        });
    }

    private checkFileType() {
        if (this.dateiname) {
            if (this.dateiname.endsWith('.png') || this.dateiname.endsWith('.jpeg') || this.dateiname.endsWith('.jpg') || this.dateiname.endsWith('.tiff')) {
                const url = URL.createObjectURL(this.blob);
                this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
                this.isImage = true;
            } else if (this.dateiname.endsWith('.pdf')) {
                this.fileService.convertBlobToBase64(this.blob).then((base64String) => {
                    console.log(base64String.substring(0, 50));
                    base64String = base64String.replace('data:application/octet-stream', 'data:application/pdf');
                    // this.pdfBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(base64String);
                    this.pdfBase64 = base64String;
                    this.isPdf = true;
                });
            }
        }
    }
}
