import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "../../../../environments/environment";
import { AppService } from "../../../app.service";
import { KieneTableService } from "../../../components/kiene-table/kiene-table.service";
import { MessageService } from "../../../services/message.service";

@Component({
    selector: "kiene-csv-upload-dialog",
    templateUrl: "./csv-upload-dialog.component.html",
    styleUrls: ["./csv-upload-dialog.component.css"],
})
export class CsvUploadDialogComponent implements OnInit {
    jahrCtrl = new UntypedFormControl();
    halbjahrCtrl = new UntypedFormControl();

    csvFile: File;
    fileName: string;

    constructor(
        public dialogRef: MatDialogRef<CsvUploadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private api: KieneTableService,
        private appService: AppService,
        private http: HttpClient,
        private messageService: MessageService
    ) {}

    ngOnInit(): void {
        let d = new Date();
        if (d.getMonth() > 5) {
            this.halbjahrCtrl.setValue(1);
            this.jahrCtrl.setValue(d.getFullYear() + 1);
        } else {
            this.halbjahrCtrl.setValue(2);
            this.jahrCtrl.setValue(d.getFullYear());
        }
    }

    onFileSelected(event) {
        this.csvFile = event.target.files[0];
        if (this.csvFile) {
            this.fileName = this.csvFile.name;
        }
    }

    close() {
        this.dialogRef.close(null);
    }

    upload() {
        let fd = new FormData();
        if (this.csvFile) {
            fd.append("dokument", this.csvFile, this.fileName);
        }
        fd.append("jahr", this.jahrCtrl.value);
        fd.append("halbjahr", this.halbjahrCtrl.value);

        const options = {
            headers: new HttpHeaders("Phito-Type: fileupload"),
            reportProgress: true,
        };

        this.appService.setDataLoading(true);
        this.http
            .post(
                environment.BASE_URI +
                    "massnahmenplan_rind/mnp_rind_schwein_generieren.php",
                fd,
                options
            )
            .subscribe(
                (response) => {
                    this.messageService.infoMessage(
                        "Die Maßnahmenpläne wurden erfolgreich angelegt!"
                    );
                    this.appService.setDataLoading(false);
                    this.close();
                },
                (error) => {
                    this.appService.setDataLoading(false);
                    this.messageService.errorMessage(
                        "Die Maßnahmenpläne konnten nich hochgeladen werden!",
                        error
                    );
                }
            );
    }
}
