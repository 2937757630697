import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MessageService} from '../../../services/message-service/message.service';
import {Tierreklamation} from '../classes/tierreklamation';
import {GruppeTier} from '../classes/gruppe-tier';
import {Subscription} from 'rxjs';
import {GruppeTierService} from '../gruppe-tier.service';
import {MatDialog} from '@angular/material/dialog';
import {
    GruppeTierReklamierenDialogComponent
} from '../gruppe-tier-reklamieren-dialog/gruppe-tier-reklamieren-dialog.component';
import {
    GruppeTierViehhaendlerZuordnenDialogComponent
} from './gruppe-tier-viehhaendler-zuordnen-dialog/gruppe-tier-viehhaendler-zuordnen-dialog.component';
import {PdfViewerInput} from '../../pdf-viewer/kiene-pdf-viewer/classes/pdf-viewer-input';
import {HttpParams} from '@angular/common/http';

@Component({
    selector: 'kiene-gruppe-tier-details',
    templateUrl: './gruppe-tier-details.component.html',
    styleUrls: ['./gruppe-tier-details.component.scss'],
})
export class GruppeTierDetailsComponent implements OnInit, OnDestroy {
    @Input('dialog') dialog = false;
    @Input('tier') tier: GruppeTier;
    @Output('changed') changed: EventEmitter<boolean> = new EventEmitter();

    noValue = '---';
    isLoading = true;
    reklamationen: Tierreklamation[];

    tierSubscription: Subscription;
    pdfViewerInput: PdfViewerInput;

    constructor(
        private route: ActivatedRoute,
        private matDialog: MatDialog,
        private gruppeTierService: GruppeTierService,
        private messageService: MessageService
    ) {
    }

    ngOnDestroy(): void {
        this.tierSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        if (this.tier) {
            this.pdfViewerInput = new PdfViewerInput(
                'tier/read_or_mail_pdf_pass.php',
                new HttpParams().set('tier_id', this.tier?.tier_id),
                {
                    betreff: `${this.tier?.gruppe} - Pass ${this.tier?.ohrmarke_notnull}`,
                }
            );
            this.isLoading = false;
        } else {
            // read tierId from route
            this.route.params.subscribe((params) => {
                if (params.gt_id) {
                    this.loadTier(params.gt_id);
                }
            });
        }
    }

    loadTier(tierId: string) {
        this.isLoading = true;
        this.tierSubscription?.unsubscribe();
        this.tierSubscription = this.gruppeTierService
            .ladeTier(Number(tierId))
            .subscribe({
                next: (tier: GruppeTier) => {
                    this.isLoading = false;
                    this.tier = tier;
                    this.pdfViewerInput = new PdfViewerInput(
                        'tier/read_or_mail_pdf_pass.php',
                        new HttpParams().set('tier_id', this.tier?.tier_id),
                        {
                            betreff: `${this.tier?.gruppe} - Pass ${this.tier?.ohrmarke_notnull}`,
                        }
                    );
                },
                error: (err) => {
                    this.isLoading = false;
                    this.messageService.errorMessage(
                        'Fehler beim Laden des Tieres',
                        err
                    );
                },
            });
    }

    openTierreklamationHinzufuegen() {
        const dialogRef = this.matDialog.open(
            GruppeTierReklamierenDialogComponent,
            {
                width: '90vw',
                minWidth: '90vw',
                data: {
                    tier: this.tier,
                },
            }
        );

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.changed.emit(res);
            }
        });
    }

    openViehhaendlerHinzufuegen() {
        const dialogRef = this.matDialog.open(
            GruppeTierViehhaendlerZuordnenDialogComponent,
            {
                width: '90vw',
                minWidth: '90vw',
                data: {
                    tier: this.tier,
                },
            }
        );

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.changed.emit(res);
            }
        });
    }

    getIcon(status_id: number) {
        if (status_id === 142) {
            return 'check_circle';
        }
        if (status_id === 145) {
            return 'check_circle_outline';
        }
        if (status_id === 141 || status_id === 144) {
            return 'schedule';
        }
        if (status_id === 143) {
            return 'error';
        }
        return 'block'; // 140 und undefined etc.
    }

    getIconStatusClass(status_id: number) {
        if (status_id === 142 || status_id === 145) {
            return 'hit-success';
        }
        if (status_id === 141 || status_id === 144) {
            return 'hit-wait';
        }
        if (status_id === 143) {
            return 'hit-failed';
        }
        return 'hit-block'; // 140 und undefined etc.
    }
}
