import {AfterViewInit, Component, Inject, OnInit, ViewChild, } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyGroup, CompanyGroupAdmin, CompanyGroupArticle, } from '../company-group';
import {CompanyGroupService} from '../company-group.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {ClientService} from '../../client/client.service';
import {MessageService} from '../../../services/message.service';
import {UntypedFormControl, Validators} from '@angular/forms';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {HttpParams} from '@angular/common/http';
import {TableDescriptor} from '../../../components/kiene-table/table-descriptor';
import {NumberTableColumn, TableColumn, } from '../../../components/kiene-table/table-column';
import {TableOption, TableOptionEvent, } from '../../../components/kiene-table/table-option';
import {
    CompanyGroupArticleDialogComponent
} from '../company-group-article-dialog/company-group-article-dialog.component';
import {Verkaufspreis} from '../../../components/verkaufspreis-selector/verkaufspreis-selector.component';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-company-group-detail',
    templateUrl: './company-group-detail.component.html',
    styleUrls: ['./company-group-detail.component.scss'],
})
export class CompanyGroupDetailComponent implements OnInit, AfterViewInit {
    currentCompanyGroupId: string;
    companyGroup: CompanyGroup = new CompanyGroup();
    clients: Client[] = [];
    isLoading = false;
    isCreationForm = false;

    selectedTab = 0;

    emailCtrl: UntypedFormControl = new UntypedFormControl(
        '',
        Validators.email
    );

    rabattCtrl = new UntypedFormControl();

    @ViewChild('paginatorCurrent', {static: true})
    paginatorCurrent: MatPaginator;
    @ViewChild('paginatorHistory', {static: true})
    paginatorHistory: MatPaginator;
    @ViewChild('currentSort', {static: true}) currentSort: MatSort;
    @ViewChild('historySort', {static: true}) historySort: MatSort;

    defaultPageSize = 10;
    pageSizeOptions = [5, 10, 20, 50];
    offset = 0;
    limit = this.defaultPageSize;
    sortColumn = 'kunde';

    artikelpreiseTableDescriptor = new TableDescriptor();

    constructor(
        private dialog: MatDialog,
        private messageService: MessageService,
        private api: KieneTableService,
        private route: ActivatedRoute,
        private service: CompanyGroupService,
        private clientService: ClientService,
        private router: Router,
        private localStorageService: LocalStorageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.selectedTab = params['tab'];
        });
        this.route.params.subscribe({
            next: (params) => {
                this.currentCompanyGroupId = params['id'];
                this.loadCompanyGroup();
            },
        });

        this.defaultPageSize =
            this.localStorageService.getCurrentUser().elemente_pro_seite;
        this.loadCompanyGroup();

        this.loadClientsForCompanyGroup();

        this.artikelpreiseTableDescriptor.headline = 'Artikelpreise';
        this.artikelpreiseTableDescriptor.uniqueIdentifier = 'Artikelpreise';
        this.artikelpreiseTableDescriptor.apiUrl =
            this.apiBaseUrl + 'unternehmensgruppe_artikel/';
        this.artikelpreiseTableDescriptor.route =
            '/stammdaten/unternehmensgruppen/' +
            this.currentCompanyGroupId +
            '?tab=5';
        this.artikelpreiseTableDescriptor.standardCreate = false;
        this.artikelpreiseTableDescriptor.nameOfIdField = 'ua_id';
        this.artikelpreiseTableDescriptor.searchPlaceholder =
            'Unternehmensgruppen Artikel durchsuchen';
        this.artikelpreiseTableDescriptor.columns.push(
            new TableColumn('artikel_me', 'Artikel')
        );

        this.artikelpreiseTableDescriptor.columns.push(
            new NumberTableColumn('einzelpreis', 'Einzelpreis', '0.2', '€')
        );

        this.artikelpreiseTableDescriptor.options.push(
            new TableOption('edit', 'editieren', 'edit', 3303)
        );
        this.artikelpreiseTableDescriptor.options.push(
            new TableOption('delete', 'löschen', 'delete', 3304)
        );

        this.artikelpreiseTableDescriptor.params = new HttpParams().append(
            'unternehmensgruppe_id',
            this.currentCompanyGroupId
        );

        this.artikelpreiseTableDescriptor.permissionView = 3301;
        this.artikelpreiseTableDescriptor.permissionCreate = 3303;

        this.artikelpreiseTableDescriptor.initialSortColumn = 'artikel';
        this.artikelpreiseTableDescriptor.initialSortDirection = 'asc';
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.emailCtrl.setValue(this.companyGroup.email);
        }, 2000);
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            this.openAddDialog();
        } else if (event.name === 'edit') {
            this.openEditDialog(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        }
    }

    openAddDialog() {
        const dialog = this.dialog.open(CompanyGroupArticleDialogComponent, {
            width: '40%',
            minWidth: '400px',
            data: {
                companyGroup: this.companyGroup,
            },
        });

        dialog.afterClosed().subscribe({
            next: (cga: CompanyGroupArticle) => {
                if (cga) {
                    this.api
                        .post(
                            this.apiBaseUrl +
                            'unternehmensgruppe_artikel/update.php',
                            cga,
                            undefined
                        )
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Der Artikelpreis wurde erfolgreich gespeichert!'
                                );
                                this.api.reload();
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    openEditDialog(cga: CompanyGroupArticle) {
        const dialog = this.dialog.open(CompanyGroupArticleDialogComponent, {
            width: '40%',
            minWidth: '400px',
            data: {
                companyGroup: this.companyGroup,
                cga: cga,
            },
        });

        dialog.afterClosed().subscribe({
            next: (companyGroupArticle: CompanyGroupArticle) => {
                if (companyGroupArticle) {
                    this.api
                        .post(
                            this.apiBaseUrl +
                            'unternehmensgruppe_artikel/update.php',
                            companyGroupArticle,
                            undefined
                        )
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Der Artikelpreis wurde erfolgreich gespeichert!'
                                );
                                this.api.reload();
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    goBack() {
        window.history.go(-1);
    }

    clearEmail() {
        this.emailCtrl.setValue(null);
    }

    setSelectedTab(event: MatTabChangeEvent) {
        const id = event.index;
        this.selectedTab = id;
        this.router
            .navigate([], {
                relativeTo: this.route,
                queryParams: {tab: id},
                queryParamsHandling: 'merge',
            })
            .then();
    }

    loadCompanyGroup() {
        if (this.currentCompanyGroupId === 'neu') {
            return;
        }
        this.service.getCompanyGroup(this.currentCompanyGroupId).subscribe({
            next: (response) => {
                this.companyGroup = response;
                this.loadCompanyGroupFinanzen();
            },
            error: () => {
            },
        });
    }

    loadCompanyGroupFinanzen() {
        if (this.currentCompanyGroupId === 'neu') {
            return;
        }
        this.api
            .getOne(
                this.apiBaseUrl + 'unternehmensgruppe_admin/read_one.php',
                'unternehmensgruppe_id',
                this.currentCompanyGroupId
            )
            .subscribe({
                next: (response) => {
                    this.companyGroup.verkaufspreis_id =
                        response.verkaufspreis_id;
                    this.rabattCtrl.setValue(response.rabatt);
                },
                error: () => {
                },
            });
    }

    loadClientsForCompanyGroup() {
        if (this.currentCompanyGroupId === 'neu') {
            return;
        }
        this.clientService
            .getClientsByCompanyGroup(
                Number.parseInt(this.currentCompanyGroupId, 10)
            )
            .subscribe({
                next: (response) => {
                    this.clients = response.records;
                },
                error: () => {
                },
            });
    }

    setSelectedVerkaufspreis(event: Verkaufspreis) {
        this.companyGroup.verkaufspreis_id = event.verkaufspreis_id;
    }

    saveCompanyGroup() {
        this.companyGroup.email = this.emailCtrl.value;
        this.companyGroup.rabatt = this.rabattCtrl.value;
        this.service.saveCompanyGroup(this.companyGroup).subscribe({
            next: (response) => {
                this.currentCompanyGroupId =
                    response.unternehmensgruppe_id.toString(10);
                this.router
                    .navigate([
                        '/stammdaten/unternehmensgruppen',
                        this.currentCompanyGroupId,
                    ])
                    .then();
                // this.loadCompanyGroup();
                // this.messageService.infoMessage(
                //     'Unternehmensgruppe ' +
                //     response.bezeichnung +
                //     ' wurde erfolgreich gespeichert!'
                // );
            },
            error: (error) => {
                this.messageService.errorMessage(
                    'Unternehmensgruppe konnte nicht gespeichert werden!',
                    error
                );
            },
        });
    }

    saveCompanyGroupFinanzen() {
        const companyGroupAdmin = new CompanyGroupAdmin();
        companyGroupAdmin.unternehmensgruppe_id =
            this.companyGroup.unternehmensgruppe_id;
        companyGroupAdmin.verkaufspreis_id = this.companyGroup.verkaufspreis_id;
        companyGroupAdmin.rabatt = this.rabattCtrl.value;

        this.api
            .post(
                this.apiBaseUrl + 'unternehmensgruppe_admin/update.php',
                companyGroupAdmin,
                undefined
            )
            .subscribe({
                next: (response) => {
                    this.loadCompanyGroup();
                    this.ngAfterViewInit();

                    this.messageService.infoMessage(
                        'Die Finanzen für die Unternehmensgruppe ' +
                        response.bezeichnung +
                        ' wurden erfolgreich gespeichert!'
                    );
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Fehler! Die Finanzen für die Unternehmensgruppe konnten nicht gespeichert werden!',
                        error
                    );
                },
            });
    }

    private openDeleteDialog(article: CompanyGroupArticle) {
        const confirmDialogInput = new ConfirmDialogInput();
        confirmDialogInput.headline = 'Artikelpreis löschen?';
        confirmDialogInput.text =
            'Wollen Sie den Artikelpreis wirklich löschen?';
        confirmDialogInput.buttonColor = 'warn';
        confirmDialogInput.okButton = 'Löschen';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: confirmDialogInput,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.ok && result?.confirmed) {
                    this.api
                        .deleteEntity(
                            this.apiBaseUrl + 'unternehmensgruppe_artikel/delete.php',
                            article
                        )
                        .subscribe({
                            next: (response) => {
                                this.messageService.infoMessage(
                                    'Der Artikelpreis wurde erfolgreich gelöscht!'
                                );
                                this.api.reload(
                                    'unternehemensgruppe artikelpreis loeschen'
                                );
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }
}
