<h1 mat-dialog-title>{{headline}}</h1>

<div mat-dialog-content>

    <mat-form-field>
        <mat-label>Suchen</mat-label>
        <input [formControl]="searchCtrl" matInput type="text">
        <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>

    <div *ngIf="selectedVvvo" class="one-row">
        <span>Aktueller Betrieb: <strong>{{selectedVvvo?.bezeichnung_final}}</strong></span>
        <button mat-icon-button (click)="betriebAbwaehlen()">
            <mat-icon>clear</mat-icon>
        </button>
    </div>

    <mat-hint *ngIf="isLoading" class="hinweis-text" style="margin: 24px">
        Lade Betriebe...
    </mat-hint>

    <ul *ngIf="!isLoading" class="list">

        <li *ngFor="let vvvo of vvvos" class="list-entry" style="
        width: 100%;
        margin-bottom: 6px;
        border-bottom: 1px solid #eee;
    ">
            <div [ngClass]="{'aktiver-betrieb': vvvo.kunde_id === selectedVvvo?.kunde_id}">
                <h4>{{vvvo?.bezeichnung_final}}</h4>
                <p style="margin: 0; padding: 0;">{{vvvo?.vvvo}}</p>
                <p style="margin: 0; padding: 0;">{{vvvo?.zusatz_final}}</p>
                <div class="one-row" *ngIf="vvvo.kunde_id !== selectedVvvo?.kunde_id">
                    <span class="spacer"></span>
                    <button (click)="betriebAuswaehlen(vvvo)" color="accent" mat-stroked-button>
                        <mat-icon>check</mat-icon>
                        ausw&auml;hlen
                    </button>
                </div>
            </div>
        </li>
    </ul>
</div>

<div mat-dialog-actions>
    <span class="spacer"></span>
    <button (click)="dialogRef.close()" mat-button>Abbrechen</button>
</div>
