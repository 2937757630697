<h3 mat-dialog-title>Lagerplatz editieren</h3>
<mat-dialog-content>
    <mat-form-field style="width: 100%;">
        <mat-label>Lager ausw&auml;hlen</mat-label>
        <mat-select [formControl]="storageCtrl" required>
            <mat-option *ngFor="let s of storages" [value]="s.lager_id">{{s.bezeichnung}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Name des Lagerplatz</mat-label>
        <input matInput type="text" [formControl]="nameCtrl" required>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-flat-button color="acdent" (click)="create()" [disabled]="storageCtrl.invalid || nameCtrl.invalid">
        Aktualisieren
    </button>
</mat-dialog-actions>
