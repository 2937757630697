import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KrankMeldungDialogComponent} from './krank-meldung-dialog/krank-meldung-dialog.component';
import {
    UrlaubsantragGenehmigenDialogComponent
} from './urlaubsantrag-genehmigen-dialog/urlaubsantrag-genehmigen-dialog.component';
import {
    UrlaubsantragStellenDialogComponent
} from './urlaubsantrag-stellen-dialog/urlaubsantrag-stellen-dialog.component';
import {
    KalendereintragStornierenDialogComponent
} from './urlaubsantrag-stornieren-dialog/kalendereintrag-stornieren-dialog.component';
import {UrlaubskalenderMultiMonthComponent} from './urlaubskalender-multi-month/urlaubskalender-multi-month.component';
import {
    WochenendFeiertagsPlanungDialogComponent
} from './wochenend-feiertrags-planung/wochenend-feiertags-planung-dialog.component';
import {UrlaubskalenderComponent} from './urlaubskalender.component';
import {DirectivesModule} from '../../../../projects/kiene-core/src/lib/directives/directives.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatBadgeModule} from '@angular/material/badge';
import {MatListModule} from '@angular/material/list';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {KieneAutocompleteModule} from '../../components/kiene-autocomplete/kiene-autocomplete.module';
import {CalendarModule, DateAdapter as CalendarDateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/date-fns';
import {MatDialogModule} from '@angular/material/dialog';
import {WochenenddienstanzeigeComponent} from './wochenenddienstanzeige/wochenenddienstanzeige.component';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SelectTierarztDialogComponent } from './wochenenddienstanzeige/select-tierarzt-dialog/select-tierarzt-dialog.component';


@NgModule({
    declarations: [
        UrlaubskalenderComponent,
        KrankMeldungDialogComponent,
        UrlaubsantragGenehmigenDialogComponent,
        UrlaubsantragStellenDialogComponent,
        KalendereintragStornierenDialogComponent,
        UrlaubskalenderMultiMonthComponent,
        WochenendFeiertagsPlanungDialogComponent,
        WochenenddienstanzeigeComponent,
        SelectTierarztDialogComponent,
    ],
    imports: [
        CommonModule,
        DirectivesModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatBadgeModule,
        MatListModule,
        MatRadioModule,
        MatCheckboxModule,
        KieneAutocompleteModule,
        CalendarModule.forRoot({provide: CalendarDateAdapter, useFactory: adapterFactory}),
        MatDialogModule,
        MatCardModule,
        MatProgressSpinnerModule
    ],
    exports: [
        UrlaubskalenderComponent
    ]
})
export class UrlaubskalenderModule {
}
