import { HttpParams } from '@angular/common/http';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { KieneBackendApiService } from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MessageService } from '../../../services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { BeauftragenResponse } from '../../../components/anfrage-details/details/details.component';
import { LocalStorageService } from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';

@Component({
  selector: 'kiene-anfrage-details',
  templateUrl: './anfrage-details.component.html',
  styleUrls: ['./anfrage-details.component.scss']
})
export class AnfrageDetailsComponent implements OnInit {
  zeitslot_buchung_id: number;
  zeitslot_buchung: any;
  showButtons: boolean = false;
  landwirt: any;
  schlachthof: any;
  haendler: any;
  spediteur: any;
  fahrer: any;
  selectedIndex = 0;
  chooseTransporteur = false;
  lieferscheinNichtAusgefuellt = true;
  lieferschein_id;
  buchung_dateien: Array<any>;
  vonHaendlerBeauftragt = false;
  schlachthofStandort;
  interval;

  constructor(
    private api: KieneBackendApiService,
    @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
    private messageService: MessageService,
    private route: ActivatedRoute,
    protected _router: Router,
    private localStorageService: LocalStorageService
  ) { 
    this.zeitslot_buchung_id = Number(this.route.snapshot.paramMap.get('id'));
    this.route.queryParams.forEach(x => {
      if(x.vonOffen){
        this.showButtons = x.vonOffen;
      }
    })
  }

  ngOnInit(): void {
    this.ladeZeitslotBuchung();
  }

  tabChanged(event: MatTabChangeEvent) {
    this.selectedIndex = event.index;
}

  ladeZeitslotBuchung(){
    if(this.zeitslot_buchung_id){
      const params = new HttpParams().set(
        'zeitslot_buchung_id',
        this.zeitslot_buchung_id
      );
      this.api
        .get(
            `${this.apiBaseUrl}zeitslot_buchung/read_one.php`,
            params)
        .subscribe({
            next: (response: any) => {
              this.zeitslot_buchung = response
              console.log(this.zeitslot_buchung);
              this.vonHaendlerBeauftragt = response.spediteur_id > 0;
              //TODO handle data changed
              this.ladeLandwirtData();
              this.ladeViehhaendlerData();
              this.ladeSchlachthofData();
              if(response.status_haendler == 1 && response.status_schlachthof == 1){
                this.ladeLieferschein();
              }
              this.ladeDateien();
              this.pruefeStandort();
            },
            error: err => {
                this.messageService.errorMessage('Buchung konnte nicht geladen werden!', err);
            }
        });
    }
  }

  ladeLandwirtData(){
    if(this.zeitslot_buchung){
      this.landwirt = {
        "bezeichnung": this.zeitslot_buchung.lw_bezeichnung,
        "vvvo": this.zeitslot_buchung.lw_vvvo,
        "telefon_1": this.zeitslot_buchung.lw_telefon_1,
        "mobil_1": this.zeitslot_buchung.lw_mobil_1,
        "fax": this.zeitslot_buchung.lw_fax,
        "email": this.zeitslot_buchung.lw_email,
        "strasse": this.zeitslot_buchung.lw_strasse,
        "plz": this.zeitslot_buchung.lw_plz,
        "ortsteil": this.zeitslot_buchung.lw_ortsteil,
        "ort": this.zeitslot_buchung.lw_ort,
      }
    }
  }

  //TODO revers this into viehhändler if fitting
  ladeViehhaendlerData(){
    if(this.zeitslot_buchung){
      const params = new HttpParams().set(
        'betrieb_id',
        this.zeitslot_buchung.haendler_id
      );
      this.api
        .get(
            `${this.apiBaseUrl}schlachthoefe/read_one.php`,
            params)
        .subscribe({
            next: (response: any) => {
              this.haendler = response
              console.log(this.haendler);
              //TODO handle data changed
            },
            error: err => {
                this.messageService.errorMessage('Buchung konnte nicht geladen werden!', err);
            }
        });
    }
  }

  ladeSchlachthofData(){
    if(this.zeitslot_buchung){
      const params = new HttpParams().set(
        'betrieb_id',
        this.zeitslot_buchung.schlachthof_betrieb
      );
      this.api
        .get(
            `${this.apiBaseUrl}schlachthoefe/read_one.php`,
            params)
        .subscribe({
            next: (response: any) => {
              this.schlachthof = response;
              var adresse = this.schlachthof.strasse + '%20' + this.schlachthof.plz + '%20' + this.schlachthof.ort;
              adresse = adresse.replaceAll("\\n","").replaceAll("\\r","").replaceAll(" ","%20");
              this.adresseZuKoordinaten(adresse);
              //TODO handle data changed
            },
            error: err => {
                this.messageService.errorMessage('Buchung konnte nicht geladen werden!', err);
            }
        });
    }
  }

  updateStatusHaendler(status: any){

    this.zeitslot_buchung.status_haendler = status;
    
    this.updateBuchungOnServer('Status wurde erfolgreich geändert!');
  }

  updateBeauftragterSpediteur(spediteur_id: number, fahrer_id: number){
    this.zeitslot_buchung.spediteur_id = spediteur_id;
    this.zeitslot_buchung.fahrer_id = fahrer_id;

    console.log(this.zeitslot_buchung);

    this.updateBuchungOnServer('Spediteur wurde erfolgreich beauftragt!');
  }

  updateBuchungOnServer(successMessage?: string){
    this.api
    .post(
        this.apiBaseUrl +
        'zeitslot_buchung/update_buchung.php',
        this.zeitslot_buchung,
        undefined
    )
    .subscribe({
        next: (response) => {

            this.messageService.infoMessage(
                successMessage
            );
            this._router.navigate(['/gruppen', 'zeitslot-historie']).then();
        },
        error: (err) => {

            this.messageService.errorMessage(
                'Fehler!',
                err
            );
        },
    });
  }

  ladeLieferschein() {
    
    const params = new HttpParams().append('buchungs_id', this.zeitslot_buchung_id);
    this.api.get(this.apiBaseUrl + 'lieferschein/read_by_buchung.php', params).subscribe(response => {
        if(response.records[0]) {
          if(response.records[0].unterschrift_landwirt != '') {
            this.lieferscheinNichtAusgefuellt = false;
          }
          this.lieferschein_id = response.records[0].lieferschein_id;
        }
    }, error => {
      this.messageService.errorMessage('Fehler! ', error);
    });
  }

  acceptBuchungCallback(acceptanceState: boolean){
    if(acceptanceState){
      this.acceptBuchung();
    } else {
      this.denyBuchung();
    }
  }

  acceptBuchung(){
    console.log("accept");
    this.updateStatusHaendler(1);
  }

  denyBuchung(){
    console.log("deny");
    this.updateStatusHaendler(2);
  }

  beauftragenCallback(state: BeauftragenResponse){
    this.zeitslot_buchung.status_transport = 2;

    this.updateBuchungOnServer('Position wurde erfolgreich übergeben!');
  }

  ladeDateien() {
    const params = new HttpParams().append('zeitslot_buchung_id', this.zeitslot_buchung_id);
    this.api.get(this.apiBaseUrl + 'zeitslot_buchung/read_dateien.php', params).subscribe(response => {
        if(response.records){
          // evtl TODO sortieren nach User
          this.buchung_dateien = response.records;
        }
    }, error => {
      this.messageService.errorMessage('Fehler! ', error);
    });
  }

  adresseZuKoordinaten(address: string) {
    // console.log(address);
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({
        'address': address
    }, (results, status) => {
      const medianLat = (results[0].geometry.location.lat());
      const medianLng = (results[0].geometry.location.lng());
      const standort = medianLat.toString() + ',' + medianLng.toString();
      this.schlachthofStandort = standort;
    });
  }

  pruefeStandort() {
    if(this.zeitslot_buchung?.standort != null && (this.zeitslot_buchung?.status_transport == 2 || this.zeitslot_buchung?.status_transport == 3)) {
      this.ladeStandort();
      this.interval = setInterval(() => {
        this.ladeStandort();
      }, 1000 * 60);
    }
  }
  
  ladeStandort() {
    const params = new HttpParams().set(
      'zeitslot_buchung_id',
      this.zeitslot_buchung_id
    );
    this.api
      .get(
          `${this.apiBaseUrl}zeitslot_buchung/read_standort.php`,
          params)
      .subscribe({
          next: (response: any) => {
            this.zeitslot_buchung.standort = response.records[0].standort;
          },
          error: err => {
              this.messageService.errorMessage('Standort konnte nicht geladen werden!', err);
          }
      });
  }
  
  // this.api.updateGeneric(`${this.baseUrl}benachrichtigung/update_gelesen.php`, benachrichtigung);
}
