import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Invoice } from '../rechnung';

@Component({
  selector: 'kiene-rechnung-storno-dialog',
  templateUrl: './rechnung-storno-dialog.component.html',
  styleUrls: ['./rechnung-storno-dialog.component.css']
})
export class RechnungStornoDialogComponent implements OnInit {

  invoice: Invoice = new Invoice();

  gutschriftDatumCtrl = new UntypedFormControl(new Date(), [Validators.required]);

  constructor(public dialogRef: MatDialogRef<RechnungStornoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) { 
      this.invoice = this.data.invoice;
    }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  save(){
    this.invoice.status_id = 55;
    this.invoice.gutschrift_datum = this.gutschriftDatumCtrl.value;
    this.dialogRef.close(this.invoice);
  }

}
