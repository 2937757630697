import { Tierliste } from "../../gruppe-tier/classes/tierliste";
import { Gruppe } from "../classes/gruppe";

export class GruppeAbgangDialogInput {
    gruppe: Gruppe;
    tierliste: Tierliste;
    alsDialog: boolean;

    constructor(tierliste: Tierliste, alsDialog: boolean, gruppe?: Gruppe){
        this.tierliste = tierliste;
        this.alsDialog = alsDialog;
        this.gruppe = gruppe;
    }

}