import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PdfViewerDesktopDialogComponent} from './kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop-dialog';
import {PdfViewerDialogComponent} from './pdf-viewer-dialog/pdf-viewer-dialog.component';
import {PdfViewerSendEmailDialogComponent} from './pdf-viewer-send-email-dialog/pdf-viewer-send-email-dialog.component';
import {KienePdfViewerComponent} from './kiene-pdf-viewer/kiene-pdf-viewer.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {PdfViewerModule as ng2PdfViewerModule} from 'ng2-pdf-viewer';
import {MatButtonModule} from '@angular/material/button';
import {MatSliderModule} from '@angular/material/slider';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {ClientModule} from '../client/client.module';
import {ShareButtonModule} from '../share-button/share-button.module';
import {KieneAutocompleteModule} from '../kiene-autocomplete/kiene-autocomplete.module';
import {DirectivesModule} from '../../directives/directives.module';

@NgModule({
    declarations: [
        PdfViewerDesktopDialogComponent,
        PdfViewerDialogComponent,
        PdfViewerSendEmailDialogComponent,
        KienePdfViewerComponent
    ],
    imports: [
        CommonModule,
        MatToolbarModule,
        MatIconModule,
        FormsModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatDividerModule,
        MatFormFieldModule,
        ng2PdfViewerModule,
        MatButtonModule,
        MatSliderModule,
        MatDialogModule,
        MatInputModule,
        ReactiveFormsModule,
        ClientModule,
        ShareButtonModule,
        KieneAutocompleteModule,
        DirectivesModule
    ],
    exports: [
        PdfViewerDesktopDialogComponent,
        PdfViewerDialogComponent,
        PdfViewerSendEmailDialogComponent,
        KienePdfViewerComponent
    ]
})
export class PdfViewerModule {
}
