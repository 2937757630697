export class Futtermittellieferant {
    futtermittellieferant_id: number;
    bezeichnung: string;
    email: string;
    telefon: string;
    mobil: string;
    fax: string;
    strasse: string;
    plz: string;
    ort: string;
}
