<div (onFileDropped)="uploadExterneBefundmitteilung($event)" kieneDropFile style="padding: 24px;">

    <!-- 1. Zeile -->
    <div class="one-row">

        <div class="one-row" style="width: 50%;">
            <button (click)="saveAndExit()" mat-icon-button matTooltip="Speichern und Schlie&szlig;en">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <h2>Befundmitteilung {{ befundmitteilung?.tagebuch_nr }}</h2>
            <span class="spacer"></span>
        </div>

        <span class="spacer"></span>

        <button (click)="transformToAntibiogramm()" *kienePermission="[1336]"
                [disabled]="befundmitteilung?.status_id === 12"
                mat-icon-button matTooltip="in Antibiogramm umwandeln">
            <mat-icon>swap_horiz</mat-icon>
        </button>
        <button (click)="freigabeEntfernen()" *kienePermission="[1312]" [disabled]="befundmitteilung?.status_id !== 13"
                mat-icon-button
                matTooltip="Freigabe entfernen">
            <mat-icon>undo</mat-icon>
        </button>
        <button (click)="save()" *kienePermission="[1312]" [disabled]="befundmitteilung?.status_id === 12"
                mat-icon-button
                matTooltip="Speichern">
            <mat-icon>save</mat-icon>
        </button>
        <button (click)="addFilesToBefundmitteilung($event)" *kienePermission="[1322]"
                [disabled]="!befundmitteilung?.befund_id" mat-icon-button matTooltip="Externe Befunde anhängen">
            <mat-icon>attach_file</mat-icon>
        </button>
        <button (click)="openExterneBefundeDialog()" [disabled]="befundmitteilungenExtern.length < 1" mat-icon-button>
            <mat-icon [matBadge]="befundmitteilungenExtern.length" matBadgeColor="accent">folder</mat-icon>
        </button>
        <button (click)="openPdfPreview($event)" *kienePermission="[1315]" [disabled]="!befundmitteilung?.befund_id"
                mat-icon-button matTooltip="PDF Vorschau">
            <mat-icon>picture_as_pdf</mat-icon>
        </button>

        <button (click)="createNotiz()" *kienePermission="[2605]" mat-stroked-button>Notizzettel erstellen</button>

        <div style="width: 100px; margin-left: 12px;">
            <button (click)="vorherigeBefundmitteilung()" *ngIf="recPositionObject.prev" mat-icon-button>
                <mat-icon>chevron_left</mat-icon>
            </button>
            <button (click)="naechsteBefundmitteilung()" *ngIf="recPositionObject.next" mat-icon-button>
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>

    </div>

    <!-- 2. Zeile -->

    <div *kienePermission="[1313]" class="one-row">

        <mat-form-field>
            <mat-label>Tagebuchnummer</mat-label>
            <input (blur)="save()" [formControl]="tagebuchCtrl" matInput>
            <span matSuffix>/{{ tagebuchJahr?.toString().substr(-2) }}</span>
        </mat-form-field>

        <mat-form-field style="width: 200px;">
            <mat-label>Probenentnahmedatum</mat-label>
            <input (blur)="save()" [formControl]="probenentnahmeCtrl" [matDatepicker]="probenentnahmePicker" matInput>
            <mat-datepicker-toggle [for]="probenentnahmePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #probenentnahmePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field style="width: 200px;">
            <mat-label>Untersuchungsbeginn</mat-label>
            <input (blur)="save()" [formControl]="untersuchungsbeginnCtrl" [matDatepicker]="untersuchungsbeginnPicker"
                   matInput>
            <mat-datepicker-toggle [for]="untersuchungsbeginnPicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #untersuchungsbeginnPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field style="width: 210px;">
            <mat-label>Untersuchungsende</mat-label>
            <input (blur)="save()" [formControl]="untersuchungsendeCtrl" [matDatepicker]="untersuchungsendePicker"
                   matInput>
            <mat-datepicker-toggle [for]="untersuchungsendePicker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #untersuchungsendePicker></mat-datepicker>
        </mat-form-field>

        <kiene-autocomplete #kundeAutocomplete (elementSelected)="setSelectedClient($event)"
                            [disabled]="isClientDisabled()"
                            [displayFields]="['kunde_nr_intern', 'bezeichnung', 'zusatz', 'unternehmensgruppe']"
                            [genericApiUrl]="'kunde/read.php'"
                            [kieneObject]="currentClient"
                            [mapperDisplayFields]="['kunde_nr_intern', 'bezeichnung', 'zusatz', 'unternehmensgruppe']"
                            [placeholder]="'Kunde'"
                            [separator]="' - '"
                            style="min-width: 400px;">
        </kiene-autocomplete>

        <kiene-autocomplete #gruppeAutocomplete (elementSelected)="setSelectedGruppe($event)"
                            [disabled]="!currentClient || isClientDisabled()"
                            [displayFields]="['bezeichnung', 'stall']"
                            [genericApiUrl]="'gruppe/read_akt.php'"
                            [kieneObject]="currentGruppe"
                            [mapperDisplayFields]="['bezeichnung', 'stall']"
                            [params]="currentClientParams"
                            [placeholder]="'Gruppe'" [separator]="' - '"
                            style="min-width: 400px;">
        </kiene-autocomplete>

    </div>

    <div *ngIf="!hasPermission(1313)" class="one-row" style="margin-bottom: 12px;">
        <span>Tagebuchnummer: <strong>{{ befundmitteilung?.tagebuch_nr }}</strong></span>
        <span>Probenentnahmedatum: <strong>{{ befundmitteilung?.probenentnahme | date: 'dd.MM.yyyy' }}</strong></span>
        <span>Untersuchungsbeginn: <strong>{{ befundmitteilung?.eingang | date: 'dd.MM.yyyy' }}</strong></span>
        <span>Untersuchungsbeginn: <strong>{{ befundmitteilung?.ausgang | date: 'dd.MM.yyyy' }}</strong></span>
        <span>Gruppe: <strong>{{ befundmitteilung?.gruppe }}</strong></span>
    </div>

    <!-- 3. Zeile -->

    <div *kienePermission="[1313]" class="one-row">

        <mat-form-field style="min-width:400px; margin-right: 12px;">
            <mat-label>Untersuchungsmaterial</mat-label>
            <input (blur)="save()" (textChanged)="replaceUntersuchungsmaterial($event)"
                   [formControl]="untersuchungsmaterialCtrl"
                   kieneTextProposal matInput>
        </mat-form-field>

        <mat-form-field style="min-width: 20%;">
            <mat-label>Pr&uuml;fer</mat-label>
            <mat-select (blur)="save()" [compareWith]="prueferComparator" [formControl]="prueferCtrl">
                <mat-option>Kein Pr&uuml;fer ausgew&auml;hlt</mat-option>
                <mat-option *ngFor="let p of pruefer" [value]="p">{{ p.bezeichnung }}</mat-option>
            </mat-select>
        </mat-form-field>

        <span class="spacer"></span>

        <div *kienePermission="[1310]">
            <button (click)="freigeben()"
                    *ngIf="befundmitteilung?.status_id === 11 || befundmitteilung?.status_id === 15"
                    [disabled]="untersuchungsendeCtrl.value == null || untersuchungsbeginnCtrl.value == null || untersuchungsmaterialCtrl.value == null || untersuchungsmaterialCtrl.value == '' || prueferCtrl.value == null"
                    color="accent"
                    mat-flat-button>
                Befundmitteilung
                freigeben
            </button>
        </div>
        <div *kienePermission="[1316]">
            <button (click)="abschliessen()" *ngIf="befundmitteilung?.status_id == 13"
                    [disabled]="untersuchungsendeCtrl.value == null || untersuchungsbeginnCtrl.value == null || untersuchungsmaterialCtrl.value == null || untersuchungsmaterialCtrl.value == '' || prueferCtrl.value == null"
                    color="accent"
                    mat-flat-button>
                Befundmitteilung
                abschlie&szlig;en
            </button>
        </div>

    </div>

    <div *ngIf="!hasPermission(1313)" class="one-row">
        <span style="margin-right: 24px;">Untersuchungsmaterial:
            <strong>{{ befundmitteilung?.untersuchungsmaterial }}</strong></span>
        <span>Pr&uuml;fer: <strong>{{ befundmitteilung?.pruefer }}</strong></span>
        <span class="spacer"></span>
    </div>

    <!-- Position hinzufuegen bzw. bearbeiten -->

    <div *ngIf="hasPermission(1313) && befundmitteilung?.status_id !== 12"
         style="border: 1px solid gray; border-radius: 5px; padding: 12px;">

        <div class="one-row">

            <kiene-article-scan-autocomplete #articleScan (selectedMaterial)="setSelectedMaterial($event)"
                                             [gesperrtAusblenden]="true" [labor]="true"
                                             [placeholder]="'Artikel'"
                                             style="width: 50%;"></kiene-article-scan-autocomplete>

            <mat-form-field style="min-width:120px;">
                <mat-label>Menge</mat-label>
                <input [formControl]="mengeCtrl" matInput type="number">
            </mat-form-field>

            <mat-checkbox [formControl]="imBefundAnzeigenCtrl">Im Befund anzeigen</mat-checkbox>
        </div>

        <mat-form-field style="width: 100%;">
            <mat-label>Text</mat-label>
            <textarea (textChanged)="replaceFreitext($event)" [formControl]="textCtrl" kieneTextProposal matInput
                      rows="3"></textarea>
        </mat-form-field>

        <mat-form-field style="width: 100%;">
            <mat-label>Text Untersuchungsauftrag</mat-label>
            <textarea (textChanged)="replaceFreitextExtern($event)" [formControl]="freitextExternCtrl" kieneTextProposal
                      matInput
                      rows="1"></textarea>
        </mat-form-field>

        <div class="one-row">
            <mat-form-field style="width: 100%;">
                <mat-label>Fu&szlig;zeile</mat-label>
                <input (textChanged)="replaceFusszeile($event)" [formControl]="fusszeileCtrl" kieneTextProposal matInput
                       rows="3">
            </mat-form-field>


            <button (click)="savePosition()" color="accent" mat-flat-button
                    style="margin-left: 24px;">Speichern
            </button>
        </div>

        <div *ngIf="this.selectedMaterial?.idexx === 1">
            <div style="width: 100%; height: 1px; background-color: grey"></div>
            <div style="font-size: 1.15em; padding:1% 0 1% 0">Idexx - XChekPlus (Status: {{
                    getTestlaufStatus() ?
                        'abgeschlossen' : 'offen'
                }})
            </div>
            <div style="width: 100%;">
                <mat-form-field style="width: 25%;">
                    <mat-label>Tierart</mat-label>
                    <mat-select [formControl]="idexx_speziesCtrl">
                        <mat-option *ngFor="let ta of idexx_spezies" [value]="ta">
                            {{ ta.bezeichnung }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="width: 25%; margin-left: 2%">
                    <mat-label>Probenart (z.B. Milch, Blut ...)</mat-label>
                    <input [formControl]="idexx_probenartCtrl " matInput>
                </mat-form-field>
            </div>

            <mat-form-field style="width: 100%;">
                <mat-label>Testlauf Kommentar</mat-label>
                <textarea (textChanged)="replaceIdexxTestlaufKommentar($event)"
                          [formControl]="idexx_testlaufkommentarCtrl" kieneTextProposal matInput
                          rows="3"></textarea>
            </mat-form-field>
        </div>

    </div>

    <!-- Positionentabelle -->

    <table class="phito-table" style="margin-top: 24px;">
        <thead>
        <tr>
            <th></th>
            <th>Position</th>
            <th>Artikel</th>
            <th>Menge</th>
            <th *kienePermission="[1334]">Einzelpreis</th>
            <th *kienePermission="[1334]">Gesamtpreis</th>
            <th>Im Beleg anzeigen</th>
            <th>Freitext</th>
            <th>Freitext Untersuchungsauftrag</th>
            <th>Fu&szlig;zeile</th>
            <th></th>
        </tr>
        </thead>
        <tbody (cdkDropListDropped)="dropPosition($event)" cdkDropList class="example-list">
        <tr (click)="positionBearbeiten(i)" *ngFor="let p of befundmitteilung?.positionen; let i = index"
            [cdkDragData]="p" cdkDrag cdkDragLockAxis="y" class="example-box" style="cursor: pointer;">
            <td cdkDragHandle style="cursor: grab;">
                <mat-icon>menu</mat-icon>
            </td>
            <td>{{ p.position }}</td>
            <td>{{ p.artikel }}</td>
            <td>{{ p.artikelmenge }}</td>
            <td *kienePermission="[1334]">{{ p.einzelpreis | number: '1.2-2' }} &euro;</td>
            <td *kienePermission="[1334]">{{ (p.artikelmenge * p.einzelpreis) | number: '1.2-2' }} &euro;</td>
            <td>
                <mat-icon>{{ p.auflisten ? 'check_box' : 'check_box_outline_blank' }}</mat-icon>
            </td>
            <td>{{ p.freitext }}</td>
            <td>{{ p.freitext_extern }}</td>
            <td>{{ p.fusszeile }}</td>
            <td>
                <kiene-delete-button (delete)="positionEntfernen(i)"
                                     *ngIf="hasPermission(1313) && befundmitteilung?.status_id !== 12">
                </kiene-delete-button>
            </td>
            <td *ngIf="isIdexxPosition(p)">
                <button (click)="openResultDialog(p)" [disabled]="p.abgeschlossen === 0" mat-icon-button>
                    <mat-icon *ngIf="p.abgeschlossen === 1" matTooltip="Ergebnisse anzeigen">checklist</mat-icon>
                    <mat-icon *ngIf="p.abgeschlossen === 0" matTooltip="In Bearbeitung">watch_later</mat-icon>
                </button>

            </td>
        </tr>
        </tbody>
    </table>

</div>
