import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Tierliste} from '../../gruppe-tier/classes/tierliste';
import { GruppeAbgangDialogInput } from './gruppe-abgang-dialog';

@Component({
    selector: 'kiene-gruppe-abgang-dialog',
    templateUrl: './gruppe-abgang-dialog.component.html',
    styleUrls: ['./gruppe-abgang-dialog.component.scss']
})
export class GruppeAbgangDialogComponent implements OnInit {

    @Output('geschlossen') geschlossen = new EventEmitter<Tierliste>();

    constructor(@Inject(MAT_DIALOG_DATA) public data: GruppeAbgangDialogInput,
                public dialogRef: MatDialogRef<GruppeAbgangDialogComponent>) {
    }

    ngOnInit(): void {
    }

    abgangGeschlossen(event: Tierliste) {
        if (event) {
            this.geschlossen.emit(event);
            this.dialogRef.close(event);
        }
    }

    public close(): void {
        this.dialogRef.close();
    }
}
