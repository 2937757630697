import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    FilterItem,
    FilterItemType
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { KieneBackendApiService } from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { LocalStorageService } from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
    @Input('filterItems') filterItems: FilterItem[] = [];

    @Input('tablename') tablename: string;

    @Output('updatedFilterItems') updatedFilterItems = new EventEmitter<FilterItem[]>();

    @Output('checkedFilterCount') checkedFilterCount = new EventEmitter<number>();

    prefix = 'Filter-';

    checkedAll = false;
    indeterminateAll = false;
    disabled = false;

    constructor(
        private api: KieneBackendApiService,
        private localStorageService: LocalStorageService
    ) {
    }

    ngOnInit() {
        this.presetFilter();
    }

    presetFilter() {
        if (this.tablename) {
            this.disabled = true;
            this.api.getSpeicher(this.prefix + this.tablename).subscribe({
                next: (itemsDatabase: FilterItem[]) => {
                    if (itemsDatabase) {
                        let changed = false;
                        for (const item of this.filterItems) {
                            if (this.hasPermission(item)) {
                                for (const itemDb of itemsDatabase) {
                                    if (item.name === itemDb.name) {
                                        if (item.value instanceof Array && itemDb.value instanceof Array) {
                                            let identisch = true;
                                            for (const v of item.value) {
                                                if (itemDb.value?.indexOf(v) < 0) {
                                                    identisch = false;
                                                }
                                            }
                                            if (identisch) {
                                                if (item.checked != itemDb.checked) {
                                                    item.checked = itemDb.checked;
                                                    changed = true;
                                                }
                                                break;
                                            }
                                        } else if (item.value === itemDb.value) {
                                            if (item.checked != itemDb.checked) {
                                                item.checked = itemDb.checked;
                                                changed = true;
                                            }
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        if (changed) {
                            this.updatedFilterItems.emit(this.filterItems);
                        }
                    }
                    this.emitCheckedFilterCount();
                    this.setCheckboxAll();
                    this.disabled = false;
                },
                error: () => {
                    this.emitCheckedFilterCount();
                    this.setCheckboxAll();
                    this.disabled = false;
                }
            });
        } else {
            this.emitCheckedFilterCount();
            this.setCheckboxAll();
        }
    }

    filterInDbSpeichern() {
        if (this.tablename) {
            this.disabled = true;
            this.api.setSpeicher(this.prefix + this.tablename, this.filterItems).subscribe(
                {
                    next: () => {
                        this.disabled = false;
                    }
                }
            );
        }
    }

    changedCheckboxAll(event: MatCheckboxChange) {
        this.indeterminateAll = false;
        this.checkedAll = event.checked;
        for (let f of this.filterItems) {
            if (this.hasPermission(f)) {
                f.checked = event.checked;
            }
        }
        this.filterInDbSpeichern();
        this.updatedFilterItems.emit(this.filterItems);
        this.emitCheckedFilterCount();
    }

    changedCheckboxValue(filterItem: FilterItem, event: MatCheckboxChange) {
        filterItem.checked = event.checked;
        this.filterInDbSpeichern();
        this.updatedFilterItems.emit(this.filterItems);
        this.setCheckboxAll();
        this.emitCheckedFilterCount();
    }

    emitCheckedFilterCount() {
        let checkedCount = 0;
        for (const f of this.filterItems) {
            if (this.hasPermission(f)) {
                if (f.checked) {
                    checkedCount++;
                }
            }
        }
        this.checkedFilterCount.emit(checkedCount);
    }

    setCheckboxAll() {
        let checkedSum = 0;
        let uncheckedSum = 0;
        for (const f of this.filterItems) {
            if (this.hasPermission(f)) {
                if (f.checked) {
                    checkedSum++;
                    if (uncheckedSum > 0) {
                        this.indeterminateAll = true;
                        this.checkedAll = false;
                        return;
                    }
                }
                if (!f.checked) {
                    uncheckedSum++;
                    if (checkedSum > 0) {
                        this.indeterminateAll = true;
                        this.checkedAll = false;
                        return;
                    }
                }
            }
        }
        this.indeterminateAll = false;
        this.checkedAll = checkedSum > 0;
    }

    isCheckbox(filterItem: FilterItem): boolean {
        if (filterItem.type === FilterItemType.CHECKBOX) {
            return true;
        }
        return false;
    }

    hasPermission(filterItem: FilterItem): boolean {
        if (this.localStorageService.currentUserIsAdmin()) {
            return true;
        }

        if (filterItem.adminOnly) {
            // ausnahme fuer verwaltung
            if (this.localStorageService.isCurrentUserVerwaltung()) {
                return true;
            } else {
                return false;
            }
        }

        if (!filterItem.permissionId) {
            return true;
        }

        return this.localStorageService.currentUserHasPermission(
            filterItem.permissionId
        );
    }
}
