import { ifError } from "assert";

export class CsvColumn {
    name: string;
    displayName: string;
    unit: string;

    constructor(name?: string, displayName?: string, unit?: string){
        if(name){
            this.name = name;
        }

        if(displayName){
            this.displayName = displayName;
        }

        if(unit){
            this.unit = unit;
        }
    }
}