import { Subscription } from 'rxjs';
import { Component, OnInit, Inject, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { MessageService } from '../../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { HttpParams } from '@angular/common/http';
import {
    KieneBackendApiService
} from '../../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { API_BASE_URL_SERVICE } from '../../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { GruppeStandortAuswertung, WertAnzahl } from './gewichte-preise';
import { GruppenService } from '../../../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';

@Component({
    selector: 'kiene-gewichte-preise-schweine',
    templateUrl: './gewichte-preise-schweine.component.html',
    styleUrls: ['./gewichte-preise-schweine.component.scss']
})
export class GewichtePreiseSchweineComponent implements OnInit, OnChanges, OnDestroy {

    @Input('gruppe_id') gruppe_id: number;

    auswertungen: GruppeStandortAuswertung[] = [];

    gruppeStandortLoading = false;
    gruppeStandortSubscription: Subscription;
    reloadGewichteUndPreiseSubscription: Subscription;

    constructor(
        private messageService: MessageService,
        private api: KieneBackendApiService,
        private gruppenService: GruppenService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
    ) {
        this.reloadGewichteUndPreiseSubscription = this.gruppenService.watchReloadGewichteUndPreise().subscribe({
            next: () => {
                this.ladeGruppenStandorte();
            }
        })
    }
    ngOnDestroy(): void {
        this.gruppeStandortSubscription?.unsubscribe();
        this.reloadGewichteUndPreiseSubscription?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.gruppe_id) {
            this.ladeGruppenStandorte();
        }
    }

    ngOnInit(): void {
    }

    ladeAuswertungen(a: GruppeStandortAuswertung) {
        a.isLoading = true;
        a.erfolgreich = false;
        let params = new HttpParams().set('gs_id', a.gruppeStandort?.gs_id);
        params = params.set('schlachtdaten', a.letzterDatensatz ? '1' : '0');
        params = params.set('preise', (a.letzterDatensatz || a.ersterDatensatz) ? '1' : '0');
        this.api.get(this.apiBaseUrl + 'gruppe_standort/read_auswertung_schweine.php', params).subscribe({
            next: (response: GruppeStandortAuswertung) => {
                a.isLoading = false;
                a.erfolgreich = true;
                if (response) {
                    a.betrieb = response.betrieb;
                    a.unternehmensgruppe = response.unternehmensgruppe;
                    a.gruppe = response.gruppe;
                }
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim laden des Auswertungen!', err);
                a.isLoading = false;
            }
        });
    }

    ladeGruppenStandorte() {
        if (this.gruppe_id) {
            this.gruppeStandortLoading = true;
            this.gruppeStandortSubscription?.unsubscribe();
            this.auswertungen = [];
            this.gruppeStandortSubscription = this.api
                .get(
                    this.apiBaseUrl + 'gruppe_standort/read.php',
                    new HttpParams().set('gruppe_id',
                        this.gruppe_id?.toString())
                )
                .subscribe({
                    next: (response) => {
                        this.gruppeStandortLoading = false;
                        if (response?.records) {
                            let i = 1;
                            for (let r of response.records) {
                                const a = new GruppeStandortAuswertung();
                                a.gruppeStandort = r;
                                a.letzterDatensatz = (i === response.recordcount);
                                a.ersterDatensatz = (i === 1);
                                this.auswertungen.push(a);
                                this.ladeAuswertungen(a);
                                i++;
                            }
                        }
                    },
                    error: err => {
                        this.messageService.errorMessage('Gruppen Standorte konnten nicht geladen werden!', err);
                        this.gruppeStandortLoading = false;
                    }
                });
        }
    }

    getClass(wa1: WertAnzahl, wa2: WertAnzahl, nachkommastellen: number) {
        const v1 = Math.round(wa1?.wert * Math.pow(10, nachkommastellen));
        const v2 = Math.round(wa2?.wert * Math.pow(10, nachkommastellen));
        if (!v1 || !v2) {
            return 'undefiniert';
        }
        if (v1 === v2) {
            return 'gleich';
        }
        if (v1 < v2) {
            return 'hoch';
        }
        return 'runter';
    }

}
