<kiene-kiene-table (optionClicked)="catchOptionEvent($event)" [tableDescriptor]="tableDescriptor">
    <div toolbarElementTop>
        <button (click)="impfbuecherAbschliessenDialog()" *kienePermission="[4481]" [disabled]="!currentClient"
                [matTooltip]="'Impfkontrollbücher abschließen'" color="accent" mat-icon-button>
            <mat-icon [matBadgeHidden]="offeneImpfbuecher?.length < 1 || !currentClient"
                [matBadge]="offeneImpfbuecher?.length" matBadgeColor="warn">vaccines
            </mat-icon>
        </button>

        <button (click)="openWarenkorb()" *kienePermission="[4481]" [disabled]="!currentClient"
                [matTooltip]="'Warenkorb öffnen'"
                color="accent"[matTooltip]="'Warenkorb öffnen'" mat-icon-button>
            <mat-icon [matBadgeHidden]="warenkorbEintraege?.length < 1 || !currentClient"
                [matBadge]="warenkorbEintraege?.length" matBadgeColor="warn">shopping_cart
            </mat-icon>
        </button>
        <button (click)="openHotKeyDialog()" *kienePermission="[2682]"
                [matTooltip]="'Neue Belegvorlage erstellen'" color="accent" mat-icon-button>
            <mat-icon [matBadgeHidden]="true" [matBadgeSize]="'small'"
                      [matBadge]="gruppeTierBemerkungen?.length" matBadgeColor="warn">
                format_list_bulleted_add
            </mat-icon>
        </button>
        <button (click)="openGruppeTierBemerkungen()" *kienePermission="[9191]" [disabled]="!currentClient"
                [matTooltip]="'Bemerkungen bearbeiten'" color="accent" mat-icon-button>
            <mat-icon [matBadgeHidden]="gruppeTierBemerkungen?.length < 1 || !currentClient" [matBadgeSize]="'small'"
                      [matBadge]="gruppeTierBemerkungen?.length" matBadgeColor="warn">
                comment
            </mat-icon>
        </button>


    </div>
</kiene-kiene-table>
