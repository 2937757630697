import { HeimtiereModule } from "./heimtiere/heimtiere.module";
import { LayoutModule, MediaMatcher } from '@angular/cdk/layout';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from "@angular/material/expansion";
import {
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatFormFieldDefaultOptions,
    MatFormFieldModule
} from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BenachrichtigungenModule } from '../../projects/kiene-core/src/lib/benachrichtigungen/benachrichtigungen.module';
import { DebugDialogModule } from '../../projects/kiene-core/src/lib/components/debug-dialog/debug-dialog.module';
import { DirectivesModule } from '../../projects/kiene-core/src/lib/directives/directives.module';
import {
    API_BASE_URL_SERVICE,
    GIT_VERSIONS_FILE,
    SIGNATURE_URL_SERVICE
} from '../../projects/kiene-core/src/lib/kiene-core.config';
import { LocalStorageService } from '../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { environment } from '../environments/environment';
import { versions } from '../environments/versions';
import { KIENE_DATE_FORMATS, KieneDateAdapter } from './adapters/kiene-date-adapter';
import { AdministrationModule } from './administration/administration.module';

import { AuthGuard } from './app.auth.guard';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AppService } from './app.service';
import { ArticleScanAutocompleteService } from './components/article-scan-autocomplete/article-scan-autocomplete.service';
import { BetriebsartSelectorService } from './components/betriebsart-selector/betriebsart-selector.service';
import { ComponentsModule } from './components/components.module';
import { EntityViewerModule } from './components/entity-viewer/entity-viewer.module';
import { GoToHITButtonModule } from './components/go-to-hitbutton/go-to-hitbutton.module';
import { KieneTableModule } from "./components/kiene-table/kiene-table.module";
import { TabinfoModule } from './components/tabinfo/tabinfo.module';
import { TierartSelectorService } from './components/tierart-selector/tierart-selector.service';
import { VerkaufspreisSelectorService } from './components/verkaufspreis-selector/verkaufspreis-selector.service';
import { DialogsModule } from './dialogs/dialogs.module';
import { FileUploadService } from './dialogs/file-upload-dialog/file-upload.service';
import { DocumentsModule } from './documents/documents.module';
import { FinanzenModule } from './finanzen/finanzen.module';
import { GruppenModule } from './gruppen/gruppen.module';
import { FortbildungenModule } from './home/fortbildungen/fortbildungen.module';
import { HomeModule } from './home/home.module';
import { KieneErrorsHandler } from './interceptors/kiene.error.interceptor';
import { KieneMatPaginatorIntl } from './KieneMatPaginatorIntl';
import { DropExterneBefundmitteilungDirective } from './laboratory/drop-externe-befundmitteilung.directive';
import { LaboratoryModule } from './laboratory/laboratory.module';
import { TextProposalService } from './laboratory/text-autocomplete/text-proposal.service';
import { LoginModule } from './login/login.module';
import { LoginService } from './login/login.service';
import { NavigatorDialogModule } from './navigator-dialog/navigator-dialog.module';
import { RegisterModule } from './register/register.module';
import { EmailService } from './services/email.service';
import { HttpCancelService } from './services/http-cancel.service';

import { MessageService } from './services/message.service';
import { RecPositionService } from './services/rec-position.service';
import { StammdatenModule } from './stammdaten/stammdaten.module';
import { VersionInfoModule } from './version-info/version-info.module';
import { WaWiModule } from './wa-wi/wa-wi.module';
import { RabattStaffelService } from './warehouse/material/rabatt-staffel/rabatt-staffel.service';
import { WarehouseModule } from './warehouse/warehouse.module';
import { KieneSecurityInterceptor } from '../../projects/kiene-core/src/lib/interceptors/kiene.security.interceptor';


registerLocaleData(localeDe);

const appearance: MatFormFieldDefaultOptions = {
    appearance: 'outline'
};

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        DropExterneBefundmitteilungDirective,
    ],

    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        LayoutModule,
        MatToolbarModule,
        MatSidenavModule,
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        AppRoutingModule,
        FinanzenModule,
        LaboratoryModule,
        WaWiModule,
        DocumentsModule,
        WarehouseModule,
        StammdatenModule,
        AdministrationModule,
        DialogsModule,
        VersionInfoModule,
        FortbildungenModule,
        NavigatorDialogModule,
        EntityViewerModule,
        MatBadgeModule,
        TabinfoModule,
        MatGridListModule,
        GruppenModule,
        BenachrichtigungenModule,
        DebugDialogModule,
        GoToHITButtonModule,
        HomeModule,
        MatProgressBarModule,
        ComponentsModule,
        MatMenuModule,
        RegisterModule,
        LoginModule,
        MatCardModule,
        MatProgressSpinnerModule,
        DirectivesModule,
        MatCheckboxModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        KieneTableModule,
        HeimtiereModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: KieneSecurityInterceptor,
            multi: true
        },
        {
            provide: GIT_VERSIONS_FILE,
            useValue: versions,
        },
        AuthGuard,
        LoginService,
        MediaMatcher,
        { provide: LOCALE_ID, useValue: 'de' },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: DateAdapter, useClass: KieneDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: KIENE_DATE_FORMATS },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance },
        { provide: API_BASE_URL_SERVICE, useValue: environment.BASE_URI },
        { provide: SIGNATURE_URL_SERVICE, useValue: environment.SIGNATURE_URL },
        {
            provide: MatPaginatorIntl,
            useClass: KieneMatPaginatorIntl
        },
        {
            provide: ErrorHandler,
            useClass: KieneErrorsHandler
        },
        TierartSelectorService,
        TextProposalService,
        LocalStorageService,
        MessageService,
        RecPositionService,
        EmailService,

        FileUploadService,

        BetriebsartSelectorService,
        VerkaufspreisSelectorService,
        RabattStaffelService,
        ArticleScanAutocompleteService,
        AppService,
        DropExterneBefundmitteilungDirective,
        DatePipe,
        HttpCancelService
    ]
})
export class AppModule {
}
