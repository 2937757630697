import { Subscription } from 'rxjs';
import {
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { HttpParams } from '@angular/common/http';
import { KieneBackendApiService } from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    GewichteUndPreise,
    GruppeStandortAuswertung,
    WertAnzahl,
} from './gewichte-preise';
import { GruppenService } from '../../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import { KieneTableService } from '../../../../../src/app/components/kiene-table/kiene-table.service';
import { ParamsChanged } from '../../../../../src/app/components/kiene-table/table-descriptor';
import { LocalStorageService } from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-gewichte-preise',
    templateUrl: './gewichte-preise.component.html',
    styleUrls: ['./gewichte-preise.component.scss'],
})
export class GewichtePreiseComponent implements OnInit, OnChanges, OnDestroy {
    @Input('gruppe_id') gruppe_id: number;

    auswertungen: GruppeStandortAuswertung[] = [];
    userIstLandwirt = false;
    gruppeStandortLoading = false;
    gruppeStandortSubscription: Subscription;
    reloadGewichteUndPreiseSubscription: Subscription;
    readAuswertungenSubscriptions: Subscription[] = [];

    constructor(
        private messageService: MessageService,
        private api: KieneBackendApiService,
        private gruppenService: GruppenService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private tableService: KieneTableService,
        private localStorageService: LocalStorageService
    ) {
        this.userIstLandwirt =
            this.localStorageService.isCurrentUserKunde() ||
            this.localStorageService.isCurrentUserKundeAdmin();

        this.reloadGewichteUndPreiseSubscription = this.gruppenService
            .watchReloadGewichteUndPreise()
            .subscribe({
                next: () => {
                    this.ladeGruppenStandorte();
                },
            });

        this.tableService.watchParamsChanged().subscribe({
            next: (value: ParamsChanged) => {
                if (value) {
                    if (value.uniqueIdentifier === 'Gruppen-Gruppen-Tiere') {
                        this.ladeGruppenStandortAuswertungGefiltert(
                            value?.params
                        );
                    }
                }
            },
        });
    }

    ngOnDestroy(): void {
        this.gruppeStandortSubscription?.unsubscribe();
        this.reloadGewichteUndPreiseSubscription?.unsubscribe();
        for (const sub of this.readAuswertungenSubscriptions) {
            sub?.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.gruppe_id) {
            this.ladeGruppenStandorte();
        }
    }

    ngOnInit(): void { }

    ladeAuswertungen(a: GruppeStandortAuswertung) {
        a.isLoading = true;
        a.erfolgreich = false;
        let params = new HttpParams().set('gs_id', a.gruppeStandort?.gs_id);
        params = params.set('schlachtdaten', a.letzterDatensatz ? '1' : '0');
        params = params.set(
            'preise',
            a.letzterDatensatz || a.ersterDatensatz ? '1' : '0'
        );
        const sub = this.api
            .get(
                this.apiBaseUrl + 'gruppe_standort/read_auswertungen.php',
                params
            )
            .subscribe({
                next: (response: GruppeStandortAuswertung) => {
                    a.isLoading = false;
                    a.erfolgreich = true;
                    if (response) {
                        a.betrieb = response.betrieb;
                        a.unternehmensgruppe = response.unternehmensgruppe;
                        a.gruppe = response.gruppe;
                        a.gefiltert = response.gefiltert;
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fehler beim Laden des Auswertungen!',
                        err
                    );
                    a.isLoading = false;
                },
            });
        this.readAuswertungenSubscriptions.push(sub);
    }

    ladeGruppenStandortAuswertungGefiltert(alleParamsAusTabelle: HttpParams) {
        for (let a of this.auswertungen) {
            a.isLoadingGefiltert = true;
            let params = alleParamsAusTabelle.set(
                'gs_id',
                a.gruppeStandort?.gs_id
            );
            params = params.set(
                'schlachtdaten',
                a.letzterDatensatz ? '1' : '0'
            );
            params = params.set(
                'preise',
                a.letzterDatensatz || a.ersterDatensatz ? '1' : '0'
            );
            this.api
                .get(
                    this.apiBaseUrl +
                    'gruppe_standort/read_auswertungen_gefiltert.php',
                    params
                )
                .subscribe({
                    next: (response: GewichteUndPreise) => {
                        a.gefiltert = response;
                        a.isLoadingGefiltert = false;
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Fehler beim Laden des Auswertungen!',
                            err
                        );
                        a.isLoadingGefiltert = false;
                    },
                });
        }
    }

    ladeGruppenStandorte() {
        if (this.gruppe_id) {
            this.gruppeStandortLoading = true;
            this.gruppeStandortSubscription?.unsubscribe();
            this.auswertungen = [];
            this.gruppeStandortSubscription = this.api
                .get(
                    this.apiBaseUrl + 'gruppe_standort/read.php',
                    new HttpParams().set(
                        'gruppe_id',
                        this.gruppe_id?.toString()
                    )
                )
                .subscribe({
                    next: (response) => {
                        this.gruppeStandortLoading = false;
                        if (response?.records) {
                            let i = 1;
                            for (let r of response.records) {
                                const a = new GruppeStandortAuswertung();
                                a.gruppeStandort = r;
                                a.letzterDatensatz = i === response.recordcount;
                                a.ersterDatensatz = i === 1;
                                this.auswertungen.push(a);
                                this.ladeAuswertungen(a);
                                i++;
                            }
                        }
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Gruppen Standorte konnten nicht geladen werden!',
                            err
                        );
                        this.gruppeStandortLoading = false;
                    },
                });
        }
    }

    getClass(wa1: WertAnzahl, wa2: WertAnzahl, nachkommastellen: number) {
        const v1 = Math.round(wa1?.wert * Math.pow(10, nachkommastellen));
        const v2 = Math.round(wa2?.wert * Math.pow(10, nachkommastellen));
        if (!v1 || !v2) {
            return 'undefiniert';
        }
        if (v1 === v2) {
            return 'gleich';
        }
        if (v1 < v2) {
            return 'hoch';
        }
        return 'runter';
    }
}
