import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KieneTableService} from '../components/kiene-table/kiene-table.service';
import {BetriebsartSelectorService} from '../components/betriebsart-selector/betriebsart-selector.service';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatOptionModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDividerModule} from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatChipsModule} from '@angular/material/chips';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatStepperModule} from '@angular/material/stepper';
import {MatRadioModule} from '@angular/material/radio';
import {MatBadgeModule} from '@angular/material/badge';
import {KieneDirectivesModule} from '../directives/kiene-directives.module';
import {ChangeCurrentClientService} from '../administration/client/change-current-client/change-current-client.service';
import {MatToolbarModule} from '@angular/material/toolbar';
import {KieneAutocompleteModule} from '../components/kiene-autocomplete/kiene-autocomplete.module';
import {KieneClientAutocompleteModule} from '../components/client-autocomplete/client-autocomlete.module';
import {ArticleScanAutocompleteModule} from '../components/article-scan-autocomplete/article-scan-autocomplete.module';
import {DeleteButtonModule} from '../components/delete-button/delete-button.module';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {DirectivesModule} from '../../../projects/kiene-core/src/lib/directives/directives.module';


@NgModule({
    declarations: [
        // AnimalTypeSelectorComponent,
        // BetriebsartSelectorComponent,
        // // ClientAutocompleteComponent,
        // ClientGroupAutocompleteComponent,
        // ClientSelectorComponent,
        // CompanyGroupAutocompleteComponent,
        // EinheitSelectorComponent,
        // GroupAutocompleteComponent,
        // // KieneAutocompleteComponent,
        // KieneButtonComponent,
        // KieneIconButtonComponent,
        // KieneMenuButtonComponent,
        // MaterialAutocompleteComponent,
        // OptionButtonComponent,
        // StoragePlaceAutocompleteComponent,
        // // TierartSelectorComponent,
        // // TourenplanComponent,
        // // AddArticlePositionDialogComponent,
        // // AddTourDialogComponent,
        // // EditTourPageComponent,
        // // GoogleMapsRouteComponent,
        // VerkaufspreisSelectorComponent,
        // ChangeCurrentClientComponent,
        // ChangeCurrentClientDialogComponent,
        // DropFileDirective

    ],
    imports: [
        CommonModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatIconModule,
        MatBadgeModule,
        MatPaginatorModule,
        MatTabsModule,
        MatTableModule,
        MatSortModule,
        MatCardModule,
        MatSelectModule,
        MatDialogModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatDividerModule,
        MatListModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatChipsModule,
        MatTooltipModule,
        MatStepperModule,
        MatRadioModule,
        KieneDirectivesModule,
        MatToolbarModule,
        KieneAutocompleteModule,
        KieneClientAutocompleteModule,
        ArticleScanAutocompleteModule,
        DeleteButtonModule,
        MatSlideToggleModule,
        DirectivesModule
    ],
    exports: [
        CommonModule,
        // AnimalTypeSelectorComponent,
        // BetriebsartSelectorComponent,
        // // ClientAutocompleteComponent,
        // KieneClientAutocompleteModule,
        // ClientGroupAutocompleteComponent,
        // ClientSelectorComponent,
        // CompanyGroupAutocompleteComponent,
        // EinheitSelectorComponent,
        // GroupAutocompleteComponent,
        // // KieneAutocompleteComponent,
        // KieneAutocompleteModule,
        // KieneButtonComponent,
        // KieneIconButtonComponent,
        // KieneMenuButtonComponent,
        // MaterialAutocompleteComponent,
        // OptionButtonComponent,
        // StoragePlaceAutocompleteComponent,
        // // TierartSelectorComponent,
        // // TourenplanComponent,
        // // AddArticlePositionDialogComponent,
        // // AddTourDialogComponent,
        // // EditTourPageComponent,
        // // GoogleMapsRouteComponent,
        // VerkaufspreisSelectorComponent,
        // MatDatepickerModule,
        // MatCheckboxModule,
        // MatIconModule,
        // MatBadgeModule,
        // MatPaginatorModule,
        // MatTabsModule,
        // MatTableModule,
        // MatSortModule,
        // MatCardModule,
        // MatSelectModule,
        // MatDialogModule,
        // MatFormFieldModule,
        // FormsModule,
        // MatInputModule,
        // MatButtonModule,
        // ReactiveFormsModule,
        // MatOptionModule,
        // MatAutocompleteModule,
        // MatDividerModule,
        // MatListModule,
        // MatMenuModule,
        // MatProgressSpinnerModule,
        // MatProgressBarModule,
        // MatChipsModule,
        // MatTooltipModule,
        // MatStepperModule,
        // MatRadioModule,
        // KieneDirectivesModule,
        // ChangeCurrentClientComponent,
        // ChangeCurrentClientDialogComponent,
        // MatToolbarModule,
        // DropFileDirective,
    ],
    providers: [
        KieneTableService,
        BetriebsartSelectorService,
        ChangeCurrentClientService
    ]
})
export class SharedModule {
}
