import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {VerkaufspreisSelectorComponent} from './verkaufspreis-selector.component';


@NgModule({
    declarations: [VerkaufspreisSelectorComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule
    ],
    exports: [VerkaufspreisSelectorComponent]
})
export class VerkaufspreisSelectorModule {
}
