<h1 mat-dialog-title>Unterschrift</h1>
<div mat-dialog-content>
    <h3>Unterschriftenfeld</h3>
    <mat-hint class="hinweis-text">
        Die Unterschrift wird automatisch in das Unterschriftenfeld eines
        Dokuments ergänzt.
    </mat-hint>
    <div
        [style.borderColor]="isDrawing ? 'green' : '#ccc'"
        style="
            width: 100%;
            background-color: #fff;
            border: 1px solid;
            border-radius: 4px;
            margin-top: 12px;
        "
    >
        <div style="width: 100%; min-height: 200px" #signatureContainer>
            <signature-pad
                style="padding: 3px; border-radius: 4px"
                #signaturePad
                #signaturePadRef
                (onBeginEvent)="drawStart()"
                (onEndEvent)="drawEnd()"
            ></signature-pad>
        </div>
        <div
            class="one-row"
            style="justify-content: flex-end; border-top: 1px solid #ccc"
        >
            <button
                [disabled]="!this.signature.unterschrift_png"
                style="transform: scale(0.9)"
                mat-icon-button
                (click)="reload()"
            >
                <mat-icon color="accent">refresh</mat-icon>
            </button>
            <button
                style="transform: scale(0.9)"
                mat-icon-button
                (click)="clear()"
            >
                <mat-icon color="accent">delete</mat-icon>
            </button>
        </div>
    </div>
    <mat-hint class="hinweis-text" style="font-size: 0.75em">
        <strong>Hinweis: </strong>Für eine größere Schreibfläche kann der
        Gerätebildschirm gedreht werden.
    </mat-hint>
    <div
        style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 24px;
        "
    >

    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button
        color="accent"
        [disabled]="isSaving"
        (click)="saveUnterschrift()"
        mat-flat-button
    >
        Speichern
    </button>
</div>
