<h1 mat-dialog-title>Artikellieferant{{supplierMaterial ? ' '+supplierMaterial.lieferant : ''}} f&uuml;r
  {{material.bezeichnung}} {{create ? 'hinzufügen' : 'editieren'}}</h1>
<div mat-dialog-content>

  <div *ngIf="create">
    <kiene-autocomplete [apiUrl]="'lieferant/'" [placeholder]="'Lieferant'"
      [displayFields]="['bezeichnung','bezeichnung_1']" [separator]="' / '"
      (elementSelected)="setSupplier($event)">
    </kiene-autocomplete>
  </div>

  <mat-form-field>
    <mat-label>Einkaufspreis</mat-label>
    <input matInput type="number" [formControl]="preisCtrl">
  </mat-form-field>
  <br>
  <mat-form-field>
    <mat-label>Rabatt (%)</mat-label>
    <input matInput type="number" [formControl]="rabattCtrl">
  </mat-form-field>
  <br>
  <mat-checkbox [formControl]="standardCtrl">Standardlieferant</mat-checkbox>
  <div *kienePermission="[1095]">
    <div *ngIf="availableMandanten.length > 0" style="margin-top: 24px;">
      <mat-divider></mat-divider>
      <h5>Soll die &Auml;nderung f&uuml;r folgende Mandanten &uuml;bernommen werden?</h5>
      <ul style="list-style-type: none;">
        <li *ngFor="let m of availableMandanten">
          <mat-checkbox [formControl]="mandantCtrl[m.mandant_id]" (change)="updateSelectedMandanten()">{{m.bezeichnung}}
          </mat-checkbox>
        </li>
      </ul>
    </div>
  </div>


</div>
<div mat-dialog-actions>
  <span class="spacer"></span>
  <button mat-button (click)="close()">Abbrechen</button>
  <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</div>