import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Client} from '../../../../../lib/components/client/classes/client';

@Component({
    selector: 'kiene-vvvodetails-dialog',
    templateUrl: './vvvodetails-dialog.component.html',
    styleUrls: ['./vvvodetails-dialog.component.scss']
})
export class VVVODetailsDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public kunde: Client,
                public dialogRef: MatDialogRef<VVVODetailsDialogComponent>) {
    }

    ngOnInit(): void {
    }

}
