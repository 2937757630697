import {
    Component,
    OnInit,
    Input,
    OnChanges,
    SimpleChanges,
    Injectable,
    Output,
    EventEmitter,
} from "@angular/core";
import { Untersuchungsmaterial } from "../../../untersuchungsmaterialien/untersuchungsmaterial";
import { BehaviorSubject } from "rxjs";
import {
    Artikelstrukturelement,
    ArtikelstrukturelementFlatNode,
} from "../../probenbegleitschein";
import { Material } from "../../../../warehouse/material/material";
import { environment } from "../../../../../environments/environment";
import { FlatTreeControl } from "@angular/cdk/tree";
import { SelectionModel } from "@angular/cdk/collections";
import { ActivatedRoute } from "@angular/router";
import { KieneTableService } from "../../../../components/kiene-table/kiene-table.service";
import { MessageService } from "../../../../services/message.service";
import {
    MatTreeFlatDataSource,
    MatTreeFlattener,
} from "@angular/material/tree";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Injectable()
export class UntersuchungTreeDatabase {
    dataChange = new BehaviorSubject<Artikelstrukturelement[]>([]);

    get data(): Artikelstrukturelement[] {
        return this.dataChange.value;
    }

    set data(value: Artikelstrukturelement[]) {
        this.dataChange.next(value);
    }

    constructor() {
        this.initialize();
    }

    initialize() {
        // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
        //     file node as children.
        // const data = this.buildFileTree(TREE_DATA, 0);
        // Notify the change.
        // this.dataChange.next(data);
    }

    public initArtikelstrukturData(tree: Artikelstrukturelement[]) {
        //const data = this.buildFileTree(tree, 0);
        const data = tree;
        this.dataChange.next(data);
    }

    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `TodoItemNode`.
     */
    buildFileTree(
        obj: { [key: string]: any },
        level: number
    ): Artikelstrukturelement[] {
        return Object.keys(obj).reduce<Artikelstrukturelement[]>(
            (accumulator, key) => {
                const value = obj[key];
                const node = new Artikelstrukturelement();
                node.untersuchung = key;

                if (value != null) {
                    if (typeof value === "object") {
                        node.kinder = this.buildFileTree(value, level + 1);
                    } else {
                        node.untersuchung = value;
                    }
                }

                return accumulator.concat(node);
            },
            []
        );
    }

    /** Add an item to to-do list */
    insertItem(parent: Artikelstrukturelement, name: string) {
        if (!parent.kinder) {
            parent.kinder = [];
        }

        const a = new Artikelstrukturelement();
        a.untersuchung = name;
        a.kinder = [];
        parent.kinder.push(a);
        this.dataChange.next(this.data);
    }

    insertArtikelItem(parent: Artikelstrukturelement, name: string) {
        if (!parent.kinder) {
            parent.kinder = [];
        }

        const a = new Artikelstrukturelement();
        a.untersuchung = name;
        a.kinder = [];
        a.artikel_id = -1;
        parent.kinder.push(a);
        this.dataChange.next(this.data);
    }

    updateItem(node: Artikelstrukturelement, name: string) {
        node.untersuchung = name;
        this.dataChange.next(this.data);
    }

    deleteItem(node: Artikelstrukturelement) {
        let tree = this.dataChange.value;
        const index = tree.indexOf(node);
        tree.splice(index, 1);
        this.data = tree;
        this.dataChange.next(this.data);
    }

    updateItemWithArtikel(node: Artikelstrukturelement, artikel: Material) {
        node.untersuchung = "";
        node.artikel_id = artikel.artikel_id;
        node.artikel = artikel.artikel_me;
        node.lieferant = artikel.lieferant;
        node.lieferant_id = artikel.lieferant_id;
        this.dataChange.next(this.data);
        console.debug("UpdateItemWithArtikel: " + node.artikel_id);
    }
}

@Component({
    selector: "kiene-untersuchung-tree",
    templateUrl: "./untersuchung-tree.component.html",
    styleUrls: ["./untersuchung-tree.component.scss"],
})
export class UntersuchungTreeComponent implements OnInit, OnChanges {
    @Input("untersuchungsmaterial")
    untersuchungsmaterial: Untersuchungsmaterial;

    @Output("articleSelected") articleSelected = new EventEmitter<
        ArtikelstrukturelementFlatNode[]
    >();

    untersuchungsmaterialId: string;

    selectedNodes: ArtikelstrukturelementFlatNode[] = [];

    /** Map from flat node to nested node. This helps us finding the nested node to be modified */
    flatNodeMap = new Map<
        ArtikelstrukturelementFlatNode,
        Artikelstrukturelement
    >();

    /** Map from nested node to flattened node. This helps us to keep the same object for selection */
    nestedNodeMap = new Map<
        Artikelstrukturelement,
        ArtikelstrukturelementFlatNode
    >();

    /** A selected parent node to be inserted */
    selectedParent: ArtikelstrukturelementFlatNode | null = null;

    /** The new item's name */
    newItemName = "";

    treeControl: FlatTreeControl<ArtikelstrukturelementFlatNode>;

    treeFlattener: MatTreeFlattener<
        Artikelstrukturelement,
        ArtikelstrukturelementFlatNode
    >;

    dataSource: MatTreeFlatDataSource<
        Artikelstrukturelement,
        ArtikelstrukturelementFlatNode
    >;

    /** The selection for checklist */
    checklistSelection = new SelectionModel<ArtikelstrukturelementFlatNode>(
        true /* multiple */
    );

    artikelstruktur: any[] = [];

    constructor(
        private route: ActivatedRoute,
        private api: KieneTableService,
        private notification: MessageService,
        private _database: UntersuchungTreeDatabase
    ) {
        this.untersuchungsmaterialId = this.route.snapshot.paramMap.get("id");

        this.treeFlattener = new MatTreeFlattener(
            this.transformer,
            this.getLevel,
            this.isExpandable,
            this.getChildren
        );
        this.treeControl = new FlatTreeControl<ArtikelstrukturelementFlatNode>(
            this.getLevel,
            this.isExpandable
        );
        this.dataSource = new MatTreeFlatDataSource(
            this.treeControl,
            this.treeFlattener
        );

        _database.dataChange.subscribe((data) => {
            this.dataSource.data = data;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.untersuchungsmaterial) {
            this.loadUntersuchungsmaterial();
        }
    }

    ngOnInit() {
        if (this.untersuchungsmaterial) {
            this.loadUntersuchungsmaterial();
        }
    }

    ngOnDestroy() {
        this._database.data = [];
    }

    loadUntersuchungsmaterial() {
        this.selectedNodes = [];
        this.artikelstruktur = this.untersuchungsmaterial.artikelstruktur;
        this.initUntersuchungsmaterial();
        this._database.initArtikelstrukturData(this.artikelstruktur);
        this.treeControl.expandAll();
    }

    initUntersuchungsmaterial() {
        if (!this.artikelstruktur) {
            this.artikelstruktur = [];
        }

        for (let a of this.artikelstruktur) {
            if (!a.kinder) {
                a.kinder = [];
            }
        }
    }

    getLevel = (node: ArtikelstrukturelementFlatNode) => node.level;

    isExpandable = (node: ArtikelstrukturelementFlatNode) => node.expandable;

    getChildren = (node: Artikelstrukturelement): Artikelstrukturelement[] =>
        node.kinder;

    hasChild = (_: number, _nodeData: ArtikelstrukturelementFlatNode) =>
        _nodeData.expandable && !_nodeData.artikel_id;

    hasNoContent = (_: number, _nodeData: ArtikelstrukturelementFlatNode) =>
        _nodeData.untersuchung === "" && !_nodeData.artikel_id;

    isNewArtikelNode = (_: number, _nodeData: ArtikelstrukturelementFlatNode) =>
        _nodeData.untersuchung === "" && _nodeData.artikel_id === -1;

    isArtikelNode = (_: number, _nodeData: ArtikelstrukturelementFlatNode) =>
        (!_nodeData.untersuchung || _nodeData.untersuchung === "") &&
        _nodeData.artikel_id &&
        _nodeData.artikel_id > -1;

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: Artikelstrukturelement, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode =
            existingNode && existingNode.untersuchung === node.untersuchung
                ? existingNode
                : new ArtikelstrukturelementFlatNode();
        flatNode.untersuchung = node.untersuchung;
        flatNode.artikel = node.artikel;
        flatNode.artikel_id = node.artikel_id;
        flatNode.lieferant = node.lieferant;
        flatNode.level = level;

        flatNode.expandable = !!node.kinder;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    };

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: ArtikelstrukturelementFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every((child) =>
            this.checklistSelection.isSelected(child)
        );
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(
        node: ArtikelstrukturelementFlatNode
    ): ArtikelstrukturelementFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    save() {
        this.untersuchungsmaterial.artikelstruktur = this._database.data;
        this.api
            .updateEntity(
                environment.BASE_URI + "untersuchungsmaterial/update.php",
                this.untersuchungsmaterial
            )
            .subscribe(
                (response) => {
                    this.notification.infoMessage(
                        "Untersuchungsmaterial erfolgreich gespeichert!"
                    );
                },
                (error) => {
                    this.notification.errorMessage(
                        "Fehler beim Speichern: ",
                        error
                    );
                }
            );
    }

    deleteNode(node: ArtikelstrukturelementFlatNode) {
        let elem = this.flatNodeMap.get(node);
        this._database.deleteItem(elem);
    }

    isCheckboxChecked(node: ArtikelstrukturelementFlatNode) {
        return false;
    }

    checkboxChanged(
        node: ArtikelstrukturelementFlatNode,
        event: MatCheckboxChange
    ) {
        if (event.checked) {
            this.selectedNodes.push(node);
        } else {
            const index = this.selectedNodes.indexOf(node);
            this.selectedNodes.splice(index, 1);
        }
        console.debug(this.selectedNodes.toString());
        this.articleSelected.emit(this.selectedNodes);
    }
}
