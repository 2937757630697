import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ArticleScanSelectedEvent } from "../../../components/article-scan-autocomplete/article-scan-autocomplete.component";
import { Verkaufspreis } from "../../../components/verkaufspreis-selector/verkaufspreis-selector.component";
import { Material } from "../../../warehouse/material/material";
import { CompanyGroup, CompanyGroupArticle } from "../company-group";

@Component({
    selector: "kiene-company-group-article-dialog",
    templateUrl: "./company-group-article-dialog.component.html",
    styleUrls: ["./company-group-article-dialog.component.scss"],
})
export class CompanyGroupArticleDialogComponent implements OnInit {
    companyGroup = new CompanyGroup();
    cga = new CompanyGroupArticle();
    preisCtrl = new UntypedFormControl();
    artikel = new Material();

    constructor(
        public dialogRef: MatDialogRef<CompanyGroupArticleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data
    ) {
        this.companyGroup = this.data.companyGroup;
        if (this.data.cga) {
            this.cga = this.data.cga;
        }
    }

    ngOnInit(): void {
        if (this.cga.ua_id) {
            this.preisCtrl.setValue(this.cga.einzelpreis);
        }
    }

    setMaterial(asse: ArticleScanSelectedEvent) {
        this.artikel = asse.article;
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        this.cga.unternehmensgruppe_id =
            this.companyGroup.unternehmensgruppe_id;
        this.cga.artikel_id = this.artikel.artikel_id;
        this.cga.einzelpreis = this.preisCtrl.value;
        this.dialogRef.close(this.cga);
    }
}
