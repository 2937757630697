<h1 mat-dialog-title>Ma&szlig;nahmenplan CSV Upload</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Jahr</mat-label>
        <input matInput type="number" maxlength="4" minlength="4" [formControl]="jahrCtrl">
    </mat-form-field>
    <br>
    <mat-form-field>
        <mat-label>Halbjahr</mat-label>
        <mat-select [formControl]="halbjahrCtrl">
            <mat-option [value]="1">1</mat-option>
            <mat-option [value]="2">2</mat-option>
        </mat-select>
    </mat-form-field>
    <br>
    <div>
        <p>
            Die CSV-Datei muss folgendermaßen aufgebaut sein:<br>
            - 1. Spalte (A): VVVO Nummer<br>
            - 7. Spalte (G): Tierart (RM1)<br>
            - 9. Spalte (I): Kennzahl<br>
            - 10. Spalte (J): Eingruppierung (4Q)
        </p>
        <button mat-flat-button color="accent" (click)="fileSelector.click()"><mat-icon>attach_file</mat-icon> CSV Datei ausw&auml;hlen</button>
      <input type="file" style="display: none" #fileSelector multiple (change)="onFileSelected($event)" accept="text/csv">
      <p *ngIf="csvFile">Ausgew&auml;hlte Datei: {{fileName}}</p>
    </div>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="upload()">Hochladen</button>
</div>