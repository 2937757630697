import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    Betriebsart,
    BetriebsartMandant
} from '../../../../../projects/kiene-core/src/lib/components/stammdaten/vvvo/classes/betriebsart';
import {FormControl} from '@angular/forms';
import {Verkaufspreis} from '../../../components/verkaufspreis-selector/verkaufspreis-selector.component';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {MessageService} from '../../../services/message.service';

@Component({
    selector: 'kiene-betriebsart-preise-und-rabatt-dialog',
    templateUrl: './betriebsart-preise-und-rabatt-dialog.component.html',
    styleUrls: ['./betriebsart-preise-und-rabatt-dialog.component.scss']
})
export class BetriebsartPreiseUndRabattDialogComponent implements OnInit {

    rabattCtrl = new FormControl();
    aktuellerVk: Verkaufspreis;
    neuerVerkaufspreis: Verkaufspreis;

    constructor(@Inject(MAT_DIALOG_DATA) public betriebsart: Betriebsart,
                public dialogRef: MatDialogRef<BetriebsartPreiseUndRabattDialogComponent>,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.rabattCtrl.setValue(this.betriebsart.rabatt);
        this.aktuellerVk = new Verkaufspreis();
        this.aktuellerVk.verkaufspreis_id = this.betriebsart.verkaufspreis_id;
        this.aktuellerVk.bezeichnung = this.betriebsart.verkaufspreis;
    }

    setVerkaufspreis(event: Verkaufspreis) {
        this.neuerVerkaufspreis = event;
    }

    save() {
        const bm = new BetriebsartMandant();
        bm.betriebsart_id = this.betriebsart.betriebsart_id;
        bm.rabatt = this.rabattCtrl.value;
        bm.verkaufspreis_id = this.neuerVerkaufspreis.verkaufspreis_id;

        this.api.post(this.apiBaseUrl + 'betriebsart_admin/update.php', bm).subscribe({
            next: (result) => {
                this.messageService.infoMessage('Verkaufspreis und Rabatt wurden erfolgreich gespeichert!');
                this.dialogRef.close(true);
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Speichern der Daten!', err);
            }
        });
    }
}
