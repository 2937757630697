import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DokumentFillOutElement } from '../dokumente.component';
import { UntypedFormControl } from '@angular/forms';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../services/message.service';
import { HttpParams } from '@angular/common/http';
import { Dokument } from '../dokument';
import { Vet } from '../../../administration/vet/vet';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { Signature } from '../../../../../projects/kiene-core/src/lib/components/unterschriften/signature';
import {
    API_BASE_URL_SERVICE,
    SIGNATURE_URL_SERVICE
} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-create-dokument-dialog',
    templateUrl: './create-dokument-dialog.component.html',
    styleUrls: ['./create-dokument-dialog.component.scss'],
})
export class CreateDokumentDialogComponent implements OnInit {
    elements: DokumentFillOutElement[] = [];
    title = '';
    dokumentTyp: number[] = [];

    // selectedTierarzt: Vet = null;
    tierarztGesetzt = false;

    formControls: UntypedFormControl[] = [];

    apiUrl: string;

    signature = new Signature();

    qrcodeUrl: string;

    constructor(
        public dialogRef: MatDialogRef<CreateDokumentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        @Inject(SIGNATURE_URL_SERVICE) private signatureUrl: string,
        private api: KieneTableService,
        private notification: MessageService,
        private pdfService: PdfViewerDesktopService
    ) {
        this.elements = data.elements;
        this.title = data.title;
        this.dokumentTyp = data.dokumentTyp;
        this.signature.kunde_id = data.kunde_id;
        this.apiUrl = data.apiUrl;
        this.signature.tierarzt_id = data.tierarzt_id;
        if (this.signature.tierarzt_id) {
            this.tierarztGesetzt = true;
        }
    }

    ngOnInit() {
        for (const e of this.elements) {
            if (this.formControls[e.name]) {
                this.formControls[e.name].setValue(
                    e.defaultValue ? e.defaultValue : null
                );
            } else {
                this.formControls[e.name] = new UntypedFormControl(
                    e.defaultValue ? e.defaultValue : null
                );
            }

            if (e.type === 'selection') {
                if (e.preSelectValue) {
                    this.formControls[e.name].setValue(e.preSelectValue);
                }
            }

            if (e.type === 'disabledText') {
                this.formControls[e.name].disable();
            }

            if (e.listener && e.listener.length > 0) {
                for (const l of e.listener) {
                    if (!this.formControls[l.listenerElement]) {
                        this.formControls[l.listenerElement] =
                            new UntypedFormControl();
                    }
                    this.formControls[l.listenerElement].valueChanges.subscribe(
                        (value) => {
                            if (
                                value === l.listenerValue ||
                                l.listenerValue === null
                            ) {
                                if (e.type === 'disabledText') {
                                    if (!l.value) {
                                        this.formControls[e.name].enable();
                                    }
                                } else {
                                    this.formControls[e.name].setValue(l.value);
                                }
                            }
                        }
                    );
                }
            }
        }
        this.prepareSigning();
    }

    setSelectedTierarzt(tierarzt: Vet) {
        // this.selectedTierarzt = tierarzt;
        this.signature.tierarzt_id = tierarzt.tierarzt_id;
        this.prepareSigning();
    }

    prepareSigning() {
        this.signature.kuid = null;
        this.api
            .post(this.apiBaseUrl + 'unterschrift/create.php', this.signature)
            .subscribe(
                (response) => {
                    this.signature = response;
                    this.qrcodeUrl =
                        this.signatureUrl +
                        'sign/' +
                        this.signature.kuid;
                },
                (error) => {
                    if (error.status === 400) {
                        this.notification.alertMessage(error.error.errortext);
                    } else {
                        this.notification.errorMessage(
                            'Es ist ein Fehler aufgetreten: ',
                            error
                        );
                    }
                    this.dialogRef.close(null);
                }
            );
    }

    createPdf() {
        let url = '';
        let dokType = 99;

        if (this.dokumentTyp.length === 1) {
            dokType = this.dokumentTyp[0];
        } else if (this.dokumentTyp.length > 1) {
            dokType = 7;
        }

        const arr = [];

        for (const e of this.elements) {
            const fc: UntypedFormControl = this.formControls[e.name];
            if (e.type === 'checkbox') {
                arr[e.name] = fc.value === true ? 1 : 0;
            } else {
                arr[e.name] = fc.value;
            }
        }

        if (dokType === 7) {
            arr['beleg_betreuungsvertrag'] = 0;
            arr['beleg_betreuungsvertrag_qs'] = 0;
            arr['beleg_futter'] = 0;
            arr['beleg_wasser'] = 0;
            arr['beleg_meldeverpflichtung_qs'] = 0;
            arr['beleg_risikomanagement'] = 0;

            for (const d of this.dokumentTyp) {
                if (d === 1) {
                    arr['beleg_betreuungsvertrag'] = 1;
                } else if (d === 2) {
                    arr['beleg_betreuungsvertrag_qs'] = 1;
                } else if (d === 3) {
                    arr['beleg_futter'] = 1;
                } else if (d === 4) {
                    arr['beleg_wasser'] = 1;
                } else if (d === 5) {
                    arr['beleg_meldeverpflichtung_qs'] = 1;
                } else if (d === 6) {
                    arr['beleg_risikomanagement'] = 1;
                }
            }
        }

        arr['kuid'] = this.signature.kuid;
        arr['kunde_id'] = this.signature.kunde_id;
        arr['tierarzt_id'] = this.signature.tierarzt_id;

        const obj = { ...arr };
        switch (dokType) {
            case 1:
                url = 'beleg/pdf_betreuungsvertrag.php';
                break;
            case 2:
                url = 'beleg/pdf_betreuungsvertrag_qs.php';
                break;
            case 3:
                url = 'beleg/pdf_futter.php';
                break;
            case 4:
                url = 'beleg/pdf_wasser.php';
                break;
            case 5:
                url = 'beleg/pdf_meldeverpflichtung_qs.php';
                break;
            case 6:
                url = 'beleg/pdf_risikomanagement.php';
                break;
            case 7:
                url = 'beleg/pdf_gesamt.php';
                break;
        }

        const params = new HttpParams().append(
            'kunde_id',
            this.signature.kunde_id.toString()
        );

        this.api.post(this.apiBaseUrl + url, obj, params).subscribe(
            (response) => {
                const dokument: Dokument = response;
                if (dokType !== 7) {
                    const input = new PdfViewerDialogInput(
                        'dokument/read_or_mail_pdf.php',
                        new HttpParams().set(
                            'dokument_id',
                            dokument?.dokument_id?.toString()
                        ),
                        this.title
                    );
                    this.pdfService.openPdfViewer(input);
                }
                this.dialogRef.close();
            },
            (error) => {
                this.notification.errorMessage('', error);
            }
        );
    }
}
