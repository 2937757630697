import {Component, ElementRef, Inject, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BestandsbuchService} from '../bestandsbuch.service';
import {BestandsbuchArtikel} from '../classes/bestandsbuch-artikel';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
    BestandsbuchArtikelTierNachtragenDialogComponent
} from '../bestandsbuch-artikel-tier-nachtragen-dialog/bestandsbuch-artikel-tier-nachtragen-dialog.component';
import {BestandsbuchArtikelTier} from '../classes/bestandsbuch-artikel-tier';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {UnterschriftService} from '../../components/stammdaten/unterschrift/unterschrift.service';
import {BestandsbuchArtikelAnwendung} from '../classes/bestandsbuch-artikel-anwendung';
import {MessageService} from '../../services/message-service/message.service';
import {LocalStorageService} from '../../services/local-storage/local-storage.service';
import {Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'kiene-bestandsbuch-artikel-details',
    templateUrl: './bestandsbuch-artikel-details.component.html',
    styleUrls: ['./bestandsbuch-artikel-details.component.scss']
})
export class BestandsbuchArtikelDetailsComponent implements OnInit, OnChanges, OnDestroy {

    bestandsbuchArtikel: BestandsbuchArtikel;

    bestandsbuchTyp: number;
    openTiere = [];
    isTierarzt: boolean;
    bestandsbuchArtikelSubscription: Subscription;

    zeigeEinzelneTiereCtrl = new FormControl(false);

    @ViewChild('tel') vetTelefon: ElementRef;
    isLoading = false;

    constructor(@Inject(MAT_DIALOG_DATA) private data, public dialogRef: MatDialogRef<BestandsbuchArtikelDetailsComponent>, private route: ActivatedRoute, private bestandsbuchService: BestandsbuchService, private dialog: MatDialog,
                private router: Router, private unterschriftService: UnterschriftService, private messageService: MessageService,
                private localStorageService: LocalStorageService) {
        this.bestandsbuchArtikel = this.data.artikel;
        this.bestandsbuchTyp = this.data.typ;
    }

    ngOnDestroy(): void {
        this.bestandsbuchArtikelSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.checkUserIsTierarzt();
        this.openTiere = new Array(this.bestandsbuchArtikel.anzahl_tiere - (this.bestandsbuchArtikel.tiere ? this.bestandsbuchArtikel.tiere.length : 0));
        if (this.bestandsbuchArtikel.tiere?.length > 0) {
            this.zeigeEinzelneTiereCtrl.setValue(true);
            this.zeigeEinzelneTiereCtrl.disable();
        }
        if (this.bestandsbuchTyp === 2) {
            if (this.bestandsbuchArtikel?.anwendungen?.length === 0) {
                this.anwendungHinzufuegen();
            }
        }
    }

    ngOnChanges() {
    }


    addTier(tier?: any) {

        const dialogRef = this.dialog.open(BestandsbuchArtikelTierNachtragenDialogComponent, {
            width: '500px',
            data: {
                tier: tier,
                gruppeId: this.bestandsbuchArtikel.gruppe_id,
            }
        });

        dialogRef.afterClosed().subscribe((result: BestandsbuchArtikelTier) => {
            if (result) {
                if (tier) {
                    // replace
                    const index = this.bestandsbuchArtikel.tiere.findIndex(t => t.bat_id === tier.bat_id);
                    this.bestandsbuchArtikel.tiere[index] = result;
                } else {
                    // add
                    this.bestandsbuchArtikel.tiere.push(result);
                }
                this.updateBestandsbuchArtikel();
            }
        });


    }

    updateBestandsbuchArtikel(index?: number) {
        if (index !== undefined && index !== null && index > -1) {
            const anz = this.bestandsbuchArtikel.anwendungen[index]?.anzahl_tiere;
            const datum = this.bestandsbuchArtikel?.anwendungen[index]?.datum;
            console.log('anz und datum: ' + anz + ' : ' + datum);
            if (!anz || !datum) {
                return;
            }
        }

        this.bestandsbuchService.updateBestandsbuchArtikel(this.bestandsbuchArtikel).subscribe({
            next: (bestandsbuchArtikel) => {
                this.bestandsbuchArtikel = <BestandsbuchArtikel>bestandsbuchArtikel;
                this.openTiere = new Array(this.bestandsbuchArtikel.anzahl_tiere - this.bestandsbuchArtikel.tiere.length);
                if (this.openTiere.length < this.bestandsbuchArtikel.anzahl_tiere) {
                    this.zeigeEinzelneTiereCtrl.setValue(true);
                    this.zeigeEinzelneTiereCtrl.disable();
                }
                this.messageService.infoMessage('Änderungen gespeichert.');
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler!', err);
                this.ladeBestandsbuchArtikel();
            }
        });
    }

    protokollieren($event: MatCheckboxChange, anwendung: BestandsbuchArtikelAnwendung) {
        if (this.isUserAllowed()) {
            anwendung.angewendet = $event.checked ? 1 : 0;
            this.updateBestandsbuchArtikel();
        }
    }

    isInFuture(datum: Date) {
        const now = new Date();
        const d = new Date(datum);
        now.setHours(0, 0, 0, 0);
        d.setHours(0, 0, 0, 0);
        return d > now;
    }

    hasValidPhoneNumber() {
        return this.bestandsbuchArtikel.telefon || this.bestandsbuchArtikel.mobil;
    }

    isUserAllowed() {
        return this.bestandsbuchArtikel.behandlung === 0 || this.isTierarzt;
    }

    impfungEingetragen(anwendung: BestandsbuchArtikelAnwendung) {
        if (anwendung.datum) {
            anwendung.angewendet = 1;
            this.updateBestandsbuchArtikel();
        }
    }

    anwendungHinzufuegen() {
        const a = new BestandsbuchArtikelAnwendung();
        a.angewendet = 0;
        this.bestandsbuchArtikel.anwendungen.push(a);
    }

    summeAnzahlTiereAnwendungen() {
        let anzahlTiere = 0;
        for (const a of this.bestandsbuchArtikel?.anwendungen) {
            if (a.anzahl_tiere) {
                anzahlTiere += a.anzahl_tiere;
            }
        }
        return anzahlTiere;
    }

    private checkUserIsTierarzt() {
        this.isTierarzt = this.localStorageService.isCurrentUserVeterinarian();
    }

    private ladeBestandsbuchArtikel() {
        this.bestandsbuchService.getBestandsbuchArtikel(this.bestandsbuchArtikel.bestandsbuch_artikel_id).subscribe({
            next: response => {
                this.bestandsbuchArtikel = response;
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Laden vom Bestandsbuch-Artikel!', err);
            }
        });
    }
}
