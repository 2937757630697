<div mat-dialog-title>
    <h3>
        {{
        this.offeneUrlaubsantraege.length > 1
            ? "Urlaubsanträge"
            : "Urlaubsantrag"
        }}
        {{ hasUrlaubGenehmigenPermission ? "genehmigen" : "bearbeiten" }}
    </h3>
</div>
<div #content mat-dialog-content>

    <div
        *ngIf="isLoading"
        style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 24px 0 24px 0;
        "
    >
        <mat-hint class="hinweis-text">Lade offene Urlaubsanträge...</mat-hint>
    </div>

    <div *ngIf="!isLoading">
        <div
            *ngIf="this.offeneUrlaubsantraege.length === 0"
            style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 24px 0 24px 0;
        "
        >
            <mat-hint class="hinweis-text">Es gibt aktuell keine offenen Urlaubsanträge.</mat-hint>
        </div>

        <table *ngIf="this.offeneUrlaubsantraege.length > 0" style="width: 100%">
            <tr>
                <th>Zeitraum</th>
                <th>Antragsteller</th>
                <th>Anmerkung</th>
                <th></th>
                <th></th>
            </tr>
            <tr *ngFor="let urlaubsantrag of this.offeneUrlaubsantraege">
                <td style="padding-right: 12px">
                    <div style="margin-bottom: 12px">
                        <mat-form-field class="no-padding-margin-mat-form-field" style="width: 100%;">
                            <mat-label>Zeitraum wählen</mat-label>
                            <mat-date-range-input #zeitraum [rangePicker]="picker">
                                <input
                                    (dateChange)="
                                    startDatumChanged($event, urlaubsantrag)
                                "
                                    (dateInput)="
                                    startDatumChanged($event, urlaubsantrag)
                                "
                                    [value]="urlaubsantrag.startdatum"
                                    matStartDate
                                    placeholder="Startdatum"
                                />
                                <input
                                    (dateChange)="
                                    endDatumChanged($event, urlaubsantrag)
                                "
                                    (dateInput)="
                                    endDatumChanged($event, urlaubsantrag)
                                "
                                    [value]="urlaubsantrag.enddatum"
                                    matEndDate
                                    placeholder="Enddatum"
                                />
                            </mat-date-range-input>
                            <mat-datepicker-toggle
                                [for]="picker"
                                matSuffix
                            ></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>

                        <div *ngIf="urlaubsantrag.startdatum && urlaubsantrag.enddatum" class="one-row">
                            <div *ngIf="sameDates(urlaubsantrag.startdatum, urlaubsantrag.enddatum)">
                                <mat-checkbox
                                    (change)="updateHalbtags($event, urlaubsantrag)"
                                    [checked]="urlaubsantrag.startdatum_halbtags === 1 && urlaubsantrag.enddatum_halbtags === 1">
                                    halbtags
                                </mat-checkbox>
                            </div>

                            <div *ngIf="!sameDates(urlaubsantrag.startdatum, urlaubsantrag.enddatum)" class="one-row"
                                 style="justify-content: space-between">
                                <mat-checkbox
                                    (change)="$event.checked ? urlaubsantrag.startdatum_halbtags = 1 : urlaubsantrag.startdatum_halbtags = 0; changed = true "
                                    [checked]="urlaubsantrag.startdatum_halbtags === 1">
                                    halbtags {{urlaubsantrag.startdatum | date: 'dd.MM.YYYY'}}
                                </mat-checkbox>
                                <mat-checkbox
                                    (change)="$event.checked ? urlaubsantrag.enddatum_halbtags = 1 : urlaubsantrag.enddatum_halbtags = 0; changed = true "
                                    [checked]="urlaubsantrag.enddatum_halbtags === 1">
                                    halbtags {{urlaubsantrag.enddatum | date: 'dd.MM.YYYY'}}
                                </mat-checkbox>
                            </div>
                        </div>
                        <button (click)="update(urlaubsantrag)" *ngIf="changed" color="accent" mat-flat-button>&Auml;nderung
                            speichern
                        </button>
                    </div>
                </td>
                <td>{{ urlaubsantrag.benutzername }}</td>
                <td>{{ urlaubsantrag?.bemerkung }}</td>
                <td>
                    <button
                        (click)="updateAntrag(urlaubsantrag, 1)"
                        *ngIf="hasUrlaubGenehmigenPermission"
                        color="accent"
                        mat-icon-button
                        matTooltip="Genehmigen"
                    >
                        <mat-icon>done</mat-icon>
                    </button>
                    <button
                        (click)="updateAntrag(urlaubsantrag, urlaubsantrag.genehmigt)"
                        *ngIf="!hasUrlaubGenehmigenPermission"
                        color="accent"
                        mat-icon-button
                        matTooltip="Aktualisieren"
                    >
                        <mat-icon>done</mat-icon>
                    </button>
                </td>
                <td>
                    <button
                        (click)="updateAntrag(urlaubsantrag, -1)"
                        *ngIf="hasUrlaubGenehmigenPermission"
                        color="warn"
                        mat-icon-button
                        matTooltip="Ablehnen"
                    >
                        <mat-icon>close</mat-icon>
                    </button>

                    <button
                        (click)="deleteAntrag(urlaubsantrag)"
                        *ngIf="!hasUrlaubGenehmigenPermission"
                        color="warn"
                        mat-icon-button
                        matTooltip="Stornieren"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </td>
            </tr>
        </table>
    </div>

</div>
<div mat-dialog-actions>
    <button (click)="onNoClick()" mat-button>Schließen</button>
</div>
