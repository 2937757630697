<form (ngSubmit)="save()">
    <h1 mat-dialog-title>Artikelliste "{{ input?.artikelliste?.listenname }}" ausfüllen</h1>
    <div mat-dialog-content>
        <h4>Artikel</h4>
        <p *ngFor="let a of input?.artikelliste?.artikel; let i = index">{{ i + 1 }}. {{ a.artikel_me }} {{
                a.tierart ? ('(' +
                    a.tierart + ')') : ''
            }}</p>
        <mat-form-field style="width: 100%">
            <mat-label>Anzahl Tiere</mat-label>
            <input type="number" matInput [formControl]="anzahlTiereCtrl"/>
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <mat-label>Tiergewicht</mat-label>
            <input type="number" matInput [formControl]="tiergewichtCtrl"/>
        </mat-form-field>
        <kiene-autocomplete (elementSelected)="selectIdentifikation($event)" [apiUrl]="'identifikation/'"
                            [params]="params" [showClear]="false"
                            [displayFields]="['bezeichnung']" [placeholder]="'Identifikation'" [focus]="false"
                            [emitFreeText]="true"></kiene-autocomplete>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button type="button" (click)="cancel()">Abbrechen</button>
        <button mat-flat-button color="accent" type="submit"
                [disabled]="!anzahlTiereCtrl.value || !tiergewichtCtrl.value || !result?.identifikation">Beleg ausf&uuml;llen
        </button>
    </div>
</form>
