import {Component, Inject, OnInit} from '@angular/core';
import {TableDescriptor} from '../../../components/kiene-table/table-descriptor';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MessageService} from '../../../services/message.service';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {TableColumn} from '../../../components/kiene-table/table-column';
import {TableOption, TableOptionEvent} from '../../../components/kiene-table/table-option';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {MandantDetailsDialogComponent} from '../mandant-details-dialog/mandant-details-dialog.component';
import {Mandant} from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'kiene-mandanten',
    templateUrl: './mandanten.component.html',
    styleUrls: ['./mandanten.component.scss']
})
export class MandantenComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();


    constructor(public dialog: MatDialog,
                private router: Router,
                private messageService: MessageService,
                private localStorageService: LocalStorageService,
                private tableService: KieneTableService,
                private api: KieneBackendApiService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    ngOnInit() {

        this.tableDescriptor.initialSortColumn = 'bezeichnung';
        this.tableDescriptor.initialSortDirection = 'asc';

        this.tableDescriptor.headline = 'Mandanten';
        this.tableDescriptor.uniqueIdentifier = 'mandanten';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'mandant/';
        this.tableDescriptor.route = '/administration/mandanten/';
        this.tableDescriptor.nameOfIdField = 'mandant_id';
        this.tableDescriptor.searchPlaceholder = 'Mandanten durchsuchen';

        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.showDetailsAsOption = true;


        this.tableDescriptor.columns.push(new TableColumn('mandant_id', 'ID'));
        this.tableDescriptor.columns.push(new TableColumn('bezeichnung', 'Mandant'));
        this.tableDescriptor.columns.push(new TableColumn('editiert', 'Editiert', 'datetime'));


        this.tableDescriptor.permissionView = 2001;
        this.tableDescriptor.permissionCreate = 2001;
        this.tableDescriptor.permissionUpdate = 2001;
        this.tableDescriptor.permissionDelete = 2001;

        this.tableDescriptor.options.push(new TableOption('edit', 'editieren', 'edit', 4022));
        this.tableDescriptor.options.push(new TableOption('artikel_kopieren', 'Stammdaten erzeugen', 'create_new_folder', 4022));


        this.tableDescriptor.initialSortColumn = 'mandant_id';
        this.tableDescriptor.initialSortDirection = 'asc';

    }


    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add' || event.name === 'edit' || event.name === 'details') {
            this.openDetailsDialog(event);
        } else if (event.name === 'artikel_kopieren') {
            this.copyArticles(event);
        }
    }

    openDetailsDialog(event: TableOptionEvent) {
        let mandant: Mandant = event.value;
        if (!mandant) {
            mandant = new Mandant();
        }
        let dialogRef = this.dialog.open(MandantDetailsDialogComponent, {
            data: mandant
        });

        dialogRef.afterClosed().subscribe(result => {
            this.tableService.reload();
        });
    }

    private copyArticles(event: TableOptionEvent) {
        let mandant: Mandant = event.value;
        if (!mandant?.mandant_id) {
            return;
        }
        this.api.post(this.apiBaseUrl + 'mandant/artikel_kopieren.php', mandant).subscribe({
            next: (response) => {
                this.messageService.infoMessage('Stammdaten wurden kopiert');
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Kopieren der Stammdaten', err);
            }
        });
    }
}
