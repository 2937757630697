import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { KieneKeyValue, TableDescriptor, ToolbarButton } from '../../components/kiene-table/table-descriptor';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '../../services/message.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import {
    EmailGesendetTableColumn,
    GedrucktTableColumn,
    KieneBooleanTableColumn,
    TableColumn
} from '../../components/kiene-table/table-column';
import { HttpParams } from '@angular/common/http';
import { Condition, Operator, TableOption, TableOptionEvent } from '../../components/kiene-table/table-option';
import { Dokument } from './dokument';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { Subscription } from 'rxjs';
import { CreateDokumentDialogComponent } from './create-dokument-dialog/create-dokument-dialog.component';
import {
    DokumentTypeSelectionDialogComponent
} from './dokument-type-selection-dialog/dokument-type-selection-dialog.component';
import {
    UploadSonstigeDateiDialogComponent
} from './upload-sonstige-datei-dialog/upload-sonstige-datei-dialog.component';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import { KieneSession } from '../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import { FileUploadService } from '../../dialogs/file-upload-dialog/file-upload.service';
import { Client } from '../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { Benutzerprofil } from '../../../../projects/kiene-core/src/lib/components/stammdaten/mein-profil/mein-profil';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {
    UnterschriftenService
} from '../../../../projects/kiene-core/src/lib/components/unterschriften/unterschriften.service';
import { Signature } from '../../../../projects/kiene-core/src/lib/components/unterschriften/signature';
import { DocumentsService } from '../documents.service';


export class DokumentFillOutElement {
    name: string;
    title: string;
    type: string;
    defaultValue: any;
    preSelectValue: any;
    listener: DokumentFillOutListener[];

    constructor(
        name: string,
        title: string,
        type: string,
        defaultValue?: any,
        preSelectValue?: any,
        listener?: DokumentFillOutListener[]
    ) {
        this.name = name;
        this.title = title;
        this.type = type;
        this.defaultValue = defaultValue;
        this.preSelectValue = preSelectValue;
        this.listener = listener;
    }
}

export class DokumentFillOutListener {
    listenerElement: string;
    listenerValue: any;
    value: any;

    constructor(listenerElement: string, listenerValue: any, value: any) {
        this.listenerElement = listenerElement;
        this.listenerValue = listenerValue;
        this.value = value;
    }
}

@Component({
    selector: 'kiene-dokumente',
    templateUrl: './dokumente.component.html',
    styleUrls: ['./dokumente.component.scss'],
})
export class DokumenteComponent implements OnInit, OnChanges, OnDestroy {

    public static UNIQUE_IDENTIFIER = 'belege/dokumente';

    @Input('dokumentType') dokumentType: number[] = [];
    @Input('headline') headline = '';

    tableDescriptor: TableDescriptor = new TableDescriptor();

    currentClient: Client;
    kieneSession: KieneSession;
    benutzerprofil: Benutzerprofil;
    watchClientSubscription: Subscription;

    elements: DokumentFillOutElement[] = [];

    constructor(
        public dialog: MatDialog,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private pdfService: PdfViewerDesktopService,
        private api: KieneBackendApiService,
        private fileUploadService: FileUploadService,
        private unterschriftService: UnterschriftenService,
        private documentsService: DocumentsService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        this.kieneSession = localStorageService.getCurrentUser();
    }

    ngOnInit() {
        this.tableDescriptor.headline = this.headline;
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'dokument/';
        this.tableDescriptor.route = '/belege/dokumente/';
        this.tableDescriptor.nameOfIdField = 'dokument_id';
        this.tableDescriptor.searchPlaceholder = 'Dokumente durchsuchen';

        this.tableDescriptor.createAllowed = false;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;

        this.tableDescriptor.columns.push(new TableColumn('dokument_id', 'ID'));
        this.tableDescriptor.columns.push(new TableColumn('kunde', 'Kunde'));
        this.tableDescriptor.columns.push(
            new TableColumn('bezeichnung', 'Bezeichnung')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('dateiname', 'Dateiname')
        );
        this.tableDescriptor.columns.push(new KieneBooleanTableColumn('unterschrift_kunde', 'Unterschrieben'));
        this.tableDescriptor.columns.push(
            new TableColumn('erstellt', 'Erstellt', 'datetime')
        );
        this.tableDescriptor.columns.push(new TableColumn('pdf_id', '', 'pdf'));
        this.tableDescriptor.columns.push(new EmailGesendetTableColumn());
        this.tableDescriptor.columns.push(new GedrucktTableColumn());

        this.tableDescriptor.options = this.getOptions();

        this.tableDescriptor.permissionCreate = 3082;
        this.tableDescriptor.permissionUpdate = 3082;
        this.tableDescriptor.permissionDelete = 3084;

        this.tableDescriptor.initialSortColumn = 'erstellt';
        this.tableDescriptor.initialSortDirection = 'desc';
        this.tableDescriptor.uniqueIdentifier = DokumenteComponent.UNIQUE_IDENTIFIER;

        let params = new HttpParams();
        params = params.append('dokumenttyp_id', this.dokumentType.toString());

        this.currentClient = this.localStorageService.getCurrentClient();

        this.watchClientSubscription = this.localStorageService
            .watchCurrentClient()
            .subscribe((client) => {
                if (client) {
                    this.currentClient = client;
                } else {
                    this.currentClient = undefined;
                }
                this.tableService?.reload('Dokumente Component: watchClientSubscription');
            });

        this.tableDescriptor.params = params;
        this.ladeBenutzerprofil();
    }

    ngOnDestroy() {
        // this.tableService = null;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.tableDescriptor.toolbarButtons = [];
        if (this.dokumentType.length === 1 && this.dokumentType[0] === 7) {

            this.tableDescriptor.toolbarButtons.push(
                new ToolbarButton('upload_file', 'upload', 3082, 'Dateien hochladen')
            );

        } else {

            this.tableDescriptor.toolbarButtons.push(
                new ToolbarButton('add', 'add', 3082)
            );
            this.tableDescriptor.toolbarButtons.push(
                new ToolbarButton('add_all', 'queue', 3082)
            );
        }

        let params = new HttpParams();
        params = params.append('dokumenttyp_id', this.dokumentType.toString());
        this.tableDescriptor.params = params;
        this.tableDescriptor.headline = this.headline;
        this.tableService.reload('reload dokument type');
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            if (!this.currentClient) {
                this.messageService.alertMessage(
                    'Bitte vorher einen Kunden auswählen!'
                );
                return;
            }
            this.openAddDocumentsDialog(this.dokumentType);
        } else if (event.name === 'add_all') {
            if (!this.currentClient) {
                this.messageService.alertMessage(
                    'Bitte vorher einen Kunden auswählen!'
                );
                return;
            }

            this.openDokumentTypeSelectionDialog();
        } else if (event.name === 'showPdf') {
            this.showPdf(event.value);
        } else if (event.name === 'singlePdf') {
            const dok: Dokument = event.value;
            const input = new PdfViewerDialogInput(
                'dokument/read_or_mail_pdf.php',
                new HttpParams().set(
                    'dokument_id',
                    dok?.dokument_id?.toString()
                ),
                'Dokument ' + dok?.dokument_id?.toString(),
                {
                    email: {
                        sende_mail: true,
                        kunde_id: dok?.kunde_id,
                    }
                }
            );
            this.pdfService.openPdfViewer(input);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        } else if (event.name === 'upload') {
            this.upload(event.value);
        } else if (event.name === 'upload_file') {
            // nur fuer dokument type 7
            this.openUploadFileDialog();
        } else if (event.name === 'sign') {
            this.signDocument(event.value);
        }
    }

    showPdf(dok: Dokument) {
        const input = new PdfViewerDialogInput(
            'dokument/read_or_mail_pdf_merged.php',
            new HttpParams().set(
                'dokument_id',
                dok?.dokument_id?.toString()
            ),
            'Verträge',
            {
                email: {
                    sende_mail: true,
                    kunde_id: dok?.kunde_id,
                }
            }
        );
        this.pdfService.openPdfViewer(input).subscribe({
            next: () => {
                this.tableService.reload();
            }
        });
    }

    upload(dok: Dokument) {
        this.fileUploadService.openAddFilesDialog(
            dok.dokument_id,
            'dokument_id',
            'dokument/update_pdf.php',
            () => {
                this.tableService.reload();
            },
            () => {
                this.tableService.reload();
            }
        );
    }

    openDokumentTypeSelectionDialog() {
        const dialogRef = this.dialog.open(DokumentTypeSelectionDialogComponent, {
            width: '50%',
            minWidth: '300px',
        });

        dialogRef.afterClosed().subscribe((result) => {
            const dokTypeArray = result;
            if (dokTypeArray) {
                if (dokTypeArray.length > 0) {
                    this.openAddDocumentsDialog(dokTypeArray);
                }
            }
        });
    }

    getDokumentName() {
        let dokumentName = '';
        if (this.dokumentType.length > 1) {
            return dokumentName;
        }

        const dokTyp = this.dokumentType[0];

        switch (dokTyp) {
            case 1:
                dokumentName = 'Tierärztlicher Betreuungsvertrag';
                break;
            case 2:
                dokumentName = 'Ergänzung zum Betreuungsvertrag';
                break;
            case 3:
                dokumentName = 'Anwendung von Tierarzneimitteln (Futter)';
                break;
            case 4:
                dokumentName = 'Anwendung von Tierarzneimitteln (Wasser)';
                break;
            case 5:
                dokumentName = 'Meldevereinbarung QS';
                break;
            case 6:
                dokumentName = 'Risikomanagement zur oralen Medikation';
                break;
        }
        return dokumentName;
    }

    addElement(element: DokumentFillOutElement) {
        if (element.name !== '') {
            for (const e of this.elements) {
                if (e.name === element.name) {
                    return;
                }
            }
        }
        this.elements.push(element);
    }

    openAddDocumentsDialog(dokumentType: number[]) {
        this.elements = [];

        if (dokumentType.includes(1)) {
            this.addElement(
                new DokumentFillOutElement(
                    '',
                    'Tierärztlicher Betreuungsvertrag',
                    'headline'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'anzahl_sauen',
                    'Anzahl Sauen',
                    'number'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'anzahl_ferkel',
                    'Anzahl Ferkel',
                    'number'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'anzahl_mastschweine',
                    'Anzahl Mastschweine',
                    'number'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'anzahl_mastkaelber',
                    'Anzahl Mastkaelber',
                    'number'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'anzahl_kaelber',
                    'Anzahl Kaelber',
                    'number'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'anzahl_fresser',
                    'Anzahl Fresser',
                    'number'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'anzahl_bullen',
                    'Anzahl Bullen',
                    'number'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'anzahl_kuehe',
                    'Anzahl Kuehe',
                    'number'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'besuche_pro_jahr',
                    'Anzahl Besuche pro Jahr',
                    'number',
                    4
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'vereinbarungen',
                    'Zusätzliche Vereinbarungen',
                    'textarea'
                )
            );
        }
        if (dokumentType.includes(2)) {
            this.addElement(
                new DokumentFillOutElement(
                    '',
                    'Ergänzung zum Betreuungsvertrag',
                    'headline'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'datum_betreuungsvertrag',
                    'Datum Betreuungsvertrag',
                    'date',
                    new Date()
                )
            );
            const selection: KieneKeyValue[] = [];
            selection.push(
                new KieneKeyValue(
                    'vollständig (einschl. aller freiwilligen Daten)',
                    1
                )
            );
            selection.push(
                new KieneKeyValue(
                    'teilweise (nur zwingend erforderl. Daten)',
                    2
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'meldung',
                    'Daten aus Anlage an QS melden',
                    'selection',
                    selection,
                    2
                )
            );
        }
        if (dokumentType.includes(3)) {
            this.addElement(
                new DokumentFillOutElement(
                    '',
                    'Anwendung von Tierarzneimitteln (Futter)',
                    'headline'
                )
            );
        }
        if (dokumentType.includes(4)) {
            this.addElement(
                new DokumentFillOutElement(
                    '',
                    'Anwendung von Tierarzneimitteln (Wasser)',
                    'headline'
                )
            );
        }
        if (dokumentType.includes(5)) {
            this.addElement(
                new DokumentFillOutElement(
                    '',
                    'Meldevereinbarung QS',
                    'headline'
                )
            );

            const selection: KieneKeyValue[] = [];
            selection.push(new KieneKeyValue('Schweine', 'Schweine'));
            selection.push(
                new KieneKeyValue(
                    'Kälber (zugekauft)',
                    'Kälber (zugekauft)'
                )
            );
            selection.push(
                new KieneKeyValue(
                    'Milchkühe',
                    'Milchkühe'
                )
            );
            selection.push(
                new KieneKeyValue(
                    'Aufzuchtferkel',
                    'Aufzuchtferkel'
                )
            );
            selection.push(
                new KieneKeyValue(
                    'Mastschweine',
                    'Mastschweine'
                )
            );
            selection.push(
                new KieneKeyValue(
                    'Sauen',
                    'Sauen'
                )
            );
            selection.push(
                new KieneKeyValue(
                    'Saugferkel',
                    'Saugferkel'
                )
            );
            selection.push(new KieneKeyValue('Freitext', 'Freitext'));
            this.addElement(
                new DokumentFillOutElement(
                    'nutzungsart',
                    'Nutzungsart',
                    'selection',
                    selection
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'freitext',
                    'Freitext',
                    'disabledText',
                    null,
                    null,
                    [
                        new DokumentFillOutListener(
                            'nutzungsart',
                            'Freitext',
                            false
                        ),
                    ]
                )
            );

            const erteilungSelection: KieneKeyValue[] = [];
            erteilungSelection.push(new KieneKeyValue('ja', 1));
            erteilungSelection.push(new KieneKeyValue('nein', 0));
            this.addElement(
                new DokumentFillOutElement(
                    'erteilung',
                    'Erteilung eines Meldeauftrags',
                    'selection',
                    erteilungSelection,
                    null,
                    [new DokumentFillOutListener('nutzungsart', null, 0)]
                )
            );

            this.addElement(
                new DokumentFillOutElement(
                    '',
                    'Die o.g. Tierarztpraxis soll für den Tierhalter folgende Informationen zu AM-Anmeldungen übermitteln',
                    'description'
                )
            );

            const alleDatenfelderSelection: KieneKeyValue[] = [];
            alleDatenfelderSelection.push(new KieneKeyValue('ja', 1));
            alleDatenfelderSelection.push(new KieneKeyValue('nein', 0));
            this.addElement(
                new DokumentFillOutElement(
                    'alle_datenfelder',
                    'Alle Datenfelder des AuA',
                    'selection',
                    alleDatenfelderSelection,
                    null,
                    [new DokumentFillOutListener('nutzungsart', null, 0)]
                )
            );

            const alleDatenfelderUebergabeSelection: KieneKeyValue[] = [];
            alleDatenfelderUebergabeSelection.push(new KieneKeyValue('HIT', 1));
            alleDatenfelderUebergabeSelection.push(new KieneKeyValue('QS', 2));
            alleDatenfelderUebergabeSelection.push(
                new KieneKeyValue('beide', 3)
            );
            alleDatenfelderUebergabeSelection.push(
                new KieneKeyValue('keine', 0)
            );
            this.addElement(
                new DokumentFillOutElement(
                    'alle_uebergabe',
                    'Übergabe an',
                    'selection',
                    alleDatenfelderUebergabeSelection,
                    null,
                    [new DokumentFillOutListener('nutzungsart', null, 0)]
                )
            );

            this.addElement(new DokumentFillOutElement('', '', 'nl'));

            const mindestumfangSelection: KieneKeyValue[] = [];
            mindestumfangSelection.push(new KieneKeyValue('ja', 1));
            mindestumfangSelection.push(new KieneKeyValue('nein', 0));
            this.addElement(
                new DokumentFillOutElement(
                    'mindestumfang',
                    'Mindestumfang halbjährliche Mitteilung',
                    'selection',
                    mindestumfangSelection,
                    null,
                    [new DokumentFillOutListener('nutzungsart', null, 1)]
                )
            );

            const mindestumfangUebergabeSelection: KieneKeyValue[] = [];
            mindestumfangUebergabeSelection.push(new KieneKeyValue('HIT', 1));
            mindestumfangUebergabeSelection.push(new KieneKeyValue('QS', 2));
            mindestumfangUebergabeSelection.push(new KieneKeyValue('beide', 3));
            mindestumfangUebergabeSelection.push(new KieneKeyValue('keine', 0));
            this.addElement(
                new DokumentFillOutElement(
                    'mindestumfang_uebergabe',
                    'Übergabe an',
                    'selection',
                    mindestumfangUebergabeSelection,
                    null,
                    [
                        new DokumentFillOutListener(
                            'nutzungsart',
                            'Schweine',
                            2
                        ),
                        new DokumentFillOutListener(
                            'nutzungsart',
                            'Kälber unter 8 Monate',
                            2
                        ),
                        new DokumentFillOutListener(
                            'nutzungsart',
                            'Kälber (zugekauft)',
                            3
                        ),
                        new DokumentFillOutListener(
                            'nutzungsart',
                            'Kälber unter 8 Monate & Rinder über 8 Monate',
                            1
                        ),
                    ]
                )
            );
        }
        if (dokumentType.includes(6)) {
            this.addElement(
                new DokumentFillOutElement(
                    '',
                    'Risikomanagement zur oralen Medikation',
                    'headline'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'handhabung',
                    'Handhabung',
                    'checkbox'
                )
            );
            this.addElement(
                new DokumentFillOutElement('lagerung', 'Lagerung', 'checkbox')
            );
            this.addElement(
                new DokumentFillOutElement(
                    'sammelkasten',
                    'Sammelkasten',
                    'checkbox'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'futterzuteilung',
                    'Futterzuteilung',
                    'checkbox'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'mischbottich',
                    'Mischbottich',
                    'checkbox'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'futterleitungen',
                    'Futterleitungen',
                    'checkbox'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'futtertroege',
                    'Futtertröge',
                    'checkbox'
                )
            );
            this.addElement(
                new DokumentFillOutElement(
                    'oaf_geeignet',
                    'Anlage für OAF-Applikation geeignet',
                    'checkbox'
                )
            );
        }

        this.addElement(
            new DokumentFillOutElement('', 'Ort und Datum', 'headline')
        );
        this.addElement(
            new DokumentFillOutElement(
                'ort',
                'Ort',
                'text',
                this.currentClient.ort_final
            )
        );
        this.addElement(
            new DokumentFillOutElement('datum', 'Datum', 'date', new Date())
        );

        const dialogRef = this.dialog.open(CreateDokumentDialogComponent, {
            width: '67%',
            minWidth: '300px',
            data: {
                apiUrl: 'dokument/create.php',
                kunde_id: this.currentClient.kunde_id,
                tierarzt_id: this.kieneSession.tierarzt_id,
                dokumentTyp: dokumentType,
                elements: this.elements,
                title: this.getDokumentName(),
            },
        });

        dialogRef.afterClosed().subscribe(() => {
            this.tableService.reload();
        });
    }

    openDeleteDialog(dok: Dokument) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Dokument ' + dok.bezeichnung + ' wirklich löschen?',
                text: 'Dokument ' + dok.bezeichnung + ' wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.api
                    .deleteEntity(this.apiBaseUrl + 'dokument/delete.php', dok)
                    .subscribe({

                        next: () => {
                            this.messageService.infoMessage(
                                'Das Dokument wurde erfolgreich gelöscht!'
                            );
                            this.tableService.reload();
                        },
                        error: (error) => {
                            this.messageService.errorMessage(
                                'Das Dokument konnte nicht gelöscht werden! ',
                                error
                            );
                        }
                    });
            }
        });
    }

    gedruckt(dok: Dokument) {
        this.api.get(this.apiBaseUrl + 'dokument/gedruckt.php', new HttpParams().set('dokument_id', dok.dokument_id)).subscribe({
            next: () => {
                this.tableService.reload('gedruckt');
                this.showPdf(dok);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private openUploadFileDialog() {
        if (!!this.currentClient) {

            const dialogRef = this.dialog.open(UploadSonstigeDateiDialogComponent, {
                data: {
                    client: this.currentClient
                }
            });
            dialogRef.afterClosed().subscribe({
                next: () => {
                    this.tableService.reload('openUploadFileDialog: after closed');
                }
            });
        } else {
            this.messageService.infoMessage('Bitte erst einen Kunden auswählen!');
        }
    }

    private signDocument(dokument: Dokument) {

        if (this.benutzerprofil?.unterschrift_png) {
            this.dokumentUnterschreiben(dokument);
        } else {
            const signature = new Signature();
            signature.benutzer = 1;
            this.unterschriftService.unterschreiben(signature).subscribe({
                next: result => {
                    if (result) {
                        this.dokumentUnterschreiben(dokument);
                    }
                }
            });
        }

    }

    private dokumentUnterschreiben(dokument: Dokument) {
        this.documentsService.dokumentUnterschreiben(dokument).subscribe({
            next: response => {
                this.messageService.infoMessage('Ihre Unterschrift wurde erfolgreich im Dokument eingefügt!');
                this.tableService.reload();
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private getOptions() {
        const options: TableOption[] = [];
        options.push(
            new TableOption(
                'singlePdf',
                'Einzelne PDF anzeigen',
                'cloud_download',
                3085
            )
        );
        options.push(
            new TableOption(
                'upload',
                'PDF manuell ersetzen',
                'cloud_upload',
                3082
            )
        );
        options.push(
            new TableOption('delete', 'löschen', 'delete', 3084)
        );

        if (this.localStorageService.isCurrentUserKundeAdmin() || this.localStorageService.isCurrentUserViehhaendlerAdmin()) {
            options.push(new TableOption('sign', 'Unterschreiben', 'edit_note', 3086, {
                conditions: [new Condition('unterschrift_kunde', Operator.KLEINER, 1),
                new Condition('tierarzt_id', Operator.EXISTIERT, undefined),
                new Condition('ort', Operator.EXISTIERT, undefined),
                new Condition('datum', Operator.EXISTIERT, undefined)]
            }));

        }

        options.push(new TableOption(null, 'Drucken', 'print', 3087, {
            void: this.gedruckt.bind(this),
            conditions: [new Condition('pdf_id', Operator.EXISTIERT, null)]
        }));
        return options;
    }

    private ladeBenutzerprofil() {
        this.api.get(this.apiBaseUrl + 'benutzerprofil/read_one_kundenportal.php').subscribe({
            next: response => {
                this.benutzerprofil = <Benutzerprofil>response;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}
