import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {ClientService} from '../../administration/client/client.service';
import {Client} from '../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-client-autocomplete',
    templateUrl: './client-autocomplete.component.html',
    styleUrls: ['./client-autocomplete.component.scss'],
})
export class ClientAutocompleteComponent
    implements OnInit, OnChanges, AfterViewInit {
    clientFormCtrl: UntypedFormControl = new UntypedFormControl();
    clients: Observable<Client[]>;

    @Input('defaultValue') defaultValue = '';
    @Input('client') client: Client = new Client();
    @Input('placeholder') placeholder = 'Betrieb';
    @Input('panelWidth') panelWidth = '100%';
    @Input('required') required = false;
    @Input('onlyActiveClients') onlyActiveClients = false;
    @Input('showClear') showClear = true;
    @Input('showVVVO') showVVVO = false;
    @Input('disabled') disabled = false;
    @Input('clearAfterSelect') clearAfterSelect = false;
    @Output() selectedClient = new EventEmitter<Client>();

    constructor(private clientService: ClientService) {
    }

    ngOnInit() {
        if (this.required) {
            this.clientFormCtrl.setValidators(Validators.required);
        }
        this.autocompleteClient();
        // this.clientFormCtrl.setValue(this.client);
    }

    ngAfterViewInit() {
    }

    ngOnChanges() {
        if (this.client && this.client.kunde_id) {
            this.clientFormCtrl.setValue(this.client);
        }
    }

    autocompleteClient() {
        this.clientFormCtrl = new UntypedFormControl();
        this.clients = this.clientFormCtrl.valueChanges.pipe(
            startWith(''),
            switchMap((value) => this.searchClients(value))
        );
    }

    searchClients(value: string) {
        return this.clientService
            .searchClients(value, {onlyActiveClients: this.onlyActiveClients})
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                map((response) => response.records)
            );
    }

    clientMapper(client: Client) {
        if (client) {
            return (
                client.kunde_nr_intern +
                ' - ' +
                client.bezeichnung_final +
                ' - ' +
                client.zusatz_final
            );
        }
        return this.defaultValue;
    }

    autocompleteClientSelected(event: MatAutocompleteSelectedEvent) {
        const client = event.option.value;
        this.selectedClient.emit(client);
        if (this.clearAfterSelect) {
            this.clientFormCtrl.setValue('');
        }
    }

    clear() {
        this.clientFormCtrl.setValue(null);
        this.selectedClient.emit(null);
    }

    getBezeichnung(client: Client) {
        let b = client.kunde_nr_intern + ' - ' + client.bezeichnung_final;
        if (client.zusatz_final) {
            b += ' - ' + client.zusatz_final;
        }
        if (client.ort_final) {
            b += ' - ' + client.ort_final;
        }
        if (client.strasse_final) {
            b += ' - ' + client.strasse_final;
        }
        if (client.vvvo && this.showVVVO) {
            b += ' - ' + client.vvvo;
        }
        if (client.unternehmensgruppe) {
            b += ' - UG: ' + client.unternehmensgruppe;
        }
        return b;
    }
}
