<div mat-dialog-content>
    <mat-form-field style="width: 100%;">
        <mat-label>Menu</mat-label>
        <input type="text"
               matInput
               [formControl]="menuCtrl"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [displayWith]="mapper" (optionSelected)="navigationItemSelected($event)">
          <mat-option *ngFor="let item of filteredItems | async" [value]="item">
            {{item.toString()}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
</div>