import { TierFilterSettings } from '../../../../../projects/kiene-core/src/lib/components/gruppe-tier/filter/gruppe-tier-filter-dialog/classes/tier-filter-settings';
import { Gruppe } from '../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { KieneBackendApiService } from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { Subscription } from 'rxjs';
import { MessageService } from '../../../services/message.service';
import { LocalStorageService } from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { GruppenService } from '../../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';

@Component({
  selector: 'kiene-gruppe-schweine-details',
  templateUrl: './gruppe-schweine-details.component.html',
  styleUrls: ['./gruppe-schweine-details.component.scss']
})
export class GruppeSchweineDetailsComponent implements OnInit {

  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  
  selectedIndex = 0;
  filterSettings: TierFilterSettings = new TierFilterSettings();
  
  gruppe: Gruppe;
  gruppeSubscription: Subscription;
  gruppeReloadSubscription: Subscription;
  isSGS = true;

  constructor(
    private route: ActivatedRoute,
    @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
    private api: KieneBackendApiService,
    private messageService: MessageService,
    private localStorageService: LocalStorageService,
    private gruppenService: GruppenService) {
      const gruppeId = Number(this.route.snapshot.paramMap.get('id'));
      this.gruppe = new Gruppe();
      this.gruppe.gruppe_id = gruppeId;
      this.ladeDaten();
    }

  ngOnInit(): void {
    this.isSGS = this.localStorageService.isCurrentUserSgs();

    this.gruppeReloadSubscription = this.gruppenService.watchReloadGruppe().subscribe({
      next: () => {
          this.loadGruppe();
      }
    });
  }

  ladeDaten(){
    this.loadGruppe();
  }

  tabChanged(event: MatTabChangeEvent) {
    this.selectedIndex = event.index;
  }

  setFilterSettings(filterSettings: TierFilterSettings) {
    this.filterSettings = filterSettings;
    //this.tableDescriptor.filterParams = this.filterSettings?.getAsParams();
    //this.tableService.reload('setFilterSettings');
  }

  loadGruppe() {
    this.gruppeSubscription?.unsubscribe();
    this.gruppeSubscription = this.api
        .getByIdAlternative(
            this.apiBaseUrl + 'gruppe-schweine/read_one.php', //'gruppe/read_one_kundenportal.php', //TODO change after merging gruppen types
            'gruppe_id',
            this.gruppe?.gruppe_id?.toString()
        )
        .subscribe({
            next: (gruppe: Gruppe) => {
                this.gruppe = gruppe;
                //this.schlachtungColumn.isVisible = !!(this.gruppe?.sgs > 0);
                //this.tableService.updateColumns();
            },
            error: err => {
                this.messageService.errorMessage('Gruppe konnte nicht geladen werden!', err);
            }
        });
  }
}
