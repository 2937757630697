<h1 mat-dialog-title>Preisliste herunterladen</h1>
<div mat-dialog-content>
    <h3>Unternehmensgruppe: {{unternehmensgruppe?.bezeichnung || '---'}}</h3>
    <div style="margin-bottom: 12px">
        <mat-hint class="hinweis-text">
            Preislisten können nach dem Selektieren eines Verkaufspreises heruntergeladen werden.
        </mat-hint>
    </div>

    <kiene-verkaufspreis-selector [allowEmptySelection]="false"
        (verkaufspreisSelectionChanged)="this.selectedVerkaufspreisId = $event.verkaufspreis_id">
    </kiene-verkaufspreis-selector>
</div>
<div mat-dialog-actions>
    <button mat-flat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="download()" [disabled]="!this.selectedVerkaufspreisId">
        Herunterladen
    </button>
</div>