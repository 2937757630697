<div class="padMe">
    <h1>
        Zeitslot buchen und Viehändler beauftragen
    </h1>
    <h2 *ngIf="gruppe">{{gruppe.bezeichnung}} : {{gruppe.anzahl_tiere_aktuell}} Tiere</h2>
    <div class="timeSlotBooking">
        <h3 *ngIf="standard_schlachthof">{{standard_schlachthof.bezeichnung}}</h3>

        <div class="slotSelection">
            <h4>Diese Woche</h4>
            <div *ngIf="noSlotsAvailable" class="no-slots">
                Es gibt keine Zeitslots für die nächsten Tage!
            </div>
            <div class="weekSchedule">
                <mat-list *ngFor="let e of days" style="margin-bottom: 6px" class="daySlots">
                    <div class="dayHeader">
                        <div>{{e.day | date: "fullDate"}}</div>
                    </div>
                    <mat-list-item *ngFor="let slot of e.slots" class="timeSlot"
                        [ngClass]="selectedSlot == slot.zeitslot_id? 'selected' : ''"
                        [ngClass]="slot.rest_kapazitaet <= 0 ? 'emptySlot' : ''" (click)="selectTimeSlot(slot)">
                        <span matLine>{{slot.datum_uhrzeit_von | date: "HH:mm"}} - {{slot.datum_uhrzeit_bis | date:
                            "HH:mm"}} Uhr</span>
                        <span matLine>{{slot.rest_kapazitaet}} Tiere</span>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>

        <h3>Tierzahl zur Schlachtung</h3>
        <div>
            <mat-form-field>
                <mat-label>Tierzahl</mat-label>
                <input matInput [formControl]="countCtrl" placeholder="Tierzahl" (change)="checkCapacityAssignment()" />
                <span matSuffix>/ {{maxCapacity}}</span>
            </mat-form-field>
        </div>

        <hr />

        <h3>Auswahl Viehhändler</h3>
        <div class="haendler-selection">
            <mat-form-field>
                <mat-label>Viehändler</mat-label>
                <mat-select matInput [formControl]="haendlerCtrl" placeholder="Viehändler"
                    (selectionChange)="checkTraderAssignment()">
                    <mat-option *ngFor="let item of favoritenHaendler"
                        [value]="item.betrieb_id">{{item.bezeichnung}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-checkbox [(ngModel)]="useHaendlerAsDefault">Als Standardhändler übernehmen</mat-checkbox>
        </div>

        <hr />

        <button (click)="buchungAbschliessen()" mat-flat-button color="accent" [disabled]="!bookingValid">
            <span>Beauftragung anfragen</span>
        </button>
    </div>
</div>