import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShareButtonComponent} from './share-button/share-button.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [
        ShareButtonComponent
    ],
    exports: [
        ShareButtonComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule
    ]
})
export class ShareButtonModule {
}
