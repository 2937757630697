import { Gruppe } from '../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';

export class GruppeEinstallenDialogInsert {
    gruppe: Gruppe;
    showPassReiter = false;

    constructor(gruppe: Gruppe, showPassReiter: boolean) {
        this.gruppe = gruppe;
        this.showPassReiter = showPassReiter;
    }
}
