<nav mat-tab-nav-bar style="margin-top: 63px;">
    <a #wla="routerLinkActive" *ngIf="isTabMenuVisible('/lager/wareneingang')" [active]="wla.isActive"
       [routerLink]="['/lager/wareneingang']" mat-tab-link routerLinkActive>Wareneingang</a>
    <a #sla="routerLinkActive" *ngIf="isTabMenuVisible('/lager/bestand')" [active]="sla.isActive" [routerLink]="['/lager/bestand']"
       mat-tab-link routerLinkActive>Bestand</a>
    <a #grhla="routerLinkActive" *ngIf="isTabMenuVisible('/lager/wareneingangshistorie')"
       [active]="grhla.isActive" [routerLink]="['/lager/wareneinganghistorie']" mat-tab-link
       routerLinkActive>Wareneingangshistorie</a>
    <a #wrlla="routerLinkActive" *ngIf="isTabMenuVisible('/lager/warenruecklieferungen')"
       [active]="wrlla.isActive" [routerLink]="['/lager/warenruecklieferungen']" mat-tab-link
       routerLinkActive>Warenr&uuml;cklieferungen</a>
    <a #lla="routerLinkActive" *ngIf="isTabMenuVisible('/lager/lieferanten')" [active]="lla.isActive"
       [routerLink]="['/lager/lieferanten']" mat-tab-link routerLinkActive>Lieferanten</a>
    <a #ala="routerLinkActive" *ngIf="isTabMenuVisible('/lager/artikel')" [active]="ala.isActive" [routerLink]="['/lager/artikel']"
       mat-tab-link routerLinkActive>Artikel</a>
    <a #cla="routerLinkActive" *ngIf="isTabMenuVisible('/lager/chargen')" [active]="cla.isActive" [routerLink]="['/lager/chargen']"
       mat-tab-link routerLinkActive>Chargen</a>
    <a #lala="routerLinkActive" *ngIf="isTabMenuVisible('/lager/lager')" [active]="lala.isActive" [routerLink]="['/lager/lager']"
       mat-tab-link routerLinkActive>Lager</a>
    <a #lapla="routerLinkActive" *ngIf="isTabMenuVisible('/lager/lagerplaetze')" [active]="lapla.isActive"
       [routerLink]="['/lager/lagerplaetze']" mat-tab-link routerLinkActive>Lagerpl&auml;tze</a>
    <a #ila="routerLinkActive" *ngIf="isTabMenuVisible('/lager/inventur')" [active]="ila.isActive" [routerLink]="['/lager/inventur']"
       mat-tab-link routerLinkActive>Inventuren</a>
</nav>
<router-outlet></router-outlet>
