<mat-form-field style="width:100%;">
    <mat-label>{{ placeholder }}</mat-label>
    <mat-hint>{{ hintMessage }}</mat-hint>
    <input
        #articleInput="matInput" (blur)="onBlur($event)"
        [formControl]="materialFormCtrl" [matAutocomplete]="autoMaterial" [placeholder]="placeholder"
        [required]="required" matInput>
    <button (click)="clearAndFocus()" *ngIf="showClearButton" [disabled]="disabled" mat-icon-button matSuffix
            type="button">
        <mat-icon>clear</mat-icon>
    </button>
    <mat-autocomplete #autoMaterial="matAutocomplete" (optionActivated)="optionActivated($event)"
                      (optionSelected)="autocompleteMaterialSelected($event)"
                      [displayWith]="materialMapper" [panelWidth]="'700px'">
        <mat-option *ngFor="let m of materialien | async" [value]="m">
            <span>{{ m.artikel_me }}</span><span
            *ngIf="showPrice"> - {{ ((m.einzelpreis) ? m.einzelpreis : 0) | number: '1.2-2' }} &euro;</span>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

