import {Component, Inject, OnInit} from '@angular/core';
import {TableDescriptor} from '../../../components/kiene-table/table-descriptor';
import {ActivatedRoute} from '@angular/router';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {TableOptionEvent} from '../../../components/kiene-table/table-option';
import {NumberTableColumn, TableColumn, TextTableColumn,} from '../../../components/kiene-table/table-column';
import {HttpParams} from '@angular/common/http';
import {forkJoin} from 'rxjs';

@Component({
    selector: 'kiene-inventur-auswertung',
    templateUrl: './inventur-auswertung.component.html',
    styleUrls: ['./inventur-auswertung.component.scss'],
})
export class InventurAuswertungComponent implements OnInit {
    tableDescriptor = new TableDescriptor();

    inventurId1: number;
    inventurId2: number;

    constructor(
        private route: ActivatedRoute,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService
    ) {
        this.inventurId1 = Number.parseInt(this.route.snapshot.queryParamMap.get('inventurId1'), 10);
        this.inventurId2 = Number.parseInt(this.route.snapshot.queryParamMap.get('inventurId2'), 10);
    }

    ngOnInit(): void {
        this.initTable();
        this.ladeInventuren();
    }

    catchOptionEvent(event: TableOptionEvent) {
    }

    private initTable() {
        this.tableDescriptor.headline = 'Auswertung Inventur';
        this.tableDescriptor.uniqueIdentifier = 'Auswertung Inventur';
        this.tableDescriptor.alternativeApiUrl =
            this.apiBaseUrl + 'inventur/analyse.php';
        this.tableDescriptor.params = new HttpParams().set(
            'inventur_id_von',
            this.inventurId1
        ).set('inventur_id_bis', this.inventurId2);
        this.tableDescriptor.initialSortColumn = 'artikel_me';
        this.tableDescriptor.initialSortDirection = 'asc';

        this.tableDescriptor.permissionView = 4446;

        this.tableDescriptor.columns = this.getTableColumns();
    }

    private getTableColumns() {
        const columns: TableColumn[] = [];

        columns.push(new TextTableColumn('artikelnummer', 'Art. Nr.:'));
        columns.push(new TextTableColumn('artikel_me', 'Artikel'));
        columns.push(
            new NumberTableColumn('inventur', 'Stand erste Inventur')
        );
        columns.push(new NumberTableColumn('zugang', 'Zugänge'));
        columns.push(new NumberTableColumn('abgang', 'Abgänge'));
        columns.push(new NumberTableColumn('soll', 'berechneter Sollwert'));
        columns.push(new NumberTableColumn('ist', 'Ist / Stand zweite Inventur'));
        columns.push(new NumberTableColumn('einkaufspreis', 'Einkaufspreis'));
        columns.push(new NumberTableColumn('warenwert', 'Warenwert'));
        columns.push(new NumberTableColumn('abweichung', 'Abweichung'));

        return columns;
    }

    private ladeInventuren() {
        const params1 = new HttpParams().set('inventur_id', this.inventurId1);
        const params2 = new HttpParams().set('inventur_id', this.inventurId2);
        const sub1 = this.api.get(this.apiBaseUrl + 'inventur/read_one.php', params1);
        const sub2 = this.api.get(this.apiBaseUrl + 'inventur/read_one.php', params2);
        forkJoin([sub1, sub2]).subscribe({
            next: response => {
                if (response[0]?.datum < response[1]?.datum) {
                    this.tableDescriptor.headline = 'Inventuranalyse zwischen ' + response[0]?.name + ' und ' + response[1]?.name;
                } else {
                    this.tableDescriptor.headline = 'Inventuranalyse zwischen ' + response[1]?.name + ' und ' + response[0]?.name;

                }
            }
        });
    }
}
