import { Injectable, Inject } from '@angular/core';
import { KieneBackendApiService } from '../backend-api/kiene-backend-api.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '../message-service/message.service';
import { API_BASE_URL_SERVICE } from '../../kiene-core.config';
import { EnterValueInput, EnterValueResponse } from '../../components/dialogs/enter-value-dialog/enter-value-response';
import { EnterValueDialogComponent } from '../../components/dialogs/enter-value-dialog/enter-value-dialog.component';
import { User } from '../../components/client/classes/user';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private api: KieneBackendApiService,
    public dialog: MatDialog,
    private messageService: MessageService,
    @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
  ) { }

  neuesPasswortVergeben(benutzerId: number): Observable<boolean> {
    const subject = new Subject<boolean>();
    const input = new EnterValueInput();
    input.title = 'Passwort';
    input.text =
      'Neues Passwort vergeben';
    input.okButton = 'ändern';
    input.addInputValue('Neues Passwort', 'password');
    input.addInputValue('Passwort wiederholen', 'password');

    const dialogRef = this.dialog.open(EnterValueDialogComponent, {
      data: input,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe({
      next: (result: EnterValueResponse) => {
        if (result?.result) {
          const pw1 = result.getValue(0);
          const pw2 = result.getValue(1);

          if (pw1?.length > 0 && pw2?.length > 0 && pw1 === pw2) {
            const benutzer = new User();
            benutzer.benutzer_id = benutzerId;
            benutzer.passwort = pw1;
            this.api.updateGeneric(this.apiBaseUrl + 'benutzerprofil/update_kundenportal.php', benutzer).subscribe(
              {
                next: (ben: User) => {
                  this.messageService.infoMessage('Passwort erfolgreich geändert!');
                  subject.next(ben?.passwort_aendern === 0);
                },
                error: err => {
                  this.messageService.errorMessage('Passwort konnte nicht geändert werden!', err);
                  return this.neuesPasswortVergeben(benutzerId);
                }
              }
            );
          } else {
            this.messageService.alertMessage('Passwörter ungültig oder stimmen nicht überein!');
            return this.neuesPasswortVergeben(benutzerId);
          }
        } else {
          return this.neuesPasswortVergeben(benutzerId);
        }
      }
    });

    return subject.asObservable();
  }

}
