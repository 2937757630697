import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../app.auth.guard';
import { FinanzenComponent } from './finanzen.component';
import { GutschriftenComponent } from './gutschriften/gutschriften.component';
import { PrimanotaComponent } from './primanota/primanota.component';
import { RechnungDetailsComponent } from './rechnungen/rechnung-details/rechnung-details.component';
import { RechnungenComponent } from './rechnungen/rechnungen.component';


const routes: Routes = [
  {
        path: '', component: FinanzenComponent, canActivate: [AuthGuard], children: [
            {path: 'rechnungen', component: RechnungenComponent, canActivate: [AuthGuard]},
            {path: 'rechnungen/:id', component: RechnungDetailsComponent, canActivate: [AuthGuard]},
            {path: 'gutschriften', component: GutschriftenComponent, canActivate: [AuthGuard]},
            {path: 'primanota', component: PrimanotaComponent, canActivate: [AuthGuard]}
        ]
    },
];

@NgModule({
  declarations: [
    
  ],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinanzenRoutingModule { }
