<h1 mat-dialog-title>Ins Wartezimmer</h1>
<div mat-dialog-content>
    <mat-form-field style="width: 100%;">
        <mat-label>Notiz</mat-label>
        <input matInput type="text" [formControl]="notizCtrl">
    </mat-form-field>
</div>

<div align="end" mat-dialog-actions>
    <button (click)="cancel()" mat-button>Abbrechen</button>
    <button mat-flat-button color="accent" *kienePermission="[7002]" [disabled]="isDisabled()" (click)="save()">
        Im Wartezimmer
    </button>
</div>