import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-add-files-dialog',
    templateUrl: './add-files-dialog.component.html',
    styleUrls: ['./add-files-dialog.component.scss']
})
export class AddFilesDialogComponent implements OnInit {

    @ViewChild('fileSelector') fileSelector;

    filesToUpload: File[] = [];
    filesProgressValues: number[] = [];
    form: UntypedFormGroup;
    showCloseButton = false;
    titleCtrl = new UntypedFormControl();

    kunde_id: number;

    apiUrl: string;
    nameOfIdField: string;
    id: number;

    constructor(public dialogRef: MatDialogRef<AddFilesDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: UntypedFormBuilder, private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl) {
        this.apiUrl = data.apiUrl;
        this.kunde_id = data.kunde_id;
    }

    ngOnInit() {

    }

    openFileSelector() {
        this.fileSelector.nativeElement.click();
    }

    // files is of type FilesList
    handleFiles(event: any) {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
            this.filesToUpload.push(files.item(i));
            this.filesProgressValues[i] = 0;
        }
    }

    isFileUploaded(file: number) {
        if (this.filesProgressValues[file] === 1) {
            return true;
        } else {
            return false;
        }
    }

    uploadFilesToServer() {
        if (this.filesToUpload.length > 0) {
            for (let i = 0; i < this.filesToUpload.length; i++) {

                const fileNumber = i;
                const file = this.filesToUpload[fileNumber];

                const fd = new FormData();
                fd.append('titel', this.titleCtrl.value);
                fd.append('dokument', file);
                if (this.kunde_id) {
                    fd.append('kunde_id', this.kunde_id.toString());
                }

                const params = new HttpParams();


                const options = {
                    headers: new HttpHeaders('Phito-Type: fileupload'),
                    params: params,
                    reportProgress: true,
                };

                // const request = new HttpRequest('POST', this.apiBaseUrl+this.apiUrl, fd, options);
                this.http.post<any>(this.apiBaseUrl + this.apiUrl, fd, options).subscribe(event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        const percentDone = Math.round(100 * event.loaded / event.total);
                        // this.filesProgressValues[i] = percentDone;
                    } else if (event instanceof HttpResponse) {
                    }
                }, error => {
                    console.error('Error while uploading file: ' + file.name);
                }, () => {
                    this.markFileAsUploaded(fileNumber);
                });
            }
        }
    }

    markFileAsUploaded(no: number) {
        this.filesProgressValues[no] = 1;
        this.checkShowCloseButton();
    }

    checkShowCloseButton() {
        let show = true;
        for (let i = 0; i < this.filesProgressValues.length; i++) {
            if (this.filesProgressValues[i] === 0) {
                show = false;
            }
        }
        if (show) {
            this.showCloseButton = true;
        }
    }

    uploadFiles() {
        this.uploadFilesToServer();
    }

    closeDialog() {
        this.dialogRef.close(true);
    }

    onCancelClick(): void {
        this.dialogRef.close(false);
    }

}
