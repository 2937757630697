import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../services/message.service';
import { GoodsReceiptHistory } from '../goods-receipt-history.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'kiene-correct-order-dialog',
  templateUrl: './correct-order-dialog.component.html',
  styleUrls: ['./correct-order-dialog.component.scss']
})
export class CorrectOrderDialogComponent implements OnInit {

  wareneingangshistorie: GoodsReceiptHistory;
  orderNoCtrl = new UntypedFormControl();

  constructor(
    public dialogRef:MatDialogRef<CorrectOrderDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) private data,
    private api: KieneTableService,
    private messageService: MessageService) 
  { 
    this.wareneingangshistorie = data.grh;
  }

  ngOnInit() {
    this.orderNoCtrl.setValue(this.wareneingangshistorie.bestell_nr);
  }

  cancel(){
    this.dialogRef.close(false);
  }

  save(){
    const updateObject = {
      umbuchung_id: this.wareneingangshistorie.umbuchung_id,
      bestell_nr: this.orderNoCtrl.value
    };

    this.api.updateGeneric(environment.BASE_URI + 'wareneingang/update_bestellung.php', updateObject).subscribe(
      response => {
        this.messageService.infoMessage('Die Bestellung wurde erfolgreich korrigiert!');
        this.dialogRef.close(true);
      }, 
      error => {
        this.messageService.errorMessage('Fehler beim Korrigieren aufgetreten!', error);
        this.dialogRef.close(false);
      }
    );

  }  

}
