import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TableDescriptor, ToolbarButton } from '../../../components/kiene-table/table-descriptor';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '../../../services/message.service';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import {
    EmailGesendetTableColumn,
    GedrucktTableColumn,
    KieneBooleanTableColumn,
    TableColumn
} from '../../../components/kiene-table/table-column';
import { Condition, Operator, TableOption, TableOptionEvent } from '../../../components/kiene-table/table-option';
import { MassnahmenplanSchwein } from './massnahmenplan-schwein';
import { ConfirmDeleteDialogComponent } from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CsvUploadDialogComponent } from '../csv-upload-dialog/csv-upload-dialog.component';
import { MassnahmenplanStatus } from '../classes/massnahmenplan-status';
import {
    ConfirmWithOptionDialogInput,
    ConfirmWithOptionDialogResult,
} from '../../../dialogs/confirm-with-option-dialog/confirm-with-option-dialog';
import { KieneKeyValue } from '../../../../../projects/kiene-core/src/lib/services/backend-api/classes/kiene-key-value';
import {
    ConfirmWithOptionDialogComponent
} from '../../../dialogs/confirm-with-option-dialog/confirm-with-option-dialog.component';
import { MassnahmenplanSchweinService } from './massnahmenplan-schwein.service';
import {
    FilterItem,
    FilterItemType
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import { Benutzerprofil } from '../../../../../projects/kiene-core/src/lib/components/stammdaten/mein-profil/mein-profil';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { Signature } from '../../../../../projects/kiene-core/src/lib/components/unterschriften/signature';
import {
    UnterschriftenService
} from '../../../../../projects/kiene-core/src/lib/components/unterschriften/unterschriften.service';
import { TabinfoService } from '../../../components/tabinfo/tabinfo.service';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'kiene-massnahmenplan-schwein',
    templateUrl: './massnahmenplan-schwein.component.html',
    styleUrls: ['./massnahmenplan-schwein.component.scss'],
})
export class MassnahmenplanSchweinComponent implements OnInit, OnDestroy {
    tableDescriptor: TableDescriptor = new TableDescriptor();
    selectedTab = 1;
    watchCurrentClientSubscription: Subscription;
    benutzerProfil: Benutzerprofil;
    anzahlOffenePlaene = 0;
    anzahlUnterschreibbarePlaene = 0;
    ladeAlleErstelltenSubscription: Subscription;

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private tabInfoService: TabinfoService,
        private api: KieneBackendApiService,
        private unterschriftService: UnterschriftenService,
        private mnpSchweinService: MassnahmenplanSchweinService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
    ) {
    }

    ngOnInit() {
        this.ladeBenutzerprofil();

        this.tableService.watchReload().subscribe({
            next: () => {
                this.ladeAlleErstellten();
                this.ladeAlleZumUnterschreiben();
            }
        });

        this.watchCurrentClientSubscription = this.localStorageService
            .watchCurrentClient()
            .subscribe(() => {
                this.tableService?.reload();
                this.ladeAlleZumUnterschreiben();
                this.ladeAlleErstellten();
            });

        this.tableDescriptor.headline = 'Maßnahmenpläne Schwein';
        this.tableDescriptor.uniqueIdentifier = 'Maßnahmenpläne Schwein';
        this.tableDescriptor.apiUrl =
            this.apiBaseUrl + 'massnahmenplan_schwein/';
        this.tableDescriptor.route = '/belege/massnahmenplaeneschwein/';
        this.tableDescriptor.detailsRoute = '../massnahmenplaeneschwein/';
        this.tableDescriptor.nameOfIdField = 'mnp_schwein_id';
        this.tableDescriptor.searchPlaceholder = 'Maßnahmenpläne durchsuchen';
        this.tableDescriptor.showDetailsAsOption = true;

        this.tableDescriptor.standardCreate = false;

        this.tableDescriptor.filterItems = this.getFilterItems();

        this.tableDescriptor.addToolbarButton(
            new ToolbarButton('csv_upload', 'file_upload', 3607, 'CSV Upload')
        );
        this.tableDescriptor.addToolbarButton(
            new ToolbarButton(
                'anlage_a',
                'attachment',
                3606,
                'Anlage A herunterladen'
            )
        );

        this.tableDescriptor.columns.push(
            new TableColumn('mnp_schwein_id', 'Nr.')
        );
        this.tableDescriptor.columns.push(new TableColumn('kunde', 'Kunde'));
        this.tableDescriptor.columns.push(
            new TableColumn('zusatz', 'Kundenzusatz')
        );
        this.tableDescriptor.columns.push(new TableColumn('_0_jahr', 'Jahr'));
        this.tableDescriptor.columns.push(
            new TableColumn('_0_halbjahr', 'Kalenderhalbjahr')
        );

        this.tableDescriptor.columns.push(new TableColumn('tierart', 'Tierart'));
        this.tableDescriptor.columns.push(new TableColumn('status', 'Status'));
        this.tableDescriptor.columns.push(new KieneBooleanTableColumn('abgabebeleg_id', 'Abgerechnet'));
        this.tableDescriptor.columns.push(new KieneBooleanTableColumn('unterschrift_kunde', 'Unterschrieben'));
        this.tableDescriptor.columns.push(
            new TableColumn('editiert', 'Editiert', 'datetime')
        );
        this.tableDescriptor.columns.push(new TableColumn('pdf_id', '', 'pdf'));
        this.tableDescriptor.columns.push(new EmailGesendetTableColumn());
        this.tableDescriptor.columns.push(new GedrucktTableColumn());

        this.tableDescriptor.options.push(
            new TableOption('duplicate', 'duplizieren', 'file_copy', 1186)
        );
        this.tableDescriptor.options.push(
            new TableOption('sign', 'unterschreiben', 'edit_note', 1153, {
                conditions: [
                    new Condition('unterschrift_kunde', Operator.GLEICH, 0)
                ]
            })
        );
        this.tableDescriptor.options.push(
            new TableOption('delete', 'löschen', 'delete', 1184)
        );
        this.tableDescriptor.options.push(
            new TableOption('reopen', 'wieder öffnen', 'lock_open', 1187)
        );
        this.tableDescriptor.options.push(
            new TableOption(null, 'Drucken', 'print', 1188, {
                void: this.gedruckt.bind(this),
                conditions: [new Condition('pdf_id', Operator.EXISTIERT, null)]
            })
        );

        this.tableDescriptor.permissionCreate = 1182;
        this.tableDescriptor.permissionUpdate = 1183;
        this.tableDescriptor.permissionDelete = 1184;

        this.tableDescriptor.initialSortColumn = 'mnp_schwein_id';
        this.tableDescriptor.initialSortDirection = 'desc';

        this.ladeAlleErstellten();
        this.ladeAlleZumUnterschreiben();
    }

    gedruckt(mnp: MassnahmenplanSchwein) {
        this.api.get(this.apiBaseUrl + 'massnahmenplan_schwein/gedruckt.php', new HttpParams().set('mnp_schwein_id', mnp.mnp_schwein_id)).subscribe({
            next: () => {
                this.tableService.reload('gedruckt');
                this.mnpSchweinService.showPdf(mnp);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }


    ladeBenutzerprofil() {
        this.api.get(this.apiBaseUrl + 'benutzerprofil/read_one_kundenportal.php').subscribe({
            next: response => {
                this.benutzerProfil = <Benutzerprofil>response;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    getFilterItems() {
        const filterItems = [];
        if (this.localStorageService.getCurrentUser().tierarzt_id) {
            filterItems.push(new FilterItem('Nur meine Tierarztgruppen', 1, FilterItemType.CHECKBOX, 'meinetierarztgruppen', false));
        }
        return filterItems;
    }

    hasPermission(permissionId: number): boolean {
        return this.localStorageService.currentUserHasPermission(permissionId);
    }

    catchOptionEvent(event: TableOptionEvent) {
        const mps: MassnahmenplanSchwein = event.value;
        if (event.name === 'details') {
            console.log('DETAILS: ', event);
            if (
                this.hasPermission(1183) &&
                event.value.status_id !== MassnahmenplanStatus.ABGESCHLOSSEN
            ) {
                this.router
                    .navigate(
                        [
                            '../massnahmenplaeneschwein',
                            event.value[this.tableDescriptor.nameOfIdField],
                        ],
                        {
                            relativeTo: this.route,
                        }
                    )
                    .then();
            } else {
                this.mnpSchweinService.showPdf(mps);
            }
        } else if (event.name === 'add') {
            this.router
                .navigate(['belege', 'massnahmenplaeneschwein', 'neu'])
                .then();
        } else if (event.name === 'showPdf') {
            this.mnpSchweinService.showPdf(mps);
        } else if (event.name === 'duplicate') {
            this.duplicate(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        } else if (event.name === 'reopen') {
            this.reopen(event.value);
        } else if (event.name === 'anlage_a') {
            this.openAnlageA();
        } else if (event.name === 'csv_upload') {
            this.openUploadCsvDialog();
        } else if (event.name === 'sign') {
            this.signMnp(mps);
        }
    }

    openAnlageA() {
        const client = this.localStorageService.getCurrentClient();
        if (!client) {
            this.messageService.alertMessage(
                'Bitte zuerst einen Betrieb auswählen!'
            );
        }

        let jahr = new Date().getFullYear();
        let halbjahr = new Date().getMonth() < 6 ? 1 : 2;

        halbjahr = halbjahr - 1;
        if (halbjahr <= 0) {
            halbjahr = 2;
            jahr = jahr - 1;
        }

        const input = new ConfirmWithOptionDialogInput();
        input.headline = 'Anlage A herunterladen';
        input.text = 'Für ' + jahr + '/' + halbjahr;
        input.label = 'Gewicht';
        input.okButton = 'Herunterladen';
        input.addOption(new KieneKeyValue('15', 'Aufzuchtferkel'));
        input.addOption(new KieneKeyValue('19', 'Mastschweine'));
        input.addOption(new KieneKeyValue('2', 'Sauen'));
        input.addOption(new KieneKeyValue('17', 'Saugferkel'));

        const dialogRef = this.dialog.open(ConfirmWithOptionDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmWithOptionDialogResult) => {
                if (result?.confirmed) {
                    const tierart_id = Number(result.option.key)

                    this.mnpSchweinService.openAnlageA(
                        client.kunde_id,
                        jahr,
                        halbjahr,
                        tierart_id,
                        true,
                        false,
                        false
                    );
                }
            },
        });
    }

    openUploadCsvDialog() {
        const dialogRef = this.dialog.open(CsvUploadDialogComponent);
        dialogRef.afterClosed().subscribe(() => {
            this.tableService?.reload();
        });
    }

    reopen(mps: MassnahmenplanSchwein) {
        this.api
            .post(
                this.apiBaseUrl + 'massnahmenplan_schwein/korrigieren.php',
                mps,
                null
            )
            .subscribe({
                next: () => {
                    this.messageService.infoMessage(
                        'Massnahmenplan wieder geöffnet!'
                    );
                    this.tableService?.reload();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Massnahmenplan konnte nicht wieder geöffnet werden!',
                        err
                    );
                },
            });
    }

    duplicate(mps: MassnahmenplanSchwein) {
        this.api
            .duplicateEntity(this.apiBaseUrl + 'massnahmenplan_schwein/duplicate.php', mps)
            .subscribe({
                next: (response) => {
                    this.messageService.infoMessage(
                        'Der Maßnahmenplan wurde erfolgreich dupliziert!'
                    );
                    this.router
                        .navigate([
                            'belege',
                            'massnahmenplaeneschwein',
                            response.mnp_schwein_id,
                        ])
                        .then();
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Der Maßnahmenplan konnte nicht dupliziert werden!',
                        error
                    );
                },
            });
    }

    openDeleteDialog(mps: MassnahmenplanSchwein) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Maßnahmenplan wirklich löschen?',
                text: 'Maßnahmenplan wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.api
                    .deleteEntity(
                        this.apiBaseUrl + 'massnahmenplan_schwein/delete.php',
                        mps
                    )
                    .subscribe({
                        next: () => {
                            this.messageService.infoMessage(
                                'Der Maßnahmenplan wurde erfolgreich gelöscht!'
                            );
                            this.tableService?.reload();
                        },
                        error: (error) => {
                            this.messageService.errorMessage(
                                'Der Maßnahmenplan konnte nicht gelöscht werden! ',
                                error
                            );
                        },
                    });
            }
        });
    }

    ngOnDestroy() {
        this.watchCurrentClientSubscription.unsubscribe();
    }

    alleUnterschreiben() {
        if (this.benutzerProfil?.unterschrift_png) {
            this.mnpSchweinService.ladeAlleZumUnterschreiben().subscribe({
                next: response => {
                    this.mnpSchweinService.dokumenteUnterschreiben(response.records);
                },
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        } else {
            const signature = new Signature();
            signature.benutzer = 1;
            this.unterschriftService.unterschreiben(signature).subscribe({
                next: result => {
                    if (result) {
                        this.api.get(this.apiBaseUrl + 'benutzerprofil/read_one_kundenportal.php').subscribe({
                            next: response => {
                                this.benutzerProfil = <Benutzerprofil>response;
                                this.alleUnterschreiben();
                            },
                            error: err => {
                                this.messageService.errorMessage('Fehler!', err);
                            }
                        });
                    }
                }, error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        }
    }

    alleAbschliessen() {
        this.ladeAlleErstelltenSubscription?.unsubscribe();
        this.ladeAlleErstelltenSubscription = this.mnpSchweinService.ladeAlleZumAbschliessen().subscribe({
            next: response => {

                for (const plan of response.records) {
                    this.mnpSchweinService.abschliessen(plan).subscribe({
                        next: () => {
                            this.tableService.reload();
                            this.tabInfoService.setReload('massnahmenplan_schwein/tabinfo.php');
                            this.tabInfoService.setReload('massnahmenplan_rind/tabinfo_gesamt.php');
                        },
                        error: err => {
                            console.error('MNP Rind mit der ID ' + plan.mnp_rind_id + ' konnte nicht abgeschlossen werden: ', err);
                            this.tabInfoService.setReload('massnahmenplan_schwein/tabinfo.php');
                            this.tabInfoService.setReload('massnahmenplan_rind/tabinfo_gesamt.php');
                        }
                    });
                }
            }
        });
    }


    private signMnp(mnp: MassnahmenplanSchwein) {

        if (this.benutzerProfil?.unterschrift_png) {
            this.mnpSchweinService.dokumentUnterschreiben(mnp);
        } else {
            const signature = new Signature();
            signature.benutzer = 1;
            this.unterschriftService.unterschreiben(signature).subscribe({
                next: result => {
                    if (result) {
                        this.mnpSchweinService.dokumentUnterschreiben(mnp);
                    }
                }
            });
        }
    }


    private ladeAlleErstellten() {
        this.mnpSchweinService.ladeAlleZumAbschliessen()?.subscribe({
            next: response => {
                this.anzahlOffenePlaene = response.count;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private ladeAlleZumUnterschreiben() {
        this.mnpSchweinService.ladeAlleZumUnterschreiben().subscribe({
            next: response => {
                this.anzahlUnterschreibbarePlaene = response.count;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}
