import { AbgabebelegPosition } from '../../abgabebelege/classes/abgabebeleg-position';
import { Tierreklamation } from './tierreklamation';
import { Tierbemerkung } from './tierbemerkung';

export class GruppeTier {
    gt_id: number;
    gruppe_id: number;
    gruppe: string;

    kunde_id: number;
    kunde: string;
    kunde_zusatz: string;
    geschlecht: string;
    geschlecht_id: number;
    csv_import: number;
    tier_id: number;
    tier_id_mutter: number;
    geburtsdatum: Date;
    ohrmarke: string;
    reklamationen: Tierreklamation[] = [];
    bemerkungen: Tierbemerkung[] = [];
    abteil: string;
    abteil_abgang: string;
    ohrmarke15: string;
    ohrmarke_kurz: string;
    ohrmarke_gescannt: number;
    ohrmarke_notnull: string;
    pass_gescannt: number;
    pass_gescannt_manuell: number; // nur fürs schreiben in DB
    erstellt: Date;
    editiert: Date;
    abgabebelegpositionen: AbgabebelegPosition[] = [];
    hit_gemeldet: number;
    hit_fehler_text: string;
    hit_fehler_einstallen: string;
    hit_fehler_abgang: string;
    hit_fehler_tod: string;
    anzahl_abteil: number;
    anzahl_viehhaendler: number;
    alter_tage: number;
    alter_wochen: number;
    wochen_einstallung: number;
    viehhaendler: string;
    viehhaendler_id: number;
    kunde_einstallen: string;
    schlachtdatum_kalb: Date;
    sgs: number;
    hit_todesdatum: Date;
    kp_todesdatum: Date;
    todesdatum_notnull: Date;
    hit_abgangsdatum: Date;
    kp_abgangsdatum: Date;
    kp_abgangspreis: number;
    kp_einstallpreis: number;
    abgangsdatum_notnull: Date;
    hit_einstalldatum: Date;
    kp_einstalldatum: Date;
    einstalldatum_notnull: Date;
    hit_lebendgewicht: number;
    hit_schlachtgewicht: number;
    hit_schlachtnummer: number;
    hit_schlachtdatum: Date;
    checked = false;
    status_id_abgang: number;
    status_id_einstallen: number;
    status_id_tod: number;
    status_id_gesamt: number;
    rasse: string;
    rasse_kuerzel: string;
    tierliste_id: number;
    tierliste_kommentar: string;
    grund_entfernung: string;
    transpondernummer: string;
    sgs_id: number;
    entsorger: string;
    entsorger_id: number;
    todesart_id: number;
    pdf_id_pass: number;
    schlachthof: string;
    schlachthof_vvvo: string;

    einstallgewicht: number; // nur schreiben, nicht lesen
    herkunft_betrieb: string;
    herkunft_kunde_id: number;
    herkunft_ort: string;
    herkunft_ortsteil: string;
    herkunft_plz: string;
    herkunft_strasse: string;
    herkunft_vvvo: string;

}

export class GeburtTier {
    gruppe_id: number;
    tier_id_mutter: number;
    ohrmarke_kurz: string;
    geburtsdatum: Date;
    rasse_id: number;
    geschlecht_id: number;
    geburtsgewicht: number;
}
