import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';
import {TextProposalService} from '../laboratory/text-autocomplete/text-proposal.service';
import {TextProposalResult} from '../interfaces/text-proposal-interface';
import {MatDialog,} from '@angular/material/dialog';
import {MessageService} from '../services/message.service';
import {AddTextProposalDialogComponent} from '../dialogs/add-text-proposal-dialog/add-text-proposal-dialog.component';

@Directive({
    selector: '[kieneTextProposal]',
})
export class TextProposalDirective {
    @Output('textChanged') textChanged = new EventEmitter<string>();

    constructor(
        private el: ElementRef,
        private service: TextProposalService,
        public dialog: MatDialog,
        public messageService: MessageService
    ) {
        //
    }

    @HostListener('keyup', ['$event']) checkForProposal(event) {
        if (event.key === '#') {
            const text = this.el.nativeElement.value;
            // let pattern = new RegExp("([ ]{1}[A-Za-z]+#)");
            const pattern = new RegExp('([A-Za-z]+#)');
            const match = pattern.exec(text);
            if (match) {
                const shortcut = match[0].substring(0, match[0].length - 1);
                const startIndex = match.index;
                const stopIndex = startIndex + shortcut.length + 2;

                this.service.loadTextProposalForKey(shortcut).subscribe(
                    (response) => {
                        const value: TextProposalResult = response;
                        const newText = text.replace(
                            text.substring(startIndex, stopIndex),
                            value.wortgruppe
                        );
                        // this.el.nativeElement.value = newText;
                        this.textChanged.emit(newText);
                    },
                    (error) => {
                        const dialogRef = this.dialog.open(
                            AddTextProposalDialogComponent,
                            {
                                width: '350px',
                                data: {kuerzel: shortcut},
                            }
                        );

                        dialogRef.afterClosed().subscribe((result) => {
                            if (result) {
                                this.service
                                    .saveTextForKuerzel(shortcut, result)
                                    .subscribe({
                                        next: (response) => {
                                            const newText = text.replace(
                                                text.substring(
                                                    startIndex,
                                                    stopIndex
                                                ),
                                                response.wortgruppe
                                            );
                                            this.textChanged.emit(newText);
                                        },
                                        error: (err) => {
                                            this.messageService.errorMessage(
                                                'Text für Kürzel ' +
                                                shortcut +
                                                ' konnte nicht gespeichert werden!',
                                                err
                                            );
                                        }
                                    });
                            }
                        });
                    }
                );
            }
        }
    }
}
