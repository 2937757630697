import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IdentifikationApi } from './identifikation';
import { AnwendungApi } from './anwendung';
import { Behandlungsanfrage, BehandlungsanfrageApi } from './behandlungsanfrage';
import { IndikationApi } from '../../warehouse/material/artikel-indikation/indikation';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable()
export class BehandlungsanfrageService {

    constructor(private http: HttpClient,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    getById(id: number): Observable<Behandlungsanfrage> {
        return this.http.get<Behandlungsanfrage>(this.apiBaseUrl + 'behandlungsanfrage/read_one.php?bestellung_id=' + id);
    }

    save(bi: Behandlungsanfrage, mandantIdKunde: number): Observable<Behandlungsanfrage> {
        let headers;
        if (mandantIdKunde !== undefined) {
            headers = { 'MANDANTID-KUNDE': mandantIdKunde.toString() };
        }
        return this.http.post<Behandlungsanfrage>(this.apiBaseUrl + 'behandlungsanfrage/update.php', bi, { headers: headers });
    }

    saveBehandlungsanfrage(behandlungsanfrage: Behandlungsanfrage): Observable<Behandlungsanfrage> {
        return this.http.post<Behandlungsanfrage>(this.apiBaseUrl + 'behandlungsanfrage/create.php', behandlungsanfrage);
    }

    updateBehandlungsanfrage(behandlungsanfrage: Behandlungsanfrage, mandantIdKunde: number): Observable<Behandlungsanfrage> {
        let headers;
        if (mandantIdKunde !== undefined) {
            headers = { 'MANDANTID-KUNDE': mandantIdKunde.toString() };
        }
        return this.http.post<Behandlungsanfrage>(this.apiBaseUrl + 'behandlungsanfrage/update.php', behandlungsanfrage, { headers: headers });
    }

    getIdentifikation(clientId: number): Observable<IdentifikationApi> {
        return this.http.get<IdentifikationApi>(this.apiBaseUrl + 'identifikation/read.php?kunde_id=' + clientId);
    }

    searchIdentifikationen(clientId: number, searchString: string): Observable<IdentifikationApi> {
        return this.http.get<IdentifikationApi>(this.apiBaseUrl + 'identifikation/read.php?kunde_id=' + clientId + '&search=' + searchString + '&limit=10');
    }

    getIndikationen(materialId: number): Observable<IndikationApi> {
        return this.http.get<IndikationApi>(this.apiBaseUrl + 'indikation/read.php?artikel_id=' + materialId);
    }

    searchIndikationen(materialId: number, searchString: string): Observable<IndikationApi> {
        return this.http.get<IndikationApi>(this.apiBaseUrl + 'indikation/read.php?artikel_id=' + materialId + '&search=' + searchString);
    }

    getAnwendungen(materialId: number): Observable<AnwendungApi> {
        return this.http.get<AnwendungApi>(this.apiBaseUrl + 'anwendung/read.php?artikel_id=' + materialId);
    }

    searchAnwendungen(materialId: number, searchString: string): Observable<AnwendungApi> {
        return this.http.get<AnwendungApi>(this.apiBaseUrl + 'anwendung/read.php?artikel_id=' + materialId + '&search=' + searchString);
    }

    transformToAbgabebeleg(behandlungsanfrage: Behandlungsanfrage): Observable<any> {
        return this.http.post(this.apiBaseUrl + 'abgabebeleg/transform.php', behandlungsanfrage);
    }

    duplicate(behandlungsanfrage: Behandlungsanfrage, mandantIdKunde?: number): Observable<Behandlungsanfrage> {
        let headers;
        if (mandantIdKunde !== undefined) {
            headers = { 'MANDANTID-KUNDE': mandantIdKunde.toString() };
        }
        return this.http.post<Behandlungsanfrage>(this.apiBaseUrl + 'behandlungsanfrage/duplicate.php', behandlungsanfrage);
    }

}
