import {Entsorger} from '../entsorger';
import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {StammdatenService} from '../../stammdaten.service';
import {MessageService} from '../../../../services/message-service/message.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {KieneBackendApiService} from '../../../../services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../kiene-core.config';
import {AppService} from '../../../../services/app/app.service';
import {EntsorgerDetailsDialogComponent} from '../entsorger-details-dialog/entsorger-details-dialog.component';

@Component({
    selector: 'kiene-entsorger-favoriten',
    templateUrl: './entsorger-uebersicht.component.html',
    styleUrls: ['./entsorger-uebersicht.component.scss'],
})
export class EntsorgerUebersichtComponent implements OnInit, OnDestroy {
    favoritenEntsorger: Entsorger[];
    entsorgerFehlt = this.stammdatenService.getEntsorgerFehlt();
    watchEntsorgerFehltSub: Subscription;
    entsorgerFavoritenSubscription: Subscription;
    @Input('showReturnButton') showReturnButton = true;

    constructor(
        private stammdatenService: StammdatenService,
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute,
        private appService: AppService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.ladeFavoriten();

        this.watchEntsorgerFehltSub = this.stammdatenService
            .watchEntsorgerFehlt()
            .subscribe((fehlt) => {
                this.entsorgerFehlt = fehlt;
            });
    }

    ngOnDestroy(): void {
        this.watchEntsorgerFehltSub?.unsubscribe();
        this.entsorgerFavoritenSubscription?.unsubscribe();
    }

    ladeFavoriten(): void {
        this.entsorgerFavoritenSubscription?.unsubscribe();
        this.entsorgerFavoritenSubscription = this.stammdatenService.loadEntsorgerFavoriten().subscribe({
            next: (data) => {
                this.favoritenEntsorger = [];
                this.favoritenEntsorger = data.records;
            },
            error: (err: any) => {
                this.messageService.errorMessage(
                    'Fehler beim Laden aller Entsorger',
                    err
                );
            },
        });
    }

    public updateFavoriten(updatedEntsorger: Entsorger) {
        if (updatedEntsorger.favorit === 1) {
            this.favoritenEntsorger.push(updatedEntsorger);
        } else {
            this.favoritenEntsorger = this.favoritenEntsorger.filter(
                (entsorger) =>
                    entsorger.entsorger_id !== updatedEntsorger.entsorger_id
            );
        }
    }

    editiereEntsorger(entsorger: Entsorger): void {
        const dialogRef = this.dialog.open(EntsorgerDetailsDialogComponent, {
            minWidth: this.appService?.isMobileScreen ? '100vw' : '400px',
            width: this.appService?.isMobileScreen ? '100%' : '',
            data: {
                entsorger: entsorger
            }
        });
        dialogRef.afterClosed().subscribe({
            next: () => {
                this.ladeFavoriten();
            }
        });
    }

    entsorgerHinzufuegen() {
        if (!this.appService.isMobileScreen) {
            const dialogRef = this.dialog.open(EntsorgerDetailsDialogComponent);
            dialogRef.afterClosed().subscribe({
                next: () => {
                    this.ladeFavoriten();
                }
            });

        } else {
            this.router.navigate(['details'], {relativeTo: this.route}).then();
        }
    }

    sendMail(entsorger: Entsorger) {
        if (entsorger.email) {
            const htmlAnchorElement = document.createElement('a');
            htmlAnchorElement.style.display = 'none';
            htmlAnchorElement.href = 'mailto:' + entsorger.email;
            document.body.appendChild(htmlAnchorElement);
            htmlAnchorElement.click();
            // document.body.removeChild(htmlAnchorElement);
        }
    }

    entsorgerLoeschen(entsorger: Entsorger) {
        if (entsorger) {
            entsorger.favorit = 0;
            this.api.post(this.apiBaseUrl + 'entsorger/favorit.php', entsorger).subscribe({
                next: response => {
                    this.messageService.infoMessage('Der Entsorger wurde erfolgreich entfernt!');
                    this.ladeFavoriten();
                },
                error: err => {
                    this.messageService.errorMessage('Fehler', err);
                }
            });
        }
    }
}
