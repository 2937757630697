import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../app.auth.guard';
import {AdministrationComponent} from './administration.component';
import {PermissionOverviewComponent} from './permission-overview/permission-overview.component';
import {TextBausteinComponent} from "./text-baustein/text-baustein.component";
import {UserDetailsComponent} from './user/user-details/user-details.component';
import {UserComponent} from './user/user.component';
import {UserroleComponent} from './userrole/userrole.component';
import {VetGroupComponent} from './vet-group/vet-group.component';
import {VetComponent} from './vet/vet.component';
import {InventurVerwaltungComponent} from './inventur-verwaltung/inventur-verwaltung.component';
import {MandantenComponent} from './mandanten/mandanten/mandanten.component';

const routes: Routes = [
    {
        path: '', component: AdministrationComponent, canActivate: [AuthGuard], children: [
            {path: 'benutzer', component: UserComponent, canActivate: [AuthGuard]},
            {path: 'benutzer/:id', component: UserDetailsComponent, canActivate: [AuthGuard]},
            {path: 'benutzerrollen', component: UserroleComponent, canActivate: [AuthGuard]},
            {path: 'benutzerrechte', component: PermissionOverviewComponent, canActivate: [AuthGuard]},
            {path: 'tieraerzte', component: VetComponent, canActivate: [AuthGuard]},
            {path: 'tierarztgruppen', component: VetGroupComponent, canActivate: [AuthGuard]},
            {path: 'inventur-verwaltung', component: InventurVerwaltungComponent, canActivate: [AuthGuard]},
            {path: 'textbaustein', component: TextBausteinComponent, canActivate: [AuthGuard]},
            {path: 'mandanten', component: MandantenComponent, canActivate: [AuthGuard]},
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdministrationRoutingModule {
}
