import { Gruppe } from '../classes/gruppe';

export class GruppeAuswaehlenDialogInput {
    mehrereGruppen = false;
    kunde_id: number;
    except_gruppe_id: number;
}

export class GruppeAuswaehlenDialogResult {
    confirmed = false;
    gruppe: Gruppe;
    mehrereGruppen = false;
}