<mat-tab-group (selectedTabChange)="tabChanged($event)" headerPosition="above" mat-stretch-tabs
    style="height: calc(100vh - 52px); width: 100%;">

    <mat-tab>
        <ng-template data-cy="einstallen-tab-pass-ohrmarke" matTabLabel>
            <mat-icon>dashboard</mat-icon>
        </ng-template>
        <kiene-einstallen-tiere-hinzufuegen [gruppe]="gruppe"
            [showPassReiter]="showPassReiter"></kiene-einstallen-tiere-hinzufuegen>
    </mat-tab>

    <mat-tab *ngIf="showNumberpadTab" [disabled]="!gruppe" data-cy="einstallen-tab-number-pad">
        <ng-template matTabLabel>
            <mat-icon>dialpad</mat-icon>
        </ng-template>
        <kiene-einstallen-number-pad [isVisible]="currentTabIndex === 1"></kiene-einstallen-number-pad>
    </mat-tab>

    <mat-tab [disabled]="!gruppe" data-cy="einstallen-tab-melden">
        <ng-template matTabLabel>
            <mat-icon>list</mat-icon>
        </ng-template>
        <ng-template matTabContent>
            <kiene-einstallen-tiere-melden></kiene-einstallen-tiere-melden>
        </ng-template>
    </mat-tab>

</mat-tab-group>