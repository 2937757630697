import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TableDescriptor, } from '../../components/kiene-table/table-descriptor';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '../../services/message.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { EmailGesendetTableColumn, GedrucktTableColumn, KieneBooleanTableColumn, TableColumn } from '../../components/kiene-table/table-column';
import { HttpParams } from '@angular/common/http';
import { Condition, Operator, TableOption, TableOptionEvent, } from '../../components/kiene-table/table-option';

import { Bestandsbesuchprotokoll } from './bestandsbesuchsprotokoll';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { Subscription } from 'rxjs';
import { TabinfoService } from '../../components/tabinfo/tabinfo.service';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { Client } from '../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-bestandsbesuchsprotokoll',
    templateUrl: './bestandsbesuchsprotokoll.component.html',
    styleUrls: ['./bestandsbesuchsprotokoll.component.scss'],
})
export class BestandsbesuchsprotokollComponent implements OnInit, OnDestroy {
    tableDescriptor: TableDescriptor = new TableDescriptor();
    currentClient: Client;
    watchClientSubscription: Subscription;

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private pdfService: PdfViewerDesktopService,
        private api: KieneTableService,
        private tabinfoService: TabinfoService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit() {
        this.currentClient = this.localStorageService.getCurrentClient();

        this.watchClientSubscription = this.localStorageService
            .watchCurrentClient()
            .subscribe((response) => {
                if (response) {
                    this.currentClient = response;
                    this.tableDescriptor.createAllowed = true;
                    const kundeId: string =
                        this.currentClient.kunde_id.toString();
                    this.tableDescriptor.params = new HttpParams().set(
                        'kunde_id',
                        kundeId
                    );
                    this.tableService.reload();
                } else {
                    this.tableDescriptor.createAllowed = false;
                    this.tableDescriptor.params = null;
                    this.tableService.reload();
                }
            });

        this.tableService.watchDataLoadedCompleted().subscribe({
            next: () => {
                this.tabinfoService.setReload('bestandsbesuchsprotokoll/tabinfo.php');
            },
        });

        if (this.currentClient) {
            const kundeId: string = this.currentClient.kunde_id.toString();
            this.tableDescriptor.params = new HttpParams().set(
                'kunde_id',
                kundeId
            );
            this.tableDescriptor.createAllowed = true;
        } else {
            this.tableDescriptor.createAllowed = false;
        }

        this.tableDescriptor.headline = 'Bestandsbesuchsprotokolle';
        this.tableDescriptor.uniqueIdentifier = 'Bestandsbesuchsprotokolle';
        this.tableDescriptor.apiUrl =
            this.apiBaseUrl + 'bestandsbesuchsprotokoll/';
        this.tableDescriptor.route = '/belege/bestandsbesuch/';
        this.tableDescriptor.nameOfIdField = 'bbp_id';
        this.tableDescriptor.searchPlaceholder =
            'Bestandsbesuchsprotokolle durchsuchen';

        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetailsAsOption = true;

        this.tableDescriptor.columns.push(new TableColumn('bbp_id', 'Nr.'));
        this.tableDescriptor.columns.push(new TableColumn('kunde', 'Kunde'));
        this.tableDescriptor.columns.push(
            new TableColumn('datum', 'Datum', 'date')
        );
        this.tableDescriptor.columns.push(new TableColumn('status', 'Status'));
        this.tableDescriptor.columns.push(new KieneBooleanTableColumn('abgabebeleg_id', 'Abgerechnet'));
        this.tableDescriptor.columns.push(new TableColumn('pdf_id', 'PDF', 'pdf'));
        this.tableDescriptor.columns.push(new EmailGesendetTableColumn());
        this.tableDescriptor.columns.push(new GedrucktTableColumn());

        this.tableDescriptor.options.push(
            new TableOption('delete', 'löschen', 'delete', 3854)
        );
        this.tableDescriptor.options.push(
            new TableOption('duplicate', 'duplizieren', 'file_copy', 3855)
        );
        this.tableDescriptor.options.push(
            new TableOption(null, 'Drucken', 'print', 3858, { conditions: [new Condition('pdf_id', Operator.EXISTIERT, null)], void: this.gedruckt.bind(this) })
        );

        this.tableDescriptor.permissionView = 3851;
        this.tableDescriptor.permissionCreate = 3852;
        this.tableDescriptor.permissionUpdate = 3853;
        this.tableDescriptor.permissionDelete = 3854;

        this.tableDescriptor.initialSortColumn = 'datum';
        this.tableDescriptor.initialSortDirection = 'desc';
    }

    gedruckt(bbp: Bestandsbesuchprotokoll) {
        this.api.get(this.apiBaseUrl + 'bestandsbesuchsprotokoll/gedruckt.php', new HttpParams().set('bbp_id', bbp.bbp_id)).subscribe({
            next: () => {
                this.tableService.reload('gedruckt');
                this.openPdf(bbp);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    ngOnDestroy() {
        this.watchClientSubscription.unsubscribe();
        this.tableService = null;
    }

    openPdf(bbp: Bestandsbesuchprotokoll) {
        const input = new PdfViewerDialogInput(
            'bestandsbesuchsprotokoll/read_or_mail_pdf.php',
            new HttpParams().set('bbp_id', bbp?.bbp_id?.toString()),
            'Bestandsbesuchsprotokoll ' + bbp?.bbp_id?.toString(),
            {
                email: {
                    sende_mail: true,
                    kunde_id: bbp?.kunde_id,
                }
            }
        );
        this.pdfService.openPdfViewer(input).subscribe({
            next: () => {
                this.api.reload();
            }
        });
    }

    catchOptionEvent(event: TableOptionEvent) {
        const bbp: Bestandsbesuchprotokoll = event?.value;
        if (event.name === 'details') {
            if (this.localStorageService.currentUserHasPermission(3853)) {
                this.router.navigate(
                    [event.value[this.tableDescriptor.nameOfIdField]],
                    { relativeTo: this.route }
                );
            } else {
                this.openPdf(bbp);
            }
        } else if (event.name === 'add') {
            this.router.navigate(['belege', 'bestandsbesuch', 'neu']);
        } else if (event.name === 'showPdf') {
            this.openPdf(bbp);
        } else if (event.name === 'duplicate') {
            this.duplicate(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        }
    }

    duplicate(bbp: Bestandsbesuchprotokoll) {
        this.api
            .duplicateEntity(this.apiBaseUrl + 'bestandsbesuchsprotokoll/duplicate.php', bbp)
            .subscribe(
                (response) => {
                    this.messageService.infoMessage(
                        'Das Bestandsbesuchsprotokoll wurde erfolgreich dupliziert!'
                    );
                    this.router.navigate([
                        'belege',
                        'bestandsbesuch',
                        response.bbp_id,
                    ]);
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Der Maßnahmenplan konnte nicht dupliziert werden!',
                        error
                    );
                }
            );
    }

    openDeleteDialog(bbp: Bestandsbesuchprotokoll) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Bestandsbesuchprotokoll wirklich löschen?',
                text: 'Bestandsbesuchprotokoll wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.api
                    .deleteEntity(
                        this.apiBaseUrl + 'bestandsbesuchsprotokoll/delete.php',
                        bbp
                    )
                    .subscribe(
                        () => {
                            this.messageService.infoMessage(
                                'Das Bestandsbesuchprotokoll wurde erfolgreich gelöscht!'
                            );
                            this.tableService.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Das Bestandsbesuchprotokoll konnte nicht gelöscht werden! ',
                                error
                            );
                        }
                    );
            }
        });
    }
}
