<kiene-page-header [ueberschrift]="'Neue Gruppe anlegen'" [showReturnButton]="!alsDialog"></kiene-page-header>
<div style="padding: 16px; padding-bottom: 64px;">

    <h5>{{ kunde?.bezeichnung_final }}</h5>
    <p>{{ kunde?.zusatz_final }} {{ kunde?.vvvo }}</p>

    <mat-checkbox *ngIf="sgsEinblenden" [formControl]="sgsMeldenCtrl">An SGS melden</mat-checkbox>

    <mat-form-field style="width: 100%;">
        <mat-label>Produktionsabschnitt</mat-label>
        <mat-select [formControl]="produktionsabschnittCtrl" [compareWith]="produktionsabschnittComparator">
            <mat-option *ngFor="let p of produktionsabschnitte" [value]="p">{{ p.produktionsabschnitt }}</mat-option>
        </mat-select>
    </mat-form-field>

    <div style="display: flex; justify-content: center; align-items: center; flex-direction: column">
        <kiene-autocomplete #standortAutocomplete (deleteSelected)="deleteStall($event)"
                            (elementSelected)="setStandort($event)" *ngIf="kunde !== null"
                            [displayFields]="['stall','sgs_nummer']"
                            separator=", " [emitFreeText]="true" [focus]="true"
                            [genericApiUrl]="readStallKundenportalUrl"
                            [kieneObject]="standort" [params]="standortParams" [placeholder]="'Standort'"
                            [showClear]="false"
                            [showDeleteButton]="true" style="width: 100%">
        </kiene-autocomplete>

        <mat-form-field *ngIf="sgsNummerEinblenden" style="width: 100%">
            <mat-label>SGS Nummer</mat-label>
            <input [formControl]="sgsNummerCtrl" matInput type="text"/>
        </mat-form-field>

        <mat-form-field style="width: 100%;">
            <mat-label>Gruppenname</mat-label>
            <input [formControl]="nameCtrl" data-cy="gruppe-anlegen-gruppenname" matInput type="text">
        </mat-form-field>

        <mat-form-field *ngIf="kunde?.kaelbermast && kunde?.rind" style="width: 100%;">
            <mat-label>Aufstalleinheit</mat-label>
            <div class="one-row">
                <input [formControl]="aufstalleinheitJahrCtrl" data-cy="gruppe-anlegen-aufstalleinheit-jahr" matInput
                       max="99" maxlength="2" min="1"
                       style="width: 40px; margin-right: 12px; border: 1px solid lightgray; padding: 4px; border-radius: 4px;"
                       type="number">
                <span>- AE -</span>
                <input [formControl]="aufstalleinheitNummerCtrl" data-cy="gruppe-anlegen-aufstalleinheit-nummer"
                       matInput
                       style="width: 60px; margin-right: 12px; border: 1px solid lightgray; padding: 4px; border-radius: 4px;"
                       type="number">
            </div>
        </mat-form-field>

        <!--Schwein-->

        <ng-container *ngIf="!localStorageService.kostenpflichtigAktiv()">


            <mat-form-field style="width: 100%;">
                <mat-label>Einstalldatum von (optional)</mat-label>
                <input [formControl]="einstalldatumVonCtrl" [matDatepicker]="einstalldatum_von" [readonly]="true"
                       data-cy="gruppe-anlegen-einstalldatum-von" matInput>
                <mat-datepicker-toggle [for]="einstalldatum_von" data-cy="gruppe-anlegen-einstalldatum-von-toggle"
                                       matSuffix></mat-datepicker-toggle>
                <mat-datepicker #einstalldatum_von></mat-datepicker>
            </mat-form-field>

            <mat-form-field style="width: 100%;">
                <mat-label>Einstalldatum bis (optional)</mat-label>
                <input [formControl]="einstalldatumBisCtrl" [matDatepicker]="einstalldatum_bis" [readonly]="true"
                       data-cy="gruppe-anlegen-einstalldatum-bis" matInput>
                <mat-datepicker-toggle [for]="einstalldatum_bis" data-cy="gruppe-anlegen-einstalldatum-bis-toggle"
                                       matSuffix></mat-datepicker-toggle>
                <mat-datepicker #einstalldatum_bis></mat-datepicker>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="kunde?.schwein === 1">

            <div *ngIf="!kunde?.tierbewegung_melden">
                <mat-form-field style="margin-right: 12px;">
                    <mat-label>Anzahl Tiere</mat-label>
                    <input [formControl]="anzahlTiereCtrl" data-cy="gruppe-anlegen-anzahl-tiere" matInput maxlength="15"
                           type="number">
                </mat-form-field>

                <mat-form-field style="margin-right: 12px;">
                    <mat-label>Alter bei Einstallung (Tage)</mat-label>
                    <input [formControl]="alterEinstallungCtrl" data-cy="gruppe-anlegen-alter-einstallung" matInput
                           maxlength="15" type="number">
                </mat-form-field>
            </div>
        </ng-container>

        <button (click)="anlegen()" [disabled]="isLoading" color="accent" data-cy="gruppe-anlegen-anlegen-btn"
                mat-flat-button style="width: 67%">Anlegen
        </button>
    </div>

</div>
