<h1 mat-dialog-title>
    Kunden auswählen
    {{ currentClient ? "(" + currentClient.bezeichnung_final + ")" : "" }}
</h1>
<div mat-dialog-content>
    <kiene-autocomplete #betriebAutocomplete (elementSelected)="setCurrentClient($event)" [resetValue]="'kein Kunde'"
        [kieneObject]="null" [panelWidth]="'100%'" [params]="params" [genericApiUrl]="'kunde/search.php'"
        [placeholder]="'Kunden suchen'" [displayFields]="getDisplayFields()" [showClear]="false" [separator]="' | '"
        [panelOpen]="false">
    </kiene-autocomplete>
</div>