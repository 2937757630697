import {
    AfterViewInit,
    Component,
    ElementRef, Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {UnterschriftService} from './unterschrift.service';
import {SignaturePad} from 'angular2-signaturepad';
import {BenutzerSignatur} from './classes/benutzer-signatur';
import {Subscription} from 'rxjs';
import {MessageService} from '../../../services/message-service/message.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'kiene-unterschrift',
    templateUrl: './unterschrift.component.html',
    styleUrls: ['./unterschrift.component.scss'],
})
export class UnterschriftComponent implements OnInit, AfterViewInit, OnDestroy {
    signaturSubscription: Subscription;

    isDrawing = false;
    isSaving = false;
    options: any;
    signature: BenutzerSignatur = new BenutzerSignatur();
    currentCanvasPng = '';
    @ViewChild('signaturePad') pad: SignaturePad;
    @ViewChild('signaturePadRef') signaturePadRef: ElementRef;
    @ViewChild('signatureContainer') signatureContainer: ElementRef;

    landscape = window.matchMedia('(orientation: landscape)');

    constructor(
        public dialogRef: MatDialogRef<UnterschriftComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private unterschriftService: UnterschriftService,
        private messageService: MessageService
    ) {
    }

    ngOnDestroy(): void {
        this.signaturSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.loadSignatur();

        this.landscape.addEventListener('change', () => {
            this.resizeCanvas();
        });

        window.addEventListener(
            'orientationchange',
            () => {
                this.resizeCanvas();
            },
            false
        );
    }

    loadSignatur() {
        this.signaturSubscription?.unsubscribe();
        this.signaturSubscription = this.unterschriftService
            .getSignatur()
            .subscribe({
                next: (resp) => {
                    if (resp) {
                        this.signature = resp;
                        this.addSignaturToCanvas(
                            this.signature.unterschrift_png
                        );
                    }
                },
                error: (error) => {
                },
            });
    }

    ngAfterViewInit() {
        this.resizeCanvas();
    }

    saveUnterschrift() {
        this.isSaving = true;
        this.signature.unterschrift_png = this.pad.toDataURL();
        this.unterschriftService.saveSignature(this.signature).subscribe({
            next: () => {
                this.messageService.infoMessage(
                    'Die Unterschrift wurde erfolgreich gespeichert.'
                );
                this.isSaving = false;
                this.dialogRef.close();
            },
            error: (error) => {
                this.messageService.errorMessage(
                    'Die Unterschrift konnte nicht gespeichert werden!',
                    error
                );
                this.isSaving = false;
            },
        });
    }

    resizeCanvas() {
        this.pad.set(
            'canvasWidth',
            this.signatureContainer.nativeElement.offsetWidth - 6
        );
        this.pad.set(
            'canvasHeight',
            this.signatureContainer.nativeElement.offsetHeight
        );
        this.pad.set('backgroundColor', '#fff');
        if (!this.pad.isEmpty()) {
            this.addSignaturToCanvas(
                this.currentCanvasPng
                    ? this.currentCanvasPng
                    : this.signature.unterschrift_png
            );
        } else {
            this.pad.clear();
        }
    }

    drawStart() {
        this.isDrawing = true;
    }

    drawEnd() {
        this.isDrawing = false;
        this.currentCanvasPng = this.pad.toDataURL();
    }

    clear() {
        this.currentCanvasPng = null;
        this.pad.clear();
    }

    reload() {
        this.clear();
        this.addSignaturToCanvas(this.signature?.unterschrift_png);
    }

    private addSignaturToCanvas(canvas: string) {
        if (this.signature.unterschrift_png) {
            const canvasOption = {
                height: this.signatureContainer.nativeElement.offsetHeight,
                width: this.signatureContainer.nativeElement.offsetWidth - 6,
            };
            this.pad.fromDataURL(canvas, canvasOption);
        }
    }
}
