import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Client} from '../classes/client';
import {LocalStorageService} from '../../../services/local-storage/local-storage.service';
import {
    BetriebAuswaehlenDialogComponent
} from '../../../components/dialogs/betrieb-auswaehlen-dialog/betrieb-auswaehlen-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'kiene-client-select-button',
    templateUrl: './client-select-button.component.html',
    styleUrls: ['./client-select-button.component.scss']
})
export class ClientSelectButtonComponent implements OnInit {

    currentClient: Client;

    @Output('change') changeEmitter = new EventEmitter<Client>();

    constructor(private localStorageService: LocalStorageService,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.currentClient = this.localStorageService.getCurrentClient();

        this.localStorageService.watchCurrentClient().subscribe({
            next: (client: Client) => {
                this.currentClient = client;
                this.changeEmitter.emit(client);
            }
        });
    }

    clear() {
        this.localStorageService.setCurrentClient(undefined);
    }

    selectCurrentClient() {
        const dialogRef = this.dialog.open(BetriebAuswaehlenDialogComponent, {
            minWidth: '90vw',
            position: {top: '10px'}
        });
        dialogRef.afterClosed().subscribe({
            next: (client: Client) => {
                if (client) {
                    this.localStorageService.setCurrentClient(client);

                }
            }
        });
    }
}
