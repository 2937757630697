<h3 mat-dialog-title>Textbausteine</h3>
<mat-dialog-content>
  <div style="height: 100%;">
    <mat-form-field style="width: 100%;">
      <input #searchInput matInput type="text" placeholder="Suchen/Titel" [formControl]="searchCtrl"
        [matAutocomplete]="autocomplete">
      <mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="mapper"
        (optionSelected)="setTextbaustein($event)">
        <mat-option *ngFor="let tb of textbausteine | async" [value]="tb">
          <div class="one-row">
            {{tb.titel}} <button mat-icon-button (click)="deleteTextbaustein($event, tb)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <mat-label>{{textbausteinConfig.titel1}}</mat-label>
      <textarea matInput [formControl]="textCtrl1" rows="8"></textarea>
    </mat-form-field>

    <mat-form-field style="width: 100%;" *ngIf="textbausteinConfig.count>=2">
      <mat-label>{{textbausteinConfig.titel2}}</mat-label>
      <textarea matInput [formControl]="textCtrl2" rows="8"></textarea>
    </mat-form-field>

    <mat-form-field style="width: 100%;" *ngIf="textbausteinConfig.count>=3">
      <mat-label>{{textbausteinConfig.titel3}}</mat-label>
      <textarea matInput [formControl]="textCtrl3" rows="8"></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
  <span class="spacer"></span>
  <button mat-flat-button color="primary" (click)="take()">Übernehmen</button>
  <button *ngIf="isSaveVisible()" mat-flat-button color="primary" (click)="save()">
    <mat-icon>save</mat-icon> Übernehmen und speichern (Alt+S)
  </button>
</mat-dialog-actions>