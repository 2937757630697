import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from '../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {KieneTableService} from '../components/kiene-table/kiene-table.service';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    isKunde = true;

    currentTab: string;
    currentTabIndex = 0;

    tierarztId = 0;

    constructor(
        private localStorageService: LocalStorageService,
        private api: KieneTableService,
        public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.localStorageService.setCurrentClientVisible(
            false,
            'HomeComponent'
        );
        this.tierarztId = this.localStorageService.getCurrentUser().tierarzt_id;

        this.currentTab = this.route.snapshot.queryParamMap.get('tab');
        if (this.currentTab) {
            if (this.currentTab === 'tourenplan') {
                this.currentTabIndex = 0;
            } else if (this.currentTab === 'information') {
                this.currentTabIndex = 1;
            } else if (this.currentTab === 'meinbestand') {
                this.currentTabIndex = 2;
            } else if (this.currentTab === 'fortbildungen') {
                if (this.currentUserIsVet()) {
                    this.currentTabIndex = 3;
                } else {
                    this.currentTabIndex = 2;
                }
            } else if (this.currentTab === 'urlaubskalender') {
                this.currentTabIndex = 4;
            } else if (this.currentTab === 'wochenendplaner') {
                this.currentTabIndex = 5;
            } else {
                this.currentTabIndex = 0;
            }
        }
    }

    ngOnInit() {
        console.log('home component init');
        this.isKunde = this.localStorageService.isCurrentUserKunde();
    }

    currentUserIsVet() {
        return this.localStorageService.getCurrentUser()?.tierarzt_id;
    }

    isTabMenuVisible(path: string) {
        return this.localStorageService.currentUserCanSeeTab(path);
    }

    setSelectedTabIndex(tabIndex: number) {
        let tab = 'tourenplan';

        switch (tabIndex) {
            case 0: {
                tab = 'tourenplan';
                break;
            }
            case 1: {
                tab = 'information';
                break;
            }
            case 2: {
                if (this.currentUserIsVet()) {
                    tab = 'meinbestand';
                } else {
                    tab = 'fortbildungen';
                }
                break;
            }
            case 3: {
                if (this.currentUserIsVet()) {
                    tab = 'fortbildungen';
                } else {
                    tab = 'urlaubskalender';
                }
                break;
            }
            case 4: {
                if (this.currentUserIsVet()) {
                    tab = 'urlaubskalender';
                } else {
                    tab = 'urlaubskalender';
                }
                break;
            }
            case 5: {
                if (this.currentUserIsVet()) {
                    tab = 'wochenendplaner';
                } else {
                    tab = 'wochenendplaner';
                }
                break;
            }
            default:
                tab = 'tourenplan';
        }

        // if (tabIndex === 0) {
        //     tab = 'tourenplan';
        // } else if (tabIndex === 1) {
        //     tab = 'information';
        // } else if (tabIndex === 2 && this.currentUserIsVet()) {
        //     tab = 'meinbestand';
        // } else if (tabIndex === 3) {
        //     tab = 'fortbildungen';
        // } else if (tabIndex === 4) {
        //     tab = 'urlaubskalender';
        // }

        const params = {tab: tab};

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge',
        });
    }
}
