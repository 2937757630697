import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {TableColumn} from '../../components/kiene-table/table-column';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MessageService} from '../../services/message.service';
import {BetriebstypDetailsDialogComponent} from './betriebstyp-details-dialog/betriebstyp-details-dialog.component';

@Component({
    selector: 'kiene-betriebstypen',
    templateUrl: './betriebstypen.component.html',
    styleUrls: ['./betriebstypen.component.scss']
})
export class BetriebstypenComponent implements OnInit {

    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(public dialog: MatDialog, private messageService: MessageService, private localStorageService: LocalStorageService, private api: KieneTableService) {
    }

    ngOnInit() {

        this.tableDescriptor.headline = 'Betriebstypen';
        this.tableDescriptor.uniqueIdentifier = 'Betriebstypen';
        this.tableDescriptor.apiUrl = environment.BASE_URI + 'betriebstyp/';
        this.tableDescriptor.route = '/stammdaten/betriebstypen/';
        this.tableDescriptor.nameOfIdField = 'betriebstyp_id';
        this.tableDescriptor.searchPlaceholder = 'Betriebstypen durchsuchen';
        this.tableDescriptor.columns.push(new TableColumn('betriebstyp_id', 'ID'));
        this.tableDescriptor.columns.push(new TableColumn('betriebstyp', 'Betriebstyp'));
        this.tableDescriptor.columns.push(new TableColumn('impfstoff_zusatz', 'Impfstoffzusatz'));
        this.tableDescriptor.columns.push(new TableColumn('antibiotika_zusatz', 'Antibiotikazusatz'));

        this.tableDescriptor.permissionView = 4471;
        this.tableDescriptor.permissionCreate = 4472;
        this.tableDescriptor.permissionUpdate = 4472;
        this.tableDescriptor.permissionDelete = 4474;

        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;

        this.tableDescriptor.options.push(new TableOption('edit', 'bearbeiten', 'edit', 4472));
        this.tableDescriptor.options.push(new TableOption('delete', 'löschen', 'delete', 4474));

        this.tableDescriptor.initialSortColumn = 'betriebstyp';
        this.tableDescriptor.initialSortDirection = 'asc';

    }

    catchOptionEvent(event: TableOptionEvent) {
        console.debug('Option event ' + event.name + ' was clicked with object: ' + JSON.stringify(event.value));
        if (event.name === 'add') {
            const dialogRef = this.dialog.open(BetriebstypDetailsDialogComponent);
            dialogRef.afterClosed().subscribe({
                next: result => {
                    this.api.reload();
                }
            });
        } else if (event.name === 'edit') {
            const dialogRef = this.dialog.open(BetriebstypDetailsDialogComponent, {
                data: {
                    betriebstyp: event.value
                }
            });
            dialogRef.afterClosed().subscribe({
                next: result => {
                    this.api.reload();
                }
            });
        } else if (event.name === 'delete') {
            const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
                data: {
                    title: 'Betriebstyp löschen',
                    text: 'Wollen Sie den Betriebstypen wirklich löschen?'
                }
            });
            dialogRef.afterClosed().subscribe({
                next: result => {
                    if (result) {
                        this.api.deleteEntity(environment.BASE_URI + 'betriebstyp/delete.php', event.value).subscribe({
                            next: response => {
                                this.messageService.infoMessage('Der Betriebstyp wurde erfolgreich gelöscht!');
                                this.api.reload();
                            },
                            error: err => {
                                this.messageService.errorMessage('Der Betriebstyp konnte nicht gelöscht werden!', err);
                            }
                        });
                    }
                }
            });
        }
    }


}
