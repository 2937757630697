import {Component, Inject, OnInit} from '@angular/core';
import {
    LocalStorageService
} from '../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {WarenkorbService} from '../wa-wi/warenkorb/warenkorb.service';
import {KieneTableService} from '../components/kiene-table/kiene-table.service';
import {MessageService} from '../services/message.service';
import {API_BASE_URL_SERVICE} from '../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'app-warehouse',
    templateUrl: './warehouse.component.html',
    styleUrls: ['./warehouse.component.css'],
})
export class WarehouseComponent implements OnInit {
    isInventurActive = false;

    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private warenkorbService: WarenkorbService,
        private messageService: MessageService
    ) {
        this.localStorageService.setCurrentClientVisible(false);
        this.localStorageService.setShoppingCartVisible(true);
    }

    ngOnInit() {
        this.warenkorbService.getWarenkorbContent().subscribe({
            next: (response) => {
                const len: number = response.records.length;
                this.localStorageService.setNumberOfShoppingCartItems(len);
            },
            error: (err) => {
                this.localStorageService.setNumberOfShoppingCartItems(0);
            },
        });

        this.tableService
            .get(
                `${this.apiBaseUrl}inventur_eintrag/read_aktiv.php`
            )
            .subscribe({
                next: (resp) => {
                    this.isInventurActive = resp.records.length > 0;
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Inventur konnte nicht geladen/überprüft werden!',
                        err
                    );
                },
            });
    }

    isTabMenuVisible(path: string) {
        return this.localStorageService.currentUserCanSeeTab(path);
    }
}
