import {Injectable} from "@angular/core";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
    LocalStorageService
} from "../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service";
import {
    ChangeCurrentClientDialogComponent
} from "./change-current-client-dialog/change-current-client-dialog.component";
import {Observable, Subject} from "rxjs";
import {Client} from "../../../../../projects/kiene-core/src/lib/components/client/classes/client";
import {KieneSession} from "../../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session";
import {Mandant} from "../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant";
import {SelectMandantDialogComponent} from "../../../dialogs/select-mandant-dialog/select-mandant-dialog.component";
import {Router} from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class ChangeCurrentClientService {
    dialogRefClient: MatDialogRef<ChangeCurrentClientDialogComponent>;
    dialogRefMandant: MatDialogRef<SelectMandantDialogComponent>;

    constructor(
        public dialog: MatDialog,
        private localStorageService: LocalStorageService,
        public router: Router
    ) {
    }

    public openChangeMandantDialog() {
        this.closeDialogs();

        const kieneSession: KieneSession =
            this.localStorageService.getCurrentUser();
        const mandanten: Mandant[] = kieneSession.mandanten;
        this.dialogRefMandant = this.dialog.open(SelectMandantDialogComponent, {
            maxWidth: "90%",
            data: {mandanten: mandanten},
        });

        this.dialogRefMandant.afterClosed().subscribe((result) => {
            const mandant: Mandant = result;
            if (mandant) {
                this.localStorageService.setCurrentMandant(
                    mandant,
                    "ChangeCurrentClientService openChangeMandantDialog()"
                );
                this.localStorageService.setCurrentClient(undefined);
                const startseite = this.localStorageService.getStartseite();
                this.router.navigate([startseite]);
            }
        });
    }

    public openChangeDialog(sender: string): Observable<Client> {
        const clientSelectedSubject = new Subject<Client>();
        if (this.localStorageService.hasPermission(4511)) {
            this.closeDialogs();

            this.dialogRefClient = this.dialog.open(
                ChangeCurrentClientDialogComponent,
                {
                    minWidth: "1000px",
                    maxWidth: "1000px",
                    width: "100%",
                    data: {
                        client: this.localStorageService.getCurrentClient(),
                    },
                }
            );

            this.dialogRefClient.afterClosed().subscribe((result) => {
                if (result?.save) {
                    this.localStorageService.setCurrentClient(result.client);
                    return clientSelectedSubject.next(result.client);
                }
            });
        } else {
            clientSelectedSubject.next(undefined);
        }
        return clientSelectedSubject.asObservable();
    }

    private closeDialogs() {
        this.dialogRefClient?.close();
        this.dialogRefMandant?.close();
    }
}
