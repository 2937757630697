import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Material } from '../../material';
import { Tierart } from '../../../../components/tierart-selector/tierart-selector.component';
import { KieneTableService } from '../../../../components/kiene-table/kiene-table.service';
import { ArtikelTierart } from '../../../../components/tierart-selector/artikel-tierart';
import { Einheit } from '../../../../components/einheit-selector/einheit';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { API_BASE_URL_SERVICE } from '../../../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
  selector: 'kiene-add-tierart-to-article-dialog',
  templateUrl: './add-tierart-to-article-dialog.component.html',
  styleUrls: ['./add-tierart-to-article-dialog.component.scss']
})
export class AddTierartToArticleDialogComponent implements OnInit {

  artikelTierart: ArtikelTierart;
  material: Material;
  tierarten: Tierart[] = [];
  tierartCtrl = new UntypedFormControl();
  einheit: Einheit;
  tierart: Tierart;

  constructor(public dialogRef: MatDialogRef<AddTierartToArticleDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private api: KieneTableService, @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    if (data.artikelTierart === null) {
      this.artikelTierart = new ArtikelTierart();
      this.artikelTierart.artikel_id = data.material.artikel_id;
    } else {
      this.artikelTierart = data.artikelTierart;
      this.einheit = new Einheit();
      this.einheit.einheit_id = this.artikelTierart.einheit_id;
      this.einheit.bezeichnung = this.artikelTierart.einheit;
      this.tierart = new Tierart();
      this.tierart.tierart_id = this.artikelTierart.tierart_id;
      this.tierart.tierart = this.artikelTierart.tierart;
      this.tierartCtrl.setValue(this.tierart);
      this.tierartCtrl.disable();
    }
    this.material = data.material;

  }

  ngOnInit() {
    this.loadAllTierarten();
  }

  loadAllTierarten() {
    this.api.get(this.apiBaseUrl + 'tierart/read.php', null).subscribe(response => {
      this.tierarten = response.records;
    });
  }

  tierartComparator(one: Tierart, two: Tierart): boolean {
    return one.tierart_id === two.tierart_id;
  }

  setEinheit(einheit: Einheit) {
    this.artikelTierart.einheit_id = einheit.einheit_id;
    this.artikelTierart.einheit = einheit.bezeichnung;
  }

  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    this.artikelTierart.tierart_id = this.tierartCtrl.value.tierart_id;
    this.dialogRef.close(this.artikelTierart);
  }

}
