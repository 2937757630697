<h1 mat-dialog-title>Sonderpreis {{ cga.ua_id ? "editieren" : "anlegen" }}</h1>
<div mat-dialog-content>
    <kiene-article-scan-autocomplete
        *ngIf="!cga.ua_id"
        (selectedMaterial)="setMaterial($event)"
    >
    </kiene-article-scan-autocomplete>
    <p>
        Info: Ist ein Einzelpreis angegeben, wird nur dieser
        ber&uuml;cksichtigt. Ansonsten der selektierte Verkaufspreis.
    </p>
    <div class="one-row">
        <mat-form-field>
            <mat-label>Einzelpreis netto</mat-label>
            <input matInput type="number" [formControl]="preisCtrl" />
            <span matSuffix>
                <mat-icon>euro</mat-icon>
            </span>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">
        {{ cga.ua_id ? "Speichern" : "Anlegen" }}
    </button>
</div>
