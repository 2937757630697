export class HeimtierBelegArtikel {
    hba_id: number;
    ht_beleg_id: number;
    position: number;
    artikel_id: number;
    artikel_me: string;
    artikelmenge: number;
    indikation_id: number;
    diagnose: string;
    heimtier_id: number;
    name: string;
    tierart_id: number;
    tierart: string;
    tierrasse_id: number;
    tiergewicht: number;
    anwendungsdauer: number;
    abgabeundbehandlung: number;
    dosierung: number;
    anwendung_id: number;
    anwendung: string;
    behandlung: number;
    faktor: number;
    einzelpreis: number;
    aus_stueckliste: number;
    menge: number;
    einheit_id: number;
    einheit: string;
    behandlung_tage: number;
    intervall_std: number;
    faktor_tierart: number;
    koerpergewicht: number;
    dosierung_txt: string;
    verpackungsmenge: number;
    dosierung_fix: number;
    erstellt: Date;
    editiert: Date;

}

export class HeimtierBeleg {
    ht_beleg_id: number;
    beleg_nr: string;
    bericht: string;
    besuchszusammenfassung: string;
    anmerkung: string;
    tierarzt_id: number;
    tierarzt: string;
    datum: Date;
    kunde_id: number;
    kunde: string;
    status_id: number;
    status: string;
    rechnung_id: number;
    pdf_id_rechnung: number;
    behandlung_seit: Date;
    behandlung_bis: Date;
    erstellt: Date;
    editiert: Date;
    positionen: HeimtierBelegArtikel[] = [];
}

export enum HeimtierBelegStatus {
    ERSTELLT = 221,
    ABGESCHLOSSEN = 222
}
