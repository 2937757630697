import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../app.auth.guard';
import { KundeEinzelGesamtReportComponent } from './kunden/kunde-einzel-gesamt-report/kunde-einzel-gesamt-report.component';
import { KundenEinzelReportComponent } from './kunden/kunden-einzel-report/kunden-einzel-report.component';
import { KundenComponent } from './kunden/kunden.component';
import { UnternehmensgruppeEinzelReportComponent } from './kunden/unternehmensgruppe-einzel-report/unternehmensgruppe-einzel-report.component';
import { UnternehmensgruppenGesamtReportComponent } from './kunden/unternehmensgruppen-gesamt-report/unternehmensgruppen-gesamt-report.component';
import { LaborArtikelReportComponent } from './labor/labor-artikel-report/labor-artikel-report.component';
import { LaborGesamtReportComponent } from './labor/labor-gesamt-report/labor-gesamt-report.component';
import { LaborMitarbeiterReportComponent } from './labor/labor-mitarbeiter-report/labor-mitarbeiter-report.component';
import { LaborComponent } from './labor/labor.component';
import { LieferantEinzelReportComponent } from './lieferanten/lieferant-einzel-report/lieferant-einzel-report.component';
import { LieferantenGesamtReportComponent } from './lieferanten/lieferanten-gesamt-report/lieferanten-gesamt-report.component';
import { LieferantenComponent } from './lieferanten/lieferanten.component';
import { ReportComponent } from './report/report.component';
import { TierarztEinzelReportComponent } from './tierarzt/tierarzt-einzel-report/tierarzt-einzel-report.component';
import { TierarztGesamtReportComponent } from './tierarzt/tierarzt-gesamt-report/tierarzt-gesamt-report.component';
import { TierarztGruppeReportComponent } from './tierarzt/tierarzt-gruppe-report/tierarzt-gruppe-report.component';
import { TierarztComponent } from './tierarzt/tierarzt.component';
import { KombiniertReportComponent } from './wareneinsatz/kombiniert-report/kombiniert-report.component';
import { WarenausgangReportComponent } from './wareneinsatz/warenausgang-report/warenausgang-report.component';
import { WareneingangReportComponent } from './wareneinsatz/wareneingang-report/wareneingang-report.component';
import { WareneinsatzComponent } from './wareneinsatz/wareneinsatz.component';
import { WarengruppenAusgangReportComponent } from './wareneinsatz/warengruppen-ausgang-report/warengruppen-ausgang-report.component';
import { WarengruppenEingangReportComponent } from './wareneinsatz/warengruppen-eingang-report/warengruppen-eingang-report.component';

const routes: Routes = [
  {
    path: '', component: ReportComponent, canActivate: [AuthGuard], children: [
      {
        path: 'tierarzt', component: TierarztComponent, canActivate: [AuthGuard], children: [
          { path: 'gesamt', component: TierarztGesamtReportComponent },
          { path: 'gruppe', component: TierarztGruppeReportComponent },
          { path: 'einzel', component: TierarztEinzelReportComponent },
          { path: '**', redirectTo: 'gesamt' }
        ]
      },
      {
        path: 'kunden', component: KundenComponent, canActivate: [AuthGuard], children: [
          { path: 'gesamt', component: UnternehmensgruppenGesamtReportComponent },
          { path: 'gesamteinzel', component: KundeEinzelGesamtReportComponent },
          { path: 'gruppe', component: UnternehmensgruppeEinzelReportComponent },
          { path: 'einzel', component: KundenEinzelReportComponent },
          { path: '**', redirectTo: 'gesamt' }
        ]
      },
      {
        path: 'labor', component: LaborComponent, canActivate: [AuthGuard], children: [
          { path: 'gesamt', component: LaborGesamtReportComponent },
          { path: 'mitarbeiter', component: LaborMitarbeiterReportComponent },
          { path: 'artikel', component: LaborArtikelReportComponent },
          { path: '**', redirectTo: 'gesamt' }
        ]
      },
      {
        path: 'lieferanten', component: LieferantenComponent, canActivate: [AuthGuard], children: [
          { path: 'gesamt', component: LieferantenGesamtReportComponent },
          { path: 'einzel', component: LieferantEinzelReportComponent },
          { path: '**', redirectTo: 'gesamt' }
        ]
      },
      {
        path: 'wareneinsatz', component: WareneinsatzComponent, canActivate: [AuthGuard], children: [
          { path: 'eingang', component: WareneingangReportComponent },
          { path: 'ausgang', component: WarenausgangReportComponent },
          { path: 'kombiniert', component: KombiniertReportComponent },
          { path: 'warengruppeneingang', component: WarengruppenEingangReportComponent },
          { path: 'warengruppenausgang', component: WarengruppenAusgangReportComponent },
          { path: '**', redirectTo: 'eingang' }
        ]
      },
      { path: '**', redirectTo: 'tierarzt' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
