import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { KieneTableService } from '../kiene-table/kiene-table.service';
import { environment } from '../../../environments/environment';
import { Einheit } from './einheit';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'kiene-einheit-selector',
  templateUrl: './einheit-selector.component.html',
  styleUrls: ['./einheit-selector.component.scss']
})
export class EinheitSelectorComponent implements OnInit {

  einheiten: Einheit[] = [];
  einheitCtrl = new UntypedFormControl();

  @Input('preselected') preselected: Einheit;

  @Output('changed') changed = new EventEmitter<Einheit>();

  constructor(private api: KieneTableService) { }

  ngOnInit() {
    this.loadEinheiten();
    if (this.preselected) {
      this.einheitCtrl.setValue(this.preselected);
    }
  }

  loadEinheiten() {
    this.api.get(environment.BASE_URI + 'einheit/read.php').subscribe(response => {
      this.einheiten = response.records;
    });
  }

  einheitComparator(one: Einheit, two: Einheit): boolean {
    return one.einheit_id === two.einheit_id;
  }

  einheitChanged(event: MatSelectChange) {
    this.changed.emit(event.value);
  }

}
