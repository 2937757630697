<h1 mat-dialog-title>Tierarzt hinzuf&uuml;gen</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Tierarzt</mat-label>
        <mat-select [formControl]="selectCtrl" [compareWith]="tierarztComparator">
            <mat-option *ngFor="let t of tieraerzte" [value]="t">{{ t.vorname }} {{ t.nachname }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="dialogRef.close(selectCtrl.value)" [disabled]="!selectCtrl.valid">
        hinzuf&uuml;gen
    </button>
</div>
