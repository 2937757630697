import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OhrmarkeComponent } from './ohrmarke/ohrmarke.component';



@NgModule({
  declarations: [
    OhrmarkeComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    OhrmarkeComponent
  ]
})
export class OhrmarkeModule { }
