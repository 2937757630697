import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Material } from '../../warehouse/material/material';

@Injectable({
  providedIn: 'root'
})
export class ArticleScanAutocompleteService {

  presetMaterialSubject = new Subject<Material>();

  constructor() { }

  setPresetMaterial(material: Material) {
    this.presetMaterialSubject.next(material);
  }

  watchPresetMaterial(): Observable<Material> {
    return this.presetMaterialSubject.asObservable();
  }

}
