import { Client } from '../../client/classes/client';
import { Tierliste } from '../../gruppe-tier/classes/tierliste';
import { Gruppe } from '../../gruppen/classes/gruppe';

export class AusstallenDialogInput {
    tierliste: Tierliste;
    gruppe_abgang: Gruppe;
    intern: boolean;
    extern: boolean;
    zielbetrieb: Client;

    constructor(intern: boolean, extern: boolean, options: { tierliste?: Tierliste, neu?: { gruppe_abgang: Gruppe, zielbetrieb: Client } }) {
        this.intern = intern;
        this.extern = extern;
        this.tierliste = options?.tierliste;
        this.zielbetrieb = options?.neu?.zielbetrieb;
        this.gruppe_abgang = options?.neu?.gruppe_abgang;
    }
}

export class AusstallenDialogResult {
    tierliste: Tierliste;

    constructor(liste: Tierliste) {
        this.tierliste = liste;
    }
}