import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KeyValueListComponent} from './key-value-list/key-value-list.component';
import {KeyValueListItemComponent} from './key-value-list-item/key-value-list-item.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
    declarations: [
        KeyValueListComponent,
        KeyValueListItemComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        KeyValueListComponent,
        KeyValueListItemComponent
    ]
})
export class KeyValueListModule {
}
