<h1 mat-dialog-title>Tierrasse</h1>
<mat-dialog-content>
    <mat-form-field style="width: 100%;">
        <mat-label>Name der Rasse</mat-label>
        <input type="text" matInput [(ngModel)]="rasse.name">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Rassenk&uuml;rzel</mat-label>
        <input type="text" matInput [(ngModel)]="rasse.kuerzel">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Geburtsgewicht in kg</mat-label>
        <input type="number" matInput [(ngModel)]="rasse.geburtsgewicht">
        <span matSuffix>kg</span>
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Tageszunahme in g</mat-label>
        <input type="number" matInput [(ngModel)]="rasse.tageszunahme">
        <span matSuffix>g</span>
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button (click)="cancel()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</mat-dialog-actions>
