
export class AnwendungTierart {
    tierart_id: number;
    tierart: string;
    kommentar: string;
    wartezeit_fleisch: number;
    wartezeit_milch: number;
    wartezeit_eier: number;
}
export class Anwendung {
    anwendung_id: number;
    anwendung: string;
    artikel_id: number;
    artikel: string;
    qs_schluessel: number;
    hit_schluessel: number;
    tierarten: AnwendungTierart[] = [];
    mandanten_ids: number[] = [];
    sortierung: number;
}