import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../app.auth.guard';
import {
    BestellungExternListComponent
} from './bestellung-extern-list/bestellung-extern-list/bestellung-extern-list.component';
import {
    BestellungExternDetailsComponent
} from './bestellung-extern/bestellung-extern-details/bestellung-extern-details.component';
import {BestellungExternComponent} from './bestellung-extern/bestellung-extern.component';
import {
    BestellungInternDetailsComponent
} from './bestellung-intern/bestellung-intern-details/bestellung-intern-details.component';
import {BestellungInternComponent} from './bestellung-intern/bestellung-intern.component';
import {BestellvorschlaegeComponent} from './bestellvorschlaege/bestellvorschlaege.component';
import {BestellvorschlagInternComponent} from './bestellvorschlag-intern/bestellvorschlag-intern.component';
import {GemeinkostenComponent} from './gemeinkosten/gemeinkosten.component';
import {GutschriftenComponent} from './gutschriften/gutschriften.component';
import {InformationenComponent} from './informationen/informationen.component';
import {WaWiComponent} from './wa-wi.component';
import {WarenkorbComponent} from './warenkorb/warenkorb.component';
import {HonorareUndGehaelterComponent} from './honorare-und-gehaelter/honorare-und-gehaelter.component';

const routes: Routes = [
    {
        path: '', component: WaWiComponent, canActivate: [AuthGuard], children: [
            {path: 'informationen', component: InformationenComponent, canActivate: [AuthGuard]},
            {
                path: 'bestellung-extern', component: BestellungExternComponent, canActivate: [AuthGuard], children: [
                    {path: 'lieferanten', component: BestellungExternListComponent, canActivate: [AuthGuard]},
                    {path: 'lieferanten/:id', component: BestellungExternDetailsComponent, canActivate: [AuthGuard]},
                    {path: 'gutschriften', component: GutschriftenComponent, canActivate: [AuthGuard]},
                    {path: 'gemeinkosten', component: GemeinkostenComponent, canActivate: [AuthGuard]},
                    {path: 'honorareundgehaelter', component: HonorareUndGehaelterComponent, canActivate: [AuthGuard]},
                    {path: '**', redirectTo: 'lieferanten'}
                ]
            },
            // { path: 'bestellung-extern/:id', component: BestellungExternDetailsComponent, canActivate: [AuthGuard] },
            {path: 'bestellvorschlaege', component: BestellvorschlaegeComponent, canActivate: [AuthGuard]},
            {path: 'bestellung-intern', component: BestellungInternComponent, canActivate: [AuthGuard]},
            {path: 'bestellung-intern/:id', component: BestellungInternDetailsComponent, canActivate: [AuthGuard]},
            {path: 'bestellvorschlaege-intern', component: BestellvorschlagInternComponent, canActivate: [AuthGuard]},
            {path: 'warenkorb', component: WarenkorbComponent, canActivate: [AuthGuard]},
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class WaWiRoutingModule {
}
