import {HttpParams} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AppService} from '../../app.service';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {TableColumn} from '../../components/kiene-table/table-column';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {
    TableOption,
    TableOptionEvent,
} from '../../components/kiene-table/table-option';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MessageService} from '../../services/message.service';
import {AddVetStockDialogComponent} from '../../warehouse/stock/add-vet-stock-dialog/add-vet-stock-dialog.component';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-tierarztwagen-bestand',
    templateUrl: './tierarztwagen-bestand.component.html',
    styleUrls: ['./tierarztwagen-bestand.component.css'],
})
export class TierarztwagenBestandComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    tierarztId = 0;

    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        public dialog: MatDialog,
        private router: Router,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private api: KieneTableService
    ) {
    }

    ngOnInit() {
        this.tierarztId = this.localStorageService.getCurrentUser().tierarzt_id;
        this.tableDescriptor.headline = 'Mein Tierarztwagen Bestand';
        this.tableDescriptor.uniqueIdentifier = 'Mein Tierarztwagen Bestand';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'bestand_charge/';
        const params = new HttpParams().set(
            'tierarzt_id',
            this.localStorageService.getCurrentUser().tierarzt_id.toString()
        );
        this.tableDescriptor.params = params;
        this.tableDescriptor.route = '/home?tab=meinbestand';
        this.tableDescriptor.nameOfIdField = 'lc_id';
        this.tableDescriptor.searchPlaceholder = 'Mein Bestand durchsuchen';

        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;

        this.tableDescriptor.columns.push(new TableColumn('lager', 'Lager'));
        this.tableDescriptor.columns.push(
            new TableColumn('bezeichnung', 'Lagerplatz')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('artikel_me', 'Artikel')
        );
        this.tableDescriptor.columns.push(new TableColumn('charge', 'Charge'));
        this.tableDescriptor.columns.push(new TableColumn('menge', 'Menge'));
        this.tableDescriptor.columns.push(
            new TableColumn('editiert', 'Editiert', 'datetime')
        );

        this.tableDescriptor.permissionUpdate = 4022;

        this.tableDescriptor.options.push(
            new TableOption('edit', 'editieren', 'edit', 4022)
        );

        this.tableDescriptor.initialSortColumn = 'editiert';
        this.tableDescriptor.initialSortDirection = 'desc';
    }

    catchOptionEvent(event: TableOptionEvent) {
    }

    addBestand() {
        const dialogRef = this.dialog.open(AddVetStockDialogComponent, {
            minWidth: '300px',
            width: '33%',
        });

        dialogRef.afterClosed().subscribe((stock) => {
            if (stock) {
                this.api
                    .post(
                        this.apiBaseUrl +
                        'bestand_charge/create_tierarzt.php',
                        stock,
                        null
                    )
                    .subscribe(
                        (response) => {
                            this.messageService.infoMessage(
                                'Der Bestand wurde erfolgreich hinzugefügt!'
                            );
                            //  this.ladeMeinBestand();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Der Bestand konnte nich hinzugefügt werden!',
                                error
                            );
                        }
                    );
            }
        });
    }
}
