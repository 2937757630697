<mat-form-field style="min-width: 300px; width: 100%">
    <input
        matInput
        [matAutocomplete]="autoClient"
        [formControl]="clientFormCtrl"
        [placeholder]="placeholder"
        [required]="required"
    />
    <button
        *ngIf="showClear"
        [disabled]="disabled"
        matSuffix
        mat-icon-button
        color="accent"
        (click)="clear()"
    >
        <mat-icon>cancel</mat-icon>
    </button>
    <mat-autocomplete
        #autoClient="matAutocomplete"
        [displayWith]="clientMapper"
        (optionSelected)="autocompleteClientSelected($event)"
        [panelWidth]="panelWidth"
    >
        <mat-option>Kein Betrieb</mat-option>
        <mat-option *ngFor="let c of clients | async" [value]="c">
            <div
                style="width: 100%; border-bottom: 1px solid #ccc; padding: 5px"
            >
                <span style="padding: 5px">{{ getBezeichnung(c) }}</span>
            </div>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
