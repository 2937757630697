<div *kienePermission="[1031]" class="kiene-content-page">

    <mat-toolbar>
        <mat-toolbar-row>
            <button (click)="save(bestellungIntern.status_id)" [disabled]="bestellungIntern.status_id >= 4"
                    color="primary"
                    mat-icon-button matTooltip="Zwischenspeichern">
                <mat-icon>save</mat-icon>
            </button>

            <button (click)="startPicking()" [disabled]="
                    bestellungInternId === 'neu' ||
                    bestellungIntern.status_id != 3
                " color="primary" mat-icon-button matTooltip="Bestellung kommissionieren">
                <mat-icon>local_shipping</mat-icon>
            </button>

            <button (click)="showPdf()" [disabled]="bestellungInternId === 'neu'" color="primary" mat-icon-button
                    matTooltip="PDF anzeigen">
                <mat-icon>description</mat-icon>
            </button>
            <button (click)="reload()" color="primary" mat-icon-button matTooltip="Seite neu laden">
                <mat-icon>refresh</mat-icon>
            </button>

            <span class="kiene-spacer"></span>
            <div *ngIf="pickingMode" class="one-row picking-mode" style="width: 40%; align-self: center">
                <mat-icon style="transform: scale(1.4)">warning</mat-icon>
                <span class="spacer"></span>
                <span>Kommissionierung</span>
                <span class="spacer"></span>
                <mat-icon style="transform: scale(1.4)">warning</mat-icon>
            </div>
            <span class="kiene-spacer"></span>

            <div *ngIf="bestellungInternId !== 'neu'">
                <span style="
                        display: inline-block;
                        margin-right: 10px;
                        font-size: 14px;
                    ">Status:
                </span>
                <mat-chip-list style="display: inline-block">
                    <mat-chip color="primary" selected>{{
                            bestellungIntern.status
                        }}
                    </mat-chip>
                </mat-chip-list>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="kiene-page-header">
        <div>
            <button (click)="goBack()" mat-icon-button matTooltip="zurück" style="display: inline-block">
                <mat-icon color="primary">arrow_back</mat-icon>
            </button>
            <h2 style="display: inline-block; margin-left: 20px">
                {{
                    bestellungInternId === "neu"
                        ? "Neue Bestellung aufgeben"
                        : "Bestellung " +
                        bestellungInternId + (bestellungIntern?.tierarzt ? (" für " + bestellungIntern?.tierarzt) : "")
                }}
            </h2>
        </div>
    </div>

    <div class="position-editing">
        <mat-form-field style="min-width: 200px; width: 30%; margin-right: 2%">
            <input [formControl]="kommentarCtrl" matInput placeholder="Kommentar" type="text"/>
        </mat-form-field>
        <mat-form-field>
            <input [formControl]="zielDatumCtrl" [matDatepicker]="picker" matInput placeholder="Zieldatum"/>
            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-card *ngIf="
                !pickingMode &&
                editAllowed &&
                (bestellungIntern.status_id < 4 ||
                    bestellungIntern.status_id === undefined)
            ">
            <h4>
                Position hinzufügen
                <span *ngIf="selectedPosition">/ editieren</span>
            </h4>
            <div class="one-row" style="width: 100%">

                <div style="width: 40%">
                    <kiene-article-scan-autocomplete #articleScanAutocompleteEdit
                                                     (cleared)="clearEditFields()"
                                                     (selectedMaterial)="setSelectedMaterial($event)"
                                                     [focus]="true" [placeholder]="'Artikel eingeben oder scannen'">
                    </kiene-article-scan-autocomplete>
                </div>

                <mat-form-field style="width: 20%; margin-right: 2%">
                    <mat-label>Menge</mat-label>
                    <input #amountInput="matInput" (keyup.enter)="addPosition()" [formControl]="positionAmountCtrl"
                           matInput
                           type="number"/>
                    <span matSuffix>Stk.</span>
                    <mat-error *ngIf="positionAmountCtrl.invalid">Menge muss > 0 sein!</mat-error>
                </mat-form-field>

                <button (click)="addPosition()" color="primary" mat-flat-button>
                    <mat-icon>add</mat-icon>
                    Hinzufügen
                </button>
            </div>
        </mat-card>

        <!-- Picking Mode -->
        <mat-card *ngIf="pickingMode && bestellungIntern.status_id < 4">
            <h4>
                Positionen kommissionieren
                <span *ngIf="selectedPosition">/ editieren</span>
            </h4>

            <form>
                <div class="one-row">
                    <div style="width: 40%; margin-right: 2%;">
                        <kiene-article-scan-autocomplete #articleScanAutocompletePicking
                                                         (cleared)="clearFieldsForSelectedArticle()"
                                                         (selectedMaterial)="pickingMaterialSelected($event)"></kiene-article-scan-autocomplete>
                    </div>

                    <mat-form-field style="margin-right: 2%">
                        <mat-label>Chargennummer</mat-label>
                        <input #chargePickerInput (blur)="checkPickCharge()" [formControl]="chargePickInputCtrl"
                               matInput type="text"/>
                        <mat-error *ngIf="chargePickInputCtrl.invalid">Charge ist nicht korrekt!</mat-error>
                    </mat-form-field>

                    <mat-form-field style="margin-right: 2%">
                        <mat-label>MHD</mat-label>
                        <input [formControl]="bestBeforeCtrl" [matDatepicker]="bestBeforePicker" matInput/>
                        <mat-datepicker-toggle [for]="bestBeforePicker" matSuffix></mat-datepicker-toggle>
                        <mat-datepicker #bestBeforePicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field style="margin-right: 2%">
                        <mat-label>Lagerplatz</mat-label>
                        <mat-select #pickStoragePlaceInput [formControl]="storagePlacePickCtrl">
                            <mat-option *ngFor="let sp of availableStoragePlacesForPick" [value]="sp">{{
                                    sp.bezeichnung
                                }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="storagePlacePickCtrl.invalid">Bitte einen Lagerplatz auswählen</mat-error>
                    </mat-form-field>

                    <mat-form-field style="margin-right: 2%">
                        <mat-label>Menge</mat-label>
                        <input #pickAmountInput="matInput" (keyup.enter)="bookPicking()" [formControl]="pickAmountCtrl"
                               matInput type="number"/>
                        <span matSuffix>Stk.</span>
                    </mat-form-field>

                    <button (click)="bookPicking()" [disabled]="isSavingPicking" color="accent" mat-flat-button
                            type="button">
                        speichern
                    </button>
                </div>
            </form>
        </mat-card>

        <h4>Positionen</h4>
        <mat-card>
            <div *ngIf="positionen?.length > 0" style="width: 100%">
                <div class="flex-grid position">
                    <div class="pos-head">Pos.</div>
                    <div class="artikel-head">Artikel</div>
                    <div class="ean-head">EAN</div>
                    <div class="amount-head">Bestellmenge</div>
                    <div class="amount-head">Gebindegröße</div>
                    <div class="amount-head">Kommissionierte Menge</div>
                    <div class="amount-head">Chargen</div>
                    <div class="remove-head"></div>
                </div>
            </div>
            <div *ngFor="let p of positionen; let i = index" style="width: 100%">
                <div [ngStyle]="
                        selectedPosition === p
                            ? { 'background-color': '#EEE' }
                            : {}
                    " class="flex-grid position">
                    <div class="pos-col">{{ i + 1 }}.</div>
                    <div class="artikel-col">{{ p.artikel_me }}</div>
                    <div class="ean-col">{{ p.ean }}</div>
                    <div class="amount-col one-row">
                        <input (blur)="leaveArtikelmenge(i)" (focus)="setArtikelmengeFocus(i)"
                               [formControl]="positionenCtrls[i]"
                               [ngClass]="{
                                'menge-input-focus': artikelmengeFocus[i]
                            }" class="menge-input" matInput
                               style="width: 50px; padding: 12px" type="number"/><span>Stk.</span><span
                        class="spacer"></span>
                    </div>
                    <div class="amount-col">{{ p.gebindegroesse | number: '1.0-1' }} Stk.</div>
                    <div class="amount-col">
                        {{ p.artikelmenge_ist | number: "1.0-1" }} Stk.
                    </div>
                    <div class="amount-col">{{ p.chargen }}</div>
                    <div class="remove-col">
                        <button (click)="removePosition(p)" *ngIf="
                                editAllowed &&
                                (p.artikelmenge_ist === null ||
                                    p.artikelmenge_ist === 0)
                            " mat-icon-button matTooltip="Position löschen">
                            <mat-icon>close</mat-icon>
                        </button>
                        <button (click)="editPosition(p)" *ngIf="!pickingMode && !p.artikelmenge_ist"
                                mat-icon-button matTooltip="Artikel editieren">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <mat-icon *ngIf="p.artikelmenge_ist >= p.artikelmenge">check</mat-icon>
                    </div>
                </div>
            </div>
        </mat-card>

        <div *ngIf="positionen?.length > 0 && bestellungIntern.status_id == 1" style="width: 100%; text-align: right">
            <button (click)="save(3)" *kienePermission="[1033]" [disabled]="!editAllowed" color="primary"
                    mat-raised-button style="margin-top: 20px">
                bestellen
            </button>
        </div>

        <div *ngIf="positionen?.length > 0 && bestellungIntern.status_id == 3" style="width: 100%; text-align: right">
            <button (click)="save(4)" *kienePermission="[1036]" color="primary" mat-raised-button
                    style="margin-top: 20px" [disabled]="isLoading">
                fertig kommissioniert
            </button>
        </div>
    </div>
</div>
