import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    Router,
} from '@angular/router';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {
    ConfirmDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {environment} from '../../../environments/environment';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {
    ConditionSetting,
    ConditionTableColumn,
    TableColumn,
} from '../../components/kiene-table/table-column';
import {
    TableDescriptor,
    ToolbarButton,
} from '../../components/kiene-table/table-descriptor';
import {
    Condition,
    Operator,
    TableOption,
    TableOptionEvent,
} from '../../components/kiene-table/table-option';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MessageService} from '../../services/message.service';
import {BestellungIntern} from '../bestellung-intern/bestellung-intern';
import {BestellvorschlagIntern} from './bestellvorschlag-intern';
import {
    BestellvorschlagInternDialogComponent
} from './bestellvorschlag-intern-dialog/bestellvorschlag-intern-dialog.component';

@Component({
    selector: 'kiene-bestellvorschlag-intern',
    templateUrl: './bestellvorschlag-intern.component.html',
    styleUrls: ['./bestellvorschlag-intern.component.css'],
})
export class BestellvorschlagInternComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private messageService: MessageService,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private router: Router,
        private tableService: KieneTableService
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Bestellvorschläge Intern';
        this.tableDescriptor.uniqueIdentifier = 'Bestellvorschläge Intern';
        this.tableDescriptor.apiUrl =
            environment.BASE_URI + 'artikel_tierarzt/';
        this.tableDescriptor.route = '/wawi/bestellvorschlaege-intern/';
        this.tableDescriptor.nameOfIdField = 'artikel_id';
        this.tableDescriptor.searchPlaceholder =
            'Bestellvorschläge durchsuchen';
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;

        this.tableDescriptor.columns.push(
            new TableColumn('tierarzt', 'Tierarzt')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('artikel_id', 'Artikel ID')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('artikel_me', 'Artikel')
        );

        const conditionSettings = [
            new ConditionSetting([new Condition('status', Operator.GLEICH, 1)], {
                icon: 'lens',
                color: 'green',
                tooltip: 'Bestand ausreichend vorhanden',
            }),
            new ConditionSetting([new Condition('status', Operator.GLEICH, 2)], {
                icon: 'warning',
                color: 'orange',
                tooltip: 'Mindestbestand bald erreicht',
            }),
            new ConditionSetting([new Condition('status', Operator.GLEICH, 3)], {
                icon: 'error',
                color: 'red',
                tooltip: 'Mindestbestand erreicht oder unterschritten',
            }),
        ];
        this.tableDescriptor.columns.push(
            new ConditionTableColumn('status', 'Status', conditionSettings, {
                sortFields: ['status'],
            })
        );

        this.tableDescriptor.columns.push(
            new TableColumn('bestand', 'Akt. Bestand')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('mindestbestand', 'Sollbestand')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('bestellt', 'Bestellt')
        );

        this.tableDescriptor.options.push(
            new TableOption('edit', 'Bearbeiten', 'edit', 1391)
        );
        this.tableDescriptor.options.push(
            new TableOption('delete', 'Entfernen', 'delete', 1394)
        );

        this.tableDescriptor.toolbarButtons.push(
            new ToolbarButton(
                'order',
                'post_add',
                1391,
                'Bestellung aus Bestellvorschlägen generieren'
            )
        );
        this.tableDescriptor.toolbarButtons.push(
            new ToolbarButton(
                'order_abgabebelege',
                'add_circle_outline',
                1391,
                'Bestellung aus Abgabebelege generieren'
            )
        );

        this.tableDescriptor.permissionView = 1191;

        this.tableDescriptor.initialSortColumn = 'artikel_id';
        this.tableDescriptor.initialSortDirection = 'asc';

        this.tableDescriptor.permissionView = 1391;
        this.tableDescriptor.permissionCreate = 1391;
        this.tableDescriptor.permissionUpdate = 1391;
        this.tableDescriptor.permissionDelete = 1391;
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            let bvi = new BestellvorschlagIntern();
            this.openDetailsDialog(bvi);
        } else if (event.name === 'edit') {
            this.openDetailsDialog(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        } else if (event.name === 'order') {
            this.openOrderConfirmDialog();
        }
    }

    public openOrderConfirmDialog() {
        const input = new ConfirmDialogInput();
        input.headline = 'Bestellung generieren?';
        input.text =
            'Wollen Sie für alle benötigten Artikel eine Bestellung generieren?';
        input.okButton = 'Bestellung generieren';

        let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.tableService
                        .post(
                            environment.BASE_URI + 'bestellung/generieren.php',
                            {},
                            null
                        )
                        .subscribe(
                            (response) => {
                                this.messageService.infoMessage(
                                    'Die Bestellung wurde erfolgreich generiert'
                                );
                                let bi: BestellungIntern = response;
                                this.router.navigate(
                                    ['../bestellung-intern', bi.bestellung_id],
                                    {relativeTo: this.route}
                                );
                            },
                            (error) => {
                                this.messageService.errorMessage(
                                    'Die Bestellung konnte nicht generiert werden!',
                                    error
                                );
                                this.tableService.reload();
                            }
                        );
                }
            },
        });
    }

    public openDeleteDialog(bvi: BestellvorschlagIntern) {
        let dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            data: {
                title: bvi.artikel_me + ' entfernen?',
                text: 'Wollen Sie den Artikel aus den Bestellvorschlägen entfernen?',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.tableService
                    .deleteEntity(environment.BASE_URI + 'artikel_tierarzt/delete.php', bvi)
                    .subscribe(
                        (response) => {
                            this.messageService.infoMessage(
                                'Der Artikel wurde erfolgreich entfernt'
                            );
                            this.tableService.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Der Artikel konnte nicht entfernt werden!',
                                error
                            );
                            this.tableService.reload();
                        }
                    );
            }
        });
    }

    public openDetailsDialog(bvi: BestellvorschlagIntern): void {
        let dialogRef = this.dialog.open(
            BestellvorschlagInternDialogComponent,
            {
                minWidth: '400px',
                width: '33%',
                data: {
                    bestellvorschlagIntern: bvi,
                },
            }
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.tableService.reload();
            }
        });
    }
}
