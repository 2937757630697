import {SignPanelComponent} from './sign-panel/sign-panel.component';
import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

const routes: Routes = [
    {path: ':id', component: SignPanelComponent},
    {path: '**', redirectTo: ':id'}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UnterschriftenRoutingModule {
}
