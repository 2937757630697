import {Component, Inject, Input, OnChanges, OnInit} from '@angular/core';
import {Information} from './information';
import {UntypedFormControl} from '@angular/forms';
import {HttpParams} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {AddInformationDialogComponent} from './add-information-dialog/add-information-dialog.component';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {MessageService} from '../../services/message.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-information-board',
    templateUrl: './information-board.component.html',
    styleUrls: ['./information-board.component.scss'],
})
export class InformationBoardComponent implements OnInit, OnChanges {
    @Input('informationType') informationType: number = 1;
    @Input('headline') headline: string = 'Informationen';
    @Input('permissionRead') permissionRead: number = 4011;
    @Input('permissionWrite') permissionWrite: number = 4012;
    @Input('permissionDelete') permissionDelete: number = 4014;

    informationen: Information[] = [];
    onlyCurrent = true;
    gueltigCtrl = new UntypedFormControl();

    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        public dialog: MatDialog,
        private messageService: MessageService,
        private localStorageService: LocalStorageService
    ) {
    }

    ngOnInit() {
        this.gueltigCtrl.setValue(!this.onlyCurrent);

        this.gueltigCtrl.valueChanges.subscribe((value) => {
            this.onlyCurrent = !value;
            this.loadInformation();
        });

        this.loadInformation();
    }

    ngOnChanges() {
        // console.debug('permission ids: %d %d %d', this.permissionRead, this.permissionWrite, this.permissionDelete);
        this.loadInformation();
    }

    loadInformation() {
        if (
            this.informationType == 1 &&
            !this.localStorageService.hasPermission(4011)
        ) {
            return;
        }
        if (
            this.informationType == 2 &&
            !this.localStorageService.hasPermission(4031)
        ) {
            return;
        }

        let params = new HttpParams();
        params = params.append('offset', '0');
        params = params.append('limit', '100');
        params = params.append(
            'informationstyp',
            this.informationType?.toString()
        );
        if (this.onlyCurrent) {
            params = params.append('gueltig', '1');
        }
        this.api
            .get(
                this.apiBaseUrl + 'information/read_info.php',
                params
            )
            .subscribe((response) => {
                this.informationen = response.records;
            });
    }

    add() {
        let dialogRef = this.dialog.open(AddInformationDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: {
                informationstyp: this.informationType,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.loadInformation();
        });
    }

    edit(i: Information) {
        let dialogRef = this.dialog.open(AddInformationDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: {
                information: i,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.loadInformation();
        });
    }

    delete(i: Information) {
        let dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: {
                title: 'Information löschen',
                text: 'Wollen Sie die Information wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.api
                    .deleteEntity(this.apiBaseUrl + 'information/delete.php', i)
                    .subscribe(
                        (response) => {
                            this.messageService.infoMessage(
                                'Die Information wurde erfolgreich gelöscht!'
                            );
                            this.loadInformation();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Die Information konnte nicht gelöscht werden!',
                                error
                            );
                        }
                    );
            }
        });
    }
}
