<div style="padding: 12px">
    <h1>Gruppe {{ gruppe?.bezeichnung }} {{gruppe?.historie > 0 ? ('(Historie)') : ''}}
        - {{ gruppe?.kunde }}
        {{gruppe?.zusatz ? (' - ' + gruppe?.zusatz) : ''}}</h1>

    <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedIndex">
        <mat-tab label="Details, Verlauf & Statistiken">
            <kiene-details-verlauf-statisiken [gruppe]="gruppe"></kiene-details-verlauf-statisiken>
        </mat-tab>

        <!--<ng-container *ngIf="gruppe?.standard === 0">-->
        <ng-container>
            <!--<mat-tab *kienePermission="[9351,9161,9162]" label="Standorte, Gewichte & Preise">-->
            <mat-tab label="Standorte, Gewichte & Preise">
                <div style="width: 100%;">
                    <kiene-gruppe-standort-verwalten [gruppe]="gruppe"
                        [filterSettings]="filterSettings"></kiene-gruppe-standort-verwalten>
                </div>
            </mat-tab>
        </ng-container>

        <!--<mat-tab *kienePermission="[9161,9162]" label="Auswertungen">-->
        <mat-tab label="Auswertungen">
            <kiene-gewichte-preise-schweine [gruppe_id]="gruppe?.gruppe_id"></kiene-gewichte-preise-schweine>
        </mat-tab>
    </mat-tab-group>
</div>