<div class="settings-page">

    <div *ngIf="!isDialog" style="display: flex; flex-direction: column; justify-content: center; align-items: center">
        <kiene-page-header style="width:100%" [ueberschrift]="kunde?.vvvo"></kiene-page-header>
        <!-- <h3 style="margin-block-start: 0; margin-block-end: 0">Detailansicht VVVO</h3> -->
    </div>

    <div class="settings-content">

        <mat-card style="margin: 5%" *ngIf="!ersteinrichtung">
            <section class="settings-content__section">
                <div style="display: flex; align-items: center; padding-bottom: 6px">
                    <h1 class="settings-content__section__headline">Aktionen</h1>
                </div>
                <kiene-vvvo-details-actions [kunde]="kunde"></kiene-vvvo-details-actions>
            </section>
        </mat-card>

        <mat-card style="margin: 5%">
            <section class="settings-content__section">

                <div style="display: flex; align-items: center">
                    <h1 class="settings-content__section__headline">Informationen</h1>
                </div>

                <div class="vvvo-row">
                    <kiene-vvvo-basis-infos-form #basisInfos [kunde]="kunde"></kiene-vvvo-basis-infos-form>
                </div>

            </section>
        </mat-card>

        <mat-card style="margin: 5%">
            <section class="settings-content__section">

                <div style="display: flex; align-items: center;">
                    <h1 class="settings-content__section__headline">Bestands- und Impfkontrollbücher</h1>
                </div>
                <div>
                    <div style="font-weight: bold; margin-top: 24px;" class="kiene-primary">Digital selbst verwalten
                    </div>
                    <div class="vvvo-row">
                        <mat-radio-group [formControl]="bestandsbuchDigitalCtrl"
                                         (change)="changeBestandsbuchDigital($event)" class="rechnungs-optionen">
                            <mat-radio-button [checked]="kunde?.bestandsbuch_digital > 0" [value]=1>Ja
                            </mat-radio-button>
                            <mat-radio-button [checked]="kunde?.bestandsbuch_digital === 0" [value]=0>Nein
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </section>
        </mat-card>

        <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 12px">
            <button style="width: 80%" mat-flat-button color="accent" (click)="updateKunde()"
                    [disabled]="!allFormsValid()">{{ saveText }}
            </button>
        </div>
    </div>
</div>
