<div style="min-width:800px; padding: 12px;" (click)="$event.stopPropagation()">
    <div *kienePermission="[4482]" class="one-row">
        <kiene-article-scan-autocomplete #articleScan style="width: 50%;"
            (selectedMaterial)="setWarenkorbArtikel($event)"></kiene-article-scan-autocomplete>
        <span class="spacer"></span>

        <mat-form-field style="min-width: 100px; width: 20%;">
            <mat-label>Menge</mat-label>
            <input #mengeInput="matInput" matInput type="number" [formControl]="mengeCtrl" (blur)="blurMenge()">
        </mat-form-field>
        <span class="spacer"></span>

        <button mat-flat-button color="accent" style="width: 20%;" [disabled]="isAdding"
            (click)="addArtikelWarenkorb()">Hinzuf&uuml;gen</button>
    </div>

    <mat-divider></mat-divider>

    <table class="phito-table" style="margin-top: 24px; font-size: 14px;">
        <thead>
            <tr>
                <th>Pos.</th>
                <th>Artikel</th>
                <th>Menge</th>
                <th>Änderungsdatum</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let we of warenkorbEintraege; let i=index;">
                <td>{{(i+1)}}.</td>
                <td>{{we.artikel_me}}<br><span style="font-size: .7em;">Kunde: {{we.kunde}} {{we.zusatz}}</span></td>
                <td>{{we.artikelmenge}}</td>
                <td>{{we.editiert | date: 'dd.MM.YY HH:mm'}}</td>
                <td><kiene-delete-button *kienePermission="[4484]" (delete)="deleteItem(we)"></kiene-delete-button></td>
            </tr>
            <tr>
            </tr>
        </tbody>
    </table>
    <div class="one-row" style="margin-top: 12px;">
        <button mat-button (click)="close()">Schlie&szlig;en</button>
        <span class="spacer"></span>
        <button *kienePermission="[4485]" mat-flat-button color="accent" (click)="abgabebelegErzeugen()">Abgabebeleg
            erzeugen</button>
    </div>
</div>