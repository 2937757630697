import { GruppeTier } from "../../../administration/client-group/gruppe-tier";

export class Abholliste {
    abholliste_id: number;
    kunde_id: number;
    anzahl_tiere: number;
    kommentar: string;
    abholdatum: Date;
    status: string;
    status_id: number;
}


export class AbhollisteTiere {
    abholliste_id: number;
    gruppen_tiere: GruppeTier[] = [];
}
