import { Component, Inject, Input, OnInit } from '@angular/core';
import {
    BetriebBenutzerZuweisenDialogComponent
} from '../../../dialogs/betrieb-benutzer-zuweisen-dialog/betrieb-benutzer-zuweisen-dialog.component';
import { LocalStorageService } from '../../../../services/local-storage/local-storage.service';
import { Subscription } from 'rxjs';
import { KieneBackendApiService } from '../../../../services/backend-api/kiene-backend-api.service';
import { API_BASE_URL_SERVICE } from '../../../../kiene-core.config';
import { MessageService } from '../../../../services/message-service/message.service';
import { HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { BenutzerHinzufuegenDialogComponent } from '../benutzer-hinzufuegen-dialog/benutzer-hinzufuegen-dialog.component';
import {
    BenutzerrolleAendernDialogComponent
} from '../benutzerrolle-aendern-dialog/benutzerrolle-aendern-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { User } from '../benutzer';
import { ConfirmDialogComponent } from '../../../dialogs/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogInput, ConfirmDialogResult } from '../../../dialogs/confirm-dialog/confirm-dialog';
import { BenutzerService } from '../benutzer.service';

@Component({
    selector: 'kiene-meine-benutzer',
    templateUrl: './meine-benutzer.component.html',
    styleUrls: ['./meine-benutzer.component.scss']
})
export class MeineBenutzerComponent implements OnInit {

    @Input('showBackButton') showBackButton = false;

    ladeBenutzerSub: Subscription;
    benutzerList: User[] = [];

    constructor(private localStorageService: LocalStorageService,
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private benutzerService: BenutzerService,
        private messageService: MessageService,
        private dialog: MatDialog,
        private route: ActivatedRoute) {
        if (this.route.snapshot.data.showBackButton) {
            this.showBackButton = true;
        }
    }

    ngOnInit(): void {
        this.ladeBenutzer();
    }

    back() {
        history.back();
    }

    ladeBenutzerBetriebe(benutzer: User) {
        const params = new HttpParams().set('benutzer_id', benutzer.benutzer_id);
        this.api.get(this.apiBaseUrl + 'kunde/read_benutzer.php', params).subscribe({
            next: (response) => {
                benutzer.kunden = response.records;
            },
            error: (err) => {
                this.messageService.errorMessage('Betriebe konnten nicht geladen werden!', err);
            }
        });
    }

    ladeBenutzer() {
        if (this.localStorageService.hasPermission(58)) {
            this.ladeBenutzerSub?.unsubscribe();
            this.ladeBenutzerSub = this.api.get(
                this.apiBaseUrl + 'benutzer/read_meine_benutzer.php'
            ).subscribe({
                next: (response) => {
                    this.benutzerList = response.records;
                    for (const b of this.benutzerList) {
                        this.ladeBenutzerBetriebe(b);
                    }
                },
                error: err => {
                    this.messageService.errorMessage('Benutzer konnte nicht geladen werden!', err);
                }
            });
        }
    }

    benutzerHinzufuegen() {
        const dialogRef = this.dialog.open(BenutzerHinzufuegenDialogComponent, {
            width: '700px',
        });
        dialogRef.afterClosed().subscribe(
            {
                next: () => {
                    this.ladeBenutzer();
                }
            }
        );
    }

    rollenEditieren(benutzer: any) {
        const dialogRef = this.dialog.open(BenutzerrolleAendernDialogComponent, {
            width: '700px',
            data: { user: { ...benutzer } } // klonen
        });
        dialogRef.afterClosed().subscribe(
            {
                next: () => {
                    this.ladeBenutzer();
                }
            }
        );
    }

    benutzerEntfernen(benutzer: User) {
        if (benutzer) {
            const inp = new ConfirmDialogInput();
            inp.headline = 'Benutzer löschen';
            inp.text = 'Wollen Sie den Benutzer ' + benutzer.vorname + ' ' + benutzer.nachname + ' wirklich löschen?';
            inp.okButton = 'Löschen';
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: inp
            });
            dialogRef.afterClosed().subscribe({
                next: (result: ConfirmDialogResult) => {
                    if (result?.ok && result?.confirmed) {
                        this.benutzerService.deleteUser(benutzer).subscribe({
                            next: response => {
                                this.messageService.infoMessage('Der Benutzer wurde erfolgreich entfernt!');
                                this.ladeBenutzer();
                            },
                            error: err => {
                                this.messageService.errorMessage('Fehler!', err);
                            }
                        });
                    }
                }
            });
        }
    }

    betriebeEditieren(user: any) {
        const dialogRef = this.dialog.open(BetriebBenutzerZuweisenDialogComponent, {
            data: user,
            width: '100%'
        });

        dialogRef.afterClosed().subscribe({
            next: result => {
                this.ladeBenutzerBetriebe(user);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}
