<!-- kalender.component.html -->
<div style="width: 99%">

    <div class="one-row search-bar">
        <!-- Schieberegler Tabellen Höhe Stunden -->
        <div class="hour-height-slider">
            <label for="hourHeightSlider">{{ getSliderLabel(hourHeight) }}</label>
            <mat-slider
                id="hourHeightSlider"
                min="20"
                max="300"
                step="5"
                [(ngModel)]="hourHeight"
                tickInterval="5"
                thumbLabel
                aria-label="Stundenhöhe Schieberegler"
                (input)="onSliderInput($event)">
            </mat-slider>
        </div>

        <span class="spacer"></span>

        <kiene-calendar-week-picker [calendarWeek]="calenderWeek"
                                    (calendarWeekChange)="changeWeek($event)">
        </kiene-calendar-week-picker>

        <span class="spacer"></span>
        <!-- Suchfeld -->
        <mat-form-field class="search-field">
            <mat-label>Suche...</mat-label>
            <input [formControl]="searchInputCtrl" matInput (input)="onSearch($event)">
            <button mat-icon-button matSuffix (click)="onSearch($event)">
                <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>

        <span class="spacer"></span>

        <button (click)="addOrEditEntry()"
                *kienePermission="[7002]"
                mat-icon-button
                color="accent"
                class="add-button"
                matTooltip="Neuer Eintrag">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <!-- Kalender -->
    <div class="kalender-container">
        <!-- Kalender Header (Wochentage) bleibt fixiert -->
        <div class="one-row kalender-header">
            <div class="stunde_head"></div> <!-- Leere Ecke oben links -->
            <div class="tag_head" *ngFor="let day of dayNames;">
                {{ day }}
            </div>
        </div>

        <!-- Scrollbarer Container -->
        <div class="kalender-body" #scrollableCalendar (mousemove)="onMouseMove($event)"
             (mouseleave)="onMouseLeaveCalendar()">
            <div class="stundenbereich">
                <div class="stunde" [style.height.px]="hourHeight" *ngFor="let hour of hours">
                    {{ hour < 10 ? '0' + hour : hour }}:00
                </div>
            </div>

            <!-- Tooltip für die Uhrzeit-Anzeige -->
            <div *ngIf="showTooltip" class="tooltip" [ngStyle]="tooltipStyle">
                {{ tooltipContent }}
            </div>

            <div class="kalender-content" [ngStyle]="{'height': calcContainerHeight()}">
                <div class="tagebereiche">
                    <!-- Tagesbereiche (scrollbarer Bereich) -->
                    <!--                    TODO: Permission-->
                    <div class="tag" *ngFor="let day of days" (click)="onCalendarClick($event, day)">
                        <!-- Dynamische Ereignisse -->
                        <div class="ereignis" [matMenuTriggerFor]="ereignismenu" (click)="$event.stopPropagation();"
                             *ngFor="let eintrag of gruppierteEintraege[getWeekDayName(day)]"
                             (mouseenter)="onMouseEnter(eintrag)"
                             (mouseleave)="onMouseLeave(eintrag)"
                             [style.height.px]="calculateHeight(eintrag)"
                             [style.top.px]="berechneTop(eintrag.von)"
                             [style.left.%]="isHovered(eintrag) ? 0 : eintrag.left"
                             [style.width.%]="isHovered(eintrag) ? enlargedWidthPercentage : eintrag.width"
                             [style.z-index]="isHovered(eintrag) ? 1999 : eintrag.zIndex">
                            <div class="ereignis-inhalt">
                                <span>{{ eintrag.kunde }}, {{ formatTime(eintrag.von) }}
                                    - {{ formatTime(eintrag.bis) }}</span>
                                <ng-container *ngIf="calculateHeight(eintrag)> 60">
                                    <br>
                                    <span>{{ eintrag.beschreibung }}</span>

                                </ng-container>
                            </div>
                            <mat-menu #ereignismenu>
                                <button mat-menu-item *kienePermission="[7002]" (click)="goToBelegeDetails(eintrag)"
                                        [disabled]="!eintrag?.ht_beleg_id">
                                    <mat-icon>list</mat-icon>
                                    <span>Beleg anzeigen</span>
                                </button>
                                <button mat-menu-item *kienePermission="[7002]" (click)="eintragBearbeiten(eintrag)">
                                    <mat-icon>edit</mat-icon>
                                    <span>Bearbeiten</span>
                                </button>
                                <button mat-menu-item *kienePermission="[7004]" (click)="eintragLoeschen(eintrag)">
                                    <mat-icon>delete</mat-icon>
                                    <span>L&ouml;schen</span>
                                </button>
                            </mat-menu>

                            <!-- Ein einziges mat-menu außerhalb der *ngFor-Schleife -->
                            <mat-menu #menu="matMenu" (closed)="closeMenu()">
                                <button mat-menu-item (click)="onMenuItemClick('edit')" *kienePermission="[7002]">
                                    Bearbeiten
                                </button>
                                <button mat-menu-item (click)="onMenuItemClick('delete')" *kienePermission="[7004]">
                                    Löschen
                                </button>
                            </mat-menu>
                        </div>
                    </div>

                </div>

                <!-- Grid Lines -->
                <div class="grid-lines">
                    <!-- Horizontale Linien (Stundenlinien) -->
                    <div class="horizontal-lines">
                        <div class="linie" *ngFor="let hour of hours" [style.top.px]="hour * hourHeight"></div>
                        <div class="linie" [style.top.px]="24 * hourHeight"></div>
                    </div>

                    <!-- Vertikale Linien (Tageslinien) -->
                    <div class="vertical-lines">
                        <div class="linie" *ngFor="let day of days; let i = index"
                             [style.left.%]="((i + 1) * 100) / days.length"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
