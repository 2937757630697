<div mat-dialog-title>Position korrigieren</div>
<div mat-dialog-content>
    <p>Position: <span style="font-weight: bold;">{{position.position}}. {{position.artikel_me}}</span></p>
    <kiene-tierart-selector #tierartSelector [materialId]="position.artikel_id" [kundeId]="abgabebeleg.kunde_id"
        [openPanelOnFocus]="false" (tierartSelectionChanged)="setSelectedTierart($event)" [zeigeKeine]="false">
    </kiene-tierart-selector>
    <kiene-anwendungs-selector #anwendungSelector (change)="setSelectedAnwendung($event)"></kiene-anwendungs-selector>
    <kiene-diagnose-selector #diagnoseSelector (change)="setSelectedDiagnose($event)">
    </kiene-diagnose-selector>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()" [disabled]="buttonsDisabled">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()" [disabled]="buttonsDisabled">Korrigieren</button>
</div>