import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { KieneKeyValue } from "../../components/kiene-table/table-descriptor";
import {
    ConfirmWithOptionDialogInput,
    ConfirmWithOptionDialogResult,
} from "./confirm-with-option-dialog";

@Component({
    selector: "kiene-confirm-with-option-dialog",
    templateUrl: "./confirm-with-option-dialog.component.html",
    styleUrls: ["./confirm-with-option-dialog.component.scss"],
})
export class ConfirmWithOptionDialogComponent implements OnInit {
    selectCtrl = new UntypedFormControl(undefined, [Validators.required]);

    constructor(
        public dialogRef: MatDialogRef<ConfirmWithOptionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmWithOptionDialogInput
    ) {}

    ngOnInit(): void {}

    close() {
        const result = new ConfirmWithOptionDialogResult();
        result.ok = false;
        this.dialogRef.close(result);
    }

    ok() {
        const result = new ConfirmWithOptionDialogResult();
        result.ok = true;
        result.confirmed = true;
        result.option = this.selectCtrl.value;
        this.dialogRef.close(result);
    }
}
