import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import {KundeArtikel} from '../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-edit-client-artikel-dialog',
    templateUrl: './edit-client-artikel-dialog.component.html',
    styleUrls: ['./edit-client-artikel-dialog.component.scss'],
})
export class EditClientArtikelDialogComponent implements OnInit {
    clientId: number;
    ka = new KundeArtikel();
    einzelpreisCtrl = new UntypedFormControl();

    constructor(
        public dialogRef: MatDialogRef<EditClientArtikelDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.clientId = data.clientId;
        this.ka = data.ka;
    }

    ngOnInit() {
        this.einzelpreisCtrl.setValue(this.ka.einzelpreis);
    }

    cancel() {
        this.dialogRef.close(false);
    }

    save() {
        this.ka.einzelpreis = this.einzelpreisCtrl.value;
        this.dialogRef.close(this.ka);
    }
}
