import {Component, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../environments/environment";
import {KieneTableService} from "../../components/kiene-table/kiene-table.service";
import {TableColumn, TextTableColumn} from "../../components/kiene-table/table-column";
import {TableDescriptor} from "../../components/kiene-table/table-descriptor";
import {Condition, Operator, TableOptionEvent} from "../../components/kiene-table/table-option";
import {ConfirmDeleteDialogComponent} from "../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component";
import {MessageService} from "../../services/message.service";
import {HeimtierKundeDialogComponent} from "../heimtiere/heimtier-kunde-dialog/heimtier-kunde-dialog.component";
import {Client} from '../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-heimtier-kunden',
    templateUrl: './heimtier-kunden.component.html',
    styleUrls: ['./heimtier-kunden.component.scss']
})
export class HeimtierKundenComponent implements OnInit {
    public static readonly PERMISSION_VIEW = 7011;
    public static readonly PERMISSION_CREATE = 7012;
    public static readonly PERMISSION_UPDATE = 7012;
    public static readonly PERMISSION_DELETE = 7014;
    tableDescriptor = new TableDescriptor();

    constructor(public dialog: MatDialog,
                private api: KieneTableService,
                private messageService: MessageService) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Kunden';
        this.tableDescriptor.uniqueIdentifier = 'Heimtierkunden';
        this.tableDescriptor.apiUrl = environment.BASE_URI + 'heimtier_kunde/';

        const columns: TableColumn[] = [];

        columns.push(new TableColumn('bezeichnung', 'Kunde'));
        columns.push(new TextTableColumn('strasse', 'Straße'));
        columns.push(new TextTableColumn('plz', 'Postleitzahl'));
        columns.push(new TextTableColumn('ort', 'Ort'));
        columns.push(new TextTableColumn('email', 'E-Mail'));
        columns.push(new TextTableColumn('telefon_1', 'Telefon'));
        columns.push(new TextTableColumn('mobil_1', 'Mobil'));
        columns.push(new TextTableColumn('kommentar', 'Kommentar'));
        columns.push(new TextTableColumn('kunde_nr_intern', 'Interne Nummer'));

        this.tableDescriptor.columns = columns;

        this.tableDescriptor.initialSortColumn = 'bezeichnung';
        this.tableDescriptor.initialSortDirection = 'asc';
        this.tableDescriptor.defaultPageSize = 10;

        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.showDetailsAsOption = true;


        this.tableDescriptor.permissionView = HeimtierKundenComponent.PERMISSION_VIEW;
        this.tableDescriptor.permissionCreate = HeimtierKundenComponent.PERMISSION_CREATE;
        this.tableDescriptor.permissionUpdate = HeimtierKundenComponent.PERMISSION_UPDATE;
        this.tableDescriptor.permissionDelete = HeimtierKundenComponent.PERMISSION_DELETE;

        this.tableDescriptor.disableConditions.push(
            new Condition('aktiv', Operator.GLEICH, 0)
        );
    }


    optionClicked(event: TableOptionEvent) {
        console.debug('Table Option: ' + event.name);
        if (event.name === 'add') {
            this.openHeimtierKundeDialog(null);
        } else if (event.name === 'edit' || event.name === 'details') {
            console.log(event.name, event.value);
            this.openHeimtierKundeDialog(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        }
    }

    openHeimtierKundeDialog(kunde: Client) {
        const dialogRef = this.dialog.open(HeimtierKundeDialogComponent, {
            data: kunde
        });

        dialogRef.afterClosed().subscribe(response => {
            this.api.reload();
        });
    }

    openDeleteDialog(kunde: Client) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: kunde.bezeichnung + ' löschen?',
                text: 'Wollen Sie die Tierart ' + kunde.bezeichnung + ' wirklich löschen?'
            }
        });

        dialogRef.afterClosed().subscribe(response => {
            if (response) {
                this.api.deleteEntity(environment.BASE_URI + 'heimtier_kunde/delete.php', kunde).subscribe({
                    next: result => {
                        this.messageService.infoMessage('Die Tierart wurde erfolgreich gelöscht!');
                        this.api.reload();
                    }, error: err => {
                        this.messageService.errorMessage('Fehler! Es ein Problem beim Löschen der Tierart aufgetreten! ', err);
                    }
                });
            }
        });
    }
}
