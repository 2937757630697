import { Inject, Injectable } from "@angular/core";
import { API_BASE_URL_SERVICE } from "../../../../projects/kiene-core/src/lib/kiene-core.config";
import {
    KieneApi,
    KieneBackendApiService,
} from "../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service";
import { Observable } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { Bestandsbuch } from "./bestandsbuch/classes/bestandsbuch";
import { BestandsbuchArtikel } from "./bestandsbuch/classes/bestandsbuch-artikel";

@Injectable({
    providedIn: "root",
})
export class BestandsbuchService {
    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        private api: KieneBackendApiService
    ) { }

    ladeVvvos(): Observable<any> {
        return this.api.getAll(
            `${this.apiBaseUrl}kunde/read_kundenportal.php`
        );
    }

    getBestandsbuch(bestandsbuch_id: number) {
        const params = new HttpParams().set(
            "bestandsbuch_id",
            bestandsbuch_id.toString()
        );
        return this.api.get(
            `${this.apiBaseUrl}bestandsbuch/read_one_kundenportal.php`,
            params
        );
    }

    updateBestandsbuch(bestandsbuch: Bestandsbuch) {
        return this.api.post(
            `${this.apiBaseUrl}bestandsbuch/update_kundenportal.php`,
            bestandsbuch,
            null
        );
    }

    bestandsbuchAbschliessen(bestandsbuch: Bestandsbuch) {
        return this.api.post(
            `${this.apiBaseUrl}bestandsbuch/abschliessen_bestandsbuch.php`,
            bestandsbuch,
            null
        );
    }

    getBestandsbuchArtikel(bestandsbuch_artikel_id: number) {
        const params = new HttpParams().set(
            "bestandsbuch_artikel_id",
            bestandsbuch_artikel_id.toString()
        );
        return this.api.get(
            `${this.apiBaseUrl}bestandsbuch_artikel/read_one_kundenportal.php`,
            params
        );
    }

    updateBestandsbuchArtikel(bestandsbuch_artikel: BestandsbuchArtikel) {
        return this.api.post(
            `${this.apiBaseUrl}bestandsbuch_artikel/update_kundenportal.php`,
            bestandsbuch_artikel,
            null
        );
    }

    alleArtikelProtokollieren(bestandsbuch: Bestandsbuch) {
        return this.api.post(
            `${this.apiBaseUrl}bestandsbuch/alle_angewendet_kundenportal.php`,
            bestandsbuch,
            null
        );
    }
}
