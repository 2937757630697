<button (click)="$event.stopPropagation()" [matMenuTriggerFor]="gruppenMenu" aria-label="Gruppenmenu" mat-icon-button>
    <mat-icon color="accent">more_vert</mat-icon>
</button>
<mat-menu #gruppenMenu="matMenu">
    <button (click)="bearbeiten()" *ngIf="gruppe?.historie == 0" mat-menu-item>
        <mat-icon color="accent">edit</mat-icon>
        Bearbeiten
    </button>
    <button (click)="tiereHinzufuegen()" *ngIf="gruppe?.historie == 0" mat-menu-item>
        <mat-icon color="accent">login</mat-icon>
        Einstallen
    </button>
    <button (click)="ausstallen()" *ngIf="gruppe?.historie == 0" mat-menu-item>
        <mat-icon color="accent">logout</mat-icon>
        Ausstallen
    </button>
    <button (click)="standortwechsel()" *ngIf="gruppe?.historie == 0" mat-menu-item>
        <mat-icon color="accent">swap_horiz</mat-icon>
        Standort wechseln
    </button>

    <button (click)="ausstalllistenAnzeigen()" *ngIf="gruppe?.historie == 0" mat-menu-item>
        <mat-icon color="accent">list</mat-icon>
        Ausstalllisten anzeigen
    </button>
    <button [matMenuTriggerFor]="hitMenu" mat-menu-item>
        <mat-icon color="accent">cloud_sync</mat-icon>
        HI-Tier
    </button>
    <mat-menu #hitMenu>
        <button (click)="tiereNachladen()" mat-menu-item>
            <mat-icon color="accent">cloud_sync</mat-icon>
            Tiere mit HIT synchronisieren
        </button>
        <button (click)="zugaengeMelden()" mat-menu-item>
            <mat-icon color="accent" style="transform: rotateY(180deg)">cloud_sync
            </mat-icon>
            Zugänge an HIT melden
        </button>
        <button (click)="abgaengeMelden()" mat-menu-item>
            <mat-icon color="accent" style="transform: rotateY(180deg)">cloud_sync
            </mat-icon>
            Abgänge an HIT melden
        </button>
    </mat-menu>
    <button (click)="zertifikateAnSGS()" *ngIf="this.gruppe?.sgs > 0" mat-menu-item>
        <mat-icon color="accent">biotech</mat-icon>
        Zertifizierungsdatei an SGS senden
    </button>
    <button (click)="openGruppeInformationen()" mat-menu-item>
        <mat-icon color="accent">description</mat-icon>
        Gruppeninformationen
    </button>
    <button [matMenuTriggerFor]="downloadMenu" mat-menu-item>
        <mat-icon color="accent">file_download</mat-icon>
        Download
    </button>
    <mat-menu #downloadMenu="matMenu">
        <button (click)="reklamationenPdfDownload()" mat-menu-item>
            <mat-icon color="accent">table_chart</mat-icon>
            Reklamationsliste
        </button>
        <button (click)="csvExport()" mat-menu-item>
            <mat-icon color="accent">forward_to_inbox</mat-icon>
            CSV-Datei aller Tiere
        </button>
        <button (click)="excelExport()" mat-menu-item>
            <mat-icon color="accent">forward_to_inbox</mat-icon>
            Excel-Datei aller Tiere
        </button>
        <button (click)="abgaengePdfDownload()" mat-menu-item>
            <mat-icon color="accent">logout</mat-icon>
            Ausstallliste / Lieferschein
        </button>
    </mat-menu>
    <button (click)="ohrmarkenGescanntMarkieren()" mat-menu-item>
        <mat-icon color="accent">label</mat-icon>
        <span>Alle Ohrmarken als gescannt markieren</span>
    </button>
    <ng-container *kienePermission="[9114]">
        <ng-container *ngIf="gruppe?.standard === 0">
            <button *ngIf="gruppe?.historie === 0" (click)="gruppeInHistorie()" mat-menu-item>
                <mat-icon color="accent">history</mat-icon>
                <span>Gruppe in Historie</span>
            </button>
            <button *ngIf="gruppe?.historie > 0" (click)="gruppeAusHistorie()" mat-menu-item>
                <mat-icon color="accent">history</mat-icon>
                <span>Gruppe aus Historie holen</span>
            </button>
        </ng-container>
    </ng-container>
    <button *ngIf="gruppe?.historie === 0" (click)="deleteGruppe()" mat-menu-item>
        <mat-icon color="accent">delete</mat-icon>
        <span>Gruppe löschen</span>
    </button>
</mat-menu>