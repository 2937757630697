<mat-form-field *ngIf="!hideOnEmptyList || this.abteileFuerGruppe.length > 0 || this.isLoading"
    class="abgang-form-input no-padding-margin-mat-form-field" style="margin: 6px 0 0 0">
    <mat-icon style="padding-right: 6px" matPrefix>search</mat-icon>
    <mat-label>{{label}}</mat-label>
    <mat-select multiple [compareWith]="compareAbteil" [formControl]="selectedAbteilCtrl">
        <mat-option *ngFor="let abteil of abteileFuerGruppe" [value]="abteil">{{abteil.abteil}} ({{abteil.anzahl}}
            Tiere)</mat-option>
    </mat-select>
    <button *ngIf="selectedAbteilCtrl.value && abteileFuerGruppe.length > 0" matSuffix mat-icon-button
        aria-label="Clear" (click)="resetAbteilCtrl($event)">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>