import { Component, OnInit } from '@angular/core';
import { TableDescriptor } from '../../components/kiene-table/table-descriptor';
import { environment } from '../../../environments/environment';
import { DateTableColumn, SlideToggleTableColumn, TableColumn, } from '../../components/kiene-table/table-column';
import { TableOption, TableOptionEvent, } from '../../components/kiene-table/table-option';
import { MatDialog } from '@angular/material/dialog';
import { Inventur } from '../../warehouse/inventur/entities/Inventur';
import { InventurCreateDialogComponent } from './inventur-create-dialog/inventur-create-dialog.component';
import { InventurEditDialogComponent } from './inventur-edit-dialog/inventur-edit-dialog.component';
import { InventurDeleteDialogComponent } from './inventur-delete-dialog/inventur-delete-dialog.component';
import {
    InventurActiceNoticeDialogComponent
} from './inventur-actice-notice-dialog/inventur-actice-notice-dialog.component';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../services/message.service';
import { KieneFileService } from '../../../../projects/kiene-core/src/lib/services/files/kiene-file.service';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'kiene-inventur-verwaltung',
    templateUrl: './inventur-verwaltung.component.html',
    styleUrls: ['./inventur-verwaltung.component.scss'],
})
export class InventurVerwaltungComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        private dialog: MatDialog,
        private tableService: KieneTableService,
        private messageService: MessageService,
        private fileService: KieneFileService
    ) {
    }

    ngOnInit(): void {
        this.tableDescriptor.headline = `Inventur - Verwaltung`;
        this.tableDescriptor.uniqueIdentifier = `Inventur - Verwaltung`;
        this.tableDescriptor.apiUrl = environment.BASE_URI + 'inventur/';
        this.tableDescriptor.route = '/lager/inventur-verwaltung/';
        this.tableDescriptor.nameOfIdField = 'inventur_id';
        this.tableDescriptor.searchPlaceholder =
            'Nach Inventurjahr durchsuchen';
        this.tableDescriptor.columns.push(new TableColumn('name', 'Name'));
        this.tableDescriptor.columns.push(new TableColumn('jahr', 'Jahr'));
        this.tableDescriptor.columns.push(
            new DateTableColumn('erstellt', 'Erstellt am', 'dd.MM.yy')
        );
        this.tableDescriptor.columns.push(
            new SlideToggleTableColumn('aktiv', 'Aktiv', 'inventurAktivSlider')
        );
        this.tableDescriptor.initialSortColumn = 'erstellt';
        this.tableDescriptor.initialSortDirection = 'desc';
        this.tableDescriptor.permissionCreate = 4442;
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;
        this.tableDescriptor.options.push(
            new TableOption('bearbeiten', 'Bearbeiten', 'edit', 4442)
        );
        this.tableDescriptor.options.push(
            new TableOption('delete', 'Löschen', 'delete', 4444)
        );
        this.tableDescriptor.options.push(
            new TableOption('downloadCsv', 'CSV Export', 'file_download', 4445)
        );
    }

    catchOptionEvent(event: TableOptionEvent) {
        const inventur: Inventur = event.value;
        if (event.name === 'add') {
            this.openCreateDialog();
        } else if (event.name === 'bearbeiten') {
            this.openEditDialog(inventur);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(inventur);
        } else if (event.name === 'inventurAktivSlider') {
            if (inventur.aktiv) {
                this.openNoticeDialog(inventur);
            } else {
                this.updateInventur(inventur);
            }
        } else if (event.name === 'downloadCsv') {
            this.exportAsCsv(inventur);
        }
        return;
    }

    private exportAsCsv(inventur: Inventur) {
        const params = new HttpParams().set(
            'inventur_id',
            inventur.inventur_id.toString()
        );
        this.fileService.downloadCSV(
            `${environment.BASE_URI}inventur/read_csv.php`,
            params
        );
    }

    private openNoticeDialog(inventur: Inventur) {
        const dialogRef = this.dialog.open(
            InventurActiceNoticeDialogComponent,
            {
                data: {
                    inventur: inventur,
                },
            }
        );
        dialogRef.afterClosed().subscribe({
            next: (setActive: boolean) => {
                if (setActive) {
                    this.updateInventur(inventur);
                } else {
                    inventur.aktiv = 0;
                }
            },
        });
    }

    private openCreateDialog() {
        const dialogRef = this.dialog.open(InventurCreateDialogComponent, {
            data: {},
        });
        dialogRef.afterClosed().subscribe({
            next: (createdInventur: Inventur) => {
                if (createdInventur) {
                    this.createNewInventur(createdInventur);
                }
            },
        });
    }

    private createNewInventur(createdInventur: Inventur) {
        this.tableService
            .post(`${environment.BASE_URI}inventur/create.php`, createdInventur)
            .subscribe({
                next: () => {
                    this.tableService.reload();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Die Inventur konnte nicht angelegt werden!',
                        err
                    );
                },
            });
        return;
    }

    private openEditDialog(value: any) {
        const dialogRef = this.dialog.open(InventurEditDialogComponent, {
            data: {
                inventur: value,
            },
        });
        dialogRef.afterClosed().subscribe({
            next: (inventur: Inventur) => {
                if (inventur) {
                    this.updateInventur(inventur);
                }
            },
        });
    }

    private openDeleteDialog(value: any) {
        const dialogRef = this.dialog.open(InventurDeleteDialogComponent, {
            data: {
                inventur: value,
            },
        });
        dialogRef.afterClosed().subscribe({
            next: (toDelete: boolean) => {
                if (toDelete) {
                    this.deleteInventur(value);
                }
            },
        });
    }

    private updateInventur(inventur: Inventur) {
        this.tableService
            .updateEntity(`${environment.BASE_URI}inventur/update.php`, inventur)
            .subscribe({
                next: () => {
                    this.tableService.reload();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Die Inventur konnte nicht gespeichert werden!',
                        err
                    );
                    inventur.aktiv = 0;
                },
            });
    }

    private deleteInventur(inventur: Inventur) {
        this.tableService
            .deleteEntity(`${environment.BASE_URI}inventur/delete.php`, inventur)
            .subscribe({
                next: () => {
                    this.tableService.reload();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Die Inventur konnte nicht gelöscht werden',
                        err
                    );
                },
            });
    }
}
