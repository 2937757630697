import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ClientService} from '../../administration/client/client.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {Client} from '../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-client-selector',
    templateUrl: './client-selector.component.html',
    styleUrls: ['./client-selector.component.scss']
})
export class ClientSelectorComponent implements OnInit {

    clients: Client[] = [];

    @Output('clientChange') clientChange = new EventEmitter<Client>();

    constructor(private clientService: ClientService, private bottomSheet: MatBottomSheet) {
    }

    ngOnInit() {
        this.loadClients();
    }


    loadClients() {
        this.clientService.searchClients('').subscribe(response => {
            this.clients = response.records;
        }, error => {
        });
    }

}

