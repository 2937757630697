<h1 mat-dialog-title>{{input?.ausstallen ? 'Ausstallen: ' : 'Einstallen: '}}Gewichte / Preise pflegen f&uuml;r
    Gruppe {{input?.gruppeStandort?.gruppe}}</h1>
<div mat-dialog-content>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <span class="spacer"></span>
    </div>
    <ng-container *ngIf="!isLoading">
        <table>
            <tr *ngIf="input?.einstallen">
                <td>Viehh&auml;ndler</td>
                <td><strong>{{input?.viehhaendler?.name}}</strong></td>
            </tr>
            <ng-container *ngIf="input?.einstallen">
                <tr *ngFor="let ed of input?.einstalldatums">
                    <td>Einstalldatum</td>
                    <td><strong>{{ed | date: 'dd.MM.yyyy'}}</strong></td>
                </tr>
            </ng-container>
            <tr *ngIf="input?.ausstallen">
                <td>Tierliste</td>
                <td><strong>{{input?.tierliste?.datum_abgang | date: 'dd.MM.yyyy'}}
                        {{input?.tierliste?.kunde_zielbetrieb}}</strong>
                </td>
            </tr>
            <tr *ngIf="input?.ausstallen">
                <td>Kommentar</td>
                <td><strong>{{input?.tierliste?.kommentar}}</strong>
                </td>
            </tr>
            <tr>
                <td>Anzahl Tiere</td>
                <td><strong>{{anzahlTiere}}</strong></td>
            </tr>
        </table>
        <p>Sind bereits Gewichte und Preise zu den oben angezeigten Daten vorhanden, werden diese beim Speichern der
            neuen Daten &uuml;berschrieben!</p>
        <mat-form-field style="width: 100%;">
            <mat-label>Gesamtgewicht der Tiere</mat-label>
            <input [formControl]="gesamtGewichtCtrl" inputmode="decimal" matInput type="number" />
            <span matSuffix><strong>kg</strong></span>
        </mat-form-field>
        <br>
        <mat-form-field style="width: 100%;">
            <mat-label>Durchschnittsgewicht eines Tieres</mat-label>
            <input [formControl]="einzeltierGewichtCtrl" inputmode="decimal" matInput type="number" />
            <span matSuffix><strong>kg</strong></span>
        </mat-form-field>
        <br>
        <mat-form-field style="width: 100%;">
            <mat-label>Gesamtpreis der Tiere (netto)</mat-label>
            <input [formControl]="gesamtpreisCtrl" inputmode="decimal" matInput type="number" />
            <span matSuffix><strong>&euro;</strong></span>
        </mat-form-field>
        <br>
        <mat-form-field style="width: 100%;">
            <mat-label>Einzelpreis pro Tier (netto)</mat-label>
            <input [formControl]="einzelpreisCtrl" inputmode="decimal" matInput type="number" />
            <span matSuffix><strong>&euro;</strong></span>
        </mat-form-field>
        <br>
        <mat-radio-group [formControl]="mwstCtrl" style="margin-bottom: 24px;">
            <mat-radio-button [value]="7">7% MwSt.</mat-radio-button>
            <mat-radio-button [value]="9">9% MwSt.</mat-radio-button>
        </mat-radio-group>
    </ng-container>
</div>
<div style="margin-top: 24px;">
    <span class="spacer"></span>
    <button (click)="dialogRef.close(false)" mat-button>Abbrechen</button>
    <button (click)="save()" color="accent" style="margin-left: 12px;" mat-flat-button>Speichern</button>
</div>