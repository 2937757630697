import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { KieneApi } from './kiene-api';
import { ParamsChanged } from './table-descriptor';

@Injectable({
    providedIn: 'root',
})
export class KieneTableService {
    reloadSubject = new Subject<string>();
    loadingFinishedSubject = new Subject<boolean>();
    dataLoadedCompletedSubject = new Subject<boolean>();
    buttonClickedSubject = new Subject<string>();
    columnsSubject = new Subject<boolean>();
    searchSubject = new Subject<string>();
    clearSelectionSubject = new Subject<boolean>();
    paramsChangedSubject = new Subject<ParamsChanged>();

    currentData: KieneApi;
    currentTotalData: any;

    selectedRows: any[] = [];
    private lastTableUrl: string;
    private lastTableParams: HttpParams;

    constructor(
        private http: HttpClient
    ) {
    }

    getLastTableUrl(): string{
        return this.lastTableUrl;
    }

    getLastTableParams(): HttpParams{
        return this.lastTableParams;
    }

    setLastTableUrlAndParams(url: string, params: HttpParams) {
        this.lastTableUrl = url;
        this.lastTableParams = params;
    }

    public get(url: string, params?: HttpParams, headers?: HttpHeaders): Observable<KieneApi> {
        return this.http.get<KieneApi>(url, { params: params, headers: headers });
    }

    public getOneByParams(url: string, params: HttpParams): Observable<any> {
        return this.http.get<any>(url, { params: params });
    }

    public getOne(
        url: string,
        idFieldName: string,
        id: string
    ): Observable<any> {
        let params = undefined;
        if (idFieldName && id) {
            params = new HttpParams().set(idFieldName, id);
        }
        return this.http.get<any>(
            url, { params: params }
        );
    }

    public getByIdAlternative(
        url: string,
        idFieldName: string,
        id: string
    ): Observable<any> {
        return this.http.get<any>(url + '?' + idFieldName + '=' + id);
    }

    public updateEntity(url: string, entity: any): Observable<any> {
        return this.http.post(url, entity);
    }

    public updateGeneric(url: string, entity: any): Observable<any> {
        return this.http.post(url, entity);
    }

    public deleteEntity(url: string, entity: any): Observable<any> {
        return this.http.post(url, entity);
    }

    public deleteGeneric(url: string, entity: any): Observable<any> {
        return this.http.post(url, entity);
    }

    public duplicateEntity(url: string, entity: any): Observable<any> {
        return this.http.post(url, entity);
    }

    /*public setFilter(params: HttpParams) {
        this.filter.next(params);
    }

    public watchFilter(): Observable<HttpParams> {
        return this.filter.asObservable();
    }*/

    public watchClearSelection(): Observable<boolean> {
        return this.clearSelectionSubject.asObservable();
    }

    public post(url: string, entity: any, params?: HttpParams): Observable<any> {
        return this.http.post(url, entity, { params: params });
    }

    reload(sender?: string) {
        this.reloadSubject.next(sender);
    }

    watchReload(): Observable<string> {
        return this.reloadSubject.asObservable();
    }

    updateColumns() {
        this.columnsSubject.next(true);
    }

    sendSearchTxt(search: string) {
        this.searchSubject.next(search);
    }

    watchSearchTxt(): Observable<string> {
        return this.searchSubject.asObservable();
    }

    watchColumns(): Observable<boolean> {
        return this.columnsSubject.asObservable();
    }

    setButtonClicked(button: string) {
        this.buttonClickedSubject.next(button);
    }

    watchButtonClicked(): Observable<string> {
        return this.buttonClickedSubject.asObservable();
    }

    setCurrentData(currentData: KieneApi) {
        this.currentData = currentData;
    }

    getCurrentData(): KieneApi {
        return this.currentData;
    }

    public setCurrentTotalData(data: any) {
        this.currentTotalData = data;
    }

    public getCurrentTotalData() {
        return this.currentTotalData;
    }

    public dataLoadedCompleted() {
        this.dataLoadedCompletedSubject.next(true);
    }

    public watchDataLoadedCompleted() {
        return this.dataLoadedCompletedSubject.asObservable();
    }

    clearSelection() {
        this.clearSelectionSubject.next(true);
    }

    setSelectedRows(rows: any[]) {
        this.selectedRows = rows;
    }

    getSelectedRows() {
        return this.selectedRows;
    }

    public setParamsChanged(uniqueIdentifier: string, params: HttpParams) {
        const pc = new ParamsChanged(uniqueIdentifier, params);
        this.paramsChangedSubject.next(pc);
    }

    public watchParamsChanged() {
        return this.paramsChangedSubject.asObservable();
    }


}
