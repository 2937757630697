import { DatePipe, formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChartType } from 'angular-google-charts';
import { environment } from '../../../../environments/environment';
import { Vet } from '../../../administration/vet/vet';
import { AppService } from '../../../app.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { ZeitauswahlChange } from '../../zeitauswahl/zeitauswahl-change';
import { Wareneingang } from './wareneingang';
import { CsvColumn } from '../../../csv-export/csv-export/csv-column';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { Subscription } from 'rxjs';
import { ReportUtils } from '../../report-utils';

@Component({
    selector: 'kiene-wareneingang-report',
    templateUrl: './wareneingang-report.component.html',
    styleUrls: ['./wareneingang-report.component.scss'],
    animations: [
        trigger('detailExpanded', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
    ],
})
export class WareneingangReportComponent implements OnInit, AfterViewInit {
    displayedColumns = [
        'artikel',
        'wareneingang',
        'warenwert',
        'preis_durchschnitt',
    ];
    dataSource = new MatTableDataSource<Wareneingang>();
    dataSourceVorjahr = new MatTableDataSource<Wareneingang>();
    @ViewChild(MatSort) sort: MatSort;
    expandedElement: Wareneingang | null;

    tieraerzte: Vet[] = [];
    aktuellerTierarzt: Vet = new Vet();
    tierarztCtrl = new UntypedFormControl();

    reportParams = new HttpParams();
    zeiteintrag: ZeitauswahlChange;
    vorjahreszeitraum: ZeitauswahlChange;

    chartType = ChartType.PieChart;
    chartColumns = ['Artikel', 'Warenwert'];
    chartData = [['', 0]];

    csvColumns: CsvColumn[] = [];
    csvColumnsDetails: CsvColumn[] = [];
    csvFilename = 'wareneingang.csv';

    gesamtWarenwert = 0;
    gesamtWarenwertVorjahr = 0;

    isLoading: boolean[] = [];
    aktuellSubscription: Subscription;
    vorjahrSubscription: Subscription;

    constructor(
        private api: KieneTableService,
        private appService: AppService,
        private datePipe: DatePipe,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.reportParams = this.reportParams.set(
            'von',
            this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        );
        this.reportParams = this.reportParams.set(
            'bis',
            this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        );

        this.csvColumns.push(new CsvColumn('artikel', 'Artikel'));
        this.csvColumns.push(new CsvColumn('wareneingang', 'Wareneingang'));
        this.csvColumns.push(new CsvColumn('warenwert', 'Wareneinsatz'));
        this.csvColumns.push(
            new CsvColumn('preis_durchschnitt', 'Durchschnittspreis')
        );

        this.csvColumnsDetails = this.csvColumns.map((column) => ({
            ...column,
        }));
        this.csvColumnsDetails.splice(
            0,
            1,
            new CsvColumn('zeitraum', 'Zeitraum')
        );
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    loadData() {
        this.loadDataAktuell();
        this.loadDataVorjahr();
    }

    loadDataAktuell() {
        this.appService.setDataLoading(true);

        let params = new HttpParams();
        params = params.set('von', formatDate(this.zeiteintrag.von, 'yyyy-MM-dd', 'de'));
        params = params.set('bis', formatDate(this.zeiteintrag.bis, 'yyyy-MM-dd', 'de'));

        this.aktuellSubscription?.unsubscribe();
        this.aktuellSubscription = this.api
            .get(
                environment.BASE_URI + 'report/read_waren_eingang.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSource.data = response.records;
                    this.resetChartData();
                    this.berechneGesamtwerte();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    loadDataVorjahr() {
        this.appService.setDataLoading(true);

        let params = new HttpParams();
        params = params.set('von', formatDate(this.vorjahreszeitraum.von, 'yyyy-MM-dd', 'de'));
        params = params.set('bis', formatDate(this.vorjahreszeitraum.bis, 'yyyy-MM-dd', 'de'));

        this.vorjahrSubscription?.unsubscribe();
        this.vorjahrSubscription = this.api
            .get(
                environment.BASE_URI + 'report/read_waren_eingang.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSourceVorjahr.data = response.records;
                    this.berechneGesamtwerteVorjahr();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    zeitintervalChanged(event: ZeitauswahlChange) {
        this.zeiteintrag = event;
        this.vorjahreszeitraum = ReportUtils.getVorjahreszeitraum(this.zeiteintrag);
        this.loadData();
    }

    berechneGesamtwerte() {
        this.gesamtWarenwert = 0;
        const data = this.dataSource.data;
        for (const r of data) {
            this.chartData.push([r.artikel, r.warenwert ? r.warenwert : 0]);
            this.gesamtWarenwert += r.warenwert ? r.warenwert : 0;
        }
    }

    berechneGesamtwerteVorjahr() {
        this.gesamtWarenwertVorjahr = 0;
        const data = this.dataSourceVorjahr.data;
        for (const r of data) {
            this.gesamtWarenwertVorjahr += r.warenwert ? r.warenwert : 0;
        }
    }

    expandVetGroupRow(element: Wareneingang) {
        this.expandedElement =
            this.expandedElement === element ? null : element;
        if (this.expandedElement) {
            this.isLoading[element.artikel_id] = true;

            let params = new HttpParams();
            params = params.set('von', formatDate(this.zeiteintrag.von, 'yyyy-MM-dd', 'de'));
            params = params.set('bis', formatDate(this.zeiteintrag.bis, 'yyyy-MM-dd', 'de'));
            params = params.set(
                'aggregieren',
                this.zeiteintrag.aggregieren
            );
            params = params.set('artikel_id', element.artikel_id.toString());
            this.api
                .get(
                    environment.BASE_URI + 'report/read_waren_eingang.php',
                    params
                )
                .subscribe(
                    (response) => {
                        element.details = response.records;
                        this.isLoading[element.artikel_id] = false;
                    },
                    (error) => {
                        this.messageService.errorMessage('Fehler!', error);
                        this.isLoading[element.artikel_id] = false;
                    }
                );
        }
    }

    resetChartData() {
        this.chartData = [['', 0]];
    }

}
