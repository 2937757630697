import {LieferantDetailsComponent} from "./lieferant/lieferant-details/lieferant-details.component";
import {LieferantUebersichtComponent} from "./lieferant/lieferant-uebersicht/lieferant-uebersicht.component";
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EntsorgerDetailsComponent} from './entsorger/entsorger-details/entsorger-details.component';
import {HaendlerDetailsComponent} from './haendler/haendler-details/haendler-details.component';
import {EntsorgerUebersichtComponent} from './entsorger/entsorger-uebersicht/entsorger-uebersicht.component';
import {VvvoUebersichtComponent} from './vvvo/vvvo-uebersicht/vvvo-uebersicht.component';
import {VvvoHinzufuegenHitComponent} from './vvvo/vvvo-hinzufuegen-hit/vvvo-hinzufuegen-hit.component';
import {VvvoDetailsComponent} from './vvvo/vvvo-details/vvvo-details.component';
import {
    VvvoDetailsActionsHitPinComponent
} from './vvvo/vvvo-details-actions-hit-pin/vvvo-details-actions-hit-pin.component';
import {
    TierReklamationenUebersichtComponent
} from './tierreklamationen/tierreklamationen-uebersicht/tier-reklamationen-uebersicht.component';
import {
    TierReklamationenEditierenComponent
} from './tierreklamationen/tierreklamationen-editieren/tier-reklamationen-editieren.component';
import {UnterschriftComponent} from './unterschrift/unterschrift.component';
import {
    EntsorgerKundenNrVerwaltenComponent
} from './entsorger/entsorger-kunden-nr-verwalten/entsorger-kunden-nr-verwalten.component';
import {MeinProfilComponent} from './mein-profil/mein-profil.component';
import {MeineBenutzerComponent} from './benutzer/meine-benutzer/meine-benutzer.component';
import {FreigabenComponent} from './freigaben/freigaben.component';
import {
    HaendlerUebersichtFavoritenAltComponent
} from './haendler/haendler-uebersicht-favoriten-alt/haendler-uebersicht-favoriten-alt.component';
import {KontakteComponent} from './kontakte/kontakte.component';

const routes: Routes = [
    {path: 'entsorger', component: EntsorgerUebersichtComponent},
    {path: 'entsorger/details', component: EntsorgerDetailsComponent},
    {path: 'entsorger/details/:id', component: EntsorgerDetailsComponent},
    {path: 'entsorger/details/:id/kunden-nr', component: EntsorgerKundenNrVerwaltenComponent},
    // {path: 'haendler', component: HaendlerUebersichtComponent},
    {path: 'haendler', component: HaendlerUebersichtFavoritenAltComponent},
    {path: 'haendler/details', component: HaendlerDetailsComponent},
    {path: 'haendler/details/:id', component: HaendlerDetailsComponent},
    {path: 'kontakte', component: KontakteComponent},
    {path: 'futtermittellieferanten', component: LieferantUebersichtComponent},
    {path: 'futtermittellieferanten/details', component: LieferantDetailsComponent},
    {path: 'futtermittellieferanten/details/:id', component: LieferantDetailsComponent},
    {path: 'freigaben', component: FreigabenComponent},
    {path: 'vvvo', component: VvvoUebersichtComponent},
    {path: 'vvvo/details', component: VvvoDetailsComponent},
    {path: 'vvvo/hit', component: VvvoHinzufuegenHitComponent},
    {path: 'vvvo/details/:id', component: VvvoDetailsComponent},
    {path: 'vvvo/details/:id/hit-pin', component: VvvoDetailsActionsHitPinComponent},
    {path: 'tierreklamationen', component: TierReklamationenUebersichtComponent},
    {path: 'tierreklamationen/details/:id', component: TierReklamationenEditierenComponent},
    {path: 'unterschrift', component: UnterschriftComponent},
    {path: 'mein-profil', component: MeinProfilComponent, data: {showBackButton: true}},
    {path: 'meine-benutzer', component: MeineBenutzerComponent, data: {showBackButton: true}},
    {path: '**', redirectTo: 'entsorger'}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class StammdatenRoutingModule {
}
