import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BenutzerHinzufuegenDialogComponent} from './benutzer-hinzufuegen-dialog/benutzer-hinzufuegen-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {
    BenutzerrolleAendernDialogComponent
} from './benutzerrolle-aendern-dialog/benutzerrolle-aendern-dialog.component';
import {MeineBenutzerComponent} from './meine-benutzer/meine-benutzer.component';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [
        BenutzerHinzufuegenDialogComponent,
        BenutzerrolleAendernDialogComponent,
        MeineBenutzerComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatIconModule
    ],
    exports: [
        BenutzerHinzufuegenDialogComponent,
        BenutzerrolleAendernDialogComponent,
        MeineBenutzerComponent
    ]
})
export class BenutzerModule {
}
