<kiene-page-header [small]="true" [ueberschrift]="'Ausstallen: ' + (gruppe?.bezeichnung ? gruppe?.bezeichnung : '')"
    [showReturnButton]="!alsDialog">
</kiene-page-header>
<div style="padding: 0 16px; overflow: hidden">

    <mat-card style="font-size: .8em;" (click)="openTierlistenauswahlDialog()">
        <div class="one-row">
            <span *ngIf="!tierliste && tierlisten?.length === 0">Bitte eine Ausstallliste erstellen!</span>
            <span *ngIf="!tierliste && tierlisten?.length > 0">Bitte eine Ausstallliste ausw&auml;hlen oder
                erstellen!</span>
            <span *ngIf="tierliste" class="tierliste-card-header">Ausstallliste von
                {{ tierliste?.kunde_abgang }}{{
                tierliste?.kunde_zusatz_abgang ? (', ' + tierliste.kunde_zusatz_abgang) :
                ''
                }}</span>
            <span class="spacer"></span>
            <!--            <button mat-icon-button (click)="openTierlistenauswahlDialog()">-->
            <!--                <mat-icon>list</mat-icon>-->
            <!--            </button>-->
            <button *ngIf="tierliste || tierlisten?.length > 0" [matMenuTriggerFor]="menu"
                (click)="$event.stopPropagation()" aria-label="Ausstallliste erstellen oder editieren Menu"
                mat-icon-button>
                <mat-icon>more_vert</mat-icon>
            </button>
            <button *ngIf="!tierliste && tierlisten?.length === 0" mat-icon-button
                (click)="$event.stopPropagation(); neueTierlisteErstellen()">
                <mat-icon>add</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button (click)="neueTierlisteErstellen()" mat-menu-item>
                    <mat-icon>add</mat-icon>
                    <span>neue Ausstallliste</span><span *ngIf="!gruppe">&nbsp;(global)</span>
                </button>
                <button (click)="aktuelleTierlisteEditieren()" [disabled]="!tierliste" mat-menu-item>
                    <mat-icon>edit</mat-icon>
                    <span>Ausstallliste bearbeiten</span>
                </button>
                <button (click)="aktuelleTierlisteLoeschen()" [disabled]="!tierliste" mat-menu-item>
                    <mat-icon>delete</mat-icon>
                    <span>Ausstallliste l&ouml;schen</span>
                </button>
                <button (click)="aktuelleTierlisteWiederOeffnen()"
                    [disabled]="!tierliste || tierliste?.status_id == 161" mat-menu-item>
                    <mat-icon>lock_open</mat-icon>
                    <span>Ausstallliste wieder öffnen</span>
                </button>
                <button (click)="aktuelleTierlisteTeilen()" [disabled]="!tierliste || tierliste?.mehrere_gruppen == 0"
                    mat-menu-item>
                    <mat-icon>share</mat-icon>
                    <span>Zugriff freigeben</span>
                </button>
            </mat-menu>
        </div>
        <div class="one-row">
            <mat-icon>place</mat-icon>
            <span style="margin-left: 6px;">{{
                tierliste?.kunde_id_zielbetrieb ? tierliste?.kunde_zielbetrieb :
                tierliste?.kunde_einstallen
                }}{{
                tierliste?.kunde_id_zielbetrieb && tierliste?.kunde_zusatz_zielbetrieb ?
                (', ' + tierliste.kunde_zusatz_zielbetrieb) : (tierliste?.kunde_id_einstallen &&
                tierliste?.kunde_zusatz_einstallen ? tierliste.kunde_zusatz_einstallen : '')
                }}</span>
            <span class="spacer"></span>
        </div>
        <div class="one-row">
            <mat-icon>event</mat-icon>
            <span style="margin-left: 6px;">{{ tierliste?.datum_abgang | date: 'dd.MM.yyyy' }}</span>
            <span class="spacer"></span>
            <span style="margin-right: 16px;">Tiere: {{ tierliste?.anzahl_tiere }}</span>
        </div>
        <div *ngIf="tierliste?.kommentar" class="one-row">
            <mat-icon>info</mat-icon>
            <span style="margin-left: 6px;">{{ tierliste?.kommentar }}</span>
            <span class="spacer"></span>
        </div>
    </mat-card>

    <div class="one-row" style="margin-top: 6px">
        <span>Tiere in Liste:
            <strong>{{ tierliste?.anzahl_tiere }}</strong></span>
    </div>

    <div *ngIf="this.gruppe_id" class="one-row" style="margin-top: 12px; margin-bottom: 12px">
        <span class="spacer"></span>
        <button (click)="alleTiereZuAbgangslisteHinzufuegen()" [disabled]="
                        !tierliste ||
                        tierliste.status_id > 161 ||
                        tiere.length < 1 ||
                        isLoading
                    " color="accent" mat-flat-button>
            Alle {{ tiere?.length || 'x' }} Tiere zur Ausstallliste hinzufügen
        </button>
        <span class="spacer"></span>
    </div>
</div>

<mat-tab-group #tabgroup (selectedTabChange)="tabChanged($event)" headerPosition="above" mat-stretch-tabs
    style="height: calc(100% - 150px); width: 100%">

    <mat-tab *ngIf="tierliste && tierliste.status_id <= 161">
        <ng-template matTabLabel>
            <mat-icon>dialpad</mat-icon>
        </ng-template>

        <kiene-abgang-number-pad [abgangsliste]="tierliste"
            [isVisible]="currentTabIndex === 0"></kiene-abgang-number-pad>
    </mat-tab>

    <mat-tab *ngIf="tierliste && tierliste.status_id <= 161">
        <ng-template matTabLabel>
            <mat-icon>barcode_reader</mat-icon>
        </ng-template>

        <kiene-abgang-scannen [abgangsliste]="tierliste"></kiene-abgang-scannen>
    </mat-tab>

    <mat-tab [disabled]="!tierliste">
        <ng-template matTabLabel>
            <mat-icon>list</mat-icon>
        </ng-template>

        <kiene-abgang-liste #abgangListe [alsDialog]="alsDialog"></kiene-abgang-liste>
    </mat-tab>
</mat-tab-group>
<!--<div mat-dialog-actions align="end">-->
<!--    <button (click)="abgangslisteGeschlossen(tierliste)" color="accent" mat-flat-button>Schließen-->
<!--    </button>-->
<!--</div>-->