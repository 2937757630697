import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import SignaturePad from 'signature_pad';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';

@Component({
    selector: 'kiene-signature-lieferschein-panel',
    templateUrl: './lieferschein-signature-panel.component.html',
    styleUrls: ['./lieferschein-signature-panel.component.scss']
})
export class LieferscheinSignaturePanelComponent implements OnInit, AfterViewInit {
    @ViewChild('signatureCanvas', {static: false}) signatureCanvas: ElementRef;

    @Input('imgBase64') imgBase64: string;

    @Output('saved') saved = new EventEmitter<string>();


    signaturePad: SignaturePad;

    isMobileDevice = false;
    isPortraitModeQuery = window.matchMedia('(orientation: portrait)');
    isPortraitMode = true;
    base64Png: string;
    @Input('hinweisText') hinweisText = '';

    constructor(private breakpointObserver: BreakpointObserver) {
        this.isPortraitModeQuery.addEventListener('change', event => {
            this.isPortraitMode = this.isPortraitModeQuery.matches;
        });
    }

    ngOnInit(): void {
        this.isPortraitMode = this.isPortraitModeQuery.matches;
    }

    ngAfterViewInit() {
        this.signaturePad = new SignaturePad(this.signatureCanvas.nativeElement);
        this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe({
            next: (state: BreakpointState) => {
                if (state.matches) {
                    this.isMobileDevice = true;
                    this.resizeCanvas();
                } else {
                    this.isMobileDevice = false;
                    this.resizeCanvas();
                }
            }
        });
        setTimeout(() => {
            if (this.imgBase64) {
                const options = {
                    width: this.signatureCanvas?.nativeElement.offsetWidth,
                    height: this.signatureCanvas?.nativeElement.offsetHeight
                };
                this.signaturePad.fromDataURL(this.imgBase64, options);
            }
            this.resizeCanvas();
        }, 200);
    }

    public clear() {
        this.signaturePad?.clear();
        this.imgBase64 = undefined;
        this.signaturePad?.on();
        this.saved.emit("");
    }

    save() {
        this.base64Png = this.signaturePad.toDataURL('image/png');
        this.saved.emit(this.base64Png);
    }

    public resizeCanvas() {
        // const ratio = 1; //Math.max(window.devicePixelRatio || 1, 1);
        // this.signatureCanvas.nativeElement.width = this.signatureCanvas?.nativeElement.offsetWidth * ratio;
        // this.signatureCanvas.nativeElement.height = this.signatureCanvas?.nativeElement.offsetHeight * ratio;
        // this.signatureCanvas?.nativeElement?.getContext('2d')?.scale(ratio, ratio);
        // this.signaturePad?.clear();
    }
}
