<h1 mat-dialog-title>Rechnungsposition {{position.artikel}} l&ouml;schen</h1>
<div mat-dialog-content style="padding: 24px;">
    <p>Wollen Sie die Position wirklich l&ouml;schen?</p>
    <br>
    <mat-checkbox [formControl]="gutschriftCtrl">Gutschrift erstellen</mat-checkbox>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="delete()">L&ouml;schen</button>
</div>