import {HttpParams} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {AppService} from '../../app.service';
import {ArticleScanSelectedEvent} from '../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {TextInputDialogComponent} from '../../dialogs/text-input-dialog/text-input-dialog.component';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MessageService} from '../../services/message.service';
import {Charge} from '../charges/charges.component';
import {GoodsReceiptHistory} from '../goods-receipt-history/goods-receipt-history.component';
import {Material} from '../material/material';
import {AddVetStockDialogComponent} from '../stock/add-vet-stock-dialog/add-vet-stock-dialog.component';
import {Stock} from '../stock/stock';
import {StockService} from '../stock/stock.service';
import {StoragePlace} from '../storage-place/storage-place';
import {
    BestandElement,
    BestandElementCharge,
    TransferStockObject,
} from './bestand-element';

@Component({
    selector: 'kiene-bestand',
    templateUrl: './bestand.component.html',
    styleUrls: ['./bestand.component.css'],
})
export class BestandComponent implements OnInit {
    articleStock: BestandElementCharge[] = [];
    elemente: BestandElement[] = [];
    currentArticle: Material = new Material();
    totalAmount = 0;

    currentArticleScanEvent: ArticleScanSelectedEvent;

    myStockCtrl = new UntypedFormControl(false);
    onlyMyStock = false;
    tierarztId = 0;

    bestandHistorie: GoodsReceiptHistory[] = [];

    constructor(
        private api: KieneTableService,
        private appService: AppService,
        private router: Router,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private stockService: StockService,
        private notification: MessageService,
        public dialog: MatDialog
    ) {
        if (this.localStorageService.getCurrentUser().tierarzt_id) {
            this.tierarztId =
                this.localStorageService.getCurrentUser().tierarzt_id;
        }
    }

    ngOnInit(): void {
        this.myStockCtrl.valueChanges.subscribe((value) => {
            this.onlyMyStock = value;
            this.searchStock(this.currentArticleScanEvent);
        });
    }

    materialSelected(event: ArticleScanSelectedEvent) {
        this.currentArticleScanEvent = event;

        this.searchStock(event);
    }

    currentUserIsVet() {
        return this.localStorageService.getCurrentUser().tierarzt_id;
    }

    istAbgelaufen(c: BestandElementCharge) {
        const mhd = new Date(c.mhd);
        const now = new Date();
        if (mhd.getTime() < now.getTime()) {
            return true;
        }
        return false;
    }

    showMyVetStock() {
        this.router.navigate(['/home'], {
            queryParams: {tab: 'meinbestand'},
        });
    }

    reload() {
        this.searchStock(this.currentArticleScanEvent);
    }

    searchStock(event: ArticleScanSelectedEvent) {
        if (!event) {
            return;
        }
        this.currentArticle = event.article;
        this.totalAmount = 0;
        this.elemente = [];
        if (event.article) {
            this.appService.setDataLoading(true);

            let params = new HttpParams();

            params = params.append(
                'artikel_id',
                event.article.artikel_id.toString()
            );

            if (this.onlyMyStock) {
                params = params.append(
                    'tierarzt_id',
                    this.tierarztId.toString()
                );
            }

            this.api
                .get(environment.BASE_URI + 'bestand_charge/read.php', params)
                .subscribe(
                    (response) => {
                        this.articleStock = response.records;
                        this.createBestandElemente();
                        this.appService.setDataLoading(false);
                        this.getBestellHistorie();
                    },
                    (error) => {
                        this.appService.setDataLoading(false);
                    }
                );
        }
    }

    getBestellHistorie() {
        let params = new HttpParams();
        params = params.append(
            'artikel_id',
            this.currentArticle.artikel_id.toString()
        );

        this.api
            .get(environment.BASE_URI + 'wareneingang/read.php', params)
            .subscribe(
                (response) => {
                    this.bestandHistorie = response.records;
                },
                (error) => {
                }
            );
    }

    createBestandElemente() {
        let lagerMap = new Map<string, BestandElementCharge[]>();

        for (let as of this.articleStock) {
            let bec = as;
            if (lagerMap.has(as.lager)) {
                lagerMap.get(as.lager).push(bec);
            } else {
                let list: BestandElementCharge[] = [];
                list.push(bec);
                lagerMap.set(as.lager, list);
            }
        }

        let keys = lagerMap.keys();
        for (let key of keys) {
            let be = new BestandElement();
            let chargen = lagerMap.get(key);
            be.lager = key;
            be.lager_id = chargen[0].lager_id;
            be.menge = this.calculateTotalAmount(chargen);
            be.chargen = chargen;
            this.totalAmount += be.menge;
            this.elemente.push(be);
        }
    }

    calculateTotalAmount(chargen: BestandElementCharge[]) {
        let amount = 0;
        for (let c of chargen) {
            amount += c.menge;
        }

        return amount;
    }

    correctStock(elem: BestandElement) {
        let dialogRef = this.dialog.open(TextInputDialogComponent, {
            data: {
                title: 'Bestand korrigieren',
                text:
                    'Bitte geben Sie den neuen Ist-Bestand für den Artikel ' +
                    this.currentArticle.artikel_me +
                    ' an. Es werden die ältesten Chargen gelöscht.',
                textLabel: 'Neue Bestandsmenge',
                textButton: 'korrigieren',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let entity = {
                    lager_id: elem.lager_id,
                    artikel_id: this.currentArticle.artikel_id,
                    menge_soll: result,
                };
                this.api
                    .post(
                        environment.BASE_URI + 'bestand_charge/korrigieren.php',
                        entity,
                        null
                    )
                    .subscribe(
                        (response) => {
                            this.notification.infoMessage(
                                'Der Bestand wurde erfolgreich korrigiert!'
                            );
                            this.searchStock(this.currentArticleScanEvent);
                        },
                        (error) => {
                            this.notification.errorMessage(
                                'Der Bestand konnte nicht korrigiert werden!',
                                error
                            );
                        }
                    );
            }
        });
    }

    openAddVetStockDialog() {
        const dialogRef = this.dialog.open(AddVetStockDialogComponent, {
            minWidth: '300px',
            width: '33%',
        });

        dialogRef.afterClosed().subscribe((stock) => {
            if (stock) {
                this.api
                    .post(
                        environment.BASE_URI +
                        'bestand_charge/create_tierarzt.php',
                        stock,
                        null
                    )
                    .subscribe(
                        (response) => {
                            this.notification.infoMessage(
                                'Der Bestand wurde erfolgreich hinzugefügt!'
                            );
                            this.searchStock(this.currentArticleScanEvent);
                        },
                        (error) => {
                            this.notification.errorMessage(
                                'Der Bestand konnte nich hinzugefügt werden!',
                                error
                            );
                        }
                    );
            }
        });
    }

    openTransferStockDialog(row: BestandElementCharge) {
        const dialogRef = this.dialog.open(TransferStockDialog, {
            width: '800px',
            data: row,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const tso = result;
                console.debug('result: ' + JSON.stringify(tso));
                this.stockService.transferStock(tso).subscribe(
                    (result) => {
                        this.notification.infoMessage(
                            'Umbuchung von ' + tso.artikel + ' war erfolgreich!'
                        );
                        this.searchStock(this.currentArticleScanEvent);
                    },
                    (error) => {
                        this.notification.errorMessage(
                            'Umbuchung von ' +
                            tso.artikel +
                            ' ist fehlgeschlagen!',
                            error
                        );
                    }
                );
            }
        });
    }

    openBookLossDialog(row: BestandElementCharge) {
        const dialogRef = this.dialog.open(BookLossDialog, {
            width: '800px',
            data: row,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const tso = result;
                console.debug('result: ' + JSON.stringify(tso));
                this.stockService.transferStock(tso).subscribe(
                    (result) => {
                        const stock: Stock = <Stock>result;
                        this.notification.infoMessage(
                            'Verlustbuchung von ' +
                            stock.artikel +
                            ' war erfolgreich!'
                        );
                        this.searchStock(this.currentArticleScanEvent);
                    },
                    (error) => {
                        this.notification.errorMessage(
                            'Verlustbuchung ist fehlgeschlagen: ',
                            error
                        );
                    }
                );
            }
        });
    }

    openCorrectStockDialog(row: BestandElementCharge) {
        const dialogRef = this.dialog.open(CorrectStockDialog, {
            width: '800px',
            data: row,
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const tso = result;
                console.debug('result: ' + JSON.stringify(tso));
                this.stockService.updateStock(tso).subscribe(
                    (result) => {
                        const stock = <Stock>result;
                        this.notification.infoMessage(
                            'Bestandskorrektur von ' +
                            stock.artikel +
                            ' war erfolgreich!'
                        );
                        this.searchStock(this.currentArticleScanEvent);
                    },
                    (error) => {
                        this.notification.errorMessage(
                            'Bestandskorrektur ist fehlgeschlagen: ',
                            error
                        );
                    }
                );
            }
        });
    }
}

@Component({
    selector: 'transfer-stock-dialog',
    template: `<h3 mat-dialog-title>
            Umbuchung {{ data.artikel }} mit Charge {{ data.charge }} vom
            Lagerplatz {{ data.bezeichnung }}
        </h3>
        <div mat-dialog-content>
            <p>Bitte den Ziellagerplatz auswählen</p>
            <kiene-storage-place-autocomplete
                (selectedStoragePlace)="setTargetStoragePlace($event)"
            ></kiene-storage-place-autocomplete>
            <p>Bitte die Umbuchungsmenge eingeben</p>
            <mat-form-field>
                <input
                    matInput
                    name="target"
                    placeholder="Menge"
                    [(ngModel)]="targetStockObject.menge"
                />
            </mat-form-field>
        </div>
        <div mat-dialog-actions>
            <button mat-button color="accent" (click)="onCancelClick()">
                Abbrechen
            </button>
            <button
                mat-flat-button
                color="accent"
                [mat-dialog-close]="targetStockObject"
            >
                Umbuchen
            </button>
        </div>`,
})
export class TransferStockDialog {
    targetStockObject: TransferStockObject = new TransferStockObject();

    constructor(
        public dialogRef: MatDialogRef<TransferStockDialog>,
        @Inject(MAT_DIALOG_DATA) public data: BestandElementCharge
    ) {
        this.targetStockObject.lc_id = data.lc_id;
    }

    setTargetStoragePlace(storagePlace: StoragePlace) {
        this.targetStockObject.lagerplatz_id_ziel = storagePlace.lagerplatz_id;
    }

    onCancelClick() {
        this.dialogRef.close();
    }
}

@Component({
    selector: 'book-loss-dialog',
    template: `<h3 mat-dialog-title>
            Verlustbuchung von
            <span class="kiene-color" style="font-style: italic">{{
                data.artikel
            }}</span>
            mit Charge {{ data.charge }} vom Lagerplatz {{ data.bezeichnung }}
        </h3>
        <div mat-dialog-content>
            <p>Bitte die Verlustmenge eingeben</p>
            <mat-form-field>
                <input
                    matInput
                    name="target"
                    placeholder="Verlustmenge"
                    [(ngModel)]="targetStockObject.menge"
                />
            </mat-form-field>
        </div>
        <div mat-dialog-actions>
            <button mat-button color="primary" (click)="onCancelClick()">
                Abbrechen
            </button>
            <button
                mat-button
                color="primary"
                [mat-dialog-close]="targetStockObject"
            >
                Verlust buchen
            </button>
        </div>`,
})
export class BookLossDialog {
    targetStockObject: TransferStockObject = new TransferStockObject();

    constructor(
        public dialogRef: MatDialogRef<TransferStockDialog>,
        @Inject(MAT_DIALOG_DATA) public data: BestandElementCharge
    ) {
        this.targetStockObject.lc_id = data.lc_id;
        this.targetStockObject.lager_id_ziel = -3;
    }

    onCancelClick() {
        this.dialogRef.close();
    }
}

@Component({
    selector: 'correct-stock-dialog',
    template: `<h3 mat-dialog-title>
            Korrektur von
            <span class="kiene-color" style="font-style: italic">{{
                data.artikel
            }}</span>
            mit Charge {{ data.charge }} vom Lagerplatz {{ data.bezeichnung }}
        </h3>
        <div mat-dialog-content>
            <p>Bitte die neue Menge eingeben</p>
            <mat-form-field>
                <input
                    matInput
                    name="target"
                    placeholder="Neue Menge"
                    [(ngModel)]="targetStockObject.menge"
                />
            </mat-form-field>
        </div>
        <div mat-dialog-actions>
            <button mat-button color="primary" (click)="onCancelClick()">
                Abbrechen
            </button>
            <button
                mat-flat-button
                color="primary"
                [mat-dialog-close]="targetStockObject"
            >
                Korrektur buchen
            </button>
        </div>`,
})
export class CorrectStockDialog {
    targetStockObject: TransferStockObject = new TransferStockObject();

    constructor(
        public dialogRef: MatDialogRef<TransferStockDialog>,
        @Inject(MAT_DIALOG_DATA) public data: BestandElementCharge
    ) {
        this.targetStockObject.lc_id = data.lc_id;
        this.targetStockObject.lager_id_ziel = -4;
    }

    onCancelClick() {
        this.dialogRef.close();
    }
}
