import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WarengruppeApi } from './warengruppe';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WarengruppeService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<WarengruppeApi> {
    return this.http.get<WarengruppeApi>(environment.BASE_URI + 'warengruppe/read.php');
  }
}
