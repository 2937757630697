import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { DateTableColumn, TableColumn } from '../../components/kiene-table/table-column';
import { TableDescriptor } from '../../components/kiene-table/table-descriptor';
import { TableOptionEvent } from '../../components/kiene-table/table-option';
import { MessageService } from '../../services/message.service';
import { CreateChargeDialogComponent } from './create-charge-dialog/create-charge-dialog.component';

@Component({
  selector: 'kiene-charges',
  templateUrl: './charges.component.html',
  styleUrls: ['./charges.component.scss']
})
export class ChargesComponent implements OnInit {

  tableDescriptor: TableDescriptor = new TableDescriptor();

  constructor(private dialog: MatDialog,
    private api: KieneTableService,
    private messageService: MessageService) { }

  ngOnInit() {

    this.tableDescriptor.headline = 'Chargen';
    this.tableDescriptor.uniqueIdentifier = 'Chargen';
    this.tableDescriptor.apiUrl = environment.BASE_URI + 'charge/';
    this.tableDescriptor.createAllowed = true;
    this.tableDescriptor.standardCreate = false;

    this.tableDescriptor.columns.push(new TableColumn('charge', 'Charge'));
    this.tableDescriptor.columns.push(new TableColumn('artikel', 'Artikel'));
    this.tableDescriptor.columns.push(new TableColumn('mhd', 'MHD'));
    this.tableDescriptor.columns.push(new DateTableColumn('erstellt', 'Erstellt'));

    this.tableDescriptor.initialSortColumn = 'erstellt';
    this.tableDescriptor.initialSortDirection = 'desc';

    this.tableDescriptor.permissionCreate = 1042;
    this.tableDescriptor.permissionUpdate = 1042;

  }

  catchOptionEvent(event: TableOptionEvent) {
    if (event.name === 'add') {
      this.openCreateDialog();
    }
  }

  openCreateDialog() {
    const dialogRef = this.dialog.open(CreateChargeDialogComponent);

    dialogRef.afterClosed().subscribe({
      next: charge => {
        if (charge) {
          this.api.post(environment.BASE_URI + 'charge/create.php', charge).subscribe({
            next: response => {
              this.api.reload();
            },
            error: err => {
              this.messageService.errorMessage('Die Charge konnte nicht angelegt werden!', err);
            }
          });
        }
      }
    });
  }

}

export class Charge {
  benutzerid: number;
  charge: string;
  charge_id: number;
  artikel: string;
  artikel_id: number;
  mhd: string;
  apotheke: string;
  lager: string;
  wagen: string;
  summe: string;
  erstellt: Date;
  chargenmenge: number;
}

export interface ChargeApi {
  records: Charge[];
  count: number;
}
