<kiene-kiene-table (optionClicked)="catchOptionEvent($event)" [tableDescriptor]="tableDescriptor">
    <div toolbarElementTop>
        <div class="one-row">
            <kiene-article-scan-autocomplete #artikelAutocomplete (cleared)="artikelCleared($event)"
                (selectedMaterial)="artikelSelected($event)" [disabled]="!inventur?.aktiv" [focus]="true"
                [placeholder]="'Artikel * scannen oder suchen'" [required]="true" [showClearButton]="true"
                style="width: 55%;">
            </kiene-article-scan-autocomplete>
            <span class="spacer"></span>
            <kiene-autocomplete #chargeAutocomplete (elementSelected)="chargeSelected($event)" [apiUrl]="'charge/'"
                [disabled]="chargeDisabled" [displayFields]="['charge', 'mhd']" [emitFreeText]="true"
                [kieneObject]="selectedCharge" [params]="chargeParams" [placeholder]="'Charge / MHD'"
                [separator]="' / '">
            </kiene-autocomplete>
            <span class="spacer"></span>
            <mat-form-field>
                <mat-label>MHD</mat-label>
                <input #mhdInput="matInput" [formControl]="mhdCtrl" [matDatepicker]="mhdPicker" matInput>
                <mat-datepicker-toggle [for]="mhdPicker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #mhdPicker></mat-datepicker>
            </mat-form-field>
            <span class="spacer"></span>
            <mat-form-field style=" width: 10%; min-width: 100px;">
                <mat-label>Menge</mat-label>
                <input #mengeInput="matInput" (blur)="skipOrSave()" [formControl]="mengeCtrl" aria-label="Menge"
                    matInput required type="number">
                <mat-error *ngIf="this.mengeCtrl.invalid">Menge >= 0</mat-error>
            </mat-form-field>
            <span class="spacer"></span>
            <kiene-autocomplete #lagerplatzAutocomplete (elementSelected)="setCurrentLagerplatz($event)"
                [apiUrl]="'lagerplatz/'" [disabled]="!inventur?.aktiv || disabledTierarzt" [focus]="false"
                [kieneObject]="currentLagerplatz" [placeholder]="this.lagerplatzPlaceholder" [required]="true"
                [showClear]="false" style="width: 30%">
            </kiene-autocomplete>
            <span class="spacer"></span>
            <button (click)="saveNewInventurEintrag()" *kienePermission="[4432]"
                [disabled]="!inventur?.aktiv || mengeCtrl.invalid" color="primary" mat-button>
                <mat-icon>save</mat-icon>
            </button>
        </div>
    </div>
</kiene-kiene-table>