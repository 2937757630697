import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { StorageService } from '../../storage/storage.service';
import { StoragePlace } from '../storage-place';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Storage } from '../../storage/storage';

@Component({
  selector: 'kiene-add-storage-place-dialog',
  templateUrl: './add-storage-place-dialog.component.html',
  styleUrls: ['./add-storage-place-dialog.component.scss']
})
export class AddStoragePlaceDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddStoragePlaceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storageService: StorageService) { }

  nameCtrl = new UntypedFormControl('', [Validators.required]);
  storageCtrl = new UntypedFormControl('', [Validators.required]);
  storages: Storage[] = [];

  ngOnInit() {
    this.loadStorages();
  }

  loadStorages() {
    this.storageService.getAll().subscribe(storages => {
      this.storages = storages.records;
    });
  }

  create() {
    const storagePlace = new StoragePlace();
    storagePlace.bezeichnung = this.nameCtrl.value;
    storagePlace.lager_id = this.storageCtrl.value.lager_id;
    this.dialogRef.close(storagePlace);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
