<div style="width: 100%; margin-bottom: 12px;">

  <mat-card>
    <div class="one-row">
      <h5>Lieferanten und Einkaufspreise</h5>
      <span class="spacer"></span>
      <button mat-icon-button color="accent" (click)="openDetailsDialog()" [disabled]="disabled"><mat-icon>add</mat-icon></button>
    </div>

    <table class="phito-table" [style.color]="disabled ? 'lightgray' : ''">
      <thead>
        <tr>
          <th>Pos.</th>
          <th>Lieferant</th>
          <th>Preis</th>
          <th>Rabatt (%)</th>
          <th>Rabatt. Preis</th>
          <th>Skonto (%)</th>
          <th>Zahlungsziel (Tage)</th>
          <th>Standardlieferant</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sm of supplierMaterials; let i = index">
          <td>{{(i + 1)}}</td>
          <td>{{sm.lieferant}}</td>
          <td>{{sm.einzelpreis | number: '1.2-2'}} &euro;</td>
          <td>{{sm.rabatt}} &#37;</td>
          <td>{{gibRabattiertenPreis(sm) | number: '1.2-2'}} &euro;</td>
          <td>{{sm.skonto}} &#37;</td>
          <td>{{sm.zahlungsziel}}</td>
          <td><mat-icon color="accent">{{sm.standard_lieferant ? 'check_box' : 'check_box_outline_blank'}}</mat-icon></td>
          <td>
            <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="disabled">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="openDetailsDialog(sm)">
                <mat-icon>edit</mat-icon>
                <span>Bearbeiten</span>
              </button>
              <button mat-menu-item (click)="openDeleteDialog(sm)">
                <mat-icon>delete</mat-icon>
                <span>L&ouml;schen</span>
              </button>
            </mat-menu>
          </td>
        </tr>
      </tbody>
    </table>

  </mat-card>

</div>