import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Labor } from '../labor';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kiene-labor-dialog',
  templateUrl: './labor-dialog.component.html',
  styleUrls: ['./labor-dialog.component.scss']
})
export class LaborDialogComponent implements OnInit {

  laborCtrl = new UntypedFormControl('', [Validators.minLength(1)]);
  strasseCtrl = new UntypedFormControl('', [Validators.minLength(1)]);
  plzCtrl = new UntypedFormControl('', [Validators.minLength(1)]);
  ortCtrl = new UntypedFormControl('', [Validators.minLength(1)]);

  labor: Labor;

  edit = true;

  constructor(public dialogRef: MatDialogRef<LaborDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if(data){
        this.labor = data.labor;
        this.laborCtrl.setValue(this.labor.bezeichnung);
        this.strasseCtrl.setValue(this.labor.strasse);
        this.plzCtrl.setValue(this.labor.plz);
        this.ortCtrl.setValue(this.labor.ort);
      }
    }

  ngOnInit() {
    if(!this.labor){
      this.edit = false;
      this.labor = new Labor();
    }
  }

  public cancel(){
    this.dialogRef.close();
  }

  public save(){
    this.labor.bezeichnung = this.laborCtrl.value;
    this.labor.strasse = this.strasseCtrl.value;
    this.labor.plz = this.plzCtrl.value;
    this.labor.ort = this.ortCtrl.value;
    this.dialogRef.close(this.labor);
  }

}
