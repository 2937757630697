import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { LocalStorageService } from '../services/local-storage/local-storage.service';

@Directive({
  selector: '[kienePermission]',
})
export class KienePermissionDirective {

    constructor(private el: ElementRef, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef,
                private localStorageService: LocalStorageService) {

    }

    @Input('kienePermission')
    set permissionIds(ids: number[]) {
        if (!Array.isArray(ids) || ids.length === 0) {
            this.viewContainer.clear();
        }
        if (this.localStorageService.currentUserHasPermissions(ids)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

}
