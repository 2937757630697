<div style="padding: 24px;">
    <div class="one-row">
        <h1 color="primary" style="margin-right: 24px;">Bestand</h1>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <kiene-article-scan-autocomplete [focus]="true" (selectedMaterial)="materialSelected($event)"
                                         style="width: 33%; min-width: 400px;"
                                         [placeholder]="'Bitte Artikel eingeben oder scannen'">
        </kiene-article-scan-autocomplete>
        <span class="spacer"></span>
        <button *ngIf="currentUserIsVet()" color="accent" matTooltip="Mein Tierarztwagen Bestand" mat-icon-button
                (click)="showMyVetStock()">
            <mat-icon>airport_shuttle</mat-icon>
        </button>
        <button mat-icon-button color="accent" (click)="reload()">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
    <div>
        <div class="one-row">
            <h2>{{currentArticle.artikel_me}}</h2>
            <span class="spacer"></span>
            <h4 *ngIf="currentArticle.artikel_me">Menge gesamt: {{totalAmount | number: '1.0-2'}} Stk.</h4>
        </div>
        <ul style="list-style-type: none;">
            <li *ngFor="let elem of elemente">
                <mat-card style="margin-bottom: 24px;">
                    <div class="one-row">
                        <div class="one-row">
                            <h4 style="margin-right: 12px;">{{elem.lager}}</h4>
                            <div *kienePermission="[1139]">
                                <button *ngIf="elem.lager_id === 1 || elem.lager_id === 2" (click)="correctStock(elem)"
                                        matTooltip="Gesamtbestand korrigieren" mat-icon-button color="accent">
                                    <mat-icon>undo</mat-icon>
                                </button>
                            </div>
                            <span class="spacer"></span>
                        </div>
                        <span
                            style="min-width: 300px; text-align: right;">Menge gesamt: {{elem.menge | number: '1.0-2'}}
                            Stk.</span>
                    </div>
                    <table class="phito-table" style="width: fit-content; min-width: 800px;">
                        <thead>
                        <tr>
                            <th></th>
                            <th style="font-size: .8em; font-weight: bold;">Lagerplatz</th>
                            <th style="font-size: .8em; font-weight: bold;">Charge</th>
                            <th style="font-size: .8em; font-weight: bold;">Menge</th>
                            <th style="font-size: .8em; font-weight: bold;">MHD</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let c of elem.chargen" [ngClass]="istAbgelaufen(c) ? 'warning' : ''">
                            <td></td>
                            <td>{{c.bezeichnung}}</td>
                            <td>{{c.charge}}</td>
                            <td>{{c.menge}} Stk.</td>
                            <td>
                                <div class="one-row">
                                    <mat-icon *ngIf="istAbgelaufen(c)" matTooltip="Charge ist abgelaufen!"
                                              color="warn">warning
                                    </mat-icon>
                                    <span>{{c.mhd
                                        | date:
                                        'dd.MM.yyyy'}}</span>
                                    <span class="spacer"></span>
                                </div>
                            </td>
                            <td>
                                <mat-menu #stockOptionsMenu>
                                    <ng-template matMenuContent>
                                        <button *kienePermission="[1135]" mat-menu-item
                                                (click)="openTransferStockDialog(c)">
                                            <mat-icon>forward</mat-icon>
                                            <span>Bestand umbuchen</span>
                                        </button>
                                        <button *kienePermission="[1135]" mat-menu-item
                                                (click)="openBookLossDialog(c)">
                                            <mat-icon>delete_sweep</mat-icon>
                                            <span>Verlust buchen</span>
                                        </button>
                                        <button *kienePermission="[1139]" mat-menu-item
                                                (click)="openCorrectStockDialog(c)">
                                            <mat-icon>undo</mat-icon>
                                            <span>Bestand korrigieren</span>
                                        </button>
                                    </ng-template>
                                </mat-menu>
                                <button *kienePermission="[1135]" mat-icon-button
                                        [matMenuTriggerFor]="stockOptionsMenu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </mat-card>
            </li>
        </ul>
    </div>
</div>
