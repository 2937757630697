export class Preisgruppe{
    preisgruppe_id: number;
    bezeichnung: string;
}

export class PreisgruppeArtikel{
    pa_id: number;
    preisgruppe_id: number;
    preisgruppe: string;
    artikel_id: number;
    artikel: string;
    verkaufspreis_id: number;
    verkaufspreis: string;
    einzelpreis: number;
}