<div class="kiene-content-page">
  <div *ngIf="isLoading" class="kiene-loading-spinner">
    <mat-progress-spinner *ngIf="isLoading"></mat-progress-spinner>
  </div>
  <div class="kiene-table-header">
    <div>

      <h2 style="display: inline-block; margin-left: 20px;">Warenkorb <span *ngIf="warenkorbPositions.length > 0">(
          {{warenkorbPositions.length}} Artikel )</span></h2>
    </div>
  </div>
  <div>
    <div style="width: 95%;">

      <mat-card style="width: 100%;margin-bottom: 20px; min-height: 120px;">
          <h4>Position hinzufügen</h4>
          <div style="width:100%;" class="one-row">
  
            <form (ngSubmit)="addPosition()" style="width: 100%;">
  
              <div class="one-row">

                <div style="min-width: 500px; margin-right: 12px;">
                
                  <kiene-article-scan-autocomplete #articleAutocomplete [placeholder]="'Artikel auswählen'" (selectedMaterial)="materialSelected($event)"></kiene-article-scan-autocomplete>
                </div>
    
                <mat-form-field style="min-width: 150px; margin-right: 2%;">
                  <input type="number" #amountInput matInput [formControl]="positionAmountCtrl" placeholder="Menge">
                  <span matSuffix>Stk.</span>
                  <mat-error *ngIf="positionAmountCtrl.invalid">Menge muss > 0 sein!</mat-error>
                </mat-form-field>

                <mat-form-field style="min-width: 500px; margin-right: 12px;">
                  <mat-label>Kommentar</mat-label>
                  <input matInput type="text" [formControl]="kommentarCtrl">
                </mat-form-field>
    
                <button [disabled]="!positionAmountCtrl.valid || !materialCtrl.valid" mat-flat-button color="accent">Hinzuf&uuml;gen</button>

                <span class="spacer"></span>
    
              </div>

            </form>
  
          </div>
        </mat-card>

        <div *ngIf="warenkorbPositions.length == 0 && isLoading == false">
            Zur Zeit befinden sich keine Artikel im Warenkorb.
        </div>

      <mat-card *ngFor="let w of warenkorbPositions" style="width: 100%;margin-bottom: 20px; min-height: 120px;">
        <div class="kiene-table-header">
          <div style="display:inline-block;">
            <h3 style="width: 100%;">{{w.artikel}} ({{w.einheit}})</h3>
            <div style="font-size: 14px; color: #999;">
              <kiene-supplier-material-selector [materialId]="w.artikel_id" [supplierId]="w.lieferant_id" (change)="updateLieferant(w, $event)"></kiene-supplier-material-selector><br>
              <mat-form-field style="width: 500px; color: black;">
                <mat-label>Kommentar</mat-label>
                <input matInput type="text" [formControl]="positionKommentarCtrl[w.warenkorb_id]">
              </mat-form-field><br>
              <span>zuletzt editiert: </span><span style="font-weight: bold; color: #555;">{{w.editiert | date:
                'medium'}}</span><span> von </span><span style="font-weight: bold; color: #555;">{{w.vorname}}
                {{w.nachname}}</span><br>
              <button mat-button (click)="removeWarenkorbPosition(w)" color="primary">Position entfernen</button>
            </div>
          </div>
          <div style="width: 100px;">
            <mat-form-field style="width:80px;">
              <input matInput type="number" placeholder="Menge" [formControl]="formCtrl[w.warenkorb_id]">
              <span style="color: #AAA;" matSuffix>x</span>
            </mat-form-field>
            <button *ngIf="!inputDirtyArray[w.warenkorb_id]" disabled="true" mat-button style="width: 100%; height: 36px;"></button>
            <button *ngIf="inputDirtyArray[w.warenkorb_id]" mat-raised-button color="primary" (click)="updateWarenkorbPosition(w)">aktualisieren</button>
          </div>
        </div>

      </mat-card>

      <button *ngIf="warenkorbPositions.length > 0" mat-raised-button color="primary" (click)="openReleaseWarenkorbConfirmDialog()">Zur Bestellung freigeben</button>
    </div>


  </div>
</div>