<h3 mat-dialog-title>Tierarztgruppe anlegen</h3>
<mat-dialog-content>
    <mat-form-field style="width: 100%;">
        <input matInput type="text" placeholder="Name" [(ngModel)]="vetGroup.bezeichnung">
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="primary" (click)="onCancelClick()">Abbrechen</button>
    <button mat-raised-button color="primary" (click)="closeDialog()">Anlegen</button>
</mat-dialog-actions>
