import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kiene-invoice-deliver-note-dialog',
  templateUrl: './invoice-deliver-note-dialog.component.html',
  styleUrls: ['./invoice-deliver-note-dialog.component.scss']
})
export class InvoiceDeliverNoteDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<InvoiceDeliverNoteDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: any) { }

  ngOnInit() {
  }

  target(target: string){
    this.dialogRef.close(target);
  }

}
