export class Tierart {
    tierart_id: number;
    tierart: string;
    kuerzel: string;
    bezeichnung: string;
    mnp_rind: number;
    ueber_8_monate: number;
    mnp_schwein: number;
    ueber_30_kg: number;
    hit: number;
    qs: number;
    wartezeit_eier: number;
    wartezeit_fleisch: number;
    wartezeit_milch: number;
    kommentar: string;
}

export class TierartOutput {
    tierart: Tierart;
    confirm: boolean = false;
}
