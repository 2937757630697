export class Artikelliste {
    artikelliste_id: number;
    listenname: string;
    artikel: ArtikellisteArtikel[] = [];
}

export class ArtikellisteArtikel {
    aa_id: number;
    abgabe: number;
    abgabeundbehandlung: number;
    anwendung: string;
    anwendung_id: number;
    anwendung_kommentar: string;
    anwendungsdauer: number;
    anwendungsdauer_fix: number;
    artikel: string;
    artikel_id: number;
    artikel_me: string;
    artikelliste_id: number;
    behandlung: number;
    chargeJN: number;
    diagnose: string;
    dosierung: number;
    dosierung_fix: number;
    dosierung_tierart: number;
    dosierung_txt: string;
    editiert_von: number;
    erstellt_von: number;
    impfung: number;
    indikation_id: number;
    koerpergewicht_tierart: number;
    mengenartikel: number;
    recposition: number;
    sonderfall: number;
    stueckliste: number;
    tierart: string;
    tierart_id: number;
    verpackungsmenge: number;
    wartezeit: number;
    wartezeit_eier: number;
    wartezeit_milch: number;
}

export class ArtikellisteAuswahlDialogResult {
    confirm = false;
    artikelliste: Artikelliste;
}
