<h1 mat-dialog-title>Rechnungen per E-Mail versenden</h1>
<div mat-dialog-content>
    <p>Alle Rechnungen mit Rechnungsdatum in dem unten ausgew&auml;hlten Zeitraum werden per E-Mail versand.</p>
    <div class="one-row">
        <mat-form-field>
            <mat-label>Rechnungsdatum von</mat-label>
            <input matInput [matDatepicker]="pickerFrom" [formControl]="vonCtrl" required>
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Rechnungsdatum bis</mat-label>
            <input matInput [matDatepicker]="pickerTo" [formControl]="bisCtrl" required>
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
    </div>
    <p>In dem ausgew&auml;hlten Zeitraum sind <span class="anzahl-rechnungen">{{anzahlBetroffenerRechnungen}}</span>
        Rechnungen vorhanden, die noch nicht per E-Mail versand wurden.</p>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()"
        [disabled]="!vonCtrl.valid || !bisCtrl.valid">Versenden</button>
</div>