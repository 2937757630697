<div style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    ">
    <div class="settings-vvvo-hit-page">
        <kiene-page-header [ueberschrift]="'Betrieb hinzufügen'"></kiene-page-header>

        <div style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            ">
            <h3>1. HIT Konto verknüpfen</h3>
        </div>

        <hit-anmeldung [navigateToAfterLogin]="'../details'"></hit-anmeldung>
    </div>
</div>