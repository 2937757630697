import {Component, Inject, OnInit} from '@angular/core';
import {GruppeTier} from '../classes/gruppe-tier';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'kiene-gruppe-tier-reklamieren-dialog',
  templateUrl: './gruppe-tier-reklamieren-dialog.component.html',
  styleUrls: ['./gruppe-tier-reklamieren-dialog.component.scss']
})
export class GruppeTierReklamierenDialogComponent implements OnInit {

    tier: GruppeTier;
    constructor(
        public dialogRef: MatDialogRef<GruppeTierReklamierenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.tier = data.tier;
    }

  ngOnInit(): void {
  }

}
