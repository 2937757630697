<mat-form-field *ngIf="!hideOnEmptyList || this.reklamationenFuerGruppe.length > 0"
    class="abgang-form-input no-padding-margin-mat-form-field" style="margin: 6px 0 0 0; width: 100%">
    <mat-icon style="padding-right: 6px" matPrefix>search</mat-icon>
    <mat-label>{{label}}</mat-label>
    <mat-select multiple [compareWith]="compareReklamation" [formControl]="selectedReklamationCtrl">
        <mat-option *ngFor="let reklamation of reklamationenFuerGruppe"
            [value]="reklamation">{{reklamation.reklamation}} ({{reklamation.anzahl}} Tiere)</mat-option>
    </mat-select>
    <button *ngIf="selectedReklamationCtrl.value && reklamationenFuerGruppe.length > 0" matSuffix mat-icon-button
        aria-label="Clear" (click)="resetReklamationCtrl($event)">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>