<div style="padding: 0 24px 64px 24px">
    <kiene-page-header [ueberschrift]="'Tierreklamation bearbeiten'"
                       [routeRelativeAlternative]="'../../'"></kiene-page-header>

    <div style="padding-top: 24px; display: flex; flex-direction: column; align-items: center; justify-content: center">
        <mat-form-field style="width: 100%">
            <mat-label>Tierreklamation</mat-label>
            <input matInput [formControl]="tierreklamationTextCtrl" name="text" required>
            <mat-error *ngIf="tierreklamationTextCtrl.hasError('required')">
                Es muss eine Reklamation angegeben werden.
            </mat-error>
        </mat-form-field>

        <button [disabled]="
        tierreklamationTextCtrl.value === null
        || tierreklamationTextCtrl.value === ''
        || isSaving"
                (click)="updateTierreklamation()" style="width: 80%; margin-top: 12px" mat-flat-button color="accent">
            Aktualisieren
        </button>
    </div>

</div>
