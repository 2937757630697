import {NgModule} from '@angular/core';

import {DocumentsRoutingModule} from './documents-routing.module';
import {AbgabebelegComponent} from './abgabebeleg/abgabebeleg.component';
import {AbgabebelegDetailsComponent} from './abgabebeleg/abgabebeleg-details/abgabebeleg-details.component';
import {
    CheckBehandlungAbgabeDialogComponent
} from './abgabebeleg/check-behandlung-abgabe-dialog/check-behandlung-abgabe-dialog.component';
import {AbgabebelegService} from './abgabebeleg/abgabebeleg.service';
import {BehandlungsanfrageComponent} from './behandlungsanfrage/behandlungsanfrage.component';
import {BehandlungsanfrageService} from './behandlungsanfrage/behandlungsanfrage.service';
import {
    BehandlungsanfrageDetailComponent
} from './behandlungsanfrage/behandlungsanfrage-detail/behandlungsanfrage-detail.component';
import {
    BehandlungsanfrageDetailService
} from './behandlungsanfrage/behandlungsanfrage-detail/behandlungsanfrage-detail.service';
import {BestandsbesuchsprotokollComponent} from './bestandsbesuchsprotokoll/bestandsbesuchsprotokoll.component';
import {
    BestandsbesuchprotokollDetailsComponent
} from './bestandsbesuchsprotokoll/bestandsbesuchprotokoll-details/bestandsbesuchprotokoll-details.component';
import {DokumenteComponent} from './dokumente/dokumente.component';
import {AutoUploadDialogComponent} from './dokumente/auto-upload-dialog/auto-upload-dialog.component';
import {CreateDokumentDialogComponent} from './dokumente/create-dokument-dialog/create-dokument-dialog.component';
import {
    DokumentTypeSelectionDialogComponent
} from './dokumente/dokument-type-selection-dialog/dokument-type-selection-dialog.component';
import {DokumenteTabViewComponent} from './dokumente/dokumente-tab-view/dokumente-tab-view.component';
import {GenerateDocumentDialogComponent} from './dokumente/generate-document-dialog/generate-document-dialog.component';
import {LaborComponent} from './labor/labor.component';
import {MassnahmenplanComponent} from './massnahmenplan/massnahmenplan.component';
import {MassnahmenplanRindComponent} from './massnahmenplan/massnahmenplan-rind/massnahmenplan-rind.component';
import {
    MassnahmenplanRindDetailsComponent
} from './massnahmenplan/massnahmenplan-rind/massnahmenplan-rind-details/massnahmenplan-rind-details.component';
import {MassnahmenplanSchweinComponent} from './massnahmenplan/massnahmenplan-schwein/massnahmenplan-schwein.component';
import {
    MassnahmenplanSchweinDetailsComponent
} from './massnahmenplan/massnahmenplan-schwein/massnahmenplan-schwein-details/massnahmenplan-schwein-details.component';
import {MeldungenComponent} from './meldungen/meldungen.component';
import {DocumentsComponent} from './documents.component';
import {KieneTableModule} from '../components/kiene-table/kiene-table.module';
import {
    MeldungenGenerierenDialogComponent
} from './abgabebeleg/meldungen-generieren-dialog/meldungen-generieren-dialog.component';
import {
    CreateMeldungenGebuehrenDialogComponent
} from './meldungen/create-meldungen-gebuehren-dialog/create-meldungen-gebuehren-dialog.component';
import {GruppenverwaltungModule} from './gruppenverwaltung/gruppenverwaltung.module';
import {
    ChangeArtikelmengeDialogComponent
} from './abgabebeleg/change-artikelmenge-dialog/change-artikelmenge-dialog.component';
import {CsvUploadDialogComponent} from './massnahmenplan/csv-upload-dialog/csv-upload-dialog.component';
import {
    IdentifikationDialogComponent
} from './abgabebeleg/abgabebeleg-details/identifikation-dialog/identifikation-dialog.component';
import {
    GenerateNullmeldungDialogComponent
} from './meldungen/generate-nullmeldung-dialog/generate-nullmeldung-dialog.component';
import {BelegPositionModule} from './beleg-position/beleg-position.module';
import {ArticleScanAutocompleteModule} from '../components/article-scan-autocomplete/article-scan-autocomplete.module';
import {TierartSelectorModule} from '../components/tierart-selector/tierart-selector.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {
    DowloadAlleDokumenteDialogComponent
} from './dowload-alle-dokumente-dialog/dowload-alle-dokumente-dialog.component';
import {DeleteButtonModule} from '../components/delete-button/delete-button.module';
import {MeldungDetailsDialogComponent} from './meldungen/meldung-details-dialog/meldung-details-dialog.component';
import {TabinfoModule} from '../components/tabinfo/tabinfo.module';
import {
    MeldungKorrekturDialogComponent
} from './abgabebeleg/abgabebeleg-details/meldung-korrektur-dialog/meldung-korrektur-dialog.component';
import {AnwendungsSelectorModule} from '../components/anwendungs-selector/anwendungs-selector.module';
import {DiagnoseSelectorModule} from '../components/diagnose-selector/diagnose-selector.module';
import {
    AbgabebelegWarenkorbDialogComponent
} from './abgabebeleg/abgabebeleg-warenkorb-dialog/abgabebeleg-warenkorb-dialog.component';
import {LaboratoryModule} from '../laboratory/laboratory.module';
import {
    MassnahmenplanRindKurzDialogComponent
} from './massnahmenplan/massnahmenplan-rind/massnahmenplan-rind-kurz-dialog/massnahmenplan-rind-kurz-dialog.component';
import {OhrmarkeModule} from '../../../projects/kiene-core/src/lib/ohrmarke/ohrmarke.module';
import {
    TierwohlprotokollRindComponent
} from './tierwohlprotokoll/tierwohlprotokoll-rind/tierwohlprotokoll-rind.component';
import {
    TierwohlprotokollSchweinComponent
} from './tierwohlprotokoll/tierwohlprotokoll-schwein/tierwohlprotokoll-schwein.component';
import {
    BestandsbesuchsprotokollUebersichtComponent
} from './bestandsbesuchsprotokoll/bestandsbesuchsprotokoll-uebersicht/bestandsbesuchsprotokoll-uebersicht.component';
import {
    TierwohlprotokollRindDetailsComponent
} from './tierwohlprotokoll/tierwohlprotokoll-rind/tierwohlprotokoll-rind-details/tierwohlprotokoll-rind-details.component';
import {
    TierwohlprotokollSchweinDetailsComponent
} from './tierwohlprotokoll/tierwohlprotokoll-schwein/tierwohlprotokoll-schwein-details/tierwohlprotokoll-schwein-details.component';
import {TAMAusHITDialogComponent} from './meldungen/tamaus-hitdialog/tamaus-hitdialog.component';
import {DokumenteOverviewComponent} from './dokumente/dokumente-overview/dokumente-overview.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {
    UploadSonstigeDateiDialogComponent
} from './dokumente/upload-sonstige-datei-dialog/upload-sonstige-datei-dialog.component';
import {
    UnterschriftenModule
} from '../../../projects/kiene-core/src/lib/components/unterschriften/unterschriften.module';
import {CommonModule} from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatChipsModule} from '@angular/material/chips';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTreeModule} from '@angular/material/tree';
import {MatTableModule} from '@angular/material/table';
import {MatListModule} from '@angular/material/list';
import {KieneAutocompleteModule} from '../components/kiene-autocomplete/kiene-autocomplete.module';
import {MatStepperModule} from '@angular/material/stepper';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from '@angular/material/tooltip';
import {GruppenModule} from '../../../projects/kiene-core/src/lib/components/gruppen/gruppen.module';
import {DirectivesModule} from '../../../projects/kiene-core/src/lib/directives/directives.module';
import {KieneDirectivesModule} from '../directives/kiene-directives.module';
import {BelegeVorlageDialogComponent} from './abgabebeleg/belege-vorlage-dialog/belege-vorlage-dialog.component';
import {
    BelegVorlageDetailsDialogComponent
} from './abgabebeleg/belege-vorlage-dialog/beleg-vorlage-details-dialog/beleg-vorlage-details-dialog.component';
import {HotKeyListDialogComponent} from './abgabebeleg/hot-key-list-dialog/hot-key-list-dialog.component';
import {ComponentsModule} from '../components/components.module';
import {HotKeyDetailsDialogComponent} from './abgabebeleg/hot-key-details-dialog/hot-key-details-dialog.component';
import {
    PositionDetailsDialogComponent
} from './abgabebeleg/abgabebeleg-details/position-details-dialog/position-details-dialog.component';
import {KeyValueListModule} from '../../../projects/kiene-core/src/lib/components/key-value-list/key-value-list.module';


@NgModule({
    declarations: [
        DocumentsComponent,
        AbgabebelegComponent,
        AbgabebelegDetailsComponent,
        CheckBehandlungAbgabeDialogComponent,
        BehandlungsanfrageComponent,
        BehandlungsanfrageDetailComponent,
        BestandsbesuchsprotokollComponent,
        BestandsbesuchprotokollDetailsComponent,
        DokumenteComponent,
        AutoUploadDialogComponent,
        CreateDokumentDialogComponent,
        DokumentTypeSelectionDialogComponent,
        DokumenteTabViewComponent,
        GenerateDocumentDialogComponent,
        LaborComponent,
        MassnahmenplanComponent,
        MassnahmenplanRindComponent,
        MassnahmenplanRindDetailsComponent,
        MassnahmenplanSchweinComponent,
        MassnahmenplanSchweinDetailsComponent,
        MeldungenComponent,
        MeldungenGenerierenDialogComponent,
        CreateMeldungenGebuehrenDialogComponent,
        ChangeArtikelmengeDialogComponent,
        CsvUploadDialogComponent,
        IdentifikationDialogComponent,
        GenerateNullmeldungDialogComponent,
        DowloadAlleDokumenteDialogComponent,
        MeldungDetailsDialogComponent,
        MeldungKorrekturDialogComponent,
        AbgabebelegWarenkorbDialogComponent,
        MassnahmenplanRindKurzDialogComponent,
        TierwohlprotokollRindComponent,
        TierwohlprotokollSchweinComponent,
        BestandsbesuchsprotokollUebersichtComponent,
        TierwohlprotokollRindDetailsComponent,
        TierwohlprotokollSchweinDetailsComponent,
        TAMAusHITDialogComponent,
        DokumenteOverviewComponent,
        UploadSonstigeDateiDialogComponent,
        BelegeVorlageDialogComponent,
        BelegVorlageDetailsDialogComponent,
        HotKeyListDialogComponent,
        HotKeyDetailsDialogComponent,
        PositionDetailsDialogComponent,

    ],
    imports: [
        CommonModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatCardModule,
        MatMenuModule,
        MatChipsModule,
        MatToolbarModule,
        MatExpansionModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatPaginatorModule,
        MatRadioModule,
        MatDividerModule,
        MatBadgeModule,
        MatTreeModule,
        MatTableModule,
        MatListModule,
        KieneAutocompleteModule,
        KieneTableModule,
        DocumentsRoutingModule,
        UnterschriftenModule,
        GruppenverwaltungModule,
        BelegPositionModule,
        ArticleScanAutocompleteModule,
        TierartSelectorModule,
        AnwendungsSelectorModule,
        DiagnoseSelectorModule,
        DragDropModule,
        DeleteButtonModule,
        TabinfoModule,
        LaboratoryModule,
        OhrmarkeModule,
        MatSidenavModule,
        MatStepperModule,
        MatAutocompleteModule,
        MatTooltipModule,
        GruppenModule,
        DirectivesModule,
        KieneDirectivesModule,
        ComponentsModule,
        KeyValueListModule
    ],
    providers: [
        AbgabebelegService,
        BehandlungsanfrageService,
        BehandlungsanfrageDetailService,
    ]
})
export class DocumentsModule {
}
