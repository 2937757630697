import {Inject, Injectable} from '@angular/core';
import {KieneApi, KieneBackendApiService,} from '../services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../kiene-core.config';
import {HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Bestandsbuch} from './classes/bestandsbuch';
import {BestandsbuchArtikel} from './classes/bestandsbuch-artikel';
import {LocalStorageService} from '../services/local-storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class BestandsbuchService {
    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        private api: KieneBackendApiService,
        private lss: LocalStorageService
    ) {
    }

    getBestandsbuecher(offset: number, limit: number, kunde_id?: number): Observable<KieneApi> {
        let params = new HttpParams().append(
            'orderby',
            'status_sortierung asc'
        );
        params = params.set('offset', offset);
        params = params.set('limit', limit);
        if (kunde_id) {
            params = params.append('kunde_id', kunde_id);
        }
        return this.api.getAll(
            `${this.apiBaseUrl}bestandsbuch/read_kundenportal.php`,
            params
        );
    }

    getBestandsbuch(bestandsbuch_id: number) {
        const params = new HttpParams().set(
            'bestandsbuch_id',
            bestandsbuch_id.toString()
        );
        return this.api.get(
            `${this.apiBaseUrl}bestandsbuch/read_one_kundenportal.php`,
            params
        );
    }

    updateBestandsbuch(bestandsbuch: Bestandsbuch) {
        return this.api.post(
            `${this.apiBaseUrl}bestandsbuch/update_kundenportal.php`,
            bestandsbuch,
            null
        );
    }

    // Recht: 4427
    bestandsbuchTrotzdemAbschliessen(bestandsbuch: Bestandsbuch) {
        if (this.lss.hasPermission(4427)) {

            return this.api.post(
                `${this.apiBaseUrl}bestandsbuch/trotzdem_abschliessen.php`,
                bestandsbuch,
                null
            );
        } else {
            return of();
        }
    }

    bestandsbuchAbschliessen(bestandsbuch: Bestandsbuch, force: boolean) {
        if (force) {
            bestandsbuch.force = 1;
        } else {
            bestandsbuch.force = 0;
        }
        return this.api.post(
            `${this.apiBaseUrl}bestandsbuch/abschliessen_bestandsbuch.php`,
            bestandsbuch,
            null
        );
    }

    getBestandsbuchArtikel(bestandsbuch_artikel_id: number) {
        const params = new HttpParams().set(
            'bestandsbuch_artikel_id',
            bestandsbuch_artikel_id.toString()
        );
        return this.api.get(
            `${this.apiBaseUrl}bestandsbuch_artikel/read_one_kundenportal.php`,
            params
        );
    }

    updateBestandsbuchArtikel(bestandsbuch_artikel: BestandsbuchArtikel) {
        return this.api.post(
            `${this.apiBaseUrl}bestandsbuch_artikel/update_kundenportal.php`,
            bestandsbuch_artikel,
            null
        );
    }

    alleArtikelProtokollieren(bestandsbuch: Bestandsbuch) {
        return this.api.post(
            `${this.apiBaseUrl}bestandsbuch/alle_angewendet_kundenportal.php`,
            bestandsbuch,
            null
        );
    }
}
