import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GruppeTier } from '../../gruppe-tier/classes/gruppe-tier';
import { UntypedFormControl } from '@angular/forms';
import { Haendler } from '../../stammdaten/haendler/haendler';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { HttpParams } from '@angular/common/http';


class HitTierMeldungManuell {
    gt_id: number;
    kp_einstalldatum: Date;
    viehhaendler_id: number;
    abteil: string;
}

@Component({
    selector: 'kiene-gruppe-tier-hit-melden-manuell-dialog',
    templateUrl: './gruppe-tier-hit-melden-manuell-dialog.component.html',
    styleUrls: ['./gruppe-tier-hit-melden-manuell-dialog.component.scss']
})
export class GruppeTierHitMeldenManuellDialogComponent {

    tier: GruppeTier;
    meldung: HitTierMeldungManuell = new HitTierMeldungManuell();

    einstallDatumCtrl: UntypedFormControl = new UntypedFormControl(null);
    viehhaendler: Haendler;
    viehhaendlerParams = new HttpParams().set('favorit', '1');
    haendlerPath: string;

    constructor(
        public dialogRef: MatDialogRef<GruppeTierHitMeldenManuellDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        if (data.tier) {
            this.tier = data.tier;
        }
        this.haendlerPath = `${this.apiBaseUrl}viehhaendler/read_kundenportal.php`;
    }

    abort() {
        this.dialogRef.close();
    }

    melden() {
        this.meldung.kp_einstalldatum = this.einstallDatumCtrl.value;
        this.meldung.gt_id = this.tier.gt_id;
        this.meldung.abteil = this.tier.abteil;
        this.meldung.viehhaendler_id = this.viehhaendler?.viehhaendler_id;

        this.dialogRef.close(this.meldung);
    }
}
