import {DatePipe} from "@angular/common";
import {CalendarWeek} from "./calender-week";

export enum TimestampAgo {
    TEN_MINUTES = 1000 * 60 * 10,
    ONE_HOURS = 1000 * 60 * 60 * 1,
    EIGHT_HOURS = 1000 * 60 * 60 * 8,
}

export class PhitoDate {
    monthValue: number;
    dayOfYear: number;
    dayOfWeek: string;
    dayOfMonth: number;
    hour: number;
    minute: number;
    second: number;
    year: number;
    month: string;
    nano: number;
    chronology: Chronology;
}

export class Chronology {
    calendarType: string;
    id: string;
}

export enum PhitoTimeUnit {
    ms = 'ms',
    s = 's',
    min = 'min',
    h = 'h',
    d = 'd',
    mo = 'mo',
    yr = 'yr',
    wk = 'wk',
    hPwk = 'hPwk',
}

export class DateInterval {
    from: Date;
    to: Date;

    constructor(options?: {from: Date; to: Date}) {
        if (options) {
            this.from = options.from;
            this.to = options.to;
        }
    }
}


export class DateUtils {
    public static phitoDateToString(phitoDate: PhitoDate): string {
        const days = phitoDate.dayOfMonth < 10 ? '0' + phitoDate.dayOfMonth : phitoDate.dayOfMonth;
        const months = phitoDate.monthValue < 10 ? '0' + phitoDate.monthValue : phitoDate.monthValue;
        const hours = phitoDate.hour < 10 ? '0' + phitoDate.hour : phitoDate.hour;
        const minutes = phitoDate.minute < 10 ? '0' + phitoDate.minute : phitoDate.minute;
        const seconds = phitoDate.second < 10 ? '0' + phitoDate.second : phitoDate.second;

        return days + '.' + months + '.' + phitoDate.year + ' ' + hours + ':' + minutes + ':' + seconds;
    }

    public static getTimestampFrom(ago: TimestampAgo) {
        const now = Date.now();
        let agoTimestamp = now - ago.valueOf();
        return new Date(agoTimestamp);
    }

    public static getTimestampFromAgo(minutes: number) {
        const now = Date.now();
        let agoTimestamp = now - minutes * 60 * 1000;
        return new Date(agoTimestamp);
    }

    public static phitoDateToStringEnglish(phitoDate: PhitoDate): string {
        return `${phitoDate.year}-${phitoDate.monthValue}-${phitoDate.dayOfMonth} ${phitoDate.hour}:${phitoDate.minute}:${phitoDate.second}`;
    }
    public static phitoDateToShortStringEnglish(phitoDate: PhitoDate): string {
        return `${phitoDate.year}-${phitoDate.monthValue}-${phitoDate.dayOfMonth}`;
    }

    public static toSeconds(date: Date) {
        return date.getTime() / 1000;
    }

    public static dateToString(date: Date): string {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${date.getFullYear()} ${
            hours < 10 ? '0' + hours : hours
        }:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    }

    public static dateToStringWithoutSeconds(date: Date): string {
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
    }

    public static dateToStringEnglish(date: Date): string {
        return `${date.getDate()}.${
            date.getMonth() + 1
        }.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    }

    public static phitoDateToDate(phitoDate: PhitoDate): Date {
        return new Date(
            phitoDate.year,
            phitoDate.monthValue,
            phitoDate.dayOfMonth,
            phitoDate.hour,
            phitoDate.minute,
            phitoDate.second,
        );
    }

    public static secondsToHHmm(seconds: number): string {
        if (seconds === null || seconds === undefined) {
            return null;
        }
        seconds = Math.round(seconds);

        let hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        let minutes = Math.floor(seconds / 60);

        let hoursS: string = hours.toString();
        let minutesS: string = minutes.toString();

        if (hours < 10) {
            hoursS = '0' + hours;
        }
        if (minutes < 10) {
            minutesS = '0' + minutes;
        }

        return hoursS + ':' + minutesS;
    }

    public static secondsToHHmmss(seconds: number): string {
        if (seconds === null || seconds === undefined) {
            return null;
        }
        seconds = Math.round(seconds);
        if (seconds === 86400) {
            return '24:00:00'; // sonst 1d 00:00:00
        }

        let days = Math.floor(seconds / 86400);
        seconds -= days * 86400;
        let hours = Math.floor(seconds / 3600);
        seconds -= hours * 3600;
        let minutes = Math.floor(seconds / 60);
        seconds -= minutes * 60;

        let hoursS: string = hours.toString();
        let minutesS: string = minutes.toString();
        let secondsS: string = seconds.toString();

        let daysS = '';
        if (days > 0) {
            daysS = days.toString() + 'd ';
        }
        if (hours < 10) {
            hoursS = '0' + hours;
        }
        if (minutes < 10) {
            minutesS = '0' + minutes;
        }
        if (seconds < 10) {
            secondsS = '0' + seconds;
        }

        return daysS + hoursS + ':' + minutesS + ':' + secondsS;
    }

    public static getDifferenceInSeconds(from: Date, to: Date): number {
        if (from && to) {
            from = new Date(from);
            to = new Date(to);
            if (from >= to) {
                return 0;
            }
            const utc1 = Date.UTC(
                from.getFullYear(),
                from.getMonth(),
                from.getDate(),
                from.getHours(),
                from.getMinutes(),
                from.getSeconds(),
            );
            const utc2 = Date.UTC(
                to.getFullYear(),
                to.getMonth(),
                to.getDate(),
                to.getHours(),
                to.getMinutes(),
                to.getSeconds(),
            );

            return Math.floor((utc2 - utc1) / 1000);
        } else {
            return null;
        }
    }

    public static getStartOfCurrentShift() {
        return this.getStartOfShift(new Date());
    }

    public static getStartOfShift(d: Date) {
        // Schichten 6-14,14-22,22-6
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        let h = d.getHours();
        if (h >= 6 && h < 14) {
            d.setHours(6);
        } else if (h >= 14 && h < 22) {
            d.setHours(14);
        } else if (h >= 22) {
            d.setHours(22);
        } else {
            // h<6
            d.setHours(22);
            d = this.minusDays(d, 1);
        }
        return d;
    }

    public static getCurrentShiftInterval(): DateInterval {
        let d = this.getStartOfCurrentShift();
        return new DateInterval({from: d, to: this.plusHours(d, 8)});
    }

    static getNextShiftInterval() {
        let d = this.getStartOfCurrentShift();
        return new DateInterval({from: this.plusHours(d, 8), to: this.plusHours(d, 16)});
    }

    /**
     * Returns the the time Range for a given shift
     * @param shifts - How many shifts to shift back to - default 1
     */
    public static getLastShiftInterval(shifts?: number): DateInterval {
        let s = 1;
        if (shifts) {
            s = shifts;
        }
        let d = this.getStartOfCurrentShift();
        console.log('SHIFTS: ', shifts);
        return new DateInterval({from: this.minusHours(d, s * 8), to: d});
    }

    public static getStartOfCurrentMonth(): Date {
        let d = this.getStartOfToday();
        return this.getStartOfMonth(d);
    }

    public static getStartOfMonth(d: Date) {
        let nd = new Date(d);
        nd.setDate(1);
        nd.setHours(0, 0, 0, 0);
        return nd;
    }

    public static getEndOfMonth(d: Date) {
        let nd = new Date(d);
        nd.setMonth(nd.getMonth() + 1);
        nd.setDate(1);
        nd.setHours(0, 0, 0, 0);
        return nd;
    }

    public static getEndOfCurrentMonth() {
        let d = this.getStartOfToday();
        return this.getEndOfMonth(d);
    }

    public static getFirstDayOfWeek(): Date {
        let d = this.getStartOfToday();
        let t = d.getDay();
        if (t == 0) {
            // Sunday
            return this.minusDays(d, 6);
        }
        return this.minusDays(d, t - 1);
    }

    public static getStartOfDay(d: Date): Date {
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    }

    public static getStartOfToday(): Date {
        let d: Date = new Date();
        return this.getStartOfDay(d);
    }

    public static getStartOfYesterday(): Date {
        let d: Date = new Date();
        d.setDate(d.getDate() - 1);
        return this.getStartOfDay(d);
    }

    public static getStartOfTomorrow(): Date {
        let d: Date = new Date();
        d.setDate(d.getDate() + 1);
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    }

    public static getEndOfYesterday(): Date {
        let d: Date = new Date();
        d = DateUtils.minusDays(d, 1);
        d.setHours(23);
        d.setMinutes(59);
        d.setSeconds(59);
        return d;
    }

    public static getEndOfCurrentHour() {
        let d = new Date();
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        d.setHours(d.getHours() + 1);
        return d;
    }

    public static getStartOfCurrentHour() {
        let d = new Date();
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        return d;
    }

    public static plusSeconds(d: Date, s: number): Date {
        return new Date(d.getTime() + s * 1000);
    }

    public static minusSeconds(d: Date, s: number): Date {
        return this.plusSeconds(d, -s);
    }

    public static plusMinutes(d: Date, h: number) {
        return this.plusSeconds(d, h * 60);
    }

    public static minusMinutes(d: Date, h: number) {
        return this.plusMinutes(d, -h);
    }

    public static plusHours(d: Date, h: number) {
        return this.plusSeconds(d, h * 60 * 60);
    }

    public static minusHours(d: Date, h: number) {
        return this.plusHours(d, -h);
    }

    public static plusDays(d: Date, n: number) {
        return this.plusSeconds(d, n * 60 * 60 * 24);
    }

    public static minusDays(d: Date, n: number) {
        return this.plusDays(d, -n);
    }

    public static plusWeeks(d: Date, n: number) {
        return this.plusSeconds(d, n * (60 * 60 * 24 * 7));
    }

    public static minusWeeks(d: Date, n: number) {
        return this.plusWeeks(d, -n);
    }

    public static getTodayInterval(): DateInterval {
        let d = this.getStartOfToday();
        return new DateInterval({from: d, to: this.plusDays(d, 1)});
    }

    public static getYesterdayInterval(): DateInterval {
        let startOfToday = this.getStartOfToday();
        return new DateInterval({from: this.minusDays(startOfToday, 1), to: startOfToday});
    }

    static getTomorrowInterval() {
        let startOfToday = this.getStartOfToday();
        return new DateInterval({from: this.plusDays(startOfToday, 1), to: startOfToday});
    }

    public static getDayBeforeYesterdayInterval(): DateInterval {
        let startOfYesterday = this.getYesterdayInterval().from;
        return new DateInterval({from: this.minusDays(startOfYesterday, 1), to: startOfYesterday});
    }

    public static getCurrentWeekInterval(): DateInterval {
        let d = this.getFirstDayOfWeek();
        return new DateInterval({from: d, to: this.plusDays(d, 7)});
    }

    public static getLastWeekInterval(): DateInterval {
        let d = this.getFirstDayOfWeek();
        return new DateInterval({from: this.minusDays(d, 7), to: d});
    }

    public static getLastTwoWeeksInterval(): DateInterval {
        let d = this.getFirstDayOfWeek();
        return new DateInterval({from: this.minusDays(d, 7), to: this.plusDays(d, 7)});
    }

    public static getCurrentMonthInterval(): DateInterval {
        return new DateInterval({
            from: this.getFirstDayOfMonth(new Date()),
            to: this.getLastDayOfMonth(new Date()),
        });
    }

    public static getFirstDayOfMonth(date?: Date) {
        if (!date) {
            date = new Date();
        }
        return new Date(date.getFullYear(), +date.getMonth(), 1);
    }

    public static getLastDayOfMonth(date?: Date) {
        if (!date) {
            date = new Date();
        }
        return new Date(date.getFullYear(), +date.getMonth() + 1, 0);
    }

    public static getLastMonthInterval(): DateInterval {
        const now = new Date();
        let lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        return new DateInterval({
            from: this.getFirstDayOfMonth(lastMonth),
            to: this.getLastDayOfMonth(lastMonth),
        });
    }

    public static getNextMonthInterval() {
        const now = new Date();
        let nextMonth = new Date();
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        return new DateInterval({
            from: this.getFirstDayOfMonth(nextMonth),
            to: this.getLastDayOfMonth(nextMonth),
        });
    }

    public static shiftIntervallBackwards(interval: DateInterval): DateInterval {
        let sec = this.getDifferenceInSeconds(interval.from, interval.to);
        if (sec) {
            return new DateInterval({from: this.minusSeconds(interval.from, sec), to: this.minusSeconds(interval.to, sec)});
        }
        return interval;
    }

    public static shiftIntervallForwards(interval: DateInterval): DateInterval {
        let sec = this.getDifferenceInSeconds(interval.from, interval.to);
        if (sec) {
            return new DateInterval({from: this.plusSeconds(interval.from, sec), to: this.plusSeconds(interval.to, sec)});
        }
        return interval;
    }

    public static shiftIntervallToNow(interval: DateInterval): DateInterval {
        let sec = this.getDifferenceInSeconds(interval.from, interval.to);
        if (sec) {
            let ch = this.getEndOfCurrentHour();
            return new DateInterval({from: this.minusSeconds(ch, sec), to: ch});
        }
        return interval;
    }

    public static getDayOfYear(dat: Date): number {
        const d = new Date(dat);
        const start = new Date(d.getFullYear(), 0, 0);
        const diff = d.getTime() - start.getTime() + (start.getTimezoneOffset() - d.getTimezoneOffset()) * 60 * 1000;
        const oneDay = 1000 * 60 * 60 * 24;
        return Math.floor(diff / oneDay);
    }

    public static isDateString(toCheck: string) {
        return new Date(toCheck).toString() !== 'Invalid Date' && !isNaN(new Date(toCheck).getDate());
    }

    public static isToday(date: Date) {
        const startOfDay = this.getStartOfToday();
        const endOfDay = DateUtils.plusDays(startOfDay, 1);
        return date >= startOfDay && date <= endOfDay;
    }

    public static isTomorrow(date: Date) {
        date = new Date(date);
        const now = new Date();
        const tomorrowMorning = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);
        const tomorrowEvening = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 23, 59, 59);
        return date >= tomorrowMorning && date <= tomorrowEvening;
    }

    /**
     * returns a beautified string of the date
     * if date is tomorrow, returns 'morgen'
     * if date is today, returns 'Heute HH:mm'
     * if date is yesterday, returns 'Gestern HH:mm'
     * if date is this year, returns 'DD.MM HH:mm'
     * else returns the date in the format 'dd.MM.yyyy HH:mm'
     * @param createdAt
     * @param options
     */
    static beautify(createdAt: Date, options?: {showTime?: boolean; showSeconds?: boolean; showYear?: boolean}) {
        const beautifiedDate = new Date(createdAt);
        const showSeconds = options?.showSeconds ?? false;
        const showYear = options?.showYear ?? false;
        const showTime = options?.showTime === undefined ? true : options.showTime;
        const today = new Date();

        // console.log('input: ' + beautifiedDate.toISOString() + ' today: ' + today.toISOString());

        const time = `${beautifiedDate.getHours() <= 9 ? '0' + beautifiedDate.getHours() : beautifiedDate.getHours()}:${
            beautifiedDate.getMinutes() <= 9 ? '0' + beautifiedDate.getMinutes() : beautifiedDate.getMinutes()
        }${showSeconds ? ':' + beautifiedDate.getSeconds() : ''}`;

        //if tommorrow
        const now = new Date();
        let tomorrow = new Date(now);
        tomorrow.setDate(tomorrow.getDate() + 1);
        if (beautifiedDate.getDate() === tomorrow.getDate()) {
            if (!showTime) {
                return 'morgen ';
            } else {
                return `morgen ${time}`;
            }
        }
        //if today
        if (
            beautifiedDate.getFullYear() === today.getFullYear() &&
            beautifiedDate.getMonth() === today.getMonth() &&
            beautifiedDate.getDate() === today.getDate()
        ) {
            if (!showTime) {
                return 'heute ';
            } else {
                return `heute ${time}`;
            }
        }
        //if yesterday
        let yesterday = new Date(now);
        yesterday.setDate(yesterday.getDate() - 1);
        if (
            beautifiedDate.getFullYear() === today.getFullYear() &&
            beautifiedDate.getMonth() === today.getMonth() &&
            beautifiedDate.getDate() === yesterday.getDate()
        ) {
            if (!showTime) {
                return 'gestern ';
            } else {
                return `Gestern ${time}`;
            }
        }
        //if this year
        if (beautifiedDate.getFullYear() === today.getFullYear()) {
            return `${beautifiedDate.getDate()}.${beautifiedDate.getMonth() + 1}${
                showYear ? ':' + beautifiedDate.getFullYear() : ''
            } ${time}`;
        }

        if (!showYear) {
            if (!showTime) {
                return `${beautifiedDate.getDate()}.${beautifiedDate.getMonth() + 1}`;
            } else {
                return `${beautifiedDate.getDate()}.${beautifiedDate.getMonth() + 1} ${time}`;
            }
        } else {
            if (!showTime) {
                return `${beautifiedDate.getDate()}.${beautifiedDate.getMonth() + 1}.${beautifiedDate.getFullYear()}`;
            } else {
                return `${beautifiedDate.getDate()}.${beautifiedDate.getMonth() + 1}.${beautifiedDate.getFullYear()} ${time}`;
            }
        }
    }

    /**
     * Converts milliseconds to the nearest time-format e.g. seconds or minutes
     * @param ms
     */
    static convertMilliseconds(ms: number): string {
        let milliseconds = ms % 1000;
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        return `${hours > 0 ? hours + ' h' : ''}
     ${minutes > 0 ? minutes + ' m' : ''}
      ${seconds > 0 ? seconds + ' s' : ''}
       ${milliseconds >= 0 ? milliseconds + ' ms' : ''} `;
    }

    // public static getDateOfMonday(weekNumber: number, year: number) {
    //   let d = m().isoWeekYear(year).isoWeek(weekNumber).startOf('week').toDate();
    //   d.setDate(d.getDate() + 1);
    //   return d;
    // }

    public static getDateOfMonday(weekNumber: number, year: number) {
        let date = new Date(year, 0, 1 + (weekNumber - 1) * 7); // Elle's method
        date.setDate(date.getDate() + (1 - date.getDay())); // 0 - Sunday, 1 - Monday etc
        return date;
    }

    // public static getCalendarWeekNumber(d: Date): number {
    //   const weekNumber = m(d).isoWeek();
    //   return weekNumber;
    // }

    public static getCalendarWeek(d: Date): CalendarWeek {
        const date = new Date(d);
        // const weekNumber = m(date).isoWeek();
        const weekNumber = this.getCalendarWeekNumber(date);
        const monday = this.getDateOfMonday(weekNumber, date.getFullYear());
        const sunday = new Date(monday);
        const cw = new CalendarWeek();
        cw.weekNumber = weekNumber;
        cw.monday = monday;
        cw.sunday = new Date(sunday.setDate(monday.getDate() + 6));
        cw.year = date.getFullYear();
        return cw;
    }

    // public static getNumberOfWeeks(year: number) {
    //   return m(new Date(year, 0, 1)).isoWeeksInYear();
    // }

    public static getCalendarWeekNumber(d: Date) {
        d = new Date(+d);
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        const yearStart = new Date(d.getFullYear(), 0, 1);
        const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
        return weekNo;
    }

    public static getNumberOfWeeks(year) {
        const d = new Date(year, 11, 31);
        const week = this.getCalendarWeekNumber(d);
        return week == 1 ? 52 : week;
    }

    static getGermanTimeUnit(value: PhitoTimeUnit) {
        switch (value) {
            case PhitoTimeUnit.d:
                return 'Tag(e)';
            case PhitoTimeUnit.h:
                return 'Std.';
            case PhitoTimeUnit.mo:
                return 'Monat(e)';
            case PhitoTimeUnit.yr:
                return 'Jahr(e)';
            case PhitoTimeUnit.s:
                return 'Sek.';
            case PhitoTimeUnit.ms:
                return 'Ms';
            case PhitoTimeUnit.min:
                return 'Min.';
            default:
                return value;
        }
    }

    static prettyPrint(geburtsdatum: Date) {
        const d = new Date(geburtsdatum);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);

        const prettyDate = new DatePipe('de-DE').transform(d, 'dd.MM.');

        if (d.getMonth() === today.getMonth() && d.getDate() === today.getDate()) {
            return 'heute (' + prettyDate + ')';
        } else if (d.getMonth() === yesterday.getMonth() && d.getDate() === yesterday.getDate()) {
            return 'gestern (' + prettyDate + ')';
        } else if (d.getMonth() === tomorrow.getMonth() && d.getDate() === tomorrow.getDate()) {
            return 'morgen (' + prettyDate + ')';
        } else {
            return prettyDate;
        }
    }

    static getAgeAtEndOfYear(birthday: Date) {
        birthday = new Date(birthday);
        const today = new Date();
        const years = today.getFullYear() - birthday.getFullYear();
        return years;
    }

    public static getAgeToday(birthday: Date): number {
        let year = this.getAgeAtEndOfYear(birthday);
        const today = new Date();
        if (today.getMonth() < birthday.getMonth()) {
            return (year -= 1);
        } else if (today.getMonth() === birthday.getMonth()) {
            if (today.getDate() < birthday.getDate()) {
                return (year -= 1);
            }
        }
        return year;
    }

    static dateToYYYYMMDD(date: Date) {
        date = new Date(date);
        const year = date.getFullYear();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        return `${date.getFullYear()}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    }

    static isSameDay(from: Date, to: Date) {
        if (
            from.getDate() === to.getDate() &&
            from.getMonth() === to.getMonth() &&
            from.getFullYear() === to.getFullYear()
        ) {
            return true;
        }
        return false;
    }
}
