export class RinderpassScan {
    grps_id: number;
    gruppe_id: number;
    gruppe: string;
    viehhaendler_id: number;
    viehhaendler: string;
    kp_einstalldatum: Date;
    pdf_id: number;
    seitenzahl_gesamt: number;
    seitenzahl_fehler: string;
    erstellt: Date;
    dateiname: string;
    verarbeitet_anzahl: number = undefined;
}
