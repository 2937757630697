import {HttpParams} from "@angular/common/http";
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from "@angular/material/select";
import {Subscription} from "rxjs";
import {API_BASE_URL_SERVICE} from "../../../../../projects/kiene-core/src/lib/kiene-core.config";
import {
    KieneBackendApiService
} from "../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service";
import {MessageService} from "../../../services/message.service";
import {HeimtierDialogComponent} from "../../heimtiere/heimtier-dialog/heimtier-dialog.component";
import {HeimtierKundeDialogComponent} from "../../heimtiere/heimtier-kunde-dialog/heimtier-kunde-dialog.component";
import {
    Heimtier,
    HeimtierKalenderEintrag,
    HeimtierKalenderEintragOutput,
    HeimtierOutput
} from "../heimtier-kalender-eintrag";
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {KieneAutocompleteCreateNewEvent} from '../../../components/kiene-autocomplete/kiene-autocomplete.component';
import {HeimtierKundenComponent} from '../../heimtier-kunden/heimtier-kunden.component';

/**
 * Komponente zur Verwaltung eines Kalendereintrags-Dialogs.
 * Diese Komponente ist für das Erstellen oder Bearbeiten von Kalendereinträgen für Heimtiere zuständig.
 */
@Component({
    selector: 'kiene-kalender-eintrag-dialog',
    templateUrl: './kalender-eintrag-dialog.component.html',
    styleUrls: ['./kalender-eintrag-dialog.component.scss']
})
export class KalenderEintragDialogComponent implements OnInit, OnDestroy {

    // FormControls für Benutzereingaben im Formular.
    public dateCtrl: FormControl<Date> = new FormControl<Date>(new Date(), Validators.required);
    public vonCtrl: FormControl = new FormControl(undefined, Validators.required);
    public bisCtrl: FormControl = new FormControl(undefined, Validators.required);


    public titelCtrl: FormControl = new FormControl(undefined, Validators.required);
    public beschreibungCtrl: FormControl = new FormControl(undefined);
    public tiereCtrl: FormControl = new FormControl([], Validators.required);

    // Variablen zur Verwaltung von Farben, Tieren und Kundendetails.
    public color: string;
    public kunde: Client;
    public tiere: Heimtier[] = [];
    autocompleCreateKundeEvent: KieneAutocompleteCreateNewEvent;
    isSaving: boolean = false;
    private sub: Subscription = new Subscription();


    constructor(
        public dialogRef: MatDialogRef<KalenderEintragDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public kalenderEintrag: HeimtierKalenderEintrag,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private dialog: MatDialog,
        private api: KieneBackendApiService,
        private messageService: MessageService,
    ) {
    }

    /**
     * Angular Lifecycle-Hook, der aufgerufen wird, wenn die Komponente initialisiert wird.
     * Setzt die FormControls basierend auf den übergebenen Kalendereintrag-Daten.
     */
    ngOnInit(): void {

        if (!this.kalenderEintrag) {
            this.kalenderEintrag = new HeimtierKalenderEintrag();
            this.kalenderEintrag.von = this.dateCtrl.value;
            this.kalenderEintrag.bis = this.dateCtrl.value;
        } else {
            this.kalenderEintrag.von = new Date(this.kalenderEintrag.von);
            this.kalenderEintrag.bis = new Date(this.kalenderEintrag.bis);

            if (!this.kalenderEintrag.von) {
                this.kalenderEintrag.von = new Date();
            }

            this.vonCtrl.setValue(this.formatTime(this.kalenderEintrag.von));
            if (this.kalenderEintrag.bis) {
                this.bisCtrl.setValue(this.formatTime(this.kalenderEintrag.bis));
            }

            this.dateCtrl.setValue(this.kalenderEintrag.von);

            this.titelCtrl.setValue(this.kalenderEintrag.titel);

            this.beschreibungCtrl.setValue(this.kalenderEintrag.beschreibung);

            if (this.kalenderEintrag?.kunde_id) {
                this.kunde = new Client();
                this.kunde.kunde_id = this.kalenderEintrag.kunde_id;
                this.kunde.bezeichnung = this.kalenderEintrag?.kunde;
                this.ladeTiere();
            }
        }

        this.autocompleCreateKundeEvent = new KieneAutocompleteCreateNewEvent();
        this.autocompleCreateKundeEvent.buttonText = 'Neuen Kunden anlegen';
        this.autocompleCreateKundeEvent.permission = [HeimtierKundenComponent.PERMISSION_CREATE];

        this.tiereCtrl.disable();
        this.ladeTiere();


        this.vonCtrl.valueChanges.subscribe({
            next: (timeString: string) => {
                const hours = timeString.split(':')[0];
                const minutes = timeString.split(':')[1];
                const von = new Date(this.dateCtrl.value);
                von.setHours(+hours);
                von.setMinutes(+minutes);
                if (!this.bisCtrl.value) {
                    const datePlusOneHour = new Date(von);
                    datePlusOneHour.setHours(datePlusOneHour.getHours() + 1);
                    this.bisCtrl.setValue(this.formatTime(datePlusOneHour));
                    this.kalenderEintrag.bis = datePlusOneHour;
                }
                this.kalenderEintrag.von = von;
            }
        });

        this.bisCtrl.valueChanges.subscribe({
            next: (timeString: string) => {
                const hours = timeString.split(':')[0];
                const minutes = timeString.split(':')[1];
                const bis = new Date(this.dateCtrl.value);
                bis.setHours(+hours);
                bis.setMinutes(+minutes);
                this.kalenderEintrag.bis = bis;
            }
        });
    }


    /**
     * Angular Lifecycle-Hook, der aufgerufen wird, wenn die Komponente zerstört wird.
     * Wird verwendet, um Ressourcen wie Subscriptions zu bereinigen.
     */
    public ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }


    /**
     * Speichert den Kalendereintrag in der Backend-API.
     */
    public save(): void {
        this.isSaving = true;

        this.kalenderEintrag.beschreibung = this.beschreibungCtrl.value;
        this.kalenderEintrag.tiere = this.tiereCtrl.value;

        const nullIndex = this.kalenderEintrag.tiere.findIndex(tier => {
            return tier === undefined || tier === null;
        });

        if (nullIndex >= 0) {
            this.kalenderEintrag.tiere.splice(nullIndex, 1);
        }


        if (!this.kalenderEintrag.titel) {
            this.kalenderEintrag.titel = this.kalenderEintrag.kunde;
        }

        this.api.post(this.apiBaseUrl + 'heimtier_kalendereintrag/update.php', this.kalenderEintrag).subscribe({
            next: response => {
                this.isSaving = false;
                const output = new HeimtierKalenderEintragOutput();
                output.heimtierKalenderEintrag = response;
                output.confirm = true;
                this.dialogRef.close(output);
            },
            error: err => {
                this.isSaving = false;
                this.messageService.errorMessage('Kalendereintrag konnte nicht gespeichert werden!', err);
            }
        });
    }


    /**
     * Überprüft, ob der "Speichern"-Button deaktiviert werden sollte.
     *
     * @returns True, wenn der Speichern-Button deaktiviert sein sollte, ansonsten false.
     */
    public isValid(): boolean {
        return (this.kalenderEintrag.kunde_id && this.kalenderEintrag.von !== undefined);
    }

    /**
     * Fügt einen Kunden zum Kalendereintrag hinzu.
     * Fokussiert das "Tiere"-Auswahlfeld, nachdem der Kunde hinzugefügt wurde.
     *
     * @param kunde - Der hinzuzufügende Kunde.
     */
    public setKunde(kunde: Client): void {
        if (!kunde) {
            return;
        }
        this.kunde = kunde;
        this.kalenderEintrag.kunde_id = kunde?.kunde_id;
        this.kalenderEintrag.kunde = kunde?.bezeichnung;
        this.tiereCtrl.enable();
        if (kunde?.kunde_id) {
            this.ladeTiere();
            setTimeout(() => {
            }, 200);
        }
    }

    /**
     * Erstellt einen neuen Kunden durch das Öffnen eines Dialogs.
     * Fokussiert das "Tiere"-Auswahlfeld, nachdem der Kunde erstellt wurde.
     */
    public createKunde(name?: string): void {
        this.dialog.open(HeimtierKundeDialogComponent, {minWidth: '600px'}).afterClosed().subscribe({
            next: kunde => {
                this.kunde = kunde;
                this.kalenderEintrag.kunde_id = kunde?.kunde_id;
                this.ladeTiere();
            }
        })
    }

    /**
     * Fügt ein neues Tier zur Liste hinzu.
     *
     * @param heimtier - Das hinzuzufügende Tier. Wenn keines übergeben wird, wird eine neue Instanz erstellt.
     */
    public createTier(heimtier?: Heimtier): void {
        if (!heimtier) {
            heimtier = new Heimtier();
        }
        heimtier.kunde_id = this.kunde?.kunde_id;

        this.dialog.open(HeimtierDialogComponent, {data: heimtier}).afterClosed().subscribe({
            next: (value: HeimtierOutput) => {
                if (value?.confirm) {
                    // Tier zum Array hinzufügen.
                    this.tiere.push(value.heimtier);

                    const controlValues: any = this.tiereCtrl.value;
                    controlValues.push(value.heimtier);

                    this.tiereCtrl.setValue(controlValues);
                }
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Hinzufügen des Tieres', err);
            }
        });
    }

    /**
     * Vergleicht zwei Tiere, um festzustellen, ob sie gleich sind.
     *
     * @param tier1 - Erstes zu vergleichendes Tier.
     * @param tier2 - Zweites zu vergleichendes Tier.
     * @returns True, wenn die Tiere gleich sind, ansonsten false.
     */
    compareTiere(tier1: Heimtier, tier2: Heimtier): boolean {
        const result = tier1 && tier2 ? tier1.heimtier_id === tier2.heimtier_id : tier1 === tier2;
        console.log(`Vergleiche Tiere: ${tier1?.heimtier_id} und ${tier2?.heimtier_id} -> ${result}`);
        return result;
    }

    /**
     * Aktualisiere die Tiere im Kalender-Eintrag mit der aktuellen Auswahl
     *
     * @param event
     */
    public onTiereSelectionChange(event: MatSelectChange): void {
        this.kalenderEintrag.tiere = event.value;
    }


    /**
     * Lädt die Tiere, die mit dem ausgewählten Kunden verknüpft sind.
     *
     * @param heimtier - Ein optionales Tier, das nach dem Laden zur Liste hinzugefügt wird.
     */
    private ladeTiere(heimtier?: Heimtier): void {
        if (!this.kunde) {
            return;
        }
        const param = new HttpParams().set('kunde_id', this.kunde?.kunde_id);
        this.sub?.unsubscribe()
        this.sub = this.api.getAll(this.apiBaseUrl + 'heimtier/read.php', param).subscribe({
            next: value => {
                this.tiere = value.records;
                this.tiereCtrl.enable()
                if (heimtier) {
                    this.kalenderEintrag.tiere.push(heimtier);
                }
                this.tiereCtrl.setValue(this.kalenderEintrag.tiere);

            }, error: err => {
                this.messageService.errorMessage('Fehler beim Laden der Tiere', err);
            }
        })
    }

    /**
     * Formatiert ein gegebenes Datum zu einem String im `HH:mm`-Format.
     *
     * @param date - Zu formatierendes Datum.
     * @returns Ein String, der die formatierte Uhrzeit repräsentiert.
     */
    private formatTime(date: Date): string {
        date = new Date(date)
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }


}
