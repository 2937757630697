import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Betriebsart} from '../../../../../projects/kiene-core/src/lib/components/stammdaten/vvvo/classes/betriebsart';
import {FormControl} from '@angular/forms';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {Tierart} from '../../tierarten/tierart';
import {MessageService} from '../../../services/message.service';

@Component({
    selector: 'kiene-betriebsarten-tierarten-dialog',
    templateUrl: './betriebsarten-tierarten-dialog.component.html',
    styleUrls: ['./betriebsarten-tierarten-dialog.component.scss']
})
export class BetriebsartenTierartenDialogComponent implements OnInit {
    tierartenCtrl = new FormControl();
    tierarten: Tierart[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public betriebsart: Betriebsart,
                public dialogRef: MatDialogRef<BetriebsartenTierartenDialogComponent>,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
        if (this.betriebsart?.tierarten) {
            this.tierartenCtrl.setValue(this.betriebsart.tierarten);
        }
        this.ladeTierarten();
    }

    tierartComparator(t1: Tierart, t2: Tierart): boolean {
        return t1?.tierart_id === t2?.tierart_id;
    }

    save() {
        this.betriebsart.tierarten = this.tierartenCtrl.value;
        this.api.post(this.apiBaseUrl + '/betriebsart/update.php', this.betriebsart).subscribe({
            next: () => {
                this.messageService.infoMessage('Erfolgreich gespeichert!');
                this.dialogRef.close(true);
            },
            error: err => {
                this.messageService.errorMessage('Speichern fehlgeschlagen!', err);
            }
        });
    }

    private ladeTierarten() {
        this.api.getAll(this.apiBaseUrl + 'tierart/read.php').subscribe({
            next: response => {
                this.tierarten = response.records;
            },
            error: err => {
                this.messageService.errorMessage('Tierarten konnten nicht geladen werden', err);
            }
        });
    }
}
