<h1 mat-dialog-title>Rechnungsposition {{position.artikel}} &auml;ndern</h1>
<div mat-dialog-content style="padding: 24px;">

    <fieldset *ngIf="position.chargen.length > 0" style="border-radius: 6px;">
        <legend>Artikelmengen</legend>
    <div class="one-row" *ngFor="let c of position.chargen">
        <span>Charge</span>
        <span class="spacer"></span>
    </div>
    <div class="one-row" *ngFor="let c of position.chargen">
        <span>{{c.charge}}</span>
        <span class="spacer"></span>
        <mat-form-field>
            <mat-label>Menge</mat-label>
            <input matInput type="number" [(ngModel)]="c.chargenmenge">
        </mat-form-field>
    </div>
    </fieldset>
    <mat-form-field *ngIf="position.chargen.length === 0">
        <mat-label>Menge</mat-label>
        <input matInput type="number" [formControl]="mengeCtrl">
    </mat-form-field>
    <br>
    <mat-form-field>
        <mat-label>Einzelpreis</mat-label>
        <input matInput type="number" [formControl]="einzelpreisCtrl">
        <span matSuffix>&euro;</span>
    </mat-form-field>
    <br>
    <mat-checkbox [formControl]="gutschriftCtrl">Gutschrift erstellen</mat-checkbox>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">&Auml;nderung speichern</button>
</div>