import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigatorDialogComponent } from './navigator-dialog/navigator-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



@NgModule({
  declarations: [NavigatorDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    RouterModule,
    MatAutocompleteModule
  ]
})
export class NavigatorDialogModule { }
