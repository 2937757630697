<h1 mat-dialog-title>Bestellmengen aktualisieren</h1>
<div mat-dialog-content>
  <p>Bestellmengen f&uuml;r den Artikel {{bestellvorschlag.bezeichnung}} aktualisieren.</p>
  <mat-form-field>
    <mat-label>Mindestbestand</mat-label>
    <input matInput type="number" [formControl]="mindestbestandCtrl">
  </mat-form-field>
  <br>
  <mat-form-field>
    <mat-label>Mindestbestellmenge</mat-label>
    <input matInput type="number" [formControl]="mindestbestellmengeCtrl">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Abbrechen</button>
  <button mat-flat-button color="accent" (click)="save()">Aktualisieren</button>
</div>