import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Entsorger} from "../entsorger";

@Component({
  selector: 'kiene-entsorger-kunden-nr-verwalten-dialog',
  templateUrl: './entsorger-kunden-nr-verwalten-dialog.component.html',
  styleUrls: ['./entsorger-kunden-nr-verwalten-dialog.component.scss']
})
export class EntsorgerKundenNrVerwaltenDialogComponent implements OnInit {

    entsorger: Entsorger;

  constructor(
      public dialogRef: MatDialogRef<EntsorgerKundenNrVerwaltenDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
      this.entsorger = data.entsorger;
  }

  ngOnInit(): void {
  }

}
