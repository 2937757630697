<h1 mat-dialog-titel>Kalenderwoche ausw&auml;hlen</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Jahr</mat-label>
        <mat-select [formControl]="yearCtrl">
            <mat-option *ngFor="let y of years" [value]="y">{{y}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-grid-list cols="4" rowHeight="1:1">
        <mat-grid-tile *ngFor="let w of weeks" style="cursor: pointer;"
            [ngClass]="{'week-tile': true, 'now' : w.year === currentYear && w.weekNumber === currentWeek, 'selected': w.year === selectedWeek.year && w.weekNumber === selectedWeek.weekNumber }"
            (click)="select(w)">
            <div style="text-align: center;">
                <p style="padding:0px; margin: 0px; font-weight: bold;">{{w.weekNumber}}</p>
                <p style="padding:0px; margin: 0px;">{{w.monday | date: 'dd.MM.'}}</p>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
</div>