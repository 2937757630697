<div class="kiene-content-page" *kienePermission="[51]">
    <h1>{{ currentUser.vorname }} {{ currentUser.nachname }}</h1>

    <mat-card>
        <mat-card-header>
            <mat-card-title>Namen ändern</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field style="width: 100px; margin-right: 24px">
                <mat-label>Titel</mat-label>
                <input matInput type="text" [formControl]="titelCtrl" />
            </mat-form-field>
            <mat-form-field style="width: 200px; margin-right: 24px">
                <mat-label>Vorname</mat-label>
                <input matInput type="text" [formControl]="vornameCtrl" />
            </mat-form-field>
            <mat-form-field style="width: 200px; margin-right: 24px">
                <mat-label>Nachname</mat-label>
                <input matInput type="text" [formControl]="nachnameCtrl" />
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions style="text-align: right">
            <button mat-raised-button color="primary" *kienePermission="[53]" (click)="changeName()">
                Speichern
            </button>
        </mat-card-actions>
    </mat-card>

    <mat-card style="margin-top: 20px">
        <mat-card-header>
            <mat-card-title>Telefonnummern</mat-card-title>
        </mat-card-header>
        <mat-form-field style="min-width: 200px; width: 22%; padding-left: 10px">
            <input matInput type="tel" placeholder="Mobiltelefon" [formControl]="mobiltelefonCtrl" />
        </mat-form-field>
        <br />
        <mat-form-field style="min-width: 200px; width: 22%; padding-left: 10px">
            <input matInput type="tel" placeholder="Festnetz" [formControl]="festnetzCtrl" />
        </mat-form-field>
        <mat-card-actions style="text-align: right">
            <button mat-raised-button color="primary" *kienePermission="[53]" (click)="changeTelefonnummer()">
                Speichern
            </button>
        </mat-card-actions>
    </mat-card>

    <mat-card style="margin-top: 20px">
        <mat-card-header>
            <mat-card-title>Zugewiesene Betriebe</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <table style="min-width: 200px; width: 50%; padding-left: 10px">
                <tr *ngFor="let c of clients">
                    <td>
                        <span style="font-weight: bold">{{
                            c.bezeichnung_final
                            }}</span>
                        {{ c.zusatz_final }}{{ " - " + c.kunde_nr_intern
                        }}{{
                        c.unternehmensgruppe
                        ? " - " + c.unternehmensgruppe
                        : ""
                        }}
                    </td>
                    <td style="text-align: right">
                        <button mat-icon-button *kienePermission="[53]" (click)="removeClientFromUser(c)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <kiene-client-autocomplete [panelWidth]="'50%'" placeholder="Betrieb hinzufügen"
                            *kienePermission="[53]" (selectedClient)="addClientToUser($event)"
                            [clearAfterSelect]="true"></kiene-client-autocomplete>
                    </td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>
    <!--
  <mat-card style="margin-top: 20px;">
    <mat-card-header>
      <mat-card-title>Administratorrechte</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-checkbox style="min-width: 200px; width: 22%; padding-left: 10px;" [formControl]="adminCtrl">besitzt
        Administratorrechte</mat-checkbox>
    </mat-card-content>
    <mat-card-actions style="text-align: right;">
      <button mat-raised-button color="primary" *kienePermission="[53]" (click)="changeAdminRights()">Speichern</button>
    </mat-card-actions>
  </mat-card>
-->
    <mat-card style="margin-top: 20px">
        <mat-card-header>
            <mat-card-title>Zugewiesene Mandanten</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <table style="min-width: 200px; width: 50%; padding-left: 10px">
                <tr *ngFor="let am of assignedMandanten">
                    <td>{{ am.bezeichnung }}</td>
                    <td style="text-align: right">
                        <button mat-icon-button *kienePermission="[53]" (click)="removeMandantFromUser(am)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <mat-select placeholder="Mandanten" [formControl]="availableMandantenCtrl"
                            (selectionChange)="assignMandantToUser($event)">
                            <mat-option *ngFor="let m of availableMandanten" [value]="m">{{ m.bezeichnung
                                }}</mat-option>
                        </mat-select>
                    </td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>

    <mat-card style="margin-top: 20px">
        <mat-card-header>
            <mat-card-title>E-Mail Adresse ändern</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field style="min-width: 200px; width: 50%; padding-left: 10px">
                <input matInput placeholder="E-Mail Adresse" type="email" [formControl]="emailCtrl" />
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions style="text-align: right">
            <button mat-raised-button color="primary" *kienePermission="[53]" (click)="changeEmail()"
                [disabled]="!emailCtrl.valid">
                Speichern
            </button>
        </mat-card-actions>
    </mat-card>

    <mat-card style="margin-top: 20px">
        <mat-card-header>
            <mat-card-title>Benutzerrolle ändern</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field style="min-width: 200px; width: 50%; padding-left: 10px">
                <mat-select multiple placeholder="Benutzerrolle" [formControl]="userrolesCtrl"
                    [compareWith]="compareRoles">
                    <mat-option *ngFor="let ur of userroles" [value]="ur">{{ ur.bezeichnung
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions style="text-align: right">
            <button mat-raised-button color="primary" (click)="changeUserrole()" *kienePermission="[53]"
                [disabled]="!userrolesCtrl.valid">
                Speichern
            </button>
        </mat-card-actions>
    </mat-card>

    <mat-card *ngIf="!istBenutzerKundeOderViehhaendlerOderSGS()" style="margin-top: 20px">
        <mat-card-header>
            <mat-card-title>Urlaubstage im Jahr</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field style="min-width: 200px; width: 50%; padding-left: 10px">
                <mat-label>Urlaubstage im Jahr</mat-label>
                <input matInput type="number" [formControl]="urlaubstageCtrl" min="0">
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions style="text-align: right">
            <button mat-raised-button color="primary" (click)="updateUrlaubstage()" *kienePermission="[53]"
                [disabled]="!urlaubstageCtrl.valid">
                Speichern
            </button>
        </mat-card-actions>
    </mat-card>

    <mat-card style="margin-top: 20px">
        <mat-card-header>
            <mat-card-title>Neues Passwort vergeben</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <mat-form-field hintLabel="Mindestens 8 Zeichen" style="min-width: 200px; width: 50%; padding-left: 10px">
                <input #newPasswordInput placeholder="Passwort" matInput type="password"
                    [formControl]="newPasswortCtrl" />
                <mat-hint align="end">{{ newPasswordInput.value?.length || 0 }}/8</mat-hint>
            </mat-form-field>
            <mat-form-field hintLabel="Mindestens 8 Zeichen" style="min-width: 200px; width: 50%; padding-left: 10px">
                <input #newPasswordRepeatInput placeholder="Passwort wiederholen" matInput type="password"
                    [formControl]="newPasswortRepeatCtrl" />
                <mat-hint align="end">{{ newPasswordRepeatInput.value?.length || 0 }}/8</mat-hint>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions style="text-align: right">
            <button mat-raised-button color="primary" *kienePermission="[53]" (click)="changePassword()" [disabled]="
                    newPasswordInput.value.length < 8 ||
                    !newPasswortCtrl.valid ||
                    !newPasswortRepeatCtrl.valid ||
                    newPasswortCtrl.value !== newPasswortRepeatCtrl.value
                ">
                Speichern
            </button>
        </mat-card-actions>
    </mat-card>
</div>