<div class="kiene-content-page">
    <div *ngIf="isLoading" class="kiene-loading-spinner">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>
    <div class="kiene-table-header">
        <div>
            <button (click)="goBack()" mat-icon-button matTooltip="zurück" style="display: inline-block">
                <mat-icon color="primary">arrow_back</mat-icon>
            </button>
            <h2 style="display: inline-block; margin-left: 20px">
                Unternehmensgruppe {{ companyGroup.bezeichnung }}
            </h2>
        </div>
    </div>
    <div>
        <div class="kiene-table-header">
            <mat-card style="width: 100%">
                <mat-tab-group (selectedTabChange)="setSelectedTab($event)" [selectedIndex]="selectedTab"
                               style="width: 100%">
                    <mat-tab label="Allgemein">
                        <div style="padding-bottom: 30px">
                            <h4>Allgemein</h4>
                            <mat-form-field style="width: 40%; min-width: 250px">
                                <input [(ngModel)]="companyGroup.bezeichnung" matInput
                                       placeholder="Name der Unternehmensgruppe"
                                       type="text"/>
                            </mat-form-field>
                            <br/>
                            <mat-form-field style="width: 82%">
                                <input [(ngModel)]="companyGroup.kommentar" matInput placeholder="Kommentar"
                                       type="text"/>
                            </mat-form-field>

                            <mat-form-field style="width: 82%">
                                <input [(ngModel)]="companyGroup.strasse" matInput placeholder="Straße" type="text"/>
                            </mat-form-field>

                            <mat-form-field style="width: 27%; margin-right: 1%">
                                <input [(ngModel)]="companyGroup.plz" matInput placeholder="PLZ" type="text"/>
                            </mat-form-field>

                            <mat-form-field style="width: 27%; margin-right: 1%">
                                <input [(ngModel)]="companyGroup.ort" matInput placeholder="Ort" type="text"/>
                            </mat-form-field>

                            <mat-form-field style="width: 27%; margin-right: 1%">
                                <input [(ngModel)]="companyGroup.ortsteil" matInput placeholder="Ortsteil"
                                       type="text"/>
                            </mat-form-field>

                            <mat-form-field style="width: 40%; margin-right: 10px">
                                <input [formControl]="emailCtrl" matInput placeholder="E-Mail Adresse" type="email"/>
                                <span matPrefix>
                                    <mat-icon>email</mat-icon>&nbsp;&nbsp;
                                </span>
                                <button (click)="clearEmail()" *ngIf="emailCtrl.value" aria-label="Clear" mat-button
                                        mat-icon-button
                                        matSuffix>
                                    <mat-icon>close</mat-icon>
                                </button>
                                <mat-error *ngIf="emailCtrl.invalid">E-Mail Adresse ungültig</mat-error>
                            </mat-form-field>
                            <br/>
                        </div>
                        <div *kienePermission="[1582]" style="width: 100%; text-align: right">
                            <button (click)="saveCompanyGroup()" *ngIf="isCreationForm" color="primary"
                                    mat-raised-button
                                    style="margin-top: 40px">
                                Speichern
                            </button>
                            <button (click)="saveCompanyGroup()" *ngIf="!isCreationForm" color="primary"
                                    mat-raised-button
                                    style="margin-top: 40px">
                                Speichern
                            </button>
                        </div>
                    </mat-tab>

                    <mat-tab label="Kunden" [disabled]="currentCompanyGroupId === 'neu'">
                        <div>
                            <h4>Zugeh&ouml;rige Kunden</h4>
                            <mat-list>
                                <mat-list-item *ngFor="let c of clients">
                                    <mat-icon>store</mat-icon>
                                    <a class="kiene-color" routerLink="/stammdaten/kunden/{{
                                            c.kunde_id
                                        }}" style="padding-left: 20px">{{ c.bezeichnung_final }} - {{
                                        c.zusatz_final }}</a>
                                </mat-list-item>
                            </mat-list>
                        </div>
                    </mat-tab>

                    <mat-tab *kienePermission="[3401]" label="Finanzen" [disabled]="currentCompanyGroupId === 'neu'">
                        <div>
                            <div style="width: 40%">
                                <kiene-verkaufspreis-selector (verkaufspreisSelectionChanged)="
                                        setSelectedVerkaufspreis($event)
                                    " [selectedVerkaufspreisId]="
                                        companyGroup.verkaufspreis_id
                                    "></kiene-verkaufspreis-selector>
                            </div>
                            <br/>
                            <mat-form-field style="width: 40%">
                                <input [formControl]="rabattCtrl" matInput placeholder="Rabatt" type="number"/>
                                <span matSuffix>%</span>
                            </mat-form-field>
                        </div>
                        <div *kienePermission="[1582]" style="width: 100%; text-align: right">
                            <button (click)="saveCompanyGroup()" *ngIf="isCreationForm" color="primary"
                                    mat-raised-button
                                    style="margin-top: 40px">
                                Speichern
                            </button>
                            <button (click)="saveCompanyGroupFinanzen()" *ngIf="!isCreationForm" color="primary"
                                    mat-raised-button
                                    style="margin-top: 40px">
                                Speichern
                            </button>
                        </div>
                    </mat-tab>

                    <mat-tab label="Artikelpreise" [disabled]="currentCompanyGroupId === 'neu'">
                        <ng-template matTabContent>
                            <kiene-kiene-table (optionClicked)="catchOptionEvent($event)"
                                               [tableDescriptor]="artikelpreiseTableDescriptor"></kiene-kiene-table>

                        </ng-template>

                    </mat-tab>
                </mat-tab-group>
            </mat-card>
        </div>
    </div>
</div>
