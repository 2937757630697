<div style="margin: 12px;">
    <button mat-stroked-button color="accent" (click)="aktualisieren()">
        <mat-icon>refresh</mat-icon> Aktualisieren</button>
</div>
<div class="one-row">
    <div class="card">
        <div class="column">
            <div class="card-header-sticky">
                <mat-icon>today</mat-icon>
                <span>Für heute geplant ({{plannedData?.length || 0}})</span>
                <span class="spacer"></span>
                <button mat-icon-button (click)="addEntry()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <mat-list>
                <p *ngIf="plannedData?.length === 0" style="width: 100%; text-align: center; font-weight: bold;">Keine
                    Einträge vorhanden</p>
                <mat-list-item *ngFor="let item of plannedData">
                    <!-- Hamburger-Menü -->
                    <button mat-icon-button [matMenuTriggerFor]="menu1" color="accent">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu1="matMenu">
                        <button mat-menu-item *kienePermission="[7002]" (click)="changeToWartezimmer(item)">
                            <mat-icon>airline_seat_recline_extra</mat-icon><span>Im Wartezimmer</span>
                        </button>
                        <button mat-menu-item *kienePermission="[7002]" (click)="editEntry(item)">
                            <mat-icon>edit</mat-icon><span>Kalendereintrag editieren</span>
                        </button>
                        <button mat-menu-item *kienePermission="[7004]" (click)="onDelete(item)">
                            <mat-icon>delete</mat-icon><span>Kalendereintrag löschen</span>
                        </button>
                    </mat-menu>
                    <div class="item-content" (click)="changeToWartezimmer(item)">
                        <h4 mat-line>{{ item.kunde }} {{ getTiernamen(item) }}</h4>
                        <p mat-line>{{ item?.von | date : 'HH:mm' }}: {{ item.titel }}</p>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    <div class="card">
        <div class="column">
            <div class="card-header-sticky">
                <mat-icon>airline_seat_recline_extra</mat-icon>
                <span>Im Wartezimmer ({{waitingRoomData?.length || 0}})</span>
                <span class="spacer"></span>
            </div>
            <mat-list>
                <p *ngIf="waitingRoomData?.length === 0" style="width: 100%; text-align: center; font-weight: bold;">
                    Keine
                    Einträge vorhanden</p>
                <mat-list-item *ngFor="let item of waitingRoomData">
                    <!-- Hamburger-Menü -->
                    <button mat-icon-button [matMenuTriggerFor]="menu2" color="accent">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu2="matMenu">
                        <button mat-menu-item (click)="changeToBehandlung(item)">
                            <mat-icon>medication</mat-icon><span>In Behandlung</span>
                        </button>
                    </mat-menu>
                    <div class="item-content" (click)="changeToBehandlung(item)">
                        <h4 mat-line>{{ item.kunde }} {{ getTiernamen(item) }}</h4>
                        <p mat-line>seit {{ item?.wartezimmer_seit | date : 'HH:mm' }}. {{ item.notiz }}</p>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    <div class="card">
        <div class="column">
            <div class="card-header-sticky">
                <mat-icon>medication</mat-icon>
                <span>In Behandlung ({{inTreatmentData?.length || 0}})</span>
                <span class="spacer"></span>
            </div>
            <mat-list>
                <p *ngIf="inTreatmentData?.length === 0" style="width: 100%; text-align: center; font-weight: bold;">
                    Keine
                    Einträge vorhanden</p>
                <mat-list-item *ngFor="let item of inTreatmentData">
                    <!-- Hamburger-Menü -->
                    <button mat-icon-button [matMenuTriggerFor]="menu3" color="accent">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu3="matMenu">
                        <button mat-menu-item (click)="gotoBeleg(item)">
                            <mat-icon>description</mat-icon><span>Beleg anzeigen</span>
                        </button>
                    </mat-menu>
                    <div class="item-content" (click)="gotoBeleg(item)">
                        <h4 mat-line>{{ item.kunde }} {{ getTiernamen(item) }}</h4>
                        <p mat-line>In Behandlung seit: {{ item?.behandlung_seit | date:'HH:mm' }}</p>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    <div class="card">
        <div class="column">
            <div class="card-header-sticky">
                <mat-icon>credit_card</mat-icon>
                <span>Fertig und Abrechnen ({{readyForPaymentData?.length || 0}})</span>
                <span class="spacer"></span>
            </div>
            <mat-list>
                <p *ngIf="readyForPaymentData?.length === 0"
                    style="width: 100%; text-align: center; font-weight: bold;">Keine
                    Einträge vorhanden</p>
                <mat-list-item *ngFor="let item of readyForPaymentData">
                    <!-- Hamburger-Menü -->
                    <button mat-icon-button [matMenuTriggerFor]="menu4" color="accent">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu4="matMenu">
                        <button mat-menu-item (click)="gotoBeleg(item)">
                            <mat-icon>description</mat-icon><span>Beleg anzeigen/editieren</span>
                        </button>
                        <button mat-menu-item (click)="bezahlen(item)">
                            <mat-icon>credit_card</mat-icon><span>Rechnung bezahlen</span>
                        </button>
                    </mat-menu>
                    <div class="item-content" (click)="bezahlen(item)">
                        <h4 mat-line>{{ item.kunde }} {{ getTiernamen(item) }}</h4>
                        <p mat-line>Beleg {{ item.beleg_nr }}</p>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
</div>