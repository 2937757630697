<!-- Kopfzeile -->
<div class="one-row">
    <h1>Beleg {{ heimtierBeleg?.beleg_nr }}</h1>
    <div *ngIf="heimtierBeleg?.status_id === HeimtierBelegStatus.ABGESCHLOSSEN" style="margin: 0 0 16px 24px;">
        <span class="phito-status-badge">{{ heimtierBeleg?.status }}</span>
    </div>
    <span class="spacer"></span>
    <div class="header-details">
        <mat-form-field>
            <mat-label>Besuchsdatum</mat-label>
            <input matInput [matDatepicker]="picker" [formControl]="besuchsdatumCtrl">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Behandlung Start</mat-label>
            <input matInput type="time" [formControl]="behandlungStartCtrl">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Behandlung Ende</mat-label>
            <input matInput type="time" [formControl]="behandlungEndeCtrl">
        </mat-form-field>


        <mat-form-field>
            <mat-label>Tierarzt</mat-label>
            <mat-select [formControl]="arztCtrl">
                <mat-option *ngFor="let arzt of aerzte" [value]="arzt">
                    {{ arzt?.vorname }} {{ arzt?.nachname }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <button *ngIf="heimtierBeleg?.status_id === 221" mat-flat-button color="primary" (click)="abschliessen()"
            class="button_send" [disabled]="isDisabled()">Abschließen
    </button>


</div>

<!-- Besuchsdaten -->
<div class="one-row">

    <div style="align-self: flex-start; width: calc(100% - 200px);">
        <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChange($event)"
                       style="width: 100%;">
            <!-- Beleg -->
            <mat-tab label="Beleg">
                <kiene-besuchs-detail-beleg [heimtierBeleg]="heimtierBeleg"
                                            (validationChange)="onBelegValidationChange($event)"></kiene-besuchs-detail-beleg>
            </mat-tab>
            <mat-tab label="Klinische Untersuchung">
                <kiene-besuchs-detail-untersuchung (heimtierChange)="setCurrentHeimtier($event)"
                                                   [heimtierBeleg]="heimtierBeleg"></kiene-besuchs-detail-untersuchung>
            </mat-tab>
            <mat-tab label="Leistungen/Medikamente">
                <kiene-leistungen [heimtierBeleg]="heimtierBeleg"
                                  (tierChange)="setCurrentHeimtier($event)"></kiene-leistungen>
            </mat-tab>
            <!-- Rechnungen -->
            <mat-tab label="Letzte Rechnungen">
                <kiene-besuchs-detail-rechnungen [heimtierBeleg]="heimtierBeleg"></kiene-besuchs-detail-rechnungen>
            </mat-tab>
            <!-- Letzte Belege -->
            <mat-tab label="Letzte Belege">
                <kiene-besuchs-detail-letzte-belege
                    [heimtierBeleg]="heimtierBeleg"></kiene-besuchs-detail-letzte-belege>
            </mat-tab>
        </mat-tab-group>
    </div>


    <span class="spacer"></span>

    <!-- Besuchsinformationen -->
    <div style="align-self: flex-start; width: 200px; margin-left: 24px">
        <kiene-heimtier-info [heimtier]="heimtier"></kiene-heimtier-info>
    </div>
</div>
