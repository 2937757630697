import {
    Component,
    EventEmitter,
    Inject,
    Injectable,
    Input,
    LOCALE_ID,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {DateRange} from '@angular/material/datepicker';
import {KalenderEintrag, KalenderTyp} from '../classes/kalender-eintrag';
import {
    CalendarEvent,
    CalendarEventTitleFormatter,
    CalendarMonthViewDay,
    CalendarUtils,
    CalendarView
} from 'angular-calendar';
import {forkJoin, Observable, Subject} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import * as moment from 'moment';
import {UrlaubskalenderService} from '../urlaubskalender.service';
import {GetMonthViewArgs, MonthView} from 'calendar-utils';
import {formatDate} from '@angular/common';
import {MessageService} from '../../../services/message.service';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {KieneApi} from '../../../components/kiene-table/kiene-api';
import {KieneUtils} from '../../../kiene-utils';


@Injectable()
export class MyCalendarUtils extends CalendarUtils {
    getMonthView(args: GetMonthViewArgs): MonthView {
        args.viewStart = moment(args.viewDate).startOf('month').toDate();
        args.viewEnd = moment(args.viewDate).endOf('month').toDate();
        return super.getMonthView(args);
    }
}

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
    constructor(@Inject(LOCALE_ID) private locale: string) {
        super();
    }

    month(event: CalendarEvent): string {
        return `<b>${formatDate(event.start, 'h:m a', this.locale)}</b> ${
            event.title
        }`;
    }
}

@Component({
    selector: 'kiene-urlaubskalender-multi-month',
    templateUrl: './urlaubskalender-multi-month.component.html',
    styleUrls: ['./urlaubskalender-multi-month.component.scss'],
    providers: [
        {
            provide: CalendarUtils,
            useClass: MyCalendarUtils,
        },
        {
            provide: CalendarEventTitleFormatter,
            useClass: CustomEventTitleFormatter,
        },
    ],
})
export class UrlaubskalenderMultiMonthComponent implements OnInit, OnChanges {

    @Input('daterange')
    daterange: DateRange<Date> = new DateRange<Date>(new Date(), moment(new Date()).add(3, 'month').toDate());

    @Input('queryParams')
    queryParams: HttpParams = new HttpParams();

    initalDateRange: DateRange<Date>;

    @Output('eintragSelected')
    eintragSelected: EventEmitter<KalenderEintrag> = new EventEmitter<KalenderEintrag>();

    drawWidth: number;
    view: CalendarView = CalendarView.Month;

    events: CalendarEvent[] = [];

    refresh = new Subject<boolean>();

    lookupTable = {};
    feiertagLookUp = {};
    monthsToShow: Date[] = [];
    kalenderEvents = new Map<Date, CalendarEvent[]>();
    selectedMonth = new Date();

    constructor(private kalenderService: UrlaubskalenderService, private messageService: MessageService,
                private localStorageService: LocalStorageService) {
    }

    ngOnInit(): void {

        this.initalDateRange = this.daterange;

        this.loadData();

        this.kalenderService.watchReload().subscribe(() => {
            this.loadData();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['queryParams']) {
            if (this.queryParams.get('datum_von') && this.queryParams.get('datum_bis')) {
                this.daterange = new DateRange<Date>(new Date(this.queryParams.get('datum_von')),
                    new Date(this.queryParams.get('datum_bis')));
                this.initalDateRange = this.daterange;
            }
            this.loadData();
        }
    }

    loadKalenderEintraege(): void {
        this.events = [];
        this.refresh.next(true);

        for (let i = 0; i <= this.monthsToShow.length - 1; i++) {
            this.getKalenderEintraege(i);

        }
    }

    public reload() {
        this.loadData();
    }

    getKalenderEintraege(i: number) {
        // console.log('PARAMS ARE BEFORE: ', this.queryParams);
        this.kalenderEvents = new Map<Date, CalendarEvent[]>();

        this.queryParams = this.queryParams.set('datum_von', moment(this.monthsToShow[i]).startOf('month').format('YYYY-MM-DD'));
        this.queryParams = this.queryParams.set('datum_bis', moment(this.monthsToShow[i]).clone()
            .add(1, 'month').startOf('month').format('YYYY-MM-DD'));

        // console.log('PARAMS ARE: ', this.queryParams);

        this.kalenderService.getKalenderEintraege(this.queryParams).subscribe({
            next: (kalenderEintraege) => {
                this.generateEvents(this.monthsToShow[i], kalenderEintraege.records);
                this.refresh.next(true);
            }, error: (err) => {
                this.messageService.errorMessage('Fehler beim Laden der Kalendereinträge!', err);
            }
        });
    }

    generateEvents(forMonth: Date, eintraege: KalenderEintrag[]): void {
        this.kalenderEvents.set(forMonth, []);
        const events = [];

        // console.log('generateEvents');
        eintraege.forEach((kalenderEintrag) => {

            // add to lookuptable
            this.lookupTable[kalenderEintrag.kalendereintrag_id] = kalenderEintrag;

            if (kalenderEintrag.eintragtyp_id === 3) {
                this.feiertagLookUp[moment(kalenderEintrag.startdatum).format('DD-MM-YYYY')] = kalenderEintrag;
            } else {
                events.push(
                    {
                        id: kalenderEintrag.kalendereintrag_id,
                        eintragtyp_id: kalenderEintrag.eintragtyp_id,
                        title: kalenderEintrag?.eintragtyp_id === 4 ? kalenderEintrag.dienstgruppe : kalenderEintrag.benutzername,
                        start: new Date(kalenderEintrag.startdatum),
                        end: new Date(kalenderEintrag.enddatum),
                        cssClass: 'kalender-eintrag-row',
                        color: {
                            primary: kalenderEintrag.hintergrundfarbe,
                            secondary: kalenderEintrag.schriftfarbe
                        }
                    });
            }
        });

        this.kalenderEvents.set(forMonth, events);
        // console.log('inputs: ', forMonth, eintraege);
        // console.log('ergs:', this.kalenderEvents);
    }

    getDay(day: any) {
        return day.date.getDate();
    }

    isWeekday(day) {
        if (!!day && day.day === 6 || day.day === 0) {
            return false;
        }
        return true;
    }

    getKalenderEintrag(kalendereintrag_id: string) {
        return this.lookupTable[kalendereintrag_id];
    }

    getDescriptionForEvent(e: any): string {
        let tooltip = '';
        const kalenderEintrag: KalenderEintrag = this.getKalenderEintrag(e.id);
        if (kalenderEintrag) {
            tooltip = (kalenderEintrag.eintragtyp === KalenderTyp.Krankheit ? 'Abwesenheit' : kalenderEintrag.eintragtyp) + ': ';
            if (kalenderEintrag.startdatum && kalenderEintrag.enddatum) {
                tooltip += KieneUtils.formatDDMMYYYY(kalenderEintrag.startdatum) + ' bis ' + KieneUtils.formatDDMMYYYY(kalenderEintrag.enddatum);
            } else if (kalenderEintrag.startdatum && !kalenderEintrag.enddatum) {
                tooltip += KieneUtils.formatDDMMYYYY(kalenderEintrag.startdatum);
            }
            if (kalenderEintrag?.bemerkung) {
                tooltip += ' : ' + kalenderEintrag.bemerkung;
            }
            if (kalenderEintrag.genehmigt) {
                tooltip += kalenderEintrag.genehmigt === 1 ? ' (genehmigt)' : '';
            }

        }
        return tooltip;
    }


    loadData() {
        this.getFeiertage();
        this.generateMonthsToShow();
        this.loadKalenderEintraege();
    }

    handleEventClick(e: any) {
        if (this.localStorageService.hasPermission(this.kalenderService.kalenderRechte.antragAnlegenEditieren)) {
            const kalenderEintrag: KalenderEintrag = this.getKalenderEintrag(e.id);
            if (kalenderEintrag) {
                this.eintragSelected.emit(kalenderEintrag);
            }
        }

    }

    beforeMonthViewRender({body}: { body: CalendarMonthViewDay[] }, currentMonth: Date): void {
        body.forEach((day) => {
            day.cssClass = '';
            if (this.kalenderService.sameDates(day.date, moment(currentMonth).startOf('month').toDate())) {
                day.cssClass = 'calendar-entry-start-day';
            }
            if (this.isDayBeforeMonthRangeStart(day.date) || this.isDayAfterMonthRangeEnd(day.date)) {
                day.cssClass += ' calendar-entry-disabled';
            } else if (this.isDayOutOfCurrentMonthRange(day.date, currentMonth)) {
                day.cssClass += ' calendar-entry-hidden';
            } else if (this.kalenderService.isWeekend(day.date)) {
                day.cssClass += ' calender-entry-weekend';
            }
        });
    }

    isDayOutOfCurrentMonthRange(candidate: Date, month: Date): boolean {
        const monthStart = moment(month).startOf('month');
        const monthEnd = moment(month).endOf('month');
        return moment(candidate).isBefore(monthStart) || moment(candidate).isAfter(monthEnd);
    }

    isDayBeforeMonthRangeStart(date: Date): boolean {
        return moment(date).isBefore(moment(this.daterange.start).clone().startOf('month'));
    }

    isDayAfterMonthRangeEnd(date: Date): boolean {
        return moment(date).isAfter(moment(this.daterange.end).clone().endOf('month'));

    }

    shiftPrevMonth() {
        const start = moment(this.daterange.start).clone().subtract(1, 'month').startOf('month').toDate();
        const end = moment(this.daterange.end).clone().subtract(1, 'month').startOf('month').toDate();
        this.updateDateRange(start, end);
    }

    shiftNextMonth() {
        const start = moment(this.daterange.start).clone().add(1, 'month').startOf('month').toDate();
        const end = moment(this.daterange.end).clone().add(1, 'month').startOf('month').toDate();
        this.updateDateRange(start, end);
    }

    updateDateRange(start: Date, end: Date) {
        this.daterange = new DateRange<Date>(start, end);
        this.loadData();
    }

    shiftToToday() {
        this.updateDateRange(this.initalDateRange.start, this.initalDateRange.end);
    }

    getTitle(e: any, day: any) {
        const kalenderEintrag: KalenderEintrag = this.getKalenderEintrag(e.id);
        if (kalenderEintrag?.eintragtyp_id === 4) {
            if (kalenderEintrag.tieraerzte?.length > 0) {
                let txt = kalenderEintrag.dienstgruppe + ': ';
                for (const t of kalenderEintrag.tieraerzte) {
                    txt += t.vorname + ' ' + t.nachname + ', ';
                }
                if (txt.length > 2) {
                    txt = txt.substring(0, txt.length - 2);
                }
                return txt;
            }
            return kalenderEintrag.dienstgruppe;
        }
        if (kalenderEintrag) {
            if (this.isHalbtagsUrlaubs(day.date, kalenderEintrag)) {
                return e.title + ' (halbtags)';
            }
        }
        return e.title;
    }

    isHalbtagsUrlaubs(candidate: Date, kalenderEintrag: KalenderEintrag) {
        return (moment(new Date(candidate)).isSame(kalenderEintrag.startdatum, 'day') && kalenderEintrag.startdatum_halbtags === 1) ||
            (moment(new Date(candidate)).isSame(kalenderEintrag.enddatum, 'day') && kalenderEintrag.enddatum_halbtags === 1);
    }

    getFeiertag(day: any) {
        if (this.feiertagLookUp) {
            const found = this.feiertagLookUp[moment(day.date).format('DD-MM-YYYY')];
            if (found?.bemerkung) {
                return found?.bemerkung;
            }
            return found ? found.titel : '';
        }
    }

    getBackgroundColor(e: any, day: any) {
        const kalenderEintrag: KalenderEintrag = this.getKalenderEintrag(e.id);
        if (kalenderEintrag?.eintragtyp_id === 4) {
            return kalenderEintrag.dienstgruppe_hintergrundfarbe;
        }
        return e.color.primary;
    }

    getColor(e: any, day: any) {
        const kalenderEintrag: KalenderEintrag = this.getKalenderEintrag(e.id);
        if (kalenderEintrag?.eintragtyp_id === 4) {
            return kalenderEintrag.dienstgruppe_farbe;
        }
        return e.color.secondary;
    }

    private getFeiertage() {
        const yearsToLoad = [];
        const fromDate = moment(this.daterange.start);
        const toDate = moment(this.daterange.end);
        while (toDate > fromDate || fromDate.format('Y') === toDate.format('Y')) {
            const month = fromDate.year();
            yearsToLoad.push(month);
            fromDate.add(1, 'year');
        }

        const observables: Observable<KieneApi>[] = [];
        yearsToLoad.forEach(year => {
            observables.push(this.kalenderService.loadFeiertage(moment(year, '01-01-YYYY').toDate()));
        });
        forkJoin(observables).subscribe({
            next: resp => {
                resp.forEach(result => {
                    // this.feiertage = this.feiertage.concat(<KalenderEintrag[]>result.records);
                    result.records.forEach((record: KalenderEintrag) => {
                        this.feiertagLookUp[moment(record.startdatum).format('DD-MM-YYYY')] = record;
                    });
                });
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Laden der Feiertage!', err);
            }
        });
    }

    private generateMonthsToShow() {
        this.monthsToShow = [];
        const fromDate = moment(this.daterange.start);
        const toDate = moment(this.daterange.end);
        while (toDate > fromDate || fromDate.format('M') === toDate.format('M')) {
            const month = fromDate.startOf('month').toDate();
            this.monthsToShow.push(month);
            fromDate.add(1, 'month');
        }
        this.drawWidth = 100 / this.monthsToShow.length;
    }
}
