import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild,} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UrlaubskalenderService} from '../urlaubskalender.service';
import {KalenderEintrag} from '../classes/kalender-eintrag';
import {MessageService} from '../../../services/message.service';
import {MatDateRangeInput} from '@angular/material/datepicker';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
    selector: 'kiene-urlaubsantrag-genehmigen-dialog',
    templateUrl: './urlaubsantrag-genehmigen-dialog.component.html',
    styleUrls: ['./urlaubsantrag-genehmigen-dialog.component.scss'],
})
export class UrlaubsantragGenehmigenDialogComponent
    implements OnInit, AfterViewInit {
    offeneUrlaubsantraege: KalenderEintrag[] = [];
    reload = true;
    hasUrlaubGenehmigenPermission = false;
    isLoading = true;

    changed = false;

    @ViewChild('zeitraum') zeitraum: MatDateRangeInput<Date>;
    @ViewChild('content') contentPage: ElementRef;

    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        public dialogRef: MatDialogRef<UrlaubsantragGenehmigenDialogComponent>,
        private kalenderService: UrlaubskalenderService,
        private messageService: MessageService
    ) {
        if (data.offenerUrlaubsantrag) {
            this.reload = false;
            this.isLoading = false;
            this.offeneUrlaubsantraege.push(data.offenerUrlaubsantrag);
        } else {
            this.loadOffeneUrlaubsantraege();
        }
    }

    ngAfterViewInit(): void {
        this.contentPage.nativeElement.click();
    }

    ngOnInit() {
        this.hasUrlaubGenehmigenPermission =
            this.kalenderService.hasUrlaubGenehmigenPermission();
    }

    loadOffeneUrlaubsantraege() {
        this.isLoading = true;
        this.kalenderService.getOffeneUrlaubsantraege().subscribe({
            next: (data) => {
                this.isLoading = false;
                this.offeneUrlaubsantraege = data.records;
            },
            error: (err) => {
                this.isLoading = false;
                this.messageService.errorMessage('Fehler beim Laden der offenen Urlaubsanträge!', err);
            },
        });
    }

    update(antrag: KalenderEintrag) {
        this.kalenderService.createOrUpdate(antrag).subscribe({
            next: response => {
                this.changed = false;
                this.kalenderService.triggerReload();
                this.messageService.infoMessage('Änderungen wurden gespeichert!');
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    updateAntrag(urlaubsantrag: KalenderEintrag, genehmigt?: number) {
        urlaubsantrag.genehmigt = genehmigt;
        this.kalenderService.createOrUpdate(urlaubsantrag).subscribe({
            next: () => {
                this.changed = false;
                if (this.hasUrlaubGenehmigenPermission) {
                    genehmigt === 1
                        ? this.messageService.infoMessage('Urlaubsantrag genehmigt')
                        : this.messageService.infoMessage(
                            'Urlaubsantrag abgelehnt'
                        );
                } else {
                    this.messageService.infoMessage('Urlaubsantrag aktualisiert');
                }

                if (this.reload) {
                    this.loadOffeneUrlaubsantraege();
                    this.kalenderService.triggerReload();
                } else {
                    this.dialogRef.close(urlaubsantrag);
                }
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Fehler beim Ändern des Urlaubsantrags',
                    err
                );
            },
        });
    }

    onNoClick() {
        this.dialogRef.close(null);
    }

    deleteAntrag(urlaubsantrag: KalenderEintrag) {
        this.kalenderService.delete(urlaubsantrag).subscribe({
            next: () => {
                this.messageService.infoMessage('Urlaubsantrag gelöscht');
                this.dialogRef.close(urlaubsantrag);
            },
        });
    }

    startDatumChanged($event, urlaubsantrag: KalenderEintrag) {
        if ($event.value) {
            urlaubsantrag.startdatum = $event.value;
            this.changed = true;
        }
    }

    endDatumChanged($event, urlaubsantrag: KalenderEintrag) {
        if ($event.value) {
            urlaubsantrag.enddatum = $event.value;
            this.changed = true;
        }
    }

    sameDates(value, value2) {
        return this.kalenderService.sameDates(value, value2);
    }

    updateHalbtags(event: MatCheckboxChange, antrag: KalenderEintrag) {
        event.checked ? antrag.startdatum_halbtags = 1 : antrag.startdatum_halbtags = 0;
        event.checked ? antrag.enddatum_halbtags = 1 : antrag.enddatum_halbtags = 0;
        this.changed = true;
    }
}
