import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InitialSetupService {

  hasHitLoggedIn = false;

  constructor() { }

  setHasHitLoggedIn(b: boolean) {
    this.hasHitLoggedIn = b;
  }

  getHasHitLoggedIn() {
    return this.hasHitLoggedIn;
  }



}
