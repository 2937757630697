import { KieneKeyValue } from "../../../../../services/backend-api/classes/kiene-key-value";

export class TierFilter extends KieneKeyValue {
    static ALLE_TIERE = new TierFilter("Alle Tiere", null, null);
    static TOTE_TIERE = new TierFilter("Tote Tiere", "tot", "1");
    static LEBENDE_TIERE = new TierFilter("Lebende Tiere", "tot", "0");
    static LEBENDE_TIERE_NICHT_ABGEGANGEN = new TierFilter(
        "Lebende Tiere nicht ausgestallt",
        "lebendig_nicht_abgegangen",
        "1"
    );
    static TIERE_HIT_FEHLER = new TierFilter(
        "Tiere mit HIT Fehler",
        "hit_fehler",
        "1"
    );
    static TIERE_SCAN_UNVOLLSTAENDIG = new TierFilter(
        "Tiere Scan unvollständig",
        "scan_fehlt",
        "1"
    );
    static PAESSE_OHNE_TIER = new TierFilter(
        "Pässe ohne Tier",
        "paesse_ohne_tier",
        "1"
    );
    static TIERE_OHNE_PASS = new TierFilter(
        "Tiere ohne Pass",
        "tiere_ohne_pass",
        "1"
    );
    static TIERE_MIT_REKLAMATION = new TierFilter(
        "Tiere mit Reklamation",
        "mit_reklamation",
        "1"
    );
    static TIERE_OHNE_REKLAMATION = new TierFilter(
        "Tiere ohne Reklamation",
        "mit_reklamation",
        "0"
    );
    static TIERE_MIT_BEMERKUNG = new TierFilter(
        "Tiere mit Bemerkungen",
        "mit_bemerkung",
        "1"
    );
    static TIERE_OHNE_BEMERKUNG = new TierFilter(
        "Tiere ohne Bemerkungen",
        "mit_bemerkung",
        "0"
    );
    static TIERE_MIT_ABTEIL = new TierFilter(
        "Tiere mit Abteil",
        "mit_abteil",
        "1"
    );
    static TIERE_OHNE_ABTEIL = new TierFilter(
        "Tiere ohne Abteil",
        "mit_abteil",
        "0"
    );
    static TIERE_MIT_TRANSPONDERNR = new TierFilter(
        "Tiere mit Transpondernr.",
        "mit_transpondernummer",
        "1"
    );
    static TIERE_OHNE_TRANSPONDERNR = new TierFilter(
        "Tiere ohne Transpondernr.",
        "mit_transpondernummer",
        "0"
    );

    name: string;
    constructor(name: string, key: string, value: any) {
        super(key, value);
        this.name = name;
    }

    static getAvailableFilter() {
        return [
            this.ALLE_TIERE,
            this.TOTE_TIERE,
            this.LEBENDE_TIERE,
            this.LEBENDE_TIERE_NICHT_ABGEGANGEN,
            this.TIERE_HIT_FEHLER,
            this.TIERE_SCAN_UNVOLLSTAENDIG,
            this.PAESSE_OHNE_TIER,
            this.TIERE_OHNE_PASS,
            this.TIERE_MIT_REKLAMATION,
            this.TIERE_OHNE_REKLAMATION,
            this.TIERE_MIT_BEMERKUNG,
            this.TIERE_OHNE_BEMERKUNG,
            this.TIERE_MIT_ABTEIL,
            this.TIERE_OHNE_ABTEIL,
            this.TIERE_MIT_TRANSPONDERNR,
            this.TIERE_OHNE_TRANSPONDERNR,
        ];
    }
}
