<h1 mat-dialog-title>Dokumententyp auswählen</h1>
<div mat-dialog-content>
    <p>Bitte wählen Sie den entsprechenden Dokumententyp für die Dateien aus.</p>
    <div class="one-row" style="margin-bottom: 32px;">
        <button (click)="target('deliveryNote')" color="accent" mat-stroked-button style="width: 200px;">
            <mat-icon>local_shipping</mat-icon>
            <span>&nbsp;Lieferschein</span>
        </button>
        <span class="spacer"></span>
        <button (click)="target('invoice')" *kienePermission="[1751]" color="accent" mat-stroked-button
                style="width: 200px;">
            <mat-icon>euro_symbol</mat-icon>
            <span>&nbsp;Rechnung</span>
        </button>
        <span class="spacer"></span>
        <button (click)="target('credit')" *kienePermission="[4102]" color="accent" mat-stroked-button
                style="width: 200px;">
            <mat-icon>money</mat-icon>
            <span>&nbsp;Gutschrift</span>
        </button>

        <!--        <mat-card (click)="target('deliveryNote')" style="width: 30%; cursor: pointer;">-->
        <!--            <div-->
        <!--                style="width: 100%; display: flex; align-items: center; justify-content: center; align-content: space-between;">-->
        <!--                <div>-->
        <!--                    <mat-icon style="transform: scale(2)">local_shipping</mat-icon>-->
        <!--                    <p>Lieferschein</p>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </mat-card>-->
        <!--        <span class="spacer"></span>-->
        <!--        <mat-card (click)="target('invoice')" *kienePermission="[1751]" style="width: 30%; cursor: pointer;">-->
        <!--            <div-->
        <!--                style="width: 100%; display: flex; align-items: center; justify-content: center; align-content: space-between;">-->
        <!--                <div>-->
        <!--                    <mat-icon style="transform: scale(2)">euro_symbol</mat-icon>-->
        <!--                    <p>Rechnung</p>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </mat-card>-->
        <!--        <span class="spacer"></span>-->
        <!--        <mat-card (click)="target('credit')" *kienePermission="[4102]" style="width: 30%; cursor: pointer;">-->
        <!--            <div-->
        <!--                style="width: 100%; display: flex; align-items: center; justify-content: center; align-content: space-between;">-->
        <!--                <div>-->
        <!--                    <mat-icon style="transform: scale(2)">money</mat-icon>-->
        <!--                    <p>Gutschrift</p>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </mat-card>-->
    </div>
</div>
