<div mat-dialog-content>
    <kiene-kiene-table [tableDescriptor]="tableDescriptor"></kiene-kiene-table>
    <div *ngIf="abholliste">
        <div class="info-box">
        <span>
            <p>Die Abholung ist für den <b>{{ abholdatumFormatted | date: 'dd.MM.yyyy' }}</b> vorgesehen.</p>
            <p>Aktueller Status: <b>{{ abholliste.status }}.</b></p>
        </span>
            <span class="spacer"></span>
        </div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-button color="accent" (click)="dialogRef.close()">Schließen</button>
</mat-dialog-actions>
