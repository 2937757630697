<h1 mat-dialog-title>Abgabebelege Vorlagen</h1>
<div mat-dialog-content class="vorlagen">
    <div class="one-row">
        <div class="left-column">
            <ul style="list-style-type: none">
                <li style="margin-bottom: 24px;">
                    <button mat-stroked-button color="accent" (click)="neueVorlageErstellen()">
                        <mat-icon>add</mat-icon>
                        Neue Vorlage erstellen
                    </button>
                </li>
                <li *ngFor="let al of artikellisten">
                    <div class="one-row kiene-inline-link"
                         style="text-decoration: underline; cursor: pointer;">
                        <mat-icon>play_circle_outline</mat-icon>
                        <span style="margin-left: 6px;">{{al?.listenname}}</span>
                        <span class="spacer"></span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="right-column">
            <h3>{{ausgewaehlteArtikelliste?.listenname}}</h3>
            <ol>
                <li *ngFor="let artikel of ausgewaehlteArtikelliste?.artikel"
                    style="border-bottom: 1px solid #cccccc; padding-bottom: 12px;">
                    <h4>{{artikel?.artikel_me}}</h4>
                    <table>
                        <tr>
                            <td>Tierart:</td>
                            <td>{{artikel?.tierart}}</td>
                        </tr>
                        <tr>
                            <td>Anwendung:</td>
                            <td>{{artikel?.anwendung}}</td>
                        </tr>
                        <tr>
                            <td>Diagnose:</td>
                            <td>{{artikel?.diagnose}}</td>
                        </tr>
                        <tr>
                            <td>Anwendungsdauer:</td>
                            <td>{{artikel?.anwendungsdauer}}</td>
                        </tr>
                        <tr>
                            <td>K&ouml;rpergewicht:</td>
                            <td>{{artikel?.koerpergewicht_tierart}} kg</td>
                        </tr>
                        <tr>
                            <td>Dosierung:</td>
                            <td>{{artikel?.dosierung}}</td>
                        </tr>
                        <tr>
                            <td>Behandlung:</td>
                            <td>{{artikel?.dosierung}}</td>
                        </tr>
                    </table>
                </li>

            </ol>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="dialogRef.close()">Schlie&szlig;en</button>
</div>
