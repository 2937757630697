<h1 mat-dialog-title>Gutschrift {{gutschrift ? 'editieren' : 'hinzufügen'}}</h1>
<div mat-dialog-content>
    <kiene-autocomplete #lieferantAuto (elementSelected)="supplierSelected($event)" [apiUrl]="'lieferant/'"
                        [disabled]="!lieferantFocus"
                        [focus]="false" [kieneObject]="lieferant" [placeholder]="'Lieferant'"
                        [presetSelected]="false"></kiene-autocomplete>
    <kiene-article-scan-autocomplete (selectedMaterial)="materialSelected($event)" [params]="artikelParams"
                                     [placeholder]="'Artikel'"
                                     [presetMaterial]="material"></kiene-article-scan-autocomplete>
    <!--    <kiene-autocomplete #artikelAuto (elementSelected)="materialSelected($event)" [apiUrl]="'artikel/'" [focus]="false"-->
    <!--                        [kieneObject]="material" [params]="artikelParams"-->
    <!--                        [placeholder]="'Artikel'"></kiene-autocomplete>-->
    <mat-form-field style="width: 100%;">
        <mat-label>Betrag (netto) in Euro</mat-label>
        <input #betragInput [formControl]="betragCtrl" matInput min="0" type="number">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>MWST</mat-label>
        <mat-select [formControl]="mwstsatzCtrl">
            <mat-option [value]="7">7%</mat-option>
            <mat-option [value]="19">19%</mat-option>
            <mat-option [value]="0">0%</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Gutschrift Nr.</mat-label>
        <input [formControl]="gutschriftNrCtrl" matInput>
    </mat-form-field>
    <mat-form-field style="width: 100%">
        <mat-label>Gutschriftdatum</mat-label>
        <input [formControl]="datumCtrl" [matDatepicker]="picker" matInput placeholder="Datum Gutschrift">
        <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <button (click)="openFileSelector()" color="accent" mat-flat-button>Gutschrift als PDF hinzuf&uuml;gen</button>
    <input #fileSelector (change)="handleFiles($event)" accept="application/pdf" style="display: none" type="file">

    <div *ngIf="filesToUpload.length > 0">
        <mat-list>
            <h4 mat-subheader>Dateien</h4>
            <div *ngFor="let file of filesToUpload; let i = index" style="width: 100%;">
                <div class="one-row" style="margin-bottom: 12px;">
                    <mat-icon mat-list-icon style="width: 70px;">insert_drive_file</mat-icon>
                    <div style="min-width: 40%;">{{file.name}}</div>
                    <mat-icon *ngIf="filesUploadStatus[i]==1" color="primary">check</mat-icon>
                    <mat-icon *ngIf="filesUploadStatus[i]==2" color="warn">warning</mat-icon>
                    <span class="spacer"></span>
                    <mat-progress-spinner [diameter]="30" [value]="filesProgressValues[i]" mode="determinate">
                    </mat-progress-spinner>
                </div>
            </div>
        </mat-list>
    </div>

</div>
<div mat-dialog-actions>
    <button (click)="close()" mat-button>Abbrechen</button>
    <button (click)="save()" [disabled]="!lieferant || !betragCtrl.valid" color="accent" mat-flat-button>Gutschrift
        Speichern
    </button>
</div>
