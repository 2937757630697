import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

@Component({
    selector: 'kiene-key-value-list-item',
    templateUrl: './key-value-list-item.component.html',
    styleUrls: ['./key-value-list-item.component.scss']
})
export class KeyValueListItemComponent implements OnInit, OnChanges {

    @Input('key') key = '';
    @Input('value') value: any;
    @Input('icon') icon: string;
    @Input('bold') bold = false;
    @Input('actionButtonIcon') actionButtonIcon: string;
    @Input('actionButtonDisabled') actionButtonDisabled = false;
    @Output('actionClicked') actionClickedEvent = new EventEmitter<Event>();

    constructor() {
    }

    ngOnInit(): void {
        if (!this.value) {
            this.value = '---';
        }
    }

    ngOnChanges() {
        if (!this.value) {
            this.value = '---';
        }
    }

    actionClicked(event: Event) {
        this.actionClickedEvent.emit(event);
    }
}
