import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { API_BASE_URL_SERVICE } from "../../../../../projects/kiene-core/src/lib/kiene-core.config";
import { HeimtierKalenderEintrag } from "../../kalender/heimtier-kalender-eintrag";

@Component({
    selector: 'kiene-notiz-dialog',
    templateUrl: './notiz-dialog.component.html',
    styleUrls: ['./notiz-dialog.component.scss']
})
export class NotizDialogComponent implements OnInit {
    public notizCtrl: FormControl = new FormControl();

    constructor(public dialogRef: MatDialogRef<NotizDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public kalenderEintrag: HeimtierKalenderEintrag,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
    ) { }

    ngOnInit(): void {
        this.notizCtrl.setValue(this.kalenderEintrag?.notiz);
    }

    public isDisabled(): boolean {
        return !this.notizCtrl.valid;
    }

    public save(): void {
        this.kalenderEintrag.notiz = this.notizCtrl.value;
        this.dialogRef.close(this.kalenderEintrag);
    }

    public cancel(): void {
        this.dialogRef.close();
    }
}
