<div class="mat-typography lieferschein">
    <form *ngIf="(form == 'landwirt') || (form == 'fahrer')" (ngSubmit)="updateLieferscheinLandwirtFahrer()" [formGroup]="lieferantFormular">
        <h1>Lieferschein</h1>

        <mat-form-field shouldLabelFloat="true">
            <mat-label>Geburtsland</mat-label>
            <input [formControl]="formGeburtsland" matInput type=""/>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Vlog ID</mat-label>
            <input [formControl]="formVlogID" matInput type=""/>
        </mat-form-field>

        <mat-form-field>
            <mat-label>VVVONr. Ferkelerzeuger</mat-label>
            <input [formControl]="formVVVONr" matInput type=""/>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Futtermittellieferant</mat-label>
            <input [formControl]="formFuttermittellieferant" matInput type=""/>
        </mat-form-field>

        <div class="fullwidth">
            <mat-radio-group [formControl]="formHaltungsbedingungen" formControlName="formHaltungsbedingungen" class="radio-group">
                <mat-label>Bei Schweine haltenden Betrieben amtlich anerkannte Anwendung kontrollierter Haltungsbedingungen.</mat-label>
                <br>
                <mat-radio-button [value]="1" class="radio-button">Ja</mat-radio-button>
                <mat-radio-button [value]="2" class="radio-button">Nein</mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="">
            <mat-radio-group [formControl]="formTierarzneimittelWartezeiten" class="radio-group flex-grid flex-column">
                <mat-label>Im Zeitraum von 7 Tagen vor Verbringung der Tiere zur Schlachtung bestanden</mat-label>
                <mat-radio-button [value]="1" class="radio-button">keine Wartezeiten für verabreichte Tierarzneimittel</mat-radio-button>
                <mat-radio-button [value]="2" class="radio-button">Wartezeiten für folgende Tierarzneimittel</mat-radio-button>
            </mat-radio-group>

            <table *ngIf="formTierarzneimittelWartezeiten.value == 2">
                <tr>
                    <th>Tier (Kennzeichnung)</th>
                    <th>Tierarzneimittel</th>
                    <th>Wartezeit</th>
                    <th>Datum der Verabreichung</th>
                </tr>
                <tr>
                    <td>
                        <mat-form-field>
                            <input [formControl]="formTierKennzeichung" matInput type=""/>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field>
                            <input [formControl]="formTierarzneimittel" matInput type=""/>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field>
                            <input [formControl]="formWartezeit" matInput type=""/>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field>
                            <input [matDatepicker]="datePicker" [formControl]="formDatumDerVerabreichung" matInput type=""/>
                        </mat-form-field>
                        
                        <mat-datepicker #datePicker></mat-datepicker>
                    </td>
                </tr>
            </table>
        </div>

        <div class="">
            <p>Im Zeitraum von 7 Tagen vor Verbringung der Tiere zur Schlachtung bestanden keine Wartezeiten für verabreichte Tierarzneimittel und wurden keine sonstigen Behandlungen durchgeführt, ausgenommen.</p>
            <mat-form-field>
                <mat-label>Ausnahme(n)</mat-label>
                <input [formControl]="formAusnahmen" matInput type=""/>
            </mat-form-field>
        </div>

        <div>
            <p>Es liegen keine Ergebnisse von Probenanalysen vor, die für den Schutz der öffentlichen Gesundheit von Bedeutung sind, ausgenommen</p>
            <mat-form-field>
                <mat-label>Ausnahme(n)</mat-label>
                <input [formControl]="formProbenanalysen" matInput type=""/>
            </mat-form-field>
        </div>

        <h2>Name und Anschrift des privaten, normalerweise hinzugezogenen Tierarztes:</h2>
        <div class="grid grid-2-columns">
            <mat-form-field>
                <mat-label>Name:</mat-label>
                <input [formControl]="formName" matInput type=""/>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Tel:</mat-label>
                <input [formControl]="formTelephone" matInput type=""/>
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>Adresse:</mat-label>
                <input [formControl]="formAdresse" matInput type=""/>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Fax:</mat-label>
                <input [formControl]="formFax" matInput type=""/>
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>PLZ und Ort:</mat-label>
                <input [formControl]="formPlzOrt" matInput type=""/>
            </mat-form-field>
        </div>
    
        <mat-radio-group [formControl]="formTetracycline" class="radio-group">
            <mat-label>Tetracycline</mat-label>
            <mat-radio-button [value]="1" class="radio-button">Ja</mat-radio-button>
            <mat-radio-button [value]="2" class="radio-button">Nein</mat-radio-button>
        </mat-radio-group>

        <mat-radio-group [formControl]="formHerkunftsnachweis" (change)="updateValidationHerkunft($event)" class="radio-group herkunft">
            <mat-label>Herkunftsnachweis</mat-label>
            <br>
            <div class="grid grid-3-columns">
                <mat-radio-button [value]="1" class="radio-button">Geboren und aufgezogen in Deutschland</mat-radio-button>
                <mat-radio-button [value]="2" class="radio-button">Aufgezogen in Deutschland</mat-radio-button>
                <div class="aufgezogen"><mat-radio-button [value]="3" class="radio-button">Aufgezogen in</mat-radio-button><input [formControl]="formAufgezogenIn"></div>
                <mat-radio-button [value]="4" class="radio-button">Geboren in Deutschland andere(s)</mat-radio-button>
                <mat-radio-button [value]="5" class="radio-button">Geboren in Deutschland Brandenburg</mat-radio-button>
                <mat-radio-button [value]="6" class="radio-button">Geboren in Deutschland Sachsen</mat-radio-button>
            </div>
        </mat-radio-group>

        <mat-form-field>
            <mat-label>Ort</mat-label>
            <input [formControl]="formOrt" matInput type=""/>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Datum</mat-label>
            <input [formControl]="formDatum" matInput type=""/>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Menge</mat-label>
            <input [formControl]="formMenge" matInput type="number"/>
        </mat-form-field>

        <mat-form-field shouldLabelFloat="true">
            <mat-label>Gewicht</mat-label>
            <input [formControl]="formGewicht" matInput type="number"/>
        </mat-form-field>

        <mat-form-field>
            <mat-label>ø Gewicht</mat-label>
            <input [formControl]="formGewichtDurchschnitt" matInput type="number"/>
        </mat-form-field>

        <div class="mat-unterschrift" [ngClass]="{'invalid': formUnterschriftFahrer.valid}">
            <b>Unterschrift Fahrer</b>
            <kiene-signature-lieferschein-panel #panelFahrer (saved)="saveSignatureFahrer($event)"></kiene-signature-lieferschein-panel>
        </div>

        <div class="mat-unterschrift" [ngClass]="{'invalid': formUnterschriftLandwirt.valid}">
            <b>Unterschrift Landwirt</b>
            <kiene-signature-lieferschein-panel #panelLandwirt (saved)="saveSignatureLandwirt($event)"></kiene-signature-lieferschein-panel>
        </div>

        <button class="submit-btn" type="submit">Fertig</button>

    </form>
    
    <form *ngIf="form == 'schlachthof'" (ngSubmit)="updateLieferscheinSchlachthof()" [formGroup]="schlachthofFormular">
        <h1>Lieferschein</h1>

        <mat-form-field>
            <mat-label>Datum</mat-label>
            <input  [formControl]="formDatum" #datePicker2 matInput type="datetime-local"/>
            <!-- <input [matDatepicker]="datePicker2" [formControl]="formDatum" matInput type="datetime-local"/>
            <mat-datepicker-toggle [for]="datePicker2" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #datePicker2></mat-datepicker>  -->
        </mat-form-field>

        <mat-form-field shouldLabelFloat="true">
            <mat-label>Stückzahl Gesamt</mat-label>
            <input [formControl]="formSchlachtGesamt" matInput type="number"/>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Tote Tiere LKW</mat-label>
            <input [formControl]="formSchlachtToteTiere" matInput type="number"/>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Tote Tiere LKW</mat-label>
            <input [formControl]="formSchlachtToteTiere2" matInput type="number"/>
        </mat-form-field>

        <div class="mat-unterschrift" [ngClass]="{'invalid': formUnterschrift.valid}">
            <kiene-signature-lieferschein-panel #panelSchlachthof (saved)="saveSignature($event)"></kiene-signature-lieferschein-panel>
        </div>

        <button class="submit-btn" type="submit">Fertig</button>

    </form>
</div>