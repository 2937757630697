<div style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 100%;
    ">
    <div class="one-row" style="height: 70px">
        <span class="spacer"></span>
        <p [style.borderColor]="checkBorderColor" [style.borderStyle]="keinErgebnis ? 'solid' : 'dashed'"
            [style.fontSize]="fontSize" style="
                width: 95%;
                text-align: center;
                font-family: monospace;
                max-width: 95%;
                overflow-wrap: break-word;
                border: 6px solid;
                border-radius: 5px;
                padding: 8px;
            ">
            {{ numbers }}
        </p>
        <span class="spacer"></span>
    </div>

    <div class="one-row" style="margin-bottom: 5px; min-height: 20px">
        <span class="spacer"></span>
        <div style="width: 95%; text-align: center">
            <span *ngIf="ohrmarken.length > 0 && !ohrmarkeGefunden">{{ ohrmarken?.length }} Tiere gefunden</span>
            <span *ngIf="ohrmarkeGefunden">{{
                getFirstTenDigits(ohrmarke)
                }}</span>
            <span *ngIf="ohrmarkeGefunden" style="font-weight: bold">{{
                getLastFiveDigits(ohrmarke)
                }}</span>
            <span *ngIf="ohrmarkeGefunden && numbers?.length < 5">&nbsp;(mind. 5 Ziffern eingeben)</span>
        </div>
        <span class="spacer"></span>
    </div>

    <!-- <div style="flex-grow: 1;"></div> -->

    <div>
        <div class="one-row" style="margin-bottom: 6px">
            <span class="spacer"></span>
            <button (click)="addNumber(1)" [disabled]="ohrmarkeGefunden && numbers.length > 4" mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                ">
                1
            </button>
            <div style="width: 12px"></div>
            <button (click)="addNumber(2)" [disabled]="ohrmarkeGefunden && numbers.length > 4" mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                ">
                2
            </button>
            <div style="width: 12px"></div>
            <button (click)="addNumber(3)" [disabled]="ohrmarkeGefunden && numbers.length > 4" mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                ">
                3
            </button>
            <span class="spacer"></span>
        </div>
        <div class="one-row" style="margin-bottom: 6px">
            <span class="spacer"></span>
            <button (click)="addNumber(4)" [disabled]="ohrmarkeGefunden && numbers.length > 4" mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                ">
                4
            </button>
            <div style="width: 12px"></div>
            <button (click)="addNumber(5)" [disabled]="ohrmarkeGefunden && numbers.length > 4" mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                ">
                5
            </button>
            <div style="width: 12px"></div>
            <button (click)="addNumber(6)" [disabled]="ohrmarkeGefunden && numbers.length > 4" mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                ">
                6
            </button>
            <span class="spacer"></span>
        </div>
        <div class="one-row" style="margin-bottom: 6px">
            <span class="spacer"></span>
            <button (click)="addNumber(7)" [disabled]="ohrmarkeGefunden && numbers.length > 4" mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                ">
                7
            </button>
            <div style="width: 12px"></div>
            <button (click)="addNumber(8)" [disabled]="ohrmarkeGefunden && numbers.length > 4" mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                ">
                8
            </button>
            <div style="width: 12px"></div>
            <button (click)="addNumber(9)" [disabled]="ohrmarkeGefunden && numbers.length > 4" mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                ">
                9
            </button>
            <span class="spacer"></span>
        </div>
        <div class="one-row" style="margin-bottom: 6px">
            <span class="spacer"></span>
            <div *ngIf="
                    (!ohrmarkeGefunden && !keinErgebnis) || numbers.length < 5
                " style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                    background-color: lightgray;
                "></div>

            <button (click)="clear()" *ngIf="keinErgebnis && numbers.length > 4" mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                    background-color: red;
                ">
                <mat-icon [style.color]="'white'">clear</mat-icon>
            </button>
            <button (click)="check()" *ngIf="ohrmarkeGefunden && numbers.length > 4"
                [style.backgroundColor]="checkBackgroundColor" mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                ">
                <mat-icon [style.color]="checkColor">{{ checkIcon }}</mat-icon>
            </button>
            <div style="width: 12px"></div>
            <button (click)="addNumber(0)" *ngIf="
                    !ohrmarkeGefunden ||
                    numbers.length < 5 ||
                    selectedTier.tierliste_id
                " [disabled]="ohrmarkeGefunden && numbers.length > 4" mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                ">
                0
            </button>
            <button (click)="openWeightDailog()" *ngIf="
                    ohrmarkeGefunden &&
                    numbers.length > 4 &&
                    !selectedTier.tierliste_id
                " mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: orange;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                ">
                <mat-icon>playlist_add_check</mat-icon>
            </button>
            <div style="width: 12px"></div>
            <button (click)="backspace()" mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                ">
                <mat-icon>backspace</mat-icon>
            </button>
            <span class="spacer"></span>
        </div>
    </div>

    <div id="gescannteOhrenmarken" style="min-height: 50%; margin-left: 12px; padding-bottom: 240px">
        <h3>Zuletzt gescannt:</h3>
        <p *ngIf="gescannteOhrmarken?.length < 1">---</p>
        <ul style="
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    font-family: 'Courier New', sans-serif;
                ">
            <li *ngFor="let ohrmarke of gescannteOhrmarken" class="ohrenmarke-list-item">
                <div class="one-row">
                    <span class="spacer"></span>
                    <mat-icon [style.color]="
                                    ohrmarke.success ? 'green' : '#9b0024'
                                ">{{ohrmarke.success ? "check" : "error"}}</mat-icon>
                    <span class="spacer"></span>
                    <div class="ohrenmarke-list-item--text">
                        <span>
                            <kiene-ohrmarke [ohrmarke]="ohrmarke.ohrmarke" style="font-size: 0.8em">
                            </kiene-ohrmarke>
                        </span>
                    </div>
                    <span class="spacer"></span>
                </div>
            </li>
        </ul>
    </div>
</div>