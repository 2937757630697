<kiene-kiene-table [tableDescriptor]="tableDescriptor" (optionClicked)="catchOptionEvent($event)"></kiene-kiene-table>
<!-- <div class="kiene-content-page">
    <mat-card>

        <div *ngIf="isLoading" class="kiene-loading-spinner">
            <mat-progress-spinner *ngIf="isLoading"></mat-progress-spinner>
          </div>

      <div class="kiene-table-header">
        <h3>Tier&auml;rzte</h3>
        <button mat-icon-button *kienePermission="[1252]" (click)="openAddVetDialog()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
  
      <div>
        <mat-form-field style="width: 100%;">
          <input matInput placeholder="Suchen" #searchInput>
        </mat-form-field>
      </div>
  
      <mat-table #table [dataSource]="vetDataSource" matSort matSortActive="nachname" matSortDisableClear matSortDirection="asc">
  
        <ng-container matColumnDef="nachname">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Nachname</mat-header-cell>
          <mat-cell *matCellDef="let row" (click)="clickedRow(row)">{{row.nachname}}</mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="vorname">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Vorname</mat-header-cell>
          <mat-cell *matCellDef="let row" (click)="clickedRow(row)">{{row.vorname}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="tierarztgruppe">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Tierarztgruppe</mat-header-cell>
          <mat-cell *matCellDef="let row" (click)="clickedRow(row)">{{row.tierarztgruppe}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="lagerplatz">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Lagerplatz</mat-header-cell>
          <mat-cell *matCellDef="let row" (click)="clickedRow(row)">{{row.lagerplatz}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="options">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-menu #userOptionsMenu>
              <ng-template matMenuContent>
                <button mat-menu-item *kienePermission="[1253]" (click)="openEditVetDialog(row)">
                  <mat-icon>forward</mat-icon>
                  <span>Lagerplatz &auml;ndern</span>
                </button>
                <button mat-menu-item *kienePermission="[1253]" (click)="openEditVetGroupDialog(row)">
                  <mat-icon>forward</mat-icon>
                  <span>Tierarztgruppe &auml;ndern</span>
                </button>
                <button mat-menu-item *kienePermission="[1254]" (click)="openDeleteConfirmDialog(row)">
                  <mat-icon>delete</mat-icon>
                  <span>Tierarzt l&ouml;schen</span>
                </button>
              </ng-template>
            </mat-menu>
            <button mat-icon-button *kienePermission="[1253,1254]" [matMenuTriggerFor]="userOptionsMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
  
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  
      </mat-table>
      <mat-paginator [length]="totalCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
    </mat-card>
  </div> -->