<nav mat-tab-nav-bar style="margin-top: 63px; width: 100%; padding: 0;">
    <a #gd="routerLinkActive" *ngIf="isTabMenuVisible('/startseite/tourenplan')" [active]="gd.isActive"
       [routerLink]="['/startseite/tourenplan']"
       mat-tab-link routerLinkActive>
        <mat-icon>map</mat-icon>
        Tourenplan</a>

    <a #ga="routerLinkActive" *ngIf="isTabMenuVisible('/startseite/infos-tourenplan')" [active]="ga.isActive"
       [routerLink]="['/startseite/infos-tourenplan']"
       mat-tab-link routerLinkActive>
        <mat-icon>info</mat-icon>
        Informationen</a>

    <a #gh="routerLinkActive" *ngIf="isTabMenuVisible('/startseite/mein-bestand')" [active]="gh.isActive"
       [routerLink]="['/startseite/mein-bestand']"
       mat-tab-link routerLinkActive>
        <mat-icon>airport_shuttle</mat-icon>
        Mein Tierarztwagen Bestand</a>

    <a #gt="routerLinkActive" *ngIf="isTabMenuVisible('/startseite/fortbildungen')" [active]="gt.isActive"
       [routerLink]="['/startseite/fortbildungen']"
       mat-tab-link routerLinkActive>
        <kiene-tabinfo icon="event" urlpfad="fortbildung/tabinfo.php"></kiene-tabinfo>
        &nbsp;Fortbildungen</a>

    <a #gg="routerLinkActive" *ngIf="isTabMenuVisible('/startseite/urlaubskalender')" [active]="gg.isActive"
       [routerLink]="['/startseite/urlaubskalender']"
       mat-tab-link routerLinkActive>
        <mat-icon>calendar_month</mat-icon>
        Urlaubskalender</a>

    <a #ghit="routerLinkActive" *ngIf="isTabMenuVisible('/startseite/wochenendplaner')" [active]="ghit.isActive"
       [routerLink]="['/startseite/wochenendplaner']"
       mat-tab-link routerLinkActive>
        <mat-icon>event</mat-icon>
        Wochenendplaner</a>
</nav>
<router-outlet></router-outlet>

<!--<div style="margin-top: 63px;">-->
<!--    <mat-tab-group *ngIf="!isKunde" style=" width: 100%;" [selectedIndex]="currentTabIndex"-->
<!--                   (selectedIndexChange)="setSelectedTabIndex($event)">-->
<!--        <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--                <mat-icon>map</mat-icon>&nbsp;-->
<!--                Tourenplan-->
<!--            </ng-template>-->
<!--            <kiene-tourenplan></kiene-tourenplan>-->
<!--        </mat-tab>-->
<!--        <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--                <mat-icon>info</mat-icon>&nbsp;-->
<!--                Informationen-->
<!--            </ng-template>-->
<!--            <kiene-information-board [informationType]="1" [permissionRead]="4011" [permissionWrite]="4012"-->
<!--                                     [permissionDelete]="4014"></kiene-information-board>-->
<!--        </mat-tab>-->
<!--        <mat-tab *ngIf="currentUserIsVet()">-->
<!--            <ng-template mat-tab-label>-->
<!--                <mat-icon>airport_shuttle</mat-icon>&nbsp;-->
<!--                Mein Tierarztwagen Bestand-->
<!--            </ng-template>-->
<!--            <ng-template matTabContent>-->
<!--                <kiene-tierarzt-bestand [tierarztId]="tierarztId"></kiene-tierarzt-bestand>-->
<!--                &lt;!&ndash; <kiene-tierarztwagen-bestand></kiene-tierarztwagen-bestand> &ndash;&gt;-->
<!--            </ng-template>-->
<!--        </mat-tab>-->

<!--        <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--                <kiene-tabinfo icon="event" urlpfad="fortbildung/tabinfo.php"></kiene-tabinfo>-->
<!--                &nbsp;Fortbildungen-->
<!--            </ng-template>-->
<!--            <ng-template matTabContent>-->
<!--                <kiene-fortbildung></kiene-fortbildung>-->
<!--            </ng-template>-->
<!--        </mat-tab>-->

<!--        <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--                <mat-icon>calendar_month</mat-icon>-->
<!--                &nbsp;Urlaubskalender-->
<!--            </ng-template>-->
<!--            <ng-template matTabContent>-->
<!--                <kiene-urlaubskalender></kiene-urlaubskalender>-->
<!--            </ng-template>-->
<!--        </mat-tab>-->
<!--        <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--                <mat-icon>event</mat-icon>-->
<!--                &nbsp;Wochenendplaner-->
<!--            </ng-template>-->
<!--            <ng-template matTabContent>-->
<!--                <kiene-wochenende-und-feiertag-planung></kiene-wochenende-und-feiertag-planung>-->
<!--            </ng-template>-->
<!--        </mat-tab>-->
<!--    </mat-tab-group>-->
<!--    &lt;!&ndash;<div *ngIf="isKunde">&ndash;&gt;-->
<!--    &lt;!&ndash;  <kiene-tourenplan></kiene-tourenplan>&ndash;&gt;-->
<!--    &lt;!&ndash;</div>&ndash;&gt;-->
<!--</div>-->
