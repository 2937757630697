import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API_BASE_URL_SERVICE} from '../../kiene-core.config';
import {
    PdfViewerInput
} from './kiene-pdf-viewer/classes/pdf-viewer-input';
import {KieneBackendApiService} from '../../services/backend-api/kiene-backend-api.service';

@Injectable({
    providedIn: 'root',
})
export class EmailService {
    constructor(private http: HttpClient, @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private apiService: KieneBackendApiService) {}

    sendEmailGeneric(kieneMail: PdfViewerInput, url: string, params?: HttpParams) {
        return this.apiService.post(this.apiBaseUrl + url, kieneMail, params);
    }

    sendEmail(kieneMail: PdfViewerInput) {
        console.log('INPUT: ', kieneMail);
        return this.apiService.post(this.apiBaseUrl + kieneMail.url, kieneMail, kieneMail.params);
    }
}
