import {NgModule} from '@angular/core';
import {MatTooltipModule} from "@angular/material/tooltip";

import {StammdatenRoutingModule} from './stammdaten-routing.module';
import {StammdatenComponent} from './stammdaten.component';

import {ClientService} from '../administration/client/client.service';
import {CompanyGroupComponent} from '../administration/company-group/company-group.component';
import {
    AddClientGroupToCompanyGroupDialogComponent
} from '../administration/company-group/add-client-group-to-company-group-dialog/add-client-group-to-company-group-dialog.component';
import {
    CompanyGroupDetailComponent
} from '../administration/company-group/company-group-detail/company-group-detail.component';
import {CompanyGroupService} from '../administration/company-group/company-group.service';
import {LandkreiseComponent} from '../administration/landkreise/landkreise.component';
import {EditLandkreisDialogComponent} from '../administration/landkreise/edit-dialog/edit-dialog.component';
import {TierartenComponent} from '../administration/tierarten/tierarten.component';
import {TierartDialogComponent} from '../administration/tierarten/tierart-dialog/tierart-dialog.component';
import {BetriebsartenComponent} from '../administration/betriebsarten/betriebsarten.component';
import {KieneTableModule} from '../components/kiene-table/kiene-table.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {GruppenverwaltungModule} from '../documents/gruppenverwaltung/gruppenverwaltung.module';
import {SelectorModule} from '../components/selector/selector.module';
import {ViehhaendlerComponent} from './viehhaendler/viehhaendler.component';
import {
    EditViehhaendlerDialogComponent
} from './viehhaendler/edit-viehhaendler-dialog/edit-viehhaendler-dialog.component';
import {DialogsModule} from '../../../projects/kiene-core/src/lib/components/dialogs/dialogs.module';
import {EntsorgerComponent} from './entsorger/entsorger.component';
import {EntsorgerDetailsDialogComponent} from './entsorger/entsorger-details-dialog/entsorger-details-dialog.component';
import {GoToHITButtonModule} from '../components/go-to-hitbutton/go-to-hitbutton.module';
import {CommonModule} from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatChipsModule} from '@angular/material/chips';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSliderModule} from '@angular/material/slider';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {KieneAutocompleteModule} from '../components/kiene-autocomplete/kiene-autocomplete.module';
import {ComponentsModule} from '../components/components.module';
import {MatListModule} from '@angular/material/list';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {DirectivesModule} from '../../../projects/kiene-core/src/lib/directives/directives.module';
import { RassenComponent } from './rassen/rassen.component';
import { RasseDialogComponent } from './rassen/rasse-dialog/rasse-dialog.component';
import { TierartenRassenComponent } from './tierarten-rassen/tierarten-rassen.component';
import { SonstigeRassenComponent } from './rassen/sonstige-rassen/sonstige-rassen.component';
import { SonstigeRassenDialogComponent } from './rassen/sonstige-rassen/sonstige-rassen-dialog/sonstige-rassen-dialog.component';
import { TierartSonstigeDialogComponent } from './rassen/sonstige-rassen/sonstige-rassen-dialog/tierart-sonstige-dialog/tierart-sonstige-dialog.component';

@NgModule({
    declarations: [
        StammdatenComponent,
        CompanyGroupComponent,
        AddClientGroupToCompanyGroupDialogComponent,
        CompanyGroupDetailComponent,
        LandkreiseComponent,
        EditLandkreisDialogComponent,
        TierartenComponent,
        TierartDialogComponent,
        BetriebsartenComponent,
        ViehhaendlerComponent,
        EditViehhaendlerDialogComponent,
        EntsorgerComponent,
        EntsorgerDetailsDialogComponent,
        RassenComponent,
        RasseDialogComponent,
        TierartenRassenComponent,
        SonstigeRassenComponent,
        SonstigeRassenDialogComponent,
        TierartSonstigeDialogComponent,
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatCardModule,
        MatMenuModule,
        MatChipsModule,
        MatToolbarModule,
        MatExpansionModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatPaginatorModule,
        MatRadioModule,
        MatDividerModule,
        KieneAutocompleteModule,
        KieneTableModule,
        StammdatenRoutingModule,
        MatSlideToggleModule,
        GruppenverwaltungModule,
        SelectorModule,
        DialogsModule,
        GoToHITButtonModule,
        ComponentsModule,
        MatListModule,
        MatAutocompleteModule,
        DirectivesModule,
        MatTooltipModule
    ],
    providers: [ClientService, CompanyGroupService],
    exports: []
})
export class StammdatenModule {
}
