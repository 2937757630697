<h1 mat-dialog-title>H&auml;ndler anlegen</h1>
<div mat-dialog-content>


    <div class="one-row">
        <kiene-autocomplete style=" width: 49%" [showClear]="false" [showDeleteButton]="false" [required]="true"
            [genericApiUrl]="haendlerPath" [displayField]="'name'" [placeholder]="'Name'" [kieneObject]="haendler"
            [emitFreeText]="true" [focus]="false" (elementSelected)="selectedHaendler($event)">
        </kiene-autocomplete>

        <mat-form-field style="width: 49%;">
            <mat-label>VVVO</mat-label>
            <input matInput type="text" maxlength="15" minlength="12" [(ngModel)]="haendler.vvvo">
        </mat-form-field>

    </div>

    <div class="one-row">
        <mat-form-field style="width: 32%;">
            <mat-label>Stra&szlig;e</mat-label>
            <input matInput type="text" [(ngModel)]="haendler.strasse">
        </mat-form-field>
        <mat-form-field style="width: 32%;">
            <mat-label>PLZ</mat-label>
            <input matInput type="text" [(ngModel)]="haendler.plz">
        </mat-form-field>
        <mat-form-field style="width: 32%;">
            <mat-label>Ort</mat-label>
            <input matInput type="text" [(ngModel)]="haendler.ort">
        </mat-form-field>
    </div>
    <div class="one-row">
        <mat-form-field style="width: 24%;">
            <mat-label>Telefon</mat-label>
            <input matInput type="tel" [(ngModel)]="haendler.telefon">
        </mat-form-field>
        <mat-form-field style="width: 24%;">
            <mat-label>Mobil</mat-label>
            <input matInput type="tel" [(ngModel)]="haendler.mobil">
        </mat-form-field>
        <mat-form-field style="width: 24%;">
            <mat-label>Fax</mat-label>
            <input matInput type="tel" [(ngModel)]="haendler.fax">
        </mat-form-field>
        <mat-form-field style="width: 24%;">
            <mat-label>E-Mail</mat-label>
            <input matInput type="email" [(ngModel)]="haendler.email">
        </mat-form-field>
    </div>

</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</div>