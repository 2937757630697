import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {HitLoginResponse} from '../../../../hit/hit-registrierung/classes/hit-login-response';

@Component({
    selector: 'kiene-vvvo-hinzufuegen-hit-dialog',
    templateUrl: './vvvo-hinzufuegen-hit-dialog.component.html',
    styleUrls: ['./vvvo-hinzufuegen-hit-dialog.component.scss']
})
export class VvvoHinzufuegenHitDialogComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<VvvoHinzufuegenHitDialogComponent>) {
    }

    ngOnInit(): void {
    }

    hitLoginSucceeded(value: HitLoginResponse) {
        this.dialogRef.close(value);
    }
}
