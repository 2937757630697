import {InjectionToken} from '@angular/core';
import {RouteStrategy} from './configuration/route-caching-strategy/route-classes';

/**
 * Usage: API_BASE_URL_SERVICE provides the InjectionToken for the API_BASE_URL
 * This is used in the app.module.ts file to provide the base url for the API for services defined within kiene-core
 * e.g.:
 * providers: [
 * { provide: API_BASE_URL_SERVICE, useValue: environment.apiBaseUrl },
 * ]
 */
export const API_BASE_URL_SERVICE = new InjectionToken<string>('API_BASE_URL_SERVICE');
export const SIGNATURE_URL_SERVICE = new InjectionToken<string>('SIGNATURE_URL_SERVICE');

/**
 * Usage: ROUTE_STRATEGY_SERVICE provides the InjectionToken for the RouteStrategy being used to cache routes
 * This is used in the app.module.ts file to provide the RouteStrategy for the app
 * The RouteCachingStrategy is defined in the route-caching-strategy/route-classes.ts file
 * The RouteStrategy defines the interface for the strategy to be used
 * e.g.:
 * the RouteCachingStrategy_perProject should be defined in an project spefici config.ts file
 *
 * providers: [
 * { provide: ROUTE_STRATEGY_SERVICE, useClass: RouteCachingStrategy_perProject },
 * ]
 */
export const ROUTE_STRATEGY_CONFIG = new InjectionToken<RouteStrategy[]>('ROUTE_STRATEGY_CONFIG');

/**
 * Usage: Configures the Position of the Snackbar
 * This is used in the app.module.ts file to provide the SnackbarPosition for the app
 * If no position is provided the default is bottom
 * Possible values are enums from the SnackbarPosition class in the message.service.ts file
 * e.g.:
 * providers: [
 * { provide: SNACKBAR_POSITION, useValue: SnackbarPosition.BOTTOM }
 * ]
 */
export const SNACKBAR_POSITION = new InjectionToken<string>('SNACKBAR_POSITION');


/**
 * Usage: Configures the Versionsfile to use
 * The file is normally autogenerated when build a project
 * e.g.:
 * providers: [
 * { provide: GIT_VERSIONS_FILE, useValue: versions }
 * ]
 */
export const GIT_VERSIONS_FILE = new InjectionToken<string>('GIT_VERSIONS_FILE');
