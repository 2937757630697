import { DatePipe, formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChartType } from 'angular-google-charts';
import { AppService } from '../../../app.service';
import { ZeitauswahlChange } from '../../zeitauswahl/zeitauswahl-change';
import { UnternehmensgruppenGesamt } from './unternehmensgruppen-gesamt';
import { CsvColumn } from '../../../csv-export/csv-export/csv-column';
import { VetGroup } from '../../../administration/vet-group/vet-group';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { Subscription } from 'rxjs';
import { ReportUtils } from '../../report-utils';

@Component({
    selector: 'kiene-unternehmensgruppen-gesamt-report',
    templateUrl: './unternehmensgruppen-gesamt-report.component.html',
    styleUrls: ['./unternehmensgruppen-gesamt-report.component.scss'],
    animations: [
        trigger('detailExpanded', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
    ],
})
export class UnternehmensgruppenGesamtReportComponent
    implements OnInit, AfterViewInit {
    displayedColumns = [
        'unternehmensgruppe',
        'adresse',
        'tierarztgruppe',
        'umsatz_prozent',
        'umsatz',
        'wareneinsatz',
        'wareneinsatz_prozent',
        'rohertrag',
        'rohertrag_prozent',
        'abgabebelege',
    ];
    dataSource = new MatTableDataSource<UnternehmensgruppenGesamt>();
    dataSourceVorjahr = new MatTableDataSource<UnternehmensgruppenGesamt>();
    @ViewChild(MatSort) sort: MatSort;
    expandedElement: UnternehmensgruppenGesamt | null;

    tierarztgruppeCtrl = new UntypedFormControl();
    tierarztgruppen: VetGroup[] = [];

    abgabebelegeGesamt = 0;
    abgabebelegeGesamtVorjahr = 0;

    rabattCtrl = new FormControl();
    ergaenzerCtrl = new FormControl();
    aktuellSubscription: Subscription;
    vorjahrSubscription: Subscription;

    zeitauswahl: ZeitauswahlChange;
    vorjahreszeitraum: ZeitauswahlChange;

    chartType = ChartType.Line;
    chartColumns = ['Zeitraum', 'Umsatz', 'Wareneinsatz', 'Rohertrag'];
    chartData = [['', 0, 0, 0]];

    chartTypeGesamt = ChartType.PieChart;
    chartColumnsGesamt = ['Unternehmensgruppe', 'Umsatz'];
    chartDataGesamt = [['', 0]];

    csvColumns: CsvColumn[] = [];
    csvColumnsDetails: CsvColumn[] = [];
    csvFilename = 'Unternehmensgruppe_Gesamt.csv';

    gesamtUmsatz = 0;
    gesamtWareneinsatz = 0;
    gesamtWareneinsatzProzent = '0';
    gesamtRohertrag = 0;
    gesamtRohertragProzent = '0';
    gesamtGehaelter = 0;
    gesamtTierarztkosten = 0;
    gesamtGemeinkosten = 0;
    gesamtGewinn = 0;
    gesamtGewinnProzent = '0';

    gesamtUmsatzVorjahr = 0;
    gesamtWareneinsatzVorjahr = 0;
    gesamtWareneinsatzProzentVorjahr = '0';
    gesamtRohertragVorjahr = 0;
    gesamtRohertragProzentVorjahr = '0';
    gesamtGehaelterVorjahr = 0;
    gesamtTierarztkostenVorjahr = 0;
    gesamtGemeinkostenVorjahr = 0;
    gesamtGewinnVorjahr = 0;
    gesamtGewinnProzentVorjahr = '0';

    isLoading: boolean[] = [];

    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private appService: AppService,
        private datePipe: DatePipe,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.ladeTierarztgruppen();

        this.csvColumns.push(
            new CsvColumn('unternehmensgruppe', 'Unternehmensgruppe')
        );
        this.csvColumns.push(new CsvColumn('adresse', 'Adresse'));
        this.csvColumns.push(new CsvColumn('tierarztgruppe', 'Tierarztgruppe'));
        this.csvColumns.push(
            new CsvColumn('umsatz_prozent', 'Umsatz von Gesamt (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('umsatz', 'Umsatz'));
        this.csvColumns.push(new CsvColumn('wareneinsatz', 'Wareneinsatz'));
        this.csvColumns.push(
            new CsvColumn('wareneinsatz_prozent', 'Wareneinsatz (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('rohertrag', 'Rohertrag'));
        this.csvColumns.push(
            new CsvColumn('rohertrag_prozent', 'Rohertrag (%)', '%')
        );
        this.csvColumns.push(
            new CsvColumn('abgabebelege', 'Anzahl Abgabebelege')
        );

        this.csvColumnsDetails = this.csvColumns.map((column) => ({
            ...column,
        }));
        this.csvColumnsDetails.splice(
            0,
            1,
            new CsvColumn('zeitraum', 'Zeitraum')
        );
    }

    tierarztgruppeOpenChange(open: boolean) {
        if (!open) {
            this.loadData();
        }
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    loadData() {
        this.loadDataAktuell();
        this.loadDataVorjahreszeitraum();
    }

    ladeTierarztgruppen() {
        this.api.get(this.apiBaseUrl + 'tierarztgruppe/read.php').subscribe({
            next: (response) => {
                this.tierarztgruppen = response.records;
            },
        });
    }

    getParams(): HttpParams {
        let params = new HttpParams();
        if (this.tierarztgruppeCtrl.value) {
            for (const v of this.tierarztgruppeCtrl.value) {
                params = params.append(
                    'tierarztgruppeids[]',
                    v.tierarztgruppe_id.toString()
                );
            }
        }
        params = params.set('rabatt', this.rabattCtrl.value ? '1' : '0');
        params = params.set('ergaenzer', this.ergaenzerCtrl.value ? '1' : '0');
        return params;
    }

    loadDataAktuell() {
        this.appService.setDataLoading(true);

        let params = this.getParams();
        params = params.set(
            'von',
            this.datePipe.transform(this.zeitauswahl.von, 'yyyy-MM-dd')
        );
        params = params.set(
            'bis',
            this.datePipe.transform(this.zeitauswahl.bis, 'yyyy-MM-dd')
        );

        this.aktuellSubscription?.unsubscribe();
        this.aktuellSubscription = this.api
            .get(
                this.apiBaseUrl + 'report/read_kunde_gesamt.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSource.data = response.records;
                    this.resetChartData();
                    this.berechneGesamtwerte();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    loadDataVorjahreszeitraum() {
        this.appService.setDataLoading(true);

        let params = this.getParams();
        params = params.set(
            'von',
            this.datePipe.transform(this.vorjahreszeitraum.von, 'yyyy-MM-dd')
        );
        params = params.set(
            'bis',
            this.datePipe.transform(this.vorjahreszeitraum.bis, 'yyyy-MM-dd')
        );

        this.vorjahrSubscription?.unsubscribe();
        this.vorjahrSubscription = this.api
            .get(
                this.apiBaseUrl + 'report/read_kunde_gesamt.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSourceVorjahr.data = response.records;
                    this.berechneGesamtwerteVorjahreszeitraum();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    zeitintervalChanged(event: ZeitauswahlChange) {
        this.zeitauswahl = event;

        this.vorjahreszeitraum = ReportUtils.getVorjahreszeitraum(this.zeitauswahl);

        this.loadData();
    }

    berechneGesamtwerte() {
        this.gesamtUmsatz = 0;
        this.gesamtWareneinsatz = 0;
        this.gesamtRohertrag = 0;
        this.gesamtGehaelter = 0;
        this.gesamtTierarztkosten = 0;
        this.gesamtGemeinkosten = 0;
        this.gesamtGewinn = 0;
        this.abgabebelegeGesamt = 0;
        const data = this.dataSource.data;
        for (const r of data) {
            this.chartDataGesamt.push([r.unternehmensgruppe, r.umsatz]);
            this.abgabebelegeGesamt += r.abgabebelege ? r.abgabebelege : 0;
            this.gesamtUmsatz += r.umsatz ? r.umsatz : 0;
            this.gesamtWareneinsatz += r.wareneinsatz ? r.wareneinsatz : 0;
            this.gesamtRohertrag += r.rohertrag ? r.rohertrag : 0;
        }
        if (this.gesamtUmsatz > 0) {
            this.gesamtWareneinsatzProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtWareneinsatz * 100
                ) / 100
            ).toString();
            this.gesamtRohertragProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtRohertrag * 100
                ) / 100
            ).toString();
        }
    }

    berechneGesamtwerteVorjahreszeitraum() {
        this.gesamtUmsatzVorjahr = 0;
        this.gesamtWareneinsatzVorjahr = 0;
        this.gesamtRohertragVorjahr = 0;
        this.gesamtGehaelterVorjahr = 0;
        this.gesamtTierarztkostenVorjahr = 0;
        this.gesamtGemeinkostenVorjahr = 0;
        this.gesamtGewinnVorjahr = 0;
        this.abgabebelegeGesamtVorjahr = 0;
        const data = this.dataSourceVorjahr.data;
        for (const r of data) {
            // this.chartDataGesamtVorjahr.push([r.unternehmensgruppe, r.umsatz]);
            this.abgabebelegeGesamtVorjahr += r.abgabebelege ? r.abgabebelege : 0;
            this.gesamtUmsatzVorjahr += r.umsatz ? r.umsatz : 0;
            this.gesamtWareneinsatzVorjahr += r.wareneinsatz ? r.wareneinsatz : 0;
            this.gesamtRohertragVorjahr += r.rohertrag ? r.rohertrag : 0;
        }
        if (this.gesamtUmsatzVorjahr > 0) {
            this.gesamtWareneinsatzProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtWareneinsatzVorjahr * 100
                ) / 100
            ).toString();
            this.gesamtRohertragProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtRohertragVorjahr * 100
                ) / 100
            ).toString();
        }
    }

    expandVetGroupRow(element: UnternehmensgruppenGesamt) {
        this.expandedElement =
            this.expandedElement === element ? null : element;
        if (this.expandedElement) {
            this.isLoading[element.unternehmensgruppe_id] = true;

            let params = new HttpParams();
            params = params.set('von', formatDate(this.zeitauswahl.von, 'yyyy-MM-dd', 'de'));
            params = params.set('bis', formatDate(this.zeitauswahl.bis, 'yyyy-MM-dd', 'de'));
            params = params.set('aggregieren', this.zeitauswahl.aggregieren);
            params = params.set(
                'unternehmensgruppe_id',
                element.unternehmensgruppe_id.toString()
            );

            this.api
                .get(
                    this.apiBaseUrl + 'report/read_kunde_gesamt.php',
                    params
                )
                .subscribe(
                    (response) => {
                        element.details = response.records;
                        this.isLoading[element.unternehmensgruppe_id] = false;
                        this.chartData = [];
                        for (const d of element.details) {
                            this.chartData.push([
                                d.zeitraum,
                                d.umsatz ? d.umsatz : 0,
                                d.wareneinsatz ? d.wareneinsatz : 0,
                                d.rohertrag ? d.rohertrag : 0,
                            ]);
                        }
                    },
                    (error) => {
                        this.messageService.errorMessage('Fehler!', error);
                        this.isLoading[element.unternehmensgruppe_id] = false;
                    }
                );
        }
    }

    resetChartData() {
        this.chartData = [['', 0, 0, 0]];
        this.chartDataGesamt = [['', 0]];
    }
}
