import {HttpParams} from '@angular/common/http';
import {MessageService} from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import {Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {SignaturePad} from 'angular2-signaturepad';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'kiene-lieferschein',
    templateUrl: './lieferschein.component.html',
    styleUrls: ['./lieferschein.component.scss']
})
export class LieferscheinComponent implements OnInit {

    @Input('form') form: string;
    @Input('zeitslot_buchung_id') zeitslot_buchung_id;
    @Input('lieferschein_id') lieferschein_id;
    signaturePad: SignaturePad;

    formInput = new FormControl('');

    // Lieferant Felder
    formGeburtsland = new FormControl('', [Validators.required]);
    formVlogID = new FormControl('', [Validators.required]);
    formVVVONr = new FormControl('', [Validators.required]);
    formFuttermittellieferant = new FormControl('', [Validators.required]);
    formHaltungsbedingungen = new FormControl('', [Validators.required]);
    formTierarzneimittelWartezeiten = new FormControl(0, [Validators.required]);
    formTierKennzeichung = new FormControl('');
    formTierarzneimittel = new FormControl('');
    formWartezeit = new FormControl('');
    formDatumDerVerabreichung = new FormControl('');
    formAusnahmen = new FormControl('', [Validators.required]);
    formProbenanalysen = new FormControl('', [Validators.required]);
    formName = new FormControl('', [Validators.required]);
    formTelephone = new FormControl('', [Validators.required]);
    formAdresse = new FormControl('', [Validators.required]);
    formFax = new FormControl('', [Validators.required]);
    formPlzOrt = new FormControl('', [Validators.required]);
    formTetracycline = new FormControl(0, [Validators.required]);
    formHerkunftsnachweis = new FormControl(0, [Validators.required]);
    formAufgezogenIn = new FormControl('');
    formOrt = new FormControl('', [Validators.required]);
    formDatum = new FormControl('', [Validators.required]);
    formUnterschrift = new FormControl('', [Validators.required]);
    formUnterschriftLandwirt = new FormControl('', [Validators.required]);
    formUnterschriftFahrer = new FormControl('', [Validators.required]);
    formsmt = new FormControl('', [Validators.required]);

    // Schlachthof Felder
    formSchlachtGesamt = new FormControl(undefined, [Validators.required]);
    formSchlachtToteTiere = new FormControl(undefined, [Validators.required]);
    formSchlachtToteTiere2 = new FormControl(undefined, [Validators.required]);

    // Fahrer Felder
    formMenge = new FormControl('', [Validators.required, Validators.min(0)]);
    formGewicht = new FormControl('', [Validators.required, Validators.min(0)]);
    formGewichtDurchschnitt = new FormControl('', [Validators.required, Validators.min(0)]);

    // Formulare
    lieferantFormular: FormGroup = this._formBuilder.group({
        formGeburtsland: this.formGeburtsland,
        formVlogID: this.formVlogID,
        formVVVONr: this.formVVVONr,
        formFuttermittellieferant: this.formFuttermittellieferant,
        formHaltungsbedingungen: this.formHaltungsbedingungen,
        formTierarzneimittelWartezeiten: this.formTierarzneimittelWartezeiten,
        formTierKennzeichung: this.formTierKennzeichung,
        formTierarzneimittel: this.formTierarzneimittel,
        formWartezeit: this.formWartezeit,
        formDatumDerVerabreichung: this.formDatumDerVerabreichung,
        formAusnahmen: this.formAusnahmen,
        formProbenanalysen: this.formProbenanalysen,
        formName: this.formName,
        formTelephone: this.formTelephone,
        formAdresse: this.formAdresse,
        formFax: this.formFax,
        formPlzOrt: this.formPlzOrt,
        formTetracycline: this.formTetracycline,
        formHerkunftsnachweis: this.formHerkunftsnachweis,
        formAufgezogenIn: this.formAufgezogenIn,
        formOrt: this.formOrt,
        formDatum: this.formDatum,
        formMenge: this.formMenge,
        formGewicht: this.formGewicht,
        formGewichtDurchschnitt: this.formGewichtDurchschnitt,
        formUnterschriftLandwirt: this.formUnterschriftLandwirt,
        formUnterschriftFahrer: this.formUnterschriftFahrer,
    });

    schlachthofFormular: FormGroup = this._formBuilder.group({
        formDatum: this.formDatum,
        formSchlachtGesamt: this.formSchlachtGesamt,
        formSchlachtToteTiere: this.formSchlachtToteTiere,
        formSchlachtToteTiere2: this.formSchlachtToteTiere2,
        formUnterschrift: this.formUnterschrift,
    })

    fahrerFormular: FormGroup = this._formBuilder.group({});

    viehhandelFormular: FormGroup = this._formBuilder.group({
        formInput: this.formInput
    });

    lieferschein: any = {};
    api_url: any;

    @Output() unterschrift = new EventEmitter();

    constructor(
        private _formBuilder: FormBuilder,
        private messageService: MessageService,
        private api: KieneBackendApiService,
        private route: ActivatedRoute,
        private notification: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
    ) {
    }

    ngOnInit(): void {
        this.zeitslot_buchung_id = Number(this.route.snapshot.paramMap.get('id'));
        if (!this.lieferschein_id) {
            this.api_url = 'create.php';
        } else {
            this.api_url = 'update.php';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // changes.
        if (changes.lieferschein_id) {
            this.api_url = 'update.php';
        } else {
            this.api_url = 'create.php';
        }
    }

    updateLieferscheinLandwirtFahrer(): void {
        // dialog
        if (this.lieferantFormular.valid) {
            // Eingabe Landwirt
            this.lieferschein.geburtsland = this.formGeburtsland.value;
            this.lieferschein.vlog_id = this.formVlogID.value;
            this.lieferschein.vvvo_nr = this.formVVVONr.value;
            this.lieferschein.futtermittellieferant = this.formFuttermittellieferant.value;
            this.lieferschein.haltungsbedingungen = this.formHaltungsbedingungen.value;
            this.lieferschein.wartezeiten = this.formTierarzneimittelWartezeiten.value;
            if (this.formTierarzneimittelWartezeiten.value == 2) {
                this.lieferschein.wartezeit_tier = this.formTierKennzeichung.value;
                this.lieferschein.wartezeit_mittel = this.formTierarzneimittel.value;
                this.lieferschein.wartezeit_zeit = this.formWartezeit.value;
                this.lieferschein.wartezeit_datum = this.formDatumDerVerabreichung.value;
            }
            this.lieferschein.behandlungen_ausnahme = this.formAusnahmen.value;
            this.lieferschein.analysen_ausnahme = this.formProbenanalysen.value;
            this.lieferschein.landwirt_name = this.formName.value;
            this.lieferschein.landwirt_tel = this.formTelephone.value;
            this.lieferschein.landwirt_adresse = this.formAdresse.value;
            this.lieferschein.landwirt_fax = this.formFax.value;
            this.lieferschein.landwirt_ort = this.formPlzOrt.value;
            this.lieferschein.tetracycline = this.formTetracycline.value;
            this.lieferschein.herkunftsnachweis = this.formHerkunftsnachweis.value;
            if (this.formHerkunftsnachweis.value == 3) {
                this.lieferschein.herkunftsnachweis_andere = this.formAufgezogenIn.value;
            }
            this.lieferschein.unterschrift_landwirt = this.formUnterschriftLandwirt.value;
            this.lieferschein.uebergabe_ort = this.formOrt.value;
            this.lieferschein.uebergabe_datum = this.formDatum.value;

            // Eingabe Fahrer
            this.lieferschein.tier_menge = this.formMenge.value;
            this.lieferschein.tier_gewicht = this.formGewicht.value;
            this.lieferschein.tier_gewicht_durchschnitt = this.formGewichtDurchschnitt.value;
            this.lieferschein.unterschrift_fahrer = this.formUnterschriftFahrer.value;

            this.saveLieferschein(this.lieferschein);
        } else {
            console.log(this.lieferantFormular);
            this.messageService.alertMessage('Formular fehlerhaft!');

        }
    }

    updateLieferscheinSchlachthof(): void {
        if (this.schlachthofFormular.valid) {
            this.lieferschein.eingangskontrolle_datum = this.formDatum.value;
            this.lieferschein.eingangskontrolle_stueckzahl = this.formSchlachtGesamt.value;
            this.lieferschein.eingangskontrolle_tote_tiere_lkw = this.formSchlachtToteTiere.value;
            this.lieferschein.eingangskontrolle_tote_tiere_lkw_2 = this.formSchlachtToteTiere2.value;
            this.lieferschein.unterschrift_schlachtbetrieb = this.formUnterschrift.value;

            this.saveLieferschein(this.lieferschein);
        } else {
            this.messageService.alertMessage('Fehler beim Speichern!');
        }
    }

    saveSignature(e): void {
        this.formUnterschrift.setValue(e);
        this.schlachthofFormular.patchValue({formUnterschrift: e})
        // this.lieferschein.emit(e);
    }

    saveSignatureFahrer(e): void {
        this.formUnterschriftFahrer.setValue(e);
        this.lieferantFormular.patchValue({formUnterschriftFahrer: e})
    }

    saveSignatureLandwirt(e): void {
        this.formUnterschriftLandwirt.setValue(e);
        this.lieferantFormular.patchValue({formUnterschriftLandwirt: e})
    }

    updateValidationHerkunft(e): void {
        if (e.value == 2) {
            this.formAufgezogenIn.setValidators([Validators.required]);
            this.formAufgezogenIn.updateValueAndValidity();
        } else {
            this.formAufgezogenIn.setValidators([]);
            this.formAufgezogenIn.updateValueAndValidity();
        }
    }

    saveLieferschein(lieferschein) {
        lieferschein.buchungs_id = this.zeitslot_buchung_id;
        let params = new HttpParams();
        // params.set('schlachthof_id', this.schlachthof_id);
        // params.set('buchungs_id', this.zeitslot_buchung_id);
        // params.set('landwirt_id', this.landwirt_id);
        // params.set('haendler_id', this.haendler_id);
        if (this.api_url == 'update.php') {
            params.set('lieferschein_id', this.lieferschein_id);
        }
        console.log(params);
        this.api
            .post(
                `${this.apiBaseUrl}lieferschein/${this.api_url}`, lieferschein,
                params)
            .subscribe({
                next: (response: any) => {
                    window.location.reload();
                },
                error: err => {
                    this.messageService.errorMessage('Lieferschein konnte nicht gespeichert werden!', err);
                }
            });
    }

    exportLieferschein() {

        const params = new HttpParams().append('buchungs_id', this.zeitslot_buchung_id);
        this.api.get(this.apiBaseUrl + 'lieferschein/read_by_buchung.php', params).subscribe(response => {

        }, error => {
            this.notification.errorMessage('Fehler! ', error);
        });
    }
}
