import { Component, OnDestroy, OnInit } from '@angular/core';
import { Tierreklamation } from '../../../gruppe-tier/classes/tierreklamation';
import { UntypedFormControl, Validators } from '@angular/forms';
import { StammdatenService } from '../../stammdaten.service';
import { MessageService } from '../../../../services/message-service/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'kiene-tierreklamationen-editieren',
    templateUrl: './tier-reklamationen-editieren.component.html',
    styleUrls: ['./tier-reklamationen-editieren.component.scss']
})
export class TierReklamationenEditierenComponent implements OnInit, OnDestroy {

    tierreklamationSubscription: Subscription;
    isSaving = false;

    tierreklamation: Tierreklamation;
    tierreklamationTextCtrl: UntypedFormControl = new UntypedFormControl('', [Validators.required]);

    constructor(private stammdatenService: StammdatenService, private messageService: MessageService,
        private router: Router, private route: ActivatedRoute) { }

    ngOnDestroy(): void {
        this.tierreklamationSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.loadTierreklamation();
    }

    loadTierreklamation() {
        this.tierreklamationSubscription?.unsubscribe();
        this.tierreklamationSubscription = this.stammdatenService.getTierreklamation(this.route.snapshot.params.id).subscribe({
            next: (tierreklamation: Tierreklamation) => {
                this.tierreklamation = tierreklamation;
                this.tierreklamationTextCtrl.setValue(this.tierreklamation.reklamation);
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Laden der Tierreklamation', err);
            }
        });
    }

    updateTierreklamation() {
        this.isSaving = true;
        this.tierreklamation.reklamation = this.tierreklamationTextCtrl.value;
        this.stammdatenService.updateTierreklamation(this.tierreklamation).subscribe({
            next: () => {
                this.messageService.infoMessage('Tierreklamation wurde erfolgreich aktualisiert');
                this.isSaving = false;
                this.router.navigate(['../../uebersicht'], { relativeTo: this.route }).then();
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Aktualisieren der Tierreklamation', err);
                this.isSaving = false;
            }
        });
    }
}
