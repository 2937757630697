import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Betriebsart, Produktionsabschnitt } from '../../../../../projects/kiene-core/src/lib/components/stammdaten/vvvo/classes/betriebsart';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EnterValueInput, EnterValueResponse } from '../../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-response';
import { EnterValueDialogComponent } from '../../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-dialog.component';
import { KieneTableService } from '../../../../../src/app/components/kiene-table/kiene-table.service';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { MessageService } from '../../../../../src/app/services/message.service';

@Component({
  selector: 'kiene-produktionsabschnitte',
  templateUrl: './produktionsabschnitte.component.html',
  styleUrls: ['./produktionsabschnitte.component.scss']
})
export class ProduktionsabschnitteComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProduktionsabschnitteComponent>,
    private dialog: MatDialog,
    private api: KieneTableService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public betriebsart: Betriebsart,
    @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
  ) { }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<Produktionsabschnitt[]>) {
    moveItemInArray(this.betriebsart?.produktionsabschnitte, event.previousIndex, event.currentIndex);
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.betriebsart?.produktionsabschnitte?.forEach((abschnitt, index) => {
      abschnitt.sortierung = index + 1;
    });

    this.api.post(this.apiBaseUrl + '/betriebsart/update.php', this.betriebsart).subscribe({
      next: () => {
        this.messageService.infoMessage('Erfolgreich gespeichert!');
        this.dialogRef.close();
      },
      error: err => {
        this.messageService.errorMessage('Speichern fehlgeschlagen!', err);
      }
    });
  }

  addOrEdit(produktionsabschnitt?: Produktionsabschnitt) {
    const input = new EnterValueInput();
    input.title = 'Produktionsabschnitt';
    if (produktionsabschnitt) {
      input.okButton = 'editieren';
    } else {
      input.okButton = 'hinzufügen';
    }
    input.addInputValue('Produktionsabschnitt', 'text', produktionsabschnitt?.produktionsabschnitt);

    const dialogRef = this.dialog.open(EnterValueDialogComponent, {
      data: input,
    });

    dialogRef.afterClosed().subscribe({
      next: (result: EnterValueResponse) => {
        const txt = result?.getValue(0);
        if (result?.result && txt) {
          if (produktionsabschnitt) {
            produktionsabschnitt.produktionsabschnitt = txt;
          } else {
            const pa = new Produktionsabschnitt();
            pa.betriebsart_id = this.betriebsart.betriebsart_id;
            pa.produktionsabschnitt = txt;
            if (!this.betriebsart?.produktionsabschnitte) {
              this.betriebsart.produktionsabschnitte = [];
            }
            this.betriebsart?.produktionsabschnitte?.push(pa);
          }
        }
      }
    });
  }

}
