// kalender.component.ts

import {HttpParams} from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    QueryList,
    TrackByFunction,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatMenuTrigger} from '@angular/material/menu';
import {interval, Subscription} from 'rxjs';
import {
    ConfirmDialogInput
} from "../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog";
import {
    ConfirmDialogComponent
} from "../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component";
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {CalendarWeek, CalenderWeekUtils} from '../../dialogs/calendar-week-picker/calender-week';
import {MessageService} from '../../services/message.service';
import {HeimtierKalenderEintrag, HeimtierKalenderEintragOutput} from './heimtier-kalender-eintrag';
import {KalenderEintragDialogComponent} from './kalender-eintrag-dialog/kalender-eintrag-dialog.component';
import {KalenderService} from "./kalender.service";
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'kiene-kalender',
    templateUrl: './kalender.component.html',
    styleUrls: ['./kalender.component.scss']
})
export class KalenderComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('currentTimeLine', {static: false}) currentTimeLine!: ElementRef;
    @ViewChild('tableWrapper', {static: false}) tableWrapper!: ElementRef;
    @ViewChild('calendarBody') calendarBody!: ElementRef; // Referenz zum Kalender-Body
    @ViewChild('hourHeightSliderRef') hourHeightSliderRef!: ElementRef;
    @ViewChild('scrollableCalendar') scrollableCalendar!: ElementRef;
    @ViewChild('stundenbereich') stundenbereich!: ElementRef;
    @ViewChildren(MatMenuTrigger) menuTriggers!: QueryList<MatMenuTrigger>; // Optional, falls mehrere Menüs

    calenderWeek: CalendarWeek = new CalendarWeek();
    kalenderEintraege: HeimtierKalenderEintrag[] = [];
    entries: HeimtierKalenderEintrag[][] = [];
    searchInputCtrl: FormControl = new FormControl();
    getKalenderSubscription!: Subscription;
    isOpen: boolean = true;
    hours: number[] = [];
    days: number[] = [];
    dayNames: string[] = [];
    gruppierteEintraege: { [key: string]: HeimtierKalenderEintrag[] } = {};
    public hourHeight: number = 60;
    public minHeight: number = 50; // Mindesthöhe in Pixeln
    public enlargedWidthPercentage: number = 90; // Vergrößerte Breite in Prozent
    hoveredEintrag: HeimtierKalenderEintrag | null = null;
    menuOpenEintrag: HeimtierKalenderEintrag | null = null; // Tracken des geöffneten Menüs
    dialogOpenEintrag: HeimtierKalenderEintrag | null = null; // Tracken des geöffneten Dialogs
    currentEntry: HeimtierKalenderEintrag | null = null; // Aktuell ausgewählter Eintrag
    hoverTimer: any;
    currentMenuTrigger: MatMenuTrigger | null = null; // Eigenschaft hinzufügen
    isMenuClosingForAction: boolean = false; // Neues Flag hinzufügen
    customLabelStyle: { [key: string]: string } = {};
    tooltipContent: string = '';
    tooltipStyle: { [key: string]: string } = {};
    showTooltip: boolean = false;
    // Tracking der gehoverten Ereignisse
    private isLinePositioned = false;
    private timeInterval: any;
    private hoverEintrag = false;

    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private notificationService: MessageService,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private kalenderService: KalenderService
    ) {
        // Initialisieren der Kalenderwoche mit der aktuellen Woche
        const today = new Date();
        this.calenderWeek.weekNumber = CalenderWeekUtils.getISOWeekNumber(today);
        this.calenderWeek.year = today.getFullYear();
        this.calenderWeek.monday = CalenderWeekUtils.getMondayOfWeek(this.calenderWeek.weekNumber, this.calenderWeek.year);
        this.calenderWeek.sunday = CalenderWeekUtils.getSundayOfWeek(this.calenderWeek.monday);
    }

    ngOnInit(): void {
        this.hours = Array.from({length: 24}, (_, i) => i);
        this.days = Array.from({length: 7}, (_, i) => i);
        this.getKalenderEntries();

    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (!this.isLinePositioned && this.currentTimeLine && this.currentTimeLine.nativeElement) {
                this.positionCurrentTimeLine();
                this.isLinePositioned = true;
            }

            // Überprüfen, ob beide Elemente existieren
            if (this.scrollableCalendar && this.scrollableCalendar.nativeElement && this.stundenbereich && this.stundenbereich.nativeElement) {
                this.scrollableCalendar.nativeElement.addEventListener('scroll', () => {
                    this.stundenbereich.nativeElement.scrollTop = this.scrollableCalendar.nativeElement.scrollTop;
                });
            }
        }, 100); // Timeout für spätes Rendern, falls nötig

        // Starten Sie ein Intervall, um die Zeitlinie jede Minute zu aktualisieren
        this.timeInterval = interval(60000).subscribe(() => {
            this.positionCurrentTimeLine();
        });
    }


    ngOnDestroy(): void {
        this.getKalenderSubscription?.unsubscribe();
        if (this.timeInterval) {
            this.timeInterval.unsubscribe();
        }
    }

    // Methode zur Vermeidung von Konflikten
    public trackById: TrackByFunction<HeimtierKalenderEintrag> = (index: number, item: HeimtierKalenderEintrag) => item.hk_id;

    getWeekDayName(index: number): string {
        return this.dayNames[index];
    }

    berechneTop(von: Date): number {
        const stunde = von.getHours();
        const minute = von.getMinutes();
        return stunde * this.hourHeight + (minute / 60) * this.hourHeight;
    }

    /**
     * Berechnet die Höhe eines Ereignisses basierend auf der Zeitspanne und einer optionalen Mindesthöhe
     * @param von Startzeit
     * @param bis Endzeit
     * @param height Mindesthöhe
     * @returns Berechnete Höhe in Pixeln
     */
    berechneHeight(von: Date, bis: Date, height?: number): number {
        const diffMs = bis.getTime() - von.getTime();
        const diffHours = diffMs / (1000 * 60 * 60); // Unterschied in Stunden
        let calculatedHeight = diffHours * this.hourHeight;

        if (height !== undefined) {
            calculatedHeight = Math.max(calculatedHeight, height);
        }

        return calculatedHeight;
    }

    /**
     * Methode zum Erfassen von Klicks auf den Kalender
     * @param event Das Klick-Event
     * @param day Der Tag der Woche (0 = Montag, ..., 6 = Sonntag)
     */
    onCalendarClick(event: MouseEvent, day: number): void {

        const {hours, minutes} = this.getMousePositionTime(event);

        // Holen Sie das Datum für den angeklickten Tag
        const selectedDate = this.calenderWeek.getDateForDayIndex(day);

        const von = new Date(selectedDate);
        von.setHours(hours);
        von.setMinutes(minutes);
        von.setSeconds(0);
        von.setMilliseconds(0);

        // Setzen Sie 'bis' als 'von' + 1 Stunde oder eine andere Standarddauer
        const bis = new Date(von);
        bis.setHours(bis.getHours() + 1);

        // Erstellen eines neuen Eintragsobjekts ohne 'hk_id'
        const newEintrag = new HeimtierKalenderEintrag();
        newEintrag.von = von;
        newEintrag.bis = bis;


        this.addOrEditEntry(newEintrag);
    }

    /**
     * Methode zum Öffnen des Dialogs zum Hinzufügen/Bearbeiten eines Eintrags
     * @param eintrag Der zu bearbeitende Eintrag oder undefined für einen neuen Eintrag
     */
    addOrEditEntry(eintrag?: HeimtierKalenderEintrag): void {
        if (eintrag) {
            this.hoveredEintrag = eintrag; // Hover-Zustand beibehalten
            this.dialogOpenEintrag = eintrag; // Dialog geöffnet für diesen Eintrag
        }


        const dialogRef = this.dialog.open(KalenderEintragDialogComponent, {
            width: '700px',
            data: eintrag
        });

        dialogRef.afterClosed().subscribe((result: HeimtierKalenderEintragOutput) => {
            if (result?.confirm) {
                this.getKalenderEntries();
            }
        });
    }

    /**
     * Handler für Klicks auf die Menüoptionen
     * @param action 'edit' oder 'delete'
     */
    onMenuItemClick(action: string): void {
        if (this.currentMenuTrigger) {
            // Setzen des Flags, um anzuzeigen, dass eine Menüaktion erfolgt
            this.isMenuClosingForAction = true;
            // Menü schließen
            this.currentMenuTrigger.closeMenu();

            if (action === 'edit') {
                console.log('Opening edit dialog for:', this.currentEntry?.hk_id);
                this.addOrEditEntry(this.currentEntry);
            } else if (action === 'delete') {
                console.log('Opening delete confirmation for:', this.currentEntry?.hk_id);
                this.onDelete(this.currentEntry);
            }
        }
    }

    onSearch(event: any): void {
        const searchTerm = event.target.value;
        this.getKalenderEntries(searchTerm);
    }

    changeWeek(newWeek: CalendarWeek): void {
        this.calenderWeek = newWeek;
        this.getKalenderEntries();
    }

    /**
     * Formatierung der Zeit für die Anzeige
     */
    formatTime(date: Date): string {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    }

    /**
     * Handler für Mouse Enter Event
     * Startet einen Timer, um den Hover-Zustand zu setzen
     */
    onMouseEnter(eintrag: HeimtierKalenderEintrag): void {
        if (this.hoverTimer) {
            clearTimeout(this.hoverTimer);
        }
        this.hoverTimer = setTimeout(() => {
            this.hoveredEintrag = eintrag;
        }, 333);
    }

    /**
     * Handler für Mouse Leave Event
     * Entfernt den Hover-Zustand nach einer kurzen Verzögerung, falls das Menü und der Dialog nicht geöffnet sind
     */
    onMouseLeave(eintrag: HeimtierKalenderEintrag): void {
        if (this.hoverTimer) {
            clearTimeout(this.hoverTimer);
        }
        // Verzögerung hinzufügen, um sicherzustellen, dass das Menü oder der Dialog geöffnet wird
        setTimeout(() => {
            if (
                this.menuOpenEintrag?.hk_id !== eintrag.hk_id &&
                this.dialogOpenEintrag?.hk_id !== eintrag.hk_id &&
                this.hoveredEintrag?.hk_id === eintrag.hk_id
            ) {
                this.hoveredEintrag = null;
                console.log('Hover removed for:', eintrag.hk_id);
            }
        }, 200); // 200ms Verzögerung
    }

    /**
     * Wird aufgerufen, wenn das Menü geöffnet wird.
     * Setzt den aktuellen Eintrag als geöffnetes Menü und behält den Hover-Zustand bei.
     * @param eintrag Der aktuelle Kalender-Eintrag.
     * @param menuTrigger Der aktuelle MatMenuTrigger.
     */
    onMenuOpen(eintrag: HeimtierKalenderEintrag, menuTrigger: MatMenuTrigger): void {
        this.menuOpenEintrag = eintrag;
        this.hoveredEintrag = eintrag;
        this.currentMenuTrigger = menuTrigger;
        this.currentEntry = eintrag;
        console.log('Menu opened for:', eintrag.hk_id);
    }

    /**
     * Wird aufgerufen, wenn das Menü geschlossen wird.
     * Setzt den Zustand zurück, sofern kein Dialog geöffnet ist.
     */
    onMenuClose(): void {
        this.menuOpenEintrag = null;
        this.currentMenuTrigger = null; // Trigger zurücksetzen
        console.log('Menu closed');
        if (!this.dialogOpenEintrag && !this.isMenuClosingForAction) { // Überprüfen des Flags
            this.hoveredEintrag = null;
            console.log('Hover removed as no dialog is open');
        }
    }

    /**
     * Methode zum Schließen des Menüs
     */
    closeMenu(): void {
        this.menuOpenEintrag = null;
        this.currentMenuTrigger = null; // Trigger zurücksetzen
        console.log('Menu closed');
        // `hoveredEintrag` bleibt gesetzt, wenn der Dialog geöffnet ist
        if (!this.dialogOpenEintrag && !this.isMenuClosingForAction) { // Überprüfen des Flags
            this.hoveredEintrag = null;
            console.log('Hover removed as no dialog is open');
        }
    }

    /**
     * Prüft, ob ein Ereignis derzeit gehovered ist, sein Menü geöffnet hat oder ein Dialog geöffnet ist
     * @param eintrag
     * @returns
     */
    isHovered(eintrag: HeimtierKalenderEintrag): boolean {
        if (this.hoverEintrag && this.hoveredEintrag?.hk_id === eintrag.hk_id) {
            return true;
        }
        const hovered = !!(
            (this.hoveredEintrag && this.hoveredEintrag.hk_id === eintrag.hk_id) ||
            (this.menuOpenEintrag && this.menuOpenEintrag.hk_id === eintrag.hk_id) ||
            (this.dialogOpenEintrag && this.dialogOpenEintrag.hk_id === eintrag.hk_id)
        );
        return hovered;
    }

    onDelete(entry: HeimtierKalenderEintrag): void {
        const input: ConfirmDialogInput = new ConfirmDialogInput();
        input.text = 'Möchten Sie diesen Eintrag wirklich löschen?';
        input.headline = 'Eintrag löschen';
        input.okButton = 'Löschen';

        this.dialog.open(ConfirmDialogComponent, {data: input}).afterClosed().subscribe({
            next: (result) => {
                if (result?.ok) {
                    this.api.deleteEntity(this.apiBaseUrl + 'heimtier_kalendereintrag/delete.php', entry).subscribe({
                        next: value => {
                            this.getKalenderEntries();
                            this.hoveredEintrag = null; // Hover-Zustand zurücksetzen
                        }, error: err => {
                            this.notificationService.errorMessage('Eintrag konnte nicht gelöscht werden!', err);
                        }
                    });
                }
                // Reset des Flags nach Abschluss der Aktion
                this.isMenuClosingForAction = false;
                // Reset des Hover-Zustands, falls keine Aktion erfolgt
                if (!result?.ok) {
                    this.hoveredEintrag = null;
                }
            }
        });
    }

    /**
     * Event-Handler für Mausbewegungen über den Kalender-Body
     * @param event MouseEvent
     */
    onMouseMove(event: MouseEvent): void {
        const target = event.target as HTMLElement;

        // Überprüfen, ob die Maus über einem Ereignis schwebt
        if (target.closest('.ereignis')) {
            this.showTooltip = false; // Tooltip verstecken
            return;
        }

        if (!this.scrollableCalendar) {
            this.showTooltip = false;
            return;
        }
        const {hours, minutes} = this.getMousePositionTime(event);

        // Formatierte Zeit für den Tooltip
        const timeString = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes} Uhr`;

        // Tooltip-Inhalt aktualisieren
        this.tooltipContent = timeString;

        // Tooltip-Position setzen
        const tooltipX = event.clientX + 10; // 10px rechts vom Mauszeiger
        const tooltipY = event.clientY + 10; // 10px unter dem Mauszeiger
        // Sicherstellen, dass sich der Mauszeiger innerhalb des Kalenderbereichs befindet
        // if (y < 0 || y > rect.height) {
        //     this.showTooltip = false;
        //     return;
        // }
        this.tooltipStyle = {
            left: `${tooltipX}px`,
            top: `${tooltipY}px`
        };

        // Tooltip anzeigen
        this.showTooltip = true;
    }

    /**
     * Event-Handler für das Verlassen des Kalender-Body
     */
    onMouseLeaveCalendar(): void {
        this.showTooltip = false;
    }

    onSliderInput(event: any): void {
        const value = event.value;
        this.updateCustomLabel(value);
    }

    updateCustomLabel(value: number): void {
        if (!this.hourHeightSliderRef) {
            return;
        }
        const sliderElement = this.hourHeightSliderRef.nativeElement as HTMLElement;
        const rect = sliderElement?.getBoundingClientRect();
        const sliderWidth = rect?.width;

        // Berechne den Prozentsatz basierend auf dem Wert
        const percentage = (value - 20) / (100 - 20); // min=20, max=100

        // Berechne die Position des Thumbs
        const thumbPosition = percentage * sliderWidth;

        // Aktualisiere die benutzerdefinierte Beschriftung
        this.customLabelStyle = {
            left: `${thumbPosition}px`
        };
    }

    getSliderLabel(value: number): string {
        switch (value) {
            case 20:
                return 'Zoom Out';
            case 60:
                return 'Normal';
            case 100:
                return 'Zoom In';
            default:
                return '';
        }
    }

    calcContainerHeight() {
        return ((25 * this.hourHeight) + 30) + 'px'; /* Konsolidiert */
    }

    calculateHeight(eintrag: HeimtierKalenderEintrag) {
        const diffMs = eintrag.bis.getTime() - eintrag.von.getTime();
        const diffMinutes = diffMs / (1000 * 60); // Unterschied in Stunden
        return diffMinutes * (this.hourHeight / 60);
    }

    goToBelegeDetails(eintrag: HeimtierKalenderEintrag) {
        this.router.navigate(['../beleg/', eintrag?.ht_beleg_id], {relativeTo: this.route}).then();
    }

    eintragBearbeiten(eintrag: HeimtierKalenderEintrag) {
        this.addOrEditEntry(eintrag);
    }

    eintragLoeschen(eintrag: HeimtierKalenderEintrag) {
        const input = new ConfirmDialogInput();
        input.headline = 'Eintrag löschen';
        input.text = 'Möchten Sie diesen Eintrag wirklich löschen?';
        input.okButton = 'Löschen';
        this.dialog.open(ConfirmDialogComponent, {data: input}).afterClosed().subscribe({
            next: (result) => {
                if (result?.ok) {
                    this.api.deleteEntity(this.apiBaseUrl + 'heimtier_kalendereintrag/delete.php', eintrag).subscribe({
                        next: value => {
                            this.getKalenderEntries();
                        }, error: err => {
                            this.notificationService.errorMessage('Eintrag konnte nicht gelöscht werden!', err);
                        }
                    });
                }
            }
        });
    }

    private getMousePositionTime(event: MouseEvent) {
        const rect = this.scrollableCalendar.nativeElement.getBoundingClientRect();


        // Berechne die Y-Position relativ zum scrollbaren Kalenderbereich
        const y = event.clientY - rect.top;


        // Berechnung der Minuten basierend auf der Y-Position
        const totalMinutes = (y + this.scrollableCalendar.nativeElement.scrollTop) / this.hourHeight * 60;

        // Runden auf das nächste 5-Minuten-Intervall
        const roundedMinutes = this.roundToFive(totalMinutes);

        const hours = Math.floor(roundedMinutes / 60);
        const minutes = roundedMinutes % 60;
        return {hours, minutes};
    }

    private initializeDayNames(): void {
        this.dayNames = Array.from({length: 7}, (_, i) => {
            const date = new Date(this.calenderWeek.monday);
            date.setDate(date.getDate() + i);
            return date.toLocaleDateString('de-DE', {
                weekday: 'long',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            });
        });
    }

    private getKalenderEntries(searchParam?: string): void {
        let param = new HttpParams();
        const von = CalenderWeekUtils.dateToYYYYMMDD(new Date(this.calenderWeek.monday));
        const bis = CalenderWeekUtils.dateToYYYYMMDD(new Date(this.calenderWeek.sunday));

        param = param.set('von', von).set('bis', bis);
        this.initializeDayNames();

        if (searchParam) {
            param = param.set('search', searchParam);
        }

        this.getKalenderSubscription?.unsubscribe();
        this.getKalenderSubscription = this.api.getAll(this.apiBaseUrl + 'heimtier_kalendereintrag/read.php', param).subscribe({
            next: (value) => {
                value.records.forEach((eintrag) => {
                    eintrag.von = new Date(eintrag.von);
                    eintrag.bis = new Date(eintrag.bis);
                });
                this.kalenderEintraege = value.records;
                this.gruppierteEintraege = this.kalenderService.processEntries(this.kalenderEintraege, this.dayNames);
                this.setTimeLine();
            },
            error: err => {
                this.notificationService.errorMessage('Kalendereinträge konnten nicht abgerufen werden!', err);
            }
        });
    }


    private positionCurrentTimeLine(): void {
        if (!this.currentTimeLine || !this.currentTimeLine.nativeElement) {
            return;
        }

        const now = new Date();
        const stunde = now.getHours();
        const minute = now.getMinutes();
        const topPosition = stunde * this.hourHeight + (minute / 60) * this.hourHeight;

        // Setzen der Top-Position der Zeitlinie
        this.currentTimeLine.nativeElement.style.top = `${topPosition}px`;
    }

    private setTimeLine(): void {
        // Initiale Positionierung der Zeitlinie
        this.positionCurrentTimeLine();
    }

    /**
     * Rundet eine gegebene Minute auf das nächste kleinere 5-Minuten-Intervall.
     * @param minute Die Minute, die gerundet werden soll.
     * @returns Die gerundete Minute.
     */
    private roundToFive(minute: number): number {
        return Math.floor(minute / 5) * 5;
    }

    /**
     * Methode zum Erstellen eines neuen Eintrags im Backend
     */
    private createEintrag(eintrag: HeimtierKalenderEintrag): void {
        this.notificationService.infoMessage('Erstelle Kalendereintrag...');
        console.log('Erstelle Kalendereintrag:', eintrag); // Debugging

        // Nur relevante Felder für das Backend senden
        const backendEintrag = {
            titel: eintrag.titel,
            beschreibung: eintrag.beschreibung,
            kunde: eintrag.kunde,
            kunde_id: eintrag.kunde_id,
            von: eintrag.von,
            bis: eintrag.bis
        };

        this.api.post(this.apiBaseUrl + 'heimtier_kalendereintrag/create.php', backendEintrag).subscribe({
            next: (response) => {
                console.log('createEintrag response:', response); // Debugging
                this.notificationService.infoMessage('Kalendereintrag erstellt!');
                this.getKalenderEntries(); // Laden Sie die Einträge erneut
            },
            error: (err) => {
                console.error('createEintrag error:', err); // Debugging
                this.notificationService.errorMessage('Kalendereintrag konnte nicht erstellt werden!', err);
            }
        });
    }

    /**
     * Methode zum Aktualisieren eines bestehenden Eintrags im Backend
     */
    private updateEintrag(eintrag: HeimtierKalenderEintrag): void {
        // Nur relevante Felder für das Backend senden
        const backendEintrag = {
            hk_id: eintrag.hk_id,
            titel: eintrag.titel,
            beschreibung: eintrag.beschreibung,
            kunde: eintrag.kunde,
            kunde_id: eintrag.kunde_id,
            von: new Date(eintrag.von).toISOString(),
            bis: new Date(eintrag.bis).toISOString()
        };

        this.api.post(this.apiBaseUrl + 'heimtier_kalendereintrag/update.php', backendEintrag).subscribe({
            next: (response) => {
                console.log('updateEintrag response:', response); // Debugging
                this.notificationService.infoMessage('Kalendereintrag aktualisiert!');
                this.getKalenderEntries(); // Laden Sie die Einträge erneut
            },
            error: (err) => {
                console.error('updateEintrag error:', err); // Debugging
                this.notificationService.errorMessage('Kalendereintrag konnte nicht aktualisiert werden!', err);
            }
        });
    }
}
