import {Component, Inject, Input, OnInit} from '@angular/core';
import {Client} from '../../client/classes/client';
import {KieneBackendApiService} from '../../../services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../kiene-core.config';
import {HttpParams} from '@angular/common/http';
import {Benutzerprofil} from '../mein-profil/mein-profil';

@Component({
    selector: 'kiene-kontakte',
    templateUrl: './kontakte.component.html',
    styleUrls: ['./kontakte.component.scss']
})
export class KontakteComponent implements OnInit {

    @Input('showReturnButton') showReturnButton = true;

    kontakte: Client[] = [];
    benutzerprofil: Benutzerprofil;

    constructor(private api: KieneBackendApiService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    ngOnInit(): void {
        this.ladeKontakte();
    }

    sendMail(k: Client) {
        if (k.email) {
            const htmlAnchorElement = document.createElement('a');
            htmlAnchorElement.style.display = 'none';
            htmlAnchorElement.href = 'mailto:' + k.email;
            document.body.appendChild(htmlAnchorElement);
            htmlAnchorElement.click();
            // document.body.removeChild(htmlAnchorElement);
        }
    }

    private ladeKontakte() {
        const params = new HttpParams().set('intern', '0').set('extern', '1');
        this.api.getAll(this.apiBaseUrl + 'kunde_kunde/read_kundenportal.php', params).subscribe({
            next: response => {
                this.kontakte = response.records;
            }
        });
    }
}
