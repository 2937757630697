import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {API_BASE_URL_SERVICE} from "../../../../../projects/kiene-core/src/lib/kiene-core.config";
import {
    KieneBackendApiService
} from "../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service";
import {MessageService} from "../../../services/message.service";
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {Heimtier} from '../../kalender/heimtier-kalender-eintrag';
import {HttpParams} from '@angular/common/http';
import {HeimtierDialogComponent} from '../heimtier-dialog/heimtier-dialog.component';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';

@Component({
    selector: 'kiene-heimtier-kunde-dialog',
    templateUrl: './heimtier-kunde-dialog.component.html',
    styleUrls: ['./heimtier-kunde-dialog.component.scss']
})
export class HeimtierKundeDialogComponent implements OnInit {
    public nameCtrl: FormControl = new FormControl(undefined, Validators.required);
    public plzCtrl: FormControl = new FormControl(undefined);
    public ortCtrl: FormControl = new FormControl(undefined);
    public strasseCtrl: FormControl = new FormControl(undefined);
    public mobilCtrl: FormControl = new FormControl(undefined);
    public phoneCtrl: FormControl = new FormControl(undefined);
    public mailCtrl: FormControl = new FormControl(undefined, [Validators.email]);
    public kommentarCtrl: FormControl = new FormControl(undefined, Validators.maxLength(99));
    tiere: Heimtier[] = [];

    constructor(public dialogRef: MatDialogRef<HeimtierKundeDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public kunde: Client,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private dialog: MatDialog,
                private api: KieneBackendApiService,
                private messageService: MessageService,) {
        if (!this.kunde) {
            this.kunde = new Client();
            this.kunde.aktiv = 1;
        }
    }

    ngOnInit(): void {
        this.initFormFields();
        this.ladeTiere();
    }

    initFormFields(): void {
        this.nameCtrl.setValue(this.kunde?.bezeichnung);
        this.plzCtrl.setValue(this.kunde?.plz);
        this.ortCtrl.setValue(this.kunde?.ort);
        this.strasseCtrl.setValue(this.kunde?.strasse);
        this.phoneCtrl.setValue(this.kunde?.telefon_1);
        this.mobilCtrl.setValue(this.kunde?.mobil_1);
        this.mailCtrl.setValue(this.kunde?.email);
        this.kommentarCtrl.setValue(this.kunde?.kommentar);
    }

    public isDisabled(): boolean {
        return !(this.nameCtrl.valid && this.kommentarCtrl.valid && this.mailCtrl.valid);
    }


    public save(): void {
        console.log(this.kunde);
        this.kunde.bezeichnung = this.nameCtrl.value;
        this.kunde.plz = this.plzCtrl.value;
        this.kunde.ort = this.ortCtrl.value;
        this.kunde.strasse = this.strasseCtrl.value;
        this.kunde.telefon_1 = this.phoneCtrl.value;
        this.kunde.mobil_1 = this.mobilCtrl.value;
        this.kunde.email = this.mailCtrl.value;
        this.kunde.kommentar = this.kommentarCtrl.value;
        this.api.post(this.apiBaseUrl + 'heimtier_kunde/update.php', this.kunde).subscribe({
            next: value => {
                this.dialogRef.close(value);
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Speichern des Kunden', err);
            }
        });
    }

    private ladeTiere() {
        if (!this.kunde?.kunde_id) {
            return;
        }
        const params = new HttpParams().set('kunde_id', this.kunde.kunde_id);
        this.api.get(this.apiBaseUrl + 'heimtier/read.php', params).subscribe({
            next: response => {
                this.tiere = response.records;
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Laden der Heimtiere', err);
            }
        });
    }

    deleteTier(tier: Heimtier) {
        const input = new ConfirmDialogInput();
        input.headline = 'Heimtier löschen';
        input.text = 'Wollen Sie das Heimtier ' + tier.name + ' wirklich löschen?';
        input.okButton = 'Löschen';
        this.dialog.open(ConfirmDialogComponent, {data: input}).afterClosed().subscribe({
            next: result => {
                if (result) {
                    this.api.post(this.apiBaseUrl + 'heimtier/delete.php', tier).subscribe({
                        next: response => {
                            this.ladeTiere();
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler beim Löschen des Heimtieres', err);
                        }
                    });
                }
            }
        });
    }

    editTier(tier: Heimtier) {
        this.dialog.open(HeimtierDialogComponent, {data: tier}).afterClosed().subscribe({
            next: result => {
                this.ladeTiere();
            }
        });
    }
}
