<div *kienePermission="[1081]" style="padding: 24px;">
    <h1>Bestellungen Arzneimittel</h1>
    <div>


        <div class="one-row">

            <span class="spacer"></span>

            <mat-form-field style="width: 50%; min-width: 300px;">
                <mat-label>Suche</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input [formControl]="searchCtrl" matInput type="text">
            </mat-form-field>

            <span class="spacer"></span>


        </div>


        <div class="one-row">
            <span class="spacer"></span>
            <div class="one-row" style="width:100%; max-width: 1400px;">
                <mat-checkbox [formControl]="withoutInvoiceCtrl">Bestellungen mit fehlender Rechnung</mat-checkbox>
                <button (click)="mergeBestellungen()" *ngIf="invoiceModeActive"
                    [disabled]="!invoiceModeActive || selectedLieferantId === -1" color="accent" mat-icon-button
                    matTooltip="Bestellungen zusammenführen">
                    <mat-icon>merge_type</mat-icon>
                </button>
                <span class="spacer"></span>
                <!--                <button (click)="openAddInvoiceToCollectionDialog()" *ngIf="invoiceModeActive"-->
                <!--                    [disabled]="bestellungRechnungArray.length < 1" color="accent" mat-icon-button-->
                <!--                    matTooltip="Rechnung zu Bestellungen hochladen">-->
                <!--                    <mat-icon>cloud_upload</mat-icon>-->
                <!--                </button>-->
                <button (click)="setzeTeilweiseGeliefertFilter()" color="accent" mat-icon-button
                    matTooltip="Alle nicht vollständig gelieferten Bestellungen anzeigen, die älter als 4 Wochen sind.">
                    <mat-icon [style.color]="teilweiseGeliefertFilter ? '#00ff46' : ''">dynamic_feed</mat-icon>
                </button>
                <button (click)="alleTeilweiseGeliefertAlsGeliefertMarkieren()" [disabled]="!teilweiseGeliefertFilter"
                    color="accent" mat-icon-button
                    matTooltip="Alle nicht vollständig gelieferten Bestellungen als geliefert markieren!">
                    <mat-icon>check</mat-icon>
                </button>
                <div style="width: 1px; height: 40px; background-color: gray;"></div>
                <button (click)="reload()" color="accent" mat-icon-button matTooltip="Daten neu laden">
                    <mat-icon>cached</mat-icon>
                </button>
                <button (click)="generatePrimanota()" *kienePermission="[1832]" color="accent" mat-icon-button
                    matTooltip="FiBu Primanota genererien">
                    <mat-icon>account_balance</mat-icon>
                </button>
                <button (click)="openOrderAllDialog()" *kienePermission="[1769]" color="accent" mat-icon-button
                    matTooltip="alle offenen Bestellungen bestellen">
                    <mat-icon>forward</mat-icon>
                </button>
                <mat-paginator #topPaginator (page)="topPageChanged($event)" [length]="totalLength"
                    [pageSizeOptions]="[5, 10, 25, 50, 100]" [pageSize]="limit"
                    style="background: transparent;"></mat-paginator>
            </div>
            <span class="spacer"></span>
        </div>


        <div class="one-row">
            <span class="spacer"></span>
            <div style="width:100%; max-width: 1400px;">

                <mat-card *ngFor="let be of bestellungenExtern" style="margin-bottom: 24px;">
                    <div>

                        <div (onFileDropped)="openInvoiceDeliveryNoteDialog($event, be)" phitoDropFile
                            style="color: gray;">

                            <div class="one-row" style="padding: 12px;">

                                <mat-checkbox (change)="addToBestellungRechnungArray($event, be)"
                                    *ngIf="invoiceModeActive && (be.status_id === 26 || be.status_id === 27 || be.status_id === 30)"
                                    [checked]="isChecked(be)"
                                    [disabled]="selectedLieferantId > 0 && selectedLieferantId !== be.lieferant_id"
                                    style="margin-right: 12px;">
                                    gruppieren
                                </mat-checkbox>

                                <h3 *ngIf="be.bestell_nr" style="padding-left: 12px; width: 350px; margin: 0px;">
                                    <a [routerLink]="['/lager','lieferanten', be.lieferant_id]"
                                        class="kiene-link kiene-inline-link">{{be.lieferant}}</a>
                                </h3>

                                <div *ngIf="!be.bestell_nr" class="one-row" style="padding-left: 12px; width: 350px;">
                                    <mat-icon color="accent" matTooltip="Zusammengeführte Bestellung">merge_type
                                    </mat-icon>
                                    <h3 style="padding-left: 12px; width: 350px; margin: 0px;">{{be.lieferant}}</h3>
                                    <span class="spacer"></span>
                                </div>

                                <span class="spacer"></span>
                                <div class="one-row" style="width: 25%;">
                                    <button *kienePermission="[1741]"
                                        [disabled]="be.dokumente?.lieferscheine?.length < 1"
                                        [mat-menu-trigger-for]="lieferscheinMenu" color="accent" mat-icon-button
                                        matTooltip="Lieferscheine anzeigen">
                                        <mat-icon [matBadgeHidden]="be.dokumente?.lieferscheine?.length < 1"
                                            [matBadge]="be.dokumente?.lieferscheine?.length" matBadgeColor="accent"
                                            matBadgeSize="medium">local_shipping
                                        </mat-icon>
                                    </button>
                                    <mat-menu #lieferscheinMenu>
                                        <ng-container *ngFor="let l of be.dokumente?.lieferscheine">
                                            <button (click)="downloadLieferschein(l)" mat-menu-item>
                                                <mat-icon>local_shipping</mat-icon>
                                                <span>{{l.datum | date: 'dd.MM.yyyy'}} - {{l.dateiname}}</span>
                                            </button>

                                        </ng-container>
                                    </mat-menu>
                                    <span class="spacer"></span>
                                    <button *kienePermission="[1725]" [disabled]="be.dokumente?.rechnungen?.length < 1"
                                        [mat-menu-trigger-for]="rechnungMenu" color="accent" mat-icon-button
                                        matTooltip="Rechnungen anzeigen">
                                        <mat-icon [matBadgeHidden]="be.dokumente?.rechnungen?.length < 1"
                                            [matBadge]="be.dokumente?.rechnungen?.length" matBadgeColor="accent"
                                            matBadgeSize="medium">euro
                                        </mat-icon>
                                    </button>
                                    <mat-menu #rechnungMenu>
                                        <ng-container *ngFor="let r of be.dokumente?.rechnungen">
                                            <button (click)="openRechnungBestellpositonenCheckDialog(be, r)"
                                                mat-menu-item>
                                                <mat-icon>euro</mat-icon>
                                                <span>{{r.datum | date: 'dd.MM.yyyy'}} - {{r.dateiname}}</span>
                                            </button>
                                        </ng-container>
                                    </mat-menu>
                                    <span class="spacer"></span>
                                    <button (click)="openShowGutschriftenDialog(be)" *kienePermission="[4101]"
                                        [disabled]="be.dokumente?.gutschriften?.length < 1"
                                        [matTooltip]="be.dokumente?.gutschriften?.length + ' Gutschriften hinterlegt'"
                                        color="accent" mat-icon-button>
                                        <mat-icon [matBadgeHidden]="be.dokumente?.gutschriften?.length < 1"
                                            [matBadge]="be.dokumente?.gutschriften?.length" matBadgeColor="accent"
                                            matBadgeSize="medium">
                                            money
                                        </mat-icon>
                                    </button>
                                    <span class="spacer"></span>
                                    <button (click)="download(be)" *kienePermission="[1767]" color="accent"
                                        mat-icon-button matTooltip="Alle Dokumente als eine PDF herunterladen">
                                        <mat-icon>cloud_download</mat-icon>
                                    </button>
                                    <span class="spacer"></span>
                                    <button (click)="openCommentDialog(be)" color="accent" mat-icon-button
                                        matTooltip="Kommentare">
                                        <mat-icon [matBadgeHidden]="!be.kommentar" matBadge="!" matBadgeColor="warn"
                                            matBadgeSize="medium">
                                            comment
                                        </mat-icon>
                                    </button>
                                </div>
                                <span class="spacer"></span>
                                <div *ngIf="be.bestell_nr" class="one-row"
                                    style="text-align: right; padding-right: 12px; width: 33%;">
                                    <span class="spacer"></span>
                                    <p style="font-size: 1.2em; margin-right: 12px;">
                                        <a [routerLink]="[be.bestellung_extern_id]" class="link">#{{be.bestell_nr}} </a>
                                    </p>
                                    <div *ngIf="be.bestell_nr"
                                        [style.background-color]="be.status_id === 28 ? 'red' : '#197E3E'"
                                        matTooltip="{{be.geliefert ? 'geliefert am ' : ''}}&nbsp;{{be.geliefert | date: 'mediumDate'}}"
                                        style=" color: white; font-weight: bold; border-radius: 5px; padding: 6px;">
                                        {{be.status }}
                                    </div>
                                </div>
                            </div>

                            <div class="one-row"
                                style="width: 95%; padding-left: 12px; padding-top: 12px; padding-bottom: 12px; padding-right: 24px; color: gray;">

                                <div *ngIf="be.bestell_nr" style="text-align: left;width: 250px; padding-left: 12px;">
                                    <span style="color: gray;">bestellt:
                                    </span><span style="font-weight: bold;">{{getFormattedDate(be.bestellt)}}</span>
                                </div>

                                <div *ngIf="be.bestell_nr" style="text-align: left; width: 200px;"><span
                                        style="color: gray;">von:
                                    </span>{{be.bestellt_nachname}}
                                </div>

                                <!-- <div style="text-align: left; width: 20%;"><span style="color: gray;">Lieferant:</span><br>
                                <p>{{be.lieferant}}</p>
                              </div> -->
                                <div *kienePermission="[1725]">
                                    Summe: {{summeAllerEinzelpreise(be) | number: '1.2-5'}} &euro;
                                </div>

                                <div *kienePermission="[1725]">
                                    Gutschrift
                                    (berechnet): {{(summeAllerEinzelpreise(be) - summeAllerRabattpreise(be))| number:
                                    '1.2-5'}}
                                    &euro;
                                </div>


                                <div>
                                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button (click)="cancel(be)" *ngIf="hasPermission(1763)"
                                            [disabled]="be.status_id == 26 || be.status_id == 30 || be.status_id == 27 || be.status_id == 28"
                                            mat-menu-item>
                                            <mat-icon>cancel</mat-icon>
                                            <span>Bestellung stornieren</span>
                                        </button>
                                        <button (click)="delete(be)" *ngIf="hasPermission(1764)"
                                            [disabled]="!(be.status_id == 21 || be.status_id == 22)" mat-menu-item>
                                            <mat-icon>delete</mat-icon>
                                            <span>Bestellung löschen</span>
                                        </button>
                                        <button (click)="markAsClosed(be)" *ngIf="hasPermission(1763)"
                                            [disabled]="be.status_id < 30" mat-menu-item>
                                            <mat-icon>check</mat-icon>
                                            <span>Bestellung als geliefert markieren</span>
                                        </button>
                                    </mat-menu>
                                </div>

                            </div>
                        </div>
                    </div>

                    <mat-divider></mat-divider>

                    <div style="padding: 12px;">
                        <table class="phito-table table-hover" style="width: 100%;">
                            <thead>
                                <tr>
                                    <th>Pos.</th>
                                    <th>Artikel</th>
                                    <th></th>
                                    <th *kienePermission="[1725]">Einzelpreis</th>
                                    <th *kienePermission="[1725]">Rabatt</th>
                                    <th *kienePermission="[1725]">Rabattpreis</th>
                                    <th>Menge Soll</th>
                                    <th>Menge Ist</th>
                                    <th>Chargen</th>
                                    <th *ngIf="!be.bestell_nr">Bestellung</th>
                                    <th *ngIf="!be.bestell_nr">Bestelldatum</th>
                                    <th>Gepr&uuml;ft</th>
                                    <th style="width: 70px;">Hinterlegte Rechnungen</th>
                                    <th>Primanota</th>
                                    <th *ngIf="!be.bestell_nr"></th>
                                </tr>
                            </thead>
                            <tbody>


                                <tr *ngFor="let p of be.positionen; let i = index">
                                    <td style="font-size: .9em;">{{i + 1}}</td>
                                    <td style="font-size: .9em;">{{p.artikel_me}}</td>
                                    <td style="font-size: .9em; max-width: 150px; white-space: pre-wrap;">
                                        {{p.kommentar}}</td>
                                    <td *kienePermission="[1725]" style="font-size: .9em; width: 120px;">
                                        <span>{{p.einzelpreis | number: '1.2-5'}}&euro;</span>
                                    </td>
                                    <td *kienePermission="[1725]" style="font-size: .9em;">{{p.rabatt}}%</td>
                                    <td *kienePermission="[1725]" style="font-size: .9em;">
                                        {{(p.rabatt ? (p.einzelpreis * (100 - p.rabatt) / 100) : p.einzelpreis) |
                                        number: '1.2-5'}}&euro;
                                    </td>
                                    <td style="font-size: .9em;">{{p.menge_soll}}</td>
                                    <td style="font-size: .9em;">{{p.menge_ist}}</td>
                                    <td style="font-size: .9em;">{{p.chargen}}</td>
                                    <td *ngIf="!be.bestell_nr" style="font-size: .9em;"><a
                                            [routerLink]="['/wawi','bestellung-extern', 'lieferanten', p.bestellung_extern_id]"
                                            class="link">#{{p.bestell_nr}}
                                            -
                                            {{p.status}}</a>
                                    </td>
                                    <td *ngIf="!be.bestell_nr">
                                        {{p.bestell_datum | date: 'mediumDate'}}
                                    </td>
                                    <td>

                                        <mat-icon color="accent" style="transform: scale(.8)">{{p.geprueft ?
                                            'check_circle' : 'radio_button_unchecked'}}
                                        </mat-icon>
                                    </td>
                                    <td>
                                        <mat-icon *ngIf="p.rechnungen && p.rechnungen?.length > 0"
                                            [matBadgeHidden]="p.rechnungen?.length < 2"
                                            [matBadge]="p.rechnungen?.length" color="accent"
                                            style="transform: scale(.8)">euro
                                        </mat-icon>
                                    </td>
                                    <td>
                                        <mat-icon color="accent" style="transform: scale(.8)">{{p.primanota_id ?
                                            'check_circle' :
                                            'radio_button_unchecked'}}</mat-icon>
                                    </td>
                                    <td *ngIf="!be.bestell_nr">
                                        <button (click)="downloadSingle(p.bestellung_extern_id, be)"
                                            [matTooltip]="'Download Dokumente für Bestellung ' + p.bestell_nr"
                                            color="accent" mat-icon-button>
                                            <mat-icon>cloud_download</mat-icon>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </mat-card>
            </div>
            <span class="spacer"></span>
        </div>

        <mat-paginator #bottomPaginator (page)="bottomPageChanged($event)" [length]="totalLength"
            [pageSizeOptions]="[5, 10, 25, 50, 100]" [pageSize]="limit"></mat-paginator>


    </div>


</div>