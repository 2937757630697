import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from '../../../client/classes/client';
import { StammdatenService } from '../../stammdaten.service';

@Component({
    selector: 'kiene-vvvo-details-actions',
    templateUrl: './vvvo-details-actions.component.html',
    styleUrls: ['./vvvo-details-actions.component.scss'],
})
export class VvvoDetailsActionsComponent implements OnInit {
    @Input('kunde')
    kunde: Client;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private stammdatenService: StammdatenService
    ) {
    }

    ngOnInit(): void {
    }

    openHitPinAendern(): void {
        this.router.navigate(['./hit-pin'], { relativeTo: this.route }).then();
    }

    removeVVVO() {
        this.stammdatenService.removeVVVO(this.kunde).subscribe({
            next: (removed: boolean) => {
                if (removed === true) {
                    this.router.navigate([
                        '/einstellungen',
                        'stammdaten',
                        'vvvo'
                    ]).then();
                }
            }
        });
    }

}
