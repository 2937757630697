import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import {Abgabebeleg, AbgabebelegCharge, AbgabebelegPosition, AbgabebelegStatus, PositionCharge} from '../abgabebeleg';
import {GruppeTier} from '../../../administration/client-group/gruppe-tier';
import {UntypedFormControl} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {Vet} from '../../../administration/vet/vet';
import {Identifikation} from '../../behandlungsanfrage/identifikation';
import {Anwendung} from '../../behandlungsanfrage/anwendung';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../../services/message.service';
import {MatSelect} from '@angular/material/select';
import {MatDialog} from '@angular/material/dialog';
import {RecPositionService} from '../../../services/rec-position.service';
import {RecPositionObject} from '../../../services/rec-position';
import {
    ChangeCurrentClientService
} from '../../../administration/client/change-current-client/change-current-client.service';
import {Indikation} from '../../../warehouse/material/artikel-indikation/indikation';
import {GruppeDialogComponent} from '../../gruppenverwaltung/gruppenuebersicht/gruppe-dialog/gruppe-dialog.component';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {BelegPositionInlineComponent} from '../../beleg-position/beleg-position-inline/beleg-position-inline.component';
import {
    AbgabebelegChargeDialogComponent
} from '../../../dialogs/abgabebeleg-charge-dialog/abgabebeleg-charge-dialog.component';
import {Location} from '@angular/common';
import {
    GruppeEditDialogComponent
} from '../../gruppenverwaltung/gruppenuebersicht/gruppe-edit-dialog/gruppe-edit-dialog.component';
import {AbgabebelegService} from '../abgabebeleg.service';
import {HttpParams} from '@angular/common/http';
import {CdkScrollable, ScrollDispatcher} from '@angular/cdk/scrolling';
import {BestellungInternPosition} from '../../../wa-wi/bestellung-intern/bestellung-intern-position';
import {AddTourDialogComponent} from '../../../components/tourenplan/add-tour-dialog/add-tour-dialog.component';
import {MeldungKorrekturDialogComponent} from './meldung-korrektur-dialog/meldung-korrektur-dialog.component';
import {GruppenService} from '../../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import {Gruppe} from '../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import {ConfirmDeleteDialogComponent} from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {
    EnterValueInput,
    EnterValueResponse
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-response';
import {
    EnterValueDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-dialog.component';
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {GruppeDialogInput} from '../../gruppenverwaltung/gruppenuebersicht/gruppe-dialog/gruppe-dialog';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {BelegPositionInlineResult} from '../../beleg-position/beleg-position-inline/beleg-position-inline';
import {PositionDetailsDialogComponent} from './position-details-dialog/position-details-dialog.component';

export class AbgabebelegPositionErledigt {
    position: AbgabebelegPosition;
    erledigt: boolean;

    constructor(position: AbgabebelegPosition, erledigt: boolean) {
        this.position = position;
        this.erledigt = erledigt;
    }
}

@Component({
    selector: 'kiene-abgabebeleg-details',
    templateUrl: './abgabebeleg-details.component.html',
    styleUrls: ['./abgabebeleg-details.component.scss'],
    host: {
        '(window:keydown)': 'checkForArrowKeys($event)',
    },
})
export class AbgabebelegDetailsComponent
    implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('belegPosition') belegPosition: BelegPositionInlineComponent;
    @ViewChild('belegPositionContainer') belegPositionContainer: ElementRef;
    @ViewChild('positionTableContainer') positionTableContainer: ElementRef;
    belegPositionContainerPosition = 0;
    stickyFlag = false;
    positionHeadline = 'Positionen';
    timeMeasuring = undefined;

    @ViewChild('clientGroupSelect') clientGroupSelect: MatSelect;

    abgabebeleg: Abgabebeleg = new Abgabebeleg();

    descriptionCtrl: UntypedFormControl = new UntypedFormControl();

    tierartCtrl: UntypedFormControl = new UntypedFormControl();
    clientGroupCtrl = new UntypedFormControl();
    clientGroups: Gruppe[] = [];

    aktPosition: AbgabebelegPosition;

    gebuehrenbelegCtrl = new UntypedFormControl(false);

    identifikationen: Observable<Identifikation[]>;
    indikationen: Indikation[] = [];
    anwendungen: Anwendung[] = [];

    einzelpreisSubscription: Subscription = null;
    tierartSubscription: Subscription = null;
    anwendungSubscription: Subscription = null;
    abgabebelegSubscription: Subscription = null;
    neighboursSubsciption: Subscription = null;
    indikationSubscription: Subscription = null;

    datumCtrl: UntypedFormControl = new UntypedFormControl();

    isLoading = false;
    isSaving = false;

    isLoadingNextPrevious = false;

    changeCustomer = false;

    watchClientSubscription: Subscription;

    @ViewChild('amountInput')
    amountInput: ElementRef;

    @ViewChild('materialInput')
    materialInput: ElementRef;

    @ViewChild('chargePickerInput')
    chargePickerInput: ElementRef;

    @ViewChild('pickStoragePlaceInput')
    pickStoragePlaceInput: ElementRef;

    @ViewChild('pickAmountInput')
    pickAmountInput: ElementRef;

    @ViewChild('dosingInput')
    dosingInput: ElementRef;

    @ViewChild('tierartSelect')
    tierartSelect: MatSelect;

    @ViewChild('anwendungSelect')
    anwendungSelect: MatSelect;

    @ViewChild('indikationSelect')
    indikationSelect: MatSelect;

    // query parameters for getting the neighbours of the entry
    orderby: string;
    search: string;
    recposition: number;
    recPositionObject: RecPositionObject = new RecPositionObject();

    positionenMitFehlendenChargenListe: AbgabebelegPositionErledigt[] = [];
    currentUserIsAdmin = false;
    currentUserIsPhitoAdmin = false;

    constructor(
        private localStorageService: LocalStorageService,
        private route: ActivatedRoute,
        private router: Router,
        private gruppenService: GruppenService,
        private messageService: MessageService,
        public dialog: MatDialog,
        private positionService: RecPositionService,
        private currentClientService: ChangeCurrentClientService,
        private kieneTableService: KieneTableService,
        private location: Location,
        private abgabebelegService: AbgabebelegService,
        private scrollDispatcher: ScrollDispatcher,
        private ngZone: NgZone,
        private changeDetector: ChangeDetectorRef,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        this.route.queryParams.subscribe((params) => {
            this.orderby = params['orderby'];
            this.search = params['search'];
            this.recposition = params['recposition'];
        });
    }

    hasPermission(id: number) {
        return this.localStorageService.hasPermission(id);
    }

    setzeAktuellePosition(pos: AbgabebelegPosition) {
        console.log('AKtuellePo');
        this.aktPosition = pos;
        if (
            this.isEditingAllowed() &&
            pos.stueckliste === 0 &&
            (!pos.aus_stueckliste || pos.aus_stueckliste === 0)
        ) {
            this.belegPosition.ladePosition(pos);
            this.gruppenService
                .getGruppePraxissoftware(pos?.gruppe_id)
                .subscribe({
                    next: (response) => {
                        const grp = <Gruppe>response;
                        this.clientGroupCtrl.setValue(grp);
                    },
                });
        }
    }

    gibGruppenbezeichnung(group: Gruppe) {
        if (group) {
            let bez = group.bezeichnung; // + ' - ' + group.kunde;
            if (group.alter_wochen) {
                bez += ' - Alter: ' + group.alter_wochen + 'W';
            }
            if (group.herdenname) {
                bez += ' - ' + group.herdenname;
            }
            if (group.stall) {
                bez += ' - ' + group.stall;
            }
            if (group.anzahl_tiere_aktuell) {
                bez += ' - T: ' + group.anzahl_tiere_aktuell;
            }
            return bez;
        }
        return '';
    }

    gibAbgabebelegNr() {
        if (this.isNewAbgabebeleg()) {
            return 'Neu';
        }
        if (this.abgabebeleg?.abgabebeleg_nr) {
            return this.abgabebeleg.abgabebeleg_nr;
        }
        return 'ID:' + this.abgabebeleg?.abgabebeleg_id;
    }

    isNewAbgabebeleg() {
        return !this.abgabebeleg.abgabebeleg_id;
    }

    setKunde(knd: Client) {
        this.abgabebeleg.kunde_id = knd.kunde_id;
        this.abgabebeleg.kunde = knd.bezeichnung_final;
        this.abgabebeleg.tierbewegung_melden = knd.tierbewegung_melden;
        this.abgabebeleg.selbstimpfer = knd.selbstimpfer;
        this.abgabebeleg.betriebsart_kaelbermast = knd.kaelbermast;
        this.abgabebeleg.betriebsart_ohrmarke = knd.ohrmarke;
        this.loadGruppen();
    }

    ngOnInit() {
        const windowRef: ElementRef = new ElementRef<any>(window);
        this.scrollDispatcher.register(
            new CdkScrollable(windowRef, this.scrollDispatcher, this.ngZone)
        );
        this.scrollDispatcher.scrolled().subscribe((data: CdkScrollable) => {
            const yPosition = data.getElementRef().nativeElement.scrollTop;
            this.checkScrollPosition(yPosition);
        });
        this.currentUserIsAdmin = this.localStorageService.getCurrentUser().admin > 0;
        this.currentUserIsPhitoAdmin = this.localStorageService.isCurrentUserPhitoUser();
        this.localStorageService.watchCurrentUser().subscribe((user) => {
            this.currentUserIsAdmin = user.admin > 0;
        });

        this.watchClientSubscription = this.localStorageService
            .watchCurrentClient()
            .subscribe((client) => {
                if (
                    client &&
                    (!this.abgabebeleg.kunde_id || this.changeCustomer)
                ) {
                    this.setKunde(client);
                    this.gruppenService
                        .getStandardGruppePraxissoftware(client.kunde_id)
                        .subscribe((response) => {
                            if (response?.records?.length > 0) {
                                this.clientGroupCtrl.setValue(response.records[0]);
                            }
                        });
                    this.changeCustomer = false;
                }
            });

        this.loadGruppen();

        const id = this.route.snapshot.paramMap.get('id');
        if (id !== null) {
            if (id === 'neu') {
                // neuer Abgabebeleg

                if (this.localStorageService.getCurrentClient() !== null) {
                    const knd = this.localStorageService.getCurrentClient();
                    this.setKunde(knd);
                }

                // this.autocompleteMaterial();
                this.datumCtrl.setValue(new Date());
                this.abgabebeleg.status_id = AbgabebelegStatus.ERSTELLT;
                const user = this.localStorageService.getCurrentUser();
                if (user.tierarzt_id) {
                    this.abgabebeleg.tierarzt_id = user.tierarzt_id;
                    this.abgabebeleg.tierarzt =
                        user.vorname + ' ' + user.nachname;
                }

                if (!this.abgabebeleg?.kunde_id) {
                    this.currentClientService.openChangeDialog('Abgabebeleg');
                }
            } else {
                // Gespeicherter Abgabebeleg

                this.loadAbgabebeleg(Number.parseInt(id, 10));
            }
        }
    }

    ngAfterViewInit() {

        this.belegPositionContainerPosition =
            this.belegPositionContainer?.nativeElement?.offsetTop;
        if (
            this.abgabebeleg &&
            this.abgabebeleg.kunde_id &&
            !this.clientGroupCtrl.value
        ) {
            this.gruppenService
                .getStandardGruppePraxissoftware(this.abgabebeleg.kunde_id)
                .subscribe((response) => {
                    if (response?.records?.length > 0) {
                        this.clientGroupCtrl.setValue(response.records[0]);
                    }
                });
        }

        const client = this.localStorageService.getCurrentClient();
        setTimeout(() => {
            let focusGruppe = false;
            if (client) {
                if ((client.kaelbermast > 0) || (client.rechnung_gruppe)) {
                    focusGruppe = true;
                }
            }

            if (focusGruppe) {
                this.belegPosition?.setFocusOnInit(false);
                this.clientGroupSelect?.focus();
            } else {
                this.belegPosition?.setFocusOnInit(true);
                this.belegPosition?.setFocusArticleAutocomplete();
            }

            this.checkIsEditingAllowed();
        }, 300);
    }

    loadGruppen() {
        if (this.abgabebeleg.kunde_id) {
            const params = new HttpParams().set(
                'kunde_id',
                this.abgabebeleg.kunde_id.toString()
            );
            this.kieneTableService
                .get(this.apiBaseUrl + 'gruppe/read_beleg.php', params)
                .subscribe({
                    next: (response) => {
                        this.clientGroups = response.records;
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Es konnten keine Gruppen zu dem Kunden mit der ID ' +
                            this.abgabebeleg.kunde_id +
                            ' geladen werden!',
                            err
                        );
                    },
                });
        }
    }

    clientGroupComparator(cg1: Gruppe, cg2: Gruppe) {
        if (cg1 && cg2) {
            return cg1?.gruppe_id === cg2?.gruppe_id;
        }
        return false;
    }

    checkIsEditingAllowed() {
        if (!this.isEditingAllowed()) {
            this.datumCtrl.disable();
            this.clientGroupCtrl.disable();
        } else {
            this.datumCtrl.enable();
            this.clientGroupCtrl.enable();
        }
    }

    ngOnDestroy() {
        if (this.watchClientSubscription) {
            this.watchClientSubscription.unsubscribe();
        }
        if (this.tierartSubscription) {
            this.tierartSubscription.unsubscribe();
        }
        if (this.anwendungSubscription) {
            this.anwendungSubscription.unsubscribe();
        }
        if (this.abgabebelegSubscription) {
            this.abgabebelegSubscription.unsubscribe();
        }
        if (this.neighboursSubsciption) {
            this.neighboursSubsciption.unsubscribe();
        }
        if (this.einzelpreisSubscription) {
            this.einzelpreisSubscription.unsubscribe();
        }
        if (this.indikationSubscription) {
            this.indikationSubscription.unsubscribe();
        }
    }

    gruppeBearbeiten() {
        const dialogRef = this.dialog.open(GruppeEditDialogComponent, {
            data: {
                gruppe: this.clientGroupCtrl.value,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.clientGroupCtrl.setValue(result);
            }
            this.clientGroupSelect?.focus();
        });
    }

    gruppeEditierbar(): boolean {
        if (this.clientGroupCtrl.value) {
            return this.clientGroupCtrl.value.bezeichnung !== 'Standard';
        } else {
            return false;
        }
    }

    isPositionEditingVisible() {
        return (
            this.abgabebeleg.status_id === AbgabebelegStatus.ERSTELLT ||
            this.abgabebeleg.status_id === AbgabebelegStatus.WIRD_KORRIGIERT
        );
    }

    isEditingAllowed() {
        return !!(
            (!this.isLoadingOrSaving() &&
                this.isPositionEditingVisible() &&
                this.abgabebeleg.kunde_id) ||
            this.isNewAbgabebeleg()
        );
    }

    unlock() {
        this.kieneTableService
            .post(
                this.apiBaseUrl + 'abgabebeleg/korrigieren.php',
                this.abgabebeleg,
                null
            )
            .subscribe({
                next: () => {
                    this.messageService.infoMessage(
                        'Der Abgabebeleg wurde wieder geöffnet!'
                    );
                    this.reload();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fehler! Der Abgabebeleg konnte nicht geöffnet werden!',
                        err
                    );
                    this.reload();
                },
            });
    }

    reload() {
        if (!this.abgabebeleg.abgabebeleg_id) {
            return;
        }
        this.loadAbgabebeleg(this.abgabebeleg.abgabebeleg_id);
    }

    loadAbgabebeleg(abgabebelegId: number) {
        this.isLoading = true;

        if (this.abgabebelegSubscription !== null) {
            this.abgabebelegSubscription.unsubscribe();
        }
        this.abgabebelegSubscription = this.kieneTableService
            .getOne(
                this.apiBaseUrl + 'abgabebeleg/read_one.php',
                'abgabebeleg_id',
                abgabebelegId.toString()
            )
            .subscribe(
                (response) => {
                    this.abgabebeleg = response;

                    this.loadGruppen();

                    if (this.localStorageService.getCurrentClient() !== null) {
                        if (!this.abgabebeleg.kunde_id) {
                            this.setKunde(
                                this.localStorageService.getCurrentClient()
                            );
                        }
                    }

                    /**
                     * Wenn noch keine Position ausgewaehlt, dann Standardgruppe setzen. Ansonsten die Gruppe der letzten Position.
                     */
                    if (this.abgabebeleg.positionen.length > 0) {
                        const cp =
                            this.abgabebeleg.positionen[
                            this.abgabebeleg.positionen.length - 1
                                ];
                        const gruppeId = cp?.gruppe_id;

                        this.gruppenService
                            .getGruppePraxissoftware(gruppeId)
                            .subscribe({
                                next: (gruppe: Gruppe) => {
                                    this.clientGroupCtrl.setValue(gruppe);
                                },
                                error: (err) => {
                                    this.messageService.errorMessage(
                                        'Die Gruppe mit der ID ' +
                                        gruppeId +
                                        ' konnte nicht geladen werden!',
                                        err
                                    );
                                },
                            });
                    } else {
                        this.gruppenService
                            .getStandardGruppePraxissoftware(
                                this.abgabebeleg.kunde_id
                            )
                            .subscribe({
                                next: (resp) => {
                                    if (resp?.records?.length > 0) {
                                        this.clientGroupCtrl.setValue(
                                            resp.records[0]
                                        );
                                    }
                                },
                            });
                    }

                    if (
                        !this.abgabebeleg.tierarzt_id ||
                        !this.abgabebeleg.abgabebeleg_nr
                    ) {
                        const user = this.localStorageService.getCurrentUser();
                        if (user.tierarzt_id) {
                            this.abgabebeleg.tierarzt_id = user.tierarzt_id;
                            this.abgabebeleg.tierarzt =
                                user.vorname + ' ' + user.nachname;
                        }
                    }

                    this.datumCtrl.setValue(this.abgabebeleg.datum);
                    this.descriptionCtrl.setValue(this.abgabebeleg.bezeichnung);

                    this.loadNeighbours();

                    this.isLoading = false;
                    this.checkIsEditingAllowed();
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Fehler beim Laden des Belegs!',
                        error
                    );
                    this.isLoading = false;
                    this.checkIsEditingAllowed();
                }
            );
    }

    gibGesamtpreis() {
        let gesamtpreis = 0.0;
        for (const p of this.abgabebeleg.positionen) {
            if (
                p.einzelpreis !== null &&
                p.einzelpreis !== undefined &&
                p.einzelpreis > 0
            ) {
                if (
                    p.artikelmenge !== null &&
                    p.artikelmenge !== undefined &&
                    p.artikelmenge > 0
                ) {
                    gesamtpreis += p.einzelpreis * p.artikelmenge;
                }
            }
        }
        return gesamtpreis;
    }

    savePositionen(result: BelegPositionInlineResult) {
        if (result?.positionen?.length > 0) {
            let insertCharge = result?.insertChargen;
            let lastPos = null;
            for (const posi of result?.positionen) {
                if (!posi.aa_id) {
                    this.abgabebeleg.positionen.push(posi);
                } else {
                    const index = this.abgabebeleg.positionen.findIndex(
                        (p) => p.aa_id === posi.aa_id
                    );
                    this.abgabebeleg.positionen.splice(index, 1, posi);
                }
                if (posi.stueckliste > 0) {
                    insertCharge = true;
                }
                lastPos = posi;
            }
            this.scrollToBottom();
            this.save(
                this.abgabebeleg.status_id,
                insertCharge,
                lastPos
            );
        }
    }

    impfkontrollbuecherAbschliessen(kunde_id: number, datum: Date, tierarzt_id: number) {
        this.abgabebelegService.getOffeneImpfkontrollbuecher(kunde_id, datum).subscribe({
            next: (response) => {
                if (response?.count > 0) {
                    this.abgabebelegService.offeneImpfbuecherAbschliessen(datum, response.records, tierarzt_id, false);
                }
            },
            error: err => {
                this.messageService.errorMessage('Impfkontrollbücher konnten nicht geladen werden!', err);
            }
        });
    }

    save(
        statusId: number,
        insertMissingCharge?: boolean,
        position?: AbgabebelegPosition
    ): void {
        if (this.abgabebeleg.status_id === AbgabebelegStatus.ABGESCHLOSSEN) {
            this.messageService.errorMessage(
                'Abgabebeleg wurde bereits abgeschlossen',
                null
            );
            return;
        }

        this.isSaving = true;

        this.abgabebeleg.status_id = statusId;
        this.abgabebeleg.bezeichnung = this.descriptionCtrl.value;
        this.abgabebeleg.datum = this.datumCtrl.value;
        // this.abgabebeleg.tierarzt_id = this.vetCtrl.value ? this.vetCtrl.value.tierarzt_id : null;

        if (!this.abgabebeleg.kunde_id) {
            this.messageService.errorMessage(
                'Bitte einen Kunden auswählen!',
                null
            );
            this.isSaving = false;
            return;
        }

        // als Gebuehrenbeleg abspeichern
        if (this.localStorageService.hasPermission(1240)) {
            if (this.gebuehrenbelegCtrl.value) {
                statusId = 48;
                this.abgabebeleg.status_id = 48;
            }
        }

        const beforePut = performance.now();
        this.kieneTableService
            .updateEntity(this.apiBaseUrl + 'abgabebeleg/update.php', this.abgabebeleg)
            .subscribe({
                next: (response) => {
                    const afterPut = performance.now();
                    this.abgabebeleg = response;
                    if (this.abgabebeleg.dbhinweis) {
                        this.messageService.hintMessage(
                            this.abgabebeleg.dbhinweis
                        );
                    } else {
                        this.messageService.infoMessage(
                            'Der Abgabebeleg wurde erfolgreich gespeichert!'
                        );
                    }
                    // setze URL von /neu auf /abgabebelegId
                    this.location.replaceState(
                        'belege/abgabebelege/' + this.abgabebeleg.abgabebeleg_id
                    );

                    this.isSaving = false;

                    if (this.abgabebeleg.status_id === AbgabebelegStatus.ABGESCHLOSSEN && this.abgabebeleg.kunde_id) {
                        this.impfkontrollbuecherAbschliessen(this.abgabebeleg.kunde_id, this.abgabebeleg.datum, this.abgabebeleg.tierarzt_id);
                    }

                    // oeffne den Chargendialog fuer alle Positionen, bei denen die Chargenangaben nicht komplett sind.
                    if (
                        statusId !== AbgabebelegStatus.ABGESCHLOSSEN &&
                        insertMissingCharge
                    ) {
                        this.initMissingChargeDialog();
                        this.openMissingChargeDialog();
                    } else {
                        // nach dem abschliessen pdf anzeigen
                        if (statusId === AbgabebelegStatus.ABGESCHLOSSEN) {
                            const dialogRef =
                                this.abgabebelegService.openAbgabebelegGesamtPdf(
                                    this.abgabebeleg,
                                    this.abgabebeleg?.kunde_id
                                );

                            dialogRef.subscribe({
                                next: (dialogClosed) => {
                                    if (dialogClosed) {
                                        const params = new HttpParams().set(
                                            'abgabebeleg_id',
                                            this.abgabebeleg.abgabebeleg_id.toString()
                                        );
                                        this.kieneTableService
                                            .get(
                                                this.apiBaseUrl +
                                                'abgabebeleg/read_artikel_tourenplan.php',
                                                params
                                            )
                                            .subscribe({
                                                next: (tourResp) => {
                                                    const positionen: BestellungInternPosition[] =
                                                        tourResp.records;
                                                    const c = new Client();
                                                    c.kunde_id =
                                                        this.abgabebeleg.kunde_id;
                                                    c.bezeichnung_final =
                                                        this.abgabebeleg.kunde;
                                                    if (
                                                        positionen &&
                                                        positionen.length > 0
                                                    ) {
                                                        const tourDialogRef =
                                                            this.dialog.open(
                                                                AddTourDialogComponent,
                                                                {
                                                                    data: {
                                                                        positionen:
                                                                        positionen,
                                                                        client: c,
                                                                    },
                                                                }
                                                            );
                                                        tourDialogRef
                                                            .afterClosed()
                                                            .subscribe(() => {
                                                                this.router
                                                                    .navigate([
                                                                        '/belege',
                                                                        'abgabebelege',
                                                                    ])
                                                                    .then();
                                                            });
                                                    } else {
                                                        this.router
                                                            .navigate([
                                                                '/belege',
                                                                'abgabebelege',
                                                            ])
                                                            .then();
                                                    }
                                                },
                                                error: (err) => {
                                                    this.messageService.errorMessage(
                                                        'Fehler beim Lesen der möglichen Tourenplan Artikel!',
                                                        err
                                                    );
                                                },
                                            });
                                    }
                                },
                            });
                        }

                        this.belegPosition?.felderLeeren(true);
                        this.belegPosition?.setFocusArticleAutocomplete();
                    }

                    if (
                        position?.position <
                        this.abgabebeleg?.positionen?.length
                    ) {
                        const pos =
                            this.abgabebeleg.positionen[position.position];
                        this.belegPosition.ladePosition(pos);
                        this.aktPosition = pos;
                    }

                    this.checkIsEditingAllowed();

                    const saveFinished = performance.now();
                    this.timeMeasuring = 'PUT:' + Math.round(afterPut - beforePut) + 'ms WORK:' + Math.round(saveFinished - afterPut) + 'ms';
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Der Abgabebeleg konnte nicht gespeichert werden!',
                        error
                    );
                    this.isSaving = false;
                    this.abgabebeleg.status_id = AbgabebelegStatus.ERSTELLT;
                    this.checkIsEditingAllowed();
                },
            });
    }

    initMissingChargeDialog() {
        const positionen = this.abgabebeleg.positionen;

        for (const p of positionen) {
            if (p.chargeJN === 1) {
                const sollmenge = p.artikelmenge;
                if (sollmenge > 0) {
                    let chargenmengen = 0;
                    for (const c of p.chargen) {
                        chargenmengen += c.chargenmenge;
                    }
                    // es fehlen noch chargen
                    if (sollmenge > chargenmengen) {
                        this.positionenMitFehlendenChargenListe.push(
                            new AbgabebelegPositionErledigt(p, false)
                        );
                    }
                }
            }
        }
    }

    openMissingChargeDialog() {
        const missingPosition = this.getNextMissingChargePosition();

        if (missingPosition != null) {
            if (!this.abgabebeleg.tierarzt_id) {
                this.messageService.alertMessage(
                    'Dieser Beleg ist keinem Tierarzt zugewiesen!'
                );
                return;
            }

            const dialogRef = this.dialog.open(
                AbgabebelegChargeDialogComponent,
                {
                    data: {
                        abgabebelegPosition: missingPosition,
                        tierarztId: this.abgabebeleg.tierarzt_id,
                    },
                }
            );

            dialogRef.afterClosed().subscribe((response) => {
                if (response) {
                    const chargen: AbgabebelegCharge[] = response;
                    const positionChargen: PositionCharge[] = [];
                    for (const c of chargen) {
                        if (c.menge_beleg > 0) {
                            const charge: PositionCharge = new PositionCharge();
                            charge.charge_id = c.charge_id;
                            charge.chargenmenge = c.menge_beleg;
                            charge.lagerplatz_id = c.lagerplatz_id;
                            positionChargen.push(charge);
                        }
                    }

                    missingPosition.chargen = positionChargen;
                    this.updatePositionInAbgabebeleg(missingPosition);
                }
                this.positionIstErledigt(missingPosition);
                this.openMissingChargeDialog();
            });
        } else {
            this.save(this.abgabebeleg.status_id, false);
        }
    }

    positionIstErledigt(missingPosition: AbgabebelegPosition) {
        const positionIndex = this.positionenMitFehlendenChargenListe.findIndex(
            (pe) => pe.position.aa_id === missingPosition.aa_id
        );
        this.positionenMitFehlendenChargenListe[positionIndex].erledigt = true;
    }

    updatePositionInAbgabebeleg(missingPosition: AbgabebelegPosition) {
        const positionIndex = this.abgabebeleg.positionen.findIndex(
            (p) => p.aa_id === missingPosition.aa_id
        );
        this.abgabebeleg.positionen.splice(positionIndex, 1, missingPosition);
    }

    getNextMissingChargePosition(): AbgabebelegPosition {
        for (const pe of this.positionenMitFehlendenChargenListe) {
            if (!pe.erledigt) {
                return pe.position;
            }
        }
        // alles erledigt
        return null;
    }

    isLoadingOrSaving() {
        return this.isLoading || this.isSaving;
    }

    goBack() {
        window.history.go(-1);
    }

    checkScrollPosition(yPosition: number) {
        const pos = yPosition + 64;
        if (pos >= this.belegPositionContainerPosition) {
            if (!this.stickyFlag) {
                // this.stickyFlag = true;
                this.changeDetector.detectChanges();
                this.positionHeadline = 'Positionen true';
            }
        } else {
            if (this.stickyFlag) {
                // this.stickyFlag = false
                this.changeDetector.detectChanges();
                this.positionHeadline = 'Positionen false';
            }
        }
    }

    checkForArrowKeys(event) {
        const keyPressed: string = event.code;
        setTimeout(() => {
        }, 200);

        if (
            event.altKey &&
            event.code === 'ArrowRight' &&
            !this.isLoadingNextPrevious
        ) {
            event.preventDefault();
            this.navigateToNext();
        } else if (
            event.altKey &&
            event.code === 'ArrowLeft' &&
            !this.isLoadingNextPrevious
        ) {
            event.preventDefault();
            this.navigateToPrevious();
        } else if (event.altKey && event.code === 'F10') {
            const activeElement = document.activeElement;
            activeElement.classList.add('active-element');
        } else if (event.code === 'F12') {
            this.save(AbgabebelegStatus.ERSTELLT);
        }

        if (
            (keyPressed.startsWith('Numpad') ||
                keyPressed.startsWith('Digit')) &&
            !this.isKeyEventFromInput(event)
        ) {
            let numberString: string;
            if (keyPressed.startsWith('Digit')) {
                numberString = keyPressed.split('Digit')[1];
            } else {
                numberString = keyPressed.split('Numpad')[1];
            }
            const pos = Number.parseInt(numberString, 10);
            setTimeout(() => {
                this.belegPosition.ladePosition(
                    this.abgabebeleg.positionen[pos - 1]
                );
            }, 10);
        }
    }

    loadNeighbours() {
        this.isLoadingNextPrevious = true;
        if (this.neighboursSubsciption !== null) {
            this.neighboursSubsciption.unsubscribe();
        }
        let kunde_id = null;
        if (this.localStorageService.getCurrentClient() !== null) {
            kunde_id = this.localStorageService.getCurrentClient().kunde_id;
        }
        this.neighboursSubsciption = this.positionService
            .getNeighbours(
                'abgabebeleg',
                this.orderby,
                this.search,
                this.recposition,
                kunde_id
            )
            .subscribe({
                next: (response) => {
                    this.recPositionObject = response;
                    this.recposition = this.recPositionObject.curr.recposition;
                    this.isLoadingNextPrevious = false;
                },
                error: () => {
                    this.isLoadingNextPrevious = false;
                },
            });
    }

    navigateToPrevious() {
        const previd = this.recPositionObject.prev.id;
        this.recposition = this.recPositionObject.prev.recposition;
        this.loadAbgabebeleg(previd);
    }

    navigateToNext() {
        const nextid = this.recPositionObject.next.id;
        this.recposition = this.recPositionObject.next.recposition;
        this.loadAbgabebeleg(nextid);
    }

    removePosition(p: AbgabebelegPosition) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
        dialogRef.afterClosed().subscribe({
            next: result => {
                if (result) {
                    const index = this.abgabebeleg.positionen.indexOf(p);
                    if (p.stueckliste === 1) {
                        this.removeStuecklistenpositionen(p);
                    }
                    this.abgabebeleg.positionen.splice(index, 1);
                    this.save(this.abgabebeleg.status_id);
                }
            }
        });

    }

    removeStuecklistenpositionen(pos: AbgabebelegPosition) {
        const liste = [];
        for (const p of this.abgabebeleg.positionen) {
            if (p.aus_stueckliste === pos.aa_id) {
                liste.push(p);
            }
        }
        for (const l of liste) {
            const index = this.abgabebeleg.positionen.indexOf(l);
            this.abgabebeleg.positionen.splice(index, 1);
        }
    }

    removeCurrentRowFromDataSource() {
        const index = this.abgabebeleg.positionen.indexOf(this.aktPosition);
        if (index >= 0) {
            this.abgabebeleg.positionen.splice(index, 1);
            this.save(AbgabebelegStatus.ERSTELLT);
        }
    }

    editCustomer() {
        this.changeCustomer = true;
        this.currentClientService.openChangeDialog('AbgabebelegDetails');
    }

    getArt(pos: AbgabebelegPosition) {
        if (pos.behandlung > 0) {
            return 'B';
        }
        return 'A';
    }

    sortPositions() {
        const positions = [];
        // erst Behandlungen
        for (const p of this.abgabebeleg.positionen) {
            if (p.behandlung > 0) {
                positions.push(p);
            }
        }
        // dann den Rest (=Abgaben)
        for (const p of this.abgabebeleg.positionen) {
            if (p.behandlung <= 0) {
                positions.push(p);
            }
        }
        this.abgabebeleg.positionen = positions;
    }

    setTierarzt(vet: Vet) {
        this.abgabebeleg.tierarzt_id = vet.tierarzt_id;
        this.abgabebeleg.tierarzt = vet.vorname + ' ' + vet.nachname;
    }

    openGruppeAddDialog(event) {
        event.stopPropagation();
        if (this.abgabebeleg?.kunde_id) {
            this.kieneTableService.getOne(this.apiBaseUrl + 'kunde/read_one.php', 'kunde_id', this.abgabebeleg?.kunde_id?.toString()).subscribe({
                next: (kunde: Client) => {
                    const input = new GruppeDialogInput(kunde, false, undefined);
                    this.dialog.open<GruppeDialogComponent, GruppeDialogInput, Gruppe>(GruppeDialogComponent, {
                        data: input,
                    }).afterClosed().subscribe((gruppe) => {
                        if (gruppe) {
                            this.clientGroupCtrl.setValue(gruppe);
                            this.loadGruppen();
                        }
                    });
                },
                error: err => {
                    this.messageService.errorMessage('Fehler beim Laden des Kunden!', err);
                }
            });
        }
    }

    getTiereString(tiere: GruppeTier[]) {
        let text = '';
        if (tiere) {
            for (const t of tiere) {
                text += t.ohrmarke + ', ';
            }
            if (text?.endsWith(', ')) {
                text = text.substring(0, text.length - 2);
            }
        }
        return text;
    }

    getIdentifikationText(position: AbgabebelegPosition) {
        if (!position) {
            return null;
        }
        let text = position?.identifikation;

        if (position.tiere && position.tiere.length > 0) {
            if (position.tiere.length < 10) {
                text = this.getTiereString(position.tiere);
            } else {
                text = position.tiere.length + ' Tiere ausgewählt';
            }
        }

        return text;
    }

    cutText(text: string, numberOfChars: number) {
        const tmpText = String(text);
        if (tmpText.length >= numberOfChars) {
            let t = tmpText.substr(0, numberOfChars - 3);
            t = t + '...';
            return t;
        }
        return text;
    }

    drop(event: CdkDragDrop<AbgabebelegPosition[]>) {
        moveItemInArray(
            this.abgabebeleg.positionen,
            event.previousIndex,
            event.currentIndex
        );
        this.save(this.abgabebeleg.status_id);
    }

    /**
     * Recht 3058
     * @param p
     */
    einzelpreisEditieren(p: AbgabebelegPosition) {
        const input = new EnterValueInput();
        input.title = 'Einzelpreis ändern';
        input.text = 'Bitte geben Sie den Einzelpreis für diese Position ein.';
        input.okButton = 'Speichern';
        input.buttonColor = 'accent';
        input.addInputValue('Einzelpreis', 'decimal', p.einzelpreis);

        const dialogRef = this.dialog.open(EnterValueDialogComponent, {data: input});

        dialogRef.afterClosed().subscribe((result: EnterValueResponse) => {
            if (result.result) {
                p.einzelpreis = result.getValue(0);
                this.kieneTableService
                    .updateGeneric(
                        this.apiBaseUrl +
                        'abgabebeleg/update_einzelpreis.php',
                        p
                    )
                    .subscribe(
                        () => {
                            this.messageService.infoMessage(
                                'Der Einzelpreis wurde erfolgreich gespeichert!'
                            );
                            this.loadAbgabebeleg(
                                this.abgabebeleg.abgabebeleg_id
                            );
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Der Einzelpreis konnte nicht gespeichert werden!',
                                error
                            );
                        }
                    );
            }
        });
    }

    preisEditierbar(): boolean {
        if (this.abgabebeleg?.status_id === AbgabebelegStatus.ERSTELLT || this.abgabebeleg?.status_id === AbgabebelegStatus.WIRD_KORRIGIERT) {
            return (
                this.localStorageService.hasPermission(3058) &&
                !this.abgabebeleg.rechnung_ids
            );
        } else {
            return false;
        }
    }

    addGruppeVisible() {
        return (
            this.abgabebeleg?.tierbewegung_melden === 0 &&
            this.localStorageService.hasPermission(1902) &&
            (!this.clientGroupCtrl.value ||
                this.clientGroupCtrl.value.bezeichnung === 'Standard')
        );
    }

    gibGruppePos(p: AbgabebelegPosition) {
        let s = p.gruppe;
        if (p.herdenname) {
            s = s + ', ' + p.herdenname;
        }
        if (p.stall) {
            s = s + ', ' + p.stall;
        }
        return s;
    }

    openAbgabebelegPdf() {
        this.abgabebelegService.openAbgabebelegGesamtPdf(
            this.abgabebeleg,
            this.abgabebeleg?.kunde_id
        );
    }

    getStatusMeldungTooltipHit(p: AbgabebelegPosition) {
        let tooltip = '';
        if (p.status_id_hit) {
            tooltip += 'HIT Meldung: ' + p.status_hit;
            if (p.gemeldet_hit) {
                const d = new Date(p.gemeldet_hit);
                tooltip += ' am: ' + d.toLocaleDateString('de-DE');
                tooltip += ' um: ' + d.toLocaleTimeString('de-DE');
            }
        }
        return tooltip;
    }

    getStatusMeldungTooltipQs(p: AbgabebelegPosition) {
        let tooltip = '';
        if (p.status_id_qs) {
            tooltip += 'QS Meldung: ' + p.status_qs;
            if (p.gemeldet_qs) {
                const d = new Date(p.gemeldet_qs);
                tooltip += ' am: ' + d.toLocaleDateString('de-DE');
                tooltip += ' um: ' + d.toLocaleTimeString('de-DE');
            }
        }
        return tooltip;
    }

    fehlerhafteMeldungKorrigieren(position: AbgabebelegPosition) {
        const dialogRef = this.dialog.open(MeldungKorrekturDialogComponent, {
            data: {
                position: position,
                abgabebeleg: this.abgabebeleg,
            },
        });

        dialogRef.afterClosed().subscribe({
            next: () => {
                this.reload();
            },
        });
    }

    openPositionDetailsDialog(p: AbgabebelegPosition) {
        this.dialog.open(PositionDetailsDialogComponent, {
            data: {
                p: p,
                abgabebeleg: this.abgabebeleg
            }
        }).afterClosed().subscribe({
            next: result => {
                if (result) {
                    this.reload();
                }
            }
        });
    }

    protected scrollToBottom(): void {
        setTimeout(() => {
            this.positionTableContainer.nativeElement.scroll({
                top: this.positionTableContainer.nativeElement.scrollHeight,
                left: 0,
                behavior: 'smooth'
            });
        }, 100);
    }

    private isKeyEventFromInput(event: KeyboardEvent): boolean {
        if (event.target instanceof Node) {
            switch (event.target.nodeName) {
                case 'INPUT':
                case 'SELECT':
                case 'TEXTAREA':
                    return true;
                default:
                    return false;
            }
        }
        return false;
    }
}
