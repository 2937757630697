import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Client } from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-gruppenuebersicht',
    templateUrl: './gruppenuebersicht.component.html',
    styleUrls: ['./gruppenuebersicht.component.css']
})
export class GruppenuebersichtComponent implements OnInit, OnChanges {

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

}
