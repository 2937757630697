import {Client} from "../../../client/classes/client";
import {StammdatenService} from "../../../stammdaten/stammdaten.service";
import {MessageService} from "../../../../services/message-service/message.service";
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {Futtermittellieferant} from '../lieferant';

@Component({
    selector: 'kiene-lieferant-kunden-nr-verwalten',
    templateUrl: './lieferant-kunden-nr-verwalten.component.html',
    styleUrls: ['./lieferant-kunden-nr-verwalten.component.scss']
})
export class LieferantKundenNrVerwaltenComponent implements OnInit, OnDestroy {

    vvvoSubscription: Subscription;
    lieferantSubscription: Subscription;
    isLoading = false;

    @Input('futtermittellieferant')
    futtermittellieferant: Futtermittellieferant;
    kunden: Client[] = [];
    redirectTo = 'einstellungen-stammdaten-lieferanten';
    ersteinrichtung = false;

    @Input('dialog') dialog = false;

    @Output('saved') saved: EventEmitter<Futtermittellieferant> = new EventEmitter();

    constructor(private route: ActivatedRoute, private router: Router, private stammdatenService: StammdatenService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {

        if (this.futtermittellieferant) {
            this.isLoading = false;
        }

        this.route.queryParams.subscribe(params => {
            this.ersteinrichtung = !!params.ersteinrichtung;
            if (this.ersteinrichtung) {
                this.redirectTo = 'registrierung-ersteinrichtung';
            }
        });

        this.route.params.subscribe(params => {
            if (params.futtermittellieferant_id) {
                this.lieferantSubscription?.unsubscribe();
                this.lieferantSubscription = this.stammdatenService.getFuttermittellieferant(params.futtermittellieferant_id).subscribe({
                    next: (futtermittellieferant) => {
                        this.isLoading = false;
                        this.futtermittellieferant = futtermittellieferant;
                    }, error: err => {
                        this.isLoading = false;
                        this.messageService.errorMessage('Fehler!', err);
                    }
                });
            }
        });

        this.route.queryParams.subscribe(qp => {
            if (qp.redirectTo) {
                this.redirectTo = qp.redirectTo;
            }
        });

    }

    /**
     * sofern eine Route als queryParam ueber die URL mitgegeben wurde im Format aaa-bbb-ccc, wird dort hingeleitet
     */
    speichern() {
        this.isLoading = true;
        this.stammdatenService.createOrUpdateFuttermittellieferant(this.futtermittellieferant).subscribe({
            next: resp => {
                this.isLoading = false;
                if (this.dialog) {
                    this.saved.emit(resp);
                } else {
                    if (this.redirectTo) {
                        this.router.navigate(['../../../'], {relativeTo: this.route}).then();
                    } else {
                        window.history.back();
                    }
                }

            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
                this.isLoading = false;
            }
        });
    }

    ngOnDestroy(): void {
        this.vvvoSubscription?.unsubscribe();
        this.lieferantSubscription?.unsubscribe();
    }


}
