import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {KieneBackendApiService} from '../../../services/backend-api/kiene-backend-api.service';

@Component({
    selector: 'kiene-csv-download',
    templateUrl: './csv-download.component.html',
    styleUrls: ['./csv-download.component.scss']
})
export class CsvDownloadComponent implements OnInit {
    csvBlob: Blob;
    csvText: string;
    headlineArray: string[];
    linesArray = [];
    private isDownloading = false;

    constructor(@Inject(MAT_DIALOG_DATA) private data,
                public dialogRef: MatDialogRef<CsvDownloadComponent>,
                private api: KieneBackendApiService) {
    }

    ngOnInit(): void {
        this.initDownload();
    }

    private initDownload() {
        this.isDownloading = true;
        this.api.getBlob(this.data.url, this.data.params).subscribe({
            next: response => {
                this.isDownloading = false;
                this.csvBlob = response;
                this.csvBlob.text().then(value => {
                    this.prepareCsv(value);
                });
            },
            error: err => {
                this.isDownloading = false;
            }
        });
    }

    private prepareCsv(value: string) {
        const lines = value.split('\n');
        const headline = lines[0];
        this.headlineArray = headline.split(';');
        for (let i = 1; i < lines.length; i++) {
            this.linesArray.push(lines[i].split(';'));
        }
    }
}
