<div style="padding: 4px; padding-top: 6px;" *kienePermission="[1231]">
    <div *ngIf="isLoadingOrSaving()" class="kiene-loading-spinner">
        <mat-spinner *ngIf="isLoadingOrSaving()"></mat-spinner>
    </div>

    <div #belegPositionContainer [ngClass]="{ sticky: this.stickyFlag }">
        <div class="one-row" style="font-size: 0.8em">
            <div class="one-row" style="max-width: 33%; width: fit-content">
                <button style="display: inline-block" (click)="goBack()" color="primary" mat-icon-button
                        matTooltip="zurück">
                    <mat-icon>arrow_back</mat-icon>
                </button>
                <h4 *kienePermission="[1233]" style="display: inline-block; margin-left: 20px">
                    {{ gibAbgabebelegNr() }}<br/><a class="kiene-link" target="_blank" [routerLink]="[
                            '/stammdaten',
                            'betriebe',
                            abgabebeleg?.betrieb_id
                        ]" [queryParams]="{'kunde_id':abgabebeleg?.kunde_id}" [matTooltip]="
                            'Kunde ID: ' +
                            abgabebeleg.kunde_id">{{ abgabebeleg.kunde }}</a>
                </h4>
                <h4 *ngIf="!hasPermission(1233)">Beleg Nr.: {{ gibAbgabebelegNr() }}<br>{{ abgabebeleg.kunde }}</h4>
                <button *kienePermission="[1233]" (click)="editCustomer()" color="primary" mat-icon-button
                        matTooltip="Kunden editieren" [disabled]="!isEditingAllowed()">
                    <mat-icon>edit</mat-icon>
                </button>
                <span class="spacer"></span>
            </div>

            <button *kienePermission="[1233]" [disabled]="!abgabebeleg?.abgabebeleg_id" mat-icon-button color="primary"
                    matTooltip="Neu laden" (click)="reload()">
                <mat-icon>refresh</mat-icon>
            </button>

            <button *kienePermission="[1233]" [disabled]="!isEditingAllowed()" mat-icon-button color="primary"
                    matTooltip="Zwischenspeichern" (click)="save(abgabebeleg.status_id)">
                <mat-icon>save</mat-icon>
            </button>

            <ng-container *ngIf="abgabebeleg?.status_id === 42">
                <button *kienePermission="[1245]" [disabled]="isEditingAllowed() || abgabebeleg.status_id === 47"
                        mat-icon-button color="primary" matTooltip="Abgabebeleg wieder &ouml;ffnen" (click)="unlock()">
                    <mat-icon>lock_open</mat-icon>
                </button>
            </ng-container>

            <div *ngIf="
                    abgabebeleg.positionen.length > 0 &&
                    (abgabebeleg.status_id == 41 || abgabebeleg.status_id == 46)">
                <button *kienePermission="[1237]" [disabled]="!isEditingAllowed" mat-icon-button color="primary"
                        (click)="save(42)" matTooltip="Abgabebeleg abschlie&szlig;en">
                    <mat-icon>lock</mat-icon>
                </button>
            </div>

            <button *ngIf="abgabebeleg.pdf_id" mat-icon-button color="accent" matTooltip="Abgabebeleg PDF anzeigen"
                    (click)="openAbgabebelegPdf()">
                <mat-icon>cloud_download</mat-icon>
            </button>

            <p *ngIf="!hasPermission(1244)">
                Tierarzt: {{ abgabebeleg.tierarzt }}
            </p>

            <kiene-autocomplete style="margin-right: 6px; width: 170px" [focus]="false" *kienePermission="[1244]"
                                [kieneObject]="{
                    tierarzt_id: abgabebeleg.tierarzt_id,
                    vorname: abgabebeleg.tierarzt
                        ? abgabebeleg.tierarzt.split(' ')[0]
                        : '',
                    nachname: abgabebeleg.tierarzt
                        ? abgabebeleg.tierarzt.split(' ')[1]
                        : ''
                }" (elementSelected)="setTierarzt($event)" [placeholder]="'Tierarzt'" [apiUrl]="'tierarzt/'"
                                [displayFields]="['vorname', 'nachname']"
                                [disabled]="!isEditingAllowed()"></kiene-autocomplete>

            <mat-form-field *kienePermission="[1233]" style="margin-right: 6px; width: 120px">
                <mat-label>Belegdatum</mat-label>
                <input matInput [matDatepicker]="picker" [formControl]="datumCtrl"/>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <span *ngIf="!hasPermission(1233)">Belegdatum: {{ datumCtrl?.value | date: 'dd.MM.yyyy' }}</span>

            <mat-form-field *kienePermission="[1233]" style="width: 400px">
                <mat-label>Gruppe</mat-label>
                <mat-select #clientGroupSelect [formControl]="clientGroupCtrl" [compareWith]="clientGroupComparator">
                    <mat-option *ngFor="let group of clientGroups" [value]="group">
                        {{ gibGruppenbezeichnung(group) }}
                    </mat-option>
                </mat-select>

                <button *ngIf="addGruppeVisible()" mat-icon-button color="accent" (click)="openGruppeAddDialog($event)"
                        matTooltip="Neue Gruppe anlegen" matSuffix [disabled]="!isEditingAllowed()">
                    <mat-icon>add</mat-icon>
                </button>

                <button *ngIf="gruppeEditierbar()" mat-icon-button [matMenuTriggerFor]="gruppeBearbeitenMenu" matSuffix
                        (click)="$event.stopPropagation()" [disabled]="!isEditingAllowed()">
                    <mat-icon>edit</mat-icon>
                </button>
                <mat-menu #gruppeBearbeitenMenu="matMenu">
                    <button mat-menu-item (click)="gruppeBearbeiten()">
                        <mat-icon>edit</mat-icon>
                        <span>Gruppe Bearbeiten</span>
                    </button>
                </mat-menu>
            </mat-form-field>

            <span *ngIf="!hasPermission(1233)">Gruppe: {{ clientGroupCtrl?.value?.bezeichnung }}
                {{ clientGroupCtrl.value?.stall }}</span>


            <div class="status-badge">
                <span>{{
                        isNewAbgabebeleg() ? "Neu" : abgabebeleg.status
                    }}</span>
            </div>

        </div>

        <div style="width: 99%">
            <div *kienePermission="[1233]">
                <kiene-beleg-position-inline #belegPosition [disabled]="isSaving || !isEditingAllowed()"
                                             [kundeId]="abgabebeleg.kunde_id" [gruppe]="clientGroupCtrl?.value"
                                             [tierarztId]="abgabebeleg.tierarzt_id" (speichern)="savePositionen($event)"
                                             [hotkeyEnabled]="true">
                </kiene-beleg-position-inline>
            </div>
        </div>
    </div>
    <div style="width: 100%; font-size: 0.8em;">
        <div class="one-row">
            <div class="one-row" style="width: 33%;">

                <!--                <h4>{{ positionHeadline }}</h4>-->
                <button *ngIf="isEditingAllowed()" mat-icon-button (click)="sortPositions()"
                        matTooltip="Positionen sortieren">
                    <mat-icon style="transform: scale(.8)">sort_by_alpha</mat-icon>
                </button>
                <span class="spacer"></span>
            </div>
            <div *ngIf="hasPermission(1229)" style="width: 33%; text-align: center;">
                <span>Gesamtpreis Netto: {{ gibGesamtpreis() | number: "1.2-2" }} €</span>
            </div>
            <ng-container *ngIf="currentUserIsPhitoAdmin">
                <span class="spacer"></span>
                <span>{{ timeMeasuring }}</span>
            </ng-container>
            <span class="spacer"></span>
            <div *ngIf="
                    abgabebeleg.positionen.length > 0 &&
                    (abgabebeleg.status_id == 41 || abgabebeleg.status_id == 46)
                " class="one-row" style="width: 33%;">
                <span class="spacer"></span>
                <mat-checkbox *kienePermission="[1240]" [formControl]="gebuehrenbelegCtrl" style="margin-right: 24px">
                    Als Geb&uuml;hrenbeleg abschlie&szlig;en?
                </mat-checkbox>
                <!--                <button *kienePermission="[1237]" [disabled]="!isEditingAllowed" mat-flat-button color="primary"-->
                <!--                        (click)="save(42)">-->
                <!--                    abschlie&szlig;en-->
                <!--                </button>-->
            </div>
        </div>
        <div #positionTableContainer style="overflow-y: auto; max-height: calc(100vh - 420px); padding-bottom: 0;"
             cdkScrollable>
            <table style="width: 100%; text-align: left;" class="position-tabelle">
                <thead>
                <tr>
                    <th>Pos</th>
                    <th>Grp.</th>
                    <th>Artikel</th>
                    <th>Tierart</th>
                    <th>Anwendung</th>
                    <th>Diagnose</th>
                    <th>Art</th>
                    <th>Tiere</th>
                    <th>AD</th>
                    <th>Menge</th>
                    <th>Gewicht</th>
                    <th>Dos.</th>
                    <th>Ident.</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let p of abgabebeleg.positionen; let i = index" (click)="setzeAktuellePosition(p)">
                    <td>{{ i + 1 }}.</td>
                    <td>{{ p.gruppe }}.</td>
                    <td>
                        <div class="artikel-check-row">
                            <span>{{ p.artikel_me }}</span>
                            <div style="margin-left: 6px;">
                                <div *ngIf="p.status_id_hit === 113" [matTooltip]="p.fehler_hit">
                                    <mat-icon (click)="fehlerhafteMeldungKorrigieren(p)"
                                              style="color: #bc4b51; transform: scale(.8);">
                                        error
                                    </mat-icon>
                                </div>
                                <div
                                    *ngIf="p.status_id_hit === 112 || p.status_id_hit === 115 || p.status_id_hit === 116"
                                    [matTooltip]="getStatusMeldungTooltipHit(p)">
                                    <mat-icon style="color: #8cb369; transform: scale(.8);">check_circle</mat-icon>
                                </div>
                                <div *ngIf="p.status_id_hit === 111 || p.status_id_hit === 114"
                                     [matTooltip]="getStatusMeldungTooltipHit(p)">
                                    <mat-icon style="color: #f4a259; transform: scale(.8);">circle</mat-icon>
                                </div>
                                <!-- QS -->
                                <div *ngIf="p.status_id_qs === 113" [matTooltip]="p.fehler_qs">
                                    <mat-icon (click)="fehlerhafteMeldungKorrigieren(p)"
                                              style="color: #bc4b51; transform: scale(.8);">
                                        error
                                    </mat-icon>
                                </div>
                                <div
                                    *ngIf="p.status_id_qs === 112 || p.status_id_qs === 115 || p.status_id_qs === 116"
                                    [matTooltip]="getStatusMeldungTooltipQs(p)">
                                    <mat-icon style="color: #8cb369; transform: scale(.8);">check_circle</mat-icon>
                                </div>
                                <div *ngIf="p.status_id_qs === 111 || p.status_id_qs === 114"
                                     [matTooltip]="getStatusMeldungTooltipQs(p)">
                                    <mat-icon style="color: #f4a259; transform: scale(.8);">circle</mat-icon>
                                </div>
                            </div>
                            <span class="sapcer"></span>
                        </div>
                    </td>
                    <td>{{ p.tierart }}</td>
                    <td>{{ p.anwendung }}</td>
                    <td>{{ p.diagnose }}</td>
                    <td>{{ getArt(p) }}</td>
                    <td>{{ p.anzahl_tiere }}</td>
                    <td>{{ p.anwendungsdauer }}</td>
                    <td>{{ p.artikelmenge }}</td>
                    <td>{{ p.tiergewicht }}</td>
                    <td>{{ p.dosierung }}</td>
                    <td>{{ getIdentifikationText(p) | slice: 0 : 20 }}</td>
                    <td>
                        <mat-icon style="transform: scale(.8); cursor: pointer;" (click)="openPositionDetailsDialog(p)">
                            info_outlined
                        </mat-icon>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <!--        <div style="overflow-y: auto; max-height: calc(100vh - 420px); padding-bottom: 0px;" cdkScrollable>-->
        <!--            <mat-card>-->
        <!--                <div class="position-list" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="-->
        <!--                    abgabebeleg.status_id == 42 || abgabebeleg.status_id == 47-->
        <!--                ">-->
        <!--                    <div style="width: 100%; font-size: .8em;" *ngIf="abgabebeleg?.positionen?.length > 0">-->
        <!--                        <div class="flex-grid position headline" style="width: 100%; text-align: left">-->
        <!--                            <div style="width: 2%">Pos.</div>-->
        <!--                            <div style="width: 1%"></div>-->
        <!--                            <div style="width: 1%"></div>-->
        <!--                            <div style="width: 22%">Artikel</div>-->
        <!--                            <div style="width: 5%">Tierart</div>-->
        <!--                            <div style="width: 7%">Anwendung</div>-->
        <!--                            <div style="width: 12%">Diagnose</div>-->
        <!--                            <div style="width: 2%">Art</div>-->
        <!--                            <div style="width: 5%">Tiere</div>-->
        <!--                            <div style="width: 20px;">AD</div>-->
        <!--                            <div style="width: 4%">Menge</div>-->
        <!--                            <div style="width: 5%">Gewicht</div>-->
        <!--                            <div style="width: 4%">Dos.</div>-->
        <!--                            <div style="width: 48px;">Identf.</div>-->
        <!--                            &lt;!&ndash;                            <div style="width: 5%">Wartez. Fl.</div>&ndash;&gt;-->
        <!--                            <div style="width: 5%" *ngIf="hasPermission(1229)">Einzelpreis</div>-->
        <!--                            <div style="width: 3%;"></div>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <div cdkDrag class="position_row" style="width: 100%; font-size: 1em;"-->
        <!--                         *ngFor="let p of abgabebeleg.positionen; let i = index" (click)="setzeAktuellePosition(p)"-->
        <!--                         cdkDragLockAxis="y" cdkDragBoundary=".position-list">-->
        <!--                        <div style="width: 100%">-->
        <!--                            <div class="flex-grid position" [ngClass]="aktPosition === p ? 'current-row' : ''" style="-->
        <!--                                width: 100%;-->
        <!--                                margin-top: 12px;-->
        <!--                                margin-bottom: 5px;-->
        <!--                            ">-->
        <!--                                <div class="hit-col" style="width: 2%">-->
        <!--                                    <div>{{ i + 1 }}.</div>-->
        <!--                                </div>-->
        <!--                                &lt;!&ndash; HIT &ndash;&gt;-->
        <!--                                <div class="hit-col" style="width: 1%">-->
        <!--                                    <div *ngIf="p.status_id_hit === 113" [matTooltip]="p.fehler_hit">-->
        <!--                                        <mat-icon (click)="fehlerhafteMeldungKorrigieren(p)"-->
        <!--                                                  style="color: #bc4b51; transform: scale(.8);">-->
        <!--                                            error-->
        <!--                                        </mat-icon>-->
        <!--                                    </div>-->
        <!--                                    <div-->
        <!--                                        *ngIf="p.status_id_hit === 112 || p.status_id_hit === 115 || p.status_id_hit === 116"-->
        <!--                                        [matTooltip]="getStatusMeldungTooltipHit(p)">-->
        <!--                                        <mat-icon style="color: #8cb369; transform: scale(.8);">check_circle</mat-icon>-->
        <!--                                    </div>-->
        <!--                                    <div *ngIf="p.status_id_hit === 111 || p.status_id_hit === 114"-->
        <!--                                         [matTooltip]="getStatusMeldungTooltipHit(p)">-->
        <!--                                        <mat-icon style="color: #f4a259; transform: scale(.8);">circle</mat-icon>-->
        <!--                                    </div>-->
        <!--                                </div>-->
        <!--                                &lt;!&ndash; QS &ndash;&gt;-->
        <!--                                <div class="hit-col" style="width: 1%">-->
        <!--                                    <div *ngIf="p.status_id_qs === 113" [matTooltip]="p.fehler_qs">-->
        <!--                                        <mat-icon (click)="fehlerhafteMeldungKorrigieren(p)"-->
        <!--                                                  style="color: #bc4b51; transform: scale(.8);">-->
        <!--                                            error-->
        <!--                                        </mat-icon>-->
        <!--                                    </div>-->
        <!--                                    <div-->
        <!--                                        *ngIf="p.status_id_qs === 112 || p.status_id_qs === 115 || p.status_id_qs === 116"-->
        <!--                                        [matTooltip]="getStatusMeldungTooltipQs(p)">-->
        <!--                                        <mat-icon style="color: #8cb369; transform: scale(.8);">check_circle</mat-icon>-->
        <!--                                    </div>-->
        <!--                                    <div *ngIf="p.status_id_qs === 111 || p.status_id_qs === 114"-->
        <!--                                         [matTooltip]="getStatusMeldungTooltipQs(p)">-->
        <!--                                        <mat-icon style="color: #f4a259; transform: scale(.8);">circle</mat-icon>-->
        <!--                                    </div>-->
        <!--                                </div>-->

        <!--                                <div class="artikel-col" style="width: 22%; font-weight: 600">-->
        <!--                                    {{ p.artikel_me }}-->
        <!--                                </div>-->
        <!--                                <div class="ean-col" style="width: 5%">-->
        <!--                                    {{ p.tierart }}-->
        <!--                                </div>-->
        <!--                                <div class="amount-col" style="width: 7%">-->
        <!--                                    {{ p.anwendung }}-->
        <!--                                </div>-->
        <!--                                <div class="amount-col" style="width: 12%">-->
        <!--                                    {{ p.diagnose }}-->
        <!--                                </div>-->
        <!--                                <div class="amount-col" style="width: 2%">-->
        <!--                                    {{ getArt(p) }}-->
        <!--                                </div>-->
        <!--                                <div class="amount-col" style="width: 5%">-->
        <!--                                    {{ p.anzahl_tiere }}-->
        <!--                                </div>-->
        <!--                                <div class="amount-col" style="width: 20px;">-->
        <!--                                    {{ p.anwendungsdauer }}-->
        <!--                                </div>-->
        <!--                                <div class="amount-col" style="width: 4%">-->
        <!--                                    {{ p.artikelmenge }}-->
        <!--                                </div>-->
        <!--                                <div class="amount-col" style="width: 5%">-->
        <!--                                    {{ p.tiergewicht }}-->
        <!--                                </div>-->
        <!--                                <div class="amount-col" style="width: 4%">-->
        <!--                                    {{ p.dosierung }}-->
        <!--                                </div>-->
        <!--                                <div class="amount-col" style="width: 48px;">-->
        <!--                                    {{ cutText(getIdentifikationText(p), 40) }}-->
        <!--                                </div>-->
        <!--                                &lt;!&ndash;                                <div class="amount-col" style="width: 5%">&ndash;&gt;-->
        <!--                                &lt;!&ndash;                                    {{ p.wartezeit }}&ndash;&gt;-->
        <!--                                &lt;!&ndash;                                </div>&ndash;&gt;-->
        <!--                                <div *ngIf="hasPermission(1229) && (!preisEditierbar())" class="amount-col"-->
        <!--                                     style="width: 5%">-->
        <!--                                    {{ p.einzelpreis | number: "1.2-2" }}€-->
        <!--                                    <span *ngIf="currentUserIsAdmin && p.einzelpreis_editor"-->
        <!--                                          [matTooltip]="'Einzelpreis wurde angepasst von: ' + p.einzelpreis_editor">&ast;</span>-->
        <!--                                </div>-->
        <!--                                <div *ngIf="hasPermission(1229) && preisEditierbar()" class="amount-col kiene-link"-->
        <!--                                     (click)="einzelpreisEditieren(p)" style="width: 5%; text-decoration: underline">-->
        <!--                                    {{ p.einzelpreis | number: "1.2-2" }}€-->
        <!--                                    <span *ngIf="currentUserIsAdmin && p.einzelpreis_editor"-->
        <!--                                          [matTooltip]="'Einzelpreis wurde angepasst von: ' + p.einzelpreis_editor">&ast;</span>-->
        <!--                                </div>-->

        <!--                                <div class="remove-col" style="width: 3%;">-->
        <!--                                    &lt;!&ndash;                                    <kiene-delete-button *ngIf="hasPermission(1233) &&&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                        isEditingAllowed() && !p.aus_stueckliste&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                    " (delete)="removePosition(p)">&ndash;&gt;-->
        <!--                                    &lt;!&ndash;                                    </kiene-delete-button>&ndash;&gt;-->

        <!--                                    <mat-icon style="cursor: pointer; transform: scale(.8)" (click)="removePosition(p)">-->
        <!--                                        delete-->
        <!--                                    </mat-icon>-->


        <!--                                </div>-->
        <!--                            </div>-->
        <!--                            <div style="-->
        <!--                                                        width: 100%;-->
        <!--                                                        border-bottom: 1px solid #bbb;-->
        <!--                                                        padding-bottom: 6px;-->
        <!--                                                        color: #777;-->
        <!--                                                    ">-->
        <!--                                <span>Gruppe: {{ gibGruppePos(p) }} | </span>-->
        <!--                                <span class="position-charge" *ngFor="let c of p.chargen">Charge: {{ c.charge }} MHD: {{-->
        <!--                                        c.mhd-->
        <!--                                    }} Menge:-->
        <!--                                    {{ c.chargenmenge }}</span>-->
        <!--                            </div>-->
        <!--                        </div>-->
        <!--                    </div>-->

        <!--                </div>-->
        <!--            </mat-card>-->
        <!--        </div>-->
    </div>
</div>
