import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { environment } from '../../../../environments/environment';
import { GruppeTier } from '../../../administration/client-group/gruppe-tier';
import { AnwendungsSelectorComponent } from '../../../components/anwendungs-selector/anwendungs-selector.component';
import {
    ArticleScanAutocompleteComponent,
    ArticleScanSelectedEvent,
} from '../../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import {
    DiagnoseSelectorComponent
} from '../../../components/diagnose-selector/diagnose-selector/diagnose-selector.component';
import { EntityViewerService } from '../../../components/entity-viewer/entity-viewer.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { ArtikelTierart, ArtikelTierartEvent } from '../../../components/tierart-selector/artikel-tierart';
import { TierartSelectorComponent } from '../../../components/tierart-selector/tierart-selector.component';
import {
    AbgabebelegChargeDialogComponent
} from '../../../dialogs/abgabebeleg-charge-dialog/abgabebeleg-charge-dialog.component';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { MessageService } from '../../../services/message.service';
import { KieneUtils } from '../../../../../projects/kiene-core/src/lib/utils/KieneUtils';
import { Indikation, IndikationEvent } from '../../../warehouse/material/artikel-indikation/indikation';
import { Material } from '../../../warehouse/material/material';
import { AbgabebelegCharge, AbgabebelegPosition, PositionCharge } from '../../abgabebeleg/abgabebeleg';
import {
    IdentifikationDialogComponent
} from '../../abgabebeleg/abgabebeleg-details/identifikation-dialog/identifikation-dialog.component';
import { Anwendung, AnwendungEvent } from '../../behandlungsanfrage/anwendung';
import { Gruppe } from '../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import { Client } from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { ArtikellisteAuswahlDialogComponent } from '../artikelliste-auswahl-dialog/artikelliste-auswahl-dialog.component';
import {
    Artikelliste,
    ArtikellisteAuswahlDialogResult
} from '../artikelliste-auswahl-dialog/artikelliste-auswahl-dialog';
import {
    ArtikellisteAusfuellenDialogComponent
} from '../artikelliste-ausfuellen-dialog/artikelliste-ausfuellen-dialog.component';
import {
    ArtikellisteAusfuellenDialogInput,
    ArtikellisteAusfuellenDialogResult
} from '../artikelliste-ausfuellen-dialog/artikelliste-ausfuellen-dialog';
import { BelegPositionInlineResult } from './beleg-position-inline';
import {
    IdentifikationDialogResult
} from '../../abgabebeleg/abgabebeleg-details/identifikation-dialog/identifikation-dialog';

@Component({
    selector: 'kiene-beleg-position-inline',
    templateUrl: './beleg-position-inline.component.html',
    styleUrls: ['./beleg-position-inline.component.scss'],
    host: { '(window:keydown)': 'checkForKeys($event)' },
})
export class BelegPositionInlineComponent
    implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('articleAutocomplete')
    articleAutocomplete: ArticleScanAutocompleteComponent;
    @ViewChild('tierartSelector') tierartSelector: TierartSelectorComponent;
    @ViewChild('anwendungSelector')
    anwendungSelector: AnwendungsSelectorComponent;
    @ViewChild('diagnoseSelector') diagnoseSelector: DiagnoseSelectorComponent;
    @ViewChild('behandlungJaNein') behandlungJaNein: MatSelect;
    @ViewChild('anzahlTiere') anzahlTiere: MatInput;
    @ViewChild('identifikationButton') identifikationButton: MatButton;
    @ViewChild('mengeInput') mengeInput: MatInput;
    @ViewChild('focusOnInit') focusOnInit = true;

    @Input('kundeId') kundeId: number;
    @Input('tierarztId') tierarztId: number;
    @Input('kundeMandantId') kundeMandantId: number;
    @Input('gruppe') gruppe: Gruppe;
    @Input('disabled') disabled = false;
    @Input('keineChargen') keineChargen = false;
    @Input('tiereAnzeigen') tiereAnzeigen = true;
    @Input() hotkeyEnabled = false;

    @Output('speichern') speichern = new EventEmitter<BelegPositionInlineResult>(); // Array für Hotkeys/Artikellisten

    position: AbgabebelegPosition;
    anzahlTiereBerechnen = false;
    aktuellerKunde: Client;
    justOpenedIdentifikationDialog = false;
    mengeExakt = '';
    isCurrentUserClient = true;
    numberInputFocused = false;

    anzahlTiereCtrl = new UntypedFormControl(null, [
        Validators.required,
        Validators.min(1),
    ]);
    anwendungsdauerCtrl = new UntypedFormControl(null, [Validators.required]);
    mengeCtrl = new UntypedFormControl(null, [
        Validators.required,
        Validators.min(0),
    ]);
    tiergewichtCtrl = new UntypedFormControl(null, [Validators.required, Validators.maxLength(15)]);
    dosierungCtrl = new UntypedFormControl(null, [Validators.required]);
    behandlungJaNeinCtrl = new UntypedFormControl();

    constructor(
        private api: KieneTableService,
        private messageService: MessageService,
        private dialog: MatDialog,
        private entityViewerService: EntityViewerService,
        private datePipe: DatePipe,
        private localStorageService: LocalStorageService
    ) {
        this.isCurrentUserClient = localStorageService.isCurrentUserKunde();
    }

    ngOnInit() {
        if (this.isCurrentUserClient) {
            this.anzahlTiereCtrl.disable({ emitEvent: false });
            this.anwendungsdauerCtrl.disable({ emitEvent: false });
            this.tiergewichtCtrl.disable({ emitEvent: false });
            this.dosierungCtrl.disable({ emitEvent: false });
            this.behandlungJaNeinCtrl.disable({ emitEvent: false });
        }
    }

    ngAfterViewInit(): void {
        this.ladeKunde();

        // 1. Feld
        this.anzahlTiereCtrl.valueChanges.subscribe(() => {
            this.anzahlTiereBerechnen = false;
            if (this.istImpfArtikel() || this.istDosierungFix()) {
                this.berechneArtikelmenge(false);
                if (this.istDosierungFix() && !this.tiergewichtCtrl.value) {
                    this.tiergewichtCtrl.setValue(
                        this.position?.koerpergewicht_tierart,
                        { emitEvent: false }
                    );
                }
            } else {
                this.berechneDosiermenge(
                    true,
                    'ngAfterViewInit::anzahlTiereCtrl'
                );
            }
        });

        // 2. Feld
        this.anwendungsdauerCtrl.valueChanges.subscribe(() => {
            if (this.anzahlTiereBerechnen) {
                this.berechneAnzahlTiere();
            } else if (this.istImpfArtikel() || this.istDosierungFix()) {
                this.berechneArtikelmenge(true);
            } else {
                this.berechneDosiermenge(
                    true,
                    'ngAfterViewInit::anwendungsdauerCtrl'
                );
            }
        });

        // 3. Feld
        this.mengeCtrl.valueChanges.subscribe((menge: number) => {
            this.ladeEinzelpreis();


            if (this.isCurrentUserClient) {
                this.berechneAnzahlTiere();
            } else if (!this.istImpfArtikel()) {
                if (this.istDosierungFix()) {
                    if (this.anzahlTiereBerechnen) {
                        this.berechneAnzahlTiere();
                    } else {
                        this.berechneTiergewicht('ngAfterViewInit::mengeCtrl');
                    }
                } else {
                    this.berechneDosiermenge(
                        false,
                        'ngAfterViewInit::mengeCtrl'
                    );
                }
            }

            if (this.position) {
                this.position.artikelmenge = menge;
            }
        });

        this.tiergewichtCtrl.valueChanges.subscribe((gewicht: number) => {
            if (this.istDosierungFix()) {
                this.berechneDosiermengeAnhandTiergewicht(gewicht);
                if (this.anzahlTiereBerechnen) {
                    this.berechneAnzahlTiere();
                } else {
                    this.berechneArtikelmenge(false);
                }
            }
        });

        // 4. Feld
        this.dosierungCtrl.valueChanges.subscribe(() => {
            if (this.anzahlTiereBerechnen) {
                this.berechneAnzahlTiere();
            } else {
                this.berechneArtikelmenge(false);
            }
        });

        this.behandlungJaNeinCtrl.valueChanges.subscribe((behandl: boolean) => {
            if (!this.position) {
                this.position = new AbgabebelegPosition();
            }

            this.position.behandlung = behandl ? 1 : 0;

            this.anzahlTiereBerechnen = false; // sonst passiert bei Taste B nichts

            if (!this.anzahlTiereBerechnen) {
                this.berechneArtikelmenge(false);
            }
        });

    }

    istImpfArtikel() {
        return this.position?.impfung === 1;
    }

    istChargenArtikel() {
        return this.position?.chargeJN === 1;
    }

    istPositionBehandlung() {
        return this.position?.behandlung === 1;
    }

    istMengenArtikel() {
        return this.position?.mengenartikel === 1;
    }

    istAnwendungsdauerFix() {
        return this.position?.anwendungsdauer_fix === 1;
    }

    istDosierungFix() {
        return this.position?.dosierung_fix === 1;
    }

    istAbgabeUndBehandlung() {
        return this.position?.abgabeundbehandlung === 1;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.ladeKunde();
        if (changes.disabled) {
            this.checkDisabled();
        }
    }

    checkDisabled() {
        console.log('checkDisabled(): ' + this.disabled);
        if (this.disabled) {
            this.setFocusOnInit(false);
            this.anzahlTiereCtrl.disable({ emitEvent: false });
            this.anwendungsdauerCtrl.disable({ emitEvent: false });
            this.mengeCtrl.disable({ emitEvent: false });
            this.tiergewichtCtrl.disable({ emitEvent: false });
            this.dosierungCtrl.disable({ emitEvent: false });
            this.behandlungJaNeinCtrl.disable({ emitEvent: false });
        } else {
            this.setFocusOnInit(true);
            this.mengeCtrl.enable({ emitEvent: false });
            if (!this.isCurrentUserClient) {
                this.anzahlTiereCtrl.enable({ emitEvent: false });
                this.anwendungsdauerCtrl.enable({ emitEvent: false });
                this.tiergewichtCtrl.enable({ emitEvent: false });
                this.dosierungCtrl.enable({ emitEvent: false });
                this.behandlungJaNeinCtrl.enable({ emitEvent: false });
            }
        }
    }

    public setFocusArticleAutocomplete() {
        setTimeout(() => {
            this.articleAutocomplete?.setFocus();
        }, 1000);
    }

    public setFocusOnInit(focus: boolean) {
        this.focusOnInit = focus;
    }

    setzeTierart(pos: AbgabebelegPosition, ladePosition: boolean) {
        console.log('Setze', pos);
        if (pos?.tierart_id) {
            const tierart = new ArtikelTierart();
            tierart.artikel_id = pos?.artikel_id;
            tierart.tierart_id = pos?.tierart_id;
            tierart.tierart = pos?.tierart;
            if (this.tierartSelector) {
                this.tierartSelector.setTierart(tierart, false, ladePosition);
            }
        } else {
            if (this.tierartSelector) {
                this.tierartSelector.ladeTierartenFuerMaterialUndKunde(
                    'ladePosition',
                    pos.artikel_id,
                    false,
                    ladePosition
                );
            }
        }
    }

    setzeAnwendung(pos: AbgabebelegPosition, ladePosition: boolean) {
        if (pos.anwendung_id) {
            const anwendung = new Anwendung();
            anwendung.anwendung_id = pos.anwendung_id;
            anwendung.anwendung = pos.anwendung;
            anwendung.artikel_id = pos.artikel_id;
            anwendung.artikel = pos.artikel;
            anwendung.wartezeit_fleisch = pos.wartezeit;
            if (this.anwendungSelector) {
                this.anwendungSelector.setAnwendung(
                    anwendung,
                    pos.tierart_id,
                    false,
                    ladePosition
                );
            }
        } else {
            if (this.anwendungSelector && pos.artikel_id && pos.tierart_id) {
                this.anwendungSelector.loadAnwendungen(
                    pos.artikel_id,
                    pos.tierart_id,
                    false,
                    ladePosition
                );
            }
        }
    }

    setzeDiagnose(pos: AbgabebelegPosition, ladePosition: boolean) {
        if (this.position.indikation_id) {
            const indikation = new Indikation();
            indikation.indikation_id = this.position.indikation_id;
            indikation.diagnose = this.position.diagnose;
            indikation.artikel_id = this.position.artikel_id;
            indikation.tierart_id = this.position.tierart_id;
            if (this.diagnoseSelector) {
                this.diagnoseSelector.setDiagnose(
                    indikation,
                    this.position.anwendung_id,
                    false,
                    ladePosition
                );
            }
        } else {
            if (
                this.diagnoseSelector &&
                this.position.artikel_id &&
                this.position.tierart_id
            ) {
                this.diagnoseSelector.loadDiagnosen(
                    this.position.artikel_id,
                    this.position.tierart_id,
                    this.position.anwendung_id,
                    false,
                    ladePosition
                );
            }
        }
    }

    setzeBehandlung() {
        if (KieneUtils.isNoU(this.position?.behandlung)) {
            this.position.behandlung = 0;
        }
        if (this.istAbgabeUndBehandlung()) {
            this.behandlungJaNeinCtrl.setValue(this.istPositionBehandlung(), {
                emitEvent: false,
            });
        }
    }

    setzeInputFelder(pos: AbgabebelegPosition) {
        /**
         * Anzahl Tiere
         */
        this.anzahlTiereCtrl.setValue(pos.anzahl_tiere, {
            emitEvent: false,
        });

        /**
         * Anwendungsdauer
         */
        this.anwendungsdauerCtrl.setValue(pos.anwendungsdauer, {
            emitEvent: false,
        });

        /**
         * Menge
         */
        this.mengeCtrl.setValue(pos.artikelmenge, { emitEvent: false });

        /**
         * Tiergewicht
         */
        this.tiergewichtCtrl.setValue(pos.tiergewicht, {
            emitEvent: false,
        });

        /**
         * Dosierung
         */
        this.dosierungCtrl.setValue(pos.dosierung, { emitEvent: false });

        this.checkDisabled();
    }

    ladePosition(position: AbgabebelegPosition) {
        this.anzahlTiereBerechnen = false;
        this.justOpenedIdentifikationDialog = false;
        this.position = position;

        /**
         * Artikel
         */
        const material = new Material();
        material.artikel_id = this.position?.artikel_id;
        material.artikel_me = this.position?.artikel_me;
        material.bezeichnung = this.position?.artikel;
        material.stueckliste = this.position?.stueckliste;
        if (this.articleAutocomplete) {
            this.articleAutocomplete.setMaterial(material, false);
            this.articleAutocomplete.setHintMessage(position?.sonderfall === 1 ? 'Sonderfall Medikation' : undefined);
        }

        this.setzeTierart(position, true);
        this.setzeAnwendung(position, true);
        this.setzeDiagnose(position, true);
        this.setzeInputFelder(position);
        this.setzeBehandlung();

        /**
         * Flags
         */

        this.checkImpfung();

        this.checkInputsDisabled(true);

        this.entityViewerService.setEntity(this.position);

        this.articleAutocomplete?.setFocus();
    }

    checkImpfung() {
        if (this.istImpfArtikel()) {
            this.tiergewichtCtrl.clearValidators();
            this.tiergewichtCtrl.updateValueAndValidity({ emitEvent: false });
            // this.tiergewichtCtrl.removeValidators(Validators.required); // erst in Angular version 13
        } else {
            this.tiergewichtCtrl.setValidators(Validators.required);
        }
    }

    ladeKunde() {
        if (this.kundeId) {
            this.api
                .getOne(
                    environment.BASE_URI + 'kunde/read_one.php',
                    'kunde_id',
                    this.kundeId.toString()
                )
                .subscribe((response) => {
                    this.aktuellerKunde = response;
                });
        }
    }

    ladeEinzelpreis() {
        if (this.isCurrentUserClient) {
            return;
        }
        if (!this.position?.artikel_id || !this.kundeId) {
            return;
        }
        let menge = this.mengeCtrl.value;
        if (!menge || menge < 1) {
            menge = 1;
        }
        let params = new HttpParams();
        params = params.set('artikel_id', this.position?.artikel_id.toString());
        params = params.set('artikelmenge', menge);
        params = params.set('kunde_id', this.kundeId.toString());

        this.api
            .getOneByParams(environment.BASE_URI + 'artikel/read_preis.php', params)
            .subscribe({
                next: (response) => {
                    this.position.einzelpreis = response.einzelpreis;
                },
                error: () => {
                },
            });
    }

    setSelectedMaterial(asse: ArticleScanSelectedEvent) {

        this.felderLeeren(false);
        this.anzahlTiereBerechnen = false;
        if (!this.position) {

            this.position = new AbgabebelegPosition();
        }

        this.position.chargeJN = asse.article.charge;
        this.position.impfung = asse.article.impfung;
        this.position.behandlung = asse.article.behandlung;
        this.position.mengenartikel = asse.article.mengenartikel;
        this.position.abgabeundbehandlung = asse.article.abgabeundbehandlung;
        this.position.verpackungsmenge = asse.article.verpackungsmenge;
        this.position.artikel_id = asse.article.artikel_id;
        this.position.artikel = asse.article.bezeichnung;
        this.position.artikel_me = asse.article.artikel_me;
        this.position.stueckliste = asse.article.stueckliste;
        this.position.anwendungsdauer_fix = asse.article.anwendungsdauer_fix;
        this.position.dosierung_fix = asse.article.dosierung_fix;

        this.ladeEinzelpreis();

        this.checkImpfung();

        if (this.istAbgabeUndBehandlung()) {
            if (this.istImpfArtikel()) {
                // wenn selbstimpfer hoechstwahrscheinlich eine Abgabe
                if (this.aktuellerKunde.selbstimpfer) {
                    this.behandlungJaNeinCtrl.setValue(false, {
                        emitEvent: false,
                    });
                    this.position.behandlung = 0;
                } else {
                    this.behandlungJaNeinCtrl.setValue(true, {
                        emitEvent: false,
                    });
                    this.position.behandlung = 1;
                }
            } else {
                this.behandlungJaNeinCtrl.setValue(true, { emitEvent: false });
                this.position.behandlung = 1;
            }
        } else {
            if (KieneUtils.isNoU(this.position?.behandlung)) {
                this.position.behandlung = 0;
            }
        }

        this.checkInputsDisabled(false);

        const session = this.localStorageService.getCurrentUser();
        if (session?.abgabebeleg_artikeleingabe_aus_historie > 0) {

            let params = new HttpParams();
            params = params.set(
                'artikel_id',
                asse.article?.artikel_id?.toString()
            );
            params = params.set(
                'kunde_id',
                this.aktuellerKunde?.kunde_id?.toString()
            );
            this.api
                .get(
                    environment.BASE_URI +
                    'abgabebeleg/read_position_historie.php',
                    params
                )
                .subscribe({
                    next: (response) => {
                        if (response.records?.length >= 1) {

                            // Artikel in Historie gefunden
                            const ap: AbgabebelegPosition = response.records[0];

                            // Identifikation nicht übernehmen, 22.12.21
                            if (session?.identifikation_aus_historie > 0) {
                                this.position.identifikation =
                                    ap.identifikation;
                                this.position.prophylaxe = ap.prophylaxe;
                                this.position.metaphylaxe = ap.metaphylaxe;
                            }
                            this.position.wartezeit = ap.wartezeit;
                            this.position.tierart = ap.tierart;
                            this.position.tierart_id = ap.tierart_id;
                            this.position.anwendung = ap.anwendung;
                            this.position.anwendung_id = ap.anwendung_id;
                            this.position.diagnose = ap.diagnose;
                            this.position.indikation_id = ap.indikation_id;
                            this.position.artikelmenge = ap.artikelmenge;
                            this.position.dosierung = ap.dosierung;
                            this.position.anzahl_tiere = ap.anzahl_tiere;
                            this.position.anwendungsdauer = ap.anwendungsdauer;
                            this.position.tiergewicht = ap.tiergewicht;
                            this.position.behandlung = ap.behandlung;
                            this.position.abgabeundbehandlung =
                                ap.abgabeundbehandlung;
                            this.position.verpackungsmenge =
                                ap.verpackungsmenge;
                            this.position.dosierung_txt = ap.dosierung_txt;
                            this.position.anwendungsdauer_fix =
                                ap.anwendungsdauer_fix;
                            this.position.dosierung_fix = ap.dosierung_fix;
                            this.position.dosierung_tierart =
                                ap.dosierung_tierart;
                            this.position.koerpergewicht_tierart =
                                ap.koerpergewicht_tierart;

                            this.setzeTierart(ap, false);
                            this.setzeAnwendung(ap, false);
                            this.setzeDiagnose(ap, false);
                            this.setzeInputFelder(ap);
                            this.setzeBehandlung();

                            this.checkInputsDisabled(false);

                            if (this.istMengenArtikel() || this.isCurrentUserClient) {
                                this.mengeInput?.focus();
                            } else {
                                this.anzahlTiere?.focus();
                            }

                            const d = new Date(ap.datum);
                            const s = d
                                ? this.datePipe.transform(d, 'dd.MM.yyyy')
                                : '?';
                            this.messageService.hintMessage(
                                'Informationen aus Abgabebeleg vom ' +
                                s +
                                ' übernommen!'
                            );
                        } else {

                            this.afterSelectedMaterial();
                        }
                    },
                    error: () => {
                        this.afterSelectedMaterial();
                    },
                });
        } else {

            this.afterSelectedMaterial();
        }
    }

    afterSelectedMaterial() {
        if (this.istMengenArtikel() || this.isCurrentUserClient) {
            if (!this.mengeCtrl.value) {
                this.mengeCtrl.setValue(1, { emitEvent: false });
            }
            this.mengeInput?.focus();
        } else {
            this.tierartSelector.ladeTierartenFuerMaterialUndKunde(
                'setSelectedMaterial',
                this.position.artikel_id,
                true,
                false
            );
        }
    }

    setSelectedTierart(event: ArtikelTierartEvent) {
        if (!event.artikelTierart) {
            return;
        }

        this.position.tierart_id = event.artikelTierart.tierart_id;
        this.position.tierart = event.artikelTierart.tierart;
        this.position.dosierung_txt = event.artikelTierart.dosierung_txt;
        this.position.dosierung_tierart = event.artikelTierart.menge;
        this.position.koerpergewicht_tierart =
            event.artikelTierart.koerpergewicht;

        if (!this.anwendungsdauerCtrl.value) {
            this.anwendungsdauerCtrl.setValue(
                event.artikelTierart.behandlung_tage,
                { emitEvent: false }
            );
        }
        if (!this.dosierungCtrl.value) {
            this.dosierungCtrl.setValue(event.artikelTierart.menge, {
                emitEvent: false,
            });
        }

        if (!event.ladePosition) {
            this.berechneDosiermenge(true, 'setSelectedTierart');
        }

        this.anwendungSelector?.loadAnwendungen(
            event.artikelTierart.artikel_id,
            event.artikelTierart.tierart_id,
            true,
            event.ladePosition
        );
    }

    setSelectedAnwendung(event: AnwendungEvent) {
        if (KieneUtils.isNoU(event?.anwendung)) {
            return;
        }

        this.position.anwendung_id = event.anwendung.anwendung_id;
        this.position.anwendung = event.anwendung.anwendung;
        this.position.wartezeit = event.anwendung.wartezeit_fleisch;

        if (this.diagnoseSelector) {
            if (event.ladePosition) {
                this.diagnoseSelector.loadDiagnosen(
                    event.anwendung.artikel_id,
                    this.position?.tierart_id,
                    this.position?.anwendung_id,
                    false,
                    true
                );
            } else {
                this.diagnoseSelector.loadDiagnosen(
                    event.anwendung.artikel_id,
                    this.position?.tierart_id,
                    this.position?.anwendung_id,
                    true,
                    false
                );
            }
        }
    }

    setSelectedDiagnose(event: IndikationEvent) {
        if (KieneUtils.isNoU(event?.indikation)) {
            return;
        }

        this.position.indikation_id = event.indikation.indikation_id;
        this.position.diagnose = event.indikation.diagnose;

        if (event.indikation.sonderfall) {
            const s = event.indikation.sonderfall;

            this.position.dosierung_txt = s.dosierung_txt;
            this.position.dosierung_tierart = s.menge;
            this.position.koerpergewicht_tierart =
                s.koerpergewicht;

            this.anwendungsdauerCtrl.setValue(
                s.behandlung_tage,
                { emitEvent: false }
            );

            this.dosierungCtrl.setValue(s.menge, {
                emitEvent: false,
            });


            this.berechneDosiermenge(true, 'setSelectedDiagnose Sonderfall');

            this.articleAutocomplete.setHintMessage('Sonderfall Medikation');

        } else {
            this.articleAutocomplete.setHintMessage(undefined);
        }


        if (!event.ladePosition) {
            if (this.istAbgabeUndBehandlung()) {
                this.behandlungJaNein?.focus();
            } else {
                this.anzahlTiere?.focus();
            }
        }
    }

    public felderLeeren(clearArticle: boolean) {
        this.justOpenedIdentifikationDialog = false;
        this.tierartSelector.clear();
        this.anwendungSelector.clear();
        this.diagnoseSelector.clear();
        this.behandlungJaNeinCtrl.setValue(null, { emitEvent: false });
        this.anzahlTiereCtrl.setValue(null, { emitEvent: false });
        this.anwendungsdauerCtrl.setValue(null, { emitEvent: false });
        this.mengeCtrl.setValue(null, { emitEvent: false });
        this.tiergewichtCtrl.setValue(null, { emitEvent: false });
        this.dosierungCtrl.setValue(null, { emitEvent: false });
        this.anzahlTiereBerechnen = false;
        this.position = new AbgabebelegPosition();

        this.anzahlTiereCtrl.markAsUntouched();
        this.anwendungsdauerCtrl.markAsUntouched();
        this.mengeCtrl.markAsUntouched();
        this.tiergewichtCtrl.markAsUntouched();
        this.dosierungCtrl.markAsUntouched();

        this.mengeExakt = '';

        if (clearArticle) {
            this.articleAutocomplete.clearAndFocus();
        }
    }

    checkInputsDisabled(markAsTouched: boolean) {
        if (this.istMengenArtikel() || this.isCurrentUserClient) {
            this.behandlungJaNeinCtrl.disable({ emitEvent: false });
            this.anzahlTiereCtrl.disable({ emitEvent: false });
            this.tiergewichtCtrl.disable({ emitEvent: false });
        } else {
            this.behandlungJaNeinCtrl.enable({ emitEvent: false });
            this.anzahlTiereCtrl.enable({ emitEvent: false });
            this.tiergewichtCtrl.enable({ emitEvent: false });

            if (markAsTouched) {
                this.anzahlTiereCtrl.markAsTouched();
                this.tiergewichtCtrl.markAsTouched();
            }
        }

        if (this.istMengenArtikel() || this.istAnwendungsdauerFix() || this.isCurrentUserClient) {
            this.anwendungsdauerCtrl.disable({ emitEvent: false });
        } else {
            this.anwendungsdauerCtrl.enable({ emitEvent: false });
            if (markAsTouched) {
                this.anwendungsdauerCtrl.markAsTouched();
            }
        }

        if (this.istMengenArtikel() || this.istDosierungFix() || this.isCurrentUserClient) {
            this.dosierungCtrl.disable({ emitEvent: false });
        } else {
            this.dosierungCtrl.enable({ emitEvent: false });
            if (markAsTouched) {
                this.dosierungCtrl.markAsTouched();
            }
        }

        //        if (this.istDosierungFix()) {
        //          this.mengeCtrl.disable({ emitEvent: false });
        //    } else {
        this.mengeCtrl.enable({ emitEvent: false });
        if (markAsTouched) {
            this.mengeCtrl.markAsTouched();
        }
        //  }
    }

    berechneTiergewicht(sender: string) {
        if (!this.istDosierungFix()) {
            return;
        }

        if (!this.position?.verpackungsmenge) {
            this.messageService.alertMessage(
                'Inhaltsmenge des Medikaments in den Stammdaten nicht eingepflegt!'
            );
            return;
        }
        if (!this.position?.koerpergewicht_tierart) {
            // wenn Körpergewicht nicht angegeben (null / 0), wird davon ausgegangen,
            // dass die Dosierung pro Tier gilt, unabhängig vom Körpergewicht
            return;
        }
        if (!this.position?.dosierung_tierart) {
            this.messageService.alertMessage(
                'Dosierung je Körpergewicht in den Stammdaten nicht eingepflegt!'
            );
            return;
        }
        const anzahlTiere: number = this.anzahlTiereCtrl.value;
        const anwendungsdauer: number = this.anwendungsdauerCtrl.value;
        const menge: number = this.mengeCtrl.value;

        const nenner =
            anwendungsdauer * anzahlTiere * this.position?.dosierung_tierart;
        if (!nenner) {
            return;
        }

        const tiergewicht = Math.round(
            (menge *
                this.position?.verpackungsmenge *
                this.position?.koerpergewicht_tierart) /
            nenner
        );
        this.tiergewichtCtrl.setValue(tiergewicht, { emitEvent: false });
        this.berechneDosiermengeAnhandTiergewicht(tiergewicht);
    }

    /**
     * nur aufrufen, wenn Dosierung Fix ist
     * @param tiergewicht
     * @returns
     */
    berechneDosiermengeAnhandTiergewicht(tiergewicht: number) {
        if (!this.istDosierungFix()) {
            return;
        }
        // z.B. 1ml / 10kg Gewicht
        if (!this.position?.dosierung_tierart) {
            this.messageService.alertMessage(
                'Dosierung je Körpergewicht in den Stammdaten nicht eingepflegt!'
            );
            return;
        }
        const dos = this.berechneDosierungFix(this.position?.koerpergewicht_tierart, this.position?.dosierung_tierart, tiergewicht);
        this.dosierungCtrl.setValue(dos, { emitEvent: false });
    }

    berechneDosierungFix(koerpergewicht_tierart: number, dosierung_: number, tiergewicht: number) {
        if (!koerpergewicht_tierart) {
            // wenn Körpergewicht nicht angegeben (null / 0), wird davon ausgegangen,
            // dass die Dosierung pro Tier gilt, unabhängig vom Körpergewicht
            return Math.round(dosierung_ * 100) / 100;
        }
        if (!tiergewicht) {
            return null;
        }
        let dos = (dosierung_ / koerpergewicht_tierart) * tiergewicht;
        return Math.round(dos * 100) / 100;
    }

    berechneDosiermenge(mindestmengeEins: boolean, sender: string) {
        if (this.istDosierungFix()) {
            return;
        }

        if (!this.mengeCtrl.value) {
            if (mindestmengeEins) {
                this.mengeCtrl.setValue(1, { emitEvent: false });
            } else {
                return;
            }
        }

        if (!this.position?.verpackungsmenge) {
            return;
        }

        const menge = this.mengeCtrl.value;
        const anzahlTiere = this.anzahlTiereCtrl.value;
        const anwendungsdauer = this.anwendungsdauerCtrl.value;
        // Verpackungsmenge

        const dosiermenge =
            (Number.parseFloat(menge) * this.position?.verpackungsmenge) /
            (Number.parseFloat(anwendungsdauer) *
                Number.parseFloat(anzahlTiere));

        if (Number.isNaN(dosiermenge)) {
            return;
        }
        let mengeRound = Math.round(dosiermenge * 100) / 100;
        mengeRound = !isNaN(mengeRound) ? mengeRound : null;

        this.dosierungCtrl.setValue(mengeRound, {
            emitEvent: false,
        });
        this.position.dosierung = this.dosierungCtrl.value;
    }

    berechneArtikelmenge(tiergewichtBerechnen: boolean) {
        if (!this.position?.verpackungsmenge) {
            this.messageService.alertMessage(
                'Inhaltsmenge des Medikaments in den Stammdaten nicht eingepflegt!'
            );
            return;
        }
        const anzahlTiere: number = this.anzahlTiereCtrl.value;
        const dosierung: number = this.dosierungCtrl.value;
        const anwendungsdauer: number = this.anwendungsdauerCtrl.value;

        let menge =
            (dosierung * anzahlTiere * anwendungsdauer) /
            this.position.verpackungsmenge;
        menge = Math.round(menge * 1000) / 1000;
        if (!this.istPositionBehandlung()) {
            // wenn abgabe, nur in ganzen verpackungseinheiten
            this.mengeExakt = '' + menge;
            menge = Math.ceil(menge);
        } else {
            this.mengeExakt = '';
        }

        this.mengeCtrl.setValue(menge, { emitEvent: false });
        this.position.artikelmenge = this.mengeCtrl.value;

        if (tiergewichtBerechnen) {
            this.berechneTiergewicht('berechneArtikelmenge');
        }
    }

    openIdentifikationDialog() {
        this.justOpenedIdentifikationDialog = true;
        if (!this.gruppe) {
            this.messageService.alertMessage('Bitte eine Gruppe auswählen!');
            return;
        }
        const dialogRef = this.dialog.open(IdentifikationDialogComponent, {
            width: '99%',
            position: { top: '10px' },
            minWidth: '1200px',
            data: {
                gruppe: this.gruppe,
                identifikation: this.position?.identifikation,
                prophylaxe: this.position?.prophylaxe,
                metaphylaxe: this.position?.metaphylaxe,
                tiere: this.position?.tiere,
                anzahlTiere: this.anzahlTiereCtrl.value,
                tiereAnzeigen: this.tiereAnzeigen
            },
        });

        dialogRef.afterClosed().subscribe((result: IdentifikationDialogResult) => {
            this.articleAutocomplete.unFocus();
            if (result?.confirm === true) {
                this.position.tiere = [];
                if (result.identifikationTyp === 'freitext') {
                    this.position.identifikation = result.freitext;
                } else if (result.identifikationTyp === 'tierauswahl') {
                    this.position.tiere = result.tiere;
                    this.position.identifikation = result.alleSelektiert
                        ? this.position.tiere.length + ' Tiere ausgewählt'
                        : this.getTiereString(result.tiere);
                }
                this.position.prophylaxe = result.prophylaxe;
                this.position.metaphylaxe = result.metaphylaxe;
                this.checkPosition();
            }
        });
    }

    checkPosition() {
        /*if (!this.position?.artikel_id) {
            this.messageService.errorMessage(
                'Es wurde kein Artikel ausgewählt!',
                null
            );
            return;
        }
        if (!this.position?.gruppe_id) {
            this.messageService.errorMessage(
                'Es wurde keine Gruppe ausgewählt!',
                null
            )
            return;
        }*/
        if (!this.keineChargen && this.istChargenArtikel()) {
            // Chargen dialog nur öffnen, Wenn noch nicht ausreichend chargen ausgewählt
            // und wenn "Charge" bei Artikel gesetzt
            let anzChargen = 0;
            for (const c of this.position.chargen) {
                anzChargen += c.chargenmenge;
            }
            if (this.position.artikelmenge === anzChargen) {
                this.save();
            } else {
                this.openAddChargeDialog();
            }
        } else {
            this.save();
        }
    }

    getIdentifikationText(position: AbgabebelegPosition) {
        if (!position) {
            return null;
        }
        let text = position.identifikation;

        if (position.tiere && position.tiere.length > 0) {
            if (position.tiere.length < 10) {
                text = this.getTiereString(position.tiere);
            } else {
                text = position.tiere.length + ' Tiere ausgewählt';
            }
        }

        return text;
    }

    openAddChargeDialog() {
        if (!this.istChargenArtikel()) {
            return;
        }

        if (!this.position?.artikel_id) {
            this.messageService.alertMessage(
                'Bitte erst einen Artikel auswählen!'
            );
            return;
        }
        if (!this.tierarztId) {
            this.messageService.alertMessage(
                'Dieser Beleg ist keinem Tierarzt zugewiesen!'
            );
            return;
        }

        this.position.artikelmenge = this.mengeCtrl.value;

        const dialogRef = this.dialog.open(AbgabebelegChargeDialogComponent, {
            data: {
                abgabebelegPosition: this.position,
                tierarztId: this.tierarztId,
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            this.justOpenedIdentifikationDialog = false;
            if (response) {
                const chargen: AbgabebelegCharge[] = response;
                const positionChargen: PositionCharge[] = [];
                for (const c of chargen) {
                    if (c.menge_beleg > 0) {
                        const charge: PositionCharge = new PositionCharge();
                        charge.charge_id = c.charge_id;
                        charge.chargenmenge = c.menge_beleg;
                        charge.lagerplatz_id = c.lagerplatz_id;
                        positionChargen.push(charge);
                    }
                }

                this.position.chargen = positionChargen;
                this.save();
            }
        });
    }

    getTiereString(tiere: GruppeTier[]) {
        let text = '';
        if (tiere) {
            for (const t of tiere) {
                text += t.ohrmarke + ', ';
            }
            if (text?.endsWith(', ')) {
                text = text.substring(0, text.length - 2);
            }
        }
        return text;
    }

    save() {
        if (!this.position?.artikel_id) {
            this.messageService.errorMessage(
                'Es wurde kein Artikel ausgewählt!',
                null
            );
            return;
        }

        this.position.gruppe_id = this.gruppe?.gruppe_id;
        this.position.gruppe = this.gruppe?.bezeichnung;
        if (!this.position?.gruppe_id) {
            this.messageService.errorMessage(
                'Es wurde keine Gruppe ausgewählt!',
                null
            );
            return;
        }
        this.position.anzahl_tiere = this.anzahlTiereCtrl.value;
        this.position.anwendungsdauer = this.anwendungsdauerCtrl.value;
        this.position.artikelmenge = this.mengeCtrl.value;
        this.position.tiergewicht = this.tiergewichtCtrl.value;
        this.position.dosierung = this.dosierungCtrl.value;
        const result = new BelegPositionInlineResult();
        result.positionen.push(this.position);
        this.speichern.emit(result);
        this.justOpenedIdentifikationDialog = false;
    }

    checkForKeys(keyEvent: KeyboardEvent) {
        // console.debug("Beleg Position keyEvents: " + keyEvent.code);
        if (keyEvent.code === 'F8') {
            this.openAddChargeDialog();
        } else if (keyEvent.code === 'F9') {
            this.felderLeeren(true);
        } else if (keyEvent.code === 'KeyB') {
            if (this.numberInputFocused) {
                const toggle: boolean = !(this.position.behandlung > 0);
                this.behandlungJaNeinCtrl.setValue(toggle, { emitEvent: true }); // true!
            }
        } else if (keyEvent.altKey && keyEvent.code === 'KeyH') {
            this.artikellisteAuswaehlen();
        }
    }

    artikellisteAuswaehlen() {
        if (this.localStorageService.hasPermission(2681) && this.hotkeyEnabled) {
            if (!this.gruppe?.gruppe_id) {
                this.messageService.alertMessage('Bitte erst eine Gruppe auswählen!');
                return;
            }

            this.dialog.open(ArtikellisteAuswahlDialogComponent, {}).afterClosed().subscribe(
                {
                    next: (result: ArtikellisteAuswahlDialogResult) => {
                        if (result?.confirm === true && result?.artikelliste) {
                            this.artikellisteAusfuellen(result.artikelliste);
                        }
                    }
                }
            );
        }
    }

    artikellisteAusfuellen(artikelliste: Artikelliste) {
        if (artikelliste) {
            const input = new ArtikellisteAusfuellenDialogInput();
            input.artikelliste = artikelliste;
            input.kunde_id = this.kundeId;
            this.dialog.open(ArtikellisteAusfuellenDialogComponent, { data: input }).afterClosed().subscribe({
                next: (result: ArtikellisteAusfuellenDialogResult) => {
                    if (result?.confirm === true) {
                        this.alleArtikelAusListeHinzufuegen(result);
                    }
                }
            });
        }
    }

    alleArtikelAusListeHinzufuegen(input: ArtikellisteAusfuellenDialogResult) {
        const result = new BelegPositionInlineResult();
        for (const listenartikel of input?.artikelliste?.artikel) {
            const abgabebelegPosition = new AbgabebelegPosition();
            abgabebelegPosition.artikel_id = listenartikel.artikel_id;
            abgabebelegPosition.indikation_id = listenartikel.indikation_id;
            abgabebelegPosition.tierart_id = listenartikel.tierart_id;
            abgabebelegPosition.anzahl_tiere = input.anzahl_tiere;
            abgabebelegPosition.tiergewicht = input.tiergewicht?.toString();
            abgabebelegPosition.anwendungsdauer = listenartikel.anwendungsdauer;
            abgabebelegPosition.identifikation = input.identifikation;
            abgabebelegPosition.anwendung_id = listenartikel.anwendung_id;
            abgabebelegPosition.behandlung = listenartikel.behandlung;
            abgabebelegPosition.gruppe_id = this.gruppe?.gruppe_id;

            if (listenartikel.mengenartikel > 0) {
                abgabebelegPosition.artikelmenge = input.anzahl_tiere;
            } else {
                if (listenartikel.dosierung_fix > 0) {
                    abgabebelegPosition.dosierung = this.berechneDosierungFix(listenartikel.koerpergewicht_tierart, listenartikel.dosierung_tierart, input.tiergewicht);
                } else {
                    abgabebelegPosition.dosierung = this.berechneDosierungFix(listenartikel.koerpergewicht_tierart, listenartikel.dosierung, input.tiergewicht);
                }
                let menge = (abgabebelegPosition.dosierung * input.anzahl_tiere * listenartikel.anwendungsdauer) / listenartikel.verpackungsmenge

                menge = Math.round(menge * 1000) / 1000;
                if (!(listenartikel.behandlung > 0)) {
                    // wenn abgabe, nur in ganzen verpackungseinheiten
                    menge = Math.ceil(menge);
                }
                abgabebelegPosition.artikelmenge = menge;
            }
            result.positionen.push(abgabebelegPosition);
        }
        result.insertChargen = true;
        this.speichern.emit(result);
    }

    berechneAnzahlTiere() {
        const anwendungsdauer: number = this.anwendungsdauerCtrl.value;
        const menge: number = this.mengeCtrl.value;
        const dosiermenge: number = this.dosierungCtrl.value;

        if (
            this.position?.verpackungsmenge &&
            anwendungsdauer &&
            menge &&
            dosiermenge
        ) {
            const tmp = dosiermenge * anwendungsdauer;
            if (tmp > 0) {
                let anzahlTiere =
                    (menge * this.position.verpackungsmenge) / tmp;

                anzahlTiere = Math.floor(anzahlTiere);
                this.anzahlTiereCtrl.setValue(anzahlTiere, {
                    emitEvent: false,
                });
                this.position.anzahl_tiere = anzahlTiere;
            }
        }
    }

    onFocusTiergewicht() {
        this.numberInputFocused = true;
        if (!this.anzahlTiereCtrl.value && this.istDosierungFix()) {
            this.anzahlTiereBerechnen = true;
        }
    }

    onFocusDosierung() {
        this.numberInputFocused = true;
        if (!this.anzahlTiereCtrl.value) {
            this.anzahlTiereBerechnen = true;
            this.berechneAnzahlTiere();
        }
    }

    onBlurNumberInput() {
        this.numberInputFocused = false;
    }

    onFocusAnzahlTiere() {
        this.numberInputFocused = true;
    }

    onBlurAnzahlTiere() {
        this.onBlurNumberInput();
    }

    onFocusNumberInput() {
        this.numberInputFocused = true;
    }

    materialInputCleared() {
        this.felderLeeren(false);
    }

    getPlaceholderArtikel() {
        if (this.hotkeyEnabled === true) {
            return 'Artikel eingeben oder Alt+H';
        }
        return 'Artikel eingeben';
    }

}
