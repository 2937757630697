<div style="padding: 24px;">
    <div class="one-row">
        <span class="spacer"></span>
        <kiene-zeitauswahl (change)="zeitintervalChanged($event)"></kiene-zeitauswahl>
        <span class="spacer"></span>
    </div>

    <table class="report-ergebnis-table">
        <thead>
            <tr>
                <th></th>
                <th>Umsatz Gesamt</th>
                <th>Wareneinsatz Gesamt</th>
                <th>Rohertrag Gesamt</th>
            </tr>
        </thead>
        <tbody>
            <tr style="font-size: 1.6em; font-weight: bold; color: green;">
                <td style="font-size: 1em; font-weight: bold; color: gray;"></td>
                <td>{{gesamtUmsatz | number: '1.2-2'}} &euro;</td>
                <td><span>{{gesamtWareneinsatz | number: '1.2-2'}} &euro;</span><br> <span
                        style="font-size: .8em; color: gray;">&#8793; {{gesamtWareneinsatzProzent}}
                        &percnt;</span></td>
                <td><span>{{gesamtRohertrag | number: '1.2-2'}} &euro;</span><br> <span
                        style="font-size: .8em; color: gray">&#8793; {{gesamtRohertragProzent}}
                        &percnt;</span></td>

            </tr>
            <tr style="font-size: 1em; font-weight: bold; color: gray;">
                <td>{{ vorjahreszeitraum?.von | date: 'dd.MM.yyyy' }}
                    bis {{vorjahreszeitraum?.bis | date: 'dd.MM.yyyy'}}</td>
                <td>{{gesamtUmsatzVorjahr | number: '1.2-2'}} &euro;</td>
                <td>{{gesamtWareneinsatzVorjahr | number: '1.2-2'}} &euro; ({{gesamtWareneinsatzProzentVorjahr}}
                    &percnt;)
                </td>
                <td>{{gesamtRohertragVorjahr | number: '1.2-2'}} &euro; ({{gesamtRohertragProzentVorjahr}} &percnt;)
                </td>

            </tr>
        </tbody>
    </table>

    <!--    <div class="one-row" style="min-height: 300px;">-->
    <!--        <kiene-report-overview-value-card [label]="'Umsatz Gesamt'" [numberValue]="gesamtUmsatz"-->
    <!--                                          [numberFormat]="'1.2-2'" [unit]="'&euro;'"-->
    <!--                                          [secondRow]="'&#8793;100 %'"></kiene-report-overview-value-card>-->
    <!--        <kiene-report-overview-value-card [label]="'Wareneinsatz Gesamt'" [numberValue]="gesamtWareneinsatz"-->
    <!--                                          [numberFormat]="'1.2-2'" [unit]="'&euro;'"-->
    <!--                                          [secondRow]="gesamtWareneinsatzProzent + '%'"></kiene-report-overview-value-card>-->
    <!--        <kiene-report-overview-value-card [label]="'Rohertrag Gesamt'" [numberValue]="gesamtRohertrag"-->
    <!--                                          [numberFormat]="'1.2-2'" [unit]="'&euro;'"-->
    <!--                                          [secondRow]="gesamtRohertragProzent + '%'"></kiene-report-overview-value-card>-->
    <!--    </div>-->

    <div class="one-row">
        <span class="spacer"></span>
        <mat-checkbox [formControl]="ergaenzerCtrl" (change)="loadData()" style="margin-right: 24px;"
            matTooltip="Artikel mit Warengruppe Ergänzer berücksichtigen">mit Ergänzern</mat-checkbox>
        <mat-checkbox [formControl]="rabattCtrl" (change)="loadData()" matTooltip="Kundenrabatte abgezogen">Bereinigter
            Umsatz</mat-checkbox>
    </div>

    <mat-card>
        <div class="one-row">
            <span class="spacer"></span>
            <kiene-csv-export [columns]="csvColumns" [data]="dataSource.data"
                [filename]="csvFilename"></kiene-csv-export>
        </div>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

            <!-- Name Column -->
            <ng-container matColumnDef="artikel">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Artikel</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.artikel}} </td>
            </ng-container>

            <ng-container matColumnDef="artikelmenge">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Menge</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.artikelmenge}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="umsatz_prozent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Umsatz von Gesamt (%)</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.umsatz_prozent | number: '1.0-2'}}%
                </td>
            </ng-container>
            <ng-container matColumnDef="umsatz">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Umsatz</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.umsatz | number: '1.2-2'}} &euro;</td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="wareneinsatz">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header">Wareneinsatz</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.wareneinsatz | number: '1.2-2'}}
                    &euro;
                </td>
            </ng-container>
            <ng-container matColumnDef="wareneinsatz_prozent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header">Wareneinsatz (%)</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.wareneinsatz_prozent | number:
                    '1.0-2'}} %
                </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="rohertrag">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Rohertrag</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.rohertrag | number: '1.2-2'}} &euro;
                </td>
            </ng-container>
            <ng-container matColumnDef="rohertrag_prozent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Rohertrag (%)</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.rohertrag_prozent | number: '1.0-2'}}
                    %
                </td>
            </ng-container>


            <ng-container matColumnDef="aggregatedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="aggregated-vetgroup-detail"
                        [@detailExpanded]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div *ngIf="isLoading[element.artikel_id]" class="one-row">
                            <span class="spacer"></span>
                            <mat-spinner [diameter]="32"></mat-spinner>
                            <span class="spacer"></span>
                        </div>
                        <div *ngIf="!isLoading[element.artikel_id]" class="one-row">
                            <span class="spacer"></span>
                            <kiene-csv-export [columns]="csvColumnsDetails" [data]="element.details"
                                [filename]="'Labor_Artikel_' + element.artikel + '.csv'"></kiene-csv-export>
                        </div>
                        <table *ngIf="!isLoading[element.artikel_id]" class="details-table"
                            style="table-layout: fixed; padding-bottom: 24px;">
                            <tr *ngFor="let d of element.details">
                                <td style="padding-left: 24px;">{{d.zeitraum}}</td>
                                <td class="number">{{d.artikelmenge | number: '1.0-0'}} Stk.</td>
                                <td class="number">{{d.umsatz_prozent | number: '1.0-2'}} %</td>
                                <td class="number">{{d.umsatz | number: '1.2-2'}} &euro;</td>
                                <td class="number">{{d.wareneinsatz | number: '1.2-2'}} &euro;</td>
                                <td class="number">{{d.wareneinsatz_prozent | number: '1.0-2'}} %</td>
                                <td class="number">{{d.rohertrag | number: '1.2-2'}} &euro;</td>
                                <td class="number">{{d.rohertrag_prozent | number: '1.0-2'}} %</td>

                            </tr>
                        </table>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="vet-group-row"
                [class.vet-group-expanded-row]="expandedElement === element" (click)="expandVetGroupRow(element)">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['aggregatedDetail']" class="aggregated-row"></tr>
        </table>
    </mat-card>

    <mat-card style="margin-bottom: 24px; margin-top: 24px; height: 600px;">
        <google-chart style="width: 100%;" [type]="chartType" [columns]="chartColumns" [data]="chartData" [height]="600"
            [options]="{pieHole: 0.4, title: 'Umsatz Artikel'}">
        </google-chart>
    </mat-card>


</div>