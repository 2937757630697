import {
    Component,
    OnInit,
    Inject,
    ViewChild,
    ElementRef,
} from '@angular/core';
import {WarenkorbPosition} from './warenkorb';
import {WarenkorbService} from './warenkorb.service';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {SupplierService} from '../../warehouse/supplier/supplier.service';
import {MessageService} from '../../services/message.service';
import {Material} from '../../warehouse/material/material';
import {Supplier} from '../../warehouse/supplier/supplier';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {SupplierMaterial} from '../../warehouse/supplier-material/supplier-material';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
    ArticleScanAutocompleteComponent,
    ArticleScanSelectedEvent,
} from '../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import {
    ArticleScanAutocompleteService
} from '../../components/article-scan-autocomplete/article-scan-autocomplete.service';

@Component({
    selector: 'kiene-warenkorb',
    templateUrl: './warenkorb.component.html',
    styleUrls: ['./warenkorb.component.scss'],
})
export class WarenkorbComponent implements OnInit {
    isLoading = false;
    warenkorbPositions: WarenkorbPosition[] = [];
    formCtrl: UntypedFormControl[] = [];
    positionKommentarCtrl: UntypedFormControl[] = [];
    inputDirtyArray: boolean[] = [];

    materials: Observable<Material[]>;
    materialCtrl: UntypedFormControl = new UntypedFormControl();
    selectedMaterial: Material = new Material();

    positionAmountCtrl: UntypedFormControl = new UntypedFormControl();

    supplier: Supplier[] = [];
    selectedSupplier: Supplier = new Supplier();

    @ViewChild('amountInput')
    amountInput: ElementRef;

    @ViewChild('articleAutocomplete')
    articleAutocomplete: ArticleScanAutocompleteComponent;

    kommentarCtrl = new UntypedFormControl();

    constructor(
        private service: WarenkorbService,
        public dialog: MatDialog,
        private messageService: MessageService
    ) {
    }

    ngOnInit() {
        this.loadWarenkorb();
    }

    loadWarenkorb() {
        this.isLoading = true;
        this.service.getWarenkorbContent().subscribe(
            (response) => {
                this.warenkorbPositions = response.records;
                this.initFormControls();
                this.isLoading = false;
            },
            () => {
                this.isLoading = false;
            }
        );
    }

    initFormControls() {
        for (const w of this.warenkorbPositions) {
            this.inputDirtyArray[w.warenkorb_id] = false;
            const fc: UntypedFormControl = new UntypedFormControl();
            fc.setValue(w.menge_soll);
            fc.valueChanges.subscribe(() => {
                this.inputDirtyArray[w.warenkorb_id] = true;
            });
            let kommentarCtrl = new UntypedFormControl(w.kommentar);
            kommentarCtrl.valueChanges.subscribe(() => {
                this.inputDirtyArray[w.warenkorb_id] = true;
            });
            this.positionKommentarCtrl[w.warenkorb_id] = kommentarCtrl;
            this.formCtrl[w.warenkorb_id] = fc;
        }
    }

    materialSelected(asse: ArticleScanSelectedEvent) {
        this.selectedMaterial = asse.article;
        this.amountInput.nativeElement?.focus();
    }

    updateLieferant(w: WarenkorbPosition, sm: SupplierMaterial) {
        w.lieferant_id = sm.lieferant_id;
        this.service.updateWarenkorbPosition(w).subscribe(
            () => {
                this.loadWarenkorb();
            },
            (error) => {
                this.messageService.errorMessage(
                    'Fehler beim Aktualisieren des Lieferanten aufgetreten!',
                    error
                );
            }
        );
    }

    addPosition() {
        const amount: number = this.positionAmountCtrl.value;

        this.service
            .addArticleToWarenkorb(
                this.selectedMaterial.artikel_id,
                amount,
                this.kommentarCtrl.value
            )
            .subscribe(
                (response) => {
                    this.messageService.infoMessage(
                        response.menge_soll +
                        'x Artikel ' +
                        this.selectedMaterial.bezeichnung +
                        ' dem Warenkorb hinzugefügt!'
                    );
                    this.positionAmountCtrl.setValue(null);
                    this.kommentarCtrl.setValue(null);
                    this.loadWarenkorb();
                    this.service.updateShoppingCartBadge();
                    this.articleAutocomplete.clearAndFocus();
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Konnte ' +
                        amount +
                        'x ' +
                        this.selectedMaterial.bezeichnung +
                        ' nicht dem Warenkorb hinzufügen!',
                        error
                    );
                }
            );
    }

    updateWarenkorbPosition(w: WarenkorbPosition) {
        const menge: number = this.formCtrl[w.warenkorb_id].value;
        w.menge_soll = menge;
        w.kommentar = this.positionKommentarCtrl[w.warenkorb_id].value;
        this.service.updateWarenkorbPosition(w).subscribe(
            () => {
                this.loadWarenkorb();
            },
            () => {
            }
        );
    }

    removeWarenkorbPosition(w: WarenkorbPosition) {
        this.service.deleteWarenkorbPosition(w).subscribe(
            () => {
                this.loadWarenkorb();
                this.service.updateShoppingCartBadge();
            },
            () => {
            }
        );
    }

    releaseWarenkorb() {
        this.service.releaseWarenkorb().subscribe(() => {
            this.service.updateShoppingCartBadge();
            this.loadWarenkorb();
        });
    }

    openReleaseWarenkorbConfirmDialog() {
        const dialogRef = this.dialog.open(ReleaseWarenkorbConfirmDialog, {
            width: '500px',
            data: {
                title: 'Bestellung freigeben',
                text: 'Wollen Sie die Bestellung freigeben?',
                okButtonText: 'Freigeben',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.releaseWarenkorb();
            }
        });
    }
}

@Component({
    selector: 'confirm-release-warenkorb-dialog',
    template: `<h3 mat-dialog-title>{{ title }}</h3>
        <mat-dialog-content>
            <p>{{ text }}</p>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button color="primary" (click)="onCancelClick()">
                Abbrechen
            </button>
            <button mat-raised-button color="primary" (click)="confirmDelete()">
                {{ okButtonText }}
            </button>
        </mat-dialog-actions>`,
})
export class ReleaseWarenkorbConfirmDialog {
    title: string;
    text: string;
    okButtonText: string;

    constructor(
        public dialogRef: MatDialogRef<ReleaseWarenkorbConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.text = data.text;
        this.title = data.title;
        this.okButtonText = data.okButtonText;
    }

    confirmDelete() {
        this.dialogRef.close(true);
    }

    onCancelClick(): void {
        this.dialogRef.close(false);
    }
}
