<h1 mat-dialog-title>Bezahlung</h1>
<div mat-dialog-content>
    <p>Bitte die Bezahlmethode auswählen.</p>
    <mat-form-field style="width: 100%;">
        <mat-label>Bezahlmethode</mat-label>
        <mat-select [(ngModel)]="methode" name="food">
            <mat-option value="bar">Barzahlung</mat-option>
            <mat-option value="karte">Kartenzahlung</mat-option>
            <mat-option value="ueberweisung">Überweisung</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div align="end" mat-dialog-actions>
    <button (click)="cancel()" mat-button>Abbrechen</button>
    <button mat-flat-button color="accent" *kienePermission="[7036]" [disabled]="isSaving || !methode" (click)="save()">
        Speichern
    </button>
</div>