import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from '../../../../services/message-service/message.service';
import { EmailService } from '../../email.service';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Client } from '../../../client/classes/client';
import { PdfViewerDialogInput } from './pdf-viewer-desktop.service';
import { PdfViewerInput } from '../classes/pdf-viewer-input';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Empfaenger } from '../classes/empfaenger';
import { ClientService } from '../../../client/client.service';
import { API_BASE_URL_SERVICE } from '../../../../kiene-core.config';
import { LocalStorageService } from '../../../../services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-pdf-viewer-desktop-dialog',
    templateUrl: './pdf-viewer-desktop-dialog.html',
    styleUrls: ['./pdf-viewer-desktop-dialog.scss'],
})
export class PdfViewerDesktopDialogComponent implements OnInit {
    input: PdfViewerDialogInput;
    isLoading = false;
    pdfAvailable = false;

    customerEmails = '';
    vetAmtEmail = '';
    ueberwachungsbehoerdeEmail = '';

    sendToCustomer = false;
    sendToCustomerAvailable = false;
    sendToVetGroup = false;
    sendToVetGroupAvailable = false;
    sendToVetAmt = false;
    sendToVetAmtAvailable = false;
    sendToUeberwachungsbehoerde = false;
    sendToUeberwachungsbehoerdeAvailable = false;
    neuerEmpfaengerCtrl: UntypedFormControl = new UntypedFormControl(null, Validators.email);
    zusaetzlicheEmpfaenger: Empfaenger[] = [];
    noPdfText = 'Es ist leider keine PDF Datei vorhanden.';
    private openedInNewTab = false;

    constructor(
        private sanatizer: DomSanitizer,
        private clientService: ClientService,
        private messageService: MessageService,
        private emailService: EmailService,
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        public dialogRef: MatDialogRef<PdfViewerDesktopDialogComponent>,
        @Inject(API_BASE_URL_SERVICE) public apiBaseUrl: string,
        @Inject(MAT_DIALOG_DATA) public data: PdfViewerDialogInput
    ) {
        this.input = data;
        this.zusaetzlicheEmpfaenger = this.input.empfaenger;
        console.log('DIALOG', this.input);
    }

    ngOnInit() {
        this.loadClient();
        this.downloadPdfBase64();
    }

    loadClient() {
        if (!this.localStorageService.hasPermission(4501)) {
            return;
        }
        if (this.input?.kunde_id) {
            this.clientService.getClientById(this.input?.kunde_id).subscribe({
                next: (client: Client) => {
                    if (
                        client.email ||
                        client.email_ug ||
                        (this.input?.isInvoice && client.kp_abw_email) ||
                        (!this.input?.isInvoice && client.belege_email)
                    ) {
                        this.sendToCustomerAvailable = true;

                        if (this.input?.isInvoice) {
                            if (client.kp_abw_email) {
                                this.customerEmails = client.kp_abw_email;
                            } else if (client.email) {
                                this.customerEmails = client.email;
                            } else {
                                this.customerEmails = client.email_ug;
                            }
                        } else {
                            if (client.belege_email) {
                                this.customerEmails = client.belege_email;
                            } else if (client.email) {
                                this.customerEmails = client.email;
                            } else {
                                this.customerEmails = client.email_ug;
                            }
                        }
                    }

                    if (client.tierarztgruppe_id) {
                        this.sendToVetGroupAvailable = true;
                    }
                    if (client.email_veterinaeramt) {
                        this.sendToVetAmtAvailable = true;
                        this.vetAmtEmail = client.email_veterinaeramt;
                    }
                    if (client.email_ueberwachungsbehoerde) {
                        this.sendToUeberwachungsbehoerdeAvailable = true;
                        this.ueberwachungsbehoerdeEmail =
                            client.email_ueberwachungsbehoerde;
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Kunde konnte nicht gelader werden!',
                        err
                    );
                },
            });
        }
    }

    downloadPdfBase64() {
        if (this.input?.pdfBase64) {
            this.pdfAvailable = true;
            console.log('pdfBase64 übergeben!');
        } else {
            this.isLoading = true;
            if (!this.input?.params) {
                this.input.params = new HttpParams();
            }
            const params = this.input.params.set('base64', 'true');
            let obs;
            if (this.input?.object) {
                obs = this.http.post(
                    this.apiBaseUrl + this.input?.apiUrl,
                    { object: this.input?.object },
                    { responseType: 'text', params: params }
                );
            } else {
                obs = this.http.get(
                    this.apiBaseUrl + this.input?.apiUrl,
                    {
                        responseType: 'text',
                        params: params
                    }
                );
            }

            obs.subscribe({
                next: (response) => {
                    if (response === 'nio') {
                        this.downloadPdf();
                    } else {
                        this.input.pdfBase64 =
                            this.sanatizer.bypassSecurityTrustResourceUrl(
                                'data:application/pdf;base64,' + response
                            );
                        this.isLoading = false;
                        this.pdfAvailable = true;
                        console.log(this.apiBaseUrl + this.input?.apiUrl);
                    }
                },
                error: (error) => {
                    this.messageService.errorMessage('Fehler:', error);
                    this.isLoading = false;
                    this.pdfAvailable = false;
                },
            });
        }
    }

    downloadPdf() {
        this.isLoading = true;
        let obs;
        if (this.input?.object) {
            obs = this.http.post(
                this.apiBaseUrl + this.input?.apiUrl,
                { object: this.input?.object },
                {
                    responseType: 'blob',
                    params: this.input?.params,
                }
            );
        } else {
            obs = this.http.get(this.apiBaseUrl + this.input?.apiUrl, {
                responseType: 'blob',
                params: this.input?.params,
            });
        }
        obs.subscribe({
            next: (response) => {
                const pdfBlob = new Blob([response], {
                    type: 'application/pdf',
                });
                if (pdfBlob.size > 0) {
                    const url =
                        window.URL.createObjectURL(pdfBlob) + '#view=FitH';
                    window.open(url);
                    this.noPdfText =
                        'PDF-Datei wurde in neuem Fenster geöffnet.';
                    if (this.input?.sende_mail) {
                        this.noPdfText += ' E-Mail Versand ist weiterhin möglich.';
                    }
                }
                this.pdfAvailable = false;
                this.isLoading = false;
                this.openedInNewTab = true;
            },
            error: (error: HttpErrorResponse) => {
                console.error(error);
                this.isLoading = false;
                this.pdfAvailable = false;
                this.messageService.errorMessage(
                    'Es ist ein Fehler beim Laden der PDF aufgetreten! ',
                    error
                );
            },
        });
    }

    sendEmail() {
        const email: PdfViewerInput = new PdfViewerInput();
        email.an_kunde = this.sendToCustomer;
        email.an_tierarztgruppe = this.sendToVetGroup;
        email.an_veterinaeramt = this.sendToVetAmt;
        email.an_ueberwachungsbehoerde = this.sendToUeberwachungsbehoerde;
        email.sende_mail = true;
        this.isLoading = true;
        email.asEmail = true;
        email.params = this.input?.params;
        email.betreff = this.input?.title;
        email.empfaenger = this.zusaetzlicheEmpfaenger;
        email.url = this.input?.apiUrl;

        this.emailService.sendEmail(email).subscribe({
            next: () => {
                this.messageService.infoMessage(
                    'Die Email wurde erfolgreich versendet!'
                );
                this.isLoading = false;
            },
            error: (error) => {
                this.messageService.errorMessage(
                    'Die Email konnte nicht versendet werden! Fehler: ',
                    error
                );
                this.isLoading = false;
            },
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    addToList(email: any) {
        if (!email) {
            return;
        }
        let emailAddress = '';
        if (typeof email === 'string') {
            emailAddress = email;
        } else if (typeof email === 'object') {
            emailAddress = email.email;
        }
        const name = emailAddress.split('@')[0];
        this.zusaetzlicheEmpfaenger.push(new Empfaenger(emailAddress, name, true));
    }

    isSendable() {
        if (!this.pdfAvailable && !this.openedInNewTab) {
            return false;
        }
        if (this.input?.forceSend) {
            return true;
        }
        return (
            this.input?.title &&
            (this.sendToCustomer ||
                this.sendToVetGroup ||
                this.sendToVetAmt ||
                this.sendToUeberwachungsbehoerde ||
                this.zusaetzlicheEmpfaenger.length > 0)
        );
    }

    istNichtKunde() {
        return !this.localStorageService.isCurrentUserKunde() && !this.localStorageService.isCurrentUserViehhaendler();
    }
}
