<div [style.padding]="dialog ? '0' : '0 24px 64px 24px'" class="mat-typography">
    <kiene-page-header *ngIf="!dialog" [routeRelativeAlternative]="'../../'"
        [ueberschrift]="'Tierdetails'"></kiene-page-header>

    <div *ngIf="isLoading" style="display: flex; align-items: center; justify-content: center">
        <mat-hint [style.padding]="dialog ? '24px 0 ' : '128px 0 0 0'" class="hinweis-text" style="text-align: center">
            Lade Tierdaten...
        </mat-hint>
    </div>

    <div *ngIf="!isLoading && tier">
        <div>
            <div class="details-headline" id="heading">
                <h3>Übersicht</h3>
            </div>

            <div id="content">
                <div class="details-item">
                    <strong>Ohrmarke:</strong>
                    <span>{{ tier?.ohrmarke_notnull }}</span>
                </div>

                <div class="details-item">
                    <strong>Gruppe:</strong>
                    <span>{{ tier?.gruppe }}</span>
                </div>

                <div class="details-item">
                    <strong>Geschlecht:</strong>
                    <span>{{ tier?.geschlecht || noValue }}</span>
                </div>

                <div class="details-item">
                    <strong>Rasse:</strong>
                    <span>{{ tier?.rasse || noValue }}</span>
                </div>

                <div class="details-item">
                    <strong>Einstalldatum:</strong>
                    <span>{{
                        (tier?.einstalldatum_notnull | date: "dd.MM.yyyy") ||
                        noValue
                        }}</span>
                </div>

                <div class="details-item">
                    <strong>Geburtsdatum:</strong>
                    <span>{{
                        (tier?.geburtsdatum | date: "dd.MM.yyyy") || noValue
                        }}</span>
                </div>

                <div *ngIf="tier?.todesdatum_notnull" class="details-item">
                    <strong>Todesdatum:</strong>
                    <span>{{
                        (tier?.todesdatum_notnull | date: "dd.MM.yyyy") ||
                        noValue
                        }}</span>
                </div>

                <div class="details-item">
                    <strong>Alter Tage:</strong>
                    <span class="details-item--no-space">
                        <span>{{ tier?.alter_tage || noValue }}</span>
                        <span *ngIf="tier?.alter_tage">&nbsp;Tage</span>
                    </span>
                </div>
                <div class="details-item">
                    <strong>Alter Wochen:</strong>
                    <span class="details-item--no-space">
                        <span>{{ tier?.alter_wochen || noValue }}</span>
                        <span *ngIf="tier?.alter_wochen">&nbsp;Wochen</span>
                    </span>
                </div>
                <div class="details-item">
                    <strong>In VetVet erfasst:</strong>
                    <span>{{
                        (tier?.erstellt | date: "dd.MM.yyyy") || noValue
                        }}</span>
                </div>

                <div *ngIf="tier?.sgs>0" class="details-item">
                    <strong>Ziel Schlachtdatum:</strong>
                    <span>{{
                        (tier?.schlachtdatum_kalb | date: "dd.MM.yyyy") ||
                        noValue
                        }}</span>
                </div>

                <div *ngIf="tier?.tierliste_id>0" class="details-item">
                    <strong>Tierliste:</strong>
                    <span>(#{{ tier?.tierliste_id }}) {{ tier?.tierliste_kommentar }}</span>
                </div>

                <div class="details-item">
                    <strong>IDs:</strong>
                    <span>GT:{{ tier?.gt_id }} / T:{{ tier?.tier_id }}</span>
                </div>
            </div>
        </div>

        <div *ngIf="dialog" style="padding: 6px 0">
            <mat-divider style="width: 100%"></mat-divider>
        </div>

        <div>
            <div class="details-headline" id="heading">
                <h3>Herkunftsbetrieb</h3>
            </div>

            <div id="content">
                <div class="details-item">
                    <strong>VVVO:</strong>
                    <span>{{ tier?.herkunft_vvvo }}</span>
                </div>
                <div class="details-item">
                    <strong>Betrieb:</strong>
                    <span>{{ tier?.herkunft_betrieb }}</span>
                </div>
                <div class="details-item">
                    <strong>Stra&szlig;e:</strong>
                    <span>{{ tier?.herkunft_strasse }}</span>
                </div>
                <div class="details-item">
                    <strong>PLZ Ort:</strong>
                    <span>{{ tier?.herkunft_plz }} {{ tier?.herkunft_ort }}</span>
                </div>
                <div class="details-item">
                    <strong>Ortsteil:</strong>
                    <span>{{ tier?.herkunft_ort }}</span>
                </div>
            </div>
        </div>

        <div *ngIf="dialog" style="padding: 6px 0">
            <mat-divider style="width: 100%"></mat-divider>
        </div>

        <ng-container *kienePermission="[9162]">
            <div>
                <div class="details-headline" id="heading">
                    <h3>Preise</h3>
                </div>

                <div id="content">
                    <div class="details-item">
                        <strong>Preis Einstallung:</strong>
                        <span>{{ (tier?.kp_einstallpreis | number : '1.2-2' : 'de') || noValue }} €</span>
                    </div>

                    <div class="details-item">
                        <strong>Preis Ausstallung:</strong>
                        <span>{{ (tier?.kp_abgangspreis | number : '1.2-2' : 'de') || noValue }} €</span>
                    </div>
                </div>
            </div>

            <div *ngIf="dialog" style="padding: 6px 0">
                <mat-divider style="width: 100%"></mat-divider>
            </div>
        </ng-container>
        <ng-container *kienePermission="[9161]">
            <div>
                <div class="details-headline" id="heading">
                    <h3>Schlachtdaten</h3>
                </div>

                <div id="content">
                    <div class="details-item">
                        <strong>Schlachtung Lebendgewicht:</strong>
                        <span>{{ (tier?.hit_lebendgewicht | number : '1.2-2' : 'de') || noValue }} kg</span>
                    </div>

                    <div class="details-item">
                        <strong>Schlachtgewicht:</strong>
                        <span>{{ (tier?.hit_schlachtgewicht | number : '1.2-2' : 'de') || noValue }} kg</span>
                    </div>

                    <div class="details-item">
                        <strong>Schlachtnummer:</strong>
                        <span>{{ tier?.hit_schlachtnummer || noValue }}</span>
                    </div>

                    <div class="details-item">
                        <strong>Schlachtdatum:</strong>
                        <span>{{
                            (tier?.hit_schlachtdatum | date: "dd.MM.yyyy") ||
                            noValue
                            }}</span>
                    </div>
                    <div class="details-item">
                        <strong>Schlachthof:</strong>
                        <span>{{ tier?.schlachthof || noValue }}</span>
                    </div>
                    <div class="details-item">
                        <strong>Schlachthof VVVO:</strong>
                        <span>{{ tier?.schlachthof_vvvo || noValue }}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="dialog" style="padding: 6px 0">
                <mat-divider style="width: 100%"></mat-divider>
            </div>

        </ng-container>

        <ng-container *kienePermission="[9131,9132]">
            <div>
                <div class="details-headline">
                    <h3>Viehhändler</h3>
                    <button (click)="openViehhaendlerHinzufuegen()" *kienePermission="[9132]" color="accent"
                        mat-icon-button>
                        <mat-icon>add</mat-icon>
                    </button>
                </div>

                <div class="details-item">
                    <span>{{ tier?.viehhaendler || noValue }}</span>
                </div>
            </div>

            <div *ngIf="dialog" style="padding: 6px 0">
                <mat-divider style="width: 100%"></mat-divider>
            </div>
        </ng-container>
        <div>
            <div>
                <h3>HIT-Meldung</h3>
            </div>

            <table style="width: 100%">
                <tr>
                    <td><strong>Einstallen:</strong></td>
                    <td>
                        <strong>{{
                            tier?.status_id_einstallen === 143 ? tier.hit_fehler_einstallen : ""
                            }}</strong>
                    </td>
                    <td>
                        <mat-icon [ngClass]="getIconStatusClass(tier?.status_id_einstallen)">
                            {{ getIcon(tier?.status_id_einstallen) }}
                        </mat-icon>
                    </td>
                </tr>

                <tr>
                    <td><strong>Abgang:</strong></td>
                    <td>
                        <strong>{{
                            tier?.status_id_abgang === 143 ? tier.hit_fehler_abgang : ""
                            }}</strong>
                    </td>
                    <td>
                        <mat-icon [ngClass]="getIconStatusClass(tier?.status_id_abgang)">
                            {{ getIcon(tier?.status_id_abgang) }}
                        </mat-icon>
                    </td>
                </tr>

                <tr>
                    <td><strong>Tod:</strong></td>
                    <td>
                        <strong>{{
                            tier?.status_id_tod === 143 ? tier.hit_fehler_tod : ""
                            }}</strong>
                    </td>
                    <td>
                        <mat-icon [ngClass]="getIconStatusClass(tier?.status_id_tod)">
                            {{ getIcon(tier?.status_id_tod) }}
                        </mat-icon>
                    </td>
                </tr>
            </table>
        </div>

        <div *ngIf="dialog" style="padding: 6px 0">
            <mat-divider style="width: 100%"></mat-divider>
        </div>

        <ng-container *kienePermission="[9231,9232]">
            <div>
                <div class="details-headline">
                    <h3>Reklamationen</h3>
                    <button (click)="openTierreklamationHinzufuegen()" *kienePermission="[9232]" color="accent"
                        mat-icon-button>
                        <mat-icon>add</mat-icon>
                    </button>
                </div>

                <div class="details-item">
                    <div *ngIf="tier?.reklamationen?.length > 0" class="details-item--list">
                        <div *ngFor="let reklamation of tier?.reklamationen">
                            <mat-icon color="accent" style="transform: scale(0.75); padding-right: 6px">
                                comment
                            </mat-icon>
                            <span>&bdquo;{{ reklamation.reklamation }}&ldquo;</span>
                        </div>
                    </div>
                    <div *ngIf="tier?.reklamationen?.length === 0">
                        <span>{{ noValue }}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="dialog && tier?.pdf_id_pass" style="padding: 6px 0">
                <mat-divider style="width: 100%"></mat-divider>
            </div>
        </ng-container>
        <div *ngIf="tier?.pdf_id_pass">
            <h3 style="margin-block-end: 0">Pass PDF</h3>

            <kiene-pdf-viewer-component [pdfViewerInput]="pdfViewerInput"
                style="margin: 0 -12px"></kiene-pdf-viewer-component>

            <div *ngIf="dialog" style="padding: 6px 0">
                <mat-divider style="width: 100%"></mat-divider>
            </div>

            <div *ngIf="dialog" style="padding: 6px 0">
                <mat-divider style="width: 100%"></mat-divider>
            </div>
        </div>
    </div>
</div>