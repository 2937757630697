<h1 mat-dialog-title>
  {{this.inventur.name}} aktivieren
</h1>
<div mat-dialog-content style="padding: 5%">
  Hinweis: Sollte bereits eine andere Inventur aktiv sein, wird diese deaktiviert.
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Abbrechen</button>
  <button mat-flat-button (click)="save()" color="accent">Bestätigen</button>
</div>
