export class Signature {
    unterschrift_id: number;
    kuid: string;
    dokumenttyp: string;
    dokument_id: number;
    gueltig_bis: Date;
    tierarzt_id: number;
    tierarzt_name: string;
    kunde_id: number;
    kunde: string;
    kunde_png: string;
    tierarzt_png: string;
    benutzer: number; // j/n
    benutzer_id: number;
    benutzer_name: string;
    benutzer_png: string;
    unterschrift_tierarzt_vorhanden: number;
}
