<kiene-kiene-table [tableDescriptor]="tableDescriptor" (optionClicked)="catchOptionEvent($event)">
    <div toolbarElementTop>
        <mat-form-field style="width: fit-content;" [matBadge]="clientCtrl.value?.length">
            <mat-label>Kunden</mat-label>
            <mat-select multiple [formControl]="clientCtrl" (selectionChange)="setSelectedClients($event)"
                        [panelClass]="'panel-class'" [compareWith]="clientComparator">
                <mat-option *ngFor="let c of companyGroupClients" [value]="c">{{c.bezeichnung_final}} {{c.zusatz_final}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</kiene-kiene-table>
