import {Component, OnInit} from '@angular/core';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-gruppen',
    templateUrl: './gruppen.component.html',
    styleUrls: ['./gruppen.component.scss']
})
export class GruppenComponent implements OnInit {

    constructor(private localStorageService: LocalStorageService) {
    }

    ngOnInit(): void {
        this.localStorageService.setShoppingCartVisible(false);
    }

    isTabMenuVisible(path: string) {
        return this.localStorageService.currentUserCanSeeTab(path);
    }

}
