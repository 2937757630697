import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import {
    ArticleScanAutocompleteComponent,
    ArticleScanSelectedEvent,
} from '../../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import { KieneAutocompleteComponent } from '../../../components/kiene-autocomplete/kiene-autocomplete.component';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { FileUploadService } from '../../../dialogs/file-upload-dialog/file-upload.service';
import { MessageService } from '../../../services/message.service';
import { RecPositionObject } from '../../../services/rec-position';
import { RecPositionService } from '../../../services/rec-position.service';
import { Material } from '../../../warehouse/material/material';
import { Pruefer } from '../../antibiogramm/antibiogramm';
import {
    Befundmitteilung,
    BefundmitteilungExtern,
    BefundmitteilungPosition,
} from '../befundmitteilung';
import { BefundmitteilungService } from '../befundmitteilung.service';
import { ExterneBefundeDialogComponent } from './externe-befunde-dialog/externe-befunde-dialog.component';
import { IdexxResultDialogComponent } from '../idexx-result-dialog/idexx-result-dialog.component';
import { HttpParams } from '@angular/common/http';
import { LocalStorageService } from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { Gruppe } from '../../../documents/gruppenverwaltung/gruppe';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { Client } from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-detailsseite',
    templateUrl: './detailsseite.component.html',
    styleUrls: ['./detailsseite.component.css'],
    host: { '(window:keydown)': 'checkForArrowKeys($event)' },
})
export class DetailsseiteComponent implements OnInit, OnDestroy {
    @ViewChild('articleScan') articleScan: ArticleScanAutocompleteComponent;
    @ViewChild('gruppeAutocomplete')
    gruppeAutocomplete: KieneAutocompleteComponent;

    befundmitteilung: Befundmitteilung;
    befundmitteilungenExtern: BefundmitteilungExtern[] = [];

    currentGruppe: Gruppe;

    currentEditPosition = -1;

    tagebuchJahr: number;
    tagebuchCtrl = new UntypedFormControl();
    probenentnahmeCtrl = new UntypedFormControl();
    untersuchungsbeginnCtrl = new UntypedFormControl();
    untersuchungsendeCtrl = new UntypedFormControl();
    untersuchungsmaterialCtrl = new UntypedFormControl();
    prueferCtrl = new UntypedFormControl();

    pruefer: Pruefer[] = [];
    idexx_spezies: any[] = [];

    selectedMaterial: Material;
    mengeCtrl = new UntypedFormControl();
    imBefundAnzeigenCtrl = new UntypedFormControl();
    textCtrl = new UntypedFormControl();
    freitextExternCtrl = new UntypedFormControl();
    fusszeileCtrl = new UntypedFormControl();
    idexx_speziesCtrl = new UntypedFormControl();
    idexx_testlaufkommentarCtrl = new UntypedFormControl();
    idexx_probenartCtrl = new UntypedFormControl();

    orderby: string;
    search: string;
    recposition: number;
    recPositionObject: RecPositionObject = new RecPositionObject();

    befundmitteilungId: string;

    routerSub: Subscription;
    currentClient: Client;
    currentClientParams: HttpParams;

    constructor(
        private route: ActivatedRoute,
        private api: KieneTableService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private messageService: MessageService,
        private positionService: RecPositionService,
        private befundmitteilungService: BefundmitteilungService,
        private fileUploadService: FileUploadService,
        private pdfViewerService: PdfViewerDesktopService,
        private router: Router,
        private dialog: MatDialog,
        private localStorageService: LocalStorageService
    ) {
        this.befundmitteilungId = this.route.snapshot.params['id'];

        this.route.queryParams.subscribe((params) => {
            this.orderby = params['orderby'];
            this.search = params['search'];
            this.recposition = params['recposition'];
        });
    }

    ngOnInit(): void {
        this.loadPruefer();
        this.loadBefundmitteilung(this.befundmitteilungId);

        this.routerSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (
                    this.befundmitteilung?.status_id === 11 ||
                    this.befundmitteilung?.status_id === 15
                ) {
                    if (this.hasPermission(1313)) {
                        this.saveAndExit();
                    }
                }
            }
        });

        this.api
            .get(this.apiBaseUrl + 'tierart/read.php', new HttpParams())
            .subscribe({
                next: (response) => {
                    response.records.forEach((record) => {
                        if (record.bezeichnung_eng) {
                            this.idexx_spezies.push(record);
                        }
                    });
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Tierarten konnten nicht geladen werden!',
                        err
                    );
                },
            });
    }

    hasPermission(permissionId: number): boolean {
        return this.localStorageService.currentUserHasPermission(permissionId);
    }

    ngOnDestroy() {
        this.routerSub.unsubscribe();
    }

    loadBefundmitteilung(id: string) {
        if (id === 'neu' || id === 'Neu') {
            this.befundmitteilung = new Befundmitteilung();
            this.befundmitteilung.status_id = 11;
            this.initBefundmitteilung();
            return;
        }
        this.api
            .getOne(this.apiBaseUrl + 'befund/read_one.php', 'befund_id', id)
            .subscribe({
                next: (response) => {
                    this.befundmitteilung = response;
                    this.initBefundmitteilung();
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Der Befund mit der ID ' +
                            id +
                            ' konnte nicht geladen werden!',
                        error
                    );
                },
            });
    }

    initBefundmitteilung() {
        this.loadNeighbours();
        this.loadBefundmitteilungenExtern();

        if (!this.befundmitteilung.eingang) {
            this.befundmitteilung.eingang = new Date();
        }
        this.tagebuchJahr = new Date(
            this.befundmitteilung.eingang
        ).getFullYear();

        if (this.befundmitteilung.tagebuch_nr) {
            this.tagebuchCtrl.setValue(
                this.befundmitteilung.tagebuch_nr.substr(
                    0,
                    this.befundmitteilung.tagebuch_nr.length - 3
                )
            );
        }

        if (this.befundmitteilung.gruppe_id) {
            setTimeout(() => {
                const gruppe = new Gruppe();
                gruppe.gruppe_id = this.befundmitteilung.gruppe_id;
                gruppe.bezeichnung = this.befundmitteilung.gruppe;
                gruppe.kunde_id = this.befundmitteilung.kunde_id;
                gruppe.kunde = this.befundmitteilung.kunde;
                const client = new Client();
                client.kunde_id = this.befundmitteilung.kunde_id;
                client.bezeichnung = this.befundmitteilung.kunde;
                client.kunde_nr_intern = this.befundmitteilung.kunde_nr_intern;
                this.currentClient = client;
                this.currentClientParams = new HttpParams().set(
                    'kunde_id',
                    this.currentClient?.kunde_id
                );
                this.currentGruppe = gruppe;
                this.gruppeAutocomplete?.setValue(gruppe, false);
            }, 1000);
        }

        this.probenentnahmeCtrl.setValue(this.befundmitteilung.probenentnahme);
        this.untersuchungsbeginnCtrl.setValue(this.befundmitteilung.eingang);
        this.untersuchungsendeCtrl.setValue(this.befundmitteilung.ausgang);
        this.untersuchungsmaterialCtrl.setValue(
            this.befundmitteilung.untersuchungsmaterial
        );
        if (this.befundmitteilung.pruefer_id) {
            const p = new Pruefer();
            p.pruefer_id = this.befundmitteilung.pruefer_id;
            p.bezeichnung = this.befundmitteilung.pruefer;
            this.prueferCtrl.setValue(p);
        }
    }

    loadPruefer() {
        if (!this.localStorageService.currentUserHasPermission(2041)) {
            return;
        }
        this.api
            .get(this.apiBaseUrl + 'pruefer/read.php')
            .subscribe((response) => {
                this.pruefer = response.records;
            });
    }

    addFilesToBefundmitteilung(event: Event) {
        this.fileUploadService.openAddFilesDialog(
            this.befundmitteilung.befund_id,
            'befund_id',
            'befund_dokument/create_extern.php',
            () => {
                this.loadBefundmitteilungenExtern();
            },
            () => {
                this.loadBefundmitteilungenExtern();
            }
        );
        event.stopPropagation();
    }

    freigeben() {
        this.befundmitteilung.status_id = 13;
        this.save();
    }

    freigabeEntfernen() {
        this.befundmitteilung.status_id = 11;
        this.save();
    }

    abschliessen() {
        this.befundmitteilung.status_id = 12;
        this.save({ showPdf: true });
    }

    showPdf(befundmitteilung: Befundmitteilung) {
        const input = new PdfViewerDialogInput(
            'befund/read_or_mail_pdf_merged.php',
            new HttpParams().set(
                'befund_id',
                befundmitteilung?.befund_id?.toString()
            ),
            this.befundmitteilungService.getPdfTitle(befundmitteilung),
            {
                email: {
                    sende_mail: true,
                    kunde_id: befundmitteilung?.kunde_id,
                },
                targetRoute: ['/labor', 'befundmitteilungen'],
            }
        );
        this.pdfViewerService.openPdfViewer(input);
    }

    goBack() {
        this.router.navigate(['../'], { relativeTo: this.route }).then();
    }

    checkForArrowKeys(event: any) {
        if (event.altKey && event.code === 'ArrowRight') {
            this.naechsteBefundmitteilung();
        } else if (event.altKey && event.code === 'ArrowLeft') {
            this.vorherigeBefundmitteilung();
        }
    }

    setSelectedMaterial(asse: ArticleScanSelectedEvent) {
        this.selectedMaterial = asse.article;
    }

    setSelectedGruppe(gruppe: Gruppe) {
        this.currentGruppe = gruppe;
    }

    replaceFreitext(event) {
        this.textCtrl.setValue(event);
    }

    replaceUntersuchungsmaterial(event) {
        this.untersuchungsmaterialCtrl.setValue(event);
    }

    replaceFreitextExtern(event) {
        this.freitextExternCtrl.setValue(event);
    }

    replaceFusszeile(event) {
        this.fusszeileCtrl.setValue(event);
    }

    replaceIdexxTestlaufKommentar(event) {
        this.idexx_testlaufkommentarCtrl.setValue(event);
    }

    positionBearbeiten(index: number) {
        if (this.befundmitteilung?.status_id === 12) {
            return;
        }
        this.currentEditPosition = index;

        const p = this.befundmitteilung.positionen[this.currentEditPosition];
        const material = new Material();
        material.artikel_id = p.artikel_id;
        material.artikel_me = p.artikel;
        material.idexx = p.idexx;
        this.articleScan.setMaterial(material, false);
        this.selectedMaterial = material;
        this.mengeCtrl.setValue(p.artikelmenge);
        this.textCtrl.setValue(p.freitext);
        this.freitextExternCtrl.setValue(p.freitext_extern);
        this.imBefundAnzeigenCtrl.setValue(p.auflisten);
        this.fusszeileCtrl.setValue(p.fusszeile);
        this.idexx_testlaufkommentarCtrl.setValue(p.idexx_testlaufkommentar);
        this.idexx_probenartCtrl.setValue(p.idexx_probenart);
        this.idexx_speziesCtrl.setValue(
            this.idexx_spezies.find(
                (o) => o.tierart_id === p.idexx_spezies_tierart_id
            )
        );
    }

    savePosition() {
        if (this.currentEditPosition > -1) {
            this.befundmitteilung.positionen[
                this.currentEditPosition
            ].artikel_id = this.selectedMaterial.artikel_id;
            this.befundmitteilung.positionen[this.currentEditPosition].artikel =
                this.selectedMaterial.artikel_me;
            this.befundmitteilung.positionen[
                this.currentEditPosition
            ].artikelmenge = this.mengeCtrl.value;
            this.befundmitteilung.positionen[
                this.currentEditPosition
            ].freitext = this.textCtrl.value;
            this.befundmitteilung.positionen[
                this.currentEditPosition
            ].freitext_extern = this.freitextExternCtrl.value;
            this.befundmitteilung.positionen[
                this.currentEditPosition
            ].auflisten = this.imBefundAnzeigenCtrl.value ? 1 : 0;
            this.befundmitteilung.positionen[
                this.currentEditPosition
            ].fusszeile = this.fusszeileCtrl.value;

            this.befundmitteilung.positionen[
                this.currentEditPosition
            ].idexx_spezies_tierart_id =
                this.idexx_speziesCtrl.value?.tierart_id;
            this.befundmitteilung.positionen[
                this.currentEditPosition
            ].idexx_spezies = this.idexx_speziesCtrl.value?.bezeichnung;
            this.befundmitteilung.positionen[
                this.currentEditPosition
            ].idexx_probenart = this.idexx_probenartCtrl.value;
            this.befundmitteilung.positionen[
                this.currentEditPosition
            ].idexx_testlaufkommentar = this.idexx_testlaufkommentarCtrl.value;
            this.clearInputFields();
            this.save();
        } else {
            this.addPosition();
        }
    }

    clearInputFields() {
        this.mengeCtrl.setValue(null);
        this.imBefundAnzeigenCtrl.setValue(false);
        this.textCtrl.setValue(null);
        this.freitextExternCtrl.setValue(null);
        this.fusszeileCtrl.setValue(null);
        this.articleScan.clearAndFocus();
        this.currentEditPosition = -1;
        this.idexx_probenartCtrl.reset();
        this.idexx_speziesCtrl.reset();
        this.idexx_testlaufkommentarCtrl.reset();
    }

    addPosition() {
        const position = new BefundmitteilungPosition();
        position.artikel_id = this.selectedMaterial.artikel_id;
        position.artikel = this.selectedMaterial.artikel_me;
        position.artikelmenge = this.mengeCtrl.value;
        position.auflisten = this.imBefundAnzeigenCtrl.value ? 1 : 0;
        position.freitext = this.textCtrl.value;
        position.freitext_extern = this.freitextExternCtrl.value;
        position.fusszeile = this.fusszeileCtrl.value;
        position.idexx_testlaufkommentar =
            this.idexx_testlaufkommentarCtrl.value;
        position.idexx_probenart = this.idexx_probenartCtrl.value;
        position.idexx_spezies_tierart_id =
            this.idexx_speziesCtrl.value?.tierart_id;
        position.idexx_spezies = this.idexx_speziesCtrl.value?.bezeichnung;
        this.befundmitteilung.positionen.push(position);
        this.clearInputFields();
        this.save();
    }

    prueferComparator(p1: Pruefer, p2: Pruefer) {
        if (p1 && p2) {
            return p1.pruefer_id === p2.pruefer_id;
        }
        return false;
    }

    save(options?: { exit?: boolean; showPdf?: boolean }) {
        let tbn = <string>this.tagebuchCtrl.value;
        if (tbn?.endsWith('/')) {
            tbn = tbn.substring(0, tbn.length - 1);
        }

        this.befundmitteilung.tagebuch_nr =
            tbn + '/' + this.tagebuchJahr.toString().substr(-2);
        this.befundmitteilung.probenentnahme = this.probenentnahmeCtrl.value;
        this.befundmitteilung.eingang = this.untersuchungsbeginnCtrl.value;
        this.befundmitteilung.ausgang = this.untersuchungsendeCtrl.value;
        this.befundmitteilung.gruppe_id = this.currentGruppe?.gruppe_id;
        this.befundmitteilung.untersuchungsmaterial =
            this.untersuchungsmaterialCtrl.value;
        const pruefer: Pruefer = this.prueferCtrl.value;
        if (pruefer) {
            this.befundmitteilung.pruefer_id = pruefer.pruefer_id;
        }

        this.api
            .updateEntity(
                this.apiBaseUrl + 'befund/update.php',
                this.befundmitteilung
            )
            .subscribe({
                next: (response) => {
                    this.befundmitteilung = response;
                    this.messageService.infoMessage(
                        'Befundmitteilung wurde erfolgreich gespeichert!'
                    );
                    if (options) {
                        if (options.showPdf) {
                            this.showPdf(response);
                        }
                        if (options.exit) {
                            this.goBack();
                        }
                    }
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Befundmitteilung konnte nicht gespeichert werden!',
                        error
                    );
                },
            });
    }

    saveAndExit() {
        if (this.befundmitteilung?.status_id === 12) {
            this.goBack();
        } else {
            // 1313: Labor - Befundmitteilung - editieren
            if (this.hasPermission(1313)) {
                this.save({ exit: true });
            } else {
                this.goBack();
            }
        }
    }

    positionEntfernen(index: number) {
        this.befundmitteilung.positionen.splice(index, 1);
        this.save();
    }

    loadBefundmitteilungenExtern() {
        if (!this.befundmitteilung.befund_id) {
            return;
        }
        this.befundmitteilungService
            .getPdfIdsForBefundmitteilung(
                this.befundmitteilung.befund_id.toString()
            )
            .subscribe({
                next: (response) => {
                    this.befundmitteilungenExtern = response.records;
                },
                error: () => {},
            });
    }

    loadNeighbours() {
        this.positionService
            .getNeighbours(
                'befund',
                this.orderby,
                this.search,
                this.recposition
            )
            .subscribe((response) => {
                this.recPositionObject = response;
                this.recposition = this.recPositionObject?.curr?.recposition;
            });
    }

    vorherigeBefundmitteilung() {
        const id = this.recPositionObject.prev.id.toString();
        this.recposition = this.recPositionObject.prev.recposition;
        this.loadBefundmitteilung(id);
        this.loadBefundmitteilungenExtern();
        this.initBefundmitteilung();
    }

    naechsteBefundmitteilung() {
        const id = this.recPositionObject.next.id.toString();
        this.recposition = this.recPositionObject.next.recposition;
        this.loadBefundmitteilung(id);
        this.loadBefundmitteilungenExtern();
        this.initBefundmitteilung();
    }

    createNotiz() {
        this.befundmitteilungService.getNotizzettel(this.befundmitteilung);
    }

    openPdfPreview(event: Event) {
        event.stopPropagation();

        const input = new PdfViewerDialogInput(
            'befund/pdf_vorschau.php',
            null,
            'Vorschau Befundmitteilung ' + this.befundmitteilung.tagebuch_nr
        );
        input.object = this.befundmitteilung;
        this.pdfViewerService.openPdfViewer(input);
    }

    uploadExterneBefundmitteilung(files: File[]) {
        this.fileUploadService.uploadFilesToServer(
            files,
            'befund_id',
            this.befundmitteilung.befund_id,
            'befund_dokument/create_extern.php'
        );
        this.fileUploadService.watchFileUploaded().subscribe((fileName) => {
            for (const f of files) {
                if (f.name === fileName) {
                    this.loadBefundmitteilungenExtern();
                }
            }
        });
    }

    openExterneBefundeDialog() {
        this.dialog.open(ExterneBefundeDialogComponent, {
            data: {
                externeBefunde: this.befundmitteilungenExtern,
                befundmitteilung: this.befundmitteilung,
            },
        });
    }

    dropPosition(event: CdkDragDrop<BefundmitteilungPosition[]>) {
        if (this.befundmitteilung?.status_id === 12) {
            return;
        }
        moveItemInArray(
            this.befundmitteilung.positionen,
            event.previousIndex,
            event.currentIndex
        );
        this.reorderPositionNumbers();
        this.save();
    }

    reorderPositionNumbers() {
        for (let i = 0; i < this.befundmitteilung.positionen.length; i++) {
            this.befundmitteilung.positionen[i].position = i + 1;
        }
    }

    transformToAntibiogramm() {
        this.api
            .post(
                this.apiBaseUrl + 'antibiogramm/transform.php',
                this.befundmitteilung,
                null
            )
            .subscribe({
                next: (response) => {
                    this.router
                        .navigate(
                            ['../../antibiogramme', response.antibiogramm_id],
                            { relativeTo: this.route }
                        )
                        .then();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Antibiogramm konnte nicht erstellt werden! ',
                        err
                    );
                },
            });
    }

    getTestlaufStatus() {
        return this.befundmitteilung.positionen[this.currentEditPosition]
            ?.abgeschlossen;
    }

    isIdexxPosition(p: BefundmitteilungPosition) {
        return p.idexx === 1;
    }

    openResultDialog(position: BefundmitteilungPosition) {
        const dialogRef = this.dialog.open(IdexxResultDialogComponent, {
            data: {
                befundeArtikelId: position.ba_id,
            },
        });
        dialogRef.afterClosed().subscribe({
            next: () => {},
        });
    }

    setSelectedClient(client: Client) {
        this.currentClient = client;
        if (!client) {
            this.currentGruppe = undefined;
            this.currentClientParams = undefined;
            this.gruppeAutocomplete.clear();
        } else {
            this.currentClientParams = new HttpParams().set(
                'kunde_id',
                this.currentClient?.kunde_id
            );
            setTimeout(() => {
                this.gruppeAutocomplete.clearAndFocus();
            }, 100);
        }
    }

    isClientDisabled() {
        if (this.befundmitteilung?.rechnung_id) {
            return true;
        }
        return false;
    }
}
