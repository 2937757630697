import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "../app.auth.guard";
import { AntibiogrammDetailsComponent } from "./antibiogramm/antibiogramm-details/antibiogramm-details.component";
import { AntibiogrammComponent } from "./antibiogramm/antibiogramm.component";
import { BefundmitteilungComponent } from "./befundmitteilung/befundmitteilung.component";
import { DetailsseiteComponent } from "./befundmitteilung/detailsseite/detailsseite.component";
import { BehandlungsArtikelComponent } from "./behandlungs-artikel/behandlungs-artikel.component";
import { LaboratoryComponent } from "./laboratory.component";
import { LaboreComponent } from "./labore/labore.component";
import { ProbenbegleitscheinDetailsComponent } from "./probenbegleitschein/probenbegleitschein-details/probenbegleitschein-details.component";
import { ProbenbegleitscheinComponent } from "./probenbegleitschein/probenbegleitschein.component";
import { TextAutocompleteComponent } from "./text-autocomplete/text-autocomplete.component";
import { UntersuchungsauftragDetailsComponent } from "./untersuchungsauftrag/untersuchungsauftrag-details/untersuchungsauftrag-details.component";
import { UntersuchungsauftragComponent } from "./untersuchungsauftrag/untersuchungsauftrag.component";
import { UntersuchungsgruppeComponent } from "./untersuchungsgruppe/untersuchungsgruppe.component";
import { UntersuchungsmaterialKonfiguratorComponent } from "./untersuchungsmaterialien/untersuchungsmaterial-konfigurator/untersuchungsmaterial-konfigurator.component";
import { UntersuchungsmaterialienComponent } from "./untersuchungsmaterialien/untersuchungsmaterialien.component";

const routes: Routes = [
    {
        path: "",
        component: LaboratoryComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: "befundmitteilungen",
                component: BefundmitteilungComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "befundmitteilungen/:id",
                component: DetailsseiteComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "antibiogramme",
                component: AntibiogrammComponent,
                data: { title: "Antibiogramme" },
                canActivate: [AuthGuard],
            },
            {
                path: "antibiogramme/:id",
                component: AntibiogrammDetailsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "probenbegleitscheine",
                component: ProbenbegleitscheinComponent,
                data: { title: "Probenbegleitschein" },
                canActivate: [AuthGuard],
            },
            {
                path: "probenbegleitscheine/:id",
                component: ProbenbegleitscheinDetailsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "behandlungsartikel",
                component: BehandlungsArtikelComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "textautocomplete",
                component: TextAutocompleteComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "untersuchungsmaterialien",
                component: UntersuchungsmaterialienComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "untersuchungsmaterialkonfigurator/:id",
                component: UntersuchungsmaterialKonfiguratorComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "untersuchungsgruppen",
                component: UntersuchungsgruppeComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "labore",
                component: LaboreComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "untersuchungsauftrag",
                component: UntersuchungsauftragComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "untersuchungsauftrag/:id",
                component: UntersuchungsauftragDetailsComponent,
                canActivate: [AuthGuard],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LaboratoryRoutingModule {}
