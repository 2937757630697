import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'kiene-ohrmarke',
  templateUrl: './ohrmarke.component.html',
  styleUrls: ['./ohrmarke.component.scss']
})
export class OhrmarkeComponent implements OnInit, OnChanges {

  part1 = '';
  part2 = '';

  @Input('ohrmarke') ohrmarke: string;

  constructor() { }

  ngOnInit(): void {
    this.cutInPieces();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cutInPieces();
  }

  cutInPieces() {
    if (this.ohrmarke) {
      if (this.ohrmarke.length === 15) {
        this.part1 = this.ohrmarke.substring(0, 10);
        this.part2 = this.ohrmarke.substring(10, 15);
      } else if (this.ohrmarke.length === 12) {
        this.part1 = this.ohrmarke.substring(0, 7);
        this.part2 = this.ohrmarke.substring(7, 12);
      } else if (this.ohrmarke.length === 10) {
        this.part1 = this.ohrmarke.substring(0, 5);
        this.part2 = this.ohrmarke.substring(5, 10);
      }
    }
  }
}
