export class KienePatternUtils {

    public static PLZ(): RegExp {
        return /^\d{5}$/;
    }
    public static VVVO(): RegExp {
        return /^\d{15}$/;
    }
    public static EMAIL(): RegExp {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
    }

    public static EMAIL_MINIMAL(): RegExp {
        return /.+@[^@]+\.[^@]{2,}$/;
    }

    public static NAME(): RegExp {
        return /^[a-zA-ZäöüÄÖÜß '-]+$/;
    }

    public static MOBILTELEFON_INTERNATIONAL() {
        return /^\+(?:\d ?){6,14}\d$/;
    }

    public static MOBILTELEFON_DE() {
        return /0([1-9][\d\.\-]+(\s?\-\s?)?[1-9][\d\.\-]*)/;
    }

    public static IBAN_INTERNATIONAL() {
        return /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/;
    }

    public static IBAN_DE() {
        return /^DE[a-zA-Z\d]{2}\s?(\d{4}\s?){4}(\d{2})\s?$/;
    }

    public static FAX(): RegExp {
        return /^(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)(\d+))/;
    }

    public static TELEFON() {
        return /^((\+|00)[1-9]\d{0,3}|0 ?[1-9]|\(00? ?[1-9][\d ]*\))[\d\-/ ]*$/;
        // return /^(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/;
    }

    public static OR(KienePatterns: RegExp[]): RegExp {
        return new RegExp(KienePatterns.map(pattern => pattern.source).join('|'));
    }

    public static PASSWORD(options?: {minLength?: number, maxLength?: number, upperCase?: boolean, numbers?: boolean, specialCharacters?: boolean}) {
        // pattern = `^.+$`;
        // customPattern = ``;
        // length
        // if(options) {
        //     if(options.minLength){}
        // }
        //
        // let minLength = options?.minLength || 8;
        // let maxLength = options?.maxLength || 16;
        // let upperCase = options?.upperCase || true;
        // let numbers = options?.numbers || true;
        // let specialCharacters = options?.specialCharacters || true;
        // let pattern = `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{${minLength},${maxLength}}).*$`;
        // if (upperCase) {
        //     pattern = `${pattern}(?=.*[A-Z])`;
        // }
        // if (numbers) {
        //     pattern = `${pattern}(?=.*[0-9])`;
        // }
        // if (specialCharacters) {
        //     pattern = `${pattern}(?=.*[!@#$%^&*])`;
        // }
        // return new RegExp(pattern);
    }


}
