import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import {API_BASE_URL_SERVICE} from '../../../kiene-core.config';
import {Empfaenger} from '../kiene-pdf-viewer/classes/empfaenger';
import {PdfViewerInput} from '../kiene-pdf-viewer/classes/pdf-viewer-input';
import {EmailService} from '../email.service';
import {MessageService} from '../../../services/message-service/message.service';
import {LocalStorageService} from '../../../services/local-storage/local-storage.service';
import {UntypedFormControl, Validators} from '@angular/forms';

@Component({
    selector: 'kiene-pdf-viewer-send-email-dialog',
    templateUrl: './pdf-viewer-send-email-dialog.component.html',
    styleUrls: ['./pdf-viewer-send-email-dialog.component.scss'],
})
export class PdfViewerSendEmailDialogComponent implements OnInit {
    pdfViewerInput: PdfViewerInput;
    empfaenger: Empfaenger[] = [];
    betreff: string;
    isSending = false;
    neuerEmpfaengerCtrl: UntypedFormControl = new UntypedFormControl(
        null,
        Validators.email
    );

    constructor(
        public dialogRef: MatDialogRef<PdfViewerSendEmailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PdfViewerInput,
        private http: HttpClient,
        @Inject(API_BASE_URL_SERVICE) public apiBaseUrl: string,
        private emailService: EmailService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService
    ) {
        this.pdfViewerInput = data;
        this.betreff = this.pdfViewerInput.betreff;
    }

    ngOnInit(): void {
        const user = this.localStorageService.getCurrentUser();
        this.empfaenger = [
            new Empfaenger(
                user.email,
                `${user.vorname} ${user.nachname}`,
                true
            ),
            ...this.pdfViewerInput.empfaenger,
        ];
    }

    isValid() {
        return this.empfaenger.some((e) => e.selected) && this.betreff;
    }

    send() {
        this.isSending = true;
        const input = new PdfViewerInput(
            this.pdfViewerInput.url,
            this.pdfViewerInput.params,
            {betreff: this.betreff}
        );
        input.empfaenger = this.empfaenger.filter((e) => e.selected);

        this.emailService.sendEmail(input).subscribe({
            next: () => {
                this.isSending = false;
                this.messageService.infoMessage(
                    'Die Email(s) werden nun im Hintergrund versandt!'
                );
                this.dialogRef.close(true);
            },
            error: (err) => {
                this.isSending = false;
                this.messageService.errorMessage(
                    'Fehler beim Versenden der Email(s)!',
                    err
                );
            },
        });
    }

    setSelected(event: MouseEvent, e: Empfaenger) {
        event.preventDefault();
        event.stopPropagation();
        e.selected = !e.selected;
    }

    addToList(email: any) {
        if (!email) {
            return;
        }
        let emailAddress = '';
        if (typeof email === 'string') {
            emailAddress = email;
        } else if (typeof email === 'object') {
            emailAddress = email.email;
        }
        const name = emailAddress.split('@')[0];
        this.empfaenger.push(new Empfaenger(emailAddress, name, true));
    }

    // addToList() {
    //     if (this.neuerEmpfaengerCtrl.hasError('email')) {
    //         this.neuerEmpfaengerCtrl.markAsTouched();
    //         return;
    //     }
    //     if (this.neuerEmpfaengerCtrl.value) {
    //         const email = this.neuerEmpfaengerCtrl.value;
    //         const name = email.split('@')[0];
    //         this.empfaenger.push(new Empfaenger(email, name, true));
    //         this.neuerEmpfaengerCtrl.clearValidators();
    //         this.neuerEmpfaengerCtrl.markAsUntouched();
    //         this.neuerEmpfaengerCtrl.setValue(null);
    //     }
    // }
}
