<div style="padding: 24px">
    <mat-radio-group [formControl]="ohrmarkePassCtrl" style="
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            margin-bottom: 12px;
        ">
        <mat-radio-button [value]="0">Ohrmarken</mat-radio-button>
        <mat-radio-button [value]="1">P&auml;sse</mat-radio-button>
    </mat-radio-group>
    <div class="one-row">
        <mat-form-field class="no-padding-margin-mat-form-field" style="width: 100%; margin-bottom: 6px">
            <mat-label>{{
                    ohrmarkePassCtrl.value === 0 ? "Ohrmarke " : "Pass "
                }}
                scannen
            </mat-label>
            <input #ohrenmarkeInput="matInput" (keyup.enter)="addOhrmarkePass($event)" [formControl]="ohrenmarkeCtrl"
                   matInput
                   maxlength="{{ maxLengthOhrmarke }}" type="text"/>
            <span matSuffix style="font-size: 0.8em">{{ ohrenmarkeCtrl.value?.length }}/{{
                    maxLengthOhrmarke
                }}</span>
            <button (click)="addOhrmarkePass($event)" *ngIf="this.ohrenmarkeCtrl.value?.length === maxLengthOhrmarke"
                    mat-icon-button matSuffix>
                <mat-icon>check</mat-icon>
            </button>
            <button *ngIf="this.ohrenmarkeCtrl.value?.length !== maxLengthOhrmarke" [disabled]="true" mat-icon-button
                    matSuffix></button>
        </mat-form-field>
    </div>

    <div class="one-row">
        <div class="one-row" style="width: 90%; justify-content: start">
            <mat-slide-toggle [formControl]="mitGewichtToggleCtrl"></mat-slide-toggle>
            <p (click)="
                    mitGewichtToggleCtrl.setValue(!mitGewichtToggleCtrl.value)
                " style="font-size: 0.8em; margin-left: 3px">
                mit Gewicht oder Abteil
            </p>
        </div>

        <span class="spacer"></span>
    </div>

    <div style="flex-grow: 1"></div>

    <div id="gescannteOhrenmarken" style="min-height: 50%; margin-left: 12px; padding-bottom: 240px">
        <h3>Zuletzt gescannt:</h3>
        <p *ngIf="gescannteOhrmarken?.length < 1">---</p>
        <ul style="
                list-style-type: none;
                padding: 0;
                margin: 0;
                font-family: 'Courier New', sans-serif;
            ">
            <li [@openClose]="openCloseState" class="ohrenmarke-list-item" style="margin-bottom: 24px">
                <div *ngIf="gescannteOhrmarken?.length > 0" class="one-row">
                    <span class="spacer"></span>
                    <mat-icon [style.color]="
                            gescannteOhrmarken[0].success ? 'green' : '#9b0024'
                        ">
                        {{
                            gescannteOhrmarken[0].success ? "check" : "error"
                        }}
                    </mat-icon>
                    <span class="spacer"></span>
                    <div class="ohrenmarke-list-item--text" id="item-text">
                        <span>
                            <kiene-ohrmarke [ohrmarke]="gescannteOhrmarken[0].ohrmarke" style="font-size: 0.8em">
                            </kiene-ohrmarke>
                        </span>
                    </div>
                    <span class="spacer"></span>
                </div>
            </li>
            <li *ngFor="let ohrmarke of gescannteOhrmarken; let i = index" class="ohrenmarke-list-item">
                <div *ngIf="i > 0" class="one-row">
                    <span class="spacer"></span>
                    <mat-icon [style.color]="
                                ohrmarke.success ? 'green' : '#9b0024'
                            ">{{
                            ohrmarke.success ? "check" : "error"
                        }}
                    </mat-icon>
                    <span class="spacer"></span>
                    <div class="ohrenmarke-list-item--text">
                        <span>
                            <kiene-ohrmarke [ohrmarke]="ohrmarke.ohrmarke" style="font-size: 0.8em">
                            </kiene-ohrmarke>
                        </span>
                    </div>
                    <span class="spacer"></span>
                </div>
            </li>
        </ul>
    </div>
</div>