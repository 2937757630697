<div [style.padding]="showAsDialog ? '0' : '0 24px 64px 24px'">

    <kiene-page-header *ngIf="!showAsDialog" [routeRelativeAlternative]="backRoute"
                       [ueberschrift]="entsorger ? entsorger.name : 'Entsorger anlegen'"></kiene-page-header>

    <div *ngIf="entsorger">
        <span>{{ entsorger.name }}</span><br>
        <span>{{ entsorger.vvvo }}</span><br>
        <span>{{ entsorger.strasse }}</span><br>
        <span>{{ entsorger.plz }}&nbsp;{{ entsorger.ort }}</span>
    </div>

    <button *ngIf="entsorger?.favorit" (click)="deleteEntsorgerFavorit($event)" color="warn" mat-flat-button
            style="margin: 12px 0 12px 0"
            type="button">
        <mat-icon>delete</mat-icon>
        Entsorger&nbsp;entfernen
    </button>

    <div style="width: 100%;">

        <kiene-autocomplete #nameAutocomplete (elementSelected)="selectedEntsorger($event)"
                            *ngIf="!entsorger?.entsorger_id"
                            [displayFields]="['name', 'ort']"
                            [separator]="', '"
                            [emitFreeText]="false"
                            [createNewAllowed]="true"
                            [createNewText]="'Entsorger anlegen'"
                            (createNew)="createNewEntsorger($event)"
                            [focus]="false"
                            [genericApiUrl]="entsorgerPath" [panelWidth]="'100%'"
                            [placeholder]="'Name'"
                            [required]="true" [showClear]="false"
                            [showLoadingIndicator]="true" style=" width: 100%">
        </kiene-autocomplete>
    </div>

    <div *ngIf="entsorger?.kundenNrVvvos?.length > 0">
        <h3>Kundennummern pro VVVO</h3>
        <div style="margin: 6px 0 12px 0">
            <mat-hint class="hinweis-text">
            <span>
                <strong>Hinweis:&nbsp;</strong>
                Bitte pro VVVO die Kundennr. für den Entsorger angegeben, sonst kann später keine Todmeldung getätigt werden.
            </span>
            </mat-hint>
        </div>


        <mat-card *ngFor="let zuordnung of entsorger?.kundenNrVvvos" style="margin-bottom: 6px; padding: 12px">
            <h3 style="margin-block: 0">{{ zuordnung.vvvo }}</h3>
            <div>
                <mat-hint class="hinweis-text">{{ zuordnung.betrieb }}</mat-hint>
                <span *ngIf="zuordnung.betrieb_zusatz"> - </span>
                <mat-hint class="hinweis-text">{{ zuordnung.betrieb_zusatz }}</mat-hint>
            </div>
            <mat-form-field class="no-padding-margin-mat-form-field" style="width: 100%">
                <input [(ngModel)]="zuordnung.kunden_nr" matInput placeholder="Kundennummer">
            </mat-form-field>
        </mat-card>
    </div>


    <div [style]="showAsDialog ? 'position: sticky; bottom: 0; background-color: white; padding: 12px 0;' : ''"
         class="one-row">
        <span *ngIf="!showAsDialog" class="spacer"></span>
        <button (click)="addOrUpdateEntsorger()" [disabled]="isLoading" color="accent" mat-flat-button>Speichern
        </button>
        <span class="spacer"></span>
        <ng-content select="[additional-actions]"></ng-content>
    </div>


</div>
