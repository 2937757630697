import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Signature } from '../signature';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../services/message-service/message.service';


@Component({
  selector: 'kiene-qrcode-dialog',
  templateUrl: './qrcode-dialog.component.html',
  styleUrls: ['./qrcode-dialog.component.scss']
})
export class QRCodeDialogComponent implements OnInit {

  signature: Signature;
  qrcodeUrl = '';

  constructor(public dialogRef: MatDialogRef<QRCodeDialogComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    this.signature = data.signature;
  }

  ngOnInit() {

  }

  cancel() {
    this.dialogRef.close(null);
  }

}
