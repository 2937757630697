<h2 mat-dialog-title>Tierart</h2>
<mat-dialog-content>
  <mat-form-field style="width: 100%;">
    <input type="text" disabled="true" matInput placeholder="Tierart" [(ngModel)]="tierart.bezeichnung">
  </mat-form-field>
  <mat-form-field style="width: 100%;">
    <input type="text" disabled="true" matInput placeholder="Kürzel" [(ngModel)]="tierart.kuerzel">
  </mat-form-field>
  
</mat-dialog-content>
<mat-checkbox [formControl]="mnp_rind_ctrl">Maßnahmenplan Rind</mat-checkbox><br>
<mat-checkbox [formControl]="gt_eight_ctrl">&gt; 8 Monate</mat-checkbox><br>
<mat-checkbox [formControl]="mnp_schwein_ctrl">Maßnahmenplan Schwein</mat-checkbox><br>
<mat-checkbox [formControl]="gt_thirty_ctrl">&gt; 30 kg</mat-checkbox><br>
<mat-checkbox [formControl]="hit_ctrl">HIT</mat-checkbox><br>
<mat-checkbox [formControl]="qs_ctrl">QS</mat-checkbox><br>
<mat-dialog-actions>
  <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
  <button mat-raised-button color="primary" (click)="save()">Speichern</button>
</mat-dialog-actions>