<h1 mat-dialog-title>Bestellung korrigieren</h1>
<div mat-dialog-content>
  <p>Bestellung f&uuml;r den Artikel {{wareneingangshistorie.artikel_me}} korrigieren.</p>
  <mat-form-field>
    <mat-label>Neue Bestell Nr.</mat-label>
    <input matInput type="number" [formControl]="orderNoCtrl">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Abbrechen</button>
  <button mat-flat-button color="accent" (click)="save()">Korrigieren</button>
</div>