import {Component, Inject, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Bestandsbuch} from '../classes/bestandsbuch';
import {BestandsbuchArtikel} from '../classes/bestandsbuch-artikel';
import {BestandsbuchService} from '../bestandsbuch.service';
import {MessageService} from '../../services/message-service/message.service';
import {UnterschriftService} from '../../components/stammdaten/unterschrift/unterschrift.service';
import {BestandsbuchStatus} from '../classes/bestandsbuch-status';
import {User} from '../../components/client/classes/user';
import {Subscription} from 'rxjs';
import {PdfViewerInput} from '../../components/pdf-viewer/kiene-pdf-viewer/classes/pdf-viewer-input';
import {HttpParams} from '@angular/common/http';
import {PdfViewerDialogComponent} from '../../components/pdf-viewer/pdf-viewer-dialog/pdf-viewer-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
    BestandsbuchArtikelDetailsComponent
} from '../bestandsbuch-artikel-details/bestandsbuch-artikel-details.component';
import {KieneBackendApiService} from '../../services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../kiene-core.config';
import {ConfirmDialogComponent} from '../../components/dialogs/confirm-dialog/confirm-dialog.component';
import {ConfirmDialogInput, ConfirmDialogResult} from '../../components/dialogs/confirm-dialog/confirm-dialog';
import {EnterValueDialogComponent} from '../../components/dialogs/enter-value-dialog/enter-value-dialog.component';
import {EnterValueInput, EnterValueResponse} from '../../components/dialogs/enter-value-dialog/enter-value-response';
import {
    UnterschriftDialogComponent
} from '../../components/unterschriften/unterschrift-dialog/unterschrift-dialog.component';
import {LocalStorageService} from '../../services/local-storage/local-storage.service';
import {BenutzerSignatur} from '../../components/stammdaten/unterschrift/classes/benutzer-signatur';
import {UnterschriftDialogInput} from '../../components/unterschriften/unterschrift-dialog/unterschrift-dialog';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'kiene-bestandsbuch-artikel-uebersicht',
    templateUrl: './bestandsbuch-artikel-uebersicht.component.html',
    styleUrls: ['./bestandsbuch-artikel-uebersicht.component.scss'],
})
export class BestandsbuchArtikelUebersichtComponent
    implements OnInit, OnChanges, OnDestroy {
    bestandsbuch: Bestandsbuch;
    unterschriftVorhanden = false;
    unterschriftBenutzerBase64: string;
    hasNotUnterschriftTrigger = false;
    BestandsbuchStatus = BestandsbuchStatus;

    unterschriftSubscription: Subscription;
    bestandsbuchSubscription: Subscription;

    isLoading = false;
    trotzdemAbschliessenCtrl = new FormControl();

    constructor(
        public dialogRef: MatDialogRef<BestandsbuchArtikelUebersichtComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private bestandsbuchService: BestandsbuchService,
        private messageService: MessageService,
        private unterschriftService: UnterschriftService,
        private dialog: MatDialog,
        private api: KieneBackendApiService,
        public localStorageService: LocalStorageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        this.bestandsbuch = this.data;
    }

    ngOnInit(): void {
        this.ladeBestandsbuch();
        this.ladeUnterschrift();
    }

    ngOnChanges() {
    }

    openArtikel(artikel: BestandsbuchArtikel) {
        const dialogRef = this.dialog.open(
            BestandsbuchArtikelDetailsComponent,
            {
                width: '100%',
                maxWidth: '500px',
                data: {
                    artikel: artikel,
                    typ: this.bestandsbuch.typ
                }
            }
        );
        dialogRef.afterClosed().subscribe({
            next: (result) => {
                this.ladeBestandsbuch();
            },
        });
    }

    setUnterschrift() {
        const input = new UnterschriftDialogInput();
        input.unterschriftBase64 = this.unterschriftBenutzerBase64;
        const dialogRef = this.dialog.open(UnterschriftDialogComponent, {
            minWidth: '100vw',
            data: input
        });
        dialogRef.afterClosed().subscribe({
            next: (unterschriftBase64: string) => {
                if (unterschriftBase64) {
                    const bs = new BenutzerSignatur();
                    bs.benutzer_id = this.localStorageService.getSession()?.benutzer_id;
                    bs.unterschrift_png = unterschriftBase64;
                    this.unterschriftService.saveSignature(bs).subscribe({
                        next: () => {
                            this.ladeUnterschrift();
                        }
                    });
                }
            },
        });
    }

    ladeUnterschrift() {
        this.unterschriftSubscription?.unsubscribe();
        this.unterschriftSubscription = this.unterschriftService
            .getSignatur()
            .subscribe({
                next: (resp: User) => {
                    this.unterschriftVorhanden = (!!resp.unterschrift_png) || (!!resp.unterschrift);
                    this.unterschriftBenutzerBase64 = resp.unterschrift_png;
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fehler beim Laden der Unterschrift!',
                        err
                    );
                },
            });
    }

    bestandsbuchAbschliessen(force: boolean) {
        if (!this.unterschriftVorhanden) {
            this.hasNotUnterschriftTrigger = true;
            return;
        }
        this.isLoading = true;
        this.bestandsbuchService
            .bestandsbuchAbschliessen(this.bestandsbuch, force)
            .subscribe({
                next: (resp) => {
                    this.isLoading = false;
                    this.messageService.infoMessage(
                        'Das Bestandsbuch wurde erfolgreich abgeschlossen.'
                    );
                    this.bestandsbuch = resp;
                    const pdfViewerInput = new PdfViewerInput(
                        'bestandsbuch/read_or_mail_pdf.php',
                        new HttpParams().append(
                            'bestandsbuch_id',
                            this.bestandsbuch.bestandsbuch_id?.toString()
                        ),
                        {
                            betreff: `Bestandsbuch ${this.bestandsbuch.abgabebeleg_nr}`,
                        }
                    );

                    this.dialog.open<PdfViewerDialogComponent, PdfViewerInput>(PdfViewerDialogComponent, {
                        minWidth: '95vw',
                        data: pdfViewerInput,
                    });
                    this.dialogRef.close(true);
                },
                error: (err) => {
                    this.isLoading = false;
                    this.messageService.errorMessage(
                        'Fehler beim Abschliessen des Bestandsbuchs!',
                        err
                    );
                },
            });
    }

    alleProtokollieren() {
        this.isLoading = true;
        this.bestandsbuchService
            .alleArtikelProtokollieren(this.bestandsbuch)
            .subscribe({
                next: (resp) => {
                    this.isLoading = false;
                    this.messageService.infoMessage(
                        'Es wurden erfolgreich alle Artikel protokolliert.'
                    );
                    this.bestandsbuch = resp;
                },
                error: (err) => {
                    this.isLoading = false;
                    this.messageService.errorMessage(
                        'Fehler beim Protokollieren aller Artikel!',
                        err
                    );
                },
            });
    }

    ngOnDestroy(): void {
        this.bestandsbuchSubscription?.unsubscribe();
    }

    deleteBestandsbuch() {
        const typText = this.bestandsbuch.typ === 1 ? 'Bestandsbuch' : 'Impfkontrollbuch';
        const input = new ConfirmDialogInput();
        input.headline = typText + ' löschen?';
        input.text = 'Soll das ' + typText + ' unwiderruflich gelöscht werden?';
        input.okButton = 'Löschen';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.isLoading = true;
                    this.api
                        .deleteGeneric(
                            this.apiBaseUrl + 'bestandsbuch/delete.php',
                            this.bestandsbuch
                        )
                        .subscribe({
                            next: (response) => {
                                this.isLoading = false;
                                this.messageService.infoMessage(
                                    typText + ' wurde erfolgreich gelöscht!'
                                );
                                this.dialogRef.close(false);
                            },
                            error: (err) => {
                                this.isLoading = false;
                                this.messageService.errorMessage(
                                    typText + ' konnte nicht gelöscht werden!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    impfkontrollbuchAbschliessenFrageDialog(force?: boolean) {
        const input = new EnterValueInput();
        input.text = 'Hiermit schliessen Sie das Impfkontrollbuch ab. Bitte geben Sie das Abschlussdatum ein.';
        input.title = 'Impfkontrollbuch abschliessen';
        input.addInputValue('Abschlussdatum', 'date', new Date());
        const dialogRef = this.dialog.open(EnterValueDialogComponent, {
            data: input
        });
        dialogRef.afterClosed().subscribe({
            next: (response: EnterValueResponse) => {
                if (response.result) {
                    const tierarzt_id = this.localStorageService.getCurrentUser().tierarzt_id;
                    this.impfbuchAbschliessen(response.values, tierarzt_id, force);
                }
            }
        });
    }

    private impfbuchAbschliessen(values: Date[], tierarzt_id: number, force?: boolean) {
        this.isLoading = true;
        const obj = {
            datum_kontrolle: new Date(values[0]),
            impfkontrollbuecher: [this.bestandsbuch],
            tierarzt_id: tierarzt_id,
            force: force ? 1 : 0
        };
        this.api.post(this.apiBaseUrl + 'bestandsbuch/abschliessen_impfkontrollbuch.php', obj, undefined).subscribe({
            next: () => {
                this.isLoading = false;
                this.messageService.infoMessage('Das Impfkontrollbuch wurde erfolgreich abgeschlossen!');
                this.dialogRef.close(true);
            },
            error: err => {
                this.isLoading = false;
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private ladeBestandsbuch() {
        this.isLoading = true;
        if (this.bestandsbuch) {
            this.api
                .getByIdAlternative(
                    this.apiBaseUrl + 'bestandsbuch/read_one.php',
                    'bestandsbuch_id',
                    this.bestandsbuch.bestandsbuch_id.toString()
                )
                .subscribe({
                    next: (response) => {
                        this.isLoading = false;
                        this.bestandsbuch = response;
                        this.initTrotzdemAbschliessenCtrl();
                    },
                    error: (err) => {
                        this.isLoading = false;
                        this.messageService.errorMessage('Fehler!', err);
                    },
                });
        }
    }

    private initTrotzdemAbschliessenCtrl() {
        this.trotzdemAbschliessenCtrl.setValue(this.bestandsbuch.trotzdem_abschliessen > 0 ? true : false);
        if (this.localStorageService.isCurrentUserVeterinarian() || this.localStorageService.isCurrentUserVerwaltung() || !this.localStorageService.hasPermission(4427)) {
            this.trotzdemAbschliessenCtrl.disable();
        }
        this.trotzdemAbschliessenCtrl.valueChanges.subscribe({
            next: value => {
                if (value && !this.bestandsbuch.trotzdem_abschliessen) {
                    this.bestandsbuch.trotzdem_abschliessen = this.localStorageService.getSession().benutzer_id;
                    this.bestandsbuchTrotzdemAbschliessen();
                } else if (this.bestandsbuch.trotzdem_abschliessen > 0) {
                    this.bestandsbuch.trotzdem_abschliessen = 0;
                    this.bestandsbuchTrotzdemAbschliessen();
                }

            }
        });
    }

    private bestandsbuchTrotzdemAbschliessen() {
        this.bestandsbuchService.bestandsbuchTrotzdemAbschliessen(this.bestandsbuch).subscribe({
            next: response => {
                this.bestandsbuch = response;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}
