import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TableDescriptor, ToolbarButton} from '../../components/kiene-table/table-descriptor';
import {AbgabebelegService} from './abgabebeleg.service';
import {MessageService} from '../../services/message.service';
import {
    ConditionSetting,
    ConditionTableColumn,
    DateTableColumn,
    EmailGesendetTableColumn,
    FunctionTableColumn,
    GedrucktTableColumn,
    NumberTableColumn,
    TableColumn,
} from '../../components/kiene-table/table-column';
import {Condition, Operator, TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {Abgabebeleg, AbgabebelegPosition, AbgabebelegStatus, AbgabebelegWarenkorb} from './abgabebeleg';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {HttpParams} from '@angular/common/http';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Behandlungsanfrage} from '../behandlungsanfrage/behandlungsanfrage';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {MeldungenGenerierenDialogComponent} from './meldungen-generieren-dialog/meldungen-generieren-dialog.component';
import {
    FilterItem,
    FilterItemType
} from '../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import {ChangeArtikelmengeDialogComponent} from './change-artikelmenge-dialog/change-artikelmenge-dialog.component';
import {
    DowloadAlleDokumenteDialogComponent
} from '../dowload-alle-dokumente-dialog/dowload-alle-dokumente-dialog.component';
import {MeldungService} from '../meldungen/meldung.service';
import {UntypedFormControl} from '@angular/forms';
import {
    ArticleScanAutocompleteComponent
} from '../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import {MatInput} from '@angular/material/input';
import {
    AbgabebelegWarenkorbDialogComponent
} from './abgabebeleg-warenkorb-dialog/abgabebeleg-warenkorb-dialog.component';
import {
    ConfirmDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {Tierbemerkung} from '../../../../projects/kiene-core/src/lib/components/gruppe-tier/classes/tierbemerkung';
import {
    GruppeBemerkungenVerwaltenDialogComponent
} from '../../gruppen/gruppe-bemerkungen-verwalten-dialog/gruppe-bemerkungen-verwalten-dialog.component';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {Bestandsbuch} from '../bestandsbuch/bestandsbuch/classes/bestandsbuch';
import {
    EnterValueDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-dialog.component';
import {
    EnterValueInput,
    EnterValueResponse
} from '../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-response';
import {Client} from '../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {Bestandsbesuchprotokoll} from '../bestandsbesuchsprotokoll/bestandsbesuchsprotokoll';
import {BelegeVorlageDialogComponent} from './belege-vorlage-dialog/belege-vorlage-dialog.component';
import {HotKeyListDialogComponent} from "./hot-key-list-dialog/hot-key-list-dialog.component";
import {
    ChangeCurrentClientService
} from "../../administration/client/change-current-client/change-current-client.service";

@Component({
    selector: 'kiene-abgabebeleg',
    templateUrl: './abgabebeleg.component.html',
    styleUrls: ['./abgabebeleg.component.scss'],
})
export class AbgabebelegComponent implements OnInit, OnDestroy {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    watchClientSubscription: Subscription;
    currentClient: Client;

    offeneImpfbuecher: Bestandsbuch[] = [];
    reloadSubscription: Subscription;

    @ViewChild('mengeInput') mengeInput: MatInput;
    @ViewChild('articleScan') articleScan: ArticleScanAutocompleteComponent;

    mengeCtrl = new UntypedFormControl();
    warenkorbEintraege: AbgabebelegWarenkorb[] = [];
    gruppeTierBemerkungen: Tierbemerkung[] = [];

    constructor(
        public dialog: MatDialog,
        private api: AbgabebelegService,
        private messageService: MessageService,
        private pdfService: PdfViewerDesktopService,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private router: Router,
        private meldungService: MeldungService,
        private abgabebelegService: AbgabebelegService,
        private route: ActivatedRoute, private currentClientService: ChangeCurrentClientService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        this.currentClient = this.localStorageService.getCurrentClient();
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Abgabebelege';
        this.tableDescriptor.uniqueIdentifier = 'Abgabebelege';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'abgabebeleg/';
        this.tableDescriptor.route = '/belege/abgabebelege/';
        this.tableDescriptor.nameOfIdField = 'abgabebeleg_id';
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.searchPlaceholder = 'Abgabebelege durchsuchen';

        this.tableDescriptor.headlineColumnTitle = 'Beleg Nr.';
        this.tableDescriptor.headlineColumnName = 'abgabebeleg_nr';

        this.tableDescriptor.columns.push(
            new TableColumn('abgabebeleg_id', 'ID')
        );

        this.tableDescriptor.columns.push(
            new TableColumn('abgabebeleg_nr', 'Beleg Nr.')
        );
        this.tableDescriptor.columns.push(new TableColumn('kunde', 'Kunde'));
        this.tableDescriptor.columns.push(
            new TableColumn('zusatz', 'Kundenzusatz')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('gruppen', 'Gruppe(n)')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('tierarzt', 'Tierarzt')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('status', 'Status', null, null, null, null, [
                'status_sortierung',
            ])
        );
        this.tableDescriptor.columns.push(
            new TableColumn(
                'status_meldung',
                'Status Meldung',
                null,
                null,
                'meldung_fehler'
            )
        );
        this.tableDescriptor.columns.push(
            new DateTableColumn('datum', 'Belegdatum', 'dd.MM.yy')
        );
        this.tableDescriptor.columns.push(new TableColumn('pdf_id', '', 'pdf'));
        this.tableDescriptor.columns.push(new EmailGesendetTableColumn());
        this.tableDescriptor.columns.push(new GedrucktTableColumn());
        this.tableDescriptor.columns.push(
            new ConditionTableColumn(
                'rechnung_ids', 'Rechn.',
                [
                    new ConditionSetting(
                        [new Condition('rechnung_ids', Operator.EXISTIERT, null)],
                        {icon: 'attach_money'}
                    ),
                ],
                {
                    sortFields: ['rechnung_ids'],
                }
            )
        );

        this.tableDescriptor.params = new HttpParams();

        this.tableDescriptor.disableConditions.push(
            new Condition('status_id', Operator.GLEICH, AbgabebelegStatus.STORNIERT)
        );

        this.tableDescriptor.permissionCreate = 1233;
        this.tableDescriptor.permissionUpdate = 1233;
        this.tableDescriptor.permissionView = 1231;
        this.tableDescriptor.permissionDelete = 1234;

        this.tableDescriptor.options.push(
            new TableOption(
                'transform',
                'in Behandlungsanfrage umwandeln',
                'swap_horiz',
                1166
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                undefined,
                'Bestandsbesuchsprotokoll erzeugen',
                'swap_horiz',
                3853,
                {void: this.bestandsbesuchsprotokollErzeugen.bind(this)}
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'openInvoice',
                'Rechnung PDF ansehen',
                'attach_money',
                1239
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'gotoInvoice',
                'zu Rechnung springen',
                'attach_money',
                2971, {
                    conditions:
                        [new Condition('rechnung_ids', Operator.EXISTIERT, '')]
                }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'showAbgabebeleg',
                'Abgabebeleg anzeigen',
                'description',
                1238, {
                    conditions:
                        [new Condition('pdf_id', Operator.EXISTIERT, '')]
                }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'showImpfkontrollbuch',
                'Impfkontrollbuch anzeigen',
                'local_hospital',
                1249, {
                    conditions:
                        [new Condition('pdf_id_impfkontrollbuch', Operator.EXISTIERT, '')]
                }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'showBestandsbuch',
                'Bestandsbuch anzeigen',
                'book',
                1248, {
                    conditions:
                        [new Condition('pdf_id_bestandsbuch', Operator.EXISTIERT, '')]
                }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption('delete', 'löschen', 'delete', 1234, {
                conditions: [
                    new Condition('status_id', Operator.GLEICH, AbgabebelegStatus.ERSTELLT),
                ]
            })
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'storno',
                'stornieren',
                'cancel_presentation',
                1246, {
                    conditions:
                        [
                            new Condition('status_id', Operator.IN, [
                                AbgabebelegStatus.ABGESCHLOSSEN,
                                AbgabebelegStatus.WIRD_KORRIGIERT,
                            ]),
                        ]
                }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'neuverschreibung',
                'Neuverschreibung',
                'unsubscribe',
                1230, {
                    conditions:
                        [
                            new Condition('status_id', Operator.IN, [
                                AbgabebelegStatus.ABGESCHLOSSEN,
                            ]),
                        ]
                }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption('duplicate', 'duplizieren', 'file_copy', 1235)
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'createMeldung',
                'HIT/QS Meldungen (neu) generieren',
                'mediation',
                1242, {
                    conditions:
                        [new Condition('status_id_meldung', Operator.IN, [120, 125])]
                }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'gotoMeldungen',
                'Meldungen HIT/QS anzeigen',
                'grading',
                4111
            )
        );

        // belege wieder oeffnen wenn sie im status abgeschlossen 42 oder neuverschrieben 49 sind
        this.tableDescriptor.options.push(
            new TableOption(
                'reopen',
                'Beleg wieder öffnen',
                'lock_open',
                1245, {
                    conditions:
                        [
                            new Condition('status_id', Operator.IN, [
                                AbgabebelegStatus.ABGESCHLOSSEN,
                                AbgabebelegStatus.NEUVERSCHREIBUNG,
                            ]),
                        ]
                }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'showKunde',
                'Zum Kunden springen',
                'agriculture',
                1711
            )
        );
        if (this.localStorageService.isCurrentUserPhitoUser()) {
            this.tableDescriptor.options.push(
                new TableOption(
                    'recreate_pdfs',
                    'PDF neu erzeugen',
                    'autorenew',
                    1259
                )
            );
        }
        this.tableDescriptor.options.push(new TableOption(null, 'Drucken', 'print', 1257, {
            void: this.gedruckt.bind(this),
            conditions: [new Condition('pdf_id', Operator.EXISTIERT, null)]
        }));

        this.tableDescriptor.toolbarButtons.push(
            new ToolbarButton(
                'hitqs',
                'mediation',
                1242,
                'QS/HIT Meldungen (neu) generieren'
            )
        );
        this.tableDescriptor.toolbarButtons.push(
            new ToolbarButton(
                'downloadAll',
                'download_for_offline',
                1260,
                'Alle Dokumente herunterladen'
            )
        );

        const session = this.localStorageService.getSession();
        if (!session?.tierarzt_id || session?.admin > 0) {
            this.tableDescriptor.filterItems.push(
                new FilterItem(
                    'Belegstatus: erstellt',
                    AbgabebelegStatus.ERSTELLT,
                    FilterItemType.CHECKBOX,
                    'status_id',
                    true
                )
            );
            this.tableDescriptor.filterItems.push(
                new FilterItem(
                    'Belegstatus: abgeschlossen',
                    AbgabebelegStatus.ABGESCHLOSSEN,
                    FilterItemType.CHECKBOX,
                    'status_id',
                    true
                )
            );
            this.tableDescriptor.filterItems.push(
                new FilterItem(
                    'Belegstatus: wird korrigiert',
                    AbgabebelegStatus.WIRD_KORRIGIERT,
                    FilterItemType.CHECKBOX,
                    'status_id',
                    true
                )
            );
            this.tableDescriptor.filterItems.push(
                new FilterItem(
                    'Belegstatus: Neuverschreibung',
                    AbgabebelegStatus.NEUVERSCHREIBUNG,
                    FilterItemType.CHECKBOX,
                    'status_id',
                    true
                )
            );
        }

        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Belegstatus: storniert',
                AbgabebelegStatus.STORNIERT,
                FilterItemType.CHECKBOX,
                'status_id',
                true,
                {permissionId: 1250}
            )
        );

        if (this.localStorageService.isCurrentUserVerwaltung() || this.localStorageService.currentUserIsAdmin()) {
            this.tableDescriptor.filterItems.push(
                new FilterItem(
                    'Meldungsstatus: nicht generiert',
                    120,
                    FilterItemType.CHECKBOX,
                    'status_id_meldung',
                    true
                )
            );
            this.tableDescriptor.filterItems.push(
                new FilterItem(
                    'Meldungsstatus: gemeldet',
                    124,
                    FilterItemType.CHECKBOX,
                    'status_id_meldung',
                    true
                )
            );
            this.tableDescriptor.filterItems.push(
                new FilterItem(
                    'Meldungsstatus: Belege/Stammdaten fehlerhaft',
                    125,
                    FilterItemType.CHECKBOX,
                    'status_id_meldung',
                    true
                )
            );
            this.tableDescriptor.filterItems.push(
                new FilterItem(
                    'Meldungsstatus: Übertragung fehlgeschlagen',
                    127,
                    FilterItemType.CHECKBOX,
                    'status_id_meldung',
                    true
                )
            );
            this.tableDescriptor.filterItems.push(
                new FilterItem(
                    'Meldungsstatus: nicht notwendig',
                    [121, 122, 123, 126, 129],
                    FilterItemType.CHECKBOX,
                    'status_id_meldung',
                    true
                )
            );
        }

        this.tableDescriptor.filterItems.push(
            new FilterItem(
                'Beleg nicht abgerechnet',
                0,
                FilterItemType.CHECKBOX,
                'rechnung',
                true,
                {adminOnly: true}
            )
        );

        this.tableDescriptor.initialSortColumn = 'status_sortierung';
        this.tableDescriptor.initialSortDirection = 'asc';

        this.tableDescriptor.expandable = true;
        this.tableDescriptor.expandableFieldName = 'positionen';
        this.tableDescriptor.openFirstRows = 5;
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('artikel_me', 'Artikel')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('tierart', 'Tierart')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('anwendung', 'Anwendung')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('diagnose', 'Diagnose')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('artikelmenge', 'Menge')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('anzahl_tiere', 'Anz. Tiere')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('dosierung', 'Dosierung')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('anwendungsdauer', 'Anwendungsdauer')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('tiergewicht', 'Tiergewicht')
        );
        this.tableDescriptor.expandableColumns.push(
            new FunctionTableColumn(
                'identifikation',
                'Identifikation',
                this.formatIdentifikation
            )
        );

        this.tableDescriptor.expandableColumns.push(
            new TableColumn('wartezeit', 'Wartezeit Fl.')
        );
        if (this.hasPermission(1229)) {
            this.tableDescriptor.expandableColumns.push(
                new NumberTableColumn('einzelpreis', 'Einzelpreis (€)')
            );
        }

        this.watchClientSubscription = this.localStorageService
            .watchCurrentClient()
            .subscribe((client) => {
                this.currentClient = client;
                this.tableService?.reload();
                this.loadAbgabebelegWarenkorb();
                this.loadGruppeTierBemerkungen();
                this.ladeOffeneImpfbuecher();
            });

        this.loadAbgabebelegWarenkorb();
        this.ladeOffeneImpfbuecher();
        this.loadGruppeTierBemerkungen();

        // this.reloadSubscription?.unsubscribe();
        // this.reloadSubscription = this.tableService.watchReload().subscribe({
        //     next: reload => {
        //         if (reload) {
        //             this.loadAbgabebelegWarenkorb();
        //             this.ladeOffeneImpfbuecher();
        //             this.loadGruppeTierBemerkungen();
        //         }
        //     }
        // });
    }

    gedruckt(abg: Abgabebeleg) {
        this.tableService.get(this.apiBaseUrl + 'abgabebeleg/gedruckt.php', new HttpParams().set('abgabebeleg_id', abg.abgabebeleg_id)).subscribe({
            next: () => {
                this.tableService.reload('gedruckt');
                this.showPdf(abg);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    hasPermission(permissionId: number): boolean {
        return this.localStorageService.hasPermission(permissionId);
    }

    bestandsbesuchsprotokollErzeugen(abgabebeleg: Abgabebeleg) {
        this.api.bestandsbesuchsprotokollErzeugen(abgabebeleg).subscribe({
            next: (bbp: Bestandsbesuchprotokoll) => {
                this.messageService.infoMessage('Bestandsbesuchsprotokoll wurde erfolgreich erzeugt!');
                this.router.navigate(['belege', 'bestandsbesuch', bbp.bbp_id]).then();
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Erzeugen!', err);
            }
        });
    }

    showPdf(abgabebeleg: Abgabebeleg) {
        this.abgabebelegService.openAbgabebelegGesamtPdf(
            abgabebeleg,
            abgabebeleg?.kunde_id
        ).subscribe({
            next: () => {
                this.tableService.reload();
            }
        });
    }

    catchOptionEvent(event: TableOptionEvent) {
        const abgabebeleg: Abgabebeleg = event?.value;
        if (event.name === 'add') {
            this.erzeugeNeuenAbgabebeleg()
        }
        if (event.name === 'transform') {
            this.transformToBehandlungsanfrage(event?.value);
        } else if (event.name === 'showPdf') {
            this.showPdf(abgabebeleg);
        } else if (event.name === 'openInvoice') {
            const input = new PdfViewerDialogInput(
                'abgabebeleg/read_rechnungen.php',
                new HttpParams().set(
                    'abgabebeleg_id',
                    abgabebeleg?.abgabebeleg_id?.toString()
                ),
                'Rechnung Abgabebeleg'
            );
            this.pdfService.openPdfViewer(input);
        } else if (event.name === 'gotoInvoice') {
            this.router
                .navigate(['/finanzen', 'rechnungen', abgabebeleg.rechnung_ids])
                .then();
        } else if (event.name === 'showAbgabebeleg') {
            this.abgabebelegService.openAbgabebelegPdf(
                abgabebeleg,
                abgabebeleg?.kunde_id
            );
        } else if (event.name === 'showImpfkontrollbuch') {
            this.abgabebelegService.openImpfkontrollbuchPdf(
                abgabebeleg,
                abgabebeleg?.kunde_id
            );
        } else if (event.name === 'showBestandsbuch') {
            this.abgabebelegService.openBestandsbuchPdf(
                abgabebeleg,
                abgabebeleg?.kunde_id
            );
        } else if (event.name === 'edit') {
            this.router
                .navigate([
                    this.tableDescriptor.route,
                    event?.value[this.tableDescriptor.nameOfIdField],
                ])
                .then();
        } else if (event.name === 'duplicate') {
            this.duplicate(event?.value);
        } else if (event.name === 'hitqs') {
            this.openMeldungenGenerierenDialog();
        } else if (event.name === 'downloadAll') {
            this.openDownloadAllDocumentsDialog();
        } else if (event.name === 'gotoMeldungen') {
            this.gotoMeldungen(<Abgabebeleg>event?.value);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event?.value);
        } else if (event.name === 'reopen') {
            this.unlock(event?.value);
        } else if (event.name === 'storno') {
            this.openStornoDialog(event?.value);
        } else if (event.name === 'neuverschreibung') {
            this.openNeuverschreibungDialog(event?.value);
        } else if (event.name === 'createMeldung') {
            this.meldungenGenerieren(event?.value?.abgabebeleg_id);
        } else if (event.name === 'showKunde') {
            this.router
                .navigate(['/stammdaten', 'betriebe', abgabebeleg?.betrieb_id], {queryParams: {kunde_id: abgabebeleg?.kunde_id}})
                .then();
        } else if (event.name === 'artikelmenge') {
            this.openChangeArtikelmengeDialog(event?.value);
        } else if (event.name === 'recreate_pdfs') {
            this.pdfsNeuErzeugen(abgabebeleg);
        }
    }

    pdfsNeuErzeugen(a: Abgabebeleg) {
        const params = new HttpParams().set(
            'abgabebeleg_ids',
            a.abgabebeleg_id.toString()
        );
        this.tableService?.get(this.apiBaseUrl + 'abgabebeleg/update_pdfs.php', params)
            .subscribe({
                next: (response) => {
                    this.messageService.infoMessage('Neu erzeugt!');
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }

    formatIdentifikation(value: string): string {
        let txt = value;
        if (txt) {
            if (typeof txt === 'string') {
                txt = txt?.replaceAll(',', ', ');
            }
        }

        return txt;
    }

    openNeuverschreibungDialog(abgabebeleg: Abgabebeleg) {
        const input = new ConfirmDialogInput();
        input.headline = 'Neuverschreibung';
        input.text = 'Abgabebeleg als Neuverschreibung kennzeichnen?';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.neuverschreibung(abgabebeleg);
                }
            },
        });
    }

    openStornoDialog(abgabebeleg: Abgabebeleg) {
        const input = new ConfirmDialogInput();
        input.headline = 'Abgabebeleg stornieren';
        input.text = 'Wollen Sie diesen Abgabebeleg stornieren?';
        input.okButton = 'Stornieren';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.storniereAbgabebeleg(abgabebeleg);
                }
            },
        });
    }

    gotoMeldungen(ab: Abgabebeleg) {
        this.router
            .navigate(['belege', 'meldungen'], {
                queryParams: {
                    abgabebeleg_id: ab.abgabebeleg_id,
                    clearsearch: true,
                },
            })
            .then();
    }

    openMeldungenGenerierenDialog() {
        const dialogRef = this.dialog.open(MeldungenGenerierenDialogComponent);
        dialogRef.afterClosed().subscribe(() => {
            this.tableService?.reload();
        });
    }

    meldungenGenerieren(abgabebelegId?: number) {
        this.api.generiereMeldungen(abgabebelegId).subscribe(
            () => {
                this.meldungService.jetztAlleMeldungenSenden().subscribe(
                    () => {
                        this.messageService.infoMessage(
                            'Meldungen wurden erfolgreich generiert und werden jetzt nacheinander an HIT/QS versendet. Dies kann einige Minuten dauern...'
                        );
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Meldungen wurden erfolgreich generiert aber konnten nicht verschickt werden!',
                            error
                        );
                    }
                );

                this.tableService?.reload();
            },
            (error) => {
                this.messageService.errorMessage(
                    'Fehler beim Generieren aufgetreten!',
                    error
                );
            }
        );
    }

    neuverschreibung(ab: Abgabebeleg) {
        this.tableService?.post(
            this.apiBaseUrl + 'abgabebeleg/neuverschreiben.php',
            ab,
            null
        )
            .subscribe({
                next: () => {
                    this.messageService.infoMessage(
                        'Der Abgabebeleg wurde erfolgreich neu verschrieben!'
                    );
                    this.tableService?.reload();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fehler! Der Abgabebeleg konnte nicht neu verschrieben werden!',
                        err
                    );
                    this.tableService?.reload();
                },
            });
    }

    storniereAbgabebeleg(ab: Abgabebeleg) {
        this.tableService?.post(this.apiBaseUrl + 'abgabebeleg/stornieren.php', ab, null)
            .subscribe({
                next: () => {
                    this.messageService.infoMessage(
                        'Der Abgabebeleg wurde erfolgreich storniert!'
                    );
                    this.tableService?.reload();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fehler! Der Abgabebeleg konnte nicht storniert werden!',
                        err
                    );
                    this.tableService?.reload();
                },
            });
    }

    unlock(ab: Abgabebeleg) {
        this.tableService
            .post(
                this.apiBaseUrl + 'abgabebeleg/korrigieren.php',
                ab,
                null
            )
            .subscribe({
                next: () => {
                    this.messageService.infoMessage(
                        'Der Abgabebeleg wurde wieder geöffnet!'
                    );
                    this.tableService?.reload();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fehler! Der Abgabebeleg konnte nicht geöffnet werden!',
                        err
                    );
                    this.tableService?.reload();
                },
            });
    }

    duplicate(abgabebeleg: Abgabebeleg) {
        const input = new ConfirmDialogInput();
        input.headline = 'Abgabebeleg duplizieren';
        input.text = 'Soll der Beleg wirklich dupliziert werden?';
        input.okButton = 'Duplizieren';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (optionValue: ConfirmDialogResult) => {
                if (optionValue?.confirmed) {
                    this.tableService
                        .duplicateEntity(
                            this.apiBaseUrl + 'abgabebeleg/duplicate.php',
                            abgabebeleg
                        )
                        .subscribe({
                            next: (response) => {
                                this.messageService.infoMessage(
                                    'Der Abgabebeleg wurde erfolgreich dupliziert!'
                                );
                                const abg = <Abgabebeleg>response;
                                this.router
                                    .navigate([
                                        'belege',
                                        'abgabebelege',
                                        abg.abgabebeleg_id,
                                    ])
                                    .then();
                            },
                            error: (error) => {
                                this.messageService.errorMessage(
                                    'Der Abgabebeleg konnte nicht dupliziert werden!',
                                    error
                                );
                            },
                        });
                }
            },
        });
    }

    transformToBehandlungsanfrage(abgabebeleg: Abgabebeleg) {
        this.api.transformToBehandlungsanfrage(abgabebeleg).subscribe(
            (response) => {
                this.messageService.infoMessage(
                    'Der Abgabebeleg wurde erfolgreich in eine Behandlungsanfrage gewandelt!'
                );
                const beh = <Behandlungsanfrage>response;
                this.router
                    .navigate([
                        'belege',
                        'behandlungsanfragen',
                        beh.bestellung_id,
                    ])
                    .then();
            },
            (error) => {
                this.messageService.errorMessage(
                    'Der Abgabebeleg konnte nicht in eine Behandlungsanfrage umgewandelt werden!',
                    error
                );
            }
        );
    }

    ngOnDestroy() {
        this.watchClientSubscription?.unsubscribe();
        // this.reloadSubscription?.unsubscribe();
        this.tableService = null;
    }

    openDeleteDialog(abgabe: Abgabebeleg) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Abgabebeleg wirklich löschen?',
                text: 'Abgabebeleg wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.tableService
                    ?.deleteEntity(this.apiBaseUrl + 'abgabebeleg/delete.php', abgabe)
                    .subscribe(
                        () => {
                            this.messageService.infoMessage(
                                'Der Abgabebeleg wurde erfolgreich gelöscht!'
                            );
                            this.tableService?.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Der Abgabebeleg konnte nicht gelöscht werden! ',
                                error
                            );
                        }
                    );
            }
        });
    }

    openChangeArtikelmengeDialog(ap: AbgabebelegPosition) {
        const dialogRef = this.dialog.open(ChangeArtikelmengeDialogComponent, {
            data: {
                abgabebelegPosition: ap,
            },
        });

        dialogRef.afterClosed().subscribe(() => {
            this.tableService?.reload();
        });
    }

    openDownloadAllDocumentsDialog() {
        if (!this.currentClient) {
            this.messageService.hintMessage(
                'Bitte erst einen Kunden auswählen!'
            );
            return;
        }
        this.dialog.open(DowloadAlleDokumenteDialogComponent);
    }

    openWarenkorb() {
        const dialogRef = this.dialog.open(AbgabebelegWarenkorbDialogComponent);
        dialogRef.afterClosed().subscribe({
            next: (abgabebeleg: Abgabebeleg) => {
                if (abgabebeleg) {
                    this.router.navigate(['/belege/abgabebelege/', abgabebeleg.abgabebeleg_id]);
                } else {
                    this.loadAbgabebelegWarenkorb();
                }
            },
        });
    }

    openGruppeTierBemerkungen() {
        const dialogRef = this.dialog.open(
            GruppeBemerkungenVerwaltenDialogComponent,
            {
                data: this.currentClient,
            }
        );
        dialogRef
            .afterClosed()
            .subscribe(() => this.loadGruppeTierBemerkungen());
    }

    loadGruppeTierBemerkungen() {
        if (this.hasPermission(9191)) {
            if (this.currentClient) {
                let params = new HttpParams().set('tierarzt', '0');
                if (this.currentClient.unternehmensgruppe_id) {
                    params = params.set(
                        'unternehmensgruppe_id',
                        this.currentClient.unternehmensgruppe_id.toString()
                    );
                } else {
                    params = params.set(
                        'kunde_id',
                        this.currentClient.kunde_id?.toString()
                    );
                }
                this.tableService
                    .get(
                        this.apiBaseUrl +
                        'gruppe_tier_bemerkung/read_kundenportal.php',
                        params
                    )
                    .subscribe({
                        next: (response) => {
                            this.gruppeTierBemerkungen = response.records;
                        },
                    });
            }
        }
    }

    loadAbgabebelegWarenkorb() {
        // 4481: Recht Belege - Warenkorb - anzeigen
        if (this.hasPermission(4481)) {
            if (this.currentClient) {
                let params = new HttpParams();
                if (this.currentClient.unternehmensgruppe_id) {
                    params = params.set(
                        'unternehmensgruppe_id',
                        this.currentClient.unternehmensgruppe_id.toString()
                    );
                } else {
                    params = params.set(
                        'kunde_id',
                        this.currentClient.kunde_id?.toString()
                    );
                }
                this.tableService?.get(
                    this.apiBaseUrl + 'abgabebeleg_warenkorb/read.php',
                    params
                )
                    .subscribe({
                        next: (response) => {
                            this.warenkorbEintraege = response.records;
                        },
                    });
            }
        }
    }

    impfbuecherAbschliessenDialog() {
        if (this.offeneImpfbuecher?.length > 0) {
            const input = new EnterValueInput();
            input.text = 'Es sind ' + this.offeneImpfbuecher.length + ' Impfkontrollbücher bereit zum Abschließen. Wollen Sie diese jetzt abschließen?';
            input.title = 'Impfkontrollbücher abschließen';
            input.okButton = 'Abschließen';
            input.addInputValue('Datum des Abschluss', 'date', new Date());
            const dialogRef = this.dialog.open(EnterValueDialogComponent, {
                data: input
            });
            dialogRef.afterClosed().subscribe({
                next: (response: EnterValueResponse) => {
                    if (response?.result) {
                        const datum = <Date>response.getValue(0);
                        this.abgabebelegService.offeneImpfbuecherAbschliessen(datum, this.offeneImpfbuecher, this.localStorageService.getCurrentUser()?.tierarzt_id, false).subscribe({
                            next: (erfolgreich: boolean) => {
                                if (erfolgreich) {
                                    this.ladeOffeneImpfbuecher();
                                }
                            }
                        });
                    }
                }
            });
        } else {
            this.messageService.alertMessage('Keine offenen Impfkontrollbücher gefunden!');
        }
    }

    neueBelegvorlageErstellen() {
        const dialogRef = this.dialog.open(BelegeVorlageDialogComponent);

        dialogRef.afterClosed().subscribe({
            next: result => {

            }
        });
    }

    openHotKeyDialog() {
        this.dialog.open(HotKeyListDialogComponent, {
            data: {}, minWidth: '400px'
        });
        /* dialogref.afterClosed().subscribe({
             next: ((result: any) => {
                 if (result) {
                     console.log(result);
                 }
             })
         });*/
    }

    private ladeOffeneImpfbuecher() {
        if (!this.currentClient) {
            return;
        }
        this.abgabebelegService.getOffeneImpfkontrollbuecher(this.currentClient.kunde_id, undefined).subscribe({
            next: response => {
                this.offeneImpfbuecher = response.records;
            },
            error: err => {
                this.messageService.errorMessage('Impfkontrollbuecher konnten nicht geladen werden!', err);
            }
        });
    }

    private erzeugeNeuenAbgabebeleg() {
        if (this.localStorageService.getCurrentClient()) {
            this.router.navigate(['neu'], {relativeTo: this.route});
        } else {
            this.currentClientService.openChangeDialog('erzeugeNeuenAbgabebeleg').subscribe({
                next: ((res) => {
                    if (res) {
                        this.router.navigate(['neu'], {relativeTo: this.route});
                    }
                })
            });
        }
    }
}
