<div style="padding: 0 24px 64px 24px">
    <kiene-page-header [ueberschrift]="'HIT-PIN aktualisieren'"></kiene-page-header>

    <mat-hint style="font-size: 0.8em; text-align: justify; display: inline-block">
        <strong>Hinweis:</strong> Die Änderung der PIN dient zur Nacherfassung
        in VetVet, nachdem die PIN im HIT-Portal geändert wurde. Das Ändern der
        PIN hat keine Auswirkungen auf die PIN im HIT-Portal.
        <br />
        <br />
        Die Nacherfassung ist notwendig, um über VetVet mit der neuen PIN
        Meldungen an das HIT-Portal zu senden.
    </mat-hint>

    <div style="padding-top: 24px">
        <mat-form-field style="width: 100%">
            <mat-label>PIN</mat-label>
            <input matInput [type]="showPasswort ? 'text' : 'password'" [formControl]="neuePinCtrl" placeholder="PIN" />
            <button mat-icon-button matSuffix (click)="showPasswort = !showPasswort">
                <mat-icon>{{
                    showPasswort ? "visibility" : "visibility_off"
                    }}</mat-icon>
            </button>
            <mat-error *ngIf="neuePinCtrl.hasError('required')">Es muss eine PIN eingegeben werden.</mat-error>
            <mat-error *ngIf="neuePinWdhCtrl.hasError('not-matching')"></mat-error>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-label>PIN wiederholen</mat-label>
            <input matInput [type]="showPasswort ? 'text' : 'password'" [formControl]="neuePinWdhCtrl"
                placeholder="PIN" />
            <button mat-icon-button matSuffix (click)="showPasswort = !showPasswort">
                <mat-icon>{{
                    showPasswort ? "visibility" : "visibility_off"
                    }}</mat-icon>
            </button>
            <mat-error *ngIf="neuePinWdhCtrl.hasError('required')">Es muss eine PIN eingegeben werden.</mat-error>
            <mat-error *ngIf="neuePinWdhCtrl.hasError('not-matching')">Die eingebenen PINs stimmen nicht
                überein.</mat-error>
        </mat-form-field>
    </div>

    <div style="
            padding-top: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
        ">
        <button style="width: 80%" mat-flat-button color="accent" (click)="pinAendern()" [disabled]="isSaving">
            PIN ändern
        </button>
    </div>
</div>