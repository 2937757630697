import { Inject, Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";
import { KieneBackendApiService } from "../../../services/backend-api/kiene-backend-api.service";
import { Gruppe } from "../classes/gruppe";
import { Subject } from "rxjs";
import { GruppeTier } from "../../gruppe-tier/classes/gruppe-tier";
import { Haendler } from "../../stammdaten/haendler/haendler";
import { ScanErgebnis } from "../classes/scan-ergebnis";
import { API_BASE_URL_SERVICE } from "../../../kiene-core.config";

@Injectable({
    providedIn: "root",
})
export class EinstallenService {
    currentGruppeSubject = new Subject<Gruppe>();
    currentGruppe: Gruppe;

    eingestallteTiereSubject = new Subject<void>();

    currentAbteilSubject = new Subject<string>();
    currentAbteil: string;

    currentHaendlerSubject = new Subject<Haendler>();

    scanErgebnisSubject = new Subject<ScanErgebnis>();

    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) { }

    loadGruppe(gruppe_id: number) {
        const params = new HttpParams().set("gruppe_id", gruppe_id.toString());
        return this.api.get(
            this.apiBaseUrl + "gruppe/read_one_kundenportal.php",
            params
        );
    }

    setCurrentGruppe(gruppe: Gruppe) {
        this.currentGruppe = gruppe;
        this.currentGruppeSubject.next(this.currentGruppe);
    }

    getCurrentGruppe(): Gruppe {
        return this.currentGruppe;
    }

    watchCurrentGruppe() {
        return this.currentGruppeSubject.asObservable();
    }

    ladeEingestallteTiere() {
        this.eingestallteTiereSubject.next();
    }

    watchEingestallteTiere() {
        return this.eingestallteTiereSubject.asObservable();
    }

    setCurrentAbteil(currentAbteil: string) {
        this.currentAbteil = currentAbteil;
        this.currentAbteilSubject.next(this.currentAbteil);
    }

    watchCurrentAbteil() {
        return this.currentAbteilSubject.asObservable();
    }

    setCurrentHaendler(haendler: Haendler) {
        this.currentHaendlerSubject.next(haendler);
    }

    watchCurrentHaendler() {
        return this.currentHaendlerSubject.asObservable();
    }

    setScanErgebnisSubject(scanErgebnis: ScanErgebnis) {
        this.scanErgebnisSubject.next(scanErgebnis);
    }

    watchScanErgebnisSubject() {
        return this.scanErgebnisSubject.asObservable();
    }
}
