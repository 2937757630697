import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StammdatenService} from '../../stammdaten.service';
import {MessageService} from '../../../../services/message-service/message.service';
import {Client} from '../../../client/classes/client';
import {Subscription} from 'rxjs';
import {LocalStorageService} from '../../../../services/local-storage/local-storage.service';
import {KieneSession} from '../../../../services/local-storage/kiene-session';
import {AppService} from '../../../../services/app/app.service';
import {MatDialog} from '@angular/material/dialog';
import {VvvoHinzufuegenHitDialogComponent} from '../vvvo-hinzufuegen-hit-dialog/vvvo-hinzufuegen-hit-dialog.component';
import {HitLoginResponse} from '../../../../hit/hit-registrierung/classes/hit-login-response';
import {VVVODetailsDialogComponent} from '../vvvodetails-dialog/vvvodetails-dialog.component';

@Component({
    selector: 'kiene-vvvo-uebersicht',
    templateUrl: './vvvo-uebersicht.component.html',
    styleUrls: ['./vvvo-uebersicht.component.scss']
})
export class VvvoUebersichtComponent implements OnInit, OnDestroy {

    @Input('showReturnButton') public showReturnButton = true;

    vvvoSubscription: Subscription;

    vvvos: Client[] = [];

    kieneSession: KieneSession;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private stammdatenService: StammdatenService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private appService: AppService,
        private dialog: MatDialog,
    ) {
    }

    ngOnInit(): void {
        this.kieneSession = this.localStorageService.getCurrentUser();
        this.ladeVvvos();
    }

    ngOnDestroy(): void {
        this.vvvoSubscription?.unsubscribe();
    }

    ladeVvvos(): void {
        this.vvvoSubscription?.unsubscribe();
        this.vvvoSubscription = this.stammdatenService.ladeVvvos().subscribe({
            next: vvvos => this.vvvos = vvvos.records,
            error: err => this.messageService.errorMessage('Fehler beim Laden der Betriebe!', err)
        });
    }

    vvvoAnlegen() {
        if (this.appService.isMobileScreen) {
            this.router.navigate(['hit'], {relativeTo: this.route}).then();
        } else {
            const dialogRef = this.dialog.open(VvvoHinzufuegenHitDialogComponent, {
                minWidth: '600px'
            });
            dialogRef.afterClosed().subscribe({
                next: (response: HitLoginResponse) => {
                    if (response) {
                        const client = HitLoginResponse.generateClient(response);
                        this.stammdatenService.saveKunde(client).subscribe({
                            next: response => {
                                this.ladeVvvos();
                                this.messageService.infoMessage('Betrieb erfolgreich hinzugefügt!');
                            }, error: err => {
                                this.messageService.errorMessage('Fehler!', err);
                            }
                        });
                    }
                }
            });
        }
    }

    editiereVvvo(vvvo: Client) {
        if (this.localStorageService.hasPermission(9102)) {
            if (this.appService.isMobileScreen) {
                console.log('router navigate 1');
                this.router.navigate(['details', vvvo.kunde_id], {relativeTo: this.route}).then();
            } else {
                console.log('hallo dialog')
                this.dialog.open(VVVODetailsDialogComponent, {data: vvvo}).afterClosed().subscribe({
                    next: () => this.ladeVvvos()
                })
            }
        }
    }

    vvvoEntfernen(client: Client) {
        this.stammdatenService.removeVVVO(client).subscribe({
            next: () => {
                this.ladeVvvos();
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler!', err);
            },
        });

    }

    sendMail(c: Client) {
        if (c.email) {
            const htmlAnchorElement = document.createElement('a');
            htmlAnchorElement.style.display = 'none';
            htmlAnchorElement.href = 'mailto:' + c.email;
            document.body.appendChild(htmlAnchorElement);
            htmlAnchorElement.click();
            // document.body.removeChild(htmlAnchorElement);
        }
    }
}
