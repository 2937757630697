import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    AfterViewInit,
} from "@angular/core";


@Component({
    selector: "kiene-bestellung-extern",
    templateUrl: "./bestellung-extern.component.html",
    styleUrls: ["./bestellung-extern.component.scss"],
})
export class BestellungExternComponent implements OnInit {
    ngOnInit() { }
}