import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { Verkaufspreis } from '../../components/verkaufspreis-selector/verkaufspreis-selector.component';
import { Betriebsart } from '../../../../projects/kiene-core/src/lib/components/stammdaten/vvvo/classes/betriebsart';

@Component({
  selector: 'kiene-add-betriebsart-dialog',
  templateUrl: './add-betriebsart-dialog.component.html',
  styleUrls: ['./add-betriebsart-dialog.component.scss']
})
export class AddBetriebsartDialogComponent implements OnInit {

  betriebsartCtrl = new UntypedFormControl();
  rabattCtrl = new UntypedFormControl();

  betriebsart = new Betriebsart();

  constructor(public dialogRef: MatDialogRef<AddBetriebsartDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  setVerkaufspreis(event: Verkaufspreis) {
    this.betriebsart.verkaufspreis_id = event.verkaufspreis_id;
  }

  save() {
    this.betriebsart.rabatt = this.rabattCtrl.value;
    this.betriebsart.bezeichnung = this.betriebsartCtrl.value;
    this.dialogRef.close(this.betriebsart);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
