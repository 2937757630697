import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArticleScanSelectedEvent } from '../../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import { Material } from '../../material/material';
import { Stock } from '../stock';

@Component({
  selector: 'kiene-add-vet-stock-dialog',
  templateUrl: './add-vet-stock-dialog.component.html',
  styleUrls: ['./add-vet-stock-dialog.component.css']
})
export class AddVetStockDialogComponent implements OnInit {

  mengeCtrl = new UntypedFormControl('',[Validators.required]);
  chargeCtrl = new UntypedFormControl();
  mhdCtrl = new UntypedFormControl();

  material: Material;

  constructor(public dialogRef: MatDialogRef<AddVetStockDialogComponent>, @Inject(MAT_DIALOG_DATA) private data) { }

  ngOnInit(): void {
  }

  setMaterial(event: ArticleScanSelectedEvent){
    this.material = event.article;
    if(event.charge){
      this.chargeCtrl.setValue(event.charge);
    }
    if(event.mhd){
      this.mhdCtrl.setValue(event.mhd);
    }
  }

  isSaveButtonDisabled(): boolean {

    if(this.material && this.mengeCtrl.value && this.mengeCtrl.value > 0){
      return false;
    }

    return true;
  }

  cancel(){
    this.dialogRef.close();
  }

  save(){
    const stock = new Stock();
    stock.artikel_id = this.material.artikel_id;
    stock.charge = this.chargeCtrl.value;
    stock.mhd = this.mhdCtrl.value;
    stock.menge = this.mengeCtrl.value;
    this.dialogRef.close(stock);
  }

}
