import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { Textbaustein } from "../../administration/text-baustein/text-baustein";
import {
  startWith,
  switchMap,
  debounceTime,
  distinctUntilChanged,
  map,
} from "rxjs/operators";
import { KieneTableService } from "../../components/kiene-table/kiene-table.service";
import { HttpParams } from "@angular/common/http";
import { MessageService } from "../../services/message.service";
import { TextbausteinConfig } from "../../directives/text-baustein.directive";
import { API_BASE_URL_SERVICE } from "../../../../projects/kiene-core/src/lib/kiene-core.config";
import { LocalStorageService } from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';

@Component({
  selector: "kiene-text-baustein-dialog",
  templateUrl: "./text-baustein-dialog.component.html",
  styleUrls: ["./text-baustein-dialog.component.scss"],
  host: { "(window:keydown)": "checkForKeyShortcut($event)" },
})
export class TextBausteinDialogComponent implements OnInit {

  searchCtrl = new UntypedFormControl();
  textCtrl1 = new UntypedFormControl();
  textCtrl2 = new UntypedFormControl();
  textCtrl3 = new UntypedFormControl();
  textbausteine: Observable<Textbaustein[]>;
  selectedTextbaustein: Textbaustein;
  presetText: string = null;
  textbausteinConfig: TextbausteinConfig;

  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(
    private tableServie: KieneTableService,
    private notification: MessageService,
    public dialogRef: MatDialogRef<TextBausteinDialogComponent>,
    private sessionService: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
  ) {
    this.textbausteinConfig = data.textbausteinConfig;
    switch (this.textbausteinConfig.count) {
      case 1:
        this.textCtrl1.setValue(this.textbausteinConfig.text1);
        break;
      case 2:
        this.textCtrl1.setValue(this.textbausteinConfig.text1);
        this.textCtrl2.setValue(this.textbausteinConfig.text2);
        break;
      case 3:
        this.textCtrl1.setValue(this.textbausteinConfig.text1);
        this.textCtrl2.setValue(this.textbausteinConfig.text2);
        this.textCtrl3.setValue(this.textbausteinConfig.text3);
        break;
    }

  }

  ngOnInit() {
    this.autocomplete();
  }

  checkForKeyShortcut(event) {
    //if(event.key === 'F12'){
    if (event.altKey && event.code === "KeyS") {
      this.save();
    }
  }

  autocomplete() {
    this.textbausteine = this.searchCtrl.valueChanges.pipe(
      startWith(""),
      switchMap((value) => {
        return this.searchTextbausteine(value);
      })
    );
  }

  searchTextbausteine(value: string) {
    let params = new HttpParams();
    params = params.append("search", value);
    params = params.append("limit", "25");
    if (this.textbausteinConfig.typ) {
      params = params.append("typ", this.textbausteinConfig.typ);
    }
    return this.tableServie
      .get(this.apiBaseUrl + "textbaustein/read_benutzer.php", params)
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map((response) => response.records)
      );
  }

  mapper(tb: Textbaustein) {
    if (tb) {
      return tb.titel;
    }
    return null;
  }

  setTextbaustein(event: MatAutocompleteSelectedEvent) {
    this.selectedTextbaustein = event.option.value;
    this.textCtrl1.setValue(event.option.value.textbaustein1);
    this.textCtrl2.setValue(event.option.value.textbaustein2);
    this.textCtrl3.setValue(event.option.value.textbaustein3);
  }

  cancel() {
    this.dialogRef.close();
  }

  createTextbaustein(): Textbaustein {
    let tb = new Textbaustein();

    if (this.selectedTextbaustein) {
      tb.textbaustein_id = this.selectedTextbaustein.textbaustein_id;
      tb.titel = this.searchInput.nativeElement.value;
    } else {
      tb.titel = this.searchCtrl.value;
    }
    tb.textbaustein1 = this.textCtrl1.value;
    tb.textbaustein2 = this.textCtrl2.value;
    tb.textbaustein3 = this.textCtrl3.value;
    tb.typ = this.textbausteinConfig.typ;

    return tb;
  }

  take() {
    this.dialogRef.close(this.createTextbaustein());
  }

  save() {
    const tb = this.createTextbaustein();
    if (!tb.titel || tb.titel.length < 1) {
      this.notification.alertMessage('Keinen Titel angegeben!');
    }
    else {
      this.tableServie
        .updateEntity(this.apiBaseUrl + "textbaustein/update.php", tb)
        .subscribe(
          (response) => {
            // this.notification.infoMessage('Der Textbaustein wurde erfolgreich gespeichert!');
            this.dialogRef.close(response);
          },
          (error) => {
            this.notification.errorMessage(
              "Der Textbaustein konnte nicht gespeichert werden! ",
              error
            );
          }
        );
    }
  }

  deleteTextbaustein(event: Event, tb: Textbaustein) {
    event.stopPropagation();
    let currentSearchValue = this.searchCtrl.value;
    this.tableServie
      .deleteEntity(this.apiBaseUrl + "textbaustein/delete.php", tb)
      .subscribe(
        (response) => {
          this.searchCtrl.setValue(currentSearchValue);
          this.notification.infoMessage(
            "Der Textbaustein wurde erfolgreich gelöscht!"
          );
        },
        (error) => {
          this.notification.errorMessage(
            "Der Textbaustein konnte nicht gelöscht werden!",
            error
          );
        }
      );
  }

  isSaveVisible(): boolean {
    if (!this.selectedTextbaustein) {
      return true;
    }
    if (this.sessionService.hasPermission(3706)) {
      return true;
    }
    if (this.selectedTextbaustein?.global > 0) {
      return false;
    }
    if (this.sessionService.getCurrentUser()?.benutzer_id === this.selectedTextbaustein?.erstellt_von) {
      return true;
    }
    return false;
  }
}
