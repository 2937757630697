import { GruppeTier } from '../../administration/client-group/gruppe-tier';

export class Abgabebeleg {
    abgabebeleg_id: number;
    abgabebeleg_nr: string;
    kunde_id: number;
    betrieb_id: number;
    kunde_nr_intern: number;
    kunde: string;
    tierbewegung_melden: number;
    bezeichnung: string;
    erstellt: Date;
    positionen: AbgabebelegPosition[] = [];
    status_id: number;
    status_id_neu: number;
    status: string;
    datum: Date;
    tierarzt_id: number;
    tierarzt: string;
    pdf_id: number;
    pdf_id_bestandsbuch: number;
    pdf_id_impfkontrollbuch: number;
    selbstimpfer: number;
    rechnung_ids: string;
    dbhinweis: string;
    betriebsart_kaelbermast: number;
    betriebsart_ohrmarke: number;
}

export interface AbgabebelegApi {
    records: Abgabebeleg[];
    count: number;
}

export class AbgabebelegPosition {
    aa_id: number;
    abgabebeleg_id: number;
    position: number;
    artikel_id: number;
    artikel: string;
    artikel_me: string;
    tierart_id: number;
    tierart: string;
    anzahl_tiere: number;
    tiergewicht: string;
    anwendungsdauer: number;
    dosierung: number;
    artikelmenge: number;
    artikelmenge_ist: number;
    verpackungsmenge: number;
    chargen: PositionCharge[] = [];
    indikation_id: number;
    diagnose: string;
    // identifikation_id: number;
    identifikation: string;
    prophylaxe: number;
    metaphylaxe: number;
    anwendung_id: number;
    anwendung: string;
    ean: number;
    // selbstimpfer: number;
    wartezeit: number;
    behandlung: number;
    abgabeundbehandlung: number;
    anwendungsdauer_fix: number;
    dosierung_fix: number;
    chargeJN: number;
    einzelpreis: number;

    status_id_hit: number;
    status_hit: string;
    fehler_hit: string;
    gemeldet_hit: Date;

    status_id_qs: number;
    status_qs: string;
    fehler_qs: string;
    gemeldet_qs: Date;

    impfung: number;
    tiere: GruppeTier[] = [];
    mengenartikel: number;
    aus_stueckliste: number;
    stueckliste: number;
    gruppe: string;
    herdenname: string;
    stall: string;
    gruppe_id: number;
    datum: Date;
    dosierung_txt: string;
    dosierung_tierart: number;
    koerpergewicht_tierart: number;
    sonderfall: number;
    erstellt: Date;
    einzelpreis_editor: string;
}

export interface AbgabebelegPositionApi {
    records: AbgabebelegPosition[];
    count: number;
}

export class PositionCharge {
    aa_id: number;
    ac_id: number;
    charge: string;
    charge_id: number;
    chargenmenge: number;
    mhd: Date;
    lagerplatz_id: number;
}

export class AbgabebelegCharge {
    charge_id: number;
    menge_beleg: number;
    menge_auto: number;
    charge: string;
    mhd: Date;
    lagerplatz_id: number;
}

export interface AbgabebelegChargeApi {
    records: AbgabebelegCharge[];
    count: number;
}

export class AbgabebelegStatus {
    public static ERSTELLT = 41;
    public static ABGESCHLOSSEN = 42;
    public static WIRD_KORRIGIERT = 46;
    public static STORNIERT = 47;
    public static NEUVERSCHREIBUNG = 49;
}

export class AbgabebelegWarenkorb {
    aw_id: number;
    kunde_id: number;
    kunde: string;
    zusatz: string;
    artikel_id: number;
    artikel_me: string;
    artikelmenge: number;
    editiert: Date;
}
