import {HttpParams} from "@angular/common/http";
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {API_BASE_URL_SERVICE} from "../../../../../projects/kiene-core/src/lib/kiene-core.config";
import {
    ConditionSetting,
    ConditionTableColumn,
    DateTableColumn,
    KieneBooleanTableColumn,
    TableColumn
} from "../../../components/kiene-table/table-column";
import {TableDescriptor, ToolbarButton} from "../../../components/kiene-table/table-descriptor";
import {Condition, Operator, TableOption} from "../../../components/kiene-table/table-option";
import {AbteilDialogComponent} from "../../abteil-dialog/abteil-dialog.component";
import {Abholliste} from "../../gruppen/globale-tiersuche/abholliste";

@Component({
    selector: 'kiene-abholliste-details',
    templateUrl: './abholliste-details.component.html',
    styleUrls: ['./abholliste-details.component.scss']
})
export class AbhollisteDetailsComponent implements OnInit {
    tableDescriptor = new TableDescriptor();
    abholdatumFormatted: string;


    constructor(
        public dialogRef: MatDialogRef<AbteilDialogComponent>,
        @Inject(MAT_DIALOG_DATA) protected abholliste: Abholliste,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit(): void {
        this.initTable();
        this.abholdatumFormatted = new Date(this.abholliste.abholdatum).toDateString();
    }

    private initTable(): void {
        this.tableDescriptor.headline = 'Tiere ' + this.abholliste?.kommentar;
        this.tableDescriptor.uniqueIdentifier = 'AbhollisteDetails';
        this.tableDescriptor.alternativeApiUrl = this.apiBaseUrl + 'gruppe_tier/read_kundenportal.php';
        this.tableDescriptor.params = new HttpParams().set('abholliste_id', this.abholliste.abholliste_id.toString());
        this.tableDescriptor.initialSortDirection = 'asc';
        this.tableDescriptor.initialSortColumn = 'gt_id';
        this.tableDescriptor.nameOfIdField = 'gt_id';
        this.tableDescriptor.selectable = false;
        this.tableDescriptor.createAllowed = false;
        this.tableDescriptor.showDetails = false;
        this.tableDescriptor.columns = this.getColumns();
        this.tableDescriptor.options = this.getOptions();
        this.tableDescriptor.toolbarButtons = this.getToolbarButtons();
        this.tableDescriptor.permissionView = 9491;

    }

    private getColumns(): TableColumn[] {
        const columns = [];
        columns.push(new TableColumn('ohrmarke_notnull', 'Ohrmarke'));
        columns.push(new TableColumn('gruppe', 'Gruppe'));
        columns.push(new TableColumn('kunde', 'Kunde'));
        columns.push(new TableColumn('abteil', 'Abteil'));

        columns.push(
            new KieneBooleanTableColumn('ohrmarke_gescannt', 'Ohrmarke')
        );
        columns.push(new KieneBooleanTableColumn('pass_gescannt', 'Pass'));
        columns.push(new DateTableColumn('geburtsdatum', 'Geburtsdatum', 'dd.MM.yy'));

        columns.push(new TableColumn('rasse_kuerzel', 'Rasse'));
        const conditionSettingsGeschlecht = [
            new ConditionSetting([new Condition('geschlecht_id', Operator.GLEICH, 1)], {
                icon: 'male',
                color: 'gray',
                tooltip: 'Männlich',
            }),
            new ConditionSetting([new Condition('geschlecht_id', Operator.GLEICH, 2)], {
                icon: 'female',
                color: 'gray',
                tooltip: 'Weiblich',
            }),
        ];
        columns.push(
            new ConditionTableColumn(
                'geschlecht',
                'Geschlecht',
                conditionSettingsGeschlecht,
                {
                    sortFields: ['geschlecht_id'],
                }
            )
        );
        columns.push(
            new DateTableColumn(
                'einstalldatum_notnull',
                'Einstalldatum',
                'dd.MM.yy'
            )
        );
        columns.push(new TableColumn('alter_wochen', 'Alter (W)'));
        columns.push(new TableColumn('alter_tage', 'Alter (T)'));
        return columns;
    }

    private getOptions(): TableOption[] {
        return [];
    }

    private getToolbarButtons(): ToolbarButton[] {
        return [];
    }
}
