<h1 mat-dialog-title>
    Benutzerrolle f&uuml;r {{ data.user.vorname }}
    {{ data.user.nachname }} ausw&auml;hlen
</h1>
<mat-dialog-content>
    <h5>Neue Benutzerrolle ausw&auml;hlen</h5>
    <mat-form-field>
        <mat-select multiple [compareWith]="compareRoles" [(value)]="data.user.benutzerrollen">
            <mat-option
                *ngFor="let ur of userroles"
                [value]="ur"
            >{{ ur.bezeichnung }}</mat-option
            >
        </mat-select>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="primary" (click)="onCancelClick()">
        Abbrechen
    </button>
    <button mat-button color="primary" (click)="safeAndCloseDialog()">
        &Auml;ndern
    </button>
</mat-dialog-actions>
