import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kiene-gewicht-eingeben-numberpad-dialog',
  templateUrl: './gewicht-eingeben-numberpad-dialog.component.html',
  styleUrls: ['./gewicht-eingeben-numberpad-dialog.component.scss']
})
export class GewichtEingebenNumberpadDialogComponent implements OnInit {

  gewichtString = '';

  constructor(public dialogRef: MatDialogRef<GewichtEingebenNumberpadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) { }

  ngOnInit(): void {
  }

  addNumber(value: number) {
    this.gewichtString += value.toString();
  }

  comma() {
    this.gewichtString += ',';
  }

  backspace() {
    this.gewichtString = this.gewichtString.substring(0, this.gewichtString.length - 1);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const numberStr = this.gewichtString.replace(',', '.');
    const weight = Number.parseFloat(numberStr);
    this.dialogRef.close(weight);
  }

}
