<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding style="border-bottom: 1px solid #CCC;">
    <div style="padding-left: 40px;">
      <div *ngIf="node.untersuchung">{{node.untersuchung}}</div><br>
    </div>

  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <span>{{node.untersuchung}}</span>

  </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: isArtikelNode" matTreeNodePadding
    style="border-bottom: 1px solid #CCC;">
    <div style="padding-left: 40px;">
      <div *ngIf="node.untersuchung">{{node.untersuchung}}</div><br>
      <div *ngIf="node.artikel"><mat-checkbox [labelPosition]="'before'" [checked]="isCheckboxChecked(node)"
          (change)="checkboxChanged(node, $event)">Artikel: {{node.artikel}}</mat-checkbox>
        <mat-form-field style="margin-left: 12px;">
          <mat-label>Kommentar</mat-label>
          <input matInput type="text" placeholder="Kommentar" [(ngModel)]="node.kommentar">
        </mat-form-field>
      </div><br>
      <div *ngIf="node.lieferant">Lieferant: {{node.lieferant}}</div>
    </div>

  </mat-tree-node>

</mat-tree>