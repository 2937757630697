<h1 mat-dialog-title>Gewicht eingeben</h1>
<div mat-dialog-content>
    <p
        style="width: 100%; min-height: 40px; margin: 0; padding:0; margin-bottom: 12px; font-size: 2em; font-weight: bold; text-align: right;">
        {{gewichtString ? gewichtString : '0'}}
        kg
    </p>
    <div style="font-size: 0.8em;">
        <div class="one-row" style="margin-bottom: 6px;">
            <span class="spacer"></span>
            <button mat-button
                style="width: 70px; height:70px; border-radius: 50%; background-color: lightgray;  font-weight: bold; font-size: 1.8em;touch-action: manipulation;"
                    data-cy="gewicht-dialog-7"
                (click)="addNumber(7)">
                7
            </button>
            <div style="width: 12px;"></div>
            <button mat-button
                style="width: 70px; height:70px; border-radius: 50%; background-color: lightgray;  font-weight: bold; font-size: 1.8em;touch-action: manipulation;"
                    data-cy="gewicht-dialog-8"
                (click)="addNumber(8)">
                8
            </button>
            <div style="width: 12px;"></div>
            <button mat-button
                style="width: 70px; height:70px; border-radius: 50%; background-color: lightgray;  font-weight: bold; font-size: 1.8em;touch-action: manipulation;"
                    data-cy="gewicht-dialog-9"
                (click)="addNumber(9)">
                9
            </button>
            <span class="spacer"></span>

        </div>
        <div class="one-row" style="margin-bottom: 6px;">
            <span class="spacer"></span>
            <button mat-button
                style="width: 70px; height:70px; border-radius: 50%; background-color: lightgray;  font-weight: bold; font-size: 1.8em;touch-action: manipulation;"
                    data-cy="gewicht-dialog-4"
                (click)="addNumber(4)">
                4
            </button>
            <div style="width: 12px;"></div>
            <button mat-button
                style="width: 70px; height:70px; border-radius: 50%; background-color: lightgray;  font-weight: bold; font-size: 1.8em;touch-action: manipulation;"
                    data-cy="gewicht-dialog-5"
                (click)="addNumber(5)">
                5
            </button>
            <div style="width: 12px;"></div>
            <button mat-button
                style="width: 70px; height:70px; border-radius: 50%; background-color: lightgray;  font-weight: bold; font-size: 1.8em;touch-action: manipulation;"
                    data-cy="gewicht-dialog-6"
                (click)="addNumber(6)">
                6
            </button>
            <span class="spacer"></span>

        </div>

        <div class="one-row" style="margin-bottom: 6px;">
            <span class="spacer"></span>
            <button mat-button
                style="width: 70px; height:70px; border-radius: 50%; background-color: lightgray;  font-weight: bold; font-size: 1.8em;touch-action: manipulation;"
                    data-cy="gewicht-dialog-1"
                (click)="addNumber(1)">
                1
            </button>
            <div style="width: 12px;"></div>
            <button mat-button
                style="width: 70px; height:70px; border-radius: 50%; background-color: lightgray;  font-weight: bold; font-size: 1.8em;touch-action: manipulation;"
                    data-cy="gewicht-dialog-2"
                (click)="addNumber(2)">
                2
            </button>
            <div style="width: 12px;"></div>
            <button mat-button
                style="width: 70px; height:70px; border-radius: 50%; background-color: lightgray;  font-weight: bold; font-size: 1.8em;touch-action: manipulation;"
                    data-cy="gewicht-dialog-3"
                (click)="addNumber(3)">
                3
            </button>
            <span class="spacer"></span>

        </div>
        <div class="one-row" style="margin-bottom: 6px;">
            <span class="spacer"></span>



            <button mat-button
                style="width: 70px; height:70px; border-radius: 50%; background-color: lightgray;  font-weight: bold; font-size: 1.8em;touch-action: manipulation;"
                    data-cy="gewicht-dialog-comma"
                (click)="comma()">
                ,
            </button>

            <div style="width: 12px;"></div>
            <button mat-button
                style="width: 70px; height:70px; border-radius: 50%; background-color: lightgray;  font-weight: bold; font-size: 1.8em;touch-action: manipulation;"
                    data-cy="gewicht-dialog-0"
                (click)="addNumber(0)">
                0
            </button>
            <div style="width: 12px;"></div>
            <button mat-button
                style="width: 70px; height:70px; border-radius: 50%; background-color: lightgray; font-weight: bold; font-size: 1.8em;touch-action: manipulation;"
                    data-cy="gewicht-dialog-backspace"
                (click)="backspace()">
                <mat-icon>backspace</mat-icon>
            </button>
            <span class="spacer"></span>

        </div>
    </div>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()" data-cy="gewicht-dialog-abort">Abbrechen</button>
    <button mat-flat-button color="accent" data-cy="gewicht-dialog-save" (click)="save()">Speichern</button>
</div>
