import { HttpParams } from "@angular/common/http";
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { API_BASE_URL_SERVICE } from "../../../../../projects/kiene-core/src/lib/kiene-core.config";
import {
    LocalStorageService
} from "../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service";
import { KieneTableService } from "../../../components/kiene-table/kiene-table.service";
import { NumberTableColumn, TableColumn } from "../../../components/kiene-table/table-column";
import { TableDescriptor } from "../../../components/kiene-table/table-descriptor";
import { TableOptionEvent } from "../../../components/kiene-table/table-option";
import { HeimtierBeleg } from '../../heimtier-beleg';

@Component({
    selector: 'kiene-besuchs-detail-rechnungen',
    templateUrl: './besuchs-detail-rechnungen.component.html',
    styleUrls: ['./besuchs-detail-rechnungen.component.scss']
})
export class BesuchsDetailRechnungenComponent implements OnInit, OnChanges {
    @Input() heimtierBeleg: HeimtierBeleg;
    tableDescriptor: TableDescriptor = new TableDescriptor();
    vetvetMandant: boolean;
    @Output() validationChange = new EventEmitter<boolean>();
    private params: HttpParams;

    constructor(
        public dialog: MatDialog,
        private tablebService: KieneTableService,
        private localStorageService: LocalStorageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        this.localStorageService.setCurrentClientVisible(true);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.heimtierBeleg?.kunde_id) {
            this.params = new HttpParams().set('kunde_id', this.heimtierBeleg?.kunde_id)
            this.tableDescriptor.params = this.params;
            this.tablebService.reload();
        }
    }

    ngOnInit() {
        this.vetvetMandant =
            this.localStorageService.getCurrentMandant()?.mandant_id === 0;

        this.tableDescriptor.headline = 'Rechnungen';
        this.tableDescriptor.uniqueIdentifier = 'Rechnungen';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'rechnung/';
        this.tableDescriptor.route = '/finanzen/rechnungen/';
        this.tableDescriptor.nameOfIdField = 'rechnung_id';
        this.tableDescriptor.searchPlaceholder = 'Rechnungen durchsuchen';
        this.tableDescriptor.createAllowed = false;
        this.tableDescriptor.showDetailsAsOption = true;
        this.tableDescriptor.initialSortColumn = 'rechnung_nr'
        this.tableDescriptor.params = this.params;

        this.tableDescriptor.columns.push(new TableColumn('rechnung_id', 'ID'));
        this.tableDescriptor.columns.push(
            new TableColumn('rechnung_nr', 'Rechnung Nr.')
        );

        this.tableDescriptor.columns.push(
            new TableColumn('rechnungsdatum', 'Rechnungsdatum', 'date')
        );
        this.tableDescriptor.columns.push(new TableColumn('status', 'Status'));
        this.tableDescriptor.columns.push(
            new TableColumn('status_bezahlung', 'Status Bezahlung')
        );
        this.tableDescriptor.columns.push(
            new NumberTableColumn('gesamt_netto', 'Gesamt Netto (€)')
        );
        this.tableDescriptor.columns.push(
            new NumberTableColumn('gesamt_brutto', 'Gesamt Brutto (€)')
        );


        this.tableDescriptor.permissionCreate = 2973;
        this.tableDescriptor.permissionDelete = 2974;
        this.tableDescriptor.permissionUpdate = 2973;
        this.tableDescriptor.permissionView = 2971;

        this.tableDescriptor.initialSortColumn = 'rechnung_id';
        this.tableDescriptor.initialSortDirection = 'desc';
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'details') {
            //openDetails
        } else if (event.name === 'generate_praxis') {

        }
    }
}
