<mat-form-field class="no-padding-margin-mat-form-field" style="margin: 6px 0 0 0; width: 100%">
    <mat-icon style="padding-right: 6px" matPrefix>filter_alt</mat-icon>
    <mat-label>Filter</mat-label>
    <mat-select [formControl]="selectedTierFilterCtrl">
        <mat-option *ngFor="let filter of tierFilter" [value]="filter">{{filter.name}}</mat-option>
    </mat-select>
    <button *ngIf="selectedTierFilterCtrl.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="resetTierFilterCtrl($event)">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>
