import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SoundType } from '../../classes/sound-type';
import { MessageService } from '../../services/message-service/message.service';
import { KieneBackendApiService } from '../../services/backend-api/kiene-backend-api.service';
import { KieneFileService } from '../../services/files/kiene-file.service';
import { Gruppe } from './classes/gruppe';
import { KieneStringUtils } from '../../utils/KieneStringUtils';
import { KieneUtils } from '../../utils/KieneUtils';
import { ScanErgebnis } from './classes/scan-ergebnis';
import { API_BASE_URL_SERVICE } from '../../kiene-core.config';
import { HttpParams } from '@angular/common/http';
import { EinstallenMelden } from './classes/einstallen-melden';
import { PdfViewerInput } from '../pdf-viewer/kiene-pdf-viewer/classes/pdf-viewer-input';
import { MatDialog } from '@angular/material/dialog';
import { PdfViewerDialogComponent } from '../pdf-viewer/pdf-viewer-dialog/pdf-viewer-dialog.component';
import {
    GruppeReklamationPdfHaendlerAuswahlDialogComponent
} from './gruppe-reklamation-pdf-haendler-auswahl-dialog/gruppe-reklamation-pdf-haendler-auswahl-dialog.component';
import {
    GruppeReklamationPdfHaendlerAuswahlDialogOutput
} from './gruppe-reklamation-pdf-haendler-auswahl-dialog/classes/gruppe-reklamation-pdf-haendler-auswahl-dialog-output';
import { EnterValueDialogComponent } from '../dialogs/enter-value-dialog/enter-value-dialog.component';
import { EnterValueInput, EnterValueResponse, } from '../dialogs/enter-value-dialog/enter-value-response';
import { Tierliste } from '../gruppe-tier/classes/tierliste';
import {
    GruppeAbgangDownloadDialogComponent,
} from './gruppe-abgang-download-dialog/gruppe-abgang-download-dialog.component';
import { AbgangDownloadRequestPojo } from './gruppe-abgang-download-dialog/gruppe-abgang-download-dialog';
import { GruppeTier } from '../gruppe-tier/classes/gruppe-tier';
import {
    PdfViewerDesktopDialogComponent
} from '../pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop-dialog';
import { PdfViewerDialogInput } from '../pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { AppService } from '../../services/app/app.service';
import { Empfaenger } from '../pdf-viewer/kiene-pdf-viewer/classes/empfaenger';

@Injectable({
    providedIn: 'root',
})
export class GruppenService {
    audio = new Audio();

    private reloadStandorteSubject = new Subject<boolean>();
    private reloadGruppeSubject = new Subject<boolean>();
    private reloadVerlaufSubject = new Subject<boolean>();
    private reloadGewichteUndPreiseSubject = new Subject<boolean>();

    constructor(
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private fileService: KieneFileService,
        private dialog: MatDialog,
        private appService: AppService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    reloadStandorte() {
        this.reloadStandorteSubject.next(true);
    }

    watchReloadStandorte(): Observable<boolean> {
        return this.reloadStandorteSubject.asObservable();
    }

    reloadVerlauf() {
        this.reloadVerlaufSubject.next(true);
    }

    watchReloadVerlauf() {
        return this.reloadVerlaufSubject.asObservable();
    }

    reloadGewichteUndPreise() {
        this.reloadGewichteUndPreiseSubject.next(true);
    }

    watchReloadGewichteUndPreise() {
        return this.reloadGewichteUndPreiseSubject.asObservable();
    }

    reloadGruppe() {
        this.reloadGruppeSubject.next(true);
    }

    watchReloadGruppe() {
        return this.reloadGruppeSubject.asObservable();
    }

    getLetzteAusgewaehlteGruppe(): Observable<any> {
        return this.api.get(
            `${this.apiBaseUrl}gruppe/read_one_aktuell_kundenportal.php`
        );
    }

    scanLoeschen(ohrmarkenScan: ScanErgebnis): Observable<any> {
        return this.api.deleteGeneric(
            `${this.apiBaseUrl}gruppe_tier/delete_kundenportal.php`,
            ohrmarkenScan
        );
    }

    ladeGruppenFuerKunde(kundeId: number) {
        const params = new HttpParams().set('kunde_id', kundeId);
        return this.api.get(this.apiBaseUrl + 'gruppe/read_akt_kundenportal.php', params);
    }

    ladeGruppe(gruppeId: number) {
        const params = new HttpParams().set('gruppe_id', gruppeId);
        return this.api.get(this.apiBaseUrl + 'gruppe/read_one.php', params);
    }

    warnungSchlachtdatum(schlachtdatum_kalb: Date | undefined) {
        // 2 wochen vorher
        const schlachtdatumZweiWochenVorher = new Date(
            new Date(schlachtdatum_kalb).setDate(
                new Date(schlachtdatum_kalb).getDate() - 14
            )
        );
        return schlachtdatumZweiWochenVorher < new Date();
    }

    getGruppeTier(gt_id: number): Observable<any> {
        return this.api.getByIdAlternative(
            `${this.apiBaseUrl}gruppe_tier/read_one_kundenportal.php`,
            'gt_id',
            gt_id.toString()
        );
    }

    exportCSV(clientGroup: Gruppe, per_mail: boolean): void {
        this.exportGruppeTierFile(
            clientGroup,
            per_mail,
            'gruppe_tier/read_csv.php',
            'text/csv'
        );
    }

    exportExcel(clientGroup: Gruppe, per_mail: boolean): void {
        this.exportGruppeTierFile(
            clientGroup,
            per_mail,
            'gruppe_tier/read_excel.php',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
    }

    playScanSound(sound: SoundType): void {
        if (!this.audio.ended) {
            this.audio.pause();
            this.audio = new Audio();
        }
        this.audio.src = `/assets/sounds/${sound}`;
        this.audio.load();
        this.audio.play().then();
    }

    loadTier(tierId: string) {
        return this.api.getByIdAlternative(
            `${this.apiBaseUrl}gruppe_tier/read_one_kundenportal.php`,
            'gt_id',
            tierId
        );
    }

    getGruppePraxissoftware(gruppe_id: number) {
        return this.api.getByIdAlternative(
            `${this.apiBaseUrl}gruppe/read_one.php`,
            'gruppe_id',
            gruppe_id.toString()
        );
    }

    getGruppeKundenportal(gruppe_id: number) {
        return this.api.getByIdAlternative(
            `${this.apiBaseUrl}gruppe/read_one_kundenportal.php`,
            'gruppe_id',
            gruppe_id.toString()
        );
    }

    openReklamationPdfDialog(gruppe: Gruppe) {
        const dialogRef = this.dialog.open(
            GruppeReklamationPdfHaendlerAuswahlDialogComponent,
            {
                minWidth: '95vw',
                data: gruppe,
            }
        );
        dialogRef
            .afterClosed()
            .subscribe(
                (res: GruppeReklamationPdfHaendlerAuswahlDialogOutput) => {
                    if (res) {
                        const pdfViewerInput = new PdfViewerInput(
                            'gruppe/read_reklamationspdf_kundenportal.php',
                            res.params.append(
                                'gruppe_id',
                                gruppe.gruppe_id.toString()
                            ),
                            {
                                betreff: `Reklamationen ${gruppe.bezeichnung}`,
                                dateiname: `Reklamationen ${gruppe.bezeichnung}.pdf`,
                            }
                        );
                        pdfViewerInput.empfaenger = [res.empfaenger];

                        this.dialog.open<PdfViewerDialogComponent, PdfViewerInput>(PdfViewerDialogComponent, {
                            minWidth: '95vw',
                            data: pdfViewerInput,
                        });
                    }
                }
            );
    }

    private sendViaMail(url: string, params: HttpParams) {
        params = params.set('mail', '1');
        this.api.get(url, params).subscribe({
            next: () => {
                this.messageService.infoMessage(
                    'Mail erfolgreich gesendet!'
                );
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Mail konnte nicht gesendet werden!',
                    err
                );
            },
        });
    }

    openGruppeAbgangDownloadDialog(options?: {
        gruppe?: Gruppe;
        tierliste?: Tierliste;
    }) {
        this.dialog.open(
            GruppeAbgangDownloadDialogComponent,
            {
                width: '90vw',
                maxWidth: '90vw',
                data: {
                    gruppe: options?.gruppe,
                    tierliste: options?.tierliste,
                },
            }
        ).afterClosed().subscribe((res: AbgangDownloadRequestPojo) => {
            if (res?.confirmed === true) {
                if (res?.pdfDownloadVerfuegbar()) {
                    const empfaengers: Empfaenger[] = [];
                    console.log(options?.tierliste);
                    if (options?.tierliste?.kunde_email_schlachthof) {
                        const empfaenger = new Empfaenger(options.tierliste.kunde_email_schlachthof, options.tierliste.kunde_schlachthof, false);
                        empfaengers.push(empfaenger);
                    }
                    this.showAbgaengePdf(
                        res,
                        'VETVET_Tierliste_' + res.tierlisten_ids[0] + '.pdf',
                        empfaengers
                    );
                } else if (res?.csvDownloadVerfuegbar()) {
                    const url =
                        this.apiBaseUrl + 'tierliste/read_csv_kundenportal.php';
                    if (this.appService.isMobileScreen) {
                        // per Mail senden
                        this.sendViaMail(url, res.getHttpParams());
                    } else {
                        this.fileService.downloadCSV(
                            url,
                            res.getHttpParams()
                        );
                    }
                } else if (res?.excelDownloadVerfuegbar()) {
                    const url =
                        this.apiBaseUrl + 'tierliste/read_excel_kundenportal.php';
                    if (this.appService.isMobileScreen) {
                        // per Mail senden
                        this.sendViaMail(url, res.getHttpParams());
                    } else {
                        this.fileService.downloadExcel(
                            url,
                            res.getHttpParams()
                        );
                    }
                } else {
                    const url = this.apiBaseUrl + 'tierliste/read_zip_kundenportal.php';
                    if (this.appService.isMobileScreen) {
                        this.sendViaMail(url, res.getHttpParams());
                    } else {
                        this.fileService.downloadZip(url, res.getHttpParams());
                    }
                }
            }
        });
    }

    private showAbgaengePdf(
        res: AbgangDownloadRequestPojo,
        dateiname: string,
        empfaengers: Empfaenger[]
    ) {
        if (this.appService.isMobileScreen) {
            const pdfViewerInput = new PdfViewerInput(
                'tierliste/read_pdf_kundenportal.php',
                res.getHttpParams(),
                {
                    betreff: `Abgangsliste`,
                    dateiname: dateiname,
                }
            );
            pdfViewerInput.empfaenger = empfaengers;
            this.dialog.open<PdfViewerDialogComponent, PdfViewerInput>(PdfViewerDialogComponent, {
                minWidth: '95vw',
                data: pdfViewerInput,
            });

        } else {
            const inp = new PdfViewerDialogInput('tierliste/read_pdf_kundenportal.php', res.getHttpParams(), 'Abgangliste');
            inp.sende_mail = true;
            inp.empfaenger = empfaengers;
            this.dialog.open<PdfViewerDesktopDialogComponent, PdfViewerDialogInput>(PdfViewerDesktopDialogComponent, { width: '100%', minWidth: '80vw', data: inp });
        }

    }

    ladeGruppenTiere(gruppe_id: number, params?: HttpParams) {
        if (!params) {
            params = new HttpParams();
        }
        params = params.append('gruppe_id', gruppe_id);
        return this.api.getAll(
            this.apiBaseUrl + 'gruppe_tier/read_kundenportal.php',
            params
        );
    }

    ladeGruppenTiereUeberAlleGruppen(params?: HttpParams) {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('abgaenge', '0');
        params = params.set('tot', '0');
        params = params.set('alle_gruppen', '1');
        return this.api.getAll(
            this.apiBaseUrl + 'gruppe_tier/read_kundenportal.php',
            params
        );
    }

    einstallenMelden(einstallenMelden: EinstallenMelden) {
        return this.api.post(
            this.apiBaseUrl + 'gruppe_tier/einstallen_hit_kundenportal.php',
            einstallenMelden,
            null
        );
    }

    updateGruppeKundenportal(gruppe: Gruppe) {
        return this.api.post(
            this.apiBaseUrl + 'gruppe/update_kundenportal.php',
            gruppe,
            null
        );
    }

    ladeTierlistenHistorie(gruppe_id_abgang: number) {
        let params = new HttpParams();
        if (gruppe_id_abgang) {
            params = params.set('gruppe_id_abgang', gruppe_id_abgang);
        }
        return this.api.getAll(
            `${this.apiBaseUrl}tierliste/read_erstellt_historie.php`,
            params
        );
    }

    gruppenTiereSuchen(searchTxt: string, kunde_id?: number) {
        let params = new HttpParams().append('search', searchTxt);
        if (KieneUtils.isNNoU(kunde_id)) {
            params = params.append('kunde_id', kunde_id);
        }
        return this.api.getAll(
            this.apiBaseUrl + 'gruppe_tier/search.php',
            params
        );
    }

    getTierreklamationenFormatted(t) {
        const reklamation = t.reklamation
            .map((x) => x.reklamation.trim())
            .join(', ')
            .trim();
        return KieneStringUtils.isNullOrEmpty(reklamation) ? null : reklamation;
    }

    deleteGruppe(g: Gruppe) {
        return this.api.deleteGeneric(
            this.apiBaseUrl + 'gruppe/delete_kundenportal.php',
            g
        );
    }

    historieAendern(g: Gruppe, historie: boolean) {
        g.historie = historie ? 1 : 0;
        return this.api.updateGeneric(
            this.apiBaseUrl + 'gruppe/historie.php',
            g
        );
    }

    tiereAusHitNachladen(gruppe: Gruppe): Observable<any> {
        this.messageService.infoMessage(
            'Tiere werden aus HI-Tier geladen. Dies kann einige Momente dauern'
        );
        const params = new HttpParams().append(
            'gruppe_id',
            gruppe.gruppe_id?.toString()
        );
        return this.api.get(
            this.apiBaseUrl + '_qs_hit/import_gruppe.php',
            params
        );
    }

    schlachtdatenAusHitNachladen(kunde_id: number): Observable<any> {
        const params = new HttpParams().append(
            'kunde_id',
            kunde_id?.toString()
        );
        return this.api.get(
            this.apiBaseUrl + 'kunde/read_schlachtdaten_aus_hit.php',
            params
        );
    }

    ladeGruppenVorschlaege(kunde_id: number) {
        const params = new HttpParams().append('kunde_id', kunde_id);
        return this.api.get(
            this.apiBaseUrl + '_qs_hit/import_gruppe.php',
            params
        );
    }

    updateMultipleGruppen(selectedVorschlaege: Gruppe[]) {
        return this.api.updateGeneric(
            this.apiBaseUrl + 'gruppe/update_multiple.php',
            { gruppen: selectedVorschlaege }
        );
    }

    searchGruppenPraxissoftware(search: string) {
        return this.api.get(
            this.apiBaseUrl +
            'gruppe/read_akt.php?limit=25&offset=0&search=' +
            search
        );
    }

    // //////////////////////////////////////////////////////////////

    getStandardGruppePraxissoftware(clientId: number) {
        const params = new HttpParams().append('kunde_id', clientId.toString());
        return this.api.get(
            this.apiBaseUrl + 'gruppe/read_standard.php',
            params
        );
    }

    getGruppesHistorySinglePraxissoftware(kundeId: number) {
        return this.api.get(
            this.apiBaseUrl + 'gruppe/read_hist.php?kunde_id=' + kundeId
        );
    }

    todMeldenKundenportal(
        gruppeTierId: number,
        kp_todesdatum: Date,
        entsorgerId: number,
        todesart_id: number
    ) {
        const json = {
            gt_id: gruppeTierId,
            kp_todesdatum: kp_todesdatum,
            entsorger_id: entsorgerId,
            todesart_id: todesart_id,
        };
        console.log(json)
        return this.api.post(
            this.apiBaseUrl + 'gruppe_tier/tod_kundenportal.php',
            json,
            null
        );
    }

    getGruppenHistory(
        kundeId: number,
        sortColumn: string,
        sortDirection: string,
        offset: number,
        limit: number,
        searchString: string
    ): Observable<Gruppe> {
        return this.api.get(
            this.apiBaseUrl +
            'gruppe/read_hist.php?kunde_id=' +
            kundeId +
            '&orderby=' +
            sortColumn +
            ' ' +
            sortDirection +
            '&limit=' +
            limit +
            '&offset=' +
            offset +
            '&search=' +
            searchString
        );
    }

    ladeGruppenVerlauf(gruppeId: number) {
        const params = new HttpParams().set('gruppe_id', gruppeId?.toString());
        return this.api.get(
            this.apiBaseUrl + 'gruppe_tier/read_verlauf.php',
            params
        );
    }

    ladeViehhaendlerFuerGruppe(gruppe: Gruppe) {
        const params = new HttpParams().set(
            'gruppe_id',
            gruppe.gruppe_id.toString()
        );
        return this.api.getAll(
            this.apiBaseUrl + 'gruppe_tier/read_gruppe_viehhaendler.php',
            params
        );
    }

    sendePdfAnAlleHaendler(gruppeId: number) {
        return this.api.post(
            this.apiBaseUrl + 'gruppe/mail_reklamationen.php',
            { gruppe_id: gruppeId },
            null
        );
    }

    zertifikateAnSGS(options: { ganzeGruppe?: { gruppe_id: number }, einzelneTiere?: { gruppenTiere: GruppeTier[] } }) {
        if (!(options?.einzelneTiere?.gruppenTiere?.length > 0 || options?.ganzeGruppe?.gruppe_id > 0)) {
            this.messageService.alertMessage('Ungültige Übergabeparameter!');
            return;
        }
        const inputData = new EnterValueInput();
        inputData.title = 'Anmeldung zur Zertifizierung';
        inputData.text =
            'Zertifizierungsdatei mit allen aktuell in der Gruppe befindlichen Tieren an die SGS senden?';
        inputData.okButton = 'an SGS senden';
        inputData.buttonColor = 'accent';
        inputData.addInputValue('voraussichtlicher Schlachttermin', 'date');
        const dialogRef = this.dialog.open(EnterValueDialogComponent, {
            data: inputData,
            position: { top: '10px' },
        });
        dialogRef.afterClosed().subscribe({
            next: (res: EnterValueResponse) => {
                if (res && res.result === true) {
                    let obj = {
                        schlachttermin: res.getValue(0)
                    };
                    if (options?.einzelneTiere?.gruppenTiere?.length > 0) {
                        let gt_ids = '';
                        for (let gt of options?.einzelneTiere?.gruppenTiere) {
                            if (gt_ids !== '') {
                                gt_ids += ',';
                            }
                            gt_ids += gt.gt_id;
                        }
                        obj['gt_ids'] = gt_ids;
                    } else {
                        obj['gruppe_id'] = options?.ganzeGruppe?.gruppe_id;
                    }

                    this.api
                        .post(
                            this.apiBaseUrl +
                            'gruppe_tier/sgs_zertifizierung.php',
                            obj,
                            null
                        )
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Zertifizierung an SGS gesendet!'
                                );
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Zertifizierung konnte nicht gesendet werden!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    private exportGruppeTierFile(
        clientGroup: Gruppe,
        per_mail: boolean,
        urlPath: string,
        mimeType: string
    ): void {
        this.api
            .getAllAlternativeBlob(
                this.apiBaseUrl + urlPath,
                new HttpParams()
                    .set('gruppe_id', clientGroup.gruppe_id.toString())
                    .set('mail', per_mail ? '1' : '0')
            )
            .subscribe({
                next: (response) => {
                    if (per_mail) {
                        this.messageService.infoMessage(
                            'Die Datei wurde per Mail an Sie versendet!'
                        );
                    } else {
                        this.fileService.openDownload(response, mimeType);
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fehler beim Exportieren der Datei',
                        err
                    );
                },
            });
    }
}
