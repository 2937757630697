<div class="one-row">
    <button mat-icon-button color="accent" (click)="previousWeek()">
        <mat-icon>chevron_left</mat-icon>
    </button>
    <h3 style="margin-bottom: 0;">Dienst diese Woche ({{ start | date: 'dd.MM.' }} - {{ ende | date: 'dd.MM.yyyy' }}
        )</h3>
    <button mat-icon-button color="accent" (click)="nextWeek()">
        <mat-icon>chevron_right</mat-icon>
    </button>
    <span class="spacer"></span>
</div>
<div *ngIf="isLoading" class="one-row">
    <span class="spacer"></span>
    <mat-progress-spinner mode="indeterminate" diameter="48"></mat-progress-spinner>
    <span class="spacer"></span>
</div>
<p *ngIf="(!eintraege || eintraege?.length === 0) && !isLoading">F&uuml;r diese Woche ist noch kein Dienst geplant!</p>
<table *ngIf="!isLoading" style="width: 100%; margin-bottom: 12px; border-collapse: collapse; border-spacing: 0;">
    <tr *ngFor="let e of eintraege">
        <td style="width: 100px;"><span style="font-weight: bold;">{{ e.startdatum | date:'E dd.MM.' }}</span></td>
        <td style="padding: 12px; min-width: 100px;">
            <span *ngIf="e.eintragtyp_id === 4" (click)="dienstgruppeEditieren(e)"
                  [ngStyle]="{'color': e.dienstgruppe_farbe, 'background-color': e.dienstgruppe_hintergrundfarbe, 'cursor': tierarztgruppeCursor()}"
                  class="tierarztgruppe">{{ e.dienstgruppe }}</span>
        </td>
        <td>
            <div class="one-row" style="flex-wrap: wrap">
                <div *ngFor="let t of e.tieraerzte" class="one-row tierarzt" style="align-self: center;"
                     [ngStyle]="{'color': e.dienstgruppe_farbe, 'background-color': e.dienstgruppe_hintergrundfarbe}">
                    <span>{{ t.vorname }} {{ t.nachname }}</span>
                    <mat-icon (click)="tierarztEntfernen(e, t)" style="cursor: pointer;">cancel</mat-icon>
                </div>
                <button mat-icon-button color="accent" (click)="tierarztHinzufuegen(e)">
                    <mat-icon>add</mat-icon>
                </button>
                <span class="spacer"></span>
            </div>
        </td>
    </tr>
</table>
