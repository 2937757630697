import {Component, Inject, OnInit} from '@angular/core';
import {TableOptionEvent} from '../../components/kiene-table/table-option';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {
    DateTableColumn,
    NumberTableColumn,
    TableColumn,
    TextTableColumn
} from '../../components/kiene-table/table-column';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {HttpParams} from '@angular/common/http';
import {Umbuchung} from '../goods-receipt-history/umbuchung';
import {
    CreateRuecklieferungDialogComponent
} from './create-ruecklieferung-dialog/create-ruecklieferung-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';

@Component({
    selector: 'kiene-warenruecklieferungen',
    templateUrl: './warenruecklieferungen.component.html',
    styleUrls: ['./warenruecklieferungen.component.scss']
})
export class WarenruecklieferungenComponent implements OnInit {

    tableDescriptor: TableDescriptor;

    constructor(@Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private tableService: KieneTableService,
                private dialog: MatDialog,
                private pdfService: PdfViewerDesktopService) {
    }

    ngOnInit(): void {
        this.tableDescriptor = new TableDescriptor();
        this.tableDescriptor.headline = 'Warenrücklieferungen';
        this.tableDescriptor.uniqueIdentifier = 'Warenrücklieferungen';
        this.tableDescriptor.alternativeApiUrl = this.apiBaseUrl + 'wareneingang/read_ruecklieferungen.php';
        this.tableDescriptor.initialSortColumn = 'umbuchung_id';
        this.tableDescriptor.initialSortDirection = 'desc';
        this.tableDescriptor.showDetails = false;
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;

        this.tableDescriptor.columns = this.getTableColumns();

        this.tableDescriptor.permissionCreate = 1659;
        this.tableDescriptor.permissionView = 1659;
        this.tableDescriptor.permissionUpdate = 1659;
        this.tableDescriptor.permissionDelete = 1659;
    }

    catchOptionEvent(event: TableOptionEvent) {
        console.log(event.name);
        if (event.name === 'showPdf') {
            this.openPdf(event.value);
        } else if (event.name === 'add') {
            this.openCreateDialog();
        }
    }

    openPdf(u: Umbuchung) {
        const input = new PdfViewerDialogInput(
            'wareneingang/read_pdf.php',
            new HttpParams().set(
                'umbuchung_id',
                u.umbuchung_id?.toString()
            ),
            'Lieferschein '
        );
        this.pdfService.openPdfViewer(input);
    }

    private getTableColumns() {
        const columns = [];

        columns.push(new DateTableColumn('erstellt', 'Datum', 'dd.MM.yyyy'));
        columns.push(new TextTableColumn('artikel_me', 'Artikel'));
        columns.push(new TextTableColumn('lieferant', 'Lieferant'));
        columns.push(new NumberTableColumn('menge', 'Menge'));
        columns.push(new TextTableColumn('charge', 'Charge'));
        columns.push(new TextTableColumn('bestell_nr', 'Bestellung', {prefix: '#'}));
        columns.push(new TableColumn('pdf_id', '', 'pdf'));

        return columns;
    }

    private openCreateDialog() {
        const dialogRef = this.dialog.open(CreateRuecklieferungDialogComponent);
        dialogRef.afterClosed().subscribe({
            next: result => {
                if (result) {
                    this.tableService.reload('create manual Ruecklieferung');
                }
            }
        });
    }
}
