import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API_BASE_URL_SERVICE} from '../../../kiene-core.config';
import {Observable} from 'rxjs';
import {User, UserApi, UserClient, UserMandant, UserMandantApi, UserRoleApi} from './benutzer';
import {Client, ClientApi} from '../../client/classes/client';
import {KieneApi} from '../../../services/backend-api/kiene-backend-api.service';

@Injectable({
    providedIn: 'root'
})
export class BenutzerService {
    constructor(private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    getAllUsers(
        sortColumn: string,
        sortDirection: string,
        offset: number,
        limit: number,
        searchString: string
    ): Observable<UserApi> {
        const parameters: HttpParams = new HttpParams()
            .set('orderby', sortColumn + ' ' + sortDirection)
            .set('limit', limit.toString())
            .set('offset', offset.toString())
            .set('search', searchString);
        return this.http.get<UserApi>(
            this.apiBaseUrl + 'benutzer/read.php',
            {params: parameters}
        );
    }

    getUser(id: string): Observable<User> {
        return this.http.get<User>(
            this.apiBaseUrl + 'benutzer/read_one.php?benutzer_id=' + id
        );
    }

    sendTestMail(user: User) {
        return this.http.post(
            this.apiBaseUrl + 'benutzer/testmail.php',
            user
        );
    }

    getAllUserRoles(): Observable<UserRoleApi> {
        return this.http.get<UserRoleApi>(
            this.apiBaseUrl + 'benutzerrolle/read.php'
        );
    }

    updateUser(user: User): Observable<User> {
        return this.http.post<User>(
            this.apiBaseUrl + 'benutzer/update.php',
            user
        );
    }

    createUser(user: User): Observable<User> {
        return this.http.post<User>(
            this.apiBaseUrl + 'benutzer/create.php',
            user
        );
    }

    assignClientToUser(user: User, client: Client): Observable<UserClient> {
        const userClient: UserClient = new UserClient();
        userClient.benutzer_id = user.benutzer_id;
        userClient.kunde_id = client.kunde_id;
        return this.http.post<UserClient>(
            this.apiBaseUrl + 'benutzer_kunde/create.php',
            userClient
        );
    }

    removeClientFromUser(user: User, client: Client) {
        const userClient: UserClient = new UserClient();
        userClient.benutzer_id = user.benutzer_id;
        userClient.kunde_id = client.kunde_id;
        return this.http.post<UserClient>(
            this.apiBaseUrl + 'benutzer_kunde/delete.php',
            userClient
        );
    }

    getClientsForUser(id: string): Observable<ClientApi> {
        return this.http.get<ClientApi>(
            this.apiBaseUrl + 'kunde/read_benutzer.php?benutzer_id=' + id
        );
    }

    deleteUser(user: User) {
        return this.http.post(
            this.apiBaseUrl + 'benutzer/delete.php',
            user
        );
    }

    getAvailableMandanten(): Observable<KieneApi> {
        return this.http.get<KieneApi>(
            this.apiBaseUrl + 'mandant/read.php'
        );
    }

    getAssignedMandanten(user: User): Observable<UserMandantApi> {
        return this.http.get<UserMandantApi>(
            this.apiBaseUrl +
            'benutzer_mandant/read.php?benutzer_id=' +
            user.benutzer_id
        );
    }

    assignMandantToUser(um: UserMandant): Observable<UserMandant> {
        return this.http.post<UserMandant>(
            this.apiBaseUrl + 'benutzer_mandant/create.php',
            um
        );
    }

    removeMandantFromUser(um: UserMandant) {
        return this.http.post(
            this.apiBaseUrl + 'benutzer_mandant/delete.php',
            um
        );
    }

}
