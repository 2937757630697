import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {KalenderEintrag, KalendereintragTierarzt} from '../classes/kalender-eintrag';
import {MessageService} from '../../../services/message.service';
import {MatDialog} from '@angular/material/dialog';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {formatDate} from '@angular/common';
import {Dienstgruppe} from '../../wochenende-und-feiertag-planung/dienstgruppe';
import {
    DienstgruppeDetailsDialogComponent
} from '../../wochenende-und-feiertag-planung/dienstgruppe-details-dialog/dienstgruppe-details-dialog.component';
import {Vet} from '../../../administration/vet/vet';
import {SelectTierarztDialogComponent} from './select-tierarzt-dialog/select-tierarzt-dialog.component';

@Component({
    selector: 'kiene-wochenenddienstanzeige',
    templateUrl: './wochenenddienstanzeige.component.html',
    styleUrls: ['./wochenenddienstanzeige.component.scss']
})
export class WochenenddienstanzeigeComponent implements OnInit {

    @Output('change') change = new EventEmitter<boolean>();

    isLoading = false;
    start: Date;
    ende: Date;
    eintraege: KalenderEintrag[] = [];
    dienstgruppen: Dienstgruppe[] = [];
    initDate = new Date();

    constructor(private api: KieneBackendApiService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private messageService: MessageService,
                private dialog: MatDialog,
                private localStorageService: LocalStorageService) {
    }

    ngOnInit(): void {
        this.initWoche();
    }

    dienstgruppeEditieren(e: KalenderEintrag) {
        if (!this.localStorageService.hasPermission(1263)) {
            return;
        }
        if (e) {
            const dg = new Dienstgruppe();
            dg.dienstgruppe = e.dienstgruppe;
            dg.farbe = e.dienstgruppe_farbe;
            dg.hintergrundfarbe = e.dienstgruppe_hintergrundfarbe;
            this.dialog.open(DienstgruppeDetailsDialogComponent, {
                data: dg
            }).afterClosed().subscribe({
                next: result => {
                    if (result) {
                        this.initWoche();
                    }
                }
            });
        }
    }

    previousWeek() {
        this.initDate = new Date(this.initDate.setDate(this.initDate.getDate() - 7));
        this.initWoche();
    }

    nextWeek() {
        this.initDate = new Date(this.initDate.setDate(this.initDate.getDate() + 7));
        this.initWoche();
    }

    tierarztgruppeCursor() {
        if (this.localStorageService.hasPermission(1263)) {
            return 'pointer';
        }
        return 'default';
    }

    tierarztEntfernen(e: KalenderEintrag, t: KalendereintragTierarzt) {
        const index = e?.tieraerzte?.indexOf(t);
        e.tieraerzte?.splice(index, 1);
        this.updateKalendereintrag(e);
    }

    tierarztHinzufuegen(e: KalenderEintrag) {
        this.dialog.open(SelectTierarztDialogComponent).afterClosed().subscribe({
            next: (tierarzt: Vet) => {
                if (tierarzt) {
                    const kt = new KalendereintragTierarzt();
                    kt.vorname = tierarzt.vorname;
                    kt.nachname = tierarzt.nachname;
                    kt.tierarzt_id = tierarzt.tierarzt_id;
                    e.tieraerzte.push(kt);
                    this.updateKalendereintrag(e);
                }
            }
        });
    }

    private initWoche() {
        const aktuellerTagDerWoche = this.initDate.getDay();
        if (aktuellerTagDerWoche > 0) {
            const tageZurueckBisMontag = aktuellerTagDerWoche - 1;
            const tmpDateStart = new Date(this.initDate);
            const tmpDateEnd = new Date(this.initDate);
            this.start = new Date(tmpDateStart.setDate(tmpDateStart.getDate() - tageZurueckBisMontag));
            const tageVorBisSonntag = 7 - aktuellerTagDerWoche;
            this.ende = new Date(tmpDateEnd.setDate(tmpDateEnd.getDate() + tageVorBisSonntag));
        } else if (aktuellerTagDerWoche === 0) {
            this.ende = new Date(this.initDate);
            const tmpDateEnd = new Date(this.initDate);
            this.start = new Date(tmpDateEnd.setDate(tmpDateEnd.getDate() - 7));
        }
        this.ladeKalendereintraege();
    }

    private ladeKalendereintraege() {
        if (!this.start || !this.ende) {
            return;
        }
        this.isLoading = true;
        let params = new HttpParams();
        // params = params.append('eintragtyp_id[]', '3');
        params = params.append('eintragtyp_id[]', '4');
        params = params.append('datum_von', formatDate(this.start, 'yyyy-MM-dd', 'de'));
        params = params.append('datum_bis', formatDate(this.ende, 'yyyy-MM-dd', 'de'));
        params = params.append('orderby', 'startdatum asc');
        this.api.get(this.apiBaseUrl + 'kalendereintrag/read.php', params).subscribe({
            next: response => {
                this.eintraege = response.records;
                this.initTierarztgruppeMap();
                this.isLoading = false;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
                this.isLoading = false;
            }
        });
    }

    private initTierarztgruppeMap() {
        this.dienstgruppen = [];
        for (let e of this.eintraege) {
            const dg = new Dienstgruppe();
            dg.dienstgruppe_id = e.dienstgruppe_id;
            dg.dienstgruppe = e.dienstgruppe;
            dg.farbe = e.dienstgruppe_farbe;
            dg.hintergrundfarbe = e.dienstgruppe_hintergrundfarbe;
            const found = this.dienstgruppen.find(gruppe => gruppe.dienstgruppe_id === dg.dienstgruppe_id);
            if (!found) {
                this.dienstgruppen.push(dg);
            }
        }
    }

    private updateKalendereintrag(e: KalenderEintrag) {
        this.isLoading = true;
        this.api.post(this.apiBaseUrl + 'kalendereintrag/update.php', e).subscribe({
            next: response => {
                this.isLoading = false;
                this.ladeKalendereintraege();
                this.change.emit(true);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
                this.isLoading = false;
            }
        });
    }
}
