<h1 mat-dialog-title>Untersuchungsgruppe {{edit ? 'bearbeiten' : 'erstellen'}}</h1>
<div mat-dialog-content>
  <mat-form-field style="width: 100%;">
    <input matInput type="text" placeholder="Untersuchungsgruppe" [formControl]="untersuchungsgruppeCtrl">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Abbrechen</button>
  <button mat-flat-button color="primary" (click)="save()" cdkFocusInitial [disabled]="untersuchungsgruppeCtrl.invalid">Speichern</button>
</div>
