<h1 mat-dialog-title>Zug&auml;nge melden</h1>
<div mat-dialog-content>

    <p *ngIf="isLoading">
        <strong>Lade Tiere...</strong>
    </p>

    <div *ngIf="tiereZumMelden?.length > 0 && !isLoading">
        <div class="one-row" style="justify-content: flex-start">
            <strong>Eingestallte, noch nicht gemeldete Tiere:</strong>
            <h3>&nbsp;{{tiereZumMelden.length}} Stk.</h3>
        </div>

        <ul style="margin: 0; padding: 0; list-style-type: none; overflow: auto">
            <ng-container *ngFor="let t of tiereZumMelden, let index = index">
                <li *ngIf="index === 0">
                    <div style="margin-top: 6px" class="one-row">
                        <h3>{{(t.kp_einstalldatum | date: 'dd.MM.yyyy') || 'ohne Einstalldatum'}}</h3>
                    </div>
                </li>
                <li *ngIf="index !== 0 && tiereZumMelden[index].kp_einstalldatum !== tiereZumMelden[index-1].kp_einstalldatum">
                    <div style="margin-top: 6px" class="one-row">
                        <h3>{{(t.kp_einstalldatum | date: 'dd.MM.yyyy') || 'ohne Einstalldatum'}}</h3>
                    </div>
                </li>
                <li>
                    <div class="one-row" style="justify-content: flex-start; margin-left: 12px">
                        <h3 style="margin-right: 12px">{{index+1}}.</h3>
                        <kiene-ohrmarke [ohrmarke]="t.ohrmarke_notnull"></kiene-ohrmarke>
                    </div>

                </li>
            </ng-container>

        </ul>
    </div>
    <div *ngIf="tiereZumMelden?.length <= 0 && !isLoading">
        <p>Zur Zeit gibt es für diese Gruppe keine noch zu meldenden Tiere f&uuml;r vorangegangene Einstallungen!</p>
    </div>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()" [disabled]="isLoading || tiereZumMelden?.length === 0">Melden</button>
</div>
