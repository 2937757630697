export class Textbaustein {
  textbaustein_id: number;
  titel: string;
  textbaustein1: string;
  textbaustein2: string;
  textbaustein3: string;
  typ: string;
  global: number;
  erstellt: Date;
  editiert: Date;
  erstellt_von: number;
}

export class TextbausteinTyp {
  typ: string;
}

export class TextbausteinResult {
  textbaustein: Textbaustein;
  confirm: boolean;
}
