import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Indikation } from '../indikation';
import { Material } from '../../material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Tierart } from '../../../../administration/tierarten/tierart';
import { KieneTableService } from '../../../../components/kiene-table/kiene-table.service';
import { environment } from '../../../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { MessageService } from '../../../../services/message.service';

/**
 *
 *
 * @export
 * @class AddIndikationToMaterialDialogComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'kiene-add-indikation-to-material-dialog',
  templateUrl: './add-indikation-to-material-dialog.component.html',
  styleUrls: ['./add-indikation-to-material-dialog.component.scss']
})
export class AddIndikationToMaterialDialogComponent implements OnInit {

  diagnoseCtrl = new UntypedFormControl();
  tierartCtrl = new UntypedFormControl();
  material = new Material();
  indikation: Indikation;
  tierarten: Tierart[] = [];

  constructor(public dialogRef: MatDialogRef<AddIndikationToMaterialDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private api: KieneTableService,
    private messageService: MessageService) {
    this.material = data.material;
    if (data.indikation) {
      this.indikation = data.indikation;
      this.diagnoseCtrl.setValue(this.indikation.diagnose);
      this.diagnoseCtrl.disable();


    }
  }

  /**
   * Lade Tierarten beim Oeffnen des Dialogs
   *
   * @memberof AddIndikationToMaterialDialogComponent
   */
  ngOnInit() {
    this.loadTierarten();
  }
  /**
   * Lade Tierarten fuer diesen Artikel. 
   *
   * @memberof AddIndikationToMaterialDialogComponent
   */
  loadTierarten() {
    let params = new HttpParams().append('artikel_id', this.material.artikel_id.toString());
    this.api.get(environment.BASE_URI + 'artikel_tierart/read.php', params).subscribe(response => {
      this.tierarten = response.records;
      this.tierartCtrl.setValue(this.indikation.tierarten);
    }, error => {
      this.messageService.errorMessage('Tierarten konnten nicht geladen werden!', error);
    });
  }

  /**
   * Vergleich zwei Tierarten anhand der ID
   *
   * @param {Tierart} t1
   * @param {Tierart} t2
   * @return {*}  {boolean}
   * @memberof AddIndikationToMaterialDialogComponent
   */
  tierartComparator(t1: Tierart, t2: Tierart): boolean {

    if (!t1 || !t2) {
      return false;
    }

    return t1.tierart_id === t2.tierart_id;
  }

  /**
   * Dialog ohne Aktion Schliessen
   *
   * @memberof AddIndikationToMaterialDialogComponent
   */
  cancel() {
    this.dialogRef.close(null);
  }

  /**
   * Dialog schliessen und Werte speichern.
   *
   * @memberof AddIndikationToMaterialDialogComponent
   */
  save() {
    if (!this.indikation) {
      this.indikation = new Indikation();
      this.indikation.diagnose = this.diagnoseCtrl.value;
    }
    this.indikation.artikel_id = this.material.artikel_id;

    // const selectedTierart: Tierart = this.tierartCtrl.value;
    // if(selectedTierart){
    //   this.indikation.tierart_id = selectedTierart.tierart_id;
    //   this.indikation.tierart = selectedTierart.tierart;
    // }
    this.indikation.tierarten = this.tierartCtrl.value;

    this.dialogRef.close(this.indikation);
  }

}
