<h1 mat-dialog-title>Sonderpreise</h1>
<div mat-dialog-content>
    <p>F&uuml;r den Artikel <span style="font-weight: bold;">{{artikel?.bezeichnung}}&nbsp;({{artikel?.einheit}})</span>
        haben folgende Kunden Sonderpreise:</p>
    <h4>Kunden</h4>
    <table style="width: 100%;" class="mat-table" role="table">
        <thead>
        <tr class="mat-header-row">
            <th class="mat-header-cell" style="text-align: left;">Kunde</th>
            <th class="mat-header-cell" style="text-align: left;">Zusatz</th>
            <th class="mat-header-cell" style="text-align: right;">Einzelpreis</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let ka of kundenArtikels" class="mat-row">
            <td class="mat-cell"><a class="kiene-link"
                                    [routerLink]="['/stammdaten/kunden', ka.kunde_id]"
                                    [queryParams]="{tab: '1'}" (click)="closeDialog()">{{ka.kunde}}</a></td>
            <td class="mat-cell">{{ka.zusatz}}</td>
            <td class="mat-cell" style="text-align: right;">{{ka.einzelpreis | number: '1.2-2'}}&euro;</td>
        </tr>
        </tbody>
    </table>
    <h4>Unternehmensgruppen</h4>
    <table style="width: 100%;" class="mat-table" role="table">
        <thead>
        <tr class="mat-header-row">
            <th class="mat-header-cell" style="text-align: left;">Kunde</th>
            <th class="mat-header-cell" style="text-align: right;">Einzelpreis</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let ua of unternehmensgruppenArtikels" class="mat-row">
            <td class="mat-cell"><a class="kiene-link"
                                    [routerLink]="['/stammdaten/unternehmensgruppen', ua.unternehmensgruppe_id]"
                                    [queryParams]="{tab: '3'}" (click)="closeDialog()">{{ua.unternehmensgruppe}}</a>
            </td>
            <td class="mat-cell" style="text-align: right;">{{ua.einzelpreis | number: '1.2-2'}}&euro;</td>
        </tr>
        </tbody>
    </table>
</div>
