import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KieneTableService } from '../../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../../services/message.service';
import { TierwohlprotokollRind } from '../classes/tierwohlprotokoll-rind';
import { Tierarzt } from '../../../../../../projects/kiene-core/src/lib/components/tieraerzte/classes/tierarzt';
import { API_BASE_URL_SERVICE } from '../../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    LocalStorageService
} from '../../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { Client } from '../../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'kiene-tierwohlprotokoll-rind-details',
    templateUrl: './tierwohlprotokoll-rind-details.component.html',
    styleUrls: ['./tierwohlprotokoll-rind-details.component.scss'],
})
export class TierwohlprotokollRindDetailsComponent {
    tierwohlprotokollRind: TierwohlprotokollRind;
    selectedKunde: Client;
    selectedTierarzt: Tierarzt;
    userIstTierarzt = false;
    abrechnenCtrl = new FormControl(true);

    constructor(
        public dialogRef: MatDialogRef<TierwohlprotokollRindDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        private api: KieneTableService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService
    ) {
        if (data?.tierwohlprotokollRind) {
            this.tierwohlprotokollRind = data?.tierwohlprotokollRind;
            this.generateObjsForAutocompletes();
        } else {
            this.tierwohlprotokollRind = new TierwohlprotokollRind();
        }

        if (this.tierwohlprotokollRind.abrechnen === 0) {
            this.abrechnenCtrl.setValue(false);
        } else if (this.tierwohlprotokollRind.abrechnen === 1) {
            this.abrechnenCtrl.setValue(true);
        }
        if (this.tierwohlprotokollRind.abgabebeleg_id) {
            this.abrechnenCtrl.disable();
        }

        if (!this.tierwohlprotokollRind.datum) {
            this.tierwohlprotokollRind.datum = new Date();
        }

        if (localStorageService.getCurrentClient()) {
            this.selectedKunde = localStorageService.getCurrentClient();
            this.setKunde(this.selectedKunde);
        }

        this.userIstTierarzt = localStorageService.isCurrentUserVeterinarian();
        if (this.userIstTierarzt) {
            const session = localStorageService.getCurrentUser();
            this.selectedTierarzt = new Tierarzt();
            this.selectedTierarzt.tierarzt_id = session.tierarzt_id;
            this.selectedTierarzt.vorname = session.vorname;
            this.selectedTierarzt.nachname = session.nachname;
            this.setTierarzt(this.selectedTierarzt);
        }


    }

    save() {
        this.tierwohlprotokollRind.abrechnen = this.abrechnenCtrl.value ? 1 : 0;
        if (this.tierwohlprotokollRind.twp_rind_id) {
            this.api
                .updateEntity(
                    this.apiBaseUrl + 'tierwohlprotokoll_rind/update.php',
                    this.tierwohlprotokollRind
                )
                .subscribe({
                    next: (value) => {
                        this.messageService.infoMessage(
                            'Tierwohlprotokoll wurde erfolgreich aktualisiert.'
                        );
                        this.tierwohlprotokollRind = value;
                        this.dialogRef.close(this.tierwohlprotokollRind);
                    },
                    error: (error) => {
                        this.messageService.errorMessage(
                            'Fehler beim Aktualisieren des Tierwohlprotokolls!',
                            error
                        );
                    },
                });
        } else {
            this.api
                .post(
                    this.apiBaseUrl + 'tierwohlprotokoll_rind/create.php',
                    this.tierwohlprotokollRind
                )
                .subscribe({
                    next: (value) => {
                        this.messageService.infoMessage(
                            'Tierwohlprotokoll wurde erfolgreich erstellt.'
                        );
                        this.tierwohlprotokollRind = value;
                        this.dialogRef.close(this.tierwohlprotokollRind);
                    },
                    error: (error) => {
                        this.messageService.errorMessage(
                            'Fehler beim Erstellen des Tierwohlprotokolls!',
                            error
                        );
                    },
                });
        }
    }

    setKunde(c: Client) {
        this.tierwohlprotokollRind.kunde_id = c.kunde_id;
        this.tierwohlprotokollRind.kunde = c.bezeichnung_final;
        this.tierwohlprotokollRind.kunde_vvvo = c.vvvo;
        this.tierwohlprotokollRind.kunde_strasse = c.strasse_final;
        this.tierwohlprotokollRind.kunde_plz = c.plz_final;
        this.tierwohlprotokollRind.kunde_ort = c.ort_final;
    }

    setTierarzt(t: Tierarzt) {
        this.tierwohlprotokollRind.tierarzt_id = t.tierarzt_id;
        this.tierwohlprotokollRind.tierarzt_vorname = t.vorname;
        this.tierwohlprotokollRind.tierarzt_nachname = t.nachname;
    }

    private generateObjsForAutocompletes() {
        if (this.tierwohlprotokollRind?.kunde_id) {
            this.loadKunde(this.tierwohlprotokollRind?.kunde_id).subscribe({
                next: (value) => {
                    this.selectedKunde = value;
                },
                error: () => {
                    this.selectedKunde = new Client();
                    this.selectedKunde.kunde_id =
                        this.tierwohlprotokollRind.kunde_id;
                    this.selectedKunde.bezeichnung_final =
                        this.tierwohlprotokollRind.kunde;
                    this.selectedKunde.vvvo =
                        this.tierwohlprotokollRind.kunde_vvvo;
                    this.selectedKunde.strasse_final =
                        this.tierwohlprotokollRind.kunde_strasse;
                    this.selectedKunde.plz_final =
                        this.tierwohlprotokollRind.kunde_plz;
                    this.selectedKunde.ort_final =
                        this.tierwohlprotokollRind.kunde_ort;
                },
            });
        }

        if (this.tierwohlprotokollRind?.tierarzt_id) {
            this.loadTierarzt(this.tierwohlprotokollRind.tierarzt_id).subscribe(
                {
                    next: (value) => {
                        this.selectedTierarzt = value;
                    },
                    error: () => {
                        this.selectedTierarzt = new Tierarzt();
                        this.selectedTierarzt.tierarzt_id =
                            this.tierwohlprotokollRind.tierarzt_id;
                        this.selectedTierarzt.vorname =
                            this.tierwohlprotokollRind.tierarzt_vorname;
                        this.selectedTierarzt.nachname =
                            this.tierwohlprotokollRind.tierarzt_nachname;
                    },
                }
            );
        }
    }

    private loadKunde(kunde_id: number) {
        return this.api.getOne(
            this.apiBaseUrl + 'kunde/read_one.php',
            'kunde_id',
            kunde_id.toString()
        );
    }

    private loadTierarzt(tierarzt_id: number) {
        return this.api.getOne(
            this.apiBaseUrl + 'tierarzt/read_one.php',
            'tierarzt_id',
            tierarzt_id.toString()
        );
    }
}
