<mat-tab-group style="padding: 24px;">
    <mat-tab label="Aktuelle">
        <ng-template matTabContent>
            <kiene-gruppen-aktuelle></kiene-gruppen-aktuelle>
        </ng-template>
    </mat-tab>
    <mat-tab label="Historie">
        <ng-template matTabContent>
            <kiene-gruppen-historie></kiene-gruppen-historie>
        </ng-template>
    </mat-tab>
</mat-tab-group>