<h1 mat-dialog-title>Tiere zur Abholliste hinzuf&uuml;gen</h1>
<div mat-dialog-content>
    <div class="info-box">
        <span>Es werden die obersten <strong>{{ anzahlTiereCtrl?.value ? anzahlTiereCtrl.value : 'X' }}</strong> Tiere aus der Tabelle zur Abholliste hinzugef&uuml;gt</span>
        <span class="spacer"></span>
    </div>

    <mat-form-field style="width: 100%;">
        <mat-label>Anzahl Tiere</mat-label>
        <input [formControl]="anzahlTiereCtrl" type="number" matInput
               placeholder="Anzahl der Tiere für die Abholliste"/>
    </mat-form-field>
    <mat-checkbox *ngIf="!nichtInAbholliste" [formControl]="inAbhollisteCtrl">Tiere in einer (nicht abgeschlossenen)
        Abholliste
        einbeziehen
    </mat-checkbox>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" [disabled]="!(anzahlTiereCtrl.value > 0)" (click)="save()">Weiter</button>
</div>
