import { Haendler } from '../../../../../projects/kiene-core/src/lib/components/stammdaten/haendler/haendler';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'kiene-add-haendler-dialog',
  templateUrl: './edit-haendler-dialog.component.html',
  styleUrls: ['./edit-haendler-dialog.component.scss']
})
export class EditHaendlerDialogComponent implements OnInit {

  haendlerPath = 'viehhaendler/read_kundenportal.php';

  haendler: Haendler = new Haendler();

  constructor(public dialogRef: MatDialogRef<EditHaendlerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) { }

  ngOnInit(): void {
    if (this.data) {
      this.haendler = this.data.haendler;
    }
  }

  selectedHaendler(haendler: Haendler) {
    console.debug('haendler selected: ', haendler);
    if (typeof haendler === 'string') {
      this.haendler.name = haendler;
    } else {
      this.haendler = haendler;
    }
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.haendler.favorit = 1;
    this.dialogRef.close(this.haendler);
  }

}
