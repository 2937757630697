import {Component, Inject, OnInit} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MessageService} from '../../../services/message.service';
import {Fortbildung} from '../fortbildung';
import {SaveForAllDialogComponent} from '../../../dialogs/save-for-all-dialog/save-for-all-dialog.component';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {Mandant} from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'kiene-fortbildung-dialog',
    templateUrl: './fortbildung-dialog.component.html',
    styleUrls: ['./fortbildung-dialog.component.css']
})
export class FortbildungDialogComponent implements OnInit {

    availableMandanten: Mandant[] = [];


    edit = false;
    fortbildung: Fortbildung;

    titelCtrl = new UntypedFormControl();
    veranstalterCtrl = new UntypedFormControl();
    veranstaltungsortCtrl = new UntypedFormControl();
    datumCtrl = new UntypedFormControl();
    anmeldefristCtrl = new UntypedFormControl();
    infotextCtrl = new UntypedFormControl();


    constructor(public dialogRef: MatDialogRef<FortbildungDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneTableService,
                private dialog: MatDialog,
                private messageService: MessageService,
                private localStorageService: LocalStorageService) {
        if (this.data.fortbildung) {
            this.fortbildung = this.data.fortbildung;
            this.edit = true;
        }
    }

    ngOnInit(): void {
        if (this.fortbildung) {
            this.titelCtrl.setValue(this.fortbildung.titel);
            this.veranstalterCtrl.setValue(this.fortbildung.veranstalter);
            this.veranstaltungsortCtrl.setValue(this.fortbildung.veranstaltungsort);
            this.datumCtrl.setValue(this.fortbildung.datum);
            this.anmeldefristCtrl.setValue(this.fortbildung.anmeldefrist);
            this.infotextCtrl.setValue(this.fortbildung.infotext);
        } else {
            this.fortbildung = new Fortbildung();
        }
        this.loadMandanten();
    }

    loadMandanten() {
        // (this.localStorageService.getCurrentMandant().mandant_id === 1) &&
        if (this.localStorageService.currentUserHasPermission(1005)) {
            let params = new HttpParams();
            if (this.fortbildung.fortbildung_id) {
                params = params.append('fortbildung_id', this.fortbildung.fortbildung_id.toString());
            }
            this.api.get(this.apiBaseUrl + 'fortbildung/read_mandanten.php', params).subscribe(
                response => {
                    this.availableMandanten = response.records;
                },
                error => {
                    this.messageService.errorMessage('Fehler beim Laden der verfügbaren Mandanten', error);
                }
            );
        }
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        this.fortbildung.titel = this.titelCtrl.value;
        this.fortbildung.veranstalter = this.veranstalterCtrl.value;
        this.fortbildung.veranstaltungsort = this.veranstaltungsortCtrl.value;
        this.fortbildung.datum = this.datumCtrl.value;
        this.fortbildung.anmeldefrist = this.anmeldefristCtrl.value;
        this.fortbildung.infotext = this.infotextCtrl.value;

        if (this.availableMandanten.length > 1) { // Array enthält nur Daten mit entspr. Berechtigung und bei Kiene
            let dialogRef = this.dialog.open(SaveForAllDialogComponent, {
                data: {
                    availableMandanten: this.availableMandanten
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.fortbildung.mandanten_ids = result;
                    this.saveFortbildung();
                }
            });
        } else {
            this.saveFortbildung();
        }


    }

    saveFortbildung() {
        this.api.updateEntity(this.apiBaseUrl + 'fortbildung/update.php', this.fortbildung).subscribe(response => {
            this.messageService.infoMessage('Fortbildung erfolgreich gespeichert!');
            this.close();
        }, error => {
            this.messageService.errorMessage('Die Fortbildung konnte nicht gespeichert werden!', error);
        });
    }

}
