<h1 mat-dialog-title>Betriebe {{user?.vorname}}&nbsp;{{user?.nachname}} zuweisen</h1>
<div mat-dialog-content>
    <div *ngIf="!isLoading">

        <div *ngFor="let b of vvvos; let i = index;" style="margin-bottom: 12px; border-bottom: 1px solid gray;">
            <div class="one-row">
                <mat-checkbox style="margin-right: 12px;" [formControl]="selectedCtrls[b.vvvo]"
                              (change)="betriebChanged($event, b)"></mat-checkbox>
                <mat-icon>business</mat-icon>
                <span style="margin-left: 12px; margin-right: 12px;"><strong>{{b.bezeichnung_final}}</strong></span>
                <span class="spacer"></span>
            </div>
            <p style="margin-left: 40px;">{{b.zusatz_final}}</p>
            <p style="margin-left: 40px;">{{b.vvvo}}</p>

        </div>
    </div>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="dialogRef.close()">Schlie&szlig;en</button>
</div>
