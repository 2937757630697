import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Supplier } from '../../../warehouse/supplier/supplier';
import { Landkreis } from '../landkreis';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'kiene-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})
export class EditLandkreisDialogComponent implements OnInit {

  landkreis = new Landkreis();

  vetAmt = new Supplier();
  ueberwachungsbehoerde = new Supplier();

  constructor(public dialogRef: MatDialogRef<EditLandkreisDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
    private api: KieneTableService,
    private notification: MessageService) {
    this.landkreis = data.landkreis;
  }

  ngOnInit() {
    this.loadVetAmt();
    this.loadUeberwachungsbehoerde();
  }

  loadVetAmt() {
    if (this.landkreis.lieferant_id_veterinaeramt) {
      this.api.getOne(environment.BASE_URI + 'lieferant/read_one.php', 'lieferant_id', this.landkreis.lieferant_id_veterinaeramt.toString()).subscribe(response => {
        this.vetAmt = response;
      });
    }

  }

  setVetAmt(supplier: Supplier) {
    this.landkreis.lieferant_id_veterinaeramt = supplier.lieferant_id;
  }

  loadUeberwachungsbehoerde() {
    if (this.landkreis.lieferant_id_ueberwachungsbehoerde) {
      this.api.getOne(environment.BASE_URI + 'lieferant/read_one.php', 'lieferant_id', this.landkreis.lieferant_id_ueberwachungsbehoerde.toString()).subscribe(response => {
        this.ueberwachungsbehoerde = response;
      });
    }

  }

  setUeberwachungsbehoerde(supplier: Supplier) {
    this.landkreis.lieferant_id_ueberwachungsbehoerde = supplier.lieferant_id;
  }

  save() {
    this.api.updateEntity(environment.BASE_URI + 'landkreis/update.php', this.landkreis).subscribe(response => {
      this.dialogRef.close();
    }, error => {
      this.notification.errorMessage('', error);
    });
  }

}
