import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'kiene-gruppe-tier-hilfe-dialog',
  templateUrl: './gruppe-tier-hilfe-dialog.component.html',
  styleUrls: ['./gruppe-tier-hilfe-dialog.component.scss']
})
export class GruppeTierHilfeDialogComponent {

  constructor(
      public dialogRef: MatDialogRef<GruppeTierHilfeDialogComponent>,
  ) { }
}
