import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityViewerComponent } from './entity-viewer/entity-viewer.component';
import { EntityViewerService } from './entity-viewer.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [EntityViewerComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule
  ], exports: [
    EntityViewerComponent
  ]
})
export class EntityViewerModule { }
