import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {KienePatternUtils} from '../../../../utils/KienePatternUtils';
import {StammdatenService} from '../../stammdaten.service';
import {Betriebsart} from '../classes/betriebsart';
import {Client} from '../../../client/classes/client';
import {MessageService} from '../../../../services/message-service/message.service';
import {Subscription} from 'rxjs';
import {VetVetRegistrationDTO} from '../../../../hit/hit-registrierung/classes/VetVetRegistrationDTO';

@Component({
    selector: 'kiene-vvvo-basis-infos-form',
    templateUrl: './vvvo-basis-infos-form.component.html',
    styleUrls: ['./vvvo-basis-infos-form.component.scss'],
})
export class VvvoBasisInfosFormComponent implements OnInit, OnChanges, OnDestroy {

    betriebsartenSubscription: Subscription;

    @Input('kunde')
    kunde: Client;

    @Output('emailChanged')
    emailChanged: EventEmitter<string> = new EventEmitter();

    @Input('vetVetRegistrationDTO')
    dto: VetVetRegistrationDTO;
    betriebsarten: Betriebsart[] = [];
    betriebsartCtrl: UntypedFormControl = new UntypedFormControl(null, Validators.required);

    emailCtrl = new UntypedFormControl('', [Validators.required]);
    strasseCtrl = new UntypedFormControl('', [Validators.required]);
    plzCtrl = new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(KienePatternUtils.PLZ()),
    ]);
    ortCtrl = new UntypedFormControl('', [Validators.required]);

    ortsteilCtrl = new UntypedFormControl('');
    telefon1Ctrl = new UntypedFormControl('');
    telefon2Ctrl = new UntypedFormControl('');
    mobil1telefonCtrl = new UntypedFormControl('');
    bezeichnungCtrl = new UntypedFormControl('', [Validators.required]);
    firmenadresszusatzCtrl = new UntypedFormControl('');
    mobil2telefonCtrl = new UntypedFormControl('');
    vvvoCtrl = new UntypedFormControl({value: '', disabled: true}, [
        Validators.required,
    ]);
    sgsCtrl = new UntypedFormControl('');
    tskCtrl = new UntypedFormControl('');
    faxCtrl = new UntypedFormControl('');
    zusatzCtrl = new UntypedFormControl('', []);

    // Tierzahlen
    tierzahlSauenCtrl = new UntypedFormControl('');
    tierzahlSaugferkelCtrl = new UntypedFormControl('');
    tierzahlAbsatzferkelCtrl = new UntypedFormControl('');
    tierzahlMastschweineCtrl = new UntypedFormControl('');
    tierzahlKueheCtrl = new UntypedFormControl('');
    tierzahlKaelberCtrl = new UntypedFormControl('');
    tierzahlFresserCtrl = new UntypedFormControl('');
    tierzahlBullenCtrl = new UntypedFormControl('');
    tierzahlMastrinderCtrl = new UntypedFormControl('');


    basisInfos = new UntypedFormGroup({
        email: this.emailCtrl,
        strasse: this.strasseCtrl,
        plz: this.plzCtrl,
        ort: this.ortCtrl,
        ortsteil: this.ortsteilCtrl,
        telefon1: this.telefon1Ctrl,
        telefon2: this.telefon2Ctrl,
        mobil1telefon: this.mobil1telefonCtrl,
        bezeichnung: this.bezeichnungCtrl,
        firmenadresszusatz: this.firmenadresszusatzCtrl,
        mobil2telefon: this.mobil2telefonCtrl,
        vvvo: this.vvvoCtrl,
        sgs: this.sgsCtrl,
        tsk: this.tskCtrl,
        fax: this.faxCtrl,
        zusatz: this.zusatzCtrl,
        tierzahlSauenCtrl: this.tierzahlSauenCtrl,
        tierzahlSaugferkelCtrl: this.tierzahlSaugferkelCtrl,
        tierzahlAbsatzferkelCtrl: this.tierzahlAbsatzferkelCtrl,
        tierzahlMastschweineCtrl: this.tierzahlMastschweineCtrl,
        tierzahlKueheCtrl: this.tierzahlKueheCtrl,
        tierzahlKaelberCtrl: this.tierzahlKaelberCtrl,
        tierzahlFresserCtrl: this.tierzahlFresserCtrl,
        tierzahlBullenCtrl: this.tierzahlBullenCtrl,
        tierzahlMastrinderCtrl: this.tierzahlMastrinderCtrl
    });

    constructor(private stammdatenService: StammdatenService, private messageService: MessageService,
                private el: ElementRef) {
    }

    ngOnDestroy(): void {
        this.betriebsartenSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        if (this.kunde) {
            this.setKundeInCtrls();
            this.initCtrls();
        }
        if (this.dto) {
            this.setDTOinControls();
            this.initDTOCtrls();
        }
        this.loadBetriebsarten();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.kunde) {
            this.setKundeInCtrls();
            this.initCtrls();
        }
        if (this.dto) {
            this.setDTOinControls();
            this.initDTOCtrls();
        }
    }

    initCtrls() {
        this.emailCtrl.valueChanges.subscribe((value) => {
            this.kunde.email = value;
            this.emailChanged.emit(value);
        });
        this.strasseCtrl.valueChanges.subscribe((value) => {
            this.kunde.strasse = value;
        });
        this.plzCtrl.valueChanges.subscribe((value) => {
            this.kunde.plz = value;
        });
        this.ortCtrl.valueChanges.subscribe((value) => {
            this.kunde.ort = value;
        });
        this.ortsteilCtrl.valueChanges.subscribe((value) => {
            this.kunde.ortsteil = value;
        });
        this.telefon1Ctrl.valueChanges.subscribe((value) => {
            this.kunde.telefon_1 = value;
        });
        this.telefon2Ctrl.valueChanges.subscribe((value) => {
            this.kunde.telefon_2 = value;
        });
        this.mobil1telefonCtrl.valueChanges.subscribe((value) => {
            this.kunde.mobil_1 = value;
        });
        this.mobil2telefonCtrl.valueChanges.subscribe((value) => {
            this.kunde.mobil_2 = value;
        });
        this.bezeichnungCtrl.valueChanges.subscribe((value) => {
            this.kunde.bezeichnung = value;
        });
        this.firmenadresszusatzCtrl.valueChanges.subscribe((value) => {
            this.kunde.zusatz = value;
        });
        this.vvvoCtrl.valueChanges.subscribe((value) => {
            this.kunde.vvvo = value;
        });
        this.sgsCtrl.valueChanges.subscribe((value) => {
            this.kunde.sgs_nummer = value;
        });
        this.tskCtrl.valueChanges.subscribe((value) => {
            this.kunde.tsk_nummer = value;
        });
        this.faxCtrl.valueChanges.subscribe((value) => {
            this.kunde.fax = value;
        });

        this.betriebsartCtrl.valueChanges.subscribe((value) => {
            this.kunde.betriebsart_id = value.betriebsart_id;
            this.kunde.betriebsart = value.betriebsart;
        });

        this.tierzahlSauenCtrl.valueChanges.subscribe((value) => {
            this.kunde.tierzahl_sauen = value;
        });
        this.tierzahlSaugferkelCtrl.valueChanges.subscribe((value) => {
            this.kunde.tierzahl_saugferkel = value;
        });
        this.tierzahlAbsatzferkelCtrl.valueChanges.subscribe((value) => {
            this.kunde.tierzahl_absatzferkel = value;
        });
        this.tierzahlMastschweineCtrl.valueChanges.subscribe((value) => {
            this.kunde.tierzahl_mastschweine = value;
        });
        this.tierzahlKueheCtrl.valueChanges.subscribe((value) => {
            this.kunde.tierzahl_kuehe = value;
        });
        this.tierzahlKaelberCtrl.valueChanges.subscribe((value) => {
            this.kunde.tierzahl_kaelber = value;
        });
        this.tierzahlFresserCtrl.valueChanges.subscribe((value) => {
            this.kunde.tierzahl_fresser = value;
        });
        this.tierzahlBullenCtrl.valueChanges.subscribe((value) => {
            this.kunde.tierzahl_bullen = value;
        });
        this.tierzahlMastrinderCtrl.valueChanges.subscribe((value) => {
            this.kunde.tierzahl_mastrinder = value;
        });

    }

    setKundeInCtrls() {
        this.emailCtrl.setValue(this.kunde.email);
        if (this.kunde.email) {
            this.emailChanged.emit(this.emailCtrl.value);
        }
        this.strasseCtrl.setValue(this.kunde.strasse);
        this.plzCtrl.setValue(this.kunde.plz);
        this.ortCtrl.setValue(this.kunde.ort);
        this.ortsteilCtrl.setValue(this.kunde.ortsteil);
        this.telefon1Ctrl.setValue(this.kunde.telefon_1);
        this.telefon2Ctrl.setValue(this.kunde.telefon_2);
        this.mobil1telefonCtrl.setValue(this.kunde.mobil_1);
        this.mobil2telefonCtrl.setValue(this.kunde.mobil_2);
        this.bezeichnungCtrl.setValue(this.kunde.bezeichnung);
        this.firmenadresszusatzCtrl.setValue(this.kunde.zusatz);
        this.vvvoCtrl.setValue(this.kunde.vvvo);
        this.sgsCtrl.setValue(this.kunde.sgs_nummer);
        this.tskCtrl.setValue(this.kunde.tsk_nummer);
        this.faxCtrl.setValue(this.kunde.fax);
        if (this.kunde?.betriebsart && this.kunde?.betriebsart_id) {
            const ba = new Betriebsart();
            ba.betriebsart_id = this.kunde.betriebsart_id;
            ba.bezeichnung = this.kunde.betriebsart;
            this.betriebsartCtrl.setValue(ba);
        } else {
            this.betriebsartCtrl.setErrors({'required': true});
            this.betriebsartCtrl.markAsTouched();
        }

        this.tierzahlSauenCtrl.setValue(this.kunde.tierzahl_sauen);
        this.tierzahlSaugferkelCtrl.setValue(this.kunde.tierzahl_saugferkel);
        this.tierzahlAbsatzferkelCtrl.setValue(this.kunde.tierzahl_absatzferkel);
        this.tierzahlMastschweineCtrl.setValue(this.kunde.tierzahl_mastschweine);
        this.tierzahlKueheCtrl.setValue(this.kunde.tierzahl_kuehe);
        this.tierzahlKaelberCtrl.setValue(this.kunde.tierzahl_kaelber);
        this.tierzahlFresserCtrl.setValue(this.kunde.tierzahl_fresser);
        this.tierzahlBullenCtrl.setValue(this.kunde.tierzahl_bullen);
        this.tierzahlMastrinderCtrl.setValue(this.kunde.tierzahl_mastrinder);


    }

    public areCtrlsValid() {
        this.basisInfos?.markAllAsTouched();
        return this.basisInfos && this.basisInfos?.valid;
    }

    public compareBetriebsarten(b1: Betriebsart, b2: Betriebsart) {
        return b1 && b2 && b1.betriebsart_id === b2.betriebsart_id;
    }

    private setDTOinControls() {
        this.emailCtrl.setValue(this.dto.email);
        if (this.dto.email) {
            this.emailChanged.emit(this.emailCtrl.value);
        }
        this.strasseCtrl.setValue(this.dto.strasse);
        this.plzCtrl.setValue(this.dto.plz);
        this.ortCtrl.setValue(this.dto.ort);
        this.ortsteilCtrl.setValue(this.dto.ortsteil);
        this.telefon1Ctrl.setValue(this.dto.telefon_1);
        this.telefon2Ctrl.setValue(this.dto.telefon_2);
        this.mobil1telefonCtrl.setValue(this.dto.mobil_1);
        this.mobil2telefonCtrl.setValue(this.dto.mobil_2);
        this.bezeichnungCtrl.setValue(this.dto.bezeichnung);
        this.firmenadresszusatzCtrl.setValue(this.dto.zusatz);
        this.vvvoCtrl.setValue(this.dto.vvvo);
        this.sgsCtrl.setValue(this.dto.sgs_nummer);
        this.tskCtrl.setValue(this.dto.tsk_nummer);
        this.faxCtrl.setValue(this.dto.fax);
        if (this.dto?.betriebsart && this.dto?.betriebsart_id) {
            const ba = new Betriebsart();
            ba.betriebsart_id = this.dto.betriebsart_id;
            ba.bezeichnung = this.dto.betriebsart;
            this.betriebsartCtrl.setValue(ba);
        } else {
            this.betriebsartCtrl.setErrors({'required': true});
            this.betriebsartCtrl.markAsTouched();
        }

        this.tierzahlSauenCtrl.setValue(this.dto.tierzahl_sauen);
        this.tierzahlSaugferkelCtrl.setValue(this.dto.tierzahl_saugferkel);
        this.tierzahlAbsatzferkelCtrl.setValue(this.dto.tierzahl_absatzferkel);
        this.tierzahlMastschweineCtrl.setValue(this.dto.tierzahl_mastschweine);
        this.tierzahlKueheCtrl.setValue(this.dto.tierzahl_kuehe);
        this.tierzahlKaelberCtrl.setValue(this.dto.tierzahl_kaelber);
        this.tierzahlFresserCtrl.setValue(this.dto.tierzahl_fresser);
        this.tierzahlBullenCtrl.setValue(this.dto.tierzahl_bullen);
        this.tierzahlMastrinderCtrl.setValue(this.dto.tierzahl_mastrinder);
    }

    private initDTOCtrls() {
        this.emailCtrl.valueChanges.subscribe((value) => {
            this.dto.email = value;
            this.emailChanged.emit(value);
        });
        this.strasseCtrl.valueChanges.subscribe((value) => {
            this.dto.strasse = value;
        });
        this.plzCtrl.valueChanges.subscribe((value) => {
            this.dto.plz = value;
        });
        this.ortCtrl.valueChanges.subscribe((value) => {
            this.dto.ort = value;
        });
        this.ortsteilCtrl.valueChanges.subscribe((value) => {
            this.dto.ortsteil = value;
        });
        this.telefon1Ctrl.valueChanges.subscribe((value) => {
            this.dto.telefon_1 = value;
        });
        this.telefon2Ctrl.valueChanges.subscribe((value) => {
            this.dto.telefon_2 = value;
        });
        this.mobil1telefonCtrl.valueChanges.subscribe((value) => {
            this.dto.mobil_1 = value;
        });
        this.mobil2telefonCtrl.valueChanges.subscribe((value) => {
            this.dto.mobil_2 = value;
        });
        this.bezeichnungCtrl.valueChanges.subscribe((value) => {
            this.dto.bezeichnung = value;
        });
        this.firmenadresszusatzCtrl.valueChanges.subscribe((value) => {
            this.dto.zusatz = value;
        });
        this.vvvoCtrl.valueChanges.subscribe((value) => {
            this.dto.vvvo = value;
        });
        this.sgsCtrl.valueChanges.subscribe((value) => {
            this.dto.sgs_nummer = value;
        });
        this.tskCtrl.valueChanges.subscribe((value) => {
            this.dto.tsk_nummer = value;
        });
        this.faxCtrl.valueChanges.subscribe((value) => {
            this.dto.fax = value;
        });

        this.betriebsartCtrl.valueChanges.subscribe((value) => {
            this.dto.betriebsart_id = value.betriebsart_id;
            this.dto.betriebsart = value.betriebsart;
        });

        this.tierzahlSauenCtrl.valueChanges.subscribe((value) => {
            this.dto.tierzahl_sauen = value;
        });
        this.tierzahlSaugferkelCtrl.valueChanges.subscribe((value) => {
            this.dto.tierzahl_saugferkel = value;
        });
        this.tierzahlAbsatzferkelCtrl.valueChanges.subscribe((value) => {
            this.dto.tierzahl_absatzferkel = value;
        });
        this.tierzahlMastschweineCtrl.valueChanges.subscribe((value) => {
            this.dto.tierzahl_mastschweine = value;
        });
        this.tierzahlKueheCtrl.valueChanges.subscribe((value) => {
            this.dto.tierzahl_kuehe = value;
        });
        this.tierzahlKaelberCtrl.valueChanges.subscribe((value) => {
            this.dto.tierzahl_kaelber = value;
        });
        this.tierzahlFresserCtrl.valueChanges.subscribe((value) => {
            this.dto.tierzahl_fresser = value;
        });
        this.tierzahlBullenCtrl.valueChanges.subscribe((value) => {
            this.dto.tierzahl_bullen = value;
        });
        this.tierzahlMastrinderCtrl.valueChanges.subscribe((value) => {
            this.dto.tierzahl_mastrinder = value;
        });
    }

    private loadBetriebsarten() {
        this.betriebsartenSubscription?.unsubscribe();
        this.betriebsartenSubscription = this.stammdatenService.loadBetriebsarten().subscribe({
            next: (response) => {
                this.betriebsarten = response.records;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}
