import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Vet, VetGroupVet} from '../vet';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {environment} from '../../../../environments/environment';
import {HttpParams} from '@angular/common/http';
import {VetGroup} from '../../vet-group/vet-group';
import {MessageService} from '../../../services/message.service';

@Component({
    selector: 'kiene-vet-vet-group-details-dialog',
    templateUrl: './vet-vet-group-details-dialog.component.html',
    styleUrls: ['./vet-vet-group-details-dialog.component.scss']
})
export class VetVetGroupDetailsDialogComponent implements OnInit {

    tierarzt: Vet;
    myVetGroups: VetGroupVet[] = [];

    constructor(public dialogRef: MatDialogRef<VetVetGroupDetailsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private api: KieneTableService,
                private notification: MessageService) {
        this.tierarzt = data.tierarzt;
    }

    ngOnInit() {
        this.loadVetGroupsForVet();
    }

    loadVetGroupsForVet() {
        if (this.tierarzt) {
            const params = new HttpParams().append('tierarzt_id', this.tierarzt.tierarzt_id.toString());
            this.api.get(environment.BASE_URI + 'tierarztgruppe_tierarzt/read.php', params).subscribe(response => {
                this.myVetGroups = response.records;
            });
        }
    }

    setVetGroup(vg: VetGroup) {
        const vgv = new VetGroupVet();
        vgv.tierarztgruppe_id = vg.tierarztgruppe_id;
        vgv.tierarztgruppe = vg.bezeichnung;
        vgv.tierarzt_id = this.tierarzt.tierarzt_id;
        this.myVetGroups.push(vgv);
        this.saveVetGroupVet(vgv);
    }

    saveVetGroupVet(vgv: VetGroupVet) {
        this.api.post(environment.BASE_URI + 'tierarztgruppe_tierarzt/create.php', vgv, null).subscribe(response => {
            this.loadVetGroupsForVet();
            this.notification.infoMessage('Tierarztgruppe wurde erfolgreich hinzugefügt.');
        }, error => {
            this.notification.errorMessage('Fehler: Tierarztgruppe konnte nicht hinzugefügt werden!', error);
        });
    }

    removeVetGroupFromVet(vgv: VetGroupVet) {
        this.api.deleteEntity(environment.BASE_URI + 'tierarztgruppe_tierarzt/delete.php', vgv).subscribe(response => {
            this.loadVetGroupsForVet();
            this.notification.infoMessage('Tierarztgruppe wurde erfolgreich entfernt.');
        }, error => {
            this.notification.errorMessage('Fehler: Tierarztgruppe konnte nicht entfernt werden!', error);
        });
    }

    cancel() {
        this.dialogRef.close();
    }

}
