import { Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RinderpassScan } from '../../classes/rinderpass-scan';
import { API_BASE_URL_SERVICE } from '../../../../kiene-core.config';
import { KieneBackendApiService } from '../../../../services/backend-api/kiene-backend-api.service';
import { HttpParams } from '@angular/common/http';
import { FehlerhafteSeite } from './fehlerhafte-seite';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MessageService } from '../../../../services/message-service/message.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'kiene-fehlerhafte-seiten-dialog',
    templateUrl: './fehlerhafte-seiten-dialog.component.html',
    styleUrls: ['./fehlerhafte-seiten-dialog.component.scss']
})
export class FehlerhafteSeitenDialogComponent implements OnInit {

    @ViewChildren('zifferInput') zifferInput: QueryList<ElementRef>;

    fehlerhafteSeiten: FehlerhafteSeite[] = [];
    aktuelleSeite: FehlerhafteSeite;
    pdfAvailable = false;

    zifferCtrl: FormControl[] = [];
    allesKorrekt = false;

    pdfBase64: SafeResourceUrl;
    ohrmarkeCtrl = new FormControl(undefined, [Validators.required, Validators.minLength(10), Validators.maxLength(15)]);

    constructor(@Inject(MAT_DIALOG_DATA) private scan: RinderpassScan,
        public dialogRef: MatDialogRef<FehlerhafteSeitenDialogComponent>,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private sanatizer: DomSanitizer,) {
    }

    ngOnInit(): void {
        for (let i = 0; i < 10; i++) {
            this.zifferCtrl[i] = new FormControl(undefined, [Validators.required, Validators.maxLength(1), Validators.minLength(1)]);
            if (i === 0) {
                this.zifferCtrl[i].valueChanges.subscribe(value => {
                    if (value.length > 0) {
                        this.zifferInput.get(i + 1).nativeElement?.focus();
                    }
                    this.istAllesKorrekt();
                });
            } else if (i < 9 && i > 0) {
                this.zifferCtrl[i].valueChanges.subscribe(value => {
                    if (value.length > 0) {
                        this.zifferInput.get(i + 1).nativeElement?.focus();
                    } else {
                        this.zifferInput.get(i - 1).nativeElement?.focus();
                    }
                    this.istAllesKorrekt();
                });

            } else {
                this.zifferCtrl[i].valueChanges.subscribe(value => {
                    if (value.length === 0) {
                        this.zifferInput.get(i - 1).nativeElement?.focus();
                    }
                    this.istAllesKorrekt();
                });
            }

        }
        this.ladeFehlerhafteSeitenFuerScan();
    }

    public ladeSeite(seitenzahl: number) {
        for (const fc of this.zifferCtrl) {
            fc.setValue(undefined, { emitEvent: false });
        }
        this.aktuelleSeite = this.fehlerhafteSeiten[seitenzahl];
        let params = new HttpParams();
        params = params.set('grp_id', this.aktuelleSeite.grp_id);
        this.pdfAvailable = false;
        this.api.getPdfBase64(this.apiBaseUrl + 'rinderpass/read_pdf.php', params).subscribe({
            next: response => {
                this.pdfBase64 =
                    this.sanatizer.bypassSecurityTrustResourceUrl(
                        'data:application/pdf;base64,' + response
                    );
                this.pdfAvailable = true;
            },
            error: err => {
                this.pdfAvailable = true;
                this.messageService.errorMessage('Fehler', err);
            }
        });
    }

    ohrmarkeNachtragen() {
        let ohrmarke = '27600';
        for (let i = 0; i < this.zifferCtrl.length; i++) {
            ohrmarke += this.zifferCtrl[i].value;
        }
        const obj = {
            grp_id: this.aktuelleSeite.grp_id,
            ohrmarke15: ohrmarke
        };
        this.api.post(this.apiBaseUrl + 'rinderpass/update.php', obj).subscribe({
            next: () => {
                this.messageService.infoMessage('Die Ohrmarke wurde erfolgreich nachgetragen');
                this.ladeFehlerhafteSeitenFuerScan();
            },
            error: err => {
                this.messageService.errorMessage('Fehler', err);
            }
        });
    }

    private ladeFehlerhafteSeitenFuerScan() {
        let params = new HttpParams();
        params = params.set('grps_id', this.scan.grps_id);
        params = params.set('erfolgreich', '0');
        params = params.set('pdf', '1');

        this.api.get(this.apiBaseUrl + 'rinderpass/read.php', params).subscribe({
            next: response => {
                this.fehlerhafteSeiten = response.records;
                if (this.fehlerhafteSeiten.length === 0) {
                    this.messageService.infoMessage('Es sind keine fehlerhaften Seiten mehr verfügbar!');
                    this.dialogRef.close();
                }
                this.fehlerhafteSeiten.sort((a, b) => {
                    if (a.seitenzahl < b.seitenzahl) {
                        return -1;
                    } else if (a.seitenzahl > b.seitenzahl) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                this.ladeErsteSeite();
            }
        });
    }

    private ladeErsteSeite() {
        if (this.fehlerhafteSeiten?.length > 0) {
            this.ladeSeite(0);
        }
    }

    private istAllesKorrekt() {
        let korrekt = true;
        for (const fc of this.zifferCtrl) {
            if (!fc.valid) {
                korrekt = false;
            }
        }
        this.allesKorrekt = korrekt;
    }
}
