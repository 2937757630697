import {NgModule} from '@angular/core';
import {MatDialogModule} from "@angular/material/dialog";
import {MatTableModule} from '@angular/material/table';
import {KieneTableComponent} from './kiene-table.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {FilterComponent} from './filter/filter.component';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DialogsModule} from '../../dialogs/dialogs.module';
import {RouterModule} from '@angular/router';
import {EntityViewerModule} from '../entity-viewer/entity-viewer.module';
import {LayoutModule} from '@angular/cdk/layout';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {OhrmarkeModule} from '../../../../projects/kiene-core/src/lib/ohrmarke/ohrmarke.module';
import {MatChipsModule} from '@angular/material/chips';
import {MatBadgeModule} from '@angular/material/badge';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from '../components.module';
import {KieneDirectivesModule} from '../../directives/kiene-directives.module';
import {MatDividerModule} from '@angular/material/divider';
import {DirectivesModule} from '../../../../projects/kiene-core/src/lib/directives/directives.module';
import { ColumnFilterDialogComponent } from './column-filter-dialog/column-filter-dialog.component';


@NgModule({
    declarations: [
        KieneTableComponent,
        FilterComponent,
        ColumnFilterDialogComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MatInputModule,
        MatTableModule,
        MatCheckboxModule,
        MatButtonModule,
        MatIconModule,
        MatFormFieldModule,
        MatPaginatorModule,
        MatSortModule,
        MatCardModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        DialogsModule,
        RouterModule,
        EntityViewerModule,
        LayoutModule,
        DragDropModule,
        MatSlideToggleModule,
        OhrmarkeModule,
        MatChipsModule,
        MatBadgeModule,
        ComponentsModule,
        KieneDirectivesModule,
        MatDividerModule,
        DirectivesModule,
        MatDialogModule
    ],
    exports: [
        KieneTableComponent,
        FilterComponent
    ]
})
export class KieneTableModule {
}
