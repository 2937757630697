import {Tierart} from '../../../administration/tierarten/tierart';

export class Indikation {
    indikation_id: number;
    diagnose: string;
    artikel_id: number;
    artikel: string;
    tierart_id: number;
    tierart: string;
    erstellt: Date;
    erstellt_von: number;
    editiert: Date;
    editiert_von: number;
    mandanten_ids: number[] = [];
    tierarten: Tierart[] = [];
    sortierung: number;
    sonderfall?: IndikationSonderfall;
}

export class IndikationApi {
    records: Indikation[] = [];
    count: number;
    recordcount: number;
}

export class IndikationEvent {
    indikation: Indikation;
    ladePosition: boolean = false;

    constructor(indikation: Indikation, options?: { ladePosition?: boolean }) {
        this.indikation = indikation;
        if (options?.ladePosition === true) {
            this.ladePosition = true;
        }
    }
}

export class IndikationSonderfall {
    wartezeit_fleisch: number;
    wartezeit_milch: number;
    wartezeit_eier: number;
    kommentar: string;
    menge: number;
    einheit_id: number;
    behandlung_tage: number;
    intervall_std: number;
    faktor: number;
    koerpergewicht: number;
    dosierung_txt: string;
}
