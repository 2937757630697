import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BestellungIntern, BestellungInternApi } from './bestellung-intern';
import { BestellungInternPositionApi } from './bestellung-intern-position';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable({
    providedIn: 'root',
})
export class BestellungInternServiceService {
    constructor(private http: HttpClient,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    getById(id: number): Observable<BestellungIntern> {
        return this.http.get<BestellungIntern>(
            this.apiBaseUrl + 'bestellung/read_one.php?bestellung_id=' + id
        );
    }

    getPositionen(
        bestellungInternId: number
    ): Observable<BestellungInternPositionApi> {
        return this.http.get<BestellungInternPositionApi>(
            this.apiBaseUrl +
            'bestellung_artikel/read.php?bestellung_id=' +
            bestellungInternId
        );
    }

    save(bi: BestellungIntern): Observable<BestellungIntern> {
        return this.http.post<BestellungIntern>(
            this.apiBaseUrl + 'bestellung/update.php',
            bi
        );
    }

    bookPicking(
        bestellungId: number,
        artikelId: number,
        amount: number,
        chargeName: string,
        lagerplatzId: number,
        ba_id: number
    ): Observable<BestellungInternPositionApi> {
        const obj = {
            bestellung_id: bestellungId,
            artikel_id: artikelId,
            chargenmenge: amount,
            charge: chargeName,
            lagerplatz_id_quelle: lagerplatzId,
            ba_id: ba_id,
        };

        return this.http.post<BestellungInternPositionApi>(
            this.apiBaseUrl + 'bestellung_artikel/pick.php',
            obj
        );
    }
}
