import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
    TierRasse,
    TierRasseOutput,
    TierRasseSonstige
} from "../../../../../../projects/kiene-core/src/lib/components/gruppe-tier/classes/tier-rasse";
import {
    KieneBackendApiService
} from "../../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service";
import {environment} from "../../../../../environments/environment";
import {Tierart} from "../../../../administration/tierarten/tierart";
import {Heimtier} from "../../../../heimtiere/kalender/heimtier-kalender-eintrag";
import {MessageService} from "../../../../services/message.service";
import {TierartSonstigeDialogComponent} from "./tierart-sonstige-dialog/tierart-sonstige-dialog.component";

@Component({
    selector: 'kiene-sonstige-rassen-dialog',
    templateUrl: './sonstige-rassen-dialog.component.html',
    styleUrls: ['./sonstige-rassen-dialog.component.scss']
})
export class SonstigeRassenDialogComponent implements OnInit {
    public tierarten: Tierart[] = [];
    rasse_name_ctrl = new UntypedFormControl('', Validators.required);
    tierartCtrl: UntypedFormControl = new UntypedFormControl(null, Validators.required);

    constructor(
        public dialogRef: MatDialogRef<SonstigeRassenDialogComponent>,
        private messageService: MessageService,
        private api: KieneBackendApiService,
        @Inject(MAT_DIALOG_DATA) public data: Heimtier,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        console.log('Tierart:', this.data.name);

        const tierRasseSonstige = new TierRasseSonstige();
        tierRasseSonstige.name = this.data.tierart;
        tierRasseSonstige.tierart_id = this.data.tierart_id;
        this.tierartCtrl.setValue(this.data);
    }

    cancel() {
        this.dialogRef.close(null);
    }

    public save() {
        // if ( !this.tierartCtrl.value || !this.tierartCtrl.value.tierart_id ) {
        //     this.messageService.alertMessage( 'Ungültige Tierart! Bitte stellen Sie sicher, dass eine gültige Tierart ausgewählt wurde.' );
        //     return;
        // }

        const tierrasse = new TierRasseSonstige();
        tierrasse.tierart_id = this.data.tierart_id;
        tierrasse.rasse = this.rasse_name_ctrl.value;
        tierrasse.name = this.rasse_name_ctrl.value;

        console.log('Tierrasse zum Speichern:', tierrasse);
        this.api.post(environment.BASE_URI + 'tierrasse/update.php', tierrasse).subscribe({
            next: (savedTierart: TierRasse) => {
                const output = new TierRasseOutput();
                output.rasse = savedTierart;
                output.confirm = true;
                this.dialogRef.close(savedTierart);
            },
            error: error => {
                this.messageService.errorMessage('Fehler! Die Tierrasse ' + tierrasse.name + ' konnte nicht gespeichert werden!', error);
            }
        });
    }

    public tierartHinzufuegen(event: Tierart): void {
        const tierRasseSonstige = new TierRasseSonstige();
        tierRasseSonstige.tierart_id = this.data.tierart_id;
        tierRasseSonstige.name = event.bezeichnung;
        this.tierartCtrl.setValue(tierRasseSonstige);
    }

    public createTierartSonstige(): void {
        const newTierart = new Tierart();
        this.dialog.open(TierartSonstigeDialogComponent, {data: newTierart}).afterClosed().subscribe({
            next: (result: Tierart) => {
                if (result) {
                    this.tierarten.push(result);
                    this.tierartCtrl.setValue(result);
                }
            }
        });
    }

    public disabled(): boolean {
        return this.tierartCtrl.invalid || this.rasse_name_ctrl.invalid;
    }
}
