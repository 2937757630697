import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CompanyGroup } from './company-group';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { PreislistePdfDownloadDialogComponent } from './preisliste-pdf-download-dialog/preisliste-pdf-download-dialog.component';
import { MessageService } from '../../services/message.service';
import { HttpParams } from '@angular/common/http';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { TableDescriptor } from '../../components/kiene-table/table-descriptor';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { environment } from '../../../environments/environment';
import { TextTableColumn } from '../../components/kiene-table/table-column';
import {
    TableOption,
    TableOptionEvent,
} from '../../components/kiene-table/table-option';
import { CompanyGroupService } from './company-group.service';
import {
    FilterItem,
    FilterItemType,
} from '../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';

@Component({
    selector: 'kiene-company-group',
    templateUrl: './company-group.component.html',
    styleUrls: ['./company-group.component.scss'],
})
export class CompanyGroupComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private messageService: MessageService,
        private api: KieneTableService,
        private service: CompanyGroupService,
        private pdfService: PdfViewerDesktopService,
        private companyGroupService: CompanyGroupService
    ) {}

    ngOnInit() {
        this.tableDescriptor.headline = 'Unternehmensgruppen';
        this.tableDescriptor.uniqueIdentifier = 'Unternehmensgruppen';
        this.tableDescriptor.apiUrl =
            environment.BASE_URI + 'unternehmensgruppe/';
        this.tableDescriptor.route = '/stammdaten/unternehmensgruppen/';
        this.tableDescriptor.nameOfIdField = 'unternehmensgruppe_id';
        this.tableDescriptor.searchPlaceholder =
            'Unternehmensgruppen durchsuchen';
        this.tableDescriptor.columns = this.getTableColumns();

        this.tableDescriptor.standardCreate = true;
        this.tableDescriptor.createAllowed = true;

        this.tableDescriptor.permissionView = 1581;
        this.tableDescriptor.permissionCreate = 1582;
        this.tableDescriptor.permissionUpdate = 1583;
        this.tableDescriptor.permissionDelete = 1584;

        this.tableDescriptor.options.push(
            new TableOption(
                'preisliste_download',
                'Preisliste PDF herunterladen',
                'file_download',
                1585
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                null,
                'Preisliste Excel herunterladen',
                'file_download',
                1585,
                { void: this.preislisteExcel.bind(this) }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'sonderpreise_download',
                'Sonderpreise PDF herunterladen',
                'file_download',
                1585
            )
        );
        this.tableDescriptor.options.push(
            new TableOption('delete', 'löschen', 'delete', 1584)
        );

        this.tableDescriptor.initialSortColumn = 'bezeichnung';
        this.tableDescriptor.initialSortDirection = 'asc';

        this.tableDescriptor.filterItems.push(
            new FilterItem('Aktiv', 1, FilterItemType.CHECKBOX, 'aktiv')
        );
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'preisliste_download') {
            this.openPreislistePdfDialog(event.value);
        } else if (event.name === 'sonderpreise_download') {
            this.downloadSonderpreise(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteConfirmDialog(event.value);
        }
    }

    preislisteExcel(companyGroup: CompanyGroup) {
        this.dialog
            .open(PreislistePdfDownloadDialogComponent, {
                data: {
                    width: '25vw',
                    unternehmensgruppe: companyGroup,
                },
            })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.companyGroupService.downloadPreislisteExcel(
                            result.unternehmensgruppe_id,
                            result.verkaufspreis_id
                        );
                    }
                },
            });
    }

    openDeleteConfirmDialog(companyGroup: CompanyGroup) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '350px',
            data: {
                title: 'Unternehmensgruppe löschen',
                text:
                    'Wollen Sie die Unternehmensgruppe ' +
                    companyGroup.bezeichnung +
                    ' wirklich löschen?',
                textButton: 'Löschen',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.deleteCompanyGroup(companyGroup);
            }
        });
    }

    deleteCompanyGroup(companyGroup: CompanyGroup) {
        this.service.deleteCompanyGroup(companyGroup).subscribe(
            (response) => {
                this.messageService.infoMessage(
                    'Unternehmensgruppe: ' +
                        companyGroup.bezeichnung +
                        ' erfolgreich entfernt!'
                );
                this.api.reload('deleteCompanyGroup');
            },
            (error) => {
                console.error(error);
                this.messageService.errorMessage(
                    'Löschen von Unternehmensgruppe: ' +
                        companyGroup.bezeichnung +
                        ' ist fehlgeschlagen!',
                    error
                );
            }
        );
    }

    openPreislistePdfDialog(companyGroup: CompanyGroup) {
        this.dialog
            .open(PreislistePdfDownloadDialogComponent, {
                data: {
                    width: '25vw',
                    unternehmensgruppe: companyGroup,
                },
            })
            .afterClosed()
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.companyGroupService.openPreislistePdf(
                            result.unternehmensgruppe_id,
                            result.verkaufspreis_id
                        );
                    }
                },
            });
    }

    downloadSonderpreise(companyGroup: CompanyGroup) {
        this.pdfService.openPdfViewer(
            new PdfViewerDialogInput(
                'unternehmensgruppe/read_pdf_sonderpreise.php',
                new HttpParams().set(
                    'unternehmensgruppe_id',
                    companyGroup?.unternehmensgruppe_id?.toString()
                ),
                'Sonderpreise'
            )
        );
    }

    private getTableColumns() {
        const columns = [];
        columns.push(new TextTableColumn('bezeichnung', 'Name'));
        columns.push(new TextTableColumn('kommentar', 'Kommentar'));
        columns.push(new TextTableColumn('strasse', 'Straße'));
        columns.push(new TextTableColumn('plz', 'PLZ'));
        columns.push(new TextTableColumn('ort', 'Ort'));
        return columns;
    }
}
