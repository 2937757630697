import {
    AfterViewInit,
    Component,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';
import {AppService} from '../../../../app.service';
import {AnwendungsSelectorComponent} from '../../../../components/anwendungs-selector/anwendungs-selector.component';
import {
    DiagnoseSelectorComponent
} from '../../../../components/diagnose-selector/diagnose-selector/diagnose-selector.component';
import {KieneTableService} from '../../../../components/kiene-table/kiene-table.service';
import {
    ArtikelTierart,
    ArtikelTierartEvent,
} from '../../../../components/tierart-selector/artikel-tierart';
import {TierartSelectorComponent} from '../../../../components/tierart-selector/tierart-selector.component';
import {MessageService} from '../../../../services/message.service';
import {Indikation, IndikationEvent} from '../../../../warehouse/material/artikel-indikation/indikation';
import {
    Anwendung,
    AnwendungEvent,
} from '../../../behandlungsanfrage/anwendung';
import {Abgabebeleg, AbgabebelegPosition} from '../../abgabebeleg';

@Component({
    selector: 'kiene-meldung-korrektur-dialog',
    templateUrl: './meldung-korrektur-dialog.component.html',
    styleUrls: ['./meldung-korrektur-dialog.component.scss'],
})
export class MeldungKorrekturDialogComponent implements OnInit, AfterViewInit {

    position?: AbgabebelegPosition;
    abgabebeleg?: Abgabebeleg;
    buttonsDisabled = false;

    @ViewChild('tierartSelector') tierartSelector: TierartSelectorComponent;
    @ViewChild('anwendungSelector')
    anwendungSelector: AnwendungsSelectorComponent;
    @ViewChild('diagnoseSelector') diagnoseSelector: DiagnoseSelectorComponent;

    constructor(
        public dialogRef: MatDialogRef<MeldungKorrekturDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private api: KieneTableService,
        private messageService: MessageService,
        private appService: AppService
    ) {
    }

    ngOnInit(): void {
        this.position = this.data.position;
        this.abgabebeleg = this.data.abgabebeleg;
    }

    ngAfterViewInit() {
        this.setzeTierart();
        this.setzeAnwendung();
        this.setzeDiagnose();
    }

    setSelectedTierart(event: ArtikelTierartEvent) {
        this.anwendungSelector?.loadAnwendungen(
            this.position?.artikel_id,
            event.artikelTierart.tierart_id,
            false,
            false
        );

        this.diagnoseSelector?.loadDiagnosen(this.position?.artikel_id, event.artikelTierart.tierart_id, undefined, false, false);
    }

    setSelectedAnwendung(event: AnwendungEvent) {
        this.anwendungSelector.close();
    }

    setSelectedDiagnose(event: IndikationEvent) {
        this.diagnoseSelector.close();
    }

    setzeTierart() {
        const tierart = new ArtikelTierart();
        tierart.artikel_id = this.position.artikel_id;
        tierart.tierart_id = this.position.tierart_id;
        tierart.tierart = this.position.tierart;
        this.tierartSelector.setTierart(tierart, false, false);
    }

    setzeAnwendung() {
        const anwendung = new Anwendung();
        anwendung.anwendung_id = this.position.anwendung_id;
        anwendung.anwendung = this.position.anwendung;
        anwendung.artikel_id = this.position.artikel_id;
        anwendung.artikel = this.position.artikel;
        this.anwendungSelector.setAnwendung(
            anwendung,
            this.position.tierart_id,
            false,
            false
        );
    }

    setzeDiagnose() {
        const indikation = new Indikation();
        indikation.indikation_id = this.position.indikation_id;
        indikation.diagnose = this.position.diagnose;
        indikation.artikel_id = this.position.artikel_id;
        indikation.tierart_id = this.position.tierart_id;
        this.diagnoseSelector.setDiagnose(indikation, undefined, false, false);
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        this.appService.setDataLoading(true);
        this.buttonsDisabled = true;

        this.position.tierart_id = this.tierartSelector?.getTierart()?.tierart_id;
        this.position.anwendung_id = this.anwendungSelector?.getAnwendung()?.anwendung_id;
        this.position.indikation_id = this.diagnoseSelector?.getIndikation()?.indikation_id;

        this.api
            .updateGeneric(
                environment.BASE_URI + 'abgabebeleg/update_position.php',
                this.position
            )
            .subscribe({
                next: (response) => {
                    this.messageService.infoMessage(
                        'Die Position wurde erfolgreich korrigiert!'
                    );
                    this.appService.setDataLoading(false);
                    this.buttonsDisabled = false;
                    this.dialogRef.close();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Die Position konnte nicht korrigiert werden!',
                        err
                    );
                    this.appService.setDataLoading(false);
                    this.buttonsDisabled = false;
                },
            });
    }
}
