import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {QRCodeModule} from 'angular2-qrcode';
import {SignButtonComponent} from './sign-button/sign-button.component';
import {QRCodeDialogComponent} from './qrcode-dialog/qrcode-dialog.component';
import {SignPanelComponent} from './sign-panel/sign-panel.component';
import {UnterschriftenRoutingModule} from './unterschriften.routing.module';
import {QrCodeSignFieldComponent} from './qr-code-sign-field/qr-code-sign-field.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import {SignaturePadModule} from 'angular2-signaturepad';
import {SignaturePanelModule} from '../signature-panel/signature-panel.module';
import { UnterschriftDialogComponent } from './unterschrift-dialog/unterschrift-dialog.component';

@NgModule({
    declarations: [SignButtonComponent, QRCodeDialogComponent, SignPanelComponent, QrCodeSignFieldComponent, UnterschriftDialogComponent],
    imports: [
        CommonModule,
        UnterschriftenRoutingModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        QRCodeModule,
        MatTabsModule,
        SignaturePadModule,
        SignaturePanelModule
    ],
    exports: [
        SignButtonComponent,
        SignPanelComponent,
        QrCodeSignFieldComponent
    ]
})
export class UnterschriftenModule {
}
