import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TableDescriptor } from '../../components/kiene-table/table-descriptor';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '../../services/message.service';
import { EmailGesendetTableColumn, GedrucktTableColumn, TableColumn } from '../../components/kiene-table/table-column';
import { Condition, Operator, TableOption, TableOptionEvent, } from '../../components/kiene-table/table-option';
import { Probenbegleitschein } from './probenbegleitschein';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Befundmitteilung } from '../befundmitteilung/befundmitteilung';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { HttpParams } from '@angular/common/http';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { Client } from '../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-probenbegleitschein',
    templateUrl: './probenbegleitschein.component.html',
    styleUrls: ['./probenbegleitschein.component.scss'],
})
export class ProbenbegleitscheinComponent implements OnInit, OnDestroy {
    tableDescriptor: TableDescriptor = new TableDescriptor();
    currentClient: Client;

    watchClientSubscription: Subscription;

    constructor(
        public dialog: MatDialog,
        private messageService: MessageService,
        private pdfService: PdfViewerDesktopService,
        private tableService: KieneTableService,
        private router: Router,
        private api: KieneTableService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Probenbegleitscheine';
        this.tableDescriptor.uniqueIdentifier = 'Probenbegleitscheine';
        this.tableDescriptor.apiUrl =
            this.apiBaseUrl + 'probenbegleitschein/';
        this.tableDescriptor.route = 'probenbegleitscheine';
        this.tableDescriptor.nameOfIdField = 'probenbegleitschein_id';
        this.tableDescriptor.searchPlaceholder =
            'Probenbegleitscheine durchsuchen';
        this.tableDescriptor.columns.push(
            new TableColumn('probenbegleitschein_id', 'Nr.')
        );
        this.tableDescriptor.columns.push(new TableColumn('kunde', 'Kunde'));
        this.tableDescriptor.columns.push(new TableColumn('gruppe', 'Gruppe'));
        this.tableDescriptor.columns.push(
            new TableColumn('tierarzt', 'Tierarzt')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('probenentnahme', 'Probenentnahme', 'date')
        );
        this.tableDescriptor.columns.push(new TableColumn('status', 'Status'));
        this.tableDescriptor.columns.push(new TableColumn('pdf_id', '', 'pdf'));
        this.tableDescriptor.columns.push(new EmailGesendetTableColumn());
        this.tableDescriptor.columns.push(new GedrucktTableColumn());

        this.tableDescriptor.permissionCreate = 2502;
        this.tableDescriptor.permissionUpdate = 2502;
        this.tableDescriptor.permissionView = 2501;
        this.tableDescriptor.permissionDelete = 2504;

        this.tableDescriptor.options.push(
            new TableOption('delete', 'löschen', 'delete', 2504)
        );
        this.tableDescriptor.options.push(
            new TableOption('duplicate', 'duplizieren', 'file_copy', 2509)
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'createBefund',
                'Befundmitteilung erstellen',
                'fast_forward',
                1335
            )
        );
        this.tableDescriptor.options.push(new TableOption(null, 'Drucken', 'print', 2506, { void: this.gedruckt.bind(this), conditions: [new Condition('pdf_id', Operator.EXISTIERT, null)] }));

        this.tableDescriptor.initialSortColumn = 'erstellt';
        this.tableDescriptor.initialSortDirection = 'desc';
    }

    gedruckt(pbs: Probenbegleitschein) {
        this.api.get(this.apiBaseUrl + 'probenbegleitschein/gedruckt.php', new HttpParams().set('probenbegleitschein_id', pbs.probenbegleitschein_id)).subscribe({
            next: () => {
                this.api.reload('gedruckt');
                this.showPdf(pbs);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    showPdf(pbs: Probenbegleitschein) {
        const input = new PdfViewerDialogInput(
            'probenbegleitschein/read_or_mail_pdf.php',
            new HttpParams().set('probenbegleitschein_id',
                pbs?.probenbegleitschein_id?.toString()),
            'Probenbegleitschein',
            {
                email: {
                    sende_mail: true,
                    kunde_id: pbs?.kunde_id
                }
            }
        );
        this.pdfService.openPdfViewer(input).subscribe({
            next: () => {
                this.api.reload();
            }
        });
    }

    catchOptionEvent(event: TableOptionEvent) {
        const pbs = <Probenbegleitschein>event.value;
        if (event.name === 'showPdf') {
            this.showPdf(pbs);
        } else if (event.name === 'createBefund') {
            this.api
                .post(
                    this.apiBaseUrl + 'befund/transform.php',
                    pbs,
                    null
                )
                .subscribe(
                    (response) => {
                        const b: Befundmitteilung = response;
                        this.messageService.infoMessage(
                            'Die Befundmitteilung wurde erfolgreich erstellt!'
                        );
                        this.router.navigate([
                            'labor',
                            'befundmitteilungen',
                            b.befund_id,
                        ]);
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Befundmitteilung konnte nicht erstellt werden!',
                            error
                        );
                    }
                );
        } else if (event.name === 'createUntersuchung') {
            this.api
                .post(
                    this.apiBaseUrl + 'untersuchungsauftrag/transform.php',
                    pbs,
                    null
                )
                .subscribe(
                    (response) => {
                        const b: any = response;
                        this.messageService.infoMessage(
                            'Der Untersuchungsauftrag wurde erfolgreich erstellt!'
                        );
                        this.router.navigate([
                            'labor',
                            'untersuchungsauftrag',
                            b.untersuchungsauftrag_id,
                        ]);
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Untersuchungsauftrag konnte nicht erstellt werden!',
                            error
                        );
                    }
                );
        } else if (event.name === 'duplicate') {
            this.duplicate(pbs);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(pbs);
        }
    }

    duplicate(probenbegleitschein: Probenbegleitschein) {
        this.tableService
            .duplicateEntity(
                this.apiBaseUrl + 'probenbegleitschein/duplicate.php',
                probenbegleitschein
            )
            .subscribe(
                (response) => {
                    this.messageService.infoMessage(
                        'Der Probenbegleitschein wurde erfolgreich dupliziert!'
                    );
                    this.router.navigate([
                        'labor',
                        'probenbegleitscheine',
                        response.probenbegleitschein_id,
                    ]);
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Der Probenbegleitschein konnte nicht dupliziert werden!',
                        error
                    );
                }
            );
    }

    ngOnDestroy() {
        this.tableService = null;
    }

    openDeleteDialog(pbs: Probenbegleitschein) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title:
                    'Probenbegleitschein ' +
                    pbs.probenbegleitschein_id +
                    ' wirklich löschen?',
                text:
                    'Probenbegleitschein ' +
                    pbs.probenbegleitschein_id +
                    ' wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.api
                    .deleteEntity(this.apiBaseUrl + 'probenbegleitschein/delete.php', pbs)
                    .subscribe(
                        () => {
                            this.messageService.infoMessage(
                                'Der Probenbegleitschein wurde erfolgreich gelöscht!'
                            );
                            this.tableService.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Der Probenbegleitschein konnte nicht gelöscht werden! ',
                                error
                            );
                        }
                    );
            }
        });
    }
}
