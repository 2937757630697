import {Component, Inject, Input, OnChanges, OnInit, SimpleChanges,} from '@angular/core';
import {Signature} from '../signature';
import {Tierarzt} from '../../tieraerzte/classes/tierarzt';
import {KieneBackendApiService} from '../../../services/backend-api/kiene-backend-api.service';
import {MessageService} from '../../../services/message-service/message.service';
import {API_BASE_URL_SERVICE, SIGNATURE_URL_SERVICE} from '../../../kiene-core.config';

@Component({
    selector: 'kiene-qr-code-sign-field',
    templateUrl: './qr-code-sign-field.component.html',
    styleUrls: ['./qr-code-sign-field.component.scss'],
})
export class QrCodeSignFieldComponent implements OnInit, OnChanges {
    @Input('signature') signature: Signature;


    qrcodeUrl = '';
    tierarzt: Tierarzt;

    constructor(
        private api: KieneBackendApiService,
        private messageService: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        @Inject(SIGNATURE_URL_SERVICE) private signatureUrl: string
    ) {
    }

    ngOnInit() {
        this.ladeTierarzt();
        this.qrcodeUrl = this.signatureUrl + 'sign/' + this.signature.kuid;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.ladeTierarzt();
        this.qrcodeUrl = this.signatureUrl + 'sign/' + this.signature.kuid;
    }

    ladeTierarzt() {
        if (this.signature.tierarzt_id) {
            this.api
                .getByIdAlternative(
                    this.apiBaseUrl + 'tierarzt/read_one_unterschrift.php',
                    'tierarzt_id',
                    this.signature.tierarzt_id.toString()
                )
                .subscribe({
                    next: (tierarzt) => {
                        this.tierarzt = tierarzt;
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Der Tierarzt mit der ID ' +
                            this.signature.tierarzt_id +
                            ' konnte nicht geladen werden!',
                            err
                        );
                    },
                });
        }
    }
}
