import { Component, OnInit } from '@angular/core';
import { Client } from '../../client/classes/client';
import {
    BetriebAuswaehlenDialogComponent
} from '../../dialogs/betrieb-auswaehlen-dialog/betrieb-auswaehlen-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { StammdatenService } from '../../stammdaten/stammdaten.service';

@Component({
    selector: 'kiene-vvvoinline-select',
    templateUrl: './vvvoinline-select.component.html',
    styleUrls: ['./vvvoinline-select.component.scss']
})
export class VVVOInlineSelectComponent implements OnInit {

    kunde: Client;

    constructor(private dialog: MatDialog,
        private stammdatenService: StammdatenService) {
    }

    ngOnInit(): void {
        this.kunde = this.stammdatenService.getAusgewaehlteVvvo();
        this.stammdatenService.watchAusgewaehlteVvvo().subscribe({
            next: vvvo => {
                this.kunde = vvvo;
            }
        });
    }

    openToolbarVVVOAuswaehlenDialog() {
        const dialogRef = this.dialog.open(BetriebAuswaehlenDialogComponent, {
            minWidth: '90vw',
            position: { top: '10px' },
            data: {
                vvvo: this.kunde
            }
        });
        dialogRef.afterClosed().subscribe({
            next: betrieb => {
                if (betrieb) {
                    this.stammdatenService.setAusgewaehlteVvvo(betrieb);
                }
            }
        });
    }

    betriebAbwaehlen() {
        this.stammdatenService.setAusgewaehlteVvvo(null);
    }
}
