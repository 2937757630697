<h1 mat-dialog-title>{{ data.headline }}</h1>
<div mat-dialog-content>
    <p style="white-space: pre-wrap;">{{ data.text }}</p>
</div>

<div mat-dialog-content>
    <mat-select mat-select [placeholder]="'Fahrer'" [formControl]="driverFormControl" *ngIf="drivers.length > 0">
        <mat-option *ngFor="let driver of drivers" [value]="driver.benutzer_id">
            {{ driver.vorname }} {{ driver.nachname }}
        </mat-option>
    </mat-select>
    <div *ngIf="drivers.length == 0">
        <p>Es sind keine Fahrer verfügbar.</p>
    </div>
</div>

<div mat-dialog-actions>
    <span class="spacer"></span>
    <button data-cy="confirm-dialog-abort" mat-button (click)="cancel()">{{data?.cancelButton}}</button>
    <button data-cy="confirm-dialog-confirm" mat-flat-button [color]="data?.buttonColor" [disabled]="!acceptButtonEnabled" (click)="ok()">
        {{ data.okButton }}
    </button>
</div>
