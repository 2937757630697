import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from '../app.auth.guard';
import {DocumentsComponent} from './documents.component';
import {AbgabebelegDetailsComponent} from './abgabebeleg/abgabebeleg-details/abgabebeleg-details.component';
import {AbgabebelegComponent} from './abgabebeleg/abgabebeleg.component';
import {
    BehandlungsanfrageDetailComponent
} from './behandlungsanfrage/behandlungsanfrage-detail/behandlungsanfrage-detail.component';
import {BehandlungsanfrageComponent} from './behandlungsanfrage/behandlungsanfrage.component';
import {
    BestandsbesuchprotokollDetailsComponent
} from './bestandsbesuchsprotokoll/bestandsbesuchprotokoll-details/bestandsbesuchprotokoll-details.component';
import {
    MassnahmenplanRindDetailsComponent
} from './massnahmenplan/massnahmenplan-rind/massnahmenplan-rind-details/massnahmenplan-rind-details.component';
import {
    MassnahmenplanSchweinDetailsComponent
} from './massnahmenplan/massnahmenplan-schwein/massnahmenplan-schwein-details/massnahmenplan-schwein-details.component';
import {MassnahmenplanComponent} from './massnahmenplan/massnahmenplan.component';
import {MeldungenComponent} from './meldungen/meldungen.component';
import {GruppenuebersichtComponent} from './gruppenverwaltung/gruppenuebersicht/gruppenuebersicht.component';
import {BestandsbuchComponent} from './bestandsbuch/bestandsbuch/bestandsbuch.component';
import {
    BestandsbesuchsprotokollUebersichtComponent
} from './bestandsbesuchsprotokoll/bestandsbesuchsprotokoll-uebersicht/bestandsbesuchsprotokoll-uebersicht.component';
import {DokumenteOverviewComponent} from './dokumente/dokumente-overview/dokumente-overview.component';

const routes: Routes = [
    {
        path: '', component: DocumentsComponent, canActivate: [AuthGuard], children: [
            {path: 'behandlungsanfragen', component: BehandlungsanfrageComponent, canActivate: [AuthGuard]},
            {path: 'behandlungsanfragen/:id', component: BehandlungsanfrageDetailComponent, canActivate: [AuthGuard]},
            {path: 'bestandsbuecher', component: BestandsbuchComponent, canActivate: [AuthGuard]},
            {path: 'abgabebelege', component: AbgabebelegComponent, canActivate: [AuthGuard]},
            {path: 'abgabebelege/:id', component: AbgabebelegDetailsComponent, canActivate: [AuthGuard]},
            {
                path: 'labor',
                loadChildren: () => import('../laboratory/laboratory.module').then(m => m.LaboratoryModule),
                data: {
                    embedded: true
                },
                canActivate: [AuthGuard],
            },
            {path: 'massnahmenplaene', component: MassnahmenplanComponent, canActivate: [AuthGuard]},
            {path: 'massnahmenplaenerind', component: MassnahmenplanComponent, canActivate: [AuthGuard]},
            {path: 'massnahmenplaenerind/:id', component: MassnahmenplanRindDetailsComponent, canActivate: [AuthGuard]},
            {path: 'massnahmenplaeneschwein', component: MassnahmenplanComponent, canActivate: [AuthGuard]},
            {
                path: 'massnahmenplaeneschwein/:id',
                component: MassnahmenplanSchweinDetailsComponent,
                canActivate: [AuthGuard]
            },
            {path: 'bestandsbesuch', component: BestandsbesuchsprotokollUebersichtComponent, canActivate: [AuthGuard]},
            {path: 'bestandsbesuch/:id', component: BestandsbesuchprotokollDetailsComponent, canActivate: [AuthGuard]},
            {path: 'dokumente', component: DokumenteOverviewComponent, canActivate: [AuthGuard]},
            {path: 'meldungen', component: MeldungenComponent, canActivate: [AuthGuard]},
            {path: 'gruppen', component: GruppenuebersichtComponent, canActivate: [AuthGuard]},

        ]
    },
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DocumentsRoutingModule {
}
