<h1 mat-dialog-title>Gutschriften von {{bestellungExtern.lieferant}}</h1>
<div mat-dialog-content>
    <table style="width: 100%;">
        <thead>
        <tr>
            <th style="text-align: left; color: gray; font-size: .9em;">Datum</th>
            <th style="text-align: left; color: gray; font-size: .9em;">Artikel</th>
            <th style="text-align: left; color: gray; font-size: .9em;">Betrag in &euro;</th>
            <th style="text-align: left; color: gray; font-size: .9em;">Gebucht</th>
            <th style="text-align: left; color: gray; font-size: .9em;">Datei</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let g of gutschriften">

            <td>{{g.erstellt | date: 'dd.MM.yyyy'}}</td>
            <td>{{g.artikel }}</td>
            <td>{{g.betrag | number: '1.2-2'}} &euro;</td>
            <td>
                <mat-icon
                    [color]="g.primanota_id ? 'accent' : ''">{{g.primanota_id ? 'check_circle' : 'radio_button_unchecked'}}</mat-icon>
            </td>
            <td>
                <button (click)="openPdf(g)" *ngIf="g.pdf_id" color="accent" mat-icon-button>
                    <mat-icon>cloud_download</mat-icon>
                </button>
            </td>

        </tr>
        </tbody>

    </table>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button (click)="close()" mat-button>OK</button>
</div>
