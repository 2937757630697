export class AcceptBookingWithDriverDialogInput {
    headline = '';
    text = '';
    okButton = 'OK';
    cancelButton = 'Abbrechen';
    buttonColor = 'accent';
}

export class AcceptBookingWithDriverDialogResult {
    ok = false; // false = Abbruch
    confirmed = false;
    driver_id = 0;
}