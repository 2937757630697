import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BelegPositionInlineComponent } from './beleg-position-inline/beleg-position-inline.component';
import { ArticleScanAutocompleteModule } from '../../components/article-scan-autocomplete/article-scan-autocomplete.module';
import { TierartSelectorModule } from '../../components/tierart-selector/tierart-selector.module';
import { AnwendungsSelectorModule } from '../../components/anwendungs-selector/anwendungs-selector.module';
import { DiagnoseSelectorModule } from '../../components/diagnose-selector/diagnose-selector.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { EntityViewerModule } from '../../components/entity-viewer/entity-viewer.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ArtikellisteAuswahlDialogComponent } from './artikelliste-auswahl-dialog/artikelliste-auswahl-dialog.component';
import { KieneAutocompleteModule } from '../../components/kiene-autocomplete/kiene-autocomplete.module';
import { ArtikellisteAusfuellenDialogComponent } from './artikelliste-ausfuellen-dialog/artikelliste-ausfuellen-dialog.component';



@NgModule({
  declarations: [BelegPositionInlineComponent, ArtikellisteAuswahlDialogComponent, ArtikellisteAusfuellenDialogComponent],
  imports: [
    CommonModule,
    ArticleScanAutocompleteModule,
    TierartSelectorModule,
    AnwendungsSelectorModule,
    DiagnoseSelectorModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    EntityViewerModule,
    MatSlideToggleModule,
    KieneAutocompleteModule
  ],
  exports: [
    BelegPositionInlineComponent
  ]
})
export class BelegPositionModule { }
