<h1 mat-dialog-title>Betriebstyp {{betriebstyp?.betriebstyp_id ? 'bearbeiten' : 'erstellen'}}</h1>
<div mat-dialog-content>
    <mat-form-field style="width: 100%;">
        <mat-label>Betriebstyp</mat-label>
        <input matInput type="text" [formControl]="betriebstypCtrl">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Impfstoffzusatz</mat-label>
        <input matInput type="text" [formControl]="impfstoffZusatzCtrl">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Antibiotikazusatz</mat-label>
        <input matInput type="text" [formControl]="antibiotikaZusatzCtrl">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Verbotene Wirkstoffe</mat-label>
        <mat-select [formControl]="wirkstoffCtrl"  multiple [compareWith]="wirkstoffComparator">
            <mat-option *ngFor="let w of wirkstoffe" [value]="w">{{w.wirkstoff}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</div>