import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {API_BASE_URL_SERVICE} from "../../../../../projects/kiene-core/src/lib/kiene-core.config";
import {
    KieneBackendApiService
} from "../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service";
import {MessageService} from "../../../services/message.service";
import {Textbaustein, TextbausteinResult} from "../text-baustein";

@Component({
    selector: 'kiene-textbaustein-general-dialog',
    templateUrl: './textbaustein-general-dialog.component.html',
    styleUrls: ['./textbaustein-general-dialog.component.scss']
})
export class TextbausteinGeneralDialogComponent implements OnInit {
    typen: string[] = [];
    form: FormGroup;
    isLoading: boolean;
    subscribe: Subscription = new Subscription();

    constructor(@Inject(MAT_DIALOG_DATA) public textbaustein: Textbaustein,
                public dialogRef: MatDialogRef<Textbaustein>,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private messageService: MessageService,
                private fb: FormBuilder,) {
    }

    public ngOnInit(): void {
        this.subscribe.unsubscribe();
        this.subscribe = this.api.get(this.apiBaseUrl + 'textbaustein/read_typen.php').subscribe({
            next: (response) => {
                this.typen = response;
            },
            error: err => {
                this.messageService.errorMessage('Typen laden fehlgeschlagen!', err);
            }
        });

        this.form = this.fb.group({
                titel: [this.textbaustein?.titel, [Validators.required]],
                textbaustein1: [this.textbaustein?.textbaustein1, [Validators.required]],
                textbaustein2: [this.textbaustein?.textbaustein2],
                textbaustein3: [this.textbaustein?.textbaustein3],
                typ: [this.textbaustein?.typ],
                global: [this.textbaustein?.global],
            }
        )
    }


    save() {
        this.isLoading = true;
        this.api.post(this.apiBaseUrl + 'textbaustein/update.php', this.prepareSave()).subscribe({
            next: (response) => {
                const result = new TextbausteinResult();
                result.confirm = true;
                result.textbaustein = response;
                this.messageService.infoMessage('Erfolgreich gespeichert!');
                this.dialogRef.close(result);
                this.isLoading = false;
            },
            error: err => {
                this.isLoading = false;
                this.messageService.errorMessage('Speichern fehlgeschlagen!', err);
            }
        });
    }

    close() {
        const result = new TextbausteinResult();
        result.confirm = false;
        this.dialogRef.close(result);
    }

    private prepareSave(): Textbaustein {
        const tb = new Textbaustein()
        tb.titel = this.form.get('titel').value;
        tb.textbaustein1 = this.form.get('textbaustein1').value;
        tb.textbaustein2 = this.form.get('textbaustein2').value;
        tb.textbaustein3 = this.form.get('textbaustein3').value;
        tb.typ = this.form.get('typ').value;
        tb.textbaustein_id = this.textbaustein.textbaustein_id;
        if (this.form.get('global').value) {
            tb.global = 1;
        } else {
            tb.global = 0;
        }
        return tb;
    }
}
