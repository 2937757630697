<div>
    <div class="one-row" style="margin-bottom: 6px">
        <h3>{{gruppe?.bezeichnung}}</h3>
        <span class="spacer"></span>
        <button (click)="importScans()" color="accent" mat-flat-button>Scans importieren</button>
        <button (click)="loadRinderPassScans()" color="accent" mat-icon-button>
            <mat-icon>refresh</mat-icon>
        </button>
    </div>

    <div *ngIf="!scans"
        style="width: 100%; display: flex; justify-content: center; align-items: center; text-align: center; margin: 64px 0 ">
        <mat-hint>Lade PDF-Dateien...</mat-hint>
    </div>

    <div *ngIf="scans?.length === 0"
        style="width: 100%; display: flex; justify-content: center; align-items: center; text-align: center; margin: 64px 0 ">
        <mat-hint>Noch keine PDFs hochgeladen...</mat-hint>
    </div>

    <div style="max-height: 50vh; overflow-y: auto">
        <table *ngIf="scans?.length > 0" aria-label="" style="width: 100%;">
            <thead>
                <th class="centered">Status</th>
                <th>Dateiname</th>
                <th>Viehh&auml;ndler</th>
                <th>Einstalldatum</th>
                <th class="centered">Seiten gesamt</th>
                <th class="centered">fehlerhafte Seiten</th>
                <th>erstellt</th>
                <th></th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let scan of scans">
                    <td [ngClass]="scanDone(scan) ? 'done' : 'pending'" class="centered">
                        <mat-icon>{{scanDone(scan) ? 'done' : 'update'}}</mat-icon>
                    </td>
                    <td>{{scan.dateiname}}</td>
                    <td>{{scan.viehhaendler}}</td>
                    <td>{{scan.kp_einstalldatum | date: 'dd.MM.YY'}}</td>
                    <td class="centered">
                        {{scan.verarbeitet_anzahl ? scan.verarbeitet_anzahl : '?'}}
                        /
                        {{scan.seitenzahl_gesamt ? scan.seitenzahl_gesamt : '?'}}
                        &nbsp;{{scanDone(scan) ? '' : '(in Arbeit)'}}
                    </td>
                    <td class="centered">
                        <div (click)="$event.stopPropagation();fehlerhafteSeitenNachtragen(scan)"
                            *ngIf="scan?.seitenzahl_fehler" class="one-row fehlerhafte-seiten"
                            matTooltip="Ohrmarken von fehlerhaften Scans manuell nachtragen">
                            <span class="spacer"></span>
                            <span>{{scan.seitenzahl_fehler}}</span>
                            <mat-icon>open_in_new</mat-icon>
                            <span class="spacer"></span>
                        </div>
                        <span *ngIf="!scan.seitenzahl_fehler">---</span>
                    </td>
                    <td>{{scan.erstellt | date: 'dd.MM.YY'}}</td>
                    <td>
                        <button mat-icon-button (click)="openPdfDialog(scan)" matTooltip="Download">
                            <mat-icon>download</mat-icon>
                        </button>
                    </td>
                    <td *ngIf="restartAllowed(scan)">
                        <button mat-icon-button (click)="restart(scan)" matTooltip="Neustart" [disabled]="isSaving">
                            <mat-icon>restart_alt</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>