export class Haendler {

    viehhaendler_id: number;
    name: string;
    vvvo: string;
    strasse: string;
    plz: number;
    ort: string;
    telefon: string;
    mobil: string;
    fax: string;
    email: string;

    favorit: number;
    anzahl: number; // nur für Filter

    public toString() {
        return 'Haendler{' +
            'viehaendler_id:' + this.viehhaendler_id + ',' +
            'name:' + this.name + ',' +
            'vvvo:' + this.vvvo + ',' +
            '}';
    }
}
