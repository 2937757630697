<div  [style.padding]="dialog ? '0' : '0 24px 64px 24px'">

    <kiene-page-header *ngIf="!dialog" [ueberschrift]="ueberschrift"></kiene-page-header>

    <div *ngIf="isLoading" style="padding-top: 128px; display: flex; align-items: center; justify-content: center">
        <span>Lade Tier...</span>
    </div>

    <div *ngIf="!isLoading">

        <h3 style="margin-block-end: 12px; margin-block-start: 0">Ohrmarke {{this.tier?.ohrmarke_notnull}}</h3>

        <mat-hint style="text-align: justify; display: inline-block; font-size: 0.8em">
            <strong>Hinweis:</strong> Neue Tierreklamationen können über das + Symbol hinzufügt werden.
        </mat-hint>

        <div style="display: flex; align-items: center; justify-content: space-between;"
        [style.margin-top]="dialog ? '12px' : '0'">
            <strong class="kiene-primary">Tierreklamationen Schnellauswahl</strong>
            <button data-cy="reklamieren-add" color="accent" mat-icon-button (click)="editTierreklamation($event)"><mat-icon>add</mat-icon></button>
        </div>

        <div *ngIf="!isLoading" style="margin-bottom: 12px">
            <div style="width: 100%; text-align: center; margin: 12px 0" *ngIf="tierreklamationCheckboxes.length === 0">
                <mat-hint style="font-size: 0.8em">Noch keine Tierreklamationen angelegt</mat-hint>
            </div>

            <div *ngIf="tierreklamationCheckboxes.length > 0">
                <div (click)="addSelected({checked: !tierreklamationCheckbox.checked}, tierreklamationCheckbox, $event)"
                     *ngFor="let tierreklamationCheckbox of tierreklamationCheckboxes; let index = index"
                     style="display: flex;flex-direction: row; align-items: center; justify-content: space-between;
                  padding: 3px; width:100%; font-size: 1.25em"
                >
                    <mat-checkbox id="reklamieren-reklamation-{{index}}" [checked]="tierreklamationCheckbox.checked"
                                  (change)="addSelected($event, tierreklamationCheckbox)">
                        {{tierreklamationCheckbox?.tierreklamation?.reklamation}}
                    </mat-checkbox>

                    <span class="spacer"></span>

                    <button color="accent" mat-icon-button (click)="editTierreklamation($event, tierreklamationCheckbox?.tierreklamation)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div [style]="dialog ? 'position: sticky; bottom: 0; background-color: white; padding: 12px 0 0 0;' : 'padding-top: 48px'"
             class="one-row" [style.justify-content]="dialog ? 'space-between' : 'center'">
            <button style="width: 80%" color="accent" mat-flat-button
                    data-cy="reklamieren-save"
                    (click)="reklamationHinzufuegen()" [disabled]="isSaving">
                Reklamation speichern
            </button>
            <span *ngIf="dialog" class="spacer"></span>
            <ng-content select="[additional-actions]"></ng-content>
        </div>
    </div>
</div>
