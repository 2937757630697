import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KieneUtils } from '../../../../../projects/kiene-core/src/lib/utils/KieneUtils';

export class CalendarWeek {
  weekNumber: number;
  year: number;
  monday: Date;
}

@Component({
  selector: 'kiene-kwdialog',
  templateUrl: './kwdialog.component.html',
  styleUrls: ['./kwdialog.component.scss']
})
export class KWDialogComponent implements OnInit {

  yearCtrl = new UntypedFormControl(new Date().getFullYear());
  years: number[] = [];
  numberOfWeeks = 0;
  weeks: CalendarWeek[] = [];
  currentWeek = 0;
  currentYear = 0;
  selectedWeek: CalendarWeek = new CalendarWeek();

  constructor(public dialogRef: MatDialogRef<KWDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) { }

  ngOnInit(): void {

    const now = new Date();
    this.currentWeek = KieneUtils.getCalendarWeek(now);
    this.currentYear = now.getFullYear();

    if (this.data) {
      this.selectedWeek = this.data.calendarWeek;
    }

    this.initYears();

    this.yearCtrl.valueChanges.subscribe({
      next: () => {
        this.initWeeks();
      }
    });

  }

  close() {
    this.dialogRef.close();
  }

  select(w: CalendarWeek) {
    this.dialogRef.close(w);
  }

  initWeeks() {
    this.weeks = [];

    this.numberOfWeeks = KieneUtils.getNumberOfWeeks(this.yearCtrl.value);
    for (let i = 1; i <= this.numberOfWeeks; i++) {
      let w = new CalendarWeek();
      w.weekNumber = i;
      w.year = this.yearCtrl.value;
      w.monday = KieneUtils.getDateOfMonday(i, this.yearCtrl.value);
      this.weeks.push(w);
    }
  }

  initYears() {
    for (let i = 1950; i <= 2100; i++) {
      this.years.push(i);
    }
    this.initWeeks();
  }

}
