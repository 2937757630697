<div style="padding: 24px">
    <div class="warning" *ngIf="!kundeHatSchweine"> Kunde hat keine Schweine</div>

    <div class="one-row">
        <h1>Maßnahmenplan nach § 58 d AMG <u>für die Tierart Schwein</u></h1>
        <span class="spacer"></span>
        <button *ngIf="mps?.status_id !== 62" mat-icon-button color="accent" (click)="save(false)"
            matTooltip="Zwischenspeichern">
            <mat-icon>save</mat-icon>
        </button>
        <button mat-button color="accent" (click)="goBack()">
            Zur&uuml;ck
        </button>
    </div>

    <div *kienePermission="[permissionAbschliessen]" class="info-box">
        <mat-checkbox [formControl]="abrechnenCtrl">Ma&szlig;nahmenplan abrechnen?</mat-checkbox>
        <span class="spacer"></span>
    </div>

    <kiene-autocomplete *ngIf="showTierarztSelector" (elementSelected)="setTierarzt($event)" [placeholder]="'Tierarzt'"
        [displayFields]="['vorname', 'nachname']"
        [genericApiUrl]="'tierarzt/read_autocomplete.php'"></kiene-autocomplete>

    <mat-radio-group [formControl]="tierartCtrl" aria-label="Nutzungsart">
        <mat-radio-button [value]="15">Aufzuchtferkel</mat-radio-button>
        <mat-radio-button [value]="19">Mastschweine</mat-radio-button>
        <mat-radio-button [value]="2">Sauen</mat-radio-button>
        <mat-radio-button [value]="17">Saugferkel</mat-radio-button>
    </mat-radio-group>

    <span class="phito-field">(Für jede Nutzungsart bitte einen Plan ausfüllen)</span>

    <br />

    <mat-form-field class="phito-field">
        <input matInput type="number" placeholder="Halbjahr" [formControl]="_0_1_2_halbjahr" />
    </mat-form-field>
    <mat-form-field class="phito-field">
        <input matInput type="number" placeholder="Jahr" [formControl]="_0_1_3_jahr" />
    </mat-form-field>
    <mat-form-field class="phito-field">
        <input matInput type="number" placeholder="Therapiehäufigkeit" [formControl]="_0_1_4_haeuffigkeit" />
    </mat-form-field>

    <!--<p>{{currentClient.bezeichnung}}</p>
    <p>{{currentClient.strasse}}</p>
    <p>{{currentClient.plz}} {{currentClient.ort}}</p>
    <p>{{currentClient.telefon_1}}</p>-->

    <div *ngIf="mps.abweichende_lieferadresse">
        <p>{{ mps.kunde }}</p>
        <p>{{ mps.strasse_lieferung }}</p>
        <p>{{ mps.plz_lieferung }} {{ mps.ort_lieferung }}</p>
        <p>{{ mps.kunde_telefon_1 }}</p>
        <p>VVVO: {{ mps.vvvo }}</p>
    </div>

    <div *ngIf="!mps.abweichende_lieferadresse">
        <p>{{ mps.kunde }}</p>
        <p>{{ mps.kunde_strasse }}</p>
        <p>{{ mps.kunde_plz }} {{ mps.kunde_ort }}</p>
        <p>{{ mps.kunde_telefon_1 }}</p>
        <p>VVVO: {{ mps.vvvo }}</p>
    </div>

    <!--

    Abschnitt 1.

   -->
    <h3 style="margin-top: 24px">1. Angaben zum Betrieb</h3>
    <div class="one row" style="margin-bottom: 12px">
        <span>Anschrift des Bertiebsstandortes: </span>
        <span> {{ mps?.kunde_strasse }}, {{ mps?.kunde_plz }} {{ mps?.kunde_ort }}</span>
    </div>
    <mat-label>Anzahl Ställe mit Stallbezeichnung und Stallplätzen:</mat-label>
    <table class="phito-table" style="width: 30%; position: relative; left: 24px">

        <tbody>
            <tr>
                <td>1.</td>
                <td>
                    <mat-form-field>
                        <mat-label>Stallnummer/-bezeichnung</mat-label>
                        <input matInput type="text" [formControl]="_1_1_3_stallnummer_1" />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <mat-label>Anzahl Stallplätze</mat-label>
                        <input matInput type="number" [formControl]="_1_1_3_stallplatze_1" />
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>2.</td>
                <td>
                    <mat-form-field>
                        <mat-label>Stallnummer/-bezeichnung</mat-label>
                        <input matInput type="text" [formControl]="_1_1_3_stallnummer_2" />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <mat-label>Anzahl Stallplätze</mat-label>
                        <input matInput type="number" [formControl]="_1_1_3_stallplatze_2" />
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>3.</td>
                <td>
                    <mat-form-field>
                        <mat-label>Stallnummer/-bezeichnung</mat-label>
                        <input matInput type="text" [formControl]="_1_1_3_stallnummer_3" />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <mat-label>Anzahl Stallplätze</mat-label>
                        <input matInput type="number" [formControl]="_1_1_3_stallplatze_3" />
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>4.</td>
                <td>
                    <mat-form-field>
                        <mat-label>Stallnummer/-bezeichnung</mat-label>
                        <input matInput type="text" [formControl]="_1_1_3_stallnummer_4" />
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <mat-label>Anzahl Stallplätze</mat-label>
                        <input matInput type="number" [formControl]="_1_1_3_stallplatze_4" />
                    </mat-form-field>
                </td>
            </tr>
        </tbody>
    </table>
    <ng-container *ngIf="istSaugferkelOderZuchtschwein()">
        <div class="one-row" style="margin-left: 12px;">
            <span class="phito-field">Isolationsstall am gleichen Standort</span>
            <mat-radio-group [formControl]="_1_1_3_isolations_stall">
                <mat-radio-button [value]="1">ja</mat-radio-button>
                <mat-radio-button [value]="0">nein</mat-radio-button>
            </mat-radio-group>
            <span class="spacer"></span>
        </div>
    </ng-container>
    <!--    <mat-form-field class="phito-field">-->
    <!--        <mat-label>Anzahl Stallplätze gesamt</mat-label>-->
    <!--        <input matInput type="text" [formControl]="_1_1_2_anzahl_staelle"/>-->
    <!--    </mat-form-field>-->
    <ng-container *ngIf="istFerkelOderMastschwein()" style="margin-top: 12px">
        <div class="one-row" style="margin-left: 12px;">
            <span class="phito-field">Sauenhalter</span>
            <mat-radio-group [formControl]="_1_1_3_sauenhalter">
                <mat-radio-button [value]="0">nein</mat-radio-button>
                <mat-radio-button [value]="1">ja</mat-radio-button>
                <mat-radio-button [value]="2">nicht zutreffend</mat-radio-button>
            </mat-radio-group>
            <span class="spacer"></span>
        </div>
    </ng-container>
    <ng-container *ngIf="istSaugferkelOderZuchtschwein()" style="margin-top: 12px; margin-left: 12px;">
        <mat-form-field class="phito-field twenty">
            <mat-label>Anzahl Sauen:</mat-label>
            <input matInput type="number" [formControl]="_1_1_3_anzahl_Sauen">
        </mat-form-field>
        <mat-form-field class="phito-field twenty">
            <mat-label>Anzahl Jungauen:</mat-label>
            <input matInput type="number" [formControl]="_1_1_3_anzahl_JungSauen">
        </mat-form-field>
    </ng-container>


    <!--

          Abschnitt 2.
         -->
    <mat-divider class="divider"></mat-divider>

    <h3 style="margin-top: 24px">2. Angaben zur tierärztlichen Betreuung</h3>
    <p style="margin-left: 12px;">
        Die tierärztliche Betreuung und Beratung für den Maßnahmenplan erfolgt
        durch folgenden Tierarzt:
    </p>
    <label for="betreuungsvertrag-id" style="margin-left: 12px;">Betreuungsvertrag:</label>
    <mat-radio-group id="betreuungsvertrag-id" class="phito-field" style="margin-right: 48px; margin-left: 12px;"
        [formControl]="_2_1_1_betreuungsvertrag">
        <mat-radio-button [value]="1">ja</mat-radio-button>
        <mat-radio-button [value]="0">nein</mat-radio-button>
    </mat-radio-group>

    <label for="weitere-tieraerzte-id" style="margin-left: 12px;">Weitere Tierärzte:</label>
    <mat-radio-group id="weitere-tieraerzte-id" class="phito-field" [formControl]="_2_2_1_weitere_aerzte"
        style="margin-left: 12px;">
        <mat-radio-button [value]="0">nein</mat-radio-button>
        <mat-radio-button [value]="1">ja</mat-radio-button>
    </mat-radio-group>

    <mat-form-field class="phito-field twenty" style="margin-right: 48px; margin-left: 12px;">
        <mat-label>Name, Anschrift</mat-label>
        <input matInput type="text" [formControl]="_2_2_1_weitere_aerzte_anschrift" />
    </mat-form-field>

    <br />

    <label for="weitere-berater-id" style="margin-left: 12px;">Weitere Berater:</label>
    <mat-radio-group id="weitere-berater-id" class="phito-field" style="margin-left: 12px;"
        [formControl]="_2_3_1_weitere_berater">
        <mat-radio-button [value]="0">nein</mat-radio-button>
        <mat-radio-button [value]="1">ja</mat-radio-button>
    </mat-radio-group>

    <mat-form-field class="phito-field twenty">
        <mat-label>Name, Anschrift:</mat-label>
        <input matInput type="text" [formControl]="_2_3_1_weitere_berater_anschrift" />
    </mat-form-field>
    <div class="one-row" style="margin-left: 12px;">
        <label for="integration">Integration:</label>
        <mat-radio-group id="integration" class="phito-field" [formControl]="_2_3_1_integration">
            <mat-radio-button [value]="0">nein</mat-radio-button>
            <mat-radio-button [value]="1">ja</mat-radio-button>
        </mat-radio-group>
        <span class="spacer"></span>
    </div>


    <!--

      Abschnitt 3.

     -->

    <h3 style="margin-top: 24px">3. Angaben zur Tierhaltung</h3>
    <div class="one-row" style="margin-left: 12px;">
        <span>QS-System-Teilnehmer:</span>
        <mat-radio-group class="phito-field" [formControl]="_3_1_7_qs_system_teilnehmer">
            <mat-radio-button [value]="0">nein</mat-radio-button>
            <mat-radio-button [value]="1">ja</mat-radio-button>
        </mat-radio-group>
        <span style="margin-left: 24px">Sonstiges:</span>
        <mat-form-field class="phito-field">
            <mat-label>Sonstiges:</mat-label>
            <input matInput type="text" [formControl]="_3_1_7_qs_system_teilnehmer_text" />
        </mat-form-field>
        <span class="spacer"></span>
    </div>

    <div class="one-row" style="margin-left: 12px;">
        <span>Bio-Siegel:</span>
        <mat-radio-group class="phito-field" [formControl]="_3_1_8_bio_siegel">
            <mat-radio-button [value]="0">nein</mat-radio-button>
            <mat-radio-button [value]="1">ja</mat-radio-button>
        </mat-radio-group>
        <span style="margin-left: 24px">Welches:</span>
        <mat-form-field class="phito-field">
            <mat-label>Bio-Siegel</mat-label>
            <input matInput type="text" [formControl]="_3_1_8_bio_siegel_text" />
        </mat-form-field>
        <span class="spacer"></span>
    </div>

    <div class="one-row" style="    margin-left: 12px;">
        <span>Tierwohlteilnahme:</span>
        <mat-radio-group class="phito-field" [formControl]="_3_1_9_tierwohl">
            <mat-radio-button [value]="0">nein</mat-radio-button>
            <mat-radio-button [value]="1">ja</mat-radio-button>
        </mat-radio-group>
        <span style="margin-left: 24px">Sonstige Programme:</span>
        <mat-form-field class="phito-field">
            <mat-label>Sonstige Programme:</mat-label>
            <input matInput type="text" [formControl]="_3_1_9_tierwohl_text" />
        </mat-form-field>
        <span class="spacer"></span>
    </div>

    <span class="one-row" style="margin-left: 12px;">Zukauf / Verkauf:</span>
    <div class="one-row" style="margin-left: 12px;">
        <mat-checkbox class="phito-field" [formControl]="_3_2_1_geschlossenes_system">geschlossenes System
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_3_2_2_zukauf">Zukauf
        </mat-checkbox>
        <mat-form-field class="phito-field twenty">
            <mat-label>Anzahl Herkünfte:</mat-label>
            <input matInput type="number" [formControl]="_3_2_3_herkuenfte" />
        </mat-form-field>
        <span class="spacer"></span>
    </div>

    <ng-container *ngIf="istSaugferkelOderZuchtschwein()" class="one-row" style="margin-left: 12px;">
        <mat-form-field>
            <mat-label>Alter und Gewicht bei Einstallung (Jungsauen)</mat-label>
            <input matInput type="text" [formControl]="_3_3_1_alter_jungsau" />
        </mat-form-field>
        <mat-form-field style="margin-left: 24px">
            <mat-label>Besatzdichte m²/Tier</mat-label>
            <input matInput type="number" [formControl]="_3_4_1_besatzdichte" />
        </mat-form-field>
        <mat-form-field style="margin-left: 24px">
            <mat-label>Tiere pro Bucht</mat-label>
            <input matInput type="number" [formControl]="_3_4_2tiere_per_bucht" />
        </mat-form-field>
        <span class="spacer"></span>
    </ng-container>


    <ng-container *ngIf="istFerkelOderMastschwein()" class="one-row" style="margin-left: 12px;">
        <div class="one-row">
            <div style="display: inline-block; margin-right: 48px">
                <span>Art und Weise der Mast - rein/raus:</span>
                <mat-checkbox class="phito-field" [formControl]="_3_1_2">abteilweise
                </mat-checkbox>
                <mat-checkbox class="phito-field" [formControl]="_3_1_3">stallweise
                </mat-checkbox>
                <mat-checkbox class="phito-field" [formControl]="_3_1_4">kontinuierlich
                </mat-checkbox>
                <mat-checkbox class="phito-field" [formControl]="_3_1_5">mit Auslauf
                </mat-checkbox>
                <mat-checkbox class="phito-field" [formControl]="_3_1_6">Freilandhaltung
                </mat-checkbox>
            </div>
        </div>
        <div class="one-row" style="margin-left: 12px;">
            <mat-form-field>
                <mat-label>Alter und Gewicht bei Einstallung</mat-label>
                <input matInput type="text" [formControl]="_3_3_1_alter_jungsau" />
            </mat-form-field>
            <span class="spacer"></span>
            <mat-form-field>
                <mat-label>Mastdauer</mat-label>
                <input matInput type="text" [formControl]="_3_3_2_mastdauer" />
            </mat-form-field>
            <span class="spacer"></span>
            <mat-form-field>
                <mat-label>Besatzdichte m²/Tier</mat-label>
                <input matInput type="text" [formControl]="_3_4_1_besatzdichte" />
            </mat-form-field>
            <span class="spacer"></span>
            <mat-form-field>
                <mat-label>Tiere pro Bucht</mat-label>
                <input matInput type="text" [formControl]="_3_4_2tiere_per_bucht" />
            </mat-form-field>
            <span class="spacer"></span>
            <mat-form-field>
                <mat-label>Leerstandzeit (i. d. Regel)</mat-label>
                <input matInput type="text" [formControl]="_3_5_1_leerstand_zeit" />
            </mat-form-field>
        </div>
    </ng-container>


    <span class="one-row" style="margin-left: 12px; font-weight: bold; font-size: .8rem">Art und Ausstattung der Ställe
        (z.B Einrichtung, Einstreu, Beleuchtung)</span>
    <div class="one-row">
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <span class="one-row" style="margin-right: 12px;">Boden:</span>
            <mat-checkbox class="phito-field" [formControl]="_3_6_1_vollspalten">Vollspalten
            </mat-checkbox>
            <mat-checkbox class="phito-field" [formControl]="_3_6_2_teilspalten">Teilspalten
            </mat-checkbox>
            <mat-checkbox class="phito-field" [formControl]="_3_6_3_beton">Betonspalten
            </mat-checkbox>
            <mat-checkbox class="phito-field" [formControl]="_3_6_4_kunststoff">Kunststoffspalten
            </mat-checkbox>
            <mat-checkbox class="phito-field" [formControl]="_3_6_5_stroh">Stroh
            </mat-checkbox>
            <mat-checkbox class="phito-field" [formControl]="_3_6_5_planbefestigt">Planbefestigt
            </mat-checkbox>
            <ng-container *ngIf="istSaugferkelOderZuchtschwein()">
                <br />
                <mat-checkbox class="phito-field" [formControl]="_3_6_6_sonstiges">
                    Sonstiges
                </mat-checkbox>
                <mat-form-field class="phito-field">
                    <mat-label>Sonstiges</mat-label>
                    <input matInput type="text" [formControl]="_3_6_6_sonstiges_text" />
                </mat-form-field>
            </ng-container>
        </div>
        <span class="spacer"></span>
    </div>


    <div class="one-row">
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <span class="one-row">Beschäftigungsmaterial:</span>
            <mat-checkbox class="phito-field" [formControl]="_3_7_1_kette">Kette
            </mat-checkbox>
            <mat-checkbox class="phito-field" [formControl]="_3_7_2_holz">Kette / Holz
            </mat-checkbox>
            <mat-checkbox class="phito-field" [formControl]="_3_7_3_seil">Seil
            </mat-checkbox>
            <mat-checkbox class="phito-field" [formControl]="_3_7_4_heu">Heu / Stroh
            </mat-checkbox>
            <ng-container *ngIf="istFerkelOderMastschwein()">
                <mat-form-field class="phito-field">
                    <mat-label>Sonstiges</mat-label>
                    <input matInput type="text" [formControl]="_3_7_5_sonstiges" />
                </mat-form-field>
                <mat-form-field class="phito-field">
                    <mat-label>Anzahl pro Bucht</mat-label>
                    <input matInput type="number" [formControl]="_3_7_6_anzahl_bucht" />
                </mat-form-field>
            </ng-container>
        </div>
        <span class="spacer"></span>
    </div>

    <ng-container *ngIf="istSaugferkelOderZuchtschwein()" style="margin-left: 12px;">
        <span class="one-row">Abferkelbuchten:</span>
        <div class="one-row">
            <mat-checkbox class="phito-field" [formControl]="_3_8_1_konventionelle_buchten">konventionelle Buchten
            </mat-checkbox>
        </div>
        <div class="one-row">
            <mat-checkbox class="phito-field" [formControl]="_3_8_3_bewegungsbuchten">Bewegungsbuchten
            </mat-checkbox>
        </div>
        <div class="one-row">
            <mat-checkbox class="phito-field" [formControl]="_3_8_3_freie_abferkelung">Freie Abferkelung
            </mat-checkbox>
        </div>

        <mat-form-field class="phito-field">
            <mat-label>Freie Abferkelbuchten:</mat-label>
            <input matInput type="number" [formControl]="_3_8_3_freie_abferkelbuchten" />
        </mat-form-field>

        <mat-form-field class="phito-field">
            <mat-label>Freie Abferkelplätze:</mat-label>
            <input matInput type="number" [formControl]="_3_8_3_freie_abferkelplaetze" />
        </mat-form-field>

        <div class="one-row">Absetzrhythmus:</div>
        <div class="one-row">
            <mat-checkbox class="phito-field" [formControl]="_3_8_8_rhytmus_1">1-Wochenrhythmus
            </mat-checkbox>
        </div>
        <div class="one-row">
            <mat-checkbox class="phito-field" [formControl]="_3_8_8_rhytmus_2">2-Wochenrhythmus
            </mat-checkbox>
        </div>
        <div class="one-row">
            <mat-checkbox class="phito-field" [formControl]="_3_8_8_rhytmus_3">3-Wochenrhythmus
            </mat-checkbox>
        </div>
        <div class="one-row">
            <mat-checkbox class="phito-field" [formControl]="_3_8_8_rhytmus_4">5-Wochenrhythmus
            </mat-checkbox>
        </div>
        <div class="one-row">
            <mat-checkbox class="phito-field" [formControl]="_3_8_8_rhytmus_5">5-Wochenrhythmus
            </mat-checkbox>
        </div>
        <div class="one-row">
            <mat-checkbox class="phito-field" [formControl]="_3_8_8_rhytmus_6">6-Wochenrhythmus
            </mat-checkbox>
        </div>
        <div class="one-row">
            <mat-checkbox class="phito-field" [formControl]="_3_8_8_rhytmus_7">Sonstiger Rhythmus
            </mat-checkbox>
        </div>
        <mat-form-field class="phito-field">
            <mat-label>Sonstiger Rhythmus</mat-label>
            <input matInput type="text" [formControl]="_3_8_rhythmus_sonstiger" />
        </mat-form-field>
        <div class="one-row">
            <mat-form-field class="phito-field">
                <mat-label>Durchschnittliches Absetzalter</mat-label>
                <input matInput type="number" [formControl]="_3_9_1_absetzalter" />
            </mat-form-field>
            <mat-form-field class="phito-field">
                <mat-label>Durchschnittliches Absetzgewicht</mat-label>
                <input matInput type="number" [formControl]="_3_9_2_absetzgewicht" />
            </mat-form-field>
            <span class="spacer"></span>
        </div>
        <div class="one-row">
            <span class="phito-field">Waschung der Sauen vor Einstallung in Abferkelbereich</span>
            <mat-radio-group [formControl]="_3_9_3_waschung">
                <mat-radio-button [value]="0">nie</mat-radio-button>
                <mat-radio-button [value]="1">manchmal</mat-radio-button>
                <mat-radio-button [value]="2">immer</mat-radio-button>
            </mat-radio-group>
            <span class="spacer"></span>
        </div>
        <div class="one-row">
            <span class="phito-field">Umsetzung der Saugferkel</span>
            <mat-radio-group [formControl]="_3_9_4_umsetzung">
                <mat-radio-button [value]="0">nein</mat-radio-button>
                <mat-radio-button [value]="1">ja, einmal</mat-radio-button>
                <mat-radio-button [value]="2">ja, mehrmals</mat-radio-button>
            </mat-radio-group>
            <span class="spacer"></span>
        </div>
        <span class="one-row" style="margin-left: 12px;">zootechnische Maßnahmen:</span>
        <div class="one-row">
            <mat-checkbox class="phito-field" [formControl]="_3_9_5_kastrieren">Kastrieren
            </mat-checkbox>
            <mat-checkbox class="phito-field" [formControl]="_3_9_6_schwaenze_kuerzen">Schwänze kürzen
            </mat-checkbox>
            <mat-checkbox class="phito-field" [formControl]="_3_9_7_zaehne_schleiffen">Zähne schleifen
            </mat-checkbox>
            <span class="spacer"></span>
        </div>
        <span class="phito-field">Kenndaten:</span>
        <div class="one-row">
            <mat-form-field class="phito-field">
                <mat-label>Remontierungsrate (%)</mat-label>
                <input matInput type="number" [formControl]="_3_9_8_remontierungsrate" />
            </mat-form-field>
            <mat-form-field class="phito-field">
                <mat-label>Abgesetzte Ferkel (pro Sau/Jahr)</mat-label>
                <input matInput type="number" [formControl]="_3_9_9_abgesetzte_ferkel" />
            </mat-form-field>
            <mat-form-field class="phito-field">
                <mat-label>Trächtigkeitsdauer (Tage)</mat-label>
                <input matInput type="number" [formControl]="_3_9_10_traechtigkeitsdauer" />
            </mat-form-field>
            <span class="spacer"></span>
        </div>
        <div class="one-row">
            <mat-form-field class="phito-field">
                <mat-label>Anzahl Würfe (pro Sau/Jahr)</mat-label>
                <input matInput type="number" [formControl]="_3_9_11_wuerfe" />
            </mat-form-field>
            <mat-form-field class="phito-field">
                <mat-label>Abferkelrate (%)</mat-label>
                <input matInput type="number" [formControl]="_3_9_12_abferkelrate" />
            </mat-form-field>
            <mat-form-field class="phito-field">
                <mat-label>Anzahl lebendgeborener Ferkel (pro Sau/Jahr)</mat-label>
                <input matInput type="number" [formControl]="_3_9_13_lebendgeboren" />
            </mat-form-field>
            <mat-form-field class="phito-field">
                <mat-label>Erstbelegungsalter (Tage)</mat-label>
                <input matInput type="number" [formControl]="_3_9_14_erstbelegungsalter" />
            </mat-form-field>
            <span class="spacer"></span>
        </div>
    </ng-container>
    <!--


        Abschnitt 4.

        -->
    <h3>4. Angaben zu Klimatechnik</h3>
    <span class="one-row" style="margin-left: 12px;">Heizung und Lüftung (z.B. Technik, Qualität):</span>
    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <span class="one-row" style="margin-right: 12px;">Lüftungsart:</span>
        <mat-checkbox class="phito-field" [formControl]="_4_1_1_zwangslueftung">Zwangslüftung
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_4_1_2_deckenlueftung">Deckenlüftung
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_4_1_3_tuerganglueftung">Türganglüftung
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_4_1_4_freie_lueftung">freie Lüftung
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_4_1_5_unterflurabsaugung">Unterflurabsaugung
        </mat-checkbox>
    </div>
    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <mat-form-field class="phito-field">
            <mat-label>Sonstiges 1</mat-label>
            <input matInput type="text" [formControl]="_4_1_6_sonstiges_1" />
        </mat-form-field>
        <mat-form-field class="phito-field">
            <mat-label>Sonstiges 2</mat-label>
            <input matInput type="text" [formControl]="_4_1_7_sonstiges_2" />
        </mat-form-field>
    </div>
    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <span *ngIf="istFerkelOderMastschwein()" class="one-row">Heiztechnik:</span>
        <span *ngIf="istSaugferkelOderZuchtschwein()" class="one-row">Heiztechnik Wartebuchten:</span>
        <mat-checkbox class="phito-field" [formControl]="_4_1_8_heizkanone">Heizkanone
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_4_1_9_kenvektor">Konvektor
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_4_1_10_deltarohre">Deltarohre
        </mat-checkbox>
        <mat-form-field class="phito-field">
            <mat-label>Sonstiges 3</mat-label>
            <input matInput type="text" [formControl]="_4_1_11_sonstiges_3" />
        </mat-form-field>
    </div>
    <div *ngIf="istSaugferkelOderZuchtschwein()"
        style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <span class="one-row">Heiztechnik Abferkelstall:</span>
        <mat-checkbox class="phito-field" [formControl]="_4_1_12_waermelampe">Wärmelampe
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_4_1_12_beheitzte_bodenlampe">beheizte Bodenplatte
        </mat-checkbox>
        <mat-form-field class="phito-field">
            <mat-label>Sonstiges 4</mat-label>
            <input matInput type="text" [formControl]="_4_1_12_sonstiges_4" />
        </mat-form-field>
    </div>
    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <span class="one-row">Stallklimatechnik:</span>
        <mat-checkbox class="phito-field" [formControl]="_4_1_13_regelmaessig">regelmäßig
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_4_1_14_bei_bedarf">bei Bedarf
        </mat-checkbox>
    </div>


    <!--


        Abschnitt 5.

        -->

    <h3>5. Angaben zu Futter- und Wasserversorgung</h3>
    <span style="margin-left: 12px; width: 100%;">Art der Fütterung <small>(z.B. Technik, Lagerung,
            Herkunft):</small></span>
    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <mat-checkbox class="phito-field" [formControl]="_5_1_1_breiautomat">Breiautomat
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_5_1_2_trockenautomat">Trockenautomat
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_5_1_3_fluessigfutter">Flüssigfutter
        </mat-checkbox>
        <mat-form-field class="phito-field">
            <mat-label>Sonstiges</mat-label>
            <input matInput type="text" [formControl]="_5_1_7_sonstiges" />
        </mat-form-field>
    </div>
    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <mat-checkbox class="phito-field" [formControl]="_5_1_9_trockenfuetterung">Trockenfütterung
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_5_1_10_abruffuetterung">Abruffütterung
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_5_1_11_raufutter_sau">Raufutter Sau
        </mat-checkbox>
    </div>
    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <mat-checkbox class="phito-field" [formControl]="_5_1_4_mehlfoermig">mehlförmig
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_5_1_5_garuliert">granuliert
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_5_1_6_pelletiert">pelletiert</mat-checkbox>
        <mat-form-field class="phito-field">
            <mat-label>Anzahl der Tiere pro Futterplatz</mat-label>
            <input matInput type="number" [formControl]="_5_1_8_anzahl_tiere" />
        </mat-form-field>
    </div>
    <div *ngIf="istSaugferkelOderZuchtschwein()"
        style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <span class="one-row">Zufütterung der Ferkel:</span>
        <mat-form-field class="phito-field">
            <mat-label>Zufütterung der Ferkel mit:</mat-label>
            <input matInput type="text" [formControl]="_5_3_1_zufuetterung_text" />
        </mat-form-field>
        <mat-checkbox class="phito-field" [formControl]="_5_3_2_kuenstliche_amme">Einsatz künstliche Amme
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_5_3_3_natuerliche_amme">Einsatz natürliche Amme
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_5_3_4_zusaetliche_milch">zusätzliche Milch
        </mat-checkbox>
    </div>

    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <span style="width: 100%;">Wasserversorgung
            <small>(z.B. Art und Anzahl der Tränke, Wasserqualität,
                Untersuchung):</small></span>
        <mat-checkbox class="phito-field" [formControl]="_5_2_1_brunnenwasser">Brunnenwasser
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_5_2_2_stadtwasser">Stadtwasser
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_5_2_3_letzte_wasseruntersuchung">letzte
            Wasseruntersuchung am
        </mat-checkbox>
        <mat-form-field class="phito-field">
            <mat-label>Letzte Untersuchung am:</mat-label>
            <input matInput [disabled]="!_5_2_3_letzte_wasseruntersuchung" type="text"
                [formControl]="_5_2_4_letzte_wasseruntersuchung_text" />
        </mat-form-field>
        <mat-form-field class="phito-field">
            <mat-label>Anzahl Tiere pro Tränke</mat-label>
            <input matInput type="text" [formControl]="_5_2_5_anzahl_tiere" />
        </mat-form-field>
        <mat-form-field class="phito-field">
            <mat-label>Art der Tränke</mat-label>
            <input matInput type="text" [formControl]="_5_2_6_art_traenke" />
        </mat-form-field>
    </div>

    <!--


        Abschnitt 6.

        -->

    <h3>6. Art und Weise der Verabreichung von Antibiotika</h3>
    <div style="display: inline-block; margin-right: 48px; width: 100%">
        <mat-checkbox class="phito-field" [formControl]="_6_1_1">Injektion
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_6_1_2">Futter
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_6_1_3">Wasser
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_6_1_4">Mischer / Vorbehälter
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_6_1_5">Dosierer
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_6_1_6">tiernah
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_6_1_7">lokal (z.B. Spray f. Haut / Klauen)
        </mat-checkbox>
        <span class="spacer"></span>
    </div>

    <div style="display: inline-block; margin: 48px 0 0 12px; width: 100%">
        <span style="margin-top: 12px">Leitfaden "Orale Anwendung von Tierarzneimitteln" bekannt?</span>
        <mat-radio-group class="phito-field" [formControl]="_6_2_1">
            <mat-radio-button [value]="1">ja</mat-radio-button>
            <mat-radio-button [value]="0">nein</mat-radio-button>
        </mat-radio-group>
        <span class="spacer"></span>
    </div>

    <div style="display: inline-block; margin: 48px 0 0 12px; width: 100%">
        <span style="margin-top: 12px">Risikomanagementplan vorhanden?:</span>
        <mat-radio-group class="phito-field" [formControl]="_6_3_1">
            <mat-radio-button [value]="1">ja</mat-radio-button>
            <mat-radio-button [value]="0">nein</mat-radio-button>
        </mat-radio-group>
    </div>

    <!--


        Abschnitt 7.

        -->
    <h3 style="margin-top: 24px">7. Angabe zur Hygiene</h3>

    <div style="display: inline-block; margin-left: 12px; margin-right: 48px">
        <span class="one-row">Externe Biosicherheit des Betriebes</span>
        <ng-container class="one-row">
            <mat-form-field class="phito-field">
                <mat-label>Art der Schutzkleidung</mat-label>
                <input matInput type="text" [formControl]="_7_1_1_art_schutzkleidung" />
            </mat-form-field>
            <mat-form-field class="phito-field">
                <mat-label>Art der Hygieneschleuse</mat-label>
                <input matInput type="text" [formControl]="_7_1_2_art_hygieneschleuse" />
            </mat-form-field>
            <span class="spacer"></span>
        </ng-container>

        <span>Desinfektionsmatten/-wannen vorhanden:</span>
        <ng-container class="one-row">
            <mat-radio-group class="phito-field" [formControl]="_7_1_2_matten_vorhanden">
                <mat-radio-button [value]="0">nein</mat-radio-button>
                <mat-radio-button [value]="1">ja, mit</mat-radio-button>
            </mat-radio-group>
            <mat-form-field class="phito-field">
                <mat-label>Desinfektionsmatten/ -wannen vorhanden:</mat-label>
                <input matInput type="text" [formControl]="_7_1_2_matten_vorhanden_text" />
            </mat-form-field>
        </ng-container>
    </div>

    <span class="one-row" style="margin-left: 12px; font-size: .8rem; font-weight: bold;">Art und Umfang der internen
        Biosicherheit</span>
    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <span>Reinigung Stall:</span>
        <mat-radio-group class="phito-field" [formControl]="_7_2_1_reinigung_stall">
            <mat-radio-button [value]="0">nein</mat-radio-button>
            <mat-radio-button [value]="1">ja, mit Reinigungsmittel</mat-radio-button>
        </mat-radio-group>
        <mat-form-field class="phito-field twenty">
            <mat-label>Reinigungsmittel</mat-label>
            <input matInput type="text" [formControl]="_7_1_4_reinigung_stall_text" />
        </mat-form-field>
        <mat-checkbox class="phito-field" [formControl]="_7_1_5_reinigung_stall_selbst">Ja, durch Betriebspersonal
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_7_1_6_reinigung_stall_fremdfirma">Ja, durch Fachfirma
        </mat-checkbox>
    </div>

    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <span>Desinfektion Stall</span>
        <mat-radio-group class="phito-field" [formControl]="_7_2_2_desinfektion_stall">
            <mat-radio-button [value]="0">nein</mat-radio-button>
            <mat-radio-button [value]="1">ja, mit Desinfektionsmittel</mat-radio-button>
        </mat-radio-group>
        <mat-form-field class="phito-field twenty">
            <mat-label>Desinfektionsmittel</mat-label>
            <input matInput type="text" [formControl]="_7_1_6_desinfektion_stall_text" />
        </mat-form-field>
        <mat-checkbox class="phito-field" [formControl]="_7_1_6_desinfektion_stall_selbst">Ja, durch
            Betriebspersonal
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_7_1_6_desinfektion_stall_fremdfirma">Ja, durch
            Fachfirma
        </mat-checkbox>
    </div>

    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <span class="one-row">Wasserleitungen:</span>
        <mat-checkbox class="phito-field" [formControl]="_7_2_5_reingung_selbst">Reinigung</mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_7_2_6_reinigung_desinfektion">Desinfektion</mat-checkbox>
        <mat-form-field class="phito-field">
            <mat-label>Desinfektion mit:</mat-label>
            <input matInput type="text" [formControl]="_7_2_5_reingung_desinfektion_text" />
        </mat-form-field>
        <mat-checkbox class="phito-field" [formControl]="_7_2_6_nach_antibiotika">nach Antibiotika-Gabe
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_7_2_7_routine">routinemäßig
        </mat-checkbox>
    </div>

    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <span class="one-row">Fütterungseinrichtungen</span>
        <mat-checkbox class="phito-field" [formControl]="_7_2_7_reinigung_futterleitungen">Reinigung
            Futterleitungen
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_7_2_8_reinigung_futtertroege">Reinigung Futterschalen
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_7_2_9_reinigung_futtersilo">Reinigung Futtersilo
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_7_2_10_nach_antibiotika">nach Antibiotika-Gabe
        </mat-checkbox>
    </div>

    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <span>Schädlingsbekämpfung</span>
        <mat-checkbox class="phito-field" [formControl]="_7_2_12_durch_fremdfirma">durch Fachfirma
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_7_2_13_selbst">durch Betriebspersonal(selbst)
        </mat-checkbox>
    </div>
    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <span>Dokumentation vorhanden</span>
        <mat-radio-group class="phito-field" [formControl]="_7_1_14_doku_vorhanden">
            <mat-radio-button [value]="0">nein</mat-radio-button>
            <mat-radio-button [value]="1">ja</mat-radio-button>
        </mat-radio-group>
    </div>

    <h4 style="margin-top: 24px; margin-left: 12px; font-weight: bold; font-size: .8rem">Absonderungsmöglichkeit:</h4>
    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%"
        *ngIf="istSaugferkelOderZuchtschwein()">
        <span>Eigenes Ammenabteil</span>
        <mat-radio-group class="phito-field" [formControl]="_7_3_1_eigenes_ammenabteil">
            <mat-radio-button [value]="0">nein</mat-radio-button>
            <mat-radio-button [value]="1">ja</mat-radio-button>
        </mat-radio-group>
    </div>

    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <span>Krankenbucht</span>
        <mat-radio-group class="phito-field" [formControl]="_7_5_y_n">
            <mat-radio-button [value]="0">nein</mat-radio-button>
            <mat-radio-button [value]="1">ja, Anzahl</mat-radio-button>
        </mat-radio-group>
        <mat-form-field class="phito-field">
            <mat-label>Anzahl</mat-label>
            <input matInput type="number" [formControl]="_7_5_y_n_anzahl" />
        </mat-form-field>
        <mat-checkbox class="phito-field" [formControl]="_7_3_4_mit_gummimatte">mit Gummimatte
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_7_3_5_mit_stroh">mit Stroh
        </mat-checkbox>
        <mat-form-field class="phito-field">
            <mat-label>Sonstiges</mat-label>
            <input matInput type="text" [formControl]="_7_3_6_sonstiges" />
        </mat-form-field>
    </div>
    <div *ngIf="istSaugferkelOderZuchtschwein()"
        style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <mat-checkbox class="phito-field" [formControl]="_7_3_7_sonst_absonderung">sonstige
            Absonderungsmöglichkeiten
        </mat-checkbox>
        <mat-form-field class="phito-field">
            <mat-label>Sonstiges</mat-label>
            <input matInput type="text" [formControl]="_7_3_8_sonst_absonderung_text" />
        </mat-form-field>
    </div>


    <h3>8. Gesundheitsmanagement</h3>
    <ng-container *ngIf="istFerkelOderMastschwein()">
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <span>Immunprohylaxe <small>(Impfschema):</small></span>
            <mat-checkbox class="phito-field" [formControl]="_8_1_1">vorgeimpft beim Ferkelerzeuger mit:
            </mat-checkbox>
            <mat-form-field class="phito-field">
                <mat-label>Impfstoff</mat-label>
                <input matInput type="text" [formControl]="_8_1_2" />
            </mat-form-field>
            <mat-checkbox class="phito-field" [formControl]="_8_1_3">im Bestand geimpft mit:
            </mat-checkbox>
            <mat-form-field class="phito-field">
                <mat-label>Impfstoff</mat-label>
                <input matInput type="text" [formControl]="_8_1_4" />
            </mat-form-field>
        </div>
    </ng-container>

    <ng-container *ngIf="istSaugferkelOderZuchtschwein()">
        <h4>
            Impfschema Zuchtschweine
        </h4>
        <mat-form-field class="phito-field hundred">
            <mat-label>Impfschema Zuchtschweine</mat-label>
            <textarea [kieneTextBaustein]="getTextbaustein9()" matInput [formControl]="_8_impfschema_zuchtschweine"
                rows="4" (textChanged)="setImpfschemaZuchtschweine($event)"></textarea>
        </mat-form-field>

        <h4>
            Impfschema Saugferkel
        </h4>
        <mat-form-field class="phito-field hundred">
            <mat-label>Impfschema Saugferkel</mat-label>
            <textarea [kieneTextBaustein]="getTextbaustein9()" matInput [formControl]="_8_impfschema_saugferkel"
                rows="4" (textChanged)="setImpfschemaSaugferkel($event)"></textarea>
        </mat-form-field>
    </ng-container>

    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <span>Behandlung gegen: Endoparasiten</span>
        <mat-radio-group class="phito-field" [formControl]="_8_1_5">
            <mat-radio-button [value]="1">ja</mat-radio-button>
            <mat-radio-button [value]="0">nein</mat-radio-button>
        </mat-radio-group>
        <span>Ektoparasiten</span>
        <mat-radio-group class="phito-field" [formControl]="_8_1_6">
            <mat-radio-button [value]="1">ja</mat-radio-button>
            <mat-radio-button [value]="0">nein</mat-radio-button>
        </mat-radio-group>
        <!--<mat-checkbox class="phito-field" [formControl]="_8_1_7">sonstiges:
        </mat-checkbox>-->
        <mat-form-field class="phito-field twenty">
            <mat-label>Sonstiges</mat-label>
            <input matInput type="text" [formControl]="_8_1_8" />
        </mat-form-field>
    </div>
    <!--


        Abschnitt 9.

        -->

    <h3>9. Gründe für das Überschreiten der Kennzahl 2:</h3>
    <span class="one-row" style="margin-left: 12px;">Schwerpunkte der Erkankung:</span>
    <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
        <mat-checkbox class="phito-field" [formControl]="_9_1_1_atemwege">Atemwege
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_9_1_2_verdauung">Verdauungsapparat
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_9_1_3_bewegung">Bewegungsapparat
        </mat-checkbox>
        <mat-checkbox class="phito-field" [formControl]="_9_1_3_1_zns">ZNS
        </mat-checkbox>
        <ng-container *ngIf="istSaugferkelOderZuchtschwein()">
            <mat-checkbox class="phito-field" [formControl]="_9_1_3_2_gesaeuge">Gesäuge
            </mat-checkbox>
            <mat-checkbox class="phito-field" [formControl]="_9_1_3_3_geschlecht">Geschlechtsapparat
            </mat-checkbox>
        </ng-container>
        <!--<mat-checkbox class="phito-field" [formControl]="_9_1_4_sonstiges">Sonstiges
        </mat-checkbox>-->
        <mat-form-field class="phito-field">
            <mat-label>Sonstiges</mat-label>
            <input matInput type="text" [formControl]="_9_1_5_sonstiges_text" />
        </mat-form-field>
    </div>

    <div style="display: inline-block; margin-right: 48px; margin-left: 12px; width: 100%">
        <mat-form-field class="phito-field twenty">
            <mat-label>Anzahl verendeter / getöteter Tiere im
                Kalenderhalbjahr
            </mat-label>
            <input matInput type="number" [formControl]="_9_2_1_anzahl_verendet" />
        </mat-form-field>

        <mat-form-field class="phito-field twenty">
            <mat-label>Verlustrate in %</mat-label>
            <input matInput type="number" [formControl]="_9_verlustrate" />
        </mat-form-field>
    </div>

    <h4>
        Angaben zu Krankheitsgeschehen
        <small>(z.B. Zeitpunkt, Dauer, Anzahl erkrankter Tiere, klinische
            Diagnose, Behandlung):</small>
    </h4>
    <mat-form-field class="phito-field hundred">
        <mat-label>Angaben zu Krankheitsgeschehen</mat-label>
        <textarea [kieneTextBaustein]="getTextbaustein9()" matInput [formControl]="_9_3_1" rows="6"
            (textChanged)="setAngabenKrankheitsgeschehen($event)"></textarea>
    </mat-form-field>

    <h4>
        Weiterführende Diagnostik
        <small>(z.B. Erregernachweis, Resistenztest, Befunde, ggf. als
            Anlage):</small>
    </h4>

    <!--<div>
        Dokumente von {{ zeitraum_von }} bis {{ zeitraum_bis }}
        <button
          *ngIf="anzahl_befunde + anzahl_antibiogramme + anzahl_abgabebelege > 0"
          mat-icon-button
          color="accent"
          (click)="showDokumente()"
        >
          <mat-icon>cloud_download</mat-icon>
        </button>
        </div>
        <div *ngIf="anzahl_befunde !== null" class="one-row">
        <span>Es wurde(n) {{ anzahl_befunde }} Befund(e) gefunden. </span>
        </div>
        <div *ngIf="anzahl_antibiogramme !== null" class="one-row">
        <span
          >Es wurde(n) {{ anzahl_antibiogramme }} Antibiogramm(e) gefunden.
        </span>
        </div>
        <div *ngIf="anzahl_abgabebelege !== null" class="one-row">
        <span>Es wurde(n) {{ anzahl_abgabebelege }} Abgabebeleg(e) gefunden. </span>
        </div>-->
    <mat-checkbox class="phito-field" [formControl]="_9_4_erregernachweis_y_n_ctrl">Erregernachweis /
        Resistenztest
        <small>(Befunde ggf. als Kopie)</small></mat-checkbox>
    <mat-form-field class="phito-field hundred">
        <mat-label>Erregernachweis / Resistenztest</mat-label>
        <textarea [kieneTextBaustein]="getTextbaustein9()" matInput [formControl]="_erregernachweis_text" rows="6"
            (textChanged)="setErregernachweis($event)"></textarea>
    </mat-form-field>
    <mat-checkbox class="phito-field" [formControl]="_9_4_sektion_y_n_ctrl">Sektion <small>(Befunde ggf. als
            Kopie)</small>
    </mat-checkbox>
    <mat-form-field class="phito-field hundred">
        <mat-label>Sektion</mat-label>
        <textarea [kieneTextBaustein]="getTextbaustein9()" matInput [formControl]="_9_4_4" rows="6"
            (textChanged)="setSektion($event)"></textarea>
    </mat-form-field>
    <mat-checkbox class="phito-field" [formControl]="_9_4_5">Sonstiges <small>(Abgabebelege ggf. als
            Kopie)</small></mat-checkbox>
    <mat-form-field class="phito-field hundred">
        <mat-label>Sonstiges</mat-label>
        <textarea [kieneTextBaustein]="getTextbaustein9()" matInput [formControl]="_9_4_6" rows="6"
            (textChanged)="setSonstiges($event)"></textarea>
    </mat-form-field>

    <div>
        <h4>Anhänge</h4>
        Anlage A
        <button mat-icon-button color="accent" (click)="downloadAnlageA()">
            <mat-icon>cloud_download</mat-icon>
        </button>
        <br />
        <mat-checkbox [formControl]="bbpAnhaengenCtrl">Bestandsbesuchsprotokolle anhängen
        </mat-checkbox>
        <button mat-icon-button color="accent" (click)="downloadBBP()">
            <mat-icon>cloud_download</mat-icon>
        </button>
        <br />
        <mat-checkbox [formControl]="befundeAnhaengenCtrl">
            Befunde anhängen
        </mat-checkbox>
        <button mat-icon-button color="accent" (click)="downloadBefunde()">
            <mat-icon>cloud_download</mat-icon>
        </button>
    </div>

    <h3>
        10. Angaben zur tierärztlichen Beratung
        <small *ngIf="_10_focus === true">(
            <mat-icon color="primary">info</mat-icon>
            F4 zum Auswählen von
            Textvorlagen)</small>
    </h3>
    <mat-form-field class="phito-field hundred">
        <mat-label>Ergebnis der Beratung</mat-label>
        <textarea [kieneTextBaustein]="getTextbaustein10und11()" (textChanged)="setValue10and11($event)" matInput
            [formControl]="_10_1_1" (focus)="_10_focus = true" (focusout)="_10_focus = false" rows="6"></textarea>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Datum</mat-label>
        <input matInput [matDatepicker]="picker_10_2" [formControl]="_10_2_1" placeholder="dd.MM.jjjj" />
        <mat-datepicker-toggle matSuffix [for]="picker_10_2"></mat-datepicker-toggle>
        <mat-datepicker #picker_10_2></mat-datepicker>
    </mat-form-field>
    <mat-checkbox class="phito-field" [formControl]="_10_3_1">Hiermit stimme ich zu, dass die oben beratende
        Tierärztin/
        der oben
        beratende Tierarzt und die zuständige <br />Überwachungsbehörde (LAVES)
        Informationen über meinen Betrieb ausschließlich in Bezug auf die
        Antibiotikaminimierung austauschen dürfen.
    </mat-checkbox>

    <h3>
        11. Maßnahmen zur Verringerung des Antibiotika-Einsatzes
        <small *ngIf="_11_focus === true">(
            <mat-icon color="primary">info</mat-icon>
            F4 zum Auswählen von
            Textvorlagen)</small>
    </h3>
    <mat-form-field class="phito-field hundred">
        <mat-label>Maßnahmen (z.B.: Impfung, Optimierung Futter / Wasser / Stallklima,
            Hygienemaßnahmen)
        </mat-label>
        <textarea [kieneTextBaustein]="getTextbaustein10und11()" (textChanged)="setValue10and11($event)" matInput
            [formControl]="_11_1_1" (focus)="_11_focus = true" (focusout)="_11_focus = false" rows="6"></textarea>
    </mat-form-field>
    <mat-form-field class="phito-field hundred">
        <mat-label>Zeitraum der Umsetzung</mat-label>
        <textarea [kieneTextBaustein]="getTextbaustein10und11()" (textChanged)="setValue10and11($event)" matInput
            [formControl]="_11_2_1" (focus)="_11_focus = true" (focusout)="_11_focus = false" rows="6"></textarea>
    </mat-form-field>

    <mat-divider style="margin-bottom: 40px"></mat-divider>

    <mat-form-field class="phito-field">
        <mat-label>Ort</mat-label>
        <input matInput type="text" [formControl]="_11_3_1" />
    </mat-form-field>

    <mat-form-field>
        <mat-label>Datum</mat-label>
        <input matInput [matDatepicker]="picker_11_3" [formControl]="_11_3_2" placeholder="dd.MM.jjjj" />
        <mat-datepicker-toggle matSuffix [for]="picker_11_3"></mat-datepicker-toggle>
        <mat-datepicker #picker_11_3></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="phito-field thirty">
        <mat-label>Angehöriger / Verantwortlicher des Betriebes</mat-label>
        <input matInput type="text" [formControl]="_11_3_3" />
    </mat-form-field>

    <br />
    <div class="one-row">
        <!-- <button *ngIf="mps.status_id !== 62" mat-raised-button color="primary" (click)="save(false)">Zwischenspeichern</button> -->
        <ng-container *ngIf="isKunde() && !mps.unterschrift_kunde">
            <button mat-flat-button color="accent" (click)="signMnp()">
                <mat-icon>edit_note</mat-icon>
                <span>unterschreiben</span>
            </button>
        </ng-container>
        <ng-container *ngIf="!isKunde()">
            <kiene-sign-button [kuid]="mps.kuid" [tierarzt_id]="mps.tierarzt_id" [kunde_id]="mps.kunde_id"
                (kuid)="mps.kuid = $event"></kiene-sign-button>
        </ng-container>

        <span class="spacer"></span>

        <ng-container *kienePermission="[permissionAbschliessen]">
            <button *ngIf="mps.status_id !== 62" mat-flat-button color="accent" (click)="save(true)">
                Abschließen
            </button>
        </ng-container>
    </div>
</div>