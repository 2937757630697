<div style="display: flex; align-items: start; justify-content: center; flex-direction: column">
    <ng-container *ngFor="let month of monthsToShow">

        <div class="one-row" style="width: 100%; margin-bottom: 12px">
            <div style="width: 40%; display: flex; align-items: center; justify-content: end">
                <button (click)="shiftPrevMonth()" color="accent" mat-icon-button style="transform: scale(1.25)">
                    <mat-icon>chevron_left</mat-icon>
                </button>
            </div>
            <div style="width: 20%; display: flex; align-items: center; justify-content: center">
                <h3>{{ month | date: 'MMMM Y' }}</h3>
            </div>
            <div style="width: 40%; display: flex; align-items: center; justify-content: start">
                <button (click)="shiftNextMonth()" color="accent" mat-icon-button style="transform: scale(1.25)">
                    <mat-icon>chevron_right</mat-icon>
                </button>
                <button (click)="shiftToToday()" [matTooltip]="'Ansicht zurücksetzen'" color="accent" mat-icon-button
                        style="transform: scale(1)">
                    <mat-icon>restart_alt</mat-icon>
                </button>
            </div>
        </div>

        <mwl-calendar-month-view
            #monthView
            (beforeViewRender)="beforeMonthViewRender($event, month)"
            [cellTemplate]="weekTemplate"
            [events]="kalenderEvents.get(month)"
            [refresh]="refresh"
            [viewDate]="month"
            [weekStartsOn]="1"
            style="width: 100%"
        >
        </mwl-calendar-month-view>

        <ng-template #weekTemplate let-day="day">
            <div class="one-row">
                <span style=" margin: 6px; color: #777; font-size: 1.25em">{{ getDay(day) }}</span>
                <mat-hint style="margin: 6px">{{ getFeiertag(day) }}</mat-hint>
            </div>
            <mat-list *ngFor="let e of day.events" style="margin-bottom: 6px">
                <div *ngIf="isWeekday(day)">
                    <div (click)="handleEventClick(e)"
                         [matTooltip]="getDescriptionForEvent(e)"
                         [style.backgroundColor]="getBackgroundColor(e, day)"
                         [style.color]="getColor(e, day)"
                         style="width:100%; display: flex; align-items: center; justify-content: center">
                        <div style="padding: 3px">{{ getTitle(e, day) }}</div>
                    </div>
                </div>
                <div *ngIf="!isWeekday(day) && e.eintragtyp_id > 2">
                    <div (click)="handleEventClick(e)"
                         [matTooltip]="getDescriptionForEvent(e)"
                         [style.backgroundColor]="getBackgroundColor(e, day)"
                         [style.color]="getColor(e,day)"
                         style="width:100%; display: flex; align-items: center; justify-content: center">
                        <div style="padding: 3px">{{ getTitle(e, day) }}</div>
                    </div>
                </div>
            </mat-list>
        </ng-template>

    </ng-container>
</div>


