import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Gruppe } from '../classes/gruppe';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { StammdatenService } from '../../stammdaten/stammdaten.service';
import { MessageService } from '../../../services/message-service/message.service';
import { Client } from '../../client/classes/client';
import { GruppeAuswaehlenDialogInput, GruppeAuswaehlenDialogResult } from './gruppe-auswaehlen-dialog';

@Component({
    selector: 'kiene-gruppe-auswaehlen-dialog',
    templateUrl: './gruppe-auswaehlen-dialog.component.html',
    styleUrls: ['./gruppe-auswaehlen-dialog.component.scss'],
})
export class GruppeAuswaehlenDialogComponent implements OnInit, OnDestroy {
    gruppen: Gruppe[] = null;
    isLoading = true;
    ladeGruppenSub: Subscription;
    selectedVVVO: Client;

    constructor(
        public dialogRef: MatDialogRef<GruppeAuswaehlenDialogComponent, GruppeAuswaehlenDialogResult>,
        @Inject(MAT_DIALOG_DATA) public data: GruppeAuswaehlenDialogInput,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        private stammdatenService: StammdatenService
    ) { }

    ngOnDestroy(): void {
        this.ladeGruppenSub?.unsubscribe();
    }

    ngOnInit(): void {
        this.ladeGruppen(this.data?.kunde_id, this.data?.except_gruppe_id);
    }

    ladeGruppen(kunde_id: number, except_gruppe_id?: number) {
        this.isLoading = true;
        let params = new HttpParams();

        if (kunde_id) {
            params = params.set('kunde_id', kunde_id?.toString());
        } else {
            this.selectedVVVO = this.stammdatenService.getAusgewaehlteVvvo();
            if (this.selectedVVVO) {
                params = params.set(
                    'kunde_id',
                    this.selectedVVVO?.kunde_id?.toString()
                );
            }
        }

        this.ladeGruppenSub?.unsubscribe();
        params = params.set('standard', '0');
        if (except_gruppe_id) {
            params = params.set(
                'except_gruppe_id',
                except_gruppe_id.toString()
            );
        }
        this.ladeGruppenSub = this.api
            .getAll(
                this.apiBaseUrl + 'gruppe/read_akt_kundenportal.php',
                params
            )
            .subscribe({
                next: (response) => {
                    this.isLoading = false;
                    this.gruppen = response.records;
                },
                error: (err) => {
                    this.isLoading = false;
                    this.messageService.errorMessage(
                        'Fehler beim Laden der Gruppen!',
                        err
                    );
                },
            });
    }

    hinzufuegen(g: Gruppe) {
        const result = new GruppeAuswaehlenDialogResult();
        result.confirmed = true;
        result.gruppe = g;
        this.dialogRef.close(result);
    }

    mehrereGruppen() {
        const result = new GruppeAuswaehlenDialogResult();
        result.confirmed = true;
        result.mehrereGruppen = true;
        this.dialogRef.close(result);
    }

    close() {
        this.dialogRef.close();
    }
}
