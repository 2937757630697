<h1 mat-dialog-title>{{data?.title}}</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Tierarzt ausw&auml;hlen</mat-label>
        <mat-select [formControl]="vetSelectCtrl" [compareWith]="compareVet">
            <mat-option *ngIf="data?.showUnbekannt" [value]="tierarzt">unbekannter Tierarzt</mat-option>
            <mat-option *ngFor="let v of vets" [value]="v">{{v.vorname}} {{v.nachname}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">{{data?.buttonOk}}</button>
</div>
