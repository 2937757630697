import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Gruppe } from '../../../gruppen/classes/gruppe';
import { TierTranspondernummer } from '../../classes/tier-transpondernummer';
import { UntypedFormControl } from '@angular/forms';
import { MessageService } from '../../../../services/message-service/message.service';
import { StammdatenService } from '../../../stammdaten/stammdaten.service';

@Component({
    selector: 'kiene-gruppe-tier-transpondernummer-filter',
    templateUrl: './gruppe-tier-transpondernummer-filter.component.html',
    styleUrls: ['./gruppe-tier-transpondernummer-filter.component.scss']
})
export class GruppeTierTranspondernummerFilterComponent implements OnInit, OnDestroy, OnChanges {

    transpondernummernGruppeSubscription: Subscription;
    isLoading = true;
    label = 'Lädt...';

    @Input('gruppe') selectedGruppe: Gruppe;
    @Input('hideOnEmptyList') hideOnEmptyList = false;
    @Output('selectedTranspondernummer') selectedTranspondernummer: EventEmitter<TierTranspondernummer> = new EventEmitter<TierTranspondernummer>();
    selectedTranspondernummerCtrl: UntypedFormControl = new UntypedFormControl(null);
    transpondernummernFuerGruppe: TierTranspondernummer[] = [];

    constructor(private messageService: MessageService, private stammdatenService: StammdatenService) { }

    ngOnDestroy(): void {
        this.transpondernummernGruppeSubscription?.unsubscribe();
    }
    ngOnInit(): void {
        this.loadTranspondernummerFuerGruppe();

        this.selectedTranspondernummerCtrl.valueChanges.subscribe(data => {
            this.selectedTranspondernummer.emit(data);
        });
    }

    ngOnChanges() {
        if (this.selectedGruppe) { this.loadTranspondernummerFuerGruppe(); }
    }

    private loadTranspondernummerFuerGruppe() {
        this.isLoading = true;
        if (this.selectedGruppe) {
            this.transpondernummernGruppeSubscription?.unsubscribe();
            this.transpondernummernGruppeSubscription = this.stammdatenService
                .ladeTranspondernummern(this.selectedGruppe.gruppe_id)
                .subscribe({
                    next: (result) => {
                        this.selectedTranspondernummerCtrl.enable();
                        this.isLoading = false;
                        this.transpondernummernFuerGruppe = result.records;
                        this.transpondernummernFuerGruppe.sort((a, b) => {
                            return a.name < b.name ? -1 : 1;
                        });

                        if (this.transpondernummernFuerGruppe.length === 0) {
                            this.label = 'keine Transpondernummern';
                            this.selectedTranspondernummerCtrl.disable();
                        } else {
                            this.label = 'Transpondernummer';
                        }
                    },
                    error: (err) => {
                        this.isLoading = false;
                        this.messageService.errorMessage(
                            'Fehler beim Laden der Transpondernummern',
                            err
                        );
                    },
                });
        }
    }

    resetTranspondernummernCtrl($event: MouseEvent) {
        $event.stopPropagation();
        this.selectedTranspondernummerCtrl.setValue(undefined);
    }

    compareTranspondernummer(a: TierTranspondernummer, b: TierTranspondernummer) {
        return a && b && a.transpondernummer_id === b.transpondernummer_id;
    }

}
