<h1 mat-dialog-title>Dokument hochladen</h1>
<div mat-dialog-content>
    <p>Bitte w&auml;hlen Sie eine PDF Datei aus, die Sie zum akutellen Kunden {{client?.bezeichnung_final}} hinzuf&uuml;gen
        m&ouml;chten!</p>

    <input #fileUpload type="file" style="display: none;" multiple (change)="handleFile($event)"
           accept="application/pdf">

    <div class="one-row">
        <span class="spacer"></span>
        <button mat-flat-button color="accent" (click)="fileUpload.click()">Datei ausw&auml;hlen und hochladen</button>
        <span class="spacer"></span>
    </div>
</div>
