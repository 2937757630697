<!--<h2 mat-dialog-title>Tierart</h2>-->
<!--<mat-dialog-content>-->
<!--    <mat-form-field style="width: 100%;">-->
<!--        <mat-label>Tierart</mat-label>-->
<!--        <input type="text" matInput [formControl]="tierartCtrl">-->
<!--    </mat-form-field>-->
<!--    <mat-form-field style="width: 100%;">-->
<!--        <mat-label>Kürzel</mat-label>-->
<!--        <input type="text" matInput [formControl]="rasseNameCtrl">-->
<!--    </mat-form-field>-->
<!--</mat-dialog-content>-->
<!--<mat-dialog-actions align="end">-->
<!--    <button mat-button color="primary" (click)="cancel()">Abbrechen</button>-->
<!--    <button mat-raised-button color="primary" (click)="save()" [disabled]="isDisabled()">Speichern</button>-->
<!--</mat-dialog-actions>-->
<h1 mat-dialog-title>Tierart {{ tierart?.tierart_id ? 'bearbeiten' : 'anlegen' }}</h1>
<div mat-dialog-content>

</div>
