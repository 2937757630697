import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {GruppenRoutingModule} from './gruppen-routing.module';
import {
    GruppeStandortWechselnDialogComponent
} from './gruppe-standort-wechseln-dialog/gruppe-standort-wechseln-dialog.component';
import {
    GruppeAbgangDownloadDialogComponent
} from './gruppe-abgang-download-dialog/gruppe-abgang-download-dialog.component';
import {PageLayoutModule} from '../page-layout/page-layout.module';
import {GruppeAbgangComponent} from './gruppe-abgang/gruppe-abgang.component';
import {AbgangListeComponent} from './gruppe-abgang/abgang-liste/abgang-liste.component';
import {AbgangNumberPadComponent} from './gruppe-abgang/abgang-number-pad/abgang-number-pad.component';
import {AbgangScannenComponent} from './gruppe-abgang/abgang-scannen/abgang-scannen.component';
import {
    AbgangslisteSchliessenDialogComponent
} from './gruppe-abgang/abgangsliste-schliessen-dialog/abgangsliste-schliessen-dialog.component';
import {GruppeTierCardModule} from '../gruppe-tier-card/gruppe-tier-card.module';
import {GruppeTierModule} from '../gruppe-tier/gruppe-tier.module';
import {OhrmarkeModule} from '../../ohrmarke/ohrmarke.module';
import {
    KontaktAnlegenDialogComponent
} from './gruppe-abgang/ausstallen-empfaenger-dialog/kontakt-anlegen-dialog/kontakt-anlegen-dialog.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {
    GruppeTierHitMeldenManuellDialogComponent
} from './gruppe-tier-hit-melden-manuell-dialog/gruppe-tier-hit-melden-manuell-dialog.component';
import {
    EinstallenMeldenDialogComponent
} from './gruppe-einstallen/einstallen-melden-dialog/einstallen-melden-dialog.component';
import {EinstallenNumberPadComponent} from './gruppe-einstallen/einstallen-number-pad/einstallen-number-pad.component';
import {
    EinstallenTiereHinzufuegenComponent
} from './gruppe-einstallen/einstallen-tiere-hinzufuegen/einstallen-tiere-hinzufuegen.component';
import {
    EinstallenTiereMeldenComponent
} from './gruppe-einstallen/einstallen-tiere-melden/einstallen-tiere-melden.component';
import {EinstallenUebersichtComponent} from './gruppe-einstallen/einstallen-uebersicht/einstallen-uebersicht.component';
import {
    TierLoeschenConfirmDialogComponent
} from './gruppe-einstallen/tier-loeschen-confirm-dialog/tier-loeschen-confirm-dialog.component';
import {
    TransponderNummerEingebenDialogComponent
} from './gruppe-einstallen/transponder-nummer-eingeben-dialog/transponder-nummer-eingeben-dialog.component';
import {
    GewichtEingebenNumberpadDialogComponent
} from './gruppe-einstallen/einstallen-tiere-hinzufuegen/gewicht-eingeben-numberpad-dialog/gewicht-eingeben-numberpad-dialog.component';
import {PdfViewerModule} from '../pdf-viewer/pdf-viewer.module';
import {
    GruppeReklamationPdfHaendlerAuswahlDialogComponent
} from './gruppe-reklamation-pdf-haendler-auswahl-dialog/gruppe-reklamation-pdf-haendler-auswahl-dialog.component';
import {BenachrichtigungenModule} from '../../benachrichtigungen/benachrichtigungen.module';
import {MatDividerModule} from '@angular/material/divider';
import {
    TierlistenauswahlDialogComponent
} from './gruppe-abgang/tierlistenauswahl-dialog/tierlistenauswahl-dialog.component';
import {GruppeAuswaehlenDialogComponent} from './gruppe-auswaehlen-dialog/gruppe-auswaehlen-dialog.component';
import {MeineKontakteModule} from '../../components/meine-kontakte/meine-kontakte.module';
import {MatStepperModule} from '@angular/material/stepper';
import {GruppeAbgangDialogComponent} from './gruppe-abgang-dialog/gruppe-abgang-dialog.component';
import {TierlistenelementModule} from '../tierlistenelement/tierlistenelement.module';
import {GruppenMenuComponent} from './gruppen-menu/gruppen-menu.component';
import {GruppeInformationenDialogComponent} from './gruppe-kommentar-dialog/gruppe-informationen-dialog.component';
import {CsvDownloadModule} from '../csv-download/csv-download.module';
import {GruppeAnlegenComponent} from './gruppe-anlegen/gruppe-anlegen.component';
import {GruppeAnlegenDialogComponent} from './gruppe-anlegen-dialog/gruppe-anlegen-dialog.component';
import {ImportOptionenDialogComponent} from './import-optionen-dialog/import-optionen-dialog.component';
import {DirectivesModule} from '../../directives/directives.module';
import {MatCardModule} from '@angular/material/card';
import {GruppeAuswaehlenSelektorComponent} from './gruppe-auswaehlen-selektor/gruppe-auswaehlen-selektor.component';
import {GruppeStandortVerwaltenComponent} from './gruppe-standort-verwalten/gruppe-standort-verwalten.component';
import {KeyValueListModule} from '../key-value-list/key-value-list.module';
import {
    EinzeltiergewichtDialogComponent
} from './gruppe-standort-verwalten/einzeltiergewicht-dialog/einzeltiergewicht-dialog.component';
import {NumberpadModule} from '../numberpad/numberpad.module';
import {StammdatenModule} from '../stammdaten/stammdaten.module';
import {AusstallenModule} from '../ausstallen/ausstallen.module';
import {GruppeGeburtComponent} from './gruppe-geburt/gruppe-geburt.component';
import {KieneAutocompleteModule} from '../kiene-autocomplete/kiene-autocomplete.module';

@NgModule({
    declarations: [
        GruppeAbgangComponent,
        AbgangListeComponent,
        AbgangNumberPadComponent,
        AbgangScannenComponent,
        AbgangslisteSchliessenDialogComponent,
        GruppeStandortWechselnDialogComponent,
        GruppeAbgangDownloadDialogComponent,
        KontaktAnlegenDialogComponent,
        GruppeTierHitMeldenManuellDialogComponent,
        EinstallenMeldenDialogComponent,
        EinstallenNumberPadComponent,
        EinstallenTiereHinzufuegenComponent,
        EinstallenTiereMeldenComponent,
        EinstallenUebersichtComponent,
        TierLoeschenConfirmDialogComponent,
        TransponderNummerEingebenDialogComponent,
        GewichtEingebenNumberpadDialogComponent,
        GruppeReklamationPdfHaendlerAuswahlDialogComponent,
        TierlistenauswahlDialogComponent,
        GruppeAuswaehlenDialogComponent,
        GruppeAbgangDialogComponent,
        GruppenMenuComponent,
        GruppeInformationenDialogComponent,
        GruppeAnlegenComponent,
        GruppeAnlegenDialogComponent,
        ImportOptionenDialogComponent,
        GruppeAuswaehlenSelektorComponent,
        GruppeStandortVerwaltenComponent,
        EinzeltiergewichtDialogComponent,
        GruppeGeburtComponent
    ],
    imports: [
        CommonModule,
        GruppenRoutingModule,
        MatButtonModule,
        MatBadgeModule,
        MatIconModule,
        KieneAutocompleteModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatInputModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatRadioModule,
        MatSelectModule,
        PageLayoutModule,
        MatMenuModule,
        MatTabsModule,
        GruppeTierCardModule,
        GruppeTierModule,
        OhrmarkeModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        PdfViewerModule,
        BenachrichtigungenModule,
        MatDividerModule,
        MeineKontakteModule,
        MatStepperModule,
        AusstallenModule,
        TierlistenelementModule,
        CsvDownloadModule,
        DirectivesModule,
        MatCardModule,
        KeyValueListModule,
        NumberpadModule,
        StammdatenModule,
    ],
    exports: [
        GruppeAbgangComponent,
        GruppeStandortWechselnDialogComponent,
        GruppeAbgangDownloadDialogComponent,
        EinstallenUebersichtComponent,
        GruppeAuswaehlenDialogComponent,
        GruppeAbgangDialogComponent,
        GruppenMenuComponent,
        GruppeInformationenDialogComponent,
        GruppeAnlegenComponent,
        GruppeAuswaehlenSelektorComponent,
        GruppeStandortVerwaltenComponent,
        ImportOptionenDialogComponent,
        GruppeGeburtComponent
    ],
})
export class GruppenModule {
}
