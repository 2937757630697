import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatInput } from '@angular/material/input';
import { KienePatternUtils } from '../../utils/KienePatternUtils';
import { HitService } from '../hit.service';


@Component({
    selector: 'hit-registrierung',
    templateUrl: './hit-registrierung.component.html',
    styleUrls: ['./hit-registrierung.component.scss'],
})
export class HitRegistrierungComponent implements OnInit, AfterViewInit {
    hidePassword = true;

    @ViewChild('VvvoInput')
    vvvoInput: MatInput;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private hitService: HitService
    ) { }

    hitPinCtrl: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);

    hitVvvoCtrl: UntypedFormControl = new UntypedFormControl('276', [
        Validators.required,
        Validators.pattern(KienePatternUtils.VVVO()),
    ]);

    hitFormGroup: UntypedFormGroup = new UntypedFormGroup({
        hitPasswordCtrl: this.hitPinCtrl,
        hitVvvoCtrl: this.hitVvvoCtrl,
    });

    ngOnInit(): void {
        this.hitService.watchHitLoginStatusErfolgreich().subscribe({
            next: (value: boolean) => {
                if (value) {
                    this.router
                        .navigate(['../vetvet'], { relativeTo: this.route })
                        .then();
                } else {
                    this.resetFormCtrls();
                }
            },
        });
    }

    ngAfterViewInit() {
        this.vvvoInput?.focus();
    }

    private resetFormCtrls() {
        this.hitVvvoCtrl.setValue('276');
        this.hitPinCtrl.setValue(null);

        this.hitVvvoCtrl.setErrors(null);
    }
}
