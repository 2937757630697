import { Component, Inject, Input, OnInit } from '@angular/core';
import { Benutzerprofil } from './mein-profil';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../services/message-service/message.service';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { FormControl, Validators } from '@angular/forms';
import { KieneSession } from '../../../services/local-storage/kiene-session';
import { ActivatedRoute } from '@angular/router';
import { User } from '../benutzer/benutzer';
import { UnterschriftService } from '../../stammdaten/unterschrift/unterschrift.service';

@Component({
    selector: 'kiene-mein-profil',
    templateUrl: './mein-profil.component.html',
    styleUrls: ['./mein-profil.component.scss']
})
export class MeinProfilComponent implements OnInit {

    @Input('showBackButton') showBackButton = false;

    benutzerprofil = new Benutzerprofil();

    passwortCtrl = new FormControl(undefined, [Validators.minLength(8)]);
    repeatPasswortCtrl = new FormControl(undefined, [Validators.minLength(8)]);
    kieneSession: KieneSession;

    constructor(@Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private unterschriftService: UnterschriftService,
        private route: ActivatedRoute) {
        if (this.route.snapshot.data.showBackButton) {
            this.showBackButton = true;

        }
        this.kieneSession = this.localStorageService.getCurrentUser();
    }

    ngOnInit(): void {
        this.ladeBenutzerprofil();
    }

    ladeBenutzerprofil() {
        this.api
            .get(
                `${this.apiBaseUrl}benutzerprofil/read_one_kundenportal.php`)
            .subscribe({
                next: (response: Benutzerprofil) => {
                    this.benutzerprofil = response;
                },
                error: err => {
                    this.messageService.errorMessage('Profil konnte nicht geladen werden!', err);
                }
            });
    }

    updateBenutzerprofil() {
        this.api
            .updateGeneric(
                `${this.apiBaseUrl}benutzerprofil/update_kundenportal.php`,
                this.benutzerprofil
            )
            .subscribe({
                next: (response: Benutzerprofil) => {
                    this.messageService.infoMessage(
                        'Die Änderungen wurden erfolgreich gespeichert!'
                    );
                    this.benutzerprofil = response;
                    const cu = this.localStorageService.getCurrentUser();
                    cu.elemente_pro_seite = response.elemente_pro_seite;
                    cu.vorname = response.vorname;
                    cu.nachname = response.nachname;
                    this.localStorageService.setCurrentUser(cu);
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler! ', err);
                },
            });
    }

    passwortSpeichern(passwd: string) {
        const user = new User();
        user.benutzer_id = this.kieneSession.benutzer_id;
        user.passwort = passwd;
        this.api
            .post(this.apiBaseUrl + 'benutzerprofil/update.php', user)
            .subscribe({
                next: () => {
                    this.messageService.infoMessage(
                        'Passwort wurde erfolgreich aktualisiert!'
                    );
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Fehler! Passwort konnte nicht aktualisiert werden!',
                        error
                    );
                },
            });
    }

    back() {
        history.back();
    }

    speicherUnterschrift(unterschriftBase64: string) {
        if (unterschriftBase64) {
            const obj = {
                benutzer_id: this.localStorageService.getSession().benutzer_id,
                unterschrift_png: unterschriftBase64
            };
            this.unterschriftService.saveSignature(obj).subscribe({
                next: () => {
                    this.messageService.infoMessage('Die Unterschrift wurde erfolgreich gespeichert!');
                },
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        }
    }
}
