import {HttpClient, HttpEventType, HttpHeaders, HttpParams} from '@angular/common/http';
import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {Gruppe} from '../../documents/gruppenverwaltung/gruppe';
import {Haendler} from '../../../../projects/kiene-core/src/lib/components/stammdaten/haendler/haendler';
import {MessageService} from '../../services/message.service';

@Component({
    selector: 'kiene-tiere-als-csvupload-dialog',
    templateUrl: './tiere-als-csvupload-dialog.component.html',
    styleUrls: ['./tiere-als-csvupload-dialog.component.scss']
})
export class TiereAlsCSVUploadDialogComponent implements OnInit {

    gruppe: Gruppe;
    filesToUpload: File[] = [];
    filesProgressValues: number[] = [];
    einstalldatumCtrl = new UntypedFormControl(new Date(), [Validators.required]);
    showCloseButton = false;
    viehhaendler: Haendler[] = [];
    viehhaendlerCtrl = new UntypedFormControl();
    abteilCtrl = new UntypedFormControl();
    hitMeldenCtrl = new UntypedFormControl(undefined, [Validators.required]);
    isLoading = false;


    @ViewChild('fileSelector') fileSelector: ElementRef;

    constructor(public dialogRef: MatDialogRef<TiereAlsCSVUploadDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data,
                private http: HttpClient,
                private messageService: MessageService,
                private api: KieneTableService) {
    }

    ngOnInit(): void {
        this.gruppe = this.data.gruppe;
        this.loadViehhaendler();
    }

    loadViehhaendler() {
        const params = new HttpParams().set('favorit', '1');
        this.api.get(`${environment.BASE_URI}viehhaendler/read_kundenportal.php`, params).subscribe({
            next: (response) => {
                this.viehhaendler = response.records;
            }
        });
    }

    clickFileButton(event?) {
        if (this.filesToUpload.length < 1) {
            this.openFileSelector();
        } else {
            this.uploadFilesToServer();
        }
    }

    openFileSelector() {
        this.fileSelector.nativeElement.click();
    }

    handleFile(event: any) {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
            this.filesToUpload.push(files.item(i));
            this.filesProgressValues[i] = 0;

        }

    }

    isFileUploaded(file: number) {
        if (this.filesProgressValues[file] === 1) {
            return true;
        } else {
            return false;
        }
    }

    uploadFilesToServer() {
        this.isLoading = true;
        if (this.filesToUpload.length > 0) {
            for (let i = 0; i < this.filesToUpload.length; i++) {

                const fileNumber = i;
                const file = this.filesToUpload[fileNumber];

                const fd = new FormData();
                fd.append('gruppe_id', this.gruppe.gruppe_id.toString());

                fd.append('dokument', file);
                fd.append('kp_einstalldatum', (<Date>this.einstalldatumCtrl.value)?.toISOString());
                if (this.viehhaendlerCtrl.value) {
                    fd.append('viehhaendler_id', this.viehhaendlerCtrl.value?.viehhaendler_id?.toString());
                }
                if (this.abteilCtrl.value) {
                    fd.append('abteil', this.abteilCtrl.value);
                }
                fd.append('hit_meldung', this.hitMeldenCtrl.value?.toString());

                const params = new HttpParams();


                const options = {
                    headers: new HttpHeaders('Phito-Type: fileupload'),
                    params: params,
                    reportProgress: true,
                };

                // const request = new HttpRequest('POST', AppConstants.BASE_URI+this.apiUrl, fd, options);
                this.http.post<any>(environment.BASE_URI + 'gruppe_tier/create_kundenportal_excel.php', fd, options).subscribe({
                    next: event => {
                        if (event.type === HttpEventType.UploadProgress) {
                            const percentDone = Math.round(100 * event.loaded / event.total);
                        }
                    },
                    error: err => {
                        this.isLoading = false;
                        this.messageService.errorMessage('Fehler beim Hochladen!', err);
                    },
                    complete: () => {
                        this.filesProgressValues[fileNumber] = 1;
                        if (fileNumber === this.filesToUpload.length) {
                            this.isLoading = false;
                        }
                        this.messageService.infoMessage('Datei(en) erfolgreich hochgeladen!');
                        this.dialogRef.close(true);
                    }
                });
            }
        }
    }

}
