export class VetGroup {
    tierarztgruppe_id: number;
    bezeichnung: string;
    farbe: string;
    hintergrundfarbe: string;
    meine_gruppe: number;
}

export interface VetGroupApi {
    records: VetGroup[];
    count: number;
}
