import { GruppeStandort } from '../../gruppen/gruppe-standort-verwalten/gruppe-standort';
import { Haendler } from '../../stammdaten/haendler/haendler';
import { Tierliste } from '../classes/tierliste';

export class GruppeTierGewichtPreisDialogInput {
    einstallen = false;
    ausstallen = false;
    gruppeStandort: GruppeStandort;
    viehhaendler: Haendler;
    einstalldatums: Date[] = [];
    tierliste: Tierliste;
}

export class GruppeStandortGewichtePreise {
    gs_id: number;
    tierliste_id: number;
    viehhaendler_id: number;
    einstalldatums: Date[] = [];
    abgangsgewicht: number;
    kp_abgangspreis: number;
    kp_abgangsmwstsatz: number;
    einstallgewicht: number;
    kp_einstallpreis: number;
    kp_einstallmwstsatz: number;
}