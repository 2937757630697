<div>
    <span class="inline-class">
        <div class="title">Datum eingeben</div>
        <mat-form-field class="example-form-field">
            <mat-label>Datum</mat-label>
            <input [formControl]="dateCtrl" matInput [matDatepicker]="datePicker">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
    </span>

    <span class="inline-class">
    <div class="title">Zeitfenster eingeben</div>
    <mat-form-field>
        <mat-label>Zeitfenster</mat-label>
        <mat-select matInput [formControl]="zeitfensterCtrl">
            <mat-option *ngFor="let option of zeitfenster" value="{{option.index}}">{{option.text}}</mat-option>
        </mat-select>
    </mat-form-field>
    </span>

    <span class="inline-class">
    <div class="title">Anzahl der Tiere eingeben</div>
    <div>
        <mat-form-field>
            <mat-label>Tiere / Anzahl</mat-label>
            <input matInput [formControl]="countCtrl" placeholder="Tiere / Anzahl"/>
        </mat-form-field>
    </div>
    </span>
    
    <span class="inline-class">
    <div class="title">Anzahl Wiederholungen</div>
    <mat-form-field>
        <mat-label>Wöchentliche Wiederholungen</mat-label>
        <mat-select matInput [formControl]="wiederholungenCtrl">
            <mat-option *ngFor="let option of wiederholungen" value="{{option.value}}">{{option.name}}</mat-option>
        </mat-select>
    </mat-form-field>
    </span>

    <span class="inline-class">
    <mat-checkbox style="margin-right: 12px;" [(ngModel)]="zeitslot.anFeiertagen">Feiertage mit einbeziehen</mat-checkbox>
    </span>
</div>