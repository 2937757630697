<h2 mat-dialog-title>Anwendung {{anwendung?.anwendung}} für {{material.bezeichnung}}</h2>
<mat-dialog-content>
  <mat-form-field *ngIf="!anwendung" style="width: 100%;">
    <mat-label>Anwendung</mat-label>
    <input type="text" matInput placeholder="Anwendung" [formControl]="anwendungCtrl">
  </mat-form-field>
  <mat-form-field style="margin-right: 12px;">
    <mat-label>QS Schl&uuml;ssel</mat-label>
    <input type="number" matInput [formControl]="qsKeyCtrl">
  </mat-form-field>
  <mat-form-field style="margin-right: 12px;">
    <mat-label>HIT Schl&uuml;ssel</mat-label>
    <input type="number" matInput [formControl]="hitKeyCtrl">
  </mat-form-field>
  <!-- <mat-form-field style="width: 100%;">
    <mat-label>Tierart</mat-label>
    <mat-select [formControl]="tierartCtrl" [compareWith]="tierartComparator" multiple>
      <mat-option *ngFor="let t of tierarten" [value]="t">{{t.tierart}}</mat-option>
    </mat-select>
  </mat-form-field> -->
  <table style="width: 100%;" class="phito-table">
    <thead>
      <tr>
        <th></th>
        <th></th>
        <th>Tierart</th>
        <th>Wartezeit Fleisch</th>
        <th>Wartezeit Milch</th>
        <th>Wartezeit Eier</th>
        <th>Kommentar</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let t of anwendungTierarten">
        <td></td>
        <td>
          <mat-checkbox [(ngModel)]="selectedTierarten[t.tierart_id]"></mat-checkbox>
        </td>
        <td>{{t.tierart}}</td>
        <td><input matInput type="number"
            style="border: 1px solid lightgray; border-radius: 5px; padding: 3px; width: 50px;"
            [(ngModel)]="t.wartezeit_fleisch"></td>
        <td><input matInput type="number"
            style="border: 1px solid lightgray; border-radius: 5px; padding: 3px; width: 50px;"
            [(ngModel)]="t.wartezeit_milch"></td>
        <td><input matInput type="number"
            style="border: 1px solid lightgray; border-radius: 5px; padding: 3px; width: 50px;"
            [(ngModel)]="t.wartezeit_eier"></td>
        <td><input matInput type="text"
            style="border: 1px solid lightgray; border-radius: 5px; padding: 3px; width: 150px;"
            [(ngModel)]="t.kommentar"></td>
      </tr>
    </tbody>
  </table>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
  <button mat-raised-button color="primary" (click)="save()">Speichern</button>
</mat-dialog-actions>