import {Gruppe} from '../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import {Client} from '../../../../projects/kiene-core/src/lib/components/client/classes/client';

export class Antibiogramm {
    antibiogramm_id: number;
    tagebuch_nr: string;
    content: any;
    ergebnis: any;
    status: number;
    gruppe_id: number;
    gruppe: string;
    kunde_id: number;
    kunde: string;
    kunde_ort: string;
    erstellt: Date;
    editiert: Date;
    pdf_id: number;
    eingang: Date;
    ausgang: Date;
    probenentnahme: Date;
    tierarztgruppe: string;
    befund_id: number;
    gruppe_nr_intern: string;
    kunde_nr_intern: number;
}

export interface AntibiogrammApi {
    records: Antibiogramm[];
    count: number;
}

export class Pruefer {
    pruefer_id: number;
    bezeichnung: string;
}

export interface PrueferApi {
    records: Pruefer[];
    count: number;
}

export class TestOrderRecord {
    sequenceNumber: number;
    materialNummer: string;
    isolatenNummer: number;
    isoKommentar: string;
    einsenderKuerzel: string;
    materialartenKuerzel: string;
    kennzeichnung: string;
}

export class HeaderRecord {
    senderName: string;
    datetime: string;
}

export class ResultRecord {
    sequenceNumber: number;
    testart: string;
    verdacht: string;
    gramfaerbung: string;
    antibiotikaName: string;
}

export class TestOrderResultRecord {
    testOrderRecord: TestOrderRecord;
    resultRecord: ResultRecord;
}

export class PatientRecord {
    sequenceNumber: number;
    patientenId: string;
    patientenName: string;
}

export class ResTest {
    testart: string;
    verdacht: string;
    tierart: string;
    kommentar: string;
    isolatenNummer: number;
    infektionsart: string;
    kennzeichnung: string;
}

export class AntibiogrammOrder {
    headerRecord: HeaderRecord;
    patientRecord: PatientRecord;
    testOrderResultRecords: TestOrderResultRecord[] = [];
}

export class AntibiogrammOrderResult {
    headerRecord: HeaderRecord = new HeaderRecord();
    patientRecord: PatientRecord = new PatientRecord();
    testOrderResultExportRecords: TestOrderResultExportRecord[] = [];
}

export class TestOrderResultExportRecord {
    testOrderRecord: TestOrderRecord;
    resultRecords: ResultExportRecord[] = [];
}

export class ResultExportRecord {
    result: string;
    testart: string;
    resultStatus: string;
    testComplete: string;
    measuredValue: string;
    sequenceNumber: number;
    antibiotikaName: string;
}

export class AntibiogrammTierart {
    tierart_id: number;
    tierart: string;
    kuerzel: string;
}

export interface AntibiogrammTierartApi {
    records: AntibiogrammTierart[];
    count: number;
}

export class Verdacht {
    verdacht_id: number;
    verdacht: string;
    kuerzel: string;
}

export interface VerdachtApi {
    records: Verdacht[];
    count: number;
}

export class Infektionsart {
    bezeichnung: string;
    kuerzel: string;

    constructor(b: string, k: string) {
        this.bezeichnung = b;
        this.kuerzel = k;
    }
}

export class AntibiogrammAllDownload {
    gruppe: Gruppe;
    client: Client;
    zeit_von: Date;
    zeit_bis: Date;
}
