import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DetachedRouteHandlesService {

    changes: Subject<string>;

    constructor() {
        this.changes = new Subject();
    }

    public watchChanges(): Observable<string> {
        return this.changes.asObservable();
    }

    public set(eventName: string): void {
        console.log('----------------------------- CHANGED SITE:', eventName);

        this.changes.next(eventName);
    }
}
