import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {
    Antibiogramm,
    AntibiogrammOrder,
    AntibiogrammOrderResult,
    AntibiogrammTierart,
    HeaderRecord,
    Infektionsart,
    PatientRecord,
    ResTest,
    ResultRecord,
    TestOrderRecord,
    TestOrderResultRecord,
    Verdacht,
} from '../antibiogramm';
import {ActivatedRoute, Router} from '@angular/router';
import {AntibiogrammService} from '../antibiogramm.service';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith, switchMap} from 'rxjs/operators';
import {MessageService} from '../../../services/message.service';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {MatDialog} from '@angular/material/dialog';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {GruppenService} from '../../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import {Gruppe} from '../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {HttpParams} from '@angular/common/http';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {KieneAutocompleteComponent} from '../../../components/kiene-autocomplete/kiene-autocomplete.component';

@Component({
    selector: 'kiene-antibiogramm-details',
    templateUrl: './antibiogramm-details.component.html',
    styleUrls: ['./antibiogramm-details.component.scss'],
})
export class AntibiogrammDetailsComponent implements OnInit {
    antibiogramm: Antibiogramm = new Antibiogramm();
    currentClient: Client;
    newTest: ResTest = new ResTest();
    resTestList: ResTest[] = [];

    currentGruppe: Gruppe = new Gruppe();

    isLoading = false;

    currentId: string;

    presetYear: number = new Date().getFullYear();
    tbnHeadline = '';

    testDescription: AntibiogrammOrder;

    gruppe: Gruppe;

    isNewAntibiogramm = false;
    testResult: AntibiogrammOrderResult;

    tierarten: Observable<AntibiogrammTierart[]>;
    tierartCtrl: UntypedFormControl = new UntypedFormControl();

    verdachte: Observable<Verdacht[]>;
    verdachtCtrl: UntypedFormControl = new UntypedFormControl();

    filteredInfektionsarten: Observable<Infektionsart[]>;
    infektionCtrl: UntypedFormControl = new UntypedFormControl();
    infektionsarten: Infektionsart[] = [
        {
            bezeichnung: 'Genitaltraktinfektion',
            kuerzel: 'G',
        },
        {
            bezeichnung: 'Mastitis',
            kuerzel: 'M',
        },
        {
            bezeichnung: 'Atemwegsinfektion',
            kuerzel: 'RTI',
        },
        {
            bezeichnung: 'Hautinfektion',
            kuerzel: 'S',
        },
        {
            bezeichnung: 'Weichteilinfektion',
            kuerzel: 'ST',
        },
        {
            bezeichnung: 'Urogenitalinfektion',
            kuerzel: 'UG',
        },
        {
            bezeichnung: 'Harnwegsinfektion',
            kuerzel: 'UTI',
        },
        {
            bezeichnung: 'Wundinfektion + Abszess',
            kuerzel: 'W+A',
        },
    ];

    @ViewChild('inputGruppeAutocomplete', {read: ElementRef})
    inputGruppeAutocomplete: ElementRef;

    @ViewChild('gruppeAutocomplete') gruppeAutocomplete: KieneAutocompleteComponent;
    currentClientParams: HttpParams;

    constructor(
        private api: KieneTableService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public dialog: MatDialog,
        private gruppenService: GruppenService,
        private router: Router,
        private service: AntibiogrammService,
        private localStorageService: LocalStorageService
    ) {
        this.currentId = this.route.snapshot.paramMap.get('id');
    }

    ngOnInit() {
        if (this.currentId === 'neu') {
            this.isNewAntibiogramm = true;
            const now = new Date();
            const yesterday = new Date();
            yesterday.setDate(now.getDate() - 1);
            this.antibiogramm.eingang = yesterday;
            const year = new Date(this.antibiogramm.eingang);
            this.presetYear = year.getFullYear();
            this.antibiogramm.probenentnahme = yesterday;
        } else {
            this.loadAntibiogramm();
        }
        this.autocompleteTierart();
        this.autocompleteVerdachte();
        this.autocompleteInfektionsarten();
    }

    hasPermission(permissionId: number): boolean {
        return this.localStorageService.currentUserHasPermission(permissionId);
    }


    loadAntibiogramm() {
        this.service.getAntibiogramm(this.currentId).subscribe({
            next: (result) => {
                this.antibiogramm = result;

                const gruppe = new Gruppe();
                gruppe.gruppe_id = this.antibiogramm.gruppe_id;
                gruppe.bezeichnung = this.antibiogramm.gruppe;
                this.currentGruppe = gruppe;

                const kunde = new Client();
                kunde.kunde_id = this.antibiogramm.kunde_id;
                kunde.bezeichnung = this.antibiogramm.kunde;
                kunde.kunde_nr_intern = this.antibiogramm.kunde_nr_intern;
                this.currentClient = kunde;


                let tbn = this.antibiogramm.tagebuch_nr;
                this.presetYear = Number.parseInt(
                    tbn.substr(tbn.length - 2, tbn.length),
                    10
                );
                this.tbnHeadline = tbn;
                tbn = tbn.substr(0, tbn.length - 3);
                this.antibiogramm.tagebuch_nr = tbn;

                this.testDescription = this.antibiogramm.content;
                this.testResult = this.antibiogramm.ergebnis;

                if (this.antibiogramm?.gruppe_id) {
                    this.loadCurrentGruppe(this.antibiogramm?.gruppe_id);
                }
            },
            error: () => {
            },
        });
    }

    openPdf(antibiogramm: Antibiogramm) {
        const pdfId = antibiogramm.pdf_id;
        if (pdfId) {
            this.service.showPdf(antibiogramm);
        }
    }

    changedEingangDate() {
        const year = new Date(this.antibiogramm.eingang);
        this.presetYear = year.getFullYear();
    }


    searchGruppen(value: string) {
        return this.gruppenService.searchGruppenPraxissoftware(value).pipe(
            debounceTime(300),
            distinctUntilChanged(),
            map((response) => response.records)
        );
    }

    loadCurrentGruppe(groupId: number) {
        this.gruppenService.getGruppePraxissoftware(groupId).subscribe({
            next: (response) => {
                this.currentGruppe = <Gruppe>response;
            },
            error: () => {
            },
        });
    }

    autocompleteTierart() {
        this.tierartCtrl = new UntypedFormControl();
        this.tierarten = this.tierartCtrl.valueChanges.pipe(
            startWith(''),
            switchMap((value) => this.searchTierarten(value))
        );
    }

    searchTierarten(value: string) {
        return this.service.searchTierarten(value).pipe(
            debounceTime(300),
            distinctUntilChanged(),
            map((response) => response.records)
        );
    }

    displayTierart(tierart?: AntibiogrammTierart) {
        return tierart ? tierart.tierart + ' - ' + tierart.kuerzel : undefined;
    }

    autocompleteVerdachte() {
        this.verdachtCtrl = new UntypedFormControl();
        this.verdachte = this.verdachtCtrl.valueChanges.pipe(
            startWith(''),
            switchMap((value) => this.searchVerdacht(value))
        );
    }

    searchVerdacht(value: string) {
        return this.service.searchVerdachte(value).pipe(
            debounceTime(300),
            distinctUntilChanged(),
            map((response) => response.records)
        );
    }

    displayVerdacht(verdacht?: Verdacht) {
        return verdacht
            ? verdacht.verdacht + ' - ' + verdacht.kuerzel
            : undefined;
    }

    autocompleteInfektionsarten() {
        this.infektionCtrl = new UntypedFormControl();
        this.filteredInfektionsarten = this.infektionCtrl.valueChanges.pipe(
            startWith(''),
            map((infektionsart) =>
                infektionsart
                    ? this.searchInfektionsarten(infektionsart)
                    : this.infektionsarten.slice()
            )
        );
    }

    searchInfektionsarten(value: any) {
        if (value instanceof Infektionsart) {
            return this.infektionsarten.filter(
                (infekt) =>
                    infekt.bezeichnung?.toLowerCase()?.indexOf(value.bezeichnung?.toLowerCase()) === 0
            );
        } else if (typeof value === 'string') {
            return this.infektionsarten.filter(
                (infekt) =>
                    infekt.bezeichnung?.toLowerCase()?.indexOf(value?.toLowerCase()) === 0
            );
        }
    }

    displayInfektionsart(infektionsart?: Infektionsart) {
        return infektionsart
            ? infektionsart.bezeichnung + ' - ' + infektionsart.kuerzel
            : undefined;
    }

    setClient(client: Client) {
        this.currentClient = client;
    }


    addTest() {
        this.newTest.tierart = this.tierartCtrl.value.kuerzel;
        this.newTest.verdacht = this.verdachtCtrl.value.kuerzel;
        this.newTest.infektionsart =
            this.infektionCtrl.value != null
                ? this.infektionCtrl.value.kuerzel
                : null;
        this.resTestList.push(this.newTest);
        this.tierartCtrl.setValue('');
        this.verdachtCtrl.setValue('');
        this.infektionCtrl.setValue('');
        this.newTest = new ResTest();
    }

    resendAntibiogrammToMCN6() {
        const anti = new Antibiogramm();
        anti.status = 0;
        anti.antibiogramm_id = this.antibiogramm.antibiogramm_id;
        this.service.updateAntibiogramm(anti).subscribe({
            next: () => {
                this.messageService.infoMessage(
                    'Antibiogramm ' +
                    this.antibiogramm.tagebuch_nr +
                    ' wurde nochmals an MCN6 übertragen!'
                );
            },
            error: (error) => {
                this.messageService.errorMessage(
                    'Fehler: Antibiogramm ' +
                    this.antibiogramm.tagebuch_nr +
                    ' konnte nicht an MCN6 übertragen weden!',
                    error
                );
            },
        });
    }

    saveAntibiogrammOrder() {
        const headerRecord: HeaderRecord = this.createHeaderRecord();
        const patientRecord: PatientRecord = this.createPatientRecord();
        const testOrderResultRecords: TestOrderResultRecord[] =
            this.createTestOrderResultRecords();

        const antibiogrammOrder = new AntibiogrammOrder();
        antibiogrammOrder.headerRecord = headerRecord;
        antibiogrammOrder.patientRecord = patientRecord;
        antibiogrammOrder.testOrderResultRecords = testOrderResultRecords;

        const antibio: Antibiogramm = new Antibiogramm();
        antibio.tagebuch_nr = this.createTagebuchNummer(
            this.antibiogramm.tagebuch_nr
        );
        antibio.gruppe_id = this.currentGruppe.gruppe_id
        antibio.gruppe = this.currentGruppe.bezeichnung;
        antibio.status = 0;
        antibio.content = JSON.stringify(antibiogrammOrder);
        antibio.eingang = this.antibiogramm.eingang;
        antibio.probenentnahme = this.antibiogramm.probenentnahme;

        if (this.antibiogramm.antibiogramm_id) {
            antibio.antibiogramm_id = this.antibiogramm.antibiogramm_id;
            this.api
                .post(
                    this.apiBaseUrl + 'antibiogramm/update_by_id.php',
                    antibio,
                    null
                )
                .subscribe({
                    next: (result) => {
                        this.antibiogramm = new Antibiogramm();
                        this.resTestList = [];
                        this.currentClient = new Client();
                        this.messageService.infoMessage(
                            'Antibiogramm ' +
                            result.tagebuch_nr +
                            ' wurde erfolgreich gespeichert!'
                        );
                        this.router.navigate(['/labor/antibiogramme']).then();
                    },
                    error: (error) => {
                        this.messageService.errorMessage(
                            'Fehler: Antibiogramm ' +
                            antibio.tagebuch_nr +
                            ' konnte nicht gespeichert werden!',
                            error
                        );
                    },
                });
        } else {
            this.api
                .post(this.apiBaseUrl + 'antibiogramm/create.php', antibio)
                .subscribe({
                    next: (result) => {
                        this.antibiogramm = new Antibiogramm();
                        this.resTestList = [];
                        this.currentClient = new Client();
                        this.messageService.infoMessage(
                            'Antibiogramm ' +
                            result.tagebuch_nr +
                            ' wurde erfolgreich gespeichert!'
                        );
                        this.router.navigate(['/labor/antibiogramme']).then();
                    },
                    error: (error) => {
                        this.messageService.errorMessage(
                            'Fehler: Antibiogramm ' +
                            antibio.tagebuch_nr +
                            ' konnte nicht gespeichert werden!',
                            error
                        );
                    },
                });
        }
    }

    createTestOrderResultRecords(): TestOrderResultRecord[] {
        const torr: TestOrderResultRecord[] = [];

        for (let i = 0; i < this.resTestList.length; i++) {
            const test = this.resTestList[i];
            const testOrderResultRecord = new TestOrderResultRecord();
            const testOrderRecord = new TestOrderRecord();
            const resultRecord = new ResultRecord();

            testOrderRecord.sequenceNumber = 1;
            testOrderRecord.materialNummer = this.createTagebuchNummer(
                this.antibiogramm.tagebuch_nr
            );
            testOrderRecord.isolatenNummer = i + 1;
            testOrderRecord.isoKommentar = test.kommentar;
            testOrderRecord.einsenderKuerzel = test.tierart;
            testOrderRecord.materialartenKuerzel = test.infektionsart;
            testOrderRecord.kennzeichnung = test.kennzeichnung;

            testOrderResultRecord.testOrderRecord = testOrderRecord;

            resultRecord.sequenceNumber = i + 1;
            resultRecord.testart = test.testart;
            resultRecord.verdacht = test.verdacht;

            testOrderResultRecord.resultRecord = resultRecord;

            torr.push(testOrderResultRecord);
        }

        return torr;
    }

    createTagebuchNummer(tagebuchNummer: string): string {
        const tbnSuffix = '/' + this.presetYear.toString().substr(-2);
        return tagebuchNummer + tbnSuffix;
    }

    createPatientRecord(): PatientRecord {
        const pr = new PatientRecord();
        pr.patientenId = this.currentGruppe.gruppe_nr_intern
            ? this.currentGruppe.gruppe_nr_intern.toString()
            : this.currentGruppe.kunde_nr_intern?.toString();
        pr.patientenName = this.getPatientenNamen();
        pr.sequenceNumber = 1;

        return pr;
    }

    getPatientenNamen(): string {
        let text = '';
        if (this.currentGruppe?.unternehmensgruppe) {
            text += this.currentGruppe.unternehmensgruppe + ' ';
        }

        text += this.currentGruppe?.kunde;

        if (this.currentGruppe?.bezeichnung !== 'Standard') {
            text += ' ' + this.currentGruppe?.bezeichnung;
        }
        return text;
    }

    createHeaderRecord(): HeaderRecord {
        const hr = new HeaderRecord();
        hr.senderName = 'MERLIN';

        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1;
        const day = now.getDate();
        const hour = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();

        hr.datetime = [
            year,
            (month > 9 ? '' : '0') + month,
            (day > 9 ? '' : '0') + day,
            (hour > 9 ? '' : '0') + hour,
            (minutes > 9 ? '' : '0') + minutes,
            (seconds > 9 ? '' : '0') + seconds,
        ].join('');

        return hr;
    }

    goBack() {
        this.router.navigate(['../'], {relativeTo: this.route}).then();
    }

    getVerdachtString(s: string) {
        const arr: string[] = s.split('^');
        return arr[1] + ' ( ' + arr[0] + ' )';
    }

    getInfektionsartName(kuerzel: string) {
        for (const i of this.infektionsarten) {
            if (i.kuerzel === kuerzel) {
                return i.bezeichnung;
            }
        }
        return kuerzel;
    }

    addToPresetYear(value: number) {
        this.presetYear = this.presetYear + value;
    }

    setSelectedClient(client: Client) {
        this.currentClient = client;
        if (!client) {
            this.currentGruppe = undefined;
            this.currentClientParams = undefined;
            this.gruppeAutocomplete.clear();
        } else {
            this.currentClientParams = new HttpParams().set('kunde_id', this.currentClient?.kunde_id);
            setTimeout(() => {
                this.gruppeAutocomplete.clearAndFocus();
            }, 100);
        }
    }

    setSelectedGruppe(gruppe: Gruppe) {
        this.currentGruppe = gruppe;
    }
}
