<div>
    <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>Suchen</mat-label>
        <input [formControl]="searchCtrl" matInput placeholder="Name oder VVVO" type="search" />
        <mat-icon matSuffix>search</mat-icon>

    </mat-form-field>

    <div *ngIf="neuenKontaktErstellen && kunde_id" class="one-row" style="padding-bottom: 20px">
        <span class="spacer"></span>
        <button (click)="neuenKontakt()" color="accent" mat-stroked-button>
            <mat-icon>add</mat-icon>
             neuen Kontakt anlegen
        </button>
        <span class="spacer"></span>
    </div>

    <mat-card *ngFor="let k of kontakte" class="kontakt-card">
        <mat-card-header>
            <mat-card-title>{{ k.bezeichnung }}</mat-card-title>
            <mat-card-subtitle *ngIf="k.zusatz">{{ k.zusatz }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <p>VVVO: {{ k.vvvo ? k.vvvo : '---' }}</p>
            <p>{{ k.strasse }}</p>
            <p>{{ k.plz }} {{ k.ort }}</p>
        </mat-card-content>
        <button (click)="kontaktAuswaehlen(k)" color="accent" mat-flat-button>
            <mat-icon>check</mat-icon>
            ausw&auml;hlen
        </button>
    </mat-card>
</div>