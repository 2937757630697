<h1 mat-dialog-title>Gruppe ausw&auml;hlen</h1>
<div mat-dialog-content>

    <div *ngIf="isLoading" style="margin: 24px 0">
        <mat-hint class="hinweis-text">
            Lade Gruppen...
        </mat-hint>
    </div>

    <div *ngIf="!isLoading && gruppen?.length > 0">
        <h5 *ngIf="selectedVVVO" style="padding: 0; margin: 0;">Betrieb: </h5>
        <p *ngIf="selectedVVVO" style="padding: 0; margin: 0;">{{selectedVVVO.vvvo}}</p>
        <p *ngIf="selectedVVVO" style="padding: 0; margin: 0;">{{selectedVVVO.bezeichnung_final}} -
            {{selectedVVVO.zusatz_final}}</p>
        <button (click)="mehrereGruppen()" *ngIf="data?.mehrereGruppen" color="accent" mat-flat-button><span
                style="white-space: break-spaces;">Tiere aus mehreren Gruppen ausw&auml;hlen</span>
        </button>
        <div *ngIf="data?.mehrereGruppen" style="margin-top: 12px;">
            <span style="font-weight: bold;">oder:</span>
        </div>
        <div *ngFor="let g of gruppen" style="
                width: 100%;
                margin-bottom: 6px;
                border-bottom: 1px solid #eee;
                padding-bottom: 6px;
            ">
            <h4>{{ g.bezeichnung }}</h4>
            <p *ngIf="!selectedVVVO" style="margin: 0; padding: 0;">{{ g.kunde }} {{ g.zusatz }}</p>
            <p style="margin: 0; padding: 0;">{{ g.stall }}</p>
            <div class="one-row" style="padding-bottom: 12px">
                <span style="font-size: .8em;">#Tiere aktuell: {{g.anzahl_tiere_aktuell}}</span>
                <span class="spacer"></span>
            </div>
            <button (click)="hinzufuegen(g)" color="accent" mat-flat-button>
                <mat-icon>check</mat-icon>
                ausw&auml;hlen
            </button>
        </div>
    </div>
    <p *ngIf="gruppen?.length == 0">Noch keine Gruppen angelegt.</p>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button (click)="close()" mat-button>Abbrechen</button>
</div>