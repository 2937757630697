<!--<div style="padding: 0 24px 64px 24px">-->

<!--    <kiene-page-header [ueberschrift]="'Meine Händler'"></kiene-page-header>-->

<!--    <div>-->
<!--        <h3>Favoriten</h3>-->
<!--        <p >Die Favoriten stehen in der Schnellauswahl für z.B. &bdquo;Verkauf&ldquo; zur Verfügung</p>-->
<!--        <kiene-haendler-uebersicht #favoriten></kiene-haendler-uebersicht>-->
<!--    </div>-->

<!--    <div>-->
<!--        <div style="display: flex; justify-content: space-between; align-items: center">-->
<!--            <h3>Alle H&auml;ndler</h3>-->
<!--            <button *kienePermission="haendlerAnlegen" color="accent" mat-icon-button (click)="haendlerHinzufuegen()"><mat-icon>add</mat-icon></button>-->
<!--        </div>-->
<!--        <p style="font-size: 0.8em">-->
<!--            Ein H&auml;ndler kann durch das an-/abw&auml;hlen zu den Favoriten hinzugefügt bzw. entfernt werden.-->
<!--        </p>-->

<!--        <mat-form-field style="margin: 6px 0 6px 0; width: 100%;">-->
<!--            <mat-icon style="padding-right: 6px" matPrefix>search</mat-icon>-->
<!--            <mat-label>H&auml;ndler suchen</mat-label>-->
<!--            <input [placeholder]="'Händler suchen nach Name'" matInput type="search"-->
<!--                   [formControl]="haendlerNameCtrl">-->
<!--            <button *ngIf="haendlerNameCtrl.value" matSuffix mat-icon-button aria-label="Clear"-->
<!--                    (click)="haendlerNameCtrl.setValue(null)">-->
<!--                <mat-icon>close</mat-icon>-->
<!--            </button>-->
<!--        </mat-form-field>-->

<!--        <ul class="ul&#45;&#45;mat-card">-->
<!--            <li style="padding-bottom: 6px" *ngFor="let haendler of alleHaendler | haendlerName: haendlerNameCtrl.value">-->
<!--                <mat-expansion-panel>-->

<!--                    <mat-expansion-panel-header style="min-height: 64px">-->
<!--                        <mat-panel-title style="display: flex">-->
<!--                            <mat-checkbox [formControl]="haendlerCtrls[haendler.viehhaendler_id]" (change)="aendereFavorit(haendler)" (click)="$event.stopPropagation();" style="padding-right: 12px"></mat-checkbox>-->
<!--                            <h3 style="padding: 0; margin: 0">-->
<!--                                <span>{{haendler.name}}</span>-->
<!--                            </h3>-->
<!--                        </mat-panel-title>-->
<!--                    </mat-expansion-panel-header>-->

<!--                    <div>-->
<!--                        <div *ngIf="haendler.vvvo" class="haendler-details">-->
<!--                            <strong>VVVO: </strong>-->
<!--                            <span>{{haendler.vvvo}}</span>-->
<!--                        </div>-->
<!--                        <div *ngIf="haendler.email" class="haendler-details">-->
<!--                            <strong>E-Mail: </strong>-->
<!--                            <span>{{haendler.email}}</span>-->
<!--                        </div>-->
<!--                        <div *ngIf="haendler.telefon" class="haendler-details">-->
<!--                            <strong>Telefon: </strong>-->
<!--                            <span>{{haendler.telefon}}</span>-->
<!--                        </div>-->
<!--                        <div *ngIf="haendler.mobil" class="haendler-details">-->
<!--                            <strong>Mobil: </strong>-->
<!--                            <span>{{haendler.mobil}}</span>-->
<!--                        </div>-->
<!--                        <div *ngIf="haendler.fax" class="haendler-details">-->
<!--                            <strong>FAX: </strong>-->
<!--                            <span>{{haendler.fax}}</span>-->
<!--                        </div>-->
<!--                        <div *ngIf="haendler.strasse || haendler.ort || haendler.plz" class="haendler-details haendler-details&#45;&#45;address">-->
<!--                            <strong>Addresse: </strong>-->
<!--                            <span *ngIf="haendler.strasse">{{haendler.strasse}}</span>-->
<!--                            <span>{{haendler.plz}} {{haendler.ort}}</span>-->
<!--                        </div>-->
<!--                        <div class="haendler-details haendler-details&#45;&#45;edit-button">-->

<!--                            <span class="spacer"></span>-->

<!--                            <button *kienePermission="haendlerAnlegen" style="margin-top: 6px" color="accent" mat-flat-button (click)="editiereHaendler(haendler)">-->
<!--                                <span>Bearbeiten</span>-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </div>-->

<!--                </mat-expansion-panel>-->
<!--            </li>-->
<!--        </ul>-->
<!--    </div>-->
<!--</div>-->

<div style="padding: 0 24px 64px 24px">

    <kiene-page-header [ueberschrift]="'Meine Händler'"></kiene-page-header>

    <div>
        <h3>Übersicht</h3>
        <kiene-haendler-favoriten #favoriten></kiene-haendler-favoriten>
    </div>
</div>
