import {Component, Inject, OnInit} from '@angular/core';
import {SelectionToolbarButton, TableDescriptor } from '../../../components/kiene-table/table-descriptor';
import { MatDialog } from '@angular/material/dialog';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {MessageService} from '../../../services/message.service';
import {AppService} from '../../../app.service';
import {GruppenService} from '../../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {TableOption, TableOptionEvent} from '../../../components/kiene-table/table-option';
import {
    DateTableColumn,
    KieneBooleanTableColumn,
    NumberTableColumn,
    TableColumn,
    TextTableColumn,
} from '../../../components/kiene-table/table-column';

import {Inventur} from '../../../warehouse/inventur/entities/Inventur';
import {InventurDetailsDialogComponent} from '../inventur-details-dialog/inventur-details-dialog.component';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'kiene-inventur-uebersicht',
    templateUrl: './inventur-uebersicht.component.html',
    styleUrls: ['./inventur-uebersicht.component.scss'],
})
export class InventurUebersichtComponent implements OnInit {
    tableDescriptor = new TableDescriptor();

    constructor(
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private messageService: MessageService,
        private appService: AppService,
        private gruppenService: GruppenService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService
    ) {
    }

    ngOnInit(): void {
        this.initTable();
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            this.openInventurDetailsDialog();
        } else if (event.name === 'edit') {
            this.openInventurDetailsDialog(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        } else if (event.name === 'report') {
            if (event.values.length < 2 || event.values.length > 2) {
                this.messageService.alertMessage('Bitte genau zwei Inventuren zum Vergleichen auswählen!');
            } else {
                this.router
                    .navigate(['/lager/inventur/inventurenauswertung'], {
                        queryParams: {
                            inventurId1: event.values[0].inventur_id,
                            inventurId2: event.values[1].inventur_id
                        }
                    })
                    .then();
            }
        }
    }

    private initTable() {
        this.tableDescriptor.headline = 'Inventuren';
        this.tableDescriptor.uniqueIdentifier = 'Inventuren';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'inventur/';
        this.tableDescriptor.initialSortColumn = 'datum';
        this.tableDescriptor.initialSortDirection = 'desc';
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.nameOfIdField = 'inventur_id';

        this.tableDescriptor.selectable = true;
        this.tableDescriptor.selectionToolbarButtons = this.getSelectionToolbarButtons();

        this.tableDescriptor.permissionView = 4441;
        this.tableDescriptor.permissionCreate = 4442;
        this.tableDescriptor.permissionUpdate = 4442;
        this.tableDescriptor.permissionDelete = 4444;

        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;

        this.tableDescriptor.columns = this.getTableColumns();

        this.tableDescriptor.options = this.getTableOptions();
    }

    private getTableColumns() {
        const columns: TableColumn[] = [];

        columns.push(new TextTableColumn('inventur_id', 'ID'));
        columns.push(new DateTableColumn('datum', 'Datum', 'dd.MM.yyyy'));
        columns.push(new TextTableColumn('name', 'Name'));
        columns.push(new KieneBooleanTableColumn('aktiv', 'Aktiv'));
        columns.push(new DateTableColumn('editiert', 'zuletzt geändert'));
        columns.push(new DateTableColumn('erstellt', 'erstellt'));

        return columns;
    }

    private openInventurDetailsDialog(inventur?: Inventur) {
        const dialogRef = this.dialog.open(InventurDetailsDialogComponent, {
            data: inventur,
        });
        dialogRef.afterClosed().subscribe({
            next: (result) => {
                if (result) {
                    this.tableService.reload('openInventurDetailsDialog');
                }
            },
        });
    }

    private getTableOptions() {
        const options: TableOption[] = [];
        options.push(
            new TableOption(
                'edit',
                'Bearbeiten',
                'edit',
                this.tableDescriptor.permissionUpdate
            )
        );
        options.push(
            new TableOption(
                'delete',
                'Löschen',
                'delete',
                this.tableDescriptor.permissionDelete
            )
        );

        return options;
    }

    private openDeleteDialog(inventur: Inventur) {
        const confirmInput = new ConfirmDialogInput();
        (confirmInput.headline = 'Inventur löschen'),
            (confirmInput.text =
                'Wollen Sie die Inventur wirklich löschen?'),
            (confirmInput.okButton = 'Löschen');
        confirmInput.buttonColor = 'warn';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: confirmInput,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.api
                        .deleteGeneric(this.apiBaseUrl + 'inventur/delete.php', inventur)
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Die Inventur wurde erfolgreich gelöscht!'
                                );
                                this.tableService.reload(
                                    'Inventur: OpenDeleteDialog'
                                );
                            },
                        });
                }
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler!', err);
                this.tableService.reload(
                    'Inventur: OpenDeleteDialog: error Case'
                );
            },
        });
    }

    private getSelectionToolbarButtons() {
        const buttons = [];
        buttons.push(new SelectionToolbarButton('report', 'analytics', 4446, true, 'Inventuranalyse'));
        return buttons;
    }
}
