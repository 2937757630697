<h3 mat-dialog-title>Dateien hinzuf&uuml;gen</h3>
<mat-dialog-content>
    <p>Bitte wählen Sie Ihre Dokumente aus, die im System gespeichert werden sollen. Die Kundenzuordnung erfolgt
        automatisch.</p>
    <button *ngIf="showUploadAndSelector" mat-raised-button color="primary" (click)="openFileSelector()">Dateien
        ausw&auml;hlen</button>
    <input type="file" style="display: none" #fileSelector multiple (change)="handleFiles($event)"
        accept="application/pdf">
    <div>
        <mat-list>
            <h4 mat-subheader>Dateien</h4>
            <div style="width: 100%;" *ngFor="let file of filesToUpload; let i = index">
                <div class="one-row" style="margin-bottom: 12px;">
                    <mat-icon style="width: 70px;" mat-list-icon>insert_drive_file</mat-icon>
                    <div style="min-width: 40%;">{{file.name}}</div>
                    <mat-icon color="primary" *ngIf="filesUploadStatus[i]==1">check</mat-icon>
                    <mat-icon color="warn" *ngIf="filesUploadStatus[i]==2">warning</mat-icon>
                    <span class="spacer"></span>
                    <mat-progress-spinner [diameter]="30" mode="determinate" [value]="filesProgressValues[i]">
                    </mat-progress-spinner>
                </div>
            </div>
        </mat-list>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="primary" (click)="onCancelClick()">Abbrechen</button>
    <button *ngIf="showUploadAndSelector && !showCloseButton" mat-button color="primary"
        (click)="uploadFiles()">Hochladen</button>
    <button *ngIf="showCloseButton" mat-button color="primary" (click)="closeDialog()">Schlie&szlig;en</button>
</mat-dialog-actions>