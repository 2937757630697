import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { GruppeTier } from '../../administration/client-group/gruppe-tier';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'kiene-abteil-dialog',
  templateUrl: './abteil-dialog.component.html',
  styleUrls: ['./abteil-dialog.component.scss']
})
export class AbteilDialogComponent implements OnInit {

  tier: GruppeTier;

  constructor(public dialogRef: MatDialogRef<AbteilDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private api: KieneTableService,
    private messageService: MessageService) {
    this.tier = this.data.tier;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.api.updateGeneric(environment.BASE_URI + 'gruppe_tier/update_kundenportal.php', this.tier).subscribe({
      next: tier => {
        this.messageService.infoMessage('Das Abteil wurde erfolgreich gespeichert!');
        this.close();
      },
      error: err => {
        this.messageService.errorMessage('Fehler!', err);
      }
    });
  }

}
