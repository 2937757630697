import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kiene-tierarten-rassen',
  templateUrl: './tierarten-rassen.component.html',
  styleUrls: ['./tierarten-rassen.component.scss']
})
export class TierartenRassenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
