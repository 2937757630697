import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { environment } from '../../../../environments/environment';
import { Indikation, IndikationEvent, } from '../../../warehouse/material/artikel-indikation/indikation';
import { KieneTableService } from '../../kiene-table/kiene-table.service';

@Component({
    selector: 'kiene-diagnose-selector',
    templateUrl: './diagnose-selector.component.html',
    styleUrls: ['./diagnose-selector.component.css'],
})
export class DiagnoseSelectorComponent implements OnInit, OnChanges {
    @ViewChild('diagnoseSelect') diagnoseSelect: MatSelect;

    diagnoseCtrl = new UntypedFormControl();

    indikationen: Indikation[] = [];

    @Input('disabled') disabled = false;
    @Input('required') required = false;
    @Input('kundeMandantId') kundeMandantId: number;

    @Output('change') change = new EventEmitter<IndikationEvent>();

    constructor(private api: KieneTableService) {
    }

    ngOnInit(): void {
        if (this.required) {
            this.diagnoseCtrl.setValidators([Validators.required]);
        }
    }

    ngOnChanges() {
        // this.loadDiagnosen();
    }

    public isValid(): boolean {
        if (!this.required) {
            return true;
        } else {
            return this.diagnoseCtrl.valid;
        }
    }

    loadDiagnosen(
        materialId: number,
        tierartId: number,
        anwendungId: number,
        emit: boolean,
        ladePosition: boolean
    ) {
        let params = new HttpParams();
        params = params.set('artikel_id', materialId.toString());
        if (tierartId) {
            params = params.set('tierart_id', tierartId.toString());
        }
        if (anwendungId) {
            params = params.set('anwendung_id', anwendungId.toString());
        }

        let headers;
        if (this.kundeMandantId !== undefined) {
            headers = { 'MANDANTID-KUNDE': this.kundeMandantId.toString() };
        }
        this.api.get(environment.BASE_URI + 'indikation/read.php', params, headers).subscribe(
            (response) => {
                this.indikationen = response.records;
                if (this.indikationen.length === 1) {
                    this.diagnoseCtrl.setValue(this.indikationen[0]);
                    if (ladePosition) {
                        this.change.emit(
                            new IndikationEvent(this.indikationen[0], {
                                ladePosition: true,
                            })
                        );
                    } else if (emit) {
                        this.change.emit(
                            new IndikationEvent(this.indikationen[0])
                        );
                    }
                } else {
                    if (emit && !ladePosition) {
                        this.setFocus();
                    }
                }
            },
            (error) => {
            }
        );
    }

    indikationSelected(event: MatSelectChange) {
        this.change.emit(new IndikationEvent(event.value));
    }

    indikationOpenedChange(event) {
    }

    indikationComparator(a: Indikation, b: Indikation): boolean {
        if (a && b) {
            return a.indikation_id === b.indikation_id;
        }
        return false;
    }

    public clear(): void {
        this.indikationen = [];
        this.diagnoseCtrl.setValue(null);
        this.diagnoseSelect.close();
        this.diagnoseCtrl.markAsUntouched();
        this.change.emit(new IndikationEvent(null));
    }

    public close(): void {
        this.diagnoseSelect.close();
    }

    public setFocus(): void {
        setTimeout(() => {
            this.diagnoseSelect?.focus();
            this.openPanel();
        }, 50);
    }

    public openPanel() {
        this.diagnoseSelect.open();
    }

    public setDiagnose(
        diagnose: Indikation,
        anwendungId: number,
        emit: boolean,
        ladePosition: boolean
    ) {
        this.diagnoseCtrl.setValue(diagnose, { emitEvent: emit });

        this.loadDiagnosen(
            diagnose.artikel_id,
            diagnose.tierart_id,
            anwendungId,
            emit,
            ladePosition
        );
    }

    public getIndikation(): Indikation | undefined {
        return this.diagnoseCtrl?.value;
    }
}
