import {HeimtierBelegArtikel} from "../heimtier-beleg";

export class HeimtierBelege {
    ht_beleg_id: number;
    beleg_nr: string;
    bericht: string;
    besuchszusammenfassung: string;
    tierarzt_id: number;
    tierarzt: string;
    datum: Date;
    kunde_id: number;
    kunde: string;
    status_id: number;
    status: string;
    pdf_id: number;
    rechnung_id: number;
    behandlung_seit: Date;
    erstellt: Date;
    editiert: Date;
    positionen: HeimtierBelegArtikel[] = [];
}

export class HeimtierBelegeInput extends HeimtierBelege{

}

export class HeimtierBelegeOutput extends HeimtierBelege{
    heimtierBeleg: HeimtierBelege;
    confirm: boolean;
}

//{
//     "recposition": 0,
//     "ht_beleg_id": 1,
//     "beleg_nr": null,
//     "tierarzt_id": null,
//     "tierarzt": null,
//     "datum": "2024-09-19",
//     "kunde_id": 6123,
//     "kunde": "Daniel Neubieser",
//     "status_id": 221,
//     "status": "erstellt",
//     "pdf_id": null,
//     "rechnung_id": null,
//     "behandlung_seit": "2024-10-22T10:05:31+00:00",
//     "erstellt": "2024-10-22T10:05:31+00:00",
//     "editiert": "2024-10-22T10:05:31+00:00",
//     "positionen": [
//         {
//             "recposition": 0,
//             "hba_id": 2,
//             "ht_beleg_id": 1,
//             "position": 1,
//             "artikel_id": 30,
//             "artikel_me": "AH - Aufzuchtkälber Behandlung durchgeführt (HD) (6 kg)",
//             "artikelmenge": null,
//             "indikation_id": null,
//             "diagnose": null,
//             "heimtier_id": 29,
//             "name": "Bubi",
//             "tierart_id": 52,
//             "tierrasse_id": 5,
//             "tiergewicht": null,
//             "anwendungsdauer": null,
//             "dosierung": null,
//             "anwendung_id": null,
//             "anwendung": null,
//             "behandlung": null,
//             "faktor": null,
//             "einzelpreis": null,
//             "aus_stueckliste": null,
//             "erstellt": "2024-10-22T14:36:37+00:00",
//             "editiert": "2024-10-22T14:36:37+00:00"
//         }
//     ]
// }
