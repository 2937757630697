import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FileUploadService} from '../../../dialogs/file-upload-dialog/file-upload.service';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'kiene-upload-invoice-dialog',
    templateUrl: './upload-invoice-dialog.component.html',
    styleUrls: ['./upload-invoice-dialog.component.scss']
})
export class UploadInvoiceDialogComponent implements OnInit {

    @ViewChild('fileSelector') fileSelector;

    datumCtrl = new UntypedFormControl(new Date());

    filesToUpload: File[] = [];
    filesProgressValues: number[] = [];

    url: string;
    ids: number[];
    paramName: string;

    constructor(public dialogRef: MatDialogRef<UploadInvoiceDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
                private uploadService: FileUploadService) {
        this.url = data.url;
        this.ids = data.ids;
        this.paramName = data.paramName;

    }

    ngOnInit() {
    }

    close() {
        this.dialogRef.close();
    }

    openFileSelector() {
        this.fileSelector.nativeElement.click();
    }

    handleFiles(event: any) {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
            const file: File = files.item(i);
            this.filesToUpload.push(file);
            this.filesProgressValues[file.name] = 0;
        }
    }

    uploadFiles() {

        this.uploadService.uploadFilesToServerForMultipleEntities(this.filesToUpload, this.paramName, this.ids, this.url, this.datumCtrl.value, this.setProgress.bind(this), (reponse) => {
        }, () => {
            console.log('Upload finished');
        });
    }

    setProgress(fileName: string, progress: number) {
        this.filesProgressValues[fileName] = progress;
    }

}
