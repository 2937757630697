import {HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {ConfirmDialogInput} from "../../../components/dialogs/confirm-dialog/confirm-dialog";
import {ConfirmDialogComponent} from "../../../components/dialogs/confirm-dialog/confirm-dialog.component";
import {
    DetachedRouteHandlesService
} from '../../../configuration/route-caching-strategy/detached-route-handles.service';
import {API_BASE_URL_SERVICE} from '../../../kiene-core.config';
import {MessageService} from '../../../services/message-service/message.service';
import {KieneUtils} from '../../../utils/KieneUtils';
import {Client} from '../../client/classes/client';
import {GruppenService} from '../../gruppen/gruppen.service';
import {KieneAutocompleteComponent} from '../../kiene-autocomplete/kiene-autocomplete.component';
import {Entsorger} from '../../stammdaten/entsorger/entsorger';
import {
    EntsorgerDetailsDialogComponent
} from '../../stammdaten/entsorger/entsorger-details-dialog/entsorger-details-dialog.component';
import {
    EntsorgerKundenNrVerwaltenDialogComponent
} from '../../stammdaten/entsorger/entsorger-kunden-nr-verwalten-dialog/entsorger-kunden-nr-verwalten-dialog.component';
import {StammdatenService} from '../../stammdaten/stammdaten.service';
import {GruppeTier} from '../classes/gruppe-tier';
import {TodesArt} from '../classes/todes-art';
import {GruppeTierService} from '../gruppe-tier.service';

@Component({
    selector: 'kiene-gruppe-tier-tod-melden',
    templateUrl: './gruppe-tier-tod-melden.component.html',
    styleUrls: ['./gruppe-tier-tod-melden.component.scss']
})
export class GruppeTierTodMeldenComponent implements OnInit, OnDestroy {

    @Input('dialog') dialog = false;

    alleTodesartenSubscription: Subscription;
    entsorgerFavoritenSubscription: Subscription;
    entsorgerSubscription: Subscription;
    gruppenSubscription: Subscription;
    kundeSubscription: Subscription;
    tierSubscription: Subscription;
    tiere: GruppeTier[] = [];
    ausgewaehlteVvvoToolbar: Client;
    isLoading = false;
    params: HttpParams;
    redirectTo: string;
    vvvoOfTierGruppe: string;
    gt_id: number;
    todesarten: TodesArt[] = [];
    selectedTodesartCtrl: UntypedFormControl = new UntypedFormControl(
        '',
        Validators.required
    );
    entsorgerFavoriten: Entsorger[] = [];
    entsorgerCtrl: UntypedFormControl = new UntypedFormControl(undefined);
    @Input('tier')
    tier: GruppeTier;
    @Output('tierGemeldet')
    tierGemeldet: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('tierAutoComplete')
    tiereAutoComplete: KieneAutocompleteComponent;
    todesDatumCtrl = new UntypedFormControl(undefined, [Validators.required]);
    selectedEntsorger: Entsorger;
    watchAusgewaehlteVvvoSub: Subscription;
    readOhrenmarke = `${this.baseUrl}gruppe_tier/read_kundenportal.php?tot=0&historie=0&abgaenge=0`;

    constructor(
        private route: ActivatedRoute,
        private gruppeTierService: GruppeTierService,
        private messageService: MessageService,
        private stammdatenService: StammdatenService,
        private detachedRouteHandlesService: DetachedRouteHandlesService,
        private gruppenService: GruppenService,
        private matDialog: MatDialog,
        @Inject(API_BASE_URL_SERVICE) private baseUrl: string
    ) {
    }

    ngOnInit(): void {
        this.ausgewaehlteVvvoToolbar = this.stammdatenService.getAusgewaehlteVvvo();

        if (this.ausgewaehlteVvvoToolbar) {
            this.params = new HttpParams().set('kunde_id', this.ausgewaehlteVvvoToolbar.kunde_id.toString());
        }

        this.route.params.subscribe((params) => {
            if (params.gt_id) {
                this.gt_id = params.gt_id;

                this.tierSubscription?.unsubscribe();
                this.tierSubscription = this.gruppeTierService.ladeTier(Number(params.gt_id)).subscribe((tier) => {
                    this.setSelectedTier(tier);
                });
            }
        });

        this.watchAusgewaehlteVvvoSub = this.stammdatenService.watchAusgewaehlteVvvo().subscribe((vvvo) => {
            this.tiereAutoComplete.clear();
            this.ausgewaehlteVvvoToolbar = vvvo;
            if (this.ausgewaehlteVvvoToolbar) {
                this.params = new HttpParams().set('kunde_id', this.ausgewaehlteVvvoToolbar.kunde_id.toString());
            } else {
                this.params = undefined;
            }
        });

        this.detachedRouteHandlesService.watchChanges().subscribe((eventName) => {
            if (eventName === 'meldungen-abgang-entsorger-bearbeiten-kundennr') {
                this.entsorgerCtrl.setErrors(null);
                this.entsorgerCtrl.markAsUntouched();
                this.checkEntsorgerKundenNr();
            } else {
                const entsorger = this.stammdatenService.ladeLetztenAngelegtenEntsorger();

                if (entsorger) {
                    this.selectedEntsorger = entsorger;
                    this.entsorgerCtrl.setValue(entsorger);
                }
                this.ladeEntsorgerFavoriten();
            }
        });

        this.entsorgerCtrl.valueChanges.subscribe((value) => {
            this.selectedEntsorger = value;
            this.checkEntsorgerKundenNr();
        });
        this.selectedTodesartCtrl.valueChanges.subscribe((value) => {
            this.selectedTodesartCtrl.setValue(value, {emitEvent: false});
        });


        this.ladeEntsorgerFavoriten();
        this.ladeTodesarten();
    }


    ngOnDestroy(): void {
        this.watchAusgewaehlteVvvoSub?.unsubscribe();
        this.alleTodesartenSubscription?.unsubscribe();
        this.entsorgerSubscription?.unsubscribe();
        this.gruppenSubscription?.unsubscribe();
        this.entsorgerFavoritenSubscription?.unsubscribe();
        this.kundeSubscription?.unsubscribe();
        this.tierSubscription?.unsubscribe();
    }

    ladeTodesarten(): void {
        this.alleTodesartenSubscription?.unsubscribe();
        this.alleTodesartenSubscription = this.stammdatenService.loadTodesarten().subscribe({
            next: (todesarten) => {
                this.todesarten = todesarten.records;
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Fehler beim Laden der Todesarten',
                    err
                );
            },
        });
    }

    ladeEntsorgerFavoriten(entsorger?: Entsorger) {
        this.isLoading = true;
        this.entsorgerFavoritenSubscription?.unsubscribe();
        this.entsorgerFavoritenSubscription = this.stammdatenService.loadEntsorgerFavoriten().subscribe({
            next: (entsorgerFavoriten) => {
                this.entsorgerFavoriten = entsorgerFavoriten.records;
                console.log("ENTSORGER", entsorgerFavoriten.records)
                // letzten benutzten Entsorger vorauswaehlen
                if (this.entsorgerFavoriten.length > 0) {
                    if (entsorger) {
                        this.entsorgerCtrl.setValue(entsorger);
                    } else {
                        this.entsorgerCtrl.setValue(this.entsorgerFavoriten[0])
                    }
                } else {
                    this.entsorgerCtrl.setErrors({'empty-list': true});
                    this.entsorgerCtrl.markAsTouched();
                }
                this.isLoading = false;
            },
            error: (err) => {
                this.isLoading = false;
                this.messageService.errorMessage(
                    'Fehler beim Laden der Entsorgerfavoriten',
                    err
                );
            },
        });
    }

    melden() {
        this.isLoading = true;
        if (!this.entsorgerCtrl.value) {
            const input = new ConfirmDialogInput();
            input.headline = 'Todmeldung ohne Entsorger';
            input.text = 'Soll die Todmeldung ohne Angabe eines Entsorgers erfolgen?';
            input.okButton = 'Melden';

            this.matDialog.open(ConfirmDialogComponent, {data: input}).afterClosed().subscribe({
                next: value => {
                    if (value.confirmed) {
                        this.speicherTodmeldung();
                    }
                }
            })
        } else {
            this.speicherTodmeldung();
        }
    }

    isValid() {
        return (
            this.tier &&
            this.todesDatumCtrl.valid &&
            this.selectedTodesartCtrl.valid
        );
    }

    setSelectedTier(tier: GruppeTier) {
        this.tier = tier;
        this.checkEntsorgerKundenNr();
    }

    compareEntsorger(c1: any, c2: any): boolean {
        return c1 && c2 && c1.entsorger_id === c2.entsorger_id;
    }

    entsorgerHinzufuegen() {
        this.matDialog.open(EntsorgerDetailsDialogComponent, {
            width: '100%',
            maxWidth: '100vw'
        }).afterClosed().subscribe((resp) => {
            if (resp) {
                this.ladeEntsorgerFavoriten(resp);
                this.entsorgerCtrl.setErrors(null);
            }
        });
    }

    kundenNrNachtragen() {
        this.matDialog.open(EntsorgerKundenNrVerwaltenDialogComponent, {
            data: {
                entsorger: this.entsorgerCtrl.value
            }
        }).afterClosed().subscribe(resp => {
            this.selectedEntsorger = resp;
            this.entsorgerCtrl.setValue(this.selectedEntsorger);
        });

    }

    public getLabel(): string {
        if (this.entsorgerCtrl.value === null) {
            return 'Ohne Entsorger';
        }
        return "Entsorger auswählen";
    }

    private speicherTodmeldung(): void {
        const entsorgerId = this.selectedEntsorger?.entsorger_id;
        if (this.tier && this.todesDatumCtrl.valid && this.selectedTodesartCtrl.valid && (entsorgerId || !this.entsorgerCtrl.value)) {
            this.isLoading = true;
            this.gruppeTierService.todMelden(
                this.tier.gt_id,
                this.todesDatumCtrl.value,
                entsorgerId,
                this.selectedTodesartCtrl.value?.todesart_id
            ).subscribe({
                next: () => {
                    this.isLoading = false;
                    this.messageService.infoMessage('Tier erfolgreich gemeldet');
                    this.tierGemeldet.emit(true);
                    this.tiereAutoComplete.clear();
                    this.selectedTodesartCtrl.setValue(null);
                    this.selectedTodesartCtrl.markAsUntouched();
                },
                error: (err) => {
                    this.isLoading = false;
                    this.messageService.errorMessage('Tiermeldung fehlgeschlagen: ', err);
                },
            });
        }
    }


    private checkEntsorgerKundenNr() {
        if (this.tier && this.selectedEntsorger) {
            this.isLoading = true;
            this.entsorgerSubscription?.unsubscribe();
            this.entsorgerSubscription = this.ladeEntsorger(this.selectedEntsorger).subscribe({
                next: (entsorger) => {
                    this.selectedEntsorger = entsorger;
                    this.gruppenSubscription?.unsubscribe();
                    this.gruppenSubscription = this.gruppenService.getGruppeKundenportal(this.tier.gruppe_id).subscribe({
                        next: (resp) => {
                            this.kundeSubscription?.unsubscribe();
                            this.kundeSubscription = this.stammdatenService.getKunde(resp.kunde_id).subscribe({
                                next: (kd) => {
                                    const kunde = <Client>kd;
                                    this.vvvoOfTierGruppe = kunde.vvvo;
                                    if (
                                        this.selectedEntsorger.kundenNrVvvos?.some(
                                            (entry) =>
                                                entry.kunde_id ===
                                                kunde.kunde_id &&
                                                KieneUtils.isNNoU(
                                                    entry.kunden_nr
                                                )
                                        )
                                    ) {
                                        if (this.entsorgerCtrl.errors) {
                                            delete this.entsorgerCtrl.errors[
                                                'keine-kundennr-fuer-entsorger'
                                                ];
                                            this.entsorgerCtrl.markAsUntouched();
                                        }
                                    } else {
                                        this.entsorgerCtrl.setErrors({
                                            'keine-kundennr-fuer-entsorger':
                                                true,
                                        });
                                        this.entsorgerCtrl.markAsTouched();
                                    }
                                    this.isLoading = false;
                                },
                                error: err => {
                                    this.messageService.errorMessage('Fehler!', err);
                                }
                            });
                        },
                        error: (err) => {
                            this.isLoading = false;
                            this.messageService.errorMessage(
                                'Fehler beim Prüfen der Entsorger-Kundennr!',
                                err
                            );
                        },
                    });
                },
                error: err => {
                    this.isLoading = false;
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        }
    }

    private ladeEntsorger(entsorger: Entsorger) {
        return this.stammdatenService.getEntsorger(entsorger.entsorger_id);
    }
}
