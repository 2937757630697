import { Component, Input, OnInit } from '@angular/core';
import { HeimtierBeleg } from '../../heimtier-beleg';

@Component({
    selector: 'kiene-besuchs-detail-letzte-belege',
    templateUrl: './besuchs-detail-letzte-belege.component.html',
    styleUrls: ['./besuchs-detail-letzte-belege.component.scss']
})
export class BesuchsDetailLetzteBelegeComponent implements OnInit {
    @Input() heimtierBeleg: HeimtierBeleg;

    constructor() {
    }

    ngOnInit(): void {
    }

}
