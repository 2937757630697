import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {StoragePlace, StoragePlaceApi} from './storage-place';
import {Observable} from 'rxjs';
import {Charge} from '../charges/charges.component';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable()
export class StoragePlaceService {

    constructor(private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    public getAllStoragePlaces(sortColumn: string, sortDirection: string, offset: number, limit: number, searchString: string): Observable<StoragePlaceApi> {
        return this.http.get<StoragePlaceApi>(this.apiBaseUrl + 'lagerplatz/read.php?orderby=' + sortColumn + ' ' + sortDirection + '&limit=' + limit + '&offset=' + offset + '&search=' + searchString);
    }

    public saveStoragePlace(storagePlace: StoragePlace): Observable<StoragePlace> {
        return this.http.post<StoragePlace>(this.apiBaseUrl + 'lagerplatz/create.php', storagePlace);
    }

    public updateStoragePlace(storagePlace: StoragePlace): Observable<StoragePlace> {
        return this.http.post<StoragePlace>(this.apiBaseUrl + 'lagerplatz/update.php', storagePlace);
    }

    public searchStoragePlaces(searchString: string): Observable<StoragePlaceApi> {
        return this.http.get<StoragePlaceApi>(this.apiBaseUrl + 'lagerplatz/read.php?search=' + searchString);
    }

    public getStoragePlacesForMaterial(artikelId: number): Observable<StoragePlaceApi> {
        return this.http.get<StoragePlaceApi>(this.apiBaseUrl + 'lagerplatz/read_by_artikel.php?artikel_id=' + artikelId);
    }

    public getVetStoragePlaces(): Observable<StoragePlaceApi> {
        // lager_id = 3: Alle Tierarztwagen
        return this.http.get<StoragePlaceApi>(this.apiBaseUrl + 'lagerplatz/read.php?lager_id=3');
    }

    public getStoragePlacesForCharge(charge: Charge): Observable<StoragePlaceApi> {
        const params = new HttpParams()
            .set('charge_id', charge.charge_id.toString());
        return this.http.get<StoragePlaceApi>(this.apiBaseUrl + 'lagerplatz/read_by_charge.php', {params: params});
    }

}
