import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { Client } from '../../../projects/kiene-core/src/lib/components/client/classes/client';
import { Router } from '@angular/router';

@Component({
    selector: 'kiene-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

    currentClient: Client = new Client();

    constructor(private localStorageService: LocalStorageService, public dialog: MatDialog, public router: Router) {
        this.localStorageService.setShoppingCartVisible(false);
    }

    ngOnInit() {
        this.currentClient = this.localStorageService.getCurrentClient();

        if (this.currentClient === undefined || this.currentClient === null) {

        } else {
            this.localStorageService.setCurrentClient(this.currentClient);
        }

        this.localStorageService.watchCurrentClient().subscribe(client => {
            this.currentClient = client;
        });

    }

    isTabMenuVisible(path: string) {
        if (this.localStorageService.isCurrentUserKunde() && path === '/belege/gruppen') {
            return false;
        }
        return this.localStorageService.currentUserCanSeeTab(path);
    }

    showTabBestandsbuch() {
        if (this.currentClient?.bestandsbuch_digital > 0 || this.currentClient?.bestandsbuch_digital_historie > 0) {
            return true;
        } else if (!this.currentClient) {
            return true;
        }
        return false;
    }

}
