import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StammdatenService} from '../../stammdaten.service';
import {Entsorger, KundenNrVvvo} from '../entsorger';
import {Client} from '../../../client/classes/client';
import {MessageService} from '../../../../services/message-service/message.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'kiene-entsorger-kunden-nr-verwalten',
    templateUrl: './entsorger-kunden-nr-verwalten.component.html',
    styleUrls: ['./entsorger-kunden-nr-verwalten.component.scss']
})
export class EntsorgerKundenNrVerwaltenComponent implements OnInit, OnDestroy {

    vvvoSubscription: Subscription;
    entsorgerSubscription: Subscription;
    isLoading = false;

    @Input('entsorger')
    entsorger: Entsorger;
    kunden: Client[] = [];
    redirectTo = 'einstellungen-stammdaten-entsorger';
    ersteinrichtung = false;

    @Input('dialog') dialog = false;

    @Output('saved') saved: EventEmitter<Entsorger> = new EventEmitter();

    constructor(private route: ActivatedRoute, private router: Router, private stammdatenService: StammdatenService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {

        if (this.entsorger) {
            this.isLoading = false;
        }

        this.route.queryParams.subscribe(params => {
            this.ersteinrichtung = !!params.ersteinrichtung;
            if (this.ersteinrichtung) {
                this.redirectTo = 'registrierung-ersteinrichtung';
            }
        });

        this.route.params.subscribe(params => {
            if (params.entsorger_id) {
                this.entsorgerSubscription?.unsubscribe();
                this.entsorgerSubscription = this.stammdatenService.getEntsorger(params.entsorger_id).subscribe({
                    next: (entsorger) => {
                        this.isLoading = false;
                        this.entsorger = entsorger;
                    }, error: err => {
                        this.isLoading = false;
                        this.messageService.errorMessage('Fehler!', err);
                    }
                });
            }
        });

        this.route.queryParams.subscribe(qp => {
            if (qp.redirectTo) {
                this.redirectTo = qp.redirectTo;
            }
        });
        this.initVvvos();

    }

    /**
     * sofern eine Route als queryParam ueber die URL mitgegeben wurde im Format aaa-bbb-ccc, wird dort hingeleitet
     */
    speichern() {
        this.isLoading = true;
        this.stammdatenService.updateEntsorger(this.entsorger).subscribe({
            next: resp => {
                this.isLoading = false;
                if (this.dialog) {
                    this.saved.emit(resp);
                } else {
                    if (this.redirectTo) {
                        this.router.navigate(['../../../'], {relativeTo: this.route}).then();
                    } else {
                        window.history.back();
                    }
                }

            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
                this.isLoading = false;
            }
        });
    }

    ngOnDestroy(): void {
        this.vvvoSubscription?.unsubscribe();
        this.entsorgerSubscription?.unsubscribe();
    }

    private initVvvos() {
        this.isLoading = true;
        this.vvvoSubscription?.unsubscribe();
        this.vvvoSubscription = this.stammdatenService.ladeVvvos().subscribe({
            next: (vvvos) => {
                this.isLoading = false;
                this.kunden = <Client[]>vvvos.records;
                // add all
                if (this.entsorger.kundenNrVvvos.length === 0) {
                    this.kunden.forEach(kunde => {
                        this.entsorger.kundenNrVvvos.push(new KundenNrVvvo(kunde.kunde_id, null, kunde.vvvo,
                            kunde.bezeichnung_final, kunde.zusatz_final));
                    });

                    // only add new
                } else {
                    this.kunden.forEach(kunde => {
                        const found = this.entsorger.kundenNrVvvos.find(entry => entry.kunde_id === kunde.kunde_id);
                        if (!found) {
                            this.entsorger.kundenNrVvvos.push(new KundenNrVvvo(kunde.kunde_id, null,
                                kunde.vvvo, kunde.bezeichnung_final, kunde.zusatz_final));
                        } else {
                            found.betrieb = kunde.bezeichnung_final;
                            found.betrieb_zusatz = kunde.zusatz_final;
                        }
                    });
                }
                this.entsorger.kundenNrVvvos.sort((obj1, obj2) => {
                    if (obj1.kunde_id > obj2.kunde_id) {
                        return 1;
                    }

                    if (obj1.kunde_id < obj2.kunde_id) {
                        return -1;
                    }

                    return 0;
                });
            },
            error: err => {
                this.isLoading = false;
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

}
