<div [style.padding]="dialog ? '0' : '0 24px 64px 24px'">

    <kiene-page-header *ngIf="!dialog" [ueberschrift]="'Händler zuordnen'"></kiene-page-header>

    <div style="text-align:center; margin-top: 128px" *ngIf="!(viehhaendler && tier)">
        <mat-hint class="hinweis-text">Lade Tierh&auml;ndler...</mat-hint>
    </div>


    <div *ngIf="viehhaendler && tier">

        <h3>Für Tier: {{tier.ohrmarke_notnull}}</h3>

        <div style="margin-bottom: 12px" >
            <mat-hint class="hinweis-text">
                <strong>Hinweis:</strong> Neue Viehh&auml;ndler können über das + Symbol hinzufügt werden.
            </mat-hint>
        </div>

        <div class="one-row">
            <mat-form-field class="no-padding-margin-mat-form-field" style="width: 90%;">
                <mat-label>Viehh&auml;ndler</mat-label>
                <mat-select [formControl]="haendlerCtrl" [compareWith]="compareHaendler">
                    <mat-option [value]="null">--</mat-option>
                    <mat-option *ngFor="let haendler of viehhaendler" [value]="haendler">
                        {{haendler.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-icon-button color="accent" (click)="addHaendler()"><mat-icon>add</mat-icon></button>
        </div>

        <div class="one-row" style="display: flex; align-items: center; justify-content: center; padding-top: 24px">
            <button [disabled]="isLoading" style="width: 50%" mat-flat-button color="primary" (click)="save()">
                {{buttonText}}
            </button>
            <span *ngIf="dialog" class="spacer"></span>
            <ng-content select="[additional-actions]"></ng-content>
        </div>

    </div>
</div>
