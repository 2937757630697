<h1 mat-dialog-title>F&uuml;r andere Mandanten &uuml;bernehmen?</h1>
<div mat-dialog-content>
    <p>Sollen die &Auml;nderungen f&uuml;r die folgenden Mandanten &uuml;bernommen werden?</p>
    <ul style="list-style-type: none;">
        <li *ngFor="let m of mandanten">
            <mat-checkbox [formControl]="mandantCtrl[m.mandant_id]"
                          (change)="updateSelectedMandanten()">{{m.bezeichnung}}</mat-checkbox>
        </li>
    </ul>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()">Abbrechen</button>
    <span class="spacer"></span>
    <button mat-flat-button color="accent" (click)="saveForSelected()"
            [disabled]="selectedMandantenIds?.length < 1">{{buttonText}}</button>
</div>
