<h1 mat-dialog-title>{{inventurEintrag?.artikel_me}}</h1>
<div mat-dialog-content>
  <mat-form-field style=" width: 100%;">
    <mat-label>Menge</mat-label>
    <input [formControl]="mengeCtrl" matInput type="number" aria-label="Menge" required>
    <mat-error *ngIf="this.mengeCtrl.invalid"> Menge >= 0</mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()">Abbrechen</button>
  <button mat-flat-button (click)="save()" [disabled]="this.mengeCtrl.invalid" color="accent">Speichern</button>
</div>
