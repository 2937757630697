<h2 mat-dialog-title>Behandlung</h2>
<mat-dialog-content>
  <p>Behandlung?</p>
  <mat-radio-group [formControl]="yesNoCtrl" class="radio_group">
    <mat-radio-button value=1>ja</mat-radio-button>
    <mat-radio-button value=0>nein</mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
  <button mat-raised-button color="primary" (click)="save()">Speichern</button>
</mat-dialog-actions>