import {HttpParams} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {AppService} from '../../app.service';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {TableColumn} from '../../components/kiene-table/table-column';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MessageService} from '../../services/message.service';
import {BestandElementCharge} from '../bestand/bestand-element';
import {BookLossDialog, CorrectStockDialog, TransferStockDialog} from '../bestand/bestand.component';
import {AddVetStockDialogComponent} from '../stock/add-vet-stock-dialog/add-vet-stock-dialog.component';
import {StockService} from '../stock/stock.service';
import {KieneSession} from '../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';

@Component({
    selector: 'kiene-tierarzt-bestand',
    templateUrl: './tierarzt-bestand.component.html',
    styleUrls: ['./tierarzt-bestand.component.css']
})
export class TierarztBestandComponent implements OnInit {

    tableDescriptor: TableDescriptor = new TableDescriptor();

    @Input('tierarztId') tierarztId;

    kieneSession: KieneSession;


    constructor(public dialog: MatDialog,
                private router: Router,
                private messageService: MessageService,
                private localStorageService: LocalStorageService,
                private tableService: KieneTableService,
                private appService: AppService,
                private stockService: StockService) {
    }

    ngOnInit() {

        this.kieneSession = this.localStorageService.getCurrentUser();
        if (!this.tierarztId) {
            this.tierarztId = this.kieneSession?.tierarzt_id;
        }


        this.tableDescriptor.headline = 'Mein Tierarztwagen Bestand';
        this.tableDescriptor.uniqueIdentifier = 'Mein Tierarztwagen Bestand';
        this.tableDescriptor.apiUrl = environment.BASE_URI + 'bestand_charge/';
        let params = new HttpParams().set('tierarzt_id', this.tierarztId.toString());
        this.tableDescriptor.params = params;
        this.tableDescriptor.route = '/home?tab=meinbestand';
        this.tableDescriptor.nameOfIdField = 'lc_id';
        this.tableDescriptor.searchPlaceholder = 'Mein Bestand durchsuchen';

        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;


        this.tableDescriptor.columns.push(new TableColumn('lager', 'Lager'));
        this.tableDescriptor.columns.push(new TableColumn('bezeichnung', 'Lagerplatz'));
        this.tableDescriptor.columns.push(new TableColumn('artikel_me', 'Artikel'));
        this.tableDescriptor.columns.push(new TableColumn('charge', 'Charge'));
        this.tableDescriptor.columns.push(new TableColumn('menge', 'Menge'));
        this.tableDescriptor.columns.push(new TableColumn('editiert', 'Editiert', 'datetime'));

        // this.tableDescriptor.toolbarButtons.push(new ToolbarButton('add', 'add', 1136, 'Bestand hinzufügen'));

        this.tableDescriptor.permissionView = 1131;
        this.tableDescriptor.permissionCreate = 1136;
        this.tableDescriptor.permissionUpdate = 1139;

        this.tableDescriptor.options.push(new TableOption('verlust', 'Verlust Buchen', 'delete_sweep', 1139));
        this.tableDescriptor.options.push(new TableOption('korrigieren', 'Bestand korrigieren', 'undo', 1139));


        this.tableDescriptor.initialSortColumn = 'editiert';
        this.tableDescriptor.initialSortDirection = 'desc';

    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            this.openAddVetStockDialog();
        } else if (event.name === 'verlust') {
            this.openBookLossDialog(event.value);
        } else if (event.name === 'korrigieren') {
            this.openCorrectStockDialog(event.value);
        }
    }

    openAddVetStockDialog() {
        const dialogRef = this.dialog.open(AddVetStockDialogComponent, {
            minWidth: '300px',
            width: '33%'
        });

        dialogRef.afterClosed().subscribe(stock => {
            if (stock) {
                this.tableService.post(environment.BASE_URI + 'bestand_charge/create_tierarzt.php', stock, null).subscribe(response => {
                    this.messageService.infoMessage('Der Bestand wurde erfolgreich hinzugefügt!');
                    this.tableService.reload();
                }, error => {
                    this.messageService.errorMessage('Der Bestand konnte nich hinzugefügt werden!', error);
                });
            }
        });
    }

    openTransferStockDialog(row: BestandElementCharge) {
        const dialogRef = this.dialog.open(TransferStockDialog, {width: '800px', data: row});

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const tso = result;
                console.debug('result: ' + JSON.stringify(tso));
                this.stockService.transferStock(tso).subscribe(result => {
                    this.messageService.infoMessage('Umbuchung von ' + tso.artikel + ' war erfolgreich!');
                    this.tableService.reload();
                }, error => {
                    this.messageService.errorMessage('Umbuchung von ' + tso.artikel + ' ist fehlgeschlagen!', error);
                });
            }
        });
    }

    openBookLossDialog(row: BestandElementCharge) {
        const dialogRef = this.dialog.open(BookLossDialog, {width: '800px', data: row});

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const tso = result;
                console.debug('result: ' + JSON.stringify(tso));
                this.stockService.transferStock(tso).subscribe(result => {
                    const stock: BestandElementCharge = <BestandElementCharge>result;
                    this.messageService.infoMessage('Verlustbuchung von ' + stock.artikel + ' war erfolgreich!');
                    this.tableService.reload();
                }, error => {
                    this.messageService.errorMessage('Verlustbuchung ist fehlgeschlagen: ', error);
                });
            }
        });
    }

    openCorrectStockDialog(row: BestandElementCharge) {
        const dialogRef = this.dialog.open(CorrectStockDialog, {width: '800px', data: row});
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const tso = result;
                console.debug('result: ' + JSON.stringify(tso));
                this.stockService.updateStock(tso).subscribe(result => {
                    const stock = <BestandElementCharge>result;
                    this.messageService.infoMessage('Bestandskorrektur von ' + stock.artikel + ' war erfolgreich!');
                    this.tableService.reload();
                }, error => {
                    this.messageService.errorMessage('Bestandskorrektur ist fehlgeschlagen: ', error);
                });
            }
        });
    }

}
