import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { API_BASE_URL_SERVICE } from '../kiene-core.config';
import { KieneBackendApiService } from '../services/backend-api/kiene-backend-api.service';
import { HitLoginDetails } from './hit-registrierung/classes/hit-login-details';
import { HitLoginResponse } from './hit-registrierung/classes/hit-login-response';

@Injectable({
    providedIn: 'root',
})
export class HitService {
    hitLoginStatusErfolgreich: Subject<boolean> = new Subject();

    private hitLoginResponse: HitLoginResponse;

    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private baseUrl: string
    ) { }

    watchHitLoginStatusErfolgreich() {
        return this.hitLoginStatusErfolgreich.asObservable();
    }

    setHitLoginStatusFehler() {
        this.hitLoginStatusErfolgreich.next(false);
    }

    setHitLoginStatusErfolgreich(hitLoginResponse: HitLoginResponse) {
        this.hitLoginResponse = hitLoginResponse;
        this.hitLoginStatusErfolgreich.next(true);
    }

    getHitLoginResponse(): HitLoginResponse {
        return this.hitLoginResponse;
    }

    hasHitLoggedIn(): boolean {
        return this.hitLoginResponse != null;
    }

    /**
     * anstossen eines HIT-Logins
     * der Status kann von einer beliebigen Component abgefragt werden ueber das entsprechende Subject des Services
     * <br> siehe watchHitLoginStatusErfolgreich()
     * @param hitLoginDetails
     */
    hitLogin(hitLoginDetails: HitLoginDetails) {
        return this.api.post(
            `${this.baseUrl}_qs_hit/hit_betriebsdaten.php`,
            hitLoginDetails,
            null
        );
    }
}
