<div *kienePermission="[1691]" style="padding: 24px">
    <mat-card>
        <div class="one-row">
            <h1>Anwendungen</h1>
            <span class="spacer"></span>
            <div class="one-row">
                <span class="spacer"></span>
                <button
                    *kienePermission="[1293]"
                    mat-icon-button
                    color="accent"
                    (click)="add()"
                >
                    <mat-icon>add</mat-icon>
                </button>
                <button mat-icon-button color="accent" (click)="reload()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        </div>

        <ul
            class="draggable-table"
            style="list-style-type: none; padding: 0px; margin: 0px"
            cdkDropList
            (cdkDropListDropped)="reorderList($event)"
        >
            <li class="draggable-row" *ngFor="let a of anwendungen" cdkDrag>
                <div
                    class="example-custom-placeholder"
                    *cdkDragPlaceholder
                ></div>
                <div class="one-row">
                    <p style="width: 34%">{{ a.anwendung }}</p>
                    <p style="width: 10%">{{ a.sortierung }}</p>
                    <p style="width: 10%">QS: {{ a.qs_schluessel }}</p>
                    <p style="width: 10%">HIT: {{ a.hit_schluessel }}</p>
                    <div style="width: 28%">
                        <table style="width: 100%" class="phito-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Tierart</th>
                                    <th>Wartezeit Fleisch</th>
                                    <th>Wartezeit Milch</th>
                                    <th>Wartezeit Eier</th>
                                    <th>Kommentar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let t of a?.tierarten">
                                    <td></td>
                                    <td>{{ t.tierart }}</td>
                                    <td>{{ t.wartezeit_fleisch }}</td>
                                    <td>{{ t.wartezeit_milch }}</td>
                                    <td>{{ t.wartezeit_eier }}</td>
                                    <td>{{ t.kommentar }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        aria-label="Anwendung Menu"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            *kienePermission="[1693]"
                            mat-menu-item
                            (click)="openAnwendungDialog(a)"
                        >
                            <mat-icon>edit</mat-icon>
                            <span>Bearbeiten</span>
                        </button>

                        <button
                            *kienePermission="[1694]"
                            mat-menu-item
                            (click)="openDeleteDialog(a)"
                        >
                            <mat-icon>delete</mat-icon>
                            <span>L&ouml;schen</span>
                        </button>
                    </mat-menu>
                </div>
            </li>
        </ul>
    </mat-card>
</div>
