import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MessageService} from '../../../services/message-service/message.service';
import {GruppenService} from '../gruppen.service';
import {Tierliste} from '../../gruppe-tier/classes/tierliste';
import {Subscription} from 'rxjs';
import {AbgangDownloadRequestPojo} from './gruppe-abgang-download-dialog';

@Component({
    selector: 'kiene-gruppe-abgang-download-dialog',
    templateUrl: './gruppe-abgang-download-dialog.component.html',
    styleUrls: ['./gruppe-abgang-download-dialog.component.scss'],
})
export class GruppeAbgangDownloadDialogComponent implements OnDestroy {
    tierlistCtrl: UntypedFormControl = new UntypedFormControl(
        null,
        Validators.required
    );
    showBarcodeCtrl: UntypedFormControl = new UntypedFormControl(0);
    showGruppendetailsCtrl: UntypedFormControl = new UntypedFormControl(0);
    showTiergruppeCtrl: UntypedFormControl = new UntypedFormControl(0);
    addScansCtrl: UntypedFormControl = new UntypedFormControl(0);

    tierlisten: Tierliste[] = [];
    tierlisteData: Tierliste = undefined;
    tierlistenSubscription: Subscription;

    downloadAsPdf = false;
    downloadAsExcel = false;
    downloadAsCsv = false;

    constructor(
        public dialogRef: MatDialogRef<GruppeAbgangDownloadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private messageService: MessageService,
        private gruppenService: GruppenService
    ) {
        if (this.data.tierliste) {
            this.tierlisteData = this.data.tierliste;
        } else if (data.gruppe?.gruppe_id) {
            this.ladeTierliste(data.gruppe.gruppe_id);
        }
    }

    ngOnDestroy(): void {
        this.tierlistenSubscription?.unsubscribe();
    }

    ladeTierliste(gruppe_id: number) {
        this.tierlistenSubscription?.unsubscribe();
        this.tierlistenSubscription = this.gruppenService
            .ladeTierlistenHistorie(gruppe_id)
            .subscribe({
                next: (response) => {
                    this.tierlisten = response.records;
                    if (this.tierlisten.length === 1) {
                        this.tierlistCtrl.setValue(this.tierlisten, {
                            emitEvent: false,
                        });
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }

    downloadFiles() {
        if (this.downloadAsCsv === false && this.downloadAsExcel === false && this.downloadAsPdf === false) {
            this.messageService.alertMessage('Bitte mindestens einen Dateitypen auswählen!');
            return;
        }

        let tierlisten_ids = [];
        if (this.tierlisteData) {
            tierlisten_ids.push(this.tierlisteData.tierliste_id);
        } else {
            if (this.tierlistCtrl.value) {
                for (const tierliste of this.tierlistCtrl.value) {
                    tierlisten_ids.push(tierliste.tierliste_id);
                }
            }
        }

        if (tierlisten_ids.length === 0) {
            this.messageService.alertMessage('Bitte mindestens eine Liste auswählen!');
            return;
        }

        const result = new AbgangDownloadRequestPojo(
            tierlisten_ids,
            this.showBarcodeCtrl.value,
            this.showGruppendetailsCtrl.value,
            this.showTiergruppeCtrl.value,
            this.addScansCtrl.value
        );
        result.csv = this.downloadAsCsv;
        result.excel = this.downloadAsExcel;
        result.pdf = this.downloadAsPdf;

        this.dialogRef.close(result);
    }

    cancel() {
        this.dialogRef.close();
    }

    compareTierliste(a: Tierliste, b: Tierliste) {
        return a && b && a.tierliste_id === b.tierliste_id;
    }
}
