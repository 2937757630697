export class Entsorger {
    entsorger_id: number;
    name: string;
    vvvo: string;
    favorit: number;
    kundenNrVvvos: KundenNrVvvo[] = [];
    email: string;
    telefon: string;
    mobil: string;
    fax: string;

    strasse: string;
    plz: string;
    ort: string;
}

export class KundenNrVvvo {
    constructor(kundeId: number, kundenNr: number, vvvo?: string, betrieb?: string, betrieb_zusatz?: string) {
        this.vvvo = vvvo;
        this.kunden_nr = kundenNr;
        this.kunde_id = kundeId;
        this.betrieb = betrieb;
        this.betrieb_zusatz = betrieb_zusatz;
    }

    vvvo: string;
    kunden_nr: number;
    kunde_id: number;
    // Temporaeres Feld fuers Frontend
    betrieb: string;
    // Temporaeres Feld fuers Frontend
    betrieb_zusatz: string;
}