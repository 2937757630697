import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    Artikelliste,
    ArtikellisteArtikel,
} from '../../beleg-position/artikelliste-auswahl-dialog/artikelliste-auswahl-dialog';
import {
    ArticleScanAutocompleteComponent,
    ArticleScanSelectedEvent,
} from '../../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import {ArtikelTierart, ArtikelTierartEvent,} from '../../../components/tierart-selector/artikel-tierart';
import {TierartSelectorComponent} from '../../../components/tierart-selector/tierart-selector.component';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {Anwendung, AnwendungEvent} from '../../behandlungsanfrage/anwendung';
import {AnwendungsSelectorComponent} from '../../../components/anwendungs-selector/anwendungs-selector.component';
import {Indikation, IndikationEvent,} from '../../../warehouse/material/artikel-indikation/indikation';
import {
    DiagnoseSelectorComponent
} from '../../../components/diagnose-selector/diagnose-selector/diagnose-selector.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {KieneUtils} from '../../../../../projects/kiene-core/src/lib/utils/KieneUtils';
import {Material} from '../../../warehouse/material/material';
import {InitMiniTable} from '../../../components/kiene-mini-table/kiene-mini-table.component';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {MatSelect} from '@angular/material/select';
import {FormControlHelper} from '../../../services/form-helpers.service';
import {MessageService} from '../../../services/message.service';
import {Mandant} from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'kiene-hot-key-details-dialog',
    templateUrl: './hot-key-details-dialog.component.html',
    styleUrls: ['./hot-key-details-dialog.component.scss'],
})
export class HotKeyDetailsDialogComponent implements OnInit {
    @ViewChild('articleAutocomplete')
    articleAutocomplete: ArticleScanAutocompleteComponent;
    @ViewChild('tierartSelector') tierartSelector: TierartSelectorComponent;
    @ViewChild('anwendungSelector')
    anwendungSelector: AnwendungsSelectorComponent;
    @ViewChild('diagnoseSelector') diagnoseSelector: DiagnoseSelectorComponent;
    @ViewChild('behandlungJaNein') behandlungJaNein: MatSelect;
    @ViewChild('focusOnInit') focusOnInit = true;

    artikellistenForm: FormGroup;
    anwendungsdauerCtrl: FormControlHelper;
    dosierungCtrl: FormControlHelper;
    listenTitelCtrl: FormControlHelper;
    behandlungJaNeinCtrl: FormControlHelper;

    apiUrl = 'artikelliste/';
    initMiniTable = new InitMiniTable();
    mandant: Mandant;
    listenArtikel = new ArtikellisteArtikel();
    private attributes: string[] = [
        'artikel_id',
        'tierart_id',
        'anwendung_id',
        'indikation_id',
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) public articleList: Artikelliste,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private localStorageService: LocalStorageService,
        public dialogRef: MatDialogRef<HotKeyDetailsDialogComponent>,
        private api: KieneBackendApiService,
        public messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.initForm();
        this.mandant = this.localStorageService.getCurrentMandant();
        this.tableConstructor(this.articleList.artikel);
        this.listenTitelCtrl.value = this.articleList.listenname;
    }

    tableConstructor(data: any[]) {
        this.initMiniTable = {
            title: 'Positionen',
            columnNames: [
                'Artikel',
                'Tierart',
                'Anwendung',
                'Diagnose',
                'Behandlung',
                'Anwendungsdauer',
                'Dosierung',
            ],
            attributes: [
                'artikel_me',
                'tierart',
                'anwendung',
                'diagnose',
                'behandlung',
                'anwendungsdauer',
                'dosierung',
            ],
            dataList: data,
            listeLaenge: data.length,
            hideAddButton: true,
            hideCloseButton: true,
            disableKeyControl: true,
            addActionButtons: true,
            kienePermissionDeleteItem: 2682,
            attributeID: 'aa_id',
            modifiedAttributes: [
                {
                    attribute: 'behandlung',
                    values: this.modifyValuesOfBehandlung(),
                },
            ],
        };
    }

    initForm() {
        let formControls = {
            anwendungsdauerCtrl: new FormControl(null, Validators.required),
            dosierungCtrl: new FormControl(null, Validators.required),
            listenTitelCtrl: new FormControl(null, Validators.required),
            behandlungJaNeinCtrl: new FormControl(null),
        };

        this.artikellistenForm = new FormGroup(
            formControls,
            this.validateAttributes
        );

        /** Instanziert die Helper für die einzelnen Formcontrols*/
        for (let ctrlName in formControls) {
            this[ctrlName] = new FormControlHelper(
                this.artikellistenForm,
                ctrlName
            );
        }
    }

    /**
     * Die zu validierenden Attribute müssen nach Einstellung des Attributes angepasst werden.
     */
    setAttributesForValidation() {
        if (this.istMengenArtikel()) this.attributes = ['artikel_id'];
        else
            this.attributes = [
                'artikel_id',
                'tierart_id',
                'anwendung_id',
                'indikation_id',
            ];
    }

    /**
     * Bietet den erforderlichen Parameter um diesen an eine FormGroup zu koppeln.
     */
    validateAttributes = () => {
        let isValid = true;
        for (let attr of this.attributes) {
            if (!this.listenArtikel[attr]) {
                isValid = false;
                break;
            }
        }
        return isValid ? null : {invalidAttributes: true};
    };

    /**
     * In der Datenbank werden keine Boolean-Werte gespeichert. Stattdessen werden diese Zustände mit
     * 1 & 0 abgebildet. Für die Darstellung in der Tabelle wird hier ein Array mit dem anzuzeigenden Inhalt
     * erstellt. Diese wird an das initMiniTable-Objekt übergeben.
     */
    modifyValuesOfBehandlung(): string[] {
        let modifiedBehandlung: string[] = [];
        this.articleList.artikel.forEach((el) => {
            if (el.behandlung > 0) {
                modifiedBehandlung.push('Ja');
            } else {
                modifiedBehandlung.push('Nein');
            }
        });
        return modifiedBehandlung;
    }

    materialInputCleared() {
        this.felderLeeren(false);
    }

    setSelectedMaterial($event: ArticleScanSelectedEvent) {
        this.articleList.artikel.forEach((el) => {
            if (el.artikel_id === $event.article.artikel_id) {
                this.messageService.infoMessage(
                    'Der gewählte Artikel befindet sich bereits in der Liste und kann nicht zweimal hinzugeüfgt werden.'
                );
                this.felderLeeren(true);
                return;
            }
        });
        this.listenArtikel.abgabe = $event.article.abgabe;
        this.listenArtikel.abgabeundbehandlung =
            $event.article.abgabeundbehandlung;
        this.listenArtikel.anwendungsdauer_fix =
            $event.article.anwendungsdauer_fix;
        this.listenArtikel.artikel_id = $event.article.artikel_id;
        this.listenArtikel.artikel_me = $event.article.artikel_me;
        this.listenArtikel.behandlung = $event.article.behandlung;
        this.listenArtikel.dosierung_fix = $event.article.dosierung_fix;
        this.listenArtikel.impfung = $event.article.impfung;
        this.listenArtikel.mengenartikel = $event.article.mengenartikel;
        this.listenArtikel.stueckliste = $event.article.stueckliste;
        this.listenArtikel.verpackungsmenge = $event.article.verpackungsmenge;
        this.checkInputsDisabled(true);
        if (this.listenArtikel.mengenartikel > 0) {
            this.savePosition();
        } else {
            this.afterSelectedMaterial($event.article.artikel_id);
        }
    }

    afterSelectedMaterial(materialID: number) {
        this.tierartSelector.ladeTierartenFuerMaterialUndKunde(
            'setSelectedMaterial',
            materialID,
            true,
            false
        );
        this.artikellistenForm.updateValueAndValidity();
    }

    setSelectedTierart(event: ArtikelTierartEvent) {
        if (!event.artikelTierart) {
            return;
        }
        this.listenArtikel.tierart_id = event.artikelTierart.tierart_id;
        this.listenArtikel.tierart = event.artikelTierart.tierart;
        this.listenArtikel.dosierung_txt = event.artikelTierart.dosierung_txt;
        this.listenArtikel.dosierung_tierart = event.artikelTierart.menge;
        this.listenArtikel.koerpergewicht_tierart =
            event.artikelTierart.koerpergewicht;
        if (!this.anwendungsdauerCtrl.value) {
            this.anwendungsdauerCtrl.setControlValue(
                event.artikelTierart.behandlung_tage,
                {emitEvent: false}
            );
        }
        if (!this.dosierungCtrl.value) {
            this.dosierungCtrl.setControlValue(event.artikelTierart.menge, {
                emitEvent: false,
            });
        }
        if (!event.ladePosition) {
            this.berechneDosiermenge();
        }
        this.checkInputsDisabled(true);
        this.anwendungSelector?.loadAnwendungen(
            event.artikelTierart.artikel_id,
            event.artikelTierart.tierart_id,
            true,
            event.ladePosition
        );
        this.artikellistenForm.updateValueAndValidity();
    }

    setSelectedAnwendung(event: AnwendungEvent) {
        if (KieneUtils.isNoU(event?.anwendung)) {
            return;
        }
        this.listenArtikel.anwendung_id = event.anwendung.anwendung_id;
        this.listenArtikel.anwendung = event.anwendung.anwendung;
        this.listenArtikel.wartezeit = event.anwendung.wartezeit_fleisch;
        if (this.diagnoseSelector) {
            if (event.ladePosition) {
                this.diagnoseSelector.loadDiagnosen(
                    event.anwendung.artikel_id,
                    this.listenArtikel?.tierart_id,
                    this.listenArtikel?.anwendung_id,
                    false,
                    true
                );
            } else {
                this.diagnoseSelector.loadDiagnosen(
                    event.anwendung.artikel_id,
                    this.listenArtikel?.tierart_id,
                    this.listenArtikel?.anwendung_id,
                    true,
                    false
                );
            }
        }
        this.artikellistenForm.updateValueAndValidity();
    }

    setSelectedDiagnose($event: IndikationEvent) {
        if (KieneUtils.isNoU($event?.indikation)) {
            return;
        }
        this.listenArtikel.indikation_id = $event.indikation.indikation_id;
        this.listenArtikel.diagnose = $event.indikation.diagnose;
        if ($event.indikation.sonderfall) {
            const s = $event.indikation.sonderfall;
            this.listenArtikel.dosierung_txt = s.dosierung_txt;
            this.listenArtikel.dosierung_tierart = s.menge;
            this.listenArtikel.koerpergewicht_tierart = s.koerpergewicht;
            this.anwendungsdauerCtrl.setControlValue(s.behandlung_tage, {
                emitEvent: false,
            });
            this.dosierungCtrl.setControlValue(s.menge, {emitEvent: false});
            this.berechneDosiermenge();
            this.articleAutocomplete.setHintMessage('Sonderfall Medikation');
        } else {
            this.articleAutocomplete.setHintMessage(undefined);
        }
        this.artikellistenForm.updateValueAndValidity();
    }

    berechneDosiermenge() {
        if (this.istDosierungFix()) {
            return;
        }
        if (!this.listenArtikel?.verpackungsmenge) {
            return;
        }
        this.listenArtikel.dosierung = this.dosierungCtrl.value;
    }

    istDosierungFix() {
        return this.listenArtikel?.dosierung_fix === 1;
    }

    istAbgabeUndBehandlung() {
        return this.listenArtikel?.abgabeundbehandlung === 1;
    }

    istPositionBehandlung() {
        return this.listenArtikel?.behandlung === 1;
    }

    istMengenArtikel() {
        return this.listenArtikel?.mengenartikel === 1;
    }

    istAnwendungsdauerFix() {
        return this.listenArtikel?.anwendungsdauer_fix === 1;
    }

    checkDisabled() {
        this.setFocusOnInit(true);
    }

    public setFocusOnInit(focus: boolean) {
        this.focusOnInit = focus;
    }

    ladePosition(listenArtikel: ArtikellisteArtikel) {
        this.listenArtikel = listenArtikel;
        this.setzeMaterial(listenArtikel);
        this.setzeTierart(listenArtikel, true);
        this.setzeAnwendung(listenArtikel, true);
        this.setzeDiagnose(listenArtikel, true);
        this.setzeInputFelder(listenArtikel);
        this.setzeBehandlung();
        this.checkInputsDisabled(true);
        this.articleAutocomplete?.setFocus();
    }

    setzeMaterial(listenArtikel: ArtikellisteArtikel) {
        const material = new Material();
        material.artikel_id = this.listenArtikel?.artikel_id;
        material.artikel_me = this.listenArtikel?.artikel_me;
        material.bezeichnung = this.listenArtikel?.artikel;
        material.stueckliste = this.listenArtikel?.stueckliste;
        if (this.articleAutocomplete) {
            this.articleAutocomplete.setMaterial(material, false);
            this.articleAutocomplete.setHintMessage(
                listenArtikel?.sonderfall === 1
                    ? 'Sonderfall Medikation'
                    : undefined
            );
        }
    }

    setzeTierart(pos: ArtikellisteArtikel, ladePosition: boolean) {
        if (pos.tierart_id) {
            const tierart = new ArtikelTierart();
            tierart.artikel_id = pos.artikel_id;
            tierart.tierart_id = pos.tierart_id;
            tierart.tierart = pos.tierart;
            if (this.tierartSelector) {
                this.tierartSelector.setTierart(tierart, false, ladePosition);
            }
        } else {
            if (this.tierartSelector) {
                this.tierartSelector.ladeTierartenFuerMaterialUndKunde(
                    'ladePosition',
                    pos.artikel_id,
                    false,
                    ladePosition
                );
            }
        }
    }

    setzeAnwendung(pos: ArtikellisteArtikel, ladePosition: boolean) {
        if (pos.anwendung_id) {
            const anwendung = new Anwendung();
            anwendung.anwendung_id = pos.anwendung_id;
            anwendung.anwendung = pos.anwendung;
            anwendung.artikel_id = pos.artikel_id;
            anwendung.artikel = pos.artikel;
            anwendung.wartezeit_fleisch = pos.wartezeit;
            if (this.anwendungSelector) {
                this.anwendungSelector.setAnwendung(
                    anwendung,
                    pos.tierart_id,
                    false,
                    ladePosition
                );
            }
        } else {
            if (this.anwendungSelector && pos.artikel_id && pos.tierart_id) {
                this.anwendungSelector.loadAnwendungen(
                    pos.artikel_id,
                    pos.tierart_id,
                    false,
                    ladePosition
                );
            }
        }
    }

    setzeDiagnose(listenArtikel: ArtikellisteArtikel, ladePosition: boolean) {
        if (listenArtikel.indikation_id) {
            const indikation = new Indikation();
            indikation.indikation_id = listenArtikel.indikation_id;
            indikation.diagnose = listenArtikel.diagnose;
            indikation.artikel_id = listenArtikel.artikel_id;
            indikation.tierart_id = listenArtikel.tierart_id;
            if (this.diagnoseSelector) {
                this.diagnoseSelector.setDiagnose(
                    indikation,
                    this.listenArtikel.anwendung_id,
                    false,
                    ladePosition
                );
            }
        } else {
            if (
                this.diagnoseSelector &&
                listenArtikel.artikel_id &&
                listenArtikel.tierart_id
            ) {
                this.diagnoseSelector.loadDiagnosen(
                    listenArtikel.artikel_id,
                    listenArtikel.tierart_id,
                    listenArtikel.anwendung_id,
                    false,
                    ladePosition
                );
            }
        }
    }

    setzeBehandlung() {
        this.behandlungJaNeinCtrl.setControlValue(this.listenArtikel?.behandlung, {emitEvent: false});
        // if (KieneUtils.isNoU(this.listenArtikel?.behandlung)) {
        //     this.listenArtikel.behandlung = 0;
        // }
        // if (this.istAbgabeUndBehandlung()) {
        //     this.behandlungJaNeinCtrl.setControlValue(
        //         this.istPositionBehandlung(),
        //         {emitEvent: false}
        //     );
        // }
    }

    setzeInputFelder(pos: ArtikellisteArtikel) {
        this.anwendungsdauerCtrl.setControlValue(pos.anwendungsdauer, {
            emitEvent: false,
        });
        this.dosierungCtrl.setControlValue(pos.dosierung, {
            emitEvent: false,
        });
        this.checkDisabled();
    }

    checkInputsDisabled(markAsTouched: boolean) {
        this.setAttributesForValidation();
        if (this.istMengenArtikel()) {
            this.behandlungJaNeinCtrl.disableControl({emitEvent: false});
        } else {
            this.behandlungJaNeinCtrl.enableControl({emitEvent: false});
        }
        if (this.istMengenArtikel() || this.istAnwendungsdauerFix()) {
            this.anwendungsdauerCtrl.disableControl({emitEvent: false});
        } else {
            this.anwendungsdauerCtrl.enableControl({emitEvent: false});
            if (markAsTouched) {
                this.anwendungsdauerCtrl.markAsTouched();
            }
        }
        if (this.istMengenArtikel() || this.istDosierungFix()) {
            this.dosierungCtrl.disableControl({emitEvent: false});
        } else {
            this.dosierungCtrl.enableControl({emitEvent: false});
            if (markAsTouched) {
                this.dosierungCtrl.markAsTouched();
            }
        }
    }

    public felderLeeren(clearArticle: boolean) {
        this.listenArtikel = new ArtikellisteArtikel();
        this.tierartSelector.clear();
        this.anwendungSelector.clear();
        this.diagnoseSelector.clear();
        this.behandlungJaNeinCtrl.setControlValue(null, {emitEvent: false});
        this.anwendungsdauerCtrl.setControlValue(null, {emitEvent: false});
        this.dosierungCtrl.setControlValue(null, {emitEvent: false});
        this.anwendungsdauerCtrl.markAsUntouched();
        this.dosierungCtrl.markAsUntouched();
        if (clearArticle) {
            this.articleAutocomplete.clearAndFocus();
        }
    }

    editSelectedElement(event: ArtikellisteArtikel) {
        this.felderLeeren(false);
        this.ladePosition(event);
    }

    blurBehandlung() {
        if (this.istMengenArtikel() || (this.istAnwendungsdauerFix() && this.istDosierungFix())) {
            this.savePosition();
        }
    }

    savePosition() {
        this.listenArtikel.anwendungsdauer = this.anwendungsdauerCtrl.value;
        this.listenArtikel.dosierung = this.dosierungCtrl.value;
        this.listenArtikel.behandlung = this.behandlungJaNeinCtrl.value;
        console.log(this.listenArtikel);
        if (!this.listenArtikel?.aa_id) {
            this.articleList.artikel.push(this.listenArtikel);
        }
        this.save(false);
    }

    save(close: boolean) {
        this.articleList.listenname = this.listenTitelCtrl.value;
        let url = this.apiBaseUrl + this.apiUrl + 'update.php';
        this.api.post(url, this.articleList).subscribe({
            next: (res) => {
                this.articleList = res;
                if (close === true) {
                    this.dialogRef.close();
                } else {
                    this.felderLeeren(true);
                }
            },
            error: (err) => {
                this.messageService.errorMessage('Error', err);
            },
        });
    }

    deleteListenartikel(a: ArtikellisteArtikel) {
        const indexOf = this.articleList.artikel?.indexOf(a);
        this.articleList.artikel.splice(indexOf, 1);
    }

    updateAnwendungsdauer() {
        this.listenArtikel.anwendungsdauer = this.anwendungsdauerCtrl.value;
    }

    updateDosierung() {
        this.listenArtikel.dosierung = this.dosierungCtrl.value;
    }
}
