import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KieneTableService } from '../../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../../services/message.service';
import { PreisgruppeArtikel } from '../../preisgruppen';
import { environment } from '../../../../../environments/environment';
import { Material } from '../../../../warehouse/material/material';
import { Verkaufspreis } from '../../../../components/verkaufspreis-selector/verkaufspreis-selector.component';

@Component({
  selector: 'kiene-add-preisgruppe-artikel',
  templateUrl: './add-preisgruppe-artikel.component.html',
  styleUrls: ['./add-preisgruppe-artikel.component.scss']
})
export class AddPreisgruppeArtikelComponent implements OnInit {

  preisgruppeArtikel: PreisgruppeArtikel;
  artikel: Material = new Material();
  verkaufspreis: Verkaufspreis = new Verkaufspreis();

  constructor(
    public dialogRef: MatDialogRef<AddPreisgruppeArtikelComponent>,
    public api: KieneTableService,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.preisgruppeArtikel !== null && data.preisgruppeArtikel !== undefined) {
      this.preisgruppeArtikel = data.preisgruppeArtikel;
      this.artikel.artikel_id = this.preisgruppeArtikel.artikel_id;
      this.artikel.artikel_me = this.preisgruppeArtikel.artikel;
      this.verkaufspreis.verkaufspreis_id = this.preisgruppeArtikel.verkaufspreis_id;
      this.verkaufspreis.bezeichnung = this.preisgruppeArtikel.verkaufspreis;
    } else {
      this.preisgruppeArtikel = new PreisgruppeArtikel();
      this.preisgruppeArtikel.preisgruppe_id = data.preisgruppe_id;
    }
  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (this.artikel !== null && this.artikel.artikel_id !== null) {
      this.preisgruppeArtikel.artikel_id = this.artikel.artikel_id;

      if (this.verkaufspreis !== null && this.verkaufspreis.verkaufspreis_id !== null) {
        this.preisgruppeArtikel.verkaufspreis_id = this.verkaufspreis.verkaufspreis_id;
      } else {
        this.preisgruppeArtikel.verkaufspreis_id = null;
      }

      this.api.updateEntity(environment.BASE_URI + 'preisgruppe_artikel/update.php', this.preisgruppeArtikel).subscribe(
        response => {
          this.cancel();
        },
        error => {
          this.messageService.errorMessage('Preisgruppenartikel konnte nicht gespeichert werden!', error);
        }
      )
    } else {
      this.messageService.errorMessage('Keinen Artikel ausgewählt!', null);
    }
  }

  setArtikel(artikel: Material) {
    this.artikel = artikel;
  }

  setVerkaufspreis(verkaufspreis: Verkaufspreis) {
    this.verkaufspreis = verkaufspreis;
  }

}
