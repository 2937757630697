import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Material} from '../material/material';
import {environment} from '../../../environments/environment';
import {HttpParams} from '@angular/common/http';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {SupplierMaterial} from './supplier-material';
import {MatDialog} from '@angular/material/dialog';
import {AddSupplierMaterialComponent} from './add-supplier-material/add-supplier-material.component';
import {MessageService} from '../../services/message.service';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';

@Component({
    selector: 'kiene-supplier-material',
    templateUrl: './supplier-material.component.html',
    styleUrls: ['./supplier-material.component.scss']
})
export class SupplierMaterialComponent implements OnInit, OnChanges {

    @Input('material') material: Material;
    @Input('disabled') disabled = false;
    @Output('change') change = new EventEmitter<boolean>();
    @Output('currentStandardSupplierMaterial') currentStandardSupplierMaterial = new EventEmitter<SupplierMaterial>();

    supplierMaterials: SupplierMaterial[] = [];


    constructor(private api: KieneTableService,
                public dialog: MatDialog,
                private notification: MessageService) {
    }

    ngOnInit() {

    }

    ngOnChanges() {
        this.loadSupplierMaterials();
    }

    loadSupplierMaterials() {
        if (this.material && this.material.artikel_id) {
            let params = new HttpParams().set('artikel_id', this.material.artikel_id.toString());
            this.api.get(environment.BASE_URI + 'artikel_lieferant/read.php', params).subscribe(response => {
                this.supplierMaterials = response.records;
                this.emitCurrentStandardSupplierMaterial();
            }, error => {
            });

        }
    }

    emitCurrentStandardSupplierMaterial() {
        for (let sm of this.supplierMaterials) {
            if (sm.standard_lieferant === 1) {
                this.currentStandardSupplierMaterial.emit(sm);
            }
        }
    }

    openDeleteDialog(sm: SupplierMaterial) {
        let dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '33%',
            minWidth: '330px',
            data: {
                title: 'Lieferant von Artikel entfernen',
                text: 'Wollen Sie Lieferant ' + sm.lieferant + ' von Artikel ' + sm.artikel + ' entfernen?',
                textButton: 'Lieferant entfernen'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.api.deleteEntity(environment.BASE_URI + 'artikel_lieferant/delete.php', sm).subscribe(response => {
                    this.notification.infoMessage("Lieferant erfolgreich entfernt!");
                    this.loadSupplierMaterials();
                }, error => {
                    this.notification.errorMessage("Fehler beim Entfernen des Lieferanten aufgetreten!", error);
                });
            }
        });
    }


    openDetailsDialog(sm?: SupplierMaterial) {
        let create = sm ? false : true;

        let dialogRef = this.dialog.open(AddSupplierMaterialComponent, {
            width: '50%',
            minWidth: '300px',
            data: {
                create: sm ? false : true,
                material: this.material,
                supplierMaterial: sm
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {

                if (create) {
                    this.api.updateEntity(environment.BASE_URI + 'artikel_lieferant/update.php', result).subscribe(response => {
                        this.notification.infoMessage("Lieferant erfolgreich hinzugefügt!");
                        this.loadSupplierMaterials();
                        this.change.emit(true);
                    }, error => {
                        this.notification.errorMessage("Fehler beim Lieferant hinzufügen aufgetreten!", error);
                        this.loadSupplierMaterials();
                    });
                } else {
                    this.api.updateEntity(environment.BASE_URI + 'artikel_lieferant/update.php', result).subscribe(response => {
                        this.notification.infoMessage("Lieferant erfolgreich editiert!");
                        this.loadSupplierMaterials();
                        this.change.emit(true);
                    }, error => {
                        this.notification.errorMessage("Fehler beim Lieferant editieren aufgetreten!", error);
                        this.loadSupplierMaterials();
                    });
                }

            }
        });

    }

    gibRabattiertenPreis(sm: SupplierMaterial) {
        if (sm.rabatt !== null && sm.rabatt !== undefined && sm.rabatt > 0) {
            return sm.einzelpreis * (100 - sm.rabatt) / 100;
        }
        return sm.einzelpreis;
    }

}
