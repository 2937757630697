import { HttpParams } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GruppeTier } from '../../../administration/client-group/gruppe-tier';
import { AppService } from '../../../app.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { MessageService } from '../../../services/message.service';
import { Gruppe, TierauswahlEvent } from '../gruppe';

@Component({
    selector: 'kiene-gruppe-details',
    templateUrl: './gruppe-details.component.html',
    styleUrls: ['./gruppe-details.component.scss'],
})
export class GruppeDetailsComponent implements OnInit, AfterViewInit {
    itemsPerPage = 10;
    pageIndex = 0;
    offset = 0;
    suchString: string = null;
    totalRecords = 0;
    sortDirection = 'asc';
    sortColumn = 'geburtsdatum';

    isLoading = false;
    showNachladeButton = false;

    @Input('gruppe') gruppe: Gruppe = new Gruppe();
    @Input('zeigeMedikation') zeigeMedikation = false;
    @Input('auswahl') auswahl = false;
    @Input('identifikation') identifikation = false;
    @Input('identifikationText') identifikationText = '';
    @Input('selectedTiere') selectedTiere: GruppeTier[] = [];
    @Input('anzahlTiereSollwert') anzahlTiereSollwert = 0;
    @Output('selectionChange') selectionChange =
        new EventEmitter<TierauswahlEvent>();

    ohrmarkeCtrl = new UntypedFormControl();

    einstalldatumSelectCtrl = new UntypedFormControl();
    einstalldaten: any[] = [];
    einstalldatum: Date;

    tiere: GruppeTier[] = [];
    selected: GruppeTier[] = [];
    numberSelected = 0;

    selectedAll = false;

    constructor(
        private api: KieneTableService,
        private messageService: MessageService,
        private appService: AppService,
        private localStorageService: LocalStorageService
    ) {
    }

    ngOnInit(): void {
        this.itemsPerPage =
            this.localStorageService.getCurrentUser().elemente_pro_seite;
        this.loadEinstalldaten();

        if (this.selectedTiere.length > 0) {
            this.tiere = this.selectedTiere;
            this.itemsPerPage = this.tiere.length;
        } else {
            this.loadTiere();
        }

        this.einstalldatumSelectCtrl.valueChanges.subscribe({
            next: (einstalldatum) => {
                this.einstalldatum = einstalldatum;
                this.loadTiere();
            }
        });
    }

    ngAfterViewInit() {
        if (this.selectedTiere && this.selectedTiere.length > 0) {
            this.selected = this.selectedTiere;
            this.numberSelected = this.selected?.length;
            if (this.selected?.length === this.anzahlTiereSollwert) {
                this.selectedAll = true;
            }
        }

        this.ohrmarkeCtrl.valueChanges
            .pipe(
                debounceTime(1000),
                distinctUntilChanged(),
                tap((value) => {
                    // console.debug('Input: ' + this.searchInput.nativeElement.value);
                    this.pageIndex = 0;
                    this.suchString = value;
                    this.loadTiere();
                })
            )
            .subscribe();
    }

    // tierHinzufuegen(){
    //   const ohrmarke = this.ohrmarkeCtrl.value;
    //   const geburtsdatum = <Date>this.geburtsdatumCtrl.value;

    //   const obj = {
    //     gruppe_id: this.gruppe.gruppe_id,
    //     ohrmarke: ohrmarke,
    //     geburtsdatum: geburtsdatum.toISOString()
    //   };

    //   this.api.create(environment.BASE_URI + 'gruppe_tier/', obj).subscribe(response => {
    //     this.geburtsdatumCtrl.setValue(null);
    //     this.ohrmarkeCtrl.setValue(null);
    //     // this.loadTiere();
    //     this.messageService.infoMessage('Das Tier wurde erfolgreich der Gruppe hinzugefügt!');
    //   }, error => {
    //     this.messageService.errorMessage('Beim Hinzufügen zur Gruppe ist ein Fehler aufgetreten!', error);
    //   });
    // }

    sortTiere(sort: Sort) {
        this.sortDirection = sort.direction;
        this.sortColumn = sort.active;

        if (!this.selectedAll) {
            this.loadTiere();
        } else {
            this.ladeAlleTiere();
        }
    }

    loadEinstalldaten() {
        const params = new HttpParams().set(
            'gruppe_id',
            this.gruppe?.gruppe_id?.toString()
        );
        this.api.get(environment.BASE_URI + 'gruppe_tier/read_gruppe_einstalldatum.php', params).subscribe({
            next: (response) => {
                this.einstalldaten = response.records;
            }
        });
    }

    loadTiere() {
        this.isLoading = true;
        let params = new HttpParams().set(
            'gruppe_id',
            this.gruppe?.gruppe_id?.toString()
        );
        params = params.set('orderby', this.sortColumn + ' ' + this.sortDirection);
        params = params.set('limit', this.itemsPerPage.toString());
        params = params.set(
            'offset',
            (this.pageIndex * this.itemsPerPage).toString()
        );
        params = params.set('aktuelle', '1');
        if (this.suchString) {
            params = params.set('search', this.suchString);
        }

        if (this.einstalldatum) {
            const d = new Date(this.einstalldatum);
            const dateString = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
            params = params.set('einstalldatum_notnull', dateString);
        }

        this.api.get(environment.BASE_URI + 'gruppe_tier/read.php', params).subscribe(
            (response) => {
                this.totalRecords = response.count;
                this.tiere = response.records;
                this.isLoading = false;
            },
            (error) => {
                this.appService.setDataLoading(false);
                this.messageService.errorMessage(
                    'Die einzelnen Tiere für die Gruppe ' +
                    this.gruppe?.bezeichnung +
                    ' konnten nicht geladen werden!',
                    error
                );
                this.isLoading = false;
            }
        );
    }

    ladeAlleTiere() {
        this.isLoading = true;
        let params = new HttpParams().set(
            'gruppe_id',
            this.gruppe?.gruppe_id?.toString()
        );
        params = params.set('orderby', this.sortColumn + ' ' + this.sortDirection);
        params = params.set('offset', '0');
        params = params.set('limit', this.anzahlTiereSollwert.toString());
        params = params.set('aktuelle', '1');

        if (this.einstalldatum) {
            const d = new Date(this.einstalldatum);
            const dateString = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
            params = params.set('einstalldatum_notnull', dateString);
        }

        this.api.get(environment.BASE_URI + 'gruppe_tier/read.php', params).subscribe(
            (response) => {
                this.totalRecords = response.count;
                this.tiere = response.records;
                this.selected = this.tiere?.slice();
                this.numberSelected = this.selected?.length;
                this.isLoading = false;

                if (this.numberSelected >= this.anzahlTiereSollwert) {
                    this.selectedAll = true;
                } else {
                    this.selectedAll = false;
                }

                const te = new TierauswahlEvent();
                te.tiere = this.selected;
                te.alleSelektiert = this.selectedAll;

                if (this.totalRecords > this.selected?.length) {
                    this.showNachladeButton = true;
                } else {
                    this.showNachladeButton = false;
                }

                this.selectionChange.emit(te);
            },
            (error) => {
                this.appService.setDataLoading(false);
                this.messageService.errorMessage(
                    'Die einzelnen Tiere für die Gruppe ' +
                    this.gruppe?.bezeichnung +
                    ' konnten nicht geladen werden!',
                    error
                );
                this.isLoading = false;
                this.selectedAll = false;
            }
        );
    }

    tiereNachladen() {
        this.isLoading = true;
        let params = new HttpParams().set(
            'gruppe_id',
            this.gruppe?.gruppe_id?.toString()
        );
        params = params.set('orderby', this.sortColumn + ' ' + this.sortDirection);
        params = params.set('offset', this.numberSelected.toString());
        params = params.set('limit', this.totalRecords.toString());
        params = params.set('aktuelle', '1');

        if (this.einstalldatum) {
            const d = new Date(this.einstalldatum);
            const dateString = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
            params = params.set('einstalldatum_notnull', dateString);
        }

        this.api.get(environment.BASE_URI + 'gruppe_tier/read.php', params).subscribe(
            (response) => {
                this.totalRecords = response.count;
                this.tiere.push.apply(this.tiere, response.records);
                this.isLoading = false;
                this.selectedAll = true;

                this.showNachladeButton = false;
            },
            (error) => {
                this.appService.setDataLoading(false);
                this.messageService.errorMessage(
                    'Die einzelnen Tiere für die Gruppe ' +
                    this.gruppe?.bezeichnung +
                    ' konnten nicht geladen werden!',
                    error
                );
                this.isLoading = false;
                this.selectedAll = false;
            }
        );
    }

    pageChanged(pe: PageEvent) {
        this.itemsPerPage = pe.pageSize;
        this.pageIndex = pe.pageIndex;
        this.loadTiere();
    }

    isChecked(tier: GruppeTier): boolean {
        if (this.selected) {
            for (const s of this.selected) {
                if (s.ohrmarke === tier.ohrmarke) {
                    return true;
                }
            }
        }
        return false;
    }

    selectTier(event: MatCheckboxChange, tier: GruppeTier) {
        if (event.checked) {
            this.selected?.push(tier);
        } else {
            const index = this.selected?.findIndex((t) => t.ohrmarke === tier.ohrmarke);
            this.selected?.splice(index, 1);
        }

        if (this.selected?.length >= this.anzahlTiereSollwert) {
            this.selectedAll = true;
        } else {
            this.selectedAll = false;
        }

        this.numberSelected = this.selected?.length;
        const te = new TierauswahlEvent();
        te.tiere = this.selected;
        te.alleSelektiert = this.selectedAll;
        this.selectionChange.emit(te);
    }

    selectAll() {
        if (!this.selectedAll) {
            this.ladeAlleTiere();
        } else {
            this.selected = [];
            this.numberSelected = 0;
            this.selectedAll = false;
            this.loadTiere();
            const te = new TierauswahlEvent();
            te.tiere = this.selected;
            te.alleSelektiert = this.selectedAll;
            this.selectionChange.emit(te);
        }
    }

    abgang(gt: GruppeTier) {

    }
}
