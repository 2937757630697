import { Component, Inject, OnInit } from '@angular/core';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../services/message.service';
import { ActivatedRoute } from '@angular/router';
import { Bestandsbesuchprotokoll } from '../bestandsbesuchsprotokoll';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { TextbausteinConfig } from '../../../directives/text-baustein.directive';
import { Vet } from '../../../administration/vet/vet';
import { HttpParams } from '@angular/common/http';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { KieneSession } from '../../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import { Client } from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { Textbaustein } from '../../../../../src/app/administration/text-baustein/text-baustein';

@Component({
    selector: 'kiene-bestandsbesuchprotokoll-details',
    templateUrl: './bestandsbesuchprotokoll-details.component.html',
    styleUrls: ['./bestandsbesuchprotokoll-details.component.scss'],
})
export class BestandsbesuchprotokollDetailsComponent implements OnInit {

    // signature = new Signature();

    bbpId: string;
    bbp: Bestandsbesuchprotokoll = new Bestandsbesuchprotokoll();
    vet: Vet;

    datumCtrl = new UntypedFormControl();

    sauenCtrl = new UntypedFormControl();
    saugferkelCtrl = new UntypedFormControl();
    absatzferkelCtrl = new UntypedFormControl();
    mastschweineCtrl = new UntypedFormControl();
    kueheCtrl = new UntypedFormControl();
    kaelberCtrl = new UntypedFormControl();
    fresserCtrl = new UntypedFormControl();
    bullenCtrl = new UntypedFormControl();
    mastrinderCtrl = new UntypedFormControl();
    _9 = new UntypedFormControl();
    _10 = new UntypedFormControl();
    _11 = new UntypedFormControl();
    _12 = new UntypedFormControl();
    _13 = new UntypedFormControl();
    _14 = new UntypedFormControl();
    _15 = new UntypedFormControl();
    _16 = new UntypedFormControl();
    _17 = new UntypedFormControl();
    _18 = new UntypedFormControl();
    _19 = new UntypedFormControl();
    _20 = new UntypedFormControl();
    _21 = new UntypedFormControl();
    _22 = new UntypedFormControl();
    _23 = new UntypedFormControl();
    _23b = new UntypedFormControl();
    _23c = new UntypedFormControl();
    _24 = new UntypedFormControl();
    _25 = new UntypedFormControl();
    _26 = new UntypedFormControl();
    bestandCtrl = new UntypedFormControl();

    kieneSession: KieneSession;
    tierarztAutocompleteDisabled = false;
    abrechnenCtrl = new FormControl(true);
    focus_24 = false;

    constructor(
        private localStorageService: LocalStorageService,
        private api: KieneTableService,
        private messageService: MessageService,
        private activatedRoute: ActivatedRoute,
        private pdf: PdfViewerDesktopService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        this.bbpId = this.activatedRoute.snapshot.paramMap.get('id');
    }

    ngOnInit() {
        this.kieneSession = this.localStorageService.getCurrentUser();

        this.datumCtrl.setValue(new Date());

        if (this.bbpId !== 'neu') {
            this.loadBbp();
        } else {
            if (!this.benutzerIstKeinTierarzt()) {
                this.vet = new Vet();
                this.vet.tierarzt_id =
                    this.localStorageService.getCurrentUser().tierarzt_id;
                this.vet.vorname =
                    this.localStorageService.getCurrentUser().vorname;
                this.vet.nachname =
                    this.localStorageService.getCurrentUser().nachname;
                this.tierarztAutocompleteDisabled = true;
            }
        }

        if (this.localStorageService.getCurrentClient() !== null) {
            if (!this.bbp.kunde_id) {
                const c = this.localStorageService.getCurrentClient();
                this.setzeKundeInitial(c);
            }
        }

        this.localStorageService.watchCurrentClient().subscribe((client) => {
            if (client && !this.bbp.kunde_id) {
                this.setzeKundeInitial(client);
            }
        });
    }

    setzeKundeInitial(client: Client) {
        this.bbp.kunde_id = client.kunde_id;
        this.bbp.kunde = client.bezeichnung_final;
        this.bbp.kunde_ort = client.ort_final;
        this.bbp.kunde_plz = client.plz_final;
        this.bbp.kunde_strasse = client.strasse_final;
        this.bbp.kunde_telefon_1 = client.telefon_1;

        let params = new HttpParams();
        params = params.append('limit', '1');
        params = params.append('orderby', 'datum desc');
        params = params.append('kunde_id', this.bbp.kunde_id);
        this.api.get(this.apiBaseUrl + 'bestandsbesuchsprotokoll/read.php', params).subscribe(
            {
                next: (response) => {
                    if (response?.count > 0) {
                        const b = <Bestandsbesuchprotokoll>response.records[0];
                        this.sauenCtrl.setValue(b.sauen);
                        this.saugferkelCtrl.setValue(b.saugferkel);
                        this.absatzferkelCtrl.setValue(b.absatzferkel);
                        this.mastschweineCtrl.setValue(b.mastschweine);
                        this.kueheCtrl.setValue(b.kuehe);
                        this.kaelberCtrl.setValue(b.kaelber);
                        this.fresserCtrl.setValue(b.fresser);
                        this.bullenCtrl.setValue(b.bullen);
                        this.mastrinderCtrl.setValue(b.mastrinder);
                        this.bestandCtrl.setValue(b.bestand);
                    }
                },
                error: err => {
                    this.messageService.errorMessage('Fehler beim Laden des letzten Protokolls!', err);
                }
            }
        );
    }

    benutzerIstKeinTierarzt(): boolean {
        return !this.localStorageService.currentUserIsVet();
    }

    setTierarzt(tierarzt: Vet) {
        this.vet = tierarzt;
        this.bbp.tierarzt_id = tierarzt.tierarzt_id;
        this.bbp.tierarzt_vorname = tierarzt.vorname;
        this.bbp.tierarzt_nachname = tierarzt.nachname;
    }

    loadBbp() {
        this.api
            .getOne(
                this.apiBaseUrl + 'bestandsbesuchsprotokoll/read_one.php',
                'bbp_id',
                this.bbpId
            )
            .subscribe((response) => {
                this.bbp = response;

                this.initForm();
            });
    }

    initForm() {

        if (this.bbp.abrechnen === 0) {
            this.abrechnenCtrl.setValue(false);
        } else if (this.bbp.abrechnen === 1) {
            this.abrechnenCtrl.setValue(true);
        }
        if (this.bbp.abgabebeleg_id) {
            this.abrechnenCtrl.disable();
        }

        this.vet = new Vet();
        this.vet.tierarzt_id = this.bbp.tierarzt_id;
        this.vet.vorname = this.bbp.tierarzt_vorname;
        this.vet.nachname = this.bbp.tierarzt_nachname;

        this.datumCtrl.setValue(this.bbp.datum);

        this.sauenCtrl.setValue(this.bbp.sauen);
        this.saugferkelCtrl.setValue(this.bbp.saugferkel);
        this.absatzferkelCtrl.setValue(this.bbp.absatzferkel);
        this.mastschweineCtrl.setValue(this.bbp.mastschweine);
        this.kueheCtrl.setValue(this.bbp.kuehe);
        this.kaelberCtrl.setValue(this.bbp.kaelber);
        this.fresserCtrl.setValue(this.bbp.fresser);
        this.bullenCtrl.setValue(this.bbp.bullen);
        this.mastrinderCtrl.setValue(this.bbp.mastrinder);
        this.bestandCtrl.setValue(this.bbp.bestand);

        this._9.setValue(this.bbp.klinisch_ohne === 1 ? true : false);
        this._10.setValue(this.bbp.klinisch_erkrankungen === 1 ? true : false);
        this._11.setValue(this.bbp.atmungsorgane === 1 ? true : false);
        this._12.setValue(this.bbp.verdauungsorgane === 1 ? true : false);
        this._13.setValue(this.bbp.nervensystem === 1 ? true : false);
        this._14.setValue(this.bbp.haut === 1 ? true : false);
        this._15.setValue(this.bbp.harnwege === 1 ? true : false);
        this._26.setValue(this.bbp.bewegungsapparates > 0 ? true : false);

        this._16.setValue(this.bbp.sonstiges);

        this._17.setValue(this.bbp.massnahmen === 1 ? true : false);
        this._18.setValue(this.bbp.kotproben === 1 ? true : false);
        this._19.setValue(this.bbp.blutproben === 1 ? true : false);
        this._20.setValue(this.bbp.tupferproben === 1 ? true : false);
        this._21.setValue(this.bbp.sektionen === 1 ? true : false);
        this._22.setValue(this.bbp.mikrobiologie === 1 ? true : false);
        this._23.setValue(this.bbp.medikamentierung === 1 ? true : false);
        this._23b.setValue(this.bbp.mnp_erstellt === 1 ? true : false);
        this._23c.setValue(this.bbp.pruefung_th === 1 ? true : false);

        this._24.setValue(this.bbp.verdachtsdiagnose);

        this._25.setValue(this.bbp.sonstiges_massnahmen);

        if (this.bbp.status_id === 72) {
            this.datumCtrl.disable();
            this.sauenCtrl.disable();
            this.saugferkelCtrl.disable();
            this.absatzferkelCtrl.disable();
            this.mastschweineCtrl.disable();
            this.kueheCtrl.disable();
            this.kaelberCtrl.disable();
            this.fresserCtrl.disable();
            this.bullenCtrl.disable();
            this.mastrinderCtrl.disable();
            this.bestandCtrl.disable();
            this._9.disable();
            this._10.disable();
            this._11.disable();
            this._12.disable();
            this._13.disable();
            this._14.disable();
            this._15.disable();
            this._26.disable();
            this._16.disable();
            this._17.disable();
            this._18.disable();
            this._19.disable();
            this._20.disable();
            this._21.disable();
            this._22.disable();
            this._23.disable();
            this._23b.disable();
            this._23c.disable();
            this._24.disable();
            this._25.disable();
        } else {
            this.datumCtrl.enable();
            this.sauenCtrl.enable();
            this.saugferkelCtrl.enable();
            this.absatzferkelCtrl.enable();
            this.mastschweineCtrl.enable();
            this.kueheCtrl.enable();
            this.kaelberCtrl.enable();
            this.fresserCtrl.enable();
            this.bullenCtrl.enable();
            this.mastrinderCtrl.enable();
            this.bestandCtrl.enable();
            this._9.enable();
            this._10.enable();
            this._11.enable();
            this._12.enable();
            this._13.enable();
            this._14.enable();
            this._15.enable();
            this._26.enable();
            this._16.enable();
            this._17.enable();
            this._18.enable();
            this._19.enable();
            this._20.enable();
            this._21.enable();
            this._22.enable();
            this._23.enable();
            this._23b.enable();
            this._23c.enable();
            this._24.enable();
            this._25.enable();
        }

        if (!this.benutzerIstKeinTierarzt()) {
            this.tierarztAutocompleteDisabled = true;
        }
    }

    save(statusId?: number) {
        if (!this.bbp.kunde_id) {
            this.messageService.alertMessage(
                'Bitte erst einen Kunden auswählen!'
            );
        }

        if (!statusId) {
            statusId = this.bbp.status_id;
        }

        this.bbp.datum = this.datumCtrl.value;

        this.bbp.sauen = this.sauenCtrl.value;
        this.bbp.saugferkel = this.saugferkelCtrl.value;
        this.bbp.absatzferkel = this.absatzferkelCtrl.value;
        this.bbp.mastschweine = this.mastschweineCtrl.value;
        this.bbp.kuehe = this.kueheCtrl.value;
        this.bbp.kaelber = this.kaelberCtrl.value;
        this.bbp.fresser = this.fresserCtrl.value;
        this.bbp.bullen = this.bullenCtrl.value;
        this.bbp.mastrinder = this.mastrinderCtrl.value;
        this.bbp.bestand = this.bestandCtrl.value;

        this.bbp.klinisch_ohne = this._9.value === true ? 1 : 0;
        this.bbp.klinisch_erkrankungen = this._10.value === true ? 1 : 0;
        this.bbp.atmungsorgane = this._11.value === true ? 1 : 0;
        this.bbp.verdauungsorgane = this._12.value === true ? 1 : 0;
        this.bbp.nervensystem = this._13.value === true ? 1 : 0;
        this.bbp.haut = this._14.value === true ? 1 : 0;
        this.bbp.harnwege = this._15.value === true ? 1 : 0;
        this.bbp.bewegungsapparates = this._26.value === true ? 1 : 0;

        this.bbp.sonstiges = this._16.value;

        this.bbp.massnahmen = this._17.value === true ? 1 : 0;
        this.bbp.kotproben = this._18.value === true ? 1 : 0;
        this.bbp.blutproben = this._19.value === true ? 1 : 0;
        this.bbp.tupferproben = this._20.value === true ? 1 : 0;
        this.bbp.sektionen = this._21.value === true ? 1 : 0;
        this.bbp.mikrobiologie = this._22.value === true ? 1 : 0;
        this.bbp.medikamentierung = this._23.value === true ? 1 : 0;
        this.bbp.mnp_erstellt = this._23b.value === true ? 1 : 0;
        this.bbp.pruefung_th = this._23c.value === true ? 1 : 0;

        this.bbp.sonstiges_massnahmen = this._25.value;

        this.bbp.verdachtsdiagnose = this._24.value;

        this.bbp.status_id = statusId;

        this.bbp.tierarzt_id = this.vet.tierarzt_id;
        // this.bbp.kuid = this.signature.kuid;

        this.bbp.abrechnen = this.abrechnenCtrl.value ? 1 : 0;

        this.api
            .updateEntity(
                this.apiBaseUrl + 'bestandsbesuchsprotokoll/update.php',
                this.bbp
            )
            .subscribe(
                (response) => {
                    this.bbp = response;

                    const input = new PdfViewerDialogInput(
                        'bestandsbesuchsprotokoll/read_or_mail_pdf.php',
                        new HttpParams().set(
                            'bbp_id',
                            this.bbp?.bbp_id?.toString()
                        ),
                        'Bestandsbesuchsprotokoll ' +
                        this.bbp?.bbp_id?.toString(),
                        {
                            email: {
                                sende_mail: true,
                                kunde_id: this.bbp?.kunde_id,
                            },
                            targetRoute: ['/belege', 'bestandsbesuch']
                        }
                    );
                    this.pdf.openPdfViewer(input);
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Das Bestandsbesuchsprotokoll konnte nicht gespeichert werden!',
                        error
                    );
                }
            );
    }

    getTextBausteinConfig(): TextbausteinConfig {
        const t = new TextbausteinConfig();
        t.typ = 'bbp';
        t.count = 1;
        t.titel1 = 'Kommentare';
        t.text1 = this._24.value;
        return t;
    }

    setCurrentUserAsVet() {
        if (this.kieneSession?.tierarzt_id) {
            this.vet = new Vet();
            this.vet.tierarzt_id = this.localStorageService.getCurrentUser()?.tierarzt_id;
            this.vet.vorname = this.localStorageService.getCurrentUser()?.vorname;
            this.vet.nachname = this.localStorageService.getCurrentUser()?.nachname;
            this.bbp.tierarzt_id = this.localStorageService.getCurrentUser()?.tierarzt_id;
            this.api
                .updateEntity(
                    this.apiBaseUrl + 'bestandsbesuchsprotokoll/update.php',
                    this.bbp
                )
                .subscribe({
                    next: (response) => {
                        this.messageService.infoMessage('Bestandsbesuchsprotokoll wurde erfolgreich gespeichert!');
                        this.bbp = response;
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Das Bestandsbesuchsprotokoll konnte nicht gespeichert werden!',
                            err
                        );
                    }
                }
                );
        }
    }

    setVerdachtsdiagnose(textbaustein: Textbaustein) {
        if (textbaustein) {
            this._24.setValue(textbaustein.textbaustein1);
        }
    }

}
