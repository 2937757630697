export class CompanyGroup {
    unternehmensgruppe_id: number;
    bezeichnung: string;
    kommentar: string;
    strasse: string;
    plz: string;
    ort: string;
    email: string;
    verkaufspreis_id: number;
    verkaufspreis: string;
    rabatt: number;
    erstellt: Date;
    erstellt_von: number;
    editiert: Date;
    editiert_von: number;
    ortsteil: string;
}

export class CompanyGroupAdmin {
    unternehmensgruppe_id: number;
    verkaufspreis_id: number;
    verkaufspreis: string;
    rabatt: number;
}

export class CompanyGroupArticle {
    ua_id: number;
    unternehmensgruppe_id: number;
    unternehmensgruppe: string;
    artikel_id: number;
    artikel: string;
    artikel_me: string;
    einzelpreis: number;
}

export interface CompanyGroupApi {
    records: CompanyGroup[];
    count: number;
}
