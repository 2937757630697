import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { KieneTableService } from "../../components/kiene-table/kiene-table.service";
import {
    DateTableColumn,
    KieneBooleanTableColumn,
    TableColumn,
    TextTableColumn
} from "../../components/kiene-table/table-column";
import { TableDescriptor } from "../../components/kiene-table/table-descriptor";
import { TableOption, TableOptionEvent } from "../../components/kiene-table/table-option";
import { ConfirmDeleteDialogComponent } from "../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component";
import { MessageService } from "../../services/message.service";
import { Textbaustein, TextbausteinResult } from "./text-baustein";
import { TextbausteinGeneralDialogComponent } from "./textbaustein-general-dialog/textbaustein-general-dialog.component";
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';

@Component({
    selector: 'kiene-text-baustein',
    templateUrl: './text-baustein.component.html',
    styleUrls: ['./text-baustein.component.scss']
})
export class TextBausteinComponent implements OnInit {
    public isLoading: any;
    tableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private api: KieneBackendApiService,
        private tableService: KieneTableService,
        private messageService: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Textbausteine';
        this.tableDescriptor.uniqueIdentifier = 'Textbausteine';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'textbaustein/';
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.permissionCreate = 3703;

        const columns: TableColumn[] = [];

        columns.push(new TextTableColumn('textbaustein_id', 'ID'));
        columns.push(new TextTableColumn('titel', 'Titel'));
        columns.push(new TextTableColumn('textbaustein1', 'Textbaustein 1'));
        columns.push(new TextTableColumn('textbaustein2', 'Textbaustein 2'));
        columns.push(new TextTableColumn('textbaustein3', 'Textbaustein 3'));
        columns.push(new TextTableColumn('typ', 'Typ'));
        columns.push(new KieneBooleanTableColumn('global', 'Global'));
        columns.push(new TextTableColumn('ersteller', 'erstellt von'));
        columns.push(new DateTableColumn('erstellt', 'erstellt am'));
        columns.push(new DateTableColumn('editiert', 'editiert'));


        this.tableDescriptor.columns = columns;

        this.tableDescriptor.initialSortColumn = 'editiert';
        this.tableDescriptor.initialSortDirection = 'desc';
        this.tableDescriptor.defaultPageSize = 10;

        this.tableDescriptor.showDetails = false;

        this.tableDescriptor.options.push(new TableOption('edit', 'Bearbeiten', 'edit', 3703));
        this.tableDescriptor.options.push(new TableOption('delete', 'Löschen', 'delete', 3704));

    }

    public optionClicked(event: TableOptionEvent): void {
        if (event.name === 'add') {
            this.openDetailsDialog();
        }
        if (event.name === 'edit') {
            this.openDetailsDialog(event.value);
        }
        if (event.name === 'delete') {
            this.openDeleteTextbaustein(event.value);
        }
    }

    private openDetailsDialog(textbaustein?: Textbaustein): void {
        if (!textbaustein) {
            textbaustein = new Textbaustein();
        }
        this.dialog.open(TextbausteinGeneralDialogComponent, {
            data: textbaustein,
            width: '30%'
        }).afterClosed().subscribe({
            next: (result: TextbausteinResult) => {
                if (result.confirm) {
                    this.tableService.reload();
                }
            },
            error: err => {
                this.messageService.errorMessage('fehlgeschlagen!', err);
            }
        })
    }

    private openDeleteTextbaustein(textbaustein: Textbaustein): void {
        this.dialog.open(ConfirmDeleteDialogComponent, {}).afterClosed().subscribe({
            next: confirm => {
                if (confirm) {
                    this.api.deleteEntity(this.tableDescriptor.apiUrl + 'delete.php', textbaustein).subscribe({
                        next: () => {
                            this.tableService.reload();
                        },
                        error: err => {
                            this.messageService.errorMessage('Laden fehlgeschlagen!', err);
                        }
                    });
                }
            },
            error: err => {
                this.messageService.errorMessage('Löschen fehlgeschlagen!', err);
            }
        })
    }
}
