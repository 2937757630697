import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { Gruppe } from '../../classes/gruppe';
import { GruppeTier } from '../../../gruppe-tier/classes/gruppe-tier';
import { KieneBackendApiService } from '../../../../services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../../services/message-service/message.service';
import { HttpParams } from '@angular/common/http';
import { EinstallenService } from '../einstallen.service';
import { Haendler } from '../../../stammdaten/haendler/haendler';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
    GewichtEingebenNumberpadDialogComponent
} from '../einstallen-tiere-hinzufuegen/gewicht-eingeben-numberpad-dialog/gewicht-eingeben-numberpad-dialog.component';
import { API_BASE_URL_SERVICE } from '../../../../kiene-core.config';
import { GruppenService } from '../../gruppen.service';
import { SoundType } from '../../../../classes/sound-type';
import { Subscription } from 'rxjs';
import { ScanErgebnis } from '../../classes/scan-ergebnis';
import { AppService } from '../../../../services/app/app.service';

@Component({
    selector: 'kiene-einstallen-number-pad',
    templateUrl: './einstallen-number-pad.component.html',
    styleUrls: ['./einstallen-number-pad.component.scss'],
})
export class EinstallenNumberPadComponent implements OnInit {
    gruppe?: Gruppe;

    numbers = '';
    fontSize = '3em';
    checkBackgroundColor = 'lightgray';
    checkColor = 'gray';
    checkBorderColor = 'transparent';
    ohrmarke = '276031234567890';
    ohrmarkeGefunden = false;
    currentHaendler: Haendler;

    ohrmarken: GruppeTier[];

    keinErgebnis: boolean;
    selectedTier: GruppeTier;
    abteilCtrl: UntypedFormControl = new UntypedFormControl(null);
    currentScanErgebnis: ScanErgebnis;
    isLoading = false;
    ladeOhrmarkeSub: Subscription;

    mitGewichtToggleCtrl = new UntypedFormControl();

    @Input('isVisible') isVisible = false;

    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private einstallService: EinstallenService,
        private messageService: MessageService,
        private dialog: MatDialog,
        private gruppenService: GruppenService,
        private appService: AppService
    ) {
    }

    ngOnInit(): void {
        this.einstallService.watchCurrentGruppe().subscribe({
            next: (val) => (this.gruppe = val),
        });
        this.einstallService.watchCurrentHaendler().subscribe({
            next: (val) => (this.currentHaendler = val),
        });

        this.einstallService.watchCurrentAbteil().subscribe({
            next: (val) => {
                this.abteilCtrl.setValue(val, { emitEvent: false });
            },
        });
        this.abteilCtrl.valueChanges.subscribe((abteil) => {
            this.einstallService.setCurrentAbteil(abteil);
        });

        this.einstallService
            .watchScanErgebnisSubject()
            .subscribe((scanErgebnis) => {
                this.currentScanErgebnis = scanErgebnis;
            });
    }

    @HostListener('window:keyup', ['$event'])
    catchKeyboardEvent(event: KeyboardEvent) {
        if (!this.isVisible) {
            return;
        }
        console.log(event.code);
        if ((event.code.startsWith('Numpad') || event.code.startsWith('Digit')) && !this.isKeyEventFromInput(event)) {
            const splitted = event.code.split(/Numpad|Digit/);
            if (splitted && splitted[1]) {
                this.addNumber(Number.parseInt(splitted[1], 10));
            }
        } else if (event.code === 'Backspace' && !this.isKeyEventFromInput(event)) {
            this.backspace();
        }
    }

    addNumber(number: number) {
        if (this.numbers.length < 10) {
            this.calcFontSize();
            this.numbers += `${number}`;
            this.ladeOhrmarke();
            this.calcFontSize();
        }
    }

    ladeOhrmarke() {
        this.ohrmarken = [];
        this.checkOhrmarken();
        if (this.numbers.length >= 5) {
            this.isLoading = true;
            let params = new HttpParams();
            params = params.set('gruppe_id', this.gruppe.gruppe_id.toString());
            params = params.set('ohrmarke5', this.numbers);

            this.ladeOhrmarkeSub?.unsubscribe();
            this.ladeOhrmarkeSub = this.api
                .getAll(
                    this.apiBaseUrl + 'gruppe_tier/read_kundenportal.php',
                    params
                )
                .subscribe({
                    next: (response) => {
                        this.isLoading = false;
                        this.ohrmarken = response.records;
                        this.checkOhrmarken();
                    },
                    error: (error) => {
                        this.isLoading = false;
                        this.messageService.errorMessage(
                            'Fehler bei der Ohrmarkensuche!',
                            error
                        );
                    },
                });
        }
    }

    backspace() {
        if (this.numbers.length >= 1) {
            this.numbers = this.numbers.substring(0, this.numbers.length - 1);
            this.ladeOhrmarke();
        }

        this.calcFontSize();
    }

    checkOhrmarken() {
        if (this.ohrmarken.length === 1) {
            this.selectedTier = this.ohrmarken[0];
            this.ohrmarke = this.ohrmarken[0].ohrmarke_notnull;
            this.ohrmarkeGefunden = true;
            this.keinErgebnis = false;
            this.checkBackgroundColor = 'green';
            this.checkColor = 'white';
            this.checkBorderColor = 'green';
        } else if (this.ohrmarken.length === 0) {
            this.selectedTier = undefined;
            this.ohrmarke = this.numbers.toString();
            this.ohrmarkeGefunden = false;
            this.keinErgebnis = true;
            this.checkBackgroundColor =
                this.numbers.length < 10 ? 'lightgray' : 'green';
            this.checkColor = this.numbers.length < 10 ? 'gray' : 'white';
            this.checkBorderColor = 'transparent';
        } else {
            this.selectedTier = undefined;
            this.ohrmarke = 'nicht vorhanden';
            this.ohrmarkeGefunden = false;
            this.keinErgebnis = true;
            this.checkBackgroundColor = 'lightgray';
            this.checkColor = 'gray';
            this.checkBorderColor = 'transparent';
        }
    }

    clearAll() {
        this.numbers = '';
        this.selectedTier = null;
        this.checkBackgroundColor = 'lightgray';
        this.checkColor = 'gray';
        this.checkBorderColor = 'transparent';
        this.ohrmarkeGefunden = false;
        this.keinErgebnis = true;
        this.ohrmarke = '';
        this.ohrmarken = [];
    }

    check() {
        let tier: GruppeTier;

        if (this.selectedTier) {
            tier = this.selectedTier;
        } else {
            tier = new GruppeTier();
            tier.ohrmarke = this.numbers.toString();
        }

        tier.gruppe_id = this.gruppe.gruppe_id;
        tier.abteil = this.abteilCtrl.value;

        if (this.mitGewichtToggleCtrl.value) {
            const dialogRef = this.dialog.open(
                GewichtEingebenNumberpadDialogComponent
            );
            dialogRef.afterClosed().subscribe({
                next: (result) => {
                    if (result) {
                        tier.einstallgewicht = result;
                    }
                    this.postOhrmarke(tier);
                },
            });
        } else {
            this.postOhrmarke(tier);
        }
    }

    postOhrmarke(tier) {
        this.appService.setBlockOnLoading(true);
        this.api
            .post(
                this.apiBaseUrl + 'gruppe_tier/create_kundenportal.php',
                tier,
                undefined
            )
            .subscribe({
                next: (response: ScanErgebnis) => {
                    this.gruppenService.playScanSound(SoundType.OK);
                    this.einstallService.setScanErgebnisSubject(response);
                    // this.einstallService.ladeEingestallteTiere();

                    this.clearAll();
                    this.appService.setBlockOnLoading(false);
                },
                error: (err) => {
                    this.gruppenService.playScanSound(SoundType.ERROR);
                    this.messageService.errorMessage('Fehler', err);
                    this.appService.setBlockOnLoading(false);
                },
            });
    }

    calcFontSize() {
        if (this.numbers.length > 12) {
            this.fontSize = '1.2em';
        } else if (this.numbers.length > 8) {
            this.fontSize = '2em';
        } else {
            this.fontSize = '3em';
        }
    }

    getFirstTenDigits(ohrmarke: string) {
        return ohrmarke.substring(0, ohrmarke.length - 5);
    }

    getLastFiveDigits(ohrmarke: string) {
        return ohrmarke.substring(ohrmarke.length - 5, ohrmarke.length);
    }

    private isKeyEventFromInput(event: KeyboardEvent): boolean {
        if (event.target instanceof Node) {
            switch (event.target.nodeName) {
                case 'INPUT':
                case 'SELECT':
                case 'TEXTAREA':
                    return true;
                default:
                    return false;
            }
        }
        return false;
    }
}
