import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {User, UserMandant} from '../../../../../projects/kiene-core/src/lib/components/stammdaten/benutzer/benutzer';
import {MatSelectChange} from '@angular/material/select';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {MessageService} from '../../../services/message.service';
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {
    BenutzerService
} from '../../../../../projects/kiene-core/src/lib/components/stammdaten/benutzer/benutzer.service';
import {UserRole} from '../user';
import {Mandant} from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'kiene-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
    currentUserId: string;
    currentUser: User = new User();
    clients: Client[] = [];
    userroles: UserRole[] = [];
    availableMandanten: Mandant[] = [];
    assignedMandanten: UserMandant[] = [];

    emailCtrl: UntypedFormControl = new UntypedFormControl();
    newPasswortCtrl: UntypedFormControl = new UntypedFormControl();
    newPasswortRepeatCtrl: UntypedFormControl = new UntypedFormControl();
    userrolesCtrl: UntypedFormControl = new UntypedFormControl();
    vornameCtrl: UntypedFormControl = new UntypedFormControl();
    nachnameCtrl: UntypedFormControl = new UntypedFormControl();
    mobiltelefonCtrl = new UntypedFormControl();
    festnetzCtrl = new UntypedFormControl();
    titelCtrl = new UntypedFormControl();
    availableMandantenCtrl = new UntypedFormControl();
    urlaubstageCtrl = new FormControl();

    constructor(
        private route: ActivatedRoute,
        private messageService: MessageService,
        private userService: BenutzerService
    ) {
        this.currentUserId = this.route.snapshot.paramMap.get('id');
    }

    loadUser() {
        if (this.currentUserId && this.currentUserId !== 'neu') {
            this.userService.getUser(this.currentUserId).subscribe({
                next: (response) => {
                    this.currentUser = response;
                    this.initForm();
                    this.loadAssignedMandanten();
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Fehler! Benutzer mit der ID ' +
                        this.currentUserId +
                        ' konnte nicht geladen werden!',
                        error
                    );
                }
            });
        }
    }

    loadUserroles() {
        if (this.currentUserId && this.currentUserId !== 'neu') {
            this.userService.getAllUserRoles().subscribe({
                next: (response) => {
                    this.userroles = response.records;
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Fehler! Benutzerrollen konnten nicht geladen werden!',
                        error
                    );
                }
            });
        }
    }

    loadClientsForUser() {
        if (this.currentUserId && this.currentUserId !== 'neu') {
            this.userService.getClientsForUser(this.currentUserId).subscribe(
                (response) => {
                    this.clients = response.records;
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Fehler! Betriebe für den Benutzer mit der ID ' +
                        this.currentUserId +
                        ' konnten nicht geladen werden!',
                        error
                    );
                }
            );
        }
    }

    loadAvailableMandanten() {
        this.userService.getAvailableMandanten().subscribe((response) => {
            this.availableMandanten = response.records;
        });
    }

    loadAssignedMandanten() {
        this.userService
            .getAssignedMandanten(this.currentUser)
            .subscribe((response) => {
                this.assignedMandanten = response.records;
            });
    }

    assignMandantToUser(event: MatSelectChange) {
        const mandant: Mandant = event.value;
        const um = new UserMandant();
        um.benutzer_id = this.currentUser.benutzer_id;
        um.mandant_id = mandant.mandant_id;
        this.userService.assignMandantToUser(um).subscribe(
            (response) => {
                this.loadAssignedMandanten();
                this.messageService.infoMessage(
                    'Mandant ' +
                    mandant.bezeichnung +
                    ' wurde erfolgreich zugewiesen!'
                );
            },
            (error) => {
                this.messageService.errorMessage(
                    'Der Mandant ' +
                    mandant.bezeichnung +
                    ' konnte nicht zugewiesen werden! Fehler: ',
                    error
                );
            }
        );
    }

    removeMandantFromUser(um: UserMandant) {
        this.userService.removeMandantFromUser(um).subscribe(
            (response) => {
                this.loadAssignedMandanten();
                this.messageService.infoMessage(
                    'Mandant ' + um.bezeichnung + ' wurde erfolgreich entfernt!'
                );
            },
            (error) => {
                this.messageService.errorMessage(
                    'Der Mandant ' +
                    um.bezeichnung +
                    ' konnte nicht entfernt werden! Fehler: ',
                    error
                );
            }
        );
    }

    ngOnInit() {
        this.loadUser();
        this.loadUserroles();
        this.loadClientsForUser();
        this.loadAvailableMandanten();
    }

    initForm() {
        this.titelCtrl.setValue(this.currentUser.titel);
        this.vornameCtrl.setValue(this.currentUser.vorname);
        this.nachnameCtrl.setValue(this.currentUser.nachname);

        this.mobiltelefonCtrl.setValue(this.currentUser.mobil);
        this.festnetzCtrl.setValue(this.currentUser.telefon);

        this.emailCtrl.setValidators([Validators.email]);
        this.emailCtrl.setValue(this.currentUser.email);

        this.newPasswortCtrl.setValidators([Validators.minLength(8)]);
        this.newPasswortCtrl.setValue('');
        this.newPasswortRepeatCtrl.setValidators([Validators.minLength(8)]);
        this.newPasswortRepeatCtrl.setValue('');

        this.userrolesCtrl.setValidators([Validators.required]);
        this.userrolesCtrl.setValue(this.currentUser.benutzerrollen);

        this.urlaubstageCtrl.setValue(this.currentUser.urlaub_pro_jahr);
    }

    addClientToUser(client: Client) {
        if (!client) {
            return;
        }

        this.userService.assignClientToUser(this.currentUser, client).subscribe(
            () => {
                this.messageService.infoMessage(
                    'Betrieb ' +
                    client.bezeichnung_final +
                    ' wurde dem Benutzer erfolgreich zugewiesen!'
                );
                this.loadClientsForUser();
            },
            (error) => {
                this.messageService.errorMessage(
                    'Fehler! Betrieb ' +
                    client.bezeichnung_final +
                    ' konnte nicht dem Benutzer mit der ID ' +
                    this.currentUserId +
                    ' hinzugefügt werden!',
                    error
                );
            }
        );

        /*
        this.clientService.updateClient(client).subscribe(response => {
          this.snackBar.open("Betrieb " + client.bezeichnung + " wurde dem Benutzer erfolgreich zugewiesen!", "", { duration: 3000 });
          this.loadClientsForUser();
        }, error => {
          this.snackBar.open("Fehler! Betrieb " + client.bezeichnung + " konnte nicht dem Benutzer mit der ID "
          +this.currentUserId + " hinzugefügt werden!", "", { duration: 3000, panelClass: ['snackbar-error'] });
        });
        */
    }

    compareRoles(a: UserRole, b: UserRole): boolean {
        return a && b && a.benutzerrolle_id === b.benutzerrolle_id;
    }

    changeTelefonnummer() {
        this.currentUser.mobil = this.mobiltelefonCtrl.value;
        this.currentUser.telefon = this.festnetzCtrl.value;
        this.userService.updateUser(this.currentUser).subscribe(
            (response) => {
                this.currentUser = response;
                this.messageService.infoMessage(
                    'Telefonnummern wurden erfolgreich aktualisiert!'
                );
            },
            (error) => {
                this.messageService.errorMessage(
                    'Fehler! Telefonnummern konnten nicht aktualisiert werden!',
                    error
                );
            }
        );
    }

    removeClientFromUser(client: Client) {
        this.userService
            .removeClientFromUser(this.currentUser, client)
            .subscribe(
                (response) => {
                    this.messageService.infoMessage(
                        'Betrieb ' +
                        client.bezeichnung_final +
                        ' wurde erfolgreich vom Benutzer entfernt!'
                    );
                    this.loadClientsForUser();
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Fehler! Betrieb ' +
                        client.bezeichnung_final +
                        ' konnte nicht vom Benutzer mit der ID ' +
                        this.currentUserId +
                        ' entfernt werden!',
                        error
                    );
                }
            );
    }

    istBenutzerKundeOderViehhaendlerOderSGS(): boolean {
        for (const r of this.currentUser?.benutzerrollen) {
            if (r.kunde > 0 || r.viehhaendler > 0 || r.sgs) {
                return true;
            }
        }
        return false;
    }

    changeUserrole() {
        this.currentUser.benutzerrollen = this.userrolesCtrl.value;
        this.userService.updateUser(this.currentUser).subscribe(
            (response) => {
                this.currentUser = response;
                this.messageService.infoMessage(
                    'Benutzerrolle wurde erfolgreich aktualisiert!'
                );
            },
            (error) => {
                this.messageService.errorMessage(
                    'Fehler! Benutzerrolle konnte nicht aktualisiert werden!',
                    error
                );
            }
        );
    }

    changePassword() {
        if (this.newPasswortCtrl.valid && this.newPasswortRepeatCtrl.valid) {
            const newPasswort: string = this.newPasswortCtrl.value;
            const newPasswortRepeat: string = this.newPasswortRepeatCtrl.value;

            if (newPasswort === newPasswortRepeat) {
                this.currentUser.passwort = newPasswort;
                this.userService.updateUser(this.currentUser).subscribe(
                    (response) => {
                        this.currentUser = response;
                        this.messageService.infoMessage(
                            'Passwort wurde erfolgreich aktualisiert!'
                        );
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Fehler! Passwort konnte nicht aktualisiert werden!',
                            error
                        );
                    }
                );
            } else {
                this.messageService.alertMessage(
                    'Die Passwortwiederholung stimmt nicht mit dem Passwort überein!'
                );
            }
        }
    }

    changeName() {
        const newVorname = this.vornameCtrl.value;
        const newNachname = this.nachnameCtrl.value;
        this.currentUser.vorname = newVorname;
        this.currentUser.nachname = newNachname;
        this.currentUser.titel = this.titelCtrl.value;
        this.userService.updateUser(this.currentUser).subscribe(
            (response) => {
                this.currentUser = response;
                this.messageService.infoMessage(
                    'Der Name wurde erfolgreich aktualisiert!'
                );
            },
            (error) => {
                this.messageService.errorMessage(
                    'Fehler! Der Name konnte nicht aktualisiert werden!',
                    error
                );
            }
        );
    }

    changeEmail() {
        if (this.emailCtrl.valid) {
            const newEmail: string = this.emailCtrl.value;
            this.currentUser.email = newEmail;
            this.userService.updateUser(this.currentUser).subscribe(
                (response) => {
                    this.currentUser = response;
                    this.messageService.infoMessage(
                        'E-Mail Adresse wurde erfolgreich aktualisiert!'
                    );
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Fehler! E-Mail Adresse konnte nicht aktualisiert werden!',
                        error
                    );
                }
            );
        }
    }

    updateUrlaubstage() {
        if (this.urlaubstageCtrl.valid) {
            this.currentUser.urlaub_pro_jahr = this.urlaubstageCtrl.value;
            this.userService.updateUser(this.currentUser).subscribe({
                next: (response) => {
                    this.currentUser = response;
                    this.messageService.infoMessage(
                        'Die Urlaubstage wurden erfolgreich aktualisiert!'
                    );
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Fehler! Die Urlaubstage konnten nicht aktualisiert werden!',
                        error
                    );
                }
            });
        }
    }
}
