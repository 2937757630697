import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NumberpadComponent} from './numberpad/numberpad.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [
        NumberpadComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        NumberpadComponent
    ]
})
export class NumberpadModule {
}
