import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-generate-document-dialog',
    templateUrl: './generate-document-dialog.component.html',
    styleUrls: ['./generate-document-dialog.component.scss']
})
export class GenerateDocumentDialogComponent implements OnInit {

    currentClient: Client = new Client();

    documentTypeCtrl = new UntypedFormControl();

    constructor(public dialogRef: MatDialogRef<GenerateDocumentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.currentClient = data.currentClient;
    }

    ngOnInit() {
    }

    cancel() {
        this.dialogRef.close();
    }

    save() {
        this.dialogRef.close(this.documentTypeCtrl.value);
    }

}
