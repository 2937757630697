import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Gruppe } from '../../../gruppen/classes/gruppe';
import { UntypedFormControl } from '@angular/forms';
import { MessageService } from '../../../../services/message-service/message.service';
import { StammdatenService } from '../../../stammdaten/stammdaten.service';
import { TierGeschlecht } from '../../classes/tier-geschlecht';
import { OnChanges } from '@angular/core';

@Component({
    selector: 'kiene-gruppe-tier-geschlecht-filter',
    templateUrl: './gruppe-tier-geschlecht-filter.component.html',
    styleUrls: ['./gruppe-tier-geschlecht-filter.component.scss']
})
export class GruppeTierGeschlechtFilterComponent implements OnInit, OnDestroy, OnChanges {

    geschlechterGruppeSubscription: Subscription;
    isLoading = true;
    label = 'Lädt...';


    @Input('gruppe') selectedGruppe: Gruppe;
    @Input('geschlechter') geschlechter: TierGeschlecht[] = [];
    @Input('hideOnEmptyList') hideOnEmptyList = false;
    @Output('selectedGeschlechter') selectedGeschlechter: EventEmitter<TierGeschlecht[]> = new EventEmitter<TierGeschlecht[]>();
    selectedGeschlechtCtrl: UntypedFormControl = new UntypedFormControl(null);
    geschlechterFuerGruppe: TierGeschlecht[] = [];

    constructor(private messageService: MessageService, private stammdatenService: StammdatenService) { }

    ngOnDestroy(): void {
        this.geschlechterGruppeSubscription?.unsubscribe();
    }
    ngOnInit(): void {
        this.loadGeschlechterFuerGruppe();

        this.selectedGeschlechtCtrl.setValue(this.geschlechter);

        this.selectedGeschlechtCtrl.valueChanges.subscribe(data => {
            this.selectedGeschlechter.emit(data);
        });
    }

    ngOnChanges() {
        if (this.selectedGruppe) { this.loadGeschlechterFuerGruppe(); }
    }

    private loadGeschlechterFuerGruppe() {
        this.isLoading = true;
        if (this.selectedGruppe) {
            this.geschlechterGruppeSubscription?.unsubscribe();
            this.geschlechterGruppeSubscription = this.stammdatenService
                .ladeGeschlechter(this.selectedGruppe.gruppe_id)
                .subscribe({
                    next: (result) => {
                        this.selectedGeschlechtCtrl.enable();
                        this.isLoading = false;
                        this.geschlechterFuerGruppe = result.records;
                        this.geschlechterFuerGruppe.sort((a, b) => {
                            return a.geschlecht_id < b.geschlecht_id ? -1 : 1;
                        });

                        if (this.geschlechterFuerGruppe.length === 0) {
                            this.label = 'keine Geschlechter';
                            this.selectedGeschlechtCtrl.disable();
                        } else {
                            this.label = 'Geschlecht';
                        }
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Fehler beim Laden der Geschlechter',
                            err
                        );
                    },
                });
        }
    }

    resetGeschlechtCtrl($event: MouseEvent) {
        $event.stopPropagation();
        this.selectedGeschlechtCtrl.setValue(undefined);
    }

    compareGeschlecht(a: TierGeschlecht, b: TierGeschlecht) {
        return a && b && a.geschlecht_id === b.geschlecht_id;
    }

}
