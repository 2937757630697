import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectorInput } from '../../components/selector/selector/selector';

@Component({
  selector: 'kiene-selector-dialog',
  templateUrl: './selector-dialog.component.html',
  styleUrls: ['./selector-dialog.component.scss']
})
export class SelectorDialogComponent implements OnInit {

  selected: any;

  constructor(public dialogRef: MatDialogRef<SelectorDialogComponent>, @Inject(MAT_DIALOG_DATA) public input: SelectorInput) {
    this.selected = input?.selectedObject;
  }

  ngOnInit(): void {

  }

  selectOption(o: any) {
    this.selected = o;
  }

  close() {
    this.dialogRef.close(undefined);
  }

  save() {
    this.dialogRef.close(this.selected);
  }

}
