import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of as observableOf } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Material } from '../../warehouse/material/material';
import { MaterialService } from '../../warehouse/material/material.service';

@Component({
  selector: 'kiene-material-autocomplete',
  templateUrl: './material-autocomplete.component.html',
  styleUrls: ['./material-autocomplete.component.scss']
})
export class MaterialAutocompleteComponent implements OnInit {

  materialFormCtrl: UntypedFormControl = new UntypedFormControl();
  materialien: Observable<Material[]>;

  @Input() placeholder = 'Artikel';
  @Output() selectedMaterial = new EventEmitter<Material>();

  constructor(private materialService: MaterialService) { }

  ngOnInit() {
    this.autocompleteMaterial();

  }

  autocompleteMaterial() {
    this.materialFormCtrl = new UntypedFormControl();
    this.materialien = this.materialFormCtrl.valueChanges.pipe(
      startWith(''),
      switchMap(value => this.searchMaterial(value))
    );
  }

  searchMaterial(value: string) {
    console.debug('Mat-Autocomplete Search for: ' + value);
    return this.materialService.searchMaterial(value).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      map(response => response.records)
    );
  }

  materialMapper(artikel: Material) {
    if (artikel) {
      return artikel.artikel_me;
    }
  }

  autocompleteMaterialSelected(event: MatAutocompleteSelectedEvent) {
    console.debug('Material selected: ' + event.option.value.bezeichnung);
    const material = event.option.value;
    this.selectedMaterial.emit(material);
  }

}
