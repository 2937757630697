import { Tierreklamation } from "./tierreklamation";

export class TierreklamationCheckbox {
    tierreklamation: Tierreklamation;
    checked: boolean;

    constructor(tierreklamation: Tierreklamation, checked: boolean) {
        this.tierreklamation = tierreklamation;
        this.checked = checked;
    }
}
