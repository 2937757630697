import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {VetGroup} from '../vet-group';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {MessageService} from '../../../services/message.service';
import {HttpParams} from '@angular/common/http';
import {Vet, VetGroupVet} from '../../vet/vet';
import {Observable} from 'rxjs';

export class KieneColor {

    farbe: string;
    hintergrundfarbe: string;

    constructor(farbe: string, hintergrundfarbe: string) {
        this.farbe = farbe;
        this.hintergrundfarbe = hintergrundfarbe;
    }
}

@Component({
    selector: 'kiene-vet-group-details-dialog',
    templateUrl: './vet-group-details-dialog.component.html',
    styleUrls: ['./vet-group-details-dialog.component.scss']
})
export class VetGroupDetailsDialogComponent implements OnInit {

    vetgroupNameCtrl = new FormControl();
    farbeCtrl = new FormControl();
    tieraerzte: VetGroupVet[] = [];

    farben: KieneColor[] = [];

    constructor(public dialogRef: MatDialogRef<VetGroupDetailsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public vetgroup: VetGroup,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private messageService: MessageService) {
        if (!this.vetgroup) {
            this.vetgroup = new VetGroup();
        }
    }

    ngOnInit(): void {
        this.initFarben();
        this.ladeTieraerzteFuerGruppe();
        this.vetgroupNameCtrl.setValue(this.vetgroup.bezeichnung);
    }

    createVetgroup(): Observable<VetGroup> {
        this.vetgroup.bezeichnung = this.vetgroupNameCtrl.value;
        if (!this.vetgroup.farbe) {
            this.vetgroup.farbe = this.farben[0].farbe;
            this.vetgroup.hintergrundfarbe = this.farben[0].hintergrundfarbe;
        }
        return this.api.post(this.apiBaseUrl + 'tierarztgruppe/create.php', this.vetgroup);
    }

    editVetgroup(): Observable<VetGroup> {
        this.vetgroup.bezeichnung = this.vetgroupNameCtrl.value;
        if (!this.vetgroup.farbe) {
            this.vetgroup.farbe = this.farben[0].farbe;
            this.vetgroup.hintergrundfarbe = this.farben[0].hintergrundfarbe;
        }
        return this.api.post(this.apiBaseUrl + 'tierarztgruppe/update.php', this.vetgroup);
    }

    save() {
        if (this.vetgroup.tierarztgruppe_id) {
            this.editVetgroup().subscribe({
                next: response => {
                    this.dialogRef.close(this.vetgroup);
                },
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        } else {
            this.createVetgroup().subscribe({
                next: response => {
                    this.dialogRef.close(this.vetgroup);
                },
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        }

    }

    isColorSelected(farbe: KieneColor) {
        return this.vetgroup.farbe === farbe?.farbe && this.vetgroup.hintergrundfarbe === farbe?.hintergrundfarbe;
    }

    selectFarbe(farbe: KieneColor) {
        this.vetgroup.farbe = farbe?.farbe;
        this.vetgroup.hintergrundfarbe = farbe?.hintergrundfarbe;
    }

    tierarztEntfernen(t: VetGroupVet) {

        this.api.deleteEntity(this.apiBaseUrl + 'tierarztgruppe_tierarzt/delete.php', t).subscribe({
            next: response => {
                this.ladeTieraerzteFuerGruppe();
            },
            error: error => {
                this.messageService.errorMessage('Fehler!', error);
            }
        });
    }

    tierarztHinzufuegen(tierarzt: Vet) {
        if (!tierarzt) {
            return;
        }
        const vgv = new VetGroupVet();
        vgv.tierarzt_id = tierarzt.tierarzt_id;

        if (!this.vetgroup?.tierarztgruppe_id) {
            this.createVetgroup().subscribe({
                next: response => {
                    if (response) {
                        this.vetgroup = response;
                        vgv.tierarztgruppe_id = this.vetgroup.tierarztgruppe_id;
                        this.api.post(this.apiBaseUrl + 'tierarztgruppe_tierarzt/create.php', vgv).subscribe({
                            next: response => {
                                this.ladeTieraerzteFuerGruppe();
                            }
                        });
                    }
                }
            });
        } else {
            vgv.tierarztgruppe_id = this.vetgroup.tierarztgruppe_id;
            this.api.post(this.apiBaseUrl + 'tierarztgruppe_tierarzt/create.php', vgv).subscribe({
                next: response => {
                    this.ladeTieraerzteFuerGruppe();
                }
            });
        }
    }

    private ladeTieraerzteFuerGruppe() {
        if (!this.vetgroup.tierarztgruppe_id) {
            return;
        }
        const params = new HttpParams().set('tierarztgruppe_id', this.vetgroup?.tierarztgruppe_id);
        this.api.getAll(this.apiBaseUrl + 'tierarztgruppe_tierarzt/read.php', params).subscribe({
            next: response => {
                this.tieraerzte = response.records;
            }
        });
    }

    private initFarben() {
        this.farben.push(new KieneColor('#000000', '#FFFFFF'));
        this.farben.push(new KieneColor('#FFFFFF', '#333333'));
        this.farben.push(new KieneColor('#FFFFFF', '#5FAD56'));
        this.farben.push(new KieneColor('#FFFFFF', '#F2C14E'));
        this.farben.push(new KieneColor('#FFFFFF', '#F78154'));
        this.farben.push(new KieneColor('#FFFFFF', '#B4436C'));
        this.farben.push(new KieneColor('#FFFFFF', '#AF1B3F'));
        this.farben.push(new KieneColor('#FFFFFF', '#4F359B'));
        this.farben.push(new KieneColor('#FFFFFF', '#246EB9'));
        this.farben.push(new KieneColor('#FFFFFF', '#8DAA9D'));
        this.farben.push(new KieneColor('#FFFFFF', '#EB7BC0'));
        this.farben.push(new KieneColor('#000000', '#F9E784'));
    }
}
