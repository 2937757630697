import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserCredentials} from './user.credentials';
import {Observable} from 'rxjs';
import {API_BASE_URL_SERVICE} from '../../../projects/kiene-core/src/lib/kiene-core.config';
import {KieneSession} from '../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';

@Injectable()
export class LoginService {
    constructor(@Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string, private http: HttpClient) {
    }

    login(userCredentials: UserCredentials): Observable<KieneSession> {
        userCredentials.trim();
        return this.http.post<KieneSession>(
            this.apiBaseUrl + 'session/create.php',
            userCredentials
        );
    }

    logout() {
        return this.http.post(
            this.apiBaseUrl + 'session/delete.php',
            null
        );
    }
}
