import {StoragePlace} from "../storage-place/storage-place";

export class BestandElementCharge {
    lc_id: number;
    bezeichnung: string;
    lager: string;
    lager_id: number;
    artikel_id: number;
    artikel: string;
    artikel_me: string;
    charge: string;
    mhd: Date;
    menge: number;
    erstellt: Date;
    editiert: Date;
    charge_id: number;
}

export class TransferStockObject {
    lc_id: number;
    lagerplatz_id_ziel: number;
    target: StoragePlace;
    menge: number;
    lager_id_ziel: number;
    artikel: string;
}

export class BestandElement {
    lager: string;
    lager_id: number;
    menge: number;
    chargen: BestandElementCharge[] = [];
}
