<h1 mat-dialog-title>Menge korrigieren</h1>
<div mat-dialog-content>
  <p>Wareneingangsmenge f&uuml;r den Artikel {{wareneingangshistorie.artikel_me}} korrigieren.</p>
  <mat-form-field>
    <mat-label>Neue Menge</mat-label>
    <input matInput type="number" [formControl]="amountCtrl">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Abbrechen</button>
  <button mat-flat-button color="accent" (click)="save()">Korrigieren</button>
</div>