<h1 mat-dialog-title>Preise und Rabatte f&uuml;r {{betriebsart?.bezeichnung}}</h1>
<div mat-dialog-content>
    <kiene-verkaufspreis-selector
        (verkaufspreisSelectionChanged)="setVerkaufspreis($event)"
        [selectedVerkaufspreisId]="aktuellerVk.verkaufspreis_id"></kiene-verkaufspreis-selector>

    <mat-form-field style="width: 100%;">
        <mat-label>Rabatt</mat-label>
        <input matInput type="number" inputmode="decimal" [formControl]="rabattCtrl">
        <mat-icon matSuffix>percent</mat-icon>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</div>
