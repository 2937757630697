import {TierFilter} from './tier-filter';
import {Tierreklamation} from '../../../classes/tierreklamation';
import {TierGeschlecht} from '../../../classes/tier-geschlecht';
import {TierRasse} from '../../../classes/tier-rasse';
import {Tierliste} from '../../../classes/tierliste';
import {HttpParams} from '@angular/common/http';
import {Haendler} from '../../../../stammdaten/haendler/haendler';
import {formatDate} from '@angular/common';
import {Abteil} from '../../gruppe-tier-abteil-filter/gruppe-tier-abteil-filter';
import {
    GruppeTierEinstalldatum
} from '../../../../../components/gruppe-tier/filter/gruppe-tier-einstalldatum-filter/gruppe-tier-einstalldatum';


export class TierFilterSettings {
    abteile: Abteil[] = [];
    reklamationen: Tierreklamation[] = [];
    geschlechter: TierGeschlecht[] = [];
    rassen: TierRasse[] = [];
    filter: TierFilter = TierFilter.ALLE_TIERE;
    abgangslisten: Tierliste[] = [];
    viehhaendler: Haendler[] = [];
    einstalldatum: GruppeTierEinstalldatum[] = [];

    showAbteile = true;
    showReklamationen = true;
    showGeschlechter = true;
    showRassen = true;
    showFilter = true;
    showAbgangslisten = true;
    showViehhaendler = true;
    showEinstalldatum = true;
    clearAfterUse = false;

    clone(): TierFilterSettings {
        const tfs = new TierFilterSettings();
        tfs.abteile = this.abteile;
        tfs.reklamationen = this.reklamationen;
        tfs.geschlechter = this.geschlechter;
        tfs.rassen = this.rassen;
        tfs.filter = this.filter;
        tfs.abgangslisten = this.abgangslisten;
        tfs.viehhaendler = this.viehhaendler;
        tfs.einstalldatum = this.einstalldatum;

        tfs.showAbteile = this.showAbteile;
        tfs.showReklamationen = this.showReklamationen;
        tfs.showGeschlechter = this.showGeschlechter;
        tfs.showRassen = this.showRassen;
        tfs.showFilter = this.showFilter;
        tfs.showAbgangslisten = this.showAbgangslisten;
        tfs.showViehhaendler = this.showViehhaendler;
        tfs.showEinstalldatum = this.showEinstalldatum;

        return tfs;
    }

    isFilterActive() {
        if (this.abteile?.length > 0) {
            return true;
        }
        if (this.reklamationen?.length > 0) {
            return true;
        }
        if (this.geschlechter?.length > 0) {
            return true;
        }
        if (this.rassen?.length > 0) {
            return true;
        }
        if (this.abgangslisten?.length > 0) {
            return true;
        }
        if (this.viehhaendler?.length > 0) {
            return true;
        }
        if (this.einstalldatum?.length > 0) {
            return true;
        }
        if (this.filter !== TierFilter.ALLE_TIERE) {
            return true;
        }
        return false;
    }

    getAsParams(): HttpParams {
        let params = new HttpParams();

        if (this.abteile && this.showAbteile) {
            this.abteile.forEach((abteil) => {
                params = params.append('abteil[]', abteil.abteil);
            });
        }

        if (this.reklamationen && this.showReklamationen) {
            this.reklamationen.forEach((reklamation) => {
                params = params.append(
                    'tierreklamation_id[]',
                    reklamation.tierreklamation_id
                );
            });
        }

        if (this.geschlechter && this.showGeschlechter) {
            this.geschlechter.forEach((geschlecht) => {
                params = params.append(
                    'geschlecht_id[]',
                    geschlecht.geschlecht_id
                );
            });
        }

        if (this.rassen && this.showRassen) {
            this.rassen.forEach((rasse) => {
                params = params.append('rasse_id[]', rasse.rasse_id);
            });
        }

        if (this.abgangslisten && this.showAbgangslisten) {
            this.abgangslisten.forEach(liste => {
                params = params.append('tierliste_id[]', liste.tierliste_id);
            });
        }

        if (this.viehhaendler && this.showViehhaendler) {
            this.viehhaendler.forEach(liste => {
                params = params.append('viehhaendler_id[]', liste.viehhaendler_id);
            });
        }

        if (this.filter?.key !== null && this.showFilter) {
            params = params.set(this.filter.key, this.filter.value);
        }

        if (this.einstalldatum && this.showEinstalldatum) {
            this.einstalldatum.forEach(datum => {
                params = params.append('einstalldatum[]', formatDate(datum?.einstalldatum, 'yyyy-MM-dd', 'de'));
            });
        }

        return params;
    }
}
