import {Component, Inject, OnInit} from '@angular/core';
import {Vet} from '../../../../administration/vet/vet';
import {MatDialogRef} from '@angular/material/dialog';
import {API_BASE_URL_SERVICE} from '../../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {FormControl, Validators} from '@angular/forms';

@Component({
    selector: 'kiene-select-tierarzt-dialog',
    templateUrl: './select-tierarzt-dialog.component.html',
    styleUrls: ['./select-tierarzt-dialog.component.scss']
})
export class SelectTierarztDialogComponent implements OnInit {

    tieraerzte: Vet[] = [];
    selectCtrl = new FormControl(undefined, [Validators.required]);

    constructor(public dialogRef: MatDialogRef<SelectTierarztDialogComponent>,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
                private api: KieneBackendApiService) {
    }

    ngOnInit(): void {
        this.ladeTierarzte();
    }

    tierarztComparator(t1: Vet, t2: Vet) {
        return t1?.tierarzt_id === t2?.tierarzt_id;
    }

    private ladeTierarzte() {
        this.api.getAll(this.apiBaseUrl + 'tierarzt/read.php').subscribe({
            next: response => {
                this.tieraerzte = response.records;
            }
        });
    }
}
