import { Component, OnInit } from "@angular/core";
import { UserroleService } from "./userrole.service";
import { UserroleRight, Userright } from "./userrole";
import { MatSelectChange } from "@angular/material/select";
import { MatTableDataSource } from "@angular/material/table";
import { UntypedFormControl } from "@angular/forms";
import { KieneTableService } from "../../components/kiene-table/kiene-table.service";
import { environment } from "../../../environments/environment";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MessageService } from "../../services/message.service";
import { UserRole } from '../user/user';

@Component({
    selector: "kiene-userrole",
    templateUrl: "./userrole.component.html",
    styleUrls: ["./userrole.component.scss"],
})
export class UserroleComponent implements OnInit {
    isLoading: boolean = false;
    userroles: UserRole[] = [];
    currentUserrole: UserRole;
    userroleRights: UserroleRight[] = [];
    notSelectedRights: Userright[] = [];
    userrights: Userright[] = [];

    menus: string[] = [];
    groups: [][] = [];
    actions: Userright[][] = [];

    formControlArray: UntypedFormControl[] = [];
    userrightArray: boolean[] = [];

    userroleDatasource = new MatTableDataSource();
    displayedColumns = ["benutzerrecht", "deletebutton"];

    userRightCtrl: UntypedFormControl = new UntypedFormControl();

    userrightMap = new Map<string, any>();

    constructor(
        private service: UserroleService,
        private messageService: MessageService,
        private tableService: KieneTableService
    ) { }

    ngOnInit() {
        this.loadUserroles();
        this.loadAllUserrights();
    }

    loadAllUserrights() {
        this.tableService
            .get(environment.BASE_URI + "benutzerrecht/read.php")
            .subscribe({
                next: (response) => {
                    this.userrights = response.records;
                    for (let ur of this.userrights) {
                        this.userrightArray[ur.benutzerrecht_id] = false;
                    }
                    this.initUserrightMap();
                },
            });
    }

    initUserrightMap() {
        for (let ur of this.userrights) {
            const parts = ur.bezeichnung.split(" - ");
            const menu = parts[0];
            const group = parts[1];
            const action = parts[2];

            // console.debug('userright: %s - %s - %s', menu, group, action);

            if (this.userrightMap.has(menu)) {
                const groupMap: Map<string, any> = this.userrightMap.get(menu);
                if (groupMap.has(group)) {
                    let actions: Userright[] = groupMap.get(group);
                    actions.push(ur);
                } else {
                    let actions: Userright[] = [];
                    actions.push(ur);
                    groupMap.set(group, actions);
                }
            } else {
                let actions: Userright[] = [];
                actions.push(ur);
                let groupMap = new Map<string, Userright[]>();
                groupMap.set(group, actions);
                this.userrightMap.set(menu, groupMap);
            }
        }

        this.menus = Array.from(this.userrightMap.keys());
        for (let m of this.menus) {
            this.groups[m] = Array.from(this.userrightMap.get(m).keys());
            for (let groupName of this.groups[m]) {
                this.actions[m + "-" + groupName] = this.userrightMap
                    .get(m)
                    .get(groupName);
            }
        }

        for (let m of this.menus) {
            console.debug("Menu: %s", m);
            for (let g of this.groups[m]) {
                console.debug("\tGroup: %s", g);
                for (let a of this.actions[m + "-" + g]) {
                    console.debug("\t\ta: %s", a.bezeichnung);
                }
            }
        }
    }

    getGroups(menuName: string) {
        console.debug("getGroups");
        const groupMap: Map<string, any> = this.userrightMap.get(menuName);
        return Array.from(groupMap.keys());
    }

    initFormControls() {
        for (let u of this.userrights) {
            this.formControlArray[u.benutzerrecht_id];
        }
    }

    loadUserroles() {
        this.service.getAllUserroles().subscribe(
            (response) => {
                this.userroles = response.records;
            },
            (error) => { }
        );
    }

    loadUserrights(event: MatSelectChange) {
        let userrole: UserRole = event.value;
        this.currentUserrole = userrole;
        // this.loadNotSelectedRightsForRole();
        // this.loadRightsForRole(userrole);
    }

    setSelectedRole(event: MatSelectChange) {
        const userrole: UserRole = event.value;
        this.currentUserrole = userrole;
        for (let ur of this.userrights) {
            this.userrightArray[ur.benutzerrecht_id] = false;
        }
        // this.loadNotSelectedRightsForRole();
        this.loadRightsForCurrentRole();
    }

    loadNotSelectedRightsForRole() {
        this.service
            .getNotSelectedRightsForRole(this.currentUserrole)
            .subscribe(
                (response) => {
                    this.notSelectedRights = response.records;
                },
                (error) => { }
            );
    }

    loadRightsForCurrentRole() {
        this.isLoading = true;
        this.service.getUserroleRights(this.currentUserrole).subscribe({
            next: (response) => {
                this.userroleRights = response.records;
                for (let ur of this.userroleRights) {
                    this.userrightArray[ur.benutzerrecht_id] = true;
                }
                // this.userroleRights.unshift(new UserroleRight());
                // this.userroleDatasource.data = this.userroleRights;
                this.isLoading = false;
            },
            error: (error) => {
                this.isLoading = false;
            },
        });
    }

    setUserright(event: MatCheckboxChange, right: Userright) {
        console.debug("setUserright: %s", event.checked);
        if (event.checked) {
            this.service.addRightToRole(this.currentUserrole, right).subscribe({
                next: (response) => {
                    this.loadRightsForCurrentRole();
                },
            });
        } else {
            this.service
                .removeRightFromRole(this.currentUserrole, right)
                .subscribe({
                    next: (response) => {
                        this.loadRightsForCurrentRole();
                    },
                });
        }
    }

    addSelectedRightToRole() {
        let userright: Userright = this.userRightCtrl.value;
        if (userright != null) {
            this.service
                .addRightToRole(this.currentUserrole, userright)
                .subscribe(
                    (response) => {
                        // this.loadNotSelectedRightsForRole();
                        this.loadRightsForCurrentRole();
                        this.messageService.infoMessage(
                            userright.bezeichnung +
                            " erfolgreich zur Rolle " +
                            this.currentUserrole.bezeichnung +
                            " hinzugefügt"
                        );
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            "Fehler: " +
                            userright.bezeichnung +
                            " konnte nicht zur Rolle " +
                            this.currentUserrole.bezeichnung +
                            " hinzugefügt werden!",
                            error
                        );
                    }
                );
        }
    }

    // removeSelectedRightFromRole(urr: UserroleRight) {
    //   this.service.removeRightFromRole(urr).subscribe(response => {
    //     this.loadNotSelectedRightsForRole();
    //     this.loadRightsForRole(this.currentUserrole);
    //     this.snackbar.open(urr.benutzerrecht + " erfolgreich von Rolle " + urr.benutzerrolle + " entfernt!", "", { duration: 3000 });
    //   }, error => {
    //     this.snackbar.open("Fehler: " + urr.benutzerrecht + " konnte nicht von Rolle " + urr.benutzerrolle + " entfernt werden!", "", { duration: 3000, panelClass: ['snackbar-alert'] });
    //   });
    // }

    openNewUserroleDialog() { }
}
