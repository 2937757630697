import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {LoginService} from '../login/login.service';
import {LocalStorageService} from '../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {RegisterService} from './register.service';
import {Client} from '../../../projects/kiene-core/src/lib/components/client/classes/client';
import {MessageService} from '../services/message.service';
import {Router} from '@angular/router';
import {InitialSetupService} from '../initial-setup/initial-setup.service';
import {KieneStringUtils} from '../../../projects/kiene-core/src/lib/utils/KieneStringUtils';
import {KieneSession} from '../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import {Betriebsart} from '../../../projects/kiene-core/src/lib/components/stammdaten/vvvo/classes/betriebsart';
import {API_BASE_URL_SERVICE} from '../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {MatInput} from '@angular/material/input';

export class HitLoginDetails {
    vvvo: string;
    pin: string;

    constructor() {
    }
}

export class HitLoginResponse extends Client {
    benutzer_email: string;
    benutzer_vorname: string;
    benutzer_nachname: string;
    landwirt: number;
    viehhaendler: number;
}

@Component({
    selector: 'kiene-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
    // hit
    @ViewChild('hitPinInput') hitPinInput: MatInput;
    hitVvvoCtrl: FormControl = new FormControl('', [Validators.required, Validators.maxLength(15)]);
    hitPinCtrl: FormControl = new FormControl('', Validators.required);
    hidePassword = true;
    hasHitLoggedIn = false;
    isLoading = false;
    hitRequest: HitLoginDetails;
    hitLoginResponse: HitLoginResponse;

    // vetvet
    betriebsarten: Betriebsart[] = [];
    betriebsartCtrl = new FormControl(null, Validators.required);
    agbCtrl = new FormControl(undefined, [Validators.requiredTrue]);
    passwortCtrl: UntypedFormControl = new UntypedFormControl(null);
    passwortWdhCtrl: UntypedFormControl = new UntypedFormControl(null);

    hitEmailCtrl: UntypedFormControl = new UntypedFormControl('', Validators.required);
    benutzerEmailEmptyInital = false;
    benutzerEmailCtrl: UntypedFormControl = new UntypedFormControl('', Validators.required);
    landwirtHaendlerCtrl = new FormControl(0, [Validators.required]);

    constructor(
        private loginService: LoginService,
        private localStorageService: LocalStorageService,
        private registerService: RegisterService,
        private messageService: MessageService,
        private initialSetupService: InitialSetupService,
        private router: Router,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService
    ) {
        if (this.localStorageService.isLoggedIn()) {
            this.loginService.logout().subscribe(() => {
                this.localStorageService.deleteBrowserSession('constructor RegisterComponent');
            });
        }
    }

    ngOnInit(): void {
        this.betriebsartCtrl.valueChanges.subscribe(
            (betriebsart: Betriebsart) => {
                this.hitLoginResponse.betriebsart = betriebsart.bezeichnung;
                this.hitLoginResponse.betriebsart_id = betriebsart.betriebsart_id;
            }
        );

        this.landwirtHaendlerCtrl.valueChanges.subscribe({
            next: value => {
                if (value === 1) {
                    this.ladeBetriebsartViehhaendler();
                }
            }
        });

        this.hitVvvoCtrl.valueChanges.subscribe({
            next: (value: string) => {
                if (value && value.length === 15) {
                    this.hitPinInput.focus();
                }
            }
        });
    }

    hitFormValid(): boolean {
        let valid = true;

        this.hitVvvoCtrl.markAsTouched();
        valid = valid && this.hitVvvoCtrl.valid;

        this.hitPinCtrl.markAsTouched();
        valid = valid && this.hitPinCtrl.valid;

        return valid;
    }

    hitLogin() {
        if (this.hitFormValid()) {
            this.isLoading = true;
            this.hitRequest = new HitLoginDetails();
            this.hitRequest.vvvo = this.hitVvvoCtrl.value;
            this.hitRequest.pin = this.hitPinCtrl.value;
            this.registerService.hitLogin(this.hitRequest).subscribe({
                next: (hitLoginResponse: HitLoginResponse) => {
                    this.hasHitLoggedIn = true;
                    this.hitLoginResponse = hitLoginResponse;
                    this.isLoading = false;
                    this.initialSetupService.setHasHitLoggedIn(true);
                    this.initVetVetRegistration();
                },
                error: (error) => {
                    this.isLoading = false;
                    this.messageService.errorMessage(
                        'Fehler beim Laden der HIT-Daten!',
                        error
                    );
                },
            });
        } else {
            this.messageService.alertMessage('Eingaben nicht vollständig!');
        }
    }

    public compareBetriebsarten(b1: Betriebsart, b2: Betriebsart) {
        return b1?.betriebsart_id === b2?.betriebsart_id;
    }

    /**
     * Update Benutzer email, wenn diese am Anfang nicht gesetzt war, anhand der hit-Email und dessen Aenderungen
     */
    syncEmailInputs() {
        this.benutzerEmailEmptyInital = KieneStringUtils.isNullOrEmpty(
            this.hitLoginResponse.benutzer_email
        );

        this.hitEmailCtrl.setValue(this.hitLoginResponse.email);
        this.benutzerEmailCtrl.setValue(this.hitLoginResponse.benutzer_email);

        this.hitEmailCtrl.valueChanges.subscribe((value) => {
            this.hitLoginResponse.email = value;
            if (this.benutzerEmailEmptyInital) {
                this.benutzerEmailCtrl.setValue(value);
                this.hitLoginResponse.benutzer_email = value;
            }
        });

        this.benutzerEmailCtrl.valueChanges.subscribe((value) => {
            this.hitLoginResponse.benutzer_email = value;
        });
    }

    register() {
        let valid = true;
        this.betriebsartCtrl.markAsTouched();
        valid = this.betriebsartCtrl.valid;
        valid = this.checkedAGBs() && valid;
        valid = this.areValidPasswords() && valid;

        this.hitLoginResponse.landwirt = this.landwirtHaendlerCtrl.value === 0 ? 1 : 0;
        this.hitLoginResponse.viehhaendler = this.landwirtHaendlerCtrl.value === 1 ? 1 : 0;
        if (valid) {
            this.isLoading = true;
            this.registerService
                .register(
                    this.hitLoginResponse,
                    this.passwortCtrl.value,
                    this.passwortWdhCtrl.value,
                    this.hitRequest.pin
                )
                .subscribe({
                    next: (resp) => {
                        const newSession = <KieneSession>resp;
                        this.localStorageService.setCurrentUser(newSession);
                        this.localStorageService.setCurrentMandant(
                            // TODO: Mandant nicht default setzen
                            newSession.mandanten[0], 'RegisterComponent register()'
                        );
                        this.isLoading = false;
                        let skipInitialSetup = false;
                        for (const ur of newSession.benutzerrollen) {
                            if (ur.viehhaendler === 1) {
                                skipInitialSetup = true;
                            }
                        }
                        if (skipInitialSetup) {
                            this.router.navigate(['/']).then();
                        } else {
                            this.router.navigate(['initial-setup']).then();
                        }
                        this.messageService.infoMessage(
                            'Erfolgreich registriert.'
                        );
                    },
                    error: (error) => {
                        this.isLoading = false;
                        this.messageService.errorMessage(
                            'Fehler beim Registrieren.',
                            error
                        );
                    },
                });
        } else {
            this.messageService.alertMessage(
                'Nicht alle notwendigen Eingabefelder ausgefüllt!'
            );
        }
    }

    checkedAGBs(): boolean {
        if (this.agbCtrl.value) {
            this.agbCtrl.setErrors(null);
            return true;
        } else {
            this.agbCtrl.setErrors({notAccepted: true});
            return false;
        }
    }

    /**
     * Prüft ob Passwörter angegeben wurden und diese übereinstimmen
     */
    areValidPasswords(): boolean {
        this.passwortCtrl.markAsTouched();
        this.passwortWdhCtrl.markAsTouched();

        if (!this.passwortCtrl.value || !this.passwortWdhCtrl.value) {
            this.passwortCtrl.setErrors({required: true});
            this.passwortWdhCtrl.setErrors({required: true});
        } else {
            if (this.passwortCtrl.value !== this.passwortWdhCtrl.value) {
                this.passwortWdhCtrl.setErrors({mismatch: true});
                this.passwortCtrl.setErrors({mismatch: true});
                return false;
            } else {
                this.passwortWdhCtrl.setErrors(null);
                this.passwortCtrl.setErrors(null);
                return true;
            }
        }
    }

    backToLogin() {
        this.router.navigate(['/login']).then();
    }

    private initVetVetRegistration() {
        this.syncEmailInputs();
        this.loadBetriebsarten();
    }

    private loadBetriebsarten() {
        this.registerService.loadBetriebsarten().subscribe({
            next: (response) => {
                this.betriebsarten = response.records;
                if (this.hitLoginResponse.betriebsart_id) {
                    const betriebsart = this.betriebsarten.find(
                        (b) =>
                            b.betriebsart_id === this.hitLoginResponse.betriebsart_id
                    );
                    this.betriebsartCtrl.setValue(betriebsart);
                } else {
                    this.betriebsartCtrl.setErrors({required: true});
                    this.betriebsartCtrl.markAsTouched();
                }
            },
        });
    }

    private ladeBetriebsartViehhaendler() {
        this.api.get(this.apiBaseUrl + 'betriebsart/read_viehhaendler.php', undefined).subscribe({
            next: betriebsart => {
                this.betriebsartCtrl.setValue(betriebsart);
            },
            error: err => {

            }
        });
    }
}
