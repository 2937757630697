import {Inject, Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import {NavigationStart, Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {LocalStorageService} from '../services/local-storage/local-storage.service';
import {AppService} from '../services/app/app.service';
import {KieneNetworkLogEvent, NetworkService} from '../services/http/network.service';
import {MessageService} from '../services/message-service/message.service';
import {GIT_VERSIONS_FILE} from '../kiene-core.config';

@Injectable()
export class KieneSecurityInterceptor implements HttpInterceptor {
    private routeBeforeNavigation = '';

    constructor(
        private router: Router,
        @Inject(GIT_VERSIONS_FILE) private version,
        private localStorageService: LocalStorageService,
        private appService: AppService,
        private networkService: NetworkService,
        private messageService: MessageService
    ) {
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                let currentRoute = this.router.url;
                currentRoute = currentRoute.split('?')[0];
                if (currentRoute !== this.routeBeforeNavigation) {
                }
                this.routeBeforeNavigation = currentRoute;
            }
        });
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.appService.setDataLoadingStart();
        const requestMethod = request.method;
        const currentHeader = request.headers.get('Phito-Type');
        const noTokenHeader = request.headers.get('NOTOKEN');
        let authRequest: HttpRequest<any>;
        if (currentHeader === 'fileupload') {
            authRequest = request.clone({
                setHeaders: this.getSecurityHeadersWithoutContentType(),
            });
        } else if (noTokenHeader === '1') {
            authRequest = request.clone({
                headers: request.headers.delete('NOTOKEN'),
            });
        } else {
            authRequest = request.clone({
                setHeaders: this.getSecurityHeaders(),
            });
        }

        return next.handle(authRequest).pipe(
            finalize(() => {
                this.appService.setDataLoadingFinished();
            }),
            catchError((response: HttpErrorResponse) => {
                this.networkService.addEvent(
                    new KieneNetworkLogEvent(
                        response.status,
                        response.url,
                        requestMethod,
                        response.error,
                        null
                    )
                );
                if (response.status === 401) {
                    this.messageService.errorMessage(
                        'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
                        response
                    );
                    this.localStorageService.setLoggedIn(false);
                    this.router.navigate(['/login']).then();
                } else if (response.status === 403) {
                    this.messageService.errorMessage(
                        'Sie haben leider keine Berechtigung für diese Aktion.',
                        response
                    );
                }
                // else {
                //     console.log('ergebnis: %s', JSON.stringify(response));
                //     const ke = new KieneError();
                //     ke.name = response.name;
                //     ke.message = response.message;
                //
                //     this.kieneBackendApiService.handleException(
                //         true,
                //         ke,
                //         this.apiBaseUrl
                //     );
                // }

                return throwError(() => response);
            }),
            tap((res: HttpEvent<HttpEventType>) => {
                if (res instanceof HttpResponse) {
                    const contentLength = res.headers?.get('content-length');
                    if (contentLength) {
                        const cl = Number(contentLength);
                        this.appService.addContentLength(cl);
                    }

                    this.networkService.addEvent(
                        new KieneNetworkLogEvent(
                            res.status,
                            res.url,
                            requestMethod,
                            JSON.stringify(res.body, null, 2),
                            null
                        )
                    );

                    const uiVersion = res.headers?.get('UI-Version');
                    if (uiVersion) {
                        if (
                            this.version &&
                            this.version.version !== uiVersion
                        ) {
                            this.messageService.updateMessage(uiVersion);
                        }
                    }
                }
            })
        );
    }

    private getSecurityHeadersWithoutContentType(): any {
        const kieneSession = this.localStorageService.getSession();
        const kieneCurrentMandant = this.localStorageService.getCurrentMandant();

        let currentMandantId = -1;

        if (kieneCurrentMandant) {
            currentMandantId = kieneCurrentMandant.mandant_id;
        }
        let token = '0';
        if (kieneSession) {
            token = kieneSession.token;
            if (kieneSession.admin) {
                const debugToken = this.localStorageService.getDebugToken();
                if (debugToken) {
                    token = debugToken;
                }
            }
        }

        return {
            'X-XSRF-TOKEN': token.toString(),
            MANDANTID: currentMandantId.toString(),
        };
    }

    private getSecurityHeaders(): any {
        const kieneSession = this.localStorageService.getSession();
        const kieneCurrentMandant = this.localStorageService.getCurrentMandant();
        let currentMandantId = -1;

        if (kieneCurrentMandant) {
            currentMandantId = kieneCurrentMandant.mandant_id;
        }
        let token = '0';
        if (kieneSession) {
            token = kieneSession.token;
            if (kieneSession.admin) {
                const debugToken = this.localStorageService.getDebugToken();
                if (debugToken) {
                    token = debugToken;
                }
            }
        }

        return {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': token.toString(),
            MANDANTID: currentMandantId.toString(),
        };
    }
}
