<div *ngIf="filterItems?.length > 1" style="padding: 5px;">
  <mat-checkbox (change)="changedCheckboxAll($event)" [checked]="checkedAll" [indeterminate]="indeterminateAll"
    [disabled]="disabled"><strong>Alle An-/Abwählen</strong></mat-checkbox>
  <mat-divider></mat-divider>
</div>
<ng-container *ngFor="let f of filterItems">
  <div *ngIf="hasPermission(f)" style="padding: 5px;">
    <div *ngIf="isCheckbox(f)">
      <mat-checkbox (change)="changedCheckboxValue(f, $event)" [checked]="f.checked"
        [disabled]="disabled">{{f.title}}</mat-checkbox>
    </div>
  </div>
</ng-container>