<h1 mat-dialog-title>Dienstgruppe {{ dienstgruppe?.dienstgruppe_id ? 'editieren' : 'anlegen' }}</h1>
<div mat-dialog-content style="min-width: 600px; max-width: 600px; padding: 24px;">
    <mat-form-field>
        <mat-label>Name der Dienstgruppe</mat-label>
        <input [formControl]="dienstgruppeNameCtrl" matInput type="text">
    </mat-form-field>
    <ng-container *kienePermission="[1271]">
        <h4>Tier&auml;rzte</h4>
        <mat-chip-list style="margin-bottom: 12px; max-width: 800px;">
            <mat-chip (removed)="tierarztEntfernen(t)" *ngFor="let t of dienstgruppe?.tieraerzte">
                <span>{{ t.vorname }} {{ t.nachname }}</span>
                <button *kienePermission="[1274]" matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
        </mat-chip-list>

        <kiene-autocomplete *kienePermission="[1272]" (elementSelected)="tierarztHinzufuegen($event)"
                            [apiUrl]="'tierarzt/'"
                            [clearAfterSelect]="true"
                            [displayFields]="['vorname', 'nachname']"
                            [placeholder]="'Tierarzt suchen und hinzufügen'"></kiene-autocomplete>
    </ng-container>

    <h4>Farbe der Tierarztgruppe</h4>
    <mat-grid-list [cols]="farben?.length / 2" gutterSize="4px">
        <mat-grid-tile (click)="selectFarbe(farbe)" *ngFor="let farbe of farben"
                       [ngClass]="{'selected-color': isColorSelected(farbe)}"
                       [ngStyle]="{'background-color': farbe?.hintergrundfarbe}" class="color-field"
                       mouseOverClass="tile-hover"><span
            [ngStyle]="{'color': farbe?.farbe}">{{ dienstgruppeNameCtrl?.value }}</span></mat-grid-tile>
    </mat-grid-list>

</div>
<div align="end" mat-dialog-actions>
    <button (click)="dialogRef.close()" mat-button>Abbrechen</button>
    <button *kienePermission="[1262,1263]" (click)="save()" color="accent" mat-flat-button>Speichern</button>
</div>
