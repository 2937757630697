import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {StorageApi} from './storage';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable()
export class StorageService {

    constructor(private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    public getAllStorages(sortColumn: string,
                          sortDirection: string,
                          offset: number,
                          limit: number,
                          searchString: string): Observable<StorageApi> {
        return this.http.get<StorageApi>(this.apiBaseUrl +
            'lager/read.php?orderby=' + sortColumn + ' ' + sortDirection + '&limit=' + limit + '&offset=' + offset + '&search=' + searchString);
    }

    public getAll(): Observable<StorageApi> {
        return this.http.get<StorageApi>(this.apiBaseUrl + 'lager/read.php');
    }

    searchCharges(searchString: string): Observable<StorageApi> {
        return this.http.get<StorageApi>(this.apiBaseUrl + 'lager/read.php?search=' + searchString);
    }
}
