export class MassnahmenplanSchwein {

    abrechnen: number;
    abgabebeleg_id: number;
    mnp_schwein_id: number;
    abweichende_lieferadresse: number;
    kunde_id: number;
    kunde_strasse: string;
    strasse_lieferung: string;
    kunde_ort: string;
    ort_lieferung: string;
    kunde_plz: string;
    plz_lieferung: string;
    kunde_telefon_1: string;
    tierarzt_id: number;
    kuid: string;
    kunde: string;
    vvvo: string;
    kunde_nr_intern: number;
    status: string;
    status_id: number;
    tierart_id: number;
    unterschrift_kunde: number;
    _0_jahr: number;
    _0_halbjahr: number;
    _0_therapiehaeufigkeit: number;
    _1_anzahl_staelle: string;
    _1_anzahl_stallplaetze_gesamt: number;
    _1_sauenhalter: number;
    _2_ta_betreuungsvertrag: number;
    _2_weitere_ta_yes_no: number;
    _2_weitere_ta_name_anschrift: string;
    _2_weitere_berater_yes_no: number;
    _2_weitere_berater_name_anschrift: string;
    _3_1_rein_raus: number;
    _3_1_abteilweise: number;
    _3_1_stallweise: number;
    _3_1_kontinuierlich: number;
    _3_1_mit_auslauf: number;
    _3_1_freilandhaltung: number;
    _3_2_qs_teilnehmer: number;
    _3_2_bio_siegel: string;
    _3_2_sonstiges: number;
    _3_3_geschlossenes_system: number;
    _3_3_zukauf: number;
    _3_3_anzahl_herkuenfte: string;
    _3_4_alter_bei_einstallung: string;
    _3_4_mastdauer: string;
    _3_5_besatzdichte: string;
    _3_5_tiere_pro_bucht: string;
    _3_6_leerstandszeit: string;
    _3_7_vollspalten: number;
    _3_7_teilspalten: number;
    _3_7_betonspalten: number;
    _3_7_kunststoffspalten: number;
    _3_7_stroh: number;
    _3_7_kette: number;
    _3_7_kette_holz: number;
    _3_7_seil: number;
    _3_7_heu_stroh: number;
    _3_7_sonstiges_material_text: string;
    _3_7_anzahl_bucht: string;
    _4_zwangslueftung: number;
    _4_deckenlueftung: number;
    _4_tuerganglueftung: number;
    _4_freie_lueftung: number;
    _4_unterflurabsaugung: number;
    _4_individuell_1: string;
    _4_individuell_2: string;
    _4_heizkanone: number;
    _4_konvektor: number;
    _4_deltarohre: number;
    _4_individuell_3: string;
    _4_individuell_4: string;
    _4_durch: number;
    _5_breiautomat: number;
    _5_trockenautomat: number;
    _5_fluessigfuetterung: number;
    _5_individuell_1: string;
    _5_mehlfoermig: number;
    _5_granuliert: number;
    _5_pelletiert: number;
    _5_tiere_futterplatz: string;
    _5_brunnenwasser: number;
    _5_stadtwasser: number;
    _5_wasseruntersuchung: number;
    _5_wasseruntersuchung_text: string;
    _5_tiere_traenke: string;
    _5_art_traenke: string;
    _6_injektion: number;
    _6_futter: number;
    _6_wasser: number;
    _6_mischer_vorbehaelter: number;
    _6_dosierer: number;
    _6_tiernah: number;
    _6_lokal: number;
    _6_leitfaden: number;
    _6_risikomanagement: number;
    _7_1_art_schutzkleidung: string;
    _7_1_art_hygieneschleuse: string;
    _7_2_reinigung_stall: number;
    _7_2_desinfektion_stall: number;
    _7_3_wl_reinigung: number;
    _7_3_wl_desinfektion: number;
    _7_3_wl_routinemaessig: number;
    _7_3_wl_nach_antibiotika: number;
    _7_3_fe_futterleitung: number;
    _7_3_fe_futtertroege: number;
    _7_3_fe_routinemaessig: number;
    _7_3_fe_nach_antibiotika: number;
    _7_3_fe_futtersilo: number;
    _7_4_durch_fremdfirma: number;
    _7_4_selbst: number;
    _7_5_y_n: number;
    _7_5_y_n_anzahl: string;
    _7_5_mit_gummimatte: number;
    _7_5_mit_stroh: number;
    _7_5_sonstiges_y_n_text: string;
    _7_6_sonstige_absonder: number;
    _7_6_sonstige_absonder_text: string;
    _8_1_vorgeimpft_y_n: number;
    _8_1_vorgeimpft_y_n_text: string;
    _8_1_im_bestand_geimpft_y_n: number;
    _8_1_im_bestand_geimpft_y_n_text: string;
    _8_2_endoparasiten_y_n: number;
    _8_2_ektoparasiten_y_n: number;
    // _8_2_sonstiges_y_n: number;
    _8_2_sonstiges_y_n_text: string;
    _9_1_atemwege: number;
    _9_1_verdauungsapparat: number;
    _9_1_bewegungssapparat: number;
    // _9_1_sonstiges_y_n: number;
    _9_1_sonstiges_y_n_text: string;
    _9_2_anzahl_kalenderhalbjahr: string;
    _9_2_anzahl_vorheriges_halbjahr: string;
    _9_3_angaben_zu_krankheit: string;
    _9_4_erregernachweis_y_n: number;
    _9_4_erregernachweis_y_n_text: string;
    _9_4_sektion_y_n: number;
    _9_4_sektion_y_n_text: string;
    _9_4_sonstiges_y_n: number;
    _9_4_sonstiges_y_n_text: string;
    _10_1_ergebnis_beratung: string;
    _10_1_datum: Date;
    _10_1_text_streichen_y_n: number;
    _11_1_massnahmen: string;
    _11_1_zeitraum_umsetzung: string;
    _11_2_ort: string;
    _11_2_datum: Date;
    _11_2_verantwortlicher: string;
    befunde_anhaengen: number;
    bbp_anhaengen: number;

    pdf_id: number;
    erstellt: Date;
    erstellt_von: number;
    editiert: Date;
    editiert_von: number;
    geloescht: number;
    mandant_id: number;

    //TODO NEUE ATTRIBUTE
    _1_anzahl_sauen: number;
    _1_anzahl_jungsauen: number;
    _1_stallnummer_1: string;
    _1_stallnummer_2: string;
    _1_stallnummer_3: string;
    _1_stallnummer_4: string;
    _1_stallplatze_1: number;
    _1_stallplatze_2: number;
    _1_stallplatze_3: number;
    _1_stallplatze_4: number;
    _1_isolations_stall: number;
    _2_integration: number;
    _3_2_qs_teilnehmer_text: string;
    _3_2_bio_siegel_y_n: number;
    _3_2_sonstiges_text: string;
    _3_6_sonstiges: number;
    _3_6_sonstiges_text: string;
    _3_8_konventionelle_buchten: number;
    _3_8_teilspalten: number;
    _3_8_betonspalten: number;
    _3_8_kunststoffspalten: number;
    _3_8_strohspalten: number;
    _3_8_planbefestigt: number;
    _3_8_sonstiges: string;
    _3_8_rhythmus_1: number;
    _3_8_rhythmus_2: number;
    _3_8_rhythmus_3: number;
    _3_8_rhythmus_4: number;
    _3_8_rhythmus_5: number;
    _3_8_rhythmus_6: number;
    _3_8_rhythmus_7: number;
    _3_8_bewegungsbuchten: number;
    _3_8_freie_abferkelung: number;
    _3_8_anzahl_abferkelbuchten: number;
    _3_8_anzahl_abferkelplatze: number;
    _3_9_absetzalter_tage: number
    _3_9_absetzgewicht: number;
    _3_9_waschung: number;
    _3_9_umsetzung: number;
    _3_9_kastrieren: number;
    _3_9_schwaenze_kuerzen: number;
    _3_9_zaehne_schleifen: number;
    _3_9_remontierungsrate: number;
    _3_9_abgesetzte_ferkel: number;
    _3_9_traechtigkeitsdauer: number;
    _3_9_wuerfe: number;
    _3_9_abferkelrate: number;
    _3_9_lebendgeboren: number;
    _3_9_erstbelegungsalter: number;
    _4_waermelampe: number;
    _4_beheizte_bodenlampe: number;
    _4_klimacheck_regelmaessig: number;
    _4_klimacheck_bei_bedarf: number;
    _5_zufuetterung_mit_text: string;
    _5_kuenstliche_amme: number;
    _5_natuerliche_amme: number;
    _5_zusaetzlich_milch: number;
    _7_1_matten_vorhanden: number;
    _7_1_matten_vorhanden_text: string;
    _7_2_reinigung_stall_text: string;
    _7_2_reinigung_stall_selbst: number;
    _7_2_reinigung_stall_fremdfirma: number;
    _7_2_desinfektion_text: string;
    _7_2_desinfektion_stall_selbst: number;
    _7_2_desinfektion_stall_fremdfirma: number;
    _7_2_doku_vorhanden: number;
    _7_5_ammenabteil: number;
    _9_1_zns: number;
    _9_1_gesaeuge: number;
    _9_1_geschlechtsapparat: number;
    _7_3_wl_reinigung_text: string;
    _3_7_planbefestigt: number;
    _3_2_tierwohl: number;
    _5_trockenfuetterung: number;
    _5_abruffuetterung: number;
    _5_raufutter: number;

    _3_8_rhythmus_sonstiger: string;
    _9_verlustrate: string;
    _8_impfschema_zuchtschweine: string;
    _8_impfschema_saugferkel: string;
}
