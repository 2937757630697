import {Component, OnInit} from '@angular/core';
import {Vet} from './vet';
import {MessageService} from '../../services/message.service';
import {MatDialog} from '@angular/material/dialog';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {NumberTableColumn, TableColumn} from '../../components/kiene-table/table-column';
import {TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {environment} from '../../../environments/environment';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {VetDetailsDialogComponent} from './vet-details-dialog/vet-details-dialog.component';
import {VetVetGroupDetailsDialogComponent} from './vet-vet-group-details-dialog/vet-vet-group-details-dialog.component';

@Component({
    selector: 'kiene-vet',
    templateUrl: './vet.component.html',
    styleUrls: ['./vet.component.scss']
})
export class VetComponent implements OnInit {

    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(public dialog: MatDialog,
                private messageService: MessageService,
                private api: KieneTableService) {
    }

    ngOnInit() {

        this.tableDescriptor.headline = 'Tierärzte';
        this.tableDescriptor.uniqueIdentifier = 'Tierärzte';
        this.tableDescriptor.apiUrl = environment.BASE_URI + 'tierarzt/';
        this.tableDescriptor.route = '/administration/tierarzte/';
        this.tableDescriptor.nameOfIdField = 'tierarzt_id';
        this.tableDescriptor.searchPlaceholder = 'Tierärzte durchsuchen';
        this.tableDescriptor.columns.push(new TableColumn('nachname', 'Nachname'));
        this.tableDescriptor.columns.push(new TableColumn('vorname', 'Vorname'));
        this.tableDescriptor.columns.push(new NumberTableColumn('tierarztnummer', 'Tierarztnummer', '3.0-0'));
        this.tableDescriptor.columns.push(new NumberTableColumn('abgabebeleg_nr', 'Nächste Abgabebelegnr.', '6.0-0'));
        this.tableDescriptor.columns.push(new TableColumn('qs_alias', 'QS Alias'));
        this.tableDescriptor.columns.push(new TableColumn('tierarztgruppe', 'Tierarztgruppe'));
        this.tableDescriptor.columns.push(new TableColumn('lagerplatz', 'Lagerplatz'));
        this.tableDescriptor.columns.push(new TableColumn('kostenstelle', 'Kostenstelle'));

        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.showDetails = false;

        this.tableDescriptor.permissionCreate = 1252;
        this.tableDescriptor.permissionView = 1251;
        this.tableDescriptor.permissionUpdate = 1253;
        this.tableDescriptor.permissionDelete = 1254;

        this.tableDescriptor.options.push(new TableOption('edit', 'Einstellungen', 'edit', 1253));
        this.tableDescriptor.options.push(new TableOption('editVetgroup', 'Tierarztgruppe ändern', 'forward', 1253));
        this.tableDescriptor.options.push(new TableOption('delete', 'löschen', 'delete', 1254));

        this.tableDescriptor.initialSortColumn = 'nachname';
        this.tableDescriptor.initialSortDirection = 'asc';

    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            this.openAddVetDialog();
        } else if (event.name === 'edit') {
            this.openEditVetDialog(event.value);
        } else if (event.name === 'editVetgroup') {
            this.openEditVetGroupDialog(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteConfirmDialog(event.value);
        }
    }


    openAddVetDialog() {
        const dialogRef = this.dialog.open(VetDetailsDialogComponent, {
            width: '700px'
        });
        dialogRef.afterClosed().subscribe(newVet => {
            if (newVet) {
                const vet: Vet = newVet;
                this.api.post(environment.BASE_URI + 'tierarzt/create.php', vet).subscribe(response => {
                    this.messageService.infoMessage('Tierarzt ' + response.vorname + ' ' + response.nachname + ' wurde erfolgreich angelegt!');
                    this.api.reload();
                }, error => {
                    this.messageService.errorMessage('Tierarzt konnte nicht angelegt werden! Fehler: ', error);
                });


            }
        });
    }

    openEditVetDialog(vet: Vet) {
        const dialogRef = this.dialog.open(VetDetailsDialogComponent, {
            width: '700px',
            data: {tierarzt: vet}
        });
        dialogRef.afterClosed().subscribe(vetEdit => {
            if (vetEdit) {
                this.api.updateEntity(environment.BASE_URI + 'tierarzt_mandant/update.php', vetEdit).subscribe(response => {
                    this.messageService.infoMessage('Der Tierarzt  wurde erfolgreich aktualisiert!');
                    this.api.reload();
                }, error => {
                    this.messageService.errorMessage('Tierarzt konnte nicht editiert werden! Fehler: ', error);
                });


            }
        });
    }


    openEditVetGroupDialog(vet: Vet) {
        const dialogRef = this.dialog.open(VetVetGroupDetailsDialogComponent, {
            width: '700px',
            data: {tierarzt: vet}
        });
        dialogRef.afterClosed().subscribe(vetEdit => {
            if (vetEdit) {
                this.api.updateEntity(environment.BASE_URI + 'tierarzt/update.php', vetEdit).subscribe(response => {
                    this.messageService.infoMessage('Tierarzt ' + response.vorname + ' ' + response.nachname + ' wurde erfolgreich editiert!');
                    this.api.reload();
                }, error => {
                    this.messageService.errorMessage('Tierarzt konnte nicht editiert werden! Fehler: ', error);
                });
            }
            this.api.reload();
        });
    }

    openDeleteConfirmDialog(vet: Vet) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '350px',
            data: {
                title: 'Tierarzt löschen',
                text: 'Wollen Sie den Tierarzt ' + vet.vorname + ' ' + vet.nachname + ' wirklich löschen?',
                textButton: 'Löschen'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.deleteVet(vet);
            }
        });
    }

    deleteVet(vet: Vet) {
        this.api.deleteEntity(environment.BASE_URI + 'tierarzt/delete.php', vet).subscribe(response => {
            this.messageService.infoMessage('Tierarzt ' + vet.vorname + ' ' + vet.nachname + ' erfolgreich entfernt!');
            this.api.reload();
        }, error => {
            console.error(error);
            this.messageService.errorMessage('Löschen von Tierarzt ' + vet.vorname + ' ' + vet.nachname + ' ist fehlgeschlagen!', error);
        });
    }


}

