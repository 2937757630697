import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {MessageService} from '../../services/message.service';
import {environment} from '../../../environments/environment';
import {IfElseTableColumn, TableColumn} from '../../components/kiene-table/table-column';
import {Condition, Operator, TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {Behandlungsanfrage, BestellungStatus} from './behandlungsanfrage';
import {BehandlungsanfrageService} from './behandlungsanfrage.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Abgabebeleg} from '../abgabebeleg/abgabebeleg';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {
    FilterItem,
    FilterItemType,
} from '../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {
    ConfirmDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {
    ChangeCurrentClientService
} from '../../administration/client/change-current-client/change-current-client.service';
import {ClientService} from '../../administration/client/client.service';
import {SelectMandantDialogComponent} from '../../dialogs/select-mandant-dialog/select-mandant-dialog.component';
import {Mandant} from '../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'kiene-behandlungsanfrage',
    templateUrl: './behandlungsanfrage.component.html',
    styleUrls: ['./behandlungsanfrage.component.scss'],
})
export class BehandlungsanfrageComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private api: BehandlungsanfrageService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private clientService: ClientService,
        private changeCurrentClientService: ChangeCurrentClientService
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Behandlungsanfragen';
        this.tableDescriptor.uniqueIdentifier = 'Behandlungsanfragen';
        this.tableDescriptor.apiUrl =
            environment.BASE_URI + 'behandlungsanfrage/';
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.route = '/belege/behandlungsanfragen/';
        this.tableDescriptor.nameOfIdField = 'bestellung_id';
        this.tableDescriptor.sendCurrentClient = true;
        this.tableDescriptor.searchPlaceholder =
            'Behandlungsanfragen durchsuchen';
        this.tableDescriptor.columns.push(
            new TableColumn('bestellung_id', 'Nr.')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('bezeichnung', 'Bezeichnung')
        );
        this.tableDescriptor.columns.push(new TableColumn('status', 'Status'));
        this.tableDescriptor.columns.push(
            new TableColumn('zieldatum', 'Zieldatum', 'date')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('tierarzt', 'Tierarzt')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('tierarztgruppe', 'Gruppe')
        );
        // this.tableDescriptor.columns.push(new TableColumn('kunde', 'Kunde'));
        this.tableDescriptor.columns.push(new IfElseTableColumn('kunde', 'Kunde', 'unternehmensgruppe', 'unternehmensgruppe', 'kunde'));
        this.tableDescriptor.columns.push(new TableColumn('zusatz', 'Zusatz'));
        this.tableDescriptor.columns.push(
            new TableColumn('erstellt', 'Erstellt am', 'datetime')
        );

        this.tableDescriptor.filterItems = this.getFilterItems();

        this.tableDescriptor.permissionView = 1161;
        this.tableDescriptor.permissionCreate = 1162;
        this.tableDescriptor.permissionUpdate = 1163;
        this.tableDescriptor.permissionDelete = 1164;

        this.tableDescriptor.options.push(
            new TableOption(
                'transform',
                'in Abgabebeleg umwandeln',
                'swap_horiz',
                1236
            )
        );
        this.tableDescriptor.options.push(
            new TableOption('duplicate', 'duplizieren', 'file_copy', 1165)
        );
        this.tableDescriptor.options.push(
            new TableOption('cancel', 'stornieren', 'cancel', 1163, {conditions: [new Condition('status_id', Operator.KLEINER, BestellungStatus.KOMMISSIONIERT)]})
        );

        this.tableDescriptor.initialSortColumn = 'erstellt';
        this.tableDescriptor.initialSortDirection = 'desc';


        // this.tableDescriptor.filterItems.push(new FilterItem('Nicht in Abgabebeleg umgewandelt', 0, FilterItemType.CHECKBOX, 'abgabebeleg', true));
    }

    getFilterItems() {
        const filterItems = [];
        if (this.localStorageService.getCurrentUser().tierarzt_id) {
            filterItems.push(new FilterItem('Nur meine Tierarztgruppen', 1, FilterItemType.CHECKBOX, 'meinetierarztgruppen', false));
        }

        filterItems.push(
            new FilterItem(
                'Anfrage aufgegeben',
                2,
                FilterItemType.CHECKBOX,
                'status_id',
                true
            )
        );
        filterItems.push(
            new FilterItem(
                'Anfrage freigegeben',
                3,
                FilterItemType.CHECKBOX,
                'status_id',
                true
            )
        );
        filterItems.push(
            new FilterItem(
                'Anfrage kommissioniert',
                4,
                FilterItemType.CHECKBOX,
                'status_id',
                true
            )
        );
        filterItems.push(
            new FilterItem(
                'Anfrage abgeholt',
                5,
                FilterItemType.CHECKBOX,
                'status_id',
                true
            )
        );
        filterItems.push(
            new FilterItem(
                'Anfrage storniert',
                6,
                FilterItemType.CHECKBOX,
                'status_id',
                false
            )
        );

        return filterItems;
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            this.neueBehandlungsanfrage();
        } else if (event.name === 'transform') {
            this.transformToAbgabebeleg(event);
        } else if (event.name === 'duplicate') {
            this.duplicate(event.value);
        } else if (event.name === 'cancel') {
            this.openCancelDialog(event.value);
        }
    }

    transformToAbgabebeleg(event: TableOptionEvent) {
        const behandlungsanfrage: Behandlungsanfrage = event.value;

        const input = new ConfirmDialogInput();
        input.headline = 'In Abgabebeleg umwandeln';
        input.text =
            'Wollen Sie die Behandlungsanfrage wirklich in einen Abgabebeleg umwandeln?';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (response: ConfirmDialogResult) => {
                if (response?.confirmed) {
                    this.api
                        .transformToAbgabebeleg(behandlungsanfrage)
                        .subscribe(
                            (beleg) => {
                                const abgabebeleg: Abgabebeleg = beleg;
                                this.messageService.infoMessage(
                                    'Die Behandlungsanfrage wurde erfolgreich in einen Abgabebeleg umgewandelt!'
                                );
                                this.router.navigate([
                                    'belege',
                                    'abgabebelege',
                                    abgabebeleg.abgabebeleg_id,
                                ]);
                            },
                            (error) => {
                                this.messageService.errorMessage(
                                    'Die Behandlungsanfrage konnte nicht in ein Abgabebeleg umgewandelt werden! ',
                                    error
                                );
                            }
                        );
                }
            },
        });
    }

    openCancelDialog(behandlungsanfrage: Behandlungsanfrage) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title:
                    'Behandlungsanfrage ' +
                    behandlungsanfrage.bezeichnung +
                    ' wirklich stornieren?',
                text:
                    'Behandlungsanfrage ' +
                    behandlungsanfrage.bezeichnung +
                    ' wirklich stornieren?',
                textButton: 'stornieren',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                behandlungsanfrage.status_id = 6;
                this.api
                    .updateBehandlungsanfrage(behandlungsanfrage, behandlungsanfrage.mandant_id)
                    .subscribe(
                        (result) => {
                            this.messageService.infoMessage(
                                'Die Behandlungsanfrage wurde erfolgreich storniert!'
                            );
                            this.tableService.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Die Behandlungsanfrage konnte nicht storniert werden! ',
                                error
                            );
                        }
                    );
            }
        });
    }

    duplicate(behandlungsanfrage: Behandlungsanfrage) {
        this.api.duplicate(behandlungsanfrage, behandlungsanfrage.mandant_id).subscribe(
            (response) => {
                this.messageService.infoMessage(
                    'Die Behandlungsanfrage wurde erfolgreich dupliziert!'
                );
                this.router.navigate([
                    'belege',
                    'behandlungsanfragen',
                    response.bestellung_id,
                ]);
            },
            (error) => {
                this.messageService.errorMessage(
                    'Die Behandlungsanfrage konnte nicht dupliziert werden!',
                    error
                );
            }
        );
    }

    private neueBehandlungsanfrage() {
        console.log('Neue Behandlungsanfrage');
        let currentClient = this.localStorageService.getCurrentClient();
        if (!currentClient) {
            this.changeCurrentClientService.openChangeDialog('Behandlungsanfrage').subscribe(client => {
                if (client) {
                    currentClient = client;
                    this.checkClientMandant();
                }
            });
        } else {
            this.checkClientMandant();
        }
    }

    private checkClientMandant() {
        const currentClient = this.localStorageService.getCurrentClient();

        if (this.localStorageService.isCurrentUserKunde()) {
            this.clientService.getMandantenForClient(currentClient).subscribe({
                next: response => {
                    const mandanten = <Mandant[]>response.records;
                    if (mandanten.length > 1) {
                        this.openMandantAuswaehlenDialog(mandanten);
                    } else if (mandanten.length === 1) {
                        const mandant = mandanten[0];
                        this.router.navigate(['neu'], {
                            queryParams: {
                                mandantid: mandant.mandant_id,
                                mandantname: mandant.bezeichnung
                            },
                            relativeTo: this.route
                        }).then();
                    } else {
                    }
                }
            });
        } else {
            const mandant = this.localStorageService.getCurrentMandant();
            this.router.navigate(['neu'], {
                queryParams: {
                    mandantid: mandant.mandant_id,
                    mandantname: mandant.bezeichnung
                },
                relativeTo: this.route
            }).then();
        }

    }

    private openMandantAuswaehlenDialog(mandanten: Mandant[]) {
        const dialogRef = this.dialog.open(SelectMandantDialogComponent, {
            maxWidth: '90%',
            data: {
                mandanten: mandanten,
                title: 'Bitte Praxis für Behandlungsanfrage auswählen'
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            const mandant: Mandant = result;
            if (mandant) {
                this.router.navigate(['neu'], {
                    queryParams: {
                        mandantid: mandant.mandant_id,
                        mandantname: mandant.bezeichnung
                    },
                    relativeTo: this.route
                }).then();
            }
        });
    }
}
