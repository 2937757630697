import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'kiene-tier-loeschen-confirm-dialog',
  templateUrl: './tier-loeschen-confirm-dialog.component.html',
  styleUrls: ['./tier-loeschen-confirm-dialog.component.scss']
})
export class TierLoeschenConfirmDialogComponent implements OnInit {

    ohrmarke: string;

  constructor(
      public dialogRef: MatDialogRef<TierLoeschenConfirmDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
      this.ohrmarke = data.ohrmarke;
  }

  ngOnInit(): void {
  }

  akzeptieren() {
      this.dialogRef.close(true);
  }

  ablehnen() {
      this.dialogRef.close(false);
  }

}
