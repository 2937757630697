<h1 mat-dialog-title>Navigationsmen&uuml;</h1>

<div mat-dialog-content>
    <div *ngIf="isLoading"
        style="display: flex; align-items: center; justify-content: center; text-align: center; margin: 32px 0">
        <mat-hint class="hinweis-text">Lade Gruppe...</mat-hint>
    </div>
    <div *ngIf="!isLoading">
        <div style="margin: 6px 0">
            <strong>Gruppe:&nbsp;</strong>
            <span>{{gruppe?.bezeichnung}}{{gruppe?.zusatz ? ' - ' + gruppe?.zusatz: ''}}</span>
        </div>
        <mat-selection-list [multiple]="false">
            <mat-list-option (click)="navigateTo('gruppe')">
                <div class="one-row">
                    <span></span>
                    <span class="spacer">Zur Gruppe</span>
                    <mat-icon color="accent">chevron_right</mat-icon>
                </div>
            </mat-list-option>
            <mat-list-option (click)="navigateTo('einstallen')">
                <div class="one-row">
                    <span></span>
                    <span class="spacer">Einstallen</span>
                    <mat-icon color="accent">chevron_right</mat-icon>
                </div>
            </mat-list-option>
            <mat-list-option (click)="navigateTo('gruppe')">
                <div class="one-row">
                    <span></span>
                    <span class="spacer">Ausstallen/Umstallen</span>
                    <mat-icon color="accent">chevron_right</mat-icon>
                </div>
            </mat-list-option>
        </mat-selection-list>
    </div>
</div>


<div mat-dialog-actions>
    <button color="accent" mat-flat-button (click)="dialogRef.close()">Schlie&szlig;en</button>
</div>