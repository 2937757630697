<div class="kiene-content-page">

    <div *ngIf="isLoading" class="kiene-loading-spinner">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div class="file-row">
        <h1>Bestellung <span *ngIf="bestellungExtern.bestell_nr">{{bestellungExtern.bestell_nr}}</span></h1>
        <span class="kiene-spacer"></span>
        <div class="file-row">

            <button (click)="goBack()" mat-icon-button matTooltip="zurück">
                <mat-icon color="primary">arrow_back</mat-icon>
            </button>

            <button (click)="saveBestellungExtern()" *kienePermission="[1763]" color="primary"
                    mat-icon-button
                    matTooltip="Zwischenspeichern">
                <mat-icon>save</mat-icon>
            </button>

            <div>
                <button *kienePermission="[1742,1752]" [disabled]="bestellungExternId === 'neu'"
                        [matMenuTriggerFor]="befundOptionsMenu"
                        color="primary"
                        mat-icon-button matTooltip="Dokumente hinzufügen">
                    <mat-icon>cloud_upload</mat-icon>
                </button>
                <mat-menu #befundOptionsMenu>
                    <ng-template matMenuContent>
                        <button (click)="openAddDeliveryNoteDialog()" *kienePermission="[1742]" mat-menu-item>
                            <mat-icon>description</mat-icon>
                            <span>Lieferschein hinzuf&uuml;gen</span>
                        </button>
                        <button (click)="openAddInvoiceDialog()" *kienePermission="[1752]" mat-menu-item>
                            <mat-icon>description</mat-icon>
                            <span>Rechnung hinzuf&uuml;gen</span>
                        </button>
                    </ng-template>
                </mat-menu>
            </div>
            <div class="status-label">{{bestellungExternId === 'neu' ? 'neu' : bestellungExtern.status}}</div>

        </div>

    </div>

    <div class="one-row">
        <span style="color: gray;">{{supplier.bezeichnung}}
            , {{supplier.strasse}} {{supplier.plz}} {{supplier.ort}}</span>
        <span class="kiene-spacer"></span>
        <span *ngIf="bestellungExtern.bestellt">bestellt am: {{bestellungExtern?.bestellt | date: 'medium'}}</span>
    </div>

    <div *ngIf="deliveryNotes.length > 0">
        <h5>Lieferscheine</h5>
        <mat-chip-list>
            <mat-chip (removed)="openRemoveDokument(dn)" *ngFor="let dn of deliveryNotes" removable="true">
                <mat-icon>insert_drive_file</mat-icon>
                <span (click)="showDeliveryNotePdf(dn)" *kienePermission="[1741]"
                      style="cursor: pointer">{{dn.dateiname ? dn.dateiname : (dn.erstellt | date: 'dd.MM.yyyy')}}</span>
                <mat-icon *kienePermission="[1744]" matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </div>

    <div *kienePermission="[1751]">
        <div *ngIf="invoices.length > 0">
            <h5>Rechnungen</h5>
            <div class="file-row">
                <kiene-option-button (onClick)="showInvoicePdf(iv)" (optionClicked)="invoiceOption($event, iv)"
                                     *ngFor="let iv of invoices"
                                     [icon]="'insert_drive_file'"
                                     [options]="[{action: 'freigeben', text: 'freigeben', icon: 'check', permission: 1758}, {action: 'gebucht', text: 'als gebucht markieren', icon: 'account_balance', permission: 1759}, {action: 'loeschen', text: 'löschen', icon: 'delete', permission: 1754}]"
                                     [text]="iv.dateiname"></kiene-option-button>
            </div>
        </div>
    </div>

    <div style="width: 100%;">

        <h4>Positionen</h4>
        <mat-card>
            <div *ngIf="bestellungExtern?.positionen?.length > 0" style="width:100%;">
                <div class="flex-grid position">
                    <div class="pos-head">Pos.</div>
                    <div class="artikel-head">Artikel</div>
                    <div class="amount-head">Bestellmenge</div>
                    <div class="amount-head">Kommentar</div>
                    <div class="amount-head">Gelieferte Menge</div>
                    <div class="amount-head">Chargen</div>
                    <div class="remove-head"></div>
                </div>
            </div>
            <div *ngFor="let p of bestellungExtern?.positionen; let i = index" style="width:100%;">
                <div class="flex-grid position">
                    <div class="pos-col">{{(i + 1)}}.</div>
                    <div class="artikel-col">{{p.artikel_me}}</div>
                    <div class="amount-col">{{p.menge_soll}} Stk.</div>
                    <div class="amount-col">{{p.kommentar}}</div>
                    <div class="amount-col">{{p.menge_ist ? p.menge_ist : 0}} Stk.</div>
                    <div class="amount-col">{{p.chargen}}</div>
                    <div class="remove-col">
                        <button (click)="removePosition(p)" *ngIf="editAllowed" mat-icon-button>
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-icon *ngIf="p.menge_ist >= p.menge_soll" color="accent">check_circle</mat-icon>
                    </div>
                </div>
            </div>
        </mat-card>
        <div class="one-row">
            <div *kienePermission="[1766]">
                <button (click)="markAsBooked()" *ngIf="bestellungExtern?.status_id === 22" color="primary"
                        mat-stroked-button
                        style="margin-top: 20px;">Als bestellt markieren
                </button>
            </div>
            <span class="spacer"></span>
            <div *kienePermission="[1766]">
                <button (click)="order()" *ngIf="bestellungExtern?.status_id === 22" color="primary"
                        mat-flat-button
                        style="margin-top: 20px;">Bestellen
                </button>
            </div>
            <div *kienePermission="[1763]">
                <button (click)="release()" *ngIf="bestellungExtern?.status_id === 21" color="primary"
                        mat-flat-button
                        style="margin-top: 20px;">Zur Bestellung freigeben
                </button>
            </div>
            <div *kienePermission="[1763]">
                <!-- 23 = bestellt, 24 = bestellt + mail, 25 = bestellt + fax, 27 = teilweise geliefert, 29 = als bestellt markiert -->
                <button (click)="close()"
                        *ngIf="bestellungExtern.status_id === 23 || bestellungExtern.status_id === 24 || bestellungExtern.status_id === 25 || bestellungExtern.status_id === 27 || bestellungExtern.status_id === 29"
                        color="primary" mat-flat-button style="margin-top: 20px;">Bestellung geliefert
                </button>
            </div>
        </div>
        <div style="width: 100%; text-align:right;">

        </div>

    </div>
</div>
