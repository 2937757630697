export class HeimtierDaten {
    ht_daten_id: number;
    heimtier_id: number;
    gewicht: number;
    puls: number;
    atmung: number;
    temperatur: number;
    erstellt: Date;
    erstelltVon: number;
    editiert: Date;
    editiertVon: number;
    geloescht: number;
}

export class HeimtierDatenInput extends HeimtierDaten {

}

export class HeimtierDatenOutput {
    heimtierDaten: HeimtierDaten;
    confirm: boolean;
}

