import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Material} from '../material';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {HttpParams} from '@angular/common/http';
import {MessageService} from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import {KundeArtikel} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';

export class UnternehmensgruppeArtikel {
    artikel: string;
    artikel_id: number;
    artikel_me: string;
    editiert: Date;
    editiert_von: number;
    einzelpreis: number;
    erstellt: Date;
    recposition: number;
    ua_id: number;
    unternehmensgruppe: string;
    unternehmensgruppe_id: number;
}

@Component({
    selector: 'kiene-sonderpreis-dialog',
    templateUrl: './sonderpreis-dialog.component.html',
    styleUrls: ['./sonderpreis-dialog.component.scss']
})
export class SonderpreisDialogComponent implements OnInit {

    kundenArtikels: KundeArtikel[] = [];
    unternehmensgruppenArtikels: UnternehmensgruppeArtikel[] = [];

    constructor(public dialogRef: MatDialogRef<SonderpreisDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public artikel: Material,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.ladeKunden();
        this.ladeUnternehmensgruppen();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    private ladeKunden() {
        const params = new HttpParams().set('artikel_id', this.artikel.artikel_id.toString());
        this.api.getAll(this.apiBaseUrl + 'kunde_artikel/read.php', params).subscribe({
            next: response => {
                this.kundenArtikels = response.records;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private ladeUnternehmensgruppen() {
        const params = new HttpParams().set('artikel_id', this.artikel.artikel_id.toString());
        this.api.getAll(this.apiBaseUrl + 'unternehmensgruppe_artikel/read.php', params).subscribe({
            next: response => {
                this.unternehmensgruppenArtikels = response.records;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}
