import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Tierliste} from '../../gruppe-tier/classes/tierliste';
import {Client} from '../../client/classes/client';
import {API_BASE_URL_SERVICE} from '../../../kiene-core.config';
import {KieneBackendApiService} from '../../../services/backend-api/kiene-backend-api.service';
import {HttpParams} from '@angular/common/http';
import {MessageService} from '../../../services/message-service/message.service';
import {GruppeAnlegenDialogComponent} from '../../gruppen/gruppe-anlegen-dialog/gruppe-anlegen-dialog.component';
import {ImportOptionenDialogComponent} from '../../gruppen/import-optionen-dialog/import-optionen-dialog.component';
import {
    GruppeAuswaehlenDialogComponent
} from '../../gruppen/gruppe-auswaehlen-dialog/gruppe-auswaehlen-dialog.component';
import {Gruppe} from '../../gruppen/classes/gruppe';
import {AppService} from '../../../services/app/app.service';
import {GruppeAnlegenDialogInput} from '../../gruppen/gruppe-anlegen-dialog/gruppe-anlegen-dialog';
import {ClientService} from '../../client/client.service';
import {
    GruppeAuswaehlenDialogInput,
    GruppeAuswaehlenDialogResult
} from '../../gruppen/gruppe-auswaehlen-dialog/gruppe-auswaehlen-dialog';

@Component({
    selector: 'kiene-tierliste-importieren-frage-dialog',
    templateUrl: './tierliste-importieren-frage-dialog.component.html',
    styleUrls: ['./tierliste-importieren-frage-dialog.component.scss']
})
export class TierlisteImportierenFrageDialogComponent implements OnInit {
    radioCtrl = new FormControl(undefined, [Validators.required]);

    constructor(public dialogRef: MatDialogRef<TierlisteImportierenFrageDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public tierliste: Tierliste,
                private dialog: MatDialog,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private messageService: MessageService,
                private appService: AppService,
                private clientService: ClientService
    ) {
    }

    ngOnInit(): void {
        this.reloadTierliste();
    }

    importieren() {
        const option = this.radioCtrl.value;
        if (option === 0) {
            this.tierlisteInNeueGruppe();
        } else if (option === 1) {
            this.zuVorhandenerGruppeHinzufuegen();
        }
    }

    openImportOptionenDialog(gruppe: Gruppe) {
        this.tierliste.gruppe_id_einstallen = gruppe.gruppe_id;
        this.tierliste.status_id = 163;
        this.dialog.open(
            ImportOptionenDialogComponent,
            {
                minWidth: '100vw',
                width: '100%',
                data: {
                    tierliste: this.tierliste,
                },
            }
        ).afterClosed().subscribe({
            next: (tierliste) => {
                if (tierliste) {
                    this.api
                        .post(
                            this.apiBaseUrl +
                            'tierliste/update_kundenportal.php',
                            tierliste
                        )
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Die Tiere wurden erfolgreich der Gruppe hinzugefügt!'
                                );
                                this.dialogRef.close();
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    private tierlisteInNeueGruppe() {
        if (!this.tierliste?.kunde_id_einstallen) {
            this.messageService.alertMessage('Kein Zielbetrieb angegeben!');
            return;
        }

        const params = new HttpParams().set('kunde_id', this.tierliste.kunde_id_einstallen);
        this.api.get(this.apiBaseUrl + 'kunde/read_one.php', params).subscribe({
            next: (zielbetrieb: Client) => {
                this.clientService.pruefeZugriff(this.tierliste.kunde_id_einstallen, this.tierliste?.kunde_id_abgang).subscribe({
                    next: (zugriff: boolean) => {
                        const input = new GruppeAnlegenDialogInput(zielbetrieb, true, zugriff ? this.tierliste?.gruppe_abgang : undefined, this.tierliste?.datum_einstallen || this.tierliste?.datum_abgang);
                        this.dialog.open<GruppeAnlegenDialogComponent, GruppeAnlegenDialogInput, Gruppe>(GruppeAnlegenDialogComponent, {
                            data: input,
                            minWidth: this.appService.isMobileScreen ? '95vw' : undefined
                        }).afterClosed().subscribe({
                            next: (gruppe: Gruppe) => {
                                if (gruppe) {
                                    this.openImportOptionenDialog(gruppe);
                                }
                            },
                        });
                    },
                    error: err => {
                        this.messageService.errorMessage('Fehler!', err);
                    }
                });
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private zuVorhandenerGruppeHinzufuegen() {
        const input = new GruppeAuswaehlenDialogInput();
        input.kunde_id = this.tierliste.kunde_id_einstallen;
        this.dialog.open<GruppeAuswaehlenDialogComponent, GruppeAuswaehlenDialogInput, GruppeAuswaehlenDialogResult>(GruppeAuswaehlenDialogComponent, {
            minWidth: this.appService.isMobileScreen ? '350px' : '600px',
            data: input
        }).afterClosed().subscribe({
            next: (result: GruppeAuswaehlenDialogResult) => {
                if (result?.confirmed) {
                    this.openImportOptionenDialog(result?.gruppe);
                }
            }
        });
    }

    private reloadTierliste() {
        const params = new HttpParams().set('tierliste_id', this.tierliste?.tierliste_id);
        this.api.get(this.apiBaseUrl + 'tierliste/read_one_kundenportal.php', params).subscribe({
            next: response => {
                this.tierliste = response;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}
