<h1 mat-dialog-title>Rechnung {{invoice.rechnung_nr}} stornieren</h1>
<div mat-dialog-content>
    <p>Wollen Sie die Rechnung {{invoice.rechnung_nr}} wirklich stornieren?</p>
    <br>
    <mat-form-field appearance="fill">
        <mat-label>Datum der Gutschrift</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="gutschriftDatumCtrl">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()" [disabled]="!gutschriftDatumCtrl.valid">Stornieren</button>
</div>