<mat-card *kienePermission="[3411]">

  <h5>Rabattstaffeln</h5>
  <table>
    <thead style="font-size: .8em; color:#AAA">
      <tr>
        <th style="text-align: left">Ab</th>
        <th style="text-align: left;">Rabatt</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let rs of rabattstaffeln">
        <td>{{rs.artikelmenge_ab}} Stk.</td>
        <td>{{rs.rabatt}} %</td>
        <td><button mat-icon-button (click)="remove(rs)">
            <mat-icon>delete</mat-icon>
          </button></td>
      </tr>
      <tr>
        <td style="width: 60px;">
          <mat-form-field>
            <input matInput type="number" [formControl]="amountCtrl" placeholder="Menge">
          </mat-form-field>
        </td>
        <td style="width: 60px;">
          <mat-form-field>
            <input matInput type="number" [formControl]="rabattCtrl" placeholder="Rabatt in %">
          </mat-form-field>
        </td>
        <td>
          <button mat-icon-button (click)="save()" color="primary"
            [disabled]="material.artikel_id === null || material.artikel_id === undefined">
            <mat-icon>add_circle_outline</mat-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</mat-card>