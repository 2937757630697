<h1 mat-dialog-title>Geburt melden</h1>
<div mat-dialog-content>
    <p><strong> Gruppe: {{ input?.bezeichnung }}</strong></p>
    <mat-form-field style="width: 100%">
        <mat-label>Ohrmarke</mat-label>
        <span matPrefix style="padding-right: 6px;">DE</span>
        <input
            [formControl]="ohrmarkeCtrl"
            type="text"
            matInput
            [minLength]="10" [maxlength]="10" style="letter-spacing: 6px;"
        />
        <span matSuffix style="font-size: .8em;">{{ ohrmarkeCtrl?.value?.length }}/10</span>
        <mat-error *ngIf="ohrmarkeCtrl.hasError('required')">Ohrmarke ist erforderlich.</mat-error>
        <mat-error *ngIf="ohrmarkeCtrl.hasError('minlength') || ohrmarkeCtrl.hasError('maxlength')">
            Die Ohrmarke muss nach 'DE' aus 10 weiteren Ziffern bestehen.
        </mat-error>
    </mat-form-field>

    <mat-form-field style="width: 100%;">
        <mat-label>Geburtsdatum</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="geburtsdatumCtrl">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="geburtsdatumCtrl.hasError('required')">Geburtsdatum ist erforderlich.</mat-error>
        <mat-error *ngIf="geburtsdatumCtrl.hasError('dateInFuture')">Das Geburtsdatum darf nicht in der Zukunft liegen
        </mat-error>
    </mat-form-field>

    <kiene-autocomplete style="width: 100%"
                        #rasseAutocomplete
                        (elementSelected)="setSelectedRasse($event)"
                        [displayFields]="['kuerzel', 'name']"
                        [genericApiUrl]="url + 'rasse/read.php'"
                        [placeholder]="'Rasse'"
                        [separator]="' - '"
    >
    </kiene-autocomplete>

    <mat-form-field style="width: 100%">
        <mat-label>Geschlecht</mat-label>
        <mat-select [formControl]="geschlechtCtrl">
            <mat-option [value]="1">Männlich</mat-option>
            <mat-option [value]="2">Weiblich</mat-option>
        </mat-select>
        <mat-error *ngIf="geschlechtCtrl.hasError('required')">Geschlecht ist erforderlich.</mat-error>
    </mat-form-field>

    <mat-form-field style="width: 100%">
        <mat-label>Geburtsgewicht (optional)</mat-label>
        <input
            [formControl]="gewichtCtrl"
            type="number"
            matInput
            placeholder="Geburtsgewicht des Tieres (optional)"
        />
    </mat-form-field>

    <kiene-autocomplete style="width: 100%"
                        #ohrmarkeAutocomplete
                        (elementSelected)="setSelectedOhrmarkeMutter($event)"
                        [displayFields]="['ohrmarke']"
                        [genericApiUrl]="url + 'gruppe_tier/search.php'"
                        [placeholder]="'Ohrmarke der Mutter'"
                        [params]="params"
                        [required]="true"
    >
    </kiene-autocomplete>
    <div class="info-box">Die Geburt wird an HIT gemeldet.</div>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" [disabled]="disableSave()" (click)="save()">Melden</button>
</div>
