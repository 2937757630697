<div class="kiene-content-page">
    <mat-card>
        <h1 color="primary">Wareneingang</h1>

        <div>
            <h4>1. Material auswählen:
                <span *ngIf="newMaterial && newMaterial.bezeichnung" class="kiene-color">{{ newMaterial.bezeichnung
                    }}</span>
            </h4>

            <div class="one-row">
                <kiene-article-scan-autocomplete #materialAutocomplete (cleared)="clearInputs();"
                    (scannedEAN)="setProduktkennzeichnung($event)" (selectedMaterial)="isArticleInDatabase($event)"
                    [focus]="true" [placeholder]="'Material scannen oder manuell eintragen'"
                    style="width: inherit"></kiene-article-scan-autocomplete>
                <button (click)="openAddArticleDialog()" color="primary" mat-icon-button
                    style="padding-left:15px; padding-bottom: 58px">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <br>
            <mat-divider *ngIf="newMaterial?.bezeichnung"></mat-divider>
            <div *ngIf="newMaterial?.bezeichnung">
                <h4>2. Charge eingeben</h4>
                <mat-form-field style="min-width: 500px;">
                    <input [(ngModel)]="goodReceipt.charge" aria-label="Charge" matInput placeholder="Charge" required>
                </mat-form-field>
            </div>
            <br>
            <mat-divider *ngIf="newMaterial?.bezeichnung"></mat-divider>
            <div *ngIf="newMaterial?.bezeichnung">
                <h4>3. MHD eingeben</h4>
                <mat-form-field style="min-width: 500px;">
                    <input [(ngModel)]="goodReceipt.mhd" [matDatepicker]="picker" matInput placeholder="MHD" required>
                    <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <br>
        <mat-divider *ngIf="goodReceipt?.mhd"></mat-divider>
        <div *ngIf="goodReceipt?.mhd">
            <h4>4. Lagerplatz auswählen:
                <span *ngIf="selectedStoragePlace?.bezeichnung != ''" class="kiene-color">{{
                    selectedStoragePlace?.bezeichnung }}</span>
            </h4>
            <mat-chip-list [selectable]="true">
                <mat-chip (click)="setSelectedStoragePlace(sps)" *ngFor="let sps of storagePlaceSuggestions"
                    [selectable]="true" [selected]="isStoragePlaceSelected(sps)" color="primary">{{ sps?.bezeichnung }}
                </mat-chip>
                <button (click)="showStoragePlaceAutocomplete = !showStoragePlaceAutocomplete"
                    *ngIf="storagePlaceSuggestions?.length > 0" color="primary" mat-button>...anderer
                    Lagerplatz
                </button>
            </mat-chip-list>
            <br>
            <kiene-autocomplete (elementSelected)="setSelectedStoragePlace($event)"
                *ngIf="showStoragePlaceAutocomplete == true || storagePlaceSuggestions?.length == 0"
                [apiUrl]="'lagerplatz/'" [displayField]="'bezeichnung'"
                [placeholder]="'Lagerplatz suchen'"></kiene-autocomplete>
            <!--        <mat-form-field *ngIf="showStoragePlaceAutocomplete == true || storagePlaceSuggestions.length == 0" style="min-width: 500px;">-->
            <!--          <input matInput placeholder="Lagerplatz suchen" aria-label="Lagerplatz" [matAutocomplete]="storagePlaceAuto"-->
            <!--            [formControl]="storagePlaceFormCtrl">-->
            <!--          <mat-autocomplete #storagePlaceAuto="matAutocomplete" [displayWith]="storagePlaceMapper" (optionSelected)="setSelectedStoragePlace($event.option.value)">-->
            <!--            <mat-option *ngFor="let sp of storagePlaces | async" [value]="sp">-->
            <!--              <span>{{sp.bezeichnung}}</span>-->
            <!--            </mat-option>-->
            <!--          </mat-autocomplete>-->
            <!--        </mat-form-field>-->
        </div>

        <br>
        <mat-divider *ngIf="selectedStoragePlace?.bezeichnung"></mat-divider>
        <div *ngIf="selectedStoragePlace?.bezeichnung">
            <h4>5. Passende Bestellung auswählen</h4>
            <mat-chip-list>
                <mat-chip (click)="setSelectedOrder(po)" *ngFor="let po of possibleBestellungen" [selectable]="true"
                    [selected]="isOrderSelected(po)" color="primary" style="cursor: pointer;">
                    {{ po?.bestell_nr }} {{ '(' + po?.lieferant + ')' }} vom {{ po?.bestellt | date : 'dd.MM.yyyy' }}
                </mat-chip>
            </mat-chip-list>

        </div>

        <br>
        <mat-divider *ngIf="selectedStoragePlace?.bezeichnung"></mat-divider>
        <div *ngIf="selectedStoragePlace?.bezeichnung">
            <h4>6. Menge eingeben</h4>
            <mat-form-field style="min-width: 500px;">
                <input [(ngModel)]="goodReceipt.menge" aria-label="Menge" matInput placeholder="Menge" required
                    type="number">
            </mat-form-field>
        </div>


        <mat-card-actions>
            <div *kienePermission="[1652]">
                <button (click)="saveGoodReceipt()" *ngIf="goodReceipt?.menge" [disabled]="saveButtonDisabled"
                    color="primary" mat-raised-button>Speichern
                </button>
            </div>
        </mat-card-actions>
    </mat-card>
</div>