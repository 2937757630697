export class Mandant {
    mandant_id: number;
    bezeichnung: string;
    bezeichnung_kurz: string;
    strasse: string;
    plz: string;
    ort: string;
    vvvo: string;
    telefon: string;
    mandant_nr: string;
    ort_labor: string;
    primanota_beraternummer: string;
    primanota_mandantennummer: string;
    bestellung_extern_konditionen: string;
    bestellung_extern_rechnungsempfaenger: string;
    bestellung_extern_ort: string;
    bestellung_extern_ansprechpartner: string;
    bestellung_extern_telefon: string;
    bestellung_extern_email: string;
    bestellung_extern_lieferbedingungen: string;
    bestellung_extern_versandart: string;
    untersuchungsauftrag_emailempfaenger: string;
    rechnung_tierarzt_angeben: number;
    email_adresse: string;
    email_smtp: string;
    email_passwort: string;
    email_nutzer: string;
    email_port: number;
    email_salt: number;
    email_signatur: string;
    rechnung_email_adresse: string;
    rechnung_email_smtp: string;
    rechnung_email_passwort: string;
    rechnung_email_nutzer: string;
    rechnung_email_port: number;
    rechnung_email_salt: number;
    ust_id: string;
    steuernummer: string;
    name_bank: string;
    iban: string;
    bic: string;
    pdf_absender_ueber_anschrift: string;
    pdf_farbe: string;
    pdf_header_logo_links: string;
    pdf_header_logo_mitte: string;
    pdf_header_logo_rechts: string;
    pdf_footer_schriftgroesse: number;
    pdf_footer_spalte_1: string;
    pdf_footer_spalte_2: string;
    pdf_footer_spalte_3: string;
    pdf_footer_spalte_4: string;
    sortierung: number;
    tierarztwagen_allgemein: number;
    erstellt: string;
    erstellt_von: number;
    editiert: string;
    editiert_von: number
}
