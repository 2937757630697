import { Component, ElementRef, Inject, OnInit, ViewChild, } from '@angular/core';
import { Supplier } from '../../../warehouse/supplier/supplier';
import { HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpResponse, } from '@angular/common/http';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../services/message.service';
import { Material } from '../../../warehouse/material/material';
import { KieneAutocompleteComponent } from '../../../components/kiene-autocomplete/kiene-autocomplete.component';
import { Gutschrift } from '../gutschrift';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    ArticleScanSelectedEvent
} from '../../../components/article-scan-autocomplete/article-scan-autocomplete.component';

@Component({
    selector: 'kiene-add-gutschrift-dialog',
    templateUrl: './add-gutschrift-dialog.component.html',
    styleUrls: ['./add-gutschrift-dialog.component.scss'],
})
export class AddGutschriftDialogComponent implements OnInit {
    @ViewChild('fileSelector') fileSelector;

    @ViewChild('artikelAuto') artikelAuto: KieneAutocompleteComponent;
    @ViewChild('lieferantAuto') lieferantAuto: KieneAutocompleteComponent;

    @ViewChild('betragInput', { static: true }) betragInput: ElementRef;

    gutschrift: Gutschrift;

    apiUrl: string;

    lieferantFocus = false;
    betragFocus = false;

    filesToUpload: File[] = [];
    filesProgressValues: number[] = [];
    filesUploadStatus: number[] = [];
    form: UntypedFormGroup;
    showCloseButton: boolean = false;
    showUploadAndSelector: boolean = true;

    lieferant: Supplier;
    material: Material;
    gutschriftNrCtrl = new UntypedFormControl();
    betragCtrl = new FormControl(0, [Validators.min(0)]);
    mwstsatzCtrl = new UntypedFormControl(19);
    datumCtrl = new FormControl(new Date());

    artikelParams = new HttpParams().set('lieferant_id', '-1');

    constructor(
        public dialogRef: MatDialogRef<AddGutschriftDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private messageService: MessageService,
        private http: HttpClient
    ) {
        this.apiUrl = data.apiUrl;
        if (data.gutschrift) {
            this.gutschrift = data.gutschrift;
            this.betragFocus = true;
        } else {
            this.lieferantFocus = true;
            this.gutschrift = new Gutschrift();
            this.gutschrift.datum = new Date();
        }
        if (data.filesToUpload) {
            this.filesToUpload = data.filesToUpload;
        }
    }

    ngOnInit() {
        if (this.gutschrift) {
            if (this.gutschrift.lieferant_id) {
                let supplier = new Supplier();
                supplier.lieferant_id = this.gutschrift.lieferant_id;
                supplier.bezeichnung = this.gutschrift.lieferant;
                this.lieferant = supplier;
                this.artikelParams = new HttpParams().set(
                    'lieferant_id',
                    this.lieferant.lieferant_id.toString()
                );
                this.betragFocus = true;
                this.betragInput.nativeElement?.focus();
            }
            if (this.gutschrift.artikel_id) {
                let material = new Material();
                material.artikel_id = this.gutschrift.artikel_id;
                material.bezeichnung = this.gutschrift.artikel;
                this.material = material;
            }
            if (this.gutschrift.betrag) {
                this.betragCtrl.setValue(this.gutschrift.betrag);
            }
            this.gutschriftNrCtrl.setValue(this.gutschrift.gutschrift_nr);
            if (
                this.gutschrift.mwstsatz == 0 ||
                this.gutschrift.mwstsatz == 7 ||
                this.gutschrift.mwstsatz == 19
            ) {
                this.mwstsatzCtrl.setValue(this.gutschrift.mwstsatz);
            }
            if (this.gutschrift.datum) {
                this.datumCtrl.setValue(this.gutschrift.datum);
            }
        }
    }

    supplierSelected(s: Supplier) {
        this.lieferant = s;
        if (this.lieferant) {
            this.artikelParams = new HttpParams().set(
                'lieferant_id',
                this.lieferant.lieferant_id.toString()
            );
        } else {
            this.artikelAuto.clear();
            this.material = null;
        }
    }

    materialSelected(event: ArticleScanSelectedEvent) {
        this.material = event.article;
    }

    openFileSelector() {
        this.fileSelector.nativeElement.click();
    }

    handleFiles(event: any) {
        const files = event.target.files;
        this.filesToUpload.push(files.item(0));
        this.filesProgressValues[0] = 0;
    }

    isFileUploaded(file: number) {
        if (this.filesUploadStatus[file] == 1) {
            return true;
        } else {
            return false;
        }
    }

    uploadFilesToServer() {
        let fd = new FormData();

        let fileName = '';

        if (this.gutschrift && this.gutschrift.gutschrift_id) {
            fd.append('gutschrift_id', this.gutschrift.gutschrift_id.toString());
        }

        if (this.gutschrift && this.gutschrift.bestellung_extern_id) {
            fd.append(
                'bestellung_extern_id',
                this.gutschrift.bestellung_extern_id.toString()
            );
        }

        if (this.lieferant) {
            fd.append('lieferant_id', this.lieferant.lieferant_id.toString());
        }

        if (this.material) {
            fd.append('artikel_id', this.material.artikel_id.toString());
        }
        fd.append('betrag', this.betragCtrl.value.toString());
        fd.append('mwstsatz', this.mwstsatzCtrl.value.toString());
        fd.append('gutschrift_nr', this.gutschriftNrCtrl.value);
        let d: Date = new Date(this.datumCtrl.value);
        if (d) {
            fd.append('datum', d.toISOString());
        }

        for (var i = 0; i < this.filesToUpload.length; i++) {
            let fileNumber = i;
            let file = this.filesToUpload[fileNumber];

            fd.append('dokument', file);
            fileName = file.name;
        }
        let params = new HttpParams();

        const options = {
            headers: new HttpHeaders('Phito-Type: fileupload'),
            params: params,
            reportProgress: true,
        };

        // const request = new HttpRequest('POST', environment.BASE_URI + this.apiUrl, fd, options);
        this.http
            .post<any>(environment.BASE_URI + this.apiUrl, fd, options)
            .subscribe(
                (event) => {
                    console.debug('upload request event: %s', JSON.stringify(event));

                    if (event.type == HttpEventType.UploadProgress) {
                        let percentDone = Math.round((100 * event.loaded) / event.total);
                        this.filesProgressValues[0] = percentDone;
                        console.debug('Uploaded ' + percentDone + '% of file ' + fileName);
                    } else if (event instanceof HttpResponse) {
                        console.debug('Uploaded file ' + fileName + ' completely!');
                    }
                },
                (error) => {
                    console.error('Error while uploading file: ' + fileName);
                    this.filesUploadStatus[0] = 2;
                    this.messageService.errorMessage('Fehler', error);
                    this.markFileAsFailed(0);
                },
                () => {
                    if (this.filesToUpload.length > 0) {
                        this.markFileAsUploaded(0);
                        console.debug('Upload of file ' + fileName + ' done!');
                        this.messageService.infoMessage(
                            fileName + ' erfolgreich hochgeladen!'
                        );
                    }
                    this.close();
                }
            );
    }

    markFileAsFailed(no: number) {
        console.debug('markFileAsFailed: ' + no);
        this.filesUploadStatus[no] = 2;
        this.checkShowCloseButton();
    }

    markFileAsUploaded(no: number) {
        console.debug('markFileAsUploaded: ' + no);
        this.filesUploadStatus[no] = 1;
        this.checkShowCloseButton();
    }

    checkShowCloseButton() {
        var show: boolean = true;
        for (var i = 0; i < this.filesUploadStatus.length; i++) {
            if (this.filesUploadStatus[i] == 0) {
                show = false;
            }
        }
        if (show == true) {
            this.showCloseButton = true;
        }
    }

    save() {
        this.showUploadAndSelector = false;
        this.uploadFilesToServer();
    }

    close() {
        this.dialogRef.close();
    }
}
