<div style="width: 100%;">

    <h2>Kundeninformation</h2>
    <div class="one-row" style="list-style-type: none; background-color: white;">

        <kiene-key-value-list-item key="Name" icon="person" [value]="kunde?.bezeichnung"></kiene-key-value-list-item>
        <span class="spacer"></span>

        <kiene-key-value-list-item icon="location_on" key="Wohnort"
            [value]="kunde?.plz + ' ' + kunde?.ort"></kiene-key-value-list-item>
        <span class="spacer"></span>

        <kiene-key-value-list-item icon="location_city" key="Straße"
            [value]=" kunde?.strasse"></kiene-key-value-list-item>
        <span class="spacer"></span>
        <kiene-key-value-list-item icon="phone" key="Telefonnummer"
            [value]=" kunde?.telefon_1"></kiene-key-value-list-item>
        <span class="spacer"></span>
        <kiene-key-value-list-item icon="phone_iphone" key="Mobil"
            [value]=" kunde?.mobil_1"></kiene-key-value-list-item>
        <span class="spacer"></span>
        <kiene-key-value-list-item icon="email" key="E-Mail" [value]=" kunde?.email"></kiene-key-value-list-item>
        <span class="spacer"></span>

    </div>
</div>