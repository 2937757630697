import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatSelectChange} from '@angular/material/select';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Client} from '../../client/classes/client';
import {StammdatenService} from '../../stammdaten/stammdaten.service';
import {MessageService} from '../../../services/message-service/message.service';

@Component({
    selector: 'kiene-vvvo-option-select',
    templateUrl: './vvvo-option-select.component.html',
    styleUrls: ['./vvvo-option-select.component.scss']
})
export class VvvoOptionSelectComponent implements OnInit, OnDestroy {

    vvvoSubscription: Subscription;

    vvvoSelectCtrl: UntypedFormControl = new UntypedFormControl();
    kunden: Client[] = [];
    aktuelleVvvo: Client;
    alleVVVOs = new Client();

    @Input('showAllVvvoSelectOption')
    showAllVvvoSelectOption = true;

    @Output('selectedVvvo')
    selectedVvvo = new EventEmitter<Client>();

    formgroup = new UntypedFormGroup({
        vvvoSelectCtrl: this.vvvoSelectCtrl
    });

    constructor(
        private stammdatenService: StammdatenService,
        private messageService: MessageService
    ) {
    }

    ngOnDestroy(): void {
        this.vvvoSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.alleVVVOs.kunde_id = -1;
        this.alleVVVOs.bezeichnung_final = 'Alle Betriebe';

        this.aktuelleVvvo = this.stammdatenService.getAusgewaehlteVvvo();

        if (this.aktuelleVvvo) {
            this.vvvoSelectCtrl.setValue(this.aktuelleVvvo);
        } else {
            this.vvvoSelectCtrl.setValue(this.alleVVVOs);
        }

        //   this.stammdatenService.watchAusgewaehlteVvvo().subscribe({
        //       next: (vvvo) => {
        //           this.aktuelleVvvo = vvvo;

        //           if (vvvo) {
        //               this.vvvoSelectCtrl.setValue(this.aktuelleVvvo);
        //           } else {
        //               this.vvvoSelectCtrl.setValue(this.alleVVVOs);
        //           }

        //           this.emitSelection();
        //       }
        //   });

        this.loadVvvos();
    }

    loadVvvos(emit: boolean = true) {
        this.vvvoSubscription?.unsubscribe();
        this.vvvoSubscription = this.stammdatenService.ladeVvvos().subscribe({
            next: (data) => {
                this.kunden = data.records;
                if (emit) {
                    this.emitSelection();
                }
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Laden der VVVO-Optionen', err);
            }
        });
    }

    emitSelection($event?: MatSelectChange) {

        if ($event) {
            this.aktuelleVvvo = $event.value.kunde_id === -1 ? null : $event.value;
        }
        this.selectedVvvo.emit(this.aktuelleVvvo);
    }

    compareVvvos(a: Client, b: Client) {
        return a && b && a.kunde_id === b.kunde_id;
    }
}
