<div style="padding: 24px; margin-top: 64px;">
    <h2>Release Notes</h2>
    <mat-card>
        <div *ngFor="let version of versioninfo">
            <h3>V{{version.version}}</h3>
            <h5>{{version.date}}</h5>
            <ul>
                <li *ngFor="let item of version.items">{{item}}</li>
            </ul>
        </div>
    </mat-card>
</div>
