import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Material} from '../../material/material';
import {SupplierMaterial} from '../supplier-material';
import {UntypedFormControl} from '@angular/forms';
import {Supplier} from '../../supplier/supplier';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {environment} from '../../../../environments/environment';
import {MessageService} from '../../../services/message.service';
import {HttpParams} from '@angular/common/http';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {Mandant} from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'kiene-add-supplier-material',
    templateUrl: './add-supplier-material.component.html',
    styleUrls: ['./add-supplier-material.component.scss']
})
export class AddSupplierMaterialComponent implements OnInit {

    material: Material;
    supplierMaterial: SupplierMaterial;
    create = false;

    preisCtrl = new UntypedFormControl();
    //skontoCtrl = new FormControl();
    //zahlungszielCtrl = new FormControl();
    rabattCtrl = new UntypedFormControl();
    standardCtrl = new UntypedFormControl();

    availableMandanten: Mandant[] = [];

    mandantCtrl: UntypedFormControl[] = [];
    selectedMandantenIds: number[] = [];

    constructor(public dialogRef: MatDialogRef<AddSupplierMaterialComponent>, @Inject(MAT_DIALOG_DATA) private data,
                private api: KieneTableService,
                private notification: MessageService,
                private localStorageService: LocalStorageService) {
        this.material = data.material;
        this.supplierMaterial = data.supplierMaterial;
        if (data.create) {
            this.create = data.create
        }
    }

    ngOnInit() {
        this.loadAvailableMandanten(this.supplierMaterial);

        if (this.supplierMaterial) {
            this.preisCtrl.setValue(this.supplierMaterial.einzelpreis);
            //this.skontoCtrl.setValue(this.supplierMaterial.skonto);
            //this.zahlungszielCtrl.setValue(this.supplierMaterial.zahlungsziel);
            this.rabattCtrl.setValue(this.supplierMaterial.rabatt);
            this.standardCtrl.setValue(this.supplierMaterial.standard_lieferant === 1 ? true : false);
        } else {

        }
    }

    loadAvailableMandanten(sm: SupplierMaterial) {
        if (this.localStorageService.currentUserHasPermission(1095)) {
            if (sm) {
                let params = new HttpParams();
                params = params.append('artikel_id', sm.artikel_id.toString());
                params = params.append('lieferant_id', sm.lieferant_id.toString());
                this.api.get(environment.BASE_URI + 'artikel_lieferant/read_mandanten.php', params).subscribe(response => {
                    this.availableMandanten = response.records;
                    for (let m of this.availableMandanten) {
                        this.mandantCtrl[m.mandant_id] = new UntypedFormControl(true);
                    }
                    this.updateSelectedMandanten();
                }, error => {
                    this.notification.errorMessage('Fehler beim Laden der Mandanten', error);
                });
            }
        }
    }

    updateSelectedMandanten() {
        this.selectedMandantenIds = [];
        for (let m of this.availableMandanten) {
            if (this.mandantCtrl[m.mandant_id].value) {
                this.selectedMandantenIds.push(m.mandant_id);
            }
        }
    }

    setSupplier(supplier: Supplier) {
        if (!this.supplierMaterial) {
            this.supplierMaterial = new SupplierMaterial();
        }
        this.supplierMaterial.lieferant_id = supplier.lieferant_id;
        this.supplierMaterial.artikel_id = this.material.artikel_id;
        this.supplierMaterial.lieferant = supplier.bezeichnung;

        this.loadAvailableMandanten(this.supplierMaterial);
    }

    close() {
        this.dialogRef.close(null);
    }

    save() {
        if (this.create && !this.supplierMaterial) {
            this.supplierMaterial = new SupplierMaterial();
        }

        if (this.localStorageService.currentUserHasPermission(1095)) {
            this.supplierMaterial.mandanten_ids = this.selectedMandantenIds;
        }

        this.supplierMaterial.artikel_id = this.material.artikel_id;
        this.supplierMaterial.einzelpreis = this.preisCtrl.value;
        //this.supplierMaterial.skonto = this.skontoCtrl.value;
        //this.supplierMaterial.zahlungsziel = this.zahlungszielCtrl.value;
        this.supplierMaterial.rabatt = this.rabattCtrl.value;
        this.supplierMaterial.standard_lieferant = this.standardCtrl.value ? 1 : 0;

        this.dialogRef.close(this.supplierMaterial);
    }

}
