import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTabChangeEvent} from '@angular/material/tabs';

@Component({
  selector: 'kiene-bestandsbesuchsprotokoll-uebersicht',
  templateUrl: './bestandsbesuchsprotokoll-uebersicht.component.html',
  styleUrls: ['./bestandsbesuchsprotokoll-uebersicht.component.scss']
})
export class BestandsbesuchsprotokollUebersichtComponent implements OnInit {

    selectedTab = 0;

    constructor(private router: Router,
                private route: ActivatedRoute) {
        this.route.queryParams.subscribe(params => {
            const tabName = params['tab'];
            if (tabName) {
                if (tabName === 'bestandsbesuchsprotokoll') {
                    this.selectedTab = 0;
                } else if (tabName === 'tierwohlprotokoll-rind') {
                    this.selectedTab = 1;
                }  else if (tabName === 'tierwohlprotokoll-schwein') {
                    this.selectedTab = 2;
                }
            }
        });
    }

    ngOnInit() {
    }

    setSelectedTab(event: MatTabChangeEvent) {
        const id = event.index;
        this.selectedTab = id;
        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {tab: this.getTabName(id)},
                queryParamsHandling: 'merge'
            }).then();
    }

    getTabName(id: number) {
        if (id === 0) {
            return 'bestandsbesuchsprotokoll';
        } else if (id === 1) {
            return 'tierwohlprotokoll-rind';
        } else {
            return 'tierwohlprotokoll-schwein';
        }
    }
}
