import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiagnoseSelectorComponent } from './diagnose-selector/diagnose-selector.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';



@NgModule({
  declarations: [DiagnoseSelectorComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatSelectModule
  ],
  exports:[
    DiagnoseSelectorComponent
  ]
})
export class DiagnoseSelectorModule { }
