<h1 mat-dialog-title>Kunde {{ kunde?.kunde_id ? 'editieren' : 'anlegen' }}</h1>

<div mat-dialog-content>
    <div class="one-row">

        <div style="width: 100%">
            <mat-form-field style="width: 100%;">
                <mat-label>Name</mat-label>
                <input matInput type="text" [formControl]="nameCtrl" style="width: 100%;">
            </mat-form-field>

            <div class="one-row">
                <mat-form-field style="width: 48%;">
                    <mat-label>Postleitzahl</mat-label>
                    <input matInput type="text" [formControl]="plzCtrl" style="width: 100%;">
                </mat-form-field>
                <span class="spacer"></span>
                <mat-form-field style="width: 48%;">
                    <mat-label>Wohnort</mat-label>
                    <input matInput type="text" [formControl]="ortCtrl" style="width: 100%;">
                </mat-form-field>
            </div>

            <mat-form-field style="width: 100%;">
                <mat-label>Straße</mat-label>
                <input matInput type="text" [formControl]="strasseCtrl" style="width: 100%;">
            </mat-form-field>

            <div class="one-row">
                <mat-form-field style="width: 48%;">
                    <mat-label>Telefon</mat-label>
                    <input matInput type="text" [formControl]="phoneCtrl" style="width: 100%;">
                </mat-form-field>
                <span class="spacer"></span>
                <mat-form-field style="width: 48%;">
                    <mat-label>Mobil</mat-label>
                    <input matInput type="text" [formControl]="mobilCtrl" style="width: 100%;">
                </mat-form-field>
            </div>
            <mat-form-field style="width: 100%;">
                <mat-label>E-Mail</mat-label>
                <input matInput type="text" [formControl]="mailCtrl" style="width: 100%;">
            </mat-form-field>
            <mat-form-field style="width: 100%;">
                <mat-label>Kommentar</mat-label>
                <input matInput [formControl]="kommentarCtrl" style="width: 100%;">
            </mat-form-field>
        </div>
        <div style="align-self: flex-start; padding-left: 24px;">
            <h3>Tiere</h3>
            <table class="phito-table" style="min-width: 600px;">
                <thead>
                <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Tierart</th>
                    <th>Rasse</th>
                    <th>Geschlecht</th>
                    <th>Geburtsdatum</th>
                    <th>Fellfarbe</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let tier of tiere">
                    <td></td>
                    <td>{{ tier.name }}</td>
                    <td>{{ tier.tierart }}</td>
                    <td>{{ tier.tierrasse }}</td>
                    <td>{{ tier.geschlecht }}</td>
                    <td>{{ tier.geburtsdatum | date: 'dd.MM.yyyy' }}</td>
                    <td>{{ tier.fellfarbe }}</td>
                    <td>
                        <button *kienePermission="[7022,7024]" mat-icon-button [matMenuTriggerFor]="tierMenu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #tierMenu="matMenu">
                            <button *kienePermission="[7022]" mat-menu-item (click)="editTier(tier)">
                                <mat-icon>edit</mat-icon>
                                <span>Bearbeiten</span>
                            </button>
                            <button *kienePermission="[7024]" mat-menu-item (click)="deleteTier(tier)">
                                <mat-icon>delete</mat-icon>
                                <span>L&ouml;schen</span>
                            </button>
                        </mat-menu>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div align="end" mat-dialog-actions>
    <button (click)="dialogRef.close()" mat-button>Abbrechen</button>
    <button *kienePermission="[7002]" [disabled]="isDisabled()" (click)="save()" color="primary" mat-flat-button>
        {{ kunde?.kunde_id ? 'Speichern' : 'Anlegen' }}
    </button>
</div>
