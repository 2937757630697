import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import {ChartSelectionChangedEvent, ChartType} from 'angular-google-charts';
import {
    GruppenDashboard,
    GruppenDashboardStall,
    GruppenDashboardStallGruppe,
} from '../classes/gruppen-dashboard';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {Gruppe} from '../../../documents/gruppenverwaltung/gruppe';
import * as moment from 'moment';
import {MatDialog} from '@angular/material/dialog';
import {
    GruppenDashboardSelectActionDialogComponent
} from './gruppen-dashboard-select-action-dialog/gruppen-dashboard-select-action-dialog.component';

@Component({
    selector: 'kiene-gruppen-dashboard',
    templateUrl: './gruppen-dashboard.component.html',
    styleUrls: ['./gruppen-dashboard.component.scss'],
})
export class GruppenDashboardComponent implements OnInit, AfterViewInit {
    @ViewChild('chartParent', {static: true}) chartParent: ElementRef;
    chartWidth = 1800;
    lookupGroup = [];
    colors: string[] = [
        '#e6194b',
        '#3cb44b',
        '#ffe119',
        '#4363d8',
        '#f55f31',
        '#911eb4',
        '#49bdbd',
        '#bcf60c',
        '#ff8d00',
        '#000075',
        '#008080',
        '#f0327b',
        '#e6beff',
        '#fabebe',
        '#fffac8',
        '#800000',
        '#aaffc3',
        '#808000',
        '#ffd8b1',
        '#808080',
        '#000000',
    ];
    colorGroupMap = {};
    colorIndex = 0;

    timelineType = ChartType.Timeline;
    chartData: any[];
    chartColumns: any[] = [
        {type: 'string', id: 'Stall'},
        {type: 'string', id: 'Gruppe'},
        {type: 'string', id: 'style', role: 'style'},
        {type: 'string', role: 'tooltip', p: {html: true}},
        {type: 'date', id: 'Start'},
        {type: 'date', id: 'Ende'},
    ];
    gruppenStaelle: GruppenDashboard[] = [];

    options = {
        width: 1800,
        height: 600,
        focusTarget: 'category',
        tooltip: {isHtml: true},
        legend: 'none',
        timeline: {
            rowLabelStyle: {fontSize: 18},
            barLabelStyle: {fontSize: 18},
        },
    };

    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private localStorageService: LocalStorageService,
        private router: Router,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.loadData();

        this.localStorageService.watchCurrentClient().subscribe(() => {
            this.chartData = undefined;
            this.loadData();
        });
    }

    ngAfterViewInit() {
        this.chartWidth = this.chartParent.nativeElement.offsetWidth;
        console.log('ChartWidth: %d', this.chartWidth);
        this.options.width = this.chartWidth;
        this.options.height = this.chartParent.nativeElement.offsetHeight;
    }

    private loadData() {
        let params = new HttpParams();
        const client = this.localStorageService.getCurrentClient();
        if (client) {
            params = params.set('kunde_id', client.kunde_id);
        }
        this.api
            .get(this.apiBaseUrl + 'gruppe/read_dashboard.php', params)
            .subscribe({
                next: (response) => {
                    this.gruppenStaelle = response;
                    this.setUpColors();
                    this.formatChartData();
                },
                error: () => {
                },
            });
    }

    private gibStallnamen(gs: GruppenDashboard, g: GruppenDashboardStall) {
        let s = '';
        if (gs.zusatz) {
            s = gs.zusatz;
        } else if (gs.bezeichnung) {
            s = gs.bezeichnung;
        }
        if (g.stallname) {
            if (s.length > 0) {
                s += ' - ';
            }
            s += g.stallname;
        }
        return s;
    }

    private getNextColorByGroupId(gruppe_id: number) {
        return this.colorGroupMap[gruppe_id];
    }

    private formatChartData() {
        this.chartData = [];
        for (const gs of this.gruppenStaelle) {
            for (const g of gs.staelle) {
                for (const gruppe of g.gruppen) {
                    this.chartData.push([
                        this.gibStallnamen(gs, g),
                        gruppe.bezeichnung +
                        ' (Alter: ' +
                        gruppe.alter_wochen +
                        'W, ' +
                        gruppe.anzahl_tiere_aktuell +
                        ' Tiere)',
                        this.getNextColorByGroupId(gruppe.gruppe_id),
                        this.createHtmlTooltip(gs, gruppe),
                        new Date(gruppe.von),
                        new Date(gruppe.bis_chart),
                    ]);
                    this.lookupGroup.push(gruppe);
                }
            }
        }
    }

    createHtmlTooltip(gd: GruppenDashboard, g: GruppenDashboardStallGruppe) {
        let s = `<div style="padding: 5px;" class="hoverable">
            <h3>${g.bezeichnung}</h3>
            <p>Anzahl Tiere aktuell: ${g.anzahl_tiere_aktuell}</p>
            <p>Einstalldatum: ${new Date(g.von).toLocaleDateString()}</p>
            <p>Tage im Stall: ${moment(new Date(Date.now()))
            .diff(new Date(g.von), 'days')
            .toString()} T</p>
            <p>Alter Wochen: ${g.alter_wochen}</p>`;
        if (g.bis) {
            s += `<p>Ausstalldatum: ${new Date(
                g.bis
            ).toLocaleDateString()}</p>`;
        }
        s += `<p>${gd.bezeichnung ? gd.bezeichnung : ''} ${
            gd.bezeichnung && gd.zusatz ? ' - ' : ''
        } ${gd?.zusatz ? gd.zusatz : ''}</p>
            <p>Standort: ${g.stall ? g.stall : '---'}</p>
            <p>VVVO: ${gd.vvvo ? gd.vvvo : '---'}</p>
        </div>`;
        return s;
    }

    select(event: ChartSelectionChangedEvent) {
        const index = event.selection[0].row;
        const gruppe: Gruppe = this.lookupGroup[index];
        this.dialog.open(GruppenDashboardSelectActionDialogComponent, {
            minWidth: '25vw',
            data: gruppe,
        });
    }

    private getNextFreeColorEntry() {
        const index = this.colorIndex % this.colors.length;
        this.colorIndex++;
        return index;
    }

    private setUpColors() {
        this.colorGroupMap = {};
        this.colorIndex = 0;
        this.gruppenStaelle.forEach((gs) => {
            gs.staelle.forEach((stall) => {
                stall.gruppen.forEach((gruppe) => {
                    const colorEintrag = this.colorGroupMap[gruppe.gruppe_id];
                    if (!colorEintrag) {
                        this.colorGroupMap[gruppe.gruppe_id] =
                            this.colors[this.getNextFreeColorEntry()];
                    }
                });
            });
        });
    }
}
