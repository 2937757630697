import { Inject, Injectable } from '@angular/core';
import {
    KieneBackendApiService
} from '../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { HitLoginDetails, HitLoginResponse } from './register.component';
import { API_BASE_URL_SERVICE } from '../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable({
    providedIn: 'root'
})
export class RegisterService {

    constructor(private api: KieneBackendApiService, @Inject(API_BASE_URL_SERVICE) private baseUrl: string) { }

    hitLogin(hitLoginDetails: HitLoginDetails) {
        return this.api.post(`${this.baseUrl}_qs_hit/hit_betriebsdaten.php`, hitLoginDetails, null);
    }

    loadBetriebsarten() {
        return this.api.getAll(
            `${this.baseUrl}betriebsart/read_kundenportal.php`
        );
    }

    register(hitLoginResponse: HitLoginResponse, passwort: string, passwortWdh: string, pin: string) {

        const dto: any = hitLoginResponse;
        dto['passwort'] = passwort;
        dto['passwortWdh'] = passwortWdh;
        dto['pin'] = pin;
        dto['platform'] = 'desktop';

        return this.api.post(`${this.baseUrl}kundenportal/registrieren.php`, dto, null);
    }
}
