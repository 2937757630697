import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CompanyGroupService } from "../company-group.service";
import { CompanyGroup } from "../company-group";
import { MessageService } from "../../../services/message.service";
import { Verkaufspreis } from "../../../components/verkaufspreis-selector/verkaufspreis-selector.component";

@Component({
    selector: 'kiene-preisliste-pdf-download-dialog',
    templateUrl: './preisliste-pdf-download-dialog.component.html',
    styleUrls: ['./preisliste-pdf-download-dialog.component.scss']
})
export class PreislistePdfDownloadDialogComponent {

    unternehmensgruppe: CompanyGroup;
    verkaufspreise: Verkaufspreis[];
    selectedVerkaufspreisId: number;

    constructor(private companyGroupService: CompanyGroupService,
        public dialogRef: MatDialogRef<PreislistePdfDownloadDialogComponent>,
        private messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.unternehmensgruppe) {
            this.unternehmensgruppe = data.unternehmensgruppe;
            this.loadVerkaufspreise();
        }
    }

    private loadVerkaufspreise() {
        this.companyGroupService.getVerkaufspreise().subscribe({
            next: request => {
                this.verkaufspreise = request.records;
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Laden der Verkaufspreise!', err);
            }
        });
    }

    download() {
        this.dialogRef.close({ unternehmensgruppe_id: this.unternehmensgruppe.unternehmensgruppe_id, verkaufspreis_id: this.selectedVerkaufspreisId });
    }
}
