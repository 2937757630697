<div style="margin: 24px 0">

    <div class="one-row" style="padding-bottom: 24px; border-bottom: 1px solid lightgray;">
        <mat-card style="width: 45%; min-width: 400px; min-height: 160px; margin-left: 12px; align-self: flex-start;">
            <h3>Meine Urlaubstage f&uuml;r {{ aktuellesJahr }}: </h3>
            <table class="urlaubstage-tabelle">
                <thead>
                <tr>
                    <th>Insgesamt</th>
                    <th>Beantragt</th>
                    <th>Genehmigt</th>
                    <th>Verbleibend</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td><span class="urlaubstage gesamt">{{ currentUser.urlaub_pro_jahr }}</span></td>
                    <td><span class="urlaubstage beantragt">{{ meineBeantragtenUrlaubstageDiesesJahr }}</span></td>
                    <td><span class="urlaubstage genehmigt">{{ meineGenehmigtenUrlaubstageDiesesJahr }}</span></td>
                    <td><span
                        class="urlaubstage gesamt">{{ currentUser.urlaub_pro_jahr - (meineGenehmigtenUrlaubstageDiesesJahr + meineBeantragtenUrlaubstageDiesesJahr) }}</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </mat-card>
        <span class="spacer"></span>
        <mat-card style="width: 45%; min-width: 400px; min-height: 160px; margin-right: 12px; align-self: flex-start;">
            <kiene-wochenenddienstanzeige (change)="filter()"></kiene-wochenenddienstanzeige>
        </mat-card>
    </div>
</div>
<div class="one-row" style="margin: 24px 0">
    <div
        style="display: flex; align-items: center; justify-content: flex-start"
    >

        <button
            (click)="urlaubsantragStellen()"
            *kienePermission="[
                kalenderService.kalenderRechte.antragAnlegenEditieren
            ]"
            color="accent"
            mat-flat-button
            style="margin-right: 12px"
        >
            Urlaubsantrag stellen
        </button>
        <button
            (click)="urlaubsantragGenehmigen()"
            *kienePermission="[kalenderService.kalenderRechte.urlaubGenehmigen]"
            [matBadgeHidden]="offeneUrlaubsantraege?.length <= 0"
            [matBadge]="offeneUrlaubsantraege?.length"
            color="accent"
            mat-flat-button
            matBadgeColor="primary"
            style="margin-right: 12px"
        >
            Urlaubsantrag genehmigen
        </button>
        <button
            (click)="krankheitMelden()"
            *kienePermission="[kalenderService.kalenderRechte.krankheitMelden]"
            color="accent"
            mat-flat-button
            style="margin-right: 12px"
        >
            Abwesenheitsmeldung
        </button>

    </div>
    <div style="display: flex; align-items: center; justify-content: flex-end">
        <!--        <div-->
        <!--            [style.background-color]="colors.wochenendDienstFeiertag"-->
        <!--            style="-->
        <!--                margin: 0 6px 0 12px;-->
        <!--                width: 12px;-->
        <!--                height: 12px;-->
        <!--                border-radius: 50%;-->
        <!--            "-->
        <!--        ></div>-->
        <!--        <span>Wochenend-/Feiertagsdienst</span>-->

        <div
            [style.background-color]="colors.Urlaub"
            style="
                margin: 0 6px 0 12px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
            "
        ></div>
        <span>Urlaub genehmigt</span>

        <div
            [style.background-color]="colors.Krankheit"
            style="
                margin: 0 6px 0 12px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
            "
        ></div>
        <span>Abwesenheit</span>

        <div
            [style.background-color]="colors.offen"
            style="
                margin: 0 6px 0 12px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
            "
        ></div>
        <span>Urlaubsantrag</span>
    </div>
</div>

<div class="one-row">
    <div
        *kienePermission="[
            this.kalenderService.kalenderRechte.kalenderAnzeigen
        ]"
        class="one-row" style="margin-top: 12px;">
        <mat-form-field
            class="no-padding-margin-mat-form-field"
            style="width: 20%; padding-right: 12px"
        >
            <mat-label>Zeitraum</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input
                    [formControl]="startCtrl"
                    matStartDate
                    placeholder="Startmonat"
                />
                <input
                    [formControl]="endCtrl"
                    matEndDate
                    placeholder="Endmonat"
                />
            </mat-date-range-input>
            <mat-datepicker-toggle
                [for]="picker"
                matSuffix
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>

        <mat-form-field
            class="no-padding-margin-mat-form-field"
            style="width: 20%; padding-right: 12px"
        >
            <mat-label>Gruppen</mat-label>
            <mat-select [formControl]="gruppenCtrl" multiple>
                <mat-option *ngFor="let gruppe of gruppen" [value]="gruppe">{{
                        gruppe.bezeichnung
                    }}
                </mat-option>
            </mat-select>
            <button
                (click)="resetFilter($event, gruppenCtrl)"
                *ngIf="isAllowed"
                mat-icon-button
                matSuffix
                matTooltip="Filter zurücksetzen"
            >
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field
            class="no-padding-margin-mat-form-field"
            style="width: 20%; padding-right: 12px"
        >
            <mat-label>Benutzer</mat-label>
            <mat-select [formControl]="benutzerCtrl" multiple>
                <mat-option *ngFor="let b of benutzer" [value]="b"
                >{{ b.vorname }} {{ b.nachname }}
                </mat-option
                >
            </mat-select>
            <button
                (click)="resetFilter($event, benutzerCtrl)"
                *ngIf="isAllowed"
                mat-icon-button
                matSuffix
                matTooltip="Filter zurücksetzen"
            >
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field
            class="no-padding-margin-mat-form-field"
            style="width: 20%; padding-right: 12px"
        >
            <mat-label>Benutzerrollen</mat-label>
            <mat-select [formControl]="benutzerrollenCtrl" multiple>
                <mat-option *ngFor="let br of benutzerrollen" [value]="br">{{
                        br.bezeichnung
                    }}
                </mat-option>
            </mat-select>
            <button
                (click)="resetFilter($event, benutzerrollenCtrl)"
                *ngIf="isAllowed"
                mat-icon-button
                matSuffix
                matTooltip="Filter zurücksetzen"
            >
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>

        <div
            style="
                width: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
            "
        >
            <button
                (click)="filter()"
                color="accent"
                mat-flat-button
                style="padding-right: 12px"
            >
                Filter anwenden
            </button>
        </div>
    </div>
</div>


<kiene-urlaubskalender-multi-month
    #multiMonthCalendar
    (eintragSelected)="handleEventClick($event)"
    [queryParams]="queryParams"
>
</kiene-urlaubskalender-multi-month>
