<h3 mat-dialog-title>Lagerplatz anlegen</h3>
<mat-dialog-content>
  <mat-form-field style="width: 100%;"> 
    <mat-select placeholder="Lager auswählen" [formControl]="storageCtrl" required>
      <mat-option *ngFor="let s of storages" [value]="s">{{s.bezeichnung}}</mat-option>
    </mat-select>
  </mat-form-field>
    <mat-form-field style="width: 100%;">
      <input matInput type="text" placeholder="Lagerplatz Name" [formControl]="nameCtrl" required>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
    <button mat-raised-button color="primary" (click)="create()" [disabled]="storageCtrl.invalid || nameCtrl.invalid">Anlegen</button>
</mat-dialog-actions>