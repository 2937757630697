import {HttpParams} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../../environments/environment';
import {AppService} from '../../../../app.service';
import {KieneTableService} from '../../../../components/kiene-table/kiene-table.service';
import {MessageService} from '../../../../services/message.service';
import {Gruppe} from '../../gruppe';
import {Client} from '../../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {
    LocalStorageService
} from '../../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-gruppe-edit-dialog',
    templateUrl: './gruppe-edit-dialog.component.html',
    styleUrls: ['./gruppe-edit-dialog.component.css'],
})
export class GruppeEditDialogComponent implements OnInit {
    gruppe = new Gruppe();

    // anzahl tiere entweder durch csv hochgeladen oder ueber HIT gemeldet
    anzahlTiereOhrmarke = 0;

    nameCtrl = new UntypedFormControl();
    standortParams = new HttpParams();
    standort = '';
    client: Client;

    aufstalleinheitJahrCtrl = new UntypedFormControl();
    aufstalleinheitNummerCtrl = new UntypedFormControl();

    // lieferantenVVVOCtrl = new FormControl();
    einstalldatumVonCtrl = new UntypedFormControl();
    einstalldatumBisCtrl = new UntypedFormControl();
    anzahlTiereCtrl = new UntypedFormControl();
    alterEinstallungCtrl = new UntypedFormControl();

    constructor(
        public dialogRef: MatDialogRef<GruppeEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private api: KieneTableService,
        private appService: AppService,
        private messageService: MessageService,
        public lss: LocalStorageService,
    ) {
        if (this.data && this.data.gruppe) {
            this.gruppe = this.data.gruppe;
            this.standortParams = this.standortParams.set(
                'kunde_id',
                this.gruppe.kunde_id.toString()
            );
        }
    }

    ngOnInit(): void {
        this.loadClient();

        this.nameCtrl.setValue(this.gruppe.bezeichnung);

        this.standort = this.gruppe.stall;
        // this.lieferantenVVVOCtrl.setValue(this.gruppe.lieferant_vvvo);
        this.einstalldatumVonCtrl.setValue(this.gruppe.einstalldatum_von);
        this.einstalldatumBisCtrl.setValue(this.gruppe.einstalldatum_bis);
        this.anzahlTiereCtrl.setValue(this.gruppe.anzahl_tiere);
        this.alterEinstallungCtrl.setValue(this.gruppe.alter_bei_einstallung);

    }

    close() {
        this.dialogRef.close(null);
    }

    setStandort(standort: any) {
        if (typeof standort === 'string') {
            this.standort = standort;
        } else {
            this.standort = standort.stall;
        }
    }

    splitAufstalleinheit() {
        if (this.gruppe && this.gruppe.herdenname) {
            if (this.client.kaelbermast) {
                const parts = this.gruppe.herdenname.split('-AE-');
                if (parts && parts.length === 2) {
                    this.aufstalleinheitJahrCtrl.setValue(parts[0]);
                    this.aufstalleinheitNummerCtrl.setValue(parts[1]);
                }
            }
        }
    }

    loadClient() {
        if (this.gruppe.kunde_id) {
            this.api
                .getOne(
                    environment.BASE_URI + 'kunde/read_one.php',
                    'kunde_id',
                    this.gruppe.kunde_id.toString()
                )
                .subscribe((response) => {
                    this.client = <Client>response;
                    if (this.client.ohrmarke) {
                        this.loadTiere();
                    }
                    this.splitAufstalleinheit();
                });
        }
    }

    loadTiere() {
        this.appService.setDataLoading(true);
        let params = new HttpParams().set(
            'gruppe_id',
            this.gruppe.gruppe_id.toString()
        );
        params = params.set('orderby', 'ohrmarke asc');
        params = params.set('limit', '1');
        params = params.set('offset', '0');

        this.api.get(environment.BASE_URI + 'gruppe_tier/read.php', params).subscribe(
            (response) => {
                this.anzahlTiereOhrmarke = response.count;
                this.appService.setDataLoading(false);
            },
            (error) => {
                this.appService.setDataLoading(false);
                this.messageService.errorMessage(
                    'Die einzelnen Tiere für die Gruppe ' +
                    this.gruppe.bezeichnung +
                    ' konnten nicht geladen werden!',
                    error
                );
            });
    }

    save() {
        this.gruppe.bezeichnung = this.nameCtrl.value;
        this.gruppe.stall = this.standort;
        this.gruppe.einstalldatum_von = this.einstalldatumVonCtrl.value;
        this.gruppe.einstalldatum_bis = this.einstalldatumBisCtrl.value;

        if (this.client.kaelbermast) {
            this.gruppe.aufstalleinheit_jahr = this.aufstalleinheitJahrCtrl.value;
            this.gruppe.aufstalleinheit_nummer = this.aufstalleinheitNummerCtrl.value;
        }

        if (
            !this.client.tierbewegung_melden ||
            (this.client.tierbewegung_melden &&
                (!this.client.ohrmarke ||
                    (this.client.ohrmarke && this.anzahlTiereOhrmarke < 1)))
        ) {
            this.gruppe.anzahl_tiere = this.anzahlTiereCtrl.value;
            this.gruppe.alter_bei_einstallung = this.alterEinstallungCtrl.value;
        }

        this.api.updateEntity(environment.BASE_URI + 'gruppe/update.php', this.gruppe).subscribe({
            next: (gruppe) => {
                this.messageService.infoMessage('Änderung erfolgreich gespeichert!');
                this.dialogRef.close(gruppe);
            },
            error: (error) => {
                this.messageService.errorMessage(
                    'Änderung konnte nicht gespeichert werden!',
                    error
                );
            }
        });
    }
}
