import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GruppeEinstallenDialogInsert } from './gruppe-einstallen-dialog-insert';
import { Gruppe } from '../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';

@Component({
  selector: 'kiene-gruppe-einstallen-dialog',
  templateUrl: './gruppe-einstallen-dialog.component.html',
  styleUrls: ['./gruppe-einstallen-dialog.component.scss']
})
export class GruppeEinstallenDialogComponent implements OnInit {

  gruppe: Gruppe;
  showPassReiter: boolean;

  constructor(public dialogRef: MatDialogRef<GruppeEinstallenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: GruppeEinstallenDialogInsert) {
    this.gruppe = this.data.gruppe;
    this.showPassReiter = this.data.showPassReiter;
  }

  ngOnInit(): void {
  }

}
