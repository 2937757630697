<div style="font-size: 14px;">
    <div class="one-row auto-select-width">
        <button (click)="deselectClient()" *ngIf="currentClient" mat-icon-button matTooltip="Kunden abwählen">
            <mat-icon>clear</mat-icon>
        </button>
        <mat-form-field class="client-select-form-field" style="margin-top: 4px; width: 700px;">
            <mat-label>{{ !currentClient ? ('Kunde auswählen') : ('Aktueller Kunde') }}</mat-label>
            <mat-select (click)="clickedSelect()" (selectionChange)="setCurrentClient($event)"
                [compareWith]="clientComparator" [formControl]="clientSelectCtrl">
                <mat-option *ngFor="let c of clients" [value]="c">{{ formatClientString(c) }}</mat-option>
            </mat-select>
        </mat-form-field>
        <button (click)="openDialog()" mat-icon-button matTooltip="Kunde auswählen">
            <mat-icon>domain</mat-icon>
        </button>
    </div>
</div>