import {
    AfterViewInit,
    Component,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    ArtikelMedikation,
    Material,
} from '../../../../warehouse/material/material';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnwendungsSelectorComponent } from '../../../../components/anwendungs-selector/anwendungs-selector.component';
import {
    ArtikelTierart,
    ArtikelTierartEvent,
} from '../../../../components/tierart-selector/artikel-tierart';
import { AnwendungEvent } from '../../../../documents/behandlungsanfrage/anwendung';
import { TierartSelectorComponent } from '../../../../components/tierart-selector/tierart-selector.component';
import {
    DiagnoseSelectorComponent
} from '../../../../components/diagnose-selector/diagnose-selector/diagnose-selector.component';
import { Anwendung } from '../../../../documents/behandlungsanfrage/anwendung';
import {
    Indikation,
    IndikationEvent,
} from '../../../../warehouse/material/artikel-indikation/indikation';
import { UntypedFormControl } from '@angular/forms';
import { API_BASE_URL_SERVICE } from '../../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../../services/message.service';
import { Unit } from '../../../../warehouse/material/unit';

@Component({
    selector: 'kiene-wartezeit-sonderfall-dialog',
    templateUrl: './wartezeit-sonderfall-dialog.component.html',
    styleUrls: ['./wartezeit-sonderfall-dialog.component.scss'],
})
export class WartezeitSonderfallDialogComponent
    implements OnInit, AfterViewInit {
    @ViewChild('tierartSelektor')
    private tierartSelektor: TierartSelectorComponent;
    @ViewChild('anwendungSelektor')
    private anwendungSelektor: AnwendungsSelectorComponent;
    @ViewChild('diagnoseSelektor')
    private diagnoseSelektor: DiagnoseSelectorComponent;

    artikelMedikation: ArtikelMedikation;

    material: Material;

    artikelTierart: ArtikelTierart;

    anwendung: Anwendung;

    fleischCtrl = new UntypedFormControl(0);
    milchCtrl = new UntypedFormControl(0);
    eierCtrl = new UntypedFormControl(0);

    private indikation: Indikation;
    kommentarCtrl = new UntypedFormControl();
    mengeCtrl = new UntypedFormControl();
    einheitCtrl = new UntypedFormControl();

    units: Unit[] = [];
    behandlungTagenCtrl = new UntypedFormControl();
    intervallStdCtrl = new UntypedFormControl();
    faktorCtrl = new UntypedFormControl();
    koerpergewichtCtrl = new UntypedFormControl();
    dosierungTxtCtrl = new UntypedFormControl();

    constructor(
        public dialogRef: MatDialogRef<WartezeitSonderfallDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private messageService: MessageService
    ) {
        this.material = this.data.material;
        this.artikelMedikation = this.data.artikelMedikation;
    }

    ngOnInit(): void {
        this.ladeEinheiten();
    }

    ngAfterViewInit() {
        this.tierartSelektor.ladeTierartenFuerMaterialUndKunde(
            'WartezeitSonderfallDialogComponent',
            this.material.artikel_id,
            false,
            false
        );
        this.init();
    }

    setArtikelTierart(event: ArtikelTierartEvent) {
        this.artikelTierart = event.artikelTierart;
        this.anwendungSelektor.loadAnwendungen(
            this.material.artikel_id,
            this.artikelTierart.tierart_id,
            true,
            false
        );
    }

    setAnwendung(event: AnwendungEvent) {
        this.anwendung = event.anwendung;
        this.diagnoseSelektor.loadDiagnosen(
            this.material.artikel_id,
            this.artikelTierart.tierart_id,
            event.anwendung?.anwendung_id,
            true,
            false
        );
    }

    setDiagnose(event: IndikationEvent) {
        this.indikation = event.indikation;
    }

    private ladeEinheiten() {
        this.api.getAll(this.apiBaseUrl + 'einheit/read.php', undefined).subscribe({
            next: (response) => {
                this.units = response.records;
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler!', err);
            },
        });
    }

    public einheitComparator(e1: Unit, e2: Unit): boolean {
        return e1?.einheit_id === e2?.einheit_id;
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        if (!this.artikelMedikation) {
            this.artikelMedikation = new ArtikelMedikation();
        }
        this.artikelMedikation.artikel_id = this.material.artikel_id;
        this.artikelMedikation.tierart_id = this.artikelTierart.tierart_id;
        this.artikelMedikation.anwendung_id = this.anwendung.anwendung_id;
        this.artikelMedikation.indikation_id = this.indikation.indikation_id;
        this.artikelMedikation.diagnose = this.indikation.diagnose;
        this.artikelMedikation.wartezeit_fleisch = this.fleischCtrl.value;
        this.artikelMedikation.wartezeit_milch = this.milchCtrl.value;
        this.artikelMedikation.wartezeit_eier = this.eierCtrl.value;
        this.artikelMedikation.kommentar = this.kommentarCtrl.value;
        this.artikelMedikation.menge = this.mengeCtrl.value;
        this.artikelMedikation.einheit_id = this.einheitCtrl.value?.einheit_id;
        this.artikelMedikation.behandlung_tage = this.behandlungTagenCtrl.value;
        this.artikelMedikation.intervall_std = this.intervallStdCtrl.value;
        this.artikelMedikation.faktor = this.faktorCtrl.value;
        this.artikelMedikation.koerpergewicht = this.koerpergewichtCtrl.value;
        this.artikelMedikation.dosierung_txt = this.dosierungTxtCtrl.value;

        this.api
            .updateGeneric(
                this.apiBaseUrl + 'artikel_medikation/update.php',
                this.artikelMedikation
            )
            .subscribe({
                next: (response) => {
                    this.messageService.infoMessage(
                        'Die Einstellungen wurden erfolgreich gespeichert!'
                    );
                    this.dialogRef.close(true);
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }

    private init() {
        if (this.artikelMedikation) {
            this.artikelTierart = new ArtikelTierart();
            this.artikelTierart.tierart_id = this.artikelMedikation.tierart_id;
            this.artikelTierart.tierart = this.artikelMedikation.tierart;
            this.artikelTierart.artikel_id = this.artikelMedikation.artikel_id;
            this.artikelTierart.artikel = this.artikelMedikation.artikel_me;
            this.tierartSelektor.setTierart(this.artikelTierart, false, true);

            this.anwendung = new Anwendung();
            this.anwendung.anwendung_id = this.artikelMedikation.anwendung_id;
            this.anwendung.artikel_id = this.artikelMedikation.artikel_id;
            this.anwendung.anwendung = this.artikelMedikation.anwendung;
            this.anwendungSelektor.setAnwendung(
                this.anwendung,
                this.artikelMedikation.tierart_id,
                false,
                true
            );

            this.indikation = new Indikation();
            this.indikation.indikation_id =
                this.artikelMedikation.indikation_id;
            this.indikation.diagnose = this.artikelMedikation.diagnose;
            this.indikation.artikel_id = this.artikelMedikation.artikel_id;
            this.indikation.tierart_id = this.artikelMedikation.tierart_id;
            this.diagnoseSelektor.setDiagnose(this.indikation, this.artikelMedikation.anwendung_id, false, true);

            this.fleischCtrl.setValue(
                this.artikelMedikation.wartezeit_fleisch,
                { emitEvent: false }
            );
            this.milchCtrl.setValue(this.artikelMedikation.wartezeit_milch, {
                emitEvent: false,
            });
            this.eierCtrl.setValue(this.artikelMedikation.wartezeit_eier, {
                emitEvent: false,
            });

            this.kommentarCtrl.setValue(this.artikelMedikation.kommentar, {
                emitEvent: false,
            });

            this.mengeCtrl.setValue(this.artikelMedikation.menge, {
                emitEvent: false,
            });
            const einheit = new Unit();
            einheit.einheit_id = this.artikelMedikation.einheit_id;
            einheit.bezeichnung = this.artikelMedikation.einheit;
            this.einheitCtrl.setValue(einheit, { emitEvent: false });

            this.behandlungTagenCtrl.setValue(
                this.artikelMedikation.behandlung_tage,
                { emitEvent: false }
            );
            this.intervallStdCtrl.setValue(
                this.artikelMedikation.intervall_std,
                { emitEvent: false }
            );
            this.faktorCtrl.setValue(this.artikelMedikation.faktor, {
                emitEvent: false,
            });
            this.koerpergewichtCtrl.setValue(
                this.artikelMedikation.koerpergewicht,
                { emitEvent: false }
            );
            this.dosierungTxtCtrl.setValue(
                this.artikelMedikation.dosierung_txt,
                { emitEvent: false }
            );
        }
    }
}
