import { GruppeTier } from '../../gruppe-tier/classes/gruppe-tier';
import { Gruppe } from '../../gruppen/classes/gruppe';

export class AusstallenFrageDialogResult {
    ok: boolean;
    intern: boolean;
    extern: boolean;
    tierliste: boolean;
}

export class AusstallenFrageDialogInput {
    gruppe: Gruppe;
    anzahl_tiere: number;
    tiere: GruppeTier[] = [];
}