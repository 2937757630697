import { Condition, Operator } from './table-option';

export class TableColumn {
    name: string;
    title: string;
    type = 'string';
    isActive = true;
    hint: string;
    maxWidth: string;
    sortFields: string[] = [];
    format: string;
    unit: string;
    displayFields: string[] = [];
    path?: string;
    nameOfIdField: string;
    tooltip: string;
    tooltipFieldname: string;
    iconName: string;
    fontFamily?: string;
    fontSize?: string;
    letterSpacing?: string;
    alternativeField?: string;
    clickable: boolean;
    eventName: string;
    enabled?: Condition[];
    permissionId: number;
    prefix?: string;
    suffix?: string;

    constructor(
        name: string,
        title: string,
        type?: string,
        isActive?: boolean,
        hint?: string,
        maxWidth?: string,
        sortFields?: string[],
        format?: string,
        unit?: string,
        displayFields?: string[],
        path?: string,
        nameOfIdField?: string,
        tooltip?: string,
        iconName?: string,
        fontFamily?: string,
        fontSize?: string,
        letterSpacing?: string,
        alternativeField?: string
    ) {
        this.name = name;
        this.title = title;

        if (type) {
            this.type = type;
        }

        if (isActive) {
            this.isActive = isActive;
        }

        if (hint) {
            this.hint = hint;
        }

        if (maxWidth) {
            this.maxWidth = maxWidth;
        }

        if (sortFields) {
            this.sortFields = sortFields;
        }

        if (format) {
            this.format = format;
        }

        if (unit) {
            this.unit = unit;
        }
        if (displayFields) {
            this.displayFields = displayFields;
        }
        if (path) {
            this.path = path;
        }
        if (nameOfIdField) {
            this.nameOfIdField = nameOfIdField;
        }
        if (tooltip) {
            this.tooltip = tooltip;
        }
        if (iconName) {
            this.iconName = iconName;
        }
        if (fontFamily) {
            this.fontFamily = fontFamily;
        }
        if (fontSize) {
            this.fontSize = fontSize;
        }
        if (letterSpacing) {
            this.letterSpacing = letterSpacing;
        }
        if (alternativeField) {
            this.alternativeField = alternativeField;
        }
    }

    checkConditions(row: any) {
        if (!this.enabled || this.enabled.length === 0) {
            return true;
        } else {
            let result = true;
            for (let c of this.enabled) {
                result = result && c.check(row);
            }
            return result;
        }
    }
}

export class DateTableColumn extends TableColumn {
    format = 'dd.MM.yy HH:mm:ss';
    alternativeField?: string;

    constructor(name: string, title: string, format?: string, options?: {
        alternativeField?: string
    }) {
        super(name, title, 'date');
        if (format) {
            this.format = format;
        }
        if (options) {
            if (options.alternativeField) {
                this.alternativeField = options.alternativeField;
            }
        }
    }
}

export class ColorTableColumn extends TableColumn {
    constructor(name: string, title: string) {
        super(name, title, 'color');
    }
}

export class TextTableColumn extends TableColumn {
    fontFamily?: string;
    fontSize?: string;
    letterSpacing?: string;
    suffix?: string;
    prefix?: string;

    constructor(
        name: string,
        title: string,
        options?: {
            fontFamily?: string;
            fontSize?: string;
            letterSpacing?: string;
            suffix?: string;
            prefix?: string;
        }
    ) {
        super(name, title, 'text');
        if (options) {
            this.fontFamily = options.fontFamily;
            this.fontSize = options.fontSize;
            this.letterSpacing = options.letterSpacing;
            this.suffix = options.suffix;
            this.prefix = options.prefix;
        }
    }
}

export class OhrmarkeTableColumn extends TableColumn {
    constructor(name: string, title: string) {
        super(name, title, 'ohrmarke', null, null, null, [
            'right(' + name + ',5)',
        ]);
    }
}

export class ButtonTableColumn extends TableColumn {
    iconName: string;
    eventName: string;
    tooltip: string;

    constructor(
        id: string,
        tooltip: string,
        iconName: string,
        eventName: string
    ) {
        super(id, '', 'button');
        this.iconName = iconName;
        this.eventName = eventName;
        this.tooltip = tooltip;
    }
}

export class InputTableColumn extends TableColumn {
    api_url: string;

    constructor(name: string, title: string, api_url: string) {
        super(name, title, 'input');
        this.api_url = api_url;
    }
}

export class URLTableColumn extends TableColumn {
    constructor(name: string, title: string) {
        super(name, title, 'url');
    }
}

export class LinkTableColumn extends TableColumn {
    path: string;
    nameOfIdField: string;
    params: string[];

    constructor(
        name: string,
        title: string,
        path: string,
        nameOfIdField: string,
        params?: string[]
    ) {
        super(name, title, 'link');
        this.path = path;
        this.nameOfIdField = nameOfIdField;
        if (params) {
            this.params = params;
        }
    }
}

export class FunctionTableColumn extends TableColumn {
    callback: (param: any) => string;

    constructor(name: string, title: string, callback: (param: any) => string) {
        super(name, title, 'function');
        this.callback = callback;
    }
}

export class IfElseTableColumn extends TableColumn {
    conditionAttribute: string;
    ifValue: string;
    elseValue: string;

    constructor(
        name: string,
        title: string,
        conditionAttribute: string,
        ifValue: string,
        elseValue: string
    ) {
        super(name, title, 'ifelse');
        this.conditionAttribute = conditionAttribute;
        this.ifValue = ifValue;
        this.elseValue = elseValue;
    }
}

export class NumberTableColumn extends TableColumn {
    format = '1.2-2';
    unit: string;

    constructor(name: string, title: string, format?: string, unit?: string, options?: { sortFields?: string[], isActive?: boolean }) {
        super(name, title, 'number');
        if (format) {
            this.format = format;
        }
        if (unit) {
            this.unit = unit;
        }
        if (options?.sortFields?.length > 0) {
            this.sortFields = options.sortFields;
        }
        if (options?.isActive === false) {
            this.isActive = false;
        }
    }
}

export class RawLocalDateTime extends TableColumn {
    constructor(name: string, title: string) {
        super(name, title, 'rawLocalDateTime');
    }
}

export class KieneBooleanTableColumn extends TableColumn {
    constructor(name: string, title: string, options?: {
        clickable?: { eventName: string, enabled: Condition[], permissionId: number },
        tooltip?: string,
        tooltipFieldname?: string
    }) {
        super(name, title, 'boolean');
        if (options?.clickable?.eventName) {
            this.clickable = true;
            this.eventName = options?.clickable?.eventName;
            this.enabled = options?.clickable?.enabled;
            this.permissionId = options?.clickable?.permissionId;
        }
        if (options?.tooltip) {
            this.tooltip = options?.tooltip;
        }
        if (options?.tooltipFieldname) {
            this.tooltipFieldname = options?.tooltipFieldname;
        }
    }
}

export class SlideToggleTableColumn extends TableColumn {
    eventName: string;

    constructor(name: string, title: string, eventName: string) {
        super(name, title, 'slideToggle');
        this.eventName = eventName;
    }
}

export class ArrayTableColumn extends TableColumn {
    displayFields: string[] = [];

    constructor(name: string, title: string, displayFields: string[]) {
        super(name, title, 'array');
        this.displayFields = displayFields;
    }
}

export class StatusTableColumn extends TableColumn {
    colorMap: Map<number, string>;

    constructor(name: string, title: string, colorMap?: Map<number, string>) {
        super(name, title, 'status', true);
        if (colorMap) {
            this.colorMap = colorMap;
        }
    }
}

export class ConditionSetting {
    conditions: Condition[] = [];
    icon: string;
    color: string;
    tooltip: string;
    tooltipFieldnames: string[];

    constructor(
        conditions: Condition[],
        options?: {
            icon?: string;
            color?: string;
            tooltip?: string;
            tooltipFieldnames?: string[];
        }
    ) {
        if (conditions) {
            this.conditions = conditions;
        }
        this.icon = options?.icon;
        this.color = options?.color;
        this.tooltip = options?.tooltip;
        this.tooltipFieldnames = options?.tooltipFieldnames;
    }

    checkConditions(values: any) {
        if (!this.conditions || this.conditions.length === 0) {
            return true;
        } else {
            let result = true;
            for (const c of this.conditions) {
                result = result && c.check(values);
            }
            return result;
        }
    }
}

export class ConditionTableColumn extends TableColumn {
    conditionSettings: ConditionSetting[] = [];
    defaultIcon: string;
    defaultColor: string;
    defaultTooltip: string;

    constructor(
        name: string, // für Download
        title: string,
        conditionSettings: ConditionSetting[],
        options?: {
            sortFields?: string[];
            defaultIcon?: string;
            defaultColor?: string;
            defaultTooltip?: string;
        }
    ) {
        super(
            name,
            title,
            'ConditionTableColumn',
            true,
            null,
            null,
            options?.sortFields
        );
        if (conditionSettings) {
            this.conditionSettings = conditionSettings;
        }
        this.defaultTooltip = options?.defaultTooltip;
        this.defaultIcon = options?.defaultIcon;
        this.defaultColor = options?.defaultColor;
    }

    getIcon(values: any) {
        for (const cs of this.conditionSettings) {
            if (cs.checkConditions(values)) {
                if (cs.icon) {
                    return cs.icon;
                }
            }
        }
        if (this.defaultIcon) {
            return this.defaultIcon;
        }
        return '';
    }

    getTooltip(value: any) {
        for (const cs of this.conditionSettings) {
            if (cs.checkConditions(value)) {
                if (cs.tooltipFieldnames?.length > 0) {
                    let s = '';
                    for (const fn of cs.tooltipFieldnames) {
                        const v = value[fn];
                        if (v) {
                            s += v + ' ';
                        }
                    }
                    return s;
                } else if (cs.tooltip) {
                    return cs.tooltip;
                }
            }
        }
        if (this.defaultTooltip) {
            return this.defaultTooltip;
        }
        return '';
    }

    getColor(values: any) {
        for (const cs of this.conditionSettings) {
            if (cs.checkConditions(values)) {
                if (cs.color) {
                    return cs.color;
                }
            }
        }
        if (this.defaultColor) {
            return this.defaultColor;
        }
        return '';
    }
}

export class EmailGesendetTableColumn extends ConditionTableColumn {

    constructor() {
        super('mail', 'Mail', [new ConditionSetting([new Condition('mail', Operator.GROESSER, 0)], {
            icon: 'mark_email_read',
            color: 'green',
            tooltipFieldnames: ['mail_adressen']
        })]);
    }

}

export class GedrucktTableColumn extends ConditionTableColumn {

    constructor() {
        super('gedruckt', 'Druck', [new ConditionSetting([new Condition('gedruckt', Operator.GROESSER, 0)], {
            icon: 'print',
            color: 'green'
        })]);
    }

}

export class AcceptDenyTableColumn extends TableColumn {
    callback: (row: any, status: any) => void;
    acceptStatus: any;
    denyStatus: any;

    constructor(name: string, title: string, callback: (row: any, status: any) => void, acceptStatus: any, denyStatus: any) {
        super(name, title, 'accept_deny');
        this.callback = callback;
        this.acceptStatus = acceptStatus;
        this.denyStatus = denyStatus;
    }
}

