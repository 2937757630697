import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormControl, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from "rxjs";
import {
    ConfirmDialogInput
} from "../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog";
import {
    ConfirmDialogComponent
} from "../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component";
import { BestellungInternPosition } from '../bestellung-intern-position';
import { BestellungIntern } from '../bestellung-intern';
import { BestellungInternServiceService } from '../bestellung-intern-service.service';
import { MessageService } from '../../../services/message.service';
import { ChargeService } from '../../../warehouse/charges/charge.service';
import { Charge } from '../../../warehouse/charges/charges.component';
import { StoragePlaceService } from '../../../warehouse/storage-place/storage-place.service';
import { StoragePlace } from '../../../warehouse/storage-place/storage-place';
import { Material } from '../../../warehouse/material/material';
import { HttpParams } from '@angular/common/http';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {
    ArticleScanAutocompleteComponent,
    ArticleScanSelectedEvent,
} from '../../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {
    AssignVetToTourData,
    AssignVetToTourResult
} from '../../../../../src/app/components/tourenplan/assign-vet-to-tour-dialog/assign-vet-to-tour';
import { MatDialog } from '@angular/material/dialog';
import {
    AssignVetToTourDialogComponent
} from '../../../../../src/app/components/tourenplan/assign-vet-to-tour-dialog/assign-vet-to-tour-dialog.component';
import { ChargeAuswahlDialogComponent } from './charge-auswahl-dialog/charge-auswahl-dialog.component';

@Component({
    selector: 'kiene-bestellung-intern-details',
    templateUrl: './bestellung-intern-details.component.html',
    styleUrls: ['./bestellung-intern-details.component.scss'],
})
export class BestellungInternDetailsComponent implements OnInit {
    bestellungInternId: string;
    bestellungIntern: BestellungIntern = new BestellungIntern();
    availableChargesForPickMaterial: Charge[] = [];
    availableStoragePlacesForPick: StoragePlace[] = [];

    articleAutocompleteParams: HttpParams;
    selectedStoragePlace: number;

    positionAmountCtrl = new FormControl(undefined, [
        Validators.min(0),
    ]);
    selectedMaterial: Material = new Material();
    kommentarCtrl: UntypedFormControl = new UntypedFormControl();
    bestBeforeCtrl: UntypedFormControl = new UntypedFormControl();

    chargePickInputCtrl: UntypedFormControl = new UntypedFormControl();
    materialPickCtrl: UntypedFormControl = new UntypedFormControl();
    pickAmountCtrl: UntypedFormControl = new UntypedFormControl();
    storagePlacePickCtrl: UntypedFormControl = new UntypedFormControl();

    positionen: BestellungInternPosition[] = [];

    selectedPosition: BestellungInternPosition = null;

    zielDatumCtrl: UntypedFormControl = new UntypedFormControl();

    isSavingPicking = false;

    currentPickingMaterial: Material;
    currentPickingBestBefore: Date;
    currentPickingCharge: string;

    positionenCtrls: UntypedFormControl[] = [];
    artikelmengeFocus: boolean[] = [];

    editAllowed = true;
    pickingMode = false;

    subscribe: Subscription = new Subscription();

    @ViewChild('articleScanAutocompletePicking', { static: false })
    articleScanAutocompletePicking: ArticleScanAutocompleteComponent;

    @ViewChild('amountInput')
    amountInput: MatInput;

    @ViewChild('articleScanAutocompleteEdit', { static: false })
    articleScanAutocompleteEdit: ArticleScanAutocompleteComponent;

    @ViewChild('chargePickerInput', { static: false })
    chargePickerInput: MatInput;

    @ViewChild('pickStoragePlaceInput', { static: false })
    pickStoragePlaceInput: MatSelect;

    @ViewChild('pickAmountInput', { static: false })
    pickAmountInput: MatInput;
    isLoading: boolean;

    constructor(
        private route: ActivatedRoute,
        private storageService: StoragePlaceService,
        private chargeService: ChargeService,
        private messageService: MessageService,
        private api: BestellungInternServiceService,
        private pdfService: PdfViewerDesktopService,
        private dialog: MatDialog,
        private localStorageService: LocalStorageService
    ) {
        this.bestellungInternId = this.route.snapshot.paramMap.get('id');
    }

    ngOnInit() {
        this.articleAutocompleteParams = new HttpParams().set('gesperrt', '0');

        if (this.bestellungInternId !== 'neu') {
            this.loadBestellungIntern();
        } else {
            this.allowEditing();
            this.bestellungIntern.status_id = 1;
            this.zielDatumCtrl.setValue(new Date());
        }
    }

    allowEditing() {
        this.editAllowed = true;
        this.kommentarCtrl.enable();
        this.zielDatumCtrl.enable();
    }

    forbidEditing() {
        this.editAllowed = false;
        this.kommentarCtrl.disable();
        this.zielDatumCtrl.disable();
    }

    reload() {
        this.loadBestellungIntern();
    }

    loadBestellungIntern() {
        this.subscribe.unsubscribe();
        this.subscribe = this.api.getById(Number.parseInt(this.bestellungInternId, 10))
            .subscribe({
                next: (response) => {
                    this.bestellungIntern = response;
                    if (
                        this.bestellungIntern.status_id <= 1 &&
                        this.bestellungIntern.behandlungsanfrage === 0
                    ) {
                        this.allowEditing();
                    }
                    this.zielDatumCtrl.setValue(this.bestellungIntern.zieldatum);
                    this.kommentarCtrl.setValue(this.bestellungIntern.bezeichnung);

                    if (this.bestellungIntern.status_id === 3) {
                        this.pickingMode = true;
                    }

                    this.loadBestellungInternPositionen();
                },
                error: err => {
                    this.messageService.errorMessage('Bestellungen konnten nicht geladen werden', err);
                }
            });
    }

    loadBestellungInternPositionen() {
        this.subscribe.unsubscribe();
        this.subscribe = this.api.getPositionen(this.bestellungIntern.bestellung_id)
            .subscribe({
                next: (response) => {
                    this.positionen = response.records;
                    this.initPositionen();
                }
            });
    }


    initPositionen() {
        this.positionenCtrls = [];
        let i = 0;
        for (const p of this.positionen) {
            this.positionenCtrls.push(new UntypedFormControl(p.artikelmenge));
            this.artikelmengeFocus[i] = false;
            i++;
        }
    }

    startPicking() {
        this.pickingMode = !this.pickingMode;
    }

    private updateBestellungDetails(response: BestellungIntern) {
        this.selectedPosition = null;
        this.bestellungInternId = response.bestellung_id.toString();
        this.loadBestellungIntern();
        this.positionAmountCtrl.setValue(undefined);
        this.storagePlacePickCtrl.setValue(undefined);
    }

    private checkOrderStatus() {
        if (this.bestellungIntern.status_id === 4) {
            this.openSchonAbgeholtDialog();
        } else if (this.bestellungIntern.status_id === 5) {
            this.goBack();
        }
    }

    private openSchonAbgeholtDialog() {
        const input = new ConfirmDialogInput();
        input.headline = 'Abholung';
        input.text = 'Wurde die Bestellung schon abgeholt?';
        input.okButton = 'Ja';
        input.cancelButton = 'Nein';
        this.dialog.open(ConfirmDialogComponent, { data: input }).afterClosed().subscribe({
            next: response => {
                if (response.confirmed) {
                    this.bestellungIntern.status_id = 5;
                    this.openAssingVetToToutDialog('Die Bestellung wurde abgeholt von:');
                } else if (this.bestellungIntern.status_id === 4) {
                    this.goBack();
                }
            }
        });
    }


    save(statusId?: number) {
        this.isLoading = true;
        if (statusId) {
            this.bestellungIntern.status_id = statusId;
        }
        this.bestellungIntern.positionen = this.positionen;
        this.bestellungIntern.zieldatum = this.zielDatumCtrl.value;
        this.bestellungIntern.bezeichnung = this.kommentarCtrl.value;
        if (!this.bestellungIntern.tierarzt_id) {
            if (this.localStorageService.isCurrentUserVeterinarian()) {
                this.bestellungIntern.tierarzt_id = this.localStorageService.getCurrentUser().tierarzt_id;
            }
        }
        this.api.save(this.bestellungIntern).subscribe({
            next: (response) => {
                this.messageService.infoMessage('Die Bestellung wurde erfolgreich gespeichert!');
                this.updateBestellungDetails(response);
                this.checkOrderStatus();
                this.isLoading = false;
            },
            error: (error) => {
                this.messageService.errorMessage('Die Bestellung konnte nicht gespeichert werden!', error);
                this.loadBestellungIntern();
                this.isLoading = false;
            }
        });
    }

    private openAssingVetToToutDialog(title: string = 'Tierarzt auswählen'): void {
        const input = new AssignVetToTourData();
        input.buttonOk = 'Auswählen';
        input.title = title;
        input.showUnbekannt = true;
        input.tierarzt_id = this.bestellungIntern.tierarzt_id
        this.dialog.open(AssignVetToTourDialogComponent, { data: input }).afterClosed().subscribe(result => {
            if (result?.confirmed) {
                this.bestellungIntern.tierarzt_id = result.tierarzt_id;
                this.save();
            }
        });
    }

    goBack() {
        window.history.go(-1);
    }

    setSelectedMaterial(event: ArticleScanSelectedEvent) {
        this.selectedMaterial = event.article;
        setTimeout(() => {
            this.amountInput.focus();
        }, 100);
    }

    pickingMaterialSelected(event: ArticleScanSelectedEvent) {
        if (event) {
            this.currentPickingMaterial = event.article;
            if (event.charge) {
                this.chargePickInputCtrl.setValue(event.charge);
                if (event.mhd) {
                    this.bestBeforeCtrl.setValue(event.mhd);
                }
            } else {
                this.dialog.open(ChargeAuswahlDialogComponent, {
                    data: event.article,
                    minWidth: '800px',
                    disableClose: true
                }).afterClosed().subscribe({
                    next: (result) => {
                        if (result) {
                            this.loadStoragePlacesForCharge(result);
                            this.bestBeforeCtrl.setValue(result.mhd);
                            this.chargePickInputCtrl.setValue(result.charge);
                        }

                    }
                });
            }
            this.getPossibleChargesForMaterial(this.currentPickingMaterial);
        }
    }

    addPosition() {
        if (!this.positionAmountCtrl.value || this.positionAmountCtrl?.value < 1) {
            return;
        }

        let position: BestellungInternPosition = null;
        if (this.selectedPosition) {
            position = this.selectedPosition;
        } else {
            this.positionenCtrls.push(new UntypedFormControl());
            position = new BestellungInternPosition();
            this.positionen.push(position);
        }

        position.artikel = this.selectedMaterial.bezeichnung;
        position.ean = this.selectedMaterial.ean;
        position.artikel_me = this.selectedMaterial.artikel_me;
        position.artikel_id = this.selectedMaterial.artikel_id;
        position.artikelmenge = this.positionAmountCtrl.value;

        this.positionAmountCtrl.setValue(null);
        this.articleScanAutocompleteEdit.clearAndFocus();

        this.save(this.bestellungIntern.status_id);
    }

    materialSelected() {
        this.amountInput?.focus();
    }

    setPickMaterial(event: MatAutocompleteSelectedEvent) {
        const material: Material = event.option.value;
        this.getPossibleChargesForMaterial(material);
        this.chargePickerInput?.focus();
    }

    getPossibleChargesForMaterial(material: Material) {
        this.chargeService
            .getChargesForMaterial(material)
            .subscribe((response) => {
                this.availableChargesForPickMaterial = response.records;
                this.chargePickInputCtrl.updateValueAndValidity();
                this.checkPickCharge();
            });
    }

    checkPickCharge() {
        if (this.chargePickInputCtrl.valid) {
            const chargeNumber = this.chargePickInputCtrl.value;
            if (chargeNumber) {
                for (const c of this.availableChargesForPickMaterial) {
                    if (c.charge.toLowerCase() === chargeNumber.toLowerCase()) {
                        this.loadStoragePlacesForCharge(c);
                        this.bestBeforeCtrl.setValue(c.mhd);
                    }
                }
            }
        }
    }

    loadStoragePlacesForCharge(charge: Charge) {
        this.storageService
            .getStoragePlacesForCharge(charge)
            .subscribe((response) => {
                this.availableStoragePlacesForPick = response.records;
                if (this.availableStoragePlacesForPick.length === 1) {
                    this.storagePlacePickCtrl.setValue(
                        this.availableStoragePlacesForPick[0]
                    );
                    this.pickAmountInput?.focus();
                } else {
                    this.pickStoragePlaceInput?.focus();
                }
            });
    }

    chargeNameValidator(
        availableChargesForPickMaterial: Charge[]
    ): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            let isChargeCorrect = false;
            const insertedChargeNumber = control.value;
            if (insertedChargeNumber) {
                for (const c of this.availableChargesForPickMaterial) {
                    if (
                        c.charge.toLowerCase() ===
                        insertedChargeNumber.toLowerCase()
                    ) {
                        isChargeCorrect = true;
                    }
                }
                if (!isChargeCorrect) {
                    return {
                        charge: true,
                    };
                }
            }
            return null;
        };
    }

    bookPicking(): void {
        this.isSavingPicking = true;

        const bestellungId = this.bestellungIntern.bestellung_id;
        const artikelId = this.currentPickingMaterial?.artikel_id;
        const amount = this.pickAmountCtrl.value;
        const chargeName = this.chargePickInputCtrl.value;
        const storagePlace = this.storagePlacePickCtrl.value
            ? this.storagePlacePickCtrl.value.lagerplatz_id
            : null;
        const ba_id = this.selectedPosition?.ba_id;

        this.api
            .bookPicking(
                bestellungId,
                artikelId,
                amount,
                chargeName,
                storagePlace,
                ba_id
            )
            .subscribe({
                next: (response) => {
                    this.selectedPosition = null;
                    this.positionen = response.records;
                    this.initPositionen();
                    this.materialPickCtrl.setValue(null);
                    this.chargePickInputCtrl.setValue(null);
                    this.pickAmountCtrl.setValue(null);
                    this.bestBeforeCtrl.setValue(null);
                    this.selectedStoragePlace = null;
                    this.isSavingPicking = false;
                    this.messageService.infoMessage(
                        'Position erfolgreich kommissioniert!'
                    );
                    this.articleScanAutocompletePicking.clearAndFocus();
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Position konnte nicht kommissioniert werden! ',
                        error
                    );
                    this.isSavingPicking = false;
                },
            });
    }

    createBestBeforeDate(value: string) {
        let date = new Date();
        if (value.length === 6) {
            const yearPrefix = 20;
            const yearString = value[0] + '' + value[1];
            const year = yearPrefix + yearString;
            const month = value[2] + '' + value[3];
            const day = value[4] + '' + value[5];
            let monthInt = parseInt(month, 10) - 1;
            if (day === '00') {
                monthInt = monthInt + 1;
            }
            date = new Date(parseInt(year, 10), monthInt, parseInt(day, 10));
        }

        return date;
    }

    editPosition(p: BestellungInternPosition) {
        if (this.selectedPosition === p) {
            // abwählen
            this.selectedPosition = null;
        } else {
            this.selectedPosition = p;

            const material = new Material();
            material.artikel_id = p.artikel_id;
            material.artikel_me = p.artikel_me;
            material.ean = p.ean;
            material.bezeichnung = p.artikel;

            this.selectedMaterial = material;
            this.articleScanAutocompleteEdit?.setMaterial(material, false);
            this.positionAmountCtrl?.setValue(p.artikelmenge);
        }
    }

    removePosition(p: BestellungInternPosition) {
        const index = this.positionen.indexOf(p);
        this.positionen.splice(index, 1);
        this.save(this.bestellungIntern.status_id);
    }

    showPdf() {
        this.pdfService.openPdfViewer(
            new PdfViewerDialogInput(
                'bestellung/pdf.php',
                new HttpParams().set(
                    'bestellung_id',
                    this.bestellungIntern?.bestellung_id?.toString()
                ),
                'Bestellung'
            )
        );
    }

    setArtikelmengeFocus(index: number) {
        this.artikelmengeFocus[index] = true;
    }

    leaveArtikelmenge(index: number) {
        this.artikelmengeFocus[index] = false;
        // es hat sich nichts geaendert
        if (
            this.positionen[index]?.artikelmenge ===
            this.positionenCtrls[index]?.value
        ) {
            return;
        }
        this.positionen[index].artikelmenge =
            this.positionenCtrls[index]?.value;
        this.save();
    }

    clearFieldsForSelectedArticle() {
        this.availableStoragePlacesForPick = new Array<StoragePlace>();
        this.bestBeforeCtrl.setValue(undefined);
        this.chargePickInputCtrl.setValue(undefined);
        this.articleScanAutocompletePicking.setFocus();
    }

    clearEditFields() {
        this.positionAmountCtrl.setValue(undefined);
        this.articleScanAutocompleteEdit.setFocus();
    }
}
