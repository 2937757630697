<div style="padding: 12px;">
    <kiene-besuchs-detail-informationen [heimtierBeleg]="this.heimtierBeleg"></kiene-besuchs-detail-informationen>
    <div style="padding: 24px;">

        <h2>Bericht</h2>
        <mat-form-field style="width: 100%">
            <mat-label>Grund für den Besuch (F4)</mat-label>
            <textarea matInput [formControl]="berichtCtrl" rows="7" [kieneTextBaustein]="getTextBausteinBericht()"
                      (change)="updateBeleg()"></textarea>
        </mat-form-field>

        <h2>Besuchszusammenfassung</h2>
        <mat-form-field class="visit-summary">
            <mat-label>Besuchszusammenfassung (F4)</mat-label>
            <textarea matInput rows="7" placeholder="Zusammenfassung des Besuchs" [formControl]="zusammenfassungCtrl"
                      [kieneTextBaustein]="getTextBausteinZusammenfassung()" (change)="updateBeleg()"></textarea>
        </mat-form-field>

        <h2>Zus&auml;tzliche Anmerkungen</h2>
        <mat-form-field class="notes">
            <mat-label>Zusätzliche Anmerkungen (F4)</mat-label>
            <textarea [formControl]="anmerkungCtrl" matInput rows="7" placeholder="Anmerkungen zur Untersuchung"
                      [kieneTextBaustein]="getTextBausteinAnmerkung()" (change)="updateBeleg()"></textarea>
        </mat-form-field>

    </div>
</div>
