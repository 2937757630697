import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {MessageService} from '../../services/message.service';
import {formatDate} from '@angular/common';
import {HttpParams} from '@angular/common/http';
import {KalenderEintrag, KalendereintragTierarzt} from '../urlaubskalender/classes/kalender-eintrag';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {KieneSession} from '../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import {
    EnterValueDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {
    EnterValueInput,
    EnterValueResponse
} from '../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-response';
import {Dienstgruppe, DienstgruppeTierarzt} from './dienstgruppe';
import {DienstgruppeDetailsDialogComponent} from './dienstgruppe-details-dialog/dienstgruppe-details-dialog.component';

@Component({
    selector: 'kiene-wochenende-und-feiertag-planung',
    templateUrl: './wochenende-und-feiertag-planung.component.html',
    styleUrls: ['./wochenende-und-feiertag-planung.component.scss'],
    host: {'(window:keydown)': 'listenForKeyEvents($event)'},
})
export class WochenendeUndFeiertagPlanungComponent implements OnInit {

    dienstgruppeCtrl = new FormControl();
    dienstgruppen: Dienstgruppe[] = [];
    currentYear = new Date().getFullYear();
    months: string[] = ['Jan.', 'Feb.', 'Mär.', 'Apr.', 'Mai', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Okt.', 'Nov.', 'Dez.'];
    monthList = [];
    monthDayEintragArray: any[] = [];
    monthDayFeiertagArray: any[] = [];
    eintraege: KalenderEintrag[] = [];
    feiertage: KalenderEintrag[] = [];

    kieneSession: KieneSession;

    constructor(@Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private messageService: MessageService,
                private localStorageService: LocalStorageService,
                private dialog: MatDialog) {
        this.kieneSession = this.localStorageService.getCurrentUser();
    }

    ngOnInit(): void {
        this.initKalender();
    }

    initKalender() {
        this.ladeFeiertage();
        this.ladeDienstgruppen();
        this.initYear();
    }

    ladeKalendereintraege() {
        let params = new HttpParams();
        params = params.append('eintragtyp_id[]', '3');
        params = params.append('eintragtyp_id[]', '4');
        params = params.append('jahr', this.currentYear);
        this.api.get(this.apiBaseUrl + 'kalendereintrag/read.php', params).subscribe({
            next: response => {
                this.eintraege = response.records;
                this.initMonthDayEintragArray();
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    listenForKeyEvents(event: KeyboardEvent) {
        if (event?.key === 'ArrowLeft') {
            this.previousYear();
        } else if (event.key === 'ArrowRight') {
            this.nextYear();
        }
    }

    getDaysInMonth(monthIndex: number): number[] {
        const days: number[] = [];
        const d = new Date(this.currentYear, monthIndex + 1, 0);
        const maxDayNumber = d.getDate();

        for (let i = 1; i <= maxDayNumber; i++) {
            days.push(i);
        }

        return days;
    }

    isWeekDay(monthIndex: number, d: number) {
        const date: Date = this.monthList[monthIndex][d - 1];
        const feiertag: Date = this.monthDayFeiertagArray[monthIndex][d];
        if (feiertag) {
            return false;
        }
        const day = date.getDay();
        if (day > 0 && day < 6) {
            return true;
        }
        return false;
    }

    getKalendereintragTierarztgruppe(monthIndex: number, d: number) {
        const eintrag = this.monthDayEintragArray[monthIndex][d];
        if (eintrag) {
            return eintrag.dienstgruppe?.substring(eintrag.dienstgruppe.length - 1);
        }
        return '';
    }

    getFeiertag(monthIndex: number, d: number) {
        const eintrag: KalenderEintrag = this.monthDayFeiertagArray[monthIndex][d];
        if (eintrag) {
            if (!eintrag.titel && eintrag.bemerkung) {
                eintrag.titel = eintrag.bemerkung;
            }
            if (eintrag?.titel?.length > 12) {
                return eintrag.titel.substring(0, 12) + '...';
            }
            return eintrag.titel;
        }
        return '';
    }

    getColor(monthIndex: number, d: number) {
        const eintrag: KalenderEintrag = this.monthDayEintragArray[monthIndex][d];
        if (eintrag) {
            return eintrag.dienstgruppe_farbe;
        } else {
            return '';
        }
    }

    getBackgroundColor(monthIndex: number, d: number) {
        const eintrag: KalenderEintrag = this.monthDayEintragArray[monthIndex][d];
        if (eintrag) {
            return eintrag.dienstgruppe_hintergrundfarbe;
        } else {
            return '';
        }
    }

    previousYear() {
        this.currentYear--;
        this.initYear();
    }

    nextYear() {
        this.currentYear++;
        this.initYear();
    }

    createKalendereintragForDay(monthIndex: number, d: number) {
        this.createKalendereintrag(monthIndex, d);
    }

    dienstgruppeEditieren() {
        this.dialog.open(DienstgruppeDetailsDialogComponent, {
            data: this.dienstgruppeCtrl.value
        }).afterClosed().subscribe({
            next: result => {
                if (result) {
                    this.initKalender();
                }
            }
        });
    }

    dienstgruppeHinzufuegen() {
        this.dialog.open(DienstgruppeDetailsDialogComponent, {
            data: new Dienstgruppe()
        }).afterClosed().subscribe({
            next: result => {
                if (result) {
                    this.initKalender();
                }
            }
        });
    }

    dienstgruppeHinzufuegenSichtbar() {
        if (!this.dienstgruppeCtrl.value && this.localStorageService.hasPermission(1262)) {
            return true;
        }
        return false;
    }

    dienstgruppeEditierenSichtbar() {
        if (this.dienstgruppeCtrl.value && this.localStorageService.hasPermission(1263)) {
            return true;
        }
        return false;
    }

    dienstgruppeComparator(d1: Dienstgruppe, d2: Dienstgruppe) {
        return d1?.dienstgruppe_id === d2?.dienstgruppe_id;
    }

    private ladeDienstgruppen() {
        this.api.getAll(this.apiBaseUrl + 'dienstgruppe/read.php').subscribe({
            next: response => {
                this.dienstgruppen = response.records;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private initYear() {

        for (let i = 0; i < 12; i++) {
            const daysInMonth = this.getDaysInMonth(i);
            this.monthList[i] = [];
            this.monthDayEintragArray[i] = [];
            this.monthDayFeiertagArray[i] = [];
            for (const d of daysInMonth) {
                this.monthList[i].push(new Date(this.currentYear, i, d));
                this.monthDayEintragArray[i][d] = undefined;
                this.monthDayFeiertagArray[i][d] = undefined;
            }
        }
        this.ladeKalendereintraege();
        this.ladeFeiertage();
    }

    private initMonthDayEintragArray() {
        for (const eintrag of this.eintraege) {
            const date = new Date(eintrag.startdatum);
            const monthIndex = date.getMonth();
            const dayIndex = date.getDate();
            if (eintrag.eintragtyp_id === 4) {
                if (!this.monthDayEintragArray[monthIndex]) {
                    this.monthDayEintragArray[monthIndex] = [];
                }
                this.monthDayEintragArray[monthIndex][dayIndex] = eintrag;
            } else if (eintrag.eintragtyp_id === 3) {
                if (!this.monthDayFeiertagArray[monthIndex]) {
                    this.monthDayFeiertagArray[monthIndex] = [];
                }
                this.monthDayFeiertagArray[monthIndex][dayIndex] = eintrag;
            }
        }
    }

    private initFeiertage() {
        for (const eintrag of this.feiertage) {
            const date = new Date(eintrag.startdatum);
            const monthIndex = date.getMonth();
            const dayIndex = date.getDate();
            if (!this.monthDayFeiertagArray[monthIndex]) {
                this.monthDayFeiertagArray[monthIndex] = [];
            }
            this.monthDayFeiertagArray[monthIndex][dayIndex] = eintrag;
        }
    }

    private createKalendereintrag(monthIndex: number, d: number) {

        if (!this.localStorageService.hasPermission(4530)) {
            return;
        }

        let dienstgruppeEintrag = <KalenderEintrag>this.monthDayEintragArray[monthIndex][d];
        let feiertagEintrag = <KalenderEintrag>this.monthDayFeiertagArray[monthIndex][d];

        if (!this.dienstgruppeCtrl.value && !feiertagEintrag) {
            if (this.isWeekDay(monthIndex, d)) {
                this.createNewFeiertag(monthIndex, d);
                return;
            } else {
                return;
            }
        }
        // selbsterzeugten Feiertag wieder loeschen
        else if (!this.dienstgruppeCtrl.value && !!feiertagEintrag && !dienstgruppeEintrag) {
            this.eintragLoeschen(feiertagEintrag);
            return;
        }
        // feiertag und noch keine tierarztgruppe
        else if (this.dienstgruppeCtrl.value && !!feiertagEintrag && !dienstgruppeEintrag) {
            const eintrag: KalenderEintrag = this.createDienstgruppenEintrag(new Date(this.currentYear, monthIndex, d, 12));
            eintrag.dienstgruppe_id = this.dienstgruppeCtrl.value?.dienstgruppe_id;
            this.speicherEintrag(eintrag);
            return;
        }
        // feiertag und tierarztgruppe gesetzt und ueberschreib mit einer anderen tierarztgruppe
        else if (this.dienstgruppeCtrl.value && !!feiertagEintrag && !!dienstgruppeEintrag) {
            if (this.dienstgruppeCtrl.value.dienstgruppe_id !== dienstgruppeEintrag.dienstgruppe_id) {
                dienstgruppeEintrag.dienstgruppe_id = this.dienstgruppeCtrl.value?.dienstgruppe_id;
                this.speicherEintrag(dienstgruppeEintrag);
                return;
            }
        } else if (!this.dienstgruppeCtrl.value && dienstgruppeEintrag) {
            return;
        } else if (this.dienstgruppeCtrl.value && this.isWeekDay(monthIndex, d)) {
            return;
        }

        if (!dienstgruppeEintrag) {
            dienstgruppeEintrag = new KalenderEintrag();
        }

        // Tierarztgruppeneintrag löschen
        if (dienstgruppeEintrag?.dienstgruppe_id === this.dienstgruppeCtrl.value?.dienstgruppe_id) {
            this.eintragLoeschen(dienstgruppeEintrag);
            return;
        } else {

            const date = new Date(this.currentYear, monthIndex, d, 12);
            let samstagEintrag: KalenderEintrag;
            let sonntagEintrag: KalenderEintrag;
            const tmpDate = new Date(date);
            if (this.isSunday(date)) {
                const samstag = new Date(tmpDate.setDate(tmpDate.getDate() - 1));
                samstagEintrag = this.createDienstgruppenEintrag(samstag);
                sonntagEintrag = this.createDienstgruppenEintrag(date);
            } else if (this.isSaturday(date)) {
                const sonntag = new Date(tmpDate.setDate(tmpDate.getDate() + 1));
                samstagEintrag = this.createDienstgruppenEintrag(date)
                sonntagEintrag = this.createDienstgruppenEintrag(sonntag);
            }
            this.speicherEintrag(samstagEintrag);
            this.speicherEintrag(sonntagEintrag);

        }

    }

    private eintragLoeschen(eintrag: KalenderEintrag) {
        this.api.post(this.apiBaseUrl + 'kalendereintrag/delete.php', eintrag).subscribe({
            next: response => {
                if (response) {
                    this.initYear();
                }
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private speicherEintrag(eintrag: KalenderEintrag) {
        if (!this.localStorageService.hasPermission(4530)) {
            return;
        }
        this.api.post(this.apiBaseUrl + 'kalendereintrag/update.php', eintrag).subscribe({
            next: response => {
                if (response) {
                    this.initYear();
                }
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private ladeFeiertage() {
        let params = new HttpParams();
        params = params.append('datum', this.currentYear + '-01-01');
        this.api.get(this.apiBaseUrl + 'kalendereintrag/read_feiertage.php', params).subscribe({
            next: response => {
                this.feiertage = response.records;
                this.initFeiertage();
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private createNewFeiertag(monthIndex: number, d: number) {
        const date = new Date(this.currentYear, monthIndex, d, 12);
        const input = new EnterValueInput();
        input.title = 'Neuen Diensttag erstellen für den ' + formatDate(date, 'dd.MM.yyyy', 'de');
        input.text = '';
        input.okButton = 'Erstellen';
        input.addInputValue('Name des Tages', 'text');
        const dialogRef = this.dialog.open(EnterValueDialogComponent, {
            data: input
        });
        dialogRef.afterClosed().subscribe({
            next: (result: EnterValueResponse) => {
                const eintrag = new KalenderEintrag();
                const value: string = result.getValue(0);
                if (value && value.trim().length > 0) {
                    eintrag.bemerkung = value;
                    eintrag.eintragtyp_id = 3;
                    eintrag.benutzer_id = this.kieneSession.benutzer_id;
                    eintrag.genehmigt = 1;
                    eintrag.startdatum = date;
                    eintrag.enddatum = date;
                    this.speicherEintrag(eintrag);
                }
            }
        });
    }

    private isSunday(date: Date) {
        return date?.getDay() === 0;
    }

    private isSaturday(date: Date) {
        return date?.getDay() === 6;
    }

    private createDienstgruppenEintrag(date: Date) {
        let kalenderEintrag = this.monthDayEintragArray[date.getMonth()][date.getDate()];
        if (!kalenderEintrag) {
            kalenderEintrag = new KalenderEintrag();
        }
        const dienstgruppe: Dienstgruppe = this.dienstgruppeCtrl.value;
        kalenderEintrag.startdatum = date;
        kalenderEintrag.enddatum = date;
        kalenderEintrag.dienstgruppe_id = dienstgruppe?.dienstgruppe_id;
        kalenderEintrag.tieraerzte = this.convertToKalendereintrageTieraerzte(dienstgruppe?.tieraerzte);
        kalenderEintrag.benutzer_id = this.kieneSession?.benutzer_id;
        kalenderEintrag.eintragtyp_id = 4;
        kalenderEintrag.genehmigt = 1;
        return kalenderEintrag;
    }

    private convertToKalendereintrageTieraerzte(tieraerzte: DienstgruppeTierarzt[]) {
        const list: KalendereintragTierarzt[] = [];

        for (const t of tieraerzte) {
            const kt = new KalendereintragTierarzt();
            kt.vorname = t.vorname;
            kt.nachname = t.nachname;
            kt.tierarzt_id = t.tierarzt_id;
            list.push(kt);
        }

        return list;
    }
}
