import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MassnahmenplanRind } from '../massnahmenplan-rind';
import {
    LocalStorageService
} from '../../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { MessageService } from '../../../../services/message.service';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { Textbaustein } from '../../../../administration/text-baustein/text-baustein';
import { TextbausteinConfig } from '../../../../directives/text-baustein.directive';
import { HttpParams } from '@angular/common/http';
import { Vet } from '../../../../administration/vet/vet';
import { MassnahmenplanRindService } from '../massnahmenplan-rind.service';
import {
    ChangeCurrentClientService
} from '../../../../administration/client/change-current-client/change-current-client.service';
import {
    PdfViewerDesktopService
} from '../../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { Client } from '../../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {
    Benutzerprofil
} from '../../../../../../projects/kiene-core/src/lib/components/stammdaten/mein-profil/mein-profil';
import { API_BASE_URL_SERVICE } from '../../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { Signature } from '../../../../../../projects/kiene-core/src/lib/components/unterschriften/signature';
import {
    UnterschriftenService
} from '../../../../../../projects/kiene-core/src/lib/components/unterschriften/unterschriften.service';

@Component({
    selector: 'kiene-massnahmenplan-rind-details',
    templateUrl: './massnahmenplan-rind-details.component.html',
    styleUrls: ['./massnahmenplan-rind-details.component.scss'],
})
export class MassnahmenplanRindDetailsComponent implements OnInit, OnDestroy {

    qrcodeUrl = '';

    tierartCtrl = new FormControl();
    _0_1_2 = new UntypedFormControl();
    _0_1_3 = new UntypedFormControl();
    _0_1_4 = new UntypedFormControl();

    // _1_1_1 = new UntypedFormControl();
    // _1_1_2 = new UntypedFormControl();
    _1_1_3 = new UntypedFormControl();

    _2_1_1 = new UntypedFormControl();

    _2_2_1 = new UntypedFormControl();
    _2_2_2 = new UntypedFormControl();

    _2_3_1 = new UntypedFormControl();
    _2_3_2 = new UntypedFormControl();

    _3_1_1 = new UntypedFormControl();
    _3_1_2 = new UntypedFormControl();
    _3_1_3 = new UntypedFormControl();
    _3_1_4 = new UntypedFormControl();
    _3_1_5 = new UntypedFormControl();
    _3_1_6 = new UntypedFormControl();
    _3_1_7 = new UntypedFormControl();
    _3_1_8 = new UntypedFormControl();
    _3_1_9 = new UntypedFormControl();

    _3_2_1 = new UntypedFormControl();
    _3_2_2 = new UntypedFormControl();
    _3_2_3 = new UntypedFormControl();

    _3_3_1 = new UntypedFormControl();
    _3_3_2 = new UntypedFormControl();
    _3_3_3 = new UntypedFormControl();
    _3_3_4 = new UntypedFormControl();
    _3_3_5 = new UntypedFormControl();
    _3_3_6 = new UntypedFormControl();
    _3_3_7 = new UntypedFormControl();

    _3_4_1 = new UntypedFormControl();
    _3_4_2 = new UntypedFormControl();

    _3_5_1 = new UntypedFormControl();

    _3_6_1 = new UntypedFormControl();
    _3_6_2 = new UntypedFormControl();
    _3_6_3 = new UntypedFormControl();
    _3_6_4 = new UntypedFormControl();
    _3_6_5 = new UntypedFormControl();
    _3_6_6 = new UntypedFormControl();
    _3_6_7 = new UntypedFormControl();
    // _3_6_8 = new UntypedFormControl();
    _3_6_9 = new UntypedFormControl();

    _4_1_1 = new UntypedFormControl();
    _4_1_2 = new UntypedFormControl();
    _4_1_3 = new UntypedFormControl();
    _4_1_4 = new UntypedFormControl();
    _4_1_5 = new UntypedFormControl();
    _4_1_6 = new UntypedFormControl();
    _4_1_7 = new UntypedFormControl();
    _4_1_8 = new UntypedFormControl();
    _4_1_9 = new UntypedFormControl();
    _4_1_10 = new UntypedFormControl();
    _4_1_11 = new UntypedFormControl();
    _4_1_12 = new UntypedFormControl();
    // _4_1_13 = new UntypedFormControl();
    // _4_1_14 = new UntypedFormControl();

    _5_1_1 = new UntypedFormControl();
    _5_1_2 = new UntypedFormControl();
    _5_1_3 = new UntypedFormControl();
    _5_1_4 = new UntypedFormControl();
    _5_1_5 = new UntypedFormControl();
    _5_1_6 = new UntypedFormControl();
    _5_1_7 = new UntypedFormControl();
    _5_1_8 = new UntypedFormControl();
    _5_1_9 = new UntypedFormControl();
    _5_1_10 = new UntypedFormControl();
    _5_1_11 = new UntypedFormControl();

    _5_2_1 = new UntypedFormControl();
    _5_2_2 = new UntypedFormControl();
    _5_2_3 = new UntypedFormControl();
    // _5_2_4 = new UntypedFormControl();
    _5_2_5 = new UntypedFormControl();
    _5_2_6 = new UntypedFormControl();

    _6_1_1 = new UntypedFormControl();
    _6_1_2 = new UntypedFormControl();
    _6_1_3 = new UntypedFormControl();
    _6_1_4 = new UntypedFormControl();
    _6_1_5 = new UntypedFormControl();
    _6_1_6 = new UntypedFormControl();
    _6_1_7 = new UntypedFormControl();
    _6_1_8 = new UntypedFormControl();

    _6_2_1 = new UntypedFormControl();

    _6_3_1 = new UntypedFormControl();

    _7_1_1 = new UntypedFormControl();
    _7_1_2 = new UntypedFormControl();

    _7_2_1 = new UntypedFormControl();
    _7_2_2 = new UntypedFormControl();
    _7_2_3 = new UntypedFormControl();
    _7_2_4 = new UntypedFormControl();
    _7_2_5 = new UntypedFormControl();
    _7_2_6 = new UntypedFormControl();
    _7_2_7 = new UntypedFormControl();
    _7_2_8 = new UntypedFormControl();
    _7_2_9 = new UntypedFormControl();
    _7_2_10 = new UntypedFormControl();
    _7_2_11 = new UntypedFormControl();
    _7_2_12 = new UntypedFormControl();

    _7_3_1 = new UntypedFormControl();
    _7_3_3 = new UntypedFormControl();
    _7_3_4 = new UntypedFormControl();
    _7_3_5 = new UntypedFormControl();
    //_7_3_6 = new UntypedFormControl();
    // _7_3_7 = new UntypedFormControl();

    _8_1_1 = new UntypedFormControl();
    _8_1_2 = new UntypedFormControl();
    _8_1_3 = new UntypedFormControl();
    _8_1_4 = new UntypedFormControl();
    _8_1_5 = new UntypedFormControl();
    _8_1_6 = new UntypedFormControl();
    _8_1_7 = new UntypedFormControl();
    _8_1_8 = new UntypedFormControl();

    _9_1_1 = new UntypedFormControl();
    _9_1_2 = new UntypedFormControl();
    _9_1_3 = new UntypedFormControl();
    _9_1_4 = new UntypedFormControl();
    _9_1_5 = new UntypedFormControl();

    _9_2_1 = new UntypedFormControl();
    _9_2_2 = new UntypedFormControl();

    _9_3_1 = new UntypedFormControl();

    _9_4_erregernachweis_y_n_ctrl = new UntypedFormControl();
    _9_4_2 = new UntypedFormControl();
    _9_4_sektion_y_n_ctrl = new UntypedFormControl();
    _9_4_4 = new UntypedFormControl();
    _9_4_5 = new UntypedFormControl();
    _9_4_6 = new UntypedFormControl();

    _10_1_1 = new UntypedFormControl();

    _10_2_1 = new UntypedFormControl();

    _10_3_1 = new UntypedFormControl();

    _11_1_1 = new UntypedFormControl();

    _11_2_1 = new UntypedFormControl();

    _11_3_1 = new UntypedFormControl();
    _11_3_2 = new UntypedFormControl();
    _11_3_3 = new UntypedFormControl();

    bbpAnhaengenCtrl = new UntypedFormControl();
    befundeAnhaengenCtrl = new UntypedFormControl();

    _1_1_3_stallnummer_1 = new UntypedFormControl();
    _1_1_3_stallnummer_2 = new UntypedFormControl();
    _1_1_3_stallnummer_3 = new UntypedFormControl();
    _1_1_3_stallnummer_4 = new UntypedFormControl();
    _1_1_3_stallplatze_1 = new UntypedFormControl();
    _1_1_3_stallplatze_2 = new UntypedFormControl();
    _1_1_3_stallplatze_3 = new UntypedFormControl();
    _1_1_3_stallplatze_4 = new UntypedFormControl();
    _2_integration = new UntypedFormControl();
    _3_biosiegel = new UntypedFormControl();
    _3_tierwohlteilnahme = new UntypedFormControl();
    _3_sonstige_programme = new UntypedFormControl();
    _4_ventilator = new UntypedFormControl();
    _4_airtubes = new UntypedFormControl();
    _5_grassilage = new UntypedFormControl();
    _5_treber = new UntypedFormControl();
    _5_trester = new UntypedFormControl();
    _5_rueben = new UntypedFormControl();
    _5_weide = new UntypedFormControl();
    _6_topdressing = new UntypedFormControl();
    _6_vermischt = new UntypedFormControl();
    _8_kryptosporidien = new UntypedFormControl();
    _8_kokzidien = new UntypedFormControl();
    _7_reinigungsmittel = new UntypedFormControl();
    _7_reinigung_fachfirma = new UntypedFormControl();
    _7_reinigung_selbst = new UntypedFormControl();
    _7_desinfektionsmittel = new UntypedFormControl();
    _7_desinfektion_fachfirma = new UntypedFormControl();
    _7_desinfektion_selbst = new UntypedFormControl();
    _7_dokumentation = new UntypedFormControl();
    _7_wasser_desinfektionsmittel = new UntypedFormControl();
    _7_wasser_routine = new UntypedFormControl();
    _7_wasser_antibiotika = new UntypedFormControl();
    _7_desinfektionsmatten = new UntypedFormControl();
    _7_desinfektionsmatten_text = new UntypedFormControl();
    _3_haltungsdauer = new UntypedFormControl();
    _3_gummiauflage = new UntypedFormControl();
    _3_planbefestigt = new UntypedFormControl();
    _3_trockensteher = new UntypedFormControl();
    _3_faersenmast = new UntypedFormControl();
    _5_vollautomatisch = new UntypedFormControl();
    _6_intramammaer = new UntypedFormControl();
    _6_intrauterin = new UntypedFormControl();
    _3_nutzungsdauer = new UntypedFormControl();
    _3_herdenalter = new UntypedFormControl();
    _3_zellzahl = new UntypedFormControl();
    _7_abkalbebuchten = new UntypedFormControl();
    _7_abkalbebuchten_anzahl = new UntypedFormControl();
    _7_abkalbebuchten_stroh = new UntypedFormControl();
    _7_abkalbebuchten_sonstiges = new UntypedFormControl();
    _3_mast_sonstiges = new UntypedFormControl();
    _5_futter_sonstiges = new UntypedFormControl();
    _5_untersuchung_am = new UntypedFormControl();
    _9_euter = new UntypedFormControl();
    _9_geschlechtsapparat = new UntypedFormControl();
    _9_stoffwechsel = new UntypedFormControl();
    _9_leitkeime = new UntypedFormControl();
    _9_uberis = new UntypedFormControl();
    _9_aureus = new UntypedFormControl();
    _9_ecoli = new UntypedFormControl();
    _9_agalactiae = new UntypedFormControl();
    _9_dysagalactiae = new UntypedFormControl();
    _9_mykoplasmen = new UntypedFormControl();
    _9_leitkeime_text = new UntypedFormControl();
    _3_kuhgebundene_aufzucht = new UntypedFormControl();

    _4b_melkstand = new UntypedFormControl();
    _4b_melkstand_art = new UntypedFormControl();
    _4b_melkstand_plaetze = new UntypedFormControl();
    _4b_melkroboter = new UntypedFormControl();
    _4b_modell = new UntypedFormControl();
    _4b_leistungspruefung = new UntypedFormControl();
    _4b_in_der_herde = new UntypedFormControl();
    _4b_am_ende = new UntypedFormControl();
    _4b_wartung = new UntypedFormControl();
    _4b_wartung_haeufigkeit = new UntypedFormControl();
    _4b_tausch = new UntypedFormControl();
    _4b_tausch_haeufigkeit = new UntypedFormControl();
    _4b_zitzendippen = new UntypedFormControl();
    _4b_einwegtuch = new UntypedFormControl();
    _4b_mehrwegtuch = new UntypedFormControl();
    _4b_zitzendesinfektion = new UntypedFormControl();
    _4b_handschuhe = new UntypedFormControl();
    _4b_schutzkleidung = new UntypedFormControl();
    _8_1_im_bestand_geimpft_y_n_2 = new UntypedFormControl();
    _8_1_im_bestand_geimpft_y_n_text_2 = new UntypedFormControl();
    _8_bestandsbetreuung = new UntypedFormControl();
    _8_fruchtbarkeit = new UntypedFormControl();
    _8_eutergesundheit = new UntypedFormControl();
    _8_klauengesundheit = new UntypedFormControl();
    _8_tiergesundheit = new UntypedFormControl();
    _8_bestandsbetreuung_sonstiges = new UntypedFormControl();
    _8_klauenpflege_selbst = new UntypedFormControl();
    _8_klauenpflege_fachfirma = new UntypedFormControl();
    _8_klauenpflege_frequenz = new UntypedFormControl();
    _8_trockenstehzeit_6 = new UntypedFormControl();
    _8_trockenstehzeit_6_8 = new UntypedFormControl();
    _8_trockenstehzeit_8 = new UntypedFormControl();
    _8_trockenstehzeit_0 = new UntypedFormControl();
    _8_reduk_melkhaeufigkeit = new UntypedFormControl();
    _8_reduk_abrupt = new UntypedFormControl();
    _8_reduk_energie = new UntypedFormControl();
    _8_art_sonstiges = new UntypedFormControl();
    _8_antibiotischer_schutz = new UntypedFormControl();
    _8_zitzenversiegler = new UntypedFormControl();

    _10_focus = false;
    _11_focus = false;

    mpr = new MassnahmenplanRind();
    // currentClient = new Client();
    mprId: string;

    zeitraum_von: string = null;
    zeitraum_bis: string = null;
    anzahl_befunde: number = null;
    anzahl_antibiogramme: number = null;
    anzahl_abgabebelege: number = null;
    antibiogrammeSubscription: Subscription;
    befundeSubscription: Subscription;
    abgabebelegeSubscription: Subscription;
    toteAktuellSubscription: Subscription;
    toteLetzteSubscription: Subscription;

    routerSub: Subscription;

    kundeHatRinder = true;

    showTierarztSelector = false;

    toteImAktuellenHalbjahr = '';
    toteImLetztenHalbjahr = '';
    abrechnenCtrl = new FormControl(true);

    permissionAbschliessen = 1155;

    benutzerprofil: Benutzerprofil;

    constructor(
        private localStorageService: LocalStorageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private activatedRoute: ActivatedRoute,
        private pdf: PdfViewerDesktopService,
        private router: Router,
        private locationService: Location,
        private mnpRindService: MassnahmenplanRindService,
        private changeCurrentClientService: ChangeCurrentClientService,
        private messageService: MessageService,
        private unterschriftService: UnterschriftenService
    ) {
        this.mprId = this.activatedRoute.snapshot.paramMap.get('id');
    }

    hasPermission(permissionId: number): boolean {
        return this.localStorageService.currentUserHasPermission(permissionId);
    }

    pruefeKundeTierart(kundeId: number) {
        const params = new HttpParams()
            .set('kunde_id', kundeId.toString())
            .set('mnp_rind', '1');
        this.api
            .get(this.apiBaseUrl + 'kunde_tierart/read.php', params)
            .subscribe({
                next: (response) => {
                    if (response.count <= 0) {
                        this.kundeHatRinder = false;
                    } else {
                        this.kundeHatRinder = true;
                    }
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Tierarten des Kunden konnten nicht abgefragt werden!',
                        null
                    );
                }
            });
    }

    setKunde(kunde: Client) {
        this.mpr.abweichende_lieferadresse = kunde.abweichende_lieferadresse;
        this.mpr.kunde_id = kunde.kunde_id;
        this.mpr.kunde = kunde.bezeichnung_final;
        this.mpr.kunde_ort = kunde.ort_final;
        this.mpr.ort_lieferung = kunde.ort_lieferung;
        this.mpr.kunde_plz = kunde.plz_final;
        this.mpr.plz_lieferung = kunde.plz_lieferung;
        this.mpr.kunde_strasse = kunde.strasse_final;
        this.mpr.strasse_lieferung = kunde.strasse_lieferung;
        this.mpr.kunde_telefon_1 = kunde.telefon_1;
        this.mpr.vvvo = kunde.vvvo;

        this.mpr._11_2_ort = kunde.ort_final;
        this._11_3_1.setValue(kunde.ort_final);

        this.pruefeKundeTierart(kunde.kunde_id);
    }

    ngOnInit() {

        this.ladeBenutzerprofil();

        if (!this.mpr.tierarzt_id) {
            this.mpr.tierarzt_id =
                this.localStorageService.getCurrentUser().tierarzt_id;
        }

        if (this.localStorageService.isCurrentUserVerwaltung()) {
            this.showTierarztSelector = true;
        }

        this.localStorageService.watchCurrentClient().subscribe((client) => {
            // this.currentClient = client;
            if (client && !this.mpr.kunde_id) {
                this.setKunde(client);
                this.calculateZeitraum();
                this.getToteImHalbjahr();
            }
        });

        if (this.mprId !== 'neu') {
            this.loadMpr();
        } else {
            this._11_3_2.setValue(new Date());
            this._10_2_1.setValue(new Date());

            if (this.localStorageService.getCurrentClient() !== null) {
                // this.currentClient = this.localStorageService.getCurrentClient();
                if (!this.mpr.kunde_id) {
                    this.setKunde(this.localStorageService.getCurrentClient());
                }
            } else {
                this.changeCurrentClientService.openChangeDialog('MNP Rind');
            }
        }

        this.initForm();

        this.routerSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (this.mpr.status_id !== 62) {
                    this.save(false);
                }
            }
        });

        this._0_1_2.valueChanges.subscribe((value) => {
            this.mpr._0_halbjahr = value;
            this.calculateZeitraum();
            this.getToteImHalbjahr();
        });

        this._0_1_3.valueChanges.subscribe((value) => {
            this.mpr._0_jahr = value;
            this.calculateZeitraum();
            this.getToteImHalbjahr();
        });

        this._9_4_erregernachweis_y_n_ctrl.valueChanges.subscribe((value) => {
            if (value === true) {
                this.befundeAnhaengenCtrl.setValue(true);
            }
        });

        this._9_4_sektion_y_n_ctrl.valueChanges.subscribe((value) => {
            if (value === true) {
                this.befundeAnhaengenCtrl.setValue(true);
            }
        });
    }

    ladeBenutzerprofil() {
        this.api.get(this.apiBaseUrl + 'benutzerprofil/read_one_kundenportal.php').subscribe({
            next: response => {
                this.benutzerprofil = <Benutzerprofil>response;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    ngOnDestroy() {
        this.routerSub.unsubscribe();
    }

    goBack() {
        this.locationService.back();
    }

    calculateHalbjahr() {
        const now = new Date();
        now.setMonth(now.getMonth() - 6);
        const year = now.getFullYear();
        const halbjahr = now.getMonth() <= 5 ? 1 : 2;
        this._0_1_2.setValue(halbjahr, { emitEvent: false });
        this._0_1_3.setValue(year, { emitEvent: false });
        this.mpr._0_halbjahr = halbjahr;
        this.mpr._0_jahr = year;
    }

    getToteImAktuellenHalbjahr() {
        this.toteImAktuellenHalbjahr = '';
        if (this.mpr?.kunde_id > 0) {
            const halbjahrAkt: number = this.mpr._0_halbjahr;
            const jahrAkt: number = this.mpr._0_jahr;

            this.toteImAktuellenHalbjahr = halbjahrAkt + '. Halbjahr ' + jahrAkt + ' = ';

            let paramsAktuell = new HttpParams();
            paramsAktuell = paramsAktuell.set(
                'kunde_id',
                this.mpr?.kunde_id?.toString()
            );
            paramsAktuell = paramsAktuell.set(
                'halbjahr',
                halbjahrAkt?.toString()
            );
            paramsAktuell = paramsAktuell.set('jahr', jahrAkt?.toString());

            this.toteAktuellSubscription?.unsubscribe();
            this.toteAktuellSubscription = this.api
                .get(
                    this.apiBaseUrl +
                    'gruppe/read_tote_rinder_halbjahr.php',
                    paramsAktuell
                )
                .subscribe({
                    next: (response) => {
                        this.toteImAktuellenHalbjahr +=
                            response.count +
                            ' Tiere ';
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Tote konnten nicht abgefragt werden!',
                            err
                        );
                        this.toteImAktuellenHalbjahr += ' ?';
                    },
                });
        }
    }

    istMilchkuehe(): boolean {
        return this.tierartCtrl?.value === 47;
    }

    istKaelber(): boolean {
        return this.tierartCtrl?.value === 46;
    }

    getToteImLetztenHalbjahr() {
        this.toteImLetztenHalbjahr = '';
        if (this.mpr?.kunde_id > 0) {
            let halbjahrLetztes = this.mpr._0_halbjahr - 1;
            let jahrLetztes = this.mpr._0_jahr;

            if (halbjahrLetztes <= 0) {
                halbjahrLetztes = 2;
                jahrLetztes--;
            }

            this.toteImLetztenHalbjahr = halbjahrLetztes + '. Halbjahr ' + jahrLetztes + ' = ';

            let paramsLetzte = new HttpParams();
            paramsLetzte = paramsLetzte.set(
                'kunde_id',
                this.mpr?.kunde_id?.toString()
            );
            paramsLetzte = paramsLetzte.set(
                'halbjahr',
                halbjahrLetztes?.toString()
            );
            paramsLetzte = paramsLetzte.set('jahr', jahrLetztes?.toString());

            this.toteLetzteSubscription?.unsubscribe();
            this.toteLetzteSubscription = this.api
                .get(
                    this.apiBaseUrl +
                    'gruppe/read_tote_rinder_halbjahr.php',
                    paramsLetzte
                )
                .subscribe({
                    next: (response) => {
                        this.toteImLetztenHalbjahr +=
                            response.count +
                            ' Tiere ';
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Tote konnten nicht abgefragt werden!',
                            err
                        );
                        this.toteImLetztenHalbjahr += ' ?';
                    },
                });
        }
    }

    getToteImHalbjahr() {
        this.getToteImAktuellenHalbjahr();
        this.getToteImLetztenHalbjahr();
    }

    calculateZeitraum() {
        if (this.mpr._0_halbjahr === 1) {
            this.zeitraum_von = this.mpr._0_jahr + '-01-01';
            this.zeitraum_bis = this.mpr._0_jahr + '-06-30';
        } else {
            this.zeitraum_von = this.mpr._0_jahr + '-07-01';
            this.zeitraum_bis = this.mpr._0_jahr + '-12-31';
        }
        if (this.mpr?.kunde_id > 0) {
            this.loadAnzahlBefunde();
            this.loadAnzahlAntibiogramme();
            this.loadAnzahlAbgabebelege();
        }
    }

    loadAnzahlAntibiogramme() {
        this.anzahl_antibiogramme = null;
        let params = new HttpParams();
        params = params.set('zeit_von', this.zeitraum_von);
        params = params.set('zeit_bis', this.zeitraum_bis);
        params = params.set('kunde_id', this.mpr.kunde_id.toString());
        if (this.antibiogrammeSubscription) {
            this.antibiogrammeSubscription.unsubscribe();
        }
        this.antibiogrammeSubscription = this.api
            .get(this.apiBaseUrl + 'antibiogramm/read.php', params)
            .subscribe({
                next: (response) => {
                    this.anzahl_antibiogramme = response.count;
                },
            });
    }

    loadAnzahlBefunde() {
        this.anzahl_befunde = null;
        let params = new HttpParams();
        params = params.set('zeit_von', this.zeitraum_von);
        params = params.set('zeit_bis', this.zeitraum_bis);
        params = params.set('kunde_id', this.mpr.kunde_id.toString());
        if (this.befundeSubscription) {
            this.befundeSubscription.unsubscribe();
        }
        this.befundeSubscription = this.api
            .get(this.apiBaseUrl + 'befund/read.php', params)
            .subscribe({
                next: (response) => {
                    this.anzahl_befunde = response.count;
                },
            });
    }

    loadAnzahlAbgabebelege() {
        this.anzahl_abgabebelege = null;
        let params = new HttpParams();
        params = params.set('datum_von', this.zeitraum_von);
        params = params.set('datum_bis', this.zeitraum_bis);
        params = params.set('kunde_id', this.mpr.kunde_id.toString());
        params = params.set('status_id[]', '42');
        params = params.set('status_id[]', '49');
        if (this.abgabebelegeSubscription) {
            this.abgabebelegeSubscription.unsubscribe();
        }
        this.abgabebelegeSubscription = this.api
            .get(this.apiBaseUrl + 'abgabebeleg/read.php', params)
            .subscribe({
                next: (response) => {
                    this.anzahl_abgabebelege = response.count;
                },
            });
    }

    loadMpr() {
        const params = new HttpParams().set('mnp_rind_id', this.mprId);
        this.api
            .get(
                this.apiBaseUrl + 'massnahmenplan_rind/read_one.php',
                params
            )
            .subscribe((mpr) => {
                this.mpr = mpr;

                if (!this.mpr.tierarzt_id) {
                    this.mpr.tierarzt_id =
                        this.localStorageService.getCurrentUser().tierarzt_id;
                }

                if (this.mpr.status_id !== 62) {
                    // this.prepareSigning();
                }
                this.initForm();
            });
    }

    getTrueFalse(value: number): boolean {
        return value === 1 ? true : false;
    }

    get0or1(value: boolean): number {
        return value === true ? 1 : 0;
    }

    initForm() {
        if (this.mprId === 'neu') {
            this.calculateHalbjahr();
        }
        this.calculateZeitraum();
        this.getToteImHalbjahr();

        if (this.mpr.abrechnen === 0) {
            this.abrechnenCtrl.setValue(false);
        } else if (this.mpr.abrechnen === 1) {
            this.abrechnenCtrl.setValue(true);
        }
        if (this.mpr.abgabebeleg_id) {
            this.abrechnenCtrl.disable();
        }

        // 0
        this.tierartCtrl.setValue(
            this.mpr.tierart_id
        );

        this._0_1_2.setValue(this.mpr._0_halbjahr, { emitEvent: false });

        this._0_1_3.setValue(this.mpr._0_jahr, { emitEvent: false });

        this._0_1_4.setValue(this.mpr._0_therapiehaeufigkeit);

        // 1
        //this._1_1_1.setValue(this.mpr._1_anzahl_staelle);

        //this._1_1_2.setValue(this.mpr._1_anzahl_stallplaetze_gesamt);

        this._1_1_3.setValue(
            this.mpr._1_milchkuehe_auf_dem_betrieb
        );

        // 2
        this._2_1_1.setValue(
            this.mpr._2_ta_betreuungsvertrag
        );

        this._2_2_1.setValue(
            this.mpr._2_weitere_ta_yes_no
        );
        this._2_2_1.valueChanges.subscribe({
            next: (value) => {
                if (value === 0) {
                    this._2_2_2.disable();
                } else {
                    this._2_2_2.enable();
                }
            },
        });

        this._2_2_2.setValue(this.mpr._2_weitere_ta_name_anschrift);

        this._2_3_1.setValue(
            this.mpr._2_weitere_berater_yes_no
        );
        this._2_3_1.valueChanges.subscribe({
            next: (value) => {
                if (value === 0) {
                    this._2_3_2.disable();
                } else {
                    this._2_3_2.enable();
                }
            },
        });

        this._2_3_2.setValue(this.mpr._2_weitere_berater_name_anschrift);

        // 3
        this._3_1_1.setValue(this.getTrueFalse(this.mpr._3_1_rein_raus));

        this._3_1_2.setValue(this.getTrueFalse(this.mpr._3_1_abteilweise));

        this._3_1_3.setValue(this.getTrueFalse(this.mpr._3_1_stallweise));

        this._3_1_4.setValue(this.getTrueFalse(this.mpr._3_1_kontinuierlich));

        this._3_1_5.setValue(this.getTrueFalse(this.mpr._3_1_mit_auslauf));

        this._3_1_6.setValue(this.getTrueFalse(this.mpr._3_1_freilandhaltung));

        this._3_1_7.setValue(this.mpr._3_2_qs_teilnehmer);

        this._3_1_8.setValue(this.mpr._3_2_bio_siegel);

        this._3_1_9.setValue(this.mpr._3_2_sonstiges);

        this._3_2_1.setValue(
            this.getTrueFalse(this.mpr._3_3_geschlossenes_system)
        );

        this._3_2_2.setValue(this.getTrueFalse(this.mpr._3_3_zukauf));

        this._3_2_3.setValue(this.mpr._3_3_anzahl_herkuenfte);

        this._3_3_1.setValue(this.getTrueFalse(this.mpr._3_4_fresser_aufzucht));

        this._3_3_2.setValue(this.getTrueFalse(this.mpr._3_4_fresser_mast));

        this._3_3_3.setValue(this.getTrueFalse(this.mpr._3_4_milchmast));

        this._3_3_4.setValue(this.getTrueFalse(this.mpr._3_4_rose_mast));

        this._3_3_5.setValue(this.getTrueFalse(this.mpr._3_4_bullenmast));

        this._3_3_6.setValue(this.mpr._3_4_alter_bei_einstallung);

        this._3_3_7.setValue(this.mpr._3_4_mastdauer);

        this._3_4_1.setValue(this.mpr._3_5_besatzdichte);

        this._3_4_2.setValue(this.mpr._3_5_tiere_pro_bucht);

        this._3_5_1.setValue(this.mpr._3_6_leerstandszeit);

        this._3_6_1.setValue(this.getTrueFalse(this.mpr._3_7_betonspalten));

        this._3_6_2.setValue(this.getTrueFalse(this.mpr._3_7_holzspalten));

        this._3_6_3.setValue(
            this.getTrueFalse(this.mpr._3_7_holzspalten_mit_kunststoff)
        );

        this._3_6_4.setValue(this.getTrueFalse(this.mpr._3_7_tiefstreu));

        this._3_6_5.setValue(
            this.getTrueFalse(this.mpr._3_7_liegeboxen_laufstall)
        );

        this._3_6_6.setValue(this.getTrueFalse(this.mpr._3_7_anbinde_haltung));

        this._3_6_7.setValue(this.getTrueFalse(this.mpr._3_7_weidegang));

        // this._3_6_8.setValue(this.getTrueFalse(this.mpr._3_7_sonstiges_y_n));

        this._3_6_9.setValue(this.mpr._3_7_sonstiges_text);

        // 4
        this._4_1_1.setValue(this.getTrueFalse(this.mpr._4_zwangslueftung));

        this._4_1_2.setValue(this.getTrueFalse(this.mpr._4_deckenlueftung));

        this._4_1_3.setValue(this.getTrueFalse(this.mpr._4_tuerganglueftung));

        this._4_1_4.setValue(this.getTrueFalse(this.mpr._4_freie_lueftung));

        this._4_1_5.setValue(this.getTrueFalse(this.mpr._4_unterflurabsaugung));

        this._4_1_6.setValue(this.getTrueFalse(this.mpr._4_heizkanone));

        this._4_1_7.setValue(this.getTrueFalse(this.mpr._4_konvektor));

        this._4_1_8.setValue(this.getTrueFalse(this.mpr._4_deltarohre));

        this._4_1_9.setValue(this.mpr._4_individuell_1);

        this._4_1_10.setValue(this.mpr._4_individuell_2);

        this._4_1_11.setValue(this.mpr._4_individuell_3);

        this._4_1_12.setValue(this.mpr._4_individuell_4);

        // this._4_1_13.setValue(this.mpr._4_ueberpruefung_am);
        //
        // this._4_1_14.setValue(this.mpr._4_durch);

        // 5
        this._5_1_1.setValue(this.getTrueFalse(this.mpr._5_milchaustauscher));

        this._5_1_2.setValue(this.getTrueFalse(this.mpr._5_tmr));

        this._5_1_3.setValue(this.getTrueFalse(this.mpr._5_kraftfutter));

        this._5_1_4.setValue(this.getTrueFalse(this.mpr._5_heu));

        this._5_1_5.setValue(this.getTrueFalse(this.mpr._5_stroh));

        this._5_1_6.setValue(this.getTrueFalse(this.mpr._5_maissilage));

        this._5_1_7.setValue(this.getTrueFalse(this.mpr._5_eimer));

        this._5_1_8.setValue(this.getTrueFalse(this.mpr._5_trog));

        this._5_1_9.setValue(this.getTrueFalse(this.mpr._5_automat));

        this._5_1_10.setValue(this.mpr._5_individuell_1);

        this._5_1_11.setValue(this.mpr._5_tiere_futterplatz);

        this._5_2_1.setValue(this.getTrueFalse(this.mpr._5_brunnenwasser));

        this._5_2_2.setValue(this.getTrueFalse(this.mpr._5_stadtwasser));

        this._5_2_3.setValue(this.getTrueFalse(this.mpr._5_wasseruntersuchung));

        //this._5_2_4.setValue(this.mpr._5_wasseruntersuchung_text);

        this._5_2_5.setValue(this.mpr._5_tiere_traenke);

        this._5_2_6.setValue(this.mpr._5_art_traenke);

        // 6
        this._6_1_1.setValue(this.getTrueFalse(this.mpr._6_injektion));

        this._6_1_2.setValue(this.getTrueFalse(this.mpr._6_futter));

        this._6_1_3.setValue(this.getTrueFalse(this.mpr._6_wasser));

        this._6_1_4.setValue(this.getTrueFalse(this.mpr._6_milchtraenke));

        this._6_1_5.setValue(
            this.getTrueFalse(this.mpr._6_mischer_vorbehaelter)
        );

        this._6_1_6.setValue(this.getTrueFalse(this.mpr._6_dosierer));

        this._6_1_7.setValue(this.getTrueFalse(this.mpr._6_tiernah));

        this._6_1_8.setValue(this.getTrueFalse(this.mpr._6_lokal));

        this._6_2_1.setValue(this.mpr._6_leitfaden);

        this._6_3_1.setValue(this.mpr._6_risikomanagement);

        // 7
        this._7_1_1.setValue(this.mpr._7_1_art_schutzkleidung);

        this._7_1_2.setValue(this.mpr._7_1_art_hygieneschleuse);

        this._7_2_1.setValue(this.getTrueFalse(this.mpr._7_2_reinigung_stall));

        this._7_2_2.setValue(
            this.getTrueFalse(this.mpr._7_2_desinfektion_stall)
        );

        this._7_2_3.setValue(this.getTrueFalse(this.mpr._7_3_reinigung));

        this._7_2_4.setValue(this.getTrueFalse(this.mpr._7_3_desinfektion));

        this._7_2_5.setValue(
            this.getTrueFalse(this.mpr._7_3_reinigung_futterleitung)
        );

        this._7_2_6.setValue(
            this.getTrueFalse(this.mpr._7_3_reinigung_futtertroege)
        );

        this._7_2_7.setValue(this.getTrueFalse(this.mpr._7_3_routinemaessig));

        this._7_2_8.setValue(this.getTrueFalse(this.mpr._7_3_nach_antibiotika));

        this._7_2_9.setValue(
            this.getTrueFalse(this.mpr._7_3_reinigung_futtersilo)
        );

        this._7_2_11.setValue(
            this.getTrueFalse(this.mpr._7_4_durch_fremdfirma)
        );

        this._7_2_12.setValue(this.getTrueFalse(this.mpr._7_4_selbst));

        this._7_3_1.setValue(this.mpr._7_5_y_n);

        this._7_3_3.setValue(this.mpr._7_5_y_n_anzahl);

        this._7_3_4.setValue(this.getTrueFalse(this.mpr._7_5_mit_stroh));

        this._7_3_5.setValue(this.mpr._7_5_sonstiges_y_n_text);

        /*this._7_3_6.setValue(
            this.getTrueFalse(this.mpr._7_6_sonstige_absonder)
        );

        this._7_3_7.setValue(this.mpr._7_6_sonstige_absonder_text);*/

        // 8
        this._8_1_1.setValue(this.getTrueFalse(this.mpr._8_1_vorgeimpft_y_n));

        this._8_1_2.setValue(this.mpr._8_1_vorgeimpft_y_n_text);

        this._8_1_3.setValue(
            this.getTrueFalse(this.mpr._8_1_im_bestand_geimpft_y_n)
        );

        this._8_1_4.setValue(this.mpr._8_1_im_bestand_geimpft_y_n_text);

        this._8_1_5.setValue(this.mpr._8_2_endoparasiten_y_n);

        this._8_1_6.setValue(this.mpr._8_2_ektoparasiten_y_n);

        this._8_1_7.setValue(this.getTrueFalse(this.mpr._8_2_sonstiges_y_n));

        this._8_1_8.setValue(this.mpr._8_2_sonstiges_y_n_text);

        // 9
        this._9_1_1.setValue(this.getTrueFalse(this.mpr._9_1_atemwege));

        this._9_1_2.setValue(
            this.getTrueFalse(this.mpr._9_1_verdauungsapparat)
        );

        this._9_1_3.setValue(
            this.getTrueFalse(this.mpr._9_1_bewegungssapparat)
        );

        this._9_1_4.setValue(this.getTrueFalse(this.mpr._9_1_sonstiges_y_n));

        this._9_1_5.setValue(this.mpr._9_1_sonstiges_y_n_text);

        this._9_2_1.setValue(this.mpr._9_2_anzahl_kalenderhalbjahr);

        this._9_2_2.setValue(this.mpr._9_2_anzahl_vorheriges_halbjahr);

        this._9_3_1.setValue(this.mpr._9_3_angaben_zu_krankheit);

        this._9_4_erregernachweis_y_n_ctrl.setValue(
            this.getTrueFalse(this.mpr._9_4_erregernachweis_y_n),
            { emitEvent: false }
        );

        this._9_4_2.setValue(this.mpr._9_4_erregernachweis_y_n_text);

        this._9_4_sektion_y_n_ctrl.setValue(
            this.getTrueFalse(this.mpr._9_4_sektion_y_n),
            { emitEvent: false }
        );

        this._9_4_4.setValue(this.mpr._9_4_sektion_y_n_text);

        this._9_4_5.setValue(this.getTrueFalse(this.mpr._9_4_sonstiges_y_n));

        this._9_4_6.setValue(this.mpr._9_4_sonstiges_y_n_text);

        // 10
        this._10_1_1.setValue(this.mpr._10_1_ergebnis_beratung);

        if (!this.isEditable() && this.mpr._10_1_datum) {
            this._10_2_1.setValue(this.mpr._10_1_datum);
        }

        this._10_3_1.setValue(
            this.getTrueFalse(this.mpr._10_1_text_streichen_y_n)
        );

        // 11
        this._11_1_1.setValue(this.mpr._11_1_massnahmen);

        this._11_2_1.setValue(this.mpr._11_1_zeitraum_umsetzung);

        // this.mpr._11_2_ort = this._11_3_1.value;
        // this.mpr._11_2_datum = this._11_3_2.value;
        // this.mpr._11_2_verantwortlicher = this._11_3_3.value;

        this._11_3_1.setValue(this.mpr._11_2_ort);
        if (!this.isEditable() && this.mpr._11_2_datum) {
            this._11_3_2.setValue(this.mpr._11_2_datum);
        }
        this._11_3_3.setValue(this.mpr._11_2_verantwortlicher);

        this.bbpAnhaengenCtrl.setValue(this.mpr.bbp_anhaengen > 0);
        this.befundeAnhaengenCtrl.setValue(this.mpr.befunde_anhaengen > 0);

        // neue Felder 08/24 ----------------------------------------------------

        this._1_1_3_stallnummer_1.setValue(this.mpr._1_stallnummer_1);
        this._1_1_3_stallnummer_2.setValue(this.mpr._1_stallnummer_2);
        this._1_1_3_stallnummer_3.setValue(this.mpr._1_stallnummer_3);
        this._1_1_3_stallnummer_4.setValue(this.mpr._1_stallnummer_4);
        this._1_1_3_stallplatze_1.setValue(this.mpr._1_stallplatze_1);
        this._1_1_3_stallplatze_2.setValue(this.mpr._1_stallplatze_2);
        this._1_1_3_stallplatze_3.setValue(this.mpr._1_stallplatze_3);
        this._1_1_3_stallplatze_4.setValue(this.mpr._1_stallplatze_4);
        this._2_integration.setValue(this.mpr._2_integration);
        this._3_biosiegel.setValue(this.mpr._3_biosiegel);
        this._3_tierwohlteilnahme.setValue(this.mpr._3_tierwohlteilnahme);
        this._3_sonstige_programme.setValue(this.mpr._3_sonstige_programme);
        this._3_haltungsdauer.setValue(this.mpr._3_haltungsdauer);
        this._3_gummiauflage.setValue(this.getTrueFalse(this.mpr._3_gummiauflage));
        this._3_planbefestigt.setValue(this.getTrueFalse(this.mpr._3_planbefestigt));
        this._3_trockensteher.setValue(this.getTrueFalse(this.mpr._3_trockensteher));
        this._3_faersenmast.setValue(this.getTrueFalse(this.mpr._3_faersenmast));
        this._3_nutzungsdauer.setValue(this.mpr._3_nutzungsdauer);
        this._3_herdenalter.setValue(this.mpr._3_herdenalter);
        this._3_zellzahl.setValue(this.mpr._3_zellzahl);
        this._3_mast_sonstiges.setValue(this.mpr._3_mast_sonstiges);
        this._3_kuhgebundene_aufzucht.setValue(this.mpr._3_kuhgebundene_aufzucht);
        this._4_ventilator.setValue(this.getTrueFalse(this.mpr._4_ventilator));
        this._4_airtubes.setValue(this.getTrueFalse(this.mpr._4_airtubes));
        this._5_grassilage.setValue(this.getTrueFalse(this.mpr._5_grassilage));
        this._5_treber.setValue(this.getTrueFalse(this.mpr._5_treber));
        this._5_trester.setValue(this.getTrueFalse(this.mpr._5_trester));
        this._5_rueben.setValue(this.getTrueFalse(this.mpr._5_rueben));
        this._5_weide.setValue(this.getTrueFalse(this.mpr._5_weide));
        this._5_vollautomatisch.setValue(this.getTrueFalse(this.mpr._5_vollautomatisch));
        this._5_futter_sonstiges.setValue(this.mpr._5_futter_sonstiges);
        this._5_untersuchung_am.setValue(this.mpr._5_untersuchung_am);
        this._6_topdressing.setValue(this.getTrueFalse(this.mpr._6_topdressing));
        this._6_vermischt.setValue(this.getTrueFalse(this.mpr._6_vermischt));
        this._6_intramammaer.setValue(this.getTrueFalse(this.mpr._6_intramammaer));
        this._6_intrauterin.setValue(this.getTrueFalse(this.mpr._6_intrauterin));
        this._7_reinigungsmittel.setValue(this.mpr._7_reinigungsmittel);
        this._7_reinigung_fachfirma.setValue(this.getTrueFalse(this.mpr._7_reinigung_fachfirma));
        this._7_reinigung_selbst.setValue(this.getTrueFalse(this.mpr._7_reinigung_selbst));
        this._7_desinfektionsmittel.setValue(this.mpr._7_desinfektionsmittel);
        this._7_desinfektion_fachfirma.setValue(this.getTrueFalse(this.mpr._7_desinfektion_fachfirma));
        this._7_desinfektion_selbst.setValue(this.getTrueFalse(this.mpr._7_desinfektion_selbst));
        this._7_dokumentation.setValue(this.mpr._7_dokumentation);
        this._7_wasser_desinfektionsmittel.setValue(this.mpr._7_wasser_desinfektionsmittel);
        this._7_wasser_routine.setValue(this.getTrueFalse(this.mpr._7_wasser_routine));
        this._7_wasser_antibiotika.setValue(this.getTrueFalse(this.mpr._7_wasser_antibiotika));
        this._7_desinfektionsmatten.setValue(this.mpr._7_desinfektionsmatten);
        this._7_desinfektionsmatten_text.setValue(this.mpr._7_desinfektionsmatten_text);
        this._7_abkalbebuchten.setValue(this.mpr._7_abkalbebuchten);
        this._7_abkalbebuchten_anzahl.setValue(this.mpr._7_abkalbebuchten_anzahl);
        this._7_abkalbebuchten_stroh.setValue(this.getTrueFalse(this.mpr._7_abkalbebuchten_stroh));
        this._7_abkalbebuchten_sonstiges.setValue(this.mpr._7_abkalbebuchten_sonstiges);
        this._8_kryptosporidien.setValue(this.mpr._8_kryptosporidien);
        this._8_kokzidien.setValue(this.mpr._8_kokzidien);
        this._9_euter.setValue(this.getTrueFalse(this.mpr._9_euter));
        this._9_geschlechtsapparat.setValue(this.getTrueFalse(this.mpr._9_geschlechtsapparat));
        this._9_stoffwechsel.setValue(this.getTrueFalse(this.mpr._9_stoffwechsel));
        this._9_leitkeime.setValue(this.mpr._9_leitkeime);
        this._9_uberis.setValue(this.getTrueFalse(this.mpr._9_uberis));
        this._9_aureus.setValue(this.getTrueFalse(this.mpr._9_aureus));
        this._9_ecoli.setValue(this.getTrueFalse(this.mpr._9_ecoli));
        this._9_agalactiae.setValue(this.getTrueFalse(this.mpr._9_agalactiae));
        this._9_dysagalactiae.setValue(this.getTrueFalse(this.mpr._9_dysagalactiae));
        this._9_mykoplasmen.setValue(this.getTrueFalse(this.mpr._9_mykoplasmen));
        this._9_leitkeime_text.setValue(this.mpr._9_leitkeime_text);
        this._4b_melkstand.setValue(this.getTrueFalse(this.mpr._4b_melkstand));
        this._4b_melkstand_art.setValue(this.mpr._4b_melkstand_art);
        this._4b_melkstand_plaetze.setValue(this.mpr._4b_melkstand_plaetze);
        this._4b_melkroboter.setValue(this.getTrueFalse(this.mpr._4b_melkroboter));
        this._4b_modell.setValue(this.mpr._4b_modell);
        this._4b_leistungspruefung.setValue(this.mpr._4b_leistungspruefung);
        this._4b_in_der_herde.setValue(this.getTrueFalse(this.mpr._4b_in_der_herde));
        this._4b_am_ende.setValue(this.getTrueFalse(this.mpr._4b_am_ende));
        this._4b_wartung.setValue(this.mpr._4b_wartung);
        this._4b_wartung_haeufigkeit.setValue(this.mpr._4b_wartung_haeufigkeit);
        this._4b_tausch.setValue(this.mpr._4b_tausch);
        this._4b_tausch_haeufigkeit.setValue(this.mpr._4b_tausch_haeufigkeit);
        this._4b_zitzendippen.setValue(this.mpr._4b_zitzendippen);
        this._4b_einwegtuch.setValue(this.getTrueFalse(this.mpr._4b_einwegtuch));
        this._4b_mehrwegtuch.setValue(this.getTrueFalse(this.mpr._4b_mehrwegtuch));
        this._4b_zitzendesinfektion.setValue(this.getTrueFalse(this.mpr._4b_zitzendesinfektion));
        this._4b_handschuhe.setValue(this.getTrueFalse(this.mpr._4b_handschuhe));
        this._4b_schutzkleidung.setValue(this.mpr._4b_schutzkleidung);
        this._8_1_im_bestand_geimpft_y_n_2.setValue(this.getTrueFalse(this.mpr._8_1_im_bestand_geimpft_y_n_2));
        this._8_1_im_bestand_geimpft_y_n_text_2.setValue(this.mpr._8_1_im_bestand_geimpft_y_n_text_2);
        this._8_bestandsbetreuung.setValue(this.mpr._8_bestandsbetreuung);
        this._8_fruchtbarkeit.setValue(this.getTrueFalse(this.mpr._8_fruchtbarkeit));
        this._8_eutergesundheit.setValue(this.getTrueFalse(this.mpr._8_eutergesundheit));
        this._8_klauengesundheit.setValue(this.getTrueFalse(this.mpr._8_klauengesundheit));
        this._8_tiergesundheit.setValue(this.getTrueFalse(this.mpr._8_tiergesundheit));
        this._8_bestandsbetreuung_sonstiges.setValue(this.mpr._8_bestandsbetreuung_sonstiges);
        this._8_klauenpflege_selbst.setValue(this.getTrueFalse(this.mpr._8_klauenpflege_selbst));
        this._8_klauenpflege_fachfirma.setValue(this.getTrueFalse(this.mpr._8_klauenpflege_fachfirma));
        this._8_klauenpflege_frequenz.setValue(this.mpr._8_klauenpflege_frequenz);
        this._8_trockenstehzeit_6.setValue(this.getTrueFalse(this.mpr._8_trockenstehzeit_6));
        this._8_trockenstehzeit_6_8.setValue(this.getTrueFalse(this.mpr._8_trockenstehzeit_6_8));
        this._8_trockenstehzeit_8.setValue(this.getTrueFalse(this.mpr._8_trockenstehzeit_8));
        this._8_trockenstehzeit_0.setValue(this.getTrueFalse(this.mpr._8_trockenstehzeit_0));
        this._8_reduk_melkhaeufigkeit.setValue(this.getTrueFalse(this.mpr._8_reduk_melkhaeufigkeit));
        this._8_reduk_abrupt.setValue(this.getTrueFalse(this.mpr._8_reduk_abrupt));
        this._8_reduk_energie.setValue(this.getTrueFalse(this.mpr._8_reduk_energie));
        this._8_art_sonstiges.setValue(this.mpr._8_art_sonstiges);
        this._8_antibiotischer_schutz.setValue(this.mpr._8_antibiotischer_schutz);
        this._8_zitzenversiegler.setValue(this.mpr._8_zitzenversiegler);

        // ----------------------------------------------------------------------

        if (!this.hasPermission(1153)) {
            this.tierartCtrl.disable();
            this._0_1_2.disable();
            this._0_1_3.disable();
            this._0_1_4.disable();

            //this._1_1_1.disable();
            //this._1_1_2.disable();
            this._1_1_3.disable();
        }
    }

    save(abschliessen: boolean) {
        // 0
        this.mpr.tierart_id = this.tierartCtrl.value;
        this.mpr._0_halbjahr = this._0_1_2.value;
        this.mpr._0_jahr = this._0_1_3.value;
        this.mpr._0_therapiehaeufigkeit = this._0_1_4.value;

        // 1
        //this.mpr._1_anzahl_staelle = this._1_1_1.value;
        //this.mpr._1_anzahl_stallplaetze_gesamt = this._1_1_2.value;
        this.mpr._1_milchkuehe_auf_dem_betrieb =
            this._1_1_3.value;

        // 2
        this.mpr._2_ta_betreuungsvertrag = this._2_1_1.value;
        this.mpr._2_weitere_ta_yes_no = this._2_2_1.value;
        this.mpr._2_weitere_ta_name_anschrift = this._2_2_2.value;
        this.mpr._2_weitere_berater_yes_no =
            this._2_3_1.value;
        this.mpr._2_weitere_berater_name_anschrift = this._2_3_2.value;

        // 3
        this.mpr._3_1_rein_raus = this._3_1_1.value === true ? 1 : 0;
        this.mpr._3_1_abteilweise = this._3_1_2.value === true ? 1 : 0;
        this.mpr._3_1_stallweise = this._3_1_3.value === true ? 1 : 0;
        this.mpr._3_1_kontinuierlich = this._3_1_4.value === true ? 1 : 0;
        this.mpr._3_1_mit_auslauf = this._3_1_5.value === true ? 1 : 0;
        this.mpr._3_1_freilandhaltung = this._3_1_6.value === true ? 1 : 0;

        this.mpr._3_2_qs_teilnehmer = this._3_1_7.value;
        this.mpr._3_2_bio_siegel = this._3_1_8.value;
        this.mpr._3_2_sonstiges = this._3_1_9.value;

        this.mpr._3_3_geschlossenes_system = this._3_2_1.value === true ? 1 : 0;
        this.mpr._3_3_zukauf = this._3_2_2.value === true ? 1 : 0;
        this.mpr._3_3_anzahl_herkuenfte = this._3_2_3.value;

        this.mpr._3_4_fresser_aufzucht = this._3_3_1.value === true ? 1 : 0;
        this.mpr._3_4_fresser_mast = this._3_3_2.value === true ? 1 : 0;
        this.mpr._3_4_milchmast = this._3_3_3.value === true ? 1 : 0;
        this.mpr._3_4_rose_mast = this._3_3_4.value === true ? 1 : 0;
        this.mpr._3_4_bullenmast = this._3_3_5.value === true ? 1 : 0;
        this.mpr._3_4_alter_bei_einstallung = this._3_3_6.value;
        this.mpr._3_4_mastdauer = this._3_3_7.value;

        this.mpr._3_5_besatzdichte = this._3_4_1.value;
        this.mpr._3_5_tiere_pro_bucht = this._3_4_2.value;

        this.mpr._3_6_leerstandszeit = this._3_5_1.value;

        this.mpr._3_7_betonspalten = this._3_6_1.value === true ? 1 : 0;
        this.mpr._3_7_holzspalten = this._3_6_2.value === true ? 1 : 0;
        this.mpr._3_7_holzspalten_mit_kunststoff =
            this._3_6_3.value === true ? 1 : 0;
        this.mpr._3_7_tiefstreu = this._3_6_4.value === true ? 1 : 0;
        this.mpr._3_7_liegeboxen_laufstall = this._3_6_5.value === true ? 1 : 0;
        this.mpr._3_7_anbinde_haltung = this._3_6_6.value === true ? 1 : 0;
        this.mpr._3_7_weidegang = this._3_6_7.value === true ? 1 : 0;
        // this.mpr._3_7_sonstiges_y_n = this._3_6_8.value === true ? 1 : 0;
        this.mpr._3_7_sonstiges_text = this._3_6_9.value;

        // 4
        this.mpr._4_zwangslueftung = this._4_1_1.value === true ? 1 : 0;
        this.mpr._4_deckenlueftung = this._4_1_2.value === true ? 1 : 0;
        this.mpr._4_tuerganglueftung = this._4_1_3.value === true ? 1 : 0;
        this.mpr._4_freie_lueftung = this._4_1_4.value === true ? 1 : 0;
        this.mpr._4_unterflurabsaugung = this._4_1_5.value === true ? 1 : 0;
        this.mpr._4_heizkanone = this._4_1_6.value === true ? 1 : 0;
        this.mpr._4_konvektor = this._4_1_7.value === true ? 1 : 0;
        this.mpr._4_deltarohre = this._4_1_8.value === true ? 1 : 0;
        this.mpr._4_individuell_1 = this._4_1_9.value;
        this.mpr._4_individuell_2 = this._4_1_10.value;
        this.mpr._4_individuell_3 = this._4_1_11.value;
        this.mpr._4_individuell_4 = this._4_1_12.value;
        // this.mpr._4_ueberpruefung_am = this._4_1_13.value;
        // this.mpr._4_durch = this._4_1_14.value;

        // 5
        this.mpr._5_milchaustauscher = this._5_1_1.value === true ? 1 : 0;
        this.mpr._5_tmr = this._5_1_2.value === true ? 1 : 0;
        this.mpr._5_kraftfutter = this._5_1_3.value === true ? 1 : 0;
        this.mpr._5_heu = this._5_1_4.value === true ? 1 : 0;
        this.mpr._5_stroh = this._5_1_5.value === true ? 1 : 0;
        this.mpr._5_maissilage = this._5_1_6.value === true ? 1 : 0;
        this.mpr._5_eimer = this._5_1_7.value === true ? 1 : 0;
        this.mpr._5_trog = this._5_1_8.value === true ? 1 : 0;
        this.mpr._5_automat = this._5_1_9.value === true ? 1 : 0;
        this.mpr._5_individuell_1 = this._5_1_10.value;
        this.mpr._5_tiere_futterplatz = this._5_1_11.value;

        this.mpr._5_brunnenwasser = this._5_2_1.value === true ? 1 : 0;
        this.mpr._5_stadtwasser = this._5_2_2.value === true ? 1 : 0;
        this.mpr._5_wasseruntersuchung = this._5_2_3.value === true ? 1 : 0;
        //this.mpr._5_wasseruntersuchung_text = this._5_2_4.value;
        this.mpr._5_tiere_traenke = this._5_2_5.value;
        this.mpr._5_art_traenke = this._5_2_6.value;

        // 6
        this.mpr._6_injektion = this._6_1_1.value === true ? 1 : 0;
        this.mpr._6_futter = this._6_1_2.value === true ? 1 : 0;
        this.mpr._6_wasser = this._6_1_3.value === true ? 1 : 0;
        this.mpr._6_milchtraenke = this._6_1_4.value === true ? 1 : 0;
        this.mpr._6_mischer_vorbehaelter = this._6_1_5.value === true ? 1 : 0;
        this.mpr._6_dosierer = this._6_1_6.value === true ? 1 : 0;
        this.mpr._6_tiernah = this._6_1_7.value === true ? 1 : 0;
        this.mpr._6_lokal = this._6_1_8.value === true ? 1 : 0;

        this.mpr._6_leitfaden = this._6_2_1.value;
        this.mpr._6_risikomanagement = this._6_3_1.value;

        // 7
        this.mpr._7_1_art_schutzkleidung = this._7_1_1.value;
        this.mpr._7_1_art_hygieneschleuse = this._7_1_2.value;
        this.mpr._7_2_reinigung_stall = this._7_2_1.value;
        this.mpr._7_2_desinfektion_stall = this._7_2_2.value;
        this.mpr._7_3_reinigung = this._7_2_3.value === true ? 1 : 0;
        this.mpr._7_3_desinfektion = this._7_2_4.value === true ? 1 : 0;
        this.mpr._7_3_reinigung_futterleitung =
            this._7_2_5.value === true ? 1 : 0;
        this.mpr._7_3_reinigung_futtertroege =
            this._7_2_6.value === true ? 1 : 0;
        this.mpr._7_3_routinemaessig = this._7_2_7.value === true ? 1 : 0;
        this.mpr._7_3_nach_antibiotika = this._7_2_8.value === true ? 1 : 0;
        this.mpr._7_3_reinigung_futtersilo = this._7_2_9.value === true ? 1 : 0;
        // this.mpr._7_4 = this._7_2_10_nach_antibiotika.value === true ? 1 : 0;
        this.mpr._7_4_durch_fremdfirma = this._7_2_11.value === true ? 1 : 0;
        this.mpr._7_4_selbst = this._7_2_12.value === true ? 1 : 0;

        this.mpr._7_5_y_n = this._7_3_1.value;
        this.mpr._7_5_y_n_anzahl = this._7_3_3.value;
        this.mpr._7_5_mit_stroh = this._7_3_4.value === true ? 1 : 0;
        this.mpr._7_5_sonstiges_y_n_text = this._7_3_5.value;

        /*this.mpr._7_6_sonstige_absonder = this._7_3_6.value === true ? 1 : 0;
        this.mpr._7_6_sonstige_absonder_text = this._7_3_7.value;*/

        // 8
        this.mpr._8_1_vorgeimpft_y_n = this._8_1_1.value === true ? 1 : 0;
        this.mpr._8_1_vorgeimpft_y_n_text = this._8_1_2.value;

        this.mpr._8_1_im_bestand_geimpft_y_n =
            this._8_1_3.value === true ? 1 : 0;
        this.mpr._8_1_im_bestand_geimpft_y_n_text = this._8_1_4.value;

        this.mpr._8_2_endoparasiten_y_n = this._8_1_5.value;
        this.mpr._8_2_ektoparasiten_y_n = this._8_1_6.value;

        this.mpr._8_2_sonstiges_y_n = this._8_1_7.value === true ? 1 : 0;
        this.mpr._8_2_sonstiges_y_n_text = this._8_1_8.value;

        // 9
        this.mpr._9_1_atemwege = this._9_1_1.value === true ? 1 : 0;
        this.mpr._9_1_verdauungsapparat = this._9_1_2.value === true ? 1 : 0;
        this.mpr._9_1_bewegungssapparat = this._9_1_3.value === true ? 1 : 0;
        this.mpr._9_1_sonstiges_y_n = this._9_1_4.value === true ? 1 : 0;
        this.mpr._9_1_sonstiges_y_n_text = this._9_1_5.value;

        this.mpr._9_2_anzahl_kalenderhalbjahr = this._9_2_1.value;
        this.mpr._9_2_anzahl_vorheriges_halbjahr = this._9_2_2.value;

        this.mpr._9_3_angaben_zu_krankheit = this._9_3_1.value;

        this.mpr._9_4_erregernachweis_y_n =
            this._9_4_erregernachweis_y_n_ctrl.value === true ? 1 : 0;
        this.mpr._9_4_erregernachweis_y_n_text = this._9_4_2.value;

        this.mpr._9_4_sektion_y_n =
            this._9_4_sektion_y_n_ctrl.value === true ? 1 : 0;
        this.mpr._9_4_sektion_y_n_text = this._9_4_4.value;

        this.mpr._9_4_sonstiges_y_n = this._9_4_5.value === true ? 1 : 0;
        this.mpr._9_4_sonstiges_y_n_text = this._9_4_6.value;

        // 10
        this.mpr._10_1_ergebnis_beratung = this._10_1_1.value;
        if (!this._10_2_1.value) {
            this._10_2_1.setValue(new Date());
        }
        this.mpr._10_1_datum = this._10_2_1.value;
        this.mpr._10_1_text_streichen_y_n = this._10_3_1.value === true ? 1 : 0;

        // 11
        this.mpr._11_1_massnahmen = this._11_1_1.value;
        this.mpr._11_1_zeitraum_umsetzung = this._11_2_1.value;

        this.mpr._11_2_ort = this._11_3_1.value;
        if (!this._11_3_2.value) {
            this._11_3_2.setValue(new Date());
        }
        this.mpr._11_2_datum = this._11_3_2.value;
        this.mpr._11_2_verantwortlicher = this._11_3_3.value;

        // Neue Felder 08/24 --------------------------------------

        this.mpr._1_stallnummer_1 = this._1_1_3_stallnummer_1.value;
        this.mpr._1_stallnummer_2 = this._1_1_3_stallnummer_2.value;
        this.mpr._1_stallnummer_3 = this._1_1_3_stallnummer_3.value;
        this.mpr._1_stallnummer_4 = this._1_1_3_stallnummer_4.value;
        this.mpr._1_stallplatze_1 = this._1_1_3_stallplatze_1.value;
        this.mpr._1_stallplatze_2 = this._1_1_3_stallplatze_2.value;
        this.mpr._1_stallplatze_3 = this._1_1_3_stallplatze_3.value;
        this.mpr._1_stallplatze_4 = this._1_1_3_stallplatze_4.value;
        this.mpr._2_integration = this._2_integration.value;
        this.mpr._3_biosiegel = this._3_biosiegel.value;
        this.mpr._3_tierwohlteilnahme = this._3_tierwohlteilnahme.value;
        this.mpr._3_sonstige_programme = this._3_sonstige_programme.value;
        this.mpr._3_haltungsdauer = this._3_haltungsdauer.value;
        this.mpr._3_gummiauflage = this.get0or1(this._3_gummiauflage.value);
        this.mpr._3_planbefestigt = this.get0or1(this._3_planbefestigt.value);
        this.mpr._3_trockensteher = this.get0or1(this._3_trockensteher.value);
        this.mpr._3_faersenmast = this.get0or1(this._3_faersenmast.value);
        this.mpr._3_nutzungsdauer = this._3_nutzungsdauer.value;
        this.mpr._3_herdenalter = this._3_herdenalter.value;
        this.mpr._3_zellzahl = this._3_zellzahl.value;
        this.mpr._3_mast_sonstiges = this._3_mast_sonstiges.value;
        this.mpr._3_kuhgebundene_aufzucht = this._3_kuhgebundene_aufzucht.value;
        this.mpr._4_ventilator = this.get0or1(this._4_ventilator.value);
        this.mpr._4_airtubes = this.get0or1(this._4_airtubes.value);
        this.mpr._5_grassilage = this.get0or1(this._5_grassilage.value);
        this.mpr._5_treber = this.get0or1(this._5_treber.value);
        this.mpr._5_trester = this.get0or1(this._5_trester.value);
        this.mpr._5_rueben = this.get0or1(this._5_rueben.value);
        this.mpr._5_weide = this.get0or1(this._5_weide.value);
        this.mpr._5_vollautomatisch = this.get0or1(this._5_vollautomatisch.value);
        this.mpr._5_futter_sonstiges = this._5_futter_sonstiges.value;
        this.mpr._5_untersuchung_am = this._5_untersuchung_am.value;
        this.mpr._6_topdressing = this.get0or1(this._6_topdressing.value);
        this.mpr._6_vermischt = this.get0or1(this._6_vermischt.value);
        this.mpr._6_intramammaer = this.get0or1(this._6_intramammaer.value);
        this.mpr._6_intrauterin = this.get0or1(this._6_intrauterin.value);
        this.mpr._7_reinigungsmittel = this._7_reinigungsmittel.value;
        this.mpr._7_reinigung_fachfirma = this.get0or1(this._7_reinigung_fachfirma.value);
        this.mpr._7_reinigung_selbst = this.get0or1(this._7_reinigung_selbst.value);
        this.mpr._7_desinfektionsmittel = this._7_desinfektionsmittel.value;
        this.mpr._7_desinfektion_fachfirma = this.get0or1(this._7_desinfektion_fachfirma.value);
        this.mpr._7_desinfektion_selbst = this.get0or1(this._7_desinfektion_selbst.value);
        this.mpr._7_dokumentation = this._7_dokumentation.value;
        this.mpr._7_wasser_desinfektionsmittel = this._7_wasser_desinfektionsmittel.value;
        this.mpr._7_wasser_routine = this.get0or1(this._7_wasser_routine.value);
        this.mpr._7_wasser_antibiotika = this.get0or1(this._7_wasser_antibiotika.value);
        this.mpr._7_desinfektionsmatten = this._7_desinfektionsmatten.value;
        this.mpr._7_desinfektionsmatten_text = this._7_desinfektionsmatten_text.value;
        this.mpr._7_abkalbebuchten = this._7_abkalbebuchten.value;
        this.mpr._7_abkalbebuchten_anzahl = this._7_abkalbebuchten_anzahl.value;
        this.mpr._7_abkalbebuchten_stroh = this.get0or1(this._7_abkalbebuchten_stroh.value);
        this.mpr._7_abkalbebuchten_sonstiges = this._7_abkalbebuchten_sonstiges.value;
        this.mpr._8_kryptosporidien = this._8_kryptosporidien.value;
        this.mpr._8_kokzidien = this._8_kokzidien.value;
        this.mpr._9_euter = this.get0or1(this._9_euter.value);
        this.mpr._9_geschlechtsapparat = this.get0or1(this._9_geschlechtsapparat.value);
        this.mpr._9_stoffwechsel = this.get0or1(this._9_stoffwechsel.value);
        this.mpr._9_leitkeime = this._9_leitkeime.value;
        this.mpr._9_uberis = this.get0or1(this._9_uberis.value);
        this.mpr._9_aureus = this.get0or1(this._9_aureus.value);
        this.mpr._9_ecoli = this.get0or1(this._9_ecoli.value);
        this.mpr._9_agalactiae = this.get0or1(this._9_agalactiae.value);
        this.mpr._9_dysagalactiae = this.get0or1(this._9_dysagalactiae.value);
        this.mpr._9_mykoplasmen = this.get0or1(this._9_mykoplasmen.value);
        this.mpr._9_leitkeime_text = this._9_leitkeime_text.value;
        this.mpr._4b_melkstand = this.get0or1(this._4b_melkstand.value);
        this.mpr._4b_melkstand_art = this._4b_melkstand_art.value;
        this.mpr._4b_melkstand_plaetze = this._4b_melkstand_plaetze.value;
        this.mpr._4b_melkroboter = this.get0or1(this._4b_melkroboter.value);
        this.mpr._4b_modell = this._4b_modell.value;
        this.mpr._4b_leistungspruefung = this._4b_leistungspruefung.value;
        this.mpr._4b_in_der_herde = this.get0or1(this._4b_in_der_herde.value);
        this.mpr._4b_am_ende = this.get0or1(this._4b_am_ende.value);
        this.mpr._4b_wartung = this._4b_wartung.value;
        this.mpr._4b_wartung_haeufigkeit = this._4b_wartung_haeufigkeit.value;
        this.mpr._4b_tausch = this._4b_tausch.value;
        this.mpr._4b_tausch_haeufigkeit = this._4b_tausch_haeufigkeit.value;
        this.mpr._4b_zitzendippen = this._4b_zitzendippen.value;
        this.mpr._4b_einwegtuch = this.get0or1(this._4b_einwegtuch.value);
        this.mpr._4b_mehrwegtuch = this.get0or1(this._4b_mehrwegtuch.value);
        this.mpr._4b_zitzendesinfektion = this.get0or1(this._4b_zitzendesinfektion.value);
        this.mpr._4b_handschuhe = this.get0or1(this._4b_handschuhe.value);
        this.mpr._4b_schutzkleidung = this._4b_schutzkleidung.value;
        this.mpr._8_1_im_bestand_geimpft_y_n_2 = this.get0or1(this._8_1_im_bestand_geimpft_y_n_2.value);
        this.mpr._8_1_im_bestand_geimpft_y_n_text_2 = this._8_1_im_bestand_geimpft_y_n_text_2.value;
        this.mpr._8_bestandsbetreuung = this._8_bestandsbetreuung.value;
        this.mpr._8_fruchtbarkeit = this.get0or1(this._8_fruchtbarkeit.value);
        this.mpr._8_eutergesundheit = this.get0or1(this._8_eutergesundheit.value);
        this.mpr._8_klauengesundheit = this.get0or1(this._8_klauengesundheit.value);
        this.mpr._8_tiergesundheit = this.get0or1(this._8_tiergesundheit.value);
        this.mpr._8_bestandsbetreuung_sonstiges = this._8_bestandsbetreuung_sonstiges.value;
        this.mpr._8_klauenpflege_selbst = this.get0or1(this._8_klauenpflege_selbst.value);
        this.mpr._8_klauenpflege_fachfirma = this.get0or1(this._8_klauenpflege_fachfirma.value);
        this.mpr._8_klauenpflege_frequenz = this._8_klauenpflege_frequenz.value;
        this.mpr._8_trockenstehzeit_6 = this.get0or1(this._8_trockenstehzeit_6.value);
        this.mpr._8_trockenstehzeit_6_8 = this.get0or1(this._8_trockenstehzeit_6_8.value);
        this.mpr._8_trockenstehzeit_8 = this.get0or1(this._8_trockenstehzeit_8.value);
        this.mpr._8_trockenstehzeit_0 = this.get0or1(this._8_trockenstehzeit_0.value);
        this.mpr._8_reduk_melkhaeufigkeit = this.get0or1(this._8_reduk_melkhaeufigkeit.value);
        this.mpr._8_reduk_abrupt = this.get0or1(this._8_reduk_abrupt.value);
        this.mpr._8_reduk_energie = this.get0or1(this._8_reduk_energie.value);
        this.mpr._8_art_sonstiges = this._8_art_sonstiges.value;
        this.mpr._8_antibiotischer_schutz = this._8_antibiotischer_schutz.value;
        this.mpr._8_zitzenversiegler = this._8_zitzenversiegler.value;

        // --------------------------------------------------------

        if (abschliessen) {
            this.mpr.status_id = 62;
        } else {
            this.mpr.status_id = 61;
        }

        this.mpr.befunde_anhaengen = this.befundeAnhaengenCtrl.value ? 1 : 0;
        this.mpr.bbp_anhaengen = this.bbpAnhaengenCtrl.value ? 1 : 0;

        this.mpr.abrechnen = this.abrechnenCtrl.value ? 1 : 0;

        this.api
            .updateGeneric(this.apiBaseUrl + 'massnahmenplan_rind/update.php', this.mpr)
            .subscribe({
                next: (response) => {
                    // this.notification.infoMessage('Maßnahmenplan erfolgreich gespeichert!');
                    this.mpr = response;
                    // this.initForm();
                    if (abschliessen) {
                        this.mnpRindService.showPdf(this.mpr);
                    }
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Der Maßnahmenplan konnte nicht gespeichert werden!',
                        error
                    );
                    this.mpr.status_id = 61;
                }
            });
    }

    setTierarzt(vet: Vet) {
        if (!vet) {
            this.mpr.tierarzt_id = null;
        } else {
            this.mpr.tierarzt_id = vet.tierarzt_id;
        }
    }

    setValue10and11(textbaustein: Textbaustein) {
        if (textbaustein) {
            this._10_1_1.setValue(textbaustein.textbaustein1);
            this._11_1_1.setValue(textbaustein.textbaustein2);
            this._11_2_1.setValue(textbaustein.textbaustein3);
        }
    }

    getTextbaustein10und11() {
        const tc = new TextbausteinConfig();
        tc.count = 3;
        tc.titel1 = 'Ergebnis der Beratung';
        tc.titel2 = 'Maßnahmen';
        tc.titel3 = 'Zeitraum der Umsetzung';
        tc.text1 = this._10_1_1.value;
        tc.text2 = this._11_1_1.value;
        tc.text3 = this._11_2_1.value;
        tc.typ = 'mnprind';
        return tc;
    }

    getTextbaustein() {
        const t = new TextbausteinConfig();
        t.typ = 'mnprind';
        return t;
    }

    isEditable() {
        if (this.mpr.status_id === 61) {
            return true;
        }
        return false;
    }

    showDokumente() {
        this.pdf.openPdfGlobal(
            this.mpr.kunde_id,
            new Date(this.zeitraum_von),
            new Date(this.zeitraum_bis),
            'datum',
            { abgabebelege: true, befunde: true, antibiogramme: true }
        );
    }

    downloadBBP() {
        this.mnpRindService.openAnlageA(
            this.mpr.kunde_id,
            this._0_1_3.value,
            this._0_1_2.value,
            this.tierartCtrl.value,
            false,
            false,
            true
        );
    }

    downloadBefunde() {
        this.mnpRindService.openAnlageA(
            this.mpr.kunde_id,
            this._0_1_3.value,
            this._0_1_2.value,
            this.tierartCtrl.value,
            false,
            true,
            false
        );
    }

    downloadAnlageA() {
        this.mnpRindService.openAnlageA(
            this.mpr.kunde_id,
            this._0_1_3.value,
            this._0_1_2.value,
            this.tierartCtrl.value,
            true,
            false,
            false
        );
    }

    setAngabenKrankheitsgeschehen(textbaustein: Textbaustein) {
        if (textbaustein) {
            this._9_3_1.setValue(textbaustein.textbaustein1);
        }
    }

    setErregernachweis(textbaustein: Textbaustein) {
        if (textbaustein) {
            this._9_4_2.setValue(textbaustein.textbaustein1);
        }
    }

    setSektion(textbaustein: Textbaustein) {
        if (textbaustein) {
            this._9_4_4.setValue(textbaustein.textbaustein1);
        }
    }

    setSonstiges(textbaustein: Textbaustein) {
        if (textbaustein) {
            this._9_4_6.setValue(textbaustein.textbaustein1);
        }
    }

    signMnp() {

        if (this.benutzerprofil?.unterschrift_png) {
            this.dokumentUnterschreiben(this.mpr);
        } else {
            const signature = new Signature();
            signature.benutzer = 1;
            this.unterschriftService.unterschreiben(signature).subscribe({
                next: result => {
                    if (result) {
                        this.dokumentUnterschreiben(this.mpr);
                    }
                }
            });
        }

    }

    isKunde() {
        return this.localStorageService.isCurrentUserKundeAdmin();
    }

    private dokumentUnterschreiben(mnp: MassnahmenplanRind) {
        this.api.post(this.apiBaseUrl + 'massnahmenplan_rind/update_unterschrift.php', mnp).subscribe({
            next: response => {
                this.messageService.infoMessage('Ihre Unterschrift wurde erfolgreich im Dokument eingefügt!');
                this.loadMpr()
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}
