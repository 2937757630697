import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {HttpEventType, HttpHeaders, HttpResponse} from '@angular/common/http';
import {MessageService} from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';

@Component({
    selector: 'kiene-upload-sonstige-datei-dialog',
    templateUrl: './upload-sonstige-datei-dialog.component.html',
    styleUrls: ['./upload-sonstige-datei-dialog.component.scss']
})
export class UploadSonstigeDateiDialogComponent implements OnInit {

    client: Client;

    constructor(public dialogRef: MatDialogRef<UploadSonstigeDateiDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private messageService: MessageService) {
        this.client = this.data?.client;
    }

    ngOnInit(): void {
    }

    handleFile(event: any) {
        const files: File[] = event.target.files;
        for (const f of files) {
            console.log(f.name);
            const fd = new FormData();
            fd.append('titel', f.name);
            fd.append('kunde_id', this.client.kunde_id.toString());
            fd.append('dokumenttyp_id', '7');
            fd.append('dokument', f);

            const options = {
                headers: new HttpHeaders('Phito-Type: fileupload'),
                reportProgress: true,
            };

            this.api.postFormData(this.apiBaseUrl + 'dokument/create.php', fd, options).subscribe({
                next: response => {
                    if (response.type === HttpEventType.UploadProgress) {
                        const percentDone = Math.round(100 * response.loaded / response.total);
                        console.log('Uploaded ' + percentDone + '% of file ' + f.name);
                    } else if (response instanceof HttpResponse) {
                        console.log('Uploaded file ' + f.name + ' completely!');
                    }
                },
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                },
                complete: () => {
                    this.dialogRef.close();
                }
            });
        }
    }

}
