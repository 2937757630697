import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { StammdatenService } from "../../stammdaten.service";
import { MessageService } from "../../../../services/message-service/message.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
    selector: "kiene-vvvo-details-actions-hit-pin-dialog",
    templateUrl: "./vvvo-details-actions-hit-pin.component.html",
    styleUrls: ["./vvvo-details-actions-hit-pin.component.scss"],
})
export class VvvoDetailsActionsHitPinComponent implements OnInit {
    constructor(
        private stammdatenService: StammdatenService,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    kundeId: number;
    showPasswort = false;
    isSaving = false;
    neuePinCtrl: UntypedFormControl = new UntypedFormControl("", [Validators.required]);
    neuePinWdhCtrl: UntypedFormControl = new UntypedFormControl("", [Validators.required]);

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.kundeId = params["id"];
        });

        this.neuePinCtrl.valueChanges.subscribe(() => {
            this.resetErrors();
        });

        this.neuePinWdhCtrl.valueChanges.subscribe(() => {
            this.resetErrors();
        });
    }

    resetErrors() {
        this.neuePinCtrl.setErrors(null);
        this.neuePinWdhCtrl.setErrors(null);
    }

    checkPins() {
        if (this.neuePinCtrl.value === this.neuePinWdhCtrl.value) {
            this.resetErrors();
            return true;
        } else {
            this.neuePinCtrl.setErrors({ "not-matching": true });
            this.neuePinWdhCtrl.setErrors({ "not-matching": true });
            return false;
        }
    }

    pinAendern() {
        if (this.checkPins()) {
            this.isSaving = true;
            this.stammdatenService
                .hitPinAendern(this.kundeId, this.neuePinCtrl.value)
                .subscribe({
                    next: () => {
                        this.messageService.infoMessage(
                            "PIN erfolgreich geändert"
                        );
                        this.isSaving = false;
                        this.router
                            .navigate(["../"], { relativeTo: this.route })
                            .then();
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            "PIN konnte nicht geändert werden." +
                                " Ggf. stimmt PIN nicht mit PIN aus HIT überein.",
                            err
                        );
                        this.isSaving = false;
                    },
                });
        }
    }
}
