<mat-form-field *ngIf="!hideOnEmptyList || this.geschlechterFuerGruppe.length > 0"
    class="abgang-form-input no-padding-margin-mat-form-field" style="margin: 6px 0 0 0; width: 100%">
    <mat-icon style="padding-right: 6px" matPrefix>search</mat-icon>
    <mat-label>{{label}}</mat-label>
    <mat-select multiple [compareWith]="compareGeschlecht" [formControl]="selectedGeschlechtCtrl">
        <mat-option *ngFor="let geschlecht of geschlechterFuerGruppe" [value]="geschlecht">{{geschlecht.bezeichnung}}
            ({{geschlecht.anzahl}} Tiere)</mat-option>
    </mat-select>
    <button *ngIf="selectedGeschlechtCtrl.value && geschlechterFuerGruppe.length > 0" matSuffix mat-icon-button
        aria-label="Clear" (click)="resetGeschlechtCtrl($event)">
        <mat-icon>close</mat-icon>
    </button>
</mat-form-field>