import {Component, Inject, OnInit} from '@angular/core';
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {API_BASE_URL_SERVICE} from "../../../../../projects/kiene-core/src/lib/kiene-core.config";
import {MessageService} from "../../../services/message.service";
import {KieneTableService} from "../kiene-table.service";
import {SpalteKonfiguration, SpaltenKonfigurationSendObject} from "../table-descriptor";

@Component({
    selector: 'kiene-column-filter-dialog',
    templateUrl: './column-filter-dialog.component.html',
    styleUrls: ['./column-filter-dialog.component.scss']
})
export class ColumnFilterDialogComponent implements OnInit {

    isSaving = false;

    constructor(public dialogRef: MatDialogRef<ColumnFilterDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { cols: SpalteKonfiguration[], identifier: string },
                private api: KieneTableService,
                private messageService: MessageService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit(): void {
    }

    save() {
        this.isSaving = true;
        const sendObject = new SpaltenKonfigurationSendObject();
        sendObject.spalten = this.data.cols;
        sendObject.tabelle = this.data.identifier;
        this.api.updateEntity(this.apiBaseUrl + 'tabelle_spalte/update.php', sendObject).subscribe({
            next: () => {
                this.isSaving = false;
                this.dialogRef.close(sendObject.spalten);
            }, error: err => {
                this.isSaving = false;
                this.messageService.errorMessageSimple(err);
                this.onCancelClick();
            }
        })
    }

    onCancelClick(): void {
        this.dialogRef.close(undefined);
    }

    public change(m: SpalteKonfiguration, event: MatCheckboxChange): void {
        m.sichtbar = +event.checked;
    }

    public checkDisable(): boolean {
        for (const cb of this.data.cols) {
            if (cb.sichtbar > 0) {
                return false;
            }
        }
        return true;
    }
}
