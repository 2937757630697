<h1 mat-dialog-title>Labor {{edit ? 'bearbeiten' : 'erstellen'}}</h1>
<div mat-dialog-content>
  <mat-form-field style="width: 100%;">
    <input matInput type="text" placeholder="Labor" [formControl]="laborCtrl">
  </mat-form-field>
  <mat-form-field style="width: 100%;">
    <input matInput type="text" placeholder="Straße" [formControl]="strasseCtrl">
  </mat-form-field>
  <mat-form-field style="width: 100%;">
    <input matInput type="text" placeholder="PLZ" [formControl]="plzCtrl">
  </mat-form-field>
  <mat-form-field style="width: 100%;">
    <input matInput type="text" placeholder="Ort" [formControl]="ortCtrl">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Abbrechen</button>
  <button mat-flat-button color="primary" (click)="save()" cdkFocusInitial [disabled]="laborCtrl.invalid">Speichern</button>
</div>
