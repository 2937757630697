<h1 mat-dialog-title>Abholliste auswählen</h1>

<div mat-dialog-content>
    <p>Es wurden {{ getAnzahlTiere() }} Tiere ausgewählt.</p>
    <p><strong>Wählen Sie eine Abholliste für diese Tiere aus!</strong></p>
    <div class="one-row" style="padding-top: 24px">
        <kiene-autocomplete
            #abhollisteAutocomplete
            (elementSelected)="setAbholliste($event)"
            [apiUrl]="abhollisteUrl"
            [placeholder]="'Abhollisten'"
            [kieneObject]="abholliste"
            [panelOpen]="false"
            [displayFields]="['kommentar', 'abholdatum' ]"
            [params]="params"
            [noPaddingNoMargin]="true"
            [separator]="' - '"
            [required]="true"
            style="width: 90%"
        ></kiene-autocomplete>
        <button
            mat-icon-button
            matTooltip="Neue Abholliste erstellen"
            (click)="openCreateNewAbhollisteDialog()"
            color="accent"
            style="width: 5%;"
        >
            <mat-icon>add</mat-icon>
        </button>
    </div>


</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" [disabled]="!abholliste" (click)="save()">Ausw&auml;hlen</button>
</div>

