import {Component, Inject, OnInit} from '@angular/core';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {Dokument} from '../dokument';
import {MatDialog} from '@angular/material/dialog';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {DocumentsService} from '../../documents.service';
import {Benutzerprofil} from '../../../../../projects/kiene-core/src/lib/components/stammdaten/mein-profil/mein-profil';
import {MessageService} from '../../../services/message.service';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {Signature} from '../../../../../projects/kiene-core/src/lib/components/unterschriften/signature';
import {
    UnterschriftenService
} from '../../../../../projects/kiene-core/src/lib/components/unterschriften/unterschriften.service';

@Component({
    selector: 'kiene-dokumente-tab-view',
    templateUrl: './dokumente-tab-view.component.html',
    styleUrls: ['./dokumente-tab-view.component.scss']
})
export class DokumenteTabViewComponent implements OnInit {

    documentType = 1;
    documentHeadline = 'Tierärztlicher Betreuungsvertrag';
    istKunde = false;
    istViehhaendler = false;
    bereitZumUnterschreiben: Dokument[] = [];

    constructor(private localStorageService: LocalStorageService,
                private dialog: MatDialog,
                private kieneTableService: KieneTableService,
                private documentsService: DocumentsService,
                private messageService: MessageService,
                private unterschriftService: UnterschriftenService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService) {
    }

    ngOnInit() {
        this.istKunde = this.localStorageService.isCurrentUserKunde() || this.localStorageService.isCurrentUserViehhaendler();
        this.ladeAlleDokumenteBereitZumUnterschreiben();
    }

    setDocumentType(type: number, headline: string) {
        this.documentType = type;
        this.documentHeadline = headline;
    }

    ladeAlleDokumenteBereitZumUnterschreiben() {
        this.documentsService.ladeDokumenteBereitZumUnterschreiben(this.localStorageService.getCurrentClient()).subscribe({
            next: response => {
                this.bereitZumUnterschreiben = response.records;
            },
            error: err => {
                this.messageService.errorMessage('Fehler', err);
            }
        });
    }

    alleProtokollieren() {
        if ((this.localStorageService.isCurrentUserVeterinarian() || this.localStorageService.isCurrentUserVerwaltung()) && !this.localStorageService.getCurrentClient()) {
            this.messageService.infoMessage('Bitte einen Kunden auswählen!');
            return;
        }

        const inp = new ConfirmDialogInput();
        inp.headline = 'Alle Dokumente unterscheiben';
        inp.text = 'Wollen Sie alle noch offenen Dokumente unterschreiben?. Wenn Sie eine Unterschrift in Ihrem Benutzerprofil hinterlegt haben, wird diese für die Dokumente genutzt. Falls nicht, öffnet sich ein Dialog, in dem Sie unterscheiben können.';
        inp.okButton = 'Unterschreiben';
        this.dialog.open(ConfirmDialogComponent, {maxWidth: '600px', data: inp}).afterClosed().subscribe({
            next: result => {
                if (result?.ok && result?.confirmed) {
                    this.signAllDocuments();
                }
            }
        });

    }

    signAllDocuments() {
        this.documentsService.ladeBenutzerprofil().subscribe({
            next: (benutzerprofil: Benutzerprofil) => {
                if (benutzerprofil.unterschrift_png) {
                    this.documentsService.alleDokumenteUnterschreiben(this.localStorageService.getCurrentClient()).subscribe({
                        next: result => {
                            this.messageService.infoMessage('Alle noch offenen Dokumente wurden unterschrieben!');
                            this.kieneTableService.reload();
                            this.ladeAlleDokumenteBereitZumUnterschreiben();
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler!', err);
                            this.kieneTableService.reload();
                            this.ladeAlleDokumenteBereitZumUnterschreiben();
                        }
                    });
                } else {
                    const signature = new Signature();
                    signature.benutzer = 1;
                    this.unterschriftService.unterschreiben(signature).subscribe({
                        next: result => {
                            if (result) {
                                this.documentsService.alleDokumenteUnterschreiben(this.localStorageService.getCurrentClient()).subscribe({
                                    next: result => {
                                        this.messageService.infoMessage('Alle noch offenen Dokumente wurden unterschrieben!');
                                        this.kieneTableService.reload();
                                        this.ladeAlleDokumenteBereitZumUnterschreiben();
                                    },
                                    error: err => {
                                        this.messageService.errorMessage('Fehler!', err);
                                        this.kieneTableService.reload();
                                        this.ladeAlleDokumenteBereitZumUnterschreiben();
                                    }
                                });
                            }
                        }
                    });
                }
            }
        });
    }

    private getNichtUnterschriebenKunde(dokumente: Dokument[]) {
        let nichtUnterschrieben: Dokument[] = [];
        for (const dok of dokumente) {
            if (dok.unterschrift_kunde === 0) {
                nichtUnterschrieben.push(dok);
            }
        }
        return nichtUnterschrieben;
    }
}
