import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Untersuchungsmaterial } from '../untersuchungsmaterial';
import { UntypedFormControl } from '@angular/forms';
import { Tierart } from '../../../administration/tierarten/tierart';

@Component({
  selector: 'kiene-add-untersuchungsmaterial-dialog',
  templateUrl: './add-untersuchungsmaterial-dialog.component.html',
  styleUrls: ['./add-untersuchungsmaterial-dialog.component.scss']
})
export class AddUntersuchungsmaterialDialogComponent implements OnInit {

  untersuchungsmaterial: Untersuchungsmaterial;
  tierart: Tierart;
  edit = false;
  nameCtrl = new UntypedFormControl();

  constructor(public dialogRef: MatDialogRef<AddUntersuchungsmaterialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.untersuchungsmaterial = data.untersuchungsmaterial;
    this.edit = data.edit;
  }

  ngOnInit() {
    if (this.untersuchungsmaterial) {
      this.edit = true;
      this.init();
    } else {
      this.untersuchungsmaterial = new Untersuchungsmaterial();
      this.edit = false;
    }
  }

  init() {
    this.nameCtrl.setValue(this.untersuchungsmaterial.bezeichnung);
  }

  setTierart(event: Tierart) {
    this.tierart = event;
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.untersuchungsmaterial.bezeichnung = this.nameCtrl.value;
    this.untersuchungsmaterial.tierart_id = this.tierart.tierart_id;
    this.dialogRef.close(this.untersuchungsmaterial);
  }

}
