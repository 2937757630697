import {Component, Inject, OnInit} from '@angular/core';
import {TableDescriptor, ToolbarButton} from '../../components/kiene-table/table-descriptor';
import {Condition, Operator, TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {MessageService} from '../../services/message.service';
import {KieneBooleanTableColumn, NumberTableColumn, TableColumn} from '../../components/kiene-table/table-column';
import {FileUploadService} from '../../dialogs/file-upload-dialog/file-upload.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Gemeinkosten} from '../gemeinkosten/gemeinkosten';
import {
    GemeinkostenDetailsDialogComponent
} from '../gemeinkosten/gemeinkosten-details-dialog/gemeinkosten-details-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {
    ConfirmDialogInput,
    ConfirmDialogResult
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {
    ConfirmDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {
    FilterItem,
    FilterItemType
} from '../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import {Router} from '@angular/router';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-honorare-und-gehaelter',
    templateUrl: './honorare-und-gehaelter.component.html',
    styleUrls: ['./honorare-und-gehaelter.component.scss']
})
export class HonorareUndGehaelterComponent implements OnInit {
    tableDescriptor = new TableDescriptor();

    constructor(@Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private tableService: KieneTableService,
                private localStorageService: LocalStorageService,
                private messageService: MessageService,
                private fileUploadService: FileUploadService,
                private dialog: MatDialog,
                private http: HttpClient,
                private router: Router,
                private pdfService: PdfViewerDesktopService) {
    }

    ngOnInit(): void {
        this.tableDescriptor.headline = 'Honorare und Gehälter';
        this.tableDescriptor.uniqueIdentifier = 'Honorare und Gehälter';
        this.tableDescriptor.alternativeApiUrl = this.apiBaseUrl + 'kosten/read_honorare.php';

        this.tableDescriptor.route = '/wawi/bestellung-extern/honorareundgehaelter/';
        this.tableDescriptor.nameOfIdField = 'kosten_id';
        this.tableDescriptor.searchPlaceholder = 'Honorare u. Gehälter durchsuchen';

        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;

        this.tableDescriptor.fileUploadApi = 'kosten/update_pdf.php';

        const cols: TableColumn[] = [];

        cols.push(new TableColumn('kosten_id', 'ID'));
        cols.push(new TableColumn('tierarzt', 'Tierarzt'));
        cols.push(new KieneBooleanTableColumn('labor', 'Labor'));
        cols.push(new TableColumn('kommentar', 'Kommentar'));
        cols.push(new TableColumn('rechnung_nr', 'Rechnung Nr.'));
        cols.push(new NumberTableColumn('kosten_netto_gesamt', 'Kosten (netto)'));
        cols.push(new TableColumn('datum', 'Rechnungsdatum', 'date'));
        cols.push(new TableColumn('leistungsdatum_von', 'Lstg. von', 'date'));
        cols.push(new TableColumn('leistungsdatum_bis', 'Lstg. bis', 'date'));
        cols.push(new KieneBooleanTableColumn('freigegeben', 'Freigabe',
            {
                clickable: {
                    eventName: 'freigabe',
                    enabled: [new Condition('primanota_id', Operator.EXISTIERT_NICHT, null)],
                    permissionId: 4216
                }
            }
        ));
        cols.push(new KieneBooleanTableColumn('primanota_id', 'Primanota', {tooltipFieldname: 'primanota_id'}));
        if (this.localStorageService.currentUserHasPermission(4215)) {
            cols.push(new TableColumn('pdf_id', '', 'pdf'));
        }

        this.tableDescriptor.columns = cols;

        this.tableDescriptor.options.push(
            new TableOption('edit', 'Editieren', 'edit', 4212)
        );
        this.tableDescriptor.options.push(
            new TableOption('delete', 'Löschen', 'delete', 4214)
        );

        this.tableDescriptor.filterItems.push(new FilterItem('Freigegeben', '1', FilterItemType.CHECKBOX, 'freigegeben', false));
        this.tableDescriptor.filterItems.push(new FilterItem('Nicht Freigegeben', '0', FilterItemType.CHECKBOX, 'freigegeben', false));
        this.tableDescriptor.filterItems.push(new FilterItem('Ohne Primanota', null, FilterItemType.CHECKBOX, 'primanota_id'));

        this.tableDescriptor.toolbarButtons.push(new ToolbarButton('generatePrimanota', 'account_balance', 1832, 'Primanota erstellen'));

        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;
        this.tableDescriptor.showDetailsAsOption = true;

        this.tableDescriptor.permissionView = 4211;
        this.tableDescriptor.permissionCreate = 4212;
        this.tableDescriptor.permissionUpdate = 4212;
        this.tableDescriptor.permissionDelete = 4214;

        this.tableDescriptor.initialSortColumn = 'erstellt';
        this.tableDescriptor.initialSortDirection = 'desc';

        this.tableDescriptor.useFileDropEvent = false;

        this.fileUploadService.watchFileUploaded().subscribe((result) => {
            if (result) {
                this.tableService.reload();
            }
        });
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            this.openGemeinkostenDetailsDialog();
        } else if (event.name === 'edit') {
            this.openGemeinkostenDetailsDialog(event.value);
        } else if (event?.name === 'showPdf') {
            const input = new PdfViewerDialogInput(
                'kosten/read_pdf_honorare.php',
                new HttpParams().set(
                    'kosten_id',
                    event?.value?.kosten_id?.toString()
                ),
                'Honorare'
            );
            this.pdfService.openPdfViewer(input);
        } else if (event?.name === 'freigabe') {
            this.freigeben(event?.value);
        } else if (event?.name === 'delete') {
            const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
                data: {
                    title: 'Löschen',
                    text: 'Wollen Sie diese Gemeinkosten wirklich löschen?',
                },
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.tableService
                        .deleteEntity(this.apiBaseUrl + 'kosten/delete.php', event.value)
                        .subscribe(
                            (response) => {
                                this.messageService.infoMessage(
                                    'Gemeinkosten wurden erfolgreich gelöscht!'
                                );
                                this.tableService.reload();
                            },
                            (error) => {
                                this.messageService.errorMessage(
                                    'Gemeinkosten konnten nicht gelöscht werden!',
                                    error
                                );
                                this.tableService.reload();
                            }
                        );
                }
            });
        } else if (event.name === 'generatePrimanota') {
            this.generatePrimanota();
        }
    }

    generatePrimanota() {
        const input = new ConfirmDialogInput();
        input.headline = 'Primanota erstellen';
        input.text = 'Soll die Primanota für alle offenen Positionen in den Reitern "Betriebliche Kosten" und "Honorare und Gehälter" erstellt werden?';
        input.okButton = 'Erstellen';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.http
                        .post(this.apiBaseUrl + 'primanota/generieren_lieferanten.php', {kosten: 1})
                        .subscribe({
                            next: response => {
                                this.messageService.infoMessage(
                                    'Die Primanota wurde erfolgreich generiert!'
                                );
                                this.router.navigate(['/finanzen', 'primanota']);
                            },
                            error: err => {
                                this.messageService.errorMessage(
                                    'Es ist ein Fehler beim Generieren der Primanota aufgetreten!',
                                    err
                                );
                            }
                        });
                }
            }
        });
    }

    openGemeinkostenDetailsDialog(gemeinkosten?: Gemeinkosten) {
        const dialogRef = this.dialog.open(GemeinkostenDetailsDialogComponent, {
            data: {
                gemeinkosten: gemeinkosten,
                nurHonorar: true
            },
        });

        dialogRef.afterClosed().subscribe(() => this.tableService.reload());
    }

    freigeben(gemeinkosten: Gemeinkosten) {
        if (!gemeinkosten.primanota_id) {
            if (gemeinkosten.freigegeben > 0) {
                // zurücksetzen ohne Confirm
                gemeinkosten.freigegeben = 0;
                this.updateGemeinkosten(gemeinkosten);
            } else {
                const input = new ConfirmDialogInput();
                input.headline = 'Freigabe';
                input.text =
                    'Position "' + gemeinkosten.artikel_me + '" freigeben?';
                input.okButton = 'Freigeben';

                const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                    data: input,
                });

                dialogRef.afterClosed().subscribe({
                    next: (accepted: ConfirmDialogResult) => {
                        if (accepted?.confirmed) {
                            gemeinkosten.freigegeben = 1;
                            this.updateGemeinkosten(gemeinkosten);
                        } else {
                            this.tableService.reload();
                        }
                    }
                });
            }
        }
    }

    updateGemeinkosten(gemeinkosten: Gemeinkosten) {
        this.tableService.post(this.apiBaseUrl + 'kosten/update.php', gemeinkosten).subscribe({
            next: response => {
                this.messageService.infoMessage('Änderungen erfolgreich gespeichert!');
                this.tableService.reload();
            },
            error: err => {
                this.messageService.errorMessage('Änderungen konnten nicht gespeichert werden!', err);
                this.tableService.reload();
            }
        });
    }

    updateGemeinkostenDialog(gemeinkosten: Gemeinkosten) {
        if (!gemeinkosten?.primanota_id) {
            const dialogRef = this.dialog.open(GemeinkostenDetailsDialogComponent, {
                data: {
                    gemeinkosten: gemeinkosten,
                },
            });

            dialogRef.afterClosed().subscribe(() => this.tableService.reload());
        }
    }
}
