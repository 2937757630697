<div style="padding: 24px;">

    <h2>Klinische Untersuchung</h2>

    <mat-form-field>
        <mat-label>Tier</mat-label>
        <mat-select [formControl]="tierCtrl">
            <mat-option *ngFor="let t of tiere" [value]="t">{{ t?.name }}</mat-option>
        </mat-select>
    </mat-form-field>

    <div class="one-row">
        <h1 mat-dialog-title>Entwicklung {{ tierCtrl?.value?.name }}</h1>
        <button mat-icon-button color="accent" (click)="createHeimtierDaten()" style="margin-bottom: 12px;">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <div mat-dialog-content>
        <!--    Tabelle-->

        <table class="phito-table" style="width: 100%">
            <thead>
                <tr>
                    <th></th>
                    <th>Datum</th>
                    <th>Gewicht</th>
                    <th>Temperatur</th>
                    <th>Puls</th>
                    <th>Atmung</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let daten of heimtierDaten; let i = index;">
                    <td>{{ i + 1 }}</td>
                    <td>{{ daten?.erstellt | date: 'dd.MM.yyyy HH:mm' }}</td>
                    <td>{{ daten?.gewicht ?? '--' }} kg</td>
                    <td>{{ daten?.temperatur ?? '--' }} &deg;C</td>
                    <td>{{ daten?.puls ?? '--' }} /min</td>
                    <td>{{ daten?.atmung ?? '--' }} /min</td>
                    <td>
                        <button mat-icon-button (click)="editDaten(daten)"><mat-icon>edit</mat-icon></button>
                        <button mat-icon-button (click)="deleteDaten(daten)"><mat-icon>delete</mat-icon></button>
                    </td>
                </tr>
            </tbody>
        </table>


    </div>

</div>