import {Component, Inject, OnInit} from '@angular/core';
import {API_BASE_URL_SERVICE} from '../../../../kiene-core.config';
import {FormControl, Validators} from '@angular/forms';
import {Client} from '../../../../components/client/classes/client';
import {Freigabe} from './../freigabe';
import {KieneBackendApiService} from '../../../../services/backend-api/kiene-backend-api.service';
import {MessageService} from '../../../../services/message-service/message.service';
import {MatDialogRef} from '@angular/material/dialog';
import {HttpParams} from '@angular/common/http';

@Component({
    selector: 'kiene-freigabe-erstellen-dialog',
    templateUrl: './freigabe-erstellen-dialog.component.html',
    styleUrls: ['./freigabe-erstellen-dialog.component.scss']
})
export class FreigabeErstellenDialogComponent implements OnInit {
    vvvoCtrl = new FormControl(undefined, [Validators.required, Validators.minLength(12), Validators.maxLength(15)]);

    params = new HttpParams().set('freigaben', '0');

    kunde: Client;

    constructor(@Inject(API_BASE_URL_SERVICE) public apiBaseUrl: string,
                public dialogRef: MatDialogRef<FreigabeErstellenDialogComponent>,
                private api: KieneBackendApiService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
    }

    setClient(client: Client) {
        this.kunde = client;
    }

    freigabeErteilen() {
        if (this.vvvoCtrl.valid && this.kunde) {

            const freigabe = new Freigabe();
            freigabe.kunde_id = this.kunde?.kunde_id;
            freigabe.vvvo_freigabe = this.vvvoCtrl.value;

            this.api.post(this.apiBaseUrl + 'kunde_freigabe/create.php', freigabe).subscribe({
                next: response => {
                    this.messageService.infoMessage('Die Freigabe wurde erteilt!');
                    this.dialogRef.close(true);
                },
                error: err => {
                    this.messageService.errorMessage('Fehler', err);
                }
            });

        }
    }
}
