import {formatDate} from "@angular/common";
import {HttpParams} from '@angular/common/http';
import {Component, Inject, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {
    ConfirmDialogResult
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {GruppeTier} from '../../../../../projects/kiene-core/src/lib/components/gruppe-tier/classes/gruppe-tier';
import {GruppeTierService} from '../../../../../projects/kiene-core/src/lib/components/gruppe-tier/gruppe-tier.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from "../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service";
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {
    ConditionSetting,
    ConditionTableColumn,
    DateTableColumn,
    KieneBooleanTableColumn,
    NumberTableColumn,
    TableColumn,
} from '../../../components/kiene-table/table-column';
import {TableDescriptor, ToolbarButton,} from '../../../components/kiene-table/table-descriptor';
import {Condition, Operator, TableOption, TableOptionEvent,} from '../../../components/kiene-table/table-option';
import {MessageService} from '../../../services/message.service';
import {AbhollisteFilter} from "../abholliste-filter";
import {AbholListeDialogInput} from "./abhol-liste-dialog-input";
import {
    AbhollisteNumberInputDialogComponent
} from "./abhol-liste-number-input-dialog/abholliste-number-input-dialog.component";
import {AbhollisteFilterDialogComponent} from "./abholliste-filter-dialog/abholliste-filter-dialog.component";
import {AddTierToListeDialogComponent} from "./add-tier-to-liste-dialog/add-tier-to-liste-dialog.component";
import {Abholliste} from './abholliste';
import {AbhollisteNumberInputDialogResult} from './abhol-liste-number-input-dialog/abholliste-number-input-dialog';

@Component({
    selector: 'kiene-globale-tiersuche',
    templateUrl: './globale-tiersuche.component.html',
    styleUrls: ['./globale-tiersuche.component.scss'],
})
export class GlobaleTiersucheComponent implements OnInit, OnChanges, OnDestroy {
    tableDescriptor = new TableDescriptor();
    filterSubscription: Subscription = new Subscription();
    filterObject: AbhollisteFilter = new AbhollisteFilter();
    alterSimuliertCol: TableColumn;
    gewichtSimuliertCol: TableColumn;
    tageszunahmeCol: TableColumn;
    private tableServiceSubscription: Subscription = new Subscription();

    constructor(
        private dialog: MatDialog,
        private tableService: KieneTableService,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private gruppeTierService: GruppeTierService,
        private api: KieneBackendApiService,
    ) {
    }

    ngOnInit(): void {
        this.filterSubscription.unsubscribe();
        this.filterSubscription = this.api.getSpeicher('AbhollisteDialog').subscribe({
            next: (value: AbhollisteFilter) => {
                this.filterObject = value;
                this.filterAnwenden();
            }, error: err => {
                this.messageService.errorMessage('Abhollistenfilter konnte nicht geladen werden!', err);
            }
        });

        this.tableDescriptor.headline = 'Globale Tiersuche (akt. Gruppen)';
        this.tableDescriptor.uniqueIdentifier = 'Globale Tiersuche (akt. Gruppen)';
        this.tableDescriptor.alternativeApiUrl = this.apiBaseUrl + 'gruppe_tier/search.php';
        this.tableDescriptor.initialSortDirection = 'asc';
        this.tableDescriptor.initialSortColumn = 'ohrmarke_notnull';
        this.tableDescriptor.nameOfIdField = 'gt_id';
        this.tableDescriptor.route = '/gruppen/globale-suche/';
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.showDetailsAsOption = true;
        this.tableDescriptor.createAllowed = false;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.selectable = true;
        this.tableDescriptor.columns = this.getColumns();
        this.tableDescriptor.options = this.getOptions();
        this.tableDescriptor.toolbarButtons = this.getToolbarButtons();
        this.tableDescriptor.sendCurrentClient = true;
    }

    ngOnChanges() {

    }

    getColumns(): TableColumn[] {
        const columns = [];
        columns.push(new TableColumn('ohrmarke_notnull', 'Ohrmarke'));
        columns.push(new TableColumn('gruppe', 'Gruppe'));
        columns.push(new TableColumn('kunde', 'Kunde'));
        columns.push(new TableColumn('abteil', 'Abteil'));

        columns.push(
            new KieneBooleanTableColumn('ohrmarke_gescannt', 'Ohrmarke')
        );
        columns.push(new KieneBooleanTableColumn('pass_gescannt', 'Pass'));
        columns.push(new DateTableColumn('geburtsdatum', 'Geburtsdatum', 'dd.MM.yy'));

        columns.push(new TableColumn('rasse_kuerzel', 'Rasse'));
        const conditionSettingsGeschlecht = [
            new ConditionSetting([new Condition('geschlecht_id', Operator.GLEICH, 1)], {
                icon: 'male',
                color: 'gray',
                tooltip: 'Männlich',
            }),
            new ConditionSetting([new Condition('geschlecht_id', Operator.GLEICH, 2)], {
                icon: 'female',
                color: 'gray',
                tooltip: 'Weiblich',
            }),
        ];
        columns.push(
            new ConditionTableColumn(
                'geschlecht',
                'Geschlecht',
                conditionSettingsGeschlecht,
                {
                    sortFields: ['geschlecht_id'],
                }
            )
        );
        columns.push(new TableColumn('bvd_status', 'BVD'));

        const conditionSettings = [];
        conditionSettings.push(new ConditionSetting([new Condition('status_id_abholliste', Operator.GLEICH, 201)], {
            icon: 'check_circle',
            color: 'orange',
            tooltip: 'Abholliste in Bearbeitung'
        }));
        conditionSettings.push(new ConditionSetting([new Condition('status_id_abholliste', Operator.GLEICH, 202)], {
            icon: 'check_circle',
            color: 'green',
            tooltip: 'Abholliste abgeschlossen'
        }));
        conditionSettings.push(new ConditionSetting([new Condition('status_id_abholliste', Operator.EXISTIERT_NICHT, null)], {icon: 'radio_button_unchecked'}));
        columns.push(new ConditionTableColumn('abholliste_id', 'Abholliste', conditionSettings));

        columns.push(
            new DateTableColumn(
                'einstalldatum_notnull',
                'Einstalldatum',
                'dd.MM.yy'
            )
        );
        columns.push(new TableColumn('alter_wochen', 'Alter (W)'));
        columns.push(new TableColumn('alter_tage', 'Alter (T)'));
        this.alterSimuliertCol = new TableColumn('alter_tage_simuliert', 'Alter simuliert (T)', null, false);
        columns.push(this.alterSimuliertCol);
        this.gewichtSimuliertCol = new NumberTableColumn('gewicht_simuliert', 'Gewicht simuliert', '1.1-1', 'kg', {isActive: false});
        columns.push(this.gewichtSimuliertCol);
        this.tageszunahmeCol = new NumberTableColumn('tageszunahme', 'Tageszunahme', '1.0-0', 'g', {isActive: false});
        columns.push(this.tageszunahmeCol);
        return columns;
    }

    getOptions() {
        const options: TableOption[] = [];

        options.push(
            new TableOption(
                'pass_vorhanden_manuell',
                'Pass vorhanden',
                'badge',
                9122,
                {conditions: [new Condition('pass_gescannt', Operator.GLEICH, 0)]}
            )
        );

        options.push(
            new TableOption(
                'ohrmarke_vorhanden_manuell',
                'Ohrmarke vorhanden',
                'label',
                9122,
                {conditions: [new Condition('ohrmarke_gescannt', Operator.GLEICH, 0)]}
            )
        );

        return options;
    }

    getToolbarButtons(): ToolbarButton[] {
        const buttons = [];
        buttons.push(new ToolbarButton('filter', 'filter_alt', 9122, 'Filtereinstellungen bearbeiten'));
        buttons.push(new ToolbarButton('tier_add_liste', 'add', 9122, 'Hinzufügen von Tieren zu einer Abholliste'));
        return buttons;
    }

    catchOptionEvent(event: TableOptionEvent) {
        const gt = <GruppeTier>event.value;
        if (event.name === 'details') {
            this.navigateToGroup(gt);
        } else if (event.name === 'pass_vorhanden_manuell') {
            this.openPassVorhandenManuellDialog(event.value);
        } else if (event.name === 'ohrmarke_vorhanden_manuell') {
            this.openOhrmarkeVorhandenManuellDialog(event.value);
        } else if (event.name === 'filter') {
            this.openAbhollisteFilterDialog();
        } else if (event.name === 'tier_add_liste') {
            this.openAddTierToListeDialog();
        }
    }

    ngOnDestroy() {
        this.tableService = null;
        this.filterSubscription.unsubscribe();
    }

    openOhrmarkeVorhandenManuellDialog(gt: GruppeTier) {
        this.gruppeTierService.ohrmarkeAlsGescanntMarkieren(gt.gruppe_id, [gt]).subscribe({
            next: (result: boolean) => {
                if (result === true) {
                    this.tableService.reload();
                }
            }
        });
    }

    openPassVorhandenManuellDialog(gt: GruppeTier) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                okButton: 'Bestätigen',
                text: 'Wollen Sie wirklich den Pass für das Tier manuell als vorhanden eintragen?',
                headline: 'Pass als vorhanden nachtragen',
                buttonColor: 'accent',
            },
        });
        dialogRef.afterClosed().subscribe((resp: ConfirmDialogResult) => {
            if (resp?.confirmed) {
                gt.pass_gescannt_manuell = 1;
                this.updateTier(gt);
            }
        });
    }

    updateTier(tier: GruppeTier) {
        this.gruppeTierService.update(tier).subscribe({
            next: () => {
                this.tableService.reload();
                this.messageService.infoMessage(
                    'Tier erfolgreich aktualisiert.'
                );
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Fehler beim Aktualisieren des Tiers!',
                    err
                );
            },
        });
    }

    filterAnwenden() {
        if (this.filterObject) {
            let params = new HttpParams();
            if (this.filterObject.geschlechterIds?.length > 0) {
                for (const id of this.filterObject.geschlechterIds) {
                    params = params.append('geschlecht_id[]', id);
                }
            }
            if (this.filterObject.rassenIds?.length > 0) {
                for (const id of this.filterObject.rassenIds) {
                    params = params.append('rasse_id[]', id);
                }
            }
            if (this.filterObject.minAlter > 0) {
                params = params.set('alter_min', this.filterObject.minAlter);
            }
            if (this.filterObject.maxAlter > 0) {
                params = params.set('alter_max', this.filterObject.maxAlter);
            }
            if (this.filterObject.datum) {
                const dat = new Date(this.filterObject.datum);
                params = params.set('datum_simuliert', formatDate(dat, 'yyyy-MM-dd', 'de'));
                this.alterSimuliertCol.isActive = true;
                this.gewichtSimuliertCol.isActive = true;
                this.tageszunahmeCol.isActive = true;
                this.tableService.updateColumns();
            } else {
                this.alterSimuliertCol.isActive = false;
                this.gewichtSimuliertCol.isActive = false;
                this.tageszunahmeCol.isActive = false;
                this.tableService.updateColumns();
            }
            if (this.filterObject?.nichtInAbholliste) {
                params = params.set('nicht_in_abholliste', 1);
            }
            this.tableDescriptor.params = params;
            this.tableService.reload();
        }
    }

    getMaennlich() {
        if (this.filterObject) {

            for (const geschlechterId of this.filterObject?.geschlechterIds) {
                if (geschlechterId === 1) {
                    return 'Männlich';
                }
            }
        }
        return undefined;
    }

    getWeiblich() {
        if (this.filterObject) {

            for (const geschlechterId of this.filterObject?.geschlechterIds) {
                if (geschlechterId === 2) {
                    return 'Weiblich';
                }
            }
        }
        return undefined;
    }

    public getMindestalter(): string {
        return "Mindestalter: " + this.filterObject?.minAlter;
    }

    public getDate(): string {
        return "Ergebnisse simuliert für Datum: " + formatDate(new Date(this.filterObject?.datum), 'dd.MM.yyyy', 'de');
    }

    public getMaxAlter(): string {
        return "Maximales Alter: " + this.filterObject?.maxAlter + ' Tage';
    }

    public getMinAlter(): string {
        return "Minimales Alter: " + this.filterObject?.minAlter + ' Tage';
    }

    public getRassen(): string {
        return 'Anzahl ausgewählte Tierrassen: ' + this.filterObject?.rassenIds?.length;
    }

    public removeMindestalter(): void {
        this.filterObject.minAlter = undefined;
        this.speicherFilter();
    }

    public removeDate(): void {
        this.filterObject.datum = undefined;
        this.speicherFilter();
    }

    public removeMaennlich(): void {
        const index = this.filterObject.geschlechterIds.findIndex(g => g === 1);
        this.filterObject.geschlechterIds.splice(index, 1);
        this.speicherFilter();
    }

    public removeWeiblich(): void {
        const index = this.filterObject.geschlechterIds.findIndex(g => g === 2);
        this.filterObject.geschlechterIds.splice(index, 1);
        this.speicherFilter();
    }

    public removeMinAlter(): void {
        this.filterObject.minAlter = undefined;
        this.speicherFilter();
    }

    public removeMaxAlter(): void {
        this.filterObject.maxAlter = undefined;
        this.speicherFilter();
    }

    public removeRassen(): void {
        this.filterObject.rassenIds = [];
        this.speicherFilter();
    }

    public removeTiereInAbholliste(): void {
        this.filterObject.nichtInAbholliste = false;
        this.speicherFilter();
    }

    public isRassenVisible(): any {
        return this.filterObject?.rassenIds?.length > 0;
    }

    public getInAbholliste(): string {
        return "Tier in Abholliste nicht anzeigen";
    }

    public openAbhollisteFilterDialog(): void {
        this.dialog.open(AbhollisteFilterDialogComponent,
            {data: this.filterObject}
        ).afterClosed().subscribe({
            next: value => {
                if (value) {
                    this.filterObject = value;
                    this.filterAnwenden();
                }
            }
        });
    }

    private navigateToGroup(gt: GruppeTier) {
        this.router.navigate(['../aktuelle', gt.gruppe_id], {relativeTo: this.route}).then();
    }

    private speicherFilter(): void {
        this.api.setSpeicher('AbhollisteDialog', this.filterObject).subscribe({
            next: value => {
                if (value) {
                    this.filterAnwenden();
                } else {
                    this.messageService.alertMessage('Die Filtereinstellungen konnten nicht gespeichert werden!')
                }
            }
        });
    }

    private openAddTierToListeDialog(): void {
        const input = new AbholListeDialogInput();
        input.datumSimuliert = this.filterObject?.datum;

        if (!this.tableService?.selectedRows.length) {
            this.dialog.open<AbhollisteNumberInputDialogComponent, boolean, AbhollisteNumberInputDialogResult>(AbhollisteNumberInputDialogComponent, {
                data: this.filterObject?.nichtInAbholliste
            }).afterClosed().subscribe({
                next: (value: AbhollisteNumberInputDialogResult) => {
                    if (value) {
                        this.openDialogAfterLoadTiere(value, input);
                    }
                },
                error: err => this.messageService.errorMessage('Tiere konnten nicht abgerufen werden!', err)
            })
        } else {
            input.selectedRows = this.tableService.selectedRows;
            for (const sr of input.selectedRows) {
                if (sr.status_id_abholliste == 202) {
                    this.messageService.alertMessage('Mind. ein Tier befindet sich in einer abgeschlossenen Abholliste!');
                    return;
                }
            }
            this.openFinalDialog(input);
        }
    }

    private openFinalDialog(input: AbholListeDialogInput): void {
        this.dialog.open(AddTierToListeDialogComponent, {data: input}).afterClosed().subscribe({
            next: (liste: Abholliste) => {
                if (liste) {
                    this.messageService.infoMessage('In der Abholliste befinden sich jetzt ' + liste.anzahl_tiere + ' Tiere!');
                    this.tableService.clearSelection();
                    this.tableService.reload();
                }
            }
        });
    }

    private openDialogAfterLoadTiere(value: AbhollisteNumberInputDialogResult, input: AbholListeDialogInput): void {
        let params = this.tableService.getLastTableParams();
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('limit', value?.anzahl_tiere);
        if (value?.in_abholliste_einbeziehen === true) {
            params = params.set('nicht_in_abgeschlossener_abholliste', 1);
        } else {
            params = params.set('nicht_in_abholliste', 1);
        }

        this.tableServiceSubscription.unsubscribe();
        this.tableServiceSubscription = this.api.getAll(this.apiBaseUrl + 'gruppe_tier/search.php', params).subscribe({
            next: res => {
                input.selectedRows = res.records;
                if (value?.anzahl_tiere > res.count) {
                    this.messageService.infoMessage('Es konnten nur ' + res.count + ' Tiere gefunden werden.')
                }
                this.openFinalDialog(input);
            }
        });
    }
}
