<div *kienePermission="[1001]" class="kiene-content-page">
    <div class="kiene-table-header">
        <div>
            <button (click)="goBack()" mat-icon-button matTooltip="zurück" style="display: inline-block">
                <mat-icon color="primary">arrow_back</mat-icon>
            </button>
            <h2 style="display: inline-block; margin-left: 20px">
                Artikel {{ material.artikel_me }}
                <span *ngIf="urlId === 'neu'">neu anlegen</span>
            </h2>
        </div>
    </div>
    <div>
        <div class="one-row">
            <div style="width: 100%">
                <div style="padding-bottom: 30px">
                    <mat-tab-group (selectedTabChange)="setSelectedTab($event)" [selectedIndex]="selectedTab">
                        <!--

              Tab Allgemein

            -->
                        <mat-tab label="Allgemein">
                            <mat-card style="margin-bottom: 20px; margin-top: 20px">
                                <h5>Allgemein</h5>

                                <div class="one-row">
                                    <mat-form-field style="
                                            min-width: 400px;
                                            margin-right: 1%;
                                        ">
                                        <mat-label>Bezeichnung</mat-label>
                                        <span matPrefix>
                                            <mat-icon>description</mat-icon>&nbsp;&nbsp;
                                        </span>
                                        <input [formControl]="bezeichnungCtrl" matInput required type="text" />
                                    </mat-form-field>

                                    <kiene-autocomplete #wirkstoffAutocomplete (deleteSelected)="
                                            deleteWirkstoff($event)
                                        " (elementSelected)="setWirkstoff($event)" [apiUrl]="'wirkstoff/'"
                                        [displayField]="'wirkstoff'" [emitFreeText]="true" [focus]="false"
                                        [kieneObject]="material?.wirkstoff" [placeholder]="'Wirkstoff'"
                                        [showDeleteButton]="true"></kiene-autocomplete>

                                    <span class="spacer"></span>
                                </div>

                                <br />

                                <mat-form-field style="min-width: 100px; margin-right: 1%">
                                    <mat-label>Kürzel</mat-label>
                                    <span matPrefix>
                                        <mat-icon>short_text</mat-icon>&nbsp;&nbsp;
                                    </span>
                                    <input [formControl]="kuerzelCtrl" matInput placeholder="K&uuml;rzel" type="text" />
                                </mat-form-field>

                                <mat-form-field style="min-width: 100px; margin-right: 1%">
                                    <mat-label>EAN</mat-label>
                                    <span matPrefix>
                                        <mat-icon>star</mat-icon>&nbsp;&nbsp;
                                    </span>
                                    <input [formControl]="eanCtrl" matInput placeholder="EAN" type="text" />
                                </mat-form-field>

                                <mat-form-field style="min-width: 100px; margin-right: 1%">
                                    <mat-label>Alt. Produktkennzeichnungen</mat-label>
                                    <span matPrefix>
                                        <mat-icon>tag</mat-icon>&nbsp;&nbsp;
                                    </span>
                                    <input [formControl]="produktkennzeichnungCtrl" matInput
                                        placeholder="Alt. Produktkennzeichnung" type="text" />
                                </mat-form-field>

                                <mat-form-field style="min-width: 100px; margin-right: 1%">
                                    <mat-label>Interne Artikelnr.</mat-label>
                                    <span matPrefix>
                                        <mat-icon>star</mat-icon>&nbsp;&nbsp;
                                    </span>
                                    <input [formControl]="interneNrCtrl" matInput placeholder="Interne Artikelnummer"
                                        type="text" />
                                </mat-form-field>
                                <br />

                                <mat-form-field style="min-width: 100px; margin-right: 1%">
                                    <mat-label>Einheit</mat-label>
                                    <span matPrefix>
                                        <mat-icon>info</mat-icon>&nbsp;&nbsp;
                                    </span>
                                    <mat-select [formControl]="einheitCtrl">
                                        <mat-option *ngFor="let u of units" [value]="u.einheit_id">{{ u.bezeichnung }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field style="min-width: 100px; margin-right: 1%">
                                    <mat-label>Volumen</mat-label>
                                    <span matPrefix>
                                        <mat-icon>opacity</mat-icon>&nbsp;&nbsp;
                                    </span>
                                    <input [formControl]="mengeCtrl" matInput placeholder="Volumen" type="text" />
                                </mat-form-field>

                                <mat-form-field style="min-width: 100px; margin-right: 1%">
                                    <mat-label>Lagereinheit</mat-label>
                                    <span matPrefix>
                                        <mat-icon>info</mat-icon>&nbsp;&nbsp;
                                    </span>
                                    <input [formControl]="lagereinheitCtrl" matInput placeholder="Lagereinheit"
                                        type="text" />
                                </mat-form-field>

                                <br />

                                <mat-form-field style="min-width: 100px; margin-right: 1%">
                                    <mat-label>Umrechnungsfaktor</mat-label>
                                    <span matPrefix>
                                        <mat-icon>swap_horiz</mat-icon>&nbsp;&nbsp;
                                    </span>
                                    <input [formControl]="umrechnungFaktCtrl" matInput placeholder="Umrechnungsfaktor"
                                        type="number" />
                                </mat-form-field>

                                <mat-form-field style="
                                        min-width: 100px;
                                        width: 228px;
                                        margin-right: 1%;
                                    ">
                                    <mat-label>Gebindegr&ouml;&szlig;e
                                    </mat-label>
                                    <span matPrefix style="padding-right: 5px">
                                        <mat-icon>storage</mat-icon>&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <input [formControl]="gebindegroesseCtrl" matInput type="number" />
                                    <span matSuffix>Stk.</span>
                                </mat-form-field>

                                <mat-form-field style="min-width: 100px; margin-right: 1%">
                                    <mat-label>Ermittlung Rohertrag</mat-label>
                                    <mat-select [formControl]="ermittlungRohertragCtrl"
                                        placeholder="Ermittlung Rohertrag">
                                        <mat-option value="Durchschnittlicher EK">Durchschnittlicher EK
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <br />

                                <mat-form-field style="min-width: 350px; margin-right: 1%">
                                    <mat-label>Warengruppe</mat-label>
                                    <span matPrefix>
                                        <mat-icon>folder</mat-icon>&nbsp;&nbsp;
                                    </span>
                                    <mat-select [compareWith]="warengruppeComparator" [formControl]="warengruppeCtrl"
                                        placeholder="Warengruppe">
                                        <mat-option *ngFor="let w of warengruppen" [value]="w">{{ w.bezeichnung }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <br />

                                <mat-slide-toggle [formControl]="gesperrtCtrl" class="phito-toggle" color="primary">
                                    Artikel gesperrt
                                </mat-slide-toggle>
                                <br />

                                <div style="text-align: right">
                                    <button (click)="updateAllgemein()" *kienePermission="[1003]"
                                        [disabled]="bezeichnungCtrl.invalid" color="accent" mat-flat-button
                                        type="button">
                                        Allgemein Speichern
                                    </button>
                                </div>
                            </mat-card>

                            <mat-card *ngIf="!isCreationForm" style="margin-bottom: 20px">
                                <h5>Lagerbestand</h5>

                                <mat-form-field style="min-width: 100px; margin-right: 1%">
                                    <mat-label>Mindestbestand</mat-label>
                                    <span matPrefix>
                                        <mat-icon>store</mat-icon>&nbsp;&nbsp;
                                    </span>
                                    <input [formControl]="mindestbestandCtrl" matInput placeholder="Mindestbestand"
                                        type="number" />
                                    <span matSuffix> Stk. </span>
                                </mat-form-field>

                                <mat-form-field style="min-width: 100px; margin-right: 1%">
                                    <mat-label>Bestellmenge</mat-label>
                                    <span matPrefix>
                                        <mat-icon>functions</mat-icon>&nbsp;&nbsp;
                                    </span>
                                    <input [formControl]="bestellmengeCtrl" matInput placeholder="Bestellmenge"
                                        type="number" />
                                    <span matSuffix> Stk. </span>
                                </mat-form-field>

                                <mat-form-field style="min-width: 100px; margin-right: 1%">
                                    <mat-label>Sicherheitsbestand</mat-label>
                                    <input [formControl]="secureStockCtrl" matInput placeholder="Sicherheitsbestand"
                                        type="number" />
                                    <span matSuffix>Stk.</span>
                                </mat-form-field>

                                <mat-slide-toggle [formControl]="variableSecureStockCtrl" class="phito-toggle"
                                    color="primary" style="min-width: 350px; margin-right: 1%">Variabler
                                    Sicherheitsbestand
                                </mat-slide-toggle>

                                <mat-form-field style="min-width: 100px">
                                    <mat-label>Konst. Bestellmenge</mat-label>
                                    <span matPrefix>&Sigma;&nbsp;&nbsp;</span>
                                    <input [formControl]="bestellmengeKonstantCtrl" matInput
                                        placeholder="Konst. Bestellmenge" type="number" />
                                </mat-form-field>

                                <div style="text-align: right">
                                    <button (click)="updateLager()" *kienePermission="[1003]"
                                        [disabled]="bezeichnungCtrl.invalid" color="accent" mat-flat-button
                                        type="button">
                                        Lager Speichern
                                    </button>
                                </div>
                            </mat-card>
                        </mat-tab>
                        <!--

              Tab Preise

            -->
                        <mat-tab *ngIf="hasAtLeastOnePermissionForPrices()" aria-label="Preise" label="Preise">
                            <div>
                                <mat-card *kienePermission="[3211]" style="
                                        margin-bottom: 20px;
                                        margin-top: 20px;
                                    ">
                                    <h5>Finanzen</h5>
                                    <mat-form-field>
                                        <mat-select [formControl]="vatCtrl" placeholder="USt. in %">
                                            <mat-option *ngFor="let ust of ustValues" [value]="ust">{{ ust }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <h5>Buchungskonten FiBu</h5>
                                    <mat-form-field style="margin-right: 12px">
                                        <mat-label>Buchungskonto</mat-label>
                                        <input [formControl]="buchungskontoCtrl" matInput type="text" />
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-label>Buchungsgegenkonto
                                        </mat-label>
                                        <input [formControl]="
                                                buchungsgegenkontoCtrl
                                            " matInput type="text" />
                                    </mat-form-field>
                                    <mat-card-actions style="text-align: right">
                                        <button (click)="saveFinanzen()" [disabled]="
                                                material.artikel_id === null ||
                                                material.artikel_id ===
                                                    undefined
                                            " color="accent" mat-flat-button>
                                            Finanzen Speichern
                                        </button>
                                    </mat-card-actions>
                                </mat-card>

                                <kiene-supplier-material (currentStandardSupplierMaterial)="
                                        setCurrentStandardSupplierMaterial(
                                            $event
                                        )
                                    " *ngIf="
                                        material.stueckliste !== 1 &&
                                        hasPermission(1091)
                                    " [disabled]="material.stueckliste === 1" [material]="material">
                                </kiene-supplier-material>

                                <mat-card *ngIf="
                                        material.stueckliste === 1 &&
                                        hasPermission(1099)
                                    " style="margin-bottom: 20px">
                                    <h5>Einkaufspreis</h5>
                                    <p>
                                        Der Einkaufspreis wird in der
                                        St&uuml;ckliste definiert und
                                        entspricht:
                                        {{
                                        stuecklistenEinkaufspreis
                                        | number: "1.2-2"
                                        }}
                                        &euro;
                                    </p>
                                </mat-card>

                                <kiene-material-sales-prices [ek]="purchasePrice" [material]="material"
                                    [rabatt]="discount" [vat]="vatCtrl.value"></kiene-material-sales-prices>

                                <kiene-rabatt-staffel [material]="material"></kiene-rabatt-staffel>
                            </div>
                        </mat-tab>
                        <!--

              Tab Einstellungen

            -->
                        <mat-tab *ngIf="!isCreationForm" label="Einstellungen">
                            <mat-card style="margin-bottom: 20px; margin-top: 20px">
                                <div class="one-row">
                                    <div>
                                        <h5>Artikeleinstellungen</h5>
                                        <mat-slide-toggle [formControl]="
                                                behandlungsartikelCtrl
                                            " class="phito-toggle" color="primary">
                                            Behandlungsartikel
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="abgabeCtrl" class="phito-toggle"
                                            color="primary">
                                            Abgabeartikel
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="laborartikelCtrl" class="phito-toggle"
                                            color="primary">
                                            Laborartikel
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="mengenartikelCtrl" class="phito-toggle"
                                            color="primary">
                                            Mengenartikel
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="chargeJaNeinCtrl" class="phito-toggle"
                                            color="primary">
                                            Charge J/N
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="sonstigeAbgabeCtrl" class="phito-toggle"
                                            color="primary">
                                            sonstige Abgabe
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="
                                                betaeubungsmittelCtrl
                                            " class="phito-toggle" color="primary">
                                            Betäubungsmittel
                                        </mat-slide-toggle>
                                        <br />
                                        <!--
                  <mat-slide-toggle class="phito-toggle" color="primary" [formControl]="hitCtrl">Meldung an HIT
                  </mat-slide-toggle><br>-->
                                        <mat-slide-toggle [formControl]="antibiotikumCtrl" class="phito-toggle"
                                            color="primary">Antibiotikum
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="impstoffCtrl" class="phito-toggle"
                                            color="primary">Impfstoff
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="nichtAbgebbarCtrl" class="phito-toggle"
                                            color="primary">nicht abgebbar
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="bestellvorschlagCtrl" class="phito-toggle"
                                            color="primary">In Bestellvorschlägen
                                            anzeigen
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="stuecklisteCtrl" class="phito-toggle"
                                            color="primary">Ist
                                            St&uuml;ckliste
                                        </mat-slide-toggle>
                                        <br />
                                        <div class="one-row">
                                            <mat-slide-toggle [formControl]="
                                                    provisionsfaehigCtrl
                                                " class="phito-toggle" color="primary">
                                                Provisionsfähig
                                            </mat-slide-toggle>
                                            <mat-form-field style="margin-left: 12px">
                                                <mat-label>Provision in
                                                    &euro;
                                                </mat-label>
                                                <input [formControl]="
                                                        provisionCtrl
                                                    " matInput type="number" />
                                            </mat-form-field>
                                            <span class="spacer"></span>
                                        </div>
                                        <div class="one-row">
                                            <mat-slide-toggle [formControl]="tourenplanCtrl" class="phito-toggle"
                                                color="primary">
                                                Neuen Tourenplan Eintrag
                                                erzeugen
                                            </mat-slide-toggle>
                                            <mat-form-field style="margin-left: 12px">
                                                <mat-label>in Tagen ab
                                                    heute
                                                </mat-label>
                                                <input [formControl]="
                                                        tourenplanTageCtrl
                                                    " matInput type="number" />
                                            </mat-form-field>
                                            <span class="spacer"></span>
                                        </div>
                                        <br />
                                        <mat-slide-toggle [formControl]="rabattfaehigCtrl" class="phito-toggle"
                                            color="primary">
                                            Rabattfähig
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="bonusfaehigCtrl" class="phito-toggle"
                                            color="primary">Bonusfähig
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="skontofaehigCtrl" class="phito-toggle"
                                            color="primary">
                                            Skontofähig
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="bestellwesenCtrl" class="phito-toggle"
                                            color="primary">
                                            Bestellwesen
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="
                                                keinNegativBestandCtrl
                                            " class="phito-toggle" color="primary">Kein
                                            Negativbestand
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="
                                                lagerbestaendeVerbuchenCtrl
                                            " class="phito-toggle" color="primary">
                                            Lagerbestände
                                            verbuchen
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="
                                                keinLiefervorschlagCtrl
                                            " class="phito-toggle" color="primary">Kein
                                            Liefervorschlag
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="
                                                keineBarcodeErfassungCtrl
                                            " class="phito-toggle" color="primary">
                                            Keine
                                            Barcodeerfassung
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="sammelartikelCtrl" class="phito-toggle"
                                            color="primary">
                                            Sammelartikel
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="
                                                artikelmengeAusblendenCtrl
                                            " class="phito-toggle" color="primary">
                                            Artikelmenge in PDFs ausblenden
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="inAbgangslisteCtrl" class="phito-toggle"
                                            color="primary">
                                            in Ausstallliste
                                            auflisten
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="honorarCtrl" class="phito-toggle"
                                            color="primary">
                                            Honorar/Gehalt
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="primanotaIgnorierenCtrl" class="phito-toggle"
                                            color="primary">
                                            Artikel unter Kosten nicht in Primonata aufnehmen
                                        </mat-slide-toggle>
                                        <br />
                                        <div class="one-row">
                                            <mat-slide-toggle [formControl]="idexxCtrl" class="phito-toggle"
                                                color="primary">
                                                Idexx
                                            </mat-slide-toggle>
                                            <mat-form-field style="margin-left: 12px">
                                                <mat-label>Messgrösse (z.B. OD%, P/Pk
                                                    %)
                                                </mat-label>
                                                <input [formControl]="
                                                        idexxMessgroesseCtrl
                                                    " matInput />
                                            </mat-form-field>
                                            <mat-form-field style="
                                                    margin-left: 12px;
                                                    min-width: 20vw;
                                                ">
                                                <mat-label>Testbeschreibung
                                                </mat-label>
                                                <textarea [formControl]="
                                                        idexxTestbeschreibungCtrl
                                                    " matInput>
                                                </textarea>
                                            </mat-form-field>
                                            <mat-form-field style="
                                                    margin-left: 12px;
                                                    min-width: 20vw;
                                                ">
                                                <mat-label>Ergebnisbeschreibung
                                                </mat-label>
                                                <textarea [formControl]="
                                                        idexxErgebnisbeschreibungCtrl
                                                    " matInput>
                                                </textarea>
                                            </mat-form-field>
                                        </div>

                                        <br />
                                        <mat-slide-toggle [formControl]="
                                                anwendungsdauerFixCtrl
                                            " class="phito-toggle" color="primary">
                                            Anwendungsdauer Fix
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="dosierungFixCtrl" class="phito-toggle"
                                            color="primary">
                                            Dosierung Fix
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="massnahmenplanRindCtrl" class="phito-toggle"
                                            color="primary">
                                            Artikel für Abrechnung Maßnahmenpläne Rind
                                        </mat-slide-toggle>
                                        <br>
                                        <mat-slide-toggle [formControl]="massnahmenplanRindKurzCtrl"
                                            class="phito-toggle" color="primary">
                                            Artikel für Abrechnung Maßnahmenpläne Rind kurz
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="massnahmenplanSchweinCtrl" class="phito-toggle"
                                            color="primary">
                                            Artikel für Abrechnung Maßnahmenpläne Schwein
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="tierwohlprotokollRindCtrl" class="phito-toggle"
                                            color="primary">
                                            Artikel für Abrechnung Tierwohlprotokolle Rind
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="tierwohlprotokollSchweinCtrl"
                                            class="phito-toggle" color="primary">
                                            Artikel für Abrechnung Tierwohlprotokolle Schwein
                                        </mat-slide-toggle>
                                        <br />
                                        <mat-slide-toggle [formControl]="bestandsbesuchsprotokollCtrl"
                                            class="phito-toggle" color="primary">
                                            Artikel für Abrechnung Bestandsbesuchsprotokolle
                                        </mat-slide-toggle>
                                        <!--
                  <mat-slide-toggle class="phito-toggle" color="primary" [formControl]="qsCtrl">QS Teilnahme
                  </mat-slide-toggle><br>
                  -->
                                    </div>
                                    <div style="
                                            width: 650px;
                                            align-self: flex-start;
                                        ">
                                        <div style="
                                                border: dotted;
                                                padding: 12px;
                                                margin-top: 24px;
                                            ">
                                            <table>
                                                <thead>
                                                    <th style="
                                                            font-size: 0.8em;
                                                            color: gray;
                                                            text-align: left;
                                                            min-width: 250px;
                                                        ">
                                                        Eigenschaft
                                                    </th>
                                                    <th style="
                                                            font-size: 0.8em;
                                                            color: gray;
                                                            text-align: left;
                                                        ">
                                                        <div class="one-row">
                                                            <div class="one-row">
                                                                ja
                                                                <mat-icon>check_box
                                                                </mat-icon>
                                                                <span class="spacer"></span>
                                                            </div>
                                                            <div class="one-row">
                                                                nein
                                                                <mat-icon>check_box_outline_blank
                                                                </mat-icon>
                                                                <span class="spacer"></span>
                                                            </div>
                                                            <div class="one-row">
                                                                abh&auml;ngig
                                                                von
                                                                Behandlung/Abgabe
                                                                <mat-icon>warning
                                                                </mat-icon>
                                                                <span class="spacer"></span>
                                                            </div>
                                                        </div>
                                                    </th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Bestandsbuch</td>
                                                        <td>
                                                            <mat-icon>{{
                                                                istBestandsbuch()
                                                                }}</mat-icon>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Impfkontrollbuch
                                                        </td>
                                                        <td>
                                                            <mat-icon>{{
                                                                istImpfkontrollbuch()
                                                                }}</mat-icon>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Berechnung
                                                            Artikelmenge
                                                        </td>
                                                        <td>
                                                            <mat-icon>{{
                                                                berechneArtikelmenge()
                                                                }}</mat-icon>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Berechnung Dosierung
                                                        </td>
                                                        <td>
                                                            <mat-icon>{{
                                                                berechneDosierung()
                                                                }}</mat-icon>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <span class="spacer"></span>
                                </div>
                            </mat-card>

                            <mat-card style="margin-bottom: 20px">
                                <h5>Tierärzteberechnung</h5>
                                <mat-slide-toggle [formControl]="interneVerrechnungCtrl" class="phito-toggle"
                                    color="primary">Interne Verrechnung
                                </mat-slide-toggle>
                                <br />
                                <mat-form-field>
                                    <input [formControl]="vetSortCtrl" matInput placeholder="Sortierung" type="text" />
                                </mat-form-field>
                            </mat-card>

                            <div style="width: 100%; text-align: right">
                                <button (click)="updateEinstellungen()" *kienePermission="[1003]"
                                    [disabled]="bezeichnungCtrl.invalid" color="accent" mat-flat-button type="button">
                                    Einstellungen Speichern
                                </button>
                            </div>
                        </mat-tab>
                        <!--

              Tab VET

            -->
                        <mat-tab *ngIf="!isCreationForm" label="VET">
                            <mat-card style="
                                    width: 90%;
                                    margin-bottom: 20px;
                                    margin-top: 20px;
                                ">
                                <h5>Tierseuchenkasse</h5>
                                <mat-slide-toggle [formControl]="tskCtrl" class="phito-toggle" color="primary" style="
                                        min-width: 100px;
                                        width: 32%;
                                        margin-right: 1%;
                                    ">Tierseuchenkassse
                                </mat-slide-toggle>
                                <br />
                                <mat-form-field style="
                                        min-width: 350px;
                                        width: 33%;
                                        margin-right: 1%;
                                    ">
                                    <mat-label>TSK Abkürzung</mat-label>
                                    <input [formControl]="tskAbkuerzungCtrl" matInput placeholder="TSK Abkürzung"
                                        type="text" />
                                </mat-form-field>
                                <mat-form-field style="min-width: 100px; width: 33%">
                                    <mat-label>TSK Preis</mat-label>
                                    <input [formControl]="tskPreisCtrl" matInput placeholder="TSK Preis"
                                        type="number" />
                                </mat-form-field>
                            </mat-card>

                            <mat-card style="width: 90%; margin-bottom: 20px">
                                <h5>Details</h5>
                                <mat-form-field style="min-width: 350px; width: 90%">
                                    <mat-label>Impftext</mat-label>
                                    <input [formControl]="impftextCtrl" matInput placeholder="Impftext" type="text" />
                                </mat-form-field>
                                <!--<mat-form-field style="min-width: 100px; width: 32%; margin-right: 1%">
                  <input type="text" matInput [formControl]="wartezeitCtrl" placeholder="WZ &lt;&gt; 28" />
                </mat-form-field>-->
                                <mat-form-field style="
                                        min-width: 100px;
                                        width: 33%;
                                        margin-right: 1%;
                                    ">
                                    <mat-label>BGA / Zulassungsnummer</mat-label>
                                    <input [formControl]="bgaCtrl" matInput placeholder="BGA" type="text" />
                                </mat-form-field>
                                <mat-form-field style="
                                        min-width: 100px;
                                        width: 33%;
                                        margin-right: 1%;
                                    ">
                                    <mat-label>Eingangsnummer</mat-label>
                                    <input [formControl]="eingangsnummerCtrl" matInput placeholder="HIT Eingangsnummer"
                                        type="text" />
                                </mat-form-field>
                                <mat-form-field style="
                                        min-width: 100px;
                                        width: 33%;
                                        margin-right: 1%;
                                    ">
                                    <mat-label>Packungs-ID</mat-label>
                                    <input [formControl]="packungsIdCtrl" matInput placeholder="HIT Packungs ID"
                                        type="number" />
                                </mat-form-field>
                                <mat-form-field style="
                                        min-width: 100px;
                                        width: 33%;
                                        margin-right: 1%;
                                    ">
                                    <mat-label>UPD</mat-label>
                                    <input [formControl]="updCtrl" matInput placeholder="HIT UPD" type="number" />
                                </mat-form-field>
                                <br>
                                <mat-hint>N&auml;here Informationen sind unter <a
                                        href="https://www4.hi-tier.de/HitCom/tam_meldungarzn.asp"
                                        target="_blank">https://www4.hi-tier.de/HitCom/tam_meldungarzn.asp</a>
                                    zu finden.
                                </mat-hint>
                            </mat-card>

                            <!--
              <mat-card style="width:100%; margin-bottom:20px;">
                <h5>Impfung</h5>
                <mat-form-field style="min-width: 350px; width: 32%; margin-right: 1%;">
                  <input type="text" matInput [formControl]="impfgebuehr024" placeholder="Impfgebühr 0-24">
                </mat-form-field>
                <mat-form-field style="min-width: 100px; width: 33%; margin-right: 1%;">
                  <input type="text" matInput [formControl]="impfgebuehr2549" placeholder="Impfgebühr 25-49">
                </mat-form-field>
                <mat-form-field style="min-width: 100px; width: 33%;">
                  <input type="text" matInput [formControl]="impfgebuehr50" placeholder="Impfgebühr 50+">
                </mat-form-field>
              </mat-card>
              -->

                            <div style="width: 100%; text-align: right">
                                <button (click)="updateVet()" *kienePermission="[1003]"
                                    [disabled]="bezeichnungCtrl.invalid" color="accent" mat-flat-button type="button">
                                    VET Speichern
                                </button>
                            </div>
                        </mat-tab>
                        <!--

              Tab Mischung

            -->
                        <!-- <mat-tab label="Mischung" *ngIf="false">
              <form (ngSubmit)="save()" style="width:95%; margin-top: 20px; margin-bottom: 20px;">
                <mat-card style="width:100%; margin-bottom:20px; margin-top:20px;">
                  <h5>Komponenten</h5>
                  <mat-form-field style="width:10%; margin-right:1%;">
                    <input type="number" matInput placeholder="Menge" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:30%; margin-right:1%;">
                    <input type="text" matInput placeholder="Artikel" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:30%; margin-right:1%;">
                    <input type="text" matInput placeholder="Dosierung" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:20%; margin-right:1%;">
                    <input type="text" matInput placeholder="Charge" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:10%; margin-right:1%;">
                    <input type="number" matInput placeholder="Menge" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:30%; margin-right:1%;">
                    <input type="text" matInput placeholder="Artikel" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:30%; margin-right:1%;">
                    <input type="text" matInput placeholder="Dosierung" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:20%; margin-right:1%;">
                    <input type="text" matInput placeholder="Charge" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:10%; margin-right:1%;">
                    <input type="number" matInput placeholder="Menge" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:30%; margin-right:1%;">
                    <input type="text" matInput placeholder="Artikel" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:30%; margin-right:1%;">
                    <input type="text" matInput placeholder="Dosierung" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:20%; margin-right:1%;">
                    <input type="text" matInput placeholder="Charge" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:10%; margin-right:1%;">
                    <input type="number" matInput placeholder="Menge" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:30%; margin-right:1%;">
                    <input type="text" matInput placeholder="Artikel" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:30%; margin-right:1%;">
                    <input type="text" matInput placeholder="Dosierung" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                  <mat-form-field style="width:20%; margin-right:1%;">
                    <input type="text" matInput placeholder="Charge" [formControl]="mischung1AmountCtrl">
                  </mat-form-field>
                </mat-card>
              </form>
            </mat-tab> -->
                        <!--

              Tab Medikation

            -->
                        <mat-tab *ngIf="!isCreationForm" label="Medikation">
                            <kiene-artikel-indikation [mandanten]="availableMandanten" [material]="material">
                            </kiene-artikel-indikation>
                            <kiene-artikel-tierart [mandanten]="availableMandanten" [material]="material">
                            </kiene-artikel-tierart>
                            <kiene-artikel-anwendung [mandanten]="availableMandanten" [material]="material">
                            </kiene-artikel-anwendung>

                            <kiene-material-wartezeit [material]="material"></kiene-material-wartezeit>
                        </mat-tab>

                        <!--

              Tab Untersuchung

            -->
                        <mat-tab *ngIf="!isCreationForm" label="Untersuchung">
                            <div style="width: 95%">
                                <mat-card style="width: 100%; margin-top: 20px">
                                    <h4>Labor</h4>
                                    <kiene-autocomplete (elementSelected)="setLabor($event)" [apiUrl]="'lieferant/'"
                                        [displayField]="'bezeichnung'" [kieneObject]="labor"
                                        [placeholder]="'Labor'"></kiene-autocomplete>
                                </mat-card>
                                <mat-card style="width: 100%; margin-top: 20px">
                                    <h4>Untersuchungsgruppe</h4>
                                    <kiene-autocomplete (elementSelected)="
                                            setUntersuchungsgruppe($event)
                                        " [apiUrl]="'untersuchungsgruppe/'" [displayField]="'bezeichnung'"
                                        [kieneObject]="untersuchungsgruppe"
                                        [placeholder]="'Untersuchungsgruppe'"></kiene-autocomplete>
                                </mat-card>
                                <mat-card style="width: 100%; margin-top: 20px">
                                    <h4>Anzahl Untersuchungen</h4>
                                    <mat-form-field style="width: 100%">
                                        <input [formControl]="
                                                anzahlUntersuchungenCtrl
                                            " matInput placeholder="Anzahl Untersuchungen" type="number" />
                                    </mat-form-field>
                                </mat-card>
                                <div style="
                                        width: 100%;
                                        text-align: right;
                                        margin-top: 20px;
                                    ">
                                    <button (click)="updateUntersuchung()" *kienePermission="[1007]"
                                        [disabled]="bezeichnungCtrl.invalid" color="accent" mat-flat-button>
                                        Untersuchung Speichern
                                    </button>
                                </div>
                            </div>
                        </mat-tab>

                        <mat-tab *ngIf="
                                material.stueckliste === 1 &&
                                hasPermissionForStueckliste()
                            " label="Stückliste">
                            <ng-template matTabContent>
                                <kiene-stueckliste (reload)="loadArtikelAdmin()" [material]="material">
                                </kiene-stueckliste>
                            </ng-template>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>