import {Component, HostListener, Input, OnInit} from '@angular/core';

@Component({
  selector: 'kiene-back-to-top-button',
  templateUrl: './back-to-top-button.component.html',
  styleUrls: ['./back-to-top-button.component.scss']
})
export class BackToTopButtonComponent implements OnInit {

    /**
     * <strong>Usage:</strong> Position the button with the css properties: left,right,top,bottom. <br>
     * Pass these as properties of an object.
     */
    @Input('position') position: any;
    /**
     * speedMultiplier - default value: 1
     */
    @Input('speedMultiplier') speedMultiplier = 1;
    @Input('jumpToTopInstant') jumpToTopInstant = false;
    windowScrolled: boolean;
    styling: any;

    @HostListener('window:scroll', [])
    onScroll() {
        if (window.scrollY || document.documentElement.scrollTop || document.body.scrollTop > 100) {
            this.windowScrolled = true;
        } else if (this.windowScrolled && window.scrollY || document.documentElement.scrollTop || document.body.scrollTop < 10) {
            this.windowScrolled = false;
        }
    }

    /**
     * the function will call itself till the window is scrolled to the top
     */
    scrollBackToTop() {
        const speedMultiplier = this.speedMultiplier;
        (function smoothscroll() {
            const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
            if (currentScroll > 0) {
                window.requestAnimationFrame(smoothscroll);
                window.scrollTo(0, currentScroll - (currentScroll / (8 / speedMultiplier)));
            }
        })();
    }

    ngOnInit(): void {
        if (this.position) {
            this.styling = {};
            if (this.position.left) {
                this.styling.left = this.position.left;
            }
            if (this.position.right) {
                this.styling.right = this.position.right;
            }
            if (this.position.top) {
                this.styling.top = this.position.top;
            }
            if (this.position.bottom) {
                this.styling.bottom = this.position.bottom;
            }
        }
    }

    jumpToTop() {
        window.scrollTo(0, 0);
    }
}

