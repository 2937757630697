import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Gruppe} from '../../../gruppen/classes/gruppe';
import {GruppeTier} from '../../../gruppe-tier/classes/gruppe-tier';
import {FormControl, Validators} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {KieneBackendApiService} from '../../../../services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../kiene-core.config';
import {HttpParams} from '@angular/common/http';
import {GruppeStandort} from '../../../gruppen/gruppe-standort-verwalten/gruppe-standort';
import {GruppeStandortTier} from '../../../gruppe-tier/classes/gruppe-standort-tier';
import {MessageService} from '../../../../services/message-service/message.service';

@Component({
    selector: 'kiene-einzeltiergewicht-dialog',
    templateUrl: './einzeltiergewicht-dialog.component.html',
    styleUrls: ['./einzeltiergewicht-dialog.component.scss']
})
export class EinzeltiergewichtDialogComponent implements OnInit {

    step = 1;

    @ViewChild('gewichtInput') gewichtInput: MatInput;

    gruppe: Gruppe;
    gruppeStandort: GruppeStandort;

    gruppeTier: GruppeTier;
    gst: GruppeStandortTier;
    gewichtCtrl = new FormControl(undefined, [Validators.required]);
    radioCtrl = new FormControl(undefined, [Validators.required]);

    constructor(@Inject(MAT_DIALOG_DATA) private data: any,
                public dialogRef: MatDialogRef<EinzeltiergewichtDialogComponent>,
                private api: KieneBackendApiService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
        if (this.data) {
            this.gruppe = this.data.gruppe;
            this.gruppeStandort = this.data.gruppeStandort;
        }

        this.gewichtCtrl.disable();

        this.radioCtrl.valueChanges.subscribe({
            next: value => {
                if (value !== undefined) {
                    this.gewichtCtrl.enable();
                }
                if (this.gst) {
                    if (value === 0) {
                        this.gewichtCtrl.setValue(this.gst.einstallgewicht);
                    } else if (value === 1) {
                        this.gewichtCtrl.setValue((this.gst.abgangsgewicht));
                    }

                }
            }
        });
    }

    tierGefunden(tier: GruppeTier) {
        this.gruppeTier = tier;
        const params = new HttpParams().set('gt_id', this.gruppeTier.gt_id).set('gs_id', this.gruppeStandort.gs_id);
        this.api.getAll(this.apiBaseUrl + 'gruppe_standort_tier/read.php', params).subscribe({
            next: response => {
                if (response.records.length === 1) {
                    this.gst = response.records[0];
                    const value = this.radioCtrl.value;
                    if (value === 0) {
                        this.gewichtCtrl.setValue(this.gst.einstallgewicht);
                    } else if (value === 1) {
                        this.gewichtCtrl.setValue((this.gst.abgangsgewicht));
                    }
                }
                this.step = 2;
                setTimeout(() => {
                    this.gewichtInput.focus();
                }, 200);

            }
        });
    }

    gewichtSpeichern() {
        const gst = new GruppeStandortTier();
        if (this.radioCtrl.value === 0) {
            gst.einstallgewicht = this.gewichtCtrl.value;
        } else if (this.radioCtrl.value === 1) {
            gst.abgangsgewicht = this.gewichtCtrl.value;
        }
        gst.gt_id = this.gruppeTier.gt_id;
        gst.gs_id = this.gruppeStandort.gs_id;
        this.api.post(this.apiBaseUrl + 'gruppe_standort_tier/update.php', gst).subscribe({
            next: response => {
                this.messageService.infoMessage('Das Gewicht wurde erfolgreich gespeichert!');
                this.reset();
            },
            error: err => {
                this.messageService.errorMessage('Fehler', err);

            }
        });

    }

    private reset() {
        this.gruppeTier = undefined;
        this.gst = undefined;
        this.gewichtCtrl.setValue(undefined);
        this.step = 1;
    }
}
