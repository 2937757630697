import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tierliste } from '../../gruppe-tier/classes/tierliste';
import { GruppeTierService } from '../../gruppe-tier/gruppe-tier.service';
import { GruppenService } from '../../gruppen/gruppen.service';

@Component({
    selector: 'kiene-tierlistenelement',
    templateUrl: './tierlistenelement.component.html',
    styleUrls: ['./tierlistenelement.component.scss']
})
export class TierlistenelementComponent implements OnInit {

    @Input('tierliste') tierliste: Tierliste;
    @Input('historie') historie = false;
    @Input('transparentBackground') transparentBackground = false;
    @Input('padding') padding: string;
    @Input('loeschenAnzeigen') loeschenAnzeigen = false;
    @Input() downloadAnzeigen = false;
    @Input('showLock') showLock = false;

    @Output('zuGruppeHinzufuegen') zuGruppeHinzufuegenEmitter = new EventEmitter<Tierliste>();
    @Output('zuNeuerGruppeHinzufuegen') zuNeuerGruppeHinzufuegenEmitter = new EventEmitter<Tierliste>();
    @Output('listeLoeschen') listeLoeschenEmitter = new EventEmitter<Tierliste>();
    @Output('tierlisteClicked') tierlisteClickedEmitter = new EventEmitter<Tierliste>();
    @Output('nichtImportieren') nichtImportierenEmitter = new EventEmitter<Tierliste>();

    constructor(
        private gruppenService: GruppenService
    ) {
    }

    ngOnInit(): void {
    }

    zuGruppeHinzufuegen(event) {
        event.stopPropagation();
        this.zuGruppeHinzufuegenEmitter.emit(this.tierliste);
    }

    zuNeuerGruppeHinzufuegen(event) {
        event.stopPropagation();
        this.zuNeuerGruppeHinzufuegenEmitter.emit(this.tierliste);
    }

    selected() {
        this.tierlisteClickedEmitter.emit(this.tierliste);
    }

    downloadListe() {
        this.gruppenService.openGruppeAbgangDownloadDialog({ tierliste: this.tierliste });
    }

    listeLoeschen(event) {
        event.stopPropagation();
        this.listeLoeschenEmitter.emit(this.tierliste);
    }

    nichtImportieren(event) {
        event.stopPropagation();
        this.nichtImportierenEmitter.emit(this.tierliste);
    }
}
