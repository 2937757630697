<div class="kiene-content-page">
    <mat-card>
  
      <div *ngIf="isLoading" class="kiene-loading-spinner">
        <mat-spinner *ngIf="isLoading"></mat-spinner>
      </div>
  
      <div class="kiene-table-header">
        <h3>Benutzerrechte &Uuml;bersicht</h3>
      </div>
  
      <div>
        <mat-form-field style="width: 100%;">
          <input matInput placeholder="Suchen" #searchInput>
        </mat-form-field>
      </div>
  
      <mat-table #table [dataSource]="permissionDataSource" matSort matSortActive="bezeichnung" matSortDisableClear
        matSortDirection="asc">
  
        <ng-container matColumnDef="benutzerrecht_id">
          <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.benutzerrecht_id}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="bezeichnung">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.bezeichnung}}</mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="nur_admin">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Nur Admin</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.nur_admin}}</mat-cell>
        </ng-container>
        
  
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  
      </mat-table>
      <mat-paginator [length]="totalCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
    </mat-card>
  </div>