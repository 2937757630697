import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {AppService} from '../../app.service';

@Component({
    selector: 'kiene-file-upload-dialog',
    templateUrl: './file-upload-dialog.component.html',
    styleUrls: ['./file-upload-dialog.component.scss']
})
export class FileUploadDialogComponent implements OnInit {
    @ViewChild('fileSelector') fileSelector;

    filesToUpload: File[] = [];
    filesProgressValues: number[] = [];
    form: UntypedFormGroup;
    showCloseButton = false;
    nameOfIdField: string;
    id: number;
    ids: number[];
    apiUrl: string;
    mimeTypeAccepted = 'application/pdf';
    isUploading = false;

    constructor(public dialogRef: MatDialogRef<FileUploadDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private appService: AppService,
                private http: HttpClient) {
        this.nameOfIdField = data.nameOfIdField;
        this.apiUrl = data.apiUrl;
        this.id = data.id;
        this.ids = data.ids;
    }

    ngOnInit() {
        if (this.data?.allowImages) {
            this.mimeTypeAccepted += ',image/*';
        }
    }

    openFileSelector() {
        this.fileSelector.nativeElement.click();
    }

    handleFiles(event: any) {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
            this.filesToUpload.push(files.item(i));
            this.filesProgressValues[i] = 0;
        }
    }

    isFileUploaded(file: number) {
        if (this.filesProgressValues[file] === 1) {
            return true;
        } else {
            return false;
        }
    }

    uploadFilesToServer() {
        if (this.filesToUpload.length > 0) {
            this.appService.setDataLoading(true);
            this.isUploading = true;
            for (let i = 0; i < this.filesToUpload.length; i++) {

                const fileNumber = i;
                const file = this.filesToUpload[fileNumber];

                const fd = new FormData();
                if (this.ids && this.ids.length > 0) {
                    fd.append(this.nameOfIdField, JSON.stringify(this.ids));
                } else {
                    fd.append(this.nameOfIdField, this.id.toString());
                }
                fd.append('dokument', file);

                const params = new HttpParams();


                const options = {
                    headers: new HttpHeaders('Phito-Type: fileupload'),
                    params: params,
                    reportProgress: true,
                };

                // const request = new HttpRequest('POST', AppConstants.BASE_URI+this.apiUrl, fd, options);
                this.http.post<any>(this.apiBaseUrl + this.apiUrl, fd, options).subscribe(event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        const percentDone = Math.round(100 * event.loaded / event.total);
                        // this.filesProgressValues[i] = percentDone;
                    } else if (event instanceof HttpResponse) {
                    }
                }, error => {
                    console.error('Error while uploading file: ' + file.name);
                }, () => {
                    this.markFileAsUploaded(fileNumber);
                    this.appService.setDataLoading(false);
                });
            }
        }
    }

    markFileAsUploaded(no: number) {
        this.filesProgressValues[no] = 1;
        this.checkShowCloseButton();
    }

    checkShowCloseButton() {
        let show = true;
        for (let i = 0; i < this.filesProgressValues.length; i++) {
            if (this.filesProgressValues[i] === 0) {
                show = false;
                this.isUploading = true;
            } else {
                this.isUploading = false;
            }
        }
        if (show) {
            this.showCloseButton = true;
        }
    }

    uploadFiles() {
        this.uploadFilesToServer();
    }

    closeDialog() {
        this.dialogRef.close(true);
    }

    onCancelClick(): void {
        this.dialogRef.close(false);
    }

}
