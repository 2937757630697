import { Component, Inject, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import {
    GruppeTierbemerkung
} from '../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe-tierbemerkung';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import { MessageService } from '../../services/message.service';
import { Tierbemerkung } from '../../../../projects/kiene-core/src/lib/components/gruppe-tier/classes/tierbemerkung';
import { Client } from '../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-gruppe-bemerkungen-verwalten-dialog',
    templateUrl: './gruppe-bemerkungen-verwalten-dialog.component.html',
    styleUrls: ['./gruppe-bemerkungen-verwalten-dialog.component.scss']
})
export class GruppeBemerkungenVerwaltenDialogComponent implements OnInit {

    currentClient: Client;
    gruppenBemerkungen: GruppeTierbemerkung[];

    constructor(
        public dialogRef: MatDialogRef<GruppeBemerkungenVerwaltenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Client,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private backendApi: KieneTableService,
        private messageService: MessageService) {
        this.currentClient = data;
        this.loadGruppeTierBemerkungen();
    }

    ngOnInit(): void {
    }

    loadGruppeTierBemerkungen() {
        if (this.currentClient) {
            let params = new HttpParams().set('tierarzt', '0');
            if (this.currentClient.unternehmensgruppe_id) {
                params = params.set(
                    'unternehmensgruppe_id',
                    this.currentClient.unternehmensgruppe_id.toString()
                );
            } else {
                params = params.set(
                    'kunde_id',
                    this.currentClient.kunde_id?.toString()
                );
            }
            this.backendApi
                .get(
                    this.apiBaseUrl +
                    'gruppe_tier_bemerkung/read_grouped.php',
                    params
                )
                .subscribe({
                    next: (response: any) => {
                        this.gruppenBemerkungen = response;
                    },
                });
        }
    }

    updateGruppenBemerkungRow(event: MouseEvent, bemerkung: Tierbemerkung) {
        event.stopPropagation();
        event.preventDefault();
        this.updateGruppenBemerkung(null, bemerkung);
    }

    updateGruppenBemerkung(event: MatCheckboxChange, bemerkung: Tierbemerkung) {
        if (event) {
            bemerkung.tierarzt = event.checked ? 1 : 0;
        } else {
            bemerkung.tierarzt = bemerkung.tierarzt === 0 ? 1 : 0;
        }
        this.backendApi
            .updateGeneric(
                this.apiBaseUrl +
                'gruppe_tier_bemerkung/update_kundenportal.php',
                bemerkung
            )
            .subscribe({
                next: (resp) => {
                    this.messageService.infoMessage('Bemerkung erfolgreich aktualisiert!');
                    bemerkung = resp;
                },
                error: (err) => this.messageService.errorMessage('Fehler beim Aktualisieren der Bemerkung!', err)
            });
    }
}
