import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'kiene-confirm-delete-dialog',
    templateUrl: './confirm-delete-dialog.component.html',
    styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class ConfirmDeleteDialogComponent implements OnInit {

    title = 'Wirklich Löschen?';
    text = 'Wollen Sie den Datensatz wirklich löschen?';
    textButton = 'Löschen';

    constructor(public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        if (this.data) {
            this.title = data.title;
            this.text = data.text;

            if (data.textButton) {
                this.textButton = data.textButton;
            }
        }
    }

    ngOnInit() {
    }

    confirmDelete() {
        this.dialogRef.close(true);
    }

    onCancelClick(): void {
        this.dialogRef.close(false);
    }

}
