import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {HitLoginDetails} from '../hit-registrierung/classes/hit-login-details';
import {MatInput} from '@angular/material/input';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {HitLoginResponse} from '../hit-registrierung/classes/hit-login-response';
import {MessageService} from '../../services/message-service/message.service';
import {HitService} from '../hit.service';
import {KienePatternUtils} from '../../utils/KienePatternUtils';

@Component({
    selector: 'hit-anmeldung',
    templateUrl: './hit-anmeldung.component.html',
    styleUrls: ['./hit-anmeldung.component.scss'],
})
export class HitAnmeldungComponent implements OnInit, AfterViewInit {
    hidePassword = true;
    ersteinrichtung = false;
    isLoading = false;

    @ViewChild('VvvoInput')
    vvvoInput: MatInput;

    @Input('navigateToAfterLogin')
    navigateToAfterLogin: string;

    @Output() loginSuccess = new EventEmitter<HitLoginResponse>();
    hitPinCtrl: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);
    hitVvvoCtrl: UntypedFormControl = new UntypedFormControl('276', [
        Validators.required,
        Validators.pattern(KienePatternUtils.VVVO()),
    ]);
    hitFormGroup: UntypedFormGroup = new UntypedFormGroup({
        hitPasswordCtrl: this.hitPinCtrl,
        hitVvvoCtrl: this.hitVvvoCtrl,
    });

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private hitService: HitService
    ) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            if (params.ersteinrichtung) {
                this.ersteinrichtung = true;
            }
        });

        this.hitService.watchHitLoginStatusErfolgreich().subscribe({
            next: (value: boolean) => {
                if (value) {

                    if (this.navigateToAfterLogin) {

                        const qp: any = {};
                        if (this.ersteinrichtung) {
                            qp['ersteinrichtung'] = 1;
                        }
                        this.router
                            .navigate([this.navigateToAfterLogin], {
                                relativeTo: this.route,
                                queryParams: qp,
                            })
                            .then();
                    }
                } else {
                    this.resetFormCtrls();
                }
            },
        });
    }

    ngAfterViewInit() {
        this.vvvoInput?.focus();
    }

    anmelden() {
        this.isLoading = true;

        const hitLoginDetails: HitLoginDetails = new HitLoginDetails();
        hitLoginDetails.vvvo = this.hitVvvoCtrl.value;
        hitLoginDetails.pin = this.hitPinCtrl.value;

        this.hitService.hitLogin(hitLoginDetails).subscribe({
            next: (hitLoginResponse: HitLoginResponse) => {
                this.loginSuccess.next(hitLoginResponse);
                hitLoginResponse.vvvo = hitLoginDetails.vvvo;
                hitLoginResponse.pin = hitLoginDetails.pin;
                this.hitService.setHitLoginStatusErfolgreich(hitLoginResponse);
                this.isLoading = false;
            },
            error: (err) => {
                this.hitService.setHitLoginStatusFehler();
                this.messageService.errorMessage(
                    'Fehler HIT Anmeldung! Bitte Daten überprüfen und erneut anmelden',
                    err
                );
                this.isLoading = false;
            },
        });
    }

    private resetFormCtrls() {
        this.hitVvvoCtrl.setValue('276');
        this.hitPinCtrl.setValue(null);

        this.hitVvvoCtrl.setErrors(null);
    }
}
