import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Material } from '../../warehouse/material/material';
import { Observable, of } from 'rxjs';
import { MaterialService } from '../../warehouse/material/material.service';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger, } from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { ArticleScanAutocompleteService } from './article-scan-autocomplete.service';
import { MessageService } from '../../services/message.service';
import { HttpParams } from '@angular/common/http';

export class ArticleScanSelectedEvent {
    article: Material;
    mhd: Date;
    charge: string;
}

@Component({
    selector: 'kiene-article-scan-autocomplete',
    templateUrl: './article-scan-autocomplete.component.html',
    styleUrls: ['./article-scan-autocomplete.component.scss'],
    host: { '(window:keydown)': 'checkForKeyShortcut($event)' },
})
export class ArticleScanAutocompleteComponent
    implements OnInit, OnChanges, AfterViewInit {
    materialFormCtrl: UntypedFormControl = new UntypedFormControl('');
    materialien: Observable<Material[]>;
    mhd: Date = null;
    charge: string = null;

    activatedMaterial: Material;

    hintMessage: string;

    @ViewChild('articleInput') articleInput: MatInput;
    @ViewChild('autoMaterial') autoMaterial: MatAutocomplete;
    @ViewChild(MatAutocompleteTrigger)
    autocompleteTrigger: MatAutocompleteTrigger;

    @Input('required') required = false;
    @Input('placeholder') placeholder = 'Artikel';
    @Input('focus') focus = true;
    @Input('showClearButton') showClearButton = true;
    @Input('presetMaterial') presetMaterial: Material;
    @Input('gesperrtAusblenden') gesperrtAusblenden = false;
    @Input('showPrice') showPrice = false;
    @Input('kundeId') kundeId: number;
    @Input('kundeMandantId') kundeMandantId: number;
    @Input('labor') labor = false;
    @Input('disabled') disabled = false;
    @Input('clearAfterSelect') clearAfterSelect = false;
    @Input('nurAbgegebeneArtikel') nurAbgegebeneArtikel = false;
    @Input('params') params: HttpParams;
    @Input() stuecklisten = true;

    @Output() selectedMaterial = new EventEmitter<ArticleScanSelectedEvent>();

    @Output() scannedEAN = new EventEmitter<string>();

    @Output('cleared') cleared = new EventEmitter<boolean>();

    constructor(
        private materialService: MaterialService,
        private articleService: ArticleScanAutocompleteService,
        private notification: MessageService
    ) { }

    ngOnInit() {
        if (this.required) {
            this.materialFormCtrl.setValidators([Validators.required]);
        }

        this.autocompleteMaterial();
        if (this.presetMaterial && this.presetMaterial.artikel_id) {
            this.materialFormCtrl.setValue(this.presetMaterial);
        }

        this.articleService.watchPresetMaterial().subscribe((material) => {
            this.presetMaterial = material;
            this.materialFormCtrl.setValue(this.presetMaterial);
        });
    }

    ngOnChanges() {
        if (this.disabled) {
            this.materialFormCtrl.disable();
        } else {
            this.materialFormCtrl.enable();
        }
        this.autocompleteMaterial();
    }

    public isValid(): boolean {
        if (!this.required) {
            return true;
        }
        if (this.required) {
            return this.materialFormCtrl.valid;
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.focus) {
                this.setFocus();
            }
            if (this.presetMaterial && this.presetMaterial.artikel_id) {
                this.materialFormCtrl.setValue(this.presetMaterial);
            }
        }, 200);
    }

    public setMaterial(material: Material, emit: boolean) {
        this.materialFormCtrl.setValue(material, { emitEvent: emit });
    }

    public clear() {
        this.materialFormCtrl.setValue('', { emitEvent: false });
        this.activatedMaterial = null;
        this.materialFormCtrl.markAsUntouched();
        this.cleared.emit(true);
    }

    public setFocus() {
        this.articleInput?.focus();
        this.autocompleteTrigger.closePanel();
    }

    public clearAndFocus() {
        this.clear();
        this.setFocus();
    }

    optionActivated(event: MatAutocompleteSelectedEvent) {
        if (event.option) {
            this.activatedMaterial = event.option.value;
        }
    }

    unFocus() {
        this.articleInput.focused = false;
    }

    onBlur(event) {
        event.stopPropagation();
        if (this.activatedMaterial) {
            this.materialFormCtrl.setValue(this.activatedMaterial);
            const material = this.activatedMaterial;
            const asse: ArticleScanSelectedEvent =
                new ArticleScanSelectedEvent();
            asse.article = material;
            asse.mhd = this.mhd;
            asse.charge = this.charge;
            this.emitSelectedMaterial(asse);
            this.activatedMaterial = null;
        }
    }

    emitSelectedMaterial(asse: ArticleScanSelectedEvent) {
        this.selectedMaterial.emit(asse);
    }

    emitEAN(EAN: string) {
        this.scannedEAN.emit(EAN);
    }

    getValue() {
        return this.materialFormCtrl?.value;
    }

    autocompleteMaterial() {
        this.materialien = this.materialFormCtrl.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((value) => this.searchMaterial(value))
        );
    }

    searchMaterial(value) {
        if (this.disabled) {
            return of([]);
        }
        // if (value === null || value === undefined || value === '') {
        //   return [];
        // }
        if (typeof value === 'string') {
            value = value.trim();
        }
        this.checkForDatamatrixPattern(value);

        return this.materialService.searchAutocompleteMaterial(value, this.gesperrtAusblenden, this.kundeId, this.labor, this.params, this.kundeMandantId, this.showPrice, this.nurAbgegebeneArtikel, this.stuecklisten).pipe(
            map((response) => response.records)
        );
    }

    materialMapper(artikel: Material) {
        if (artikel) {
            return artikel.artikel_me;
        }
    }

    autocompleteMaterialSelected(event: MatAutocompleteSelectedEvent) {
        const d = new Date();
        const material = event.option.value;
        const asse: ArticleScanSelectedEvent = new ArticleScanSelectedEvent();
        asse.article = material;
        asse.mhd = this.mhd;
        asse.charge = this.charge;
        this.activatedMaterial = null;
        if (this.clearAfterSelect) {
            this.clearAndFocus();
        }
        this.emitSelectedMaterial(asse);
    }

    checkForDatamatrixPattern(value: string) {
        if (!value) {
            return;
        }
        /* check for pattern: 010 8713184031693 17 190800 10 A234A01
         * 010: prefix
         * 8713184031693: ean code of material
         * 17: delimiter
         * 190800: best before: 2019 August; if last two digits 00 then take 30th or 31st day of month
         * 10: delimter
         * A234A01: Charge
         */

        // value = value.trim();

        // const pattern = new RegExp('^(010)([0-9]{13})(10|17)([A-Za-z0-9\\-]{1,})(10|17)([A-Za-z0-9\\-]{1,})$');
        const pattern = new RegExp(
            '^(010)([0-9]{13})(((17)([0-9]{6})(10)([a-zA-Z0-9-]{1,20}))|((10)([a-zA-Z0-9-]{1,20})(17)([0-9]{6})))$'
        );
        const match = pattern.exec(value);
        if (match != null) {
            if (match[5] && match[5] === '17') {
                this.mhd = this.createBestBeforeDate(match[6]);
                this.charge = match[8];
            }

            if (match[10] && match[10] === '10') {
                this.mhd = this.createBestBeforeDate(match[13]);
                this.charge = match[11];
            }

            // if(match[3] === '17') {
            //   this.mhd = this.createBestBeforeDate(match[4]);
            // } else if(match[3] === '10'){
            //   this.charge = match[4];
            // }

            // if(match[5] === '17'){
            //   this.mhd = this.createBestBeforeDate(match[6]);
            // } else if(match[5] === '10'){
            //   this.charge = match[6];
            // }

            this.materialService
                .getMaterialByEAN(match[2])
                .subscribe((response) => {
                    const asse: ArticleScanSelectedEvent = new ArticleScanSelectedEvent();
                    if (response.count === 0) {
                        this.notification.alertMessage('Es wurde kein Material mit dem EAN Code ' + match[2] + ' gefunden!');
                        this.emitEAN(match[2]);
                        asse.mhd = this.mhd;
                        asse.charge = this.charge;
                        this.emitSelectedMaterial(asse);

                        return;
                    }
                    if (response.count > 1) {
                        this.notification.alertMessage('Es wurde mehr als ein Artikel mit dem EAN Code ' + match[2] + ' gefunden!'
                        ); return;
                    }
                    const art = response.records[0];
                    this.materialFormCtrl.setValue(art);

                    asse.article = art;
                    asse.mhd = this.mhd;
                    asse.charge = this.charge;
                    this.emitSelectedMaterial(asse);
                });
        } else {
            this.emitEAN(value);
        }
    }

    createBestBeforeDate(value: string): Date {
        if (value.length === 6) {
            const yearPrefix = 20;
            const yearString = value[0] + '' + value[1];
            const year = yearPrefix + yearString;
            const month = value[2] + '' + value[3];
            const day = value[4] + '' + value[5];
            let monthInt = parseInt(month, 10) - 1;
            if (day === '00') {
                monthInt = monthInt + 1;
            }
            const date = new Date(
                parseInt(year, 10),
                monthInt,
                parseInt(day, 10)
            );
            return date;
        } else {
            return null;
        }
    }

    checkForKeyShortcut(event: KeyboardEvent) {
        if (event.code === 'KeyA' && !this.isKeyEventFromInput(event)) {
            event.preventDefault();
            this.setFocus();
        }
    }

    public setHintMessage(hintMessage: string) {
        this.hintMessage = hintMessage;
    }

    private isKeyEventFromInput(event: KeyboardEvent): boolean {
        if (event.target instanceof Node) {
            switch (event.target.nodeName) {
                case 'INPUT':
                case 'SELECT':
                case 'TEXTAREA':
                    return true;
                default:
                    return false;
            }
        }
        return false;
    }
}
