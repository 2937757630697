import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Antibiogramm, AntibiogrammAllDownload } from './antibiogramm';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { MessageService } from '../../services/message.service';
import { HttpParams } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { Gruppe } from '../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import { GruppenService } from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { TableDescriptor, ToolbarButton } from '../../components/kiene-table/table-descriptor';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    FilterItem,
    FilterItemType
} from '../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import {
    ConditionSetting,
    ConditionTableColumn,
    DateTableColumn,
    EmailGesendetTableColumn,
    GedrucktTableColumn,
    TableColumn,
    TextTableColumn
} from '../../components/kiene-table/table-column';
import { Condition, Operator, TableOption, TableOptionEvent } from '../../components/kiene-table/table-option';
import { AntibiogrammService } from './antibiogramm.service';
import { Client } from '../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-antibiogramm',
    templateUrl: './antibiogramm.component.html',
    styleUrls: ['./antibiogramm.component.scss'],
})
export class AntibiogrammComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private api: KieneTableService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private service: AntibiogrammService,
        private pdfViewerService: PdfViewerDesktopService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Antibiogramme';
        this.tableDescriptor.uniqueIdentifier = 'Antibiogramme';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'antibiogramm/';
        this.tableDescriptor.createAllowed = false;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.nameOfIdField = 'antibiogramm_id';

        if (this.router.url.startsWith('/belege')) {
            this.tableDescriptor.sendCurrentClient = true;
            this.tableDescriptor.detailsRoute = '/belege/labor/antibiogramme';
        } else {
            this.tableDescriptor.detailsRoute = '/labor/antibiogramme';
        }

        const befund_id = this.route.snapshot.queryParamMap.get('befund_id');
        if (befund_id) {
            this.tableDescriptor.params = new HttpParams().set('befund_id', befund_id);
        }

        this.tableDescriptor.columns = this.getTableColumns();
        this.tableDescriptor.options = this.getTableOptions();
        this.tableDescriptor.filterItems = this.getFilterItems();

        this.tableDescriptor.addToolbarButton(new ToolbarButton('download_all', 'save_alt', 1818));

        this.tableDescriptor.permissionView = 1811;
        this.tableDescriptor.permissionCreate = 1812;
        this.tableDescriptor.permissionUpdate = 1813;
        this.tableDescriptor.permissionDelete = 1814;

        this.tableDescriptor.initialSortColumn = 'editiert';
        this.tableDescriptor.initialSortDirection = 'desc';

    }

    getFilterItems() {
        const items = [];
        items.push(new FilterItem('Befund nicht abgerechnet', '0', FilterItemType.CHECKBOX, 'rechnung', false));
        if (this.localStorageService.getCurrentUser()?.tierarzt_id) {
            items.push(new FilterItem('Nur meine Tierarztgruppe', '1', FilterItemType.CHECKBOX, 'meinetierarztgruppen', true));
        }
        return items;
    }


    getTableColumns() {
        const columns = [];
        columns.push(new TextTableColumn('tagebuch_nr', 'Tagebuchnummer'));
        columns.push(new TextTableColumn('kunde', 'Kunde'));
        columns.push(new TextTableColumn('tierarztgruppe', 'Tierarztgruppe'));
        columns.push(new TextTableColumn('gruppe', 'Gruppe'));
        columns.push(new DateTableColumn('eingang', 'Untersuchungsbeginn', 'dd.MM.yyyy'));
        columns.push(new DateTableColumn('erstellt', 'Erstellt', 'dd.MM.yyyy HH:mm:ss'));
        columns.push(new DateTableColumn('editiert', 'Aktualisiert', 'dd.MM.yyyy HH:mm:ss'));
        const statusConditions = [];
        statusConditions.push(
            new ConditionSetting(
                [
                    new Condition('status', Operator.GLEICH, 0)
                ], { icon: 'fiber_new', tooltip: 'erstellt' }));
        statusConditions.push(
            new ConditionSetting(
                [
                    new Condition('status', Operator.GLEICH, 1)
                ], { icon: 'fax', tooltip: 'übertragen' }));
        statusConditions.push(
            new ConditionSetting(
                [
                    new Condition('status', Operator.GLEICH, 3)
                ], { icon: 'done', tooltip: 'Ergebnis vorhanden' }));
        statusConditions.push(
            new ConditionSetting(
                [
                    new Condition('status', Operator.GLEICH, -1)
                ], { icon: 'note_add', tooltip: 'generiert' }));

        columns.push(new ConditionTableColumn('status', 'Status', statusConditions));
        columns.push(new TableColumn('pdf_id', '', 'pdf'));

        columns.push(new EmailGesendetTableColumn());
        columns.push(new GedrucktTableColumn());

        return columns;
    }

    getTableOptions() {
        const options = [];
        options.push(new TableOption('delete', 'Antibiogramm löschen', 'delete', 1814));
        options.push(new TableOption('zu_befundmitteilung_gehen', 'zu Befundmitteilung gehen', 'play_arrow', 1311, { conditions: [new Condition('befund_id', Operator.GROESSER, 0)] }));
        options.push(new TableOption('antibiogramm_pdf_neu_erstellen', 'Antibiogramm PDF neu erstellen', 'edit', 1816));
        options.push(new TableOption(null, 'Drucken', 'print', 1850, { void: this.gedruckt.bind(this), conditions: [new Condition('pdf_id', Operator.EXISTIERT, null)] }));

        return options;
    }

    gedruckt(anti: Antibiogramm) {
        this.api.get(this.apiBaseUrl + 'antibiogramm/gedruckt.php', new HttpParams().set('antibiogramm_id', anti.antibiogramm_id)).subscribe({
            next: () => {
                this.api.reload('gedruckt');
                this.openPdf(anti);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'showPdf') {
            this.openPdf(event.value);
        } else if (event.name === 'antibiogramm_pdf_neu_erstellen') {
            this.recreateAntibiogramm(event.value);
        } else if (event.name === 'zu_befundmitteilung_gehen') {
            this.goToBefund(event.value);
        } else if (event.name === 'delete') {
            this.deleteAntibiogramm(event.value);
        } else if (event.name === 'add') {
            this.openNewAntibiogramm();
        }
    }

    // loadAntibiogramme() {
    //     this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    //
    //     merge(this.sort.sortChange, this.paginator.page)
    //         .pipe(
    //             startWith({}),
    //             switchMap(() => {
    //                 this.isLoading = true;
    //                 this.offset =
    //                     this.paginator.pageIndex != null &&
    //                     this.paginator.pageSize != null
    //                         ? this.paginator.pageIndex * this.paginator.pageSize
    //                         : 0;
    //                 this.limit =
    //                     this.paginator.pageSize != null
    //                         ? this.paginator.pageSize
    //                         : this.limit;
    //                 this.sortColumn =
    //                     this.sort.active != null
    //                         ? this.sort.active
    //                         : this.sortColumn;
    //                 return this.service!.getAllAntibiogramme(
    //                     this.sortColumn,
    //                     this.sort.direction,
    //                     this.offset,
    //                     this.limit,
    //                     this.searchInput.nativeElement.value,
    //                     this.queryOnlyTierarztgruppe,
    //                     this.params
    //                 );
    //             }),
    //             map((data) => {
    //                 this.isLoading = false;
    //                 this.totalCount = data.count;
    //                 return data.records;
    //             }),
    //             catchError(() => {
    //                 this.isLoading = false;
    //                 return observableOf([]);
    //             })
    //         )
    //         .subscribe((data) => {
    //             this.antibiogrammDataSource.data = data;
    //             this.tabinfoService.setReload();
    //         });
    // }


    openNewAntibiogramm() {
        if (this.router.url.startsWith('/belege')) {
            this.router.navigate(['/belege/labor/antibiogramme/neu']).then();
        } else {
            this.router.navigate(['/labor/antibiogramme/neu']).then();
        }
    }

    clickedRow(antibiogramm_id: number) {
        this.router
            .navigate([antibiogramm_id], { relativeTo: this.route })
            .then();
    }

    goToBefund(a: Antibiogramm) {
        this.router
            .navigate(['labor', 'befundmitteilungen', a.befund_id])
            .then();
    }

    recreateAntibiogramm(antibiogramm: Antibiogramm) {
        this.service.recreateAntibiogramm(antibiogramm).subscribe({
            next: () => {
                this.messageService.infoMessage(
                    'Antibiogramm PDF ' +
                    antibiogramm.tagebuch_nr +
                    ' erfolgreich neu erstellt!'
                );
                this.api.reload('recreateAntibiogramm');
            },
            error: (error) => {
                console.error(error);
                this.messageService.errorMessage(
                    'Fehler ' + antibiogramm.tagebuch_nr + '!',
                    error
                );
            },
        });
    }

    openPdf(antibiogramm: Antibiogramm) {
        const pdfId = antibiogramm.pdf_id;
        if (pdfId) {
            this.service.showPdf(antibiogramm);
        }
    }

    openDownloadAllDialog() {
        const dialogRef = this.dialog.open(DownloadAllAntibiogrammeDialog, {
            width: '700px',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const antibiogrammAllDownload: AntibiogrammAllDownload = result;
                const client: Client = antibiogrammAllDownload.client;

                if (antibiogrammAllDownload.client != null) {
                    const title: string =
                        'Antibiogramm_alle_' +
                        antibiogrammAllDownload.client.bezeichnung_final;
                    const zeit_von = antibiogrammAllDownload.zeit_von;
                    const zeit_bis = antibiogrammAllDownload.zeit_bis;

                    let params = new HttpParams();
                    params = params.append(
                        'kunde_id',
                        client?.kunde_id?.toString()
                    );
                    params = params.append('zeit_von', zeit_von?.toISOString());
                    params = params.append('zeit_bis', zeit_bis?.toISOString());
                    const input = new PdfViewerDialogInput(
                        'antibiogramm/read_or_mail_pdf_period.php',
                        params,
                        title,
                        {
                            email: {
                                sende_mail: true,
                                kunde_id: client?.kunde_id,
                            }
                        }
                    );
                    this.pdfViewerService.openPdfViewer(input);
                }
            }
        });
    }

    openConfirmDeleteAntibiogrammDialog(antibiogramm: Antibiogramm) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '350px',
            data: {
                title: 'Antibiogramm löschen',
                text:
                    'Wollen Sie das Antibiogramm ' +
                    antibiogramm.tagebuch_nr +
                    ' wirklich löschen?',
                textButton: 'Löschen',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.deleteAntibiogramm(antibiogramm);
            }
        });
    }

    deleteAntibiogramm(antibiogramm: Antibiogramm) {
        this.service.deleteAntibiogramm(antibiogramm).subscribe({
            next: () => {
                this.messageService.infoMessage(
                    'Befundmitteilung: ' +
                    antibiogramm.tagebuch_nr +
                    ' erfolgreich entfernt!'
                );
                this.api.reload('deleteAntibiogramm');
            },
            error: (error) => {
                console.error(error);
                this.messageService.errorMessage(
                    'Löschen von Antibiogramm: ' +
                    antibiogramm.tagebuch_nr +
                    ' ist fehlgeschlagen!',
                    error
                );
            },
        });
    }
}

@Component({
    selector: 'download-all-antibiogramme-dialog',
    template: `<h3 mat-dialog-title>
        Alle Antibiogramme zu einem Betrieb herunterladen
    </h3>
    <mat-dialog-content>
        <h5>Betrieb ausw&auml;hlen</h5>
        <kiene-client-autocomplete
            [panelWidth]="'50%'"
            (selectedClient)="setSelectedClient($event)"
        ></kiene-client-autocomplete>
        <mat-divider></mat-divider>
        <h5>Zeitraum angeben (Probenentnahme)</h5>

        <mat-form-field style="min-width: 40%; padding-left: 10px;">
            <input
                matInput
                [matDatepicker]="fromPicker"
                placeholder="Von"
                [(ngModel)]="antibiogrammAllDownload.zeit_von"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="fromPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="min-width: 40%; padding-left: 10px;">
            <input
                matInput
                [matDatepicker]="toPicker"
                placeholder="Bis"
                [(ngModel)]="antibiogrammAllDownload.zeit_bis"
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="toPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button color="primary" (click)="onCancelClick()">
            Abbrechen
        </button>
        <button mat-raised-button color="primary" (click)="closeDialog()">
            Herunterladen
        </button>
    </mat-dialog-actions>`,
})
export class DownloadAllAntibiogrammeDialog implements OnInit {
    antibiogrammAllDownload: AntibiogrammAllDownload =
        new AntibiogrammAllDownload();

    client: Client;

    gruppeFormCtrl: UntypedFormControl = new UntypedFormControl();
    gruppen: Observable<Gruppe[]>;

    constructor(
        private gruppenService: GruppenService,
        public dialogRef: MatDialogRef<DownloadAllAntibiogrammeDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
        this.autocompleteGruppen();
    }

    setSelectedClient(c: Client) {
        this.client = c;
    }

    closeDialog() {
        this.antibiogrammAllDownload.client = this.client;
        this.dialogRef.close(this.antibiogrammAllDownload);
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    autocompleteGruppen() {
        this.gruppeFormCtrl = new UntypedFormControl();
        this.gruppen = this.gruppeFormCtrl.valueChanges.pipe(
            startWith(''),
            switchMap((value) => this.searchGruppen(value))
        );
    }

    searchGruppen(value: string) {
        return this.gruppenService.searchGruppenPraxissoftware(value).pipe(
            debounceTime(300),
            distinctUntilChanged(),
            map((response) => response.records)
        );
    }
}
