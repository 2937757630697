<div style="width: 100%;">

    <h2>Tierinfo</h2>
    <kiene-key-value-list>
        <kiene-key-value-list-item icon="label" [value]="heimtier?.name"></kiene-key-value-list-item>

        <kiene-key-value-list-item icon="pets" key="Tierart" [value]="heimtier?.tierart"></kiene-key-value-list-item>

        <kiene-key-value-list-item icon="pets" key="Tierrasse"
            [value]="heimtier?.tierrasse"></kiene-key-value-list-item>

        <kiene-key-value-list-item icon="palette" key="Farbe" [value]="heimtier?.fellfarbe"></kiene-key-value-list-item>

        <kiene-key-value-list-item icon="fitness_center" key="Gewicht"
            [value]="heimtier?.aktuelles_gewicht ? (heimtier.aktuelles_gewicht + ' kg') : null"></kiene-key-value-list-item>

        <kiene-key-value-list-item icon="calendar_today" key="Geboren"
            value="{{heimtier?.geburtsdatum | date: 'dd.MM.yyyy'}}"></kiene-key-value-list-item>

        <kiene-key-value-list-item icon="wc" key="Geschlecht"
            [value]="heimtier?.geschlecht"></kiene-key-value-list-item>

        <kiene-key-value-list-item icon="qr_code" key="Chipnummer"
            [value]="heimtier?.chipnummer "></kiene-key-value-list-item>

        <kiene-key-value-list-item icon="book_5" key="Zuchtbuchnummer"
            [value]="heimtier?.zuchtbuchnummer"></kiene-key-value-list-item>

        <kiene-key-value-list-item icon="public" key="EU-Tierpass"
            [value]="heimtier?.eu_tierpass"></kiene-key-value-list-item>

        <kiene-key-value-list-item icon="fingerprint" key="Identifizierung"
            [value]="heimtier?.identifizierung"></kiene-key-value-list-item>

        <kiene-key-value-list-item *ngIf="heimtier?.verstorben" icon="highlight_off" key="Identifizierung"
            [value]="heimtier?.verstorben ? 'Ja' : 'Nein'  "></kiene-key-value-list-item>
    </kiene-key-value-list>
</div>