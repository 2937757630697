<nav mat-tab-nav-bar style="margin-top: 63px; width: 100%; padding: 0;">
   <a #gd="routerLinkActive" *ngIf="isTabMenuVisible('/gruppen/dashboard')" [active]="gd.isActive"
      [routerLink]="['/gruppen/dashboard']" mat-tab-link routerLinkActive>&Uuml;bersicht</a>
   <a #ga="routerLinkActive" *ngIf="isTabMenuVisible('/gruppen/aktuelle')" [active]="ga.isActive"
      [routerLink]="['/gruppen/aktuelle']" mat-tab-link routerLinkActive>Rinder Gruppen</a>
   <a #ga="routerLinkActive" *ngIf="isTabMenuVisible('/gruppen/aktuelle')" [active]="ga.isActive"
      [routerLink]="['/gruppen/schweine-gruppen']" mat-tab-link routerLinkActive>Schweine Gruppen</a>
   <a #gh="routerLinkActive" *ngIf="isTabMenuVisible('/gruppen/historie')" [active]="gh.isActive"
      [routerLink]="['/gruppen/historie']" mat-tab-link routerLinkActive>Historie Gruppen</a>
   <a #ga="routerLinkActive" *ngIf="isTabMenuVisible('/gruppen/aktuelle')" [active]="ga.isActive"
      [routerLink]="['/gruppen/zeitslot-historie']" mat-tab-link routerLinkActive>Buchungs Historie</a>
   <a #gt="routerLinkActive" *ngIf="isTabMenuVisible('/gruppen/tierlisten')" [active]="gt.isActive"
      [routerLink]="['/gruppen/tierlisten']" mat-tab-link routerLinkActive>
      <kiene-tabinfo [ladeWennKunde]="true" [sendeKundeIdOptional]="true" [sendeKundeId]="true" icon=""
         urlpfad="tierliste/tabinfo.php"></kiene-tabinfo>
      &nbsp;Tierlisten</a>
   <a #gg="routerLinkActive" [active]="gg.isActive" *ngIf="isTabMenuVisible('/gruppen/abhollisten')"
      [routerLink]="['/gruppen/abhollisten']" mat-tab-link routerLinkActive>Abhollisten</a>
   <a #gg="routerLinkActive" *ngIf="isTabMenuVisible('/gruppen/aktuelle')" [active]="gg.isActive"
      [routerLink]="['/gruppen/globale-suche']" mat-tab-link routerLinkActive>Globale Tiersuche</a>
   <a #ghit="routerLinkActive" *ngIf="isTabMenuVisible('/gruppen/hitmeldungen')" [active]="ghit.isActive"
      [routerLink]="['/gruppen/hitmeldungen']" mat-tab-link routerLinkActive>HIT Meldungen</a>
</nav>
<router-outlet></router-outlet>