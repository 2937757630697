import {Component, Inject, OnInit} from '@angular/core';
import {MessageService} from '../../services/message.service';
import {AddStoragePlaceDialogComponent} from './add-storage-place-dialog/add-storage-place-dialog.component';
import {EditStoragePlaceDialogComponent} from './edit-storage-place-dialog/edit-storage-place-dialog.component';
import {StoragePlace} from './storage-place';
import {MatDialog} from '@angular/material/dialog';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {
    PdfViewerDesktopService
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {ClientService} from '../../administration/client/client.service';
import {Router} from '@angular/router';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {TextTableColumn} from '../../components/kiene-table/table-column';
import {TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {StoragePlaceService} from './storage-place.service';

@Component({
    selector: 'kiene-storage-place',
    templateUrl: './storage-place.component.html',
    styleUrls: ['./storage-place.component.scss']
})
export class StoragePlaceComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private pdfService: PdfViewerDesktopService,
        private clientService: ClientService,
        private router: Router,
        private api: KieneTableService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private service: StoragePlaceService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Lagerplätze';
        this.tableDescriptor.uniqueIdentifier = 'Lagerplätze';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'lagerplatz/';
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;

        this.tableDescriptor.columns = this.getTableColumns();
        this.tableDescriptor.options = this.getTableOptions();

        this.tableDescriptor.permissionView = 1011;
        this.tableDescriptor.permissionCreate = 1012;
        this.tableDescriptor.permissionUpdate = 1013;
        this.tableDescriptor.permissionDelete = 1014;

        this.tableDescriptor.initialSortColumn = 'bezeichnung';
        this.tableDescriptor.initialSortDirection = 'asc';

    }

    getTableColumns() {
        const columns = [];
        columns.push(new TextTableColumn('bezeichnung', 'Name'));
        columns.push(new TextTableColumn('lager', 'Lager'));
        columns.push(new TextTableColumn('wagen', 'Wagen'));
        return columns;
    }


    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'edit') {
            this.openEditStoragePlaceDialog(event.value);
        } else if (event.name === 'add') {
            this.openAddStoragePlaceDialog();
        }
    }

    openAddStoragePlaceDialog() {
        const dialogRef = this.dialog.open(AddStoragePlaceDialogComponent, {
            width: '33%'
        });

        dialogRef.afterClosed().subscribe(storagePlace => {
            if (storagePlace) {
                this.service.saveStoragePlace(storagePlace).subscribe(response => {
                    this.messageService.infoMessage('Lagerplatz ' + response.bezeichnung + ' wurde erfolgreich gespeichert!');
                    this.api.reload('openAddStoragePlaceDialog');
                }, error => {
                    this.messageService.errorMessage('Der Lagerplatz ' + storagePlace.bezeichnung + ' konnte nicht angelegt werden. Fehler: ', error);
                });
            }
        });
    }

    openEditStoragePlaceDialog(storagePlace: StoragePlace) {
        const dialogRef = this.dialog.open(EditStoragePlaceDialogComponent, {
            width: '33%',
            data: {
                storagePlace: storagePlace
            }
        });

        dialogRef.afterClosed().subscribe(sp => {
            if (sp) {
                this.service.updateStoragePlace(sp).subscribe(response => {
                    this.messageService.infoMessage('Lagerplatz ' + response.bezeichnung + ' wurde erfolgreich gespeichert!');
                    this.api.reload('openEditStoragePlaceDialog');
                }, error => {
                    this.messageService.errorMessage('Der Lagerplatz ' + storagePlace.bezeichnung + ' konnte nicht angelegt werden. Fehler: ', error);
                });
            }
        });
    }

    private getTableOptions() {
        const options = [];
        options.push(new TableOption('edit', 'bearbeiten', 'edit', 1013));
        return options;
    }
}
