import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { KalenderEintrag } from '../classes/kalender-eintrag';
import { Tierarzt } from '../../../../../projects/kiene-core/src/lib/components/tieraerzte/classes/tierarzt';
import { TierarztGruppe } from '../../../report/tierarzt/tierarzt-gruppe-report/tierarzt-gruppe';
import { MatSelectChange } from '@angular/material/select';
import { UrlaubskalenderService } from '../urlaubskalender.service';
import { MessageService } from '../../../services/message.service';
import { UntypedFormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MMMM MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'kiene-wochenend-feiertags-planung-dialog',
    templateUrl: './wochenend-feiertags-planung-dialog.component.html',
    styleUrls: ['./wochenend-feiertags-planung-dialog.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
})
export class WochenendFeiertagsPlanungDialogComponent implements OnInit {

    currentMonthCtrl: UntypedFormControl = new UntypedFormControl(moment(new Date()).startOf('month').toDate());
    currentGruppeCtrl: UntypedFormControl = new UntypedFormControl();

    eintraege: KalenderEintrag[] = [];
    tieraerzte: Tierarzt[] = [];
    tierarztGruppen: TierarztGruppe[] = [];

    isLoading = true;
    editedDate = false;

    constructor(public dialogRef: MatDialogRef<WochenendFeiertagsPlanungDialogComponent>,
        private urlaubskalenderService: UrlaubskalenderService,
        private messageService: MessageService) { }

    ngOnInit(): void {
        this.loadEintraege();
        this.loadTierarztGruppen();

        this.currentGruppeCtrl.valueChanges.subscribe(() => {
            this.loadEintraege();
        });
    }

    loadEintraege() {
        if (this.currentGruppeCtrl.value && this.currentMonthCtrl.value) {
            this.eintraege = [];
            this.loadTieraerzte();
            this.isLoading = true;
            this.urlaubskalenderService.loadWochenendeFeiertage(this.currentGruppeCtrl.value, this.currentMonthCtrl.value)
                .subscribe({
                    next: resp => {
                        this.isLoading = false;
                        this.eintraege = resp.records;
                    },
                    error: err => {
                        this.isLoading = false;
                        this.messageService.errorMessage('Fehler beim Laden der Kalendereinträge!', err);
                    }
                });
        }
    }

    loadTierarztGruppen() {
        this.urlaubskalenderService.loadTierarztGruppen().subscribe({
            next: (data) => {
                this.tierarztGruppen = data.records;
                this.currentGruppeCtrl.setValue(this.tierarztGruppen[0]);
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Fehler beim Laden der Tierarzt-Gruppen',
                    err
                );
            },
        });
    }

    loadTieraerzte(): void {
        this.urlaubskalenderService.loadTieraerzte(this.currentGruppeCtrl.value).subscribe({
            next: (data) => {
                this.tieraerzte = data.records;
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Fehler beim Laden der Tierärzte!',
                    err
                );
            },
        });
    }

    updateEintrag(event: MatSelectChange, eintrag: KalenderEintrag) {
        console.log(event);
        if (event && event.value) {
            const tierarzt: Tierarzt = event.value;
            eintrag.benutzer_id = tierarzt.benutzer_id;
            eintrag.benutzername = tierarzt.vorname + ' ' + tierarzt.nachname;
        } else {
            eintrag.benutzer_id = null;
            eintrag.benutzername = null;
        }
        eintrag.genehmigt = 1;
        this.urlaubskalenderService.createOrUpdate(eintrag).subscribe({
            next: () => {
                this.editedDate = true;
                this.messageService.infoMessage('Eintrag erfolgreich aktualisiert');
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Speichern der Einträge!', err);
            }
        });
    }

    tierarztComparator(t1: Tierarzt, t2: Tierarzt) {
        return t1 && t2 && t1.tierarzt_id === t2.tierarzt_id;
    }


    setMonthAndYear(change: moment.Moment, datepicker: MatDatepicker<any>) {
        datepicker.close();
        this.currentMonthCtrl.setValue(change.toDate());
        this.loadEintraege();
    }

    getTierarzt(eintrag: KalenderEintrag) {
        if (eintrag.benutzer_id && this.tieraerzte) {
            return this.tieraerzte.find(arzt => arzt.benutzer_id === eintrag.benutzer_id);
        }
        return null;
    }
}
