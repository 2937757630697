import {HttpParams} from '@angular/common/http';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {Client} from '../../../../client/classes/client';
import {API_BASE_URL_SERVICE} from '../../../../../kiene-core.config';
import {KieneBackendApiService} from '../../../../../services/backend-api/kiene-backend-api.service';
import {MessageService} from '../../../../../services/message-service/message.service';

@Component({
    selector: 'kiene-kontakt-anlegen-dialog',
    templateUrl: './kontakt-anlegen-dialog.component.html',
    styleUrls: ['./kontakt-anlegen-dialog.component.scss'],
})
export class KontaktAnlegenDialogComponent implements OnInit, OnDestroy {
    vvvoCtrl = new UntypedFormControl('276', [
        Validators.required,
        Validators.minLength(15),
        Validators.maxLength(15),
    ]);
    formularAnzeigen = false;
    eingabenDisabled = true;
    kunde_b: Client = new Client();
    abweichendeLieferadresseProxy = false;
    ladeKontaktFuerVVVOSub: Subscription;
    kundenvorschlaege: Client[] = [];

    constructor(
        public dialogRef: MatDialogRef<KontaktAnlegenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private kunde_id_a: number,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        private api: KieneBackendApiService,
        private messageService: MessageService
    ) {
    }

    ngOnDestroy(): void {
        this.ladeKontaktFuerVVVOSub?.unsubscribe();
    }

    ngOnInit(): void {
        this.vvvoCtrl.valueChanges.subscribe({
            next: (value: string) => {
                if (value.length === 15) {
                    this.ladeKontaktFuerVVVO(value);
                } else {
                    this.kunde_b = undefined;
                    this.formularAnzeigen = false;
                }
            },
        });
    }

    ladeKontaktFuerVVVO(vvvo: string) {
        const params = new HttpParams().set('vvvo', vvvo);

        this.ladeKontaktFuerVVVOSub?.unsubscribe();
        this.ladeKontaktFuerVVVOSub = this.api
            .getAll(
                this.apiBaseUrl + 'kunde/search_vvvo_kundenportal.php',
                params
            )
            .subscribe({
                next: (response) => {
                    if (response.count === 1) {
                        this.kunde_b = response.records[0];
                    } else if (response.count > 1) {
                        this.kundenvorschlaege = response.records;
                        this.formularAnzeigen = false;
                    } else {
                        this.kunde_b = new Client();
                        this.kunde_b.vvvo = this.vvvoCtrl.value;
                        this.formularAnzeigen = true;
                        this.eingabenDisabled = false;
                    }
                },
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
    }

    close() {
        this.dialogRef.close();
    }

    save(kunde?: Client) {
        if (this.kunde_b) {
            if (this.abweichendeLieferadresseProxy === true) {
                this.kunde_b.abweichende_lieferadresse = 1;
            } else {
                this.kunde_b.abweichende_lieferadresse = 0;
            }
        }

        const obj = {
            kunde_id_a: this.kunde_id_a,
            kunde_b: kunde ? kunde : this.kunde_b,
        };

        this.api
            .post(
                this.apiBaseUrl + 'kunde_kunde/create_kundenportal.php',
                obj,
                undefined
            )
            .subscribe({
                next: (response) => {
                    this.messageService.infoMessage(
                        'Der Kontakt wurde erfolgreich angelegt!'
                    );
                    this.dialogRef.close(response);
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }
}
