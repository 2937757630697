import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpParams, HttpHeaders, HttpRequest, HttpEventType, HttpResponse, HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'kiene-auto-upload-dialog',
  templateUrl: './auto-upload-dialog.component.html',
  styleUrls: ['./auto-upload-dialog.component.scss']
})
export class AutoUploadDialogComponent implements OnInit {

  @ViewChild('fileSelector') fileSelector;

  filesToUpload: File[] = [];
  filesProgressValues: number[] = [];
  filesUploadStatus: number[] = [];
  form: UntypedFormGroup;
  showCloseButton: boolean = false;
  showUploadAndSelector: boolean = true;

  apiUrl: string;
  nameOfIdField: string;
  id: number;

  constructor(public dialogRef: MatDialogRef<AutoUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private messageService: MessageService) {
    this.apiUrl = data.apiUrl;
  }

  ngOnInit() {

  }

  openFileSelector() {
    this.fileSelector.nativeElement.click();
  }

  handleFiles(event: any) {
    let files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      this.filesToUpload.push(files.item(i));
      this.filesProgressValues[i] = 0;
    }
  }

  isFileUploaded(file: number) {
    if (this.filesUploadStatus[file] == 1) {
      return true;
    }
    else {
      return false;
    }
  }

  uploadFilesToServer() {
    if (this.filesToUpload.length > 0) {
      for (var i = 0; i < this.filesToUpload.length; i++) {

        let fileNumber = i;
        let file = this.filesToUpload[fileNumber];

        let fd = new FormData();
        fd.append('dokument', file);


        let params = new HttpParams();


        const options = {
          headers: new HttpHeaders('Phito-Type: fileupload'),
          params: params,
          reportProgress: true,
        };

        // const request = new HttpRequest('POST', environment.BASE_URI + this.apiUrl, fd, options);
        this.http.post<any>(environment.BASE_URI + this.apiUrl, fd, options).subscribe(event => {
          if (event.type == HttpEventType.UploadProgress) {
            let percentDone = Math.round(100 * event.loaded / event.total);
            this.filesProgressValues[fileNumber] = percentDone;
            console.debug("Uploaded " + percentDone + "% of file " + file.name);
          }
          else if (event instanceof HttpResponse) {
            console.debug("Uploaded file " + file.name + " completely!");
          }
        }, error => {
          console.error("Error while uploading file: " + file.name);
          this.filesUploadStatus[fileNumber] = 2;
          this.messageService.errorMessage('Fehler', error);
          this.markFileAsFailed(fileNumber);
        }, () => {
          this.markFileAsUploaded(fileNumber);
          console.debug("Upload of file " + file.name + " done!");
          this.messageService.infoMessage(file.name + " erfolgreich hochgeladen!");
        });
      }
    } else {
      this.messageService.errorMessage('Keine Dateien zum Upload ausgewählt', null);
    }
  }

  markFileAsFailed(no: number) {
    console.debug("markFileAsFailed: " + no);
    this.filesUploadStatus[no] = 2;
    this.checkShowCloseButton();
  }

  markFileAsUploaded(no: number) {
    console.debug("markFileAsUploaded: " + no);
    this.filesUploadStatus[no] = 1;
    this.checkShowCloseButton();
  }

  checkShowCloseButton() {
    var show: boolean = true;
    for (var i = 0; i < this.filesUploadStatus.length; i++) {
      if (this.filesUploadStatus[i] == 0) {
        show = false;
      }
    }
    if (show == true) {
      this.showCloseButton = true;
    }
  }

  uploadFiles() {
    this.showUploadAndSelector = false;
    this.uploadFilesToServer();
  }

  closeDialog() {
    this.dialogRef.close(true);
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

}