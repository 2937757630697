import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TableDescriptor, ToolbarButton } from '../../components/kiene-table/table-descriptor';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../services/message.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { HttpParams } from '@angular/common/http';
import {
    ConditionSetting,
    ConditionTableColumn,
    DateTableColumn,
    KieneBooleanTableColumn,
    LinkTableColumn,
    RawLocalDateTime,
    TableColumn,
} from '../../components/kiene-table/table-column';
import { Condition, Operator, TableOption, TableOptionEvent } from '../../components/kiene-table/table-option';
import { Meldung } from './meldung';
import {
    FilterItem,
    FilterItemType,
} from '../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import {
    CreateMeldungenGebuehrenDialogComponent
} from './create-meldungen-gebuehren-dialog/create-meldungen-gebuehren-dialog.component';
import { GenerateNullmeldungDialogComponent } from './generate-nullmeldung-dialog/generate-nullmeldung-dialog.component';
import { MeldungDetailsDialogComponent } from './meldung-details-dialog/meldung-details-dialog.component';
import { MeldungService } from './meldung.service';
import { TAMAusHITDialogComponent } from './tamaus-hitdialog/tamaus-hitdialog.component';
import { Client } from '../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { ConfirmDialogInput, ConfirmDialogResult } from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import { ConfirmDialogComponent } from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'kiene-meldungen',
    templateUrl: './meldungen.component.html',
    styleUrls: ['./meldungen.component.scss'],
})
export class MeldungenComponent implements OnInit, OnDestroy {
    tableDescriptor: TableDescriptor = new TableDescriptor();
    currentClient: Client;
    watchClientSubscription: Subscription;

    constructor(
        public dialog: MatDialog,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private route: ActivatedRoute,
        private meldungService: MeldungService
    ) {
        const abgabebeleg_id =
            this.route.snapshot.queryParamMap.get('abgabebeleg_id');
        const cs = this.route.snapshot.queryParamMap.get('clearsearch');

        if (abgabebeleg_id) {
            if (!this.tableDescriptor.params) {
                this.tableDescriptor.params = new HttpParams();
            }
            this.tableDescriptor.params = this.tableDescriptor.params.append(
                'abgabebeleg_id',
                abgabebeleg_id
            );
        }

        if (cs) {
            const clearSearch = cs === 'true';
            if (clearSearch) {
                this.tableDescriptor.clearSearch = true;
            }
        }

        /*this.api
            .get(this.apiBaseUrl + 'meldung/tabinfo.php')
            .subscribe({
                next: (response) => {
                    this.anzahlFehler = response.cnt;
                },
                error: (err) => {
                    this.messageService.errorMessage(err, null);
                },
            });*/
    }

    ngOnInit() {
        this.currentClient = this.localStorageService.getCurrentClient();

        this.watchClientSubscription = this.localStorageService
            .watchCurrentClient()
            .subscribe((response) => {
                if (response) {
                    this.currentClient = response;
                    const kundeId: string =
                        this.currentClient.kunde_id.toString();
                    this.tableDescriptor.params = new HttpParams().set(
                        'kunde_id',
                        kundeId
                    );
                    this.tableService.reload();
                } else {
                    this.tableDescriptor.params = null;
                    this.tableService.reload();
                }
            });

        if (this.currentClient) {
            const kundeId: string = this.currentClient.kunde_id.toString();
            this.tableDescriptor.params = new HttpParams().set(
                'kunde_id',
                kundeId
            );
        }

        this.tableDescriptor.headline = 'Meldungen QS/HIT';
        this.tableDescriptor.uniqueIdentifier = 'Meldungen QS/HIT';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'meldung/';
        this.tableDescriptor.route = '/belege/meldungen/';
        this.tableDescriptor.nameOfIdField = 'meldung_id';
        this.tableDescriptor.searchPlaceholder = 'Meldungen durchsuchen';
        this.tableDescriptor.showDetailsAsOption = true;

        this.tableDescriptor.standardCreate = false;

        this.tableDescriptor.columns.push(new TableColumn('meldung_id', 'ID'));
        this.tableDescriptor.columns.push(
            new LinkTableColumn(
                'kunde',
                'Kunde',
                '/stammdaten/betriebe',
                'betrieb_id',
                ['kunde_id']
            )
        );
        this.tableDescriptor.columns.push(new TableColumn('zusatz', 'Zusatz'));
        this.tableDescriptor.columns.push(new TableColumn('vvvo', 'VVVO'));
        this.tableDescriptor.columns.push(new LinkTableColumn('abgabebeleg_nr', 'Abgabebeleg', '/belege/abgabebelege/', 'abgabebeleg_id'));
        this.tableDescriptor.columns.push(new TableColumn('position', 'Pos'));
        this.tableDescriptor.columns.push(
            new LinkTableColumn(
                'artikel',
                'Artikel',
                '/lager/artikel',
                'artikel_id'
            )
        );
        this.tableDescriptor.columns.push(new TableColumn('tierart', 'Tierart'));
        this.tableDescriptor.columns.push(
            new RawLocalDateTime('datum', 'Abgabe am')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('lfd_nr', 'Lfd.Nr')
        );
        this.tableDescriptor.columns.push(new TableColumn('meldung_an', 'An'));

        const conditionSettings = [
            new ConditionSetting([new Condition('status_id', Operator.GLEICH, 111)], {
                icon: 'circle',
                color: '#f4a259',
                tooltip: 'Meldung erstellt',
            }),
            new ConditionSetting([new Condition('status_id', Operator.GLEICH, 112)], {
                icon: 'check_circle',
                color: '#8cb369',
                tooltip: 'Erfolgreich gemeldet',
            }),
            new ConditionSetting([new Condition('status_id', Operator.GLEICH, 113)], {
                icon: 'error',
                color: '#bc4b51',
                tooltip: 'Fehler! Nicht gemeldet',
            }),
            new ConditionSetting([new Condition('status_id', Operator.GLEICH, 114)], {
                icon: 'circle',
                color: '#9999FF',
                tooltip: 'Wird gerade an HIT/QS übertragen',
            }),
            new ConditionSetting([new Condition('status_id', Operator.GLEICH, 115)], {
                icon: 'check_circle',
                color: '#8cb369',
                tooltip: 'Es liegt bereits ein anderer Datensatz in HIT/QS vor',
            }),
            new ConditionSetting([new Condition('status_id', Operator.GLEICH, 116)], {
                icon: 'check_circle',
                color: '#8cb369',
                tooltip: 'Produktionsart in QS unbekannt',
            }),
            new ConditionSetting([new Condition('status_id', Operator.GLEICH, 117)], {
                icon: 'check_circle',
                color: '#8cb369',
                tooltip: 'Manuell gemeldet',
            }),
        ];
        this.tableDescriptor.columns.push(
            new ConditionTableColumn('status', 'Status', conditionSettings, {
                sortFields: ['status_id'],
            })
        );

        this.tableDescriptor.columns.push(
            new KieneBooleanTableColumn('nullmeldung', 'Nullmeldung')
        );
        this.tableDescriptor.columns.push(new TableColumn('jahr', 'Jahr'));
        this.tableDescriptor.columns.push(new TableColumn('halbjahr', 'Hj.'));

        this.tableDescriptor.columns.push(
            new TableColumn(
                'fehler',
                'Fehler',
                'string',
                true,
                'fehler',
                '150px'
            )
        );
        this.tableDescriptor.columns.push(
            new DateTableColumn('gemeldet', 'Gemeldet', 'dd.MM. HH:mm \'Uhr\'')
        );
        // this.tableDescriptor.columns.push(new TableColumn('editiert', 'Editiert', 'datetime'));

        this.tableDescriptor.permissionView = 4111;
        //  this.tableDescriptor.permissionCreate = 3852;
        //  this.tableDescriptor.permissionUpdate = 3853;
        //  this.tableDescriptor.permissionDelete = 3854;
        this.tableDescriptor.filterItems = this.getFilterItems();

        this.tableDescriptor.toolbarButtons.push(
            new ToolbarButton(
                'reset',
                'settings_backup_restore',
                4115,
                'Fehlerhafte Meldungen zurücksetzen'
            )
        );
        this.tableDescriptor.toolbarButtons.push(
            new ToolbarButton(
                'gebuehren',
                'euro_symbol',
                1243,
                'Abgabebelege für HIT/QS Gebühren erstellen'
            )
        );
        this.tableDescriptor.toolbarButtons.push(
            new ToolbarButton(
                'nullmeldungen',
                'note',
                1243,
                'Nullmeldungen generieren'
            )
        );
        this.tableDescriptor.toolbarButtons.push(
            new ToolbarButton(
                'jetzt_senden',
                'send',
                1242,
                'Meldungen jetzt an HIT/QS versenden'
            )
        );

        this.tableDescriptor.initialSortColumn = 'meldung_id';
        this.tableDescriptor.initialSortDirection = 'desc';

        this.tableDescriptor.options.push(new TableOption(null, 'Manuell gemeldet', 'edit', 4113, { conditions: [new Condition('status_id', Operator.IN, [111, 113])], void: this.manuellGemeldet.bind(this) }));
    }

    public manuellGemeldet(meldung: Meldung) {
        const input = new ConfirmDialogInput();
        input.headline = 'Status editieren?';
        input.text = 'Wurde diese Meldung manuell gemeldet?';
        input.okButton = 'Ja';
        input.buttonColor = 'warn';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    meldung.status_id_neu = 117;
                    this.api.updateGeneric(this.apiBaseUrl + 'meldung/update.php', meldung).subscribe({
                        next: () => {
                            this.tableService.reload();
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler!', err);
                        }
                    });
                }
            }
        });
    }

    public getFilterItems(): FilterItem[] {
        const filter: FilterItem[] = [];

        filter.push(
            new FilterItem(
                'erstellt',
                111,
                FilterItemType.CHECKBOX,
                'status_id',
                true
            )
        );
        filter.push(
            new FilterItem(
                'gemeldet',
                112,
                FilterItemType.CHECKBOX,
                'status_id',
                true
            )
        );
        filter.push(
            new FilterItem(
                'fehlerhaft',
                113,
                FilterItemType.CHECKBOX,
                'status_id',
                true
            )
        );
        filter.push(
            new FilterItem(
                'selektiert',
                114,
                FilterItemType.CHECKBOX,
                'status_id',
                true
            )
        );
        filter.push(
            new FilterItem(
                'bereits gemeldet',
                115,
                FilterItemType.CHECKBOX,
                'status_id',
                true
            )
        );
        /*filter.push(
            new FilterItem(
                'Produktionsart in QS unbekannt',
                116,
                FilterItemType.CHECKBOX,
                'status_id',
                true
            )
        );*/

        return filter;
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'reset') {
            this.api
                .post(
                    this.apiBaseUrl + 'meldung/zuruecksetzen.php',
                    {},
                    null
                )
                .subscribe(
                    (response) => {
                        this.tableService.reload();
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Fehler beim Zurücksetzen aufgetreten',
                            error
                        );
                    }
                );
        } else if (event.name === 'gebuehren') {
            this.abgabebelegeGenerieren();
        } else if (event.name === 'nullmeldungen') {
            this.openNullmeldungDialog();
        } else if (event.name === 'details') {
            this.openMeldungDetailsDialog(event.value);
        } else if (event.name === 'jetzt_senden') {
            this.jetztSenden();
        } else if (event.name === 'tam_aus_hit') {
            this.openTAMausHITDialog(event.value);
        }
    }

    openTAMausHITDialog(value: any) {
        this.dialog.open(TAMAusHITDialogComponent, {
            data: {
                meldung: value,
            },
        });
    }

    abgabebelegeGenerieren() {
        this.dialog.open(CreateMeldungenGebuehrenDialogComponent, {
            minWidth: '400px',
        });
    }

    ngOnDestroy() {
        this.watchClientSubscription.unsubscribe();
        this.tableService = null;
    }

    openNullmeldungDialog() {
        const dialogRef = this.dialog.open(GenerateNullmeldungDialogComponent, {
            width: '400px',
        });
    }

    openMeldungDetailsDialog(meldung: Meldung) {
        const dialogRef = this.dialog.open(MeldungDetailsDialogComponent, {
            data: {
                meldung: meldung,
            },
        });
    }

    jetztSenden() {
        this.meldungService.jetztAlleMeldungenSenden().subscribe(
            (response) => {
                this.messageService.infoMessage(
                    'Die Meldungen werden jetzt nacheinander an HIT/QS versendet. Dies kann ein paar Minuten dauern...'
                );
            },
            (error) => {
                this.messageService.errorMessage(
                    'Es is ein Fehler beim Versenden aufgetreten',
                    error
                );
            }
        );
    }
}
