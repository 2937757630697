import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export class NavigationItem {
  name: string;
  route: string;
  permission: number;

  public toString(): string {
    return this.name + ' - ' + this.route;
  }
}

@Component({
  selector: 'kiene-navigator-dialog',
  templateUrl: './navigator-dialog.component.html',
  styleUrls: ['./navigator-dialog.component.css']
})
export class NavigatorDialogComponent implements OnInit {

  items: NavigationItem[] = [];

  menuCtrl = new UntypedFormControl();
  filteredItems: Observable<NavigationItem[]>;


  constructor(public dialogRef: MatDialogRef<NavigatorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private router: Router) { }

  ngOnInit(): void {

    let item = new NavigationItem();
    item.name = 'Gruppen';
    item.route = '/belege/gruppen';
    item.permission = 0;
    this.items.push(item);

    item = new NavigationItem();
    item.name = 'Abgabebelege';
    item.route = '/belege/abgabebelege';
    item.permission = 0;
    this.items.push(item);

    item = new NavigationItem();
    item.name = 'HIT/QS Meldungen';
    item.route = '/belege/meldungen';
    item.permission = 0;
    this.items.push(item);

    item = new NavigationItem();
    item.name = 'Kunden';
    item.route = '/stammdaten/kunden';
    item.permission = 0;
    this.items.push(item);

    item = new NavigationItem();
    item.name = 'Betriebsarten';
    item.route = '/stammdaten/betriebsarten';
    item.permission = 0;
    this.items.push(item);

    item = new NavigationItem();
    item.name = 'Probenbegleitscheine';
    item.route = '/labor/probenbegleitscheine';
    item.permission = 0;
    this.items.push(item);

    item = new NavigationItem();
    item.name = 'Befundmitteilungen';
    item.route = '/labor/befundmitteilungen';
    item.permission = 0;
    this.items.push(item);

    item = new NavigationItem();
    item.name = 'Antibiogramme';
    item.route = '/labor/antibiogramme';
    item.permission = 0;
    this.items.push(item);

    item = new NavigationItem();
    item.name = 'Rechnungen';
    item.route = '/finanzen/rechnungen';
    item.permission = 0;
    this.items.push(item);

    item = new NavigationItem();
    item.name = 'Gutschriften';
    item.route = '/finanzen/gutschriften';
    item.permission = 0;
    this.items.push(item);

    item = new NavigationItem();
    item.name = 'Primanotas';
    item.route = '/finanzen/primanota';
    item.permission = 0;
    this.items.push(item);

    item = new NavigationItem();
    item.name = 'Artikel';
    item.route = '/lager/artikel';
    item.permission = 0;
    this.items.push(item);




    this.filteredItems = this.menuCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  private _filter(value: string): NavigationItem[] {
    if (!value) {
      return this.items;
    }
    const filterValue = value.toLowerCase();

    return this.items.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  mapper(item: NavigationItem): string | null {
    if (item) {

      return item.toString();
    }
    return null;
  }

  navigationItemSelected(event: MatAutocompleteSelectedEvent) {
    this.router.navigate([event.option.value.route]);
    this.dialogRef.close();
  }

}
