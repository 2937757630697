<h2 mat-dialog-title>Indikation für {{material.bezeichnung}}</h2>
<mat-dialog-content>
  <mat-form-field style="width: 100%;">
    <input type="text" matInput placeholder="Bezeichnung" [formControl]="diagnoseCtrl">
  </mat-form-field>
  <mat-form-field style="width: 100%;">
    <mat-label>Tierart</mat-label>
    <mat-select [formControl]="tierartCtrl" [compareWith]="tierartComparator" multiple>
      <mat-option *ngFor="let t of tierarten" [value]="t">{{t.tierart}}</mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
  <button mat-raised-button color="primary" (click)="save()">Speichern</button>
</mat-dialog-actions>