<div class="kiene-content-page">
  <mat-card>
    <div *ngIf="isLoading" class="kiene-loading-spinner">
      <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>
    <div class="kiene-table-header">
      <h3>Benutzerrollen</h3>
    </div>

    <div>
      <mat-form-field style="width: 100%;">
        <mat-label>Benutzerrolle</mat-label>
        <mat-select (selectionChange)="setSelectedRole($event)" placeholder="Benutzerrolle auswählen...">
          <mat-option *ngFor="let u of userroles" [value]="u">{{u.bezeichnung}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <h4 *ngIf="currentUserrole">Benutzerrechte für Rolle {{currentUserrole.bezeichnung}}:</h4>
    <!--
    <div *kienePermission="[1052]">
      <mat-form-field *ngIf="currentUserrole" style="width: 600px; min-width: 200px;">
        <mat-select placeholder="Ein Recht der Rolle hinzufügen..." [formControl]="userRightCtrl">
          <mat-option *ngFor="let r of notSelectedRights" [value]="r">
            {{r.bezeichnung}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <button *ngIf="currentUserrole" style="margin-left: 20px;" mat-raised-button color="primary" (click)="addSelectedRightToRole()">hinzufügen</button>
    </div>
-->

    <mat-accordion class="example-headers-align">
      <mat-expansion-panel *ngFor="let m of menus" [disabled]="!currentUserrole">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{m}}
          </mat-panel-title>

        </mat-expansion-panel-header>

        <div *ngFor="let group of groups[m]">
          <p style="font-weight: bold;">{{group}}</p>
          <mat-checkbox *ngFor="let ur of actions[m+'-'+group]" style="margin-right: 12px;"
            [(ngModel)]="userrightArray[ur.benutzerrecht_id]" (change)="setUserright($event, ur)">
            {{ur.bezeichnung.split(' - ')[2]}}
          </mat-checkbox>
        </div>

      </mat-expansion-panel>

    </mat-accordion>
    <!--
    <table class="phito-table">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th>Benutzerrecht</th> -->
    <!-- <th></th> -->
    <!-- </tr>
      </thead>
      <tbody>
        <tr *ngFor="let ur of userrights">
          <td></td>
          <td>
            <mat-checkbox [(ngModel)]="userrightArray[ur.benutzerrecht_id]" (change)="setUserright($event, ur)">
            </mat-checkbox>
          </td>
          <td>{{ur.bezeichnung}}</td> -->
    <!-- <td>
            <kiene-delete-button *kienePermission=[1054] (deleted)="removeSelectedRightFromRole(ur)">
            </kiene-delete-button>
          </td> -->
    <!-- </tr>
      </tbody>
    </table> -->


    <!-- <table *ngIf="userroleRights.length > 0" mat-table [dataSource]="userroleDatasource" style="width: 100%;">

      <ng-container matColumnDef="benutzerrecht">
        <th mat-header-cell *matHeaderCellDef>Benutzerrecht</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <div *ngIf="i>0">{{element.benutzerrecht}} {{element.nur_admin ? ' (Admin)' : ''}}</div>
          <div *ngIf="i==0">
            <div *kienePermission="[1052]">
              <mat-form-field *ngIf="currentUserrole" style="width: 50%; min-width: 200px;">
                <mat-select placeholder="Ein Recht der Rolle hinzufügen..." [formControl]="userRightCtrl">
                  <mat-option *ngFor="let r of notSelectedRights" [value]="r">
                    {{r.bezeichnung}} {{r.nur_admin ? ' (Admin)' : ''}}
                  </mat-option>
                </mat-select>
              </mat-form-field>


              <button *ngIf="currentUserrole" style="margin-left: 20px;" mat-raised-button color="primary" (click)="addSelectedRightToRole()" [disabled]="userRightCtrl.value == null">hinzufügen</button>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="deletebutton">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; let i = index">
          <div *ngIf="i>0">
            <button mat-icon-button *kienePermission=[1054] (click)="removeSelectedRightFromRole(element)">
              <mat-icon color="primary">delete</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table> -->

  </mat-card>
</div>