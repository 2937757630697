import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Material } from '../../material/material';
import { KieneBackendApiService } from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-goods-receipt-add-article',
    templateUrl: './goods-receipt-add-article.component.html',
    styleUrls: ['./goods-receipt-add-article.component.scss']
})
export class GoodsReceiptAddArticleComponent implements OnInit {
    produktkennzeichnungCtrl = new FormControl();
    artikel: Material;

    constructor(
        @Inject(MAT_DIALOG_DATA) public produktkennzeichnung: string,
        public dialogRef: MatDialogRef<GoodsReceiptAddArticleComponent>,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    selectMaterial(material: Material) {
        this.artikel = material;
        if (this.artikel) {
            let pk = '';
            if (this.artikel.produktkennzeichnungen) {
                pk = this.artikel.produktkennzeichnungen + ', ';
            }
            pk += this.produktkennzeichnung;
            this.produktkennzeichnungCtrl.setValue(pk);
        } else {
            this.produktkennzeichnungCtrl.setValue(this.produktkennzeichnung);
        }
    }

    ngOnInit(): void {
        this.produktkennzeichnungCtrl.setValue(this.produktkennzeichnung);
    }

    public close() {
        this.dialogRef.close();
    }

    public confirm() {
        this.artikel.produktkennzeichnungen = this.produktkennzeichnungCtrl.value;
        this.api.post(this.apiBaseUrl + 'artikel/update_allgemein.php', this.artikel).subscribe({
            next: (artikel: Material) => {
                this.dialogRef.close(artikel);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }


}
