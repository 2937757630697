<h1 mat-dialog-title>
    Tierwohlprotokoll Rind vom
    {{ tierwohlprotokollRind?.datum | date: "dd.MM.yy" }}
</h1>

<div mat-dialog-content>
    <div class="info-box">
        <mat-checkbox [formControl]="abrechnenCtrl">Tierwohlprotokoll abrechnen?</mat-checkbox>
        <span class="spacer"></span>
    </div>
    <div style="width: 100%" id="betrieb-tierarzt">
        <div style="width: 45%">
            <h3>Betrieb</h3>
            <kiene-autocomplete [showClear]="true" [showDeleteButton]="false" [required]="true" [apiUrl]="'kunde/'"
                [separator]="' | '" [displayFields]="[
                    'bezeichnung_final',
                    'vvvo',
                    'strasse_final',
                    'plz_final',
                    'ort_final'
                ]" [placeholder]="'Betrieb'" [kieneObject]="selectedKunde" [focus]="false"
                (elementSelected)="setKunde($event)">
            </kiene-autocomplete>
        </div>

        <mat-form-field class="no-padding-margin-mat-form-field">
            <mat-label>Datum</mat-label>
            <input matInput [matDatepicker]="dpDatum" [(ngModel)]="tierwohlprotokollRind.datum" />
            <mat-datepicker-toggle matSuffix [for]="dpDatum"></mat-datepicker-toggle>
            <mat-datepicker #dpDatum></mat-datepicker>
        </mat-form-field>

        <div *ngIf="!userIstTierarzt" style="width: 45%">
            <h3>Tierarzt</h3>
            <kiene-autocomplete [showClear]="true" [showDeleteButton]="false" [required]="true" [apiUrl]="'tierarzt/'"
                [displayFields]="['vorname', 'nachname']" [placeholder]="'Tierarzt'" [kieneObject]="selectedTierarzt"
                [focus]="false" (elementSelected)="setTierarzt($event)">
            </kiene-autocomplete>
        </div>
    </div>

    <div id="tierbestand">
        <div id="tiere">
            <h3>Anzahl Tierpl&auml;tze</h3>
            <div class="row">
                <mat-form-field>
                    <mat-label>Milchk&uuml;he</mat-label>
                    <input matInput type="number" [(ngModel)]="tierwohlprotokollRind.anzahl_milchkuehe" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>F&auml;rsen</mat-label>
                    <input matInput type="number" [(ngModel)]="tierwohlprotokollRind.anzahl_faersen" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>K&auml;lber</mat-label>
                    <input matInput type="number" [(ngModel)]="tierwohlprotokollRind.anzahl_kaelber" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Mastrinder</mat-label>
                    <input matInput type="number" [(ngModel)]="tierwohlprotokollRind.anzahl_mastrinder" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Mastk&auml;lber</mat-label>
                    <input matInput type="number" [(ngModel)]="tierwohlprotokollRind.anzahl_mastkaelber" />
                </mat-form-field>
            </div>
        </div>
        <div id="verluste">
            <div style="margin-bottom: 1em">
                <h3 style="margin-block-end: 0.25em">Verluste</h3>
                <mat-hint>(z.B. seit letzter Bestandsuntersuchung im
                    Kalenderjahr/Betriebsjahr)
                </mat-hint>
            </div>

            <div class="row">
                <mat-form-field>
                    <mat-label>K&auml;lber</mat-label>
                    <input matInput type="text" [(ngModel)]="tierwohlprotokollRind.verluste_kaelber" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>adulte Tiere</mat-label>
                    <input matInput type="text" [(ngModel)]="
                            tierwohlprotokollRind.verluste_adulte_tiere
                        " />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Abortrate</mat-label>
                    <input matInput type="text" [(ngModel)]="tierwohlprotokollRind.verluste_abortrate" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Totgeburten</mat-label>
                    <input matInput type="text" [(ngModel)]="tierwohlprotokollRind.verluste_totgeburten" />
                </mat-form-field>
            </div>
        </div>
        <div id="biologische-daten">
            <h3>Biologische Daten</h3>
            <div class="row">
                <mat-form-field>
                    <mat-label>Tageszunahmen (Masttiere)</mat-label>
                    <input matInput type="number" [(ngModel)]="
                            tierwohlprotokollRind.biologisch_tageszunahmen_masttiere
                        " />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>&Oslash;-Nutzungsdauer (Milchvieh)</mat-label>
                    <input matInput type="number" [(ngModel)]="
                            tierwohlprotokollRind.biologisch_nutzungsdauer_milchvieh
                        " />
                </mat-form-field>
            </div>
        </div>
    </div>

    <div id="gesundheitsstatus-seuchen">
        <div id="gesundheitsstatus-bv">
            <h3>Gesundheitsstatus</h3>
            <div class="row row--radio_buttons">
                <strong>BHV1:</strong>
                <mat-radio-group [(ngModel)]="tierwohlprotokollRind.gesundheitsstatus_bhv1">
                    <mat-radio-button class="row--radio_buttons__btn" [value]="1">positiv
                    </mat-radio-button>
                    <mat-radio-button class="row--radio_buttons__btn" [value]="0">negativ
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="row row--radio_buttons">
                <strong>BVD:</strong>
                <mat-radio-group [(ngModel)]="tierwohlprotokollRind.gesundheitsstatus_bvd">
                    <mat-radio-button class="row--radio_buttons__btn" [value]="1">positiv
                    </mat-radio-button>
                    <mat-radio-button class="row--radio_buttons__btn" [value]="0">negativ
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div id="bestand-klinisch">
            <h3>Bestand klinisch ohne besonderen Befund</h3>
            <div class="row row--radio_buttons">
                <mat-radio-group [(ngModel)]="
                        tierwohlprotokollRind.bestand_klinisch_ohne_befund
                    ">
                    <mat-radio-button class="row--radio_buttons__btn" [value]="1">Ja
                    </mat-radio-button>
                    <mat-radio-button class="row--radio_buttons__btn" [value]="0">Nein
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div id="seuchen">
            <h3>Hinweise auf Tierseuchen</h3>
            <div class="row row--radio_buttons">
                <mat-radio-group [(ngModel)]="tierwohlprotokollRind.hinweise_tierseuchen">
                    <mat-radio-button class="row--radio_buttons__btn" [value]="1">Ja
                    </mat-radio-button>
                    <mat-radio-button class="row--radio_buttons__btn" [value]="0">Nein
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>

    <div id="managementfaktoren-gesundheitsstatus">
        <div id="managementfaktoren">
            <div style="margin-bottom: 1em">
                <h3 style="margin-block-end: 0.25em">Managementfaktoren</h3>
                <mat-hint>Folgende Bereiche wurden zur Erhebung von
                    Managementfaktoren berücksichtigt.
                </mat-hint>
            </div>

            <div class="row row--checkboxes">
                <mat-checkbox class="row--checkboxes__box" [ngModel]="
                        !!tierwohlprotokollRind.mangagementfaktor_haltungssystem
                    " (ngModelChange)="
                        tierwohlprotokollRind.mangagementfaktor_haltungssystem =
                            $event ? 1 : 0
                    ">
                    Haltungssystem
                </mat-checkbox>

                <mat-checkbox class="row--checkboxes__box" [ngModel]="
                        !!tierwohlprotokollRind.managementfaktor_fuetterung_wasserversorgung
                    " (ngModelChange)="
                        tierwohlprotokollRind.managementfaktor_fuetterung_wasserversorgung =
                            $event ? 1 : 0
                    ">
                    F&uuml;tterung und Wasserversorgung
                </mat-checkbox>

                <mat-checkbox class="row--checkboxes__box" [ngModel]="!!tierwohlprotokollRind.managementfaktor_betrieb"
                    (ngModelChange)="
                        tierwohlprotokollRind.managementfaktor_betrieb = $event
                            ? 1
                            : 0
                    ">
                    Betriebsmanagement
                </mat-checkbox>
            </div>

            <div class="row row--checkbox">
                <mat-checkbox class="row--checkboxes__box" [ngModel]="!!tierwohlprotokollRind.managementfaktor_hygiene"
                    (ngModelChange)="
                        tierwohlprotokollRind.managementfaktor_hygiene = $event
                            ? 1
                            : 0
                    ">
                    Hygienemanagement
                </mat-checkbox>

                <mat-checkbox class="row--checkboxes__box" [ngModel]="!!tierwohlprotokollRind.managementfaktor_zucht"
                    (ngModelChange)="
                        tierwohlprotokollRind.managementfaktor_zucht = $event
                            ? 1
                            : 0
                    ">
                    Zuchtmanagement (Reproduktion/Genetik)
                </mat-checkbox>

                <mat-checkbox class="row--checkboxes__box" [ngModel]="
                        !!tierwohlprotokollRind.managementfaktor_sonstiges
                    " (ngModelChange)="
                        tierwohlprotokollRind.managementfaktor_sonstiges =
                            $event ? 1 : 0
                    ">
                    Sonstiges
                </mat-checkbox>
            </div>
        </div>

        <div id="gesundheitsstatus">
            <h3>Erhebung des Gesundheitsstatus</h3>

            <div class="row row--checkboxes">
                <mat-checkbox class="row--checkboxes__box" [ngModel]="
                        !!tierwohlprotokollRind.gesundheitsstatus_atmungsorgane
                    " (ngModelChange)="
                        tierwohlprotokollRind.gesundheitsstatus_atmungsorgane =
                            $event ? 1 : 0
                    ">
                    Atmungsorgane
                </mat-checkbox>

                <mat-checkbox class="row--checkboxes__box" [ngModel]="
                        !!tierwohlprotokollRind.gesundheitsstatus_verdauungsapparat
                    " (ngModelChange)="
                        tierwohlprotokollRind.gesundheitsstatus_verdauungsapparat =
                            $event ? 1 : 0
                    ">
                    Verdauungsapparat
                </mat-checkbox>

                <mat-checkbox class="row--checkboxes__box" [ngModel]="
                        !!tierwohlprotokollRind.gesundheitsstatus_bewegungsapparat
                    " (ngModelChange)="
                        tierwohlprotokollRind.gesundheitsstatus_bewegungsapparat =
                            $event ? 1 : 0
                    ">
                    Bewegungsapparat
                </mat-checkbox>
            </div>

            <div class="row row--checkbox">
                <mat-checkbox class="row--checkboxes__box" [ngModel]="
                        !!tierwohlprotokollRind.gesundheitsstatus_haut_kondition
                    " (ngModelChange)="
                        tierwohlprotokollRind.gesundheitsstatus_haut_kondition =
                            $event ? 1 : 0
                    ">
                    Haut, Kondition
                </mat-checkbox>

                <mat-checkbox class="row--checkboxes__box" [ngModel]="
                        !!tierwohlprotokollRind.gesundheitsstatus_eutergesundheit
                    " (ngModelChange)="
                        tierwohlprotokollRind.gesundheitsstatus_eutergesundheit =
                            $event ? 1 : 0
                    ">
                    Eutergesundheit
                </mat-checkbox>

                <mat-checkbox class="row--checkboxes__box" [ngModel]="
                        !!tierwohlprotokollRind.gesundheitsstatus_sonstiges
                    " (ngModelChange)="
                        tierwohlprotokollRind.gesundheitsstatus_sonstiges =
                            $event ? 1 : 0
                    ">
                    Sonstiges
                </mat-checkbox>
            </div>
        </div>
    </div>

    <div id="verdachtsdiagnose">
        <h3>Verdachtsdiagnosen</h3>
        <mat-form-field style="width: 50%">
            <textarea matInput rows="5" [(ngModel)]="tierwohlprotokollRind.verdachtsdiagnosen"></textarea>
        </mat-form-field>
    </div>

    <div id="diagnostische-massnahmen">
        <h3>Diagnostische Ma&szlig;nahmen</h3>

        <div class="row row--checkboxes">
            <mat-checkbox class="row--checkboxes__box" [ngModel]="
                    !!tierwohlprotokollRind.diagnostische_massnahmen_sektion
                " (ngModelChange)="
                    tierwohlprotokollRind.diagnostische_massnahmen_sektion =
                        $event ? 1 : 0
                ">
                Sektion
            </mat-checkbox>

            <mat-checkbox class="row--checkboxes__box" [ngModel]="!!tierwohlprotokollRind.diagnostische_massnahmen_kot"
                (ngModelChange)="
                    tierwohlprotokollRind.diagnostische_massnahmen_kot = $event
                        ? 1
                        : 0
                ">
                Kotprobe
            </mat-checkbox>

            <mat-checkbox class="row--checkboxes__box" [ngModel]="
                    !!tierwohlprotokollRind.diagnostische_massnahmen_resistenztest
                " (ngModelChange)="
                    tierwohlprotokollRind.diagnostische_massnahmen_resistenztest =
                        $event ? 1 : 0
                ">
                Resistenztest
            </mat-checkbox>
        </div>

        <div class="row row--checkbox">
            <mat-checkbox class="row--checkboxes__box" [ngModel]="
                    !!tierwohlprotokollRind.diagnostische_massnahmen_futter_wasser
                " (ngModelChange)="
                    tierwohlprotokollRind.diagnostische_massnahmen_futter_wasser =
                        $event ? 1 : 0
                ">
                Futter-/Wasserprobe
            </mat-checkbox>

            <mat-checkbox class="row--checkboxes__box" [ngModel]="
                    !!tierwohlprotokollRind.diagnostische_massnahmen_blut
                " (ngModelChange)="
                    tierwohlprotokollRind.diagnostische_massnahmen_blut = $event
                        ? 1
                        : 0
                ">
                Blutprobe
            </mat-checkbox>

            <mat-checkbox class="row--checkboxes__box" [ngModel]="
                    !!tierwohlprotokollRind.diagnostische_massnahmen_hautgeschabsel
                " (ngModelChange)="
                    tierwohlprotokollRind.diagnostische_massnahmen_hautgeschabsel =
                        $event ? 1 : 0
                ">
                Hautgeschabsel
            </mat-checkbox>
        </div>

        <div class="row row--checkbox">
            <mat-checkbox class="row--checkboxes__box" [ngModel]="
                    !!tierwohlprotokollRind.diagnostische_massnahmen_milch
                " (ngModelChange)="
                    tierwohlprotokollRind.diagnostische_massnahmen_milch =
                        $event ? 1 : 0
                ">
                Milchprobe
            </mat-checkbox>

            <mat-checkbox class="row--checkboxes__box" [ngModel]="
                    !!tierwohlprotokollRind.diagnostische_massnahmen_tupfer
                " (ngModelChange)="
                    tierwohlprotokollRind.diagnostische_massnahmen_tupfer =
                        $event ? 1 : 0
                ">
                Tupferprobe
            </mat-checkbox>

            <div class="row row--checkbox row--checkboxes__box">
                <mat-checkbox class="row--checkboxes__box" [ngModel]="
                        !!tierwohlprotokollRind.diagnostische_massnahmen_sonstiges
                    " (ngModelChange)="
                        tierwohlprotokollRind.diagnostische_massnahmen_sonstiges =
                            $event ? 1 : 0
                    ">
                    Sonstiges
                </mat-checkbox>

                <mat-form-field style="width: 100%; padding-left: 6px" class="no-padding-margin-mat-form-field">
                    <mat-label>Sonstiges</mat-label>
                    <input [disabled]="
                            !tierwohlprotokollRind.diagnostische_massnahmen_sonstiges ||
                            tierwohlprotokollRind.diagnostische_massnahmen_sonstiges ===
                                0
                        " matInput [(ngModel)]="
                            tierwohlprotokollRind.diagnostische_massnahmen_sonstiges_txt
                        " />
                </mat-form-field>
            </div>
        </div>
    </div>

    <div id="eingesehen">
        <div>
            <h3>Antibiotikamonitoring (bei Masttieren)</h3>
            <div class="row row--radio_buttons">
                <strong>eingesehen:</strong>

                <mat-radio-group [(ngModel)]="tierwohlprotokollRind.antibiotika_monitoring">
                    <mat-radio-button class="row--radio_buttons__btn" [value]="1">Ja
                    </mat-radio-button>
                    <mat-radio-button class="row--radio_buttons__btn" [value]="0">Nein
                    </mat-radio-button>
                </mat-radio-group>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Therapieindex</mat-label>
                    <input matInput [disabled]="
                            !tierwohlprotokollRind.antibiotika_monitoring ||
                            tierwohlprotokollRind.antibiotika_monitoring === 0
                        " [(ngModel)]="
                            tierwohlprotokollRind.antibiotika_monitoring_therapieindex
                        " />
                </mat-form-field>
            </div>
        </div>

        <div>
            <h3>Betriebliche Eigenkontrolle</h3>
            <div class="row row--radio_buttons">
                <strong>eingesehen:</strong>

                <mat-radio-group [(ngModel)]="
                        tierwohlprotokollRind.betriebliche_eigenkontrolle
                    ">
                    <mat-radio-button class="row--radio_buttons__btn" [value]="1">Ja
                    </mat-radio-button>
                    <mat-radio-button class="row--radio_buttons__btn" [value]="0">Nein
                    </mat-radio-button>
                </mat-radio-group>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Kommentar</mat-label>
                    <input matInput [disabled]="
                            !tierwohlprotokollRind.betriebliche_eigenkontrolle
                        " [(ngModel)]="
                            tierwohlprotokollRind.betriebliche_eigenkontrolle_txt
                        " />
                </mat-form-field>
            </div>
        </div>

        <div>
            <h3>Tierschutzindikatoren</h3>
            <div class="row row--radio_buttons">
                <strong>eingesehen:</strong>

                <mat-radio-group [(ngModel)]="tierwohlprotokollRind.tierschutzindikatoren">
                    <mat-radio-button class="row--radio_buttons__btn" [value]="1">Ja
                    </mat-radio-button>
                    <mat-radio-button class="row--radio_buttons__btn" [value]="0">Nein
                    </mat-radio-button>
                </mat-radio-group>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Kommentar</mat-label>
                    <input matInput [disabled]="
                            !tierwohlprotokollRind.tierschutzindikatoren
                        " [(ngModel)]="
                            tierwohlprotokollRind.tierschutzindikatoren_txt
                        " />
                </mat-form-field>
            </div>
        </div>

        <div>
            <h3>Ergebnisse der Milchleistungspr&uuml;fung</h3>
            <div class="row row--radio_buttons">
                <strong>eingesehen:</strong>

                <mat-radio-group [(ngModel)]="tierwohlprotokollRind.milchleistungspruefung">
                    <mat-radio-button class="row--radio_buttons__btn" [value]="1">Ja
                    </mat-radio-button>
                    <mat-radio-button class="row--radio_buttons__btn" [value]="0">Nein
                    </mat-radio-button>
                </mat-radio-group>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Kommentar</mat-label>
                    <input matInput [disabled]="
                            !tierwohlprotokollRind.milchleistungspruefung
                        " [(ngModel)]="
                            tierwohlprotokollRind.milchleistungspruefung_txt
                        " />
                </mat-form-field>
            </div>
        </div>

        <div>
            <h3>Rationsberechnung/en</h3>
            <div class="row row--radio_buttons">
                <strong>eingesehen:</strong>

                <mat-radio-group [(ngModel)]="tierwohlprotokollRind.rationsberechnung">
                    <mat-radio-button class="row--radio_buttons__btn" [value]="1">Ja
                    </mat-radio-button>
                    <mat-radio-button class="row--radio_buttons__btn" [value]="0">Nein
                    </mat-radio-button>
                </mat-radio-group>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Kommentar</mat-label>
                    <input matInput [disabled]="!tierwohlprotokollRind.rationsberechnung" [(ngModel)]="
                            tierwohlprotokollRind.rationsberechnung_txt
                        " />
                </mat-form-field>
            </div>
        </div>

        <div>
            <h3>Tr&auml;nkwasserqualit&auml;t</h3>
            <div class="row row--radio_buttons">
                <strong>eingesehen:</strong>

                <mat-radio-group [(ngModel)]="tierwohlprotokollRind.traenkwasser_qualitaet">
                    <mat-radio-button class="row--radio_buttons__btn" [value]="1">Ja
                    </mat-radio-button>
                    <mat-radio-button class="row--radio_buttons__btn" [value]="0">Nein
                    </mat-radio-button>
                </mat-radio-group>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Kommentar</mat-label>
                    <input matInput [disabled]="
                            !tierwohlprotokollRind.traenkwasser_qualitaet
                        " [(ngModel)]="
                            tierwohlprotokollRind.traenkwasser_qualitaet_txt
                        " />
                </mat-form-field>
            </div>
        </div>

        <div>
            <h3>Stallklima</h3>
            <div class="row row--radio_buttons">
                <strong>kontrolliert:</strong>

                <mat-radio-group [(ngModel)]="tierwohlprotokollRind.stallklima_kontrolliert">
                    <mat-radio-button class="row--radio_buttons__btn" [value]="1">Ja
                    </mat-radio-button>
                    <mat-radio-button class="row--radio_buttons__btn" [value]="0">Nein
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>

    <div id="impfprogramm">
        <h3>Impfprogramm</h3>

        <div class="row row--checkboxes">
            <mat-checkbox class="row--checkboxes__box" [ngModel]="!!tierwohlprotokollRind.impfprogramm_bvd"
                (ngModelChange)="
                    tierwohlprotokollRind.impfprogramm_bvd = $event ? 1 : 0
                ">
                BVD
            </mat-checkbox>

            <mat-checkbox class="row--checkboxes__box" [ngModel]="
                    !!tierwohlprotokollRind.impfprogramm_brsv_pasteurellen
                " (ngModelChange)="
                    tierwohlprotokollRind.impfprogramm_brsv_pasteurellen =
                        $event ? 1 : 0
                ">
                BRSV + Pasteurellen
            </mat-checkbox>

            <mat-checkbox class="row--checkboxes__box" [ngModel]="!!tierwohlprotokollRind.impfprogramm_blauzunge"
                (ngModelChange)="
                    tierwohlprotokollRind.impfprogramm_blauzunge = $event
                        ? 1
                        : 0
                ">
                Blauzunge
            </mat-checkbox>
        </div>

        <div class="row row--checkbox">
            <mat-checkbox class="row--checkboxes__box" [ngModel]="!!tierwohlprotokollRind.impfprogramm_bhv1"
                (ngModelChange)="
                    tierwohlprotokollRind.impfprogramm_bhv1 = $event ? 1 : 0
                ">
                BHV1
            </mat-checkbox>

            <mat-checkbox class="row--checkboxes__box" [ngModel]="!!tierwohlprotokollRind.impfprogramm_mutterschutz"
                (ngModelChange)="
                    tierwohlprotokollRind.impfprogramm_mutterschutz = $event
                        ? 1
                        : 0
                ">
                Mutterschutz (Rota-Viren)
            </mat-checkbox>

            <div class="row row--checkbox row--checkboxes__box">
                <mat-checkbox class="row--checkboxes__box" [ngModel]="!!tierwohlprotokollRind.impfprogramm_sonstiges"
                    (ngModelChange)="
                        tierwohlprotokollRind.impfprogramm_sonstiges = $event
                            ? 1
                            : 0
                    ">
                    Sonstiges
                </mat-checkbox>

                <mat-form-field style="width: 100%; padding-left: 6px" class="no-padding-margin-mat-form-field">
                    <mat-label>Sonstiges</mat-label>
                    <input [disabled]="
                            !tierwohlprotokollRind.impfprogramm_sonstiges ||
                            tierwohlprotokollRind.impfprogramm_sonstiges === 0
                        " matInput [(ngModel)]="
                            tierwohlprotokollRind.impfprogramm_sonstiges_txt
                        " />
                </mat-form-field>
            </div>
        </div>
    </div>

    <div id="parasitoligische-massnahmen">
        <div style="margin-bottom: 1em">
            <h3 style="margin-block-end: 0.25em">
                Parasitologische Ma&szlig;nahmen
            </h3>
            <strong>Prophylaxe gegen:</strong>
        </div>

        <div class="row" style="width: 100%">
            <div class="row row--checkbox row--checkboxes__box" style="width: 50%">
                <mat-checkbox style="width: 25%" [ngModel]="
                        !!tierwohlprotokollRind.parasitologische_massnahmen_endoparasiten
                    " (ngModelChange)="
                        tierwohlprotokollRind.parasitologische_massnahmen_endoparasiten =
                            $event ? 1 : 0
                    ">
                    Endoparasiten
                </mat-checkbox>

                <mat-form-field style="width: 25%" class="no-padding-margin-mat-form-field">
                    <mat-label>Zuletzt</mat-label>
                    <input matInput [matDatepicker]="dpEndo" [(ngModel)]="
                            tierwohlprotokollRind.parasitologische_massnahmen_endoparasiten_zuletzt
                        " />
                    <mat-datepicker-toggle matSuffix [for]="dpEndo"></mat-datepicker-toggle>
                    <mat-datepicker #dpEndo></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="row row--checkbox row--checkboxes__box" style="width: 50%">
                <mat-checkbox style="width: 25%" [ngModel]="
                        !!tierwohlprotokollRind.parasitologische_massnahmen_ektoparasiten
                    " (ngModelChange)="
                        tierwohlprotokollRind.parasitologische_massnahmen_ektoparasiten =
                            $event ? 1 : 0
                    ">
                    Ektoparasiten
                </mat-checkbox>

                <mat-form-field style="width: 25%" class="no-padding-margin-mat-form-field">
                    <mat-label>Zuletzt</mat-label>
                    <input matInput [matDatepicker]="dpEkto" [(ngModel)]="
                            tierwohlprotokollRind.parasitologische_massnahmen_ektoparasiten_zuletzt
                        " />
                    <mat-datepicker-toggle matSuffix [for]="dpEkto"></mat-datepicker-toggle>
                    <mat-datepicker #dpEkto></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div id="einschaetzung">
        <div style="margin-bottom: 1em">
            <h3 style="margin-block-end: 0.25em">
                Aktuelle Einsch&auml;tzung / Auff&auml;lligkeiten /
                Bestandsproblem
            </h3>
            <div style="width: 50%">
                <mat-hint style="width: 50%"><strong>Hinweis:&nbsp;</strong>Bei gemeinsam festgestellten
                    Handlungsbedarf ist individuell für den Betrieb ein Plan für
                    Tiergesundheits- und Hygienemanagement zu erstellen. Ggf.
                    ist au&szlig;erdem ein Ma&szlig;nahmenplan aufzustellen, der
                    die Einzelaktivit&auml;ten (von Tierarzt und Tierhalter)
                    festlegt.
                </mat-hint>
            </div>
        </div>

        <mat-form-field style="width: 50%">
            <textarea matInput rows="3" [(ngModel)]="
                    tierwohlprotokollRind.einschaetzung_auffaelligkeiten_bestandsprobleme
                "></textarea>
        </mat-form-field>
    </div>

    <div id="empfehlungen">
        <h3>Empfehlungen / Ma&szlig;nahmen</h3>

        <mat-form-field style="width: 50%">
            <textarea matInput rows="3" [(ngModel)]="tierwohlprotokollRind.empfehlungen_massnahmen"></textarea>
        </mat-form-field>
    </div>

    <div id="weitere-bemerkungen">
        <h3>Weitere Bemerkungen</h3>

        <mat-form-field style="width: 50%">
            <textarea matInput rows="3" [(ngModel)]="tierwohlprotokollRind.weitere_bemerkungen"></textarea>
        </mat-form-field>
    </div>

    <div>
        <div>
            <h3>Plan f&uuml;r Tiergesundheits- und Hygienemanagement</h3>
            <div class="row row--radio_buttons">
                <mat-radio-group [(ngModel)]="
                        tierwohlprotokollRind.plan_tiergesundheit_hygienemanagement
                    ">
                    <mat-radio-button class="row--radio_buttons__btn" [value]="1">Ja
                    </mat-radio-button>
                    <mat-radio-button class="row--radio_buttons__btn" [value]="0">Nein
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div>
            <h3>Ma&szlig;nahmenplan mit Einzelaktivit&auml;ten</h3>
            <div class="row row--radio_buttons">
                <mat-radio-group [(ngModel)]="
                        tierwohlprotokollRind.massnahmenplan_einzelaktivitaeten
                    ">
                    <mat-radio-button class="row--radio_buttons__btn" [value]="1">Ja
                    </mat-radio-button>
                    <mat-radio-button class="row--radio_buttons__btn" [value]="0">Nein
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div id="durchfuehrung-bis">
            <h3>Durchf&uuml;hrung vereinbart bis</h3>
            <mat-form-field style="width: 25%" class="no-padding-margin-mat-form-field">
                <input matInput [matDatepicker]="durchfuehrungBis"
                    [(ngModel)]="tierwohlprotokollRind.durchfuehrung_bis" />
                <mat-datepicker-toggle matSuffix [for]="durchfuehrungBis"></mat-datepicker-toggle>
                <mat-datepicker #durchfuehrungBis></mat-datepicker>
            </mat-form-field>
        </div>

        <div id="naechster-bestandsbesuch-bis">
            <h3>N&auml;chster Bestandsbesuch bis</h3>
            <mat-form-field style="width: 25%" class="no-padding-margin-mat-form-field">
                <input matInput [matDatepicker]="naechsterBesuchBis"
                    [(ngModel)]="tierwohlprotokollRind.naechster_bestandsbesuch" />
                <mat-datepicker-toggle matSuffix [for]="naechsterBesuchBis"></mat-datepicker-toggle>
                <mat-datepicker #naechsterBesuchBis></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-flat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</div>