import { animate, state, style, transition, trigger, } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpParams } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { API_BASE_URL_SERVICE } from "../../../../projects/kiene-core/src/lib/kiene-core.config";
import { FilterItem } from '../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import { KieneFileService } from '../../../../projects/kiene-core/src/lib/services/files/kiene-file.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { AppService } from '../../app.service';
import { FileUploadService } from '../../dialogs/file-upload-dialog/file-upload.service';
import { JsonDialogComponent } from '../../dialogs/json-dialog/json-dialog.component';
import { MessageService } from '../../services/message.service';
import { EntityViewerService } from '../entity-viewer/entity-viewer.service';
import { ColumnFilterDialogComponent } from "./column-filter-dialog/column-filter-dialog.component";
import { KieneTableService } from './kiene-table.service';
import {
    AcceptDenyTableColumn,
    ButtonTableColumn,
    ConditionTableColumn,
    FunctionTableColumn,
    IfElseTableColumn,
    InputTableColumn,
    LinkTableColumn,
    StatusTableColumn,
    TableColumn,
} from './table-column';
import { SpalteKonfiguration, TableDescriptor } from './table-descriptor';
import { TableOption, TableOptionEvent } from './table-option';
import { environment } from '../../../environments/environment';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';

@Component({
    selector: 'kiene-kiene-table',
    templateUrl: './kiene-table.component.html',
    styleUrls: ['./kiene-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ])
    ],
    host: { '(window:keydown)': 'checkForKeyShortcut($event)' },
})
export class KieneTableComponent
    implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    isLoading = false;
    initialSortDirection: SortDirection = 'asc';

    @ViewChild('searchInput') searchInput: MatInput;

    @Input() tableDescriptor: TableDescriptor = new TableDescriptor();
    @Input() kunde_id: number;

    tableDataSource = new MatTableDataSource();

    totalCount = 0;
    checkedFilterCount: number = null;

    onInitComplete = false;

    showColumnFilter = true;
    displayedColumns: string[] = [];
    spaltenKonfigurationen: SpalteKonfiguration[] = [];

    externalParams: HttpParams;

    reloadSubscription: Subscription;
    currentClientSubscription: Subscription;
    loadDataSubscription: Subscription;
    loadDataTotalSubscription: Subscription;
    clearSelectionSubscription: Subscription;

    expandedIndexes: number[] = [];

    selectedIndex = 0;

    allowMultipleSelection = true;
    selection: SelectionModel<any>;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    searchCtrl = new UntypedFormControl();

    @Output('optionClicked')
    optionClicked = new EventEmitter<TableOptionEvent>();

    @Output('filterChanged') filterChanged = new EventEmitter<FilterItem[]>();

    isMobileScreen = false;

    constructor(
        private kieneTableService: KieneTableService,
        private api: KieneBackendApiService,
        private localStorageService: LocalStorageService,
        private router: Router,
        private route: ActivatedRoute,
        private fileUploadService: FileUploadService,
        private appService: AppService,
        public dialog: MatDialog,
        private entityViewerService: EntityViewerService,
        private breakpointObserver: BreakpointObserver,
        private messageService: MessageService,
        private fileService: KieneFileService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl
    ) {
        this.breakpointObserver.observe([
            Breakpoints.HandsetPortrait,
            Breakpoints.HandsetLandscape,
        ]).subscribe({
            next: (result) => {
                if (result.matches) {
                    this.isMobileScreen = true;
                } else {
                    this.isMobileScreen = false;
                }
            },
        });
    }

    ladeEntityInEntityViewer(row) {
        this.entityViewerService.setEntity(row);
    }

    ngOnInit() {
        this.loadVisibleColumns(true);

        this.reloadSubscription = this.kieneTableService.watchReload().subscribe((response) => {
            this.reload(response);
        });

        this.kieneTableService.watchColumns().subscribe({
            next: () => {
                this.initSpaltenKonfigurationen(false);
            }
        });

        this.clearSelectionSubscription = this.kieneTableService.watchClearSelection().subscribe({
            next: () => {
                this.clearSelection();
            }
        });

        this.currentClientSubscription = this.localStorageService.watchCurrentClient().subscribe((client) => {
            this.expandedIndexes = [];
            this.reload('watch current client');
        });

        this.tableDescriptor.defaultPageSize =
            this.localStorageService.getCurrentUser().elemente_pro_seite;

        if (
            this.tableDescriptor.showSearchBar &&
            !this.tableDescriptor.clearSearch
        ) {
            let searchFilterValue =
                this.localStorageService.getSearchFilterValue(
                    this.tableDescriptor.headline
                );
            searchFilterValue = searchFilterValue ? searchFilterValue : '';

            this.searchCtrl.setValue(
                searchFilterValue, { emitEvent: false }
            );
            this.kieneTableService.sendSearchTxt(searchFilterValue);
        }
        this.initialSortDirection = <SortDirection>(
            this.tableDescriptor.initialSortDirection
        );

        this.sort.direction = <SortDirection>(
            this.tableDescriptor.initialSortDirection
        );

        if (!this.isMobileScreen) {
            this.paginator.pageSize =
                this.localStorageService.getCurrentUser().elemente_pro_seite;
        }

        this.selection = new SelectionModel(
            this.allowMultipleSelection
        );

        this.onInitComplete = true;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.onInitComplete) {
            this.loadDataNew('on changes', false);
        }
    }

    ngAfterViewInit() {
        this.searchCtrl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => {
                // console.debug('Input: ' + this.searchInput.nativeElement.value);
                this.paginator.pageIndex = 0;
                const search = this.searchCtrl.value ? this.searchCtrl.value : '';
                if (this.tableDescriptor.showSearchBar) {
                    this.localStorageService.setSearchFilterValue(
                        this.tableDescriptor.headline,
                        search
                    );
                }
                this.kieneTableService.sendSearchTxt(search);
                this.loadDataNew('after view init', true);
            })
        ).subscribe();

        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

        merge(this.sort.sortChange, this.paginator.page).subscribe((x) =>
            this.loadDataNew('sort or pagination', true)
        );
    }

    ngOnDestroy() {
        this.reloadSubscription?.unsubscribe();
        this.currentClientSubscription?.unsubscribe();
        this.clearSelectionSubscription?.unsubscribe();
        this.loadDataSubscription?.unsubscribe();
        this.loadDataTotalSubscription?.unsubscribe();
    }

    masterSelectionToggle(event: MatCheckboxChange) {
        if (event.checked) {
            this.selection?.clear();
            const oldPageIndex = this.paginator.pageIndex;
            const oldPageSize = this.paginator.pageSize;
            this.paginator.pageIndex = 0;
            this.paginator.pageSize = this.totalCount;
            const sub = this.loadDataNew(
                'masterSelectionToggle',
                true,
                true
            ).subscribe({
                next: (result) => {
                    result.forEach((elem) => this.selection?.select(elem));
                    this.kieneTableService.setSelectedRows(this.selection?.selected);
                    sub.unsubscribe();
                    this.paginator.pageIndex = oldPageIndex;
                    this.paginator.pageSize = oldPageSize;
                },
            });
        } else {
            this.selection?.clear();
            this.kieneTableService.setSelectedRows(this.selection?.selected);
        }

    }

    isAllSelected() {
        return this.selection?.selected?.length >= this.totalCount;
    }

    isRowSelected(row: any) {
        const index = this.selection?.selected?.findIndex(
            (elem) =>
                elem[this.tableDescriptor.nameOfIdField] ===
                row[this.tableDescriptor.nameOfIdField]
        );
        if (index > -1) {
            return true;
        }
        return false;
    }

    changeSelection(event: MatCheckboxChange, row: any) {
        if (event.checked) {
            this.selection?.selected?.push(row);
        } else {
            const index = this.selection?.selected?.findIndex(
                (elem) =>
                    elem[this.tableDescriptor.nameOfIdField] ===
                    row[this.tableDescriptor.nameOfIdField]
            );
            this.selection?.selected?.splice(index, 1);
        }
        this.kieneTableService.setSelectedRows(this.selection?.selected);
    }

    checkForKeyShortcut(event: KeyboardEvent) {
        if (event.code === 'KeyN' && !this.isKeyEventFromInput(event)) {
            if (
                this.tableDescriptor.standardCreate &&
                this.tableDescriptor.createAllowed
            ) {
                this.createNew();
            } else if (
                !this.tableDescriptor.standardCreate &&
                this.tableDescriptor.createAllowed
            ) {
                this.clickedOption('add', null);
            }
        }
        if (event.code === 'KeyF' && !this.isKeyEventFromInput(event)) {
            setTimeout(() => {
                this.searchInput?.focus();
            }, 10);
        }
        if (
            event.code === 'KeyB' &&
            !event.altKey &&
            !this.isKeyEventFromInput(event)
        ) {
            if (
                this.localStorageService.currentUserHasPermission(
                    this.tableDescriptor.permissionUpdate
                )
            ) {
                const to = new TableOptionEvent();
                to.name = 'edit';
                to.value = this.tableDataSource.data[this.selectedIndex];
                this.optionClicked.emit(to);
            }
        }
        if (event.code === 'Delete' && !this.isKeyEventFromInput(event)) {
            if (
                this.localStorageService.currentUserHasPermission(
                    this.tableDescriptor.permissionDelete
                )
            ) {
                const to = new TableOptionEvent();
                to.name = 'delete';
                to.value = this.tableDataSource.data[this.selectedIndex];
                this.optionClicked.emit(to);
            }
        }
        if (event.code === 'ArrowUp' && !this.isKeyEventFromInput(event)) {
            if (this.selectedIndex > 0) {
                this.selectedIndex--;
            }
        }
        if (event.code === 'ArrowDown' && !this.isKeyEventFromInput(event)) {
            if (this.selectedIndex < this.tableDataSource.data.length - 1) {
                this.selectedIndex++;
            }
        }
    }

    getColumns() {
        const columns = this.tableDescriptor.columns;
        const newColumns: TableColumn[] = [];

        for (const c of columns) {
            if (
                c.name !== 'options' &&
                c.name !== 'detailsButton' &&
                c.isActive === true
            ) {
                newColumns.push(c);
            }
        }
        return newColumns;
    }

    getAllColumnsLength() {
        let len = this.tableDescriptor.columns.length;
        if (this.tableDescriptor.options.length > 0) {
            len++;
        }
        if (this.tableDescriptor.expandable) {
            len++;
        }
        // entity viewer button
        if (this.localStorageService?.isCurrentUserPhitoUser()) {
            len++;
        }
        if (this.tableDescriptor.selectable) {
            len++;
        }

        return len;
    }

    reload(sender: string) {
        if (sender && !environment?.production) {
            console.log('reload data: %s', sender);
        }
        this.loadDataNew(sender, true);
    }

    download_excel() {
        this.fileService.downloadExcel(
            this.getUrl(),
            this.getParams(false),
            { columnsCommaSeparated: this.prepareColumnsForDownloads() }
        );
    }

    download_csv() {
        // this.fileService.downloadCSV(
        //     this.getUrl(),
        //     this.getParams(false),
        //     {columnsCommaSeparated: this.tableDescriptor.getColumnsForDownload()}
        // );
        this.fileService.downloadCSV(
            this.getUrl(),
            this.getParams(false),
            { columnsCommaSeparated: this.prepareColumnsForDownloads() }
        );
    }

    loadDataNew(
        sender: string,
        reload?: boolean,
        onlyInBackground?: boolean
    ): Observable<any[]> {
        const dataLoadFinishedSubject = new Subject<any[]>();

        if (this.getUrl().startsWith('undefined')) {
            return;
        }

        if (this.tableDescriptor.loadOnlyOnReload) {
            if (!reload) {
                return;
            }
        }

        if (sender && !environment?.production) {
            console.log('Request sender is %s', sender);
        }

        if (this.tableDescriptor.waitToLoad) {
            if (!environment?.production) {
                console.log('waittoload');
            }
            return;
        }

        this.appService.setDataLoading(true);
        this.isLoading = true;

        const params = this.getParams(true);
        this.kieneTableService.setParamsChanged(this.tableDescriptor.uniqueIdentifier, params);

        const url = this.getUrl();

        this.loadDataSubscription?.unsubscribe();
        this.loadDataSubscription = this.api!.get(
            url,
            params
        ).subscribe({
            next: (response) => {
                this.appService.setDataLoading(false);
                this.kieneTableService.setLastTableUrlAndParams(url, params);
                this.isLoading = false;
                this.api.dataLoadedCompleted();
                if (!onlyInBackground) {
                    this.totalCount = response.count;
                    this.api.setCurrentData(response);
                    this.tableDataSource.data = response.records;
                }
                dataLoadFinishedSubject.next(response.records);
            },
            error: (error) => {
                this.appService.setDataLoading(false);
                this.isLoading = false;
                this.messageService.errorMessage(
                    'Daten konnten nicht geladen werden!',
                    error
                );
                dataLoadFinishedSubject.next(undefined);
            },
        });

        if (this.tableDescriptor.totalRow === true) {
            this.loadDataTotalSubscription?.unsubscribe();
            this.loadDataTotalSubscription = this.api.get(this.tableDescriptor.totalRowApiUrl, this.getParams(false)).subscribe({
                next: (response: any) => {
                    this.kieneTableService.setCurrentTotalData(response);
                },
                error: (err) => {
                    this.messageService.errorMessage('Summenwerte konnten nicht geladen werden!', err);
                }
            });
        }

        return dataLoadFinishedSubject;
    }

    getSortDirection() {
        if (this.sort?.direction != null) {
            return this.sort.direction;
        }
        return this.tableDescriptor.initialSortDirection;
    }

    updatedFilterItems(filterItems: FilterItem[]) {
        if (filterItems) {
            this.tableDescriptor.filterItems = filterItems;
            this.loadDataNew('updatedFilterItems', true);
        }
    }

    hasPermissionForFilter(fi: FilterItem): boolean {
        if (fi.adminOnly) {
            if (!this.localStorageService.currentUserIsAdmin()) {
                return false;
            }
        }
        if (fi.permissionId) {
            return this.localStorageService.currentUserHasPermission(
                fi.permissionId
            );
        }
        return true;
    }

    getTableFilterParams(params: HttpParams): HttpParams {
        for (const fi of this.tableDescriptor.filterItems) {
            if (fi.checked && this.hasPermissionForFilter(fi)) {
                if (fi.value === null) {
                    params = params.append(fi.name + '[]', 'null');
                } else if (fi.value instanceof Array) {
                    for (let v of fi.value) {
                        params = params.append(fi.name + '[]', v);
                    }
                } else {
                    params = params.append(fi.name + '[]', fi.value);
                }

            }
        }
        return params;
    }

    createNew() {
        this.router.navigate(['neu'], { relativeTo: this.route });
    }

    slideToggled(column: any, row: any, event: MatSlideToggleChange) {
        const option = new TableOptionEvent();
        row[column.name] = event.checked ? 1 : 0;
        option.name = column.eventName;
        option.value = row;
        this.optionClicked.emit(option);
    }

    setSelectedRow(row: any, index: number) {
        this.selectedIndex = index;
    }

    showDetails(row: any) {
        if (this.tableDescriptor.showDetailsAsOption) {
            const option = new TableOptionEvent();
            option.name = 'details';
            option.value = row;
            this.optionClicked.emit(option);
            return;
        }

        const id = row[this.tableDescriptor.nameOfIdField];
        const recposition = row.recposition;

        if (!this.tableDescriptor.showDetails || !id) {
            if (!id && !environment?.production) {
                console.log('the id value of that row is null or undefined!');
            }
            return;
        }

        const routeArray: any[] = [];
        if (this.tableDescriptor.detailsRoute) {
            routeArray.push(this.tableDescriptor.detailsRoute);
        }
        routeArray.push(id);
        this.router.navigate(routeArray, {
            relativeTo: this.route,
            queryParams: {
                recposition: recposition,
                orderby: this.getOrderBy(),
                search: this.searchCtrl.value,
            },
        });
    }

    getOrderBy() {
        if (this.sort?.active && this.displayedColumns.indexOf(this.sort.active) < 0) {
            this.sort.active = this.tableDescriptor.initialSortColumn;
        }

        let o = '';
        for (const sc of this.getSortColumns()) {
            if (o != '') {
                o += ',';
            }
            o += sc + ' ' + this.getSortDirection();
        }
        return o;
    }

    hasAtLeastOneOptionPermission(): boolean {
        for (const o of this.tableDescriptor.options) {
            if (
                this.localStorageService.currentUserHasPermission(
                    o.permissionId
                )
            ) {
                return true;
            }
        }
        return false;
    }

    clickedPdf(row: any) {
        const event: TableOptionEvent = new TableOptionEvent();
        event.name = 'showPdf';
        event.value = row;
        this.optionClicked.emit(event);
    }

    clickedTableOption(option: TableOption, row: any) {
        if (option?.void) {
            option.void(row);
        } else {
            this.clickedOption(option?.name, row);
        }
    }

    clickedOption(name: string, row: any) {
        const event: TableOptionEvent = new TableOptionEvent();
        event.name = name;
        event.value = row;
        this.optionClicked.emit(event);
    }

    clickedSelectionToolbarButton(name: string) {
        const event: TableOptionEvent = new TableOptionEvent();
        event.name = name;
        event.selectionToolbarButton = true;
        event.values = this.selection?.selected;
        this.optionClicked.emit(event);
    }

    getSelectedRows(): any[] {
        const rows = this.selection?.selected;
        return rows ? rows : [];
    }

    filesDropped(files: File[], row: any) {
        const event = new TableOptionEvent();
        event.name = 'filesDropped';
        event.value = { files: files, row: row };
        this.optionClicked.emit(event);
    }

    getPermissionIds(): number[] {
        const ids = [];
        if (this.tableDescriptor.options.length > 0) {
            for (const o of this.tableDescriptor.options) {
                ids.push(o.permissionId);
            }
        }
        return ids;
    }

    getStatusBackgroundColor(column: TableColumn, row: any) {
        const c: StatusTableColumn = <StatusTableColumn>column;
        const status = row['status_id'];
        const colorMap = c.colorMap;
        let color = '#FFFFFF';

        if (colorMap) {
            colorMap.forEach((v: string, k: number) => {
                if (status === k) {
                    color = v;
                }
            });
        }

        return color;
    }

    getStatusFrontColor(column: StatusTableColumn, row: any) {
        const status = row['status_id'];
        const colorMap = column.colorMap;
        let color = '#000';

        if (colorMap) {
            colorMap.forEach((v: string, k: number) => {
                if (status === k) {
                    color = '#FFF';
                }
            });
        }
        return color;
    }

    uploadFiles(files: File[], row: any) {
        this.fileUploadService.uploadFilesToServer(
            files,
            this.tableDescriptor.nameOfIdField,
            row[this.tableDescriptor.nameOfIdField],
            this.tableDescriptor.fileUploadApi
        );
    }

    getIconName(column: TableColumn, row: any) {
        const c: ConditionTableColumn = <ConditionTableColumn>column;
        return c.getIcon(row);
    }

    getIconColor(column: TableColumn, row: any) {
        const c: ConditionTableColumn = <ConditionTableColumn>column;
        return c.getColor(row);
    }

    getTooltip(column: TableColumn, row: any) {
        const c: ConditionTableColumn = <ConditionTableColumn>column;
        return c.getTooltip(row);
    }

    public getSumValue(field: string) {
        let v = this.getValue(this.kieneTableService.getCurrentTotalData(), field);
        if (v === null || v === undefined) {
            return null;
        }
        return '(' + v + ')';
    }

    public getValue(object: any, field: string): any {
        if (field && object) {
            if (field.includes('.')) {
                const fields: string[] = field.split('.');
                let obj = object;
                for (const f of fields) {
                    if (obj !== null && obj !== undefined) {
                        obj = obj[f];
                    }
                }
                return obj;
            } else {
                return object[field];
            }
        }
        return null;
    }

    public getColor(object: any) {
        if (this.tableDescriptor.disableConditions.length <= 0) {
            return 'black';
        }
        let complied = true;
        for (const c of this.tableDescriptor.disableConditions) {
            complied = complied && c.check(object);
        }
        if (complied) {
            return 'lightgray';
        }
        return 'black';
    }

    expand(row: any) {
        const recpos: number = row?.recposition;
        if (recpos >= 0) {
            if (this.isExpanded(row)) {
                const i = this.expandedIndexes.findIndex((ei) => ei === recpos);
                if (i >= 0) {
                    this.expandedIndexes.splice(i, 1);
                }
            } else {
                this.expandedIndexes.push(recpos);
            }
        }
    }

    isExpanded(row: any) {
        if (row.recposition >= 0) {
            for (const i of this.expandedIndexes) {
                if (i === row.recposition) {
                    return true;
                }
            }
        }
        return false;
    }

    executeCallback(row: any, column: TableColumn) {
        const c: FunctionTableColumn = <FunctionTableColumn>column;
        const value = this.getValue(row, c.name);
        return c.callback(value);
    }

    ifElseValue(row: any, column: TableColumn) {
        const c: IfElseTableColumn = <IfElseTableColumn>column;
        const v = this.getValue(row, c.conditionAttribute);
        if (v) {
            return this.getValue(row, c.ifValue);
        } else {
            return this.getValue(row, c.elseValue);
        }
    }

    formatJson(json: any): string {
        return JSON.stringify(json, null, 2);
    }

    openJsonDialog(json: any) {
        this.dialog.open(JsonDialogComponent, {
            data: {
                json: json,
            },
        });
    }

    public parseStringForUrl(text: string) {
        if (!text) {
            return null;
        }
        const urlRegex =
            '(https?://(www.)?)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)';
        const urltext = text.replace(
            /(https?:\/\/(www\.)?)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&\/\/=]*)/g,
            '<a class="kiene-inline-link" href="$&">$&</a>'
        );
        return urltext;
    }

    getDateFromRawLocalDateTime(row: any, columnName: string) {
        const raw = this.getValue(row, columnName);
        if (!raw) {
            return null;
        }
        if (typeof raw === 'string') {
            return new Date(raw);
        } else {
            const date = new Date(raw.year, 0);
            return new Date(date.setDate(raw.dayOfYear));
        }
    }

    clickedOnExpandedTable(row: any) {
        if (!this.tableDescriptor.showDetailsExpandable) {
            return;
        }

        if (this.tableDescriptor.showDetailsAsOptionExpandable) {
            this.optionClicked.emit(
                new TableOptionEvent('details_expandable', row)
            );
            return;
        }

        this.router.navigate([
            this.tableDescriptor.route,
            row[this.tableDescriptor.nameOfIdField],
        ]).then();
    }

    showDownloads() {
        return (
            this.tableDescriptor?.showDownloads ||
            this.localStorageService?.currentUserIsAdmin()
        );
    }

    emitButtonEvent(row, c: TableColumn) {
        const tc = <ButtonTableColumn>c;
        this.clickedOption(tc.eventName, row);
        this.kieneTableService.setButtonClicked(tc.eventName);
    }

    acceptButtonEvent(row, c: TableColumn) {
        const tc = <AcceptDenyTableColumn>c;
        tc.callback(row, tc.acceptStatus);
    }

    denyButtonEvent(row, c: TableColumn) {
        const tc = <AcceptDenyTableColumn>c;
        tc.callback(row, tc.denyStatus);
    }

    saveInput(row, c: TableColumn) {
        const tc = <InputTableColumn>c;
        this.api.updateGeneric(tc.api_url + 'update.php', row).subscribe({
            next: (data) =>
                this.messageService.infoMessage('Zusatztext gespeichert!'),
            error: (err) =>
                this.messageService.errorMessage(
                    'Fehler Zusatztext speichern: ',
                    err
                ),
        });
    }

    checkboxChanged($event: MatCheckboxChange, c: TableColumn, row: any) {
        this.clickedOption(c.eventName, row);
    }

    hasPermission(permissionId: number) {
        return this.localStorageService.hasPermission(permissionId);
    }

    clearSelection() {
        this.selection?.clear();
        this.kieneTableService.setSelectedRows(this.selection?.selected);
    }

    setCheckedFilterCount(count: number) {
        if (count > 0) {
            this.checkedFilterCount = count;
        } else {
            this.checkedFilterCount = null;
        }
    }

    createQueryParams(c: TableColumn, row) {
        const ltc = <LinkTableColumn>c
        const p = {};
        if (ltc.params) {
            for (const k of ltc.params) {
                p[k] = this.getValue(row, k);
            }
            return p;
        }
        return undefined;
    }

    public openFilterDialog(): void {
        this.dialog.open(ColumnFilterDialogComponent, {
            data: { cols: this.spaltenKonfigurationen, identifier: this.tableDescriptor.uniqueIdentifier }
        }).afterClosed().subscribe({
            next: value => {
                if (value) {
                    this.initSpaltenKonfigurationen(false, value);
                }
            }
        })
    }

    private getSortColumns(): string[] {
        if (this.sort?.active != null) {
            for (const tc of this.tableDescriptor.columns) {
                if (this.sort.active === tc.name && tc.isActive) {
                    if (tc.sortFields?.length > 0) {
                        return tc.sortFields;
                    }
                }
            }
            return [this.sort.active];
        }
        return [this.tableDescriptor.initialSortColumn];
    }

    private loadVisibleColumns(loadData: boolean): void {
        if (this.tableDescriptor.uniqueIdentifier) {
            const url = this.apiBaseUrl + 'tabelle_spalte/read.php?tabelle=' + this.tableDescriptor.uniqueIdentifier;
            this.api.get(url).subscribe({
                next: value => {
                    this.initSpaltenKonfigurationen(true, value.records);
                }, error: err => {
                    this.initSpaltenKonfigurationen(true);
                    this.messageService.errorMessageSimple(err);
                }
            })
        } else {
            this.initSpaltenKonfigurationen(true);
        }
    }

    private initSpaltenKonfigurationen(loadData: boolean, spalteKonfigAusDb?: SpalteKonfiguration[]) {
        const konfigs = [];
        for (const col of this.tableDescriptor?.columns) {
            const sk = new SpalteKonfiguration();
            sk.spalte = col.name;
            sk.titel = col.title;
            sk.aktiv = col.isActive;
            sk.sichtbar = (col.isActive === true) ? 1 : 0;

            if (spalteKonfigAusDb) {
                const ausDb = spalteKonfigAusDb.find(s => s.spalte === col.name);
                if (ausDb?.sichtbar === 0 && sk.sichtbar === 1 && sk.aktiv) {
                    sk.sichtbar = 0;
                }
            }

            konfigs.push(sk);
        }

        this.spaltenKonfigurationen = konfigs;
        this.initialDisplayedColumns('loadVisibleColumns #2', loadData);
    }

    /**
     * nur von initSpaltenKonfigurationen aufrufen!
     * @param sender
     * @param loadData
     */
    private initialDisplayedColumns(sender: string, loadData: boolean): void {
        if (!environment?.production) {
            console.log('call initialDisplayedColumns from: ' + sender);
        }
        const cols = [];

        if (this.localStorageService?.isCurrentUserPhitoUser()) {
            cols.push('entityViewerButton');
        }

        if (this.tableDescriptor?.draggable) {
            cols.push('dragHandle');
        }

        if (this.tableDescriptor?.selectable) {
            cols.push('select');
        }

        if (this.tableDescriptor?.expandable) {
            cols.push('detailsButton');
        }

        for (const sk of this.spaltenKonfigurationen) {
            if (sk.sichtbar > 0 && sk.aktiv) {
                cols.push(sk.spalte);
            }
        }

        if (this.tableDescriptor?.options?.length > 0) {
            cols.push('options');
        }

        this.displayedColumns = cols;

        if (loadData) {
            this.loadDataNew('init', false);
        }
    }


    private isKeyEventFromInput(event: KeyboardEvent): boolean {
        if (event.target instanceof Node) {
            switch (event.target.nodeName) {
                case 'INPUT':
                case 'SELECT':
                case 'TEXTAREA':
                    return true;
                default:
                    return false;
            }
        }
        return false;
    }

    private getUrl() {
        if (
            this.tableDescriptor.alternativeApiUrl &&
            !this.tableDescriptor.apiUrl
        ) {
            return this.tableDescriptor.alternativeApiUrl;
        }

        return this.tableDescriptor.apiUrl + 'read.php';
    }

    private getParams(withLimitAndOffset: boolean): HttpParams {
        let params: HttpParams = new HttpParams();
        if (withLimitAndOffset) {
            const offset =
                this.paginator.pageIndex != null &&
                    this.paginator.pageSize != null
                    ? this.paginator.pageIndex * this.paginator.pageSize
                    : 0;
            const limit =
                this.paginator.pageSize != null
                    ? this.paginator.pageSize
                    : this.tableDescriptor.defaultPageSize;
            params = params.set('limit', limit.toString());
            params = params.set('offset', offset.toString());
        }

        params = params.set('orderby', this.getOrderBy());
        params = params.set(
            'search',
            this.searchCtrl.value ? this.searchCtrl.value : ''
        );

        let belegeOderFinanzen = false;

        const currentUrl = this.router.url;
        if (
            currentUrl &&
            (currentUrl.startsWith('/belege/') ||
                currentUrl.startsWith('/finanzen/'))
        ) {
            belegeOderFinanzen = true;
        }
        let ausklappen = false;

        if (belegeOderFinanzen || this.tableDescriptor.sendCurrentClient) {
            if (this.kunde_id) {
                params = params.set('kunde_id[]', this.kunde_id);
            } else if (this.localStorageService.getCurrentClient()?.kunde_id) {
                params = params.set(
                    'kunde_id[]',
                    this.localStorageService.getCurrentClient().kunde_id.toString()
                );
                ausklappen = true;
            }

        }

        if (currentUrl && currentUrl.startsWith('/stammdaten/betriebe')) {
            ausklappen = true;
        }

        if (ausklappen &&
            this.tableDescriptor.openFirstRows > 0 &&
            this.expandedIndexes.length === 0
        ) {
            for (
                let i = 0;
                i < this.tableDescriptor.openFirstRows;
                i++
            ) {
                this.expandedIndexes.push(i);
            }
        }

        params = this.getTableFilterParams(params);

        if (this.tableDescriptor.params) {
            const keys: string[] = this.tableDescriptor.params.keys();
            for (const k of keys) {
                const values: string[] = this.tableDescriptor.params.getAll(k);
                for (const v of values) {
                    params = params.append(k, v);
                }
            }
        }

        if (this.tableDescriptor.filterParams) {
            const keys: string[] = this.tableDescriptor.filterParams.keys();
            for (const k of keys) {
                const values: string[] =
                    this.tableDescriptor.filterParams.getAll(k);
                for (const v of values) {
                    params = params.append(k, v);
                }
            }
        }

        if (this.externalParams) {
            const keys = this.externalParams.keys();
            for (const key of keys) {
                const value = this.externalParams.get(key);
                params = params.delete(key);
                params = params.set(key, value);
            }
        }

        return params;
    }

    private prepareColumnsForDownloads() {
        let columns = '';
        for (const col of this.spaltenKonfigurationen) {
            if (col.aktiv && col.sichtbar) {
                columns += col.spalte + '::' + col.titel + ';';
            }
        }
        if (columns.length > 0) {
            columns = columns.substr(0, columns.length - 1);
        }
        return columns;
    }
}
