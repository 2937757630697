import {Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {GruppeTier} from '../classes/gruppe-tier';
import {GruppenService} from '../../gruppen/gruppen.service';
import {MessageService} from '../../../services/message-service/message.service';
import {Subscription} from 'rxjs';
import {Tierbemerkung} from '../classes/tierbemerkung';
import {
    KieneAutocompleteComponent
} from '../../kiene-autocomplete/kiene-autocomplete.component';
import {API_BASE_URL_SERVICE} from '../../../kiene-core.config';
import {GruppeTierService} from '../gruppe-tier.service';

@Component({
  selector: 'kiene-gruppe-tier-bemerkung',
  templateUrl: './gruppe-tier-bemerkung.component.html',
  styleUrls: ['./gruppe-tier-bemerkung.component.scss']
})
export class GruppeTierBemerkungComponent implements OnInit, OnDestroy {

    @Input('dialog') dialog = false;
    @Input('tier') tier: GruppeTier;

    @Output('saved')
    saved: EventEmitter<boolean> = new EventEmitter<boolean>();
    isLoading = true;
    isSaving = false;
    ueberschrift = 'Bemerkungen hinzufügen';
    gruppeTierSubscription: Subscription;
    @ViewChild('bemerkungenAutocomplete') bemerkungenAutocomplete: KieneAutocompleteComponent;

    bemerkungenAutocompleteUrl = `${this.apiBaseUrl}gruppe_tier_bemerkung/autocomplete.php`;



    constructor(@Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string, private gruppenService: GruppenService,
                private messageService: MessageService, private gruppeTierService: GruppeTierService) {}

    ngOnInit(): void {
      this.loadTier();
    }

    ngOnDestroy(): void {
        this.gruppeTierSubscription?.unsubscribe();
    }

    bemerkungenHinzufuegen() {
        this.gruppeTierService.update(this.tier).subscribe({
            next: (resp) => {
                this.tier = resp;
            },
            error: (err) => this.messageService.errorMessage('Fehler beim Speichern der Bemerkungen!', err)
        });
        this.saved.emit(true);
    }

    loadTier() {
        this.gruppeTierSubscription?.unsubscribe();
        this.gruppeTierSubscription = this.gruppenService.getGruppeTier(this.tier?.gt_id).subscribe({
            next: (tier) => {
                this.tier = tier;
                if (!this.tier.bemerkungen) {
                    this.tier.bemerkungen = [];
                }

                    this.isLoading = false;
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Laden des Tiers', err);
                this.isLoading = false;
            }
        });
    }

    addBemerkung(bemerkung: Tierbemerkung | string): void {
        if (bemerkung) {
            let toAdd: Tierbemerkung;
            if (typeof bemerkung === 'string') {
                toAdd = new Tierbemerkung({bemerkung: bemerkung, gt_id: this.tier.gt_id});
            } else {
                toAdd = bemerkung;
                toAdd.gt_id = this.tier.gt_id;
            }
            this.createOrUpdateBemerkung(toAdd);
        }
    }

    add() {
        this.bemerkungenAutocomplete.emitCurrentValue();
    }

    deleteBemerkung(bemerkung: Tierbemerkung) {
        this.gruppeTierService.deleteBemerkung(bemerkung).subscribe({
            next: () => {
                this.loadTier();
                this.bemerkungenAutocomplete.clear();
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Löschen der Bemerkung!', err);
            }
        });
    }

    private createOrUpdateBemerkung(toAdd: Tierbemerkung) {
        this.gruppeTierService.createOrUpdateBemerkung(toAdd).subscribe({
            next: (resp) => this.tier.bemerkungen.push(resp),
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Erstellen der Bemerkungen!', err);
            }
        });
    }
}
