import {HttpParams} from '@angular/common/http';
import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {
    ArrayTableColumn,
    ConditionSetting,
    ConditionTableColumn,
    DateTableColumn,
    KieneBooleanTableColumn,
    NumberTableColumn,
    OhrmarkeTableColumn,
    TableColumn,
} from '../../components/kiene-table/table-column';
import {
    AddGruppeTierReklamationDialogComponent
} from '../add-gruppe-tier-reklamation-dialog/add-gruppe-tier-reklamation-dialog.component';
import {TiereAlsCSVUploadDialogComponent} from '../tiere-als-csvupload-dialog/tiere-als-csvupload-dialog.component';
import {MatMenuTrigger} from '@angular/material/menu';
import {GruppeTierTodMeldenComponent} from '../gruppe-tier-tod-melden/gruppe-tier-tod-melden.component';
import {TierEditierenDialogComponent} from '../tier-editieren-dialog/tier-editieren-dialog.component';
import {ZugangMeldenDialogComponent} from '../zugang-melden-dialog/zugang-melden-dialog.component';
import {GruppeInformationenDialogComponent} from '../gruppe-informationen-dialog/gruppe-informationen-dialog.component';
import {
    ConfirmDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';

import {Gruppe, GruppenStatistik,} from '../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {GruppeEinstallenDialogComponent} from './gruppe-einstallen-dialog/gruppe-einstallen-dialog.component';
import {GruppeEinstallenDialogInsert} from './gruppe-einstallen-dialog/gruppe-einstallen-dialog-insert';
import {GruppeTierService} from '../../../../projects/kiene-core/src/lib/components/gruppe-tier/gruppe-tier.service';
import {GruppeTier} from '../../../../projects/kiene-core/src/lib/components/gruppe-tier/classes/gruppe-tier';
import {Subscription} from 'rxjs';
import {GruppenVerlauf} from '../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppen-verlauf';
import {GruppenService} from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import {KieneTableComponent} from '../../components/kiene-table/kiene-table.component';
import {
    EinstallenMeldenDialogInput,
    EinstallenMeldenDialogResult,
} from '../../../../projects/kiene-core/src/lib/components/gruppen/classes/einstallen-melden';
import {
    EinstallenMeldenDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppe-einstallen/einstallen-melden-dialog/einstallen-melden-dialog.component';
import {
    TierFilterSettings
} from '../../../../projects/kiene-core/src/lib/components/gruppe-tier/filter/gruppe-tier-filter-dialog/classes/tier-filter-settings';
import {
    TierSortierFilter
} from '../../../../projects/kiene-core/src/lib/components/gruppe-tier/classes/tier-sortier-filter';
import {
    GruppeTierDetailsDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/gruppe-tier/gruppe-tier-details-dialog/gruppe-tier-details-dialog.component';
import {
    GruppeTierPassScanVerwaltungDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/gruppe-tier/gruppe-tier-pass-scan-verwaltung-dialog/gruppe-tier-pass-scan-verwaltung-dialog.component';
import {
    GruppeTierBemerkungDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/gruppe-tier/gruppe-tier-bemerkung-dialog/gruppe-tier-bemerkung-dialog.component';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {
    GruppeReklamationPdfHaendlerAuswahlDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppe-reklamation-pdf-haendler-auswahl-dialog/gruppe-reklamation-pdf-haendler-auswahl-dialog.component';
import {
    GruppeReklamationPdfHaendlerAuswahlDialogOutput
} from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppe-reklamation-pdf-haendler-auswahl-dialog/classes/gruppe-reklamation-pdf-haendler-auswahl-dialog-output';
import {
    EnterValueDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-dialog.component';
import {
    EnterValueInput,
    EnterValueResponse,
} from '../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-response';
import {AusstallenService} from '../../../../projects/kiene-core/src/lib/components/ausstallen/ausstallen.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {
    GruppeAbgangDownloadDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppe-abgang-download-dialog/gruppe-abgang-download-dialog.component';
import {SelectionToolbarButton, TableDescriptor, ToolbarButton,} from '../../components/kiene-table/table-descriptor';
import {FileDownloadService} from '../../services/file-download.service';
import {MessageService} from '../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import {Condition, Operator, TableOption, TableOptionEvent,} from '../../components/kiene-table/table-option';
import {
    GruppeStandortWechselnDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppe-standort-wechseln-dialog/gruppe-standort-wechseln-dialog.component';
import {
    GruppeStandortWechselnDialogResult
} from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppe-standort-wechseln-dialog/gruppe-standort-wechseln-dialog-result';
import {
    AbgangDownloadRequestPojo
} from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppe-abgang-download-dialog/gruppe-abgang-download-dialog';
import {
    GruppeTierFilterButtonComponent
} from '../../../../projects/kiene-core/src/lib/components/gruppe-tier/filter/gruppe-tier-filter-button/gruppe-tier-filter-button.component';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';

@Component({
    selector: 'kiene-gruppe-details',
    templateUrl: './gruppe-details.component.html',
    styleUrls: ['./gruppe-details.component.scss'],
})
export class GruppeDetailsComponent implements OnInit, OnDestroy {
    @ViewChild('menuTrigger') menu: MatMenuTrigger;

    @ViewChild('filterButton') filterButton: GruppeTierFilterButtonComponent;

    @ViewChild('tabGroup') tabGroup: MatTabGroup;

    gruppe: Gruppe;
    gruppeSubscription: Subscription;
    gruppeReloadSubscription: Subscription;

    tiere: GruppeTier[] = [];
    tableDescriptor = new TableDescriptor();

    verlaufIsLoading = true;

    gruppenAnzeigen = 9111;
    gruppenAnlegen = 9112;

    gruppenVerlauf: GruppenVerlauf[];
    gruppenVerlaufSubscription: Subscription;
    gruppenVerlaufReloadSubscription: Subscription;

    gruppenStatistik: GruppenStatistik;
    gruppenStatistikSubscription: Subscription;

    schlachtungColumn: TableColumn;

    @ViewChild('kieneTable')
    kieneTable: KieneTableComponent;

    selectedRows: GruppeTier[] = [];
    filterSettings: TierFilterSettings = new TierFilterSettings();

    isSGS = true;
    selectedIndex = 0;
    eingescanntePaesseVerwaltenDialogOpen = false;
    search = '';

    constructor(
        private route: ActivatedRoute,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private dialog: MatDialog,
        private fileDownloadService: FileDownloadService,
        private pdfViewerService: PdfViewerDesktopService,
        private messageService: MessageService,
        private gruppeTierService: GruppeTierService,
        private tableService: KieneTableService,
        private gruppenService: GruppenService,
        private ausstallenService: AusstallenService,
        private localStorageService: LocalStorageService
    ) {
        const gruppeId = Number(this.route.snapshot.paramMap.get('id'));
        this.gruppe = new Gruppe();
        this.gruppe.gruppe_id = gruppeId;

        this.ladeDaten();
        this.initTierTable();
    }

    ngOnInit(): void {
        this.isSGS = this.localStorageService.isCurrentUserSgs();

        this.tableService.watchSearchTxt().subscribe({
            next: (search: string) => {
                this.search = search;
            },
        });

        this.gruppeReloadSubscription = this.gruppenService
            .watchReloadGruppe()
            .subscribe({
                next: () => {
                    this.loadGruppe();
                },
            });

        this.gruppenVerlaufReloadSubscription = this.gruppenService
            .watchReloadVerlauf()
            .subscribe({
                next: () => {
                    this.loadGruppenVerlauf();
                },
            });
    }

    ladeDaten() {
        this.loadGruppe();
        this.loadGruppenVerlauf();
        this.loadGruppenStatistik();
        this.gruppenService.reloadStandorte();
    }

    ngOnDestroy() {
        this.gruppeSubscription?.unsubscribe();
        this.gruppenStatistikSubscription?.unsubscribe();
        this.gruppenVerlaufSubscription?.unsubscribe();
        this.gruppeReloadSubscription?.unsubscribe();
        this.gruppenVerlaufReloadSubscription?.unsubscribe();
    }

    getGruppeTierColumns(): TableColumn[] {
        const columns = [];

        columns.push(new OhrmarkeTableColumn('ohrmarke_notnull', 'Ohrmarke'));
        columns.push(
            new TableColumn('abteil', 'Abteil', null, null, null, null, [
                'abteil_string',
                'abteil_number',
            ])
        );
        columns.push(
            new KieneBooleanTableColumn('ohrmarke_gescannt', 'Ohrmarke')
        );

        const conditionSettingsPass = [
            new ConditionSetting(
                [new Condition('pdf_id_pass', Operator.EXISTIERT, undefined)],
                {
                    icon: 'scanner',
                    color: '#197E3E',
                    tooltip: 'Pass eingescannt mit Scanner',
                }
            ),
            new ConditionSetting(
                [new Condition('pass_gescannt', Operator.GROESSER, 0)],
                {
                    icon: 'barcode_reader',
                    color: '#197E3E',
                    tooltip: 'Pass gescannt mit Barcodeleser',
                }
            ),
        ];
        columns.push(
            new ConditionTableColumn(
                'pass_gescannt',
                'Pass',
                conditionSettingsPass,
                {
                    sortFields: ['pass_gescannt'],
                    defaultIcon: 'check_box_outline_blank',
                    defaultColor: '#197E3E',
                }
            )
        );

        const conditionSettingsGeschlecht = [
            new ConditionSetting(
                [new Condition('geschlecht_id', Operator.GLEICH, 1)],
                {
                    icon: 'male',
                    color: 'gray',
                    tooltip: 'Männlich',
                }
            ),
            new ConditionSetting(
                [new Condition('geschlecht_id', Operator.GLEICH, 2)],
                {
                    icon: 'female',
                    color: 'gray',
                    tooltip: 'Weiblich',
                }
            ),
        ];
        columns.push(
            new ConditionTableColumn(
                'geschlecht',
                'Geschl.',
                conditionSettingsGeschlecht,
                {
                    sortFields: ['geschlecht'],
                }
            )
        );

        columns.push(new TableColumn('rasse_kuerzel', 'Rasse'));
        columns.push(
            new DateTableColumn('geburtsdatum', 'Geburtsdatum', 'dd.MM.yyyy')
        );
        columns.push(
            new DateTableColumn(
                'einstalldatum_notnull',
                'Einstalldatum',
                'dd.MM.yyyy'
            )
        );
        columns.push(new KieneBooleanTableColumn('tierliste_id', 'Liste'));
        columns.push(
            new DateTableColumn(
                'abgangsdatum_notnull',
                'Abgangsdatum',
                'dd.MM.yyyy'
            )
        );
        columns.push(
            new DateTableColumn(
                'todesdatum_notnull',
                'Todesdatum',
                'dd.MM.yyyy'
            )
        );
        columns.push(new NumberTableColumn('alter_tage', 'Alter', '1.0', 'd'));
        columns.push(new TableColumn('wochen_einstallung', 'Wochen Einst.'));
        this.schlachtungColumn = new DateTableColumn(
            'schlachtdatum_kalb',
            'Spät. Schlachtung',
            'dd.MM.yyyy'
        );
        columns.push(this.schlachtungColumn);

        const conditionSettingsZugang = [
            new ConditionSetting(
                [new Condition('status_id_einstallen', Operator.GLEICH, 143)],
                {
                    icon: 'error',
                    color: '#bc4b51',
                    tooltipFieldnames: ['hit_fehler_einstallen'],
                }
            ),
            new ConditionSetting(
                [
                    new Condition(
                        'status_id_einstallen',
                        Operator.IN,
                        [141, 144]
                    ),
                ],
                {
                    icon: 'check_circle',
                    color: '#f2c52d',
                    tooltip: 'Zugang wird an HI-Tier gemeldet!',
                }
            ),
            new ConditionSetting(
                [new Condition('status_id_einstallen', Operator.IN, [142])],
                {
                    icon: 'check_circle',
                    color: '#8cb369',
                    tooltip: 'Zugangsmeldung erfolgreich an HI-Tier gesendet!',
                }
            ),
            new ConditionSetting(
                [new Condition('status_id_einstallen', Operator.IN, [145])],
                {
                    icon: 'check_circle_outline',
                    color: '#8cb369',
                    tooltip: 'Zugangsmeldung aus HI-Tier gelesen!',
                }
            ),
        ];
        columns.push(
            new ConditionTableColumn(
                'status_id_einstallen',
                'HIT Zugang',
                conditionSettingsZugang,
                {
                    sortFields: ['status_id_einstallen'],
                    defaultIcon: 'block',
                    defaultColor: 'gray',
                    defaultTooltip: 'Zugang nicht an HI-Tier melden!',
                }
            )
        );

        const conditionSettingsAbgang = [
            new ConditionSetting(
                [new Condition('status_id_abgang', Operator.GLEICH, 143)],
                {
                    icon: 'error',
                    color: '#bc4b51',
                    tooltipFieldnames: ['hit_fehler_abgang'],
                }
            ),
            new ConditionSetting(
                [new Condition('status_id_abgang', Operator.IN, [141, 144])],
                {
                    icon: 'check_circle',
                    color: '#f2c52d',
                    tooltip: 'Abgang wird an HI-Tier gemeldet!',
                }
            ),
            new ConditionSetting(
                [new Condition('status_id_abgang', Operator.IN, [142])],
                {
                    icon: 'check_circle',
                    color: '#8cb369',
                    tooltip: 'Abgangsmeldung erfolgreich an HI-Tier gesendet!',
                }
            ),
            new ConditionSetting(
                [new Condition('status_id_abgang', Operator.IN, [145])],
                {
                    icon: 'check_circle_outline',
                    color: '#8cb369',
                    tooltip: 'Abgangsmeldung aus HI-Tier gelesen!',
                }
            ),
        ];
        const conditionSettingsTod = [
            new ConditionSetting(
                [new Condition('status_id_tod', Operator.GLEICH, 143)],
                {
                    icon: 'error',
                    color: '#bc4b51',
                    tooltipFieldnames: ['hit_fehler_tod'],
                }
            ),
            new ConditionSetting(
                [new Condition('status_id_tod', Operator.IN, [141, 144])],
                {
                    icon: 'check_circle',
                    color: '#f2c52d',
                    tooltip: 'Tod wird an HI-Tier gemeldet!',
                }
            ),
            new ConditionSetting(
                [new Condition('status_id_tod', Operator.IN, [142])],
                {
                    icon: 'check_circle',
                    color: '#8cb369',
                    tooltip: 'Todmeldung erfolgreich an HI-Tier gesendet!',
                }
            ),
            new ConditionSetting(
                [new Condition('status_id_tod', Operator.IN, [145])],
                {
                    icon: 'check_circle_outline',
                    color: '#8cb369',
                    tooltip: 'Todmeldung aus HI-Tier gelesen!',
                }
            ),
        ];
        columns.push(
            new ConditionTableColumn(
                'status_id_abgang',
                'HIT Abgang',
                conditionSettingsAbgang,
                {
                    sortFields: ['status_id_abgang'],
                    defaultIcon: 'block',
                    defaultColor: 'gray',
                    defaultTooltip: 'Abgang nicht an HI-Tier melden!',
                }
            )
        );
        columns.push(
            new ConditionTableColumn(
                'status_id_tod',
                'HIT Tod',
                conditionSettingsTod,
                {
                    sortFields: ['status_id_tod'],
                    defaultIcon: 'block',
                    defaultColor: 'gray',
                    defaultTooltip: 'Tod nicht an HI-Tier melden!',
                }
            )
        );
        const conditionSettingsSgs = [
            new ConditionSetting(
                [
                    new Condition(
                        'sgs_id_zertifizierung',
                        Operator.EXISTIERT,
                        undefined
                    ),
                ],
                {
                    icon: 'verified',
                    color: '#8cb369',
                    tooltip: 'SGS Zertifizierungsdatei wurde erstellt.',
                }
            ),
        ];

        columns.push(
            new ConditionTableColumn(
                'sgs_id_zertifizierung',
                'SGS Zert.',
                conditionSettingsSgs,
                {
                    sortFields: ['sgs_id_zertifizierung'],
                    defaultIcon: '',
                    defaultColor: 'gray',
                    defaultTooltip:
                        'Noch keine SGS Zertifizierungsdatei erstellt.',
                }
            )
        );

        columns.push(
            new ArrayTableColumn('reklamationen', 'Reklamationen', [
                'reklamation',
            ])
        );

        columns.push(new TableColumn('viehhaendler', 'Händler'));
        columns.push(new TableColumn('herkunft_betrieb', 'Herkunftsbetrieb'));

        columns.push(
            new ArrayTableColumn('bemerkungen', 'Bemerkungen', ['bemerkung'])
        );
        columns.push(new NumberTableColumn('einstallgewicht', 'Einstallgewicht', '1.2-2', 'kg'));
        columns.push(new NumberTableColumn('ausstallgewicht', 'Ausstallgewicht', '1.2-2', 'kg'));

        // columns.push(new NumberTableColumn('hit_schlachtgewicht', 'S.Gew.', '1.1-1'));

        return columns;
    }

    getToolbarButtons(): ToolbarButton[] {
        return [];
    }

    getSelectionToolbarButtons(): SelectionToolbarButton[] {
        return [];
    }

    loadGruppe() {
        this.gruppeSubscription?.unsubscribe();
        this.gruppeSubscription = this.api
            .getByIdAlternative(
                this.apiBaseUrl + 'gruppe/read_one_kundenportal.php',
                'gruppe_id',
                this.gruppe?.gruppe_id?.toString()
            )
            .subscribe({
                next: (gruppe: Gruppe) => {
                    this.gruppe = gruppe;
                    this.schlachtungColumn.isActive = !!(this.gruppe?.sgs > 0);
                    this.tableService.updateColumns();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Gruppe konnte nicht geladen werden!',
                        err
                    );
                },
            });
    }

    initTierTable() {
        this.tableDescriptor.headline = 'Tiere';
        this.tableDescriptor.uniqueIdentifier = 'Gruppen-Gruppen-Tiere';
        this.tableDescriptor.alternativeApiUrl =
            this.apiBaseUrl + 'gruppe_tier/read_kundenportal.php';
        this.tableDescriptor.initialSortColumn = 'right(ohrmarke_notnull,5)';
        this.tableDescriptor.nameOfIdField = 'gt_id';
        this.tableDescriptor.createAllowed = false;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.showDetailsAsOption = true;
        this.tableDescriptor.selectable = true;
        this.tableDescriptor.showDownloads = true;
        this.tableDescriptor.showRefresh = true;
        this.tableDescriptor.expandable = false;

        this.tableDescriptor.params = new HttpParams().set(
            'gruppe_id',
            this.gruppe?.gruppe_id?.toString()
        );

        this.tableDescriptor.permissionView = 9121;
        this.tableDescriptor.permissionCreate = 9122;
        this.tableDescriptor.permissionUpdate = 9122;
        this.tableDescriptor.permissionDelete = 9124;

        this.tableDescriptor.columns = this.getGruppeTierColumns();
        this.tableDescriptor.toolbarButtons = this.getToolbarButtons();
        this.tableDescriptor.selectionToolbarButtons =
            this.getSelectionToolbarButtons();
        this.tableDescriptor.options = this.getOptions();
    }

    getOptions(): TableOption[] {
        const options = [];
        options.push(
            new TableOption(
                'reklamation',
                'Reklamation editieren',
                'comment',
                9122
            )
        );
        options.push(
            new TableOption('bemerkung_editieren', 'Bemerkungen', 'chat', 9192)
        );
        options.push(
            new TableOption('bearbeiten', 'Bearbeiten', 'label', 9122)
        );
        options.push(new TableOption('delete', 'Löschen', 'delete', 9124));
        options.push(
            new TableOption('tod_melden', 'Tod melden', 'cancel', 9125, {
                conditions: [
                    new Condition(
                        'kp_todesdatum',
                        Operator.EXISTIERT_NICHT,
                        null
                    ),
                ],
            })
        );

        options.push(
            new TableOption(
                'einstalldatum_aendern',
                'Einstalldatum ändern',
                'today',
                9128
            )
        );
        options.push(
            new TableOption(
                'abgangsdatum_aendern',
                'Abgangsdatum ändern',
                'event',
                9129,
                {
                    conditions: [
                        new Condition(
                            'hit_abgangsdatum',
                            Operator.EXISTIERT,
                            undefined
                        ),
                    ],
                }
            )
        );
        options.push(
            new TableOption(
                'pass_vorhanden_manuell',
                'Pass vorhanden',
                'badge',
                9122,
                {
                    conditions: [
                        new Condition('pass_gescannt', Operator.GLEICH, 0),
                    ],
                }
            )
        );
        options.push(
            new TableOption(
                'ohrmarke_vorhanden_manuell',
                'Ohrmarke vorhanden',
                'label',
                9122,
                {
                    conditions: [
                        new Condition('ohrmarke_gescannt', Operator.GLEICH, 0),
                    ],
                }
            )
        );
        options.push(
            new TableOption(
                'gruppe_aendern',
                'Gruppe ändern',
                'sync_alt',
                9122,
                {
                    conditions: [
                        new Condition(
                            'tierliste_id',
                            Operator.EXISTIERT_NICHT,
                            undefined
                        ),
                    ],
                }
            )
        );
        return options;
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'upload_gruppe_csv') {
            this.uploadTiereCSV();
        } else if (event.name === 'download_reklamations_pdf') {
            this.downloadReklamationsPdf();
        } else if (event.name === 'reklamation') {
            this.openAddGruppeTierReklamation(event.value);
        } else if (event.name === 'bemerkung') {
            this.openAddGruppeTierBemerkung(event.value);
        } else if (event.name === 'bearbeiten') {
            this.openEditierenDialog(<GruppeTier>event.value);
        } else if (event.name === 'delete') {
            this.deleteGruppeTier(event.value);
            /*} else if (event.name === 'hit_zugang') {
            this.openHITZugangDialog(event.value);*/
        } else if (event.name === 'zugaenge_melden') {
            this.openZugangMeldenDialog();
        } else if (event.name === 'tod_melden') {
            this.openTodMeldenDialog(<GruppeTier>event.value);
        } else if (event.name === 'einstalldatum_aendern') {
            this.openEinstalldatumAendernDialog([<GruppeTier>event.value]);
        } else if (event.name === 'abgangsdatum_aendern') {
            this.openAbgangsdatumAendernDialog([<GruppeTier>event.value]);
        } else if (event.name === 'abgangsdatum_aendern_list') {
            this.openAbgangsdatumAendernDialog(event.values);
        } else if (event.name === 'pass_vorhanden_manuell') {
            this.openPassVorhandenManuellDialog(event.value);
        } else if (event.name === 'ohrmarke_vorhanden_manuell') {
            this.openOhrmarkeVorhandenManuellDialog(event.value);
        } else if (event.name === 'details') {
            this.openGruppeTierDialog(event.value);
        } else if (event.name === 'bemerkung_editieren') {
            this.openBemerkungDialog(event.value);
        } else if (event.name === 'gruppe_aendern') {
            this.gruppeAendernTier(event.value);
        }
    }

    openOhrmarkeVorhandenManuellDialog(gt: GruppeTier) {
        this.gruppeTierService
            .ohrmarkeAlsGescanntMarkieren(gt.gruppe_id, [gt])
            .subscribe({
                next: (result: boolean) => {
                    if (result === true) {
                        this.tableService.reload();
                    }
                },
            });
    }

    openPassVorhandenManuellDialog(gt: GruppeTier) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                okButton: 'Bestätigen',
                text: 'Wollen Sie wirklich den Pass für das Tier manuell als vorhanden eintragen?',
                headline: 'Pass als vorhanden nachtragen',
                buttonColor: 'accent',
            },
        });
        dialogRef.afterClosed().subscribe((resp: ConfirmDialogResult) => {
            if (resp?.confirmed) {
                gt.pass_gescannt_manuell = 1;
                this.updateTier(gt);
            }
        });
    }

    updateTier(tier: GruppeTier) {
        this.gruppeTierService.update(tier).subscribe({
            next: () => {
                this.tableService.reload();
                this.messageService.infoMessage(
                    'Tier erfolgreich aktualisiert.'
                );
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Fehler beim Aktualisieren des Tiers!',
                    err
                );
            },
        });
    }

    openEinstalldatumAendernDialog(gt: GruppeTier[]) {
        const input = new EnterValueInput();
        input.title = 'Einstalldatum ändern';
        input.text =
            'Es wird das Einstalldatum für ' +
            gt.length +
            ' Tier(e) geändert. Die HIT Zugangsmeldung wird ebenfalls aktualisiert.';
        input.okButton = 'Einstalldatum ändern';
        input.buttonColor = 'accent';
        input.addInputValue('Neues Einstalldatum', 'date');

        const dialogRef = this.dialog.open(EnterValueDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: EnterValueResponse) => {
                if (result?.result && result?.getValue(0)) {
                    const einstalldatumNeu: Date = result.getValue(0);
                    const obj = {
                        einstalldatum_neu: einstalldatumNeu,
                        gruppen_tiere: gt,
                    };
                    this.api
                        .post(
                            this.apiBaseUrl +
                            'gruppe_tier/einstalldatum_aendern.php',
                            obj,
                            undefined
                        )
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Das Einstalldatum wurde erfolgreich geändert! Die Aktualisierung in der HIT Datenbank kann bis zu fünf Minuten dauern.'
                                );
                                this.tableService.reload(
                                    'openEinstalldatumAendernDialog'
                                );
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                                this.tableService.reload(
                                    'openEinstalldatumAendernDialog ERROR'
                                );
                            },
                        });
                }
            },
        });
    }

    openGruppeEinstallenDialog() {
        const gedi = new GruppeEinstallenDialogInsert(this.gruppe, true);
        const dialogRef = this.dialog.open(GruppeEinstallenDialogComponent, {
            data: gedi,
        });
        dialogRef.afterClosed().subscribe({
            next: () => {
                this.tableService.reload('openGruppeEinstallenDialog:close');
            },
        });
    }

    openAbgangsdatumAendernDialog(gt: GruppeTier[]) {
        const input = new EnterValueInput();
        input.title = 'Abgangsdatum ändern';
        input.text =
            'Es wird das Abgangsdatum für ' +
            gt.length +
            ' Tier(e) geändert. Die HIT Abgangsmeldung wird ebenfalls aktualisiert.';
        input.okButton = 'Abgangsdatum ändern';
        input.buttonColor = 'accent';
        input.addInputValue('Neues Abgangsdatum', 'date');

        const dialogRef = this.dialog.open(EnterValueDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: EnterValueResponse) => {
                if (result?.result && result?.getValue(0)) {
                    const abgangsdatumNeu: Date = result.getValue(0);
                    const obj = {
                        abgangsdatum_neu: abgangsdatumNeu,
                        gruppen_tiere: gt,
                    };
                    this.api
                        .post(
                            this.apiBaseUrl +
                            'gruppe_tier/abgangsdatum_aendern.php',
                            obj,
                            undefined
                        )
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Das Abgangsdatum wurde erfolgreich geändernt! Die Aktualisierung in der HIT Datenbank kann bis zu fünf Minuten dauern.'
                                );
                                this.tableService.reload(
                                    'openAbgangsdatumAendernDialog'
                                );
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                                this.tableService.reload(
                                    'openAbgangsdatumAendernDialog ERROR'
                                );
                            },
                        });
                }
            },
        });
    }

    uploadTiereCSV() {
        const dialogRef = this.dialog.open(TiereAlsCSVUploadDialogComponent, {
            data: {
                gruppe: this.gruppe,
            },
        });

        dialogRef.afterClosed().subscribe({
            next: (result) => {
                if (result) {
                    this.messageService.infoMessage(
                        'Die CSV Datei wurde erfolgreich hochgeladen!'
                    );
                    this.loadGruppe(); // für Details
                    this.tableService.reload('uploadTiereCSV');
                }
            },
        });
    }

    openEditierenDialog(tier: GruppeTier) {
        const dialogRef = this.dialog.open(TierEditierenDialogComponent, {
            width: '25vw',
            data: {
                tier: tier,
            },
        });

        dialogRef.afterClosed().subscribe({
            next: () => {
                this.tableService.reload('openEditierenDialog');
            },
        });
    }

    deleteGruppeTier(tier: GruppeTier) {
        const input = new ConfirmDialogInput();
        input.headline = 'Tier ' + tier.ohrmarke_notnull + ' löschen?';
        input.text =
            'Wollen Sie dieses Tier wirklich löschen? Eine mögliche HIT Zugangsmeldung über vetvet wird ebenfalls storniert!';
        input.okButton = 'Löschen';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.gruppeTierService.deleteTier(tier).subscribe({
                        next: () => {
                            this.messageService.infoMessage(
                                'Die Ohrmarke wurde erfolgreich gelöscht!'
                            );
                            this.tableService.reload('deleteGruppeTier');
                        },
                    });
                }
            },
        });
    }

    openAddGruppeTierReklamation(value: GruppeTier) {
        const dialogRef = this.dialog.open(
            AddGruppeTierReklamationDialogComponent,
            {
                data: {
                    gruppeTier: value,
                },
            }
        );
        dialogRef.afterClosed().subscribe({
            next: (gruppeTier: GruppeTier) => {
                if (gruppeTier) {
                    this.api
                        .updateGeneric(
                            this.apiBaseUrl +
                            'gruppe_tier/update_kundenportal.php',
                            gruppeTier
                        )
                        .subscribe({
                            next: () => {
                                this.tableService.reload(
                                    'openAddGruppeTierReklamation'
                                );
                                this.messageService.infoMessage(
                                    'Reklamationen wurden erfolgreich aktualisiert!'
                                );
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    downloadGruppeCsv() {
        const params = new HttpParams().set(
            'gruppe_id',
            this.gruppe.gruppe_id.toString()
        );
        this.fileDownloadService.downloadCSV(
            'gruppe_tier/read_csv.php',
            params
        );
    }

    downloadGruppeExcel() {
        const params = new HttpParams().set(
            'gruppe_id',
            this.gruppe.gruppe_id.toString()
        );
        this.fileDownloadService.downloadExcel(
            'gruppe_tier/read_excel.php',
            params
        );
    }

    downloadReklamationsPdf() {
        const dialogRef = this.dialog.open(
            GruppeReklamationPdfHaendlerAuswahlDialogComponent,
            {
                minWidth: '50vw',
                data: this.gruppe,
            }
        );
        dialogRef
            .afterClosed()
            .subscribe(
                (res: GruppeReklamationPdfHaendlerAuswahlDialogOutput) => {
                    if (res) {
                        const empfaenger = [];
                        if (res.empfaenger) {
                            empfaenger.push(res.empfaenger);
                        }
                        this.pdfViewerService.openPdfViewer(
                            new PdfViewerDialogInput(
                                'gruppe/read_reklamationspdf_kundenportal.php',
                                res.params.set(
                                    'gruppe_id',
                                    this.gruppe.gruppe_id.toString()
                                ),
                                `Reklamation ${this.gruppe.bezeichnung}`,
                                {
                                    email: {
                                        sende_mail: true,
                                        kunde_id: this.gruppe.kunde_id,
                                    },
                                    empfaenger: empfaenger,
                                }
                            )
                        );
                    }
                }
            );
    }

    openAbgangslisteDownloadDialog() {
        this.dialog.open(
            GruppeAbgangDownloadDialogComponent,
            {
                width: '50vw',
                data: {
                    gruppe: this.gruppe,
                },
            }
        ).afterClosed().subscribe((res: AbgangDownloadRequestPojo) => {
            if (res?.confirmed === true) {
                if (res?.pdfDownloadVerfuegbar()) {
                    // eine Pdf
                    this.downloadAbgaengePdf(res);
                } else if (res?.csvDownloadVerfuegbar()) {
                    // eine CSV
                    this.fileDownloadService.downloadCSV(
                        'tierliste/read_csv_kundenportal.php',
                        res.getHttpParams()
                    );
                } else if (res?.excelDownloadVerfuegbar()) {
                    // eine Excel
                    this.fileDownloadService.downloadExcel(
                        'tierliste/read_excel_kundenportal.php',
                        res.getHttpParams()
                    );
                } else {
                    this.fileDownloadService.downloadZip(
                        'tierliste/read_zip_kundenportal.php',
                        res.getHttpParams()
                    );
                }
            }
        });
    }

    downloadAbgaengePdf(res: AbgangDownloadRequestPojo) {
        this.pdfViewerService.openPdfViewer(
            new PdfViewerDialogInput(
                'tierliste/read_pdf_kundenportal.php',
                res.getHttpParams(),
                'Ausstallliste',
                {
                    email: {sende_mail: true, kunde_id: null},
                }
            )
        );
    }

    /**
     * s1 - s2 ==> (+/- {result})
     * @param s1
     * @param s2
     * @param options - asPercentage: boolean
     */
    getDifferenceString(
        s1: number | undefined,
        s2: number | undefined,
        options?: { asPercentage: boolean }
    ): string {
        if (s1 && s2) {
            let erg: number;
            if (options?.asPercentage) {
                erg = Number(((s1 / s2) * 100).toFixed(1));
            } else {
                erg = s1 - s2;
            }
            if (erg > 0) {
                return `(${options?.asPercentage ? '' : '+'}${erg.toString()}${options?.asPercentage ? '%' : ''
                })`;
            } else if (erg < 0) {
                return `(${erg.toString()}${options?.asPercentage ? '%' : ''})`;
            } else {
                return '';
            }
        }
        return '';
    }

    openGruppeInformationenDialog() {
        const dialofRef = this.dialog.open(GruppeInformationenDialogComponent, {
            width: '50vw',
            data: {
                gruppe_id: this.gruppe.gruppe_id,
            },
        });

        dialofRef.afterClosed().subscribe({
            next: () => {
                this.loadGruppe();
            },
        });
    }

    loadGruppenVerlauf() {
        this.verlaufIsLoading = true;
        this.gruppenVerlaufSubscription?.unsubscribe();
        this.gruppenVerlaufSubscription = this.gruppenService
            .ladeGruppenVerlauf(this.gruppe?.gruppe_id)
            .subscribe({
                next: (response) => {
                    this.verlaufIsLoading = false;
                    this.gruppenVerlauf = response.records;
                },
                error: (err) => {
                    this.verlaufIsLoading = false;
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }

    isEinstalldatumAendernDisabled() {
        const selectedRows = this.tableService.getSelectedRows();
        return (
            selectedRows?.length === 0 ||
            selectedRows.some((row: GruppeTier) => row.kp_abgangsdatum === null)
        );
    }

    isAusstalldatumAenderDisabled() {
        const selectedRows = this.tableService.getSelectedRows();
        return selectedRows?.length === 0;
    }

    zugaengeMelden() {
        const input = new EinstallenMeldenDialogInput();
        input.gruppe_id = this.gruppe?.gruppe_id;

        const dialogRef = this.dialog.open(EinstallenMeldenDialogComponent, {
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: EinstallenMeldenDialogResult) => {
                if (result?.ok) {
                    this.tableService.reload();
                }
            },
        });
    }

    abgaengeMelden() {
        const inp = new ConfirmDialogInput();
        inp.text =
            'Sollen alle Tiere bei HI-Tier abgemeldet werden, die sich in einer abgeschlossenen Tierliste befinden?';
        inp.okButton = 'Melden';
        inp.headline = 'Abgänge melden';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: inp,
        });
        dialogRef.afterClosed().subscribe((resp: ConfirmDialogResult) => {
            if (resp?.confirmed) {
                this.api
                    .updateGeneric(
                        this.apiBaseUrl + 'gruppe_tier/abgaenge_hit.php',
                        this.gruppe
                    )
                    .subscribe({
                        next: () => {
                            this.messageService.infoMessage(
                                'Tiere werden bei HIT abgemeldet!'
                            );
                            this.tableService.reload();
                        },
                        error: (err) => {
                            this.messageService.errorMessage(
                                'Tiere können nicht abgemeldet werden!',
                                err
                            );
                        },
                    });
            }
        });
    }

    loadGruppenStatistik() {
        const params = new HttpParams().set(
            'gruppe_id',
            this.gruppe?.gruppe_id.toString()
        );

        this.gruppenStatistikSubscription?.unsubscribe();
        this.gruppenStatistikSubscription = this.api
            .get(this.apiBaseUrl + 'gruppe_tier/read_statistik.php', params)
            .subscribe({
                next: (gruppenStatistik) => {
                    this.gruppenStatistik = gruppenStatistik;
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }

    gibAnzahlStatistiken() {
        const l1 = this.gruppenStatistik?.geschlechter?.length;
        const l2 = this.gruppenStatistik?.reklamationen?.length;
        const l3 = this.gruppenStatistik?.rassen?.length;
        const l4 = this.gruppenStatistik?.abteile?.length;
        return (l1 ? l1 : 0) + (l2 ? l2 : 0) + (l3 ? l3 : 0) + (l4 ? l4 : 0);
    }

    setFilterSettings(filterSettings: TierFilterSettings) {
        this.filterSettings = filterSettings;
        this.tableDescriptor.filterParams = this.filterSettings?.getAsParams();
        this.tableService.reload('setFilterSettings');
    }

    sortiereTiere(tierSortierFilter: TierSortierFilter) {
        const params = this.tableDescriptor.params;
        tierSortierFilter.param.keys().forEach((key) => {
            params.set(key, tierSortierFilter.param.get(key));
        });
        this.tableDescriptor.params = params;
        this.tableService.reload();
    }

    statistikOpenable() {
        return (
            this.gruppenStatistik &&
            (this.gruppenStatistik?.rassen.length > 0 ||
                this.gruppenStatistik?.geschlechter.length > 0 ||
                this.gruppenStatistik?.abteile.length > 0 ||
                this.gruppenStatistik?.reklamationen.length > 0)
        );
    }

    verlaufOpenable() {
        return this.gruppenVerlauf && this.gruppenVerlauf?.length > 0;
    }

    eingescanntePaesseVerwalten() {
        const dialogRef = this.dialog.open(
            GruppeTierPassScanVerwaltungDialogComponent,
            {
                minWidth: '50vw',
                data: this.gruppe,
            }
        );
    }

    zertifikateAnSgsEinzeln(gruppenTiere: GruppeTier[]) {
        this.gruppenService.zertifikateAnSGS({
            einzelneTiere: {gruppenTiere: gruppenTiere},
        });
    }

    zertifikateAnSGS() {
        this.gruppenService.zertifikateAnSGS({
            ganzeGruppe: {gruppe_id: this.gruppe?.gruppe_id},
        });
    }

    ausstallen() {
        this.ausstallenService.ausstallen(
            this.gruppe,
            true,
            this.tableService.getSelectedRows()
        );
    }

    public alleOhrmarkenGescannt() {
        this.gruppeTierService
            .ohrmarkeAlsGescanntMarkieren(
                this.gruppe?.gruppe_id,
                this.tableService.getSelectedRows()
            )
            .subscribe({
                next: (b: boolean) => {
                    if (b) {
                        this.tableService.reload('alleOhrmarkenGescannt');
                    }
                },
            });
    }

    standortwechsel() {
        const dialogRefStandortWechsel = this.dialog.open(
            GruppeStandortWechselnDialogComponent,
            {
                data: {
                    gruppe: this.gruppe,
                },
            }
        );
        dialogRefStandortWechsel.afterClosed().subscribe({
            next: (result: GruppeStandortWechselnDialogResult) => {
                if (result && result.confirm) {
                    this.loadGruppe();
                    this.loadGruppenVerlauf();
                    this.gruppenService.reloadStandorte();
                }
            },
        });
    }

    public gruppeAendernTiere() {
        this.gruppeAendern(this.kieneTable.getSelectedRows());
    }

    tabChanged(event: MatTabChangeEvent) {
        this.selectedIndex = event.index;
    }

    public tabWechseln(index: number) {
        this.selectedIndex = index;
    }

    private openZugangMeldenDialog() {
        this.dialog.open(ZugangMeldenDialogComponent, {
            width: '25vw',
            data: {
                gruppe: this.gruppe,
            },
        });
    }

    private openAddGruppeTierBemerkung(value: any) {
        const dialogRef = this.dialog.open(GruppeTierBemerkungDialogComponent, {
            minWidth: '50vw',
            data: {
                tier: value,
            },
        });
        dialogRef.afterClosed().subscribe({
            next: (gruppeTier: GruppeTier) => {
                if (gruppeTier) {
                    this.api
                        .updateGeneric(
                            this.apiBaseUrl +
                            'gruppe_tier/update_kundenportal.php',
                            gruppeTier
                        )
                        .subscribe({
                            next: () => {
                                this.tableService.reload();
                                this.messageService.infoMessage(
                                    'Bemerkungen wurden erfolgreich aktualisiert!'
                                );
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    private openTodMeldenDialog(tier: GruppeTier) {
        const dialogRef = this.dialog.open(GruppeTierTodMeldenComponent, {
            width: '25vw',
            data: {
                gruppeTier: tier,
            },
        });
        dialogRef.afterClosed().subscribe({
            next: (resp) => {
                if (resp) {
                    this.tableService.reload();
                }
            },
        });
    }

    private openGruppeTierDialog(gruppeTier: GruppeTier) {
        this.dialog.open(GruppeTierDetailsDialogComponent, {
            minWidth: '30vw',
            data: {
                tier: gruppeTier,
            },
        });
    }

    private openBemerkungDialog(gt: GruppeTier) {
        const dialogRef = this.dialog.open(GruppeTierBemerkungDialogComponent, {
            data: {
                tier: gt,
            },
        });
        dialogRef.afterClosed().subscribe({
            next: () => {
                this.tableService.reload('openBemerkungDialog');
            },
        });
    }

    private gruppeAendernTier(gruppeTier: GruppeTier) {
        this.gruppeAendern([gruppeTier]);
    }

    private gruppeAendern(gruppeTiere: GruppeTier[]) {
        this.gruppeTierService.gruppeAendernConfirm(gruppeTiere).subscribe({
            next: (b: boolean) => {
                if (b) {
                    this.tableService.reload('GruppeTier: gruppeAendern');
                }
            },
        });
    }
}
