import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BenutzerrechtApi } from './permission';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermissionOverviewService {

  constructor(private http: HttpClient) { }

  getAll(sortColumn: string, sortDirection: string, offset: number, limit: number, searchString: string): Observable<BenutzerrechtApi>{
    var parameters: HttpParams = new HttpParams()
    .set('orderby', sortColumn + " " + sortDirection)
    .set('limit', limit.toString())
    .set('offset', offset.toString())
    .set('search', searchString);
    return this.http.get<BenutzerrechtApi>(environment.BASE_URI+"benutzerrecht/read.php", { params: parameters });
  }
}
