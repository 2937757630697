import {Component, Inject, OnInit} from '@angular/core';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InitMiniTable} from '../../../components/kiene-mini-table/kiene-mini-table.component';
import {HotKeyDetailsDialogComponent} from '../hot-key-details-dialog/hot-key-details-dialog.component';
import {Artikelliste} from '../../beleg-position/artikelliste-auswahl-dialog/artikelliste-auswahl-dialog';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';

@Component({
    selector: 'kiene-hot-key-list-dialog',
    templateUrl: './hot-key-list-dialog.component.html',
    styleUrls: ['./hot-key-list-dialog.component.scss'],
})
export class HotKeyListDialogComponent implements OnInit {
    apiUrl = 'artikelliste/';
    initMiniTable = new InitMiniTable();
    allArticleLists: Artikelliste[] = [];

    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        public dialogRef: MatDialogRef<HotKeyListDialogComponent>,
        public dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.getData();
    }

    getData() {
        let url;
        url = this.apiBaseUrl + this.apiUrl + 'read.php';
        this.api.get(url).subscribe({
            next: (res) => {
                this.tableConstructor(res.records);
                this.allArticleLists = res.records;
            },
            error: (err) => {
                console.error(err);
            },
        });
    }

    tableConstructor(data: Artikelliste[]) {
        this.initMiniTable = {
            title: '',
            hideAddButton: false,
            addActionButtons: true,
            disableKeyControl: true,
            columnNames: ['Listenname'],
            attributes: ['listenname'],
            dataList: data,
            listeLaenge: data.length,
            kienePermissionDeleteItem: 2684,
            attributeID: 'artikelliste_id',
        };
    }

    getElement($event: any) {
        if (!$event) {
            this.dialogRef.close();
        } else {
            this.openDetailsDialog($event);
        }
    }

    openDetailsDialog(obj?: any) {
        if (!obj) {
            obj = new Artikelliste();
        }
        const dialogref = this.dialog.open(HotKeyDetailsDialogComponent, {
            data: obj,
            minHeight: '600px',
            minWidth: '1500px',
        });
        dialogref.afterClosed().subscribe({
            next: () => {
                this.getData();
            },
        });
    }

    closeDialog() {
        this.dialogRef.close();
    }

    deleteArticleList(list: Artikelliste) {
        const input = new ConfirmDialogInput();
        input.headline = 'Vorlage löschen';
        input.text = 'Wollen Sie die Belegvorlage wirklich löschen?';
        input.okButton = 'löschen';
        input.buttonColor = 'warn';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input
        }).afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result.ok && result.confirmed) {
                    this.deleteList(list);

                }
            }
        });
    }

    neueVorlageAnlegen() {
        this.openDetailsDialog();
    }

    private deleteList(list: Artikelliste) {
        let url = this.apiBaseUrl + this.apiUrl + 'delete.php';
        this.api.post(url, list).subscribe({
            next: (res) => {
                this.getData();
            },
            error: (err) => {
                console.error(err);
            },
        });
    }
}
