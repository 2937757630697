import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, } from '@angular/core';
import { GruppeTier } from '../classes/gruppe-tier';
import { Gruppe, GruppenStatistik } from '../../gruppen/classes/gruppe';
import { HttpParams } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { MessageService } from '../../../services/message-service/message.service';
import { TierFilter } from '../filter/gruppe-tier-filter-dialog/classes/tier-filter';
import { TierFilterSettings } from '../filter/gruppe-tier-filter-dialog/classes/tier-filter-settings';
import { TierSortierFilter } from '../classes/tier-sortier-filter';
import { UntypedFormControl } from '@angular/forms';
import { KieneUtils } from '../../../utils/KieneUtils';
import { MatDialog } from '@angular/material/dialog';
import { GruppeTierHilfeDialogComponent } from '../gruppe-tier-hilfe-dialog/gruppe-tier-hilfe-dialog.component';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { ActivatedRoute } from '@angular/router';
import {
    GruppeTierFilterButtonComponent
} from '../../gruppe-tier/filter/gruppe-tier-filter-button/gruppe-tier-filter-button.component';

@Component({
    selector: 'kiene-gruppe-tier-list',
    templateUrl: './gruppe-tier-list.component.html',
    styleUrls: ['./gruppe-tier-list.component.scss'],
})
export class GruppeTierListComponent implements OnInit, OnDestroy, OnChanges {

    @ViewChild('filterButton') filterButton: GruppeTierFilterButtonComponent;
    @Input('gruppeTiereUrl') gruppeTiereUrl =
        'gruppe_tier/read_kundenportal.php';
    @Input('gruppeUrl') gruppeUrl = 'gruppe/read_one_kundenportal.php';
    @Input('gruppeStatistikUrl') gruppeStatistikUrl =
        'gruppe_tier/read_statistik.php';
    @Input('gruppeId') gruppeId: number;

    // Optionen
    @Input('hideSuche') hideSuche = false;
    @Input('hideFilter') hideFilter = false;
    @Input('hideSortierung') hideSortierung = false;
    @Input('hideHilfeButton') hideHilfeButton = false;
    @Input('hideReloadButton') hideReloadButton = false;

    @Output('reload') reload = new EventEmitter<boolean>();
    @Output('filterSelected') filterSelected = new EventEmitter<TierFilterSettings>();

    gruppe: Gruppe;
    gruppeTiere: GruppeTier[];

    isLoading = true;
    public tierFilter = TierFilter;
    filterSettings: TierFilterSettings = new TierFilterSettings();
    ueberschrift: string;
    selectedSortierFilter: TierSortierFilter;
    selectedAbteil: string;
    selectedTierFilter: TierFilter = TierFilter.ALLE_TIERE;
    gruppenStatistik: GruppenStatistik;

    // Formcontrols
    searchCtrl = new UntypedFormControl();
    scanUnvollstaendigCtrl = new UntypedFormControl();

    // Subscriptions
    searchSub: Subscription;
    tierfilterSubscription: Subscription;
    gruppenStatistikSubscription: Subscription;
    gruppeSubscription: Subscription;

    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private baseUrl: string,
        private messageService: MessageService,
        private dialog: MatDialog,
        private route: ActivatedRoute
    ) {
    }

    ngOnChanges(): void {
        if (this.gruppeId) {
            this.gruppe = new Gruppe(); // solange noch nicht geladen
            this.gruppe.gruppe_id = this.gruppeId;
            this.loadGruppe();
        }
    }

    ngOnDestroy(): void {
        this.searchSub?.unsubscribe();
        this.gruppeSubscription?.unsubscribe();
        this.tierfilterSubscription?.unsubscribe();
    }

    ngOnInit(): void {

        const search = this.route.snapshot.queryParamMap.get('search');
        this.searchCtrl.setValue(search, { emitEvent: false });

        if (this.gruppeId) {
            this.gruppe = new Gruppe(); // solange noch nicht geladen
            this.gruppe.gruppe_id = this.gruppeId;
            this.loadGruppe();
        }

        this.searchCtrl.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(300))
            .subscribe({
                next: () => {
                    this.loadTiere();
                },
            });

        this.scanUnvollstaendigCtrl.valueChanges.subscribe({
            next: (value) => {
                this.loadTiere({ scanUnvollstaendig: value });
            },
        });

    }

    loadGruppe() {
        this.loadGruppenStatistik();

        const params = new HttpParams().set(
            'gruppe_id',
            this.gruppe?.gruppe_id?.toString()
        );

        this.gruppeSubscription?.unsubscribe();
        this.gruppeSubscription = this.api
            .get(this.baseUrl + this.gruppeUrl, params)
            .subscribe({
                next: (gruppe) => {
                    this.gruppe = gruppe;
                    this.ueberschrift = `${this.gruppe.historie === 1 ? 'Historie: ' : ''
                        } ${this.gruppe.bezeichnung}`;
                    this.loadTiere();
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }

    loadGruppenStatistik() {
        const params = new HttpParams().set(
            'gruppe_id',
            this.gruppe?.gruppe_id?.toString()
        );

        this.gruppenStatistikSubscription?.unsubscribe();
        this.gruppenStatistikSubscription = this.api
            .get(this.baseUrl + this.gruppeStatistikUrl, params)
            .subscribe({
                next: (gruppenStatistik) => {
                    this.gruppenStatistik = gruppenStatistik;
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }

    loadTiere(options?: { scanUnvollstaendig?: boolean }) {
        this.gruppeTiere = [];

        let params = this.filterSettings.getAsParams();
        console.log('Filter Params: %s', JSON.stringify(params));

        if (this.selectedSortierFilter?.param) {
            const keys: string[] = this.selectedSortierFilter.param.keys();
            for (const k of keys) {
                const values: string[] = this.selectedSortierFilter.param.getAll(k);
                for (const v of values) {
                    params = params.append(k, v);
                }
            }
        } else {
            params = params.set('orderby', 'geburtsdatum asc, tier_id asc');
        }

        params = params.append('gruppe_id', this.gruppe?.gruppe_id?.toString());

        if (this.searchCtrl.value) {
            params = params.append('search', this.searchCtrl.value);
        }

        if (options && options.scanUnvollstaendig) {
            params = params.set(
                'scan_fehlt',
                options.scanUnvollstaendig ? '1' : '0'
            );
        }

        this.isLoading = true;
        this.searchSub?.unsubscribe();
        this.searchSub = this.api.get(this.baseUrl + this.gruppeTiereUrl, params).subscribe({
            next: (response) => {
                this.isLoading = false;
                this.gruppeTiere = response.records;
            },
            error: (error) => {
                this.isLoading = false;
                this.messageService.errorMessage(
                    'Fehler beim Laden der Tiere!',
                    error
                );
            },
        });
    }

    validFilterSettings(filterSettings: TierFilterSettings) {
        return (
            filterSettings?.filter !== TierFilter.ALLE_TIERE ||
            KieneUtils.isNNoU(filterSettings?.rassen) ||
            KieneUtils.isNNoU(filterSettings?.reklamationen) ||
            KieneUtils.isNNoU(filterSettings?.geschlechter) ||
            KieneUtils.isNNoU(filterSettings?.abteile)
        );
    }

    sortiereTiere(sortierung?: TierSortierFilter) {
        this.isLoading = true;
        this.selectedSortierFilter = sortierung;
        this.loadTiere();
    }

    setFilterSettings(filterSettings: TierFilterSettings) {
        this.filterSettings = filterSettings;
        this.loadTiere();
        this.filterSelected.emit(filterSettings);
    }

    openHilfe() {
        this.dialog.open(GruppeTierHilfeDialogComponent, {
            minWidth: '90vw',
            width: '90vw',
        });
    }

    reloadEvent() {
        this.loadTiere();
        this.reload.emit(true);
    }

}
