export class MassnahmenplanRind {
    abrechnen: number;
    abgabebeleg_id: number;
    mnp_rind_id: number;
    abweichende_lieferadresse: number;
    kuid: string;
    tierarzt_id: number;
    kunde_id: number;
    kunde: string;
    kunde_nr_intern: number;
    vvvo: string;
    kunde_ort: string;
    ort_lieferung: string;
    kunde_plz: string;
    plz_lieferung: string;
    kunde_strasse: string;
    strasse_lieferung: string;
    kunde_telefon_1: string;
    status: string;
    status_id: number;
    mnp_kurz: number;
    unterschrift_kunde: number;
    tierart_id: number;
    _0_jahr: number;
    _0_halbjahr: number;
    _0_therapiehaeufigkeit: number;
    //_1_anzahl_staelle: string;
    // _1_anzahl_stallplaetze_gesamt: number;
    _1_milchkuehe_auf_dem_betrieb: number;
    _2_ta_betreuungsvertrag: number;
    _2_weitere_ta_yes_no: number;
    _2_weitere_ta_name_anschrift: string;
    _2_weitere_berater_yes_no: number;
    _2_weitere_berater_name_anschrift: string;
    _3_1_rein_raus: number;
    _3_1_abteilweise: number;
    _3_1_stallweise: number;
    _3_1_kontinuierlich: number;
    _3_1_mit_auslauf: number;
    _3_1_freilandhaltung: number;
    _3_2_qs_teilnehmer: number;
    _3_2_bio_siegel: string;
    _3_2_sonstiges: string;
    _3_3_geschlossenes_system: number; // eigene nachzucht
    _3_3_zukauf: number;
    _3_3_anzahl_herkuenfte: string;
    _3_4_fresser_aufzucht: number;
    _3_4_fresser_mast: number;
    _3_4_milchmast: number;
    _3_4_rose_mast: number;
    _3_4_bullenmast: number;
    _3_4_alter_bei_einstallung: string;
    _3_4_mastdauer: string;
    _3_5_besatzdichte: string;
    _3_5_tiere_pro_bucht: string;
    _3_6_leerstandszeit: string;
    _3_7_betonspalten: number;
    _3_7_holzspalten: number;
    _3_7_holzspalten_mit_kunststoff: number;
    _3_7_tiefstreu: number;
    _3_7_liegeboxen_laufstall: number;
    _3_7_anbinde_haltung: number;
    _3_7_weidegang: number;
    // _3_7_sonstiges_y_n: number;
    _3_7_sonstiges_text: string;
    _4_zwangslueftung: number;
    _4_deckenlueftung: number;
    _4_tuerganglueftung: number;
    _4_freie_lueftung: number;
    _4_unterflurabsaugung: number;
    _4_individuell_1: string;
    _4_individuell_2: string;
    _4_heizkanone: number;
    _4_konvektor: number;
    _4_deltarohre: number;
    _4_individuell_3: string;
    _4_individuell_4: string;
    _4_ueberpruefung_am: Date;
    _4_durch: string;
    _5_milchaustauscher: number;
    _5_tmr: number;
    _5_kraftfutter: number;
    _5_heu: number;
    _5_stroh: number;
    _5_maissilage: number;
    _5_individuell_1: string;
    _5_eimer: number;
    _5_trog: number;
    _5_automat: number;
    _5_tiere_futterplatz: string;
    _5_brunnenwasser: number;
    _5_stadtwasser: number;
    _5_wasseruntersuchung: number;
    // _5_wasseruntersuchung_text: string;
    _5_tiere_traenke: string;
    _5_art_traenke: string;
    _6_injektion: number;
    _6_futter: number;
    _6_wasser: number;
    _6_milchtraenke: number;
    _6_mischer_vorbehaelter: number;
    _6_dosierer: number;
    _6_tiernah: number;
    _6_lokal: number;
    _6_leitfaden: number;
    _6_risikomanagement: number;
    _7_1_art_schutzkleidung: string;
    _7_1_art_hygieneschleuse: string;
    _7_2_reinigung_stall: number;
    _7_2_desinfektion_stall: number;
    _7_3_reinigung: number;
    _7_3_desinfektion: number;
    _7_3_reinigung_futterleitung: number;
    _7_3_reinigung_futtertroege: number;
    _7_3_routinemaessig: number;
    _7_3_nach_antibiotika: number;
    _7_3_reinigung_futtersilo: number;
    _7_4_durch_fremdfirma: number;
    _7_4_selbst: number;
    _7_5_y_n: number;
    _7_5_y_n_anzahl: string;
    _7_5_mit_stroh: number;
    _7_5_sonstiges_y_n: number;
    _7_5_sonstiges_y_n_text: string;
    //_7_6_sonstige_absonder: number;
    //_7_6_sonstige_absonder_text: string;
    _8_1_vorgeimpft_y_n: number;
    _8_1_vorgeimpft_y_n_text: string;
    _8_1_im_bestand_geimpft_y_n: number;
    _8_1_im_bestand_geimpft_y_n_text: string;
    _8_2_endoparasiten_y_n: number;
    _8_2_ektoparasiten_y_n: number;
    _8_2_sonstiges_y_n: number;
    _8_2_sonstiges_y_n_text: string;
    _9_1_atemwege: number;
    _9_1_verdauungsapparat: number;
    _9_1_bewegungssapparat: number;
    _9_1_sonstiges_y_n: number;
    _9_1_sonstiges_y_n_text: string;
    _9_2_anzahl_kalenderhalbjahr: string;
    _9_2_anzahl_vorheriges_halbjahr: string;
    _9_3_angaben_zu_krankheit: string;
    _9_4_erregernachweis_y_n: number;
    _9_4_erregernachweis_y_n_text: string;
    _9_4_sektion_y_n: number;
    _9_4_sektion_y_n_text: string;
    _9_4_sonstiges_y_n: number;
    _9_4_sonstiges_y_n_text: string;
    _10_1_ergebnis_beratung: string;
    _10_1_datum: Date;
    _10_1_text_streichen_y_n: number;
    _11_1_massnahmen: string;
    _11_1_zeitraum_umsetzung: string;
    _11_2_ort: string;
    _11_2_datum: Date;
    _11_2_verantwortlicher: string;
    befunde_anhaengen: number;
    bbp_anhaengen: number;

    pdf_id: number;
    erstellt: Date;
    erstellt_von: number;
    editiert: Date;
    editiert_von: number;
    geloescht: number;
    mandant_id: number;

    // ###########

    _1_stallnummer_1: string;
    _1_stallnummer_2: string;
    _1_stallnummer_3: string;
    _1_stallnummer_4: string;
    _1_stallplatze_1: number;
    _1_stallplatze_2: number;
    _1_stallplatze_3: number;
    _1_stallplatze_4: number;
    _2_integration: number;
    _3_biosiegel: number;
    _3_tierwohlteilnahme: number;
    _3_sonstige_programme: string;
    _3_haltungsdauer: string;
    _3_gummiauflage: number;
    _3_planbefestigt: number;
    _3_trockensteher: number;
    _3_faersenmast: number;
    _3_nutzungsdauer: string;
    _3_herdenalter: string;
    _3_zellzahl: string;
    _3_mast_sonstiges: string;
    _3_kuhgebundene_aufzucht: number;
    _4_ventilator: number;
    _4_airtubes: number;
    _5_grassilage: number;
    _5_treber: number;
    _5_trester: number;
    _5_rueben: number;
    _5_weide: number;
    _5_vollautomatisch: number;
    _5_futter_sonstiges: string;
    _5_untersuchung_am: string;
    _6_topdressing: number;
    _6_vermischt: number;
    _6_intramammaer: number;
    _6_intrauterin: number;
    _7_reinigungsmittel: string;
    _7_reinigung_fachfirma: number;
    _7_reinigung_selbst: number;
    _7_desinfektionsmittel: string;
    _7_desinfektion_fachfirma: number;
    _7_desinfektion_selbst: number;
    _7_dokumentation: number;
    _7_wasser_desinfektionsmittel: string;
    _7_wasser_routine: number;
    _7_wasser_antibiotika: number;
    _7_desinfektionsmatten: number;
    _7_desinfektionsmatten_text: string;
    _7_abkalbebuchten: number;
    _7_abkalbebuchten_anzahl: string;
    _7_abkalbebuchten_stroh: number;
    _7_abkalbebuchten_sonstiges: string;
    _8_kryptosporidien: number;
    _8_kokzidien: number;
    _9_euter: number;
    _9_geschlechtsapparat: number;
    _9_stoffwechsel: number;
    _9_leitkeime: number;
    _9_uberis: number;
    _9_aureus: number;
    _9_ecoli: number;
    _9_agalactiae: number;
    _9_dysagalactiae: number;
    _9_mykoplasmen: number;
    _9_leitkeime_text: string;
    _4b_melkstand: number;
    _4b_melkstand_art: string;
    _4b_melkstand_plaetze: string;
    _4b_melkroboter: number;
    _4b_modell: string;
    _4b_leistungspruefung: number;
    _4b_in_der_herde: number;
    _4b_am_ende: number;
    _4b_wartung: number;
    _4b_wartung_haeufigkeit: string;
    _4b_tausch: number;
    _4b_tausch_haeufigkeit: string;
    _4b_zitzendippen: number;
    _4b_einwegtuch: number;
    _4b_mehrwegtuch: number;
    _4b_zitzendesinfektion: number;
    _4b_handschuhe: number;
    _4b_schutzkleidung: string;
    _8_1_im_bestand_geimpft_y_n_2: number;
    _8_1_im_bestand_geimpft_y_n_text_2: string;
    _8_bestandsbetreuung: number;
    _8_fruchtbarkeit: number;
    _8_eutergesundheit: number;
    _8_klauengesundheit: number;
    _8_tiergesundheit: number;
    _8_bestandsbetreuung_sonstiges: string;
    _8_klauenpflege_selbst: number;
    _8_klauenpflege_fachfirma: number;
    _8_klauenpflege_frequenz: string;
    _8_trockenstehzeit_6: number;
    _8_trockenstehzeit_6_8: number;
    _8_trockenstehzeit_8: number;
    _8_trockenstehzeit_0: number;
    _8_reduk_melkhaeufigkeit: number;
    _8_reduk_abrupt: number;
    _8_reduk_energie: number;
    _8_art_sonstiges: string;
    _8_antibiotischer_schutz: number;
    _8_zitzenversiegler: number;
}
