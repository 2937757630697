export class DienstgruppeTierarzt {
    dt_id: number;
    dienstrguppe_id: number;
    tierarzt_id: number;
    vorname: string;
    nachname: string;
    editiert: Date;
}

export class Dienstgruppe {
    dienstgruppe_id: number;
    dienstgruppe: string;
    farbe: string;
    hintergrundfarbe: string;
    tieraerzte: DienstgruppeTierarzt[] = [];
    editiert: Date;
}
