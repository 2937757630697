import { Component, Input, OnInit } from '@angular/core';
import { Gruppe } from '../../classes/gruppe';
import { EinstallenService } from '../einstallen.service';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
    selector: 'kiene-einstallen-uebersicht',
    templateUrl: './einstallen-uebersicht.component.html',
    styleUrls: ['./einstallen-uebersicht.component.scss']
})
export class EinstallenUebersichtComponent implements OnInit {

    @Input() gruppe: Gruppe;
    @Input() showNumberpadTab = true;
    @Input() showPassReiter = false;

    currentTabIndex = 0;

    constructor(
        private einstallService: EinstallenService,
    ) {
    }

    ngOnInit(): void {
        this.einstallService.watchCurrentGruppe().subscribe({
            next: gruppe => this.gruppe = gruppe
        });
    }

    tabChanged(event: MatTabChangeEvent) {
        this.currentTabIndex = event.index;
        if (event.index === 2) {
            this.einstallService.ladeEingestallteTiere();
        }
    }

}

