import {Inject, Injectable} from '@angular/core';
import {KieneBackendApiService} from '../../../services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../kiene-core.config';
import {BenutzerSignatur} from './classes/benutzer-signatur';

@Injectable({
    providedIn: 'root',
})
export class UnterschriftService {
    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    saveSignature(signature: BenutzerSignatur) {
        return this.api.updateGeneric(
            this.apiBaseUrl + 'benutzerprofil/update_unterschrift.php',
            signature
        );
    }

    getSignatur() {
        return this.api.get(
            this.apiBaseUrl + 'benutzerprofil/read_unterschrift.php'
        );
    }
}
