import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'kiene-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnChanges {
    @Input() showReturnButton = true;
    @Input() ueberschrift = '';
    @Input() small = false;
    @Input() routeRelativeAlternative: string;

    constructor(private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
    }

    ngOnChanges() {
    }

    zurVorherigenSeite() {
        if (this.routeRelativeAlternative) {
            this.router
                .navigate([this.routeRelativeAlternative], {
                    relativeTo: this.route,
                })
                .then();
        } else {
            window.history.back();
        }
    }
}
