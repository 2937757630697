import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../services/message.service';
import {
    TierwohlprotokollRindDetailsComponent
} from './tierwohlprotokoll-rind-details/tierwohlprotokoll-rind-details.component';
import { TableDescriptor } from '../../../components/kiene-table/table-descriptor';
import { Subscription } from 'rxjs';
import { TabinfoService } from '../../../components/tabinfo/tabinfo.service';
import { EmailGesendetTableColumn, GedrucktTableColumn, KieneBooleanTableColumn, TableColumn } from '../../../components/kiene-table/table-column';
import { Condition, Operator, TableOption, TableOptionEvent } from '../../../components/kiene-table/table-option';
import { ConfirmDeleteDialogComponent } from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { TierwohlprotokollRind } from './classes/tierwohlprotokoll-rind';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import { HttpParams } from '@angular/common/http';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { Client } from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-tierwohlprotokoll-rind',
    templateUrl: './tierwohlprotokoll-rind.component.html',
    styleUrls: ['./tierwohlprotokoll-rind.component.scss'],
})
export class TierwohlprotokollRindComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();
    currentClient: Client;
    watchClientSubscription: Subscription;

    constructor(
        public dialog: MatDialog,
        private messageService: MessageService,
        private pdfService: PdfViewerDesktopService,
        private api: KieneTableService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private tabinfoService: TabinfoService
    ) {
    }

    ngOnInit() {
        this.api.watchDataLoadedCompleted().subscribe({
            next: () => {
                this.tabinfoService.setReload('bestandsbesuchsprotokoll/tabinfo.php');
            },
        });

        this.tableDescriptor.headline = 'Tierwohlprotokolle Rind';
        this.tableDescriptor.uniqueIdentifier = 'Tierwohlprotokolle Rind';
        this.tableDescriptor.apiUrl =
            this.apiBaseUrl + 'tierwohlprotokoll_rind/';
        this.tableDescriptor.route = '/belege/tierwohlprotokoll_rind/';
        this.tableDescriptor.nameOfIdField = 'twp_rind_id';
        this.tableDescriptor.searchPlaceholder =
            'Tierwohlprotokolle Rind durchsuchen';

        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.showDetailsAsOption = true;

        this.tableDescriptor.columns.push(
            new TableColumn('twp_rind_id', 'ID')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('datum', 'Datum', 'date')
        );
        this.tableDescriptor.columns.push(new TableColumn('kunde', 'Kunde'));
        this.tableDescriptor.columns.push(
            new TableColumn(
                'tierarzt_nachname',
                'Tierarzt',
                'string',
                true,
                null,
                null,
                null,
                null,
                null,
                ['tierarzt_vorname', 'tierarzt_nachname']
            )
        );
        this.tableDescriptor.columns.push(new TableColumn('status', 'Status'));
        this.tableDescriptor.columns.push(new KieneBooleanTableColumn('abgabebeleg_id', 'Abgerechnet'));
        this.tableDescriptor.columns.push(new EmailGesendetTableColumn());
        this.tableDescriptor.columns.push(new GedrucktTableColumn());

        this.tableDescriptor.options.push(
            new TableOption('delete', 'Löschen', 'delete', 9313)
        );
        this.tableDescriptor.options.push(
            new TableOption('close', 'Abschließen', 'done', 9312, {
                conditions: [
                    new Condition('status_id', Operator.GLEICH, 153),
                ]
            })
        );
        this.tableDescriptor.options.push(new TableOption(null, 'Duplizieren', 'file_copy', 9316, { void: this.duplicate.bind(this) }));
        this.tableDescriptor.options.push(
            new TableOption(null, 'Drucken', 'print', 9315, { void: this.gedruckt.bind(this), conditions: [new Condition('pdf_id', Operator.EXISTIERT, null)] })
        );

        this.tableDescriptor.permissionView = 9311;
        this.tableDescriptor.permissionCreate = 9312;
        this.tableDescriptor.permissionUpdate = 9312;
        this.tableDescriptor.permissionDelete = 9313;

        this.tableDescriptor.initialSortColumn = 'twp_rind_id';
        this.tableDescriptor.initialSortDirection = 'desc';
    }

    gedruckt(twpr: TierwohlprotokollRind) {
        this.api.get(this.apiBaseUrl + 'tierwohlprotokoll_rind/gedruckt.php', new HttpParams().set('twp_rind_id', twpr.twp_rind_id)).subscribe({
            next: () => {
                this.api.reload('gedruckt');
                this.openPdf(twpr);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    duplicate(twpr: TierwohlprotokollRind) {
        this.api.post(this.apiBaseUrl + 'tierwohlprotokoll_rind/duplicate.php', twpr).subscribe({
            next: (response: TierwohlprotokollRind) => {
                this.api.reload('duplicate');
                this.openDetailsDialog(response);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    catchOptionEvent(event: TableOptionEvent) {
        const twpr = <TierwohlprotokollRind>event.value;
        if (event.name === 'details') {
            if (twpr.status_id === 154) {
                this.openPdf(twpr);
            } else {
                this.openDetailsDialog(twpr);
            }
        } else if (event.name === 'add') {
            this.openDetailsDialog();
        } else if (event.name === 'delete') {
            this.openDeleteDialog(twpr);
        } else if (event.name === 'close') {
            this.openConfirmDialog(twpr);
        }
    }

    openDeleteDialog(twpr: TierwohlprotokollRind) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Tierwohlprotokoll löschen?',
                text: 'Soll das Tierwohlprotokoll wirklich gelöscht werden?',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.api
                    .deleteEntity(
                        this.apiBaseUrl + 'tierwohlprotokoll_rind/delete.php',
                        twpr
                    )
                    .subscribe({
                        next: () => {
                            this.messageService.infoMessage(
                                'Das Tierwohlprotokoll wurde erfolgreich gelöscht!'
                            );
                            this.api.reload();
                        },
                        error: (error) => {
                            this.messageService.errorMessage(
                                'Das Tierwohlprotokoll konnte nicht gelöscht werden! ',
                                error
                            );
                        },
                    });
            }
        });
    }

    private openDetailsDialog(twpr?: TierwohlprotokollRind) {
        const dialogRef = this.dialog.open(
            TierwohlprotokollRindDetailsComponent,
            {
                width: '90vw',
                data: {
                    tierwohlprotokollRind: twpr,
                },
            }
        );

        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                this.api.reload();
            }
        });
    }

    private openPdf(twrp: TierwohlprotokollRind) {
        const input = new PdfViewerDialogInput(
            'tierwohlprotokoll_rind/read_or_mail_pdf.php',
            new HttpParams().set('twp_rind_id', twrp?.twp_rind_id?.toString()),
            'Tierwohlprotokoll Rind ' + twrp.twp_rind_id?.toString(),
            {
                email: {
                    sende_mail: true,
                    kunde_id: twrp?.kunde_id,
                }
            }
        );
        this.pdfService.openPdfViewer(input).subscribe({
            next: () => {
                this.api.reload();
            }
        });
    }

    private openConfirmDialog(twpr: TierwohlprotokollRind) {
        const input = new ConfirmDialogInput();
        input.headline = 'Tierwohlprotokoll Rind abschließen';
        input.text =
            'Soll das Tierwohlprotokoll Rind wirklich abgeschlossen werden? Durch das Abschließen wird eine PDF zu diesem Tierwohlprotokoll erzeugt.';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (resp: ConfirmDialogResult) => {
                if (resp?.confirmed) {
                    twpr.status_id = 154;
                    this.api
                        .updateEntity(
                            this.apiBaseUrl + 'tierwohlprotokoll_rind/update.php',
                            twpr
                        )
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Tierwohlprotokoll wurde erfolgreich abgeschlossen.'
                                );
                                this.api.reload();
                                this.openPdf(twpr);
                            },
                            error: (error) => {
                                this.messageService.errorMessage(
                                    'Fehler beim Aktualisieren des Tierwohlprotokolls!',
                                    error
                                );
                            },
                        });
                }
            },
        });
    }
}
