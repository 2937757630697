import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {
    ConditionSetting,
    ConditionTableColumn,
    DateTableColumn,
    NumberTableColumn,
    TableColumn,
} from '../../components/kiene-table/table-column';
import {TableDescriptor, ToolbarButton,} from '../../components/kiene-table/table-descriptor';
import {Condition, Operator, TableOption, TableOptionEvent,} from '../../components/kiene-table/table-option';
import {
    GruppeDialogComponent
} from '../../documents/gruppenverwaltung/gruppenuebersicht/gruppe-dialog/gruppe-dialog.component';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MessageService} from '../../services/message.service';
import {AppService} from '../../app.service';
import {GruppenService} from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import {Gruppe} from '../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import {
    ChangeCurrentClientDialogComponent
} from '../../administration/client/change-current-client/change-current-client-dialog/change-current-client-dialog.component';
import {
    EnterValueDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-dialog.component';
import {
    EnterValueInput,
    EnterValueResponse,
} from '../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-response';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {KieneSession} from '../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import {HttpParams} from '@angular/common/http';
import {
    HitPinAktualisierenDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/stammdaten/vvvo/hit-pin-aktualisieren-dialog/hit-pin-aktualisieren-dialog.component';
import {GruppeDialogInput} from '../../documents/gruppenverwaltung/gruppenuebersicht/gruppe-dialog/gruppe-dialog';
import {
    ConfirmDialogInput
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {
    ConfirmDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {Router} from '@angular/router';
import {SessionPojo} from './aktuelle-gruppen';
import {
    ChangeCurrentClientService
} from '../../administration/client/change-current-client/change-current-client.service';
import {FileDownloadService} from '../../services/file-download.service';
import {KieneUtils} from '../../kiene-utils';

@Component({
    selector: 'kiene-aktuelle-gruppen',
    templateUrl: './aktuelle-gruppen.component.html',
    styleUrls: ['./aktuelle-gruppen.component.scss'],
})
export class AktuelleGruppenComponent implements OnInit, OnDestroy {
    tableDescriptor = new TableDescriptor();
    watchClientSubscription: Subscription;
    kundeColumn: TableColumn;
    kundeZusatzColumn: TableColumn;

    currentUser: KieneSession;

    constructor(
        private dialog: MatDialog,
        private changeCurrentClientService: ChangeCurrentClientService,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private messageService: MessageService,
        private appService: AppService,
        private router: Router,
        private gruppenService: GruppenService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private fileDownloadService: FileDownloadService,
    ) {
    }

    ngOnDestroy() {
        this.watchClientSubscription.unsubscribe();
        this.tableService = null;
    }

    ngOnInit(): void {

        this.checkHitPinAktuell();
        this.checkStripe();

        this.currentUser = this.localStorageService.getSession();

        this.watchClientSubscription = this.localStorageService
            .watchCurrentClient()
            .subscribe((response) => {
                if (response) {
                    if (this.kundeColumn && this.kundeZusatzColumn) {
                        this.kundeColumn.isActive = false;
                        this.kundeZusatzColumn.isActive = false;
                        this.tableService.updateColumns();
                    }
                } else {
                    if (this.kundeColumn && this.kundeZusatzColumn) {
                        this.kundeColumn.isActive = true;
                        this.kundeZusatzColumn.isActive = true;
                        this.tableService.updateColumns();
                    }
                }
            });

        this.tableDescriptor.headline = 'Aktuelle Gruppen (Rinder)';
        this.tableDescriptor.headline = 'Aktuelle Gruppen (Rinder)';
        this.tableDescriptor.uniqueIdentifier = 'Aktuelle Gruppen';
        this.tableDescriptor.alternativeApiUrl =
            this.apiBaseUrl + 'gruppe/read_akt_kundenportal.php';
        this.tableDescriptor.params = new HttpParams().set('schwein', 0);
        this.tableDescriptor.initialSortDirection = 'desc';
        this.tableDescriptor.initialSortColumn = 'einstalldatum_von';
        this.tableDescriptor.nameOfIdField = 'gruppe_id';
        this.tableDescriptor.route = '/gruppen/aktuelle/';
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDownloads = true;
        this.tableDescriptor.sendCurrentClient = true;

        this.tableDescriptor.columns = this.getColumns();
        this.tableDescriptor.options = this.getOptions();
        this.tableDescriptor.toolbarButtons = this.getToolbarButtons();

        this.tableDescriptor.tooltips['add_button'] = 'Neue Gruppe anlegen';

        this.tableDescriptor.permissionCreate = 9112;
        this.tableDescriptor.permissionUpdate = 9112;

        this.tableDescriptor.totalRow = true;
        this.tableDescriptor.totalRowApiUrl = this.apiBaseUrl + 'gruppe/read_akt_kundenportal_summen.php';
    }

    getColumns(): TableColumn[] {
        const columns = [];
        columns.push(new TableColumn('bezeichnung', 'Gruppenname'));
        columns.push(new TableColumn('produktionsabschnitt', 'Abschnitt'));
        columns.push(new TableColumn('herdenname', 'Herdenname'));

        columns.push(new TableColumn('stall', 'Standort'));
        columns.push(new TableColumn('sgs_nummer', 'SGS Nr.'));

        this.kundeColumn = new TableColumn('kunde', 'Kunde');
        this.kundeZusatzColumn = new TableColumn('zusatz', 'Kunde Zusatz');
        if (this.localStorageService.getCurrentClient()) {
            this.kundeColumn.isActive = false;
            this.kundeZusatzColumn.isActive = false;
        }
        columns.push(this.kundeColumn);
        columns.push(this.kundeZusatzColumn);

        columns.push(
            new DateTableColumn(
                'einstalldatum_von',
                'Einstalldatum von',
                'dd.MM.yy'
            )
        );
        columns.push(
            new DateTableColumn(
                'einstalldatum_bis',
                'Einstalldatum bis',
                'dd.MM.yy'
            )
        );
        columns.push(
            new TableColumn('anzahl_tiere_gesamt', 'Eingestallte Tiere')
        );
        columns.push(
            new TableColumn('anzahl_tiere_aktuell', 'Anzahl Tiere aktuell')
        );
        columns.push(new TableColumn('alter_wochen', 'Tieralter in Wo.'));
        columns.push(new TableColumn('anzahl_tote', 'Tote'));
        columns.push(
            new NumberTableColumn('verluste', 'Verluste', '1.1-1', '%')
        );
        columns.push(new TableColumn('anzahl_abgaenge', 'Abgänge'));
        columns.push(new DateTableColumn('schlachtdatum_kalb', 'Schlachttermin', 'dd.MM.yyyy'));

        const conditionSettings = [
            new ConditionSetting(
                [new Condition('hit_status_id', Operator.IN, [142, 145])],
                {
                    color: '#479864',
                    tooltipFieldnames: ['hit_status_fehler']
                }
            ),
            new ConditionSetting(
                [new Condition('hit_status_id', Operator.IN, [141, 144])],
                {
                    color: '#f2c52d',
                    tooltipFieldnames: ['hit_status_fehler']
                }
            ),
            new ConditionSetting(
                [new Condition('hit_status_id', Operator.IN, [143])],
                {
                    color: '#d50000',
                    tooltipFieldnames: ['hit_status_fehler']
                }
            ),
        ];

        columns.push(
            new ConditionTableColumn('hit_status_id', 'HIT', conditionSettings, {
                sortFields: ['hit_status_id'],
                defaultIcon: 'cloud_sync',
                defaultColor: '#ddd',
            })
        );

        columns.push(
            new DateTableColumn(
                'synchronisiert',
                'HIT Sync.',
                'dd.MM.yy HH:mm',
                {alternativeField: 'hit_fehler'}
            )
        );
        return columns;
    }

    getOptions() {
        const options: TableOption[] = [];
        options.push(new TableOption('bearbeiten', 'Gruppenname bearbeiten', 'edit', 9117, {conditions: [new Condition('standard', Operator.GLEICH, '0')]}));
        options.push(
            new TableOption(
                'hit',
                'Tiere mit HIT synchronisieren',
                'cloud_sync',
                1912
            )
        );
        options.push(
            new TableOption(
                null,
                'Gruppe in Historie verschieben',
                'history_edu',
                9114,
                {
                    conditions: [new Condition('standard', Operator.GLEICH, '0')],
                    void: this.inHistorieVerschieben.bind(this)
                }
            )
        );
        return options;
    }

    getToolbarButtons(): ToolbarButton[] {
        const buttons = [];
        buttons.push(new ToolbarButton('hit_schlachtdaten', 'cloud_sync', 9109, 'Schlachtdaten aus HIT abfragen'));
        buttons.push(new ToolbarButton('bestand_zu_datum', 'event', 9109, 'Bestand zu Datum abfragen'));
        return buttons;
    }

    catchOptionEvent(event: TableOptionEvent) {
        const gruppe: Gruppe = event.value;
        if (event.name === 'add') {
            this.createGruppe();
        } else if (event.name === 'hit') {
            this.tiereAusHitNachladen(gruppe);
        } else if (event.name === 'bearbeiten') {
            this.bearbeiten(gruppe);
        } else if (event.name === 'hit_schlachtdaten') {
            this.schlachtdatenNachladen();
        } else if (event.name === 'bestand_zu_datum') {
            this.bestandZuDatumAbfragen();
        }
    }

    inHistorieVerschieben(gruppe: Gruppe) {
        const input = new ConfirmDialogInput();
        input.headline = 'Gruppe in Historie verschieben?';
        input.text = 'Gruppe in Historie verschieben?';
        input.okButton = 'Verschieben';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '500px',
            data: input
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.gruppenService.historieAendern(gruppe, true)
                    .subscribe(
                        {
                            next: () => {
                                this.messageService.infoMessage(
                                    'Gruppe erfolgreich in Historie verschoben!'
                                );
                                this.tableService?.reload();
                            },
                            error: err => {
                                this.messageService.errorMessage(
                                    'Gruppe konnte nicht in die Historie verschoben werden!',
                                    err
                                );
                            }
                        }
                    );
            }
        });
    }

    tiereAusHitNachladen(gruppe: Gruppe) {
        this.appService.setDataLoading(true);
        this.messageService.infoMessage('Tiere werden aus HIT geladen!');
        this.gruppenService.tiereAusHitNachladen(gruppe).subscribe({
            next: () => {
                this.messageService.infoMessage(
                    'Tiere erfolgreich aus HIT geladen!'
                );
                this.appService.setDataLoading(false);
                this.tableService?.reload();
            },
            error: (error) => {
                this.messageService.errorMessage(
                    'Tiere konnten nicht aus HIT nachgeladen werden!',
                    error
                );
                this.appService.setDataLoading(false);
            },
        });
    }

    createGruppe() {
        if (!this.localStorageService.getCurrentClient()) {
            this.dialog.open(
                ChangeCurrentClientDialogComponent,
                {
                    width: '50%',
                    data: {
                        closeOnSelect: true,
                    },
                }
            ).afterClosed().subscribe((result) => {
                if (result?.save && result?.client) {
                    this.localStorageService.setCurrentClient(result.client);
                    this.createGruppe();
                }
            });
        } else {
            const input = new GruppeDialogInput(this.localStorageService.getCurrentClient(), false, undefined);
            this.dialog.open<GruppeDialogComponent, GruppeDialogInput, Gruppe>(GruppeDialogComponent, {
                data: input
            }).afterClosed().subscribe({
                next: (gruppe: Gruppe) => {
                    if (gruppe) {
                        this.router.navigate(['/gruppen', 'aktuelle', gruppe.gruppe_id]).then();
                    } else {
                        this.tableService.reload();
                    }
                }
            });
        }
    }

    bearbeiten(gruppe: Gruppe) {
        const input = new EnterValueInput();
        input.title = 'Gruppennamen';
        input.text = 'Bitte geben Sie den Gruppennamen ein.';
        input.okButton = 'Speichern';
        input.buttonColor = 'accent';
        input.addInputValue('Gruppennamen', 'text', gruppe.bezeichnung);

        const dialogRef = this.dialog.open(EnterValueDialogComponent, {
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: EnterValueResponse) => {
                if (result?.result) {
                    gruppe.bezeichnung = result.getValue(0);
                    this.api
                        .updateGeneric(this.apiBaseUrl + 'gruppe/update.php', gruppe)
                        .subscribe({
                            next: (response) => {
                                this.messageService.infoMessage(
                                    'Der Gruppenname wurde erfolgreich gespeichert!'
                                );
                                this.tableService.reload(
                                    'aktuelle-gruppe: bearbeiten'
                                );
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    private schlachtdatenNachladen() {
        const client = this.localStorageService.getCurrentClient();
        if (!client) {
            this.messageService.alertMessage('Bitte zunächst oben einen Betrieb auswählen!');
            return;
        }

        this.appService.setDataLoading(true);
        this.messageService.infoMessage('Schlachtdaten werden aus HIT geladen!');
        this.gruppenService.schlachtdatenAusHitNachladen(client?.kunde_id).subscribe({
            next: () => {
                this.messageService.infoMessage(
                    'Schlachtdaten erfolgreich aus HIT geladen!'
                );
                this.appService.setDataLoading(false);
                this.tableService?.reload();
            },
            error: (error) => {
                this.messageService.errorMessage(
                    'Schlachtdaten konnten nicht aus HIT nachgeladen werden!',
                    error
                );
                this.appService.setDataLoading(false);
            },
        });
    }

    private checkStripe() {
        this.api.post(this.apiBaseUrl + 'stripe/check.php', undefined).subscribe({
            next: (response1) => {
                if (response1?.zahlungsartFehlt === true) {
                    const sessionPojo = new SessionPojo();
                    sessionPojo.returnUrl = window.location.href;
                    this.api.post(this.apiBaseUrl + 'stripe/session.php', sessionPojo).subscribe({
                        next: (response2) => {
                            if (response2?.portalUrl) {
                                const input = new ConfirmDialogInput();
                                input.headline = "Zahlungsmethode hinterlegen";
                                input.text = "Bitte hinterlegen Sie eine Zahlungsmethode für die (offenen) Rechnungen. Dazu werden Sie auf die Seite unseres Bezahlservices stripe weitergeleitet.";
                                input.showCancel = false;
                                this.dialog.open(ConfirmDialogComponent, {data: input}).afterClosed().subscribe({
                                    next: () => {
                                        window.open(response2.portalUrl).focus();
                                    }
                                });
                            }
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler!', err);
                        }
                    });
                }
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private checkHitPinAktuell() {
        if (this.localStorageService.currentUserHasPermission(9108)) {
            const params = new HttpParams().set('hit_pin_falsch', '1');
            this.api.get(this.apiBaseUrl + 'kunde/read_pin_falsch.php', params).subscribe({
                next: response => {
                    if (response?.records?.length > 0) {
                        return this.dialog.open(HitPinAktualisierenDialogComponent, {
                            data: response.records,
                            maxWidth: '500px',
                            disableClose: true,
                            closeOnNavigation: false
                        });
                    }
                }
            });
        }
    }

    private bestandZuDatumAbfragen() {
        const client = this.localStorageService.getCurrentClient();
        if (!client) {
            this.changeCurrentClientService.openChangeDialog('AktuelleGruppenComponent bestandZuDatumAbfragen()').subscribe({
                next: (client) => {
                    if (client) {
                        this.bestandZuDatumAbfragen();
                    }
                }
            });
        } else {

            const input = new EnterValueInput();
            input.title = 'Bestand zu Datum';
            input.text = 'Bitte geben Sie das Datum ein, zu dem der Bestand abgefragt werden soll.';
            input.okButton = 'Abfragen';
            input.addInputValue('Datum', 'date', new Date());
            this.dialog.open(EnterValueDialogComponent, {data: input}).afterClosed().subscribe({
                next: result => {
                    if (result) {
                        this.appService.setDataLoading(true);
                        this.messageService.infoMessage('Bestand wird abgefragt!');
                        const d = result.getValue(0);
                        const dateFormatted = KieneUtils.formatYYYY_MM_DD(d);
                        const params = new HttpParams().set('kunde_id', client.kunde_id).set('datum', dateFormatted);
                        this.fileDownloadService.downloadExcel('kunde/bestand.php', params).subscribe({
                            next: () => {
                                this.messageService.infoMessage('Bestand erfolgreich abgefragt!');
                                this.appService.setDataLoading(false);
                            },
                            error: err => {
                                this.messageService.errorMessage('Bestand konnte nicht abgefragt werden!', err);
                                this.appService.setDataLoading(false);
                            }
                        });

                    }
                }
            });
        }
    }
}
