export const environment = {
    SUB_TITLE: 'DEMO',
    production: false,
    beta: false,
    dev: true,
    VERSION: require('./versions.ts').version,
    COMMIT: require('./versions.ts').revision,
    BRANCH: require('./versions.ts').branch,
    BASE_URI: 'https://demo3.vetvet.de/api/',
    SIGNATURE_URL: 'https://demo3.vetvet.de/',
};
