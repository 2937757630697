export class Umbuchung {
    umbuchung_id: number;
    artikel: string;
    artikel_me: string;
    artikel_id: number;
    bestell_nr: number;
    bestellung_extern_id: number;
    charge: string;
    erstellt: Date;
    lagerplatz: string;
    menge: number;
    mhd: Date;
}
