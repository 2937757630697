import {Inject, Injectable, Optional} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Entsorger} from './entsorger/entsorger';
import {MatDialog} from '@angular/material/dialog';
import {Haendler} from './haendler/haendler';
import {HttpParams} from '@angular/common/http';
import {Tierreklamation} from '../gruppe-tier/classes/tierreklamation';
import {Client} from '../client/classes/client';
import {KieneApi, KieneBackendApiService} from '../../services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../kiene-core.config';
import {EnterValueInput, EnterValueResponse} from '../dialogs/enter-value-dialog/enter-value-response';
import {EnterValueDialogComponent} from '../dialogs/enter-value-dialog/enter-value-dialog.component';
import {MessageService} from '../../services/message-service/message.service';
import {LocalStorageService} from '../../services/local-storage/local-storage.service';
import {UserClient} from './benutzer/benutzer';
import {ConfirmDialogInput, ConfirmDialogResult} from '../dialogs/confirm-dialog/confirm-dialog';
import {ConfirmDialogComponent} from '../dialogs/confirm-dialog/confirm-dialog.component';
import {Futtermittellieferant} from './lieferant/lieferant';

@Injectable({
    providedIn: 'root',
})
export class StammdatenService {
    setLetzterAngelegterHaendler: Haendler;
    setLetzterAngelegterEntsorger: Entsorger;
    setLetzterAngelegterLieferant: Futtermittellieferant;

    haendlerFehlt = false;
    entsorgerFehlt = false;
    lieferantFehlt = false;
    haendlerFehltSubject: Subject<boolean> = new Subject<boolean>();
    entsorgerFehltSubject: Subject<boolean> = new Subject<boolean>();
    lieferantFehltSubject: Subject<boolean> = new Subject<boolean>();

    ausgewaehlteVvvoSubject: Subject<Client> = new Subject<Client>();
    vvvoAktualisiertSubject: Subject<Client> = new Subject<Client>();

    constructor(
        @Optional() private dialog: MatDialog,
        private api: KieneBackendApiService,
        private localStorageService: LocalStorageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private messageService: MessageService
    ) {
    }

    setHaendlerFehlt(b: boolean) {
        this.haendlerFehlt = b;
        this.haendlerFehltSubject.next(this.haendlerFehlt);
    }

    getHaendlerFehlt() {
        return this.haendlerFehlt;
    }

    watchHaendlerFehlt(): Observable<boolean> {
        return this.haendlerFehltSubject.asObservable();
    }

    setEntsorgerFehlt(b: boolean) {
        this.entsorgerFehlt = b;
        this.entsorgerFehltSubject.next(this.entsorgerFehlt);
    }

    getEntsorgerFehlt() {
        return this.entsorgerFehlt;
    }

    watchEntsorgerFehlt(): Observable<boolean> {
        return this.entsorgerFehltSubject.asObservable();
    }

    setLieferantFehlt(b: boolean) {
        this.lieferantFehlt = b;
        this.haendlerFehltSubject.next(this.lieferantFehlt);
    }

    getLieferantFehlt() {
        return this.lieferantFehlt;
    }

    watchLieferantFehlt(): Observable<boolean> {
        return this.lieferantFehltSubject.asObservable();
    }

    watchAusgewaehlteVvvo(): Observable<Client> {
        return this.ausgewaehlteVvvoSubject.asObservable();
    }

    setAusgewaehlteVvvo(client: Client) {
        this.localStorageService.setCurrentClient(client);
        this.ausgewaehlteVvvoSubject.next(client);
    }

    getAusgewaehlteVvvo(): Client {
        return this.localStorageService.getCurrentClient();
    }

    watchAktualisierteVvvo(): Observable<Client> {
        return this.vvvoAktualisiertSubject.asObservable();
    }

    setAktualisierteVvvo(client: Client) {
        this.vvvoAktualisiertSubject.next(client);
    }

    loadEntsorger(): Observable<any> {
        return this.api.getAll(
            `${this.apiBaseUrl}entsorger/read_kundenportal.php`
        );
    }

    ladeFuttermittellieferanten(): Observable<any> {
        return this.api.getAll(
            `${this.apiBaseUrl}futtermittellieferant/read_all.php`
        );
    }

    ladeFuttermittellieferantenFavoriten(): Observable<any> {
        return this.api.getAll(
            `${this.apiBaseUrl}futtermittellieferant/read_favoriten.php`
        );
    }

    loadTodesarten(): Observable<any> {
        return this.api.getAll(`${this.apiBaseUrl}todesart/read.php`);
    }

    ladeVvvos(params?: HttpParams): Observable<KieneApi> {
        return this.api.getAll(
            `${this.apiBaseUrl}kunde/read_kundenportal.php`, params
        );
    }

    ladeZugewieseneKunden(benutzerId: number) {
        return this.api.getAll(
            this.apiBaseUrl + 'kunde/read_benutzer.php?benutzer_id=' + benutzerId
        );
    }

    kundeBenutzerZuweisen(benutzerId: number, kundeId: number) {
        const userClient: UserClient = new UserClient();
        userClient.benutzer_id = benutzerId;
        userClient.kunde_id = kundeId;
        return this.api.post(this.apiBaseUrl + 'benutzer_kunde/create.php', userClient);
    }

    kundeBenutzerEntfernen(benutzerId: number, kundeId: number) {
        const obj = {
            benutzer_id: benutzerId,
            kunde_id: kundeId
        };
        return this.api.post(this.apiBaseUrl + 'benutzer_kunde/delete.php', obj);
    }

    loadEntsorgerFavoriten(): Observable<KieneApi> {
        let params = new HttpParams().set('favorit', '1');
        params = params.set('orderby', 'letzte_entsorgung desc');
        return this.api.getAll(
            `${this.apiBaseUrl}entsorger/read_kundenportal.php`,
            params
        );
    }

    loadHaendlerFavoriten() {
        const params = new HttpParams().set('favorit', '1');
        return this.api.getAll(
            `${this.apiBaseUrl}viehhaendler/read_kundenportal.php`,
            params
        );
    }

    updateHaendlerFavorit(haendler: Haendler) {
        return this.api.updateGeneric(
            `${this.apiBaseUrl}viehhaendler/favorit.php`,
            haendler
        );
    }

    updateEntsorgerFavorit(entsorger: Entsorger) {
        return this.api.updateGeneric(
            `${this.apiBaseUrl}entsorger/favorit.php`,
            entsorger
        );
    }

    updateFuttermittellieferantFavorit(futtermittellieferant: Futtermittellieferant) {
        return this.api.updateGeneric(
            `${this.apiBaseUrl}futtermittellieferant/favorit.php`,
            futtermittellieferant
        );
    }

    deleteHaendlerFavorit(haendler: Haendler) {
        haendler.favorit = 0;
        return this.api.updateGeneric(
            `${this.apiBaseUrl}viehhaendler/favorit.php`,
            haendler
        );
    }

    deleteEntsorgerFavorit(entsorger: Entsorger) {
        entsorger.favorit = 0;
        return this.api.updateGeneric(
            `${this.apiBaseUrl}entsorger/favorit.php`,
            entsorger
        );
    }

    loadHaendler(): Observable<any> {
        return this.api.getAll(
            `${this.apiBaseUrl}viehhaendler/read_kundenportal.php`
        );
    }

    updateEntsorger(entsorger: Entsorger) {
        return this.api.updateGeneric(
            `${this.apiBaseUrl}entsorger/update_kundenportal.php`,
            entsorger
        );
    }

    createHaendler(haendler: Haendler) {
        return this.api.post(
            `${this.apiBaseUrl}viehhaendler/create_kundenportal.php`,
            haendler,
            null
        );
    }

    updateHaendler(haendler: Haendler) {
        return this.api.updateGeneric(
            `${this.apiBaseUrl}viehhaendler/update_kundenportal.php`,
            haendler
        );
    }

    createOrUpdateFuttermittellieferant(futtermittellieferant: Futtermittellieferant) {
        return this.api.updateGeneric(
            `${this.apiBaseUrl}futtermittellieferant/update.php`,
            futtermittellieferant
        );
    }

    getEntsorger(id: any): Observable<any> {
        return this.api.getByIdAlternative(
            `${this.apiBaseUrl}entsorger/read_one_kundenportal.php`,
            'entsorger_id',
            id
        );
    }

    getHaendler(id: any): Observable<any> {
        return this.api.getByIdAlternative(
            `${this.apiBaseUrl}viehhaendler/read_one_kundenportal.php`,
            'viehhaendler_id',
            id
        );
    }

    getFuttermittellieferant(futtermittellieferant_id: number): Observable<any> {
        let params = new HttpParams();
        params = params.set('futtermittellieferant_id', futtermittellieferant_id);
        return this.api.get(
            `${this.apiBaseUrl}futtermittellieferant/read_one_kundenportal.php`,
            params
        );
    }

    getKunde(kunde_id: any) {
        return this.api.getByIdAlternative(
            `${this.apiBaseUrl}kunde/read_one_kundenportal.php`,
            'kunde_id',
            kunde_id
        );
    }

    saveKunde(kunde: Client): Observable<Client> {
        return this.api.updateGeneric(
            `${this.apiBaseUrl}kunde/update_kundenportal.php`,
            kunde
        );
    }

    /**
     * speichert einen neuen Haendler im Service
     * @param haendler
     */
    speichereLetzenAngelegtenHaendler(haendler: Haendler) {
        this.setLetzterAngelegterHaendler = haendler;
    }

    /**
     * speichert einen neuen Haendler im Service
     * @param lieferant
     */
    speichereLetzenAngelegtenFuttermittellieferant(futtermittellieferant: Futtermittellieferant) {
        this.setLetzterAngelegterLieferant = futtermittellieferant;
    }

    /**
     * Liefert den letzten angelegten Haendler zurück und löscht den Wert aus dem Service
     */
    ladeLetztenAngelegtenHaendler() {
        const haendler = this.setLetzterAngelegterHaendler;
        this.setLetzterAngelegterHaendler = null;
        return haendler;
    }

    /**
     * Liefert den letzten angelegten Haendler zurück und löscht den Wert aus dem Service
     */
    ladeLetztenAngelegtenLieferanten() {
        const lieferant = this.setLetzterAngelegterLieferant;
        this.setLetzterAngelegterLieferant = null;
        return lieferant;
    }

    /**
     * speichert einen neuen Entsorger im Service
     * @param entsorger
     */
    speichereLetzenAngelegtenEntsorger(entsorger: Entsorger) {
        this.setLetzterAngelegterEntsorger = entsorger;
    }

    /**
     * Liefert den letzten angelegten Entsorger zurück und löscht den Wert aus dem Service
     */
    ladeLetztenAngelegtenEntsorger() {
        const entsorger = this.setLetzterAngelegterEntsorger;
        this.setLetzterAngelegterEntsorger = null;
        return entsorger;
    }

    hitPinAendern(kundeId: number, pin: string): Observable<any> {
        return this.api.post(
            `${this.apiBaseUrl}kunde/update_hit_pin_kundenportal.php`,
            {kunde_id: kundeId, pin: pin},
            null
        );
    }

    // Tierreklamationen

    updateTierreklamation(tierreklamation: Tierreklamation): Observable<any> {
        return this.api.updateGeneric(
            `${this.apiBaseUrl}tierreklamation/update_kundenportal.php`,
            tierreklamation
        );
    }

    getTierreklamation(id: number): Observable<any> {
        return this.api.getByIdAlternative(
            `${this.apiBaseUrl}tierreklamation/read_one_kundenportal.php`,
            'tierreklamation_id',
            id.toString()
        );
    }

    reklamationenTeilen() {
        const input = new EnterValueInput();
        input.title = 'Reklamationen freigeben?';
        input.text =
            'Die Email-Adresse des Benutzers angeben, für den der lesende Zugriff auf die Reklamationen erlaubt werden soll.';
        input.okButton = 'freigeben';
        input.buttonColor = 'accent';
        input.addInputValue('Email Adresse', 'text');

        const dialogRef = this.dialog.open(EnterValueDialogComponent, {
            data: input,
            position: {top: '10px'},
        });
        dialogRef.afterClosed().subscribe({
            next: (res: EnterValueResponse) => {
                if (res?.result === true) {
                    this.api
                        .post(
                            this.apiBaseUrl + 'tierreklamation/teilen.php',
                            {email: res.getValue(0)},
                            null
                        )
                        .subscribe({
                            next: (response) => {
                                this.messageService.infoMessage(
                                    'Die Reklamationen wurden erfolgreich freigegeben!'
                                );
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler bei Freigabe!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    addTierreklamation(reklamation: string): Observable<any> {
        const tierreklamation = new Tierreklamation();
        tierreklamation.reklamation = reklamation;
        return this.updateTierreklamation(tierreklamation);
    }

    deleteTierreklamation(tierreklamation: Tierreklamation): Observable<any> {
        return this.api.deleteGeneric(
            `${this.apiBaseUrl}tierreklamation/delete_kundenportal.php`,
            tierreklamation
        );
    }

    getTierreklamationen(inklFreigegebene: boolean): Observable<any> {
        const params = new HttpParams().set(
            'freigegeben',
            inklFreigegebene ? '1' : '0'
        );
        return this.api.getAll(
            `${this.apiBaseUrl}tierreklamation/read_kundenportal.php`,
            params
        );
    }

    loadBetriebsarten(): Observable<any> {
        return this.api.getAll(
            `${this.apiBaseUrl}betriebsart/read_kundenportal.php`
        );
    }

    ladeAbteile(gruppe_id: number) {
        const params = new HttpParams().set('gruppe_id', gruppe_id?.toString());
        return this.api.getAll(
            `${this.apiBaseUrl}gruppe_tier/read_gruppe_abteil.php`,
            params
        );
    }

    ladeTierreklamationen(gruppe_id: number) {
        const params = new HttpParams().set('gruppe_id', gruppe_id.toString());
        return this.api.getAll(
            `${this.apiBaseUrl}gruppe_tier/read_gruppe_reklamation.php`,
            params
        );
    }

    ladeGeschlechter(gruppe_id: number) {
        const params = new HttpParams().set('gruppe_id', gruppe_id.toString());
        return this.api.getAll(
            `${this.apiBaseUrl}gruppe_tier/read_gruppe_geschlecht.php`,
            params
        );
    }

    ladeRassen(gruppe_id: number) {
        const params = new HttpParams().set('gruppe_id', gruppe_id.toString());
        return this.api.getAll(
            `${this.apiBaseUrl}gruppe_tier/read_gruppe_rasse.php`,
            params
        );
    }

    ladeTranspondernummern(gruppe_id: number) {
        const params = new HttpParams().set('gruppe_id', gruppe_id.toString());
        return this.api.getAll(
            `${this.apiBaseUrl}gruppe_tier/read_gruppe_transpondernummer.php`,
            params
        );
    }

    ladeViehhaendler(gruppe_id: number, params?: HttpParams) {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('gruppe_id', gruppe_id.toString());
        return this.api.getAll(
            `${this.apiBaseUrl}gruppe_tier/read_gruppe_viehhaendler.php`,
            params
        );
    }

    ladeEinstalldaten(gruppe_id: number, params) {
        if (!params) {
            params = new HttpParams();
        }
        params = params.set('gruppe_id', gruppe_id.toString());
        return this.api.getAll(
            `${this.apiBaseUrl}gruppe_tier/read_gruppe_einstalldatum.php`,
            params
        );
    }

    removeVVVO(kunde: Client): Observable<boolean> {
        const subject = new Subject<boolean>();
        const input = new ConfirmDialogInput();
        input.headline = 'VVVO entfernen';
        input.text =
            'Wollen Sie wirklich die VVVO ' +
            kunde?.vvvo +
            ' aus Ihrer Benutzerkonfiguration entfernen? Sie können jederzeit die VVVVO wieder hinzufügen!';
        input.okButton = 'Entfernen';
        input.buttonColor = 'warn';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    const bk = {
                        kunde_id: kunde?.kunde_id,
                        benutzer_id:
                        this.localStorageService.getCurrentUser()
                            .benutzer_id,
                    };
                    this.api
                        .deleteGeneric(
                            this.apiBaseUrl +
                            'benutzer_kunde/delete_kundenportal.php',
                            bk
                        )
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Die VVVO wurde erfolgreich entfernt!'
                                );
                                subject.next(true);
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                                subject.next(false);
                            },
                        });
                } else {
                    subject.next(false);
                }
            },
        });
        return subject;
    }

}
