export class Stock {
    lc_id: number;
    bezeichnung: string;
    lager: string;
    artikel_id: number;
    artikel: string;
    artikel_me: string;
    charge: string;
    mhd: Date;
    menge: number;
    erstellt: Date;
    editiert: Date;
    tierarzt_id: number;
  }
  
  export interface StockApi {
    records: Stock[];
    count: number;
  }