<div class="container">
    <h1 style="font-size: 16px;">Unterschrift</h1>
    <p style="font-size: 12px;">Bitte unterschreiben Sie bei Ihrem Namen und drücken Sie anschließend den <i>Signatur
        Speichern</i> Knopf.</p>
    <mat-tab-group (selectedTabChange)="tabChanged($event)">
        <mat-tab *ngIf="signature?.tierarzt_id && signature.unterschrift_tierarzt_vorhanden === 0"
                 [label]="signature.tierarzt_name">
            <div *ngIf="!signatureVetSet" style="margin-top: 12px;">
                <kiene-signature-panel #panelVet (saved)="saveVetSignature($event)"></kiene-signature-panel>
            </div>
            <img *ngIf="signatureVetSet" [src]="signature.tierarzt_png" style="max-width: 900px;">
        </mat-tab>
        <mat-tab *ngIf="signature?.kunde_id" [label]="signature.kunde">
            <div *ngIf="!signatureCustomerSet" style="margin-top: 12px;">
                <kiene-signature-panel #panelCustomer (saved)="saveCustomerSignature($event)"></kiene-signature-panel>
            </div>
            <img *ngIf="signatureCustomerSet" [src]="signature.kunde_png" style="max-width: 900px;">
        </mat-tab>
        <mat-tab *ngIf="signature?.benutzer_id" [label]="signature.benutzer_name">
            <div *ngIf="!signatureUserSet" style="margin-top: 12px">
                <kiene-signature-panel #panelUser (saved)="saveUserSignature($event)"></kiene-signature-panel>
            </div>
            <img *ngIf="signatureUserSet" [src]="signature.benutzer_png" style="max-width: 900px;">
        </mat-tab>
    </mat-tab-group>
    <button mat-flat-button color="accent" (click)="close()">
        Fenster schlie&szlig;en
    </button>
</div>
