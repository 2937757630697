import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Entsorger } from '../../entsorger/entsorger';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { KienePatternUtils } from '../../../../utils/KienePatternUtils';
import { StammdatenService } from '../../stammdaten.service';
import { MessageService } from '../../../../services/message-service/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Haendler } from '../haendler';
import { ConfirmDialogComponent } from '../../../dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { KieneAutocompleteComponent } from '../../../kiene-autocomplete/kiene-autocomplete.component';
import {
    DetachedRouteHandlesService
} from '../../../../configuration/route-caching-strategy/detached-route-handles.service';
import { RegistrierungService } from '../../../registrierung/registrierung.service';
import { Subscription } from 'rxjs';
import { ConfirmDialogInput, ConfirmDialogResult } from '../../../dialogs/confirm-dialog/confirm-dialog';
import { API_BASE_URL_SERVICE } from '../../../../kiene-core.config';

@Component({
    selector: 'kiene-haendler-details',
    templateUrl: './haendler-details.component.html',
    styleUrls: ['./haendler-details.component.scss'],
})
export class HaendlerDetailsComponent implements OnInit, OnDestroy {

    @Input('isDialog') isDialog = false;

    haendlerSubscription: Subscription;
    isLoading = false;

    haendlerPath = `${this.apiBaseUrl}viehhaendler/read_kundenportal.php?favorit=0`;

    @ViewChild('nameAutocomplete') nameAutocomplete: KieneAutocompleteComponent;

    @Input('haendler')
    haendler: Haendler;
    buttonText = 'Speichern';
    backRoute = '../../';
    headline = 'Viehhändler / Kontakt';

    @Input('redirectBack')
    redirectBack = true;
    redirectTo: string;
    ersteinrichtung = false;

    @Output('saved')
    saved: EventEmitter<Entsorger> = new EventEmitter<Entsorger>();

    nameCtrl = new UntypedFormControl(null, [Validators.required]);
    emailCtrl = new UntypedFormControl(null);
    strasseCtrl = new UntypedFormControl(null);
    plzCtrl = new UntypedFormControl(null);
    ortCtrl = new UntypedFormControl(null);
    telefonCtrl = new UntypedFormControl(null);
    mobilCtrl = new UntypedFormControl(null);
    vvvoCtrl = new UntypedFormControl(null, [
        Validators.pattern(KienePatternUtils.VVVO()),
    ]);
    faxCtrl = new UntypedFormControl(null);

    haendlerGroup = new UntypedFormGroup({
        name: this.nameCtrl,
        email: this.emailCtrl,
        strasse: this.strasseCtrl,
        plz: this.plzCtrl,
        ort: this.ortCtrl,
        telefon: this.telefonCtrl,
        mobil: this.mobilCtrl,
        vvvo: this.vvvoCtrl,
        fax: this.faxCtrl,
    });

    constructor(
        private stammdatenService: StammdatenService,
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute,
        private matDialog: MatDialog,
        private registrierungService: RegistrierungService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnDestroy(): void {
        this.haendlerSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        if (this.isDialog && this.haendler) {
            this.setup(this.haendler.viehhaendler_id);
        } else {
            this.route.queryParams.subscribe((params) => {
                if (params.ersteinrichtung) {
                    this.redirectTo = '../';
                    this.backRoute = this.redirectTo;
                }
            });

            this.route.params.subscribe((params) => {
                this.setup(params.id);
            });
        }
    }

    setup(haendler_id: number) {
        if (haendler_id) {
            this.buttonText = 'Aktualisieren';
            this.loadHaendler(haendler_id);
        } else {
            this.headline = 'Viehhändler / Kontakt anlegen';
            this.buttonText = 'Speichern';
            this.haendler = new Haendler();
        }
    }

    loadHaendler(haendler_id: number) {
        this.haendlerSubscription?.unsubscribe();
        this.haendlerSubscription = this.stammdatenService
            .getHaendler(haendler_id)
            .subscribe({
                next: (haendler) => {
                    this.headline =
                        'Viehhändler / Kontakt aktualisieren';
                    this.haendler = haendler;
                    this.haendlerGroup.patchValue(this.haendler);
                },
            });
    }

    setHaendlerInCtrls() {
        this.nameCtrl.setValue(this.haendler.name);
        this.emailCtrl.setValue(this.haendler.email);
        this.strasseCtrl.setValue(this.haendler.strasse);
        this.plzCtrl.setValue(this.haendler.plz);
        this.ortCtrl.setValue(this.haendler.ort);
        this.telefonCtrl.setValue(this.haendler.telefon);
        this.mobilCtrl.setValue(this.haendler.mobil);
        this.vvvoCtrl.setValue(this.haendler.vvvo);
        this.faxCtrl.setValue(this.haendler.fax);
    }

    setCtrlsInHaendler() {
        this.haendler.name = this.nameCtrl.value;
        this.haendler.email = this.emailCtrl.value;
        this.haendler.strasse = this.strasseCtrl.value;
        this.haendler.plz = this.plzCtrl.value;
        this.haendler.ort = this.ortCtrl.value;
        this.haendler.telefon = this.telefonCtrl.value;
        this.haendler.mobil = this.mobilCtrl.value;
        this.haendler.vvvo = this.vvvoCtrl.value;
        this.haendler.fax = this.faxCtrl.value;
    }

    resetCtrls() {
        this.nameCtrl.reset();
        this.emailCtrl.reset();
        this.strasseCtrl.reset();
        this.plzCtrl.reset();
        this.ortCtrl.reset();
        this.telefonCtrl.reset();
        this.mobilCtrl.reset();
        this.vvvoCtrl.reset();
        this.faxCtrl.reset();
    }

    redirect() {
        if (this.redirectBack) {
            if (this.redirectTo) {
                this.router
                    .navigate([this.redirectTo], { relativeTo: this.route })
                    .then();
            } else {
                window.history.back();
            }
        }
    }

    addOrUpdateHaendler() {
        if (this.isValid()) {
            this.setCtrlsInHaendler();
            if (this.haendler.viehhaendler_id) {
                this.isLoading = true;
                this.stammdatenService.updateHaendler(this.haendler).subscribe({
                    next: (haendler) => {
                        this.messageService.infoMessage(
                            'Der Viehhändler / Kontakt wurde erfolgreich aktualisiert.'
                        );
                        this.saved.emit(haendler);
                        this.registrierungService.addHaendlerErsteinrichtung();
                        this.stammdatenService.speichereLetzenAngelegtenHaendler(
                            haendler
                        );
                        this.resetCtrls();
                        this.isLoading = false;
                        if (!this.isDialog) {
                            this.redirect();
                        }
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Beim Aktualisieren des Viehhändlers / Kontakts ist ein Fehler aufgetreten.',
                            err
                        );
                        this.isLoading = false;
                    },
                });
            } else {
                this.isLoading = true;
                this.stammdatenService.createHaendler(this.haendler).subscribe({
                    next: (haendler) => {
                        this.messageService.infoMessage(
                            'Der Viehhändler / Kontakt wurde erfolgreich hinzugefügt.'
                        );
                        this.saved.emit(haendler);
                        this.registrierungService.addHaendlerErsteinrichtung();
                        this.stammdatenService.speichereLetzenAngelegtenHaendler(
                            haendler
                        );
                        this.resetCtrls();
                        this.isLoading = false;
                        if (!this.isDialog) {
                            this.redirect();
                        }
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Beim Hinzufügen des Viehhändlers / Kontakts ist ein Fehler aufgetreten.',
                            err
                        );
                        this.isLoading = false;
                    },
                });
            }
        }
    }

    selectedHaendler(element: any) {
        if (element) {
            if (typeof element === 'string') {
                this.nameCtrl.setValue(element);
            } else {
                this.haendler = element;
                this.setHaendlerInCtrls();
            }
        }
    }

    deleteHaendlerFavorit(event: MouseEvent) {
        event.stopPropagation();

        const input = new ConfirmDialogInput();
        input.headline = 'Entsorger löschen';
        input.text = `Möchten Sie den Entsorger ${this.haendler.name} wirklich löschen?`;
        input.okButton = 'Löschen';

        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.stammdatenService
                        .deleteHaendlerFavorit(this.haendler)
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Der Entsorger wurde erfolgreich gelöscht.'
                                );
                                this.redirect();
                            },
                            error: (err: any) => {
                                this.messageService.errorMessage(
                                    'Fehler beim Löschen des Entsorgerfavoriten',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    private isValid() {
        this.vvvoCtrl.markAsTouched();
        this.nameCtrl.markAsTouched();

        this.nameCtrl.value
            ? this.nameAutocomplete.formControl.setErrors(null)
            : this.nameAutocomplete.setError('required');

        return !this.nameAutocomplete.formControl.hasError('required');
    }
}
