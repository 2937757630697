<h1 mat-dialog-title>
    Tier HIT-Meldung
</h1>
<div mat-dialog-content>
    <mat-form-field class="no-padding-margin-mat-form-field" style="width: 100%;">
        <mat-label>Einstalldatum wählen</mat-label>
        <input matInput [formControl]="einstallDatumCtrl" [matDatepicker]="picker" [readonly]="true">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error class="input-error" *ngIf="einstallDatumCtrl.hasError('required')">
            Das Einstalldatum istein Pflichtfeld.
        </mat-error>
    </mat-form-field>

    <kiene-autocomplete style=" width: 100%" [showClear]="false" [panelWidth]="'70%'" [showDeleteButton]="false"
        [genericApiUrl]="haendlerPath" [displayField]="'name'" [multiLine]="true" [params]="viehhaendlerParams"
        [placeholder]="'Viehhändler (optional)'" [emitFreeText]="true" [focus]="false"
        (elementSelected)="viehhaendler = $event">
    </kiene-autocomplete>
</div>

<div mat-dialog-actions>
    <button mat-flat-button (click)="abort()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="melden()">Melden</button>
</div>
