import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GruppeTier } from '../classes/gruppe-tier';
import { MessageService } from '../../../services/message-service/message.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Haendler } from '../../stammdaten/haendler/haendler';
import { StammdatenService } from '../../stammdaten/stammdaten.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import {
    HaendlerDetailsDialogComponent
} from '../../stammdaten/haendler/haendler-details-dialog/haendler-details-dialog.component';
import { GruppeTierService } from '../gruppe-tier.service';

@Component({
    selector: 'kiene-gruppe-tier-bearbeiten',
    templateUrl: './gruppe-tier-bearbeiten.component.html',
    styleUrls: ['./gruppe-tier-bearbeiten.component.scss']
})
export class GruppeTierBearbeitenComponent implements OnInit, OnDestroy {

    @Input('dialog') dialog = false;
    @Input('tier') gruppeTier: GruppeTier;
    @Output('saved') saved: EventEmitter<boolean> = new EventEmitter();


    haendlerFavoritenSubscription: Subscription;
    gruppeTierSubscription: Subscription;


    // ctrls
    abteilCtrl: UntypedFormControl = new UntypedFormControl(null);
    haendlerCtrl: UntypedFormControl = new UntypedFormControl();
    einstalldatumCtrl: UntypedFormControl = new UntypedFormControl(null, Validators.required);
    abgangsdatumCtrl: UntypedFormControl = new UntypedFormControl(null);
    haendlerFavoriten: Haendler[] = [];
    emptyListHaendler = false;
    isLoading = false;

    changedEinstallDatum = false;
    changedAbgangsdatum = false;

    constructor(private router: Router, private route: ActivatedRoute, private gruppeTierService: GruppeTierService,
        private messageService: MessageService, private stammdatenService: StammdatenService,
        private matDialog: MatDialog) { }

    ngOnInit(): void {
        if (this.gruppeTier) {
            this.abteilCtrl.setValue(this.gruppeTier.abteil);
            this.einstalldatumCtrl.setValue(this.gruppeTier.einstalldatum_notnull);

            this.gruppeTier.abgangsdatum_notnull ? this.abgangsdatumCtrl.setValue(this.gruppeTier.abgangsdatum_notnull) :
                this.abgangsdatumCtrl.disable();
            this.ladeHaendlerFavoriten();
        } else {
            this.route.params.subscribe(params => {
                if (params.gt_id) {
                    this.loadGruppeTier(params.gt_id);
                }
            });
        }
        this.einstalldatumCtrl.valueChanges.subscribe(() => this.changedEinstallDatum = true);
        this.abgangsdatumCtrl.valueChanges.subscribe(() => this.changedAbgangsdatum = true);
    }

    ngOnDestroy(): void {
        this.gruppeTierSubscription?.unsubscribe();
        this.haendlerFavoritenSubscription?.unsubscribe();
    }

    private loadGruppeTier(gt_id: any) {
        this.gruppeTierSubscription?.unsubscribe();
        this.gruppeTierSubscription = this.gruppeTierService.ladeTier(gt_id).subscribe({
            next: resp => {
                this.gruppeTier = resp;
                this.abteilCtrl.setValue(this.gruppeTier.abteil);
                this.einstalldatumCtrl.setValue(this.gruppeTier.einstalldatum_notnull);
                this.abgangsdatumCtrl.setValue(this.gruppeTier.abgangsdatum_notnull);
                this.ladeHaendlerFavoriten();
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Laden des Tieres!', err);
            }
        });
    }

    isValid() {
        this.einstalldatumCtrl.markAsTouched();
        return this.einstalldatumCtrl?.value && moment(this.einstalldatumCtrl?.value).isValid();
    }

    updateGruppeTier() {
        if (!this.isValid()) {
            return;
        }
        this.changedAbgangsdatum =
            this.changedAbgangsdatum &&
            this.gruppeTier.abgangsdatum_notnull &&
            !moment().isSame(moment(this.abgangsdatumCtrl?.value) && moment(this.gruppeTier?.abgangsdatum_notnull));

        this.changedEinstallDatum =
            this.changedEinstallDatum &&
            !moment().isSame(moment(this.einstalldatumCtrl?.value) && moment(this.gruppeTier?.einstalldatum_notnull));
        console.log('COMP: ', moment(this.einstalldatumCtrl?.value).toDate(), moment(this.gruppeTier?.einstalldatum_notnull).toDate());

        this.gruppeTier.abteil = this.abteilCtrl.value;
        this.gruppeTier.einstalldatum_notnull = this.einstalldatumCtrl?.value;
        this.gruppeTier.abgangsdatum_notnull = this.abgangsdatumCtrl?.value;
        this.gruppeTier.viehhaendler_id = this.haendlerCtrl.value?.viehhaendler_id;



        this.isLoading = true;
        this.gruppeTierService.update(this.gruppeTier).subscribe({
            next: resp => {
                this.isLoading = false;
                this.gruppeTier = resp;
                if (!(this.changedAbgangsdatum || this.changedEinstallDatum)) {
                    this.messageService.infoMessage('Tierdaten wurden erfolgreich aktualisiert.');
                }

                if (this.changedAbgangsdatum) {
                    this.hitMeldungAbgangsdatum();
                }
                if (this.changedEinstallDatum) {
                    this.hitMeldungEinstalldatum();
                }

                this.dialog ? this.saved.emit() : this.router.navigate(['../'], { relativeTo: this.route }).then();
            },
            error: err => {
                this.isLoading = false;
                this.messageService.errorMessage('Fehler beim Speichern des Tieres!', err);
            }
        });
    }

    hitMeldungAbgangsdatum() {
        this.isLoading = true;
        this.gruppeTierService.abgangsdatumHitAendern([this.gruppeTier], this.abgangsdatumCtrl.value).subscribe({
            next: () => {
                this.isLoading = false;
                this.messageService.infoMessage(
                    'Das Abgangsdatum wurde erfolgreich geändert! Die Aktualisierung in der HIT Datenbank kann bis zu fünf Minuten dauern.'
                );
            },
            error: err => {
                this.isLoading = false;
                this.messageService.errorMessage('Fehler beim Melden des Abgangsdatums an HIT!', err);
            }
        });
    }

    hitMeldungEinstalldatum() {
        this.isLoading = true;
        this.gruppeTierService.einstalldatumHitAendern([this.gruppeTier], this.einstalldatumCtrl.value).subscribe({
            next: () => {
                this.isLoading = false;
                this.messageService.infoMessage(
                    'Das Einstalldatum wurde erfolgreich geändert! Die Aktualisierung in der HIT Datenbank kann bis zu fünf Minuten dauern.'
                );
            },
            error: err => {
                this.isLoading = false;
                this.messageService.errorMessage('Fehler beim Melden des Einstalldatums an HIT!', err);
            }
        });

    }

    openHaendlerVerwaltung() {
        if (this.dialog) {
            const dialogRef = this.matDialog.open(HaendlerDetailsDialogComponent, {
                width: '90vw',
                minWidth: '90vw',
            });
            dialogRef.afterClosed().subscribe(res => {
                if (res) {
                    this.haendlerCtrl.setValue(res);
                    this.ladeHaendlerFavoriten();
                }
            });
        } else {
            this.router.navigate(['./viehhaendler/neu'], { relativeTo: this.route }).then();
        }
    }

    compareHaendler(h1: Haendler, h2: Haendler) {
        return h1 && h2 && h1.viehhaendler_id === h2.viehhaendler_id;
    }

    ladeHaendlerFavoriten() {
        this.haendlerFavoritenSubscription?.unsubscribe();
        this.haendlerFavoritenSubscription = this.stammdatenService
            .loadHaendlerFavoriten()
            .subscribe({
                next: (haendlerFavoriten) => {
                    this.haendlerFavoriten = haendlerFavoriten.records;

                    if (this.gruppeTier.viehhaendler_id && !this.haendlerCtrl.value) {
                        this.haendlerCtrl.setValue(this.haendlerFavoriten.find(h => h.viehhaendler_id === this.gruppeTier.viehhaendler_id));
                    }

                    if (this.haendlerFavoriten.length === 0) {
                        this.emptyListHaendler = true;
                    }
                },
                error: err => {
                    this.messageService.errorMessage('Fehler beim Laden der Händlerfavoriten!', err);
                }
            });
    }
}
