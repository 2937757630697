<div class="one-row option-button">
  <mat-icon *ngIf="icon">{{icon}}</mat-icon>
  <div (click)="clickedText()">{{text}}</div>
  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Option Menu">
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <div *ngFor="let o of options">

        <button *kienePermission="[o.permission]" mat-menu-item (click)="clickedOption(o)">
          <mat-icon>{{o.icon}}</mat-icon>
          <span>{{o.text}}</span>
        </button>

    </div>
  </mat-menu>
</div>