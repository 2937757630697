import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RecPositionObject} from './rec-position';
import {Observable} from 'rxjs';
import {API_BASE_URL_SERVICE} from '../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable({
    providedIn: 'root'
})
export class RecPositionService {

    constructor(private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    getNeighbours(objectName: string, orderBy: string, search: string, currentPosition: number, clientId?: number): Observable<RecPositionObject> {
        let recpos = '';
        const orderby: string = orderBy ? orderBy : '';
        const searchString: string = search ? search : '';
        if (currentPosition) {
            recpos = currentPosition.toString();
        }
        let parameters: HttpParams = new HttpParams()
            .set('orderby', orderby)
            .set('search', searchString)
            .set('recposition', recpos);
        if (clientId) {
            parameters = parameters.set('kunde_id', clientId.toString());
        }
        return this.http.get<RecPositionObject>(this.apiBaseUrl + objectName + '/read_neighbors.php', {params: parameters});
    }
}
