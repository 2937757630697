import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'kiene-dokument-type-selection-dialog',
  templateUrl: './dokument-type-selection-dialog.component.html',
  styleUrls: ['./dokument-type-selection-dialog.component.scss']
})
export class DokumentTypeSelectionDialogComponent implements OnInit {

  ctrl1 = new UntypedFormControl(false);
  ctrl2 = new UntypedFormControl(false);
  ctrl3 = new UntypedFormControl(false);
  ctrl4 = new UntypedFormControl(false);
  ctrl5 = new UntypedFormControl(false);
  ctrl6 = new UntypedFormControl(false);

  constructor(public dialogRef: MatDialogRef<DokumentTypeSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) private data) { }

  ngOnInit() {
  }

  cancel(){
    this.dialogRef.close(null);
  }

  create(){
    let dokTypes = [];

    if(this.ctrl1.value){
      dokTypes.push(1);
    }
    if(this.ctrl2.value){
      dokTypes.push(2);
    }
    if(this.ctrl3.value){
      dokTypes.push(3);
    }
    if(this.ctrl4.value){
      dokTypes.push(4);
    }
    if(this.ctrl5.value){
      dokTypes.push(5);
    }
    if(this.ctrl6.value){
      dokTypes.push(6);
    }

    this.dialogRef.close(dokTypes);
  }

}
