import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Haendler} from '../haendler';
import {StammdatenService} from '../../stammdaten.service';
import {MessageService} from '../../../../services/message-service/message.service';

@Component({
    selector: 'kiene-haendler-selector',
    templateUrl: './haendler-selector.component.html',
    styleUrls: ['./haendler-selector.component.scss']
})
export class HaendlerSelectorComponent implements OnInit, OnChanges {

    @Output('haendlerSelected') haendlerSelected = new EventEmitter<Haendler>();
    @Input('disabled') disabled = false;

    haendlerCtrl = new FormControl();
    haendler: Haendler[] = [];

    constructor(private stammdatenService: StammdatenService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {

        this.ladeHaendlerFavoriten();

        this.haendlerCtrl.valueChanges.subscribe({
            next: haendler => {
                this.haendlerSelected.emit(haendler);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.disabled) {
            this.haendlerCtrl.disable();
        } else {
            this.haendlerCtrl.enable();
        }
    }

    haendlerComparator(h1: Haendler, h2: Haendler) {
        return h1?.viehhaendler_id === h2?.viehhaendler_id;
    }

    private ladeHaendlerFavoriten() {
        this.stammdatenService.loadHaendlerFavoriten().subscribe({
            next: response => {
                this.haendler = response.records;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}
