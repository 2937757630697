import { Inject, Injectable } from '@angular/core';
import { MatSnackBar, } from '@angular/material/snack-bar';
import { API_BASE_URL_SERVICE } from '../../../projects/kiene-core/src/lib/kiene-core.config';
import { KieneTableService } from '../components/kiene-table/kiene-table.service';
import {
    LocalStorageService
} from '../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    constructor(
        private snackbar: MatSnackBar,
        private tableService: KieneTableService,
        private localStorageService: LocalStorageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    infoMessage(text: string) {
        this.snackbar.open(text, '', {
            duration: 4000,
            panelClass: ['snackbar-ok'],
        });
    }

    alertMessage(text: string) {
        this.snackbar.open(text, '', {
            duration: 4000,
            panelClass: ['snackbar-alert'],
        });
    }

    hintMessage(text: string) {
        this.snackbar.open(text, '', {
            duration: 4000,
            panelClass: ['snackbar-hint'],
        });
    }

    updateMessage(version: string) {
        this.snackbar
            .open(
                'Es ist eine neue Version (' + version + ') verfügbar.',
                'Jetzt aktualisieren',
                {
                    duration: 10000,
                    panelClass: ['snackbar-hint'],
                    horizontalPosition: 'start',
                }
            )
            .onAction()
            .subscribe(() => {
                this.tableService
                    .post(this.apiBaseUrl + 'session/refresh.php', undefined)
                    .subscribe({
                        next: (response) => {
                            this.localStorageService.setCurrentUser(response);
                            location.reload();
                        },
                        error: (err) => {
                            location.reload();
                        },
                    });
            });
    }

    hintMessageRight(text: string) {
        this.snackbar.open(text, '', {
            duration: 4000,
            verticalPosition: 'bottom',
            horizontalPosition: 'right',
            panelClass: ['snackbar-hint'],
        });
    }

    errorMessageSimple(errorObject: any) {
        this.errorMessage('Fehler', errorObject);
    }

    errorMessage(text: string, errorObject: HttpErrorResponse) {
        // const error = errorObject.error;
        // const errorText = JSON.parse(error);
        let fehler = '';
        if (errorObject) {
            if (errorObject.status === 0) {
                text = '';
                fehler = 'Keine Internetverbindung!';
            } else if (errorObject.error) {
                if (errorObject.error.errortext) {
                    fehler = '\n' + errorObject.error.errortext;
                } else if (errorObject.error.fehler) {
                    fehler = '\n' + errorObject.error.fehler;
                } else {
                    if (typeof errorObject.error === 'object') {
                        fehler = '\n' + errorObject.error;
                    } else if (typeof errorObject.error === 'string') {
                        const err = JSON.parse(errorObject.error);
                        fehler = '\n' + err.errortext;
                    } else {
                        fehler = '\n';
                    }
                }
            }
        }
        this.snackbar.open(text + fehler, '', {
            duration: 6000,
            panelClass: ['snackbar-alert'],
        });
        // this.snackbar.open(text + '\n' + errorText.errortext, '', {duration: 4000, panelClass: ['snackbar-alert']});
    }
}
