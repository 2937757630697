import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Artikelliste, ArtikellisteAuswahlDialogResult } from './artikelliste-auswahl-dialog';

@Component({
  selector: 'kiene-artikelliste-auswahl-dialog',
  templateUrl: './artikelliste-auswahl-dialog.component.html',
  styleUrls: ['./artikelliste-auswahl-dialog.component.scss']
})
export class ArtikellisteAuswahlDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ArtikellisteAuswahlDialogComponent>
  ) { }

  ngOnInit(): void {
  }

  selectListe(liste: Artikelliste) {
    if (liste) {
      const result = new ArtikellisteAuswahlDialogResult();
      result.confirm = true;
      result.artikelliste = liste;
      this.dialogRef.close(result);
    }
  }

  cancel() {
    this.dialogRef.close(new ArtikellisteAuswahlDialogResult());
  }

}
