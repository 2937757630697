import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { Gruppe } from "../../../gruppen/classes/gruppe";
import { UntypedFormControl } from "@angular/forms";
import { MessageService } from "../../../../services/message-service/message.service";
import { Tierliste } from "../../classes/tierliste";
import { GruppenService } from "../../../gruppen/gruppen.service";

@Component({
    selector: "kiene-gruppe-tier-abgangsliste-filter",
    templateUrl: "./gruppe-tier-abgangsliste-filter.component.html",
    styleUrls: ["./gruppe-tier-abgangsliste-filter.component.scss"],
})
export class GruppeTierAbgangslisteFilterComponent
    implements OnInit, OnDestroy, OnChanges
{
    abgangslistenGruppeSubscription: Subscription;
    isLoading = true;
    label = "Lädt...";

    @Input("gruppe") selectedGruppe: Gruppe;
    @Input("abgangslisten") abgangslisten: Tierliste[] = [];
    @Input("hideOnEmptyList") hideOnEmptyList = false;
    @Output("selectedAbgangslisten") selectedAbgangslisten: EventEmitter<
        Tierliste[]
    > = new EventEmitter<Tierliste[]>();
    selectedAbgangslisteCtrl: UntypedFormControl = new UntypedFormControl(null);
    abgangslistenFuerGruppe: Tierliste[] = [];

    constructor(
        private messageService: MessageService,
        private gruppenService: GruppenService
    ) {}

    ngOnDestroy(): void {
        this.abgangslistenGruppeSubscription?.unsubscribe();
    }
    ngOnInit(): void {
        this.loadabgangslistenFuerGruppe();

        this.selectedAbgangslisteCtrl.setValue(this.abgangslisten);

        this.selectedAbgangslisteCtrl.valueChanges.subscribe((data) => {
            this.selectedAbgangslisten.emit(data);
        });
    }

    ngOnChanges() {
        if (this.selectedGruppe) {
            this.loadabgangslistenFuerGruppe();
        }
    }

    private loadabgangslistenFuerGruppe() {
        this.isLoading = true;
        if (this.selectedGruppe) {
            this.abgangslistenGruppeSubscription?.unsubscribe();
            this.abgangslistenGruppeSubscription = this.gruppenService
                .ladeTierlistenHistorie(this.selectedGruppe.gruppe_id)
                .subscribe({
                    next: (result) => {
                        this.isLoading = false;
                        this.abgangslistenFuerGruppe = result.records;
                        this.abgangslistenFuerGruppe.sort((a, b) => {
                            return a.tierliste_id < b.tierliste_id ? -1 : 1;
                        });

                        if (this.abgangslistenFuerGruppe.length === 0) {
                            this.label = "keine Abgangslisten";
                            this.selectedAbgangslisteCtrl.disable();
                        } else {
                            this.label = "Abgangsliste";
                        }
                    },
                    error: (err) => {
                        this.isLoading = false;
                        this.messageService.errorMessage(
                            "Fehler beim Laden der Abgangslisten",
                            err
                        );
                    },
                });
        }
    }

    resetAbgangslisteCtrl($event: any) {
        $event.stopPropagation();
        this.selectedAbgangslisteCtrl.setValue(undefined);
    }

    compareAbgangsliste(a: Tierliste, b: Tierliste) {
        return a && b && a.tierliste_id === b.tierliste_id;
    }
}
