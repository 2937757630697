<div style="padding: 24px" *kienePermission="[4401]">
    <h3>St&uuml;ckliste {{ material?.bezeichnung }}</h3>

    <fieldset
        *kienePermission="[4402]"
        style="width: calc(100% - 12px); border: 1px solid lightgray; border-radius: 12px"
    >
        <legend>
            Position hinzuf&uuml;gen/editieren
            <button
                style="margin-left: 12px"
                mat-stroked-button
                (click)="clear()"
            >
                Felder leeren
            </button>
        </legend>
        <div class="one-row">
            <kiene-article-scan-autocomplete
                #articleAutocomplete
                style="min-width: 400px; margin-right: 12px"
                placeholder="Artikel scannen oder eingeben"
                (selectedMaterial)="setSelectedMaterial($event)"
            >
            </kiene-article-scan-autocomplete>

            <kiene-tierart-selector
                #tierartSelector
                style="margin-right: 12px"
                (tierartSelectionChanged)="setSelectedTierart($event)"
            ></kiene-tierart-selector>

            <kiene-anwendungs-selector
                #anwendungSelector
                style="margin-right: 12px"
                (change)="setAnwendung($event)"
            >
            </kiene-anwendungs-selector>

            <kiene-diagnose-selector
                #diagnoseSelector
                style="margin-right: 12px"
                (change)="setDiagnose($event)"
            >
            </kiene-diagnose-selector>

            <mat-form-field
                *ngIf="
                    selectedMaterial?.behandlung === selectedMaterial?.abgabe
                "
                style="margin-left: 12px; width: 110px"
            >
                <mat-label>Behandlung</mat-label>
                <mat-select
                    #behandlungSelector
                    [formControl]="behandlungJaNeinCtrl"
                >
                    <mat-option [value]="1">Ja</mat-option>
                    <mat-option [value]="0">Nein</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left: 12px; width: 120px">
                <mat-label>Anwendungsdauer</mat-label>
                <input
                    matInput
                    type="number"
                    [formControl]="anwendungsdauerCtrl"
                />
            </mat-form-field>

            <mat-form-field style="margin-left: 12px; width: 100px">
                <mat-label>Dosierung</mat-label>
                <input matInput type="number" [formControl]="dosierungCtrl"/>
            </mat-form-field>

            <mat-form-field style="margin-left: 12px; width: 100px">
                <mat-label>Menge</mat-label>
                <input
                    matInput
                    type="number"
                    [formControl]="mengeCtrl"
                    (blur)="addPosition()"
                />
            </mat-form-field>

            <button
                style="margin-left: 12px"
                mat-button
                (click)="addPosition()"
            >
                Speichern
            </button>
            <span class="spacer"></span>
        </div>
    </fieldset>

    <table
        class="phito-table"
        style="margin-top: 24px; width: calc(100% - 12px);"
    >
        <thead>
        <tr>
            <th>Pos.</th>
            <th>Artikel</th>
            <th>Lieferant</th>
            <th>Tierart</th>
            <th>Anwendung</th>
            <th>Diagnose</th>
            <th>Dosierung</th>
            <th>Anwendungsdauer</th>
            <th>Wartezeit Fl.</th>
            <th>Behandlung</th>
            <th>Menge</th>
            <th *kienePermission="[3201]">Einzelpreis</th>
            <th *kienePermission="[3201]">Rabatt</th>
            <th *kienePermission="[3201]">Gesamt</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let a of artikelliste; let i = index">
            <td>{{ i + 1 }}</td>
            <td>{{ a.artikel_pos }}</td>
            <td>{{ a.lieferant }}</td>
            <td>{{ a.tierart }}</td>
            <td>{{ a.anwendung }}</td>
            <td>{{ a.diagnose }}</td>
            <td>{{ a.dosierung }}</td>
            <td>{{ a.anwendungsdauer }}</td>
            <td>{{ a.wartezeit }}</td>
            <td>
                <mat-icon>{{
                    a.behandlung === 1
                        ? "check_box"
                        : "check_box_outline_blank"
                    }}</mat-icon>
            </td>
            <td>{{ a.artikelmenge }} Stk.</td>
            <td *kienePermission="[3201]">
                {{ a.einzelpreis | number: "1.2-2" }} &euro;
            </td>
            <td *kienePermission="[3201]">{{ a.rabatt }}%</td>
            <td *kienePermission="[3201]">
                {{
                a.einzelpreis * a.artikelmenge * (1 - a.rabatt / 100)
                    | number: "1.2-2"
                }}
                &euro;
            </td>
            <td>
                <div class="one-row" style="width: 100px">
                    <button
                        *kienePermission="[4403]"
                        mat-icon-button
                        color="accent"
                        (click)="editPosition(a)"
                    >
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button
                        *kienePermission="[4404]"
                        mat-icon-button
                        color="accent"
                        (click)="removePosition(a)"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </td>
        </tr>
        <tr *kienePermission="[3201]">
            <td colspan="12"></td>
            <td style="padding-top: 24px">Einkaufspreis:</td>
            <td style="padding-top: 24px; font-weight: bold">
                {{ einkaufspreis | number: "1.2-2" }} &euro;
            </td>
            <td></td>
        </tr>
        </tbody>
    </table>
</div>
