<h1 mat-dialog-title>Tierarztgruppen für {{tierarzt ? tierarzt.vorname + ' ' + tierarzt.nachname : ''}}</h1>
<div mat-dialog-content>

  <div *ngIf="myVetGroups.length > 0">
    <ul style="list-style-type: none;">
      <li *ngFor="let vg of myVetGroups">
        <div class="one-row">
          <mat-icon>group</mat-icon>
          <span style="width: fit-content;">&nbsp;{{vg.tierarztgruppe}}&nbsp;</span>
          <div style="width: 70%;"></div>
          <span class="spacer"></span>
          <kiene-delete-button (delete)="removeVetGroupFromVet(vg)"></kiene-delete-button>
        </div>
      </li>
    </ul>
  </div>
  <kiene-autocomplete [panelWidth]="'50%'" [apiUrl]="'tierarztgruppe/'" [placeholder]="'Tierarztgruppe hinzufügen'" [clearAfterSelect]="true" (elementSelected)="setVetGroup($event)"></kiene-autocomplete>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Schlie&szlig;en</button>
</div>