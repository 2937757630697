<h1 mat-dialog-title>{{ futtermittellieferant ? futtermittellieferant.bezeichnung : 'Futtermittellieferant hinzufügen' }}</h1>

<div mat-dialog-content>
    <kiene-lieferant-details (saved)="dialogRef.close($event)"
                             [futtermittellieferant]="futtermittellieferant">
        <ng-container additional-actions>
            <button (click)="dialogRef.close()" mat-button>Abbrechen</button>
        </ng-container>
    </kiene-lieferant-details>
</div>
