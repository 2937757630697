import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { Tierreklamation } from "../../classes/tierreklamation";
import { UntypedFormControl } from "@angular/forms";
import { MessageService } from "../../../../services/message-service/message.service";
import { StammdatenService } from "../../../stammdaten/stammdaten.service";
import { Gruppe } from "../../../gruppen/classes/gruppe";
import { Subscription } from "rxjs";

@Component({
    selector: "kiene-gruppe-tier-reklamation-filter",
    templateUrl: "./gruppe-tier-tier-reklamation-filter.component.html",
    styleUrls: ["./gruppe-tier-tier-reklamation-filter.component.scss"],
})
export class GruppeTierTierReklamationFilterComponent
    implements OnInit, OnDestroy, OnChanges
{
    tierReklamationenGruppeSubscription: Subscription;
    isLoading = true;
    label = "Lädt...";

    @Input("gruppeId") gruppeId: number;
    @Input("reklamationen") reklamationen: Tierreklamation[] = [];
    @Input("hideOnEmptyList") hideOnEmptyList = false;
    @Output("selectedReklamationen") selectedReklamationen: EventEmitter<
        Tierreklamation[]
    > = new EventEmitter<Tierreklamation[]>();
    selectedReklamationCtrl: UntypedFormControl = new UntypedFormControl(null);
    reklamationenFuerGruppe: Tierreklamation[] = [];

    constructor(
        private messageService: MessageService,
        private stammdatenService: StammdatenService
    ) {}

    ngOnDestroy(): void {
        this.tierReklamationenGruppeSubscription?.unsubscribe();
    }
    ngOnInit(): void {
        this.loadTierReklamationenFuerGruppe();

        this.selectedReklamationCtrl.setValue(this.reklamationen);

        this.selectedReklamationCtrl.valueChanges.subscribe((data) => {
            this.selectedReklamationen.emit(data);
        });
    }

    ngOnChanges() {
        this.loadTierReklamationenFuerGruppe();
    }

    private loadTierReklamationenFuerGruppe() {
        if (this.gruppeId) {
            this.isLoading = true;
            this.tierReklamationenGruppeSubscription?.unsubscribe();
            this.tierReklamationenGruppeSubscription = this.stammdatenService
                .ladeTierreklamationen(this.gruppeId)
                .subscribe({
                    next: (result) => {
                        this.isLoading = false;
                        this.reklamationenFuerGruppe = result.records;
                        this.reklamationenFuerGruppe.sort((a, b) => {
                            return a.reklamation < b.reklamation ? -1 : 1;
                        });

                        if (this.reklamationenFuerGruppe.length === 0) {
                            this.label = "keine Reklamationen";
                            this.selectedReklamationCtrl.disable();
                        } else {
                            this.label = "Reklamation";
                        }
                    },
                    error: (err) => {
                        this.isLoading = false;
                        this.messageService.errorMessage(
                            "Fehler beim Laden der Tierreklamationen",
                            err
                        );
                    },
                });
        }
    }

    resetReklamationCtrl($event: MouseEvent) {
        $event.stopPropagation();
        this.selectedReklamationCtrl.setValue(undefined);
    }

    compareReklamation(a: Tierreklamation, b: Tierreklamation) {
        return a && b && a.tierreklamation_id === b.tierreklamation_id;
    }
}
