export class Behandlungsanfrage {
    bestellung_id: number;
    behandlungsanfrage: number;
    kunde_id: number;
    kunde: string;
    zusatz: string;
    kunde_nr_intern: number;
    bezeichnung: string;
    erstellt: Date;
    positionen: BehandlungsanfragePosition[] = [];
    status_id: number;
    status: string;
    zieldatum: Date;
    zieldatum_bis: Date;
    tierarzt_id: number;
    tierarzt: string;
    apotheke: number;
    tierarztgruppe: string;
    unternehmensgruppe_id: number;
    unternehmensgruppe: string;
    mandant_id: number;
    mandant: string;
}

export interface BehandlungsanfrageApi {
    records: Behandlungsanfrage[];
    count: number;
}

export class BehandlungsanfragePosition {
    ba_id: number;
    position: number;
    artikel_id: number;
    artikel: string;
    artikel_me: string;
    einheit: string;
    tierart_id: number;
    tierart: string;
    anzahl_tiere: number;
    tiergewicht: string;
    anwendungsdauer: number;
    dosierung: number;
    artikelmenge: number;
    artikelmenge_ist: number;
    chargen: string;
    indikation_id: number;
    diagnose: string;
    identifikation_id: number;
    identifikation: string;
    anwendung_id: number;
    anwendung: string;
    ean: number;
    selbstimpfer: number;
    wartezeit: number;
    gruppe_id: number;
    gruppe: string;
    erstellt: Date;
}

export interface BehandlungsanfragePositionApi {
    records: BehandlungsanfragePosition[];
    count: number;
}

export class BestellungStatus {
    public static ZWISCHENGESPEICHERT = 1;
    public static AUFGEGEBEN = 2;
    public static FREIGEGEBEN = 3;
    public static KOMMISSIONIERT = 4;
    public static ABGEHOLT = 5;
    public static STORNIERT = 6;
}
