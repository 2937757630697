import { Component, Inject, Input, OnInit } from '@angular/core';
import { Gruppe } from '../../gruppen/classes/gruppe';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../services/message-service/message.service';
import { RinderpassScan } from '../classes/rinderpass-scan';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import {
    GruppeTierScanImportierenDialogComponent
} from './gruppe-tier-scan-importieren-dialog/gruppe-tier-scan-importieren-dialog.component';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { FehlerhafteSeitenDialogComponent } from './fehlerhafte-seiten-dialog/fehlerhafte-seiten-dialog.component';
import { log } from 'console';
import { ConfirmDialogInput, ConfirmDialogResult } from '../../dialogs/confirm-dialog/confirm-dialog';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';

export interface Upload {
    progress: number;
    state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
}

@Component({
    selector: 'kiene-gruppe-tier-pass-scan-verwaltung',
    templateUrl: './gruppe-tier-pass-scan-verwaltung.component.html',
    styleUrls: ['./gruppe-tier-pass-scan-verwaltung.component.scss'],
})
export class GruppeTierPassScanVerwaltungComponent implements OnInit {
    @Input('gruppe') gruppe: Gruppe;
    scans: RinderpassScan[];
    isSaving = false;

    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private http: HttpClient,
        private dialog: MatDialog,
        private pdfViewer: PdfViewerDesktopService
    ) {
    }

    ngOnInit(): void {
        this.loadRinderPassScans();
    }

    loadRinderPassScans(): void {
        this.scans = undefined;
        let params = new HttpParams().set(
            'gruppe_id',
            this.gruppe.gruppe_id.toString()
        );
        params = params.append('orderby', 'erstellt desc');
        this.api.getAll(`${this.apiBaseUrl}rinderpassscan/read.php`, params).subscribe({
            next: (resp) => {
                this.scans = resp.records;
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Fehler beim Laden der Rinderpassscans!',
                    err
                );
            },
        });
    }

    openPdfDialog(selected: RinderpassScan) {
        this.pdfViewer.openPdfViewer(
            new PdfViewerDialogInput(
                'rinderpassscan/read_pdf.php',
                new HttpParams().set('grps_id', selected.grps_id?.toString()),
                'Rinderpassscan'
            )
        );
    }

    importScans() {
        const dialogRef = this.dialog.open(
            GruppeTierScanImportierenDialogComponent,
            {
                data: this.gruppe
            }
        );
        dialogRef.afterClosed().subscribe((resp) => {
            if (resp) {
                this.loadRinderPassScans();
            }
        });
    }

    restart(scan: RinderpassScan) {
        this.loadRinderPassScans();

        const input = new ConfirmDialogInput();
        input.headline = 'Import neustarten';
        input.text = 'Bitte den Prozess nur neu starten, wenn längere Zeit keine Seiten verarbeitet werden!';
        input.okButton = 'Neustart';
        this.dialog.open(ConfirmDialogComponent, {
            position: { top: '5%' },
            data: input,
        }).afterClosed().subscribe((response: ConfirmDialogResult) => {
            if (response?.confirmed) {
                this.isSaving = true;
                this.api.get(this.apiBaseUrl + 'rinderpassscan/restart.php', new HttpParams().set('grps_id', scan?.grps_id)).subscribe({
                    next: () => {
                        this.messageService.infoMessage('Pässe werden neu eingelesen!');
                        this.isSaving = false;
                    },
                    error: err => {
                        this.messageService.errorMessage('Fehler!', err);
                        this.isSaving = false;
                    }
                });
            }
        });
    }

    scanDone(scan: RinderpassScan): boolean {
        return !!(scan.seitenzahl_gesamt === scan.verarbeitet_anzahl);
    }

    restartAllowed(scan: RinderpassScan): boolean {
        return !this.scanDone(scan); // && (erstellt < jetzt);
    }

    fehlerhafteSeitenNachtragen(scan: RinderpassScan) {
        const dialogRef = this.dialog.open(FehlerhafteSeitenDialogComponent, {
            width: '100%',
            data: scan
        });
    }
}
