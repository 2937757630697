import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GruppenDashboardComponent} from './gruppen-dashboard/gruppen-dashboard.component';
import {GoogleChartsModule} from 'angular-google-charts';
import {MatFormFieldModule} from "@angular/material/form-field";
import { GruppenDashboardSelectActionDialogComponent } from './gruppen-dashboard/gruppen-dashboard-select-action-dialog/gruppen-dashboard-select-action-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [
        GruppenDashboardComponent,
        GruppenDashboardSelectActionDialogComponent
    ],
    imports: [
        CommonModule,
        GoogleChartsModule,
        MatFormFieldModule,
        MatDialogModule,
        MatButtonModule,
        MatListModule,
        MatIconModule,
    ],
    exports: [
        GruppenDashboardComponent
    ]
})
export class GruppenDashboardModule {
}
