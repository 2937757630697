import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Benachrichtigung, BenachrichtigungInfo } from '../classes/benachrichtigung';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { MatTabGroup } from '@angular/material/tabs';
import { BenachrichtigungenService } from '../benachrichtigungen.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-benachrichtigung-button',
    templateUrl: './benachrichtigung-button.component.html',
    styleUrls: ['./benachrichtigung-button.component.scss']
})
export class BenachrichtigungButtonComponent implements OnInit, OnDestroy {
    benachrichtigungen: Benachrichtigung[] = [];
    ungelesen: number;
    opened = false;
    interval: any;

    @ViewChild('trigger') trigger: CdkOverlayOrigin;
    @ViewChild('matTabGroup') matTabGroup: MatTabGroup;

    constructor(
        private benachrichtigungService: BenachrichtigungenService,
        private localStorageService: LocalStorageService
    ) { }


    ngOnInit(): void {
        this.loadUnreadCount();
        // lade neue Benachrichtigungen alle 60 sek.
        this.interval = setInterval(() => {
            this.loadUnreadCount();
        }, 1000 * 60);

        this.benachrichtigungService.watchBenachrichtigungen().subscribe(() => {
            this.loadUnreadCount();
        });
    }

    ngOnDestroy() {
        clearInterval(this.interval);
    }

    private loadUnreadCount() {
        if (this.localStorageService?.isLoggedIn()) {
            this.benachrichtigungService.getUngeleseneBenachrichtigungenCount().subscribe({
                next: (resp: BenachrichtigungInfo) => {
                    if (resp) {
                        this.ungelesen = resp.ungelesen;
                        if (resp.popup > 0) {
                            this.benachrichtigungService.showPopup().subscribe({
                                next: (reload: boolean) => {
                                    if (reload) {
                                        this.loadUnreadCount();
                                    }
                                }
                            });
                        }
                    }
                },
            });
        }
    }

    triggerNotificationOverlay(event: MouseEvent) {
        this.opened = !this.opened;
        // this.benachrichtigungService.triggeredNotificationOverlay(this.opened);
    }
}
