import { Component, OnInit } from '@angular/core';
import { TableDescriptor } from '../../components/kiene-table/table-descriptor';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { TableColumn } from '../../components/kiene-table/table-column';
import {
    TableOptionEvent,
    TableOption,
} from '../../components/kiene-table/table-option';
import { AddUntersuchungsmaterialDialogComponent } from './add-untersuchungsmaterial-dialog/add-untersuchungsmaterial-dialog.component';
import { Untersuchungsmaterial } from './untersuchungsmaterial';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../services/message.service';
import { Router } from '@angular/router';

@Component({
    selector: 'kiene-untersuchungsmaterialien',
    templateUrl: './untersuchungsmaterialien.component.html',
    styleUrls: ['./untersuchungsmaterialien.component.scss'],
})
export class UntersuchungsmaterialienComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private api: KieneTableService,
        private notification: MessageService,
        private router: Router
    ) { }

    ngOnInit() {
        this.tableDescriptor.headline = 'Untersuchungsmaterialien';
        this.tableDescriptor.uniqueIdentifier = 'Untersuchungsmaterialien';
        this.tableDescriptor.apiUrl =
            environment.BASE_URI + 'untersuchungsmaterial/';
        this.tableDescriptor.route = '/labor/unteruchungsmaterialien/';
        this.tableDescriptor.nameOfIdField = 'untersuchungsmaterial_id';
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;

        this.tableDescriptor.columns.push(
            new TableColumn('bezeichnung', 'Untersuchungsmaterial')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('tierart', 'Tierart')
        );

        this.tableDescriptor.options.push(
            new TableOption('edit', 'Bearbeiten', 'edit', 1422)
        );
        this.tableDescriptor.options.push(
            new TableOption('config', 'Konfigurieren', 'settings', 1422)
        );

        this.tableDescriptor.permissionCreate = 1422;
        this.tableDescriptor.permissionUpdate = 1422;
        this.tableDescriptor.permissionView = 1421;
        this.tableDescriptor.permissionDelete = 1424;

        this.tableDescriptor.initialSortColumn = 'bezeichnung';
        this.tableDescriptor.initialSortDirection = 'asc';
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            this.openAddDialog();
        } else if (event.name === 'edit') {
            this.edit(event.value);
        } else if (event.name === 'config') {
            this.router.navigate([
                'labor',
                'untersuchungsmaterialkonfigurator',
                event.value.untersuchungsmaterial_id,
            ]);
        }
    }

    edit(untersuchungsmaterial: Untersuchungsmaterial) {
        // Material nachladen, damit die Artikelstruktur enthalten ist
        this.api
            .getOne(
                environment.BASE_URI + 'untersuchungsmaterial/read_one.php',
                'untersuchungsmaterial_id',
                untersuchungsmaterial.untersuchungsmaterial_id?.toString()
            )
            .subscribe({
                next: (material: Untersuchungsmaterial) => {
                    this.openAddDialog(material);
                },
                error: (err) => {
                    this.notification.errorMessage(
                        'Fehler beim Nachladen!',
                        err
                    );
                },
            });
    }

    openAddDialog(untersuchungsmaterial?: Untersuchungsmaterial) {
        const dialogRef = this.dialog.open(
            AddUntersuchungsmaterialDialogComponent,
            {
                width: '50%',
                minWidth: '300px',
                data: {
                    untersuchungsmaterial: untersuchungsmaterial,
                },
            }
        );

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.api
                    .updateEntity(
                        environment.BASE_URI + 'untersuchungsmaterial/update.php',
                        result
                    )
                    .subscribe(
                        (response) => {
                            this.notification.infoMessage(
                                'Untersuchungsmaterial erfolgreich gespeichert!'
                            );
                            this.api.reload();
                        },
                        (error) => {
                            this.notification.errorMessage(
                                'Untersuchungsmaterial konnte nicht gespeichert werden!',
                                error
                            );
                        }
                    );
            }
        });
    }
}
