import { DatePipe, formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChartType } from 'angular-google-charts';
import { environment } from '../../../../environments/environment';
import { AppService } from '../../../app.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { ZeitauswahlChange } from '../../zeitauswahl/zeitauswahl-change';
import { Material } from '../../../warehouse/material/material';
import { Kombiniert } from './kombiniert';
import { CsvColumn } from '../../../csv-export/csv-export/csv-column';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { Subscription } from 'rxjs';
import { ReportUtils } from '../../report-utils';

@Component({
    selector: 'kiene-kombiniert-report',
    templateUrl: './kombiniert-report.component.html',
    styleUrls: ['./kombiniert-report.component.scss'],
})
export class KombiniertReportComponent implements OnInit, AfterViewInit {
    displayedColumns = [
        'zeitraum',
        'wareneingang',
        'warenausgang',
        'differenz',
    ];
    dataSource = new MatTableDataSource<Kombiniert>();
    dataSourceVorjahr = new MatTableDataSource<Kombiniert>();
    @ViewChild(MatSort) sort: MatSort;
    expandedElement: Kombiniert | null;

    currentMaterial: Material;

    reportParams = new HttpParams();

    zeiteintrag: ZeitauswahlChange;
    vorjahreszeitraum: ZeitauswahlChange;

    chartType = ChartType.Bar;
    chartColumns = ['Zeitraum', 'Wareneingang', 'Warenausgang'];
    chartData = [['', 0, 0]];

    csvColumns: CsvColumn[] = [];
    csvFilename = 'Warenein-und-ausgang.csv';

    gesamtWareneingang = 0;
    gesamtWarenausgang = 0;

    gesamtWareneingangVorjahr = 0;
    gesamtWarenausgangVorjahr = 0;

    isLoading: boolean[] = [];
    aktuellSubscription: Subscription;
    vorjahrSubscription: Subscription;

    constructor(
        private api: KieneTableService,
        private appService: AppService,
        private datePipe: DatePipe,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.reportParams = this.reportParams.set(
            'von',
            this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        );
        this.reportParams = this.reportParams.set(
            'bis',
            this.datePipe.transform(new Date(), 'yyyy-MM-dd')
        );

        this.csvColumns.push(new CsvColumn('zeitraum', 'Zeitraum'));
        this.csvColumns.push(new CsvColumn('wareneingang', 'Wareneingang'));
        this.csvColumns.push(new CsvColumn('warenausgang', 'Warenausgang'));
        this.csvColumns.push(new CsvColumn('differenz', 'Differenz'));
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    loadData() {
        this.loadDataAktuell();
        this.loadDataVorjahr();
    }

    loadDataAktuell() {
        if (!this.currentMaterial) {
            return;
        }
        this.appService.setDataLoading(true);

        this.createSupplierParams();

        this.reportParams = this.reportParams.set(
            'von',
            this.datePipe.transform(this.zeiteintrag.von, 'yyyy-MM-dd')
        );
        this.reportParams = this.reportParams.set(
            'bis',
            this.datePipe.transform(this.zeiteintrag.bis, 'yyyy-MM-dd')
        );
        this.reportParams = this.reportParams.set(
            'aggregieren',
            this.zeiteintrag.aggregieren
        );

        this.aktuellSubscription?.unsubscribe();
        this.aktuellSubscription = this.api
            .get(
                environment.BASE_URI + 'report/read_waren_gesamt.php',
                this.reportParams
            )
            .subscribe(
                (response) => {
                    this.dataSource.data = response.records;
                    this.resetChartData();
                    this.berechneGesamtwerte();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    loadDataVorjahr() {
        if (!this.currentMaterial) {
            return;
        }
        this.appService.setDataLoading(true);

        this.createSupplierParams();

        this.reportParams = this.reportParams.set(
            'von',
            formatDate(this.vorjahreszeitraum.von, 'yyyy-MM-dd', 'de')
        );
        this.reportParams = this.reportParams.set(
            'bis',
            formatDate(this.vorjahreszeitraum.bis, 'yyyy-MM-dd', 'de')
        );
        this.reportParams = this.reportParams.set(
            'aggregieren',
            this.zeiteintrag.aggregieren
        );

        this.vorjahrSubscription?.unsubscribe();
        this.vorjahrSubscription = this.api
            .get(
                environment.BASE_URI + 'report/read_waren_gesamt.php',
                this.reportParams
            )
            .subscribe(
                (response) => {
                    this.dataSourceVorjahr.data = response.records;
                    this.berechneGesamtwerteVorjahr();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    createSupplierParams() {
        if (this.currentMaterial) {
            this.reportParams = this.reportParams.set(
                'artikel_id',
                this.currentMaterial.artikel_id.toString()
            );
        }
    }

    setCurrentMaterial(material: Material) {
        if (material) {
            this.currentMaterial = material;
            this.loadData();
        } else {
            this.clearAll();
        }
    }

    clearAll() {
        this.dataSource.data = [];
        this.clearGesamtwerte();
        this.resetChartData();
    }

    clearGesamtwerte() {
        this.gesamtWareneingang = 0;
        this.gesamtWarenausgang = 0;
    }

    zeitintervalChanged(event: ZeitauswahlChange) {
        this.zeiteintrag = event;
        this.vorjahreszeitraum = ReportUtils.getVorjahreszeitraum(this.zeiteintrag);
        this.loadData();
    }

    berechneGesamtwerte() {
        this.gesamtWareneingang = 0;
        this.gesamtWarenausgang = 0;

        const data = this.dataSource.data;
        for (const r of data) {
            this.chartData.push([
                r.zeitraum,
                r.wareneingang ? r.wareneingang : 0,
                r.warenausgang ? r.warenausgang : 0,
            ]);
            this.gesamtWareneingang += r.wareneingang ? r.wareneingang : 0;
            this.gesamtWarenausgang += r.warenausgang ? r.warenausgang : 0;
        }
    }

    berechneGesamtwerteVorjahr() {
        this.gesamtWareneingangVorjahr = 0;
        this.gesamtWarenausgangVorjahr = 0;

        const data = this.dataSourceVorjahr.data;
        for (const r of data) {
            this.chartData.push([
                r.zeitraum,
                r.wareneingang ? r.wareneingang : 0,
                r.warenausgang ? r.warenausgang : 0,
            ]);
            this.gesamtWareneingangVorjahr += r.wareneingang ? r.wareneingang : 0;
            this.gesamtWarenausgangVorjahr += r.warenausgang ? r.warenausgang : 0;
        }
    }

    resetChartData() {
        this.chartData = [['', 0, 0]];
    }

}
