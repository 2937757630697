import { KeyValue } from '@angular/common';

export class KieneKeyValue implements KeyValue<string, any> {
    key: string;
    value: any;

    constructor(key: string, value: any) {
        this.key = key;
        this.value = value;
    }
}

