<nav mat-tab-nav-bar style="margin-top: 63px; width: 100%">
    <a
        *ngIf="isTabMenuVisible('/belege/behandlungsanfragen')"
        mat-tab-link
        [routerLink]="'/belege/behandlungsanfragen'"
        routerLinkActive
        #bla="routerLinkActive"
        [active]="bla.isActive || router.url.startsWith('/belege/behandlungsanfragen')"
    >Behandlungsanfragen</a
    >
    <a
        *ngIf="isTabMenuVisible('/belege/abgabebelege')"
        mat-tab-link
        [routerLink]="'/belege/abgabebelege'"
        routerLinkActive
        #ala="routerLinkActive"
        [active]="ala.isActive || router.url.startsWith('/belege/abgabebelege')"
    >Abgabebelege</a
    >
    <a
        *ngIf="showTabBestandsbuch() && isTabMenuVisible('/belege/bestandsbuecher')"
        mat-tab-link
        [routerLink]="['/belege/bestandsbuecher']"
        routerLinkActive
        #bebuela="routerLinkActive"
        [active]="bebuela.isActive || router.url.startsWith('/belege/bestandsbuecher')"
    >
        <kiene-tabinfo icon="" [sendeKundeIdOptional]="true" [ladeWennKunde]="true"
                       urlpfad="bestandsbuch/tabinfo.php"></kiene-tabinfo>
        &nbsp;
        Bestandsb&uuml;cher</a
    >
    <a
        *ngIf="isTabMenuVisible('/labor/befundmitteilungen')"
        mat-tab-link
        [routerLink]="['/belege/labor/befundmitteilungen']"
        routerLinkActive
        #lala="routerLinkActive"
        [active]="lala.isActive || router.url.startsWith('/belege/labor/befundmitteilungen')"
    >
        <kiene-tabinfo
            icon=""
            urlpfad="antibiogramm/tabinfo.php"
            [sendeKundeId]="true"
        ></kiene-tabinfo
        >&nbsp;Labor
    </a>
    <a
        *ngIf="isTabMenuVisible('/belege/massnahmenplaene')"
        mat-tab-link
        [routerLink]="['/belege/massnahmenplaene']"
        routerLinkActive
        #mpla="routerLinkActive"
        [active]="mpla.isActive || router.url.startsWith('/belege/massnahmenplaene')"
    >
        <kiene-tabinfo [ladeWennKunde]="true" [sendeKundeId]="true" [sendeKundeIdOptional]="true"
                       urlpfad="massnahmenplan_rind/tabinfo_gesamt.php"></kiene-tabinfo>
        <span>&nbsp;Maßnahmenpläne</span>
    </a>
    <a
        *ngIf="isTabMenuVisible('/belege/bestandsbesuch')"
        mat-tab-link
        [routerLink]="['/belege/bestandsbesuch']"
        routerLinkActive
        #bbla="routerLinkActive"
        [active]="bbla.isActive || router.url.startsWith('/belege/bestandsbesuch')"
    >
        <kiene-tabinfo
            icon=""
            urlpfad="bestandsbesuchsprotokoll/tabinfo.php"
            [sendeKundeId]="true"
        ></kiene-tabinfo>
        &nbsp;Bestandsbes. Protokoll
    </a>
    <a
        *ngIf="isTabMenuVisible('/belege/dokumente')"
        mat-tab-link
        [routerLink]="['/belege/dokumente']"
        routerLinkActive
        #dla="routerLinkActive"
        [active]="dla.isActive || router.url.startsWith('/belege/dokumente')"
    >
        <kiene-tabinfo [ladeWennKunde]="true" [sendeKundeId]="true" [sendeKundeIdOptional]="true"
                       urlpfad="dokument/tabinfo.php"></kiene-tabinfo>
        <span>&nbsp;Dokumente</span>
    </a>
    <a
        *ngIf="isTabMenuVisible('/belege/meldungen')"
        mat-tab-link
        [routerLink]="['/belege/meldungen']"
        routerLinkActive
        #mla="routerLinkActive"
        [active]="mla.isActive || router.url.startsWith('/belege/meldungen')"
    >
        <kiene-tabinfo [urlpfad]="'meldung/tabinfo.php'"></kiene-tabinfo>
        &nbsp;Meldungen QS/HIT</a
    >
    <a
        *ngIf="isTabMenuVisible('/belege/gruppen')"
        mat-tab-link
        [routerLink]="['/belege/gruppen']"
        routerLinkActive
        #gla="routerLinkActive"
        [active]="gla.isActive || router.url.startsWith('/belege/gruppen')"
    >Gruppen</a
    >
</nav>
<router-outlet></router-outlet>
