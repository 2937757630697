<div mat-dialog-title>{{headline}}</div>
<div mat-dialog-content>
    <p>{{text}}</p>
    <mat-form-field>
        <mat-label>{{label}}</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="dateCtrl" [readonly]="true">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">{{okButton}}</button>
</div>