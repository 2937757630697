<h1 mat-dialog-title>{{ data.headline }}</h1>

<div mat-dialog-content>
    <pdf-viewer *ngIf="base64" 
        [autoresize]="false"
        [original-size]="false"
        [render-text]="true"
        [show-all]="true"
        [src]="base64"
        style="height: calc(90vh - 48px); max-height: calc(90vh - 48px); width: calc(80vw - 48px); max-width: 892px; overflow: scroll;"
    ></pdf-viewer>
</div>

<div mat-dialog-actions>
    <span class="spacer"></span>
    <button data-cy="confirm-dialog-abort" mat-button (click)="cancel()">{{data?.cancelButton}}</button>
    <button data-cy="confirm-dialog-confirm" mat-flat-button [color]="data?.buttonColor" (click)="ok()">
        {{ data.okButton }}
    </button>
</div>
