<div style="padding: 24px;">
    <mat-tab-group>
        <mat-tab> 
            <ng-template mat-tab-label>
                <mat-icon>pets</mat-icon>
                &nbsp;Allgemein
            </ng-template>
            <ng-template matTabContent>
                <kiene-kiene-table [tableDescriptor]="tableDescriptorAllgemein" (optionClicked)="catchOptionEvent($event)"></kiene-kiene-table>
            </ng-template>    
        </mat-tab>
        <mat-tab> 
            <ng-template mat-tab-label>
                <mat-icon>history</mat-icon>
                &nbsp;Historie
            </ng-template>
            <ng-template matTabContent>
                <kiene-kiene-table [tableDescriptor]="tableDescriptorHistorie" (optionClicked)="catchOptionEvent($event)"></kiene-kiene-table>
            </ng-template>    
        </mat-tab>
      </mat-tab-group>
</div>