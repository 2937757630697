import {Inject, Injectable} from '@angular/core';
import {Dokument} from './dokumente/dokument';
import {
    KieneBackendApiService
} from '../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../projects/kiene-core/src/lib/kiene-core.config';
import {MessageService} from '../services/message.service';
import {Client} from '../../../projects/kiene-core/src/lib/components/client/classes/client';
import {HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {

    constructor(private api: KieneBackendApiService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private messageService: MessageService) {
    }

    public alleDokumenteUnterschreiben(client?: Client) {
        let params = new HttpParams();
        if (client) {
            params = params.set('kunde_id', client.kunde_id);
        }
        return this.api.get(this.apiBaseUrl + 'beleg/update_unterschriften.php', params);
    }

    public ladeDokumenteBereitZumUnterschreiben(client?: Client) {
        let params = new HttpParams();
        if (client) {
            params = params.set('kunde_id', client.kunde_id);
        }
        return this.api.getAll(this.apiBaseUrl + 'beleg/read_bereit_zum_unterschreiben.php', params);
    }

    public dokumentUnterschreiben(dokument: Dokument) {
        return this.api.post(this.apiBaseUrl + 'beleg/update_unterschrift.php', dokument);
    }

    public ladeBenutzerprofil() {
        return this.api.get(`${this.apiBaseUrl}benutzerprofil/read_one_kundenportal.php`);
    }
}
