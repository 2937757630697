import { User } from "../../administration/user/user";

export class Fortbildung{
    fortbildung_id: number;
    titel: string;
    veranstalter: string;
    veranstaltungsort: string;
    datum: Date;
    anmeldefrist: Date;
    teilnahme: number;
    teilnehmer: User[] = [];
    infotext: string;
    pdf_id: number;
    erstellt: Date;
    erstellt_von: number;
    editiert: Date;
    editiert_von: number;
    geloescht: number;
    mandant_id: number;
    abgelaufen: number;
    mandanten_ids: number[] = [];
}