<div style="padding: 0 24px 64px 24px">
    <div class="one-row" style="margin: 12px 0;">
        <kiene-page-header [showReturnButton]="showReturnButton" [ueberschrift]="'Meine Freigaben'"
                           [routeRelativeAlternative]="'../../'"></kiene-page-header>
        <span class="spacer"></span>
        <button (click)="freigabeAnlegen()" color="accent" mat-icon-button>
            <mat-icon>add</mat-icon>
        </button>

    </div>


    <ng-container *ngFor="let f of freigaben; let i = index;">
        <div class="one-row">
            <h3>Freigabe vom {{ f.erstellt | date: 'dd.MM.yyyy' }}</h3>
            <button mat-icon-button color="accent" [matMenuTriggerFor]="haendlerMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #haendlerMenu>
                <button mat-menu-item (click)="deleteFreigabe(f)">
                    <mat-icon>delete</mat-icon>
                    <span>l&ouml;schen</span>
                </button>
            </mat-menu>
        </div>

        <div style="margin-bottom: 48px;">
            <kiene-key-value-list>
                <kiene-key-value-list-item [icon]="'start'" key="Betrieb"
                                           [value]="f.betrieb_freigeber + ' ' + (f.zusatz_freigeber || '') + ', ' + f.vvvo_freigeber"></kiene-key-value-list-item>
                <kiene-key-value-list-item [icon]="'adjust'" key="wurde freigegeben f&uuml;r"
                                           [value]="f.betrieb_freigabe + ' ' + (f.zusatz_freigabe || '') + ', ' + f.vvvo_freigabe"></kiene-key-value-list-item>

            </kiene-key-value-list>
        </div>
    </ng-container>

</div>
