<h1 mat-dialog-title>Gruppeninformationen</h1>


<div mat-dialog-content>

    <div style="display: flex; flex-direction: column; margin-bottom: 6px">
        <strong>{{gruppe?.kunde}}</strong>
        <mat-hint class="one-row">
            <span>{{gruppe?.bezeichnung}}</span>
            <span class="spacer"></span>
            <span>{{gruppe?.stall}}</span>
        </mat-hint>
    </div>

    <mat-form-field
        style="width: 100%">
        <mat-label>Gruppeninformationen</mat-label>
        <textarea
            matInput
            [formControl]="informationenCtrl"
            rows="10"
        ></textarea>
    </mat-form-field>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="accent" (click)="updateKommentar()">Speichern</button>
    <button mat-flat-button (click)="dialogRef.close(null)">Abbrechen</button>
</div>
