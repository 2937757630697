import {Component, OnInit} from '@angular/core';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MessageService} from '../../services/message.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {environment} from '../../../environments/environment';
import {TableColumn} from '../../components/kiene-table/table-column';
import {TableOptionEvent, TableOption} from '../../components/kiene-table/table-option';
import {EditLandkreisDialogComponent} from './edit-dialog/edit-dialog.component';

@Component({
    selector: 'kiene-landkreise',
    templateUrl: './landkreise.component.html',
    styleUrls: ['./landkreise.component.scss']
})
export class LandkreiseComponent implements OnInit {

    tableDescriptor: TableDescriptor = new TableDescriptor();


    constructor(public dialog: MatDialog,
                private router: Router,
                private messageService: MessageService,
                private localStorageService: LocalStorageService,
                private tableService: KieneTableService,
                private api: KieneTableService) {
    }

    ngOnInit() {

        this.tableDescriptor.initialSortColumn = 'bezeichnung';
        this.tableDescriptor.initialSortDirection = 'asc';

        this.tableDescriptor.headline = 'Landkreise';
        this.tableDescriptor.uniqueIdentifier = 'Landkreise';
        this.tableDescriptor.apiUrl = environment.BASE_URI + 'landkreis/';
        this.tableDescriptor.route = '/administration/landkreise/';
        this.tableDescriptor.nameOfIdField = 'landkreis_id';
        this.tableDescriptor.searchPlaceholder = 'Landkreise durchsuchen';

        this.tableDescriptor.createAllowed = false;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;


        this.tableDescriptor.columns.push(new TableColumn('landkreis_id', 'ID'));
        this.tableDescriptor.columns.push(new TableColumn('bezeichnung', 'Landkreis'));
        this.tableDescriptor.columns.push(new TableColumn('bundesland', 'Bundesland'));
        this.tableDescriptor.columns.push(new TableColumn('veterinaeramt', 'Veterinäramt'));
        this.tableDescriptor.columns.push(new TableColumn('ueberwachungsbehoerde', 'Überwachungsbehörde'));
        this.tableDescriptor.columns.push(new TableColumn('editiert', 'Editiert', 'datetime'));


        this.tableDescriptor.permissionUpdate = 4022;

        this.tableDescriptor.options.push(new TableOption('edit', 'editieren', 'edit', 4022));


        this.tableDescriptor.initialSortColumn = 'landkreis_id';
        this.tableDescriptor.initialSortDirection = 'asc';

    }


    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'edit') {
            this.openEditDialog(event);
        }
    }

    openEditDialog(event: TableOptionEvent) {
        let dialogRef = this.dialog.open(EditLandkreisDialogComponent, {
            width: '50%',
            data: {
                landkreis: event.value
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.tableService.reload();
        });
    }

}
