import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {FileUploadService} from '../../../dialogs/file-upload-dialog/file-upload.service';
import {Mandant} from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';
import {KieneUtils} from '../../../kiene-utils';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {MessageService} from '../../../services/message.service';
import {HttpEventType, HttpParams, HttpResponse} from '@angular/common/http';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {LogoPlaceholder} from './logo-placeholder';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {AppService} from '../../../app.service';

@Component({
    selector: 'kiene-mandant-details-dialog',
    templateUrl: './mandant-details-dialog.component.html',
    styleUrls: ['./mandant-details-dialog.component.scss']
})
export class MandantDetailsDialogComponent implements OnInit {

    isLoading = false;

    nameCtrl = new FormControl(undefined, Validators.required);
    praxisNameCtrl = new FormControl(undefined, Validators.required);
    mandantNrCtrl = new FormControl(undefined, [Validators.required, Validators.min(1), Validators.max(999)]);
    praxisVVVOCtrl = new FormControl(undefined, [Validators.required]);
    praxisStrasseCtrl = new FormControl(undefined, Validators.required);
    praxisPlzCtrl = new FormControl(undefined, Validators.required);
    praxisOrtCtrl = new FormControl(undefined, Validators.required);
    praxisEmailCtrl = new FormControl(undefined, [Validators.required, Validators.email]);
    praxisTelefonCtrl = new FormControl(undefined, Validators.required);

    praxisUstIdCtrl = new FormControl();
    praxisSteuernummerCtrl = new FormControl();
    praxisBankNameCtrl = new FormControl();
    praxisBankIbanCtrl = new FormControl();
    praxisBankBicCtrl = new FormControl();
    primanotaBeraterNrCtrl = new FormControl();
    primanotaMandantenNrCtrl = new FormControl();
    bestellungExternKonditionenCtrl = new FormControl();
    bestellungExternRechnungsempfaengerCtrl = new FormControl();
    bestellungExternLieferbedingungenCtrl = new FormControl();
    bestellungExternVersandartCtrl = new FormControl();
    bestellungExternOrtCtrl = new FormControl();

    emailAdresseCtrl = new FormControl(undefined, [Validators.email]);
    emailBenutzerCtrl = new FormControl();
    emailPasswortCtrl = new FormControl();
    emailSmtpCtrl = new FormControl();
    emailSmtpPortCtrl = new FormControl();
    emailSignaturCtrl = new FormControl();
    rechnung_emailAdresseCtrl = new FormControl(undefined, [Validators.email]);
    rechnung_emailBenutzerCtrl = new FormControl();
    rechnung_emailPasswortCtrl = new FormControl();
    rechnung_emailSmtpCtrl = new FormControl();
    rechnung_emailSmtpPortCtrl = new FormControl();
    untersuchungsauftragEmailempfaengerCtrl = new FormControl();

    rechnungTextObenCtrl = new FormControl();
    rechnungTierarztAngebenCtrl = new FormControl(false);
    ortLaborCtrl = new FormControl();
    pdfAbsenderCtrl = new FormControl();
    pdfFarbeHexCtrl = new FormControl();
    pdfFarbeRgbRotCtrl = new FormControl(undefined, [Validators.max(255)]);
    pdfFarbeRgbGruenCtrl = new FormControl(undefined, [Validators.max(255)]);
    pdfFarbeRgbBlauCtrl = new FormControl(undefined, [Validators.max(255)]);
    pdfFooterFontSizeCtrl = new FormControl();
    pdfFooterSpalte1Ctrl = new FormControl();
    pdfFooterSpalte2Ctrl = new FormControl();
    pdfFooterSpalte3Ctrl = new FormControl();
    pdfFooterSpalte4Ctrl = new FormControl();

    hitPinCtrl = new FormControl();

    mandantFields = [];
    hitPinVisible = false;

    qsBenutzerCtrl = new FormControl();
    qsPinCtrl = new FormControl();
    qsPinVisible = false;


    constructor(@Inject(MAT_DIALOG_DATA) public mandant: Mandant,
                public dialogRef: MatDialogRef<MandantDetailsDialogComponent>,
                private fileUploadService: FileUploadService,
                private api: KieneBackendApiService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private appService: AppService,
                private messageService: MessageService,
                private pdfService: PdfViewerDesktopService) {
    }

    ngOnInit(): void {
        this.loadMandant();

        this.pdfFarbeHexCtrl.valueChanges.subscribe(value => {
            if (value?.length === 6 || value?.length === 3) {
                const result = this.hexToRgb(value);
                this.pdfFarbeRgbRotCtrl.setValue(result?.r);
                this.pdfFarbeRgbGruenCtrl.setValue(result?.g);
                this.pdfFarbeRgbBlauCtrl.setValue(result?.b);
            }
        });

    }

    updateRgb() {
        let r = this.pdfFarbeRgbRotCtrl.value;
        let g = this.pdfFarbeRgbGruenCtrl.value;
        let b = this.pdfFarbeRgbBlauCtrl.value;

        if (KieneUtils.notNullOrUndefined(r) && KieneUtils.notNullOrUndefined(g) && KieneUtils.notNullOrUndefined(b)) {
            this.pdfFarbeHexCtrl.setValue(this.rgbToHex(r, g, b), {emitEvent: false});
        }
    }

    hexToRgb(hex: string) {
        // wenn kurzform eingegeben wurde, wie ACE -> AACCEE
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (matcher, r, g, b) {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    rgbToHex(r: number, g: number, b: number) {
        let one = r?.toString(16);
        let two = g?.toString(16);
        let three = b?.toString(16);

        if (one.length === 1) {
            one = '0' + one;
        }
        if (two.length === 1) {
            two = '0' + two;
        }
        if (three.length === 1) {
            three = '0' + three;
        }

        console.log(one + two + three);
        return one + two + three;
    }

    uploadFile(file: File, nameFormField: string) {
        this.appService.setDataLoading(true);
        const fd = new FormData();
        fd.append(nameFormField, file);
        fd.append('mandant_id', this.mandant?.mandant_id?.toString());
        return this.fileUploadService.uploadFormularToServer(fd, 'mandant/upload.php').subscribe({
            next: event => {
                if (event.type === HttpEventType.UploadProgress) {
                    const percentDone = Math.round(100 * event.loaded / event.total);
                    console.log(percentDone);
                } else if (event instanceof HttpResponse) {
                }
            }, error: err => {
                console.error('Error while uploading file: ' + file.name);
                this.messageService.errorMessage('Fehler: Datei konnte nicht hochgeladen werden! ', err);
                this.appService.setDataLoading(false);

            }, complete: () => {
                this.messageService.infoMessage(file.name + ' wurde erfolgreich hochgeladen!');
                this.appService.setDataLoading(false);
                this.loadMandant();
            }
        });
    }

    handleFilesLinks(event) {
        const file = event?.target?.files[0];
        if (file) {
            this.uploadFile(file, 'logo_links');
        }
    }

    handleFilesMitte(event) {
        const file = event?.target?.files[0];
        if (file) {
            this.uploadFile(file, 'logo_mitte');
        }
    }

    handleFilesRechts(event) {
        const file = event?.target?.files[0];
        if (file) {
            this.uploadFile(file, 'logo_rechts');
        }
    }

    save() {
        this.isLoading = true;
        let updateSessionAfterSave = false;
        if (!this.mandant) {
            this.mandant = new Mandant();
        }
        if (!this.mandant.mandant_id) {
            updateSessionAfterSave = true;
        }

        this.mandantFields.forEach(({ctrl, field, isBoolean}) => {
            const value = isBoolean ? (ctrl.value ? 1 : 0) : ctrl.value;
            this.mandant[field] = value;
        });

        this.api.createOrUpdate('mandant', this.mandant, 'mandant_id').subscribe({
            next: response => {
                this.mandant = response;
                this.isLoading = false;
                this.initFormFields();
                this.messageService.infoMessage('Erfolgreich gespeichert!');
                this.dialogRef.close(this.mandant);
                if (updateSessionAfterSave) {
                    this.appService.reloadSession();
                }
            },
            error: err => {
                this.isLoading = false;
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    getLogoLinksSource() {
        let src = '';
        if (this.mandant?.pdf_header_logo_links) {
            src = 'data:image/png;base64, ' + this.mandant?.pdf_header_logo_links;
        } else {
            src = 'data:image/png;base64,' + LogoPlaceholder.placeholderBase64;
        }
        return src;
    }

    getLogoMitteSource() {
        let src = '';
        if (this.mandant?.pdf_header_logo_mitte) {
            src = 'data:image/png;base64, ' + this.mandant?.pdf_header_logo_mitte;
        } else {
            src = 'data:image/png;base64,' + LogoPlaceholder.placeholderBase64;
        }
        return src;
    }

    getLogoRechtsSource() {
        let src = '';
        if (this.mandant?.pdf_header_logo_rechts) {
            src = 'data:image/png;base64, ' + this.mandant?.pdf_header_logo_rechts;
        } else {
            src = 'data:image/png;base64,' + LogoPlaceholder.placeholderBase64;
        }
        return src;
    }

    isMandantValid() {
        return this.nameCtrl.valid && this.praxisNameCtrl.valid && this.mandantNrCtrl.valid && this.praxisVVVOCtrl.valid && this.praxisStrasseCtrl.valid
            && this.praxisOrtCtrl.valid && this.praxisPlzCtrl.valid && this.praxisEmailCtrl.valid && this.praxisTelefonCtrl.valid;
    }

    pdfPreview() {
        const input = new PdfViewerDialogInput('mandant/pdf_test.php', undefined, 'PDF Mandant Vorschau');
        this.pdfService.openPdfViewer(input);
    }

    private initMandantFields() {
        this.mandantFields = [
            {ctrl: this.nameCtrl, field: 'bezeichnung_kurz'},
            {ctrl: this.mandantNrCtrl, field: 'mandant_nr'},
            {ctrl: this.praxisVVVOCtrl, field: 'vvvo'},
            {ctrl: this.praxisNameCtrl, field: 'bezeichnung'},
            {ctrl: this.praxisStrasseCtrl, field: 'strasse'},
            {ctrl: this.praxisPlzCtrl, field: 'plz'},
            {ctrl: this.praxisOrtCtrl, field: 'ort'},
            {ctrl: this.praxisEmailCtrl, field: 'praxis_email_adresse'},
            {ctrl: this.praxisTelefonCtrl, field: 'telefon'},
            {ctrl: this.praxisUstIdCtrl, field: 'ust_id'},
            {ctrl: this.praxisSteuernummerCtrl, field: 'steuernummer'},
            {ctrl: this.praxisBankNameCtrl, field: 'name_bank'},
            {ctrl: this.praxisBankIbanCtrl, field: 'iban'},
            {ctrl: this.praxisBankBicCtrl, field: 'bic'},
            {ctrl: this.primanotaBeraterNrCtrl, field: 'primanota_beraternummer'},
            {ctrl: this.primanotaMandantenNrCtrl, field: 'primanota_mandantennummer'},
            {ctrl: this.bestellungExternKonditionenCtrl, field: 'bestellung_extern_konditionen'},
            {ctrl: this.bestellungExternRechnungsempfaengerCtrl, field: 'bestellung_extern_rechnungsempfaenger'},
            {ctrl: this.bestellungExternLieferbedingungenCtrl, field: 'bestellung_extern_lieferbedingungen'},
            {ctrl: this.bestellungExternVersandartCtrl, field: 'bestellung_extern_versandart'},
            {ctrl: this.bestellungExternOrtCtrl, field: 'bestellung_extern_ort'},
            {ctrl: this.emailAdresseCtrl, field: 'email_adresse'},
            {ctrl: this.emailBenutzerCtrl, field: 'email_nutzer'},
            {ctrl: this.emailPasswortCtrl, field: 'email_passwort'},
            {ctrl: this.emailSmtpCtrl, field: 'email_smtp'},
            {ctrl: this.emailSmtpPortCtrl, field: 'email_port'},
            {ctrl: this.emailSignaturCtrl, field: 'email_signatur'},
            {ctrl: this.rechnung_emailAdresseCtrl, field: 'rechnung_email_adresse'},
            {ctrl: this.rechnung_emailBenutzerCtrl, field: 'rechnung_email_nutzer'},
            {ctrl: this.rechnung_emailPasswortCtrl, field: 'rechnung_email_passwort'},
            {ctrl: this.rechnung_emailSmtpCtrl, field: 'rechnung_email_smtp'},
            {ctrl: this.rechnung_emailSmtpPortCtrl, field: 'rechnung_email_port'},
            {ctrl: this.untersuchungsauftragEmailempfaengerCtrl, field: 'untersuchungsauftrag_emailempfaenger'},
            {ctrl: this.pdfFarbeHexCtrl, field: 'pdf_farbe'},
            {ctrl: this.pdfAbsenderCtrl, field: 'pdf_absender_ueber_anschrift'},
            {ctrl: this.ortLaborCtrl, field: 'ort_labor'},
            {ctrl: this.rechnungTierarztAngebenCtrl, field: 'rechnung_tierarzt_angeben', isBoolean: true},
            {ctrl: this.pdfFooterFontSizeCtrl, field: 'pdf_footer_schriftgroesse'},
            {ctrl: this.pdfFooterSpalte1Ctrl, field: 'pdf_footer_spalte_1'},
            {ctrl: this.pdfFooterSpalte2Ctrl, field: 'pdf_footer_spalte_2'},
            {ctrl: this.pdfFooterSpalte3Ctrl, field: 'pdf_footer_spalte_3'},
            {ctrl: this.pdfFooterSpalte4Ctrl, field: 'pdf_footer_spalte_4'},
            {ctrl: this.hitPinCtrl, field: 'hit_pin'},
            {ctrl: this.qsBenutzerCtrl, field: 'qs_user'},
            {ctrl: this.qsPinCtrl, field: 'qs_pin'},
        ];
    }

    private initFormFields() {

        this.initMandantFields();

        this.mandantFields.forEach(({ctrl, field, isBoolean}) => {
            const value = isBoolean ? !!this.mandant?.[field] : this.mandant?.[field];
            ctrl.setValue(value);
        });
    }

    private loadMandant() {
        if (!this.mandant?.mandant_id) {
            this.initFormFields();
            return;
        }
        this.appService.setDataLoading(true);
        const params = new HttpParams().set('mandant_id', this.mandant?.mandant_id);
        this.api.get(this.apiBaseUrl + 'mandant/read_one.php', params).subscribe({
            next: response => {
                this.mandant = response;
                this.appService.setDataLoading(false);
                this.initFormFields();
            },
            error: err => {
                this.appService.setDataLoading(false);
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}
