<h1 mat-dialog-title>{{edit ? 'Fortbildung editieren' : 'Neue Fortbildung'}}</h1>
<div mat-dialog-content>
    <mat-form-field style="width: 100%;">
        <mat-label>Titel</mat-label>
        <input matInput type="text" [formControl]="titelCtrl">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Veranstalter</mat-label>
        <input matInput type="text" [formControl]="veranstalterCtrl">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Veranstaltungsort</mat-label>
        <input matInput type="text" [formControl]="veranstaltungsortCtrl">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Datum</mat-label>
        <input matInput [matDatepicker]="datum" [formControl]="datumCtrl">
        <mat-datepicker-toggle matSuffix [for]="datum"></mat-datepicker-toggle>
        <mat-datepicker #datum></mat-datepicker>
      </mat-form-field>
      <br>
    <mat-form-field appearance="fill">
        <mat-label>Anmeldefrist</mat-label>
        <input matInput [matDatepicker]="anmeldefrist" [formControl]="anmeldefristCtrl">
        <mat-datepicker-toggle matSuffix [for]="anmeldefrist"></mat-datepicker-toggle>
        <mat-datepicker #anmeldefrist></mat-datepicker>
      </mat-form-field>
      <br>
      <mat-form-field style="width: 100%;" appearance="fill">
        <mat-label>Infotext</mat-label>
        <textarea matInput [formControl]="infotextCtrl" rows="3"></textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</div>
