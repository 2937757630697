import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Material } from '../material';
import { Observable } from 'rxjs';
import { ArtikelRabattstaffelApi, ArtikelRabattstaffel } from './rabatt-staffel.component';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RabattStaffelService {

  constructor(private http: HttpClient) { }

  getRabattStaffeln(materialId: string): Observable<ArtikelRabattstaffelApi> {
    const params = new HttpParams().set('artikel_id', materialId);
    return this.http.get<ArtikelRabattstaffelApi>(environment.BASE_URI + 'artikel_rabattstaffel/read.php', {params: params});
  }

  remove(rs: ArtikelRabattstaffel): Observable<void> {
    const params = new HttpParams().set('ar_id', rs.ar_id.toString());
    return this.http.post<void>(environment.BASE_URI + 'artikel_rabattstaffel/delete.php', rs);
  }

  save(rs: ArtikelRabattstaffel): Observable<ArtikelRabattstaffel> {
    return this.http.post<ArtikelRabattstaffel>(environment.BASE_URI + 'artikel_rabattstaffel/update.php', rs);
  }
}
