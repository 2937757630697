import { DatePipe } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChartType } from 'angular-google-charts';
import { AppService } from '../../../app.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { ZeitauswahlChange } from '../../zeitauswahl/zeitauswahl-change';
import { KundenEinzel } from './kunden-einzel';
import { CsvColumn } from '../../../csv-export/csv-export/csv-column';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { ReportService } from '../../report.service';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { Client } from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ReportUtils } from '../../report-utils';

@Component({
    selector: 'kiene-kunden-einzel-report',
    templateUrl: './kunden-einzel-report.component.html',
    styleUrls: ['./kunden-einzel-report.component.scss'],
})
export class KundenEinzelReportComponent implements OnInit, AfterViewInit {
    displayedColumns = [
        'artikel',
        'artikelmenge',
        'umsatz_prozent',
        'umsatz',
        'wareneinsatz',
        'wareneinsatz_prozent',
        'rohertrag',
        'rohertrag_prozent',
    ];
    dataSource = new MatTableDataSource<KundenEinzel>();
    dataSourceVorjahr = new MatTableDataSource<KundenEinzel>();
    @ViewChild(MatSort) sort: MatSort;
    expandedElement: KundenEinzel | null;

    currentKunde: Client;
    //abgabebelegeGesamt = 0;
    //abgabebelegeGesamtVorjahr = 0;

    rabattCtrl = new FormControl();
    ergaenzerCtrl = new FormControl();
    aktuellSubscription: Subscription;
    vorjahrSubscription: Subscription;

    zeitauswahl: ZeitauswahlChange;
    vorjahreszeitraum: ZeitauswahlChange;

    chartType = ChartType.PieChart;
    chartColumns = ['Artikel', 'Umsatz'];
    chartData = [['', 0]];

    csvColumns: CsvColumn[] = [];
    csvColumnsDetails: CsvColumn[] = [];
    csvFilename = 'Kunde_Einzel.csv';

    gesamtUmsatz = 0;
    gesamtWareneinsatzProzent = '0';
    gesamtWareneinsatz = 0;
    gesamtRohertrag = 0;
    gesamtRohertragProzent = '0';

    gesamtUmsatzVorjahr = 0;
    gesamtWareneinsatzProzentVorjahr = '0';
    gesamtWareneinsatzVorjahr = 0;
    gesamtRohertragVorjahr = 0;
    gesamtRohertragProzentVorjahr = '0';

    isLoading: boolean[] = [];

    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneTableService,
        private appService: AppService,
        private datePipe: DatePipe,
        private reportService: ReportService,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.csvColumns.push(new CsvColumn('artikel', 'Artikel'));
        this.csvColumns.push(new CsvColumn('artikelmenge', 'Menge'));
        this.csvColumns.push(
            new CsvColumn('umsatz_prozent', 'Umsatz von Gesamt (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('umsatz', 'Umsatz'));
        this.csvColumns.push(new CsvColumn('wareneinsatz', 'Wareneinsatz'));
        this.csvColumns.push(
            new CsvColumn('wareneinsatz_prozent', 'Wareneinsatz (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('rohertrag', 'Rohertrag'));
        this.csvColumns.push(
            new CsvColumn('rohertrag_prozent', 'Rohertrag (%)', '%')
        );

        this.csvColumnsDetails = this.csvColumns.map((column) => ({
            ...column,
        }));
        this.csvColumnsDetails.splice(
            0,
            1,
            new CsvColumn('zeitraum', 'Zeitraum')
        );
    }

    ngAfterViewInit() {
        this.sort.sort({ id: 'umsatz', start: 'desc' } as MatSortable);
        this.dataSource.sort = this.sort;
    }

    loadData() {
        this.loadDataAktuelle();
        this.loadDataVorjahr();
    }

    loadDataAktuelle() {
        if (!this.currentKunde || !this.currentKunde.kunde_id) {
            return;
        }

        this.appService.setDataLoading(true);

        let params = new HttpParams();
        params = params.set('rabatt', this.rabattCtrl.value ? '1' : '0');
        params = params.set('ergaenzer', this.ergaenzerCtrl.value ? '1' : '0');
        params = params.set(
            'kunde_id',
            this.currentKunde.kunde_id.toString()
        );
        params = params.set(
            'von',
            this.datePipe.transform(this.zeitauswahl.von, 'yyyy-MM-dd')
        );
        params = params.set(
            'bis',
            this.datePipe.transform(this.zeitauswahl.bis, 'yyyy-MM-dd')
        );

        this.aktuellSubscription?.unsubscribe();
        this.aktuellSubscription = this.api
            .get(
                this.apiBaseUrl + 'report/read_kunde_kunde.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSource.data = response.records;
                    this.berechneGesamtwerte();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    loadDataVorjahr() {
        if (!this.currentKunde || !this.currentKunde.kunde_id) {
            return;
        }

        this.appService.setDataLoading(true);

        let params = new HttpParams();
        params = params.set('rabatt', this.rabattCtrl.value ? '1' : '0');
        params = params.set('ergaenzer', this.ergaenzerCtrl.value ? '1' : '0');
        params = params.set(
            'kunde_id',
            this.currentKunde.kunde_id.toString()
        );
        params = params.set(
            'von',
            this.datePipe.transform(this.vorjahreszeitraum.von, 'yyyy-MM-dd')
        );
        params = params.set(
            'bis',
            this.datePipe.transform(this.vorjahreszeitraum.bis, 'yyyy-MM-dd')
        );

        this.vorjahrSubscription?.unsubscribe();
        this.vorjahrSubscription = this.api
            .get(
                this.apiBaseUrl + 'report/read_kunde_kunde.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSourceVorjahr.data = response.records;
                    this.berechneGesamtwerteVorjahr();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    setCurrentClient(client: Client) {
        if (client) {
            this.currentKunde = client;
            this.loadData();
        } else {
            this.clearAll();
        }
    }

    clearAll() {
        this.dataSource.data = [];
        this.berechneGesamtwerte();
        this.berechneGesamtwerteVorjahr();
    }

    zeitintervalChanged(event: ZeitauswahlChange) {
        this.zeitauswahl = event;
        this.vorjahreszeitraum = ReportUtils.getVorjahreszeitraum(this.zeitauswahl);
        this.loadData();
    }

    berechneGesamtwerte() {
        const data = this.dataSource.data;
        this.chartData = [['', 0]];
        this.gesamtUmsatz = 0;
        this.gesamtWareneinsatz = 0;
        this.gesamtRohertrag = 0;
        for (const r of data) {
            this.chartData.push([r.artikel, r.umsatz]);
            this.gesamtUmsatz += r.umsatz ? r.umsatz : 0;
            this.gesamtWareneinsatz += r.wareneinsatz ? r.wareneinsatz : 0;
            this.gesamtRohertrag += r.rohertrag ? r.rohertrag : 0;
        }
        if (this.gesamtUmsatz > 0) {
            this.gesamtWareneinsatzProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtWareneinsatz * 100
                ) / 100
            ).toString();
            this.gesamtRohertragProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtRohertrag * 100
                ) / 100
            ).toString();
        } else {
            this.gesamtWareneinsatzProzent = '0.00';
            this.gesamtRohertragProzent = '0.00';
        }
    }

    berechneGesamtwerteVorjahr() {
        const data = this.dataSourceVorjahr.data;
        this.gesamtUmsatzVorjahr = 0;
        this.gesamtWareneinsatzVorjahr = 0;
        this.gesamtRohertragVorjahr = 0;
        for (const r of data) {
            this.gesamtUmsatzVorjahr += r.umsatz ? r.umsatz : 0;
            this.gesamtWareneinsatzVorjahr += r.wareneinsatz ? r.wareneinsatz : 0;
            this.gesamtRohertragVorjahr += r.rohertrag ? r.rohertrag : 0;
        }
        if (this.gesamtUmsatzVorjahr > 0) {
            this.gesamtWareneinsatzProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtWareneinsatzVorjahr * 100
                ) / 100
            ).toString();
            this.gesamtRohertragProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtRohertragVorjahr * 100
                ) / 100
            ).toString();
        } else {
            this.gesamtWareneinsatzProzentVorjahr = '0.00';
            this.gesamtRohertragProzentVorjahr = '0.00';
        }
    }

    rueckverguetung() {
        this.reportService.rueckverguetung(
            this.currentKunde.kunde_id,
            this.zeitauswahl.von,
            this.zeitauswahl.bis
        );
    }

}
