import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GruppeTierCardComponent } from './gruppe-tier-card/gruppe-tier-card.component';
import { OhrmarkeModule } from '../../ohrmarke/ohrmarke.module';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { KieneCoreModule } from '../../kiene-core.module';



@NgModule({
  declarations: [
    GruppeTierCardComponent
  ],
  imports: [
    CommonModule,
    OhrmarkeModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    KieneCoreModule
  ],
  exports: [
    GruppeTierCardComponent
  ]
})
export class GruppeTierCardModule { }
