import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Verkaufspreis } from '../../components/verkaufspreis-selector/verkaufspreis-selector.component';
import { Betriebsart } from '../../../../projects/kiene-core/src/lib/components/stammdaten/vvvo/classes/betriebsart';

@Component({
  selector: 'kiene-edit-betriebsart-dialog',
  templateUrl: './edit-betriebsart-dialog.component.html',
  styleUrls: ['./edit-betriebsart-dialog.component.scss']
})
export class EditBetriebsartDialogComponent implements OnInit, AfterViewInit {

  betriebsartCtrl = new UntypedFormControl();
  rabattCtrl = new UntypedFormControl();
  sortierungCtrl = new UntypedFormControl();
  kaelbermastCtrl = new UntypedFormControl();
  ohrmarkeCtrl = new UntypedFormControl();
  qsCtrl = new UntypedFormControl(false);
  hitCtrl = new UntypedFormControl(false);

  betriebsart = new Betriebsart();

  constructor(public dialogRef: MatDialogRef<EditBetriebsartDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.betriebsart = data.betriebsart;
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.rabattCtrl.setValue(this.betriebsart.rabatt);
      this.betriebsartCtrl.setValue(this.betriebsart.bezeichnung);
      this.sortierungCtrl.setValue(this.betriebsart.sortierung);
      this.qsCtrl.setValue(this.betriebsart.qs);
      this.hitCtrl.setValue(this.betriebsart.hit);
    }, 10);
  }

  setVerkaufspreis(event: Verkaufspreis) {
    this.betriebsart.verkaufspreis_id = event.verkaufspreis_id;
  }

  save() {
    this.betriebsart.rabatt = this.rabattCtrl.value;
    this.betriebsart.bezeichnung = this.betriebsartCtrl.value;
    this.betriebsart.sortierung = this.sortierungCtrl.value;
    this.betriebsart.kaelbermast = this.kaelbermastCtrl.value ? 1 : 0;
    this.betriebsart.ohrmarke = this.ohrmarkeCtrl.value ? 1 : 0;
    this.betriebsart.qs = this.qsCtrl.value ? 1 : 0;
    this.betriebsart.hit = this.hitCtrl.value ? 1 : 0;
    this.dialogRef.close(this.betriebsart);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
