<h1 mat-dialog-title>Freigabe erteilen</h1>
<div mat-dialog-content>
    <mat-stepper orientation="vertical">
        <mat-step>
            <ng-template matStepLabel>Freizugebener Betrieb</ng-template>
            <kiene-autocomplete [placeholder]="'Freizugebener Betrieb:'"
                                [genericApiUrl]="apiBaseUrl + 'kunde/search.php'"
                                [params]="params"
                                (elementSelected)="setClient($event)" [showClear]="true"></kiene-autocomplete>
            <div class="one-row">
                <button mat-button matStepperNext>Weiter</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Empf&auml;nger VVVO</ng-template>
            <mat-form-field>
                <mat-label>VVVO eintragen</mat-label>
                <input matInput type="text" [formControl]="vvvoCtrl" minlength="12" maxlength="15">
                <span matSuffix>({{ vvvoCtrl.value?.length }}/15)</span>
            </mat-form-field>
            <div class="one-row">
                <button mat-button matStepperPrevious>Zur&uuml;ck</button>
                <span class="spacer"></span>
                <button mat-button matStepperNext>Weiter</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Freigabe erteilen</ng-template>
            <button mat-flat-button color="accent" (click)="freigabeErteilen()" [disabled]="!vvvoCtrl.valid || !kunde">
                Freigabe erteilen
            </button>
            <div class="one-row">
                <button mat-button matStepperPrevious>Zur&uuml;ck</button>
                <span class="spacer"></span>
            </div>
        </mat-step>
    </mat-stepper>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close(false)">Abbrechen</button>
</div>
