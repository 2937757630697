import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, } from '@angular/common/http';
import { Antibiogramm, AntibiogrammApi, AntibiogrammTierartApi, PrueferApi, VerdachtApi, } from './antibiogramm';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { Router } from '@angular/router';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';

@Injectable({
    providedIn: 'root',
})
export class AntibiogrammService {
    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private router: Router,
        private pdfService: PdfViewerDesktopService,
        private api: KieneTableService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
    ) {
    }

    getAllAntibiogramme(
        sortColumn: string,
        sortDirection: string,
        offset: number,
        limit: number,
        search: string,
        onlyMyVetGroups: boolean,
        params: HttpParams
    ): Observable<AntibiogrammApi> {
        const cu = this.localStorageService.getCurrentUser();

        if (!params) {
            params = new HttpParams();
        }

        const currentRoute = this.router.url;
        if (currentRoute && currentRoute.startsWith('/belege/')) {
            if (
                this.localStorageService.getCurrentClient() &&
                this.localStorageService.getCurrentClient().kunde_id
            ) {
                params = params.append(
                    'kunde_id',
                    this.localStorageService
                        .getCurrentClient()
                        .kunde_id.toString()
                );
            }
        }

        if (sortColumn) {
            if (!sortDirection) {
                sortDirection = 'asc';
            }
            params = params.append('orderby', sortColumn + ' ' + sortDirection);
        }

        if (limit) {
            params = params.append('limit', limit.toString());
        }

        if (offset) {
            params = params.append('offset', offset.toString());
        }

        if (search) {
            params = params.append('search', search);
        }

        if (cu.tierarzt_id) {
            if (onlyMyVetGroups) {
                params = params.append('meinetierarztgruppen', '1');
            } else {
                params = params.append('meinetierarztgruppen', '0');
            }
        }

        return this.http.get<AntibiogrammApi>(
            this.apiBaseUrl + 'antibiogramm/read.php',
            { params: params }
        );
    }

    getAllPruefer(): Observable<PrueferApi> {
        return this.http.get<PrueferApi>(
            this.apiBaseUrl + 'pruefer/read.php'
        );
    }

    getAntibiogramm(id: string): Observable<Antibiogramm> {
        return this.http.get<Antibiogramm>(
            this.apiBaseUrl +
            'antibiogramm/read_one.php?antibiogramm_id=' +
            id
        );
    }

    createAntibiogramm(antibiogramm: Antibiogramm): Observable<Antibiogramm> {
        return this.http.post<Antibiogramm>(
            this.apiBaseUrl + 'antibiogramm/create.php',
            antibiogramm
        );
    }

    recreateAntibiogramm(antibiogramm: Antibiogramm): Observable<Antibiogramm> {
        return this.http.get<Antibiogramm>(
            this.apiBaseUrl +
            'antibiogramm/update_pdf.php?antibiogramm_id=' +
            antibiogramm.antibiogramm_id
        );
    }

    updateAntibiogramm(antibiogramm: Antibiogramm): Observable<Antibiogramm> {
        return this.http.post<Antibiogramm>(
            this.apiBaseUrl + 'antibiogramm/update.php',
            antibiogramm
        );
    }

    deleteAntibiogramm(antibiogramm: Antibiogramm): Observable<void> {
        return this.http.post<void>(
            this.apiBaseUrl + 'antibiogramm/delete.php',
            antibiogramm
        );
    }

    searchTierarten(searchString: string): Observable<AntibiogrammTierartApi> {
        return this.http.get<AntibiogrammTierartApi>(
            this.apiBaseUrl +
            'tierart/read_labor.php?search=' +
            searchString
        );
    }

    searchVerdachte(searchString: string): Observable<VerdachtApi> {
        return this.http.get<VerdachtApi>(
            this.apiBaseUrl + 'verdacht/read.php?search=' + searchString
        );
    }

    showPdf(antibiogramm: Antibiogramm) {
        let titel: string = antibiogramm.gruppe_nr_intern
            ? antibiogramm.gruppe_nr_intern
            : antibiogramm.kunde_nr_intern?.toString();
        titel +=
            ' ' +
            (antibiogramm.kunde ? antibiogramm.kunde : '') +
            ', ' +
            (antibiogramm.kunde_ort ? antibiogramm.kunde_ort : '') +
            ' (' +
            (antibiogramm.tagebuch_nr ? antibiogramm.tagebuch_nr : '') +
            ')';

        const input = new PdfViewerDialogInput(
            'antibiogramm/read_or_mail_pdf.php',
            new HttpParams().append(
                'antibiogramm_id',
                antibiogramm?.antibiogramm_id?.toString()
            ),
            titel,
            {
                email: {
                    sende_mail: true,
                    kunde_id: antibiogramm.kunde_id,
                }
            }
        );
        this.pdfService.openPdfViewer(input).subscribe({
            next: () => {
                this.api.reload('showPdf');
            }
        });
    }
}
