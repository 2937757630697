<h2 mat-dialog-title>Tierart für {{ material.bezeichnung }}</h2>
<mat-dialog-content>
    <mat-form-field style="width: 100%">
        <mat-label>Tierart</mat-label>
        <mat-select
            [compareWith]="tierartComparator"
            [formControl]="tierartCtrl"
        >
            <mat-option *ngFor="let t of tierarten" [value]="t">{{
                t.tierart
            }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field style="width: 100%">
        <mat-label>Menge</mat-label>
        <input type="number" matInput [(ngModel)]="artikelTierart.menge" />
    </mat-form-field>

    <kiene-einheit-selector
        [preselected]="einheit"
        (changed)="setEinheit($event)"
    ></kiene-einheit-selector>

    <!-- <mat-form-field style="width: 100%;">
    <mat-label>Wartezeit Fleisch</mat-label>
    <input type="number" matInput [(ngModel)]="artikelTierart.wartezeit_tage">
  </mat-form-field>

  <mat-form-field style="width: 100%;">
    <mat-label>Wartezeit Milch</mat-label>
    <input type="number" matInput [(ngModel)]="artikelTierart.wartezeit_milch">
  </mat-form-field>

  <mat-form-field style="width: 100%;">
    <mat-label>Wartezeit Eier</mat-label>
    <input type="number" matInput [(ngModel)]="artikelTierart.wartezeit_eier">
  </mat-form-field> -->

    <mat-form-field style="width: 100%">
        <mat-label>Behandlung in Tagen</mat-label>
        <input
            type="number"
            matInput
            [(ngModel)]="artikelTierart.behandlung_tage"
        />
    </mat-form-field>

    <mat-form-field style="width: 100%">
        <mat-label>Intervall in Stunden</mat-label>
        <input
            type="number"
            matInput
            [(ngModel)]="artikelTierart.intervall_std"
        />
    </mat-form-field>

    <mat-form-field style="width: 100%">
        <mat-label>Faktor</mat-label>
        <input type="number" matInput [(ngModel)]="artikelTierart.faktor" />
    </mat-form-field>

    <mat-form-field style="width: 100%">
        <mat-label>Körpergewicht in KG</mat-label>
        <input
            type="number"
            matInput
            placeholder=""
            [(ngModel)]="artikelTierart.koerpergewicht"
        />
    </mat-form-field>

    <mat-form-field style="width: 100%">
        <mat-label>Dosierungstext</mat-label>
        <input
            type="text"
            matInput
            placeholder=""
            [(ngModel)]="artikelTierart.dosierung_txt"
        />
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
    <button mat-raised-button color="primary" (click)="save()">
        Speichern
    </button>
</mat-dialog-actions>
