import { UserRole } from '../../../../../../../src/app/administration/user/user';

export class User {
    benutzer_id: number;

    titel: string;
    vorname: string;
    nachname: string;
    email: string;
    mobil: string;
    telefon: string;
    passwort: string;
    passwort_aendern: number;

    strasse: string;
    plz: string;

    benutzerrollen: UserRole[] = [];

    erstellt: Date;
    admin: number;

    elemente_pro_seite: number;
    info_bestandsbesuchsprotokoll: number;
    info_antibiogramme: number;
    abgabebeleg_duplikat: number;
    identifikation_aus_historie: number;
    abgabebeleg_artikeleingabe_aus_historie: number;
    automatische_chargenauswahl: number;
    nur_chargen_aus_wagen: number;
    immer_unterschreiben: number;
    tierarzt_id: number;
    tierarztgruppe_id: number;

    unterschrift_png: string;
    unterschrift: string; // dateiname

    constructor(options?: { benutzer_id?: number }) {
        if (options?.benutzer_id) {
            this.benutzer_id = options?.benutzer_id;
        }
    }
}
