import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Haendler} from "../haendler";

@Component({
  selector: 'kiene-haendler-details-dialog',
  templateUrl: './haendler-details-dialog.component.html',
  styleUrls: ['./haendler-details-dialog.component.scss']
})
export class HaendlerDetailsDialogComponent implements OnInit {

    haendler: Haendler;
    constructor(
        public dialogRef: MatDialogRef<HaendlerDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        if (data?.haendler) {
           this.haendler = data.haendler;
        }
    }

    ngOnInit(): void {
    }

}
