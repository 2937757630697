import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MessageService} from '../../../services/message-service/message.service';
import {GruppenService} from '../gruppen.service';
import {Haendler} from '../../stammdaten/haendler/haendler';
import {Gruppe} from '../classes/gruppe';
import {UntypedFormControl} from '@angular/forms';
import {HttpParams} from '@angular/common/http';
import {
    GruppeReklamationPdfHaendlerAuswahlDialogOutput
} from './classes/gruppe-reklamation-pdf-haendler-auswahl-dialog-output';
import {Empfaenger} from '../../pdf-viewer/kiene-pdf-viewer/classes/empfaenger';
import {KieneUtils} from '../../../utils/KieneUtils';

@Component({
  selector: 'kiene-gruppe-reklamation-pdf-haendler-auswahl-dialog',
  templateUrl: './gruppe-reklamation-pdf-haendler-auswahl-dialog.component.html',
  styleUrls: ['./gruppe-reklamation-pdf-haendler-auswahl-dialog.component.scss']
})
export class GruppeReklamationPdfHaendlerAuswahlDialogComponent implements OnInit {

    haendler: Haendler[] = [];
    haendlerCtrl: UntypedFormControl = new UntypedFormControl();
    isLoading = true;
    gruppe: Gruppe;
    alleHaendler = new Haendler();

    constructor(
      public dialogRef: MatDialogRef<GruppeReklamationPdfHaendlerAuswahlDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: Gruppe,
      private messageService: MessageService,
      private gruppenService: GruppenService
    ) {
      this.gruppe = data;
      this.ladeHaendlerFuerGruppe();
    }

    ngOnInit(): void {
    }

    private ladeHaendlerFuerGruppe() {
       this.gruppenService.ladeViehhaendlerFuerGruppe(this.gruppe).subscribe({
           next: resp => {
               this.isLoading = false;
               this.haendler = resp.records;
               this.haendlerCtrl.setValue(this.alleHaendler);
           },
           error: err => {
               this.isLoading = false;
               this.messageService.errorMessage('Fehler beim Laden der Händler!', err);
           }
       });
    }

    compareHaendler(h1: Haendler, h2: Haendler) {
        return h1 && h2 && h1.viehhaendler_id === h2.viehhaendler_id;
    }

    close() {
        const output = new GruppeReklamationPdfHaendlerAuswahlDialogOutput();
        const haendler: Haendler = this.haendlerCtrl.value;
        let params = new HttpParams();
        if (haendler) {
            if (haendler.viehhaendler_id) {
                params = params.set('viehhaendler_id', this.haendlerCtrl.value.viehhaendler_id);
            }
            if (haendler.email) {
                output.empfaenger = new Empfaenger(haendler.email, haendler.name, false);
            }
        }
        output.params = params;
        this.dialogRef.close(output);
    }

    sendePdfAnAlleHaendler() {
        this.gruppenService.sendePdfAnAlleHaendler(this.gruppe?.gruppe_id).subscribe({
            next: () => this.messageService.infoMessage('Die E-Mails werden im Hintergrund versandt.'),
            error: error => this.messageService.errorMessage('Fehler beim Versenden der E-Mails!', error)
        });
    }

    isEmailVersandMoeglich() {
        return this.haendler?.some(haendler => KieneUtils.isNNoU(haendler.email));
    }
}
