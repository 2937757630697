import {Injectable} from '@angular/core';
import {NativeDateAdapter} from '@angular/material/core';

@Injectable()
export class KieneDateAdapter extends NativeDateAdapter {

    radix = 10;

    setFirstDayOfWeek(): number {
        return 1;
    }

    parse(value: any): Date {
        if (value && typeof value == 'string') {
            const dateString: string = value;
            if (dateString.includes('.')) {
                const dateParts: string[] = dateString.split('.');
                if (dateParts.length === 2) {
                    const d = new Date();
                    // d.setDate(Number.parseInt(dateParts[0]));
                    d.setMonth(Number.parseInt(dateParts[1], this.radix) - 1, Number.parseInt(dateParts[0], this.radix));
                    return d;
                } else if (dateParts.length === 3) {
                    const d = new Date();
                    // d.setDate(Number.parseInt(dateParts[0]));
                    d.setMonth(Number.parseInt(dateParts[1], this.radix) - 1, Number.parseInt(dateParts[0], this.radix));
                    const yearString = dateParts[2];
                    if (yearString.length === 2) {
                        const yearNumber = Number.parseInt(yearString, this.radix);
                        d.setFullYear(2000 + yearNumber);
                    } else if (yearString.length === 4) {
                        d.setFullYear(Number.parseInt(yearString, this.radix));
                    }
                    return d;
                }
            } else {
                //ddMMYYYY
                if (dateString.length === 8) {
                    // console.debug('datestring: %s', dateString);
                    const d = dateString.substr(0, 2);
                    // console.debug('d: %s', d);
                    const m = dateString.substr(2, 2);
                    const y = dateString.substr(4, 4);
                    let date = new Date();
                    // date.setDate(Number.parseInt(d));
                    date.setMonth(Number.parseInt(m, this.radix) - 1, Number.parseInt(d, this.radix));
                    date.setFullYear(Number.parseInt(y, this.radix));
                    // console.debug('ddMMYYYY: %s', date);
                    return date;
                } else if (dateString.length === 6) {
                    //ddMMYY
                    const d = dateString.substr(0, 2);
                    const m = dateString.substr(2, 2);
                    const y = dateString.substr(4, 4);
                    const date = new Date();
                    // date.setDate(Number.parseInt(d));
                    date.setMonth(Number.parseInt(m, this.radix) - 1, Number.parseInt(d, this.radix));
                    date.setFullYear(2000 + Number.parseInt(y, this.radix));
                    return date;
                }
            }
        }
    }

    format(date: Date, displayFormat: Object): string {
        // console.debug('format: %s', date);
        let day: string = date.getDate().toString();
        day = +day < 10 ? '0' + day : day;
        let month: string = (date.getMonth() + 1).toString();
        month = +month < 10 ? '0' + month : month;
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;

    }

    getFirstDayOfWeek(): number {
        return 1;
    }
}

export const KIENE_DATE_FORMATS = {
    parse: {
        dateInput: 'dd.MM.YYYY',
    },
    display: {
        dateInput: 'dd.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'MMM YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
