import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {API_BASE_URL_SERVICE} from '../../../../kiene-core.config';
import {GruppenService} from '../../gruppen.service';
import {MessageService} from '../../../../services/message-service/message.service';
import {KieneUtils} from '../../../../utils/KieneUtils';
import {
    EinstallenMelden,
    EinstallenMeldenDialogInput,
    EinstallenMeldenDialogResult,
} from '../../classes/einstallen-melden';
import {KieneBackendApiService} from '../../../../services/backend-api/kiene-backend-api.service';
import {Subscription} from 'rxjs';
import {GruppeTier} from '../../../gruppe-tier/classes/gruppe-tier';

@Component({
    selector: 'kiene-einstallen-melden-dialog',
    templateUrl: './einstallen-melden-dialog.component.html',
    styleUrls: ['./einstallen-melden-dialog.component.scss'],
})
export class EinstallenMeldenDialogComponent implements OnInit {
    readonly HIT_MELDEN_PASS_UND_OHRMARKE = 'hit_melden_pass_und_ohrmarke';
    readonly HIT_MELDEN_NUR_PASS_GESCANNT = 'hit_melden_nur_pass_gescannt';
    readonly HIT_MELDEN_NUR_OHRMARKE_GESCANNT =
        'hit_melden_nur_ohrmarke_gescannt';
    readonly HIT_MELDEN_WEDER_PASS_NOCH_OHRMARKE =
        'hit_melden_weder_pass_noch_ohrmarke';

    subscription: Subscription;
    isLoading = true;

    pass_und_ohrmarke = false;
    nur_pass_gescannt = false;
    nur_ohrmarke_gescannt = false;
    weder_pass_noch_ohrmarke = false;

    anzahl_pass_ohrmarke = 0;
    anzahl_pass = 0;
    anzahl_ohrmarke = 0;
    anzahl_csv = 0;
    nicht_meldbar = 0;

    constructor(
        public dialogRef: MatDialogRef<EinstallenMeldenDialogComponent>,
        private gruppenService: GruppenService,
        private messageService: MessageService,
        private api: KieneBackendApiService,
        @Inject(MAT_DIALOG_DATA) public data: EinstallenMeldenDialogInput,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        this.ladeTiere();
    }

    ladeTiere() {
        this.isLoading = true;
        this.anzahl_pass_ohrmarke = 0;
        this.anzahl_pass = 0;
        this.anzahl_ohrmarke = 0;
        this.anzahl_csv = 0;
        this.nicht_meldbar = 0;
        this.subscription?.unsubscribe();
        this.subscription = this.gruppenService
            .ladeGruppenTiere(this.data?.gruppe_id)
            .subscribe({
                next: (response) => {
                    response.records.forEach((tier: GruppeTier) => {
                        if (
                            KieneUtils.isNoU(tier.kp_einstalldatum) &&
                            KieneUtils.isNoU(tier.hit_einstalldatum)
                        ) {
                            this.nicht_meldbar++;
                        } else if (
                            tier.ohrmarke_gescannt >= 1 &&
                            tier.pass_gescannt >= 1
                        ) {
                            this.anzahl_pass_ohrmarke++;
                        } else if (
                            tier.ohrmarke_gescannt >= 1 &&
                            tier.pass_gescannt === 0
                        ) {
                            this.anzahl_ohrmarke++;
                        } else if (
                            tier.ohrmarke_gescannt === 0 &&
                            tier.pass_gescannt >= 1
                        ) {
                            this.anzahl_pass++;
                        } else {
                            // alle anderen Fälle abfangen
                            // if (tier.csv_import >= 1)
                            this.anzahl_csv++;
                        }
                    });
                    this.isLoading = false;
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler beim Laden!', err);
                    this.isLoading = false;
                },
            });
    }

    ngOnInit(): void {
        this.variablenLaden();
    }

    variablenSpeichern() {
        this.api.setSpeicher(
            this.HIT_MELDEN_PASS_UND_OHRMARKE,
            this.pass_und_ohrmarke
        );
        this.api.setSpeicher(
            this.HIT_MELDEN_NUR_PASS_GESCANNT,
            this.nur_pass_gescannt
        );
        this.api.setSpeicher(
            this.HIT_MELDEN_NUR_OHRMARKE_GESCANNT,
            this.nur_ohrmarke_gescannt
        );
        this.api.setSpeicher(
            this.HIT_MELDEN_WEDER_PASS_NOCH_OHRMARKE,
            this.weder_pass_noch_ohrmarke
        );
    }

    variablenLaden() {
        this.api.getSpeicher(this.HIT_MELDEN_PASS_UND_OHRMARKE).subscribe({
            next: (result) => {
                if (KieneUtils.isNotNullOrUndefined(result)) {
                    this.pass_und_ohrmarke = result;
                }
            },
        });

        this.api.getSpeicher(this.HIT_MELDEN_NUR_PASS_GESCANNT).subscribe({
            next: (result) => {
                if (KieneUtils.isNotNullOrUndefined(result)) {
                    this.nur_pass_gescannt = result;
                }
            },
        });

        this.api.getSpeicher(this.HIT_MELDEN_NUR_OHRMARKE_GESCANNT).subscribe({
            next: (result) => {
                if (KieneUtils.isNotNullOrUndefined(result)) {
                    this.nur_ohrmarke_gescannt = result;
                }
            },
        });

        this.api
            .getSpeicher(this.HIT_MELDEN_WEDER_PASS_NOCH_OHRMARKE)
            .subscribe({
                next: (result) => {
                    if (KieneUtils.isNotNullOrUndefined(result)) {
                        this.weder_pass_noch_ohrmarke = result;
                    }
                },
            });
    }

    save() {
        this.variablenSpeichern();

        const einstallenMelden = new EinstallenMelden();
        einstallenMelden.gruppe_id = this.data?.gruppe_id;
        einstallenMelden.nur_ohrmarke_gescannt = this.nur_ohrmarke_gescannt
            ? 1
            : 0;
        einstallenMelden.nur_pass_gescannt = this.nur_pass_gescannt ? 1 : 0;
        einstallenMelden.pass_und_ohrmarke = this.pass_und_ohrmarke ? 1 : 0;
        einstallenMelden.weder_pass_noch_ohrmarke = this
            .weder_pass_noch_ohrmarke
            ? 1
            : 0;

        this.gruppenService.einstallenMelden(einstallenMelden).subscribe({
            next: () => {
                this.messageService.infoMessage(
                    'Die Tiere werden im Hintergrund an HI-Tier gemeldet.'
                );

                const result = new EinstallenMeldenDialogResult();
                result.ok = true;
                result.melden = true;
                this.dialogRef.close(result);
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Fehler beim Melden der Tiere!',
                    err
                );
            },
        });
    }

    close() {
        const result = new EinstallenMeldenDialogResult();
        result.ok = false;
        this.dialogRef.close(result);
    }

    tiereOhneEinstalldatumAnzeigen() {
        const result = new EinstallenMeldenDialogResult();
        result.tiereOhneEinstalldatumAnzeigen = true;
        this.dialogRef.close(result);
    }
}
