import {Inject, Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, UrlSegment} from "@angular/router";
import {Observable, Subject} from "rxjs";
import {DebugDialogComponent} from "../../projects/kiene-core/src/lib/components/debug-dialog/debug-dialog.component";
import {KieneSession} from '../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import {KieneBackendApiService} from '../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../projects/kiene-core/src/lib/kiene-core.config';
import {LocalStorageService} from '../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MessageService} from './services/message.service';

@Injectable({
    providedIn: "root",
})
export class AppService {
    private dataLoading = new Subject<boolean>();

    constructor(
        private route: ActivatedRoute,
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private localStorageService: LocalStorageService,
        private messageService: MessageService,
        private dialog: MatDialog) {
        this.monitorCurrentRoute();
    }

    public monitorCurrentRoute() {
        this.route.url.subscribe((urlSegments: UrlSegment[]) => {
            urlSegments.forEach((segment) => {
                console.debug(segment.path);
            });
        });
    }

    setDataLoading(loading: boolean) {
        this.dataLoading.next(loading);
    }

    watchDataLoading(): Observable<boolean> {
        return this.dataLoading.asObservable();
    }

    openDebugDialog() {
        this.dialog.open(DebugDialogComponent);
    }

    reloadSession() {
        this.api.post(this.apiBaseUrl + 'session/refresh.php', undefined).subscribe({
            next: (session: KieneSession) => {
                this.localStorageService.setCurrentUser(session);
                this.messageService.infoMessage('Session wurde erfolgreich aktualisiert!');
                location.reload();
            },
            error: err => {
                this.messageService.errorMessage('Session konnte nicht aktualisiert werden!', err);
            }
        });
    }
}
