import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './app.auth.guard';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { VersionInfoComponent } from './version-info/version-info.component';
import { EditTourPageComponent } from './components/tourenplan/edit-tour-page/edit-tour-page.component';
import { RegisterComponent } from './register/register.component';

const appRoutes: Routes = [
    // {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
    // {path: 'startseite', component: HomeComponent, canActivate: [AuthGuard]},
    { path: 'edittourenplan/:id', component: EditTourPageComponent, canActivate: [AuthGuard] },
    {
        path: 'startseite',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'administration',
        loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'profil',
        loadChildren: () => import('./profile-settings/profile-settings.module').then(m => m.ProfileSettingsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'stammdaten', loadChildren: () => import('./stammdaten/stammdaten.module').then(m => m.StammdatenModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'lager', loadChildren: () => import('./warehouse/warehouse.module').then(m => m.WarehouseModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'wawi', loadChildren: () => import('./wa-wi/wa-wi.module').then(m => m.WaWiModule),
        canActivate: [AuthGuard]
    },

    {
        path: 'belege', loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'labor', loadChildren: () => import('./laboratory/laboratory.module').then(m => m.LaboratoryModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'finanzen',
        loadChildren: () => import('./finanzen/finanzen.module').then(m => m.FinanzenModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'reporte',
        loadChildren: () => import('./report/report.module').then(m => m.ReportModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'heimtiere',
        loadChildren: () => import('./heimtiere/heimtiere.module').then(m => m.HeimtiereModule),
        canActivate: [AuthGuard]
    },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    {
        path: 'initial-setup',
        loadChildren: () => import('./initial-setup/initial-setup.module').then(m => m.InitialSetupModule)
    },
    { path: 'password', loadChildren: () => import('./password/password.module').then(m => m.PasswordModule) },
    {
        path: 'sign',
        loadChildren: () => import('../../projects/kiene-core/src/lib/components/unterschriften/unterschriften.module').then(m => m.UnterschriftenModule)
    },
    { path: 'versioninfo', component: VersionInfoComponent, canActivate: [AuthGuard] },
    {
        path: 'gruppen',
        loadChildren: () => import('./gruppen/gruppen.module').then(m => m.GruppenModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'logistik',
        loadChildren: () => import('./logistik/logistik.module').then(m => m.LogistikModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'gruppen-schweine',
        loadChildren: () => import('./gruppen-schweine/gruppen-schweine.module').then(m => m.GruppenSchweineModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'schlachthoefe',
        loadChildren: () => import('./schlachthoefe/schlachthoefe.module').then(m => m.SchlachthoefeModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'viehhaendler',
        loadChildren: () => import('./viehhaendler/viehhaendler.module').then(m => m.ViehhaendlerModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'spediteur',
        loadChildren: () => import('./spediteur/spediteur.module').then(m => m.SpediteurModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'exceptions',
        loadChildren: () => import('./exceptions/exceptions.module').then(m => m.ExceptionsModule),
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
