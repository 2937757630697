import {Inject, Injectable} from '@angular/core';
import {Abgabebeleg, AbgabebelegApi, AbgabebelegChargeApi, AbgabebelegPosition,} from './abgabebeleg';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {IdentifikationApi} from '../behandlungsanfrage/identifikation';
import {IndikationApi} from '../../warehouse/material/artikel-indikation/indikation';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {Bestandsbesuchprotokoll} from '../bestandsbesuchsprotokoll/bestandsbesuchsprotokoll';
import {MessageService} from '../../services/message.service';
import {Bestandsbuch} from '../bestandsbuch/bestandsbuch/classes/bestandsbuch';
import {KieneApi} from '../../components/kiene-table/kiene-api';
import {formatDate} from '@angular/common';

@Injectable()
export class AbgabebelegService {
    constructor(
        private http: HttpClient,
        private pdfService: PdfViewerDesktopService,
        private messageService: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    getById(id: number): Observable<Abgabebeleg> {
        return this.http.get<Abgabebeleg>(
            this.apiBaseUrl + 'abgabebeleg/read_one.php?abgabebeleg_id=' + id
        );
    }

    getAllAbgabebelege(
        sortColumn: string,
        sortDirection: string,
        offset: number,
        limit: number,
        searchString: string
    ): Observable<AbgabebelegApi> {
        return this.http.get<AbgabebelegApi>(
            this.apiBaseUrl +
            'abgabebeleg/read.php?orderby=' +
            sortColumn +
            ' ' +
            sortDirection +
            '&limit=' +
            limit +
            '&offset=' +
            offset +
            '&search=' +
            searchString
        );
    }

    getAbgabebelegForId(id: string): Observable<Abgabebeleg> {
        return this.http.get<Abgabebeleg>(
            this.apiBaseUrl + 'abgabebeleg/read_one.php?abgabebeleg_id=' + id
        );
    }

    bestandsbesuchsprotokollErzeugen(
        abgabebeleg: Abgabebeleg
    ): Observable<Bestandsbesuchprotokoll> {
        return this.http.post<Bestandsbesuchprotokoll>(
            this.apiBaseUrl + 'bestandsbesuchsprotokoll/transform.php',
            abgabebeleg
        );
    }

    saveAbgabebeleg(abgabebeleg: Abgabebeleg): Observable<Abgabebeleg> {
        return this.http.post<Abgabebeleg>(
            this.apiBaseUrl + 'abgabebeleg/create.php',
            abgabebeleg
        );
    }

    getOffeneImpfkontrollbuecher(
        kunde_id: number,
        datum: Date
    ): Observable<KieneApi> {
        let params = new HttpParams().set('typ', '2');
        params = params.set('abschliessbar', '1');
        params = params.set('kunde_id', kunde_id);
        if (datum) {
            params = params.set(
                'datum_max',
                formatDate(datum, 'yyyy-MM-dd', 'de-DE')
            );
        }
        return this.http?.get<KieneApi>(
            this.apiBaseUrl + 'bestandsbuch/read.php',
            {params: params}
        );
    }

    offeneImpfbuecherAbschliessen(
        datum: Date,
        offeneImpfbuecher: Bestandsbuch[],
        tierarzt_id: number,
        force: boolean
    ): Observable<boolean> {
        const subject = new Subject<boolean>();
        const obj = {
            datum_kontrolle: new Date(datum),
            impfkontrollbuecher: offeneImpfbuecher,
            tierarzt_id: tierarzt_id,
            force: force ? 1 : 0
        };
        this.http
            ?.post(
                this.apiBaseUrl +
                'bestandsbuch/abschliessen_impfkontrollbuch.php',
                obj
            )
            .subscribe({
                next: () => {
                    this.messageService.infoMessage(
                        'Die offenen Impfkontrollbücher wurden erfolgreich abgeschlossen!'
                    );
                    subject.next(true);
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                    subject.next(false);
                },
            });
        return subject;
    }

    duplicateAbgabebeleg(
        abgabebeleg: Abgabebeleg,
        meldung_uebernehmen?: number
    ) {
        const mu = meldung_uebernehmen === undefined ? 0 : meldung_uebernehmen;
        const obj = {
            abgabebeleg_id: abgabebeleg.abgabebeleg_id,
            meldung_uebernehmen: mu,
        };
        return this.http.post(
            this.apiBaseUrl + 'abgabebeleg/duplicate.php',
            obj
        );
    }

    cancelAbgabebeleg(abgabebeleg: Abgabebeleg) {
        return this.http.post(
            this.apiBaseUrl + 'abgabebeleg/stornieren.php',
            abgabebeleg
        );
    }

    searchAbgabebelegn(searchString: string): Observable<AbgabebelegApi> {
        return this.http.get<AbgabebelegApi>(
            this.apiBaseUrl + 'abgabebeleg/read.php?search=' + searchString
        );
    }

    getIdentifikation(clientId: number): Observable<IdentifikationApi> {
        return this.http.get<IdentifikationApi>(
            this.apiBaseUrl + 'identifikation/read.php?kunde_id=' + clientId
        );
    }

    searchIdentifikationen(
        clientId: number,
        searchString: string
    ): Observable<IdentifikationApi> {
        return this.http.get<IdentifikationApi>(
            this.apiBaseUrl +
            'identifikation/read.php?kunde_id=' +
            clientId +
            '&search=' +
            searchString
        );
    }

    getIndikationen(
        materialId: number,
        tierartId: number
    ): Observable<IndikationApi> {
        return this.http.get<IndikationApi>(
            this.apiBaseUrl +
            'indikation/read.php?artikel_id=' +
            materialId +
            '&tierart_id=' +
            tierartId
        );
    }

    searchIndikationen(
        materialId: number,
        searchString: string
    ): Observable<IndikationApi> {
        return this.http.get<IndikationApi>(
            this.apiBaseUrl +
            'indikation/read.php?artikel_id=' +
            materialId +
            '&search=' +
            searchString
        );
    }

    // getAnwendungen(material: Material, tierart: Tierart): Observable<AnwendungApi> {
    //   let params = new HttpParams();
    //   params = params.set('artikel_id', material?.artikel_id.toString());
    //   params = params.set('tierart_id', tierart?.tierart_id.toString());
    //   return this.http.get<AnwendungApi>(environment.BASE_URI + 'anwendung/read.php', {params: params});
    // }

    // searchAnwendungen(materialId: number, searchString: string): Observable<AnwendungApi> {
    //   return this.http.get<AnwendungApi>(environment.BASE_URI + 'anwendung/read.php?artikel_id=' + materialId + '&search=' + searchString);
    // }

    transformToBehandlungsanfrage(abgabebeleg: Abgabebeleg): Observable<any> {
        return this.http.post(
            this.apiBaseUrl + 'behandlungsanfrage/transform.php',
            abgabebeleg
        );
    }

    generiereMeldungen(abgabebelegId?: number): Observable<any> {
        return this.http.post(this.apiBaseUrl + 'meldung/generieren.php', {
            abgabebeleg_id: abgabebelegId,
        });
    }

    getChargenForMaterial(
        abgabebelegPosition: AbgabebelegPosition,
        tierarztId: number
    ): Observable<AbgabebelegChargeApi> {
        let params: HttpParams = new HttpParams();
        if (abgabebelegPosition?.aa_id) {
            params = params.append(
                'aa_id',
                abgabebelegPosition?.aa_id?.toString()
            );
        }
        if (tierarztId) {
            params = params.append('tierarzt_id', tierarztId?.toString());
        }
        params = params.append(
            'artikel_id',
            abgabebelegPosition?.artikel_id?.toString()
        );
        return this.http.get<AbgabebelegChargeApi>(
            this.apiBaseUrl + 'bestand_charge/read_abgabebeleg.php',
            {params: params}
        );
    }

    openAbgabebelegGesamtPdf(
        abgabebeleg: Abgabebeleg,
        kunde_id: number
    ): Observable<boolean> {
        const input = new PdfViewerDialogInput(
            'abgabebeleg/read_or_mail_pdf_gesamt.php',
            new HttpParams().set(
                'abgabebeleg_id',
                abgabebeleg?.abgabebeleg_id?.toString()
            ),
            'Abgabebeleg gesamt ' + abgabebeleg?.abgabebeleg_nr,
            {
                email: {
                    sende_mail: true,
                    kunde_id: kunde_id,
                },
            }
        );
        return this.pdfService.openPdfViewer(input);
    }

    openAbgabebelegPdf(abgabebeleg: Abgabebeleg, kunde_id: number) {
        const input = new PdfViewerDialogInput(
            'abgabebeleg/read_or_mail_pdf.php',
            new HttpParams().set(
                'abgabebeleg_id',
                abgabebeleg?.abgabebeleg_id?.toString()
            ),
            'Abgabebeleg ' + abgabebeleg?.abgabebeleg_nr,
            {
                email: {
                    sende_mail: true,
                    kunde_id: kunde_id,
                },
            }
        );
        this.pdfService.openPdfViewer(input);
    }

    openImpfkontrollbuchPdf(abgabebeleg: Abgabebeleg, kunde_id: number) {
        const input = new PdfViewerDialogInput(
            'abgabebeleg/read_or_mail_pdf_impfkontrollbuch.php',
            new HttpParams().set(
                'abgabebeleg_id',
                abgabebeleg?.abgabebeleg_id?.toString()
            ),
            'Impfkontrollbuch ' + abgabebeleg.abgabebeleg_nr,
            {
                email: {
                    sende_mail: true,
                    kunde_id: kunde_id,
                },
            }
        );
        this.pdfService.openPdfViewer(input);
    }

    openBestandsbuchPdf(abgabebeleg: Abgabebeleg, kunde_id: number) {
        const input = new PdfViewerDialogInput(
            'abgabebeleg/read_or_mail_pdf_bestandsbuch.php',
            new HttpParams().set(
                'abgabebeleg_id',
                abgabebeleg?.abgabebeleg_id?.toString()
            ),
            'Bestandsbuch ' + abgabebeleg?.abgabebeleg_nr,
            {
                email: {
                    sende_mail: true,
                    kunde_id: kunde_id,
                },
            }
        );
        this.pdfService.openPdfViewer(input);
    }
}
