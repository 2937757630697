import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import {
    KieneBackendApiService
} from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { Client } from '../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { Haendler } from '../../../../projects/kiene-core/src/lib/components/stammdaten/haendler/haendler';

@Injectable({
    providedIn: 'root'
})
export class KundenSettingsService {

    constructor(@Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService) {
    }

    loadTodesarten(): Observable<any> {
        return this.api.getAll(`${this.apiBaseUrl}todesart/read.php`);
    }


    loadEntsorgerFavoriten(): Observable<any> {
        let params = new HttpParams().set('favorit', '1');
        params = params.set('orderby', 'letzte_entsorgung desc');
        return this.api.getAll(
            `${this.apiBaseUrl}entsorger/read_kundenportal.php`,
            params
        );
    }

    loadHaendlerFavoriten() {
        const params = new HttpParams().set('favorit', '1');
        return this.api.getAll(
            `${this.apiBaseUrl}viehhaendler/read_kundenportal.php`,
            params
        );
    }

    addOrUpdateHaendler(haendler: Haendler) {
        if (haendler.viehhaendler_id) {
            return this.updateHaendler(haendler);
        } else {
            return this.createHaendler(haendler);
        }
    }

    createHaendler(haendler: Haendler) {
        return this.api.post(
            `${this.apiBaseUrl}viehhaendler/create_kundenportal.php`,
            haendler,
            null
        );
    }

    updateHaendler(haendler: Haendler) {
        return this.api.updateGeneric(
            `${this.apiBaseUrl}viehhaendler/update_kundenportal.php`,
            haendler
        );
    }

    getKunde(kunde_id: any) {
        return this.api.getByIdAlternative(
            `${this.apiBaseUrl}kunde/read_one_kundenportal.php`,
            'kunde_id',
            kunde_id
        );
    }

    ladeVvvos(): Observable<any> {
        return this.api.getAll(
            `${this.apiBaseUrl}kunde/read_kundenportal.php`
        );
    }

    registerVVVO(client: Client) {
        return this.api.updateGeneric(`${this.apiBaseUrl}kunde/update_kundenportal.php`, client);
    }
}
