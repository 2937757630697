import {HttpParams} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MessageService} from '../../services/message.service';
import {
    PdfViewerDesktopService
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {Client} from '../../../../projects/kiene-core/src/lib/components/client/classes/client';

export class DownloadPermissionObject {
    name: string;
    permisssionId: number;
    displayName: string;
    formControl: UntypedFormControl;

    constructor(
        name: string,
        permissionId: number,
        displayName: string,
        formControl: UntypedFormControl
    ) {
        this.name = name;
        this.permisssionId = permissionId;
        this.displayName = displayName;
        this.formControl = formControl;
    }
}

@Component({
    selector: 'kiene-dowload-alle-dokumente-dialog',
    templateUrl: './dowload-alle-dokumente-dialog.component.html',
    styleUrls: ['./dowload-alle-dokumente-dialog.component.css'],
})
export class DowloadAlleDokumenteDialogComponent implements OnInit {
    currentClient: Client;

    vonCtrl = new UntypedFormControl(null, [Validators.required]);
    bisCtrl = new UntypedFormControl(null, [Validators.required]);

    sortierungCtrl = new UntypedFormControl('chronologisch');

    alleCtrl = new UntypedFormControl();

    selectedItems = 0;

    downloadPermissionObjects: DownloadPermissionObject[] = [];

    constructor(
        public dialogRef: MatDialogRef<DowloadAlleDokumenteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private localStorageService: LocalStorageService,
        private pdfViewerService: PdfViewerDesktopService,
        private messageService: MessageService
    ) {
        this.currentClient = this.localStorageService.getCurrentClient();
    }

    ngOnInit(): void {
        const jetzt = new Date();
        let vorDreissigTagen = new Date();
        vorDreissigTagen = new Date(
            vorDreissigTagen.setDate(vorDreissigTagen.getDate() - 30)
        );
        this.vonCtrl.setValue(vorDreissigTagen);
        this.bisCtrl.setValue(jetzt);

        this.localStorageService
            .watchCurrentClient()
            .subscribe((client) => (this.currentClient = client));

        if (this.localStorageService.hasPermission(1231)) {
            this.downloadPermissionObjects[0] = new DownloadPermissionObject(
                'abgabebelege',
                1231,
                'Abgabebelege',
                new UntypedFormControl(false)
            );
        }

        if (this.localStorageService.hasPermission(1311)) {
            this.downloadPermissionObjects[1] = new DownloadPermissionObject(
                'befunde',
                1311,
                'Befunde',
                new UntypedFormControl(false)
            );
        }

        if (this.localStorageService.hasPermission(1811)) {
            this.downloadPermissionObjects[2] = new DownloadPermissionObject(
                'antibiogramme',
                1811,
                'Antibiogramme',
                new UntypedFormControl(false)
            );
        }

        if (this.localStorageService.hasPermission(3851)) {
            this.downloadPermissionObjects[3] = new DownloadPermissionObject(
                'bbp',
                3851,
                'Bestandsbesuchsprotokolle',
                new UntypedFormControl(false)
            );
        }

        if (this.localStorageService.hasPermission(1151)) {
            this.downloadPermissionObjects[4] = new DownloadPermissionObject(
                'mnp_rind',
                1151,
                'Maßnahmenplan Rind',
                new UntypedFormControl(false)
            );
        }

        if (this.localStorageService.hasPermission(1181)) {
            this.downloadPermissionObjects[5] = new DownloadPermissionObject(
                'mnp_schwein',
                1181,
                'Maßnahmenplan Schwein',
                new UntypedFormControl(false)
            );
        }

        if (this.localStorageService.hasPermission(1249)) {
            this.downloadPermissionObjects[6] = new DownloadPermissionObject(
                'impfkontrollbuecher',
                1249,
                'Impfkontrollbücher',
                new UntypedFormControl(false)
            );
        }

        if (this.localStorageService.hasPermission(1248)) {
            this.downloadPermissionObjects[7] = new DownloadPermissionObject(
                'bestandsbuecher',
                1248,
                'Bestandsbücher',
                new UntypedFormControl(false)
            );
        }

        if (this.localStorageService.hasPermission(3085)) {
            this.downloadPermissionObjects[8] = new DownloadPermissionObject(
                'dok1',
                3085,
                'Betreuungsvertrag',
                new UntypedFormControl(false)
            );
        }

        if (this.localStorageService.hasPermission(3085)) {
            this.downloadPermissionObjects[9] = new DownloadPermissionObject(
                'dok2',
                3085,
                'Betreuungsvertrag QS',
                new UntypedFormControl(false)
            );
        }

        if (this.localStorageService.hasPermission(3085)) {
            this.downloadPermissionObjects[10] = new DownloadPermissionObject(
                'dok3',
                3085,
                'Tierarzneimittel Futter',
                new UntypedFormControl(false)
            );
        }

        if (this.localStorageService.hasPermission(3085)) {
            this.downloadPermissionObjects[11] = new DownloadPermissionObject(
                'dok4',
                3085,
                'Tierarzneimittel Wasser',
                new UntypedFormControl(false)
            );
        }

        if (this.localStorageService.hasPermission(3085)) {
            this.downloadPermissionObjects[12] = new DownloadPermissionObject(
                'dok5',
                3085,
                'Meldevereinbarung QS',
                new UntypedFormControl(false)
            );
        }

        if (this.localStorageService.hasPermission(3085)) {
            this.downloadPermissionObjects[13] = new DownloadPermissionObject(
                'dok6',
                3085,
                'Risikomanagement',
                new UntypedFormControl(false)
            );
        }

        this.alleCtrl.valueChanges.subscribe((value) =>
            this.selectAllToggle(value)
        );
    }

    close() {
        return this.dialogRef.close();
    }

    selectAllToggle(value: boolean) {
        for (const po of this.downloadPermissionObjects) {
            po.formControl.setValue(value);
        }
    }

    notAllSelected() {
        this.selectedItems = this.downloadPermissionObjects.filter(
            (po) => po.formControl.value
        ).length;
        return (
            this.selectedItems > 0 &&
            this.selectedItems < this.downloadPermissionObjects.length
        );
    }

    download() {
        this.selectedItems = this.downloadPermissionObjects.filter(
            (po) => po.formControl.value
        ).length;
        if (this.selectedItems < 1) {
            this.messageService.hintMessage(
                'Bitte mindestens ein Dokumententyp auswählen!'
            );
            return;
        }

        const url = environment.BASE_URI + 'abgabebeleg/read_pdf_global.php';
        let params = new HttpParams();

        const von: Date = this.vonCtrl.value;
        const bis: Date = this.bisCtrl.value;

        params = params.set('von', von.toISOString());
        params = params.set('bis', bis.toISOString());

        params = params.set('orderby', this.sortierungCtrl.value);

        params = params.set('kunde_id', this.currentClient.kunde_id.toString());

        for (const po of this.downloadPermissionObjects) {
            params = params.set(po.name, po.formControl.value ? '1' : '0');
        }

        this.pdfViewerService.downloadPdf(url, params);
        this.close();
    }
}
