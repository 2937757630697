<div
    style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    "
>
    <div class="register-vvvo-page">
        <kiene-page-header
            [ueberschrift]="'Registrierung'"
            [routeRelativeAlternative]="'../../login'"
        ></kiene-page-header>

        <mat-card style="margin: 5%">

            <h3>1. HIT Anmeldung</h3>
            <p class="hit-form-text">
                Damit Sie den vollen Funktionsumfang von
                <strong>vetvet.de</strong> nutzen können, ist eine einmalige Anmeldung
                bei HI-Tier notwendig.
            </p>
            <hit-anmeldung
                [navigateToAfterLogin]="'../vetvet'"
            ></hit-anmeldung>
        </mat-card>
    </div>
</div>
