import { animate, style, transition, trigger } from '@angular/animations';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { GruppeTier } from '../../../../administration/client-group/gruppe-tier';
import { KieneTableService } from '../../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../../services/message.service';
import { Identifikation } from '../../../behandlungsanfrage/identifikation';
import { Gruppe, TierauswahlEvent } from '../../../gruppenverwaltung/gruppe';
import { IdentifikationDialogResult } from './identifikation-dialog';

@Component({
    selector: 'kiene-identifikation-dialog',
    templateUrl: './identifikation-dialog.component.html',
    styleUrls: ['./identifikation-dialog.component.css'],
    animations: [
        trigger('freitextAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate('500ms ease-in', style({ transform: 'translateX(0%)' })),
            ]),
            transition(':leave', [
                animate('10ms ease-out', style({ transform: 'translateX(-100%)' })),
            ]),
        ]),
        trigger('tierauswahlAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(100%)' }),
                animate('500ms ease-in', style({ transform: 'translateX(0%)' })),
            ]),
            transition(':leave', [
                animate('10ms ease-out', style({ transform: 'translateX(100%)' })),
            ]),
        ]),
    ],
    host: { '(window:keydown)': 'checkForKeyShortcut($event)' },
})
export class IdentifikationDialogComponent implements OnInit, AfterViewInit {
    radioCtrl = new UntypedFormControl(0);
    prophylaxeCtrl = new FormControl();
    metaphylaxeCtrl = new FormControl();
    showSaveButton = true;

    identityCtrl = new UntypedFormControl();
    @ViewChild('identityCtrlRef', { static: false })
    identityCtrlRef: ElementRef<HTMLTextAreaElement>; // angular bug, geht nicht anders
    @ViewChild('autoIdentity') autoIdentity: MatAutocomplete;

    identifikationen: Observable<Identifikation[]>;

    gruppe: Gruppe = new Gruppe();
    tierauswahl: GruppeTier[] = [];

    anzahlTiereSollwert: number;
    identifikationTmp: string = null;
    alleTiereSelektiert = false;

    tiereAnzeigen = true;

    constructor(
        public dialogRef: MatDialogRef<IdentifikationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private api: KieneTableService,
        private messageService: MessageService
    ) {
        this.gruppe = this.data.gruppe;

        if (this.data.tiereAnzeigen === false) {
            this.tiereAnzeigen = false;
        }

        if (this.data.tiere && this.data.tiere.length > 0) {
            this.radioCtrl.setValue(1);
            this.tierauswahl = this.data.tiere;
        }

        if (this.data?.identifikation) {
            this.identifikationTmp = this.data.identifikation;
        }

        if (this.data?.prophylaxe > 0) {
            this.prophylaxeCtrl.setValue(true, { emitEvent: false });
        } else if (this.data?.metaphylaxe > 0) {
            this.metaphylaxeCtrl.setValue(true, { emitEvent: false });
        }

        if (this.data.anzahlTiere) {
            this.anzahlTiereSollwert = this.data.anzahlTiere;
        }

        this.prophylaxeCtrl.valueChanges.subscribe({
            next: (value: boolean) => {
                if (value === true) {
                    this.metaphylaxeCtrl.setValue(false, { emitEvent: false });
                }
            }
        });
        this.metaphylaxeCtrl.valueChanges.subscribe({
            next: (value: boolean) => {
                if (value === true) {
                    this.prophylaxeCtrl.setValue(false, { emitEvent: false });
                }
            }
        });
    }

    ngOnInit(): void {
        if (!this.tiereAnzeigen) {
            // 0 = Freitext
            this.radioCtrl.setValue(0);
        }
        this.autocompleteIdentifikationen();
    }

    ngAfterViewInit() {
        if (this.identifikationTmp) {
            setTimeout(
                () =>
                    (this.identityCtrlRef.nativeElement.value = this.identifikationTmp),
                200
            ); // // angular bug, geht nicht anders
        }
    }

    setTiere(event: TierauswahlEvent) {
        this.tierauswahl = event.tiere;
        this.alleTiereSelektiert = event.alleSelektiert;
    }

    close() {
        this.dialogRef.close(null);
    }

    save() {
        const result = new IdentifikationDialogResult();
        result.confirm = true;
        result.identifikationTyp = this.radioCtrl.value === 0 ? 'freitext' : 'tierauswahl';
        result.freitext = this.getFreitext();
        result.tiere = this.tierauswahl;
        result.alleSelektiert = this.alleTiereSelektiert;
        result.prophylaxe = this.prophylaxeCtrl.value === true ? 1 : 0;
        result.metaphylaxe = this.metaphylaxeCtrl.value === true ? 1 : 0;
        this.dialogRef.close(result);
    }

    getFreitext(): string {
        return this.identityCtrlRef?.nativeElement.value; // angular bug, geht nicht anders
    }

    autocompleteIdentifikationen() {
        this.identifikationen = this.identityCtrl.valueChanges.pipe(
            debounceTime(100),
            distinctUntilChanged(),
            switchMap((value) => this.searchIdentifikationen(this.getFreitext()))
        );
    }

    searchIdentifikationen(value: string): Observable<Identifikation[]> {
        if (!this.gruppe?.kunde_id) {
            return new Observable<Identifikation[]>();
        }
        let params = new HttpParams();
        params = params.set('kunde_id', this.gruppe.kunde_id.toString());
        params = params.set('search', value);
        return this.api
            .get(environment.BASE_URI + 'identifikation/read.php', params)
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                map((response) => response.records)
            );
    }

    deleteIdentifikation(identifikation: Identifikation, event: Event) {
        event.stopPropagation();
        this.api
            .deleteEntity(environment.BASE_URI + 'identifikation/delete.php', identifikation)
            .subscribe(
                (response) => {
                    this.identifikationen = this.searchIdentifikationen(
                        this.getFreitext()
                    );
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Die Identifikation konnte nicht gelöscht werden!',
                        error
                    );
                }
            );
    }

    identityMapper(identifikation: Identifikation) {
        if (identifikation) {
            return identifikation.bezeichnung;
        }
        return '';
    }

    checkForKeyShortcut(event: KeyboardEvent) {
        if (this.isTextarea) {
            if ((event.ctrlKey || event.altKey) && event.key === 'Enter') {
                event.preventDefault();
                let s = this.getFreitext();
                s += '\n';
                this.identityCtrlRef.nativeElement.value = s;
            } else if (event.key === 'Enter') {
                event.preventDefault();
                this.save();
            }
        }
    }

    private isTextarea(event: KeyboardEvent): boolean {
        if (event.target instanceof Node) {
            switch (event.target.nodeName) {
                case 'TEXTAREA':
                    return true;
            }
        }
        return false;
    }
}
