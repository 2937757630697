import { GruppeTier } from '../../../../../../src/app/administration/client-group/gruppe-tier';

export class IdentifikationDialogResult {
    confirm = false;
    identifikationTyp: string;
    freitext: string;
    tiere: GruppeTier[] = [];
    alleSelektiert: boolean;
    prophylaxe: number;
    metaphylaxe: number;
}