import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppService } from '../../../../services/app/app.service';
import { BenutzerService } from '../benutzer.service';
import { MessageService } from '../../../../services/message-service/message.service';
import { User } from '../benutzer';
import { UserRole } from '../../../../../../../../src/app/administration/user/user';

@Component({
    selector: 'kiene-benutzer-hinzufuegen-dialog',
    templateUrl: './benutzer-hinzufuegen-dialog.component.html',
    styleUrls: ['./benutzer-hinzufuegen-dialog.component.scss']
})
export class BenutzerHinzufuegenDialogComponent implements OnInit {
    userroles: UserRole[] = [];


    newUserFormGroup = new FormGroup(
        {
            titel: new FormControl(''),
            vorname: new FormControl('', [Validators.required]),
            nachname: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.email]),
            benutzerrollen: new FormControl(undefined, [Validators.required]),
            password: new FormControl(undefined, [Validators.minLength(8), Validators.required]),
        },
    );
    sendePasswortAnEmailCtrl = new FormControl(true);

    constructor(
        public dialogRef: MatDialogRef<BenutzerHinzufuegenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private userService: BenutzerService,
        private messageService: MessageService,
        private appService: AppService
    ) {
        this.loadUserRoles();
    }

    ngOnInit() {
        const pwd = this.appService.generateKienePassword();
        this.newUserFormGroup.get('password').setValue(pwd);
    }


    loadUserRoles() {
        this.userService.getAllUserRoles().subscribe({
            next: (response) => {
                this.userroles = response.records;
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler!', err);
            }
        }
        );
    }

    compareBenutzerrolle(r1: UserRole, r2: UserRole) {
        return r1?.benutzerrolle_id === r2.benutzerrolle_id;
    }

    safeAndCloseDialog() {
        if (this.newUserFormGroup.valid) {
            const newUser = new User();
            newUser.vorname = this.newUserFormGroup.get('vorname').value;
            newUser.nachname = this.newUserFormGroup.get('nachname').value;
            newUser.titel = this.newUserFormGroup.get('titel').value;
            newUser.email = this.newUserFormGroup.get('email').value;
            newUser.benutzerrollen = this.newUserFormGroup.get('benutzerrollen').value;
            newUser.passwort = this.newUserFormGroup.get('password').value;
            newUser.sende_email = this.sendePasswortAnEmailCtrl.value === true ? 1 : 0;

            this.userService.createUser(newUser).subscribe({
                next: (response) => {
                    this.closeDialog(response);
                },
                error: (err) => {
                    // this.snackBar.open('Fehler! Benutzer konnte nicht angelegt werden!', '', { duration: 3000, panelClass: ['snackbar-alert'] });
                    this.messageService.errorMessage(
                        'Fehler! Benutzer konnte nicht angelegt werden!',
                        err
                    );
                }
            }
            );
        } else {
            const errors: ValidationErrors = this.newUserFormGroup.errors;
        }
    }

    closeDialog(user: User) {
        this.dialogRef.close(user);
    }

    onCancelClick(): void {
        this.dialogRef.close(null);
    }

}
