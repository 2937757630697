import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { TableDescriptor } from '../../../components/kiene-table/table-descriptor';
import { environment } from '../../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { IdexxTestlauf } from './classes/IdexxTestlauf';
import { AppService } from '../../../app.service';
import {
    DateTableColumn,
    InputTableColumn,
    NumberTableColumn,
    TableColumn
} from '../../../components/kiene-table/table-column';
import { MessageService } from '../../../services/message.service';

@Component({
    selector: 'kiene-idexx-result-dialog',
    templateUrl: './idexx-result-dialog.component.html',
    styleUrls: ['./idexx-result-dialog.component.scss'],
})
export class IdexxResultDialogComponent implements OnInit {
    befundeArtikelId: number;
    tableDescriptor: TableDescriptor = new TableDescriptor();
    idexxTestlauf: IdexxTestlauf;
    cols: string[] = [
        'gesamt',
        'positiver',
        'negativer',
        'ungueltiger',
        'fraglicher',
    ];

    // for Future use
    resultDisplayMappings: [
        { eng: 'Positive'; ger: 'Positiv' },
        { eng: 'Negative'; ger: 'Negativ' },
        { eng: 'ReCheck'; ger: 'Erneut testen' },
        { eng: 'Inv'; ger: 'Ungültig' },
        { eng: 'Open'; ger: 'Offen' },
        { eng: 'Pregnant'; ger: 'Schwanger' },
        { eng: 'Unknown'; ger: 'Unbekannt' }
    ];

    constructor(
        public dialogRef: MatDialogRef<IdexxResultDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private tableService: KieneTableService,
        private appService: AppService,
        private messageService: MessageService
    ) {
        this.befundeArtikelId = this.data.befundeArtikelId;
    }

    ngOnInit(): void {
        this.appService.setDataLoading(true);

        this.tableDescriptor.headline = `Ergebnisse`;
        this.tableDescriptor.uniqueIdentifier = `Ergebnisse`;
        this.tableDescriptor.apiUrl = environment.BASE_URI + 'idexx_probe/';
        this.tableDescriptor.route = '/labor/befundmitteilungen/';
        this.tableDescriptor.nameOfIdField = 'probe_id';
        this.tableDescriptor.params = new HttpParams()
            .append('ba_id', this.befundeArtikelId.toString())
            .append('abgeschlossen', '1');
        this.tableDescriptor.searchPlaceholder = 'Ergebnisse durchsuchen';
        this.tableDescriptor.columns.push(
            new TableColumn('roehrchennummer', 'Nr.')
        );
        this.tableDescriptor.columns.push(
            new NumberTableColumn('od_wert', 'OD-Wert', '1.3-3', '')
        );
        this.tableDescriptor.columns.push(
            new NumberTableColumn('proben_messwert', 'Messwert', '1.3-3', '')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('ergebnis', 'Ergebnis')
        );
        this.tableDescriptor.columns.push(
            new DateTableColumn('ergebnis_vom', 'Ergebnis vom', 'dd.MM.yy')
        );
        this.tableDescriptor.columns.push(
            new InputTableColumn('zusatz_text', 'Zusatztext', this.tableDescriptor.apiUrl)
        );
        this.tableDescriptor.initialSortColumn = 'roehrchennummer';
        this.tableDescriptor.initialSortDirection = 'asc';
        this.tableDescriptor.showDetails = false;

        this.tableService
            .get(
                environment.BASE_URI + 'idexx_testlauf/read.php',
                new HttpParams().append(
                    'ba_id',
                    this.befundeArtikelId.toString()
                )
            )
            .subscribe({
                next: (res) => {
                    console.debug('RES IST: ' + JSON.stringify(res));
                    this.idexxTestlauf = res.records[0];
                    this.setNullValuesToNA();
                    this.appService.setDataLoading(false);
                    this.tableService.reload();
                },
                error: (err) => {
                    this.appService.setDataLoading(false);
                    this.messageService.errorMessage(
                        'Ergebnisse konnten nicht geladen werden!',
                        err
                    );
                },
            });
    }


    close() {
        this.dialogRef.close(null);
    }

    getTestlaufAsArray(): any[] {
        return [
            {
                anzahl_gesamt: this.idexxTestlauf?.anzahl_gesamt,
                anzahl_positiver: this.idexxTestlauf?.anzahl_positiver,
                anzahl_negativer: this.idexxTestlauf?.anzahl_negativer,
                anzahl_ungueltiger: this.idexxTestlauf?.anzahl_ungueltiger,
                anzahl_fraglicher: this.idexxTestlauf?.anzahl_fraglicher,
            },
        ];
    }

    private setNullValuesToNA() {
        for (const [key, value] of Object.entries(this.idexxTestlauf)) {
            if (
                (value === null || value.toString().trim() === '') &&
                key !== 'testlaufkommentar'
            ) {
                this.idexxTestlauf[key] = 'n/a';
            }
            if (value === 'Unknown') {
                this.idexxTestlauf[key] = 'Unbekannt';
            }
        }
    }
}
