import {Component, Inject, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
    BestellungExtern,
    BestellungExternDokument,
    BestellungExternDokumentPruefung,
    BestellungExternPosition
} from '../../bestellung-extern/bestellung-extern';
import {MessageService} from '../../../services/message.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {FormControl, Validators} from '@angular/forms';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {
    EnterValueDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-dialog.component';
import {
    EnterValueInput,
    EnterValueResponse
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-response';
import {SupplierMaterialService} from '../../../warehouse/supplier-material/supplier-material.service';

@Component({
    selector: 'kiene-rechnung-bestellposition-check-dialog',
    templateUrl: './rechnung-bestellposition-check-dialog.component.html',
    styleUrls: ['./rechnung-bestellposition-check-dialog.component.scss']
})
export class RechnungBestellpositionCheckDialogComponent implements OnInit {

    pdfBase64: SafeResourceUrl;
    pdfAvailable = false;
    isLoading = false;

    bestellungExtern: BestellungExtern;
    bestellungExternDokument: BestellungExternDokument;

    bestellungExternDokumentPruefung: BestellungExternDokumentPruefung;

    inRechnungCtrls: FormControl[] = [];
    geprueftCtrls: FormControl[] = [];
    rechnungsNummerCtrl = new FormControl(undefined, [Validators.required]);
    rechnungsDatumCtrl = new FormControl(undefined, [Validators.required]);

    constructor(@Inject(MAT_DIALOG_DATA) private data,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                public dialogRef: MatDialogRef<RechnungBestellpositionCheckDialogComponent>,
                private api: KieneBackendApiService,
                private http: HttpClient,
                private sanatizer: DomSanitizer,
                private messageService: MessageService,
                private dialog: MatDialog,
                private supplierMaterialService: SupplierMaterialService) {
    }

    ngOnInit(): void {
        this.bestellungExtern = this.data?.bestellungExtern;
        this.bestellungExternDokument = this.data?.bestellungExternDokument;
        this.rechnungsDatumCtrl.setValue(this.bestellungExternDokument?.datum);
        this.rechnungsNummerCtrl.setValue(this.bestellungExternDokument?.nummer);
        this.ladeDokumentePosition();
        this.downloadPdfBase64();
    }

    downloadPdfBase64() {
        if (this.pdfBase64) {
            this.pdfAvailable = true;
        } else {
            this.isLoading = true;
            const params = new HttpParams().set('base64', 'true').set('bed_id', this.bestellungExternDokument.bed_id);
            let obs;
            if (this.bestellungExternDokument) {
                obs = this.http.get(
                    this.apiBaseUrl + 'bestellung_extern_dokument/read_pdf_rechnung.php',
                    {params: params, responseType: 'text'}
                );
            }
            obs.subscribe({
                next: (response) => {
                    if (response === 'nio') {
                        this.downloadPdf();
                    } else {
                        this.pdfBase64 =
                            this.sanatizer.bypassSecurityTrustResourceUrl(
                                'data:application/pdf;base64,' + response
                            );
                        this.isLoading = false;
                        this.pdfAvailable = true;
                    }
                },
                error: (error) => {
                    this.messageService.errorMessage('Fehler download:', error);
                    this.isLoading = false;
                    this.pdfAvailable = false;
                },
            });
        }
    }

    isValid() {
        if (!this.rechnungsDatumCtrl.valid) {
            return false;
        }
        if (!this.rechnungsNummerCtrl.valid) {
            return false;
        }
        return true;
    }

    save() {
        const input = new ConfirmDialogInput();
        input.headline = 'Speichern';
        input.text = 'Alle Positionen, die für die Primanota freigegeben sind, werden in die aktuelle Primanota eingetragen und können danach nicht mehr bearbeitet werden! Möchten Sie wirklich speichern?';
        input.okButton = 'Speichern';
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '25%',
            data: input
        });
        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    for (let i = 0; i < this.bestellungExternDokumentPruefung.positionen.length; i++) {
                        this.bestellungExternDokumentPruefung.positionen[i].in_rechnung = this.inRechnungCtrls[i].value === true ? 1 : 0;
                        this.bestellungExternDokumentPruefung.positionen[i].geprueft = this.geprueftCtrls[i].value === true ? 1 : 0;
                    }
                    this.bestellungExternDokumentPruefung.nummer = this.rechnungsNummerCtrl.value;
                    this.bestellungExternDokumentPruefung.datum = this.rechnungsDatumCtrl.value;

                    this.api.updateGeneric(this.apiBaseUrl + 'bestellung_extern/update_rechnung.php', this.bestellungExternDokumentPruefung).subscribe({
                        next: response => {
                            this.messageService.infoMessage('Die Eingaben wurden erfolgreich gespeichert!');
                            this.dialogRef.close(true);
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler!', err);
                        }
                    });
                }
            }
        });

    }

    alleAnhakenInRechnung(event: MatCheckboxChange) {
        if (event.checked) {
            for (let i = 0; i < this.bestellungExternDokumentPruefung.positionen.length; i++) {
                if (this.inRechnungCtrls[i].enabled) {
                    this.inRechnungCtrls[i].setValue(true);
                }
            }
        }
    }

    editEinzelpreis(p: BestellungExternPosition) {
        const input = new EnterValueInput();
        input.title = 'Einzelpreis ändern';
        input.addInputValue('Einzelpreis', 'decimal', p.einzelpreis);
        const dialogRef = this.dialog.open(EnterValueDialogComponent, {data: input});
        dialogRef.afterClosed().subscribe({
            next: (result: EnterValueResponse) => {
                if (result?.result) {
                    const einzelpreis = result.getValue(0);
                    p.einzelpreis = einzelpreis;
                    this.storePriceInArticle(this.bestellungExtern, p);
                }
            }
        });

    }

    storePriceInArticle(be: BestellungExtern, p: BestellungExternPosition) {
        const artikelLieferant = {
            artikel_id: p.artikel_id,
            lieferant_id: be.lieferant_id,
            einzelpreis: p.einzelpreis,
            bea_id: p.bea_id,
            rabatt_inkl: 1,
        };

        this.supplierMaterialService.saveSupplierMaterial(artikelLieferant);
    }

    private downloadPdf() {
        console.error('PDF anzeigen ging nicht');
    }

    private initPositionen() {

        // disable datum und nummer ctrls
        for (const p of this.bestellungExternDokumentPruefung.positionen) {
            if (p.in_rechnung === 1 && p.primanota_id > 0) {
                this.rechnungsDatumCtrl.disable();
                this.rechnungsNummerCtrl.disable();
                break;
            }
        }

        // disable positions
        for (let i = 0; i < this.bestellungExternDokumentPruefung.positionen.length; i++) {
            this.inRechnungCtrls[i] = new FormControl<boolean>(this.bestellungExternDokumentPruefung.positionen[i].in_rechnung === 0 ? false : true);
            this.geprueftCtrls[i] = new FormControl<boolean>(this.bestellungExternDokumentPruefung.positionen[i].geprueft === 0 ? false : true);
            if (this.bestellungExternDokumentPruefung.positionen[i].primanota_id > 0) {
                this.inRechnungCtrls[i].disable();
                this.geprueftCtrls[i].disable();
            }
        }

    }

    private ladeDokumentePosition() {
        let params = new HttpParams();
        params = params.set('bestellung_extern_id', this.bestellungExtern.bestellung_extern_id);
        params = params.set('bed_id', this.bestellungExternDokument.bed_id);
        this.api.get(this.apiBaseUrl + 'bestellung_extern_dokument/read_mit_positionen.php', params).subscribe({
            next: (response: BestellungExternDokumentPruefung) => {
                this.bestellungExternDokumentPruefung = response;
                this.initPositionen();
            },
            error: err => {
                this.messageService.errorMessage('Die Positionen zu dieser Bestellung konnten nicht geladen werden!', err);
            }
        });
    }
}
