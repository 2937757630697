import {Component, Inject, OnInit} from '@angular/core';
import {VetGroup} from '../vet-group';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'kiene-add-vet-group-dialog',
    templateUrl: './add-vet-group-dialog.component.html',
    styleUrls: ['./add-vet-group-dialog.component.scss']
})
export class AddVetGroupDialogComponent implements OnInit {

    vetGroup: VetGroup = new VetGroup();

    constructor(public dialogRef: MatDialogRef<AddVetGroupDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

    }

    ngOnInit() {
        this.vetGroup = new VetGroup();
    }

    closeDialog() {
        this.dialogRef.close(this.vetGroup);
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

}
