import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ArtikellisteAusfuellenDialogInput, ArtikellisteAusfuellenDialogResult} from './artikelliste-ausfuellen-dialog';
import {HttpParams} from '@angular/common/http';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'kiene-artikelliste-ausfuellen-dialog',
    templateUrl: './artikelliste-ausfuellen-dialog.component.html',
    styleUrls: ['./artikelliste-ausfuellen-dialog.component.scss']
})
export class ArtikellisteAusfuellenDialogComponent implements OnInit {

    anzahlTiereCtrl = new FormControl();
    tiergewichtCtrl = new FormControl();

    result = new ArtikellisteAusfuellenDialogResult();
    params: HttpParams;

    constructor(
        public dialogRef: MatDialogRef<ArtikellisteAusfuellenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public input: ArtikellisteAusfuellenDialogInput
    ) {
        if (input?.kunde_id) {
            this.params = new HttpParams().set('kunde_id', input.kunde_id);
        }
    }

    ngOnInit(): void {
    }

    cancel() {
        this.dialogRef.close(this.result);
    }

    save() {
        this.result.anzahl_tiere = this.anzahlTiereCtrl.value;
        this.result.tiergewicht = this.tiergewichtCtrl.value;
        if (this.result?.anzahl_tiere && this.result?.tiergewicht && this.result?.identifikation) {
            this.result.confirm = true;
            this.result.artikelliste = this.input?.artikelliste;
            this.dialogRef.close(this.result);
        }
    }

    selectIdentifikation(identifikation: any) {
        if (!identifikation) {
            this.result.identifikation = null;
        } else if (typeof identifikation === 'string') {
            this.result.identifikation = identifikation
        } else {
            this.result.identifikation = identifikation?.bezeichnung;
        }
    }

}
