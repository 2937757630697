export class MaterialPrice {
    av_id: number;
    artikel_id: number;
    artikel: string;
    artikel_me: string;
    verkaufspreis_id: number;
    verkaufspreis: number;
    einzelpreis: number;
    erstellt: Date;
    erstellt_von: number;
    editiert: Date;
    editiert_von: number;
}

export interface MaterialPriceApi {
    records: MaterialPrice[];
    count: number;
}
