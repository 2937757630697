<div *kienePermission="[1291]" style="padding: 24px">
    <mat-card>
        <div class="one-row">
            <h1>Indikationen</h1>
            <span class="spacer"></span>
            <div class="one-row">
                <span class="spacer"></span>
                <button
                    *kienePermission="[1293]"
                    mat-icon-button
                    color="accent"
                    (click)="add()"
                >
                    <mat-icon>add</mat-icon>
                </button>
                <button mat-icon-button color="accent" (click)="reload()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        </div>

        <ul
            class="draggable-table"
            style="list-style-type: none; padding: 0px; margin: 0px"
            cdkDropList
            (cdkDropListDropped)="reorderList($event)"
        >
            <li class="draggable-row" *ngFor="let i of indikationen" cdkDrag>
                <div
                    class="example-custom-placeholder"
                    *cdkDragPlaceholder
                ></div>
                <div class="one-row">
                    <p style="width: 34%">{{ i.diagnose }}</p>
                    <p style="width: 33%">{{ i.sortierung }}</p>
                    <div style="width: 28%">
                        <ul style="list-style-type: disc">
                            <li *ngFor="let t of i?.tierarten">
                                {{ t.tierart }}
                            </li>
                        </ul>
                    </div>
                    <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        aria-label="Indikation Menu"
                    >
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button
                            *kienePermission="[1293]"
                            mat-menu-item
                            (click)="openIndikationDialog(i)"
                        >
                            <mat-icon>edit</mat-icon>
                            <span>Bearbeiten</span>
                        </button>

                        <button
                            *kienePermission="[1294]"
                            mat-menu-item
                            (click)="openDeleteDialog(i)"
                        >
                            <mat-icon>delete</mat-icon>
                            <span>L&ouml;schen</span>
                        </button>
                    </mat-menu>
                </div>
            </li>
        </ul>
    </mat-card>
</div>
