<div style="padding: 24px;">

    <h2>Leistungen</h2>

    <div class="one-row">
        <mat-form-field>
            <mat-label>Tier</mat-label>
            <mat-select [formControl]="tierCtrl" [compareWith]="tierComparator">
                <mat-option *ngFor="let tier of tiere" [value]="tier">{{ tier?.name }}&nbsp;|&nbsp;{{ tier?.tierart }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <kiene-article-scan-autocomplete style="min-width: 300px; width: 15%" [disabled]="disabled" #articleAutocomplete
                                         (selectedMaterial)="setSelectedMaterial($event)"
                                         [placeholder]="getPlaceholderArtikel()" [gesperrtAusblenden]="true"
                                         [showPrice]="true"
                                         [kundeId]="heimtierBeleg?.kunde_id" [required]="true" [focus]="focusOnInit"
                                         (cleared)="materialInputCleared()"
                                         [nurAbgegebeneArtikel]="false">
        </kiene-article-scan-autocomplete>


        <kiene-anwendungs-selector #anwendungSelector style="width: 10%" [required]="false"
                                   [disabled]="istMengenArtikel() || disabled"
                                   (change)="setSelectedAnwendung($event)">
        </kiene-anwendungs-selector>

        <kiene-diagnose-selector #diagnoseSelector style="width: 15%"
                                 [disabled]="istMengenArtikel() || disabled"
                                 [required]="false" (change)="setSelectedDiagnose($event)">
        </kiene-diagnose-selector>

        <mat-form-field *ngIf="istAbgabeUndBehandlung()" style="width: 5%">
            <mat-label>Behandlung:</mat-label>
            <mat-select #behandlungJaNein [formControl]="behandlungJaNeinCtrl" [disabled]="disabled">
                <mat-option [value]="true">Ja</mat-option>
                <mat-option [value]="false">Nein</mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field style="width: 8%">
            <mat-label>Anwendungsdauer</mat-label>
            <input #anwendungsdauer="matInput" matInput type="number" [formControl]="anwendungsdauerCtrl"
                   (focus)="onFocusNumberInput()"
                   (change)="anwendungsdauerChange()"/>
        </mat-form-field>

        <mat-form-field style="width: 70px;">
            <mat-label>Menge {{ mengeExakt }}</mat-label>
            <input #mengeInput="matInput" matInput type="number" [formControl]="mengeCtrl"
                   (change)="mengeChange()"/>
        </mat-form-field>

        <mat-form-field style="width: 70px;">
            <mat-label>Faktor</mat-label>
            <input #mengeInput="matInput" matInput type="number" [formControl]="faktorCtrl"
                   (change)="faktorChange()"/>
        </mat-form-field>


        <mat-form-field style="width: 12%">
            <mat-label>{{
                    position?.dosierung_txt ? position.dosierung_txt : "Dosierung"
                }}
            </mat-label>

            <input matInput type="number" [formControl]="dosierungCtrl" (focus)="onFocusDosierung()"
                   (blur)="onBlurDosierung()"/>
        </mat-form-field>

        <button mat-icon-button color="accent" (click)="savePosition()" [disabled]="isSaving">
            <mat-icon>{{ position?.hba_id ? 'save' : 'add' }}</mat-icon>
        </button>

    </div>

    <table class="phito-table leistungen-table">
        <thead>
        <tr>
            <th>Pos.</th>
            <th>Tier</th>
            <th>Artikel</th>
            <th>Anwendung</th>
            <th>Diagnose</th>
            <th>Behandlung</th>
            <th>AD</th>
            <th>Menge</th>
            <th>Faktor</th>
            <th>Dos</th>
            <th>Preis</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let pos of heimtierBeleg?.positionen; let i = index;" (click)="editPosition(pos)">
            <td>{{ (i + 1) }}</td>
            <td>{{ pos?.name }}</td>
            <td>{{ pos?.artikel_me }}</td>
            <td>{{ pos?.anwendung }}</td>
            <td>{{ pos?.diagnose }}</td>
            <td>{{ pos?.behandlung === 1 ? 'ja' : 'nein' }}</td>
            <td>{{ pos?.anwendungsdauer }}</td>
            <td>{{ pos?.artikelmenge }}</td>
            <td>{{ pos?.faktor }}</td>
            <td>{{ pos?.dosierung }}</td>
            <td>{{ pos?.einzelpreis | number: '1.2-2' }}&euro;</td>
            <td>
                <ng-container *ngIf="!pos?.aus_stueckliste">
                    <button mat-icon-button (click)="$event.stopPropagation(); removePosition(pos)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </ng-container>
            </td>
        </tr>
        </tbody>
    </table>

</div>
