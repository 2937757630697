<h1 mat-dialog-title>Wochenend- und Feiertagsplanung</h1>

<div mat-dialog-content>

    <div class="one-row">
        <mat-form-field class="no-padding-margin-mat-form-field" style="width: 45%">
            <mat-label>Monat</mat-label>
            <input matInput [matDatepicker]="dp" [formControl]="currentMonthCtrl">
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp
                            startView="year"
                            (monthSelected)="setMonthAndYear($event, dp)">
            </mat-datepicker>
        </mat-form-field>

        <mat-form-field class="no-padding-margin-mat-form-field" style="width: 45%">
            <mat-label>Gruppen</mat-label>
            <mat-select [formControl]="currentGruppeCtrl">
                <mat-option *ngFor="let gruppe of tierarztGruppen" [value]="gruppe">{{
                    gruppe.bezeichnung
                    }}</mat-option>
            </mat-select>
        </mat-form-field>

    </div>

    <div style="width: 100%; text-align: center; padding: 64px 0" *ngIf="isLoading">
        <mat-hint class="hinweis-text">Lade Eintr&auml;ge...</mat-hint>
    </div>

    <div *ngIf="!isLoading">
        <div style="width: 100%; text-align: center; padding: 64px 0" *ngIf="eintraege.length === 0">
            <mat-hint class="hinweis-text">Keine Eintr&auml;ge für die ausgew&auml;hlte Konfiguration gefunden</mat-hint>
        </div>
        <div *ngIf="eintraege.length > 0">
            <div *ngFor="let eintrag of eintraege">
                <div class="one-row" style="width: 50%;">
                    <strong style="margin-left: 12px">{{eintrag.startdatum | date: 'dd.MM.YY'}}</strong>
                    <strong>{{eintrag.titel}}</strong>
                </div>
                <span class="spacer"></span>
                <mat-form-field style="width: 45%" class="no-padding-margin-mat-form-field">
                    <mat-label>Eingeplanter Tierarzt</mat-label>
                    <mat-select [value]="getTierarzt(eintrag)" [compareWith]="tierarztComparator" (selectionChange)="updateEintrag($event, eintrag)">
                        <mat-option [value]="null">- kein Tierarzt -</mat-option>
                        <mat-option *ngFor="let tierarzt of tieraerzte" [value]="tierarzt">{{tierarzt.vorname}} {{tierarzt.nachname}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-flat-button color="accent" (click)="dialogRef.close(editedDate)">Schlie&szlig;en</button>
</div>
