import { Injectable, Component, Inject, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../../app.constants';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpEventType, HttpResponse, HttpHeaders } from '@angular/common/http';
import { UserroleApi, UserroleRightApi, UserrightApi, Userright, UserroleRight } from './userrole';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import { UserRole } from '../user/user';

@Injectable({
  providedIn: 'root'
})
export class UserroleService {

  constructor(private http: HttpClient,
    @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) { }

  getAllUserroles(): Observable<UserroleApi> {
    return this.http.get<UserroleApi>(this.apiBaseUrl + 'benutzerrolle/read.php');
  }

  getNotSelectedRightsForRole(role: UserRole): Observable<UserrightApi> {
    return this.http.get<UserrightApi>(this.apiBaseUrl + 'benutzerrecht/read_unbenutzt.php?benutzerrolle_id=' + role.benutzerrolle_id);
  }

  getUserroleRights(role: UserRole): Observable<UserroleRightApi> {
    return this.http.get<UserroleRightApi>(this.apiBaseUrl + 'benutzerrolle_benutzerrecht/read.php?benutzerrolle_id=' + role.benutzerrolle_id);
  }

  addRightToRole(role: UserRole, right: Userright) {
    const userroleRight: UserroleRight = new UserroleRight();
    userroleRight.benutzerrolle_id = role.benutzerrolle_id;
    userroleRight.benutzerrecht_id = right.benutzerrecht_id;
    return this.http.post(this.apiBaseUrl + 'benutzerrolle_benutzerrecht/create.php', userroleRight);
  }

  removeRightFromRole(role: UserRole, right: Userright) {
    const userroleRight = new UserroleRight();
    userroleRight.benutzerrolle_id = role.benutzerrolle_id;
    userroleRight.benutzerrecht_id = right.benutzerrecht_id;
    return this.http.post(this.apiBaseUrl + 'benutzerrolle_benutzerrecht/delete.php', userroleRight);
  }
}
