import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';
import {
    ArticleScanSelectedEvent
} from '../../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import {
    ArticleScanAutocompleteService
} from '../../../components/article-scan-autocomplete/article-scan-autocomplete.service';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MessageService} from '../../../services/message.service';
import {Material} from '../../../warehouse/material/material';
import {BestellvorschlagIntern} from '../bestellvorschlag-intern';

@Component({
    selector: 'kiene-bestellvorschlag-intern-dialog',
    templateUrl: './bestellvorschlag-intern-dialog.component.html',
    styleUrls: ['./bestellvorschlag-intern-dialog.component.css']
})
export class BestellvorschlagInternDialogComponent implements OnInit {

    bestellvorschlagIntern: BestellvorschlagIntern;
    presetMaterial = new Material();

    mindestbestandCtrl = new UntypedFormControl(0, [Validators.min(1)]);

    constructor(public dialogRef: MatDialogRef<BestellvorschlagInternDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data,
                private api: KieneTableService,
                private messageService: MessageService,
                private localStorageService: LocalStorageService) {
        this.bestellvorschlagIntern = this.data.bestellvorschlagIntern;
        if (this.bestellvorschlagIntern.artikel_id) {
            this.presetMaterial.artikel_id = this.bestellvorschlagIntern.artikel_id;
            this.presetMaterial.artikel_me = this.bestellvorschlagIntern.artikel_me;
            // this.articleScanService.setPresetMaterial(this.presetMaterial);
        }
    }

    ngOnInit(): void {
        if (!this.bestellvorschlagIntern) {
            this.bestellvorschlagIntern = new BestellvorschlagIntern();
        }


        this.mindestbestandCtrl.setValue(this.bestellvorschlagIntern.mindestbestand);
    }

    formIsValid(): boolean {
        return (this.mindestbestandCtrl.valid);
    }

    setMaterial(event: ArticleScanSelectedEvent) {

        this.bestellvorschlagIntern.artikel_id = event.article.artikel_id;


    }

    cancel() {
        this.dialogRef.close();
    }

    save() {

        this.bestellvorschlagIntern.mindestbestand = this.mindestbestandCtrl.value;
        this.bestellvorschlagIntern.tierarzt_id = this.localStorageService.getCurrentUser().tierarzt_id;

        this.api.post(environment.BASE_URI + 'artikel_tierarzt/update.php', this.bestellvorschlagIntern, null).subscribe(response => {
            this.messageService.infoMessage('Bestellvorschlag wurde erfolgreich gespeichert!');
            this.dialogRef.close(true);
        }, error => {
            this.messageService.errorMessage('Fehler beim Speichern von Bestellvorschlag!', error);
            this.dialogRef.close(true);
        });
    }

}
