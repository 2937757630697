import { DatePipe, formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger, } from '@angular/animations';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChartType } from 'angular-google-charts';
import { environment } from '../../../../environments/environment';
import { Vet } from '../../../administration/vet/vet';
import { AppService } from '../../../app.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { ZeitauswahlChange } from '../../zeitauswahl/zeitauswahl-change';
import { TierarztGruppe } from './tierarzt-gruppe';
import { VetGroup } from '../../../administration/vet-group/vet-group';
import { CsvColumn } from '../../../csv-export/csv-export/csv-column';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { Subscription } from 'rxjs';
import { ReportUtils } from '../../report-utils';

@Component({
    selector: 'kiene-tierarzt-gruppe-report',
    templateUrl: './tierarzt-gruppe-report.component.html',
    styleUrls: ['./tierarzt-gruppe-report.component.scss'],
    animations: [
        trigger('detailExpanded', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
    ],
})
export class TierarztGruppeReportComponent implements OnInit, AfterViewInit {
    displayedColumns = [
        'tierarzt',
        'umsatz_prozent',
        'umsatz',
        'wareneinsatz',
        'wareneinsatz_prozent',
        'rohertrag',
        'rohertrag_prozent',
        'gehaelter',
        'gehaelter_prozent',
        'tierarztkosten',
        'tierarztkosten_prozent',
        'gemeinkosten',
        'gemeinkosten_prozent',
        'gewinn',
        'gewinn_prozent',
    ];
    dataSource = new MatTableDataSource<TierarztGruppe>();
    dataSourceVorjahr = new MatTableDataSource<TierarztGruppe>();
    @ViewChild(MatSort) sort: MatSort;
    expandedElement: TierarztGruppe | null;

    tierarztgruppen: VetGroup[] = [];
    tierarztgruppeCtrl = new UntypedFormControl();

    tieraerzte: Vet[] = [];
    aktuellerTierarzt: Vet = new Vet();
    tierarztCtrl = new UntypedFormControl();

    rabattCtrl = new FormControl();
    ergaenzerCtrl = new FormControl();
    aktuellSubscription: Subscription;
    vorjahrSubscription: Subscription;

    zeitauswahl: ZeitauswahlChange;
    vorjahreszeitraum: ZeitauswahlChange;

    chartType = ChartType.Line;
    chartColumns = ['Zeitraum', 'Umsatz', 'Kosten', 'Gewinn'];
    chartData = [['', 0, 0, 0]];

    csvColumns: CsvColumn[] = [];
    csvColumnsDetails: CsvColumn[] = [];
    csvFilename = 'Tierarzt_Gruppe.csv';

    gesamtUmsatz = 0;
    gesamtUmsatzProzent = 0;
    gesamtWareneinsatz = 0;
    gesamtWareneinsatzProzent = '0';
    gesamtRohertrag = 0;
    gesamtRohertragProzent = '0';
    gesamtGehaelter = 0;
    gesamtGehaelterProzent = '0';
    gesamtTierarztkosten = 0;
    gesamtTierarztkostenProzent = '0';
    gesamtGemeinkosten = 0;
    gesamtGemeinkostenProzent = '0';
    gesamtGewinn = 0;
    gesamtGewinnProzent = '0';

    gesamtUmsatzVorjahr = 0;
    gesamtUmsatzProzentVorjahr = 0;
    gesamtWareneinsatzVorjahr = 0;
    gesamtWareneinsatzProzentVorjahr = '0';
    gesamtRohertragVorjahr = 0;
    gesamtRohertragProzentVorjahr = '0';
    gesamtGehaelterVorjahr = 0;
    gesamtGehaelterProzentVorjahr = '0';
    gesamtTierarztkostenVorjahr = 0;
    gesamtTierarztkostenProzentVorjahr = '0';
    gesamtGemeinkostenVorjahr = 0;
    gesamtGemeinkostenProzentVorjahr = '0';
    gesamtGewinnVorjahr = 0;
    gesamtGewinnProzentVorjahr = '0';

    isLoading: boolean[] = [];

    constructor(
        private api: KieneTableService,
        private appService: AppService,
        private datePipe: DatePipe,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.tierarztgruppeCtrl.valueChanges.subscribe(() => this.loadData());
        this.loadTierarztgruppen();

        this.csvColumns.push(new CsvColumn('tierarzt', 'Tierarzt'));
        this.csvColumns.push(
            new CsvColumn('umsatz_prozent', 'Umsatz von Gesamt (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('umsatz', 'Umsatz'));
        this.csvColumns.push(new CsvColumn('wareneinsatz', 'Wareneinsatz'));
        this.csvColumns.push(
            new CsvColumn('wareneinsatz_prozent', 'Wareneinsatz (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('rohertrag', 'Rohertrag'));
        this.csvColumns.push(
            new CsvColumn('rohertrag_prozent', 'Rohertrag (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('gehaelter', 'Gehälter'));
        this.csvColumns.push(
            new CsvColumn('gehaelter_prozent', 'Gehälter (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('tierarztkosten', 'Tierarztkosten'));
        this.csvColumns.push(
            new CsvColumn('tierarztkosten_prozent', 'Tierarztkosten (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('gemeinkosten', 'Gemeinkosten'));
        this.csvColumns.push(
            new CsvColumn('gemeinkosten_prozent', 'Gemeinkosten (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('gewinn', 'Gewinn'));
        this.csvColumns.push(
            new CsvColumn('gewinn_prozent', 'Gewinn (%)', '%')
        );

        this.csvColumnsDetails = this.csvColumns.map((column) => ({
            ...column,
        }));
        this.csvColumnsDetails.splice(
            0,
            1,
            new CsvColumn('zeitraum', 'Zeitraum')
        );
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    loadTierarztgruppen() {
        this.api
            .get(environment.BASE_URI + 'tierarztgruppe/read.php')
            .subscribe((response) => {
                this.tierarztgruppen = response.records;
                // if(this.tierarztgruppen.length > 0){
                //   this.tierarztgruppeCtrl.setValue([this.tierarztgruppen[0]]);
                // }
            });
    }

    loadData() {
        this.loadDataAktuelle();
        this.loadDataVorjahr();
    }

    loadDataAktuelle() {
        // if(!this.tierarztgruppeCtrl.value){
        //   return;
        // }

        this.appService.setDataLoading(true);

        let params = this.createTierarztgruppenParams();

        params = params.set(
            'von',
            this.datePipe.transform(this.zeitauswahl.von, 'yyyy-MM-dd')
        );
        params = params.set(
            'bis',
            this.datePipe.transform(this.zeitauswahl.bis, 'yyyy-MM-dd')
        );

        this.aktuellSubscription?.unsubscribe();
        this.aktuellSubscription = this.api
            .get(
                environment.BASE_URI + 'report/read_tierarzt_gruppe.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSource.data = response.records;
                    this.resetChartData();
                    this.berechneGesamtwerte();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    loadDataVorjahr() {
        // if(!this.tierarztgruppeCtrl.value){
        //   return;
        // }

        this.appService.setDataLoading(true);

        let params = this.createTierarztgruppenParams();

        params = params.set(
            'von',
            this.datePipe.transform(this.vorjahreszeitraum.von, 'yyyy-MM-dd')
        );
        params = params.set(
            'bis',
            this.datePipe.transform(this.vorjahreszeitraum.bis, 'yyyy-MM-dd')
        );

        this.vorjahrSubscription?.unsubscribe();
        this.vorjahrSubscription = this.api
            .get(
                environment.BASE_URI + 'report/read_tierarzt_gruppe.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSourceVorjahr.data = response.records;
                    this.berechneGesamtwerteVorjahr();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    createTierarztgruppenParams(): HttpParams {
        // if(!this.tierarztgruppeCtrl.value){
        //   return;
        // }
        let tierarztgruppenIds = 'null';
        const selectedGruppen = <VetGroup[]>this.tierarztgruppeCtrl.value;
        if (selectedGruppen && selectedGruppen.length > 0) {
            tierarztgruppenIds = '';
            selectedGruppen.forEach((gruppe, index, arr) => {
                tierarztgruppenIds += gruppe.tierarztgruppe_id.toString();
                if (index < arr.length - 1) {
                    tierarztgruppenIds += ',';
                }
            });
        }

        let params = new HttpParams();
        params = params.set('rabatt', this.rabattCtrl.value ? '1' : '0');
        params = params.set('ergaenzer', this.ergaenzerCtrl.value ? '1' : '0');

        return params.set(
            'tierarztgruppe_id',
            tierarztgruppenIds
        );
    }

    zeitintervalChanged(event: ZeitauswahlChange) {
        this.zeitauswahl = event;
        this.vorjahreszeitraum = ReportUtils.getVorjahreszeitraum(this.zeitauswahl);
        this.loadData();
    }

    berechneGesamtwerte() {
        this.gesamtUmsatz = 0;
        this.gesamtWareneinsatz = 0;
        this.gesamtRohertrag = 0;
        this.gesamtGehaelter = 0;
        this.gesamtTierarztkosten = 0;
        this.gesamtGemeinkosten = 0;
        this.gesamtGewinn = 0;
        const data = this.dataSource.data;
        for (const r of data) {
            this.gesamtUmsatz += r.umsatz ? r.umsatz : 0;
            this.gesamtWareneinsatz += r.wareneinsatz ? r.wareneinsatz : 0;
            this.gesamtRohertrag += r.rohertrag ? r.rohertrag : 0;
            this.gesamtGehaelter += r.gehaelter ? r.gehaelter : 0;
            this.gesamtTierarztkosten += r.tierarztkosten
                ? r.tierarztkosten
                : 0;
            this.gesamtGemeinkosten += r.gemeinkosten ? r.gemeinkosten : 0;
            this.gesamtGewinn += r.gewinn ? r.gewinn : 0;
        }

        if (this.gesamtUmsatz > 0) {
            this.gesamtWareneinsatzProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtWareneinsatz * 100
                ) / 100
            ).toString();
            this.gesamtRohertragProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtRohertrag * 100
                ) / 100
            ).toString();
            this.gesamtGehaelterProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtGehaelter * 100
                ) / 100
            ).toString();
            this.gesamtTierarztkostenProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtTierarztkosten * 100
                ) / 100
            ).toString();
            this.gesamtGemeinkostenProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtGemeinkosten * 100
                ) / 100
            ).toString();
            this.gesamtGewinnProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtGewinn * 100
                ) / 100
            ).toString();
        }
    }

    berechneGesamtwerteVorjahr() {
        this.gesamtUmsatzVorjahr = 0;
        this.gesamtWareneinsatzVorjahr = 0;
        this.gesamtRohertragVorjahr = 0;
        this.gesamtGehaelterVorjahr = 0;
        this.gesamtTierarztkostenVorjahr = 0;
        this.gesamtGemeinkostenVorjahr = 0;
        this.gesamtGewinnVorjahr = 0;
        const data = this.dataSourceVorjahr.data;
        for (const r of data) {
            this.gesamtUmsatzVorjahr += r.umsatz ? r.umsatz : 0;
            this.gesamtWareneinsatzVorjahr += r.wareneinsatz ? r.wareneinsatz : 0;
            this.gesamtRohertragVorjahr += r.rohertrag ? r.rohertrag : 0;
            this.gesamtGehaelterVorjahr += r.gehaelter ? r.gehaelter : 0;
            this.gesamtTierarztkostenVorjahr += r.tierarztkosten
                ? r.tierarztkosten
                : 0;
            this.gesamtGemeinkostenVorjahr += r.gemeinkosten ? r.gemeinkosten : 0;
            this.gesamtGewinnVorjahr += r.gewinn ? r.gewinn : 0;
        }

        if (this.gesamtUmsatzVorjahr > 0) {
            this.gesamtWareneinsatzProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtWareneinsatzVorjahr * 100
                ) / 100
            ).toString();
            this.gesamtRohertragProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtRohertragVorjahr * 100
                ) / 100
            ).toString();
            this.gesamtGehaelterProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtGehaelterVorjahr * 100
                ) / 100
            ).toString();
            this.gesamtTierarztkostenProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtTierarztkostenVorjahr * 100
                ) / 100
            ).toString();
            this.gesamtGemeinkostenProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtGemeinkostenVorjahr * 100
                ) / 100
            ).toString();
            this.gesamtGewinnProzent = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtGewinnVorjahr * 100
                ) / 100
            ).toString();
        }
    }

    expandVetGroupRow(element: TierarztGruppe) {
        this.expandedElement =
            this.expandedElement === element ? null : element;
        if (this.expandedElement) {
            this.isLoading[element.tierarzt_id] = true;

            let params = new HttpParams();
            params = params.set('von', formatDate(this.zeitauswahl.von, 'yyyy-MM-dd', 'de'));
            params = params.set('bis', formatDate(this.zeitauswahl.bis, 'yyyy-MM-dd', 'de'));
            params = params.set('aggregieren', this.zeitauswahl.aggregieren);
            params = params.set('tierarzt_id', element.tierarzt_id.toString());
            this.api
                .get(
                    environment.BASE_URI + 'report/read_tierarzt_gruppe.php',
                    params
                )
                .subscribe(
                    (response) => {
                        element.details = response.records;
                        this.isLoading[element.tierarzt_id] = false;
                        this.chartData = [];
                        for (const d of element.details) {
                            const kosten =
                                (d.gemeinkosten ? d.gemeinkosten : 0) +
                                (d.tierarztkosten ? d.tierarztkosten : 0);
                            this.chartData.push([
                                d.zeitraum,
                                d.umsatz ? d.umsatz : 0,
                                kosten,
                                d.gewinn ? d.gewinn : 0,
                            ]);
                        }
                    },
                    (error) => {
                        this.messageService.errorMessage('Fehler!', error);
                        this.isLoading[element.tierarzt_id] = false;
                    }
                );
        }
    }

    resetChartData() {
        this.chartData = [['', 0, 0, 0]];
    }

}
