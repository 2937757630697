import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../../../services/message-service/message.service';
import {UntypedFormControl, UntypedFormGroup, Validators,} from '@angular/forms';
import {KienePatternUtils} from '../../../../utils/KienePatternUtils';
import {MatRadioChange} from '@angular/material/radio';
import {RegistrierungService} from '../../../registrierung/registrierung.service';
import {VvvoBasisInfosFormComponent} from '../vvvo-basis-infos-form/vvvo-basis-infos-form.component';
import {Client} from '../../../client/classes/client';
import {KieneUtils} from '../../../../utils/KieneUtils';
import {StammdatenService} from '../../stammdaten.service';
import {Subscription} from 'rxjs';
import {HitLoginResponse} from '../../../../hit/hit-registrierung/classes/hit-login-response';
import {HitService} from '../../../../hit/hit.service';

@Component({
    selector: 'vvvo-details',
    templateUrl: './vvvo-details.component.html',
    styleUrls: ['./vvvo-details.component.scss'],
})
export class VvvoDetailsComponent implements OnInit, OnDestroy {

    @Input() isDialog = false;
    @Output() save = new EventEmitter<Client>();

    kundeSubscription: Subscription;
    @Input() public kunde: Client;
    kundeId: number;
    redirectTo = '../../';
    hitLoginResponse: HitLoginResponse;
    saveText = 'Einstellungen speichern';
    kontoinhaberCtrl: UntypedFormControl = new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(KienePatternUtils.NAME()),
    ]);
    kreditinstitutCtrl: UntypedFormControl = new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(KienePatternUtils.NAME()),
    ]);
    ibanCtrl: UntypedFormControl = new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(KienePatternUtils.IBAN_INTERNATIONAL()),
    ]);
    rechnungsOptionenCtrl: UntypedFormControl = new UntypedFormControl(null, [
        Validators.required,
    ]);
    lastschriftFormGroup: UntypedFormGroup = new UntypedFormGroup({
        kontoinhaberCtrl: this.kontoinhaberCtrl,
        kreditinstitutCtrl: this.kreditinstitutCtrl,
        ibanCtrl: this.ibanCtrl,
    });
    abweichendeLieferadresseCtrl: UntypedFormControl = new UntypedFormControl(
        null,
        [Validators.required]
    );
    abweichendeLieferadresseStrasse: UntypedFormControl =
        new UntypedFormControl(null, [Validators.required]);
    abweichendeLieferadressePlz: UntypedFormControl = new UntypedFormControl(
        null,
        [Validators.required, Validators.pattern(KienePatternUtils.PLZ())]
    );
    abweichendeLieferadresseOrt: UntypedFormControl = new UntypedFormControl(
        null,
        [Validators.required]
    );
    abweichendeLieferadresseOrtsteil: UntypedFormControl =
        new UntypedFormControl(null);
    abweichendeLieferadresseFormGroup: UntypedFormGroup = new UntypedFormGroup({
        abweichendeLieferadresseStrasse: this.abweichendeLieferadresseStrasse,
        abweichendeLieferadressePlz: this.abweichendeLieferadressePlz,
        abweichendeLieferadresseOrt: this.abweichendeLieferadresseOrt,
        abweichendeLieferadresseOrtsteil: this.abweichendeLieferadresseOrtsteil
    });
    abweichendeRechnungsadresseCtrl: UntypedFormControl =
        new UntypedFormControl(Validators.required);
    rechnungsaddresseVornameCtrl: UntypedFormControl = new UntypedFormControl(
        null,
        [Validators.pattern(KienePatternUtils.NAME())]
    );
    rechnungsaddresseNachnameCtrl: UntypedFormControl = new UntypedFormControl(
        null,
        [Validators.pattern(KienePatternUtils.NAME())]
    );
    rechnungsaddresseStrasseCtrl: UntypedFormControl = new UntypedFormControl(
        null,
        []
    );
    rechnungsaddressePlzCtrl: UntypedFormControl = new UntypedFormControl(
        null,
        [Validators.pattern(KienePatternUtils.PLZ())]
    );
    rechnungsaddresseOrtCtrl: UntypedFormControl = new UntypedFormControl(
        null,
        [Validators.pattern(KienePatternUtils.NAME())]
    );
    rechnungsaddresseOrtsteilCtrl: UntypedFormControl = new UntypedFormControl(
        null,
        [Validators.pattern(KienePatternUtils.NAME())]
    );
    rechnungsaddresseFormGroup: UntypedFormGroup = new UntypedFormGroup({
        rechnungsaddresseVornameCtrl: this.rechnungsaddresseVornameCtrl,
        rechnungsaddresseNachnameCtrl: this.rechnungsaddresseNachnameCtrl,
        rechnungsaddresseStrasseCtrl: this.rechnungsaddresseStrasseCtrl,
        rechnungsaddressePlzCtrl: this.rechnungsaddressePlzCtrl,
        rechnungsaddresseOrtCtrl: this.rechnungsaddresseOrtCtrl,
        rechnungsaddresseOrtsteilCtrl: this.rechnungsaddresseOrtsteilCtrl,
    });
    abweichendeEmailCtrl: UntypedFormControl = new UntypedFormControl(null, [
        Validators.pattern(KienePatternUtils.EMAIL()),
    ]);
    perPostCtrl: UntypedFormControl = new UntypedFormControl(null, []);
    perEmailCtrl: UntypedFormControl = new UntypedFormControl(null, []);
    zustellungsForm: UntypedFormGroup = new UntypedFormGroup({
        abweichendeEmailCtrl: this.abweichendeEmailCtrl,
        perPostCtrl: this.perPostCtrl,
        perEmailCtrl: this.perEmailCtrl,
    });
    bestandsbuchDigitalCtrl: UntypedFormControl = new UntypedFormControl(null);
    ersteinrichtung = false;
    @ViewChild('basisInfos')
    private basisInfos: VvvoBasisInfosFormComponent;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private stammdatenService: StammdatenService,
        private registrierungService: RegistrierungService,
        private hitService: HitService
    ) {
    }

    ngOnDestroy(): void {
        this.kundeSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        if (!this.isDialog) {

            this.route.queryParams.subscribe((params) => {
                if (params.ersteinrichtung) {
                    this.ersteinrichtung = true;
                    this.redirectTo = '../../';
                    this.saveText = 'VVVO hinzufügen';
                }
            });

            this.route.params.subscribe((params) => {
                if (params.id) {
                    this.kundeId = params.id;
                    this.loadKunde();
                } else if (this.hitService.hasHitLoggedIn()) {
                    // neue HIT VVVO hinzugefügt - pin an Backend senden
                    // this.registerNewVVVOToExistingClient();
                    this.hitLoginResponse = this.hitService.getHitLoginResponse();
                    this.kunde = HitLoginResponse.generateClient(
                        this.hitLoginResponse
                    );
                    this.setDefaults();
                    this.setKundeInForm();
                    this.initFormControls();
                } else {
                    this.router
                        .navigate(['../uebersicht'], {relativeTo: this.route})
                        .then();
                }
            });
        }
    }

    /**
     * Setzt die Defaultwerte für die Kundendaten, wenn keine Daten vorhanden sind.
     * <strong>Tierbewegung_melden immer auf 1 setzen.</strong>
     */
    setDefaults() {
        this.kunde.tierbewegung_melden = 1;

        if (!this.kunde.kp_abw_rechnungsadresse) {
            this.kunde.kp_abw_rechnungsadresse = 0;
        }
        if (!this.kunde.abweichende_lieferadresse) {
            this.kunde.abweichende_lieferadresse = 0;
        }
        if (!this.kunde.kp_lastschrift) {
            this.kunde.kp_lastschrift = 0;
        }
        if (!this.kunde.kp_rechnung_post) {
            this.kunde.kp_rechnung_post = 0;
        }
        if (!this.kunde.kp_rechnung_email) {
            this.kunde.kp_rechnung_email = 1;
        }

        if (!this.kunde.bestandsbuch_digital) {
            this.kunde.bestandsbuch_digital = 0;
        }
    }

    loadKunde() {
        this.kundeSubscription?.unsubscribe();
        this.kundeSubscription = this.stammdatenService
            .getKunde(this.kundeId)
            .subscribe({
                next: (kunde) => {
                    this.kunde = kunde;
                    this.setDefaults();
                    this.setKundeInForm();
                    this.initFormControls();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fehler beim Laden der VVVO',
                        err
                    );
                },
            });
    }

    setKundeInForm() {
        // Abweichende Lieferadresse
        this.setAbweichendeLieferadresseInForm();

        // Abrechnung
        this.setAbrechnungInForm();
    }

    setAbweichendeLieferadresseInForm() {
        this.abweichendeLieferadresseCtrl.setValue(
            this.kunde.abweichende_lieferadresse
        );

        this.abweichendeLieferadresseStrasse.setValue(
            this.kunde.strasse_lieferung
        );
        this.abweichendeLieferadressePlz.setValue(this.kunde.plz_lieferung);
        this.abweichendeLieferadresseOrt.setValue(this.kunde.ort_lieferung);
        this.abweichendeLieferadresseOrtsteil.setValue(
            this.kunde.ortsteil_lieferung
        );

        this.triggerAbweichendeLieferadresseCtrls(
            this.kunde.abweichende_lieferadresse
        );
    }

    setAbrechnungInForm() {
        // Allg.
        this.rechnungsOptionenCtrl.setValue(this.kunde.kp_lastschrift);

        // per Rechnung - Abweichende Rechnungsadresse
        this.abweichendeRechnungsadresseCtrl.setValue(
            this.kunde.kp_abw_rechnungsadresse
        );

        this.rechnungsaddresseVornameCtrl.setValue(
            this.kunde.kp_abw_rechnungsadresse_vorname
        );
        this.rechnungsaddresseNachnameCtrl.setValue(
            this.kunde.kp_abw_rechnungsadresse_nachname
        );
        this.rechnungsaddresseStrasseCtrl.setValue(
            this.kunde.kp_abw_rechnungsadresse_strasse
        );
        this.rechnungsaddressePlzCtrl.setValue(
            this.kunde.kp_abw_rechnungsadresse_plz
        );
        this.rechnungsaddresseOrtCtrl.setValue(
            this.kunde.kp_abw_rechnungsadresse_ort
        );
        this.rechnungsaddresseOrtsteilCtrl.setValue(
            this.kunde.kp_abw_rechnungsadresse_ortsteil
        );
        this.triggerAbweichendeRechnungsadresseCtrls(
            this.kunde.kp_abw_rechnungsadresse
        );

        // per Lastschrift
        this.kontoinhaberCtrl.setValue(this.kunde.kp_kontoinhaber);
        this.kreditinstitutCtrl.setValue(this.kunde.kp_kreditinstitut);
        this.ibanCtrl.setValue(this.kunde.kp_iban);

        this.triggerLastschriftCtrls(this.kunde.kp_lastschrift);

        // Rechnungszustellung
        if (this.kunde.kp_rechnung_post === 1) {
            this.perPostCtrl.setValue(!!this.kunde.kp_rechnung_post);
        }
        if (this.kunde.kp_rechnung_email === 1) {
            this.perEmailCtrl.setValue(!!this.kunde.kp_rechnung_email);
        }
        this.abweichendeEmailCtrl.setValue(this.kunde.kp_abw_email);

        this.triggerRechnungszustellungCtrls(this.kunde.kp_rechnung_email);
    }

    initFormControls() {
        this.rechnungsOptionenCtrl.valueChanges.subscribe((valueChanges) => {
            this.kunde.kp_lastschrift = valueChanges;
            this.triggerLastschriftCtrls(this.kunde.kp_lastschrift);
        });

        this.abweichendeRechnungsadresseCtrl.valueChanges.subscribe(
            (valueChanges) => {
                if (valueChanges) {
                    this.kunde.kp_abw_rechnungsadresse = 1;
                    this.rechnungsaddresseFormGroup.enable();
                } else {
                    this.kunde.kp_abw_rechnungsadresse = 0;
                    this.rechnungsaddresseFormGroup.disable();
                }
            }
        );

        this.abweichendeLieferadresseCtrl.valueChanges.subscribe(
            (valueChanges) => {
                if (valueChanges) {
                    this.kunde.abweichende_lieferadresse = 1;
                    this.abweichendeLieferadresseFormGroup.enable();
                } else {
                    this.kunde.abweichende_lieferadresse = 0;
                    this.abweichendeLieferadresseFormGroup.disable();
                }
            }
        );

        this.perEmailCtrl.valueChanges.subscribe((valueChanges) => {
            valueChanges
                ? this.abweichendeEmailCtrl.enable()
                : this.abweichendeEmailCtrl.disable();
        });
    }

    updateKunde() {
        this.setCtrlsInKunde();

        this.stammdatenService.saveKunde(this.kunde).subscribe({
            next: (resp) => {
                this.stammdatenService.setAktualisierteVvvo(resp);
                this.messageService.infoMessage('VVVO wurde aktualisiert.');
                this.registrierungService.addVvvoErsteinrichtung();

                if (!this.isDialog) {

                    if (this.kundeId) {
                        this.redirectTo = '../../';
                    } else {
                        this.redirectTo = '../';
                    }
                    this.router
                        .navigate([this.redirectTo], {relativeTo: this.route})
                        .then();
                } else {
                    this.save.emit(resp);
                }
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Fehler: VVVO konnte nicht aktualisiert werden.',
                    err
                );
            },
        });
    }

    setCtrlsInKunde() {
        if (this.hitLoginResponse) {
            this.kunde.pin = this.hitLoginResponse.pin;
        }

        this.kunde.kp_kontoinhaber = this.kontoinhaberCtrl.value;
        this.kunde.kp_kreditinstitut = this.kreditinstitutCtrl.value;
        this.kunde.kp_iban = this.ibanCtrl.value;
        if (this.kunde.kp_lastschrift) {
            this.kunde.kp_lastschrift = this.rechnungsOptionenCtrl.value;
        }

        this.kunde.abweichende_lieferadresse = this.abweichendeLieferadresseCtrl
            .value
            ? 1
            : 0;
        this.kunde.strasse_lieferung =
            this.abweichendeLieferadresseStrasse.value;
        this.kunde.plz_lieferung = this.abweichendeLieferadressePlz.value;
        this.kunde.ort_lieferung = this.abweichendeLieferadresseOrt.value;
        this.kunde.ortsteil_lieferung =
            this.abweichendeLieferadresseOrtsteil.value;

        this.kunde.kp_abw_rechnungsadresse = this
            .abweichendeRechnungsadresseCtrl.value
            ? 1
            : 0;
        this.kunde.kp_abw_rechnungsadresse_vorname =
            this.rechnungsaddresseVornameCtrl.value;
        this.kunde.kp_abw_rechnungsadresse_nachname =
            this.rechnungsaddresseNachnameCtrl.value;
        this.kunde.kp_abw_rechnungsadresse_strasse =
            this.rechnungsaddresseStrasseCtrl.value;
        this.kunde.kp_abw_rechnungsadresse_plz =
            this.rechnungsaddressePlzCtrl.value;
        this.kunde.kp_abw_rechnungsadresse_ort =
            this.rechnungsaddresseOrtCtrl.value;
        this.kunde.kp_abw_rechnungsadresse_ortsteil =
            this.rechnungsaddresseOrtsteilCtrl.value;

        this.kunde.kp_rechnung_post = this.perPostCtrl.value ? 1 : 0;
        this.kunde.kp_rechnung_email = this.perEmailCtrl.value ? 1 : 0;
        this.kunde.kp_abw_email = this.abweichendeEmailCtrl.value;
    }

    triggerLastschriftCtrls(lastschriftEnable: number) {
        if (lastschriftEnable === 1) {
            this.lastschriftFormGroup.enable();
        } else {
            this.lastschriftFormGroup.disable();
        }
    }

    triggerAbweichendeRechnungsadresseCtrls(
        abweichendeRechnungsadresseEnable: number
    ) {
        if (abweichendeRechnungsadresseEnable === 1) {
            this.rechnungsaddresseFormGroup.enable();
        } else {
            this.rechnungsaddresseFormGroup.disable();
        }
    }

    triggerAbweichendeLieferadresseCtrls(
        abweichendeLieferadresseEnable: number
    ) {
        if (abweichendeLieferadresseEnable === 1) {
            this.abweichendeLieferadresseFormGroup.enable();
        } else {
            this.abweichendeLieferadresseFormGroup.disable();
        }
    }

    changeRechnungsoption(event: MatRadioChange) {
        const lastschrift: number = event.value;
        if (this.kunde) {
            this.kunde.kp_lastschrift = lastschrift;
        }

        this.triggerLastschriftCtrls(lastschrift);
    }

    hasAbweichendeRechnungsadresse() {
        return this.abweichendeRechnungsadresseCtrl.value;
    }

    hasAbweichendeLieferadresse() {
        return this.abweichendeLieferadresseCtrl.value;
    }

    allFormsValid() {
        let valid = this.basisInfos?.areCtrlsValid();
        valid = valid && KieneUtils.isNNoU(this.kunde.bestandsbuch_digital);

        // if (this.hasAbweichendeLieferadresse()) {
        //     valid = valid && this.abweichendeLieferadresseFormGroup.valid;
        // }
        // if (this.hasAbweichendeRechnungsadresse()) {
        //     valid = valid && this.rechnungsaddresseFormGroup.valid;
        // }
        // valid =
        //     valid &&
        //     this.zustellungsForm.valid &&
        //     (this.perPostCtrl.value || this.perEmailCtrl.value);
        //
        // if (parseInt(this.rechnungsOptionenCtrl.value, 10) === 1) {
        //     valid = valid && this.lastschriftFormGroup.valid;
        // }
        return valid;
    }

    changeBestandsbuchDigital(event: MatRadioChange) {
        const bestandsbuch_digital: number = event.value;
        if (this.kunde) {
            this.kunde.bestandsbuch_digital = bestandsbuch_digital;
        }
    }

    private triggerRechnungszustellungCtrls(kp_rechnung_email: number) {
        if (kp_rechnung_email === 1) {
            this.abweichendeEmailCtrl.enable();
        } else {
            this.abweichendeEmailCtrl.disable();
        }
    }
}
