import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Gruppe } from '../gruppe';

@Component({
  selector: 'kiene-gruppe-details-dialog',
  templateUrl: './gruppe-details-dialog.component.html',
  styleUrls: ['./gruppe-details-dialog.component.css']
})
export class GruppeDetailsDialogComponent implements OnInit {

  gruppe: Gruppe;
  auswahl = false;
  identifikation = false;
  identifikationText = '';

  constructor(public dialogRef: MatDialogRef<GruppeDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) {
      this.gruppe = this.data.gruppe;
      this.auswahl = this.data.auswahl;
      this.identifikation = this.data.indentifikation;
      this.identifikationText = this.data.identifikationText;
     }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

}
