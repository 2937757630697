import {HttpClient, HttpParams} from '@angular/common/http';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AppService} from '../../../../app.service';
import {KieneAutocompleteComponent} from '../../../../components/kiene-autocomplete/kiene-autocomplete.component';
import {MessageService} from '../../../../services/message.service';
import {Gruppe} from '../../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import {
    Betriebsart,
    Produktionsabschnitt
} from '../../../../../../projects/kiene-core/src/lib/components/stammdaten/vvvo/classes/betriebsart';
import {GruppeDialogInput} from './gruppe-dialog';
import {Subscription} from 'rxjs';
import {API_BASE_URL_SERVICE} from '../../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    LocalStorageService
} from '../../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {
    KieneBackendApiService
} from '../../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'kiene-gruppe-dialog',
    templateUrl: './gruppe-dialog.component.html',
    styleUrls: ['./gruppe-dialog.component.css'],
})
export class GruppeDialogComponent implements OnInit {
    @ViewChild('standortAutocomplete')
    standortAutocomplete: KieneAutocompleteComponent;

    standortParams = new HttpParams();
    standort: string = null;

    sgsNummerCtrl = new FormControl();
    gruppennameCtrl = new FormControl();
    aufstalleinheitJahrCtrl = new FormControl();
    aufstalleinheitNummerCtrl = new FormControl();

    einstalldatumVonCtrl = new FormControl();
    einstalldatumBisCtrl = new FormControl();
    anzahlTiereCtrl = new FormControl();
    alterEinstallungCtrl = new FormControl();
    // csvFile: File;
    // fileName: string;

    produktionsabschnittCtrl = new FormControl<Produktionsabschnitt>(undefined);
    produktionsabschnitte: Produktionsabschnitt[] = [];
    produktionsabschnittSub: Subscription;

    sgsMeldenCtrl = new FormControl(false);
    sgsEinblenden = false;
    sgsNummerEinblenden = false;

    constructor(
        public dialogRef: MatDialogRef<GruppeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public input: GruppeDialogInput,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private http: HttpClient,
        private appService: AppService,
        public lss: LocalStorageService,
    ) {
    }

    ngOnInit(): void {
        this.checkSgsMelden();

        if (this.input?.nameVorschlag) {
            this.gruppennameCtrl.setValue(this.input.nameVorschlag);
        }

        if (this.input?.kunde?.sgs_nummer) {
            this.sgsNummerCtrl.setValue(this.input.kunde.sgs_nummer);
        }

        if (this.input?.kunde) {
            if (this.input?.kunde?.schwein === 1) {
                this.alterEinstallungCtrl = new FormControl(14);
            }

            this.standortParams = this.standortParams.set(
                'kunde_id',
                this.input?.kunde?.kunde_id?.toString()
            );

            this.loadGruppennamenUndAufstalleinheitVorschlag();
            this.ladeProduktionsabschnitte();
        } else {
            this.messageService.alertMessage('Es wurde kein Kunde übergeben!');
        }

        if (!this.lss.kostenpflichtigAktiv()) {

            this.einstalldatumVonCtrl.valueChanges.subscribe((value: Date) => {
                this.einstalldatumBisCtrl.setValue(
                    value ? new Date(value.getTime() + 6 * 24 * 3600 * 1000) : null
                );
            });
        }
    }

    setStandort(standort) {
        if (typeof standort === 'object' && standort?.stall) {
            this.standort = standort.stall;
            if (standort?.sgs_nummer) {
                this.sgsNummerCtrl.setValue(standort.sgs_nummer);
            }
        }
        if (typeof standort === 'string' && standort) {
            this.standort = standort;
        }
    }

    deleteStall(gruppe: Gruppe) {
        this.api
            .deleteGeneric(
                this.apiBaseUrl + 'gruppe/delete_stall.php',
                gruppe
            )
            .subscribe({
                next: () => {
                    this.messageService.infoMessage(
                        'Der Standord wurde erfolgreich gelöscht!'
                    );
                    this.standortAutocomplete.clearAndFocus();
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Der Standort konnte nicht gelöscht werden',
                        error
                    );
                },
            });
    }

    loadGruppennamenUndAufstalleinheitVorschlag() {
        this.api
            .getByIdAlternative(this.apiBaseUrl + 'gruppe/read_vorschlag.php', 'kunde_id',
                this.input?.kunde?.kunde_id?.toString())
            .subscribe((response) => {
                if (response) {
                    if (!this.input.nameVorschlag) {
                        this.gruppennameCtrl.setValue(response.bezeichnung);
                    }
                    this.aufstalleinheitJahrCtrl.setValue(
                        response.aufstalleinheit_jahr
                    );
                    this.aufstalleinheitNummerCtrl.setValue(
                        response.aufstalleinheit_nummer
                    );
                }
            });
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        const gruppe = new Gruppe();
        // const fd = new FormData();
        //
        // fd.append('kunde_id', this.input?.kunde?.kunde_id?.toString());
        // fd.append('stall', this.standort);
        // fd.append('bezeichnung', this.gruppennameCtrl.value);
        // fd.append('aufstalleinheit_jahr', this.aufstalleinheitJahrCtrl.value);
        // fd.append(
        //     'aufstalleinheit_nummer',
        //     this.aufstalleinheitNummerCtrl.value
        // );
        // fd.append('sgs', this.sgsMeldenCtrl.value ? '1' : '0');
        // fd.append('sgs_nummer', this.sgsNummerCtrl.value);
        // fd.append('produktionsabschnitt_id', this.produktionsabschnittCtrl.value?.produktionsabschnitt_id?.toString());
        //
        // if (!this.lss.kostenpflichtigAktiv() && !this.input?.einstalldatumAusblenden) {
        //     const einstalldatum_von: Date = this.einstalldatumVonCtrl.value;
        //     fd.append('einstalldatum_von', einstalldatum_von?.toISOString());
        //     const einstalldatum_bis: Date = this.einstalldatumBisCtrl.value;
        //     fd.append('einstalldatum_bis', einstalldatum_bis?.toISOString());
        // }
        //
        // if (this.input?.kunde?.schwein === 1) {
        //     fd.append('anzahl_tiere', this.anzahlTiereCtrl.value);
        //     fd.append('alter_bei_einstallung', this.alterEinstallungCtrl.value);
        // }

        // fd.append('lieferant_vvvo', this.lieferantenVVVOCtrl.value);
        // if (this.csvFile) {
        //     fd.append('dokument', this.csvFile, this.fileName);
        // }

        // const options = {
        //     headers: new HttpHeaders('Phito-Type: fileupload'),
        //     reportProgress: true,
        // };

        gruppe.sgs = this.sgsMeldenCtrl.value ? 1 : 0;
        gruppe.kunde_id = this.input?.kunde?.kunde_id;
        gruppe.produktionsabschnitt_id = this.produktionsabschnittCtrl.value?.produktionsabschnitt_id;
        gruppe.stall = this.standort;
        gruppe.sgs_nummer = this.sgsNummerCtrl.value;
        gruppe.bezeichnung = this.gruppennameCtrl.value;
        gruppe.aufstalleinheit_jahr = this.aufstalleinheitJahrCtrl.value;
        gruppe.aufstalleinheit_nummer =
            this.aufstalleinheitNummerCtrl.value;

        gruppe.einstalldatum_von = this.einstalldatumVonCtrl.value;
        gruppe.einstalldatum_bis = this.einstalldatumBisCtrl.value;
        if (this.input?.kunde?.schwein === 1) {
            gruppe.alter_bei_einstallung = this.alterEinstallungCtrl.value;
            gruppe.anzahl_tiere = this.anzahlTiereCtrl.value;
        }


        this.appService.setDataLoading(true);
        this.api
            .post(this.apiBaseUrl + 'gruppe/create.php', gruppe)
            .subscribe({
                next: (response) => {
                    const gruppe = <Gruppe>response;
                    this.messageService.infoMessage(
                        'Die Gruppe wurde erfolgreich angelegt!'
                    );
                    this.appService.setDataLoading(false);
                    this.dialogRef.close(gruppe);
                },
                error: (error) => {
                    this.appService.setDataLoading(false);
                    this.messageService.errorMessage(
                        'Die Grupe konnte nicht angelegt werden!',
                        error
                    );
                },
            });
    }

    produktionsabschnittComparator(a: Produktionsabschnitt, b: Produktionsabschnitt): boolean {
        return a?.produktionsabschnitt_id === b?.produktionsabschnitt_id;
    }

    ladeProduktionsabschnitte() {
        if (this.input?.kunde?.betriebsart_id) {
            this.produktionsabschnittSub?.unsubscribe();
            this.produktionsabschnittSub = this.api.getByIdAlternative(this.apiBaseUrl + 'betriebsart/read_one.php', 'betriebsart_id', this.input?.kunde?.betriebsart_id?.toString()).subscribe({
                next: (betriebsart: Betriebsart) => {
                    this.produktionsabschnitte = betriebsart.produktionsabschnitte;
                    if (this.produktionsabschnitte?.length === 1) {
                        this.produktionsabschnittCtrl.setValue(this.produktionsabschnitte[0]);
                    }
                },
                error: err => {
                    this.messageService.errorMessage('Produktionsabschnitte konnten nicht geladen werden!', err);
                }
            });
        }
    }

    isFormValid() {
        // if (!this.lss.kostenpflichtigAktiv()) {
        //     if (!this.einstalldatumVonCtrl.value || !this.einstalldatumBisCtrl.value) {
        //         return false;
        //     }
        // }
        return true;
    }

    private checkSgsMelden() {
        this.sgsMeldenCtrl.disable();
        this.sgsEinblenden = false;
        this.sgsNummerEinblenden = false;
        if (this.input?.kunde) {
            if (this.input?.kunde?.kaelbermast) {
                this.sgsMeldenCtrl.setValue(true);
                this.sgsNummerEinblenden = true;
            } else {
                if (this.input?.kunde?.sgs_nummer) {
                    this.sgsMeldenCtrl.setValue(true);
                    this.sgsMeldenCtrl.enable();
                    this.sgsEinblenden = true;
                    this.sgsNummerEinblenden = true;
                } else {
                    this.sgsMeldenCtrl.setValue(false);
                }
            }
        }
    }
}
