import { Component, Inject } from '@angular/core';
import { Tierarzt } from '../../../../../../projects/kiene-core/src/lib/components/tieraerzte/classes/tierarzt';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { API_BASE_URL_SERVICE } from '../../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { KieneTableService } from '../../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../../services/message.service';
import { TierwohlprotokollSchwein } from '../classes/tierwohlprotokoll-schwein';
import {
    LocalStorageService
} from '../../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { Client } from '../../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'kiene-tierwohlprotokoll-schwein-details',
    templateUrl: './tierwohlprotokoll-schwein-details.component.html',
    styleUrls: ['./tierwohlprotokoll-schwein-details.component.scss'],
})
export class TierwohlprotokollSchweinDetailsComponent {
    tierwohlprotokollSchwein: TierwohlprotokollSchwein;
    selectedKunde: Client;
    selectedTierarzt: Tierarzt;
    userIstTierarzt = false;
    abrechnenCtrl = new FormControl(true);

    constructor(
        public dialogRef: MatDialogRef<TierwohlprotokollSchweinDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        private api: KieneTableService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService
    ) {
        if (data?.tierwohlprotokollSchwein) {
            this.tierwohlprotokollSchwein = data?.tierwohlprotokollSchwein;
            this.generateObjsForAutocompletes();
        } else {
            this.tierwohlprotokollSchwein = new TierwohlprotokollSchwein();
        }

        if (this.tierwohlprotokollSchwein.abrechnen === 0) {
            this.abrechnenCtrl.setValue(false);
        } else if (this.tierwohlprotokollSchwein.abrechnen === 1) {
            this.abrechnenCtrl.setValue(true);
        }
        if (this.tierwohlprotokollSchwein.abgabebeleg_id) {
            this.abrechnenCtrl.disable();
        }

        if (!this.tierwohlprotokollSchwein.datum) {
            this.tierwohlprotokollSchwein.datum = new Date();
        }

        if (localStorageService.getCurrentClient()) {
            this.selectedKunde = localStorageService.getCurrentClient();
            this.setKunde(this.selectedKunde);
        }

        this.userIstTierarzt = localStorageService.isCurrentUserVeterinarian();
        if (this.userIstTierarzt) {
            const session = localStorageService.getCurrentUser();
            this.selectedTierarzt = new Tierarzt();
            this.selectedTierarzt.tierarzt_id = session.tierarzt_id;
            this.selectedTierarzt.vorname = session.vorname;
            this.selectedTierarzt.nachname = session.nachname;
            this.setTierarzt(this.selectedTierarzt);
        }
    }

    save() {
        this.tierwohlprotokollSchwein.abrechnen = this.abrechnenCtrl.value ? 1 : 0;
        if (this.tierwohlprotokollSchwein.twp_schwein_id) {
            this.api
                .updateEntity(
                    this.apiBaseUrl + 'tierwohlprotokoll_schwein/update.php',
                    this.tierwohlprotokollSchwein
                )
                .subscribe({
                    next: (value) => {
                        this.messageService.infoMessage(
                            'Tierwohlprotokoll wurde erfolgreich aktualisiert.'
                        );
                        this.tierwohlprotokollSchwein = value;
                        this.dialogRef.close(this.tierwohlprotokollSchwein);
                    },
                    error: (error) => {
                        this.messageService.errorMessage(
                            'Fehler beim Aktualisieren des Tierwohlprotokolls!',
                            error
                        );
                    },
                });
        } else {
            this.api
                .post(
                    this.apiBaseUrl + 'tierwohlprotokoll_schwein/create.php',
                    this.tierwohlprotokollSchwein
                )
                .subscribe({
                    next: (value) => {
                        this.messageService.infoMessage(
                            'Tierwohlprotokoll wurde erfolgreich erstellt.'
                        );
                        this.tierwohlprotokollSchwein = value;
                        this.dialogRef.close(this.tierwohlprotokollSchwein);
                    },
                    error: (error) => {
                        this.messageService.errorMessage(
                            'Fehler beim Erstellen des Tierwohlprotokolls!',
                            error
                        );
                    },
                });
        }
    }

    setKunde(c: Client) {
        this.tierwohlprotokollSchwein.kunde_id = c.kunde_id;
        this.tierwohlprotokollSchwein.kunde = c.bezeichnung_final;
        this.tierwohlprotokollSchwein.kunde_vvvo = c.vvvo;
        this.tierwohlprotokollSchwein.kunde_strasse = c.strasse_final;
        this.tierwohlprotokollSchwein.kunde_plz = c.plz_final;
        this.tierwohlprotokollSchwein.kunde_ort = c.ort_final;
    }

    setTierarzt(t: Tierarzt) {
        this.tierwohlprotokollSchwein.tierarzt_id = t.tierarzt_id;
        this.tierwohlprotokollSchwein.tierarzt_vorname = t.vorname;
        this.tierwohlprotokollSchwein.tierarzt_nachname = t.nachname;
    }

    private generateObjsForAutocompletes() {
        if (this.tierwohlprotokollSchwein?.kunde_id) {
            this.loadKunde(this.tierwohlprotokollSchwein?.kunde_id).subscribe({
                next: (value) => {
                    this.selectedKunde = value;
                },
                error: () => {
                    this.selectedKunde = new Client();
                    this.selectedKunde.kunde_id =
                        this.tierwohlprotokollSchwein.kunde_id;
                    this.selectedKunde.bezeichnung_final =
                        this.tierwohlprotokollSchwein.kunde;
                    this.selectedKunde.vvvo =
                        this.tierwohlprotokollSchwein.kunde_vvvo;
                    this.selectedKunde.strasse_final =
                        this.tierwohlprotokollSchwein.kunde_strasse;
                    this.selectedKunde.plz_final =
                        this.tierwohlprotokollSchwein.kunde_plz;
                    this.selectedKunde.ort_final =
                        this.tierwohlprotokollSchwein.kunde_ort;
                },
            });
        }

        if (this.tierwohlprotokollSchwein?.tierarzt_id) {
            this.loadTierarzt(
                this.tierwohlprotokollSchwein.tierarzt_id
            ).subscribe({
                next: (value) => {
                    this.selectedTierarzt = value;
                },
                error: () => {
                    this.selectedTierarzt = new Tierarzt();
                    this.selectedTierarzt.tierarzt_id =
                        this.tierwohlprotokollSchwein.tierarzt_id;
                    this.selectedTierarzt.vorname =
                        this.tierwohlprotokollSchwein.tierarzt_vorname;
                    this.selectedTierarzt.nachname =
                        this.tierwohlprotokollSchwein.tierarzt_nachname;
                },
            });
        }
    }

    private loadKunde(kunde_id: number) {
        return this.api.getOne(
            this.apiBaseUrl + 'kunde/read_one.php',
            'kunde_id',
            kunde_id.toString()
        );
    }

    private loadTierarzt(tierarzt_id: number) {
        return this.api.getOne(
            this.apiBaseUrl + 'tierarzt/read_one.php',
            'tierarzt_id',
            tierarzt_id.toString()
        );
    }
}
