import {Component, OnInit, ViewChild} from '@angular/core';
import {ArtikelAdmin, Material, Wirkstoff} from '../material';
import {ActivatedRoute, Router} from '@angular/router';
import {MaterialService} from '../material.service';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {Unit} from '../unit';
import {Observable} from 'rxjs';
import {Supplier} from '../../supplier/supplier';
import {MessageService} from '../../../services/message.service';
import {Warengruppe} from '../../warengruppe/warengruppe';
import {WarengruppeService} from '../../warengruppe/warengruppe.service';
import {Untersuchungsgruppe} from '../../../laboratory/untersuchungsgruppe/untersuchungsgruppe';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {environment} from '../../../../environments/environment';
import {HttpParams} from '@angular/common/http';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MatDialog} from '@angular/material/dialog';
import {SupplierMaterial} from '../../supplier-material/supplier-material';
import {SaveForAllDialogComponent} from '../../../dialogs/save-for-all-dialog/save-for-all-dialog.component';
import {KieneAutocompleteComponent} from '../../../components/kiene-autocomplete/kiene-autocomplete.component';
import {Mandant} from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'kiene-material-details',
    templateUrl: './material-details.component.html',
    styleUrls: ['./material-details.component.scss'],
})
export class MaterialDetailsComponent implements OnInit {
    @ViewChild('wirkstoffAutocomplete')
    wirkstoffAutocomplete: KieneAutocompleteComponent;
    availableMandanten: Mandant[] = [];
    material: Material = new Material();
    supplier: Observable<Supplier[]>;
    units: Unit[] = [];
    urlId: string;
    selectedTab = 0;
    ustValues = [5, 7, 16, 19];
    stuecklistenEinkaufspreis = 0;
    warengruppen: Warengruppe[];
    purchasePrice = 0;
    discount = 0;
    bezeichnungCtrl: UntypedFormControl = new UntypedFormControl();
    einheitCtrl: UntypedFormControl = new UntypedFormControl();
    kuerzelCtrl: UntypedFormControl = new UntypedFormControl();
    eanCtrl: UntypedFormControl = new UntypedFormControl();
    produktkennzeichnungCtrl = new FormControl();
    interneNrCtrl: UntypedFormControl = new UntypedFormControl();
    mengeCtrl: UntypedFormControl = new UntypedFormControl();
    mindestbestandCtrl: UntypedFormControl = new UntypedFormControl();
    bestellmengeCtrl: UntypedFormControl = new UntypedFormControl();
    behandlungsartikelCtrl: UntypedFormControl = new UntypedFormControl();
    gesperrtCtrl = new UntypedFormControl();
    antibiotikumCtrl = new UntypedFormControl();
    impstoffCtrl: UntypedFormControl = new UntypedFormControl();
    bestellvorschlagCtrl: UntypedFormControl = new UntypedFormControl();
    lagereinheitCtrl = new UntypedFormControl();
    secureStockCtrl = new UntypedFormControl();
    variableSecureStockCtrl = new UntypedFormControl();
    umrechnungFaktCtrl = new UntypedFormControl();
    gebindegroesseCtrl = new UntypedFormControl();
    vatCtrl = new UntypedFormControl();
    ermittlungRohertragCtrl = new UntypedFormControl();
    collectionMaterialCtrl = new UntypedFormControl();
    vetSortCtrl = new UntypedFormControl();
    mischung1AmountCtrl = new UntypedFormControl();
    bestellmengeKonstantCtrl = new UntypedFormControl();
    abgabeCtrl = new UntypedFormControl();
    laborartikelCtrl = new UntypedFormControl();
    mengenartikelCtrl = new UntypedFormControl();
    chargeJaNeinCtrl = new UntypedFormControl();
    sonstigeAbgabeCtrl = new UntypedFormControl();
    betaeubungsmittelCtrl = new UntypedFormControl();
    nichtAbgebbarCtrl = new UntypedFormControl();
    provisionsfaehigCtrl = new UntypedFormControl();
    provisionCtrl = new UntypedFormControl();
    tourenplanCtrl = new UntypedFormControl();
    tourenplanTageCtrl = new UntypedFormControl();
    rabattfaehigCtrl = new UntypedFormControl();
    bonusfaehigCtrl = new UntypedFormControl();
    skontofaehigCtrl = new UntypedFormControl();
    bestellwesenCtrl = new UntypedFormControl();
    keinNegativBestandCtrl = new UntypedFormControl();
    lagerbestaendeVerbuchenCtrl = new UntypedFormControl();
    keinLiefervorschlagCtrl = new UntypedFormControl();
    keineBarcodeErfassungCtrl = new UntypedFormControl();
    sammelartikelCtrl = new UntypedFormControl();
    interneVerrechnungCtrl = new UntypedFormControl();
    bgaCtrl = new UntypedFormControl();
    tskCtrl = new UntypedFormControl();
    tskAbkuerzungCtrl = new UntypedFormControl();
    tskPreisCtrl = new UntypedFormControl();
    impftextCtrl = new UntypedFormControl();
    warengruppeCtrl = new UntypedFormControl();
    anzahlUntersuchungenCtrl = new UntypedFormControl();
    artikelmengeAusblendenCtrl = new UntypedFormControl();
    inAbgangslisteCtrl = new UntypedFormControl();
    honorarCtrl = new UntypedFormControl();
    primanotaIgnorierenCtrl = new UntypedFormControl();
    stuecklisteCtrl = new UntypedFormControl();
    buchungskontoCtrl = new UntypedFormControl();
    buchungsgegenkontoCtrl = new UntypedFormControl();
    idexxCtrl = new UntypedFormControl();
    idexxMessgroesseCtrl = new UntypedFormControl();
    idexxTestbeschreibungCtrl = new UntypedFormControl();
    idexxErgebnisbeschreibungCtrl = new UntypedFormControl();
    anwendungsdauerFixCtrl = new UntypedFormControl();
    dosierungFixCtrl = new UntypedFormControl();
    massnahmenplanRindCtrl = new UntypedFormControl();
    massnahmenplanSchweinCtrl = new UntypedFormControl();
    tierwohlprotokollRindCtrl = new UntypedFormControl();
    tierwohlprotokollSchweinCtrl = new UntypedFormControl();
    bestandsbesuchsprotokollCtrl = new UntypedFormControl();
    labor: any;
    untersuchungsgruppe: any;
    isCreationForm = false;
    eingangsnummerCtrl = new FormControl();
    packungsIdCtrl = new FormControl();
    updCtrl = new FormControl();
    massnahmenplanRindKurzCtrl = new FormControl<boolean>(undefined);

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private warengruppeService: WarengruppeService,
        private api: MaterialService,
        private tableService: KieneTableService,
        public dialog: MatDialog,
        private localStorageService: LocalStorageService,
        private messageService: MessageService
    ) {
        this.urlId = this.route.snapshot.paramMap.get('id');

    }

    ngOnInit() {
        if (this.urlId !== 'neu') {
            this.loadMaterial(Number.parseInt(this.urlId, 10));
        } else {
            this.isCreationForm = true;
            this.loadMandanten();
        }

        this.loadUnits();
        this.loadWarengruppen();

        this.route.queryParams.subscribe((params) => {
            this.selectedTab = params['tab'];
        });
    }

    loadMandanten() {
        if (this.localStorageService.currentUserHasPermission(1005)) {
            let params = new HttpParams();
            if (!this.isCreationForm) {
                params = params.append(
                    'artikel_id',
                    this.material.artikel_id.toString()
                );
            }
            this.tableService
                .get(
                    environment.BASE_URI + 'artikel/read_mandanten.php',
                    params
                )
                .subscribe(
                    (response) => {
                        this.availableMandanten = response.records;
                    },
                    (error) => {
                        this.messageService.errorMessage(
                            'Fehler beim Laden der verfügbaren Mandanten',
                            error
                        );
                    }
                );
        }
    }

    goBack() {
        window.history.go(-1);
    }

    loadMaterial(id: number) {
        this.api.getOne(id).subscribe(
            (material) => {
                this.material = material;
                this.loadMandanten();
                this.loadArtikelAdmin();
                this.intiFormControls();
            },
            (error) => {
                this.messageService.errorMessage(
                    'Artikel konnte nicht geladen werden!',
                    error
                );
            }
        );
    }

    loadUnits() {
        this.api.getUnits().subscribe((response) => {
            this.units = response.records;
        });
    }

    setCurrentStandardSupplierMaterial(sm: SupplierMaterial) {
        if (sm.rabatt) {
            this.purchasePrice = (sm.einzelpreis * (100 - sm.rabatt)) / 100;
        } else {
            this.purchasePrice = sm.einzelpreis;
        }
    }

    intiFormControls() {
        this.bezeichnungCtrl.setValue(this.material.bezeichnung);

        this.bezeichnungCtrl.setValidators([Validators.required]);

        this.einheitCtrl.setValue(this.material.einheit_id);

        this.kuerzelCtrl.setValue(this.material.kuerzel);

        this.eanCtrl.setValue(this.material.ean);
        this.produktkennzeichnungCtrl.setValue(this.material.produktkennzeichnungen);

        this.interneNrCtrl.setValue(this.material.artikel_nr_intern);

        this.mengeCtrl.setValue(this.material.menge);

        this.mindestbestandCtrl.setValue(this.material.mindestbestand);

        this.bestellmengeCtrl.setValue(this.material.bestellmenge);

        this.behandlungsartikelCtrl.setValue(
            this.material.behandlung === 1 ? true : false
        );

        this.gesperrtCtrl.setValue(this.material.gesperrt === 1 ? true : false);

        this.antibiotikumCtrl.setValue(
            this.material.antibiotikum === 1 ? true : false
        );

        this.impstoffCtrl.setValue(this.material.impfung === 1 ? true : false);

        this.bestellvorschlagCtrl.setValue(
            this.material.bestellvorschlag === 1 ? true : false
        );

        this.lagereinheitCtrl.setValue(this.material.einheit_alternativ);

        this.secureStockCtrl.setValue(this.material.sicherheitsbestand);

        this.variableSecureStockCtrl.setValue(
            this.material.sicherheitsbestand_variabel === 1 ? true : false
        );

        this.bestellmengeKonstantCtrl.setValue(
            this.material.bestellmenge_konstant
        );

        this.umrechnungFaktCtrl.setValue(this.material.umrechnungsfaktor);

        this.gebindegroesseCtrl.setValue(this.material.gebindegroesse);

        this.ermittlungRohertragCtrl.setValue(
            this.material.ermittlung_rohertrag
        );

        this.abgabeCtrl.setValue(this.material.abgabe === 1 ? true : false);

        this.laborartikelCtrl.setValue(
            this.material.laborartikel === 1 ? true : false
        );

        this.mengenartikelCtrl.setValue(
            this.material.mengenartikel === 1 ? true : false
        );

        this.chargeJaNeinCtrl.setValue(
            this.material.charge === 1 ? true : false
        );

        this.sonstigeAbgabeCtrl.setValue(
            this.material.sonstige_abgabe === 1 ? true : false
        );

        this.betaeubungsmittelCtrl.setValue(
            this.material.betaeubungsmittel === 1 ? true : false
        );

        this.nichtAbgebbarCtrl.setValue(
            this.material.nicht_abgebbar === 1 ? true : false
        );

        this.provisionsfaehigCtrl.setValue(
            this.material.provisionsfaehig === 1 ? true : false
        );

        this.anwendungsdauerFixCtrl.setValue(
            this.material.anwendungsdauer_fix === 1 ? true : false
        );
        this.dosierungFixCtrl.setValue(
            this.material.dosierung_fix === 1 ? true : false
        );
        this.massnahmenplanRindCtrl.setValue(
            this.material.massnahmenplan_rind > 0 ? true : false
        );
        this.massnahmenplanRindKurzCtrl.setValue(
            this.material.massnahmenplan_rind_kurz > 0 ? true : false
        );
        this.massnahmenplanSchweinCtrl.setValue(
            this.material.massnahmenplan_schwein > 0 ? true : false
        );
        this.bestandsbesuchsprotokollCtrl.setValue(
            this.material.bestandsbesuchsprotokoll > 0 ? true : false
        );
        this.tierwohlprotokollRindCtrl.setValue(
            this.material.tierwohlprotokoll_rind > 0 ? true : false
        );
        this.tierwohlprotokollSchweinCtrl.setValue(
            this.material.tierwohlprotokoll_schwein > 0 ? true : false
        );

        this.provisionCtrl.setValue(this.material.provision);
        if (!this.provisionsfaehigCtrl.value) {
            this.provisionCtrl.disable();
        }

        this.provisionsfaehigCtrl.valueChanges.subscribe({
            next: (value) => {
                if (value) {
                    this.provisionCtrl.enable();
                } else {
                    this.provisionCtrl.disable();
                }
            },
        });

        this.idexxCtrl.valueChanges.subscribe({
            next: (value) => {
                if (value) {
                    this.idexxMessgroesseCtrl.enable();
                    this.idexxTestbeschreibungCtrl.enable();
                    this.idexxErgebnisbeschreibungCtrl.enable();
                } else {
                    this.idexxMessgroesseCtrl.disable();
                    this.idexxTestbeschreibungCtrl.disable();
                    this.idexxErgebnisbeschreibungCtrl.disable();
                }
            },
        });

        this.tourenplanCtrl.setValue(
            this.material.tourenplan === 1 ? true : false
        );

        this.tourenplanTageCtrl.setValue(this.material.tourenplan_tage);
        if (!this.tourenplanCtrl.value) {
            this.tourenplanTageCtrl.disable();
        }

        this.tourenplanCtrl.valueChanges.subscribe({
            next: (value) => {
                if (value) {
                    this.tourenplanTageCtrl.enable();
                } else {
                    this.tourenplanTageCtrl.disable();
                }
            },
        });

        this.rabattfaehigCtrl.setValue(
            this.material.rabattfaehig === 1 ? true : false
        );

        this.bonusfaehigCtrl.setValue(
            this.material.bonusfaehig === 1 ? true : false
        );

        this.skontofaehigCtrl.setValue(
            this.material.skontofaehig === 1 ? true : false
        );

        this.bestellwesenCtrl.setValue(
            this.material.bestellwesen === 1 ? true : false
        );

        this.keinNegativBestandCtrl.setValue(
            this.material.kein_negativbestand === 1 ? true : false
        );

        this.lagerbestaendeVerbuchenCtrl.setValue(
            this.material.lagerbestaende_verbuchen === 1 ? true : false
        );

        this.keinLiefervorschlagCtrl.setValue(
            this.material.kein_liefervorschlag === 1 ? true : false
        );

        this.keineBarcodeErfassungCtrl.setValue(
            this.material.keine_barcodeerfassung === 1 ? true : false
        );

        this.sammelartikelCtrl.setValue(
            this.material.sammelartikel === 1 ? true : false
        );

        this.interneVerrechnungCtrl.setValue(
            this.material.interne_verrechnung === 1 ? true : false
        );

        this.artikelmengeAusblendenCtrl.setValue(
            this.material.artikelmenge_ausblenden === 1 ? true : false
        );

        this.inAbgangslisteCtrl.setValue(
            this.material.in_abgangsliste > 0 ? true : false
        );

        this.honorarCtrl.setValue(this.material.honorar > 0 ? true : false);

        this.primanotaIgnorierenCtrl.setValue(this.material.primanota_ignorieren > 0 ? true : false);

        this.vetSortCtrl.setValue(this.material.vet_sort);

        this.bgaCtrl.setValue(this.material.bga);

        this.tskCtrl.setValue(this.material.tsk === 1 ? true : false);

        this.tskAbkuerzungCtrl.setValue(this.material.tsk_abkuerzung);

        this.tskPreisCtrl.setValue(this.material.tsk_preis);

        this.impftextCtrl.setValue(this.material.impftext);


        this.anzahlUntersuchungenCtrl.setValue(
            this.material.untersuchungen_anzahl
        );

        this.labor = {
            lieferant_id: this.material.lieferant_id_labor,
            bezeichnung: this.material.lieferant_labor,
        };

        this.untersuchungsgruppe = {
            untersuchungsgruppe_id: this.material.untersuchungsgruppe_id,
            bezeichnung: this.material.untersuchungsgruppe,
        };

        this.stuecklisteCtrl.setValue(
            this.material.stueckliste === 1 ? true : false
        );

        this.idexxCtrl.setValue(this.material.idexx === 1);
        this.idexxMessgroesseCtrl.setValue(this.material.idexx_messgroesse);
        this.idexxTestbeschreibungCtrl.setValue(
            this.material.idexx_pdf_beschreibung
        );
        this.idexxErgebnisbeschreibungCtrl.setValue(
            this.material.idexx_pdf_tabellen_text
        );

        const warengruppe = new Warengruppe();
        warengruppe.warengruppe_id = this.material.warengruppe_id;
        warengruppe.bezeichnung = this.material.warengruppe;
        this.warengruppeCtrl.setValue(warengruppe);

        this.packungsIdCtrl.setValue(this.material.packungs_id);
        this.eingangsnummerCtrl.setValue(this.material.eingangsnummer);
        this.updCtrl.setValue(this.material.upd);
    }

    loadArtikelAdmin() {
        if (this.localStorageService.currentUserHasPermission(3211)) {
            this.api.loadAdminMaterial(this.material.artikel_id).subscribe(
                (response) => {
                    this.vatCtrl.setValue(response.ust);
                    this.buchungsgegenkontoCtrl.setValue(
                        response.buchungsgegenkonto
                    );
                    this.buchungskontoCtrl.setValue(response.buchungskonto);
                    this.stuecklistenEinkaufspreis =
                        response.stueckliste_einkaufspreis;
                    if (this.stuecklistenEinkaufspreis) {
                        this.purchasePrice = this.stuecklistenEinkaufspreis;
                    }
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Admin Daten konnten nicht geladen werden',
                        error
                    );
                }
            );
        }
    }

    loadWarengruppen() {
        this.warengruppeService.getAll().subscribe((response) => {
            this.warengruppen = response.records;
        });
    }

    warengruppeComparator(one: Warengruppe, two: Warengruppe) {
        if (one === null || two === null) {
            return false;
        }

        return one.warengruppe_id === two.warengruppe_id;
    }

    saveFinanzen() {
        const vat = this.vatCtrl.value;
        const aa = new ArtikelAdmin();
        aa.ust = vat;
        aa.artikel_id = this.material.artikel_id;

        aa.buchungskonto = this.buchungskontoCtrl.value;
        aa.buchungsgegenkonto = this.buchungsgegenkontoCtrl.value;

        if (this.availableMandanten.length > 1) {
            // Array enthält nur Daten mit entspr. Berechtigung und bei Kiene
            const dialogRef = this.dialog.open(SaveForAllDialogComponent, {
                data: {
                    availableMandanten: this.availableMandanten,
                },
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    aa.mandanten_ids = result;
                    this.saveAdminMaterial(aa);
                }
            });
        } else {
            this.saveAdminMaterial(aa);
        }
    }

    saveAdminMaterial(aa: ArtikelAdmin) {
        this.api.saveAdminMaterial(aa).subscribe(
            () => {
                this.messageService.infoMessage(
                    'Die Daten wurden erfolgreich gespeichert!'
                );
            },
            (error) => {
                this.messageService.errorMessage(
                    'Beim Speichern ist ein Fehler aufgetreten! ',
                    error
                );
            }
        );
    }

    updateAllgemein() {
        this.saveWithMandantDialog('artikel/update_allgemein.php');
    }

    updateLager() {
        this.save('artikel/update_lager.php');
    }

    updateEinstellungen() {
        this.saveWithMandantDialog('artikel/update_einstellungen.php');
    }

    updateVet() {
        this.saveWithMandantDialog('artikel/update_vet.php');
    }

    updateUntersuchung() {
        if (this.localStorageService.hasPermission(1007)) {
            this.save('artikel/update_untersuchung.php');
        }
    }

    saveWithMandantDialog(apiUrl: string) {
        if (this.availableMandanten.length > 1) {
            // Array enthält nur Daten mit entspr. Berechtigung und bei Kiene
            const dialogRef = this.dialog.open(SaveForAllDialogComponent, {
                data: {
                    availableMandanten: this.availableMandanten,
                },
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.material.mandanten_ids = result;
                    this.save(apiUrl);
                }
            });
        } else {
            this.save(apiUrl);
        }
    }

    save(apiUrl: string) {
        this.material.bezeichnung = this.bezeichnungCtrl.value;
        this.material.einheit_id = this.einheitCtrl.value;
        this.material.kuerzel = this.kuerzelCtrl.value;
        this.material.ean = this.eanCtrl.value;
        this.material.produktkennzeichnungen = this.produktkennzeichnungCtrl.value;
        this.material.artikel_nr_intern = this.interneNrCtrl.value;
        this.material.menge = this.mengeCtrl.value;
        this.material.mindestbestand = this.mindestbestandCtrl.value;
        this.material.bestellmenge = this.bestellmengeCtrl.value;

        this.material.behandlung = this.behandlungsartikelCtrl.value ? 1 : 0;

        this.material.antibiotikum = this.antibiotikumCtrl.value ? 1 : 0;
        this.material.impfung = this.impstoffCtrl.value ? 1 : 0;
        this.material.bestellvorschlag = this.bestellvorschlagCtrl.value
            ? 1
            : 0;
        this.material.einheit_alternativ = this.lagereinheitCtrl.value;
        this.material.sicherheitsbestand = this.secureStockCtrl.value;
        this.material.sicherheitsbestand_variabel = this.variableSecureStockCtrl
            .value
            ? 1
            : 0;
        this.material.bestellmenge_konstant =
            this.bestellmengeKonstantCtrl.value;
        this.material.umrechnungsfaktor = this.umrechnungFaktCtrl.value;
        this.material.gebindegroesse = this.gebindegroesseCtrl.value;
        this.material.ermittlung_rohertrag = this.ermittlungRohertragCtrl.value;
        this.material.abgabe = this.abgabeCtrl.value ? 1 : 0;
        this.material.laborartikel = this.laborartikelCtrl.value ? 1 : 0;
        this.material.mengenartikel = this.mengenartikelCtrl.value ? 1 : 0;
        this.material.charge = this.chargeJaNeinCtrl.value ? 1 : 0;
        this.material.sonstige_abgabe = this.sonstigeAbgabeCtrl.value ? 1 : 0;
        this.material.betaeubungsmittel = this.betaeubungsmittelCtrl.value
            ? 1
            : 0;
        this.material.nicht_abgebbar = this.nichtAbgebbarCtrl.value ? 1 : 0;
        this.material.provisionsfaehig = this.provisionsfaehigCtrl.value
            ? 1
            : 0;
        this.material.provision = this.provisionCtrl.value;
        this.material.tourenplan = this.tourenplanCtrl.value ? 1 : 0;
        this.material.tourenplan_tage = this.tourenplanTageCtrl.value;
        this.material.rabattfaehig = this.rabattfaehigCtrl.value ? 1 : 0;
        this.material.bonusfaehig = this.bonusfaehigCtrl.value ? 1 : 0;
        this.material.skontofaehig = this.skontofaehigCtrl.value ? 1 : 0;
        this.material.bestellwesen = this.bestellwesenCtrl.value ? 1 : 0;
        this.material.kein_negativbestand = this.keinNegativBestandCtrl.value
            ? 1
            : 0;
        this.material.lagerbestaende_verbuchen = this
            .lagerbestaendeVerbuchenCtrl.value
            ? 1
            : 0;
        this.material.kein_liefervorschlag = this.keinLiefervorschlagCtrl.value
            ? 1
            : 0;
        this.material.keine_barcodeerfassung = this.keineBarcodeErfassungCtrl
            .value
            ? 1
            : 0;
        this.material.sammelartikel = this.sammelartikelCtrl.value ? 1 : 0;
        this.material.interne_verrechnung = this.interneVerrechnungCtrl.value
            ? 1
            : 0;
        this.material.vet_sort = this.vetSortCtrl.value;

        this.material.bga = this.bgaCtrl.value;
        this.material.tsk = this.tskCtrl.value ? 1 : 0;
        this.material.tsk_abkuerzung = this.tskAbkuerzungCtrl.value;
        this.material.tsk_preis = this.tskPreisCtrl.value;
        this.material.impftext = this.impftextCtrl.value;

        this.material.warengruppe_id = this.warengruppeCtrl.value
            ? this.warengruppeCtrl.value.warengruppe_id
            : null;
        this.material.gesperrt = this.gesperrtCtrl.value ? 1 : 0;
        this.material.untersuchungen_anzahl =
            this.anzahlUntersuchungenCtrl.value;
        this.material.stueckliste = this.stuecklisteCtrl.value ? 1 : 0;
        this.material.artikelmenge_ausblenden = this.artikelmengeAusblendenCtrl
            .value
            ? 1
            : 0;
        this.material.in_abgangsliste = this.inAbgangslisteCtrl.value ? 1 : 0;
        this.material.honorar = this.honorarCtrl.value ? 1 : 0;
        this.material.primanota_ignorieren = this.primanotaIgnorierenCtrl.value ? 1 : 0;
        this.material.idexx = this.idexxCtrl.value ? 1 : 0;
        this.material.idexx_messgroesse = this.idexxMessgroesseCtrl.value;
        this.material.idexx_pdf_beschreibung =
            this.idexxTestbeschreibungCtrl.value;
        this.material.idexx_pdf_tabellen_text =
            this.idexxErgebnisbeschreibungCtrl.value;
        this.material.anwendungsdauer_fix = this.anwendungsdauerFixCtrl.value
            ? 1
            : 0;
        this.material.dosierung_fix = this.dosierungFixCtrl.value ? 1 : 0;
        this.material.massnahmenplan_rind = this.massnahmenplanRindCtrl.value ? 1 : 0;
        this.material.massnahmenplan_rind_kurz = this.massnahmenplanRindKurzCtrl.value ? 1 : 0;
        this.material.massnahmenplan_schwein = this.massnahmenplanSchweinCtrl.value ? 1 : 0;
        this.material.tierwohlprotokoll_rind = this.tierwohlprotokollRindCtrl.value ? 1 : 0;
        this.material.tierwohlprotokoll_schwein = this.tierwohlprotokollSchweinCtrl.value ? 1 : 0;
        this.material.bestandsbesuchsprotokoll = this.bestandsbesuchsprotokollCtrl.value ? 1 : 0;

        if (
            this.material.idexx &&
            (this.material.idexx_messgroesse === null ||
                this.material.idexx_messgroesse === undefined ||
                this.material.idexx_messgroesse?.split(' ').join('') === '')
        ) {
            this.messageService.alertMessage(
                'Bei Option Idexx muss eine Messgröße mit angegeben werden!'
            );
            return;
        }
        this.material.packungs_id = this.packungsIdCtrl.value;
        this.material.eingangsnummer = this.eingangsnummerCtrl.value;
        this.material.upd = this.updCtrl.value;

        this.tableService
            .updateGeneric(environment.BASE_URI + apiUrl, this.material)
            .subscribe(
                (response) => {
                    this.material = response;
                    this.intiFormControls();
                    this.loadArtikelAdmin();
                    this.messageService.infoMessage(
                        'Artikel ' +
                        response.bezeichnung +
                        ' wurde erfolgreich gespeichert!'
                    );
                    this.router.navigate(
                        ['/lager', 'artikel', this.material.artikel_id],
                        {
                            queryParams: {tab: this.selectedTab},
                            queryParamsHandling: 'merge',
                        }
                    );
                    this.isCreationForm = false;
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Artikel ' +
                        this.material.bezeichnung +
                        ' konnte nicht gespeichert werden! Fehler: ',
                        error
                    );
                }
            );
    }

    setSelectedTab(event: MatTabChangeEvent) {
        if (event.index < 6) {
            const id = event.index;
            // this.selectedTab = id;
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {tab: id},
                queryParamsHandling: 'merge',
            });

        }
    }

    setUntersuchungsgruppe(ug: Untersuchungsgruppe) {
        this.material.untersuchungsgruppe_id = ug.untersuchungsgruppe_id;
    }

    setLabor(l: Supplier) {
        this.material.lieferant_id_labor = l.lieferant_id;
    }

    hasPermission(permission: number) {
        return this.localStorageService.currentUserHasPermission(permission);
    }

    hasAtLeastOnePermissionForPrices() {
        return (
            this.localStorageService.currentUserHasPermissions([
                3201, 3211, 3411, 1091, 1099,
            ]) && !this.isCreationForm
        );
    }

    hasPermissionForStueckliste() {
        return (
            this.localStorageService.currentUserHasPermissions([4401]) &&
            !this.isCreationForm
        );
    }

    istBestandsbuch() {
        const mengenartikel = this.mengenartikelCtrl.value;
        const behandlung = this.behandlungsartikelCtrl.value;
        const abgabe = this.abgabeCtrl.value;
        const impfung = this.impstoffCtrl.value;
        const charge = this.chargeJaNeinCtrl.value;

        if (mengenartikel || impfung) {
            return 'check_box_outline_blank';
        }

        if (charge) {
            return 'check_box';
        }

        if (behandlung === abgabe) {
            return 'warning';
        }

        if (!behandlung) {
            return 'check_box';
        }

        return 'check_box_outline_blank';
    }

    istImpfkontrollbuch() {
        const mengenartikel = this.mengenartikelCtrl.value;
        const behandlung = this.behandlungsartikelCtrl.value;
        const abgabe = this.abgabeCtrl.value;
        const impfung = this.impstoffCtrl.value;
        const charge = this.chargeJaNeinCtrl.value;

        if (mengenartikel || !impfung) {
            return 'check_box_outline_blank';
        }

        if (behandlung === abgabe) {
            return 'warning';
        }

        if (!behandlung) {
            return 'check_box';
        }

        return 'check_box_outline_blank';
    }

    berechneArtikelmenge() {
        const behandlung = this.behandlungsartikelCtrl.value;
        const abgabe = this.abgabeCtrl.value;
        const impfung = this.impstoffCtrl.value;
        const charge = this.chargeJaNeinCtrl.value;

        // https://www.wolframalpha.com/input/?i=truth+table+i+%7C%7C+%28b+%26%26+c%29&assumption=%22i%22+-%3E+%22Variable%22

        if (impfung) {
            return 'check_box';
        }

        if (!charge) {
            return 'check_box_outline_blank';
        }

        if (behandlung === abgabe) {
            return 'warning';
        }

        if (behandlung) {
            return 'check_box';
        }

        return 'check_box_outline_blank';
    }

    berechneDosierung() {
        const behandlung = this.behandlungsartikelCtrl.value;
        const abgabe = this.abgabeCtrl.value;
        const impfung = this.impstoffCtrl.value;
        const charge = this.chargeJaNeinCtrl.value;

        // https://www.wolframalpha.com/input/?i=truth+table+%21%28i+%7C%7C+%28b+%26%26+c%29%29&assumption=%22i%22+-%3E+%22Variable%22

        if (impfung) {
            return 'check_box_outline_blank';
        }

        if (behandlung === abgabe) {
            return 'warning';
        }

        if (behandlung && charge) {
            return 'check_box_outline_blank';
        }

        return 'check_box';
    }

    setWirkstoff(value: any) {
        if (typeof value === 'string') {
            this.material.wirkstoff = value;
        } else if (typeof value === 'object') {
            this.material.wirkstoff = value?.wirkstoff;
        }
    }

    deleteWirkstoff(wirkstoff: Wirkstoff) {
        this.tableService
            .deleteEntity(environment.BASE_URI + 'wirkstoff/delete.php', wirkstoff)
            .subscribe({
                    next: () => {
                        this.messageService.infoMessage('Wirkstoff gelöscht!');
                        this.wirkstoffAutocomplete.clearAndFocus();
                    },
                    error: err => {
                        this.messageService.errorMessage(
                            'Wirkstoff konnte nicht gelöscht werden!',
                            err
                        );
                    }
                }
            );
    }
}
