<div style="padding: 12px">
    <h1>Gruppe {{ gruppe?.bezeichnung }} {{ gruppe?.historie > 0 ? ('(Historie)') : '' }}
        - {{ gruppe?.kunde }}
        {{ gruppe?.zusatz ? (' - ' + gruppe?.zusatz) : '' }}</h1>

    <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)" [selectedIndex]="selectedIndex">
        <mat-tab label="Tiere">
            <div *kienePermission="[1912, 9122,9322,9352]" style="margin-top: 24px;">
                <ng-container *ngIf="gruppe?.standard === 0">
                    <button (click)="openGruppeEinstallenDialog()" *kienePermission="[1912, 9122]" color="accent"
                        mat-flat-button>
                        <mat-icon>login</mat-icon>
                        <span>&nbsp;Tiere einstallen</span>
                    </button>
                </ng-container>
                <button (click)="ausstallen()" *kienePermission="[9322]" color="accent" mat-flat-button
                    style="margin-left: 12px;">
                    <mat-icon>logout</mat-icon>
                    <span>&nbsp;(selektierte) Tiere ausstallen</span>
                </button>
                <ng-container *ngIf="gruppe?.standard === 0">
                    <button (click)="standortwechsel()" *kienePermission="[9352]" color="accent" mat-flat-button
                        style="margin-left: 12px;">
                        <mat-icon>swap_horiz</mat-icon>
                        <span>&nbsp;Standort wechseln</span>
                    </button>
                    <ng-container *ngIf="gruppe?.standard === 0">
                        <button (click)="tabWechseln(2)" *kienePermission="[9378,9379]" color="accent" mat-flat-button
                            style="margin-left: 12px;">
                            <mat-icon>scale</mat-icon>
                            <span>&nbsp;Gewichte & Preise einpflegen</span>
                        </button>
                    </ng-container>
                </ng-container>
            </div>

            <kiene-kiene-table #kieneTable (optionClicked)="catchOptionEvent($event)"
                [tableDescriptor]="tableDescriptor">
                <div toolbarElementTop>
                    <div class="one-row">
                        <span class="spacer"></span>

                        <button (click)="selectedRows = kieneTable.getSelectedRows()" *ngIf="!isSGS"
                            [matMenuTriggerFor]="tableMenu" mat-icon-button>
                            <mat-icon>more_vert</mat-icon>
                        </button>

                        <mat-menu #tableMenu="matMenu" class="mat-menu--desktop-gruppe-details" style="min-width: 20vw">
                            <button (click)="eingescanntePaesseVerwalten()" *kienePermission="[9361]" mat-menu-item>
                                <mat-icon color="accent">scanner</mat-icon>
                                <span>Eingescannte P&auml;sse verwalten</span>
                            </button>
                            <mat-divider></mat-divider>
                            <button (click)="zugaengeMelden()" *kienePermission="[9126]" mat-menu-item>
                                <mat-icon color="accent" style="transform: rotateY(180deg)">cloud_sync
                                </mat-icon>
                                Zugänge an HIT melden
                            </button>
                            <button (click)="abgaengeMelden()" *kienePermission="[9126]" mat-menu-item>
                                <mat-icon color="accent" style="transform: rotateY(180deg)">cloud_sync
                                </mat-icon>
                                Abgänge an HIT melden
                            </button>
                            <mat-divider></mat-divider>
                            <ng-container *kienePermission="[9377]">
                                <button (click)="zertifikateAnSGS()" *ngIf="this.gruppe?.sgs > 0" mat-menu-item>
                                    <mat-icon color="accent">biotech</mat-icon>
                                    Zertifizierungsdatei an SGS senden (alle Tiere)
                                </button>
                            </ng-container>
                            <button #menuTrigger="matMenuTrigger" #tierlistenMenuButton="matMenuTrigger"
                                *kienePermission="[9116,9121,1971,9122]" [matMenuTriggerFor]="tierlistenMenu"
                                mat-menu-item>
                                <mat-icon color="accent">summarize</mat-icon>
                                <span>Download / Upload</span>
                            </button>
                            <mat-menu #tierlistenMenu="matMenu">
                                <button (click)="downloadReklamationsPdf()" *kienePermission="[9116]" mat-menu-item>
                                    <mat-icon color="accent">picture_as_pdf</mat-icon>
                                    Reklamationsliste
                                </button>
                                <button (click)="downloadGruppeCsv()" *kienePermission="[9121]" mat-menu-item>
                                    <mat-icon color="accent">table_chart</mat-icon>
                                    CSV-Datei aller Tiere
                                </button>
                                <button (click)="downloadGruppeExcel()" *kienePermission="[9121]" mat-menu-item>
                                    <mat-icon color="accent">table_chart</mat-icon>
                                    Excel-Datei aller Tiere
                                </button>
                                <button (click)="openAbgangslisteDownloadDialog()" *kienePermission="[1971]"
                                    mat-menu-item>
                                    <mat-icon color="accent">download</mat-icon>
                                    Ausstallliste / Lieferschein
                                </button>
                                <button (click)=" uploadTiereCSV()" *kienePermission="[9122]" mat-menu-item>
                                    <mat-icon color="accent">upload</mat-icon>
                                    Tierliste hochladen
                                </button>
                            </mat-menu>
                            <mat-divider></mat-divider>
                            <button #menuTrigger="matMenuTrigger" #selektierteMenuButton="matMenuTrigger"
                                *kienePermission="[9122,9124,9128,9129]" [disabled]="selectedRows?.length === 0"
                                [matMenuTriggerFor]="selektierteMenu" mat-menu-item>
                                <mat-icon color="accent">edit</mat-icon>
                                <span>Aktionen für selektierte Tiere</span>
                            </button>
                            <mat-menu #selektierteMenu="matMenu">
                                <button (click)="alleOhrmarkenGescannt()" *kienePermission="[9122]" mat-menu-item>
                                    <mat-icon color="accent">label</mat-icon>
                                    <span>Ohrmarken als gescannt markieren</span>
                                </button>
                                <button (click)="gruppeAendernTiere()" *kienePermission="[9124]" mat-menu-item>
                                    <mat-icon color="accent">sync_alt</mat-icon>
                                    <span>Gruppe ändern</span>
                                </button>
                                <button (click)="openEinstalldatumAendernDialog(this.selectedRows)"
                                    *kienePermission="[9128]" [disabled]="isAusstalldatumAenderDisabled()"
                                    mat-menu-item>
                                    <mat-icon color="accent">today</mat-icon>
                                    <span>Einstalldatum ändern</span>
                                </button>
                                <button (click)="openAbgangsdatumAendernDialog(this.selectedRows)"
                                    *kienePermission="[9129]" [disabled]="isEinstalldatumAendernDisabled()"
                                    mat-menu-item>
                                    <mat-icon color="accent">event</mat-icon>
                                    <span>Abgangsdatum ändern</span>
                                </button>
                                <button (click)="zertifikateAnSgsEinzeln(this.selectedRows)" *kienePermission="[9377]"
                                    mat-menu-item>
                                    <mat-icon color="accent">biotech</mat-icon>
                                    <span>Zertifizierungsdatei an SGS senden</span>
                                </button>
                            </mat-menu>
                        </mat-menu>

                        <kiene-gruppe-tier-filter-button #filterButton (filterSelected)="setFilterSettings($event)"
                            *ngIf="!isSGS" [gruppe]="gruppe" [tierFilterSettings]="filterSettings">
                        </kiene-gruppe-tier-filter-button>


                        <!--                <button matTooltip="Einstallen" *kienePermission="[9121]" mat-icon-button (click)="openEinstalldatumAendernDialog()">-->
                        <!--                    <mat-icon color="accent">login</mat-icon>-->
                        <!--                </button>-->

                        <!--                this.openEinstalldatumAendernDialog(event.values)-->
                    </div>
                </div>
            </kiene-kiene-table>
        </mat-tab>

        <mat-tab label="Details, Verlauf & Statistiken">

            <div style="margin-top: 24px;">
                <ng-container *ngIf="gruppe?.standard === 0">
                    <button (click)="openGruppeInformationenDialog()" *kienePermission="[1912]" color="accent"
                        mat-flat-button>
                        <mat-icon>edit_note</mat-icon>
                        <span>&nbsp;Informationen hinzufügen</span>
                    </button>
                </ng-container>
            </div>

            <mat-expansion-panel [disabled]="!statistikOpenable()" [expanded]="true" style="margin-top: 24px;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div style="display: inline-flex">
                            <mat-icon>info</mat-icon>
                            <span style="margin-left: 6px; padding-top: 4px">Gruppendetails</span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <table aria-label="gruppen-infos">
                    <tr>
                        <td class="tdlabel">
                            Herdenname:
                        </td>
                        <td class="tdvalue">
                            {{ gruppe?.herdenname || "---" }}
                        </td>
                        <td class="tdlabel">Anzahl Tiere:
                        </td>
                        <td class="tdvalue">{{
                            gruppe?.anzahl_tiere_gesamt || "---"
                            }}
                        </td>
                        <td class="tdlabel">Tiere gescannt:
                        </td>
                        <td class="tdvalue">{{
                            gruppe?.anzahl_tiere_ohrmarken || "---"
                            }} {{
                            getDifferenceString(
                            gruppe?.anzahl_tiere_ohrmarken,
                            gruppe?.anzahl_tiere_gesamt
                            )
                            }}
                        </td>
                        <td class="tdlabel">Abgänge:
                        </td>
                        <td class="tdvalue">{{ gruppe?.anzahl_abgaenge || "---" }}
                            {{
                            getDifferenceString(
                            gruppe?.anzahl_abgaenge,
                            gruppe?.anzahl_tiere_gesamt,
                            {asPercentage: true}
                            )
                            }}
                        </td>
                    </tr>

                    <tr>
                        <td class="tdlabel">Standort:
                        </td>
                        <td class="tdvalue">{{
                            gruppe?.stall || "---"
                            }}
                        </td>
                        <td class="tdlabel">- laut HIT:
                        </td>
                        <td class="tdvalue">{{ gruppe?.anzahl_tiere_hit || "---" }} {{
                            getDifferenceString(
                            gruppe?.anzahl_tiere_hit,
                            gruppe?.anzahl_tiere_gesamt
                            )
                            }}
                        </td>
                        <td class="tdlabel">- davon Tiere ohne Pass:
                        </td>
                        <td class="tdvalue">{{ gruppe?.tiere_ohne_pass || "---" }}
                        </td>
                        <td class="tdlabel">Tote:
                        </td>
                        <td class="tdvalue">{{ gruppe?.anzahl_tote || "---" }} {{
                            getDifferenceString(
                            gruppe?.anzahl_tote,
                            gruppe?.anzahl_tiere_gesamt,
                            {asPercentage: true}
                            )
                            }}
                        </td>
                    </tr>

                    <tr>
                        <td class="tdlabel">Einstallzeitraum:
                        </td>
                        <td class="tdvalue">
                            {{
                            (gruppe?.einstalldatum_von | date : "dd.MM.yy") ||
                            "---"
                            }}
                            -
                            {{
                            (gruppe?.einstalldatum_bis | date : "dd.MM.yy") ||
                            "---"
                            }}
                        </td>
                        <td class="tdlabel">Alter:
                        </td>
                        <td class="tdvalue">
                            {{ gruppe?.alter_wochen || "---" }} Wochen
                        </td>
                        <td class="tdlabel">P&auml;sse ohne Tier:
                        </td>
                        <td class="tdvalue">{{ gruppe?.paesse_ohne_tier || "---" }}
                        </td>
                        <td class="tdlabel">Anzahl Tiere aktuell:
                        </td>
                        <td class="tdvalue">{{
                            gruppe?.anzahl_tiere_aktuell || "---"
                            }}
                        </td>
                    </tr>

                    <tr>
                        <td class="tdlabel">Akt. Produktionsabschnitt:</td>
                        <td class="tdvalue">{{ gruppe?.produktionsabschnitt || '---' }} seit {{
                            (gruppe?.datum_von |
                            date:"dd.MM.yy") || '---'
                            }}
                        </td>
                    </tr>

                    <tr>
                        <td class="tdlabel">Informationen:</td>
                        <td class="tdvalue" colspan="7">{{ gruppe?.informationen || '---' }}</td>
                    </tr>
                </table>
            </mat-expansion-panel>

            <mat-expansion-panel [disabled]="verlaufIsLoading || !verlaufOpenable()" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="one-row">
                            <mat-icon>history</mat-icon>
                            <span style="margin-left: 6px">{{
                                gruppenVerlauf?.length === 0
                                ? "Verlauf - keine Ereignisse vorhanden"
                                : "Verlauf"
                                }}</span>
                            &nbsp;
                            <span>{{
                                gruppenVerlauf && gruppenVerlauf.length > 0
                                ? "(" + gruppenVerlauf?.length + ")"
                                : ""
                                }}</span>
                            <span class="spacer"></span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div>
                    <div *ngFor="let verlaufEintrag of gruppenVerlauf" class="verlauf one-row">
                        <mat-icon>{{ verlaufEintrag.icon }}</mat-icon>
                        <span>{{ verlaufEintrag.datum | date : "dd.MM.YY" }}</span>
                        <span>{{ verlaufEintrag.txt }}</span>
                        <span class="spacer"></span>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [disabled]="!statistikOpenable()" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div style="display: inline-flex">
                            <mat-icon>assessment</mat-icon>
                            <span style="margin-left: 6px; padding-top: 4px">Statistiken ({{
                                gibAnzahlStatistiken()
                                }})</span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div>
                    <table class="info-table">
                        <tr *ngIf="gruppenStatistik?.rassen?.length > 0">
                            <td><strong>Rassen</strong></td>
                            <td></td>
                        </tr>
                        <tr *ngFor="let r of gruppenStatistik?.rassen">
                            <td>{{ r.rasse }}</td>
                            <td>{{ r.anzahl }}x</td>
                        </tr>
                        <tr *ngIf="gruppenStatistik?.geschlechter?.length > 0">
                            <td style="padding-top: 12px">
                                <strong>Anzahl Geschlechter</strong>
                            </td>
                            <td></td>
                        </tr>
                        <tr *ngFor="let g of gruppenStatistik?.geschlechter">
                            <td>{{ g.geschlecht }}</td>
                            <td>{{ g.anzahl }}x</td>
                        </tr>
                        <tr *ngIf="gruppenStatistik?.reklamationen?.length > 0">
                            <td style="padding-top: 12px">
                                <strong>Anzahl Reklamationen</strong>
                            </td>
                            <td></td>
                        </tr>
                        <tr *ngFor="let g of gruppenStatistik?.reklamationen">
                            <td>{{ g.reklamation }}</td>
                            <td>{{ g.anzahl }}x</td>
                        </tr>
                        <tr *ngIf="gruppenStatistik?.abteile?.length > 0">
                            <td style="padding-top: 12px">
                                <strong>Tiere je Abteil</strong>
                            </td>
                            <td></td>
                        </tr>
                        <tr *ngFor="let a of gruppenStatistik?.abteile">
                            <td>{{ a?.abteil }}</td>
                            <td>{{ a?.anzahl }}x</td>
                        </tr>
                    </table>
                </div>
            </mat-expansion-panel>
        </mat-tab>

        <ng-container *ngIf="gruppe?.standard === 0">
            <mat-tab *kienePermission="[9351,9161,9162]" label="Standorte, Gewichte & Preise">
                <div style="width: 100%;">
                    <kiene-gruppe-standort-verwalten [filterSettings]="filterSettings"
                        [gruppe]="gruppe"></kiene-gruppe-standort-verwalten>
                </div>
            </mat-tab>
        </ng-container>

        <mat-tab *kienePermission="[9161,9162]" label="Auswertungen">
            <kiene-gewichte-preise [gruppe_id]="gruppe?.gruppe_id"></kiene-gewichte-preise>
        </mat-tab>
    </mat-tab-group>
</div>