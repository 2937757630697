import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {
    TierFilter
} from '../gruppe-tier-filter-dialog/classes/tier-filter';

@Component({
  selector: 'kiene-gruppe-tier-filter',
  templateUrl: './gruppe-tier-filter.component.html',
  styleUrls: ['./gruppe-tier-filter.component.scss']
})
export class GruppeTierFilterComponent implements OnInit {
    @Input('filter') filter: TierFilter;
    @Output('selectedFilter') selectedFilter: EventEmitter<TierFilter> = new EventEmitter<TierFilter>();
    selectedTierFilterCtrl: UntypedFormControl = new UntypedFormControl(null);
    tierFilter: TierFilter[] = TierFilter.getAvailableFilter();

    constructor() {
        this.selectedTierFilterCtrl.valueChanges.subscribe(data => {
            this.filter = data;
            this.selectedFilter.emit(this.filter);
        });
    }

    ngOnInit(): void {
        this.selectedTierFilterCtrl.setValue(this.filter);
    }

    resetTierFilterCtrl($event: MouseEvent) {
        $event.stopPropagation();
        this.selectedTierFilterCtrl.setValue(undefined);
    }
}
