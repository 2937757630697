<mat-form-field style="width: 100%;">
    <mat-label>Betrieb ausw&auml;hlen</mat-label>

    <mat-select data-cy="vvvo-select" [compareWith]="compareVvvos" [formControl]="vvvoSelectCtrl"
                (selectionChange)="emitSelection($event)">

        <mat-select-trigger class="multiline-mat-option">
            <div>
                {{vvvoSelectCtrl.value?.bezeichnung}}
            </div>
            <div style="font-size: 0.8em; color: #777">{{vvvoSelectCtrl.value?.zusatz}} {{vvvoSelectCtrl.value?.vvvo}}
            </div>
        </mat-select-trigger>

        <mat-option id="option-all" *ngIf="showAllVvvoSelectOption" [value]="alleVVVOs">Alle Betriebe</mat-option>
        <mat-option id="option-{{index}}" class="multiline-mat-option" *ngFor="let k of kunden; let index = index "
                    [value]="k">
            <div>
                {{k.bezeichnung_final}}
            </div>
            <div style="font-size: 0.8em; color: #777">{{k.zusatz_final}} {{k.vvvo}}</div>
        </mat-option>
    </mat-select>

</mat-form-field>
