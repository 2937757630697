import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { AnwendungsSelectorComponent } from "./anwendungs-selector.component";

@NgModule({
    declarations: [
      AnwendungsSelectorComponent,  
    ],
    imports: [
        CommonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule
    ],
    exports:[
        AnwendungsSelectorComponent
    ]
  })
  export class AnwendungsSelectorModule { }