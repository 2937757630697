import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { HttpParams } from '@angular/common/http';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { GruppeTier } from '../../../gruppe-tier/classes/gruppe-tier';
import { KieneBackendApiService } from '../../../../services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../../services/message-service/message.service';
import { GruppeAbgangService } from '../gruppe-abgang.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { EnterValueDialogComponent } from '../../../dialogs/enter-value-dialog/enter-value-dialog.component';
import {
    EnterValueInput,
    EnterValueResponse,
} from '../../../dialogs/enter-value-dialog/enter-value-response';
import { API_BASE_URL_SERVICE } from '../../../../kiene-core.config';
import { AppService } from '../../../../services/app/app.service';
import { SoundType } from '../../../../classes/sound-type';
import { GruppenService } from '../../gruppen.service';
import { Tierliste } from '../../../gruppe-tier/classes/tierliste';

export class AbgangScanResult {
    ohrmarke: string;
    success: boolean;
}

@Component({
    selector: 'kiene-abgang-scannen',
    templateUrl: './abgang-scannen.component.html',
    styleUrls: ['./abgang-scannen.component.scss'],
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    backgroundColor: 'rgba(55, 126, 34, 0.8)',
                })
            ),
            state(
                'closed',
                style({
                    backgroundColor: 'rgba(55, 126, 34, 0)',
                })
            ),
            transition('open => closed', [animate('1s')]),
            transition('closed => open', [animate('0.5s')]),

            state(
                'openDuplicate',
                style({
                    backgroundColor: 'rgba(255, 150, 45, 0.8)',
                })
            ),
            state(
                'closeDuplicate',
                style({
                    backgroundColor: 'rgba(255, 150, 45, 0)',
                })
            ),
            transition('openDuplicate => closeDuplicate', [animate('1s')]),
            transition('closeDuplicate => openDuplicate', [animate('0.5s')]),

            state(
                'openError',
                style({
                    backgroundColor: 'rgba(155, 0, 36, 0.8)',
                })
            ),
            state(
                'closedError',
                style({
                    backgroundColor: 'rgba(155, 0, 36, 0)',
                })
            ),
            transition('openError => closedError', [animate('1s')]),
            transition('closedError => openError', [animate('0.5s')]),
        ]),
    ],
})
export class AbgangScannenComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    @ViewChild('ohrenmarkeInput') ohrenmarkeInput: MatInput;

    @Input('abgangsliste') abgangsliste: Tierliste;

    ohrmarkePassCtrl = new UntypedFormControl(0);

    ohrenmarkeCtrl = new UntypedFormControl(undefined, [
        Validators.maxLength(10),
        Validators.minLength(10),
    ]);

    gescannteOhrmarken: AbgangScanResult[] = [];
    openCloseState = 'closed';

    ladeOhrmarkeSub: Subscription;

    maxLengthOhrmarke = 10;

    mitGewichtToggleCtrl = new UntypedFormControl(false);
    selectedTier: GruppeTier;

    gruppeTierSubscription: Subscription;

    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private abgangService: GruppeAbgangService,
        private messageService: MessageService,
        private dialog: MatDialog,
        private appService: AppService,
        private gruppenService: GruppenService
    ) {}

    ngOnDestroy(): void {
        this.ladeOhrmarkeSub?.unsubscribe();
    }

    ngOnInit(): void {
        this.ohrmarkePassCtrl.valueChanges.subscribe((value) => {
            if (value === 1) {
                this.maxLengthOhrmarke = 15;
            } else {
                this.maxLengthOhrmarke = 10;
            }
        });
    }

    clear() {
        this.ohrmarkePassCtrl.setValue(undefined, { emitEvent: false });
        this.selectedTier = undefined;
        this.ohrenmarkeInput?.focus();
    }

    ngAfterViewInit(): void {
        this.ohrenmarkeInput?.focus();
    }

    isInputValid(): boolean {
        if (this.ohrmarkePassCtrl.value === 0) {
            // ohrmarke
            if (this.ohrenmarkeCtrl.value?.length === 10) {
                return true;
            }
            this.messageService.alertMessage(
                'Die Länge der Ohrmarke ist ungültig!'
            );
        }
        if (this.ohrmarkePassCtrl.value === 1) {
            // pass
            if (this.ohrenmarkeCtrl.value?.length === 15) {
                return true;
            }
            this.messageService.alertMessage(
                'Die Länge der Passnummer ist ungültig!'
            );
        }
        this.ohrenmarkeCtrl.setValue('');
        return false;
    }

    checkPassOhrmarke(gewichtInKg?: number, abteil?: string) {
        if (!this.isInputValid()) {
            return;
        }
        let params = new HttpParams();
        if (this.abgangsliste?.gruppe_id_abgang) {
            params = params.set(
                'gruppe_id',
                this.abgangsliste?.gruppe_id_abgang.toString()
            );
        } else {
            params = params.set('alle_gruppen', '1');
        }

        if (this.ohrmarkePassCtrl.value === 0) {
            // ohrmarke gescannt
            params = params.set('ohrmarke_kurz', this.ohrenmarkeCtrl.value);
        } else if (this.ohrmarkePassCtrl.value === 1) {
            // pass gescannt
            params = params.set('ohrmarke15', this.ohrenmarkeCtrl.value);
        }

        params = params.set('tot', '0');

        this.ladeOhrmarkeSub?.unsubscribe();
        this.ladeOhrmarkeSub = this.api
            .getAll(
                this.apiBaseUrl + 'gruppe_tier/read_kundenportal.php',
                params
            )
            .subscribe({
                next: (response) => {
                    if (response.count === 1) {
                        this.selectedTier = response.records[0];
                        this.melden(gewichtInKg, abteil);
                    } else if (response.count > 1) {
                        this.messageService.alertMessage(
                            'Mehr als ein Tier gefunden!'
                        );
                        this.ohrenmarkeCtrl.setValue('');
                        this.ohrenmarkeInput?.focus();
                    } else {
                        this.messageService.alertMessage(
                            'Kein Tier mit diesen Daten gefunden!'
                        );
                        this.ohrenmarkeCtrl.setValue('');
                        this.ohrenmarkeInput?.focus();
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }

    melden(weight?: number, abteil?: string) {
        this.appService.setBlockOnLoading(true);
        const obj = {
            tierliste_id: this.abgangsliste?.tierliste_id,
            gruppen_tiere: [
                {
                    gt_id: this.selectedTier.gt_id,
                    abgangsgewicht: weight,
                    abteil_abgang: abteil,
                },
            ],
        };
        this.api
            .post(
                this.apiBaseUrl + 'tierliste/abgang_kundenportal.php',
                obj,
                undefined
            )
            .subscribe({
                next: (tierlisteAntwort: Tierliste) => {
                    this.abgangService.setAbgangsliste(
                        tierlisteAntwort,
                        true,
                        true
                    );
                    this.appService.setBlockOnLoading(false);
                    this.ohrenmarkeCtrl.enable();

                    const scanResult = new AbgangScanResult();
                    if (this.ohrmarkePassCtrl.value === 0) {
                        scanResult.ohrmarke = this.selectedTier.ohrmarke_kurz;
                    } else {
                        scanResult.ohrmarke = this.selectedTier.ohrmarke15;
                    }
                    scanResult.success = true;
                    this.gescannteOhrmarken.unshift(scanResult);
                    this.gruppenService.playScanSound(SoundType.OK);
                    this.openCloseState = 'closed';
                    this.openCloseState = 'open';
                    this.ohrenmarkeCtrl.setValue(undefined, {
                        emitEvent: false,
                    });
                    this.ohrenmarkeInput?.focus();
                    this.selectedTier = undefined;
                    setTimeout(() => {
                        this.openCloseState = 'closed';
                    }, 400);
                },
                error: (err) => {
                    this.messageService.errorMessage('Fehler!', err);
                    this.appService.setBlockOnLoading(false);
                    this.ohrenmarkeCtrl.enable();
                    this.gruppenService.playScanSound(SoundType.ERROR);
                    const scanResult = new AbgangScanResult();
                    if (this.ohrmarkePassCtrl.value === 0) {
                        scanResult.ohrmarke = this.selectedTier.ohrmarke_kurz;
                        scanResult.success = false;
                    } else {
                        scanResult.ohrmarke = this.selectedTier.ohrmarke15;
                        scanResult.success = false;
                    }
                    this.gescannteOhrmarken.unshift(scanResult);
                    this.openCloseState = 'openError';
                    setTimeout(() => {
                        this.openCloseState = 'closedError';
                        this.ohrenmarkeCtrl.setValue('');
                        this.ohrenmarkeInput?.focus();
                    }, 400);
                },
            });
    }

    openWeightDailog() {
        const input = new EnterValueInput();
        input.title = 'Gewicht oder Abteil eingeben';
        input.text =
            'Es kann optional ein Gewicht oder Abteil angegeben werden';
        input.okButton = 'Speichern';
        input.buttonColor = 'accent';
        input.addInputValue('Gewicht in kg (otional)', 'decimal');
        input.addInputValue(
            'Abteil (optional)',
            'text',
            this.abgangService.abteilAbgang
        );

        const dialogRef = this.dialog.open(EnterValueDialogComponent, {
            data: input,
            position: { top: '10px' },
        });
        console.log(dialogRef);
        dialogRef.afterClosed().subscribe({
            next: (response: EnterValueResponse) => {
                if (response) {
                    if (response.result) {
                        const gewicht = response.getValue(0);
                        const abteil = response.getValue(1);
                        this.abgangService.abteilAbgang = abteil;
                        this.checkPassOhrmarke(gewicht, abteil);
                    } else {
                        this.checkPassOhrmarke();
                    }
                }
            },
        });
    }

    addOhrmarkePass($event) {
        $event.stopPropagation();
        if (!this.isInputValid()) {
            return;
        }
        this.mitGewichtToggleCtrl.value
            ? this.openWeightDailog()
            : this.checkPassOhrmarke();
    }
}
