<h1 mat-dialog-title>{{ headline }}</h1>
<div mat-dialog-content style="padding-top: 12px;">
    <mat-radio-group [formControl]="radioCtrl">
        <mat-radio-button [value]="0">Stornieren</mat-radio-button>
        <div>
            <p *ngIf="kalenderEintrag">
                Möchten Sie den
                {{
                kalenderEintrag?.eintragtyp === kalenderTyp.Urlaub
                    ? "Urlaub"
                    : "Abwesenheit"
                }}
                vom
                <strong>{{
                    kalenderEintrag?.startdatum | date : "dd.MM.YYYY"
                    }}</strong>
                bis zum
                <strong>{{
                    kalenderEintrag?.enddatum | date : "dd.MM.YYYY"
                    }}</strong>
                für <strong>{{ kalenderEintrag?.benutzername }}</strong> wirklich
                stornieren?
            </p>

            <mat-form-field style="width: 100%">
                <mat-label>Stornierungsgrund</mat-label>
                <input
                    [formControl]="grundCtrl"
                    matInput
                    placeholder="Stornierungsgrund"
                />
            </mat-form-field>
        </div>
        <mat-radio-button [value]="1">Dauer &auml;ndern</mat-radio-button>
        <div class="one-row" style="padding-top: 12px;">
            <mat-form-field>
                <mat-label>Start</mat-label>
                <input [(ngModel)]="kalenderEintrag.startdatum" [disabled]="aenderungDisabled"
                       [matDatepicker]="pickerStart" matInput>
                <mat-hint>TT.MM.JJJJ</mat-hint>
                <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
            </mat-form-field>
            <span class="spacer"></span>
            <mat-form-field>
                <mat-label>Ende</mat-label>
                <input [(ngModel)]="kalenderEintrag.enddatum" [disabled]="aenderungDisabled"
                       [matDatepicker]="pickerEnde" matInput>
                <mat-hint>TT.MM.JJJJ</mat-hint>
                <mat-datepicker-toggle [for]="pickerEnde" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #pickerEnde></mat-datepicker>
            </mat-form-field>
        </div>
    </mat-radio-group>

</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button (click)="dialogRef.close()" mat-flat-button>Abbrechen</button>
    <button
        (click)="save()"
        color="accent"
        mat-flat-button
    >
        {{radioCtrl.value === 0 ? 'Stornieren' : 'Dauer ändern'}}
    </button>
</div>
