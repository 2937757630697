import { Component, Inject, Input, OnInit } from '@angular/core';
import { Freigabe } from './freigabe';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../services/message-service/message.service';
import { MatDialog } from '@angular/material/dialog';
import { FreigabeErstellenDialogComponent } from './freigabe-erstellen-dialog/freigabe-erstellen-dialog.component';
import { AppService } from '../../../services/app/app.service';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogInput, ConfirmDialogResult } from '../../dialogs/confirm-dialog/confirm-dialog';

@Component({
    selector: 'kiene-freigaben',
    templateUrl: './freigaben.component.html',
    styleUrls: ['./freigaben.component.scss']
})
export class FreigabenComponent implements OnInit {

    @Input('showReturnButton') showReturnButton = true;
    isMobile = false;

    freigaben: Freigabe[] = [];

    constructor(@Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private dialog: MatDialog,
        private appService: AppService) {
        this.isMobile = this.appService.isMobileScreen;
    }

    ngOnInit(): void {
        this.ladeFreigaben();
    }

    freigabeAnlegen() {
        this.dialog.open(FreigabeErstellenDialogComponent, {
            width: '100%',
            minWidth: this.isMobile ? '99vw' : '600px'
        }).afterClosed().subscribe({
            next: result => {
                this.ladeFreigaben();
            }
        });
    }

    deleteFreigabe(f: Freigabe) {
        const inp = new ConfirmDialogInput();
        inp.headline = 'Freigabe entfernen';
        inp.text = 'Wollen Sie die Freigabe wirklich entfernen?';
        inp.okButton = 'entfernen';
        this.dialog.open(ConfirmDialogComponent, { data: inp }).afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result.ok && result.confirmed) {
                    this.api.deleteEntity(this.apiBaseUrl + 'kunde_freigabe/delete.php', f).subscribe({
                        next: response => {
                            this.ladeFreigaben();
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler!', err);
                        }
                    });

                }
            }
        });
    }

    private ladeFreigaben() {
        this.api.getAll(this.apiBaseUrl + 'kunde_freigabe/read.php').subscribe({
            next: response => {
                this.freigaben = response.records;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}
