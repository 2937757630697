import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Material } from '../../material/material';
import { SupplierMaterial } from '../supplier-material';
import { HttpParams } from '@angular/common/http';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { environment } from '../../../../environments/environment';
import { UntypedFormControl } from '@angular/forms';
import { Supplier } from '../../supplier/supplier';

@Component({
  selector: 'kiene-supplier-material-selector',
  templateUrl: './supplier-material-selector.component.html',
  styleUrls: ['./supplier-material-selector.component.scss']
})
export class SupplierMaterialSelectorComponent implements OnInit, OnChanges {

  @Input('materialId') materialId: number;
  @Input('supplierId') supplierId: number;

  @Output('change') change = new EventEmitter<SupplierMaterial>();

  supplierCtrl = new UntypedFormControl();

  currentSupplierMaterial = new SupplierMaterial();

  supplierMaterials: SupplierMaterial[] = [];

  constructor(private api: KieneTableService) { }

  ngOnInit() {
    this.currentSupplierMaterial.artikel_id = this.materialId;
    this.currentSupplierMaterial.lieferant_id = this.supplierId;
    this.supplierCtrl.setValue(this.currentSupplierMaterial);

    this.loadSupplierMaterials();

    this.supplierCtrl.valueChanges.subscribe(supplierMaterial => {
      this.change.emit(supplierMaterial);
    });
  }

  ngOnChanges() {
    // this.loadSupplierMaterials();
  }

  loadSupplierMaterials() {
    if (this.materialId) {
      let params = new HttpParams().set('artikel_id', this.materialId.toString());
      this.api.get(environment.BASE_URI + 'artikel_lieferant/read.php', params).subscribe(response => {
        this.supplierMaterials = response.records;
      }, error => { });

    }
  }

  supplierMaterialComparator(smOne: SupplierMaterial, smTwo: SupplierMaterial) {
    if (!smOne || !smTwo) {
      return false;
    }
    return smOne.lieferant_id === smTwo.lieferant_id;
  }

}
