export class Bestandsbesuchprotokoll {
    abrechnen: number;
    abgabebeleg_id: number;
    bbp_id: number;

    tierarzt_id: number;
    tierarzt_vorname: string;
    tierarzt_nachname: string;

    kunde_id: number;
    kuid: string;
    kunde: string;
    kunde_ort: string;
    kunde_plz: string;
    kunde_strasse: string;
    kunde_telefon_1: string;

    status_id: number;
    status: string;

    pdf_id: number;
    datum: Date;

    sauen: number;
    saugferkel: number;
    absatzferkel: number;
    mastschweine: number;
    kuehe: number;
    kaelber: number;
    fresser: number;
    bullen: number;
    mastrinder: number;
    bestand: string;
    klinisch_ohne: number;
    klinisch_erkrankungen: number;
    atmungsorgane: number;
    verdauungsorgane: number;
    nervensystem: number;
    haut: number;
    harnwege: number;
    bewegungsapparates: number;
    sonstiges: string;
    massnahmen: number;
    kotproben: number;
    blutproben: number;
    tupferproben: number;
    sektionen: number;
    mikrobiologie: number;
    medikamentierung: number;
    mnp_erstellt: number;
    pruefung_th: number;
    verdachtsdiagnose: string;
    sonstiges_massnahmen: string;


}
