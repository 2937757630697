import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Untersuchungsgruppe } from '../untersuchungsgruppe';

@Component({
  selector: 'kiene-untersuchungsgruppe-dialog',
  templateUrl: './untersuchungsgruppe-dialog.component.html',
  styleUrls: ['./untersuchungsgruppe-dialog.component.scss']
})
export class UntersuchungsgruppeDialogComponent implements OnInit {

  untersuchungsgruppeCtrl = new UntypedFormControl('', [Validators.minLength(1)]);

  untersuchungsgruppe: Untersuchungsgruppe;

  edit = true;

  constructor(public dialogRef: MatDialogRef<UntersuchungsgruppeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if(data){
        this.untersuchungsgruppe = data.untersuchungsgruppe;
        this.untersuchungsgruppeCtrl.setValue(this.untersuchungsgruppe.bezeichnung);
      }
    }

  ngOnInit() {
    if(!this.untersuchungsgruppe){
      this.edit = false;
      this.untersuchungsgruppe = new Untersuchungsgruppe();
    }
  }

  public cancel(){
    this.dialogRef.close();
  }

  public save(){
    this.untersuchungsgruppe.bezeichnung = this.untersuchungsgruppeCtrl.value;
    this.dialogRef.close(this.untersuchungsgruppe);
  }

}
