<mat-form-field style="width: 100%">
  <mat-label>Anwendung</mat-label>
  <mat-select
    #anwendungSelect
    [formControl]="anwendungCtrl"
    [disabled]="disabled"
    (focus)="openPanel()"
    (selectionChange)="anwendungSelected($event)"
    (openedChange)="anwendungOpenedChange($event)"
    [compareWith]="anwendungComparator"
  >
    <mat-option *ngFor="let a of anwendungen" [value]="a">{{
      a.anwendung
    }}</mat-option>
  </mat-select>
</mat-form-field>
