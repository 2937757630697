import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NewPasswordComponent} from './new-password/new-password.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';


@NgModule({
    declarations: [
        NewPasswordComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule
    ],
    exports: [
        NewPasswordComponent
    ]
})
export class NewPasswordModule {
}
