<div style="padding: 20px;">
  <div class="one-row">
    <h1>Untersuchungsmaterial {{untersuchungsmaterial.bezeichnung_tierart}}</h1>
    <span class="spacer"></span>
    <button mat-icon-button (click)="addNewUntersuchung()"><mat-icon>add</mat-icon></button>
  </div>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!--
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding
      style="border-bottom: 1px solid #CCC;">
      <div style="padding-left: 40px;">
        <div *ngIf="node.untersuchung">{{node.untersuchung}}</div><br>
        <div *ngIf="node.artikel">Artikel: {{node.artikel}}</div><br>
        <div *ngIf="node.lieferant">Lieferant: {{node.lieferant}}</div>
      </div>
      <button mat-icon-button [matMenuTriggerFor]="addUntersuchungMenu">
          <mat-icon>add</mat-icon>
        </button>
        <mat-menu #addUntersuchungMenu="matMenu">
          <button mat-menu-item (click)="addNewItem(node)">Untersuchung</button>
          <button mat-menu-item (click)="addNewArtikelItem(node)">Artikel</button>
        </mat-menu>
    </mat-tree-node>
-->
    <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <mat-form-field>
        <input matInput #itemValue placeholder="Untersuchung hinzufügen...">
      </mat-form-field>
      <button mat-button (click)="saveNode(node, itemValue.value)">Speichern</button>
    </mat-tree-node>
    
    <mat-tree-node *matTreeNodeDef="let node; when: isNewArtikelNode" matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <div style="min-width:400px;">
        <kiene-autocomplete [apiUrl]="'artikel_autocomplete/'" [displayField]="'artikel_me'" [placeholder]="'Artikel auswählen...'" (elementSelected)="saveArtikelNode(node, $event)"></kiene-autocomplete>
      </div>
      <button mat-icon-button color="accent" (click)="deleteNode(node)"><mat-icon>delete</mat-icon></button>
    </mat-tree-node>

    

    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
        <mat-icon class="mat-icon-rtl-mirror">
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <span>{{node.untersuchung}}</span>
      <button mat-icon-button (click)="openEditUntersuchungsDialog(node)"><mat-icon>edit</mat-icon></button>
      <button mat-icon-button [matMenuTriggerFor]="addUntersuchungMenu">
          <mat-icon>add</mat-icon>
        </button>
        <mat-menu #addUntersuchungMenu="matMenu">
          <button mat-menu-item (click)="addNewItem(node)">Untersuchung</button>
          <button mat-menu-item (click)="addNewArtikelItem(node)">Artikel</button>
        </mat-menu>
        <button mat-icon-button (click)="deleteNode(node)"><mat-icon>delete</mat-icon></button>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: isArtikelNode"  matTreeNodePadding
      style="border-bottom: 1px solid #CCC;">
      <div style="padding-left: 40px;">
        <div *ngIf="node.untersuchung">{{node.untersuchung}}</div><br>
        <div *ngIf="node.artikel">Artikel: {{node.artikel}}</div><br>
        <div *ngIf="node.lieferant">Lieferant: {{node.lieferant}}</div>
      </div>
      <button mat-icon-button (click)="deleteNode(node)"><mat-icon>delete</mat-icon></button>
    </mat-tree-node>

  </mat-tree>

  <div>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
  </div>

</div>