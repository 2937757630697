import {
    Component,
    OnInit,
    AfterViewInit,
    ViewChild,
    ElementRef,
    Inject,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BestellvorschlagService} from './bestellvorschlag.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {merge, Observable, of as observableOf, fromEvent} from 'rxjs';
import {
    catchError,
    map,
    startWith,
    switchMap,
    debounceTime,
    distinctUntilChanged,
    tap,
} from 'rxjs/operators';
import {Bestellvorschlag} from './bestellvorschlag';
import {Router} from '@angular/router';
import {Supplier} from '../../warehouse/supplier/supplier';
import {SupplierService} from '../../warehouse/supplier/supplier.service';
import {WarenkorbPosition} from '../warenkorb/warenkorb';
import {WarenkorbService} from '../warenkorb/warenkorb.service';
import {MaterialService} from '../../warehouse/material/material.service';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';

import {MessageService} from '../../services/message.service';

import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {environment} from './../../../environments/environment';
import {
    ConditionSetting,
    ConditionTableColumn,
    TableColumn,
} from '../../components/kiene-table/table-column';
import {
    Condition,
    Operator,
    TableOption,
    TableOptionEvent,
} from '../../components/kiene-table/table-option';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {ChangeBestellmengenDialogComponent} from './change-bestellmengen-dialog/change-bestellmengen-dialog.component';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
    ConfirmDialogComponent
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';

@Component({
    selector: 'kiene-bestellvorschlaege',
    templateUrl: './bestellvorschlaege.component.html',
    styleUrls: ['./bestellvorschlaege.component.scss'],
})
export class BestellvorschlaegeComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private warenkorbService: WarenkorbService
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Bestellvorschläge';
        this.tableDescriptor.uniqueIdentifier = 'Bestellvorschläge';
        this.tableDescriptor.apiUrl =
            environment.BASE_URI + 'bestellvorschlag/';
        this.tableDescriptor.route = '/lager/artikel/';
        this.tableDescriptor.nameOfIdField = 'artikel_id';
        this.tableDescriptor.searchPlaceholder =
            'Bestellvorschläge durchsuchen';

        this.tableDescriptor.columns.push(
            new TableColumn('artikel_id', 'Artikel ID')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('bezeichnung', 'Artikel')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('einheit', 'Einheit')
        );

        const conditionSettings = [
            new ConditionSetting([new Condition('status', Operator.GLEICH, 1)], {
                icon: 'lens',
                color: 'green',
                tooltip: 'Bestand ausreichend vorhanden',
            }),
            new ConditionSetting([new Condition('status', Operator.GLEICH, 2)], {
                icon: 'warning',
                color: 'orange',
                tooltip: 'Mindestbestand bald erreicht',
            }),
            new ConditionSetting([new Condition('status', Operator.GLEICH, 3)], {
                icon: 'error',
                color: 'red',
                tooltip: 'Mindestbestand erreicht oder unterschritten',
            }),
        ];
        this.tableDescriptor.columns.push(
            new ConditionTableColumn('status', 'Status', conditionSettings, {
                sortFields: ['status'],
            })
        );

        this.tableDescriptor.columns.push(
            new TableColumn('apotheke', 'Menge Apotheke', 'number')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('lager', 'Menge Lager', 'number')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('gesamt', 'Menge Gesamt', 'number')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('mindestbestand', 'Mindestbestand')
        );
        // this.tableDescriptor.columns.push(new TableColumn('fehlend', 'Fehlende Menge min.', 'number'));
        this.tableDescriptor.columns.push(
            new TableColumn('bestellmenge', 'Mindestbestellmenge')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('bestellt', 'Bestellt')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('warenkorb', 'Warenkorb')
        );

        this.tableDescriptor.permissionView = 1191;

        this.tableDescriptor.options.push(
            new TableOption(
                'add_to_warenkorb',
                'Zum Warenkorb hinzufügen',
                'add_shopping_cart',
                1772
            )
        );
        this.tableDescriptor.options.push(
            new TableOption('edit', 'Bestellmengen editieren', 'edit', 1770)
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'remove',
                'Bestellvorschlag entfernen',
                'remove',
                1770
            )
        );

        this.tableDescriptor.initialSortColumn = 'status';
        this.tableDescriptor.initialSortDirection = 'desc';
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        } else if (event.name === 'add_to_warenkorb') {
            this.openAddToWarenkorbDialog(event.value);
        } else if (event.name === 'edit') {
            this.openEditBestellmengenDialog(event.value);
        } else if (event.name === 'remove') {
            this.openRemoveBestellvorschlagDialog(event.value);
        }
    }

    openRemoveBestellvorschlagDialog(b: Bestellvorschlag) {
        const input = new ConfirmDialogInput();
        input.headline = 'Bestellvorschlag entfernen';
        input.text =
            'Hiermit wird der Artikel ' +
            b.bezeichnung +
            ' nicht mehr in den Bestellvorschlägen angezeigt.';
        input.okButton = 'Entfernen';

        let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    b.bestellvorschlag = 0;
                    this.tableService
                        .updateGeneric(
                            environment.BASE_URI +
                            'bestellvorschlag/update_artikel.php',
                            b
                        )
                        .subscribe(
                            (response) => {
                                this.messageService.infoMessage(
                                    'Der Bestellvorschlag wurde erfolgreich entfernt'
                                );
                                this.tableService.reload();
                            },
                            (error) => {
                                this.messageService.errorMessage(
                                    'Beim Entfernen des Bestellvorschlags ist ein Fehler aufgetreten.',
                                    error
                                );
                            }
                        );
                }
            },
        });
    }

    openEditBestellmengenDialog(b: Bestellvorschlag) {
        let dialogRef = this.dialog.open(ChangeBestellmengenDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: {
                bestellvorschlag: b,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.tableService.reload();
        });
    }

    openAddToWarenkorbDialog(b: Bestellvorschlag) {
        let dialogRef = this.dialog.open(AddArticleToWarenkorbDialog, {
            width: '50%',
            minWidth: '300px',
            data: {
                bestellvorschlag: b,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            let wkp: WarenkorbPosition = result;
            this.warenkorbService
                .addWarenkorbPositionToWarenkorb(wkp)
                .subscribe(
                    (response) => {
                        this.warenkorbService.updateShoppingCartBadge();
                        this.messageService.infoMessage(
                            'Der Artikel wurde zum Warenkorb hinzugefügt!'
                        );
                        this.tableService.reload();
                    },
                    (error) => {
                        this.messageService.errorMessage('Fehler', error);
                    }
                );
        });
    }

    openDeleteDialog(supplier: Supplier) {
        let dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Lieferant ' + supplier.bezeichnung + ' löschen?',
                text:
                    'Wollen Sie den Lieferanten ' +
                    supplier.bezeichnung +
                    ' wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.tableService
                    .deleteEntity(environment.BASE_URI + 'lieferant/delete.php', supplier)
                    .subscribe(
                        (result) => {
                            this.messageService.infoMessage(
                                'Der Lieferant wurde erfolgreich gelöscht!'
                            );
                            this.tableService.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Der Lieferant konnte nicht gelöscht werden! ',
                                error
                            );
                        }
                    );
            }
        });
    }
}

@Component({
    selector: 'add-article-to-warenkorb-dialog',
    template: `<h3 mat-dialog-title>Artikel dem Warenkorb hinzuf&uuml;gen</h3>
        <div mat-dialog-content>
            <h3>{{ bestellvorschlag.bezeichnung }}</h3>
            <p>Bitte geben Sie die Bestellmenge ein:</p>
            <mat-form-field>
                <input
                    matInput
                    type="number"
                    placeholder="Bestellmenge"
                    [(ngModel)]="bestellvorschlag.bestellmenge"
                />
            </mat-form-field>
            <div *ngIf="lieferanten.length > 1">
                <p>Bitte w&auml;hlen Sie den Lieferanten aus:</p>
                <mat-form-field style="width: 100%;">
                    <mat-select [(value)]="selectedLieferant">
                        <mat-option *ngFor="let l of lieferanten" [value]="l">{{
                            l.bezeichnung
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div mat-dialog-actions>
            <button mat-button color="primary" (click)="onCancelClick()">
                Abbrechen
            </button>
            <button
                mat-raised-button
                color="primary"
                (click)="addToWarenkorb()"
            >
                Hinzuf&uuml;gen
            </button>
        </div>`,
})
export class AddArticleToWarenkorbDialog {
    bestellvorschlag: Bestellvorschlag;
    lieferanten: Supplier[] = [];
    selectedLieferant: Supplier = new Supplier();

    constructor(
        public dialogRef: MatDialogRef<AddArticleToWarenkorbDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private materialService: MaterialService,
        private supplierService: SupplierService
    ) {
        this.bestellvorschlag = data.bestellvorschlag;
        this.loadSupplierForArticle(this.bestellvorschlag.artikel_id);
    }

    loadSupplierForArticle(articleId: number) {
        this.supplierService.getSuppliersForMaterialId(articleId).subscribe(
            (response) => {
                this.lieferanten = response.records;
                if (this.lieferanten.length === 1) {
                    this.selectedLieferant = this.lieferanten[0];
                }
            },
            (error) => {
            }
        );
    }

    addToWarenkorb() {
        const wkp: WarenkorbPosition = new WarenkorbPosition();
        wkp.artikel_id = this.bestellvorschlag.artikel_id;
        wkp.artikel = this.bestellvorschlag.bezeichnung;
        wkp.einheit = this.bestellvorschlag.einheit;
        wkp.lieferant_id = this.selectedLieferant.lieferant_id;
        wkp.menge_soll = this.bestellvorschlag.bestellmenge;
        this.dialogRef.close(wkp);
    }

    onCancelClick(): void {
        this.dialogRef.close(false);
    }
}
