import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {VerkaufspreisApi} from './verkaufspreis-selector.component';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable({
    providedIn: 'root'
})
export class VerkaufspreisSelectorService {

    constructor(private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    loadVerkaufspreise(): Observable<VerkaufspreisApi> {
        return this.http.get<VerkaufspreisApi>(this.apiBaseUrl + 'verkaufspreis/read.php');
    }
}
