<h1 mat-dialog-title>Warenr&uuml;cksendung</h1>
<div mat-dialog-content>
    <h3>Bestellung #{{bestellungExtern?.bestell_nr}}</h3>
    <table>
        <tr>
            <td>Lieferant:</td>
            <td><strong>{{bestellungExtern?.lieferant}}</strong></td>
        </tr>
        <tr>
            <td>Artikel:</td>
            <td><strong>{{umbuchung?.artikel_me}}</strong></td>
        </tr>
        <tr>
            <td>Charge:</td>
            <td><strong>{{umbuchung?.charge}}</strong></td>
        </tr>
        <tr>
            <td>Bestellte Menge:</td>
            <td><strong>{{bestellteMenge}}</strong></td>
        </tr>
        <tr>
            <td>Geliefertet Menge:</td>
            <td><strong>{{umbuchung?.menge}}</strong></td>
        </tr>
        <tr>
            <td>Wareneingang:</td>
            <td><strong>{{umbuchung?.erstellt | date: 'dd.MM.yyyy'}}</strong></td>
        </tr>
    </table>
    <h3>Bitte geben Sie die zur&uuml;ckzugebene Menge ein!</h3>
    <mat-form-field style="width: 100%">
        <mat-label>Menge an {{umbuchung?.artikel_me}}</mat-label>
        <input [formControl]="mengeCtrl" inputmode="decimal" matInput type="number"/>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button (click)="dialogRef.close()" mat-button>Abbrechen</button>
    <button (click)="save()" [disabled]="!mengeCtrl.valid" color="accent" mat-flat-button>R&uuml;cklieferung buchen
    </button>
</div>
