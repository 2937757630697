<h1 mat-dialog-title>Alle Dokumente herunterladen</h1>
<div mat-dialog-content>
    <div class="one-row">

        <mat-form-field style="margin-right: 12px;">
            <mat-label>Von</mat-label>
            <input matInput [matDatepicker]="pickerVon" [formControl]="vonCtrl">
            <mat-datepicker-toggle matSuffix [for]="pickerVon"></mat-datepicker-toggle>
            <mat-datepicker #pickerVon></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Bis</mat-label>
            <input matInput [matDatepicker]="pickerBis" [formControl]="bisCtrl">
            <mat-datepicker-toggle matSuffix [for]="pickerBis"></mat-datepicker-toggle>
            <mat-datepicker #pickerBis></mat-datepicker>
        </mat-form-field>

    </div>

    <h4>Sortierung</h4>
    <mat-radio-group [formControl]="sortierungCtrl">
        <mat-radio-button [value]="'chronologisch'">Chronologisch</mat-radio-button>
        <mat-radio-button [value]="'typ'">Nach Typ</mat-radio-button>
    </mat-radio-group>

    <h4><mat-checkbox [indeterminate]="notAllSelected()" [formControl]="alleCtrl">alle Dokumente</mat-checkbox></h4>
    <ul style="list-style-type: none;">
        <li *ngFor="let po of downloadPermissionObjects">
            <mat-checkbox [formControl]="po.formControl">{{po.displayName}}</mat-checkbox>
        </li>
    </ul>

</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="download()">Herunterladen</button>
</div>