import {Inject, Injectable} from '@angular/core';

import {Observable, Subject} from 'rxjs';
import {MessageService} from '../../services/message-service/message.service';
import {StammdatenService} from '../stammdaten/stammdaten.service';
import {KieneBackendApiService} from '../../services/backend-api/kiene-backend-api.service';
import {LocalStorageService} from '../../services/local-storage/local-storage.service';
import {KieneSession} from '../../services/local-storage/kiene-session';
import {Ersteinrichtung} from './classes/ersteinrichtung';
import {API_BASE_URL_SERVICE} from '../../kiene-core.config';
import {VetVetRegistrationDTO} from '../../hit/hit-registrierung/classes/VetVetRegistrationDTO';

@Injectable({
    providedIn: 'root',
})
export class RegistrierungService {
    vetVetRegistrationsStatusErfolgreich: Subject<boolean> = new Subject();
    ersteinrichtung: Ersteinrichtung = new Ersteinrichtung(0, 0, 0);
    ersteinrichtungSubject: Subject<Ersteinrichtung> = new Subject();

    constructor(
        private api: KieneBackendApiService,
        private localStorageService: LocalStorageService,
        private messageService: MessageService,
        private stammdatenService: StammdatenService,
        @Inject(API_BASE_URL_SERVICE) private baseUrl: string
    ) {
    }

    watchVetVetRegistrationsStatusErfolgreich() {
        return this.vetVetRegistrationsStatusErfolgreich.asObservable();
    }

    setVetVetRegistrationsStatusErfolgreich(status: boolean) {
        this.vetVetRegistrationsStatusErfolgreich.next(status);
    }

    watchErsteinrichtung() {
        return this.ersteinrichtungSubject.asObservable();
    }

    getErsteinrichtung() {
        return this.ersteinrichtung;
    }

    addVvvoErsteinrichtung(): void {
        this.ersteinrichtung.vvvo += 1;
        this.ersteinrichtungSubject.next(this.ersteinrichtung);
    }

    addHaendlerErsteinrichtung(): void {
        this.ersteinrichtung.haendler += 1;
        this.ersteinrichtungSubject.next(this.ersteinrichtung);
    }

    addEntsorgerErsteinrichtung(): void {
        this.ersteinrichtung.entsorger += 1;
        this.ersteinrichtungSubject.next(this.ersteinrichtung);
    }

    /**
     * registrieren eines neuen Kunden
     * der Status kann von einer beliebigen Component abgefragt werden ueber das entsprechende Subject des Services
     * <br> siehe setVetVetRegistrationsStatusErfolgreich()
     * @param vetVetRegistrationDTO
     */
    registerKunde(
        vetVetRegistrationDTO: VetVetRegistrationDTO
    ): Observable<boolean> {
        const subject = new Subject<boolean>();
        vetVetRegistrationDTO.platform = 'mobil';
        this.api
            .post(
                `${this.baseUrl}kundenportal/registrieren.php`,
                vetVetRegistrationDTO,
                null
            )
            .subscribe({
                next: (data) => {
                    const newSession = <KieneSession>data;
                    this.setLoggedInUserinStorage(newSession);
                    this.localStorageService.setCurrentMandant(
                        newSession.mandanten[0], 'RegistrierungService registerKunde()'
                    );
                    this.messageService.infoMessage(
                        `Registrierung erfolgreich.`
                    );

                    this.setVetVetRegistrationsStatusErfolgreich(true);
                    this.addVvvoErsteinrichtung();
                    return subject.next(true);
                },
                error: (err) => {
                    this.setVetVetRegistrationsStatusErfolgreich(false);
                    this.messageService.errorMessage(
                        'Fehler VetVet Registration! ',
                        err
                    );
                    return subject.next(false);
                },
            });
        return subject.asObservable();
    }

    loadBetriebsarten(): Observable<any> {
        return this.stammdatenService.loadBetriebsarten();
    }

    resetErsteinrichtung() {
        this.ersteinrichtung = new Ersteinrichtung(0, 0, 0);
    }

    private setLoggedInUserinStorage(session: KieneSession): void {
        this.localStorageService.setCurrentUser(session);
    }
}
