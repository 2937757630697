import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InventurUebersichtComponent} from '../../warehouse/inventur/inventur-uebersicht/inventur-uebersicht.component';
import {InventurComponent} from '../../warehouse/inventur/inventur.component';
import {InventurAuswertungComponent} from '../../warehouse/inventur/inventur-auswertung/inventur-auswertung.component';
import {AbgelaufeneChargenComponent} from '../../warehouse/inventur/abgelaufene-chargen/abgelaufene-chargen.component';

const routes: Routes = [
    {path: 'inventuren', component: InventurUebersichtComponent},
    {path: 'inventuren/:id', component: InventurComponent},
    {path: 'inventurenauswertung', component: InventurAuswertungComponent},
    {path: 'abgelaufenechargen', component: AbgelaufeneChargenComponent},
    {path: '**', redirectTo: 'inventuren'}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InventurRoutingModule {
}
