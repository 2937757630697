export class KalendereintragTierarzt {
    kt_id: number;
    kalendereintrag_id: number;
    tierarzt_id: number;
    vorname: string;
    nachname: string;
    editiert: Date;
}

export class KalenderEintrag {
    kalendereintrag_id: number;
    eintragtyp: KalenderTyp;
    startdatum: Date;
    startdatum_halbtags: number;
    enddatum: Date;
    enddatum_halbtags: number;
    genehmigt = 0;
    benutzer_id: number;
    benutzername: string;
    bemerkung: string;
    hintergrundfarbe: string;
    schriftfarbe: string;
    eintragtyp_id: number;
    stornierungsgrund: string;
    titel: string;
    dienstgruppe_id: number;
    dienstgruppe: string;
    dienstgruppe_farbe: string;
    dienstgruppe_hintergrundfarbe: string;
    tieraerzte: KalendereintragTierarzt[] = [];

    constructor(options?: {
        kalendereintrag_id?: number, typ: KalenderTyp, startdatum: Date, enddatum: Date,
        benutzer_id: number, benutzer_name: string, bemerkung: string, hintergrundfarbe: string, schriftfarbe: string,
        eintragtyp_id: number, startdatum_halbtags: number, enddatum_halbtags: number, titel: string
    }) {
        if (options) {
            this.kalendereintrag_id = options.kalendereintrag_id;
            this.eintragtyp = options.typ;
            this.eintragtyp_id = options.eintragtyp_id;
            this.startdatum = options.startdatum;
            this.enddatum = options.enddatum;
            this.benutzer_id = options.benutzer_id;
            this.benutzername = options.benutzer_name;
            this.bemerkung = options.bemerkung;
            this.hintergrundfarbe = options.hintergrundfarbe;
            this.schriftfarbe = options.schriftfarbe;
            this.startdatum_halbtags = options.startdatum_halbtags;
            this.enddatum_halbtags = options.enddatum_halbtags;
            this.titel = options.titel;
        }

    }
}

export enum KalenderTyp {
    Urlaub = 'Urlaub',
    Feiertag = 'Feiertag',
    Wochenenddienst = 'Wochenenddienst',
    Krankheit = 'Krankheit',
}
