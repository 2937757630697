
import { HeimtierBelegeTableComponent } from "./heimtier-belege-table/heimtier-belege-table.component";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    BesuchsDetailRechnungenComponent
} from "./besuchs-detail-seite/besuchs-detail-rechnungen/besuchs-detail-rechnungen.component";
import { BesuchsDetailSeiteComponent } from "./besuchs-detail-seite/besuchs-detail-seite.component";
import { HeimtierKundenComponent } from "./heimtier-kunden/heimtier-kunden.component";
import { HeimtiereStartseiteComponent } from "./heimtiere-startseite/heimtiere-startseite.component";

import { HeimtiereComponent } from "./heimtiere/heimtiere.component";
import { KalenderComponent } from "./kalender/kalender.component";

const routes: Routes = [
    {
        path: '', component: HeimtiereComponent, children: [
            { path: 'startseite', component: HeimtiereStartseiteComponent },
            { path: 'kalender', component: KalenderComponent },
            { path: 'kunden', component: HeimtierKundenComponent },
            { path: 'rechnungen/:id', component: BesuchsDetailRechnungenComponent },
            { path: 'belege/:id', component: BesuchsDetailSeiteComponent },
            { path: 'belege', component: HeimtierBelegeTableComponent },
            { path: '**', redirectTo: 'startseite' },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HeimtiereRoutingModule { }
