import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../app.auth.guard';
import {BestandComponent} from './bestand/bestand.component';
import {ChargesComponent} from './charges/charges.component';
import {GoodsReceiptHistoryComponent} from './goods-receipt-history/goods-receipt-history.component';
import {GoodsReceiptComponent} from './goods-receipt/goods-receipt.component';
import {MaterialDetailsComponent} from './material/material-details/material-details.component';
import {MaterialComponent} from './material/material.component';
import {StoragePlaceComponent} from './storage-place/storage-place.component';
import {StorageComponent} from './storage/storage.component';
import {SupplierDetailsComponent} from './supplier/supplier-details/supplier-details.component';
import {SupplierComponent} from './supplier/supplier.component';
import {WarehouseComponent} from './warehouse.component';
import {WarenruecklieferungenComponent} from './warenruecklieferungen/warenruecklieferungen.component';

const routes: Routes = [
    {
        path: '', component: WarehouseComponent, canActivate: [AuthGuard], children: [
            {path: 'wareneingang', component: GoodsReceiptComponent, canActivate: [AuthGuard]},
            {path: 'bestand', component: BestandComponent, canActivate: [AuthGuard]},
            {path: 'wareneinganghistorie', component: GoodsReceiptHistoryComponent, canActivate: [AuthGuard]},
            {path: 'warenruecklieferungen', component: WarenruecklieferungenComponent, canActivate: [AuthGuard]},
            {path: 'artikel', component: MaterialComponent, canActivate: [AuthGuard]},
            {path: 'artikel/:id', component: MaterialDetailsComponent, canActivate: [AuthGuard]},
            {path: 'lieferanten', component: SupplierComponent, canActivate: [AuthGuard]},
            {path: 'lieferanten/:id', component: SupplierDetailsComponent, canActivate: [AuthGuard]},
            {path: 'chargen', component: ChargesComponent, canActivate: [AuthGuard]},
            {path: 'lager', component: StorageComponent, canActivate: [AuthGuard]},
            {path: 'lagerplaetze', component: StoragePlaceComponent, canActivate: [AuthGuard]},
            {
                path: 'inventur',
                loadChildren: () => import('./inventur/inventur.module').then(m => m.InventurModule),
                canActivate: [AuthGuard]
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class WarehouseRoutingModule {
}
