<div class="container">
    <div class="detail-block">
        <h2>Daten</h2>
        <div class="detail-block-content">
            <span class="field-title">Datum: </span>
            <div>{{zeitslot_buchung.datum_uhrzeit_von | date: "dd.MM.YYYY"}}</div>
            <span class="field-title">Uhrzeit: </span>
            <div>{{zeitslot_buchung.datum_uhrzeit_von | date: "HH:mm"}} - {{zeitslot_buchung.datum_uhrzeit_bis | date: "HH:mm"}} Uhr</div>
            <span class="field-title">Tierzahl: </span>
            <div>{{zeitslot_buchung.tierzahl}}</div>
        </div>
    </div>
    <div class="grid grid-2-columns gap-10">
        <div *ngIf="landwirt" class="detail-block">
            <h2>Landwirt</h2>
            <div class="detail-block-content">
                <span class="field-title">Name: </span>
                <div>{{landwirt.bezeichnung}}</div>
                <span class="field-title">VVVO: </span>
                <div>{{landwirt.vvvo}}</div>
                <span class="field-title">Telefon: </span>
                <div>{{landwirt.telefon_1}}</div>
                <span class="field-title">Mobil: </span>
                <div>{{landwirt.mobil_1}}</div>
                <span class="field-title">Fax: </span>
                <div>{{landwirt.fax}}</div>
                <span class="field-title">e-Mail: </span>
                <div>{{landwirt.email}}</div>
                <span class="field-title">Adresse:</span>
                <div>
                    <div>{{landwirt.strasse}}</div>
                    <div>{{landwirt.plz}} {{landwirt.ortsteil}} {{landwirt.ort}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="schlachthof" class="detail-block">
            <h2>Schlachthof</h2>
            <div class="detail-block-content">
                <span class="field-title">Name: </span>
                <div>{{schlachthof.bezeichnung}}</div>
                <span class="field-title">VVVO: </span>
                <div>{{schlachthof.vvvo}}</div>
                <span class="field-title">Telefon: </span>
                <div>{{schlachthof.telefon_1}}</div>
                <span class="field-title">Mobil: </span>
                <div>{{schlachthof.mobil_1}}</div>
                <span class="field-title">Fax: </span>
                <div>{{schlachthof.fax}}</div>
                <span class="field-title">e-Mail: </span>
                <div>{{schlachthof.email}}</div>
                <span class="field-title">Adresse:</span>
                <div>
                    <div>{{schlachthof.strasse}}</div>
                    <div>{{schlachthof.plz}} {{schlachthof.ort}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="haendler" class="detail-block">
            <h2>Viehändler</h2>
            <div class="detail-block-content">
                <span class="field-title">Name: </span>
                <div>{{haendler.bezeichnung}}</div>
                <span class="field-title">VVVO: </span>
                <div>{{haendler.vvvo}}</div>
                <span class="field-title">Telefon: </span>
                <div>{{haendler.telefon_1}}</div>
                <span class="field-title">Mobil: </span>
                <div>{{haendler.mobil_1}}</div>
                <span class="field-title">Fax: </span>
                <div>{{haendler.fax}}</div>
                <span class="field-title">e-Mail: </span>
                <div>{{haendler.email}}</div>
                <span class="field-title">Adresse:</span>
                <div>
                    <div>{{haendler.strasse}}</div>
                    <div>{{haendler.plz}} {{haendler.ortsteil}} {{haendler.ort}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="spediteur" class="detail-block">
            <h2>Spediteur</h2>
            <div class="detail-block-content">
                <span class="field-title">Name: </span>
                <div>{{spediteur.bezeichnung}}</div>
                <span class="field-title">VVVO: </span>
                <div>{{spediteur.vvvo}}</div>
                <span class="field-title">Telefon: </span>
                <div>{{spediteur.telefon_1}}</div>
                <span class="field-title">Mobil: </span>
                <div>{{spediteur.mobil_1}}</div>
                <span class="field-title">Fax: </span>
                <div>{{spediteur.fax}}</div>
                <span class="field-title">e-Mail: </span>
                <div>{{spediteur.email}}</div>
                <span class="field-title">Adresse:</span>
                <div>
                    <div>{{spediteur.strasse}}</div>
                    <div>{{spediteur.plz}} {{spediteur.ort}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="fahrerData" class="detail-block">
            <h2>Fahrer</h2>
            <div class="detail-block-content">
                <span class="field-title">Name: </span>
                <div>{{fahrerData.bezeichnung}}</div>
                <span class="field-title">Adresse: </span>
                <div>
                    <div>{{fahrerData.strasse}}</div>
                    <div>{{fahrerData.plz}} {{fahrerData.ort}}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="flex-grid buchungs-buttons" *ngIf="!offen">
    <button class="icon-btn pdf-btn" (click)="leseLieferschein()" *ngIf="!lieferscheinNichtAusgefuellt; else disabledButton">
        <img class="icon-btn-pdf" src="../../../../assets/pdf.svg">
        Lieferschein anschauen
    </button>
    <ng-template #disabledButton>
        <button class="icon-btn pdf-btn disabled" disabled>
            <img class="icon-btn-pdf" src="../../../../assets/pdf.svg">
            Lieferschein anschauen
        </button>
    </ng-template>

</div>

<div class="flex-grid buchungs-buttons" *ngIf="!vonHaendlerBeauftragt">
    <button mat-raised-button color="primary" [disabled]="lieferscheinNichtAusgefuellt" (click)="beauftragen()">
        <mat-icon>navigate_next</mat-icon>
        {{beauftragenButton}}
    </button>
</div>

<div class="spediteur-buchung" *ngIf="chooseTransporteur">
    <div class="flex-grid buchungs-buttons" >
        <mat-select placeholder="Spediteur auswählen" [formControl]="spediteurFormControl">
            <mat-option *ngFor="let spediteur of spediteure" [value]="spediteur.kunde_id">{{spediteur._bezeichnung}}</mat-option>
        </mat-select>
        <mat-select placeholder="Fahrer wählen" [formControl]="fahrerFormControl">
            <mat-option *ngFor="let driver of fahrer" [value]="driver.benutzer_id">{{ driver.vorname }} {{ driver.nachname }}</mat-option>
        </mat-select>
    </div>
    <div class="flex-grid buchungs-buttons spediteur-beauftragen-button">
        <button mat-raised-button color="primary" [disabled]="!beauftragenEnabled" (click)="beauftragen()">
            <mat-icon>navigate_next</mat-icon>
            Beauftragen
        </button>
    </div>
</div>

<div class="flex-grid verzoegerung" *ngIf="vonHaendlerBeauftragt">
    <button mat-raised-button color="primary" (click)="reportVerzoegerung()" [disabled]="!canReportVerzoegerung">
        <mat-icon>alarm</mat-icon>
        Verzögerung melden
    </button>
    <div *ngIf="zeitslot_buchung.verzoegert" class="verzoegerung-status">
        Der Transport ist Verzögert!
    </div>
</div>

<div class="flex-grid buchungs-buttons" *ngIf="offen">
    
    <button class="icon-btn accept-btn reaction-btn" (click)="acceptBuchung()">
        <mat-icon>check_circle</mat-icon>
        Zusagen
    </button>
    
    <button class="icon-btn deny-btn reaction-btn" (click)="denyBuchung()">
        <mat-icon>cancel</mat-icon>
        Ablehnen
    </button>

</div>