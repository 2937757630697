import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TourenplanComponent} from './tourenplan.component';
import {AddArticlePositionDialogComponent} from './add-article-position-dialog/add-article-position-dialog.component';
import {AddTourDialogComponent} from './add-tour-dialog/add-tour-dialog.component';
import {EditTourPageComponent} from './edit-tour-page/edit-tour-page.component';
import {GoogleMapsRouteComponent} from './google-maps-route/google-maps-route.component';
import {KWDialogComponent} from './kwdialog/kwdialog.component';
import {AssignVetToTourDialogComponent} from './assign-vet-to-tour-dialog/assign-vet-to-tour-dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {KieneAutocompleteModule} from '../kiene-autocomplete/kiene-autocomplete.module';
import {MatSelectModule} from '@angular/material/select';
import {ArticleScanAutocompleteModule} from '../article-scan-autocomplete/article-scan-autocomplete.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {SharedModule} from '../../shared/shared.module';
import {MatGridListModule} from '@angular/material/grid-list';
import {DirectivesModule} from '../../../../projects/kiene-core/src/lib/directives/directives.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule} from '@angular/material/card';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatBadgeModule} from '@angular/material/badge';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ComponentsModule} from '../components.module';
import {KieneClientAutocompleteModule} from '../client-autocomplete/client-autocomlete.module';
import {MatDividerModule} from '@angular/material/divider';


@NgModule({
    declarations: [TourenplanComponent, AddArticlePositionDialogComponent, AddTourDialogComponent, EditTourPageComponent, GoogleMapsRouteComponent, KWDialogComponent, AssignVetToTourDialogComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        MatSlideToggleModule,
        KieneAutocompleteModule,
        MatSelectModule,
        ArticleScanAutocompleteModule,
        MatDatepickerModule,
        SharedModule,
        MatGridListModule,
        DirectivesModule,
        MatTooltipModule,
        MatCardModule,
        MatPaginatorModule,
        MatBadgeModule,
        MatMenuModule,
        MatCheckboxModule,
        ComponentsModule,
        KieneClientAutocompleteModule,
        MatDividerModule
    ],
    exports: [TourenplanComponent]
})
export class TourenplanModule {
}
