import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Haendler} from '../haendler';
import {UntypedFormControl} from '@angular/forms';
import {HaendlerUebersichtComponent} from '../haendler-uebersicht/haendler-uebersicht.component';
import {StammdatenService} from '../../stammdaten.service';
import {MessageService} from '../../../../services/message-service/message.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
    selector: 'kiene-haendler-verwaltung',
    templateUrl: './haendler-verwaltung.component.html',
    styleUrls: ['./haendler-verwaltung.component.scss']
})
export class HaendlerVerwaltungComponent implements OnInit, OnDestroy {

    alleHaendlerSubscription: Subscription;

    haendlerAnlegen: number[] = [9142];
    alleHaendler: Haendler[] = [];
    haendlerCtrls: UntypedFormControl[] = [];
    haendlerNameCtrl: UntypedFormControl = new UntypedFormControl('');

    @ViewChild('favoriten')
    favoritenList: HaendlerUebersichtComponent;

    constructor(private stammdatenService: StammdatenService, private messageService: MessageService,
                private router: Router, private route: ActivatedRoute) {
    }

    ngOnDestroy(): void {
        this.alleHaendlerSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.ladeAlleHaendler();
    }

    neuenHaendlerErstellen(): void {
    }

    ladeAlleHaendler(): void {
        this.alleHaendlerSubscription?.unsubscribe();
        this.alleHaendlerSubscription = this.stammdatenService.loadHaendler().subscribe({
            next: (data) => {
                console.debug(data);
                this.alleHaendler = data.records;
                this.initHaendlerCtrls();
            },
            error: (err: any) => {
                this.messageService.errorMessage('Fehler beim Laden aller Haendler', err);
            }
        });
    }

    initHaendlerCtrls() {
        this.haendlerCtrls = [];
        this.alleHaendler.forEach(haendler => {
            this.haendlerCtrls[haendler.viehhaendler_id] = new UntypedFormControl(!!haendler.favorit);
        });
    }


    editiereHaendler(haendler: Haendler): void {
        this.router.navigate(['../details', haendler.viehhaendler_id], {relativeTo: this.route}).then();
    }


    aendereFavorit(haendler: Haendler) {

        this.haendlerNameCtrl.setValue(null);

        haendler.favorit = this.haendlerCtrls[haendler.viehhaendler_id].value ? 1 : 0;

        this.stammdatenService.updateHaendlerFavorit(haendler).subscribe({
            next: (favHaendler) => {
                this.messageService.infoMessage('Favoritenstatus geändert');
                // this.favoritenList.updateFavoriten(favHaendler);
            },
            error: (err: any) => {
                this.messageService.errorMessage('Fehler beim Ändern des Favoritenstatus', err);
            }
        });
    }

    haendlerHinzufuegen() {
        this.router.navigate(['../details'], {relativeTo: this.route}).then();
    }
}
