export class Tierliste {
    anzahl_tiere: number;
    abgang_melden: number;
    einstallen_melden: number;
    erstellt: Date;
    erstellt_von: number;
    gewicht_gesamt_abgang: number;
    gewicht_gesamt_einstallen: number;
    tierliste_id: number;
    kommentar: string;
    gruppe_id_abgang: number;
    gruppe_id_einstallen: number;
    gruppe_abgang: string;
    gruppe_einstallen: string;
    datum_abgang: Date;
    datum_einstallen: Date;
    status_id: number;
    status: string;
    kunde_id_abgang: number;
    kunde_abgang: string;
    kunde_zusatz_abgang: string;
    kunden_abgang: string;

    kunde_id_einstallen: number;
    kunde_einstallen: string;
    kunde_zusatz_einstallen: string;
    kunde_vvvo_einstallen: string;
    kunde_strasse_einstallen: string;
    kunde_ort_einstallen: string;
    kunde_plz_einstallen: string;

    kunde_id_zielbetrieb: number;
    kunde_zusatz_zielbetrieb: string;
    kunde_zielbetrieb: string;
    kunde_vvvo_zielbetrieb: string;
    kunde_strasse_zielbetrieb: string;
    kunde_plz_zielbetrieb: string;
    kunde_ort_zielbetrieb: string;

    kunde_id_schlachthof: number;
    kunde_zusatz_schlachthof: string;
    kunde_schlachthof: string;
    kunde_vvvo_schlachthof: string;
    kunde_strasse_schlachthof: string;
    kunde_plz_schlachthof: string;
    kunde_ort_schlachthof: string;
    kunde_email_schlachthof: string;

    reklamationen_weitergeben: number;
    import_einstallen: number;
    mehrere_gruppen: number;
    haendler_uebernehmen: number;
    viehhaendler_id: number;
    tierliste_extern: number;
    kfz: string;
}
