export class ArtikelTierart {
    artikel: string;
    artikel_id: number;
    at_id: number;
    behandlung_tage: number;
    dosierung_txt: string;
    einheit: string;
    einheit_id: number;
    intervall_std: number;
    faktor: number;
    koerpergewicht: number;
    menge: number;
    recposition: number;
    tierart: string;
    tierart_id: number;
    mandanten_ids: number[] = [];
}

export interface ArtikelTierartApi {
    records: ArtikelTierart[];
    count: number;
}

export class ArtikelTierartEvent {
    artikelTierart: ArtikelTierart;
    ladePosition: boolean = false;

    constructor(
        artikelTierart: ArtikelTierart,
        options?: { ladePosition?: boolean }
    ) {
        this.artikelTierart = artikelTierart;
        if (options?.ladePosition === true) {
            this.ladePosition = true;
        }
    }
}
