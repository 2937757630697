import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Gruppe } from '../classes/gruppe';
import { EnterValueInput, EnterValueResponse } from '../../dialogs/enter-value-dialog/enter-value-response';
import { EnterValueDialogComponent } from '../../dialogs/enter-value-dialog/enter-value-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { MessageService } from '../../../services/message-service/message.service';
import { Router } from '@angular/router';
import { AusstallenService } from '../../ausstallen/ausstallen.service';
import { ConfirmDialogInput, ConfirmDialogResult } from '../../dialogs/confirm-dialog/confirm-dialog';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { GruppenService } from '../../gruppen/gruppen.service';
import { EinstallenMeldenDialogInput, EinstallenMeldenDialogResult } from '../../gruppen/classes/einstallen-melden';
import {
    EinstallenMeldenDialogComponent
} from '../../gruppen/gruppe-einstallen/einstallen-melden-dialog/einstallen-melden-dialog.component';
import {
    GruppeInformationenDialogComponent
} from '../../gruppen/gruppe-kommentar-dialog/gruppe-informationen-dialog.component';
import {
    GruppeStandortWechselnDialogResult
} from '../../gruppen/gruppe-standort-wechseln-dialog/gruppe-standort-wechseln-dialog-result';

import {
    GruppeStandortWechselnDialogComponent
} from '../../gruppen/gruppe-standort-wechseln-dialog/gruppe-standort-wechseln-dialog.component';
import { GruppeTierService } from '../../gruppe-tier/gruppe-tier.service';

@Component({
    selector: 'kiene-gruppen-menu',
    templateUrl: './gruppen-menu.component.html',
    styleUrls: ['./gruppen-menu.component.scss'],
})
export class GruppenMenuComponent implements OnInit {
    @Input('gruppe') gruppe: Gruppe;
    @Input('historie') historie = false;

    @Output('changed') changed = new EventEmitter<boolean>();

    constructor(
        private dialog: MatDialog,
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private messageService: MessageService,
        private router: Router,
        private ausstallenService: AusstallenService,
        private gruppenService: GruppenService,
        private gruppeTierService: GruppeTierService
    ) {
    }

    ngOnInit(): void {
    }

    emitChange() {
        console.log('GruppeMenu: change happens');
        this.changed.emit(true);
    }

    bearbeiten() {
        const input = new EnterValueInput();
        input.title = 'Gruppennamen';
        input.text = 'Bitte geben Sie den Gruppennamen ein.';
        input.okButton = 'Speichern';
        input.buttonColor = 'accent';
        input.addInputValue('Gruppennamen', 'text', this.gruppe.bezeichnung);

        const dialogRef = this.dialog.open(EnterValueDialogComponent, {
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: EnterValueResponse) => {
                if (result?.result) {
                    this.gruppe.bezeichnung = result.getValue(0);
                    this.api
                        .updateGeneric(this.apiBaseUrl + 'gruppe/update.php', this.gruppe)
                        .subscribe({
                            next: (response) => {
                                this.messageService.infoMessage(
                                    'Der Gruppenname wurde erfolgreich gespeichert!'
                                );
                                this.emitChange();
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    tiereHinzufuegen() {
        this.router
            .navigate([
                '/gruppen/tierehinzufuegen',
                this.gruppe.gruppe_id.toString(),
            ])
            .then();
    }

    ausstallen() {
        this.ausstallenService.ausstallen(this.gruppe, false, undefined);
    }

    standortwechsel() {
        const dialogRefStandortWechsel = this.dialog.open(
            GruppeStandortWechselnDialogComponent,
            {
                data: {
                    gruppe: this.gruppe,
                },
            }
        );
        dialogRefStandortWechsel.afterClosed().subscribe({
            next: (result: GruppeStandortWechselnDialogResult) => {
                if (result && result.confirm) {
                    this.emitChange();
                }
            },
        });
    }

    ausstalllistenAnzeigen() {
        this.router.navigate(['/gruppen/abgang', this.gruppe.gruppe_id]).then();
    }

    tiereNachladen() {
        const input = new ConfirmDialogInput();
        input.headline = 'Tiere aus HIT laden';
        input.text =
            'Sollen Tiere im angegeben Einstallzeitraum in HIT gesucht werden?';
        input.okButton = 'Tiere laden';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    const snackbarRef = this.messageService.infoMessage(
                        'Tiere werden aus HI-Tier geladen. Dies kann einen Moment dauern.'
                    );
                    this.gruppenService
                        .tiereAusHitNachladen(this.gruppe)
                        .subscribe({
                            next: () => {
                                snackbarRef?.dismiss();
                                this.messageService.infoMessage(
                                    'Tiere wurden erfolgreich aus HI-Tier nachgeladen!'
                                );
                                this.emitChange();
                            },
                            error: (err) => {
                                snackbarRef?.dismiss();
                                this.messageService.errorMessage(
                                    'Fehler!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    abgaengeMelden() {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                okButton: 'Melden',
                text: 'Sollen alle Tiere bei HI-Tier abgemeldet werden, die sich in einer abgeschlossenen Tierliste befinden?',
                headline: 'Abgänge melden',
                buttonColor: 'accent',
            },
        });
        dialogRef.afterClosed().subscribe((resp: ConfirmDialogResult) => {
            if (resp?.confirmed) {
                this.api
                    .updateGeneric(
                        this.apiBaseUrl + 'gruppe_tier/abgaenge_hit.php',
                        this.gruppe
                    )
                    .subscribe({
                        next: () => {
                            this.messageService.infoMessage(
                                'Tiere werden bei HIT abgemeldet!'
                            );
                            this.emitChange();
                        },
                        error: (err) => {
                            this.messageService.errorMessage(
                                'Tiere können nicht abgemeldet werden!',
                                err
                            );
                        },
                    });
            }
        });
    }

    zugaengeMelden() {
        const input = new EinstallenMeldenDialogInput();
        input.gruppe_id = this.gruppe?.gruppe_id;

        const dialogRef = this.dialog.open(EinstallenMeldenDialogComponent, {
            width: '90vw',
            minWidth: '90vw',
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: EinstallenMeldenDialogResult) => {
                if (result?.ok) {
                    this.emitChange();
                }
            },
        });
    }

    zertifikateAnSGS() {
        this.gruppenService.zertifikateAnSGS({ ganzeGruppe: { gruppe_id: this.gruppe?.gruppe_id } });
    }

    openGruppeInformationen() {
        const dialogRef = this.dialog.open(GruppeInformationenDialogComponent, {
            width: '90vw',
            data: {
                gruppe_id: this.gruppe.gruppe_id,
            },
        });
        dialogRef.afterClosed().subscribe({
            next: (result) => {
                if (result) {
                    this.emitChange();
                }
            },
        });
    }

    csvExport() {
        // const url = this.apiBaseUrl + 'gruppe_tier/read_csv.php';
        // const params = new HttpParams().set('gruppe_id', this.gruppe?.gruppe_id.toString());
        // const filename = 'Tiere Gruppe ' + this.gruppe.bezeichnung + '.csv';
        // this.csvDownloadService.downloadCsv(url, params, filename);
        this.gruppenService.exportCSV(this.gruppe, true);
    }

    excelExport() {
        this.gruppenService.exportExcel(this.gruppe, true);
    }

    reklamationenPdfDownload() {
        this.gruppenService.openReklamationPdfDialog(this.gruppe);
    }

    abgaengePdfDownload() {
        this.gruppenService.openGruppeAbgangDownloadDialog({
            gruppe: this.gruppe,
        });
    }

    gruppeInHistorie() {
        const input = new ConfirmDialogInput();
        input.headline = 'Gruppe in die Historie verschieben?';
        input.text = `Möchten Sie die Gruppe ${this.gruppe?.bezeichnung} wirklich in die Historie schieben?`;
        input.okButton = 'Verschieben';
        this.gruppeHistorieAendern(input, true);
    }

    gruppeAusHistorie() {
        const input = new ConfirmDialogInput();
        input.headline = 'Gruppe aus der Historie holen?';
        input.text = `Möchten Sie die Gruppe ${this.gruppe?.bezeichnung} wirklich wieder nach "aktuelle Gruppen" verschieben?`;
        input.okButton = 'Verschieben';
        this.gruppeHistorieAendern(input, false);
    }

    gruppeHistorieAendern(input: ConfirmDialogInput, historie: boolean) {
        this.dialog.open(ConfirmDialogComponent, {
            data: input
        }).afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.gruppenService
                        .historieAendern(this.gruppe, historie)
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Die Gruppe wurde erfolgreich verschoben!'
                                );
                                this.changed.emit(true);
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Die Gruppe konnte nicht verschoben werden!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    deleteGruppe() {
        const input = new ConfirmDialogInput();
        input.headline = 'Gruppe unwiderruflich löschen?';
        input.text = `Möchten Sie die Gruppe ${this.gruppe?.bezeichnung} wirklich löschen?`;
        input.okButton = 'Löschen';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.gruppenService.deleteGruppe(this.gruppe).subscribe({
                        next: () => {
                            this.messageService.infoMessage(
                                'Die Gruppe wurde erfolgreich gelöscht!'
                            );
                            this.changed.emit(true);
                        },
                        error: (err) => {
                            this.messageService.errorMessage(
                                'Die Gruppe konnte nicht gelöscht werden!',
                                err
                            );
                        },
                    });
                }
            },
        });
    }

    public ohrmarkenGescanntMarkieren() {
        this.gruppeTierService.ohrmarkeAlsGescanntMarkieren(this.gruppe?.gruppe_id, null).subscribe({
            next: (b: boolean) => {
                if (b) {
                    this.emitChange();
                }
            }
        });
    }

}
