import { Inject, Injectable } from '@angular/core';
import { Benachrichtigung, BenachrichtigungInfo } from './classes/benachrichtigung';
import { Observable, Subject } from 'rxjs';
import { API_BASE_URL_SERVICE } from '../kiene-core.config';
import { HttpParams } from '@angular/common/http';
import { KieneApi, KieneBackendApiService } from '../services/backend-api/kiene-backend-api.service';
import {
    BenachrichtigungClickEvent
} from './classes/benachrichtigung-click-event';
import { MessageService } from '../services/message-service/message.service';
import { ConfirmDialogInput, ConfirmDialogResult } from '../components/dialogs/confirm-dialog/confirm-dialog';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../components/dialogs/confirm-dialog/confirm-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class BenachrichtigungenService {

    benachrichtigungen: Benachrichtigung[] = [];
    benachrichtigungSubject: Subject<Benachrichtigung[]> = new Subject();
    benachrichtigungSelectedSubject: Subject<Benachrichtigung> = new Subject();
    ungelesenSubject: Subject<number> = new Subject();
    benachrichtigungClickEventSubject = new Subject<BenachrichtigungClickEvent>();
    reloadSubject = new Subject<boolean>();

    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private baseUrl: string,
        private messageService: MessageService,
        private dialog: MatDialog
    ) {
    }

    markBenachrichtigungAsRead(benachrichtigung: Benachrichtigung): Observable<any> {
        return this.api.updateGeneric(`${this.baseUrl}benachrichtigung/update_gelesen.php`, benachrichtigung);
    }

    getBenachrichtigungen(filter?: { search?: any; gelesen?: number; offset?: number; limit?: number }) {
        let params = new HttpParams();
        params = params.append('orderby', 'erstellt desc');
        if (filter?.search) {
            params = params.append('searchText', filter.search);
        }
        if (filter?.gelesen >= 0) {
            params = params.append('gelesen', filter.gelesen);
        }
        if (filter?.offset >= 0) {
            params = params.append('offset', filter.offset);
        }
        if (filter?.limit) {
            params = params.append('limit', filter.limit);
        }
        return this.api.getAll(`${this.baseUrl}benachrichtigung/read.php`, params);
    }

    markAllBenachrichtigungAsRead(): Observable<any> {
        return this.api.updateGeneric(this.baseUrl + 'benachrichtigung/update_gelesen.php', { alle: 1 });
    }

    setBenachrichtigungen(benachrichtigungen: Benachrichtigung[]) {
        this.benachrichtigungen = benachrichtigungen;
        this.benachrichtigungSubject.next(benachrichtigungen);
    }

    watchBenachrichtigungen() {
        return this.benachrichtigungSubject.asObservable();
    }

    watchReload() {
        return this.reloadSubject.asObservable();
    }

    triggerReload() {
        return this.reloadSubject.next(true);
    }

    setBenachrichtigungSelected(benachrichtigung: Benachrichtigung) {
        this.benachrichtigungSelectedSubject.next(benachrichtigung);
    }

    watchBenachrichtigungSelected() {
        return this.benachrichtigungSelectedSubject.asObservable();
    }

    setUngelesen(ungelesen: number) {
        this.ungelesenSubject.next(ungelesen);
    }

    watchUngelesenSubject() {
        return this.ungelesenSubject.asObservable();
    }

    getUngeleseneBenachrichtigungenCount(): Observable<BenachrichtigungInfo> {
        return this.api.get(`${this.baseUrl}benachrichtigung/read_ungelesen.php`);
    }

    fireBenachrichtiungClickEvent(event: BenachrichtigungClickEvent) {
        console.log('fireBenachrichtiungClickEvent');
        this.benachrichtigungClickEventSubject.next(event);
    }

    watchBenachrichtigungClickEvent(): Observable<BenachrichtigungClickEvent> {
        return this.benachrichtigungClickEventSubject.asObservable();
    }

    showPopup(): Observable<boolean> {
        const subject = new Subject<boolean>();
        const params = new HttpParams().set('popup', 1).set('gelesen', 0);
        this.api.getAll(this.baseUrl + 'benachrichtigung/read.php', params).subscribe({
            next: (records: KieneApi) => {
                if (records?.records?.length > 0 && this.dialog.openDialogs?.length == 0) {
                    const benachrichtigung: Benachrichtigung = records.records[0];

                    const input = new ConfirmDialogInput();
                    input.headline = benachrichtigung.titel;
                    input.text = benachrichtigung.txt;
                    input.okButton = 'OK';
                    input.showCancel = false;

                    this.dialog.open(ConfirmDialogComponent, { data: input }).afterClosed().subscribe({
                        next: (result: ConfirmDialogResult) => {
                            if (result?.confirmed) {
                                this.markBenachrichtigungAsRead(benachrichtigung).subscribe({
                                    next: () => {
                                        this.triggerReload();
                                        subject.next(true);
                                    },
                                    error: err => {
                                        this.messageService.errorMessage('Fehler!', err);
                                        subject.next(false);
                                    }
                                });
                            } else {
                                subject.next(false);
                            }
                        }
                    });
                } else {
                    subject.next(false);
                }
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
        return subject;
    }
}

