import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CsvDownloadComponent} from './csv-download/csv-download.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
    declarations: [
        CsvDownloadComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        CsvDownloadComponent
    ]
})
export class CsvDownloadModule {
}
