export class TierwohlprotokollSchwein {
    abrechnen: number;
    abgabebeleg_id: number;
    twp_schwein_id: number;

    datum: Date;

    status_id: number;
    status: string;

    tierarzt_id: number;
    tierarzt_vorname: string;
    tierarzt_nachname: string;

    kunde_id: number;
    kunde: string;
    kunde_ort: string;
    kunde_plz: string;
    kunde_strasse: string;
    kunde_telefon_1: string;
    kunde_vvvo: string;

    anzahl_sauen: number;
    anzahl_eber: number;
    anzahl_ferkel: number;
    anzahl_mastschweine: number;

    verluste_saugferkel: string;
    verluste_aufzuchtferkel: string;
    verluste_sauen: string;
    verluste_abortrate: string;
    verluste_totgeburten: string;
    verluste_mastschweine: string;

    biologisch_umrauscherrate: string;
    biologisch_wurfzahl_abgegangene_sauen: number;
    biologisch_lebendgeborene_ferkel: number;
    biologisch_aufgezogene_ferkel: number;
    biologisch_tageszunahmen_mastschweine: number;

    gesundheitsstatus_prrs: number;
    gesundheitsstatus_app: number;
    bestand_klinisch_ohne_befund: number;
    hinweise_tierseuchen: number;

    mangagementfaktor_haltungssystem: number;
    managementfaktor_fuetterung_wasserversorgung: number;
    managementfaktor_betrieb: number;
    managementfaktor_hygiene: number;
    managementfaktor_zucht: number;
    managementfaktor_sonstiges: number;

    gesundheitsstatus_atmungsorgane: number;
    gesundheitsstatus_verdauungsapparat: number;
    gesundheitsstatus_bewegungsapparat: number;
    gesundheitsstatus_haut_kondition: number;
    gesundheitsstatus_sonstiges: number;

    verdachtsdiagnosen: string;

    diagnostische_massnahmen_sektion: number;
    diagnostische_massnahmen_kot: number;
    diagnostische_massnahmen_resistenztest: number;
    diagnostische_massnahmen_hautgeschabsel: number;
    diagnostische_massnahmen_blut: number;
    diagnostische_massnahmen_futter_wasser: number;
    diagnostische_massnahmen_tupfer: number;
    diagnostische_massnahmen_sonstiges: number;
    diagnostische_massnahmen_sonstiges_txt: string;

    salmonellen_monitoring: number;
    salmonellen_monitoring_kategorie: string;
    salmonellen_monitoring_txt: string;

    antibiotika_monitoring: number;
    antibiotika_monitoring_therapieindex: string;
    antibiotika_monitoring_txt: string;

    schlacht_befunddaten: number;
    schlacht_befunddaten_txt: string;

    betriebliche_eigenkontrolle: number;
    betriebliche_eigenkontrolle_txt: string;

    tierschutzindikatoren: number;
    tierschutzindikatoren_txt: string;

    stallklima_kontrolliert: number;

    impfprogramm_influenza: number;
    impfprogramm_prrs: number;
    impfprogramm_rotlauf: number;
    impfprogramm_circo: number;
    impfprogramm_rhinitis_atr: number;
    impfprogramm_ileitis: number;
    impfprogramm_haemophilus: number;
    impfprogramm_mycoplasmen: number;
    impfprogramm_app: number;
    impfprogramm_parvo: number;
    impfprogramm_sonstiges: number;
    impfprogramm_sonstiges_txt: string;

    parasitologische_massnahmen_endoparasiten: number;
    parasitologische_massnahmen_endoparasiten_zuletzt: Date;

    parasitologische_massnahmen_ektoparasiten: number;
    parasitologische_massnahmen_ektoparasiten_zuletzt: Date;

    einschaetzung_auffaelligkeiten_bestandsprobleme: string;
    empfehlungen_massnahmen: string;
    weitere_bemerkungen: string;

    plan_tiergesundheit_hygienemanagement: number;
    massnahmenplan_einzelaktivitaeten: number;

    durchfuehrung_bis: Date;
    naechster_bestandsbesuch: Date;
}
