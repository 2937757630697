import { Component, Inject, OnInit } from '@angular/core';
import { TableDescriptor } from '../../../components/kiene-table/table-descriptor';
import { TableOption, TableOptionEvent } from '../../../components/kiene-table/table-option';
import { DateTableColumn, NumberTableColumn, TableColumn } from '../../../components/kiene-table/table-column';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangeCurrentClientDialogComponent } from '../../../administration/client/change-current-client/change-current-client-dialog/change-current-client-dialog.component';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { GruppeDialogInput } from '../../../documents/gruppenverwaltung/gruppenuebersicht/gruppe-dialog/gruppe-dialog';
import { Gruppe } from '../../../documents/gruppenverwaltung/gruppe';
import { GruppeDialogComponent } from '../../../documents/gruppenverwaltung/gruppenuebersicht/gruppe-dialog/gruppe-dialog.component';
@Component({
    selector: 'kiene-aktuelle-gruppen-schweine',
    templateUrl: './aktuelle-gruppen-schweine.component.html',
    styleUrls: ['./aktuelle-gruppen-schweine.component.scss']
})
export class AktuelleGruppenSchweineComponent implements OnInit {

    tableDescriptor = new TableDescriptor();
    kundeColumn: TableColumn;
    kundeZusatzColumn: TableColumn;

    constructor(
        protected _router: Router,
        private dialog: MatDialog,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) { }

    ngOnInit(): void {
        this.tableDescriptor.headline = 'Aktuelle Gruppen (Schweine)';
        this.tableDescriptor.alternativeApiUrl =
            this.apiBaseUrl + 'gruppe-schweine/read_akt_kundenportal.php';
        this.tableDescriptor.initialSortDirection = 'desc';
        this.tableDescriptor.initialSortColumn = 'einstalldatum_von';
        this.tableDescriptor.nameOfIdField = 'gruppe_id';
        this.tableDescriptor.route = '/gruppen/schweine-gruppen/';
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDownloads = true;
        this.tableDescriptor.sendCurrentClient = true;

        this.tableDescriptor.tooltips['add_button'] = 'Neue Gruppe anlegen';

        this.tableDescriptor.columns = this.getColumns();
        this.tableDescriptor.options = this.getOptions();

        this.tableDescriptor.permissionCreate = 9112;
        this.tableDescriptor.permissionUpdate = 9112;
    }

    getColumns(): TableColumn[] {
        const columns = [];
        columns.push(new TableColumn('bezeichnung', 'Gruppenname'));
        columns.push(new TableColumn('herdenname', 'Herdenname'));

        columns.push(new TableColumn('stall', 'Standort'));

        this.kundeColumn = new TableColumn('kunde', 'Kunde');
        this.kundeZusatzColumn = new TableColumn('zusatz', 'Kunde Zusatz');
        /*if (this.localStorageService.getCurrentClient()) {
            this.kundeColumn.isVisible = false;
            this.kundeZusatzColumn.isVisible = false;
        }*/
        columns.push(this.kundeColumn);
        columns.push(this.kundeZusatzColumn);

        columns.push(
            new DateTableColumn(
                'einstalldatum_von',
                'Einstalldatum von',
                'dd.MM.yy'
            )
        );
        columns.push(
            new DateTableColumn(
                'einstalldatum_bis',
                'Einstalldatum bis',
                'dd.MM.yy'
            )
        );
        columns.push(
            new TableColumn('anzahl_tiere_gesamt', 'Eingestallte Tiere')
        );
        columns.push(
            new TableColumn('anzahl_tiere_aktuell', 'Anzahl Tiere aktuell')
        );
        columns.push(new TableColumn('alter_wochen', 'Tieralter in Wo.'));
        columns.push(new TableColumn('anzahl_tote', 'Tote'));
        columns.push(
            new NumberTableColumn('verluste', 'Verluste', '1.1-1', '%')
        );
        columns.push(new TableColumn('anzahl_abgaenge', 'Abgänge'));

        return columns;
    }

    getOptions() {
        const options: TableOption[] = [];
        options.push(
            new TableOption(
                'schlachten',
                'Zur Schlachtung bringen',
                'description',
                9117
            )
        )
        return options;
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'add') {
            this.createGruppe();
        } else if (event.name == "schlachten") {
            this._router.navigate(['/gruppen', 'zeitslot-buchen', event.value['gruppe_id']]).then();
        }

    }

    createGruppe() {
        if (!this.localStorageService.getCurrentClient()) {
            this.dialog.open(
                ChangeCurrentClientDialogComponent,
                {
                    width: '50%',
                    data: {
                        closeOnSelect: true,
                    },
                }
            ).afterClosed().subscribe((result) => {
                if (result?.save && result?.client) {
                    this.localStorageService.setCurrentClient(result.client);
                    this.createGruppe();
                }
            });
        } else {
            const input = new GruppeDialogInput(this.localStorageService.getCurrentClient(), false, undefined);
            this.dialog.open<GruppeDialogComponent, GruppeDialogInput, Gruppe>(GruppeDialogComponent, {
                data: input
            }).afterClosed().subscribe({
                next: (gruppe: Gruppe) => {
                    if (gruppe) {
                        this._router.navigate(['/gruppen', 'schweine-gruppen', gruppe.gruppe_id]).then();
                    } else {
                        this.tableService.reload();
                    }
                }
            });
        }
    }
}
