import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GoodReceipt} from '../goods-receipt/good-receipt';
import {Stock, StockApi} from './stock';
import {TransferStockObject} from '../bestand/bestand-element';
import {environment} from '../../../environments/environment';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable()
export class StockService {

    constructor(private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    public getAllStocks(sortColumn: string,
                        sortDirection: string,
                        offset: number,
                        limit: number,
                        searchString: string,
                        queryVetOnly: boolean,
                        vetId: number): Observable<StockApi> {
        return this.http.get<StockApi>(this.apiBaseUrl + 'bestand_charge/read.php?orderby='
            + sortColumn + ' ' + sortDirection
            + '&limit=' + limit
            + '&offset=' + offset
            + '&search=' + searchString
            + (queryVetOnly ? '&tierarzt_id=' + vetId : ''));
    }

    transferStock(transferStockObject: TransferStockObject) {
        return this.http.post(this.apiBaseUrl + 'bestand_charge/transfer.php', transferStockObject);
    }

    updateStock(transferStockObject: TransferStockObject) {
        return this.http.post(this.apiBaseUrl + 'bestand_charge/update.php', transferStockObject);
    }

    createStock(goodReceipt: GoodReceipt) {
        return this.http.post(this.apiBaseUrl + 'bestand_charge/create.php', goodReceipt);
    }

    createTierarztBestand(stock: Stock) {
        return this.http.post(environment.BASE_URI + 'bestand_charge/create_tierarzt.php', stock);
    }

    searchStocks(searchString: string): Observable<StockApi> {
        return this.http.get<StockApi>(this.apiBaseUrl + 'bestand_charge/read.php?search=' + searchString);
    }

}
