import { LocalStorageService } from '../../local-storage/local-storage.service';

export class FilterItem {
    type: FilterItemType;
    value: any;
    name: string;
    title: string;
    checked = false;
    permissionId: number = null;
    adminOnly: boolean = false;

    constructor(
        title: string,
        value: any,
        type: FilterItemType,
        name: string,
        checked?: boolean,
        options?: { permissionId?: number; adminOnly?: boolean }
    ) {
        this.title = title;
        this.value = value;
        this.type = type;
        this.name = name;
        this.checked = checked ? true : false;
        if (options) {
            if (options.permissionId) {
                this.permissionId = options.permissionId;
            }
            if (options.adminOnly !== null) {
                this.adminOnly = options.adminOnly;
            }
        }
    }
}

export enum FilterItemType {
    CHECKBOX,
}
