import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Kundengutschrift } from '../gutschrift';

@Component({
  selector: 'kiene-gutschrift-details-dialog',
  templateUrl: './gutschrift-details-dialog.component.html',
  styleUrls: ['./gutschrift-details-dialog.component.css']
})
export class GutschriftDetailsDialogComponent implements OnInit {

  kundengutschrift: Kundengutschrift;

  constructor(public dialogRef: MatDialogRef<GutschriftDetailsDialogComponent>, @Inject(MAT_DIALOG_DATA) private data) { 
    this.kundengutschrift = this.data.kundengutschrift;
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  saved(){
    this.dialogRef.close(true);
  }

}
