<div style="padding: 24px">
    <div *ngIf="!kundeHatRinder" class="warning">Kunde hat keine Rinder</div>

    <div class="one-row">
        <h1>Maßnahmenplan nach § 58 d AMG <u>für die Tierart Rind</u></h1>
        <span class="spacer"></span>
        <button *ngIf="mpr?.status_id !== 62" mat-icon-button color="accent" (click)="save(false)"
                matTooltip="Zwischenspeichern">
            <mat-icon>save</mat-icon>
        </button>
        <button (click)="goBack()" color="accent" mat-button>
            Zur&uuml;ck
        </button>
    </div>

    <div *kienePermission="[permissionAbschliessen]" class="info-box">
        <mat-checkbox [formControl]="abrechnenCtrl">Ma&szlig;nahmenplan abrechnen?</mat-checkbox>
        <span class="spacer"></span>
    </div>

    <kiene-autocomplete (elementSelected)="setTierarzt($event)" *ngIf="showTierarztSelector"
                        [disabled]="!hasPermission(1153)" [displayFields]="['vorname', 'nachname']"
                        [genericApiUrl]="'tierarzt/read_autocomplete.php'"
                        [placeholder]="'Tierarzt'"></kiene-autocomplete>

    <mat-radio-group [formControl]="tierartCtrl" aria-label="Tierart">
        <mat-radio-button [value]="46" class="phito-field">Kälber (zugekauft)</mat-radio-button>
        <mat-radio-button [value]="47" class="phito-field">Milchkühe</mat-radio-button>
    </mat-radio-group>

    <span class="phito-field">(Für jede Nutzungsart bitte einen Plan ausfüllen)</span>

    <br/>

    <mat-form-field class="phito-field">
        <mat-label>Halbjahr</mat-label>
        <input [formControl]="_0_1_2" matInput type="number"/>
    </mat-form-field>
    <mat-form-field class="phito-field">
        <mat-label>Jahr</mat-label>
        <input [formControl]="_0_1_3" matInput type="number"/>
    </mat-form-field>
    <mat-form-field class="phito-field">
        <mat-label>Therapiehäufigkeit</mat-label>
        <input [formControl]="_0_1_4" matInput type="number"/>
    </mat-form-field>

    <!--<p>{{currentClient.bezeichnung}}</p>
  <p>{{currentClient.strasse}}</p>
  <p>{{currentClient.plz}} {{currentClient.ort}}</p>
  <p>{{currentClient.telefon_1}}</p>-->
    <div *ngIf="mpr.abweichende_lieferadresse">
        <p>{{ mpr.kunde }}</p>
        <p>{{ mpr.strasse_lieferung }}</p>
        <p>{{ mpr.plz_lieferung }} {{ mpr.ort_lieferung }}</p>
        <p>{{ mpr.kunde_telefon_1 }}</p>
        <p>VVVO: {{ mpr.vvvo }}</p>
    </div>

    <div *ngIf="!mpr.abweichende_lieferadresse">
        <p>{{ mpr.kunde }}</p>
        <p>{{ mpr.kunde_strasse }}</p>
        <p>{{ mpr.kunde_plz }} {{ mpr.kunde_ort }}</p>
        <p>{{ mpr.kunde_telefon_1 }}</p>
        <p>VVVO: {{ mpr.vvvo }}</p>
    </div>

    <!--

 Abschnitt 1.

 -->
    <ng-container *ngIf="istKaelber() || istMilchkuehe()">
        <h3>1. Angaben zum Betrieb</h3>
        <mat-label>Anzahl Ställe mit Stallbezeichnung und Stallplätzen:</mat-label>
        <table class="phito-table" style="width: 30%; position: relative; left: 24px">

            <tbody>
            <tr>
                <td>1.</td>
                <td>
                    <mat-form-field>
                        <mat-label>Stallnummer/-bezeichnung</mat-label>
                        <input matInput type="text" [formControl]="_1_1_3_stallnummer_1"/>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <mat-label>Anzahl Stallplätze</mat-label>
                        <input matInput type="number" [formControl]="_1_1_3_stallplatze_1"/>
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>2.</td>
                <td>
                    <mat-form-field>
                        <mat-label>Stallnummer/-bezeichnung</mat-label>
                        <input matInput type="text" [formControl]="_1_1_3_stallnummer_2"/>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <mat-label>Anzahl Stallplätze</mat-label>
                        <input matInput type="number" [formControl]="_1_1_3_stallplatze_2"/>
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>3.</td>
                <td>
                    <mat-form-field>
                        <mat-label>Stallnummer/-bezeichnung</mat-label>
                        <input matInput type="text" [formControl]="_1_1_3_stallnummer_3"/>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <mat-label>Anzahl Stallplätze</mat-label>
                        <input matInput type="number" [formControl]="_1_1_3_stallplatze_3"/>
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>4.</td>
                <td>
                    <mat-form-field>
                        <mat-label>Stallnummer/-bezeichnung</mat-label>
                        <input matInput type="text" [formControl]="_1_1_3_stallnummer_4"/>
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field>
                        <mat-label>Anzahl Stallplätze</mat-label>
                        <input matInput type="number" [formControl]="_1_1_3_stallplatze_4"/>
                    </mat-form-field>
                </td>
            </tr>
            </tbody>
        </table>

        <!--<mat-form-field class="phito-field" style="width: 30%">
            <mat-label>Anzahl Ställe mit Stallbezeichnung</mat-label>
            <input [formControl]="_1_1_1" matInput type="text" />
        </mat-form-field>
        <mat-form-field class="phito-field">
            <mat-label>Anzahl Stallplätze gesamt</mat-label>
            <input [formControl]="_1_1_2" matInput type="text" />
        </mat-form-field>-->
        <ng-container *ngIf="istKaelber()">
            <div class="one-row" style="margin-left: 12px;">
                <span class="phito-field">Milchkühe auf dem Betrieb?</span>
                <mat-radio-group [formControl]="_1_1_3" class="phito-field">
                    <mat-radio-button [value]="0">nein</mat-radio-button>
                    <mat-radio-button [value]="1">ja</mat-radio-button>
                </mat-radio-group>
                <span class="spacer"></span>
            </div>
        </ng-container>

        <!--

      Abschnitt 2.

      -->

        <mat-divider class="divider"></mat-divider>
        <h3 style="margin-top: 24px">2. Angaben zur tierärztlichen Betreuung</h3>
        <p style="margin-left: 12px;">
            Die tierärztliche Betreuung und Beratung für den Maßnahmenplan erfolgt
            durch folgenden Tierarzt:
        </p>
        <label for="betreuungsvertrag-id" style="margin-left: 12px;">Betreuungsvertrag</label>
        <mat-radio-group id="betreuungsvertrag-id" class="phito-field" style="margin-right: 48px; margin-left: 12px;"
                         [formControl]="_2_1_1">
            <mat-radio-button [value]="1">ja</mat-radio-button>
            <mat-radio-button [value]="0">nein</mat-radio-button>
        </mat-radio-group>

        <label for="weitere-tieraerzte-id" style="margin-left: 12px;">Weitere Tierärzte:</label>
        <mat-radio-group id="weitere-tieraerzte-id" class="phito-field" [formControl]="_2_2_1"
                         style="margin-left: 12px;">
            <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
            <mat-radio-button [value]="1" class="phito-field">ja</mat-radio-button>
        </mat-radio-group>

        <mat-form-field class="phito-field twenty" style="margin-right: 48px; margin-left: 12px;">
            <mat-label>Weitere Tier&auml;rzte:</mat-label>
            <input [formControl]="_2_2_2" matInput placeholder="Name, Anschrift" type="text"/>
        </mat-form-field>

        <br/>

        <span style="margin-left: 12px;">Weitere Berater:</span>
        <mat-radio-group [formControl]="_2_3_1" style="margin-left: 12px;">
            <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
            <mat-radio-button [value]="1" class="phito-field">ja</mat-radio-button>
        </mat-radio-group>

        <mat-form-field class="phito-field" style="margin-right: 24px; margin-left: 12px;">
            <mat-label>Weitere Berater:</mat-label>
            <input [formControl]="_2_3_2" matInput placeholder="Name, Anschrift" type="text"/>
        </mat-form-field>


        <span style="margin-left: 12px;">Integration:</span>
        <mat-radio-group [formControl]="_2_integration" style="margin-left: 12px;">
            <mat-radio-button [value]="1" class="phito-field">ja</mat-radio-button>
            <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
        </mat-radio-group>
        <span class="spacer"></span>


        <!--

      Abschnitt 3.

      -->

        <h3 style="margin-top: 24px">3. Angaben zur Tierhaltung</h3>
        <span class="one-row" style="margin-left: 12px;font-size: .8rem; font-weight: bold">Programmteilnahme</span>
        <div class="one-row" style="margin-left: 12px;">
            <span>QS-System-Teilnehmer:</span>
            <mat-radio-group class="phito-field" [formControl]="_3_1_7">
                <mat-radio-button [value]="0">nein</mat-radio-button>
                <mat-radio-button [value]="1">ja</mat-radio-button>
            </mat-radio-group>
            <span style="margin-left: 24px">Sonstiges:</span>
            <mat-form-field class="phito-field">
                <mat-label>Sonstiges:</mat-label>
                <input matInput type="text" [formControl]="_3_1_9"/>
            </mat-form-field>
            <span class="spacer"></span>
        </div>

        <div class="one-row" style="margin-left: 12px;">
            <span>Bio-Siegel:</span>
            <mat-radio-group class="phito-field" [formControl]="_3_biosiegel">
                <mat-radio-button [value]="0">nein</mat-radio-button>
                <mat-radio-button [value]="1">ja</mat-radio-button>
            </mat-radio-group>
            <span style="margin-left: 24px">Welches:</span>
            <mat-form-field class="phito-field">
                <mat-label>Bio-Siegel</mat-label>
                <input matInput type="text" [formControl]="_3_1_8"/>
            </mat-form-field>
            <span class="spacer"></span>
        </div>

        <div class="one-row" style="margin-left: 12px;">
            <span>Tierwohlteilnahme:</span>
            <mat-radio-group class="phito-field" [formControl]="_3_tierwohlteilnahme">
                <mat-radio-button [value]="0">nein</mat-radio-button>
                <mat-radio-button [value]="1">ja</mat-radio-button>
            </mat-radio-group>
            <span style="margin-left: 24px">Sonstige Programme:</span>
            <mat-form-field class="phito-field">
                <mat-label>Sonstige Programme:</mat-label>
                <input matInput type="text" [formControl]="_3_sonstige_programme"/>
            </mat-form-field>
            <span class="spacer"></span>
        </div>

        <ng-container *ngIf="istKaelber()">
            <div style="display: inline-block; margin-right: 48px; margin-left: 12px; width: 100%">

                <span class="one-row">Art und Weise der Mast:</span>
                <mat-checkbox [formControl]="_3_1_1" class="phito-field">rein/raus
                </mat-checkbox>
                <mat-checkbox [formControl]="_3_1_2" class="phito-field">abteilweise
                </mat-checkbox>
                <mat-checkbox [formControl]="_3_1_3" class="phito-field">stallweise
                </mat-checkbox>
                <mat-checkbox [formControl]="_3_1_4" class="phito-field">kontinuierlich
                </mat-checkbox>
                <mat-checkbox [formControl]="_3_1_5" class="phito-field">mit Auslauf
                </mat-checkbox>
                <mat-checkbox [formControl]="_3_1_6" class="phito-field">Freilandhaltung
                </mat-checkbox>
                <mat-form-field class="phito-field twenty" style="margin-left: 12px;">
                    <mat-label>Leerstandszeit (i. d. Regel)</mat-label>
                    <input [formControl]="_3_5_1" matInput type="text"/>
                </mat-form-field>
            </div>
            <div style="display: inline-block; margin-right: 48px; margin-left: 12px; width: 100%">
                <mat-checkbox [formControl]="_3_3_1" class="phito-field">Fresser-Aufzucht
                </mat-checkbox>
                <mat-checkbox [formControl]="_3_3_2" class="phito-field">Fresser-Mast
                </mat-checkbox>
                <mat-checkbox [formControl]="_3_3_3" class="phito-field">Milchmast
                </mat-checkbox>
                <mat-checkbox [formControl]="_3_3_4" class="phito-field">Rose-Mast
                </mat-checkbox>
                <mat-checkbox [formControl]="_3_3_5" class="phito-field">Bullenmast
                </mat-checkbox>
                <mat-checkbox [formControl]="_3_faersenmast" class="phito-field">Färsenmast
                </mat-checkbox>
                <mat-form-field class="phito-field">
                    <mat-label>Sonstiges</mat-label>
                    <input [formControl]="_3_mast_sonstiges" matInput type="text"/>
                </mat-form-field>
            </div>
        </ng-container>

        <ng-container *ngIf="istMilchkuehe()">
            <div class="one-row" style="margin-left: 12px;">
                <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">

                    <span style="margin-right: 12px;">Angaben zur Tiergesundheit:</span>
                    <mat-form-field class="phito-field">
                        <mat-label>Nutzungsdauer</mat-label>
                        <input [formControl]="_3_nutzungsdauer" matInput type="text"/>
                    </mat-form-field>
                    <mat-form-field class="phito-field">
                        <mat-label>Herdenalter</mat-label>
                        <input [formControl]="_3_herdenalter" matInput type="text"/>
                    </mat-form-field>
                    <mat-form-field class="phito-field">
                        <mat-label>Durchschnittliche Zellzahl pro Jahr</mat-label>
                        <input [formControl]="_3_zellzahl" matInput type="text"/>
                    </mat-form-field>
                </div>
                <span class="spacer"></span>
            </div>
        </ng-container>

        <div style="display: inline-block; margin-right: 48px; margin-left: 12px; width: 100%">
            <mat-form-field class="phito-field">
                <mat-label>Alter bei Einstallung (Wochen/Monate)</mat-label>
                <input [formControl]="_3_3_6" matInput type="text"/>
            </mat-form-field>
            <mat-form-field class="phito-field">
                <mat-label>Mastdauer (Wochen/Monate)</mat-label>
                <input [formControl]="_3_3_7" matInput type="text"/>
            </mat-form-field>
            <mat-form-field class="phito-field">
                <mat-label>Haltungsdauer (Wochen/Monate)</mat-label>
                <input [formControl]="_3_haltungsdauer" matInput type="text"/>
            </mat-form-field>
        </div>

        <span class="one-row" style="margin-left: 12px;">Zukauf / Verkauf:</span>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <ng-container *ngIf="istMilchkuehe()">
                <mat-checkbox [formControl]="_3_2_1" class="phito-field">eigene Nachzucht
                </mat-checkbox>
                <span style="margin-left: 24px">Kuhgebundene Aufzucht:</span>
                <mat-radio-group [formControl]="_3_kuhgebundene_aufzucht">
                    <mat-radio-button [value]="1" class="phito-field">ja</mat-radio-button>
                    <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                </mat-radio-group>
                <br/>
            </ng-container>
            <mat-checkbox style="margin-right: 12px;" [formControl]="_3_2_2" class="phito-field">Zukauf
            </mat-checkbox>
            <mat-form-field class="phito-field">
                <mat-label>Anzahl Herkünfte</mat-label>
                <input [formControl]="_3_2_3" matInput placeholder="Anzahl Herkünfte" type="text"/>
            </mat-form-field>

            <mat-form-field class="phito-field">
                <mat-label>Besatzdichte (m²/Tier)</mat-label>
                <input [formControl]="_3_4_1" matInput type="text"/>
            </mat-form-field>
            <mat-form-field class="phito-field">
                <mat-label>Tiere pro Bucht/Liegebox</mat-label>
                <input [formControl]="_3_4_2" matInput type="text"/>
            </mat-form-field>
        </div>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">

            <span class="one-row">Haltungssystem:</span>
            <mat-checkbox [formControl]="_3_6_1" class="phito-field">Betonspalten
            </mat-checkbox>
            <ng-container *ngIf="istKaelber()">
                <mat-checkbox [formControl]="_3_gummiauflage" class="phito-field">Betonspalten + Gummiauflage
                </mat-checkbox>
                <mat-checkbox [formControl]="_3_6_2" class="phito-field">Holzspalten
                </mat-checkbox>
                <mat-checkbox [formControl]="_3_6_3" class="phito-field">Holzspalten mit Kunststoff
                </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="istMilchkuehe()">
                <mat-checkbox [formControl]="_3_trockensteher" class="phito-field">Trockensteher Haltung
                </mat-checkbox>
                <mat-checkbox [formControl]="_3_planbefestigt" class="phito-field">planbefestigt
                </mat-checkbox>
            </ng-container>
            <mat-checkbox [formControl]="_3_6_4" class="phito-field">Tiefstreu
            </mat-checkbox>
            <mat-checkbox [formControl]="_3_6_5" class="phito-field">Liegeboxen-Laufstall
            </mat-checkbox>
            <mat-checkbox [formControl]="_3_6_6" class="phito-field">Anbinde-Haltung
            </mat-checkbox>
            <mat-checkbox [formControl]="_3_6_7" class="phito-field">Weidegang
            </mat-checkbox>
            <br/>
            <!--<mat-checkbox [formControl]="_3_6_8" class="phito-field">Sonstiges
            </mat-checkbox>-->
            <mat-form-field class="phito-field" style="margin-left: 24px">
                <mat-label>Sonstiges</mat-label>
                <input [formControl]="_3_6_9" matInput type="text"/>
            </mat-form-field>
        </div>
        <!--

        Abschnitt 4.

       -->

        <mat-divider class="divider"></mat-divider>
        <h3>4a. Angaben zu Klimatechnik</h3>
        <span class="one-row"
              style="margin-left: 12px;font-size: .8rem; font-weight: bold">Heizung und Belüftung (z.B. Technik, Qualität)</span>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <span class="one-row">Lüftungsart:</span>
            <ng-container *ngIf="istKaelber()">
                <mat-checkbox [formControl]="_4_1_1" class="phito-field">Zwangslüftung
                </mat-checkbox>
                <mat-checkbox [formControl]="_4_1_2" class="phito-field">Deckenlüftung
                </mat-checkbox>
                <mat-checkbox [formControl]="_4_1_3" class="phito-field">Türganglüftung
                </mat-checkbox>
                <mat-checkbox [formControl]="_4_1_5" class="phito-field">Unterflurabsaugung
                </mat-checkbox>
                <mat-checkbox [formControl]="_4_ventilator" class="phito-field">Ventilator
                </mat-checkbox>
                <mat-checkbox [formControl]="_4_airtubes" class="phito-field">Airtubes
                </mat-checkbox>
            </ng-container>
        </div>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">

            <mat-checkbox [formControl]="_4_1_4" class="phito-field">freie Lüftung
            </mat-checkbox>
            <mat-form-field class="phito-field">
                <mat-label>Andere/Zusätzliche Technik 1</mat-label>
                <input [formControl]="_4_1_9" matInput type="text"/>
            </mat-form-field>
            <mat-form-field class="phito-field">
                <mat-label>Andere/Zusätzliche Technik 2</mat-label>
                <input [formControl]="_4_1_10" matInput type="text"/>
            </mat-form-field>
            <mat-form-field class="phito-field">
                <mat-label>Andere/Zusätzliche Technik 3</mat-label>
                <input [formControl]="_4_1_11" matInput type="text"/>
            </mat-form-field>
        </div>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">

            <span class="one-row">Heiztechnik:</span>
            <mat-checkbox [formControl]="_4_1_6" class="phito-field">Heizkanone
            </mat-checkbox>
            <mat-checkbox [formControl]="_4_1_7" class="phito-field">Konvektor
            </mat-checkbox>
            <mat-checkbox [formControl]="_4_1_8" class="phito-field">Deltarohre
            </mat-checkbox>
            <mat-form-field class="phito-field">
                <mat-label>Zusätzliche Heiztechnik:</mat-label>
                <input [formControl]="_4_1_12" matInput type="text"/>
            </mat-form-field>
        </div>
        <!--<br />
        <mat-form-field>
            <mat-label>&Uuml;berpr&uuml;fung am</mat-label>
            <input [formControl]="_4_1_13" [matDatepicker]="picker_4_1" matInput placeholder="dd.MM.jjjj" />
            <mat-datepicker-toggle [for]="picker_4_1" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker_4_1></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="phito-field twenty">
            <mat-label>durch</mat-label>
            <input [formControl]="_4_1_14" matInput type="text" />
        </mat-form-field>-->

        <ng-container *ngIf="istMilchkuehe()">
            <h3 style="margin-top: 24px;">4b. Angaben zur Melktechnik und Hygiene</h3>

            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">


                <mat-checkbox [formControl]="_4b_melkstand" class="phito-field">Melkstand
                </mat-checkbox>
                <mat-form-field class="phito-field">
                    <mat-label>Art</mat-label>
                    <input [formControl]="_4b_melkstand_art" matInput type="text"/>
                </mat-form-field>
                <mat-form-field class="phito-field">
                    <mat-label>Anzahl Plätze</mat-label>
                    <input [formControl]="_4b_melkstand_plaetze" matInput type="text"/>
                </mat-form-field>
            </div>
            <br/>
            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">


                <mat-checkbox [formControl]="_4b_melkroboter" class="phito-field">Melkroboter
                </mat-checkbox>
                <mat-form-field class="phito-field">
                    <mat-label>Modell</mat-label>
                    <input [formControl]="_4b_modell" matInput type="text"/>
                </mat-form-field>
            </div>

            <div style="display: inline-block; margin: 24px 48px 0 12px;width: 100%">
                <span>Teilnahme an Milchleistungsprüfung?</span>
                <mat-radio-group [formControl]="_4b_leistungspruefung">
                    <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                    <mat-radio-button [value]="1" class="phito-field">ja</mat-radio-button>
                </mat-radio-group>
            </div>

            <div style="display: inline-block; margin: 24px 48px 0 12px;width: 100%">
                <span class="one-row">Melken euterkranker Tiere:</span>
                <mat-checkbox [formControl]="_4b_in_der_herde" class="phito-field">in der Herde, ohne Reihenfolge, mit
                    eigenem Melkzeug
                </mat-checkbox>
                <mat-checkbox [formControl]="_4b_am_ende" class="phito-field">am Ende der Melkreihenfolge, mit eigenem
                    Melkzeug
                </mat-checkbox>
            </div>

            <div style="display: inline-block; margin: 24px 48px 0 12px;width: 100%">
                <span class="one-row">Regelmäßige Wartung Melksystem:</span>
                <mat-radio-group [formControl]="_4b_wartung">
                    <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                    <mat-radio-button [value]="1" class="phito-field">ja, Häufigkeit:</mat-radio-button>
                </mat-radio-group>
                <mat-form-field class="phito-field">
                    <mat-label>Häufigkeit</mat-label>
                    <input [formControl]="_4b_wartung_haeufigkeit" matInput type="text"/>
                </mat-form-field>
            </div>

            <div style="display: inline-block; margin: 24px 48px 0 12px;width: 100%">
                <span>Regelmäßiger Tausch Zitzengummis:</span>
                <mat-radio-group [formControl]="_4b_tausch">
                    <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                    <mat-radio-button [value]="1" class="phito-field">ja, Häufigkeit:</mat-radio-button>
                </mat-radio-group>
                <mat-form-field class="phito-field">
                    <mat-label>Häufigkeit</mat-label>
                    <input [formControl]="_4b_tausch_haeufigkeit" matInput type="text"/>
                </mat-form-field>
            </div>

            <div style="display: inline-block; margin: 24px 48px 0 12px;width: 100%">
                <span>Zitzendippen:</span>
                <mat-radio-group [formControl]="_4b_zitzendippen">
                    <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                    <mat-radio-button [value]="1" class="phito-field">ja</mat-radio-button>
                </mat-radio-group>
            </div>

            <span class="one-row" style="margin: 12px 0 0 12px; font-size: .8rem; font-weight: bold">Melkhygiene</span>
            <div style="display: inline-block; margin-top: 12px; margin-left: 12px;width: 100%">
                <span class="one-row">Eutervorreinigung mit:</span>
                <mat-checkbox [formControl]="_4b_einwegtuch" class="phito-field">Einwegtuch
                </mat-checkbox>
                <mat-checkbox [formControl]="_4b_mehrwegtuch" class="phito-field">Mehrwegtuch
                </mat-checkbox>
                <mat-checkbox [formControl]="_4b_zitzendesinfektion" class="phito-field">Zitzendesinfektion
                </mat-checkbox>
                <mat-checkbox [formControl]="_4b_handschuhe" class="phito-field">Handschuhe beim Melken
                </mat-checkbox>
                <mat-form-field class="phito-field">
                    <mat-label>Schutzkleidung</mat-label>
                    <input [formControl]="_4b_schutzkleidung" matInput type="text"/>
                </mat-form-field>
            </div>
        </ng-container>

        <!--

        Abschnitt 5.

       -->
        <h3>5. Angaben zu Futter- und Wasserversorgung</h3>
        <span style="margin-left: 12px;">Art der Fütterung <small>(z.B. Technik, Lagerung, Herkunft)</small></span>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <ng-container *ngIf="istKaelber()">
                <mat-checkbox [formControl]="_5_1_1" class="phito-field">Milchaustauscher
                </mat-checkbox>
            </ng-container>
            <mat-checkbox [formControl]="_5_1_2" class="phito-field">TMR
            </mat-checkbox>
            <mat-checkbox [formControl]="_5_1_3" class="phito-field">Kraftfutter
            </mat-checkbox>
            <mat-checkbox [formControl]="_5_1_4" class="phito-field">Heu
            </mat-checkbox>
            <mat-checkbox [formControl]="_5_1_5" class="phito-field">Stroh
            </mat-checkbox>
            <mat-checkbox [formControl]="_5_weide" class="phito-field">Weide
            </mat-checkbox>
            <mat-checkbox [formControl]="_5_1_6" class="phito-field">Maissilage
            </mat-checkbox>
            <mat-checkbox [formControl]="_5_grassilage" class="phito-field">Grassilage
            </mat-checkbox>
            <mat-checkbox [formControl]="_5_treber" class="phito-field">Treber/Pülpe
            </mat-checkbox>
            <mat-checkbox [formControl]="_5_trester" class="phito-field">Trester
            </mat-checkbox>
            <mat-checkbox [formControl]="_5_rueben" class="phito-field">Rüben
            </mat-checkbox>
            <mat-form-field class="phito-field">
                <mat-label>Sonstiges</mat-label>
                <input [formControl]="_5_futter_sonstiges" matInput type="text"/>
            </mat-form-field>
            <br/>
            <ng-container *ngIf="istKaelber()">
                <mat-checkbox [formControl]="_5_1_7" class="phito-field">Eimer
                </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="istMilchkuehe()">
                <mat-checkbox [formControl]="_5_vollautomatisch" class="phito-field">Vollautomatische Fütterung
                </mat-checkbox>
            </ng-container>
            <mat-checkbox [formControl]="_5_1_8" class="phito-field">Trog
            </mat-checkbox>
            <mat-checkbox [formControl]="_5_1_9" class="phito-field">Automat
            </mat-checkbox>
        </div>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <mat-form-field class="phito-field">
                <mat-label>Sonstiges</mat-label>
                <input [formControl]="_5_1_10" matInput type="text"/>
            </mat-form-field>
            <mat-form-field class="phito-field">
                <mat-label>Anzahl der Tiere pro Futterplatz</mat-label>
                <input [formControl]="_5_1_11" matInput type="text"/>
            </mat-form-field>
        </div>

        <span style="margin-left: 12px;">
            Wasserversorgung
            <small>(z.B. Art und Anzahl der Tränke, Wasserqualität,
                Untersuchung):</small>
        </span>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">


            <mat-checkbox [formControl]="_5_2_1" class="phito-field">Brunnenwasser
            </mat-checkbox>
            <mat-checkbox [formControl]="_5_2_2" class="phito-field">Stadtwasser
            </mat-checkbox>
            <mat-checkbox [formControl]="_5_2_3" class="phito-field">letzte Wasseruntersuchung am:
            </mat-checkbox>
            <mat-form-field class="phito-field">
                <mat-label>Untersuchung am</mat-label>
                <input [formControl]="_5_untersuchung_am" matInput type="text"/>
            </mat-form-field>
        </div>
        <!--<mat-form-field class="phito-field">
            <mat-label>Sonstiges</mat-label>
            <input [formControl]="_5_2_4" matInput type="text" />
        </mat-form-field>-->
        <br/>
        <mat-form-field class="phito-field">
            <mat-label>Anzahl Tiere pro Tränke</mat-label>
            <input [formControl]="_5_2_5" matInput type="text"/>
        </mat-form-field>
        <mat-form-field class="phito-field">
            <mat-label>Art der Tränke</mat-label>
            <input [formControl]="_5_2_6" matInput type="text"/>
        </mat-form-field>

        <!--

        Abschnitt 6.

       -->

        <h3>6. Art und Weise der Verabreichung von Antibiotika</h3>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">


            <mat-checkbox [formControl]="_6_1_1" class="phito-field">Injektion
            </mat-checkbox>
            <mat-checkbox [formControl]="_6_1_2" class="phito-field">Futter
            </mat-checkbox>
            <mat-checkbox [formControl]="_6_1_3" class="phito-field">Wasser
            </mat-checkbox>
            <ng-container *ngIf="istKaelber()">
                <mat-checkbox [formControl]="_6_1_4" class="phito-field">Milchtränke
                </mat-checkbox>
                <mat-checkbox [formControl]="_6_topdressing" class="phito-field">Futter Topdressing
                </mat-checkbox>
                <mat-checkbox [formControl]="_6_vermischt" class="phito-field">Futter vermischt
                </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="istMilchkuehe()">
                <mat-checkbox [formControl]="_6_intramammaer" class="phito-field">intramammär
                </mat-checkbox>
                <mat-checkbox [formControl]="_6_intrauterin" class="phito-field">intrauterin
                </mat-checkbox>
            </ng-container>
            <mat-checkbox [formControl]="_6_1_5" class="phito-field">Mischer / Vorbehälter
            </mat-checkbox>
            <mat-checkbox [formControl]="_6_1_6" class="phito-field">Dosierer
            </mat-checkbox>
            <mat-checkbox [formControl]="_6_1_7" class="phito-field">tiernah
            </mat-checkbox>
            <mat-checkbox [formControl]="_6_1_8" class="phito-field">lokal (z.B. Spray f. Haut / Klauen)
            </mat-checkbox>
        </div>
        <ng-container *ngIf="istKaelber()">
            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">


            <span>Leitfaden "Orale Anwendung von Tierarzneimitteln"
                bekannt?:</span>
                <mat-radio-group [formControl]="_6_2_1">
                    <mat-radio-button [value]="1" class="phito-field">ja</mat-radio-button>
                    <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                </mat-radio-group>
            </div>
            <br/>
            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">


                <span>Risikomanagementplan vorhanden?:</span>
                <mat-radio-group [formControl]="_6_3_1">
                    <mat-radio-button [value]="1" class="phito-field">ja</mat-radio-button>
                    <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                </mat-radio-group>
            </div>
        </ng-container>

        <!--

      Abschnitt 7.

     -->
        <mat-divider class="divider"></mat-divider>
        <h3>7. Angabe zur Hygiene</h3>
        <span style="margin-left: 12px;font-size: .8rem; font-weight: bold">Externe Biosicherheit des Betriebes</span>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <mat-form-field class="phito-field twenty">
                <mat-label>Art der Schutzkleidung</mat-label>
                <input [formControl]="_7_1_1" matInput type="text"/>
            </mat-form-field>
            <mat-form-field class="phito-field twenty">
                <mat-label>Art der Hygieneschleuse</mat-label>
                <input [formControl]="_7_1_2" matInput type="text"/>
            </mat-form-field>
        </div>

        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <span>Desinfektionsmatten/-wannen vorhanden:</span>
            <mat-radio-group [formControl]="_7_desinfektionsmatten">
                <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                <mat-radio-button [value]="1" class="phito-field">ja, mit</mat-radio-button>
            </mat-radio-group>
            <mat-form-field class="phito-field">
                <mat-label>Desinfektionsmatten</mat-label>
                <input [formControl]="_7_desinfektionsmatten_text" matInput type="text"/>
            </mat-form-field>
        </div>

        <span style="margin-left: 12px;font-size: .8rem; font-weight: bold">Art und Umfang der internen Biosicherheit</span>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <span>Reinigung Stall:</span>
            <mat-radio-group [formControl]="_7_2_1">
                <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                <mat-radio-button [value]="1" class="phito-field">ja, mit</mat-radio-button>
            </mat-radio-group>
            <mat-form-field class="phito-field">
                <mat-label>Reinigungsmittel</mat-label>
                <input [formControl]="_7_reinigungsmittel" matInput type="text"/>
            </mat-form-field>
            <mat-checkbox [formControl]="_7_reinigung_selbst" class="phito-field">durch Betriebspersonal
            </mat-checkbox>
            <mat-checkbox [formControl]="_7_reinigung_fachfirma" class="phito-field">Fachfirma
            </mat-checkbox>
        </div>

        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <span>Desinfektion Stall:</span>
            <mat-radio-group [formControl]="_7_2_2">
                <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                <mat-radio-button [value]="1" class="phito-field">ja, mit</mat-radio-button>
            </mat-radio-group>
            <mat-form-field class="phito-field">
                <mat-label>Desinfektionsmittel</mat-label>
                <input [formControl]="_7_desinfektionsmittel" matInput type="text"/>
            </mat-form-field>
            <mat-checkbox [formControl]="_7_desinfektion_selbst" class="phito-field">durch Betriebspersonal
            </mat-checkbox>
            <mat-checkbox [formControl]="_7_desinfektion_fachfirma" class="phito-field">Fachfirma
            </mat-checkbox>
        </div>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">


            <span>Wasserleitungen:</span>
            <mat-checkbox [formControl]="_7_2_3" class="phito-field">Reinigung
            </mat-checkbox>
            <mat-checkbox [formControl]="_7_2_4" class="phito-field">Desinfektion mit
            </mat-checkbox>
            <mat-form-field class="phito-field">
                <mat-label>Desinfektionsmittel</mat-label>
                <input [formControl]="_7_wasser_desinfektionsmittel" matInput type="text"/>
            </mat-form-field>
            <mat-checkbox [formControl]="_7_wasser_routine" class="phito-field">routinemäßig
            </mat-checkbox>
            <ng-container *ngIf="istKaelber()">
                <mat-checkbox [formControl]="_7_wasser_antibiotika" class="phito-field">nach Antibiotika-Gabe
                </mat-checkbox>
            </ng-container>
        </div>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">


            <span>Fütterungseinrichtungen:</span>
            <mat-checkbox [formControl]="_7_2_5" class="phito-field">Reinigung Futterleitungen
            </mat-checkbox>
            <mat-checkbox [formControl]="_7_2_6" class="phito-field">Reinigung Futtertröge
            </mat-checkbox>
            <mat-checkbox [formControl]="_7_2_7" class="phito-field">routinemäßig
            </mat-checkbox>
            <ng-container *ngIf="istKaelber()">
                <mat-checkbox [formControl]="_7_2_8" class="phito-field">nach Antibiotika-Gabe
                </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="istMilchkuehe()">
                <mat-checkbox [formControl]="_7_2_9" class="phito-field">Reinigung Futtersilo
                </mat-checkbox>
            </ng-container>
        </div>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">


            <span>Schädlingsbekämpfung:</span>
            <mat-checkbox [formControl]="_7_2_11" class="phito-field">Fachfirma
            </mat-checkbox>
            <mat-checkbox [formControl]="_7_2_12" class="phito-field">durch Betriebspersonal
            </mat-checkbox>
            <span>, Dokumentation vorhanden:</span>
            <mat-radio-group [formControl]="_7_dokumentation">
                <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                <mat-radio-button [value]="1" class="phito-field">ja</mat-radio-button>
            </mat-radio-group>
        </div>

        <span style="margin-left: 12px; margin-top: 24px;font-size: 0.8em; font-weight: bold">Absonderungsmöglichkeit für kranke Tiere</span>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <span>Krankenbucht: </span>
            <mat-radio-group [formControl]="_7_3_1">
                <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                <mat-radio-button [value]="1" class="phito-field">ja, Anzahl:</mat-radio-button>
            </mat-radio-group>
            <mat-form-field class="phito-field">
                <mat-label>Anzahl:</mat-label>
                <input [formControl]="_7_3_3" matInput type="text"/>
            </mat-form-field>
            <mat-checkbox [formControl]="_7_3_4" class="phito-field">mit Stroh
            </mat-checkbox>
            <mat-form-field class="phito-field">
                <mat-label>Sonstiges:</mat-label>
                <input [formControl]="_7_3_5" matInput placeholder="Sonstiges" type="text"/>
            </mat-form-field>
        </div>

        <ng-container *ngIf="istMilchkuehe()">
            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
                <span>Abkalbebuchten: </span>
                <mat-radio-group [formControl]="_7_abkalbebuchten">
                    <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                    <mat-radio-button [value]="1" class="phito-field">ja, Anzahl:</mat-radio-button>
                </mat-radio-group>
                <mat-form-field class="phito-field">
                    <mat-label>Anzahl:</mat-label>
                    <input [formControl]="_7_abkalbebuchten_anzahl" matInput type="text"/>
                </mat-form-field>
                <mat-checkbox [formControl]="_7_abkalbebuchten_stroh" class="phito-field">mit Stroh
                </mat-checkbox>
                <mat-form-field class="phito-field">
                    <mat-label>Sonstiges:</mat-label>
                    <input [formControl]="_7_abkalbebuchten_sonstiges" matInput placeholder="Sonstiges" type="text"/>
                </mat-form-field>
            </div>
        </ng-container>

        <!--<mat-checkbox [formControl]="_7_3_6" class="phito-field">sonstige Absonderungsmöglichkeiten
        </mat-checkbox>
        <mat-form-field class="phito-field twenty">
            <input [formControl]="_7_3_7" matInput placeholder="" type="text" />
        </mat-form-field>-->

        <!--

        Abschnitt 8.

       -->

        <mat-divider class="divider"></mat-divider>
        <h3>8. Gesundheitsmanagement</h3>
        <span style="margin-left: 12px;">Immunprohylaxe <small>(Impfschema):</small></span>
        <ng-container *ngIf="istKaelber()">
            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
                <mat-checkbox [formControl]="_8_1_1" class="phito-field">vorgeimpft mit:
                </mat-checkbox>
                <mat-form-field class="phito-field">
                    <mat-label>Name Impfung und Impfzeitpunkt</mat-label>
                    <input [formControl]="_8_1_2" matInput type="text"/>
                </mat-form-field>
            </div>
            <br/>
        </ng-container>

        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <mat-checkbox [formControl]="_8_1_3" class="phito-field">im Bestand geimpft mit:
            </mat-checkbox>
            <mat-form-field class="phito-field">
                <mat-label>Name Impfung und Impfzeitpunkt</mat-label>
                <input [formControl]="_8_1_4" matInput type="text"/>
            </mat-form-field>
        </div>

        <ng-container *ngIf="istMilchkuehe()">
            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
                <mat-checkbox [formControl]="_8_1_im_bestand_geimpft_y_n_2" class="phito-field">im Bestand geimpft mit:
                </mat-checkbox>
                <mat-form-field class="phito-field">
                    <mat-label>Name Impfung und Impfzeitpunkt</mat-label>
                    <input [formControl]="_8_1_im_bestand_geimpft_y_n_text_2" matInput type="text"/>
                </mat-form-field>
            </div>

            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
                <span>Inanspruchnahme einer integrierten tierärztlichen Bestandsbetreuung (ITBS)?:</span>
                <mat-radio-group [formControl]="_8_bestandsbetreuung">
                    <mat-radio-button [value]="0" class="phito-field">Nein</mat-radio-button>
                    <mat-radio-button [value]="1" class="phito-field">Ja, im Bereich:</mat-radio-button>
                </mat-radio-group>
                <mat-checkbox [formControl]="_8_fruchtbarkeit" class="phito-field">Fruchtbarkeit
                </mat-checkbox>
                <mat-checkbox [formControl]="_8_eutergesundheit" class="phito-field">Eutergesundheit
                </mat-checkbox>
                <mat-checkbox [formControl]="_8_klauengesundheit" class="phito-field">Klauengesundheit
                </mat-checkbox>
                <mat-checkbox [formControl]="_8_tiergesundheit" class="phito-field">Kälber-/Jungtiergesundheit
                </mat-checkbox>
                <mat-form-field class="phito-field">
                    <mat-label>Sonstiges</mat-label>
                    <input [formControl]="_8_bestandsbetreuung_sonstiges" matInput type="text"/>
                </mat-form-field>
            </div>
        </ng-container>

        <span style="margin-left: 12px;">Behandlung gegen:</span>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <span>Endoparasiten:</span>
            <mat-radio-group [formControl]="_8_1_5">
                <mat-radio-button [value]="1" class="phito-field">ja</mat-radio-button>
                <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
            </mat-radio-group>
        </div>

        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <span>Ektoparasiten:</span>
            <mat-radio-group [formControl]="_8_1_6">
                <mat-radio-button [value]="1" class="phito-field">ja</mat-radio-button>
                <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
            </mat-radio-group>
        </div>

        <ng-container *ngIf="istKaelber()">
            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
                <span>Kryptosporidien:</span>
                <mat-radio-group [formControl]="_8_kryptosporidien">
                    <mat-radio-button [value]="1" class="phito-field">ja</mat-radio-button>
                    <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                </mat-radio-group>
            </div>

            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
                <span>Kokzidien:</span>
                <mat-radio-group [formControl]="_8_kokzidien">
                    <mat-radio-button [value]="1" class="phito-field">ja</mat-radio-button>
                    <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                </mat-radio-group>
            </div>
        </ng-container>

        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <mat-checkbox [formControl]="_8_1_7" class="phito-field">Sonstiges:
            </mat-checkbox>
            <mat-form-field class="phito-field">
                <mat-label>Sonstiges:</mat-label>
                <input [formControl]="_8_1_8" matInput type="text"/>
            </mat-form-field>
        </div>

        <ng-container *ngIf="istMilchkuehe()">
            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
                <span>Klauenpflege: </span>
                <mat-checkbox [formControl]="_8_klauenpflege_selbst" class="phito-field">durch Betriebspersonal (selbst)
                </mat-checkbox>
                <mat-checkbox [formControl]="_8_klauenpflege_fachfirma" class="phito-field">Fachfirma
                </mat-checkbox>
                <mat-form-field class="phito-field">
                    <mat-label>Frequenz</mat-label>
                    <input [formControl]="_8_klauenpflege_frequenz" matInput type="text"/>
                </mat-form-field>
            </div>

            <span style="margin-left: 12px; font-size: .8rem; font-weight: bold;">Trockenstellenmanagement</span>
            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
                <span>Länge der Trockenstehzeit:</span>
                <mat-checkbox [formControl]="_8_trockenstehzeit_6" class="phito-field">weniger als 6 Wochen
                </mat-checkbox>
                <mat-checkbox [formControl]="_8_trockenstehzeit_6_8" class="phito-field">6-8 Wochen
                </mat-checkbox>
                <mat-checkbox [formControl]="_8_trockenstehzeit_8" class="phito-field">mehr als 8 Wochen
                </mat-checkbox>
                <mat-checkbox [formControl]="_8_trockenstehzeit_0" class="phito-field">kein Trockenstellen (Durchmelken)
                </mat-checkbox>
            </div>

            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
                <span>Art des Trockenstellens:</span>
                <mat-checkbox [formControl]="_8_reduk_melkhaeufigkeit" class="phito-field">Reduktion der Melkhäufigkeit
                </mat-checkbox>
                <mat-checkbox [formControl]="_8_reduk_abrupt" class="phito-field">abruptes Trockenstellen
                </mat-checkbox>
                <mat-checkbox [formControl]="_8_reduk_energie" class="phito-field">Reduktion der Energiezufuhr über
                    Futter
                </mat-checkbox>
                <mat-form-field class="phito-field">
                    <mat-label>Sonstiges</mat-label>
                    <input [formControl]="_8_art_sonstiges" matInput type="text"/>
                </mat-form-field>
            </div>

            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
                <span>Trockenstellen unter antibiotischem Schutz:</span>
                <mat-radio-group [formControl]="_8_antibiotischer_schutz">
                    <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                    <mat-radio-button [value]="1" class="phito-field">alle Kühe</mat-radio-button>
                    <mat-radio-button [value]="2" class="phito-field">selektiv</mat-radio-button>
                </mat-radio-group>
            </div>

            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
                <span>Trockensteller mit Zitzenversiegler:</span>
                <mat-radio-group [formControl]="_8_zitzenversiegler">
                    <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                    <mat-radio-button [value]="1" class="phito-field">alle Kühe</mat-radio-button>
                    <mat-radio-button [value]="2" class="phito-field">selektiv</mat-radio-button>
                </mat-radio-group>
            </div>
        </ng-container>

        <!--


      Abschnitt 9.

      -->

        <mat-divider class="divider"></mat-divider>
        <h3>9. Gründe für das Überschreiten der Kennzahl 2</h3>
        <span style="margin-left: 12px;">Schwerpunkte der Erkankung: </span>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <mat-checkbox [formControl]="_9_1_1" class="phito-field">Atemwege
            </mat-checkbox>
            <mat-checkbox [formControl]="_9_1_2" class="phito-field">Verdauungsapparat
            </mat-checkbox>
            <mat-checkbox [formControl]="_9_1_3" class="phito-field">Bewegungsapparat
            </mat-checkbox>
        </div>

        <ng-container *ngIf="istMilchkuehe()">
            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
                <mat-checkbox [formControl]="_9_euter" class="phito-field">Euter
                </mat-checkbox>
                <mat-checkbox [formControl]="_9_geschlechtsapparat" class="phito-field">Geschlechtsapparat
                </mat-checkbox>
                <mat-checkbox [formControl]="_9_stoffwechsel" class="phito-field">Stoffwechsel
                </mat-checkbox>
            </div>
        </ng-container>

        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <mat-checkbox [formControl]="_9_1_4" class="phito-field">Sonstiges
            </mat-checkbox>
            <mat-form-field class="phito-field twenty">
                <input [formControl]="_9_1_5" matInput type="text"/>
            </mat-form-field>
        </div>

        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">
            <mat-form-field class="phito-field twenty">
                <mat-label>Anzahl verendeter / getöteter Tiere im Kalenderhalbjahr
                </mat-label>
                <input [formControl]="_9_2_1" matInput type="text"/>
                <span matSuffix>{{ toteImAktuellenHalbjahr }}</span>
            </mat-form-field>

            <mat-form-field class="phito-field twenty">
                <mat-label>Anzahl verendeter / getöteter Tiere im vorhergehenden
                    Halbjahr
                </mat-label>
                <input [formControl]="_9_2_2" matInput type="text"/>
                <span matSuffix>{{ toteImLetztenHalbjahr }}</span>
            </mat-form-field>
        </div>
        <span>
            Angaben zu Krankheitsgeschehen
            <small>(z.B. Zeitpunkt, Dauer, Anzahl erkrankter Tiere, klinische
                Diagnose, Behandlung):</small>
        </span>
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">


            <mat-form-field class="phito-field hundred">
        <textarea [formControl]="_9_3_1" [kieneTextBaustein]="getTextbaustein()"
                  (textChanged)="setAngabenKrankheitsgeschehen($event)" matInput></textarea>
            </mat-form-field>
        </div>
        <span>
            Weiterführende Diagnostik
            <small>(z.B. Erregernachweis, Resistenztest, Befunde, ggf. als
                Anlage):</small>
        </span>

        <!--<div>
        Dokumente von {{ zeitraum_von }} bis {{ zeitraum_bis }}
        <button
          *ngIf="anzahl_befunde + anzahl_antibiogramme + anzahl_abgabebelege > 0"
          mat-icon-button
          color="accent"
          (click)="showDokumente()"
        >
          <mat-icon>cloud_download</mat-icon>
        </button>
      </div>
      <div *ngIf="anzahl_befunde !== null" class="one-row">
        <span>Es wurde(n) {{ anzahl_befunde }} Befund(e) gefunden. </span>
      </div>
      <div *ngIf="anzahl_antibiogramme !== null" class="one-row">
        <span
          >Es wurde(n) {{ anzahl_antibiogramme }} Antibiogramm(e) gefunden.
        </span>
      </div>
      <div *ngIf="anzahl_abgabebelege !== null" class="one-row">
        <span>Es wurde(n) {{ anzahl_abgabebelege }} Abgabebeleg(e) gefunden. </span>
      </div>-->
        <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">


            <mat-checkbox [formControl]="_9_4_erregernachweis_y_n_ctrl" class="phito-field">Erregernachweis /
                Resistenztest
                <small>(Befunde ggf. als Kopie)</small>
            </mat-checkbox>
            <mat-form-field class="phito-field hundred">
        <textarea [formControl]="_9_4_2" [kieneTextBaustein]="getTextbaustein()"
                  (textChanged)="setErregernachweis($event)" matInput></textarea>
            </mat-form-field>
        </div>
        <ng-container *ngIf="istMilchkuehe()">
            <div style="display: inline-block; margin-right: 48px; margin-left: 12px;width: 100%">


                <span>Leitkeime bekannt: </span>
                <mat-radio-group [formControl]="_9_leitkeime">
                    <mat-radio-button [value]="0" class="phito-field">nein</mat-radio-button>
                    <mat-radio-button [value]="1" class="phito-field">ja, folgende:</mat-radio-button>
                </mat-radio-group>
                <mat-checkbox [formControl]="_9_uberis" class="phito-field">Sc. uberis
                </mat-checkbox>
                <mat-checkbox [formControl]="_9_aureus" class="phito-field">Sc. aureus
                </mat-checkbox>
                <mat-checkbox [formControl]="_9_ecoli" class="phito-field">E. coli
                </mat-checkbox>
                <mat-checkbox [formControl]="_9_agalactiae" class="phito-field">Sc. agalactiae
                </mat-checkbox>
                <mat-checkbox [formControl]="_9_dysagalactiae" class="phito-field">Sc. dysagalactiae
                </mat-checkbox>
                <mat-checkbox [formControl]="_9_mykoplasmen" class="phito-field">Mykoplasmen
                </mat-checkbox>
                <mat-form-field class="phito-field">
                    <mat-label>Sonstiges</mat-label>
                    <input [formControl]="_9_leitkeime_text" matInput placeholder="Sonstiges" type="text"/>
                </mat-form-field>
            </div>
        </ng-container>


        <mat-checkbox [formControl]="_9_4_sektion_y_n_ctrl" class="phito-field">Sektion <small>(Befunde ggf. als
            Kopie)</small>
        </mat-checkbox>
        <mat-form-field class="phito-field hundred">
        <textarea [formControl]="_9_4_4" [kieneTextBaustein]="getTextbaustein()" (textChanged)="setSektion($event)"
                  matInput></textarea>
        </mat-form-field>
        <mat-checkbox [formControl]="_9_4_5" class="phito-field">Sonstiges <small>(Abgabebelege ggf. als Kopie)</small>
        </mat-checkbox>
        <mat-form-field class="phito-field hundred">
        <textarea [formControl]="_9_4_6" [kieneTextBaustein]="getTextbaustein()" [rows]="6"
                  (textChanged)="setSonstiges($event)" matInput></textarea>
        </mat-form-field>

        <div>
            <h4>Anhänge</h4>
            Anlage A
            <button (click)="downloadAnlageA()" color="accent" mat-icon-button>
                <mat-icon>cloud_download</mat-icon>
            </button>
            <br/>
            <mat-checkbox [formControl]="bbpAnhaengenCtrl">Bestandsbesuchsprotokolle anhängen
            </mat-checkbox>
            <button (click)="downloadBBP()" color="accent" mat-icon-button>
                <mat-icon>cloud_download</mat-icon>
            </button>
            <br/>
            <mat-checkbox [formControl]="befundeAnhaengenCtrl">
                Befunde anhängen
            </mat-checkbox>
            <button (click)="downloadBefunde()" color="accent" mat-icon-button>
                <mat-icon>cloud_download</mat-icon>
            </button>
        </div>

        <mat-divider class="divider"></mat-divider>
        <h3>
            10. Angaben zur tierärztlichen Beratung
            <small *ngIf="_10_focus === true">(
                <mat-icon color="primary">info</mat-icon>
                F4 zum Auswählen von
                Textvorlagen)</small>
        </h3>
        <mat-form-field class="phito-field hundred">
            <mat-label>Ergebnis der Beratung</mat-label>
            <textarea (focus)="_10_focus = true" (focusout)="_10_focus = false" (textChanged)="setValue10and11($event)"
                      [formControl]="_10_1_1" [kieneTextBaustein]="getTextbaustein10und11()" matInput></textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Datum</mat-label>
            <input [formControl]="_10_2_1" [matDatepicker]="picker_10_2" matInput/>
            <mat-datepicker-toggle [for]="picker_10_2" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker_10_2></mat-datepicker>
        </mat-form-field>

        <mat-checkbox [formControl]="_10_3_1" class="phito-field">Hiermit stimme ich zu, dass die oben beratende
            Tierärztin/
            der oben
            beratende Tierarzt und die zuständige <br/>Überwachungsbehörde (LAVES)
            Informationen über meinen Betrieb ausschließlich in Bezug auf die
            Antibiotikaminimierung austauschen dürfen.
        </mat-checkbox>

        <mat-divider class="divider"></mat-divider>
        <h3>
            11. Maßnahmen zur Verringerung des Antibiotika-Einsatzes
            <small *ngIf="_11_focus === true">(
                <mat-icon color="primary">info</mat-icon>
                F4 zum Auswählen von
                Textvorlagen)</small>
        </h3>
        <mat-form-field class="phito-field hundred">
            <mat-label>Maßnahmen (z.B.: Impfung, Optimierung Futter / Wasser / Stallklima,
                Hygienemaßnahmen)
            </mat-label>
            <textarea (focus)="_11_focus = true" (focusout)="_11_focus = false" (textChanged)="setValue10and11($event)"
                      [formControl]="_11_1_1" [kieneTextBaustein]="getTextbaustein10und11()" matInput></textarea>
        </mat-form-field>
        <mat-form-field class="phito-field hundred">
            <mat-label>Zeitraum der Umsetzung</mat-label>
            <textarea (focus)="_11_focus = true" (focusout)="_11_focus = false" (textChanged)="setValue10and11($event)"
                      [formControl]="_11_2_1" [kieneTextBaustein]="getTextbaustein10und11()" matInput></textarea>
        </mat-form-field>

        <mat-divider style="margin-bottom: 40px"></mat-divider>

        <mat-form-field class="phito-field">
            <input [formControl]="_11_3_1" matInput placeholder="Ort" type="text"/>
        </mat-form-field>

        <mat-form-field>
            <input [formControl]="_11_3_2" [matDatepicker]="picker_11_3" matInput placeholder="Datum"/>
            <mat-datepicker-toggle [for]="picker_11_3" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker_11_3></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="phito-field thirty">
            <input [formControl]="_11_3_3" matInput placeholder="Angehöriger / Verantwortlicher des Betriebes"
                   type="text"/>
        </mat-form-field>
        <br/>
        <div class="one-row">
            <!-- <button *ngIf="mpr.status_id !== 62" mat-raised-button color="primary" (click)="save(false)">Zwischenspeichern</button> -->
            <ng-container *ngIf="isKunde() && !mpr?.unterschrift_kunde">
                <button mat-flat-button color="accent" (click)="signMnp()">
                    <mat-icon>edit_note</mat-icon>
                    <span>unterschreiben</span>
                </button>
            </ng-container>
            <ng-container *ngIf="!isKunde()">
                <kiene-sign-button (kuid)="mpr.kuid = $event" [kuid]="mpr.kuid" [kunde_id]="mpr.kunde_id"
                                   [tierarzt_id]="mpr.tierarzt_id"></kiene-sign-button>
            </ng-container>
            <span class="spacer"></span>
            <ng-container *kienePermission="[permissionAbschliessen]">
                <button (click)="save(true)" *ngIf="mpr.status_id !== 62" color="primary" mat-raised-button>
                    Abschließen
                </button>
            </ng-container>
        </div>
    </ng-container>
</div>
