import { BestellungInternPosition } from "./bestellung-intern-position";

export class BestellungIntern {
  bestellung_id: number;
  behandlungsanfrage: number;
  kunde_id: number;
  kunde: string;
  bezeichnung: string;
  erstellt: Date;
  positionen: BestellungInternPosition[] = [];
  status_id: number;
  status: string;
  zieldatum: Date;
  tierarzt_id: number;
  tierarzt: string;
}

export interface BestellungInternApi{
  records: BestellungIntern[];
  count: number;
}
