import { log } from 'console';
import { ZeitauswahlChange } from './zeitauswahl/zeitauswahl-change';

export class ReportUtils {

    public static getVorjahreszeitraum(input: ZeitauswahlChange): ZeitauswahlChange {
        const von = new Date(input.von);
        const bis = new Date(input.bis);
        let vonVorjahr: Date;
        let bisVorjahr: Date;
        if (von.getDate() === 29 && von.getMonth() === 1) {
            vonVorjahr = new Date(
                von.getFullYear() - 1,
                1,
                28
            );
        } else {
            vonVorjahr = new Date(
                von.getFullYear() - 1,
                von.getMonth(),
                von.getDate()
            );
        }
        if (bis.getDate() === 29 && bis.getMonth() === 1) {
            bisVorjahr = new Date(
                bis.getFullYear() - 1,
                1,
                28
            );
        } else {
            bisVorjahr = new Date(
                bis.getFullYear() - 1,
                bis.getMonth(),
                bis.getDate()
            );
        }
        return {
            von: vonVorjahr,
            bis: bisVorjahr,
            aggregieren: input.aggregieren,
            aktuellerZeitinterval: input.aktuellerZeitinterval,
        };
    }

}