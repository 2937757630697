<div [style.padding]="dialog ? '0' : '0 24px 64px 24px'">

    <kiene-page-header *ngIf="!dialog" [ueberschrift]="'Tier bearbeiten'" [routeRelativeAlternative]="'../'">
    </kiene-page-header>

    <div *ngIf="!gruppeTier" [style.padding]="dialog ? '24px 0' : '128px 0 0 0'" style="text-align: center">
        <mat-hint class="hinweis-text">Lade Tier...</mat-hint>
    </div>

    <div *ngIf="gruppeTier">
        <div style="padding: 12px 0">
            <h3>Ohrmarke: {{gruppeTier?.ohrmarke_notnull}} </h3>
        </div>

        <div style="padding-bottom: 24px">
            <mat-checkbox [(ngModel)]="gruppeTier.pass_gescannt"
                (ngModelChange)="gruppeTier.pass_gescannt = $event ? 1 : 0">
                Pass vorhanden?
            </mat-checkbox>
        </div>

        <mat-form-field style="width: 100%; padding-bottom: 24px" class="no-padding-margin-mat-form-field">
            <mat-label>Abteil</mat-label>
            <input matInput [placeholder]="'Abteil'" [formControl]="abteilCtrl" />
        </mat-form-field>

        <div
            style="display: flex; justify-content: space-around; align-items: center; width: 100%; padding-bottom: 48px">
            <mat-form-field class="no-padding-margin-mat-form-field"
                style="width: 90% ;display:flex; flex-direction: column ; justify-content: space-around;">
                <mat-label>H&auml;ndler ausw&auml;hlen</mat-label>
                <mat-select [compareWith]="compareHaendler" [formControl]="haendlerCtrl">
                    <mat-option *ngFor="let haendler of haendlerFavoriten"
                        [value]="haendler">{{haendler?.name}}</mat-option>
                </mat-select>
                <mat-hint style="padding-top: 24px" *ngIf="emptyListHaendler">
                    Es ist kein H&auml;ndler gespeichert. Ggf. über das + Symbol einen H&auml;ndler hinzufügen.
                </mat-hint>
            </mat-form-field>
            <button (click)="openHaendlerVerwaltung()" mat-icon-button color="accent">
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <mat-form-field style="width: 100%">
            <mat-label>Einstalldatum</mat-label>
            <input required matInput [matDatepicker]="einstallpicker" [formControl]="einstalldatumCtrl"
                [readonly]="true">
            <mat-datepicker-toggle matSuffix [for]="einstallpicker"></mat-datepicker-toggle>
            <mat-datepicker #einstallpicker></mat-datepicker>
            <mat-error *ngIf="einstalldatumCtrl.hasError('required')">Das Einstalldatum ist ein Pflichtfeld</mat-error>
            <mat-error *ngIf="einstalldatumCtrl.hasError('pattern')">Bitte im Format dd.mm.yyyy</mat-error>
        </mat-form-field>

        <mat-form-field style="width: 100%; margin-bottom: 12px" class="no-padding-margin-mat-form-field">
            <mat-label>Abgangsdatum</mat-label>
            <input matInput [matDatepicker]="abgangspicker" [formControl]="abgangsdatumCtrl" [readonly]="true">
            <mat-datepicker-toggle matSuffix [for]="abgangspicker"></mat-datepicker-toggle>
            <mat-datepicker #abgangspicker></mat-datepicker>
            <mat-hint class="hinweis-text" style="font-size: 0.9em" *ngIf="gruppeTier?.abgangsdatum_notnull">
                Tier wurde noch nicht als Abgang gemeldet
            </mat-hint>
            <mat-error *ngIf="einstalldatumCtrl.hasError('pattern')">Bitte im Format dd.mm.yyyy</mat-error>
        </mat-form-field>

        <div [style]="dialog ? 'position: sticky; bottom: 0; background-color: white; padding-top: 12px;' : ''"
            class="one-row">
            <button [disabled]="isLoading" (click)="updateGruppeTier()" mat-flat-button
                color="accent">Speichern</button>
            <span class="spacer"></span>
            <ng-content select="[additional-actions]"></ng-content>
        </div>

    </div>

</div>
