<h1 mat-dialog-title>HIT Daten</h1>
<div mat-dialog-content>
    <table>
        <tr>
            <td>VVVVO:</td>
            <td>{{vvvo}}</td>
            <td>
                <button (click)="copyVVVO()" mat-icon-button>
                    <mat-icon>content_copy</mat-icon>
                </button>
            </td>
        </tr>
        <tr>
            <td>PIN:</td>
            <td>{{pin}}</td>
            <td>
                <button (click)="copyPin()" mat-icon-button>
                    <mat-icon>content_copy</mat-icon>
                </button>
            </td>
        </tr>
    </table>
</div>
<div mat-dialog-actions>
    <button (click)="dialogRef.close()" mat-button>Schlie&szlig;en</button>
</div>
