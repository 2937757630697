import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TabinfoService {

    reloadSubjects: Subject<boolean>[] = [];

    constructor() {
    }

    setReload(name: string) {
        this.reloadSubjects[name].next(true);
    }

    watchReload(name: string): Observable<boolean> {
        if (!this.reloadSubjects[name]) {
            this.reloadSubjects[name] = new Subject<boolean>();
            console.log('registered tabinfo reload subject ' + name + ': ' + (this.reloadSubjects[name] !== undefined));
        }
        return this.reloadSubjects[name].asObservable();
    }
}
