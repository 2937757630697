import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TourenplanApi, Tourenplan } from './tourenplan';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TourenplanService {

  constructor(private http: HttpClient) {
  }

  assignToVet(tourenplan: Tourenplan) {
    return this.http.post(environment.BASE_URI + 'tourenplan_tierarzt/update.php', tourenplan);
  }
}
