import { formatNumber } from '@angular/common';
import { Component, Input, LOCALE_ID, OnInit } from '@angular/core';
import { CsvColumn } from './csv-column';

@Component({
  selector: 'kiene-csv-export',
  templateUrl: './csv-export.component.html',
  styleUrls: ['./csv-export.component.css']
})
export class CsvExportComponent implements OnInit {

  @Input('columns') columns: CsvColumn[] = [];
  @Input('data') data: any[] = [];
  @Input('filename') filename = 'download.csv';

  constructor() { }

  ngOnInit(): void {
  }

  export(){
    if(this.columns.length > 0){
      let csv = '';
      csv = this.createCsvColumns(csv);
      csv = this.createData(csv);
      console.debug(csv);

      let a = document.createElement('a');
      a.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv));
      a.setAttribute('download', this.filename);
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  createCsvColumns(csv: string): string{
    for(let c of this.columns){
      csv += c.displayName + ';';
    }
    csv = csv.substr(0, csv.length -1);
    csv += '\n';
    return csv;
  }

  createData(csv: string): string {

    for(let d of this.data){
      for(let c of this.columns){
        let value = d[c.name];
        if(value != undefined && value != null){
          if(this.isNumber(value)){
            value = formatNumber(value, 'de', '1.0-2');
          }
          if(c.unit){
            value += c.unit;
          }
        } else {
          value = '';
        }
        csv += value + ';';
      }
      csv = csv.substr(0, csv.length -1);
      csv += '\n';
    }

    return csv;
  }

  isNumber(n: any): boolean{
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

}
