import { Haendler } from '../../../../../projects/kiene-core/src/lib/components/stammdaten/haendler/haendler';
import { StammdatenService } from '../../../../../projects/kiene-core/src/lib/components/stammdaten/stammdaten.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { MessageService } from '../../../services/message.service';
import { KieneApi } from '../../../components/kiene-table/kiene-api';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { Benutzerprofil } from '../../../../../projects/kiene-core/src/lib/components/stammdaten/mein-profil/mein-profil';
import { KieneBackendApiService } from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TimeSlot, TimeSlotDay, ZeitslotBuchung } from '../zeitslot-buchen/zeitslot-buchen.component';

@Component({
  selector: 'kiene-zeitslot-buchung-aendern',
  templateUrl: './zeitslot-buchung-aendern.component.html',
  styleUrls: ['./zeitslot-buchung-aendern.component.scss']
})
export class ZeitslotBuchungAendernComponent implements OnInit {

  haendlerFavoritenSubscription: Subscription;
  favoritenHaendler: Haendler[];
  benutzerprofil = new Benutzerprofil();
  standard_schlachthof: any;

  days: any[] = [];

  selectedHaendlerId: number = -1;
  selectedSlot: number = -1;
  maxCapacity: number = 0;
  countCtrl: UntypedFormControl = new UntypedFormControl(null,[Validators.required, Validators.max(this.maxCapacity), Validators.min(1)]);
  haendlerCtrl: UntypedFormControl = new UntypedFormControl(this.selectedHaendlerId, [Validators.required]);
  bookingValid: boolean = false;
  noSlotsAvailable: boolean = false;
  gruppe: any;
  zeitslot_buchung_id: number;
  zeitslot_buchung: any;
  currentDate: Date = new Date();
  umbuchen: boolean = false;

  constructor(
    private api: KieneBackendApiService,
    private http: HttpClient,
    private stammdatenService: StammdatenService,
    private messageService: MessageService,
    protected _router: Router,
    private route: ActivatedRoute,
    @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
  ) {
    this.zeitslot_buchung_id = Number(this.route.snapshot.paramMap.get('zeitslot_buchung_id'));
    this.route.queryParams.forEach(x => {
      if(x.umbuchen){
        this.umbuchen = x.umbuchen;
      }
    })
    console.log(this.umbuchen);
    this.countCtrl.disable();
    this.haendlerCtrl.disable();
  }

  ngOnInit(): void {
    this.ladeZeitslotBuchung();
    this.ladeBenutzerprofil();
    this.ladeFavoriten();
  }

  ngOnDestroy(): void {
    this.haendlerFavoritenSubscription?.unsubscribe();
  }
  
  ladeFavoriten(): void {
    this.haendlerFavoritenSubscription?.unsubscribe();
    this.haendlerFavoritenSubscription = this.stammdatenService.loadHaendlerFavoriten().subscribe({
        next: (data) => {
            this.favoritenHaendler = [];
            this.favoritenHaendler = data.records;
        },
        error: (err: any) => {
            this.messageService.errorMessage(
                'Fehler beim Laden aller Händler',
                err
            );
        },
    });
}

  protected buchungAbschliessen(): void {
    //TODO change to update booking
    /*let buchung = new ZeitslotBuchung();
    buchung.tierzahl = this.countCtrl.value;
    buchung.zeitslot_id = this.selectedSlot;
    buchung.haendler_id = this.haendlerCtrl.value;
    buchung.gruppen_id = this.gruppen_id;
    buchung.geaendert_von = this.benutzerprofil.benutzer_id;

    this.api.createGeneric(this.apiBaseUrl + 'zeitslot_buchung/create_buchung.php', buchung).subscribe({
      next: (response: TimeSlot) => {
        this.messageService.infoMessage(
          'Buchung wurde aufgegeben.'
        );
        this._router.navigate(['/gruppen', 'schweine-gruppen']).then();
      },
      error: error => {
          if (error.status === 400) {
            this.messageService.alertMessage(error.error.errortext);
          } else {
            this.messageService.errorMessage('Es ist ein Fehler aufgetreten: ', error);
          }
      }
    });*/
    if(this.umbuchen){
      this.buchungUmbuchen();
    } else {
      this.buchungAbschliessen();
    }
  }

  protected leaveAfterDone(){
    this._router.navigate(['/gruppen', 'zeitslot-historie']).then();
  }

  protected buchungAendern(){
    //TODO update buchung

    this.leaveAfterDone();
  }

  protected buchungUmbuchen(){
    //TODO create new booking
    let buchung = new ZeitslotBuchung();
    buchung.tierzahl = this.countCtrl.value;
    buchung.zeitslot_id = this.selectedSlot;
    buchung.haendler_id = this.haendlerCtrl.value;
    buchung.gruppen_id = this.zeitslot_buchung.gruppen_id;
    buchung.geaendert_von = this.benutzerprofil.benutzer_id;

    this.api.createGeneric(this.apiBaseUrl + 'zeitslot_buchung/create_buchung.php', buchung).subscribe({
      next: (response: TimeSlot) => {
        this.messageService.infoMessage(
          'Buchung wurde aufgegeben.'
        );

        //TODO set old buchung storniert
        this.leaveAfterDone();
      },
      error: error => {
          if (error.status === 400) {
            this.messageService.alertMessage(error.error.errortext);
          } else {
            this.messageService.errorMessage('Es ist ein Fehler aufgetreten: ', error);
          }
      }
    });
  }

  protected selectTimeSlot(slot : TimeSlot){
    if(slot.anzahl_tiere > 0){
      this.selectedSlot = slot.zeitslot_id;
      this.maxCapacity = slot.anzahl_tiere;
      this.countCtrl.enable();
      this.countCtrl.setValidators([Validators.required, Validators.max(this.maxCapacity), Validators.min(1)]);
      this.countCtrl.updateValueAndValidity();
      this.haendlerCtrl.enable();
      console.log(slot);

      this.checkBookingValidity();
    }
  }

  checkTraderAssignment(){
    //TODO check if trader possible
    
    this.checkBookingValidity();
  }

  checkCapacityAssignment(){
    //TODO check if too much assigned
    
    this.checkBookingValidity();
  }

  checkBookingValidity(){
    this.bookingValid = this.haendlerCtrl.valid && this.selectedSlot != -1 && this.countCtrl.valid;
  }

  ladeVerfuegbareZeitslots(){
    //TODO add timeframe to request
    let params = new HttpParams().append('betrieb_id', this.benutzerprofil.default_schlachthof_id);
    let fromDate = new Date();
    fromDate.setHours(0,0,0,0);
    let toDate = new Date(fromDate.getTime() + (1000 * 60 * 60 * 24)*7);
    toDate.setHours(23,59,59,0);
    params = params.append("zeit_von", fromDate.toISOString());
    params = params.append("zeit_bis", toDate.toISOString())

    this.get(this.apiBaseUrl + "/zeitslot/read.php", params).subscribe(
      x => {
        this.days = [];
        this.noSlotsAvailable = true;
        for(let i = 0; i < 7; i++){
          let day = new TimeSlotDay();
          day.slots = [];
          day.day = new Date()
          day.day.setDate(day.day.getDate() + i);
          day.day.setHours(0,0,0,0);
          this.days.push(day)
        }

        x.records.forEach(slot => {

          // get day of slot
          var slotDate = new Date(slot.datum_uhrzeit_von);
          slotDate.setHours(0,0,0,0);

          // compare if present in days
          var dayPresent = undefined; //Define type
          //this.days.find
          this.days.forEach(day => {
            if(day.day.getTime() == slotDate.getTime()){
              dayPresent = day;
            }
          });
          
          // add day if needed
          // add slot in day
          // assume entries are already sorted since backend schould give back sorted
          if(dayPresent){
            dayPresent.slots.push(slot);
          } else {
            var slotDay = new TimeSlotDay();
            slotDay.day = slotDate;
            slotDay.slots = [];
            slotDay.slots.push(slot);
            this.days.push(slotDay);
          }

          this.noSlotsAvailable = this.noSlotsAvailable && false;
        })
      }
    );
  }

  public get(url: string, params?: HttpParams, headers?: HttpHeaders): Observable<KieneApi> {
    return this.http.get<KieneApi>(url, { params: params, headers: headers });
  }
  
  ladeBenutzerprofil() {
    this.api.get(
      `${this.apiBaseUrl}benutzerprofil/read_one_kundenportal.php`)
    .subscribe({
      next: (response: Benutzerprofil) => {
        this.benutzerprofil = response;
        this.haendlerCtrl.setValue(this.benutzerprofil.default_haendler_id);
        if(this.benutzerprofil.default_schlachthof_id){
          this.ladeSchlachthofDaten();
          this.ladeVerfuegbareZeitslots();
        }
        console.log(this.selectedHaendlerId);
      },
      error: err => {
        this.messageService.errorMessage('Profil konnte nicht geladen werden!', err);
      }
    });
  }

  ladeSchlachthofDaten(){
    if(this.benutzerprofil?.default_schlachthof_id){
      const params = new HttpParams().set(
        'betrieb_id',
        this.benutzerprofil.default_schlachthof_id.toString()
      );
      this.api
          .get(
              `${this.apiBaseUrl}schlachthoefe/read_one.php`,
              params)
          .subscribe({
              next: (response: any) => {
                this.standard_schlachthof = response;
              },
              error: err => {
                  this.messageService.errorMessage('Profil konnte nicht geladen werden!', err);
              }
          });
    }
  }

  ladeGruppenDaten(){
    if(this.zeitslot_buchung?.gruppen_id){
      const params = new HttpParams().set(
        'gruppe_id',
        this.zeitslot_buchung.gruppen_id
      );
      this.api
          .get(
              `${this.apiBaseUrl}gruppe-schweine/read_one.php`,
              params)
          .subscribe({
              next: (response: any) => {
                this.gruppe = response
                this.maxCapacity = this.gruppe.anzahl_tiere;
              },
              error: err => {
                  this.messageService.errorMessage('Gruppe konnte nicht geladen werden!', err);
              }
          });
    }
  }

  ladeZeitslotBuchung(){
    if(this.zeitslot_buchung_id){
      const params = new HttpParams().set(
        'zeitslot_buchung_id',
        this.zeitslot_buchung_id
      );
      this.api
          .get(
              `${this.apiBaseUrl}zeitslot_buchung/read_one.php`,
              params)
          .subscribe({
              next: (response: any) => {
                this.zeitslot_buchung = response
                console.log(this.zeitslot_buchung);
                this.updateBuchungsdaten();
                this.ladeGruppenDaten();
              },
              error: err => {
                  this.messageService.errorMessage('Buchung konnte nicht geladen werden!', err);
              }
          });
    }
  }

  updateBuchungsdaten(){
    this.countCtrl.setValue(this.zeitslot_buchung.tierzahl);
    this.haendlerCtrl.setValue(this.zeitslot_buchung.haendler_id);
    this.selectedSlot = this.zeitslot_buchung.zeitslot_id;
  }
}
