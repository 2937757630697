import { ConfirmDialogInput, ConfirmDialogResult } from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import { ConfirmDialogComponent } from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'kiene-dialog-dropdown',
  templateUrl: './dialog-dropdown.component.html',
  styleUrls: ['./dialog-dropdown.component.scss']
})
export class DialogDropdownComponent implements OnInit {

@Input('dropdown') dropdown;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogInput,
  ) { }

  ngOnInit(): void {
  }

  cancel() {
      const result = new ConfirmDialogResult();
      result.ok = false;
      this.dialogRef.close(result);
  }

  ok() {
      const result = new ConfirmDialogResult();
      result.ok = true;
      result.confirmed = true;
      this.dialogRef.close(result);
  }

}
