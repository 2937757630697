import { Component, Input, OnInit } from '@angular/core';
import { MapGeocoderResponse, MapMarker } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'kiene-standort',
  templateUrl: './standort.component.html',
  styleUrls: ['./standort.component.scss']
})
export class StandortComponent implements OnInit {

  @Input('standort_daten') standort_daten;
  @Input('schlachthof_daten') schlachthof_daten;
  private options: any;
  center: any;
  markerPosition: any;
  schlachthofPosition: any;
  icon = '../../../../assets/lkw_icon.svg';

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.setGoogleMaps();
  }

  ngOnChanges(): void {
    if(this.schlachthof_daten){
      this.updateGoogleMaps();
    }
  }

  setGoogleMaps(): void {
    var split = this.standort_daten.split(',');
    this.markerPosition = new google.maps.LatLng(split[0], split[1]);
  }

  updateGoogleMaps(): void {
    var split2 = this.schlachthof_daten.split(',');
    this.schlachthofPosition = new google.maps.LatLng(split2[0], split2[1]);
    // var marker: MapMarker;
    
    // marker.setIcon();
    
    // this.markerPosition = 
  }

}
