import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatBadgeModule } from "@angular/material/badge";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TabinfoComponent } from "./tabinfo.component";

@NgModule({
    declarations: [TabinfoComponent],
    imports: [CommonModule, MatIconModule, MatBadgeModule],
    exports: [TabinfoComponent]
})
export class TabinfoModule {}
