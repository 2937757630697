import { Component, Inject, OnInit } from '@angular/core';
import { Gruppe } from '../../documents/gruppenverwaltung/gruppe';
import { GruppeTier } from '../../administration/client-group/gruppe-tier';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../services/message.service';
import { HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'kiene-zugang-melden-dialog',
    templateUrl: './zugang-melden-dialog.component.html',
    styleUrls: ['./zugang-melden-dialog.component.scss']
})
export class ZugangMeldenDialogComponent implements OnInit {

    gruppe: Gruppe;
    isLoading = true;

    tiereZumMelden: GruppeTier[];
    abgangsdatumCtrl = new UntypedFormControl(new Date(), [Validators.required]);

    constructor(public dialogRef: MatDialogRef<ZugangMeldenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private api: KieneTableService,
        private messageService: MessageService) {
        this.gruppe = this.data.gruppe;
    }

    ngOnInit(): void {
        this.loadTiereZumMelden();
    }

    loadTiereZumMelden() {
        let params = new HttpParams();
        params = params.append('gruppe_id', this.gruppe.gruppe_id.toString());
        params = params.append('status_id_einstallen', '140');
        params = params.append('orderby', 'kp_einstalldatum desc');

        this.api.get(environment.BASE_URI + 'gruppe_tier/read_kundenportal.php', params).subscribe({
            next: response => {
                this.isLoading = false;
                this.tiereZumMelden = response.records
                    .filter(tier => tier.csv_import >= 1 || (tier.pass_gescannt >= 1 && tier.ohrmarke_gescannt >= 1));
            },
            error: err => {
                this.isLoading = false;
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    close() {
        this.dialogRef.close();
    }

    save() {

        this.api.post(environment.BASE_URI + 'gruppe_tier/einstallen_hit_kundenportal.php', this.gruppe, undefined).subscribe({
            next: () => {
                this.messageService.infoMessage('Die Zugänge wurden erfolgreich gemeldet');
                this.dialogRef.close(true);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}

