import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {ArtikelTierart} from '../../../components/tierart-selector/artikel-tierart';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {MessageService} from '../../../services/message.service';
import {Anwendung} from '../anwendung';
import {BehandlungsanfrageService} from '../behandlungsanfrage.service';
import {Identifikation} from '../identifikation';
import {Behandlungsanfrage, BehandlungsanfragePosition, BestellungStatus} from '../behandlungsanfrage';
import {RecPositionService} from '../../../services/rec-position.service';
import {RecPositionObject} from '../../../services/rec-position';
import {Abgabebeleg} from '../../abgabebeleg/abgabebeleg';
import {AppService} from '../../../app.service';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {Gruppe} from '../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import {KieneSession} from '../../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import {BelegPositionInlineComponent} from '../../beleg-position/beleg-position-inline/beleg-position-inline.component';
import {
    ChangeCurrentClientService
} from '../../../administration/client/change-current-client/change-current-client.service';
import {Location} from '@angular/common';
import {
    GruppeAuswaehlenSelektorComponent
} from '../../../../../projects/kiene-core/src/lib/components/gruppen/gruppe-auswaehlen-selektor/gruppe-auswaehlen-selektor.component';
import {BelegPositionInlineResult} from '../../beleg-position/beleg-position-inline/beleg-position-inline';
import {Mandant} from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'kiene-behandlungsanfrage-detail',
    templateUrl: './behandlungsanfrage-detail.component.html',
    styleUrls: ['./behandlungsanfrage-detail.component.scss'],
    host: {'(window:keydown)': 'checkForArrowKeys($event)'},
})
export class BehandlungsanfrageDetailComponent implements OnInit {

    @ViewChild('positionInline') positionInline: BelegPositionInlineComponent;
    @ViewChild('gruppeSelektor') gruppeSelektor: GruppeAuswaehlenSelektorComponent;
    behandlungsanfrageId: string;
    behandlungsanfrage: Behandlungsanfrage = new Behandlungsanfrage();

    clientGroup: Gruppe = new Gruppe();

    descriptionCtrl: UntypedFormControl = new UntypedFormControl();

    selectedTierarztId: number;

    identifikationen: Observable<Identifikation[]>;
    anwendungen: Anwendung[] = [];

    zielDatumCtrl: UntypedFormControl = new UntypedFormControl(new Date());

    apothekeCtrl = new UntypedFormControl();

    releaseAllowed = false;

    tierarten: ArtikelTierart[] = [];

    kieneSession: KieneSession;

    disabled = true;

    // query parameters for getting the neighbours of the entry
    orderby: string;
    search: string;
    recposition: number;
    recPositionObject: RecPositionObject = new RecPositionObject();
    aktuelleGruppe: Gruppe;

    mandantid: number;
    mandantname: string;

    mandant: Mandant;
    protected readonly BestellungStatus = BestellungStatus;

    constructor(
        private localStorageService: LocalStorageService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private clientChangeService: ChangeCurrentClientService,
        private messageService: MessageService,
        private api: BehandlungsanfrageService,
        private positionService: RecPositionService,
        public dialog: MatDialog,
        private appService: AppService
    ) {
        this.behandlungsanfrageId = this.route.snapshot.paramMap.get('id');

        this.route.queryParams.subscribe((params) => {
            this.orderby = params['orderby'];
            this.search = params['search'];
            this.recposition = params['recposition'];
            this.mandantid = params['mandantid'];
            this.mandantname = params['mandantname'];
        });

        this.kieneSession = this.localStorageService.getCurrentUser();
    }

    ngOnInit() {
        // this.currentPosition = -1;

        if (this.mandantid && this.mandantname) {
            this.mandant = new Mandant();
            this.mandant.mandant_id = this.mandantid;
            this.mandant.bezeichnung = this.mandantname;
        }

        this.localStorageService.watchCurrentClient().subscribe((client) => {
            if (!this.behandlungsanfrage.kunde_id) {
                this.behandlungsanfrage.kunde_id = client.kunde_id;
                this.behandlungsanfrage.kunde = client.bezeichnung_final;
            }
        });


        if (this.behandlungsanfrageId !== 'neu') {
            this.loadBehandlungsanfrage();
        } else {
            this.zielDatumCtrl.setValue(new Date());
            this.behandlungsanfrage.status_id = 1;
            if (this.localStorageService.getCurrentClient()) {
                this.behandlungsanfrage.kunde_id =
                    this.localStorageService.getCurrentClient().kunde_id;
                this.behandlungsanfrage.kunde =
                    this.localStorageService.getCurrentClient().bezeichnung_final;
            }
            if (this.mandantid > -1) {
                this.disabled = false;
            }
            if (!this.behandlungsanfrage.kunde_id) {
                this.clientChangeService.openChangeDialog('behandlungsanfrage details');
            }
        }
    }

    reload() {
        this.loadBehandlungsanfrage();
    }

    loadBehandlungsanfrage() {
        this.appService.setDataLoading(true);
        this.loadNeighbours();
        // this.autocompleteMaterial();
        this.api
            .getById(Number.parseInt(this.behandlungsanfrageId, 10))
            .subscribe((response) => {
                this.appService.setDataLoading(false);
                this.behandlungsanfrage = response;
                if (this.behandlungsanfrage.status_id === 2) {
                    this.releaseAllowed = true;
                }

                this.selectedTierarztId = this.behandlungsanfrage.tierarzt_id;
                this.zielDatumCtrl.setValue(this.behandlungsanfrage.zieldatum);
                this.descriptionCtrl.setValue(
                    this.behandlungsanfrage.bezeichnung
                );
                this.apothekeCtrl.setValue(
                    this.behandlungsanfrage.apotheke === 1
                );
                if (this.behandlungsanfrage.status_id > 3) {
                    this.apothekeCtrl.disable();
                }
                this.mandantid = this.behandlungsanfrage.mandant_id;
                this.mandant = new Mandant();
                this.mandant.mandant_id = this.behandlungsanfrage.mandant_id;
                this.mandant.bezeichnung = this.behandlungsanfrage.mandant;
                this.checkStatus();

            });
    }

    takeoverCurrentClient() {
        const currentClient = this.localStorageService.getCurrentClient();
        if (currentClient) {
            this.behandlungsanfrage.kunde_id = currentClient.kunde_id;
            this.behandlungsanfrage.kunde = currentClient.bezeichnung_final;
        }
    }

    save(statusId: number) {
        if (!this.behandlungsanfrage.kunde_id) {
            this.messageService.alertMessage(
                'Bitte erst einen Betrieb auswählen! (ALT+B)'
            );
            return;
        }

        this.behandlungsanfrage.status_id = statusId;
        this.behandlungsanfrage.bezeichnung = this.descriptionCtrl.value;
        this.behandlungsanfrage.zieldatum = this.zielDatumCtrl.value;

        this.behandlungsanfrage.apotheke =
            this.apothekeCtrl.value === true ? 1 : 0;

        const wasNew = this.behandlungsanfrageId === 'neu';

        this.api.save(this.behandlungsanfrage, this.mandantid).subscribe({

            next: (response) => {
                this.messageService.infoMessage(
                    'Die Behandlungsanfrage wurde erfolgreich gespeichert!'
                );
                this.behandlungsanfrage = response;
                this.checkStatus();
                this.loadMandant();
                if (wasNew) {
                    this.location.replaceState(this.router.createUrlTree([
                        '/belege/behandlungsanfragen',
                        this.behandlungsanfrage.bestellung_id,
                    ]).toString());
                }
            },
            error: (error) => {
                this.messageService.errorMessage(
                    'Die Behandlungsanfrage konnte nicht gespeichert werden!',
                    error
                );
            }

        });
    }

    goBack() {
        window.history.go(-1);
    }

    checkForArrowKeys(event) {
        if (event.altKey && event.code === 'ArrowRight') {
            this.navigateToNext();
        } else if (event.altKey && event.code === 'ArrowLeft') {
            this.navigateToPrevious();
        }

        /*else if (event.code === 'ArrowRight') {
            this.nextPosition();
        } else if (event.code === 'ArrowLeft') {
            this.previousPosition();
        } else if (event.code === 'F10') {
            this.removeCurrentRowFromDataSource();
        } else if (event.code === 'F12') {
            if (this.behandlungsanfrage.status_id < 2) {
                this.save(1);
            }
        }*/
    }

    loadNeighbours() {
        this.positionService
            .getNeighbours(
                'behandlungsanfrage',
                this.orderby,
                this.search,
                this.recposition
            )
            .subscribe((response) => {
                this.recPositionObject = response;
                this.recposition = this.recPositionObject.curr.recposition;
            });
    }

    navigateToPrevious() {
        this.behandlungsanfrageId = this.recPositionObject.prev.id.toString();
        this.recposition = this.recPositionObject.prev.recposition;
        this.loadBehandlungsanfrage();
    }

    navigateToNext() {
        this.behandlungsanfrageId = this.recPositionObject.next.id.toString();
        this.recposition = this.recPositionObject.next.recposition;
        this.loadBehandlungsanfrage();
    }

    /*nextPosition() {
        if (
            this.currentPosition <
            this.behandlungsanfrage.positionen.length - 1
        ) {
            this.currentPosition++;
        } else {
            this.currentPosition = -1;
        }
    }

    previousPosition() {
        if (this.currentPosition > 0) {
            this.currentPosition--;
        } else if (this.currentPosition === -1) {
            this.currentPosition =
                this.behandlungsanfrage.positionen.length - 1;
        } else if (this.currentPosition === 0) {
            this.currentPosition = -1;
        }
    }*/

    removePosition(p: BehandlungsanfragePosition) {
        const index = this.behandlungsanfrage.positionen.indexOf(p);
        this.behandlungsanfrage.positionen.splice(index, 1);
    }

    /*removeCurrentRowFromDataSource() {
        if (this.currentPosition !== -1) {
            this.behandlungsanfrage.positionen.splice(this.currentPosition, 1);
        }
    }*/

    transformToAbgabebeleg() {
        const input = new ConfirmDialogInput();
        input.headline = 'In Abgabebeleg umwandeln';
        input.text =
            'Wollen Sie die Behandlungsanfrage wirklich in einen Abgabebeleg umwandeln?';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (response: ConfirmDialogResult) => {
                if (response?.confirmed) {
                    this.api
                        .transformToAbgabebeleg(this.behandlungsanfrage)
                        .subscribe({

                            next: (beleg) => {
                                const abgabebeleg: Abgabebeleg = beleg;
                                this.messageService.infoMessage(
                                    'Die Behandlungsanfrage wurde erfolgreich in einen Abgabebeleg umgewandelt!'
                                );
                                this.router.navigate([
                                    'belege',
                                    'abgabebelege',
                                    abgabebeleg.abgabebeleg_id,
                                ]).then();
                            },
                            error: (error) => {
                                this.messageService.errorMessage(
                                    'Behandlungsanfrage konnte nicht in ein Abgabebeleg umgewandelt werden!, ',
                                    error
                                );
                            }

                        });
                }
            },
        });
    }

    duplicate() {
        this.api.duplicate(this.behandlungsanfrage, this.behandlungsanfrage.mandant_id).subscribe({

            next: (response) => {
                this.messageService.infoMessage(
                    'Die Behandlungsanfrage wurde erfolgreich dupliziert!'
                );
                this.router.navigate([
                    'belege',
                    'behandlungsanfragen',
                    response.bestellung_id,
                ]).then();
            },
            error: (error) => {
                this.messageService.errorMessage(
                    'Die Behandlungsanfrage konnte nicht dupliziert werden!',
                    error
                );
            }

        });
    }

    assignToMe() {
        this.behandlungsanfrage.tierarzt_id = this.kieneSession.tierarzt_id;
        this.save(this.behandlungsanfrage.status_id);
    }

    positionenSpeichern(result: BelegPositionInlineResult) { // eigentlich BehandlungsanfragePosition[]
        if (result?.positionen?.length > 0) {
            for (const posi of result.positionen) {
                const index = this.behandlungsanfrage?.positionen.findIndex((ba) => {
                    return ba.ba_id === posi.ba_id;
                });
                if (index > -1) {
                    this.behandlungsanfrage.positionen.splice(index, 1, posi);
                } else {
                    this.behandlungsanfrage?.positionen.push(posi);
                }
            }
            this.save(this.behandlungsanfrage.status_id);
            this.positionInline.felderLeeren(true);
        }
    }

    aktuellePositionEditieren(p: any) {
        if (p && this.canEditBehandlungsanfrage()) {
            this.positionInline.ladePosition(p);
            this.gruppeSelektor.setzeGruppe(p.gruppe_id);
        }

    }

    setzeGruppe(gruppe: Gruppe) {
        this.aktuelleGruppe = gruppe;
    }

    canEditBehandlungsanfrage() {
        // kunde
        if (this.localStorageService.isCurrentUserKunde()) {
            if (this.behandlungsanfrage.status_id < BestellungStatus.AUFGEGEBEN) {
                return true;
            }
        } else {
            if (this.behandlungsanfrage.status_id < BestellungStatus.KOMMISSIONIERT) {
                return true;
            }
        }
        return false;
    }

    private loadMandant() {

    }

    private checkStatus() {
        if (this.canEditBehandlungsanfrage()) {
            if (this.mandantid > -1) {
                this.disabled = false;
            }

        } else {
            this.disabled = true;
            this.zielDatumCtrl.disable();
            this.descriptionCtrl.disable();
        }
    }
}
