// kalender.service.ts
import {Injectable} from '@angular/core';
import {HeimtierKalenderEintrag} from './heimtier-kalender-eintrag';

@Injectable( {
    providedIn: 'root'
} )
export class KalenderService {

    // Mindestbreite pro Ereignis in Prozent (z.B. 30%)
    private minWidth = 30;
    // Gesamte verfügbare Breite für Ereignisse (90%)
    private maxWidth = 90;
    // Inkrement des linken Offsets für jedes zusätzliche Ereignis ab dem 4. in einer Gruppe
    private additionalOffset = 10;

    constructor() { }

    /**
     * Verarbeitet die Kalender-Einträge und gruppiert sie nach Tagen.
     * Berechnet zudem die Spaltenzuweisung für überlappende Ereignisse.
     *
     * @param kalenderEintraege Liste der Kalender-Einträge
     * @param dayNames Liste der Wochentagsnamen (z.B. ['Montag', 'Dienstag', ...])
     * @returns Ein nach Tagen gruppiertes Objekt mit den verarbeiteten Ereignissen
     */
    processEntries(kalenderEintraege: HeimtierKalenderEintrag[], dayNames: string[]): {
        [key: string]: HeimtierKalenderEintrag[]
    } {
        // Initialisiere das Ergebnis-Objekt, in dem die Einträge nach Tagen gruppiert werden.
        const gruppierteEintraege = this.initialisiereGruppierteEintraege( dayNames );
        // Gruppiere die Kalendereinträge nach Wochentagen.
        this.gruppiereKalenderEintraege( kalenderEintraege, gruppierteEintraege, dayNames );
        // Verarbeite jedes Ereignis, um die Position, Breite und Z-Index zu berechnen.
        this.verarbeiteEreignisseProTag( gruppierteEintraege, dayNames );
        return gruppierteEintraege;
    }

    /**
     * Bestimmt den Tagname basierend auf dem Datum.
     *
     * @param date Datum des Ereignisses
     * @param dayNames Liste der Wochentagsnamen
     * @returns Name des Tages oder null, falls ungültig
     */
    static getTagName(date: Date, dayNames: string[]): string | null {
        const tagIndex = date.getDay(); // 0 (Sonntag) bis 6 (Samstag)
        // Korrigiere den Index, da JavaScript 0 = Sonntag
        return dayNames[(tagIndex + 6) % 7] || null; // 0 -> Samstag, 1 -> Sonntag, etc.
    }

    /**
     * Initialisiert ein Objekt, in dem die Kalendereinträge nach Tagen gruppiert werden.
     *
     * @param dayNames Liste der Wochentagsnamen
     * @returns Ein Objekt, das für jeden Wochentag eine leere Liste enthält
     */
    private initialisiereGruppierteEintraege(dayNames: string[]): { [key: string]: HeimtierKalenderEintrag[] } {
        const gruppierteEintraege: { [key: string]: HeimtierKalenderEintrag[] } = {};
        dayNames.forEach( tag => {
            gruppierteEintraege[tag] = []; // Initialisiere jeden Tag mit einer leeren Liste.
        } );
        return gruppierteEintraege;
    }

    /**
     * Gruppiert die Kalender-Einträge nach den jeweiligen Tagen der Woche.
     *
     * @param kalenderEintraege Liste der Kalender-Einträge
     * @param gruppierteEintraege Objekt, in dem die Einträge nach Tagen gruppiert werden
     * @param dayNames Liste der Wochentagsnamen
     */
    private gruppiereKalenderEintraege(
        kalenderEintraege: HeimtierKalenderEintrag[],
        gruppierteEintraege: { [key: string]: HeimtierKalenderEintrag[] },
        dayNames: string[]
    ): void {
        kalenderEintraege.forEach( eintrag => {
            const tagName = KalenderService.getTagName( eintrag.von, dayNames );
            if ( tagName ) {
                gruppierteEintraege[tagName].push( eintrag ); // Füge den Eintrag zum entsprechenden Tag hinzu.
            }
        } );
    }

    /**
     * Verarbeitet die Ereignisse jedes Tages, indem Startzeit, Breite, Position und Z-Index festgelegt werden.
     *
     * @param gruppierteEintraege Nach Tagen gruppierte Ereignisse
     * @param dayNames Liste der Wochentagsnamen
     */
    private verarbeiteEreignisseProTag(
        gruppierteEintraege: { [key: string]: HeimtierKalenderEintrag[] },
        dayNames: string[]
    ): void {
        dayNames.forEach( tag => {
            const tagesEintraege = gruppierteEintraege[tag];
            if ( tagesEintraege.length === 0 ) return;

            // Sortiere die Ereignisse nach Startzeit und dann nach Endzeit.
            tagesEintraege.sort( (a, b) => {
                if ( a.von.getTime() !== b.von.getTime() ) {
                    return a.von.getTime() - b.von.getTime();
                }
                return a.bis.getTime() - b.bis.getTime();
            } );

            const groupedByStartTime: { [key: number]: HeimtierKalenderEintrag[] } = {};

            // Gruppiere Ereignisse nach ihrer Startzeit.
            tagesEintraege.forEach( event => {
                const startTimeKey = event.von.getTime();
                if ( !groupedByStartTime[startTimeKey] ) {
                    groupedByStartTime[startTimeKey] = [];
                }
                groupedByStartTime[startTimeKey].push( event );
            } );

            // Verarbeite jede Gruppe von Ereignissen mit gleicher Startzeit.
            Object.keys( groupedByStartTime ).forEach( startTimeKey => {
                const group = groupedByStartTime[startTimeKey];
                const groupSize = group.length;

                // Berechne die Breite für jedes Ereignis in dieser Gruppe (maximal 90% aufgeteilt).
                let availableWidth = this.maxWidth / groupSize;

                // Stelle sicher, dass jedes Ereignis mindestens minWidth (30%) bekommt.
                if ( availableWidth < this.minWidth ) {
                    availableWidth = this.minWidth;
                }

                // Verhindere, dass die Gesamtbreite 90% überschreitet.
                const totalWidth = availableWidth * groupSize;
                if ( totalWidth > this.maxWidth ) {
                    availableWidth = this.maxWidth / groupSize; // Reduziere zurück auf verfügbare Breite.
                }

                // Setze die Breite und Position für jedes Ereignis in der Gruppe.
                group.forEach( (event, index) => {
                    event.width = availableWidth;
                    event.left = index * availableWidth; // Position basierend auf dem Index (0%, 30%, 60%).
                    event.zIndex = index + 1; // Z-Index basierend auf der Reihenfolge innerhalb der Gruppe.
                } );
            } );

            // Verarbeite später beginnende Ereignisse, die innerhalb eines anderen Ereignisses starten.
            tagesEintraege.forEach( (currentEvent, currentIndex) => {
                // Prüfe, ob das aktuelle Ereignis mit vorherigen Ereignissen überschneidet.
                for ( let i = 0; i < currentIndex; i++ ) {
                    const previousEvent = tagesEintraege[i];
                    if ( previousEvent.bis > currentEvent.von ) {
                        // Überlappung festgestellt.
                        // Prüfe, ob das vorherige Ereignis zur gleichen Startzeit gehört.
                        if ( previousEvent.von.getTime() !== currentEvent.von.getTime() ) {
                            // Ereignis überschneidet sich mit einem vorherigen, hat aber eine andere Startzeit.
                            // Verschiebe das aktuelle Ereignis nach rechts.
                            currentEvent.left += this.additionalOffset; // Verschiebe weiter nach rechts (z.B. 10%).
                            currentEvent.width -= this.additionalOffset; // Verkleinere die Breite.
                            currentEvent.zIndex = currentIndex + 1; // Z-Index basierend auf der Reihenfolge innerhalb der Gruppe.
                        }
                        // Wenn die Startzeiten gleich sind, wird die Position bereits durch die Gruppierung korrekt gesetzt.
                    }
                }
            } );
        } );
    }
}
