export class ScanErgebnis {
    gt_id: number;
    success: boolean;
    abteil: string;
    anzahl_abteil: number;
    anzahl_viehhaendler: number;
    anzahl_tiere_match: number;
    anzahl_tiere: number;
    anzahl_tiere_datensaetze: number;
    ohrmarke_notnull: string;
    ohrmarke_gescannt: number;
    pass_gescannt: number;
    einstallgewicht: number;
}
