import { ConfirmDialogInput, ConfirmDialogResult } from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import { ConfirmDialogComponent } from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogSanitizeHtmlPipe } from './dialog-sanitize-html.pipe';
import {PdfViewerModule as ng2PdfViewerModule} from 'ng2-pdf-viewer';

@Component({
  selector: 'kiene-dialog-html',
  templateUrl: './dialog-html.component.html',
  styleUrls: ['./dialog-html.component.scss']
})
export class DialogHtmlComponent implements OnInit {

  @Input('base64') base64;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogInput,
  ) { }

  ngOnInit(): void {
  }

  cancel() {
      const result = new ConfirmDialogResult();
      result.ok = false;
      this.dialogRef.close(result);
  }

  ok() {
      const result = new ConfirmDialogResult();
      result.ok = true;
      result.confirmed = true;
      this.dialogRef.close(result);
  }

}
