<div mat-dialog-title>
    <h3>Abwesenheitsmeldung</h3>
</div>
<div mat-dialog-content>
    <div>
        <p *ngIf="!hasPermission()">Antragsteller: {{currentUser?.vorname}} {{currentUser?.nachname}}</p>
        <kiene-autocomplete (elementSelected)="selectedAntragssteller($event)" *ngIf="hasPermission()"
                            [displayFields]="['vorname', 'nachname']" [emitFreeText]="true"
                            [focus]="false" [genericApiUrl]="userPath" [kieneObject]="currentUser"
                            [params]="params"
                            [placeholder]="'Antragssteller'" [required]="true" [showClear]="false"
                            [showDeleteButton]="false" style=" width: 100%">
        </kiene-autocomplete>
    </div>
    <div class="one-row">
        <mat-form-field>
            <mat-label>Zeitraum wählen</mat-label>
            <mat-date-range-input
                [formGroup]="datumCtrl"
                [rangePicker]="picker"
            >
                <input
                    [formControl]="datumVonCtrl"
                    matStartDate
                    placeholder="Startdatum"
                />
                <input
                    [formControl]="datumBisCtrl"
                    matEndDate
                    placeholder="Enddatum"
                />
            </mat-date-range-input>
            <mat-datepicker-toggle
                [for]="picker"
                matSuffix
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error
                *ngIf="
                    datumCtrl.controls.datumVonCtrl.hasError(
                        'matStartDateInvalid'
                    )
                "
            >Ungültiges Startdatum.
            </mat-error
            >
            <mat-error
                *ngIf="
                    datumCtrl.controls.datumBisCtrl.hasError(
                        'matEndDateInvalid'
                    )
                "
            >Ungültiges Startdatum.
            </mat-error
            >
            <mat-error *ngIf="datumCtrl.hasError('required')"
            >Der Zeitraum ist ein Pflichtfeld.
            </mat-error
            >
        </mat-form-field>
    </div>

    <div class="one-row">
        <mat-form-field style="width: 100%">
            <mat-label>Anmerkungen</mat-label>
            <input [formControl]="beschreibungCtrl" matInput/>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button (click)="onNoClick()" mat-flat-button>Abbrechen</button>
    <button (click)="antragStellen()" color="accent" mat-flat-button>
        Stellen
    </button>
</div>
