import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {KieneTableService} from '../../kiene-table/kiene-table.service';
import {ArtikelMenge, Tourenplan} from '../tourenplan';
import {ArticleScanAutocompleteComponent} from '../../article-scan-autocomplete/article-scan-autocomplete.component';
import {UntypedFormControl} from '@angular/forms';
import {CompanyGroup} from '../../../administration/company-group/company-group';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {environment} from '../../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {AddArticlePositionDialogComponent} from '../add-article-position-dialog/add-article-position-dialog.component';
import {
    Behandlungsanfrage,
    BehandlungsanfragePosition,
} from '../../../documents/behandlungsanfrage/behandlungsanfrage';
import {MessageService} from '../../../services/message.service';
import {BehandlungsanfrageService} from '../../../documents/behandlungsanfrage/behandlungsanfrage.service';
import {Material} from '../../../warehouse/material/material';
import {HttpParams} from '@angular/common/http';
import {KieneUtils} from '../../../../../projects/kiene-core/src/lib/utils/KieneUtils';
import {CalendarWeek, KWDialogComponent} from '../kwdialog/kwdialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-edit-tour-page',
    templateUrl: './edit-tour-page.component.html',
    styleUrls: ['./edit-tour-page.component.scss'],
})
export class EditTourPageComponent implements OnInit {
    @ViewChild('articleAuto') articleAuto: ArticleScanAutocompleteComponent;
    @ViewChild('identifikation') identifikation: ElementRef;
    @ViewChild('menge') menge: ElementRef;

    behandlungsanfrageId: string;

    tour: Tourenplan = new Tourenplan();

    betriebe: Client[] = [];

    isKunde = true;

    paramsActive = new HttpParams().append('aktiv', '1');

    artikelMenge: ArtikelMenge[] = [];
    currentArtikelMenge = new ArtikelMenge();
    mengeCtrl = new UntypedFormControl();
    zieldatumFromCtrl = new UntypedFormControl(new Date());

    zieldatumToCtrl = new UntypedFormControl();
    currentCompanyGroup: CompanyGroup = new CompanyGroup();
    currentClient: Client; // = new Client();
    notizCtrl = new UntypedFormControl();
    apothekeCtrl = new UntypedFormControl(false);
    identifikationCtrl = new UntypedFormControl();

    constructor(
        private route: ActivatedRoute,
        private api: KieneTableService,
        private session: LocalStorageService,
        public dialog: MatDialog,
        private notification: MessageService,
        private router: Router,
        private behandlungsanfrageService: BehandlungsanfrageService
    ) {
        const id = this.route.snapshot.paramMap.get('id');
        if (id && id !== 'neu') {
            this.behandlungsanfrageId = id;
        }
    }

    ngOnInit() {
        this.initUserrole();
        if (this.behandlungsanfrageId) {
            this.loadTourenplan();
        }
    }

    goBack() {
        window.history.go(-1);
    }

    setClientAndCompanyGroup() {
        if (this.tour) {

            this.currentCompanyGroup = new CompanyGroup();
            this.currentCompanyGroup.bezeichnung = this.tour.unternehmensgruppe;
            this.currentCompanyGroup.unternehmensgruppe_id =
                this.tour.unternehmensgruppe_id;

            this.currentClient = new Client();
            this.currentClient.kunde_id = this.tour.kunde_id;
            this.currentClient.kunde_nr_intern = this.tour.kunde_nr_intern;
            this.currentClient.bezeichnung_final = this.tour.kunde;
            this.currentClient.zusatz_final = this.tour.zusatz;
        }
    }

    loadTourenplan() {
        this.api
            .getOne(
                environment.BASE_URI + 'tourenplan_tierarzt/read_one.php',
                'bestellung_id',
                this.behandlungsanfrageId
            )
            .subscribe((response) => {
                this.tour = response;
                this.setArtikelMenge(this.tour);

                this.setClientAndCompanyGroup();

                this.zieldatumFromCtrl.setValue(this.tour.zieldatum);
                this.zieldatumToCtrl.setValue(this.tour.zieldatum_bis);

                this.apothekeCtrl.setValue(
                    this.tour.apotheke === 1 ? true : false
                );

                this.notizCtrl.setValue(this.tour.bezeichnung);
            });
    }

    setArtikelMenge(b: Behandlungsanfrage) {
        if (b.positionen) {
            this.artikelMenge = [];
            for (const p of b.positionen) {
                const am = new ArtikelMenge();
                const m = new Material();
                m.artikel_me = p.artikel_me;
                m.artikel_id = p.artikel_id;
                am.article = m;
                am.menge = p.artikelmenge;
                am.anzahl_tiere = p.anzahl_tiere;
                am.identifikation = p.identifikation;
                am.ba_id = p.ba_id;
                this.artikelMenge.push(am);
            }
        }
    }

    initUserrole() {
        if (this.session.isCurrentUserKunde()) {
            this.isKunde = true;
            this.loadBetriebe();
        } else {
            this.isKunde = false;
        }
    }

    loadBetriebe() {
        if (this.isKunde) {
            this.api
                .get(environment.BASE_URI + 'kunde/read.php', null)
                .subscribe((response) => {
                    this.betriebe = response.records;
                    if (this.betriebe.length === 1) {
                        this.currentClient = this.betriebe[0];
                    }
                });
        }
    }

    setUnternehmensgruppe(companyGroup: CompanyGroup) {
        this.currentCompanyGroup = companyGroup;
    }

    save() {
        const b = new Behandlungsanfrage();
        b.status_id = this.isKunde ? 2 : 3;

        if (this.tour && this.tour.bestellung_id) {
            b.bestellung_id = this.tour.bestellung_id;
        }

        // kunde hat priorität gegenüber unternehmensgruppe
        if (this.currentClient && this.currentClient.kunde_id) {
            b.kunde_id = this.currentClient.kunde_id;
        } else {
            if (
                this.currentCompanyGroup &&
                this.currentCompanyGroup.unternehmensgruppe_id
            ) {
                b.unternehmensgruppe_id =
                    this.currentCompanyGroup.unternehmensgruppe_id;
            }
        }

        b.bezeichnung = this.notizCtrl.value;
        b.zieldatum = this.zieldatumFromCtrl.value;
        b.zieldatum_bis = this.zieldatumToCtrl.value;
        b.positionen = this.getPositionen();

        b.apotheke = this.apothekeCtrl.value ? 1 : 0;

        this.api
            .updateEntity(environment.BASE_URI + 'behandlungsanfrage/update.php', b)
            .subscribe(
                (response) => {
                    this.notification.infoMessage(
                        'Die Anfrage wurde erfolgreich gespeichert!'
                    );
                    this.router.navigate(['startseite/tourenplan']);
                },
                (error) => {
                    this.notification.errorMessage(
                        'Die Tour konnte nicht gespeichert werden!',
                        error
                    );
                }
            );
    }

    getPositionen(): BehandlungsanfragePosition[] {
        const positionen: BehandlungsanfragePosition[] = [];

        for (const a of this.artikelMenge) {
            const b = new BehandlungsanfragePosition();
            b.artikel_id = a.article.artikel_id;
            b.artikelmenge = a.menge;
            b.identifikation = a.identifikation;
            b.anzahl_tiere = a.anzahl_tiere;
            b.ba_id = a.ba_id;
            positionen.push(b);
        }

        return positionen;
    }

    setClient(c: Client) {
        this.currentClient = c;
    }

    removeArtikel(am: ArtikelMenge) {
        const index = this.artikelMenge.indexOf(am);
        this.artikelMenge.splice(index, 1);
    }

    editArtikel(am: ArtikelMenge) {
        const dialogRef = this.dialog.open(AddArticlePositionDialogComponent, {
                data: am
            }
        );
        dialogRef.afterClosed().subscribe({
            next: result => {
                if (result) {
                    am = result;
                }
            }
        });
    }

    openAddPositionDialog() {
        const dialogRef = this.dialog.open(AddArticlePositionDialogComponent, {
            width: '100%',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.artikelMenge.push(result);
            }
        });
    }

    openSaveConfirmDialog() {
        const input = new ConfirmDialogInput();
        input.headline = this.behandlungsanfrageId
            ? 'Änderung speichern'
            : 'Bestellung abschließen';
        input.text = this.behandlungsanfrageId
            ? 'Die aktuellen Änderungen werden gespeichert'
            : 'Hiermit schließen Sie die aktuelle Bestellung ab!';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.save();
                }
            },
        });
    }

    openKWDialog() {
        const dialogRef = this.dialog.open(KWDialogComponent);
        dialogRef.afterClosed().subscribe({
            next: (kw: CalendarWeek) => {
                const monday = KieneUtils.getDateOfMonday(
                    kw.weekNumber,
                    kw.year
                );
                const sunday = KieneUtils.addDays(monday, 6);

                this.zieldatumFromCtrl.setValue(monday);
                this.zieldatumToCtrl.setValue(sunday);
            },
        });
    }
}
