import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    Befundmitteilung,
    BefundmitteilungApi,
    BefundmitteilungExternApi,
} from './befundmitteilung';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { Router } from '@angular/router';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable()
export class BefundmitteilungService {
    constructor(
        private http: HttpClient,
        public dialog: MatDialog,
        private localStorageService: LocalStorageService,
        private router: Router,
        private pdfViewerService: PdfViewerDesktopService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {}

    getAllBefundmitteilungen(
        sortColumn: string,
        sortDirection: string,
        offset: number,
        limit: number,
        search: string,
        onlyMyVetGroups: boolean,
        befundParams?: HttpParams
    ): Observable<BefundmitteilungApi> {
        const cu = this.localStorageService.getCurrentUser();

        let params = new HttpParams();

        if (befundParams) {
            params = befundParams;
        }

        const currentRoute = this.router.url;
        if (currentRoute && currentRoute.startsWith('/belege/')) {
            if (
                this.localStorageService.getCurrentClient() &&
                this.localStorageService.getCurrentClient().kunde_id
            ) {
                params = params.append(
                    'kunde_id',
                    this.localStorageService
                        .getCurrentClient()
                        .kunde_id.toString()
                );
            }
        }

        if (sortColumn) {
            if (!sortDirection) {
                sortDirection = 'asc';
            }
            params = params.append('orderby', sortColumn + ' ' + sortDirection);
        }

        if (limit) {
            params = params.append('limit', limit.toString());
        }

        if (offset) {
            params = params.append('offset', offset.toString());
        }

        if (search) {
            params = params.append('search', search);
        }

        if (cu.tierarzt_id) {
            if (onlyMyVetGroups) {
                params = params.append('meinetierarztgruppen', '1');
            } else {
                params = params.append('meinetierarztgruppen', '0');
            }
        }

        return this.http.get<BefundmitteilungApi>(
            this.apiBaseUrl + 'befund/read.php',
            { params: params }
        );
    }

    saveBefundmitteilung(
        befundmitteilung: Befundmitteilung
    ): Observable<Befundmitteilung> {
        return this.http.post<Befundmitteilung>(
            this.apiBaseUrl + 'befund/create.php',
            befundmitteilung
        );
    }

    updateBefundmitteilung(
        befundmitteilung: Befundmitteilung
    ): Observable<Befundmitteilung> {
        return this.http.post<Befundmitteilung>(
            this.apiBaseUrl + 'befund/update.php',
            befundmitteilung
        );
    }

    getBefundmitteilungForId(id: string): Observable<Befundmitteilung> {
        return this.http.get<Befundmitteilung>(
            this.apiBaseUrl + 'befund/read_one.php?befund_id=' + id
        );
    }

    recreateBefund(befund: Befundmitteilung) {
        return this.http.get<Befundmitteilung>(
            this.apiBaseUrl +
                'befund/update_pdf.php?befund_id=' +
                befund.befund_id
        );
    }

    getPdfIdsForBefundmitteilung(
        befundId: string
    ): Observable<BefundmitteilungExternApi> {
        return this.http.get<BefundmitteilungExternApi>(
            this.apiBaseUrl +
                'befund_dokument/read.php?dokument_typ=1&befund_id=' +
                befundId
        );
    }

    getSampleSheetPdf(
        gruppeId: number,
        probenentnahme: Date
    ): Observable<Blob> {
        return this.http.get(
            this.apiBaseUrl +
                'probenbegleitschein/pdf_create.php?gruppe_id=' +
                gruppeId +
                '&probenentnahme=' +
                probenentnahme.toISOString(),
            {
                responseType: 'blob',
            }
        );
    }

    getNotizzettel(befund: Befundmitteilung) {
        if (befund) {
            const url = 'befund/pdf_create.php';
            let params = new HttpParams()
                .set('gruppe_id', befund?.gruppe_id?.toString())
                .set('tagebuch_nr', befund?.tagebuch_nr);
            if (befund.untersuchungsmaterial) {
                params = params.set(
                    'untersuchungsmaterial',
                    befund?.untersuchungsmaterial
                );
            }
            if (befund.tierarzt) {
                params = params.set('tierarzt', befund?.tierarzt);
            }
            this.pdfViewerService.openPdfViewer(
                new PdfViewerDialogInput(url, params, 'Notizzettel')
            );
        }
    }

    deleteBefundmitteilung(befundmitteilung: Befundmitteilung) {
        return this.http.post(
            this.apiBaseUrl + 'befund/delete.php',
            befundmitteilung
        );
    }

    getPdfTitle(befundmitteilung: Befundmitteilung) {
        let title: string = befundmitteilung.gruppe_nr_intern
            ? befundmitteilung.gruppe_nr_intern
            : befundmitteilung.kunde_nr_intern?.toString();
        title +=
            ' ' +
            befundmitteilung.kunde +
            ', ' +
            befundmitteilung.kunde_ort +
            ' (' +
            befundmitteilung.tagebuch_nr +
            ') ' +
            this.ausgangsdatumAlsString(befundmitteilung);

        return title;
    }

    private ausgangsdatumAlsString(befundmitteilung: Befundmitteilung) {
        if (befundmitteilung) {
            let ausgang = befundmitteilung.ausgang;
            if (ausgang) {
                ausgang = new Date(ausgang);
                return (
                    ausgang.getDate() +
                    '.' +
                    (ausgang.getMonth() + 1) +
                    '.' +
                    ausgang.getFullYear()
                );
            }
        }
        return '';
    }
}
