
import {
    KalenderEintragDialogComponent
} from "../kalender/kalender-eintrag-dialog/kalender-eintrag-dialog.component";
import { HttpParams } from "@angular/common/http";
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { API_BASE_URL_SERVICE } from "../../../../projects/kiene-core/src/lib/kiene-core.config";
import {
    KieneBackendApiService
} from "../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service";
import { DateUtils } from "../../dialogs/calendar-week-picker/date-utils";
import { MessageService } from "../../services/message.service";
import { HeimtierKalenderEintrag, HeimtierKalenderEintragOutput } from "../kalender/heimtier-kalender-eintrag";
import { NotizDialogComponent } from "./notiz-dialog/notiz-dialog.component";
import { ConfirmDialogInput, ConfirmDialogResult } from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmDialogComponent } from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import { HeimtiereService } from '../heimtiere.service';
import { HeimtierBezahlungDialogComponent } from './heimtier-bezahlung-dialog/heimtier-bezahlung-dialog.component';

@Component({
    selector: 'kiene-heimtiere-startseite',
    templateUrl: './heimtiere-startseite.component.html',
    styleUrls: ['./heimtiere-startseite.component.scss']
})
export class HeimtiereStartseiteComponent implements OnInit {
    plannedData: HeimtierKalenderEintrag[] = [];
    waitingRoomData: HeimtierKalenderEintrag[] = [];
    inTreatmentData: HeimtierKalenderEintrag[] = [];
    readyForPaymentData: HeimtierKalenderEintrag[] = [];

    constructor(private api: KieneBackendApiService,
        private messageService: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private dialog: MatDialog,
        private router: Router,
        private heimtierService: HeimtiereService
    ) { }

    ngOnInit(): void {
        this.aktualisieren();
    }

    public aktualisieren() {
        this.getPlannedData();
        this.getWaitingRoomData();
        this.getInTreatmentData();
        this.getReadyForPaymentData();
    }

    public changeToWartezimmer(event: HeimtierKalenderEintrag): void {
        this.dialog.open(NotizDialogComponent, { data: event }).afterClosed().subscribe({
            next: value => {
                if (value) {
                    event = value;

                    const entity = {
                        hk_id: event.hk_id,
                        notiz: event?.notiz
                    };
                    this.api.post(this.apiBaseUrl + 'heimtier_kalendereintrag/in_wartezimmer.php', entity).subscribe({
                        next: () => {
                            this.getPlannedData();
                            this.getWaitingRoomData();
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler beim Wechseln des Status', err);
                        }
                    })
                }
            }
        });
    }

    public changeToBehandlung(event: HeimtierKalenderEintrag): void {
        const input = new ConfirmDialogInput();
        input.headline = 'Behandlung beginnen';
        input.text =
            'Status in "in Behandlung" ändern?';

        this.dialog.open(ConfirmDialogComponent, {
            data: input,
        }).afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.api.post(this.apiBaseUrl + 'heimtier_kalendereintrag/in_behandlung.php', event).subscribe({
                        next: () => {
                            this.getWaitingRoomData();
                            this.getInTreatmentData();
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler beim Wechseln des Status', err);
                        }
                    });
                }
            }
        });
    }

    public gotoBeleg(event: HeimtierKalenderEintrag): void {
        if (event?.ht_beleg_id) {
            this.router.navigate(['heimtiere', 'belege', event?.ht_beleg_id]).then();
        }
    }

    private getPlannedData(): void {
        let param = new HttpParams();
        const von = DateUtils.dateToYYYYMMDD(new Date(new Date()));
        const bis = DateUtils.dateToYYYYMMDD(new Date(new Date().setDate(new Date().getDate() + 1)));
        param = param.set('von', von).set('bis', bis).set('status_id', 211);
        param = param.set('orderby', 'bis');

        this.api.getAll(this.apiBaseUrl + 'heimtier_kalendereintrag/read.php', param).subscribe({
            next: (data) => {
                this.plannedData = data.records;
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Laden der Daten', err);
            }
        })
    }

    private getWaitingRoomData(): void {
        let param = new HttpParams();
        param = param.set('status_id', 212)
        param = param.set('orderby', 'wartezimmer_seit');
        this.api.getAll(this.apiBaseUrl + 'heimtier_kalendereintrag/read.php', param).subscribe({
            next: (data) => {
                this.waitingRoomData = data.records;
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Laden der Daten', err);
            }
        });
    }

    private getInTreatmentData(): void {
        let param = new HttpParams();
        param = param.set('status_id', 213);
        param = param.set('orderby', 'hk.editiert desc');

        this.api.getAll(this.apiBaseUrl + 'heimtier_kalendereintrag/read.php', param).subscribe({
            next: (data) => {
                this.inTreatmentData = data.records;
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Laden der Daten', err);
            }
        });
    }

    private getReadyForPaymentData(): void {
        let param = new HttpParams();
        param = param.set('status_id', 214);
        param = param.set('orderby', 'hk.editiert desc');

        this.api.getAll(this.apiBaseUrl + 'heimtier_kalendereintrag/read.php', param).subscribe({
            next: (data) => {
                this.readyForPaymentData = data.records;
            },
            error: (err) => {
                this.messageService.errorMessage('Fehler beim Laden der Daten', err);
            }
        });
    }


    public addEntry(): void {
        const item = new HeimtierKalenderEintrag();
        this.dialog.open(KalenderEintragDialogComponent, { data: item, width: '50%' }).afterClosed().subscribe({
            next: (value: HeimtierKalenderEintragOutput) => {
                if (value?.confirm) {
                    this.getPlannedData();
                }
            }
        })
    }

    public editEntry(item: HeimtierKalenderEintrag): void {
        this.dialog.open(KalenderEintragDialogComponent, { data: item, width: '50%' }).afterClosed().subscribe({
            next: (value: HeimtierKalenderEintragOutput) => {
                if (value?.confirm) {
                    this.getPlannedData();
                }
            }
        })
    }

    public onDelete(entry: HeimtierKalenderEintrag): void {
        const data = { title: 'Termin löschen', text: 'Soll der Termin wirklich gelöscht werden?' };

        this.dialog.open(ConfirmDeleteDialogComponent, { data: data }).afterClosed().subscribe({
            next: value => {
                if (value) {
                    this.api.deleteEntity(this.apiBaseUrl + 'heimtier_kalendereintrag/delete.php', entry).subscribe({
                        next: () => {
                            this.getPlannedData();
                        }, error: err => {
                            this.messageService.errorMessage('Eintrag konnte nicht gelöscht werden!', err);
                        }
                    })
                }
            }
        })
    }

    public getTiernamen(item: HeimtierKalenderEintrag): string {
        if (!item?.tiere || item?.tiere?.length == 0) {
            return '(kein Tier)';
        }
        let result = '';
        for (let tier of item?.tiere) {
            if (result != '') {
                result += ', ';
            }
            result += tier?.name;
        }
        return '(' + result + ')';
    }

    public bezahlen(eintrag: HeimtierKalenderEintrag) {
        this.dialog.open(HeimtierBezahlungDialogComponent, { data: eintrag }).afterClosed().subscribe({
            next: (result) => {
                if (result) {
                    this.getReadyForPaymentData();
                }
            }
        });
    }

}
