import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsComponent } from './details/details.component';
import { LieferscheinComponent } from './lieferschein/lieferschein.component';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { SignaturePanelModule } from '../../../../projects/kiene-core/src/lib/components/signature-panel/signature-panel.module';
import { PdfUploadComponent } from './pdf-upload/pdf-upload.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { LieferscheinSignaturePanelComponent } from './lieferschein-signature-panel/lieferschein-signature-panel.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileViewerModule } from '../../../../projects/kiene-core/src/lib/components/file-viewer/file-viewer.module';
import { StandortComponent } from './standort/standort.component';
import { GoogleMapsModule } from '@angular/google-maps';



@NgModule({
  declarations: [
    DetailsComponent,
    LieferscheinComponent,
    PdfUploadComponent,
    LieferscheinSignaturePanelComponent,
    StandortComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    SignaturePanelModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDatepickerModule,
    PdfViewerModule,
    FileViewerModule,
    GoogleMapsModule
  ],
  exports: [
    DetailsComponent,
    LieferscheinComponent,
    PdfUploadComponent,
    LieferscheinSignaturePanelComponent,
    StandortComponent
  ]
})
export class AnfrageDetailsModule { 

}
