import {HttpParams} from "@angular/common/http";
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {API_BASE_URL_SERVICE} from "../../../../../../projects/kiene-core/src/lib/kiene-core.config";
import {
    KieneBackendApiService
} from "../../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service";
import {KieneAutocompleteComponent} from "../../../../components/kiene-autocomplete/kiene-autocomplete.component";
import {MessageService} from "../../../../services/message.service";
import {AbholListeDialogInput} from "../abhol-liste-dialog-input";
import {Abholliste, AbhollisteTiere} from "../abholliste";
import {AbhollisteDialogComponent} from "./abholliste-dialog/abholliste-dialog.component";

@Component({
    selector: 'kiene-add-tier-to-liste-dialog',
    templateUrl: './add-tier-to-liste-dialog.component.html',
    styleUrls: ['./add-tier-to-liste-dialog.component.scss']
})
export class AddTierToListeDialogComponent implements OnInit {
    @ViewChild('abhollisteAutocomplete') abhollisteAutocomplete: KieneAutocompleteComponent;
    public abhollisteUrl: string = 'abholliste/';
    public abholliste: Abholliste = new Abholliste();
    public params: HttpParams = new HttpParams().set('status_id', 201);

    constructor(
        public dialogRef: MatDialogRef<AddTierToListeDialogComponent>,
        private dialog: MatDialog,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: AbholListeDialogInput,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit(): void {
    }

    public save(): void {
        const abhollisteTiere = new AbhollisteTiere();
        abhollisteTiere.abholliste_id = this.abholliste.abholliste_id;
        abhollisteTiere.gruppen_tiere = this.data.selectedRows;
        this.saveAbhollisteTiere(abhollisteTiere);
    }

    public getAnzahlTiere(): string {
        return "" + this.data.selectedRows.length;
    }

    public openCreateNewAbhollisteDialog(): void {
        if (this.data.datumSimuliert) {
            this.abholliste.abholdatum = new Date(this.data.datumSimuliert);
        }
        console.log(this.abhollisteUrl)
        this.dialog.open(AbhollisteDialogComponent, {
            data: this.abholliste,
            minWidth: '800px'
        }).afterClosed().subscribe({
            next: value => {
                if (value) {
                    this.api.post(this.apiBaseUrl + this.abhollisteUrl + 'update.php', value).subscribe({
                        next: response => {
                            this.abholliste = response;
                        },
                        error: error => {
                            this.messageService.alertMessage('Fehler beim Speichern der Abholliste.');
                        }
                    });
                }
            }
        });
    }

    public setAbholliste(event: any): void {
        this.abholliste = event;
    }

    private saveAbhollisteTiere(abhollisteTiere: AbhollisteTiere): void {
        this.api.post(this.apiBaseUrl + 'abholliste/update_tiere.php', abhollisteTiere).subscribe({
            next: response => {
                this.dialogRef.close(response);
            },
            error: error => {
                this.messageService.errorMessage('Fehler beim Speichern der Abholliste.', error);
            }
        })
    }
}
