import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MaterialPricesService} from './material-prices.service';
import {MaterialPrice} from './material-price';
import {MessageService} from '../../../services/message.service';
import {Material} from '../material';
import {MatDialog} from '@angular/material/dialog';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {SaveForAllDialogComponent} from '../../../dialogs/save-for-all-dialog/save-for-all-dialog.component';
import {Mandant} from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';


@Component({
    selector: 'kiene-material-sales-prices',
    templateUrl: './material-prices.component.html',
    styleUrls: ['./material-prices.component.scss']
})
export class MaterialPricesComponent implements OnInit, OnChanges {

    @Input('material') material: Material;
    @Input('vat') vat = 19;
    @Input('ek') einkaufspreis = 0;
    @Input('rabatt') rabatt = 0;

    materialPrices: MaterialPrice[] = [];

    availableMandantenForArticle: Mandant[] = [];

    vk1Ctrl = new UntypedFormControl(0, Validators.min(0));
    vk2Ctrl = new UntypedFormControl(0, Validators.min(0));
    vk3Ctrl = new UntypedFormControl(0, Validators.min(0));
    vk4Ctrl = new UntypedFormControl(0, Validators.min(0));
    vk5Ctrl = new UntypedFormControl(0, Validators.min(0));

    vkCtrls: UntypedFormControl[] = [];

    constructor(private service: MaterialPricesService,
                private messageService: MessageService,
                public dialog: MatDialog,
                private localStorageService: LocalStorageService,
                private api: KieneTableService) {
    }

    ngOnInit() {
        if (this.material && this.material.artikel_id) {
            this.loadMaterialPrices();
            this.loadAvailableMandantenForThisArticle();
        }
    }

    ngOnChanges() {
        if (this.material && this.material.artikel_id) {
            this.loadMaterialPrices();
            this.loadAvailableMandantenForThisArticle();
        }
    }

    loadAvailableMandantenForThisArticle() {
        if (this.localStorageService.currentUserHasPermission(1005)) {
            let params = new HttpParams().set('artikel_id', this.material.artikel_id.toString());
            this.api.get(environment.BASE_URI + 'artikel/read_mandanten.php', params).subscribe(
                response => {
                    if (response) {
                        this.availableMandantenForArticle = response.records;
                    }
                },
                error => {
                    this.messageService.errorMessage('Mandanten konnten nicht geladen werden!', error);
                }
            );
        }
    }


    loadMaterialPrices() {
        this.service.loadMaterialPrices(this.material.artikel_id).subscribe(result => {
            this.materialPrices = result.records;
            this.materialPrices.sort((m1, m2) => m1.verkaufspreis_id - m2.verkaufspreis_id);
            this.vkCtrls = [];
            for (let mp of this.materialPrices) {
                const index = mp.verkaufspreis_id + '_' + mp.artikel_id;
                this.vkCtrls[index] = new UntypedFormControl(mp.einzelpreis);
            }


            // this.materialPrices.forEach(price => {
            //   switch (price.verkaufspreis_id) {
            //     case 1: this.vk1Ctrl.setValue(price.einzelpreis); return;
            //     case 2: this.vk2Ctrl.setValue(price.einzelpreis); return;
            //     case 3: this.vk3Ctrl.setValue(price.einzelpreis); return;
            //     case 4: this.vk4Ctrl.setValue(price.einzelpreis); return;
            //     case 5: this.vk5Ctrl.setValue(price.einzelpreis); return;
            //   }
            // });
        });
    }

    savePrices() {

        for (let mp of this.materialPrices) {
            mp.einzelpreis = this.vkCtrls[mp.verkaufspreis_id + '_' + mp.artikel_id].value;
        }

        const prices = {preise: this.materialPrices, mandanten_ids: []};

        if (this.availableMandantenForArticle.length > 1 && this.localStorageService.currentUserHasPermission(1005)) {
            let dialogRef = this.dialog.open(SaveForAllDialogComponent, {
                data: {
                    availableMandanten: this.availableMandantenForArticle
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                let selectedMandantenIds: number[] = [];
                if (result) {
                    selectedMandantenIds = result;
                    prices.mandanten_ids = selectedMandantenIds;

                    this.service.saveMaterialPrices(prices).subscribe(response => {
                        this.loadMaterialPrices();
                        this.messageService.infoMessage('Die Verkaufspreise wurden gespeichert');
                    }, error => {
                        this.messageService.errorMessage('Die Verkaufspreise konnten nicht gespeichert werden! ', error);
                    });
                }

            });


        } else {

            this.service.saveMaterialPrices(prices).subscribe(response => {
                this.loadMaterialPrices();
                this.messageService.infoMessage('Die Verkaufspreise wurden gespeichert');
            }, error => {
                this.messageService.errorMessage('Die Verkaufspreise konnten nicht gespeichert werden! ', error);
            });
        }


    }

    gibRabattiertenPreis() {
        if (this.einkaufspreis > 0 && this.rabatt >= 0 && this.rabatt < 100) {
            return this.einkaufspreis * (100 - this.rabatt) / 100;
        }
        return undefined;
    }

    preiseOk(verkaufspreis: number) {
        if (verkaufspreis === null || verkaufspreis === undefined || verkaufspreis <= 0) {
            return false;
        }
        if (this.gibRabattiertenPreis() === undefined) {
            return false;
        }
        return true;
    }

    gibHandelsspanne(verkaufspreis: number) {
        if (!this.preiseOk(verkaufspreis)) {
            return undefined;
        }
        return ((verkaufspreis - this.gibRabattiertenPreis()) / verkaufspreis) * 100.0;
    }

    gibRohertrag(verkaufspreis: number) {
        if (!this.preiseOk(verkaufspreis)) {
            return undefined;
        }
        return verkaufspreis - this.gibRabattiertenPreis();
    }

    gibKalkulationszuschlag(verkaufspreis: number) {
        if (!this.preiseOk(verkaufspreis)) {
            return undefined;
        }
        let preis = this.gibRabattiertenPreis();
        return ((verkaufspreis - preis) / preis) * 100.0
    }

    gibBruttoVK(verkaufspreis: number) {
        if (!this.preiseOk(verkaufspreis)) {
            return undefined;
        }
        return verkaufspreis * (1 + (this.vat / 100.0))
    }

}
