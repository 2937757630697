import { HttpParams } from '@angular/common/http';

export class SelectorInput {
    label: string = 'Auswählen';
    selectedObject: any = null;
    apiUrl: string;
    params: HttpParams;
    displayColumns: string[] = [];
    delimiter = ' - ';
    autoSelect = false;
    comparator: (o1: any, o2: any) => boolean;

    constructor(options?: { apiUrl?: string, displayColumns?: string[], label?: string, delimiter?: string, selectedObject?: any, params?: HttpParams, autoSelect?: boolean, comparator?: (o1: any, o2: any) => boolean }) {
        if (options?.apiUrl) {
            this.apiUrl = options?.apiUrl;
        }
        if (options?.displayColumns) {
            this.displayColumns = options?.displayColumns;
        }
        if (options?.label) {
            this.label = options?.label;
        }
        if (options?.delimiter) {
            this.delimiter = options?.delimiter;
        }
        if (options?.selectedObject) {
            this.selectedObject = options?.selectedObject;
        }
        if (options?.params) {
            this.params = options?.params;
        }
        if (options?.comparator) {
            this.comparator = options?.comparator;
        } else {
            this.comparator = function (a: any, b: any): boolean {
                return false;
            }
        }
        if (options?.autoSelect) {
            this.autoSelect = options?.autoSelect;
        }
    }
}