
<mat-tab-group>
    <mat-tab label="Tierarten">
        <kiene-tierarten></kiene-tierarten>
    </mat-tab>

    <mat-tab label="Rinderrassen">
        <kiene-rassen></kiene-rassen>
    </mat-tab>

    <mat-tab label="Sonstige Rassen">
        <kiene-sonstige-rassen></kiene-sonstige-rassen>
    </mat-tab>
</mat-tab-group>
