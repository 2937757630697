import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from "rxjs";
import {TierRasse} from "../../../../../../projects/kiene-core/src/lib/components/gruppe-tier/classes/tier-rasse";
import {API_BASE_URL_SERVICE} from "../../../../../../projects/kiene-core/src/lib/kiene-core.config";
import {
    KieneBackendApiService
} from "../../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service";
import {MessageService} from "../../../../services/message.service";
import {AbhollisteFilter} from "../../abholliste-filter";

@Component({
    selector: 'kiene-abholliste-filter-dialog',
    templateUrl: './abholliste-filter-dialog.component.html',
    styleUrls: ['./abholliste-filter-dialog.component.scss']
})
export class AbhollisteFilterDialogComponent implements OnInit, OnDestroy {
    filterObject = new AbhollisteFilter();
    minAlterCtrl = new FormControl<number>(28);
    maxAlterCtrl = new FormControl<number>(undefined);
    datumCtrl = new FormControl();
    rassenListe: TierRasse[] = [];
    maennlich: boolean;
    weiblich: boolean;
    nichtInAbholliste: boolean;
    rassenSubscription: Subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<AbhollisteFilterDialogComponent>,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: AbhollisteFilter,
        @Inject(API_BASE_URL_SERVICE) public apiBaseUrl: string
    ) {
    }

    filterDates = (d: Date): boolean => {
        const today = new Date();
        // Entferne die Zeitkomponente von beiden Daten
        today.setHours(0, 0, 0, 0);
        if (d) {
            d.setHours(0, 0, 0, 0);
        }
        return d ? d > today : false;
    }

    ngOnInit(): void {
        if (this.data) {
            this.initFormFields();
        }
    }

    ngOnDestroy(): void {
        this.rassenSubscription.unsubscribe();
    }

    public setSelectedRasse(event: TierRasse): void {
        const foundRasse = this.rassenListe.find(rasse => rasse.rasse_id === event.rasse_id);
        if (!foundRasse) {
            this.rassenListe.push(event);
        }
    }


    public save(): void {
        if (this.maennlich) {
            this.filterObject.geschlechterIds.push(1);
        }
        if (this.weiblich) {
            this.filterObject.geschlechterIds.push(2);
        }
        if (this.minAlterCtrl.value) {
            this.filterObject.minAlter = this.minAlterCtrl.value;
        }
        if (this.maxAlterCtrl.value) {
            if (this.filterObject.minAlter <= this.maxAlterCtrl.value) {
                this.filterObject.maxAlter = this.maxAlterCtrl.value;
            } else {
                this.messageService.alertMessage('Maximalalter muss größer als Minimalalter sein!');
                return;
            }
        }
        for (let rasse of this.rassenListe) {
            this.filterObject.rassenIds.push(rasse.rasse_id);
        }
        if (this.datumCtrl.value) {
            console.log(new Date(), new Date(this.datumCtrl.value));
            if (new Date() <= new Date(this.datumCtrl.value)) {
                this.filterObject.datum = this.datumCtrl.value;
            } else {
                this.messageService.alertMessage('Das simulierte Datum muss in der Zukunft liegen!');
                return;
            }
        }
        this.filterObject.nichtInAbholliste = this.nichtInAbholliste;

        this.speicherFilter();
    }

    public removeRasse(rasse: TierRasse): void {
        const findIndex = this.rassenListe.findIndex(r => r.rasse_id === rasse.rasse_id);
        if (findIndex >= 0) {
            this.rassenListe.splice(findIndex, 1);
        }
    }

    public setMaennlich(event: MatCheckboxChange): void {
        this.maennlich = event.checked;
    }

    public setWeiblich(event: MatCheckboxChange): void {
        this.weiblich = event.checked;
    }

    public setInAbholliste(event: MatCheckboxChange): void {
        this.nichtInAbholliste = event.checked;
    }

    public resetFilter(): void {
        this.minAlterCtrl.setValue(undefined);
        this.maxAlterCtrl.setValue(undefined);
        this.weiblich = false;
        this.maennlich = false;
        this.datumCtrl.setValue(undefined);
        this.rassenListe = [];
        this.nichtInAbholliste = false;
    }

    private initFormFields(): void {
        this.minAlterCtrl.setValue(this.data?.minAlter || 28);
        this.maxAlterCtrl.setValue(this.data?.maxAlter || undefined);
        this.maennlich = !!this.data?.geschlechterIds?.find(n => n === 1);
        this.weiblich = !!this.data?.geschlechterIds?.find(n => n === 2)
        this.datumCtrl.setValue(this.data?.datum);
        this.nichtInAbholliste = !!this.data?.nichtInAbholliste;

        this.ladeRassen();
    }

    private ladeRassen(): void {
        this.rassenSubscription.unsubscribe();
        this.rassenSubscription = this.api.getAll(this.apiBaseUrl + 'rasse/read.php').subscribe({
            next: value => {
                for (let r of this.data?.rassenIds ?? []) {
                    const find: TierRasse = value.records.find(ras => ras.rasse_id === r);
                    if (find) {
                        this.rassenListe.push(find);
                    }
                }
            },
            error: err => {
                this.messageService.errorMessage('Tierrassen konnten nicht geladen werden!', err);
            }
        });
    }

    private speicherFilter(): void {
        this.api.setSpeicher('AbhollisteDialog', this.filterObject).subscribe({
            next: value => {
                if (value) {
                    this.dialogRef.close(this.filterObject);
                } else {
                    this.messageService.alertMessage('Die Filtereinstellungen konnten nicht gespeichert werden!')
                }
            }
        });
    }

}
