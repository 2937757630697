<h1 mat-dialog-title>
    {{bestandsbuchArtikel.artikel_me}}
</h1>
<div mat-dialog-content style="margin-bottom: 24px">
    <div class="one-row" style="margin-bottom: 12px">
        <h3>Details</h3>
    </div>
    <div>
        <div *ngIf="bestandsbuchArtikel?.tierart" class=" detail-row">
            <strong>{{bestandsbuchArtikel?.tierart}}:</strong>
            <span>{{bestandsbuchArtikel?.anzahl_tiere || '---'}} Stk.</span>
        </div>
        <div class="detail-row">
            <strong>Diagnose:</strong>
            <div>{{bestandsbuchArtikel?.diagnose || '---'}} </div>
        </div>
        <div class="detail-row">
            <strong>Dosierung:</strong>
            <div>{{bestandsbuchArtikel?.dosierung_txt || '---'}} </div>
        </div>
        <div class=" detail-row">
            <strong>Abgabemenge:</strong>
            <div>{{bestandsbuchArtikel?.artikel_me || '---'}}</div>
        </div>
        <div class=" detail-row">
            <strong>Wartezeit Fleisch:</strong>
            <span>{{bestandsbuchArtikel?.wartezeit || '---'}} Tag(e)</span>
        </div>
    </div>

    <div *ngIf="bestandsbuchArtikel?.anwendungen?.length > 0">
        <h3>Protokollierung</h3>
        <mat-hint *ngIf="isUserAllowed()" class="hinweis-text"><strong>Hinweis:</strong> Es können keine Tage im voraus protokolliert werden.</mat-hint>
        <mat-hint *ngIf="!isUserAllowed()" class="hinweis-text">
            <strong>Hinweis:</strong>
            Der aktuelle Artikel wird durch einen Tierarzt protokolliert.
        </mat-hint>
        <div *ngFor="let anwendung of bestandsbuchArtikel.anwendungen; let index = index">
            <div *ngIf="index % 4 === 0">
                <mat-checkbox style="margin: 6px 0 6px 0" [checked]="anwendung.angewendet > 0"
                              [disabled]="(isInFuture(anwendung.datum) || anwendung.angewendet > 0) || !isUserAllowed()"
                              (change)="protokollieren($event, anwendung)">
                    {{anwendung.datum | date: 'dd.MM.yyyy'}}
                </mat-checkbox>
                <mat-checkbox *ngIf="index+1 < bestandsbuchArtikel.anwendungen.length"  style="margin: 6px 0 6px 48px"
                              [checked]="anwendung.angewendet > 0"
                              [disabled]="(isInFuture(anwendung.datum) || anwendung.angewendet > 0) || !isUserAllowed()"
                              (change)="protokollieren($event, anwendung)">
                    {{bestandsbuchArtikel.anwendungen[index+1].datum | date: 'dd.MM.yyyy'}}
                </mat-checkbox>
                <mat-checkbox *ngIf="index+2 < bestandsbuchArtikel.anwendungen.length" style="margin: 6px 0 6px 48px"
                              [checked]="anwendung.angewendet > 0"
                              [disabled]="(isInFuture(anwendung.datum) || anwendung.angewendet > 0) || !isUserAllowed()"
                              (change)="protokollieren($event, anwendung)">
                    {{bestandsbuchArtikel.anwendungen[index+2].datum | date: 'dd.MM.yyyy'}}
                </mat-checkbox>
                <mat-checkbox *ngIf="index+3 < bestandsbuchArtikel.anwendungen.length" style="margin: 6px 0 6px 48px"
                              [checked]="anwendung.angewendet > 0"
                              [disabled]="(isInFuture(anwendung.datum) || anwendung.angewendet > 0) || !isUserAllowed()"
                              (change)="protokollieren($event, anwendung)">
                    {{bestandsbuchArtikel.anwendungen[index+3].datum | date: 'dd.MM.yyyy'}}
                </mat-checkbox>
            </div>
        </div>
    </div>

    <div>
        <h3>Tiere/Ohrmarken</h3>

        <div>
            <div style="margin-bottom: 12px" *ngIf="bestandsbuchArtikel.anzahl_tiere > bestandsbuchArtikel.tiere.length && isUserAllowed()">
                <mat-hint class="hinweis-text">Es müssen noch {{bestandsbuchArtikel.anzahl_tiere - bestandsbuchArtikel.tiere.length}} Tiere nachgetragen werden.</mat-hint>
            </div>

            <div style="margin-bottom: 12px" *ngIf="bestandsbuchArtikel.anzahl_tiere > bestandsbuchArtikel.tiere.length && !isUserAllowed()">
                <mat-hint class="hinweis-text">Der Tierarzt muss noch {{bestandsbuchArtikel.anzahl_tiere - bestandsbuchArtikel.tiere.length}}  Tiere nachtragen.</mat-hint>
            </div>

            <div style="margin-bottom: 12px" *ngIf="!bestandsbuchArtikel?.tiere?.length && !bestandsbuchArtikel?.identifikation">
                <mat-hint class="hinweis-text">Es wurden weder Tiere noch Identifikationen dokumentiert.</mat-hint>
            </div>

            <div *ngIf="bestandsbuchArtikel?.tiere?.length > 0" style="  display:flex; flex-direction: column; width:100%">

                <mat-form-field style="width: 100%">
                    <mat-label>Tier suchen</mat-label>
                    <input matInput [formControl]="tiersucheCtrl">
                </mat-form-field>

                <div style="height:5vh;" (click)="isUserAllowed() ? addTier(tier): null" *ngFor="let tier of bestandsbuchArtikel?.tiere | tierSucheFilter: tiersucheCtrl.value let index = index " >
                    <div style="display: flex; align-items: center; justify-content: flex-start">
                        <h3 style="width: 24px">{{index+1}}.</h3>
                        <kiene-ohrmarke style="margin-left: 24px; width: 12vw" [ohrmarke]="tier.ohrmarke_notnull"></kiene-ohrmarke>
                        <mat-icon style="margin-left: 12px" *ngIf="isUserAllowed()" color="accent">edit</mat-icon>
                    </div>
                </div>
                <ng-container>
                    <div class="empty-animal" style="height:5vh" *ngFor="let i of openTiere; let index = index" (click)="isUserAllowed() ? addTier() : showTierarztHint()">
                        <mat-hint style="color: #ccc; font-size: 1.25em; display: flex; align-items: center;">
                            <mat-icon style="width: 24px; padding-right: 6px; transform: scale(0.8)">warning</mat-icon>
                            <span style="margin-left: 24px; width: 12vw">{{index+bestandsbuchArtikel.tiere.length+1}}. Tier nachtragen</span>
                        </mat-hint>
                        <mat-icon *ngIf="isUserAllowed()" color="accent" style="margin-left: 6px">edit</mat-icon>
                    </div>
                </ng-container>

            </div>

            <div *ngIf="!bestandsbuchArtikel?.tiere?.length && bestandsbuchArtikel?.identifikation">
                <div class="detail-row detail-row__new-line">
                    <strong>Identifikation:</strong>
                    <div class="detail-row__data">{{bestandsbuchArtikel?.identifikation}}</div>
                </div>
            </div>
        </div>
    </div>

</div>

<div mat-dialog-actions>
    <button mat-flat-button color="accent" (click)="dialogRef.close()">Schließen</button>
</div>
