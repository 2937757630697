import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'kiene-benutzerrechte-dialog',
  templateUrl: './benutzerrechte-dialog.component.html',
  styleUrls: ['./benutzerrechte-dialog.component.scss']
})
export class BenutzerrechteDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<BenutzerrechteDialogComponent>) { }

  ngOnInit(): void {
  }

}
