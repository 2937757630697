import { ActivatedRoute } from '@angular/router';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { KieneBackendApiService } from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import { HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FileViewerDialogComponent } from '../../../../../projects/kiene-core/src/lib/components/file-viewer/file-viewer-dialog/file-viewer-dialog.component';

export interface Upload {
  progress: number;
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
}

@Component({
  selector: 'kiene-pdf-upload',
  templateUrl: './pdf-upload.component.html',
  styleUrls: ['./pdf-upload.component.scss']
})
export class PdfUploadComponent implements OnInit {

  @ViewChild('fileSelector') fileSelector;
  @Input('zeitslot_buchung_id') zeitslot_buchung_id;
  @Input('buchung_dateien') buchung_dateien;
  @Input('schlachtdaten') schlachtdaten;
  @Input('abrechnung') abrechnung;
  scanToProgress: File;
  chosenFiles: [File];
  uploadStatus: Upload;
  istSchlachthof: boolean = true;
  istViehhandel: boolean = true;
  showSchlachtdaten: boolean = true;
  showAbrechnung: boolean = true;

  constructor(
    @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
    private dialog: MatDialog,
    private http: HttpClient,
    private messageService: MessageService,
    private api: KieneBackendApiService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.zeitslot_buchung_id = Number(this.route.snapshot.paramMap.get('id'));
  }

  handleFiles(event): void {
    this.chosenFiles = event.target.files;
  }

  private uploadFiles(event, dateityp: BuchungDateiTypen): void {

    const files = event.target.files;
    const fd = new FormData();
    const file: File = files[0];
    fd.append('buchungs_id', this.zeitslot_buchung_id);
    fd.append('dokument', files[0]);
    fd.append('dateityp', dateityp.toString());

    const params = new HttpParams();


    const options = {
        headers: new HttpHeaders('Phito-Type: fileupload'),
        params: params,
        reportProgress: true,
    };

    // const request = new HttpRequest('POST', AppConstants.BASE_URI+this.apiUrl, fd, options);
    this.http.post<any>(this.apiBaseUrl + 'zeitslot_buchung/create_datei.php', fd, options).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * event.loaded / event.total);
            // this.filesProgressValues[i] = percentDone;
        } else if (event instanceof HttpResponse) {
        }
    }, error => {
        this.messageService.errorMessage('Die Datei konnte nicht hochgeladen werden!', error);
    }, () => {
        this.messageService.infoMessage('Die Datei/Foto wurden erfolgreich hochgeladen!');
    });
  }

  filesAttachend(event): void {
    this.uploadFiles(event, BuchungDateiTypen.ALLGEMEIN);
  }

  abrechnungAttached(event): void {
    this.uploadFiles(event, BuchungDateiTypen.ABRECHNUNG);
  }

  schlachtdatenAttached(event): void {
    this.uploadFiles(event, BuchungDateiTypen.SCHLACHTDATEN);
    this.triggerSchlachtdatenCalculation();
  }

  downloadDatei(datei) {
    const params = new HttpParams().append('zeitslot_buchung_id', datei.datei_id);
    
    const dialogRef = this.dialog.open(FileViewerDialogComponent, {
        data: {
            url: 'zeitslot_buchung/read_datei.php',
            dateiname: datei.dateiname,
            params: params
        },
        minWidth: '100vw',
        width: '100%'
    });
  }
  
  triggerSchlachtdatenCalculation() {
    const params = new HttpParams().set(
      'buchung_id',
      this.zeitslot_buchung_id
    );
    this.api.get('schlachtdaten/generiere_schlachtdaten.php', params).subscribe(
      response => {
        console.log("Schlachtdaten generiert", response);
      }
    );
  }
}

export enum BuchungDateiTypen {
  ALLGEMEIN = 0,
  ABRECHNUNG = 1,
  SCHLACHTDATEN = 2,
}
