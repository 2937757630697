<h1 mat-dialog-title>
    Tierwohlprotokoll Schwein vom
    {{ tierwohlprotokollSchwein.datum | date: "dd.MM.yy" }}
</h1>

<div mat-dialog-content>
    <div class="info-box">
        <mat-checkbox [formControl]="abrechnenCtrl">Tierwohlprotokoll abrechnen?</mat-checkbox>
        <span class="spacer"></span>
    </div>
    <div id="betrieb-tierarzt" style="width: 100%">
        <div style="width: 45%">
            <h3>Betrieb</h3>
            <kiene-autocomplete (elementSelected)="setKunde($event)" [apiUrl]="'kunde/'" [displayFields]="[
                    'bezeichnung_final',
                    'vvvo',
                    'strasse_final',
                    'plz_final',
                    'ort_final'
                ]" [focus]="false" [kieneObject]="selectedKunde" [placeholder]="'Betrieb'" [required]="true"
                [separator]="' | '" [showClear]="true" [showDeleteButton]="false">
            </kiene-autocomplete>
        </div>

        <mat-form-field class="no-padding-margin-mat-form-field" style="width: 25%">
            <mat-label>Datum</mat-label>
            <input [(ngModel)]="tierwohlprotokollSchwein.datum" [matDatepicker]="dpDatum" matInput />
            <mat-datepicker-toggle [for]="dpDatum" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #dpDatum></mat-datepicker>
        </mat-form-field>

        <div *ngIf="!userIstTierarzt" style="width: 45%">
            <h3>Tierarzt</h3>
            <kiene-autocomplete (elementSelected)="setTierarzt($event)" [apiUrl]="'tierarzt/'"
                [displayFields]="['vorname', 'nachname']" [focus]="false" [kieneObject]="selectedTierarzt"
                [placeholder]="'Betrieb'" [required]="true" [showClear]="true" [showDeleteButton]="false">
            </kiene-autocomplete>
        </div>
    </div>

    <div id="tierbestand">
        <div id="tiere">
            <h3>Anzahl Tierpl&auml;tze</h3>
            <div class="row">
                <mat-form-field>
                    <mat-label>Sauen</mat-label>
                    <input [(ngModel)]="tierwohlprotokollSchwein.anzahl_sauen" matInput type="number" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Eber</mat-label>
                    <input [(ngModel)]="tierwohlprotokollSchwein.anzahl_eber" matInput type="number" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Ferkel</mat-label>
                    <input [(ngModel)]="tierwohlprotokollSchwein.anzahl_ferkel" matInput type="number" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Mastschweine</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.anzahl_mastschweine
                        " matInput type="number" />
                </mat-form-field>
            </div>
        </div>
        <div id="verluste">
            <div style="margin-bottom: 1em">
                <h3 style="margin-block-end: 0.25em">Anzahl Verluste</h3>
                <mat-hint>(z.B. seit letzter Bestandsuntersuchung im
                    Kalenderjahr/Betriebsjahr)
                </mat-hint>
            </div>

            <div class="one-row">
                <mat-form-field style="width: 15%; margin-right: 2em;">
                    <mat-label>Saugferkel</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.verluste_saugferkel
                        " matInput type="text" />
                </mat-form-field>

                <mat-form-field style="width: 15%; margin-right: 2em;">
                    <mat-label>Aufzuchtferkel</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.verluste_aufzuchtferkel
                        " matInput type="text" />
                </mat-form-field>

                <mat-form-field style="width: 15%; margin-right: 2em;">
                    <mat-label>Sauen</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.verluste_sauen
                        " matInput type="text" />
                </mat-form-field>
                <span class="spacer"></span>
            </div>
            <div class="one-row">

                <mat-form-field style="width: 15%; margin-right: 2em;">
                    <mat-label>Abortrate</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.verluste_abortrate
                        " matInput type="text" />
                </mat-form-field>

                <mat-form-field style="width: 15%; margin-right: 2em;">
                    <mat-label>Totgeburten</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.verluste_totgeburten
                        " matInput type="text" />
                </mat-form-field>

                <mat-form-field style="width: 15%; margin-right: 2em;">
                    <mat-label>Mastschweine</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.verluste_mastschweine
                        " matInput type="text" />
                </mat-form-field>
                <span class="spacer"></span>

            </div>
        </div>
        <div id="biologische-daten">
            <h3>Biologische Daten</h3>
            <div class="row">
                <mat-form-field style="width: 20%">
                    <mat-label>Umrauscherrate</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.biologisch_umrauscherrate
                        " matInput type="text" />
                </mat-form-field>

                <mat-form-field style="width: 20%">
                    <mat-label>&Oslash; Wurfzahl der abgegangenen Sauen
                    </mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.biologisch_wurfzahl_abgegangene_sauen
                        " matInput type="number" />
                </mat-form-field>

                <mat-form-field style="width: 20%">
                    <mat-label>&Oslash; lebendgeborene Ferkel je Wurf
                    </mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.biologisch_lebendgeborene_ferkel
                        " matInput type="number" />
                </mat-form-field>
            </div>

            <div class="row">
                <mat-form-field style="width: 20%">
                    <mat-label>&Oslash; aufgezogene Ferkel je Wurf</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.biologisch_aufgezogene_ferkel
                        " matInput type="number" />
                </mat-form-field>

                <mat-form-field style="width: 20%">
                    <mat-label>&Oslash; Tageszunahmen (Mastschweine)</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.biologisch_tageszunahmen_mastschweine
                        " matInput type="number" />
                </mat-form-field>
            </div>
        </div>
    </div>

    <div id="gesundheitsstatus-seuchen">
        <div id="gesundheitsstatus-bv">
            <h3>Gesundheitsstatus</h3>
            <div class="row row--radio_buttons">
                <strong>PRRS:</strong>
                <mat-radio-group [(ngModel)]="
                        tierwohlprotokollSchwein.gesundheitsstatus_prrs
                    ">
                    <mat-radio-button [value]="1" class="row--radio_buttons__btn">positiv
                    </mat-radio-button>
                    <mat-radio-button [value]="0" class="row--radio_buttons__btn">negativ
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="row row--radio_buttons">
                <strong>APP:</strong>
                <mat-radio-group [(ngModel)]="tierwohlprotokollSchwein.gesundheitsstatus_app">
                    <mat-radio-button [value]="1" class="row--radio_buttons__btn">positiv
                    </mat-radio-button>
                    <mat-radio-button [value]="0" class="row--radio_buttons__btn">negativ
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div id="bestand-klinisch">
            <h3>Bestand klinisch ohne besonderen Befund</h3>
            <div class="row row--radio_buttons">
                <mat-radio-group [(ngModel)]="
                        tierwohlprotokollSchwein.bestand_klinisch_ohne_befund
                    ">
                    <mat-radio-button [value]="1" class="row--radio_buttons__btn">Ja
                    </mat-radio-button>
                    <mat-radio-button [value]="0" class="row--radio_buttons__btn">Nein
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div id="seuchen">
            <h3>Hinweise auf Tierseuchen</h3>
            <div class="row row--radio_buttons">
                <mat-radio-group [(ngModel)]="tierwohlprotokollSchwein.hinweise_tierseuchen">
                    <mat-radio-button [value]="1" class="row--radio_buttons__btn">Ja
                    </mat-radio-button>
                    <mat-radio-button [value]="0" class="row--radio_buttons__btn">Nein
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>

    <div id="managementfaktoren-gesundheitsstatus">
        <div id="managementfaktoren">
            <div style="margin-bottom: 1em">
                <h3 style="margin-block-end: 0.25em">Managementfaktoren</h3>
                <mat-hint>Folgende Bereiche wurden zur Erhebung von
                    Managementfaktoren berücksichtigt.
                </mat-hint>
            </div>

            <div class="row row--checkboxes">
                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.mangagementfaktor_haltungssystem =
                            $event ? 1 : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.mangagementfaktor_haltungssystem
                    " class="row--checkboxes__box">
                    Haltungssystem
                </mat-checkbox>

                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.managementfaktor_fuetterung_wasserversorgung =
                            $event ? 1 : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.managementfaktor_fuetterung_wasserversorgung
                    " class="row--checkboxes__box" style="width: 30%">
                    F&uuml;tterung und Wasserversorgung
                </mat-checkbox>

                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.managementfaktor_betrieb =
                            $event ? 1 : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.managementfaktor_betrieb
                    " class="row--checkboxes__box">
                    Betriebsmanagement
                </mat-checkbox>
            </div>

            <div class="row row--checkbox">
                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.managementfaktor_hygiene =
                            $event ? 1 : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.managementfaktor_hygiene
                    " class="row--checkboxes__box">
                    Hygienemanagement
                </mat-checkbox>

                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.managementfaktor_zucht = $event
                            ? 1
                            : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.managementfaktor_zucht
                    " class="row--checkboxes__box" style="width: 30%">
                    Zuchtmanagement (Reproduktion/Genetik)
                </mat-checkbox>

                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.managementfaktor_sonstiges =
                            $event ? 1 : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.managementfaktor_sonstiges
                    " class="row--checkboxes__box">
                    Sonstiges
                </mat-checkbox>
            </div>
        </div>

        <div id="gesundheitsstatus">
            <h3>Erhebung des Gesundheitsstatus</h3>

            <div class="row row--checkboxes">
                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.gesundheitsstatus_atmungsorgane =
                            $event ? 1 : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.gesundheitsstatus_atmungsorgane
                    " class="row--checkboxes__box">
                    Atmungsorgane
                </mat-checkbox>

                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.gesundheitsstatus_verdauungsapparat =
                            $event ? 1 : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.gesundheitsstatus_verdauungsapparat
                    " class="row--checkboxes__box">
                    Verdauungsapparat
                </mat-checkbox>

                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.gesundheitsstatus_bewegungsapparat =
                            $event ? 1 : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.gesundheitsstatus_bewegungsapparat
                    " class="row--checkboxes__box">
                    Bewegungsapparat
                </mat-checkbox>
            </div>

            <div class="row row--checkbox">
                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.gesundheitsstatus_haut_kondition =
                            $event ? 1 : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.gesundheitsstatus_haut_kondition
                    " class="row--checkboxes__box">
                    Haut, Kondition
                </mat-checkbox>

                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.gesundheitsstatus_sonstiges =
                            $event ? 1 : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.gesundheitsstatus_sonstiges
                    " class="row--checkboxes__box">
                    Sonstiges
                </mat-checkbox>
            </div>
        </div>
    </div>

    <div id="verdachtsdiagnose">
        <h3>Verdachtsdiagnosen</h3>
        <mat-form-field style="width: 50%">
            <textarea [(ngModel)]="tierwohlprotokollSchwein.verdachtsdiagnosen" matInput rows="5"></textarea>
        </mat-form-field>
    </div>

    <div id="diagnostische-massnahmen">
        <h3>Diagnostische Ma&szlig;nahmen</h3>

        <div class="row row--checkboxes">
            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.diagnostische_massnahmen_sektion =
                        $event ? 1 : 0
                " [ngModel]="
                    !!tierwohlprotokollSchwein.diagnostische_massnahmen_sektion
                " class="row--checkboxes__box">
                Sektion
            </mat-checkbox>

            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.diagnostische_massnahmen_kot =
                        $event ? 1 : 0
                " [ngModel]="
                    !!tierwohlprotokollSchwein.diagnostische_massnahmen_kot
                " class="row--checkboxes__box">
                Kotprobe
            </mat-checkbox>

            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.diagnostische_massnahmen_resistenztest =
                        $event ? 1 : 0
                " [ngModel]="
                    !!tierwohlprotokollSchwein.diagnostische_massnahmen_resistenztest
                " class="row--checkboxes__box">
                Resistenztest
            </mat-checkbox>
        </div>

        <div class="row row--checkbox">
            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.diagnostische_massnahmen_futter_wasser =
                        $event ? 1 : 0
                " [ngModel]="
                    !!tierwohlprotokollSchwein.diagnostische_massnahmen_futter_wasser
                " class="row--checkboxes__box">
                Futter-/Wasserprobe
            </mat-checkbox>

            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.diagnostische_massnahmen_blut =
                        $event ? 1 : 0
                " [ngModel]="
                    !!tierwohlprotokollSchwein.diagnostische_massnahmen_blut
                " class="row--checkboxes__box">
                Blutprobe
            </mat-checkbox>

            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.diagnostische_massnahmen_hautgeschabsel =
                        $event ? 1 : 0
                " [ngModel]="
                    !!tierwohlprotokollSchwein.diagnostische_massnahmen_hautgeschabsel
                " class="row--checkboxes__box">
                Hautgeschabsel
            </mat-checkbox>
        </div>

        <div class="row row--checkbox">
            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.diagnostische_massnahmen_tupfer =
                        $event ? 1 : 0
                " [ngModel]="
                    !!tierwohlprotokollSchwein.diagnostische_massnahmen_tupfer
                " class="row--checkboxes__box">
                Tupferprobe
            </mat-checkbox>

            <div class="row row--checkbox row--checkboxes__box" style="width: 60%">
                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.diagnostische_massnahmen_sonstiges =
                            $event ? 1 : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.diagnostische_massnahmen_sonstiges
                    " class="row--checkboxes__box">
                    Sonstiges
                </mat-checkbox>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Sonstiges</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.diagnostische_massnahmen_sonstiges_txt
                        " [disabled]="
                            !tierwohlprotokollSchwein.diagnostische_massnahmen_sonstiges ||
                            tierwohlprotokollSchwein.diagnostische_massnahmen_sonstiges ===
                                0
                        " matInput />
                </mat-form-field>
            </div>
        </div>
    </div>

    <div id="eingesehen">
        <div>
            <h3>Salmonellenmonitoring</h3>
            <div class="row row--radio_buttons">
                <strong>eingesehen:</strong>

                <mat-radio-group [(ngModel)]="
                        tierwohlprotokollSchwein.salmonellen_monitoring
                    ">
                    <mat-radio-button [value]="1" class="row--radio_buttons__btn">Ja
                    </mat-radio-button>
                    <mat-radio-button [value]="0" class="row--radio_buttons__btn">Nein
                    </mat-radio-button>
                </mat-radio-group>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Kategorie</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.salmonellen_monitoring_kategorie
                        " [disabled]="
                            !tierwohlprotokollSchwein.salmonellen_monitoring ||
                            tierwohlprotokollSchwein.salmonellen_monitoring ===
                                0
                        " matInput />
                </mat-form-field>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Kommentar</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.salmonellen_monitoring_txt
                        " [disabled]="
                            !tierwohlprotokollSchwein.salmonellen_monitoring
                        " matInput />
                </mat-form-field>
            </div>
        </div>

        <div>
            <h3>Antibiotikamonitoring</h3>
            <div class="row row--radio_buttons">
                <strong>eingesehen:</strong>

                <mat-radio-group [(ngModel)]="
                        tierwohlprotokollSchwein.antibiotika_monitoring
                    ">
                    <mat-radio-button [value]="1" class="row--radio_buttons__btn">Ja
                    </mat-radio-button>
                    <mat-radio-button [value]="0" class="row--radio_buttons__btn">Nein
                    </mat-radio-button>
                </mat-radio-group>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Therapieindex</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.antibiotika_monitoring_therapieindex
                        " [disabled]="
                            !tierwohlprotokollSchwein.antibiotika_monitoring ||
                            tierwohlprotokollSchwein.antibiotika_monitoring ===
                                0
                        " matInput />
                </mat-form-field>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Kommentar</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.antibiotika_monitoring_txt
                        " [disabled]="
                            !tierwohlprotokollSchwein.antibiotika_monitoring
                        " matInput />
                </mat-form-field>
            </div>
        </div>

        <div>
            <h3>Schlachtbefunddaten</h3>
            <div class="row row--radio_buttons">
                <strong>eingesehen:</strong>

                <mat-radio-group [(ngModel)]="tierwohlprotokollSchwein.schlacht_befunddaten">
                    <mat-radio-button [value]="1" class="row--radio_buttons__btn">Ja
                    </mat-radio-button>
                    <mat-radio-button [value]="0" class="row--radio_buttons__btn">Nein
                    </mat-radio-button>
                </mat-radio-group>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Kommentar</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.schlacht_befunddaten_txt
                        " [disabled]="
                            !tierwohlprotokollSchwein.schlacht_befunddaten
                        " matInput />
                </mat-form-field>
            </div>
        </div>

        <div>
            <h3>Betriebliche Eigenkontrolle</h3>
            <div class="row row--radio_buttons">
                <strong>eingesehen:</strong>

                <mat-radio-group [(ngModel)]="
                        tierwohlprotokollSchwein.betriebliche_eigenkontrolle
                    ">
                    <mat-radio-button [value]="1" class="row--radio_buttons__btn">Ja
                    </mat-radio-button>
                    <mat-radio-button [value]="0" class="row--radio_buttons__btn">Nein
                    </mat-radio-button>
                </mat-radio-group>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Kommentar</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.betriebliche_eigenkontrolle_txt
                        " [disabled]="
                            !tierwohlprotokollSchwein.betriebliche_eigenkontrolle
                        " matInput />
                </mat-form-field>
            </div>
        </div>

        <div>
            <h3>Tierschutzindikatoren</h3>
            <div class="row row--radio_buttons">
                <strong>eingesehen:</strong>

                <mat-radio-group [(ngModel)]="tierwohlprotokollSchwein.tierschutzindikatoren">
                    <mat-radio-button [value]="1" class="row--radio_buttons__btn">Ja
                    </mat-radio-button>
                    <mat-radio-button [value]="0" class="row--radio_buttons__btn">Nein
                    </mat-radio-button>
                </mat-radio-group>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Kommentar</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.tierschutzindikatoren_txt
                        " [disabled]="
                            !tierwohlprotokollSchwein.tierschutzindikatoren
                        " matInput />
                </mat-form-field>
            </div>
        </div>

        <div>
            <h3>Stallklima</h3>
            <div class="row row--radio_buttons">
                <strong>kontrolliert:</strong>

                <mat-radio-group [(ngModel)]="
                        tierwohlprotokollSchwein.stallklima_kontrolliert
                    ">
                    <mat-radio-button [value]="1" class="row--radio_buttons__btn">Ja
                    </mat-radio-button>
                    <mat-radio-button [value]="0" class="row--radio_buttons__btn">Nein
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </div>

    <div id="impfprogramm">
        <h3>Impfprogramm</h3>

        <div class="row row--checkboxes">
            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.impfprogramm_influenza = $event
                        ? 1
                        : 0
                " [ngModel]="!!tierwohlprotokollSchwein.impfprogramm_influenza" class="row--checkboxes__box">
                Influenza
            </mat-checkbox>

            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.impfprogramm_rotlauf = $event
                        ? 1
                        : 0
                " [ngModel]="!!tierwohlprotokollSchwein.impfprogramm_rotlauf" class="row--checkboxes__box">
                Rotlauf
            </mat-checkbox>

            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.impfprogramm_rhinitis_atr = $event
                        ? 1
                        : 0
                " [ngModel]="!!tierwohlprotokollSchwein.impfprogramm_rhinitis_atr" class="row--checkboxes__box">
                Rhinitis atr.
            </mat-checkbox>
        </div>

        <div class="row row--checkboxes">
            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.impfprogramm_haemophilus = $event
                        ? 1
                        : 0
                " [ngModel]="!!tierwohlprotokollSchwein.impfprogramm_haemophilus" class="row--checkboxes__box">
                Haemophilus
            </mat-checkbox>

            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.impfprogramm_app = $event ? 1 : 0
                " [ngModel]="!!tierwohlprotokollSchwein.impfprogramm_app" class="row--checkboxes__box">
                APP
            </mat-checkbox>

            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.impfprogramm_parvo = $event ? 1 : 0
                " [ngModel]="!!tierwohlprotokollSchwein.impfprogramm_parvo" class="row--checkboxes__box">
                Parvo
            </mat-checkbox>
        </div>

        <div class="row row--checkboxes">
            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.impfprogramm_prrs = $event ? 1 : 0
                " [ngModel]="!!tierwohlprotokollSchwein.impfprogramm_prrs" class="row--checkboxes__box">
                PRRS
            </mat-checkbox>

            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.impfprogramm_circo = $event ? 1 : 0
                " [ngModel]="!!tierwohlprotokollSchwein.impfprogramm_circo" class="row--checkboxes__box">
                Circo
            </mat-checkbox>

            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.impfprogramm_ileitis = $event
                        ? 1
                        : 0
                " [ngModel]="!!tierwohlprotokollSchwein.impfprogramm_ileitis" class="row--checkboxes__box">
                Ileitis
            </mat-checkbox>
        </div>

        <div class="row row--checkbox">
            <mat-checkbox (ngModelChange)="
                    tierwohlprotokollSchwein.impfprogramm_mycoplasmen = $event
                        ? 1
                        : 0
                " [ngModel]="!!tierwohlprotokollSchwein.impfprogramm_mycoplasmen" class="row--checkboxes__box">
                Mycoplasmen
            </mat-checkbox>

            <div class="row row--checkbox row--checkboxes__box" style="width: 60%">
                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.impfprogramm_sonstiges = $event
                            ? 1
                            : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.impfprogramm_sonstiges
                    " class="row--checkboxes__box">
                    Sonstiges
                </mat-checkbox>

                <mat-form-field class="no-padding-margin-mat-form-field">
                    <mat-label>Sonstiges</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.impfprogramm_sonstiges_txt
                        " [disabled]="
                            !tierwohlprotokollSchwein.impfprogramm_sonstiges ||
                            tierwohlprotokollSchwein.impfprogramm_sonstiges ===
                                0
                        " matInput />
                </mat-form-field>
            </div>
        </div>
    </div>

    <div id="parasitoligische-massnahmen">
        <div style="margin-bottom: 1em">
            <h3 style="margin-block-end: 0.25em">
                Parasitologische Ma&szlig;nahmen
            </h3>
            <strong>Prophylaxe gegen:</strong>
        </div>

        <div class="row" style="width: 100%">
            <div class="row row--checkbox row--checkboxes__box" style="width: 50%">
                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.parasitologische_massnahmen_endoparasiten =
                            $event ? 1 : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.parasitologische_massnahmen_endoparasiten
                    " style="width: 25%">
                    Endoparasiten
                </mat-checkbox>

                <mat-form-field class="no-padding-margin-mat-form-field" style="width: 25%">
                    <mat-label>Zuletzt</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.parasitologische_massnahmen_endoparasiten_zuletzt
                        " [matDatepicker]="dpEndo" matInput />
                    <mat-datepicker-toggle [for]="dpEndo" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #dpEndo></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="row row--checkbox row--checkboxes__box" style="width: 50%">
                <mat-checkbox (ngModelChange)="
                        tierwohlprotokollSchwein.parasitologische_massnahmen_ektoparasiten =
                            $event ? 1 : 0
                    " [ngModel]="
                        !!tierwohlprotokollSchwein.parasitologische_massnahmen_ektoparasiten
                    " style="width: 25%">
                    Ektoparasiten
                </mat-checkbox>

                <mat-form-field class="no-padding-margin-mat-form-field" style="width: 25%">
                    <mat-label>Zuletzt</mat-label>
                    <input [(ngModel)]="
                            tierwohlprotokollSchwein.parasitologische_massnahmen_ektoparasiten_zuletzt
                        " [matDatepicker]="dpEkto" matInput />
                    <mat-datepicker-toggle [for]="dpEkto" matSuffix></mat-datepicker-toggle>
                    <mat-datepicker #dpEkto></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div id="einschaetzung">
        <div style="margin-bottom: 1em">
            <h3 style="margin-block-end: 0.25em">
                Aktuelle Einsch&auml;tzung / Auff&auml;lligkeiten /
                Bestandsproblem
            </h3>
            <div style="width: 50%">
                <mat-hint style="width: 50%"><strong>Hinweis:&nbsp;</strong>Bei gemeinsam festgestellten
                    Handlungsbedarf ist individuell für den Betrieb ein Plan für
                    Tiergesundheits- und Hygienemanagement zu erstellen. Ggf.
                    ist au&szlig;erdem ein Ma&szlig;nahmenplan aufzustellen, der
                    die Einzelaktivit&auml;ten (von Tierarzt und Tierhalter)
                    festlegt.
                </mat-hint>
            </div>
        </div>

        <mat-form-field style="width: 50%">
            <textarea [(ngModel)]="
                    tierwohlprotokollSchwein.einschaetzung_auffaelligkeiten_bestandsprobleme
                " matInput rows="3"></textarea>
        </mat-form-field>
    </div>

    <div id="empfehlungen">
        <h3>Empfehlungen / Ma&szlig;nahmen</h3>

        <mat-form-field style="width: 50%">
            <textarea [(ngModel)]="tierwohlprotokollSchwein.empfehlungen_massnahmen" matInput rows="3"></textarea>
        </mat-form-field>
    </div>

    <div id="weitere-bemerkungen">
        <h3>Weitere Bemerkungen</h3>

        <mat-form-field style="width: 50%">
            <textarea [(ngModel)]="tierwohlprotokollSchwein.weitere_bemerkungen" matInput rows="3"></textarea>
        </mat-form-field>
    </div>

    <div>
        <div>
            <h3>Plan f&uuml;r Tiergesundheits- und Hygienemanagement</h3>
            <div class="row row--radio_buttons">
                <mat-radio-group [(ngModel)]="
                        tierwohlprotokollSchwein.plan_tiergesundheit_hygienemanagement
                    ">
                    <mat-radio-button [value]="1" class="row--radio_buttons__btn">Ja
                    </mat-radio-button>
                    <mat-radio-button [value]="0" class="row--radio_buttons__btn">Nein
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div>
            <h3>Ma&szlig;nahmenplan mit Einzelaktivit&auml;ten</h3>
            <div class="row row--radio_buttons">
                <mat-radio-group [(ngModel)]="
                        tierwohlprotokollSchwein.massnahmenplan_einzelaktivitaeten
                    ">
                    <mat-radio-button [value]="1" class="row--radio_buttons__btn">Ja
                    </mat-radio-button>
                    <mat-radio-button [value]="0" class="row--radio_buttons__btn">Nein
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div id="durchfuehrung-bis">
            <h3>Durchf&uuml;hrung vereinbart bis</h3>
            <mat-form-field class="no-padding-margin-mat-form-field" style="width: 25%">
                <input [(ngModel)]="tierwohlprotokollSchwein.durchfuehrung_bis" [matDatepicker]="durchfuehrungBis"
                    matInput />
                <mat-datepicker-toggle [for]="durchfuehrungBis" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #durchfuehrungBis></mat-datepicker>
            </mat-form-field>
        </div>

        <div id="naechster-bestandsbesuch-bis">
            <h3>N&auml;chster Bestandsbesuch bis</h3>
            <mat-form-field class="no-padding-margin-mat-form-field" style="width: 25%">
                <input [(ngModel)]="
                        tierwohlprotokollSchwein.naechster_bestandsbesuch
                    " [matDatepicker]="naechsterBesuchBis" matInput />
                <mat-datepicker-toggle [for]="naechsterBesuchBis" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #naechsterBesuchBis></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
</div>

<div mat-dialog-actions>
    <button (click)="dialogRef.close()" mat-flat-button>Abbrechen</button>
    <button (click)="save()" color="accent" mat-flat-button>Speichern</button>
</div>