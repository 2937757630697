import { Pipe, PipeTransform } from '@angular/core';
import {BestandsbuchArtikelTier} from "../bestandsbuch/classes/bestandsbuch-artikel-tier";

@Pipe({
  name: 'tierSucheFilter'
})
export class TierSucheFilterPipe implements PipeTransform {

    transform(tiere: BestandsbuchArtikelTier[], ohrenMarkeSubstring: string): BestandsbuchArtikelTier[] {
        if (ohrenMarkeSubstring) {
            return tiere.filter(tier => tier.ohrmarke_notnull.toLowerCase().includes(ohrenMarkeSubstring.toLowerCase()));
        } else {
            return tiere;
        }
    }
}
