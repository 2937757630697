<div class="one-row">
    <span class="spacer"></span>

    <div *ngIf="kunde" class="one-row" style="width: 90%; margin: 0; margin-left: 24px; font-size: 0.8em">
                <span
                    (click)="openToolbarVVVOAuswaehlenDialog()">Betrieb: {{ kunde?.bezeichnung_final }} {{ kunde?.zusatz_final
                    }}</span>
        <button (click)="betriebAbwaehlen()" color="accent" mat-icon-button>
            <mat-icon style="transform:scale(0.85);">clear</mat-icon>
        </button>
        <span class="spacer"></span>
    </div>
    <div *ngIf="!kunde" class="one-row" style="width: 100%; margin: 0;">
        <span class="spacer"></span>
        <button (click)="openToolbarVVVOAuswaehlenDialog()" color="accent" mat-button>Betrieb ausw&auml;hlen</button>
        <span class="spacer"></span>
    </div>

    <span class="spacer"></span>
</div>
