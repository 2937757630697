import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {MessageService} from '../../services/message.service';
import {Observable, Subject} from 'rxjs';
import {AppService} from '../../app.service';
import {FileUploadDialogComponent} from './file-upload-dialog.component';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {

    fileUploaded = new Subject<string>();

    constructor(private dialog: MatDialog,
                private http: HttpClient,
                private notification: MessageService,
                private appService: AppService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    watchFileUploaded(): Observable<string> {
        return this.fileUploaded.asObservable();
    }

    openAddFilesDialog(id: number, nameOfIdField: string, apiUrl: string, callbackOk, callbackCancel, options?: {
        allowImages?: boolean
    }) {
        const dialogRef = this.dialog.open(FileUploadDialogComponent, {
            width: '550px',
            data: {id: id, nameOfIdField: nameOfIdField, apiUrl: apiUrl, allowImages: options?.allowImages}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                callbackOk();
            } else {
                callbackCancel();
            }
        });
    }

    openAddFilesDialogIds(ids: number[], nameOfIdField: string, apiUrl: string, callbackOk, callbackCancel) {
        const dialogRef = this.dialog.open(FileUploadDialogComponent, {
            width: '550px',
            data: {ids: ids, nameOfIdField: nameOfIdField, apiUrl: apiUrl}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                callbackOk();
            } else {
                callbackCancel();
            }
        });
    }

    uploadFilesToServerForMultipleEntities(filesToUpload: File[], parameterName: string, ids: number[], apiUrl: string, datum: Date, callbackProgress, callbackResponse, callbackFinished) {
        if (filesToUpload.length > 0) {
            for (let i = 0; i < filesToUpload.length; i++) {

                const fileNumber = i;
                const file = filesToUpload[fileNumber];


                const fd = new FormData();
                fd.append(parameterName, JSON.stringify(ids));
                fd.append('dokument', file);
                if (datum) {
                    datum = new Date(datum);
                    fd.append('datum', datum.toISOString());
                }

                const options = {
                    headers: new HttpHeaders('Phito-Type: fileupload'),
                    reportProgress: true,
                };

                // const request = new HttpRequest('POST', this.apiBaseUrl+apiUrl, fd, options);
                this.http.post<any>(this.apiBaseUrl + apiUrl, fd, options).subscribe({
                    next: event => {
                        console.log(typeof event);
                        if (event.type === HttpEventType.UploadProgress) {
                            const percentDone = Math.round(100 * event.loaded / event.total);
                            callbackProgress(file.name, percentDone);
                        } else {
                            callbackResponse(event);
                        }
                    }, error: error => {
                        console.error('Error while uploading file: ' + file.name);
                        this.notification.errorMessage('Fehler: Datei konnte nicht hochgeladen werden! ', error);
                    }, complete: () => {
                        this.notification.infoMessage(file.name + ' wurde erfolgreich hochgeladen!');
                        this.fileUploaded.next(file.name);
                        callbackFinished();
                    }
                });
            }
        }
    }

    uploadFileToServer(file: File, nameOfIdField: string, id: number, apiUrl: string) {

        const fd = new FormData();
        fd.append(nameOfIdField, id.toString());
        fd.append('dokument', file);

        const params = new HttpParams();


        const options = {
            headers: new HttpHeaders('Phito-Type: fileupload'),
            params: params,
            reportProgress: true,
        };

        return this.http.post<any>(this.apiBaseUrl + apiUrl, fd, options);
    }

    uploadFormularToServer(formData: FormData, apiUrl: string) {

        const params = new HttpParams();


        const options = {
            headers: new HttpHeaders('Phito-Type: fileupload'),
            params: params,
            reportProgress: true,
        };

        return this.http.post<any>(this.apiBaseUrl + apiUrl, formData, options);
    }

    uploadFilesToServer(filesToUpload: File[], nameOfIdField: string, id: number, apiUrl: string) {
        if (filesToUpload.length > 0) {
            for (let i = 0; i < filesToUpload.length; i++) {

                const fileNumber = i;
                const file = filesToUpload[fileNumber];

                const fd = new FormData();
                fd.append(nameOfIdField, id.toString());
                fd.append('dokument', file);

                const params = new HttpParams();


                const options = {
                    headers: new HttpHeaders('Phito-Type: fileupload'),
                    params: params,
                    reportProgress: true,
                };

                this.appService.setDataLoading(true);
                this.http.post<any>(this.apiBaseUrl + apiUrl, fd, options).subscribe(event => {
                    if (event.type === HttpEventType.UploadProgress) {
                        const percentDone = Math.round(100 * event.loaded / event.total);
                        // this.filesProgressValues[i] = percentDone;
                    } else if (event instanceof HttpResponse) {
                    }
                }, error => {
                    console.error('Error while uploading file: ' + file.name);
                    this.notification.errorMessage('Fehler: Datei konnte nicht hochgeladen werden! ', error);
                    this.appService.setDataLoading(false);
                }, () => {
                    this.notification.infoMessage(file.name + ' wurde erfolgreich hochgeladen!');
                    this.appService.setDataLoading(false);
                    this.fileUploaded.next(file.name);
                });
            }
        }
    }
}
