<kiene-kiene-table [tableDescriptor]="tableDescriptor" (optionClicked)="catchOptionEvent($event)"></kiene-kiene-table>

<!--<div class="kiene-content-page">-->
<!--  <mat-card>-->
<!--    <div *ngIf="isLoading" class="kiene-loading-spinner">-->
<!--      <mat-spinner *ngIf="isLoading"></mat-spinner>-->
<!--    </div>-->
<!--    <div class="kiene-table-header">-->
<!--      <h3>Befundmitteilungen</h3>-->
<!--      <mat-checkbox *ngIf="showTierarztGruppeCheckbox" [(ngModel)]="queryOnlyTierarztgruppe"-->
<!--        (change)="filterTierarztgruppe()">Nur meine Tierarztgruppe</mat-checkbox>-->
<!--      <div>-->

<!--        <button style="display: inline-block; width: 50px;" mat-icon-button color="accent"-->
<!--          [matMenuTriggerFor]="filterMenu" matTooltip="Filtern">-->
<!--          <mat-icon>filter_list</mat-icon>-->
<!--        </button>-->
<!--        <mat-menu #filterMenu="matMenu" [class]="'filter_menu'">-->
<!--          <div *ngIf="hasPermission()" style="padding: 5px;">-->
<!--            <div>-->
<!--              <mat-checkbox (change)="changedFilterAbgerechnetValue($event)" [checked]="filterAbgerechnetValue">Befund-->
<!--                nicht abgerechnet</mat-checkbox>-->
<!--            </div>-->
<!--          </div>-->
<!--        </mat-menu>-->

<!--        <button mat-icon-button color="primary" (click)="refresh()">-->
<!--          <mat-icon>refresh</mat-icon>-->
<!--        </button>-->
<!--        <button mat-icon-button color="primary" *kienePermission="[1312,2605,2505]"-->
<!--          (click)="tableAddFormVisible = !tableAddFormVisible">-->
<!--          <mat-icon>{{tableAddFormVisible == true ? 'remove' : 'add'}}</mat-icon>-->
<!--        </button>-->
<!--        <button mat-icon-button color="primary" *kienePermission="[1318]" (click)="openDownloadAllDialog()">-->
<!--          <mat-icon>save_alt</mat-icon>-->
<!--        </button>-->
<!--        <button *kienePermission="[1339]" mat-icon-button color="primary"-->
<!--          matTooltip="alle offenen Idexx Positionen exportieren" (click)="exportAllIdexxTestlaeufe()">-->
<!--          <mat-icon>file_upload</mat-icon>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div *kienePermission="[1312,2605,2505]">-->
<!--      <div class="table-add-form" *ngIf="tableAddFormVisible">-->
<!--        <div class="button-row">-->
<!--          <button mat-raised-button *kienePermission="[1312]" (click)="openNewBefundmitteilung()">-->
<!--            <mat-icon color="primary">assignment</mat-icon> Neue Befundmitteilung-->
<!--          </button>-->
<!--          <button mat-raised-button *kienePermission="[2605]" (click)="openNewBefundmitteilungNotizDialog()">-->
<!--            <mat-icon color="primary">description</mat-icon> Neuer Notizzettel-->
<!--          </button>-->
<!--          <button mat-raised-button *kienePermission="[2505]" (click)="openNewBefundmitteilungSampleSheetDialog()">-->
<!--            <mat-icon color="primary">list_alt</mat-icon> Neuer Probenbegleitschein-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div>-->
<!--      <mat-form-field style="width: 100%;">-->
<!--        <mat-label>Suchen</mat-label>-->
<!--        <input matInput [formControl]="searchCtrl">-->
<!--      </mat-form-field>-->
<!--    </div>-->

<!--    <mat-table class="kiene-table" #table [dataSource]="befundmitteilungDataSource" matSort matSortActive="erstellt"-->
<!--      matSortDisableClear matSortDirection="desc">-->

<!--      <ng-container matColumnDef="tagebuch_nr">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header>Tagebuchnummer</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.befund_id, row.recposition)">{{row.tagebuch_nr}}-->
<!--        </mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="untersuchungsmaterial">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Untersuchungsmaterial</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.befund_id, row.recposition)">-->
<!--          {{row.untersuchungsmaterial}}</mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="tierarztgruppe">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Tierarzt-Grp.</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.befund_id, row.recposition)">{{row.tierarztgruppe}}-->
<!--        </mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="kunde">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Kunde</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.befund_id, row.recposition)">{{row.kunde}}</mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="gruppe">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Gruppe</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.befund_id, row.recposition)">{{row.gruppe}}</mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="eingang">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Untersuchungsbeginn</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.befund_id, row.recposition)">{{row.eingang | date:-->
<!--          'shortDate'}}</mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="ausgang">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Untersuchungsende</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.befund_id, row.recposition)">{{row.ausgang | date:-->
<!--          'shortDate'}}</mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="status">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Status</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.befund_id, row.recposition)" [matTooltip]="row.status">-->
<!--          <mat-icon *ngIf="row.status_id === 11">fiber_new</mat-icon>-->
<!--          <mat-icon *ngIf="row.status_id === 12">lock</mat-icon>-->
<!--          <mat-icon *ngIf="row.status_id === 13">done</mat-icon>-->
<!--          <mat-icon *ngIf="row.status_id === 14">cancel</mat-icon>-->
<!--          <mat-icon *ngIf="row.status_id === 15">edit</mat-icon>-->
<!--        </mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="rechnung_id">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true"></mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row">-->
<!--          <div *kienePermission="[2971]">-->
<!--            <mat-icon *ngIf="row.rechnung_id" matTooltip="Rechnung anzeigen" (click)="openInvoice(row)">attach_money-->
<!--            </mat-icon>-->
<!--          </div>-->
<!--        </mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="erstellt">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header disableClear="true">Erstellt</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.befund_id, row.recposition)">-->
<!--          {{row.erstellt | date: 'dd.MM.yyyy HH:mm:ss'}}</mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="pdf">-->
<!--        <mat-header-cell *matHeaderCellDef disableClear="true">PDF</mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" style="cursor: pointer;">-->
<!--          <div *kienePermission="[1317]">-->

<!--            <button *ngIf="row.pdf_id" mat-icon-button (click)="downloadAllPdfsForBefundmitteilungMerged(row)">-->
<!--              <mat-icon color="primary">cloud_download</mat-icon>-->
<!--            </button>-->
<!--          </div>-->
<!--        </mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="befunde_extern">-->
<!--        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row" (click)="clickedRow(row.befund_id, row.recposition)">-->
<!--          <mat-icon *ngIf="row.befunde_extern > 0">attach_file</mat-icon>-->
<!--        </mat-cell>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="options">-->
<!--        <mat-header-cell *matHeaderCellDef></mat-header-cell>-->
<!--        <mat-cell *matCellDef="let row">-->
<!--          <mat-menu #befundOptionsMenu>-->
<!--            <ng-template matMenuContent>-->
<!--              <div *ngIf="!row.antibiogramm_id">-->
<!--                <button *kienePermission="[1336]" mat-menu-item (click)="transformToAntibiogramm(row)">-->
<!--                  <mat-icon>swap_horiz</mat-icon>-->
<!--                  <span>in Antibiogramm umwandeln</span>-->
<!--                </button>-->
<!--              </div>-->
<!--              <div>-->
<!--                <button *kienePermission="[1337]" mat-menu-item (click)="transformToUntersuchungsauftrag(row)">-->
<!--                  <mat-icon>swap_horiz</mat-icon>-->
<!--                  <span>Untersuchungsauftrag generieren</span>-->
<!--                </button>-->
<!--              </div>-->
<!--              <div *ngIf="row.antibiogramme > 0">-->
<!--                <button *kienePermission="[1811]" mat-menu-item (click)="goToAntibiogramme(row)">-->
<!--                  <mat-icon>play_arrow</mat-icon>-->
<!--                  <span>Antibiogramme anzeigen</span>-->
<!--                </button>-->
<!--              </div>-->

<!--              <div *ngIf="row.untersuchungsauftraege > 0">-->
<!--                <button *kienePermission="[2511]" mat-menu-item (click)="goToUntersuchungsauftraege(row)">-->
<!--                  <mat-icon>play_arrow</mat-icon>-->
<!--                  <span>Untersuchungsaufträge anzeigen</span>-->
<!--                </button>-->
<!--              </div>-->
<!--              <button *kienePermission="[1322]" mat-menu-item (click)="openAddExternalPdfsDialog(row)">-->
<!--                <mat-icon>forward</mat-icon>-->
<!--                <span>PDFs hinzuf&uuml;gen</span>-->
<!--              </button>-->
<!--              <button *kienePermission="[1317]" mat-menu-item (click)="openPdf(row)">-->
<!--                <mat-icon>save_alt</mat-icon>-->
<!--                <span>Befund einzeln herunterladen</span>-->
<!--              </button>-->
<!--              <button *kienePermission="[1314]" mat-menu-item (click)="openConfirmDeleteBefundmitteilungDialog(row)">-->
<!--                <mat-icon>delete</mat-icon>-->
<!--                <span>Befund l&ouml;schen</span>-->
<!--              </button>-->
<!--              <button *kienePermission="[1320]" mat-menu-item (click)="openInvoice(row)">-->
<!--                <mat-icon>attach_money</mat-icon>-->
<!--                <span>Rechnung anzeigen</span>-->
<!--              </button>-->
<!--              <button *kienePermission="[2971]" [disabled]="!row.rechnung_id" mat-menu-item-->
<!--                (click)="rechnungAnzeigen(row)">-->
<!--                <mat-icon>attach_money</mat-icon>-->
<!--                <span>zu Rechnung springen</span>-->
<!--              </button>-->
<!--              <button *kienePermission="[1308]" mat-menu-item (click)="recreateBefund(row)">-->
<!--                <mat-icon>edit</mat-icon>-->
<!--                <span>Befund PDF neu erstellen</span>-->
<!--              </button>-->
<!--              <button *kienePermission="[1338]" mat-menu-item (click)="befundDuplizieren(row)">-->
<!--                <mat-icon>file_copy</mat-icon>-->
<!--                <span>Befund duplizieren</span>-->
<!--              </button>-->
<!--              <button *kienePermission="[2605]" mat-menu-item (click)="createNotiz(row)">-->
<!--                <mat-icon>note_add</mat-icon>-->
<!--                <span>Notizzettel erstellen</span>-->
<!--              </button>-->
<!--              <button [disabled]="row.status_id !== 12" *kienePermission="[1329]" mat-menu-item-->
<!--                (click)="cancelBefund(row)">-->
<!--                <mat-icon>cancel</mat-icon>-->
<!--                <span>Befund stornieren</span>-->
<!--              </button>-->
<!--              <button [disabled]="row.status_id !== 12" *kienePermission="[1309]" mat-menu-item-->
<!--                (click)="reOpenBefund(row)">-->
<!--                <mat-icon>lock_open</mat-icon>-->
<!--                <span>Befund wieder &ouml;ffnen</span>-->
<!--              </button>-->
<!--              <button [disabled]="!rowContainsIdexxArtikel(row)" *kienePermission="[1339]" mat-menu-item-->
<!--                (click)="exportIdexxTestlauf(row)">-->
<!--                <mat-icon>file_upload</mat-icon>-->
<!--                <span>Idexx Positionen exportieren</span>-->
<!--              </button>-->
<!--            </ng-template>-->
<!--          </mat-menu>-->
<!--          <button *kienePermission="[1322,1319,1314,1320]" mat-icon-button [matMenuTriggerFor]="befundOptionsMenu">-->
<!--            <mat-icon>more_vert</mat-icon>-->
<!--          </button>-->
<!--        </mat-cell>-->
<!--      </ng-container>-->

<!--      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>-->
<!--      <mat-row *matRowDef="let row; columns: displayedColumns" kieneDropFile-->
<!--        (onFileDropped)="uploadExterneBefundmitteilung($event, row)"></mat-row>-->

<!--    </mat-table>-->
<!--    <mat-paginator [length]="totalCount" [showFirstLastButtons]="true" [pageSize]="defaultPageSize"-->
<!--      [pageSizeOptions]="pageSizeOptions"></mat-paginator>-->
<!--  </mat-card>-->
<!--</div>-->
