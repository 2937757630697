<h1 mat-dialog-title>Standardtierarten bearbeiten</h1>
<div mat-dialog-content>
    <p>f&uuml;r Betriebsart: <strong>{{ betriebsart?.bezeichnung }}</strong></p>
    <mat-form-field style="width: 100%;">
        <mat-label>Tierarten</mat-label>
        <mat-select [multiple]="true" [formControl]="tierartenCtrl" [compareWith]="tierartComparator">
            <mat-option *ngFor="let t of tierarten" [value]="t">{{ t.bezeichnung }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</div>
