import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from '../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-stammdaten',
    templateUrl: './stammdaten.component.html',
    styleUrls: ['./stammdaten.component.css'],
})
export class StammdatenComponent implements OnInit {

    constructor(private localStorageService: LocalStorageService) {
        this.localStorageService.setCurrentClientVisible(false);
        this.localStorageService.setShoppingCartVisible(false);
    }

    ngOnInit() {
    }

    isTabMenuVisible(path: string) {
        return this.localStorageService.currentUserCanSeeTab(path);
    }
}
