<h1 mat-dialog-title style="font-size: 2em; font-weight: bold">
    {{ this.idexxTestlauf?.testlaufnummer }}
</h1>
<div mat-dialog-content style="padding-top: 2.5%">
    <div class="kiene-color statisics">Proben Statistik</div>
    <div>
        <div class="list">
            <p class="list-item">
                <span class="list-item-description">Test:</span>
                {{ this.idexxTestlauf?.testcode }}
            </p>
            <p class="list-item">
                <span class="list-item-description">Tierart:</span>
                {{ this.idexxTestlauf?.spezies }}
            </p>
            <p class="list-item">
                <span class="list-item-description">Probe:</span>
                {{ this.idexxTestlauf?.probenart }}
            </p>
            <p class="list-item">
                <span class="list-item-description">Kunde:</span>
                {{ this.idexxTestlauf?.kunde_name }}
            </p>
            <p class="list-item">
                <span class="list-item-description">Pr&uuml;fer:</span>
                {{ this.idexxTestlauf?.pruefer_name }}
            </p>
            <p class="list-item">
                <span class="list-item-description">Charge:</span>
                {{ this.idexxTestlauf?.charge }} (Verfallsdatum:
                {{
                    this.idexxTestlauf?.chargen_verfallsdatum | date: "dd.MM.y"
                }})
            </p>
            <div
                *ngIf="this.idexxTestlauf?.kommentar"
                class="list-item"
                style="height: auto"
            >
                <span class="list-item-description">Notiz:</span>
                <div style="max-width: 500px; width: 82%">
                    {{ this.idexxTestlauf?.kommentar }}
                </div>
            </div>
        </div>
    </div>

    <div>
        <table
            mat-table
            [dataSource]="getTestlaufAsArray()"
            style="width: 100%"
        >
            <ng-container matColumnDef="gesamt">
                <th mat-header-cell class="header-cell" *matHeaderCellDef>
                    Gesamt
                </th>
                <td mat-cell class="cell" *matCellDef="let anzahl">
                    {{ anzahl.anzahl_gesamt }} Stk.
                </td>
            </ng-container>
            <ng-container matColumnDef="positiver">
                <th mat-header-cell class="header-cell" *matHeaderCellDef>
                    Positiv
                </th>
                <td mat-cell class="cell" *matCellDef="let anzahl">
                    {{ anzahl.anzahl_positiver }} Stk.
                </td>
            </ng-container>
            <ng-container matColumnDef="negativer">
                <th mat-header-cell class="header-cell" *matHeaderCellDef>
                    Negativ
                </th>
                <td mat-cell class="cell" *matCellDef="let anzahl">
                    {{ anzahl.anzahl_negativer }} Stk.
                </td>
            </ng-container>
            <ng-container matColumnDef="ungueltiger">
                <th mat-header-cell class="header-cell" *matHeaderCellDef>
                    Ung&uuml;ltig
                </th>
                <td mat-cell class="cell" *matCellDef="let anzahl">
                    {{ anzahl.anzahl_ungueltiger }} Stk.
                </td>
            </ng-container>
            <ng-container matColumnDef="fraglicher">
                <th mat-header-cell class="header-cell" *matHeaderCellDef>
                    nicht eindeutig
                </th>
                <td mat-cell class="cell" *matCellDef="let anzahl">
                    {{ anzahl.anzahl_fraglicher }} Stk.
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="cols"></tr>
            <tr mat-row *matRowDef="let row; columns: cols"></tr>
        </table>
    </div>

    <kiene-kiene-table [tableDescriptor]="tableDescriptor"> </kiene-kiene-table>
    <div mat-dialog-actions style="flex-direction: column; align-items: end">
        <button mat-flat-button color="accent" (click)="close()">
            Schließen
        </button>
    </div>
</div>
