import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UrlaubskalenderService} from '../urlaubskalender.service';
import {KalenderEintrag, KalenderTyp} from '../classes/kalender-eintrag';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {MessageService} from '../../../services/message.service';

@Component({
    selector: 'kiene-urlaubsantrag-stornieren-dialog',
    templateUrl: './kalendereintrag-stornieren-dialog.component.html',
    styleUrls: ['./kalendereintrag-stornieren-dialog.component.scss']
})
export class KalendereintragStornierenDialogComponent implements OnInit {

    kalenderEintrag: KalenderEintrag;
    headline = 'Kalendereintrag stornieren';
    isLoading = true;

    aenderungDisabled = true;

    grundCtrl: UntypedFormControl = new UntypedFormControl();
    kalenderTyp = KalenderTyp;
    radioCtrl = new FormControl(0);

    constructor(@Inject(MAT_DIALOG_DATA) private data,
                public dialogRef: MatDialogRef<KalendereintragStornierenDialogComponent>,
                private kalenderService: UrlaubskalenderService, private messageService: MessageService) {
        if (data.kalendereintrag) {
            this.kalenderEintrag = this.data.kalendereintrag;
        }
    }

    ngOnInit(): void {
        this.radioCtrl.valueChanges.subscribe({
            next: value => {
                if (value === 0) {
                    this.aenderungDisabled = true;
                    this.grundCtrl.enable();
                } else {
                    this.aenderungDisabled = false;
                    this.grundCtrl.disable();
                }
            }
        });
    }


    save() {
        //stornieren
        if (this.radioCtrl.value === 0) {
            this.kalenderEintrag.stornierungsgrund = this.grundCtrl.value;
            this.kalenderService.delete(this.kalenderEintrag).subscribe({
                next: response => {
                    this.messageService.infoMessage('Stornierung erfolgreich!');
                    this.dialogRef.close(true);
                },
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        } else if (this.radioCtrl.value === 1) {
            this.kalenderService.createOrUpdate(this.kalenderEintrag).subscribe({
                next: response => {
                    this.messageService.infoMessage('Änderung erfolgreich!');
                    this.dialogRef.close(true);
                },
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        }

    }

}
