import {Component, Inject, OnInit} from '@angular/core';
import {VetGroup} from '../vet-group';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'kiene-update-vet-group-dialog',
    templateUrl: './update-vet-group-dialog.component.html',
    styleUrls: ['./update-vet-group-dialog.component.scss']
})
export class UpdateVetGroupDialogComponent implements OnInit {
    vetGroup: VetGroup = new VetGroup();

    constructor(public dialogRef: MatDialogRef<UpdateVetGroupDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.vetGroup = data.vetGroup;
    }

    ngOnInit() {
    }

    closeDialog() {
        this.dialogRef.close(this.vetGroup);
    }

    onCancelClick(): void {
        this.dialogRef.close(null);
    }
}
