import {Component, OnInit} from '@angular/core';
import {TableDescriptor, ToolbarButton} from '../../components/kiene-table/table-descriptor';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {MessageService} from '../../services/message.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {FileUploadService} from '../../dialogs/file-upload-dialog/file-upload.service';
import {environment} from '../../../environments/environment';
import {
    ConditionSetting,
    ConditionTableColumn,
    NumberTableColumn,
    TableColumn,
} from '../../components/kiene-table/table-column';
import {Condition, Operator, TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {GutschriftDetailsDialogComponent} from './gutschrift-details-dialog/gutschrift-details-dialog.component';
import {Kundengutschrift} from './gutschrift';
import {SelectDateDialogComponent} from '../../dialogs/select-date-dialog/select-date-dialog.component';
import {AppService} from '../../app.service';
import {
    ChangeCurrentClientDialogComponent
} from '../../administration/client/change-current-client/change-current-client-dialog/change-current-client-dialog.component';
import {HttpParams} from '@angular/common/http';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {Client} from '../../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-gutschriften',
    templateUrl: './gutschriften.component.html',
    styleUrls: ['./gutschriften.component.css'],
})
export class GutschriftenComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();
    currentClient: Client;

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private api: KieneTableService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private pdfService: PdfViewerDesktopService,
        private fileUploadService: FileUploadService,
        private route: ActivatedRoute,
        private appService: AppService
    ) {
        this.localStorageService.setCurrentClientVisible(true);
    }

    ngOnInit() {
        this.currentClient = this.localStorageService.getCurrentClient();

        this.localStorageService.watchCurrentClient().subscribe((client) => {
            this.currentClient = client;
        });

        this.tableDescriptor.headline = 'Gutschriften';
        this.tableDescriptor.uniqueIdentifier = 'Gutschriften';
        this.tableDescriptor.apiUrl =
            environment.BASE_URI + 'kundengutschrift/';
        // this.tableDescriptor.alternativeApiUrl = environment.BASE_URI + 'kundengutschrift/read_kunden.php';
        this.tableDescriptor.route = '/finanzen/gutschriften/';
        this.tableDescriptor.nameOfIdField = 'kundengutschrift_id';
        this.tableDescriptor.searchPlaceholder = 'Gutschriften durchsuchen';
        this.tableDescriptor.columns.push(
            new TableColumn('kundengutschrift_id', 'ID')
        );
        this.tableDescriptor.columns.push(new TableColumn('kunde', 'Kunde'));
        this.tableDescriptor.columns.push(new TableColumn('zusatz', 'Zusatz'));
        this.tableDescriptor.columns.push(
            new TableColumn('tierarzt', 'Tierarzt')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('rechnung_nr', 'Rechnung Nr.')
        );
        this.tableDescriptor.columns.push(
            new NumberTableColumn(
                'gesamt_brutto',
                'Betrag Brutto (Euro)',
                '1.2-2'
            )
        );
        this.tableDescriptor.columns.push(
            new TableColumn('datum', 'Datum Gutschrift', 'date')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('erstellt', 'Erstellt Am', 'datetime')
        );

        const conditionSettings = [
            new ConditionSetting(
                [new Condition('primanota_id', Operator.EXISTIERT, null)],
                {
                    icon: 'check',
                    color: 'green',
                    tooltip: 'Bereits zur FiBu exportiert',
                }
            ),
        ];
        this.tableDescriptor.columns.push(
            new ConditionTableColumn('primanota_id', 'Primanota', conditionSettings, {
                sortFields: ['primanota_id'],
            })
        );

        if (this.localStorageService.currentUserHasPermissions([2945])) {
            this.tableDescriptor.columns.push(
                new TableColumn('pdf_id', '', 'pdf')
            );
        }

        this.tableDescriptor.toolbarButtons.push(
            new ToolbarButton(
                'generate_provision',
                'request_quote',
                0,
                'Ergänzerprovisionen erzeugen'
            )
        );

        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.showDetailsAsOption = true;

        this.tableDescriptor.permissionView = 2941;
        this.tableDescriptor.permissionCreate = 2943;
        this.tableDescriptor.permissionUpdate = 2943;
        this.tableDescriptor.permissionDelete = 2944;

        this.tableDescriptor.options.push(
            new TableOption(
                'createPdf',
                'PDF neu erstellen',
                'picture_as_pdf',
                2945, {
                    conditions:
                        [new Condition('primanota_id', Operator.EXISTIERT_NICHT, undefined)]
                }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption('edit', 'Bearbeiten', 'edit', 2943)
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'showinvoice',
                'Rechnung anzeigen',
                'receipt',
                2971, {
                    conditions:
                        [new Condition('rechnung_id', Operator.EXISTIERT, '')]
                }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption('delete', 'Löschen', 'delete', 2944, {
                conditions: [
                    new Condition('primanota_id', Operator.EXISTIERT_NICHT, null),
                    new Condition('rechnung_id', Operator.EXISTIERT_NICHT, null),
                ]
            })
        );

        this.tableDescriptor.initialSortColumn = 'erstellt';
        this.tableDescriptor.initialSortDirection = 'desc';

        this.tableDescriptor.useFileDropEvent = true;

        this.fileUploadService.watchFileUploaded().subscribe((result) => {
            if (result) {
                this.api.reload();
            }
        });
    }

    catchOptionEvent(event: TableOptionEvent) {
        const gutschrift: Kundengutschrift = event.value;
        if (event.name === 'add') {
            this.createNewGutschriftDialog();
        } else if (event.name === 'edit' || event.name === 'details') {
            this.openEditDialog(event.value);
        } else if (event.name === 'showPdf') {
            const input = new PdfViewerDialogInput(
                'kundengutschrift/read_or_mail_pdf.php',
                new HttpParams().set(
                    'kundengutschrift_id',
                    gutschrift?.kundengutschrift_id?.toString()
                ),
                'Gutschrift ' + gutschrift?.kundengutschrift_id?.toString(),
                {
                    email: {
                        sende_mail: true,
                        kunde_id: gutschrift?.kunde_id,
                    },
                }
            );
            // bei Gutschriften die Rechnungsemailadresse verwenden
            input.isInvoice = true;
            this.pdfService.openPdfViewer(input);
        } else if (event.name === 'createPdf') {
            this.api
                .getOne(
                    environment.BASE_URI + 'kundengutschrift/create_pdf.php',
                    'kundengutschrift_id',
                    gutschrift?.kundengutschrift_id?.toString()
                )
                .subscribe({
                    next: () => {
                        this.api.reload();

                        const input = new PdfViewerDialogInput(
                            'kundengutschrift/read_or_mail_pdf.php',
                            new HttpParams().set(
                                'kundengutschrift_id',
                                gutschrift?.kundengutschrift_id?.toString()
                            ),
                            'Gutschrift ' + gutschrift?.kundengutschrift_id?.toString(),
                            {
                                email: {
                                    sende_mail: true,
                                    kunde_id: gutschrift?.kunde_id,
                                },
                            }
                        );
                        // bei Gutschriften die Rechnungsemailadresse verwenden
                        input.isInvoice = true;
                        this.pdfService.openPdfViewer(input);
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'PDF konnte nicht erstellt werden!',
                            err
                        );
                    },
                });
        } else if (event.name === 'showinvoice') {
            this.router.navigate(['../rechnungen', gutschrift.rechnung_id], {
                relativeTo: this.route,
            });
        } else if (event.name === 'generate_provision') {
            this.openProvisionDialog();
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        }
    }

    // openAddGutschriftDialog(g?: Gutschrift) {
    //   const dialogRef = this.dialog.open(AddGutschriftDialogComponent, {
    //     width: '500px',
    //     data: {
    //       gutschrift: g,
    //       apiUrl: 'gutschrift/update.php'
    //     }
    //   });

    //   dialogRef.afterClosed().subscribe(result => {
    //     this.api.reload();
    //   });

    // }

    openDeleteDialog(kg: Kundengutschrift) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Gutschrift wirklich löschen?',
                text: 'Gutschrift wirklich löschen?',
                textButton: 'löschen',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.api
                    .deleteEntity(environment.BASE_URI + 'kundengutschrift/delete.php', kg)
                    .subscribe(
                        (result) => {
                            this.messageService.infoMessage(
                                'Die Gutschrift wurde erfolgreich gelöscht!'
                            );
                            this.api.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Die Gutschrift konnte nicht gelöscht werden! ',
                                error
                            );
                        }
                    );
            }
        });
    }

    createNewGutschriftDialog() {
        if (!this.currentClient) {
            const dialogRef = this.dialog.open(
                ChangeCurrentClientDialogComponent,
                {
                    width: '50%',
                    data: {
                        closeOnSelect: true,
                    },
                }
            );

            dialogRef.afterClosed().subscribe((result) => {
                if (result?.save) {
                    const selectedClient: Client = result.client;
                    const kgutschrift = new Kundengutschrift();
                    kgutschrift.kunde_id = selectedClient.kunde_id;
                    kgutschrift.kunde = selectedClient.bezeichnung_final;
                    const dialogRefDetails = this.dialog.open(
                        GutschriftDetailsDialogComponent,
                        {
                            width: '90%',
                            data: {
                                kundengutschrift: kgutschrift,
                            },
                        }
                    );
                    dialogRefDetails.afterClosed().subscribe((saved) => {
                        if (saved) {
                            this.api.reload();
                        }
                    });
                }
            });
        } else {
            const kgutschrift = new Kundengutschrift();
            kgutschrift.kunde_id = this.currentClient.kunde_id;
            kgutschrift.kunde = this.currentClient.bezeichnung_final;
            const dialogRef = this.dialog.open(
                GutschriftDetailsDialogComponent,
                {
                    width: '90%',
                    data: {
                        kundengutschrift: kgutschrift,
                    },
                }
            );
            dialogRef.afterClosed().subscribe((saved) => {
                if (saved) {
                    this.api.reload();
                }
            });
        }
    }

    openEditDialog(gutschrift: Kundengutschrift) {
        const dialogRef = this.dialog.open(GutschriftDetailsDialogComponent, {
            width: '90%',
            data: {
                kundengutschrift: gutschrift,
            },
        });
        dialogRef.afterClosed().subscribe((saved) => {
            if (saved) {
                this.api.reload();
            }
        });
    }

    openProvisionDialog() {
        const dialogRef = this.dialog.open(SelectDateDialogComponent, {
            width: '33%',
            minWidth: '300px',
            data: {
                headline: 'Gutschriften für Provisionen erzeugen',
                text: 'Es werden für alle Tierärzte Provisionsgutschriften erzeugt, die bis zum Ende des eingegebenen Zeitraums Ergänzerfuttermittel vertrieben haben.',
                label: 'Ende des Zeitraums',
                okButton: 'Provisionen erzeugen',
            },
        });

        dialogRef.afterClosed().subscribe({
            next: (datum: Date) => {
                if (datum) {
                    const obj = {
                        bis: datum.toISOString(),
                    };
                    this.appService.setDataLoading(true);
                    this.api
                        .post(
                            environment.BASE_URI +
                            'kundengutschrift/generieren_provision.php',
                            obj,
                            undefined
                        )
                        .subscribe({
                            next: (response) => {
                                this.messageService.infoMessage(
                                    'Die Provisionsgutschriften wurden erfolgreich erzeugt!'
                                );
                                this.appService.setDataLoading(false);

                                this.api.reload();
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Die Provisionsgutschriften konnten nicht erzeugt werden!',
                                    err
                                );
                                this.appService.setDataLoading(false);

                                this.api.reload();
                            },
                        });
                }
            },
        });
    }
}
