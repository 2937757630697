import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {AppService} from '../../../services/app/app.service';

@Component({
    selector: 'kiene-new-password',
    templateUrl: './new-password.component.html',
    styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

    @Output('changePassword') changePassword = new EventEmitter<string>();

    passwordRepeat = '';

    showPassword = true;
    showPasswordRepeat = true;
    passwordCtrl = new FormControl(undefined, [Validators.minLength(8)]);
    passwordConfirmCtrl = new FormControl(undefined, [Validators.minLength(8)]);

    constructor(private appService: AppService) {
    }

    ngOnInit(): void {
        const pwd = this.appService.generateKienePassword();
        this.passwordCtrl.setValue(pwd);
        this.passwordConfirmCtrl.setValue(pwd);
    }

    save() {
        this.changePassword.emit(this.passwordCtrl.value);
    }
}
