import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'kiene-add-text-proposal-dialog',
    templateUrl: './add-text-proposal-dialog.component.html',
    styleUrls: ['./add-text-proposal-dialog.component.scss']
})
export class AddTextProposalDialogComponent implements OnInit {
    kuerzelText: string;

    constructor(
        public dialogRef: MatDialogRef<AddTextProposalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    ngOnInit() {
    }

    closeAndSafe() {
        this.dialogRef.close(this.kuerzelText);
    }

    onCancelClick(): void {
        this.dialogRef.close('');
    }
}
