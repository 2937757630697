export class Gutschrift {
    gutschrift_id: number;
    lieferant_id: number;
    lieferant: string;
    artikel_id: number;
    artikel: string;
    betrag: number;
    pdf_id: number;
    mwstsatz: number;
    primanota_id: number;
    datum: Date;
    gutschrift_nr: string;
    erstellt: Date;
    editiert: Date;
    bestellung_extern_id: number;
    gutschrift_id_storno: number;
    gutschrift_id_storniert: number;
}