import {Component, Input, OnChanges, OnInit, SimpleChanges,} from "@angular/core";
import {TableDescriptor} from "../../../components/kiene-table/table-descriptor";
import {environment} from "../../../../environments/environment";
import {MatDialog} from "@angular/material/dialog";
import {
    AddIndikationToMaterialDialogComponent
} from "./add-indikation-to-material-dialog/add-indikation-to-material-dialog.component";
import {Material} from "../material";
import {KieneTableService} from "../../../components/kiene-table/kiene-table.service";
import {MessageService} from "../../../services/message.service";
import {Indikation} from "./indikation";
import {ConfirmDeleteDialogComponent} from "../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component";
import {SaveForAllDialogComponent} from "../../../dialogs/save-for-all-dialog/save-for-all-dialog.component";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {HttpParams} from '@angular/common/http';
import {Mandant} from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: "kiene-artikel-indikation",
    templateUrl: "./artikel-indikation.component.html",
    styleUrls: ["./artikel-indikation.component.scss"],
})
export class ArtikelIndikationComponent implements OnInit, OnChanges {
    tableDescriptor = new TableDescriptor();

    @Input("material") material: Material;
    @Input("mandanten") mandanten: Mandant[] = [];

    indikationen: Indikation[] = [];

    constructor(
        public dialog: MatDialog,
        private api: KieneTableService,
        private messageService: MessageService
    ) {
    }

    ngOnInit() {
    }

    loadIndikationen() {
        if (this.material?.artikel_id) {
            let params = new HttpParams().set(
                "artikel_id",
                this.material.artikel_id.toString()
            );
            this.api
                .get(
                    environment.BASE_URI + "indikation/read_grouped.php",
                    params
                )
                .subscribe({
                    next: (response) => {
                        this.indikationen = response.records;
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            "Indikationen konnten nicht für den Artikel " +
                            this.material.artikel_me +
                            " geladen werden!",
                            err
                        );
                    },
                });
        }
    }

    reorderList(event: CdkDragDrop<Indikation[]>) {
        moveItemInArray(this.indikationen, event.previousIndex, event.currentIndex);
        const obj = {
            records: this.indikationen,
        };
        this.api
            .updateGeneric(environment.BASE_URI + "indikation/update_sort.php", obj)
            .subscribe({
                next: (response) => {
                    this.loadIndikationen();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        "Die Indikationen konnten nicht neu sortiert werden!",
                        err
                    );
                },
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.material) {
            this.loadIndikationen();
        }
    }

    reload() {
        this.loadIndikationen();
    }

    add() {
        this.openIndikationDialog();
    }

    saveIndikation(indikation: Indikation) {
        if (this.mandanten.length > 1) {
            // Array enthält nur Daten mit entspr. Berechtigung und bei Kiene
            let dialogRef = this.dialog.open(SaveForAllDialogComponent, {
                data: {
                    availableMandanten: this.mandanten,
                },
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    indikation.mandanten_ids = result;
                    this.save(indikation);
                }
            });
        } else {
            this.save(indikation);
        }
    }

    save(indikation: Indikation) {
        this.api
            .updateGeneric(
                environment.BASE_URI + "indikation/update_grouped.php",
                indikation
            )
            .subscribe({
                next: (saved) => {
                    this.messageService.infoMessage(
                        "Die Indikation " +
                        saved.diagnose +
                        " wurde erfolgreich gespeichert!"
                    );
                    this.reload();
                },
                error: (error) => {
                    console.debug(JSON.stringify(error));
                    this.messageService.errorMessage(
                        "Fehler! Die Indikation " +
                        indikation.diagnose +
                        " konnte nicht gespeichert werden! ",
                        error
                    );
                    this.api.reload();
                },
            });
    }

    openIndikationDialog(indikation?: Indikation) {
        const dialogRef = this.dialog.open(AddIndikationToMaterialDialogComponent, {
            width: "500px",
            data: {
                material: this.material,
                indikation: indikation,
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.saveIndikation(response);
            }
        });
    }

    openDeleteDialog(indikation: Indikation) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: "500px",
            data: {
                title: indikation.diagnose + " löschen?",
                text:
                    "Wollen Sie die Indikation " +
                    indikation.diagnose +
                    " wirklich löschen?",
            },
        });

        dialogRef.afterClosed().subscribe({
            next: (response) => {
                if (response) {
                    this.api
                        .deleteEntity(environment.BASE_URI + "indikation/delete.php", indikation)
                        .subscribe({
                            next: (result) => {
                                this.messageService.infoMessage(
                                    "Die Indikation wurde erfolgreich gelöscht!"
                                );
                                this.reload();
                            },
                            error: (error) => {
                                this.messageService.errorMessage(
                                    "Fehler! Es ein Problem beim Löschen der Indikation aufgetreten! ",
                                    error
                                );
                            },
                        });
                }
            },
            error: (error) => {
            },
        });
    }
}
