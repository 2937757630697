import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {GruppeTier} from "../classes/gruppe-tier";

@Component({
  selector: 'kiene-gruppe-tier-tod-melden-dialog',
  templateUrl: './gruppe-tier-tod-melden-dialog.component.html',
  styleUrls: ['./gruppe-tier-tod-melden-dialog.component.scss']
})
export class GruppeTierTodMeldenDialogComponent implements OnInit {

    tier: GruppeTier;
  constructor(
      public dialogRef: MatDialogRef<GruppeTierTodMeldenDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
        this.tier = data.tier;
  }

  ngOnInit(): void {
  }

}
