import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Meldung } from '../meldung';

@Component({
  selector: 'kiene-meldung-details-dialog',
  templateUrl: './meldung-details-dialog.component.html',
  styleUrls: ['./meldung-details-dialog.component.css']
})
export class MeldungDetailsDialogComponent implements OnInit {

  meldung = new Meldung();
  fehlerArray: string[] = [];

  constructor(public dialogRef: MatDialogRef<MeldungDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) {
    if (this.data && this.data.meldung) {
      this.meldung = this.data.meldung;
    }
  }

  ngOnInit(): void {
    if (this.meldung.fehler) {
      this.fehlerArray = this.meldung.fehler.split(';');
    }
  }

  getStatusColor() {
    let color = 'black';
    switch (this.meldung.status_id) {
      case 112:
      case 115:
      case 116: {
        color = '#8cb369';
        break;
      }
      case 113: {
        color = '#bc4b51';
        break;
      }
    }
    return color;
  }

  getStatusIcon() {
    let icon = 'circle';
    switch (this.meldung.status_id) {
      case 112:
      case 115:
      case 116: {
        icon = 'check_circle';
        break;
      }
      case 113: {
        icon = 'error';
        break;
      }
    }
    return icon;
  }

}
