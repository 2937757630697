<h1 mat-dialog-title>Tierbemerkungen</h1>

<div mat-dialog-content>

    <div *ngIf="!gruppenBemerkungen" style="width: 100%; display: flex; align-items: center; justify-content: center; text-align: center; margin: 20% 0">
        <mat-hint>Lade Tierbemerkungen...</mat-hint>
    </div>

    <div *ngIf="gruppenBemerkungen" style="margin-bottom: 12px">

        <div style="max-width: 40vw">
            <mat-hint class="hinweis-text">
                Hier aufgelistete Bemerkungen bitte zur Kenntnis nehmen und das Tier bei Bedarf erneut sichten,
                um dem Grund nachzugehen. Danach kann die Bemerkung abgehakt werden.
            </mat-hint>
        </div>


        <div *ngFor="let gruppenBemerkung of gruppenBemerkungen" style="margin-bottom: 12px">
            <div style="margin-left: 6px">
                <h4 style="margin-block-end: 0" class="green">{{gruppenBemerkung.kunde}} {{gruppenBemerkung.zusatz}}</h4>
                <h4 style="margin-block-start: 0" class="green">{{gruppenBemerkung.gruppe}}</h4>
                <div *ngFor="let tier of gruppenBemerkung.tiere">
                    <div style="margin: 6px 0 0 6px">
                        <strong>Ohrmarke: {{tier.ohrmarke_notnull}}</strong>
                        <table aria-label="" style="width: 100%">
                            <thead>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            </thead>
                            <tbody>
                            <tr *ngFor="let bemerkung of tier.bemerkungen" class="bemerkung" (click)="updateGruppenBemerkungRow($event, bemerkung)">
                                <td>
                                    <mat-checkbox [checked]="bemerkung.tierarzt === 1"
                                                  (change)="updateGruppenBemerkung($event, bemerkung)">
                                    </mat-checkbox>
                                </td>
                                <td>{{bemerkung.bemerkung}}</td>
                                <td>{{bemerkung.vorname.substring(0,1)}}. {{bemerkung.nachname}}</td>
                                <td style="text-align: end">{{bemerkung.erstellt | date: 'dd.MM.YY HH:mm'}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div mat-dialog-actions>
    <button mat-flat-button color="accent" (click)="dialogRef.close()">Schlie&szlig;en</button>
</div>
