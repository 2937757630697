<h1 mat-dialog-title>Filtereinstellungen</h1>
<div mat-dialog-content>
    <mat-form-field style="width: 100%;">
        <mat-label>Mindestalter in Tagen</mat-label>
        <input [formControl]="minAlterCtrl" type="number" matInput/>
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Maximalalter in Tagen</mat-label>
        <input [formControl]="maxAlterCtrl" type="number" matInput/>
    </mat-form-field>

    <mat-form-field style="width: 100%;">
        <mat-label>Simulieren f&uuml;r Datum</mat-label>
        <input matInput [matDatepicker]="datum" [matDatepickerFilter]="filterDates" [formControl]="datumCtrl">
        <mat-datepicker-toggle matSuffix [for]="datum"></mat-datepicker-toggle>
        <mat-datepicker #datum></mat-datepicker>
        <button matSuffix mat-icon-button (click)="datumCtrl.setValue(undefined)" color="accent"
                matTooltip="Datum zurücksetzen">
            <mat-icon>clear</mat-icon>
        </button>
    </mat-form-field>


    <mat-chip-list>
        <mat-chip *ngFor="let rasse of rassenListe" (removed)="removeRasse(rasse)">
            {{ rasse.name }}
            <button matChipRemove>
                <mat-icon>clear</mat-icon>
            </button>
        </mat-chip>
    </mat-chip-list>
    <kiene-autocomplete style="width: 100%;"
                        #rasseAutocomplete
                        (elementSelected)="setSelectedRasse($event)"
                        [displayFields]="['kuerzel', 'name']"
                        [genericApiUrl]="'rasse/read.php'"
                        [placeholder]="'Rasse hinzufügen'"
                        [separator]="' - '"
                        [showDeleteButton]="false"
                        [clearAfterSelect]="true"
                        [focus]="false"
                        [showClear]="false"
    ></kiene-autocomplete>

    <h5>Geschlechter</h5>
    <div class="one-row">
        <mat-checkbox (change)="setMaennlich($event)" [checked]="maennlich" style="margin-right: 24px;">Männlich
        </mat-checkbox>
        <mat-checkbox (change)="setWeiblich($event)" [checked]="weiblich">Weiblich</mat-checkbox>
        <span class="spacer"></span>

    </div>
    <h5>Tiere in Abholliste</h5>
    <mat-checkbox (change)="setInAbholliste($event)" [checked]="nichtInAbholliste"> nicht anzeigen</mat-checkbox>
</div>
<div mat-dialog-actions align="end">
    <button mat-stroked-button (click)="resetFilter()">Felder leeren</button>
    <span class="spacer"></span>
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Filtern</button>
</div>

