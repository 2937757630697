<h1 mat-dialog-title>Inforamtion</h1>
<div mat-dialog-content>
  <mat-form-field style="min-width:50%;">
    <input matInput type="text" placeholder="Titel" [(ngModel)]="information.titel">
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill" style="width: 100%;">
    <mat-label>Text</mat-label>
    <textarea matInput [(ngModel)]="information.txt" placeholder="Text"></textarea>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Gültig ab</mat-label>
    <input matInput [matDatepicker]="from" [(ngModel)]="information.gueltig_von">
    <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
    <mat-datepicker #from></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Gültig bis</mat-label>
    <input matInput [matDatepicker]="to" [(ngModel)]="information.gueltig_bis">
    <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
    <mat-datepicker #to></mat-datepicker>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Abbrechen</button>
  <button mat-flat-button color="primary" cdkFocusInitial (click)="save()">Speichern</button>
</div>