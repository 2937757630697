<mat-form-field style="width: 100%">
    <mat-label>Passwort</mat-label>
    <input
        [type]="showPassword ? 'text' : 'password'"
        placeholder="Passwort"
        matInput
        [formControl]="passwordCtrl"
    />
    <button mat-icon-button matSuffix (click)="showPassword = !showPassword">
        <mat-icon>{{showPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <mat-error *ngIf="passwordCtrl.invalid">Bitte Passwort eintragen!</mat-error>
    <mat-hint>Mindestens 8 Zeichen, davon mindestens ein Gro&szlig;buchstaben, ein Kleinbuchstaben und eine Ziffer!
    </mat-hint>
</mat-form-field>
<mat-form-field style="width: 100%; margin-top: 24px;">
    <mat-label>Passwort wiederholen</mat-label>
    <input
        [type]="showPasswordRepeat ? 'text' : 'password'"
        placeholder="Passwort wiederholen"
        matInput
        [formControl]="passwordConfirmCtrl"
    >
    <button mat-icon-button matSuffix (click)="showPasswordRepeat = !showPasswordRepeat">
        <mat-icon>{{showPasswordRepeat ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <mat-error
        *ngIf="passwordConfirmCtrl.invalid"
    >Passwort stimmt nicht überein!
    </mat-error
    >
</mat-form-field>
<div class="one-row">
    <span class="spacer"></span>
    <button mat-flat-button color="accent" (click)="save()"
            [disabled]="!passwordCtrl.valid || !passwordConfirmCtrl.valid || passwordCtrl.value?.length !== passwordConfirmCtrl.value?.length">
        Passwort speichern
    </button>
</div>
