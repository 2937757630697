import { Directive, Output, EventEmitter, HostListener, HostBinding } from '@angular/core';

@Directive({
  selector: '[kieneDropFile]'
})
export class DropFileDirective {

  @Output() onFileDropped = new EventEmitter<any>();

  @HostBinding('style.background-color') private background = 'transparent'
  @HostBinding('style.opacity') private opacity = '1'

  constructor() { }

  //Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    console.debug('Drag over');
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#00ffc5';
    this.opacity = '0.8'
  }
	
  //Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    console.debug('Drag leave');
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'transparent'
    this.opacity = '1'
  }
	
  //Drop listener
  @HostListener('drop', ['$event']) public ondrop(evt) {
    console.debug('Drop file');
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'transparent'
    this.opacity = '1'
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files)
    }
  }

}
