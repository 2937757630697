import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Client} from "../../../../../../../projects/kiene-core/src/lib/components/client/classes/client";
import {MessageService} from "../../../../../services/message.service";
import {Abholliste} from "../../abholliste";

@Component({
    selector: 'kiene-abholliste-dialog',
    templateUrl: './abholliste-dialog.component.html',
    styleUrls: ['./abholliste-dialog.component.scss']
})
export class AbhollisteDialogComponent implements OnInit {
    public kommentarCtrl: FormControl;
    public abholdatumCtrl: FormControl;
    public abholer: Client;


    constructor(
        public dialogRef: MatDialogRef<AbhollisteDialogComponent>,
        private messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public abholliste: Abholliste,
    ) {
    }

    ngOnInit(): void {
        this.kommentarCtrl = new FormControl(this.abholliste?.kommentar);
        this.abholdatumCtrl = new FormControl(this.abholliste?.abholdatum);

    }

    public save(): void {
        if (this.kommentarCtrl.invalid || this.abholdatumCtrl.invalid) {
            this.messageService.alertMessage('Bitte füllen Sie alle Felder aus.');
            return;
        }
        this.abholliste.kommentar = this.kommentarCtrl.value;
        this.abholliste.abholdatum = this.abholdatumCtrl.value;
        this.abholliste.kunde_id = this.abholer.kunde_id;
        this.abholliste.status_id = 201;

        this.dialogRef.close(this.abholliste);
    }

    public isDisabled(): boolean {
        return this.abholdatumCtrl?.invalid || !this.abholer || this.abholliste?.status_id === 202;
    }

    public setAbholer(event: Client): void {
        this.abholer = event;
    }
}
