<h1 mat-dialog-title>Tierlisten f&uuml;r {{gruppe?.bezeichnung}}</h1>
<div mat-dialog-content>
    <p *ngIf="isLoading">Die Listen werden geladen...</p>
    <ul style="list-style-type: none; padding: 0; margin: 0; min-width: 250px;">
        <li *ngFor="let tierliste of tierlisten" style="border-bottom: 1px solid #999; padding-bottom: 6px;">
            <kiene-tierlistenelement (tierlisteClicked)="select($event)" [historie]="true" [showLock]="true"
                [tierliste]="tierliste"></kiene-tierlistenelement>
            <div class="one-row" style="margin-bottom: 24px;">
                <span class="spacer"></span>
                <button (click)="$event.stopPropagation(); select(tierliste)" color="accent" mat-flat-button>
                    <mat-icon>check</mat-icon>&nbsp;ausw&auml;hlen
                </button>
            </div>
        </li>
    </ul>
</div>
<div mat-dialog-actions>
    <button (click)="dialogRef.close()" mat-button>Schlie&szlig;en</button>
</div>