import { TableColumn } from './table-column';
import { Condition, TableOption } from './table-option';
import { HttpParams } from '@angular/common/http';
import { FilterItem } from '../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import { KeyValue } from '@angular/common';

export class TableDescriptor {
    headline = '';
    apiUrl: string;
    uniqueIdentifier: string;
    alternativeApiUrl: string;
    columns: TableColumn[] = [];
    permissionView: number;
    permissionCreate: number;
    permissionUpdate: number;
    permissionDelete: number;
    searchPlaceholder = 'Suchen';
    initialSortColumn: string;
    initialSortDirection = 'asc';
    nameOfIdField = 'id';
    defaultPageSize = 10;
    pageSizeOptions: number[] = [5, 10, 20, 50, 100];
    route = '';
    detailsRoute: string;
    options: TableOption[] = [];
    params: HttpParams;
    filterParams: HttpParams;
    showDownloads = false;
    filterItems: FilterItem[] = [];
    standardCreate = true; // route .../neu
    createAllowed = true;
    showDetails = true;
    showDetailsExpandable = true;
    expandable = false;
    expandableFieldName: string;
    expandableColumns: TableColumn[] = [];
    expandableOptions: TableOption[] = [];
    toolbarButtons: ToolbarButton[] = [];
    selectionToolbarButtons: SelectionToolbarButton[] = [];
    fileUploadApi: string;
    useFileDropEvent = false;
    showSearchBar = true;
    clearSearch = false;
    waitToLoad = false;
    disableConditions: Condition[] = [];
    showDetailsAsOption = false;
    public showDetailsAsOptionExpandable = false;
    draggable = false;
    openFirstRows = 0;
    headlineColumnName: string;
    headlineColumnTitle: string;
    loadOnlyOnReload = false;
    selectable = false;
    expandableRowClicked: ExpandableRowClickedOptions;
    showRefresh = true;
    sendCurrentClient = false;
    tooltips: string[] = [];

    totalRow = false;
    totalRowApiUrl: string;

    addToolbarButton(tb: ToolbarButton) {
        this.toolbarButtons.push(tb);
    }

    getColumnsForDownload(): string {
        let colums = '';
        for (const c of this.columns) {
            colums += c.name + ';';
        }
        return colums;
    }

}

export class ParamsChanged {

    params: HttpParams;
    uniqueIdentifier: string;

    constructor(uniqueIdentifier: string, params: HttpParams) {
        this.uniqueIdentifier = uniqueIdentifier;
        this.params = params;
    }

}

export class ExpandableRowClickedOptions {
    emit = false;
    navigate = true;
}

export class ToolbarButton {
    action: string;
    icon: string;
    permission: number;
    tooltip: string;

    constructor(
        action: string,
        icon: string,
        permission: number,
        tooltip?: string
    ) {
        this.action = action;
        this.icon = icon;
        this.permission = permission;
        if (tooltip) {
            this.tooltip = tooltip;
        }
    }
}

export class SelectionToolbarButton {
    action: string;
    icon: string;
    permission: number;
    disableOnEmptySelection: boolean;
    tooltip: string;
    conditions: Condition[];

    constructor(
        action: string,
        icon: string,
        permission: number,
        disableOnEmptySelection: boolean,
        tooltip?: string,
        conditions?: Condition[]
    ) {
        this.action = action;
        this.icon = icon;
        this.permission = permission;
        this.disableOnEmptySelection = disableOnEmptySelection;
        if (tooltip) {
            this.tooltip = tooltip;
        }
        if (conditions) {
            this.conditions = conditions;
        }
    }

    checkConditions(dataList: any[]) {
        if (!this.conditions || this.conditions.length === 0) {
            return true;
        } else {
            for (const c of this.conditions) {
                for (const data of dataList) {
                    if (!c.check(data)) {
                        return false;
                    }
                }
            }
            return true;
        }
    }
}

export class KieneKeyValue implements KeyValue<string, any> {
    key: string;
    value: any;

    constructor(key: string, value: any) {
        this.key = key;
        this.value = value;
    }
}


export class SpalteKonfiguration {
    spalte: string;
    titel: string;
    sichtbar: number;
    aktiv: boolean;
}

export class SpaltenKonfigurationSendObject {
    tabelle: string;
    spalten: SpalteKonfiguration[] = [];
}
