import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SignaturePanelComponent} from './signature-panel/signature-panel.component';
import {MatButtonModule} from '@angular/material/button';


@NgModule({
    declarations: [
        SignaturePanelComponent
    ],
    imports: [
        CommonModule,
        MatButtonModule
    ],
    exports: [
        SignaturePanelComponent
    ]
})
export class SignaturePanelModule {
}
