import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { PdfViewerModule as ng2PdfViewerModule } from "ng2-pdf-viewer";
import { DirectivesModule } from "../../directives/directives.module";
import { KieneCoreModule } from "../../kiene-core.module";
import { OhrmarkeModule } from "../../ohrmarke/ohrmarke.module";
import { GruppeTierCardModule } from "../gruppe-tier-card/gruppe-tier-card.module";
import { GruppeTierReklamationEditDialogComponent } from "../gruppe-tier/gruppe-tier-reklamation-neu-dialog/gruppe-tier-reklamation-edit-dialog.component";
import { KieneAutocompleteModule } from "../kiene-autocomplete/kiene-autocomplete.module";
import { PageLayoutModule } from "../page-layout/page-layout.module";
import { PdfViewerModule } from "../pdf-viewer/pdf-viewer.module";
import { StammdatenModule } from "../stammdaten/stammdaten.module";
import { GruppeTierAbgangslisteFilterComponent } from "./filter/gruppe-tier-abgangsliste-filter/gruppe-tier-abgangsliste-filter.component";
import { GruppeTierAbteilFilterComponent } from "./filter/gruppe-tier-abteil-filter/gruppe-tier-abteil-filter.component";
import { GruppeTierEinstalldatumFilterComponent } from "./filter/gruppe-tier-einstalldatum-filter/gruppe-tier-einstalldatum-filter.component";
import { GruppeTierFilterButtonComponent } from "./filter/gruppe-tier-filter-button/gruppe-tier-filter-button.component";
import { GruppeTierFilterDialogComponent } from "./filter/gruppe-tier-filter-dialog/gruppe-tier-filter-dialog.component";
import { GruppeTierFilterComponent } from "./filter/gruppe-tier-filter/gruppe-tier-filter.component";
import { GruppeTierGeschlechtFilterComponent } from "./filter/gruppe-tier-geschlecht-filter/gruppe-tier-geschlecht-filter.component";
import { GruppeTierRasseFilterComponent } from "./filter/gruppe-tier-rasse-filter/gruppe-tier-rasse-filter.component";
import { GruppeTierTierReklamationFilterComponent } from "./filter/gruppe-tier-tier-reklamation-filter/gruppe-tier-tier-reklamation-filter.component";
import { GruppeTierTranspondernummerFilterComponent } from "./filter/gruppe-tier-transpondernummer-filter/gruppe-tier-transpondernummer-filter.component";
import { GruppeTierViehhaendlerFilterComponent } from "./filter/gruppe-tier-viehhaendler-filter/gruppe-tier-viehhaendler-filter.component";
import { GruppeTierBearbeitenDialogComponent } from "./gruppe-tier-bearbeiten-dialog/gruppe-tier-bearbeiten-dialog.component";
import { GruppeTierBearbeitenComponent } from "./gruppe-tier-bearbeiten/gruppe-tier-bearbeiten.component";
import { GruppeTierBemerkungDialogComponent } from "./gruppe-tier-bemerkung-dialog/gruppe-tier-bemerkung-dialog.component";
import { GruppeTierBemerkungComponent } from "./gruppe-tier-bemerkung/gruppe-tier-bemerkung.component";
import { GruppeTierDetailsDialogComponent } from "./gruppe-tier-details-dialog/gruppe-tier-details-dialog.component";
import { GruppeTierDetailsComponent } from "./gruppe-tier-details/gruppe-tier-details.component";
import { GruppeTierViehhaendlerZuordnenDialogComponent } from "./gruppe-tier-details/gruppe-tier-viehhaendler-zuordnen-dialog/gruppe-tier-viehhaendler-zuordnen-dialog.component";
import { GruppeTierViehhaendlerZuordnenComponent } from "./gruppe-tier-details/gruppe-tier-viehhaendler-zuordnen/gruppe-tier-viehhaendler-zuordnen.component";
import { GruppeTierGewichtPreisDialogComponent } from "./gruppe-tier-gewicht-preis-dialog/gruppe-tier-gewicht-preis-dialog.component";
import { GruppeTierHilfeDialogComponent } from "./gruppe-tier-hilfe-dialog/gruppe-tier-hilfe-dialog.component";
import { GruppeTierListComponent } from "./gruppe-tier-list/gruppe-tier-list.component";
import { GruppeTierPassScanVerwaltungDialogComponent } from "./gruppe-tier-pass-scan-verwaltung-dialog/gruppe-tier-pass-scan-verwaltung-dialog.component";
import { FehlerhafteSeitenDialogComponent } from "./gruppe-tier-pass-scan-verwaltung/fehlerhafte-seiten-dialog/fehlerhafte-seiten-dialog.component";
import { GruppeTierPassScanVerwaltungComponent } from "./gruppe-tier-pass-scan-verwaltung/gruppe-tier-pass-scan-verwaltung.component";
import { GruppeTierScanImportierenDialogComponent } from "./gruppe-tier-pass-scan-verwaltung/gruppe-tier-scan-importieren-dialog/gruppe-tier-scan-importieren-dialog.component";
import { GruppeTierReklamierenDialogComponent } from "./gruppe-tier-reklamieren-dialog/gruppe-tier-reklamieren-dialog.component";
import { GruppeTierReklamierenComponent } from "./gruppe-tier-reklamieren/gruppe-tier-reklamieren.component";
import { GruppeTierRoutingModule } from "./gruppe-tier-routing.module";
import { GruppeTierSortierungButtonComponent } from "./gruppe-tier-sortierung-button/gruppe-tier-sortierung-button.component";
import { GruppeTierTodMeldenDialogComponent } from "./gruppe-tier-tod-melden-dialog/gruppe-tier-tod-melden-dialog.component";
import { GruppeTierTodMeldenComponent } from "./gruppe-tier-tod-melden/gruppe-tier-tod-melden.component";

@NgModule({
    declarations: [
        GruppeTierAbgangslisteFilterComponent,
        GruppeTierAbteilFilterComponent,
        GruppeTierFilterButtonComponent,
        GruppeTierFilterDialogComponent,
        GruppeTierGeschlechtFilterComponent,
        GruppeTierRasseFilterComponent,
        GruppeTierTierReklamationFilterComponent,
        GruppeTierListComponent,
        GruppeTierTodMeldenDialogComponent,
        GruppeTierTodMeldenComponent,
        GruppeTierReklamierenDialogComponent,
        GruppeTierReklamierenComponent,
        GruppeTierReklamationEditDialogComponent,
        GruppeTierDetailsDialogComponent,
        GruppeTierDetailsComponent,
        GruppeTierDetailsDialogComponent,
        GruppeTierViehhaendlerZuordnenDialogComponent,
        GruppeTierViehhaendlerZuordnenComponent,
        GruppeTierBearbeitenDialogComponent,
        GruppeTierBearbeitenComponent,
        GruppeTierBearbeitenDialogComponent,
        GruppeTierHilfeDialogComponent,
        GruppeTierTranspondernummerFilterComponent,
        GruppeTierSortierungButtonComponent,
        GruppeTierFilterComponent,
        GruppeTierBemerkungDialogComponent,
        GruppeTierBemerkungComponent,
        GruppeTierPassScanVerwaltungDialogComponent,
        GruppeTierPassScanVerwaltungComponent,
        GruppeTierScanImportierenDialogComponent,
        GruppeTierViehhaendlerFilterComponent,
        GruppeTierEinstalldatumFilterComponent,
        GruppeTierGewichtPreisDialogComponent,
        FehlerhafteSeitenDialogComponent,
    ],
    imports: [
        CommonModule,
        GruppeTierRoutingModule,
        MatBadgeModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatListModule,
        GruppeTierCardModule,
        MatMenuModule,
        MatInputModule,
        KieneAutocompleteModule,
        PageLayoutModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        OhrmarkeModule,
        MatCardModule,
        KieneCoreModule,
        StammdatenModule,
        FormsModule,
        PdfViewerModule,
        ng2PdfViewerModule,
        MatRadioModule,
        DirectivesModule,
        MatTooltipModule,
    ],
    exports: [
        GruppeTierAbgangslisteFilterComponent,
        GruppeTierAbteilFilterComponent,
        GruppeTierFilterButtonComponent,
        GruppeTierFilterDialogComponent,
        GruppeTierGeschlechtFilterComponent,
        GruppeTierRasseFilterComponent,
        GruppeTierTierReklamationFilterComponent,
        GruppeTierListComponent,
        GruppeTierTodMeldenDialogComponent,
        GruppeTierTodMeldenComponent,
        GruppeTierReklamierenDialogComponent,
        GruppeTierReklamierenComponent,
        GruppeTierReklamationEditDialogComponent,
        GruppeTierDetailsDialogComponent,
        GruppeTierDetailsComponent,
        GruppeTierDetailsDialogComponent,
        GruppeTierViehhaendlerZuordnenDialogComponent,
        GruppeTierViehhaendlerZuordnenComponent,
        GruppeTierBearbeitenDialogComponent,
        GruppeTierBearbeitenComponent,
        GruppeTierBearbeitenDialogComponent,
        GruppeTierHilfeDialogComponent,
        GruppeTierTranspondernummerFilterComponent,
        GruppeTierSortierungButtonComponent,
        GruppeTierViehhaendlerFilterComponent,
        GruppeTierGewichtPreisDialogComponent,
    ],
})
export class GruppeTierModule {}
