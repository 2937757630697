<div style="padding: 0 24px 64px 24px">

    <div class="one-row" style="margin: 12px 0;">
        <kiene-page-header [showReturnButton]="showReturnButton" [ueberschrift]="'Meine Betriebe'"
                           [routeRelativeAlternative]="'../../'"></kiene-page-header>
        <span class="spacer"></span>
        <button (click)="vvvoAnlegen()" color="accent" mat-icon-button>
            <mat-icon>add</mat-icon>
        </button>

    </div>

    <ng-container *ngFor="let c of vvvos">
        <div class="one-row">
            <h3>{{ c.bezeichnung }} {{ c.zusatz ? ('- ' + c.zusatz) : '' }}</h3>
            <button mat-icon-button color="accent" [matMenuTriggerFor]="haendlerMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #haendlerMenu>
                <button mat-menu-item (click)="editiereVvvo(c)">
                    <mat-icon>edit</mat-icon>
                    <span>bearbeiten</span>
                </button>
                <button mat-menu-item (click)="vvvoEntfernen(c)">
                    <mat-icon>delete</mat-icon>
                    <span>l&ouml;schen</span>
                </button>
            </mat-menu>
        </div>

        <div style="margin-bottom: 48px;">
            <kiene-key-value-list>
                <kiene-key-value-list-item key="VVVO" [value]="c.vvvo"></kiene-key-value-list-item>
                <kiene-key-value-list-item key="Stra&szlig;e" [value]="c.strasse"></kiene-key-value-list-item>
                <kiene-key-value-list-item key="PLZ Ort" [value]="c.plz + ' ' + c.ort"></kiene-key-value-list-item>
                <kiene-key-value-list-item key="E-Mail" [value]="c.email"
                                           [actionButtonIcon]="c.email ? 'mail' : undefined"
                                           (actionClicked)="sendMail(c)"></kiene-key-value-list-item>
                <ng-container *ngIf="c.abweichende_lieferadresse>0">
                    <kiene-key-value-list-item key="Straße Lieferanschrift"
                                               [value]="c.strasse_lieferung"></kiene-key-value-list-item>
                    <kiene-key-value-list-item key="PLZ Ort Lieferanschrift"
                                               [value]="(c.plz_lieferung || '---') + ' ' + (c.ort_lieferung || '---')"></kiene-key-value-list-item>
                </ng-container>
            </kiene-key-value-list>
        </div>
    </ng-container>

</div>
