<h2 mat-dialog-title>Chargen</h2>
<form (ngSubmit)="save()">
<mat-dialog-content>
  <p>Artikel: <span style="font-weight: bold;">{{gibArtikelname()}}</span></p>
  <p>Sollmenge: <span style="font-weight: bold;">{{gibArtikelmenge()}}</span></p>
  <div class="charge-container">
    <div *ngIf="isLoading" class="kiene-loading-spinner">
      <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>
   
    <table class="charge-table">
      <thead>
        <tr>
          <th>Charge</th>
          <th>MHD</th>
          <th>Vorh. Menge</th>
          <th>Ist Menge</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let c of chargen; let i =index">
          <td>{{c.charge}}</td>
          <td>{{c.mhd}}</td>
          <td>{{c.menge_auto | number: '1.0-4'}}</td> <!-- + löscht die Nachkommastellen -->
          <td>
            <input class="menge-input" matInput #istMengeInput type="number" [formControl]="istMengeCtrls[i]">
            <!-- <mat-form-field>
              <mat-label>Ist-Menge</mat-label>
              <input matInput #istMengeInput type="number" [formControl]="istMengeCtrls[i]">
            </mat-form-field> -->
          </td>
        </tr>
      </tbody>
    </table>
  
  </div>
  <mat-divider></mat-divider>
  <h5>Neue Charge in Tierarztwagen anlegen</h5>
  <mat-form-field style="margin-right: 12px;">
    <mat-label>Charge</mat-label>
    <input matInput type="text" [formControl]="chargeCtrl">
  </mat-form-field>
  <mat-form-field style="margin-right: 12px; width: 100px;">
    <mat-label>Menge</mat-label>
    <input matInput type="number" [formControl]="mengeCtrl">
  </mat-form-field>
  <mat-form-field style="margin-right: 12px; width: 150px;">
    <mat-label>MHD</mat-label>
    <input matInput [matDatepicker]="mhdPicker" [formControl]="mhdCtrl">
    <mat-datepicker-toggle matSuffix [for]="mhdPicker"></mat-datepicker-toggle>
    <mat-datepicker #mhdPicker></mat-datepicker>
  </mat-form-field>
  <button type="button" mat-flat-button color="accent" (click)="createCharge()">Anlegen</button>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions>
  <button mat-button type="button" (click)="cancel()">Abbrechen</button>
  <button mat-flat-button type="submit" color="accent">Speichern</button>
</mat-dialog-actions>
</form>