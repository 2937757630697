import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild,} from '@angular/core';
import {TierartSelectorService} from './tierart-selector.service';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {KieneTableService} from '../kiene-table/kiene-table.service';
import {HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {MessageService} from '../../services/message.service';
import {ArtikelTierart, ArtikelTierartEvent} from './artikel-tierart';
import {UntypedFormControl, Validators} from '@angular/forms';

@Component({
    selector: 'kiene-tierart-selector',
    templateUrl: './tierart-selector.component.html',
    styleUrls: ['./tierart-selector.component.scss'],
})
export class TierartSelectorComponent implements OnInit, OnChanges {
    @ViewChild('select') select: MatSelect;

    tierarten: ArtikelTierart[] = [];
    tierartCtrl = new UntypedFormControl();

    @Input('zeigeKeine') zeigeKeine = true;

    @Input('materialId') materialId: number;

    @Input('kundeId') kundeId: number;
    @Input('kundeMandantId') kundeMandantId: number;

    @Input('disabled') disabled = false;

    @Input('required') required = false;

    @Input('multiple') multiple = false;
    @Input('openPanelOnFocus') openPanelOnFocus = true;

    @Output('tierartSelectionChanged')
    tierartSelectionChanged = new EventEmitter<ArtikelTierartEvent>();

    constructor(
        private _service: TierartSelectorService,
        private api: KieneTableService,
        private messageService: MessageService
    ) {
    }

    ngOnInit() {
        if (this.required) {
            this.tierartCtrl.setValidators([Validators.required]);
        }
    }

    public isValid(): boolean {
        if (!this.required) {
            return true;
        } else {
            return this.tierartCtrl.valid;
        }
    }

    ngOnChanges() {
        // this.init();
    }

    tierartComparator(t1: ArtikelTierart, t2: ArtikelTierart): boolean {
        if (t1 && t2) {
            if (t1.tierart_id === t2.tierart_id) {
                if (t1.artikel_id && t2.artikel_id) {
                    if (t1.artikel_id === t2.artikel_id) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            }
        }
        return false;
    }

    ladeTierartenFuerMaterialUndKunde(
        sender: string,
        artikelId: number,
        emit: boolean,
        ladePosition: boolean
    ) {
        this.materialId = artikelId;

        let params = new HttpParams();
        let url = 'artikel_tierart/read.php';
        if (this.materialId) {
            params = params.set('artikel_id', this.materialId.toString());

            if (this.kundeId) {
                params = params.set('kunde_id', this.kundeId.toString());
                url = 'artikel_tierart/read_kunde.php';
            }

            let headers;
            if (this.kundeMandantId !== undefined) {
                headers = {'MANDANTID-KUNDE': this.kundeMandantId.toString()};
            }

            this.api.get(environment.BASE_URI + url, params, headers).subscribe(
                (response) => {
                    this.tierarten = response.records;
                    if (this.tierarten.length === 1) {
                        this.tierartCtrl.setValue(this.tierarten[0]);
                        if (ladePosition) {
                            this.tierartSelectionChanged.emit(
                                new ArtikelTierartEvent(this.tierarten[0], {
                                    ladePosition: true,
                                })
                            );
                        } else if (emit) {
                            this.tierartSelectionChanged.emit(
                                new ArtikelTierartEvent(this.tierarten[0])
                            );
                        }
                    } else {
                        if (emit && !ladePosition) {
                            this.setFocus();
                        }
                        // if (this.tierarten?.length === 0) {
                        //     this.messageService.alertMessage(
                        //         'Zu diesem Artikel ist keine Tierart hinterlegt.'
                        //     );
                        // }
                    }
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Tierarten konnten nicht geladen werden!',
                        error
                    );
                }
            );
        }

        // if (this.materialId != null) {
        //   this._service.getTierartenForMaterial(this.materialId).subscribe(response => {
        //     this.tierarten = response.records;
        //   });
        // }
        // else{
        //   this._service.getTierarten().subscribe(response => {
        //     this.tierarten = response.records;
        //   });
        // }
    }

    tierartChanged(event: MatSelectChange) {
        console.debug('Tierart Changed: ' + event.value);
        this.tierartSelectionChanged.emit(new ArtikelTierartEvent(event.value));
    }

    public clear() {
        this.tierarten = [];
        this.tierartCtrl.setValue(null);
        this.select.close();
        this.tierartCtrl.markAsUntouched();
        this.tierartSelectionChanged.emit(new ArtikelTierartEvent(null));
    }

    public setFocus() {
        console.debug(
            'TierartSelector setFocus() at %s',
            new Date().toISOString()
        );
        setTimeout(() => {
            this.select?.focus();
            this.openPanel();
        }, 50);
    }

    public openPanel() {
        if (this.openPanelOnFocus) {
            this.select.open();
        }
    }

    public setTierart(
        artikelTierart: ArtikelTierart,
        emit: boolean,
        ladePosition: boolean
    ) {
        this.materialId = artikelTierart.artikel_id;
        this.tierartCtrl.setValue(artikelTierart, {emitEvent: emit});
        this.ladeTierartenFuerMaterialUndKunde(
            'TierartSelectorComponent.setTierart',
            artikelTierart.artikel_id,
            emit,
            ladePosition
        );
    }

    public getTierart(): ArtikelTierart {
        return this.tierartCtrl?.value;
    }
}

export class Tierart {
    artikel_id: number;
    artikel: string;
    tierart_id: number;
    tierart: string;
}

export interface TierartenApi {
    records: Tierart[];
    count: number;
}

export class TierartSelectionChangeEvent {
    selectedTierartId: number;
    tierart: string;
}
