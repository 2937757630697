import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {TierRasse} from "../../../../../projects/kiene-core/src/lib/components/gruppe-tier/classes/tier-rasse";
import {API_BASE_URL_SERVICE} from "../../../../../projects/kiene-core/src/lib/kiene-core.config";
import {KieneTableService} from "../../../components/kiene-table/kiene-table.service";
import {NumberTableColumn, TableColumn} from "../../../components/kiene-table/table-column";
import {TableDescriptor} from "../../../components/kiene-table/table-descriptor";
import {TableOption, TableOptionEvent} from "../../../components/kiene-table/table-option";
import {TierartenRassenComponent} from "../../tierarten-rassen/tierarten-rassen.component";
import {RasseDialogComponent} from "../rasse-dialog/rasse-dialog.component";
import {SonstigeRassenDialogComponent} from "./sonstige-rassen-dialog/sonstige-rassen-dialog.component";

@Component( {
    selector: 'kiene-sonstige-rassen',
    templateUrl: './sonstige-rassen.component.html',
    styleUrls: ['./sonstige-rassen.component.scss']
} )
export class SonstigeRassenComponent implements OnInit {
    tableDescriptor = new TableDescriptor();

    constructor(public dialog: MatDialog,
                private api: KieneTableService,
                @Inject( API_BASE_URL_SERVICE ) private apiBaseUrl: string) {
    }

    ngOnInit(): void {
        this.tableDescriptor.headline = 'sonstige Tierrassen';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'tierrasse/';
        this.tableDescriptor.uniqueIdentifier = 'sonstige_tierrassen';
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.initialSortColumn = 'name';
        this.tableDescriptor.initialSortDirection = 'asc';

        const columns: TableColumn[] = [];

        columns.push( new TableColumn( 'tierart', 'Tierart' ) );
        columns.push( new TableColumn( 'name', 'Rasse' ) );
        this.tableDescriptor.columns = columns;

        this.tableDescriptor.permissionView = 2071;
        this.tableDescriptor.permissionCreate = 2072;
        this.tableDescriptor.permissionUpdate = 2074;

        this.tableDescriptor.showDetails = true;

        this.tableDescriptor.options.push( new TableOption( 'edit', 'Bearbeiten', 'edit', 2062 ) );
    }

    optionClicked(event: TableOptionEvent) {
        console.debug( 'Table Option: ' + event.name );
        if ( event.name === 'add' ) {
            this.openRasseDialog( null );
        } else if ( event.name === 'edit' ) {
            this.openRasseDialog( event.value );
        }
    }

    openRasseDialog(rasse: TierRasse) {
        console.log( rasse )
        const dialogRef = this.dialog.open( SonstigeRassenDialogComponent, {
            width: '500px',
            data: {
                rasse: rasse
            }
        } );

        dialogRef.afterClosed().subscribe( response => {
            console.log( response )
            this.api.reload();
        } );
    }
}
