import {HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpResponse,} from '@angular/common/http';
import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';
import {Vet} from '../../../administration/vet/vet';
import {MessageService} from '../../../services/message.service';
import {Material} from '../../../warehouse/material/material';
import {Supplier} from '../../../warehouse/supplier/supplier';
import {Gemeinkosten, GemeinkostenPreise} from '../gemeinkosten';

@Component({
    selector: 'kiene-gemeinkosten-details-dialog',
    templateUrl: './gemeinkosten-details-dialog.component.html',
    styleUrls: ['./gemeinkosten-details-dialog.component.css'],
})
export class GemeinkostenDetailsDialogComponent implements OnInit {
    @ViewChild('fileSelector') fileSelector;
    filesToUpload: File[] = [];
    filesProgressValues: number[] = [];
    filesUploadStatus: number[] = [];

    gemeinkosten = new Gemeinkosten();

    material: Material;
    supplier: Supplier;

    artikelParams: HttpParams;
    lieferantParams = new HttpParams().set('nebenkosten', '1');

    gemeinkostenTypCtrl = new FormControl();
    datumCtrl = new FormControl();
    kommentarCtrl = new FormControl('');
    rechnungNummerCtrl = new FormControl('');
    leistungsdatumVonCtrl = new FormControl();
    leistungsdatumBisCtrl = new FormControl();

    tierarzt: Vet;
    nurHonorar = false;

    constructor(
        public dialogRef: MatDialogRef<GemeinkostenDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private http: HttpClient,
        private messageService: MessageService
    ) {
        if (data?.gemeinkosten) {
            this.gemeinkosten = data.gemeinkosten;
        }
        if (data?.nurHonorar) {
            this.nurHonorar = this.data.nurHonorar;
            this.artikelParams = new HttpParams().set('honorar', '1');
        } else {
            this.artikelParams = new HttpParams().set('nebenkosten', '1');
            this.artikelParams = this.artikelParams.set('honorar', '0');
        }
    }

    setDates() {
        if (!this.leistungsdatumVonCtrl.value) {
            this.leistungsdatumVonCtrl.setValue(this.datumCtrl.value);
        }
        if (!this.leistungsdatumBisCtrl.value) {
            this.leistungsdatumBisCtrl.setValue(this.datumCtrl.value);
        }
    }

    ngOnInit(): void {
        if (this.gemeinkosten.kosten_id) {
            this.material = <Material>{
                artikel_id: this.gemeinkosten.artikel_id,
                artikel_me: this.gemeinkosten.artikel_me,
            };
            this.gemeinkostenTypCtrl.setValue(
                this.gemeinkosten.tierarzt_id
                    ? 2
                    : this.gemeinkosten.allgemein
                        ? 0
                        : 1
            );
            this.kommentarCtrl.setValue(this.gemeinkosten.kommentar);
            this.rechnungNummerCtrl.setValue(this.gemeinkosten.rechnung_nr);
            this.datumCtrl.setValue(this.gemeinkosten.datum);
            this.leistungsdatumVonCtrl.setValue(
                this.gemeinkosten.leistungsdatum_von,
                {emitEvent: false}
            );
            this.leistungsdatumBisCtrl.setValue(
                this.gemeinkosten.leistungsdatum_bis
            );
            this.tierarzt = this.createTierarztObject(this.gemeinkosten);
            if (this.gemeinkosten.lieferant_id) {
                this.supplier = <Supplier>{
                    lieferant_id: this.gemeinkosten.lieferant_id,
                    bezeichnung: this.gemeinkosten.lieferant,
                };
            }
        }
        if (this.gemeinkosten.preise.length === 0) {
            this.gemeinkosten.preise.push(new GemeinkostenPreise());
        }
    }

    createTierarztObject(g: Gemeinkosten) {
        let vet = new Vet();
        vet.tierarzt_id = g.tierarzt_id;
        vet.nachname = g.tierarzt;
        vet.vorname = '';
        vet.titel = '';
        return vet;
    }

    setMaterial(m: Material) {
        this.material = m;
        // this.checkGemeinkostenTyp();
    }

    setLieferant(s: Supplier) {
        this.supplier = s;
    }

    setTierarzt(v: Vet) {
        this.tierarzt = v;
    }

    checkGemeinkostenTyp() {
        if (this.material) {
            if (this.material.warengruppe_labor) {
                this.gemeinkostenTypCtrl.setValue(1);
            } else if (this.material.warengruppe_tierarzt) {
                this.gemeinkostenTypCtrl.setValue(2);
            } else {
                this.gemeinkostenTypCtrl.setValue(0);
            }
        }
    }

    close() {
        this.dialogRef.close();
    }

    handleFiles(event: any) {
        const files = event.target.files;
        this.filesToUpload.push(files.item(0));
        this.filesProgressValues[0] = 0;
    }

    isFileUploaded(file: number) {
        if (this.filesUploadStatus[file] == 1) {
            return true;
        } else {
            return false;
        }
    }

    uploadFilesToServer() {
        let fd = new FormData();

        let fileName = '';

        fd.set('labor', null);
        fd.set('allgemein', null);
        fd.set('tierarzt_id', null);

        if (this.gemeinkosten && this.gemeinkosten.kosten_id) {
            fd.append('kosten_id', this.gemeinkosten.kosten_id.toString());
        }

        if (this.supplier) {
            fd.append('lieferant_id', this.supplier?.lieferant_id?.toString());
        }

        if (this.tierarzt && this.gemeinkostenTypCtrl.value === 2) {
            fd.set('tierarzt_id', this.tierarzt.tierarzt_id.toString());
        }
        if (this.gemeinkostenTypCtrl.value === 0) {
            fd.set('allgemein', '1');
        }

        if (this.gemeinkostenTypCtrl.value === 1) {
            fd.set('labor', '1');
        }

        if (this.material) {
            fd.append('artikel_id', this.material.artikel_id.toString());
        }

        fd.append('kommentar', this.kommentarCtrl.value);
        fd.append('rechnung_nr', this.rechnungNummerCtrl.value);
        fd.append('preise', JSON.stringify(this.gemeinkosten.preise));
        fd.append('freigegeben', this.gemeinkosten?.freigegeben?.toString());

        if (this.datumCtrl.value) {
            const d: Date = new Date(this.datumCtrl.value);
            fd.append('datum', d.toISOString());
        }

        if (this.leistungsdatumVonCtrl.value) {
            const lv: Date = new Date(this.leistungsdatumVonCtrl.value);
            fd.append('leistungsdatum_von', lv.toISOString());
        }

        if (this.leistungsdatumBisCtrl.value) {
            const lb = new Date(this.leistungsdatumBisCtrl.value);
            fd.append('leistungsdatum_bis', lb.toISOString());
        }

        for (var i = 0; i < this.filesToUpload.length; i++) {
            let fileNumber = i;
            let file = this.filesToUpload[fileNumber];

            fd.append('dokument', file);
            fileName = file.name;
        }
        let params = new HttpParams();

        const options = {
            headers: new HttpHeaders('Phito-Type: fileupload'),
            params: params,
            reportProgress: true,
        };

        // const request = new HttpRequest('POST', environment.BASE_URI + this.apiUrl, fd, options);
        this.http
            .post<any>(environment.BASE_URI + 'kosten/update.php', fd, options)
            .subscribe(
                (event) => {
                    if (event.type == HttpEventType.UploadProgress) {
                        let percentDone = Math.round(
                            (100 * event.loaded) / event.total
                        );
                        this.filesProgressValues[0] = percentDone;
                        console.debug(
                            'Uploaded ' + percentDone + '% of file ' + fileName
                        );
                    } else if (event instanceof HttpResponse) {
                        console.debug(
                            'Uploaded file ' + fileName + ' completely!'
                        );
                    }
                },
                (error) => {
                    console.error(
                        'Fehler beim Hochladen der Datei: ' + fileName
                    );
                    this.filesUploadStatus[0] = 2;
                    this.messageService.errorMessage('Fehler', error);
                    this.markFileAsFailed(0);
                },
                () => {
                    if (this.filesToUpload.length > 0) {
                        this.markFileAsUploaded(0);
                        console.debug('Upload of file ' + fileName + ' done!');
                        this.messageService.infoMessage(
                            fileName + ' erfolgreich hochgeladen!'
                        );
                    }
                    this.close();
                }
            );
    }

    markFileAsFailed(no: number) {
        this.filesUploadStatus[no] = 2;
        this.checkShowCloseButton();
    }

    markFileAsUploaded(no: number) {
        this.filesUploadStatus[no] = 1;
        this.checkShowCloseButton();
    }

    checkShowCloseButton() {
        var show: boolean = true;
        for (var i = 0; i < this.filesUploadStatus.length; i++) {
            if (this.filesUploadStatus[i] == 0) {
                show = false;
            }
        }
        if (show == true) {
            // this.showCloseButton = true;
        }
    }

    save() {
        this.uploadFilesToServer();
        // if(!this.gemeinkosten){
        //   this.gemeinkosten = new Gemeinkosten();
        // }

        // this.gemeinkosten.artikel_id = this.material.artikel_id;
        // this.gemeinkosten.kosten_netto = this.preisCtrl.value;
        // this.gemeinkosten.mwstsatz = this.mwstCtrl.value;
        // this.gemeinkosten.datum = this.datumCtrl.value;
        // this.gemeinkosten.kommentar = this.kommentarCtrl.value;
        // if(this.tierarzt && this.gemeinkostenTypCtrl.value === 2){
        //   this.gemeinkosten.tierarzt_id = this.tierarzt.tierarzt_id;
        //   this.gemeinkosten.allgemein = null;
        //   this.gemeinkosten.labor = null;
        // }
        // if(this.gemeinkostenTypCtrl.value === 0){
        //   this.gemeinkosten.labor = null;
        //   this.gemeinkosten.allgemein = 1;
        //   this.gemeinkosten.tierarzt_id = null;
        // }

        // if(this.gemeinkostenTypCtrl.value === 1){
        //   this.gemeinkosten.labor = 1;
        //   this.gemeinkosten.allgemein = null;
        //   this.gemeinkosten.tierarzt_id = null;
        // }

        // this.api.update(environment.BASE_URI + 'kosten/', this.gemeinkosten).subscribe(response => {
        //   this.messageService.infoMessage('Gemeinkosten wurden erfolgreich gespeichert!');
        //   this.close();
        // }, error => {
        //   this.messageService.errorMessage('Die Gemeinkosten konnten nicht gespeichert werden!', error);
        // });
    }

    openFileSelector() {
        this.fileSelector.nativeElement.click();
    }

    isValid() {
        if (
            this.gemeinkostenTypCtrl.value === null ||
            this.gemeinkostenTypCtrl.value === undefined
        ) {
            return false;
        }
        //tierarzt
        if (this.gemeinkostenTypCtrl.value === 2) {
            if (!this.tierarzt) {
                return false;
            }
        }

        if (!this.nurHonorar) {
            if (!this.supplier) {
                return false;
            }
        }

        if (!this.material) {
            return false;
        }

        if (!this.datumCtrl.value) {
            return false;
        }
        if (!this.rechnungNummerCtrl.value) {
            return false;
        }
        if (!this.leistungsdatumVonCtrl.value) {
            return false;
        }
        if (this.gemeinkosten.preise.length === 0) {
            return false;
        } else {
            for (const p of this.gemeinkosten.preise) {
                if (
                    p.kosten_netto === null ||
                    p.kosten_netto === undefined ||
                    p.mwstsatz === null ||
                    p.mwstsatz === undefined
                ) {
                    return false;
                }
            }
        }
        return true;
    }

    neuenPreisHinzufuegen() {
        if (this.gemeinkosten) {
            this.gemeinkosten.preise.push(new GemeinkostenPreise());
        }
    }

    preisAusListeEntfernen(i: number) {
        this.gemeinkosten.preise.splice(i, 1);
    }
}
