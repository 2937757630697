import {Component, Inject, OnInit} from '@angular/core';
import {TableDescriptor} from '../../../components/kiene-table/table-descriptor';
import {MatDialog} from '@angular/material/dialog';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {
    ConditionSetting,
    ConditionTableColumn,
    DateTableColumn,
    EmailGesendetTableColumn,
    GedrucktTableColumn,
    KieneBooleanTableColumn,
    TableColumn,
} from '../../../components/kiene-table/table-column';
import {Condition, Operator, TableOption, TableOptionEvent} from '../../../components/kiene-table/table-option';
import {HttpParams} from '@angular/common/http';
import {Bestandsbuch} from './classes/bestandsbuch';
import {BestandsbuchArtikel} from './classes/bestandsbuch-artikel';
import {BestandsbuchArtikelDialogComponent} from '../bestandsbuch-artikel-dialog/bestandsbuch-artikel-dialog.component';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {
    BestandsbuchArtikelUebersichtComponent
} from '../../../../../projects/kiene-core/src/lib/bestandsbuecher/bestandsbuch-artikel-uebersicht/bestandsbuch-artikel-uebersicht.component';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {MessageService} from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import {FormControl} from '@angular/forms';
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {BestandsbuchAngewandt} from '../../../../../projects/kiene-core/src/lib/bestandsbuecher/classes/bestandsbuch';

@Component({
    selector: 'kiene-bestandsbuch',
    templateUrl: './bestandsbuch.component.html',
    styleUrls: ['./bestandsbuch.component.scss'],
})
export class BestandsbuchComponent implements OnInit {
    // TODO
    permission = 4421;
    tableDescriptor: TableDescriptor = new TableDescriptor();

    currentClient: Client;

    bestandsbuecher: Bestandsbuch[] = [];
    bestandsbuchArtikel: BestandsbuchArtikel[] = [];

    typCtrl = new FormControl(0);
    offeneImpfkontrollbuecher = 0;

    constructor(
        public dialog: MatDialog,
        private pdfService: PdfViewerDesktopService,
        public localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
        this.currentClient = this.localStorageService.getCurrentClient();
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Bestands- und Impfkontrollbücher';
        this.tableDescriptor.uniqueIdentifier = 'Bestands- und Impfkontrollbücher';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'bestandsbuch/';
        this.tableDescriptor.route = '/belege/bestandsbuecher/';
        this.tableDescriptor.nameOfIdField = 'bestandsbuch_id';
        this.tableDescriptor.searchPlaceholder = 'Bestandsbücher durchsuchen';

        this.tableDescriptor.headlineColumnTitle = 'ID';
        this.tableDescriptor.headlineColumnName = 'bestandsbuch_id';
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.showDetailsAsOption = true;
        this.tableDescriptor.showDetailsAsOptionExpandable = true;

        this.tableDescriptor.columns.push(
            new TableColumn('bestandsbuch_id', 'ID')
        );

        const typSettings: ConditionSetting[] = [];
        typSettings.push(new ConditionSetting([new Condition('typ', Operator.GLEICH, 1)], {
            color: 'black', icon: 'menu_book', tooltipFieldnames: ['typ_name']
        }));
        typSettings.push(new ConditionSetting([new Condition('typ', Operator.GLEICH, 2)], {
            color: 'black', icon: 'vaccines', tooltipFieldnames: ['typ_name']
        }));
        this.tableDescriptor.columns.push(new ConditionTableColumn('typ_name', 'Typ', typSettings));
        this.tableDescriptor.columns.push(
            new TableColumn('abgabebeleg_nr', 'Abgabebeleg')
        );

        this.tableDescriptor.columns.push(new TableColumn('kunde', 'Kunde'));
        this.tableDescriptor.columns.push(
            new TableColumn('zusatz', 'Kundenzusatz')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('tierarzt', 'Tierarzt')
        );
        this.tableDescriptor.columns.push(new TableColumn('status', 'Status'));
        this.tableDescriptor.columns.push(new KieneBooleanTableColumn('abschliessbar', 'Abschliessbar'));
        this.tableDescriptor.columns.push(
            new DateTableColumn('datum', 'Belegdatum', 'dd.MM.yy')
        );
        this.tableDescriptor.columns.push(new TableColumn('pdf_id', '', 'pdf'));
        this.tableDescriptor.columns.push(new EmailGesendetTableColumn());
        this.tableDescriptor.columns.push(new GedrucktTableColumn());

        this.tableDescriptor.params = new HttpParams();

        this.tableDescriptor.permissionCreate = 4422;
        this.tableDescriptor.permissionUpdate = 4422;
        this.tableDescriptor.permissionView = 4421;
        this.tableDescriptor.permissionDelete = 4424;

        /*this.tableDescriptor.options.push(
            new TableOption(
                'closeBestandsbuch',
                'Bestandsbuch abschließen',
                'description',
                4422
            )
        );*/
        this.tableDescriptor.options.push(
            new TableOption(
                'showAbgabebeleg',
                'Abgabebeleg anzeigen',
                'description',
                1238,
                {conditions: [new Condition('pdf_id_abgabebeleg', Operator.EXISTIERT, '')]}
            )
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'deleteBestandsbuch',
                'Bestandsbuch löschen',
                'delete',
                4424
            )
        );
        this.tableDescriptor.options.push(new TableOption(null, 'Drucken', 'print', 4428, {
            void: this.gedruckt.bind(this),
            conditions: [new Condition('pdf_id', Operator.EXISTIERT, null)]
        }));

        this.tableDescriptor.createAllowed = false;

        this.tableDescriptor.initialSortColumn = 'status_sortierung';
        this.tableDescriptor.initialSortDirection = 'asc';

        this.tableDescriptor.expandable = true;
        this.tableDescriptor.expandableFieldName = 'artikel';
        this.tableDescriptor.openFirstRows = 1;
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('artikel_me', 'Artikel')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('tierart', 'Tierart')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('anwendung', 'Anwendung')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('diagnose', 'Diagnose')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('artikelmenge', 'Menge')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('anzahl_tiere', 'Anz. Tiere')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('dosierung_txt', 'Dosierung')
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn(
                'anwendungsdauer',
                'Anwendungsdauer (Tage)',
                null,
                null,
                null,
                null,
                null,
                null,
                'Tage'
            )
        );
        this.tableDescriptor.expandableColumns.push(
            new TableColumn('tiergewicht', 'Tiergewicht (kg)')
        );

        this.tableDescriptor.expandableColumns.push(
            new TableColumn('wartezeit', 'Wartezeit Fleisch')
        );

        this.typCtrl.valueChanges.subscribe({
            next: typ => {
                if (typ === 2) {
                    this.ladeOffeneImpfkontrollbuecher();
                }
                this.reload();
            }
        });

        this.ladeOffeneImpfkontrollbuecher();
    }

    gedruckt(bb: Bestandsbuch) {
        this.api.get(this.apiBaseUrl + 'bestandsbuch/gedruckt.php', new HttpParams().set('bestandsbuch_id', bb.bestandsbuch_id)).subscribe({
            next: () => {
                this.tableService.reload('gedruckt');
                this.openPdf(bb);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    hasPermission(permissionId: number): boolean {
        return this.localStorageService.hasPermission(permissionId);
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'details_expandable') {
            const bestandsbuchArtikel = <BestandsbuchArtikel>event.value;
            this.openBestandsbuchDetailsDialog(bestandsbuchArtikel);
            /*} else if (event.name === 'closeBestandsbuch') {
            const bestandsbuch = <Bestandsbuch>event.value;
            this.closeBestandsbuch(bestandsbuch);*/
        } else if (event.name === 'details') {
            const bb = <Bestandsbuch>event.value;
            if (bb.status_id === 152) {
                this.openPdf(event.value);
            } else {
                this.openBestandsbuchArtikelUebersichtDialog(event.value);
            }
        } else if (event.name === 'showPdf') {
            this.openPdf(event.value);
        } else if (event.name === 'showAbgabebeleg') {
            const bestandsbuch = <Bestandsbuch>event.value;
            this.openAbgabebelegPdf(
                bestandsbuch?.abgabebeleg_id,
                bestandsbuch?.abgabebeleg_nr,
                bestandsbuch?.kunde_id
            );
        } else if (event.name === 'deleteBestandsbuch') {
            this.deleteBestandsbuch(<Bestandsbuch>event.value);
        }
    }

    deleteBestandsbuch(bb: Bestandsbuch) {
        const input = new ConfirmDialogInput();
        input.headline = 'Bestandsbuch löschen?';
        input.text = 'Soll das Bestandsbuch unwiderruflich gelöscht werden?';
        input.okButton = 'Löschen';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '50%',
            minWidth: '300px',
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.api
                        .deleteGeneric(this.apiBaseUrl + 'bestandsbuch/delete.php', bb)
                        .subscribe({
                            next: (response) => {
                                this.messageService.infoMessage(
                                    'Bestandsbuch wurde erfolgreich gelöscht!'
                                );
                                this.tableService.reload();
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Bestandsbuch konnte nicht gelöscht werden!',
                                    err
                                );
                            },
                        });
                }
            },
        });
    }

    openAbgabebelegPdf(
        abgabebeleg_id: number,
        abgabebeleg_nr: string,
        kunde_id: number
    ) {
        const input = new PdfViewerDialogInput(
            'abgabebeleg/read_or_mail_pdf.php',
            new HttpParams().set('abgabebeleg_id', abgabebeleg_id.toString()),
            'Abgabebeleg ' + abgabebeleg_nr,
            {
                email: {
                    sende_mail: true,
                    kunde_id: kunde_id,
                },
            }
        );
        this.pdfService.openPdfViewer(input);
    }

    openPdf(bb: Bestandsbuch) {
        const input = new PdfViewerDialogInput(
            'bestandsbuch/read_or_mail_pdf.php',
            new HttpParams().set(
                'bestandsbuch_id',
                bb?.bestandsbuch_id?.toString()
            ),
            'Bestandsbuch ' + bb?.abgabebeleg_nr,
            {
                email: {
                    sende_mail: true,
                    kunde_id: bb?.kunde_id,
                },
            }
        );
        this.pdfService.openPdfViewer(input).subscribe({
            next: () => {
                this.tableService.reload();
            }
        });
    }

    alleAbschliessen() {
        // Bestandsbuecher
        if (this.typCtrl?.value === 1) {
            let params = new HttpParams();
            if (this.localStorageService.getCurrentClient()) {
                params = params.set('kunde_id', this.localStorageService.getCurrentClient()?.kunde_id);
            }
            this.api.getAll(this.apiBaseUrl + 'bestandsbuch/alle_angewendet_bestandsbuecher.php', params).subscribe({
                next: response => {
                    if (response.count > 0) {
                        const nichtAbschliessbar = this.getNichtAbschliessbar(response.records);
                        if (nichtAbschliessbar > 0) {
                            const inp = new ConfirmDialogInput();
                            inp.headline = 'Trotzdem abschließen?';
                            inp.text = nichtAbschliessbar + ' Bestandsbuch(er) erfüllen nicht alle Voraussetzungen zum Abschließen. Sollen diese trotzdem abgeschlossen werden?';
                            inp.okButton = 'Trotzdem abschließen';
                            this.dialog.open(ConfirmDialogComponent, {data: inp}).afterClosed().subscribe({
                                next: result => {
                                    if (result?.ok && result?.confirmed) {
                                        this.alleBestandsbuecherAbschliessen(response.records, true);
                                    }
                                }
                            });
                        } else {
                            this.alleBestandsbuecherAbschliessen(response.records, false);
                        }

                    }
                }
            });
            // Impfkontrollbuecher
        } else if (this.typCtrl?.value === 2) {
            let params = new HttpParams();
            if (this.localStorageService.getCurrentClient()) {
                params = params.set('kunde_id', this.localStorageService.getCurrentClient()?.kunde_id);
            }
            this.api.getAll(this.apiBaseUrl + 'bestandsbuch/read_offene_impfkontrollbuecher.php', params).subscribe({
                next: response => {
                    if (response.count > 0) {
                        this.alleImpfkontrollbuecherAbschliessen(response.records, false);

                    }
                },
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        }

    }

    /*private closeBestandsbuch(bestandsbuch: Bestandsbuch) {
        this.dialog.open(CloseBestandsbuchDialogComponent, {
            minWidth: '50vw',
            data: {
                bestandsbuch: bestandsbuch,
            },
        });
    }*/

    ladeOffeneImpfkontrollbuecher() {
        let params = new HttpParams();
        if (this.localStorageService.getCurrentClient()) {
            params = params.set('kunde_id', this.localStorageService.getCurrentClient()?.kunde_id);
        }
        this.api.getAll(this.apiBaseUrl + 'bestandsbuch/read_offene_impfkontrollbuecher.php', params).subscribe({
            next: response => {
                this.offeneImpfkontrollbuecher = response.count;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private openBestandsbuchDetailsDialog(
        bestandsbuchArtikel: BestandsbuchArtikel
    ) {
        this.dialog.open(BestandsbuchArtikelDialogComponent, {
            minWidth: '50vw',
            data: {
                bestandsbuchArtikel: bestandsbuchArtikel,
            },
        });
    }

    private openBestandsbuchArtikelUebersichtDialog(
        bestandsbuch: Bestandsbuch
    ) {
        const dialogRef = this.dialog.open(
            BestandsbuchArtikelUebersichtComponent,
            {
                width: '400px',
                data: bestandsbuch,
            }
        );
        dialogRef.afterClosed().subscribe({
            next: () => {
                this.tableService.reload(
                    'openBestandsbuchArtikelUebersichtDialog: afterClosed'
                );
            },
        });
    }

    private reload() {
        if (this.typCtrl.value > 0) {
            this.tableDescriptor.params = this.tableDescriptor.params.set('typ', this.typCtrl.value);
        } else {
            this.tableDescriptor.params = this.tableDescriptor.params.delete('typ');
        }
        this.tableService.reload('bestandsbuch typ change');
    }

    private getNichtAbschliessbar(buecher: BestandsbuchAngewandt[]): number {
        let nichtAbschliessbar = 0;
        for (const b of buecher) {
            if (b.abschliessbar === 0) {
                nichtAbschliessbar++;
            }
        }
        return nichtAbschliessbar;
    }

    private alleBestandsbuecherAbschliessen(buecher: BestandsbuchAngewandt[], force: boolean) {
        const requestArray = [];
        this.api.post(this.apiBaseUrl + 'bestandsbuch/abschliessen_bestandsbuecher.php', {force: force ? 1 : 0}).subscribe({
            next: response => {
                this.messageService.infoMessage('Bestandsbücher wurden erfolgreich abgeschlossen!');
                this.tableService.reload();
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private alleImpfkontrollbuecherAbschliessen(buecher: BestandsbuchAngewandt[], force: boolean) {

        if (buecher?.length > 0) {
            const dialogRef = this.dialog.open(
                BestandsbuchArtikelUebersichtComponent,
                {
                    width: '400px',
                    data: buecher[0],
                }
            );
            dialogRef.afterClosed().subscribe({
                next: result => {
                    this.tableService.reload(
                        'openBestandsbuchArtikelUebersichtDialog: afterClosed'
                    );
                    if (result) {


                        let params = new HttpParams();
                        if (this.localStorageService.getCurrentClient()) {
                            params = params.set('kunde_id', this.localStorageService.getCurrentClient()?.kunde_id);
                        }
                        this.api.getAll(this.apiBaseUrl + 'bestandsbuch/read_offene_impfkontrollbuecher.php', params).subscribe({
                            next: response => {
                                if (response.count > 0) {
                                    this.alleImpfkontrollbuecherAbschliessen(response.records, false);

                                }
                            }
                        });
                    }
                },
            });


        }

    }
}
