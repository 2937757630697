<h1 mat-dialog-title>Tier {{ tier?.name ? 'editieren' : 'anlegen' }}</h1>

<div mat-dialog-content style="width: 100%; padding: 24px;">
    <div style="width: 100%">
        <!-- Name des Tiers -->
        <mat-form-field style="width: 100%; margin-bottom: 24px;">
            <mat-label>Name des Tiers</mat-label>
            <input matInput type="text" [formControl]="nameCtrl" style="width: 100%;">
        </mat-form-field>

        <kiene-autocomplete #tierartAutocomplete
                            *kienePermission="[2021, 2029]"
                            (elementSelected)="setTierart($event)"
                            apiUrl="tierart/"
                            [clearAfterSelect]="false"
                            [displayFields]="['tierart']"
                            [placeholder]="'Tierart'"
                            [focus]="false"
                            [kieneObject]="tierart"
                            [createNewEvent]="tierartAutocompleteCreateEvent"
                            (createNewEvent)="createTierart()"
                            style="width: 100%;">
        </kiene-autocomplete>

        <kiene-autocomplete
            #rasseAutocomplete
            *kienePermission="[2061]"
            (elementSelected)="setRasse($event)"
            apiUrl="tierrasse/"
            [clearAfterSelect]="true"
            [displayFields]="['name']"
            [placeholder]="'Rasse'"
            [focus]="false"
            [kieneObject]="rasse"
            [createNewEvent]="rasseAutocompleteCreateEvent"
            (createNewEvent)="createRasse()"
            [disabled]="!tierart"
            [params]="rasseParams"
            style="width: 100%;">
        </kiene-autocomplete>

        <!-- Geschlecht und Geburtsdatum -->
        <div class="one-row" style="display: flex; gap: 24px; margin-bottom: 24px;">
            <mat-form-field style="flex: 1;">
                <mat-label>Geschlecht</mat-label>
                <mat-select [formControl]="geschlechtCtrl">
                    <mat-option [value]="1">Männlich</mat-option>
                    <mat-option [value]="2">Weiblich</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style="flex: 1;">
                <mat-label>Geburtsdatum:</mat-label>
                <input matInput [matDatepicker]="picker" [formControl]="alterCtrl">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <!-- Gewicht und Fellfarbe -->
        <div class="one-row" style="display: flex; gap: 24px; margin-bottom: 24px;">
            <mat-form-field style="flex: 1;">
                <mat-label>Fellfarbe</mat-label>
                <input matInput type="text" [formControl]="fellfarbeCtrl" style="width: 100%;">
            </mat-form-field>
        </div>

        <!-- Besondere Merkmale -->
        <mat-form-field style="width: 100%; margin-bottom: 24px;">
            <mat-label>besondere Merkmale</mat-label>
            <input type="text" matInput [formControl]="identifizierungCtrl" rows="7">
        </mat-form-field>

        <!-- Chipnummer, EU-Tierpass, Zuchtbuchnummer -->
        <div class="one-row" style="display: flex; gap: 24px; margin-bottom: 24px;">
            <mat-form-field style="flex: 1;">
                <mat-label>Chipnummer</mat-label>
                <input matInput type="text" [formControl]="chipnummerCtrl" style="width: 100%;">
            </mat-form-field>
            <mat-form-field style="flex: 1;">
                <mat-label>EU-Tierpass</mat-label>
                <input matInput type="text" [formControl]="tierpassCtrl" style="width: 100%;">
            </mat-form-field>
            <mat-form-field style="flex: 1;">
                <mat-label>Zuchtbuchnummer</mat-label>
                <input matInput type="text" [formControl]="zuchtbuchnummerCtrl" style="width: 100%;">
            </mat-form-field>
        </div>
    </div>
</div>

<div align="end" mat-dialog-actions>
    <button (click)="dialogRef.close()" mat-button>Abbrechen</button>
    <button *kienePermission="[7002]" [disabled]="!isValid() || isSaving" (click)="save()" color="primary"
            mat-flat-button>
        Speichern
    </button>
</div>
