import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Bestellvorschlag } from '../bestellvorschlag';
import { UntypedFormControl } from '@angular/forms';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { environment } from '../../../../environments/environment';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'kiene-change-bestellmengen-dialog',
  templateUrl: './change-bestellmengen-dialog.component.html',
  styleUrls: ['./change-bestellmengen-dialog.component.scss']
})
export class ChangeBestellmengenDialogComponent implements OnInit {

  bestellvorschlag: Bestellvorschlag;

  mindestbestandCtrl = new UntypedFormControl();
  mindestbestellmengeCtrl = new UntypedFormControl();

  constructor(public dialogRef: MatDialogRef<ChangeBestellmengenDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
    private api: KieneTableService,
    private messageService: MessageService) {
    this.bestellvorschlag = data.bestellvorschlag;
  }

  ngOnInit() {
    this.mindestbestandCtrl.setValue(this.bestellvorschlag.mindestbestand);
    this.mindestbestellmengeCtrl.setValue(this.bestellvorschlag.bestellmenge);
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.bestellvorschlag.mindestbestand = this.mindestbestandCtrl.value;
    this.bestellvorschlag.bestellmenge = this.mindestbestellmengeCtrl.value;

    this.api.updateGeneric(environment.BASE_URI + 'bestellvorschlag/update_artikel.php', this.bestellvorschlag).subscribe(response => {
      this.messageService.infoMessage('Die Bestellmengen wurden aktualisert');
      this.dialogRef.close();
    }, error => {
      this.messageService.errorMessage('Bestellmengen konnten nicht aktualisiert werden.', error);
    });

  }

}
