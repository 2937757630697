<mat-card style="min-width: 120px; margin: 0px;">
    <p style="color: gray; font-size: 14px;">{{label}}</p>
    <h2 class="one-row" [ngClass]="(numberValue < 0) ? 'red' : ''">
        <span class="spacer"></span>
        <mat-icon *ngIf="prefixIcon">{{prefixIcon}}</mat-icon>
        <div>{{numberValue | number: numberFormat}}{{unit ? (' ' + unit) : ''}}</div>
        <span class="spacer"></span>
    </h2>
    <h3 *ngIf="secondRow" class="one-row" style="color: gray; font-weight: bold;">
        <span class="spacer"></span>
        <div>{{secondRow}}</div>
        <span class="spacer"></span>
    </h3>
    <mat-divider></mat-divider>
    <div *ngIf="numberValueVorjahr" class="one-row" style="font-style: italic; color: #7d7d7d; font-size: .9em;">
        <span class="spacer"></span>
        <mat-icon *ngIf="prefixIcon">{{prefixIcon}}</mat-icon>
        <div>{{numberValueVorjahr | number: numberFormat}}{{unit ? (' ' + unit) : ''}}</div>
        <span class="spacer"></span>
    </div>
    <h3 *ngIf="secondRowVorjahr" class="one-row" style="color: #7d7d7d; font-size: .9em; font-style: italic;">
        <span class="spacer"></span>
        <div>{{secondRowVorjahr}}</div>
        <span class="spacer"></span>
    </h3>

</mat-card>
