<div class="main-wrapper mat-typography">
    <mat-progress-bar *ngIf="isDataLoading" color="warn" mode="indeterminate"
        style="position: fixed; top: 0px; z-index: 999"></mat-progress-bar>
    <mat-sidenav-container (backdropClick)="closeSidenav()" class="kiene-sidenav-container">
        <mat-sidenav #snav (keydown.escape)="closeSidenav()" class="kiene-sidenav" disableClose>
            <ul class="main-menu">
                <li (click)="closeSidenav()" *ngIf="isMenuVisible('/startseite')">
                    <a [routerLink]="getLinkForFirstTab('startseite', 'tourenplan')"
                        routerLinkActive="active-menu-item">
                        <mat-icon class="inline">dashboard</mat-icon>
                        Startseite
                        <span *ngIf="!istCurrentUserKundeOderViehhaendler()">(ALT + 1)</span>
                    </a>
                </li>
                <li (click)="closeSidenav()"
                    *ngIf="isMenuVisible('/stammdaten') &&  !istCurrentUserKundeOderViehhaendler()">
                    <a [routerLink]="getLinkForFirstTab('stammdaten', 'betriebe')" routerLinkActive="active-menu-item">
                        <mat-icon class="inline">list</mat-icon>
                        Stammdaten
                        <span *ngIf="!istCurrentUserKundeOderViehhaendler()">(ALT + 2)</span>
                    </a>
                </li>
                <li (click)="closeSidenav()" *ngIf="isMenuVisible('/lager')">
                    <a [routerLink]="getLinkForFirstTab('lager')" routerLinkActive="active-menu-item">
                        <mat-icon class="inline">store</mat-icon>
                        Lager
                        <span *ngIf="!istCurrentUserKundeOderViehhaendler()">(ALT + 3)</span>
                    </a>
                </li>
                <li (click)="closeSidenav()" *ngIf="isMenuVisible('/wawi')">
                    <a [routerLink]="getLinkForFirstTab('wawi')" routerLinkActive="active-menu-item">
                        <mat-icon class="inline">shopping_cart</mat-icon>
                        WaWi
                        <span *ngIf="!istCurrentUserKundeOderViehhaendler()">(ALT + 4)</span>
                    </a>
                </li>
                <li (click)="closeSidenav()" *ngIf="isMenuVisible('/belege')">
                    <a [routerLink]="
                            getLinkForFirstTab('belege', 'abgabebelege')
                        " routerLinkActive="active-menu-item">
                        <mat-icon class="inline">assignment</mat-icon>
                        Belege
                        <span *ngIf="!istCurrentUserKundeOderViehhaendler()">(ALT + 5)</span>
                    </a>
                </li>
                <li (click)="closeSidenav()" *ngIf="
                        isMenuVisible('/labor') &&
                        !istCurrentUserKundeOderViehhaendler()
                    ">
                    <a [routerLink]="getLinkForFirstTab('labor')" routerLinkActive="active-menu-item">
                        <mat-icon class="inline">biotech</mat-icon>
                        Labor
                        <span *ngIf="!istCurrentUserKundeOderViehhaendler()">(ALT + 6)</span>
                    </a>
                </li>
                <li (click)="closeSidenav()" *ngIf="isMenuVisible('/finanzen')">
                    <a [routerLink]="getLinkForFirstTab('finanzen')" routerLinkActive="active-menu-item">
                        <mat-icon class="inline">attach_money</mat-icon>
                        Finanzen
                        <span *ngIf="!istCurrentUserKundeOderViehhaendler()">(ALT + 7)</span>
                    </a>
                </li>
                <li (click)="closeSidenav()" *ngIf="isMenuVisible('/administration')">
                    <a [routerLink]="getLinkForFirstTab('administration')" routerLinkActive="active-menu-item">
                        <mat-icon class="inline">settings</mat-icon>
                        Administration
                        <span *ngIf="!istCurrentUserKundeOderViehhaendler()">(ALT + 8)</span>
                    </a>
                </li>
                <li (click)="closeSidenav()" *ngIf="isMenuVisible('/reporte')">
                    <a [routerLink]="getLinkForFirstTab('reporte')" routerLinkActive="active-menu-item">
                        <mat-icon class="inline">pie_chart</mat-icon>
                        Reporte
                        <span *ngIf="!istCurrentUserKundeOderViehhaendler()">(ALT + 9)</span>
                    </a>
                </li>
                <li (click)="closeSidenav()" *ngIf="isMenuVisible('/gruppen')">
                    <a [routerLink]="getLinkForFirstTab('gruppen')" routerLinkActive="active-menu-item">
                        <mat-icon class="inline">ballot</mat-icon>
                        Gruppen
                        <span *ngIf="!istCurrentUserKundeOderViehhaendler() && !isCurrentUserSgs">(ALT
                            + 0)</span>
                    </a>
                </li>
                <li (click)="closeSidenav()" *ngIf="isMenuVisible('/heimtiere')">
                    <a [routerLink]="getLinkForFirstTab('heimtiere', 'startseite')" routerLinkActive="active-menu-item">
                        <mat-icon class="inline">pets</mat-icon>
                        Heimtiere <span *ngIf="!istCurrentUserKundeOderViehhaendler()"></span>
                        <span *ngIf="!istCurrentUserKundeOderViehhaendler()"></span>
                    </a>
                </li>
                <!--<li (click)="closeSidenav()" *ngIf="isMenuVisible('/logistik')">
                    <a [routerLink]="getLinkForFirstTab('logistik')" routerLinkActive="active-menu-item">
                        <mat-icon class="inline">local_shipping</mat-icon>
                        Logistik
                        <span *ngIf="!istCurrentUserKundeOderViehhaendler() && !isCurrentUserSgs">(ALT
                            + ß)</span>
                    </a>
                </li>-->
                <!--<li (click)="closeSidenav()" *ngIf="isMenuVisible('/gruppen-schweine')">
                    <a [routerLink]="getLinkForFirstTab('gruppen-schweine')" routerLinkActive="active-menu-item">
                        <mat-icon class="inline">ballot</mat-icon>
                        Gruppen (Schweine)
                    </a>
                </li>-->
                <li (click)="closeSidenav()" *ngIf="isMenuVisible('/schlachthoefe')">
                    <a [routerLink]="getLinkForFirstTab('schlachthoefe')" routerLinkActive="active-menu-item">
                        <mat-icon class="inline">ballot</mat-icon>
                        Schlachthöfe
                    </a>
                </li>
                <li (click)="closeSidenav()" *ngIf="isMenuVisible('/viehhaendler')">
                    <a [routerLink]="getLinkForFirstTab('viehhaendler')" routerLinkActive="active-menu-item">
                        <mat-icon class="inline">ballot</mat-icon>
                        Anfragen (Viehhändler)
                    </a>
                </li>
                <li (click)="closeSidenav()" *ngIf="isMenuVisible('/spediteur')">
                    <a [routerLink]="getLinkForFirstTab('spediteur')" routerLinkActive="active-menu-item">
                        <mat-icon class="inline">ballot</mat-icon>
                        Anfragen (Spediteur)
                    </a>
                </li>
            </ul>
            <div style="position: absolute; bottom: 20px; width: 100%">
                <span style="
                        width: 100%;
                        text-align: left;
                        font-size: 12px;
                        color: #777;
                        padding: 10px;
                    ">{{ appVersion }}</span>
                <br />
                <a *kienePermission="[501]" [routerLink]="['/versioninfo']" style="
                        width: 100%;
                        text-align: left;
                        font-size: 12px;
                        color: #777;
                        padding: 10px;
                    ">Versionsverlauf anschauen</a>
                <br />
                <a href="https://www.vetvet.de/datenschutz" target="_blank" style="
                        width: 100%;
                        text-align: left;
                        font-size: 12px;
                        color: #777;
                        padding: 10px;
                    ">https://www.vetvet.de/datenschutz</a>
                <br />
                <span style="
                        width: 100%;
                        text-align: left;
                        font-size: 12px;
                        color: #777;
                        padding: 10px;
                    ">{{ branch }}</span>
                <br />
                <span style="
                        width: 100%;
                        text-align: left;
                        font-size: 12px;
                        color: #777;
                        padding: 10px;
                    ">&copy; VetVet Beratung GmbH & Co. KG</span>
            </div>
        </mat-sidenav>
        <mat-sidenav-content style="overflow: auto">
            <mat-toolbar *ngIf="isLoggedIn() && showToolbar" class="kiene-toolbar"
                [style.background-color]="environment.dev ? '#ff7500' : ''">
                <mat-toolbar-row>
                    <button (click)="snav.toggle()" mat-icon-button matTooltip="F2 zum Öffnen und Schließen">
                        <mat-icon>menu</mat-icon>
                    </button>
                    <h1 *ngIf="!isMobileScreen" style="color: white">
                        <span *ngIf="istCurrentUserKundeOderViehhaendler() || isCurrentUserSgs"
                            style="font-size: 1.6em; font-weight: 200;">VETVET.</span><span
                            *ngIf="!istCurrentUserKundeOderViehhaendler() && !isCurrentUserSgs">
                            {{ currentMandant?.bezeichnung }} ({{
                            currentMandant?.mandant_nr
                            }}) {{ subTitle ? " - " + subTitle : "" }}</span>
                    </h1>
                    <h1 *ngIf="isMobileScreen" style="font-size: 12px; color: white">
                        <span *ngIf="istCurrentUserKundeOderViehhaendler() || isCurrentUserSgs"
                            style="font-size: 1.6em; font-weight: 100">vetvet.de</span><span
                            *ngIf="!istCurrentUserKundeOderViehhaendler() && !isCurrentUserSgs">
                            {{ currentMandant?.bezeichnung }} <br />({{
                            currentMandant?.mandant_nr
                            }}) {{ subTitle ? " - " + subTitle : "" }}</span>
                    </h1>
                    <span style="flex: 1 1 auto"></span>
                    <div *ngIf="showCurrentClient" class="one-row" style="max-width: 50%; margin: 0">
                        <kiene-change-current-client *kienePermission="[4511]"></kiene-change-current-client>
                        <kiene-go-to-hitbutton *ngIf="isCurrentUserPhitoUser()"></kiene-go-to-hitbutton>
                        <span class="spacer"></span>
                    </div>
                    <span style="flex: 1 1 auto"></span>

                    <kiene-benachrichtigung-button style="margin: 0 12px"></kiene-benachrichtigung-button>

                    <ng-container *kienePermission="[1771]">
                        <button (click)="showWarenkorb()" *ngIf="showShoppingCart" mat-icon-button
                            matTooltip="Warenkorb anzeigen">
                            <mat-icon [matBadge]="numberOfShoppingCartItems"
                                matBadgeColor="warn">shopping_cart</mat-icon>
                        </button>
                    </ng-container>

                    <div class="one-row" [matMenuTriggerFor]="menu"
                        style="width: fit-content; font-size: .8em; min-height: 64px; cursor: pointer;">
                        <mat-icon>person</mat-icon>
                        <span *ngIf="!isMobileScreen" style="margin-left: 6px;">{{ currentUserName }}</span>
                        <span *ngIf="!isMobileScreen" class="spacer"></span>
                    </div>


                    <!--                    <button mat-icon-button [matMenuTriggerFor]="menu">-->
                    <!--                        <mat-icon>menu</mat-icon>-->
                    <!--                    </button>-->
                    <mat-menu #menu="matMenu">
                        <a [routerLink]="'/profil'" mat-menu-item>
                            <mat-icon>person</mat-icon>
                            <span>Mein Profil</span>
                        </a>
                        <button (click)="showMyVetStock()" *ngIf="currentUserIsVet()" mat-menu-item>
                            <mat-icon>airport_shuttle</mat-icon>
                            <span>Tierarztwagen Bestand</span>
                        </button>
                        <button (click)="goToInventur()" *ngIf="showInventur" mat-menu-item>
                            <mat-icon>checklist_rtl</mat-icon>
                            <span>Inventur</span>
                        </button>
                        <button (click)="openUploadDocumentDialog()" *kienePermission="[802, 1322]" mat-menu-item
                            matTooltip="Gescannte Kiene Dokumente hochladen">
                            <mat-icon>cloud_upload</mat-icon>
                            <span>Kiene Dokumente hochladen</span>
                        </button>
                        <button (click)="logout()" mat-menu-item>
                            <mat-icon>power_settings_new</mat-icon>
                            <span>Abmelden</span>
                        </button>
                        <button (click)="sessionRefresh()" mat-menu-item>
                            <mat-icon>lock_reset</mat-icon>
                            <span>Session &amp; Rechte aktualisieren</span>
                        </button>
                    </mat-menu>

                    <button (click)="toggleEntityViewer()" *ngIf="kieneSession?.email?.endsWith('@phito.de')"
                        mat-icon-button>
                        <mat-icon>developer_mode</mat-icon>
                    </button>
                </mat-toolbar-row>
            </mat-toolbar>
            <div style="padding: 10px; overflow: auto">
                <router-outlet></router-outlet>
            </div>

            <kiene-entity-viewer style="
                    position: fixed;
                    top: 60px;
                    right: 20px;
                    width: 400px;
                    margin-top: 24px;
                    z-index: 9999;
                "></kiene-entity-viewer>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
<div *ngIf="showNetworkLog"
    style="position: fixed; bottom: 0; z-index: 9999; width:100%; max-height: 150px; overflow: scroll; background-color: black; color: #20c997; font-size: 1.6em; font-family: monospace;">
    <table style="width: 100%;">
        <tr (click)="alert(event);" *ngFor="let event of networkLogEvents" class="networklog">
            <td style="padding-right: 12px;">{{ event?.requestTimestamp | date: 'HH:mm:ss.SSS' }}</td>
            <td style="padding-right: 12px;">{{ event?.responseTimestamp | date: 'HH:mm:ss.SSS' }}</td>
            <td style="padding-right: 12px; min-width: 100px;">{{
                event?.responseTimestamp?.getTime() -
                event?.requestTimestamp?.getTime()
                }}
                ms
            </td>
            <td [style.color]="event?.network.status >= 400 ? 'red' : '#20c997'" style="padding-right: 12px;">
                {{ event?.network.status }}
            </td>
            <td style="padding-right: 12px;">{{ event?.network.method }}</td>
            <td>{{ event?.network.url }}</td>
        </tr>
    </table>
</div>