<div style="padding: 24px;">
    <div class="one-row">
        <span class="spacer"></span>
        <kiene-zeitauswahl (change)="zeitintervalChanged($event)"></kiene-zeitauswahl>
        <span class="spacer"></span>
    </div>

    <table class="report-ergebnis-table">
        <thead>
        <tr>
            <th></th>
            <th>Umsatz Gesamt</th>
            <th>Gutschriften Gesamt</th>
            <th>Rabatte Gesamt</th>
            <th>Umsatz II Gesamt</th>
            <th>Bestellungen Gesamt</th>
        </tr>
        </thead>
        <tbody>
        <tr style="font-size: 1.6em; font-weight: bold; color: green;">
            <td style="font-size: 1em; font-weight: bold; color: gray;"></td>
            <td>{{gesamtUmsatz | number: '1.2-2'}} &euro;</td>
            <td><span>{{gesamtGutschriften | number: '1.2-2'}} &euro;</span><br> <span
                style="font-size: .8em; color: gray;">&#8793; {{gesamtGutschriftenProzent}}
                &percnt;</span></td>
            <td><span>{{gesamtRabatte | number: '1.2-2'}} &euro;</span><br> <span
                style="font-size: .8em; color: gray">&#8793; {{gesamtRabatteProzent}}
                &percnt;</span></td>
            <td><span>{{gesamtUmsatzII | number: '1.2-2'}} &euro;</span><br> <span
                style="font-size: .8em; color: gray">&#8793; {{gesamtUmsatzIIProzent}}
                &percnt;</span></td>
            <td>{{gesamtBestellungen | number: '1.0-0'}}</td>
        </tr>
        <tr style="font-size: 1em; font-weight: bold; color: gray;">
            <td>{{ vorjahreszeitraum?.von | date: 'dd.MM.yyyy' }}
                bis {{vorjahreszeitraum?.bis | date: 'dd.MM.yyyy'}}</td>
            <td>{{gesamtUmsatzVorjahr | number: '1.2-2'}} &euro;</td>
            <td>{{gesamtGutschriftenVorjahr | number: '1.2-2'}} &euro; ({{gesamtGutschriftenProzentVorjahr}} &percnt;)
            </td>
            <td>{{gesamtRabatteVorjahr | number: '1.2-2'}} &euro; ({{gesamtRabatteProzentVorjahr}} &percnt;)</td>
            <td>{{gesamtUmsatzIIVorjahr | number: '1.2-2'}} &euro; ({{gesamtUmsatzIIProzentVorjahr}} &percnt;)</td>
            <td>{{gesamtBestellungenVorjahr | number: '1.2-2'}} &euro; ({{gesamtBestellungenVorjahr}}
                &percnt;)
            </td>
        </tr>
        </tbody>
    </table>

    <!--    <div class="one-row" style="min-height: 300px;">-->
    <!--        <kiene-report-overview-value-card [label]="'Umsatz Gesamt'" [numberValue]="gesamtUmsatz"-->
    <!--                                          [numberFormat]="'1.2-2'" [unit]="'&euro;'"-->
    <!--                                          [secondRow]="'&#8793;100 %'"></kiene-report-overview-value-card>-->
    <!--        <kiene-report-overview-value-card [label]="'Gutschriften Gesamt'" [numberValue]="gesamtGutschriften"-->
    <!--                                          [numberFormat]="'1.2-2'" [unit]="'&euro;'"-->
    <!--                                          [secondRow]="gesamtGutschriftenProzent + '%'"></kiene-report-overview-value-card>-->
    <!--        <kiene-report-overview-value-card [label]="'Rabatte Gesamt'" [numberValue]="gesamtRabatte"-->
    <!--                                          [numberFormat]="'1.2-2'" [unit]="'&euro;'"-->
    <!--                                          [secondRow]="gesamtRabatteProzent + '%'"></kiene-report-overview-value-card>-->
    <!--        <kiene-report-overview-value-card [label]="'Umsatz II. Gesamt'" [numberValue]="gesamtUmsatzII"-->
    <!--                                          [numberFormat]="'1.2-2'" [unit]="'&euro;'"-->
    <!--                                          [secondRow]="gesamtUmsatzIIProzent + '%'"></kiene-report-overview-value-card>-->
    <!--        <kiene-report-overview-value-card [label]="'Bestellungen Gesamt'" [numberValue]="gesamtBestellungen"-->
    <!--                                          [numberFormat]="'1.0-0'" [unit]="'Stk.'"></kiene-report-overview-value-card>-->
    <!--    </div>-->

    <mat-card>
        <div class="one-row">
            <span class="spacer"></span>
            <kiene-csv-export [columns]="csvColumns" [data]="dataSource.data"
                              [filename]="csvFilename"></kiene-csv-export>
        </div>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

            <!-- Name Column -->
            <ng-container matColumnDef="lieferant">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Lieferant</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.lieferant}} </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="umsatz">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Umsatz</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.umsatz | number: '1.2-2'}} &euro;</td>
            </ng-container>
            <ng-container matColumnDef="umsatz_prozent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Umsatz (%)</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.umsatz_prozent | number: '1.0-2'}}%
                </td>
            </ng-container>

            <ng-container matColumnDef="gutschriften">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Gutschriften</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.gutschriften | number: '1.2-2'}}
                    &euro;
                </td>
            </ng-container>
            <ng-container matColumnDef="gutschriften_prozent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Gutschriften (%)</th>
                <td mat-cell *matCellDef="let element"
                    class="number"> {{element.gutschriften_prozent | number: '1.0-2'}} %
                </td>
            </ng-container>

            <ng-container matColumnDef="rabatte">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Rabatte</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.rabatte | number: '1.2-2'}} &euro;</td>
            </ng-container>
            <ng-container matColumnDef="rabatte_prozent">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Rabatte (%)</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.rabatte_prozent | number: '1.0-2'}}%
                </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="endpreis">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header"> Umsatz II.</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.endpreis | number: '1.2-2'}} &euro;
                </td>
            </ng-container>
            <ng-container matColumnDef="bestellungen">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header">Bestellungen</th>
                <td mat-cell *matCellDef="let element" class="number"> {{element.bestellungen | number: '1.0-0'}}Stk.
                </td>
            </ng-container>


            <ng-container matColumnDef="aggregatedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="aggregated-vetgroup-detail"
                         [@detailExpanded]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <div *ngIf="isLoading[element.lieferant_id]" class="one-row">
                            <span class="spacer"></span>
                            <mat-spinner [diameter]="32"></mat-spinner>
                            <span class="spacer"></span>
                        </div>
                        <div *ngIf="!isLoading[element.lieferant_id]" class="one-row">
                            <span class="spacer"></span>
                            <kiene-csv-export [columns]="csvColumnsDetails" [data]="element.details"
                                              [filename]="'Lieferanten_Gesamt_' + element.lieferant + '.csv'"></kiene-csv-export>
                        </div>
                        <table *ngIf="!isLoading[element.lieferant_id]" class="details-table"
                               style="table-layout: fixed; padding-bottom: 24px;">
                            <tr *ngFor="let d of element.details">
                                <td style="padding-left: 24px;">{{d.zeitraum}}</td>
                                <td class="number">{{d.umsatz_prozent | number: '1.0-2'}} %</td>
                                <td class="number">{{d.umsatz | number: '1.2-2'}} &euro;</td>
                                <td class="number">{{d.gutschriften | number: '1.2-2'}} &euro;</td>
                                <td class="number">{{d.gutschriften_prozent | number: '1.0-2'}} %</td>
                                <td class="number">{{d.rabatte | number: '1.2-2'}} &euro;</td>
                                <td class="number">{{d.rabatte_prozent | number: '1.0-2'}} %</td>
                                <td class="number">{{d.endpreis | number: '1.2-2'}} &euro;</td>
                                <td class="number">{{d.bestellungen | number: '1.0-0'}} Stk.</td>
                            </tr>
                        </table>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="vet-group-row"
                [class.vet-group-expanded-row]="expandedElement === element"
                (click)="expandVetGroupRow(element)">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['aggregatedDetail']" class="aggregated-row"></tr>
        </table>
    </mat-card>

    <mat-card style="margin-bottom: 24px; margin-top: 24px; height: 600px;">
        <google-chart style="width: 100%;" [type]="chartType" [columns]="chartColumns" [data]="chartData" [height]="600"
                      [options]="{pieHole: 0.4, title: 'Umsatz Lieferanten'}">
        </google-chart>
    </mat-card>


</div>
