import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, } from '@angular/core';
import { GruppeTier } from '../../gruppe-tier/classes/gruppe-tier';
import { MatDialog } from '@angular/material/dialog';
import {
    GruppeTierTodMeldenDialogComponent
} from '../../gruppe-tier/gruppe-tier-tod-melden-dialog/gruppe-tier-tod-melden-dialog.component';
import { GruppeTierService } from '../../gruppe-tier/gruppe-tier.service';
import { MessageService } from '../../../services/message-service/message.service';
import { ConfirmDialogInput, ConfirmDialogResult, } from '../../dialogs/confirm-dialog/confirm-dialog';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import {
    GruppeTierReklamierenDialogComponent
} from '../../gruppe-tier/gruppe-tier-reklamieren-dialog/gruppe-tier-reklamieren-dialog.component';
import {
    GruppeTierDetailsDialogComponent
} from '../../gruppe-tier/gruppe-tier-details-dialog/gruppe-tier-details-dialog.component';
import {
    GruppeTierBearbeitenDialogComponent
} from '../../gruppe-tier/gruppe-tier-bearbeiten-dialog/gruppe-tier-bearbeiten-dialog.component';
import {
    GruppeTierHilfeDialogComponent
} from '../../gruppe-tier/gruppe-tier-hilfe-dialog/gruppe-tier-hilfe-dialog.component';
import {
    GruppeTierBemerkungDialogComponent
} from '../../gruppe-tier/gruppe-tier-bemerkung-dialog/gruppe-tier-bemerkung-dialog.component';

@Component({
    selector: 'kiene-gruppe-tier-card',
    templateUrl: './gruppe-tier-card.component.html',
    styleUrls: ['./gruppe-tier-card.component.scss'],
})
export class GruppeTierCardComponent {
    @Input('tier') tier: GruppeTier;

    @Input('disableCardClickDetails') disableCardClickDetails = false;

    @Input('hideAllButtons') hideAllButtons = false;
    @Input('hideDetailsButton') hideDetailsButton = false;
    @Input('hideTodmeldungButton') hideTodmeldungButton = false;
    @Input('hideHitMeldenButton') hideHitMeldenButton = false;
    @Input('hideLoeschenButton') hideLoeschenButton = false;
    @Input('hideReklamationButton') hideReklamationButton = false;
    @Input('hideBemerkungButton') hideBemerkungButton = false;
    @Input('hideBearbeitenButton') hideBearbeitenButton = false;
    @Input('hideHilfeButton') hideHilfeButton = false;

    // Tierinfos
    @Input('hideStatusInfos') hideStatusInfos = false;
    @Input('hideTranspondernr') hideTranspondernr = false;
    @Input('hideGeschlechtRasse') hideGeschlechtRasse = false;
    @Input('hideGruppe') hideGruppe = false;
    @Input('hideKunde') hideKunde = true;
    @Input('hideAbteil') hideAbteil = false;
    @Input('hideGewicht') hideGewicht = false;
    @Input('hideAlter') hideAlter = false;
    @Input('hideEinstallungAbgang') hideEinstallung = false;
    @Input('hideAbgang') hideAbgang = false;
    @Input('hideTodesdatum') hideTodesdatum = false;
    @Input('hideGrundEntfernung') hideGrundEntfernung = false;
    @Input('hideReklamationen') hideReklamationen = false;
    @Input('hideBemerkung') hideBemerkung = false;

    @Output('reload') reload: EventEmitter<boolean> = new EventEmitter();

    @ContentChild(TemplateRef) matButtonTemplatesCard: TemplateRef<any>;

    constructor(
        private dialog: MatDialog,
        private gruppeTierService: GruppeTierService,
        private messageService: MessageService
    ) {
    }

    showDetails(tier: GruppeTier, event?: MouseEvent) {
        if (event && this.disableCardClickDetails) {
            return;
        }

        const dialogRef = this.dialog.open(GruppeTierDetailsDialogComponent, {
            width: '90vw',
            minWidth: '90vw',
            data: {
                tier: tier,
            },
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.reload.emit(res);
            }
        });
    }

    todmeldung(tier: GruppeTier) {
        const dialogRef = this.dialog.open(GruppeTierTodMeldenDialogComponent, {
            width: '90vw',
            minWidth: '90vw',
            data: {
                tier: tier,
            },
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.reload.emit(res);
                this.tier = res;
            }
        });
    }

    tierBearbeiten(tier: GruppeTier) {
        // TODO einstalldatum und ausstalldatum mit hinzufügen
        // Hinweis wenn Datum sich geändert hat das sich dadurch auch Daten in HIT ändern
        // Vor normalen Update ggf. HIT-Update-Route aufrufen

        const dialogRef = this.dialog.open(
            GruppeTierBearbeitenDialogComponent,
            {
                width: '90vw',
                minWidth: '90vw',
                data: {
                    tier: tier,
                },
            }
        );
        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.reload.emit(res);
                this.tier = res;
            }
        });
    }

    loeschen(t: GruppeTier) {
        const input = new ConfirmDialogInput();
        input.headline = `Tier ${t.ohrmarke_notnull} löschen?`;
        input.text = 'Wollen Sie dieses Tier wirklich löschen? Wenn ein Zugang über vetvet an HIT gemeldet wurden, wird dieser ebenfalls storniert!';
        input.okButton = 'Löschen';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.gruppeTierService.deleteTier(t).subscribe({
                        next: () => {
                            this.messageService.infoMessage(
                                'Tier erfolgreich gelöscht'
                            );
                            this.reload.emit();
                        },
                        error: (err) => {
                            this.messageService.errorMessage(
                                'Fehler beim Löschen des Tiers',
                                err
                            );
                        },
                    });
                }
            },
        });
    }

    reklamationHinzufuegen(t: GruppeTier) {
        const dialogRef = this.dialog.open(
            GruppeTierReklamierenDialogComponent,
            {
                width: '90vw',
                minWidth: '90vw',
                data: {
                    tier: t,
                },
            }
        );

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.reload.emit(res);
            }
        });
    }

    bemerkungHinzufuegen(tier: GruppeTier) {
        const dialogRef = this.dialog.open(GruppeTierBemerkungDialogComponent, {
            width: '90vw',
            minWidth: '90vw',
            data: {
                tier: tier,
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.reload.emit(res);
            }
        });
    }

    openHelp() {
        this.dialog.open(GruppeTierHilfeDialogComponent, {
            width: '90vw',
            minWidth: '90vw',
        });
    }

    addPassGescannt() {
        this.tier.pass_gescannt = 1;
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                okButton: 'Bestätigen',
                text: 'Wollen Sie wirklich den Pass für das Tier manuell als vorhanden eintragen?',
                headline: 'Pass als vorhanden nachtragen',
                buttonColor: 'accent',
            },
        });
        dialogRef.afterClosed().subscribe((resp: ConfirmDialogResult) => {
            if (resp?.confirmed) {
                this.tier.pass_gescannt = 1;
                this.updateTier(this.tier);
            }
        });
    }

    addOhrmarkeGescannt() {
        this.gruppeTierService.ohrmarkeAlsGescanntMarkieren(this.tier?.gruppe_id, [this.tier]).subscribe({
            next: (result: boolean) => {
                if (result === true) {
                    this.reload.emit(true);
                }
            }
        });
    }

    updateTier(tier: GruppeTier) {
        this.gruppeTierService.update(tier).subscribe({
            next: (resp) => {
                this.reload.emit(resp);
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Fehler beim Aktualisieren des Tiers!',
                    err
                );
            },
        });
    }

    getIconStatusClass(status_id: number) {
        if (status_id === 142 || status_id === 145) {
            return 'icon-status--checked';
        }
        if (status_id === 141 || status_id === 144) {
            return 'icon-status--wait';
        }
        if (status_id === 143) {
            return 'icon-status--error';
        }
        return 'icon-status--unchecked'; // 140 und undefined etc.
    }

    gruppeAendern() {
        this.gruppeTierService.gruppeAendernConfirm([this.tier]).subscribe({
            next: (b: boolean) => {
                if (b) {
                    this.reload.emit(true);
                }
            },
        });
    }
}
