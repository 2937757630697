import { Component, Inject, OnInit } from '@angular/core';
import { StammdatenService } from '../../stammdaten/stammdaten.service';
import { Client } from '../../client/classes/client';
import { MessageService } from '../../../services/message-service/message.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'kiene-betrieb-benutzer-zuweisen-dialog',
    templateUrl: './betrieb-benutzer-zuweisen-dialog.component.html',
    styleUrls: ['./betrieb-benutzer-zuweisen-dialog.component.scss']
})
export class BetriebBenutzerZuweisenDialogComponent implements OnInit {
    vvvos: Client[] = [];
    zugewieseneBetriebe: Client[] = [];
    selectCtrl = new FormControl();
    selectedCtrls: FormControl[] = [];
    isLoading = false;
    private vvvoSub: any;

    constructor(private stammdatenService: StammdatenService,
        private messageService: MessageService,
        public dialogRef: MatDialogRef<BetriebBenutzerZuweisenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public user: any) {
    }

    ngOnInit(): void {
        this.loadVvvos();

    }

    loadVvvos() {
        this.isLoading = true;
        this.vvvoSub?.unsubscribe();
        this.vvvoSub = this.stammdatenService.ladeVvvos().subscribe({
            next: resp => {
                this.vvvos = resp.records;
                this.ladeVvvosFuerBenutzer();
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Laden der Betriebe!', err);
                this.isLoading = false;
            }
        });
    }

    betriebAuswaehlen(event: MatSelectChange) {
        console.log('betrieb select: ', event.value);

    }

    vvvoCompare(c1: Client, c2: Client) {
        return c1?.kunde_id === c2?.kunde_id;
    }

    betriebChanged(event: MatCheckboxChange, betrieb: Client) {
        console.log(event);
        if (event.checked) {
            const client = event.source.value;
            this.stammdatenService.kundeBenutzerZuweisen(this.user.benutzer_id, betrieb.kunde_id).subscribe({
                next: response => {
                    this.messageService.infoMessage('Betrieb erfolgreich zugwiesen.');
                    this.ladeVvvosFuerBenutzer();
                },
                error: err => {
                    this.messageService.errorMessage('Fehler beim Betrieb zuweisen!', err);
                }
            });
        } else {
            const client = event.source.value;
            this.stammdatenService.kundeBenutzerEntfernen(this.user.benutzer_id, betrieb.kunde_id).subscribe({
                next: response => {
                    this.messageService.infoMessage('Betrieb erfolgreich entfernt.')
                    this.ladeVvvosFuerBenutzer();
                },
                error: err => {
                    this.messageService.errorMessage('Fehler beim Betrieb entfernen!', err);
                }
            });
        }

    }

    initSelectedCtrls() {
        for (const v of this.vvvos) {
            const fc = new FormControl(false);
            this.selectedCtrls[v.vvvo] = fc;
        }
        for (const b of this.zugewieseneBetriebe) {
            this.selectedCtrls[b.vvvo].setValue(true);
        }
        this.isLoading = false;
    }

    private ladeVvvosFuerBenutzer() {
        this.stammdatenService.ladeZugewieseneKunden(this.user.benutzer_id).subscribe({
            next: response => {
                this.zugewieseneBetriebe = response.records;
                this.initSelectedCtrls();

            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
                this.isLoading = false;
            }
        });
    }
}
