import {Component, OnChanges, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {Client} from '../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {MassnahmenplanRindService} from './massnahmenplan-rind/massnahmenplan-rind.service';
import {TabinfoComponent} from '../../components/tabinfo/tabinfo.component';
import {MassnahmenplanSchweinService} from './massnahmenplan-schwein/massnahmenplan-schwein.service';

@Component({
    selector: 'kiene-massnahmenplan',
    templateUrl: './massnahmenplan.component.html',
    styleUrls: ['./massnahmenplan.component.scss']
})
export class MassnahmenplanComponent implements OnInit, OnChanges {

    @ViewChild('rindTabInfo') rindTabInfo: TabinfoComponent;

    selectedTab = 0;

    constructor(private router: Router,
                private localStorageService: LocalStorageService,
                private mnpSchweinService: MassnahmenplanSchweinService,
                private massnahmenplanRindService: MassnahmenplanRindService,
                private route: ActivatedRoute) {
        this.route.queryParams.subscribe(params => {
            const tabName = params['tab'];
            if (tabName) {
                if (tabName === 'rind') {
                    this.selectedTab = 0;
                } else if (tabName === 'schwein') {
                    this.selectedTab = 1;
                }
            }
        });
    }

    ngOnInit() {
        this.localStorageService.watchCurrentClient().subscribe({
            next: (currentClient: Client) => {
                if (currentClient) {
                    this.checkSchweineTab();
                }
                this.rindTabInfo.reload();
            }
        });
        this.massnahmenplanRindService.watchTabInfoNeuladen().subscribe({
            next: value => {
                if (value) {
                    this.rindTabInfo.reload();
                }
            }
        });
    }

    ngOnChanges() {
        this.checkSchweineTab();
    }

    setSelectedTab(event: MatTabChangeEvent) {
        this.setTab(event.index);

    }

    setTab(index: number) {
        this.selectedTab = index;
        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {tab: this.getTabName(index)},
                queryParamsHandling: 'merge'
            });
    }

    getTabName(id: number) {
        if (id === 0) {
            return 'rind';
        } else if (id === 1) {
            return 'schwein';
        } else {
            return 'rind';
        }
    }

    alleAbschliessenSchwein() {
        this.mnpSchweinService.alleOffenenAbschliessen();
    }

    alleAbschliessenRind() {
        this.massnahmenplanRindService.alleOffenenAbschliessen();
    }

    private checkSchweineTab() {
        const currentClient = this.localStorageService.getCurrentClient();
        if (currentClient?.schwein) {
            this.selectedTab = 1;
        }
    }
}
