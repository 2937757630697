import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from "@angular/core";
import { StammdatenService } from "../../../stammdaten/stammdaten.service";
import { Haendler } from "../../../stammdaten/haendler/haendler";
import { ActivatedRoute, Router } from "@angular/router";
import { GruppenService } from "../../../gruppen/gruppen.service";
import { UntypedFormControl } from "@angular/forms";
import { MessageService } from "../../../../services/message-service/message.service";
import { GruppeTier } from "../../classes/gruppe-tier";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { HaendlerDetailsDialogComponent } from "../../../stammdaten/haendler/haendler-details-dialog/haendler-details-dialog.component";
import { GruppeTierService } from "../../gruppe-tier.service";

@Component({
    selector: "kiene-gruppe-tier-viehhaendler-zuordnen",
    templateUrl: "./gruppe-tier-viehhaendler-zuordnen.component.html",
    styleUrls: ["./gruppe-tier-viehhaendler-zuordnen.component.scss"],
})
export class GruppeTierViehhaendlerZuordnenComponent
    implements OnInit, OnDestroy
{
    @Input("dialog") dialog = false;
    @Input("tier") tier: GruppeTier;
    @Output("saved") saved: EventEmitter<boolean> = new EventEmitter();

    isLoading = false;

    haendlerSubscription: Subscription;
    tierSubscription: Subscription;

    viehhaendler: Haendler[];
    haendlerCtrl: UntypedFormControl = new UntypedFormControl(null);
    buttonText = "Speichern";

    constructor(
        private stammdatenService: StammdatenService,
        private route: ActivatedRoute,
        private router: Router,
        private gruppenService: GruppenService,
        private gruppeTierService: GruppeTierService,
        private messageService: MessageService,
        private matDialog: MatDialog
    ) {}

    ngOnDestroy(): void {
        this.tierSubscription?.unsubscribe();
        this.haendlerSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.ladeHaendler();

        this.haendlerCtrl.valueChanges.subscribe((value) => {
            console.debug(value);
        });
    }

    ladeHaendler() {
        this.viehhaendler = [];
        this.haendlerSubscription?.unsubscribe();
        this.haendlerSubscription = this.stammdatenService
            .loadHaendler()
            .subscribe({
                next: (haendler) => {
                    this.viehhaendler = haendler.records;
                    if (this.tier) {
                        this.setupCtrl();
                    } else {
                        this.route.params.subscribe((params) => {
                            this.loadTier(params["gt_id"]);
                        });
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        "Fehler beim Laden der Händler",
                        err
                    );
                },
            });
    }

    setupCtrl() {
        if (this.tier.viehhaendler && this.tier.viehhaendler_id) {
            this.buttonText = "Aktualisieren";
            if (!this.haendlerCtrl.value) {
                const haendler = this.viehhaendler.find(
                    (h) => h.viehhaendler_id === this.tier.viehhaendler_id
                );
                this.haendlerCtrl.setValue(haendler);
            }
        }
    }

    loadTier(tierId: string) {
        this.tierSubscription?.unsubscribe();
        this.tierSubscription = this.gruppenService.loadTier(tierId).subscribe({
            next: (tier: GruppeTier) => {
                this.tier = tier;
                this.setupCtrl();
            },
            error: (err) => {
                this.messageService.errorMessage(
                    "Fehler beim Laden des Tieres",
                    err
                );
            },
        });
    }

    compareHaendler(c1: Haendler, c2: Haendler): boolean {
        return c1 && c2 ? c1.viehhaendler_id === c2.viehhaendler_id : c1 === c2;
    }

    save() {
        if (this.haendlerCtrl.value) {
            const haendler: Haendler = this.haendlerCtrl.value;
            this.tier.viehhaendler = haendler.name;
            this.tier.viehhaendler_id = haendler.viehhaendler_id;
        }
        this.isLoading = true;
        this.gruppeTierService.update(this.tier).subscribe({
            next: (tier: GruppeTier) => {
                this.isLoading = false;
                this.tier = tier;
                this.messageService.infoMessage(
                    "Viehhändler wurde gespeichert"
                );
                if (this.dialog) {
                    this.saved.emit();
                }
            },
            error: (err) => {
                this.isLoading = false;
                this.messageService.errorMessage(
                    "Fehler beim Speichern des Viehhändlers",
                    err
                );
            },
        });
    }

    addHaendler() {
        if (this.dialog) {
            const dialogRef = this.matDialog.open(
                HaendlerDetailsDialogComponent,
                {
                    width: "90vw",
                    minWidth: "90vw",
                }
            );
            dialogRef.afterClosed().subscribe((result: Haendler) => {
                if (result) {
                    this.ladeHaendler();
                    this.haendlerCtrl.setValue(result);
                }
            });
        } else {
            this.router.navigate(["./neu"], { relativeTo: this.route }).then();
        }
    }
}
