import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TableDescriptor, ToolbarButton } from '../../../components/kiene-table/table-descriptor';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from '../../../services/message.service';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import {
    EmailGesendetTableColumn,
    GedrucktTableColumn,
    KieneBooleanTableColumn,
    TableColumn
} from '../../../components/kiene-table/table-column';
import { Condition, Operator, TableOption, TableOptionEvent } from '../../../components/kiene-table/table-option';
import { MassnahmenplanRind } from './massnahmenplan-rind';
import { ConfirmDeleteDialogComponent } from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { CsvUploadDialogComponent } from '../csv-upload-dialog/csv-upload-dialog.component';
import {
    MassnahmenplanRindKurzDialogComponent
} from './massnahmenplan-rind-kurz-dialog/massnahmenplan-rind-kurz-dialog.component';
import { MassnahmenplanStatus } from '../classes/massnahmenplan-status';
import {
    ConfirmWithOptionDialogInput,
    ConfirmWithOptionDialogResult,
} from '../../../dialogs/confirm-with-option-dialog/confirm-with-option-dialog';
import { KieneKeyValue } from '../../../../../projects/kiene-core/src/lib/services/backend-api/classes/kiene-key-value';
import {
    ConfirmWithOptionDialogComponent
} from '../../../dialogs/confirm-with-option-dialog/confirm-with-option-dialog.component';
import { MassnahmenplanRindService } from './massnahmenplan-rind.service';
import { Subscription } from 'rxjs';
import {
    FilterItem,
    FilterItemType
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import { Signature } from '../../../../../projects/kiene-core/src/lib/components/unterschriften/signature';
import { Benutzerprofil } from '../../../../../projects/kiene-core/src/lib/components/stammdaten/mein-profil/mein-profil';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    KieneBackendApiService
} from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {
    UnterschriftenService
} from '../../../../../projects/kiene-core/src/lib/components/unterschriften/unterschriften.service';
import { TabinfoService } from '../../../components/tabinfo/tabinfo.service';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'kiene-massnahmenplan-rind',
    templateUrl: './massnahmenplan-rind.component.html',
    styleUrls: ['./massnahmenplan-rind.component.scss'],
})
export class MassnahmenplanRindComponent implements OnInit, OnDestroy {
    tableDescriptor: TableDescriptor = new TableDescriptor();
    watchCurrentClientSubscription: Subscription;
    benutzerProfil: Benutzerprofil;
    anzahlOffenePlaene = 0;
    anzahlUnterschreibbarePlaene = 0;
    ladeAlleErstelltenSubscription: Subscription;

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private tabInfoService: TabinfoService,
        private api: KieneBackendApiService,
        private mnpRindService: MassnahmenplanRindService,
        private unterschriftService: UnterschriftenService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnDestroy(): void {
        this.watchCurrentClientSubscription?.unsubscribe();
    }

    ngOnInit() {
        this.ladeBenutzerprofil();

        this.tableService.watchReload().subscribe({
            next: () => {
                this.ladeAlleErstellten();
                this.ladeAlleZumUnterschreiben();
            }
        });

        this.watchCurrentClientSubscription = this.localStorageService
            .watchCurrentClient()
            .subscribe((client) => {
                this.tableService?.reload();
                this.ladeAlleZumUnterschreiben();
                this.ladeAlleErstellten();
            });

        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.headline = 'Maßnahmenpläne Rinder';
        this.tableDescriptor.uniqueIdentifier = 'Maßnahmenpläne Rinder';
        this.tableDescriptor.apiUrl =
            this.apiBaseUrl + 'massnahmenplan_rind/';
        // this.tableDescriptor.route = 'massnahmenplaenerind/';
        this.tableDescriptor.detailsRoute = '../massnahmenplaenerind/';
        this.tableDescriptor.nameOfIdField = 'mnp_rind_id';
        this.tableDescriptor.searchPlaceholder = 'Maßnahmenpläne durchsuchen';
        this.tableDescriptor.showDetailsAsOption = true;

        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.createAllowed = true;

        this.tableDescriptor.filterItems = this.getFilterItems();

        this.tableDescriptor.addToolbarButton(
            new ToolbarButton('csv_upload', 'file_upload', 3607, 'CSV Upload')
        );
        this.tableDescriptor.addToolbarButton(
            new ToolbarButton(
                'anlage_a',
                'attachment',
                3605,
                'Anlage A herunterladen'
            )
        );

        this.tableDescriptor.columns.push(
            new TableColumn('mnp_rind_id', 'Nr.')
        );
        this.tableDescriptor.columns.push(new TableColumn('kunde', 'Kunde'));
        this.tableDescriptor.columns.push(
            new TableColumn('zusatz', 'Kundenzusatz')
        );

        this.tableDescriptor.columns.push(new TableColumn('_0_jahr', 'Jahr'));
        this.tableDescriptor.columns.push(
            new TableColumn('_0_halbjahr', 'Kalenderhalbjahr')
        );

        this.tableDescriptor.columns.push(new TableColumn('tierart', 'Tierart'));
        this.tableDescriptor.columns.push(new TableColumn('status', 'Status'));
        this.tableDescriptor.columns.push(new KieneBooleanTableColumn('abgabebeleg_id', 'Abgerechnet'));
        this.tableDescriptor.columns.push(new KieneBooleanTableColumn('unterschrift_kunde', 'Unterschrieben'));
        this.tableDescriptor.columns.push(
            new TableColumn('editiert', 'Editiert', 'datetime')
        );
        this.tableDescriptor.columns.push(new TableColumn('pdf_id', '', 'pdf'));
        this.tableDescriptor.columns.push(new EmailGesendetTableColumn());
        this.tableDescriptor.columns.push(new GedrucktTableColumn());

        this.tableDescriptor.options.push(
            new TableOption('duplicate', 'duplizieren', 'file_copy', 1156)
        );
        this.tableDescriptor.options.push(
            new TableOption('delete', 'löschen', 'delete', 1154)
        );
        if (this.localStorageService.isCurrentUserKundeAdmin() || this.localStorageService.isCurrentUserViehhaendlerAdmin()) {
            console.log('unterschreiben ja nein')
            console.log('current user has mnp rind editieren permission: ', this.localStorageService.hasPermission(1153));
            this.tableDescriptor.options.push(
                new TableOption('sign', 'unterschreiben', 'edit_note', 1153, {
                    conditions: [
                        new Condition('unterschrift_kunde', Operator.KLEINER, 1),
                        new Condition('tierarzt_id', Operator.EXISTIERT, null)
                        // new Condition('tierarzt_id', Operator.EXISTIERT, undefined),
                    ]
                })
            );
        }
        this.tableDescriptor.options.push(
            new TableOption('reopen', 'wieder öffnen', 'lock_open', 1157, {
                conditions: [
                    new Condition('mnp_kurz', Operator.UNGLEICH, 1),
                ]
            })
        );
        this.tableDescriptor.options.push(
            new TableOption(null, 'Drucken', 'print', 1158, {
                void: this.gedruckt.bind(this),
                conditions: [new Condition('pdf_id', Operator.EXISTIERT, null)]
            })
        );

        this.tableDescriptor.permissionCreate = 1152;
        this.tableDescriptor.permissionUpdate = 1153;
        this.tableDescriptor.permissionDelete = 1154;

        this.tableDescriptor.initialSortColumn = 'mnp_rind_id';
        this.tableDescriptor.initialSortDirection = 'desc';

        this.ladeAlleErstellten();
        this.ladeAlleZumUnterschreiben();
    }

    gedruckt(mnp: MassnahmenplanRind) {
        this.api.get(this.apiBaseUrl + 'massnahmenplan_rind/gedruckt.php', new HttpParams().set('mnp_rind_id', mnp.mnp_rind_id)).subscribe({
            next: () => {
                this.tableService.reload('gedruckt');
                this.mnpRindService.showPdf(mnp);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    ladeBenutzerprofil() {
        this.api.get(this.apiBaseUrl + 'benutzerprofil/read_one_kundenportal.php').subscribe({
            next: response => {
                this.benutzerProfil = <Benutzerprofil>response;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }


    getFilterItems() {
        const filterItems = [];
        if (this.localStorageService.getCurrentUser().tierarzt_id) {
            filterItems.push(new FilterItem('Nur meine Tierarztgruppen', 1, FilterItemType.CHECKBOX, 'meinetierarztgruppen', false));
        }
        return filterItems;
    }

    hasPermission(permissionId): boolean {
        return this.localStorageService.currentUserHasPermission(permissionId);
    }

    catchOptionEvent(event: TableOptionEvent) {
        const mpr: MassnahmenplanRind = event.value;
        if (event.name === 'details') {
            if (
                event.value.mnp_kurz !== 1 &&
                event.value.status_id !== MassnahmenplanStatus.ABGESCHLOSSEN
            ) {
                this.router
                    .navigate(
                        [
                            '../massnahmenplaenerind',
                            event.value[this.tableDescriptor.nameOfIdField],
                        ],
                        {
                            relativeTo: this.route,
                        }
                    )
                    .then();
            } else {
                this.mnpRindService.showPdf(mpr);
            }
        } else if (event.name === 'add') {
            this.addMnp();
        } else if (event.name === 'showPdf') {
            this.mnpRindService.showPdf(mpr);
        } else if (event.name === 'duplicate') {
            this.duplicate(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        } else if (event.name === 'reopen') {
            this.reopen(event.value);
        } else if (event.name === 'anlage_a') {
            this.downloadAnlageA();
        } else if (event.name === 'csv_upload') {
            this.openUploadCsvDialog();
        } else if (event.name === 'sign') {
            this.signMnp(mpr);
        }
    }

    downloadAnlageA() {
        const client = this.localStorageService.getCurrentClient();
        if (!client) {
            this.messageService.alertMessage(
                'Bitte zuerst einen Betrieb auswählen!'
            );
        }

        let jahr = new Date().getFullYear();
        let halbjahr = new Date().getMonth() < 6 ? 1 : 2;

        halbjahr = halbjahr - 1;
        if (halbjahr <= 0) {
            halbjahr = 2;
            jahr = jahr - 1;
        }

        const input = new ConfirmWithOptionDialogInput();
        input.headline = 'Anlage A herunterladen';
        input.text = 'Für ' + jahr + '/' + halbjahr;
        input.label = 'Alter';
        input.okButton = 'Herunterladen';
        input.addOption(new KieneKeyValue('46', 'Kälber (zugekauft)'));
        input.addOption(new KieneKeyValue('47', 'Milchkühe'));

        const dialogRef = this.dialog.open(ConfirmWithOptionDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmWithOptionDialogResult) => {
                if (result?.confirmed) {
                    const tierart_id = Number(result.option.key);

                    this.mnpRindService.openAnlageA(
                        client.kunde_id,
                        jahr,
                        halbjahr,
                        tierart_id,
                        true,
                        false,
                        false
                    );
                }
            },
        });
    }

    addMnp() {
        this.router.navigate(['belege', 'massnahmenplaenerind', 'neu']).then();
    }

    openUploadCsvDialog() {
        const dialogRef = this.dialog.open(CsvUploadDialogComponent);
        dialogRef.afterClosed().subscribe(() => {
            this.tableService?.reload();
        });
    }

    reopen(mpr: MassnahmenplanRind) {
        this.api
            .post(
                this.apiBaseUrl + 'massnahmenplan_rind/korrigieren.php',
                mpr,
                null
            )
            .subscribe({
                next: () => {
                    this.messageService.infoMessage(
                        'Massnahmenplan wieder geöffnet!'
                    );
                    this.tableService.reload();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Massnahmenplan konnte nicht wieder geöffnet werden!',
                        err
                    );
                },
            });
    }

    duplicate(mpr: MassnahmenplanRind) {
        if (mpr.mnp_kurz === 1) {
            mpr.mnp_rind_id = undefined;
            const dialogRef = this.dialog.open(
                MassnahmenplanRindKurzDialogComponent,
                {
                    data: {
                        mpr: mpr,
                    },
                }
            );
            dialogRef.afterClosed().subscribe({
                next: (mnp: MassnahmenplanRind) => {
                    if (mnp) {
                        this.api
                            .updateGeneric(
                                this.apiBaseUrl + 'massnahmenplan_rind/update.php',
                                mnp
                            )
                            .subscribe({
                                next: () => {
                                    this.messageService.infoMessage(
                                        'Der Maßnahmenplan wurde erfolgreich dupliziert!'
                                    );
                                    this.tableService.reload();
                                },
                                error: (err) => {
                                    this.messageService.errorMessage(
                                        'Fehler!',
                                        err
                                    );
                                },
                            });
                    }
                },
            });
        } else {
            this.api
                .duplicateEntity(this.apiBaseUrl + 'massnahmenplan_rind/duplicate.php', mpr)
                .subscribe({
                    next: (response) => {
                        this.messageService.infoMessage(
                            'Der Maßnahmenplan wurde erfolgreich dupliziert!'
                        );
                        this.router
                            .navigate([
                                'belege',
                                'massnahmenplaenerind',
                                response.mnp_rind_id,
                            ])
                            .then();
                    },
                    error: (error) => {
                        this.messageService.errorMessage(
                            'Der Maßnahmenplan konnte nicht dupliziert werden!',
                            error
                        );
                    },
                });
        }
    }

    openDeleteDialog(mpr: MassnahmenplanRind) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Maßnahmenplan wirklich löschen?',
                text: 'Maßnahmenplan wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.api
                    .deleteEntity(this.apiBaseUrl + 'massnahmenplan_rind/delete.php', mpr)
                    .subscribe({
                        next: () => {
                            this.messageService.infoMessage(
                                'Der Maßnahmenplan wurde erfolgreich gelöscht!'
                            );
                            this.tableService.reload();
                        },
                        error: (error) => {
                            this.messageService.errorMessage(
                                'Der Maßnahmenplan konnte nicht gelöscht werden! ',
                                error
                            );
                        },
                    });
            }
        });
    }

    alleUnterschreiben() {
        if (this.benutzerProfil?.unterschrift_png) {
            this.mnpRindService.ladeAlleZumUnterschreiben().subscribe({
                next: response => {
                    this.mnpRindService.dokumenteUnterschreiben(response.records);
                },
                error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        } else {
            const signature = new Signature();
            signature.benutzer = 1;
            this.unterschriftService.unterschreiben(signature).subscribe({
                next: result => {
                    if (result) {
                        this.api.get(this.apiBaseUrl + 'benutzerprofil/read_one_kundenportal.php').subscribe({
                            next: response => {
                                this.benutzerProfil = <Benutzerprofil>response;
                                this.alleUnterschreiben();
                            },
                            error: err => {
                                this.messageService.errorMessage('Fehler!', err);
                            }
                        });
                    }
                }, error: err => {
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
        }
    }

    alleAbschliessen() {
        this.ladeAlleErstelltenSubscription?.unsubscribe();
        this.ladeAlleErstelltenSubscription = this.mnpRindService.ladeAlleZumAbschliessen().subscribe({
            next: response => {

                for (const plan of response.records) {
                    this.mnpRindService.abschliessen(plan).subscribe({
                        next: innerResponse => {
                            this.tableService.reload();
                            this.tabInfoService.setReload('massnahmenplan_rind/tabinfo.php');
                            this.tabInfoService.setReload('massnahmenplan_rind/tabinfo_gesamt.php');
                        },
                        error: err => {
                            console.error('MNP Rind mit der ID ' + plan.mnp_rind_id + ' konnte nicht abgeschlossen werden: ', err);
                            this.tabInfoService.setReload('massnahmenplan_rind/tabinfo.php');
                            this.tabInfoService.setReload('massnahmenplan_rind/tabinfo_gesamt.php');
                        }
                    });
                }
            }
        });
    }

    private signMnp(mnp: MassnahmenplanRind) {

        if (this.benutzerProfil?.unterschrift_png) {
            this.mnpRindService.dokumentUnterschreiben(mnp);
        } else {
            const signature = new Signature();
            signature.benutzer = 1;
            this.unterschriftService.unterschreiben(signature).subscribe({
                next: result => {
                    if (result) {
                        this.mnpRindService.dokumentUnterschreiben(mnp);
                    }
                }
            });
        }

    }


    private ladeAlleErstellten() {
        this.mnpRindService.ladeAlleZumAbschliessen()?.subscribe({
            next: response => {
                this.anzahlOffenePlaene = response.count;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    private ladeAlleZumUnterschreiben() {
        this.mnpRindService.ladeAlleZumUnterschreiben().subscribe({
            next: response => {
                this.anzahlUnterschreibbarePlaene = response.count;
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}
