import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    ArticleScanSelectedEvent
} from '../../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {MessageService} from '../../../services/message.service';
import {Material} from '../../../warehouse/material/material';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-create-meldungen-gebuehren-dialog',
    templateUrl: './create-meldungen-gebuehren-dialog.component.html',
    styleUrls: ['./create-meldungen-gebuehren-dialog.component.css']
})
export class CreateMeldungenGebuehrenDialogComponent implements OnInit {

    currentYear = new Date().getFullYear();
    halbjahre = [1, 2];
    selectedMaterial: Material;

    jahrCtrl = new UntypedFormControl(this.currentYear, [Validators.min(2000), Validators.maxLength(4)]);
    halbjahrCtrl = new UntypedFormControl();
    belegDatumCtrl = new FormControl();

    constructor(public dialogRef: MatDialogRef<CreateMeldungenGebuehrenDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data,
                private api: KieneTableService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.halbjahrCtrl.setValue(this.aktuellesHalbjahr());
        this.belegDatumCtrl.disable();
        this.belegDatumCtrl.setValue(this.aktuellesBelegdatum());

        this.halbjahrCtrl.valueChanges.subscribe({
            next: () => {
                this.belegDatumCtrl.setValue(this.aktuellesBelegdatum());
            }
        });
        this.jahrCtrl.valueChanges.subscribe({
            next: () => {
                this.belegDatumCtrl.setValue(this.aktuellesBelegdatum());
            }
        });
    }

    aktuellesHalbjahr(): number {
        const now = new Date();
        if (now.getMonth() < 6) {
            return 1;
        } else {
            return 2;
        }
    }

    setMaterial(asse: ArticleScanSelectedEvent) {
        this.selectedMaterial = asse.article;
    }

    close() {
        this.dialogRef.close(null);
    }

    save() {
        let obj = {
            jahr: this.jahrCtrl.value,
            halbjahr: this.halbjahrCtrl.value,
            artikel_id: this.selectedMaterial.artikel_id,
            datum: this.belegDatumCtrl.value
        };

        this.api.post(this.apiBaseUrl + 'abgabebeleg/generieren.php', obj, null).subscribe({
            next: response => {
                this.messageService.infoMessage('Die Belege wurden erfolgreich erzeugt!');
                this.close();
            }, error: error => {
                this.messageService.errorMessage('Beim Erzeugen der Belege ist ein Fehler aufgetreten!', error);
            }
        });
    }

    private aktuellesBelegdatum() {
        if (this.halbjahrCtrl.value === 1) {
            return new Date(this.jahrCtrl.value, 5, 30);
        } else {
            return new Date(this.jahrCtrl.value, 11, 31);
        }
    }
}
