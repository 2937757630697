import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TierRasse} from "../../../../../projects/kiene-core/src/lib/components/gruppe-tier/classes/tier-rasse";
import {environment} from "../../../../environments/environment";
import {KieneTableService} from "../../../components/kiene-table/kiene-table.service";
import {MessageService} from "../../../services/message.service";

@Component( {
    selector: 'kiene-rasse-dialog',
    templateUrl: './rasse-dialog.component.html',
    styleUrls: ['./rasse-dialog.component.scss']
} )
export class RasseDialogComponent implements OnInit {
    rasse: TierRasse;
    rasse_kuerzel_ctrl = new UntypedFormControl();
    rasse_name_ctrl = new UntypedFormControl();
    rasse_geburtsgewicht_ctrl = new UntypedFormControl();
    rasse_tageszunahme_ctrl = new UntypedFormControl();

    constructor(public dialogRef: MatDialogRef<RasseDialogComponent>,
                private api: KieneTableService,
                private messageService: MessageService,
                @Inject( MAT_DIALOG_DATA ) public data: any) {
            console.log(data)
        if ( data.rasse ) {
            this.rasse = data.rasse;
        } else {
            this.rasse = new TierRasse();
        }
    }

    ngOnInit(): void {
        this.rasse_name_ctrl.setValue( this.rasse?.name );
        this.rasse_kuerzel_ctrl.setValue( this.rasse?.kuerzel );
        this.rasse_geburtsgewicht_ctrl.setValue( this.rasse?.geburtsgewicht );
        this.rasse_tageszunahme_ctrl.setValue( this.rasse?.tageszunahme );
    }

    cancel() {
        this.dialogRef.close( null );
    }

    save() {
        this.api.updateEntity( environment.BASE_URI + 'rasse/update.php', this.rasse ).subscribe( saved => {
            this.messageService.infoMessage( 'Die Tierrasse ' + saved.name + ' wurde erfolgreich gespeichert!' );
            this.dialogRef.close( saved );
        }, error => {
            this.messageService.errorMessage( 'Fehler! Die Tierrasse ' + this.rasse.name + ' konnte nicht gespeichert werden! ', error );
        } );
    }
}
