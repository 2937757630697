<div style="padding: 24px">
    <div class="one-row">
        <span class="spacer"></span>
        <kiene-zeitauswahl (change)="zeitintervalChanged($event)"></kiene-zeitauswahl>
        <span class="spacer"></span>
    </div>

    <table class="report-ergebnis-table">
        <thead>
        <tr>
            <th></th>
            <th>Warenwert Gesamt</th>

        </tr>
        </thead>
        <tbody>
        <tr style="font-size: 1.6em; font-weight: bold; color: green;">
            <td style="font-size: 1em; font-weight: bold; color: gray;"></td>
            <td>{{gesamtWarenwert | number: '1.2-2'}} &euro;</td>
        </tr>
        <tr style="font-size: 1em; font-weight: bold; color: gray;">
            <td>{{ vorjahreszeitraum?.von | date: 'dd.MM.yyyy' }}
                bis {{vorjahreszeitraum?.bis | date: 'dd.MM.yyyy'}}</td>
            <td>{{gesamtWarenwertVorjahr | number: '1.2-2'}} &euro;</td>
        </tr>
        </tbody>
    </table>

    <!--    <div class="one-row" style="min-height: 300px">-->
    <!--        <kiene-report-overview-value-card [label]="'Warenwert Gesamt'" [numberValue]="gesamtWarenwert"-->
    <!--                                          [numberFormat]="'1.2-2'" [unit]="'&euro;'"></kiene-report-overview-value-card>-->
    <!--    </div>-->

    <mat-card>
        <div class="one-row">
            <span class="spacer"></span>
            <kiene-csv-export [columns]="csvColumns" [data]="dataSource.data"
                              [filename]="csvFilename"></kiene-csv-export>
        </div>
        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>
            <!-- Name Column -->
            <ng-container matColumnDef="warengruppe">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header">
                    Warengruppe
                </th>
                <td mat-cell *matCellDef="let element" class="number">
                    {{ element.warengruppe }}
                </td>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="warenwert">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header">
                    Warenwert
                </th>
                <td mat-cell *matCellDef="let element" class="number">
                    {{ element.warenwert | number : "1.2-2" }} &euro;
                </td>
            </ng-container>

            <ng-container matColumnDef="artikel">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header">
                    Anz. untersch. Artikel
                </th>
                <td mat-cell *matCellDef="let element" class="number">
                    {{ element.artikel | number : "1.0-2" }} Stk.
                </td>
            </ng-container>

            <ng-container matColumnDef="wareneingang">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="number-header">
                    Wareneingang
                </th>
                <td mat-cell *matCellDef="let element" class="number">
                    {{ element.wareneingang | number : "1.0-2" }} Stk.
                </td>
            </ng-container>

            <ng-container matColumnDef="aggregatedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="aggregated-vetgroup-detail" [@detailExpanded]="
                            element == expandedElement
                                ? 'expanded'
                                : 'collapsed'
                        ">
                        <div *ngIf="isLoading[element.warengruppe_id]" class="one-row">
                            <span class="spacer"></span>
                            <mat-spinner [diameter]="32"></mat-spinner>
                            <span class="spacer"></span>
                        </div>
                        <div *ngIf="!isLoading[element.warengruppe_id]" class="one-row">
                            <span class="spacer"></span>
                            <kiene-csv-export [columns]="csvColumnsDetails" [data]="element.details" [filename]="
                                    'WarengruppenEingang_' +
                                    element.warengruppe +
                                    '.csv'
                                "></kiene-csv-export>
                        </div>
                        <table *ngIf="!isLoading[element.warengruppe_id]" class="details-table"
                               style="table-layout: fixed; padding-bottom: 24px">
                            <tr *ngFor="let d of element.details">
                                <td style="padding-left: 24px">
                                    {{ d.zeitraum }}
                                </td>
                                <td class="number">
                                    {{ d.warenwert | number : "1.2-2" }} &euro;
                                </td>
                                <!-- <td class="number">{{d.artikel | number: '1.0-2'}} Stk.</td> -->
                                <td class="number">
                                    {{ d.wareneingang | number : "1.0-2" }} Stk.
                                </td>
                            </tr>
                        </table>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns" class="vet-group-row"
                [class.vet-group-expanded-row]="expandedElement === element" (click)="expandVetGroupRow(element)"></tr>
            <tr mat-row *matRowDef="let row; columns: ['aggregatedDetail']" class="aggregated-row"></tr>
        </table>
    </mat-card>

    <mat-card style="margin-bottom: 24px; margin-top: 24px; height: 600px">
        <google-chart style="width: 100%" [type]="chartType" [columns]="chartColumns" [data]="chartData" [height]="600"
                      [options]="{ pieHole: 0.4, title: 'Wareneinsatz Warengruppen' }">
        </google-chart>
    </mat-card>
</div>
