import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User, UserAdmin } from '../../administration/user/user';
import { KieneApi } from '../../components/kiene-table/kiene-api';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import { environment } from '../../../environments/environment';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'kiene-change-user-email-account-dialog',
  templateUrl: './change-user-email-account-dialog.component.html',
  styleUrls: ['./change-user-email-account-dialog.component.scss']
})
export class ChangeUserEmailAccountDialogComponent implements OnInit {

  user: User;
  userAdmin = new UserAdmin();
  emailKopie = false;

  constructor(public dialogRef: MatDialogRef<ChangeUserEmailAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: KieneTableService,
    private notificationService: MessageService) {
    this.user = data.user;
  }

  ngOnInit() {
    this.loadUserAdmin();
  }

  loadUserAdmin() {
    this.api.getOne(environment.BASE_URI + 'benutzer_admin/read_one.php', 'benutzer_id', this.user.benutzer_id.toString()).subscribe(response => {
      this.userAdmin = response;
      if (this.userAdmin.email_kopie === 1) {
        this.emailKopie = true;
      } else {
        this.emailKopie = false;
      }
    });
  }

  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    if (!this.userAdmin.benutzer_id) {
      this.userAdmin.benutzer_id = this.user.benutzer_id;
    }
    if (this.emailKopie === true) {
      this.userAdmin.email_kopie = 1;
    } else {
      this.userAdmin.email_kopie = 0;
    }
    this.api.updateEntity(environment.BASE_URI + 'benutzer_admin/update.php', this.userAdmin).subscribe(response => {
      this.notificationService.infoMessage('Die E-Mail Account Daten wurden erfolgreich gespeichert!');
      this.dialogRef.close(true);
    }, error => {
      this.notificationService.errorMessage('Die E-Mail Account Daten konnten nicht gepeichert werden! ', error);
      this.dialogRef.close(false);
    });
  }

}
