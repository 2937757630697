import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Component, Inject, OnInit} from '@angular/core';
import {Futtermittellieferant} from '../lieferant';

@Component({
    selector: 'kiene-lieferant-kunden-nr-verwalten-dialog',
    templateUrl: './lieferant-kunden-nr-verwalten-dialog.component.html',
    styleUrls: ['./lieferant-kunden-nr-verwalten-dialog.component.scss']
})
export class LieferantKundenNrVerwaltenDialogComponent implements OnInit {

    futtermittellieferant: Futtermittellieferant;

    constructor(
        public dialogRef: MatDialogRef<LieferantKundenNrVerwaltenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.futtermittellieferant = data.futtermittellieferant;
    }

    ngOnInit(): void {
    }

}
