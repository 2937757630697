import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kiene-informationen',
  templateUrl: './informationen.component.html',
  styleUrls: ['./informationen.component.scss']
})
export class InformationenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
