<h1 mat-dialog-title>Import Optionen</h1>
<div mat-dialog-content>
    <mat-form-field style="width: 100%;">
        <mat-label>Einstalldatum</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="einstalldatumCtrl" [readonly]="true">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="width: 100%;">
        <mat-label>Gewicht gesamt</mat-label>
        <input type="number" matInput inputmode="decimal" [formControl]="gewichtCtrl">
    </mat-form-field>
    <mat-form-field style="width: 100%; margin-bottom: 24px;">
        <mat-label>KFZ-Kennzeichen</mat-label>
        <input matInput [formControl]="kfzCtrl"/>
        <mat-hint>KFZ Kennzeichen des Transportfahrzeugs</mat-hint>
    </mat-form-field>
    <mat-divider></mat-divider>
    <ng-container *ngIf="!istDerselbeKunde()">
        <p>Sollen die Tiere in HI-Tier als
            Zugang gemeldet werden?</p>
        <div style="width: 100%; margin-bottom: 24px;">
            <mat-radio-group aria-label="Abgang an HIT melden ja oder nein" [formControl]="hitMeldenCtrl">
                <mat-radio-button [value]="0">nein</mat-radio-button>
                <mat-radio-button [value]="1">ja</mat-radio-button>
            </mat-radio-group>
        </div>
        <mat-divider></mat-divider>

        <p>H&auml;ndler ausw&auml;hlen, der f&uuml;r alle Tiere dieser Liste hinterlegt werden soll. (optional)</p>
        <span *ngIf="tierliste?.haendler_uebernehmen > 0" class="info-box">Der Ersteller der Tierliste ({{
                tierliste.kunde_abgang
            }}
            ) hat angegeben, dass die H&auml;ndler der einzelnen Tiere mit &uuml;bergeben werden. Wenn hier ein anderer
            H&auml;ndler ausgew&auml;hlt wird, werden diese &uuml;berschrieben.</span>
        <kiene-haendler-selector [disabled]="hitMeldenCtrl.value === 0"
                                 (haendlerSelected)="setHaendler($event)"></kiene-haendler-selector>
    </ng-container>
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</div>
