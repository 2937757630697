import {Inject, Injectable} from '@angular/core';
import {API_BASE_URL_SERVICE} from '../../kiene-core.config';
import {MessageService} from '../message-service/message.service';
import {HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class KieneFileService {
    apiBaseUrl: string;

    constructor(
        @Inject(API_BASE_URL_SERVICE) apiBaseUrl,
        private messageService: MessageService,
        private http: HttpClient
    ) {
        this.apiBaseUrl = apiBaseUrl;
    }

    downloadZip(url: string, params: HttpParams) {
        this.http
            .get(url, {
                params: params,
                responseType: 'blob',
                observe: 'response'
            })
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.openDownload(response, 'application/zip');
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fehler beim Download der ZIP Datei!',
                        err
                    );
                },
            });
    }

    openDownload(backendResponse: HttpResponse<Blob>, mimeType: string) {
        const blob = new Blob([backendResponse.body], {
            type: mimeType,
        });
        if (blob.size <= 0) {
            this.messageService.alertMessage('Keine Daten für Download gefunden!');
            return;
        }
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;

        const regex = /filename="(?<filename>.*)"/;
        const erg = backendResponse.headers
            .get('Content-Disposition')
            .match(regex);
        if (erg && erg.groups['filename']) {
            link.setAttribute('download', erg.groups['filename']);
        } else {
            link.setAttribute('download', 'Fehler.vetvet');
        }

        if (typeof link.download === 'undefined') {
            link.setAttribute('target', '_blank');
        }
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
        }, 100);
    }

    downloadPdfFile(base64: string) {
        const pdfBlob = new Blob([this.b64toBlobPart(base64)], {
            type: 'application/pdf',
        });
        if (pdfBlob.size > 0) {
            const urlToOpen =
                window.URL.createObjectURL(pdfBlob) + '#view=FitH';
            window.open(urlToOpen);
        } else {
            this.messageService.hintMessage(
                'Es wurden keine passende PDFs gefunden!'
            );
        }
    }

    downloadPdf(url: string, params: HttpParams, per_mail: boolean) {
        if (per_mail === true) {
            if (!params) {
                params = new HttpParams();
            }
            params = params.append('mail', '1');
        }

        this.http
            .get(this.apiBaseUrl + url, {
                responseType: 'blob',
                params: params,
            })
            .subscribe({
                next: (response) => {
                    if (per_mail === true) {
                        this.messageService.infoMessage(
                            'Die Datei wurde per Mail an Sie versendet!'
                        );
                    } else {
                        const pdfBlob = new Blob([response], {
                            type: 'application/pdf',
                        });
                        if (pdfBlob.size > 0) {
                            const urlToOpen =
                                window.URL.createObjectURL(pdfBlob) +
                                '#view=FitH';
                            window.open(urlToOpen);
                        } else {
                            this.messageService.hintMessage(
                                'Es wurden keine passende PDFs gefunden!'
                            );
                        }
                    }
                },
                error: (error) => {
                    console.error(error);
                    this.messageService.errorMessage(
                        'Es ist ein Fehler beim Laden der PDF aufgetreten! ',
                        error
                    );
                },
            });
    }

    b64toBlobPart(base64) {
        const raw = window.atob(base64);
        const ab = new ArrayBuffer(raw.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < raw.length; i++) {
            ia[i] = raw.charCodeAt(i);
        }
        return ab;
    }

    public downloadCSV(
        url: string,
        params: HttpParams,
        options?: { columnsCommaSeparated: string }
    ) {
        let headers = new HttpHeaders();
        headers = headers.set('ACCEPT', 'csv');
        if (options?.columnsCommaSeparated) {
            headers = headers.set(
                'Table-Columns',
                options?.columnsCommaSeparated
            );
        }
        this.http
            .get(url, {
                params: params,
                responseType: 'blob',
                observe: 'response',
                headers: headers,
            })
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.openDownload(response, 'text/csv');
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fehler beim Download der CSV Datei!',
                        err
                    );
                },
            });
    }

    public downloadExcel(
        url: string,
        params: HttpParams,
        options?: { columnsCommaSeparated: string }
    ) {
        let headers = new HttpHeaders();
        headers = headers.set('ACCEPT', 'excel');
        if (options?.columnsCommaSeparated) {
            headers = headers.set(
                'Table-Columns',
                options?.columnsCommaSeparated
            );
        }
        this.http
            .get(url, {
                params: params,
                responseType: 'blob',
                observe: 'response',
                headers: headers,
            })
            .subscribe({
                next: (response) => {
                    if (response) {
                        this.openDownload(response, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
                    }
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fehler beim Download der Excel Datei!',
                        err
                    );
                },
            });
    }

    download(
        url: string,
        params: HttpParams
    ): Observable<Blob> {
        const subject = new Subject<Blob>();

        if (!params) {
            params = new HttpParams();
        }

        this.http
            .get(this.apiBaseUrl + url, {
                params: params,
                responseType: 'blob',
                reportProgress: true,
                observe: 'events',
            }).subscribe({
            next: (resp) => {
                if (resp.type === HttpEventType.DownloadProgress) {
                    const percentDone = Math.round(100 * resp.loaded / resp.total);
                    console.log(percentDone);
                }
                if (resp.type === HttpEventType.Response) {
                    const header = resp.headers;
                    const blob = new Blob([resp.body]);
                    subject.next(blob);

                }
            },
            error: (err) => {
                this.messageService.errorMessage(
                    `Datei konnte nicht heruntergeladen werden!`,
                    err
                );
            },
        });
        return subject.asObservable();
    }

    convertBlobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result as string;
                resolve(base64String);
            };
            reader.onerror = () => {
                reject('Error converting Blob to Base64');
            };
            reader.readAsDataURL(blob);
        });
    }
}
