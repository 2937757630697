import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';

export class InitMiniTable {
    /** Titel der Tabelle */
    title: string;
    /** Array aus Namen für die Spaltenüberschriften */
    columnNames: string[];
    /** Array aus Namen der anzuzeigenden Attribute der Objekte. */
    attributes: string[];
    /** Array aus Objekten, die in der Tabelle dargestellt werden sollen. */
    dataList: any[];
    /** Länge der eingegebenen Liste */
    listeLaenge: number;
    /** Soll der Button, um der Table ein Element hinzuzufügen, versteckt werden.*/
    hideAddButton = true;
    /** Soll der Abbrechen-Button ausgeblendet werden? */
    hideCloseButton? = false;
    /** Die Möglichkeit in der Tabelle mit den Pfeiltasten zu navigieren, kann hier abgestellt werden.*/
    disableKeyControl: boolean;
    /** Soll die Spalte mit Buttons zum Editieren und Löschen versteckt werden? */
    addActionButtons? = false;
    /** Code für die Permission ein Element zu löschen. */
    kienePermissionDeleteItem: number;
    /** Hier wird der Name des Feldes für die ID eines Elementes übergeben. Ist notwendig zum Löschen. */
    attributeID: string;
    /** Wenn es ein Attribut gibt, welches in der Tabelle nicht so angezeigt werden soll, wie es im Objekt gespeichert
     * wird, kann man hier die veränderten Values übergeben. */
    modifiedAttributes?: ModifiedAttribute[];
}

export class ModifiedAttribute {
    /** Name des Attributes*/
    attribute: string;
    /** Die veränderten Values*/
    values: any[];
}

@Component({
    selector: 'kiene-kiene-mini-table',
    templateUrl: './kiene-mini-table.component.html',
    styleUrls: ['./kiene-mini-table.component.scss'],
})
export class KieneMiniTableComponent implements OnInit, OnChanges {
    @Input() initMiniTable = new InitMiniTable();
    currentIndex = 0;
    rowHandler = Array<boolean>();
    @Output() selectedElement = new EventEmitter<any>();
    @Output() addButton = new EventEmitter<any>();
    @Output() closeDialog = new EventEmitter<any>();
    @Output() deleteItem = new EventEmitter<any>();

    ngOnChanges(changes: SimpleChanges) {
        if (changes.initMiniTable && !changes.initMiniTable.firstChange) {
            this.checkInitMiniTable();
        }
    }

    ngOnInit(): void {
        this.checkInitMiniTable();
    }

    checkInitMiniTable() {
        if (
            !this.initMiniTable.disableKeyControl &&
            this.initMiniTable.disableKeyControl !== undefined
        ) {
            this.rowHandler[0] = true;
        }
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (!this.initMiniTable.disableKeyControl) {
            switch (event.key) {
                case 'ArrowDown':
                    if (this.currentIndex < this.initMiniTable.listeLaenge) {
                        this.nextRow();
                    } else {
                        this.firstRow();
                    }
                    break;
                case 'ArrowUp':
                    if (this.currentIndex > 0) {
                        this.lastRow();
                    } else {
                        this.endRow();
                    }
                    break;
                case 'Tab':
                    event.stopPropagation();
                    if (this.currentIndex < this.initMiniTable.listeLaenge) {
                        this.nextRow();
                    } else {
                        this.firstRow();
                    }
                    break;
                case 'Enter':
                    if (this.initMiniTable.listeLaenge) {
                        event.stopPropagation();
                        this.confirm();
                    }
                    break;
            }
        }
    }

    nextRow() {
        this.rowHandler[this.currentIndex] = false;
        this.currentIndex++;
        this.rowHandler[this.currentIndex] = true;
    }

    lastRow() {
        this.rowHandler[this.currentIndex] = false;
        this.currentIndex--;
        this.rowHandler[this.currentIndex] = true;
    }

    firstRow() {
        this.rowHandler[this.currentIndex] = false;
        this.currentIndex = 0;
        this.rowHandler[this.currentIndex] = true;
    }

    endRow() {
        this.rowHandler[this.currentIndex] = false;
        this.currentIndex = this.initMiniTable.listeLaenge;
        this.rowHandler[this.currentIndex] = true;
    }

    confirm(element?: any) {
        if (!this.initMiniTable.disableKeyControl) {
            if (this.currentIndex === this.initMiniTable.listeLaenge) {
                this.selectedElement.emit();
                return;
            } else {
                if (!element) {
                    this.selectedElement.emit(
                        this.initMiniTable.dataList[this.currentIndex]
                    );
                    return;
                }
            }
        }
        this.selectedElement.emit(element);
    }

    addClick() {
        this.addButton.emit();
    }

    abort() {
        this.closeDialog.emit();
    }

    deleteClick(element_id: number) {
        this.deleteItem.emit(element_id);
    }
}
