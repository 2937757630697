import { Charge } from "../../warehouse/charges/charges.component";

export class Invoice {
  rechnung_id: number;
  rechnung_nr: number;
  //gruppe_id: number;
  //gruppe: string;
  preis: number;
  //mwst19: number;
  //mwst07: number;
  mwst_gesamt: number;
  skonto: number;
  pdf_id: number;
  kunde: string;
  kunde_id: number;
  rechnungsdatum: Date;
  status: string;
  status_id: number;
  //abgabe_mwst07: number;
  //abgabe_mwst19: number;
  abgabe_preis: number;
  //behandlung_mwst07: number;
  //behandlung_mwst19: number;
  behandlung_preis: number;
  positionen: InvoicePosition[] = [];
  primanota_id: number;
  gesamt_brutto: number;
  gesamt_netto: number;
  gutschrift_datum: Date;
  rechnung_id_neu: number;
  kundengutschrift_id: number;
  kunde_nr_intern: number;
  gruppe: string;
  strasse: string;
  plz: string;
  ort: string;
  zusatz: string;
  vvvo: string;
}

export class InvoicePosition {
  ra_id: number;
  rechnung_id: number;
  abgabe: number;
  artikel: string;
  artikel_id: number;
  artikelmenge: number;
  behandlung: number;
  datum: Date;
  einzelpreis: number;
  gesamtpreis: number;
  //mwst19: number;
  //mwst07: number;
  mwstsatz: number;
  mwst: number;
  gutschrift: number;
  aa_id: number; //abgabebeleg
  ba_id: number; //befundmitteilung
  chargen: Charge[] = [];
  abgabebeleg_id: number;
  abgabebeleg: string;
  befund_id: number;
  befund: string;
  position: number;
  abgabebeleg_nr: string;
  tagebuch_nr: string;
}
