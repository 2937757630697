import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { MessageService } from '../../../services/message.service';
import { UntypedFormControl } from "@angular/forms";
import { API_BASE_URL_SERVICE } from "../../../../../projects/kiene-core/src/lib/kiene-core.config";
import { Entsorger, KundenNrVvvo } from '../../../../../projects/kiene-core/src/lib/components/stammdaten/entsorger/entsorger';

@Component({
    selector: 'kiene-entsorger-kunden-nr-nachtragen',
    templateUrl: './entsorger-kunden-nr-nachtragen.component.html',
    styleUrls: ['./entsorger-kunden-nr-nachtragen.component.scss']
})
export class EntsorgerKundenNrNachtragenComponent implements OnInit {

    vvvo: string;
    kundeId: number;
    entsorger: Entsorger;
    kundenNrVvvoCtrl: UntypedFormControl = new UntypedFormControl();

    constructor(public dialogRef: MatDialogRef<EntsorgerKundenNrNachtragenComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        @Inject(API_BASE_URL_SERVICE) private baseUrl,
        private api: KieneTableService,
        private messageService: MessageService) {
        if (data.vvvo) { this.vvvo = data.vvvo; }
        if (data.kundeId) { this.kundeId = data.kundeId; }
        if (data.entsorger) { this.entsorger = data.entsorger; }
    }

    speichern() {
        if (!this.entsorger.kundenNrVvvos) {
            this.entsorger.kundenNrVvvos = [];
        }
        this.entsorger.kundenNrVvvos.push(new KundenNrVvvo(this.kundeId, this.kundenNrVvvoCtrl.value, this.vvvo));
        this.api.updateGeneric(
            `${this.baseUrl}entsorger/update_kundenportal.php`,
            this.entsorger
        ).subscribe({
            next: resp => {
                this.messageService.infoMessage('Kundennr. erfolgreich nachgetragen.');
                this.dialogRef.close(resp);
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Aktualisieren des Entsorgers!', err);
            }
        });
    }

    ngOnInit(): void {
    }

}
