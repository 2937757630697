<h1 mat-dialog-title>Nullmeldungen generieren</h1>
<div mat-dialog-content>
    <div class="alert">
        <div class="one-row">
            <mat-icon style="margin-right: 12px;">warning</mat-icon>
            <p>Bitte achten Sie darauf alle Meldungen f&uuml;r das Halbjahr erzeugt zu haben, bevor Sie die Nullmeldungen generieren!</p>
        </div>
    </div>
    <mat-form-field style="margin-top: 24px;">
        <mat-label>Jahr</mat-label>
        <mat-select [formControl]="jahrCtrl">
            <mat-option *ngFor="let y of years" [value]="y">{{y}}</mat-option>
        </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field>
        <mat-label>Halbjahr</mat-label>
        <mat-select [formControl]="halbjahrCtrl">
            <mat-option [value]="1">1</mat-option>
            <mat-option [value]="2">2</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Generieren</button>
</div>