<mat-icon
    *ngIf="icon"
    [matBadge]="info"
    matBadgePosition="before"
    matBadgeOverlap="false"
    matBadgeColor="warn"
>{{ icon }}
</mat-icon
>
<span
    *ngIf="!icon"
    [matBadge]="info"
    matBadgePosition="before"
    matBadgeOverlap="false"
    matBadgeColor="warn"
></span>
