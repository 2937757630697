import {NgModule} from '@angular/core';

import {WarehouseRoutingModule} from './warehouse-routing.module';
import {ChargeService} from './charges/charge.service';
import {ChargesComponent} from './charges/charges.component';
import {GoodsReceiptComponent} from './goods-receipt/goods-receipt.component';
import {GoodsReceiptHistoryComponent} from './goods-receipt-history/goods-receipt-history.component';
import {GoodsReceiptHistoryService} from './goods-receipt-history/goods-receipt-history.service';
import {
    CorrectAmountDialogComponent
} from './goods-receipt-history/correct-amount-dialog/correct-amount-dialog.component';
import {CorrectOrderDialogComponent} from './goods-receipt-history/correct-order-dialog/correct-order-dialog.component';
import {MaterialComponent} from './material/material.component';
import {MaterialService} from './material/material.service';
import {ArtikelAnwendungComponent} from './material/artikel-anwendung/artikel-anwendung.component';
import {
    ArtikelAnwendungDialogComponent
} from './material/artikel-anwendung/artikel-anwendung-dialog/artikel-anwendung-dialog.component';
import {ArtikelIndikationComponent} from './material/artikel-indikation/artikel-indikation.component';
import {
    AddIndikationToMaterialDialogComponent
} from './material/artikel-indikation/add-indikation-to-material-dialog/add-indikation-to-material-dialog.component';
import {ArtikelTierartComponent} from './material/artikel-tierart/artikel-tierart.component';
import {
    AddTierartToArticleDialogComponent
} from './material/artikel-tierart/add-tierart-to-article-dialog/add-tierart-to-article-dialog.component';
import {MaterialDetailsComponent} from './material/material-details/material-details.component';
import {MaterialPricesComponent} from './material/material-prices/material-prices.component';
import {MaterialPricesService} from './material/material-prices/material-prices.service';
import {RabattStaffelService} from './material/rabatt-staffel/rabatt-staffel.service';
import {RabattStaffelComponent} from './material/rabatt-staffel/rabatt-staffel.component';
import {StockService} from './stock/stock.service';
import {StorageService} from './storage/storage.service';
import {StoragePlaceService} from './storage-place/storage-place.service';
import {SupplierService} from './supplier/supplier.service';
import {WarengruppeService} from './warengruppe/warengruppe.service';
import {SupplierComponent} from './supplier/supplier.component';
import {SupplierDetailsComponent} from './supplier/supplier-details/supplier-details.component';
import {WarehouseComponent} from './warehouse.component';
import {StorageComponent} from './storage/storage.component';
import {StoragePlaceComponent} from './storage-place/storage-place.component';
import {SupplierMaterialComponent} from './supplier-material/supplier-material.component';
import {AddSupplierMaterialComponent} from './supplier-material/add-supplier-material/add-supplier-material.component';
import {
    SupplierMaterialSelectorComponent
} from './supplier-material/supplier-material-selector/supplier-material-selector.component';
import {WarengruppeComponent} from './warengruppe/warengruppe.component';
import {
    AddStoragePlaceDialogComponent
} from './storage-place/add-storage-place-dialog/add-storage-place-dialog.component';
import {
    EditStoragePlaceDialogComponent
} from './storage-place/edit-storage-place-dialog/edit-storage-place-dialog.component';
import {KieneTableModule} from '../components/kiene-table/kiene-table.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {SupplierMaterialService} from './supplier-material/supplier-material.service';
import {AddVetStockDialogComponent} from './stock/add-vet-stock-dialog/add-vet-stock-dialog.component';
import {BestandComponent, BookLossDialog, CorrectStockDialog, TransferStockDialog} from './bestand/bestand.component';
import {TierarztBestandComponent} from './tierarzt-bestand/tierarzt-bestand.component';
import {StuecklisteComponent} from './material/stueckliste/stueckliste.component';
import {AnwendungsSelectorModule} from '../components/anwendungs-selector/anwendungs-selector.module';
import {DiagnoseSelectorModule} from '../components/diagnose-selector/diagnose-selector.module';
import {TierartSelectorModule} from '../components/tierart-selector/tierart-selector.module';
import {ArticleScanAutocompleteModule} from '../components/article-scan-autocomplete/article-scan-autocomplete.module';
import {DialogsModule} from '../dialogs/dialogs.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CreateChargeDialogComponent} from './charges/create-charge-dialog/create-charge-dialog.component';

import {SonderpreisDialogComponent} from './material/sonderpreis-dialog/sonderpreis-dialog.component';
import {MaterialWartezeitComponent} from './material/material-wartezeit/material-wartezeit.component';
import {
    WartezeitSonderfallDialogComponent
} from './material/material-wartezeit/wartezeit-sonderfall-dialog/wartezeit-sonderfall-dialog.component';
import {InventurModule} from './inventur/inventur.module';
import {
    ReturnDeliveryDialogComponent
} from './goods-receipt-history/return-delivery-dialog/return-delivery-dialog.component';
import {WarenruecklieferungenComponent} from './warenruecklieferungen/warenruecklieferungen.component';
import {CommonModule} from '@angular/common';
import {MatTabsModule} from '@angular/material/tabs';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatChipsModule} from '@angular/material/chips';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSliderModule} from '@angular/material/slider';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {KieneAutocompleteModule} from '../components/kiene-autocomplete/kiene-autocomplete.module';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {ComponentsModule} from '../components/components.module';
import {DirectivesModule} from '../../../projects/kiene-core/src/lib/directives/directives.module';
import {
    CreateRuecklieferungDialogComponent
} from './warenruecklieferungen/create-ruecklieferung-dialog/create-ruecklieferung-dialog.component';
import { GoodsReceiptAddArticleComponent } from './goods-receipt/goods-receipt-add-article/goods-receipt-add-article.component';

@NgModule({
    declarations: [
        WarehouseComponent,
        ChargesComponent,
        GoodsReceiptComponent,
        GoodsReceiptHistoryComponent,
        CorrectAmountDialogComponent,
        CorrectOrderDialogComponent,
        MaterialComponent,
        ArtikelAnwendungComponent,
        ArtikelAnwendungDialogComponent,
        ArtikelIndikationComponent,
        AddIndikationToMaterialDialogComponent,
        ArtikelTierartComponent,
        AddTierartToArticleDialogComponent,
        MaterialDetailsComponent,
        MaterialPricesComponent,
        RabattStaffelComponent,
        SupplierComponent,
        SupplierDetailsComponent,
        StorageComponent,
        StoragePlaceComponent,
        TransferStockDialog,
        BookLossDialog,
        CorrectStockDialog,
        SupplierMaterialComponent,
        AddSupplierMaterialComponent,
        SupplierMaterialSelectorComponent,
        WarengruppeComponent,
        AddStoragePlaceDialogComponent,
        EditStoragePlaceDialogComponent,
        AddVetStockDialogComponent,
        BestandComponent,
        TierarztBestandComponent,
        StuecklisteComponent,
        CreateChargeDialogComponent,
        SonderpreisDialogComponent,
        MaterialWartezeitComponent,
        WartezeitSonderfallDialogComponent,
        ReturnDeliveryDialogComponent,
        WarenruecklieferungenComponent,
        CreateRuecklieferungDialogComponent,
        GoodsReceiptAddArticleComponent,
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatCardModule,
        MatMenuModule,
        MatChipsModule,
        MatToolbarModule,
        MatExpansionModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatPaginatorModule,
        MatRadioModule,
        MatDividerModule,
        KieneAutocompleteModule,
        KieneTableModule,
        WarehouseRoutingModule,
        MatSlideToggleModule,
        AnwendungsSelectorModule,
        DiagnoseSelectorModule,
        TierartSelectorModule,
        ArticleScanAutocompleteModule,
        DialogsModule,
        DragDropModule,
        InventurModule,
        ComponentsModule,
        DirectivesModule
    ],
    exports: [
        TierarztBestandComponent,
        BestandComponent,
        BookLossDialog,
        CorrectStockDialog,
        TransferStockDialog,
        SupplierMaterialSelectorComponent,
    ],
    providers: [
        ChargeService,
        GoodsReceiptHistoryService,
        MaterialService,
        MaterialPricesService,
        RabattStaffelService,
        StockService,
        StorageService,
        StoragePlaceService,
        SupplierService,
        WarengruppeService,
        SupplierMaterialService,
    ],
})
export class WarehouseModule {
}
