<nav mat-tab-nav-bar style="margin-top: 63px; width: 100%; padding: 0;">

    <a #startseiteElemente="routerLinkActive" *ngIf="isTabMenuVisible('/startseite')" [active]="startseiteElemente.isActive"
       [routerLink]="['/heimtiere/startseite']" mat-tab-link routerLinkActive>Startseite</a>

    <a #kalenderElemente="routerLinkActive" *ngIf="isTabMenuVisible('/kalender')" [active]="kalenderElemente.isActive"
       [routerLink]="['/heimtiere/kalender']" mat-tab-link routerLinkActive>Kalender</a>

    <a #kundenElemente="routerLinkActive" *ngIf="isTabMenuVisible('/kunden')" [active]="kundenElemente.isActive"
       [routerLink]="['/heimtiere/kunden']" mat-tab-link routerLinkActive>Kunden</a>

    <a #kundenElemente="routerLinkActive" *ngIf="isTabMenuVisible('/belege')" [active]="kundenElemente.isActive"
       [routerLink]="['/heimtiere/belege']" mat-tab-link routerLinkActive>Belege</a>
</nav>
<router-outlet></router-outlet>
