<h1 mat-dialog-title>CSV Download</h1>
<div mat-dialog-content>
    <table style="text-align: left;">
        <thead>
        <tr>
            <th *ngFor="let h of headlineArray">{{h}}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let l of linesArray">
            <td *ngFor="let lc of l">{{lc}}</td>
        </tr>
        </tbody>
    </table>
</div>
