import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {API_BASE_URL_SERVICE} from '../../../../kiene-core.config';
import {KieneBackendApiService} from '../../../../services/backend-api/kiene-backend-api.service';
import {MessageService} from '../../../../services/message-service/message.service';
import {StammdatenService} from '../../../stammdaten/stammdaten.service';
import {Tierliste} from '../../../gruppe-tier/classes/tierliste';
import {AppService} from '../../../../services/app/app.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'kiene-abgangsliste-schliessen-dialog',
    templateUrl: './abgangsliste-schliessen-dialog.component.html',
    styleUrls: ['./abgangsliste-schliessen-dialog.component.scss'],
})
export class AbgangslisteSchliessenDialogComponent
    implements OnInit, OnDestroy {
    datumCtrl = new UntypedFormControl();
    abgangsliste: Tierliste;
    hitMeldenCtrl = new UntypedFormControl(1);
    gleicheUnternehmensgruppe: boolean;
    isSaving = false;

    vvvoSubscription: Subscription;
    haendlerInfoCtrl = new FormControl();
    kfzCtrl = new FormControl();

    constructor(
        public dialogRef: MatDialogRef<AbgangslisteSchliessenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private appService: AppService,
        private stammdatenService: StammdatenService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.abgangsliste = this.data.tierliste;
        console.log(this.abgangsliste);
        this.haendlerInfoCtrl.setValue(this.abgangsliste?.haendler_uebernehmen || 0);
        this.vvvoSubscription?.unsubscribe();
        this.vvvoSubscription = this.stammdatenService.ladeVvvos().subscribe({
            next: (response) => {
                let kundeGefunden = false;
                for (const c of response.records) {
                    if (c.kunde_id === this.abgangsliste.kunde_id_einstallen) {
                        kundeGefunden = true;
                        break;
                    }
                }
                this.gleicheUnternehmensgruppe = kundeGefunden;
            },
            error: () => {
                this.gleicheUnternehmensgruppe = false;
            },
        });
        this.datumCtrl.setValue(this.abgangsliste.datum_abgang);
    }

    ngOnDestroy(): void {
        this.vvvoSubscription?.unsubscribe();
    }

    istDerselbeKunde() {
        return this.abgangsliste?.tierliste_extern === 0;
    }

    close() {
        this.dialogRef.close();
    }

    save() {
        this.appService.setBlockOnLoading(true);
        this.isSaving = true;
        this.abgangsliste.datum_abgang = this.datumCtrl.value;
        if (this.istDerselbeKunde()) {
            this.abgangsliste.abgang_melden = 0; // radiobuttons ausgeblendet
        } else {
            this.abgangsliste.abgang_melden = this.hitMeldenCtrl.value;
        }
        this.abgangsliste.haendler_uebernehmen = this.haendlerInfoCtrl.value;
        this.abgangsliste.status_id = 162;
        this.abgangsliste.kfz = this.kfzCtrl.value;

        if (
            (this.abgangsliste.import_einstallen ||
                this.gleicheUnternehmensgruppe) &&
            this.abgangsliste.kunde_id_einstallen
        ) {
            this.abgangsliste.import_einstallen = 1;
        } else {
            this.abgangsliste.import_einstallen = 0;
        }

        if (
            (this.abgangsliste.reklamationen_weitergeben ||
                this.gleicheUnternehmensgruppe) &&
            this.abgangsliste.kunde_id_einstallen
        ) {
            this.abgangsliste.reklamationen_weitergeben = 1;
        } else {
            this.abgangsliste.reklamationen_weitergeben = 0;
        }

        this.api
            .post(
                this.apiBaseUrl + 'tierliste/update_kundenportal.php',
                this.abgangsliste,
                undefined
            )
            .subscribe({
                next: () => {
                    this.appService.setBlockOnLoading(false);
                    this.isSaving = false;
                    this.messageService.infoMessage(
                        'Die Ausstallliste wurde erfolgreich abgeschlossen!'
                    );
                    if (this.gleicheUnternehmensgruppe) {
                        if (this.data.alsDialog) {
                            this.router
                                .navigate([
                                    '/gruppen/tierlisten/tierlisten',
                                ], {queryParams: {tab: 1}})
                                .then();
                            this.dialogRef.close(true);
                        } else {
                            this.router
                                .navigate([
                                    '/gruppen/zugangsliste',
                                    this.abgangsliste.tierliste_id.toString(),
                                ], {queryParams: {tab: 1}})
                                .then();

                        }
                    }
                    this.dialogRef.close(true);
                },
                error: (err) => {
                    this.appService.setBlockOnLoading(false);
                    this.isSaving = false;
                    this.messageService.errorMessage('Fehler!', err);
                },
            });
    }
}
