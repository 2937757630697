import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InventurDeleteDialogComponent} from '../inventur-delete-dialog/inventur-delete-dialog.component';
import {Inventur} from '../../../warehouse/inventur/entities/Inventur';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';


@Component({
    selector: 'kiene-inventur-create-dialog',
    templateUrl: './inventur-create-dialog.component.html',
    styleUrls: ['./inventur-create-dialog.component.scss'],
})

export class InventurCreateDialogComponent implements OnInit {
    yearMin = new Date().getFullYear() - 10;
    createdInventur: Inventur;
    nameCtrl: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);
    yearCtrl: UntypedFormControl = new UntypedFormControl(null, [Validators.required, Validators.min(this.yearMin)]);

    constructor(public dialogRef: MatDialogRef<InventurDeleteDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data) {
    }

    ngOnInit(): void {
        this.createdInventur = new Inventur();
        this.createdInventur.aktiv = 0;
        this.nameCtrl.valueChanges.subscribe(value => this.createdInventur.name = value);

    }

    onInputChange(event: MatSlideToggleChange) {
        this.createdInventur.aktiv = event.checked ? 1 : 0;
    }

    close() {
        this.dialogRef.close(null);
    }

    save() {
        this.dialogRef.close(this.createdInventur);
    }

    checkYear(min: any) {
        // prüfe erst, wenn Zahl vierstellig
        return min && this.yearCtrl.value.toString().length >= 4;
    }
}

