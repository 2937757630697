<div class="one-row">
    <button mat-icon-button color="accent" (click)="previousWeek()">
        <mat-icon>chevron_left</mat-icon>
    </button>
    <span class="spacer"></span>
    <span class="kw-text" (click)="openCalenderWeekSelector()">KW {{ calendarWeek?.weekNumber }}</span>
    <span>{{ calendarWeek?.monday | date : 'dd.MM.yyyy' }} - {{ calendarWeek?.sunday | date : 'dd.MM.yyyy' }}</span>
    <span class="spacer"></span>
    <button mat-icon-button color="accent" (click)="nextWeek()">
        <mat-icon>chevron_right</mat-icon>
    </button>
    <div style="width: 160px">
        <button
            *ngIf="currentKW.weekNumber !== calendarWeek?.weekNumber"
            mat-stroked-button
            color="accent"
            (click)="setCurrentWeek()"
        >
            aktuelle KW
        </button>
    </div>
    <span class="spacer"></span>
</div>
