import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';
import {KieneUtils} from '../../../../utils/KieneUtils';

@Component({
  selector: 'kiene-transponder-nummer-eingeben-dialog',
  templateUrl: './transponder-nummer-eingeben-dialog.component.html',
  styleUrls: ['./transponder-nummer-eingeben-dialog.component.scss']
})
export class TransponderNummerEingebenDialogComponent {

    transponderNrCtrl: UntypedFormControl = new UntypedFormControl();

    constructor(public dialogRef: MatDialogRef<TransponderNummerEingebenDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data) { }

    isValidInput() {
        return this.transponderNrCtrl.value && KieneUtils.isNNoU(this.transponderNrCtrl.value.trim());
    }
}
