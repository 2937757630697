import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Tierart } from '../tierart';
import { UntypedFormControl } from '@angular/forms';
import { MessageService } from '../../../services/message.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'kiene-tierart-dialog',
  templateUrl: './tierart-dialog.component.html',
  styleUrls: ['./tierart-dialog.component.scss']
})
export class TierartDialogComponent implements OnInit {

  tierart: Tierart;
  mnp_rind_ctrl = new UntypedFormControl();
  gt_eight_ctrl = new UntypedFormControl();
  mnp_schwein_ctrl = new UntypedFormControl();
  gt_thirty_ctrl = new UntypedFormControl();
  hit_ctrl = new UntypedFormControl();
  qs_ctrl = new UntypedFormControl();

  constructor(public dialogRef: MatDialogRef<TierartDialogComponent>,
    private api: KieneTableService,
    private messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.tierart) {
      this.tierart = data.tierart;
    } else {
      this.tierart = new Tierart();
    }
  }

  ngOnInit() {
    this.mnp_rind_ctrl.setValue(this.tierart.mnp_rind === 1 ? true : false);
    this.gt_eight_ctrl.setValue(this.tierart.ueber_8_monate === 1 ? true : false);
    this.mnp_schwein_ctrl.setValue(this.tierart.mnp_schwein === 1 ? true : false);
    this.gt_thirty_ctrl.setValue(this.tierart.ueber_30_kg === 1 ? true : false);
    this.hit_ctrl.setValue(this.tierart.hit === 1 ? true : false);
    this.qs_ctrl.setValue(this.tierart.qs === 1 ? true : false);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    this.tierart.mnp_rind = this.mnp_rind_ctrl.value === true ? 1 : 0;
    this.tierart.ueber_8_monate = this.gt_eight_ctrl.value === true ? 1 : 0;
    this.tierart.mnp_schwein = this.mnp_schwein_ctrl.value === true ? 1 : 0;
    this.tierart.ueber_30_kg = this.gt_thirty_ctrl.value === true ? 1 : 0;
    this.tierart.hit = this.hit_ctrl.value === true ? 1 : 0;
    this.tierart.qs = this.qs_ctrl.value === true ? 1 : 0;

    this.api.updateEntity(environment.BASE_URI + 'tierart/update.php', this.tierart).subscribe(saved => {
      this.messageService.infoMessage('Die Tierart ' + saved.bezeichnung + ' wurde erfolgreich gespeichert!');
      this.dialogRef.close(saved);
    }, error => {
      this.messageService.errorMessage('Fehler! Die Tierart ' + this.tierart.bezeichnung + ' konnte nicht gespeichert werden! ', error);
    });
  }

}