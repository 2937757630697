<h1 mat-dialog-title>Tier nachtragen</h1>

<div mat-dialog-content>
    <kiene-autocomplete #autocomplete [required]="true" (elementSelected)="updateTier($event)"
                        [genericApiUrl]="readTiere" [placeholder]="'Ohrmarke suchen'"
    [params]="params" [displayFields]="['ohrmarke_notnull']" [showClear]="true" [focus]="false" [kieneObject]="tier">
    </kiene-autocomplete>
</div>

<div mat-dialog-actions>
    <button mat-flat-button (click)="onNoClick()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</div>
