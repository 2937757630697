<nav mat-tab-nav-bar [ngStyle]="{'margin-top': embedded ? '0px': '63px'}">
  <a *ngIf="isTabMenuVisible('/labor/probenbegleitscheine')" mat-tab-link [routerLink]="['probenbegleitscheine']"
    routerLinkActive #pbla="routerLinkActive" [active]="pbla.isActive">Probenbegleitscheine</a>
  <a *ngIf="isTabMenuVisible('/labor/befundmitteilungen')" mat-tab-link [routerLink]="['befundmitteilungen']"
    routerLinkActive #bla="routerLinkActive" [active]="bla.isActive">Befundmitteilungen</a>
  <a *ngIf="isTabMenuVisible('/labor/antibiogramme')" mat-tab-link [routerLink]="['antibiogramme']" routerLinkActive
    #ala="routerLinkActive" [active]="ala.isActive">
    <kiene-tabinfo icon="" urlpfad="antibiogramm/tabinfo.php" [sendeKundeId]="true"></kiene-tabinfo>&nbsp;Antibiogramme
  </a>
  <a *ngIf="!isBelegeMenu() && isTabMenuVisible('/labor/untersuchungsauftrag')" mat-tab-link
    [routerLink]="['untersuchungsauftrag']" routerLinkActive #uala="routerLinkActive"
    [active]="uala.isActive">Untersuchungsaufträge</a>
  <a *ngIf="!isBelegeMenu() && isTabMenuVisible('/labor/behandlungsartikel')" mat-tab-link
    [routerLink]="['behandlungsartikel']" routerLinkActive #bala="routerLinkActive"
    [active]="bala.isActive">Laborartikel</a>
  <a *ngIf="!isBelegeMenu() && isTabMenuVisible('/labor/text-autovervollstaendigung')" mat-tab-link
    [routerLink]="['textautocomplete']" routerLinkActive #tala="routerLinkActive" [active]="tala.isActive">Text
    Autovervollständigung</a>
  <a *ngIf="!isBelegeMenu() && isTabMenuVisible('/labor/untersuchungsmaterialien')" mat-tab-link
    [routerLink]="['untersuchungsmaterialien']" routerLinkActive #umla="routerLinkActive"
    [active]="umla.isActive">Untersuchungsmaterialien</a>
  <!--<a *ngIf="isTabMenuVisible('/labor/untersuchungen')" mat-tab-link [routerLink]="['/labor/untersuchungen']" routerLinkActive #utla="routerLinkActive" [active]="utla.isActive">Untersuchungen</a>-->
  <a *ngIf="!isBelegeMenu() && isTabMenuVisible('/labor/untersuchungsgruppen')" mat-tab-link
    [routerLink]="['untersuchungsgruppen']" routerLinkActive #ugla="routerLinkActive"
    [active]="ugla.isActive">Untersuchungsgruppen</a>
  <!--<a *ngIf="isTabMenuVisible('/labor/labore')" mat-tab-link [routerLink]="['/labor/labore']" routerLinkActive #lala="routerLinkActive" [active]="lala.isActive">Labore</a>-->
</nav>
<router-outlet></router-outlet>