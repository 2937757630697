import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Supplier, SupplierApi} from './supplier';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable()
export class SupplierService {

    constructor(private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    getAllSuppliers(sortColumn: string, sortDirection: string, offset: number, limit: number, searchString: string): Observable<SupplierApi> {
        return this.http.get<SupplierApi>(this.apiBaseUrl + 'lieferant/read.php?orderby='
            + sortColumn + ' ' + sortDirection + '&limit=' + limit + '&offset=' + offset + '&search=' + searchString);
    }

    getSupplierById(id: number): Observable<Supplier> {
        return this.http.get<Supplier>(this.apiBaseUrl + 'lieferant/read_one.php?lieferant_id=' + id);
    }

    getSuppliersForMaterialId(id: number): Observable<SupplierApi> {
        return this.http.get<SupplierApi>(this.apiBaseUrl + 'lieferant/read_by_artikel.php?artikel_id=' + id);
    }

    saveSupplier(supplier: Supplier): Observable<Supplier> {
        return this.http.post<Supplier>(this.apiBaseUrl + 'lieferant/update.php', supplier);
    }

    searchSupplier(searchString: string): Observable<SupplierApi> {
        return this.http.get<SupplierApi>(this.apiBaseUrl + 'lieferant/read.php?search=' + searchString);
    }

}
