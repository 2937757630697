import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
    Untersuchungsauftrag,
    UntersuchungsauftragDokument,
    UntersuchungsauftragPosition,
} from '../untersuchungsauftrag';
import { Untersuchungsmaterial } from '../../untersuchungsmaterialien/untersuchungsmaterial';
import { ActivatedRoute } from '@angular/router';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { TierartSelectorService } from '../../../components/tierart-selector/tierart-selector.service';
import { MessageService } from '../../../services/message.service';
import { LocalStorageService } from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { environment } from '../../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Material } from '../../../warehouse/material/material';
import { KieneConstants } from '../../../kiene-constants';
import { Supplier } from '../../../warehouse/supplier/supplier';
import { ConfirmDeleteDialogComponent } from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { GruppenService } from '../../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import { Gruppe } from '../../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { KieneSession } from '../../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';

@Component({
    selector: 'kiene-untersuchungsauftrag-details',
    templateUrl: './untersuchungsauftrag-details.component.html',
    styleUrls: ['./untersuchungsauftrag-details.component.scss'],
})
export class UntersuchungsauftragDetailsComponent implements OnInit {
    untersuchungsauftragId = 'neu';
    gruppe: Gruppe;
    labor: Supplier;
    untersuchungsmaterial: Untersuchungsmaterial;
    kieneSession: KieneSession;

    artikelCtrl = new UntypedFormControl();
    anzahlCtrl = new UntypedFormControl();
    notizCtrl = new UntypedFormControl();
    kennzeichnungCtrl = new UntypedFormControl();
    probenentnahmeCtrl = new UntypedFormControl();
    tagebuchCtrl = new UntypedFormControl();

    untersuchungsauftrag: Untersuchungsauftrag = new Untersuchungsauftrag();

    selectedArtikel: Material;

    positionen: UntersuchungsauftragPosition[] = [];

    untersuchungsauftragDokumente: UntersuchungsauftragDokument[] = [];

    params = new HttpParams();

    constructor(
        private route: ActivatedRoute,
        private api: KieneTableService,
        private tierartService: TierartSelectorService,
        private gruppenService: GruppenService,
        private notification: MessageService,
        private localStorageService: LocalStorageService,
        private cd: ChangeDetectorRef,
        private pdfViewer: PdfViewerDesktopService,
        public dialog: MatDialog
    ) {
        this.untersuchungsauftragId = this.route.snapshot.paramMap.get('id');
        this.kieneSession = localStorageService.getCurrentUser();
    }

    ngOnInit() {
        if (this.untersuchungsauftragId !== 'neu') {
            this.loadUntersuchungsauftrag();
        } else if (this.untersuchungsauftragId === 'neu') {
            this.untersuchungsauftrag.status_id =
                KieneConstants.STATUS.UNTERSUCHUNGSAUFTRAG.ERSTELLT;
        }
    }

    checkEnterKey(event) {
        if (event.keyCode === 13) {
            this.addCurrentPosition();
        }
    }

    loadUntersuchungsauftrag() {
        this.api
            .getOne(
                environment.BASE_URI + 'untersuchungsauftrag/read_one.php',
                'untersuchungsauftrag_id',
                this.untersuchungsauftragId
            )
            .subscribe((response) => {
                this.untersuchungsauftrag = response;
                this.params = new HttpParams().set(
                    'lieferant_id_labor',
                    this.untersuchungsauftrag.lieferant_id.toString()
                );

                this.loadPositionen();
                this.initFields();
                this.loadDokumente();
            });
    }

    loadPositionen() {
        let params = new HttpParams();
        params = params.append(
            'untersuchungsauftrag_id',
            this.untersuchungsauftrag.untersuchungsauftrag_id.toString()
        );
        this.api
            .get(
                environment.BASE_URI + 'untersuchungsauftrag_artikel/read.php',
                params
            )
            .subscribe({
                next: (response) => {
                    this.positionen = response.records;
                },
                error: (error) => {
                    this.notification.errorMessage(
                        'Positionen konnten nicht geladen werden!',
                        error
                    );
                },
            });
    }

    loadDokumente() {
        const params = new HttpParams().append(
            'untersuchungsauftrag_id',
            this.untersuchungsauftrag.untersuchungsauftrag_id.toString()
        );
        this.api
            .get(
                environment.BASE_URI + 'untersuchungsauftrag_dokument/read.php',
                params
            )
            .subscribe((response) => {
                this.untersuchungsauftragDokumente = response.records;
            });
    }

    removeUntersuchungsauftragDokument(ud: UntersuchungsauftragDokument) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '25%',
            data: {
                title: ud.dateiname + ' löschen?',
                text:
                    'Wollen Sie die Datei ' +
                    ud.dateiname +
                    ' wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.api
                    .deleteEntity(
                        environment.BASE_URI +
                        'untersuchungsauftrag_dokument/delete.php',
                        ud
                    )
                    .subscribe(() => {
                        this.loadDokumente();
                    });
            }
        });
    }

    untersuchungsauftragDokumentSelected(
        untersuchungsauftragDokument: UntersuchungsauftragDokument
    ) {
        this.pdfViewer.openPdfViewer(
            new PdfViewerDialogInput(
                'untersuchungsauftrag_dokument/read_pdf.php',
                new HttpParams().set(
                    'ud_id',
                    untersuchungsauftragDokument?.ud_id?.toString()
                ),
                untersuchungsauftragDokument.dateiname
            )
        );
    }

    loadGruppe(gruppe_id: number) {
        this.gruppenService
            .getGruppePraxissoftware(gruppe_id)
            .subscribe((cg) => {
                this.gruppe = <Gruppe>cg;
            });
    }

    loadLabor(lieferant_id: number) {
        if (!this.localStorageService.currentUserHasPermission(1071)) {
            return;
        }
        if (lieferant_id) {
            this.api
                .getOne(
                    environment.BASE_URI + 'lieferant/read_one.php',
                    'lieferant_id',
                    lieferant_id.toString()
                )
                .subscribe((labor) => (this.labor = labor));
        }
    }

    setLabor(labor: Supplier) {
        this.labor = labor;
        if (this.labor) {
            this.params = new HttpParams().set(
                'lieferant_id_labor',
                this.labor.lieferant_id.toString()
            );
        }
    }

    loadUntersuchungsmaterial(untersuchungsmaterial_id: number) {
        if (untersuchungsmaterial_id) {
            this.api
                .getOne(
                    environment.BASE_URI + 'untersuchungsmaterial/read_one.php',
                    'untersuchungsmaterial_id',
                    untersuchungsmaterial_id.toString()
                )
                .subscribe(
                    (untersuchungsmaterial) =>
                        (this.untersuchungsmaterial = untersuchungsmaterial)
                );
        }
    }

    initFields() {
        this.kennzeichnungCtrl.setValue(
            this.untersuchungsauftrag.kennzeichnung
        );
        this.probenentnahmeCtrl.setValue(this.untersuchungsauftrag.datum);
        this.tagebuchCtrl.setValue(this.untersuchungsauftrag.tagebuch_nr);
        this.loadGruppe(this.untersuchungsauftrag.gruppe_id);
        this.loadLabor(this.untersuchungsauftrag.lieferant_id);
        this.loadUntersuchungsmaterial(
            this.untersuchungsauftrag.untersuchungsmaterial_id
        );

        if (this.untersuchungsauftrag.status_id >= 92) {
            this.kennzeichnungCtrl.disable();
            this.probenentnahmeCtrl.disable();
        }
    }

    setArtikel(artikel: Material) {
        this.selectedArtikel = artikel;
    }

    addCurrentPosition() {
        const pbp = this.createCurrentPosition();
        this.positionen.push(pbp);
        this.clearInputs();
        this.save(KieneConstants.STATUS.UNTERSUCHUNGSAUFTRAG.ERSTELLT);
    }

    clearInputs() {
        this.artikelCtrl.setValue(null);
        this.anzahlCtrl.setValue(null);
        this.notizCtrl.setValue(null);
        this.cd.detectChanges();
    }

    close() {
        this.save(KieneConstants.STATUS.UNTERSUCHUNGSAUFTRAG.ABGESCHLOSSEN);
    }

    save(status_id?: number) {
        this.untersuchungsauftrag.positionen = this.positionen;
        this.untersuchungsauftrag.gruppe_id = this.gruppe.gruppe_id;
        this.untersuchungsauftrag.datum = this.probenentnahmeCtrl.value;
        this.untersuchungsauftrag.tagebuch_nr = this.tagebuchCtrl.value;
        if (this.labor) {
            this.untersuchungsauftrag.lieferant_id = this.labor.lieferant_id;
        }
        if (this.untersuchungsmaterial) {
            this.untersuchungsauftrag.untersuchungsmaterial_id =
                this.untersuchungsmaterial.untersuchungsmaterial_id;
        }
        this.untersuchungsauftrag.kennzeichnung = this.kennzeichnungCtrl.value;

        if (status_id) {
            this.untersuchungsauftrag.status_id = status_id;
        }
        this.api
            .updateEntity(
                environment.BASE_URI + 'untersuchungsauftrag/update.php',
                this.untersuchungsauftrag
            )
            .subscribe({
                next: (response) => {
                    this.notification.infoMessage(
                        'Der Untersuchungsauftrag wurde erfolgreich gespeichert!'
                    );
                    this.untersuchungsauftrag = response;
                    this.initFields();
                    this.untersuchungsauftragId =
                        this.untersuchungsauftrag.untersuchungsauftrag_id.toString();
                    this.loadPositionen();
                    if (
                        this.untersuchungsauftrag.status_id ===
                        KieneConstants.STATUS.UNTERSUCHUNGSAUFTRAG.ABGESCHLOSSEN
                    ) {
                        this.pdfViewer.openPdfViewer(
                            new PdfViewerDialogInput(
                                'untersuchungsauftrag/read_or_mail_pdf.php',
                                new HttpParams().set(
                                    'untersuchungsauftrag_id',
                                    this.untersuchungsauftrag?.untersuchungsauftrag_id?.toString()
                                ),
                                'Externer Untersuchungsauftrag ' +
                                this.untersuchungsauftrag
                                    .untersuchungsauftrag_id,
                                {
                                    targetRoute: [
                                        '/labor',
                                        'untersuchungsauftrag',
                                    ],
                                    email: {
                                        sende_mail: true,
                                        kunde_id: this.untersuchungsauftrag?.kunde_id
                                    }
                                }
                            )
                        );
                    }
                },
                error: (error) => {
                    this.notification.errorMessage(
                        'Der Probenbegleitschein konnte nicht gespeichert werden!',
                        error
                    );
                },
            });
    }

    createCurrentPosition() {
        const uap = new UntersuchungsauftragPosition();

        uap.artikel_id = this.selectedArtikel.artikel_id;
        uap.artikel = this.selectedArtikel.artikel_me;
        uap.anzahl = this.anzahlCtrl.value;
        uap.notiz = this.notizCtrl.value;

        return uap;
    }

    removePosition(p: UntersuchungsauftragPosition) {
        const index = this.positionen.indexOf(p);
        this.positionen.splice(index, 1);
        this.save();
    }
}
