import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Futtermittellieferant} from '../lieferant';

@Component({
    selector: 'kiene-lieferant-details-dialog',
    templateUrl: './lieferant-details-dialog.component.html',
    styleUrls: ['./lieferant-details-dialog.component.scss']
})
export class LieferantDetailsDialogComponent implements OnInit {

    futtermittellieferant: Futtermittellieferant;

    constructor(
        public dialogRef: MatDialogRef<LieferantDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        if (this.data?.futtermittellieferant) {
            this.futtermittellieferant = this.data.futtermittellieferant;
        }
    }

    ngOnInit(): void {
    }
}
