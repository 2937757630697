import {Component, Input, OnChanges, OnInit, SimpleChanges,} from "@angular/core";
import {TableDescriptor} from "../../../components/kiene-table/table-descriptor";
import {Material} from "../material";
import {MatDialog} from "@angular/material/dialog";
import {KieneTableService} from "../../../components/kiene-table/kiene-table.service";
import {MessageService} from "../../../services/message.service";
import {environment} from "../../../../environments/environment";
import {HttpParams} from "@angular/common/http";
import {TableOptionEvent} from "../../../components/kiene-table/table-option";
import {ArtikelAnwendungDialogComponent} from "./artikel-anwendung-dialog/artikel-anwendung-dialog.component";
import {ConfirmDeleteDialogComponent} from "../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component";
import {Anwendung} from "./anwendung";
import {SaveForAllDialogComponent} from "../../../dialogs/save-for-all-dialog/save-for-all-dialog.component";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {Mandant} from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: "kiene-artikel-anwendung",
    templateUrl: "./artikel-anwendung.component.html",
    styleUrls: ["./artikel-anwendung.component.scss"],
})
export class ArtikelAnwendungComponent implements OnInit, OnChanges {
    tableDescriptor = new TableDescriptor();

    @Input("material") material: Material;

    @Input("mandanten") mandanten: Mandant[] = [];

    anwendungen: Anwendung[] = [];

    constructor(
        public dialog: MatDialog,
        private api: KieneTableService,
        private messageService: MessageService
    ) {
    }

    ngOnInit() {
    }

    loadAnwendungen() {
        if (this.material?.artikel_id) {
            let params = new HttpParams().set(
                "artikel_id",
                this.material.artikel_id.toString()
            );
            params = params.set("search", "");
            this.api
                .get(
                    environment.BASE_URI + "anwendung/read_grouped.php",
                    params
                )
                .subscribe({
                    next: (response) => {
                        this.anwendungen = response.records;
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            "Anwendungen konnten nicht für den Artikel " +
                            this.material.artikel_me +
                            " geladen werden!",
                            err
                        );
                    },
                });
        }
    }

    reload() {
        this.loadAnwendungen();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.material) {
            // nur wenn sich der Artikel geändert hat
            this.loadAnwendungen();
        }
    }

    reorderList(event: CdkDragDrop<Anwendung[]>) {
        moveItemInArray(this.anwendungen, event.previousIndex, event.currentIndex);
        const obj = {
            records: this.anwendungen,
        };
        this.api
            .updateGeneric(environment.BASE_URI + "anwendung/update_sort.php", obj)
            .subscribe({
                next: (response) => {
                    this.loadAnwendungen();
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        "Die Anwendungen konnten nicht neu sortiert werden!",
                        err
                    );
                },
            });
    }

    optionClicked(event: TableOptionEvent) {
        console.debug("Table Option: " + event.name);
        if (event.name === "add") {
            this.openAnwendungDialog(null);
        } else if (event.name === "edit") {
            this.openAnwendungDialog(event.value);
        } else if (event.name === "delete") {
            this.openDeleteDialog(event.value);
        }
    }

    add() {
        this.openAnwendungDialog(null);
    }

    saveAndwendung(anwendung: Anwendung) {
        if (this.mandanten.length > 1) {
            // Array enthält nur Daten mit entspr. Berechtigung und bei Kiene
            let dialogRef = this.dialog.open(SaveForAllDialogComponent, {
                data: {
                    availableMandanten: this.mandanten,
                },
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    anwendung.mandanten_ids = result;
                    this.save(anwendung);
                }
            });
        } else {
            this.save(anwendung);
        }
    }

    save(anwendung: Anwendung) {
        this.api
            .updateGeneric(
                environment.BASE_URI + "anwendung/update_grouped.php",
                anwendung
            )
            .subscribe({
                next: (saved) => {
                    this.messageService.infoMessage(
                        "Die Anwendung " +
                        saved.anwendung +
                        " wurde erfolgreich gespeichert!"
                    );
                    this.reload();
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        "Fehler! Die Anwendung " +
                        anwendung.anwendung +
                        " konnte nicht gespeichert werden! ",
                        error
                    );
                },
            });
    }

    openAnwendungDialog(anwendung: Anwendung) {
        const dialogRef = this.dialog.open(ArtikelAnwendungDialogComponent, {
            width: "800px",
            data: {
                material: this.material,
                anwendung: anwendung,
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.saveAndwendung(response);
            }
        });
    }

    openDeleteDialog(anwendung: Anwendung) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: "500px",
            data: {
                title: anwendung.anwendung + " löschen?",
                text:
                    "Wollen Sie die Anwendung " +
                    anwendung.anwendung +
                    " wirklich löschen?",
            },
        });

        dialogRef.afterClosed().subscribe({
            next: (response) => {
                if (response) {
                    this.api
                        .deleteEntity(environment.BASE_URI + "anwendung/delete.php", anwendung)
                        .subscribe({
                            next: (result) => {
                                this.messageService.infoMessage(
                                    "Die Anwendung wurde erfolgreich gelöscht!"
                                );
                                this.reload();
                            },
                            error: (error) => {
                                this.messageService.errorMessage(
                                    "Fehler! Es ein Problem beim Löschen der Anwendung aufgetreten! ",
                                    error
                                );
                            },
                        });
                }
            },
            error: (error) => {
            },
        });
    }
}
