import { Component, Inject, OnInit } from '@angular/core';
import { TableDescriptor } from '../../../components/kiene-table/table-descriptor';
import { Router } from '@angular/router';
import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { Condition, Operator, TableOption, TableOptionEvent } from '../../../components/kiene-table/table-option';
import { ConditionSetting, ConditionTableColumn, DateTableColumn, TableColumn } from '../../../components/kiene-table/table-column';
import { TimeSlot } from '../zeitslot-buchen/zeitslot-buchen.component';
import { KieneBackendApiService } from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../services/message.service';
import { KieneTableService } from '../../../components/kiene-table/kiene-table.service';
import { FilterItem, FilterItemType } from '../../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';

@Component({
  selector: 'kiene-zeitslot-historie',
  templateUrl: './zeitslot-historie.component.html',
  styleUrls: ['./zeitslot-historie.component.scss']
})
export class ZeitslotHistorieComponent implements OnInit {

  tableDescriptor = new TableDescriptor();
  kundeColumn: TableColumn;
  kundeZusatzColumn: TableColumn;

  constructor(
    protected _router: Router,
    @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
    private api: KieneBackendApiService,
    private tableService: KieneTableService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.tableDescriptor.headline = 'Schlachtung Buchungshistorie';
    this.tableDescriptor.alternativeApiUrl =
        this.apiBaseUrl + 'zeitslot_buchung/read_zeitslot_historie.php';
    this.tableDescriptor.initialSortDirection = 'desc';
    this.tableDescriptor.initialSortColumn = 'buchungs_datum';
    this.tableDescriptor.nameOfIdField = 'zeitslot_buchung_id';
    this.tableDescriptor.route = '/gruppen/zeitslot-historie/';
    this.tableDescriptor.showDetails = true;
    this.tableDescriptor.detailsRoute = '/gruppen/anfrage-details/';
    this.tableDescriptor.createAllowed = false;
    this.tableDescriptor.standardCreate = false;
    this.tableDescriptor.showDownloads = true;
    this.tableDescriptor.sendCurrentClient = true;

    this.tableDescriptor.columns = this.getColumns();
    this.tableDescriptor.options = this.getOptions();
    this.setFilterOptions();

    this.tableDescriptor.permissionCreate = 9112;
    this.tableDescriptor.permissionUpdate = 9112;
  }

  getColumns(): TableColumn[] {
    const columns = [];
    
    columns.push(new TableColumn('gruppen_name', 'Gruppe'));
    columns.push(new DateTableColumn('datum_uhrzeit_von', 'Zeitslot'));

    this.kundeColumn = new TableColumn('haendler_name', 'Händler');
    columns.push(this.kundeColumn);

    columns.push(new TableColumn('tierzahl', 'Tierzahl'));

    columns.push(new TableColumn('storniert', 'Storniert'));

    const conditionSettings = [
      new ConditionSetting(
          [new Condition('combined_status', Operator.IN, [0])],
          {
            color: '#f2c52d',
          }
      ),
      new ConditionSetting(
          [new Condition('combined_status', Operator.IN, [1])],
          {
            color: '#479864',
            icon: 'check_circle'
          }
      ),
      new ConditionSetting(
          [new Condition('combined_status', Operator.IN, [2])],
          {
            color: '#d50000',
          }
      ),
    ];

    columns.push(new ConditionTableColumn('combined_status', 'Status', conditionSettings, {
      defaultIcon: 'radio_button_unchecked',
      defaultColor: '#ddd',
    }));

    columns.push(
      new DateTableColumn(
        'buchungs_datum',
        'Buchungsdatum',
        'dd.MM.yy'
      )
    );

    return columns;
  }

  setFilterOptions() {
    this.tableDescriptor.filterItems.push(
      new FilterItem(
          'Status: Angenommen',
          '1',
          FilterItemType.CHECKBOX,
          'combined_status',
          true
      )
    );
    this.tableDescriptor.filterItems.push(
      new FilterItem(
          'Status: Abgelehnt',
          '2',
          FilterItemType.CHECKBOX,
          'combined_status',
          true
      )
    );
    this.tableDescriptor.filterItems.push(
      new FilterItem(
          'Status: Ausstehend',
          '0',
          FilterItemType.CHECKBOX,
          'combined_status',
          true
      )
    );
  }

  getOptions() {
    const options: TableOption[] = [];
    options.push(
      new TableOption(
        'details',
        'Buchungsdetails',
        'description',
        9117
      ),
      new TableOption(
        'aendern',
        'Anfrage ändern',
        'edit',
        9117, {
          conditions:
              [
                  new Condition('storniert', Operator.UNGLEICH, [ 1 ]),
                  new Condition('status_schlachthof', Operator.GLEICH, [ 0 ]),
                  new Condition('status_haendler', Operator.GLEICH, [ 0 ])
              ]
        }
      ),
      new TableOption(
        'umbuchen',
        'Umbuchen',
        'edit',
        9117
      ),
      new TableOption(
        'stornieren',
        'Buchung Stornieren',
        'cancel',
        9117, {
          conditions:
              [
                  new Condition('storniert', Operator.UNGLEICH, [
                      1
                  ]),
              ]
        }
      )
    )
    return options;
  }

  catchOptionEvent(event: TableOptionEvent) {
    console.log(event);
    //TODO handle events
    switch(event.name){
      case "details":
        //TODO open detail screen
        console.log('details');
        this._router.navigate(['/gruppen', 'anfrage-details', event.value['zeitslot_buchung_id']]).then();
        break;
      case "aendern":
        console.log("aendern");
        this._router.navigate(['/gruppen', 'zeitslot-buchung-aendern', event.value['zeitslot_buchung_id']]).then();
        break;
      case "umbuchen":
        this._router.navigate(['/gruppen', 'zeitslot-buchung-aendern', event.value['zeitslot_buchung_id']], {queryParams: { 'umbuchen': 'true'}}).then();
        break;
      case "stornieren":
        this.api.updateGeneric(this.apiBaseUrl + 'zeitslot_buchung/cancel_buchung.php', event.value).subscribe({
          next: (response: TimeSlot) => {
            this.messageService.infoMessage(
              'Buchung wurde Storniert.'
            );
            this.tableService.reload();
          },
          error: error => {
              if (error.status === 400) {
                this.messageService.alertMessage(error.error.errortext);
              } else {
                this.messageService.errorMessage('Es ist ein Fehler aufgetreten: ', error);
              }
          }
        });
        break;
    }
  }
}
