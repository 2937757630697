import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PermissionOverviewService } from './permission-overview.service';
import { merge, Observable, of as observableOf, fromEvent } from 'rxjs';
import { catchError, map, startWith, switchMap, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'kiene-permission-overview',
  templateUrl: './permission-overview.component.html',
  styleUrls: ['./permission-overview.component.scss']
})
export class PermissionOverviewComponent implements OnInit {

  displayedColumns = ['benutzerrecht_id', 'bezeichnung', 'nur_admin'];
  userDataSource = new MatTableDataSource();

  searchInputCtrl = new UntypedFormControl();

  tableAddFormVisible = false;

  permissionDataSource = new MatTableDataSource();


  totalCount = 0;
  defaultPageSize = 10;
  pageSizeOptions = [5, 10, 20, 50];
  offset = 0;
  limit = this.defaultPageSize;
  sortColumn = 'bezeichnung';
  searchString = '';
  isLoading = false;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('searchInput') searchInput: ElementRef;

  constructor(private service: PermissionOverviewService) { }

  ngOnInit() {
    this.loadPermissions();
  }

  ngAfterViewInit() {
    fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => {
        this.paginator.pageIndex = 0;
        this.loadPermissions();
      })
    ).subscribe();
  }

  loadPermissions() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page).pipe(
      startWith({}),
      switchMap(() => {
        this.isLoading = true;
        this.offset = (this.paginator.pageIndex != null && this.paginator.pageSize != null) ? (this.paginator.pageIndex * this.paginator.pageSize) : 0;
        this.limit = this.paginator.pageSize != null ? this.paginator.pageSize : this.limit;
        this.sortColumn = this.sort.active != null ? this.sort.active : this.sortColumn;
        return this.service!.getAll(this.sortColumn, this.sort.direction, this.offset, this.limit, this.searchInput.nativeElement.value);
      }),
      map(data => {
        this.isLoading = false;
        this.totalCount = data.count;
        return data.records;
      }),
      catchError(() => {
        this.isLoading = false;
        return observableOf([]);
      })
    ).subscribe(data => this.permissionDataSource.data = data);
  }

}
