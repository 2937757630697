<h1 mat-dialog-title>Heimtier Daten bearbeiten</h1>

<div mat-dialog-content>
    <mat-form-field style="width: 100%">
        <mat-label>Gewicht (kg)</mat-label>
        <input matInput [formControl]="gewichtCtrl" name="gewicht">
    </mat-form-field>
    <mat-form-field style="width: 100%">
        <mat-label>Temperatur (°C)</mat-label>
        <input matInput [formControl]="temperaturCtrl" name="temperatur">
    </mat-form-field>
    <mat-form-field style="width: 100%">
        <mat-label>Puls (/min)</mat-label>
        <input matInput [formControl]="pulsCtrl" name="puls">
    </mat-form-field>
    <mat-form-field style="width: 100%">
        <mat-label>Atmung (/min)</mat-label>
        <input matInput [formControl]="atmungCtrl" name="atmung">
    </mat-form-field>
</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-button color="accent" (click)="save()" [disabled]=isSaving>Speichern</button>
</div>