import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Gruppe} from '../../classes/gruppe';
import {Tierliste} from '../../../gruppe-tier/classes/tierliste';
import {API_BASE_URL_SERVICE} from '../../../../kiene-core.config';
import {KieneBackendApiService} from '../../../../services/backend-api/kiene-backend-api.service';
import {HttpParams} from '@angular/common/http';
import {MessageService} from '../../../../services/message-service/message.service';
import {TierlistenauswahlDialogInput} from './tierlistenauswahl-dialog';

@Component({
    selector: 'kiene-tierlistenauswahl-dialog',
    templateUrl: './tierlistenauswahl-dialog.component.html',
    styleUrls: ['./tierlistenauswahl-dialog.component.scss']
})
export class TierlistenauswahlDialogComponent implements OnInit {

    gruppe: Gruppe;
    tierlisten: Tierliste[] = [];
    isLoading = false;

    onlyActive = false;

    constructor(public dialogRef: MatDialogRef<TierlistenauswahlDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data: TierlistenauswahlDialogInput,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private api: KieneBackendApiService,
                private messageService: MessageService) {
    }

    ngOnInit(): void {
        this.gruppe = this.data.gruppe;

        if (this.data?.onlyActive) {
            this.onlyActive = this.data.onlyActive;
        }

        if (this.data?.tierlisten?.length > 0) {
            this.tierlisten = this.data.tierlisten;
        } else {
            this.ladeTierlisten();
        }
    }

    select(t: Tierliste) {
        this.dialogRef.close(t);
    }

    private ladeTierlisten() {
        this.isLoading = true;
        let params = new HttpParams();
        if (this.data?.gruppe) {
            params = params.set('gruppe_id_abgang', this.data.gruppe?.gruppe_id);
        }
        this.api.get(this.apiBaseUrl + 'tierliste/read_meine_tierlisten.php', params).subscribe({
            next: response => {
                if (response) {
                    this.tierlisten = response.records;
                }
                this.isLoading = false;
            },
            error: err => {
                this.isLoading = false;
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }
}
