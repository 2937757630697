import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { TierartSelectorComponent } from "./tierart-selector.component";

@NgModule({
    declarations: [
        TierartSelectorComponent
    ],
    imports:[
        CommonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule
    ], exports:[
        TierartSelectorComponent
    ]
})
export class TierartSelectorModule{}