<h2 mat-dialog-title>Kundenartikel hinzufügen</h2>
<mat-dialog-content>
    <kiene-material-autocomplete (selectedMaterial)="setSelectedMaterial($event)">
    </kiene-material-autocomplete>
    <p>
        Info: Ist ein Einzelpreis angegeben, wird nur dieser
        ber&uuml;cksichtigt. Ansonsten der selektierte Verkaufspreis.
    </p>
    <div class="one-row">
        <mat-form-field>
            <mat-label>Einzelpreis</mat-label>
            <input type="number" matInput placeholder="Einzelpreis" [formControl]="einzelpreisCtrl" />
            <span matSuffix>
                <mat-icon>euro</mat-icon>
            </span>
        </mat-form-field>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button color="primary" (click)="cancel()">Abbrechen</button>
    <button mat-raised-button color="primary" (click)="save()">
        Speichern
    </button>
</mat-dialog-actions>