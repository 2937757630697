import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MessageService } from '../../../services/message.service';
import { KieneTableService } from '../../kiene-table/kiene-table.service';
import { Subscription } from 'rxjs';
import { SelectorInput } from './selector';

@Component({
  selector: 'kiene-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.css']
})
export class SelectorComponent implements OnInit, OnChanges {

  @Input('selectorInput') selectorInput: SelectorInput;
  /*@Input('label') label = 'Bitte Label definieren';
  @Input('selectedObject') selectedObject: any;
  @Input('apiUrl') apiUrl: string;
  @Input('params') params: HttpParams;
  @Input('displayColumns') displayColumns: string[] = [];
  @Input('delimiter') delimiter: string = ' - ';
  @Input('comparator') comparator: (o1: any, o2: any) => boolean;*/

  @Output('optionSelected') optionSelected = new EventEmitter<any>();

  selectCtrl = new UntypedFormControl();
  sub: Subscription;
  options: any[] = [];
  isLoading = false;

  constructor(private api: KieneTableService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.loadOptions();
    this.setCtrl(this.selectorInput?.selectedObject);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedObject) {
      this.setCtrl(this.selectorInput?.selectedObject);
    }

    if (changes.apiUrl || changes.params) {
      this.loadOptions();
    }
  }

  clear(event) {
    event.stopPropagation();
    this.setCtrl(undefined);
  }

  loadOptions() {
    if (!this.selectorInput?.apiUrl) {
      this.messageService.alertMessage('Api Url nicht definiert!');
      return;
    }
    this.isLoading = true;
    this.sub?.unsubscribe();
    this.sub = this.api.get(this.selectorInput?.apiUrl, this.selectorInput?.params).subscribe({
      next: (response) => {
        this.options = response.records;
        if ((this.selectorInput?.autoSelect === true) && (this.options?.length === 1)) {
          this.setCtrl(this.options[0]);
        }
        this.isLoading = false;
      }, error: err => {
        this.messageService.errorMessage('Die Optionen konnten nicht geladen werden!', err);
        this.isLoading = false;
      }
    });
  }

  formatOptionText(o: any) {
    let text = '';
    if (o) {
      let first = true;
      for (let d of this.selectorInput?.displayColumns) {
        if (first) {
          first = false;
        } else {
          text += this.selectorInput?.delimiter;
        }
        text += o[d];
      }
    }
    return text;
  }

  setCtrl(v: any) {
    this.selectCtrl.setValue(v, { emitEvent: false });
    this.optionSelected.emit(v);
  }

  setSelectedOption(option: MatSelectChange) {
    this.optionSelected.emit(option.value);
  }

}
