import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntityViewerService {

  visible = false;

  visibleSubject = new Subject<boolean>();
  entitySubject = new Subject<any>();

  constructor() { }

  setVisible(visible: boolean){
    this.visible = visible;
    this.visibleSubject.next(visible);
  }
  
  toggleVisible(){
    this.visible = !this.visible;
    this.visibleSubject.next(this.visible);
  }

  watchVisible(){
    return this.visibleSubject.asObservable();
  }
  
  setEntity(entity: any){
    this.entitySubject.next(entity);
  }

  watchEntity(){
    return this.entitySubject.asObservable();
  }
}
