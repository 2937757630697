<h1 mat-dialog-title>Ausstalllisten / Lieferscheine</h1>

<div mat-dialog-content>
    <!--<strong>Gruppe: {{gruppe.bezeichnung}}</strong>-->

    <div *ngIf="!tierlisteData" class="one-row">
        <mat-form-field class="no-padding-margin-mat-form-field" style="width: 100%">
            <mat-label>Tierliste</mat-label>
            <mat-select multiple [formControl]="tierlistCtrl" [compareWith]="compareTierliste">
                <mat-option style="line-height: 1em !important" class="multiline-mat-option"
                    *ngFor="let tierliste of tierlisten" [value]="tierliste">
                    <div>
                        {{ tierliste?.kunden_abgang }} -
                        {{ tierliste?.gruppe_abgang }}
                    </div>
                    <div>({{ tierliste.anzahl_tiere }} Tiere - {{tierliste.datum_abgang | date : "dd.MM.yyy"}} -
                        {{tierliste.kommentar ? tierliste.kommentar : 'k.A.'}})</div>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div style="width: 100%; margin: 12px 0">
        <div style="margin-bottom: 12px">
            <strong style="margin-right: 6px">Liste als folgende(r) Dateityp(en) herunterladen:</strong>
        </div>
        <mat-checkbox [(ngModel)]="downloadAsPdf" style="margin-right: 24px;">PDF</mat-checkbox>
        <mat-checkbox [(ngModel)]="downloadAsExcel" style="margin-right: 24px;">Excel</mat-checkbox>
        <mat-checkbox [(ngModel)]="downloadAsCsv">CSV</mat-checkbox>
    </div>

    <div style="width: 100%; margin: 12px 0">
        <div style="margin-bottom: 12px">
            <strong style="margin-right: 6px">Gruppe/Betrieb je Tier anzeigen?</strong>
        </div>

        <mat-radio-group [formControl]="showTiergruppeCtrl">
            <mat-radio-button style="margin-right: 12px" [value]="1">Ja</mat-radio-button>
            <mat-radio-button [value]="0">Nein</mat-radio-button>
        </mat-radio-group>
    </div>

    <div style="width: 100%; margin: 12px 0">
        <div style="margin-bottom: 12px">
            <strong style="margin-right: 6px">Gruppendetails auf PDF anzeigen?</strong>
        </div>

        <mat-radio-group [formControl]="showGruppendetailsCtrl" [disabled]="!downloadAsPdf">
            <mat-radio-button style="margin-right: 12px" [value]="1">Ja</mat-radio-button>
            <mat-radio-button [value]="0">Nein</mat-radio-button>
        </mat-radio-group>
    </div>

    <div style="width: 100%; margin: 12px 0">
        <div style="margin-bottom: 12px">
            <strong style="margin-right: 6px">Barcode auf PDF anzeigen?</strong>
        </div>

        <mat-radio-group [formControl]="showBarcodeCtrl" [disabled]="!downloadAsPdf">
            <mat-radio-button style="margin-right: 12px" [value]="1">Ja</mat-radio-button>
            <mat-radio-button [value]="0">Nein</mat-radio-button>
        </mat-radio-group>
    </div>

    <div style="width: 100%; margin: 12px 0">
        <div style="margin-bottom: 12px">
            <strong style="margin-right: 6px">Eingescannte Pässe anhängen?</strong>
        </div>

        <mat-radio-group [formControl]="addScansCtrl" [disabled]="!downloadAsPdf">
            <mat-radio-button style="margin-right: 12px" [value]="1">Ja</mat-radio-button>
            <mat-radio-button [value]="0">Nein</mat-radio-button>
        </mat-radio-group>
    </div>
</div>

<div mat-dialog-actions>
    <button mat-stroked-button style="margin-top: 8px" (click)="cancel()">
        Abbrechen
    </button>
    <button mat-flat-button style="margin-top: 8px" color="accent" (click)="downloadFiles()">
        OK
    </button>
</div>