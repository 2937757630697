<div class="container" style="height: 80vh">
    <div class="toolbar">
        <div style="display: flex; align-items: center; justify-content: space-between">
            <mat-slide-toggle style="padding-left: 6px" (change)="filterUnread($event.checked)" [checked]="true">Nur
                ungelesene anzeigen
            </mat-slide-toggle>
            <button [disabled]="allRead() || isSaving" (click)="markAllAsRead()" matTooltip="Alle als gelesen markieren"
                color="accent" mat-icon-button>
                <mat-icon>done_all</mat-icon>
            </button>
        </div>
        <div style="display: flex; align-items: center; justify-content: center">
            <mat-form-field style="width: 95%" class="mat-form-field-no-padding-margin">
                <mat-label>Suche</mat-label>
                <input matInput [formControl]="searchCtrl" placeholder="Suche" />
            </mat-form-field>
        </div>
    </div>

    <div *ngIf="benachrichtigungen.length === 0 && !onlyUnreadBenachrichtigungen && isLoading"
        style="color: #777; display: flex; align-items: center; justify-content: center; width: 100%; height: 75vh">
        <mat-hint> Lade Benachrichtigungen...</mat-hint>
    </div>

    <div *ngIf="benachrichtigungen.length === 0 && !isLoading"
        style="color: #777; display: flex; align-items: center; justify-content: center; width: 100%; height: 75vh">
        <mat-hint> Keine Benachrichtigungen</mat-hint>
    </div>

    <div *ngIf="benachrichtigungen.length === 0 && onlyUnreadBenachrichtigungen"
        style="color: #777; display: flex; align-items: center; justify-content: center; width: 100%; height: 75vh">
        <mat-hint> Keine ungelesenen Benachrichtigungen</mat-hint>
    </div>

    <div *ngIf="benachrichtigungen.length > 0">
        <div style="height: 65vh; overflow-y: auto" infinite-scroll [infiniteScrollDistance]="2"
            [infiniteScrollUpDistance]="1.5" (scrolled)="scrolled()" [scrollWindow]="false">
            <div class="notification-item" (click)="markAsRead(nachricht)" *ngFor="let nachricht of benachrichtigungen">

                <div
                    style="width: 15%; display: flex; align-items: center; justify-content: center; margin: 0 3px 0 3px">
                    <div class="acronym-icon">
                        <mat-icon color="primary">{{ benachrichtigungsTyp[nachricht.typ] }}</mat-icon>
                    </div>
                </div>

                <div style="width: 80%">
                    <strong>{{ nachricht.titel }}</strong>
                    <div class="one-row">
                        <span>{{ nachricht.txt }}</span>
                        <mat-icon *ngIf="isLink(nachricht)">chevron_right</mat-icon>
                    </div>
                    <div style="width: 100%; text-align: right; color: #777; font-size: 0.8em">
                        {{ formatDate(nachricht.erstellt) }}
                    </div>
                </div>
                <div style="width: 5%; display: flex; align-items: center; justify-content: center">
                    <div *ngIf="nachricht.gelesen === 0" class="unread-dot"></div>
                </div>
            </div>
        </div>
    </div>
</div>