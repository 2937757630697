<div style="padding: 0 24px 64px 24px">
    <kiene-page-header [ueberschrift]="'Einstallen'"></kiene-page-header>

    <h4>Gruppe: {{ gruppe?.bezeichnung }}</h4>

    <mat-radio-group [disabled]="isLoading" [formControl]="ohrmarkePassCtrl" style="
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            margin-bottom: 12px;
        ">
        <mat-radio-button data-cy="einstallen-ohrmarke-radio-button" [value]="0">Ohrmarken</mat-radio-button>
        <mat-radio-button data-cy="einstallen-pass-radio-button" [value]="1">P&auml;sse</mat-radio-button>
    </mat-radio-group>

    <div id="nurBeiPass" *ngIf="ohrmarkePassCtrl.value === 1">
        <div style="
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 100%;
            ">
            <mat-form-field style="
                    width: 90%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                ">
                <mat-label>H&auml;ndler ausw&auml;hlen</mat-label>
                <mat-select [disabled]="isLoading" data-cy="einstallen-pass-haendler-select"
                            [compareWith]="compareHaendler" [formControl]="haendlerCtrl">
                    <mat-option [value]="null"> - kein H&auml;ndler -
                    </mat-option>
                    <mat-option *ngFor="let haendler of haendlerFavoriten" [value]="haendler">{{ haendler.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button *kienePermission="[9132]" data-cy="einstallen-pass-haendler-add" (click)="openHaendlerVerwaltung()"
                    mat-icon-button color="accent">
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <mat-form-field style="width: 100%">
            <mat-label>Einstalldatum</mat-label>
            <input [disabled]="isLoading" data-cy="einstallen-einstalldatum" matInput [matDatepicker]="picker"
                   [readonly]="true" [formControl]="datumCtrl"/>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>

    <div id="nurBeiScan" *ngIf="ohrmarkePassCtrl.value === 0">
        <mat-form-field style="width: 100%; margin-bottom: 6px" class="no-padding-margin-mat-form-field">
            <mat-label>Abteil</mat-label>
            <input [disabled]="isLoading" data-cy="einstallen-abteil" matInput [formControl]="abteilCtrl"/>
            <button mat-icon-button matSuffix [disabled]="abteilCtrl.value === null"
                    (click)="abteilCtrl.setValue(null)">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>
    </div>

    <div class="one-row">
        <mat-form-field style="width: 100%; margin-bottom: 6px" class="no-padding-margin-mat-form-field">
            <mat-label>{{
                    ohrmarkePassCtrl.value === 0 ? "Ohrmarke " : "Pass "
                }}
                scannen
            </mat-label>

            <input [disabled]="isLoading" data-cy="einstallen-ohrmarke-pass-input" type="text"
                   maxlength="{{ maxLengthOhrmarke }}" #ohrenmarkeInput="matInput" matInput
                   [formControl]="ohrenmarkeCtrl"
                   (keyup.enter)="addOhrmarke()"/>
            <span matSuffix style="font-size: 0.8em">{{ ohrenmarkeCtrl.value?.length }}/{{
                    maxLengthOhrmarke
                }}</span>

            <button *ngIf="this.ohrenmarkeCtrl.value?.length === maxLengthOhrmarke"
                    data-cy="einstallen-add-ohrmarke-pass" matSuffix mat-icon-button (click)="addOhrmarke()">
                <mat-icon>check</mat-icon>
            </button>
        </mat-form-field>
    </div>

    <div *ngIf="ohrmarkePassCtrl.value === 0" style="display: flex; align-items: center; justify-content: center">
        <div (click)="changeGewichtToggle($event)" style="width: 50%; justify-content: start" class="one-row">
            <mat-slide-toggle data-cy="einstallen-gewicht-toggle" [disabled]="isLoading"
                              [formControl]="mitGewichtToggleCtrl" (change)="mitGewichtToggleCtrl.setValue(
                    !mitGewichtToggleCtrl.value)"></mat-slide-toggle>
            <p style="font-size: 0.8em; margin-left: 3px">mit Gewicht</p>
        </div>

        <div (click)="changeTransponderNrToggle($event)" style="width: 50%; justify-content: start" class="one-row">
            <mat-slide-toggle data-cy="einstallen-transpondernummer-toggle" [disabled]="isLoading"
                              [formControl]="mitTransponderNummerToggleCtrl" (change)="mitTransponderNummerToggleCtrl.setValue(
                    !mitTransponderNummerToggleCtrl.value)"></mat-slide-toggle>
            <p style="font-size: 0.8em; margin-left: 3px">mit Transpondernr.</p>
        </div>
    </div>

    <div>
        <table style="width: 100%">
            <thead>
            <tr>
                <th style="
                            text-align: left;
                            font-size: 0.7em;
                            color: gray;
                            font-weight: normal;
                        ">
                    Tiere gesamt
                </th>
                <th style="
                            text-align: left;
                            font-size: 0.7em;
                            color: gray;
                            font-weight: normal;
                        ">
                    Akt.
                    {{
                        ohrmarkePassCtrl.value === 0 ? "Abteil " : "Händler"
                    }}
                </th>
                <th style="
                            text-align: left;
                            font-size: 0.7em;
                            color: gray;
                            font-weight: normal;
                        ">
                    Pass &amp; Ohrmarke
                </th>
            </tr>
            <tr style="
                        font-size: 1.5em;
                        font-weight: bold;
                        font-family: 'Courier New', sans-serif;
                    ">
                <td data-cy="einstallen-tiere-gesamt">{{ currentScanErgebnis?.anzahl_tiere_datensaetze }}</td>
                <td data-cy="einstallen-akt-abteil" *ngIf="ohrmarkePassCtrl.value === 0">
                    {{ currentScanErgebnis?.anzahl_abteil || "---" }}
                </td>
                <td data-cy="einstallen-akt-haendler" *ngIf="ohrmarkePassCtrl.value !== 0">
                    {{ currentScanErgebnis?.anzahl_viehhaendler || "---" }}
                </td>
                <td data-cy="einstallen-tiere-pass-ohrmarke">{{ currentScanErgebnis?.anzahl_tiere_match }}</td>
            </tr>
            </thead>
        </table>
    </div>

    <div id="gescannteOhrenmarken" data-cy="einstallen-scan-list">
        <div class="one-row">
            <h3>Letzte 10 Scans:</h3>
            <!--<p>{{timeMs ? (timeMs + 'ms') : ''}}</p>-->
        </div>
        <p *ngIf="gescannteOhrmarken?.length < 1">---</p>
        <ul style="
                list-style-type: none;
                padding: 0;
                margin: 0;
                font-family: 'Courier New', sans-serif;
            ">
            <li class="ohrenmarke-list-item" [@openClose]="openCloseState">
                <div class="one-row" *ngIf="gescannteOhrmarken?.length > 0">
                    <div id="item-text" class="ohrenmarke-list-item--text">
                        <mat-icon *ngIf="gescannteOhrmarken[0]" [style.color]="
                                gescannteOhrmarken[0].success
                                    ? 'green'
                                    : '#9b0024'
                            ">
                            {{
                                gescannteOhrmarken[0].success
                                    ? "check"
                                    : "error"
                            }}
                        </mat-icon>

                        <span style="padding-left: 5px">
                            <kiene-ohrmarke style="font-size: 0.8em" [ohrmarke]="
                                    gescannteOhrmarken[0]?.ohrmarke_notnull
                                " id="reklamation-on-click-0" (click)="
                                    reklamationFuerScanHinzufuegen(
                                        gescannteOhrmarken[0]
                                    )
                                "></kiene-ohrmarke>
                            <span
                                *ngIf="gescannteOhrmarken[0]?.einstallgewicht"
                                style="font-size: .8em;">{{ gescannteOhrmarken[0]?.einstallgewicht }}kg</span>
                        </span>

                    </div>
                    <div id="item-buttons">
                        <button *ngIf="gescannteOhrmarken[0].success" mat-icon-button
                                [matMenuTriggerFor]="tierMenuFirst">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #tierMenuFirst="matMenu">
                            <button mat-menu-item (click)="
                                    reklamationFuerScanHinzufuegen(
                                        gescannteOhrmarken[0]
                                    )
                                ">
                                <mat-icon color="accent">feedback</mat-icon>
                                <span>Reklamieren</span>
                            </button>
                            <button mat-menu-item (click)="scanLoeschen(gescannteOhrmarken[0])">
                                <mat-icon color="accent">delete</mat-icon>
                                <span>Löschen</span>
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </li>
            <li *ngFor="let ohrmarke of gescannteOhrmarken; let i = index">
                <div *ngIf="i > 0" class="ohrenmarke-list-item">
                    <div data-cy="einstallen-abteilung-change" style="width: 100%; height: 2px; background-color: #ccc"
                         *ngIf="hasAbteilungsChange(ohrmarke, i)"></div>
                    <div class="one-row" *ngIf="gescannteOhrmarken?.length > 0">
                        <div class="ohrenmarke-list-item--text">
                            <mat-icon [style.color]="
                                    ohrmarke.success ? 'green' : '#9b0024'
                                ">
                                {{ ohrmarke.success ? "check" : "error" }}
                            </mat-icon>
                            <span style="padding-left: 5px">
                                <kiene-ohrmarke id="reklamation-on-click-{{i}}" style="font-size: 0.8em"
                                                [ohrmarke]="ohrmarke.ohrmarke_notnull" (click)="
                                        reklamationFuerScanHinzufuegen(ohrmarke)
                                    "></kiene-ohrmarke>
                            <span
                                *ngIf="ohrmarke?.einstallgewicht"
                                style="font-size: .8em;">{{ ohrmarke?.einstallgewicht }}kg</span>
                            </span>
                        </div>

                        <div>
                            <button *ngIf="ohrmarke?.success" mat-icon-button [matMenuTriggerFor]="tierMenu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #tierMenu="matMenu">
                                <button mat-menu-item (click)="
                                        reklamationFuerScanHinzufuegen(ohrmarke)
                                    ">
                                    <mat-icon color="accent">feedback</mat-icon>
                                    <span>Reklamieren</span>
                                </button>
                                <button mat-menu-item (click)="scanLoeschen(ohrmarke)">
                                    <mat-icon color="accent">delete</mat-icon>
                                    <span>Löschen</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
