import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Client } from '../../../client/classes/client';
import { FormControl, Validators } from '@angular/forms';
import { MessageService } from '../../../../services/message-service/message.service';
import { StammdatenService } from '../../../../components/stammdaten/stammdaten.service';
import { HttpParams } from '@angular/common/http';
import { API_BASE_URL_SERVICE } from '../../../../kiene-core.config';
import { KieneBackendApiService } from '../../../../services/backend-api/kiene-backend-api.service';

@Component({
    selector: 'kiene-hit-pin-aktualisieren-dialog',
    templateUrl: './hit-pin-aktualisieren-dialog.component.html',
    styleUrls: ['./hit-pin-aktualisieren-dialog.component.scss']
})
export class HitPinAktualisierenDialogComponent implements OnInit {

    aktuellerKunde: Client;
    alleKunden: Client[] = [];
    pinCtrl = new FormControl(undefined, [Validators.required]);

    visible = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: Client[],
        private dialogRef: MatDialogRef<HitPinAktualisierenDialogComponent>,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private stammdatenService: StammdatenService) {
    }

    ngOnInit(): void {
        if (this.data && this.data.length > 0) {
            this.alleKunden = this.data;
            this.aktuellerKunde = this.alleKunden[0];
        }
    }


    pinAktualisieren() {
        this.stammdatenService.hitPinAendern(this.aktuellerKunde.kunde_id, this.pinCtrl.value).subscribe({
            next: () => {
                this.messageService.infoMessage('Die PIN wurde erfolgreich editiert!');
                this.checkHitPinAkutell();
            },
            error: err => {
                this.messageService.errorMessage('Die HIT PIN konnte nicht aktualisiert werden!', err);
            }
        });
    }

    private checkHitPinAkutell() {
        const params = new HttpParams().set('hit_pin_falsch', '1');
        this.api.get(this.apiBaseUrl + 'kunde/read_pin_falsch.php', params).subscribe({
            next: response => {
                if (response && response.records.length > 0) {
                    this.alleKunden = response.records;
                    this.aktuellerKunde = this.alleKunden[0];
                } else {
                    this.dialogRef.close();
                }
            }
        });
    }

    removeVVVO() {
        this.stammdatenService.removeVVVO(this.aktuellerKunde).subscribe({
            next: (removed: boolean) => {
                if (removed === true) {
                    this.checkHitPinAkutell();
                }
            }
        });
    }
}
