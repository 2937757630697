import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from '../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {Client} from '../../../projects/kiene-core/src/lib/components/client/classes/client';

@Component({
    selector: 'kiene-laboratory',
    templateUrl: './laboratory.component.html',
    styleUrls: ['./laboratory.component.scss']
})
export class LaboratoryComponent implements OnInit {

    currentUrl = '';
    currentClient: Client;
    embedded = false;

    constructor(private localStorageService: LocalStorageService,
                private router: Router,
                private route: ActivatedRoute) {
        this.localStorageService.setShoppingCartVisible(false);
        this.currentUrl = this.router.url;
        console.debug('Labor current url: ' + this.currentUrl);
        if (this.route.snapshot.data['embedded']) {
            this.embedded = this.route.snapshot.data['embedded'];
        }
    }

    ngOnInit() {
        this.currentClient = this.localStorageService.getCurrentClient();

        this.localStorageService.watchCurrentClient().subscribe(client => {
            this.currentClient = client;
        });
    }

    isTabMenuVisible(path: string) {

        return this.localStorageService.currentUserCanSeeTab(path);
    }

    isBelegeMenu(): boolean {
        return this.currentUrl.startsWith('/belege');
    }


}
