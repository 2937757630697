import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Entsorger} from '../entsorger';
import {KieneBackendApiService} from '../../../../services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../kiene-core.config';
import {MessageService} from '../../../../services/message-service/message.service';

@Component({
    selector: 'kiene-entsorger-anlegen-dialog',
    templateUrl: './entsorger-anlegen-dialog.component.html',
    styleUrls: ['./entsorger-anlegen-dialog.component.scss']
})
export class EntsorgerAnlegenDialogComponent implements OnInit {
    nameCtrl = new FormControl(undefined, [Validators.required]);
    strasseCtrl = new FormControl();
    plzCtrl = new FormControl();
    ortCtrl = new FormControl();
    vvvoCtrl = new FormControl(undefined, [Validators.maxLength(15)]);
    emailCtrl = new FormControl(undefined, [Validators.email, Validators.required]);

    constructor(public dialogRef: MatDialogRef<EntsorgerAnlegenDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private entsorgerName: string,
                private api: KieneBackendApiService,
                private messageService: MessageService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    ngOnInit(): void {
        console.log('entsorgerName: ' + this.entsorgerName);
        if (this.entsorgerName) {
            this.nameCtrl.setValue(this.entsorgerName);
        }
    }

    anlegen() {
        const entsorger = new Entsorger();
        entsorger.name = this.nameCtrl.value;
        entsorger.strasse = this.strasseCtrl.value;
        entsorger.plz = this.plzCtrl.value;
        entsorger.ort = this.ortCtrl.value;
        entsorger.vvvo = this.vvvoCtrl.value;
        entsorger.email = this.emailCtrl.value;

        this.api.post(this.apiBaseUrl + 'entsorger/create_kundenportal.php', entsorger).subscribe({
            next: response => {
                this.dialogRef.close(response);
            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Anlegen eines Entsorgers!', err);
            }
        });
    }
}
