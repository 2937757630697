<div style="padding: 0 24px 64px 24px">

    <div class="one-row" style="margin: 24px 0px;">

        <kiene-page-header [showReturnButton]="showReturnButton" [ueberschrift]="'Meine Futtermittellieferanten'"
                           [routeRelativeAlternative]="'../../'"></kiene-page-header>

    </div>

    <ng-container *ngFor="let betrieb of betriebe">
        <h3>{{ betrieb?.bezeichnung_final }}</h3>
        <kiene-key-value-list>
            <kiene-key-value-list-item [key]="'Zusatz'" [value]="betrieb?.zusatz_final"></kiene-key-value-list-item>
            <kiene-key-value-list-item [key]="'VVVO'" [value]="betrieb?.vvvo"></kiene-key-value-list-item>
            <kiene-key-value-list-item [key]="'Futtermittellieferant'"
                                       [value]="betrieb?.futtermittellieferant ? betrieb?.futtermittellieferant : '- kein Eintrag -' "
                                       [actionButtonIcon]="betrieb?.futtermittellieferant ? 'delete' : 'add'"
                                       (actionClicked)="changeFuttermittellieferant(betrieb)"
            ></kiene-key-value-list-item>
        </kiene-key-value-list>

    </ng-container>

</div>
