import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {VetGroup, VetGroupApi} from './vet-group';
import {Observable} from 'rxjs';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable({
    providedIn: 'root'
})
export class VetGroupService {

    constructor(private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    getAllVetGroups(sortColumn: string, sortDirection: string, offset: number, limit: number, searchString: string): Observable<VetGroupApi> {
        const orderby: string = sortColumn + ' ' + sortDirection;
        const parameters: HttpParams = new HttpParams()
            .set('orderby', orderby)
            .set('limit', limit.toString())
            .set('offset', offset.toString())
            .set('search', searchString);
        return this.http.get<VetGroupApi>(this.apiBaseUrl + 'tierarztgruppe/read.php', {params: parameters});
    }

    getAll(): Observable<VetGroupApi> {
        const parameters: HttpParams = new HttpParams()
            .set('orderby', 'bezeichnung asc');
        return this.http.get<VetGroupApi>(this.apiBaseUrl + 'tierarztgruppe/read.php', {params: parameters});
    }

    getAllVetGroupsWithoutSort(): Observable<VetGroupApi> {
        return this.http.get<VetGroupApi>(this.apiBaseUrl + 'tierarztgruppe/read.php');
    }

    deleteVetGroup(vetGroup: VetGroup) {
        return this.http.post(this.apiBaseUrl + 'tierarztgruppe/delete.php', vetGroup);
    }

    updateVetGroup(vetGroup: VetGroup): Observable<VetGroup> {
        return this.http.post<VetGroup>(this.apiBaseUrl + 'tierarztgruppe/update.php', vetGroup);
    }

}
