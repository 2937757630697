import { WertAnzahl } from '../../../../../../../src/app/gruppen/gruppe-details/gewichte-preise/gewichte-preise';

export class GruppeStandort {
    gs_id: number;
    gruppe_id: number;
    gruppe: string;
    stall: string;
    datum_von: Date;
    datum_bis: Date;
    produktionsabschnitt_id: number;
    produktionsabschnitt: string;
    sgs_nummer: string;
    anzahl_tiere: number;

    einstallgewicht_avg: WertAnzahl;
    abgangsgewicht_avg: WertAnzahl;
    tageszunahmen_avg: WertAnzahl;
    einkaufspreis_avg: WertAnzahl;
    verkaufspreis_avg: WertAnzahl;
    erloesdifferenz_avg: WertAnzahl;

    einstallgewicht: number;
}
