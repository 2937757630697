export class HITQSCharge {
    charge_nr: string;
    chargenmenge: number;
}

export class HITQSMeldung {
    vvvo: string;
    tierarzt: string;
    chargen: HITQSCharge[] = [];
}

export class Meldung {
    meldung_id: number;
    abgabebeleg_id: number;
    fehler: string;
    meldung_an: string;
    gruppe_id: number;
    gruppe: string;
    kunde: string;
    kunde_id: number;
    betrieb_id: number;
    zusatz: string;
    vvvo: string;
    gemeldet: Date;
    status_id: number;
    status_id_neu: number;
    status: string;
    artikel: string;
    jahr: number;
    halbjahr: number;
    meldung: HITQSMeldung;
}
