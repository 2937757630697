import {Component, OnInit, ViewChild} from '@angular/core';
import {Artikelliste} from '../../../beleg-position/artikelliste-auswahl-dialog/artikelliste-auswahl-dialog';
import {
    ArticleScanSelectedEvent
} from '../../../../components/article-scan-autocomplete/article-scan-autocomplete.component';
import {ArtikelTierart, ArtikelTierartEvent} from '../../../../components/tierart-selector/artikel-tierart';
import {AnwendungEvent} from '../../../behandlungsanfrage/anwendung';
import {IndikationEvent} from '../../../../warehouse/material/artikel-indikation/indikation';
import {FormControl} from '@angular/forms';
import {TierartSelectorComponent} from '../../../../components/tierart-selector/tierart-selector.component';
import {AnwendungsSelectorComponent} from '../../../../components/anwendungs-selector/anwendungs-selector.component';
import {
    DiagnoseSelectorComponent
} from '../../../../components/diagnose-selector/diagnose-selector/diagnose-selector.component';

@Component({
    selector: 'kiene-beleg-vorlage-details-dialog',
    templateUrl: './beleg-vorlage-details-dialog.component.html',
    styleUrls: ['./beleg-vorlage-details-dialog.component.scss']
})
export class BelegVorlageDetailsDialogComponent implements OnInit {
    artikelliste: Artikelliste;

    disabled = false;
    behandlungJaNeinCtrl = new FormControl();

    @ViewChild('tierartSelector') tierartSelector: TierartSelectorComponent;
    @ViewChild('anwendungSelector') anwendungSelector: AnwendungsSelectorComponent;
    @ViewChild('diagnoseSelector') diagnoseSelector: DiagnoseSelectorComponent;

    artikelTierart: ArtikelTierart;

    constructor() {
    }

    ngOnInit(): void {
    }

    setSelectedMaterial(event: ArticleScanSelectedEvent) {
        this.tierartSelector.ladeTierartenFuerMaterialUndKunde('setSelectedMaterial', event.article?.artikel_id, true, true);
    }

    materialInputCleared() {

    }

    setSelectedTierart(event: ArtikelTierartEvent) {
        this.artikelTierart = event.artikelTierart;
        this.anwendungSelector.loadAnwendungen(event.artikelTierart.artikel_id, event.artikelTierart.tierart_id, true, true);
    }

    setSelectedAnwendung(event: AnwendungEvent) {
        this.diagnoseSelector.loadDiagnosen(event.anwendung.artikel_id, this.artikelTierart.tierart_id, event.anwendung.anwendung_id, true, true);
    }

    setSelectedDiagnose($event: IndikationEvent) {

    }
}
