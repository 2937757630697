import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Vet, VetApi } from './vet';
import { Observable } from 'rxjs';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable({
  providedIn: 'root'
})
export class VetService {

  constructor(private http: HttpClient,
    @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
  }

  getVetForId(id: number): Observable<Vet> {
    const params = new HttpParams().set('tierarzt_id', id.toString());
    return this.http.get<Vet>(this.apiBaseUrl + 'tierarzt/read_one.php', { params: params });
  }

  deleteVet(vet: Vet) {
    return this.http.post(this.apiBaseUrl + 'tierarzt/delete.php', vet);
  }

  createVet(vet: Vet): Observable<Vet> {
    return this.http.post<Vet>(this.apiBaseUrl + 'tierarzt/create.php', vet);
  }

}
