import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GoodsReceiptHistoryApi} from './goods-receipt-history.component';
import {HttpClient, HttpParams} from '@angular/common/http';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable({
    providedIn: 'root'
})
export class GoodsReceiptHistoryService {

    constructor(private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    getGoodsReceiptHistory(sortColumn: string, sortDirection: string, offset: number, limit: number, searchString: string, zeit_von: Date, zeit_bis: Date): Observable<GoodsReceiptHistoryApi> {
        var parameters: HttpParams = new HttpParams()
            .set('orderby', sortColumn + ' ' + sortDirection)
            .set('limit', limit.toString())
            .set('offset', offset.toString())
            .set('search', searchString)
            .set('zeit_von', zeit_von != null ? zeit_von.toISOString() : '')
            .set('zeit_bis', zeit_bis != null ? zeit_bis.toISOString() : '');

        return this.http.get<GoodsReceiptHistoryApi>(this.apiBaseUrl + 'wareneingang/read.php', {params: parameters});
    }

    searchGoodsReceiptHistory(searchString: string): Observable<GoodsReceiptHistoryApi> {
        return this.http.get<GoodsReceiptHistoryApi>(this.apiBaseUrl + 'wareneingang/read.php?search=' + searchString);
    }
}
