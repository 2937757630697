import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QRCodeDialogComponent } from '../qrcode-dialog/qrcode-dialog.component';
import { Signature } from '../signature';
import { MessageService } from '../../../services/message-service/message.service';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';

@Component({
    selector: 'kiene-sign-button',
    templateUrl: './sign-button.component.html',
    styleUrls: ['./sign-button.component.scss'],
})
export class SignButtonComponent implements OnInit, OnChanges {
    @Input('dokumenttyp') dokumenttyp: string;
    @Input('dokument_id') dokument_id: number;
    @Input('tierarzt_id') tierarzt_id: number;
    @Input('kunde_id') kunde_id: number;
    @Input('istBenutzer') istBenutzer: boolean;
    @Input('kuid') kuidInput: string;
    @Input('disabled') disabled = false;
    @Input('buttonText') buttonText = 'Unterschreiben';
    signature: Signature;

    @Output('kuid') kuid = new EventEmitter<string>();

    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl,
        public dialog: MatDialog,
        private api: KieneBackendApiService,
        private notification: MessageService
    ) {}

    ngOnInit() {}

    ngOnChanges() {}

    prepareSigning() {
        if (this.kuidInput) {
            this.signature.kuid = this.kuidInput;
            this.dialog.open(QRCodeDialogComponent, {
                width: '300px',
                minWidth: '300px',
                data: {
                    signature: this.signature,
                },
            });
        } else {
            // erzeugt eine KUID für Unterschrift auf Handy
            this.api
                .createGeneric(
                    this.apiBaseUrl + 'unterschrift/create.php',
                    this.signature
                )
                .subscribe({
                    next: (response: Signature) => {
                        this.signature = response;
                        this.kuid.emit(this.signature.kuid);
                        this.dialog.open(QRCodeDialogComponent, {
                            width: '300px',
                            minWidth: '300px',
                            data: {
                                signature: this.signature,
                            },
                        });
                    },
                    error: (error) => {
                        if (error.status === 400) {
                            this.notification.alertMessage(
                                error.error.errortext
                            );
                        } else {
                            this.notification.errorMessage(
                                'Es ist ein Fehler aufgetreten: ',
                                error
                            );
                        }
                    },
                });
        }
    }

    signDocument() {
        if (!this.signature) {
            this.signature = new Signature();
        }
        this.signature.dokument_id = this.dokument_id;
        this.signature.dokumenttyp = this.dokumenttyp;
        this.signature.tierarzt_id = this.tierarzt_id;
        this.signature.kunde_id = this.kunde_id;
        this.signature.benutzer = this.istBenutzer === true ? 1 : 0;
        this.prepareSigning();
    }
}
