<h1 mat-dialog-title>Dateien f&uuml;r Befundmitteilung {{befundmitteilung?.tagebuch_nr}}</h1>
<div mat-dialog-content>
    <mat-list>
        <mat-list-item *ngFor="let eb of externeBefunde; let i = index;">
            <mat-icon mat-list-icon>insert_drive_file</mat-icon>
            <div mat-line>
                <div class="one-row">
                    <p style="cursor: pointer;" (click)="openFile(eb)">{{eb.dateiname}}</p>
                    <kiene-delete-button *kienePermission="[1324]" (delete)="delete(eb, i)"></kiene-delete-button>
                </div>

            </div>
        </mat-list-item>
    </mat-list>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="close()">Schlie&szlig;en</button>
</div>