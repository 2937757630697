import {Component, OnInit, AfterViewInit} from '@angular/core';
import {Supplier} from './supplier';
import {TableDescriptor} from '../../components/kiene-table/table-descriptor';
import {MatDialog} from '@angular/material/dialog';
import {MessageService} from '../../services/message.service';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {environment} from './../../../environments/environment';
import {TableColumn} from '../../components/kiene-table/table-column';
import {TableOption, TableOptionEvent} from '../../components/kiene-table/table-option';
import {ConfirmDeleteDialogComponent} from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';


@Component({
    selector: 'app-supplier',
    templateUrl: './supplier.component.html',
    styleUrls: ['./supplier.component.scss']
})
export class SupplierComponent implements OnInit {

    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(public dialog: MatDialog,
                private messageService: MessageService,
                private localStorageService: LocalStorageService,
                private tableService: KieneTableService) {
    }

    ngOnInit() {

        this.tableDescriptor.headline = 'Lieferanten';
        this.tableDescriptor.uniqueIdentifier = 'SupplierLieferanten';
        this.tableDescriptor.apiUrl = environment.BASE_URI + 'lieferant/';
        this.tableDescriptor.route = '/lager/lieferanten/';
        this.tableDescriptor.nameOfIdField = 'lieferant_id';
        this.tableDescriptor.searchPlaceholder = 'Lieferanten durchsuchen';

        this.tableDescriptor.columns.push(new TableColumn('lieferant_id', 'Nr.'));
        this.tableDescriptor.columns.push(new TableColumn('bezeichnung', 'Bezeichnung'));
        this.tableDescriptor.columns.push(new TableColumn('bezeichnung_1', 'Bez. 1'));
        this.tableDescriptor.columns.push(new TableColumn('bezeichnung_2', 'Bez. 2'));
        this.tableDescriptor.columns.push(new TableColumn('bezeichnung_3', 'Bez. 3'));
        this.tableDescriptor.columns.push(new TableColumn('bezeichnung_kurz', 'Bez. kurz'));
        this.tableDescriptor.columns.push(new TableColumn('strasse', 'Strasse'));
        this.tableDescriptor.columns.push(new TableColumn('plz', 'PLZ'));
        this.tableDescriptor.columns.push(new TableColumn('ort', 'Ort'));
        this.tableDescriptor.columns.push(new TableColumn('lieferant_nr_intern', 'Interne Nr.'));

        this.tableDescriptor.permissionCreate = 1072;
        this.tableDescriptor.permissionDelete = 1074;
        this.tableDescriptor.permissionUpdate = 1073;
        this.tableDescriptor.permissionView = 1071;

        this.tableDescriptor.options.push(new TableOption('delete', 'löschen', 'delete', 1074));

        this.tableDescriptor.initialSortColumn = 'erstellt';
        this.tableDescriptor.initialSortDirection = 'desc';

    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        }
    }

    openDeleteDialog(supplier: Supplier) {
        let dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: 'Lieferant ' + supplier.bezeichnung + ' löschen?',
                text: 'Wollen Sie den Lieferanten ' + supplier.bezeichnung + ' wirklich löschen?'
            }
        });

        dialogRef.afterClosed().subscribe(response => {
            if (response) {
                this.tableService.deleteEntity(environment.BASE_URI + 'lieferant/delete.php', supplier).subscribe(result => {
                    this.messageService.infoMessage('Der Lieferant wurde erfolgreich gelöscht!');
                    this.tableService.reload();
                }, error => {
                    this.messageService.errorMessage('Der Lieferant konnte nicht gelöscht werden! ', error);
                });
            }
        });
    }

}

