import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL_SERVICE } from '../../kiene-core.config';
import { GruppeTier } from './classes/gruppe-tier';
import { Observable, Subject } from 'rxjs';
import { Tierreklamation } from './classes/tierreklamation';
import { KieneBackendApiService } from '../../services/backend-api/kiene-backend-api.service';
import { Tierbemerkung } from './classes/tierbemerkung';
import { ConfirmDialogInput, ConfirmDialogResult } from '../dialogs/confirm-dialog/confirm-dialog';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { Gruppe } from '../gruppen/classes/gruppe';
import { GruppeAuswaehlenDialogComponent } from '../gruppen/gruppe-auswaehlen-dialog/gruppe-auswaehlen-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from '../../services/message-service/message.service';
import { GruppenService } from '../gruppen/gruppen.service';
import { GruppeAuswaehlenDialogInput, GruppeAuswaehlenDialogResult } from '../gruppen/gruppe-auswaehlen-dialog/gruppe-auswaehlen-dialog';

@Injectable({
    providedIn: 'root',
})
export class GruppeTierService {
    constructor(
        private api: KieneBackendApiService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private dialog: MatDialog,
        private messageService: MessageService,
        private gruppenService: GruppenService
    ) {
    }

    ladeTier(gt_id: number) {
        return this.api.getByIdAlternative(
            `${this.apiBaseUrl}gruppe_tier/read_one_kundenportal.php`,
            'gt_id',
            gt_id.toString()
        );
    }

    todMelden(
        gruppeTierId: number,
        kp_todesdatum: Date,
        entsorgerId: number,
        todesart_id: number
    ) {
        const gt = new GruppeTier();
        gt.gt_id = gruppeTierId;
        gt.kp_todesdatum = kp_todesdatum;
        gt.entsorger_id = entsorgerId;
        gt.todesart_id = todesart_id;
        return this.api.post(
            `${this.apiBaseUrl}gruppe_tier/tod_kundenportal.php`,
            gt,
            null
        );
    }

    deleteTier(tier: GruppeTier): Observable<any> {
        return this.api.deleteGeneric(
            this.apiBaseUrl + 'gruppe_tier/delete_kundenportal.php',
            tier
        );
    }

    updateTierreklamation(tierreklamation: Tierreklamation): Observable<any> {
        return this.api.updateGeneric(
            `${this.apiBaseUrl}tierreklamation/update_kundenportal.php`,
            tierreklamation
        );
    }


    einstalldatumHitAendern(tiere: GruppeTier[], einstalldatum_neu: Date) {
        return this.api.post(
            this.apiBaseUrl + 'gruppe_tier/einstalldatum_aendern.php',
            {
                einstalldatum_neu: einstalldatum_neu,
                gruppen_tiere: tiere,
            },
            null
        );
    }

    abgangsdatumHitAendern(tiere: GruppeTier[], abgangsdatum_neu: Date) {
        return this.api.post(
            this.apiBaseUrl + 'gruppe_tier/abgangsdatum_aendern.php',
            {
                abgangsdatum_neu: abgangsdatum_neu,
                gruppen_tiere: tiere,
            },
            null
        );
    }

    update(gruppeTier: GruppeTier) {
        return this.api.updateGeneric(
            `${this.apiBaseUrl}gruppe_tier/update_kundenportal.php`,
            gruppeTier
        );
    }

    createOrUpdateBemerkung(bemerkung: Tierbemerkung) {
        return this.api.updateGeneric(
            `${this.apiBaseUrl}gruppe_tier_bemerkung/update_kundenportal.php`,
            bemerkung
        );
    }

    deleteBemerkung(bemerkung: Tierbemerkung) {
        return this.api.deleteGeneric(
            this.apiBaseUrl + 'gruppe_tier_bemerkung/delete_kundenportal.php',
            bemerkung
        );
    }

    gruppeAendern(gruppeTiere: GruppeTier[], gruppe_id_alt: number, gruppe_id_neu: number) {
        const gt_ids = [];
        for (const gt of gruppeTiere) {
            gt_ids.push(gt.gt_id);
        }
        const object = {
            gruppe_id_alt: gruppe_id_alt,
            gruppe_id_neu: gruppe_id_neu,
            gt_ids: gt_ids
        }
        return this.api.updateGeneric(
            this.apiBaseUrl + 'gruppe_tier/update_gruppe.php',
            object
        );
    }

    ohrmarkeAlsGescanntMarkieren(gruppe_id: number, gruppeTiere: GruppeTier[]): Observable<boolean> {
        const subject = new Subject<boolean>();
        const input = new ConfirmDialogInput();
        input.headline = 'Ohrmarke(n) als gescannt markieren';
        input.text = 'Wollen Sie die Ohrmarke(n) als gescannt markieren? Dieser Vorgang kann nicht rückgängig gemacht werden.';
        input.okButton = 'Markieren';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    let gt_ids = null;
                    if (gruppeTiere) {
                        gt_ids = [];
                        for (const gt of gruppeTiere) {
                            gt_ids.push(gt.gt_id);
                        }
                    }

                    const obj = { gruppe_id: gruppe_id, ohrmarken_gescannt: 1, gt_ids: gt_ids };
                    this.api.updateGeneric(this.apiBaseUrl + 'gruppe_tier/alle_ohrmarken_gescannt.php', obj).subscribe({
                        next: () => {
                            this.messageService.infoMessage('Alle Ohrmarken als gescannt markiert!');
                            subject.next(true);
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler!', err);
                            subject.next(false);
                        }
                    });
                } else {
                    subject.next(false);
                }
            }
        });

        return subject.asObservable();
    }

    gruppeAendernConfirm(gruppeTiere: GruppeTier[]): Observable<boolean> {
        const subject = new Subject<boolean>();
        if (gruppeTiere?.length > 0) {
            // Prüfen ob alle Tiere aus derselben Gruppe
            const gruppe_id_alt = gruppeTiere[0].gruppe_id;
            for (const tier of gruppeTiere) {
                if (tier.gruppe_id !== gruppe_id_alt) {
                    subject.next(false);
                    return subject.asObservable();
                }
            }

            const input = new ConfirmDialogInput();
            input.headline = 'Gruppe ändern?';
            input.text =
                'Diese Funktion bitte nur verwenden, um falsch eingepflegte Tiere in eine andere Gruppe zu verschieben und nicht fürs Umstallen!';
            input.okButton = 'Gruppe ändern';

            const dialogRef1 = this.dialog.open(ConfirmDialogComponent, {
                data: input,
            });
            dialogRef1.afterClosed().subscribe({
                next: (result1: ConfirmDialogResult) => {
                    if (result1?.confirmed) {
                        this.gruppenService.getGruppeKundenportal(gruppe_id_alt).subscribe({
                            next: (gruppe: Gruppe) => {
                                const input = new GruppeAuswaehlenDialogInput();
                                input.kunde_id = gruppe.kunde_id;
                                input.except_gruppe_id = gruppe_id_alt;
                                this.dialog.open<GruppeAuswaehlenDialogComponent, GruppeAuswaehlenDialogInput, GruppeAuswaehlenDialogResult>(GruppeAuswaehlenDialogComponent,
                                    {
                                        data: input
                                    }).afterClosed().subscribe({
                                        next: (result2: GruppeAuswaehlenDialogResult) => {
                                            if (result2?.confirmed) {
                                                this.gruppeAendern(gruppeTiere, gruppe_id_alt, result2?.gruppe?.gruppe_id)
                                                    .subscribe({
                                                        next: (gt: GruppeTier) => {
                                                            this.messageService.infoMessage(
                                                                'Gruppe erfolgreich geändert!'
                                                            );
                                                            subject.next(true);
                                                        },
                                                        error: (err) => {
                                                            this.messageService.errorMessage(
                                                                'Gruppe konnte nicht geändert werden!',
                                                                err
                                                            );
                                                            subject.next(false);
                                                        },
                                                    });
                                            } else {
                                                subject.next(false);
                                            }
                                        },
                                    });
                            },
                            error: err => {
                                this.messageService.errorMessage('Fehler!', err);
                                subject.next(false);
                            }
                        });
                    } else {
                        subject.next(false);
                    }
                },
            });
        } else {
            subject.next(false);
        }
        return subject.asObservable();
    }

}
