<div style="

        padding: 6px;
        background-color: white;
    ">
    <!-- <fieldset class="position-fieldset">
        <legend>
            <div style="white-space: nowrap; font-size: 12px;">Position hinzuf&uuml;gen &nbsp;</div>
        </legend> -->

    <div class="one-row" style="font-size: .8em">
        <kiene-article-scan-autocomplete style="min-width: 300px; width: 15%" [disabled]="disabled" #articleAutocomplete
            [kundeMandantId]="kundeMandantId" (selectedMaterial)="setSelectedMaterial($event)"
            [placeholder]="getPlaceholderArtikel()" [gesperrtAusblenden]="true" [showPrice]="!isCurrentUserClient"
            [kundeId]="kundeId" [required]="true" [focus]="focusOnInit" (cleared)="materialInputCleared()"
            [nurAbgegebeneArtikel]="isCurrentUserClient">
        </kiene-article-scan-autocomplete>

        <kiene-tierart-selector #tierartSelector [zeigeKeine]="false" [kundeId]="kundeId" [required]="true"
            [kundeMandantId]="kundeMandantId" [disabled]="istMengenArtikel() || disabled || isCurrentUserClient"
            (tierartSelectionChanged)="setSelectedTierart($event)" style="width: 10%">
        </kiene-tierart-selector>

        <kiene-anwendungs-selector #anwendungSelector style="width: 10%" [required]="true"
            [kundeMandantId]="kundeMandantId" [disabled]="istMengenArtikel() || disabled || isCurrentUserClient"
            (change)="setSelectedAnwendung($event)">
        </kiene-anwendungs-selector>

        <kiene-diagnose-selector #diagnoseSelector style="width: 15%"
            [disabled]="istMengenArtikel() || disabled || isCurrentUserClient" [kundeMandantId]="kundeMandantId"
            [required]="true" (change)="setSelectedDiagnose($event)">
        </kiene-diagnose-selector>

        <mat-form-field *ngIf="istAbgabeUndBehandlung()" style="width: 5%">
            <mat-label>Behandlung:</mat-label>
            <mat-select #behandlungJaNein [formControl]="behandlungJaNeinCtrl" [disabled]="isCurrentUserClient">
                <mat-option [value]="true">Ja</mat-option>
                <mat-option [value]="false">Nein</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field style="width: 9%">
            <mat-label>Anzahl Tiere {{ anzahlTiereBerechnen ? "*" : "" }}</mat-label>
            <input matInput #anzahlTiere="matInput" type="number" [formControl]="anzahlTiereCtrl"
                (focus)="onFocusAnzahlTiere()" (blur)="onBlurAnzahlTiere()" />
            <span *ngIf="gruppe?.anzahl_tiere_aktuell" matSuffix>/ {{ gruppe.anzahl_tiere_aktuell }}</span>
        </mat-form-field>

        <mat-form-field style="width: 8%">
            <mat-label>Anwendungsdauer</mat-label>
            <input matInput type="number" [formControl]="anwendungsdauerCtrl" (focus)="onFocusNumberInput()"
                (blur)="onBlurNumberInput()" />
        </mat-form-field>

        <mat-form-field style="width: 8%">
            <mat-label>Menge {{ mengeExakt }}</mat-label>
            <input #mengeInput="matInput" matInput type="number" [formControl]="mengeCtrl"
                (focus)="onFocusNumberInput()" (blur)="onBlurNumberInput()" />
        </mat-form-field>

        <mat-form-field style="width: 8%">
            <mat-label>Tiergewicht</mat-label>
            <input matInput type="number" maxlength="15" [formControl]="tiergewichtCtrl" (focus)="onFocusTiergewicht()"
                (blur)="onBlurNumberInput()" />
        </mat-form-field>

        <mat-form-field style="width: 12%">
            <mat-label>{{
                position?.dosierung_txt ? position.dosierung_txt : "Dosierung"
                }}
            </mat-label>

            <input matInput type="number" [formControl]="dosierungCtrl" (focus)="onFocusDosierung()"
                (blur)="onBlurNumberInput()" />
        </mat-form-field>
    </div>

    <div class="one-row" style="font-size: .7em">
        <button #identifikationButton mat-stroked-button color="accent" [disabled]="disabled || !position" (focus)="
                !justOpenedIdentifikationDialog
                    ? openIdentifikationDialog()
                    : null
            " (click)="openIdentifikationDialog()" style="margin-right: 12px">
            <mat-icon>open_in_new</mat-icon>
            Identifikation
        </button>

        <span *ngIf="position" style="margin-right: 12px">
            {{ istPositionBehandlung() ? "(B)" : "(A)" }}
        </span>

        <span *ngIf="position?.prophylaxe>0" style="margin-right: 12px">
            (P)
        </span>

        <span *ngIf="position?.metaphylaxe>0" style="margin-right: 12px">
            (M)
        </span>

        <span style="margin-right: 12px" *ngIf="
                position?.wartezeit !== undefined &&
                position?.wartezeit !== null &&
                position?.wartezeit >= 0
            ">Wartezeit Fl.:
            {{ position?.wartezeit }}
            Tage
        </span>

        <span *ngIf="position?.einzelpreis" style="white-space: nowrap; margin-right: 12px">
            Einzelpreis:
            {{ position?.einzelpreis | number: "1.2-2" }} &euro;</span>

        <button style="margin-left: 12px; margin-right: 4px" mat-stroked-button type="button" *ngIf="!keineChargen"
            (click)="openAddChargeDialog()" [disabled]="!istChargenArtikel() || disabled || isCurrentUserClient">
            <mat-icon>content_paste</mat-icon>
            {{ position?.chargen ? "(" + position?.chargen.length + ")" : "" }}
            Chargen (F8)
        </button>

        <div *ngIf="istImpfArtikel()">
            <div class="one-row">
                <mat-icon color="accent">vaccines</mat-icon>
                <span *ngIf="aktuellerKunde?.selbstimpfer === 1">Kunde ist Selbstimpfer</span>
                <span class="spacer"></span>
            </div>
        </div>

        <span class="spacer"></span>

        <p>{{ getIdentifikationText(position) }}</p>
        <!-- <span class="spacer"></span>
        <p *ngIf="position?.tiere.length > 0">Tiere: {{getTiereString(position.tiere)}}</p> -->
        <span class="spacer"></span>
        <button style="margin-right: 12px" type="button" mat-flat-button color="accent" (click)="checkPosition()"
            [disabled]="disabled">
            {{ position?.erstellt ? "speichern" : "hinzufügen" }}
        </button>

        <button mat-stroked-button (click)="felderLeeren(true)" [disabled]="disabled">
            Neue Position/Felder leeren (F9)
        </button>
    </div>

    <!-- </fieldset> -->
</div>