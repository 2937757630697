import {HttpParams} from "@angular/common/http";
import {ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {Tierrasse2,} from "../../../../../projects/kiene-core/src/lib/components/gruppe-tier/classes/tier-rasse";
import {API_BASE_URL_SERVICE} from "../../../../../projects/kiene-core/src/lib/kiene-core.config";
import {
    KieneBackendApiService
} from "../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service";
import {Tierart} from "../../../administration/tierarten/tierart";
import {
    KieneAutocompleteComponent,
    KieneAutocompleteCreateNewEvent
} from "../../../components/kiene-autocomplete/kiene-autocomplete.component";
import {MessageService} from "../../../services/message.service";
import {Heimtier, HeimtierOutput} from "../../kalender/heimtier-kalender-eintrag";
import {Client} from '../../../../../projects/kiene-core/src/lib/components/client/classes/client';
import {
    EnterValueDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-dialog.component';
import {
    EnterValueInput
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-response';

@Component({
    selector: 'kiene-heimtier-dialog',
    templateUrl: './heimtier-dialog.component.html',
    styleUrls: ['./heimtier-dialog.component.scss']
})
export class HeimtierDialogComponent implements OnInit, OnDestroy {
    @ViewChild('rasseAutocomplete') rasseAutocomplete: KieneAutocompleteComponent;

    // Form Controls
    nameCtrl: FormControl = new FormControl(undefined, Validators.required);
    alterCtrl: FormControl = new FormControl(new Date());
    geschlechtCtrl: FormControl = new FormControl(1, Validators.required);
    fellfarbeCtrl: FormControl = new FormControl(undefined);
    identifizierungCtrl: FormControl = new FormControl(undefined);
    chipnummerCtrl: FormControl = new FormControl(undefined);
    zuchtbuchnummerCtrl: FormControl = new FormControl(undefined);
    tierpassCtrl: FormControl = new FormControl(undefined);
    rasseParams: HttpParams = new HttpParams().set('tierart_id', 0);

    // Weitere Eigenschaften
    color: string;
    kunde: Client;
    tierart: Tierart;
    rasse: Tierrasse2;
    tierartAutocompleteCreateEvent: KieneAutocompleteCreateNewEvent;
    rasseAutocompleteCreateEvent: KieneAutocompleteCreateNewEvent;
    isSaving: boolean = false;
    private sub: Subscription = new Subscription();

    constructor(
        public dialogRef: MatDialogRef<HeimtierDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public tier: Heimtier,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private dialog: MatDialog,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private cdr: ChangeDetectorRef,
    ) {
    }

    ngOnInit(): void {
        if (this.tier?.name) {
            this.nameCtrl.setValue(this.tier.name);
        }

        if (this.tier?.geburtsdatum) {
            this.alterCtrl.setValue(this.tier.geburtsdatum);
        }
        if (this.tier?.geschlecht_id) {
            this.geschlechtCtrl.setValue(this.tier.geschlecht_id);
        }
        if (this.tier?.fellfarbe) {
            this.fellfarbeCtrl.setValue(this.tier.fellfarbe);
        }
        if (this.tier?.identifizierung) {
            this.identifizierungCtrl.setValue(this.tier.identifizierung);
        }
        if (this.tier?.chipnummer) {
            this.chipnummerCtrl.setValue(this.tier.chipnummer);
        }
        if (this.tier?.zuchtbuchnummer) {
            this.zuchtbuchnummerCtrl.setValue(this.tier.zuchtbuchnummer);
        }
        if (this.tier?.eu_tierpass) {
            this.tierpassCtrl.setValue(this.tier.eu_tierpass);
        }
        if (this.tier?.tierrasse) {
            this.rasse = new Tierrasse2();
            this.rasse.name = this.tier.tierrasse;
            this.rasse.tierrasse_id = this.tier.tierrasse_id;

        }

        if (this.tier?.tierart_id) {
            this.tierart = new Tierart();
            this.tierart.tierart_id = this.tier.tierart_id;
            this.tierart.tierart = this.tier.tierart;
        }

        this.tierartAutocompleteCreateEvent = new KieneAutocompleteCreateNewEvent();
        this.tierartAutocompleteCreateEvent.permission = [2222];
        this.tierartAutocompleteCreateEvent.buttonText = 'Tierart anlegen';

        this.rasseAutocompleteCreateEvent = new KieneAutocompleteCreateNewEvent();
        this.rasseAutocompleteCreateEvent.permission = [2072];
        this.rasseAutocompleteCreateEvent.buttonText = 'Rasse anlegen';

    }

    ngOnDestroy(): void {
        this.sub?.unsubscribe();
    }

    public save() {
        this.isSaving = true;
        this.updateTierAttributes();
        this.api.post(this.apiBaseUrl + 'heimtier/update.php', this.tier).subscribe({
            next: (response) => {
                // const heimtierOutput = new HeimtierOutput();
                // heimtierOutput.heimtier = this.tier;
                // heimtierOutput.confirm = true;
                // this.isSaving = false;
                // this.dialogRef.close(heimtierOutput);
                const heimtierOutput = new HeimtierOutput();
                heimtierOutput.heimtier = response;
                heimtierOutput.confirm = true;
                this.isSaving = false;
                this.dialogRef.close(heimtierOutput);

            },
            error: err => {
                this.messageService.errorMessage('Fehler beim Speichern des Tieres', err);
                this.isSaving = false;
            }
        });
    }

    public isValid() {
        return this.nameCtrl.valid && this.tierart && this.rasse;
    }

    /**
     * Methode zur Auswahl der Tierart
     * @param tierart Ausgewählte Tierart
     */
    public setTierart(tierart: Tierart): void {
        // Aktualisieren der Tierart-Informationen
        this.tier.tierart_id = tierart?.tierart_id;
        this.tier.tierart = tierart?.tierart;
        this.tierart = tierart;

        this.rasseParams = new HttpParams().set('tierart_id', tierart?.tierart_id);


        // // Leeren des Rassenfeldes und Triggern der Suche
        // // Schritt 1: Setzen eines Nicht-Leeren Wertes, um die Suche zu erzwingen
        // this.rasseCtrl.setValue('a', { emitEvent: true });
        // // Schritt 2: Zurücksetzen auf einen leeren Wert, um die Suche basierend auf den neuen Params zu triggern
        // this.rasseCtrl.setValue('', { emitEvent: true });
        //
        // // Schritt 3: Manuelles Auslösen der Änderungserkennung
        // this.cdr.detectChanges();
        //
        // // Schritt 4: Programmatisches Öffnen des Autocomplete-Panels nach einer kleinen Verzögerung
        // setTimeout(() => {
        //     if (this.rasseAutocomplete && this.rasseAutocomplete.trigger) {
        //         this.rasseAutocomplete.setFocus();
        //         this.rasseAutocomplete.trigger.openPanel();
        //         console.log('Rassen-Autocomplete-Panel geöffnet');
        //     } else {
        //         console.warn('MatAutocompleteTrigger ist nicht verfügbar');
        //     }
        // }, 100); // Leichte Verzögerung, um sicherzustellen, dass die Suche abgeschlossen ist
    }

    /**
     * Methode zur Auswahl der Rasse
     * @param rasse Ausgewählte Rasse
     */
    public setRasse(rasse: Tierrasse2): void {
        this.rasse = rasse;
    }

    /**
     * Methode zum Erstellen einer neuen Tierart
     */
    public createTierart(name?: string): void {
        const input = new EnterValueInput();
        input.title = 'Tierart anlegen';
        input.text = 'Bitte geben Sie den Namen der Tierart ein.';
        input.addInputValue('Tierart', 'text', name);
        input.okButton = 'Anlegen';
        this.dialog.open(EnterValueDialogComponent, {data: input}).afterClosed().subscribe({
            next: result => {
                console.log('Ergebnis:', result);
                if (result?.result) {
                    const tierart = new Tierart();
                    tierart.bezeichnung = result.getValue(0);
                    this.api.post(this.apiBaseUrl + 'tierart/update.php', tierart).subscribe({
                        next: (response: Tierart) => {
                            this.tierart = response;
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler beim Anlegen der Tierart', err);
                        }
                    });
                }
            }
        });
        // const tierart = new TierRasse();
        // tierart.name = this.tier.tierart;

        // const dialogRef = this.dialog.open(TierartSonstigeDialogComponent, {
        //     width: '500px',
        // });
        //
        // dialogRef.afterClosed().subscribe({
        //     next: (value: TierartOutput) => {
        //         if (value?.confirm) {
        //             this.tierartCtrl.setValue(value.tierart);
        //         }
        //     }
        // });
    }

    /**
     * Methode zum Erstellen einer neuen Rasse
     */
    public createRasse(): void {
        //
        // const dialogRef = this.dialog.open(SonstigeRassenDialogComponent, {
        //     width: '500px',
        //     data: this.tierartCtrl.value
        // });
        //
        // dialogRef.afterClosed().subscribe({
        //     next: value => {
        //         if (value?.confirm) {
        //             this.setRasse(value.rasse);
        //         }
        //     }
        // });

        const input = new EnterValueInput();
        input.title = 'Tierrasse für ' + this.tierart?.bezeichnung + ' anlegen';
        input.text = 'Bitte geben Sie den Namen der Rasse ein.';
        input.addInputValue('Rasse', 'text');
        input.okButton = 'Anlegen';
        this.dialog.open(EnterValueDialogComponent, {data: input}).afterClosed().subscribe({
            next: result => {
                console.log('Ergebnis:', result);
                if (result?.result) {
                    const rasse = new Tierrasse2();
                    rasse.name = result.getValue(0);
                    rasse.tierart_id = this.tierart?.tierart_id;
                    this.api.post(this.apiBaseUrl + 'tierrasse/update.php', rasse).subscribe({
                        next: (response: Tierrasse2) => {
                            this.rasse = response;
                        },
                        error: err => {
                            this.messageService.errorMessage('Fehler beim Anlegen der Tierart', err);
                        }
                    });
                }
            }
        });
    }


    /**
     * Methode zur Aktualisierung der Tierattribute vor dem Speichern
     */
    private updateTierAttributes(): void {
        this.tier.name = this.nameCtrl.value;
        this.tier.tierart = this.tierart.bezeichnung
        this.tier.tierart_id = this.tierart.tierart_id;
        this.tier.tierrasse = this.rasse.name;
        this.tier.tierrasse_id = this.rasse.tierrasse_id;
        this.tier.geburtsdatum = this.alterCtrl.value;
        this.tier.geschlecht_id = this.geschlechtCtrl.value;
        this.tier.geschlecht = this.geschlechtCtrl.value === 1 ? 'männlich' : 'weiblich';
        this.tier.fellfarbe = this.fellfarbeCtrl.value;
        this.tier.identifizierung = this.identifizierungCtrl.value;
        this.tier.chipnummer = this.chipnummerCtrl.value;
        this.tier.zuchtbuchnummer = this.zuchtbuchnummerCtrl.value;
        this.tier.eu_tierpass = this.tierpassCtrl.value;


    }
}
