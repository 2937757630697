import { API_BASE_URL_SERVICE } from '../../../../../projects/kiene-core/src/lib/kiene-core.config';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HeimtierKalenderEintrag } from '../../kalender/heimtier-kalender-eintrag';
import { KieneBackendApiService } from '../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';

@Component({
  selector: 'kiene-heimtier-bezahlung-dialog',
  templateUrl: './heimtier-bezahlung-dialog.component.html',
  styleUrls: ['./heimtier-bezahlung-dialog.component.scss']
})
export class HeimtierBezahlungDialogComponent implements OnInit {

  isSaving = false;
  methode: string;

  constructor(
    public dialogRef: MatDialogRef<HeimtierBezahlungDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public kalenderEintrag: HeimtierKalenderEintrag,
    @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
    private api: KieneBackendApiService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public save(): void {
    this.isSaving = true;
    this.api.post(this.apiBaseUrl + '', null).subscribe({
      next: (result) => {
        this.isSaving = false;
        this.dialogRef.close(result);
      },
      error: err => {
        this.isSaving = false;
        this.messageService.errorMessage('Fehler!', err);
      }
    });
  }

}
