<h1 mat-dialog-title>Abholliste</h1>

<div mat-dialog-content>
    <mat-form-field style="width: 100%">
        <mat-label>Bezeichnung</mat-label>
        <input matInput [formControl]="kommentarCtrl" name="kommentar" required>
    </mat-form-field>
    <mat-form-field style="width: 100%">
        <input matInput [formControl]="abholdatumCtrl" [matDatepicker]="picker" placeholder="Abholdatum" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <kiene-autocomplete [apiUrl]="'kunde/'" (elementSelected)="setAbholer($event)"
                        [placeholder]="'Zielbetrieb'" [separator]="' | '" [panelWidth]="'800px'"
                        [displayFields]="['vvvo', 'bezeichnung', 'zusatz', 'ort']"></kiene-autocomplete>
    <!--    <kiene-client-autocomplete placeholder="Empfänger" (selectedClient)="setAbholer($event)" [required]="true"-->
    <!--                               [client]="abholer" [showClear]="false">-->
    <!--    </kiene-client-autocomplete>-->
</div>
<div mat-dialog-actions align="end">
    <button mat-button (click)="dialogRef.close()">Abbrechen</button>
    <button mat-flat-button color="accent" [disabled]="isDisabled()" (click)="save()">Speichern</button>
</div>
