import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ReportRoutingModule} from './report-routing.module';
import {ReportComponent} from './report/report.component';
import {MatTabsModule} from '@angular/material/tabs';
import {ZeitauswahlComponent} from './zeitauswahl/zeitauswahl.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {KieneTableModule} from '../components/kiene-table/kiene-table.module';
import {GoogleChartsModule} from 'angular-google-charts';
import {MatCardModule} from '@angular/material/card';
import {TierarztComponent} from './tierarzt/tierarzt.component';
import {ReportOverviewValueCardComponent} from './report-overview-value-card/report-overview-value-card.component';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TierarztGruppeReportComponent} from './tierarzt/tierarzt-gruppe-report/tierarzt-gruppe-report.component';
import {TierarztEinzelReportComponent} from './tierarzt/tierarzt-einzel-report/tierarzt-einzel-report.component';
import {KundenComponent} from './kunden/kunden.component';
import {
    UnternehmensgruppenGesamtReportComponent
} from './kunden/unternehmensgruppen-gesamt-report/unternehmensgruppen-gesamt-report.component';
import {
    UnternehmensgruppeEinzelReportComponent
} from './kunden/unternehmensgruppe-einzel-report/unternehmensgruppe-einzel-report.component';
import {KieneAutocompleteModule} from '../components/kiene-autocomplete/kiene-autocomplete.module';
import {KundenEinzelReportComponent} from './kunden/kunden-einzel-report/kunden-einzel-report.component';
import {KieneClientAutocompleteModule} from '../components/client-autocomplete/client-autocomlete.module';
import {LaborComponent} from './labor/labor.component';
import {TierarztGesamtReportComponent} from './tierarzt/tierarzt-gesamt-report/tierarzt-gesamt-report.component';
import {LaborGesamtReportComponent} from './labor/labor-gesamt-report/labor-gesamt-report.component';
import {LaborMitarbeiterReportComponent} from './labor/labor-mitarbeiter-report/labor-mitarbeiter-report.component';
import {LaborArtikelReportComponent} from './labor/labor-artikel-report/labor-artikel-report.component';
import {
    LieferantenGesamtReportComponent
} from './lieferanten/lieferanten-gesamt-report/lieferanten-gesamt-report.component';
import {LieferantenComponent} from './lieferanten/lieferanten.component';
import {LieferantEinzelReportComponent} from './lieferanten/lieferant-einzel-report/lieferant-einzel-report.component';
import {WareneinsatzComponent} from './wareneinsatz/wareneinsatz.component';
import {WareneingangReportComponent} from './wareneinsatz/wareneingang-report/wareneingang-report.component';
import {WarenausgangReportComponent} from './wareneinsatz/warenausgang-report/warenausgang-report.component';
import {KombiniertReportComponent} from './wareneinsatz/kombiniert-report/kombiniert-report.component';
import {CsvExportModule} from '../csv-export/csv-export.module';
import {
    WarengruppenEingangReportComponent
} from './wareneinsatz/warengruppen-eingang-report/warengruppen-eingang-report.component';
import {
    WarengruppenAusgangReportComponent
} from './wareneinsatz/warengruppen-ausgang-report/warengruppen-ausgang-report.component';
import {
    KundeEinzelGesamtReportComponent
} from './kunden/kunde-einzel-gesamt-report/kunde-einzel-gesamt-report.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {
    KundeArtikelReportDialogComponent
} from './kunden/unternehmensgruppe-einzel-report/kunde-artikel-report-dialog/kunde-artikel-report-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';


@NgModule( {
    declarations: [
        ReportComponent,
        ZeitauswahlComponent,
        TierarztComponent,
        TierarztGesamtReportComponent,
        ReportOverviewValueCardComponent,
        TierarztGruppeReportComponent,
        TierarztEinzelReportComponent,
        KundenComponent,
        UnternehmensgruppenGesamtReportComponent,
        UnternehmensgruppeEinzelReportComponent,
        KundenEinzelReportComponent,
        LaborComponent,
        LaborGesamtReportComponent,
        LaborMitarbeiterReportComponent,
        LaborArtikelReportComponent,
        LieferantenGesamtReportComponent,
        LieferantenComponent,
        LieferantEinzelReportComponent,
        WareneinsatzComponent,
        WareneingangReportComponent,
        WarenausgangReportComponent,
        KombiniertReportComponent,
        WarengruppenEingangReportComponent,
        WarengruppenAusgangReportComponent,
        KundeEinzelGesamtReportComponent,
        KundeArtikelReportDialogComponent
    ],
    exports: [
        ZeitauswahlComponent
    ],
    imports: [
        CommonModule,
        ReportRoutingModule,
        MatTabsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatButtonToggleModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        KieneTableModule,
        MatTableModule,
        MatSortModule,
        GoogleChartsModule,
        MatCardModule,
        MatProgressSpinnerModule,
        KieneAutocompleteModule,
        KieneClientAutocompleteModule,
        CsvExportModule,
        MatDividerModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatDialogModule
    ]
})
export class ReportModule {
}
