import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    startWith,
    switchMap,
} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Befundmitteilung,
    BefundmitteilungAllDownload,
} from './befundmitteilung';
import { LocalStorageService } from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import { MessageService } from '../../services/message.service';
import { KieneTableService } from '../../components/kiene-table/kiene-table.service';
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { HttpParams } from '@angular/common/http';
import { ConfirmDialogComponent } from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import { GruppenService } from '../../../../projects/kiene-core/src/lib/components/gruppen/gruppen.service';
import { Gruppe } from '../../../../projects/kiene-core/src/lib/components/gruppen/classes/gruppe';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {
    TableDescriptor,
    ToolbarButton,
} from '../../components/kiene-table/table-descriptor';
import { API_BASE_URL_SERVICE } from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {
    ButtonTableColumn,
    ConditionSetting,
    ConditionTableColumn,
    DateTableColumn,
    EmailGesendetTableColumn,
    GedrucktTableColumn,
    TableColumn,
    TextTableColumn,
} from '../../components/kiene-table/table-column';
import {
    Condition,
    Operator,
    TableOption,
    TableOptionEvent,
} from '../../components/kiene-table/table-option';
import { BefundmitteilungService } from './befundmitteilung.service';
import { FileUploadService } from '../../dialogs/file-upload-dialog/file-upload.service';
import { FileDownloadService } from '../../services/file-download.service';
import {
    FilterItem,
    FilterItemType,
} from '../../../../projects/kiene-core/src/lib/services/backend-api/classes/filter-item';
import { Client } from '../../../../projects/kiene-core/src/lib/components/client/classes/client';
import { KieneBackendApiService } from '../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';

@Component({
    selector: 'kiene-befundmitteilung',
    templateUrl: './befundmitteilung.component.html',
    styleUrls: ['./befundmitteilung.component.scss'],
})
export class BefundmitteilungComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private tableService: KieneTableService,
        private api: KieneBackendApiService,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private befundmitteilungService: BefundmitteilungService,
        private pdfViewerService: PdfViewerDesktopService,
        private fileUploadService: FileUploadService,
        private fileDownloadService: FileDownloadService,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) { }

    ngOnInit() {
        this.tableDescriptor.headline = 'Befundmitteilungen';
        this.tableDescriptor.uniqueIdentifier = 'Befundmitteilungen';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'befund/';
        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.nameOfIdField = 'befund_id';

        if (this.router.url.startsWith('/belege')) {
            this.tableDescriptor.detailsRoute =
                '/belege/labor/befundmitteilungen/';
            this.tableDescriptor.sendCurrentClient = true;
        } else {
            this.tableDescriptor.detailsRoute = '/labor/befundmitteilungen/';
        }

        this.tableDescriptor.columns = this.getTableColumns();
        this.tableDescriptor.options = this.getTableOptions();
        this.tableDescriptor.filterItems = this.getFilterItems();

        this.tableDescriptor.addToolbarButton(
            new ToolbarButton(
                'neuer_notizzettel',
                'description',
                2605,
                'Neuen Notizzettel erstellen'
            )
        );
        this.tableDescriptor.addToolbarButton(
            new ToolbarButton(
                'neuer_probenbegleitschein',
                'list_alt',
                2605,
                'Neuen Probenbegleitschein erstellen'
            )
        );

        this.tableDescriptor.permissionView = 1311;
        this.tableDescriptor.permissionCreate = 1312;
        this.tableDescriptor.permissionUpdate = 1313;
        this.tableDescriptor.permissionDelete = 1314;

        this.tableDescriptor.initialSortColumn = 'erstellt';
        this.tableDescriptor.initialSortDirection = 'desc';
    }

    getFilterItems() {
        const items = [];
        items.push(
            new FilterItem(
                'Befund nicht abgerechnet',
                '0',
                FilterItemType.CHECKBOX,
                'rechnung',
                false
            )
        );
        if (this.localStorageService.getCurrentUser()?.tierarzt_id) {
            items.push(
                new FilterItem(
                    'Nur meine Tierarztgruppe',
                    '1',
                    FilterItemType.CHECKBOX,
                    'meinetierarztgruppen',
                    true
                )
            );
        }
        return items;
    }

    getTableColumns() {
        const columns = [];
        columns.push(new TextTableColumn('tagebuch_nr', 'Tagebuchnummer'));
        columns.push(
            new TextTableColumn(
                'untersuchungsmaterial',
                'Untersuchungsmaterial'
            )
        );
        columns.push(new TextTableColumn('tierarztgruppe', 'Tierarztgruppe'));
        columns.push(new TextTableColumn('kunde', 'Kunde'));
        columns.push(new TextTableColumn('gruppe', 'Gruppe'));
        columns.push(
            new DateTableColumn('eingang', 'Untersuchungsbeginn', 'dd.MM.yyyy')
        );
        columns.push(
            new DateTableColumn('ausgang', 'Untersuchungsende', 'dd.MM.yyyy')
        );
        const statusConditions = [];
        statusConditions.push(
            new ConditionSetting(
                [new Condition('status_id', Operator.GLEICH, 11)],
                { icon: 'fiber_new' }
            )
        );
        statusConditions.push(
            new ConditionSetting(
                [new Condition('status_id', Operator.GLEICH, 12)],
                { icon: 'lock' }
            )
        );
        statusConditions.push(
            new ConditionSetting(
                [new Condition('status_id', Operator.GLEICH, 13)],
                { icon: 'done' }
            )
        );
        statusConditions.push(
            new ConditionSetting(
                [new Condition('status_id', Operator.GLEICH, 14)],
                { icon: 'cancel' }
            )
        );
        statusConditions.push(
            new ConditionSetting(
                [new Condition('status_id', Operator.GLEICH, 15)],
                { icon: 'edit' }
            )
        );
        columns.push(
            new ConditionTableColumn('status_id', 'Status', statusConditions)
        );
        columns.push(
            new ButtonTableColumn(
                'rechnung_id',
                'Rechnung anzeigen',
                'attach_money',
                'open_invoice'
            )
        );
        columns.push(
            new DateTableColumn('erstellt', 'Erstellt', 'dd.MM.yyyy HH:mm:ss')
        );
        columns.push(new TableColumn('pdf_id', '', 'pdf'));
        columns.push(new EmailGesendetTableColumn());
        columns.push(new GedrucktTableColumn());

        const conditionBefundeExtern = [];
        conditionBefundeExtern.push(
            new ConditionSetting(
                [new Condition('befunde_extern', Operator.GROESSER, 0)],
                { icon: 'attach_file' }
            )
        );
        columns.push(
            new ConditionTableColumn(
                'befunde_extern',
                '',
                conditionBefundeExtern
            )
        );
        return columns;
    }

    getTableOptions() {
        const options = [];
        options.push(
            new TableOption(
                'antibiogramm_umwandeln',
                'In Antibiogramm umwandeln',
                'swap_horiz',
                1336,
                {
                    conditions: [
                        new Condition(
                            'antibiogramm_id',
                            Operator.EXISTIERT_NICHT,
                            undefined
                        ),
                    ],
                }
            )
        );
        options.push(
            new TableOption(
                'untersuchungsauftrag_generieren',
                'Untersuchungsauftrag generieren',
                'swap_horiz',
                1337
            )
        );
        options.push(
            new TableOption(
                'antibiogramm_anzeigen',
                'Antibiogramme anzeigen',
                'play_arrow',
                1811,
                {
                    conditions: [
                        new Condition('antibiogramme', Operator.GROESSER, 0),
                    ],
                }
            )
        );
        options.push(
            new TableOption(
                'untersuchungsauftraege_anzeigen',
                'Untersuchungsaufträge anzeigen',
                'play_arrow',
                2511,
                {
                    conditions: [
                        new Condition(
                            'untersuchungsauftraege',
                            Operator.GROESSER,
                            0
                        ),
                    ],
                }
            )
        );
        options.push(
            new TableOption(
                'pdfs_hinzufuegen',
                'PDFs hinzufügen',
                'forward',
                1322
            )
        );
        options.push(
            new TableOption(
                'befund_herunterladen',
                'Befund einzeln herunterladen',
                'save_alt',
                1317
            )
        );
        options.push(
            new TableOption('delete', 'Befund löschen', 'delete', 1314)
        );
        options.push(
            new TableOption(
                'rechnung_anzeigen',
                'Rechnung anzeigen',
                'attach_money',
                1320
            )
        );
        options.push(
            new TableOption(
                'rechnung_springen',
                'zu Rechnung springen',
                'attach_money',
                2971,
                {
                    conditions: [
                        new Condition(
                            'rechnung_id',
                            Operator.EXISTIERT,
                            undefined
                        ),
                    ],
                }
            )
        );
        options.push(
            new TableOption(
                'pdf_neu_erstellen',
                'Befund PDF neu erstellen',
                'replay',
                1308
            )
        );
        options.push(
            new TableOption(
                'befund_duplizieren',
                'Befund duplizieren',
                'file_copy',
                1338
            )
        );
        options.push(
            new TableOption(
                'notizzettel_erstellen',
                'Notizzettel erstellen',
                'note_add',
                2605
            )
        );
        options.push(
            new TableOption(
                'befund_stornieren',
                'Befund stornieren',
                'cancel',
                1329,
                {
                    conditions: [
                        new Condition('status_id', Operator.GLEICH, 12),
                    ],
                }
            )
        );
        options.push(
            new TableOption(
                'befund_wieder_oeffnen',
                'Befund wieder öffnen',
                'lock_open',
                1309,
                {
                    conditions: [
                        new Condition('status_id', Operator.GLEICH, 12),
                    ],
                }
            )
        );
        options.push(
            new TableOption(
                'export_idexx_testlauf',
                'Idexx Positionen exportieren',
                'file_upload',
                1339,
                { conditions: [new Condition('idexx', Operator.GROESSER, 0)] }
            )
        );
        options.push(new TableOption(null, 'Drucken', 'print', 1349, { conditions: [new Condition('pdf_id', Operator.EXISTIERT, null)], void: this.gedruckt.bind(this) }))
        return options;
    }

    gedruckt(befund: Befundmitteilung) {
        this.api.get(this.apiBaseUrl + 'befund/gedruckt.php', new HttpParams().set('befund_id', befund.befund_id)).subscribe({
            next: () => {
                this.tableService.reload('gedruckt');
                this.openPdf(befund);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    catchOptionEvent(event: TableOptionEvent) {
        if (event.name === 'open_invoice') {
            this.openInvoice(event.value);
        } else if (event.name === 'antibiogramm_umwandeln') {
            this.transformToAntibiogramm(event.value);
        } else if (event.name === 'untersuchungsauftrag_generieren') {
            this.transformToUntersuchungsauftrag(event.value);
        } else if (event.name === 'antibiogramm_anzeigen') {
            this.goToAntibiogramme(event.value);
        } else if (event.name === 'untersuchungsauftraege_anzeigen') {
            this.goToUntersuchungsauftraege(event.value);
        } else if (event.name === 'pdfs_hinzufuegen') {
            this.openAddExternalPdfsDialog(event.value);
        } else if (event.name === 'befund_herunterladen') {
            this.openPdf(event.value);
        } else if (event.name === 'delete') {
            this.openConfirmDeleteBefundmitteilungDialog(event.value);
        } else if (event.name === 'rechnung_anzeigen') {
            this.openInvoice(event.value);
        } else if (event.name === 'rechnung_springen') {
            this.rechnungAnzeigen(event.value);
        } else if (event.name === 'pdf_neu_erstellen') {
            this.recreateBefund(event.value);
        } else if (event.name === 'befund_duplizieren') {
            this.befundDuplizieren(event.value);
        } else if (event.name === 'notizzettel_erstellen') {
            this.createNotiz(event.value);
        } else if (event.name === 'befund_stornieren') {
            this.cancelBefund(event.value);
        } else if (event.name === 'befund_wieder_oeffnen') {
            this.reOpenBefund(event.value);
        } else if (event.name === 'export_idexx_testlauf') {
            this.exportIdexxTestlauf(event.value);
        } else if (event.name === 'add') {
            this.openNewBefundmitteilung();
        } else if (event.name === 'neuer_notizzettel') {
            this.openNewBefundmitteilungNotizDialog();
        } else if (event.name === 'neuer_probenbegleitschein') {
            this.openNewBefundmitteilungSampleSheetDialog();
        } else if (event.name === 'showPdf') {
            this.downloadAllPdfsForBefundmitteilungMerged(event.value);
        }
    }

    transformToAntibiogramm(b: Befundmitteilung) {
        this.api
            .post(this.apiBaseUrl + 'antibiogramm/transform.php', b, null)
            .subscribe({
                next: (response) => {
                    this.router
                        .navigate(
                            ['../antibiogramme', response.antibiogramm_id],
                            {
                                relativeTo: this.route,
                            }
                        )
                        .then();
                },
                error: (error) => {
                    this.messageService.errorMessage(
                        'Antibiogramm konnte nicht erstellt werden! ',
                        error
                    );
                },
            });
    }

    goToAntibiogramme(b: Befundmitteilung) {
        this.router
            .navigate(['../antibiogramme'], {
                relativeTo: this.route,
                queryParams: { befund_id: b.befund_id, clearsearch: true },
            })
            .then();
    }

    goToUntersuchungsauftraege(b: Befundmitteilung) {
        this.router
            .navigate(['../untersuchungsauftrag'], {
                relativeTo: this.route,
                queryParams: { befund_id: b.befund_id, clearsearch: true },
            })
            .then();
    }

    transformToUntersuchungsauftrag(b: Befundmitteilung) {
        this.api
            .post(
                this.apiBaseUrl + 'untersuchungsauftrag/transform.php',
                b,
                null
            )
            .subscribe(
                (response) => {
                    this.router
                        .navigate(
                            [
                                '../untersuchungsauftrag',
                                response.untersuchungsauftrag_id,
                            ],
                            { relativeTo: this.route }
                        )
                        .then();
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Es konnte kein Untersuchungsauftrag generiert werden! ',
                        error
                    );
                }
            );
    }

    openConfirmDeleteBefundmitteilungDialog(row: Befundmitteilung) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '350px',
            data: {
                title: 'Befundmitteilung löschen',
                text:
                    'Wollen Sie die Befundmitteilung ' +
                    row.tagebuch_nr +
                    ' wirklich löschen?',
                textButton: 'Löschen',
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.deleteBefundmitteilung(row);
            }
        });
    }

    deleteBefundmitteilung(befundmitteilung: Befundmitteilung) {
        this.befundmitteilungService
            .deleteBefundmitteilung(befundmitteilung)
            .subscribe({
                next: () => {
                    this.messageService.infoMessage(
                        'Befundmitteilung: ' +
                        befundmitteilung.tagebuch_nr +
                        ' erfolgreich entfernt!'
                    );
                    this.tableService.reload('deleteBefundmitteilung');
                },
                error: (error) => {
                    console.error(error);
                    this.messageService.errorMessage(
                        'Löschen von Befundmitteilung: ' +
                        befundmitteilung.tagebuch_nr +
                        ' ist fehlgeschlagen!',
                        error
                    );
                },
            });
    }

    openNewBefundmitteilung() {
        this.router.navigate(['neu'], { relativeTo: this.route }).then();
    }

    createNotiz(b: Befundmitteilung) {
        this.befundmitteilungService.getNotizzettel(b);
    }

    reOpenBefund(b: Befundmitteilung) {
        this.api
            .post(this.apiBaseUrl + 'befund/korrigieren.php', b, null)
            .subscribe({
                next: () => {
                    this.messageService.infoMessage(
                        'Befund ' + b.tagebuch_nr + ' wurde wieder geöffnet'
                    );
                    this.tableService.reload('reOpenBefund');
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Befund ' +
                        b.tagebuch_nr +
                        ' konnte nicht wieder geöffnet werden. ',
                        err
                    );
                },
            });
    }

    befundDuplizieren(b) {
        const input = new ConfirmDialogInput();
        input.headline = 'Befundmitteilung duplizieren';
        input.text = 'Wollen Sie die Befundmitteilung duplizieren?';
        input.okButton = 'duplizieren';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });
        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    this.api
                        .post(
                            this.apiBaseUrl + 'befund/duplizieren.php',
                            b,
                            undefined
                        )
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Der Befund wurde erfolgreich dupliziert!'
                                );
                                this.tableService.reload('reOpenBefund');
                            },
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Fehler beim Duplizieren!',
                                    err
                                );
                                this.tableService.reload('reOpenBefund');
                            },
                        });
                }
            },
        });
    }

    openNewBefundmitteilungNotizDialog() {
        const dialogRef = this.dialog.open(BefundmitteilungNewNoteDialog, {
            width: '550px',
        });

        dialogRef.afterClosed().subscribe((note) => {
            if (note && note.tagebuchnummer) {
                const befund = new Befundmitteilung();
                befund.gruppe_id = note?.clientGroup?.gruppe_id;
                befund.tagebuch_nr = note.tagebuchnummer;
                this.befundmitteilungService.getNotizzettel(befund);
            }
        });
    }

    openNewBefundmitteilungSampleSheetDialog() {
        const dialogRef = this.dialog.open(
            BefundmitteilungNewSampleSheetDialog,
            {
                width: '550px',
            }
        );

        dialogRef.afterClosed().subscribe((response) => {
            if (response && response.clientGroup && response.probenentnahme) {
                const clientGroup: Gruppe = response.clientGroup;
                const probenentnahme: Date = response.probenentnahme;
                let params = new HttpParams();
                params = params.append(
                    'gruppe_id',
                    clientGroup?.gruppe_id?.toString()
                );
                params = params.append(
                    'probenentnahme',
                    probenentnahme?.toISOString()
                );
                this.pdfViewerService.openPdfViewer(
                    new PdfViewerDialogInput(
                        'probenbegleitschein/pdf_create.php',
                        params,
                        'Probenbegleitschein'
                    ) // super
                );
            }
        });
    }

    openDownloadAllDialog() {
        const dialogRef = this.dialog.open(DownloadAllBefundmitteilungDialog, {
            width: '700px',
        });

        dialogRef
            .afterClosed()
            .subscribe((result: BefundmitteilungAllDownload) => {
                if (result) {
                    // let clientGroup = befundmiteilungAllDownload.clientGroup;
                    const client = result.client;
                    if (result.client != null) {
                        const title: string =
                            'Befund_alle_' + result.client.bezeichnung_final;

                        let params = new HttpParams();
                        params = params.append(
                            'kunde_id',
                            client?.kunde_id?.toString()
                        );
                        params = params.append(
                            'zeit_von',
                            result?.zeit_von?.toISOString()
                        );
                        params = params.append(
                            'zeit_bis',
                            result?.zeit_bis?.toISOString()
                        );

                        const input = new PdfViewerDialogInput(
                            'befund/read_or_mail_pdf_period.php',
                            params,
                            title,
                            {
                                email: {
                                    sende_mail: true,
                                    kunde_id: client.kunde_id,
                                },
                            }
                        );
                        this.pdfViewerService.openPdfViewer(input); // super
                    }
                }
            });
    }

    downloadPdf(pdfId: number) {
        if (pdfId) {
            let params = new HttpParams();
            params = params.set('pdf_id', pdfId);
            this.pdfViewerService.downloadPdf(
                this.apiBaseUrl + 'pdf/read_one.php',
                params
            );
            //     .subscribe((response) => {
            //     const blob = new Blob([response], {type: 'application/pdf'});
            //     const url = window.URL.createObjectURL(blob);
            //     window.open(url, '_blank');
            // });
        }
    }

    downloadAllPdfsForBefundmitteilungMerged(
        befundmitteilung: Befundmitteilung
    ) {
        const input = new PdfViewerDialogInput(
            'befund/read_or_mail_pdf_merged.php',
            new HttpParams().set(
                'befund_id',
                befundmitteilung?.befund_id?.toString()
            ),
            this.befundmitteilungService.getPdfTitle(befundmitteilung),
            {
                email: {
                    sende_mail: true,
                    kunde_id: befundmitteilung?.kunde_id,
                },
            }
        );
        this.pdfViewerService.openPdfViewer(input).subscribe({
            next: () => {
                this.tableService.reload('downloadAllPdfsForBefundmitteilungMerged');
            }
        });
    }

    openAddExternalPdfsDialog(befundmitteilung: Befundmitteilung) {
        this.fileUploadService.openAddFilesDialog(
            befundmitteilung.befund_id,
            'befund_id',
            'befund_extern/create.php',
            () => { },
            () => { }
        );
    }

    openPdf(befundmitteilung: Befundmitteilung) {
        const pdfId = befundmitteilung.pdf_id;
        if (pdfId) {
            const input = new PdfViewerDialogInput(
                'befund/read_or_mail_pdf.php',
                new HttpParams().set(
                    'befund_id',
                    befundmitteilung?.befund_id?.toString()
                ),
                this.befundmitteilungService.getPdfTitle(befundmitteilung),
                {
                    email: {
                        sende_mail: true,
                        kunde_id: befundmitteilung?.kunde_id,
                    },
                }
            );
            this.pdfViewerService.openPdfViewer(input); // super
        }
    }

    openInvoice(befundmitteilung: Befundmitteilung) {
        const title: string =
            'Rechnung Befundmitteilung ' + befundmitteilung.tagebuch_nr;

        const input = new PdfViewerDialogInput(
            'befund/read_rechnung.php',
            new HttpParams().append(
                'befund_id',
                befundmitteilung?.befund_id?.toString()
            ),
            title
        );
        this.pdfViewerService.openPdfViewer(input); // super
    }

    rechnungAnzeigen(b: Befundmitteilung) {
        this.router.navigate(['/finanzen', 'rechnungen', b.rechnung_id]).then();
    }

    cancelBefund(b: Befundmitteilung) {
        this.api
            .post(this.apiBaseUrl + 'befund/stornieren.php', b, null)
            .subscribe({
                next: () => {
                    this.messageService.infoMessage(
                        'Der Bufund wurde storniert!'
                    );
                    this.tableService.reload('cancelBefund');
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Der Befund konnte nicht storniert werden!',
                        err
                    );
                },
            });
    }

    recreateBefund(befundmitteilung: Befundmitteilung) {
        this.befundmitteilungService.recreateBefund(befundmitteilung).subscribe(
            () => {
                this.messageService.infoMessage(
                    'Befund PDF ' +
                    befundmitteilung.tagebuch_nr +
                    ' erfolgreich neu erstellt!'
                );
                this.tableService.reload('recreateBefund');
            },
            (error) => {
                console.error(error);
                this.messageService.errorMessage(
                    'Fehler ' + befundmitteilung.tagebuch_nr + '!',
                    error
                );
            }
        );
    }

    uploadExterneBefundmitteilung(
        files: File[],
        befundmitteilung: Befundmitteilung
    ) {
        this.fileUploadService.uploadFilesToServer(
            files,
            'befund_id',
            befundmitteilung.befund_id,
            'befund_dokument/create_extern.php'
        );
        this.fileUploadService.watchFileUploaded().subscribe((fileName) => {
            for (const f of files) {
                if (f.name === fileName) {
                    this.tableService.reload('uploadExterneBefundmitteilung');
                }
            }
        });
    }

    rowContainsIdexxArtikel(row: Befundmitteilung) {
        return row.positionen?.some((i) => i.idexx === 1);
    }

    exportAllIdexxTestlaeufe() {
        this.fileDownloadService
            .downloadCSV('idexx_testlauf/create_csv_all.php', null)
            .subscribe({
                next: (size) => {
                    if (size <= 0) {
                        this.messageService.infoMessage(
                            'Es wurden keine offenen Idexx-Positionen gefunden. Bei Bedarf Einzelexporte durchführen.'
                        );
                    }
                },
                error: (error) => {
                    this.messageService.errorMessage('Fehler: ', error);
                },
            });
    }

    exportIdexxTestlauf(row: Befundmitteilung) {
        const idsToExport: number[] = [];
        row.positionen.forEach((position) => {
            if (position.idexx === 1) {
                idsToExport.push(position.ba_id);
            }
        });

        let httpParams = new HttpParams();
        httpParams = httpParams.set('ba_ids', idsToExport.toString());
        this.fileDownloadService
            .downloadCSV('idexx_testlauf/create_csv_one.php', httpParams)
            .subscribe({
                next: (size) => {
                    if (size <= 0) {
                        this.messageService.infoMessage(
                            'Alle Idexx-Testläufe der Befundmitteilung sind bereits exportiert und abgeschlossen!'
                        );
                    }
                },
                error: (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                },
            });
    }
}

@Component({
    selector: 'befundmitteilung-new-note-dialog',
    template: `<h3 mat-dialog-title>Neue Befundmitteilungnotiz</h3>
        <mat-dialog-content>
            <p>Bitte Betrieb ausw&auml;hlen</p>
            <kiene-client-group-autocomplete
                (selectedGroup)="setSelectedGroup($event)"
            ></kiene-client-group-autocomplete>
            <p>Bitte die Tagebuchnummer eingeben</p>
            <mat-form-field>
                <input
                    matInput
                    name="tagebuchnummer"
                    placeholder="Tagebuchnummer"
                    [(ngModel)]="tagebuchnummer"
                    (keyup.enter)="createBefundmitteilungNote()"
                />
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button color="primary" (click)="onCancelClick()">
                Abbrechen
            </button>
            <button
                mat-button
                color="primary"
                (click)="createBefundmitteilungNote()"
            >
                Erstellen
            </button>
        </mat-dialog-actions>`,
})
export class BefundmitteilungNewNoteDialog {
    tagebuchnummer: string;
    client: Client;
    clientGroup: Gruppe;

    constructor(
        public dialogRef: MatDialogRef<BefundmitteilungNewNoteDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    setCurrentClient(client: Client) {
        this.client = client;
    }

    setSelectedGroup(clientGroup: Gruppe) {
        this.clientGroup = clientGroup;
    }

    createBefundmitteilungNote() {
        const note = {
            tagebuchnummer: this.tagebuchnummer,
            client: this.client,
            clientGroup: this.clientGroup,
        };
        this.dialogRef.close(note);
    }

    onCancelClick(): void {
        this.dialogRef.close(false);
    }
}

@Component({
    selector: 'befundmitteilung-new-sample-sheet-dialog',
    template: `<h3 mat-dialog-title>Neuer Probenbegleitschein</h3>
        <mat-dialog-content>
            <p>Bitte Betrieb ausw&auml;hlen</p>
            <kiene-client-group-autocomplete
                (selectedGroup)="setSelectedGroup($event)"
            ></kiene-client-group-autocomplete>
            <p>Bitte Probenentnahmedatum eingeben</p>
            <mat-form-field style="margin-right: 10px; min-width: 10%;">
                <input
                    matInput
                    [matDatepicker]="probenentnahmePicker"
                    placeholder="Probenentnahmedatum"
                    [(ngModel)]="probenentnahme"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="probenentnahmePicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #probenentnahmePicker></mat-datepicker>
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button color="primary" (click)="onCancelClick()">
                Abbrechen
            </button>
            <button
                mat-button
                color="primary"
                (click)="createBefundmitteilungSampleSheet()"
            >
                Erstellen
            </button>
        </mat-dialog-actions>`,
})
export class BefundmitteilungNewSampleSheetDialog {
    client: Client;
    clientGroup: Gruppe;
    probenentnahme: Date = new Date();

    constructor(
        public dialogRef: MatDialogRef<BefundmitteilungNewNoteDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    setCurrentClient(client: Client) {
        this.client = client;
    }

    setSelectedGroup(clientGroup: Gruppe) {
        this.clientGroup = clientGroup;
    }

    createBefundmitteilungSampleSheet() {
        const sampleSheet = {
            client: this.client,
            clientGroup: this.clientGroup,
            probenentnahme: this.probenentnahme,
        };
        this.dialogRef.close(sampleSheet);
    }

    onCancelClick(): void {
        this.dialogRef.close(false);
    }
}

@Component({
    selector: 'download-all-befundmitteilung-dialog',
    template: `<h3 mat-dialog-title>
            Alle Befunde zu einem Betrieb herunterladen
        </h3>
        <mat-dialog-content>
            <h5>Betrieb ausw&auml;hlen</h5>
            <kiene-client-autocomplete
                (selectedClient)="setSelectedClient($event)"
                [panelWidth]="'50%'"
            ></kiene-client-autocomplete>
            <mat-divider></mat-divider>
            <h5>Zeitraum angeben (Probenentnahme)</h5>

            <mat-form-field style="min-width: 40%; padding-left: 10px;">
                <input
                    matInput
                    [matDatepicker]="fromPicker"
                    placeholder="Von"
                    [(ngModel)]="befundmitteilungAllDownload.zeit_von"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="fromPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #fromPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field style="min-width: 40%; padding-left: 10px;">
                <input
                    matInput
                    [matDatepicker]="toPicker"
                    placeholder="Bis"
                    [(ngModel)]="befundmitteilungAllDownload.zeit_bis"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="toPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #toPicker></mat-datepicker>
            </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button color="primary" (click)="onCancelClick()">
                Abbrechen
            </button>
            <button mat-raised-button color="primary" (click)="closeDialog()">
                Herunterladen
            </button>
        </mat-dialog-actions>`,
})
export class DownloadAllBefundmitteilungDialog implements OnInit {
    befundmitteilungAllDownload: BefundmitteilungAllDownload =
        new BefundmitteilungAllDownload();

    gruppeFormCtrl: UntypedFormControl = new UntypedFormControl();
    gruppen: Observable<Gruppe[]>;
    client: Client;

    constructor(
        private gruppenService: GruppenService,
        public dialogRef: MatDialogRef<DownloadAllBefundmitteilungDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        this.autocompleteGruppen();
    }

    setSelectedClient(c: Client) {
        this.client = c;
    }

    closeDialog() {
        this.befundmitteilungAllDownload.client = this.client;
        this.dialogRef.close(this.befundmitteilungAllDownload);
    }

    onCancelClick(): void {
        this.dialogRef.close();
    }

    autocompleteGruppen() {
        this.gruppeFormCtrl = new UntypedFormControl();
        this.gruppen = this.gruppeFormCtrl.valueChanges.pipe(
            startWith(''),
            switchMap((value) => this.searchGruppen(value))
        );
    }

    searchGruppen(value: string) {
        return this.gruppenService.searchGruppenPraxissoftware(value).pipe(
            debounceTime(300),
            distinctUntilChanged(),
            map((response) => response.records)
        );
    }
}

@Component({
    selector: 'download-file-info-dialog',
    template: ` <h3 mat-dialog-title>
            <mat-icon color="primary">info</mat-icon>
            Download Info
        </h3>
        <mat-dialog-content>
            Die Datei
            <span style="font-style: italic">{{ data.dateiname }}.pdf</span>
            wird heruntergeladen...
            <br />
            <br />
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            <br />
        </mat-dialog-content>`,
})
export class DownloadFileInfoDialog {
    constructor(
        public dialogRef: MatDialogRef<DownloadFileInfoDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    onCancelClick(): void {
        this.dialogRef.close(false);
    }
}
