<form class="vvvo-add-form">
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>VVVO</mat-label>
            <input required matInput type="text" placeholder="VVVO" [formControl]="vvvoCtrl" autocomplete="vvvo">
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Firmenname</mat-label>
            <input required matInput type="text" placeholder="Firmenname" [formControl]="bezeichnungCtrl" autocomplete="company">
            <mat-error class="input-error" *ngIf="bezeichnungCtrl.hasError('required')">Der Firmenname ist ein Pflichtfeld.</mat-error>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Betriebsart</mat-label>
            <mat-select required [formControl]="betriebsartCtrl" [compareWith]="compareBetriebsarten">
                <mat-option *ngFor="let betriebsart of betriebsarten" [value]="betriebsart">
                    {{betriebsart.bezeichnung}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Adresszusatz</mat-label>
            <input matInput type="text" placeholder="Adresszusatz" [formControl]="firmenadresszusatzCtrl" autocomplete="company">
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Strasse</mat-label>
            <input required matInput type="text" placeholder="Strasse Hausnr." [formControl]="strasseCtrl" autocomplete="street">
            <mat-error class="input-error" *ngIf="strasseCtrl.hasError('pattern')">Die Strasse ist ungültig.</mat-error>
            <mat-error class="input-error" *ngIf="strasseCtrl.hasError('required')">Die Strasse ist ein Pflichtfeld.</mat-error>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Postleitzahl</mat-label>
            <input required matInput type="text" placeholder="Postleitzahl" [formControl]="plzCtrl" autocomplete="zipcode">
            <mat-error class="input-error" *ngIf="plzCtrl.hasError('pattern')">Die Postleitzahl muss aus fünf Ziffern bestehen.</mat-error>
            <mat-error class="input-error" *ngIf="plzCtrl.hasError('required')">Die Postleitzahl ist ein Pflichtfeld.</mat-error>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Ort</mat-label>
            <input required matInput type="text" placeholder="Ort" [formControl]="ortCtrl" autocomplete="city">
            <mat-error class="input-error" *ngIf="ortCtrl.hasError('pattern')">Der Ort ist ungültig.</mat-error>
            <mat-error class="input-error" *ngIf="ortCtrl.hasError('required')">Der Ort ist ein Pflichtfeld.</mat-error>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Ortsteil</mat-label>
            <input matInput type="text" placeholder="Ortsteil" [formControl]="ortsteilCtrl" autocomplete="city">
            <mat-error class="input-error" *ngIf="ortsteilCtrl.hasError('pattern')">Der Ortsteil ist ungültig.</mat-error>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>E-Mail Adresse (mehrere Adressen mit ; trennen)</mat-label>
            <input required matInput type="email" placeholder="E-Mail" [formControl]="emailCtrl" autocomplete="email">
            <mat-error class="input-error" *ngIf="emailCtrl.hasError('required')">Die E-Mail Adresse ist ein Pflichtfeld.</mat-error>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Telefon</mat-label>
            <input matInput type="tel" placeholder="Telefon" [formControl]="telefon1Ctrl" autocomplete="phone">
            <mat-error class="input-error" *ngIf="telefon1Ctrl.hasError('pattern')">Die Telefonnummer ist ungültig.</mat-error>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Telefon alternativ</mat-label>
            <input matInput type="tel" placeholder="Telefon alternativ" [formControl]="telefon2Ctrl" autocomplete="phone">
            <mat-error class="input-error" *ngIf="telefon2Ctrl.hasError('pattern')">Die Telefonnummer ist ungültig.</mat-error>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Mobiltelefon</mat-label>
            <input matInput type="tel" placeholder="Mobiltelefon" [formControl]="mobil1telefonCtrl" autocomplete="mobilephone">
            <mat-error class="input-error" *ngIf="mobil1telefonCtrl.hasError('pattern')">Die Mobiltelefonnummer ist ungültig.</mat-error>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Mobiltelefon alternativ</mat-label>
            <input matInput type="tel" placeholder="Mobiltelefon alternativ" [formControl]="mobil2telefonCtrl" autocomplete="mobilephone">
            <mat-error class="input-error" *ngIf="mobil2telefonCtrl.hasError('pattern')">Die Mobiltelefonnummer ist ungültig.</mat-error>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Fax</mat-label>
            <input matInput type="tel" placeholder="Fax" [formControl]="faxCtrl" autocomplete="fax">
            <mat-error class="input-error" *ngIf="faxCtrl.hasError('pattern')">Die Faxnummer ist ungültig.</mat-error>
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>SGS Nummer</mat-label>
            <input matInput type="text" placeholder="SGS Nummer" [formControl]="sgsCtrl" autocomplete="sgs">
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>TSK Nummer</mat-label>
            <input matInput type="text" placeholder="TSK Nummer" [formControl]="tskCtrl" autocomplete="tsk">
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <!--Tierzahlen-->
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Tierzahl Sauen</mat-label>
            <input matInput type="number" placeholder="Tierzahl Sauen" [formControl]="tierzahlSauenCtrl" autocomplete="sauen">
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Tierzahl Saugferkel</mat-label>
            <input matInput type="number" placeholder="Tierzahl Saugferkel" [formControl]="tierzahlSaugferkelCtrl" autocomplete="saugferkel">
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Tierzahl Absatzferkel</mat-label>
            <input matInput type="number" placeholder="Tierzahl Absatzferkel" [formControl]="tierzahlAbsatzferkelCtrl" autocomplete="absatzferkel">
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Tierzahl Mastschweine</mat-label>
            <input matInput type="number" placeholder="Tierzahl Mastschweine" [formControl]="tierzahlMastschweineCtrl" autocomplete="mastschweine">
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Tierzahl K&uuml;he</mat-label>
            <input matInput type="number" placeholder="Tierzahl Kühe" [formControl]="tierzahlKueheCtrl" autocomplete="kuehe">
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Tierzahl K&auml;lber</mat-label>
            <input matInput type="number" placeholder="Tierzahl Kälber" [formControl]="tierzahlKaelberCtrl" autocomplete="kaelber">
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Tierzahl Fresser</mat-label>
            <input matInput type="number" placeholder="Tierzahl Fresser" [formControl]="tierzahlFresserCtrl" autocomplete="fresser">
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Tierzahl Bullen</mat-label>
            <input matInput type="number" placeholder="Tierzahl Bullen" [formControl]="tierzahlBullenCtrl" autocomplete="bullen">
        </mat-form-field>
        <span class="spacer"></span>
    </div>
    <div class="one-row">
        <span class="spacer"></span>
        <mat-form-field class="vvvo-details-form-input">
            <mat-label>Tierzahl Mastrinder</mat-label>
            <input matInput type="number" placeholder="Tierzahl Mastrinder" [formControl]="tierzahlMastrinderCtrl" autocomplete="mastrinder">
        </mat-form-field>
        <span class="spacer"></span>
    </div>

</form>
