import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TierSortierFilter} from '../classes/tier-sortier-filter';

@Component({
  selector: 'kiene-gruppe-tier-sortierung-button',
  templateUrl: './gruppe-tier-sortierung-button.component.html',
  styleUrls: ['./gruppe-tier-sortierung-button.component.scss']
})
export class GruppeTierSortierungButtonComponent implements OnInit {

    tiersortierfilter = TierSortierFilter.getAvailableFilter();
    selectedSortierFilter: TierSortierFilter;
    @Output('selectedFilter') selectedFilter = new EventEmitter<TierSortierFilter>();

  constructor() { }

  ngOnInit(): void {
  }

  emitEvent(sortierung: TierSortierFilter) {
      this.selectedSortierFilter = sortierung;
      this.selectedFilter.emit(this.selectedSortierFilter);
  }
}
