<div class="one-row">
    <div style="min-width: 200px; display: flex; flex-direction: column; align-self: flex-start; margin-top: 63px;">
        <h3>Reporte</h3>
        <ul class="report-menu" style="list-style-type: none;">
            <li>
                <a *ngIf="isTabMenuVisible('/reporte/tierarzt')" [routerLink]="['/reporte/tierarzt']" routerLinkActive
                   #rltg="routerLinkActive"
                   [ngClass]="(rltg.isActive || router.url.startsWith('/reporte/tierarzt')) ? 'active' : ''">
                    <div class="one-row">
                        <mat-icon style="margin-right: 12px;">local_hospital</mat-icon>
                        <p>Tierarzt</p><span class="spacer"></span></div>
                </a>
            </li>
            <li>
                <a *ngIf="isTabMenuVisible('/reporte/labor')" [routerLink]="['/reporte/labor']" routerLinkActive
                   #rllg="routerLinkActive" [ngClass]="rllg.isActive ? 'active' : ''">
                    <div class="one-row">
                        <mat-icon style="margin-right: 12px;">biotech</mat-icon>
                        <p>Labor</p><span class="spacer"></span></div>
                </a>
            </li>
            <li>
                <a *ngIf="isTabMenuVisible('/reporte/lieferanten')" [routerLink]="['/reporte/lieferanten']"
                   routerLinkActive #lieferanten="routerLinkActive" [ngClass]="lieferanten.isActive ? 'active' : ''">
                    <div class="one-row">
                        <mat-icon style="margin-right: 12px;">local_shipping</mat-icon>
                        <p>Lieferanten</p><span class="spacer"></span></div>
                </a>
            </li>
            <li>
                <a *ngIf="isTabMenuVisible('/reporte/kunden')" [routerLink]="['/reporte/kunden']" routerLinkActive
                   #rlkg="routerLinkActive" [ngClass]="rlkg.isActive ? 'active' : ''">
                    <div class="one-row">
                        <mat-icon style="margin-right: 12px;">groups</mat-icon>
                        <p>Kunden</p><span class="spacer"></span></div>
                </a>
            </li>
            <li>
                <a *ngIf="isTabMenuVisible('/reporte/waren')" [routerLink]="['/reporte/wareneinsatz']" routerLinkActive
                   #wareneinsatz="routerLinkActive" [ngClass]="wareneinsatz.isActive ? 'active' : ''">
                    <div class="one-row">
                        <mat-icon style="margin-right: 12px;">store</mat-icon>
                        <p>Wareneinsatz</p><span class="spacer"></span></div>
                </a>
            </li>
        </ul>
        <span class="spacer"></span>
    </div>
    <div style="width: 100%; margin-top: 63px;">
        <router-outlet></router-outlet>
    </div>
</div>
