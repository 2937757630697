<div *ngIf="isLoading" class="kiene-loading-spinner">
    <mat-spinner *ngIf="isLoading"></mat-spinner>
</div>
<div class="one-row gruppe-info" style="width: 99%">
    <div style="margin-right: 24px">
        Gruppe:
        <span style="font-weight: bold">{{
            gruppe?.bezeichnung ? gruppe.bezeichnung : "--"
            }}</span>
    </div>
    <div style="margin-right: 24px">
        Abschnitt:
        <span style="font-weight: bold">{{
            gruppe?.produktionsabschnitt ? gruppe.produktionsabschnitt : "--"
            }}</span>
    </div>
    <div style="margin-right: 24px">
        Herdenname:
        <span style="font-weight: bold">{{
            gruppe?.herdenname ? gruppe.herdenname : "--"
            }}</span>
    </div>
    <div style="margin-right: 24px">
        Einstalldatum von:
        <span style="font-weight: bold">{{
            gruppe?.einstalldatum_von | date : "mediumDate"
            }}</span>
    </div>
    <div style="margin-right: 24px">
        Einstalldatum bis:
        <span style="font-weight: bold">{{
            gruppe?.einstalldatum_bis | date : "mediumDate"
            }}</span>
    </div>
    <div style="margin-right: 24px">
        Mastdauer in Tagen (Wochen):
        <span style="font-weight: bold">{{ gruppe?.alter_tage | number : "1.0-0" }} ({{
            gruppe?.alter_wochen | number : "1.0-0"
            }})</span>
    </div>
    <div style="margin-right: 24px">
        Eingestallte Tiere:
        <span style="font-weight: bold">{{
            gruppe?.anzahl_tiere | number : "1.0-0"
            }}</span>
    </div>
    <div style="margin-right: 24px">
        Abg&auml;nge:
        <span style="font-weight: bold">{{
            gruppe?.abgaenge | number : "1.0-0"
            }}</span>
    </div>
    <div style="margin-right: 24px">
        Verlust %:
        <span style="font-weight: bold">{{ gruppe?.verluste | number : "1.0-1" }}%</span>
    </div>
    <div style="margin-right: 24px">
        Verluste:
        <span style="font-weight: bold">{{
            gruppe?.tote ? gruppe.tote : (0 | number : "1.0-0")
            }}</span>
    </div>
    <div style="margin-right: 24px">
        Verkauft:
        <span style="font-weight: bold">{{
            gruppe?.verkaeufe | number : "1.0-1"
            }}</span>
    </div>
    <div style="margin-right: 24px">
        Anzahl Tiere:
        <span style="font-weight: bold">{{
            gruppe?.anzahl_tiere_aktuell | number : "1.0-0"
            }}</span>
    </div>
</div>

<div>
    <ul style="list-style-type: none">
        <li style="margin-top: 12px">
            <div class="one-row">
                <mat-form-field style="width: 50%">
                    <mat-label>Ohrmarke suchen</mat-label>
                    <input matInput type="text" [formControl]="ohrmarkeCtrl" />
                </mat-form-field>
                <span class="spacer"></span>
                <mat-form-field style="width: 300px">
                    <mat-label>Einstalldatum ausw&auml;hlen</mat-label>
                    <mat-select [formControl]="einstalldatumSelectCtrl">
                        <mat-option>Alle</mat-option>
                        <mat-option *ngFor="let ed of einstalldaten" [value]="ed.einstalldatum">{{ ed.einstalldatum |
                            date : "dd.MM.yyyy" }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <span class="spacer"></span>
            </div>
        </li>
    </ul>
    <table style="width: 100%" matSort (matSortChange)="sortTiere($event)">
        <thead>
            <tr>
                <th style="width: 5%; text-align: left">
                    <mat-checkbox *ngIf="auswahl" [checked]="selectedAll" (change)="selectAll()"></mat-checkbox>
                    <span style="padding-left: 4px; font-size: 14px">({{ numberSelected }}/{{ anzahlTiereSollwert
                        }})</span>
                </th>
                <th style="width: 10%" mat-sort-header="ohrmarke">Ohrmarke</th>
                <th style="width: 5%" mat-sort-header="einstalldatum_notnull">
                    Einstalldatum
                </th>
                <th style="width: 5%" mat-sort-header="geburtsdatum">
                    Geburtsdatum
                </th>
                <th style="width: 5%" mat-sort-header="geschlecht">
                    Geschlecht
                </th>
                <th style="width: 10%" mat-sort-header="alter_tage">
                    Alter in Tagen (Wochen)
                </th>
                <th style="width: 10%" mat-sort-header="wartezeit">
                    Wartezeit Fleisch / Milch
                </th>
                <th style="width: 10%" mat-sort-header="artikel">
                    Letzte Abgabe
                </th>
                <th style="width: 5%"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let t of tiere; let j = index" style="font-size: 14px"
                [style.background-color]="j % 2 === 0 ? 'whitesmoke' : 'while'">
                <td style="width: 5%">
                    <mat-checkbox *ngIf="auswahl" [disabled]="
                            numberSelected === anzahlTiereSollwert &&
                            !isChecked(t)
                        " [checked]="isChecked(t)" (change)="selectTier($event, t)">
                    </mat-checkbox>
                </td>
                <td style="width: 10%; text-align: left; font-size: 0.8em">
                    <kiene-ohrmarke [ohrmarke]="t?.ohrmarke"></kiene-ohrmarke>
                </td>
                <td style="width: 5%; text-align: left">
                    {{ t?.einstalldatum_notnull | date : "mediumDate" }}
                </td>
                <td style="width: 5%; text-align: left">
                    {{ t?.geburtsdatum | date : "mediumDate" }}
                </td>
                <td style="width: 5%; text-align: left">
                    {{ t?.geschlecht }}
                </td>
                <td style="width: 10%; text-align: left">
                    {{ t?.alter_tage | number : "1.0-0" }} ({{
                    t?.alter_wochen | number : "1.0-0"
                    }})
                </td>
                <td>
                    {{ t?.wartezeit_fleisch_bis | date : "mediumDate" }} /
                    {{ t?.wartezeit_milch_bis | date : "mediumDate" }}
                </td>
                <td>{{ t?.artikel }}</td>
                <td style="width: 5%">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="abgang(t)">
                            <mat-icon>remove_circle</mat-icon>
                            <span>Abgang</span>
                        </button>
                        <button mat-menu-item>
                            <mat-icon>delete</mat-icon>
                            <span>L&ouml;schen</span>
                        </button>
                    </mat-menu>
                </td>
            </tr>
        </tbody>
    </table>
    <br />
    <div *ngIf="showNachladeButton" class="one-row">
        <span class="spacer"></span>
        <button mat-stroked-button color="accent" (click)="tiereNachladen()">
            Restliche Tiere nachladen
        </button>
        <span class="spacer"></span>
    </div>
    <mat-paginator *ngIf="!selectedAll" [length]="totalRecords" [showFirstLastButtons]="true" [pageSize]="itemsPerPage"
        [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageChanged($event)">
    </mat-paginator>
</div>