<div style="padding: 0 24px 64px 24px">
    <div class="one-row" style="margin: 12px 0;">
        <kiene-page-header [showReturnButton]="showReturnButton" [ueberschrift]="'Meine Händler / Kontakte'"
                           [routeRelativeAlternative]="'../../'"></kiene-page-header>
        <span class="spacer"></span>
        <!--        <button (click)="anlegenHaendler()" color="accent" mat-icon-button>-->
        <!--            <mat-icon>add</mat-icon>-->
        <!--        </button>-->
    </div>

    <div style="margin-bottom: 12px">
        <mat-hint class="hinweis-text">
            <strong>Hinweis:</strong>
            <span> Neben Viehhändlern können auch allgemeine Kontakte (z.B. Abnehmer für den Abgang) gespeichert
                werden.</span>
        </mat-hint>
    </div>

    <div *ngIf="haendlerFehlt" style="margin-bottom: 12px">
        <mat-hint class="hinweis-text" style="display: flex; align-items: flex-start; justify-content: flex-start">
            <div>
                <span class="dot-warn"></span>
            </div>
            <div style="text-align: justify; margin-left: 6px">
                <span> Es muss mind. ein H&auml;ndler hinterlegt werden, sonst ist keine Abgangsmeldung
                    m&ouml;glich.</span>
            </div>
        </mat-hint>
    </div>

    <ng-container *ngFor="let h of favoritenHaendler">
        <div class="one-row">
            <h3>{{ h.bezeichnung }}<span class="default-haendler"
                                         *ngIf="h.betrieb_id == benutzerprofil.default_haendler_id">(Standard Händler)</span>
            </h3>
            <!--            <button mat-icon-button color="accent" [matMenuTriggerFor]="haendlerMenu">-->
            <!--                <mat-icon>more_vert</mat-icon>-->
            <!--            </button>-->
            <!--            <mat-menu #haendlerMenu>-->
            <!--                <button mat-menu-item (click)="editiereHaendler(h)">-->
            <!--                    <mat-icon>edit</mat-icon>-->
            <!--                    <span>bearbeiten</span>-->
            <!--                </button>-->
            <!--                <button mat-menu-item (click)="deleteHaendler(h)">-->
            <!--                    <mat-icon>delete</mat-icon>-->
            <!--                    <span>l&ouml;schen</span>-->
            <!--                </button>-->
            <!--                <button mat-menu-item (click)="setHaendlerAsDefault(h)">-->
            <!--                    <mat-icon>star</mat-icon>-->
            <!--                    <span>Als Standardhändler festlegen</span>-->
            <!--                </button>-->
            <!--            </mat-menu>-->
        </div>

        <div style="margin-bottom: 48px;">
            <kiene-key-value-list>
                <kiene-key-value-list-item key="VVVO" [value]="h.vvvo"></kiene-key-value-list-item>
                <kiene-key-value-list-item key="Stra&szlig;e Nr." [value]="h.strasse"></kiene-key-value-list-item>
                <kiene-key-value-list-item key="PLZ Ort" [value]="h.plz + ' ' + h.ort"></kiene-key-value-list-item>
                <kiene-key-value-list-item key="E-Mail" [value]="h.email"
                                           [actionButtonIcon]="h.email ? 'mail' : undefined"
                                           (actionClicked)="sendMail(h)"></kiene-key-value-list-item>
            </kiene-key-value-list>
        </div>
    </ng-container>

</div>
