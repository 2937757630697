import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { KeyValueListModule } from "../../../projects/kiene-core/src/lib/components/key-value-list/key-value-list.module";
import { ArticleScanAutocompleteModule } from '../components/article-scan-autocomplete/article-scan-autocomplete.module';
import { KieneAutocompleteModule } from "../components/kiene-autocomplete/kiene-autocomplete.module";
import { KieneTableModule } from "../components/kiene-table/kiene-table.module";
import { TimePickerModule } from "../components/time-picker/time-picker.module";
import { DialogsModule } from "../dialogs/dialogs.module";
import { BelegPositionModule } from '../documents/beleg-position/beleg-position.module';
import { GruppenModule } from "../gruppen/gruppen.module";
import { ReportModule } from "../report/report.module";
import { BesuchsDetailBelegComponent } from "./besuchs-detail-seite/besuchs-detail-beleg/besuchs-detail-beleg.component";
import {
    HeimtierDatenDialogComponent
} from "./besuchs-detail-seite/besuchs-detail-beleg/heimtier-daten-dialog/heimtier-daten-dialog.component";
import {
    BesuchsDetailInformationenComponent
} from "./besuchs-detail-seite/besuchs-detail-informationen/besuchs-detail-informationen.component";
import {
    BesuchsDetailLetzteBelegeComponent
} from "./besuchs-detail-seite/besuchs-detail-letzte-belege/besuchs-detail-letzte-belege.component";
import {
    BesuchsDetailRechnungenComponent
} from "./besuchs-detail-seite/besuchs-detail-rechnungen/besuchs-detail-rechnungen.component";
import { BesuchsDetailSeiteComponent } from "./besuchs-detail-seite/besuchs-detail-seite.component";
import { LeistungenComponent } from './besuchs-detail-seite/leistungen/leistungen.component';
import { HeimtierBelegeTableComponent } from "./heimtier-belege-table/heimtier-belege-table.component";
import { HeimtierKundenComponent } from './heimtier-kunden/heimtier-kunden.component';
import { HeimtiereRoutingModule } from './heimtiere-routing.module';
import { HeimtiereStartseiteComponent } from "./heimtiere-startseite/heimtiere-startseite.component";
import { NotizDialogComponent } from './heimtiere-startseite/notiz-dialog/notiz-dialog.component';
import { HeimtierDialogComponent } from './heimtiere/heimtier-dialog/heimtier-dialog.component';
import { HeimtierInfoComponent } from './heimtiere/heimtier-info/heimtier-info.component';
import { HeimtierKundeDialogComponent } from './heimtiere/heimtier-kunde-dialog/heimtier-kunde-dialog.component';
import { HeimtiereComponent } from './heimtiere/heimtiere.component';
import { KalenderEintragDialogComponent } from "./kalender/kalender-eintrag-dialog/kalender-eintrag-dialog.component";
import { KalenderComponent } from "./kalender/kalender.component";
import { DirectivesModule } from '../../../projects/kiene-core/src/lib/directives/directives.module';
import { KieneDirectivesModule } from '../directives/kiene-directives.module';
import { AnwendungsSelectorModule } from "../components/anwendungs-selector/anwendungs-selector.module";
import { TierartSelectorModule } from '../components/tierart-selector/tierart-selector.module';
import { DiagnoseSelectorModule } from '../components/diagnose-selector/diagnose-selector.module';
import { BesuchsDetailUntersuchungComponent } from './besuchs-detail-seite/besuchs-detail-untersuchung/besuchs-detail-untersuchung.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HeimtierBezahlungDialogComponent } from './heimtiere-startseite/heimtier-bezahlung-dialog/heimtier-bezahlung-dialog.component';


@NgModule({
    declarations: [
        HeimtiereComponent,
        HeimtierKundenComponent,
        HeimtierKundeDialogComponent,
        HeimtierDialogComponent,
        HeimtiereComponent,
        KalenderComponent,
        KalenderComponent,
        KalenderEintragDialogComponent,
        NotizDialogComponent,
        HeimtiereStartseiteComponent,
        BesuchsDetailSeiteComponent,
        BesuchsDetailRechnungenComponent,
        BesuchsDetailLetzteBelegeComponent,
        BesuchsDetailInformationenComponent,
        BesuchsDetailBelegComponent,
        LeistungenComponent,
        HeimtierInfoComponent,
        HeimtierBelegeTableComponent,
        HeimtierDatenDialogComponent,
        BesuchsDetailUntersuchungComponent,
        HeimtierBezahlungDialogComponent,
    ],
    imports: [
        CommonModule,
        HeimtiereRoutingModule,
        MatTabsModule,
        DialogsModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        MatInputModule,
        ReactiveFormsModule,
        KieneTableModule,
        MatDialogModule,
        MatDatepickerModule,
        MatCardModule,
        ReportModule,
        GruppenModule,
        MatDividerModule,
        KieneAutocompleteModule,
        MatGridListModule,
        DirectivesModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatSliderModule,
        FormsModule,
        MatCheckboxModule,
        MatListModule,
        MatBadgeModule,
        KeyValueListModule,
        MatSliderModule,
        FormsModule,
        MatCheckboxModule,
        MatListModule,
        MatBadgeModule,
        BelegPositionModule,
        ArticleScanAutocompleteModule,
        TimePickerModule,
        MatTableModule,
        KieneDirectivesModule,
        AnwendungsSelectorModule,
        TierartSelectorModule,
        DiagnoseSelectorModule,
        MatProgressSpinnerModule
    ]
})
export class HeimtiereModule {
}
