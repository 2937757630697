<h1 mat-dialog-title>Textbausteine bearbeiten</h1>
<div mat-dialog-content>
    <form [formGroup]="form">
        <mat-form-field style="width: 100%">
            <mat-label>Titel</mat-label>
            <input matInput type="text" formControlName="titel">
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-label>Textbaustein 1</mat-label>
            <textarea formControlName="textbaustein1" rows="6" matInput>{{ textbaustein?.textbaustein1 }}</textarea>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-label>Textbaustein 2</mat-label>
            <textarea formControlName="textbaustein2" rows="6" matInput>{{ textbaustein?.textbaustein2 }}</textarea>
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-label>Textbaustein 3</mat-label>
            <textarea formControlName="textbaustein3" rows="6" matInput>{{ textbaustein?.textbaustein3 }}</textarea>
        </mat-form-field>


        <div class="one-row">
            <mat-form-field>
                <mat-label>Typ</mat-label>
                <mat-select formControlName="typ">
                    <mat-option *ngFor="let t of typen" [value]="t">{{ t }}</mat-option>
                </mat-select>
            </mat-form-field>
            <span class="spacer"></span>
            <mat-checkbox *kienePermission="[3705]" formControlName="global">Global verfügbar</mat-checkbox>
        </div>
    </form>
</div>

<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()" [disabled]="isLoading">Speichern</button>
</div>