<div style="
        padding: 0 24px 0 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 100%;
        height: calc(100vh - 110px);
    ">
    <kiene-page-header [small]="true" [ueberschrift]="'Einstallen'"></kiene-page-header>

    <div class="one-row">
        <mat-form-field style="width: 80%">
            <mat-label>Abteil</mat-label>
            <input data-cy="einstallen-number-pad-abteil" matInput [formControl]="abteilCtrl" />
            <button mat-icon-button matSuffix [disabled]="abteilCtrl.value === null"
                (click)="abteilCtrl.setValue(null)">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>
        <span class="spacer"></span>

        <div style="width: 50px">
            <mat-slide-toggle data-cy="einstallen-number-pad-gewicht-toggle"
                [formControl]="mitGewichtToggleCtrl"></mat-slide-toggle>
            <p style="font-size: 0.8em">mit Gewicht</p>
        </div>
    </div>

    <div style="padding: 3px 12px 3px 12px">
        <div>
            <table style="width: 100%">
                <thead>
                    <tr>
                        <th style="
                                text-align: left;
                                font-size: 0.7em;
                                color: gray;
                                font-weight: normal;
                            ">
                            Tiere gesamt
                        </th>
                        <th style="
                                text-align: left;
                                font-size: 0.7em;
                                color: gray;
                                font-weight: normal;
                            ">
                            Akt. Abteil
                        </th>
                        <th style="
                                text-align: left;
                                font-size: 0.7em;
                                color: gray;
                                font-weight: normal;
                            ">
                            Pass &amp; Ohrmarke
                        </th>
                    </tr>
                    <tr style="
                            font-size: 1.5em;
                            font-weight: bold;
                            font-family: 'Courier New', sans-serif;
                        ">
                        <td data-cy="einstallen-number-pad-tiere-gesamt">{{
                            currentScanErgebnis?.anzahl_tiere_datensaetze }}</td>
                        <td data-cy="einstallen-number-pad-akt-abteil">
                            {{ currentScanErgebnis?.anzahl_abteil || "---" }}
                        </td>
                        <td data-cy="einstallen-number-pad-pass-ohrmarke">{{ currentScanErgebnis?.anzahl_tiere_match }}
                        </td>
                    </tr>
                </thead>
            </table>
        </div>
    </div>

    <div class="one-row" style="height: 50px">
        <p style="
                width: 95%;
                text-align: center;
                font-family: monospace;
                max-width: 95%;
                overflow-wrap: break-word;
                border: 6px solid;
                border-radius: 5px;
            " [style.borderColor]="checkBorderColor" [style.fontSize]="fontSize"
            [style.borderStyle]="keinErgebnis ? 'solid' : 'dashed'">
            {{ numbers }}
        </p>
    </div>

    <div class="one-row" style="margin-bottom: 5px; min-height: 20px">
        <span class="spacer"></span>
        <div style="width: 95%; text-align: center">
            <span *ngIf="isLoading">Suche Ohrmarke...</span>
            <div *ngIf="!isLoading">
                <span *ngIf="numbers.length < 5">
                    mind. 5 Zeichen - aktuell {{ numbers.length }}
                </span>
                <div *ngIf="numbers.length >= 5">
                    <span *ngIf="ohrmarken?.length > 0 && !ohrmarkeGefunden">
                        {{ ohrmarken?.length }} gefunden
                    </span>
                    <span *ngIf="ohrmarkeGefunden">
                        {{ getFirstTenDigits(ohrmarke) }}
                        <span style="font-weight: bold">{{
                            getLastFiveDigits(ohrmarke)
                            }}</span>
                    </span>
                    <span *ngIf="keinErgebnis" style="padding-bottom: 6px">
                        <div data-cy="einstallen-number-pad-manuell-text">Ohrmarke manuell hinzufügen</div>
                        <div style="font-size: 0.8em">
                            10 Zeichen - aktuell {{ numbers.length }}
                        </div>
                    </span>
                </div>
            </div>
        </div>
        <span class="spacer"></span>
    </div>

    <div style="flex-grow: 1"></div>

    <div style="margin: 12px 0 164px 0">
        <div class="one-row" style="margin-bottom: 6px">
            <span class="spacer"></span>
            <button mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                " data-cy="einstallen-number-pad-1" (click)="addNumber(1)">
                1
            </button>
            <div style="width: 12px"></div>
            <button mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                " data-cy="einstallen-number-pad-2" (click)="addNumber(2)">
                2
            </button>
            <div style="width: 12px"></div>
            <button mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                " data-cy="einstallen-number-pad-3" (click)="addNumber(3)">
                3
            </button>
            <span class="spacer"></span>
        </div>
        <div class="one-row" style="margin-bottom: 6px">
            <span class="spacer"></span>
            <button mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                " data-cy="einstallen-number-pad-4" (click)="addNumber(4)">
                4
            </button>
            <div style="width: 12px"></div>
            <button mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                " data-cy="einstallen-number-pad-5" (click)="addNumber(5)">
                5
            </button>
            <div style="width: 12px"></div>
            <button mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                " data-cy="einstallen-number-pad-6" (click)="addNumber(6)">
                6
            </button>
            <span class="spacer"></span>
        </div>
        <div class="one-row" style="margin-bottom: 6px">
            <span class="spacer"></span>
            <button mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                " data-cy="einstallen-number-pad-7" (click)="addNumber(7)">
                7
            </button>
            <div style="width: 12px"></div>
            <button mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                " data-cy="einstallen-number-pad-8" (click)="addNumber(8)">
                8
            </button>
            <div style="width: 12px"></div>
            <button mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                " data-cy="einstallen-number-pad-9" (click)="addNumber(9)">
                9
            </button>
            <span class="spacer"></span>
        </div>
        <div class="one-row" style="margin-bottom: 6px">
            <span class="spacer"></span>
            <div *ngIf="!ohrmarkeGefunden && !keinErgebnis" style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                    background-color: lightgray;
                "></div>

            <button *ngIf="ohrmarkeGefunden || keinErgebnis" mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                " [style.backgroundColor]="checkBackgroundColor"
                [disabled]="numbers.length !== 10 && !ohrmarkeGefunden && !isLoading"
                data-cy="einstallen-number-pad-check" (click)="check()">
                <mat-icon *ngIf="ohrmarkeGefunden && !keinErgebnis" [style.color]="checkColor">{{ ohrmarkeGefunden ?
                    "check" : "stop" }}</mat-icon>
                <mat-icon *ngIf="
                        keinErgebnis &&
                        !ohrmarkeGefunden &&
                        numbers.length === 10
                    " [style.color]="checkColor">
                    add</mat-icon>
            </button>

            <div style="width: 12px"></div>
            <button mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                " data-cy="einstallen-number-pad-0" (click)="addNumber(0)">
                0
            </button>
            <div style="width: 12px"></div>
            <button mat-button style="
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    background-color: lightgray;
                    font-weight: bold;
                    font-size: 1.8em;
                    touch-action: manipulation;
                " data-cy="einstallen-number-pad-backspace" (click)="backspace()">
                <mat-icon>backspace</mat-icon>
            </button>
            <span class="spacer"></span>
        </div>
    </div>
</div>