import {HttpParams} from '@angular/common/http';
import {Component, Inject, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {
    ConfirmDialogInput,
    ConfirmDialogResult,
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog';
import {
    ConfirmDialogComponent
} from '../../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component';
import {AppService} from '../../../app.service';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {ArrayTableColumn, TableColumn, URLTableColumn,} from '../../../components/kiene-table/table-column';
import {TableDescriptor} from '../../../components/kiene-table/table-descriptor';
import {Condition, Operator, TableOption, TableOptionEvent,} from '../../../components/kiene-table/table-option';
import {TabinfoService} from '../../../components/tabinfo/tabinfo.service';
import {ConfirmDeleteDialogComponent} from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {
    LocalStorageService
} from '../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {FileUploadService} from '../../../dialogs/file-upload-dialog/file-upload.service';
import {MessageService} from '../../../services/message.service';
import {Fortbildung} from '../fortbildung';
import {FortbildungDialogComponent} from '../fortbildung-dialog/fortbildung-dialog.component';
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput,
} from '../../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import {API_BASE_URL_SERVICE} from '../../../../../projects/kiene-core/src/lib/kiene-core.config';

@Component({
    selector: 'kiene-fortbildung',
    templateUrl: './fortbildung.component.html',
    styleUrls: ['./fortbildung.component.css'],
})
export class FortbildungComponent implements OnInit {
    tableDescriptor: TableDescriptor = new TableDescriptor();

    @Input('tierarztId') tierarztId = 0;

    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        public dialog: MatDialog,
        private router: Router,
        private messageService: MessageService,
        private localStorageService: LocalStorageService,
        private tableService: KieneTableService,
        private appService: AppService,
        private fileUploadService: FileUploadService,
        private pdfService: PdfViewerDesktopService,
        private tabinfoService: TabinfoService
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Fortbildungen';
        this.tableDescriptor.uniqueIdentifier = 'Fortbildungen';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'fortbildung/';
        this.tableDescriptor.route = '/home?tab=fortbildung';
        this.tableDescriptor.nameOfIdField = 'fortbildung_id';
        this.tableDescriptor.searchPlaceholder = 'Fortbildungen durchsuchen';
        this.tableDescriptor.uniqueIdentifier = 'Fortbildungen';

        this.tableDescriptor.createAllowed = true;
        this.tableDescriptor.standardCreate = false;
        this.tableDescriptor.showDetails = false;

        this.tableDescriptor.columns.push(new URLTableColumn('titel', 'Titel'));
        this.tableDescriptor.columns.push(
            new URLTableColumn('veranstalter', 'Veranstalter')
        );
        this.tableDescriptor.columns.push(
            new URLTableColumn('veranstaltungsort', 'Veranstaltungsort')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('datum', 'Datum', 'date')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('anmeldefrist', 'Anmeldefrist', 'date')
        );
        this.tableDescriptor.columns.push(
            new TableColumn('teilnahme', 'Teilnahme', 'boolean')
        );
        this.tableDescriptor.columns.push(
            new ArrayTableColumn('teilnehmer', 'Teilnehmer', ['teilnehmer'])
        );
        this.tableDescriptor.columns.push(new TableColumn('pdf_id', '', 'pdf'));

        this.tableDescriptor.disableConditions.push(
            new Condition('abgelaufen', Operator.GLEICH, 1)
        );

        this.tableDescriptor.expandable = true;
        this.tableDescriptor.expandableColumns = [
            new TableColumn('infotext', 'Infotext'),
        ];
        this.tableDescriptor.expandableFieldName = 'infotext_expand';

        this.tableDescriptor.permissionView = 4411;
        this.tableDescriptor.permissionCreate = 4412;
        this.tableDescriptor.permissionUpdate = 4412;
        this.tableDescriptor.permissionDelete = 4414;

        this.tableDescriptor.initialSortColumn = 'datum';
        this.tableDescriptor.initialSortDirection = 'desc';

        this.tableDescriptor.options.push(
            new TableOption('teilnehmen', 'Teilnehmen', 'check_box', 4415, {
                conditions: [
                    new Condition('teilnahme', Operator.GLEICH, 0),
                    new Condition('abgelaufen', Operator.GLEICH, 0),
                ]
            })
        );
        this.tableDescriptor.options.push(
            new TableOption(
                'teilnehmen',
                'Abmelden',
                'check_box_outline_blank',
                4415, {
                    conditions:
                        [
                            new Condition('teilnahme', Operator.GLEICH, 1),
                            new Condition('abgelaufen', Operator.GLEICH, 0),
                        ]
                }
            )
        );
        this.tableDescriptor.options.push(
            new TableOption('edit', 'Bearbeiten', 'edit', 4412, {
                conditions: [
                    new Condition('abgelaufen', Operator.GLEICH, 0),
                ]
            })
        );
        this.tableDescriptor.options.push(
            new TableOption('upload', 'PDF hochladen', 'upload', 4412, {
                conditions: [
                    new Condition('abgelaufen', Operator.GLEICH, 0),
                ]
            })
        );
        this.tableDescriptor.options.push(
            new TableOption('delete', 'Löschen', 'delete', 4414, {
                conditions: [
                    new Condition('abgelaufen', Operator.GLEICH, 0),
                ]
            })
        );

        this.tableService.watchDataLoadedCompleted().subscribe({
            next: (n) => {
                if (n) {
                    this.fortbildungenGeladen();
                }
            },
        });
    }

    fortbildungenGeladen() {
        this.tableService
            .post(this.apiBaseUrl + 'fortbildung/gesehen.php', {}, null)
            .subscribe({
                next: () => {
                    this.tabinfoService.setReload('fortbildung/tabinfo.php');
                },
                error: (err) => {
                    this.messageService.errorMessage(
                        'Fortbildungen konnten nicht als gelesen markiert werden!',
                        err
                    );
                },
            });
    }

    catchOptionEvent(event: TableOptionEvent) {
        const fortbildung: Fortbildung = event.value;
        if (event.name === 'add') {
            this.openFortbildungDialog();
        } else if (event.name === 'edit') {
            this.openFortbildungDialog(event.value);
        } else if (event.name === 'teilnehmen') {
            this.openTeilnehmenDialog(event.value);
        } else if (event.name === 'upload') {
            this.fileUploadService.openAddFilesDialog(
                event.value.fortbildung_id,
                'fortbildung_id',
                'fortbildung/update_pdf.php',
                () => {
                    this.messageService.infoMessage(
                        'Die Datei wurde erfolgreich hochgeladen!'
                    );
                    this.tableService.reload();
                },
                () => {
                }
            );
        } else if (event.name === 'showPdf') {
            const input = new PdfViewerDialogInput(
                'fortbildung/read_pdf.php',
                new HttpParams().set(
                    'fortbildung_id',
                    fortbildung?.fortbildung_id
                ),
                'Fortbildung PDF'
            );
            this.pdfService.openPdfViewer(input);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        }
    }

    openFortbildungDialog(fortbildung?: Fortbildung) {
        const dialogRef = this.dialog.open(FortbildungDialogComponent, {
            data: {
                fortbildung: fortbildung,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.tableService.reload();
        });
    }

    openTeilnehmenDialog(fortbildung: Fortbildung) {
        const input = new ConfirmDialogInput();
        input.headline = fortbildung.teilnahme === 0 ? 'Teilnahme' : 'Abmelden';
        input.text =
            fortbildung.teilnahme === 0
                ? 'Wollen Sie an dieser Fortbildung teilnehmen?'
                : 'Wollen Sie sich von dieser Fortbildung abmelden?';
        input.okButton =
            fortbildung.teilnahme === 0 ? 'Teilnehmen' : 'Abmelden';

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: input,
        });

        dialogRef.afterClosed().subscribe({
            next: (result: ConfirmDialogResult) => {
                if (result?.confirmed) {
                    const teilnahme = {
                        fortbildung_id: fortbildung.fortbildung_id,
                        teilnehmen: fortbildung.teilnahme === 0 ? 1 : 0,
                    };
                    this.tableService
                        .post(
                            this.apiBaseUrl + 'fortbildung/teilnehmen.php',
                            teilnahme,
                            null
                        )
                        .subscribe(
                            (response) => {
                                if (teilnahme.teilnehmen === 1) {
                                    this.messageService.infoMessage(
                                        'Sie haben sich erfolgreich für die Fortbildung angemeldet!'
                                    );
                                } else {
                                    this.messageService.infoMessage(
                                        'Sie haben sich erfolgreich von der Fortbildung abgemeldet!'
                                    );
                                }
                                this.tableService.reload();
                            },
                            (error) => {
                                this.messageService.errorMessage(
                                    'Fehler beim Speichern aufgetreten!',
                                    error
                                );
                                this.tableService.reload();
                            }
                        );
                }
            },
        });
    }

    openDeleteDialog(fortbildung: Fortbildung) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.tableService
                    .deleteEntity(this.apiBaseUrl + 'fortbildung/delete.php', fortbildung)
                    .subscribe(
                        () => {
                            this.messageService.infoMessage(
                                'Die Fortbildung wurde erfolgreich gelöscht!'
                            );
                            this.tableService.reload();
                        },
                        (error) => {
                            this.messageService.errorMessage(
                                'Fehler beim Löschen aufgetreten!',
                                error
                            );
                        }
                    );
            }
        });
    }
}
