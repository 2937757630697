export class TierwohlprotokollRind {
    abrechnen: number;
    abgabebeleg_id: number;
    twp_rind_id: number;

    datum: Date;
    status_id: number;
    status: string;

    tierarzt_id: number;
    tierarzt_vorname: string;
    tierarzt_nachname: string;

    kunde_id: number;
    kunde: string;
    kunde_ort: string;
    kunde_plz: string;
    kunde_strasse: string;
    kunde_telefon_1: string;
    kunde_vvvo: string;

    anzahl_milchkuehe: number;
    anzahl_faersen: number;
    anzahl_kaelber: number;
    anzahl_mastrinder: number;
    anzahl_mastkaelber: number;

    verluste_kaelber: string;
    verluste_adulte_tiere: string;
    verluste_abortrate: string;
    verluste_totgeburten: string;

    biologisch_tageszunahmen_masttiere: number;
    biologisch_nutzungsdauer_milchvieh: number;

    gesundheitsstatus_bhv1: number;
    gesundheitsstatus_bvd: number;
    bestand_klinisch_ohne_befund: number;
    hinweise_tierseuchen: number;

    mangagementfaktor_haltungssystem: number;
    managementfaktor_fuetterung_wasserversorgung: number;
    managementfaktor_betrieb: number;
    managementfaktor_hygiene: number;
    managementfaktor_zucht: number;
    managementfaktor_sonstiges: number;

    gesundheitsstatus_atmungsorgane: number;
    gesundheitsstatus_verdauungsapparat: number;
    gesundheitsstatus_bewegungsapparat: number;
    gesundheitsstatus_haut_kondition: number;
    gesundheitsstatus_eutergesundheit: number;
    gesundheitsstatus_sonstiges: number;

    verdachtsdiagnosen: string;

    diagnostische_massnahmen_sektion: number;
    diagnostische_massnahmen_kot: number;
    diagnostische_massnahmen_resistenztest: number;
    diagnostische_massnahmen_futter_wasser: number;
    diagnostische_massnahmen_blut: number;
    diagnostische_massnahmen_hautgeschabsel: number;
    diagnostische_massnahmen_milch: number;
    diagnostische_massnahmen_tupfer: number;
    diagnostische_massnahmen_sonstiges: number;
    diagnostische_massnahmen_sonstiges_txt: string;

    antibiotika_monitoring: number;
    antibiotika_monitoring_therapieindex: string;

    betriebliche_eigenkontrolle: number;
    betriebliche_eigenkontrolle_txt: string;

    tierschutzindikatoren: number;
    tierschutzindikatoren_txt: string;

    milchleistungspruefung: number;
    milchleistungspruefung_txt: string;

    rationsberechnung: number;
    rationsberechnung_txt: string;

    traenkwasser_qualitaet: number;
    traenkwasser_qualitaet_txt: string;

    stallklima_kontrolliert: number;

    impfprogramm_bvd: number;
    impfprogramm_mutterschutz: number;
    impfprogramm_bhv1: number;
    impfprogramm_blauzunge: number;
    impfprogramm_brsv_pasteurellen: number;
    impfprogramm_sonstiges: number;
    impfprogramm_sonstiges_txt: string;

    parasitologische_massnahmen_endoparasiten: number;
    parasitologische_massnahmen_endoparasiten_zuletzt: Date;

    parasitologische_massnahmen_ektoparasiten: number;
    parasitologische_massnahmen_ektoparasiten_zuletzt: Date;

    einschaetzung_auffaelligkeiten_bestandsprobleme: string;
    empfehlungen_massnahmen: string;
    weitere_bemerkungen: string;

    plan_tiergesundheit_hygienemanagement: number;
    massnahmenplan_einzelaktivitaeten: number;

    durchfuehrung_bis: Date;
    naechster_bestandsbesuch: Date;
}
