import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Abgabebeleg, AbgabebelegPosition, AbgabebelegStatus} from '../../abgabebeleg';
import {
    LocalStorageService
} from '../../../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {
    EnterValueInput,
    EnterValueResponse
} from '../../../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-response';
import {
    EnterValueDialogComponent
} from '../../../../../../projects/kiene-core/src/lib/components/dialogs/enter-value-dialog/enter-value-dialog.component';
import {
    KieneBackendApiService
} from '../../../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../../../../../../projects/kiene-core/src/lib/kiene-core.config';
import {MessageService} from '../../../../services/message.service';
import {ConfirmDeleteDialogComponent} from '../../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';

@Component({
    selector: 'kiene-position-details-dialog',
    templateUrl: './position-details-dialog.component.html',
    styleUrls: ['./position-details-dialog.component.scss']
})
export class PositionDetailsDialogComponent implements OnInit {

    currentUserIsAdmin = false;
    isLoading = false;
    p: AbgabebelegPosition;
    abgabebeleg: Abgabebeleg;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<PositionDetailsDialogComponent>,
                private localStorageService: LocalStorageService,
                private dialog: MatDialog,
                private api: KieneBackendApiService,
                private messageService: MessageService,
                @Inject(API_BASE_URL_SERVICE) public apiBaseUrl: string,) {
        this.p = this.data?.p;
        this.abgabebeleg = this.data?.abgabebeleg;
        this.currentUserIsAdmin = this.localStorageService.getCurrentUser().admin > 0;
    }

    ngOnInit(): void {
    }

    chargenText(p: AbgabebelegPosition) {
        let txt = '';
        for (const c of p?.chargen) {
            txt += c.chargenmenge + 'x ' + c.charge + ' MHD: ' + c.mhd + '; ';
        }
        console.log(txt);
        return txt;
    }

    preisEditierbar(): boolean {
        if (this.abgabebeleg?.status_id === AbgabebelegStatus.ERSTELLT || this.abgabebeleg?.status_id === AbgabebelegStatus.WIRD_KORRIGIERT) {
            return (
                this.localStorageService.hasPermission(3058) &&
                !this.abgabebeleg.rechnung_ids
            );
        } else {
            return false;
        }
    }

    hasPermission(id: number) {
        return this.localStorageService.hasPermission(id);
    }

    einzelpreisEditieren() {
        if (this.hasPermission(1229) && this.preisEditierbar()) {
            const input = new EnterValueInput();
            input.title = 'Einzelpreis ändern';
            input.text = 'Bitte geben Sie den Einzelpreis für diese Position ein.';
            input.okButton = 'Speichern';
            input.buttonColor = 'accent';
            input.addInputValue('Einzelpreis', 'decimal', this.p.einzelpreis);

            const dialogRef = this.dialog.open(EnterValueDialogComponent, {data: input});

            dialogRef.afterClosed().subscribe((result: EnterValueResponse) => {
                if (result.result) {
                    this.p.einzelpreis = result.getValue(0);
                    this.api
                        .updateGeneric(
                            this.apiBaseUrl +
                            'abgabebeleg/update_einzelpreis.php',
                            this.p
                        )
                        .subscribe({
                            next: () => {
                                this.messageService.infoMessage(
                                    'Der Einzelpreis wurde erfolgreich gespeichert!'
                                );
                            }
                            ,
                            error: (err) => {
                                this.messageService.errorMessage(
                                    'Der Einzelpreis konnte nicht gespeichert werden!',
                                    err
                                );
                            }
                        });
                }
            });
        }
    }

    removePosition() {
        this.isLoading = true;
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent);
        dialogRef.afterClosed().subscribe({
            next: result => {
                this.isLoading = false;
                if (result) {
                    const index = this.abgabebeleg.positionen.indexOf(this.p);
                    if (this.p.stueckliste === 1) {
                        this.removeStuecklistenpositionen(this.p);
                    }
                    this.abgabebeleg.positionen.splice(index, 1);
                    this.save();
                }
            }
        });

    }

    removeStuecklistenpositionen(pos: AbgabebelegPosition) {
        const liste = [];
        for (const p of this.abgabebeleg.positionen) {
            if (p.aus_stueckliste === pos.aa_id) {
                liste.push(p);
            }
        }
        for (const l of liste) {
            const index = this.abgabebeleg.positionen.indexOf(l);
            this.abgabebeleg.positionen.splice(index, 1);
        }
    }

    private save() {
        this.isLoading = true;
        this.api.updateGeneric(this.apiBaseUrl + 'abgabebeleg/update.php', this.abgabebeleg)
            .subscribe({
                next: (response) => {
                    this.isLoading = false;
                    this.dialogRef.close(response);
                },
                error: (err) => {
                    this.isLoading = false;
                    this.messageService.errorMessage('Fehler!', err);
                }
            });
    }
}
