import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {CalendarView} from 'angular-calendar';
import {KalenderEintrag, KalenderTyp} from './classes/kalender-eintrag';
import {HttpParams} from '@angular/common/http';
import {UrlaubskalenderService} from './urlaubskalender.service';
import * as moment from 'moment';
import {UntypedFormControl} from '@angular/forms';
import {TierarztGruppe} from '../../report/tierarzt/tierarzt-gruppe-report/tierarzt-gruppe';
import {User, UserRole} from '../../administration/user/user';
import {KieneTableService} from '../../components/kiene-table/kiene-table.service';
import {MessageService} from '../../services/message.service';
import {MatDialog} from '@angular/material/dialog';
import {
    LocalStorageService
} from '../../../../projects/kiene-core/src/lib/services/local-storage/local-storage.service';
import {
    UrlaubsantragStellenDialogComponent
} from './urlaubsantrag-stellen-dialog/urlaubsantrag-stellen-dialog.component';
import {
    UrlaubsantragGenehmigenDialogComponent
} from './urlaubsantrag-genehmigen-dialog/urlaubsantrag-genehmigen-dialog.component';
import {KrankMeldungDialogComponent} from './krank-meldung-dialog/krank-meldung-dialog.component';
import {
    KalendereintragStornierenDialogComponent
} from './urlaubsantrag-stornieren-dialog/kalendereintrag-stornieren-dialog.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {UrlaubskalenderMultiMonthComponent} from './urlaubskalender-multi-month/urlaubskalender-multi-month.component';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';
import {KieneSession} from '../../../../projects/kiene-core/src/lib/services/local-storage/kiene-session';
import {
    WochenendeUndFeiertagPlanungComponent
} from '../wochenende-und-feiertag-planung/wochenende-und-feiertag-planung.component';

export const YEAR_MONTH_FORMAT = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'kiene-urlaubskalender',
    templateUrl: './urlaubskalender.component.html',
    styleUrls: ['./urlaubskalender.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {provide: MAT_DATE_FORMATS, useValue: YEAR_MONTH_FORMAT},
    ],
})
export class UrlaubskalenderComponent implements OnInit {
    @Input() view: CalendarView;

    @Input() locale = 'de';

    @ViewChild('multiMonthCalendar')
    multiMonthCalendar: UrlaubskalenderMultiMonthComponent;

    queryParams: HttpParams = new HttpParams();

    startCtrl: UntypedFormControl = new UntypedFormControl();
    endCtrl: UntypedFormControl = new UntypedFormControl();

    colors = {
        Urlaub: '#479864',
        Krankheit: '#B6174B',
        offen: '#FFD500FF',
        wochenendDienstFeiertag: '#00a3bc',
    };

    gruppenCtrl: UntypedFormControl = new UntypedFormControl();
    gruppen: TierarztGruppe[] = [];

    benutzerCtrl: UntypedFormControl = new UntypedFormControl();
    benutzer: User[] = [];

    benutzerrollenCtrl: UntypedFormControl = new UntypedFormControl();
    benutzerrollen: UserRole[] = [];

    offeneUrlaubsantraege: KalenderEintrag[] = [];

    meineUrlaubstage: KalenderEintrag[] = [];
    meineGenehmigtenUrlaubstageDiesesJahr = 0;
    meineBeantragtenUrlaubstageDiesesJahr = 0;

    isAllowed = false;
    aktuellesJahr = new Date().getFullYear();

    currentUser: KieneSession;

    constructor(
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        private api: KieneTableService,
        private messageService: MessageService,
        private dialog: MatDialog,
        private localStorageService: LocalStorageService,
        public kalenderService: UrlaubskalenderService
    ) {
    }

    ngOnInit(): void {
        this.currentUser = this.localStorageService.getCurrentUser();
        // inital filtering
        this.startCtrl.setValue(new Date());
        this.endCtrl.setValue(moment(new Date()).add(2, 'month').toDate());

        this.filter();

        this.isAllowed = this.localStorageService.hasAllPermissions(
            this.kalenderService.filterPermissions
        );
        if (this.isAllowed) {
            this.loadTierarztGruppen();
            this.loadBenutzer();
            this.loadBenutzerrollen();
        } else {
            this.gruppenCtrl.disable();
            this.benutzerCtrl.disable();
            this.benutzerrollenCtrl.disable();
        }

        if (
            this.localStorageService.hasPermission(
                this.kalenderService.kalenderRechte.urlaubGenehmigen
            )
        ) {
            this.loadOffeneUrlaubsantraege();
        }

        this.meineUrlaubstageDiesesJahr();
    }

    loadTierarztGruppen() {
        this.kalenderService.loadTierarztGruppen().subscribe({
            next: (data) => {
                this.gruppen = data.records;
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Fehler beim Laden der Tierarzt-Gruppen',
                    err
                );
            },
        });
    }

    loadBenutzer() {
        let params = new HttpParams().append('praxis', '1');
        params = params.append('orderby', 'nachname,vorname');
        this.api.get(this.apiBaseUrl + 'benutzer/read.php', params).subscribe({
            next: (data) => {
                this.benutzer = data.records;
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Fehler beim Laden der Benutzer',
                    err
                );
            },
        });
    }

    loadBenutzerrollen() {
        const params = new HttpParams().append('praxis', '1');
        this.api.get(this.apiBaseUrl + 'benutzerrolle/read.php', params).subscribe({
            next: (data) => {
                this.benutzerrollen = data.records;
            },
            error: (err) => {
                this.messageService.errorMessage(
                    'Fehler beim Laden der Benutzerrollen',
                    err
                );
            },
        });
    }

    resetFilter($event: MouseEvent, ctrl: UntypedFormControl) {
        $event.stopPropagation();
        ctrl.setValue(null);
        this.filter();
    }

    filter() {
        let params = new HttpParams();

        if (this.gruppenCtrl.value) {
            this.gruppenCtrl.value.forEach((gruppe) => {
                params = params.append(
                    'tierarztgruppe_id[]',
                    gruppe.tierarztgruppe_id.toString()
                );
            });
        }

        if (this.benutzerCtrl.value) {
            this.benutzerCtrl.value.forEach((b) => {
                params = params.append('benutzer_id[]', b.benutzer_id.toString());
            });
        }

        if (this.benutzerrollenCtrl.value) {
            this.benutzerrollenCtrl.value.forEach((b) => {
                params = params.append(
                    'benutzerrolle_id[]',
                    b.benutzerrolle_id.toString()
                );
            });
        }

        if (this.startCtrl.value && this.endCtrl.value) {
            params = params.append(
                'datum_von',
                moment(this.startCtrl.value)
                    .startOf('month')
                    .toDate()
                    .toISOString()
            );
            params = params.append(
                'datum_bis',
                moment(this.endCtrl.value).endOf('month').toDate().toISOString()
            );
        }
        this.queryParams = params;
    }

    urlaubsantragStellen() {
        const dialogRef = this.dialog.open(
            UrlaubsantragStellenDialogComponent,
            {
                width: '25vw',
                data: {},
            }
        );
        dialogRef.afterClosed().subscribe((result: KalenderEintrag) => {
            if (result) {
                this.kalenderService.createOrUpdate(result).subscribe({
                    next: () => {
                        this.messageService.infoMessage(
                            'Urlaubsantrag wurde erfolgreich erstellt.'
                        );
                        this.multiMonthCalendar.reload();
                        this.meineUrlaubstageDiesesJahr();
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Fehler beim Erstellen des Urlaubsantrags.',
                            err
                        );
                    },
                });
            }
        });
    }

    urlaubsantragGenehmigen(kalenderEintrag?: KalenderEintrag) {
        const dialogRef = this.dialog.open(
            UrlaubsantragGenehmigenDialogComponent,
            {
                width: '50vw',
                data: {
                    offenerUrlaubsantrag: kalenderEintrag,
                },
            }
        );
        dialogRef.afterClosed().subscribe((result: KalenderEintrag) => {
            if (result) {
                this.multiMonthCalendar.reload();
            }
        });
    }

    krankheitMelden() {
        const dialogRef = this.dialog.open(KrankMeldungDialogComponent, {
            width: '25vw',
            data: {},
        });
        dialogRef.afterClosed().subscribe((result: KalenderEintrag) => {
            if (result) {
                this.kalenderService.createOrUpdate(result).subscribe({
                    next: () => {
                        this.messageService.infoMessage(
                            'Die Abwesenheitsmeldung wurde erfolgreich erstellt.'
                        );
                        this.multiMonthCalendar.reload();
                    },
                    error: (err) => {
                        this.messageService.errorMessage(
                            'Fehler beim Erstellen der Abwesenheitsmeldung',
                            err
                        );
                    },
                });
            }
        });
    }

    handleEventClick(kalenderEintrag: KalenderEintrag) {
        console.log('Click on calendar item: %s', JSON.stringify(kalenderEintrag));
        // Urlaub
        if (kalenderEintrag.eintragtyp === KalenderTyp.Urlaub) {
            // noch nicht genehmigt
            if (kalenderEintrag.genehmigt === 0) {
                // Recht zum genehmigen oder Nutzer klickt auf eigenen Urlaubsantrag
                if (
                    this.kalenderService.hasUrlaubGenehmigenPermission() ||
                    this.kalenderService.isOwnKalenderEintrag(kalenderEintrag)
                ) {
                    this.urlaubsantragGenehmigen(kalenderEintrag);
                }
            } else if (kalenderEintrag.genehmigt === 1) {
                this.eintragStornierenOderAendern(kalenderEintrag);
            }
        }

        // Krankmeldung
        if (kalenderEintrag.eintragtyp === KalenderTyp.Krankheit) {
            if (this.kalenderService.hasKrankMeldenPermission()) {
                this.eintragStornierenOderAendern(kalenderEintrag);
            }
        }
    }

    loadOffeneUrlaubsantraege() {
        this.kalenderService.getOffeneUrlaubsantraege().subscribe({
            next: (data) => {
                this.offeneUrlaubsantraege = data.records;
            },
            error: (err) => {
                console.log(err);
            },
        });
    }

    wochenendeFeiertagePlanen() {
        const dialogRef = this.dialog.open(
            WochenendeUndFeiertagPlanungComponent,
            {
                minWidth: '99vw',
                width: '100%',
                minHeight: '99vh'
            }
        );
        dialogRef.afterClosed().subscribe((hasEditedData) => {
            if (hasEditedData) {
                this.multiMonthCalendar.reload();
            }
        });
    }

    private eintragStornierenOderAendern(kalenderEintrag: KalenderEintrag) {
        if (!this.localStorageService.currentUserHasPermission(4525)) {
            return;
        }
        const dialogRef = this.dialog.open(
            KalendereintragStornierenDialogComponent,
            {
                width: '33vw',
                minWidth: '800px',
                data: {
                    kalendereintrag: kalenderEintrag,
                },
            }
        );
        dialogRef.afterClosed().subscribe((result: KalenderEintrag) => {
            if (result) {
                this.multiMonthCalendar.reload();
            }
        });
    }

    private meineUrlaubstageDiesesJahr() {
        this.kalenderService.ladeMeineUrlaubstageDiesesJahr().subscribe({
            next: result => {
                this.meineUrlaubstage = result.records;
                this.berechneMeineUrlaubstage();
            }
        });
    }

    private berechneMeineUrlaubstage() {
        this.meineBeantragtenUrlaubstageDiesesJahr = 0;
        this.meineGenehmigtenUrlaubstageDiesesJahr = 0;
        for (const ke of this.meineUrlaubstage) {
            const tage = this.kalenderService.gibAnzahlUrlaubstage(ke);
            if (ke.genehmigt === 1) {
                this.meineGenehmigtenUrlaubstageDiesesJahr += tage;
            } else {
                this.meineBeantragtenUrlaubstageDiesesJahr += tage;
            }

        }
    }
}
