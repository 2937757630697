import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Gruppe} from '../../../gruppen/classes/gruppe';
import {UntypedFormControl} from '@angular/forms';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent} from '@angular/common/http';
import {scan} from 'rxjs';
import {Upload} from '../gruppe-tier-pass-scan-verwaltung.component';
import {API_BASE_URL_SERVICE} from '../../../../kiene-core.config';
import {KieneBackendApiService} from '../../../../services/backend-api/kiene-backend-api.service';
import {MessageService} from '../../../../services/message-service/message.service';

import {
    HaendlerDetailsDialogComponent
} from '../../../stammdaten/haendler/haendler-details-dialog/haendler-details-dialog.component';
import {Haendler} from '../../../stammdaten/haendler/haendler';
import {KieneAutocompleteComponent} from '../../../kiene-autocomplete/kiene-autocomplete.component';
import {LocalStorageService} from '../../../../services/local-storage/local-storage.service';

@Component({
    selector: 'kiene-gruppe-tier-scan-importieren-dialog',
    templateUrl: './gruppe-tier-scan-importieren-dialog.component.html',
    styleUrls: ['./gruppe-tier-scan-importieren-dialog.component.scss']
})
export class GruppeTierScanImportierenDialogComponent implements OnInit {

    gruppe: Gruppe;
    @ViewChild('fileSelector') fileSelector;
    @ViewChild('viehhaendlerAutoComplete') viehhaendlerAutoComplete: KieneAutocompleteComponent;
    pdfFile: File;
    uploadStatus: Upload;
    isLoading = false;

    viehhaendlerCtrl: UntypedFormControl = new UntypedFormControl();
    kpEinstalldatumCtrl: UntypedFormControl = new UntypedFormControl();

    constructor(
        @Inject(API_BASE_URL_SERVICE) public apiBaseUrl,
        private api: KieneBackendApiService,
        public lss: LocalStorageService,
        private dialog: MatDialog,
        private messageService: MessageService,
        private http: HttpClient,
        public dialogRef: MatDialogRef<GruppeTierScanImportierenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Gruppe) {
        this.gruppe = data;
    }

    ngOnInit(): void {
    }


    handleFiles(event: any) {
        console.log(event);
        if (event) {
            const files = event.target.files;
            this.pdfFile = files.item(0);
        }
    }

    progressScan() {
        this.isLoading = true;
        const fd = new FormData();
        fd.append('dokument', this.pdfFile);
        if (this.viehhaendlerCtrl.value) {
            fd.append('viehhaendler_id', (<Haendler>this.viehhaendlerCtrl?.value)?.viehhaendler_id.toString());
        }
        fd.append('kp_einstalldatum', new Date(this.kpEinstalldatumCtrl?.value).toISOString());
        fd.append('gruppe_id', this.gruppe.gruppe_id.toString());

        const initialState: Upload = {state: 'PENDING', progress: 0};

        const calculateState = (upload: Upload, event: HttpEvent<unknown>): Upload => {
            if (this.isHttpProgressEvent(event)) {
                const currProgress = event.total ? Math.round((100 * event.loaded) / event.total) : upload.progress;
                return {
                    progress: currProgress,
                    state: currProgress === 100 ? 'DONE' : 'IN_PROGRESS',
                };
            }
            return upload;
        };

        this.http.post<any>(`${this.apiBaseUrl}rinderpassscan/create.php`, fd,
            {reportProgress: true, observe: 'events', headers: new HttpHeaders('Phito-Type: fileupload')})
            .pipe(scan(calculateState, initialState))
            .subscribe({
                next: (upload) => {
                    this.uploadStatus = upload;
                    console.log(upload);
                    if (this.uploadStatus.state === 'DONE') {
                        this.messageService.infoMessage('Die Datei wurde erfolgreich hochgeladen und wird im Hintergrund verarbeitet.');
                        this.isLoading = false;
                        this.dialogRef.close(true);
                    }
                },
                error: err => {
                    this.messageService.errorMessage('Fehler beim Hochladen der Datei!', err);
                    this.isLoading = false;
                }
            });
    }

    isHttpProgressEvent(
        event: HttpEvent<unknown>
    ): event is HttpProgressEvent {
        return (
            event.type === HttpEventType.DownloadProgress ||
            event.type === HttpEventType.UploadProgress
        );
    }


    isValid() {
        return this.kpEinstalldatumCtrl.value && this.pdfFile;
    }

    addViehhaendler() {
        this.dialog.open(HaendlerDetailsDialogComponent, {
            minWidth: '50vw'
        }).afterClosed().subscribe((res: Haendler) => {
            if (res) {
                this.viehhaendlerAutoComplete.setValue(res, true);
                this.viehhaendlerCtrl.setValue(res);
            }
        });
    }
}
