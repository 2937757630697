import {Component, Input, OnChanges, OnInit, SimpleChanges,} from '@angular/core';
import {TableDescriptor} from '../../../components/kiene-table/table-descriptor';
import {Material} from '../material';
import {MatDialog} from '@angular/material/dialog';
import {MaterialService} from '../material.service';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {MessageService} from '../../../services/message.service';
import {environment} from '../../../../environments/environment';
import {HttpParams} from '@angular/common/http';
import {TableColumn} from '../../../components/kiene-table/table-column';
import {TableOption, TableOptionEvent,} from '../../../components/kiene-table/table-option';
import {ConfirmDeleteDialogComponent} from '../../../dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import {
    AddTierartToArticleDialogComponent
} from './add-tierart-to-article-dialog/add-tierart-to-article-dialog.component';
import {ArtikelTierart} from '../../../components/tierart-selector/artikel-tierart';
import {SaveForAllDialogComponent} from '../../../dialogs/save-for-all-dialog/save-for-all-dialog.component';
import {Mandant} from '../../../../../projects/kiene-core/src/lib/components/mandanten/classes/mandant';

@Component({
    selector: 'kiene-artikel-tierart',
    templateUrl: './artikel-tierart.component.html',
    styleUrls: ['./artikel-tierart.component.scss'],
})
export class ArtikelTierartComponent implements OnInit, OnChanges {
    tableDescriptor = new TableDescriptor();

    @Input('material') material: Material;
    @Input('mandanten') mandanten: Mandant[] = [];

    constructor(
        public dialog: MatDialog,
        private materialService: MaterialService,
        private api: KieneTableService,
        private messageService: MessageService
    ) {
    }

    ngOnInit() {
        this.tableDescriptor.headline = 'Tierarten';
        this.tableDescriptor.uniqueIdentifier = 'ArtikelTierarten';
        this.tableDescriptor.apiUrl = environment.BASE_URI + 'artikel_tierart/';
        this.tableDescriptor.standardCreate = false;

        this.tableDescriptor.permissionView = 1301;
        this.tableDescriptor.permissionCreate = 1303;
        this.tableDescriptor.permissionUpdate = 1303;
        this.tableDescriptor.permissionDelete = 1304;

        if (this.material?.artikel_id) {
            this.tableDescriptor.params = new HttpParams().set(
                'artikel_id',
                this.material.artikel_id.toString()
            );
        } else {
            this.tableDescriptor.waitToLoad = true;
        }

        const columns: TableColumn[] = [];

        columns.push(new TableColumn('tierart', 'Tierart'));
        columns.push(new TableColumn('menge', 'Menge'));
        columns.push(new TableColumn('einheit', 'Einheit'));
        columns.push(new TableColumn('behandlung_tage', 'Behandlung in Tagen'));
        columns.push(new TableColumn('intervall_std', 'Intervall in Stunden'));
        columns.push(new TableColumn('faktor', 'Faktor'));
        columns.push(new TableColumn('wirktage_zb', 'Wirktage (Bsp.)'));
        columns.push(new TableColumn('koerpergewicht', 'Körpergewicht in KG'));
        columns.push(new TableColumn('dosierung_txt', 'Dosierungstext'));

        this.tableDescriptor.columns = columns;

        this.tableDescriptor.initialSortColumn = 'tierart';
        this.tableDescriptor.initialSortDirection = 'asc';
        this.tableDescriptor.defaultPageSize = 10;

        this.tableDescriptor.options.push(
            new TableOption('edit', 'Bearbeiten', 'edit', 1303)
        );

        this.tableDescriptor.options.push(
            new TableOption('delete', 'Löschen', 'delete', 1304)
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.material) {
            // Nur wenn sich das Material geändert hat
            if (this.material?.artikel_id && this.tableDescriptor.waitToLoad) {
                this.tableDescriptor.waitToLoad = false;
                this.tableDescriptor.params = new HttpParams().set(
                    'artikel_id',
                    this.material.artikel_id.toString()
                );
                this.api.reload('ngOnChanges Tierart');
            }
        }
    }

    optionClicked(event: TableOptionEvent) {
        console.debug('Table Option: ' + event.name);
        if (event.name === 'add') {
            this.openTierartDialog(null);
        } else if (event.name === 'edit') {
            this.openTierartDialog(event.value);
        } else if (event.name === 'delete') {
            this.openDeleteDialog(event.value);
        }
    }

    saveArtikelTierart(artikelTierart: ArtikelTierart) {
        if (this.mandanten.length > 1) {
            // Array enthält nur Daten mit entspr. Berechtigung und bei Kiene
            const dialogRef = this.dialog.open(SaveForAllDialogComponent, {
                data: {
                    availableMandanten: this.mandanten,
                },
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    artikelTierart.mandanten_ids = result;
                    this.save(artikelTierart);
                }
            });
        } else {
            this.save(artikelTierart);
        }
    }

    save(artikelTierart: ArtikelTierart) {
        this.api
            .updateEntity(environment.BASE_URI + 'artikel_tierart/update.php', artikelTierart)
            .subscribe(
                (saved) => {
                    this.messageService.infoMessage(
                        'Die Tierart ' +
                        saved.tierart +
                        ' wurde erfolgreich gespeichert!'
                    );
                    this.api.reload();
                },
                (error) => {
                    this.messageService.errorMessage(
                        'Fehler! Die Tierart ' +
                        artikelTierart.tierart +
                        ' konnte nicht gespeichert werden! ',
                        error
                    );
                }
            );
    }

    openTierartDialog(artikelTierart: ArtikelTierart) {
        const dialogRef = this.dialog.open(AddTierartToArticleDialogComponent, {
            width: '500px',
            data: {
                material: this.material,
                artikelTierart: artikelTierart,
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response) {
                this.saveArtikelTierart(response);
            } else {
                this.api.reload();
            }
        });
    }

    openDeleteDialog(artikelTierart: ArtikelTierart) {
        const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
            width: '500px',
            data: {
                title: artikelTierart.tierart + ' löschen?',
                text:
                    'Wollen Sie die Tierart ' +
                    artikelTierart.tierart +
                    ' wirklich löschen?',
            },
        });

        dialogRef.afterClosed().subscribe(
            (response) => {
                if (response) {
                    this.api
                        .deleteEntity(
                            environment.BASE_URI + 'artikel_tierart/delete.php',
                            artikelTierart
                        )
                        .subscribe(
                            (result) => {
                                this.messageService.infoMessage(
                                    'Die Tierart wurde erfolgreich gelöscht!'
                                );
                                this.api.reload();
                            },
                            (error) => {
                                this.messageService.errorMessage(
                                    'Fehler! Es ein Problem beim Löschen der Tierart aufgetreten! ',
                                    error
                                );
                            }
                        );
                }
            },
            (error) => {
            }
        );
    }
}
