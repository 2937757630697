<div style="padding: 0 12px 0 24px">

    <div class="one-row">
        <kiene-page-header [showReturnButton]="showReturnButton" [ueberschrift]="'Meine Tierreklamationen'"
                           [routeRelativeAlternative]="'../../'"></kiene-page-header>
        <span class="spacer"></span>
        <button *kienePermission="[9232]" (click)="reklamationAnlegen()" color="accent" mat-icon-button>
            <mat-icon>add</mat-icon>
        </button>
        <button *kienePermission="[9232]" (click)="zugriffFreigeben()" mat-icon-button color="accent">
            <mat-icon>share</mat-icon>
        </button>

    </div>


    <mat-hint
        style="font-size: 0.8em; text-align: justify; display: inline-block; padding: 6px;">
        <strong>Hinweis:</strong> Die hier angelegten Reklamationen dienen als
        Option für das Reklamieren eines Tieres (z.B. beim Einstallen).
    </mat-hint>

    <kiene-key-value-list>
        <ng-container *ngFor="let tk of tierreklamationen">

            <kiene-key-value-list-item style="cursor: pointer;" (click)="editTierreklamation(tk)" key="Beschreibung"
                                       [value]="tk.reklamation"
                                       [actionButtonIcon]="localStorageService.hasPermission(9234) ? 'delete' : undefined"
                                       (actionClicked)="deleteTierreklamation($event, tk)"></kiene-key-value-list-item>


        </ng-container>
    </kiene-key-value-list>

</div>
