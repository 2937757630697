<h1 mat-dialog-title>Tod melden</h1>
<div mat-dialog-content>
    <div style="padding: 12px">

        <p style="margin-bottom: 24px">
            <strong>Ohrmarke:</strong>
            <kiene-ohrmarke [ohrmarke]="tier.ohrmarke_notnull"></kiene-ohrmarke>
        </p>

        <mat-form-field class="no-padding-margin-mat-form-field" style=" width: 50%; padding-bottom: 24px">
            <mat-label>Todesdatum wählen</mat-label>
            <input matInput [formControl]="todesDatumCtrl" [matDatepicker]="picker" [readonly]="true">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error class="input-error" *ngIf="todesDatumCtrl.hasError('required')">Das Todesdatum ist ein
                Pflichtfeld.
            </mat-error>
        </mat-form-field>

        <div style="width: 100%; padding-bottom: 24px" class="one-row">
            <mat-form-field class="no-padding-margin-mat-form-field" style="width: 90%; padding-bottom: 12px">
                <mat-label>Entsorger</mat-label>
                <mat-select [formControl]="entsorgerCtrl" [compareWith]="compareEntsorger">
                    <mat-option>Kein Entsorger</mat-option>
                    <mat-option [value]="entsorger" *ngFor="let entsorger of entsorgerFavoriten">{{ entsorger.name }}
                    </mat-option>
                </mat-select>
                <mat-error style="padding-top: 24px" *ngIf="entsorgerCtrl.hasError('keine-kundennr-fuer-entsorger')">
                    Es ist keine Kundennr. beim Entsorger für die VVVO {{ vvvoOfTierGruppe }} hinterlegt. Bitte über die
                    Einstellungen nachtragen, sonst ist keine Meldung möglich.
                </mat-error>
            </mat-form-field>
            <div
                style="display:flex; align-items: center; justify-content: center; transform:scale(1.1); width: 10%; padding-bottom: 12px">
                <button mat-icon-button color="accent" (click)="entsorgerHinzufuegen($event)">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>


        <div style="width: 100%; display: flex; align-items: center; justify-content: center; padding: 6px 0 6px 0"
             *ngIf="entsorgerCtrl.hasError('keine-kundennr-fuer-entsorger')">
            <button mat-flat-button color="accent" style="width: 66%" (click)="kundenNrNachtragen()">Kundennr.
                nachtragen
            </button>
        </div>

        <mat-form-field style="width: 90%">
            <mat-label>Todesart w&auml;hlen</mat-label>
            <mat-select [formControl]="selectedTodesartCtrl">
                <mat-option [value]="todesart" *ngFor="let todesart of todesarten">{{ todesart.bezeichnung }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="selectedTodesartCtrl.hasError('required')">Es muss eine Todesart ausgew&auml;hlt
                werden.
            </mat-error>
        </mat-form-field>
    </div>

</div>
<div mat-dialog-actions>
    <button mat-flat-button (click)="dialogRef.close(null)">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="todMelden()"
            [disabled]="!todesDatumCtrl?.value || !selectedTodesartCtrl?.value">Tod melden
    </button>
</div>
