import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
    PdfViewerInput
} from '../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/classes/pdf-viewer-input';
import {API_BASE_URL_SERVICE} from '../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable({
    providedIn: 'root',
})
export class EmailService {
    constructor(private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    sendEmail(kieneMail: PdfViewerInput, url: string): Observable<string> {
        return this.http.post<string>(this.apiBaseUrl + url, kieneMail, kieneMail);
    }
}
