import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { MaterialPriceApi, MaterialPrice } from "./material-price";
import { environment } from "../../../../environments/environment";
import { AppConstants } from "../../../app.constants";

@Injectable({
    providedIn: "root",
})
export class MaterialPricesService {
    constructor(private http: HttpClient) {}

    loadMaterialPrices(materialId: number): Observable<MaterialPriceApi> {
        return this.http.get<MaterialPriceApi>(
            environment.BASE_URI +
                "artikel_verkaufspreis/read_vk.php?artikel_id=" +
                materialId
        );
    }

    saveMaterialPrices(materialPrices: any): Observable<void> {
        return this.http.post<void>(
            environment.BASE_URI + "artikel_verkaufspreis/update.php",
            materialPrices
        );
    }
}
