<div class="one-row">
    <h1 mat-dialog-title>Belege f&uuml;r stornierte Rechnung {{invoice?.rechnung_nr}} korrigieren</h1>
    <button mat-icon-button color="accent" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <div class="one-row" style="position: sticky;">
        <h5>Kunde: {{invoice?.kunde}}</h5>

    </div>
    <ul style="list-style-type: none;">
        <li *ngFor="let a of stornoResult?.abgabebelege">

            <div class="one-row">
                <mat-icon>description</mat-icon>
                <p style="font-weight:bold; margin-right: 24px; text-decoration: underline; cursor: pointer;"
                    (click)="openAbgabebelegInNewTab(a)">Abgabebeleg {{a.abgabebeleg_nr}} vom {{a.datum | date:
                    'dd.MM.yyyy'}}</p>
                <button *ngIf="!(a.status_id == 41 || a.status_id == 46 || a.status_id == 47)" mat-stroked-button
                    color="accent" (click)="reopenAbgabebeleg(a)" style="margin-right: 24px;">
                    <mat-icon>lock_open</mat-icon> wieder &ouml;ffnen
                </button>
                <button *ngIf="a.status_id == 41 || a.status_id == 46" mat-stroked-button color="accent"
                    (click)="lockAbgabebeleg(a)" style="margin-right: 24px;">
                    <mat-icon>lock</mat-icon> abschlie&szlig;en
                </button>
                <button [disabled]="a.status_id !== 42" mat-stroked-button color="warn" (click)="cancelAbgabebeleg(a)"
                    style="margin-right: 24px;">
                    <mat-icon>cancel</mat-icon> stornieren
                </button>
                <button [disabled]="a.status_id !== 42" mat-stroked-button color="warn"
                    (click)="openRemoveAbgabebelegDialog(a)">
                    <mat-icon>remove_circle</mat-icon> entfernen
                </button>
                <span class="spacer"></span>
            </div>
            <div *ngIf="a.status_id == 41 || a.status_id == 46" class="one-row" style="font-size: .8em;">
                <mat-icon style="color: orange;">warning</mat-icon>
                <p style="text-decoration: underline;">Es werden im Abgabebeleg nur die Artikelmenge, Einzelpreis und
                    Anzahl Chargen angepasst. Jegliche
                    Berechnungen, wie z.B. Dosierungen, m&uuml;ssen vom Tierarzt im Beleg selbst vorgenommen werden!</p>
                <span class="spacer"></span>
            </div>
            <table class="phito-table" style="margin-left: 24px;">
                <thead>
                    <tr>
                        <th></th>
                        <th>Artikel</th>
                        <th>Menge</th>
                        <th>Einzelpreis</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let p of a.positionen; let i = index">
                        <td></td>
                        <td>{{p.artikel_me}}</td>
                        <td *ngIf="a.status_id == 41 || a.status_id == 46">

                            <input class="value-input" matInput type="number"
                                [formControl]="formControlsArtikelmenge[p.aa_id]"
                                (blur)="updateArtikelmengeAbgabebelegposition(p)"
                                (keyup.enter)="updateArtikelmengeAbgabebelegposition(p)">
                        </td>
                        <td *ngIf="!(a.status_id == 41 || a.status_id == 46)">{{p.artikelmenge |
                            number: '1.0-2'}} Stk</td>

                        <td *ngIf="a.status_id == 41 || a.status_id == 46">
                            <input class="value-input" matInput type="number"
                                [formControl]="formControlsArtikelpreise[p.aa_id]"
                                (blur)="updateEinzelpreisAbgabebelegposition(p)"
                                (keyup.enter)="updateEinzelpreisAbgabebelegposition(p)">
                            <span>&nbsp;&euro;</span>
                        </td>
                        <td *ngIf="!(a.status_id == 41 || a.status_id == 46)">{{p.einzelpreis |
                            number: '1.2-2'}} &euro;</td>
                        <td style="width: 170px;">
                            <kiene-delete-button *ngIf="a.status_id == 41 || a.status_id == 46"
                                (delete)="deletePosition(a, i)"></kiene-delete-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </li>
        <li *ngFor="let b of stornoResult?.befunde">

            <div class="one-row">
                <mat-icon>description</mat-icon>
                <p style="font-weight:bold; margin-right: 24px; text-decoration: underline; cursor: pointer;"
                    (click)="openBefundInNewTab(b)">Befund {{b.tagebuch_nr}} vom {{b.ausgang | date:
                    'dd.MM.yyyy'}}</p>
                <button [disabled]="b.status_id !== 12" mat-stroked-button color="accent" (click)="reopenBefund(b)"
                    style="margin-right: 24px;">
                    <mat-icon>lock_open</mat-icon> wieder &ouml;ffnen
                </button>
                <button [disabled]="b.status_id !== 12" mat-stroked-button color="warn" (click)="cancelBefund(b)">
                    <mat-icon>cancel</mat-icon> stornieren
                </button>
                <button [disabled]="b.status_id !== 12" mat-stroked-button color="warn"
                    (click)="openRemoveBefundDialog(b)">
                    <mat-icon>remove_circle</mat-icon> entfernen
                </button>
                <span class="spacer"></span>
            </div>
            <table class="phito-table" style="margin-left: 24px;">
                <thead>
                    <tr>
                        <th></th>
                        <th>Artikel</th>
                        <th>Menge</th>
                        <th>Einzelpreis</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let p of b.positionen">
                        <td></td>
                        <td>{{p.artikel}}</td>
                        <td>{{p.artikelmenge}} Stk</td>
                        <td>{{p.einzelpreis | number: '1.2-2'}} &euro;</td>
                    </tr>
                </tbody>
            </table>
        </li>
    </ul>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="openCreateNewInvoiceDialog()">Neue Rechnung erstellen</button>
</div>