<h1 mat-dialog-title>Abteil {{tier?.abteil ? 'editieren' : 'hinzufügen'}}</h1>
<div mat-dialog-content>
    <mat-form-field style="width: 100%;">
        <mat-label>Abteil</mat-label>
        <input type="text" matInput [(ngModel)]="tier.abteil" />
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button mat-flat-button color="accent" (click)="save()">Speichern</button>
</div>