import {Gruppe} from './gruppe';
import {GruppeTier} from '../../gruppe-tier/classes/gruppe-tier';

export class EinstallenMeldenDialogInput {
    gruppe_id: number;
}

export class EinstallenMeldenDialogResult {
    ok = false;
    melden = false;
    tiereOhneEinstalldatumAnzeigen = false;
}

export class EinstallenMelden {
    gruppe_id: number;
    pass_und_ohrmarke: number;
    nur_pass_gescannt: number;
    nur_ohrmarke_gescannt: number;
    weder_pass_noch_ohrmarke: number;
}
