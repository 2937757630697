import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {TextProposalResult} from '../../interfaces/text-proposal-interface';
import {TextKuerzelApi, TextProposal} from './textkuerzel';
import {API_BASE_URL_SERVICE} from '../../../../projects/kiene-core/src/lib/kiene-core.config';

@Injectable({
    providedIn: 'root'
})
export class TextProposalService {

    constructor(private http: HttpClient,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string) {
    }

    createTextProposal(tp: TextProposal): Observable<TextProposal> {
        return this.http.post<TextProposal>(this.apiBaseUrl + 'textkuerzel/create.php', tp);
    }

    loadTextProposalForKey(key: string): Observable<TextProposalResult> {
        return this.http.get<TextProposalResult>(this.apiBaseUrl + 'textkuerzel/read_one.php?textkuerzel=' + key);
    }

    updateTextProposal(tp: TextProposal): Observable<TextProposal> {
        return this.http.post<TextProposal>(this.apiBaseUrl + 'textkuerzel/update.php', tp);
    }

    deleteTextProposal(tp: TextProposal): Observable<TextProposal> {
        return this.http.post<TextProposal>(this.apiBaseUrl + 'textkuerzel/delete.php', tp);
    }

    loadAllTextProposals(sortColumn: string, sortDirection: string, offset: number, limit: number, searchString: string): Observable<TextKuerzelApi> {
        return this.http.get<TextKuerzelApi>(this.apiBaseUrl + 'textkuerzel/read.php?orderby=' + sortColumn + ' ' + sortDirection + '&limit=' + limit + '&offset=' + offset + '&search=' + searchString);
    }

    saveTextForKuerzel(key: string, text: string): Observable<TextProposalResult> {
        const textkuerzel = {textkuerzel: key, wortgruppe: text};
        return this.http.post<TextProposalResult>(this.apiBaseUrl + 'textkuerzel/create.php', textkuerzel);
    }
}
