<h1 mat-dialog-title>HIT PIN aktualisieren (1/{{data.length}})</h1>
<div mat-dialog-content>
    <div class="one-row">
        <div style="width: 100px; text-align: center;">
            <mat-icon>info</mat-icon>
        </div>
        <span style="color: gray;">Anscheinend haben Sie ihre PIN in HI-Tier ge&auml;ndert! Bitte geben Sie hier Ihre
            aktuelle HI-Tier PIN ein, damit VETVET die HI-Tier Schnittstelle benutzen kann. Vielen Dank!</span>
    </div>
    <h2>{{aktuellerKunde?.bezeichnung_final}}</h2>
    <h3>{{aktuellerKunde?.zusatz_final}}</h3>
    <h3>VVVO: {{aktuellerKunde?.vvvo}}</h3>
    <mat-form-field style="width: 100%">
        <mat-label>Aktuelle HIT PIN eingeben</mat-label>
        <input [type]="visible ? 'text' : 'password'" matInput [formControl]="pinCtrl">
        <button matSuffix mat-icon-button (click)="visible = !visible">
            <mat-icon>{{visible ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-flat-button color="warn" (click)="removeVVVO()">Diesen Betrieb löschen</button>
    <span class="spacer"></span>
    <button mat-flat-button color="accent" (click)="pinAktualisieren()" [disabled]="!pinCtrl.valid">PIN aktualisieren
    </button>
</div>