<h1 mat-dialog-title>Tier {{!!tier ? 'editieren' : 'nachtragen'}}</h1>

<div mat-dialog-content>
    <kiene-autocomplete #autocomplete (elementSelected)="updateTier($event)" [displayFields]="['ohrmarke_notnull']"
                        [genericApiUrl]="readTiere" [kieneObject]="tier"
                        [params]="params" [placeholder]="'Ohrmarke suchen'" [required]="true"
                        [showClear]="true">
    </kiene-autocomplete>
</div>

<div mat-dialog-actions>
    <button (click)="onNoClick()" mat-flat-button>Abbrechen</button>
    <button (click)="save()" color="accent" mat-flat-button>Speichern</button>
</div>
