<div style="padding: 24px">
    <h1>Bestandsbesuchsprotokoll</h1>

    <div class="info-box">
        <mat-checkbox [formControl]="abrechnenCtrl">Bestandsbesuchsprotokoll abrechnen?</mat-checkbox>
        <span class="spacer"></span>
    </div>

    <div class="one-row">

        <mat-form-field style="margin-right: 12px;">
            <mat-label>Datum</mat-label>
            <input matInput [matDatepicker]="picker_4_1" [formControl]="datumCtrl" />
            <mat-datepicker-toggle matSuffix [for]="picker_4_1"></mat-datepicker-toggle>
            <mat-datepicker #picker_4_1></mat-datepicker>
        </mat-form-field>

        <div>

            <kiene-autocomplete *kienePermission="[1255]" style="width: 300px;" [kieneObject]="vet"
                [disabled]="bbp.status_id === 72 || tierarztAutocompleteDisabled"
                [genericApiUrl]="'tierarzt/read_autocomplete.php'" [displayFields]="['vorname', 'nachname']"
                [placeholder]="'Tierarzt'" (elementSelected)="setTierarzt($event)">
            </kiene-autocomplete>
        </div>
        <ng-container *kienePermission="[3853]">
            <button
                *ngIf="bbp?.status_id === 71 && kieneSession?.tierarzt_id > 0 && bbp?.tierarzt_id !== kieneSession?.tierarzt_id"
                style="margin-left: 6px;" mat-stroked-button color="accent" (click)="setCurrentUserAsVet()">Mich als
                Tierarzt setzen
            </button>
        </ng-container>

        <span class="spacer"></span>

        <button *kienePermission="[3853]" mat-icon-button color="accent" (click)="bbp.status_id = 71; initForm()">
            <mat-icon>edit</mat-icon>
        </button>
    </div>

    <p>{{ bbp.kunde }}</p>
    <p>{{ bbp.kunde_strasse }}</p>
    <p>{{ bbp.kunde_plz }} {{ bbp.kunde_ort }}</p>
    <p>{{ bbp.kunde_telefon_1 }}</p>

    <h3>Bestandsgröße</h3>
    <mat-form-field class="phito-field">
        <mat-label>Sauen</mat-label>
        <input matInput type="number" [formControl]="sauenCtrl" />
    </mat-form-field>
    <mat-form-field class="phito-field">
        <mat-label>Saugferkel</mat-label>
        <input matInput type="number" [formControl]="saugferkelCtrl" />
    </mat-form-field>
    <mat-form-field class="phito-field">
        <mat-label>Absatzferkel</mat-label>
        <input matInput type="number" [formControl]="absatzferkelCtrl" />
    </mat-form-field>
    <mat-form-field class="phito-field">
        <mat-label>Mastschweine</mat-label>
        <input matInput type="number" [formControl]="mastschweineCtrl" />
    </mat-form-field>
    <mat-form-field class="phito-field">
        <mat-label>Kühe</mat-label>
        <input matInput type="number" [formControl]="kueheCtrl" />
    </mat-form-field>
    <mat-form-field class="phito-field">
        <mat-label>Kälber</mat-label>
        <input matInput type="number" [formControl]="kaelberCtrl" />
    </mat-form-field>
    <mat-form-field class="phito-field">
        <mat-label>Fresser</mat-label>
        <input matInput type="number" [formControl]="fresserCtrl" />
    </mat-form-field>
    <mat-form-field class="phito-field">
        <mat-label>Bullen</mat-label>
        <input matInput type="number" [formControl]="bullenCtrl" />
    </mat-form-field>
    <mat-form-field class="phito-field">
        <mat-label>Mastrinder</mat-label>
        <input matInput type="number" [formControl]="mastrinderCtrl" />
    </mat-form-field>
    <br />
    <mat-form-field class="phito-field" style="width: 50%">
        <mat-label>Zu protokollierende Tiere</mat-label>
        <input matInput type="text" [formControl]="bestandCtrl" />
    </mat-form-field>
    <br />
    <mat-divider style="margin-bottom: 30px"></mat-divider>
    <mat-checkbox [formControl]="_9"><b>klinisch ohne besonderen Befund</b></mat-checkbox>
    <br /><br />
    <mat-checkbox [formControl]="_10"><b>klinische Erkrankungen der / des</b></mat-checkbox>
    <br />
    <div style="display: inline-block; margin-left: 48px">
        <mat-checkbox [formControl]="_11">Atmungsorgane</mat-checkbox>&nbsp;
        <mat-checkbox [formControl]="_12">Verdauungsorgane</mat-checkbox>&nbsp;
        <mat-checkbox [formControl]="_13">Nervensystems</mat-checkbox>&nbsp;
        <mat-checkbox [formControl]="_14">Haut</mat-checkbox>&nbsp;
        <mat-checkbox [formControl]="_15">Harn- und Geschlechtswege</mat-checkbox>&nbsp;
        <mat-checkbox [formControl]="_26">Bewegungsapparates</mat-checkbox>&nbsp;
        <mat-form-field class="phito-field" style="min-width: 250px">
            <mat-label>Sonstiges</mat-label>
            <input matInput type="text" [formControl]="_16" />
        </mat-form-field>
    </div>

    <br />
    <mat-checkbox [formControl]="_17"><b>Maßnahmen</b></mat-checkbox>
    <br />
    <div style="display: inline-block; margin-left: 48px">
        <mat-checkbox [formControl]="_18">Kotproben</mat-checkbox>&nbsp;
        <mat-checkbox [formControl]="_19">Blutproben</mat-checkbox>&nbsp;
        <mat-checkbox [formControl]="_20">Tupferproben</mat-checkbox>&nbsp;
        <mat-checkbox [formControl]="_21">Sektion(en)</mat-checkbox>&nbsp;
        <mat-checkbox [formControl]="_22">Mikrobiologie</mat-checkbox>&nbsp;
        <mat-checkbox [formControl]="_23">Medikamentierung</mat-checkbox>&nbsp;
        <mat-checkbox [formControl]="_23b">Massnahmenplan erstellt</mat-checkbox>&nbsp;
        <mat-checkbox [formControl]="_23c">Prüfung der halbjährlichen TH</mat-checkbox>&nbsp;
        <mat-form-field class="phito-field" style="min-width: 250px">
            <mat-label>Sonstiges</mat-label>
            <input matInput type="text" [formControl]="_25" />
        </mat-form-field>
    </div>

    <h3>Kommentare</h3>
    <mat-form-field style="width: 50%; min-width: 500px">
        <mat-hint *ngIf="focus_24">F4 dr&uuml;cken zum Ausw&auml;hlen von Textvorlagen</mat-hint>
        <mat-label>Kommentare</mat-label>
        <textarea matInput (focus)="focus_24 = true" (focusout)="focus_24 = false"
            [kieneTextBaustein]="getTextBausteinConfig()" [formControl]="_24"
            (textChanged)="setVerdachtsdiagnose($event)"></textarea>
    </mat-form-field>

    <div class="one-row" style="margin-top: 24px;">
        <!-- <button *ngIf="bbp.status_id !== 72" mat-raised-button color="primary" (click)="save(71)">Zwischenspeichern</button> -->
        <kiene-sign-button [disabled]="bbp.status_id === 72" [kuid]="bbp.kuid" [tierarzt_id]="kieneSession.tierarzt_id"
            [kunde_id]="bbp.kunde_id" (kuid)="bbp.kuid = $event"></kiene-sign-button>
        <span class="spacer"></span>
        <button *ngIf="bbp.status_id !== 72" mat-raised-button color="primary" (click)="save(72)">
            Abschließen
        </button>
    </div>
</div>