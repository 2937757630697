import { Directive } from '@angular/core';

@Directive({
  selector: '[kieneDropExterneBefundmitteilung]'
})
export class DropExterneBefundmitteilungDirective {

  constructor() { }

}
