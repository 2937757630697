import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {API_BASE_URL_SERVICE} from '../../kiene-core.config';
import {KieneBackendApiService} from '../../services/backend-api/kiene-backend-api.service';
import {LocalStorageService} from '../../services/local-storage/local-storage.service';
import {MessageService} from '../../services/message-service/message.service';

@Component({
    selector: 'kiene-debug-dialog',
    templateUrl: './debug-dialog.component.html',
    styleUrls: ['./debug-dialog.component.css']
})
export class DebugDialogComponent implements OnInit {

    sessionTokenCtrl = new UntypedFormControl();

    isAdmin = false;

    constructor(public dialogRef: MatDialogRef<DebugDialogComponent>, @Inject(MAT_DIALOG_DATA) private data,
                private api: KieneBackendApiService,
                private messageService: MessageService,
                private localStorageService: LocalStorageService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private router: Router) {
    }

    ngOnInit(): void {
        if (this.localStorageService.getCurrentUser().admin) {
            this.isAdmin = true;
        }
    }

    close() {
        this.dialogRef.close();
    }

    replaceSession() {
        const token = this.sessionTokenCtrl.value;
        this.localStorageService.setDebugToken(token);
        this.api.post(this.apiBaseUrl + 'session/refresh.php', {}, null).subscribe({
            next: response => {
                this.localStorageService.setCurrentUser(response);
                this.localStorageService.removeDebugToken();
                this.router.navigate(['/home']).then();
                this.dialogRef.close();
            },
            error: error => {
                this.messageService.errorMessage('Session konnte nicht ersetzt werden!', error);
            }
        });

    }

    dryLogout() {
        this.localStorageService.deleteBrowserSession('dryLogout');
        this.router.navigate(['/login']);
    }

}
