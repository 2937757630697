import {Inject, Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {KieneSession} from '../../services/local-storage/kiene-session';
import {KieneBackendApiService} from '../../services/backend-api/kiene-backend-api.service';
import {API_BASE_URL_SERVICE} from '../..//kiene-core.config';
import {MessageService} from '../../services/message-service/message.service';
import {LocalStorageService} from '../../services/local-storage/local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AppService {

    requestCounter = 0;
    contentLengthCounter = 0;

    dataLoading = new Subject<boolean>();
    blockOnLoading = new Subject<boolean>();

    constructor(private breakpointObserver: BreakpointObserver,
                private api: KieneBackendApiService,
                @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
                private localStorageService: LocalStorageService, private messageService: MessageService) {
        this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.HandsetPortrait]).subscribe((response) => {
            this._isMobileScreen = response.matches;
        });
        this.breakpointObserver.observe([Breakpoints.Tablet]).subscribe({
            next: (state) => {
                this._isTablet = state.matches;
            },
        });
        window.matchMedia('(orientation: portrait)').addEventListener('change', (event) => {
            if (event.matches) {
                this._isPortrait = true;
            } else {
                this._isPortrait = false;
            }
        });
    }

    private _isMobileScreen = false;

    get isMobileScreen(): boolean {
        return this._isMobileScreen;
    }

    set isMobileScreen(value: boolean) {
        this._isMobileScreen = value;
    }

    private _isTablet = false;

    get isTablet(): boolean {
        return this._isTablet;
    }

    set isTablet(value: boolean) {
        this._isTablet = value;
    }

    private _isPortrait = false;

    get isPortrait(): boolean {
        return this._isPortrait;
    }

    set isPortrait(value: boolean) {
        this._isPortrait = value;
    }

    addContentLength(cl: number): number {
        this.contentLengthCounter += cl;
        return this.contentLengthCounter;
    }

    getContentLengths() {
        return this.contentLengthCounter;
    }

    setDataLoadingStart() {
        this.requestCounter++;
        this.dataLoading.next(true);
    }

    setDataLoadingFinished() {
        if (this.requestCounter > 0) {
            this.requestCounter--;
        }
        if (this.requestCounter <= 0) {
            this.dataLoading.next(false);
        }
    }

    watchDataLoading(): Observable<boolean> {
        return this.dataLoading.asObservable();
    }

    setBlockOnLoading(blockOnLoading: boolean) {
        this.blockOnLoading.next(blockOnLoading);
    }

    watchBlockOnLoading(): Observable<boolean> {
        return this.blockOnLoading.asObservable();
    }

    generatePassword(length?: number) {
        let pwd = '';
        if (!length) {
            length = 24;
        }
        const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        const specicalChars = '!@#$%^&*()?><';

        const allChars = upperCaseChars + numbers + specicalChars + lowerCaseChars;

        let counter = 0;
        while (counter <= length) {
            pwd += allChars.charAt(Math.floor(Math.random() * allChars.length));
            counter++;
        }
        return pwd;
    }

    generateKienePassword() {
        const pwd = this.generatePassword();

        const len = pwd.length;
        let containsUpperCase = false;
        let containsLowerCase = false;
        let containsNumber = false;

        for (let i = 0; i < len; i++) {
            const char: any = pwd.charAt(i);
            if (!isNaN(char * 1)) {
                containsNumber = true;
            } else {
                if (char === char.toUpperCase()) {
                    containsUpperCase = true;
                }
                if (char === char.toLowerCase()) {
                    containsLowerCase = true;
                }
            }
            if (containsUpperCase && containsLowerCase && containsNumber) {
                return pwd;
            }
        }
        return this.generateKienePassword();
    }

    reloadSession() {
        this.api.post(this.apiBaseUrl + 'session/refresh.php', undefined).subscribe({
            next: (session: KieneSession) => {
                this.localStorageService.setCurrentUser(session);
                this.messageService.infoMessage('Session wurde erfolgreich aktualisiert!');
                location.reload();
            },
            error: err => {
                this.messageService.errorMessage('Session konnte nicht aktualisiert werden!', err);
            }
        });
    }
}
