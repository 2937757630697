import * as moment from 'moment';

export class KieneUtils {

    /**
     * RFC 2822 standard email validation
     * @param email
     * @returns
     */
    public static isEmailValid(email: string): boolean {
        if (/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(email)) {
            return true;
        } else {
            return false;
        }
    }

    static formatDDMMYYYY(date: Date) {
        date = new Date(date);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        const dayString = day < 10 ? '0' + day : day;
        const monthString = month < 10 ? '0' + month : month;
        return dayString + '.' + monthString + '.' + year;
    }

    static formatYYYY_MM_DD(date: Date) {
        date = new Date(date);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        const dayString = day < 10 ? '0' + day : day;
        const monthString = month < 10 ? '0' + month : month;
        return year + '-' + monthString + '-' + dayString;
    }

    static beautifyDate(createdAt: Date, options?: { showSeconds?: boolean; showYear?: boolean }) {
        const beautifiedDate = new Date(createdAt);
        const showSeconds = options?.showSeconds ?? false;
        const showYear = options?.showYear ?? false;
        const today = new Date();

        const time = `${beautifiedDate.getHours() <= 9 ? '0' + beautifiedDate.getHours() : beautifiedDate.getHours()}:${
            beautifiedDate.getMinutes() <= 9 ? '0' + beautifiedDate.getMinutes() : beautifiedDate.getMinutes()
        }${showSeconds ? ':' + beautifiedDate.getSeconds() : ''}`;

        // if tommorrow
        if (beautifiedDate.getDate() === moment(today).clone().add(1, 'day').toDate().getDate()) {
            return `Morgen ${time}`;
        }
        // if today
        if (
            beautifiedDate.getFullYear() === today.getFullYear() &&
            beautifiedDate.getMonth() === today.getMonth() &&
            beautifiedDate.getDate() === today.getDate()
        ) {
            return `Heute ${time}`;
        }
        // if yesterday
        if (
            beautifiedDate.getFullYear() === today.getFullYear() &&
            beautifiedDate.getMonth() === today.getMonth() &&
            beautifiedDate.getDate() === moment(today).clone().subtract(1, 'day').toDate().getDate()
        ) {
            moment(today).get('month');
            return `Gestern ${time}`;
        }
        // if this year
        if (beautifiedDate.getFullYear() === today.getFullYear()) {
            return `${beautifiedDate.getDate()}.${moment(beautifiedDate).get('month') + 1}${
                showYear ? ':' + beautifiedDate.getFullYear() : ''
            } ${time}`;
        }

        return `${beautifiedDate.getDate()}.${
            moment(beautifiedDate).get('month') + 1
        }.${beautifiedDate.getFullYear()} ${time}`;
    }

    static notNullOrUndefined(value: number) {
        return (value !== undefined && value !== null);
    }
}
