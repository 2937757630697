import {DatePipe, formatDate} from '@angular/common';
import {HttpParams} from '@angular/common/http';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ChartType} from 'angular-google-charts';
import {environment} from '../../../../environments/environment';
import {AppService} from '../../../app.service';
import {KieneTableService} from '../../../components/kiene-table/kiene-table.service';
import {ZeitauswahlChange} from '../../zeitauswahl/zeitauswahl-change';
import {UnternehmensgruppeEinzel} from './unternehmensgruppe-einzel';
import {CompanyGroup} from '../../../administration/company-group/company-group';
import {CsvColumn} from '../../../csv-export/csv-export/csv-column';
import {ReportService} from '../../report.service';
import {MessageService} from '../../../../../projects/kiene-core/src/lib/services/message-service/message.service';
import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ReportUtils} from '../../report-utils';
import {MatDialog} from '@angular/material/dialog';
import {KundeArtikelReportDialogComponent} from './kunde-artikel-report-dialog/kunde-artikel-report-dialog.component';

export class KundeArtikelReportInput {
    kunde_id: number;
    von: Date;
    bis: Date;
}

@Component({
    selector: 'kiene-unternehmensgruppe-einzel-report',
    templateUrl: './unternehmensgruppe-einzel-report.component.html',
    styleUrls: ['./unternehmensgruppe-einzel-report.component.scss'],
    animations: [
        trigger('detailExpanded', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ),
        ]),
    ],
})
export class UnternehmensgruppeEinzelReportComponent
    implements OnInit, AfterViewInit {
    displayedColumns = [
        'kunde',
        'umsatz_prozent',
        'umsatz',
        'wareneinsatz',
        'wareneinsatz_prozent',
        'rohertrag',
        'rohertrag_prozent',
        'abgabebelege',
        'rueckverguetung',
        'artikelverbrauch',
    ];
    dataSource = new MatTableDataSource<UnternehmensgruppeEinzel>();
    dataSourceVorjahr = new MatTableDataSource<UnternehmensgruppeEinzel>();
    @ViewChild(MatSort) sort: MatSort;
    expandedElement: UnternehmensgruppeEinzel | null;

    currentUnternehmensgruppe: CompanyGroup;

    abgabebelegeGesamt = 0;
    abgabebelegeGesamtVorjahr = 0;

    rabattCtrl = new FormControl();
    ergaenzerCtrl = new FormControl();
    aktuellSubscription: Subscription;
    vorjahrSubscription: Subscription;

    zeitauswahl: ZeitauswahlChange;
    vorjahreszeitraum: ZeitauswahlChange;

    chartType = ChartType.Line;
    chartColumns = ['Zeitraum', 'Umsatz', 'Wareneinsatz', 'Rohertrag'];
    chartData = [[new Date(), 0, 0, 0]];

    csvColumns: CsvColumn[] = [];
    csvColumnsDetails: CsvColumn[] = [];
    csvFilename = 'Unternehmensgruppe_Einzel.csv';

    gesamtUmsatz = 0;
    gesamtWareneinsatz = 0;
    gesamtWareneinsatzProzent = '0';
    gesamtRohertragProzent = '0';
    gesamtRohertrag = 0;

    gesamtUmsatzVorjahr = 0;
    gesamtWareneinsatzVorjahr = 0;
    gesamtWareneinsatzProzentVorjahr = '0';
    gesamtRohertragProzentVorjahr = '0';
    gesamtRohertragVorjahr = 0;

    isLoading: boolean[] = [];

    constructor(
        private api: KieneTableService,
        private appService: AppService,
        private datePipe: DatePipe,
        private reportService: ReportService,
        private messageService: MessageService,
        private dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.csvColumns.push(new CsvColumn('kunde', 'Kunde'));
        this.csvColumns.push(
            new CsvColumn('umsatz_prozent', 'Umsatz von Gesamt (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('umsatz', 'Umsatz'));
        this.csvColumns.push(new CsvColumn('wareneinsatz', 'Wareneinsatz'));
        this.csvColumns.push(
            new CsvColumn('wareneinsatz_prozent', 'Wareneinsatz (%)', '%')
        );
        this.csvColumns.push(new CsvColumn('rohertrag', 'Rohertrag'));
        this.csvColumns.push(
            new CsvColumn('rohertrag_prozent', 'Rohertrag (%)', '%')
        );
        this.csvColumns.push(
            new CsvColumn('abgabebelege', 'Anzahl Abgabebelege')
        );

        this.csvColumnsDetails = this.csvColumns.map((column) => ({
            ...column,
        }));
        this.csvColumnsDetails.splice(
            0,
            1,
            new CsvColumn('zeitraum', 'Zeitraum')
        );
    }

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }

    loadData() {
        this.loadDataAktuell();
        this.loadDataVorjahr();
    }

    loadDataAktuell() {
        // if(!this.currentUnternehmensgruppe){
        //   return;
        // }
        this.appService.setDataLoading(true);

        let params = this.createUnternehmensgruppeParams();

        params = params.set(
            'von',
            this.datePipe.transform(this.zeitauswahl.von, 'yyyy-MM-dd')
        );
        params = params.set(
            'bis',
            this.datePipe.transform(this.zeitauswahl.bis, 'yyyy-MM-dd')
        );

        this.aktuellSubscription?.unsubscribe();
        this.aktuellSubscription = this.api
            .get(
                environment.BASE_URI + 'report/read_kunde_ug.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSource.data = response.records;
                    this.resetChartData();
                    this.berechneGesamtwerte();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    loadDataVorjahr() {
        this.appService.setDataLoading(true);

        let params = this.createUnternehmensgruppeParams();

        params = params.set(
            'von',
            this.datePipe.transform(this.vorjahreszeitraum.von, 'yyyy-MM-dd')
        );
        params = params.set(
            'bis',
            this.datePipe.transform(this.vorjahreszeitraum.bis, 'yyyy-MM-dd')
        );

        this.vorjahrSubscription?.unsubscribe();
        this.vorjahrSubscription = this.api
            .get(
                environment.BASE_URI + 'report/read_kunde_ug.php',
                params
            )
            .subscribe(
                (response) => {
                    this.dataSourceVorjahr.data = response.records;
                    this.berechneGesamtwerteVorjahr();
                    this.appService.setDataLoading(false);
                },
                (error) => {
                    this.messageService.errorMessage('Fehler!', error);
                    this.appService.setDataLoading(false);
                }
            );
    }

    createUnternehmensgruppeParams(): HttpParams {
        let params = new HttpParams();
        if (this.currentUnternehmensgruppe) {
            params = params.set(
                'unternehmensgruppe_id',
                this.currentUnternehmensgruppe.unternehmensgruppe_id.toString()
            );
        } else {
            params = params.set(
                'unternehmensgruppe_id',
                'null'
            );
        }
        params = params.set('rabatt', this.rabattCtrl.value ? '1' : '0');
        params = params.set('ergaenzer', this.ergaenzerCtrl.value ? '1' : '0');
        return params;
    }

    setCurrentUnternehmensgruppe(ug: CompanyGroup) {
        this.currentUnternehmensgruppe = ug;
        this.loadData();
    }

    zeitintervalChanged(event: ZeitauswahlChange) {
        this.zeitauswahl = event;
        this.vorjahreszeitraum = ReportUtils.getVorjahreszeitraum(this.zeitauswahl);
        this.loadData();
    }

    berechneGesamtwerte() {
        this.gesamtUmsatz = 0;
        this.gesamtWareneinsatz = 0;
        this.gesamtRohertrag = 0;
        this.abgabebelegeGesamt = 0;

        const data = this.dataSource.data;
        for (const r of data) {
            this.abgabebelegeGesamt += r.abgabebelege ? r.abgabebelege : 0;
            this.gesamtUmsatz += r.umsatz ? r.umsatz : 0;
            this.gesamtWareneinsatz += r.wareneinsatz ? r.wareneinsatz : 0;
            this.gesamtRohertrag += r.rohertrag ? r.rohertrag : 0;
        }
        if (this.gesamtUmsatz > 0) {
            this.gesamtWareneinsatzProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtWareneinsatz * 100
                ) / 100
            ).toString();
            this.gesamtRohertragProzent = (
                Math.round(
                    (100 / this.gesamtUmsatz) * this.gesamtRohertrag * 100
                ) / 100
            ).toString();
        }
    }

    berechneGesamtwerteVorjahr() {
        this.gesamtUmsatzVorjahr = 0;
        this.gesamtWareneinsatzVorjahr = 0;
        this.gesamtRohertragVorjahr = 0;
        this.abgabebelegeGesamtVorjahr = 0;

        const data = this.dataSourceVorjahr.data;
        for (const r of data) {
            this.abgabebelegeGesamtVorjahr += r.abgabebelege ? r.abgabebelege : 0;
            this.gesamtUmsatzVorjahr += r.umsatz ? r.umsatz : 0;
            this.gesamtWareneinsatzVorjahr += r.wareneinsatz ? r.wareneinsatz : 0;
            this.gesamtRohertragVorjahr += r.rohertrag ? r.rohertrag : 0;
        }
        if (this.gesamtUmsatzVorjahr > 0) {
            this.gesamtWareneinsatzProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtWareneinsatzVorjahr * 100
                ) / 100
            ).toString();
            this.gesamtRohertragProzentVorjahr = (
                Math.round(
                    (100 / this.gesamtUmsatzVorjahr) * this.gesamtRohertragVorjahr * 100
                ) / 100
            ).toString();
        }
    }

    expandVetGroupRow(element: UnternehmensgruppeEinzel) {
        this.expandedElement =
            this.expandedElement === element ? null : element;
        if (this.expandedElement) {
            this.isLoading[element.kunde_id] = true;

            let params = new HttpParams();
            params = params.set('von', formatDate(this.zeitauswahl?.von, 'yyyy-MM-dd', 'de'));
            params = params.set('bis', formatDate(this.zeitauswahl?.bis, 'yyyy-MM-dd', 'de'));
            params = params.set('aggregieren', this.zeitauswahl?.aggregieren);
            params = params.set('kunde_id', element.kunde_id.toString());
            this.api
                .get(
                    environment.BASE_URI + 'report/read_kunde_ug.php',
                    params
                )
                .subscribe(
                    (response) => {
                        element.details = response.records;
                        this.isLoading[element.kunde_id] = false;
                        this.chartData = [];
                        for (const d of element.details) {
                            this.chartData.push([
                                d.datum,
                                d.umsatz ? d.umsatz : 0,
                                d.wareneinsatz ? d.wareneinsatz : 0,
                                d.rohertrag ? d.rohertrag : 0,
                            ]);
                        }
                    },
                    (error) => {
                        this.messageService.errorMessage('Fehler!', error);
                        this.isLoading[element.kunde_id] = false;
                    }
                );
        }
    }

    resetChartData() {
        this.chartData = [[new Date(), 0, 0, 0]];
    }

    rueckverguetung(kunde_id: number) {
        this.reportService.rueckverguetung(
            kunde_id,
            this.zeitauswahl.von,
            this.zeitauswahl.bis
        );
    }

    openArtikelDialog(kunde_id: any) {
        const input = new KundeArtikelReportInput();
        input.kunde_id = kunde_id;
        input.von = this.zeitauswahl.von;
        input.bis = this.zeitauswahl.bis;
        const dialogRef = this.dialog.open(KundeArtikelReportDialogComponent, {
            data: input,
            width: '90vw'
        });
    }
}
