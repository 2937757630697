import { Injectable } from '@angular/core';
import { HeimtierKalenderEintrag } from './kalender/heimtier-kalender-eintrag';
import { PdfViewerDesktopService, PdfViewerDialogInput } from '../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service';
import { HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { HeimtierBeleg } from './heimtier-beleg';

@Injectable({
  providedIn: 'root'
})
export class HeimtiereService {

  constructor(
    private pdfService: PdfViewerDesktopService
  ) { }

  rechnungAnzeigenBeleg(beleg: HeimtierBeleg) {
    if (beleg?.pdf_id_rechnung) {
      const subject = new Subject<boolean>();
      const input = new PdfViewerDialogInput(
        'rechnung/read_or_mail_pdf.php',
        new HttpParams().set(
          'rechnung_id',
          beleg?.rechnung_id?.toString()
        ),
        'Rechnung',
        { email: { sende_mail: true, kunde_id: beleg?.kunde_id } }
      );
      input.isInvoice = true;
      this.pdfService.openPdfViewer(input).subscribe({
        next: () => {
          subject.next(true);
        }
      });
      return subject;
    }
    return of(true);
  }

  rechnungAnzeigenEintrag(eintrag: HeimtierKalenderEintrag): Observable<boolean> {
    if (eintrag?.pdf_id_rechnung) {
      const subject = new Subject<boolean>();
      const input = new PdfViewerDialogInput(
        'rechnung/read_or_mail_pdf.php',
        new HttpParams().set(
          'rechnung_id',
          eintrag?.rechnung_id?.toString()
        ),
        'Rechnung',
        { email: { sende_mail: true, kunde_id: eintrag?.kunde_id } }
      );
      input.isInvoice = true;
      this.pdfService.openPdfViewer(input).subscribe({
        next: () => {
          subject.next(true);
        }
      });
      return subject;
    }
    return of(true);
  }

}
