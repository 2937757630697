import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Gruppe } from '../classes/gruppe';
import { API_BASE_URL_SERVICE } from '../../../kiene-core.config';
import { KieneBackendApiService } from '../../../services/backend-api/kiene-backend-api.service';
import { MessageService } from '../../../services/message-service/message.service';

import { GruppeStandortWechselnDialogResult } from './gruppe-standort-wechseln-dialog-result';
import { Betriebsart, Produktionsabschnitt } from '../../stammdaten/vvvo/classes/betriebsart';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { GruppeStandort } from '../gruppe-standort-verwalten/gruppe-standort';

@Component({
    selector: 'kiene-gruppe-standort-wechseln-dialog',
    templateUrl: './gruppe-standort-wechseln-dialog.component.html',
    styleUrls: ['./gruppe-standort-wechseln-dialog.component.scss']
})
export class GruppeStandortWechselnDialogComponent implements OnInit {

    gruppe: Gruppe;
    gruppeStandort: GruppeStandort;
    standortApiUrl = this.apiBaseUrl + 'gruppe/read_stall_kundenportal.php';
    params: HttpParams;

    standortSelected: string;
    stall: any;
    datumVonCtrl = new UntypedFormControl(undefined, [Validators.required]);
    datumBisCtrl = new FormControl();
    sgsNummerCtrl = new FormControl();

    produktionsabschnittCtrl = new FormControl<Produktionsabschnitt>(undefined);
    produktionsabschnitte: Produktionsabschnitt[] = [];
    produktionsabschnittSub: Subscription;

    anzahlTiereAnDatum = 0;
    gesamtgewichtCtrl = new FormControl();
    durchschnittsgewichtCtrl = new FormControl();
    showGewicht = false;
    sgsNummerEinblenden = false;

    constructor(public dialogRef: MatDialogRef<GruppeStandortWechselnDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string,
        @Inject(MAT_DATE_LOCALE) private locale: string,
        private api: KieneBackendApiService,
        private messageService: MessageService) {
        if (this.data?.gruppe) {
            this.gruppe = this.data.gruppe;
        } else {
            this.messageService.alertMessage('Keine Gruppe übergeben!');
        }

        if (this.data?.showGewicht) {
            this.showGewicht = true;
        }
    }

    ngOnInit(): void {

        this.initGewichtCtrls();

        if (this.gruppe?.kaelbermast > 0 || !!this.gruppe?.sgs_nummer) {
            this.sgsNummerEinblenden = true;
        }

        if (this.data.gruppeStandort) {
            this.gruppeStandort = <GruppeStandort>this.data.gruppeStandort;
            const stall = {
                stall: this.gruppeStandort.stall
            };
            this.stall = stall;
            const pa = new Produktionsabschnitt();
            pa.produktionsabschnitt = this.gruppeStandort.produktionsabschnitt;
            pa.produktionsabschnitt_id = this.gruppeStandort.produktionsabschnitt_id;
            this.produktionsabschnittCtrl.setValue(pa);
            this.datumVonCtrl.setValue(this.gruppeStandort.datum_von);
            this.datumBisCtrl.setValue(this.gruppeStandort.datum_bis);
            this.anzahlTiereAnDatum = this.gruppeStandort.anzahl_tiere;
            this.durchschnittsgewichtCtrl.setValue(this.gruppeStandort.einstallgewicht);
            this.sgsNummerCtrl.setValue(this.gruppeStandort.sgs_nummer);
        }

        this.params = new HttpParams().set('kunde_id', this.gruppe?.kunde_id.toString());
        this.ladeProduktionsabschnitte();

        this.initDatumCtrl();


        if (!this.gruppeStandort?.einstallgewicht || !this.gruppeStandort?.anzahl_tiere) {
            this.ladeTiereAnDatum(this.datumVonCtrl.value);
        }

    }

    setStandort(event: any) {
        if (event === null || event === undefined) {
            return;
        }
        if (typeof event === 'string') {
            this.standortSelected = event;
        } else if (typeof event === 'object') {
            this.standortSelected = event.stall;
            if (event.sgs_nummer) {
                this.sgsNummerCtrl.setValue(event.sgs_nummer);
            }
        } else {
        }
    }

    close() {
        this.dialogRef.close(new GruppeStandortWechselnDialogResult());
    }

    save() {
        if (!this.gruppeStandort) {
            this.gruppeStandort = new GruppeStandort();
        }
        this.gruppeStandort.gruppe_id = this.gruppe.gruppe_id;
        this.gruppeStandort.stall = this.standortSelected;
        this.gruppeStandort.datum_von = this.datumVonCtrl.value;
        this.gruppeStandort.datum_bis = this.datumBisCtrl.value;
        this.gruppeStandort.einstallgewicht = this.durchschnittsgewichtCtrl.value;
        this.gruppeStandort.anzahl_tiere = this.anzahlTiereAnDatum;
        this.gruppeStandort.produktionsabschnitt_id = this.produktionsabschnittCtrl.value?.produktionsabschnitt_id;
        this.gruppeStandort.sgs_nummer = this.sgsNummerCtrl.value;
        this.api.post(this.apiBaseUrl + 'gruppe_standort/update.php', this.gruppeStandort, undefined).subscribe({
            next: () => {
                const result = new GruppeStandortWechselnDialogResult();
                result.confirm = true;
                this.messageService.infoMessage('Der Standort wurde erfolgreich gewechselt!');
                this.dialogRef.close(result);
            },
            error: err => {
                this.messageService.errorMessage('Fehler!', err);
            }
        });
    }

    produktionsabschnittComparator(a: Produktionsabschnitt, b: Produktionsabschnitt): boolean {
        return a?.produktionsabschnitt_id === b?.produktionsabschnitt_id;
    }

    ladeProduktionsabschnitte() {
        if (this.gruppe?.kunde_id) {
            this.produktionsabschnittSub?.unsubscribe();
            this.produktionsabschnittSub = this.api.getByIdAlternative(this.apiBaseUrl + 'betriebsart/read_one_by_kunde.php', 'kunde_id', this.gruppe?.kunde_id?.toString()).subscribe({
                next: (betriebsart: Betriebsart) => {
                    this.produktionsabschnitte = betriebsart.produktionsabschnitte;
                    if (this.produktionsabschnitte?.length === 1) {
                        this.produktionsabschnittCtrl.setValue(this.produktionsabschnitte[0]);
                    }
                },
                error: err => {
                    this.messageService.errorMessage('Produktionsabschnitte konnten nicht geladen werden!', err);
                }
            });
        }
    }

    private initGewichtCtrls() {
        this.gesamtgewichtCtrl.valueChanges.subscribe({
            next: gesamtgewicht => {
                if (!isNaN(gesamtgewicht) && this.anzahlTiereAnDatum > 0) {
                    let dg = gesamtgewicht / this.anzahlTiereAnDatum;
                    dg = (Math.round(dg * 100)) / 100;
                    console.log('dg: ' + dg);
                    this.durchschnittsgewichtCtrl.setValue(dg, { emitEvent: false });
                }
            }
        });
        this.durchschnittsgewichtCtrl.valueChanges.subscribe({
            next: durchschnittsgewicht => {
                if (!isNaN(durchschnittsgewicht) && this.anzahlTiereAnDatum > 0) {
                    const gg = durchschnittsgewicht * this.anzahlTiereAnDatum;
                    console.log('gg: ' + gg);
                    this.gesamtgewichtCtrl.setValue(gg, { emitEvent: false });
                }
            }
        });
    }

    private initDatumCtrl() {
        this.datumVonCtrl.valueChanges.subscribe({
            next: datum => {
                if (datum) {
                    this.ladeTiereAnDatum(datum);
                }
            }
        });
    }

    private ladeTiereAnDatum(datum: Date) {
        this.gesamtgewichtCtrl.disable();
        this.durchschnittsgewichtCtrl.disable();
        let params = new HttpParams();
        params = params.set('gruppe_id', this.gruppe.gruppe_id);
        params = params.set('datum', formatDate(datum, 'yyyy-MM-dd', this.locale));
        this.api.get(this.apiBaseUrl + 'gruppe/read_anzahl_tiere_an_datum.php', params).subscribe({
            next: response => {
                this.anzahlTiereAnDatum = response.anzahl_tiere;
                this.gesamtgewichtCtrl.setValue(undefined);
                this.durchschnittsgewichtCtrl.setValue(undefined);
                this.gesamtgewichtCtrl.enable();
                this.durchschnittsgewichtCtrl.enable();
            },
            error: err => {
                this.messageService.errorMessage('Fehler', err);
                this.gesamtgewichtCtrl.enable();
                this.durchschnittsgewichtCtrl.enable();
            }
        });
    }
}
