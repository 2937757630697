<h1 mat-dialog-title>{{ data.headline }}</h1>
<div mat-dialog-content>
    <p>{{ data.text }}</p>
    <mat-form-field style="width: 100%">
        <mat-label>{{ data.label }}</mat-label>
        <mat-select [formControl]="selectCtrl">
            <mat-option *ngFor="let o of data.options" [value]="o">{{
                o.value
            }}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button mat-button (click)="close()">Abbrechen</button>
    <button
        mat-flat-button
        color="accent"
        (click)="ok()"
        [disabled]="!selectCtrl.value"
    >
        {{ data.okButton }}
    </button>
</div>
