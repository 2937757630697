import {HttpParams} from "@angular/common/http";
import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {
    ConfirmDialogInput,
    ConfirmDialogResult
} from "../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog";
import {
    ConfirmDialogComponent
} from "../../../../projects/kiene-core/src/lib/components/dialogs/confirm-dialog/confirm-dialog.component";
import {
    PdfViewerDesktopService,
    PdfViewerDialogInput
} from "../../../../projects/kiene-core/src/lib/components/pdf-viewer/kiene-pdf-viewer/kiene-pdf-viewer-desktop/pdf-viewer-desktop.service";
import {API_BASE_URL_SERVICE} from "../../../../projects/kiene-core/src/lib/kiene-core.config";
import {
    KieneBackendApiService
} from "../../../../projects/kiene-core/src/lib/services/backend-api/kiene-backend-api.service";
import {KieneTableService} from "../../components/kiene-table/kiene-table.service";
import {DateTableColumn, StatusTableColumn, TableColumn} from "../../components/kiene-table/table-column";
import {TableDescriptor, ToolbarButton} from "../../components/kiene-table/table-descriptor";
import {Condition, Operator, TableOption, TableOptionEvent} from "../../components/kiene-table/table-option";
import {MessageService} from "../../services/message.service";
import {Abholliste} from "../gruppen/globale-tiersuche/abholliste";
import {
    AbhollisteDialogComponent
} from "../gruppen/globale-tiersuche/add-tier-to-liste-dialog/abholliste-dialog/abholliste-dialog.component";
import {AbhollisteDetailsComponent} from "./abholliste-details/abholliste-details.component";

@Component({
    selector: 'kiene-abhollisten',
    templateUrl: './abhollisten.component.html',
    styleUrls: ['./abhollisten.component.scss']
})
export class AbhollistenComponent implements OnInit {
    tableDescriptor = new TableDescriptor();
    loadAbhollisteSubscription = new Subscription();

    constructor(
        private api: KieneBackendApiService,
        private tableService: KieneTableService,
        private messageService: MessageService,
        private pdfService: PdfViewerDesktopService,
        private dialog: MatDialog,
        @Inject(API_BASE_URL_SERVICE) private apiBaseUrl: string
    ) {
    }

    ngOnInit(): void {
        // this.loadAllAbhollisten();
        this.initTable();
    }

    public catchOptionEvent(event: TableOptionEvent): void {
        console.log(event)
        const abholliste = <Abholliste>event.value;
        if (event.name === 'abschliessen') {
            this.abschliessen(abholliste);
        } else if (event.name === 'wieder_oeffnen') {
            this.reOpen(abholliste);
        } else if (event.name === 'showPdf') {
            this.generatePdf(abholliste);
        } else if (event.name === 'add') {
            this.openAbhollistenDialog();
        } else if (event.name === 'delete') {
            this.deleteAbholliste(abholliste);
        } else if (event.name === 'details') {
            this.openAbhollisteDetailsDialog(abholliste);
        }
    }

    generatePdf(al: Abholliste) {
        const input = new PdfViewerDialogInput(
            'abholliste/read_or_mail_pdf.php',
            new HttpParams().set('abholliste_id', al.abholliste_id.toString()),
            'Abholliste ' + al.kommentar,
            {
                email: {
                    sende_mail: true,
                    kunde_id: al.kunde_id,
                },
            }
        );
        this.pdfService.openPdfViewer(input);
    }

    private initTable(): void {
        this.tableDescriptor.headline = 'Abhollisten';
        this.tableDescriptor.uniqueIdentifier = 'Abhollisten';
        this.tableDescriptor.apiUrl = this.apiBaseUrl + 'abholliste/';
        this.tableDescriptor.initialSortDirection = 'asc';
        this.tableDescriptor.initialSortColumn = 'abholliste_id';
        this.tableDescriptor.nameOfIdField = 'abholliste_id';
        this.tableDescriptor.selectable = false;
        this.tableDescriptor.createAllowed = false;
        this.tableDescriptor.showDetails = true;
        this.tableDescriptor.showDetailsAsOption = true;
        this.tableDescriptor.columns = this.getColumns();
        this.tableDescriptor.options = this.getOptions();
        this.tableDescriptor.toolbarButtons = this.getToolbarButtons();
        this.tableDescriptor.permissionView = 9491;
        this.tableDescriptor.permissionCreate = 9492;
        this.tableDescriptor.permissionUpdate = 9492;
        this.tableDescriptor.permissionDelete = 9494;

    }

    private getColumns(): TableColumn[] {
        const columns = [];
        columns.push(new TableColumn('abholliste_id', 'ID'));
        columns.push(new TableColumn('kommentar', 'Kommentar'));
        columns.push(new TableColumn('kunde', 'Empfänger'));
        columns.push(new TableColumn('kunde_zusatz', 'Kunde Zusatz'));
        columns.push(new DateTableColumn('abholdatum', 'Abholdatum', 'dd.MM.YYYY'));
        columns.push(new TableColumn('anzahl_tiere', 'Anzahl Tiere'));
        columns.push(new StatusTableColumn('status', 'Status', this.getStatusColorMap()));
        columns.push(new TableColumn('pdf_id', '', 'pdf'));

        return columns;
    }

    private getOptions(): TableOption[] {
        const options: TableOption[] = [];

        options.push(
            new TableOption('abschliessen', 'Abschließen', 'check_small', 9492,
                {
                    conditions: [
                        new Condition('status_id', Operator.UNGLEICH, 202),
                        new Condition('anzahl_tiere', Operator.GROESSER, 0)
                    ]
                })
        );
        options.push(
            new TableOption('wieder_oeffnen', 'Wieder öffnen', 'repartition', 9492,
                {conditions: [new Condition('status_id', Operator.GLEICH, 202)]})
        );
        options.push(
            new TableOption('delete', 'Löschen', 'delete', 9494,
                {conditions: [new Condition('status_id', Operator.UNGLEICH, 202)]})
        );

        return options;
    }

    private getToolbarButtons(): ToolbarButton[] {
        const toolbarButtons = [];
        return toolbarButtons;
    }

    private openAbhollistenDialog(input?: Abholliste) {
        if (!input) {
            input = new Abholliste();
        }
        input.status_id = 201;
        this.dialog.open(AbhollisteDialogComponent, {data: input}).afterClosed().subscribe({
            next: value => {
                if (value) {
                    console.log(value)
                    this.api.post(this.apiBaseUrl + 'abholliste/update.php', value).subscribe({
                        next: response => {
                            this.tableService.reload('openAbhollistenDialog');
                        },
                        error: error => {
                            this.messageService.alertMessage('Fehler beim Speichern der Abholliste.');
                        }
                    });
                }
            }
        });
    }

    private abschliessen(abholliste: Abholliste): void {
        if (abholliste.status_id === 202) {
            this.messageService.alertMessage('Abholliste wurde bereits abgeschlossen.');
            return;
        }
        abholliste.status_id = 202;
        this.updateAbholliste(abholliste);
    }

    private updateAbholliste(abholliste: Abholliste): void {
        this.api.post(this.apiBaseUrl + 'abholliste/update.php', abholliste).subscribe({
            next: response => {
                this.tableService.reload('abschliessen');
            },
            error: error => {
                this.messageService.errorMessage('Fehler beim Abschließen der Abholliste.', error);
            }
        })
    }

    private reOpen(abholliste: Abholliste): void {
        abholliste.status_id = 201;
        this.updateAbholliste(abholliste);
    }


    private deleteAbholliste(abholliste: Abholliste): void {
        if (abholliste.status_id === 202) {
            this.messageService.alertMessage('Abholliste wurde bereits abgeschlossen.');
            return;
        }
        const input = new ConfirmDialogInput();
        input.headline = 'Abholliste löschen';
        input.text = 'Möchten sie diese Abholliste wirklich löschen?';
        input.okButton = 'Löschen';
        this.dialog.open(ConfirmDialogComponent, {data: input}).afterClosed().subscribe({
            next: (value: ConfirmDialogResult) => {
                if (value.ok) {
                    this.api.post(this.apiBaseUrl + 'abholliste/delete.php', abholliste).subscribe({
                        next: response => {
                            this.tableService.reload('deleteAbholliste');
                        },
                        error: error => {
                            this.messageService.errorMessage('Fehler beim Löschen der Abholliste.', error);
                        }
                    });
                }
            }
        });
    }

    private getStatusColorMap() {
        const map = new Map<number, string>();
        map.set(201, '#ef8354');
        map.set(202, '#4f902f');
        return map;
    }

    private openAbhollisteDetailsDialog(abholliste: Abholliste): void {
        this.dialog.open(AbhollisteDetailsComponent, {data: abholliste, minWidth: '90%'});
    }
}
