export class StoragePlace {
    lagerplatz_id: number;
    bezeichnung: string;
    lager_id: number;
    mandant_id: number;
  }

  export interface StoragePlaceApi {
    records: StoragePlace[];
    count: number;
  }
