import { HttpParams } from '@angular/common/http';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { environment } from '../../../environments/environment';
import {
    Anwendung,
    AnwendungEvent,
} from '../../documents/behandlungsanfrage/anwendung';
import { KieneTableService } from '../kiene-table/kiene-table.service';

@Component({
    selector: 'kiene-anwendungs-selector',
    templateUrl: './anwendungs-selector.component.html',
    styleUrls: ['./anwendungs-selector.component.css'],
})
export class AnwendungsSelectorComponent implements OnInit, OnChanges {
    @ViewChild('anwendungSelect') anwendungSelect: MatSelect;

    anwendungCtrl = new UntypedFormControl();
    anwendungen: Anwendung[] = [];

    @Input('disabled') disabled = false;
    @Input('kundeMandantId') kundeMandantId: number;
    @Input('required') required = false;
    @Output('change') change = new EventEmitter<AnwendungEvent>();

    constructor(private api: KieneTableService) {}

    ngOnInit(): void {
        if (this.required) {
            this.anwendungCtrl.setValidators([Validators.required]);
        }
    }

    ngOnChanges() {}

    public isValid(): boolean {
        if (!this.required) {
            return true;
        } else {
            return this.anwendungCtrl.valid;
        }
    }

    loadAnwendungen(
        materialId: number,
        tierartId: number,
        emit: boolean,
        ladePosition: boolean
    ) {
        if (!materialId || !tierartId) {
            return;
        }

        let params = new HttpParams();
        params = params.set('artikel_id', materialId.toString());
        params = params.set('tierart_id', tierartId.toString());

        let headers;
        if (this.kundeMandantId !== undefined) {
            headers = { 'MANDANTID-KUNDE': this.kundeMandantId.toString() };
        }

        this.api
            .get(
                environment.BASE_URI + 'anwendung/read_beleg.php',
                params,
                headers
            )
            .subscribe(
                (response) => {
                    this.anwendungen = response.records;
                    if (this.anwendungen.length === 1) {
                        this.anwendungCtrl.setValue(this.anwendungen[0]);
                        if (ladePosition) {
                            this.change.emit(
                                new AnwendungEvent(this.anwendungen[0], {
                                    ladePosition: true,
                                })
                            );
                        } else if (emit) {
                            this.change.emit(
                                new AnwendungEvent(this.anwendungen[0])
                            );
                        }
                    } else {
                        if (emit && !ladePosition) {
                            this.setFocus();
                        }
                    }
                },
                (error) => {}
            );
    }

    anwendungSelected(event: MatSelectChange) {
        this.change.emit(new AnwendungEvent(event.value));
    }

    anwendungOpenedChange(event) {}

    anwendungComparator(a: Anwendung, b: Anwendung) {
        if (a && b) {
            return a.anwendung_id === b.anwendung_id;
        }
        return false;
    }

    public clear() {
        this.anwendungen = [];
        this.anwendungCtrl.setValue(null);
        this.anwendungSelect.close();
        this.anwendungCtrl.markAsUntouched();
        this.change.emit(new AnwendungEvent(null));
    }

    public close() {
        this.anwendungSelect.close();
    }

    public setFocus(): void {
        console.debug(
            'AnwendungsSelector setFocus() at %s',
            new Date().toISOString()
        );
        setTimeout(() => {
            this.anwendungSelect?.focus();
            this.openPanel();
        }, 50);
    }

    public openPanel() {
        this.anwendungSelect.open();
    }

    public setAnwendung(
        anwendung: Anwendung,
        tierartId: number,
        emit: boolean,
        ladePosition: boolean
    ) {
        this.anwendungCtrl.setValue(anwendung, { emitEvent: emit });
        this.loadAnwendungen(
            anwendung.artikel_id,
            tierartId,
            emit,
            ladePosition
        );
    }

    public getAnwendung(): Anwendung {
        return this.anwendungCtrl?.value;
    }
}
