import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FortbildungComponent } from './fortbildung/fortbildung.component';
import { FortbildungDialogComponent } from './fortbildung-dialog/fortbildung-dialog.component';
import { KieneTableModule } from '../../components/kiene-table/kiene-table.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatDialogModule} from "@angular/material/dialog";



@NgModule({
  declarations: [FortbildungComponent, FortbildungDialogComponent],
    imports: [
        CommonModule,
        KieneTableModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule
    ],
  exports: [
    FortbildungComponent
  ]
})
export class FortbildungenModule { }
