<div [style.padding]="isDialog ? '0' : '0 24px 64px 24px'">

    <kiene-page-header *ngIf="!isDialog" [routeRelativeAlternative]="backRoute"
                       [ueberschrift]="headline"></kiene-page-header>

    <mat-card *ngIf="haendler?.viehhaendler_id && haendler?.favorit === 1 && !this.ersteinrichtung"
              style="margin-bottom: 12px">
        <section class="settings-content__section">
            <div style="display: flex; align-items: center; padding-bottom: 6px">
                <h1 class="settings-content__section__headline">Aktionen</h1>
            </div>
            <button style="margin: 0 0 12px 0" mat-flat-button type="button" color="warn" class="button"
                    (click)="deleteHaendlerFavorit($event)">Löschen
            </button>

        </section>
    </mat-card>

    <mat-card [style.padding]="isDialog ? '0 !important' : ''">
        <form>
            <div class="one-row">
                <span class="spacer"></span>

                <kiene-autocomplete #nameAutocomplete style=" width: 100%" [showClear]="false"
                                    [showDeleteButton]="false"
                                    [required]="true" [genericApiUrl]="haendlerPath" [displayField]="'name'"
                                    [showLoadingIndicator]="true"
                                    [placeholder]="'Name'" [kieneObject]="haendler" [emitFreeText]="true"
                                    [focus]="false" (elementSelected)="selectedHaendler($event)">
                </kiene-autocomplete>
                <span class="spacer"></span>
            </div>
            <div class="one-row">
                <span class="spacer"></span>
                <mat-form-field class="entsorger-form-input">
                    <mat-label>VVVO</mat-label>
                    <input data-cy="haendler-details-vvvo" matInput type="text" placeholder="VVVO"
                           [formControl]="vvvoCtrl" autocomplete="vvvo" maxlength="15" minlength="15">
                    <span matSuffix>{{ vvvoCtrl?.value?.length }}/15</span>
                    <mat-error class="input-error" *ngIf="vvvoCtrl.hasError('required')">Die VVVO ist ein Pflichtfeld.
                    </mat-error>
                    <mat-error class="input-error" *ngIf="vvvoCtrl.hasError('pattern')">Die VVVO-Nummer muss 15 Zeichen
                        lang sein.
                    </mat-error>
                </mat-form-field>
                <span class="spacer"></span>
            </div>
            <div class="one-row">
                <span class="spacer"></span>
                <mat-form-field class="entsorger-form-input">
                    <mat-label>E-Mail</mat-label>
                    <input matInput data-cy="haendler-details-email" type="text" placeholder="E-Mail"
                           [formControl]="emailCtrl" autocomplete="email">
                    <mat-error class="input-error" *ngIf="emailCtrl.hasError('pattern')">Die E-Mail ist ungültig. Bitte
                        überprüfen.
                    </mat-error>
                </mat-form-field>
                <span class="spacer"></span>
            </div>
            <div class="one-row">
                <span class="spacer"></span>
                <mat-form-field class="entsorger-form-input">
                    <mat-label>Telefon</mat-label>
                    <input data-cy="haendler-details-telefon" matInput type="text" placeholder="Telefon"
                           [formControl]="telefonCtrl" autocomplete="tel">
                    <mat-error class="input-error" *ngIf="telefonCtrl.hasError('pattern')">Die Telefonnummer ist
                        ungültig. Bitte überprüfen.
                    </mat-error>
                </mat-form-field>
                <span class="spacer"></span>
            </div>
            <div class="one-row">
                <span class="spacer"></span>
                <mat-form-field class="entsorger-form-input">
                    <mat-label>Mobil</mat-label>
                    <input matInput type="text" data-cy="haendler-details-mobil" placeholder="Mobil"
                           [formControl]="mobilCtrl" autocomplete="mobil">
                    <mat-error class="input-error" *ngIf="mobilCtrl.hasError('pattern')">Die Mobilfunknummer ist
                        ungültig. Bitte überprüfen.
                    </mat-error>
                </mat-form-field>
                <span class="spacer"></span>
            </div>
            <div class="one-row">
                <span class="spacer"></span>
                <mat-form-field class="entsorger-form-input">
                    <mat-label>FAX</mat-label>
                    <input matInput type="text" data-cy="haendler-details-fax" placeholder="Fax" [formControl]="faxCtrl"
                           autocomplete="fax">
                    <mat-error class="input-error" *ngIf="faxCtrl.hasError('pattern')">Die Faxnummer ist ungültig. Bitte
                        überprüfen.
                    </mat-error>
                </mat-form-field>
                <span class="spacer"></span>
            </div>
            <div class="one-row">
                <span class="spacer"></span>
                <mat-form-field class="entsorger-form-input">
                    <mat-label>Strasse</mat-label>
                    <input matInput type="text" data-cy="haendler-details-strasse" placeholder="Strasse Hausnr."
                           [formControl]="strasseCtrl" autocomplete="street-address">
                    <mat-error class="input-error" *ngIf="strasseCtrl.hasError('pattern')">Die Strasse ist ungültig.
                    </mat-error>
                    <mat-error class="input-error" *ngIf="strasseCtrl.hasError('required')">Die Strasse ist ein
                        Pflichtfeld.
                    </mat-error>
                </mat-form-field>
                <span class="spacer"></span>
            </div>
            <div class="one-row">
                <span class="spacer"></span>
                <mat-form-field class="entsorger-form-input">
                    <mat-label>Postleitzahl</mat-label>
                    <input matInput type="text" data-cy="haendler-details-plz" placeholder="Postleitzahl"
                           [formControl]="plzCtrl" autocomplete="postal-code">
                    <mat-error class="input-error" *ngIf="plzCtrl.hasError('pattern')">Die Postleitzahl muss aus fünf
                        Ziffern bestehen.
                    </mat-error>
                    <mat-error class="input-error" *ngIf="plzCtrl.hasError('required')">Die Postleitzahl ist ein
                        Pflichtfeld.
                    </mat-error>
                </mat-form-field>
                <span class="spacer"></span>
            </div>
            <div class="one-row">
                <span class="spacer"></span>
                <mat-form-field class="entsorger-form-input">
                    <mat-label>Ort</mat-label>
                    <input matInput type="text" data-cy="haendler-details-ort" placeholder="Ort" [formControl]="ortCtrl"
                           autocomplete="address-level2">
                    <mat-error class="input-error" *ngIf="ortCtrl.hasError('pattern')">Die Stadt ist ungültig.
                    </mat-error>
                    <mat-error class="input-error" *ngIf="ortCtrl.hasError('required')">Die Stadt ist ein Pflichtfeld.
                    </mat-error>
                </mat-form-field>
                <span class="spacer"></span>
            </div>
            <div
                [style]="isDialog ? 'position: sticky; bottom: 0; background-color: white; padding: 12px 0;' : 'justify-content: center'"
                class="one-row">
                <span *ngIf="isDialog" class="spacer"></span>
                <ng-content select="[additional-actions]"></ng-content>
                <button data-cy="haendler-details-save" mat-flat-button
                        type="button" color="accent" (click)="addOrUpdateHaendler()"
                        [disabled]="isLoading">{{ buttonText }}
                </button>
            </div>
        </form>
    </mat-card>


</div>
